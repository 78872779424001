/*!

=========================================================
*Invetory Main (TOMAS FÍSICAS)
=========================================================

*Componente para verificar los inventarios no / valorados
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

//Componentes de React.
import React, { useState, useEffect, useContext } from "react";

// Reactstrap components
import {
    CardHeader,
    CardBody,
    Button,
    Card,
    Row,
    Col,
} from "reactstrap";

//Componente para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Button Geist.
import { Button as ButtonGeist } from "@geist-ui/react";

// core antd
import { Tabs } from 'antd';

//Componentes locales
import Validated from "./Validated";
import NoValidated from "./NoValidated";

const { TabPane } = Tabs;

const InventoryMain = ({ handleOnBack }) => {
 //#region States globales
    const {
        isMobile,
        isComputer
    } = useContext(UserContext);
    //#endregion

    const [labels, setLabels] = useState(
        {
            title: "Tomas Físicas",
            subtitle: "Tomas de inventario"
        }
    )

    const [fields, setFields] = useState([
        {
            id: 'code',
            label: "Número de serie",
            width: "4",
            type: "text",
            disabled: false,
            required: true
        },
        {
            id: 'code2',
            label: "Número de serie 2",
            width: "4",
            type: "text",
            disabled: false,
            required: true
        },
        {
            id: 'code3',
            label: "Número de serie 3",
            width: "4",
            type: "text",
            disabled: false,
            required: true
        },
    ])

    const [newInfo, setNewInfo] = useState([])

    //Llenar newInfo en base a fields.
    useEffect(() => {
        const info = fields.reduce((fields, field) => {
            return { ...fields, [field.id]: "" };
        }, {});
        setNewInfo(info)

    }, [])

    //Butones superiores.
    const [buttons, setButtons] = useState([

        {
            id: "validated",
            color: "success",
            icon: "fas fa-boxes",
            label: "1. Inventario Valorado",
            width: "4",
            title: "Inventario Valorado",
            subtitle: "Tomas fisicas de inventario valorado",
            component: Validated,

        },
        {
            id: "novalidated",
            color: "success",
            icon: "fas fa-truck-moving",
            label: "2. Inventario no valorado",
            width: "4",
            component: NoValidated,
            title: "Inventario No Valorado",
            subtitle: "Tomas fisicas de inventario no valorado",


        },
    ])

    //Si es PC que al arrancar ponga la pantalla en 1.
    useEffect(() => {
        if (isComputer) {
            handleChangePane("0")
        }
    }, [])

    //Component selected.
    const [selectedComponent, setSelectedComponent] = useState(
        null
    )

    //Función para abrir un componente de un button.
    const handleOpenComponent = (component) => {
        setStage(1)
        setSelectedComponent(component)
    };

    //Función para volver a los botones o cards.
    const handleLocalOnBack = () => {
        if (setSelectedComponent && stage > 1) {
            setStage(stage - 1)
        } else if (selectedComponent == null || isComputer) {
            handleOnBack()
        } else {
            setSelectedComponent(null)
        }
    }

    //En base al nombre del pane, asigna el componente. 
    const handleChangePane = (key) => {
        setSelectedComponent(null);
        let component = buttons.filter((button, keyB) => keyB == key)[0];
        setSelectedComponent(component)
        setStage(1)

    }

    //Etapas diseñados para los componentes.
    const [stage, setStage] = useState(1)


    return (
        <>
            <Row className="d-flex justify-content-center">
                <Col xs={isMobile ? "12" : "6"}>
                    <Card className="bg-gradient ">


                        <CardHeader>
                            <Row>
                                <Col xs="4">
                                    <Button color="secondary" size="sm" outline onClick={() => handleLocalOnBack()}>
                                        <span className="btn-inner--icon mr-1">
                                            <i className={"fas fa-arrow-left"} />
                                        </span>
                                        {(selectedComponent && stage > 1) || (isMobile && selectedComponent) ? "Volver" : "Inicio"}
                                    </Button>
                                </Col>
                                <Col xs="6">
                                    <div>
                                        <h5 className="h3 mb-0">{labels.title}</h5>
                                        {!isMobile && <h6 className="surtitle">{labels.subtitle}</h6>}
                                    </div>
                                </Col>


                            </Row>
                        </CardHeader>

                        <CardBody className=" ">

                            {/* --------- Botones sólo para móvil ------------------------*/}
                            {!selectedComponent && isMobile &&
                                <Row className=" mt--3">
                                    {buttons.map((button, key) =>
                                        <Col xs={12} key={key + 'c'} className={" d-flex justify-content-center align-items-center  " + (isMobile && "mt-1")}>
                                            <ButtonGeist
                                                key={key + 'b'}
                                                type={button.color}
                                                size="small"
                                                block
                                                style={{ width: isComputer ? "30%" : '100%' }}
                                                // ype="button"
                                                onClick={() => handleOpenComponent(button)} className=""
                                            >
                                                <span className="btn-inner--icon mr-2 " >
                                                    <i className={button.icon} />
                                                </span>
                                                <span className="btn-inner--text ">
                                                    {button.label}
                                                </span>
                                            </ButtonGeist>
                                        </Col>
                                    )}
                                </Row>
                            }
                            {/* --------- Fin de botones sólo para móvil ------------------*/}

                            {/* --------- Navtab sólo para PC -----------------------------*/}
                            {!isMobile &&
                                <Row className="  ">

                                    <Col xs={12} className=" p-1 mt--4 ">
                                        <Tabs defaultActiveKey={""} onChange={(key) => handleChangePane(key)}>
                                            {buttons.map((button, key) =>
                                                <TabPane
                                                    tab={
                                                        <span>
                                                            <span className="btn-inner--icon mb-2 mr-2">
                                                                <i className={button.icon} aria-hidden="true" />
                                                            </span>
                                                            {button.label}
                                                        </span>
                                                    }
                                                    key={key}

                                                >

                                                </TabPane>

                                            )}
                                        </Tabs>
                                    </Col>
                                </Row>
                            }
                            {/* --------- Fin de Navtab sólo para PC ------------------*/}

                            {selectedComponent &&
                                (


                                    <selectedComponent.component
                                        //#region Props para el card
                                        //handleOnBack={handleOnBack}
                                        //#endregion
                                        //#region Props de otros componentes
                                        //#endregion  
                                        isMobile={isMobile}
                                        isComputer={isComputer}
                                        stage={stage}
                                        setStage={setStage}
                                        selectedComponent={selectedComponent}
                                    />

                                )
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default InventoryMain;