// react library
import React, { useState, useEffect, useRef } from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {
    Button, Col, FormGroup, Input, Row, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Form, Container
} from "reactstrap";
//Token 
import { getUserProtected } from "selectors/adminLayout";
import { useDispatch } from 'react-redux';
import { Upload, Icon } from 'antd';
import { urlBase } from "api/urls.jsx";
import { newRequest } from 'actions/DocumentSystem.jsx'
import { Spin } from 'antd';
import { Typeahead } from 'react-bootstrap-typeahead'
//actions for endpoint
import { getDataCustomers, deleteFile, deleteAllFiles } from 'actions/DocumentSystem.jsx';

const { Dragger } = Upload;

const NewDocumentModal = ({

    showModal,
    setModal,
    vendors,
    documentType,
    discountType,
    listIdDocument,
    Toast,
    setRealTable,
    rows,
    isAdmin,
}) => {
    const dispatch = useDispatch();


    const [loading, setLoad] = useState(false);

    const [selected, setSelected] = useState([]);

    const [state, setState] = useState({
        unmountOnClose: true,
        fileList: [],
        idFileRequest: "",
        readyToSend: false,
        readyToUpload: false
    });
    const token = getUserProtected();

    const { unmountOnClose, fileList, readyToSend, readyToUpload } = state;

    //state que guarda la información de la solicitud para hacer el insert
    const [newInfo, setNewInfo] = useState({})

    const { documentId, vendor } = newInfo;

    //estado para capturar el valor del typehead customerName
    const typeaheadRef = useRef(null);

    //funcion para cerrar el modal
    const toggleNewDocumentModal = (() => {

        const { documentId } = newInfo;
        const { fileList } = state;

        // if (documentId !== "" && fileList.length > 0) {
        //     dispatch(deleteAllFiles({ id: documentId })).then((resp) => {
        //         const { payload } = resp;
        //         if (payload.status === 200) {
        //             console.log("Se borraron los archivos del BE")
        //         } else {
        //             console.log("error al borrar los archivos del BE")
        //         }
        //     })
        // }

        setNewInfo({})
        setVendorDiscount(false);
        setVendorDiscountNumber(false);

        //limpiar el upload
        setState((prevState) => ({
            ...prevState,
            fileList: [],
            readyToSend: false,
        }));

        //Limpiar los inputs extras de SV (no sirve)
        listIdDocument.length = 0
        setRealTable(prevState => !prevState);
        setModal((prevState) => ({
            ...prevState,
            showNewDocumentModal: !prevState.showNewDocumentModal,
            listIdDocument: [],
        }));
    });

    //funcion para agregar PO extras del SV
    const plusButton = () => {
        const list = listIdDocument.push(1);
        setModal((prevState) => ({
            ...prevState,
            listIdDocument: list,
        }));
    }
    //funcion para eliminar PO extras del SV
    const minusButton = () => {
        const list = listIdDocument.pop(1);
        setModal((prevState) => ({
            ...prevState,
            listIdDocument: list,
        }));
    }

    //funcion para eliminar o subir nuevos archivos
    const handleOnFileList = (info) => {

        const { status } = info.file;
        //console.log(status);
        if (status === "removed") {

            const { file: { name, uid, response: { status, payload } } } = info;

            if (status === 200) {
                const { idFile } = payload;

                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            }


            dispatch(deleteFile({ id: documentId, name: name })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    if (fileList.length === 1) {
                        setState((prevState) => ({
                            ...prevState,
                            readyToSend: false,
                        }));
                    }
                } else {
                    console.log("error al intentar borrar el archivo")
                }
            })
            console.log(`El archivo ${name} fue quitado exitosamente.`);


        } else {
            const { fileList, file: { status, name } } = info;
            let response = "";
            if (status === 'done') {
                const { file } = info;
                response = file["response"]
                response = response.payload.idFile;
                //habilitar el botón de enviar
                setState((prevState) => ({
                    ...prevState,
                    readyToSend: true,
                }));
            } else if (status === 'error') {
                console.log(`Ocurrio un error cargando el archivo ${name}.`);
            }


            setState((prevState) => ({
                ...prevState,
                idFileRequest: response,
                fileList: fileList,
            }));
        }
    }

    const allowedFileTypes = [
        ".xlsx", ".xls", ".xlsm", ".xlsb", ".xlt", ".ppt", ".pptx", ".pdf", ".doc", ".docx",
        ".rar", ".zip", ".cfr", ".csv", ".txt", ".eml", ".jpg", ".png", ".html", ".rtf", ".xml",
        ".css", ".tif", ".ods", ".msg"
    ];

    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: allowedFileTypes.join(","),
        multiple: true,
        action: `${urlBase}/document-system/upload-files/${documentId}`,
        beforeUpload: file => {
            const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
            if (!allowedFileTypes.includes(fileType)) {
                console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
                return false;
            }
            return true;
        },
        onChange: info => {
            console.log(info)
            // Filtrar la lista de archivos válidos para mantener solo los que aún existen
            const filteredFiles = info.fileList.filter(file => {
                console.log(file)
                if (file.status) {
                    return true;
                }
                return false;
            });
            // Actualizar la lista de archivos válidos
            info.fileList = filteredFiles;
            // Llamar a la función handleOnFileList con la lista de archivos válidos
            handleOnFileList(info);
        },
    };

    //estado para saber si se debe de agregar el campo "Número de Descuento de proveedor"
    const [vendorDiscountNumber, setVendorDiscountNumber] = useState(false);

    //estado para saber si se debe de agregar el campo "Número de Descuento de proveedor"
    const [vendorDiscount, setVendorDiscount] = useState(false);

    const [sbType, setSbType] = useState("text");

    //estado para verificar que label tendra el numero de descuento
    const [sbLabelName, setSbLabelName] = useState("");

    const [discountTypesValues, setDiscountTypesValues] = useState([{ value: "", label: "" }]);


    //estado para verificar si el tipo de descuento esta lleno
    const [selectedDiscountType, setSelectedDiscountType] = useState('');

    //funcion para modificar el state principal
    const handleChangeInfo = (constant, e) => {
        console.log(e);
        //si se cambia el document Type se busca si el usuario agrego el cliente y si fuera el caso se elimnina
        //para no afectar el BE a la hora de enviar la solicitud 
        if (constant === "documentType") {
            if (newInfo["customerName"]) {
                delete newInfo["customerName"];
            }
            if (newInfo["quote"]) {
                delete newInfo["quote"];
            }

        }

        //
        if (constant === "discountType") {

            setVendorDiscountNumber((e.label === "Special Bid") ? true : false);
            if (e.label !== "Special Bid") {
                if (newInfo["discountNumber"]) {
                    delete newInfo["discountNumber"];
                }
            }
            e = e.value;
            setSelectedDiscountType(e.value);
        }

        //si es valor que cambio es el vendor entonces se realiza un array de valores (select multiple)
        if (constant === "vendor") {

            const selectedVendorIds = e ? e.map(option => option.value) : [];
            const vendorDiscountSearch = selectedVendorIds.some(id => [10027706, 10002552, 10028101, 10035157].includes(id));
            const vendorDiscountSearchCisco = selectedVendorIds.some(id => id === 10002552);
            const vendorDiscountSearchToshiba = selectedVendorIds.some(id => id === 10035157);

            //label del campo
            if (vendorDiscountSearch) {
                const labels = {
                    "10027706": "Número de Special Bid",
                    "10028101": "Número de Special Bid",
                    "10002552": "Smart Account",
                    "10035157": "Nombre del Cart"
                };

                const vendorLabel = selectedVendorIds.map(id => labels[id])[0];
                setSbLabelName(vendorLabel);

                const result = discountType.filter(obj => {
                    return e.some(selectObj => selectObj.value === obj.vendor);
                });

                setDiscountTypesValues(result);

            } else {
                setSbLabelName("");
                if (newInfo["discountNumber"]) {
                    delete newInfo["discountNumber"];
                }
                if (newInfo["discountType"]) {
                    delete newInfo["discountType"];
                }
            }

            //verificar cual enseñar
            setVendorDiscountNumber((vendorDiscountSearchCisco || vendorDiscountSearchToshiba) ? true : false)

            setVendorDiscount((vendorDiscountSearchCisco || vendorDiscountSearchToshiba) ? false : vendorDiscountSearch);

            setSbType((vendorDiscountSearchCisco || vendorDiscountSearchToshiba) ? "text" : "number");

            if (e !== null) {
                e = Array.from(e, option => option.value);
            }
        }

        //se actualiza el state de la nueva informacion
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: e,

        }))

    }


    //funcion enviar una nueva solicitud
    const handleSendNewRequest = (e) => {

        setLoad(true);
        let cont = 0;

        if (selectedDiscountType === "" && vendorDiscount) {
            Toast.fire({
                title: "Atención",
                html: "Ingrese el Tipo de Descuento",
                type: 'error'
            });
            cont++;
        }

        const forms = document.querySelectorAll('#discountNumber, #quote');
        Array.from(forms).forEach((input) => {
            if (input.value === "") {
                input.classList.add("is-invalid");
                cont++;
                Toast.fire({
                    title: "Atención",
                    html: "Ingrese todos los campos obligatorios",
                    type: 'error'
                });
            } else {
                input.classList.remove("is-invalid");
            }
        });

        const selectedValues = typeaheadRef.current?.getInput().value;
        if (selectedValues !== undefined) {
            if (selectedValues.length === 0 || selectedValues === "") {
                Toast.fire({
                    title: "Atención",
                    html: "Ingrese el cliente de la orden de compra",
                    type: 'error'
                });
                cont++;
            }
        }

        if (cont > 0) {
            setLoad(false);
            return;
        }
        console.log(newInfo);
        dispatch(newRequest({ newInfo: newInfo })).then((resp) => {
            const { payload } = resp;
            console.log(resp)
            if (payload.status === 200) {
                toggleNewDocumentModal();

                Toast.fire({
                    title: "Atención",
                    html: "Se creo correctamente",
                    type: 'success'
                });


            } else {
                //si la PO no existe en SAP eliminar archivos de la DB
                const { documentId } = newInfo;
                //limpiar el upload
                setState((prevState) => ({
                    ...prevState,
                    fileList: [],
                    readyToSend: false,
                }));
                if (payload.response.data.message !== "El documento ya se encuentra registrado.") {
                    // dispatch(deleteAllFiles({ id: documentId })).then((resp) => {
                    //     const { payload } = resp;
                    //     if (payload.status === 200) {
                    //         console.log("Se borraron los archivos del BE")
                    //     } else {
                    //         console.log("error al borrar los archivos del BE")
                    //     }
                    // })
                }

                Toast.fire({
                    title: "Atención",
                    html: payload.response.data.message,
                    type: 'warning'
                });
            }
            setLoad(false);
            //Limpiar los inputs extras de SV
            listIdDocument.length = 0

        })

    }

    //funcion para guardar el valor Cliente del typehead
    useEffect(() => {
        setNewInfo((prevState) => ({
            ...prevState,
            customerName: selected,
        }))
    }, selected);

    useEffect(() => {
        setLoad(true);
        const getCustomersR = async () => {
            await getCustomers();
        }
        getCustomersR();

    }, [])

    const [dataCustomers, setDataCustomers] = useState("");

    const getCustomers = async () => {
        dispatch(getDataCustomers()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDataCustomers(payload.data.payload.rows);
            }
            setLoad(false);
        })
    }

    return (
        <Modal size="lg" isOpen={showModal} unmountOnClose={unmountOnClose} toggle={toggleNewDocumentModal}>
            <ModalHeader >Crear nuevo documento</ModalHeader>
            <ModalBody>
                <Spin size="large" spinning={loading}>
                    <Form noValidate className="formInfoContacts needs-validation formInfo">
                        <Row className="align-items-center">
                            <Col xs="6">
                                <FormGroup>
                                    <label className="form-control-label">Tipo de Documento*</label>
                                    <Select
                                        required={true}
                                        className="basic-single"
                                        id="documentType"
                                        type="select"
                                        placeholder={"Seleccione el tipo de documento"}
                                        isSearchable
                                        options={documentType}
                                        value={documentType.label}
                                        onChange={(e) => handleChangeInfo("documentType", e.value)}
                                    />

                                </FormGroup>
                            </Col>
                            <Col xs={(token.PAIS === "SV" || isAdmin) ? "4" : "6"}>
                                <FormGroup>
                                    <label className="form-control-label">Id de Documento*</label>
                                    <Input
                                        id="documentId"
                                        required={true}
                                        className="form-control"
                                        type="text"
                                        maxLength="10"
                                        placeholder="Ingresar el ID de la PO/SO"
                                        onChange={(e) => handleChangeInfo("documentId", e.target.value)}
                                    />
                                    {listIdDocument.length !== 0 ?
                                        listIdDocument.map((row, key) => {
                                            return (
                                                <Input
                                                    id={`documentId${key}`}
                                                    key={key}
                                                    required={true}
                                                    className="form-control mt-3"
                                                    type="text"
                                                    maxLength="10"
                                                    placeholder="Ingresar el ID de la PO/SO"
                                                    onChange={(e) => handleChangeInfo(`documentId${key}`, e.target.value)}
                                                />
                                            )
                                        }) : <></>}
                                </FormGroup>
                            </Col>
                            {(token.PAIS === "SV" || isAdmin) && (
                                <Col xs="2">
                                    <Button outline color="success" size="sm" style={{ borderRadius: "40px" }} onClick={(e) => plusButton()} disabled={listIdDocument.length === 3 ? true : false}>
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-plus" />
                                        </span>
                                    </Button>
                                    <Button outline color="danger" size="sm" style={{ borderRadius: "40px" }} onClick={(e) => minusButton()}>
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-minus" />
                                        </span>
                                    </Button>
                                </Col>
                            )
                            }
                        </Row>
                        {newInfo.documentType === "PO_PS" || vendorDiscount || vendorDiscountNumber ?
                            <>
                                <Row className="align-items-center">
                                    {
                                        newInfo.documentType === "PO_PS" &&
                                        <>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <label className="form-control-label">Cliente*</label>

                                                    <Typeahead
                                                        id="customerName"
                                                        ref={typeaheadRef}
                                                        minLength={1}
                                                        onChange={(e) => handleChangeInfo("customerName", e)}
                                                        options={dataCustomers}
                                                        placeholder="Escriba el nombre del cliente..."
                                                        value={newInfo.customerName}
                                                        renderMenuItemChildren={(option) => (
                                                            <div>
                                                                {option.label}
                                                                <div>
                                                                    <small>Pais: {option.Pais}</small>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <label className="form-control-label">Quote ganado*</label>
                                                    <Input
                                                        id="quote"
                                                        required={true}
                                                        className="form-control"
                                                        type="text"
                                                        valid={null === "valid"}
                                                        invalid={null === "invalid"}
                                                        placeholder="Ingrese el número de quote que ganó"
                                                        onChange={(e) => handleChangeInfo("quote", e.target.value)}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Este campo no puede estar vacio
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </>
                                    }
                                    {
                                        vendorDiscount &&
                                        <Col xs="6">
                                            <FormGroup>
                                                <label className="form-control-label">Tipo de Descuento*</label>
                                                <Select
                                                    required={true}
                                                    className="basic-single"
                                                    id="discountType"
                                                    type="select"
                                                    placeholder={"Seleccione el tipo de documento"}
                                                    isSearchable
                                                    options={discountTypesValues}
                                                    value={discountTypesValues.label}
                                                    onChange={(e) => handleChangeInfo("discountType", e)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                    {
                                        vendorDiscountNumber &&
                                        <Col xs="6">
                                            <FormGroup>
                                                <label className="form-control-label">{sbLabelName}*</label>
                                                <Input
                                                    id="discountNumber"
                                                    required={true}
                                                    className="form-control"
                                                    type={sbType}
                                                    valid={null === "valid"}
                                                    invalid={null === "invalid"}
                                                    placeholder="Ingresar el número"
                                                    onChange={(e) => handleChangeInfo("discountNumber", e.target.value)}
                                                />
                                                <div className="invalid-feedback">
                                                    Este campo no puede estar vacio
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                            </> : <></>
                        }
                        <Row className="align-items-center">
                            <Col xs="6">
                                <FormGroup>
                                    <label className="form-control-label">Proveedores*</label>

                                    <Select
                                        isMulti={true}
                                        required={true}
                                        className="basic-single"
                                        id="vendor"
                                        name="proveedor[]"
                                        type="select"
                                        placeholder={"Seleccione el proveedor"}
                                        isSearchable
                                        options={vendors}
                                        value={vendors.value}
                                        onChange={(e) => handleChangeInfo("vendor", e)}
                                    />

                                    {/* <select className="form-control"
                                        id="vendor"
                                        type="select"
                                        placeholder={"Selecione el proveedor"}
                                        multiple={true}
                                        name="proveedor[]"
                                        required={true}
                                        onChange={(e) => handleChangeInfo("vendor", e)}
                                    >
                                        {vendors.map((vendors, key) => {
                                            return (
                                                <option
                                                    value={vendors.id}
                                                    key={"select-" + key}
                                                >
                                                    {vendors.label}
                                                </option>
                                            );
                                        })}
                                    </select> */}

                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <label className="form-control-label">Comentarios</label>
                                    <textarea
                                        name="comment"
                                        type="text"
                                        id="comentario"
                                        size="30" className="form-control"
                                        placeholder="Comentarios"
                                        onChange={(e) => handleChangeInfo("comment", e.target.value)}
                                    >
                                    </textarea>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            (documentId && documentId.length > 6 && vendor && newInfo.documentType) &&
                            <Row className="align-items-center">
                                <Col xs="12">
                                    <FormGroup>
                                        <label style={{ textAlign: "center" }} className="form-control-label">Adjuntos*</label>
                                        <div className="form-row align-items-center">
                                            <Col className="m-1" sm="12">
                                                <Container>
                                                    <Dragger {...uploadProps} fileList={fileList}>
                                                        <p className="ant-uploading-drag-icon">
                                                            <Icon type="inbox" />
                                                        </p>
                                                        <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                                                        <p className="ant-uploading-hint">
                                                            Puede seleccionar archivos de diferentes folders/carpetas
                                                        </p>
                                                    </Dragger>
                                                </Container>
                                            </Col>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                        }
                    </Form>
                </Spin>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    type="button"
                    onClick={(e) => toggleNewDocumentModal()}
                >
                    Cancelar
                </Button>
                {
                    readyToSend &&
                    // fileList.length > 0 ?
                    <Button color="success" onClick={(e) => handleSendNewRequest(e)} >Enviar</Button>
                    // : <></>
                }
            </ModalFooter>
        </Modal >
    )
};

export default NewDocumentModal;