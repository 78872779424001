import { useEffect } from "react";
// javascript plugin that creates nice dropzones for files
import { Spin } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form as FormRS,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
} from "reactstrap";

const EditUserModal = (props) => {
  const {
    user,
    title,
    roles,
    message,
    loading,
    showModal,
    isInvalid,
    initialValues,
    toggleModal,
    onValidate,
    onWorkFlow,
  } = props;

  useEffect(() => {
    if (user.infoUser?.username) {
      onValidate(user.infoUser);
    }
  }, [user]);

  const validate = (values) => {
    const errors = {};
    if (Object.keys(user).length) {
      if (!values.role || values.role === "0")
        errors.role = "Debes seleccionar un rol.";
      if (!values.country || !values.country.length)
        errors.country = "Debes seleccionar los países.";
    }
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onWorkFlow}
              validate={validate}
              initialValues={initialValues}
              render={({
                handleSubmit,
                values,
                submitting,
                validating,
                valid,
              }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label className="form-control-label" for="username">
                        Nombre de Usuario
                      </Label>
                      <Field name="username">
                        {({ field }) => (
                          <InputGroup className="input-group-merge">
                            <Input
                              {...field}
                              type="text"
                              className="m-0"
                              value={user.infoUser?.username}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </Field>
                    </FormGroup>
                    {/* onClick={() => onValidate(input.value)} */}
                    {Object.keys(user).length ? (
                      <blockquote className="blockquote text-center">
                        <p className="mb-0">{user.NOMBRE}</p>
                        <footer className="blockquote-footer">
                          {user.POSICION}
                        </footer>
                      </blockquote>
                    ) : null}
                    {Object.keys(user).length ? (
                      <>
                        <FormGroup>
                          <Label className="form-control-label" for="role">
                            Rol
                          </Label>
                          <Field name="role">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un role
                                  </option>
                                  {roles.map((row, key) => {
                                    return (
                                      <option key={key + 1} value={row.id}>
                                        {row.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-control-label" for="country">
                            País
                          </Label>
                          <Field name="country">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  multiple
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="1" value="CR">
                                    Costa Rica
                                  </option>
                                  <option key="2" value="PA">
                                    Panama
                                  </option>
                                  <option key="3" value="HN">
                                    Honduras
                                  </option>
                                  <option key="4" value="GT">
                                    Guatemala
                                  </option>
                                  <option key="5" value="SV">
                                    El Salvador
                                  </option>
                                  <option key="6" value="NI">
                                    Nicaragua
                                  </option>
                                  <option key="7" value="DR">
                                    Republica Dominicana
                                  </option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </>
                    ) : null}
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting || isInvalid}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Continuar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

export default EditUserModal;
