import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const filters = handleActions({
  [constants.GET_AVAILABLE_FILTERS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.data;
    }
    return {};
  },
  // [constants.GET_FILTERS_DATA_BY_FILTER]: (state, action) => {
  //   const { status, data } = action.payload;
  //   if (status === 200) {
  //     return data.payload.data;
  //   }
  //   return [];
  // },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const dataChartPlannedRealHours = handleActions({
  [constants.GET_DATA_BY_CALENDAR_MODE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.data;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const dataChartHistory = handleActions({
  [constants.GET_HISTORY_DATA_BY_CALENDAR_MODE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.data;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const dataChartIndicator = handleActions({
  [constants.GET_INDICATOR_DATA_BY_CALENDAR_MODE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.data;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const collaboratorsReported = handleActions({
  [constants.GET_COLLABORATORS_REPORTED]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.data;
    }
    return {};
  }
}, {});

export const hoursAccused = handleActions({
  [constants.GET_HOURS_ACCUSED]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  }
}, {});

export const optionsFormMaintenance = handleActions({
  [constants.GET_VALUES_MAINTENANCE_USERS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  }
}, {});

export const activitiesReport = handleActions({
  [constants.GET_ACTIVITIES_REPORT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  }
}, {});

export const userCreated = handleActions({
  [constants.CREATE_USER_COE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  }
}, {});

export const allUsers = handleActions({
  [constants.GET_ALL_EMA_USERS_ACTIVE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.emaUsers;
    }
    return [];
  }
}, []);