import { Icon, Upload } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const { Dragger } = Upload;
const ModalImportExcel = props => {

    const {
        fileList,
        onFileList,
        showModal,
        importData,
        handleOnOpenModalImport,
        idCustomer
    } = props

    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: ".xlsx, .xls",
        multiple: false,
        action: `${urlBase}/gbm-contacts/contacts-updated-request/${idCustomer}`,
        onChange: onFileList,
    };

    return (
        <div>
            <Modal
                size="lg"
                isOpen={showModal}
                toggle={handleOnOpenModalImport}>
                <ModalHeader toggle={handleOnOpenModalImport}>Importar Excel</ModalHeader>
                <ModalBody>
                    <Dragger {...uploadProps} fileList={fileList}>
                        <p className="ant-uploading-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                        <p className="ant-uploading-hint">
                            *Se debe cargar el machote oficial de modificación de contactos.
                        </p>
                    </Dragger>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleOnOpenModalImport}>Cancelar</Button>{' '}
                    {
                        fileList.length > 0 ?
                            <Button color="success" onClick={importData} >Enviar</Button>
                            : <></>
                    }
                </ModalFooter>
            </Modal>
        </div>
    );
};

ModalImportExcel.propTypes = {

};

export default ModalImportExcel;