/*!
=========================================================
*Databot New Robot Modal
=========================================================

* Modal que se encarga de crear un nuevo robot, con su respectivo
planner.
=========================================================

* Coded by Diego Meza Castro & Eduardo Piedra Sanabria - Application Management GBM

*/

//Líbrería de React
import React, { useEffect, useState } from 'react';

//Para hacer peticiones al API
import { useDispatch } from 'react-redux';

//Action para crear el nuevo robot
import { newBot } from 'actions/databotDashboard';

//Usuario activo
import { getUsernameLogged } from 'selectors/adminLayout';

//Librería de Reactstra´p
import {
    ModalFooter,
    ModalHeader,
    FormGroup,
    ModalBody,
    CardBody,
    Button,
    Input,
    Modal,
    Badge,
    Card,
    Form,
    Row,
    Col,
} from "reactstrap";


import Select from "react-select";

//Import de Typeahead
import { Typeahead } from "react-bootstrap-typeahead";

//Librería del timepicker para el planner.
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

//Spinner
import { Spin } from 'antd';

//Drawer tipo modal
import { DrawerPlannerHelp } from './Orchestrator/DrawerPlannerHelp';

const NewRobotModal = ({ //Props
    setRealTable,
    showModal,
    employees,
    setModal,
    options,
    spinner,
    Toast,
}) => {

    //Disparador al api
    const dispatch = useDispatch();

    //State importante para almacenar la nueva información
    const [newInfo, setNewInfo] = useState({
        planner: { needPlanner: false },
        info: {}
    });

    //#region Generar opciones
    //Función para generar un arreglo de números usado por ejemplo para los minutos.
    const generateArrayNumbers = (limit, label) => {

        let counter = 1;
        let minutes = []

        while (counter != (limit + 1)) {
            minutes.push({ label: counter + " " + label, value: counter });
            counter++;
        }
        return minutes
    }

    //Agregar opciones de forma local para el form.
    options = {
        ...options, sino: [
            {
                label: "Si",
                value: "1"
            },
            {
                label: "No",
                value: "0"
            }
        ],
        typePlanner: [
            {
                label: "Minutos",
                value: "0"
            },
            {
                label: "Horas",
                value: "1"
            },
            {
                label: "Semanas",
                value: "2"
            },
            {
                label: "Mes",
                value: "3"
            },
        ],
        typeMinutes: generateArrayNumbers(60, "min"),
        typeWeek: [
            {
                label: "Lunes",
                value: "1"
            },
            {
                label: "Martes",
                value: "2"
            },
            {
                label: "Miercoles",
                value: "3"
            },
            {
                label: "Jueves",
                value: "4"
            },
            {
                label: "Viernes",
                value: "5"
            },
            {
                label: "Sabado",
                value: "6"
            },
            {
                label: "Domingo",
                value: "0"
            },
        ],

        typeMonth: generateArrayNumbers(31, "")
    }

    //#endregion

    //Desestructurar
    const { mode, bot } = showModal;

    //Efecto para cuando arranque el modal, establezca la data predeterminada.
    useEffect(() => {
        // debugger;
        if (mode == "edit") {

            //#region Establecer los campos predefinidos antes del planificador
            let fieldsA = {}

            fields.map(field => {

                if (field.elementType == "Input") {

                    fieldsA = { ...fieldsA, [field.id]: bot[field.id] }

                } else if (field.elementType == "Select") {
                    // debugger;

                    let searchOption =
                        options[field.options] &&
                        options[field.options]?.filter(

                            option => {

                                if (option.value == bot[field.id])
                                    return option
                            }

                        )[0]

                    fieldsA = { ...fieldsA, [field.id]: searchOption }

                } else if (field.elementType == "Typeahead") {
                    let searchEmployee =
                        employees &&
                        employees?.filter(

                            employee => {

                                if (employee.name == bot[field.id])
                                    return employee
                            }

                        )[0]

                    fieldsA = { ...fieldsA, [field.id]: searchEmployee }
                }

            })

            //#endregion


            //#region Establecer el planificador.
            let plannerA = {};

            if (Object.keys(JSON.parse(bot.planner)).length > 0) //=> Si no es un {} significa que tiene planner.
            {
                bot.planner = JSON.parse(bot.planner);

                plannerA = { needPlanner: true }


                fieldsPlanner.map(fieldPlanner => {

                    if (fieldPlanner.type == "select") {
                        let searchOption = options[fieldPlanner.options].filter(
                            option =>
                                option.value == bot.planner[fieldPlanner.idOnBot])



                        if (searchOption.length > 0) //=>Encontró la opción.
                        {
                            plannerA = { ...plannerA, [fieldPlanner.id]: searchOption[0] }
                        } else {
                            plannerA = { ...plannerA, [fieldPlanner.id]: "" }
                        }

                    } else if (fieldPlanner.type == "Multiselect") {

                        let fieldPlannerA = { ...fieldPlanner }

                        let searchOptions = options[fieldPlannerA.options].filter(
                            option => {
                                // debugger;
                                let splitedOptions = bot.planner[fieldPlannerA.idOnBot].split(",")

                                if ((splitedOptions).includes(option.value.toString()) &&
                                    bot.planner[fieldPlannerA.idOnBot] != "0") {
                                    return option
                                }
                            })


                        plannerA = { ...plannerA, [fieldPlannerA.id]: searchOptions.length > 0 ? searchOptions : "" }

                    } else if (fieldPlanner.type == "TimePicker") {

                        if (bot.planner[fieldPlanner.idOnBot] != '0') {
                            // console.log( bot.planner[fieldPlanner.idOnBot])
                            plannerA = {
                                ...plannerA,
                                [fieldPlanner.id]: bot.planner[fieldPlanner.idOnBot].replace(/\s/g, '').split(",")
                            }
                        }
                        else { plannerA = { ...plannerA, [fieldPlanner.id]: "" } }

                    }

                })

            }
            //#endregion


            //Adición final
            setNewInfo(newInfo => { return { ...newInfo, info: fieldsA, planner: plannerA } })

        }
    }, [mode])







    //#region Generar los campos del form

    //state de los campos de los formularios
    const [fields, setFields] = useState([
        {
            colWidth: "6",
            label: "Área*",
            placeholder: "",
            id: "area",
            options: "databotAreas",
            type: "select",
            disabled: false,
            required: true,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Usa SAP?*",
            placeholder: "",
            id: "sap",
            options: "sino",
            type: "select",
            disabled: false,
            required: true,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Proyecto*",
            placeholder: "Nombre de la carpeta de la Clase",
            id: "projectName",
            options: "",
            type: "text",
            disabled: false,
            required: true,
            visibleDefault: false,
            elementType: "Input"
        },
        {
            colWidth: "6",
            label: "Clase*",
            placeholder: "Clase de VisualStudio",
            id: "class",
            options: "",
            type: "text",
            disabled: false,
            required: true,
            visibleDefault: false,
            elementType: "Input"
        },
        {
            colWidth: "6",
            label: "Tiempo manual (minutos)*",
            placeholder: "",
            id: "manualTime",
            options: "",
            type: "number",
            disabled: false,
            required: true,
            visibleDefault: false,
            elementType: "Input"
        },
        {
            colWidth: "6",
            label: "Tiempo automatizado (minutos)*",
            placeholder: "",
            id: "automatedTime",
            options: "",
            type: "number",
            disabled: false,
            required: true,
            visibleDefault: false,
            elementType: "Input"
        },
        {
            colWidth: "6",
            label: "Departamento*",
            placeholder: "",
            id: "department",
            options: "department",
            type: "select",
            disabled: "false",
            required: true,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Usuario Funcional*",
            placeholder: "",
            id: "functionalUser",
            options: "employees",
            type: "Typeahead",
            disabled: "false",
            visibleDefault: false,
            required: true,
            elementType: "Typeahead"
        },
        {
            colWidth: "12",
            label: "Descripción del Robot",
            placeholder: "",
            id: "projectDescription",
            options: "",
            type: "textarea",
            disabled: false,
            visibleDefault: false,
            required: false,
            elementType: "Input"
        }
    ]);

    //state de los campos de la sección planer
    const [fieldsPlanner, setFieldsPlanner] = useState([
        {
            width: "6",
            label: "Seleccione el tipo de planner*",
            placeholder: "",
            id: "typePlanner",
            idOnBot: "Type", //=> Este id es porque en el Databot el id es diferente.
            options: "typePlanner",
            type: "select",
            disabled: false,
            required: true,
            visibleDefault: false,
            idFieldDepends: "",
            valueThatDepends: [],
        },
        {
            width: "6",
            label: "Cada cuantos minutos se ejecuta*",
            placeholder: "",
            id: "typeMinutes",
            idOnBot: "Minutes",
            options: "typeMinutes",
            type: "select",
            disabled: false,
            required: true,
            visibleDefault: false,
            idFieldDepends: "typePlanner",
            valueThatDepends: ["Minutos"],
        },

        {
            width: "6",
            label: "Seleccione los días de la semana*",
            placeholder: "",
            id: "typeWeek",
            idOnBot: "Week",
            options: "typeWeek",
            type: "Multiselect",
            disabled: false,
            required: true,
            visibleDefault: false,
            idFieldDepends: "typePlanner",
            valueThatDepends: ["Semanas"],
        },
        {
            width: "6",
            label: "Seleccione los días del mes*",
            placeholder: "",
            id: "typeMonth",
            idOnBot: "Month",
            options: "typeMonth",
            type: "Multiselect",
            disabled: false,
            required: true,
            visibleDefault: false,
            idFieldDepends: "typePlanner",
            valueThatDepends: ["Mes"],
        },
        {
            width: "6",
            label: "Seleccione waiting del robot*",
            placeholder: "",
            id: "waitingPlanner",
            idOnBot: "Waiting",
            options: "typeMinutes",
            type: "select",
            disabled: false,
            required: true,
            visibleDefault: false,
            idFieldDepends: "typePlanner",
            valueThatDepends: ["Minutos", "Semanas", "Mes", "Horas"],
        },

        {
            width: "6",
            label: "Seleccione las horas del día*",
            placeholder: "",
            id: "typeHours",
            idOnBot: "Hour",
            options: "",
            type: "TimePicker",
            disabled: false,
            required: true,
            visibleDefault: false,
            idFieldDepends: "typePlanner",
            valueThatDepends: ["Horas", "Semanas", "Mes"],
        },

    ]);

    //#endregion

    //#region Funciones para eliminar y gestionar el Typeahead
    //ref del typeahead para limpiarlo
    const [deleteRef, setDeleteRef] = useState(false);

    //Referencia para limipar typeahead
    const refTypeahead = React.createRef();

    useEffect(() => {
        if (deleteRef === true) {
            refTypeahead.current.clear();
            setDeleteRef(false)

        }
    }, [deleteRef])
    //#endregion

    //#region Mostrar o no mostrar la sección de ayuda de creación de planner

    //State para mostrar la ayuda
    const [showPlannerHelp, setShowPlannerHelp] = useState(false)

    //Handle para desplegar la ayuda
    const handleShowPlannerHelp = () => {
        setShowPlannerHelp(true)
    }

    //#endregion

    //#region Agregar las horas al planner

    //State temporal para almacenar la hora del planner y agregarlo al state
    const [temporalTime, setTemporalTime] = useState({ hour: "", moment: undefined });


    //Este handle es para crear las horas del planner
    const handleTime = (action, id, event,) => {

        if (action == "addTime") {
            if (!newInfo.planner["typeHours"].includes(event) && temporalTime.hour != undefined && temporalTime.hour != '') {

                setNewInfo(newInfo => {
                    return {
                        ...newInfo, planner: {
                            ...newInfo.planner,
                            ["typeHours"]: [...newInfo.planner["typeHours"], temporalTime.hour].sort((x, y) => x.localeCompare(y)),
                        }
                    }
                })

                setTemporalTime({ ...temporalTime, moment: undefined, hour: "" })

            }

        } else if (action == "addTemporalTime" && event !== undefined) {
            // console.log(action._d)

            setTemporalTime({ ...temporalTime, moment: event, hour: event != null ? event.format('HH:mm') : undefined })
        }
    }

    //#endregion

    //#region Handles para creación de planners, funciones para validar y limpiar el form

    //Handle para la primer parte del formulario antes del planificador
    const handleForm = (id, event) => {

        if (event?.value === undefined) { // Es un multiselect debido a que viene con un array
            setNewInfo(newInfo => { return { ...newInfo, info: { ...newInfo.info, [id]: event } } })


        } else { //Es un input normal que trae .value

            try { setNewInfo(newInfo => { return { ...newInfo, info: { ...newInfo.info, [id]: { label: event?.label, value: event?.id }, } } }) } catch (e) { console.log("PROBLEMAAASS", e) }

        }
    }


    //Handle que agrega los campos del planner con toda la lógica
    const handlePlanner = (id, event, action) => {
        // debugger;

        if (id == "needPlanner") {
            if (!newInfo.planner[id] === false) { refreshAllFields(id) } //si se va a apagar que ponga todos en ""
            // setPlanner(plannerI => { return { ...plannerI, [id]: !planner[id], } })

            setNewInfo(newInfo => { return { ...newInfo, planner: { ...newInfo.planner, [id]: !newInfo.planner[id], } } })


        } else if (id === "typeHours") {

            if (action == "add") {
                // if (!newInfo.planner[id].includes(event)) {

                //     // setPlanner(plannerI => { return { ...plannerI, [id]: [...planner[id], event].sort((x, y) => x.localeCompare(y)), } })

                //     setNewInfo(newInfo => {
                //         return {
                //             ...newInfo, planner: {
                //                 ...newInfo.planner,
                //                 [id]: [...newInfo.planner[id], event].sort((x, y) => x.localeCompare(y)),
                //             }
                //         }
                //     })
                // }

                console.log(id)
                console.log(event)
                console.log(action)


            } else if (action == "delete") {

                let aux = newInfo.planner[id /*"typeHours"*/].filter((hour) => hour != event).sort((x, y) => x.localeCompare(y));
                // setPlanner(plannerI => { return { ...plannerI, [id]: aux, } })

                setNewInfo(newInfo => { return { ...newInfo, planner: { ...newInfo.planner, [id]: aux, } } })


            }


        }

        else {
            if (event.value === undefined) { // Es un multiselect debido a que viene con un array
                // setPlanner(plannerI => { return { ...plannerI, [id]: event } })
                setNewInfo(newInfo => { return { ...newInfo, planner: { ...newInfo.planner, [id]: event, } } })

            } else { //Es un input normal que trae .value
                // setPlanner(plannerI => { return { ...plannerI, [id]: { label: event.label, value: event.id } } })


                setNewInfo(newInfo => { return { ...newInfo, planner: { ...newInfo.planner, [id]: { label: event.label, value: event.id }, } } })


            }

        }

        //Pone a los hijos en ""
        refreshClildFields(id);

    }
    
    //Efecto para alertar de si selecciona un día que no está en el mes se ejecutará el último de cada mes.
    useEffect(() => {
        if (newInfo?.planner?.typePlanner?.value == "3") {
            Toast.fire({
                title: "Información",
                html: `Si elige el día 31 y el mes tiene menos días, se ejecutará en el último día disponible del mes.`,
                type: 'info'
            });
        }
    }, [newInfo])

    //Pone en ""  los de planner los campos hijos que dependen del padre fieldId
    const refreshClildFields = (fieldId) => {

        const fieldsToRefresh = fieldsPlanner.filter(field =>
            field.idFieldDepends === fieldId
        )

        if (fieldsToRefresh.length > 0) {

            fieldsToRefresh.map(field => {
                // setPlanner(plannerI => { return { ...plannerI, [field.id]: "", } })
                setNewInfo(newInfo => { return { ...newInfo, planner: { ...newInfo.planner, [field.id]: "", } } })

            })

        }

    }

    //Pone a todos en "" los de planner excepto el principal needPlanner
    const refreshAllFields = (fieldIdMain) => {

        fieldsPlanner.map(field => {
            if (field.id != fieldIdMain) {
                // setPlanner(plannerI => { return { ...plannerI, [field.id]: "", } })
                setNewInfo(newInfo => { return { ...newInfo, planner: { ...newInfo.planner, [field.id]: "", } } })

            }
        })

    }

    //Handle para enviar la solicitud de creación del robot
    const handleCreateBot = () => {
        // alert("A crear!")

        if (validateForms()) {
            dispatch(newBot({ newInfo: newInfo, mode: mode, idBot: bot?.id })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    Toast.fire({
                        title: "Atención",
                        html: `Robot ${mode == "create" ? 'creado' : 'modificado'} con éxito!`,
                        type: 'success'
                    });

                    setNewInfo({
                        planner: { needPlanner: false },
                        info: {}
                    })

                    cleanForms();
                    setDeleteRef(true)

                    if (mode == "edit") {
                        handleCloseModal()
                    }


                } else {

                    console.log(payload.message);

                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrió un error, por favor reinicie la aplicación, si persiste contacte a Application Management.",
                        type: 'warning'
                    });
                };
            })
        }
    }

    //Método para validar si los campos están de todos los forms están vacíos o no.
    const validateForms = () => {
        let toReturn = true;

        //Contador de cuantos multiselect están vacíos
        let counterMultiselect = 0;

        let cont = 0;
        //#region Valida los Typeahead
        const forms = document.getElementsByClassName("rbt-input-main form-control rbt-input");

        Array.from(forms).forEach((input) => {
            if (input.defaultValue === "") {
                input.setAttribute("style", "border-color:#fb6340;")
                cont++
            } else { input.removeAttribute("style") }

        })
        //#endregion
        const forms2 = document.querySelectorAll(".formInfo input,Select, textarea")
        // console.log(forms2);
        Array.from(forms2).forEach((input) => {
            //Valide todos menos los LDRS.


            if (input.value === ""
                && input.classList[0] !== "rbt-input-hint" //Los typeahead no
                && input.classList[0] !== "react-ios-time-picker-input" //El timer no
                && input.id !== "needPlanner" //El toggle needPlanner no

            ) {
                //console.log(input)
                //if (input.id !== "react-select-2-input" && input.id !== "react-select-3-input"&& input.id !== "react-select-12-input") { //Ignorar los multiselect
                if (!(input.id).includes("react-select") && input.id != "") {
                    input.classList.add("is-invalid");
                    cont++;
                }
                //}
            }
            else {
                input.classList.remove("is-invalid");
            }

        });


        //#region Validaciones
        if (cont > 0) {
            toReturn = false;
            Toast.fire({
                title: "Atención",
                html: `Existen campos vacíos`,
                type: 'warning'
            });
        } else if
            (newInfo.planner.needPlanner === true &&
            parseInt(newInfo.planner.typePlanner.value) === 3 &&
            // (newInfo.planner.typeMonth === "" || newInfo.planner.typeMonth === [])
            (newInfo.planner.typeMonth === "")

        ) {

            Toast.fire({
                title: "Atención",
                html: `Debe llenar el multiselect de días al mes`,
                type: 'warning'
            });
            toReturn = false;

        } else if
            (newInfo.planner.needPlanner === true &&
            parseInt(newInfo.planner.typePlanner.value) === 2 &&
            // (newInfo.planner.typeWeek === "" || newInfo.planner.typeWeek === [])
            (newInfo.planner.typeWeek === "")

        ) {

            Toast.fire({
                title: "Atención",
                html: `Debe llenar el multiselect de días a la semana`,
                type: 'warning'
            });
            toReturn = false;

        } else if
            (newInfo.planner.needPlanner === true &&
            parseInt(newInfo.planner.typePlanner.value) !== 0 &&
            // (newInfo.planner.typeHours === "" || newInfo.planner.typeHours === [])
            (newInfo.planner.typeHours === "")

        ) {

            Toast.fire({
                title: "Atención",
                html: `Debe seleccionar al menos una hora en el día`,
                type: 'warning'
            });
            toReturn = false;

        } else if
            (parseInt(newInfo.info.manualTime) < parseInt(newInfo.info.automatedTime)) {

            Toast.fire({
                title: "Atención",
                html: `El tiempo manual debe ser mayor al automatizado, de lo contrario no es viable crear el bot`,
                type: 'warning'
            });
            toReturn = false;

        }

        //#endregion


        return toReturn;

    }

    //Limpia todos los campos de todos los forms y lo pone default.
    const cleanForms = (event) => {

        const forms = document.querySelectorAll(".formInfo input,Select, textarea");
        //reset campos normales
        Array.from(forms).forEach(input => (input.value = ""));

    }

    //funcion para cerrar modal
    const handleCloseModal = (() => {
        // setPage(1);
        setModal((prevState) => (!prevState.showModal));
        setRealTable((prevState) => (!prevState));


        setNewInfo({
            info: {},
            planner: { needPlanner: false }
        })



    });

    //#endregion



    return (
        <>
            <Modal isOpen={showModal} style={{ maxWidth: '600px', width: '100%' }} unmountOnClose={true} toggle={handleCloseModal}>
                <DrawerPlannerHelp
                    showPlannerHelp={showPlannerHelp}
                    setShowPlannerHelp={setShowPlannerHelp}
                />
                <Spin size="large" spinning={spinner && false}>
                    <ModalHeader className="d-flex justify-content-center mb--2">
                        {mode == "create" ? "Crear nuevo" : "Modificar"} robot {mode == "edit" && bot.class} - Databot
                    </ModalHeader>
                    <ModalBody className='lg-0 mb--3 pb-0'>
                        <Card style={{ borderColor: "black !important" }}>
                            <CardBody>
                                <Form noValidate className="needs-validation formInfo">
                                    <Row>

                                        {
                                            //#region Campos de la primera parte del formulario
                                            fields.map((row, key) => (
                                                <Col sm={row.colWidth} key={key}>
                                                    <FormGroup>
                                                        <label className="form-control-label">{row.label}</label>
                                                        {
                                                            row.elementType === "Input" ?
                                                                <>

                                                                    <Input
                                                                        key={row.id}
                                                                        id={row.id}
                                                                        name={row.id}
                                                                        className="form-control"
                                                                        type={row.type}
                                                                        readOnly={row.disabled}
                                                                        value={newInfo?.info[row.id]}
                                                                        placeholder={row.placeholder}
                                                                        onChange={(e) => handleForm(row.id, e.target.value)}
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Este campo no puede estar vacío
                                                                    </div>
                                                                </>
                                                                : row.elementType === "Typeahead" ?
                                                                    <>
                                                                        <Typeahead
                                                                            id={row.id + key}
                                                                            labelKey={employees => `${employees.name}`}
                                                                            minLength={0}
                                                                            required={row.required}
                                                                            options={employees}
                                                                            ref={refTypeahead}
                                                                            defaultSelected={(mode == "edit" && (newInfo?.info[row.id] != undefined)) ? [newInfo?.info[row.id]] : []}
                                                                            onChange={(e) => handleForm(row.id, e[0])}
                                                                            renderMenuItemChildren={(value) => (
                                                                                <div >
                                                                                    {value.name}
                                                                                    <div>
                                                                                        {
                                                                                            <small>Id: {value.UserID}</small>}
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Este campo no puede estar vacío
                                                                        </div>




                                                                    </>
                                                                    :
                                                                    <>

                                                                        <Input
                                                                            key={row.id}
                                                                            id={row.id}
                                                                            name={row.id}
                                                                            required={row.required}
                                                                            className="form-control"
                                                                            type={row.type}
                                                                            placeholder={row.placeholder}
                                                                            issearchable="true"
                                                                            classnameprefix="select"
                                                                            isdisabled={row.disabled}
                                                                            value={newInfo.info[row.id]?.label}

                                                                            onChange={(e) => handleForm(row.id, e.target.children[e.target.selectedIndex])}
                                                                        >
                                                                            <option id=""></option>
                                                                            {

                                                                                options[row.options] &&
                                                                                options[row.options].map((value, key) => {
                                                                                    return (
                                                                                        <option key={key} id={value.value}>{value.label}</option>
                                                                                    );
                                                                                })

                                                                            }
                                                                        </Input>
                                                                        <div className="invalid-feedback">
                                                                            Este campo no puede estar vacío
                                                                        </div>
                                                                    </>
                                                        }
                                                    </FormGroup>
                                                </Col>

                                            ))
                                            //#endregion
                                        }

                                        <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
                                            <label className="form-control-label">¿El robot necesita planificador?</label>

                                        </Col>

                                        <Col xs="12" className="mb-4" >
                                            <Row>
                                                <Col xs="5"></Col>
                                                <Col xs="4">
                                                    <label className="custom-toggle custom-toggle-success">
                                                        <input id="needPlanner" className='mb-2' type="checkbox" checked={newInfo.planner.needPlanner}
                                                            onChange={(e) => handlePlanner(e.target.id)}
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Si"></span>
                                                    </label>
                                                </Col>
                                                <Col xs="3" >
                                                    {newInfo.planner.needPlanner === true &&
                                                        <><Button
                                                            className="btn-info btn-round btn-icon"
                                                            color="default"
                                                            id="helpRequestBtn"
                                                            size="sm"
                                                            onClick={handleShowPlannerHelp}
                                                        >
                                                            <span className="btn-inner--icon mr-1">
                                                                <i className="fa fa-question-circle" />
                                                            </span>
                                                            <span className="btn-inner--text">Instrucciones</span>
                                                        </Button>
                                                            {/* <UncontrolledTooltip delay={0} zIndex={1060} fade={false} target="helpRequestBtn" placement="top" style={{ zIndex: 1600 }}
                                                            >
                                                                {messageHelpPlanner}
                                                            </UncontrolledTooltip> */}
                                                        </>
                                                    }

                                                </Col>


                                            </Row>
                                            <br></br>
                                        </Col>

                                        {newInfo.planner.needPlanner == true &&

                                            //#region Todos los campos para crear el planner
                                            fieldsPlanner.map((rowPlanner, keyPlanner) => (
                                                (rowPlanner.idFieldDepends === "" ||
                                                    (rowPlanner.valueThatDepends.includes(newInfo.planner[rowPlanner.idFieldDepends]?.label)))
                                                && (

                                                    rowPlanner.type === "Multiselect" ?
                                                        <Col sm={rowPlanner.width} className="" key={keyPlanner}>
                                                            <FormGroup key={keyPlanner}>
                                                                <label className="form-control-label">{rowPlanner.label}</label>


                                                                <Select
                                                                    key={rowPlanner.id}
                                                                    id={rowPlanner.id}
                                                                    required={rowPlanner.required}
                                                                    className="basic-single text-dark"
                                                                    type="Multiselect"
                                                                    isMulti
                                                                    isSearchable
                                                                    value={newInfo?.planner[rowPlanner.id]}
                                                                    onChange={(e) => handlePlanner(rowPlanner.id, e)}
                                                                    options={options[rowPlanner.options] &&
                                                                        options[rowPlanner.options].map((value, key) => {
                                                                            return (
                                                                                { value: value.value, label: value.label }
                                                                            );
                                                                        })}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    Este campo no puede estar vacío
                                                                </div>

                                                            </FormGroup>
                                                        </Col>

                                                        : rowPlanner.type === "TimePicker" ?
                                                            <Col sm={rowPlanner.width} className="d-flex" key={keyPlanner}>
                                                                <FormGroup key={keyPlanner}>
                                                                    <label className="form-control-label">{rowPlanner.label}</label>

                                                                    <Row className="">
                                                                        <Col xs="10" className="" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                            <TimePicker
                                                                                className={"time"}
                                                                                showSecond={false}
                                                                                onChange={(e) => handleTime("addTemporalTime", rowPlanner.id, e)}
                                                                                hideDisabledOptions
                                                                                minuteStep={5}
                                                                                value={temporalTime.moment}
                                                                                use24Hours
                                                                            />
                                                                        </Col>
                                                                        <Col xs="auto" className="p-0 " style={{ display: "flex", justifyContent: "flex-start" }}>
                                                                            <Button size="sm" className="btn-icon" color="success" block type="button"
                                                                                onClick={(e) => handleTime("addTime")}
                                                                            >
                                                                                <span className="btn-inner--icon mr-">
                                                                                    <i className="ni ni-fat-add" />
                                                                                </span>
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>




                                                                    <br></br>

                                                                    <label className="form-control-label">Horas seleccionadas: </label>

                                                                    <br></br>
                                                                    {newInfo.planner[rowPlanner.id /*"typeHours"*/] && newInfo.planner[rowPlanner.id/*"typeHours"*/]?.map((hour) => (

                                                                        <Badge color={'info'} pill onClick={(e) => handlePlanner(rowPlanner.id, hour, "delete")}>

                                                                            {hour + " "} <i className="fas fa-trash" />
                                                                        </Badge>
                                                                    ))}

                                                                </FormGroup>


                                                            </Col>

                                                            :
                                                            <Col sm={rowPlanner.width} key={keyPlanner}>
                                                                <FormGroup>
                                                                    <label className="form-control-label">{rowPlanner.label}</label>
                                                                    <Input
                                                                        id={rowPlanner.id}
                                                                        key={keyPlanner}
                                                                        required={true}
                                                                        className="form-control"
                                                                        type={"select"}
                                                                        value={newInfo?.planner[rowPlanner.id]?.label ? newInfo.planner[rowPlanner.id].label : ""}
                                                                        issearchable="true"
                                                                        classnameprefix="select"
                                                                        onChange={(e) => handlePlanner(rowPlanner.id, e.target.children[e.target.selectedIndex])}
                                                                    >
                                                                        <option id=""></option>
                                                                        {

                                                                            options[rowPlanner.options] &&
                                                                            options[rowPlanner.options].map((value, key) => {
                                                                                return (
                                                                                    <option key={key} id={value.value}>{value.label}</option>
                                                                                );
                                                                            })

                                                                        }
                                                                    </Input>
                                                                    <div className="invalid-feedback">
                                                                        Este campo no puede estar vacío
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>

                                                )

                                            ))
                                        }
                                    </Row>

                                </Form>


                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter className=" pt-0">
                        <Row>
                            <Col>
                                <Button size="sm" className="btn-icon" color="success" block type="button"
                                    onClick={(e) => handleCreateBot(e)}
                                >
                                    <span className="btn-inner--icon mr-">
                                        <i className="fas fa-check" />
                                    </span>
                                    <span className="btn-inner--text">
                                        {mode == "create" ? "Crear Robot" : "Modificar Robot"}
                                    </span>
                                </Button>
                            </Col>
                            <Col>
                                <Button size="sm" className="btn-icon" color="danger" block type="button"
                                    onClick={(e) => handleCloseModal()}
                                >
                                    <span className="btn-inner--icon mr-">
                                        <i className="ni ni-fat-remove" />
                                    </span>
                                    <span className="btn-inner--text">
                                        Cancelar
                                    </span>
                                </Button>
                            </Col>
                        </Row>


                    </ModalFooter>

                </Spin>
            </Modal>
        </>
    );
}

export default NewRobotModal;