/*!

=========================================================
*Autopp - GeneralInfoForm
=========================================================

* Este componente es la vista de Información General, el cual en su 
state newInfo almacena la información local, y cuando la bandera
readyToSend ubicada en CardSelectForm es true, almcena su info en el 
state principal editData.

*Además habilita o deshabilita el componente LDRSelectOption, dependiendo si
fue habilitado la opción GTL Quotation en ciclo de ventas.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerías de react.
import React, { useContext, useState, useEffect } from 'react'
import { Col, FormGroup, Input, Row, Form } from "reactstrap";

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de componentes 
import { LDRSelectOption } from "./LDRSelectOption";
import { options } from 'dropzone';

export const GeneralInfoForm = React.memo(({
  setToggleCheck,
  toggleCheck
}) => {

  //#region configuración del título
  const [state] = useState({
    title: "Información General",
    subtitle: "Ingrese por favor la información general de la oportunidad."
  });
  const { title, subtitle } = state;
  //#endregion

  //#region States Globales
  const {

    setResetSpecificStates,
    resetSpecificStates,
    dropDownOptions,
    setEditData,
    readyToSend,
    editData,
    setCycle,

    setGeneralInformationInfo,

  } = useContext(UserContext);
  //#endregion

  //#region States Locales

  //State Local de datos para cuando esté listo para enviar, lo agregue al state principal
  const [newInfo, setNewInfo] = useState("");

  //Lista de Fields visibles/invisibles dependientes del TypeOpportunity
  const [ListBlockTypeOpp, setListBlockTypeOpp] = useState({});

  const [enableLDROptions, setEnableLDROptions] = useState(false);

  //Realiza un formato de fecha acorde al campo Date
  const DateNowFormatToLabel = () => {
    let date = new Date();
    let DateBuilded = date.toISOString().split('T')[0]
    return DateBuilded;
  }

  const [fieldsForm, setFieldsForm] = useState([
    {
      colWidth: "6",
      label: "Tipo de oportunidad*",
      placeholder: "Seleccione el tipo de oportundidad",
      id: 'typeOpportunity',
      defaultValue: 'value="Standard" label="Standard"',
      type: "select",
      disabled: false,
      visibleDefault: true,
      LockForTypeOpp: false,
      required: true
    },
    {
      colWidth: "6",
      label: "Descripción de la oportunidad*",
      placeholder: "Detalle una descripción",
      id: "description",
      type: "text",
      disabled: false,
      visibleDefault: true,
      LockForTypeOpp: false,
      required: true
    },
    {
      colWidth: "6",
      label: "Tipo de venta*",
      placeholder: "Seleccione el tipo de venta",
      id: "salesType",
      type: "select",
      disabled: false,
      visibleDefault: false,
      LockForTypeOpp: false,
      required: true
    },
    {
      colWidth: "6",
      label: "Outsourcing*",
      placeholder: "Seleccione si necesita outsourcing",
      id: "outsourcing",
      type: "select",
      disabled: false,
      visibleDefault: false,
      LockForTypeOpp: true,
      required: true
    },
    {
      colWidth: "6",
      label: "Fecha de inicio*",
      id: "initialDate",
      placeholder: "",
      type: "date",
      defaultValue: DateNowFormatToLabel(),
      disabled: true,
      visibleDefault: true,
      LockForTypeOpp: false,
      required: true
    },
    {
      colWidth: "6",
      label: "Fecha de cierre*",
      placeholder: "Seleccione la fecha de cierre",
      id: "finalDate",
      type: "date",
      disabled: false,
      visibleDefault: true,
      LockForTypeOpp: false,
      required: true
    },
    {
      colWidth: "6",
      label: "Ciclo de ventas*",
      placeholder: "Seleccione el ciclo de ventas",
      id: "cycle",
      type: "select",
      disabled: false,
      visibleDefault: true,
      LockForTypeOpp: false,
      required: true
    },
    {
      colWidth: "6",
      label: "Origen de la oportunidad*",
      placeholder: "Seleccione el origen de la oportunidad",
      id: "sourceOpportunity",
      type: "select",
      disabled: false,
      visibleDefault: true,
      LockForTypeOpp: false,
      required: true
    },
  ]);
  //#endregion

  //#region Effects
  //Cuando esté listo para enviar, que lo agregue al stateprincipal editData.
  useEffect(() => {
    if (readyToSend == true) {

      // Object.assign(editData, newInfo)
      setGeneralInformationInfo(newInfo)
    }

  }, [readyToSend])

  //Establece una lista con los elementos a bloquear y desbloquear por Type Opportunity.
  useEffect(() => {
    const filter = fieldsForm.filter(e =>
      e["LockForTypeOpp"] === true
    ).map((e) => e.id);
    setListBlockTypeOpp(filter);
  }, [])

  //Establece la fecha inicial al arrancar el componente.
  useEffect(() => {

    setEditData(prevState => ({
      ...prevState, ["initialDate"]: DateNowFormatToLabel()
    }))
  }, [])

  //Cuando se envia una solicitud hace un reset y establece los toggles por defecto 
  useEffect(() => {

    if (resetSpecificStates === true) {

      setResetSpecificStates(false);
      setEnableLDROptions(prevState => {
        return false;

      });

      setToggleCheck(prevState => {
        return { "applyLDROption": false, "applyBOMPNOption": false };
      });

    }

  }, [resetSpecificStates])

  //#endregion

  //#region Handles y métodos del componente

  //Método para averiguar si un elemento de fieldsForm está bloqueado en el campo LockForTypeOpp por Tipo de oportunidad.
  const beFieldInBlockList = (nameField) => {
    const filter = ListBlockTypeOpp.filter((blockField) => blockField === nameField)
    if (filter.length > 0) { return true } else { return false };
  }

  //Método encargado de habilitar y deshabilitar campos en el form según reglas como por ejemplo LockForTypeOpp: true de fieldsForm    
  const enableAndDisablesFields = (e) => {

    if (e["label"] === "Standard" ||
      e["label"] === "Proyectos" ||
      e["label"] === "Simple"
    ) {

      //Si selecciona nuevamente Standard, elimina los campos de EditData que ahora no estan habilitados.
      try {
        ListBlockTypeOpp.map((value) => (delete newInfo[value]))
      } catch (e) { }


      const filter = fieldsForm.map((field) => {

        if (beFieldInBlockList(field["id"])) {
          return {
            ...field, LockForTypeOpp: true
          }
        } else { return field };
      })
      setFieldsForm(filter);
    }
    if (e["label"] === "Multiservicios") {
      const filter = fieldsForm.map((field) => {

        if (beFieldInBlockList(field["id"])) {
          return {
            ...field, LockForTypeOpp: false
          }
        } else { return field };
      })
      setFieldsForm(filter);
    }
  }

  //Habilita campos, asigna información al editData principal conforme a las selecciones.
  const handleChangeInfo = (constant, value, label, f) => {

    //Habilita campos conforme al typeoportunity
    enableAndDisablesFields({ label: label, value: value });

    if (constant === "cycle") {

      //Este state se comunica con CardSelectForm
      setCycle(value);

      // let haveLDR = dropDownOptions.cycle.filter((cycleO) => cycleO.value == value)[0].haveLDR
      let applyLDR = dropDownOptions.cycle.filter((cycleO) => cycleO.value == value)[0]?.applyLDR

      // if (haveLDR) { //Si el ciclo está en algún registro del LDR Brand, o sea tiene LDR.
      if (applyLDR) { //los que tienen la bandera de apply LDR.

        setEnableLDROptions(true);
        setToggleCheck({ "applyLDROption": true, "applyBOMPNOption": false });

      } else {//Reset a LDRMainForm y a sus toggles

        setEnableLDROptions(false);
        setToggleCheck({ "applyLDROption": false, "applyBOMPNOption": false })

      }
    }
    //insert general de todos los campos
    setNewInfo(prevState => ({
      ...prevState,
      //[constant]: value
      [constant]: value.replace("'", "").replace('"', "") //Para que el caracter ' no de problema en el insert API

    }))
  }

  //#endregion

  return (

    <>
      <Row className="mb-4">

        <Col xs="12" sm='6'>
          <h3 className="mb-0">{title}</h3>
          <p className="text-sm mb-0">{subtitle}</p>

        </Col>

      </Row>


      <Form noValidate className="formInfo needs-validation">

        <Row className="">
          {fieldsForm.map((row, key) =>
            row.type === "text" ? (
              <Col xs="12" sm={row.colWidth} key={key}>
                <FormGroup key={key}>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    {`${row.label}`}
                  </label>
                  <Input
                    key={key}
                    id={row.id}
                    name={row.id}
                    required={row.required}
                    placeholder={row.placeholder}
                    className="form-control text-dark"
                    readOnly={row.disabled}
                    onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                    type={row.type}
                  />
                  <div className="invalid-feedback">
                    Este campo no puede estar vacío
                  </div>
                </FormGroup>
              </Col>
            ) : row.type === "date" ? (
              <Col xs="12" sm={row.colWidth} key={key}>
                <FormGroup key={key}>
                  <label

                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    {`${row.label}`}
                  </label>
                  <Input
                    key={key}
                    id={row.id}
                    name={row.id}
                    required={row.required}
                    className="basic-multi-select text-dark"
                    min={new Date().toISOString().split('T')[0]}
                    type={row.type}
                    placeholder={row.placeholder}
                    value={row.defaultValue}
                    disabled={row.disabled}
                    onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                  ></Input>
                  <div className="invalid-feedback">
                    No puede estar vacía o menor a la fecha actual
                  </div>

                </FormGroup>
              </Col>
            ) : (
              //No están bloqueadas por Tipo de Oportunidad
              !row.LockForTypeOpp && (
                <Col xs="12" sm={row.colWidth} key={key}>
                  <FormGroup>
                    <label

                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {row.label}
                    </label>

                    <Input
                      id={row.id}
                      key={key}
                      name={row.id}
                      required={row.required}
                      className="basic-multi-select text-dark"
                      type={row.type}
                      placeholder={row.placeholder}
                      disabled={row.disabled}
                      onChange={(e) => handleChangeInfo(row.id, e.target.children[e.target.selectedIndex].id, e.target.children[e.target.selectedIndex].value /*row.defaultValueLabel, e.target.children[e.target.selectedIndex].value)*/)}
                    >
                      <option id=""></option>
                      {
                        dropDownOptions[row.id] && dropDownOptions[row.id].map((value) => {
                          return (
                            <option style={{ minHeight: "200px" }} id={value.value}>{value.label}</option>
                          );
                        })}
                    </Input>
                    <div className="invalid-feedback">
                      Este campo no puede estar vacío
                    </div>

                  </FormGroup>
                </Col>
              )
            )
          )}
        </Row>
      </Form>


      {/*JSON.stringify(toggleCheck)*/}



      {enableLDROptions &&

        <LDRSelectOption
          toggleCheck={toggleCheck}
          setToggleCheck={setToggleCheck}
          editData={editData}
          setEditData={setEditData}
          cycle={newInfo?.cycle}
        />
      }

    </>
  )
})

export default GeneralInfoForm;
