import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";

const DigitalFormButtons = ({ setActiveTab, setLoad, setInfo }) => {
  const changeTab = (num) => {
    setActiveTab(num);
    setInfo("");
    setInfo({ date: new Date().toISOString().split('T')[0] });
  }
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col sm="8" md="6">
              <h3 className="mb-0">Nueva Entrevista de Salida</h3>
              <p className="text-sm mb-0">
                Ingrese los datos para crear una nueva entrevista de Salida
              </p>
            </Col>
            <Col xs="10"
              md="2"
              className="p-1"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Button
                className="btn btn-icon"
                color="success"
                block
                type="button"
                onClick={() => changeTab(1)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-copy"></i>
                </span>
                <span className="btn-inner--text">Nuevo</span>
              </Button>
            </Col>
            <Col
              xs="10"
              md="2"
              className="p-1"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Button
                className="btn-icon"
                color="primary"
                block
                type="button"
                onClick={() => changeTab(2)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-archive" />
                </span>
                <span className="btn-inner--text">Guardado</span>
              </Button>
            </Col>
            <Col
              xs="10"
              md="2"
              className="p-1"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Button
                className="btn-icon"
                color="secondary"
                block
                type="button"
                onClick={() => changeTab(3)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-eraser" />
                </span>
                <span className="btn-inner--text">Borradores</span>
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default DigitalFormButtons;
