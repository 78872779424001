/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Button, Card, CardFooter, CardHeader, Col, FormGroup, Input, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";

const ServicesManagements = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    page,
    search,
    onSearch,
    statusFlag,
    sizePerPage,
    onSetPage,
    onChangeView,
    onAddService,
    onEditService,
    onDeleteService,
    onUploadService,
    onRelaunchService
  } = props;

  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1;

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => onSetPage(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="12" md='7'>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0 mt-1">{subtitle}</h5>
          </Col>
          <Col xs="12" md='5' style={{ display: "grid", justifyContent: "flex-end" }}>
            <div className='d-flex justify-content-center mt-2'>
              <div className="custom-control custom-radio custom-control-inline">
                <Button
                  className="btn-icon"
                  color="success"
                  size="sm"
                  block
                  type="button"
                  onClick={onAddService}
                >
                  <span className="btn-inner--icon mr-">
                    <i className="fas fa-folder-plus" />
                  </span>
                  <span className="btn-inner--text">
                    Agregar Nuevo
                  </span>
                </Button>
              </div>
              {/* <div className="custom-control custom-radio custom-control-inline">
                <Button
                  className="btn-icon"
                  color="info"
                  size="sm"
                  block
                  type="button"
                  onClick={onUploadService}
                >
                  <span className="btn-inner--icon mr-">
                    <i className="fas fa-upload" />
                  </span>
                  <span className="btn-inner--text">
                    Cargar Archivo
                  </span>
                </Button>
              </div> */}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center mt-2">
          <Col md="6">
            <div className="d-flex justify-content-center">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="viewActive"
                  type="radio"
                  checked={statusFlag}
                  onChange={onChangeView}
                />
                <label
                  className="custom-control-label"
                  htmlFor="viewActive"
                >
                  Activos
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="viewBlocked"
                  type="radio"
                  checked={!statusFlag}
                  onChange={onChangeView}
                />
                <label
                  className="custom-control-label"
                  htmlFor="viewBlocked"
                >
                  Bloqueados
                </label>
              </div>
            </div>
          </Col>
          <Col className="text-right" xs="12" md="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="search"
              >
                Buscar
              </label>
              <Input
                id="search"
                className="m-0"
                style={{ height: "calc(2.25rem)" }}
                placeholder="Ingrese el valor a buscar"
                type="text"
                value={search}
                onChange={(e) => onSearch(e)}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {
              cols.map((row, key) => (<th key={key}>{row.name}</th>))
            }
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.slice((page - 1) * sizePerPage, ((page - 1) * sizePerPage) + sizePerPage).map((row, key) => (
              <tr key={key}>
                {
                  cols.map((col, key) => (
                    <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {
                        (col.key === 'services' || col.key === 'countries') ?
                          row[col.key].map((row) =>
                            <Badge color="info">{row.name}</Badge>
                          )
                          :
                          row[col.key]}
                    </th>
                  ))
                }
                {
                  statusFlag ?
                    <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <>
                        <a
                          className="table-action table-action"
                          href="#"
                          onClick={() => onEditService(row)}
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          className="table-action table-action"
                          href="#"
                          onClick={() => onDeleteService(row)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                      </>
                    </th>
                    :
                    <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <a
                        className="table-action table-action"
                        href="#"
                        onClick={() => onRelaunchService(row)}
                      >
                        <i className="fas fa-sync" />
                      </a>
                    </th>
                }
              </tr>
            ))
          }
        </tbody>
      </Table>
      <CardFooter className="py-4">
        <nav aria-label="...">
          <Row className="align-items-center">
            <Col xs='12' md='6' className="p-1">
              <span className='pagination mb-0'>
                Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
              </span>
            </Col>
            <Col xs='12' md='6' className="p-1">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={page === 1 ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => onSetPage(page === 1 ? page : page - 1)}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {
                  renderPaginations()
                }
                <PaginationItem className={page === paginations ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => onSetPage(page === paginations ? page : page + 1)}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        </nav>
      </CardFooter>
    </Card>
  );
};

ServicesManagements.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
};

export default ServicesManagements;