import React from "react";
import {
  Col,
  Row,
  Input,
  Table,
  Card,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { downloadAttachmentByPath } from "actions/financialFlows";
import CardHeader from "reactstrap/lib/CardHeader";
import Select from "react-select";
import { WeuiBackOutlined } from "../Shared/Icons/iconiFy";
import { useDispatch } from "react-redux";
import { TablerPoint } from "../Shared/Icons/iconiFy";

const Details = ({
  detailsData,
  disabled,
  title,
  subtitle,
  handleReturn,
  files,
  cancelPurchaseOrder,
  savePurchaseOrder,
  handleShowApproveOption,
  handleShowCancelOption,
  newInfo,
  showSaveOptions,
  showCancelOptions,
  handleOnChangeInfo,
  isComplete,
  isButtonConfirm,
  isButtonCancel,
  addSo,
  deleteSo,
  role,
}) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const dispatch = useDispatch();

  // Funcion que permite descargar los archivos adjuntos de la solicitud.
  const downloadDocumentAttachment = (requestFileId, filename) => {
    dispatch(downloadAttachmentByPath(window.btoa(requestFileId))).then(
      (resp) => {
        const { payload } = resp;
        if (payload.status === 200) {
          const url = window.URL.createObjectURL(new Blob([payload.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}`);
          document.body.appendChild(link);
          link.click();
        } else {
        }
      }
    );
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <Row
            className="justify-content-between"
            style={{ marginRight: "2vh" }}
          >
            <Col>
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            <Col sm="12" md="2">
              <Button
                className="btn-icon sm"
                color="dark"
                block
                type="button"
                onClick={() => handleReturn()}
              >
                <span
                  className="btn-inner--icon mr-1"
                  style={{ whiteSpace: "normal", textAlign: "justify" }}
                >
                  <WeuiBackOutlined />
                </span>
                Regresar
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {detailsData.map((item, key) => (
            <Row className="mt-3" key={key}>
              {item.typeUnitType === "select" ? (
                <>
                  <Col xs="12" md="4" key={key}>
                    {" "}
                    {`${item.UnitTypeName}`}:
                  </Col>
                  <Col xs="12" md="8" key={`colSelect${key}`}>
                    <Select
                      isDisabled={disabled}
                      key={key}
                      className="basic-single is-invalid"
                      type="select"
                      options={item.options}
                      value={item.options[item.value]}
                    />
                  </Col>
                </>
              ) : item.typeUnitType === "money" ? (
                <>
                  <Col key={key} xs="12" md="4">
                    {item.UnitTypeName}:
                  </Col>
                  <Col xs="12" md="4" key={`colSelect${key}`}>
                    <Input
                      disabled={disabled}
                      key={key}
                      className="m-0"
                      type={"number"}
                      defaultValue={item.value}
                    />
                  </Col>
                  <Col xs="12" md="4" key={`colSelect2${key}`}>
                    <Input
                      disabled={true}
                      key={key}
                      className="m-0"
                      type={"text"}
                      value={formatter.format(item.value)}
                    />
                  </Col>
                </>
              ) : item.typeUnitType === "table" ? (
                <>
                  <Col xs="8" md="4" key={key}>
                    {" "}
                    {item.UnitTypeName}:
                  </Col>
                  <Col xs="12" md="7" key={`colTable${key}`}>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                      striped={true}
                      hover={true}
                    >
                      <thead className="thead-light ">
                        <tr>
                          {item.options.map((element, key) => {
                            return <th key={key}>{element.label}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody className="list">
                        {item.value.map((information, key) => (
                          <tr key={`tr${key}$`}>
                            {item.options.map((element, key2) => (
                              <td key={key2}>
                                <Input
                                  key={`input${key2}`}
                                  disabled={disabled}
                                  defaultValue={information[element.label]}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </>
              ) : (
                <>
                  <Col key={key} xs="12" md="4">
                    {`${item.UnitTypeName}`}:
                  </Col>
                  <Col xs="12" md="8" key={`colSelect${key}`}>
                    <Input
                      disabled={disabled}
                      key={key}
                      className="m-0"
                      type={item.typeUnitType}
                      defaultValue={item.value}
                    />
                  </Col>
                </>
              )}
            </Row>
          ))}
        </CardBody>
      </Card>
      <Row>
        <Col xs="12" xl="6">
          <Card>
            <CardHeader>
              <Row
                className="justify-content-between"
                style={{ marginRight: "2vh" }}
              >
                <Col>
                  <h3 className="mb-0">Archivos</h3>
                  <p className="text-sm mb-0">Documentos de la solicitud</p>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="align-items-center">
                <Col xs="4">
                  <ListGroup className="list my--3" flush>
                    {files && files.length === 0 ? (
                      <>
                        <h5>Sin archivos adjuntos</h5>
                      </>
                    ) : (
                      <>
                        {files.map((file, key) => (
                          <ListGroupItem className="px-0" key={key}>
                            <Row>
                              <Col>{file.filename}</Col>
                              <Col xs="4">
                                <div className="text-right">
                                  <Button
                                    className="btn-icon btn-xs"
                                    color="success"
                                    onClick={() =>
                                      downloadDocumentAttachment(
                                        file.id,
                                        file.filename
                                      )
                                    }
                                    size="sm"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-download" />
                                    </span>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        ))}
                      </>
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        {role === "BS Admin" && (
          <Col xs="12" xl="6">
            <Card>
              <CardHeader>
                <Row
                  className="justify-content-between"
                  style={{ marginRight: "2vh" }}
                >
                  <Col>
                    <h3 className="mb-0">Acciones</h3>
                    <p className="text-sm mb-0">Flujo de aprobación</p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {!showCancelOptions && !showSaveOptions && (
                  <>
                    <Row>
                      <Col xs="12" md="6" xl="12">
                        <Button
                          className="btn  btn-icon"
                          color="danger"
                          block
                          type="button"
                          onClick={() => handleShowCancelOption()}
                          disabled={isComplete}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-times"></i>
                          </span>
                          <span className="btn-inner--text">
                            Rechazar solicitud
                          </span>
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col xs="12" md="6" xl="12">
                        <Button
                          className="btn-icon"
                          color="success"
                          block
                          type="button"
                          onClick={() => handleShowApproveOption()}
                          disabled={isComplete}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-save"></i>
                          </span>
                          <span className="btn-inner--text">
                            Aprobar solicitud
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                {showSaveOptions && (
                  <>
                    <Row>
                      <Col xs="12" md="6" xl="12" className="mt-1">
                        <h5>Comentario:</h5>
                        <Input
                          className="m-0"
                          type={"text"}
                          defaultValue={newInfo.comment}
                          onChange={(e) =>
                            handleOnChangeInfo("comment", e.target.value)
                          }
                        />
                      </Col>
                      <Col xs="8" md="8" xl="8" className="mt-1">
                        <h5>SO:</h5>
                        <Input
                          className="m-0"
                          type={"text"}
                          value={newInfo.SO}
                          onChange={(e) =>
                            handleOnChangeInfo("SO", e.target.value)
                          }
                        />
                      </Col>
                      <Col xs="4" xl="4">
                        <Button
                          className="btn-icon"
                          color="primary"
                          block
                          type="button"
                          onClick={() => addSo(newInfo.SO)}
                          style={{ marginTop: "30px" }}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-plus"></i>
                          </span>
                          <span className="btn-inner--text">Agregar</span>
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="12" xl="12" className="mr-5">
                        <h5>Lista SO`s:</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="12" xl="12" className="mt-10">
                        {newInfo.SOList.length !== 0 &&
                          newInfo.SOList.map((item, key) => (
                            <React.Fragment key={key}>
                              <Row>
                                <Col xs="8">
                                  <TablerPoint /> {item}
                                </Col>
                                <Col xs="4">
                                  <div className="text-right">
                                    <Button
                                      className="btn-icon btn-xs"
                                      color="danger"
                                      onClick={() => deleteSo(key)}
                                      size="sm"
                                    >
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-times" />
                                      </span>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          ))}
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col xs="12" xl="6">
                        <Button
                          className="btn-icon"
                          color="danger"
                          block
                          type="button"
                          onClick={() => handleShowApproveOption()}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-times"></i>
                          </span>
                          <span className="btn-inner--text">Cancelar</span>
                        </Button>
                      </Col>

                      <Col xs="12" xl="6">
                        <Button
                          className="btn-icon"
                          color="success"
                          block
                          type="button"
                          onClick={() => savePurchaseOrder()}
                          disabled={isButtonConfirm}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-save"></i>
                          </span>
                          <span className="btn-inner--text">Confirmar</span>
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}

                {showCancelOptions && (
                  <>
                    <Row>
                      <Col xs="12" md="6" xl="12" className="mt-1">
                        <h5>Comentario:</h5>
                        <Input
                          className="m-0"
                          type={"text"}
                          defaultValue={newInfo.comment}
                          onChange={(e) =>
                            handleOnChangeInfo("comment", e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col xs="12" xl="6">
                        <Button
                          className="btn-icon"
                          color="danger"
                          block
                          type="button"
                          onClick={() => handleShowCancelOption()}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-times"></i>
                          </span>
                          <span className="btn-inner--text">Cancelar</span>
                        </Button>
                      </Col>
                      <Col xs="12" xl="6">
                        <Button
                          className="btn-icon"
                          color="success"
                          block
                          type="button"
                          onClick={() => cancelPurchaseOrder()}
                          disabled={isButtonCancel}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-save"></i>
                          </span>
                          <span className="btn-inner--text">Rechazar</span>
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Details;
