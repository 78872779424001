import React, { Component } from 'react';
// antd components
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import { Col, Container, Row, Card, CardBody, CardTitle } from "reactstrap";
import Charts from "components/Sales/Dashboard/Charts.jsx";
import { getAllCountries, getAllYears, dataRequestDashboardContracts, getAllInformationCards } from "selectors/Sales/managers.jsx";
import { findAllInformationCardsChart, findAllInformationYearAndContries } from 'actions/Sales/managers.jsx';
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import moment from "moment";

class contractDashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryType: { label: "Todos", value: "NA" },
            yearType: { label: moment().format("YYYY"), value: moment().format("YYYY") },
            page: false,
            loading: false,
            message: null
        };
    };

    componentDidMount() {
        this.findAllDataDashboard(this.state.countryType.value, this.state.yearType.value);
        this.props.findAllInformationYearAndContries();
    }
    findAllDataDashboard = (country, year) => {
        this.setState({
            loading: true,
            message: "Cargando el dashboard de Requerimientos"
        });
        this.props.findAllInformationCardsChart(
            year,
            country
        ).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "Atención", payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            }
            this.setState({
                loading: false,
                message: null
            });
        });
    };
    notify = (type, title, message) => {
        let options = {
            place: "br",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {title}
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (Object.keys(this.refs).length)
            if (this.props.history.location.pathname === this.props.location.pathname)
                this.refs.notificationAlert.notificationAlert(options);
    };
    handleOnChangeFilters = (id, value) => {
        this.setState({
            [id]: value,
        });
        if (id === "countryType") {
            this.findAllDataDashboard(value.value, this.state.yearType.value);
        } else if (id === "yearType") {
            this.findAllDataDashboard(this.state.countryType.value, value.value);
        }
    };
    render() {
        const {
            allDashboardInformation,
            allCountries,
            allYears,
            allInformationCards
        } = this.props
        const {
            countryType,
            yearType,
            loading,
            message
        } = this.state;
        return (
            <div>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert" />
                </div>

                <AdminHeader
                    name="Dashboard"
                    parentName="Dashboard de Contratos"
                />
                <Spin size="large" spinning={loading} tip={message}>

                    <Container className="mt--6" fluid>
                        <Row className="justify-content-end" >
                            <Col md="6" xl="2">
                                <Select
                                    className="basic-single"
                                    id="countryType"
                                    type="select"
                                    isSearchable
                                    options={allCountries}
                                    value={countryType}
                                    onChange={(e) => this.handleOnChangeFilters("countryType", e)}
                                />
                            </Col>
                            <Col md="6" xl="2">
                                <Select
                                    className="basic-single"
                                    id="yearType"
                                    type="select"
                                    isSearchable
                                    options={allYears}
                                    value={yearType}
                                    onChange={(e) => this.handleOnChangeFilters("yearType", e)}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="6" xl="4">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Requerimientos creados
                                            </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {allInformationCards.amountRequest.total}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                    <i className="fas fa-globe" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-success mr-2">
                                                <i className="fas fa-clock" />
                                                {" " + allInformationCards.amountRequestMonth.total}
                                            </span>{" "}
                                            <span className="text-nowrap">En el presente mes</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="4">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Ofertas ganadas
                                            </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {allInformationCards.amountWon.total}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="fas fa-check" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-success mr-2">
                                                <i className="fas fa-clock" />
                                                {" " + allInformationCards.amountWonMonth.total}
                                            </span>{" "}
                                            <span className="text-nowrap">En el presente mes</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="4">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Ofertas no consideradas
                                            </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {allInformationCards.amountReject.total}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="fas fa-times" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-success mr-2">
                                                <i className="fas fa-clock" />
                                                {" " + allInformationCards.amountRejectMonth.total}
                                            </span>{" "}
                                            <span className="text-nowrap">En el presente mes</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <Row>
                                <Col xs="12"
                                    md="12">
                                    <Charts bar
                                        object={allDashboardInformation}
                                        subtitle="Gráfico anual de los requerimientos" 
                                        title="Requerimientos Digitales"/>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Spin>
            </div>
        );
    }
}
contractDashBoard.defaultProps = {
    allInformationCards: {
        amountRequest: {
            total: 0
        },
        amountRequestMonth: {
            total: 0
        },
        amountWon: {
            total: 0
        },
        amountWonMonth: {
            total: 0
        },
        amountReject: {
            total: 0
        },
        amountRejectMonth: {
            total: 0
        },
    }
}
contractDashBoard.propTypes = {
    name: PropTypes.string.isRequired,
    parentName: PropTypes.string.isRequired,
    allCountries: PropTypes.array.isRequired,
    allYears: PropTypes.array.isRequired,
    allDashboardInformation: PropTypes.object.isRequired,
    allInformationCards: PropTypes.object.isRequired
};
//selector
const mapStateToProps = (state) => ({
    allCountries: getAllCountries(state),
    allYears: getAllYears(state),
    allDashboardInformation: dataRequestDashboardContracts(state),
    allInformationCards: getAllInformationCards(state)
});
//actions
export default withRouter(
    connect(mapStateToProps, {
        findAllInformationCardsChart,
        findAllInformationYearAndContries
    })(contractDashBoard)
);