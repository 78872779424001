// antd components
import { Spin } from "antd";
// core actions
import {
  findAllUsersMaintenance,
  validateUserInSystemByUsername,
  createUserInCriticalPartsAdminBySignID,
  deactivateUserCriticalPartsByID,
} from "actions/Sales/criticalParts.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useState, useEffect, useRef } from "react";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
// core components
import AddUserModal from "components/CriticalParts/AddUserModal.jsx";
import UserMaintenance from "components/CriticalParts/UserMaintenance.jsx";
// react-redux library for conect redux
import { useDispatch } from "react-redux";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

const UsersMaintenance = (props) => {
  const { name, parentName, cols } = props;
  const notifyEl = useRef(null);
  const dispatch = useDispatch();
  const [spinnerInfo, setSpinnerInfo] = useState({
    loading: false,
    message: null,
  });
  const [users, setUsers] = useState([]);
  const [willMount, setWillMount] = useState(true);
  const [modalAddUser, setModalAddUser] = useState(false);
  const [alert, setAlert] = useState(null);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    handleOnGetMainInfo();
  }, []);

  const notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notifyEl.current.notificationAlert(options);
  };

  const handleOnGetMainInfo = async () => {
    dispatch(findAllUsersMaintenance()).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          notify("warning", "Atención", payload.message);
        } else {
          notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        setUsers(payload.users);
        if (willMount) notify("success", "Atención", payload.message);
      }
      setWillMount(false);
    });
  };

  const handleOnToggleAddUser = () => {
    setModalAddUser(!modalAddUser);
    setUserInfo({});
  };

  const onDeleteUserByID = async (id) => {
    setAlert(null);
    setSpinnerInfo({
      loading: true,
      message: "Eliminando el usuario seleccionado",
    });
    dispatch(deactivateUserCriticalPartsByID(id)).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          notify("warning", "Atención", payload.message);
        } else {
          notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        notify("success", "Atención", payload.message);
        handleOnGetMainInfo();
      }
      setSpinnerInfo({
        loading: false,
        message: null,
      });
    });
  };

  const handleOnDeleteUser = (row) => {
    setAlert(
      <ReactBSAlert
        custom
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Está seguro de eliminar el usuario?"
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        onConfirm={() => onDeleteUserByID(row.id)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sí, eliminar"
        cancelBtnText="No, cancelar"
        btnSize="md"
      />
    );
  };

  const handleOnValidateUserInformation = async (values) => {
    console.log(values);
    setSpinnerInfo({
      loading: true,
      message: "Validando el usuario",
    });
    dispatch(validateUserInSystemByUsername(values)).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          notify("warning", "Atención", payload.message);
        } else {
          notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        console.log(payload.user);
        setUserInfo(payload.user);
        notify("success", "Atención", payload.message);
      }
      setSpinnerInfo({
        loading: false,
        message: null,
      });
    });
  };

  const handleOnWorkFlowAddUserCriticalParts = async (values) => {
    setSpinnerInfo({
      loading: true,
      message: "Creando el usuario en el sistema",
    });
    dispatch(createUserInCriticalPartsAdminBySignID(values.id)).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          notify("warning", "Atención", payload.message);
        } else {
          notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        handleOnGetMainInfo();
        notify("success", "Atención", payload.message);
      }
      handleOnToggleAddUser();
      setSpinnerInfo({
        loading: false,
        message: null,
      });
    });
  };

  return (
    <>
      {alert}
      <AddUserModal
        title="Creación de acceso usuario partes críticas"
        showModal={modalAddUser}
        initialValues={userInfo}
        spinnerInfo={spinnerInfo}
        toggleModal={handleOnToggleAddUser}
        onValidate={handleOnValidateUserInformation}
        onWorkFlow={handleOnWorkFlowAddUserCriticalParts}
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyEl} />
      </div>
      <AdminHeader name={name} parentName={parentName} />
      <Container className="mt--6" fluid>
        <Spin
          size="large"
          spinning={spinnerInfo.loading}
          tip={spinnerInfo.message}
        >
          {willMount ? (
            <Row className="justify-content-center">
              <Col>
                <CardLoading title="Cargando la lista de usuarios en el sistema" />
              </Col>
            </Row>
          ) : (
            <UserMaintenance
              title="Usuarios Partes Críticas"
              subtitle="Mantenimiento de usuarios de administración Partes Críticas"
              cols={cols}
              rows={users}
              addUser={handleOnToggleAddUser}
              deleteUser={handleOnDeleteUser}
            />
          )}
        </Spin>
      </Container>
    </>
  );
};

UsersMaintenance.defaultProps = {
  name: "Mantenimiento Usuarios",
  parentName: "Partes Críticas",
  cols: [
    { key: "UserID", name: "ID Colaborador" },
    { key: "username", name: "Usuario" },
    { key: "name", name: "Nombre Completo" },
    { key: "position", name: "Posición" },
    { key: "email", name: "Correo Electrónico" },
    { key: "country", name: "País" },
    { key: "createdAt", name: "Fecha Creación" },
  ],
};

UsersMaintenance.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
};

export default UsersMaintenance;
