/*!

=========================================================
*Inventario No Validado (TOMAS FÍSICAS)
=========================================================

*Componente para verificar los inventarios no / valorados
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

import React, { useState, useContext } from "react";
// reactstrap components
import {
    InputGroupAddon,
    InputGroup,
    FormGroup,
    Button,
    Input,
    Label,
    Row,
    Col,
} from "reactstrap";

//Componente para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Componente local
import ModalInfoInventoryAndInquiries from "../SharedComponents/ModalInfoInventoryAndInquiries.jsx";

import { useDispatch } from 'react-redux';

//Imports de actions de consultas al API.
import {
    createNewDocumentInventoryNoValidated,
    executeConfirmNoValidated
} from 'actions/wms';

//Button geist
import { Button as ButtonGeist } from "@geist-ui/react";

//#region icons
import { MdWarehouse } from "react-icons/md";
import { FaMapMarkerAlt, FaBoxes } from "react-icons/fa";
import { TbStatusChange } from "react-icons/tb";
import { IoDocumentText } from "react-icons/io5";
import { PiBarcode } from "react-icons/pi";
import { IoIosSend } from "react-icons/io";
//#endregion

import { encrypter } from 'helpers/desencrypt.jsx';

const NoValidated = (
    {
        stage,
        setStage,
        selectedComponent
    }
) => {

    //Renombrar variable.
    const idComponent = selectedComponent.id;

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isComputer,
        warehouse,
        isMobile,
        options,
        notify,
        MySwal,
        sapPassword
    } = useContext(UserContext);
    //#endregion

    //State para guardar la información.
    const [newInfo, setNewInfo] = useState({
        status: "No iniciado",
        plant: warehouse,
        quantity: 1

    })
    //estado para verificar si se debe de enviar
    const [readyToSend, setReadyToSend] = useState(false);
    //estado para verificar el disabled del boton start y stop
    const [isStarted, setIsStarted] = useState(false);
    const [isStoped, setIsStoped] = useState(true);

    //Spinner local para botón
    const [localSpinner, setlocalSpinner] = useState(false);

    //abrir modal de busqueda
    const [openModal, setOpenModal] = useState(false);

    const [modalInfo, setModalInfo] = useState({
        title: "Documentos",
        subtitle: "Seleccione un documento",
        spinnerText: "Extrayendo Documentos...",
        searchText: "Buscar...",
        colunmName: "Documento",
        id: "document"
    })

    const getWareHouses = (newInfo) => {
        alert("per")
    }

    const [fields, setFields] = useState([
        {
            id: 'document',
            label: "No. Documento",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: IoDocumentText,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
            // function: handleSearchPlant
        },
        {
            id: 'status',
            label: "Estado",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: TbStatusChange,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
            // function: handleSearchPlantType
        },
        {
            id: 'location',
            label: "Ubicación",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: FaMapMarkerAlt,
            deleteEmptyOption: true,
            stage: [1, 2]
        },
        {
            id: 'plant',
            label: "Bodega No.",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: MdWarehouse,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
            // function: handleSearchUbication
        },
        {
            id: 'quantity',
            label: "Cantidad",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: FaBoxes,
            deleteEmptyOption: true,
            stage: [1, 2]
        },
        {
            id: 'plate',
            label: "Placa",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: PiBarcode,
            deleteEmptyOption: true,
            stage: [1, 2]
        },

    ]);


    const updateDisabledById = (id, disabled) => {
        setFields(prevFields =>
            prevFields.map(field =>
                field.id === id ? { ...field, disabled: disabled } : field
            )
        );
    };

    //Cambia el estado newInfo según el campo.
    const handleOnChange = (idField, label, idComponent) => {


        let readyToChange = true;

        if (idField === "document") {
            label = label.padStart(10, '0').slice(-10);
        }

        if (idField === "plate") {
            if (isNaN(label)) {
                notify("danger", "Alerta", "Sólo puede ingresar elementos númericos.")
                let strings = label.match(/\D/g, "");
                if (strings && strings.length >= 2) {
                    label = "";
                } else {
                    label = label.replace(/\D/g, "");

                }
            }
            else {
                if (label.toString().length > 7) {
                    notify("danger", "Alerta", "El máximo de dígitos es 7.")
                    label = label.toString().substring(0, 7);
                }
            }
        }

        if (readyToChange) {
            setNewInfo({ ...newInfo, [idField]: label })
        }

    }

    const modeButtonAddon = (field, type) => {
        if (field.matchNumbers) {
            if (newInfo[field.id] == newInfo[field.matchNumbers]) {
                return type == "color" ? "success" : type == "icon" ? "fas fa-check" : true
            }
            else if
                (newInfo[field.id] < newInfo[field.matchNumbers]) {
                return type == "color" ? "warning" : type == "icon" ? "fas fa-times" : true
            } else if
                (newInfo[field.id] > newInfo[field.matchNumbers]) {
                return type == "color" ? "danger" : type == "icon" ? "fas fa-times" : true

            } else {
                return type == "color" ? "default" : type == "icon" ? "fas fa-times" : true
            }
        } else {
            return type == "color" ? "success" : type == "icon" ? "fas fa-search" : false
        }
    }

    const handleConfirmNoValidated = (newInfo) => {
        let err = null;
        console.log(newInfo)
        if (newInfo.location === "" || newInfo.location === 0 || newInfo.location == undefined) {
            err = "Por favor ingresar la ubicación.";
        } else {
            if (newInfo.quantity === null || newInfo.quantity === 0 || newInfo.quantity == undefined) {
                err = "La cantidad debe ser mayor que cero.";
            } else {
                if (newInfo.plate === "" || newInfo.plate === null || newInfo.plate === undefined) {
                    err = "Debe ingresar la placa.";
                } else {
                    if (newInfo.plate.length < 7) {
                        err = "La placa debe tener 7 dígitos";
                    }
                }
            }
        }

        if (err) {

            notify("warning", "Alerta", err)
        } else {
            handleConfirm(newInfo);
        }
    }

    const handleConfirm = (newInfo) => {
        setlocalSpinner(true)
        dispatch(executeConfirmNoValidated({...newInfo, password: encrypter(sapPassword)})).then((resp) => {
            setlocalSpinner(false)
            const { payload } = resp;
            console.log(payload)
            if (resp.payload.isAxiosError) {
                notify("danger", "Alerta", "Hubo un error al registrar los datos, comuníquese con Application Management.");
            }
            else {
                let message = payload.data.message;
                setNewInfo({
                    status: "No iniciado",
                    plant: warehouse,
                    quantity: 1
                })
                setIsStarted(prev => !prev)
                setIsStoped(prev => !prev)
                updateDisabledById('location', true);
                updateDisabledById('plate', true);
                updateDisabledById('quantity', true);
                setReadyToSend(prev => !prev);
                notify((message.includes("Error")) ? "danger" : "success", "Resultado:", message);
            }

        });
    }

    const handleStart = (newInfo) => {
        let htmlMatTable = `<${isComputer ? 'h1' : 'h2'} class="mb-3">Información</${isComputer ? 'h1' : 'h2'}>
        Si desea crear un nuevo documento, debe confirmar al menos un ítem. De otro modo no se realizará el registro del mismo.
        <ul style="text-align: left;">`;

        //inventario no valorado 
        MySwal.fire({
            // title: `Aviso`,
            html: `${htmlMatTable}`,
            icon: "warning",
            showCancelButton: true,
            // width: 1000,
            confirmButtonText: `Si, confirmar`,
            confirmButtonColor: "#2dce89",
            cancelButtonText: "No, cancelar",
            cancelButtonColor: "#adb5bd",
            buttonsStyling: isComputer,
            customClass: {
                confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
            },
            className: "p-0",
            style: {
                overflow: 'unset',
                padding: '0px'
            }

        }).then((result) => {
            if (result.value) {
                var info = {
                    lgnum: warehouse, view: idComponent
                    
                };
                dispatch(createNewDocumentInventoryNoValidated({...info, password: encrypter(sapPassword)})).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {
                        let noDocu = payload.data.document;
                        setNewInfo(prev => {
                            return {
                                ...prev,
                                document: noDocu,
                                status: "Iniciado"
                            }
                        })
                        setIsStarted(prev => !prev)
                        setIsStoped(prev => !prev)
                        updateDisabledById('location', false);
                        updateDisabledById('plate', false);
                        updateDisabledById('quantity', false);
                        setReadyToSend(prev => !prev);
                    } else {
                        notify("danger", "Alerta", "Error al intentar crear el documento, comuniquese con Application Management")
                    }

                });


            }
        });
    }

    const handleStop = (newInfo) => {
        if (true) {
            let htmlMatTable = `<${isComputer ? 'h1' : 'h2'} class="mb-3">¿Está seguro que desea finalizar la confirmación?</${isComputer ? 'h1' : 'h2'}>
            Puede retomar el documento en cualquier momento con el botón de seleccionar documento.
            <ul style="text-align: left;">`;

            //inventario no valorado 
            MySwal.fire({
                // title: `Aviso`,
                html: `${htmlMatTable}`,
                icon: "warning",
                showCancelButton: true,
                // width: 1000,
                confirmButtonText: `Si, finalizar`,
                confirmButtonColor: "#2dce89",
                cancelButtonText: "No, cancelar",
                cancelButtonColor: "#adb5bd",
                buttonsStyling: isComputer,
                customClass: {
                    confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                    cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
                },
                className: "p-0",
                style: {
                    overflow: 'unset',
                    padding: '0px'
                }
            }).then((result) => {
                if (result.value) {
                    setNewInfo(prev => {
                        return {
                            ...prev,
                            document: "",
                            status: "No Iniciado"
                        }
                    })
                    setIsStarted(prev => !prev)
                    setIsStoped(prev => !prev)
                    updateDisabledById('location', true);
                    updateDisabledById('plate', true);
                    updateDisabledById('quantity', true);
                    setReadyToSend(prev => !prev);
                }
            });
        }
    }

    const handleSearchDocument = (e, newInfo, id) => {
        setModalInfo(prev => {
            return { ...prev, id: id }
        })
        setOpenModal(true)
    }

    return (
        <>
            <ModalInfoInventoryAndInquiries
                openModal={openModal}
                setOpenModal={setOpenModal}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                modalInfo={modalInfo}
                getWareHouses={getWareHouses}
                setFields={setFields}
                setIsStarted={setIsStarted}
                setIsStoped={setIsStoped}
                updateDisabledById={updateDisabledById}
                MySwal={MySwal}
                setReadyToSend={setReadyToSend}
            />

            <Row>
                <Col xs="10">

                </Col>
                <Col xs="2" style={isMobile ? {} : { display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        className="btn-icon-only rounded-circle"
                        color="info"
                        outline
                        type="button"
                        onClick={(e) => handleSearchDocument(e, newInfo, "document")}
                    >
                        <span className="btn-inner--icon">
                            <i className="fas fa-search" />
                        </span>
                    </Button>
                </Col>
            </Row>

            <Row className={'mt-3'}>
                <Col xs="3">
                    <Button
                        className="btn-icon"
                        color="warning"
                        type="button"
                        disabled={isStarted}
                        onClick={() => handleStart(newInfo)}
                    >
                        <span className="btn-inner--icon">
                            <i className="fas fa-play" />
                        </span>
                    </Button>
                </Col>
                <Col xs="6">
                    {isMobile &&
                        <h3 className='text-center'>{selectedComponent.title}</h3>
                    }
                </Col>
                <Col xs="3" style={isMobile ? {} : { display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        className="btn-icon"
                        color="danger"
                        type="button"
                        disabled={isStoped}
                        onClick={() => handleStop(newInfo)}
                    >
                        <span className="btn-inner--icon">
                            <i className="fas fa-stop" />
                        </span>
                    </Button>
                </Col>

            </Row>


            <Row className={'mt-3'}>
                {
                    fields.map((field, key) =>
                        <Col xs={field.width}>
                            <FormGroup className="mb-4" >

                                <Label className="font-weight-bold ml-2" >

                                    {field.icon && //Icono 
                                        <field.icon className="mr-2 mb-1" style={{ fontSize: "16px" }} />
                                    }

                                    {field.label + ':'}
                                </Label>


                                <InputGroup className="input-group-merge">
                                    <Input
                                        type={field.type}
                                        className=""
                                        disabled={field.disabled || (field.dependsEnableOf && !newInfo[field.dependsEnableOf])}
                                        size={isMobile && "sm"}
                                        value={newInfo && newInfo[field.id] ? newInfo[field.id] : ""}
                                        onChange={(e) => {
                                            if (field.type === "number") {
                                                handleOnChange(field.id, e.target.value.replace(/\D/g, ""), idComponent);
                                            } else {
                                                handleOnChange(field.id, e.target.value, idComponent);
                                            }
                                        }}

                                        min={1}
                                    >
                                        {field.deleteEmptyOption && <option id=""></option>}
                                        {
                                            options[field.options] && options[field.options].map((value) => {
                                                return (
                                                    <option id={value.value}>{value.label}</option>
                                                );
                                            })}
                                    </Input>

                                    {(field.elementType == "inputKey") &&
                                        <InputGroupAddon size="sm" addonType="append" className={'ml--2'}>
                                            <Button color={modeButtonAddon(field, "color")}
                                                size="sm"
                                                outline
                                                onClick={(e) => field.function(e, newInfo, field.id)}
                                                disabled={modeButtonAddon(field, "disabled")}>
                                                <span className="btn-inner--icon">
                                                    <i className={modeButtonAddon(field, "icon")} />
                                                </span>

                                            </Button>
                                        </InputGroupAddon>}
                                </InputGroup>


                            </FormGroup>
                        </Col>
                    )
                }
            </Row>

            <Row className={'mt-3'}>
                <Col xs={12} className={" d-flex justify-content-center align-items-center  " + (isMobile && "mt-1")}>
                    {/* <Button color="success" disabled={!readyToSend} block onClick={() => handleConfirmNoValidated(newInfo)}>
                        <span className="btn-inner--icon mr-1">
                            <i className={"fas fa-paper-plane"} />
                        </span>
                    </Button> */}
                    {/* <ButtonGeist loading={localSpinner} onClick={() => handleConfirmNoValidated(newInfo)} style={{ width: "100%" }} type={"success"} size={"sm"} >
                        Enviar
                        <IoIosSend className="ml-2 mt-1" style={{ fontSize: "16px" }} />
                    </ButtonGeist> */}

                    <ButtonGeist
                        type={"success"}
                        size="small"
                        block
                        loading={localSpinner}
                        disabled={!readyToSend}
                        style={{ width: isComputer ? "30%" : '100%' }}
                        onClick={() => handleConfirmNoValidated(newInfo)} className=""
                    >
                        <span className="btn-inner--icon mr-2 " >
                            <i className={"fas fa-search"} />
                        </span>
                        <span className="btn-inner--text ">
                            Enviar
                        </span>
                    </ButtonGeist>
                </Col>
            </Row>
        </>
    )
}

export default NoValidated;