import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// react library
import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Progress,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Table from "components/Shared/Table/Table.jsx";

import {
  transactionCount,
  loginTotals,
  mostExecutedTransaction,
  executedTotals,
  movementsTotals,
  locationsTotals,
  monthsTotals,
  daysTotals,
} from "actions/wms.jsx";

import {
  transactionCountSelector,
  loginTotalsSelector,
  mostExecutedTransactionSelector,
  executedTotalsSelector,
  movementsTotalsSelector,
  locationsTotalsSelector,
  monthsTotalsSelector,
  daysTotalsSelector,
} from "selectors/wms.jsx";

import { chartOptions, parseOptions } from "variables/WMSCharts.jsx";
import { Line, Bar, Pie } from "react-chartjs-2";
import Chart from "chart.js";

//Bindings
class WMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movementsChart: {},
      executedChart: {},
      locationsChart: {},
      showTransactionModal: false,
      monthsChart: {},
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      showTransactionModal: !prevState.showTransactionModal,
    }));
  }

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    this.props.transactionCount().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });

    this.props.loginTotals().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
    this.props.mostExecutedTransaction().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });

    this.props.executedTotals().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });

    this.props.movementsTotals().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
    this.props.locationsTotals().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
    this.props.locationsTotals().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });

    this.props.monthsTotals().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });

    this.props.daysTotals().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
  }

  handleOnSelect = () => {};

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnCLick = () => {};

  render() {
    const {
      transactionCountData,
      loginTotalsData,
      mostExecutedTransactionData,
      executedTotalsData,
      movementsTotalsData,
      locationsTotalsData,
      monthsTotalsData,
      daysTotalsData,
    } = this.props;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="WMS" parentName="Overview Dashboard" />
        <Container className="mt--6" fluid>
          <Row>
            <Col md="6" xl="3">
              <Card className="bg-gradient-primary border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Transacciones Realizadas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        {transactionCountData.total}
                      </span>
                      <Progress
                        className="progress-xs mt-3 mb-0"
                        max="100"
                        value="100"
                        color="secondary"
                      />
                    </div>
                    <Col className="col-auto"></Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <a
                      className="text-nowrap text-white font-weight-600"
                      onClick={this.toggle}
                      href="#details"
                    >
                      Ver Detalles
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="bg-gradient-info-gbm border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Inicios de Sesión
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        {" "}
                        {loginTotalsData.total}
                      </span>
                      <Progress
                        className="progress-xs mt-3 mb-0"
                        max="100"
                        value="100"
                        color="secondary"
                      />
                    </div>
                    <Col className="col-auto"></Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <a
                      className="text-nowrap text-white font-weight-600"
                      onClick={this.toggle}
                      href="#details"
                    >
                      Ver Detalles
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="bg-gradient-danger border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Transacción más Ejecutada
                      </CardTitle>
                      <span className="h3 font-weight-bold mb-0 text-white">
                        {JSON.stringify(mostExecutedTransactionData.total)}
                      </span>
                      <Progress
                        className="progress-xs mt-3 mb-0"
                        max="100"
                        value="100"
                        color="secondary"
                      />
                    </div>
                    <Col className="col-auto"></Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <a
                      className="text-nowrap text-white font-weight-600"
                      onClick={this.toggle}
                      href="#details"
                    >
                      Ver Detalles
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="bg-gradient-default border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Tráfico Diario
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        {daysTotalsData.total} Transacciones
                      </span>
                      <Progress
                        className="progress-xs mt-3 mb-0"
                        max="100"
                        value="90"
                        color="secondary"
                      />
                    </div>
                    <Col className="col-auto"></Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <a
                      className="text-nowrap text-white font-weight-600"
                      onClick={this.toggle}
                      href="#details"
                    >
                      Ver Detalles
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Crecimiento de Movimientos
                      </h6>
                      <h2 className=" mb-0">Globales</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line data={monthsTotalsData.data} />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Entrada y Salida de Mercancía
                      </h6>
                      <h2 className="mb-0">Tipos de Movimiento</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={movementsTotalsData.data}
                      options={movementsTotalsData.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Registros
                      </h6>
                      <h2 className="mb-0">Movimientos por Ubicaciones</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={locationsTotalsData.data}
                      options={locationsTotalsData.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col xl="6">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Registros
                      </h6>
                      <h2 className="mb-0"> Transacciones Más Ejecutadas</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Pie
                      data={executedTotalsData.data}
                      //options={executedTotalsData.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              {executedTotalsData.values ? (
                <Table
                  title="Transacciones"
                  subtitle="Totales por transacciones"
                  columns={[
                    {
                      dataField: "key",
                      text: "Transacción",
                    },
                    {
                      dataField: "doc_count",
                      text: "Cantidad de Ejecuciones",
                    },
                  ]}
                  data={executedTotalsData.values}
                  sizePerPage={12}
                  page={1}
                  totalSize={executedTotalsData.values.length}
                />
              ) : null}
            </Col>
          </Row>
        </Container>

        {executedTotalsData.values ? (
          <div>
            <Modal isOpen={this.state.showTransactionModal}>
              <ModalHeader toggle={this.toggle}>
                Transacciones Realizadas
              </ModalHeader>
              <ModalBody>
                <Table
                  title="Transacciones"
                  subtitle="Totales por transacciones"
                  columns={[
                    {
                      dataField: "key",
                      text: "Transacción",
                    },
                    {
                      dataField: "doc_count",
                      text: "Cantidad de Ejecuciones",
                    },
                  ]}
                  data={executedTotalsData.values}
                  sizePerPage={12}
                  page={1}
                  totalSize={executedTotalsData.values.length}
                />
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>
          </div>
        ) : null}
      </>
    );
  }
}

WMS.defaultProps = {
  transactionCountData: [],
  loginTotalsData: { total: { hits: { total: 0 } } },
  mostExecutedTransactionData: [],
  executedTotalsData: { values: [] },
  movementsTotalsData: [],
  locationsTotalsData: [],
  monthsTotalsData: [],
  daysTotalsData: {},
};

const mapStateToProps = (state) => ({
  transactionCountData: transactionCountSelector(state),
  loginTotalsData: loginTotalsSelector(state),
  mostExecutedTransactionData: mostExecutedTransactionSelector(state),
  executedTotalsData: executedTotalsSelector(state),
  movementsTotalsData: movementsTotalsSelector(state),
  locationsTotalsData: locationsTotalsSelector(state),
  monthsTotalsData: monthsTotalsSelector(state),
  daysTotalsData: daysTotalsSelector(state),
  //monthTotals: monthTotalsSelector(state),
});

export default withRouter(
  connect(mapStateToProps, {
    transactionCount,
    loginTotals,
    mostExecutedTransaction,
    executedTotals,
    movementsTotals,
    locationsTotals,
    monthsTotals,
    daysTotals,
  })(WMS)
);
