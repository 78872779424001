// core antd
import { Divider } from "antd";
// javascript library for dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useEffect } from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

const FormComplete = (props) => {
  const {
    title,
    subtitle,
    initialValues,
    options: { maritalStatus, relationShip, familyGroups, emergyContacts },
    onSignature,
  } = props;

  return (
    <Row>
      <div className="col">
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={onSignature}
                initialValues={initialValues}
                render={({
                  handleSubmit,
                  values,
                  submitting,
                  validating,
                  valid,
                }) => (
                  <FormRS onSubmit={handleSubmit}>
                    <Divider>Datos Personales</Divider>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="documentID"
                          >
                            Número de documento
                          </Label>
                          <Field name="documentID">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="number"
                                  placeholder="Ingrese su número de documento"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="firstLastName"
                          >
                            Primer Apellido
                          </Label>
                          <Field name="firstLastName">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese su primer apellido"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="secondLastName"
                          >
                            Segundo Apellido
                          </Label>
                          <Field name="secondLastName">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese su segundo apellido"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label className="form-control-label" for="name">
                            Nombre Completo
                          </Label>
                          <Field name="name">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese su nombre completo"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="placeOfExpedition"
                          >
                            Lugar de expedición
                          </Label>
                          <Field name="placeOfExpedition">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="textarea"
                                  placeholder="Escriba aquí el lugar de expedición."
                                  maxLength={250}
                                  rows="2"
                                  resize="none"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="expeditionDate"
                          >
                            Fecha de expedición
                          </Label>
                          <Field name="expeditionDate">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese su nombre completo"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="placeOfBirth"
                          >
                            Lugar de nacimiento
                          </Label>
                          <Field name="placeOfBirth">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="textarea"
                                  placeholder="Escriba aquí el lugar de nacimiento."
                                  maxLength={250}
                                  rows="2"
                                  resize="none"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="dateOfBirth"
                          >
                            Fecha de nacimiento
                          </Label>
                          <Field name="dateOfBirth">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese su nombre completo"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="nationality"
                          >
                            Nacionalidad
                          </Label>
                          <Field name="nationality">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese su nacionalidad"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="occupation"
                          >
                            Ocupación
                          </Label>
                          <Field name="occupation">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese su ocupación"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="maritalStatus"
                          >
                            Estado Civil
                          </Label>
                          <Field name="maritalStatus">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  {maritalStatus.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>
                                        {row.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="otherMaritalStatus"
                          >
                            Especifique
                          </Label>
                          <Field name="otherMaritalStatus">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  disabled={values.maritalStatus !== "5"}
                                  placeholder="Ingrese el detalle de su estado civil"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label className="form-control-label" for="telephone">
                            Telefono
                          </Label>
                          <Field name="telephone">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="number"
                                  placeholder="Ingrese su número de telefono"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="9">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="residenceAddress"
                          >
                            Dirección de residencia
                          </Label>
                          <Field name="residenceAddress">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="textarea"
                                  placeholder="Escriba aquí la dirección de residencia."
                                  maxLength={250}
                                  rows="2"
                                  resize="none"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="8">
                        <div className="form-row align-items-center">
                          <Divider>Departamento en el que labora</Divider>
                          <Col className="mb-3" sm="12" md="6">
                            <FormGroup>
                              <Label className="form-control-label" for="area">
                                Área
                              </Label>
                              <Field name="area">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="text"
                                      placeholder="Ingrese el área donde labora"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="6">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="position"
                              >
                                Cargo
                              </Label>
                              <Field name="position">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="text"
                                      placeholder="Ingrese la posición donde labora"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </div>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="email">
                            Correo electrónico personal
                          </Label>
                          <Field name="email">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese su correo electrónico"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <Divider>
                      GRUPO / NUCLEO FAMILIAR (Padres, conyuge o compañero/a de
                      vida, hijos/as)
                    </Divider>
                    <div className="form-row align-items-center mr-1 justify-content-center">
                      {familyGroups.length ? (
                        <>
                          <Table className="align-items-center mb-3" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  Nombre Completo
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  No. de Identificación
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  Fecha de nacimiento
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  Parentesco
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  Detalle Parentesco
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {familyGroups.map((row, key) => (
                                <tr key={key}>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.name}
                                  </th>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.documentID}
                                  </th>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {moment(row.dateOfBirth)
                                      .utc()
                                      .utcOffset(moment().utcOffset())
                                      .format("DD/MM/YYYY")}
                                  </th>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.relationShip}
                                  </th>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.relationShipDetail}
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                    </div>
                    <Divider />
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="isExposed">
                            ¿Usted es o ha sido una persona políticamente
                            expuesta (PEP)?
                          </Label>
                          <Field name="isExposed">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="1">Sí</option>
                                  <option value="2">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="positionExposed"
                          >
                            En caso afirmativo, detalle el cargo que ostenta u
                            ostentó
                          </Label>
                          <Field name="positionExposed">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Escriba aquí el cargo."
                                  disabled={values.isExposed !== "1"}
                                  maxLength={50}
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      {values.isExposed == "1" && (
                        <>
                          <Col className="mb-3" sm="12" md="2">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="startExposed"
                              >
                                Fecha Inicio
                              </Label>
                              <Field name="startExposed">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="text"
                                      placeholder="Ingrese su nombre completo"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="2">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="endExposed"
                              >
                                Fecha Fin
                              </Label>
                              <Field name="endExposed">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="text"
                                      placeholder="Ingrese su nombre completo"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="isExposedLinked"
                          >
                            ¿Existe algún vínculo entre usted y una persona
                            políticamente expuesta (PEP)?
                          </Label>
                          <Field name="isExposedLinked">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="1">Sí</option>
                                  <option value="2">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="positionExposedLinked"
                          >
                            En caso afirmativo, detalle el cargo que ostenta u
                            ostentó
                          </Label>
                          <Field name="positionExposedLinked">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Escriba aquí el cargo."
                                  disabled={values.isExposedLinked !== "1"}
                                  maxLength={50}
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      {values.isExposedLinked == "1" && (
                        <>
                          <Col className="mb-3" sm="12" md="2">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="startExposedLinked"
                              >
                                Fecha Inicio
                              </Label>
                              <Field name="startExposedLinked">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="text"
                                      placeholder="Ingrese su nombre completo"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="2">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="endExposedLinked"
                              >
                                Fecha Fin
                              </Label>
                              <Field name="endExposedLinked">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="text"
                                      placeholder="Ingrese su nombre completo"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="isTax">
                            ¿Es usted sujeto de obligaciones tributarias en otro
                            país?
                          </Label>
                          <Field name="isTax">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="1">Sí</option>
                                  <option value="2">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="taxDescription"
                          >
                            En caso afirmativo, especifique el o los paises:
                          </Label>
                          <Field name="taxDescription">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  disabled
                                  type="text"
                                  placeholder="Ingrese aqui el o los paises."
                                  disabled={values.isTax !== "1"}
                                  maxLength={250}
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <Divider>EN CASO DE EMERGENCIA AVISAR A:</Divider>
                    <div className="form-row align-items-center mr-1 justify-content-center">
                      {emergyContacts.length ? (
                        <>
                          <Table className="align-items-center mb-3" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  Nombre Completo
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  Telefono
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  Telefono 2
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  Dirección
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {emergyContacts.map((row, key) => (
                                <tr key={key}>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.name}
                                  </th>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.telephone}
                                  </th>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.telephone2}
                                  </th>
                                  <th
                                    scope="row"
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.address}
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                    </div>
                    <div className="form-row align-items-center">
                      <Divider>INFORMACIÓN FINANCIERA</Divider>
                      <Col className="mb-3" sm="12" md="7">
                        <div className="form-row align-items-center">
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="salaryAmount"
                              >
                                Salario Devengado Fijo $
                              </Label>
                              <Field name="salaryAmount">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="number"
                                      placeholder="Ingrese el ingreso anual"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="expenseAmount"
                              >
                                Egresos Anuales $
                              </Label>
                              <Field name="expenseAmount">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="number"
                                      placeholder="Ingrese el egreso anual"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="incomeAmount"
                              >
                                Otros Ingresos Gravados $
                              </Label>
                              <Field name="incomeAmount">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="number"
                                      placeholder="Ingrese otros ingresos anuales"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </div>
                      </Col>
                      <Col className="mb-3" sm="12" md="5">
                        <div className="form-row align-items-center">
                          <Col className="mb-3" sm="12" md="6">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="assetAmount"
                              >
                                Activos $ (Bienes, muebles e inmuebles)
                              </Label>
                              <Field name="assetAmount">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="number"
                                      placeholder="Ingrese el monto de los activos"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="6">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="passiveAmount"
                              >
                                Pasivos $ (Créditos bancarios, hipotecas)
                              </Label>
                              <Field name="passiveAmount">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      disabled
                                      type="number"
                                      placeholder="Ingrese el monto de los pasivos"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </div>
                      </Col>
                    </div>
                    <div className="form-row align-items-center justify-content-center">
                      <Col className="mb-3" sm="12" md="10">
                        <FormGroup check>
                          <Field name="termsAndConditions" type="checkbox">
                            {({ input, meta }) => (
                              <>
                                <Label
                                  style={{ textAlign: "justify", color: "red" }}
                                >
                                  Declaro bajo juramento: a) Que la información
                                  proporcionada es correcta y autorizo a GBM DE
                                  EL SALVADOR, SOCIEDAD ANONIMA DE CAPITAL
                                  VARIABLE para que confirme su veracidad a
                                  través de los medios que considere
                                  convenientes. b) Que me comprometo a mantener
                                  actualizada la información, comunicando de
                                  manera inmediata cualquier cambio en la misma.
                                  c) Que no estoy incluido en en la Oficina del
                                  Control de los Activos de los Extranjeros
                                  (OFAC), o en cualquier otra lista de similar
                                  naturaleza, o en alguna de carácter nacional
                                  y/o internacional en la que se publiquen los
                                  datos de las personas a quienes se les haya
                                  iniciado proceso judicial, actuación
                                  administrativa o que hayan sido sancionadas
                                  y/o condenadas por las autoridades nacionales
                                  e internacionales, de manera directa o
                                  indirecta, con actividades ilegales, tales
                                  como narcotráfico, terrorismo o su
                                  financiación, lavado de dinero y activos,
                                  tráfico de estupefacientes, secuestro,
                                  extorsiones y trata de personas, entre otras.
                                  d) Que todos los valores que entregue o
                                  reciba, provenientes de financiamiento o para
                                  depósito, en inversión o en garantía, tendrán
                                  un origen y un destino que, de ninguna manera,
                                  estará relacionado con los delitos generadores
                                  del lavado de Dinero y Activos ni ninguna
                                  actividad ilitica, ni admitiré que terceros
                                  efectúen depósitos en cuentas mi favor, con
                                  fondos provenientes de las actividades
                                  ilícitas. e) Que eximo a GBM DE EL SALVADOR,
                                  S.A. DE C.V., de toda responsabilidad que se
                                  derive por información errónea, falsa o
                                  inexacta, que yo hubiere proporcionado en este
                                  documento, o la violación del mismo.
                                </Label>
                                <div style={{ marginBottom: "1rem" }}>
                                  {meta.error && meta.touched && (
                                    <span
                                      style={{
                                        fontSize: "80%",
                                        color: "#fb6340",
                                      }}
                                    >
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                  </FormRS>
                )}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </Row>
  );
};

FormComplete.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormComplete;
