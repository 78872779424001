// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";


moment.locale("es");

const CardLetters = props => {

  const {
    boss,
    title,
    manager,
    letters,
    openLetter
  } = props;
  return (
    <div className="card-deck flex-column flex-xl-row">
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">{title}</h5>
        </CardHeader>
        <CardBody>
          <ListGroup className="list my--3" flush>
            {
              letters.map((row, key) => {
                return (
                  <ListGroupItem key={key} className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          alt="Notificación"
                          className="avatar rounded-circle"
                          src={require(`assets/img/theme/target-letter-${boss ? 'headship' : manager ? 'g-manager' : 'hc'}.png`)}
                        />
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          Colaborador: {row.collaborator}
                        </h4>
                        <p
                          className={row.flowStatus === 0 ? "mb-0 text-primary" : row.flowStatus === 1 ? "mb-0 text-success" : "mb-0 text-danger"}
                        >
                          {
                            moment(row.updatedAt)
                              .utc()
                              .utcOffset(moment().utcOffset())
                              .format("lll")
                          } - {
                            row.flowStatus === 0 ? "En proceso" : row.flowStatus === 1 ? "Aprobada" : "Rechazada"
                          }
                        </p>
                      </div>
                      <Col className="col-auto">
                        <Button
                          color="success"
                          // size="sm"
                          type="button"
                          onClick={() => openLetter(row)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-envelope-open-text" />
                          </span>
                          <span className="btn-inner--text">Abrir</span>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                )
              })
            }
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  );
};

CardLetters.propTypes = {
  title: PropTypes.string.isRequired,
  letters: PropTypes.array.isRequired,
  openLetter: PropTypes.func.isRequired,
};

export default CardLetters;