import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getMasterData = createAction(constants.GET_DATA_EXIT_INTERVIEW, method.get(urls.EXITINTERVIEW.getMasterData));
export const getInterview = createAction(constants.GET_INTERVIEW, body => method.post(urls.EXITINTERVIEW.getInterview, body)());
export const getInterviewComplete = createAction(constants.GET_INTERVIEW, body => method.post(urls.EXITINTERVIEW.getInterviewComplete, body)());
export const getDraftInterview = createAction(constants.GET_DRAFT_INTERVIEW, method.get(urls.EXITINTERVIEW.getDraftInterview));
export const insertExitType = createAction(constants.INSERT_EXIT_TYPE, body => method.post(urls.EXITINTERVIEW.insertExitType, body)());
export const insertExitReason = createAction(constants.INSERT_EXIT_REASON, body => method.post(urls.EXITINTERVIEW.insertExitReason, body)());
export const changeStatusExitType = createAction(constants.CHANGE_STATUS_EXIT_TYPE, body => method.post(urls.EXITINTERVIEW.changeStatusExitType, body)());
export const changeStatusExitReason = createAction(constants.CHANGE_STATUS_EXIT_REASON, body => method.post(urls.EXITINTERVIEW.changeStatusExitReason, body)());
export const getDataChartCountry = createAction(constants.GET_DATA_CHART1_EXIT_INTERVIEW, body => method.post(urls.EXITINTERVIEW.getDataChartCountry, body)());
export const getDataChartExitType = createAction(constants.GET_DATA_CHART_EXIT_TYPE_INTERVIEW, body => method.post(urls.EXITINTERVIEW.getDataChartExitType, body)());
export const getDataChatExitReasons = createAction(constants.GET_DATA_CHART_EXIT_REASONS_INTERVIEW, body => method.post(urls.EXITINTERVIEW.getDataChatExitReasons, body)());
export const insertInterview = createAction(constants.INSERT_INTERVIEW, body => method.post(urls.EXITINTERVIEW.insertInterview, body)());
// export const insertDraftInterview = createAction(constants.INSERT_DRAFT_INTERVIEW, body => method.post(urls.EXITINTERVIEW.insertDraftInterview, body)());
export const getDataChartWords = createAction(constants.GET_DATA_CHART_WORDS, method.get(urls.EXITINTERVIEW.getDataChartWords));
export const deleteDraftInterview = createAction(constants.DELETE_DRAFT_INTERVIEW, (body) => method.post(urls.EXITINTERVIEW.deleteDraftInterview, body)());
// export const updateDraftInterview = createAction(constants.UPDATE_DRAFT_INTERVIEW, (body) => method.post(urls.EXITINTERVIEW.updateDraftInterview, body)());
export const draftInterviewToInterview = createAction(constants.DRAFTINTERVIEW_TO_INTERVIEW, (body) => method.post(urls.EXITINTERVIEW.draftInterviewToInterview, body)());
export const getInfoByUser = createAction(constants.GET_INFO_BY_USER_EXIT_INTERVIEW, (body) => method.post(urls.EXITINTERVIEW.getInfoByUser, body)());
export const getDraftInterviewStatusInfo = createAction(constants.GET_DRAFT_INTERVIEW_STATUS, method.get(urls.EXITINTERVIEW.getDraftInterviewStatus));
export const sendEmailToUser = createAction(constants.SEND_EMAIL_USER_EXIT_INTERVIEW, (body) => method.post(urls.EXITINTERVIEW.sendEmailToUser, body)());
export const sendEmailToHCM = createAction(constants.SEND_EMAIL_HCM_EXIT_INTERVIEW, (body) => method.post(urls.EXITINTERVIEW.sendEmailToHCM, body)());
export const updateInterview = createAction(constants.UPDATE_INTERVIEW, (body) => method.post(urls.EXITINTERVIEW.updateInterview, body)());
export const getCountriesByHCM = createAction(constants.GETCOUNTRIESBYHCM, (body) => method.post(urls.EXITINTERVIEW.getCountriesByHCM, body)());

export const newUserRole = createAction("INSERT_USER_ROLE", (body) => method.post(urls.EXITINTERVIEW.inserUserRole, body)());
export const inactiveRoleByUser = createAction("INACTIVE_USER_ROLE", (body) => method.post(urls.EXITINTERVIEW.inactiveRole, body)());
export const updateUserRoleByUser = createAction("UPDATE_USER_ROLE", (body) => method.post(urls.EXITINTERVIEW.updateUserRole, body)());
export const getDataCardsHistory = createAction("GET_CARDS_HISTORY", (body) => method.post(urls.EXITINTERVIEW.getDataCardsHistory, body)());
export const getChartDraft = createAction("GET_CHART_DRAFT", (body) => method.post(urls.EXITINTERVIEW.getDataChartDraft, body)());







