// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import { Button, Card, CardHeader, Col, Input, Row, Table } from "reactstrap";

const FrusCard = (props) => {
  const {
    title,
    subtitle,
    cols,
    rows,
    initialValues,
    onChange,
    onBackView,
    onCompleteProcess,
  } = props;

  const frus = Object.keys(initialValues).filter((row) => row.includes("fru"));

  console.log(initialValues);

  return (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md="8">
            <h6 className="surtitle text-white">{title}</h6>
            <h5 className="h3 mb-0 text-white">{subtitle}</h5>
          </Col>
          <Col xs="12" md="4">
            <Row>
              <Col
                className="d-flex align-items-center justify-content-end"
                xs="12"
                md="5"
              >
                <Button
                  block
                  size="sm"
                  color="danger"
                  className="btn-icon btn-3"
                  onClick={onBackView}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-arrow-left" />
                  </span>
                  <span className="btn-inner--text">Regresar</span>
                </Button>
              </Col>
              <Col
                className="d-flex align-items-center justify-content-end"
                xs="12"
                md="7"
              >
                <Button
                  block
                  size="sm"
                  color="info"
                  className="btn-icon btn-3"
                  disabled={frus.length !== rows.length}
                  onClick={() => onCompleteProcess(rows.length)}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-double" />
                  </span>
                  <span className="btn-inner--text">Completar Proceso</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-dark table-flush" responsive>
        <thead className="thead-dark">
          <tr>
            {cols.map((row, key) => (
              <th key={key}>{row.name}</th>
            ))}
          </tr>
        </thead>
        <tbody className="list">
          {rows.map((row, key) => {
            return (
              <tr key={key}>
                {key === 0 ? (
                  <>
                    <td
                      rowSpan={row.counts}
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      {row.amountEquipments}
                    </td>
                    <td
                      rowSpan={row.counts}
                      style={{ whiteSpace: "normal", textAlign: "justify" }}
                    >
                      {row.typeModel}
                    </td>
                  </>
                ) : (
                  rows[key - 1].idEquipment !== row.idEquipment && (
                    <>
                      <td
                        rowSpan={row.counts}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        {row.amountEquipments}
                      </td>
                      <td
                        rowSpan={row.counts}
                        style={{ whiteSpace: "normal", textAlign: "justify" }}
                      >
                        {row.typeModel}
                      </td>
                    </>
                  )
                )}
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.platform}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.category}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.description}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.classify}
                </td>
                {/* <th
                  key={key}
                  style={{ whiteSpace: "normal", textAlign: "justify" }}
                >
                  <label className="custom-toggle">
                    <input
                      id={`isClassify-${row.id}`}
                      type="checkbox"
                      checked={initialValues[`isClassify-${row.id}`]}
                      onChange={(e) => onChange(e)}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Sí"
                    />
                  </label>
                </th> */}
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  <Input
                    style={{
                      backgroundColor: "hsla(0,0%,100%,.1)",
                      border: "1px solid #1394b6",
                    }}
                    id={`fru-${row.id}`}
                    type="text"
                    placeholder="Ingrese el FRU"
                    value={
                      initialValues[`fru-${row.id}`]
                        ? initialValues[`fru-${row.id}`]
                        : row.fru
                    }
                    onChange={(e) => onChange(e)}
                    maxLength={17}
                  />
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  <Input
                    style={{
                      backgroundColor: "hsla(0,0%,100%,.1)",
                      border: "1px solid #1394b6",
                    }}
                    id={`amount-${row.id}`}
                    type="number"
                    defaultValue={row.amount}
                    value={initialValues[`amount-${row.id}`]}
                    onChange={(e) => onChange(e)}
                    min={1}
                    max={99}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};

FrusCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default FrusCard;
