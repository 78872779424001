// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const CardRequests = ({
  title,
  icon,
  count,
  keyRequest,
  selectData,
  color,
  gradiend
}) => {

  return (
    <>
      <Card
        className="card-stats card-lift--hover"
        onClick={() => selectData(keyRequest)}
        style={{ backgroundColor: color, cursor: "pointer" }}
      >
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                {title}
              </CardTitle>
            </div>
            <Col className="col-auto">
              <div className={`icon icon-shape ${gradiend} text-white rounded-circle shadow`}>
                <i className={icon} />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-sm">
            <span className="text-success mr-2">
              <i className="fas fa-folder" /> {count}
            </span>{" "}
            <span className="text-nowrap">solicitudes realizadas</span>
          </p>
        </CardBody>
      </Card>
    </>
  );
};

CardRequests.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  keyRequest: PropTypes.string.isRequired,
  selectData: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

export default CardRequests;