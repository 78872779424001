import { Col, FormGroup, Input, Row, Button, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
// react-select library for filters
import Select from "react-select";
// react library
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// url variables api
import { urlBase } from "api/urls.jsx";
import { deleteFile } from 'actions/DocumentSystem.jsx';
import UploadFilesModal from 'components/DocumentSystem/UploadFilleModal';
import { encrypter } from 'helpers/desencrypt.jsx';
const EditFormThree = ({
    row,
    displayI,
    setLogModal,
    handleOnChange,
    isAdmin,
    isDisplay,
    Toast,
    filesCustomer,
    rejectedReason,
    discountType,
    setModal,
    formatNumber
}) => {

    //#region Funciones
    console.log(row.documentId);
    console.log(encrypter(row.documentId));

    // console.log(formatNumber(row["discountAmount"]))
    const dispatch = useDispatch();

    const discountTypeObject = {
        colWidth: "6",
        elementType: "Select",
        label: "Tipo de Descuento",
        placeholder: "Seleccione un Tipo de Descuento",
        id: "discountType",
        type: "Select",
        min: 0,
        max: 1999999999,
        defaultValue: "discountType",
        disabled: true,
        options: discountType,
        value: discountType.label,
        defaultValueLabel: "discountTypeName",
        defaultValueValue: "discountType",
    };

    const [fields, setFields] = useState([
        {
            colWidth: "6",
            elementType: "Input",
            label: "Comentario del país",
            placeholder: "Comentario del país",
            id: "comment",
            type: "textarea",
            min: 0,
            max: 1999999999,
            defaultValue: "comment",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: "Comentario de Business System",
            placeholder: "Comentario Business System",
            id: "bsResponse",
            type: "textarea",
            min: 0,
            max: 1999999999,
            defaultValue: "bsResponse",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "Select",
            label: "Razón de Rechazado",
            placeholder: "Seleccione un Razón de Rechazado",
            id: "rejectedReason",
            type: "Select",
            min: 0,
            max: 1999999999,
            defaultValue: "rejectedReason",
            disabled: true,
            options: rejectedReason,
            value: rejectedReason.label,
            defaultValueLabel: "rejectedReasonName",
            defaultValueValue: "rejectedReason",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: "Razón de Cancelado",
            placeholder: "Razón de Cancelado",
            id: "cancelledReason",
            type: "textarea",
            min: 0,
            max: 1999999999,
            defaultValue: "cancelledReason",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        //va el discountType pero depende si es Cisco o IBM u otros
        {
            colWidth: "6",
            elementType: "Input",
            label: (row.documentVendor !== undefined) ? (row.documentVendor.includes("CISCO")) ? "Smart Account" : (row.documentVendor.includes("TOSHIBA")) ? "Nombre del Cart" : "Número de Special Bid" : "",
            placeholder: (row.documentVendor !== undefined) ? (row.documentVendor.includes("CISCO")) ? "Smart Account" : (row.documentVendor.includes("TOSHIBA")) ? "Nombre del Cart" : "Número de Special Bid" : "",
            id: "discountNumber",
            type: (row.documentVendor !== undefined) ? (row.documentVendor.includes("CISCO") || row.documentVendor.includes("TOSHIBA")) ? "text" : "number" : "text",
            min: 0,
            max: 1999999999,
            defaultValue: "discountNumber",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: (row.documentVendor !== undefined) ? (row.documentVendor.includes("CISCO")) ? "Monto Deal ID" : "Monto Special Bid" : "",
            placeholder: (row.documentVendor !== undefined) ? (row.documentVendor.includes("CISCO")) ? "Monto Deal ID" : "Monto Special Bid" : "",
            id: "discountAmount",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "discountAmount",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: "Fecha de Solicitud \n\n",
            placeholder: "Fecha de Solicitud",
            id: "createdAt",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "Cdate",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: "Última Persona quien actualizó",
            placeholder: "última Persona quien actualizó",
            id: "updatedBy",
            type: (isAdmin || isDisplay) ? "text" : "hidden",
            min: 0,
            max: 1999999999,
            defaultValue: "updatedBy",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: "Última fecha de actualización",
            placeholder: "Última fecha de actualización",
            id: "updatedAt",
            type: (isAdmin || isDisplay) ? "text" : "hidden",
            min: 0,
            max: 1999999999,
            defaultValue: "Udate",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Button",
            label: "Log de Cambio de Estados",
            id: "documentLog",
            defaultValue: "Abrir Log",
            type: (isAdmin || isDisplay) ? "text" : "hidden",
        },
        {
            colWidth: (isAdmin || isDisplay) ? "8" : "12",
            elementType: "List",
            label: "Documentación",
            id: "documentFiles",
            defaultValue: "documentFiles",
        },
    ]);

    useEffect(() => {
        if (row.documentVendor !== undefined) {
            if (row.documentVendor.includes("IBM") || row.documentVendor.includes("LOTUS")) {
                setFields(prevState => {
                    const newFields = [...prevState]; // Create a shallow copy of the array
                    newFields.splice(4, 0, discountTypeObject);
                    return newFields;
                })
            }
        }
    }, [])

    //array
    //#region Pagination

    const [state, setState] = useState({
        page: 1,
        sizePerPage: 3,
        idFileRequest: "",
    })
    const { page, sizePerPage } = state;


    const paginations = filesCustomer.length > sizePerPage ?
        filesCustomer.length / sizePerPage > Math.round(filesCustomer.length / sizePerPage) ?
            Math.round(filesCustomer.length / sizePerPage) + 1
            : Math.round(filesCustomer.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink href="#"
                        onClick={(e) => handleOnSetPage(e, i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Cambiar de pagina
    const handleOnSetPage = (event, page, who) => {

        setState((prevState) => ({
            ...prevState,
            [who]: page
        }))
    };
    //#endregion


    //funcion para abrir el log de cambios de status

    const handleOnLoadLog = (event, documentId) => {
        setLogModal((prevState) => ({
            ...prevState,
            modalToggel: !prevState.modalToggel,
            documentId: documentId,
        }))
    };




    //funcion para eliminar un archivo de una solicitud
    const handleDeleteFile = (documentId, file) => {
        dispatch(deleteFile({ id: documentId, name: file })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                console.log("Se borró el archivo del BE");
                setModal(prevState => {
                    return {
                        ...prevState,
                        filesCustomer: prevState.filesCustomer.filter(x => x !== file)
                    };
                });

            } else {
                Toast.fire({
                    title: "Atención",
                    html: "Error al intentar borrar el archivo",
                    type: 'error'
                });
            }
        })
    }

    //funcion para abrir el componente de subir archivos nuevos
    const [uploadModal, setUploadModal] = useState({
        modalUploadToggel: false,
    })
    const { modalUploadToggel } = uploadModal

    //funcion para abrir modal de upload
    const handleOnUploadFiles = () => {
        setUploadModal((prevState) => ({
            modalUploadToggel: !prevState.modalUploadToggel
        }));

    }

    const handleDownloadAllFiles = (e, documentId) => {
        const downloadUrl = `${urlBase}/document-system/download-all-files/${encrypter(documentId)}`;

        fetch(downloadUrl, {
            method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
        })
            .then(response => {
                if (response.ok) {
                    window.location.assign(downloadUrl);
                } else {
                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
                        type: "warning",

                    });
                }
            })
            .catch(error => {
                console.error('Error checking file existence:', error);
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrio un error al validar el archivo ",
                    type: "warning",

                });
            });
    }

    const handleDownloadFile = (e, documentId, file) => {
        const downloadUrl = `${urlBase}/document-system/download-file/${encrypter(documentId)}/${encrypter(file)}`;

        fetch(downloadUrl, {
            method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
        })
            .then(response => {
                if (response.ok) {
                    window.location.assign(downloadUrl);
                } else {
                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
                        type: "warning",

                    });
                }
            })
            .catch(error => {
                console.error('Error checking file existence:', error);
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrio un error al validar el archivo ",
                    type: "warning",

                });
            });
    }


    //#endregion

    return (

        <>

            <UploadFilesModal
                row={row}
                showModalUpload={modalUploadToggel}
                setShowModalUpload={setUploadModal}
                filesCustomer={filesCustomer}
                setFiles={setModal}
            />
            <Row className="">
                {
                    fields.map((field, key) => (

                        field.type !== "hidden" &&
                        <Col xs={field.colWidth} key={key}>
                            <FormGroup key={key}>
                                <label className="form-control-label" style={field.id === "documentFiles" ? { marginRight: "20px" } : { marginRight: 0 }}>{field.label}</label>
                                {field.id === "documentFiles" ?
                                    <>
                                        {
                                            (isAdmin || isDisplay) &&
                                            <>
                                                <a
                                                    title="Descargar todos los archivos"
                                                    id="downloadAllFiles"
                                                    onClick={(e) => handleDownloadAllFiles(e, row.documentId)}
                                                    style={{ marginRight: "5px" }}
                                                >
                                                    <span className="btn btn-primary">
                                                        <i className="fas fa-download" />
                                                    </span>
                                                </a>
                                                {
                                                    isAdmin &&
                                                    <Button title="Subir nuevos archivos" id="uploadFiles" color="info" onClick={() => handleOnUploadFiles()}>
                                                        <i className="fas fa-upload" />
                                                    </Button>
                                                }
                                            </>
                                        }
                                        <Table
                                            className="align-items-center table-flush"
                                            responsive
                                            striped={true}
                                            hover={true}
                                            key={field.id}
                                            style={{ marginTop: "7px" }}
                                        >
                                            <thead className="thead-light">
                                                {/* <tr>
                                                    Documentos
                                                </tr> */}
                                            </thead>
                                            <tbody className="list">
                                                {
                                                    filesCustomer.length ?
                                                        filesCustomer.slice((page - 1) * sizePerPage, page * sizePerPage).map((file, key2) => (
                                                            (file.toLowerCase() !== "confirmacion de compra.pdf" || isAdmin || isDisplay) &&
                                                            <tr key={key2}>
                                                                <td>
                                                                    <a
                                                                        onClick={(e) => handleDownloadFile(e, row.documentId, file)}
                                                                    >
                                                                        {file}
                                                                    </a>
                                                                </td>
                                                                {
                                                                    isAdmin &&
                                                                    <td>
                                                                        <a onClick={(e) => handleDeleteFile(row.documentId, file)} >
                                                                            <span className="">
                                                                                <i className="fas fa-trash-alt" />
                                                                            </span>
                                                                        </a>
                                                                    </td>
                                                                }

                                                            </tr>
                                                        ))
                                                        :
                                                        <>
                                                            <tr>
                                                                <td className="justify-content-md-center">
                                                                    No tiene documentos asociados
                                                                </td>
                                                            </tr>
                                                        </>
                                                }
                                            </tbody>
                                        </Table>

                                        <nav aria-label="...">
                                            <Row className="align-items-center">
                                                <Col xs='12' md='12' className="p-1">
                                                    <Pagination
                                                        className="pagination justify-content-end mb-0"
                                                        listClassName="justify-content-end mb-0"
                                                    >
                                                        {
                                                            renderPaginations()
                                                        }
                                                    </Pagination>
                                                </Col>
                                            </Row>
                                        </nav>
                                    </>
                                    : field.id === "discountNumber" ?
                                        <Input
                                            key={field.id}
                                            id={field.id}
                                            className="form-control"
                                            type={field.type}
                                            readOnly={!field.disabled ? true : isAdmin ? displayI : false}
                                            defaultValue={row[field.defaultValue]}
                                            placeholder={field.placeholder}
                                            onChange={(e) => handleOnChange(field.id, e.target.value)}
                                        />
                                        : field.elementType === "Input" ?
                                            <Input
                                                key={field.id}
                                                id={field.id}
                                                className="form-control"
                                                type={field.type}
                                                readOnly={!field.disabled ? true : displayI}
                                                defaultValue={row[field.defaultValue]}
                                                placeholder={field.placeholder}
                                                onChange={(e) => handleOnChange(field.id, e.target.value)}
                                            />

                                            : field.elementType === "Select" ?
                                                <Select
                                                    key={field.id}
                                                    className="basic-single"
                                                    id={field.id}
                                                    type="select"
                                                    placeholder={field.placeholder}
                                                    isDisabled={!field.disabled ? true : displayI}
                                                    isSearchable
                                                    options={field.options}
                                                    defaultValue={{ label: row[field.defaultValueLabel], value: row[field.defaultValueValue] }}
                                                    onChange={(e) => handleOnChange(field.id, e.value)}
                                                />
                                                : field.elementType === "Button" ?
                                                    (isAdmin || isDisplay) &&
                                                    <Button key={field.id} className="btn-icon" color="info" size="sm" block type="button" id={field.id}
                                                        onClick={(e) => handleOnLoadLog(e, row["documentId"])}
                                                    >
                                                        <span className="btn-inner--icon mr-">
                                                            <i className="fas fa-print-search" />
                                                        </span>
                                                        <span className="btn-inner--text">
                                                            {field.defaultValue}
                                                        </span>
                                                    </Button>

                                                    :
                                                    <></>
                                }
                            </FormGroup>
                        </Col>
                    ))
                }
            </Row>
        </>
    )
};

export default EditFormThree;