/* eslint-disable jsx-a11y/anchor-is-valid */
// core antd
import { Divider, Icon, Tabs, Upload, message } from "antd";
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useEffect, useState } from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
} from "reactstrap";

const { TabPane } = Tabs;
const { Dragger } = Upload;

const MedicalReportForm = (props) => {
  const {
    id,
    all,
    step,
    options,
    initialValues,
    otherInitialValues,
    emergencyContacts,
    medicalInfo: { allergies, diseases, interventions, otherMedicalInfo },
    onSubmit,
    onShowAll,
    editContact,
    deleteContact,
    editAllergy,
    deleteAllergy,
    editDisease,
    deleteDisease,
    editIntervention,
    editOtherMedicalInfo,
    deleteIntervention,
    deleteOtherMedicalInfo,
    medicatedEvent,
    addEmergencyContact,
    onAddMedicalInfo,
    viewMedications,
    onFileList,
    fileList,
    onDownload,
  } = props;

  const [tabKeyMedical, setTabKeyMedical] = useState("1");

  const changeTabMedical = (value) => {
    setTabKeyMedical(value);
  };

  useEffect(() => {
    delete otherInitialValues.test;
  });

  const validate = (values) => {
    const errors = {};
    if (!values.location || values.location === "0")
      errors.location = "Debes seleccionar una opción.";
    if (!values.bloodType || values.bloodType === "0")
      errors.bloodType = "Debes seleccionar una opción.";
    if (!values.bornDate || values.bornDate === "")
      errors.bornDate = "Debes seleccionar una fecha.";
    if (!values.address || values.address === "")
      errors.address = "Debes ingresar tu dirección.";
    return errors;
  };

  const allowedFileTypes = [
    ".pdf",
    ".jpeg",
    ".jpg",
    ".png",
    ".docx",
    ".pptx",
    ".xlsx",
  ];

  const uploadProps = {
    name: "doc",
    accept: allowedFileTypes.join(","),
    multiple: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    action: `${urlBase}/MR/attachment`,
    beforeUpload: (file) => {
      const fileType = "." + file.name.split(".").pop();
      if (!allowedFileTypes.includes(fileType)) {
        console.error(
          `Solo puedes subir archivos de tipo: ${allowedFileTypes.join(", ")}`
        );
        message.error(
          `Solo puedes subir archivos de tipo: ${allowedFileTypes.join(", ")}`
        );
        return false;
      }
      return true;
    },
    onChange: (info) => {
      // Filtrar la lista de archivos válidos para mantener solo los que aún existen
      const filteredFiles = info.fileList.filter((file) => {
        if (file.status) {
          return true;
        }
        return false;
      });
      info.fileList = filteredFiles;
      // Actualizar la lista de archivos válidos
      // Llamar a la función handleOnFileList con la lista de archivos válidos
      onFileList(info);
    },
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, values, submitting, validating, valid }) => (
          <FormRS onSubmit={handleSubmit}>
            <div className="form-row align-items-center mr-1">
              <Col className="mb-1" sm="12" md="4">
                <FormGroup>
                  <Label className="form-control-label" for="location">
                    Localidad GBM
                  </Label>
                  <Field name="location">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          type="select"
                          valid={!meta.error && meta.modified && meta.touched}
                          invalid={meta.error && meta.touched}
                        >
                          <option key="0" value="0">
                            Seleccione la localidad donde labora
                          </option>
                          {options.locations.map((row, key) => {
                            return (
                              <option key={key} value={row.id}>
                                {row.name}
                              </option>
                            );
                          })}
                        </Input>
                        {meta.error && meta.touched && (
                          <span className="invalid-feedback">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </FormGroup>
              </Col>
              <Col className="mb-1" sm="12" md="4">
                <FormGroup>
                  <Label className="form-control-label" for="bloodType">
                    Grupo Sanguineo
                  </Label>
                  <Field name="bloodType">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          type="select"
                          valid={!meta.error && meta.modified && meta.touched}
                          invalid={meta.error && meta.touched}
                        >
                          <option key="0" value="0">
                            Seleccione un Grupo Sanguineo
                          </option>
                          {options.bloodTypes.map((row, key) => {
                            return (
                              <option key={key} value={row.id}>
                                {row.name}
                              </option>
                            );
                          })}
                        </Input>
                        {meta.error && meta.touched && (
                          <span className="invalid-feedback">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </FormGroup>
              </Col>
              <Col className="mb-1" sm="12" md="4">
                <FormGroup>
                  <Label className="form-control-label" for="bornDate">
                    Fecha de Nacimiento
                  </Label>
                  <Field name="bornDate">
                    {({ input, meta }) => (
                      <>
                        <ReactDatetime
                          {...input}
                          inputProps={{
                            placeholder: "Ejemplo: 18/12/1994",
                            className:
                              !meta.error && meta.modified && meta.touched
                                ? "is-valid form-control"
                                : meta.error && meta.touched
                                ? "is-invalid form-control"
                                : "form-control",
                          }}
                          timeFormat={false}
                        />
                        {meta.error && meta.touched && (
                          <span
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#fb6340",
                            }}
                          >
                            {meta.error}
                          </span>
                        )}
                      </>
                    )}
                  </Field>
                </FormGroup>
              </Col>
            </div>
            <div className="form-row align-items-center mr-1">
              <Col className="mb-1" sm="12" md="12">
                <FormGroup>
                  <Label className="form-control-label" for="address">
                    Dirección (500)
                  </Label>
                  <Field name="address">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          type="textarea"
                          placeholder="Escriba aquí su dirección exacta."
                          maxLength={500}
                          rows="2"
                          resize="none"
                          invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <span className="invalid-feedback">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </FormGroup>
              </Col>
            </div>
            {step === 1 && (
              <>
                <div className="form-row align-items-center mr-1 justify-content-center pb-5">
                  <Col className="m-3" sm="12" md="3">
                    <Button
                      disabled={validating || submitting}
                      className="btn-icon"
                      block
                      color="success"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">
                        Guardar Información
                      </span>
                    </Button>
                  </Col>
                </div>
              </>
            )}
            {step === 4 && (
              <>
                <div className="form-row align-items-center mr-1 justify-content-center">
                  <Col className="m-3" sm="12" md="3">
                    <Button
                      disabled={validating || submitting}
                      className="btn-icon"
                      block
                      color="success"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">
                        Actualizar Información
                      </span>
                    </Button>
                  </Col>
                </div>
              </>
            )}
          </FormRS>
        )}
      />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={otherInitialValues}
        render={({ handleSubmit, values, submitting, validating, valid }) => (
          <FormRS onSubmit={handleSubmit}>
            {step !== 1 && (
              <>
                <Divider
                  className="heading-small text-muted"
                  style={{ fontSize: ".75rem" }}
                >
                  Contacto de Emergencia
                </Divider>
                <div className="form-row align-items-center mr-1">
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label className="form-control-label" for="contactName">
                        Nombre Completo
                      </Label>
                      <Field name="contactName">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Escriba aquí el nombre completo"
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label className="form-control-label" for="telephone">
                        Teléfono
                      </Label>
                      <Field name="telephone">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="number"
                              placeholder="Escriba aquí el número de teléfono"
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label className="form-control-label" for="relationship">
                        Parentesco
                      </Label>
                      <Field name="relationship">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Escriba aquí el tipo de parentesco"
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
                <Row>
                  <Col className="mb-1" sm="12" md="10">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        for="contactAddress"
                      >
                        Dirección (500)
                      </Label>
                      <Field name="contactAddress">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="textarea"
                              placeholder="Escriba aquí la dirección exacta."
                              maxLength={500}
                              rows="2"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col
                    className="mb-1 d-flex align-items-center justify-content-center"
                    sm="12"
                    md="2"
                  >
                    <Button
                      className="btn-icon"
                      color="info"
                      type="button"
                      onClick={() => addEmergencyContact(values)}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Agregar</span>
                    </Button>
                  </Col>
                </Row>
                <div className="form-row align-items-center mr-1 justify-content-center">
                  {emergencyContacts.length ? (
                    <>
                      <Table className="align-items-center mb-3" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "normal",
                                textAlign: "center",
                              }}
                            >
                              Nombre Completo
                            </th>
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "normal",
                                textAlign: "center",
                              }}
                            >
                              Teléfono
                            </th>
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "normal",
                                textAlign: "center",
                              }}
                            >
                              Parentesco
                            </th>
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "normal",
                                textAlign: "center",
                              }}
                            >
                              Dirección
                            </th>
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "normal",
                                textAlign: "center",
                              }}
                            >
                              Creado
                            </th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {emergencyContacts
                            .slice(0, all ? emergencyContacts.length : 2)
                            .map((row, key) => (
                              <tr key={key}>
                                <th
                                  scope="row"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  {row.name}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  {row.phone}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  {row.relation}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "justify",
                                  }}
                                >
                                  {row.address}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  {moment(row.createdAt)
                                    .utc()
                                    .utcOffset(moment().utcOffset())
                                    .format("DD/MM/YYYY hh:mm:ss A")}
                                </th>
                                <th
                                  className="table-actions"
                                  scope="row"
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  <a
                                    className="table-action table-action"
                                    href="#"
                                    id="edit"
                                    onClick={() => editContact(row)}
                                  >
                                    <i className="fas fa-edit" />
                                  </a>
                                  <a
                                    className="table-action table-action-delete"
                                    href="#"
                                    id="reject"
                                    onClick={() => deleteContact(row)}
                                  >
                                    <i className="fas fa-trash" />
                                  </a>
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      {emergencyContacts.length > 2 && (
                        <div className="form-row align-items-center">
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="info"
                            type="button"
                            onClick={() => onShowAll(all ? "less" : "all")}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i
                                className={all ? "fas fa-times" : "fas fa-plus"}
                              />
                            </span>
                            <span className="btn-inner--text">
                              {all ? "Mostrar Menos" : "Mostrar Más"}
                            </span>
                          </Button>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </>
            )}
            {step > 2 && (
              <>
                <Divider
                  className="heading-small text-muted"
                  style={{ fontSize: ".75rem" }}
                >
                  Información Médica
                </Divider>
                <Tabs
                  defaultActiveKey={tabKeyMedical}
                  onChange={changeTabMedical}
                  className="pb-2"
                >
                  <TabPane tab="Alergias" key="1">
                    <div className="form-row align-items-center mr-1">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="allergyName"
                          >
                            Nombre
                          </Label>
                          <Field name="allergyName">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Escriba aquí el nombre de la alergia"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="allergyStartedDate"
                          >
                            Fecha de Inicio
                          </Label>
                          <Field name="allergyStartedDate">
                            {({ input, meta }) => (
                              <>
                                <ReactDatetime
                                  {...input}
                                  inputProps={{
                                    placeholder: "Ejemplo: 18/12/1994",
                                    className:
                                      !meta.error &&
                                      meta.modified &&
                                      meta.touched
                                        ? "is-valid form-control"
                                        : meta.error && meta.touched
                                        ? "is-invalid form-control"
                                        : "form-control",
                                  }}
                                  timeFormat={false}
                                />
                                {meta.error && meta.touched && (
                                  <span
                                    style={{
                                      marginTop: "0.25rem",
                                      fontSize: "80%",
                                      color: "#fb6340",
                                    }}
                                  >
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="allergyStillHappening"
                          >
                            ¿Sigue Pasando?
                          </Label>
                          <Field name="allergyStillHappening">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una opción
                                  </option>
                                  <option key="1" value="1">
                                    Sí
                                  </option>
                                  <option key="2" value="2">
                                    No
                                  </option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center mr-1">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="allergyMedicated"
                          >
                            ¿Medicado?
                          </Label>
                          <Field name="allergyMedicated">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                  onClick={medicatedEvent}
                                >
                                  <option key="0" value="0">
                                    Seleccione una opción
                                  </option>
                                  <option key="1" value="1">
                                    Sí
                                  </option>
                                  <option key="2" value="2">
                                    No
                                  </option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="allergySymptom"
                          >
                            Síntoma
                          </Label>
                          <Field name="allergySymptom">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Escriba aquí el síntoma"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col
                        className="mb-1 d-flex align-items-center justify-content-center"
                        sm="12"
                        md="4"
                      >
                        <Button
                          className="btn-icon"
                          color="info"
                          type="button"
                          onClick={() => onAddMedicalInfo(values, "allergy")}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Agregar</span>
                        </Button>
                      </Col>
                    </div>
                    <Table className="align-items-center pb-3" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Nombre
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Fecha de Inicio
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            ¿Sigue Pasando?
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            ¿Medicado?
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Síntoma
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Medicamentos
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {allergies.length ? (
                          allergies.map((row, key) => (
                            <tr key={key}>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.name}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.startedDate}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.stillHappening === 1 ? "Sí" : "No"}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.medicated === 1 ? "Sí" : "No"}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.simptoms}
                              </th>
                              <th
                                key={key}
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.medicated === 1 ? (
                                  <Button
                                    size="sm"
                                    color="info"
                                    type="button"
                                    onClick={() => viewMedications(row)}
                                    className="btn-icon btn-2"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-info" />
                                    </span>
                                  </Button>
                                ) : (
                                  "N/A"
                                )}
                              </th>
                              <th
                                className="table-actions"
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                <a
                                  className="table-action table-action"
                                  href="#"
                                  id="edit"
                                  onClick={() => editAllergy(row)}
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <a
                                  className="table-action table-action-delete"
                                  href="#"
                                  id="reject"
                                  onClick={() => deleteAllergy(row)}
                                >
                                  <i className="fas fa-trash" />
                                </a>
                              </th>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div className="pt-2 pb-2">
                                <h5 className="h3 title text-center">
                                  <span className="d-block mb-1">
                                    Sin alergias registradas
                                  </span>
                                  <small className="h3 font-weight-light text-muted">
                                    Al día de hoy no tienes alergias registradas
                                    en el sistema.
                                  </small>
                                </h5>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                  <TabPane tab="Enfermedades" key="2">
                    <div className="form-row align-items-center mr-1">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="diseaseName"
                          >
                            Nombre
                          </Label>
                          <Field name="diseaseName">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Escriba aquí el nombre de la enfermedad"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="diseaseNameStartedDate"
                          >
                            Fecha de Inicio
                          </Label>
                          <Field name="diseaseNameStartedDate">
                            {({ input, meta }) => (
                              <>
                                <ReactDatetime
                                  {...input}
                                  inputProps={{
                                    placeholder: "Ejemplo: 18/12/1994",
                                    className:
                                      !meta.error &&
                                      meta.modified &&
                                      meta.touched
                                        ? "is-valid form-control"
                                        : meta.error && meta.touched
                                        ? "is-invalid form-control"
                                        : "form-control",
                                  }}
                                  timeFormat={false}
                                />
                                {meta.error && meta.touched && (
                                  <span
                                    style={{
                                      marginTop: "0.25rem",
                                      fontSize: "80%",
                                      color: "#fb6340",
                                    }}
                                  >
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="diseaseStillHappening"
                          >
                            ¿Sigue Pasando?
                          </Label>
                          <Field name="diseaseStillHappening">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una opción
                                  </option>
                                  <option key="1" value="1">
                                    Sí
                                  </option>
                                  <option key="2" value="2">
                                    No
                                  </option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center mr-1">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="diseaseMedicated"
                          >
                            ¿Medicado?
                          </Label>
                          <Field name="diseaseMedicated">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                  onClick={medicatedEvent}
                                >
                                  <option key="0" value="0">
                                    Seleccione una opción
                                  </option>
                                  <option key="1" value="1">
                                    Sí
                                  </option>
                                  <option key="2" value="2">
                                    No
                                  </option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="6">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="diseaseReason"
                          >
                            Razon
                          </Label>
                          <Field name="diseaseReason">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  placeholder="Escriba aquí las razones de la enfermedad."
                                  maxLength={500}
                                  rows="2"
                                  resize="none"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col
                        className="mb-1 d-flex align-items-center justify-content-center"
                        sm="12"
                        md="2"
                      >
                        <Button
                          className="btn-icon"
                          color="info"
                          type="button"
                          onClick={() => onAddMedicalInfo(values, "disease")}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Agregar</span>
                        </Button>
                      </Col>
                    </div>
                    <Table className="align-items-center pb-3" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Nombre
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Fecha de Inicio
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            ¿Sigue Pasando?
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Razon
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Medicamentos
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {diseases.length ? (
                          diseases.map((row, key) => (
                            <tr key={key}>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.disease}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.startedDate}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.stillHappening === 1 ? "Sí" : "No"}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.reason}
                              </th>
                              <th
                                key={key}
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.medicated === 1 ? (
                                  <Button
                                    size="sm"
                                    color="info"
                                    type="button"
                                    onClick={() => viewMedications(row)}
                                    className="btn-icon btn-2"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-info" />
                                    </span>
                                  </Button>
                                ) : (
                                  "N/A"
                                )}
                              </th>
                              <th
                                className="table-actions"
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                <a
                                  className="table-action table-action"
                                  href="#"
                                  id="edit"
                                  onClick={() => editDisease(row)}
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <a
                                  className="table-action table-action-delete"
                                  href="#"
                                  id="reject"
                                  onClick={() => deleteDisease(row)}
                                >
                                  <i className="fas fa-trash" />
                                </a>
                              </th>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <div className="pt-2 pb-2">
                                <h5 className="h3 title text-center">
                                  <span className="d-block mb-1">
                                    Sin enfermedades registradas
                                  </span>
                                  <small className="h3 font-weight-light text-muted">
                                    Al día de hoy no tienes enfermedades
                                    registradas en el sistema.
                                  </small>
                                </h5>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                  <TabPane tab="Intervenciones Quirúrgicas" key="3">
                    <div className="form-row align-items-center mr-1">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="interventionsReason"
                          >
                            Razon
                          </Label>
                          <Field name="interventionsReason">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Escriba aquí la razon de la Intervención"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="interventionsDate"
                          >
                            Fecha
                          </Label>
                          <Field name="interventionsDate">
                            {({ input, meta }) => (
                              <>
                                <ReactDatetime
                                  {...input}
                                  inputProps={{
                                    placeholder: "Ejemplo: 18/12/1994",
                                    className:
                                      !meta.error &&
                                      meta.modified &&
                                      meta.touched
                                        ? "is-valid form-control"
                                        : meta.error && meta.touched
                                        ? "is-invalid form-control"
                                        : "form-control",
                                  }}
                                  timeFormat={false}
                                />
                                {meta.error && meta.touched && (
                                  <span
                                    style={{
                                      marginTop: "0.25rem",
                                      fontSize: "80%",
                                      color: "#fb6340",
                                    }}
                                  >
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="interventionsRequireMedication"
                          >
                            ¿Requiere Medicación?
                          </Label>
                          <Field name="interventionsRequireMedication">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                  onClick={medicatedEvent}
                                >
                                  <option key="0" value="0">
                                    Seleccione una opción
                                  </option>
                                  <option key="1" value="1">
                                    Sí
                                  </option>
                                  <option key="2" value="2">
                                    No
                                  </option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center mr-1">
                      <Col className="mb-1" sm="12" md="8">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="interventionsNotes"
                          >
                            Notas
                          </Label>
                          <Field name="interventionsNotes">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  placeholder="Escriba aquí notas de las intervisiones."
                                  maxLength={500}
                                  rows="2"
                                  resize="none"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col
                        className="mb-1 d-flex align-items-center justify-content-center"
                        sm="12"
                        md="4"
                      >
                        <Button
                          className="btn-icon"
                          color="info"
                          type="button"
                          onClick={() =>
                            onAddMedicalInfo(values, "interventions")
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Agregar</span>
                        </Button>
                      </Col>
                    </div>
                    <Table className="align-items-center pb-3" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Razon
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Fecha
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            ¿Requiere Medicación?
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Notas
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Medicamentos
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {interventions.length ? (
                          interventions.map((row, key) => (
                            <tr key={key}>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.reason}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.date}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.medicated === 1 ? "Sí" : "No"}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.notes}
                              </th>
                              <th
                                key={key}
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.medicated === 1 ? (
                                  <Button
                                    size="sm"
                                    color="info"
                                    type="button"
                                    onClick={() => viewMedications(row)}
                                    className="btn-icon btn-2"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-info" />
                                    </span>
                                  </Button>
                                ) : (
                                  "N/A"
                                )}
                              </th>
                              <th
                                className="table-actions"
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                <a
                                  className="table-action table-action"
                                  href="#"
                                  id="edit"
                                  onClick={() => editIntervention(row)}
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <a
                                  className="table-action table-action-delete"
                                  href="#"
                                  id="reject"
                                  onClick={() => deleteIntervention(row)}
                                >
                                  <i className="fas fa-trash" />
                                </a>
                              </th>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <div className="pt-2 pb-2">
                                <h5 className="h3 title text-center">
                                  <span className="d-block mb-1">
                                    Sin intervisiones quirúrgicas registradas
                                  </span>
                                  <small className="h3 font-weight-light text-muted">
                                    Al día de hoy no tienes intervisiones
                                    quirúrgicas registradas en el sistema.
                                  </small>
                                </h5>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                  <TabPane tab="Vacunas" key="4">
                    <div className="form-row align-items-center mr-1 pb-1">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="medicalType"
                          >
                            Tipo
                          </Label>
                          <Field name="medicalType">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una opción
                                  </option>
                                  {options.otherTypes.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>
                                        {row.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="medicalDate"
                          >
                            Fecha
                          </Label>
                          <Field name="medicalDate">
                            {({ input, meta }) => (
                              <>
                                <ReactDatetime
                                  {...input}
                                  inputProps={{
                                    placeholder: "Ejemplo: 18/12/1994",
                                    className:
                                      !meta.error &&
                                      meta.modified &&
                                      meta.touched
                                        ? "is-valid form-control"
                                        : meta.error && meta.touched
                                        ? "is-invalid form-control"
                                        : "form-control",
                                  }}
                                  timeFormat={false}
                                />
                                {meta.error && meta.touched && (
                                  <span
                                    style={{
                                      marginTop: "0.25rem",
                                      fontSize: "80%",
                                      color: "#fb6340",
                                    }}
                                  >
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="medicalName"
                          >
                            Nombre (Casa Farmacéutica)
                          </Label>
                          <Field name="medicalName">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Escriba aquí el nombre"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center mr-1 pb-1">
                      <Col className="mb-1" sm="12" md="8">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="medicalDescription"
                          >
                            Descripción (Efectos Secundarios)
                          </Label>
                          <Field name="medicalDescription">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  placeholder="Escriba aquí si presento efectos secundarios."
                                  maxLength={500}
                                  rows="2"
                                  resize="none"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col
                        className="mb-1 d-flex align-items-center justify-content-center"
                        sm="12"
                        md="4"
                      >
                        <Button
                          className="btn-icon"
                          color="info"
                          type="button"
                          onClick={() => onAddMedicalInfo(values, "medical")}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Agregar</span>
                        </Button>
                      </Col>
                    </div>
                    <Table className="align-items-center pb-4" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Tipo
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Nombre
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Descripción
                          </th>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            Fecha
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {otherMedicalInfo.length ? (
                          otherMedicalInfo.map((row, key) => (
                            <tr key={key}>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.typeName}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.name}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.description}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                {row.date}
                              </th>
                              <th
                                className="table-actions"
                                scope="row"
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "center",
                                }}
                              >
                                <a
                                  className="table-action table-action"
                                  href="#"
                                  id="edit"
                                  onClick={() => editOtherMedicalInfo(row)}
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <a
                                  className="table-action table-action-delete"
                                  href="#"
                                  id="reject"
                                  onClick={() => deleteOtherMedicalInfo(row)}
                                >
                                  <i className="fas fa-trash" />
                                </a>
                              </th>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <div className="pt-2 pb-2">
                                <h5 className="h3 title text-center">
                                  <span className="d-block mb-1">
                                    Sin información médica adicional registrada
                                  </span>
                                  <small className="h3 font-weight-light text-muted">
                                    Al día de hoy no tienes información médica
                                    adicional registrada en el sistema.
                                  </small>
                                </h5>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                </Tabs>
                <div className="form-row align-items-center mr-1 pb-3">
                  <Col className="m-2" sm="12">
                    <Label className="form-control-label" for="files">
                      Adjuntar Archivos
                    </Label>
                    <Dragger {...uploadProps} fileList={fileList}>
                      <p className="ant-uploading-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-uploading-text">
                        Suelte el archivo aquí o haga clic para cargar.
                      </p>
                      <p className="ant-uploading-hint">
                        *En caso de tener documentos de referencia
                      </p>
                    </Dragger>
                  </Col>
                </div>
                {initialValues.attachments.length ? (
                  <div className="card-deck flex-column flex-xl-row">
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col sm="12" md="8">
                            <h5 className="h4 mb-0">Documentos Cargados</h5>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <ListGroup className="list my--3" flush>
                          {initialValues.attachments.map((row, key) => (
                            <ListGroupItem key={key} className="px-0">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    alt="Notificación"
                                    className="avatar rounded-circle"
                                    src={require(`assets/img/theme/attachment.png`)}
                                  />
                                </Col>
                                <div className="col ml--2">
                                  <h4 className="mb-0">{row.filename}</h4>
                                  <small>
                                    {`${moment(row.createdAt)
                                      .utc()
                                      .utcOffset(moment().utcOffset())
                                      .format("LLL")}`}
                                  </small>
                                </div>
                                <Col className="col-auto">
                                  <Button
                                    color="info"
                                    size="sm"
                                    onClick={() => onDownload(row)}
                                  >
                                    <span className="btn-inner--icon mr-1">
                                      <i className="fas fa-file-download" />
                                    </span>
                                    <span className="btn-inner--text">
                                      Descargar
                                    </span>
                                  </Button>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </CardBody>
                    </Card>
                  </div>
                ) : null}
              </>
            )}
          </FormRS>
        )}
      />
    </>
  );
};

MedicalReportForm.propTypes = {
  emergencyContacts: PropTypes.array.isRequired,
};

export default MedicalReportForm;
