/*
=========================================================
*Notifications - Extra Hours report pending to approve
=========================================================

*Description.
This component creates all the view of the extra hours report pending to approve.

=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React, { useRef, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SolicitudSection from "./Sections.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import NotificationAlert from "react-notification-alert";
import { Spin } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalDetailsExtra from "components/HoursReport/ExtraHours/ModalDetailsExtra.jsx";
import { MdInfoOutline } from "react-icons/md";

import {
  getRequestPendingForApproval,
  setExtraCEO,
  setExtraPreApprover,
} from "actions/HoursReport.jsx";
import ModalRejectReason from "./ModalRejectReason.jsx";

function ExtraHoursReport({ name, parentName }) {
  const [reasonRejected, setReasonRejected] = useState("");
  const [showRejectMessage, setShowRejectMessage] = useState(false);
  const [flag, setFlag] = useState(false);

  const handleRejectMessage = () => {
    setShowRejectMessage(!showRejectMessage);
  };

  const onInputMessage = (message) => {
    setReasonRejected(message);
  };

  const handleAccepted = async () => {
    let data;
    if (idRol === "CEO") {
      data = {
        itemID: reportDetails.id,
        decision: "Aprobada",
        reason: "N/A",
        decisionBy: "bss",
        sapStatus: "Enviar",
        moreTimeDenied: 0,
      };
    } else {
      data = {
        itemID: reportDetails.id,
        decision: "Aprobada",
        reason: "N/A",
        bossDecision: "En espera",
        decisionBy: "pre",
        moreTimeDenied: 0,
        sapStatus: "No enviar",
      };
    }

    const MySwal = withReactContent(Swal);
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      text: `¿Quieres aceptar este reporte de horas extra?`,
      showCancelButton: true,
      confirmButtonColor: "#2dce89",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    });

    if (result.value) {
      if (idRol === "CEO") {
        await sendExtraCEO(data);
      } else {
        await sendExtraPreapprover(data);
      }
    }
  };

  const handleRejected = async () => {
    let data;
    if (idRol === "CEO") {
      data = {
        itemID: reportDetails.id,
        decision: "Rechazada",
        reason: reasonRejected,
        decisionBy: "bss",
        sapStatus: "No enviar",
        moreTimeDenied: 0,
      };
    } else {
      data = {
        itemID: reportDetails.id,
        decision: "Rechazada",
        reason: reasonRejected,
        bossDecision: "Rechazada",
        decisionBy: "pre",
        moreTimeDenied: 0,
        sapStatus: "No enviar",
      };
    }

    const MySwal = withReactContent(Swal);
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      text: `¿Quieres rechazar este reporte de horas extra?`,
      showCancelButton: true,
      confirmButtonColor: "#2dce89",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    });

    if (result.value) {
      if (idRol === "CEO") {
        await sendExtraCEO(data);
      } else {
        await sendExtraPreapprover(data);
      }
    }
  };

  const sendExtraPreapprover = async (data) => {
    setSpinnerInfo({
      loading: true,
      message: "Actualizando estado del reporte",
    });
    const resp = await dispatch(setExtraPreApprover(data));
    if ("isAxiosError" in resp.payload) {
      const { message } = resp.payload.response.data.payload;
      const { status } = resp.payload.response.data;
      if (status === 500) {
        notify("danger", "Falló", message);
        setShowRejectMessage(false);
        setShowReportDetails(false);
      } else if (status === 404) {
        notify("warning", "Atención", message);
        setShowRejectMessage(false);
        setShowReportDetails(false);
      } else if (status === 422) {
        notify("warning", "Atención", message);
        setShowRejectMessage(false);
        setShowReportDetails(false);
      }
    } else {
      const { data } = resp.payload;
      if (data.status === 200) {
        notify(
          "success",
          "Estado actualizado exitosamente",
          data.payload.message
        );
        setReasonRejected("");
        setShowRejectMessage(false);
        setShowReportDetails(false);
        setFlag(!flag);
        setReportDetails(null);
        setIdRol(null);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  const sendExtraCEO = async (data) => {
    setSpinnerInfo({
      loading: true,
      message: "Actualizando estado del reporte",
    });
    const resp = await dispatch(setExtraCEO(data));

    if ("isAxiosError" in resp.payload) {
      const { message } = resp.payload.response.data.payload;
      const { status } = resp.payload.response.data;

      if (status === 500) {
        notify("danger", "Falló", message);
        setShowRejectMessage(false);
        setShowReportDetails(false);
      } else if (status === 404) {
        notify("warning", "Atención", message);
        setShowRejectMessage(false);
        setShowReportDetails(false);
      } else if (status === 422) {
        notify("warning", "Atención", message);
        setShowRejectMessage(false);
        setShowReportDetails(false);
      }
    } else {
      const { data } = resp.payload;
      if (data.status === 200) {
        notify(
          "success",
          "Estado actualizado exitosamente",
          data.payload.message
        );
        setReasonRejected("");
        setShowRejectMessage(false);
        setShowReportDetails(false);
        setFlag(!flag);
        setReportDetails(null);
        setIdRol(null);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };
  const dispatch = useDispatch();
  const notifyEl = useRef(null);
  const [spinnerInfo, setSpinnerInfo] = useState({
    loading: false,
    message: null,
  });

  const [pendingReports, setPendingReports] = useState(null);
  const [showReportDetails, setShowReportDetails] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [idRol, setIdRol] = useState(null);

  const onSelect = (report, idRol) => {
    setReportDetails(report);
    setIdRol(idRol);
    setShowReportDetails(!showReportDetails);
  };

  const formatDetailsExtra = (key, request) => {
    if (key === "Orden/item") {
      return request.info === "-/-" ? "Ninguna orden reportada" : request.info;
    } else if (key === "motivoRechazo") {
      return request.ceoReason;
    } else {
      return request.preApproverName !== "N/A"
        ? request.preApproverName
        : "No necesita preaprobación";
    }
  };

  const notify = (type, title, message) => {
    try {
      let options = {
        place: "br",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {title}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 7,
      };
      notifyEl.current.notificationAlert(options);
    } catch (error) {}
  };

  const getPendingReports = async () => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo reportes pendientes de aprobar",
    });
    const resp = await dispatch(getRequestPendingForApproval());
    if ("isAxiosError" in resp.payload) {
      const { message } = resp.payload.response.data.payload;
      const { status } = resp.payload.data.payload.status;
      if (status === 500) {
        notify("danger", "Falló", message);
      } else if (status === 404) {
        notify("warning", "Atención", message);
      }
    } else {
      const { data } = resp.payload;
      if (data.status === 200) {
        setPendingReports(data.payload.data);
        notify("success", "Obtenidas exitosamente", data.payload.message);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  useEffect(() => {
    const fetchReports = async () => {
      await getPendingReports();
    };
    fetchReports();
  }, [flag]);

  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyEl} />
      </div>
      <AdminHeader name={name} parentName={parentName} />
      <Container className="mt--6" fluid>
        <div style={{ marginBottom: "2rem" }}></div>
        <Spin
          size="large"
          spinning={spinnerInfo.loading}
          tip={spinnerInfo.message}
        >
          {showReportDetails && (
            <ModalDetailsExtra
              isOpen={showReportDetails}
              handleClose={onSelect}
              request={reportDetails}
              formatDetails={formatDetailsExtra}
              isNotification={true}
              onAccept={handleAccepted}
              onReject={handleRejectMessage}
              idRol={idRol}
            />
          )}

          {showRejectMessage && (
            <ModalRejectReason
              isOpen={showRejectMessage}
              handleClose={handleRejectMessage}
              onChange={onInputMessage}
              buttonOnClick={handleRejected}
            />
          )}

          {pendingReports === null ? (
            <Spin
              size="large"
              spinning={true}
              tip={"Cargando reportes pendientes de aprobación"}
            ></Spin>
          ) : pendingReports[0].ceo.length === 0 &&
            pendingReports[0].preapprover.length === 0 ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <h3>No quedan reportes pendientes de aprobación </h3>
              <MdInfoOutline className="mr-2" size={40} />
            </div>
          ) : (
            <>
              {pendingReports[0].preapprover &&
              pendingReports[0].preapprover.length > 0 &&
              pendingReports[0].ceo &&
              pendingReports[0].ceo.length > 0 ? (
                <>
                  <div
                    className="solicitud-section"
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <SolicitudSection
                      title="Pendientes como preaprobador"
                      cards={pendingReports[0].preapprover}
                      onSelect={onSelect}
                      idRol={"PREAPPROVER"}
                    />
                  </div>
                  <div
                    className="solicitud-section"
                    style={{ marginTop: "20px" }}
                  >
                    <SolicitudSection
                      title="Pendientes como CEO"
                      cards={pendingReports[0].ceo}
                      onSelect={onSelect}
                      idRol={"CEO"}
                    />
                  </div>
                </>
              ) : (
                <>
                  {pendingReports[0].preapprover &&
                  pendingReports[0].preapprover.length > 0 ? (
                    <SolicitudSection
                      title="Pendientes como preaprobador"
                      cards={pendingReports[0].preapprover}
                      onSelect={onSelect}
                      idRol={"PREAPPROVER"}
                    />
                  ) : null}
                  {pendingReports[0].ceo && pendingReports[0].ceo.length > 0 ? (
                    <SolicitudSection
                      title="Pendientes como CEO"
                      cards={pendingReports[0].ceo}
                      onSelect={onSelect}
                      idRol={"CEO"}
                    />
                  ) : null}
                </>
              )}
            </>
          )}
        </Spin>
      </Container>
    </div>
  );
}

ExtraHoursReport.defaultProps = {
  name: "Notificaciones",
  parentName: "Aprobación de horas extra",
};

ExtraHoursReport.propTypes = {
  preaprobadorCard: PropTypes.array.isRequired,
  managerCard: PropTypes.array.isRequired,
};

export default ExtraHoursReport;
