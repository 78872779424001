/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to show details of an extra hours report
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/
import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
} from "reactstrap";

import { RiErrorWarningFill } from "react-icons/ri";
import { FaCalendarAlt, FaClock, FaClipboard } from "react-icons/fa";
import {
  MdComment,
  MdAccountCircle,
  MdCancelPresentation,
} from "react-icons/md";
import { BiCheckCircle } from "react-icons/bi";
import { FaTasks } from "react-icons/fa";

function ModalDetailsExtra({
  isOpen,
  handleClose,
  request,
  formatDetails,
  isNotification,
  onAccept,
  onReject,
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader toggle={handleClose}>
        <span className="font-weight-bold" style={{ fontSize: "1.20em" }}>
          Detalles de reporte Horas Extra
        </span>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <FaClipboard
                  className="mr-2 mb-1"
                  style={{ fontSize: "14px" }}
                />
                ID Reporte:
              </Label>
              <Input type="text" value={request.id} readOnly />
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <RiErrorWarningFill
                  className="mr-2 mb-1"
                  style={{ fontSize: "16px" }}
                />
                Estado del reporte:
              </Label>
              <InputGroup>
                <Input type="text" value={request.status} readOnly />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <FaCalendarAlt
                  className="mr-2 mb-1"
                  style={{ fontSize: "14px" }}
                />
                Fecha reportada:
              </Label>
              <Input
                type="text"
                value={request.date.substring(0, 10)}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <FaClock className="mr-2 mb-1" style={{ fontSize: "14px" }} />
                Horas especificadas:
              </Label>
              <Input
                type="text"
                value={`${request.time.substring(
                  0,
                  5
                )} - ${request.endTime.substring(0, 5)}`}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <BiCheckCircle
                  className="mr-2 mb-1"
                  style={{ fontSize: "16px" }}
                />
                Justificación:
              </Label>
              <textarea
                className="form-control"
                value={request.justification}
                readOnly
                resize="none"
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <MdComment className="mr-2 mb-1" style={{ fontSize: "16px" }} />
                Comentario:
              </Label>
              <textarea
                className="form-control"
                value={request.jobDescription}
                readOnly
                resize="none"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <MdAccountCircle
                  className="mr-2 mb-1"
                  style={{ fontSize: "16px" }}
                />
                Jefatura:
              </Label>
              <Input type="text" value={request.ceoName} readOnly />
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <MdAccountCircle
                  className="mr-2 mb-1"
                  style={{ fontSize: "16px" }}
                />
                Preaprobador:
              </Label>
              <Input
                type="text"
                value={formatDetails("preapprover", request)}
                readOnly
              />
            </FormGroup>
          </Col>
          {request.info !== "-/-" && (
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label className="font-weight-bold">
                  <FaTasks className="mr-2 mb-1" style={{ fontSize: "16px" }} />
                  Orden/item relacionado:
                </Label>
                <Input
                  type="text"
                  value={formatDetails("Orden/item", request)}
                  readOnly
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          {request.status === "Rechazada" && (
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label className="font-weight-bold">
                  <MdCancelPresentation
                    className="mr-2 mb-1"
                    style={{ fontSize: "18px" }}
                  />
                  Motivo de rechazo:
                </Label>
                <textarea
                  className="form-control"
                  value={formatDetails("motivoRechazo", request)}
                  readOnly
                  resize="none"
                />
              </FormGroup>
            </Col>
          )}
        </Row>
      </ModalBody>

      <ModalFooter>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isNotification && (
            <div
              style={{
                display: "flex",
                gap: "1rem",
                marginLeft: 0,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#2dce89",
                  color: "white",
                  width: "120px",
                }}
                type="button"
                onClick={onAccept}
              >
                Aceptar
              </Button>

              <Button
                style={{
                  backgroundColor: "#f5365c",
                  color: "white",
                  width: "120px",
                }}
                type="button"
                onClick={onReject}
                className="mr-3"
              >
                Rechazar
              </Button>
            </div>
          )}

          {!isNotification && (
            <Button
              style={{ backgroundColor: "#1275bc", color: "white" }}
              type="button"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}

ModalDetailsExtra.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
};

export default ModalDetailsExtra;
