// core actions
import { findTargetLetterByGeneralManager, updateTargetLetterFlow } from "actions/targetLetter.jsx";
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import CardEmpty from 'components/Shared/Notifications/CardEmpty.jsx';
// core components TargetLetter
import CardLetters from 'components/TargetLetter/CardLetters.jsx';
import CardTargetLetter from 'components/TargetLetter/CardTargetLetter.jsx';
import WorkFlowModal from 'components/TargetLetter/WorkFlowModal.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getTargetsLetterGeneralManager } from "selectors/targetLetter.jsx";

class TargetLettersGeneralManager extends Component {

  constructor(props) {
    super(props);
    this.state = {
      willMount: true,
      alert: null,
      loading: false,
      showModal: false,
      selectedLetter: null,
    };
  };

  componentDidMount() {
    this.props.findTargetLetterByGeneralManager().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        willMount: false,
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleOpenLetter = row => {
    this.setState({
      selectedLetter: row
    });
  };

  handleOnBack = () => {
    this.setState({
      selectedLetter: null
    });
  };

  handleOnOpenFlow = () => {
    this.setState({
      showModal: true
    });
  };

  handleOnWorkFlow = values => {
    const { selectedLetter: { id } } = this.state;
    const {
      action,
      comments
    } = values;
    this.setState({
      loading: true,
    });
    this.props.updateTargetLetterFlow(id, {
      action,
      desicion: 'G_MANAGERS',
      comments
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false,
        showModal: false,
        selectedLetter: null
      });
    });
  };

  render() {

    const {
      name,
      parentName,
      letters,
    } = this.props;

    const {
      alert,
      loading,
      showModal,
      willMount,
      selectedLetter,
    } = this.state;

    console.log(letters);

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <WorkFlowModal
          loading={loading}
          showModal={showModal}
          toggleModal={this.toggleModal}
          onWorkFlow={this.handleOnWorkFlow}
        />
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          {
            willMount ?
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando las cartas de objetivos pendientes por revisar" />
                </Col>
              </Row>
              :
              selectedLetter ?
                <CardTargetLetter
                  targetLetter={selectedLetter}
                  onBack={this.handleOnBack}
                  onOpenFlow={this.handleOnOpenFlow}
                />
                :
                letters.length ?
                  <Row>
                    <Col>
                      <CardLetters
                        manager
                        title="Cartas de objetivos pendientes de revisar"
                        letters={letters}
                        openLetter={this.handleOpenLetter}
                      />
                    </Col>
                  </Row>
                  :
                  <Row className="justify-content-center">
                    <Col sm="12" md="8">
                      <CardEmpty
                        title="No hay cartas pendientes."
                        subtitle="Al día de hoy no tienes cartas de objetivos pendientes, en el momento que exista una nueva, aparecerán en esta ventana."
                      />
                    </Col>
                  </Row>}
        </Container>
      </>
    );
  }
}

TargetLettersGeneralManager.defaultProps = {
  name: "Carta de objetivos",
  parentName: "Notificaciones",
  letters: [],
};

TargetLettersGeneralManager.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  letters: PropTypes.array.isRequired,
};

// export default TargetLettersGeneralManager;
const mapStateToProps = state => ({
  letters: getTargetsLetterGeneralManager(state),
});

export default withRouter(connect(mapStateToProps, {
  findTargetLetterByGeneralManager,
  updateTargetLetterFlow
})(TargetLettersGeneralManager));