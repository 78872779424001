/*!

=========================================================
*MasterData - MyRequestsTableMasterData
=========================================================

* Este componente contiene una tabla con todas las aprobaciones de 
*datos maestros dependiendo de su Rol
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerías de react.
import React, { useState, useEffect } from 'react'
import {
  PaginationItem,
  PaginationLink,
  Pagination,
  CardHeader,
  FormGroup,
  Button,
  Input,
  Table,
  Badge,
  Card,
  Col,
  Row
} from "reactstrap";

//Footer de la tabla
import CardFooter from 'reactstrap/lib/CardFooter';

//Multiselect
import { MultiSelect } from "react-multi-select-component";

// imports de actions
import {
  getDataApprovalsRows,
} from 'actions/masterData';

//Import de Spinner
import { Spin } from "antd";


//Import para consulta  en DB
import { useDispatch } from 'react-redux';

//Funciones para averiguar el usuario y sus roles en SS
import { getUsernameLogged, getTeamsUserAccess } from 'selectors/adminLayout';


export const ApprovalsTableMasterData = ({
  setReloadTableRequests,
  reloadTableRequests,
  setModalDetail,
}) => {

  //Declaración de disparador para el api
  const dispatch = useDispatch();

  //#region Establecer array de columnas 
  //Columnas para el multicolumn y si la seleccionan se agrega al columnsSelectedTableRequests
  const optionsRequestsSelect = [

    { value: "seeDetail", label: "Botón", disabled: true },
    { value: "Gestión", label: "Gestión" },
    { value: "Formulario", label: "Proceso", disabled: true },
    { value: "Fecha", label: "Fecha" },
    { value: "createdBy", label: "Solicitante" },
    { value: "typeOfManagementSingle", label: "Operación" },
    { value: "Estado", label: "Estado" },
    { value: "Factor", label: "Factor" },
    { value: "Aprobadores", label: "Aprobadores" },
    { value: "Respuesta", label: "Respuesta" },
    { value: "method", label: "Método" },
    { value: "comment", label: "Comentario" },
    { value: "commentApproval", label: "Comentario Aprobador" },
    { value: "createdBy", label: "Creado por" },
    { value: "approvedBy", label: "Aprobado por" },
    { value: "finalizationAt", label: "Finalizado en" },
    { value: "approvallAt", label: "Aprobado en" },
    { value: "createdAt", label: "Creado en" },
  ];

  //Columnas seleccionadas por defecto en la tabla.
  const [columnsSelectedTableRequests, setColumnsSelectedTableRequests] = useState(
    [

      { value: "seeDetail", label: "Botón", width: 60 },
      { value: "Gestion", label: "Gestión", width: 60 },
      { value: "Formulario", label: "Formulario", width: 90 },
      { value: "Fecha", label: "Fecha", width: 70 },
      { value: "Factor", label: "Factor", width: 70 },
      { value: "createdBy", label: "Solicitante", width: 90 },
      { value: "Estado", label: "Estado", width: 90 },
      { value: "typeOfManagementSingle", label: "Operación", width: 90 },
    ]
  );

  //#endregion

  //#region Funciones para aplicar flitros a la tabla
  //Filtros request tables
  const [filters, setFilters] = useState([]);


  //Capturar los valores de los inputs de los filtros
  const handleOnfilterDynamic = (constant, e) => {
    console.log(constant, e)
    setFilters(prevState => ({
      ...prevState,
      [constant]: e
    }))
  }

  //Aplicar los filtros a la data de la tabla
  const renderRowsByFilters = (rows) => {

    if (Object.keys(filters).length === 0) {
      return rows
    } else {
      const filterKeys = Object.keys(filters);

      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();

        rows = rows.filter(

          (item) => {
            let temp;

            try {
              temp = item[element]?.toString().toLowerCase().indexOf(valueFiltered);
            } catch (e) { temp = -1 }

            //Si se le da un espacio en el campo, imprima tdoos los vacios
            if (valueFiltered === " ") {
              return temp === undefined
            } else { //No es un espacio, traiga todos los de la palabra clave
              return temp != -1 && temp != undefined
            }

          }
        )
      }
      return rows
    }
  }

  //#endregion

  //#region Extraer las filas del formulario
  const [rowsRequests, setRowsRequests] = useState({
    rows: [],
    statsBaw: [],
    length: 0,
  })

  //Cuando reloadTableRequests es true, ejecuta este efecto 
  useEffect(() => {
    if (reloadTableRequests) {

      const getDataR = async () => {
        await getData();
      }
      getDataR();
    }
  }, [reloadTableRequests]);


  //Extrae las filas que se van a proyectar en las columnas
  const getData = async () => {

    dispatch(getDataApprovalsRows()).then((resp) => {

      const { payload } = resp;
      //console.log(payload)
      if (payload.status === 200) {
        setRowsRequests((e) => ({
          ...e,
          rows: payload.data.payload.rows,
          length: payload.data.payload.rows.length,
        }))





      } else {
        setRowsRequests({
          rows: [],
          length: 0,
        })

      }
      setReloadTableRequests(false);
      //setLoad(false);
    })
  }
  //#endregion

  //#region Realizar paginations en la tabla


  const [sizePerPage, setSizePerPage] = useState(10)
  const rows = renderRowsByFilters(rowsRequests.rows);

  //state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);


  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => handleOnSetPage(i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  //Establece el número de filas por pagina 
  const handleOnSetPage = (page) => {
    setPage(page);
  };

  const handleSetNumPag = (e) => {
    setPage(1);
    const value = e.target.value;
    setSizePerPage(value);
  }


  //#endregion

  //#region Funciones y efectos para abrir el modal y recarga las filas
  const handleOpenDetailModal = (row) => {
    setModalDetail({
      showDetailModal: true,
      view: "approvals",
      row: row
    })
  }


  //Efecto para cargar filas cuando arranque por primera vez.
  useEffect(() => {
    setReloadTableRequests(true);
  }, [])
  //#endregion

  return (
    <>


      <Card className=" ">



        <CardHeader className="bg-transparent border-0">
          <Row className="mb-4 mt--2">

            <Col xs="12" sm='6'>
              <h3 className="mb-0">Aprobaciones</h3>
              <p className="text-sm mb-0">A continuación se detallan las gestiones pendientes de aprobación.</p>

            </Col>

          </Row>

          <Row className=" ">
            <Col xs="7" sm="8" style={{ display: "flex", justifyContent: "flex-start" }}>
              <Col xs="auto" sm="auto" className="bg-transparent border-0">
                <h3 className=" mb-0">
                  Filtro </h3>
              </Col>


              <Col xs="auto" sm='auto '>
                <MultiSelect
                  size="sm"
                  id="multiselectFilter"
                  options={optionsRequestsSelect}
                  value={columnsSelectedTableRequests}
                  onChange={setColumnsSelectedTableRequests}
                  labelledBy="Select"
                />

              </Col>

            </Col>


            <Col xs="auto" sm="4" className=" " style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button id="refreshBtn" className="btn-info" size="sm" block type="button" style={{ width: "100px ", height: "30px" }}
                onClick={(e) => setReloadTableRequests(true)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-sync-alt" />
                </span>
                <span className="btn-inner--text">
                  {" "}Refrescar
                </span>
              </Button>
            </Col>
          </Row>

        </CardHeader>


        <Spin size="large" spinning={reloadTableRequests}>
          <Table
            className="align-items-center  table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">

              <tr>
                {
                  columnsSelectedTableRequests.map((item, key) => {
                    return (
                      item.value !== "seeDetail" ?

                        <th key={key} className="justify-content-md-center">

                          <div style={{ width: item.width }}>
                            <FormGroup key={key}>
                              <label
                                style={{ fontSize: "12px" }}
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                {`${item.label}:`}
                              </label>
                              <Input
                                id={item.id}
                                key={key}
                                className="form-control"
                                type="text"
                                placeholder={`Buscar ${item.label}...`}
                                onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                              />
                            </FormGroup>
                          </div>
                        </th>
                        :
                        <th key={key} className="justify-content-md-center">

                        </th>


                    )
                  })

                }
              </tr>
            </thead>
            <tbody className='list'>

              {rows.length > 0 ?

                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {
                  return (
                    <tr key={key}>
                      {
                        columnsSelectedTableRequests.map((col, keyCol) => {
                          return (
                            col.value === "Estado" ? (
                              <td key={key + keyCol} className="justify-content-md-center">
                                <div style={{ width: col.width }}>
                                  <Badge key={key + keyCol} color={row.statusType} pill>
                                    {row.Estado}
                                  </Badge>
                                </div>
                              </td>
                            ) :
                              col.value === "Respuesta" ? (
                                <td key={key + keyCol} className="justify-content-md-center">

                                  <div style={{ width: col.width }}>
                                    <Input
                                      key={key + keyCol}
                                      id={key + keyCol}
                                      className="form-control text-dark"
                                      type="textarea"
                                      background-color="white"
                                      readOnly={true}
                                      value={row.Respuesta}
                                    />
                                  </div>
                                </td>
                              )

                                : col.value === "seeDetail" ? ( //Este status es para el botón de detalle



                                  <td key={key + keyCol} className="justify-content-md-center">
                                    <div style={{ width: col.width }}>
                                      <Row key={key + keyCol + "row"}>
                                        {row.statusId !== "1" && //No lo muestre cuando apenas se está generando la opp


                                          <Col key={key + keyCol + "col"}>
                                            <Button id="refreshBtn" className="btn-default" size="sm" block type="button" style={{ width: "95px ", height: "30px" }}
                                              onClick={(e) => handleOpenDetailModal(row)}
                                            >
                                              <span className="btn-inner--icon mr-">
                                                <i className="fas fa-edit" />
                                              </span>
                                              <span className="btn-inner--text">
                                                {" "} Ir a detalle
                                              </span>
                                            </Button>
                                          </Col>
                                        }

                                      </Row>
                                    </div>
                                  </td>

                                ) : (

                                  <td key={key + keyCol} className="text-center text-break" style={{ whiteSpace: "normal", textAlign: "auto" }}>
                                    <div style={{ width: col.width }} >

                                      <span className="btn-inner--text">
                                        {row[col.value]}
                                      </span>

                                    </div>
                                  </td>

                                )
                          )
                        })
                      }

                    </tr>

                  )
                })
                :
                <>
                  <tr>
                    <td className="justify-content-md-center">
                      Sin resultados encontrados
                    </td>
                  </tr>
                </>
              }

            </tbody>




          </Table>
        </Spin>
        <CardFooter>

          <Row className="align-items-center ">
            <Col xs='12' md='6' className="p-1 ">
              <Row className="justify-content-start">
                <Col xs='12' md='6'>
                  <span className='pagination mb-0'>
                    Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                  </span>
                </Col>
                <Col xs='12' md='2'>
                  <Input type="select"
                    onChange={(e) => handleSetNumPag(e)}>
                    <option>5</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                  </Input>
                </Col>
              </Row>
            </Col>

            <Col xs='12' md='6' className="p-1 ">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={page === 1 ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {
                  renderPaginations()
                }
                <PaginationItem className={page === paginations ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>

        </CardFooter>

      </Card>

    </>
  )
}
