import React, { useEffect, useState } from 'react';
import Filters from 'components/BusinessSystem/Filters';
import Table from 'components/BusinessSystem/Table';
import Modals from "components/BusinessSystem/Modals";
import Flows from 'components/BusinessSystem/Flows';
import { useDispatch } from "react-redux";
import { getData, getOrdersAssign, getCountriesByHCM, getReportUS, getReportFlow, updateRequest, insertRequest, getPurchaseOrder, confirmPurchase, cancelPurchase, masterInsert, masterUpdate, getMyReserved, sendMailReserved, getAllReservedOrder, approveReserve, cancelReserve, getMyPurchase } from "actions/BusinessSystem"
import ReactBSAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getTeamsUserAccess, getUserProtected } from 'selectors/adminLayout.jsx';
import { desencrypt } from 'helpers/desencrypt.jsx';
import { TabPane, TabContent, Row } from "reactstrap";
import { Tabs } from "antd";
import { MaterialSymbolsPendingActions, CarbonPurchase, FluentMdl2ReservationOrders, EmojioneMonotoneAirplaneDeparture, TablerReservedLine, BxPurchaseTag } from "../Shared/Icons/iconiFy";
import Details from "./Details";
import * as jwt from 'jsonwebtoken';
import ApproveReserve from "./ApproveReserve";
import moment from "moment";
const MainPage = ({ setLoad }) => {

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const dispatch = useDispatch();

    //#region [USESTATES]
    const [tab, setTab] = useState("0");

    const [purchaseOrderSelected, setPurchaseOrderSelected] = useState({
        requestInformation: [],
        files: [],
        position: ""
    });

    const [reservedSelected, setReservedSelected] = useState({
        position: "",
        selected: false
    });

    const [alert, setAlert] = useState("");

    const [openModal, setModal] = useState({
        type: "",
        visible: false,
        reserved: false
    });
    const [showApproveOption, setShowApproveOption] = useState({
        approve: false,
        cancel: false
    });
    const [countries, setCountries] = useState([]);
    const [page, setPage] = useState(1);
    const [thisTable, setThisTable] = useState("reportUS");
    const [filtersByTable, setFiltersByTable] = useState({});
    const [infoTable, setInfoTable] = useState(
        {
            reportUs: [],
            orderAssing: [],
            purchaseOrder: [],
            myReserved: [],
            reservedOrder: [],
            itemSelected: "",
            flowItem: false,
            flowInfo: [],
            myPurchaseOrder: []
        }
    )
    const [valueSelect, setValueSelect] = useState(
        {
            countryOA: [],
            statusReports: [],
            Status:[],
            statusOrderAssign: [],
            roles: [],
            users: [],
            statusPurchaseRequest: [],
            countrySelect: []
        }
    )
    const [newInfo, setNewInfo] = useState({});
    const [newInfoPurchase, setNewInfoPurchase] = useState({
        comment: "",
        SOList: [],
        SO: ""
    });
    const [rol, setRol] = useState("");

    //#endregion

    //#region [CARGA INICIAL DE DATOS]
    //Carga la data inicial de las vistas
    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetInfoTables();
        };
        getInfo();
    }, []);
    //Valida cual rol tiene el usuario
    useEffect(() => {
        //trae informacion del token
        const teams = getTeamsUserAccess();
        if (teams.some((row) => row.indexOf("BS Pais") !== -1)) {
            setRol("BS Pais")
        }
        if (teams.some((row) => row.indexOf("BS Admin") !== -1)) {
            setRol("BS Admin")
        }
        if (teams.some((row) => row.indexOf("BS Ventas") !== -1)) {
            setRol("BS Ventas")
        }
    }, []);
    //Trae la informacion de los endpoints de las tablas y los selects
    const handleOnGetInfoTables = async () => {
        setLoad(true);
        let countries = [];
        const nombre = getUserProtected().NOMBRE;

        dispatch(getData()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let data = desencrypt(payload.data.payload.masterData);
                data = JSON.parse(data)
                setValueSelect((prevState) => ({
                    ...prevState,
                    countryOA: data.Countries,
                    statusReports: data.StatusReports,
                    Status: data.StatusReports,
                    StatusOrderAssign: data.StatusOrderAssign,
                    roles: data.roles,
                    users: data.users,
                    statusPurchaseRequest: data.statusPurchaseRequest,
                }));
            }
            setLoad(false);
        });
        dispatch(getCountriesByHCM({ name: nombre })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                const dataCountry = payload.data.payload.data;
                const updatedCountry = dataCountry.map(item => ({
                    label: item.country,
                    value: item.countryId
                }));
                setCountries(dataCountry)
                countries = dataCountry;
                setValueSelect((prevState) => ({
                    ...prevState,
                    countrySelect: updatedCountry
                }))
            }
        })
        dispatch(getOrdersAssign()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let orderAssing = payload.data.payload.data;
               
                orderAssing = orderAssing.filter((item) => {
                    return countries.some((x) => item.countryOA === x.countryId);
                });
                setInfoTable((prevState) => ({
                    ...prevState,
                    orderAssing: orderAssing,
                }));
            }
            setLoad(false);
        });
        dispatch(getReportUS()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let reportUS = payload.data.payload.data;
                let role = "";
                const teams = getTeamsUserAccess();
                if (teams.some((row) => row.indexOf("BS Admin") !== -1)) {
                    role = "BS Admin";
                } else {
                    role = "No Admin";
                };
                if (role !== "BS Admin") {
                    reportUS = reportUS.filter((x) => x.statusReports === 3 || x.statusReports === 4);
                    setInfoTable((prevState) => ({
                        ...prevState,
                        reportUs: reportUS,
                    }));
                } else {
                    setInfoTable((prevState) => ({
                        ...prevState,
                        reportUs: reportUS,
                    }));
                }
            }
            setLoad(false);
        });
        dispatch(getPurchaseOrder()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoTable((prevState) => ({
                    ...prevState,
                    purchaseOrder: payload.data.data,
                }));
            }
            setLoad(false);
        });
        dispatch(getMyReserved()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoTable((prevState) => ({
                    ...prevState,
                    myReserved: payload.data.payload.data,
                }));
            }
        })
        dispatch(getAllReservedOrder()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoTable((prevState) => ({
                    ...prevState,
                    reservedOrder: payload.data.data,
                }));
            }
            setLoad(false);
        });
        dispatch(getMyPurchase()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoTable((prevState) => ({
                    ...prevState,
                    myPurchaseOrder: payload.data.data,
                }));
            }
            setLoad(false);
        });
    };


    //#endregion

    //#region [FUNCIONES GENERALES DEL SISTEMA]

    //Funcion para cambiar de tabs en el menu superior de la vista
    const changeTab = async (infoView, tab) => {
        setThisTable(infoView[tab].thisTable);
        setInfoModal(infoView[tab].colums)
        setTab(tab);
        setFiltersByTable({});
        setPurchaseOrderSelected({
            requestInformation: [],
            files: []
        });
        setFiltersByTable([]);
        await handleOnGetInfoTables();
    };
    //Funcion para renderizar la tabla con los filtros aplicados
    const renderRowsByFilters = (rows) => {

        if (Object.keys(filtersByTable).length === 0) {
            return rows
        }
        else {
            const filterKeys = Object.keys(filtersByTable);
            for (const element of filterKeys) {
                const valueFiltered = filtersByTable[element].toLowerCase();
                if (valueFiltered !== "") {
                    rows = rows.filter((item) => item[element] !== null);
                    rows = rows.filter((item) => item[element].toString().toLowerCase().indexOf(valueFiltered) != -1
                    )
                }
            }
        }
        return rows
    }
    //Funcion para los filtros por columna
    const handleOnfilterDinamic = (constant, e) => {

        setFiltersByTable(prevState => ({
            ...prevState,
            [constant]: `${e}`
        }))

        setPage(1);
    }
    //Funcion para tomar la informacion de la fila a la hora de tocar el boton de editar
    const handleTakeInfoRow = (key, row) => {
        setModal((prevState) => ({
            ...prevState,
            visible: true,
            type: "edit"
        }))
        setInfoTable((prevState) => ({
            ...prevState,
            itemSelected: row,
        }));
    }

    //Funcion para almacenar campos modificados por nombre
    const handleOnChangeInfo = (constant, value) => {
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: value,
        }));
    };

    //Funcion para almacenar campos modificados por nombre
    const handleOnChangeInfoEdit = (constant, value) => {
        setInfoTable(prevState => ({
            ...prevState, // Mantenemos las demás propiedades de infoTable
            itemSelected: {
                ...prevState.itemSelected, // Mantenemos las propiedades actuales de itemSelected
                [constant]: value // Solo actualizamos el campo 'name'
            }
        }));
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: value,
        }));
    };
    //Funcion para almacenar los campos de un nuevo item
    const handleOnChangeNewInfo = (constant, value) => {
        if (constant === "reservedAmount") {
            if (Number(value) > newInfo["quantityAvailable"]) {
                return; // Evita que se siga escribiendo si es mayor a validacion
            } else {
                setNewInfo((prevState) => ({
                    ...prevState,
                    [constant]: value
                }));
            }
        } else {
            setNewInfo((prevState) => ({
                ...prevState,
                [constant]: value
            }));
        }
    }
    //Mostrar y ocultar modal
    const handleOnViewModal = (value) => {

        if (value === "close") {
            setInfoTable((prevState) => ({
                ...prevState,
                itemSelected: "",
            }));

            setModal((prevState) => ({
                ...prevState,
                type: ""
            }))
            setNewInfo({});
        }
        setModal((prevState) => ({
            ...prevState,
            visible: !openModal.visible
        }))
    }
    //#endregion

    //#region [JSONS DE FILTROS]
    const filtersUS = [
        {
            type: "input",
            label: "PO",
            md: "4",
            value: "POTrad"
        },
        {
            type: "input",
            label: "Número de producto",
            md: "4",
            value: "PN",
            isBS: false
        },
        {
            type: "select",
            label: "Status",
            md: "4",
            name: "Status",
            value: "statusReports",
            isBS: false
        }
    ]
    const filtersOr = [
        {
            type: "select",
            label: "País",
            md: "4",
            name: "countryOA",
            value: "countryOA"

        },
        {
            type: "input",
            label: "Número de producto",
            md: "4",
            value: "PN",
            isBS: false
        },
        {
            type: "select",
            label: "Status",
            md: "4",
            name: "StatusOrderAssign",
            value: "StatusOrderAssign",
            isBS: false
        }
    ]
    const filtersPurchase = [
        {
            type: "input",
            label: "Identificador",
            md: "4",
            value: "id",
            isBS: false
        },
        {
            type: "select",
            label: "Estado",
            md: "4",
            name: "statusPurchaseRequest",
            value: "statusId",
            isBS: false
        },

    ]
    const filtersMyReserved = [
        {
            type: "input",
            label: "Identificador",
            md: "4",
            value: "id",
            isBS: false
        },
        {
            type: "select",
            label: "Estado",
            md: "4",
            name: "statusPurchaseRequest",
            value: "status",
            isBS: false
        },

    ]
    //#endregion

    //#region [JSONS DE TABLAS]

    const filtersReserve = [
        {
            type: "input",
            label: "Identificador",
            md: "4",
            value: "Id",
            isBS: false
        },
        {
            type: "input",
            label: "Producto",
            md: "4",
            name: "PN",
            value: "PN"

        },
        {
            type: "input",
            label: "SO",
            md: "4",
            name: "SO",
            value: "SO"

        },
    ]
    const tableReportUs = [
        { label: "Fecha de colocación", value: "ordenEntryDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isTable: true },
        { label: "PO", value: "POTrad", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isTable: true },
        { label: "SO", value: "SO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isTable: true, isBS: false },
        { label: "Número de producto", value: "PN", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad del pedido", value: "ordenQuantity", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad disponible", value: "quantityAvailables", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad Reservada", value: "quantityReserved", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: true, isTable: true },
        { label: "Cantidad Bloqueada", value: "quantityBlock", colWidth: "4", required: true, filter: false, disabled: true, isTable: true },
        { label: "Fecha estimada de salida a planta", value: "actualShipDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha estimada de llegada a Miami", value: "deliveryDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Monto colocado", value: "colocationAmount", colWidth: "6", maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Estado", value: "Status", valueSelect: "statusReports", type: "select", colWidth: "6", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha Orden en transito", value: "updateAt", type: "date", colWidth: "6", required: false, filter: true, disabled: false, isBS: false, isTable: false },
        { label: "Item", value: "item", colWidth: "6", maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Comentario", value: "comment", colWidth: "12", maxlength: "500", filter: true, disabled: false, isTable: true }
    ]
    const modalReportUs = [
        { label: "Fecha de colocación", value: "ordenEntryDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isTable: true },
        { label: "PO", value: "POTrad", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isTable: true },
        { label: "SO", value: "SO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isTable: true, isBS: false },
        { label: "Número de producto", value: "PN", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad del pedido", value: "ordenQuantity", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad disponible", value: "quantityAvailables", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad Reservada", value: "quantityReserved", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: true, isTable: true },
        { label: "Cantidad Bloqueada", value: "quantityBlock", colWidth: "4", required: true, filter: false, disabled: true, isTable: true },
        { label: "Fecha estimada de salida a planta", value: "actualShipDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha estimada de llegada a Miami", value: "deliveryDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Monto colocado", value: "colocationAmount", colWidth: "6", maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Estado", value: "Status", valueSelect: "statusReports", type: "select", colWidth: "6", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha Orden en transito", value: "updateAt", type: "date", colWidth: "6", required: false, filter: true, disabled: false, isBS: false, isTable: false },
        { label: "Item", value: "item", colWidth: "6", maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Comentario", value: "comment", colWidth: "12", maxlength: "500", filter: true, disabled: false, isTable: true }
    ]
    const tableOrders = [
        { label: "País", value: "country", colWidth: "4", type: "select", valueSelect: "countryOA", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Cliente", value: "customer", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha de recibo de la orden", type: "input", value: "dateToRevibeOrder", colWidth: "4", required: true, filter: false, disabled: false, isBS: false, isTable: true },
        { label: "SO", value: "SO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "PO País", value: "countryPO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Número de producto", value: "PN", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Cantidad Reservada", value: "reservedAmount", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: false, isBS: false, isTable: true },
        // { label: "Costo total SO", value: "totalCostSO", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: false, isBS: false },
        { label: "Estado", value: "Status", colWidth: "4", type: "select", valueSelect: "StatusOrderAssign", required: true, filter: false, disabled: false, isBS: false, isTable: true },
        { label: "Post", value: "post", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Usuario", value: "user", colWidth: "4", required: true, maxlength: "50", filter: false, disabled: false, isTable: true },
        { label: "Comentario", value: "comment", colWidth: "12", required: true, maxlength: "500", filter: false, disabled: false, isTable: true },
    ]
    const modalOrders = [
        { label: "País", value: "country", colWidth: "4", type: "select", valueSelect: "countryOA", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Cliente", value: "customer", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha de recibo de la orden", type: "input", value: "dateToRevibeOrder", colWidth: "4", required: true, filter: false, disabled: false, isBS: false, isTable: true },
        { label: "SO", value: "SO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "PO País", value: "countryPO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Número de producto", value: "PN", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Cantidad Reservada", value: "reservedAmount", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: false, isBS: false, isTable: true },
        // { label: "Costo total SO", value: "totalCostSO", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: false, isBS: false },
        { label: "Estado", value: "Status", colWidth: "4", type: "select", valueSelect: "StatusOrderAssign", required: true, filter: false, disabled: false, isBS: false, isTable: true },
        { label: "Post", value: "post", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Usuario", value: "user", colWidth: "4", required: true, maxlength: "50", filter: false, disabled: false, isTable: true },
        { label: "Comentario", value: "comment", colWidth: "12", required: true, maxlength: "500", filter: false, disabled: false, isTable: true },
    ]
    const tablePurchase = [
        { label: "Identificador", value: "id", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Estatus", value: "statusName", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Creador", value: "createBy", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "SO", value: "so", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Referencia solicitud", value: "fkFinancial", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Nombre documento", value: "documentName", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Categoria", value: "documentCategoryName", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha de ingreso", value: "createdAt	", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
    ]
    const tableMyPurchase = [
        { label: "Identificador", value: "id", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Estatus", value: "statusName", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        //{ label: "Creador", value: "createBy", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "SO", value: "so", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Referencia solicitud", value: "fkFinancial", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Nombre documento", value: "documentName", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Categoria", value: "documentCategoryName", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha de ingreso", value: "createdAt	", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
    ]
    const tableReserved = [
        { label: "id", value: "id", colWidth: "4", type: "input", required: true, filter: true, disabled: false, isTable: true, isBS: false },
        { label: "País", value: "country", colWidth: "4", type: "select", valueSelect: "countrySelect", required: true, filter: true, disabled: false, isTable: true, isBS: false },
        { label: "Cliente", value: "customer", colWidth: "4", required: true, filter: true, disabled: false, isTable: true, isBS: false },
        { label: "Sales Order", value: "SO", colWidth: "4", required: true, filter: true, disabled: false, isTable: true, isBS: false },
        { label: "Purchase Order País", value: "countryPO", colWidth: "4", required: true, filter: true, disabled: false, isTable: true, isBS: false },
        { label: "Cantidad solicitada", type: "number", value: "reservedAmount", colWidth: "4", required: true, filter: true, disabled: false, max: "quantityAvailable", isTable: true, isBS: false },
        { label: "Número de producto ", value: "PN", colWidth: "4", required: true, filter: true, disabled: true, isTable: true, isBS: false },
        { label: "Estado", value: "statusName", colWidth: "4", required: true, filter: true, disabled: true, isTable: true, isBS: false },
        { label: "Creado por", value: "user", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha de creación", value: "createAt", type: "date", colWidth: "4", required: true, filter: true, disabled: true, isTable: true, isBS: false },
    ]
    const tableOrderApprove = [
        { label: "Identificador", value: "Id", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Pais", value: "countryName", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Cliente", value: "customer", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "SO", value: "SO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "countryPO", value: "countryPO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "PN", value: "PN", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Cantidad reservada", value: "reservedAmount", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Creado por", value: "user", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha de ingreso", type: "date", value: "createdAt", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
    ];

    const structureApprove = [
        { label: "Pais", value: "countryName", type: "input", disabled: true, options: countries.map(item => ({ label: item.country, value: item.countryId })) },
        { label: "Cliente", value: "customer", type: "input", disabled: true },
        { label: "SO", value: "SO", type: "input", disabled: true },
        { label: "countryPO", value: "countryPO", type: "input", disabled: true },
        { label: "PN", value: "PN", type: "input", disabled: true },
        { label: "Cantidad reservada", value: "reservedAmount", type: "input", disabled: true },
        { label: "Creado por", value: "user", type: "input", disabled: true },
        { label: "Fecha de ingreso", type: "date", value: "createAt", disabled: true },
    ];
    //#endregion

    //#region [JSONS DE MODALES]
    const [infoModal, setInfoModal] = useState(thisTable === "reportUS" ? modalReportUs : modalOrders);
    const reserved = [
        { label: "País", value: "countryOA", colWidth: "4", type: "select", valueSelect: "countryOA", required: true, filter: true, disabled: false, isTable: true },
        { label: "Cliente", value: "customer", colWidth: "4", required: true, filter: true, disabled: false, isTable: true },
        { label: "Sales Order", value: "SO", colWidth: "4", required: true, filter: true, disabled: false, isTable: true },
        { label: "Purchase Order País", value: "countryPO", colWidth: "4", required: true, filter: true, disabled: false, isTable: true },
        { label: "Cantidad Disponible", type: "number", value: "quantityAvailable", colWidth: "4", required: true, disabled: true, isTable: true },
        { label: "Cantidad por reservar", type: "number", value: "reservedAmount", colWidth: "4", required: true, filter: true, disabled: false, max: "quantityAvailable", isTable: true },
        { label: "Número de producto ", value: "PN", colWidth: "4", required: true, filter: true, disabled: true, isTable: true },
        { label: "Fecha", value: "createAt", type: "date", colWidth: "4", required: true, filter: true, disabled: true, isTable: true },
    ]
    //#endregion

    //#region [PRODUCTOS EN TRANSITO TABAL#1]
    //Funcion para abrir el flujo del item seleccionado al tocar el boton de flujo
    const handleOnFlowItem = async (key, row) => {
        setLoad(true);
        setInfoTable((prevState) => ({
            ...prevState,
            flowItem: true,
            itemSelected: row,
        }));
        dispatch(getReportFlow(row.Id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoTable((prevState) => ({
                    ...prevState,
                    flowInfo: payload.data.payload.data
                }));
            }
            setLoad(false);
        })
    }
    //Funcion para modificar el registro seleccionado
    const handleOnUpdateRequest = async () => {
        setLoad(true);
        dispatch(updateRequest(infoTable.itemSelected["Id"], { data: newInfo, table: thisTable, idStatus: infoTable.itemSelected["idStatus"] })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logro establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                handleOnGetInfoTables();
                setModal((prevState) => ({
                    ...prevState,
                    visible: false,
                    type: ""
                }))
                Toast.fire({
                    title: "Actualizado",
                    html: "La información se actualizo correctamente",
                    type: "success",
                });
            }
        });
        setAlert("");
        setLoad(false);
    };
    //Funcion para insertar registro nuevo
    const handleOnInsertRequest = async () => {
        setLoad(true);
        dispatch(insertRequest({ data: newInfo, table: thisTable })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logro establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                handleOnGetInfoTables();
                setModal((prevState) => ({
                    ...prevState,
                    visible: false,
                }))
                Toast.fire({
                    title: "Insertado",
                    html: "Se inserto correctamente",
                    type: "success",
                });
            }
        });
        setAlert("");
        setLoad(false);
    };
    //Volver a la tabla principal desde el flujo
    const handleOnGoBack = () => {
        setInfoTable((prevState) => ({
            ...prevState,
            flowItem: false
        }));
        setInfoTable((prevState) => ({
            ...prevState,
            itemSelected: ""
        }));
        setFiltersByTable([]);
    }
    //ABRE EL MODAL PARA RESERVAR UN PRODUCTO
    const handleOnOpenReservedModal = (key, row) => {
        const token = localStorage.getItem("token");
        const decoded = jwt.decode(token);
        if (openModal.reserved === false) {
            const info = {
                user: decoded.user, fk_reportUs: row["Id"], PN: row["PN"], SO: "Pendiente",
                countryPO: "Pendiente", createAt: moment().utc().utcOffset(moment().utcOffset()).format("YYYY-MM-DD"),
                quantityAvailable: parseInt(row["quantityAvailable"]) - (row["quantityBlock"] === null ? 0 : parseInt(row["quantityBlock"]))
            }
            setInfoTable((prevState) => ({
                ...prevState,
                itemSelected: row,
            }));
            setNewInfo(info)
            setModal((prevState) => ({
                ...prevState,
                reserved: !openModal.reserved
            }))
        } else {
            setModal((prevState) => ({
                ...prevState,
                reserved: !openModal.reserved
            }))
        }
    }
    //Funcion para generar una nueva reserva
    const handleOnNewReserved = async () => {

        const update = { quantityBlock: parseInt(newInfo.reservedAmount) + parseInt(infoTable.itemSelected.quantityBlock) }
        const id = newInfo.fk_reportUs;
        delete newInfo["quantityAvailable"];
        delete newInfo["createAt"];

        dispatch(masterInsert({ info: newInfo, table: "ordesAssign" })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logro establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                setLoad(true);
                setModal((prevState) => ({
                    ...prevState,
                    reserved: false,
                }))
                dispatch(masterUpdate({ info: update, table: "reportUS", id: id })).then((res) => {
                    if (res.payload.isAxiosError) {
                        if (res.payload.response) {
                            const { data: { payload } } = res.payload.response;
                            Toast.fire({
                                title: "Atención",
                                html: payload.message,
                                type: "danger",
                            });
                        } else {
                            Toast.fire({
                                title: "Fallo",
                                html: "No se logro establecer conexion con el servidor.",
                                type: "danger",
                            });
                        }
                    } else {
                        dispatch(sendMailReserved({newInfo})).then((res) => {

                            if (res.payload.isAxiosError) {
                                if (res.payload.response) {
                                    const { data: { payload } } = res.payload.response;
                                    Toast.fire({
                                        title: "Atención",
                                        html: payload.message,
                                        type: "danger",
                                    });
                                } else {
                                    Toast.fire({
                                        title: "Fallo",
                                        html: "No se logro establecer conexion con el servidor.",
                                        type: "danger",
                                    });
                                }
                            } else {
                                Toast.fire({
                                    title: "Generado",
                                    html: "Se genero la reserva correctamente",
                                    type: "success",
                                });
                                handleOnGetInfoTables();
                            }
                        })
                    }
                });
            }
        });
        setAlert("");
        setLoad(false);
    };
    //Funcion para preguntar si esta seguro de generar la nueva reserva
    const questionNewReserved = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Esta segur@ que desea generar la reserva?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={handleOnNewReserved}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, reservar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }
    //Guardar EL nuevo registro o actualizar nuevo registro
    const handleOnSave = () => {
        let cont = 0;
        const forms = document.querySelectorAll(".isValid input, select");
        Array.from(forms).forEach((input) => {
            if (input.required === true && input.value === "") {
                document.getElementsByClassName("css-yk16xz-control")[0].setAttribute("style", "border-color:#fb6340;");
                input.classList.add("is-invalid");
                cont++;
            } else {
                document.getElementsByClassName("css-yk16xz-control")[0].removeAttribute("style");
                input.classList.remove("is-invalid");
            }
            if (input.required === true && (input.value === "" || input.value === "---")) {
                input.classList.add("is-invalid");
                cont++;
            } else {
                input.classList.remove("is-invalid");
            }
        }
        );
        if (cont === 0) {
            setAlert(
                <ReactBSAlert
                    custom
                    showCancel
                    style={{ display: "block", marginTop: "-100px" }}
                    title={"¿Esta segur@ que desea guardar?"}
                    customIcon={
                        <div
                            className="swal2-icon swal2-question swal2-animate-question-icon"
                            style={{ display: "flex" }}
                        >
                            <span className="swal2-icon-text">?</span>
                        </div>
                    }
                    onConfirm={openModal.type === "edit" ? () => handleOnUpdateRequest() : () => handleOnInsertRequest()}
                    onCancel={() => setAlert("")}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Sí, guardar"
                    cancelBtnText="No, cancelar"
                    btnSize="md"
                />
            );
        }
    };
    //#endregion

    //#region [SOLICITUDES DE COMPRA TABLA#3]

    //Funcion para mostrar comfirmacion de la solicitud
    const handleShowApproveOption = () => {
        setShowApproveOption(prevState => ({
            ...prevState,
            approve: !showApproveOption.approve
        }));
        if (showApproveOption.approve) {
            setNewInfoPurchase({
                comment: "",
                SOList: [],
                SO: ""
            })
        }
    }
    //Funcion para mostrar rechazo de la solicitud
    const handleShowCancelOption = () => {
        setShowApproveOption(prevState => ({
            ...prevState,
            cancel: !showApproveOption.cancel
        }));
        if (showApproveOption.cancel) {
            setNewInfoPurchase({
                comment: "",
                SOList: [],
                SO: ""
            })
        }
    }
    //funcion que selecciona una solicitud de compra y la posicion de esta en el array
    function handlePurchaseDetails(key, item) {
        setPurchaseOrderSelected({
            ...item,
            position: key
        });
        setNewInfoPurchase({
            comment: "",
            SOList: [],
            SO: ""
        })
    }
    //funcion que setea los valores al punto inicial de la seleccion de una solicitud de compra 
    function handleReturn() {
        setPurchaseOrderSelected({
            requestInformation: [],
            files: [],
            position: ""
        });
        // si esta activo las opciones de aprobación, las desactiva
        setShowApproveOption(prevState => ({
            ...prevState,
            approve: false,
            cancel: false
        }));
        setNewInfoPurchase({
            comment: "",
            SOList: [],
            SO: ""
        })
    }
    //funcion que retorna un boolean si la solicitud de compra esta completa, su si estatus no es pendiente
    const isComplete = () => {
        return (purchaseOrderSelected && purchaseOrderSelected.statusId !== 1)
    }
    //funcion valida si se ingreso el SO para la aprobación de una solicitud
    const validateConfirm = () => {
        return (newInfoPurchase.SOList.length === 0)
    }
    //funcion que valida si el comentario del aprobador fue ingresado
    const validateCancel = () => {
        return (newInfoPurchase.comment === "")
    }
    //funcion que muestra el mensaje de confirmacion sobre la aprobacion de una solicitud
    const messageConfirm = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Esta segur@ que desea aprobar la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={handleConfirmPurchase}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, aprobar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }
    //funcion que envia la peticion de aprobacion al API
    const handleConfirmPurchase = async () => {
        setLoad(true);
        setAlert("");
        await dispatch(confirmPurchase(purchaseOrderSelected.id, { serviceOrder: newInfoPurchase.SOList, comment: newInfoPurchase.comment, email: purchaseOrderSelected.requesterEmail, Fk_FinancialFlows: purchaseOrderSelected.fkFinancial })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logro establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                infoTable.purchaseOrder[purchaseOrderSelected.position] = res.payload.data.data[0];
                Toast.fire({
                    title: "Insertado",
                    html: "Se aprobó correctamente",
                    type: "success",
                });
                setPurchaseOrderSelected({
                    requestInformation: [],
                    files: [],
                    position: ""
                })
                setShowApproveOption(prevState => ({
                    ...prevState,
                    approve: false,
                    cancel: false
                }));
                setNewInfoPurchase({
                    comment: "",
                    SOList: [],
                    SO: ""
                });
            }
        });

        setLoad(false);
    }
    //funcion que muestra un mensaje de confirmacion para rechaza una solicitud
    const messageCancel = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Esta segur@ que desea rechazar la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={handleCancelPurchase}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, rechazar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }
    //funcion que envia la peticion de rechazo aal API
    const handleCancelPurchase = async () => {
        setLoad(true);
        setAlert("");
        await dispatch(cancelPurchase(purchaseOrderSelected.id, { comment: newInfoPurchase.comment, email: purchaseOrderSelected.requesterEmail, Fk_FinancialFlows: purchaseOrderSelected.fkFinancial })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logro establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                infoTable.purchaseOrder[purchaseOrderSelected.position] = res.payload.data.data[0];
                Toast.fire({
                    title: "Insertado",
                    html: "Se rechazó correctamente",
                    type: "success",
                });
                setPurchaseOrderSelected({
                    requestInformation: [],
                    files: [],
                    position: ""
                })
                setShowApproveOption(prevState => ({
                    ...prevState,
                    approve: false,
                    cancel: false
                }));
                setNewInfoPurchase({
                    comment: "",
                    SOList: [],
                    SO: ""
                });
            }
        });
        setLoad(false);
    }

    //funcion que agrega so`s a la lista
    const addSo = (value) => {
        setNewInfoPurchase(prevState => ({
            ...prevState,
            SOList: [...prevState.SOList, value],
            SO: ""
        }))
    }

    //funcion que elimina so`s a la lista
    const deleteSo = (index) => {
        const newItems = [...newInfoPurchase.SOList];

        // Elimina el elemento en la posición especificada
        newItems.splice(index, 1);
        setNewInfoPurchase(prevState => ({
            ...prevState,
            SOList: newItems,
        }))
    }
    //#endregion

    //#region [ORDENES DE RESERVA TABLA#5]

    //funcion que selecciona una solicitud de reserva
    function handleReservedDetails(key, item) {
        setReservedSelected({
            ...item,
            position: key,
            selected: true
        });
    }
    //funcion que setea los valores iniciales la seleccion de una solicitud de reserva
    function handleReturnReserved() {
        setReservedSelected({
            position: "",
            selected: false
        });
        setShowApproveOption(prevState => ({
            ...prevState,
            approve: false,
            cancel: false
        }));
        setNewInfoPurchase({
            comment: "",
            SOList: [],
            SO: ""
        })
    }
    //mensaje de confirmación de la aprobacion de una reserva
    const messageConfirmApproveReserve = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Esta segur@ que desea aprobar la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={handleConfirmApproveReserve}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, aprobar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }
    //funcion que envia la peticion de aprobacion de una reserva
    const handleConfirmApproveReserve = async () => {
        setLoad(true);
        setAlert("");
        await dispatch(approveReserve(reservedSelected.Id, { email: reservedSelected.email, fk_reportUs: reservedSelected.fk_reportUs, amount: reservedSelected.reservedAmount, PN: reservedSelected.PN })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logró establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                infoTable.purchaseOrder[purchaseOrderSelected.position] = res.payload.data.data[0];
                Toast.fire({
                    title: "Aprobado",
                    html: "Se aprobó correctamente",
                    type: "success",
                });
                setReservedSelected({
                    position: "",
                    selected: false
                })
            }
        });
        await handleOnGetInfoTables();
        setLoad(false);
    }
    //funcion que muestra un mensaje de confirmación del rechazo de una reserva
    const messageConfirmCancel = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Esta segur@ que desea rechazar la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={handleCancelReserve}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, rechazar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }
    //funcion que envia la peticion de rechazo al API
    const handleCancelReserve = async () => {
        setLoad(true);
        setAlert("");
        await dispatch(cancelReserve(reservedSelected.Id, { email: reservedSelected.email, fk_reportUs: reservedSelected.fk_reportUs, amount: reservedSelected.reservedAmount, PN: reservedSelected.PN })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logró establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                infoTable.purchaseOrder[purchaseOrderSelected.position] = res.payload.data.data[0];
                Toast.fire({
                    title: "Insertado",
                    html: "Se rechazó correctamente",
                    type: "success",
                });
                setReservedSelected({
                    position: "",
                    selected: false
                })
            }
        });
        await handleOnGetInfoTables();
        setLoad(false);
    }
    //#endregion

    //Informacion de las Vistas de los tabs
    const infoView = [
        {
            title: "Productos en Transito",
            icon: <EmojioneMonotoneAirplaneDeparture />,
            tabIndex: "0",
            text: "Detalles de productos en transito",
            handleTakeInfoRow: (key, e) => handleTakeInfoRow(key, e),
            colums: tableReportUs,
            rowsTable: infoTable.reportUs,
            pageTable: page,
            setPage: (e) => setPage(e),
            thisTable: "reportUS",
            filters: (constant, e) => handleOnfilterDinamic(constant, e),
            handleOnFlowItemFunction: (e) => handleOnFlowItem(e),
            handleOnViewModal: (e) => handleOnViewModal(e),
            rolSet: rol,
            type: 1,
            contentFilter: filtersUS,
            showTab: rol === "BS Admin" || rol === "BS Pais" || rol === "BS Ventas" ? true : false,
            aditionalButtons: [
                { color: "secondary", icon: "far fa-edit", id: "botonTool", tooltip: "Editar", onclick: (key, e) => handleTakeInfoRow(key, e), showButton: rol === "BS Admin" },
                { color: "info", icon: "fas fa-truck-moving", id: "botonTool2", tooltip: "Ver Flujo", onclick: (key, e) => handleOnFlowItem(key, e), showButton: true },
                { color: "dark", icon: "fas fa-warehouse", id: "botonTool3", tooltip: "Reservar", onclick: (key, e) => handleOnOpenReservedModal(key, e), showButton: true },
            ]
        },
        {
            title: "Ordenes pendientes de asignación",
            icon: <MaterialSymbolsPendingActions widthIcon="1px" heightIcon="1px" />,
            tabIndex: "1",
            text: "Ordenes pendientes de asignación",
            handleTakeInfoRow: (key, e) => handleTakeInfoRow(key, e),
            colums: tableOrders,
            rowsTable: infoTable.orderAssing,
            pageTable: page,
            setPage: (e) => setPage(e),
            thisTable: "ordesAssign",
            filters: (constant, e) => handleOnfilterDinamic(constant, e),
            handleOnFlowItem: (e) => handleOnFlowItem(e),
            handleOnViewModal: (e) => handleOnViewModal(e),
            rolSet: rol,
            type: 2,
            contentFilter: filtersOr,
            showTab: rol === "BS Admin" || rol === "BS Pais" ? true : false,
            aditionalButtons: [
                { color: "secondary", icon: "far fa-edit", id: "botonTool", tooltip: "Editar", onclick: (key, e) => handleTakeInfoRow(key, e), showButton: rol === "BS Admin" },
            ]
        },
        {
            title: "Solicitudes de Compras",
            icon: <CarbonPurchase widthIcon="1px" heightIcon="1px" />,
            tabIndex: "2",
            text: "Solicitudes de Compras",
            handleTakeInfoRow: (key, e) => handleTakeInfoRow(key, e),
            colums: tablePurchase,
            rowsTable: infoTable.purchaseOrder,
            pageTable: page,
            setPage: (e) => setPage(e),
            thisTable: "pendingApprove",
            filters: (constant, e) => handleOnfilterDinamic(constant, e),
            handleOnFlowItem: (e) => handleOnFlowItem(e),
            handleOnViewModal: (e) => handleOnViewModal(e),
            rolSet: rol,
            type: 2,
            contentFilter: filtersPurchase,
            showTab: rol === "BS Admin" ? true : false,
            aditionalButtons: [
                { color: "secondary", icon: "far fa-edit", id: "buttonDetails", tooltip: "Detalles", onclick: (key, e) => handlePurchaseDetails(key, e), showButton: rol === "BS Admin" },
            ]
        },
        {
            title: "Mis Reservas",
            icon: <TablerReservedLine widthIcon="1px" heightIcon="1px" />,
            tabIndex: "3",
            text: "Reservas realizadas",
            handleTakeInfoRow: (key, e) => handleTakeInfoRow(key, e),
            colums: tableReserved,
            rowsTable: infoTable.myReserved,
            pageTable: page,
            setPage: (e) => setPage(e),
            thisTable: "myReserved",
            filters: (constant, e) => handleOnfilterDinamic(constant, e),
            rolSet: rol,
            type: 2,
            contentFilter: filtersMyReserved,
            showTab: rol === "BS Admin" || rol === "BS Ventas" || rol === "BS Pais" ? true : false,

        },
        {
            title: "Mis Solicitudes de Compras",
            icon: <BxPurchaseTag widthIcon="1px" heightIcon="1px" />,
            tabIndex: "4",
            text: "Mis Solicitudes de Compras",
            handleTakeInfoRow: (key, e) => handleTakeInfoRow(key, e),
            colums: tableMyPurchase,
            rowsTable: infoTable.myPurchaseOrder,
            pageTable: page,
            setPage: (e) => setPage(e),
            thisTable: "myPurchaseOrder",
            filters: (constant, e) => handleOnfilterDinamic(constant, e),
            handleOnFlowItem: (e) => handleOnFlowItem(e),
            handleOnViewModal: (e) => handleOnViewModal(e),
            rolSet: rol,
            type: 2,
            contentFilter: filtersPurchase,
            showTab: rol === "BS Admin" || rol === "BS Ventas" || rol === "BS Pais" ? true : false,
            aditionalButtons: [
                { color: "secondary", icon: "far fa-edit", id: "buttonDetails", tooltip: "Detalles", onclick: (key, e) => handlePurchaseDetails(key, e), showButton: true },
            ]
        },
        {
            title: "Reservas Pendiente de Aprobación",
            icon: <FluentMdl2ReservationOrders />,
            tabIndex: "5",
            text: "Solicitudes de Compras",
            handleTakeInfoRow: (key, e) => handleTakeInfoRow(key, e),
            colums: tableOrderApprove,
            rowsTable: infoTable.reservedOrder,
            pageTable: page,
            setPage: (e) => setPage(e),
            thisTable: "reservedOrder",
            filters: (constant, e) => handleOnfilterDinamic(constant, e),
            handleOnFlowItem: (e) => handleOnFlowItem(e),
            handleOnViewModal: (e) => handleOnViewModal(e),
            rolSet: rol,
            type: 2,
            contentFilter: filtersReserve,
            showTab: rol === "BS Admin" ? true : false,
            aditionalButtons: [
                { color: "secondary", icon: "far fa-edit", id: "buttonApprove", tooltip: "Aprobar", onclick: (key, e) => handleReservedDetails(key, e), showButton: rol === "BS Admin" },
            ]
        }
    ]

    //#region exportacion de la informacion

    //#endregion
    return (
        <div>
            {alert}
            {infoTable.flowItem === true ? (
                <Flows
                    flow={infoTable.flowInfo}
                    goBack={handleOnGoBack}
                    flowInfo={infoTable.itemSelected}
                />
            ) : (
                thisTable !== "Admin" && (
                    <>
                        {purchaseOrderSelected &&
                            purchaseOrderSelected.requestInformation.length === 0 &&
                            !reservedSelected.selected && (
                                <>
                                    <Row className="d-flex justify-content-center">
                                        <Tabs
                                            defaultActiveKey={tab}
                                            onChange={(key) => changeTab(infoView, key)}
                                        >
                                            {infoView.map(
                                                (item, key) =>
                                                    item.showTab && (
                                                        <TabPane
                                                            key={key}
                                                            tab={
                                                                <span key={key}>
                                                                    <span className="btn-inner--icon mb-2 mr-2">
                                                                        {item.icon}
                                                                    </span>
                                                                    {item.title}
                                                                </span>
                                                            }
                                                        />
                                                    )
                                            )}
                                        </Tabs>
                                    </Row>
                                    <TabContent activeTab={tab}>
                                        {infoView.map(
                                            (item, key) =>
                                                item.showTab && (
                                                    <TabPane tabId={item.tabIndex} key={`tp${key}`}>
                                                        {tab == item.tabIndex && (
                                                            <>
                                                                <Filters //item.thisTable === "reportUS" ? rol !== "BS Admin" ? filtersUS.filter(item => item.isBS === false) : filtersUS : rol !== "BS Admin" ? filtersOr.filter(item => item.isBS === false) : filtersOr
                                                                    jsonFilters={
                                                                        rol !== "BS Admin"
                                                                            ? item.contentFilter.filter(
                                                                                (item) => item.isBS === false
                                                                            )
                                                                            : item.contentFilter
                                                                    }
                                                                    optionsFilters={valueSelect}
                                                                    thisTable={item.thisTable}
                                                                    filters={handleOnfilterDinamic}
                                                                    rol={rol}
                                                                />
                                                                <Table
                                                                    text={item.text}
                                                                    handleTakeInfoRow={item.handleTakeInfoRow}
                                                                    colums={item.colums}
                                                                    rows={renderRowsByFilters(item.rowsTable)}
                                                                    page={item.pageTable}
                                                                    setPage={item.setPage}
                                                                    thisTable={item.thisTable}
                                                                    filters={item.filters}
                                                                    handleOnFlowItem={
                                                                        item.handleOnFlowItemFunction
                                                                    }
                                                                    handleOnViewModal={item.handleOnViewModal}
                                                                    handlePurchaseDetails={
                                                                        handlePurchaseDetails
                                                                    }
                                                                    rol={rol}
                                                                    type={item.type}
                                                                    aditionalButtons={item.aditionalButtons}
                                                                    isExport={true}
                                                                />
                                                            </>
                                                        )}
                                                    </TabPane>
                                                )
                                        )}
                                    </TabContent>
                                </>
                            )}
                        {purchaseOrderSelected &&
                            purchaseOrderSelected.requestInformation.length > 0 && (
                                <>
                                    <Details
                                        detailsData={purchaseOrderSelected.requestInformation}
                                        disabled={true}
                                        handleReturn={handleReturn}
                                        title={"Detalles de la orden"}
                                        subtitle={"todos los detalles"}
                                        files={purchaseOrderSelected.files}
                                        newInfo={newInfoPurchase}
                                        handleOnChangeInfo={handleOnChangeInfo}
                                        handleShowApproveOption={handleShowApproveOption}
                                        handleShowCancelOption={handleShowCancelOption}
                                        showSaveOptions={showApproveOption.approve}
                                        showCancelOptions={showApproveOption.cancel}
                                        isComplete={isComplete()}
                                        savePurchaseOrder={messageConfirm}
                                        cancelPurchaseOrder={messageCancel}
                                        isButtonConfirm={validateConfirm()}
                                        isButtonCancel={validateCancel()}
                                        addSo={addSo}
                                        deleteSo={deleteSo}
                                        role={rol}
                                    />
                                </>
                            )}
                        {reservedSelected.selected &&
                            <>
                                <ApproveReserve
                                    title="Reservas por aprobar"
                                    subtitle="Apobaciones"
                                    structureApprove={structureApprove}
                                    info={reservedSelected}
                                    handleReturn={handleReturnReserved}
                                    showConfirmApprove={messageConfirmApproveReserve}
                                    showCancelApprove={messageConfirmCancel}
                                />
                            </>
                        }
                        <Modals
                            openModal={openModal.visible}
                            handleOnViewModal={handleOnViewModal}
                            text={
                                openModal.type === "edit" ? "Editar solicitud" : "Nueva linea"
                            }
                            filters={handleOnfilterDinamic}
                            itemModal={infoModal}
                            itemInfo={infoTable.itemSelected}
                            valueSelect={valueSelect}
                            handleOnChangeInfo={handleOnChangeInfoEdit}
                            saveButton={handleOnSave}
                        />
                        <Modals
                            openModal={openModal.reserved}
                            handleOnViewModal={handleOnOpenReservedModal}
                            text={"Reservar "}
                            itemModal={reserved}
                            itemInfo={newInfo}
                            valueSelect={valueSelect}
                            handleOnChangeInfo={handleOnChangeNewInfo}
                            saveButton={questionNewReserved}
                        />
                    </>
                )
            )}
        </div>
    );
};

export default MainPage;