import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const masterData = handleActions({
  [constants.GET_ALL_VARIABLES_MASTER_DATA]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { variables } = data.payload;
      return variables;
    }
    return [];
  },
  [constants.UPDATE_VARIABLE_MASTER_DATA_BY_ID]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { updated, id } = data.payload;
      return state.map((row) => {
        if (row.id === parseInt(id)) {
          return updated;
        }
        return row;
      });
    }
    return state;
  },
  [constants.SIGN_OUT]: () => [],
}, []);

export const offers = handleActions({
  [constants.GET_OFFERS_REQUEST_BY_ID]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.UPDATE_MANUAL_CALC_OFFERS_REQUEST_BY_ID]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { ajustOffer, message, request, resume } = state;
      const { ajustUpdated, type } = data.payload;
      let dataEquip = resume[0];
      let dataServ = resume[1];
      let dataParts = resume[2];
      if (type === "equipments") {
        dataEquip = resume[0].map((row) => {
          if (row.id === ajustUpdated.id) {
            return ajustUpdated;
          } else {
            return row;
          }
        })
      } else if (type === "services") {
        dataServ = resume[1].map((row) => {
          if (row.id === ajustUpdated.id) {
            return ajustUpdated;
          } else {
            return row;
          }
        })
      } else if (type === "spareParts") {
        dataParts = resume[2].map((row) => {
          if (row.id_CalculoSparePartes === ajustUpdated.id_CalculoSparePartes) {
            return ajustUpdated;
          } else {
            return row;
          }
        })
      }
      return {
        message,
        request,
        ajustOffer,
        resume: [dataEquip, dataServ, dataParts]
      }
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const requestInAjustment = handleActions({
  [constants.GET_REQUEST_OFFERS_IN_AJUSTMENT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const logsOffer = handleActions({
  [constants.GET_LOGS_BY_AJUST_OFFER]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});
