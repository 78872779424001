import React from "react";
import { WeuiBackOutlined } from "../Shared/Icons/iconiFy";
import { Col, Row, Input, Card, CardBody, Button, Label } from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import moment from "moment";

const ApproveReserve = ({
  title,
  subtitle,
  structureApprove,
  info,
  handleReturn,
  showConfirmApprove,
  showCancelApprove,
}) => {
  return (
    <div>
      <Card>
        <CardHeader>
          <Row
            className="justify-content-between"
            style={{ marginRight: "2vh" }}
          >
            <Col>
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            <Col sm="12" md="2">
              <Button
                className="btn-icon sm"
                color="dark"
                block
                type="button"
                onClick={() => handleReturn()}
              >
                <span
                  className="btn-inner--icon mr-1"
                  style={{ whiteSpace: "normal", textAlign: "justify" }}
                >
                  <WeuiBackOutlined />
                </span>
                Regresar
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {structureApprove.map((item, key) => (
            <Row className="mt-3" key={key}>
              {item.type === "select" ? (
                <>
                  <Col xs="12" md="4" key={key}>
                    {" "}
                    {`${item.label}`}:
                  </Col>
                  <Col xs="12" md="8" key={`colSelect${key}`}>
                    <Input
                      disabled={item.disabled}
                      key={key}
                      className="m-0"
                      type={item.type}
                      defaultValue={info[item.value]}
                    />
                  </Col>
                </>
              ) : item.type === "date" ? (
                <>
                  <Col key={key} xs="12" md="4">
                    {`${item.label}`}:
                  </Col>
                  <Col xs="12" md="8" key={`colSelect${key}`}>
                    <Input
                      disabled={item.disabled}
                      key={key}
                      className="m-0"
                      type={item.type}
                      defaultValue={moment.utc(info[item.value]).format("YYYY-MM-DD")}
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col key={key} xs="12" md="4">
                    {`${item.label}`}:
                  </Col>
                  <Col xs="12" md="8" key={`colSelect${key}`}>
                    <Input
                      disabled={item.disabled}
                      key={key}
                      className="m-0"
                      type={item.type}
                      defaultValue={info[item.value]}
                    />
                  </Col>
                </>
              )}
            </Row>
          ))}
        </CardBody>
      </Card>
      <Row>
        <Col xs="12" xl="6">
          <Card>
            <CardHeader>
              <Row
                className="justify-content-between"
                style={{ marginRight: "2vh" }}
              >
                <Col>
                  <h3 className="mb-0">Acciones</h3>
                  <p className="text-sm mb-0">Flujo de aprobación</p>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <>
                <Row>
                  <Col xs="12" md="6" xl="12">
                    <Button
                      className="btn  btn-icon"
                      color="danger"
                      block
                      type="button"
                      onClick={() => showCancelApprove()}
                    >
                      <span className="btn-inner--icon mr-">
                        <i className="fas fa-times"></i>
                      </span>
                      <span className="btn-inner--text">
                        Rechazar solicitud
                      </span>
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col xs="12" md="6" xl="12">
                    <Button
                      className="btn-icon"
                      color="success"
                      block
                      type="button"
                      onClick={() => showConfirmApprove()}
                    >
                      <span className="btn-inner--icon mr-">
                        <i className="fas fa-save"></i>
                      </span>
                      <span className="btn-inner--text">Aprobar solicitud</span>
                    </Button>
                  </Col>
                </Row>
              </>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ApproveReserve;
