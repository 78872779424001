import { getContacts, insertSalesTeam, deleteSalesTeam, getProducts } from "actions/costaRicaBids";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button
} from "reactstrap";

const AgreementCREditFormTwo = ({
  dataProducts,
  info,
  handleChangeInfo,
  handleOnLoadEditModal,
  options,
  isDisabled,
  setOptions,
  editInfo,
  setEditInfo,
  setRowsModal
}) => {
  const dispatch = useDispatch();

  const colTable = [
    {
      value: "code",
      label: "Codigo",
    },
    {
      value: "name",
      label: "Nombre",
    },
    {
      value: "amount",
      label: "Cantidad",
    },
    {
      value: "unit",
      label: "Unidad",
    },
    {
      value: "unitPrice",
      label: "Precio Unitario",
    },
  ];
  const rowsModal = [
    {
      colWidth: "2",
      label: "Interes para GBM",
      id: "gbmStatus",
      type: "select",
    },
    {
      colWidth: "5",
      label: "Account Manager",
      id: "accountManager",
      type: "typeahead",
      options: "employee"


    },
    {
      colWidth: "5",
      label: "Gerente de Sector",
      id: "managerSector",
      type: "typeahead",
      options: "employee"

    },
    {
      colWidth: "4",
      label: "Oportunidad",
      id: "opp",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Quote",
      id: "quote",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Sales Order",
      id: "salesOrder",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Participa",
      id: "participation",
      type: "select",
    },
    {
      colWidth: "4",
      label: "Linea de Producto",
      id: "productLine",
      type: "select",
    },
    {
      colWidth: "4",
      label: "Value Team",
      id: "valueTeam",
      type: "select",
    },
    {
      colWidth: "6",
      label: "Cliente de la institucion",
      id: "customerInstitute",
      type: "typeahead",
      options: "institution"
    },
    {
      colWidth: "6",
      label: "Contacto",
      id: "contactName",
      type: "select",
    },
    {
      colWidth: "5",
      label: "Sales Team",
      id: "SalesTeam",
      type: "typeahead",
      options: "employee"
    },
  ];
  //#region
  const [page, setPage] = useState(1);
  //#endregion
  const [loading, setLoading] = useState(false);
  //#region  Traer Contactos
  useEffect(() => {
    const getContacts = async () => {
      await handelOngetContacts();
    };
    getContacts();
  }, [editInfo.customerInstitute]);
  //ad
  const handelOngetContacts = async () => {
    if (editInfo.customerInstitute) {
      setLoading(true);
      let idCustomer = "";
      if (editInfo.customerInstitute) {
        idCustomer = editInfo.customerInstitute
      } else {
        idCustomer = info.customerInstitute
      }
      dispatch(getContacts({ info: idCustomer, type: "2" })).then((resp) => {
        const { payload } = resp;
        if (payload.status === 200) {
          setOptions((prevState) => ({
            ...prevState,
            contacts: payload.data.payload.response,
          }));
          const contactDefault = payload.data.payload.response[0];
          if (contactDefault) {
            setEditInfo(prevState => ({
              ...prevState,
              contactName: `${contactDefault.FIRST_NAME} ${contactDefault.LAST_NAME}`,
              contactId: contactDefault.ID_CONTACT_CRM
            }))
          }
        }
        setLoading(false);
      });
    }
  };
  //#endregion

  const [state, setState] = useState({
    sizePerPage: 3,
  });
  const { sizePerPage } = state;
  const rowsProducts = dataProducts.products;
  //#region Pagination
  const paginations =
    rowsProducts.length > sizePerPage
      ? rowsProducts.length / sizePerPage > Math.round(rowsProducts.length / sizePerPage)
        ? Math.round(rowsProducts.length / sizePerPage) + 1
        : Math.round(rowsProducts.length / sizePerPage)
      : 1;
  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink onClick={() => handleOnSetPage(i, "page")}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return options.length >= 5
      ? page >= 5
        ? options.slice(page - 3, page + 2)
        : options.slice(0, 5)
      : options;
  };
  //Cambiar de pagina
  const handleOnSetPage = (page) => {
    setPage(page);
  };
  //#endregion

  const handleOnDeleteSalesTeam = (id) => {
    console.log(id)
    dispatch(deleteSalesTeam({ id: id })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        dispatch(getProducts({ info: editInfo.id })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setRowsModal(prevState => ({
              ...prevState,
              salesTeam: payload.data.payload.salesTeam,
            }))
          }
        })
      }
    });
  }
  const handleOnInsertSalesTeam = () => {
    dispatch(insertSalesTeam({ info: editInfo })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        dispatch(getProducts({ info: editInfo.id })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setRowsModal(prevState => ({
              ...prevState,
              salesTeam: payload.data.payload.salesTeam,
            }))
          }
        })
      }
    });
  }
  console.log(editInfo)
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Card>
              <CardBody>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  striped={true}
                  hover={true}
                >
                  <thead className="thead-light">
                    <tr>
                      {colTable.map((row, key) => {
                        return (
                          <th key={key} className="justify-content-md-center">
                            {row.label}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {dataProducts &&
                      dataProducts
                        .products.slice((page - 1) * sizePerPage, page * sizePerPage)
                        .map((row, key) => (
                          <tr key={key} className="text-center">
                            {colTable.map((col, key) => (
                              <td key={key} className="text-center">
                                {row[col.value]}
                              </td>
                            ))}
                          </tr>
                        ))}
                  </tbody>
                </Table>
                <Col xs="12" md="6" className="p-1">
                  <Row className="justify-content-start">
                    <Col xs="12" md="6">
                      <span className="pagination mb-0">
                        Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                        {page * sizePerPage > rowsProducts.length
                          ? rowsProducts.length
                          : page * sizePerPage}{" "}
                        de {rowsProducts.length} resultados
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="6" className="p-1">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                      <PaginationLink
                        onClick={() =>
                          handleOnSetPage(page === 1 ? page : page - 1, "page")
                        }
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {renderPaginations()}
                    <PaginationItem
                      className={page === paginations ? "disabled" : ""}
                    >
                      <PaginationLink
                        onClick={() =>
                          handleOnSetPage(
                            page === paginations ? page : page + 1,
                            "page"
                          )
                        }
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </Col>
              </CardBody>
              <CardFooter>
                <Row>
                  {rowsModal.map((row, key) =>
                    row.type === "text" ? (
                      <Col xs={row.colWidth} key={key}>
                        <FormGroup key={key}>
                          <label
                            style={{ fontSize: "12px" }}
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {`${row.label}:`}
                          </label>
                          <Input
                            disabled={row.disabled === true ? true : isDisabled}
                            id={row.id}
                            key={key}
                            className="form-control"
                            type={row.type}
                            defaultValue={info[row.id] || ""}
                            onChange={(e) =>
                              handleChangeInfo(row.id, e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    ) : row.type === "select" ?
                      row.id === "contactName" ? (
                        <Spin size="small" spinning={loading}>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {" "}
                              {`Contacto:`}
                            </label>
                            <Input
                              type={"select"}
                              disabled={row.disabled === true ? true : isDisabled}
                              className="basic-multi-select text-dark"
                              value={editInfo.contactName}
                              onChange={(e) =>
                                handleChangeInfo(
                                  "contactId",
                                  e.target.children[e.target.selectedIndex]
                                )
                              }
                            >
                              {options["contacts"].map((value, key) => {
                                return (
                                  <option
                                    key={key}
                                    id={value.ID_CONTACT_CRM}
                                  >{`${value.FIRST_NAME} ${value.LAST_NAME}`}</option>
                                );
                              })}
                            </Input>
                          </FormGroup>
                        </Spin>) : (
                        <Col xs={row.colWidth} key={key}>
                          <FormGroup key={key}>
                            <label
                              style={{ fontSize: "12px" }}
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {`${row.label}:`}
                            </label>
                            <Select
                              key={`input${key}`}
                              isDisabled={row.disabled === true ? true : isDisabled}
                              className="basic-single"
                              type="select"
                              isSearchable
                              defaultValue={{ label: info[row.id], value: 1 } || ""}
                              options={options[row.id]}
                              onChange={row.id === "participation" ? (e) => handleOnLoadEditModal(e, info) : (e) => handleChangeInfo(row.id, e.value)}
                            />
                          </FormGroup>
                        </Col>
                      ) : row.type === "typeahead" && row.id === "customerInstitute" ? (
                        <Col xs={row.colWidth} key={key}>
                          <FormGroup >
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {`Cliente:`}
                            </label>
                            <Typeahead
                              id={row.id}
                              disabled={row.disabled === true ? true : isDisabled}
                              labelKey={options => `${options.name}`}
                              minLength={0}
                              onChange={(e) => handleChangeInfo("customerInstitute", e[0])}
                              options={options["customers"]}
                              defaultInputValue={editInfo[`customerName`] || ""}
                              renderMenuItemChildren={(value) => (
                                <div >
                                  {value.id}
                                  <div>
                                    {
                                      <small>ID: {value.name}</small>
                                    }
                                  </div>
                                </div>
                              )}
                            />
                          </FormGroup>
                        </Col>
                      ) : row.type === "typeahead" && row.id !== "SalesTeam" ?
                        <Col xs={row.colWidth} key={key}>
                          <FormGroup >
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {row.label}
                            </label>
                            <Typeahead
                              id={row.id}
                              disabled={row.disabled === true ? true : isDisabled}
                              labelKey={options => `${options.name}`}
                              minLength={0}
                              onChange={(e) => handleChangeInfo(row.id, e[0])}
                              options={options[row.options]}
                              defaultInputValue={info[row.id] || ""}
                              renderMenuItemChildren={(value) => (
                                <div >
                                  {value.user}
                                  <div>
                                    {
                                      <small>Nombre: {value.name}</small>
                                    }
                                  </div>
                                </div>
                              )}
                            />
                          </FormGroup>
                        </Col>
                        : (
                          <>
                            <Col xs={row.colWidth} key={key}>
                              <FormGroup >
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {row.label}
                                </label>
                                <Typeahead
                                  id={row.id}
                                  disabled={row.disabled === true ? true : isDisabled}
                                  labelKey={options => `${options.name}`}
                                  minLength={0}
                                  onChange={(e) => handleChangeInfo(row.id, e[0])}
                                  options={options[row.options]}
                                  renderMenuItemChildren={(value) => (
                                    <div >
                                      {value.user}
                                      <div>
                                        {
                                          <small>Nombre: {value.name}</small>
                                        }
                                      </div>
                                    </div>
                                  )}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={1}>
                              <FormGroup >
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {" "}
                                </label>
                                <Button
                                  style={{ marginTop: "10px" }}
                                  className="btn btn-icon"
                                  color="success"
                                  block
                                  type="button"
                                  onClick={() => handleOnInsertSalesTeam()}
                                > <span className="btn-inner--icon mr-">
                                    <i class="fas fa-plus"></i>
                                  </span>
                                  <span className="btn-inner--text"></span>

                                </Button>
                              </FormGroup>
                            </Col>
                          </>
                        )
                  )}
                </Row>
                <Row>
                  {dataProducts &&
                    dataProducts
                      .salesTeam.map((item, key) => (
                        <>
                          <Col md="2">
                            <label key={key}>{item.name}</label>
                          </Col>
                          <Col md="1">
                            <Button
                              key={key}
                              className="btn btn-icon"
                              color="danger"
                              block
                              type="button"
                              onClick={() => handleOnDeleteSalesTeam(item.id)}
                            >
                              <span key={key} className="btn-inner--icon mr-">
                                <i key={key} class="fas fa-trash-alt"></i>
                              </span>
                              <span key={key} className="btn-inner--text"></span>
                            </Button>
                          </Col>
                        </>
                      ))
                  }
                </Row>
              </CardFooter>
            </Card>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AgreementCREditFormTwo;
