export const getInfoRequestInQuote = (state) => {
  if (Object.keys(state.infoRequerimentsInQuotes).length) {
    const { request } = state.infoRequerimentsInQuotes;
    return request;
  }
  return [];
};

export const getRequestInQuotes = (state) => {
  if (Object.keys(state.requerimentsInQuotes).length) {
    const { requests } = state.requerimentsInQuotes;
    return requests;
  }
  return [];
};

export const getFiltersOptions = (state) => {
  if (Object.keys(state.requerimentsInQuotes).length) {
    const { requests } = state.requerimentsInQuotes;
    let clients = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.customer,
      };
    });
    clients = clients
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => clients[e])
      .map((e) => clients[e]);
    clients = clients.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let states = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.status,
      };
    });
    states = states
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => states[e])
      .map((e) => states[e]);
    states = states.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let opps = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.opportunityNumber,
      };
    });
    opps = opps
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => opps[e])
      .map((e) => opps[e]);
    opps = opps.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return {
      customer: [{ value: 0, label: "Todos" }, ...clients],
      status: [{ value: 0, label: "Todos" }, ...states],
      opp: [{ value: 0, label: "Todos" }, ...opps],
    };
  }
  return {
    customer: [],
    status: [],
    opp: [],
  };
};

export const getCommentsRequestInQuotes = (state) => {
  if (Object.keys(state.commentsRequestInQuotes).length) {
    const { comments } = state.commentsRequestInQuotes;
    return comments;
  }
  return [];
};

export const getFilesRequestInQuotes = (state) => {
  if (Object.keys(state.filesRequestInQuotes).length) {
    const { files } = state.filesRequestInQuotes;
    return files;
  }
  return [];
};
