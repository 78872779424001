import { updateFastCotyzationReportStatus } from 'actions/panamaBids.jsx';
import React, { useState, useEffect } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, Col, Input, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { getAllFastCotyzationReport } from "actions/panamaBids.jsx";
import moment from "moment";

const QuickMainTable = ({ rows, openModal, page, setPage, setrefreshInput, Toast, setRowsModal, isAdmin }) => {

  const dispatch = useDispatch();

  const [selected, setSelected] = useState([
    { value: "singleOrderRecord", label: "Registro Unico de Pedido" },
    { value: "purchaseUnit", label: "Unidad de Compra" },
    { value: "entity", label: "Entidad" },
    { value: "quoteDate", label: "Fecha de Cotizacion" },
    { value: "interestGBM", label: "Interes GBM" }
  ]);
  const optionsSelect = [
    {
      value: "singleOrderRecord",
      label: "Registro Unico de Pedido",
      disabled: true,
    },
    { value: "entity", label: "Entidad", disabled: true },
    { value: "dependence", label: "Depencia" },
    { value: "purchaseUnit", label: "Unidad de Compra", disabled: true },
    { value: "description", label: "Descripcion" },
    { value: "quoteDate", label: "Fecha de Cotizacion", disabled: true },
    { value: "estimatePrice", label: "Precio Estimado" },
    { value: "contactName", label: "Nombre de Contacto" },
    { value: "contactPhone", label: "Telefono de Contacto" },
    { value: "mailContact", label: "Correo de Contacto" },
    { value: "deliveryMethod", label: "Metodo de Entrega" },
    { value: "deliveryDays", label: "Dias de Entrega" },
    { value: "interestGBM", label: "Interes GBM", disabled: true },
    { value: "quoteLink", label: "Link de Cotizacion" },
    { value: "comments", label: "Comentarios" },
  ];
  const [displayI, setdisplayI] = useState(false);
  const [alert, setAlert] = useState("");

  const [state, setState] = useState({
    sizePerPage: 10,
  });

  const { sizePerPage } = state;
  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => handleOnSetPage(i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  //Cambiar de pagina
  const handleOnSetPage = (page, who) => {
    setPage(page);
  };
  const handleOnSetNumPagination = (e) => {
    setPage(1);
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      sizePerPage: value
    }))
  }
  const handleOnEditAgreement = (row) => {
    const interes = row.interestGBM;
    setAlert(
      <ReactBSAlert
        custom
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={interes === "Si" ? "¿Está seguro(a) que NO es de interes de GBM?" : "¿Está seguro(a) que SI es de interes de GBM?"}
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        onConfirm={() => handleToggle(row)}
        onCancel={() => setAlert("")}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sí, guardar"
        cancelBtnText="No, cancelar"
        btnSize="md"
      />
    )
  }
  useEffect(() => {
    const getRowsData = async () => {
      await getRows();
    }
    getRowsData();
  }, [displayI]);

  const getRows = async () => {
    dispatch(getAllFastCotyzationReport()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal((prevState) => ({
          ...prevState,
          rows: payload.data.payload.data,
        }));
      }
    });
  }
  const handleToggle = ((row) => {
    let value = "";
    if (row.interestGBM === "Si") {
      value = "No"
    } else {
      value = "Si"
    }
    dispatch(updateFastCotyzationReportStatus({ info: row, status: value })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setAlert("")
        setrefreshInput(3);
        Toast.fire({
          title: "Atención",
          html: "Se guardó correctamente",
          type: 'success'
        });
      }
    }
    )
    setdisplayI(!displayI);
  });

  return (
    < div >
      {alert}
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="10">
              <p className="text-sm mb-0">
                Columnas Seleccionables
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="2">
              <MultiSelect style={{ maxWeight: "5px" }}
                options={optionsSelect}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">
              <tr>
                {selected.map((item, key) => {
                  return (
                    <th key={key} className="justify-content-md-center">
                      {item.label}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="list">
              {rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                <tr key={key} className="text-center">
                  {selected.map((col, key) => (
                    <td key={key} className="text-center">
                      {col.value === "singleOrderRecord" ?
                        <a
                          onClick={() => openModal(row)}
                          style={{ color: "blue" }}
                        >
                          {row[col.value]}
                        </a>
                        : col.value === "interestGBM" ?
                          <label className="custom-toggle custom-toggle-info">
                            <input id="edit" type="checkbox" onChange={() => handleOnEditAgreement(row)} disabled={isAdmin === true ? false : true} checked={row[col.value] === "Si" ? true : false} />
                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Si"></span>
                          </label>
                          : col.type === "date" && moment.utc(row[col.value]).format('L') !== "Invalid date" ?
                            moment.utc(row[col.value]).format('L')
                            : (
                              row[col.value]
                            )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="p-1">
                <Row className="justify-content-start">
                  <Col xs="12" md="6">
                    <span className="pagination mb-0">
                      Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                      {page * sizePerPage > rows.length
                        ? rows.length
                        : page * sizePerPage}{" "}
                      de {rows.length} resultados
                    </span>
                  </Col>
                  <Col xs='12' md='2'>
                    <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="6" className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() =>
                        handleOnSetPage(page === 1 ? page : page - 1, "page")
                      }
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {renderPaginations()}
                  <PaginationItem
                    className={page === paginations ? "disabled" : ""}
                  >
                    <PaginationLink
                      onClick={() =>
                        handleOnSetPage(
                          page === paginations ? page : page + 1,
                          "page"
                        )
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>
    </div >
  );
};

export default QuickMainTable;
