// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

const CardRequests = props => {

  const {
    title,
    subtitle,
    rows,
    onOpen
  } = props;

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col xs="12" md='7'>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <ListGroup className="list my--3" flush>
          {
            rows.map((row, key) => {
              return (
                <ListGroupItem key={key} className="px-0">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        alt="Notificación"
                        className="avatar rounded-circle"
                        src={require(`assets/img/theme/requeriments.png`)}
                      />
                    </Col>
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        Oportunidad: {row.opportunityNumber} - Usuario: {row.createdBy}
                      </h4>
                      <p className={row.state === 5 ? "mb-0 text-danger" : row.flowStatus === 4 ? "mb-0 text-success" : "mb-0"}>
                        {
                          moment(row.assignmentCreation)
                            .utc()
                            .utcOffset(moment().utcOffset())
                            .format("lll")
                        } - {row.status}
                      </p>
                    </div>
                    <Col className="col-auto">
                      <Button
                        color="success"
                        // size="sm"
                        type="button"
                        onClick={() => onOpen(row)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-folder-open" />
                        </span>
                        <span className="btn-inner--text">Abrir</span>
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              )
            })
          }
        </ListGroup>
      </CardBody>
    </Card>
  );
};

CardRequests.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
};

export default CardRequests;
