import { getAllPurchaseOrderMacro, getDataReport } from 'actions/panamaBids.jsx';
import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
// react-select library for filters
import Select from "react-select";
import Exportexcel from "react-export-excel";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import moment from "moment";

const AgreementFilters = ({ rows, contInput, setContInput, setrefreshInput, dataSelect, setRowsModal, options, setLoading, isDisplayBF,
  Toast, }) => {
  const excelReport = [
    { label: "Registro unico de pedido", value: "singleOrderRecord" },
    { label: "Sector", value: "sector" },
    { label: "Convenio", value: "agreement" },
    { label: "Entidad", value: "entity" },
    { label: "Codigo Producto", value: "productCode" },
    { label: "Descipción Producto", value: "productDescription" },
    { label: "Marca", value: "brand" },
    { label: "Precio producto", value: "price" },
    { label: "Cantidad", value: "quantity" },
    { label: "orderType", value: "orderType" },
    { label: "Total", value: "Total" },
    { label: "Sub total de orden", value: "orderSubtotal" },
    { label: "Orden de compra", value: "purchaseOrder" },
    { label: "Fecha de registro", value: "registrationDate" },
    { label: "Fecha de publicación", value: "publicationDate" },
    { label: "Cumplimiento de fianza", value: "performanceBond" },
    { label: "Oportunidad", value: "oportunity" },
    { label: "Quote", value: "quote" },
    { label: "Orden de venta", value: "salesOrder" },
    { label: "Día de entrega", value: "deliveryDay" },
    { label: "Fecha maxima de entrega", value: "maximumDeliveryDate" },
    { label: "Días faltantes", value: "daysRemaining" },
    { label: "Forecast", value: "forecast" },
    { label: "Tipo de Forecast", value: "forecastType" },
    { label: "Estado GBM", value: "gbmStatus" },
    { label: "Estado de la orden", value: "orderStatus" },
    { label: "Provincia", value: "state" },
    { label: "Lugar de entrega", value: "deliveryLocation" },
    { label: "Contacto de entrega", value: "deliveryContact" },
    { label: "Teléfono", value: "phone" },
    { label: "Email", value: "email" },
    { label: "Confirmación de orden", value: "orderConfirmation" },
    { label: "Fecha real de entrega", value: "actualDeliveryDate" },
    { label: "Monto de multa", value: "fineAmount" },
    { label: "Unidad Solicitante", value: "requestingUnit" },
    { label: "Contacto de cuenta", value: "accountContact" },
    { label: "Email de cuenta", value: "emailAccount" },
    { label: "Pedido del proveedor", value: "vendorOrder" },
    { label: "Link del documento", value: "documentLink" },
    { label: "Comentario", value: "comment" }
  ]
  const ExcelFile = Exportexcel.ExcelFile;
  const ExcelSheet = Exportexcel.ExcelSheet;
  const ExcelColumn = Exportexcel.ExcelColumn;

  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState([]);
  const [dataSelectFiler, setDataSelectFiler] = useState([]);
  const [conditionFilter, setConditionFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState([]);
  const [labelDataFilter, setLabelDataFilter] = useState([]);
  const [typeDate, setTypeDate] = useState([]);
  const handleOnPlusInput = () => {
    let amount = contInput;
    amount.push(2);
    setContInput(amount);
    setrefreshInput(`num${amount}`);
  };
  const typeDateOptions = [
    { label: "Fecha de Registro", value: "registrationDate" },
    { label: "Fecha de Publicación", value: "publicationDate" },
    { label: "Fecha Real", value: "actualDeliveryDate" },
    { label: "Forecast", value: "forecast" }
  ]
  const handleOnMinusInput = () => {
    let amount = contInput;
    amount.pop(1);
    setContInput(amount);
    setrefreshInput(`num${amount}`);
  };
  const handleChangeInfo = (constant, e, type) => {
    if (type === "select") {
      if (e.Code) {
        setDataFilter((prevState) => ({
          ...prevState,
          [constant]: e.Code
        }))
        setLabelDataFilter((prevState) => ({
          ...prevState,
          [constant]: e.label
        }))
      } else {
        setDataFilter((prevState) => ({
          ...prevState,
          [constant]: e.label
        }))
        setLabelDataFilter((prevState) => ({
          ...prevState,
          [constant]: e.label
        }))
      }
    } else {
      setDataFilter((prevState) => ({
        ...prevState,
        [constant]: e
      }))
    }

  }
  const handleChangeInfoSelects = (constant, e, key) => {
    setLabelDataFilter((prevState) => ({
      ...prevState,
      [`Text${key}`]: ""
    }))
    setDataFilter((prevState) => ({
      ...prevState,
      [`Text${key}`]: ""
    }))
    setDataSelectFiler((prevState) => ({
      ...prevState,
      [constant]: e
    }))
  }
  const handleChangeInfoCondition = (constant, e) => {
    setConditionFilter((prevState) => ({
      ...prevState,
      [constant]: e
    }))
  }
  const handleChangeInfoDate = (constant, e) => {
    setDateFilter((prevState) => ({
      ...prevState,
      [constant]: e
    }))
  }
  const cleanFilters = () => {
    setDataFilter([]);
    setDataSelectFiler([]);
    setConditionFilter([]);
    setDateFilter([]);
    setContInput([1]);
    setrefreshInput(5);
    getDataTable();
    setTypeDate([]);
  }
  const getDataTable = () => {
    setLoading(true);
    const date = typeDate.value
    dispatch(getAllPurchaseOrderMacro({ data: dataFilter, selects: dataSelectFiler, condition: conditionFilter, dates: dateFilter, typeDate: date, displayBf: isDisplayBF })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal(prevState => ({
          ...prevState,
          rows: payload.data.payload.data
        }))
      } else if (resp.payload.response.data.status === 404) {
        Toast.fire({
          title: "Atención:",
          html: "La información no se encontro",
          type: 'warning'
        });
      } else {
        Toast.fire({
          title: "Atención:",
          html: "Llenar el campo consicional AND/OR",
          type: 'warning'
        });
      }
      setLoading(false);
    })
  }
  const handleOnTypeDate = (e) => {
    setTypeDate(e);
  }
  useEffect(() => {
    const getRowsData = async () => {
      await handleOnDownloadExcel();
    }
    getRowsData();
  }, [rows.rows], [])
  const handleOnDownloadExcel = async () => {
    const date = typeDate.value
    dispatch(getDataReport({ data: dataFilter, selects: dataSelectFiler, condition: conditionFilter, dates: dateFilter, typeDate: date, displayBf: isDisplayBF })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        const data = payload.data.payload.data
        console.log(data)
        setRowsModal(prevState => ({
          ...prevState,
          rowsExcel: data
        }))
      } else if (resp.payload.response.data.status === 404) {
        Toast.fire({
          title: "Atención:",
          html: "La información no se encontro",
          type: 'warning'
        });
      }
    })
  }
  console.log(rows.rowsExcel)
  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="8">
              <h3 className="mb-0">Busqueda Avanzada</h3>
              <p className="text-sm mb-0">
                Busqueda personalizada en los registros de la tabla inferior
              </p>
            </Col>
            <Col xs="12" md="1" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-icon"
                color="primary"
                size="sm"
                block
                type="button"
                onClick={() => getDataTable()}
              >
                <span className="btn-inner--icon mr-">
                  <i class="fas fa-search"></i>
                </span>
                <span className="btn-inner--text">Buscar</span>
              </Button>
            </Col>
            <Col xs="12"md="2" className="p-1">
              <ExcelFile element={<Button
                className="btn btn-icon"
                color="success"
                block
                type="button"
              >
                <span className="btn-inner--icon mr-">
                  <i class="fas fa-download"></i>
                </span>
                <span className="btn-inner--text">Descargar</span>
              </Button>} filename="Reporte Convenio Marco">

                <ExcelSheet data={rows.rowsExcel} name="Reporte convenio marco">
                  {excelReport.map((col, key) => (
                    <ExcelColumn key={key} label={col.label} value={col.value} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
            </Col>
            <Col xs="12" md="1" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-icon"
                color="danger"
                size="sm"
                block
                type="button"
                onClick={() => cleanFilters()}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text">Limpiar</span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="d-flex justify-content-center align-items-center">
            {
              contInput.map((item, key) => (
                <>
                  {
                    item === 1 ?
                      <>
                        <Col xs="12" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="startDate">
                            </label>
                            <Input
                              key={`select${key}`}
                              className="m-0"
                              type="select"
                              value={dataSelectFiler[`Select${key}`] ? (dataSelectFiler[`Select${key}`].value) : ""}
                              onChange={(e) => handleChangeInfoSelects(`Select${key}`, e.target.children[e.target.selectedIndex].id, key)}
                            >
                              {dataSelect.map((value, key) => {
                                return (
                                  <option key={key} id={value.Field}>{`${value.Comment}`}</option>
                                );
                              })
                              }
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                          {
                            dataSelectFiler.Select0 === "sector" || dataSelectFiler.Select0 === "forecastType" ||
                              dataSelectFiler.Select0 === "orderStatus" || dataSelectFiler.Select0 === "gbmStatus"
                              ?
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                </label>
                                <Select
                                  key={`input${key}`}
                                  className="basic-single"
                                  type="select"
                                  isSearchable
                                  value={{ label: labelDataFilter[`Text${key}`], value: 1 } || ""}
                                  options={options[dataSelectFiler.Select0]}
                                  onChange={(e) => handleChangeInfo(`Text${key}`, e, "select")}
                                />
                              </FormGroup> :
                              <FormGroup >
                                <label
                                  className="form-control-label"
                                  htmlFor="startDate">
                                </label>
                                <Input
                                  key={`input${key}`}
                                  className="m-0"
                                  value={dataFilter[`Text${key}`] || ""}
                                  onChange={(e) => handleChangeInfo(`Text${key}`, e.target.value)}
                                />
                              </FormGroup>
                          }

                        </Col>
                        <Col xs="12" md="1" className=" d-flex justify-content-end">
                          <Button
                            className="btn ml-5 "
                            color="success"
                            id="edit"
                            disabled={contInput.length === 4 ? true : false}
                            onClick={() => handleOnPlusInput()}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus"></i>
                            </span>
                          </Button>
                        </Col>
                        <Col xs="12" md="1">
                          <Button
                            className="btn mr-5"
                            color="danger"
                            id="edit"
                            disabled={contInput.length === 1 ? true : false}
                            onClick={() => handleOnMinusInput()}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-minus"></i>
                            </span>
                          </Button>
                        </Col>
                      </>
                      : item === 2 &&
                      <>
                        <Col xs="12" md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="startDate">
                            </label>
                            <Input
                              key={`select${key}`}
                              value={dataSelectFiler[`Select${key}`] ? (dataSelectFiler[`Select${key}`].value) : ""}
                              className="m-0"
                              type="select"
                              onChange={(e) => handleChangeInfoSelects(`Select${key}`, e.target.children[e.target.selectedIndex].id, key)}
                            >
                              {dataSelect.map((value, key) => {
                                return (
                                  <option key={key} id={value.Field}>{`${value.Comment}`}</option>
                                );
                              })
                              }
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                          {
                            dataSelectFiler[`Select${key}`] === "sector" || dataSelectFiler[`Select${key}`] === "forecastType" ||
                              dataSelectFiler[`Select${key}`] === "orderStatus" || dataSelectFiler[`Select${key}`] === "gbmStatus" ?
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                </label>
                                <Select
                                  key={`input${key}`}
                                  className="basic-single"
                                  type="select"
                                  isSearchable
                                  value={{ label: labelDataFilter[`Text${key}`], value: 1 } || ""}
                                  options={options[dataSelectFiler[`Select${key}`]]}
                                  onChange={(e) => handleChangeInfo(`Text${key}`, e, "select")}
                                />
                              </FormGroup>
                              :
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="startDate">
                                </label>
                                <Input
                                  key={`input${key}`}
                                  value={dataFilter[`Text${key}`] || ""}
                                  className="m-0"
                                  onChange={(e) => handleChangeInfo(`Text${key}`, e.target.value)}
                                />
                              </FormGroup>
                          }
                        </Col>
                        <Col xs="12" md="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="startDate">
                            </label>
                            <Select
                              key={key}
                              className="basic-single"
                              type="select"
                              value={{ label: conditionFilter[`condition${key}`], value: 1 } || ""}
                              isSearchable
                              options={[{ label: "AND", value: 1 }, { label: "OR", value: 2 }]}
                              onChange={(e) => handleChangeInfoCondition(`condition${key}`, e.label)}
                            />
                          </FormGroup>
                        </Col>
                      </>
                  }
                </>
              ))
            }

          </Row>
          <Row>
            <Col className="text-left" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="startDate">
                  {"Tipo de Fecha:"}
                </label>
                <Select
                  className="basic-single"
                  type="select"
                  isSearchable
                  value={{ label: typeDate.label, value: 1 } || ""}
                  options={typeDateOptions}
                  onChange={(e) => handleOnTypeDate(e)}
                />
              </FormGroup>
            </Col>
            <Col className="text-left" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="startDate">
                  Fecha Inicio:
                </label>
                <Input
                  className="m-0"
                  value={dateFilter["DateStart"] || ""}
                  style={{ height: "calc(2.25rem + 2px)" }}
                  type="date"
                  onChange={(e) => handleChangeInfoDate("DateStart", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="text-left" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="endDate">
                  Fecha Final:
                </label>
                <Input
                  className="m-0"
                  style={{ height: "calc(2.25rem + 2px)" }}
                  value={dateFilter["DateEnd"] || ""}
                  type="date"
                  onChange={(e) => handleChangeInfoDate("DateEnd", e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AgreementFilters;
