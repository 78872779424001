import {
  findMaintenance,
  insertMaintenance,
  updateMaintenanceInfo
} from "actions/mis.jsx";
import Form from "components/MIS/FormMISMaintenance.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Table from "components/Shared/Table/Table.jsx";
import moment from "moment";
import "moment/locale/es";
// react library
import React, { Component } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Badge, Button, Container, Progress } from "reactstrap";
// core selectors
import { getTeamsUserAccess } from "selectors/adminLayout";
import { getMaintenance } from "selectors/mis.jsx";
import "../../assets/css/table.css";

/**
 * Clase para visualizar los componentes que
 * se utilizaran en el modulo de Planning MRS
 * @class Nodes
 * @extends {Component}
 */
class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddForm: false,
      target: "",
      affectedServices: "",
      affectedSites: "",
      startDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00:00",
      endDate: moment().format("YYYY-MM-DD"),
      endTime: "00:00:00",
      envDEV: false,
      envQA: false,
      envPRD: true,
      status: "",
      color: "R",
      otherCon: "",
      comment: "",
      showButtonsEdit: props.teams.find(row => row === "General MIS"),
      objectValidation: {}
    };
    //this.onChangeValuesMaintenance = this.onChangeValuesMaintenance.bind(this);
  }

  componentWillMount() {
    this.setState({
      loading2: true
    });
    this.props.findMaintenance().then(async res => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload }
          } = res.payload.response;
          await this.notify("danger", "Atención", payload.message);
        } else {
          await this.notify(
            "danger",
            "Atención",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload }
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading2: false
      });
    });
  }

  publishMaintenanceData = data => {
    if (data.startTime.length !== 8) {
      data.startTime = data.startTime + ":00";
    }
    if (data.endTime.length !== 8) {
      data.endTime = data.endTime + ":00";
    }
    var timePananaEnd = moment(data.endDate + " " + data.endTime)
      .utc()
      .utcOffset("-05:00")
      .format("YYYY-MM-DD HH:mm:ss");
    var timePananaStart = moment(data.startDate + " " + data.startTime)
      .utc()
      .utcOffset("-05:00")
      .format("YYYY-MM-DD HH:mm:ss");
    data.endDate = moment(timePananaEnd).format("YYYY-MM-DD");
    data.endTime = moment(timePananaEnd).format("HH:mm:ss");
    data.startDate = moment(timePananaStart).format("YYYY-MM-DD");
    data.startTime = moment(timePananaStart).format("HH:mm:ss");

    var envOK = true;
    if (
      data.envDEV === false &&
      data.envPRD === false &&
      data.envQA === false
    ) {
      envOK = false;
      this.notify("warning", "Atención", "Selecciona un ambiente afectado");
    }
    var allOk = true;
    var objectFail = {};
    var key;
    for (key in this.state) {
      if (this.state[key] === "" && key !== "comment") {
        objectFail[key] = true;
      } else {
        objectFail[key] = false;
      }
    }
    this.setState({
      objectValidation: objectFail
    });
    for (key in objectFail) {
      if (objectFail[key] === true || envOK === false) {
        allOk = false;
      }
    }
    if (allOk) {
      var stringEnv = "";
      for (var i = 0; i < 3; i++) {
        if (i === 0) {
          if (data.envDEV === true) {
            stringEnv = stringEnv + "Desarrollo,";
          }
        }
        if (i === 1) {
          if (data.envQA === true) {
            stringEnv = stringEnv + "Calidad,";
          }
        }
        if (i === 2) {
          if (data.envPRD === true) {
            stringEnv = stringEnv + "Producción,";
          }
        }
      }
      data.env = stringEnv;
      this.setState({
        loading2: true,
        alert: null
      });
      this.sleep(500).then(() => {
        if (this.state.agreggate === true) {
          this.props.insertMaintenance(data).then(async res => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload }
                } = res.payload.response;
                await this.notify("danger", "Atención", payload.message);
              } else {
                await this.notify(
                  "danger",
                  "Atención",
                  "No se logro establecer conexion con el servidor."
                );
              }
            }
            this.setState({
              loading2: false,
              showAddForm: false
            });
          });
        } else {
          this.props.updateMaintenanceInfo(data).then(async res => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload }
                } = res.payload.response;
                await this.notify("danger", "Atención", payload.message);
              } else {
                await this.notify(
                  "danger",
                  "Atención",
                  "No se logro establecer conexion con el servidor."
                );
              }
            }
            this.setState({
              loading2: false,
              showAddForm: false
            });
          });
        }
      });
    }
  };

  expandRow = {
    parentClassName: "expand-parent",
    renderer: row => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Objetivo</span>
              <br></br>
              <span>{row.target}</span>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Sitios afectados</span>
              <br></br>
              {row.affectedSites}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Status</span>
              <br></br>
              <Badge color="" className="badge-dot mr-4">
                <i className={this.stateColor(row)} />
                <span className="status">{row.status}</span>
              </Badge>
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Conexión alterna</span>
              <br></br>
              {row.otherCon}
            </div>
          </div>
        </span>
      </div>
    ),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return (
          <div style={{ cursor: "pointer" }}>
            <b>-</b>
          </div>
        );
      }
      return (
        <div style={{ cursor: "pointer" }}>
          <b>+</b>
        </div>
      );
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <div style={{ cursor: "pointer" }}>
            <b>-</b>
          </div>
        );
      }
      return (
        <div style={{ cursor: "pointer" }}>
          <b>...</b>
        </div>
      );
    }
  };

  onChangeValuesMaintenance = value => {
    var boolValue;
    if (value.target.id === "endDate" || value.target.id === "startDate") {
      this.setState({ [value.target.id]: value.target.value }, () => {
        if (
          new Date(this.state.startDate + "") >
          new Date(this.state.endDate + "")
        ) {
          this.setState({
            startDate: moment(value.target.value).format("YYYY-MM-DD"),
            endDate: moment(value.target.value).format("YYYY-MM-DD")
          });
        }
      });
    } else if (value.target.id === "envDEV") {
      boolValue = value.target.value === "true" ? true : false;
      this.setState({ envDEV: !boolValue });
    } else if (value.target.id === "envPRD") {
      boolValue = value.target.value === "true" ? true : false;
      this.setState({ envPRD: !boolValue });
    } else if (value.target.id === "envQA") {
      boolValue = value.target.value === "true" ? true : false;
      this.setState({ envQA: !boolValue });
    } else {
      this.setState({ [value.target.id]: value.target.value });
    }
  };

  spanishDate(date) {
    var localLocale = moment(date)
      .utc()
      .utcOffset(moment().utcOffset());
    moment.locale("es");
    localLocale.locale(false);
    var stringDate = localLocale.format("LLLL");
    var dateToReturn = stringDate.charAt(0).toUpperCase() + stringDate.slice(1);
    return dateToReturn;
  }

  editRow(row, visibility) {
    this.setState({
      agreggate: false,
      id: row.id
    });
    var envs = row.env;
    var listEnvs = envs.split(",");
    var envDev = false;
    var envQa = false;
    var envPrd = false;
    for (var i = 0; i < listEnvs.length; i++) {
      if (listEnvs[i] === "Producción") {
        envPrd = true;
      } else if (listEnvs[i] === "Calidad") {
        envQa = true;
      } else if (listEnvs[i] === "Desarrollo") {
        envDev = true;
      }
    }
    this.setState(
      {
        agreggate: false,
        showAddForm: true,
        target: row.target,
        affectedServices: row.affectedServices,
        affectedSites: row.affectedSites,
        startDate: moment(row.startDateTime)
          .utc()
          .utcOffset(moment().utcOffset())
          .format("YYYY-MM-DD"),
        startTime: moment(row.startDateTime)
          .utc()
          .utcOffset(moment().utcOffset())
          .format("HH:mm:ss"),
        endDate: moment(row.endDateTime)
          .utc()
          .utcOffset(moment().utcOffset())
          .format("YYYY-MM-DD"),
        endTime: moment(row.endDateTime)
          .utc()
          .utcOffset(moment().utcOffset())
          .format("HH:mm:ss"),
        envDEV: envDev,
        envQA: envQa,
        envPRD: envPrd,
        status: row.status,
        color: row.color,
        otherCon: row.otherCon,
        comment: row.comment,
        visibility: visibility
      },
      () => {
        if (visibility === 1) {
          this.setState({
            showAddForm: true
          });
        } else {
          this.setState({
            showAddForm: false,
            alert: (
              <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Está seguro que quiere eliminar el mantenimiento?"
                customIcon={
                  <div
                    className="swal2-icon swal2-question swal2-animate-question-icon"
                    style={{ display: "flex" }}
                  >
                    <span className="swal2-icon-text">?</span>
                  </div>
                }
                onConfirm={() => this.publishMaintenanceData(this.state)}
                onCancel={() => this.setState({ alert: null })}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No, cancelar"
                btnSize="md"
              />
            )
          });
        }
      }
    );
  }

  sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  stateColor(row) {
    if (row.color === "B") {
      return "bg-info";
    } else if (row.color === "G") {
      return "bg-success";
    } else {
      return "bg-danger";
    }
  }

  cancelMaintenanceForm = () => {
    this.setState({
      showAddForm: false
    });
  };

  addButtonFunction = () => {
    this.setState({
      agreggate: true,
      showAddForm: true,
      target: "",
      affectedServices: "",
      affectedSites: "",
      startDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00:00",
      endDate: moment().format("YYYY-MM-DD"),
      endTime: "00:00:00",
      envDEV: false,
      envQA: false,
      envPRD: true,
      status: "",
      color: "R",
      otherCon: "",
      comment: ""
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    const { showAddForm, loading2, showButtonsEdit, alert } = this.state;
    const { maintenance } = this.props;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name="Información MIS"
          parentName="Manteniemientos programados"
        />
        {loading2 ? (
          <Progress animated max="100" value="100" color="info" />
        ) : (
          <Container className="mt--6" fluid>
            {showAddForm === false ? (
              <Table
                title="Mantenimientos"
                subtitle="Información de los futuros mantenimientos."
                columns={[
                  {
                    dataField: "id",
                    text: "Id",
                    sort: true,
                    hidden: true,
                    searchable: false
                  },
                  {
                    dataField: "target",
                    text: "Objetivos",
                    sort: true,
                    hidden: true,
                    formatter: (cellContent, row) => {
                      return (
                        <div
                          style={{ whiteSpace: "pre-wrap" }}
                          className="d-flex align-items-center"
                        >
                          <span className="completion mr-2">{row.target}</span>
                        </div>
                      );
                    }
                  },
                  {
                    dataField: "affectedServices",
                    text: "Servicios afectados",
                    sort: true,
                    formatter: (cellContent, row) => {
                      return (
                        <div
                          style={{ whiteSpace: "pre-wrap" }}
                          className="d-flex align-items-center"
                        >
                          <span className="completion mr-2">
                            {row.affectedServices}
                          </span>
                        </div>
                      );
                    }
                  },
                  {
                    dataField: "env",
                    text: "Ambiente",
                    sort: true,
                    formatter: (cellContent, row) => {
                      return (
                        <div
                          style={{ whiteSpace: "pre-wrap" }}
                          className="d-flex align-items-center"
                        >
                          <span className="completion mr-2">
                            {row.env.substring(0, row.env.length - 1)}
                          </span>
                        </div>
                      );
                    }
                  },
                  {
                    dataField: "affectedSites",
                    text: "Sitios afectados",
                    hidden: true,
                    sort: true,
                    formatter: (cellContent, row) => {
                      return (
                        <div
                          style={{ whiteSpace: "pre-wrap" }}
                          className="d-flex align-items-center"
                        >
                          <span className="completion mr-2">
                            {row.affectedSites}
                          </span>
                        </div>
                      );
                    }
                  },
                  {
                    dataField: "startDateTime",
                    text: "Tiempo inicialización",
                    sort: true,
                    formatter: (cellContent, row) => {
                      return (
                        <div
                          style={{ whiteSpace: "pre-wrap" }}
                          className="d-flex align-items-center"
                        >
                          <span className="completion mr-2">
                            {this.spanishDate(row.startDateTime) + " horas"}
                          </span>
                        </div>
                      );
                    }
                  },
                  {
                    dataField: "endDateTime",
                    text: "Tiempo finalización",
                    sort: true,
                    formatter: (cellContent, row) => {
                      return (
                        <div
                          style={{ whiteSpace: "pre-wrap" }}
                          className="d-flex align-items-center"
                        >
                          <span className="completion mr-2">
                            {this.spanishDate(row.endDateTime) + " horas"}
                          </span>
                        </div>
                      );
                    }
                  },
                  {
                    dataField: "status",
                    text: "Estado",
                    sort: true,
                    hidden: true,
                    formatter: (cellContent, row) => {
                      return (
                        <div
                          style={{ whiteSpace: "pre-wrap" }}
                          className="d-flex align-items-center"
                        >
                          <Badge color="" className="badge-dot mr-4">
                            <i className={this.stateColor(row)} />
                            <span className="status">{row.status}</span>
                          </Badge>
                        </div>
                      );
                    }
                  },
                  {
                    dataField: "otherCon",
                    text: "Conexión alterna",
                    sort: true,
                    hidden: true
                  },
                  {
                    dataField: "actions",
                    isDummyField: true,
                    text: "ADMIN",
                    hidden: !showButtonsEdit,
                    formatter: (cellContent, row) => {
                      return (
                        <div>
                          <Button
                            onClick={() => this.editRow(row, 0)}
                            className="btn-icon btn-2"
                            color="danger"
                            type="button"
                            size="sm"
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-times"></i>
                            </span>
                          </Button>
                          <Button
                            onClick={() => this.editRow(row, 1)}
                            className="btn-icon btn-2"
                            color="info"
                            type="button"
                            size="sm"
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-edit"></i>
                            </span>
                          </Button>
                        </div>
                      );
                    }
                  }
                ]}
                data={maintenance}
                sizePerPage={3}
                page={3}
                totalSize={3}
                showSearch={true}
                showAddButton={showButtonsEdit}
                buttonAddFunct={this.addButtonFunction}
                expandFeatrue={this.expandRow}
                buttonBack={() => this.props.history.goBack()}
              />
            ) : (
              <Form
                cancel={this.cancelMaintenanceForm}
                savedData={this.publishMaintenanceData}
                infoMaintenance={this.state}
                onChangeForm={this.onChangeValuesMaintenance}
                objectFailValidation={this.state.objectValidation}
              ></Form>
            )}
          </Container>
        )}
      </>
    );
  }
}

Maintenance.defaultProps = {
  maintenance: [],
  teams: []
};

const mapStateToProps = state => ({
  maintenance: getMaintenance(state),
  teams: getTeamsUserAccess()
});

export default withRouter(
  connect(
    mapStateToProps,
    { findMaintenance, insertMaintenance, updateMaintenanceInfo }
  )(Maintenance)
);
