// core antd
import { Drawer, Icon, Upload } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Col } from "reactstrap";

const { Dragger } = Upload;

const UploadDrawerOffer = props => {

  const {
    title,
    options: {
      openUpload,
    },
    onToggle,
    fileList,
    onFileList,
  } = props;

  const uploadProps = {
    name: 'file',
    accept: ".xlsx",
    multiple: false,
    action: `${urlBase}/auth/test`,
    onChange: onFileList,
  };

  return (
    <Drawer
      title={title}
      width={340}
      onClose={onToggle}
      visible={openUpload}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <blockquote className="blockquote text-center">
        <p className="mb-0">
          Seleccione el archivo descargado para el ajuste de la oferta
        </p>
        <footer className="blockquote-footer">
          <cite title="Source Title">Unicamente se tomaran en cuenta las columnas ajustables</cite>
        </footer>
      </blockquote>
      <div className="form-row align-items-center">
        <Col className="m-1" sm="12">
          <Dragger {...uploadProps} fileList={fileList}>
            <p className="ant-uploading-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
            <p className="ant-uploading-hint">
              *Se debe cargar el archivo que descargo con el calculo de la oferta.
            </p>
          </Dragger>
        </Col>
      </div>
    </Drawer>
  );
};

UploadDrawerOffer.propTypes = {
  title: PropTypes.string.isRequired,
};

export default UploadDrawerOffer;
