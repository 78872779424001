// react library
import React, { useState } from 'react';
// reactstrap components
import {
    Button, Col, Row, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Table,
    Pagination, PaginationItem, PaginationLink
} from "reactstrap";

const LogModal = ({
    modalToggel,
    documentId,
    setLogModal,
    info,
}) => {

    //state inicial
    const [state, setState] = useState({
        title: "Nuevo Documento",
        unmountOnClose: true,
        page: 1,
        sizePerPage: 3,
    });

    const { unmountOnClose, title, page, sizePerPage } = state;

    //funcion para cerrar modal
    const toggleLogModal = (() => {

        setLogModal((prevState) => ({
            ...prevState,
            documentId: "",
            modalToggel: !prevState.modalToggel,
        }));

    });

    //state de la columna de las tablas
    const columnsLog = [
        { value: "fk_documentId", name: "Id documento" },
        { value: "statusName", name: "Estado" },
        { value: "Cdate", name: "Fecha" },
        { value: "createdBy", name: "Responsable" },
    ];

    //#region Pagination (no sirve)
    const paginations = info.length > sizePerPage ?
        info.length / sizePerPage > Math.round(info.length / sizePerPage) ?
            Math.round(info.length / sizePerPage) + 1
            : Math.round(info.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        href="#"
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {

        setState((prevState) => ({
            ...prevState,
            [who]: page
        }))
    };

    //#endregion

    return (
        <Modal size="md" isOpen={modalToggel} unmountOnClose={unmountOnClose} toggle={toggleLogModal}>
            <ModalHeader>
                Log de Status
            </ModalHeader>

            <ModalBody className='mb-lg-0'>
                <Table
                    className="align-items-center table-flush"
                    responsive
                    striped={true}
                    hover={true}
                >
                    <thead className="thead-light">
                        <tr>
                            {
                                columnsLog.map((row, key) => {
                                    return (
                                        <th key={key} className="justify-content-md-center">
                                            {row.name}
                                        </th>
                                    )
                                })
                            }

                        </tr>
                    </thead>
                    <tbody className="list">
                        {
                            info.length ?
                                info.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                                    <tr key={key}>

                                        {
                                            columnsLog.map((col, key) => (
                                                col.value === "statusName" ?
                                                    <td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                        <button type="button" className={row["fk_status"] === 'CD' ? "btn btn-round btn-wd btn-sm btn-success" : row["fk_status"] === 'NW' ? "btn btn-round btn-wd btn-sm btn-warning" : row["fk_status"] === 'CA' || row["fk_status"] === 'RJ' ? "btn btn-round btn-wd btn-sm btn-danger" : "btn btn-round btn-wd btn-sm btn-info"}>
                                                            <span className="btn-label">
                                                                <i className="fa fa-warning"></i>
                                                            </span>
                                                            {row["statusName"]}
                                                        </button>
                                                    </td>
                                                    :
                                                    <td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                        {
                                                            row[col.value]
                                                        }
                                                    </td>

                                            ))
                                        }
                                    </tr>
                                ))
                                :
                                <>
                                    <tr>
                                        <td className="justify-content-md-center">
                                            Sin resultados encontrados
                                        </td>
                                    </tr>
                                </>
                        }
                    </tbody>
                </Table>

                <nav aria-label="...">
                    <Row className="align-items-center">
                        <Col xs='12' md='12' className="p-1">
                            <Pagination
                                className="pagination justify-content-end mb-0"
                                listClassName="justify-content-end mb-0"
                            >
                                <PaginationItem className={page === 1 ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                        tabIndex="-1"
                                        href="#"
                                    >
                                        <i className="fas fa-angle-left" />
                                        <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                </PaginationItem>
                                {
                                    renderPaginations()
                                }
                                <PaginationItem className={page === paginations ? "disabled" : ""}>
                                    <PaginationLink
                                        href="#"
                                        onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                    >
                                        <i className="fas fa-angle-right" />
                                        <span className="sr-only">Next</span>
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </Col>
                    </Row>
                </nav>
            </ModalBody>

            <ModalFooter>
                <Button
                    color="danger"
                    type="button"
                    onClick={(e) => toggleLogModal()}
                >
                    Cerrar
                </Button>
            </ModalFooter>

        </Modal>


    );

};

export default LogModal;