import React from "react";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Row,
  Button,
  ButtonToolbar
} from "reactstrap";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

const DateRanges = props => {
  const {
    title,
    subtitle,
    startDate,
    endDate,
    reactDatetimeChange,
    classNameReactDatetimeDays,
    haveButtons,
    handleOnCLick,
    clearSearch,
    buttonSwitchFunc
  } = props;

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            {buttonSwitchFunc && (
              <Col>
                <Button
                  className="btn-icon"
                  color="info"
                  size="sm"
                  type="button"
                  style={{ float: "right" }}
                  onClick={buttonSwitchFunc}
                >
                  <span className="btn-inner--icon mr-2">
                    <i className="fas fa-chart-bar"></i>
                  </span>
                  <span className="btn-inner--text">
                    Ver balance de horas por usuarios
                  </span>
                </Button>
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="input-daterange datepicker align-items-center">
              <Col xs={12} sm={6}>
                <label className="form-control-label">Fecha de Inicio</label>
                <FormGroup>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Elija una fecha"
                    }}
                    value={startDate}
                    timeFormat={false}
                    onChange={e => reactDatetimeChange("startDate", e)}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      classes += classNameReactDatetimeDays(currentDate);
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <label className="form-control-label">Fecha de Fin</label>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Elija una fecha"
                    }}
                    value={endDate}
                    timeFormat={false}
                    onChange={e => reactDatetimeChange("endDate", e)}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      classes += classNameReactDatetimeDays(currentDate);
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={4}>
                {haveButtons && (
                  <div>
                    <ButtonToolbar>
                      <Button
                        className="btn-icon"
                        color="info"
                        size="sm"
                        type="button"
                        onClick={handleOnCLick}
                      >
                        <span className="btn-inner--icon mr-2">
                          <i className="fas fa-search" />
                        </span>
                        <span className="btn-inner--text">Buscar</span>
                      </Button>
                      <Button
                        className="btn-icon"
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={clearSearch}
                      >
                        <span className="btn-inner--icon mr-">
                          <i className="fas fa-trash" />
                        </span>
                        <span className="btn-inner--text">Limpiar</span>
                      </Button>
                    </ButtonToolbar>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

DateRanges.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  reactDatetimeChange: PropTypes.func.isRequired,
  classNameReactDatetimeDays: PropTypes.func.isRequired,
  haveButtons: PropTypes.bool,
  handleOnCLick: PropTypes.func,
  clearSearch: PropTypes.func,
  buttonSwitchFunc: PropTypes.func
};

export default DateRanges;
