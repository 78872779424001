/* eslint-disable eqeqeq */
import * as constants from "constants/index.jsx";
import * as jwt from 'jsonwebtoken';
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";

// labels chart
const labels = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Colors charts
const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529"
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340"
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent"
};

// options charts Bar
const optionsBarLegend = {
  legend: {
    position: "top",
    display: true,
    // fullWidth: true,
    labels: {
      usePointStyle: false
    }
  },
  tooltips: {
    mode: "index",
    intersect: false
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: false
      }
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          }
        }
      }
    ]
  }
};

export const getMyTargetsLetters = state => {
  if (Object.keys(state.myTargetsLetters).length) {
    const { myLetters } = state.myTargetsLetters;
    return myLetters.map((row) => {
      row.targets = row.targets.map((element) => {
        element.type = element.type === "01" ? `${element.type}-Facturación`
          : element.type === "02" ? `${element.type}-Compras`
            : element.type === "03" ? `${element.type}-Bolsa`
              : element.type === "04" ? `${element.type}-Certificación`
                : element.type === "05" ? `${element.type}-Otros (YTD)`
                  : element.type === "06" ? `${element.type}-Otros (Mensual)`
                    : element.type === "07" ? `${element.type}-E.P.M`
                      : element.type === "08" ? `${element.type}-New Signing`
                        : element.type === "09" ? `${element.type}-Expand` : '';
        return element;
      });
      row.requestType = row.requestType === "01" ? `${row.requestType}-Carta de Objetivos de acuerdo al plan de compensación`
        : row.requestType === "02" ? `${row.requestType}-Cambios en objetivos antes del 30 de setiembre`
          : row.requestType === "03" ? `${row.requestType}`
            : row.requestType === "04" ? `${row.requestType}-Cambios en objetivos después 30 Setiembre o de cuotas + 25%`
              : row.requestType === "05" ? `${row.requestType}-Entrega de Resultados`
                : row.requestType === "06" ? `${row.requestType}-Carta de Objetivos diferente al plan de compensación`
                  : row.requestType === "07" ? `${row.requestType}-Carta de Objetivos no indicados en el Plan`
                    : row.requestType === "08" ? `${row.requestType}-Cambios de cuotas de EPM` : '';
      // row.startLetter = moment(row.startLetter)
      //   .utc()
      //   .utcOffset(moment().utcOffset())
      //   .format("L");
      // row.endLetter = moment(row.endLetter)
      //   .utc()
      //   .utcOffset(moment().utcOffset())
      //   .format("L");
      return row;
    });
  }
  return [];
};

export const getTargetsLetters = state => {
  if (Object.keys(state.targetsLetters).length) {
    const { targetsLetters } = state.targetsLetters;
    return targetsLetters.map((row) => {
      row.targets = row.targets.map((element) => {
        element.type = element.type === "01" ? `${element.type}-Facturación`
          : element.type === "02" ? `${element.type}-Compras`
            : element.type === "03" ? `${element.type}-Bolsa`
              : element.type === "04" ? `${element.type}-Certificación`
                : element.type === "05" ? `${element.type}-Otros (YTD)`
                  : element.type === "06" ? `${element.type}-Otros (Mensual)`
                    : element.type === "07" ? `${element.type}-E.P.M`
                      : element.type === "08" ? `${element.type}-New Signing`
                        : element.type === "09" ? `${element.type}-Expand` : '';
        return element;
      });
      row.requestType = row.requestType == "01" ? `${row.requestType}-Carta de Objetivos de acuerdo al plan de compensación`
        : row.requestType == "02" ? `${row.requestType}-Cambios en objetivos antes del 30 de setiembre`
          : row.requestType == "03" ? `${row.requestType}`
            : row.requestType == "04" ? `${row.requestType}-Cambios en objetivos después 30 Setiembre o de cuotas + 25%`
              : row.requestType == "05" ? `${row.requestType}-Entrega de Resultados`
                : row.requestType == "06" ? `${row.requestType}-Carta de Objetivos diferente al plan de compensación`
                  : row.requestType == "07" ? `${row.requestType}-Carta de Objetivos no indicados en el Plan`
                    : row.requestType == "08" ? `${row.requestType}-Cambios de cuotas de EPM` : row.requestType;
      row.startLetter = moment(row.startLetter)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("L");
      row.endLetter = moment(row.endLetter)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("L");
      return row;
    });
  }
  return [];
};

export const getFiltersOptions = state => {
  if (Object.keys(state.targetsLetters).length) {
    const { targetsLetters } = state.targetsLetters;
    let country = targetsLetters.map((row, key) => {
      return {
        value: key + 1,
        label: `${row.organizationalUnit.split("-")[0]}/${row.persSubArea}`
      }
    });
    country = country.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => country[e]).map(e => country[e]);
    country = country.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    let status = targetsLetters.map((row, key) => {
      return {
        value: key + 1,
        label: row.flowStatus
      }
    });
    status = status.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => status[e]).map(e => status[e]);
    status = status.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    let requestNumber = targetsLetters.map((row, key) => {
      return {
        value: key + 1,
        label: row.requestNumber
      }
    });
    requestNumber = requestNumber.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => requestNumber[e]).map(e => requestNumber[e]);
    requestNumber = requestNumber.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    let flow = targetsLetters.map((row, key) => {
      return {
        value: key + 1,
        label: row.flow
      }
    });
    flow = flow.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => flow[e]).map(e => flow[e]);
    flow = flow.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return {
      country: [
        { value: 0, label: "Todos" },
        ...country
      ],
      flow: [
        { value: 0, label: "Todos" },
        ...flow
      ],
      status: [
        { value: 0, label: "Todos" },
        ...status
      ],
      requestNumber: [
        { value: 0, label: "Todos" },
        ...requestNumber
      ],
    };
  }
  return {
    country: [],
    flow: [],
    status: [],
    requestNumber: [],
  };
};

export const getTargetLetterUser = state => {
  if (Object.keys(state.targetLetterByUser).length) {
    const { targetsLetterUser } = state.targetLetterByUser;
    targetsLetterUser.requestType = targetsLetterUser.requestType == "01" ? `${targetsLetterUser.requestType}-Carta de Objetivos de acuerdo al plan de compensación`
      : targetsLetterUser.requestType == "02" ? `${targetsLetterUser.requestType}-Cambios en objetivos antes del 30 de setiembre`
        : targetsLetterUser.requestType == "03" ? `${targetsLetterUser.requestType}`
          : targetsLetterUser.requestType == "04" ? `${targetsLetterUser.requestType}-Cambios en objetivos después 30 Setiembre o de cuotas + 25%`
            : targetsLetterUser.requestType == "05" ? `${targetsLetterUser.requestType}-Entrega de Resultados`
              : targetsLetterUser.requestType == "06" ? `${targetsLetterUser.requestType}-Carta de Objetivos diferente al plan de compensación`
                : targetsLetterUser.requestType == "07" ? `${targetsLetterUser.requestType}-Carta de Objetivos no indicados en el Plan`
                  : targetsLetterUser.requestType == "08" ? `${targetsLetterUser.requestType}-Cambios de cuotas de EPM` : targetsLetterUser.requestType;
    targetsLetterUser.targets = targetsLetterUser.targets.map((element) => {
      element.type = element.type === "01" ? `${element.type}-Facturación`
        : element.type === "02" ? `${element.type}-Compras`
          : element.type === "03" ? `${element.type}-Bolsa`
            : element.type === "04" ? `${element.type}-Certificación`
              : element.type === "05" ? `${element.type}-Otros (YTD)`
                : element.type === "06" ? `${element.type}-Otros (Mensual)`
                  : element.type === "07" ? `${element.type}-E.P.M`
                    : element.type === "08" ? `${element.type}-New Signing`
                      : element.type === "09" ? `${element.type}-Expand` : '';
      return element;
    });
    return targetsLetterUser;
  }
  return {};
};

export const getTargetsLetterHC = state => {
  if (Object.keys(state.targetsLetterHC).length) {
    const { targetsLetter } = state.targetsLetterHC;
    return targetsLetter.map((row) => {
      row.targets = row.targets.map((element) => {
        element.type = element.type === "01" ? `${element.type}-Facturación`
          : element.type === "02" ? `${element.type}-Compras`
            : element.type === "03" ? `${element.type}-Bolsa`
              : element.type === "04" ? `${element.type}-Certificación`
                : element.type === "05" ? `${element.type}-Otros (YTD)`
                  : element.type === "06" ? `${element.type}-Otros (Mensual)`
                    : element.type === "07" ? `${element.type}-E.P.M`
                      : element.type === "08" ? `${element.type}-New Signing`
                        : element.type === "09" ? `${element.type}-Expand` : '';
        return element;
      });
      row.requestType = row.requestType == "01" ? `${row.requestType}-Carta de Objetivos de acuerdo al plan de compensación`
        : row.requestType == "02" ? `${row.requestType}-Cambios en objetivos antes del 30 de setiembre`
          : row.requestType == "03" ? `${row.requestType}`
            : row.requestType == "04" ? `${row.requestType}-Cambios en objetivos después 30 Setiembre o de cuotas + 25%`
              : row.requestType == "05" ? `${row.requestType}-Entrega de Resultados`
                : row.requestType == "06" ? `${row.requestType}-Carta de Objetivos diferente al plan de compensación`
                  : row.requestType == "07" ? `${row.requestType}-Carta de Objetivos no indicados en el Plan`
                    : row.requestType == "08" ? `${row.requestType}-Cambios de cuotas de EPM` : row.requestType;
      return row;
    });
  }
  return [];
};

export const getTargetsLetterHeadShip = state => {
  if (Object.keys(state.targetsLetterHeadShip).length) {
    const { targetsLetter } = state.targetsLetterHeadShip;
    return targetsLetter.map((row) => {
      row.targets = row.targets.map((element) => {
        element.type = element.type === "01" ? `${element.type}-Facturación`
          : element.type === "02" ? `${element.type}-Compras`
            : element.type === "03" ? `${element.type}-Bolsa`
              : element.type === "04" ? `${element.type}-Certificación`
                : element.type === "05" ? `${element.type}-Otros (YTD)`
                  : element.type === "06" ? `${element.type}-Otros (Mensual)`
                    : element.type === "07" ? `${element.type}-E.P.M`
                      : element.type === "08" ? `${element.type}-New Signing`
                        : element.type === "09" ? `${element.type}-Expand` : '';
        return element;
      });
      row.requestType = row.requestType == "01" ? `${row.requestType}-Carta de Objetivos de acuerdo al plan de compensación`
        : row.requestType == "02" ? `${row.requestType}-Cambios en objetivos antes del 30 de setiembre`
          : row.requestType == "03" ? `${row.requestType}`
            : row.requestType == "04" ? `${row.requestType}-Cambios en objetivos después 30 Setiembre o de cuotas + 25%`
              : row.requestType == "05" ? `${row.requestType}-Entrega de Resultados`
                : row.requestType == "06" ? `${row.requestType}-Carta de Objetivos diferente al plan de compensación`
                  : row.requestType == "07" ? `${row.requestType}-Carta de Objetivos no indicados en el Plan`
                    : row.requestType == "08" ? `${row.requestType}-Cambios de cuotas de EPM` : row.requestType;
      return row;
    });
  }
  return [];
};

export const getTargetsLetterHCRM = state => {
  if (Object.keys(state.targetsLetterHCRM).length) {
    const { targetsLetter } = state.targetsLetterHCRM;
    return targetsLetter.map((row) => {
      row.targets = row.targets.map((element) => {
        element.type = element.type === "01" ? `${element.type}-Facturación`
          : element.type === "02" ? `${element.type}-Compras`
            : element.type === "03" ? `${element.type}-Bolsa`
              : element.type === "04" ? `${element.type}-Certificación`
                : element.type === "05" ? `${element.type}-Otros (YTD)`
                  : element.type === "06" ? `${element.type}-Otros (Mensual)`
                    : element.type === "07" ? `${element.type}-E.P.M`
                      : element.type === "08" ? `${element.type}-New Signing`
                        : element.type === "09" ? `${element.type}-Expand` : '';
        return element;
      });
      row.requestType = row.requestType == "01" ? `${row.requestType}-Carta de Objetivos de acuerdo al plan de compensación`
        : row.requestType == "02" ? `${row.requestType}-Cambios en objetivos antes del 30 de setiembre`
          : row.requestType == "03" ? `${row.requestType}`
            : row.requestType == "04" ? `${row.requestType}-Cambios en objetivos después 30 Setiembre o de cuotas + 25%`
              : row.requestType == "05" ? `${row.requestType}-Entrega de Resultados`
                : row.requestType == "06" ? `${row.requestType}-Carta de Objetivos diferente al plan de compensación`
                  : row.requestType == "07" ? `${row.requestType}-Carta de Objetivos no indicados en el Plan`
                    : row.requestType == "08" ? `${row.requestType}-Cambios de cuotas de EPM` : row.requestType;
      return row;
    });
  }
  return [];
};

export const getTransactionsByFlow = state => {
  if (Object.keys(state.dashboardTargetLetter).length) {
    const { allData } = state.dashboardTargetLetter;
    const [[transactionsFlow]] = allData;
    return transactionsFlow;
  }
  return {
    totalRequest: 0,
    totalRequestMonth: 0,
    totalManagers: 0,
    totalManagersMonth: 0,
    totalCollaborator: 0,
    totalCollaboratorMonth: 0,
    totalPayrrols: 0,
    totalPayrrolsMonth: 0,
  };
};

export const getTransactionsByStatus = state => {
  if (Object.keys(state.dashboardTargetLetter).length) {
    const { allData } = state.dashboardTargetLetter;
    const [, [transactionStatus]] = allData;
    return transactionStatus;
  }
  return {
    awaitingTotal: 0,
    awaitingTotalMonth: 0,
    rejectedTotal: 0,
    rejectedTotalMonth: 0,
    approvedTotal: 0,
    approvedTotalMonth: 0,
    appliedTotal: 0,
    appliedTotalMonth: 0,
  };
};

export const getAllDataStatusByYear = state => {
  const dataAwaiting = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataRejected = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataApproved = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataApplied = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  if (Object.keys(state.dashboardTargetLetter).length) {
    const { graphStatus } = state.dashboardTargetLetter;
    const [awaiting, approved, rejected, applied] = graphStatus;
    if (awaiting.length) {
      for (const row of awaiting) {
        dataAwaiting[row.month - 1] = row.awaitingTotal;
      }
    }
    if (rejected.length) {
      for (const row of rejected) {
        dataRejected[row.month - 1] = row.rejectedTotal;
      }
    }
    if (approved.length) {
      for (const row of approved) {
        dataApproved[row.month - 1] = row.approvedTotal;
      }
    }
    if (applied.length) {
      for (const row of applied) {
        dataApplied[row.month - 1] = row.appliedTotal;
      }
    }
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Pendientes",
            data: dataAwaiting,
            backgroundColor: colors.theme["warning"]
          },
          {
            label: "Rechazadas",
            data: dataRejected,
            backgroundColor: colors.theme["danger"]
          },
          {
            label: "Aprobadas",
            data: dataApproved,
            backgroundColor: colors.theme["success"]
          },
          {
            label: "Aplicadas",
            data: dataApplied,
            backgroundColor: colors.theme["default"]
          }
        ]
      },
      options: optionsBarLegend,
    };
  } else {
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Pendientes",
            data: dataAwaiting,
            backgroundColor: colors.theme["warning"]
          },
          {
            label: "Rechazadas",
            data: dataRejected,
            backgroundColor: colors.theme["danger"]
          },
          {
            label: "Aprobadas",
            data: dataApproved,
            backgroundColor: colors.theme["success"]
          },
          {
            label: "Aplicadas",
            data: dataApplied,
            backgroundColor: colors.theme["default"]
          }
        ]
      },
      options: optionsBarLegend,
    };
  }
};

export const getAllDataFlowsByYear = state => {
  const dataRequests = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataManagers = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataCollaborators = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataPayrrols = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  if (Object.keys(state.dashboardTargetLetter).length) {
    const { graphFlows } = state.dashboardTargetLetter;
    const [managers, collaborators, payrrols, requests] = graphFlows;
    if (requests.length) {
      for (const row of requests) {
        dataRequests[row.month - 1] = row.totalRequest;
      }
    }
    if (managers.length) {
      for (const row of managers) {
        dataManagers[row.month - 1] = row.totalManagers;
      }
    }
    if (collaborators.length) {
      for (const row of collaborators) {
        dataCollaborators[row.month - 1] = row.totalCollaborator;
      }
    }
    if (payrrols.length) {
      for (const row of payrrols) {
        dataPayrrols[row.month - 1] = row.totalPayrrols;
      }
    }
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Total Solicitudes",
            data: dataRequests,
            backgroundColor: colors.theme["warning"]
          },
          {
            label: "Pendiente HC Manager",
            data: dataManagers,
            backgroundColor: colors.theme["danger"]
          },
          {
            label: "Pendiente Colaborador",
            data: dataCollaborators,
            backgroundColor: colors.theme["success"]
          },
          {
            label: "Pendiente HC Payrrol",
            data: dataPayrrols,
            backgroundColor: colors.theme["default"]
          }
        ]
      },
      options: optionsBarLegend,
    };
  } else {
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Total Solicitudes",
            data: dataRequests,
            backgroundColor: colors.theme["warning"]
          },
          {
            label: "Pendiente HC Manager",
            data: dataManagers,
            backgroundColor: colors.theme["danger"]
          },
          {
            label: "Pendiente Colaborador",
            data: dataCollaborators,
            backgroundColor: colors.theme["success"]
          },
          {
            label: "Pendiente HC Payrrol",
            data: dataPayrrols,
            backgroundColor: colors.theme["default"]
          }
        ]
      },
      options: optionsBarLegend,
    };
  }
};

export const getAllCountriesOfTargetsLetters = state => {
  if (Object.keys(state.dashboardTargetLetter).length) {
    const { countries } = state.dashboardTargetLetter;
    const countriesList = [
      { value: 0, label: "Todos" },
    ];
    countries.map((row, key) => {
      countriesList.push({ value: key + 1, label: row.country })
      return row;
    });
    return countriesList;
  }
  return [
    { value: 0, label: "Todos" },
  ];
};

export const getAllYearsOfTargetsLetters = state => {
  if (Object.keys(state.dashboardTargetLetter).length) {
    const { years } = state.dashboardTargetLetter;
    const yearsList = [];
    years.map((row, key) => {
      yearsList.push({ value: key + 1, label: row.year })
      return row;
    });
    return yearsList;
  }
  return [
    { value: 1, label: moment().year() },
  ];
};

export const getUserIsHCPayrrol = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      const { teams } = decoded;
      return teams.some((row) => row === "Human Capital Payrrol");
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getUserIsHCManager = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      const { teams } = decoded;
      return teams.some((row) => row === "Human Capital Manager");
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getTargetsLetterGeneralManager = state => {
  if (Object.keys(state.targetsLetterGeneralManager).length) {
    const { targetsLetter } = state.targetsLetterGeneralManager;
    return targetsLetter.map((row) => {
      row.targets = row.targets.map((element) => {
        element.type = element.type === "01" ? `${element.type}-Facturación`
          : element.type === "02" ? `${element.type}-Compras`
            : element.type === "03" ? `${element.type}-Bolsa`
              : element.type === "04" ? `${element.type}-Certificación`
                : element.type === "05" ? `${element.type}-Otros (YTD)`
                  : element.type === "06" ? `${element.type}-Otros (Mensual)`
                    : element.type === "07" ? `${element.type}-E.P.M`
                      : element.type === "08" ? `${element.type}-New Signing`
                        : element.type === "09" ? `${element.type}-Expand` : '';
        return element;
      });
      row.requestType = row.requestType == "01" ? `${row.requestType}-Carta de Objetivos de acuerdo al plan de compensación`
        : row.requestType == "02" ? `${row.requestType}-Cambios en objetivos antes del 30 de setiembre`
          : row.requestType == "03" ? `${row.requestType}`
            : row.requestType == "04" ? `${row.requestType}-Cambios en objetivos después 30 Setiembre o de cuotas + 25%`
              : row.requestType == "05" ? `${row.requestType}-Entrega de Resultados`
                : row.requestType == "06" ? `${row.requestType}-Carta de Objetivos diferente al plan de compensación`
                  : row.requestType == "07" ? `${row.requestType}-Carta de Objetivos no indicados en el Plan`
                    : row.requestType == "08" ? `${row.requestType}-Cambios de cuotas de EPM` : row.requestType;
      return row;
    });
  }
  return [];
};

export const getTargetsLetterManagementServicesDirector = state => {
  if (Object.keys(state.targetsLetterManagementServicesDirector).length) {
    const { targetsLetter } = state.targetsLetterManagementServicesDirector;
    return targetsLetter.map((row) => {
      row.targets = row.targets.map((element) => {
        element.type = element.type === "01" ? `${element.type}-Facturación`
          : element.type === "02" ? `${element.type}-Compras`
            : element.type === "03" ? `${element.type}-Bolsa`
              : element.type === "04" ? `${element.type}-Certificación`
                : element.type === "05" ? `${element.type}-Otros (YTD)`
                  : element.type === "06" ? `${element.type}-Otros (Mensual)`
                    : element.type === "07" ? `${element.type}-E.P.M`
                      : element.type === "08" ? `${element.type}-New Signing`
                        : element.type === "09" ? `${element.type}-Expand` : '';
        return element;
      });
      row.requestType = row.requestType == "01" ? `${row.requestType}-Carta de Objetivos de acuerdo al plan de compensación`
        : row.requestType == "02" ? `${row.requestType}-Cambios en objetivos antes del 30 de setiembre`
          : row.requestType == "03" ? `${row.requestType}`
            : row.requestType == "04" ? `${row.requestType}-Cambios en objetivos después 30 Setiembre o de cuotas + 25%`
              : row.requestType == "05" ? `${row.requestType}-Entrega de Resultados`
                : row.requestType == "06" ? `${row.requestType}-Carta de Objetivos diferente al plan de compensación`
                  : row.requestType == "07" ? `${row.requestType}-Carta de Objetivos no indicados en el Plan`
                    : row.requestType == "08" ? `${row.requestType}-Cambios de cuotas de EPM` : row.requestType;
      return row;
    });
  }
  return [];
};