// core actions
import { findAllDataDashoard, findAllSignInDashoard, findAllTransactionsDashoard } from "actions/performance.jsx";
// core antd
import { BackTop, Spin } from "antd";
// javascipt plugin for creating charts
import Chart from "chart.js";
// core components
import AlternativeHeader from "components/Shared/Header/AdminHeader.jsx";
import Charts from "components/UsabilityRecord/Charts.jsx";
import StatusCard from "components/UsabilityRecord/StatusCard.jsx";
import TransactionsByZone from "components/UsabilityRecord/TransactionsByZone.jsx";
import VisitCard from "components/UsabilityRecord/VisitCard.jsx";
// javascript library for dates
import moment from 'moment';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// core reactstrap
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getDataMaps, getDataSignInsUsers, getDataVisitedModules, getDataWitget, getGraphsDashboard, getMapData, getSignInGraphs, getTransactionsGraphs } from "selectors/performance.jsx";
// options for the charts
import {
  // global options for the charts
  chartOptions,
  // function that adds the global options to our charts
  parseOptions
} from "variables/charts.jsx";

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      type: 'month',
      date: moment().year(),
      dateSelected: moment().format(),
    };
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  };

  componentDidMount() {
    this.onGetAllData();
    // document.body.classList.add("bg-secundary");
  };

  componentWillUnmount() {
    // document.body.classList.remove("bg-secundary");
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  onGetAllData = async () => {
    const { dateSelected } = this.state;
    const year = moment(dateSelected).year();
    const month = moment(dateSelected).month() + 1
    this.setState((state) => ({
      loading: !state.loading,
      message: `Cargando la información del dashboard`
    }));
    await this.props.findAllDataDashoard().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
    });
    await this.props.findAllSignInDashoard(
      year,
      month
    ).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
    });
    await this.props.findAllTransactionsDashoard(
      year,
      month
    ).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
    });
    this.setState((state) => ({
      loading: !state.loading,
      message: null
    }));
  };

  renderDataSignGraphs = data => {
    const { type } = this.state;
    if (type === "month") {
      return data.months;
    } else if (type === "day") {
      return data.days;
    }
  };

  handleOnChangeTypeGraph = type => {
    this.setState((state) => ({
      type,
      date: type === "day" ? moment(state.dateSelected).format("MMMM").toUpperCase() : moment(state.dateSelected).year()
    }));
  };

  handleOnChangeValuesGraph = async (event, type) => {
    let { dateSelected } = this.state;
    if (type === 'day') {
      dateSelected = event === "next" ? moment(dateSelected).add(1, 'M').format() : moment(dateSelected).subtract(1, 'months').format();
    } else {
      dateSelected = event === "next" ? moment(dateSelected).add(1, 'Y').format() : moment(dateSelected).subtract(1, 'years').format();
    }
    const year = moment(dateSelected).year();
    const month = moment(dateSelected).month() + 1
    await this.props.findAllSignInDashoard(
      year,
      month
    ).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
    });
    await this.props.findAllTransactionsDashoard(
      year,
      month
    ).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
    });
    this.setState({
      type,
      date: type === "day" ? moment(dateSelected).format("MMMM").toUpperCase() : moment(dateSelected).year(),
      dateSelected
    });
  };

  render() {

    const { loading, message, type, date, dateSelected } = this.state;

    const {
      name,
      parentName,
      options,
      colsVisit,
      colsSignIns,
      rowsVisit,
      rowsSignIns,
      witget,
      dataMaps,
      mapData,
      graphsDashboard,
    } = this.props;

    return (
      <>
        <BackTop />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AlternativeHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message}>
            <Row>
              <Col sm='12' md='4'>
                <StatusCard
                  title="Inicios de Sesiones"
                  total={witget.amountLogins}
                  totalMonth={witget.amountLoginsMonth}
                  color='bg-gradient-red'
                  icon='fas fa-sign-out-alt'
                />
              </Col>
              <Col sm='12' md='4'>
                <StatusCard
                  title="Transacciones Ejecutadas"
                  total={witget.amountTransactions}
                  totalMonth={witget.amountTransactionsMonth}
                  color='bg-gradient-green'
                  icon='fas fa-chart-line'
                />
              </Col>
              <Col sm='12' md='4'>
                <StatusCard
                  parent
                  title="Módulo más ejecutado"
                  total={witget.module}
                  totalMonth={witget.amountModule}
                  color='bg-gradient-orange'
                  icon='ni ni-folder-17'
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Charts
                  line
                  title="Tráfico"
                  type={type}
                  subtitle={
                    `Inicios de Sesión y Transacciones
                    ${type === "day" ?
                      moment(dateSelected).year()
                      : ''
                    }`
                  }
                  date={date}
                  data={this.renderDataSignGraphs(graphsDashboard)}
                  options={options}
                  onChangeType={this.handleOnChangeTypeGraph}
                  onChangeValue={this.handleOnChangeValuesGraph}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TransactionsByZone
                  title="Tráfico"
                  subtitle="Marcación de las zonas por transacciones"
                  markers={dataMaps}
                  mapData={mapData}
                />
              </Col>
            </Row>
            <Row>
              <Col sm='12' md='6'>
                <VisitCard
                  title='Modulos Visitados'
                  cols={colsVisit}
                  rows={rowsVisit}
                  length={rowsVisit.length}
                  sizePerPage={25}
                  paginations={
                    rowsVisit.length > 25 ?
                      rowsVisit.length / 25 > Math.round(rowsVisit.length / 25) ?
                        Math.round(rowsVisit.length / 25) + 1
                        : Math.round(rowsVisit.length / 25)
                      : 1
                  }
                />
              </Col>
              <Col sm='12' md='6'>
                <VisitCard
                  title='Registro de Usuarios'
                  cols={colsSignIns}
                  rows={rowsSignIns}
                  length={rowsSignIns.length}
                  sizePerPage={25}
                  paginations={
                    rowsSignIns.length > 25 ?
                      rowsSignIns.length / 25 > Math.round(rowsSignIns.length / 25) ?
                        Math.round(rowsSignIns.length / 25) + 1
                        : Math.round(rowsSignIns.length / 25)
                      : 1
                  }
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

Dashboard.defaultProps = {
  name: 'Registro de Usabilidad',
  parentName: 'Smart & Simple',
  colsVisit: [
    { key: 'module', name: 'Módulo' },
    { key: 'amount', name: 'Transacciones' },
    { key: 'users', name: 'Usuarios' },
  ],
  colsSignIns: [
    { key: 'userID', name: 'ID Colaborador' },
    { key: 'username', name: 'Nombre de Usuario' },
    { key: 'amount', name: 'Inicios de Sesión' },
  ],
  rowsVisit: [],
  rowsSignIns: [],
  witget: {},
  dataMaps: [],
  mapData: {},
  signGraphs: {},
  transactionsGraphs: {},
  graphsDashboard: {},
  options: {
    responsive: true,
    hoverMode: 'index',
    stacked: false,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      position: "top",
      display: true,
      fullWidth: true,
      labels: {
        usePointStyle: false
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [
        {
          stacked: false
        }
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            callback: function (value) {
              if (!(value % 1)) {
                return value;
              }
            }
          }
        }
      ]
    }
  },
}

Dashboard.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  colsVisit: PropTypes.array.isRequired,
  rowsVisit: PropTypes.array.isRequired,
  colsSignIns: PropTypes.array.isRequired,
  rowsSignIns: PropTypes.array.isRequired,
  witget: PropTypes.object.isRequired,
  dataMaps: PropTypes.array.isRequired,
  mapData: PropTypes.object.isRequired,
  signGraphs: PropTypes.object.isRequired,
  transactionsGraphs: PropTypes.object.isRequired,
  graphsDashboard: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  rowsVisit: getDataVisitedModules(state),
  rowsSignIns: getDataSignInsUsers(state),
  witget: getDataWitget(state),
  dataMaps: getDataMaps(state),
  mapData: getMapData(state),
  signGraphs: getSignInGraphs(state),
  transactionsGraphs: getTransactionsGraphs(state),
  graphsDashboard: getGraphsDashboard(state),
});

export default withRouter(connect(mapStateToProps, {
  findAllDataDashoard,
  findAllSignInDashoard,
  findAllTransactionsDashoard,
})(Dashboard));
