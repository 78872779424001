/*!

=========================================================
*Databot DrawerPlannerHelp
=========================================================

*Es un tipo "modal" para desplegar más información de 
como hacer un planner.

=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/

//Librerías de React
import React from 'react';

//Componente para desplegar el "modal"
import { Drawer } from 'antd';

//Componentes de la librería reactstrap
import { Card, CardBody, } from "reactstrap";


export const DrawerPlannerHelp = ({ 
    //Props
    showPlannerHelp, 
    setShowPlannerHelp, 
}) => {

    return (
        <>
            <Drawer
                title="Instrucciones para crear un planner"
                width={380}
                onClose={() => setShowPlannerHelp(false)}
                visible={showPlannerHelp}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1600 }}

            >


                <Card className="card-profile bg-secondary ">

                    <CardBody
                        className=" px-5"
                        style={{ backgroundColor: "#e8f5fa" }}
                    >
                        <div className="mb-4">
                            <div className="justify-content-center row">
                                <h2>
                                    Tipos de planner</h2>
                            </div>

                            <p className="mb-3"><strong>-Minutos:</strong> Establece que el robot se va a ejecutar cada X minutos. Ejemplo: Cada 5 minutos partiendo la hora en la que arranque.</p>

                            <p className="mb-3"><strong>-Horas:</strong> Establece las horas del día en las cuales se desea que se ejecute el robot. Por ejemplo: 7:00, 8:00...</p>

                            <p className="mb-3"><strong>-Semanas:</strong> Establece los días de la semana en las cuales se desea que se ejecute el robot, además se debe establecer las horas de cada día de ejecución. Por ejemplo: Lunes, Martes. 7:00, 8:00.</p>

                            <p className="mb-3"><strong>-Mes:</strong> Establece los días del mes en las cuales se desea que se ejecute el robot, además se debe establecer las horas de cada día de ejecución. Por ejemplo: 1, 2. 7:00, 8:00.</p>

                            <p className="mb-3"><strong>-Waiting:</strong> En caso que se establezca el planner a las 7:00 y a esa hora está ejecutando otro robot, el waiting establece cuantos minutos puede esperar el planner a que el bot finalice el otro proceso. Ejemplo: Planner a las 7:00 p.m., waiting de 10 minutos, el proceso se puede ejecutar-esperar en el lapso de 7:00 a 7:10 mientras termina.</p>


                        </div>
                    </CardBody>
                </Card>

            </Drawer>
        </>
    )
}

