// core actions
import { deactivatedUserWithAccess, findUsersWithAccess, createUserAccess } from "actions/digitalSignature.jsx";
import { validateGbmCollaborator } from "actions/Sales/plannersFlow.jsx";
// antd components
import { Spin } from 'antd';
// core components Digital Signature
import AddUserAccess from 'components/DigitalSignature/AddUserAccess.jsx';
import UserMaintenance from 'components/DigitalSignature/UserMaintenance.jsx';
// core components Shared
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getAllUsersWithAccess } from 'selectors/digitalSignature.jsx';

class Maintenance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      showModal: false,
      disabled: true,
      initialValues: {},
      infoUser: {},
    };
  };

  componentWillMount() {
    this.props.findUsersWithAccess().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          willMount: false,
        });
      }
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnToggleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal
    }));
  };

  handleOnAddUserAccess = () => {
    this.handleOnToggleModal();
  };

  onDeleteUserAccess = id => {
    this.hideAlert();
    this.setState({
      loading: true,
      message: 'Eliminando el Acceso al usuario'
    });
    this.props.deactivatedUserWithAccess(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findUsersWithAccess();
      }
      this.setState({
        loading: false,
        message: ''
      });
    });
  };

  handleOnDeleteUserAccess = row => {
    const { id } = row;
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar el acceso al usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteUserAccess(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      )
    });
  };

  handleOnValidateUser = async values => {
    const { username } = values;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Validando el usuario ingresado con la información de la compañia`,
      initialValues: { username },
      infoUser: {},
    }));
    await this.props.validateGbmCollaborator({ username: username.split('@')[0].toUpperCase() }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState({
          disabled: true,
        });
      } else {
        const { data: { payload: { response } } } = res.payload;
        this.setState({
          initialValues: { username: response.EMAIL },
          infoUser: response,
          disabled: false,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null
      }));
    });
  };

  handleOnWorkFlow = values => {
    const { infoUser } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Creando el acceso al colaborador indicado`,
    }));
    this.props.createUserAccess({ user: infoUser, country: values.country }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findUsersWithAccess();
      }
      this.handleOnToggleModal();
      this.setState({
        disabled: true,
        initialValues: {},
        infoUser: {},
        loading: false,
        message: null
      });
    });
  };

  render() {

    const {
      name,
      parentName,
      cols,
      users,
      countries,
    } = this.props;

    const {
      alert,
      loading,
      message,
      willMount,
      disabled,
      infoUser,
      showModal,
      initialValues,
    } = this.state;

    return (
      <>
        {alert}
        <AddUserAccess
          type="Agregar Acceso a Usuario"
          message={message}
          user={infoUser}
          isInvalid={disabled}
          loading={loading}
          showModal={showModal}
          initialValues={initialValues}
          countries={countries}
          toggleModal={this.handleOnToggleModal}
          onValidete={this.handleOnValidateUser}
          onWorkFlow={this.handleOnWorkFlow}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          {
            !willMount ?
              <Spin size="large" spinning={loading} tip={message}>
                <UserMaintenance
                  title="Administración de Accesos"
                  subtitle="Gestión de accesos al módulo de Firma Digital"
                  cols={cols}
                  rows={users}
                  addUserAccess={this.handleOnAddUserAccess}
                  onDelete={this.handleOnDeleteUserAccess}
                />
              </Spin>
              :
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando los usuarios con acceso al módulo de Firma Digital" />
                </Col>
              </Row>
          }
        </Container>
      </>
    );
  }
}

Maintenance.defaultProps = {
  name: "Mantenimiento Usuarios",
  parentName: "Firma Digital",
  cols: [
    { key: "UserID", name: "ID Colaborador" },
    { key: "user", name: "Usuario" },
    { key: "name", name: "Nombre Completo" },
    { key: "country", name: "País" },
  ],
  countries: [
    { key: 'CO', name: 'Colombia' },
    { key: 'CR', name: 'Costa Rica' },
    { key: 'DR', name: 'Republica Dominicana' },
    { key: 'GT', name: 'Guatemala' },
    { key: 'HN', name: 'Honduras' },
    { key: 'NI', name: 'Nicaragua' },
    { key: 'PA', name: 'Pánama' },
    { key: 'SV', name: 'El Salvador' },
    { key: 'Managers', name: 'Regional' },
  ],
  users: [],
};

Maintenance.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  users: getAllUsersWithAccess(state),
});

export default withRouter(connect(mapStateToProps, {
  createUserAccess,
  findUsersWithAccess,
  validateGbmCollaborator,
  deactivatedUserWithAccess,
})(Maintenance));
