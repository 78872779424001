/*!

=========================================================
*Ventana de Mantenimiento de Campos de Formularios de Datos Maestros
=========================================================

*Componente para desplegar un card por cada campo tipo lista de cada formulario de Datos Maestros seleccionado en CardsMaintenance.jsx
=========================================================

* Coded by Diego Meza - Application Management GBM

*/
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody } from "reactstrap";


export const CardMaintenance = React.memo((
    {
        title,
        id,
        formSelected,
        setFormSelected
    }) => {

    return (
        <>
            <Card
                className="card-stats card-lift--hover"
                style={{ backgroundColor: (formSelected === id) ? "#d6d4fa" : '#ffffff', cursor: "pointer" }}
                onClick={() => setFormSelected(id)}
            >
                <CardBody>

                    <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require(`assets/img/masterdata/editForm.png`)}
                        style={{ objectFit: "contain", width: "60px", height: "60px" }}
                    />

                    <div className="pt-4 text-center">
                        <h5 className="h3 title">
                            <span className="d-block mb-1">{title}</span>
                            {/* <small className="h4 font-weight-light text-muted">
                                {subtitle}
                            </small> */}
                        </h5>
                    </div>
                </CardBody>
            </Card>
        </>
    )
})