import FieldsInput from 'components/Contacts/FieldInput.jsx'
import React,{useState,useEffect} from 'react'
import {
    Row,
  } from 'reactstrap'


const ExpandableTable = ({ data }) => {
    return (
      <div className='expandable-content p-2'>
        <Row>
          <FieldsInput
            md='3'
            sm='12'
            id={`SAM_${data.id}`}
            value={data.SAM}
            label='SAM'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`EXT1_${data.id}`}
            value={data.EXT1}
            label='Ext #1'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`EXT2_${data.id}`}
            value={data.EXT2}
            label='Ext #2'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`EMAIL_${data.id}`}
            value={data.EMAIL}
            label='Correo electrónico'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`TITLE_${data.id}`}
            value={data.TITLE}
            label='Título'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`PHONE1_${data.id}`}
            value={data.PHONE1}
            label='Teléfono #1'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`PHONE2_${data.id}`}
            value={data.PHONE2}
            label='Teléfono #2'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`PHONES_${data.id}`}
            value={data.PHONES}
            label='Teléfonos'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`STATUS_${data.id}`}
            value={data.STATUS}
            label='Estado'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`ADDRESS_${data.id}`}
            value={data.ADDRESS}
            label='Dirección'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`CONFIRM_${data.id}`}
            value={data.CONFIRM}
            label='Confirmado'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`COUNTRY_${data.id}`}
            value={data.COUNTRY}
            label='País'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`MOBILE1_${data.id}`}
            value={data.MOBILE1}
            label='Celular #1'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`MOBILE2_${data.id}`}
            value={data.MOBILE2}
            label='Celular #2'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`FUNCTION_${data.id}`}
            value={data.FUNCTION}
            label='Función'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`LANGUAGE_${data.id}`}
            value={data.LANGUAGE}
            label='Idioma'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`UPDATEBY_${data.id}`}
            value={data.UPDATEBY}
            label='Actualizado por'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`CREATEDAT_${data.id}`}
            value={data.CREATEDAT}
            label='Fecha de creación'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`LAST_NAME_${data.id}`}
            value={data.LAST_NAME}
            label='Apellido'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`FIRST_NAME_${data.id}`}
            value={data.FIRST_NAME}
            label='Nombre'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`UPDATEDATE_${data.id}`}
            value={data.UPDATEDATE}
            label='Fecha de actualización'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`DEPARTMENT_${data.id}`}
            value={data.DEPARTMENT}
            label='Departamento'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`ID_CUSTOMER_${data.id}`}
            value={data.ID_CUSTOMER}
            label='Id de cliente'
          />
          <FieldsInput
            md='3'
            sm='12'
            id={`ID_CONTACT_CRM_${data.id}`}
            value={data.ID_CONTACT_CRM}
            label='Id del contacto'
          />
        </Row>
      </div>
    )
  }

  export default ExpandableTable