/*!

=========================================================
*Autopp - MainTableAutopp
=========================================================

* En este componente partiendo del state formSelected (que lo asigna CardSelectForm), despliega 
las diferentes vistas de formularios.

*Cuenta con reducers para BAW y SalesTeams, los cuales sirven para agregar, modificar, resetear, la lista de items de 
cada componente mediante actions, reducers e item(ubicado en ItemReducer).

*Además partiendo de la bandera "ReadyToSend", agrega el bawItemsList, salesTeamsList, ldrsList, 
al state principal editData, esto con el fin de que cada componente tenga states locales y 
cuando haya un cambio sea en el componente local, no renderice en los demás componentes, y cuando esté
listo lo agregue al state principal.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React, {
  useContext, useState, useEffect, useReducer,
} from 'react';

import {
  Card, TabContent, TabPane, Row, Col, CardHeader,
} from "reactstrap";
import { UserContext } from './SharedComponents/UserContext';

//Imports de las diferentes vistas
import GeneralInfoForm from "./GeneralInformation/GeneralIInfoForm";
import ClientForm from "./Clients/ClientForm";
import BawItems from './Baw/BawItems';
import SalesTeamsItems from './SalesTeam/SalesTeamsItems';
import LDRMainForm from './LDRForms/LDRMainForm';
import { MyRequestsAutopp } from './Requests/MyRequestsAutopp';

export const MainTableAutopp = React.memo(() => {


  //#region state de la lista de LDRS 
  const [ldrsList, setLdrsList] = useState({})
  //#endregion 

  //#region States globales
  const {
    setFormSelected,
    countLDRActives,
    setToggleCheck,
    setReadyToSend,
    requestsOrForm,
    temporalBOMId,
    formSelected,
    setEditData,
    readyToSend,
    toggleCheck,
    Toast,
    rows,
    cycle,
    editData,

    bawItemsList,
    setBawItemsList,
    salesTeamsList,
    setSalesTeamsList,


    setLDRSAreReady,

  } = useContext(UserContext);
  //#endregion

  //#region Efecto para agregar BawItemsList o SalesTeamsList al editData cuando se presione le boton de enviar gestión.

  //Realiza un conteo de la cantidad de LDRS activos en la LDRSList
  const getCountLDRSActivesInLDRSList = () => {
    if (ldrsList != undefined) {
      return Object.keys(ldrsList).length;
    } else {
      return 0;
    }
  }


  useEffect(() => {

    if (readyToSend === true) {


      //Validación para verificar que los togglesLDRActives estén en la ldrsList de manera completa, esto debido a 
      //que los ldrsItems envia su información a ldrsList cuando readyToSend is true, sin embargo hay que esperar
      //hasta que todos los LDRS terminen de enviar su información antes de enviar la gestión.

      if (getCountLDRSActivesInLDRSList() === countLDRActives
      ) {

        setLDRSAreReady(true);

        setEditData(prevState => {
          return {
            ...prevState,
            ["LDRS"]: ldrsList,
          };
        });

      }
    }


  }, [readyToSend, ldrsList, salesTeamsList, bawItemsList])

  //#endregion
  return (
    <Card className="">
      <CardHeader className="">


        <TabContent key="contentMain" activeTab={requestsOrForm}>
          <TabPane key="forms" tabId={"forms"} className="p-0">
            <Row>
              <Col sm='12' className="" id="">
                <TabContent key="contentMain" activeTab={formSelected}>
                  <TabPane key="General" tabId="1">
                    <GeneralInfoForm
                      toggleCheck={toggleCheck}
                      setToggleCheck={setToggleCheck}
                    />
                  </TabPane>

                  <TabPane key="Client" tabId="2">
                    <ClientForm />
                  </TabPane>

                  <TabPane key="SalesTeams" tabId="3">

                    <SalesTeamsItems
                      salesTeamsList={salesTeamsList}
                      setSalesTeamsList={setSalesTeamsList}
                    ></SalesTeamsItems>
                  </TabPane>

                  {

                    //Si el cycle es alguno de estos ids, que no lo muestre.
                    ![2/*Identify Opportunity*/, 3/*Qualification*/, 5/*Close*/].includes(parseInt(cycle)) &&

                    <TabPane key="LDRS" tabId="4">
                      <LDRMainForm
                        ldrsList={ldrsList}
                        setLdrsList={setLdrsList}
                        toggleCheck={toggleCheck}
                        setToggleCheck={setToggleCheck}
                      />
                    </TabPane>

                  }

                  {

                    //Si el cycle es alguno de estos ids, que no lo muestre.
                    ![2/*Identify Opportunity*/, 3/*Qualification*/, 4/*Quotation*/, 5/*Close*/, 6/*GESTO Quotation*/].includes(parseInt(cycle)) &&


                    <TabPane key="BawItems" tabId="5">
                      <BawItems
                        bawItemsList={bawItemsList}
                        setBawItemsList={setBawItemsList} />
                    </TabPane>
                  }
                </TabContent>

              </Col>
            </Row>
          </TabPane>

          <TabPane key="requests" tabId={"requests"}>

            <Row>
              <Col sm='12'>
                <MyRequestsAutopp
                  rows={rows}
                />
              </Col>
            </Row>

          </TabPane>


        </TabContent>







      </CardHeader>
      {/*JSON.stringify(editData)*/}
      {/*JSON.stringify(temporalBOMId)*/}
    </Card>
  )
})

export default MainTableAutopp;
