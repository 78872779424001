// core antd
import { Tabs } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row, Table } from "reactstrap";

const { TabPane } = Tabs;

const SpareList = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    references,
    onBack,
  } = props;

  const [tabKey, setTabKey] = useState("1");

  const changeTab = value => {
    setTabKey(value);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Tabs defaultActiveKey={tabKey} onChange={changeTab}>
          <TabPane tab="Lista de Equipos" key="1">
            <Table
              className="align-items-center table-flush"
              responsive
              striped
            >
              <thead className="thead-light">
                <tr>
                  {
                    cols.map((row, key) => (<th key={key}>{row.name}</th>))
                  }
                </tr>
              </thead>
              <tbody className="list">
                {
                  rows.map((row, key) => (
                    <tr key={key}>
                      {
                        cols.map((col, key) => (
                          <td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {
                              row[col.key]
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </TabPane>
          {
            references.length &&
            <TabPane tab="Archivos Cargados" key="2">
              <Card>
                <CardBody>
                  <ListGroup className="list my--3" flush>
                    {
                      references.map((row, key) => (
                        <ListGroupItem key={key} className="px-0">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                alt="Referencia"
                                className="avatar rounded-circle"
                                src={require(`assets/img/theme/attachment.png`)}
                              />
                            </Col>
                            <div className="col ml--2">
                              <h4 className="mb-0">
                                {row.name}
                              </h4>
                              <small>
                                {
                                  `${row.createdBy} - ${moment(row.createdAt)
                                    .utc()
                                    .utcOffset(moment().utcOffset())
                                    .format("LLL")}`
                                }
                              </small>
                            </div>
                            <Col className="col-auto">
                              <Button
                                color="info"
                                size="sm"
                                href={`${urlBase}/digital-request/download-attachment/${window.btoa(row.path)}/${window.btoa(row.name)}`}
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-file-download" />
                                </span>
                                <span className="btn-inner--text">Descargar</span>
                              </Button>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      ))
                    }
                  </ListGroup>
                </CardBody>
              </Card>
            </TabPane>
          }
        </Tabs>
        <div className="form-row align-items-center mt-2">
          <Col className="m-2" sm="12" md="3">
            <Button
              className="btn-icon"
              color="success"
              onClick={onBack}
              size='sm'
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Regresar</span>
            </Button>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

SpareList.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SpareList;
