import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from "react-select";
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from "reactstrap";
import { getContacts, updatePurchaseOrder, getProducts } from 'actions/costaRicaBids';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import ReactBSAlert from "react-bootstrap-sweetalert";

const ModalParticipate = ({ showModal, options, setOptions, rows, setShowModal, Toast, setRefreshInput, setRowsModal }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [salesTeam, setSalesTeam] = useState([]);
    const [editData, setEditData] = useState({ bidNumber: rows.bidNumber, contactName: rows.contactName });
    const [alert, setAlert] = useState("");
    const [alertFail, setAlertFail] = useState("");

    //#region  Traer Contactos
    useEffect(() => {
        const getContacts = async () => {
            await handelOngetContacts();
        }
        getContacts();
    }, [editData.customerInstitute]);
    //ad
    const handelOngetContacts = async () => {
        setLoading(true);
        let idCustomer = "";
        if (editData.customerInstitute) {
            idCustomer = editData.customerInstitute
        } else {
            idCustomer = rows.customerInstitute
        }
        dispatch(getContacts({ info: idCustomer, type: "2" })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    contacts: payload.data.payload.response,
                }));
                const contactDefault = payload.data.payload.response[0];
                if (contactDefault) {
                    setEditData(prevState => ({
                        ...prevState,
                        contactName: `${contactDefault.FIRST_NAME} ${contactDefault.LAST_NAME}`,
                        contactId: contactDefault.ID_CONTACT_CRM
                    }))
                }
            };

            setLoading(false);
        });
    };
    //#endregion

    const handleChangeInfo = (constant, e) => {
        if (e && constant === "customerInstitute") {
            setEditData(prevState => ({
                ...prevState,
                customerName: e.name,
                customerInstitute: `00${e.id}`
            }))
        }
        if (constant === "contactId") {
            setEditData(prevState => ({
                ...prevState,
                contactName: e.value,
                contactId: `00${e.id}`
            }))
        } else if (constant !== "customerInstitute") {
            if (editData.newOportunity === "Si") {
                delete editData.opp
            } else if (editData.newOportunity === "No") {
                delete editData.oppType
                delete editData.salesType
            }
            setEditData(prevState => ({
                ...prevState,
                [constant]: e
            }))

        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setEditData([]);
    }
    const sendParticipation = () => {
        editData["id"] = rows.id;
        editData["participation"] = "SI";
        editData["statusRobot"] = 1;
        dispatch(updatePurchaseOrder({ info: editData, type: "2", salesTeam: salesTeam })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                Toast.fire({
                    title: "Atención",
                    html: "Se guardó correctamente",
                    type: 'success'
                });
                setRefreshInput(`${editData.bidNumber}${6}`);
                setShowModal(false);
                dispatch(getProducts({ info: editData.id })).then((resp) => {
                    const { payload } = resp;
                    if (payload.status === 200) {
                        setRowsModal(prevState => ({
                            ...prevState,
                            salesTeam: payload.data.payload.salesTeam,
                        }))
                    }
                })
            } else if (!payload.status) {
                setAlertFail(
                    <ReactBSAlert
                        danger
                        style={{ display: "block", marginTop: "-100px" }}
                        title={`Concurso rechazado`}
                        onConfirm={() => closeAlert()}
                        confirmBtnBsStyle="info"
                        confirmBtnText="ok"
                        btnSize="md"
                    >
                        {`El concurso ya fue rechazado con anterioridad.`}
                    </ReactBSAlert>
                )
                setRefreshInput(`${editData.bidNumber}${6}`);
            }
        });
    }
    const handleOnChangeSalesTeam = (e) => {
        // console.log(e)
        setSalesTeam(e);
    }
    useEffect(() => {
        setAlert(
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title={`Concurso sin cliente`}
                onConfirm={() => setAlert("")}
                confirmBtnBsStyle="info"
                confirmBtnText="ok"
                btnSize="md"
            >
                {`La institución ${rows.institution} no posee cliente en SAP, seleccione su Sales Team y proceda a crear la opp manualmente`}
            </ReactBSAlert>
        )
    }, []);

    const closeAlert = () => {
        setAlert("")
        setShowModal(false);
    }
    console.log(salesTeam)
    return (
        <>
            {alertFail}
            {rows.customerInstitute === "" || rows.customerInstitute === null ?
                alert :
                <></>
            }
            < Modal className="modal-xl" isOpen={showModal} >
                <ModalHeader className="d-flex justify-content-center">
                    {"Participación del concurso: "}
                    <a rel="noreferrer" target="_blank" style={{ color: "blue" }}>
                        {rows.bidNumber}
                    </a>
                </ModalHeader>
                <ModalBody className="d-flex justify-content-center">
                    <Row>
                        <Col xs={12} >
                            <FormGroup >
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {`Cliente:`}
                                </label>
                                <Typeahead
                                    disabled={rows.customerInstitute === "" || rows.customerInstitute === null ? true : false}
                                    id={"Typeahead1"}
                                    labelKey={options => `${options.name}`}
                                    minLength={1}
                                    className={`basic-typeahead-multiple`}
                                    onChange={(e) => handleChangeInfo("customerInstitute", e[0])}
                                    options={options["customers"]}
                                    defaultInputValue={rows[`customerName`] || ""}
                                    renderMenuItemChildren={(value) => (
                                        <div >
                                            {value.id}
                                            <div>
                                                {
                                                    <small>ID: {value.name}</small>
                                                }
                                            </div>
                                        </div>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} >
                            <Spin size="small" spinning={loading}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >  {`Contacto:`}</label>
                                    <Input
                                        disabled={rows.customerInstitute === "" || rows.customerInstitute === null ? true : false}
                                        type={"select"}
                                        className="basic-multi-select text-dark"
                                        value={editData.contactName}
                                        onChange={(e) => handleChangeInfo("contactId", e.target.children[e.target.selectedIndex])}
                                    >
                                        {options["contacts"].map((value, key) => {
                                            return (
                                                <option key={key} id={value.ID_CONTACT_CRM}>{`${value.FIRST_NAME} ${value.LAST_NAME}`}</option>
                                            );
                                        })
                                        }
                                    </Input>
                                </FormGroup>
                            </Spin>
                        </Col>
                        <Col xs={12} >
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >  {`Desea crear la oportunidad?`}</label>
                                <Select
                                    isDisabled={rows.customerInstitute === "" || rows.customerInstitute === null ? true : false}
                                    className="basic-single"
                                    type="select"
                                    isSearchable
                                    onChange={(e) => handleChangeInfo("newOportunity", e.value)}
                                    options={options.newOportunity}
                                />
                            </FormGroup>
                        </Col>
                        {editData.newOportunity == "No" && (
                            <Col xs={12} >
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >
                                        {`Oportunidad:`}
                                    </label>
                                    <Input
                                        placeholder="Indique la oportunidad"
                                        className="basic-multi-select text-dark"
                                        type={"text"}
                                        onChange={(e) => handleChangeInfo("opp", e.target.value)}
                                    ></Input>
                                </FormGroup>
                            </Col>
                        )}
                        {editData.newOportunity == "Si" && (
                            <Col xs={12} >
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >  {`Tipo de oportunidad:`}</label>
                                    <Select
                                        className="basic-single"
                                        type="select"
                                        isSearchable
                                        onChange={(e) => handleChangeInfo("oppType", e.value)}
                                        options={options.oppType}
                                    />
                                </FormGroup>
                            </Col>
                        )
                        }
                        {(editData.newOportunity === "Si") && (
                            <Col xs={12} >
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >  {`Tipo de Venta:`}</label>
                                    <Select
                                        className="basic-single"
                                        type="select"
                                        isSearchable
                                        onChange={(e) => handleChangeInfo("salesType", e.value)}
                                        options={options.salesType}
                                    />
                                </FormGroup>
                            </Col>
                        )
                        }
                        <Col xs={12}>
                            <FormGroup >
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {`Sales Team:`}
                                </label>
                                <Typeahead
                                    style={{ border: "normal", textAlign: "auto", minWidth: "100px" }}
                                    id={"typeaheadcustom"}
                                    labelKey={options => `${options.name}`}
                                    minLength={1}
                                    multiple
                                    className={`basic-typeahead-multiple`}
                                    onChange={(e) => handleOnChangeSalesTeam(e)}
                                    options={options["employee"] }
                                    defaultInputValue={rows[`salesTeam`] || ""}
                                    renderMenuItemChildren={(value) => (
                                        <div >
                                            {value.name}
                                            <div>
                                                {
                                                    <small>User: {value.user}</small>
                                                }
                                            </div>
                                        </div>
                                    )}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Button
                            color="danger"
                            onClick={() => handleCloseModal()}
                        >
                            Cancelar
                        </Button>
                        {/* Valida los campos para el boton enviar */}
                        <Button
                            color="info"
                            disabled={((editData.newOportunity === "No" && (!editData.opp || editData.opp === "")) ||
                                (editData.newOportunity === "Si" && !editData.oppType) || 
                                (editData.newOportunity === "Si" && !editData.salesType))  
                               || (salesTeam.length <= 0)
                                ? true : false}
                            onClick={() => sendParticipation()}
                        >
                            Enviar
                        </Button >
                    </Row>
                </ModalFooter>
            </Modal >
        </>

    );
};
export default ModalParticipate;
