import moment from "moment";
import React, { useState } from 'react';
import Select from "react-select";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

const QuickQuoteEditFormOne = (
  { dataModal, handleChangeInfo, isAdmin }
) => {
  const [rowsModal, setRowsModal] = useState([
    {
      colWidth: "4",
      label: "Entidad",
      id: "entity",
      type: "text"
    },
    {
      colWidth: "4",
      label: "Dependencia",
      id: "dependence",
      type: "text"
    },
    {
      colWidth: "4",
      label: "Unidad de compra",
      id: "purchaseUnit",
      type: "text"
    },
    {
      colWidth: "8",
      label: "Descripcion",
      id: "description",
      type: "text"
    },
    {
      colWidth: "4",
      label: "Fecha de presentacion",
      id: "quoteDate",
      type: "date"
    },
    {
      colWidth: "4",
      label: "Interes de GBM",
      id: "interestGBM",
      type: "Select"
    },
  ]);

  return (
    <div>
      <Card>
        <CardBody >
          <Row>
            {rowsModal.map((row, key) =>
              row.type === "text" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      className="form-control"
                      // type={row.type}
                      disabled={isAdmin === true ? false : true}
                      value={dataModal[row.id] || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : row.type === "date" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      type={row.type}
                      value={moment.utc(dataModal[row.id]).format("YYYY-MM-DD") || ""}
                      disabled={isAdmin === true ? false : true}
                      className="basic-multi-select text-dark"
                    ></Input>
                  </FormGroup>
                </Col>
              ) : (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="startDate">
                      {`${row.label}:`}
                    </label>
                    <Select
                      key={key}
                      className="basic-single"
                      type="select"
                      value={{ label: dataModal[row.id], value: dataModal[row.id] } || ""}
                      isDisabled={isAdmin === true ? false : true}
                      isSearchable
                      options={[{ label: "Si", value: "Si" }, { label: "No", value: "No" }]}
                      onChange={(e) => handleChangeInfo(row.id, e.value)}
                    />
                  </FormGroup>
                </Col>
              )
            )}
          </Row>
        </CardBody>
      </Card>

    </div>
  );
};



export default QuickQuoteEditFormOne;