import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findInternalTeams = createAction(
  constants.GET_INTERNAL_TEAMS,
  method.get(urls.MIS.getInternalTeams)
);

export const findInactive = createAction(
  constants.GET_INACTIVE,
  method.get(urls.MIS.getInactive)
);

export const updateInactiveInfo = createAction(
  constants.UPDATE_INACTIVE,
  data => method.put(urls.MIS.updateInactiveInfo, data)()
);

export const insertInactive = createAction(constants.INSERT_INACTIVE, data =>
  method.post(urls.MIS.insertInactive, data)()
);

export const findMaintenance = createAction(
  constants.GET_MAINTENANCE,
  method.get(urls.MIS.getMaintenance)
);

export const updateMaintenanceInfo = createAction(
  constants.UPDATE_MAINTENANCE,
  data => method.put(urls.MIS.updateMaintenanceInfo, data)()
);

export const insertMaintenance = createAction(
  constants.INSERT_MAINTENANCE,
  data => method.post(urls.MIS.insertMaintenance, data)()
);

export const findProjects = createAction(constants.GET_PROJECTS, data =>
  method.post(urls.MIS.getProjects, data)()
);

export const findCountProjects = createAction(
  constants.GET_COUNT_PROJECTS,
  method.get(urls.MIS.getCountProjects)
);

export const updateProjectsInfo = createAction(
  constants.UPDATE_PROJECTS,
  data => method.put(urls.MIS.updateProjectsInfo, data)()
);

export const insertProjects = createAction(constants.INSERT_PROJECTS, data =>
  method.post(urls.MIS.insertProjects, data)()
);
