import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findOffices = createAction(
  constants.GET_OFFICES,
  method.get(urls.MEDICALRECORDS.offices)
);

export const findMedicalRecord = createAction(
  constants.GET_MEDICAL_RECORD,
  method.get(`${urls.MEDICALRECORDS.medicalRecord}`)
);

export const createMedicalRecord = createAction(
  constants.CREATE_MEDICAL_RECORD,
  (body) => method.post(urls.MEDICALRECORDS.medicalRecord, body)()
);

export const updateMedicalRecord = createAction(
  constants.UPDATE_MEDICAL_RECORD,
  (id, body) => method.put(`${urls.MEDICALRECORDS.medicalRecord}/${id}`, body)()
);

export const findEmergencyContacts = createAction(
  constants.GET_EMERGENCY_CONTACTS,
  (id) => method.get(`${urls.MEDICALRECORDS.contacts}/${id}`)()
);

export const createEmergencyContact = createAction(
  constants.CREATE_EMERGENCY_CONTACT,
  (body) => method.post(urls.MEDICALRECORDS.contact, body)()
);

export const updateEmergencyContact = createAction(
  constants.UPDATE_EMERGENCY_CONTACT,
  (id, body) => method.put(`${urls.MEDICALRECORDS.contact}/${id}`, body)()
);

export const deleteEmergencyContact = createAction(
  constants.DELETE_EMERGENCY_CONTACT,
  (id) => method.put(`${urls.MEDICALRECORDS.deleteContact}/${id}`)()
);

export const findAllAllergies = createAction(
  constants.GET_ALL_ALLERGIES,
  (id) => method.get(`${urls.MEDICALRECORDS.allergies}/${id}`)()
);

export const createAllergy = createAction(constants.CREATE_ALLERGY, (body) =>
  method.post(urls.MEDICALRECORDS.allergy, body)()
);

export const updateAllergy = createAction(
  constants.UPDATE_ALLERGY,
  (id, body) => method.put(`${urls.MEDICALRECORDS.allergy}/${id}`, body)()
);

export const deleteAllergy = createAction(constants.DELETE_ALLERGY, (id) =>
  method.put(`${urls.MEDICALRECORDS.deleteAllergy}/${id}`)()
);

export const findAllDiseases = createAction(constants.GET_ALL_DISEASES, (id) =>
  method.get(`${urls.MEDICALRECORDS.diseases}/${id}`)()
);

export const createDisease = createAction(constants.CREATE_DISEASE, (body) =>
  method.post(urls.MEDICALRECORDS.disease, body)()
);

export const updateDisease = createAction(
  constants.UPDATE_DISEASE,
  (id, body) => method.put(`${urls.MEDICALRECORDS.disease}/${id}`, body)()
);

export const deleteDisease = createAction(constants.DELETE_DISEASE, (id) =>
  method.put(`${urls.MEDICALRECORDS.deleteDisease}/${id}`)()
);

export const findAllInterventions = createAction(
  constants.GET_ALL_INTERVENTIONS,
  (id) => method.get(`${urls.MEDICALRECORDS.interventions}/${id}`)()
);

export const createIntervention = createAction(
  constants.CREATE_INTERVENTION,
  (body) => method.post(urls.MEDICALRECORDS.intervention, body)()
);

export const updateIntervention = createAction(
  constants.UPDATE_INTERVENTION,
  (id, body) => method.put(`${urls.MEDICALRECORDS.intervention}/${id}`, body)()
);

export const deleteIntervention = createAction(
  constants.DELETE_INTERVENTION,
  (id) => method.put(`${urls.MEDICALRECORDS.deleteIntervention}/${id}`)()
);

export const findRecordsByRol = createAction(
  constants.GET_RECORDS_BY_ROL,
  (country) =>
    method.get(`${urls.MEDICALRECORDS.findRecordsByRol}/${country}`)()
);

export const findAllDataAdminDashboard = createAction(
  constants.GET_INFORMATION_DASHBOARD,
  (year) =>
    method.get(`${urls.MEDICALRECORDS.findAllDataAdminDashboard}/${year}`)()
);

export const findOtherTypes = createAction(
  constants.GET_OTHER_TYPES,
  method.get(urls.MEDICALRECORDS.othersTypes)
);

export const findAllOtherMedicalInfo = createAction(
  constants.GET_ALL_OTHER_MEDICAL_INFO,
  (id) => method.get(`${urls.MEDICALRECORDS.others}/${id}`)()
);

export const createOtherMedicalInfo = createAction(
  constants.CREATE_OTHER_MEDICAL_INFO,
  (body) => method.post(urls.MEDICALRECORDS.other, body)()
);

export const updateOtherMedicalInfo = createAction(
  constants.UPDATE_OTHER_MEDICAL_INFO,
  (id, body) => method.put(`${urls.MEDICALRECORDS.other}/${id}`, body)()
);

export const deleteOtherMedicalInfo = createAction(
  constants.DELETE_OTHER_MEDICAL_INFO,
  (id) => method.put(`${urls.MEDICALRECORDS.deleteOther}/${id}`)()
);

export const updateMedication = createAction(
  constants.UPDATE_MEDICATION,
  (id, body) => method.put(`${urls.MEDICALRECORDS.medication}/${id}`, body)()
);

export const downloadVaccineDataLocation = createAction(
  constants.DOWNLOAD_PARTS_BY_MODEL_IN_QUOTE,
  (year) =>
    method.downloadDataTables(
      `${urls.MEDICALRECORDS.downloadVaccineDataLocation}/${year}`
    )()
);

export const findUserWithAccessRecords = createAction(
  constants.GET_USERS_ACCESS_MEDICAL_RECORD,
  method.get(urls.MEDICALRECORDS.getUserWithAccess)
);

export const createUserAccessRecords = createAction(
  constants.CREATE_USER_ACCESS_MEDICAL_RECORD,
  (body) => method.post(urls.MEDICALRECORDS.createUserAccess, body)()
);

export const hideMedicalEvent = createAction(
  constants.HIDDEN_EVENT_MEDICAL_RECORD,
  (id) => method.post(`${urls.MEDICALRECORDS.hide}/${id}`)()
);

export const deleteUserAccess = createAction(
  constants.DELETE_USER_ACCESS_MEDICAL_RECORD,
  (id) => method.put(`${urls.MEDICALRECORDS.deleteUserAccess}/${id}`)()
);

export const downloadAttachment = createAction(
  constants.DOWNLOAD_ATTACHMENT_MEDICAL_RECORD,
  (id) => method.fileDownload(`${urls.MEDICALRECORDS.attachment}/${id}`)()
);

export const downloadMedicalRecordsAdmin = createAction(
  constants.DOWNLOAD_PARTS_BY_MODEL_IN_QUOTE,
  method.downloadDataTables(
    `${urls.MEDICALRECORDS.downloadMedicalRecordsAdmin}`
  )
);

export const downloadAttachmentRecords = createAction(
  constants.DOWNLOAD_PARTS_BY_MODEL_IN_QUOTE,
  (body) =>
    method.downloadDataTables(
      `${urls.MEDICALRECORDS.downloadAttachmentsRecordsAdmin}`,
      body
    )()
);
