import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const createRequest = handleActions(
  {
    [constants.AS_CREATE_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const approversData = handleActions(
  {
    [constants.AS_GET_APPROVERS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.approvers;
      }
      return {};
    },
  },
  []
);

export const otherApproversData = handleActions(
  {
    [constants.AS_GET_OTHER_APPROVERS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.approvers;
      }
      return {};
    },
  },
  []
);

export const requestData = handleActions(
  {
    [constants.AS_GET_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.request;
      }
      return {};
    },
    [constants.AS_FULL_DOC]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.request;
      }
      return {};
    },
  },
  []
);

export const completeRequestsData = handleActions(
  {
    [constants.AS_COMPLETE_REQUESTS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.requests;
      }
      return [];
    },
  },
  []
);

export const pendingRequestsData = handleActions(
  {
    [constants.AS_PENDING_REQUESTS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.requests;
      }
      return [];
    },
  },
  []
);

export const cancelledRequestsData = handleActions(
  {
    [constants.AS_CANCELLED_REQUESTS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.requests;
      }
      return [];
    },
  },
  []
);

export const NotificationsData = handleActions(
  {
    [constants.AS_NOTIFICATIONS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.requests;
      }
      return [];
    },
  },
  []
);
