import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

//#region EPIEDRA
export const getLocationsUser = createAction("", method.get(urls.WMS.getLocationsUser));
export const getAllPosByPlant = createAction("", body => method.post(urls.WMS.getAllPosByPlant, body)());
export const getSupplierByPo = createAction("", body => method.post(urls.WMS.getSupplierByPO, body)());
export const getPositionsByPo = createAction("", body => method.post(urls.WMS.getPositionsByPo, body)());
export const inboundConfirmation = createAction("", body => method.post(urls.WMS.inboundConfirmation, body)());
export const getItemsOtsByWarehouse = createAction("", body => method.post(urls.WMS.getItemsOtsByWarehouse, body)());
export const getItemInfoByOt = createAction("", body => method.post(urls.WMS.getItemInfoByOt, body)());
export const sendRequestInbound = createAction("", body => method.post(urls.WMS.sendRequestInbound, body)());
export const getAllDocsByPlant = createAction("", body => method.post(urls.WMS.getAllDocsByPlant, body)());
export const getPositionsByDoc = createAction("", body => method.post(urls.WMS.getPositionsByDoc, body)());
export const sendRequestOutboundByDoc = createAction("", body => method.post(urls.WMS.sendRequestOutboundByDoc, body)());
export const getPositionsByOt = createAction("", body => method.post(urls.WMS.getPositionsByOt, body)());
export const sendRequestOutboundByOt = createAction("", body => method.post(urls.WMS.sendRequestOutboundByOt, body)());

export const getReservationsByMovementAndPlant = createAction("", body => method.post(urls.WMS.getReservationsByMovementAndPlant, body)());
export const getReservationActiveByDoc = createAction("", body => method.post(urls.WMS.getReservationActiveByDoc, body)());
export const getPositionsByReservation = createAction("", body => method.post(urls.WMS.getPositionsByReservation, body)());
export const getReservationsByPlant = createAction("", body => method.post(urls.WMS.getReservationsByPlant, body)());
export const getMovementByReservation = createAction("", body => method.post(urls.WMS.getMovementByReservation, body)());
export const sendReservationOutboundConfirmation = createAction("", body => method.post(urls.WMS.sendReservationOutboundConfirmation, body)());


//#endregion



//#region DMEZA
export const getAllDataModalInventory = createAction("", body => method.post(urls.WMS.getAllDataModalInventory, body)());
export const createNewDocumentInventoryNoValidated = createAction("", body => method.post(urls.WMS.createNewDocumentInventoryNoValidated, body)());
export const stockInquiries = createAction("", body => method.post(urls.WMS.stockInquiries, body)());
export const expandMaterials = createAction("", body => method.post(urls.WMS.expandMaterials, body)());
export const checkValidatedInventory = createAction("", body => method.post(urls.WMS.checkValidatedInventory, body)());
export const executeConfirmNoValidated = createAction("", body => method.post(urls.WMS.executeConfirmNoValidated, body)());

//#endregion

//#region Dashboard
export const transactionCount = createAction(
  constants.WMS_DASHBOARD_COUNT_TRANSACTIONS,
  method.get(urls.WMS.transactionCount)
);

export const loginTotals = createAction(
  constants.WMS_DASHBOARD_LOGIN_TOTALS,
  method.get(urls.WMS.loginTotals)
);

export const mostExecutedTransaction = createAction(
  constants.WMS_DASHBOARD_MOST_EXECUTED,
  method.get(urls.WMS.mostExecutedTransaction)
);

export const executedTotals = createAction(
  constants.WMS_DASHBOARD_EXECUTED_TOTALS,
  method.get(urls.WMS.executedTotals)
);

export const movementsTotals = createAction(
  constants.WMS_DASHBOARD_MOVEMENT_TOTALS,
  method.get(urls.WMS.movementsTotals)
);

export const locationsTotals = createAction(
  constants.WMS_DASHBOARD_LOCATION_TOTALS,
  method.get(urls.WMS.locationsTotals)
);

export const monthsTotals = createAction(
  constants.WMS_DASHBOARD_MONTHS_TOTALS,
  method.get(urls.WMS.monthsTotals)
);

export const daysTotals = createAction(
  constants.WMS_DASHBOARD_DAYS_TOTALS,
  method.get(urls.WMS.daysTotals)
);

// export const monthTotals = createAction(
//   constants.GET_NODES,
//   method.get(urls.PLANNINGMRS.getNodes)
// );
//#endregion

export const signInWms = createAction("", body => method.post(urls.WMS.signInWms, body)());


