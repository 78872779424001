// core actions
import {
  clearData,
  createRelation,
  findPositions,
  createMasterData,
  findAllMasterData,
  findRelationDataByPosition,
} from "actions/newPosition.jsx";
// core antd
import { Spin } from "antd";
// core components
import CreateElements from "components/NewPosition/CreateElements.jsx";
import CreateRelations from "components/NewPosition/CreateRelations.jsx";
import TypePosition from "components/NewPosition/TypePosition.jsx";
import AddMasterDataDrawer from "components/NewPosition/AddMasterDataDrawer.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
// selectors
import {
  getAccessByPosition,
  getBussinessLinesByPosition,
  getCecoByPosition,
  getDirectionByPosition,
  getOrgUnitByPosition,
  getPersonalAreasByPosition,
  getPositions,
  getAllMasterDataPositions,
} from "selectors/newPosition";

class SupportPosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      supportType: "",
      supportTypeSelected: false,
      idPosition: 0,
      orgUnitKeys: [],
      cecoKeys: [],
      personalAreasKeys: [],
      directionsKeys: [],
      bussinessLinesKeys: [],
      accessKeys: [],
      flag: false,
      masterTableSelected: {
        value: 0,
        key: "all",
        label: "Seleccione la tabla maestra a gestionar",
      },
      addMasterData: false,
      loading: false,
      message: null,
    };
  }

  componentWillMount() {
    if (!this.props.positions.length) {
      this.props.findPositions().then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            await this.notify("danger", "Falló", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
      });
    }
  }

  componentWillUpdate = (nextProps, nextState) => {
    const {
      orgUnitKeys,
      cecoKeys,
      personalAreasKeys,
      directionsKeys,
      bussinessLinesKeys,
      accessKeys,
      flag,
    } = nextState;
    const {
      orgUnitByPosition,
      cecoByPosition,
      personalAreasByPosition,
      directionsByPosition,
      bussinessLinesByPosition,
      accessByPosition,
    } = nextProps;
    if (!flag) {
      if (orgUnitByPosition.length && !orgUnitKeys.length) {
        this.setState({
          orgUnitKeys: orgUnitByPosition,
        });
      }
      if (cecoByPosition.length && !cecoKeys.length) {
        this.setState({
          cecoKeys: cecoByPosition,
        });
      }
      if (personalAreasByPosition.length && !personalAreasKeys.length) {
        this.setState({
          personalAreasKeys: personalAreasByPosition,
        });
      }
      if (directionsByPosition.length && !directionsKeys.length) {
        this.setState({
          directionsKeys: directionsByPosition,
        });
      }
      if (bussinessLinesByPosition.length && !bussinessLinesKeys.length) {
        this.setState({
          bussinessLinesKeys: bussinessLinesByPosition,
        });
      }
      if (accessByPosition.length && !accessKeys.length) {
        this.setState({
          accessKeys: accessByPosition,
        });
      }
    }
  };

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  componentWillUnmount() {
    this.props.clearData();
  }

  handleOnClick = (event) => {
    this.setState({
      supportType: event,
      supportTypeSelected: true,
    });
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnBack = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de regresar?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.props.history.goBack()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, regresar"
          cancelBtnText="No, continuar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnClearSupportType = () => {
    this.setState({
      supportType: "",
      supportTypeSelected: false,
    });
  };

  handleOnSearch = async (values) => {
    const { idPosition } = values;
    this.setState({
      idPosition,
    });
    await this.props
      .findRelationDataByPosition(idPosition)
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            await this.notify("danger", "Falló", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          await this.notify("success", "Éxito", payload.message);
        }
      });
  };

  handleOnCancelData = async () => {
    await this.props.clearData();
    this.setState({
      orgUnitKeys: [],
      cecoKeys: [],
      personalAreasKeys: [],
      directionsKeys: [],
      bussinessLinesKeys: [],
      accessKeys: [],
      flag: false,
      idPosition: 0,
    });
  };

  handleOnChange = (type) => (values) => {
    if (type === "organizationalUnit") {
      this.setState({
        orgUnitKeys: values,
        flag: true,
      });
    } else if (type === "ceco") {
      this.setState({
        cecoKeys: values,
        flag: true,
      });
    } else if (type === "personalArea") {
      this.setState({
        personalAreasKeys: values,
        flag: true,
      });
    } else if (type === "direction") {
      this.setState({
        directionsKeys: values,
        flag: true,
      });
    } else if (type === "bussinessLine") {
      this.setState({
        bussinessLinesKeys: values,
        flag: true,
      });
    } else if (type === "access") {
      this.setState({
        accessKeys: values,
        flag: true,
      });
    }
  };

  handleOnCreate = async () => {
    const {
      idPosition,
      accessKeys,
      bussinessLinesKeys,
      cecoKeys,
      directionsKeys,
      orgUnitKeys,
      personalAreasKeys,
    } = this.state;
    await this.props
      .createRelation({
        idPosition,
        accessKeys,
        bussinessLinesKeys,
        cecoKeys,
        directionsKeys,
        orgUnitKeys,
        personalAreasKeys,
      })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          await this.handleOnCancelData();
        }
      });
  };

  handleOnChangeMasterOptionsValue = (event) => {
    this.setState({
      masterTableSelected: event,
    });
  };

  findMasterVariablesByMasterTable = () => {
    this.setState({
      loading: true,
      message: "Cargando los datos para la opción seleccionada",
    });
    this.props.findAllMasterData().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnSearchMasterDataByOptionSelected = () => {
    this.findMasterVariablesByMasterTable();
  };

  handleOnToggleDrawer = () => {
    this.setState((state) => ({
      addMasterData: !state.addMasterData,
    }));
  };

  handleOnCreateMasterData = (values) => {
    const {
      masterTableSelected: { key },
    } = this.state;
    values.type = key;
    console.log(values);
    this.setState({
      loading: true,
      message: "Creando el dato maestro",
    });
    this.props.createMasterData(values).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        // this.notify("success", "Éxito", payload.message);
        this.handleOnToggleDrawer();
        this.findMasterVariablesByMasterTable();
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  render() {
    const {
      alert,
      supportType,
      supportTypeSelected,
      orgUnitKeys,
      cecoKeys,
      personalAreasKeys,
      directionsKeys,
      bussinessLinesKeys,
      accessKeys,
      flag,
      masterTableSelected,
      addMasterData,
      loading,
      message,
    } = this.state;

    const { positions, masterData, generalCols } = this.props;

    return (
      <>
        {alert}
        <AddMasterDataDrawer
          title="Creación de un dato maestro en el sistema"
          type={masterTableSelected.key}
          visible={addMasterData}
          uploading={loading}
          initialValues={{}}
          toggleDrawer={this.handleOnToggleDrawer}
          onFinish={this.handleOnCreateMasterData}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Soporte de posiciones" parentName="Nueva Posición" />
        <Spin size="large" spinning={loading} tip={message}>
          <Container className="mt--6" fluid>
            {!supportTypeSelected ? (
              <Row className="justify-content-center">
                <Col xs="12">
                  <Row>
                    <Col xs="12" md="4">
                      <TypePosition
                        icon="fas fa-user-plus"
                        type="createdPosition"
                        title="Solicitar una nueva posición."
                        subtitle="Realizar una solicitud para la posible creación de un nuevo puesto en GBM."
                        keyworks={[
                          "Creación de un puesto",
                          "DO y Compensación",
                          "Creación de Job en SAP",
                          "Automatización",
                          "Entre otros...",
                        ]}
                        onClick={this.handleOnClick}
                      />
                    </Col>
                    <Col xs="12" md="4">
                      <TypePosition
                        icon="fas fa-user-edit"
                        type="createdRelation"
                        title="Asociación de elementos."
                        subtitle="Asociación de todos los elementos requeridos para una nueva posición o modificación de una relación existente."
                        keyworks={[
                          "Centro de Costo",
                          "Área Personal",
                          "Línea de negocio",
                          "Unidad Organizacional",
                          "Entre otros...",
                        ]}
                        onClick={this.handleOnClick}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : supportType === "createdPosition" ? (
              <CreateElements
                title="Creación de datos maestros"
                subtitle="Formulario para la creación de datos maestros en la base de datos."
                optionSelected={masterTableSelected}
                masterData={masterData}
                generalCols={generalCols}
                onAddMasterData={this.handleOnToggleDrawer}
                onChange={this.handleOnChangeMasterOptionsValue}
                onSearch={this.handleOnSearchMasterDataByOptionSelected}
                onBack={this.handleOnClearSupportType}
              />
            ) : (
              <CreateRelations
                title="Creación o modificiación de relaciones"
                subtitle="Formulario para asociar a las posiciones cada elemento relacionado en la base de datos."
                positions={positions}
                orgUnitKeys={orgUnitKeys}
                cecoKeys={cecoKeys}
                personalAreasKeys={personalAreasKeys}
                directionsKeys={directionsKeys}
                bussinessLinesKeys={bussinessLinesKeys}
                accessKeys={accessKeys}
                valid={flag}
                onBack={this.handleOnClearSupportType}
                onSearch={this.handleOnSearch}
                onCancel={this.handleOnCancelData}
                onChange={this.handleOnChange}
                onCreate={this.handleOnCreate}
              />
            )}
            {supportType === "" ? (
              <Row className="justify-content-left">
                <Col className="mb-3" sm="12" md="3">
                  <Button
                    className="btn-icon"
                    block
                    color="info"
                    type="button"
                    onClick={this.handleOnBack}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-arrow-left" />
                    </span>
                    <span className="btn-inner--text">Volver</span>
                  </Button>
                </Col>
              </Row>
            ) : null}
          </Container>
        </Spin>
      </>
    );
  }
}

SupportPosition.defaultProps = {
  positions: [],
  orgUnitByPosition: [],
  cecoByPosition: [],
  personalAreasKeys: [],
  directionsKeys: [],
  bussinessLinesKeys: [],
  accessKeys: [],
  masterData: {},
  generalCols: {
    cecos: [
      { key: "id", name: "#REF" },
      { key: "name", name: "Número" },
      { key: "createdAt", name: "Creado" },
    ],
    ins: [
      { key: "id", name: "#REF" },
      { key: "number", name: "Número" },
      { key: "key", name: "Key" },
      { key: "createdAt", name: "Creado" },
    ],
    organizationalUnits: [
      { key: "id", name: "#REF" },
      { key: "name", name: "Nombre" },
      { key: "key", name: "Key" },
      { key: "createdAt", name: "Creado" },
    ],
    positions: [
      { key: "id", name: "#REF" },
      { key: "name", name: "Nombre" },
      { key: "insNumber", name: "Número INS" },
      { key: "protection", name: "Protección" },
      { key: "localRegionalType", name: "Local / Regional" },
      { key: "createdAt", name: "Creado" },
    ],
  },
};

SupportPosition.propTypes = {
  positions: PropTypes.array.isRequired,
  orgUnitByPosition: PropTypes.array.isRequired,
  cecoByPosition: PropTypes.array.isRequired,
  personalAreasByPosition: PropTypes.array.isRequired,
  directionsByPosition: PropTypes.array.isRequired,
  bussinessLinesByPosition: PropTypes.array.isRequired,
  accessByPosition: PropTypes.array.isRequired,
  masterData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  positions: getPositions(state),
  orgUnitByPosition: getOrgUnitByPosition(state),
  cecoByPosition: getCecoByPosition(state),
  personalAreasByPosition: getPersonalAreasByPosition(state),
  directionsByPosition: getDirectionByPosition(state),
  bussinessLinesByPosition: getBussinessLinesByPosition(state),
  accessByPosition: getAccessByPosition(state),
  masterData: getAllMasterDataPositions(state),
});

export default withRouter(
  connect(mapStateToProps, {
    clearData,
    findPositions,
    createRelation,
    createMasterData,
    findAllMasterData,
    findRelationDataByPosition,
  })(SupportPosition)
);
