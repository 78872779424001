// core actions
import { findAllConflictInterestsInfo } from "actions/conflictInterests.jsx";
// core antd
import { message, Spin } from "antd";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// core components Main
import Forms from "components/ConflictInterests/Forms.jsx";
import FormInfo from "components/ConflictInterests/FormInfo.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getAllConflictInterestsInfo } from "selectors/conflictInterests.jsx";

class FormsComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      formSelected: {},
    };
  }

  componentDidMount() {
    this.findAllConflictInterestsInfo();
  }

  findAllConflictInterestsInfo = () => {
    this.props.findAllConflictInterestsInfo().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        willMount: false,
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  handleOnOpenForm = (values) => {
    this.setState({
      formSelected: values,
    });
  };

  handleOnBackMain = () => {
    this.setState({
      formSelected: {},
    });
  };

  render() {
    const { name, parentName, forms, columns, cols } = this.props;

    const {
      alert,
      willMount,
      loading,
      message,
      page,
      sizePerPage,
      formSelected,
    } = this.state;

    console.log(forms);

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message} size="large">
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de formularios" />
                </Col>
              </Row>
            ) : Object.keys(formSelected).length ? (
              <FormInfo
                title=" Conflicto de Intereses con Proveedores"
                subtitle="Completar Formulario Conflicto de Intereses con Proveedores"
                cols={cols}
                initialValues={formSelected}
                onBack={this.handleOnBackMain}
              />
            ) : forms.length ? (
              <Forms
                page={page}
                title="Lista de los formularios"
                columns={columns}
                sizePerPage={sizePerPage}
                data={forms}
                onSetPage={this.handleOnSetPage}
                onOpen={this.handleOnOpenForm}
              />
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay formularios por visualizar."
                    subtitle="Actualmente al día de hoy, no tienes formularios de conflicto de intereses con proveedores, cuando existan se enlistarán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

FormsComplete.defaultProps = {
  name: "Formularios",
  parentName: "Conflicto de Intereses con Proveedores",
  forms: [],
  columns: [
    "#REF",
    "Nombre del Colaborador",
    "ID del Colaborador",
    "Posición",
    "País",
    "Estado",
    "Fecha de Creación",
    "Fecha de Actualización",
  ],
  cols: [
    { key: "supplierName", name: "Nombre del Proveedor" },
    { key: "name", name: "Nombre de la persona" },
    { key: "relationType", name: "Tipo de Relación" },
    { key: "position", name: "Cargo de la persona" },
    { key: "supplierInfo", name: "Servicio que brinda" },
    {
      key: "isSupplierContractingLink",
      name: "¿Tiene participación en la contratación?",
    },
    {
      key: "hasSupplierBenefit",
      name: "¿Posee relación laboral o beneficio económico?",
    },
    {
      key: "benefitDescription",
      name: "Detalle la relación laboral o beneficio",
    },
    { key: "comments", name: "Comentarios" },
  ],
};

FormsComplete.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  forms: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  forms: getAllConflictInterestsInfo(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllConflictInterestsInfo,
  })(FormsComplete)
);
