import React, { useState, useEffect } from 'react';
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { Spin } from 'antd';
import { useDispatch } from "react-redux";
import ShowCategories from "components/FinanceFlows/FlowsAprobation/ShowCategories.jsx"
import ShowDocuments from "components/FinanceFlows/FlowsAprobation/ShowDocuments"
import RequestInformation from "components/FinanceFlows/FlowsAprobation/RequestInformation"
import { urlBase } from "api/urls.jsx";
import { useHistory } from "react-router-dom";

import withReactContent from "sweetalert2-react-content";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import { getDocumentById, getDocumentField, getRulesAndApproverByTemplate, createNewRequest, deleteFile, getBusinessUnitByUser } from "actions/financialFlows"
import FormToRequest from "components/FinanceFlows/FlowsAprobation/FormToRequest";
import { object } from 'prop-types';

const NewRequest = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const [loading, setLoad] = useState(false);
    const [info, setInfo] = useState({
        Category: "",
        Document: "",
        DocumentField: "",
        Template: "",
        view: 0,
        countries: []
    });
    const [documents, setDocuments] = useState([]);
    const [documentField, setDocumentField] = useState([]);
    const [documentsTemplates, setDocumentsTemplates] = useState({});
    const [template, setTemplate] = useState([]);
    const [newInfo, setNewInfo] = useState({});
    const [newInfoToInsert, setNewInfoToInsert] = useState({});
    const [infoFilters, setinfoFilters] = useState([]);
    const [approvers, setApprovers] = useState([]);
    //Número random temporal, para usarlo de idTemporal y subir los archivos BOM al ftp
    const [temporalFolderFilesId] = useState(new Date().getTime());
    const [alert, setAlert] = useState("");
    const [disabledForm, setDisabledForm] = useState(false);
    const history = useHistory();
    const [countRows, setCountRows] = useState([]);
    const [generateRequest, setGenerateRequest] = useState(false);

    const handleOnChangeCountRows = (e, options) => {
        let array = [];
        for (let i = 1; i <= e; i++) {
            let json = {};
            for (const item of options) {
                const { label } = item;
                json[label] = "";
            }
            array.push(json);
        }
        setCountRows(array);
    }
    useEffect(() => {
        const getData = async () => {
            handleOnGetBusinessUnitByUser();
        }
        getData();
    }, []);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    //Funcion que trae la data de la tabla principal
    const handleOnGetBusinessUnitByUser = async () => {
        dispatch(getBusinessUnitByUser()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfo((prevState) => ({
                    ...prevState,
                    countries: payload.data.data,
                }));
            }
        })
    }
    const handleOnResetInfo = () => {
        setApprovers([]);
        setTemplate([]);
        setCountRows([]);
        setDisabledForm(false);
        setGenerateRequest(false);
        setNewInfoToInsert([]);
    }
    //Funcion para guardar la categoria
    const setCategory = (constant, value) => {
        value !== "" && getDocuments(value.value);
        setView(0);
        setInfo((prevState) => ({
            ...prevState,
            [constant]: value,
        }));
        setInfo((prevState) => ({
            ...prevState,
            Document: "",
        }));
        handleOnResetInfo();
    }
    //Funcion para guardar el documenton traer los campos del documento, las plantillas asociadas y los aprovadores
    const setDocument = (constant, value) => {
        setView(0);
        handleOnResetInfo();
        let document = "";
        value !== "" && getDocumentFieldById(value.id);
        value !== "" && getDocumentTampletById(value.id);
        value !== "" && (document = value.name)
        setInfo((prevState) => ({
            ...prevState,
            [constant]: document,
        }));
        
    }
    //Funcion para traer los documentos asociados a la categoria
    const getDocuments = (id) => {
        setLoad(true);
        dispatch(getDocumentById(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDocuments(payload.data.data);
            } else {
                setLoad(false);
            }
            setLoad(false);
        });
    }
    //Funcion para traer los campos del formulario del documento
    const getDocumentFieldById = (id) => {
        setLoad(true);
        dispatch(getDocumentField(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let documentField = payload.data.data;
                documentField.map(item => {
                    if (item.UnitTypeName.toLowerCase() ==="Unidad de Negocio") {
                        item.options = item.options.filter((element) => {
                            let ok = false;
                            if (info.countries) {
                                for (const itemOptions of info.countries) {
                                    if (itemOptions.name === element.label) {
                                        ok = true
                                    }
                                }
                                return ok;
                            }
                        }
                        );
                    }

                });
                setDocumentField(payload.data.data);
            } else {
                setLoad(false);
            }
            setLoad(false);
        });
    }
    //Funcion para traer las plantillas asociadas al documento
    const getDocumentTampletById = (id) => {
        setLoad(true);
        dispatch(getRulesAndApproverByTemplate(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDocumentsTemplates(payload.data.data);
            } else {
                setLoad(false);
            }
            setLoad(false);
        });
    }
    //Funcion para hacer los cambios de informacion en los campos de input, select .etc
    const handleOnChangeInfo = (constant, value) => {

        if (typeof value === "object") {

            setinfoFilters((prevState) => ({
                ...prevState,
                [constant.unitTypeId]: value.label,
            }))
            handleOnChange(constant, value.value);

        } else {
            constant === "description" ? (
                setNewInfoToInsert((prevState) => ({
                    ...prevState,
                    [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
                })))
                :
                handleOnChange(constant, value);
        }


    };
    const handleOnChange = (constant, value) => {

        setNewInfo((prevState) => ({
            ...prevState,
            [constant.unitTypeId]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }))
        setNewInfoToInsert((prevState) => ({
            ...prevState,
            [constant.id]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }))
    }
    //Funcion para cambiar los elementos de las tablas
    const handleOnChangeInfoTable = (constant, value, label, row) => {
        countRows[row][label.label] = value;
        setNewInfoToInsert((prevState) => ({
            ...prevState,
            [constant]: countRows,
        }))
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: countRows,
        }))
    }
    //Buscar las plantillas y filtrarlas por las reglas
    const getTemplates = () => {
        let templatesArray = [];
        for (const item of Object.keys(newInfo)) {
            for (const element of Object.keys(infoFilters)) {
                if (item === element) {
                    newInfo[`${item}`] = infoFilters[`${element}`]
                }
            }
        }

        for (const template of documentsTemplates) {
            if (template.Rulers) {
                if (template.Rulers.length === 1) {
                    for (const ruler of template.Rulers) {
                        documentField.map((item) => {
                            if (ruler.unitTypeId === item.unitTypeId) {
                                if (ruler.operation === ">") {
                                    if (ruler.endRange) {
                                        if (parseInt(newInfo[ruler.unitTypeId]) > parseInt(ruler.value)
                                            && parseInt(newInfo[ruler.unitTypeId]) < parseInt(ruler.endRange)) {
                                            templatesArray.push(template)
                                        }
                                    } else if (parseInt(newInfo[ruler.unitTypeId]) > parseInt(ruler.value)) {
                                        templatesArray.push(template)
                                    }
                                } else if (ruler.operation === "<") {
                                    if (ruler.endRange) {
                                        if (parseInt(newInfo[ruler.unitTypeId]) < parseInt(ruler.value)
                                            && parseInt(newInfo[ruler.unitTypeId]) > parseInt(ruler.endRange)) {
                                            templatesArray.push(template)
                                        }
                                    } else if (parseInt(newInfo[ruler.unitTypeId]) < parseInt(ruler.value)) {
                                        templatesArray.push(template)
                                    }
                                } else if (ruler.operation === ">=") {
                                    if (ruler.endRange) {
                                        if (parseInt(newInfo[ruler.unitTypeId]) >= parseInt(ruler.value)
                                            && parseInt(newInfo[ruler.unitTypeId]) <= parseInt(ruler.endRange)) {
                                            templatesArray.push(template)
                                        }
                                    } else if (parseInt(newInfo[ruler.unitTypeId]) >= parseInt(ruler.value)) {
                                        templatesArray.push(template)
                                    }
                                } else if (ruler.operation === "<=") {
                                    if (ruler.endRange) {
                                        if (parseInt(newInfo[ruler.unitTypeId]) <= parseInt(ruler.value)
                                            && parseInt(newInfo[ruler.unitTypeId]) >= parseInt(ruler.endRange)) {
                                            templatesArray.push(template)
                                        }
                                    } else if (parseInt(newInfo[ruler.unitTypeId]) <= parseInt(ruler.value)) {
                                        templatesArray.push(template)
                                    }
                                } else {

                                    if (newInfo[ruler.unitTypeId] === ruler.value) {
                                        templatesArray.push(template)
                                    }
                                }
                            }
                        })
                    }
                } else {
                    let tempRules = [];
                    for (const ruler of template.Rulers) {
                        documentField.map((item) => {
                            if (ruler.unitTypeId === item.unitTypeId) {
                                if (ruler.operation === ">") {
                                    if (ruler.endRange) {
                                        if (parseInt(newInfo[ruler.unitTypeId]) > parseInt(ruler.value)
                                            && parseInt(newInfo[ruler.unitTypeId]) < parseInt(ruler.endRange)) {
                                            tempRules.push(ruler);
                                        }
                                    } else if (parseInt(newInfo[ruler.unitTypeId]) > parseInt(ruler.value)) {
                                        tempRules.push(ruler);
                                    }
                                } else if (ruler.operation === "<") {
                                    if (ruler.endRange) {
                                        if (parseInt(newInfo[ruler.unitTypeId]) < parseInt(ruler.value)
                                            && parseInt(newInfo[ruler.unitTypeId]) > parseInt(ruler.endRange)) {
                                            tempRules.push(ruler);
                                        }
                                    } else if (parseInt(newInfo[ruler.unitTypeId]) < parseInt(ruler.value)) {
                                        tempRules.push(ruler);
                                    }
                                } else if (ruler.operation === ">=") {
                                    if (ruler.endRange) {
                                        if (parseInt(newInfo[ruler.unitTypeId]) >= parseInt(ruler.value)
                                            && parseInt(newInfo[ruler.unitTypeId]) <= parseInt(ruler.endRange)) {
                                            templatesArray.push(template)
                                        }
                                    } else if (parseInt(newInfo[ruler.unitTypeId]) >= parseInt(ruler.value)) {
                                        templatesArray.push(template)
                                    }
                                } else if (ruler.operation === "<=") {
                                    if (ruler.endRange) {
                                        if (parseInt(newInfo[ruler.unitTypeId]) <= parseInt(ruler.value)
                                            && parseInt(newInfo[ruler.unitTypeId]) >= parseInt(ruler.endRange)) {
                                            templatesArray.push(template)
                                        }
                                    } else if (parseInt(newInfo[ruler.unitTypeId]) <= parseInt(ruler.value)) {
                                        templatesArray.push(template)
                                    }
                                } else {
                                    if (newInfo[ruler.unitTypeId] === ruler.value) {

                                        tempRules.push(ruler);
                                    }
                                }
                            }
                            if (JSON.stringify(tempRules) === JSON.stringify(template.Rulers)) {
                                templatesArray.push(template)
                                tempRules = 0;
                            }
                        })
                    }
                }

            } else {
                if (!templatesArray.includes(template)) {
                    templatesArray.push(template);
                }
            }
        }

        setTemplate(templatesArray);
    }
    //Funcion para tomar los aprobadores de una plantilla
    const handleOnSetApprovers = (item) => {
        setInfo((prevState) => ({
            ...prevState,
            Template: item,
        }));
        let value = item.approvers
        setDisabledForm(!disabledForm);
        setApprovers(value);
    }
    //Funcion para vaciar los aprobadores y volver a hacer enabled los campos del formulario
    const handleOnDeleteApprovers = () => {
        setDisabledForm(!disabledForm);
        setApprovers([]);
    }
    //Funcion para cambiar de vista
    const setView = (item) => {
        setInfo((prevState) => ({
            ...prevState,
            view: item,
        }));
    }
    // Funcion que muestra un mensaje de confirmacion al aceptar una solicitud
    const confirmCreateRequest = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea crear la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => insertNewRequest()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, Crear"
                cancelBtnText="No, Cancelar"
                btnSize="md"
            />
        );
    }
    //Funcion para hacer el insert de la solicitud
    const insertNewRequest = () => {
        setLoad(true);
        setAlert("");
        const jsonInsert = { newInfo: newInfoToInsert, generalInfo: info, approvers: approvers, files: fileList, temporalFolderFilesId: temporalFolderFilesId };
        dispatch(createNewRequest(jsonInsert)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                history.push("/admin/finance/myRequests");
            } else {
                let message = payload.response.data.message;
                setInfo({
                    Category: "",
                    Document: "",
                    DocumentField: "",
                    Template: "",
                    view: 0,
                    countries: []
                })
                setNewInfoToInsert({});
                setApprovers([]);
                Toast.fire({
                    title: "Error",
                    html: message ? message : "Ocurrio un error al intentar crear la solicitud",
                    type: "warning",
                });
                setLoad(false);
            }
            setLoad(false);
        });
    }
    //#region Funciones para la carga de archivos
    const [state, setState] = useState({
        fileList: [],
        idFileRequest: "",
        readyToSend: false,
    });
    const { fileList } = state
    //Funcion para agregar o borrar archivos del dragger
    const handleOnFileList = (info) => {
        const { status } = info.file;
        if (status === "removed") {
            const { file: { name, uid, response: { status, payload } } } = info;
            if (status === 200) {
                const { idFile } = payload;
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            }
            dispatch(deleteFile({ id: temporalFolderFilesId, name: name })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    //deshabilitar el botón de enviar
                    if (fileList.length === 1) {
                        setState((prevState) => ({
                            ...prevState,
                            readyToSend: false,
                        }));
                    }
                }  else {
                    setLoad(false);
                }
            })
        } else {
            const { fileList, file: { status, name } } = info;
            let response = "";
            if (status === 'done') {
                const { file } = info;
                response = file["response"]
                response = response.payload.idFile;
                //habilitar el botón de enviar
                setState((prevState) => ({
                    ...prevState,
                    readyToSend: true,
                }));
            } else if (status === 'error') {
                //  message.success(`Ocurrio un error cargando el archivo ${name}.`);
            }
            setState((prevState) => ({
                ...prevState,
                idFileRequest: response,
                fileList: fileList,
            }));
        }
    }
    //Funcion para llamar el endpoint que carga los archivos para crearlos en el back
    const allowedFileTypes = [
        ".xlsx", ".xls", ".xlsb", ".xlt", ".ppt", ".pptx", ".pdf", ".doc", ".docx",
        ".rar", ".zip", ".cfr", ".csv", ".txt", ".eml", ".jpg", ".png", ".html", ".rtf", ".xml",
        ".css", ".tif", ".ods", ".msg"
    ];
    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: allowedFileTypes.join(","),
        multiple: true,
        action: `${urlBase}/finance-flows/add-files/${temporalFolderFilesId}`,
        beforeUpload: file => {
            const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
            if (!allowedFileTypes.includes(fileType)) {
                console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
                return false;
            }
            return true;
        },
        onChange: info => {
            //Filtrar la lista de archivos válidos para mantener solo los que aún existen
            const filteredFiles = info.fileList.filter(file => {
                if (file.status) {
                    return true;
                }
                return false;
            });
            info.fileList = filteredFiles; 
            // Actualizar la lista de archivos válidos
            // Llamar a la función handleOnFileList con la lista de archivos válidos
            handleOnFileList(info);
        },
    };
    //#endregion

    return (
        <div>
            <AdminHeader name="Crear Solicitud" parentName="Finance Flows" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    {alert}
                    <ShowCategories
                        info={info}
                        setLoad={setLoad}
                        setNewInfoToInsert={setNewInfoToInsert}
                        setNewInfo={setNewInfo}
                        setCategory={setCategory} />
                    {info.Category !== "" &&
                        <ShowDocuments
                            info={info}
                            documents={documents}
                            setDocument={setDocument}
                            setNewInfoToInsert={setNewInfoToInsert}
                            setNewInfo={setNewInfo}
                        />
                    }
                    {info.Document !== "" &&
                        <RequestInformation
                            disabled={false}
                            InformationTamplet={documentField}
                            handleOnChangeInfo={handleOnChangeInfo}
                            newInfoToInsert={newInfoToInsert}
                            getTamplets={getTemplates}
                            template={template}
                            approvers={approvers}
                            setApprovers={handleOnSetApprovers}
                            deleteApprovers={handleOnDeleteApprovers}
                            setView={setView}
                            info={info}
                            newInfo={newInfo}
                            handleOnChangeInfoTable={handleOnChangeInfoTable}
                            handleOnChangeCountRows={handleOnChangeCountRows}
                            countRows={countRows}
                            disabledForm={disabledForm}
                            setCountRows={setCountRows}
                            setNewInfo={setNewInfo}
                            setNewInfoToInsert={setNewInfoToInsert}
                            setGenerateRequest={setGenerateRequest}
                            generateRequest={generateRequest}


                        />
                    }
                    {
                        info.view === 1 &&
                        <FormToRequest
                            disabled={true}
                            InformationTamplet={documentField}
                            handleOnChangeInfo={handleOnChangeInfo}
                            template={template}
                            setApprovers={handleOnSetApprovers}
                            approvers={approvers}
                            newInfo={newInfo}
                            newInfoToInsert={newInfoToInsert}
                            insertNewRequest={confirmCreateRequest}
                            fileList={fileList}
                            uploadProps={uploadProps}
                        />
                    }
                </Spin>
            </Container>
        </div>
    );
};


export default NewRequest;