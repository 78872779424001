import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const targetLetterByUser = handleActions({
  [constants.GET_TARGET_LETTER_BY_USER]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.UPDATE_TARGET_LETTER_FLOW]: (state, action) => {
    const { status } = action.payload;
    if (status === 200) {
      return {};
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const targetsLetterHC = handleActions({
  [constants.GET_TARGETS_LETTER_HC]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.UPDATE_TARGET_LETTER_FLOW]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200 && Object.keys(state).length) {
      const { id } = data.payload;
      const { targetsLetter, message } = state;
      const newLetters = targetsLetter.filter((el) => el.id !== parseInt(id));
      return {
        targetsLetter: [...newLetters],
        message
      };
    }
    return state;
  },
  [constants.DEACTIVATE_TARGET_LETTER]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200 && Object.keys(state).length) {
      const { id } = data.payload;
      const { targetsLetter, message } = state;
      const newLetters = targetsLetter.filter((el) => el.id !== parseInt(id));
      return {
        targetsLetter: [...newLetters],
        message
      };
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const targetsLetterHeadShip = handleActions({
  [constants.GET_TARGETS_LETTER_HEADSHIP]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.UPDATE_TARGET_LETTER_FLOW]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200 && Object.keys(state).length) {
      const { id } = data.payload;
      const { targetsLetter, message } = state;
      const newLetters = targetsLetter.filter((el) => el.id !== parseInt(id));
      return {
        targetsLetter: [...newLetters],
        message
      };
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const targetsLetterHCRM = handleActions({
  [constants.GET_TARGETS_LETTER_HCRM]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.UPDATE_TARGET_LETTER_FLOW]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200 && Object.keys(state).length) {
      const { id } = data.payload;
      const { targetsLetter, message } = state;
      const newLetters = targetsLetter.filter((el) => el.id !== parseInt(id));
      return {
        targetsLetter: [...newLetters],
        message
      };
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const targetsLetters = handleActions({
  [constants.GET_ALL_TARGETS_LETTERS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.REJECT_TARGET_LETTER_BY_ID]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { targetsLetters, message } = state;
      const { letterRejected } = data.payload;
      const letters = targetsLetters.map((row) => {
        if (row.id === letterRejected.id) {
          return letterRejected;
        } else {
          return row;
        }
      });
      return {
        targetsLetters: letters,
        message
      };
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const myTargetsLetters = handleActions({
  [constants.GET_MY_TARGETS_LETTERS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const dashboardTargetLetter = handleActions({
  [constants.GET_ALL_DATA_DASHBOARD_TARGET_LETTERS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const targetsLetterGeneralManager = handleActions({
  [constants.GET_TARGETS_LETTER_GENERAL_MANAGER]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.UPDATE_TARGET_LETTER_FLOW]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200 && Object.keys(state).length) {
      const { id } = data.payload;
      const { targetsLetter, message } = state;
      const newLetters = targetsLetter.filter((el) => el.id !== parseInt(id));
      return {
        targetsLetter: [...newLetters],
        message
      };
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const targetsLetterManagementServicesDirector = handleActions({
  [constants.GET_TARGETS_LETTER_MANAGEMENT_SERVICES_DIRECTOR]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.UPDATE_TARGET_LETTER_FLOW]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200 && Object.keys(state).length) {
      const { id } = data.payload;
      const { targetsLetter, message } = state;
      const newLetters = targetsLetter.filter((el) => el.id !== parseInt(id));
      return {
        targetsLetter: [...newLetters],
        message
      };
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});