import React, { useState, useEffect, useRef } from "react";
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Tables from "components/UserCloudRequest/Tables";
// import Filters from "components/UserCloudRequest/Filters"
import { useDispatch } from "react-redux";
import ModalInformation from "components/UserCloudRequest/ModalInformation.jsx";
import {
  approveRequestCloud,
  getAllRequest,
  deleteApproveFile,
  declineRequestCloud,
  returnRequestCloud,
} from "actions/userCloudRequests.jsx";
import { getUsernameLogged } from "selectors/adminLayout.jsx";
import { urlBase } from "api/urls.jsx";
import { Spin } from "antd";
import NotificationAlert from "react-notification-alert";
import ModalApprove from "components/UserCloudRequest/ModalApprove";

const ApprovalsRequests = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [textConfirmation, setTextConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const actualUser = getUsernameLogged();
  const [allRequest, setAllRequest] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModalApprove, setIsOpenModalApprove] = useState(false);
  const [isOpenModalDecline, setIsOpenModalDecline] = useState(false);
  const [isOpenModalReturn, setIsOpenModalReturn] = useState(false);
  const [requestSelected, setRequestSelected] = useState({
    requestInfo: [],
  });
  const [reload, setReload] = useState(false);

  const jsonFilters = [
    { label: "Identificador" },
    { label: "Estatus" },
    { label: "Correo GBM" },
    { label: "Nombre" },
  ];
  const colums = [
    { label: "Identificador", value: "id", type: "text" },
    { label: "Estatus", value: "status", type: "text" },
    { label: "Correo GBM", value: "requesterEmail", type: "text" },
    { label: "Tipo solicitud", value: "typeRequest", type: "text" },
    { label: "Nombre", value: "nameUser", type: "text" },
    {
      label: "Detalles",
      value: "requestInfo",
      type: "button",
      method: (e) => detailsRequest(e),
      legend: "Información detallada de la solicitud",
    },
  ];

  const dispatch = useDispatch();
  const notifyEl = useRef(null);

  const notify = (type, title, message) => {
    try {
      let options = {
        place: "br",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {title}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 7,
      };
      notifyEl.current.notificationAlert(options);
    } catch (error) {}
  };

  const aditionalsButtons = [
    {
      color: "success",
      icon: "fas fa-user-check",
      function: (e) => handleOnCloseModalApprove(e),
      tool: "Aprobar",
      id: "aprovar",
    },
    {
      color: "danger",
      icon: "fas fa-user-times",
      function: (e) => handleOnCloseModalDecline(e),
      tool: "Rechazar",
      id: "rechazar",
    },
    {
      color: "warning",
      icon: "fas fa-undo",
      function: (e) => handleOnCloseModalReturn(e),
      tool: "Devolver",
      id: "devolver",
    },
  ];

  //
  const detailsRequest = (e) => {
    setRequestSelected(e);
    setIsOpen(true);
  };
  //Funcion para los filtros por columna
  const handleOnfilterDinamic = (constant, e) => {
    setFilters((prevState) => ({
      ...prevState,
      [constant]: e,
    }));
  };

  //Aplicar los filtros a la data de la tabla
  const renderRowsByFilters = (rows) => {
    if (Object.keys(filters).length === 0) {
      return rows;
    } else {
      const filterKeys = Object.keys(filters);
      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();
        rows = rows.filter(
          (item) =>
            item[element].toString().toLowerCase().indexOf(valueFiltered) != -1
        );
      }
      return rows;
    }
  };

  //#region Funciones para la carga de archivos
  const [state, setState] = useState({
    fileList: [],
    idFileRequest: "",
    readyToSend: false,
  });
  const { fileList } = state;

  //Funcion para agregar o borrar archivos del dragger
  const handleOnFileList = (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idFile } = payload;
        setState((prevState) => ({
          ...prevState,
          fileList: fileList.filter((row) => row.uid !== uid),
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          fileList: fileList.filter((row) => row.uid !== uid),
        }));
      }
      dispatch(deleteApproveFile({ id: requestSelected.id, name: name })).then(
        (resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            //deshabilitar el botón de enviar
            if (fileList.length === 1) {
              setState((prevState) => ({
                ...prevState,
                readyToSend: false,
              }));
            }
          } else {
            setLoading(false);
          }
        }
      );
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      let response = "";
      if (status === "done") {
        const { file } = info;
        response = file["response"];
        response = response.payload.idFile;
        //habilitar el botón de enviar
        setState((prevState) => ({
          ...prevState,
          readyToSend: true,
        }));
      } else if (status === "error") {
        //  message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      setState((prevState) => ({
        ...prevState,
        idFileRequest: response,
        fileList: [...fileList],
      }));
    }
  };

  //Funcion para llamar el endpoint que carga los archivos para crearlos en el back
  const uploadProps = {
    name: "file",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    accept: ".pdf",
    multiple: true,
    action: `${urlBase}/user-cloud-request/approve-files/${requestSelected.id}`,
    onChange: handleOnFileList,
  };

  //Funcion que almacena la informacion tomada del campo de texto del modal de confirmacion
  const confirmationText = (text) => {
    setTextConfirmation(text);
  };

  // funcion que aprueba la solicitud.
  const handleOnApprove = async () => {
    setLoading(true);
    const body = {
      requestId: requestSelected.id,
      approverUser: actualUser,
      comment: textConfirmation,
      files: fileList,
      email: requestSelected.requesterEmail,
      request: requestSelected,
    };
    setIsOpenModalApprove(!isOpenModalApprove);
    dispatch(approveRequestCloud(body)).then((resp) => {
      const { payload } = resp;
      if(payload.isAxiosError) {
        setLoading(false);
        notify("danger", "Error", payload.message);
        setReload(!reload);
        setRequestSelected({
          requestInfo: [],
        });
      } else {
        setLoading(false);
        notify("success", "Exito", "Se aprobó correctamente");
        setReload(!reload);
        setRequestSelected({
          requestInfo: [],
        });
      }
    });
  };

  // funcion que aprueba la solicitud.
  const handleOnDecline = async () => {
    setLoading(true);
    const body = {
      requestId: requestSelected.id,
      approverUser: actualUser,
      comment: textConfirmation,
      email: requestSelected.requesterEmail,
      request: requestSelected,
    };
    setIsOpenModalDecline(!isOpenModalDecline);
    dispatch(declineRequestCloud(body)).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        setLoading(false);
        notify("danger", "Error", payload.message);
        setReload(!reload);
        setRequestSelected({
          requestInfo: [],
        });
      } else {
        setLoading(false);
        notify("success", "Exito", "Se Rechazó correctamente");
        setReload(!reload);
        setRequestSelected({
          requestInfo: [],
        });
      }
    });
  };

  // funcion que aprueba la solicitud.
  const handleOnReturn = async () => {
    setLoading(true);
    const body = {
      requestId: requestSelected.id,
      approverUser: actualUser,
      comment: textConfirmation,
      email: requestSelected.requesterEmail,
      request: requestSelected,
    };
    setIsOpenModalReturn(!isOpenModalReturn);
    dispatch(returnRequestCloud(body)).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        setLoading(false);
        notify("danger", "Error", payload.message);
        setReload(!reload);
        setRequestSelected({
          requestInfo: [],
        });
      } else {
        setLoading(false);
        notify("success", "Exito", "Se Devolvió correctamente");
        setReload(!reload);
        setRequestSelected({
          requestInfo: [],
        });
      }
    });
  };

  const handleOnCloseModal = () => {
    setIsOpen(!isOpen);
  };

  const handleOnCloseModalApprove = (e) => {
    if (isOpenModalApprove) {
      setRequestSelected({
        requestInfo: [],
      });
      setTextConfirmation("");
    } else {
      setRequestSelected(e);
    }
    setIsOpenModalApprove(!isOpenModalApprove);
  };

  const handleOnCloseModalDecline = (e) => {
    if (isOpenModalDecline) {
      setRequestSelected({
        requestInfo: [],
      });
      setTextConfirmation("");
    } else {
      setRequestSelected(e);
    }
    setIsOpenModalDecline(!isOpenModalDecline);
  };

  const handleOnCloseModalReturn = (e) => {
    if (isOpenModalReturn) {
      setRequestSelected({
        requestInfo: [],
      });
      setTextConfirmation("");
    } else {
      setRequestSelected(e);
    }
    setIsOpenModalReturn(!isOpenModalReturn);
  };

  const validateConfirm = (textConfirmation) => {
    return textConfirmation === "";
  };

  const validateConfirmCustom = (
    textConfirmation,
    requestSelected,

  ) => {
    if (requestSelected && requestSelected.isCustom === 1) {
      return textConfirmation === "" 
    }
  };

  useEffect(() => {
    const getRequest = async () => {
      await handleOnGetRequest();
    };
    getRequest();
  }, [reload]);
  //Funcion que trae la data de los campos selects
  const handleOnGetRequest = async () => {
    setLoading(true);
    dispatch(getAllRequest()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setAllRequest(payload.data.data);
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
    setLoading(true);
  };

  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyEl} />
      </div>
      <AdminHeader name="Solicitudes" parentName="Usuarios Cloud" />
      <Spin size="large" spinning={loading}>
        <Container className="mt--6" fluid>
          <ModalInformation
            requestDetails={requestSelected.requestInfo}
            requestSelected={requestSelected}
            files ={requestSelected.requestFile}
            toggle={handleOnCloseModal}
            notify={notify}
            setLoad={setLoading}
            modal={isOpen}
            headerText={"Detalle de la solicitud"}
            buttonCancelText={"Cerrar"}
            showButtonConfirm={false}
            valueInsert={"value"}
            labelValueInsert={"formRequestDataName"}
            valueName={"formRequestDataName"}
            editing={true}
          />
          <ModalApprove
            modal={isOpenModalApprove}
            toggle={handleOnCloseModalApprove}
            title={"Aprobación de la solicitud"}
            textFirstButton={"Cerrar"}
            textSecondButton={"Aprobar"}
            actionFirstButton={handleOnCloseModalApprove}
            actionSecondButton={handleOnApprove}
            item={requestSelected}
            uploadProps={uploadProps}
            fileList={fileList}
            confirmationText={confirmationText}
            isComplete={validateConfirmCustom(
              textConfirmation,
              requestSelected
            )}
          />
          <ModalApprove
            modal={isOpenModalDecline}
            toggle={handleOnCloseModalDecline}
            title={"Rechazo de la solicitud"}
            textFirstButton={"Cerrar"}
            textSecondButton={"Rechazar"}
            actionFirstButton={handleOnCloseModalDecline}
            actionSecondButton={handleOnDecline}
            item={requestSelected}
            confirmationText={confirmationText}
            isComplete={validateConfirm(textConfirmation)}
          />
          <ModalApprove
            modal={isOpenModalReturn}
            toggle={handleOnCloseModalReturn}
            title={"Devolución de la solicitud"}
            textFirstButton={"Cerrar"}
            textSecondButton={"Devolver"}
            actionFirstButton={handleOnCloseModalReturn}
            actionSecondButton={handleOnReturn}
            item={requestSelected}
            confirmationText={confirmationText}
            isComplete={validateConfirm(textConfirmation)}
          />
          <Tables
            page={page}
            setPage={setPage}
            colums={colums}
            aditionalsButtons={aditionalsButtons}
            filters={handleOnfilterDinamic}
            rows={renderRowsByFilters(allRequest)}
            subtitle={"Solicitudes"}
            title={"Todas las solicitudes de usuarios cloud"}
            thText={"Acciones"}
          />
        </Container>
      </Spin>
    </div>
  );
};

export default ApprovalsRequests;
