// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Button, Card, CardFooter, CardHeader, Pagination, PaginationItem, PaginationLink, Row, Col, Table } from "reactstrap";

moment.locale("es");

const CardRequeriments = props => {

  const {
    title,
    data,
    columns,
    page,
    sizePerPage,
    onOpen,
    onSetPage,
  } = props;

  const paginations = data.length > sizePerPage ?
    data.length / sizePerPage > Math.round(data.length / sizePerPage) ?
      Math.round(data.length / sizePerPage) + 1
      : Math.round(data.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => onSetPage(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  return (
    <>
      <Card className="bg-default shadow">
        <CardHeader className="bg-transparent border-0">
          <h3 className="text-white mb-0">{title}</h3>
        </CardHeader>
        {
          data.length ?
            <>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    {
                      columns.map((row, key) => <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>{row}</th>)
                    }
                    <th />
                  </tr>
                </thead>
                <tbody className="list">
                  {
                    data.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {
                      return (
                        <tr key={key} onClick={() => onOpen(row)}>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            {row.id}
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            <Badge color="info">
                              {`v${row.version}`}
                            </Badge>
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            {row.opportunityNumber}
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            {row.salesRep}
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            {row.customer}
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            <Badge color="" className="badge-dot mr-4">
                              <i className={row.color} />
                              {row.status}
                            </Badge>
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            <Badge color="" className="badge-dot mr-4">
                              <i className='bg-warning' />
                              {row.criticalParts}
                            </Badge>
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            {row.businessModel}
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            {
                              moment(row.createdAt)
                                .utc()
                                .utcOffset(moment().utcOffset())
                                .format("DD/MM/YYYY H:mm A")
                            }
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            {
                              row.updatedAt ? moment(row.updatedAt)
                                .utc()
                                .utcOffset(moment().utcOffset())
                                .format("DD/MM/YYYY H:mm A") : 'N/A'
                            }
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            {row.createdBy}
                          </th>
                          <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                            <Button
                              className="btn-icon btn-3"
                              outline
                              size="sm"
                              color="info"
                              type="button"
                              onClick={() => onOpen(row)}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-folder-open" />
                              </span>
                              <span className="btn-inner--text">Abrir</span>
                            </Button>
                          </th>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Row className="align-items-center">
                    <Col xs='12' md='6' className="p-1">
                      <span className='pagination mb-0'>
                        Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > data.length ? data.length : page * sizePerPage} de {data.length} resultados
                    </span>
                    </Col>
                    <Col xs='12' md='6' className="p-1">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className={page === 1 ? "disabled" : ""}>
                          <PaginationLink
                            onClick={() => onSetPage(page === 1 ? page : page - 1)}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {
                          renderPaginations()
                        }
                        <PaginationItem className={page === paginations ? "disabled" : ""}>
                          <PaginationLink
                            onClick={() => onSetPage(page === paginations ? page : page + 1)}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </Col>
                  </Row>
                </nav>
              </CardFooter>
            </>
            :
            <div className="pt-4 pb-4">
              <h5 className="h3 title">
                <span className="d-block mb-1 text-center text-white">No hay requerimientos creados</span>
                <small className="h3 d-block font-weight-light text-center text-muted text-white">
                  Actualmente al día de hoy, no tienes requerimientos digitales creados, en el momento que exista una nueva, aparecerán en esta ventana.
                </small>
              </h5>
            </div>
        }
      </Card>
    </>
  );
};

CardRequeriments.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default CardRequeriments;
