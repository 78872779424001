/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to show a Table with all user Assigned items that can be related to an extra hours request
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormFeedback,
  FormGroup,
} from "reactstrap";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

import { MdInfoOutline } from "react-icons/md";
import { RiFileAddFill } from "react-icons/ri";
import moment from "moment";

function ModalRelateOrder({
  isOpen,
  handleClose,
  networkItemsList,
  columns,
  columnsNetwork,
  page,
  sizePerPage,
  preparePageData,
  setPage,
  onSelectType,
  isNetwork,
  title,
  subtitle,
  inputValidation,
  errors,
  reportType,
  buttonOnClick,
  isNetworkVerify,
  formatData,
  filtersLabels,
  filtersPlaceHolder,
  filtersData,
  setFilter,
  applyFilters,
  setItemsTableDate,
  onClickDateButton,
  dateFieldValue,
  idFilterValue,
  setFixedDateFlag,
  fixedDateFlag,
}) {
  let startIndex, endIndex, currentPageData;
  const dataWithFilters = applyFilters(filtersData);

  if (
    dataWithFilters.length == 0 &&
    filtersData.filter1.length == 10 &&
    fixedDateFlag == true
  ) {
    const fixedDate = moment("2017-01-01");
    setItemsTableDate(fixedDate);
  }

  if (filtersData.filter1.length == "" && dataWithFilters.length > 0) {
    setFixedDateFlag(true);
  }

  if (isNetwork) {
    const networkData = preparePageData(networkItemsList, page, sizePerPage);
    startIndex = networkData.startIndex;
    endIndex = networkData.endIndex;
    currentPageData = networkData.currentPageData;
  } else {
    const OSData = preparePageData(dataWithFilters, page, sizePerPage);
    startIndex = OSData.startIndex;
    endIndex = OSData.endIndex;
    currentPageData = OSData.currentPageData;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      size="xl"
      style={{ maxWidth: "1500px", width: "100%" }}
    >
      <ModalHeader toggle={handleClose}>
        <span className="font-weight-bold" style={{ fontSize: "1.20em" }}>
          Relaciona tu orden
        </span>
      </ModalHeader>

      <ModalBody>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h3 className="mb-0">{title}</h3>
                <p className="text-sm mb-0">{subtitle}</p>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className=" align-items-center">
              <Col xs={12} sm={4}>
                <FormGroup>
                  <Label className="font-weight-bold">Tipo de orden</Label>
                  <Input
                    id="select-typeReport"
                    type="select"
                    className="basic-multi-select text-dark"
                    style={{
                      color: "black",
                      borderColor: "#dee2e6",
                    }}
                    defaultValue={"0"}
                    onChange={(e) => {
                      onSelectType(e.target.value);
                    }}
                    invalid={errors.idReport}
                  >
                    {reportType.map((report, index) => (
                      <option key={report} value={index}>
                        {report}
                      </option>
                    ))}
                  </Input>
                  {errors.idReport && (
                    <FormFeedback style={{ color: "white" }}>.</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              {isNetwork ? (
                <>
                  <Col xs={12} sm={4}>
                    <FormGroup>
                      <Label className="font-weight-bold">
                        Digita el ID de tu Network
                      </Label>
                      <Input
                        id="text-input"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Número de ID"
                        style={{
                          color: "black",
                          borderColor: errors.idReport ? "red" : "#dee2e6",
                        }}
                        onChange={(e) => {
                          inputValidation(e.target.value);
                        }}
                        invalid={errors.idReport}
                      />
                      {errors.idReport && (
                        <FormFeedback style={{ color: "red" }}>
                          Este campo es obligatorio
                        </FormFeedback>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={2}>
                    <Button
                      className="btn-icon"
                      color="success"
                      size="sm"
                      type="button"
                      style={{
                        height: "2rem",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                        justifyContent: "center",
                      }}
                      onClick={buttonOnClick}
                    >
                      <span className="btn-inner--text">
                        <RiFileAddFill
                          style={{
                            color: "white",
                            fontSize: "15px",
                            marginRight: "2px",
                            marginBottom: "3px",
                          }}
                        />
                        Buscar items
                      </span>
                    </Button>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </CardBody>
        </Card>

        {!isNetwork && dataWithFilters.length > 0 && (
          <Card>
            <CardHeader className="border-0">
              <Row className="mt-3 mb-3">
                <Col>
                  <span className="font-weight-bold">Filtrar por:</span>
                </Col>
              </Row>
              <Row className="mt-3 mb-3">
                <Col xs={12} sm={4} style={{ marginBottom: "1rem" }}>
                  <Label
                    for="os_item"
                    className="font-weight-bold"
                    style={{ fontSize: "13px" }}
                  >
                    {filtersLabels[0]}
                  </Label>
                  <Input
                    type="text"
                    id="os_item"
                    placeholder={filtersPlaceHolder[0]}
                    autoComplete="off"
                    bsSize="sm"
                    style={{ fontSize: "0.875rem", padding: "0.25rem 0.5rem" }}
                    value={idFilterValue}
                    onChange={(e) => {
                      setFilter("filter1", e.target.value);
                    }}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <FormGroup>
                    <Label
                      for="os_item"
                      className="font-weight-bold"
                      style={{ fontSize: "13px" }}
                    >
                      {filtersLabels[1]}
                    </Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: filtersPlaceHolder[1],
                        className: "form-control form-control-sm",
                        style: {
                          fontSize: "0.875rem",
                          padding: "0.25rem 0.5rem",
                        },
                      }}
                      value={dateFieldValue}
                      timeFormat={false}
                      onChange={(date) => setItemsTableDate(date)}
                    />
                  </FormGroup>
                </Col>

                <Col
                  sm="12"
                  md="2"
                  className="d-flex justify-content align-items-center"
                >
                  <Button
                    className="btn-icon"
                    color="info"
                    size="sm"
                    type="button"
                    style={{
                      borderRadius: "10px",
                      height: "26px",
                      fontSize: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={onClickDateButton}
                  >
                    <span className="btn-inner--text">Quitar filtros</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table
              className="align-items-center table-flush"
              responsive
              striped
            >
              <thead className="thead-light">
                <tr>
                  {columns.map((column, index) => (
                    <th key={index} className=" font-weight-bold">
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="list">
                {currentPageData.map((row, key) => (
                  <tr key={key}>
                    {columns.map((column, index) => (
                      <td
                        key={index}
                        style={{
                          whiteSpace: "normal",
                          textAlign: "justify",
                          wordWrap: "break-word",
                        }}
                      >
                        {currentPageData.length
                          ? formatData(column.key, row[column.key], row)
                          : null}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}

        {!isNetwork && !dataWithFilters.length > 0 && (
          <Card>
            <CardHeader className="border-0">
              <Row className="mt-3 mb-3">
                <Col>
                  <span className="font-weight-bold">Filtrar por:</span>
                </Col>
              </Row>
              <Row className="mt-3 mb-3">
                <Col xs={12} sm={4} style={{ marginBottom: "1rem" }}>
                  <Label
                    for="os_item"
                    className="font-weight-bold"
                    style={{ fontSize: "13px" }}
                  >
                    {filtersLabels[0]}
                  </Label>
                  <Input
                    type="text"
                    id="os_item"
                    placeholder={filtersPlaceHolder[0]}
                    autoComplete="off"
                    bsSize="sm"
                    style={{ fontSize: "0.875rem", padding: "0.25rem 0.5rem" }}
                    value={idFilterValue}
                    onChange={(e) => {
                      setFilter("filter1", e.target.value);
                    }}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <FormGroup>
                    <Label
                      for="os_item"
                      className="font-weight-bold"
                      style={{ fontSize: "13px" }}
                    >
                      {filtersLabels[1]}
                    </Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: filtersPlaceHolder[1],
                        className: "form-control form-control-sm",
                        style: {
                          fontSize: "0.875rem",
                          padding: "0.25rem 0.5rem",
                        },
                      }}
                      value={dateFieldValue}
                      timeFormat={false}
                      onChange={(date) => setItemsTableDate(date)}
                    />
                  </FormGroup>
                </Col>

                <Col
                  sm="12"
                  md="2"
                  className="d-flex justify-content align-items-center"
                >
                  <Button
                    className="btn-icon"
                    color="info"
                    size="sm"
                    type="button"
                    style={{
                      borderRadius: "10px",
                      height: "26px",
                      fontSize: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={onClickDateButton}
                  >
                    <span className="btn-inner--text">Quitar filtros</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table
              className="align-items-center table-flush"
              responsive
              striped
            >
              <tbody className="list">
                <tr>
                  <td className="text-center" colSpan={columns.length}>
                    <MdInfoOutline className="mr-2" size={25} />
                    <div style={{ fontSize: "15px", marginBottom: "10px" }}>
                      No se han encontrado registros en el sistema.
                    </div>
                    <div style={{ fontSize: "15px" }}>
                      Si no puede encontrar su item asignado, por favor utilice
                      el filtro de "Items asignados desde" para extender su
                      búsqueda.
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        )}

        {!isNetwork && dataWithFilters.length > 0 && (
          <ModalFooter>
            <Row className="align-items-center">
              <Col xs="12" md="6" className="p-1">
                <span className="pagination mb-0">
                  Mostrando del {startIndex + 1} al{" "}
                  {endIndex > dataWithFilters.length
                    ? dataWithFilters.length
                    : endIndex}{" "}
                  de {dataWithFilters.length} resultados
                </span>
              </Col>
              <Col xs="12" md="6" className="p-1 d-flex justify-content-md-end">
                <Pagination
                  className="pagination mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => setPage(page === 1 ? page : page - 1)}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {Array.from({
                    length: Math.ceil(dataWithFilters.length / sizePerPage),
                  }).map((item, index) => (
                    <PaginationItem key={index} active={page === index + 1}>
                      <PaginationLink onClick={() => setPage(index + 1)}>
                        {index + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    className={
                      page === Math.ceil(dataWithFilters.length / sizePerPage)
                        ? "disabled"
                        : ""
                    }
                  >
                    <PaginationLink
                      onClick={() =>
                        setPage(
                          page ===
                            Math.ceil(dataWithFilters.length / sizePerPage)
                            ? page
                            : page + 1
                        )
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12" className="text-center">
                <Button
                  style={{
                    backgroundColor: "#1275bc",
                    color: "white",
                    marginLeft: "0.5rem",
                  }}
                  type="button"
                  onClick={handleClose}
                >
                  Cerrar
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        )}

        {isNetworkVerify && networkItemsList.length > 0 && (
          <Table className="align-items-center table-flush" responsive striped>
            <thead className="thead-light">
              <tr>
                {columnsNetwork.map((column, index) => (
                  <th key={index} className=" font-weight-bold">
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="list">
              {currentPageData.map((row, key) => (
                <tr key={key}>
                  {columnsNetwork.map((column, index) => (
                    <td
                      key={index}
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify",
                        wordWrap: "break-word",
                      }}
                    >
                      {currentPageData.length
                        ? formatData(column.key, row[column.key], row)
                        : null}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </ModalBody>

      {isNetworkVerify && !networkItemsList.length > 0 && (
        <Card>
          <Table className="align-items-center table-flush" responsive striped>
            <tbody className="list">
              <tr>
                <td className="text-center" colSpan={columnsNetwork.length}>
                  <MdInfoOutline className="mr-2" size={25} />
                  <span style={{ fontSize: "15px" }}>
                    No se han encontrado registros en el sistema
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      )}

      {isNetworkVerify && networkItemsList.length > 0 && (
        <ModalFooter>
          <Row className="align-items-center">
            <Col xs="12" md="6" className="p-1">
              <span className="pagination mb-0">
                Mostrando del {startIndex + 1} al{" "}
                {endIndex > networkItemsList.length
                  ? networkItemsList.length
                  : endIndex}{" "}
                de {networkItemsList.length} resultados
              </span>
            </Col>
            <Col xs="12" md="6" className="p-1 d-flex justify-content-md-end">
              <Pagination
                className="pagination mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={page === 1 ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => setPage(page === 1 ? page : page - 1)}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {Array.from({
                  length: Math.ceil(networkItemsList.length / sizePerPage),
                }).map((item, index) => (
                  <PaginationItem key={index} active={page === index + 1}>
                    <PaginationLink onClick={() => setPage(index + 1)}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem
                  className={
                    page === Math.ceil(networkItemsList.length / sizePerPage)
                      ? "disabled"
                      : ""
                  }
                >
                  <PaginationLink
                    onClick={() =>
                      setPage(
                        page ===
                          Math.ceil(networkItemsList.length / sizePerPage)
                          ? page
                          : page + 1
                      )
                    }
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="12" className="text-center">
              <Button
                style={{
                  backgroundColor: "#1275bc",
                  color: "white",
                  marginLeft: "0.5rem",
                }}
                type="button"
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      )}
    </Modal>
  );
}

export default ModalRelateOrder;
