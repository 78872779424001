import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// react library
import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";

import moment from "moment";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  Badge,
  Modal,
} from "reactstrap";
import { orderDetails } from "actions/SOActions.jsx";

import { orderDetailsSelector } from "selectors/SO.jsx";

//MEJORAS
import { Spin } from "antd";

class ServiceOrdersDetails extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      orderDetailsData: {},
      urlID: match.params.id,
      loading: false,
      errorMessage: null,
      error: false,
    };
  }

  componentDidMount() {
    this.setState((state) => ({
      loading: !state.loading,
    }));

    if (this.state.urlID) {
      const id = this.state.urlID;
      this.props.orderDetails(id).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.setState({ error: true });
            this.setState({
              errorMessage: (
                <Modal
                  className="modal-dialog-centered modal-danger"
                  contentClassName="bg-gradient-danger"
                  isOpen={this.state.error}
                >
                  <div className="modal-header"></div>
                  <div className="modal-body">
                    <div className="py-3 text-center">
                      <i className="ni ni-bell-55 ni-3x" />
                      <h4 className="heading mt-4">
                        Lo sentimos,{" "}
                        {res.payload.response.status === 404
                          ? "No podemos encontrar la Boleta de Servicio que buscas."
                          : "No podemos mostrarte la Boleta de Servicio seleccionada."}
                      </h4>
                      {res.payload.response.status !== 404 && (
                        <p>Mensaje del sistema: {payload.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button
                      className="btn-white"
                      color="default"
                      type="button"
                      onClick={this.props.history.goBack}
                    >
                      Regresar
                    </Button>
                  </div>
                </Modal>
              ),
            });
            this.setState((state) => ({
              loading: !state.loading,
            }));
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
            this.setState((state) => ({
              loading: !state.loading,
            }));
          }
        } else {
          this.setState((state) => ({
            loading: !state.loading,
          }));
        }
      });
    } else {
      this.props.history.goBack();
    }
  }

  handleOnSelect = () => {};

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnCLick = () => {};

  render() {
    const { orderDetailsData } = this.props;
    let { loading, error, errorMessage } = this.state;
    const tz = moment().utcOffset();
    let notifications = [];
    let devices = [];
    if (orderDetailsData.id) {
      orderDetailsData.timeline.forEach((activity) => {
        notifications.push(
          <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
            key={activity.id}
          >
            <div className="timeline-block">
              <span className="timeline-step badge-primary">
                <i className={activity.icon} />
              </span>
              <div className="timeline-content">
                <small className="text-light font-weight-bold">
                  {activity.formatedDateTime}
                </small>
                <h5 className="text-white mt-3 mb-0">{activity.description}</h5>
                {/* <p className="text-light text-sm mt-1 mb-0">
                  Nullam id dolor id nibh ultricies vehicula ut id elit. Cum
                  sociis natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus.
                </p> */}
                <div className="mt-3">
                  <Badge color={activity.color} pill>
                    {activity.statusName}
                  </Badge>
                </div>
              </div>
            </div>
          </div>
        );
      });

      orderDetailsData.devices.forEach((device) => {
        var replacements = [];

        device.replacements.forEach((replacement) => {
          replacements.push(
            <li key={replacement.id}>
              Part Number: {replacement.partNumber} (Qty: {replacement.quantity}
              )
            </li>
          );
        });
        if (replacements.length === 0) {
          replacements.push(<li key={device.id}>Repuestos no ingresados</li>);
        }

        devices.push(
          <ListGroupItem className="px-0" key={device.id}>
            <Row className="align-items-center">
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                  <i className="fa fa-check" />
                </div>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">{device.serial}</h4>
                <small>Material: {device.material}</small>
              </div>
              <Col className="col-auto" id={device.id}>
                <Card>
                  <CardBody>
                    <ListGroup className="list my--3" flush>
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h5 className="heading-small text-muted mb-4">
                              Repuestos:
                            </h5>
                            <ul>{replacements}</ul>
                          </div>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ListGroupItem>
        );
      });
    }

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Boleta de Servicio Digital" parentName="Detalles" />
        <Spin style={{ height: "50vh" }} size="large" spinning={loading}>
          <Container className="mt--6" fluid>
            {orderDetailsData.id && (
              <Row>
                <Col className="order-xl-1" xl="8">
                  <Row>
                    <Col lg="6">
                      <Card className="bg-gradient-success border-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                className="text-uppercase text-muted mb-0 text-white"
                                tag="h5"
                              >
                                Estado Actual
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0 text-white">
                                {orderDetailsData.statusName}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                <i className="fa fa-calendar-check" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="bg-gradient-danger border-0" tag="h5">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                Tiempo Total
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0 text-white">
                                {orderDetailsData.totalTime}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                <i className="fa fa-clock" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Información de Boleta </h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button
                            color="primary"
                            href={`https://se-api.gbm.net/service-order/${orderDetailsData.id}/download/timezone/${tz}`}
                            size="sm"
                          >
                            Descargar
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-os"
                                >
                                  Orden de Servicio
                                </label>
                                <Input
                                  disabled
                                  defaultValue={orderDetailsData.os}
                                  id="input-os"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-item"
                                >
                                  Item
                                </label>
                                <Input
                                  disabled
                                  defaultValue={orderDetailsData.item}
                                  id="input-item"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-ticket"
                                >
                                  Ticket
                                </label>
                                <Input
                                  disabled
                                  defaultValue={orderDetailsData.ticket}
                                  id="input-ticket"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label">
                                  Detalles de Trabajos
                                </label>
                                {orderDetailsData.ticketInfo.ORDER_DETAIL ? (
                                  <Input
                                    value={
                                      orderDetailsData.ticketInfo.ORDER_DETAIL
                                        .item[0].DESCRIPTION || ""
                                    }
                                    rows="3"
                                    type="textarea"
                                    disabled
                                    id="input-details"
                                  />
                                ) : (
                                  <Input
                                    value={"No especificado."}
                                    rows="3"
                                    type="textarea"
                                    disabled
                                    id="input-details"
                                  />
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />

                        <h6 className="heading-small text-muted mb-4">
                          Tiempo de Atención
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-date-from"
                                >
                                  Desde
                                </label>
                                <Input
                                  id="input-date-from"
                                  type="text"
                                  disabled
                                  defaultValue={
                                    orderDetailsData.formatedOffsetTime
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-date-to"
                                >
                                  Hasta
                                </label>
                                <Input
                                  id="input-date-to"
                                  type="text"
                                  disabled
                                  defaultValue={
                                    orderDetailsData.formatedOffsetEndTime
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />

                        <h6 className="heading-small text-muted mb-4">
                          Informacion de Servicio
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="8">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Ingeniero Asignado
                                </label>
                                <Input
                                  disabled
                                  defaultValue={orderDetailsData.assigned.name}
                                  id="input-username"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email
                                </label>
                                <Input
                                  disabled
                                  id="input-email"
                                  type="text"
                                  defaultValue={orderDetailsData.assigned.email}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label">
                                  Actividades Realizadas:
                                </label>
                                <Input
                                  id="activities"
                                  rows="3"
                                  type="textarea"
                                  disabled
                                  value={orderDetailsData.activities || ""}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label">
                                  Comentario de Ingeniero:
                                </label>
                                <Input
                                  id="comments"
                                  value={orderDetailsData.comments || ""}
                                  rows="3"
                                  type="textarea"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-distance"
                                >
                                  Distancia Recorrida
                                </label>
                                <Input
                                  id="input-distance"
                                  type="text"
                                  disabled
                                  defaultValue={orderDetailsData.distance}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-travel"
                                >
                                  Tiempo de Viaje
                                </label>
                                <Input
                                  id="input-travel"
                                  type="text"
                                  disabled
                                  defaultValue={orderDetailsData.travel}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>

                  {devices.length === 0 ? null : (
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Equipos Atendidos</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <ListGroup className="list my--3" flush>
                          {devices}
                        </ListGroup>
                      </CardBody>
                    </Card>
                  )}
                </Col>
                <Col className="order-xl-2" xl="4">
                  <Col>
                    <Card className="bg-gradient-default shadow">
                      <CardHeader className="bg-transparent">
                        <h3 className="mb-0 text-white">
                          Timeline de Actividades
                        </h3>
                      </CardHeader>
                      <CardBody>{notifications}</CardBody>
                    </Card>
                  </Col>
                </Col>
              </Row>
            )}
            {error && errorMessage}
          </Container>
        </Spin>
      </>
    );
  }
}

ServiceOrdersDetails.defaultProps = {
  orderDetailsData: {},
};

const mapStateToProps = (state) => ({
  orderDetailsData: orderDetailsSelector(state),
});

export default withRouter(
  connect(mapStateToProps, { orderDetails })(ServiceOrdersDetails)
);
