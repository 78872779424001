// javascript plugin
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Badge, Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Row } from "reactstrap";

const UserCorrectionView = props => {

  const {
    title,
    loading,
    message,
    values,
    logs,
    options,
    onUpdate,
  } = props;

  const validate = values => {
    const errors = {};
    if ((!values.totalDays || values.totalDays === 0) && values.frequency === 1)
      errors.totalDays = "Debes ingresar la cantidad de días";
    if (!values.frequency || values.frequency === "0")
      errors.frequency = "Debes seleccionar al menos una opción";
    if (!values.model || values.model === "0")
      errors.model = "Debes seleccionar al menos una opción";
    if (!values.direction || values.direction === "")
      errors.direction = "Debes ingresar una dirección";
    if ((!values.monday && !values.tuesday && !values.thursday && !values.wednesday && !values.friday) && values.frequency === "1")
      errors.direction = "Debes seleccionar al menos un día";
    return errors;
  };

  return (
    <Spin size="large" spinning={loading} tip={message}>
      <Row>
        <Col className="order-xl-2" xl='4'>
          <Row>
            <Col>
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <h4 className="surtitle text-white text-center">Actividades</h4>
                </CardHeader>
                <CardBody>
                  <div
                    className="timeline timeline-one-side"
                    data-timeline-axis-style="dashed"
                    data-timeline-content="axis"
                  >
                    {
                      logs.map((row, key) => (
                        <div className="timeline-block" key={key}>
                          <span className={`timeline-step badge-${row.color}`}>
                            <i className={row.icon} />
                          </span>
                          <div className="timeline-content">
                            <div className="d-flex justify-content-between pt-1">
                              <div>
                                <span className="text-muted text-sm text-light font-weight-bold">
                                  {row.description}
                                </span>
                              </div>
                              <div>
                                <small className="text-light font-weight-bold">
                                  <i className="fas fa-clock mr-1" />{row.createdAt}
                                </small>
                              </div>
                            </div>
                            <h5 className="mt-3 mb-0 text-white">{row.comments}</h5>
                            <div className="mt-3">
                              <Badge color={row.color} pill>
                                {row.action}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="order-xl-1" xl='8'>
          <Row>
            <Col>
              <Card className="mb-4">
                <CardHeader>
                  <div className="text-muted text-center mt-2 mb-2">
                    <h6 className="surtitle">{title}</h6>
                    <h5 className="h3 mb-0">{values ? values.document : ''}</h5>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-3">
                  <Form
                    onSubmit={onUpdate}
                    validate={validate}
                    initialValues={values}
                    render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <FormRS role="form">
                        <Row>
                          <Col xs="12" md={parseInt(values.frequency) === 1 ? "4" : "6"}>
                            <FormGroup className="mb-1">
                              <Label className="form-control-label" for="frequency">
                                Frecuencia del Home Office
                              </Label>
                              <Field name="frequency">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="select"
                                      invalid={meta.error && meta.touched}
                                    >
                                      <option key="0" value="0">Seleccione una opción</option>
                                      {
                                        options.frequencies.map((row, key) => (
                                          <option key={key} value={row.id}>{row.name}</option>
                                        ))
                                      }
                                    </Input>
                                    {
                                      meta.error && <span className="invalid-feedback">{meta.error}</span>
                                    }
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          {
                            parseInt(values.frequency) === 1 &&
                            <Col xs="12" md="4">
                              <FormGroup className="mb-1">
                                <Label className="form-control-label" for="totalDays">
                                  Cantidad de días de Home Office
                                </Label>
                                <Field name="totalDays">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="number"
                                        max={5}
                                        min={1}
                                        invalid={meta.error && meta.touched}
                                      />
                                      {
                                        meta.error && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          }
                          <Col xs="12" md={parseInt(values.frequency) === 1 ? "4" : "6"}>
                            <FormGroup className="mb-1">
                              <Label className="form-control-label" for="model">
                                Modelo de Home Office
                              </Label>
                              <Field name="model">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="select"
                                      disabled={parseInt(values.frequency) > 1}
                                      invalid={meta.error && meta.touched}
                                      value={parseInt(values.frequency) > 1 ? 6 : values.model}
                                    >
                                      <option key="0" value="0">Seleccione una opción</option>
                                      {
                                        options.models.map((row, key) => (
                                          <option key={key} value={row.id}>{row.name}</option>
                                        ))
                                      }
                                    </Input>
                                    {
                                      meta.error && <span className="invalid-feedback">{meta.error}</span>
                                    }
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </Row>
                        {
                          (parseInt(values.frequency) > 1 || parseInt(values.model) === 6) &&
                          <FormGroup className="mb-1">
                            <Label className="form-control-label" for="modelDetail">
                              Detalle del Modelo
                            </Label>
                            <Field name="modelDetail">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="textarea"
                                    maxLength={500}
                                    rows="2"
                                    resize="none"
                                    invalid={meta.error && meta.touched}
                                  />
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        }
                        {
                          options.days && parseInt(values.frequency) === 1 &&
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="amountDays"
                            >
                              Días en la modalidad de Home Office
                            </Label>
                            <Row>
                              {
                                options.days.map((row, key) => (
                                  <Col xs="6" md="4" key={key}>
                                    <FormGroup check>
                                      <Field name={row.key} type="checkbox">
                                        {({ input, meta }) => (
                                          <Label>
                                            <Input
                                              {...input}
                                              type="checkbox"
                                              invalid={meta.error && meta.touched}
                                            />{" "}
                                            {row.name}
                                          </Label>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>
                                ))
                              }
                            </Row>
                          </FormGroup>
                        }
                        <FormGroup className="mb-1">
                          <Label className="form-control-label" for="direction">
                            Domicilio donde realizara el Home Office
                            <p style={{ color: "red" }}>*(Detalle claramente sobre la distribución político-geográfica: Provincia, cantón, distrito, delegación, departamento,
                              estado, municipio, corregimiento, zona, barrio y señas extras según corresponda</p>
                          </Label>
                          <Field name="direction">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  maxLength={500}
                                  rows="3"
                                  resize="none"
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                        <div className="d-flex align-items-center justify-content-center text-center">
                          <Button
                            disabled={validating || submitting}
                            className="my-4"
                            color="success"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Actualizar
                          </Button>
                        </div>
                      </FormRS>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

UserCorrectionView.propTypes = {
  title: PropTypes.string.isRequired,
};

export default UserCorrectionView;