import React from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
import lifecycle from "react-pure-lifecycle";
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { PropTypes } from "prop-types";

// core components

import { chartOptions, parseOptions } from "variables/charts.jsx";

const methods = {
  componentWillMount(props) {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
};

const ExtraHoursChart = props => {
  var manageDataXaxis = () => {
    return [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Oct",
      "Nov",
      "Dic"
    ];
  };

  var manageDataApproved = () => {
    var arrayApproved = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 1; i < 13; i++) {
      for (var j = 0; j < props.data.length; j++) {
        if (props.data[j].month === i && props.data[j].approved != null) {
          arrayApproved[i - 1] = arrayApproved[i - 1] + props.data[j].approved;
        }
      }
    }
    return arrayApproved;
  };

  var manageDataDenied = () => {
    var arrayDenied = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 1; i < 13; i++) {
      for (var j = 0; j < props.data.length; j++) {
        if (props.data[j].month === i && props.data[j].denied != null) {
          arrayDenied[i - 1] = arrayDenied[i - 1] + props.data[j].denied;
        }
      }
    }
    return arrayDenied;
  };

  var manageDataWait = () => {
    var arrayWait = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 1; i < 13; i++) {
      for (var j = 0; j < props.data.length; j++) {
        if (props.data[j].month === i && props.data[j].wait != null) {
          arrayWait[i - 1] = arrayWait[i - 1] + props.data[j].wait;
        }
      }
    }
    return arrayWait;
  };

  var chart = {
    options: {
      tooltips: {
        mode: "index",
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: false,
            categoryPercentage: 0.5
          }
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              callback: function(value) {
                if (!(value % 1)) {
                  return value + "hrs";
                }
              }
            }
          }
        ]
      }
    },
    data: {
      labels: manageDataXaxis(),
      datasets: [
        {
          label: "Aprobadas",
          backgroundColor: "#32CD32",
          data: manageDataApproved()
        },
        {
          label: "Rechazadas",
          backgroundColor: "#FF0000",
          data: manageDataDenied()
        },
        {
          label: "En espera",
          backgroundColor: "#FFFF00",
          data: manageDataWait()
        }
      ]
    }
  };
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h6 className="surtitle">{props.status}</h6>
              <h5 className="h3 mb-0">
                Horas aprobadas y rechazadas por colaborador o supervisor
              </h5>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Bar
                  data={chart.data}
                  options={chart.options}
                  className="chart-canvas"
                  id="chart-bars"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

ExtraHoursChart.propTypes = {
  data: PropTypes.array.isRequired
};
export default lifecycle(methods)(ExtraHoursChart);
