/*!

=========================================================
*MasterData - PermissionsItems
=========================================================

*Componente para desplegar cada uno de los permisos en el dropdown 
de permisos.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//#region Imports de librerías externas.

//Imports del Framework de React.
import React, { useContext } from "react";

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de la Librería raíz Reactstrap.
import {
    Col,
    Row,
    Form
} from "reactstrap";

// react plugin for creating notifications over the dashboard
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Typeahead } from "react-bootstrap-typeahead";

//#endregion

//#region Imports de componentes internos.
import { PermissionItem } from "./PermissionItem";
//#endregion


export const PermissionsItems = ({ permissionsRequests, setPermissionsRequests }) => {

    //#region States Globales
    const {
        options,
        employees

    } = useContext(UserContext);
    //#endregion




    //Extracción de librería swal.
    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });



    return (
        <>



            <Row>

                    <Col sm="12">
                        <Form noValidate className="formInfo needs-validation">

                            {permissionsRequests.map((permission, keyPermission) =>


                                <PermissionItem
                                    key={permission.id + "pi"}
                                    permission={permission}
                                    keyPermission={keyPermission}
                                    permissionsRequests={permissionsRequests}
                                    setPermissionsRequests={setPermissionsRequests}

                                />
                            )}
                        </Form>

                    </Col>
            </Row>
        </>

    )
}
