// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const Transactions = props => {

  const { data } = props;

  return (
    <div className="header-body">
      <Row>
        <Col sm="12" md="6" xl="3">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="8">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Expedientes Médicos
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {data.records}
                  </span>
                </Col>
                <Col xs="4" className="col-auto">
                  <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                    <i className="fas fa-clipboard-list" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.recordsMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6" xl="3">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="8">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Vacunas Covid-19
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {data.vaccines}
                  </span>
                </Col>
                <Col xs="4" className="col-auto">
                  <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow">
                    <i className="fas fa-clipboard-list" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.vaccinesMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6" xl="3">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="8">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Enfermedades Registradas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {data.diseases}
                  </span>
                </Col>
                <Col xs="4" className="col-auto">
                  <div className="icon icon-shape bg-gradient-danger text-white rounded-circle shadow">
                    <i class="fas fa-clipboard-list" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.diseasesMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6" xl="3">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="8" className="pr-0">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Intervenciones Registradas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {data.interventions}
                  </span>
                </Col>
                <Col xs="4" className="col-auto">
                  <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                    <i className="fas fa-clipboard-list" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.interventionsMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Transactions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Transactions;