import { Col, FormGroup, Input, Row, InputGroup, InputGroupAddon, Button } from "reactstrap";
// react-select library for filters
import Select from "react-select";
//Hook
import React, { useState, useEffect } from 'react';

const PersonalDataForm = ({
    data,
    row,
    handleOnChange,
    setUpdateInfo,
    dropDownOptions
}) => {

    const yesAndNo = [
        {
            value: "",
            label: "",
        },
        {
            value: "1",
            label: "Sí",
        },
        {
            value: "2",
            label: "No",
        }
    ];
    
    useEffect(() => {
        if(dropDownOptions){
            dropDownOptions["yAndN"] = yesAndNo;
        }
        
    }, [])
    //inputs y elementos del formulario
    const [fields, setFields] = useState([
        {
            colWidth: "4",
            label: "Identificación",
            placeholder: "Identificación",
            id: "identification",
            defaultValue: "identification",
            type: "text",
            min: 0,
            max: 1999999999,
            elementType: "Input",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            label: "Primer nombre",
            placeholder: "Primer nombre",
            id: "firstName",
            defaultValue: "firstName",
            type: "text",
            min: 0,
            max: 1999999999,
            elementType: "Input",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            label: "Segundo Nombre",
            placeholder: "Segundo Nombre",
            id: "secondName",
            defaultValue: "secondName",
            type: "text",
            min: 0,
            max: 1999999999,
            elementType: "Input",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            label: "Primer Apellido",
            placeholder: "Primer Apellido",
            id: "firstSurname",
            defaultValue: "firstSurname",
            type: "text",
            min: 0,
            max: 1999999999,
            elementType: "Input",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            label: "Segundo Apellido",
            placeholder: "Segundo Apellido",
            id: "secondSurname",
            defaultValue: "secondSurname",
            type: "text",
            min: 0,
            max: 1999999999,
            elementType: "Input",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            id: "civilStatus",
            defaultValue: "civilStatus",
            placeholder: "Estado Civil",
            type: "select",
            min: 0,
            max: 1999999999,
            label: "Estado Civil",
            elementType: "select",
            disabled: false,
            options: "CivilStatus",
            value: "CivilStatus",
            defaultValueLabel: "civilStatusName",
            defaultValueValue: "civilStatus",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "Genero",
            placeholder: "Genero",
            id: "gender",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "gender",
            disabled: false,
            options: "Gender",
            value: "Gender",
            defaultValueLabel: "genderName",
            defaultValueValue: "gender",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "Nacionalidad",
            placeholder: "Nacionalidad",
            id: "nationality",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "nationality",
            disabled: false,
            options: "Nationality",
            value: "Nationality",
            defaultValueLabel: "nationalityName",
            defaultValueValue: "nationality",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Fecha de nacimiento",
            placeholder: "Fecha de nacimiento",
            id: "birthDate",
            type: "date",
            min: 0,
            max: 1999999999,
            defaultValue: "bDate",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Dirección de domicilio",
            placeholder: "Dirección de domicilio",
            id: "address",
            type: "textarea",
            min: 0,
            max: 1999999999,
            defaultValue: "address",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Número Teléfono Casa",
            placeholder: "Número Teléfono Casa",
            id: "telephone",
            type: "number",
            min: 0,
            max: 1999999999,
            defaultValue: "telephone",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Número Teléfono Celular",
            placeholder: "Número Teléfono Celular",
            id: "cellPhone",
            type: "number",
            min: 0,
            max: 1999999999,
            defaultValue: "cellPhone",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "Disponibilidad para viajar",
            placeholder: "Disponibilidad para viajar",
            id: "dispTravel",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "dispTravel",
            disabled: false,
            options: "yAndN",
            value: "yAndN",
            defaultValueLabel:  "dispTravelName",
            defaultValueValue: "dispTravel",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "Disponibilidad reubicacion",
            placeholder: "Disponibilidad reubicacion",
            id: "dispRelocation",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "dispRelocation",
            disabled: false,
            options: "yAndN",
            value: "yAndN",
            defaultValueLabel:  "dispRelocationName",
            defaultValueValue: "dispRelocation",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "Disponibilidad ingreso",
            placeholder: "Disponibilidad ingreso",
            id: "dispEntry",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "dispEntry",
            disabled: false,
            options: "EntryAvailability",
            value: "EntryAvailability",
            defaultValueLabel: "availabilityName",
            defaultValueValue: "dispEntry",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "Aspiración Salarial",
            placeholder: "Aspiración Salarial",
            id: "wageAspiration",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "wageAspiration",
            disabled: false,
            options: "WageAspiration",
            value: "WageAspiration",
            defaultValueLabel: "aspirationName",
            defaultValueValue: "wageAspiration",
        }
    ]);
//yesAndNo.find(el => el.value === data["dispTravel"]).label
    return (
        <>
            <Row className="align-items-center">
                {
                    fields.map((field, key) => (
                        <Col xs={field.colWidth} key={key}>
                            <FormGroup key={key} >
                                <label className="form-control-label">{field.label}</label>
                                {
                                    field.elementType === "Input" ?
                                        <Input
                                            key={field.id}
                                            id={field.id}
                                            name={field.id}
                                            className="form-control"
                                            type={field.type}
                                            min={field.min}
                                            max={field.max}
                                            readOnly={field.disabled}
                                            defaultValue={data[field.defaultValue]}
                                            placeholder={field.placeholder}
                                            onChange={(e) => handleOnChange("CandidatePersonalData", field.id, e.target.value)}
                                        />
                                        :
                                        <Select
                                            key={field.id}
                                            id={field.id}
                                            name={field.id}
                                            className="basic-single"
                                            type={field.type}
                                            placeholder={field.placeholder}
                                            isSearchable
                                            isDisabled={field.disabled}
                                            options={dropDownOptions[field.options]}
                                            defaultValue={{ label: data[field.defaultValueLabel], value: data[field.defaultValueValue] }}
                                            onChange={(e) => handleOnChange("CandidatePersonalData", field.id, e.value)}
                                        />
                                }
                            </FormGroup>
                        </Col>
                    ))
                }
            </Row>
        </>
    );
};

export default PersonalDataForm;