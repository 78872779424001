// core antd
import { Spin } from "antd";
// core actions
import {
  findAllRequestsAssignment,
  findEquipmentsAssignmentByRequest,
  validateGbmCollaborator,
  createUserAssignmentByPlanner,
  createUserAssignmentWithEquipmentsByPlanner,
  findUserAssignmentByRequest,
  findAllPartsEquipmentsByRequest,
} from "actions/Sales/plannersFlow.jsx";
import { findAllDataByDigitalRequest } from "actions/Sales/managers.jsx";
import { updateActivityFlow } from "actions/Sales/digitalRequest.jsx";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// core components Planners
import CardConfigurations from "components/Sales/PlannersFlow/CardConfigurations.jsx";
import Filters from "components/Sales/PlannersFlow/Filters.jsx";
import EquipmentsList from "components/Sales/PlannersFlow/EquipmentsList.jsx";
import Requests from "components/Sales/PlannersFlow/Requests.jsx";
import RequestDetail from "components/Sales/PlannersFlow/RequestDetail.jsx";
import WorkFlow from "components/Sales/PlannersFlow/WorkFlow.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selector
import {
  getAllRequirementsPlanners,
  getEquipmentsByRequest,
  getFiltersOptions,
  getEquipmentsJTRByRequest,
  getFiltersOptionsEquipments,
  getUserAssignmentByRequest,
  getAllPartsEquipmentsByRequest,
  getAllDataRequirementsPlanners,
} from "selectors/Sales/plannersFlow";
import {
  getActivitiesFlowByRequest,
  getEquipmenstByRequest,
  getConfigurationsByRequest,
} from "selectors/Sales/managers.jsx";

class AssignmentManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      request: null,
      equipmentsView: false,
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
        country: {
          value: 0,
          label: "Todos",
        },
      },
      filtersEquipmentsValues: {
        platform: {
          value: 0,
          label: "Todos",
        },
        engineer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
      },
      showModal: false,
      infoUser: {},
      disabled: true,
      initialValues: {},
      assignUser: false,
      equipmentSelected: [],
      configurationsView: false,
    };
  }

  componentDidMount() {
    this.props.findAllRequestsAssignment().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState((state) => ({
        willMount: !state.willMount,
      }));
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnClearFilters = () => {
    this.setState({
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
        country: {
          value: 0,
          label: "Todos",
        },
      },
    });
  };

  handleOnClearFiltersEquipments = () => {
    this.setState({
      filtersEquipmentsValues: {
        platform: {
          value: 0,
          label: "Todos",
        },
        engineer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
      },
    });
  };

  handleOnChangeFilters = (key, event) => {
    const { filtersValues } = this.state;
    filtersValues[key] = event;
    this.setState({
      filtersValues,
      page: 1,
    });
  };

  handleOnChangeFiltersEquipments = (key, event) => {
    const { filtersEquipmentsValues } = this.state;
    filtersEquipmentsValues[key] = event;
    this.setState({
      filtersEquipmentsValues,
      page: 1,
    });
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  handleOnOpenRequestSelected = async (row) => {
    const { id } = row;
    this.setState({
      loading: true,
      message: `Cargando la información del requerimiento`,
    });
    this.props.findAllDataByDigitalRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.props.findUserAssignmentByRequest(id);
        this.props.findAllPartsEquipmentsByRequest(id);
        this.props.findEquipmentsAssignmentByRequest(id);
        this.setState({
          request: row,
        });
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnBackMainView = () => {
    this.setState({
      request: null,
    });
  };

  handleOnViewEquipments = () => {
    const {
      request: { id },
    } = this.state;
    this.setState({
      loading: true,
      message: `Cargando los equipos agregados al requerimiento seleccionado`,
    });
    this.props.findEquipmentsAssignmentByRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          equipmentsView: true,
        });
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnBackEquipmentsView = () => {
    this.setState({
      equipmentsView: false,
      assignUser: false,
      equipmentSelected: [],
    });
  };

  handleOnBackConfigurationsView = () => {
    this.setState({
      configurationsView: false,
    });
  };

  handleOnViewConfigurations = () => {
    this.setState({
      configurationsView: true,
    });
  };

  renderRowsFiltered = (rows) => {
    console.log(rows);
    const {
      filtersValues: { customer, status, opp, country },
    } = this.state;
    const { dataAssigned } = this.props;
    if (customer.value !== 0) {
      rows = rows.filter((row) => row.createdBy === customer.label);
    }
    if (status.value !== 0) {
      const rowsFiltered = [];
      for (const element of rows) {
        if (
          !dataAssigned.some((ele) => ele.id === element.id) &&
          element.state === 6
        ) {
          rowsFiltered.push(element);
        } else {
          if (element.state === 6) {
            let contAssigned = 0;
            let equipments = 0;
            for (const ele of dataAssigned) {
              if (ele.id === element.id) {
                equipments = parseInt(ele.equipments);
                contAssigned = contAssigned + parseInt(ele.equipAssigned);
              }
            }
            if (
              contAssigned != parseInt(equipments) &&
              status.label === "Pendiente de Asignación"
            ) {
              rowsFiltered.push(element);
            } else if (
              contAssigned === parseInt(equipments) &&
              status.label !== "Pendiente de Asignación"
            ) {
              rowsFiltered.push(element);
            }
          }
        }
        // }
        // if (element.state !== 6) {
        //   rows = rows.filter((row) => row.status === status.label);
        // }
      }
      console.log(status);
      rows =
        status.value === 1 || status.value === 6
          ? rowsFiltered
          : rows.filter((row) => row.state === status.value);
      console.log(rows);
    }
    if (opp.value !== 0) {
      rows = rows.filter((row) => row.opportunityNumber === opp.label);
    }
    if (country.value !== 0) {
      rows = rows.filter((row) => row.country === country.label);
    }
    return rows;
  };

  renderRowsFilteredEquipments = (rows) => {
    const {
      filtersEquipmentsValues: { platform, engineer, status },
    } = this.state;
    if (platform.value !== 0) {
      rows = rows.filter((row) => row.platform === platform.label);
    }
    if (engineer.value !== 0) {
      rows = rows.filter((row) => row.engineer === engineer.label);
    }
    if (status.value !== 0) {
      rows = rows.filter((row) => row.status === status.label);
    }
    return rows;
  };

  modalWorFlow = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
      alert: null,
    }));
  };

  handleOnToggleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
    }));
  };

  handleOnValidateUser = async (values) => {
    const { username } = values;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Validando el usuario ingresado con la información de la compañia`,
      initialValues: { username },
      infoUser: {},
    }));
    await this.props
      .validateGbmCollaborator({
        username: username.split("@")[0].toUpperCase(),
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState({
            disabled: true,
          });
        } else {
          const {
            data: {
              payload: { response },
            },
          } = res.payload;
          this.setState({
            initialValues: { username: response.EMAIL },
            infoUser: response,
            disabled: false,
          });
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      });
  };

  handleOnWorkFlow = async (values) => {
    console.log(values);
    const { startDate } = values;
    const {
      infoUser,
      request: { id },
      equipmentSelected,
    } = this.state;
    if (equipmentSelected.length) {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Asignando el recurso a los equipos seleccionados de la oportunidad`,
      }));
      await this.props
        .createUserAssignmentWithEquipmentsByPlanner(id, {
          user: infoUser,
          startDate,
          equipments: equipmentSelected,
        })
        .then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            await this.props.updateActivityFlow(id, {
              description: `Asignación del ingeníero ${infoUser.USERNAME} por Planner`,
              state: "Asignación Completada",
              type: "plannerFlow",
            });
            this.setState({
              initialValues: {},
              infoUser: {},
              disabled: true,
              request: null,
              page: 1,
              equipmentsView: false,
              equipmentSelected: [],
            });
            this.handleOnToggleModal();
            this.handleOnClearFilters();
            this.handleOnClearFiltersEquipments();
            this.handleOnDisableAssignUser();
            this.props.findAllRequestsAssignment();
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Asignando el recurso a la oportunidad`,
      }));
      await this.props
        .createUserAssignmentByPlanner(id, {
          user: infoUser,
          startDate,
        })
        .then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.handleOnToggleModal();
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            await this.props.updateActivityFlow(id, {
              description: `Asignación del ingeníero ${infoUser.USERNAME} por Planner`,
              state: "Asignación Completada",
              type: "plannerFlow",
            });
            this.setState({
              initialValues: {},
              infoUser: {},
              disabled: true,
              request: null,
              page: 1,
            });
            this.handleOnToggleModal();
            this.handleOnClearFilters();
            this.handleOnClearFiltersEquipments();
            this.props.findAllRequestsAssignment();
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
    }
  };

  handleOnAllocateResources = () => {
    const { request } = this.state;
    if (request.state === 9) {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="Asegúrese de validar con la JTR en la matriz de partes críticas"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.modalWorFlow()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, estoy seguro"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        ),
      });
    } else {
      this.modalWorFlow();
    }
  };

  handleOnEnableAssignUser = () => {
    this.setState({
      assignUser: true,
    });
  };

  handleOnDisableAssignUser = () => {
    this.setState({
      assignUser: false,
      equipmentSelected: [],
    });
  };

  handleOnSelectEquipment = (row) => {
    const { id, idAssign } = row;
    let { equipmentSelected } = this.state;
    if (equipmentSelected.some((row) => row === id)) {
      equipmentSelected = equipmentSelected.filter((row) => row !== id);
    } else {
      equipmentSelected.push({ id, idAssign });
    }
    this.setState({ equipmentSelected });
  };

  handleOnAssignUserEquipment = () => {
    this.modalWorFlow();
  };

  onCreateNewActivityFlow = async (input) => {
    const { request } = this.state;
    if (input.length > 80) {
      this.notify(
        "warning",
        "Atención",
        "La nota es muy larga, no debe sobrepasar los 80 caracteres"
      );
    } else {
      this.hideAlert();
      this.setState((state) => ({
        loading: !state.loading,
        message: `Creando nota al flujo de actividades`,
      }));
      await this.props
        .updateActivityFlow(request.id, {
          description: `${input}`,
          state: "Nota Creada",
          type: "plannerFlow",
        })
        .then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
    }
  };

  handleOnNewActivityFlow = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-180px" }}
          title="Ingrese la nota deseada para el flujo de actividades"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.onCreateNewActivityFlow(input)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, enviar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese una nota
        </ReactBSAlert>
      ),
    });
  };

  render() {
    const {
      name,
      parentName,
      rows,
      cols,
      users,
      parts,
      filters,
      equipments,
      dataAssigned,
      activityFlow,
      equipmentsJTR,
      filtersOptions,
      filtersEquipments,
      filtersEquipmentsOptions,
      equipmentsAssign,
      configurations,
    } = this.props;

    const {
      alert,
      page,
      loading,
      message,
      willMount,
      sizePerPage,
      request,
      infoUser,
      showModal,
      disabled,
      assignUser,
      initialValues,
      filtersValues,
      filtersEquipmentsValues,
      equipmentsView,
      equipmentSelected,
      configurationsView,
    } = this.state;

    return (
      <>
        {alert}
        <WorkFlow
          type={
            request
              ? request.state === 5
                ? "Obtener Configuración"
                : request.state === 6
                ? "Identificar Partes"
                : "Validar JTR "
              : ""
          }
          message={message}
          user={infoUser}
          isInvalid={disabled}
          loading={loading}
          showModal={showModal}
          initialValues={initialValues}
          toggleModal={this.handleOnToggleModal}
          onValidete={this.handleOnValidateUser}
          onWorkFlow={this.handleOnWorkFlow}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message} size="large">
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de requerimientos" />
                </Col>
              </Row>
            ) : configurationsView ? (
              <CardConfigurations
                title="Configuraciones"
                subtitle="Documentos de configuración cargados al requerimiento"
                configurations={configurations}
                comments={[]}
                onBack={this.handleOnBackConfigurationsView}
              />
            ) : equipmentsView ? (
              <>
                <Filters
                  title="Filtros Disponibles"
                  subtitle="Aplicación de Filtros a la lista de equipos"
                  filters={filtersEquipments}
                  options={filtersEquipmentsOptions}
                  values={filtersEquipmentsValues}
                  onClear={this.handleOnClearFiltersEquipments}
                  onChange={this.handleOnChangeFiltersEquipments}
                />
                <EquipmentsList
                  title="Equipos Cargados"
                  subtitle="Lista de los Equipos Cargados al Requerimiento"
                  cols={
                    request.state === 5
                      ? [
                          { name: "#REF", key: "id", hide: false },
                          { name: "País", key: "country", hide: false },
                          {
                            name: "Horario de Atención",
                            key: "officeHour",
                            hide: false,
                          },
                          {
                            name: "Tipo de Modelo",
                            key: "typeModel",
                            hide: false,
                          },
                          { name: "Práctica", key: "practice", hide: false },
                          { name: "Plataforma", key: "platform", hide: false },
                        ]
                      : [
                          { name: "#REF", key: "id", hide: false },
                          { name: "País", key: "country", hide: false },
                          {
                            name: "Horario de Atención",
                            key: "officeHour",
                            hide: false,
                          },
                          {
                            name: "Tipo de Modelo",
                            key: "typeModel",
                            hide: false,
                          },
                          { name: "Práctica", key: "practice", hide: false },
                          { name: "Plataforma", key: "platform", hide: false },
                          { name: "Ingeniero", key: "engineer", hide: false },
                          { name: "Estado", key: "status", hide: false },
                        ]
                  }
                  rows={this.renderRowsFilteredEquipments(equipmentsAssign)}
                  equipmentsJTR={equipmentsJTR}
                  status={request.state}
                  assignUser={assignUser}
                  equipmentsChecked={equipmentSelected}
                  onAssignUser={this.handleOnEnableAssignUser}
                  onNoAssignUser={this.handleOnDisableAssignUser}
                  onBack={this.handleOnBackEquipmentsView}
                  onChangeEquipment={this.handleOnSelectEquipment}
                  onAssignUserEquipment={this.handleOnAssignUserEquipment}
                />
              </>
            ) : request ? (
              <RequestDetail
                title="Información del Requerimiento"
                request={request}
                equipments={equipments}
                equipmentsAssign={equipmentsAssign}
                parts={parts}
                activityFlow={activityFlow}
                usersAssignment={users}
                configurations={configurations}
                onBack={this.handleOnBackMainView}
                onValidUser={this.handleOnAllocateResources}
                onViewEquipments={this.handleOnViewEquipments}
                onViewConfigurations={this.handleOnViewConfigurations}
                onNewActivityFlow={this.handleOnNewActivityFlow}
              />
            ) : rows.length ? (
              <>
                <Filters
                  title="Filtros Disponibles"
                  subtitle="Aplicación de Filtros a la lista de requerimientos"
                  filters={filters}
                  options={filtersOptions}
                  values={filtersValues}
                  onClear={this.handleOnClearFilters}
                  onChange={this.handleOnChangeFilters}
                />
                <Requests
                  title="Lista de requerimientos creados"
                  page={page}
                  sizePerPage={sizePerPage}
                  data={this.renderRowsFiltered(rows)}
                  columns={cols}
                  dataAssigned={dataAssigned}
                  onOpen={this.handleOnOpenRequestSelected}
                  onSetPage={this.handleOnSetPage}
                />
              </>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay requerimientos por visualizar."
                    subtitle="Actualmente al día de hoy, no hay requerimientos digitales para visualizar, cuando hayan requerimientos se enlistarán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

AssignmentManager.defaultProps = {
  name: "Gestor de Asignaciones",
  parentName: "Planificación",
  rows: [],
  cols: [
    { key: "id", name: "#REF" },
    { key: "version", name: "Versión" },
    { key: "opportunityNumber", name: "Oportunidad" },
    { key: "country", name: "País" },
    { key: "salesRep", name: "Representante" },
    { key: "status", name: "Estado" },
    { key: "criticalParts", name: "Partes Críticas" },
    { key: "businessModel", name: "Modelo Negocio" },
    { key: "createdAt", name: "Fecha de Creación" },
    { key: "createdBy", name: "Creado Por" },
  ],
  filters: [
    {
      key: "customer",
      name: "Vendedor",
      placeholder: "Seleccione un vendedor",
    },
    { key: "status", name: "Estado", placeholder: "Seleccione un estado" },
    {
      key: "opp",
      name: "Oportunidad",
      placeholder: "Seleccione una oportunidad",
    },
    { key: "country", name: "País", placeholder: "Seleccione un país" },
  ],
  filtersEquipments: [
    {
      key: "platform",
      name: "Plataforma",
      placeholder: "Seleccione una plataforma",
    },
    {
      key: "engineer",
      name: "Ingeniero",
      placeholder: "Seleccione un ingeniero",
    },
    { key: "status", name: "Estado", placeholder: "Seleccione un estado" },
  ],
  filtersOptions: {
    customer: [],
    status: [],
    opp: [],
    country: [],
  },
  filtersEquipmentsOptions: {
    platform: [],
    engineer: [],
    status: [],
  },
  equipments: [],
  equipmentsJTR: [],
  equipmentsAssign: [],
  configurations: [],
  users: [],
  parts: [],
  activityFlow: [],
  dataAssigned: [],
};

AssignmentManager.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  equipments: PropTypes.array.isRequired,
  equipmentsJTR: PropTypes.array.isRequired,
  equipmentsAssign: PropTypes.array.isRequired,
  configurations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  parts: PropTypes.array.isRequired,
  activityFlow: PropTypes.array.isRequired,
  dataAssigned: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  rows: getAllRequirementsPlanners(state),
  equipments: getEquipmenstByRequest(state),
  equipmentsJTR: getEquipmentsJTRByRequest(state),
  equipmentsAssign: getEquipmentsByRequest(state),
  configurations: getConfigurationsByRequest(state),
  filtersOptions: getFiltersOptions(state),
  filtersEquipmentsOptions: getFiltersOptionsEquipments(state),
  users: getUserAssignmentByRequest(state),
  parts: getAllPartsEquipmentsByRequest(state),
  activityFlow: getActivitiesFlowByRequest(state),
  dataAssigned: getAllDataRequirementsPlanners(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllRequestsAssignment,
    findAllDataByDigitalRequest,
    findEquipmentsAssignmentByRequest,
    validateGbmCollaborator,
    createUserAssignmentByPlanner,
    updateActivityFlow,
    createUserAssignmentWithEquipmentsByPlanner,
    findUserAssignmentByRequest,
    findAllPartsEquipmentsByRequest,
  })(AssignmentManager)
);
