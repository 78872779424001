
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Button
} from "reactstrap";

const ShowDocuments = ({ setDocument, info, documents,setNewInfoToInsert, setNewInfo  }) => {
    const handleOnChangeCategory = () =>{
        setNewInfoToInsert([]);
        setNewInfo([])
        setDocument("Document", "")
    }
    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h3 className="mb-0">
                                Seleccione el Documento
                            </h3>
                        </Col>
                        {
                            info.Document !== "" &&
                            <Col xs="2">
                                <Button
                                    className="btn  btn-icon"
                                    color="dark"
                                    block
                                    type="button"
                                    onClick={() => handleOnChangeCategory()}
                                >
                                    <span className="btn-inner--icon mr-">
                                        <i className="fas fa-sync-alt"></i>
                                    </span>
                                    <span className="btn-inner--text">Cambiar</span>
                                </Button>
                            </Col>
                        }
                    </Row>
                </CardHeader>
                {info.Document === ""
                    ?
                    <CardBody>
                        <Row>
                            {
                                documents && documents.map((item, key) => (
                                    <Col xs={"6"} key={key}>
                                        <button
                                            key={key}
                                            className="btn-icon-clipboard"
                                            type="button"
                                            onClick={() => setDocument("Document", item)}
                                        >
                                            <div>
                                                <span>{item.name}</span>
                                            </div>
                                        </button>
                                    </Col>


                                ))
                            }
                        </Row>
                    </CardBody>
                    :
                    <CardBody>
                        <Row className="mb-3">
                            <span className="h6 surtitle text-muted">
                                Documento Seleccionado:
                            </span>
                        </Row>
                        <Row className="mb-3">
                            <h1>{info.Document}</h1>
                        </Row>
                    </CardBody>
                }
            </Card>
        </div>
    );
};


export default ShowDocuments;