import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Col, CardHeader, FormGroup, Input, Button, Row, ListGroup, ListGroupItem, Table, CardFooter } from 'reactstrap';
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import FormTemplate from "components/FinanceFlows/Administration/FormTemplate";
import Filter from "components/FinanceFlows/Administration/Filters";
import { useDispatch } from 'react-redux';
import { getOptions, getAllCategories, getAllDocumentApprovalTemplateByCategoryId, getAllTemplatePosition, getUnitTypeByDocumentId, addDocumentApprovalTemplate, updateDocumentApprovalTemplate, deleteDocumentApprovalTemplate } from "actions/financialFlows.jsx";
import { Spin } from 'antd';
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


const ApprovalTemplate = () => {

    const [filterByTable, setFiltersByTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [infoToModule, setInfoToModule] = useState({
        categories: [],
        documentApprovalTemplate: [],
        unitTypeByDocument: []
    });
    const [categorySelected, setCategorySelected] = useState({
        category: {},
        isSelected: false
    });
    const [itemInfo, setItemInfo] = useState({
        isSelected: false,
        templateApprovers: [],
        rules: []
    });
    const [newInfo, setNewInfo] = useState({
        isSelected: false,
        approvers: [],
        rules: []
    });

    const [newRule, setNewRule] = useState(null);
    const [infoRules, setInfoRules] = useState([]);
    const [alert, setAlert] = useState("");

    const MySwal = withReactContent(Swal);

    // Notificaciones acerca de las acciones realizadas en la pagina.
    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    // atributos para definir los parametros de los filtros
    const filter = [
        {
            label: "Documento",
            type: "input",
            xs: "6",
            value: "documentName"
        },
        {
            label: "Descripción",
            type: "input",
            xs: "6",
            value: "description"
        }
    ];

    //Columnas de la tabla principal generica
    const colums = [
        {
            label: "Documento",
            value: "documentName",
            type: "a"
        },
        {
            label: "Descripción",
            value: "description",
            type: "a"
        }
    ];

    // filtro que verifica si las reglas son del tipo texto o combobox (Si es de alguno de estos tipos solo se permite el operador "=")
    const filterOptions = (unitTypeId) => {
        let findUnitType = infoToModule.unitTypeByDocument?.find((value) => {
            return value.value === unitTypeId
        });

        if (findUnitType) {
            return findUnitType.typeUnitTypeName !== "Texto" && findUnitType.typeUnitTypeName !== "Combobox"
        }
    }

    // funcion que permite renderizar la seleccion de paises si el tipo de unidad es "Unidad de Negocio"
    const filterCountry = () => {
        if (newRule) {
            let findUnitType = infoToModule.unitTypeByDocument?.find((value) => {
                return value.value === newRule.unitTypeId
            });
            if (findUnitType && findUnitType.label === "Unidad de Negocio") return true;
        }
    }

    // se asigna los valores de los operadores segun el tipo de unidad
    const operator = filterOptions(newRule?.unitTypeId) ?
        [
            { label: "Menor que", value: "<" },
            { label: "Menor e igual que", value: "<=" },
            { label: "Mayor que", value: ">" },
            { label: "Mayor e igual que", value: ">=" },
            { label: "Igual que", value: "=" },
            
        ]
        :
        [
            { label: "Igual que", value: "=" }
        ]
        ;

    //Botones adicionales en la tabla principal
    const aditionalsButtons = [
        {
            color: "secondary",
            function: (e) => handleOnSetEdit(e),
            icon: "fas fa-edit",
            className: "table-action table-action-edit"
        },
        {
            color: "secondary",
            function: (e) => handleOnConfirmDelete(e),
            icon: "fas fa-trash",
            className: "table-action table-action-delete"
        }
    ]

    //Funcion para guardar los filtros de la tabla principal
    const handleOnfilterDinamic = (constant, e) => {
        setFiltersByTable(prevState => ({
            ...prevState,
            [constant]: `${e}`
        }))
    }

    //Funcion para aplicar los filtros que se utilizaran
    const renderRowsByFilters = (rows) => {
        if (Object.keys(filterByTable).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filterByTable);
            for (const element of filterKeys) {
                const valueFiltered = filterByTable[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }

    // json con las opciones para crear una nueva plantilla
    const createOptions = [
        { label: "Descripcion de Plantilla", value: "description", type: "input", colWidth: "12", customAction: false },
        { label: "Documento relacionado", value: "documentId", valueInsert: "documentId", required: true, type: "select", colWidth: "12", multi: false, options: infoToModule.documentsByCategory, placeholder: "Seleccione el documento", isCustom: true, customAction: (constant, value) => handleOnSetDocumentCreate(constant, value), disabled: false },
        { label: "Aprobadores", value: "approvers", valueInsert: "approvers", required: true, type: "select", colWidth: "12", multi: true, options: infoToModule.templatePosition, placeholder: "Seleccione los aprobadores", isCustom: false, customAction: "", disabled: false },
        { label: "Prioridad de los Aprobadores", value: "approvers", required: true, type: "sorter", colWidth: "12", options: newInfo.approvers, isCustom: false, customAction: "" },
        { label: "Notificación de Aprobación", value: "notifyApprovers", valueInsert: "position", required: false, type: "select", colWidth: "12", multi: true, options: infoToModule.templatePosition, placeholder: "Seleccione la posicion a notificar", isCustom: false, customAction: "", disabled: false }
    ]

    // json con las opciones para editar plantilla
    const editOptions = [
        { label: "Descripcion de Plantilla", value: "description", type: "input", colWidth: "12", customAction: false },
        { label: "Documento relacionado", value: "documentName", valueInsert: "documentId", required: true, type: "select", colWidth: "12", multi: false, options: infoToModule.documentsByCategory, placeholder: "Seleccione el documento", isCustom: false, customAction: "", disabled: true },
        { label: "Aprobadores", value: "templateApprovers", valueInsert: "templateApprovers", required: true, type: "select", colWidth: "12", multi: true, options: infoToModule.templatePosition, placeholder: "Seleccione los aprobadores", isCustom: false, customAction: "", disabled: false },
        { label: "Prioridad de los Aprobadores", value: "templateApprovers", required: true, type: "sorter", colWidth: "12", options: itemInfo.templateApprovers, isCustom: false, customAction: "" },
        { label: "Notificación de Aprobación", value: "notifyApprovers", valueInsert: "notifyApprovers", required: false, type: "select", colWidth: "12", multi: true, options: infoToModule.templatePosition, placeholder: "Seleccione la posicion a notificar", isCustom: false, customAction: "", disabled: false }
    ]

    // json con las opciones para editar plantilla
    const extraOptions = [
        { label: "Campo a validar", value: "unitTypeName", valueInsert: "unitTypeId", required: true, type: "select", colWidth: "12", multi: false, options: infoToModule.unitTypeByDocument, placeholder: "Seleccione el campo", isConditional: false },
        { label: "Operacíon", value: "operation", valueInsert: "operation", required: true, type: "select", colWidth: "12", multi: false, options: operator, placeholder: "Seleccione el operador", isConditional: false },
        filterCountry() ? { label: "Valor", value: "value", valueInsert: "value", required: true, type: "select", colWidth: "12", multi: false, options: infoToModule.businessUnit, placeholder: "Seleccione la unidad de negocio", isConditional: false } : { label: "Valor", value: "value", type: "input", colWidth: "12", isConditional: false }, // usa un filtro para utilizar un select en caso de que se deba seleccionar un pais.
        { label: "¿Es Rango?", value: "isRange", valueInsert: "isRange", required: true, type: "select", colWidth: "12", multi: false, options: [{ label: "Si", value: 1 }, { label: "No", value: 0 }], placeholder: "Seleccione si la regla tiene rangos", isConditional: true },
        { label: "Rango", value: "endRange", type: "input", colWidth: "12", isConditional: true }
    ]

    const dispatch = useDispatch();

    //Funcion que trae la data de la tabla principal
    const handleOnGetInfoData = async () => {
        setLoading(true);
        dispatch(getAllCategories()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    categories: payload.data.data[0],
                }));
                setLoading(false);
            }
        })
        dispatch(getOptions()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    businessUnit: payload.data.data.businessUnit,
                }));
                setLoading(false);
            }
        })
        dispatch(getAllTemplatePosition()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let tempTemplatePositionOptions = payload.data.data[0];
                tempTemplatePositionOptions.push({ id: 9999, position: `NINGUNO`, name: "SIN ASIGNAR" });
                let templatePositionOptions = tempTemplatePositionOptions.map((item) => ({ "value": item.id, "label": `${item.position} - ${item.name}` }))

                setInfoToModule((prevState) => ({
                    ...prevState,
                    templatePosition: templatePositionOptions,
                }));
                setLoading(false);
            }
        })
    }

    // funcion que asigna la categoria asignada y carga las plantillas relacionadas a esa categoria.
    const handleOnSelectCategory = (category) => {
        setLoading(true);
        setCategorySelected({
            category: category,
            isSelected: true
        });
        dispatch(getAllDocumentApprovalTemplateByCategoryId(category.id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    documentApprovalTemplate: payload.data.data[0],
                    documentsByCategory: category.documents
                }));
                setLoading(false);
            }
        })
    }

    // funcion que elimina la categoria seleccionada.
    const handleOnClearCategory = () => {
        setCategorySelected({
            category: {},
            isSelected: false
        });
        handleOnClearNewInfo();
    }

    //Funcion para hacer los cambios de informacion en los campos de input, select .etc
    const handleOnChangeInfo = (constant, value) => {

        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };

    //Funcion para hacer los cambios de informacion en los campos de input, select .etc
    const handleOnChangeInfoEdit = (constant, value) => {
        setItemInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };

    //Funcion para hacer los cambios de informacion en los campos de input, select .etc
    const handleOnChangeNewRule = (constant, value) => {
        setNewRule((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };

    // funcion que ordena los items dentro de un array (react-beautiful-dnd)
    const reorder = (array, source, destination) => {
        let result = [...array];
        let [removed] = result.splice(source, 1);
        result.splice(destination, 0, removed);
        return result;
    }

    // funcion que ordena los items segun el usuario los arrastre a la hora de crear una nueva plantilla.
    const onSortEndCreate = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        if (source.index === destination.index && source.droppableId === destination.droppableId) return;

        setNewInfo((prevState) => ({
            ...prevState,
            approvers: reorder(prevState.approvers, source.index, destination.index),
        }));
    }

    // funcion que ordena los items segun el usuario los arrastre a la hora de editar una plantilla.
    const onSortEndEdit = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        if (source.index === destination.index && source.droppableId === destination.droppableId) return;

        setItemInfo((prevState) => ({
            ...prevState,
            templateApprovers: reorder(prevState.templateApprovers, source.index, destination.index),
        }));
    }

    // funcion que habilita la vista con las opciones para crear una nueva plantilla
    const handleOnSetCreate = () => {
        setNewInfo((prevState) => ({
            ...prevState,
            isSelected: true,
        }));
    }

    // funcion que asigna los valores de una plantilla seleccionada para editarla y habilita la vista.
    const handleOnSetEdit = (e) => {
        setLoading(true);
        if (newInfo.isSelected) {
            setNewInfo({
                isSelected: false,
                approvers: [],
                rules: []
            })
        }
        setItemInfo(e);
        setItemInfo((prevState) => ({
            ...prevState,
            isSelected: true
        }));
        getUnitTypeByDocument(e.documentId);
        setInfoRules(!e.rules ? [] : [...e.rules]);
        setLoading(false);
    }

    // funcion que limpia los datos de una plantilla nueva en caso de cerrar la vista o si esta se creo correctamente, asi como deshabilitar la vista de creación.
    const handleOnClearNewInfo = () => {
        setNewInfo({
            isSelected: false,
            approvers: [],
            rules: []
        })
        setInfoRules([]);
    }

    // funcion que limpia los datos de una plantilla seleccionada en caso de cerrar la vista o si esta se editó correctamente, asi como deshabilitar la vista de edición.
    const handleOnClearItemInfo = () => {
        setItemInfo({
            isSelected: false,
            approvers: [],
            rules: []
        })
        setInfoRules([]);
    }

    // funcion que limpia los datos de una nueva regla relacionada a la plantilla.
    const handleOnClearRule = (e) => {
        setNewRule(null);
        e.preventDefault();
    }

    // función que crea una nueva regla, asi como sus principales datos requeridos.
    const handleOnCreateRule = (e) => {
        setNewRule({
            unitTypeName: "",
            unitTypeId: 0,
            value: "",
            operation: "",
            isRange: 0,
            endRange: null
        })
        e.preventDefault();
    }

    // funcion que obtiene los tipos de unidad relacionados al documento seleccionado en la creacion o edicion de una plantilla.
    const getUnitTypeByDocument = (value) => {
        dispatch(getUnitTypeByDocumentId(value)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    unitTypeByDocument: payload.data.data,
                }));
            }
        })
    }

    // funcion que agrega el documento seleccionado, asi como la obtención de sus tipos de unidades relacionados.
    const handleOnSetDocumentCreate = (constant, value) => {
        setLoading(true);
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
        getUnitTypeByDocument(value);
        setNewRule(null);
        setLoading(false);
    }

    // funcion que agrega una nueva regla.
    const handleOnAddRule = () => {
        if (!(typeof newRule.id === "number")) {
            let findUnitTypeName = infoToModule.unitTypeByDocument.find((unitType) => unitType.value === newRule.unitTypeId);
            if (findUnitTypeName) {
                if (findUnitTypeName.label === "Unidad de Negocio") {
                    let findBusinessUnitName = infoToModule.businessUnit.find((bu) => bu.value === newRule.value);
                    if (findBusinessUnitName) {
                        newRule.value = findBusinessUnitName.label;
                    }
                }
                newRule.unitTypeName = findUnitTypeName.label;
            }
            infoRules.push(newRule);
            setNewRule(null);
        } else {
            let findUnitTypeName = infoToModule.unitTypeByDocument.find((unitType) => unitType.value === newRule.unitTypeId);
            if (findUnitTypeName) {
                if (findUnitTypeName.label === "Unidad de Negocio") {
                    let findBusinessUnitName = infoToModule.businessUnit.find((bu) => bu.value === newRule.value);
                    if (findBusinessUnitName) {
                        newRule.value = findBusinessUnitName.label;
                    }
                }
                newRule.unitTypeName = findUnitTypeName.label;
            }
            infoRules[newRule.id] = newRule;
            setNewRule(null);
        }

    }

    // funcion que crea una ventana de confirmacion en caso de querer eliminar una plantilla.
    const handleOnConfirmDeleteRule = (value) => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea eliminar la regla seleccionada?"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnDeleteRule(value)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }
    // funcion que elimina una regla ya creada en base a su posicion.
    const handleOnDeleteRule = (value) => {
        let result = [...infoRules];
        result.splice(value, 1);
        setInfoRules(result);
        setAlert("");
    }

    // funcion que le asigna un id a las reglas para posteriormente ser editada.
    const handleOnEditRule = (value, index) => {
        value.id = index; // se le asigna un id para identificarlo en la edición
        setNewRule(value);
    }


    // funcion que crea una ventana de confirmacion en caso de querer eliminar una plantilla.
    const handleOnConfirmDelete = (e) => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea eliminar la plantilla?"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnDeleteDocumentApprovalTemplate(e)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }

    // funcion que envia una solicitud de eliminacion de una plantilla.
    const handleOnDeleteDocumentApprovalTemplate = (e) => {
        setLoading(true);
        dispatch(deleteDocumentApprovalTemplate(e.id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                handleOnSelectCategory(categorySelected.category);
                setAlert("");
                setLoading(false);
                Toast.fire({
                    title: "Eliminando",
                    html: "Se Elimino correctamente",
                    type: "success",
                });
            }
        })
    }

    // funcion que muestra una confirmacion en caso de querer crear una nueva plantilla.
    const handleOnConfirmCreate = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea crear la nueva plantilla?"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnCreate()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, crear"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }

    // funcion que muestra una confirmacion en caso de editar una plantilla.
    const handleOnConfirmEdit = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea editar la plantilla seleccionada?"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnEdit()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, editar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }

    // funcion que envia una solicitud de creacion de una nueva plantilla.
    const handleOnCreate = () => {
        setLoading(true);
        const body = {
            categoryId: categorySelected.category.id,
            description: newInfo.description,
            documentId: newInfo.documentId,
            approvers: newInfo.approvers,
            notify: newInfo.position,
            rules: infoRules
        }
        dispatch(addDocumentApprovalTemplate(body)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setLoading(false);
                Toast.fire({
                    title: "Insertando",
                    html: "Se Inserto correctamente",
                    type: "success",
                });
                setAlert("");
                handleOnClearNewInfo();
                handleOnSelectCategory(categorySelected.category);
            }
        })
    }

    // funcion que muestra una confirmacion en caso de querer crear una nueva plantilla.
    const handleOnConfirmCreateDuplicate = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea duplicar la plantilla?"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnCreateDuplicate()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, duplicar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }

    // funcion que envia una solicitud de creacion de una nueva plantilla.
    const handleOnCreateDuplicate = () => {
        setLoading(true);
        const body = {
            categoryId: categorySelected.category.id,
            description: itemInfo.description,
            documentId: itemInfo.documentId,
            approvers: itemInfo.templateApprovers,
            notify: itemInfo.notifyApprovers,
            rules: infoRules
        }
        dispatch(addDocumentApprovalTemplate(body)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setLoading(false);
                Toast.fire({
                    title: "Insertando",
                    html: "Se Inserto correctamente",
                    type: "success",
                });
                setAlert("");
                handleOnClearItemInfo();
                setNewRule(null);
                setInfoRules([]);
                handleOnSelectCategory(categorySelected.category);
            }
        })
    }

    // funcion que envia una solicitud para editar una plantilla seleccionada.
    const handleOnEdit = () => {
        setLoading(true);
        const body = {
            documentApprovalTemplateId: itemInfo.id,
            description: itemInfo.description,
            documentId: itemInfo.documentId,
            approvers: itemInfo.templateApprovers,
            notify: itemInfo.notifyApprovers,
            rules: infoRules
        }
        dispatch(updateDocumentApprovalTemplate(body)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setLoading(false);
                Toast.fire({
                    title: "Actualizando",
                    html: "Se Actualizó correctamente",
                    type: "success",
                });
                setAlert("");
                handleOnClearItemInfo();
                setNewRule(null);
                handleOnSelectCategory(categorySelected.category);
            }
        })
    }

    // funcion que valida si esta toda la informacion requerida para la creación de una plantilla.
    const validateCreate = () => {
        return newInfo.description === "" || (newInfo.templateApprovers && (newInfo.templateApprovers.length === 0)) || !newInfo.position || newInfo.position === 0;
    }

    // funcion que valida si esta toda la informacion requerida para la edicion de una plantilla.
    const validateEdit = () => {

        return itemInfo.description === "" || (itemInfo.templateApprovers && (itemInfo.templateApprovers.length === 0)) || itemInfo.NotificationApprovalId === 0;
    }

    // funcion que valida si esta toda la informacion requerida para la edicion de una plantilla.
    const validateRule = () => {
        if (newRule) {
            if (filterOptions(newRule.unitTypeId)) {
                if (newRule.isRange === 1) {
                    return newRule.unitTypeId === 0 || newRule.value === "" || newRule.operation === "" || newRule.endRange === null;
                } else {
                    return newRule.unitTypeId === 0 || newRule.value === "" || newRule.operation === "";
                }
            } else {
                return newRule.unitTypeId === 0 || newRule.value === "" || newRule.operation === "";
            }
        } else {
            return false;
        }
    }

    // useEffect que carga la informacion inicial (categorias, unidades de negocio, aprobadores)
    useEffect(() => {
        const getData = async () => {
            handleOnGetInfoData();
        }
        getData();
    }, []);

    // render que se encarga del manejo de las reglas de una plantilla.
    let extraOptionsRender =
        <>
            <CardHeader>
                <Row>
                    <Col xs="8">
                        <h3 className="mb-0">
                            Agregar nueva regla
                        </h3>
                    </Col>
                    {!newRule && (
                        <Col className="text-right" xs="4">
                            <Button
                                className="btn  btn-icon"
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={(e) => handleOnCreateRule(e)}
                            >
                                +
                            </Button>
                        </Col>
                    )}
                </Row>
            </CardHeader>
            <CardBody>
                <Row>
                    {newRule && (
                        <>
                            {
                                extraOptions.map((item, key) => (
                                    <Col xs={item.colWidth} key={key}>
                                        {item.type === "select" && (
                                            item.isConditional ?
                                                <>
                                                    {filterOptions(newRule.unitTypeId) && (
                                                        <FormGroup>
                                                            <label
                                                                style={{ fontSize: "12px" }}
                                                                className="form-control-label"
                                                            >
                                                                {item.label}:
                                                            </label>
                                                            <Select
                                                                key={key}
                                                                className="basic-single is-invalid"
                                                                type="select"
                                                                required={item.required}
                                                                isSearchable
                                                                isMulti={item.multi}
                                                                options={item.options}
                                                                placeholder={item.placeholder}
                                                                defaultValue={newRule && (item.multi === true ? newRule[item.value] : { label: newRule[item.value], value: 1 })}
                                                                onChange={(e) => handleOnChangeNewRule(item.valueInsert, item.multi === true ? e : e.value)}
                                                                disabled={item.value === "unitTypeName" && !infoToModule.unitTypeByDocument}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    <FormGroup>
                                                        <label
                                                            style={{ fontSize: "12px" }}
                                                            className="form-control-label"
                                                        >
                                                            {item.label}:
                                                        </label>
                                                        <Select
                                                            key={key}
                                                            className="basic-single is-invalid"
                                                            type="select"
                                                            required={item.required}
                                                            isSearchable
                                                            isMulti={item.multi}
                                                            options={item.options}
                                                            placeholder={item.placeholder}
                                                            getOptionLabel={(option) => `${option.label}`}
                                                            defaultValue={newRule && (item.multi === true ? newRule[item.value] : { label: newRule[item.value], value: 1 })}
                                                            onChange={(e) => handleOnChangeNewRule(item.valueInsert, item.multi === true ? e : e.value)}
                                                        />
                                                    </FormGroup>
                                                </>

                                        )}
                                        {item.type === "input" && (
                                            item.isConditional ?
                                                <>
                                                    {filterOptions(newRule.unitTypeId) && (
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                {item.label}:
                                                            </label>
                                                            <Input
                                                                defaultValue={newRule[item.value]}
                                                                key={key}
                                                                required={item.required}
                                                                className="m-0"
                                                                type={item.type}
                                                                onChange={(e) => handleOnChangeNewRule(item.value, e.target.value)}
                                                                disabled={newRule.isRange === 0 && item.value === "endRange"}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            {item.label}:
                                                        </label>
                                                        <Input
                                                            defaultValue={newRule[item.value]}
                                                            key={key}
                                                            required={item.required}
                                                            className="m-0"
                                                            type={item.type}
                                                            onChange={(e) => handleOnChangeNewRule(item.value, e.target.value)}
                                                            disabled={newRule.isRange === 0 && item.value === "endRange"}
                                                        />
                                                    </FormGroup>
                                                </>
                                        )}
                                    </Col>
                                ))
                            }
                            <Col xs="8">
                                <Button
                                    className="btn btn-sm  btn-icon"
                                    color="dark"
                                    type="button"
                                    onClick={(e) => handleOnClearRule(e)}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col xs="4">
                                <Button
                                    className="btn btn-sm  btn-icon"
                                    color="info"
                                    type="button"
                                    onClick={(e) => handleOnAddRule(e)}
                                    disabled={validateRule()}
                                >
                                    Agregar
                                </Button>
                            </Col>
                        </>
                    )}
                    {!newRule && (
                        infoRules.length > 0 ?
                            <>
                                {infoRules.map((value, index) => (
                                    <React.Fragment key={index}>

                                        <Col xs="7">
                                            <h4>
                                                <p>
                                                    {value.unitTypeName}{" "}
                                                    {value.operation}{" "}
                                                    {value.value}
                                                    {value.isRange === 1 &&
                                                        ` y < ${value.endRange}`}
                                                </p>
                                            </h4>
                                        </Col>
                                        <Col xs="5">
                                            <div className="text-right">
                                                <Button
                                                    color="secondary"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleOnEditRule(value, index)}
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </Button>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleOnConfirmDeleteRule(index)}
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </Button>
                                            </div>
                                        </Col>
                                    </React.Fragment>
                                ))}
                            </>
                            :
                            <>
                                <Col xs="12">
                                    <h4>No se han registrado reglas</h4>
                                </Col>
                            </>

                    )}
                </Row>
            </CardBody>
        </>
    return (
        <div>
            <AdminHeader name="Plantillas de Aprobación" parentName="Finance Flows" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    {alert}
                    <Row>
                        {!categorySelected.isSelected && (
                            <Col xs={6}>
                                <Card style={{ height: "1000px", overflow: "scroll" }}>
                                    <CardHeader>
                                        <Row className="justify-content-between" style={{ marginRight: '2vh' }}>
                                            <Col sm="12" md="10">
                                                <h4 className="surtitle">Aprobaciones por categoria</h4>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <ListGroup className="list my--3" flush>
                                            {infoToModule.categories.map((category) => {
                                                return (
                                                    <ListGroupItem
                                                        key={"category-" + category.id}
                                                    >
                                                        <Row className="align-items-center">
                                                            <Col xs={8}>
                                                                <h4 className="mb-0">{category.name}</h4>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <Button
                                                                    className="btn-icon sm"
                                                                    color="dark"
                                                                    type="button"
                                                                    onClick={() => handleOnSelectCategory(category)}
                                                                >
                                                                    Seleccionar
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                );
                                            })}
                                        </ListGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        {categorySelected.isSelected && (
                            <Col xs={6}>
                                <Spin size="large" spinning={itemInfo.isSelected} tip="Editando">
                                    <Card style={{ height: "1000px", overflow: "scroll" }}>
                                        <CardHeader>
                                            <Row className="align-items-center">
                                                <Col sm="9" md="8">
                                                    <h4 className="surtitle mb-0">{categorySelected.category && categorySelected.category.name}</h4>
                                                </Col>
                                                <Col sm="3" md="4">
                                                    <Button
                                                        className="sm"
                                                        color="warning"
                                                        onClick={() => handleOnClearCategory()}
                                                    >
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-chevron-left" />
                                                        </span>
                                                        <span>Regresar</span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Button
                                                className="mb-3"
                                                block
                                                color="primary"
                                                size="lg"
                                                type="button"
                                                onClick={() => handleOnSetCreate()}
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-plus" />
                                                </span>
                                                <span>Nueva Plantilla</span>
                                            </Button>
                                            <Filter
                                                jsonFilters={filter}
                                                filters={handleOnfilterDinamic}
                                            />
                                            <Table
                                                className="align-items-center table-flush"
                                                responsive
                                                striped={true}
                                                hover={true}
                                            >
                                                <thead className="thead-light">
                                                    <tr>
                                                        {colums.map((item, key) => {
                                                            return (
                                                                <th key={key}>{item.label}</th>
                                                            );
                                                        })}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list">
                                                    {renderRowsByFilters(infoToModule.documentApprovalTemplate).map((item, key) => (
                                                        <tr key={`tr${key}$`} >
                                                            {
                                                                colums.map((col, index) => (
                                                                    <td key={`td${index}$`} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                                        {col.type === "a" ?
                                                                            <a>{item[col.value].substring(0, 50)}</a>
                                                                            : col.type === "b" ?
                                                                                <b>{item[col.value].substring(0, 50)}</b>
                                                                                :
                                                                                <a>{item[col.value].substring(0, 50)}</a>
                                                                        }
                                                                    </td>
                                                                ))
                                                            }
                                                            <td key={`td1${key}$`} className="text-center">
                                                                {aditionalsButtons.map((itemButton, key) => (
                                                                    <a
                                                                        key={`Icon${key}`}
                                                                        className={itemButton.className}
                                                                        onClick={() => itemButton.function(item)}
                                                                    >
                                                                        <i className={itemButton.icon} />
                                                                    </a>
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </Spin>
                            </Col>
                        )}
                        {!itemInfo.isSelected && !newInfo.isSelected && (
                            <Col xs={6}>
                                <Card style={{ height: "1000px", overflow: "scroll" }}>
                                    <CardHeader>
                                        <Row className="justify-content-between" style={{ marginRight: '2vh' }}>
                                            <Col sm="12" md="10">
                                                <h4 className="surtitle">Configuracion de Flujo</h4>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        {newInfo.isSelected && (
                            <Col xs={6}>
                                <Card style={{ height: "1000px", overflow: "scroll" }}>
                                    <CardHeader>
                                        <Row className="justify-content-between" style={{ marginRight: '2vh' }}>
                                            <Col sm="12" md="10">
                                                <h4 className="surtitle">Nueva plantilla de Aprobación</h4>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <FormTemplate
                                            itemForm={createOptions}
                                            itemInfo={newInfo}
                                            handleOnChangeInfo={handleOnChangeInfo}
                                            onSortEnd={onSortEndCreate}
                                            nameButton1={"Cerrar"}
                                            nameButton2={"Guardar"}
                                            saveButton={handleOnConfirmCreate}
                                            closeButton={handleOnClearNewInfo}
                                            saveButtonDisabled={validateCreate() || newRule !== null}
                                            extraOptions={extraOptionsRender}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        {itemInfo.isSelected && (
                            <Col xs={6}>
                                <Card style={{ height: "1000px", overflow: "scroll" }}>
                                    <CardHeader>
                                        <Row className="justify-content-between" style={{ marginRight: '2vh' }}>
                                            <Col sm="12" md="10">
                                                <h4 className="surtitle">plantilla de Aprobación</h4>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <FormTemplate
                                            itemForm={editOptions}
                                            itemInfo={itemInfo}
                                            handleOnChangeInfo={handleOnChangeInfoEdit}
                                            onSortEnd={onSortEndEdit}
                                            nameButton1={"Cerrar"}
                                            nameButton2={"Guardar"}
                                            nameButton3={"Guardar como plantilla nueva"}
                                            saveButton={handleOnConfirmEdit}
                                            closeButton={handleOnClearItemInfo}
                                            duplicateButton={handleOnConfirmCreateDuplicate}
                                            saveButtonDisabled={validateEdit() || newRule !== null}
                                            extraOptions={extraOptionsRender}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Spin>
            </Container>
        </div>
    );
};

export default ApprovalTemplate;