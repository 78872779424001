/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Main" de Billing para la visualización y aprobación/rechazo de las facturas adjuntas por los freelance externos
*El cual llama los siguientes componentes:
    FiltersMain: Componente compartido para filtrar la tabla principal de asignaciones de ordenes de compra.
    MainTable: Componente compartido para mostrar las asignaciones de las ordenes de compra.
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    MySwal: funcion para las notificaciones personalizadas
    isL2: useState que guarda un booleano para verificar si el usuario logeado es aprobador L2
=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState, useEffect } from "react";
//customized Component
import FiltersMain from "components/Freelance/SharedComponents/FiltersMain.jsx";
import MainTable from "components/Freelance/SharedComponents/MainTable.jsx";
//api urls for download
import urls from "api/urls.jsx";
//customized CSS for sweatAlert html NavWizard
import "../../../assets/css/navWizard.css";
//accion para exraer las solicitudes para el reporte de excel y para aprobar o devolver facturas
import { billsActions } from "actions/Freelance";
//usedispatch for fetch
import { useDispatch } from "react-redux";
// javascript library for dates
import moment from "moment";
//token get logged userName
import { getUsernameLogged } from "selectors/adminLayout";
import { encrypter } from 'helpers/desencrypt.jsx';

const MainBilling = ({
  setLoad,
  Toast,
  dropDownOptions,
  rolArea,
  isAdmin,
  MySwal,
  isL2,
}) => {

  //#region states and functions

  //#region ejecutar fetch acciones
  const dispatch = useDispatch();
  //#endregion

  //#region State para guardar los filtros seleccionados según cada tabla en FilterMain
  const [search, setSearch] = useState({});
  const handleOnChangeFilter = (constant, valor) => {
    setSearch((prevState) => ({
      ...prevState,
      [constant]: valor,
    }));
  };
  //#endregion

  //#region state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);
  //#endregion

  //#region state para recargar la tabla principal cuando se necesite
  const [reloadTable, setRealTable] = useState(false);
  //#endregion

  //#region funcion para buscar las facturas para MainTable
  const handleSearch = () => {
    setPage(1);
    setRealTable((prevState) => !prevState);
  };
  //#endregion

  //#region campos del filtro para el componente FilterMain
  const fields = [
    {
      colWidth: "6",
      label: "Área de negocio",
      placeholder: "Seleccione el Área de negocio",
      id: "purcharseOrderAssignation.area",
      options: "billsArea",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select"
    },
    {
      colWidth: "6",
      label: "Orden de Compra",
      placeholder: "Seleccione la Orden de Compra",
      id: "purcharseOrderAssignation.purchaseOrder",
      options: "purchaseOrderAssignation",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Estado",
      placeholder: "Seleccione el Estado",
      id: "billsFreelance.status",
      options: "billsStatus",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Proveedores",
      placeholder: "Seleccione el Proveedor",
      id: "purcharseOrderAssignation.consultant",
      options: "accessFreelance",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Proyecto",
      placeholder: "Seleccione el Proyecto",
      id: "purcharseOrderAssignation.project",
      options: "assignationProjects",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Responsable",
      placeholder: "Seleccione el responsable",
      id: "purcharseOrderAssignation.responsible",
      options: "Responsables",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Fecha Desde",
      placeholder: "Seleccione la fecha",
      id: "toDate",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "StartDate",
    },
    {
      colWidth: "6",
      label: "Fecha Hasta",
      placeholder: "Seleccione la fecha",
      id: "toDate",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "EndDate",
    },
  ];
  //#endregion

  //#region Botones del FilterMain para ejecutar las funciones anteriores
  const buttons = [
    {
      color: "primary",
      icon: "fas fa-search",
      title: "Buscar Facturas",
      function: handleSearch,
      type: "button",
      colWidth: "6",
    },
    {
      color: "info",
      icon: "fa fa-download",
      title: "Descargar reporte",
      docTitle: "Reporte Facturación " + new Date().toISOString().slice(0, 10),
      type: "download",
      colWidth: "6",
    },
  ];
  //#endregion

  //#region funcion para abrir los detalles de la factura
  const viewDetails = (e, row, dropDown) => {

    let str_fac = `<div class="nav-wizards-container">
                            ${facturacionEstado(row.status)}
                        </div>

                        <div class="card">
                            <div class="card-body">
                            ${Contenido(row)}
                            </div>
                    </div>`;

    MySwal.fire({
      html: str_fac,
      width: 1400,
      title: "Información de Flujo",
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: () => {
        // document.getElementById('returnReason').value === ""
      },
    });
  };
  //#endregion

  //#region funcion para aprobar una factura determinada
  const approveBill = (e, row, dropDown) => {
    console.log(row);
    const approvedSwal = MySwal.fire({
      title: `Desea aprobar la factura ${row.BillNumber} de la Orden ${row.purchaseOrders}?`,
      text: (row.byHito === "1") ? `Monto enviado: ${row.mountHito}` : `Horas enviadas: ${row.hours}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, aprobar.",
      confirmButtonColor: "#2dce89",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          row: row,
          action: "app",
        };
        dispatch(billsActions({ info: info })).then((resp) => {
          const { payload } = resp;

          if (payload.status === 200) {
            setRealTable((prevCheck) => !prevCheck);
            Toast.fire({
              title: "Atención",
              html: "Factura aprobada.",
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.message,
              type: "warning",
            });
          }
          setLoad(false);
        });
      }
    });
  };
  //#endregion

  //#region funcion para devolver/rechazar una factura determinada
  const returnBill = (e, row, dropDown) => {
    let html = `${(row.byHito === "1") ? `<label>Monto aprobado: ${row.mountHito}</label><br>` : ""}<label for="reason">Detalle de la devolución</label><textarea class="form-control" rows="3" id="reason" placeholder="Ingrese el mensaje de la devolución para el consultor Freelance..."></textarea>`;
    MySwal.fire({
      title: `Desea devolver la factura ${row.BillNumber} de la Orden ${row.purchaseOrders}?`,
      html: html,
      confirmButtonText: "Si, devolver.",
      confirmButtonColor: "#f5365c",
      cancelButtonText: "No, cancelar",
      showCancelButton: true,
      preConfirm: () => {
        setLoad(true);
        if (document.getElementById("reason").value === "") {
          MySwal.showValidationMessage("Razón obligatoria");
          setLoad(false);
        } else {
          const userName = getUsernameLogged();
          var info = {
            row: row,
            action: "dev",
            comment: Buffer.from(
              `[${userName} - ${moment().format("DD/MM/YYYY HH:mm:ss")}] ` +
              document.getElementById("reason").value.toString()
            ).toString("base64"),
          };
          dispatch(billsActions({ info: info })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {
              setRealTable((prevCheck) => !prevCheck);
              Toast.fire({
                title: "Atención",
                html: "Se devolvió con éxito",
                type: "success",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.message,
                type: "warning",
              });
            }
            setLoad(false);
          });
        }
      },
    });
  };
  //#endregion

  //#region funcion para reprocesar una factura determinada
  const reprocessBill = (e, row, dropDown) => {
    let html = `<label for="newHes">Número de HES (Opcional)</label><input class="form-control" rows="3" id="newHes" placeholder="En caso de que ya se haya creado la HES por favor ingresarla aquí..."></input>`;
    MySwal.fire({
      title: `Desea reprocesar la factura ${row.BillNumber} de la Orden ${row.purchaseOrders}?`,
      html: html,
      confirmButtonText: "Si, reprocesar.",
      confirmButtonColor: "#2dce89",
      cancelButtonText: "No, cancelar",
      showCancelButton: true,
      preConfirm: () => {
        setLoad(true);
        var info = {
          row: row,
          action: "rep",
          newHes: Buffer.from(document.getElementById("newHes").value.toString()).toString("base64"),
        };
        dispatch(billsActions({ info: info })).then((resp) => {
          const { payload } = resp;

          if (payload.status === 200) {
            setRealTable((prevCheck) => !prevCheck);
            Toast.fire({
              title: "Atención",
              html: "Se reprocesó con éxito",
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.message,
              type: "warning",
            });
          }
          setLoad(false);
        });

      },
    });
  };
  //#endregion

  //#region funcion descargar la factura
  const downloadBill = (e, row, dropDown) => {

    const downloadUrl = urls.FREELANCE.downloadFile + `/${encrypter(row.id)}/${encrypter(`${"2"}`)}`;
    fetch(downloadUrl, {
      method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
    })
      .then(response => {
        if (response.ok) {
          window.location.assign(downloadUrl);
        } else {
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
            type: "warning",

          });
        }
      })
      .catch(error => {
        console.error('Error checking file existence:', error);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error al validar el archivo ",
          type: "warning",

        });
      });
  };
  //#endregion

  //#region funcion descargar el acta de cierre cuando sea hito
  const downloadActa = (e, row, dropDown) => {

    const downloadUrl = urls.FREELANCE.downloadFile + `/${encrypter(row.poId)}/${encrypter(`${"4"}`)}`;

    fetch(downloadUrl, {
                method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
            })
                .then(response => {
                    if (response.ok) {
                        window.location.assign(downloadUrl);
                    } else {
                        Toast.fire({
                            title: "Atención",
                            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
                            type: "warning",
    
                        });
                    }
                })
                .catch(error => {
                    console.error('Error checking file existence:', error);
                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrio un error al validar el archivo ",
                        type: "warning",
    
                    });
                });
  };
  //#endregion

  //#region botones de la tabla MainTable por cada fila de la tabla que dependen del status de la solicitud
  const actions = [
    {
      color: "primary",
      icon: "ni ni-zoom-split-in",
      fun: viewDetails,
      add: 0,
      title: "Información de Factura",
      id: "billDetail",
    },
    {
      color: "success",
      icon: "fas fa-check",
      fun: approveBill,
      add: 3,
      title: "Aprobar Factura",
      id: "approveBill",
    },
    {
      color: "warning",
      icon: "ni ni-bold-left",
      fun: returnBill,
      add: 3,
      title: "Devolver Factura",
      id: "returnBill",
    },
    {
      color: "success",
      icon: "fas fa-check",
      fun: approveBill,
      add: true,
      title: "Aprobar Factura",
      id: "approveBill",
    },
    {
      color: "warning",
      icon: "ni ni-bold-left",
      fun: returnBill,
      add: true,
      title: "Devolver Factura",
      id: "returnBill",
    },
    {
      color: "info",
      icon: "ni ni-cloud-download-95",
      fun: downloadBill,
      add: 0,
      title: "Descargar Factura",
      id: "downloadBill",
    },
    {
      color: "dark",
      icon: "ni ni-cloud-download-95",
      fun: downloadActa,
      add: "hitoActa",
      title: "Descargar Acta",
      id: "downloadActa",
    },
    {
      color: "warning",
      icon: "fas fa-sync-alt",
      fun: reprocessBill,
      add: 6,
      title: "Reprocesar Factura",
      id: "reprocessBill",
    },
  ];
  //#endregion

  //#region columnas seleccionados de la tabla MainTable
  const [selected, setSelected] = useState([
    { value: "BillNumber", label: "Id Factura" },
    { value: "consultantName", label: "Consultor" },
    { value: "purchaseOrders", label: "Orden de Compra", disabled: true },
    { value: "descriptions", label: "Cliente/Descripción" },
    { value: "responsible", label: "Responsable" },
    { value: "hes", label: "HES" },
    { value: "hours", label: "Horas" },
    { value: "statusName", label: "Estado", type: "color", optionList: "billsStatus", option: "statusType", val: "status", },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ]);
  //#endregion

  //#region todas columnas de la tabla MainTable
  const optionsSelect = [
    { value: "id", label: "Id" },
    { value: "consultantName", label: "Consultor" },
    { value: "BillNumber", label: "Id Factura" },
    { value: "createdAt", label: "Fecha", type: "date" },
    { value: "purchaseOrders", label: "Orden de Compra", disabled: true },
    { value: "descriptions", label: "Cliente/Descripción" },
    { value: "responsible", label: "Responsable" },
    { value: "commentsDesencrypt", label: "Comentario" },
    { value: "hes", label: "HES" },
    { value: "hours", label: "Horas" },
    { value: "mountHito", label: "Monto Hito" },
    { value: "areaCode", label: "Area" },
    { value: "helpAreas", label: "Area de apoyo" },
    { value: "projects", label: "Proyecto" },
    { value: "approverL1", label: "Aprobador L1" },
    { value: "approvedTsL1", label: "Fecha L1" },
    { value: "approverL2", label: "Aprobador L2" },
    { value: "approvedTsL2", label: "Fecha L2" },
    { value: "statusName", label: "Estado", type: "color", optionList: "billsStatus", option: "statusType", val: "status", },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ];
  //#endregion

  //#endregion

  return (
    <>
      <FiltersMain
        dropDownOptions={dropDownOptions}
        fields={fields}
        buttons={buttons}
        handleOnChangeFilter={handleOnChangeFilter}
        columnTitles={optionsSelect}
        tableParameter={"bill"}
        search={search}
        Toast={Toast}
      />

      <MainTable
        setPage={setPage}
        page={page}
        reloadTable={reloadTable}
        search={search}
        dropDownOptions={dropDownOptions}
        selected={selected}
        setSelected={setSelected}
        optionsSelect={optionsSelect}
        tableParameter={"bill"}
        rolArea={rolArea}
        statusError={"6"}
        isAdmin={isAdmin}
        isL2={isL2}
      />
    </>
  );
};

//funcion para determinar la ruta en la que esta la factura para el sweatAlert del detalle de factura
//que depende del estado de la factura
function facturacionEstado(estado) {
  let resultado;
  switch (estado) {
    case 10:
      resultado = `<nav class="nav nav-wizards-3 mb-2">
                            <div class="nav-item col">
                            <a class="nav-link completed" >
                              <div class="nav-dot"></div>
                              <div class="nav-no">Factura</div>
                              <div class="nav-text">Subir factura</div>
                            </a>
                          </div>
                            <div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Aprobación L1</div>
                                <div class="nav-text">Coordinador</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Aprobación L2</div>
                                <div class="nav-text">Gerente</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Devolución</div>
                                <div class="nav-text">Corregir factura</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Completado</div>
                                <div class="nav-text">Procesado</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Fin</div>
                                <div class="nav-text">Fin de proceso</div>
                              </a>
                            </div> </nav>`;
      break;
    case 9:
      resultado = `<nav class="nav nav-wizards-3 mb-2"><div class="nav-item col">
      <a class="nav-link completed" >
        <div class="nav-dot"></div>
        <div class="nav-no">Factura</div>
        <div class="nav-text">Subir factura</div>
      </a>
    </div><div class="nav-item col">
      <a class="nav-link completed" >
        <div class="nav-dot"></div>
        <div class="nav-no">Aprobación L1</div>
        <div class="nav-text">Coordinador</div>
      </a>
    </div><div class="nav-item col">
      <a class="nav-link completed" >
        <div class="nav-dot"></div>
        <div class="nav-no">Aprobación L2</div>
        <div class="nav-text">Gerente</div>
      </a>
    </div><div class="nav-item col">
      <a class="nav-link completed" >
        <div class="nav-dot"></div>
        <div class="nav-no">Devolución</div>
        <div class="nav-text">Corregir factura</div>
      </a>
    </div><div class="nav-item col">
      <a class="nav-link completed" >
        <div class="nav-dot"></div>
        <div class="nav-no">Completado</div>
        <div class="nav-text">Procesado</div>
      </a>
    </div><div class="nav-item col">
      <a class="nav-link disabled" >
        <div class="nav-dot"></div>
        <div class="nav-no">Fin</div>
        <div class="nav-text">Fin de proceso</div>
      </a>
    </div> </nav>`;
      break;
    case 3:
      resultado = `<nav class="nav nav-wizards-3 mb-2"><div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Factura</div>
                                <div class="nav-text">Subir factura</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Aprobación L1</div>
                                <div class="nav-text">Coordinador</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Aprobación L2</div>
                                <div class="nav-text">Gerente</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Devolución</div>
                                <div class="nav-text">Corregir factura</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Completado</div>
                                <div class="nav-text">Procesado</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Fin</div>
                                <div class="nav-text">Fin de proceso</div>
                              </a>
                            </div> </nav>`;
      break;
    case 4:
      resultado = `<nav class="nav nav-wizards-3 mb-2">
                          <div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Factura</div>
                                <div class="nav-text">Subir factura</div>
                              </a>
                            </div>
                            <div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Aprobación L1</div>
                                <div class="nav-text">Coordinador</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Aprobación L2</div>
                                <div class="nav-text">Gerente</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Devolución</div>
                                <div class="nav-text">Corregir factura</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Completado</div>
                                <div class="nav-text">Procesado</div>
                              </a>
                            </div><div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Fin</div>
                                <div class="nav-text">Fin de proceso</div>
                              </a>
                            </div> </nav>`;
      break;
    case 11:
      resultado = `<div class="nav nav-wizards-3 mb-2">
                            <div class="nav-item col">
                              <a class="nav-link completed" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Factura</div>
                                <div class="nav-text">Subir factura</div>
                              </a>
                            </div>
                            <div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Aprobación L1</div>
                                <div class="nav-text">Coordinador</div>
                              </a>
                            </div>
                            <div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Aprobación L2</div>
                                <div class="nav-text">Gerente</div>
                              </a>
                            </div>
                            <div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Devolución</div>
                                <div class="nav-text">Corregir factura</div>
                              </a>
                            </div>
                            <div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Completado</div>
                                <div class="nav-text">Procesado</div>
                              </a>
                            </div>
                             <div class="nav-item col">
                              <a class="nav-link disabled" >
                                <div class="nav-dot"></div>
                                <div class="nav-no">Fin</div>
                                <div class="nav-text">Fin de proceso</div>
                              </a>
                            </div>
                            </nav>`;
      break;
    case 2:
      resultado = `<nav class="nav nav-wizards-3 mb-2"><div class="nav-item col">
                                    <a class="nav-link completed" >
                                      <div class="nav-dot"></div>
                                      <div class="nav-no">Factura</div>
                                      <div class="nav-text">Subir factura</div>
                                    </a>
                                  </div><div class="nav-item col">
                                    <a class="nav-link completed" >
                                      <div class="nav-dot"></div>
                                      <div class="nav-no">Aprobación L1</div>
                                      <div class="nav-text">Coordinador</div>
                                    </a>
                                  </div><div class="nav-item col">
                                    <a class="nav-link completed" >
                                      <div class="nav-dot"></div>
                                      <div class="nav-no">Aprobación L2</div>
                                      <div class="nav-text">Gerente</div>
                                    </a>
                                  </div><div class="nav-item col">
                                    <a class="nav-link completed" >
                                      <div class="nav-dot"></div>
                                      <div class="nav-no">Devolución</div>
                                      <div class="nav-text">Corregir factura</div>
                                    </a>
                                  </div><div class="nav-item col">
                                    <a class="nav-link disabled" >
                                      <div class="nav-dot"></div>
                                      <div class="nav-no">Completado</div>
                                      <div class="nav-text">Procesado</div>
                                    </a>
                                  </div><div class="nav-item col">
                                    <a class="nav-link disabled" >
                                      <div class="nav-dot"></div>
                                      <div class="nav-no">Fin</div>
                                      <div class="nav-text">Fin de proceso</div>
                                    </a>
                                  </div> </nav>`;
      break;
    default:
      resultado = `<nav class="nav nav-wizards-3 mb-2"><div class="nav-item col">
                                  <a class="nav-link disabled" >
                                    <div class="nav-dot"></div>
                                    <div class="nav-no">Factura</div>
                                    <div class="nav-text">Subir factura</div>
                                  </a>
                                </div><div class="nav-item col">
                                  <a class="nav-link disabled" >
                                    <div class="nav-dot"></div>
                                    <div class="nav-no">Aprobación L1</div>
                                    <div class="nav-text">Coordinador</div>
                                  </a>
                                </div><div class="nav-item col">
                                  <a class="nav-link disabled" >
                                    <div class="nav-dot"></div>
                                    <div class="nav-no">Aprobación L2</div>
                                    <div class="nav-text">Gerente</div>
                                  </a>
                                </div><div class="nav-item col">
                                  <a class="nav-link disabled" >
                                    <div class="nav-dot"></div>
                                    <div class="nav-no">Devolución</div>
                                    <div class="nav-text">Corregir factura</div>
                                  </a>
                                </div><div class="nav-item col">
                                  <a class="nav-link disabled" >
                                    <div class="nav-dot"></div>
                                    <div class="nav-no">Completado</div>
                                    <div class="nav-text">Procesado</div>
                                  </a>
                                </div><div class="nav-item col">
                                  <a class="nav-link disabled" >
                                    <div class="nav-dot"></div>
                                    <div class="nav-no">Fin</div>
                                    <div class="nav-text">Fin de proceso</div>
                                  </a>
                                </div> </nav>`;
      break;

  }
  return resultado;
}
//funcion para determinar si un campo es null o undefined ponerlo en blanco en el input del sweatAlert
function Nullable(valor) {
  let resultado;
  if (valor === null || valor === "" || valor === undefined) {
    resultado = "";
  } else {
    resultado = valor;
  }
  return resultado;
}
//funcion para armar el HTML del sweatAlert para ver el detalle de la factura
function Contenido(row) {
  if (row.comments == null) {
    row.comments = "Tm8gaGF5IG5pbmfDum4gY29tZW50YXJpbyBwb3IgZWwgbW9tZW50by4="; //'No hay ningún comentario por el momento.';
  }


  let resultado = `
            <div class="row sp_r fila_plataforma">
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label class="control-label">Proveedor</label>
                   <input readonly="" type="text" style ="text-align: center;" class="form-control" value="${Nullable(
    row.consultantName
  )}" />
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label class="control-label">Purchase Orders</label>
                   <input readonly=""  type="text" style ="text-align: center;" class="form-control" value="${Nullable(
    row.purchaseOrders
  )}" />
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label class="control-label">Número de Factura</label>
                   <input readonly=""  type="text" style ="text-align: center;" class="form-control" value="${Nullable(
    row.BillNumber
  )}" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="control-label">Descripcion</label>
                     <input readonly="" type="text" style ="text-align: center;"  class="form-control" value="${Nullable(
    row.descriptions
  )}" />
                </div>
            </div>
        </div>
        <div class="row sp_r fila_plataforma">
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label class="control-label">${(row.byHito.toString() === "1") ? "Monto Aprobado" : "Cantidad de horas"}</label>
                   <input readonly=""  type="text" style ="text-align: center;" class="form-control" value="${Nullable(
    (row.byHito.toString() === "1") ? row.mountHito : row.hours)}" />
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label class="control-label">HES</label>
                     <input readonly="" type="text" style ="text-align: center;" class="form-control" value="${Nullable(
      row.hes
    )}" />
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label class="control-label">Área</label>
                   <input readonly="" type="text" style ="text-align: center;" class="form-control" value="${Nullable(
      row.areaCode
    )}" />
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label class="control-label">Fecha de creacion</label>
                     <input readonly=""  type="text" style ="text-align: center;" class="form-control" value="${Nullable(
      row.Cdate
    )}" />
                </div>
            </div>
        </div>
        <div class="row sp_r fila_plataforma">
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label class="control-label">Aprobador L1</label>
                   <input readonly=""  type="text" style ="text-align: center;" class="form-control" value="${Nullable(
      row.approverL1
    )}" />
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label class="control-label">Aprobador L2</label>
                     <input readonly="" type="text" style ="text-align: center;" class="form-control" value="${Nullable(
      row.approverL2
    )}" />
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label class="control-label">Fecha Aprb. L1</label>
                   <input readonly="" type="text" style ="text-align: center;" class="form-control" value="${Nullable(
      row.TsL1
    )}" />
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label class="control-label">Fecha Aprb. L2</label>
                     <input readonly="" type="text" style ="text-align: center;" class="form-control" value="${Nullable(
      row.TsL2
    )}" />
                </div>
            </div>
        </div>
        <div class="row sp_r fila_plataforma">
            <div class="col-md-12 col-md-offset-1">
                <div class="form-group">
                    <label class="control-label">Comentarios del aprobador</label>
                   <textarea readonly="" class="form-control" rows="2" >${Buffer.from(
      Nullable(row["comments"]),
      "base64"
    ).toString()}</textarea>
                </div>
            </div>
        </div>
`;
  return resultado;
}

export default MainBilling;
