import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const requirementForm = handleActions({
  [constants.GET_INITIAL_VALUES_REQUERIMENT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.data;
    }
    return {};
  },
  // [constants.CLEAR_DATA]: () => { return {} },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const requeriment = handleActions({
  [constants.CREATE_REQUIREMENT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.idCreated;
    }
    return 0;
  },
  [constants.SIGN_OUT]: () => 0,
}, 0);

export const myRequirements = handleActions({
  [constants.GET_ALL_REQUIREMENTS_BY_USER]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.requirements;
    }
    return [];
  },
  [constants.SIGN_OUT]: () => []
}, []);

export const equipmentsByRequirement = handleActions({
  [constants.GET_VALUES_AND_EQUIPMENTS_BY_REQUIREMENT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.data;
    }
    return {};
  },
  [constants.CLEAR_DATA]: () => { return {} },
  [constants.SIGN_OUT]: () => { return {} }
}, {})

export const criticalParts = handleActions({
  [constants.GET_ALL_CRITICAL_PARTS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CREATE_CRITICAL_PART]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { errors, created } = data.payload;
      const { message, criticalParts, length } = state;
      if (criticalParts) {
        return {
          message,
          criticalParts, //: [...criticalParts, ...created],
          errors,
          length: length + created.length
        }
      } else {
        return {
          message: data.payload.message,
          criticalParts: [...created],
          errors,
          length: length + created.length
        }
      }
    }
    return state;
  },
  [constants.UPDATE_CRITICAL_PART]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { id, updatedPart } = data.payload;
      const { message, criticalParts, length, platforms, families, categories } = state;
      return {
        message,
        criticalParts: criticalParts.map((row) => {
          if (row.id === parseInt(id)) {
            return updatedPart;
          }
          return row;
        }),
        platforms, families, categories,
        length
      }
    }
    return state;
  },
  [constants.DELETE_CRITICAL_PART]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { id } = data.payload;
      const { message, criticalParts, length, platforms, families, categories } = state;
      return {
        message,
        criticalParts: criticalParts.filter((row) => row.id !== parseInt(id)),
        length: length - 1,
        platforms, families, categories
      }
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} }
}, {});

export const debuggedModels = handleActions({
  [constants.GET_ALL_DEBUGGED_MODELS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CREATE_DEBUGGED_MODEL]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { errors, created } = data.payload;
      const { message, models, length } = state;
      if (models) {
        return {
          message,
          models, //: [...models, ...created],
          errors,
          length: length + created.length
        }
      } else {
        return {
          message,
          models: [...created],
          errors,
          length: length + created.length
        }
      }
    }
    return state;
  },
  [constants.DELETE_DEBUGGED_MODEL]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { id } = data.payload;
      const { message, models, length } = state;
      return {
        message,
        models: models.filter((row) => row.id !== parseInt(id)),
        length: length - 1
      }
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} }
}, {});