//Imports de React
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Row, Col } from "reactstrap";

import { useDispatch } from 'react-redux';


// imports de Alerts y  Spin
import { Spin } from 'antd';

import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
//import { MyRequestsTableMasterData } from 'components/NewMasterData/MyRequests/MyRequestsTableMasterData.jsx';

// imports de actions
import {
    getOptions,
    getEmployees
} from 'actions/masterData';

import { RequestDetailModal } from 'components/NewMasterData/SharedComponents/RequestDetailModal';
import { AllRequestsTableMasterData } from 'components/NewMasterData/AllRequests/AllRequestsTableMasterData';
import { IndividualFormModal } from 'components/NewMasterData/SharedComponents/IndividualFormModal';
import { getTeamsUserAccess } from 'selectors/adminLayout';
import { NotAccessView } from 'components/NewMasterData/SharedComponents/NotAccessView';


const AllRequestsMasterData = () => {

    // Función para traer solicitudes de la DB
    const dispatch = useDispatch();

    //State para establecer los datos del modal de Detalle de Gestión de mis gestiones.
    const [modalDetail, setModalDetail] = useState({
        //length: 0,
        showDetailModal: false
    })

    const [options, setOptions] = useState([]);




    //#region Extraer los nombres de los empleados del API
    const [employees, setEmployees] = useState({
        employeesOptions: {},
        loadingEmployees: false
    });
    const { employeesOptions, loadingEmployees } = employees;


    //#endregion 


    //State para el formularion individual tipo lineal.
    const [individualFormModal, setIndividualFormModal] = useState({
        showModal: false,
        view: "",
        row: "",
        formName: "",
        newInfo: { info: {}, updateInfo: false }
    })

    //Arreglo grande
    const [stateModalsIndvForms, setStateModalsIndvForms] = useState([])

    //Filtra para averiguar si el usuario posee permisos de master data y mostrar la vista o no 
    const haveMasterDataPerm = getTeamsUserAccess().filter(permission => permission.includes("Master Data"))


    return (
        <>
            {/* <RequestDetailModal
            options={options}
                modalDetail={modalDetail}
                setModalDetail={setModalDetail}
            >

            </RequestDetailModal> */}

            <RequestDetailModal
                options={options}
                modalDetail={modalDetail}
                setModalDetail={setModalDetail}
                //setReloadTableRequests={setReloadTableRequests}

                //Para poder abrir desde este modal el formulario individual
                individualFormModal={individualFormModal}
                setIndividualFormModal={setIndividualFormModal}


                stateModalsIndvForms={stateModalsIndvForms}
                setStateModalsIndvForms={setStateModalsIndvForms}
            >

            </RequestDetailModal>

            {/* <IndividualFormModal
            options={options}
            individualFormModal={individualFormModal}
            setIndividualFormModal={setIndividualFormModal}
            //setReloadTableRequests={setReloadTableRequests}


            > 

            </IndividualFormModal>*/}

            {
                stateModalsIndvForms.length > 0 &&
                stateModalsIndvForms.map((modal) => (
                    <IndividualFormModal
                        options={options}
                        individualFormModal={modal}
                        setIndividualFormModal={setIndividualFormModal}

                        stateModalsIndvForms={stateModalsIndvForms}
                        setStateModalsIndvForms={setStateModalsIndvForms}

                        employeesOptions={employeesOptions}
                    //setReloadTableRequests={setReloadTableRequests}


                    >

                    </IndividualFormModal>
                )
                )
            }


            <AdminHeader name="Todas las Gestiones" parentName="Datos Maestros" />
            <Spin size="large" spinning={false}>
                <Container className="mt--6" fluid>
                    <Card>
                        <CardBody>

                            {
                                haveMasterDataPerm.length > 0 ?

                                    <AllRequestsTableMasterData
                                        setModalDetail={setModalDetail}
                                        options={options}
                                        setOptions ={setOptions}
                                        employees={employees}
                                        setEmployees={setEmployees}
                                    />

                                    :
                                    <NotAccessView />
                            }
                        </CardBody>
                    </Card>
                </Container>
            </Spin>
        </>
    );
};



export default AllRequestsMasterData;