// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const WorkFlowReject = props => {

  const {
    message,
    loading,
    options,
    showModal,
    toggleModal,
    onWorkFlow,
    initialValues,
    onChangeTextOption,
  } = props;

  const validate = () => {
    const errors = {};
    if (!initialValues.option || initialValues.option === "0")
      errors.option = "Debes seleccionar una opción.";
    if ((!initialValues.comment || initialValues.comment === "") && initialValues.option === "3")
      errors.comment = "Debes ingresar el comentario.";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>Rechazo de Ajuste a Oferta</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onWorkFlow}
              validate={validate}
              initialValues={initialValues}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label className="form-control-label" for="option">Opciones de Rechazo</Label>
                      <Field name="option">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              valid={!meta.error && meta.modified && meta.touched}
                              invalid={meta.error && meta.touched}
                              value={initialValues.option}
                              onChange={(e) => onChangeTextOption(e, values.option)}
                            >
                              {
                                options.map((element, key) => (
                                  <option key={key} value={element.key}>{element.name}</option>
                                ))
                              }
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    {
                      parseInt(initialValues.option) === 3 &&
                      <FormGroup>
                        <Label for="comment">Justificación (250)</Label>
                        <Field name="comment">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="textarea"
                                placeholder="Escriba aquí la justificación."
                                maxLength={249}
                                rows="3"
                                resize="none"
                                invalid={meta.error && meta.touched}
                                value={initialValues.comment}
                                onChange={(e) => onChangeTextOption(e, values.option)}
                              />
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    }
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Finalizar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

WorkFlowReject.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default WorkFlowReject;
