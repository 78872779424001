
import React from 'react';
import {
    Col,
    Row,
    ListGroup
} from "reactstrap";
import Approvers from "components/FinanceFlows/FlowsAprobation/Approvers";

const FormApprovers = ({ template, approvers, setApprovers }) => {
    
    return (
        template.length === 0 ?
            <span>Ninguna plantilla encontrada para el formulario ingresado.</span>
            :
            approvers.length > 0 ?

                <Approvers
                    approvers={approvers} />
                :
                template.map((item, key) => (
                    <Row className="mt-3" key={key}>
                        <Col xs="12" key={key}>
                            <button
                                key={key}
                                className="btn-icon-clipboard"
                                type="button"
                                onClick={() => setApprovers(item)}
                            >
                                <div>
                                    <span>{item.description}</span>
                                </div>
                            </button>
                        </Col>
                    </Row>
                ))
    );
};

export default FormApprovers;