/*!

=========================================================
*AutoppCare - LDRTableStatus
=========================================================

* Este componente despliega una tabla con todos los 
formularios LDR para poder tomar acciones como: 
editar y eliminar. 

*Reutiliza el componente dynamicform para poder editar 
el LDR.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/



import { UserContext } from 'components/AutoppLdrs/SharedComponents/UserContext';
import React, {
  useContext, useState, useEffect, useReducer,
} from 'react';

import { Spin } from 'antd';


import {
  Card, TabContent, TabPane, Row, Col, CardHeader, Table, Button, Tooltip, Badge, PaginationItem, PaginationLink,
  Input, FormGroup, CardFooter, Pagination
} from "reactstrap";
// import { UserContext } from './SharedComponents/UserContext';

// react MultiSelect
import { MultiSelect } from "react-multi-select-component";

// imports de actions
import {
  deleteLDR

} from 'actions/autoppLdrs';

import { useDispatch } from 'react-redux';

// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';





export const LDRTableStatus = React.memo(({ options }) => {

  //Dispatch al API.
  const dispatch = useDispatch();

  //#region Notificaciones

  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  //#endregion

  //#region States globales
  const {
    setRefreshOptions,
    spinnerTable,
    setSpinnerTable,

    showComponent,
    setShowComponent,
    setInfoLDRToEdit,
    setTypeLDR,



  } = useContext(UserContext);
  //#endregion

  //Rows de la tabla 
  const [rowsRequests, setRowsRequests] = useState([])

  //Efecto para inicializar los rows de la tabla
  useEffect(() => {
    if (Object.values(options.LDRForms).length > 0) {
      setRowsRequests(Object.values(options.LDRForms).reverse())
    }
  }, [options])

  useEffect(() => {
    // console.log("EL SPINNER TABLE", spinnerTable)
  }, [spinnerTable])



  //Función para eliminar un formulario LDR.
  const deleteLDRF = (row) => {


    MySwal.fire({

      type: 'warning',
      title: `Eliminar Formulario LDR`,
      html:

        `<h2>¿Está seguro que desea eliminar el LDR ${row.label}?</h2>`,

      confirmButtonText: 'Si, eliminar',
      confirmButtonColor: '#f5365c',//#2DCE89
      cancelButtonText: 'No, cancelar',
      showCancelButton: true,

      preConfirm: () => {

        dispatch(deleteLDR({ idLDR: row.idNumber })).then((resp) => {

          const { payload } = resp;
          if (payload.status === 200) {
            Toast.fire({
              title: "Atención",
              html: `¡Documento eliminado con éxito!`,
              type: 'success'
            });

            //Refresca las opciones y pone el spinner
            setRefreshOptions(true);
            setSpinnerTable(true);



          } else {

            Toast.fire({
              title: "Atención",
              html: payload.message,
              type: 'warning'
            });
          };
        })


      },

    })


  }

  //Función para abrir el componente para editar un LDR.
  const handleEditLDR = (row) => {

    // console.log("row", row)


    //No tiene información a editar porque es una creación 
    setInfoLDRToEdit(row)
    //Indicar que es una edición
    setTypeLDR("edit")
    //Indicar abrir el componente
    setShowComponent({ ...showComponent, dinamycForm: true })

  }




  //states de títulos
  const [text, setText] = useState({ title: "Formularios LDR", subtitle: "Edición de levantamiento de requerimientos" })
  let { title, subtitle } = text;

  //#region botones de la tabla MainTable por cada fila de la tabla que dependen del status de la solicitud
  const actions = [
    {
      color: "success",
      icon: "fa fa-edit",
      fun: handleEditLDR,
      title: "Editar documento LDR",
      id: "editLDR",
    },
    {
      color: "warning",
      icon: "fa fa-trash",
      fun: deleteLDRF,
      title: "Eliminar formulario",
      id: "deleteLDR",
    },
  ];
  //#endregion


  //Columnas para el multicolumn y si la seleccionan se agrega al columnsSelected
  const optionsToSelect = [

    { label: "Acciones", value: "actions", buttons: actions, disabled: true, width: 40 },
    { label: "Id", value: "idNumber", width: 20 },
    { label: "Nombre", value: "label", width: 30 },
    { label: "Ciclo de Ventas", value: "cycleName", width: 50 },
    { label: "Brand", value: "brandName", width: 30 },
    { label: "Estado", value: "stage", width: 30 },
    { label: "Creado por", value: "createdBy", width: 30 },
    { label: "Creado en", value: "createdAt", width: 30 },
    { label: "Actualizado por", value: "updatedBy", width: 30 },
    { label: "Actualizado en", value: "updatedAt", width: 30 },

  ];


  //Columnas seleccionadas por defecto en la tabla.
  const [columnsSelected, setColumnsSelected] = useState(
    [

      { label: "Acciones", value: "actions", buttons: actions, disabled: true, width: 40 },
      { label: "Id", value: "idNumber", width: 20 },
      { label: "Nombre", value: "label", width: 30 },
      { label: "Estado", value: "stage", width: 30 },
      { label: "Ciclo de Ventas", value: "cycleName", width: 50 },
      { label: "Brand", value: "brandName", width: 30 },
    ]
  );

  //Para refrescar la tabla
  const handleRefreshTable = () => {
    setSpinnerTable(true)
    setRefreshOptions(true)
  }


  //Filtros request tables
  const [filters, setFilters] = useState([]);

  const renderRowsByFilters = (rows) => {

    if (Object.keys(filters).length === 0) {
      return rows
    } else {
      const filterKeys = Object.keys(filters);
      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();
        rows = rows.filter((item) => item[element].toString().toLowerCase().indexOf(valueFiltered) != -1)
      }
      return rows
    }
  }

  //#region States Locales

  const [sizePerPage, setSizePerPage] = useState(5)
  const rows = renderRowsByFilters(rowsRequests);



  //state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);

  //console.log(rows);

  //#endregion

  //#region Métodos
  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i} 
        >
          <PaginationLink
            onClick={() => handleOnSetPage(i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }



  //#endregion

  //#region Handles 

  //Establece el número de filas por pagina 
  const handleOnSetPage = (page) => {
    setPage(page);
  };

  const handleSetNumPag = (e) => {
    setPage(1);
    const value = e.target.value;
    setSizePerPage(value);
  }

  //Capturar los valores de los inputs de los filtros
  const handleOnfilterDynamic = (constant, e) => {
    console.log(constant, e)
    setFilters(prevState => ({
      ...prevState,
      [constant]: e
    }))
  }

  //#endregion








  return (
    <Card className="">
      <CardHeader className="">
        <Row>
          <Col md="auto" xl="auto" className="text-md-left">
            <div className="ml-3">
              <h6 className="surtitle">{subtitle}</h6>
              <h5 className="h3 mb-0">{title}</h5>
            </div>

          </Col>
          <Col md="auto" xl="auto" className="text-md-right">
            <MultiSelect
              options={optionsToSelect}
              value={columnsSelected}
              onChange={setColumnsSelected}
              labelledBy="Select"
            />
          </Col>
          <Col md="auto" xl="auto" className="ml-md-auto text-md-right">
            <Button
              className="btn-icon-only"
              outline
              color="info"
              type="button"
              title={"Refrescar tabla"}
              id={"buttonRefresh"}
              onClick={(e) => handleRefreshTable()}

            >
              <span className="btn-inner--icon mr-">
                <i className="fas fa-sync-alt" />
              </span>
            </Button>

            <Tooltip target={"buttonRefresh"} placement="top">
              {"Refrescar tabla"}
            </Tooltip>
          </Col>

        </Row>
      </CardHeader>
      <Spin size="large" spinning={spinnerTable}>
        <Table
          className="align-items-center table-flush"
          responsive
          striped={true}
          hover={true}

        >
          <thead className="thead-light">
            {/* <tr>
              {
                columnsSelected.map((col, key) => (
                  <th key={"title" + key} className="text-center">
                    {col.label}
                  </th>))
              }
            </tr> */}

            <tr>
              {columnsSelected.map((col, key) => (


                col.value != "actions" ?

                  <th>
                    <FormGroup key={key}>
                      <label
                        style={{ fontSize: "12px" }}
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        {`${col.label}:`}
                      </label>
                      <Input
                        id={col.id}
                        key={key}
                        className="form-control"
                        type="text"
                        placeholder={`Buscar ${col.label}...`}
                        onChange={(e) => handleOnfilterDynamic(col.value, e.target.value)}
                      />
                    </FormGroup>
                  </th>
                  :
                  <th>
                    {/* Si es actions que no imprima la columna */}
                  </th>



              ))
              }
            </tr>
          </thead>


          <tbody className="list">
            {
              rows && rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (

                //rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {

                <tr key={"row" + key} className="text-center">
                  {
                    row && row != undefined && columnsSelected.map((col, key) => (

                      col.value === "actions" ?
                        <th key={key} >



                          {col.buttons.map((butt, key3) => {
                            return (

                              <>

                                <Button
                                  className="btn-icon-only"
                                  outline
                                  color={butt.color}
                                  type="button"
                                  key={"button" + key3}
                                  onClick={(e) => butt.fun(row)}
                                  title={butt.title}
                                  id={butt.id}
                                >
                                  <span className="btn-inner--icon">
                                    <i className={butt.icon} />
                                  </span>
                                </Button>

                                <Tooltip key={"Tooltip" + key3} target={butt.id}>{butt.title}</Tooltip>
                              </>
                            )
                          })
                          }




                          {row[col.value]}

                        </th>
                        : col.value === "stage" ?
                          <th key={key} >
                            {


                              // <Button
                              //   className="btn-icon-only"
                              //   color={row["buttonColor"]}
                              //   type={"button"}
                              //   key={"stage" + col.value}
                              //   id={col.value + "stage"}
                              //   style={{ width: "90px ", height: "30px" }}
                              // >
                              //   <span className="btn-inner--icon">
                              //   </span>
                              //   {row[col.value]}
                              // </Button>

                              <Badge key={"stage" + col.value} color={row["buttonColor"]} pill style={{ fontSize: "0.6rem" }}>
                                {row[col.value]}
                              </Badge>



                            }

                          </th>
                          :
                          <th key={key} className="p-0">
                            {/* style={{ whiteSpace: "normal", textAlign: "justify" }} className="justify-content-md-center p-0"*/}

                            {row[col.value]}


                          </th>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Spin>
      <CardFooter>

        <Row className="align-items-center ">
          <Col xs='12' md='6' className="p-1 ">
            <Row className="justify-content-start">
              <Col xs='12' md='6'>
                <span className='pagination mb-0'>
                  Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                </span>
              </Col>
              <Col xs='12' md='2'>
                <Input type="select"
                  onChange={(e) => handleSetNumPag(e)}>
                  <option>5</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                </Input>
              </Col>
            </Row>
          </Col>

          <Col xs='12' md='6' className="p-1 ">
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem className={page === 1 ? "disabled" : ""}>
                <PaginationLink
                  onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                  tabIndex="-1"
                >
                  <i className="fas fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
              {
                renderPaginations()
              }
              <PaginationItem className={page === paginations ? "disabled" : ""}>
                <PaginationLink
                  onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                >
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>

      </CardFooter>
    </Card >
  )
})

export default LDRTableStatus;
