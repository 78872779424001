/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "CardMain" el cual Posee el diseño de los Cards de la vista principal

*Props:
    subtitle: el subtitulo del Card
    setFormSelected: useState que actualiza el tab activo del tabContent del componente MainComponent
    id: el id del card seleccionado que concuerda con el del tabPanel del tabContent del componente MainComponent
    image: la imagen del card
    colorCardSelected: el color del card al ser seleccionado
    formSelected: useState que guarda el tab activo del tabContent del componente MainComponent
=========================================================

* Coded by Diego Meza - Application Management GBM

*/
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody} from "reactstrap";


export const CardMain = React.memo(({ title,
    subtitle,
    setFormSelected,
    id,
    image,
    colorCardSelected,
    formSelected
 }) => {

    return (
        <>
            <Card
                className="card-stats card-lift--hover"
                style={{backgroundColor: (formSelected===id) ? colorCardSelected : '#ffffff', cursor: "pointer" }}
                onClick={() => setFormSelected(id)}
            >
                <CardBody>

                    <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require(`assets/img/freelance/${image}`)}
                        style={{ objectFit: "contain", width: "60px", height: "60px" }}
                    />

                    <div className="pt-4 text-center">
                        <h5 className="h3 title">
                            <span className="d-block mb-1">{title}</span>
                            <small className="h4 font-weight-light text-muted">
                                {subtitle}
                            </small>
                        </h5>
                    </div>
                </CardBody>
            </Card>
        </>
    )
})