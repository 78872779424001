// core actions
import {
  createAjustOfferRequest,
  createCommentaryByRequest,
  createEquipmentSpare,
  createJustifyByRequest,
  createManyEquipments,
  createNewVersionByRejectRequest,
  createNewVersionByRequest,
  createOneEquipment,
  createServiceOrderRequest,
  deactivateConfigurations,
  deactivateEquipmentById,
  deactivateEquipmentSpareById,
  deactivateReferences,
  deactivateReferencesSpare,
  findActivityFlowRequest,
  findConfigurationsByRequest,
  findEquipmentsByRequest,
  findEquipmentsCreatedByRequest,
  findEquipmentsSpareByRequest,
  findFormValuesEquipmentsRequest,
  findFormValuesRequestOpportunity,
  findJustificationsByRequest,
  findLastVersionByRequest,
  findOptionsRequestToVersion,
  findReferencesByRequest,
  findReferencesSpareByRequest,
  findRequerimentsByUser,
  findResumeOffersRequestById,
  sendEmailOfferWonRequest,
  updateActivityFlow,
  updateEquipmentById,
  updateEquipmentSpareById,
  updateStateRequest,
  updateAmountEquipmentsByRequest,
} from "actions/Sales/digitalRequest.jsx";
import { findOffersRequestById } from "actions/Sales/pricing.jsx";
// antd components
import { message, Spin } from "antd";
import CardConfiguration from "components/Sales/Request/CardConfiguration.jsx";
import CardRequeriments from "components/Sales/Request/CardRequeriments.jsx";
import ConfigurationsList from "components/Sales/Request/ConfigurationsList.jsx";
import EquipmentsError from "components/Sales/Request/EquipmentsError.jsx";
import EquipmentsList from "components/Sales/Request/EquipmentsList.jsx";
import EquipmentsListForm from "components/Sales/Request/EquipmentsListForm.jsx";
import EquipmentsUbicationsModal from "components/Sales/Request/EquipmentsUbicationsModal.jsx";
import FiltersCard from "components/Sales/Request/FiltersCard.jsx";
import RequestDetail from "components/Sales/Request/RequestDetail.jsx";
import ResumeOffers from "components/Sales/Request/ResumeOffers.jsx";
import SpareEquipments from "components/Sales/Request/SpareEquipments.jsx";
import AddEquipments from "components/Sales/Request/Versions/AddEquipments.jsx";
import DeleteEquipments from "components/Sales/Request/Versions/DeleteEquipments.jsx";
import NewVersionModal from "components/Sales/Request/Versions/NewVersionModal.jsx";
import WorkFlowModal from "components/Sales/Request/WorkFlowModal.jsx";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getActivityFlowRequest,
  getCommentsConfigurationsByRequest,
  getConfigurationsByRequest,
  getEquipmentsCreated,
  getEquipmentsSpare,
  getInitialValuesEquipments,
  getInitialValuesFormEquipments,
  getJustificationsByRequest,
  getLastVersionByRequest,
  getOptionsFilterRequests,
  getOptionsFormToVersion,
  getReferencesByRequest,
  getReferencesSpareByRequest,
  getRequerimentsByUser,
  getResumeOffersByRequest,
} from "selectors/Sales/requests.jsx";
import { getOffersCalc } from "selectors/Sales/pricing.jsx";
// javascript library to parser xlsx to json
const XLSX = require("xlsx");

class MyRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      openEditEquipment: false,
      enabled: false,
      loading: false,
      loadingEdit: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      requestSelected: null,
      equipmentVisible: null,
      configurationView: null,
      amountEquipmentsCont: 0,
      loadFile: false,
      fileList: [],
      justify: null,
      commentary: null,
      referenceFilesList: [],
      referencesRemoved: [],
      referenceSpareFileList: [],
      referenceSpareRemoved: [],
      configurationsFilesList: [],
      configurationsRemoved: [],
      referenceAjustFilesList: [],
      referenceAjustRemoved: [],
      ibmEquipments: [],
      ciscoEquipments: [],
      flow: {
        IBM: "created",
        CISCO: "created",
      },
      flowModal: {
        type: null,
        showModal: false,
        title: null,
        options: [],
      },
      oppNumberSelected: {
        value: 0,
        label: "Todos",
      },
      clientSelected: {
        value: 0,
        label: "Todos",
      },
      stateSelected: {
        value: 0,
        label: "Todos",
      },
      dateSelected: null,
      initialValues: {},
      equipmentAdded: true,
      equipmentsValues: {
        country: undefined,
        typeModel: undefined,
        serial: undefined,
        platform: undefined,
        amountMaintenance: undefined,
        amountEquipments: undefined,
        // description: null,
        practice: undefined,
        officeHours: undefined,
        timeChangePart: undefined,
        validityService: undefined,
        automaticRenewal: undefined,
        equipmentParts: undefined,
        // amountHRMant: null,
        viatic: undefined,
        validWarranty: undefined,
        price: undefined,
      },
      equipmentsEditValues: {
        id: undefined,
        country: undefined,
        typeModel: undefined,
        serial: undefined,
        platform: undefined,
        amountMaintenance: undefined,
        amountEquipments: undefined,
        practice: undefined,
        officeHours: undefined,
        timeChangePart: undefined,
        validityService: undefined,
        automaticRenewal: undefined,
        equipmentParts: undefined,
        viatic: undefined,
        validWarranty: undefined,
        price: undefined,
      },
      editEquipment: false,
      spareVisibility: false,
      addSpare: false,
      editSpare: false,
      spareValues: {
        partNumber: undefined,
        description: undefined,
        amountEquipments: undefined,
        cost: undefined,
      },
      spareEditValues: {
        id: undefined,
        partNumber: undefined,
        description: undefined,
        amountEquipments: undefined,
        cost: undefined,
      },
      referenceView: false,
      configSC: false,
      amountInEdit: 0,
      viewResume: false,
      showModalVersions: false,
      typeVersion: {
        type: "",
        name: "",
      },
      loadingVersion: false,
      messageVersion: null,
      deleteEquipments: false,
      updateEquipments: false,
      addEquipments: false,
      newValuesEquipmentsToAdd: {},
      newVersion: "",
      equipmentsDeleteSelected: {},
      equipmentsAddCreated: [],
      equipmentsAddCreatedKeys: [],
      newIdRequest: null,
      equipmentsErrors: [],
      modalEquipmentsErrors: false,
      modalEquipmentsUbications: {
        showModal: false,
        amount: 0,
      },
    };
  }

  componentDidMount() {
    this.findMyRequest();
  }

  onMainStatesValues = () => {
    this.setState({
      alert: null,
      openEditEquipment: false,
      enabled: false,
      loading: false,
      loadingEdit: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      requestSelected: null,
      equipmentVisible: null,
      configurationView: null,
      amountEquipmentsCont: 0,
      loadFile: false,
      fileList: [],
      justify: null,
      commentary: null,
      referenceFilesList: [],
      referencesRemoved: [],
      referenceSpareFileList: [],
      referenceSpareRemoved: [],
      configurationsFilesList: [],
      configurationsRemoved: [],
      referenceAjustFilesList: [],
      referenceAjustRemoved: [],
      ibmEquipments: [],
      ciscoEquipments: [],
      flow: {
        IBM: "created",
        CISCO: "created",
      },
      flowModal: {
        type: null,
        showModal: false,
        title: null,
        options: [],
      },
      oppNumberSelected: {
        value: 0,
        label: "Todos",
      },
      clientSelected: {
        value: 0,
        label: "Todos",
      },
      stateSelected: {
        value: 0,
        label: "Todos",
      },
      dateSelected: null,
      initialValues: {},
      equipmentAdded: true,
      equipmentsValues: {
        country: undefined,
        typeModel: undefined,
        serial: undefined,
        platform: undefined,
        amountMaintenance: undefined,
        amountEquipments: undefined,
        // description: null,
        practice: undefined,
        officeHours: undefined,
        timeChangePart: undefined,
        validityService: undefined,
        automaticRenewal: undefined,
        equipmentParts: undefined,
        // amountHRMant: null,
        viatic: undefined,
        validWarranty: undefined,
        price: undefined,
      },
      equipmentsEditValues: {
        id: undefined,
        country: undefined,
        typeModel: undefined,
        serial: undefined,
        platform: undefined,
        amountMaintenance: undefined,
        amountEquipments: undefined,
        practice: undefined,
        officeHours: undefined,
        timeChangePart: undefined,
        validityService: undefined,
        automaticRenewal: undefined,
        equipmentParts: undefined,
        viatic: undefined,
        validWarranty: undefined,
        price: undefined,
      },
      editEquipment: false,
      spareVisibility: false,
      addSpare: false,
      editSpare: false,
      spareValues: {
        partNumber: undefined,
        description: undefined,
        amountEquipments: undefined,
        cost: undefined,
      },
      spareEditValues: {
        id: undefined,
        partNumber: undefined,
        description: undefined,
        amountEquipments: undefined,
        cost: undefined,
      },
      referenceView: false,
      configSC: false,
      amountInEdit: 0,
      viewResume: false,
      showModalVersions: false,
      loadingVersion: false,
      messageVersion: null,
      deleteEquipments: false,
      updateEquipments: false,
      addEquipments: false,
      newValuesEquipmentsToAdd: {},
      newVersion: "",
      equipmentsDeleteSelected: {},
      equipmentsAddCreated: [],
      equipmentsAddCreatedKeys: [],
      newIdRequest: null,
      equipmentsErrors: [],
      modalEquipmentsErrors: false,
      modalEquipmentsUbications: {
        showModal: false,
        amount: 0,
      },
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  onVerifyPartsAndEquipments = (
    equipmentsFile,
    equipmentsNormal,
    key,
    key2
  ) => {
    return (
      equipmentsFile.some((row) => row["IncluyePartesEquipos"] === key) ||
      equipmentsNormal.some((row) => row["idEquipmentParts"] === key2)
    );
  };

  calcVersionNumber = (version) => {
    return (version + 0.1).toFixed(1);
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  onVerifyValidPrecies = (equipment) => {
    let flag = false;
    equipment.forEach((element) => {
      if (element.Precio) {
        if (
          parseInt(element.Precio) > 99999999 ||
          parseInt(element.Precio) <= 0
        ) {
          flag = true;
        }
      }
    });
    return flag;
  };

  onVerifyPriceEquipments = (ibm, cisco) => {
    let flag = false;
    ibm.forEach((element) => {
      if (element.price === "N/A") {
        flag = true;
      }
    });
    cisco.forEach((element) => {
      if (element.price === "N/A") {
        flag = true;
      }
    });
    return flag;
  };

  onVerifyAllPrices = (ibm, cisco, key) => {
    let flag = false;
    ibm.forEach((element) => {
      if (element[key] === "N/A" || !element[key]) {
        flag = true;
      }
    });
    cisco.forEach((element) => {
      if (element[key] === "N/A" || !element[key]) {
        flag = true;
      }
    });
    return flag;
  };

  onVerifyCriticalParts = (equipment) => {
    const {
      requestSelected: { criticalParts },
    } = this.state;
    // console.log(equipment);
    // console.log(criticalParts);
    for (const element of equipment) {
      // console.log(element);
      // console.log([1, 2, 3].some((row) => row === element.idEquipmentParts));
      if (
        (["Partes", "Equipos", "Partes y Equipos"].some(
          (row) => row === element.IncluyePartesEquipos
        ) &&
          criticalParts === "SÍ") ||
        (element.IncluyePartesEquipos === "No aplica" && criticalParts === "NO")
      ) {
        return true;
      }
      if (
        ([1, 2, 3].some((row) => row === element.idEquipmentParts) &&
          criticalParts === "SÍ") ||
        (element.idEquipmentParts === 4 && criticalParts === "NO")
      ) {
        return true;
      }
    }
    return false;
  };

  findMyRequest = () => {
    this.setState({
      loading: true,
      message: "Cargando los requerimientos",
    });
    this.props.findRequerimentsByUser().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  onUpdateStateRequest = (id, state, message, description, stateText, type) => {
    this.setState((state) => ({
      loading: !state.loading,
      message: message,
    }));
    this.props.updateStateRequest(id, state).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.updateActivityFlow(id, {
          description,
          state: stateText,
          type,
        });
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
          requestSelected: null,
        }));
        this.onMainStatesValues();
        this.findMyRequest();
      }
    });
  };

  _handleOnToggleOpenEditEquipment = () => {
    this.setState((state) => ({
      openEditEquipment: !state.openEditEquipment,
    }));
  };

  _handleOnOpenRequeriment = (row) => {
    const { id } = row;
    // const { equipmentsValues } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Cargando la información del requerimiento`,
    }));
    this.props.findEquipmentsByRequest(id);
    this.props.findEquipmentsSpareByRequest(id);
    this.props.findReferencesByRequest(id);
    this.props.findJustificationsByRequest(id);
    this.props.findEquipmentsByRequest(id);
    this.props.findConfigurationsByRequest(id);
    this.props.findOffersRequestById(id);
    this.props.findActivityFlowRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        // equipmentsValues['country'] = row.country;
        this.setState({
          requestSelected: row,
          // equipmentsValues
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  _handleOnAddEquipments = () => {
    const {
      amountEquipmentsCont,
      equipmentsValues,
      requestSelected: {
        amountOfEquipment,
        country,
        officeHours,
        timeChangePart,
        responseTime,
      },
    } = this.state;
    const cont = amountEquipmentsCont + 1;
    if (cont > amountOfEquipment) {
      this.notify(
        "warning",
        "Atención",
        "La cantidad de equipos no debe sobrepasar a la ingresada en el LDR"
      );
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: "Cargando el formulario de Equipos",
      }));
      this.props.findFormValuesEquipmentsRequest().then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          const flavor =
            officeHours === "24x7" &&
            timeChangePart === "Mejor Esfuerzo" &&
            responseTime === "2 horas"
              ? "gold"
              : officeHours === "24x7" &&
                responseTime === "2 horas" &&
                timeChangePart === "Hasta 4 horas"
              ? "platinum"
              : null;
          equipmentsValues["amountMaintenance"] =
            flavor === "platinum" ? 2 : flavor === "gold" ? 1 : undefined;
          equipmentsValues["country"] = country;
          this.setState((state) => ({
            equipmentVisible: "add",
            loading: !state.loading,
            message: null,
            equipmentsValues,
          }));
        }
      });
    }
  };

  _handleOnViewEquipments = () => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Cargando la lista de Equipos",
    }));
    this.props.findFormValuesEquipmentsRequest();
    this.props.findEquipmentsByRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        this.setState((state) => ({
          equipmentVisible: "view",
          loading: !state.loading,
          message: null,
          flow: {
            IBM: state.requestSelected.state === 1 ? "complete" : "created",
            CISCO: state.requestSelected.state === 1 ? "complete" : "created",
          },
        }));
      }
    });
  };

  _handleOnViewEquipmentsSpare = () => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Cargando la lista de Equipos",
    }));
    this.props.findEquipmentsSpareByRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        this.props.findReferencesSpareByRequest(this.state.requestSelected.id);
        this.setState((state) => ({
          spareVisibility: true,
          addSpare: this.state.requestSelected.state < 5 ? true : false,
          referenceView: this.state.requestSelected.state < 5 ? true : false,
          loading: !state.loading,
          message: null,
        }));
      }
    });
  };

  _handleOnCloseEquipments = () => {
    this.setState((state) => ({
      equipmentVisible: null,
    }));
  };

  _handleOnCloseConfiguration = async () => {
    const { configurationsRemoved, configurationsFilesList } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Saliendo`,
    }));
    for (const element of configurationsFilesList) {
      const {
        response: {
          payload: { idConfiguration },
        },
      } = element;
      configurationsRemoved.push(idConfiguration);
    }
    if (configurationsRemoved.length) {
      await this.props.deactivateConfigurations({
        configurationsIds: configurationsRemoved,
      });
    }
    this.setState((state) => ({
      initialValues: {},
      configurationView: null,
      loading: !state.loading,
      message: null,
    }));
  };

  _handleOnToggleLoadView = () => {
    this.setState((state) => ({
      loadFile: !state.loadFile,
    }));
  };

  _handleOnChangeJustify = (event) => {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  };

  _handleOnFileUpload = async (info) => {
    const { status } = info.file;
    let {
      flow,
      requestSelected: { amountOfEquipment, criticalParts },
    } = this.state;
    if (status === "removed") {
      this.setState({
        fileList: [],
        ibmEquipments: [],
        ciscoEquipments: [],
      });
      message.success(`El archivo ${info.file.name} fue quitado exitosamente.`);
    } else {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
          let { status } = file;
          let isValid = true;
          if (status === "done") {
            message.success(
              `El archivo ${file.name} fue cargado exitosamente.`
            );
            const reader = new FileReader();
            reader.onload = async (event) => {
              const data = event.target.result;
              const workbook = XLSX.read(data, {
                type: "binary",
              });
              let flag = false;
              workbook.SheetNames.forEach((sheetName) => {
                let XL_row_object = XLSX.utils.sheet_to_row_object_array(
                  workbook.Sheets[sheetName]
                );
                // XL_row_object = XL_row_object.map((row) => {
                //   if (!row.Precio) {
                //     row.Precio = 'N/A';
                //   }
                //   return row;
                // });
                if (sheetName === "Lista de Equipos") {
                  if (XL_row_object.length <= amountOfEquipment) {
                    const countries = [
                      { id: "CR", name: "Costa Rica", key: "CR" },
                      { id: "SV", name: "El Salvador", key: "SV" },
                      { id: "GT", name: "Guatemala", key: "GT" },
                      { id: "HN", name: "Honduras", key: "HN" },
                      { id: "MD", name: "Miami", key: "MD" },
                      { id: "NI", name: "Nicaragua", key: "NI" },
                      { id: "PA", name: "Panamá", key: "PA" },
                      { id: "DO", name: "República Dominicana", key: "DO" },
                    ];
                    if (this.onVerifyValidPrecies(XL_row_object)) {
                      flag = true;
                      this.notify(
                        "warning",
                        "Atención",
                        `Se detecto en las lineas de equipos uno o mas precios invalidos`
                      );
                    } else if (!this.onVerifyCriticalParts(XL_row_object)) {
                      flag = true;
                      this.notify(
                        "warning",
                        "Atención",
                        `${
                          criticalParts === "SÍ"
                            ? "Se detecto que el requerimiento requiere partes criticas y no se detecta al menos una linea de equipos que incluya partes críticas"
                            : "Se detecto que el requerimiento no requiere partes criticas y se detecta al menos una linea de equipos que incluye partes críticas"
                        }`
                      );
                    } else {
                      flow["IBM"] = "load";
                      this.setState({
                        ibmEquipments: XL_row_object.map((row) => {
                          const country = countries.find(
                            (ele) => ele.name === row["Pais"]
                          );
                          row["Pais"] = country.id;
                          return row;
                        }),
                      });
                    }
                  } else {
                    this.notify(
                      "warning",
                      "Atención",
                      `La cantidad de equipos no debe sobrepasar a la ingresada en el LDR`
                    );
                  }
                } else if (sheetName === "Equipos CISCO") {
                  if (this.onVerifyValidPrecies(XL_row_object)) {
                    this.notify(
                      "warning",
                      "Atención",
                      `Se detecto en las lineas de equipos IBM uno o mas precios invalidos`
                    );
                    isValid = false;
                  } else {
                    flag = true;
                    flow["CISCO"] = "load";
                    this.setState({
                      ciscoEquipments: XL_row_object,
                    });
                  }
                }
              });
              if (flag) {
                this.setState({
                  fileList: [],
                });
              }
              this.setState((state) => ({
                loadFile: !state.loadFile,
                flow,
              }));
              if (
                !this.state.ibmEquipments.length &&
                !this.state.ciscoEquipments.length &&
                isValid
              ) {
                this.notify(
                  "warning",
                  "Atención",
                  `No se encontraron lineas de equipos en el archivo ${file.name}`
                );
              }
            };
            reader.onerror = function (event) {
              this.notify(
                "warning",
                "Atención",
                `No se logro leer el archivo ${file.name}, ${event.target.error.code}`
              );
            };
            reader.readAsBinaryString(file.originFileObj);
          } else if (status === "error") {
            // this.notify("danger", "Falló", `La carga del archivo ${file.name} fallo.`);
            message.success(`La carga del archivo ${file.name} fallo.`);
          } else if (status === "removed") {
            // this.notify("warning", "Atención", `El archivo ${file.name} fue quitado exitosamente.`);
            message.success(
              `El archivo ${file.name} fue quitado exitosamente.`
            );
          }
        }
        return file;
      });
      this.setState({
        fileList,
        // upload: false,
      });
    }
  };

  _handleOnCreateEquipment = () => {
    const {
      formOptionsEquipments: { practices },
      equipmentsCreated: { ibm },
    } = this.props;
    const {
      requestSelected: {
        id,
        idBusinessModel,
        country,
        officeHours,
        timeChangePart,
        responseTime,
        amountOfEquipment,
        criticalParts,
      },
      equipmentsValues,
      amountEquipmentsCont,
    } = this.state;
    const practiceSelect = practices.find(
      (row) => row.id === parseInt(equipmentsValues.practice)
    );
    const values = Object.keys(equipmentsValues).filter(
      (row) => row !== "price"
    );
    const cont =
      idBusinessModel === 1
        ? parseInt(amountEquipmentsCont) + 1
        : parseInt(amountEquipmentsCont) +
          parseInt(equipmentsValues.amountEquipments);
    const flavor =
      officeHours === "24x7" &&
      timeChangePart === "Mejor Esfuerzo" &&
      responseTime === "2 horas"
        ? "gold"
        : officeHours === "24x7" &&
          responseTime === "2 horas" &&
          timeChangePart === "Hasta 4 horas"
        ? "platinum"
        : null;
    console.log(ibm);
    console.log(equipmentsValues);
    const { typeModel, serial } = equipmentsValues;
    console.log(
      ibm.some((row) => row.typeModel === typeModel && row.serial === serial)
    );
    if (
      timeChangePart !== "Mejor Esfuerzo" &&
      practiceSelect.prevents > parseInt(equipmentsValues.amountMaintenance)
    ) {
      this.notify(
        "warning",
        "Atención",
        `El minimo de cantidad de mantenimientos para la práctica seleccionada es ${practiceSelect.prevents}`
      );
    } else if (
      ibm.some((row) => row.typeModel === typeModel && row.serial === serial)
    ) {
      this.notify(
        "warning",
        "Atención",
        "No se puede crear un equipo con el mismo modelo y serial anteriormente creado"
      );
    } else if (
      values.some(
        (row) =>
          (equipmentsValues[row] === null ||
            equipmentsValues[row] === undefined) &&
          ((idBusinessModel === 1 && row !== "amountEquipments") ||
            (idBusinessModel === 2 && row !== "serial"))
      )
    ) {
      this.notify(
        "warning",
        "Atención",
        "Primero debes completar todos los valores"
      );
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Creando el equipo`,
      }));
      this.props
        .createOneEquipment(id, {
          type: "IBM",
          values: equipmentsValues,
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            this.setState({
              flow: {
                IBM: "created",
                CISCO: "created",
              },
              enabled: true,
              equipmentAdded: true,
              equipmentsValues: {
                country,
                typeModel: undefined,
                serial: undefined,
                platform: undefined,
                amountMaintenance:
                  criticalParts === "NO"
                    ? 0
                    : flavor === "platinum"
                    ? 2
                    : flavor === "gold"
                    ? 1
                    : undefined,
                amountEquipments: undefined,
                practice: undefined,
                officeHours: undefined,
                timeChangePart: undefined,
                validityService: undefined,
                automaticRenewal: undefined,
                equipmentParts: undefined,
                viatic: undefined,
                price: undefined,
              },
              amountEquipmentsCont: cont,
            });
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
    }
  };

  _handleOnCompleteEquipments = () => {
    this.hideAlert();
    const {
      requestSelected: {
        id,
        criticalParts,
        amountOfEquipment,
        idBusinessModel,
      },
      flow,
      ibmEquipments,
    } = this.state;
    // console.log(amountOfEquipment);
    const {
      equipmentsCreated: { ibm },
    } = this.props;
    if (
      !this.onVerifyCriticalParts(ibm) &&
      !this.onVerifyCriticalParts(ibmEquipments)
    ) {
      this.notify(
        "warning",
        "Atención",
        `${
          criticalParts === "SÍ"
            ? "Se detecto que el requerimiento requiere partes criticas y no se detecta al menos una linea de equipo que incluya partes"
            : "Se detecto que el requerimiento no requiere partes criticas y se detecta al menos una linea de equipo que incluye partes"
        }`
      );
      // } else if (idBusinessModel === 1 && ((ibm.length && amountOfEquipment > ibm.length) || (ibmEquipments.length && amountOfEquipment > ibmEquipments.length))) {
      // this.notify("warning", "Atención", `Cantidad de equipos creados es menor a la cantidad ingresada en el LDR`);
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Completando la tarea de Equipos`,
      }));
      this.props
        .updateActivityFlow(id, {
          description: `Carga de la lista de Equipos al requerimiento`,
          state: `Equipos Cargados`,
          type: "IBM",
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            flow["IBM"] = "complete";
            this.props.updateStateRequest(id, 1);
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
            flow: flow,
            equipmentAdded: flow["IBM"] === "complete" ? null : true,
          }));
        });
    }
  };

  _handleOnCreateEquipments = (type) => {
    const {
      requestSelected: { id, amountOfEquipment },
      ibmEquipments,
      ciscoEquipments,
    } = this.state;
    let { flow, enabled } = this.state;
    //if (ibmEquipments.length > amountOfEquipment) {
    //  this.notify("warning", "Atención", "La cantidad de equipos no debe sobrepasar a la ingresada en el LDR");
    // } else {
    this.setState((state) => ({
      loading: !state.loading,
      message: `Creando todos los equipos cargados`,
    }));
    this.props
      .createManyEquipments(id, {
        type,
        equipments: type === "IBM" ? ibmEquipments : ciscoEquipments,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: {
                payload: { errors, message },
              },
            } = res.payload.response;
            this.notify("warning", "Atención", message);
            if (errors.length) {
              this.setState({
                modalEquipmentsErrors: true,
                equipmentsErrors: errors,
              });
            }
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: {
              payload: { errors, message, created },
            },
          } = res.payload;
          if (errors.length) {
            this.setState({
              modalEquipmentsErrors: true,
              equipmentsErrors: errors,
            });
          } else if (created.length) {
            this.notify("success", "Éxito", message);
            flow[type] = "created";
            enabled = true;
            this.props.findEquipmentsCreatedByRequest(id);
          }
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
          flow,
          enabled,
        }));
      });
    // }
  };

  _handleOnSaveAndBack = async () => {
    const {
      requestSelected: { id },
      referenceFilesList,
      referencesRemoved,
      justify,
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Guardando el proceso del requerimiento",
    }));
    if (!referenceFilesList.length && !referencesRemoved.length && !justify) {
      this.props
        .updateActivityFlow(id, {
          description: `Requerimiento guardado, pendiente de completar`,
          state: `Pendiente`,
          type: "pending",
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          } else {
            this.onMainStatesValues();
            this.findMyRequest();
          }
        });
    } else {
      const errors = [];
      if (justify) {
        await this.props
          .createJustifyByRequest(id, {
            justify,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                errors.push({ type: "justify", error: payload.message });
              } else {
                errors.push({
                  type: "justify",
                  error: "No se logro establecer conexion con el servidor.",
                });
              }
            }
          });
      }
      if (referencesRemoved.length) {
        await this.props
          .deactivateReferences({
            referencesIds: referencesRemoved,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                errors.push({ type: "references", error: payload.message });
              } else {
                errors.push({
                  type: "references",
                  error: "No se logro establecer conexion con el servidor.",
                });
              }
            }
          });
      }
      if (errors.length) {
        this.notify(
          "danger",
          "Error",
          "Se presento un error intentando guardar la información"
        );
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        this.props
          .updateActivityFlow(id, {
            description: `Requerimiento guardado, pendiente de completar`,
            state: `Pendiente`,
            type: "pending",
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              this.onMainStatesValues();
              this.props.findActivityFlowRequest(id);
            }
          });
      }
    }
  };

  _handleOnCompleteRequest = async (flag) => {
    const {
      requestSelected: { id, idBusinessModel },
      flow,
      referenceFilesList,
      referencesRemoved,
      justify,
      ibmEquipments,
      ciscoEquipments,
    } = this.state;
    const {
      equipmentsCreated: { ibm, cisco },
      referencesRequest,
      justifications,
    } = this.props;
    if (this.onVerifyPriceEquipments(ibm, cisco)) {
      this.notify(
        "warning",
        "Atención",
        "Se detectaron lineas de equipos sin precios, para avanzar es necesario que se ingresen todos los precios"
      );
    } else if (
      flag &&
      !referenceFilesList.length &&
      !referencesRequest.length &&
      justify === null &&
      !justifications.length
    ) {
      this.notify(
        "warning",
        "Atención",
        "Es obligatorio adjuntar la referencia o escribir una justificación para el o los precios agregados"
      );
    } else {
      if (flow["IBM"] !== "complete" && (ibmEquipments.length || ibm.length)) {
        this.notify(
          "warning",
          "Atención",
          "Aun no se han completado los equipos"
        );
      } else if (
        flow["CISCO"] !== "complete" &&
        (ciscoEquipments.length || cisco.length)
      ) {
        this.notify(
          "warning",
          "Atención",
          "Aun no se han completado los equipos"
        );
      } else {
        const partsFlow = this.onVerifyPartsAndEquipments(
          ibmEquipments,
          ibm,
          "Partes",
          1
        );
        const equipmentsFlow = this.onVerifyPartsAndEquipments(
          ibmEquipments,
          ibm,
          "Equipos (Spare)",
          2
        );
        const partsEquipmentsFlow = this.onVerifyPartsAndEquipments(
          ibmEquipments,
          ibm,
          "Partes y Equipos (Spare)",
          3
        );
        if (!partsFlow && !equipmentsFlow && !partsEquipmentsFlow) {
          console.log("No lleva partes y equipos");
          this.setState({
            loading: true,
            message: `Completando el proceso de la oportunidad`,
          });
          const errors = [];
          if (justify) {
            await this.props
              .createJustifyByRequest(id, {
                justify,
              })
              .then((res) => {
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    errors.push({ type: "justify", error: payload.message });
                  } else {
                    errors.push({
                      type: "justify",
                      error: "No se logro establecer conexion con el servidor.",
                    });
                  }
                }
              });
          }
          if (referencesRemoved.length) {
            await this.props
              .deactivateReferences({
                referencesIds: referencesRemoved,
              })
              .then((res) => {
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    errors.push({ type: "references", error: payload.message });
                  } else {
                    errors.push({
                      type: "references",
                      error: "No se logro establecer conexion con el servidor.",
                    });
                  }
                }
              });
          }
          if (errors.length) {
            this.notify(
              "danger",
              "Error",
              "Se presento un error intentando guardar la información"
            );
            this.setState({
              loading: false,
              message: null,
            });
          } else {
            await this.props.updateStateRequest(id, 8).then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState({
                  loading: false,
                  message: null,
                });
              } else {
                const {
                  data: { payload },
                } = res.payload;
                this.notify("success", "Éxito", payload.message);
                this.props.updateActivityFlow(id, {
                  description: `Levantamiento de requerimiento completado`,
                  state: `Completado`,
                  type: "complete",
                });
                this.onMainStatesValues();
                this.findMyRequest();
              }
            });
          }
        } else {
          // console.log(partsFlow, equipmentsFlow, partsEquipmentsFlow);
          if (idBusinessModel === 1) {
            this.setState({
              spareVisibility: equipmentsFlow || partsEquipmentsFlow,
              addSpare: equipmentsFlow || partsEquipmentsFlow,
              configurationView:
                partsFlow || partsEquipmentsFlow ? "add" : null,
              flowModal: {
                type: "configuration",
                showModal: partsFlow && !partsEquipmentsFlow & !equipmentsFlow,
                title: "Verificación de Configuración",
                options: [
                  {
                    parent: true,
                    label: "Tiene la Configuración",
                    type: "select",
                    key: "configuration",
                    visible: true,
                    required: true,
                    options: ["Seleccione una opción", "No", "Sí"],
                  },
                  {
                    parent: false,
                    label: "Orden de Servicio",
                    type: "text",
                    key: "serviceOrder",
                    visible: false,
                    required: true,
                    max: 10,
                    min: 10,
                    options: [],
                    placeholder: "Ingrese la orden de servicio",
                  },
                  {
                    parent: false,
                    label: "Comentarios (Opcional)",
                    type: "textarea",
                    key: "commentary",
                    visible: false,
                    required: false,
                    max: 200,
                    options: [],
                    placeholder: "Ingrese la orden de servicio",
                  },
                ],
              },
              equipments: partsFlow && !partsEquipmentsFlow & !equipmentsFlow,
              loading: true,
              message: "Actualizando el proceso del requerimiento",
            });
            const errors = [];
            if (justify) {
              await this.props
                .createJustifyByRequest(id, {
                  justify,
                })
                .then((res) => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      errors.push({ type: "justify", error: payload.message });
                    } else {
                      errors.push({
                        type: "justify",
                        error:
                          "No se logro establecer conexion con el servidor.",
                      });
                    }
                  }
                });
            }
            if (referencesRemoved.length) {
              await this.props
                .deactivateReferences({
                  referencesIds: referencesRemoved,
                })
                .then((res) => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      errors.push({
                        type: "references",
                        error: payload.message,
                      });
                    } else {
                      errors.push({
                        type: "references",
                        error:
                          "No se logro establecer conexion con el servidor.",
                      });
                    }
                  }
                });
            }
            if (errors.length) {
              this.notify(
                "danger",
                "Error",
                "Se presento un error intentando guardar la información"
              );
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              await this.props
                .updateStateRequest(
                  id,
                  equipmentsFlow ? 2 : partsEquipmentsFlow ? 3 : 4
                )
                .then((res) => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      this.notify("warning", "Atención", payload.message);
                    } else {
                      this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                    }
                  } else {
                    const {
                      data: { payload },
                    } = res.payload;
                    this.notify("success", "Éxito", payload.message);
                  }
                  this.setState({
                    loading: false,
                    message: null,
                  });
                });
            }
          } else if (idBusinessModel === 2) {
            if (partsFlow && !equipmentsFlow && !partsEquipmentsFlow) {
              console.log(
                "Se envía el requerimiento a asignación de recurso para validar partes, no para obtner configuración"
              );
              const errors = [];
              if (justify) {
                await this.props
                  .createJustifyByRequest(id, {
                    justify,
                  })
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        errors.push({
                          type: "justify",
                          error: payload.message,
                        });
                      } else {
                        errors.push({
                          type: "justify",
                          error:
                            "No se logro establecer conexion con el servidor.",
                        });
                      }
                    }
                  });
              }
              if (referencesRemoved.length) {
                await this.props
                  .deactivateReferences({
                    referencesIds: referencesRemoved,
                  })
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        errors.push({
                          type: "references",
                          error: payload.message,
                        });
                      } else {
                        errors.push({
                          type: "references",
                          error:
                            "No se logro establecer conexion con el servidor.",
                        });
                      }
                    }
                  });
              }
              if (errors.length) {
                this.notify(
                  "danger",
                  "Error",
                  "Se presento un error intentando guardar la información"
                );
                this.setState((state) => ({
                  loading: !state.loading,
                  message: null,
                }));
              } else {
                await this.props.updateStateRequest(id, 6).then((res) => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      this.notify("warning", "Atención", payload.message);
                    } else {
                      this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                    }
                    this.setState((state) => ({
                      loading: !state.loading,
                      message: null,
                    }));
                  } else {
                    const {
                      data: { payload },
                    } = res.payload;
                    this.notify("success", "Éxito", payload.message);
                    this.onMainStatesValues();
                    this.findMyRequest();
                  }
                });
              }
            } else {
              this.setState({
                spareVisibility: equipmentsFlow || partsEquipmentsFlow,
                addSpare: equipmentsFlow || partsEquipmentsFlow,
                configurationView: false,
                flowModal: {
                  type: "configuration",
                  showModal: false,
                  title: "Verificación de Configuración",
                  options: [
                    {
                      parent: true,
                      label: "Tiene la Configuración",
                      type: "select",
                      key: "configuration",
                      visible: true,
                      required: true,
                      options: ["Seleccione una opción", "No", "Sí"],
                    },
                    {
                      parent: false,
                      label: "Orden de Servicio",
                      type: "text",
                      key: "serviceOrder",
                      visible: false,
                      required: true,
                      max: 10,
                      min: 10,
                      options: [],
                      placeholder: "Ingrese la orden de servicio",
                    },
                    {
                      parent: false,
                      label: "Comentarios (Opcional)",
                      type: "textarea",
                      key: "commentary",
                      visible: false,
                      required: false,
                      max: 200,
                      options: [],
                      placeholder: "Ingrese la orden de servicio",
                    },
                  ],
                },
                loading: true,
                message: "Actualizando el proceso del requerimiento",
              });
              const errors = [];
              if (justify) {
                await this.props
                  .createJustifyByRequest(id, {
                    justify,
                  })
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        errors.push({
                          type: "justify",
                          error: payload.message,
                        });
                      } else {
                        errors.push({
                          type: "justify",
                          error:
                            "No se logro establecer conexion con el servidor.",
                        });
                      }
                    }
                  });
              }
              if (referencesRemoved.length) {
                await this.props
                  .deactivateReferences({
                    referencesIds: referencesRemoved,
                  })
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        errors.push({
                          type: "references",
                          error: payload.message,
                        });
                      } else {
                        errors.push({
                          type: "references",
                          error:
                            "No se logro establecer conexion con el servidor.",
                        });
                      }
                    }
                  });
              }
              if (errors.length) {
                this.notify(
                  "danger",
                  "Error",
                  "Se presento un error intentando guardar la información"
                );
                this.setState((state) => ({
                  loading: !state.loading,
                  message: null,
                }));
              } else {
                await this.props
                  .updateStateRequest(
                    id,
                    equipmentsFlow ? 2 : partsEquipmentsFlow ? 3 : 7
                  )
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        this.notify("warning", "Atención", payload.message);
                      } else {
                        this.notify(
                          "danger",
                          "Falló",
                          "No se logro establecer conexion con el servidor."
                        );
                      }
                      this.setState({
                        loading: false,
                        message: null,
                      });
                    } else {
                      const {
                        data: { payload },
                      } = res.payload;
                      this.notify("success", "Éxito", payload.message);
                      this.setState({
                        loading: false,
                        message: null,
                        equipments: false,
                        partsEquipmentsFlow,
                        configSC: false,
                      });
                    }
                  });
              }
            }
          }
          // this.setState((state) => ({
          //   loading: !state.loading,
          //   message: `Completando el proceso del requerimiento`
          // }));
          // const errors = [];
          // if (justify) {
          //   await this.props.createJustifyByRequest(id, {
          //     justify
          //   }).then((res) => {
          //     if (res.payload.isAxiosError) {
          //       if (res.payload.response) {
          //         const { data: { payload } } = res.payload.response;
          //         errors.push({ type: 'justify', error: payload.message });
          //       } else {
          //         errors.push({ type: 'justify', error: "No se logro establecer conexion con el servidor." });
          //       }
          //     }
          //   });
          // }
          // if (referencesRemoved.length) {
          //   await this.props.deactivateReferences({
          //     referencesIds: referencesRemoved
          //   }).then((res) => {
          //     if (res.payload.isAxiosError) {
          //       if (res.payload.response) {
          //         const { data: { payload } } = res.payload.response;
          //         errors.push({ type: 'references', error: payload.message });
          //       } else {
          //         errors.push({ type: 'references', error: "No se logro establecer conexion con el servidor." });
          //       }
          //     }
          //   });
          // }
          // if (errors.length) {
          //   this.notify('danger', 'Error', 'Se presento un error intentando guardar la información');
          //   this.setState((state) => ({
          //     loading: !state.loading,
          //     message: null,
          //   }));
          // } else {
          //   this.props.updateStateRequest(id, 2).then((res) => {
          //     if (res.payload.isAxiosError) {
          //       if (res.payload.response) {
          //         const { data: { payload } } = res.payload.response;
          //         this.notify("warning", "Atención", payload.message);
          //       } else {
          //         this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          //       }
          //       this.setState((state) => ({
          //         loading: !state.loading,
          //         message: null,
          //       }));
          //     } else {
          //       this.props.updateActivityFlow(id, {
          //         description: `Levantamiento de requerimiento completado`,
          //         state: `Completado`,
          //         type: 'complete'
          //       });
          //       const { data: { payload } } = res.payload;
          //       this.notify("success", "Éxito", payload.message);
          //       this.onMainStatesValues();
          //       this.findMyRequest();
          //     }
          //   });
          // }
        }
      }
    }
  };

  _handleOnMainView = () => {
    // this.setState({
    //   requestSelected: null,
    // });
    this.onMainStatesValues();
    this.findMyRequest();
    // this.findMyRequest();
  };

  _handleOnChangeFlowRequest = (state) => {
    const {
      requestSelected: { id, criticalParts },
    } = this.state;
    const {
      equipmentsCreated: { ibm, cisco },
      referencesRequest,
      justifications,
    } = this.props;
    if (this.onVerifyPriceEquipments(ibm, cisco)) {
      this.notify(
        "warning",
        "Atención",
        "Se detectaron lineas de equipos sin precios, para avanzar es necesario que se ingresen todos los precios"
      );
    } else if (!referencesRequest.length && !justifications.length) {
      this.notify(
        "warning",
        "Atención",
        "Es necesario adjuntar las referencias o escribir la justificación de los precios agregados"
      );
    } else {
      if (state < 5 && state > 1) {
        if (criticalParts === "NO") {
          // El requerimiento no requiere partes criticas, entonces se envia a validacion de reglas
          this.onUpdateStateRequest(
            id,
            3,
            "Actualizando el flujo",
            "En espera de las reglas de negocio",
            "Pendiente",
            "upgrade"
          );
        } else {
          // El requerimeinto tiene partes criticas, se muestra el modal
          this.setState({
            flowModal: {
              type: "configuration",
              showModal: true,
              title: "Verificación de Configuración",
              options: [
                {
                  parent: true,
                  label: "Tiene la Configuración",
                  type: "select",
                  key: "configuration",
                  visible: true,
                  required: true,
                  options: ["Seleccione una opción", "No", "Sí"],
                },
                {
                  parent: false,
                  label: "Orden de Servicio",
                  type: "text",
                  key: "serviceOrder",
                  visible: false,
                  required: true,
                  max: 10,
                  min: 10,
                  options: [],
                  placeholder: "Ingrese la orden de servicio",
                },
                {
                  parent: false,
                  label: "Comentarios (Opcional)",
                  type: "textarea",
                  key: "commentary",
                  visible: false,
                  required: false,
                  max: 200,
                  options: [],
                  placeholder: "Ingrese la orden de servicio",
                },
              ],
            },
          });
        }
      }
    }
  };

  _handleOnToggleModal = (type) => {
    this.setState({
      [type]: {
        type: null,
        showModal: false,
        title: null,
        options: [],
      },
      initialValues: {},
    });
  };

  _handleOnWorkFlow = async (values, type) => {
    const {
      requestSelected: { id },
      newIdRequest,
    } = this.state;
    if (type === "configuration") {
      const { configuration } = values;
      if (configuration === "1") {
        const { commentary, serviceOrder } = values;
        this.setState((state) => ({
          loading: !state.loading,
          message:
            "Actualizando el flujo y notificando a la oficina de planificación",
        }));
        const errors = [];
        if (commentary) {
          await this.props
            .createCommentaryByRequest(newIdRequest ? newIdRequest : id, {
              commentary,
              hasConfiguration: 0,
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  errors.push({ type: "commentary", error: payload.message });
                } else {
                  errors.push({
                    type: "commentary",
                    error: "No se logro establecer conexion con el servidor.",
                  });
                }
              }
            });
        }
        if (errors.length) {
          this.notify(
            "danger",
            "Error",
            "Se presento un error intentando guardar la información"
          );
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          await this.props
            .createServiceOrderRequest(newIdRequest ? newIdRequest : id, {
              serviceOrder,
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  errors.push({ type: "serviceOrder", error: payload.message });
                } else {
                  errors.push({
                    type: "serviceOrder",
                    error: "No se logro establecer conexion con el servidor.",
                  });
                }
              }
            });
          if (errors.length) {
            this.notify(
              "danger",
              "Error",
              "Se presento un error intentando guardar la información"
            );
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          } else {
            await this.props
              .updateStateRequest(newIdRequest ? newIdRequest : id, 5)
              .then(async (res) => {
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    this.notify("warning", "Atención", payload.message);
                  } else {
                    this.notify(
                      "danger",
                      "Falló",
                      "No se logro establecer conexion con el servidor."
                    );
                  }
                  this.setState((state) => ({
                    loading: !state.loading,
                    message: null,
                  }));
                } else {
                  await this.props.updateActivityFlow(id, {
                    description: "En espera de la asignación de un ingeniero",
                    state: "Asignación Pendiente",
                    type: "upgrade",
                  });
                  const {
                    data: { payload },
                  } = res.payload;
                  this.notify("success", "Éxito", payload.message);
                  this.onMainStatesValues();
                  this.findMyRequest();
                }
              });
          }
        }
      } else {
        this.setState({
          addSpare: false,
          addEquipments: false,
          spareVisibility: false,
          configurationView: "add",
        });
        this._handleOnToggleModal("flowModal");
      }
    }
  };

  _handleOnClearFilters = () => {
    this.setState({
      oppNumberSelected: {
        value: 0,
        label: "Todos",
      },
      clientSelected: {
        value: 0,
        label: "Todos",
      },
      stateSelected: {
        value: 0,
        label: "Todos",
      },
      dateSelected: null,
    });
  };

  _handleOnChangeFilters = (who, event) => {
    this.setState({
      [who]: event,
      page: 1,
    });
  };

  _handleOnRenderRequest = (rows) => {
    const { clientSelected, stateSelected, dateSelected, oppNumberSelected } =
      this.state;
    if (
      clientSelected.value === 0 &&
      stateSelected.value === 0 &&
      dateSelected === null &&
      oppNumberSelected === null
    ) {
      return rows;
    } else {
      if (oppNumberSelected.value !== 0) {
        rows = rows.filter(
          (row) => row.opportunityNumber === oppNumberSelected.label
        );
      }
      if (clientSelected.value !== 0) {
        rows = rows.filter((row) => row.customer === clientSelected.label);
      }
      if (stateSelected.value !== 0) {
        rows = rows.filter((row) => row.status === stateSelected.label);
      }
      if (dateSelected !== null) {
        rows = rows.filter(
          (row) =>
            moment(row.createdAt).format("DD/MM/YYYY") ===
            moment(dateSelected).format("DD/MM/YYYY")
        );
      }
      return rows;
    }
  };

  _handleOnReferenceFileList = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idRerence } = payload;
        this.setState((state) => ({
          referenceFilesList: state.referenceFilesList.filter(
            (row) => row.uid !== uid
          ),
          referencesRemoved: [...state.referencesRemoved, idRerence],
        }));
      } else {
        this.setState((state) => ({
          referenceFilesList: state.referenceFilesList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        referenceFilesList: fileList,
      });
    }
  };

  _handleOnEditEquipment = async (values, type) => {
    const { id } = values;
    // console.log(values)
    this.setState((state) => ({
      loadingEdit: !state.loadingEdit,
    }));
    await this.props
      .updateEquipmentById(id, {
        values,
        type,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this._handleOnToggleOpenEditEquipment();
        }
        this.setState((state) => ({
          loadingEdit: !state.loadingEdit,
        }));
      });
  };

  _handleOnChangeValueFlowModal = (event) => {
    const { name, value } = event.target;
    let { flowModal, initialValues } = this.state;
    if (name === "configuration") {
      flowModal.options.map((row) => {
        if (!row.parent) {
          row.visible = value === "1" ? true : false;
        }
        return row;
      });
      this.setState({
        flowModal,
      });
    }
    initialValues[name] = value;
    this.setState({
      initialValues,
    });
  };

  _handleOnConfigurationUpload = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idConfiguration } = payload;
        this.setState((state) => ({
          configurationsFilesList: state.configurationsFilesList.filter(
            (row) => row.uid !== uid
          ),
          configurationsRemoved: [
            ...state.configurationsRemoved,
            idConfiguration,
          ],
        }));
      } else {
        this.setState((state) => ({
          configurationsFilesList: state.configurationsFilesList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        configurationsFilesList: fileList,
      });
    }
  };

  _handleOnCompleteConfiguration = async () => {
    const {
      configurationsFilesList,
      configurationsRemoved,
      commentary,
      requestSelected: { id },
      newIdRequest,
    } = this.state;
    if (!configurationsFilesList.length) {
      this.notify(
        "warning",
        "Atención",
        "Es requerido que se adjunte la configuración para avanzar"
      );
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Completando el proceso de la configuración`,
      }));
      const errors = [];
      if (commentary) {
        await this.props
          .createCommentaryByRequest(newIdRequest ? newIdRequest : id, {
            commentary,
            hasConfiguration: 1,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                errors.push({ type: "commentary", error: payload.message });
              } else {
                errors.push({
                  type: "commentary",
                  error: "No se logro establecer conexion con el servidor.",
                });
              }
            }
          });
      }
      if (errors.length) {
        this.notify(
          "danger",
          "Error",
          "Se presento un error intentando guardar la información"
        );
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        if (configurationsRemoved.length) {
          await this.props
            .deactivateConfigurations({
              configurationsIds: configurationsRemoved,
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  errors.push({ type: "references", error: payload.message });
                } else {
                  errors.push({
                    type: "references",
                    error: "No se logro establecer conexion con el servidor.",
                  });
                }
              }
            });
        }
        if (errors.length) {
          this.notify(
            "danger",
            "Error",
            "Se presento un error intentando guardar la información"
          );
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          await this.props
            .updateStateRequest(newIdRequest ? newIdRequest : id, 6)
            .then(async (res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState((state) => ({
                  loading: !state.loading,
                  message: null,
                }));
              } else {
                await this.props.updateActivityFlow(
                  newIdRequest ? newIdRequest : id,
                  {
                    description: "Archivos de Configuración Cargados",
                    state: "Configuración Cargada",
                    type: "configuration",
                  }
                );
                await this.props.updateActivityFlow(
                  newIdRequest ? newIdRequest : id,
                  {
                    description: "En espera de la asignación de un ingeniero",
                    state: "Asignación Pendiente",
                    type: "configuration",
                  }
                );
                const {
                  data: { payload },
                } = res.payload;
                this.notify("success", "Éxito", payload.message);
                this.onMainStatesValues();
                this.findMyRequest();
              }
            });
        }
      }
    }
  };

  _handleOnViewConfigurations = async () => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Cargando las configuraciones",
    }));
    await this.props.findConfigurationsByRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        this.setState((state) => ({
          configurationView: "view",
          loading: !state.loading,
          message: null,
        }));
      }
    });
  };

  _handleOnAddEquipment = () => {
    this.setState({
      equipmentAdded: false,
    });
  };

  _handleOnChageEquipmentsValues = (event, key) => {
    const { id, value } = event.target;
    if (key === "edit") {
      const { equipmentsEditValues } = this.state;
      equipmentsEditValues[id] = value;
      this.setState({
        equipmentsEditValues,
        // equipmentAdded: false,
      });
    } else if (key === "add") {
      const { equipmentsValues } = this.state;
      equipmentsValues[id] = value;
      this.setState({
        equipmentsValues,
        // equipmentAdded: false,
      });
    }
  };

  handleOnReferenceSpareUpload = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idReference } = payload;
        this.setState((state) => ({
          referenceSpareFileList: state.referenceSpareFileList.filter(
            (row) => row.uid !== uid
          ),
          referenceSpareRemoved: [...state.referenceSpareRemoved, idReference],
        }));
      } else {
        this.setState((state) => ({
          referenceSpareFileList: state.referenceSpareFileList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
        await this.props
          .findReferencesSpareByRequest(this.state.requestSelected.id)
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexión con el servidor."
                );
              }
            }
          });
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        referenceSpareFileList: fileList,
      });
    }
  };

  handleOnEditEquipmentSpare = (equipment) => {
    const { spareEditValues } = this.state;
    Object.keys(spareEditValues).forEach((element) => {
      spareEditValues[element] = equipment[element];
    });
    this.setState({
      editSpare: true,
      spareEditValues,
    });
  };

  handleOnBackEquipmentSpare = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que desea cancelar?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => {
            this.setState({
              spareVisibility: false,
              addSpare: false,
              referenceView: false,
              alert: null,
              equipmentVisible: null,
            });
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, cancelar"
          cancelBtnText="No, continuar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnSaveEquipmentSpareProcess = async () => {
    const {
      requestSelected: { id },
      referenceSpareRemoved,
    } = this.state;
    const { equipmentsSpare } = this.props;
    if (equipmentsSpare.length) {
      this.setState((state) => ({
        loading: !state.loading,
        message: "Guardando el proceso del requerimiento",
      }));
      if (referenceSpareRemoved.length) {
        await this.props
          .deactivateReferencesSpare({
            referencesIds: referenceSpareRemoved,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexión con el servidor."
                );
              }
            }
          });
      }
      this.props
        .updateActivityFlow(id, {
          description: `Equipos Spare pendiente de completar`,
          state: `Pendiente`,
          type: "pending",
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          } else {
            this.onMainStatesValues();
            this.findMyRequest();
          }
        });
    } else {
      this.notify(
        "warning",
        "Atención",
        "No se detectaron cambios que deban ser guardados"
      );
    }
  };

  onDeleteEquipmentSpare = (id) => {
    this.setState((state) => ({
      loading: !state.loading,
      message: `Eliminando la linea de equipo spare`,
    }));
    this.props.deactivateEquipmentSpareById(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        this.hideAlert();
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  handleOnDeleteEquipmentSpare = (equipment) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que desea eliminar el equipo?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteEquipmentSpare(equipment.id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnCreateEquipmentSpare = () => {
    const {
      requestSelected: { id },
      spareValues,
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Creando el Equipo Spare`,
    }));
    this.props.createEquipmentSpare(id, spareValues).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          spareValues: {
            partNumber: "",
            description: "",
            amountEquipments: "",
            cost: "",
          },
          referenceView: true,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  handleOnUpdateEquipmentSpare = async () => {
    const { spareEditValues } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Actualizando la linea de equipo spare`,
    }));
    await this.props
      .updateEquipmentSpareById(spareEditValues.id, spareEditValues)
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexión con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
            spareEditValues: {
              id: undefined,
              partNumber: undefined,
              description: undefined,
              amountEquipments: undefined,
              cost: undefined,
            },
            editSpare: false,
          }));
        }
      });
  };

  handleOnChangeEquipmentSpare = (event, type) => {
    const { id, value } = event.target;
    if (type === "add") {
      const { spareValues } = this.state;
      spareValues[id] = value;
      this.setState({
        spareValues,
      });
    } else if (type === "edit") {
      const { spareEditValues } = this.state;
      spareEditValues[id] = value;
      this.setState({
        spareEditValues,
      });
    }
  };

  handleOnCompleteEquipmentSpare = async () => {
    console.log("Completar Equipos Spare");
    const {
      requestSelected: { id, idBusinessModel },
      referenceSpareFileList,
      ibmEquipments,
    } = this.state;
    const {
      referenceSpare,
      equipmentsCreated: { ibm },
    } = this.props;
    const partsFlow = this.onVerifyPartsAndEquipments(
      ibmEquipments,
      ibm,
      "Partes",
      1
    );
    // const equipmentsFlow = this.onVerifyPartsAndEquipments(ibmEquipments, ibm, "Equipos (Spare)", 2);
    const partsEquipmentsFlow = this.onVerifyPartsAndEquipments(
      ibmEquipments,
      ibm,
      "Partes y Equipos (Spare)",
      3
    );
    if (!referenceSpareFileList.length && !referenceSpare.length) {
      this.notify(
        "warning",
        "Atención",
        "Es obligatorio adjuntar al menos una referencia para el o los equipos agregados"
      );
    } else {
      if ((partsFlow || partsEquipmentsFlow) && idBusinessModel === 1) {
        this.setState({
          // spareVisibility: false,
          // addSpare: false,
          flowModal: {
            type: "configuration",
            showModal: true,
            title: "Verificación de Configuración",
            options: [
              {
                parent: true,
                label: "Tiene la Configuración",
                type: "select",
                key: "configuration",
                visible: true,
                required: true,
                options: ["Seleccione una opción", "No", "Sí"],
              },
              {
                parent: false,
                label: "Orden de Servicio",
                type: "text",
                key: "serviceOrder",
                visible: false,
                required: true,
                max: 10,
                min: 10,
                options: [],
                placeholder: "Ingrese la orden de servicio",
              },
              {
                parent: false,
                label: "Comentarios (Opcional)",
                type: "textarea",
                key: "commentary",
                visible: false,
                required: false,
                max: 200,
                options: [],
                placeholder: "Ingrese la orden de servicio",
              },
            ],
          },
        });
        this.props.updateActivityFlow(id, {
          description: `Carga de lineas de Equipos Spare al requerimiento`,
          state: `Equipos Spare Cargados`,
          type: "spare",
        });
      } else {
        console.log("Solo equipos Spare, finaliza");
        this.setState((state) => ({
          loading: !state.loading,
          message: `Completando carga de Equipos Spare`,
        }));
        await this.props
          .updateStateRequest(id, partsFlow || partsEquipmentsFlow ? 6 : 8)
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              this.props.updateActivityFlow(id, {
                description: `Carga de lineas de Equipos Spare al requerimiento`,
                state: `Equipos Spare Cargados`,
                type: "spare",
              });
              if (!partsEquipmentsFlow) {
                this.props.updateActivityFlow(id, {
                  description: "Cotización de la oportunidad completada",
                  state: "Cotización Completada",
                  type: "quotes",
                });
              }
              this.onMainStatesValues();
              this.findMyRequest();
            }
          });
      }
    }
  };

  handleOnEditEquipment = (values) => {
    console.log(values);
    const { equipmentsEditValues } = this.state;
    console.log(equipmentsEditValues);
    const { country } = values;
    console.log(country);
    const countries = [
      { id: "CR", name: "Costa Rica", key: "CR" },
      { id: "SV", name: "El Salvador", key: "SV" },
      { id: "GT", name: "Guatemala", key: "GT" },
      { id: "HN", name: "Honduras", key: "HN" },
      { id: "MD", name: "Miami", key: "MD" },
      { id: "NI", name: "Nicaragua", key: "NI" },
      { id: "PA", name: "Panamá", key: "PA" },
      { id: "DO", name: "República Dominicana", key: "DO" },
    ];
    const countrySelect = countries.find(
      (row) => row.name === country || row.key === country
    );
    console.log(countrySelect);
    Object.keys(equipmentsEditValues).forEach((element) => {
      equipmentsEditValues[element] = values[element];
    });
    equipmentsEditValues["country"] = countrySelect.key;
    equipmentsEditValues["price"] = parseInt(values["price"]);
    equipmentsEditValues["practice"] = values["idPractice"];
    equipmentsEditValues["platform"] = values["idPlatform"];
    equipmentsEditValues["officeHours"] = values["idOfficeHours"];
    equipmentsEditValues["equipmentParts"] = values["idEquipmentParts"];
    equipmentsEditValues["automaticRenewal"] = values["idAutomaticRenewal"];
    equipmentsEditValues["timeChangePart"] = values["idTimeChangePart"];
    equipmentsEditValues["validityService"] = values["idValidityService"];
    this.setState({
      editEquipment: true,
      equipmentsEditValues,
      amountInEdit: equipmentsEditValues.amountEquipments,
    });
  };

  handleOnUpdateEquipment = async () => {
    const { equipmentsEditValues, amountInEdit, amountEquipmentsCont } =
      this.state;
    const { amountEquipments } = equipmentsEditValues;
    const amountNew = amountEquipments - amountInEdit;
    equipmentsEditValues["idPractice"] = equipmentsEditValues["practice"];
    equipmentsEditValues["idPlatform"] = equipmentsEditValues["platform"];
    equipmentsEditValues["idOfficeHours"] = equipmentsEditValues["officeHours"];
    equipmentsEditValues["idEquipmentParts"] =
      equipmentsEditValues["equipmentParts"];
    equipmentsEditValues["idAutomaticRenewal"] =
      equipmentsEditValues["automaticRenewal"];
    equipmentsEditValues["idTimeChangePart"] =
      equipmentsEditValues["timeChangePart"];
    equipmentsEditValues["idValidityService"] =
      equipmentsEditValues["validityService"];
    this.setState((state) => ({
      loading: !state.loading,
    }));
    await this.props
      .updateEquipmentById(equipmentsEditValues.id, {
        values: equipmentsEditValues,
        type: "ibm",
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.setState({
            equipmentsEditValues: {
              id: undefined,
              country: undefined,
              typeModel: undefined,
              serial: undefined,
              platform: undefined,
              amountMaintenance: undefined,
              amountEquipments: undefined,
              practice: undefined,
              officeHours: undefined,
              timeChangePart: undefined,
              validityService: undefined,
              automaticRenewal: undefined,
              equipmentParts: undefined,
              viatic: undefined,
              validWarranty: undefined,
              price: undefined,
            },
            editEquipment: false,
            amountEquipmentsCont: amountEquipmentsCont + amountNew,
          });
          // this._handleOnToggleOpenEditEquipment();
        }
        this.setState((state) => ({
          loading: !state.loading,
        }));
      });
  };

  onDeleteEquipment = async (id) => {
    const { amountEquipmentsCont, requestSelected } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Eliminando la linea de equipo",
    }));
    await this.props.deactivateEquipmentById(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          alert: null,
          amountEquipmentsCont: amountEquipmentsCont - 1,
        });
        this.props.findEquipmentsCreatedByRequest(requestSelected.id);
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  handleOnDeleteEquipment = (values) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que desea eliminar el equipo?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteEquipment(values.id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnConfigSC = (flag) => {
    const {
      flow,
      referenceFilesList,
      justify,
      ibmEquipments,
      ciscoEquipments,
    } = this.state;
    const {
      equipmentsCreated: { ibm, cisco },
    } = this.props;
    if (flow["IBM"] !== "complete" && (ibmEquipments.length || ibm.length)) {
      this.notify(
        "warning",
        "Atención",
        "Aun no se han completado los equipos"
      );
    } else {
      if (
        this.onVerifyAllPrices(ibmEquipments, ciscoEquipments, "Precio") ||
        this.onVerifyAllPrices(ibm, cisco, "price")
      ) {
        this.notify(
          "warning",
          "Atención",
          "Se detectaron lineas de equipos sin precios, para avanzar es necesario que se ingresen todos los precios"
        );
      } else if (flag && !referenceFilesList.length && justify === null) {
        this.notify(
          "warning",
          "Atención",
          "Es obligatorio adjuntar la referencia o escribir una justificación para el o los precios agregados"
        );
      } else {
        this.setState({
          configSC: true,
        });
      }
    }
  };

  handleOnOpenResumeOffer = () => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Cargando el resumen de la oferta",
    }));
    this.props.findResumeOffersRequestById(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          viewResume: true,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  handleOnReferenceAjustOffer = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idReference } = payload;
        this.setState((state) => ({
          referenceAjustFilesList: state.referenceAjustFilesList.filter(
            (row) => row.uid !== uid
          ),
          referenceAjustRemoved: [...state.referenceAjustRemoved, idReference],
        }));
      } else {
        this.setState((state) => ({
          referenceAjustFilesList: state.referenceAjustFilesList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        referenceAjustFilesList: fileList,
      });
    }
  };

  handleOnSubmitAjustOffer = async (values, provision, price, both) => {
    const {
      referenceAjustFilesList,
      referenceAjustRemoved,
      requestSelected: { id },
    } = this.state;
    if (!referenceAjustFilesList.length) {
      this.notify(
        "warning",
        "Atención",
        "Es requerido adjuntar al menos un archivo para continuar"
      );
    } else {
      this.setState({
        loading: true,
        message: "Solicitando un ajuste a la oferta",
      });
      if (referenceAjustRemoved.length) {
        await this.props
          .deactivateReferencesSpare({
            referencesIds: referenceAjustRemoved,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexión con el servidor."
                );
              }
            }
          });
      }
      await this.props
        .createAjustOfferRequest(id, {
          provision: values.provisionAmount,
          justification: values.justification,
          type: provision ? 1 : price ? 2 : both ? 3 : 0,
          referencesIds: referenceAjustFilesList,
        })
        .then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexión con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            await this.props.updateStateRequest(id, 10).then(async (res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState({
                  loading: false,
                  message: null,
                });
              } else {
                await this.props.updateActivityFlow(id, {
                  description: `Solicitud de ajuste en el calculo de la oferta`,
                  state: `Ajuste en la oferta`,
                  type: "ajuste",
                });
                const {
                  data: { payload },
                } = res.payload;
                this.notify("success", "Éxito", payload.message);
                this.onMainStatesValues();
                this.findMyRequest();
              }
            });
          }
          this.setState({
            loading: false,
            message: null,
          });
        });
    }
  };

  onUpdateOfferWonStatus = async () => {
    const {
      requestSelected: { id },
    } = this.state;
    const {
      resumeOfferBase: {
        equipments,
        officeHours,
        totalEquipment,
        totalServices,
        totalSpareParts,
        validityService,
      },
    } = this.props;
    this.setState({
      loading: true,
      message: "Registrando la oferta del requerimiento ganado",
      alert: null,
    });
    await this.props.updateStateRequest(id, 14).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({
          loading: false,
          message: null,
        });
      } else {
        await this.props.updateActivityFlow(id, {
          description: `Oferta del requerimiento ganada`,
          state: `Oferta Ganada`,
          type: "ganado",
        });
        await this.props
          .sendEmailOfferWonRequest(id, {
            oficial: true,
            equipments,
            officeHours,
            totalEquipment,
            totalServices,
            totalSpareParts,
            validityService,
          })
          .then(async (res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              this.setState({
                loading: false,
                message: null,
              });
            } else {
              const {
                data: { payload },
              } = res.payload;
              this.notify("success", "Éxito", payload.message);
            }
          });
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.onMainStatesValues();
        this.findMyRequest();
      }
    });
  };

  handleOnOfferWon = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de que es la oferta ganada?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onUpdateOfferWonStatus()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, continuar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnGenerateDocuments = async () => {
    const {
      requestSelected: { id },
    } = this.state;
    const {
      resumeOfferBase: {
        equipments,
        officeHours,
        totalEquipment,
        totalServices,
        totalSpareParts,
        validityService,
      },
    } = this.props;
    this.setState({
      loading: true,
      message: "Enviando los documentos al correo electrónico",
    });
    await this.props
      .sendEmailOfferWonRequest(id, {
        oficial: false,
        equipments,
        officeHours,
        totalEquipment,
        totalServices,
        totalSpareParts,
        validityService,
      })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState({
            loading: false,
            message: null,
          });
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  handleOnBackOfferToRequest = () => {
    this.setState({
      viewResume: false,
    });
  };

  handleOnToggleModalVersion = () => {
    this.setState((state) => ({
      showModalVersions: !state.showModalVersions,
    }));
  };

  handleOnToggleModalErrors = () => {
    this.setState((state) => ({
      modalEquipmentsErrors: !state.modalEquipmentsErrors,
    }));
  };

  handleOnNewVersion = (status) => {
    if (status === 8) {
      const {
        requestSelected: { opportunityNumber },
      } = this.state;
      this.setState({
        loading: true,
        message: "Cargando la información del requerimiento",
      });
      this.props.findLastVersionByRequest(opportunityNumber).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findOptionsRequestToVersion();
          this.setState((state) => ({
            showModalVersions: !state.showModalVersions,
          }));
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
    } else if (status === 15) {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Está seguro que desea versionar el requerimiento rechazado?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.handleOnNewVersionRejectRequest()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, versionar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        ),
      });
    }
  };

  handleOnNewVersionRequest = (values, type, newVersion) => {
    const {
      equipmentsValues,
      requestSelected: {
        id,
        opportunityNumber,
        country,
        officeHours,
        timeChangePart,
        responseTime,
      },
    } = this.state;
    values["amountOfEquipment"] =
      parseInt(values.amountOfEquipmentIn) +
      parseInt(values.amountOfEquipmentOut);
    if (type.type === "deleteEquipments") {
      this.setState({
        loadingVersion: true,
        messageVersion: `Cargando los equipos del requerimiento`,
      });
      this.props.findEquipmentsByRequest(id).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.setState({
            showModalVersions: false,
            deleteEquipments: true,
            newVersion,
          });
        }
        this.setState({
          loadingVersion: false,
          messageVersion: null,
        });
      });
    } else if (type.type === "addEquipments") {
      this.setState({
        loadingVersion: true,
        messageVersion: `Cargando los equipos del requerimiento`,
      });
      this.props.findEquipmentsByRequest(id).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findFormValuesEquipmentsRequest().then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              const flavor =
                officeHours === "24x7" &&
                timeChangePart === "Mejor Esfuerzo" &&
                responseTime === "2 horas"
                  ? "gold"
                  : officeHours === "24x7" &&
                    responseTime === "2 horas" &&
                    timeChangePart === "Hasta 4 horas"
                  ? "platinum"
                  : null;
              equipmentsValues["amountMaintenance"] =
                flavor === "platinum" ? 2 : flavor === "gold" ? 1 : undefined;
              equipmentsValues["country"] = country;
              this.setState({
                equipmentsValues,
                showModalVersions: false,
                addEquipments: true,
                newValuesEquipmentsToAdd: values,
                newVersion,
              });
            }
          });
        }
        this.setState({
          loadingVersion: false,
          messageVersion: null,
        });
      });
    } else {
      this.setState({
        loadingVersion: true,
        messageVersion: `Creando nueva versión ${newVersion} para la OPP ${opportunityNumber}`,
      });
      this.props
        .createNewVersionByRequest(id, {
          type: type.type,
          values,
          newVersion,
          equipmentsIds: [],
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            this.onMainStatesValues();
            this.findMyRequest();
          }
          this.setState({
            loadingVersion: false,
            messageVersion: null,
          });
        });
    }
  };

  onCreateNewVersionWithEquipmentsDeleted = (keys, values) => {
    const {
      requestSelected: { id, opportunityNumber },
      newVersion,
    } = this.state;
    const idsEquipmentsDeleted = keys.filter((row) => values[row] === true);
    // console.log(idsEquipmentsDeleted);
    this.setState({
      alert: null,
      loading: true,
      message: `Creando nueva versión ${newVersion} para la OPP ${opportunityNumber}`,
    });
    this.props
      .createNewVersionByRequest(id, {
        type: "deleteEquipment",
        values,
        newVersion,
        equipmentsIds: idsEquipmentsDeleted,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.onMainStatesValues();
          this.findMyRequest();
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  handleOnDeleteEquipmentOfVersion = (keys, values) => {
    // console.log(keys, values)
    if (!keys.some((row) => values[row] === true)) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-180px" }}
            title="Atención"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="warning"
            confirmBtnText="Entendido"
            btnSize=""
          >
            No se ha seleccionado ningún equipo para eliminar
          </ReactBSAlert>
        ),
      });
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-180px" }}
            title="¿Está seguro(a) de eliminar los equipos seleccionados?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() =>
              this.onCreateNewVersionWithEquipmentsDeleted(keys, values)
            }
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, eliminar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        ),
      });
    }
  };

  getInitialValuesEquipmentsDelete = (rows) => {
    const { equipmentsDeleteSelected } = this.state;
    if (!Object.keys(equipmentsDeleteSelected).length && rows.length) {
      rows.forEach((element) => {
        equipmentsDeleteSelected[element.id] = false;
      });
      this.setState({
        equipmentsDeleteSelected,
      });
    }
    return equipmentsDeleteSelected;
  };

  handleOnAddEquipment = () => {
    const {
      equipmentsValues,
      requestSelected: {
        country,
        officeHours,
        responseTime,
        timeChangePart,
        idBusinessModel,
        criticalParts,
      },
      newValuesEquipmentsToAdd,
      equipmentsAddCreated,
    } = this.state;
    const { formOptionsEquipments } = this.props;
    delete equipmentsValues.officeHours;
    const values = Object.keys(equipmentsValues).filter(
      (row) => row !== "price"
    );
    // console.log(equipmentsValues);
    if (
      values.some(
        (row) =>
          (equipmentsValues[row] === null ||
            equipmentsValues[row] === undefined) &&
          ((idBusinessModel === 1 && row !== "amountEquipments") ||
            (idBusinessModel === 2 && row !== "serial"))
      )
    ) {
      this.notify(
        "warning",
        "Atención",
        "Primero debes completar todos los valores"
      );
    } else {
      let amountAdded = 0;
      if (idBusinessModel === 1) {
        amountAdded = equipmentsAddCreated.length + 1;
      } else if (idBusinessModel === 2) {
        const amountArr = equipmentsAddCreated.map((row) =>
          parseInt(row.amountEquipments)
        );
        amountAdded = amountArr.length
          ? amountArr.reduce((a, b) => a + b) +
            parseInt(equipmentsValues.amountEquipments)
          : parseInt(equipmentsValues.amountEquipments);
      }
      if (amountAdded <= parseInt(newValuesEquipmentsToAdd.amountOfEquipment)) {
        const flavor =
          officeHours === "24x7" &&
          timeChangePart === "Mejor Esfuerzo" &&
          responseTime === "2 horas"
            ? "gold"
            : officeHours === "24x7" &&
              responseTime === "2 horas" &&
              timeChangePart === "Hasta 4 horas"
            ? "platinum"
            : null;
        const newEquipmentsKeys = {
          country: [
            { id: "CR", name: "Costa Rica", key: "CR" },
            { id: "SV", name: "El Salvador", key: "SV" },
            { id: "GT", name: "Guatemala", key: "GT" },
            { id: "HN", name: "Honduras", key: "HN" },
            { id: "MD", name: "Miami", key: "MD" },
            { id: "NI", name: "Nicaragua", key: "NI" },
            { id: "PA", name: "Panamá", key: "PA" },
            { id: "DO", name: "República Dominicana", key: "DO" },
          ].find((row) => row.key === equipmentsValues.country).name,
          typeModel: equipmentsValues.typeModel,
          serial: equipmentsValues.serial,
          platform: formOptionsEquipments.platforms.find(
            (row) => row.id === parseInt(equipmentsValues.platform)
          ).name,
          amountMaintenance: equipmentsValues.amountMaintenance,
          amountEquipments: equipmentsValues.amountEquipments,
          practice: formOptionsEquipments.practices.find(
            (row) => row.id === parseInt(equipmentsValues.practice)
          ).name,
          officeHour: formOptionsEquipments.officeHours.find(
            (row) => row.id === parseInt(equipmentsValues.officeHour)
          ).name,
          timeChangePart: formOptionsEquipments.timeChangePart.find(
            (row) => row.id === parseInt(equipmentsValues.timeChangePart)
          ).name,
          validityService: formOptionsEquipments.validityService.find(
            (row) => row.id === parseInt(equipmentsValues.validityService)
          ).name,
          automaticRenewal: formOptionsEquipments.automaticRenewal.find(
            (row) => row.id === parseInt(equipmentsValues.automaticRenewal)
          ).name,
          equipmentParts: formOptionsEquipments.includesParts.find(
            (row) => row.id === parseInt(equipmentsValues.equipmentParts)
          ).name,
          viatic: equipmentsValues.viatic,
          validWarranty: equipmentsValues.validWarranty,
          price: equipmentsValues.price,
        };
        this.setState((state) => ({
          equipmentsAddCreated: [
            ...state.equipmentsAddCreated,
            newEquipmentsKeys,
          ],
          equipmentsAddCreatedKeys: [
            ...state.equipmentsAddCreatedKeys,
            equipmentsValues,
          ],
          equipmentsValues: {
            country,
            typeModel: "",
            serial: "",
            platform: 0,
            amountMaintenance:
              criticalParts === "NO"
                ? 0
                : flavor === "platinum"
                ? 2
                : flavor === "gold"
                ? 1
                : "",
            amountEquipments: 1,
            practice: 0,
            officeHour: 0,
            timeChangePart: 0,
            validityService: 0,
            automaticRenewal: 0,
            equipmentParts: 0,
            viatic: 0,
            price: 0,
            validWarranty: 0,
          },
        }));
      } else {
        this.notify(
          "warning",
          "Atención",
          "No se puede sobrepasar la cantidad de equipos indicada"
        );
      }
    }
  };

  onCreateNewVersionWithEquipmentAdded = () => {
    const {
      requestSelected: { id, opportunityNumber, criticalParts },
      newVersion,
      newValuesEquipmentsToAdd,
      equipmentsAddCreatedKeys,
    } = this.state;
    this.setState({
      alert: null,
      loading: true,
      message: `Creando nueva versión ${newVersion} para la OPP ${opportunityNumber}`,
    });
    this.props
      .createNewVersionByRequest(id, {
        type: "addEquipments",
        values: newValuesEquipmentsToAdd,
        newVersion,
        equipmentsIds: [],
        equipmentsAdded: equipmentsAddCreatedKeys,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          if (criticalParts === "NO") {
            console.log("No lleva partes y equipos");
            this.setState({
              loading: true,
              message: `Completando el proceso de la oportunidad`,
            });
            this.props
              .updateStateRequest(payload.newIdRequest, 8)
              .then((res) => {
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    this.notify("warning", "Atención", payload.message);
                  } else {
                    this.notify(
                      "danger",
                      "Falló",
                      "No se logro establecer conexion con el servidor."
                    );
                  }
                  this.setState({
                    loading: false,
                    message: null,
                  });
                } else {
                  const {
                    data: { payload },
                  } = res.payload;
                  this.notify("success", "Éxito", payload.message);
                  this.props.updateActivityFlow(payload.newIdRequest, {
                    description: `Levantamiento de requerimiento completado`,
                    state: `Completado`,
                    type: "complete",
                  });
                  this.onMainStatesValues();
                  this.findMyRequest();
                }
              });
          } else {
            this.setState({
              flowModal: {
                type: "configuration",
                showModal: true,
                title: "Verificación de Configuración",
                options: [
                  {
                    parent: true,
                    label: "Tiene la Configuración",
                    type: "select",
                    key: "configuration",
                    visible: true,
                    required: true,
                    options: ["Seleccione una opción", "No", "Sí"],
                  },
                  {
                    parent: false,
                    label: "Orden de Servicio",
                    type: "text",
                    key: "serviceOrder",
                    visible: false,
                    required: true,
                    max: 10,
                    min: 10,
                    options: [],
                    placeholder: "Ingrese la orden de servicio",
                  },
                  {
                    parent: false,
                    label: "Comentarios (Opcional)",
                    type: "textarea",
                    key: "commentary",
                    visible: false,
                    required: false,
                    max: 200,
                    options: [],
                    placeholder: "Ingrese la orden de servicio",
                  },
                ],
              },
              newIdRequest: payload.newIdRequest,
            });
          }
          // this.onMainStatesValues();
          // this.findMyRequest();
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  handleOnCreateNewVersionAddEquipment = () => {
    const {
      requestSelected: { idBusinessModel },
      newValuesEquipmentsToAdd,
      equipmentsAddCreatedKeys,
    } = this.state;
    let amountAdded = 0;
    if (idBusinessModel === 1) {
      amountAdded = equipmentsAddCreatedKeys.length;
    } else if (idBusinessModel === 2) {
      const amountArr = equipmentsAddCreatedKeys.map((row) =>
        parseInt(row.amountEquipments)
      );
      amountAdded = amountArr.reduce((a, b) => a + b);
    }
    // console.log(amountAdded);
    // console.log(newValuesEquipmentsToAdd);
    if (amountAdded !== parseInt(newValuesEquipmentsToAdd.amountOfEquipment)) {
      this.notify(
        "warning",
        "Atención",
        "La cantidad de equipos no coincide con la indicada a crear"
      );
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-180px" }}
            title="¿Está seguro(a) de versionar con los equipos ingresados?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.onCreateNewVersionWithEquipmentAdded()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, versionar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        ),
      });
    }
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  onVerifyCompleteEquipments = (amount) => {
    this.hideAlert();
    this.setState({
      modalEquipmentsUbications: {
        showModal: true,
        amount,
      },
    });
    // this.setState({
    //   alert: (
    //     <ReactBSAlert
    //       custom
    //       showCancel
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="¿Está seguro que desea avanzar?"
    //       customIcon={
    //         <div
    //           className="swal2-icon swal2-question swal2-animate-question-icon"
    //           style={{ display: "flex" }}
    //         >
    //           <span className="swal2-icon-text">?</span>
    //         </div>
    //       }
    //       onConfirm={() => this._handleOnCompleteEquipments()}
    //       onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="success"
    //       cancelBtnBsStyle="danger"
    //       confirmBtnText="Sí, avanzar"
    //       cancelBtnText="No, cancelar"
    //       btnSize="md"
    //     >
    //       El requerimiento se actualizaria a la cantidad de {amount} equipos
    //     </ReactBSAlert>
    //   )
    // });
  };

  handleOnCompleteEquipments = () => {
    const {
      requestSelected: { amountOfEquipment },
      ibmEquipments,
    } = this.state;
    const {
      equipmentsCreated: { ibm },
    } = this.props;
    if (
      amountOfEquipment !== ibm.length &&
      amountOfEquipment !== ibmEquipments.length
    ) {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Está seguro que desea avanzar?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() =>
              this.onVerifyCompleteEquipments(
                ibm.length ? ibm.length : ibmEquipments.length
              )
            }
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, avanzar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          >
            En el requerimiento se indicaron {amountOfEquipment} y estas
            agregando{" "}
            {ibm.length
              ? ibm.length
              : ibmEquipments.length
              ? ibmEquipments.length
              : 0}
          </ReactBSAlert>
        ),
      });
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Creación de equipos"
            onConfirm={() => this._handleOnCompleteEquipments()}
            onCancel={() => this._handleOnCompleteEquipments()}
            btnSize=""
            text="Al dar continuar esta garantizando que los equipos aun estén legibles a tener soporte según la política"
          >
            Al dar continuar esta garantizando que los equipos aun estén
            legibles a tener soporte según la política
          </ReactBSAlert>
        ),
      });
      // this._handleOnCompleteEquipments();
    }
  };

  handleOnToggleEquipmentsUbicationsModal = () => {
    this.setState({
      modalEquipmentsUbications: {
        showModal: false,
        amount: 0,
      },
    });
  };

  handleOnWorkFlowEquipmentsUbications = (values) => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Actualizando la información`,
    }));
    this.props.updateAmountEquipmentsByRequest(id, values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this._handleOnCompleteEquipments();
        this.handleOnToggleEquipmentsUbicationsModal();
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  onNewVersionRejectRequest = () => {
    const {
      requestSelected: { id, opportunityNumber },
    } = this.state;
    const { lastVersion } = this.props;
    const newVersion = this.calcVersionNumber(parseFloat(lastVersion));
    this.setState({
      loading: true,
      message: `Creando nueva versión ${newVersion} para la OPP ${opportunityNumber}`,
    });
    this.props
      .createNewVersionByRejectRequest(id, {
        newVersion,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.onMainStatesValues();
          this.findMyRequest();
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  handleOnNewVersionRejectRequest = () => {
    const {
      requestSelected: { opportunityNumber },
    } = this.state;
    this.setState({
      alert: null,
      loading: true,
      message: "Cargando la información del requerimiento",
    });
    this.props.findLastVersionByRequest(opportunityNumber).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.props.findOptionsRequestToVersion().then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            this.onNewVersionRejectRequest();
          }
        });
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  render() {
    const {
      name,
      columns,
      options,
      parentName,
      equipments,
      lastVersion,
      requeriments,
      activityFlow,
      justifications,
      referenceSpare,
      resumeOfferBase,
      equipmentsSpare,
      referencesRequest,
      equipmentsCreated,
      formOptionsVersion,
      configurationsRequest,
      formOptionsEquipments,
      commentsConfigurations,
      offersCalcs,
    } = this.props;

    const {
      alert,
      flow,
      page,
      flavor,
      loading,
      addSpare,
      editSpare,
      enabled,
      message,
      fileList,
      configSC,
      loadFile,
      flowModal,
      viewResume,
      newVersion,
      spareValues,
      loadingEdit,
      sizePerPage,
      dateSelected,
      newIdRequest,
      addEquipments,
      editEquipment,
      ibmEquipments,
      initialValues,
      stateSelected,
      referenceView,
      clientSelected,
      equipmentAdded,
      loadingVersion,
      messageVersion,
      requestSelected,
      ciscoEquipments,
      spareEditValues,
      spareVisibility,
      deleteEquipments,
      updateEquipments,
      equipmentsValues,
      equipmentVisible,
      oppNumberSelected,
      showModalVersions,
      openEditEquipment,
      configurationView,
      referenceFilesList,
      equipmentsAddCreated,
      equipmentsEditValues,
      referenceSpareFileList,
      configurationsFilesList,
      referenceAjustFilesList,
      equipmentsErrors,
      modalEquipmentsErrors,
      modalEquipmentsUbications,
    } = this.state;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {requestSelected && (
          <EquipmentsError
            showModal={modalEquipmentsErrors}
            toggleModal={this.handleOnToggleModalErrors}
            errors={equipmentsErrors}
            cols={[
              { name: "Tipo de Modelo", key: "TipoModelo", hide: false },
              {
                name: "Serie",
                key: "Serie",
                hide: requestSelected.idBusinessModel === 2 ? true : false,
              },
              { name: "Práctica", key: "Practica", hide: false },
              {
                name: "Cantidad de Mantenimientos",
                key: "CantidadMantenimientos",
                hide: false,
              },
              {
                name: "Cantidad de Equipos",
                key: "CantidadEquipos",
                hide: requestSelected.idBusinessModel === 1 ? true : false,
              },
              { name: "Plataforma", key: "Plataforma", hide: false },
              {
                name: "Horario de Atención",
                key: "HorarioAtencion",
                hide: false,
              },
              {
                name: "Tiempo Cambio de Parte",
                key: "TiempoCambioParte",
                hide: false,
              },
              { name: "Años del Servicio", key: "AñosServicio", hide: false },
              {
                name: "Renovación Automatica",
                key: "RenovacionAutomatica",
                hide: false,
              },
              {
                name: "Incluye Partes/Equipos",
                key: "IncluyePartesEquipos",
                hide: false,
              },
              {
                name: `${
                  requestSelected.idBusinessModel === 1 ? "Precio $" : "Costo $"
                }`,
                key: "Precio",
                hide: false,
              },
              { name: "Error", key: "msg", hide: false },
            ]}
          />
        )}
        <EquipmentsUbicationsModal
          options={modalEquipmentsUbications}
          toggleModal={this.handleOnToggleEquipmentsUbicationsModal}
          onWorkFlow={this.handleOnWorkFlowEquipmentsUbications}
        />
        <NewVersionModal
          loading={loadingVersion}
          message={messageVersion}
          request={requestSelected}
          showModal={showModalVersions}
          lastVersion={lastVersion}
          options={formOptionsVersion}
          toggleModal={this.handleOnToggleModalVersion}
          onNewVersion={this.handleOnNewVersionRequest}
        />
        <WorkFlowModal
          type={flowModal.type}
          title={flowModal.title}
          showModal={flowModal.showModal}
          options={flowModal.options}
          loading={loading}
          initialValues={initialValues}
          onChange={this._handleOnChangeValueFlowModal}
          toggleModal={this._handleOnToggleModal}
          onWorkFlow={this._handleOnWorkFlow}
        />
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            <Row>
              <Col>
                {requestSelected ? (
                  addEquipments ? (
                    <AddEquipments
                      title={`Nueva Versión ${newVersion} OPP ${requestSelected.opportunityNumber}`}
                      subtitle="Versionamiento - Agregación de Equipos"
                      cols={[
                        { name: "País", key: "country", hide: false },
                        {
                          name: "Tipo de Modelo",
                          key: "typeModel",
                          hide: false,
                        },
                        {
                          name: "Serie",
                          key: "serial",
                          hide:
                            requestSelected.idBusinessModel === 2
                              ? true
                              : false,
                        },
                        { name: "Práctica", key: "practice", hide: false },
                        {
                          name: "Cantidad de Mantenimientos",
                          key: "amountMaintenance",
                          hide: false,
                        },
                        {
                          name: "Cantidad de Equipos",
                          key: "amountEquipments",
                          hide:
                            requestSelected.idBusinessModel === 1
                              ? true
                              : false,
                        },
                        { name: "Plataforma", key: "platform", hide: false },
                        {
                          name: "Horario de Atención",
                          key: "officeHour",
                          hide: false,
                        },
                        {
                          name: "Tiempo Cambio de Parte",
                          key: "timeChangePart",
                          hide: false,
                        },
                        {
                          name: "Años del Servicio",
                          key: "validityService",
                          hide: false,
                        },
                        {
                          name: "Renovación Automatica",
                          key: "automaticRenewal",
                          hide: false,
                        },
                        {
                          name: "Incluye Partes/Equipos",
                          key: "equipmentParts",
                          hide: false,
                        },
                        // { name: "Cantidad/Hr/Mant", key: "amountHRMant" },
                        { name: "Viáticos", key: "viatic", hide: false },
                        {
                          name: "Garantía Vigente",
                          key: "validWarranty",
                          hide: false,
                        },
                        { name: "Precio", key: "price", hide: false },
                      ]}
                      rows={equipments.ibm}
                      options={[
                        {
                          name: "País",
                          key: "country",
                          placeholder: "Ingrese el País",
                          options: [
                            { id: "CR", name: "Costa Rica", key: "CR" },
                            { id: "SV", name: "El Salvador", key: "SV" },
                            { id: "GT", name: "Guatemala", key: "GT" },
                            { id: "HN", name: "Honduras", key: "HN" },
                            { id: "MD", name: "Miami", key: "MD" },
                            { id: "NI", name: "Nicaragua", key: "NI" },
                            { id: "PA", name: "Panamá", key: "PA" },
                            {
                              id: "DO",
                              name: "República Dominicana",
                              key: "DO",
                            },
                          ],
                          type: "select",
                          hide: false,
                        },
                        {
                          name: "Tipo de Modelo",
                          key: "typeModel",
                          placeholder: "Ingrese el tipo de modelo",
                          type: "text",
                          max: 50,
                          hide: false,
                        },
                        {
                          name: "Serie",
                          key: "serial",
                          placeholder: "Ingrese la Serie",
                          type: "text",
                          max: 50,
                          hide:
                            requestSelected.idBusinessModel === 2
                              ? true
                              : false,
                        },
                        {
                          name: "Práctica",
                          key: "practice",
                          placeholder: "Seleccione una opción",
                          options: formOptionsEquipments.practices,
                          type: "select",
                          hide: false,
                        },
                        {
                          name: "Cant de Mantenimientos",
                          key: "amountMaintenance",
                          placeholder: "Ingrese la cantidad de mantenimientos",
                          type: "number",
                          max: 10,
                          min: flavor === "platinum" ? 2 : 1,
                          hide: false,
                        },
                        {
                          name: "Cant de Equipos",
                          key: "amountEquipments",
                          placeholder: "Ingrese la cantidad de equipos",
                          type: "number",
                          max: 99,
                          min: 1,
                          hide:
                            requestSelected.idBusinessModel === 1
                              ? true
                              : false,
                        },
                        {
                          name: "Plataforma",
                          key: "platform",
                          placeholder: "Seleccione una opción",
                          options: formOptionsEquipments.platforms,
                          type: "select",
                          hide: false,
                        },
                        {
                          name: "Horario de Atención",
                          key: "officeHour",
                          placeholder: "Seleccione una opción",
                          options: formOptionsEquipments.officeHours,
                          type: "select",
                          hide: false,
                        },
                        {
                          name: "Tiempo Cambio de Parte",
                          key: "timeChangePart",
                          placeholder: "Seleccione una opción",
                          options: formOptionsEquipments.timeChangePart,
                          type: "select",
                          hide: false,
                        },
                        {
                          name: "Años del Servicio",
                          key: "validityService",
                          placeholder: "Seleccione una opción",
                          options: formOptionsEquipments.validityService,
                          type: "select",
                          hide: false,
                        },
                        {
                          name: "Renovación Automatica",
                          key: "automaticRenewal",
                          placeholder: "Seleccione una opción",
                          options: formOptionsEquipments.automaticRenewal,
                          type: "select",
                          hide: false,
                        },
                        {
                          name: "Incluye Partes/Equipos",
                          key: "equipmentParts",
                          placeholder: "Seleccione una opción",
                          options: formOptionsEquipments.includesParts,
                          type: "select",
                          hide: false,
                        },
                        {
                          name: "Viáticos",
                          key: "viatic",
                          placeholder: "Ingrese los Viáticos",
                          type: "number",
                          max: 99999999,
                          min: 1,
                          hide: false,
                        },
                        {
                          name: "Garantía Vigente",
                          key: "validWarranty",
                          placeholder: "Ingrese la Garantía Vigente",
                          type: "number",
                          max: 9,
                          min: requestSelected.idBusinessModel === 1 ? 0 : 1,
                          hide: false,
                        },
                        {
                          name: "Precio",
                          key: "price",
                          placeholder: "Ingrese el Precio",
                          type: "number",
                          max: 99999999,
                          min: 1,
                          hide: false,
                        },
                      ]}
                      initialValues={equipmentsValues}
                      equipmentsAdded={equipmentsAddCreated}
                      onChangeValues={this._handleOnChageEquipmentsValues}
                      addEquipment={this.handleOnAddEquipment}
                      onCreateVersion={
                        this.handleOnCreateNewVersionAddEquipment
                      }
                    />
                  ) : deleteEquipments ? (
                    <DeleteEquipments
                      title={`Nueva Versión ${newVersion} OPP ${requestSelected.opportunityNumber}`}
                      subtitle="Versionamiento - Eliminación de Equipos"
                      cols={[
                        {
                          name: "Cantidad de Equipos",
                          key: "amountEquipments",
                          hide:
                            requestSelected.idBusinessModel === 1
                              ? true
                              : false,
                        },
                        { name: "País", key: "country", hide: false },
                        {
                          name: "Tipo de Modelo",
                          key: "typeModel",
                          hide: false,
                        },
                        {
                          name: "Serie",
                          key: "serial",
                          hide:
                            requestSelected.idBusinessModel === 1
                              ? false
                              : true,
                        },
                        { name: "Práctica", key: "practice", hide: false },
                        {
                          name: "Cantidad de Mantenimientos",
                          key: "amountMaintenance",
                          hide: false,
                        },
                        { name: "Plataforma", key: "platform", hide: false },
                        {
                          name: "Horario de Atención",
                          key: "officeHour",
                          hide: false,
                        },
                        {
                          name: "Tiempo Cambio de Parte",
                          key: "timeChangePart",
                          hide: false,
                        },
                        {
                          name: "Años del Servicio",
                          key: "validityService",
                          hide: false,
                        },
                        {
                          name: "Renovación Automatica",
                          key: "automaticRenewal",
                          hide: false,
                        },
                        {
                          name: "Incluye Partes/Equipos",
                          key: "equipmentParts",
                          hide: false,
                        },
                        { name: "Viáticos", key: "viatic", hide: false },
                        { name: "Precio", key: "price", hide: false },
                      ]}
                      rows={equipments.ibm}
                      initialValues={this.getInitialValuesEquipmentsDelete(
                        equipments.ibm
                      )}
                      onDelete={this.handleOnDeleteEquipmentOfVersion}
                    />
                  ) : spareVisibility ? (
                    <SpareEquipments
                      isView={requestSelected.state > 4 ? true : false}
                      title="Equipos Spare"
                      subtitle="Asignación de Equipos Spare al requerimiento"
                      add={addSpare}
                      edit={editSpare}
                      cols={[
                        { name: "Partnumber", key: "partNumber" },
                        { name: "Descripción", key: "description" },
                        {
                          name: "Cantidad de Equipos",
                          key: "amountEquipments",
                        },
                        { name: "Costo Unitario", key: "cost" },
                      ]}
                      options={[
                        {
                          name: "Partnumber",
                          key: "partNumber",
                          placeholder: "Ingrese el Partnumber",
                          type: "text",
                          max: 17,
                        },
                        {
                          name: "Descripción",
                          key: "description",
                          placeholder: "Ingrese el Descripción",
                          type: "text",
                          max: 500,
                        },
                        {
                          name: "Cantidad de Equipos",
                          key: "amountEquipments",
                          placeholder: "Ingrese la Cantidad de Equipos",
                          type: "number",
                          max: 99999,
                        },
                        {
                          name: "Costo",
                          key: "cost",
                          placeholder: "Ingrese el Costo",
                          type: "number",
                          max: 9999999.99,
                        },
                      ]}
                      initialValues={spareValues}
                      initialEditValues={spareEditValues}
                      rows={equipmentsSpare}
                      references={referenceSpare}
                      referenceView={referenceView}
                      idRequest={requestSelected.id}
                      fileList={referenceSpareFileList}
                      onFileList={this.handleOnReferenceSpareUpload}
                      onEdit={this.handleOnEditEquipmentSpare}
                      onBack={this.handleOnBackEquipmentSpare}
                      onSave={this.handleOnSaveEquipmentSpareProcess}
                      onDelete={this.handleOnDeleteEquipmentSpare}
                      onCreate={this.handleOnCreateEquipmentSpare}
                      onUpdate={this.handleOnUpdateEquipmentSpare}
                      onChange={this.handleOnChangeEquipmentSpare}
                      onComplete={this.handleOnCompleteEquipmentSpare}
                    />
                  ) : configurationView === "add" ? (
                    <CardConfiguration
                      title="Configuración"
                      subtitle="Adjuntar los archivos referentes a la configuración"
                      idRequest={
                        newIdRequest ? newIdRequest : requestSelected.id
                      }
                      fileList={configurationsFilesList}
                      onBack={this._handleOnCloseConfiguration}
                      onChange={this._handleOnChangeJustify}
                      onComplete={this._handleOnCompleteConfiguration}
                      onFileList={this._handleOnConfigurationUpload}
                    />
                  ) : configurationView === "view" ? (
                    <ConfigurationsList
                      title="Configuraciones"
                      subtitle="Documentos de configuración cargados al requerimiento"
                      configurations={configurationsRequest}
                      comments={commentsConfigurations}
                      onBack={this._handleOnCloseConfiguration}
                    />
                  ) : equipmentVisible === "add" ? (
                    <EquipmentsListForm
                      views
                      parts={
                        requestSelected.criticalParts === "NO" ? false : true
                      }
                      configSC={configSC}
                      edit={editEquipment}
                      idRequest={requestSelected.id}
                      request={requestSelected}
                      enabled={enabled}
                      type={requestSelected.idBusinessModel}
                      title="Creación y Carga de Equipos"
                      subtitle="Agregar la lista de equipos al requerimiento"
                      formOptions={formOptionsEquipments}
                      loadView={loadFile}
                      fileList={fileList}
                      flow={flow}
                      state={equipmentAdded}
                      amountEquipments={requestSelected.amountOfEquipment}
                      equipments={{ ibmEquipments, ciscoEquipments }}
                      equipmentsCreated={equipmentsCreated}
                      initialValues={equipmentsValues}
                      initialEditValues={equipmentsEditValues}
                      configurationsFilesList={configurationsFilesList}
                      onConfigSC={this.handleOnConfigSC}
                      onCancel={this._handleOnCloseEquipments}
                      onCreate={this._handleOnCreateEquipment}
                      onEditEquipment={this.handleOnEditEquipment}
                      onUpdateEquipment={this.handleOnUpdateEquipment}
                      onDeleteEquipment={this.handleOnDeleteEquipment}
                      onAddEquipment={this._handleOnAddEquipment}
                      onChangeValues={this._handleOnChageEquipmentsValues}
                      toggleLoadView={this._handleOnToggleLoadView}
                      onChangeJustify={this._handleOnChangeJustify}
                      onFileList={this._handleOnFileUpload}
                      referenceFilesList={referenceFilesList}
                      onFileListConfigSC={this._handleOnConfigurationUpload}
                      onBack={this._handleOnCloseEquipments}
                      onFileListReference={this._handleOnReferenceFileList}
                      onSaveAndBack={this._handleOnSaveAndBack}
                      onCreateEquipments={this._handleOnCreateEquipments}
                      onCompleteEquipments={this.handleOnCompleteEquipments}
                      onCompleteRequest={this._handleOnCompleteRequest}
                    />
                  ) : equipmentVisible === "view" ? (
                    <EquipmentsList
                      open={openEditEquipment}
                      toggleOpenEdit={this._handleOnToggleOpenEditEquipment}
                      idRequest={requestSelected.id}
                      title="Equipos Cargados"
                      loading={loadingEdit}
                      idBusinessModel={requestSelected.idBusinessModel}
                      subtitle="Lista de los Equipos Cargados al Requerimiento"
                      formOptions={formOptionsEquipments}
                      state={requestSelected.state}
                      equipments={equipments}
                      fileList={referenceFilesList}
                      references={referencesRequest}
                      justifications={justifications}
                      onFileList={this._handleOnReferenceFileList}
                      notify={this.notify}
                      onBack={this._handleOnCloseEquipments}
                      onChangeJustify={this._handleOnChangeJustify}
                      onEditEquipment={this._handleOnEditEquipment}
                      onSaveAndBack={this._handleOnSaveAndBack}
                      onCompleteProcess={this._handleOnCompleteRequest}
                    />
                  ) : viewResume ? (
                    <ResumeOffers
                      title="Calculo Oferta"
                      subtitle="Resumen del calculo realizado a la oportunidad"
                      wayPay={requestSelected.wayPay}
                      opp={requestSelected.opportunityNumber}
                      country={requestSelected.country}
                      type={requestSelected.idBusinessModel}
                      idRequest={requestSelected.id}
                      state={requestSelected.state}
                      resume={resumeOfferBase}
                      offersCalcs={offersCalcs}
                      fileList={referenceAjustFilesList}
                      onOfferWon={this.handleOnOfferWon}
                      onSubmit={this.handleOnSubmitAjustOffer}
                      onBack={this.handleOnBackOfferToRequest}
                      onFileList={this.handleOnReferenceAjustOffer}
                      generateDocuments={this.handleOnGenerateDocuments}
                    />
                  ) : (
                    <RequestDetail
                      request={requestSelected}
                      activityFlow={activityFlow}
                      equipments={equipments.ibm}
                      equipmentsSpare={equipmentsSpare}
                      configurations={configurationsRequest}
                      onNewVersion={this.handleOnNewVersion}
                      onChangeFlow={this._handleOnChangeFlowRequest}
                      addEquipments={this._handleOnAddEquipments}
                      onBack={this._handleOnMainView}
                      onOpenOffer={this.handleOnOpenResumeOffer}
                      onViewEquipments={this._handleOnViewEquipments}
                      onViewEquipmentsSpare={this._handleOnViewEquipmentsSpare}
                      onViewConfigurations={this._handleOnViewConfigurations}
                    />
                  )
                ) : (
                  <>
                    <FiltersCard
                      title="Filtros"
                      subtitle="Aplicar filtros a los requerimientos"
                      options={options}
                      values={{
                        opp: oppNumberSelected,
                        client: clientSelected,
                        state: stateSelected,
                        date: dateSelected,
                      }}
                      onClear={this._handleOnClearFilters}
                      onChangeFilters={this._handleOnChangeFilters}
                    />
                    <CardRequeriments
                      title="Lista de los requerimientos creados"
                      columns={columns}
                      page={page}
                      sizePerPage={sizePerPage}
                      data={this._handleOnRenderRequest(requeriments)}
                      onOpen={this._handleOnOpenRequeriment}
                      onSetPage={this.handleOnSetPage}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

MyRequests.defaultProps = {
  name: "Mis Requerimientos",
  parentName: "Requerimientos Digitales",
  requeriments: [],
  columns: [
    "#REF",
    "Versión",
    "Oportunidad",
    "Representante",
    "Cliente",
    "Estado",
    "Partes Críticas",
    "Modelo Negocio",
    "Fecha de Creación",
    "Fecha de Actualización",
    "Creado Por",
  ],
  activityFlow: [],
  formOptionsEquipments: {
    coverageLevel: [],
    officeHours: [],
    automaticRenewal: [],
    timeChangePart: [],
    validityService: [],
  },
  initialValuesEquipments: {},
  equipmentsCreated: {
    ibm: [],
    cisco: [],
  },
  equipments: {
    ibm: [],
    cisco: [],
  },
  options: {
    clients: [],
    states: [],
  },
  referencesRequest: [],
  justifications: [],
  configurationsRequest: [],
  commentsConfigurations: [],
  equipmentsSpare: [],
  resumeOfferBase: {
    officeHours: "",
    validityService: 0,
    totalEquipment: {
      totalPayment: 0,
      totalPrice: 0,
    },
    totalServices: {
      totalPayment: 0,
      totalPrice: 0,
    },
    totalSpareParts: {
      totalPayment: 0,
      totalPrice: 0,
    },
  },
  lastVersion: null,
  formOptionsVersion: {
    officeHours: [],
    validityService: [],
    wayPay: [],
  },
  offersCalcs: {},
};

MyRequests.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  parentName: PropTypes.string.isRequired,
  requeriments: PropTypes.array.isRequired,
  activityFlow: PropTypes.array.isRequired,
  equipmentsCreated: PropTypes.object.isRequired,
  formOptionsEquipments: PropTypes.object.isRequired,
  initialValuesEquipments: PropTypes.object.isRequired,
  equipments: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  referencesRequest: PropTypes.array.isRequired,
  configurationsRequest: PropTypes.array.isRequired,
  justifications: PropTypes.array.isRequired,
  commentsConfigurations: PropTypes.array.isRequired,
  equipmentsSpare: PropTypes.array.isRequired,
  resumeOfferBase: PropTypes.object.isRequired,
  formOptionsVersion: PropTypes.object.isRequired,
  offersCalcs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requeriments: getRequerimentsByUser(state),
  activityFlow: getActivityFlowRequest(state),
  formOptionsEquipments: getInitialValuesEquipments(state),
  equipmentsCreated: getEquipmentsCreated(state),
  equipments: getEquipmentsCreated(state),
  initialValuesEquipments: getInitialValuesFormEquipments(state),
  options: getOptionsFilterRequests(state),
  referencesRequest: getReferencesByRequest(state),
  configurationsRequest: getConfigurationsByRequest(state),
  justifications: getJustificationsByRequest(state),
  commentsConfigurations: getCommentsConfigurationsByRequest(state),
  equipmentsSpare: getEquipmentsSpare(state),
  referenceSpare: getReferencesSpareByRequest(state),
  resumeOfferBase: getResumeOffersByRequest(state),
  lastVersion: getLastVersionByRequest(state),
  formOptionsVersion: getOptionsFormToVersion(state),
  offersCalcs: getOffersCalc(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findActivityFlowRequest,
    findRequerimentsByUser,
    findFormValuesEquipmentsRequest,
    findEquipmentsByRequest,
    findReferencesByRequest,
    findJustificationsByRequest,
    findConfigurationsByRequest,
    findEquipmentsCreatedByRequest,
    findResumeOffersRequestById,
    createAjustOfferRequest,
    createJustifyByRequest,
    createOneEquipment,
    createManyEquipments,
    createCommentaryByRequest,
    createServiceOrderRequest,
    updateActivityFlow,
    updateEquipmentById,
    updateStateRequest,
    deactivateReferences,
    deactivateConfigurations,
    deactivateReferencesSpare,
    deactivateEquipmentSpareById,
    findReferencesSpareByRequest,
    createEquipmentSpare,
    updateEquipmentSpareById,
    findEquipmentsSpareByRequest,
    deactivateEquipmentById,
    sendEmailOfferWonRequest,
    findLastVersionByRequest,
    findFormValuesRequestOpportunity,
    findOptionsRequestToVersion,
    createNewVersionByRequest,
    updateAmountEquipmentsByRequest,
    createNewVersionByRejectRequest,
    findOffersRequestById,
  })(MyRequests)
);
