/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "FreelanceAltas" es un componente compartido por el MaintainComponents de Altas para abrir un modal el cual permite 
subir el archivo de excel para que el bot de MIS de alta a los freelance en SAP

*Props:
  row:va toda la informacion para el llenado de los campos del modal
  setModal: State para definir el tipo de modal que se abre ya sea crear o modificar
  showModal: State false para tener el modal cerra hasta que se de click y se abra el mismo
  dropDownOptions: useState con las opciones de los dropdowns
  setRealTable: State para recargar la pagina principal cuando se modifique
  Toast: funcion para las notificaciones
  title: prop en el cual se envia el titulo que va a aparecer en el modal segun sea crear o modificar
  type:prop en el cual se envia el tipo del modal segun sea crear o modificar
  setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Diego Meza  - Application Management GBM

*/

// react library
import React, { useState, useEffect, useRef } from "react";
//usedispatch for fetch
import { useDispatch } from "react-redux";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  CardHeader,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
// react-select library for filters
import Select from "react-select";
// antd components para que se muestre un spinner mientras se carga la pagina y su informacion
import { Spin } from "antd";
import { Upload, Icon } from 'antd';
//api urls for download
import urls from "api/urls.jsx";
import classnames from "classnames";
//accion que se utiliza tanto para las altas del proveedor
import { sendAltaRequest, deleteAltaFile } from "actions/Freelance.jsx";
import moment from "moment";

const { Dragger } = Upload;

const FreelanceAltas = ({
  row,
  setModal,
  showModal,
  dropDownOptions,
  setDropDownOptions,
  setRealTable,
  Toast,
  title,
  type,
  setRefreshOptions,
  subcontracts,
  setSubcontracts,
  subareaOptions,
  setSubareaOptions
}) => {
  //#region Funtions

  //state para guardar la información 
  const [altaInfo, setAltaInfo] = useState({});

  //#region state para guardar el modal cerrador y ocultar el boton de enviar
  const [state, setState] = useState({
    unmountOnClose: true,
    fileList: [],
    readyToSend: true,
  });

  const { fileList, unmountOnClose, readyToSend } = state;

  //#endregion

  //estado para guardar el nuevo nombre del archivo que viene del BE
  const [newFileName, setNewFileName] = useState("");

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region state para el estado del spin
  const [loading, setLoad] = useState(false);
  //#endregion


  //state para determinar en que tab estoy
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    setActiveTab((type === 2) ? "1" : "0")
    if (type === 1) {
      if (row["hireDate"]) {
        setAltaInfo((prevState) => ({
          ...prevState,
          "hireDate": row["hireDate"],
        }));
      }
      if (row["contractEndDate"]) {
        setAltaInfo((prevState) => ({
          ...prevState,
          "contractEndDate": row["contractEndDate"],
        }));
      }
    }
  }, [type])


  //#region Funcion para cancelar o cerrar el modal
  const toggleCloseModal = () => {
    setSubareaOptions([]);
    setAltaInfo({});
    setActiveTab("0");
    setNewFileName("");
    isInitialRender.current = true;
    setState((prevState) => ({
      ...prevState,
      fileList: [],
      readyToSend: true,
    }));
    setModal((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
      row: {},
      type: 0
    }));
  };
  //#endregion

  //funcion que cambia el tab
  const handleTab = (event, tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //#region campos que se muestran en el modal
  const [fields, setFields] = useState([
    {
      colWidth: "6",
      label: "Nombre",
      placeholder: "",
      modalType: "both",
      id: "firstName",
      options: "",
      type: "text",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: false
    },
    {
      colWidth: "6",
      label: "Segundo nombre",
      placeholder: "",
      modalType: "both",
      id: "middleName",
      options: "",
      type: "text",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "Apellido",
      placeholder: "",
      modalType: "both",
      id: "lastName",
      options: "",
      type: "text",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: false
    },
    {
      colWidth: "6",
      label: "Fecha de Nacimiento",
      placeholder: "",
      modalType: "both",
      id: "birthDate",
      options: "",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: false
    },
    {
      colWidth: "6",
      label: "Dirección",
      placeholder: "",
      modalType: "both",
      id: "address",
      options: "",
      type: "textarea",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: false
    },
    {
      colWidth: "6",
      label: "País",
      placeholder: "",
      modalType: "both",
      id: "country",
      options: "sapCountries",
      defaultValue: "countryName",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      optional: false
    },
    {
      colWidth: "6",
      label: "Nacionalidad",
      placeholder: "",
      modalType: "both",
      id: "nationality",
      options: "Nationality",
      defaultValue: "nationalityName",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      optional: false
    },
    {
      colWidth: "6",
      label: "Tipo de ID",
      placeholder: "",
      modalType: "both",
      id: "idType",
      options: "idType",
      defaultValue: "idTypeCode",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      optional: false
    },
    {
      colWidth: "6",
      label: "Identificación",
      placeholder: "",
      modalType: "both",
      id: "identification",
      options: "",
      type: "text",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: false
    },
    {
      colWidth: "6",
      label: "Email Personal",
      placeholder: "",
      modalType: "both",
      id: "personalEmail",
      options: "",
      type: "email",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: false
    },
    {
      colWidth: "6",
      label: "Fecha de ingreso",
      placeholder: "",
      modalType: "both",
      id: "hireDate",
      options: "",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: false
    },
    {
      colWidth: "6",
      label: "Fin de contrato",
      placeholder: "",
      modalType: "both",
      id: "contractEndDate",
      options: "",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: false
    },
    {
      colWidth: "6",
      label: "Company Code",
      placeholder: "",
      modalType: "both",
      id: "companyCode",
      options: "companyCode",
      defaultValue: "companyCodeText",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      optional: false
    },
    {
      colWidth: "6",
      label: "EE subgroup",
      placeholder: "",
      modalType: "both",
      id: "eeSubgroup",
      options: "EmployeeSubGroup",
      defaultValue: "eeSubGroupText",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      optional: false
    },
    {
      colWidth: "6",
      label: "Supplier",
      placeholder: "Ingrese el id del proveedor en SAP",
      modalType: "both",
      id: "supplier",
      options: "",
      type: "number",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "Subarea",
      placeholder: "",
      modalType: "both",
      id: "subarea",
      options: "subareaOptions",
      defaultValue: "subAreaName",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      optional: false
    },
    {
      colWidth: "6",
      label: "Personnel Subarea",
      placeholder: "",
      modalType: "both",
      id: "personnelSubarea",
      options: "PersonalArea",
      defaultValue: "personnelSubareaName",
      type: "select",
      isabled: false,
      visibleDefault: false,
      elementType: "Select",
    },


  ]);
  //#endregion

  //variable para campos extras cuando es una edición
  const [extraFieldsEdit, setExtraFieldsEdit] = useState([
    {
      colWidth: "6",
      label: "ID Posición",
      placeholder: "",
      modalType: "both",
      id: "positionId",
      options: "",
      type: "number",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "ID Colaborador",
      placeholder: "",
      modalType: "both",
      id: "idSap",
      options: "",
      type: "number",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "Email GBM",
      placeholder: "",
      modalType: "both",
      id: "gbmEmail",
      options: "",
      type: "email",
      disabled: true,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "UserName",
      placeholder: "",
      modalType: "both",
      id: "sapUserName",
      options: "",
      type: "text",
      disabled: true,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "Razón de Rechazado",
      placeholder: "",
      modalType: "both",
      id: "rejectedReason",
      options: "",
      type: "textarea",
      disabled: true,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "Respuesta del bot",
      placeholder: "",
      modalType: "both",
      id: "botResponse",
      options: "",
      type: "textarea",
      disabled: true,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "Solicitado En",
      placeholder: "",
      modalType: "both",
      id: "createdAt",
      options: "",
      type: "date",
      disabled: true,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
    {
      colWidth: "6",
      label: "Solicitante",
      placeholder: "",
      modalType: "both",
      id: "createdBy",
      options: "",
      type: "text",
      disabled: true,
      visibleDefault: false,
      elementType: "Input",
      optional: true
    },
  ]);


  //cambio de info
  function areAllFieldsNotEmpty(altaInfo) {
    for (const field of fields) {
      const { id, optional } = field;
      if (!optional) {
        // Check if the field's ID is a key in altaInfo and the value is blank
        if (!altaInfo.hasOwnProperty(id) || altaInfo[id] === "") {
          return true; // If any field is empty, return false
        }
      }
    }

    return false; // If all fields are not empty, return true
  }

  //funcion para llenar el json al BE
  const handleChangeInfo = (field, fieldDefault, e, type) => {
    let val = "";
    if (type === 'input') {
      val = e.target.value;
      setModal((prevState) => ({
        ...prevState,
        row: {
          ...prevState.row,
          [field]: val
        }
      }));
    } else {
      val = e.value;
      setModal((prevState) => ({
        ...prevState,
        row: {
          ...prevState.row,
          [field]: e.value,
          [fieldDefault]: e.label
        }
      }));
    }

    if (field === "companyCode") {
      //modificar la lista de subArea
      const newOptions = dropDownOptions.SubArea.filter(x => x.companyCode === val);
      setModal((prevState) => ({
        ...prevState,
        row: {
          ...prevState.row,
          ["subarea"]: undefined,
          ["subAreaName"]: undefined
        }
      }));
      setSubareaOptions(newOptions);
    }

    //add info 
    setAltaInfo((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  }

  const validateDates = (hireDate, contractEndDate) => {
    if (hireDate && contractEndDate) {
      if (new Date(hireDate) >= new Date(contractEndDate)) {
        return false;
      } else {
        return true;
      }
    }
  };

  const isInitialRender = useRef(true);

  useEffect(() => {

    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (type === 1) {
      //crear
      const result = areAllFieldsNotEmpty(altaInfo);
      // Update the readyToSend state based on allFieldsFilled
      setState((prevState) => ({
        ...prevState,
        readyToSend: result,
      }));
    } else if (type === 2) {
      //editar
      setState((prevState) => ({
        ...prevState,
        readyToSend: false,
      }));
    }
    else {
      setState((prevState) => ({
        ...prevState,
        readyToSend: true,
      }));
    }
  }, [altaInfo]);

  //enviar solicitud
  const handleSendAlta = () => {
    if (activeTab === "1") {
      //tipo formulario
      console.log(altaInfo)

      const eeSubgroup = altaInfo.eeSubgroup || (row && row.eeSubgroup) || "";
      const supplier = altaInfo.supplier || (row && row.supplier) || "";

      let contractEndDate = altaInfo.contractEndDate !== undefined ? altaInfo.contractEndDate : row.contractEndDate;
      let hireDate = altaInfo.hireDate !== undefined ? altaInfo.hireDate : row.hireDate;

      let vall = validateDates(hireDate, contractEndDate);

      if (!vall) {
        Toast.fire({
          title: "Atención",
          html: "La fecha final del contrato debe ser posterior a fecha de ingreso",
          type: "warning",
        });
        return;
      }
    }

    setLoad(true);
    dispatch(sendAltaRequest({ newFileName, altaInfo, activeTab, type, id: (row ? row.id : 0) })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRealTable((prevState) => !prevState);
        Toast.fire({
          title: "Atención",
          html: (type === 1) ? `La carga se ha realizado exitosamente` : "Se actualizado con éxito",
          type: "success",
        });
        toggleCloseModal();
      } else {
        Toast.fire({
          title: "Atención",
          html: payload.response.data.message,
          type: "warning",
        });
      }
      setLoad(false);

    });
  }

  //#region upload dragger options and function
  //funcion para eliminar o subir nuevos archivos
  const handleOnFileList = (info) => {

    const { status } = info.file;
    //console.log(status);
    if (status === "removed") {
      const { file: { name, uid, response: { status, payload } } } = info;

      setState((prevState) => ({
        ...prevState,
        fileList: fileList.filter((row) => row.uid !== uid),
        readyToSend: true,
      }));

      dispatch(deleteAltaFile({ name: newFileName })).then((resp) => {
        const { payload } = resp;
        if (payload.status === 200) {
          if (fileList.length === 1) {
            setState((prevState) => ({
              ...prevState,
              readyToSend: true,
            }));
          }
        } else {
          console.log("error al intentar borrar el archivo")
        }
      })
      setNewFileName("");
      console.log(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const { fileList, file: { status, name } } = info;
      let response = "";
      if (status === 'done') {
        console.log(info.file.response.nameNormalize);
        setNewFileName(info.file.response.nameNormalize);
        const { file } = info;
        response = file["response"];
        //habilitar el botón de enviar
        setState((prevState) => ({
          ...prevState,
          readyToSend: false,
        }));
      } else if (status === 'error') {
        console.log(`Ocurrio un error cargando el archivo ${name}.`);
      }

      setState((prevState) => ({
        ...prevState,
        fileList: fileList,
        readyToSend: false,
      }));
    }
  }

  const allowedFileTypes = [
    ".xlsx", ".xls"
  ];
  const uploadProps = {
    name: 'file',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
    accept: allowedFileTypes.join(","),
    multiple: false,
    action: `${urls.FREELANCE.uploadAltaFile}`,
    beforeUpload: file => {
      const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
      if (!allowedFileTypes.includes(fileType)) {
        console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
        return false;
      }
      return true;
    },
    onChange: info => {
      console.log(info)
      // Filtrar la lista de archivos válidos para mantener solo los que aún existen
      const filteredFiles = info.fileList.filter(file => {
        console.log(file)
        if (file.status) {
          return true;
        }
        return false;
      });
      info.fileList = filteredFiles;
      // Actualizar la lista de archivos válidos
      // Llamar a la función handleOnFileList con la lista de archivos válidos
      handleOnFileList(info);
    },
  };
  //#endregion

  const handleDownloadTemplate = (e) => {
    const downloadUrl = urls.FREELANCE.downloadFileTemplate;

    fetch(downloadUrl, {
      method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
    })
      .then(response => {
        if (response.ok) {
          window.location.assign(downloadUrl);
        } else {
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
            type: "warning",

          });
        }
      })
      .catch(error => {
        console.error('Error checking file existence:', error);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error al validar el archivo ",
          type: "warning",

        });
      });
  };

  //#endregion
  return (
    <>
      <Modal
        size="lg"
        isOpen={showModal}
        unmountOnClose={unmountOnClose}
        toggle={toggleCloseModal}
      >
        <Spin size="large" spinning={loading}>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody className="mb-lg-0">

            <Card className="shadow mb-lg-0">
              <CardHeader className="border-0">
                <Nav
                  className="nav-fill flex-column flex-md-row"
                  id="tabs-icons-text"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={activeTab === "1"}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: activeTab === "1",
                      })}
                      onClick={(e) => handleTab(e, "1")}
                      role="tab"
                    >
                      <span className="btn-inner--icon mr-2">
                        <i className="ni ni-archive-2" />
                      </span>
                      <span className="btn-inner--text mr-2">
                        Formulario
                      </span>
                    </NavLink>
                  </NavItem>
                  {type === 1 &&
                    <NavItem>
                      <NavLink
                        aria-selected={activeTab === "2"}
                        className={classnames("mb-sm-3 mb-md-0 ", {
                          active: activeTab === "2",
                        })}
                        onClick={(e) => handleTab(e, "2")}
                        role="tab"
                      >
                        <span className="btn-inner--icon mr-2">
                          <i className="ni ni-single-copy-04 mr-2" />
                        </span>
                        <span className="btn-inner--text">
                          Masivo
                        </span>
                      </NavLink>
                    </NavItem>

                  }


                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent activeTab={activeTab}>

                  <TabPane tabId="1">
                    <Form className="needs-validation" noValidate>
                      {/* <div className='nav-wrapper'> */}
                      <Card className="shadow mb-lg-0">
                        <CardBody>
                          <Row>
                            {

                              fields.map((field, key) =>

                                <Col sm={field.colWidth} key={key}>
                                  <FormGroup key={key}>
                                    <label className="form-control-label">
                                      {(field.optional) ? field.label : field.label + "*"}
                                    </label>
                                    {
                                      field.elementType === "Input" ? (
                                        <Input
                                          key={field.id}
                                          id={field.id}
                                          className="form-control"
                                          type={field.type}
                                          readOnly={field.disabled}
                                          value={
                                            row &&
                                              (row[field.id] !== undefined && row[field.id] !== "") ?
                                              (field.type === "date") ? moment.utc(row[field.id]).format("YYYY-MM-DD") : row[field.id]
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleChangeInfo(field.id, field.defaultValue, e, 'input')
                                          }
                                          placeholder={field.placeholder}
                                        />
                                      ) : field.elementType === "Select" ? (
                                        <Select
                                          key={field.id}
                                          id={field.id}
                                          placeholder={field.placeholder}
                                          name={field.id}
                                          className="basic-single text-dark"
                                          type="select"
                                          isSearchable
                                          isDisabled={field.disabled}
                                          value={
                                            row &&
                                            row[field.id] !== undefined &&
                                            {
                                              label: row[field.defaultValue],
                                              value: row[field.id],
                                            }
                                          }
                                          onChange={(e) =>
                                            handleChangeInfo(field.id, field.defaultValue, e, 'select')
                                          }
                                          options={(field.id === "subarea") ? subareaOptions : dropDownOptions[field.options]}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                  </FormGroup>
                                </Col>

                              )
                            }
                            {
                              type === 2 ?
                                extraFieldsEdit.map((field, key) =>

                                  <Col sm={field.colWidth} key={key}>
                                    <FormGroup key={key}>
                                      <label className="form-control-label">
                                        {(field.optional) ? field.label : field.label + "*"}
                                      </label>
                                      {
                                        field.elementType === "Input" ? (
                                          <Input
                                            key={field.id}
                                            id={field.id}
                                            className="form-control"
                                            type={field.type}
                                            readOnly={field.disabled}
                                            value={
                                              row &&
                                                (row[field.id] !== undefined && row[field.id] !== "") ?
                                                (field.type === "date") ? moment.utc(row[field.id]).format("YYYY-MM-DD") : row[field.id]
                                                : ""
                                            }
                                            onChange={(e) =>
                                              handleChangeInfo(field.id, field.defaultValue, e, 'input')
                                            }
                                            placeholder={field.placeholder}
                                          />
                                        ) : field.elementType === "Select" ? (
                                          <Select
                                            key={field.id}
                                            id={field.id}
                                            placeholder={field.placeholder}
                                            name={field.id}
                                            className="basic-single text-dark"
                                            type="select"
                                            isSearchable
                                            isDisabled={field.disabled}
                                            value={
                                              row &&
                                              row[field.id] !== undefined &&
                                              {
                                                label: row[field.defaultValue],
                                                value: row[field.id],
                                              }
                                            }
                                            onChange={(e) =>
                                              handleChangeInfo(field.id, field.defaultValue, e, "select")
                                            }
                                            options={(field.id === "subarea") ? subareaOptions : dropDownOptions[field.options]}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                    </FormGroup>
                                  </Col>

                                )
                                :
                                <></>
                            }
                          </Row>
                        </CardBody>
                      </Card>
                    </Form>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row className="mb-4">
                      <Col className="text-center" sm="12">
                        <Button
                          block
                          color="info"
                          className="mt-2"
                          onClick={(e) => handleDownloadTemplate(e)}
                        >
                          <span className="btn-inner--icon mr-2">
                            <i className="fas fa-download mr-2"></i>
                          </span>
                          Descargar Plantilla Masivo
                        </Button>
                      </Col>
                    </Row>
                    <Row></Row>
                    <Row className="mb-4">
                      <Col sm="12">
                        <Container>
                          <Dragger {...uploadProps} fileList={fileList}>
                            <p className="ant-uploading-drag-icon">
                              <Icon type="inbox" />
                            </p>
                            <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                            <p className="ant-uploading-hint">
                              Por favor subir el archivo de carga oficial
                            </p>
                          </Dragger>
                        </Container>
                      </Col>
                    </Row>

                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>



          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-icon"
              color="success"
              size="sm"
              block
              disabled={readyToSend}
              type="button"
              onClick={(e) => handleSendAlta()}
            >
              <span className="btn-inner--text">
                Envíar
              </span>
            </Button>

            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              block
              type="button"
              onClick={(e) => toggleCloseModal()}
            >
              <span className="btn-inner--text">Cerrar</span>
            </Button>
          </ModalFooter>
        </Spin>
      </Modal>
    </>
  );
};

export default FreelanceAltas;
