/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "ToAssignModal" de PoAssignation para la asignación de ordenes de compra a los freelance externos.

*Props:
    setModal: useState para cerrar el modal
    showModal: useState para guardar si el modal esta abierto o no
    dropDownOptions: useState con las opciones de los dropdowns
    setRealTable: useState para actualizar las filas de la tabla MainTable automaticamente
    Toast: funcion para las notificaciones
    MySwal: funcion para las notificaciones personalizadas
    type: useState para verifica si es una asignación por Hito o Normal
    setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/

//react library
import React, { useState, useEffect } from "react";
//usedispatch for fetch
import { useDispatch } from "react-redux";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  Form,
  InputGroup,
} from "reactstrap";
// antd components para el diseño cuando la información
import { Spin } from "antd";
//action para extraer la información de la PO seleccionada y para insertar la nueva asignación
import { getPoInfo, newPoAssignation } from "actions/Freelance.jsx";

const ToAssignModal = ({
  setModal,
  showModal,
  dropDownOptions,
  setRealTable,
  Toast,
  MySwal,
  type,
  setRefreshOptions,
}) => {

  //#region Functions

  //#region agregar la opcion de los items de la PO al state dropDownOptions para así utilizarlo en el select
  useEffect(() => {
    dropDownOptions["PoItems"] = [];
  }, []);
  //#endregion

  //#region initialize the modal with new data every time I click an element
  const [state, setState] = useState({
    unmountOnClose: true
  });

  const { unmountOnClose } = state;
  //#endregion

  //#region state para guardar la info seleccionada
  const [newInfo, setNewInfo] = useState({});
  //#endregion

  //#region state para guardar los items de la PO ingresada
  const [items, setItems] = useState([]);
  //#endregion

  //#region state para verificar si la PO necesita proyecto o no dependiendo del área del responsable
  const [needProject, setNeedProject] = useState(false);
  //#endregion

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region estado para el boolean del spinner
  const [loading, setLoad] = useState(false);
  //#endregion

  //#region company code de la PO ingresada para guardarla en la base de datos
  const [companyCode, setCompanyCode] = useState("");
  //#endregion

  //#region funcion para cancelar o cerrar el modal
  const toggleCloseModal = () => {
    setNewInfo({});
    setItems([]);
    dropDownOptions["PoItems"] = [];
    setModal((prevState) => !prevState.showModal);
  };
  //#endregion

  //#region funcion para guardar los campos seleccionados de la nueva asignación
  const handleChangeInfo = (constant, e) => {
    if (constant === "responsible") {
      //verificar si el responsable seleccionado es de SIL (necesita proyecto) o admin (se necesita seleccionar el area de la PO)
      let rol =
        typeof dropDownOptions["Responsables"].find((dt) => dt.value === e) !==
          "undefined"
          ? dropDownOptions["Responsables"].find((dt) => dt.value === e)["rol"]
          : "";

      let area = rol.split("Freelance Coordinator ")[1];

      setNewInfo((prevState) => ({
        ...prevState,
        area: area,
      }));

      if (rol.includes("SIL")) {
        setNeedProject(true);
      } else if (rol.includes("Admin")) {
        var areasObj = {};
        dropDownOptions["billsArea"].forEach(function (json) {
          areasObj[json["areaCode"]] = json["label"]; //se envia el label porque en el BE se busca el rol y su id
        });
        MySwal.fire({
          title: `Seleccione el área que desea asignar la Orden de Compra`,
          input: "select",
          inputOptions: areasObj,
          inputPlaceholder: `Seleccione la nueva área`,
          // showCancelButton: true,
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value === "") {
                resolve("Seleccione un área para la orden");
              } else {
                setNewInfo((prevState) => ({
                  ...prevState,
                  area: value,
                }));
                if (value === "SIL") {
                  setNeedProject(true);
                } else {
                  setNeedProject(false);
                }
                resolve();
              }
            });
          },
        });
      } else {
        setNeedProject(false);
      }

    }
    //se actualiza el state de la nueva informacion
    setNewInfo((prevState) => ({
      ...prevState,
      [constant]: e,
    }));
  };
  //#endregion

  //#region funcion para guardar la información del state newInfo en la base de datos
  const handleCreateAssignation = (e) => {
    setLoad(true);
    const forms = document.querySelectorAll(".formInfo input,Select");
    let cont = 0;
    Array.from(forms).forEach((input) => {
      if (input.id === "project" && !needProject) {
        input.classList.remove("is-invalid");
        return;
      }

      if (input.value === "") {
        input.classList.add("is-invalid");
        cont++;
      } else {
        input.classList.remove("is-invalid");
      }
    });
    if (cont > 0) {
      setLoad(false);
      return;
    }

    var found = items.filter(function (item) {
      return item.value === newInfo.poItem;
    });
    newInfo["description"] = found[0].label.split("|")[1];
    newInfo["companyCode"] = companyCode;
    newInfo["byHito"] = type;

    dispatch(newPoAssignation({ newInfo: newInfo })).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {
        setRealTable((prevCheck) => !prevCheck);
        setRefreshOptions((prevCheck) => !prevCheck);
        toggleCloseModal();
        Toast.fire({
          title: "Atención",
          html: "La orden de compra fue asignada correctamente",
          type: "success",
        });
      } else if (payload.response.status === 409) {
        Toast.fire({
          title: "Atención",
          html: "La orden de compra y el item ya estan asignados",
          type: "warning",
        });
      } else {
        //limpiar el upload
        setState((prevState) => ({
          ...prevState,
          readyToSend: true,
        }));
        Toast.fire({
          title: "Atención",
          html: payload.response.data.message,
          type: "warning",
        });
      }
      setLoad(false);
    });
  };
  //#endregion

  //#region funcion para buscar los items de la PO ingresada
  const handleGetPoInfo = () => {
    setLoad(true);
    const { purchaseOrder } = newInfo;
    // alert(purchaseOrder);
    try {
      dispatch(getPoInfo(purchaseOrder)).then((resp) => {
        const { payload } = resp;
        if (payload.status === 200) {
          const pInfo = payload.data.poInfo;
          // alert(JSON.stringify(pInfo));
          if (pInfo.length > 0) {
            setCompanyCode(payload.data.companyCode);
            // const pInfo = [
            //     {
            //         label: "0001 Item 1",
            //         value: "0001"
            //     },
            //     {
            //         label: "0002 Item 2",
            //         value: "0002"
            //     }
            // ];

            setItems(pInfo);
            // alert(items);
            dropDownOptions["PoItems"] = pInfo;
          } else {
            dropDownOptions["PoItems"] = [];
            setItems([]);
            Toast.fire({
              title: "Atención",
              html: "La PO no existe",
              type: "warning",
            });
          }
        } else {
          dropDownOptions["PoItems"] = [];
          setItems([]);
          Toast.fire({
            title: "Atención",
            html: payload.response.data.message,
            type: "warning",
          });
        }
        setLoad(false);
      });
    } catch (error) {
      Toast.fire({
        title: "Atención",
        html: error,
        type: "warning",
      });
      setLoad(false);
    }
  };
  //#endregion

  //#region inputs y selects del modal
  const fields = [
    {
      colWidth: "6",
      label: "Orden de Compra",
      placeholder: "Escriba la orden de compra de SAP",
      id: "purchaseOrder",
      type: "text",
      disabled: false,
      visibleDefault: false,
      elementType: "Combo",
      stati: null,
      invalidText: "Este campo no puede estar vacio",
    },
    {
      colWidth: "6",
      label: "Item",
      placeholder: "Ingrese la Po para seleccionar",
      id: "poItem",
      options: "PoItems",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      invalidText: "Este campo no puede estar vacio",
    },
    {
      colWidth: "6",
      label: "Consultor",
      placeholder: "Seleccione el consultor",
      id: "freelance",
      options: (type === "1") ? "accessCompanies" : "accessFreelance",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      invalidText: "Este campo no puede estar vacio",
    },
    {
      colWidth: "6",
      label: "Responsable",
      placeholder: "Seleccione el Proveedor",
      id: "responsible",
      options: "Responsables",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      invalidText: "Este campo no puede estar vacio",
    },
    {
      colWidth: "6",
      label: "Apoyo a área",
      placeholder: "Seleccione el área de apoyo",
      id: "helpArea",
      options: "helpArea",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      invalidText: "Este campo no puede estar vacio",
    },
    {
      colWidth: "6",
      label: "Proyecto",
      placeholder: "Seleccione un proyecto",
      id: "project",
      options: "assignationProjects",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
      invalidText: "Seleccione el proyecto SIL",
    },
  ];
  //#endregion

  //#endregion

  return (
    <>
      <Modal
        size="lg"
        isOpen={showModal}
        unmountOnClose={unmountOnClose}
        toggle={toggleCloseModal}
      >
        <Spin size="large" spinning={loading}>
          <ModalHeader>
            {type === "0"
              ? "Asignación de Orden de Compra"
              : "Asignación de Orden de Compra por Hito"}
          </ModalHeader>
          <ModalBody className="mb-lg-0">
            <Form noValidate className="needs-validation formInfo">
              <Card className="shadow mb-lg-0">
                <CardBody>
                  <Row>
                    {fields.map((row, keyFields) => (
                      <Col sm={row.colWidth} key={"col" + keyFields}>
                        <FormGroup>
                          <label className="form-control-label">
                            {row.label}
                          </label>
                          {row.elementType === "Combo" ? (
                            <InputGroup>
                              <Button
                                color="primary"
                                outline
                                onClick={(e) => handleGetPoInfo(e)}
                              >
                                <i className="fas fa-search" />
                              </Button>
                              <Input
                                // key={row.id}
                                id={row.id}
                                required={true}
                                className="form-control"
                                type="text"
                                valid={row.stati === "valid"}
                                invalid={row.stati === "invalid"}
                                placeholder={row.placeholder}
                                onChange={(e) =>
                                  handleChangeInfo(row.id, e.target.value)
                                }
                              />
                              <div className="invalid-feedback">
                                {row.invalidText}
                              </div>
                            </InputGroup>
                          ) : row.elementType === "Item" ? (
                            <>
                              <Input
                                // key={row.id}
                                id={row.id}
                                name={row.id}
                                required={true}
                                className="basic-single text-dark"
                                type={row.type}
                                placeholder={row.placeholder}
                                // issearchable
                                // classNamePrefix="select"
                                // isdisabled={row.disabled}
                                onChange={(e) =>
                                  handleChangeInfo(
                                    row.id,
                                    e.target.children[e.target.selectedIndex].id
                                  )
                                }
                              >
                                <option id=""></option>
                                {items !== {} &&
                                  items.map((item, keyItem) => {
                                    return (
                                      <option key={row.id + keyItem} id={item.value}>
                                        {item.label}
                                      </option>
                                    );
                                  })}
                              </Input>
                              <div className="invalid-feedback">
                                {row.invalidText}
                              </div>
                            </>
                          ) : row.elementType === "Input" ? (
                            <>
                              <Input
                                // key={row.id}
                                id={row.id}
                                className="form-control"
                                type={row.type}
                                readOnly={row.disabled}
                                placeholder={row.placeholder}
                                onChange={(e) =>
                                  handleChangeInfo(row.id, e.target.value)
                                }
                              />
                              <div className="invalid-feedback">
                                {row.invalidText}
                              </div>
                            </>
                          ) : (
                            <>
                              <Input
                                // key={row.id}
                                id={row.id}
                                name={row.id}
                                required={false}
                                className="basic-multi-select text-dark"
                                type={row.type}
                                placeholder={row.placeholder}
                                // issearchable
                                // classNamePrefix="select"
                                // isdisabled={row.disabled}
                                options={dropDownOptions[row.options]}
                                value={newInfo[row.defaultValueLabel]}
                                onChange={(e) =>
                                  handleChangeInfo(
                                    row.id,
                                    e.target.children[e.target.selectedIndex].id
                                  )
                                }
                              >
                                <option id=""></option>
                                {dropDownOptions[row.options] &&
                                  dropDownOptions[row.options].map((value, key2) => {
                                    return (
                                      value.label !== "Sales" && type === "1" || type === "0" ?
                                        <option key={row.id + key2} id={value.value}>
                                          {value.label}
                                        </option>
                                        :
                                        <></>
                                    );
                                  })}
                              </Input>
                              <div className="invalid-feedback">
                                {row.invalidText}
                              </div>
                            </>
                          )}
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </ModalBody>
          <ModalFooter>
            {
              <Button
                className="btn-icon"
                color="success"
                size="sm"
                block
                type="button"
                onClick={(e) => handleCreateAssignation(e)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-check" />
                </span>
                <span className="btn-inner--text">Confirmar</span>
              </Button>
            }

            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              block
              type="button"
              onClick={(e) => toggleCloseModal()}
            >
              <span className="btn-inner--icon mr-">
                <i className="ni ni-fat-remove" />
              </span>
              <span className="btn-inner--text">Cancelar</span>
            </Button>
          </ModalFooter>
        </Spin>
      </Modal>
    </>
  );
};

export default ToAssignModal;
