/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
This is the component of the action buttons showed at Extra Hours RecordTable component
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import { FaEye } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { Button } from "reactstrap";

function ActionButtons({ onDetails, onHide, request, status }) {
  return (
    <div
      style={{
        whiteSpace: "normal",
        textAlign: "justify",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <div>
        <Button onClick={() => onDetails(request)}>
          <FaEye style={{ color: "dodgerblue", fontSize: "15px" }} />
        </Button>
      </div>
      <div>
        {status === "Cancelada" ||
        status === "Rechazada" ||
        status === "Expirada" ? (
          <Button disabled onClick={() => onHide(request)}>
            <MdOutlineCancel style={{ color: "grey", fontSize: "15px" }} />
          </Button>
        ) : (
          <Button onClick={() => onHide(request)}>
            <MdOutlineCancel style={{ color: "red", fontSize: "15px" }} />
          </Button>
        )}
      </div>
    </div>
  );
}

ActionButtons.propTypes = {
  onDetails: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
};
export default ActionButtons;
