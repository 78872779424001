import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Row, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

const CardImportSignature = props => {
  const {
    extension,
    celPhone,
    changeExtention,
    changeCelPhone,
    preView,
    handleOnBack,
    changeInputPhone,
    changeInputExtention,
    codeCountry,
    changeName,
    changeInputName,
    valueName,
    changeCodeCountry,
    changeInputCode,
    user,
    setCountry
  } = props

  return (
    <Card className="bg-secondary shadow border-0">
      <CardHeader className="text-center bg-transparent">
        <h4 className="heading-small text-muted" style={{ fontSize: '.85rem' }}>Generar firma</h4>
      </CardHeader>
      <CardBody>
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="input-username"
          >
            Nombre:
          </label>
          <Input
            className="form-control"
            name="extension"
            onChange={changeName}
            onChange={changeInputName}
            type="text"
            defaultValue={valueName}
          />
        </FormGroup>
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="input-username"
          >
            Extensión:
          </label>
          <Input
            className="form-control"
            name="extension"
            value={extension}
            onChange={changeExtention}
            type="text"
            pattern="{0,8}"
            onChange={changeInputExtention}
          />
        </FormGroup>
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="input-username"
          >
            Número de Celular :
          </label>
          <Row>
            <Col sm="12" md="3">
              <Input
                className="form-control"
                name="codeCountry"
                defaultValue={codeCountry}
                onChange={changeCodeCountry}
                onChange={changeInputCode}
                type="text" />
            </Col>
            <Col sm="12" md="9">
              <Input
                className="form-control"
                name="celular"
                value={celPhone}
                onChange={changeCelPhone}
                pattern="[0-9()-]{0,30}"
                onChange={changeInputPhone}
                type="text"
              />
            </Col>
          </Row>
        </FormGroup>
      </CardBody>
      <CardFooter className="bg-transparent">
        <Row className="justify-content-end">
          <Col sm="6" className="d-flex justify-content-end">
            <Button color="info"
              onClick={handleOnBack}>
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>Volver
            </Button>
          </Col>
          {
            extension &&
            <Col sm="6" className="d-flex justify-content-end">
              <Button color="success"
                onClick={preView}>
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-eye" />
                </span>Visualizar
              </Button>
            </Col>
          }
        </Row>
      </CardFooter>
    </Card>
  );
};

CardImportSignature.propTypes = {
  handleOnBack: PropTypes.func.isRequired,
};

export default CardImportSignature;
