// // nodejs library for dates
// import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

const ConflictInterestsForm = (props) => {
  const { title, subtitle, initialValues, cols, onSignature, onAddSupplier } =
    props;

  if (initialValues.suppliersExtra.length) {
    initialValues.isSupplierRelationship = "1";
  }

  const validate = (values) => {
    const errors = {};
    if (!values.employeeID || values.employeeID === "")
      errors.employeeID = "Debes ingresar tú ID del colaborador";
    if (!values.nameEmployee || values.nameEmployee === "")
      errors.nameEmployee = "Debes ingresar tú nombre del colaborador";
    if (!values.positionEmployee || values.positionEmployee === "")
      errors.positionEmployee = "Debes ingresar tú posición";
    if (!values.country || values.country === "")
      errors.country = "Debes ingresar tú país";
    if (!values.isSupplierRelationship || values.isSupplierRelationship === "0")
      errors.isSupplierRelationship = "Debes seleccionar una opción";
    if (values.isSupplierRelationship === "1") {
      // if (!values.supplierName || values.supplierName === "")
      //   errors.supplierName = "Debes ingresar el nombre del proveedor";
      // if (!values.name || values.name === "")
      //   errors.name =
      //     "Debes ingresar el nombre de la persona con la que se tiene la relación";
      // if (!values.relationType || values.relationType === "")
      //   errors.relationType = "Debes ingresar el tipo de relación";
      // if (!values.relationType || values.relationType === "")
      //   errors.relationType =
      //     "Debes ingresar el cargo de la persona con la que se tiene la relación";
      // if (!values.position || values.position === "")
      //   errors.position =
      //     "Debes ingresar el cargo de la persona con la que se tiene la relación";
      // if (!values.supplierInfo || values.supplierInfo === "")
      //   errors.supplierInfo =
      //     "Debes ingresar que servicio le brinda el proveedor a GBM";
      // if (
      //   !values.isSupplierContractingLink ||
      //   values.isSupplierContractingLink === "0"
      // )
      //   errors.isSupplierContractingLink = "Debes seleccionar una opción";
      // if (!values.hasSupplierBenefit || values.hasSupplierBenefit === "0")
      //   errors.hasSupplierBenefit = "Debes seleccionar una opción";
      // if (values.hasSupplierBenefit === "1") {
      //   if (!values.benefitDescription || values.benefitDescription === "")
      //     errors.benefitDescription = "Debes ingresar el detalle";
      // }
    }
    return errors;
  };

  return (
    <Row>
      <div className="col">
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={onSignature}
                validate={validate}
                initialValues={initialValues}
                render={({
                  handleSubmit,
                  values,
                  submitting,
                  validating,
                  valid,
                }) => (
                  <FormRS onSubmit={handleSubmit}>
                    <div className="form-row justify-content-center align-items-center">
                      <Col className="mt-3" sm="12" md="12">
                        <h3 className="mb-4">
                          GBM como parte del procedimiento PR-PRV-001 de
                          Selección y Registro de Proveedores y en aras evitar
                          posibles conflictos de interés le solicita que
                          responder lo siguiente:
                        </h3>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="employeeID"
                          >
                            ID del Colaborador
                          </Label>
                          <Field name="employeeID">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="number"
                                  placeholder="Ingrese su número de colaborador"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="nameEmployee"
                          >
                            Nombre del Colaborador
                          </Label>
                          <Field name="nameEmployee">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese su nombre"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="positionEmployee"
                          >
                            Posición
                          </Label>
                          <Field name="positionEmployee">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese su posición"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <FormGroup>
                          <Label className="form-control-label" for="country">
                            País
                          </Label>
                          <Field name="country">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese su país"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row justify-content-center align-items-center">
                      <Col
                        className="mt-3 d-flex align-items-center justify-content-center"
                        sm="12"
                        md="12"
                      >
                        <Label
                          className="pl-1 mb-4"
                          style={{ textAlign: "justify" }}
                        >
                          Conflicto de interés se entiende como aquella o
                          aquellas situaciones en las que el juicio de una
                          persona se puede ver afectado o influenciado por un
                          interés personal o particular. Es decir, una persona
                          incurre en un conflicto de interés cuando en vez de
                          cumplir con lo debido, podría guiar sus decisiones o
                          actuar en beneficio propio o de un tercero.
                        </Label>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="isSupplierRelationship"
                          >
                            ¿Tiene usted alguna relación familiar, romantica o
                            amistosa con una persona física o jurídica que es
                            proveedor de GBM?
                          </Label>
                          <Field name="isSupplierRelationship">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="1">Sí</option>
                                  <option value="2">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col
                        className="d-flex align-items-center justify-content-end"
                        xs="6"
                        md="2"
                      ></Col>
                      {parseInt(values.isSupplierRelationship) === 1 && (
                        <Col
                          className="d-flex align-items-center justify-content-end"
                          xs="6"
                          md="3"
                        >
                          <Button
                            block
                            size="sm"
                            color="info"
                            className="btn-icon btn-3"
                            onClick={onAddSupplier}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-user-plus" />
                            </span>
                            <span className="btn-inner--text">
                              Agregar un nuevo proveedor
                            </span>
                          </Button>
                        </Col>
                      )}
                      {/* <Col className="mt-3" sm="12" md="7">
                        <Label
                          className="pl-4"
                          style={{ textAlign: "justify" }}
                        >
                          Conflicto de interés se entiende como aquella o
                          aquellas situaciones en las que el juicio de una
                          persona se puede ver afectado o influenciado por un
                          interés personal o particular. Es decir, una persona
                          incurre en un conflicto de interés cuando en vez de
                          cumplir con lo debido, podría guiar sus decisiones o
                          actuar en beneficio propio o de un tercero.
                        </Label>
                      </Col> */}
                    </div>
                    {parseInt(values.isSupplierRelationship) === 1 && (
                      <>
                        {/* <div className="form-row align-items-center">
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="supplierName"
                              >
                                Nombre del Proveedor
                              </Label>
                              <Field name="supplierName">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="text"
                                      placeholder="Ingrese el nombre del proveedor"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label className="form-control-label" for="name">
                                Nombre de la persona con la que se tiene la
                                relación
                              </Label>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="text"
                                      placeholder="Ingrese el nombre de la persona"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="relationType"
                              >
                                Tipo de Relación
                              </Label>
                              <Field name="relationType">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="text"
                                      placeholder="Ingrese el tipo de la relación"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </div>
                        <div className="form-row align-items-center">
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="position"
                              >
                                Cargo de la persona con la que se tiene la
                                relación
                              </Label>
                              <Field name="position">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="text"
                                      placeholder="Ingrese el cargo de la persona"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="supplierInfo"
                              >
                                ¿Que servicio le brinda el proveedor a GBM?
                              </Label>
                              <Field name="supplierInfo">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="text"
                                      placeholder="Ingrese que servicio le brinda el proveedor a GBM"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="isSupplierContractingLink"
                              >
                                ¿Tiene usted desde su puesto de trabajo en GBM
                                participación en la contratación del proveedor?
                              </Label>
                              <Field name="isSupplierContractingLink">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="select"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    >
                                      <option value="0">
                                        Seleccione una opción
                                      </option>
                                      <option value="1">Sí</option>
                                      <option value="2">No</option>
                                    </Input>
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </div>
                        <div className="form-row align-items-center">
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="hasSupplierBenefit"
                              >
                                ¿Posee usted algun tipo de relación laboral o
                                beneficio económico con ese proveedor?
                              </Label>
                              <Field name="hasSupplierBenefit">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="select"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    >
                                      <option value="0">
                                        Seleccione una opción
                                      </option>
                                      <option value="1">Sí</option>
                                      <option value="2">No</option>
                                    </Input>
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          {parseInt(values.hasSupplierBenefit) === 1 && (
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  for="benefitDescription"
                                >
                                  Detalle el tipo de relación laboral o
                                  beneficio económico con ese proveedor
                                </Label>
                                <Field name="benefitDescription">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="text"
                                        placeholder="Ingrese el detalle"
                                        max={250}
                                        valid={
                                          !meta.error &&
                                          meta.modified &&
                                          meta.touched
                                        }
                                        invalid={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="invalid-feedback">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          )}
                          <Col className="mb-3" sm="12" md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                for="comments"
                              >
                                Comentarios, si lo requiere
                              </Label>
                              <Field name="comments">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="textarea"
                                      placeholder="Escriba aquí los comentarios."
                                      maxLength={500}
                                      rows="3"
                                      resize="none"
                                      valid={
                                        !meta.error &&
                                        meta.modified &&
                                        meta.touched
                                      }
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </div> */}
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              {cols.map((row, key) => (
                                <th
                                  key={key}
                                  style={{
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  {row.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="list">
                            {initialValues.suppliersExtra.map((row, key) => (
                              <tr key={key}>
                                {cols.map((col, key) => (
                                  <th
                                    key={key}
                                    style={{
                                      whiteSpace: "normal",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row[col.key]}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}
                    <div className="form-row justify-content-center align-items-center">
                      <Col
                        className="mt-3 d-flex align-items-center justify-content-center"
                        sm="12"
                        md="12"
                      >
                        <Label
                          className="pb-4"
                          style={{ textAlign: "justify" }}
                        >
                          Toda la información otorgada en el formulario se
                          espera que se realice con la mayor transparencia de
                          parte de los colaboradores como parte de sus
                          compromisos con GBM. La información otorgada podrá ser
                          sujeta a verificación con la finalidad de salvaguardar
                          los intereses de GBM.
                        </Label>
                      </Col>
                    </div>
                    <div className="form-row align-items-center justify-content-center">
                      <Col
                        className="mb-3 d-flex align-items-center justify-content-center"
                        sm="12"
                        md="10"
                      >
                        <FormGroup check>
                          <Field name="termsAndConditions" type="checkbox">
                            {({ input, meta }) => (
                              <>
                                <Label
                                  style={{ textAlign: "center", color: "red" }}
                                >
                                  <Input
                                    htmlFor="termsAndConditions"
                                    {...input}
                                    type="checkbox"
                                    invalid={meta.error && meta.touched}
                                  />{" "}
                                  Confirmo que toda la información brindada es
                                  verdadera
                                </Label>
                                <div style={{ marginBottom: "1rem" }}>
                                  {meta.error && meta.touched && (
                                    <span
                                      style={{
                                        fontSize: "80%",
                                        color: "#fb6340",
                                      }}
                                    >
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center justify-content-center">
                      <Col className="col-auto">
                        <Button
                          disabled={validating || !values.termsAndConditions}
                          className="btn-icon"
                          block
                          color="success"
                          type="submit"
                          // onClick={handleSubmit}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-signature" />
                          </span>
                          <span className="btn-inner--text">
                            Registrar Firma
                          </span>
                        </Button>
                      </Col>
                    </div>
                  </FormRS>
                )}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </Row>
  );
};

ConflictInterestsForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default ConflictInterestsForm;
