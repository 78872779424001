// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
import ImportExcel from "react-html-table-to-excel";
import { PiExportBold } from "react-icons/pi";
import { MdModeEdit } from "react-icons/md";
// reactstrap components
import {
  Badge,
  Input,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const UsersAccessList = (props) => {
  const {
    title,
    subtitle,
    cols,
    rows,
    page,
    onDelete,
    addUser,
    editUser,
    sizePerPage,
    onSetPage,
  } = props;

  const paginations =
    rows.length > sizePerPage
      ? rows.length / sizePerPage > Math.round(rows.length / sizePerPage)
        ? Math.round(rows.length / sizePerPage) + 1
        : Math.round(rows.length / sizePerPage)
      : 1;

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink onClick={() => onSetPage(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return options.length >= 5
      ? page >= 5
        ? options.slice(page - 3, page + 2)
        : options.slice(0, 5)
      : options;
  };

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="12" md="7">
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            <Col
              xs="12"
              md="5"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div style={{ display: "flex", gap: "2rem" }}>
                <ImportExcel
                  id="BotonExportarHoursReport"
                  className="btn btn-primary"
                  table="tablaExportarUsuarios"
                  filename={`Historial de usuarios`}
                  sheet="Hoja 1"
                  buttonText={
                    <>
                      <PiExportBold
                        style={{
                          color: "white",
                          fontSize: "13px",
                        }}
                      />{" "}
                      Exportar
                    </>
                  }
                />

                <Button
                  className="btn-round btn-icon"
                  color="success"
                  onClick={addUser}
                  size="sm"
                >
                  <span className="btn-inner--text">
                    <i className="fas fa-folder-plus" />
                  </span>
                  <span
                    style={{ fontSize: "13px" }}
                    className="btn-inner--text"
                  >
                    Agregar Usuario
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>

        {true ? (
          <div style={{ display: "none" }}>
            <Table
              id="tablaExportarUsuarios"
              className="align-items-center table-flush"
              responsive
              striped
            >
              <thead className="thead-light">
                <tr>
                  {cols.map((row, key) => (
                    <th
                      key={key}
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      {row.name}
                    </th>
                  ))}
                  <th />
                </tr>
              </thead>
              <tbody className="list">
                {rows.map((row, key) => (
                  <tr key={key}>
                    {cols.map((col, key) =>
                      col.key === "createdAt" || col.key === "updatedAt" ? (
                        <th
                          style={{ whiteSpace: "normal", textAlign: "center" }}
                        >
                          {row[col.key]
                            ? moment(row[col.key])
                                .utc()
                                .utcOffset(moment().utcOffset())
                                .format("DD/MM/YYYY")
                            : "N/A"}
                        </th>
                      ) : col.key === "country" ? (
                        <th
                          style={{ whiteSpace: "normal", textAlign: "center" }}
                        >
                          {row[col.key] ? (
                            row[col.key].length >= 7 ? (
                              <Badge className="badge-md" color="primary" pill>
                                Regional
                              </Badge>
                            ) : (
                              row[col.key].split(",").map((ele) => (
                                <Badge
                                  className="badge-md"
                                  color="primary"
                                  pill
                                >
                                  {ele}
                                </Badge>
                              ))
                            )
                          ) : (
                            "N/A"
                          )}
                        </th>
                      ) : (
                        <th
                          style={{ whiteSpace: "normal", textAlign: "center" }}
                        >
                          {row[col.key] ? row[col.key] : "N/A"}
                        </th>
                      )
                    )}
                    <th
                      key="action"
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      <a
                        className="table-action table-action"
                        href="#"
                        onClick={() => onDelete(row)}
                      >
                        <i className="fas fa-trash" />
                      </a>
                      <a
                        className="table-action table-action"
                        href="#"
                        onClick={() => onDelete(row)}
                      >
                        <i className="fas fa-trash" />
                      </a>
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              {cols.map((row, key) => (
                <th
                  key={key}
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  {row.name}
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody className="list">
            {rows
              .slice((page - 1) * sizePerPage, page * sizePerPage)
              .map((row, key) => (
                <tr key={key}>
                  {cols.map((col, key) =>
                    col.key === "createdAt" || col.key === "updatedAt" ? (
                      <th
                        // key={key}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        {row[col.key]
                          ? moment(row[col.key])
                              .utc()
                              .utcOffset(moment().utcOffset())
                              .format("DD/MM/YYYY")
                          : "N/A"}
                      </th>
                    ) : col.key === "country" ? (
                      <th
                        // key={key}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        {row[col.key] ? (
                          row[col.key].length >= 7 ? (
                            <Badge className="badge-md" color="primary" pill>
                              Regional
                            </Badge>
                          ) : (
                            row[col.key].split(",").map((ele) => (
                              <Badge className="badge-md" color="primary" pill>
                                {ele}
                              </Badge>
                            ))
                          )
                        ) : (
                          "N/A"
                        )}
                      </th>
                    ) : (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {row[col.key] ? row[col.key] : "N/A"}
                      </th>
                    )
                  )}
                  <th
                    key="action"
                    style={{
                      whiteSpace: "normal",
                      textAlign: "center",
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      className="table-action"
                      href="#"
                      onClick={() => editUser(row)}
                    >
                      <i
                        className="fas fa-pencil-alt"
                        style={{ color: "#4e8fd6" }}
                      />
                    </a>

                    <a
                      className="table-action"
                      href="#"
                      onClick={() => editUser(row)}
                    >
                      <i className="fas fa-trash" style={{ color: "red" }} />
                    </a>
                  </th>
                </tr>
              ))}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="p-1">
                <span className="pagination mb-0">
                  Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                  {page * sizePerPage > rows.length
                    ? rows.length
                    : page * sizePerPage}{" "}
                  de {rows.length} resultados
                </span>
              </Col>
              <Col xs="12" md="6" className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => onSetPage(page === 1 ? page : page - 1)}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {renderPaginations()}
                  <PaginationItem
                    className={page === paginations ? "disabled" : ""}
                  >
                    <PaginationLink
                      onClick={() =>
                        onSetPage(page === paginations ? page : page + 1)
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>
    </>
  );
};

UsersAccessList.propTypes = {};

export default UsersAccessList;
