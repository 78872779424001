// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
  FormGroup,
  Button,
  CardHeader,
  Card,
} from "reactstrap";

class AdminHeader extends Component {
  state = {
    country: "Todos",
  };

  // handleSubmit = () => {
  //   console.log(this.props.usersList);
  // };

  render() {
    const {
      name,
      parentName,
      optionalInput,
      letterDashboard,
      recordsDashboard,
      selectionChange,
      selectionOptionalUser,
      usersList,
      selectedUser,
      countries,
      countrySelected,
      yearsStatus,
      yearSelected,
      typeDashboardSelected,
      buttonSwitchFunc,
    } = this.props;

    return (
      <div className="header header-dark header-background  pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col xs="12" md="7">
                <h6 className="fullcalendar-title h2 text-grey-gbm  d-inline-block mb-0">
                  {name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4 header-home-gbm"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <i className="fas text-grey-gbm " />
                    </a>
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem> */}
                  <BreadcrumbItem
                    aria-current="page"
                    className="active text-lightgrey-gbm"
                  >
                    {parentName}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {letterDashboard && (
                <Col
                  className="text-right d-flex justify-content-end"
                  xs="12"
                  md="5"
                >
                  <Row className="align-items-center">
                    <Col className="text-right" xs="12" md="4">
                      <FormGroup style={{ width: "100px" }}>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                          style={{ width: "100px" }}
                        >
                          Tipo de Vista
                        </label>
                        <Select
                          className="basic-single"
                          id="example-month-input"
                          type="select"
                          isSearchable={false}
                          options={[
                            { value: 1, label: "Estado" },
                            { value: 2, label: "Nivel" },
                          ]}
                          value={typeDashboardSelected}
                          onChange={(e) => selectionChange("typeDashboard", e)}
                        ></Select>
                      </FormGroup>
                    </Col>
                    <Col className="text-right" xs="12" md="4">
                      <FormGroup style={{ width: "100px" }}>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                          style={{ width: "100px" }}
                        >
                          País
                        </label>
                        <Select
                          className="basic-single"
                          id="example-month-input"
                          type="select"
                          isSearchable={false}
                          options={countries}
                          value={countrySelected}
                          onChange={(e) =>
                            selectionChange("countrySelected", e)
                          }
                        ></Select>
                      </FormGroup>
                    </Col>
                    <Col className="text-right" xs="12" md="4">
                      <FormGroup style={{ width: "100px" }}>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                          style={{ width: "100px" }}
                        >
                          Año
                        </label>
                        <Select
                          className="basic-single"
                          id="example-month-input"
                          type="select"
                          isSearchable={false}
                          options={yearsStatus}
                          value={yearSelected}
                          onChange={(e) => selectionChange("yearSelected", e)}
                        ></Select>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              )}
              {recordsDashboard && (
                <Col
                  className="text-right d-flex justify-content-end"
                  xs="12"
                  md="5"
                >
                  <FormGroup style={{ width: "100px" }}>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                      style={{ width: "100px" }}
                    >
                      Año
                    </label>
                    <Select
                      className="basic-single"
                      id="example-month-input"
                      type="select"
                      isSearchable={false}
                      options={yearsStatus}
                      value={yearSelected}
                      onChange={(e) => selectionChange("yearSelected", e)}
                    ></Select>
                  </FormGroup>
                </Col>
              )}
              {optionalInput && (
                <Row className="align-items-center">
                  <Col className="text-right" xs="12" md="6">
                    {countries.length > 0 && (
                      <FormGroup style={{ width: "100px" }}>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                          style={{ width: "100px" }}
                        >
                          País
                        </label>
                        <Select
                          className="basic-single"
                          id="example-month-input"
                          type="select"
                          isSearchable={false}
                          options={countries}
                          value={{
                            value: countrySelected,
                            label: countrySelected,
                          }}
                          onChange={(e) => selectionChange("country", e)}
                        ></Select>
                      </FormGroup>
                    )}
                  </Col>
                  <Col className="text-right" xs="12" md="6">
                    <FormGroup style={{ width: "100px" }}>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                        style={{ width: "100px" }}
                      >
                        Año
                      </label>
                      <Select
                        className="basic-single"
                        id="example-month-input"
                        type="select"
                        isSearchable={false}
                        options={yearsStatus}
                        value={{
                          value: yearSelected,
                          label: yearSelected,
                        }}
                        onChange={(e) => selectionChange("year", e)}
                      ></Select>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Row>
            {selectionOptionalUser && (
              <Card>
                <CardHeader>
                  <Row style={{ float: "right" }}>
                    <Button
                      className="btn-icon float-right"
                      color="info"
                      size="sm"
                      type="button"
                      onClick={buttonSwitchFunc}
                    >
                      <span className="btn-inner--icon mr-2">
                        <i className="fas fa-table"></i>
                      </span>
                      <span className="btn-inner--text">
                        Volver a la tabla de registros
                      </span>
                    </Button>
                  </Row>
                  <br></br>
                  <br></br>
                  <br></br>

                  <Row className="align-items-center">
                    <Col className="text-right" xs="12" md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Año
                        </label>
                        <Select
                          className="basic-single"
                          id="example-month-input"
                          type="select"
                          isSearchable={false}
                          options={yearsStatus}
                          value={{
                            value: yearSelected,
                            label: yearSelected,
                          }}
                          onChange={(e) => selectionChange("year", e)}
                        ></Select>
                      </FormGroup>
                    </Col>
                    <Col className="text-right" xs="12" md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Rol
                        </label>
                        <Select
                          id="example-month-input"
                          type="select"
                          isSearchable={false}
                          placeholder={"Colaborador"}
                          onChange={(e) => selectionChange("rol", e)}
                          options={[
                            { value: "userName", label: "Colaborador" },
                            { value: "ceoName", label: "Supervisor" },
                            {
                              value: "preApproverName",
                              label: "Preaprobador",
                            },
                          ]}
                        ></Select>
                      </FormGroup>
                    </Col>
                    <Col className="text-right" xs="12" md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Colaborador
                        </label>
                        <Select
                          id="example-month-input"
                          type="select"
                          placeholder={selectedUser}
                          options={usersList}
                          value={selectedUser}
                          onChange={(e) => selectionChange("col", e)}
                        ></Select>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

AdminHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  optionalInput: PropTypes.bool,
  selectionChange: PropTypes.func,
  selectionOptionalUser: PropTypes.bool,
  usersList: PropTypes.array,
  selectedUser: PropTypes.string,
  countries: PropTypes.array,
  // countrySelected: PropTypes.string,
  yearsStatus: PropTypes.array,
  // yearSelected: PropTypes.number,
  buttonSwitchFunc: PropTypes.func,
};

export default AdminHeader;
