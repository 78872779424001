import React, { Component, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import "assets/css/table.css"
import moment from "moment";
import "moment/locale/es";
// reactstrap components
import { Container } from "reactstrap";
import Filters from 'components/Contacts/Filters.jsx';
import CustomersRequest from 'components/Contacts/CustomersRequest.jsx';
import RequestContactsList from 'components/Contacts/requestContactsList.jsx';
import ModalReplacement from "components/Contacts/ModalReplacement.jsx"
import ModalNewContact from 'components/Contacts/ModalNewContact.jsx';
import ModalImportExcel from 'components/Contacts/ModalImportExcel';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
//Actions Contacts
import {
    getCustomers, getContacts, getFunctions, getDepartaments, getCountrys, getHistory, getFileUpdate,
    updateUserContact, createContact, confirmCheckContact, removeConfirmContact, lockContact, downloadExcel
} from "actions/contacts.jsx";
//Token 
import { getUserProtected, getTeamsUserAccess } from "selectors/adminLayout";
//Selector Contacts
import { getAllCustomers, getAllContactsByCustomer, getAllFunctions, getAllDepartaments, getAllCountrys, getAllHistory, getContactUpdate, getFileUpdateContact } from "selectors/contacts.jsx";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// antd components
import { message, Spin } from 'antd';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples'
const { getCountryCallingCode } = require('libphonenumber-js');
moment.locale("es");
class CustomersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            page: 1,
            files: [],
            pageLock: 1,
            pageContacts: 1,
            sizePerPageReplace: 15,
            sizePerPage: 10,
            sizePerPageContacts: 10,
            searchText: {},
            searchTextLock: {},
            searchTextCutomer: {},
            modalInfo: false,
            modalImport: false,
            modalNewContact: false,
            showModalStatus: false,
            newReplacement: "",
            previusPage: true,
            loading: true,
            languageSelect: "",
            contactInfo: {},
            editContactInfo: {},
            customerId: "",
            customerName: "",
            rowContact: "",
            rowContactSustitute: "",
            changeConfirm: false,
            invalid: {},
            isLock: 1,
            userRol: {},
            idFileRequest: "",
            newContactInfo: { FUNCTION: "", DEPARTAMENT: "", PHONE1: "", MOBILE1: "", EXT1: "", PHONE2: "", MOBILE2: "", EXT2: "" },
            isAdmin: false
        }
        this.handelOnToggleModalSaveContact = this.handelOnToggleModalSaveContact.bind(this)
        this.handelOnToggleModalSaveNewContact = this.handelOnToggleModalSaveNewContact.bind(this)
        this.handelOnToggleModalLockContact = this.handelOnToggleModalLockContact.bind(this)
        this.handelOnToggleModalSaveLockNewContact = this.handelOnToggleModalSaveLockNewContact.bind(this)
        this.handelOnToggleModalSuccess = this.handelOnToggleModalSuccess.bind(this)
    }


    //Cambiar de pagina
    handleOnSetPage = (page, who) => {
        this.setState({
            [who]: page
        });
    };
    handleOnSetNumPagination = (e) => {
        this.setState({
            sizePerPage: e.target.value
        })
    }
    handleOnSetNumPaginationContacts = (e) => {
        this.setState({
            sizePerPageContacts: e.target.value
        })
    }
    //Tomar los campos que se va a filtrar
    handleOnfilterSelects = (event, key) => {
        var text = event.target.value
        const { searchText } = this.state
        searchText[key] = text;
        this.setState({
            searchText
        })
    }
    handleOnfilterSelectsLock = (event, key) => {
        var text = event.target.value
        const { searchTextLock } = this.state
        searchTextLock[key] = text;
        this.setState({
            searchTextLock
        })
    }
    handleOnfilterSelectsCustomer = (event, key) => {
        var text = event.target.value
        const { searchTextCutomer } = this.state
        searchTextCutomer[key] = text;
        this.setState({
            searchTextCutomer
        })
    }
    //Tomar data cambiada de los inputs y selects
    handelOnChangeData = (val, id) => {
        const { newContactInfo } = this.state
        let validate = true;
        const phones = ["PHONE1", "MOBILE1", "PHONE2", "MOBILE2"];
        if (phones.includes(id)) {
            if (!newContactInfo.hasOwnProperty("COUNTRY") || newContactInfo["COUNTRY"] === "") {
                validate = false
                this.setState({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title={"Elija un país antes de digitar un teléfono"}
                            onConfirm={() => this.hideAlert()}
                            confirmBtnBsStyle="danger"
                            confirmBtnText="Ok"
                            btnSize="md"
                        />
                    )
                });
                // this.notifyModal("danger", "Error", "Elija un país antes de digitar un teléfono");
            }
            else if (val.length > this.getExpectedPhoneDigitsByCountry(newContactInfo["COUNTRY"])) {
                validate = false
                this.setState({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title={`Por favor no incluir prefijo, el máximo de caracteres para el télefono es de ${this.getExpectedPhoneDigitsByCountry(newContactInfo["COUNTRY"])}`}
                            onConfirm={() => this.hideAlert()}
                            confirmBtnBsStyle="danger"
                            confirmBtnText="Ok"
                            btnSize="md"
                        />
                    )
                });
                // this.notifyModal("danger", "Error", `Por favor no incluir prefijo, el máximo de caracteres para el télefono es de ${this.getExpectedPhoneDigitsByCountry(newContactInfo["COUNTRY"])}`);
            }
            else {
                val = val.replace(/[^0-9]/g, '');
            }
        }
        console.log(val)

        if (validate) {
            newContactInfo[id] = val
            this.setState({
                newContactInfo
            })
        }
    }
    handelOnEditChangeData = (val, id) => {
        console.log(id)
        const { editContactInfo, contactInfo } = this.state
        console.log(editContactInfo, contactInfo, editContactInfo["COUNTRY"]);
        let validate = true;
        const phones = ["PHONE1", "MOBILE1", "PHONE2", "MOBILE2"];
        if (phones.includes(id)) {

            if ((editContactInfo.hasOwnProperty("COUNTRY") && editContactInfo["COUNTRY"] === "") ||
                (contactInfo.hasOwnProperty("COUNTRY") && contactInfo["COUNTRY"] === "")) {
                validate = false
                this.setState({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title={"Elija un país antes de digitar un teléfono"}
                            onConfirm={() => this.hideAlert()}
                            confirmBtnBsStyle="danger"
                            confirmBtnText="Ok"
                            btnSize="md"
                        />
                    )
                });
                // this.notifyModal("danger", "Error", "Elija un país antes de digitar un teléfono");
            }
            else if (val.length > this.getExpectedPhoneDigitsByCountry((editContactInfo["COUNTRY"] !== "" && editContactInfo["COUNTRY"] !== undefined) ? editContactInfo["COUNTRY"] : contactInfo["COUNTRY"])) {
                validate = false
                this.setState({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title={`Por favor no incluir prefijo, el máximo de caracteres para el télefono es de ${this.getExpectedPhoneDigitsByCountry((editContactInfo["COUNTRY"] !== "" && editContactInfo["COUNTRY"] !== undefined) ? editContactInfo["COUNTRY"] : contactInfo["COUNTRY"])}`}
                            onConfirm={() => this.hideAlert()}
                            confirmBtnBsStyle="danger"
                            confirmBtnText="Ok"
                            btnSize="md"
                        />
                    )
                });
                // this.notifyModal("danger", "Error", `Por favor no incluir prefijo, el máximo de caracteres para el télefono es de ${this.getExpectedPhoneDigitsByCountry(newContactInfo["COUNTRY"])}`);
            }
            else {
                val = val.replace(/[^0-9]/g, '');
            }
        }
        console.log(val)

        if (validate) {
            editContactInfo[id] = val
            contactInfo[id] = val

            console.log(editContactInfo)
            console.log(contactInfo)
            
            this.setState({
                editContactInfo,
                contactInfo
            })
        }
    }
    //Aplicar filtros a la tabla 
    renderRowsByFilters = (rows) => {
        const { searchText } = this.state
        if (Object.keys(searchText).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(searchText);
            for (const element of filterKeys) {
                const valueFiltered = searchText[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    renderRowsByFiltersCustomer = (rows) => {
        const { searchTextCutomer } = this.state
        if (Object.keys(searchTextCutomer).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(searchTextCutomer);
            for (const element of filterKeys) {
                const valueFiltered = searchTextCutomer[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    renderRowsByFiltersLock = (rows) => {
        const { searchTextLock } = this.state
        if (Object.keys(searchTextLock).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(searchTextLock);
            for (const element of filterKeys) {
                const valueFiltered = searchTextLock[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    //Abrir y cerrar el modal de nuevo contacto
    handleOnOpenModalNewContact = () => {
        const { invalid } = this.state
        const forms = document.querySelectorAll('.formInfoContacts input,select');
        Array.from(forms).forEach((input) => {
            if (input.required == true) {
                input.invalid = ""
                invalid[input.id] = ""
                this.setState({
                    invalid
                })
            }
        });

        this.setState({
            isLock: 1,
            modalNewContact: !this.state.modalNewContact,
            newContactInfo: { FUNCTION: "", DEPARTAMENT: "", PHONE1: "", MOBILE1: "", EXT1: "", PHONE2: "", MOBILE2: "", EXT2: "" }
        });
    }
    handleOnOpenModalNewContactLock = () => {
        const { invalid } = this.state
        const forms = document.querySelectorAll('.formInfoContacts input,select');
        Array.from(forms).forEach((input) => {
            if (input.required == true) {
                input.invalid = ""
                invalid[input.id] = ""
                this.setState({
                    invalid
                })
            }
        });

        this.setState({
            isLock: 0,
            modalNewContact: !this.state.modalNewContact
        });
    }
    //Abrir y cerrar el modal de importar data
    handleOnOpenModalImport = () => {

        this.setState({
            modalImport: !this.state.modalImport
        });
    }
    //Abrir modal de bloqueo de contactos
    handelOnToggleModalReplacement = (event, row, key) => {
        if (event.target.id) {
            this.setState({
                showModalStatus: true,
                rowContact: row,
                isLock: 1
            });
        }
    }
    //Cerrar modal de bloqueo de contactos
    handelOnToggleCloseModalReplacement = () => {
        this.setState({
            showModalStatus: false,
            searchTextLock: {}
        });
    }
    //Abrir y cerrar modal de actualizacion de informacion
    handleOnOpenInformationModal = (row) => {
        const contactInfo = Object.assign(row, {});

        const { invalid } = this.state
        const forms = document.querySelectorAll('.formInfoContacts input,select');
        Array.from(forms).forEach((input) => {
            if (input.required == true) {
                input.invalid = ""
                invalid[input.id] = ""
                this.setState({
                    invalid
                })
            }
        });
        // if (Object.keys(row).length > 0) {

        //     let country = contactInfo["COUNTRY"];
        //     if (country.length > 2) {
        //         country = this.props.listCountry.filter(x => x.label === country)[0].value;
        //     }
        //     const dialingPrefix = getCountryCallingCode(country);
        //     const phones = ["PHONE1", "MOBILE1", "PHONE2", "MOBILE2"]
        //     let validate = true;
        //     for (const phone of phones) {
        //         if (contactInfo[phone] !== "") {
        //             if (contactInfo[phone].includes("+")) {
        //                 contactInfo[phone] = contactInfo[phone].toString().replace("+" + dialingPrefix, "");
        //             }
        //         }
        //     }
        // }


        this.setState({
            modalInfo: !this.state.modalInfo,
            contactInfo
        });
    }
    //Consumir EndPoint de incativar contacto y poner sustituto
    handelOnContactReplacement = (row) => {

        const { rowContact, customerId } = this.state
        this.setState((state) => ({
            loading: true
        }));

        if (row) {
            rowContact["STATUS"] = "X";
            console.log(row);
        }
        this.props.lockContact({
            idContactLock: rowContact["ID_CONTACT_CRM"],
            infoContact: row,
            idCustomer: customerId
        }).then((res) => {
            if (res.payload.status === 200) {
                this.handelOnToggleModalSuccess(res.payload.data.payload.message)
            } else {
                this.notify("danger", "Falló", "No se logro sustituir el contacto.");
            }
            this.setState((state) => ({
                loading: false
            }));
        });
        this.setState({
            showModalStatus: false
        });
        this.hideAlert();

    }
    //Traer los contactos del cliente
    handelOnGetContactsByCustomer = (e, row) => {

        const { previusPage } = this.state

        this.setState((state) => ({
            loading: true
        }));
        this.props.getContacts({
            idCustomer: row["idClient"]
        }).then((res) => {
            console.log(res)
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "", payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            } else {

                this.setState({
                    previusPage: !previusPage,
                    customerId: row["idClient"],
                    customerName: row["name"],
                    isLock: 0
                });
            }
            this.setState((state) => ({
                loading: false
            }));

            if (res.payload.data.payload.response.length === 0) {
                this.notify("danger", "Atencion ", " El cliente no tiene contactos asociados")
            }
        });
    }
    //Devolver a la tabla de clientes
    handleOnPreviusPage = () => {
        const { previusPage } = this.state

        const token = getUserProtected()["IDCOLABC"];
        this.setState((state) => ({
            loading: true
        }));
        this.props.getCustomers({
            idSalesRep: token
        }).then((res) => {
            if (res.payload.status !== 200) {

                this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
            }
            this.setState((state) => ({
                loading: false,
                previusPage: !previusPage,
                customerId: "",
                searchText: {},
                searchTextCutomer: {},
                pageContacts: 1
            }));
        });
    }
    //Consumir EndPoint de confirmar Contacto
    confirmContact = (event, row) => {

        this.setState((state) => ({
            loading: true
        }));
        const { customerId } = this.state
        row["ID_CUSTOMER"] = customerId
        if (event.target.id && row["CONFIRM"] !== "X") {

            row["CONFIRM"] = "X"
            this.props.confirmCheckContact({
                infoContact: row
            }).then((res) => {
                if (res.payload.data.status === 200) {
                    this.notify("success", "", res.payload.data.payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            });
            this.setState((state) => ({
                loading: false
            }));
        } else {
            row["CONFIRM"] = ""
            let json = { idContact: row["ID_CONTACT_CRM"], idCustomer: customerId };
            this.props.removeConfirmContact(JSON.stringify(json)).then((res) => {
                if (res.payload.data.status === 200) {
                    this.notify("success", "", res.payload.data.payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            });
            this.setState((state) => ({
                loading: false
            }));
        }
    }
    //Ocultar moddal de confirmacion
    hideAlert = () => {
        this.setState({
            alert: null
        });
    };
    //Modal de confirmacion de modificacion nuevo contacto
    handelOnToggleModalSaveContact() {

        const { invalid } = this.state
        let cont = 0;
        const forms = document.querySelectorAll('.formInfoContacts input,select');
        Array.from(forms).forEach((input) => {
            if (input.type === "email" && input.required == true) {
                let lastAtPos = input.value.includes('@');
                let lastDotPos = input.value.includes('.');
                if (lastAtPos !== true || lastDotPos !== true) {
                    invalid[input.id] = "invalid";
                    cont++;
                } else {
                    invalid[input.id] = "";
                }

                this.setState({
                    invalid
                })
            } else if (input.required == true && input.value === "") {
                invalid[input.id] = "invalid"
                this.setState({
                    invalid
                })
                cont++;
            } else {
                invalid[input.id] = ""
            }
        });
        if (cont > 0) {
            this.hideAlert();
        } else {
            this.setState({
                alert: (
                    <ReactBSAlert
                        custom
                        showCancel
                        style={{ display: "block", marginTop: "-100px" }}
                        title="¿Está seguro(a) que desea guardar los cambios del contacto?"
                        customIcon={
                            <div
                                className="swal2-icon swal2-question swal2-animate-question-icon"
                                style={{ display: "flex" }}
                            >
                                <span className="swal2-icon-text">?</span>
                            </div>
                        }
                        onConfirm={() => this.handleUpdatedContact()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Sí, guardar"
                        cancelBtnText="No, cancelar"
                        btnSize="md"
                    />
                )
            });
        }
    };
    //Modal de confirmacion de bloquear contacto
    handelOnToggleModalLockContact(row) {
        this.setState({
            alert: (
                <ReactBSAlert
                    custom
                    showCancel
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Está seguro(a) que desea seleccionar ese contacto?"
                    customIcon={
                        <div
                            className="swal2-icon swal2-question swal2-animate-question-icon"
                            style={{ display: "flex" }}
                        >
                            <span className="swal2-icon-text">?</span>
                        </div>
                    }
                    onConfirm={() => this.handelOnContactReplacement(row)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Sí, guardar"
                    cancelBtnText="No, cancelar"
                    btnSize="md"
                />
            )
        });
    };
    //Modal de confirmacion de crear nuevo contacto
    handelOnToggleModalSaveNewContact() {
        console.log(this.state.newContactInfo)
        const { invalid } = this.state
        let cont = 0;
        const forms = document.querySelectorAll('.formInfoContacts input,select');
        Array.from(forms).forEach((input) => {
            if (input.type === "email" && input.required == true) {
                let lastAtPos = input.value.includes('@');
                let lastDotPos = input.value.includes('.');

                if (lastAtPos !== true || lastDotPos !== true) {
                    invalid[input.id] = "invalid";
                    cont++;
                } else {
                    invalid[input.id] = "";
                }

                this.setState({
                    invalid
                })
            } else if (input.required == true && input.value === "") {
                invalid[input.id] = "invalid"
                this.setState({
                    invalid
                })
                cont++;
            } else {
                invalid[input.id] = ""
            }
        });
        if (cont > 0) {
            this.hideAlert();
        } else {
            this.setState({
                alert: (
                    <ReactBSAlert
                        custom
                        showCancel
                        style={{ display: "block", marginTop: "-100px" }}
                        title="¿Está seguro(a) que desea guardar el nuevo contacto?"
                        customIcon={
                            <div
                                className="swal2-icon swal2-question swal2-animate-question-icon"
                                style={{ display: "flex" }}
                            >
                                <span className="swal2-icon-text">?</span>
                            </div>
                        }
                        onConfirm={() => this.handleSaveNewContact()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Sí, guardar"
                        cancelBtnText="No, cancelar"
                        btnSize="md"
                    />
                )
            });
        }
    };
    //Modal de confirmacion 
    handelOnToggleModalSuccess(text) {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={`${text}`}
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize="md"
                />
            )
        });
    };
    //Modal de confirmacion de  sustituto y bloquear el antiguo
    handelOnToggleModalSaveLockNewContact() {
        console.log(this.state.newContactInfo)
        const { invalid } = this.state
        let cont = 0;
        const forms = document.querySelectorAll('.formInfoContacts input,select');
        Array.from(forms).forEach((input) => {
            if (input.type === "email" && input.required == true) {
                let lastAtPos = input.value.includes('@');
                let lastDotPos = input.value.includes('.');

                if (lastAtPos !== true || lastDotPos !== true) {
                    invalid[input.id] = "invalid";
                    cont++;
                } else {
                    invalid[input.id] = "";
                }

                this.setState({
                    invalid
                })
            } else if (input.required == true && input.value === "") {
                invalid[input.id] = "invalid"
                this.setState({
                    invalid
                })
                cont++;
            } else {
                invalid[input.id] = ""
            }
        });
        if (cont > 0) {
            this.hideAlert();
        } else {
            this.setState({
                alert: (
                    <ReactBSAlert
                        custom
                        showCancel
                        style={{ display: "block", marginTop: "-100px" }}
                        title="¿Está seguro(a) que desea guardar el nuevo contacto y utilizarlo como sustituto?"
                        customIcon={
                            <div
                                className="swal2-icon swal2-question swal2-animate-question-icon"
                                style={{ display: "flex" }}
                            >
                                <span className="swal2-icon-text">?</span>
                            </div>
                        }
                        onConfirm={() => this.handleSaveAndLockContact()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Sí, guardar"
                        cancelBtnText="No, cancelar"
                        btnSize="md"
                    />
                )
            });
        }
    };
    //Consumir endPoint de crear contacto nuevo como sustituto y bloquear el antiguo
    handleSaveAndLockContact = () => {
        console.log(this.state.newContactInfo)
        this.setState((state) => ({
            loading: true
        }));
        const { newContactInfo, customerId } = this.state
        newContactInfo["ID_CUSTOMER"] = customerId
        this.props.createContact({
            newContactInfo
        }).then((res) => {
            let idCRM = {};
            if (res.payload.status === 200) {
                idCRM["ID_CONTACT_CRM"] = res.payload.data.payload["idCRM"]
                this.handelOnToggleModalSuccess(res.payload.data.payload.message)
                this.handelOnContactReplacement(idCRM)

            } else {
                this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
            }
            this.handelOnGetAllContactsByCustomer(customerId)
        });
        this.hideAlert();
        this.setState({
            modalNewContact: false,
            showModalStatus: false
        });
    }
    //Consumir endPoint de crear contacto nuevo
    handleSaveNewContact = () => {

        const { newContactInfo, customerId } = this.state
        newContactInfo["ID_CUSTOMER"] = customerId;
        const phones = ["PHONE1", "MOBILE1", "PHONE2", "MOBILE2"]
        let validate = true;
        const countryLength = this.getExpectedPhoneDigitsByCountry(newContactInfo["COUNTRY"]);
        for (const phone of phones) {
            if (newContactInfo[phone].length > 0) {

                console.log(countryLength);
                console.log(newContactInfo[phone].length);
                if (countryLength !== newContactInfo[phone].length) {
                    console.log("Error de teléfono")

                    validate = false;

                    // this.notifyModal("danger", "Error", `El número ${newContactInfo[phone]} del país ${newContactInfo["COUNTRY"]} debe poseer ${countryLength} caracteres, actualmente posee ${newContactInfo[phone].length}, por favor modificar.`);
                }
            }
        }

        this.hideAlert();
        if (!validate) {
            this.setState({
                loading: false,
                alert: (
                    <ReactBSAlert
                        danger
                        style={{ display: "block", marginTop: "-100px" }}
                        title={`Los números de teléfono no coinciden con los del país ${newContactInfo["COUNTRY"]}. Los números deben poseer ${countryLength} caracteres, por favor modificar.`}
                        onConfirm={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                        confirmBtnText="Ok"
                        btnSize="md"
                    />
                )
            });
        }
        else {
            this.setState({
                loading: true
            });
            this.hideAlert();
            this.props.createContact({
                newContactInfo
            }).then((res) => {
                if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                        const { data: { payload } } = res.payload.response;
                        this.notify("warning", "Atención", payload.message);
                    } else {
                        this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                    }
                } else {
                    const { data: { payload } } = res.payload;
                    this.notify("success", "Éxito", payload.message);
                    this.handelOnGetAllContactsByCustomer(customerId)
                    this.setState({
                        newContactInfo,
                        loading: false,
                        modalNewContact: !this.state.modalNewContact
                    })
                }
            });
        }

    }
    //Consumir endPoint de actualizar informacion de contacto
    handleUpdatedContact = () => {
        //el objeto que tiene los verdaderos cambios es editContactInfo
        const { contactInfo, editContactInfo, customerId } = this.state
        let tempArray = JSON.parse(JSON.stringify(contactInfo));
        let phoneArrayTemp = JSON.parse(JSON.stringify(contactInfo));

        Object.keys(tempArray).forEach(function (k) {
            if (tempArray[k] !== editContactInfo[k] && editContactInfo[k] !== undefined) {
                tempArray[k] = editContactInfo[k]
            }
            // else if (k !== "ID_CONTACT_CRM" && k !== "MOBILE1" && k !== "MOBILE2" && k !== "PHONE1" && k !== "PHONE2" && k !== "EXT1" && k !== "EXT2") {
            //     tempArray[k] = "";
            // }

            if (phoneArrayTemp[k] !== editContactInfo[k] && editContactInfo[k] !== undefined) {
                phoneArrayTemp[k] = editContactInfo[k]
            }
        });

        tempArray["ID_CUSTOMER"] = customerId;
        console.log(phoneArrayTemp)

        const phones = ["PHONE1", "MOBILE1", "PHONE2", "MOBILE2"]
        let validate = true;

        //get el country ya sea el nuevo o el viejo
        let country = editContactInfo["COUNTRY"] !== undefined && editContactInfo["COUNTRY"] !== "" ? editContactInfo["COUNTRY"] : contactInfo["COUNTRY"];
        if (country.length > 2) {
            country = this.props.listCountry.filter(x => x.label === country)[0].value;
        }
        tempArray["COUNTRY"] = country;

        //valida si los telefonos ingresados concuerdan
        const countryLength = this.getExpectedPhoneDigitsByCountry(country);
        for (const phone of phones) {
            if (phoneArrayTemp[phone].length > 0) {

                console.log(countryLength);
                console.log(phoneArrayTemp[phone].length);
                if (countryLength !== phoneArrayTemp[phone].length) {
                    console.log("Error de teléfono")

                    validate = false;

                    // this.notifyModal("danger", "Error", `El número ${newContactInfo[phone]} del país ${newContactInfo["COUNTRY"]} debe poseer ${countryLength} caracteres, actualmente posee ${newContactInfo[phone].length}, por favor modificar.`);
                }
                else{
                    editContactInfo["COUNTRY"] = country;
                }
            }
        }

        this.hideAlert();
        if (!validate) {
            this.setState({
                alert: (
                    <ReactBSAlert
                        danger
                        style={{ display: "block", marginTop: "-100px" }}
                        title={`Los números de teléfono no coinciden con los del país ${tempArray["COUNTRY"]}. Los números deben poseer ${countryLength} caracteres, por favor modificar.`}
                        onConfirm={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                        confirmBtnText="Ok"
                        btnSize="md"
                    />
                )
            });
        }
        else {
            this.setState((state) => ({
                loading: true
            }));
            //API
            editContactInfo["ID_CUSTOMER"] = customerId;
            editContactInfo["ID_CONTACT_CRM"] = tempArray["ID_CONTACT_CRM"];
           
            console.log(editContactInfo)
            console.log(tempArray)

            this.props.updateUserContact({
                contactInfo: editContactInfo
            }).then((res) => {
                if (res.payload.status === 200) {
                    this.notify("success", "", res.payload.data.payload.message);
                } else {
                    this.notify("danger", "Falló ", "Error al actualizar el contacto");
                }
                this.setState({
                    loading: false,
                    modalInfo: !this.state.modalInfo,
                    editContactInfo: {}
                });
                this.handelOnGetAllContactsByCustomer(customerId)
            });

            this.hideAlert();
        
        }

    }
    //Consumir endPoint de traer contactos de clientes
    handelOnGetAllContactsByCustomer = (id) => {
        this.setState((state) => ({
            loading: true
        }));
        this.props.getContacts({
            idCustomer: id
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "", payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
               
            }
            this.setState((state) => ({
                loading: false,
            }));
        });
    }
    //Mensajes de notificacion
    notify = (type, title, message) => {

        let options = {
            place: "br",
            message: (
                <div className="alert-text" style={{ zIndex: 10000 }}>
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {title}
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,

        };
        if (Object.keys(this.refs).length)
            if (this.props.history.location.pathname === this.props.location.pathname)
                this.refs.notificationAlert.notificationAlert(options);
    };

   
    //EndPoint consumidos antes de cargar la pagina
    componentWillMount() {

        const token = getUserProtected()["IDCOLABC"];
        const roles = getTeamsUserAccess();
        if (roles.includes("GBM Contact Administrator")) {
            this.setState((state) => ({
                isAdmin: true,
            }));
        }
      
        this.props.getFunctions({
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "", payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            }
        });
        this.props.getDepartaments({
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "", payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            }
        });
        this.props.getCountrys({
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "", payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            }
        });

        this.props.getCustomers({
            idSalesRep: token
        }).then((res) => {
            if (res.payload.status !== 200) {

                this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
            }
            this.setState((state) => ({
                loading: false
            }));
        });
    };
    handleOnFileList = (info) => {
        const { status } = info.file;
        if (status === "removed") {
            const { file: { name, uid, response: { status, payload } } } = info;
            if (status === 200) {
                const { idFile } = payload;
                this.setState((state) => ({
                    files: state.files.filter((row) => row.uid !== uid),
                }));
            } else {
                this.setState((state) => ({
                    files: state.files.filter((row) => row.uid !== uid),
                }));
            }
            message.success(`El archivo ${name} fue quitado exitosamente.`);
        } else {
            const { fileList, file: { status, name } } = info;
            let response = "";
            if (status === 'done') {
                const { file } = info;
                response = file["response"]
                response = response.payload.idFile;
            } else if (status === 'error') {
                message.success(`Ocurrio un error cargando el archivo ${name}.`);
            }
            this.setState({
                idFileRequest: response,
                files: fileList,
            });
        }
    }
    handleOnSendEmail = () => {
        const { idFileRequest } = this.state
        this.props.getFileUpdate({
            id: idFileRequest
        }).then((res) => {
            if (res.payload.status == 200) {
                // const { data: { payload } } = res.payload.response;
                // this.notify("warning", "Atención", payload.message);
                this.handelOnToggleModalSuccess(res.payload.data.payload["message"])
            } else {
                this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
            }
        });

        this.setState({
            modalImport: !this.state.modalImport
        });
    }
    handleOnDownloadExcel = (name) => {
        const { listContactsByCustomer } = this.props;
        const { customerId } = this.state
        this.setState((state) => ({
            loading: true
        }));
        let date = new Date();
        date = moment(date).utc().utcOffset(moment().utcOffset()).format('YYYY-MM-DD');
        name = `${name} ${customerId} ${date}`
        let table = listContactsByCustomer.map((row) => {

            const json = {}
            json['ID de Contacto'] = row["ID_CONTACT_CRM"];
            json['Tratamiento'] = row["TITLE"];
            json['Nombre'] = row["FIRST_NAME"];
            json['Apellido'] = row["LAST_NAME"];
            json['País'] = row["COUNTRY"];
            json['Dirección'] = row["ADDRESS"];
            json['Correo electrónico'] = row["EMAIL"];
            json['Teléfono de Oficina'] = row["PHONE1"];
            json['Teléfono Celular'] = row["MOBILE1"];
            json['Extensión'] = row["EXT1"];
            json['Teléfono de Oficina Secundario'] = row["PHONE2"];
            json['Teléfono Celular Secundario'] = row["MOBILE2"];
            json['Extensión Secundaria'] = row["EXT2"];
            json['Funcion'] = row["FUNCTION"];
            json['Departamento'] = row["DEPARTAMENT"];
            json['Idioma'] = row["LANGUAGE"];
            json['SAM'] = row["SAM"];

            return json;
        });

        this.props.downloadExcel({
            name,
            table
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    this.notify("warning", "Atención", `No se logro descargar la información del modelo seleccionado`);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            } else {
                const url = window.URL.createObjectURL(new Blob([res.payload.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${name}.xlsx`);
                document.body.appendChild(link);
                link.click();
               
            }
            this.setState((state) => ({
                loading: false,
            }));
        });
    }
    handleOnDownloadExcelCustomers = (name) => {
        const { listCustomers } = this.props;
        this.setState((state) => ({
            loading: true
        }));
        const token = getUserProtected();
        let user = token.EMAIL.split("@")[0];
        name = name + " " + user;
        let table = listCustomers.map((row) => {

            const json = {}
            json['ID del Cliente'] = row["idClient"];
            json['Nombre'] = row["name"];
            json['Territorio'] = row["territory"];
            json['País'] = row["countryName"];
            json['Empleado responsable'] = row["countryName"];
            json['Gerente'] = row["manager"];
            json['Estado'] = row["active"];

            return json;
        });

        this.props.downloadExcel({
            name,
            table
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    this.notify("warning", "Atención", `No se logro descargar la información del modelo seleccionado`);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            } else {
                const url = window.URL.createObjectURL(new Blob([res.payload.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${name}.xlsx`);
                document.body.appendChild(link);
                link.click();
              
            }
            this.setState((state) => ({
                loading: false,
            }));
        });
    }

    getExpectedPhoneDigitsByCountry = (country) => {
        // alert(country)
        console.log(country)
        if (country.length > 2) {
            country = this.props.listCountry.filter(x => x.label === country)[0].value;
        }
        console.log(country)

        // Obtener un ejemplo de número telefónico para Costa Rica
        const exampleNumber = getExampleNumber(country, examples);

        if (exampleNumber) {
            const phoneNumberLength = exampleNumber.nationalNumber.length;
            console.log('Longitud esperada del número de teléfono para ' + country + ":", phoneNumberLength);
            return phoneNumberLength;
        } else {
            console.log('No se pudo obtener un ejemplo de número de teléfono para' + country);
            return 0;
        }

    }

    render() {
        const {
            columnsTableCustomer,
            filterListCustomer,
            columnsTableContacts,
            filterListContacts,
            replacementList,
            listCustomers,
            listContactsByCustomer,
            selectLanguage,
            selectTitle,
            listFunctions,
            listCountry,
            filterListCustomerManager,
            listDepartaments
        } = this.props
        const {
            page,
            pageLock,
            pageContacts,
            sizePerPage,
            previusPage,
            modalNewContact,
            alert,
            modalInfo,
            contactInfo,
            showModalStatus,
            loading,
            sizePerPageReplace,
            modalImport,
            isLock,
            invalid,
            files,
            customerId,
            customerName,
            rowContact,
            sizePerPageContacts,
            isAdmin
        } = this.state
        return (
            <div>
                {alert}

                {
                    previusPage ?
                        <>
                            <NotificationAlert ref="notificationAlert" />
                            <AdminHeader name="Clientes" parentName="Lista de clientes" />
                            <Spin size="large" spinning={loading}>
                                <Container className="mt--6" fluid>
                                    <Filters
                                        filter={this.handleOnfilterSelectsCustomer}
                                        filterList={filterListCustomer}
                                        previusPage="false"
                                        title={"Filtros"}
                                        subtitle={"Aplicar filtros a clientes"}
                                        filterListManager={filterListCustomerManager}
                                    />
                                    < CustomersRequest
                                        cols={columnsTableCustomer}
                                        rows={this.renderRowsByFiltersCustomer(listCustomers)}
                                        page={page}
                                        sizePerPage={sizePerPage}
                                        setNumPagination={this.handleOnSetNumPagination}
                                        onSetPage={this.handleOnSetPage}
                                        showModalImport={this.handleOnOpenModalImport}
                                        showContacts={this.handelOnGetContactsByCustomer}
                                        downloadFile={this.handleOnDownloadExcelCustomers} 
                                        isAdmin={isAdmin}
                                        />

                                </Container>
                            </Spin>
                        </>
                        :
                        <>
                            <div className="rna-wrapper">
                                <NotificationAlert ref="notificationAlert" />
                            </div>
                            <AdminHeader name="Contactos" parentName="Lista de contactos" />
                            <Spin size="large" spinning={loading} styles={{ zIndex: "9999 !important" }}>
                                <Container className="mt--6" fluid>
                                    <RequestContactsList
                                        downloadFile={this.handleOnDownloadExcel}
                                        loading={loading}
                                        setNumPagination={this.handleOnSetNumPaginationContacts}
                                        filter={this.handleOnfilterSelects}
                                        functionPreviusPage={this.handleOnPreviusPage}
                                        filterList={filterListContacts}
                                        subtitle={`${customerId} - ${customerName}`}
                                        cols={columnsTableContacts}
                                        rows={this.renderRowsByFilters(listContactsByCustomer)}
                                        page={pageContacts}
                                        invalid={invalid}
                                        sizePerPage={sizePerPageContacts}
                                        onSetPage={this.handleOnSetPage}
                                        modalInfo={modalInfo}
                                        getInfo={this.handleOnOpenInformationModal}
                                        changeStatus={this.handelOnToggleModalReplacement}
                                        dataInformationModal={contactInfo}
                                        handleOnOpenModalNewContact={this.handleOnOpenModalNewContact}
                                        changeData={this.handelOnEditChangeData}
                                        saveUpdate={this.handelOnToggleModalSaveContact}
                                        getConfirm={this.confirmContact}
                                        showModalImport={this.handleOnOpenModalImport}
                                        options={{ LANGUAGE: selectLanguage, TITLE: selectTitle, COUNTRY: listCountry, FUNCTION: listFunctions, DEPARTAMENT: listDepartaments }}
                                        isAdmin={isAdmin}
                                    />
                                </Container>
                            </Spin>
                        </>
                }
                <ModalReplacement
                    idSeleccionado={rowContact}
                    loading={loading}
                    cols={replacementList}
                    rows={this.renderRowsByFiltersLock(listContactsByCustomer)}
                    filter={this.handleOnfilterSelectsLock}
                    handelOnToggleCloseModalReplacement={this.handelOnToggleCloseModalReplacement}
                    modalNewContact={showModalStatus}
                    handleOnOpenModalNewContact={this.handleOnOpenModalNewContactLock}
                    contactReplacement={this.handelOnToggleModalLockContact}
                    page={pageLock}
                    sizePerPage={sizePerPageReplace}
                    onSetPage={this.handleOnSetPage}
                />
                <ModalNewContact
                    loading={loading}
                    invalid={invalid}
                    cols={columnsTableContacts}
                    modalNewContact={modalNewContact}
                    handleOnOpenModalNewContact={this.handleOnOpenModalNewContact}
                    saveCustomer={this.handelOnToggleModalSaveNewContact}
                    changeData={this.handelOnChangeData}
                    options={{ LANGUAGE: selectLanguage, TITLE: selectTitle, COUNTRY: listCountry, FUNCTION: listFunctions, DEPARTAMENT: listDepartaments }}
                    isLock={isLock}
                    saveLockContact={this.handelOnToggleModalSaveLockNewContact}
                    newContactInfo={this.state.newContactInfo}
                />
                <ModalImportExcel
                    handleOnOpenModalImport={this.handleOnOpenModalImport}
                    onFileList={this.handleOnFileList}
                    importData={this.handleOnSendEmail}
                    fileList={files}
                    idCustomer={customerId}
                    showModal={modalImport} />
            </div>
        );
    }
}
CustomersList.defaultProps = {
    filterListCustomerManager: [
        {
            value: "idClient",
            label: "Id de Cliente"
        },
        {
            value: "name",
            label: "Nombre"
        },
        {
            value: "territory",
            label: "Territorio"
        },
        {
            value: "countryName",
            label: "País"
        },
        {
            value: "accountManagerUser",
            label: "Empleado responsable"
        },
        {
            value: "active",
            label: "Estado"
        }
    ],
    filterListCustomer: [
        {
            value: "idClient",
            label: "Id de Cliente"
        },
        {
            value: "name",
            label: "Nombre"
        },
        {
            value: "territory",
            label: "Territorio"
        },
        {
            value: "countryName",
            label: "País"
        }
    ],
    columnsTableCustomer: [
        {
            value: "idClient",
            label: "Id de Cliente"
        },
        {
            value: "name",
            label: "Nombre"
        },
        {
            value: "territory",
            label: "Territorio"
        },
        {
            value: "countryName",
            label: "País"
        },
        {
            value: "accountManagerUser",
            label: "Empleado responsable del cliente"
        },
        {
            value: "manager",
            label: "Gerente"
        },
        {
            value: "active",
            label: "Estado"
        }
    ],
    filterListContacts: [
        {
            value: "ID_CONTACT_CRM",
            label: "Id del Contacto"
        }, {
            value: "FIRST_NAME",
            label: "Nombre"
        }, {
            value: "LAST_NAME",
            label: "Apellido"
        }, {
            value: "COUNTRY",
            label: "País"
        },
        {
            value: "EMAIL",
            label: "Correo electrónico"
        }
    ],
    columnsTableContacts: [
        {
            value: "ID_CONTACT_CRM",
            label: "Id del Contacto",
            type: "text"
        },
        {
            value: "STATUS",
            label: "Deshabilitado/Habilitado",
            type: "Check"
        },
        {
            value: "CONFIRM",
            label: "Sin Aprobación/Aprobado",
            type: "Check"
        },
        {
            value: "TITLE",
            label: "Tratamiento",
            type: "select",
            required: true
        },
        {
            value: "FIRST_NAME",
            label: "Nombre",
            type: "text",
            maxlength: "30",
            required: true
        },
        {
            value: "LAST_NAME",
            label: "Apellido",
            type: "text",
            maxlength: "50",
            required: true
        },
        {
            value: "COUNTRY",
            label: "País",
            type: "select",
            required: true
        },
        {
            value: "ADDRESS",
            label: "Dirección",
            type: "text",
            maxlength: "100",
            required: true
        },
        {
            value: "EMAIL",
            label: "Correo electrónico ",
            type: "email",
            maxlength: "60",
            required: true
        },
        {
            value: "PHONE1",
            label: "Teléfono Oficina",
            type: "number",
            maxlength: "30",
            required: true
        },
        {
            value: "EXT1",
            label: "Ext",
            type: "text",
            maxlength: "6",
            placeholder: "No obligatorio..."
        },
        {
            value: "MOBILE1",
            label: "Teléfono Celular",
            type: "number",
            maxlength: "30",
            placeholder: "No obligatorio..."
        },
        {
            value: "PHONE2",
            label: "Teléfono Oficina secundario",
            type: "number",
            maxlength: "30",
            placeholder: "No obligatorio..."
        },
        {
            value: "EXT2",
            label: "Ext",
            type: "text",
            maxlength: "6",
            placeholder: "No obligatorio..."
        },
        {
            value: "MOBILE2",
            label: "Teléfono Celular secundario",
            type: "number",
            maxlength: "30",
            placeholder: "No obligatorio..."
        },
        {
            value: "FUNCTION",
            label: "Función",
            type: "select"
        },
        {
            value: "DEPARTAMENT",
            label: "Departamento",
            type: "select"
        },
        {
            value: "LANGUAGE",
            label: "Idioma ",
            type: "select",
            required: true
        },
        {
            value: "SAM",
            label: "SAM ",
            type: "text"
        },
        {
            value: "UPDATEBY",
            label: "Editado por",
            type: "text"
        },
        {
            value: "UPDATEDATE",
            label: "Fecha de modificacion",
            type: "text"
        }
    ],
    selectLanguage: [
        {
            value: "",
            label: ""
        },
        {
            value: "ES",
            label: "Español"
        },
        {
            value: "EN",
            label: "Inglés"
        }
    ],
    selectTitle: [
        {
            value: "",
            label: ""
        },
        {
            value: "0001",
            label: "Señora"
        },
        {
            value: "0002",
            label: "Señor"
        }
    ],
    replacementList: [
        {
            value: "ID_CONTACT_CRM",
            label: "Id del Contacto"
        },
        {
            value: "FIRST_NAME",
            label: "Nombre"
        }, {
            value: "LAST_NAME",
            label: "Apellido"
        }, {
            value: "COUNTRY",
            label: "País"
        },
        {
            value: "EMAIL",
            label: "Correo electrónico",
            type: "text"
        },
    ],
    listCustomers: [],
    listContactsByCustomer: [],
    listCountry: [],
    listFunctions: [],
    listDepartaments: [],
    getFileUpdateContact: ""
}

CustomersList.propTypes = {
    listCustomers: PropTypes.array.isRequired,
    listContactsByCustomer: PropTypes.array.isRequired,
    listFunctions: PropTypes.array.isRequired,
    listCountry: PropTypes.array.isRequired,
    listDepartaments: PropTypes.array.isRequired

};
//selecte
const mapStateToProps = state => ({
    listCustomers: getAllCustomers(state),
    listContactsByCustomer: getAllContactsByCustomer(state),
    listFunctions: getAllFunctions(state),
    listCountry: getAllCountrys(state),
    listDepartaments: getAllDepartaments(state),
    updateContact: getContactUpdate(state),
    getFileUpdateContact: getFileUpdateContact(state),
});
//action
export default withRouter(connect(mapStateToProps, {
    getCustomers,
    getContacts,
    getFunctions,
    getDepartaments,
    getCountrys,
    getHistory,
    updateUserContact,
    createContact,
    removeConfirmContact,
    confirmCheckContact,
    lockContact,
    getFileUpdate,
    downloadExcel
})(CustomersList));
