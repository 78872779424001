import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const allOrders = createAction(
  constants.SO_ALL_ORDERS,
  method.get(urls.SO.allOrders)
);

export const ordersByCountry = createAction(
  constants.SO_COUNTRY_ORDERS,
  method.get(urls.SO.ordersByCountry)
);

// export const ordersByUser = createAction(
//   constants.SO_USER_ORDERS,
//   method.get(urls.SO.ordersByUser)
// );

export const ordersByUser = createAction(constants.SO_USER_ORDERS, user =>
  method.get(`${urls.SO.ordersByUser}/${user}`)()
);

// export const orderDetails = createAction(
//   constants.SO_ORDER_DETAILS,
//   method.get(urls.SO.orderDetails, data)
// );

export const orderDetails = createAction(constants.SO_ORDER_DETAILS, id =>
  method.get(`${urls.SO.orderDetails}/${id}`)()
);
