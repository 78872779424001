// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import React, { useState } from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import { Button, Card, CardHeader, CardImg, Col, Row } from "reactstrap";

const { SearchBar } = Search;

/**
 * Componente para renderizar tablas, recibe de parametros las columnas, la data, si acciona alguna alerta
 * el tamaño inicial de la pagina y si la tabla tendra la opcion de seleccionar datos
 * @param {*} { columns, data, alert, sizePerPage, selectRow }
 * @returns
 */
const Table = props => {
  const {
    columns,
    data,
    alert,
    sizePerPage,
    totalSize,
    page,
    selectRow,
    filterFactory,
    defaultSorted,
    hiddenTotal,
    title,
    subtitle,
    expandFeatrue,
    cvsFeatrue,
    showSearch,
    showAddButton,
    buttonAddFunct,
    buttonBack,
    onBack,
    onApply,
    fileName,
    extraTable,
    filterToSend,
    filterToApply,
    extrasToSendList,
    functionBackTable,
    sendToSAP,
    deleteSearch,
    showButtonToSend,
    showButtonToApply,
    showTableToApply,
    total,
    total2,
    totalA
  } = props;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Mostrando del {from} al {to} de {size} resultados
    </span>
  );

  let totalHours = 0;
  const [hoursState, setHoursState] = useState(0);

  if (total || total2) {
    for (const row of data) {
      totalHours += parseInt(row.hoursReported);
    }
  }

  if (totalA) {
    for (const row of data) {
      totalHours += parseInt(row.Horas);
    }
  }

  const pagination = paginationFactory({
    page,
    sizePerPage,
    alwaysShowAllBtns: false,
    showTotal: hiddenTotal ? false : true,
    paginationTotalRenderer: customTotal,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Mostrar{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={e => onSizePerPageChange(e.target.value)}
            >
              <option value={sizePerPage}>{sizePerPage}</option>
              <option value={sizePerPage * 3}>{sizePerPage * 3}</option>
              <option value={sizePerPage * 5}>{sizePerPage * 5}</option>
            </select>
          }{" "}
          filas.
        </label>
      </div>
    )
  });

  const MyExportCSV = props => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <button
        className="btn btn-success"
        color="success"
        type="button"
        size="sm"
        onClick={handleClick}
        style={{
          height: "1.75rem",
          width: "4.8rem",
          padding: "0.2rem",
          fontSize: "0.8rem"
        }}
      >
        <span className="btn-inner--icon mr-1">
          <i className="fas fa-file-excel" />
        </span>
        <span className="btn-inner--text">Exportar</span>
      </button>
    );
  };

  const onChangeSearch = event => {
    if (total) {
      const { searchText } = event;
      var newData = data.filter(function (o) {
        return Object.keys(o).some(function (k) {
          return o[k].toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        })
      });
      let newtotalHours = 0;
      for (const row of newData) {
        newtotalHours += row.hoursReported;
      }
      setHoursState(newtotalHours);
    }
    if (totalA) {
      const { searchText } = event;
      newData = data.filter(function (o) {
        return Object.keys(o).some(function (k) {
          return o[k].toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        })
      });
      let newtotalHours = 0;
      for (const row of newData) {
        newtotalHours += row.Horas;
      }
      setHoursState(newtotalHours);
    }
  }

  return (
    <>
      {alert}
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            <Col>
              {extraTable &&
                showButtonToSend &&
                extrasToSendList === false &&
                data.length > 0 && (
                  <Button
                    // className="btn-icon"
                    color="primary"
                    size="sm"
                    type="button"
                    style={{ float: "right", marginBottom: "6px" }}
                    onClick={() => filterToSend()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="far fa-paper-plane"></i>
                    </span>
                    <span className="btn-inner--text">
                      Registros para sincronizar a SAP
                    </span>
                  </Button>
                )}
              {
                showButtonToApply &&
                <Button
                  // className="btn-icon"
                  color="primary"
                  size="sm"
                  type="button"
                  style={{ float: "right", marginBottom: "6px" }}
                  onClick={filterToApply}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-double"></i>
                  </span>
                  <span className="btn-inner--text">
                    Cartas para Aplicar Medida
                  </span>
                </Button>
              }
              {
                showTableToApply &&
                <div>
                  <Button
                    // className="btn-icon"
                    color="success"
                    size="sm"
                    type="button"
                    style={{ float: "right", marginLeft: "10px" }}
                    onClick={onApply}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-check"></i>
                    </span>
                    <span className="btn-inner--text">
                      Aplicar Medida Seleccionados
                    </span>
                  </Button>{" "}
                  <Button
                    // className="btn-icon"
                    color="danger"
                    size="sm"
                    type="button"
                    style={{ float: "right" }}
                    onClick={onBack}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-arrow-left"></i>
                    </span>
                    <span className="btn-inner--text">Atrás</span>
                  </Button>
                </div>
              }
              {extraTable && extrasToSendList && (
                <div>
                  <Button
                    // className="btn-icon"
                    color="success"
                    size="sm"
                    type="button"
                    style={{ float: "right", marginLeft: "10px" }}
                    onClick={() => sendToSAP()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-check"></i>
                    </span>
                    <span className="btn-inner--text">
                      Enviar a SAP seleccionados
                    </span>
                  </Button>{" "}
                  <Button
                    // className="btn-icon"
                    color="danger"
                    size="sm"
                    type="button"
                    style={{ float: "right" }}
                    onClick={() => functionBackTable()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-arrow-left"></i>
                    </span>
                    <span className="btn-inner--text">Atrás</span>
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </CardHeader>
        <ToolkitProvider
          data={data}
          keyField="id"
          columns={columns}
          search
          exportCSV={{
            onlyExportFiltered: true,
            exportAll: false,
            fileName: fileName ? `${fileName}.csv` : "archivo.csv",
            noAutoBOM: false
          }}
        >
          {props => (
            <div className="py-4 table-responsive">
              {!showSearch && (
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 pb-1"
                >
                  {!deleteSearch && (
                    <label>
                      Buscar:
                      <SearchBar
                        className="form-control-sm"
                        placeholder="Ingrese la palabra clave con la que desea buscar"
                        {...props.searchProps}
                        onChange={onChangeSearch(props.searchProps)}
                      />
                      <Button
                        // className="btn-icon"
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={() => props.searchProps.onSearch("")}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-trash" />
                        </span>
                        <span className="btn-inner--text">Limpiar</span>
                      </Button>
                      {cvsFeatrue && data.length > 0 && (
                        <MyExportCSV {...props.csvProps}></MyExportCSV>
                      )}
                    </label>
                  )}
                </div>
              )}
              {data.length === 0 ? (
                <div style={{ textAlign: "center", marginBottom: "40px" }}>
                  <CardImg
                    style={{
                      width: "10%",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                    alt="..."
                    src={require("assets/img/theme/search.png")}
                  />
                  <h3 className="mb-0">
                    {"No se encontraron registros relacionados"}
                  </h3>
                </div>
              ) : (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  pagination={sizePerPage >= totalSize ? null : pagination}
                  bordered={false}
                  selectRow={selectRow}
                  filter={filterFactory && filterFactory()}
                  defaultSorted={defaultSorted}
                  expandRow={expandFeatrue}
                />
              )}
              {buttonBack && (
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-3 pb-3"
                >
                  <Button
                    color="info"
                    size="sm"
                    type="button"
                    onClick={buttonBack}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-arrow-left"></i>
                    </span>
                    <span className="btn-inner--text">Volver</span>
                  </Button>
                </div>
              )}
              {showAddButton && (
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter  "
                >
                  <Button
                    color="success"
                    size="sm"
                    type="button"
                    onClick={buttonAddFunct}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-plus"></i>
                    </span>
                    <span className="btn-inner--text">Agregar</span>
                  </Button>
                </div>
              )}
            </div>
          )}
        </ToolkitProvider>
        {
          (total || totalA) ?
            <blockquote className="blockquote text-center">
              <p className="mb-0">
                Total de Horas
              </p>
              <footer className="blockquote-footer">
                {hoursState > 0 ? Math.round((hoursState) * 100) / 100 : Math.round((totalHours) * 100) / 100}
              </footer>
            </blockquote>
            : null
        }
        {
          total2 ?
            <blockquote className="blockquote text-center">
              <p className="mb-0">
                Horas Reportadas
              </p>
              <footer className="blockquote-footer">
                {totalHours}
              </footer>
            </blockquote>
            : null
        }
      </Card>
    </>
  );
};

Table.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  sizePerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  selectRow: PropTypes.object,
  filterFactory: PropTypes.func,
  defaultSorted: PropTypes.arrayOf(PropTypes.object).isRequired,
  expandFeatrue: PropTypes.object,
  cvsFeatrue: PropTypes.bool,
  showSearch: PropTypes.bool,
  showAddButton: PropTypes.bool,
  buttonAddFunct: PropTypes.func,
  buttonBack: PropTypes.func,
  extraTable: PropTypes.bool,
  filterToSend: PropTypes.func,
  extrasToSendList: PropTypes.bool,
  functionBackTable: PropTypes.func,
  sendToSAP: PropTypes.func,
  deleteSearch: PropTypes.bool,
  showButtonToSend: PropTypes.bool
};

export default Table;
