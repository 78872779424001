/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Approvers" del boton de Mantenimiento en el cual se crea o modifica un aprobador
    
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    MySwal: funcion para las notificaciones personalizadas
    setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState } from "react";
// reactstrap components
import { Button, Card, CardBody, Col, Row } from "reactstrap";
//customized Component
import MainTable from "components/Freelance/SharedComponents/MainTable.jsx";
//acciones necesarias para bloquear y crear el aprovador
import { blockApprover, newApprover } from "actions/Freelance";
//usedispatch for fetch
import { useDispatch } from "react-redux";

const Approvers = ({
  setLoad,
  Toast,
  dropDownOptions,
  rolArea,
  isAdmin,
  MySwal,
  setRefreshOptions,
}) => {
  //#region Functions

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region State para guardar los filtros seleccionados según cada tabla en FilterMain
  const [search, setSearch] = useState({});
  //#endregion

  //#region State para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);
  //#endregion

  //#region State para recargar la tabla principal cuando se necesite
  const [reloadTable, setRealTable] = useState(false);
  //#endregion

  //#region funcion para buscar los aprovadores
  const handleSearch = () => {
    setPage(1);
    setRealTable((prevState) => !prevState);
  };
  //#endregion

  //#region Funciones para abrir el SwalAlert segun el tipo que se le envia en la misma
  const openCreateModalCoordinador = (e, row, dropDown) => {
    openAlert(e, "crear", row, dropDown);
  };

  const editAccess = (e, row, dropDown) => {
    openAlert(e, "editar", row, dropDown);
  };

  const openAlert = (e, type, row, dropDown) => {
    let selectHtml =
      '<select id="permissions" class="form-control"> <option value=""></option> ';

    dropDown["Permissions"].forEach(function (json) {
      selectHtml =
        selectHtml +
        `<option ${
          type === "editar" ? (row.rol === json["label"] ? "selected" : "") : ""
        }  value="${json["value"]}">${json["label"]}</option>`;
    });
    selectHtml = selectHtml + "</select><br>";

    let selectHtml2 = `<select id="employees" class="form-control" ${
      type === "editar" ? 'disabled="true"' : ""
    }  > <option value=""></option>  `;
    dropDown["employees"].forEach(function (json) {
      selectHtml2 =
        selectHtml2 +
        `<option ${
          type === "editar"
            ? row.UserName === json["label"]
              ? "selected"
              : ""
            : ""
        }  value="${json["value"]}">${json["label"]}</option>`;
    });
    selectHtml2 = selectHtml2 + "</select><br>";

    MySwal.fire({
      title: `${
        type === "crear"
          ? "Crear nuevo acceso a Coordinador"
          : "Modificar acceso a Coordinador "
      } `,
      html:
        `<FormGroup><label for="name">Nombre Completo</label> ` +
        selectHtml2 +
        `<label for="permissions">Permisos</label>` +
        selectHtml +
        `</FormGroup>`,
      confirmButtonText: `${type === "crear" ? "Crear" : "Modificar"} `,
      confirmButtonColor: "#50D050",
      cancelButtonText: `Cancelar`,
      showCancelButton: true,
      preConfirm: () => {
        setLoad(true);
        if (
          document.getElementById("permissions").value === "" ||
          document.getElementById("employees").value === ""
        ) {
          MySwal.showValidationMessage("Los campos son obligatorios");
          setLoad(false);
        } else {
          var info = {
            row: row,
            action: type,
            fk_Permissions: document.getElementById("permissions").value,
            fk_SignID: document.getElementById("employees").value,
          };
          dispatch(newApprover({ info: info })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
              setRealTable((prevCheck) => !prevCheck);
              setRefreshOptions((prevCheck) => !prevCheck);
              Toast.fire({
                title: "Atención",
                html: `Se ${type === "crear" ? "Creo" : "Modifico"} con éxito`,
                type: "success",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.message,
                type: "warning",
              });
            }
            setLoad(false);
          });
        }
      },
    });
  };

  //#endregion

  //#region Funcion para bloquear el acceso del aprovador
  const blockAccess = (e, row) => {

    MySwal.fire({
      title: `Esta seguro que desea ${
        row.active === 1 ? "Bloquear" : "Desbloquear"
      } el acceso al coordinador? `,
      text: "Se puede reversar con ayuda de Application Management",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Si, ${row.active === 1 ? "Bloquear" : "Desbloquear"}`,
      confirmButtonColor: `${row.active === 1 ? "#fa0303" : "#50D050"}`,
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          row: row,
        };
        dispatch(blockApprover({ info })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setRealTable((prevState) => !prevState);
            setRefreshOptions((prevCheck) => !prevCheck);
            Toast.fire({
              title: "Atención",
              html: `Se ${
                row.active === 1 ? "Bloqueo" : "Desbloqueo"
              } con éxito`,
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.message,
              type: "warning",
            });
          }
          setLoad(false);
        });
      }
    });
  };
  //#endregion

  //#region Botones del FilterMain para ejecutar las funciones
  const buttons = [
    {
      color: "primary",
      icon: "fas fa-search",
      title: "Buscar",
      function: handleSearch,
      colWidth: "6",
    },
    {
      color: "secondary",
      icon: "fa fa-plus",
      title: "Crear Acceso",
      function: openCreateModalCoordinador,
      colWidth: "6",
    },
  ];
  //#endregion

  //#region botones de la tabla MainTable
  const actions = [
    {
      color: "secondary",
      icon: "fas fa-edit",
      fun: editAccess,
      add: 0,
      title: "Editar Acceso",
      id: "accessEdit",
    },
    {
      color: "secondary",
      icon: "fas fa-lock",
      fun: blockAccess,
      add: 0,
      title: "Des/Bloquear acceso",
      id: "blockAccess",
    },
  ];
  //#endregion

  //#region columnas seleccionados de la tabla MainTable
  const [selected, setSelected] = useState([
    { value: "UserID", label: "User Id" },
    { value: "user", label: "Usuario", disabled: true },
    { value: "UserName", label: "Nombre" },
    { value: "country", label: "País" },
    { value: "rol", label: "Rol" },
    { value: "createdAt", label: "Fecha", type: "date" },
    { value: "createdBy", label: "Creado por" },
    {
      value: "activeName",
      label: "Estado",
      type: "color",
      optionList: "activeStatus",
      option: "activeType",
      val: "active",
    },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ]);
  //#endregion

  //#region columnas de la tabla MainTable
  const optionsSelect = [
    { value: "UserID", label: "User Id" },
    { value: "user", label: "Usuario", disabled: true },
    { value: "UserName", label: "Nombre" },
    { value: "country", label: "País" },
    { value: "rol", label: "Rol" },
    { value: "createdAt", label: "Fecha", type: "date" },
    { value: "createdBy", label: "Creado por" },
    {
      value: "activeName",
      label: "Estado",
      type: "color",
      optionList: "activeStatus",
      option: "activeType",
      val: "active",
    },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ];
  //#endregion

  //#endregion

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            {buttons.map((row, key) => (
              <Col sm={row.colWidth} key={key}>
                <Button
                  className="btn-icon"
                  color={row.color}
                  size="sm"
                  block
                  type="button"
                  onClick={(e) => row.function(e, undefined, dropDownOptions)}
                >
                  <span className="btn-inner--icon mr-">
                    <i className={row.icon} />
                  </span>
                  <span className="btn-inner--text">{row.title}</span>
                </Button>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      <MainTable
        setPage={setPage}
        page={page}
        reloadTable={reloadTable}
        search={search}
        Toast={Toast}
        dropDownOptions={dropDownOptions}
        selected={selected}
        setSelected={setSelected}
        optionsSelect={optionsSelect}
        tableParameter={"app"}
        rolArea={rolArea}
        statusError={"NA"}
        isAdmin={isAdmin}
      />
    </>
  );
};

export default Approvers;
