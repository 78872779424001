// core actions
import {
  findTargetLetterByUser,
  updateTargetLetterFlow,
} from "actions/targetLetter.jsx";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// core components TargetLetter
import CardTargetLetter from "components/TargetLetter/CardTargetLetter.jsx";
import WorkFlowModal from "components/TargetLetter/WorkFlowModal.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getTargetLetterUser } from "selectors/targetLetter.jsx";
class TargetLetterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: true,
      showModal: false,
    };
  }

  componentWillMount() {
    this.props.findTargetLetterByUser().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({
        loading: false,
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  _toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  _handleOnOpenFlow = () => {
    this.setState({
      showModal: true,
    });
  };

  _handleOnWorkFlow = (values) => {
    const {
      targetLetter: { id },
    } = this.props;
    const { action, comments } = values;
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props
      .updateTargetLetterFlow(id, {
        action,
        desicion: "USER",
        comments,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState((state) => ({
          loading: !state.loading,
          showModal: false,
        }));
      });
  };

  handleOnBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, showModal } = this.state;

    const { name, parentName, targetLetter } = this.props;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <WorkFlowModal
          user={true}
          loading={loading}
          showModal={showModal}
          toggleModal={this._toggleModal}
          onWorkFlow={this._handleOnWorkFlow}
        />
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          {Object.keys(targetLetter).length ? (
            <CardTargetLetter
              targetLetter={targetLetter}
              onBack={this.handleOnBack}
              onOpenFlow={this._handleOnOpenFlow}
              isUser
            />
          ) : (
            <Row className="justify-content-center">
              <Col sm="12" md="8">
                <CardEmpty
                  title="No hay cartas pendientes."
                  subtitle="Al día de hoy no tienes cartas de objetivos pendientes, en el momento que exista una nueva, aparecerán en esta ventana."
                />
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

TargetLetterUser.defaultProps = {
  name: "Mi Carta de Objetivos",
  parentName: "Notificaciones",
  targetLetter: {},
};

TargetLetterUser.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  targetLetter: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  targetLetter: getTargetLetterUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findTargetLetterByUser,
    updateTargetLetterFlow,
  })(TargetLetterUser)
);
