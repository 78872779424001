// javascript library dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Form as FormRS, FormGroup, Input, Label, Row } from "reactstrap";

const SignatureResource = props => {

  const {
    signature,
    logs,
    options: {
      days,
      frequencies,
      models
    },
    onBack,
    onRelaunch
  } = props;

  return (
    <Row>
      <Col className="order-xl-2" xl='4'>
        <Card className="bg-gradient-default shadow">
          <CardHeader className="bg-transparent">
            <h4 className="surtitle text-white text-center">Actividades</h4>
          </CardHeader>
          <CardBody>
            <div
              className="timeline timeline-one-side"
              data-timeline-axis-style="dashed"
              data-timeline-content="axis"
            >
              {
                logs.map((row, key) => (
                  <div className="timeline-block" key={key}>
                    <span className={`timeline-step badge-${row.color}`}>
                      <i className={row.icon} />
                    </span>
                    <div className="timeline-content">
                      <div className="d-flex justify-content-between pt-1">
                        <div>
                          <span className="text-muted text-sm text-light font-weight-bold">
                            {row.description}
                          </span>
                        </div>
                        <div>
                          <small className="text-light font-weight-bold">
                            <i className="fas fa-clock mr-1" />{row.createdAt}
                          </small>
                        </div>
                      </div>
                      <h5 className="mt-3 mb-0 text-white">{row.comments}</h5>
                      <div className="mt-3">
                        <Badge color={row.color} pill>
                          {row.action}
                        </Badge>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col className="order-xl-1" xl='8'>
        <Row>
          <Col sm='12' md='6'>
            <Card className="bg-gradient-success border-0">
              <CardBody>
                <Row>
                  <Col className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Estado de la firma
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {signature.state}
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className="ni ni-bell-55" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm='12' md='6'>
            <Card className="bg-gradient-danger border-0">
              <CardBody>
                <Row>
                  <Col className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Fecha de la firma
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {moment(signature.createdAt).format('L')}
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className="ni ni-time-alarm" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <div className="text-muted text-center mt-2 mb-2">
                  <h6 className="surtitle">{signature ? signature.user : ''}</h6>
                  <h5 className="h3 mb-0">{signature ? signature.description : ''}</h5>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <Form
                  onSubmit={onBack}
                  initialValues={signature}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <FormRS role="form">
                      <Row>
                        <Col xs="12" md={values.frequency === 1 ? "4" : "6"}>
                          <FormGroup className="mb-1">
                            <Label className="form-control-label" for="frequency">
                              Frecuencia del Home Office
                            </Label>
                            <Field name="frequency">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="select"
                                    disabled
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      frequencies.map((row, key) => (
                                        <option key={key} value={row.id}>{row.name}</option>
                                      ))
                                    }
                                  </Input>
                                  {
                                    meta.error && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        {
                          values.frequency === 1 &&
                          <Col xs="12" md="4">
                            <FormGroup className="mb-1">
                              <Label className="form-control-label" for="totalDays">
                                Cantidad de días de Home Office
                              </Label>
                              <Field name="totalDays">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="number"
                                      max={5}
                                      min={1}
                                      disabled
                                      invalid={meta.error && meta.touched}
                                    />
                                    {
                                      meta.error && <span className="invalid-feedback">{meta.error}</span>
                                    }
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        }
                        <Col xs="12" md={values.frequency === 1 ? "4" : "6"}>
                          <FormGroup className="mb-1">
                            <Label className="form-control-label" for="model">
                              Modelo de Home Office
                            </Label>
                            <Field name="model">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="select"
                                    disabled
                                    invalid={meta.error && meta.touched}
                                    value={parseInt(values.frequency) > 1 ? 6 : values.model}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      models.map((row, key) => (
                                        <option key={key} value={row.id}>{row.name}</option>
                                      ))
                                    }
                                  </Input>
                                  {
                                    meta.error && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                      {
                        values.modelDetail &&
                        <FormGroup className="mb-1">
                          <Label className="form-control-label" for="modelDetail">
                            Detalle del Modelo
                          </Label>
                          <Field name="modelDetail">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  maxLength={500}
                                  rows="2"
                                  resize="none"
                                  disabled
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      }
                      {
                        values.frequency === 1 &&
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="amountDays"
                          >
                            Días en la modalidad de Home Office
                          </Label>
                          <Row>
                            {
                              days.map((row, key) => (
                                <Col xs="6" md="4" key={key}>
                                  <FormGroup check>
                                    <Field name={row.key} type="checkbox">
                                      {({ input, meta }) => (
                                        <Label>
                                          <Input
                                            {...input}
                                            type="checkbox"
                                            disabled
                                            invalid={meta.error && meta.touched}
                                          />{" "}
                                          {row.name}
                                        </Label>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              ))
                            }
                          </Row>
                        </FormGroup>
                      }
                      <FormGroup className="mb-1">
                        <Label className="form-control-label" for="direction">
                          Domicilio donde realizara el Home Office
                          <p style={{ color: "red" }}>*(Detalle claramente sobre la distribución político-geográfica: Provincia, cantón, distrito, delegación, departamento,
                            estado, municipio, corregimiento, zona, barrio y señas extras según corresponda</p>
                        </Label>
                        <Field name="direction">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="textarea"
                                maxLength={500}
                                rows="3"
                                resize="none"
                                disabled
                                invalid={meta.error && meta.touched}
                              />
                              {
                                meta.error && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <div className="d-flex align-items-center justify-content-center text-center">
                        <Button
                          className="my-4"
                          color="danger"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-arrow-left" />
                          </span>
                          <span className="btn-inner--text">Regresar</span>
                        </Button>
                        {
                          (signature.status === 1 || signature.status === 2) &&
                          <Button
                            className="my-4"
                            color="success"
                            type="button"
                            onClick={onRelaunch}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-external-link-alt" />
                            </span>
                            <span className="btn-inner--text">Relanzar</span>
                          </Button>
                        }
                      </div>
                    </FormRS>
                  )}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SignatureResource.propTypes = {
  signature: PropTypes.object.isRequired,
};

export default SignatureResource;