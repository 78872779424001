// core actions
import { findAllYearSignatures, findDataByDashboard, findDataDashboardByYear } from 'actions/digitalSignature.jsx';
// antd components
import { Spin } from 'antd';
// core components
import Charts from "components/DigitalSignature/Charts.jsx";
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import Table from "components/Shared/Table/Table.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from "reactstrap";
// core selectors
import { getAllCountries, getAllDepartaments, getAllDocuments, getAllSignatures, getAllSignaturesByCountry, getAllSignaturesMan, getResponseRateSginatures, getYearsSignatures } from 'selectors/digitalSignature.jsx';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      yearSelected: 0,
      page: 1,
      sizePerPage: 25,
      departamentSelected: {
        value: 0,
        label: "Todos"
      },
      documentSelected: {
        value: 0,
        label: "Todos"
      },
      countrySelected: {
        value: 0,
        label: "Todos"
      }
    };
  }

  componentWillMount() {
    this.props.findAllYearSignatures().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
    });
    this.props.findDataByDashboard().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState((state) => ({
        loading: !state.loading
      }));
    });
  }

  /**
  * Funcion para el manejo de notificaciones parametrizadas
  * @memberof OptionalsPositions
  */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  _handleOnChangeCountry = (who, year) => {
    this.setState((state) => ({
      loading: !state.loading
    }));
    this.props.findDataDashboardByYear({ date: year.value === 0 ? 0 : year.label }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState((state) => ({
        yearSelected: year.value,
        loading: !state.loading
      }));
    });
  };

  _handleOnChangeFilters = (who, value) => {
    this.setState({
      [who]: value
    });
  };

  _handleRenderDataByFilters = (data) => {
    const { departamentSelected, documentSelected, countrySelected } = this.state;
    if (departamentSelected.value === 0 && documentSelected.value === 0 && countrySelected.value === 0) {
      return data;
    }
    else {
      if (departamentSelected.value !== 0) {
        data = data.filter((row) => row.organizationalUnit === departamentSelected.label);
      }
      if (documentSelected.value !== 0) {
        data = data.filter((row) => row.documents === documentSelected.label);
      }
      if (countrySelected.value !== 0) {
        data = data.filter((row) => row.country === countrySelected.label);
      }
      return data;
    }
  };

  _handleOnClearFilters = () => {
    this.setState({
      departamentSelected: {
        value: 0,
        label: "Todos"
      },
      documentSelected: {
        value: 0,
        label: "Todos"
      },
      countrySelected: {
        value: 0,
        label: "Todos"
      }
    });
  };

  expandRows = {
    parentClassName: '#2dce89',
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Correo Electrónico</span> <br />
              {row.email}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Documento</span> <br />
              {row.documents}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Posición</span> <br />
              {row.position}
            </div>
          </div>
        </span>
      </div>
    ),
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  }

  render() {

    const {
      name,
      parentName,
      cols,
      countrys,
      years,
      signaturesByCountrys,
      responseRate,
      allSignatures,
      allSignaturesMan,
      departamentsOptions,
      documentsOptions,
      countriesOptions
    } = this.props;

    const {
      loading,
      yearSelected,
      page,
      sizePerPage,
      departamentSelected,
      documentSelected,
      countrySelected,
    } = this.state;

    const defaultSorted = [
      {
        dataField: "createdAt",
        order: "desc"
      }
    ];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name={name}
          parentName={parentName}
          optionalInput
          countries={countrys}
          yearsStatus={years}
          yearSelected={years.length ? years[yearSelected].label : ""}
          selectionChange={this._handleOnChangeCountry}
        />
        <Spin size="large" spinning={loading}>
          <Container className="mt--6" fluid>
            <Row>
              <Col xs="12" md="7">
                <Charts
                  bar
                  title="Firmas"
                  subtitle="Total de firmas por país"
                  object={signaturesByCountrys}
                />
              </Col>
              <Col xs="12" md="5">
                <Charts
                  pie
                  title="Response Rate"
                  subtitle="Tasa de % del total de firmas"
                  object={responseRate}
                />
              </Col>
            </Row>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col sm="12" md="8">
                    <h3 className="mb-0" >Filtros</h3>
                    <p className="text-sm mb-0">Aplicación de filtros a la información que se visualiza en las tablas de firmas automáticas y manuales.</p>
                  </Col>
                  <Col className="text-right" xs="12" md="4">
                    <Button
                      className="btn-icon"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={this._handleOnClearFilters}
                    >
                      <span className="btn-inner--icon mr-">
                        <i className="fas fa-trash" />
                      </span>
                      <span className="btn-inner--text">
                        Limpiar
                      </span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="text-right" xs="12" md={countriesOptions.length > 2 ? "4" : '6'}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Departamento
                      </label>
                      <Select
                        className="basic-single"
                        id="example-month-input"
                        type="select"
                        placeholder={"Departamento"}
                        isSearchable={false}
                        options={departamentsOptions}
                        value={departamentSelected}
                        onChange={(e) => this._handleOnChangeFilters('departamentSelected', e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="text-right" xs="12" md={countriesOptions.length > 2 ? "4" : '6'}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Documento
                  </label>
                      <Select
                        className="basic-single"
                        id="example-month-input"
                        type="select"
                        placeholder={"Documento"}
                        isSearchable={false}
                        options={documentsOptions}
                        value={documentSelected}
                        onChange={(e) => this._handleOnChangeFilters('documentSelected', e)}
                      />
                    </FormGroup>
                  </Col>
                  {
                    countriesOptions.length > 2 ?
                      <Col className="text-right" xs="12" md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            País
                          </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Paises"}
                            isSearchable={false}
                            options={countriesOptions}
                            value={countrySelected}
                            onChange={(e) => this._handleOnChangeFilters('countrySelected', e)}
                          />
                        </FormGroup>
                      </Col>
                      : null
                  }
                </Row>
              </CardBody>
            </Card>
            <Row>
              <Col>
                <Table
                  title="Firmas Automáticas"
                  subtitle="Total de firmas automáticas realizadas."
                  columns={cols}
                  data={this._handleRenderDataByFilters(allSignatures)}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={this._handleRenderDataByFilters(allSignatures).length}
                  defaultSorted={defaultSorted}
                  expandFeatrue={this.expandRows}
                  cvsFeatrue
                  fileName="Firmas automaticas"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  title="Firmas Manuales"
                  subtitle="Total de firmas manuales realizadas."
                  columns={cols}
                  data={this._handleRenderDataByFilters(allSignaturesMan)}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={this._handleRenderDataByFilters(allSignaturesMan).length}
                  defaultSorted={defaultSorted}
                  expandFeatrue={this.expandRows}
                  cvsFeatrue
                  fileName="Firmas manuales"
                />
              </Col>
            </Row>
          </Container>
        </Spin>
      </>
    );
  }
}

Dashboard.defaultProps = {
  name: "Historial de Firmas",
  parentName: "Dashboard",
  cols: [
    {
      dataField: "idCollaborator",
      text: "ID Colaborador",
      sort: true
    },
    {
      dataField: "username",
      text: "Nombre de Usuario",
      sort: true
    },
    {
      dataField: "fullname",
      text: "Nombre Completo",
      sort: true
    },
    {
      dataField: "email",
      text: "Correo Electrónico",
      sort: true,
      hidden: true
    },
    {
      dataField: "country",
      text: "País",
      sort: true,
    },
    {
      dataField: "organizationalUnit",
      text: "Departamento",
      sort: true
    },
    {
      dataField: "position",
      text: "Posición",
      sort: true,
      hidden: true
    },
    {
      dataField: "documents",
      text: "Documento",
      sort: true,
      hidden: true
    },
    {
      dataField: "createdAt",
      text: "Fecha",
      sort: true
    },
  ],
  countrys: [],
  years: [],
  signaturesByCountrys: {},
  responseRate: {},
  allSignatures: [],
  allSignaturesMan: [],
  departamentsOptions: [],
  documentsOptions: [],
  countriesOptions: []
}

Dashboard.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  countrys: PropTypes.array.isRequired,
  years: PropTypes.array.isRequired,
  signaturesByCountrys: PropTypes.object.isRequired,
  responseRate: PropTypes.object.isRequired,
  allSignatures: PropTypes.array.isRequired,
  allSignaturesMan: PropTypes.array.isRequired,
  departamentsOptions: PropTypes.array.isRequired,
  documentsOptions: PropTypes.array.isRequired,
  countriesOptions: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  years: getYearsSignatures(state),
  signaturesByCountrys: getAllSignaturesByCountry(state),
  responseRate: getResponseRateSginatures(state),
  allSignatures: getAllSignatures(state),
  allSignaturesMan: getAllSignaturesMan(state),
  departamentsOptions: getAllDepartaments(state),
  documentsOptions: getAllDocuments(state),
  countriesOptions: getAllCountries(state),
});

export default withRouter(connect(mapStateToProps, {
  findAllYearSignatures,
  findDataByDashboard,
  findDataDashboardByYear
})(Dashboard));
