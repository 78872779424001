/*!

=========================================================
*Databot DrawerInfo
=========================================================

*Es un tipo "modal" para desplegar más información del
proyecto del Databot

=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/


//Librería antd, para desplegar el "modal" de información.
import { Drawer } from 'antd';

//Librería de react.
import React, { useState, useContext } from 'react';

//Librería de reactstrap.
import { Card, CardBody, Col, Row, Button } from "reactstrap";

//Import de componente contexto, para establecer variables globales
import { UserContext } from 'components/Databot/SharedComponents/UserContext';

export const DrawerInfo = () => {

    //#region States Globales
    const {

        showMoreInfoDatabot,
        setShowMoreInfoDatabot,

    } = useContext(UserContext);
    //#endregion


    //Arreglo para desplegar las fotografías de los miembros del equipo del databot.
    const [team, setteam] = useState([
        {
            name: "Diego Meza",
            place: "RPA Technical Leader",
            width: "12",
            email: "dmeza@gbm.net",
            img: "dmeza.png"
        },
        {
            name: "Eduardo Piedra",
            place: "Application Management Analyst",
            width: "6",
            email: "epiedra@gbm.net",
            img: "epiedra.png"

        },
        {
            name: "Sergio Marín",
            place: "Internal Customer Services Analyst",
            width: "6",
            email: "smarin@gbm.net",
            img: "smarin.png"
        }
    ])

    return (
        <>
            <Drawer
                title="Proyecto Databot"
                width={740}
                onClose={() => setShowMoreInfoDatabot(false)}
                visible={showMoreInfoDatabot}
                bodyStyle={{ paddingBottom: 80 }}
            >


                <Card className="card-profile bg-secondary mt-5">
                    <Row className="justify-content-center">
                        <Col className="order-lg-2" lg="3">
                            <div className="card-profile-image">
                                <img
                                    alt="..."
                                    className="rounded-circle border-secondary"
                                    src={require(`assets/img/databot/bot2.png`)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <CardBody
                        className="pt-7 px-5"
                        style={{ backgroundColor: "#e8f5fa" }}
                    >
                        <div className="mb-4">
                            <div className="justify-content-center row">
                                <h2>Databot</h2>
                            </div>
                            
                            <p className="mb-3" align="justify">
                                <font face="Arial">
                                    Bienvenido a nuestro Proyecto RPA (Robot Process Automation), la solución innovadora que automatiza los procesos de GBM para obtener una serie de beneficios significativos. Con nuestro sistema, se reduce los costos al requerir menos trabajo de colaboradores, quienes podrán enfocarse en tareas más productivas y estratégicas. Además, nuestros robots ofrecen una agilidad inigualable en los procesos, una respuesta 24/7, escalabilidad ilimitada y una contingencia de errores eficaz.
                                </font>
                            </p>


                            <p className="mb-3" align="justify">
                                <font face="Arial">
                                    Este proyecto es uno de los principales desarrollos internos de nuestro equipo de MIS, lo que significa que no requerirá una licencia adicional para su implementación. Además, nuestro soporte se brinda de manera local, lo que garantiza una asistencia rápida y eficiente en todo momento.
                                </font>
                            </p>


                            <p className="mb-3" align="justify">
                                <font face="Arial">
                                    ¿Quiere saber más sobre cómo nuestro sistema RPA puede ayudar a su área? Póngase en contacto con nuestro equipo de Databot y estaremos encantados de brindarle toda la información que necesite.
                                </font>
                            </p>
                            <Row>
                                {team.map((person) => (


                                    <Col sm={person.width}>
                                        <img
                                            alt="..."
                                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                            src={require(`assets/img/databot/${person.img}`)}
                                            style={{ width: "60px" }}
                                        />
                                        <div className="pt-2 text-center">
                                            <h5 className="h3 title">
                                                <span className="d-block mb-1">{person.name}</span>
                                                <small className="h4 font-weight-light text-muted">
                                                    {person.place}
                                                </small>
                                            </h5>
                                            <div className="mt-2">
                                                <Button
                                                    href={"mailto:" + person.email}
                                                    className="btn-icon-only rounded-circle"
                                                    color="twitter"
                                                >
                                                    <i className="far fa-envelope" />
                                                </Button>
                                            </div>
                                            <div></div>
                                        </div>
                                    </Col>

                                ))}

                            </Row>

                        </div>
                    </CardBody>
                </Card>

            </Drawer>
        </>
    )
}

