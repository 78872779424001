import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const extras = handleActions(
  {
    [constants.GET_EXTRAS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.extras;
      }
      return [];
    },
    [constants.UPDATE_EXTRAS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const [{ id }] = data.payload.extrasData;
        const newExtra = state.map(dataOld => {
          if (dataOld.id === id) {
            dataOld = data.payload.extrasData[0];
          }
          return dataOld;
        });
        return newExtra;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const updatedExtrasSAP = handleActions(
  {
    [constants.UPDATE_EXTRAS_SAP]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.updateExtrasSAP;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const extrasToSend = handleActions(
  {
    [constants.GET_EXTRAS_TO_SEND]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.extrasToSend;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const userRolesExtra = handleActions(
  {
    [constants.GET_USER_ROLES]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.userRolesExtra;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const yearsExtras = handleActions(
  {
    [constants.GET_YEARS_EXTRAS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.yearsExtras;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const dateExtras = handleActions(
  {
    [constants.GET_DATE_EXTRAS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.dateExtras;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const allYearExtraSumHoursWidget = handleActions(
  {
    [constants.GET_ALL_YEAR_EXTRAS_SUM_HOURS_WIDGET]: (state, action) => {
      const { status, data } = action.payload;
      // console.log(data);
      if (status === 200) {
        return data.payload.hours[0];
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    }
  },
  {}
);

export const countryExtrasSumHoursWidget = handleActions(
  {
    [constants.GET_COUNTRY_YEAR_EXTRAS_SUM_HOURS_WIDGET]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.hours[0];
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    }
  },
  {}
);

export const countryExtrasSumHoursGraph = handleActions(
  {
    [constants.GET_COUNTRY_YEAR_EXTRAS_SUM_HOURS_GRAPH]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.hours;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const allYearExtraSumHoursGraph = handleActions(
  {
    [constants.GET_ALL_YEAR_EXTRAS_SUM_HOURS_GRAPH]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.hours;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const findUserByYear = handleActions(
  {
    [constants.GET_USER_YEAR]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.users;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const findHoursByUser = handleActions(
  {
    [constants.GET_HOUR_USER]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.user;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => []
  },
  []
);

export const findMonthWidget = handleActions(
  {
    [constants.GET_MONTH_WIDGET]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.hours[0];
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    }
  },
  {}
);

export const findMonthWidgetCountry = handleActions(
  {
    [constants.GET_MONTH_WIDGET_COUNTRY]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.hours[0];
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    }
  },
  {}
);

export const usersWithAccessExtraHours = handleActions({
  [constants.GET_USERS_WITH_ACCESS_EXTRA_HOURS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.DEACTIVATE_USER_ROL_EXTRA_HOURS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { id } = data.payload;
      const { users, message } = state;
      return {
        message,
        users: users.filter((row) => row.id !== parseInt(id))
      }
    }
    return state;
  },
  [constants.CREATE_USER_ROL_EXTRAS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { user } = data.payload;
      const { users, message } = state;
      return {
        message,
        users: [...user, ...users]
      }
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} }
}, {});

export const extraHourCeo = handleActions(
  {
    [constants.GET_EXTRA_HOURS_BY_CEO]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {}; }
  },
  {}
);

export const extraHoursHC = handleActions({
  [constants.GET_USERS_WITH_ACCESS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});