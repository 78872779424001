/*!

=========================================================
*ManageForms
=========================================================

*Componente para los ingreso, retorno y cancelación de los productos.
*Props:
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React, { useState, useEffect, useContext } from "react";
// reactstrap components
import {
    CardHeader,
    CardBody,
    Button,
    Card,
    Row,
    Col,
} from "reactstrap";

import { Button as ButtonGeist } from "@geist-ui/react";

import { UserContext } from './SharedComponents/UserContext';


// core antd
import { Tabs } from 'antd';
import { Inbound } from "./Inbound/Inbound";
import { Outbound } from "./Outbound/Outbound";
import { TasksSpare } from "./TasksSpare/TasksSpare";
import { WarehousesMovements } from "./WarehousesMovements/WarehousesMovements";

const { TabPane } = Tabs;



export const ManageForms = ({ handleOnBack, propsCard }) => {
    const idCard = propsCard.id
    const { title, subtitle } = propsCard;

    //#region States globales
    const {
        isMobile,
        isComputer,
    } = useContext(UserContext);
    //#endregion

    //Butones superiores.
    const [buttons, setButtons] = useState(
        {
            inbound: [

                {
                    id: "po",
                    color: "success",
                    icon: "fa fa-arrow-down",
                    label: "Ingreso PO",
                    width: "4",
                    setPlant: true,
                    modal: {
                        title: "Seleccionar PO",
                        subtitle: "Extraer las Purchase Order disponibles.",
                        spinnerText: "Extrayendo POs...",
                        searchText: "Buscar purchase order...",
                    },
                    component: Inbound
                },
                {
                    id: "return",
                    color: "success",
                    icon: "fa fa-undo",
                    label: "Retorno",
                    width: "4",
                    modal: {
                        title: "Seleccionar OT",
                        subtitle: "Extraer las cancelaciones disponibles.",
                        spinnerText: "Extrayendo OTs...",
                        searchText: "Buscar order transfer...",
                    },
                    component: Inbound


                },
                {
                    id: "cancelation",
                    color: "success",
                    icon: "fa fa-times",
                    label: "Cancelación",
                    width: "4",
                    modal: {
                        title: "Seleccionar OT",
                        subtitle: "Extraer las cancelaciones disponibles.",
                        spinnerText: "Extrayendo OTs...",
                        searchText: "Buscar order transfer...",
                    },
                    component: Inbound

                },
            ],
            outbound: [
                {
                    id: "reservationOutboundAssets",
                    color: "success",
                    icon: "fa fa-arrow-down",
                    label: "Salida por reserva - activos",
                    width: "4",
                    setPlant: true,
                    modal: {
                        title: "Seleccionar Reserva",
                        subtitle: "Extraer las reserva disponibles.",
                        spinnerText: "Extrayendo reservas...",
                        searchText: "Buscar reserva...",
                    },
                    strictedMaterialValidation: true,
                    notValidateQuantity: true,
                    component: Outbound
                },
                {
                    id: "reservationOutboundOthers",
                    color: "success",
                    icon: "fa fa-arrow-down",
                    label: "Salida por reserva - Otras",
                    width: "4",
                    setPlant: true,
                    modal: {
                        title: "Seleccionar Reserva",
                        subtitle: "Extraer las reserva disponibles.",
                        spinnerText: "Extrayendo reservas...",
                        searchText: "Buscar reserva...",
                    },
                    strictedMaterialValidation: true,
                    notValidateQuantity: true,
                    component: Outbound

                },
                {
                    id: "deliveryOutbound",
                    color: "success",
                    icon: "fa fa-truck",
                    label: "Salida por entrega",
                    width: "4",
                    setPlant: true,
                    modal: {
                        title: "Seleccionar Entrega",
                        subtitle: "Extraer las entregas disponibles.",
                        spinnerText: "Extrayendo entregas...",
                        searchText: "Buscar entrega...",
                    },

                    strictedMaterialValidation: true,
                    notValidateQuantity: true,
                    component: Outbound

                },
                {
                    id: "cancelationOutbound",
                    color: "success",
                    icon: "fa fa-times",
                    label: "Cancelación",
                    width: "4",
                    modal: {
                        title: "Seleccionar OT",
                        subtitle: "Extraer las cancelaciones disponibles.",
                        spinnerText: "Extrayendo OTs...",
                        searchText: "Buscar order transfer...",
                    },
                    component: Outbound

                },
                {
                    id: "returnOutbound",
                    color: "success",
                    icon: "fa fa-undo",
                    label: "Retorno",
                    width: "4",
                    modal: {
                        title: "Seleccionar OT",
                        subtitle: "Extraer las cancelaciones disponibles.",
                        spinnerText: "Extrayendo OTs...",
                        searchText: "Buscar order transfer...",
                    },
                    component: Outbound


                },

            ],
            tasks: [
                {
                    id: "otTasks",
                    color: "success",
                    icon: "ni ni-check-bold",
                    label: "OT",
                    width: "4",
                    modal: {
                        title: "Seleccionar OT",
                        subtitle: "Extraer los movimientos",
                        spinnerText: "Extrayendo OTs...",
                        searchText: "Buscar order transfer...",
                    },
                    notValidateQuantity: true,
                    component: TasksSpare
                },
            ],
            warehousesMovements: [
                {
                    id: "lm05Movement",
                    color: "success",
                    icon: "ni ni-check-bold",
                    label: "LM05",
                    width: "4",
                    modal: {
                        title: "Seleccionar OT",
                        subtitle: "Extraer los movimientos",
                        spinnerText: "Extrayendo OTs...",
                        searchText: "Buscar order transfer...",
                    },
                    component: WarehousesMovements
                },
            ]

        }

    )

    //Si es PC que al arrancar ponga la pantalla en 1.
    useEffect(() => {
        if (isComputer || onlyHaveOneButton()) {
            handleChangePane("0")
        }
    }, [])

    //Component selected.
    const [selectedComponent, setSelectedComponent] = useState(
        null
    )

    //Función para abrir un componente de un button.
    const handleOpenComponent = (component) => {
        setStage(1)
        setSelectedComponent(component)
    };

    //Función para volver a los botones o cards.
    const handleLocalOnBack = () => {
        if (setSelectedComponent && stage > 1) {
            setStage(stage - 1)
        } else if (selectedComponent == null || isComputer || onlyHaveOneButton()) {
            handleOnBack()
        } else {
            setSelectedComponent(null)
        }
    }

    //En base al nombre del pane, asigna el componente. 
    const handleChangePane = (key) => {
        setSelectedComponent(null);
        let component = buttons[idCard].filter((button, keyB) => keyB == key)[0];
        setSelectedComponent(component)
        setStage(1)

    }

    //Etapas diseñados para los componentes.
    const [stage, setStage] = useState(1)

    //Función para saber si sólo hay un botón disponible.
    const onlyHaveOneButton = () => {
        return buttons[idCard].length == 1
    }


    return (
        <>
            <Card className="bg-gradient ">


                {/* <CardHeader className={"d-flex justify-content-between align-items-center " + (isMobile && "pt-2 pb-2")}> */}
                <CardHeader>

                    <Row>
                        <Col xs="4" sm="1">
                            <Button color="secondary" size="sm" outline onClick={() => handleLocalOnBack()}>
                                <span className="btn-inner--icon mr-1">
                                    <i className={"fas fa-arrow-left"} />
                                </span>
                                {(selectedComponent && stage > 1) || (isMobile && selectedComponent) ? "Volver" : "Inicio"}
                            </Button>
                        </Col>
                        <Col xs="8" sm="11">
                            <div>
                                <h5 className="h3 mb-0">{title}</h5>
                                {!isMobile && <h6 className="surtitle">{subtitle}</h6>}
                            </div>
                        </Col>


                    </Row>

                </CardHeader>

                <CardBody className=" ">

                    {/* --------- Botones sólo para móvil ------------------------*/}
                    {!selectedComponent && isMobile && !onlyHaveOneButton() && /**Si solo tiene un botón entonces que abra la vista de una vez. */
 
                        <Row className=" mt--3">
                            {
                                buttons[idCard].map((button, key) =>
                                    <Col xs={12} key={key + 'c'} className={" d-flex justify-content-center align-items-center  " + (isMobile && "mt-1")}>
                                        <ButtonGeist
                                            key={key + 'b'}
                                            type={button.color}
                                            size="small"
                                            block
                                            style={{ width: isComputer ? "30%" : '100%' }}
                                            onClick={() => handleOpenComponent(button)} className=""
                                        >
                                            <span className="btn-inner--icon mr-2 " >
                                                <i className={button.icon} />
                                            </span>
                                            <span className="btn-inner--text ">
                                                {button.label}
                                            </span>
                                        </ButtonGeist>
                                    </Col>
                                )
                            }
                        </Row>

                    }
                    {/* --------- Fin de botones sólo para móvil ------------------*/}

                    {/* --------- Navtab sólo para PC -----------------------------*/}
                    {!isMobile &&
                        <Row className="  ">

                            <Col xs={12} className=" p-1 mt--4 ">
                                <Tabs defaultActiveKey={""} onChange={(key) => handleChangePane(key)}>
                                    {buttons[idCard].map((button, key) =>
                                        <TabPane
                                            tab={
                                                <span>
                                                    <span className="btn-inner--icon mb-2 mr-2">
                                                        <i className={button.icon} aria-hidden="true" />
                                                    </span>
                                                    {button.label}
                                                </span>
                                            }
                                            key={key}

                                        >

                                        </TabPane>

                                    )}
                                </Tabs>
                            </Col>
                        </Row>
                    }
                    {/* --------- Fin de Navtab sólo para PC ------------------*/}

                    {selectedComponent &&
                        (

                            <selectedComponent.component
                                //#region Props para el card
                                //handleOnBack={handleOnBack}
                                //#endregion
                                //#region Props de otros componentes
                                //#endregion  
                                stage={stage}
                                setStage={setStage}
                                selectedComponent={selectedComponent}
                                mainView={idCard}
                            />

                        )
                    }

                </CardBody>
            </Card>
        </>
    )
}