import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getDatabotOptions = createAction(constants.DB_GET_OPTIONS, method.get(urls.DATABOT.getDatabotOptions));
export const getDatabotAreas = createAction(constants.DB_GET_AREAS, method.get(urls.DATABOT.getDatabotAreas));
export const getBots = createAction(constants.DB_GET_BOTS, method.get(urls.DATABOT.getBots));
export const getMandantes = createAction(constants.DB_GET_MANDANTES, method.get(urls.DATABOT.getMandantes));
export const getBotDetails = createAction(constants.DB_GET_BOT_DETAILS, body => method.post(urls.DATABOT.getBotDetails, body)());
export const getBotLogOrDetailsServerSide = createAction(constants.DB_GET_BOT_LOG_OR_DETAILS_SERVERSIDE, body => method.post(urls.DATABOT.getBotLogOrDetailsServerSide, body)());
export const getGraphsData = createAction(constants.DB_GET_GRAPHS_DATA, body => method.post(urls.DATABOT.getGraphsData, body)());
export const updateRobot = createAction(constants.DB_TURN_BOT, body => method.post(urls.DATABOT.turnBot, body)());
export const activateRobot = createAction(constants.DB_ACTIVATE_BOT, body => method.post(urls.DATABOT.activateBot, body)());
export const updateMand = createAction(constants.DB_TURN_MAND, body => method.post(urls.DATABOT.turnMand, body)());
export const newBot = createAction(constants.DB_NEW_BOT, body => method.post(urls.DATABOT.newBot, body)());
export const getTopSavingBots = createAction(constants.DB_GET_TOP_SAVING_BOTS, body => method.post(urls.DATABOT.getTopSavingBots, body)());
export const deleteBot = createAction(constants.DB_DELETE_BOT, body => method.post(urls.DATABOT.deleteBot, body)());



