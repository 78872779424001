/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Table } from "reactstrap";

const MasterVariables = props => {

  const {
    title,
    subtitle,
    optionSelected,
    options,
    colsAvaibles,
    onChange,
    onBack,
    onEdit,
    onDelete,
    onAddVariable,
  } = props;

  const renderTablesWithMasterOptions = (cols, rows, type) => (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md='7'>
            <h3 className="text-white mb-0">Tabla Maestra</h3>
          </Col>
          <Col className="text-right" xs="12" md='5'>
            <Row>
              <Col xs='12' md="12" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className="btn-round btn-icon"
                  // outline
                  color="info"
                  id="add"
                  onClick={() => onAddVariable(type)}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-folder-plus" />
                  </span>
                  <span className="btn-inner--text">Agregar Nuevo</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Table
        className="align-items-center table-dark table-flush"
        responsive
      >
        <thead className="thead-dark">
          <tr>
            {
              cols.map((row, key) => <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>{row.name}</th>)
            }
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.map((row, key) => (
              <tr key={key}>
                {
                  cols.map((col, key) => (
                    <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                      {row[col.key]}
                    </th>
                  ))
                }
                <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                  <a
                    className="table-action table-action"
                    href="#"
                    onClick={() => onEdit(row, type)}
                  >
                    <i className="fas fa-edit" />
                  </a>

                  <a
                    className="table-action table-action"
                    href="#"
                    onClick={() => onDelete(row, type)}
                  >
                    <i className="fas fa-trash" />
                  </a>
                </th>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Card >
  );

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col sm="12" md="8">
            <h5 className="surtitle">{title}</h5>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
          <Col sm="12" md="4" className="d-flex justify-content-end">
            <Button
              id="add"
              className="btn-round btn-icon"
              color="success"
              onClick={onBack}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Regresar</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="text-right" xs="12" md="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="masterTable"
              >
                Tablas Maestras Disponibles
              </label>
              <Select
                isSearchable
                className="basic-single"
                id="masterTable"
                type="select"
                placeholder="Seleccione la tabla maestra a gestionar"
                options={[
                  { value: 1, key: 'coes', label: "COES", type: 'generic' },
                  { value: 2, key: 'equipments', label: "Equipos", type: 'generic' },
                  { value: 3, key: 'countries', label: "Paises", type: 'generic' },
                  { value: 4, key: 'specialities', label: "Especialidades", type: 'generic' },
                ]}
                value={optionSelected}
                onChange={(e) => onChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              optionSelected.value > 0 &&
              renderTablesWithMasterOptions(colsAvaibles[optionSelected.key], options[optionSelected.key], optionSelected.key)
            }
          </Col>
        </Row>

      </CardBody>
    </Card>
  );
};

MasterVariables.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MasterVariables;