/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la ventana de confirmacion que aparece al dar cancelar a la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React from "react";
import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

const CancellationModal = ({ showModal, toggle, okFunct }) => {
    //FUNCIONES

    const [reason, setReason] = useState("");

    const continueButton = () => {
        toggle(); // Cierra el modal
        okFunct(reason); // Llama a la función que se ejecuta en OK
    };

    //RETURN
    return (
        <>
            <Modal isOpen={showModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirmación acción</ModalHeader>
                <ModalBody>
                    Por favor escriba una razón para la cancelación
                    <Input
                        type="textarea"
                        placeholder="Razón de la cancelación"
                        maxLength={500}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={continueButton}>Continuar</Button>
                    {' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
export default CancellationModal;