/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Input, Row, Table } from "reactstrap";

const MasterDataCard = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    edit,
    editValues,
    onEdit,
    onBack,
    onChange,
    onUpdate,
    onFlowLogs,
  } = props;

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="12" md='9'>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
          <Col xs="12" md='3' className="p-1 d-flex justify-content-end">
            {
              edit &&
              <Button
                block
                color="danger"
                size="sm"
                onClick={onBack}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-times" />
                </span>
                <span className="btn-inner--text">Cancelar</span>
              </Button>
            }
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {
              cols.map((row, key) => (<th key={key}>{row.name}</th>))
            }
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.map((row, key) => (
              <tr key={key}>
                {
                  cols.map((col, key) => (
                    col.key === "updatedAt" ?
                      <th key={key}>
                        {
                          row["updatedAt"] ?
                            <>
                              {moment(row[col.key])
                                .utc()
                                .utcOffset(moment().utcOffset())
                                .format("DD/MM/YYYY H:mm A")}
                              <a
                                className="table-action table-action"
                                href="#"
                                onClick={() => onFlowLogs(row.id)}
                              >
                                <i className="fas fa-info-circle" />
                              </a>
                            </>
                            : 'N/A'
                        }
                      </th>
                      :
                      col.key === "action" ?
                        <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                          {
                            edit && row.id === editValues.id ?
                              <a
                                className="table-action table-action"
                                href="#"
                                onClick={onUpdate}
                              >
                                <i className="fas fa-check-double" />
                              </a>
                              :
                              <a
                                className="table-action table-action"
                                href="#"
                                onClick={() => onEdit(row)}
                              >
                                <i className="fas fa-edit" />
                              </a>
                          }
                        </th>
                        :
                        <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                          {
                            col.key === 'value' ?
                              row.type === 'porcentaje' ?
                                edit && row.id === editValues.id ?
                                  <Input
                                    step='0.01'
                                    id="value"
                                    type="number"
                                    placeholder="Ingrese el valor"
                                    value={editValues.value}
                                    onChange={(e) => onChange(e)}
                                    min={0}
                                  />
                                  :
                                  `${row[col.key].toFixed(2)}%`
                                :
                                edit && row.id === editValues.id ?
                                  <Input
                                    // step='0.01'
                                    id="value"
                                    type="number"
                                    placeholder="Ingrese el valor"
                                    value={editValues.value}
                                    onChange={(e) => onChange(e)}
                                    min={0}
                                  />
                                  :
                                  row[col.key]
                              :
                              row[col.key]
                          }
                        </th>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Card>
  );
};

MasterDataCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default MasterDataCard;
