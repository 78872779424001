/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to show a Table with all assigned items that a user have and select one to create a report relate to it
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import ReactDatetime from "react-datetime";

import { FaLongArrowAltLeft } from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";

function ItemsTable({
  title,
  description,
  page,
  sizePerPage,
  setPage,
  preparePageData,
  columns,
  formatData,
  goBackButton,
  filtersLabels,
  filtersPlaceHolder,
  filtersData,
  setFilter,
  applyFilters,
  setItemsTableDate,
  onClickDateButton,
  dateFieldValue,
  idFilterValue,
  setFixedDateFlag,
  fixedDateFlag,
}) {
  const dataWithFilters = applyFilters(filtersData);

  if (
    dataWithFilters.length == 0 &&
    filtersData.filter1.length == 10 &&
    fixedDateFlag == true
  ) {
    const fixedDate = moment("2017-01-01");
    setItemsTableDate(fixedDate);
  }

  if (filtersData.filter1.length == "" && dataWithFilters.length > 0) {
    setFixedDateFlag(true);
  }

  const { startIndex, endIndex, currentPageData } = preparePageData(
    dataWithFilters,
    page,
    sizePerPage
  );

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col sm="12" md="10">
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{description}</p>
          </Col>
          <Col
            sm="12"
            md="2"
            className="d-flex justify-content-end align-items-center"
          >
            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                height: "2rem",
                fontSize: "0.75rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={goBackButton}
            >
              <span className="btn-inner--text">
                <FaLongArrowAltLeft
                  style={{ color: "white", fontSize: "15px" }}
                />{" "}
                Retroceder
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            <span className="font-weight-bold">Filtrar por:</span>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col xs={12} sm={4} style={{ marginBottom: "1rem" }}>
            <Label
              for="os_item"
              className="font-weight-bold"
              style={{ fontSize: "13px" }}
            >
              {filtersLabels[0]}
            </Label>
            <Input
              type="text"
              id="os_item"
              placeholder={filtersPlaceHolder[0]}
              autoComplete="off"
              bsSize="sm"
              style={{ fontSize: "0.875rem", padding: "0.25rem 0.5rem" }}
              value={idFilterValue}
              onChange={(e) => {
                setFilter("filter1", e.target.value);
              }}
            />
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label
                for="os_item"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {filtersLabels[1]}
              </Label>
              <ReactDatetime
                inputProps={{
                  placeholder: filtersPlaceHolder[1],
                  className: "form-control form-control-sm",
                  style: {
                    fontSize: "0.875rem",
                    padding: "0.25rem 0.5rem",
                  },
                }}
                value={dateFieldValue}
                timeFormat={false}
                onChange={(date) => setItemsTableDate(date)}
              />
            </FormGroup>
          </Col>

          <Col
            sm="12"
            md="2"
            className="d-flex justify-content align-items-center"
          >
            <Button
              className="btn-icon"
              color="info"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                height: "26px",
                fontSize: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={onClickDateButton}
            >
              <span className="btn-inner--text">Quitar filtros</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive striped>
        <thead className="thead-light">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody className="list">
          {currentPageData.length ? (
            currentPageData.map((row, key) => (
              <tr key={key}>
                {columns.map((column, index) => (
                  <td
                    key={index}
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  >
                    {formatData(column.key, row[column.key], row)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={columns.length}>
                <MdInfoOutline className="mr-2" size={25} />
                <div style={{ fontSize: "15px", marginBottom: "10px" }}>
                  No se han encontrado registros en el sistema.
                </div>
                <div style={{ fontSize: "15px" }}>
                  Si no puede encontrar su item asignado, por favor utilice el
                  filtro de "Items asignados desde" para extender su búsqueda.
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {dataWithFilters.length > 0 ? (
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="p-1">
                <span className="pagination mb-0">
                  Mostrando del {startIndex + 1} al{" "}
                  {endIndex > dataWithFilters.length
                    ? dataWithFilters.length
                    : endIndex}{" "}
                  de {dataWithFilters.length} resultados
                </span>
              </Col>
              <Col xs="12" md="6" className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => setPage(page === 1 ? page : page - 1)}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {Array.from({
                    length: Math.ceil(dataWithFilters.length / sizePerPage),
                  }).map((item, index) => (
                    <PaginationItem key={index} active={page === index + 1}>
                      <PaginationLink onClick={() => setPage(index + 1)}>
                        {index + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    className={
                      page === Math.ceil(dataWithFilters.length / sizePerPage)
                        ? "disabled"
                        : ""
                    }
                  >
                    <PaginationLink
                      onClick={() =>
                        setPage(
                          page ===
                            Math.ceil(dataWithFilters.length / sizePerPage)
                            ? page
                            : page + 1
                        )
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      ) : null}
    </Card>
  );
}

ItemsTable.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  preparePageData: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  formatData: PropTypes.func.isRequired,
};

export default ItemsTable;
