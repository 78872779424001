// javascript library for dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
// urls for uploadfiles
import { urlBase } from "api/urls.jsx";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core selectors
import { getTeamsUserAccess } from "selectors/adminLayout.jsx";
// javascript plugin that creates nice dropzones for files
import { Icon, Upload } from "antd";

const { Dragger } = Upload;

const NewCecoPositionForm = (props) => {
  const {
    title,
    subtitle,
    teams,
    onBack,
    onSearch,
    onCancel,
    onUpdate,
    onFileList,
    onRequestView,
    initialValues,
    data,
  } = props;

  const uploadProps = {
    name: "file",
    multiple: true,
    action: `${urlBase}/auth/test`,
    onChange: onFileList,
  };

  /**
   * Funcion para las validaciones de los campos de los formularios
   * recibe como parametro los valores de cada campo del formulario
   * retorna un json con los errores detectados para mostrarlos en el formulario
   * @param {*} values
   * @returns
   */
  const validate = (values) => {
    const errors = {};
    if (Object.keys(data).length) {
      if (!values.user || values.user === "0")
        errors.user = "Debes seleccionar un colaborador";
      if (!values.idPositionName || values.idPositionName === "0")
        errors.idPositionName = "Debes seleccionar una posición";
      if (!values.changeRequestDate)
        errors.changeRequestDate = "Debes seleccionar una fecha.";
      if (
        moment(values.changeRequestDate).format("YYYY-MM-DD") === "Invalid date"
      )
        errors.changeRequestDate = "Debes seleccionar una fecha valida.";
      if (!values.idPositionManager || values.idPositionManager === "0")
        errors.idPositionManager = "Debes seleccionar un gerente a reportar.";
      if (!values.idOrgUnit || values.idOrgUnit === "0")
        errors.idOrgUnit = "Debes seleccionar una unidad organizacional.";
      if (!values.idCecoN || values.idCecoN === "0")
        errors.idCecoN = "Debes seleccionar un centro de costo nuevo.";
      if (!values.idPersonalArea || values.idPersonalArea === "0")
        errors.idPersonalArea = "Debes seleccionar un área personal.";
      if (!values.reasonForChanges)
        errors.reasonForChanges = "Debes indicar los cambios realizados.";
    }
    return errors;
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col sm="12" md="8">
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            {!Object.keys(data).length &&
            teams.find((row) => row === "New Position Admin") ? (
              <Col className="text-right" xs="4">
                <Button color="info" size="sm" onClick={onRequestView}>
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-folder-17" />
                  </span>
                  <span className="btn-inner--text">Ver solicitudes</span>
                </Button>
              </Col>
            ) : null}
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={Object.keys(data).length ? onUpdate : onSearch}
            validate={validate}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, validating }) => (
              <FormRS onSubmit={handleSubmit}>
                <div className="form-row align-items-center">
                  <Col className="mb-3" sm="12" md="4">
                    <FormGroup>
                      <Label for="idPositionUser">Número de posición</Label>
                      <Field name="idPositionUser">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="number"
                              placeholder="Ejemplo: 70009999"
                              valid={
                                !meta.error && meta.modified && meta.touched
                              }
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  {Object.keys(data).length ? (
                    <>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="user">Colaborador</Label>
                          <Field name="user">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Colaborador"
                                  disabled
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un Colaborador
                                  </option>
                                  {data.collaborators.map((row, key) => {
                                    return (
                                      <option key={key} value={row.IdEmployee}>
                                        {row.NameEmployee}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="UserID">Id del colaborador</Label>
                          {/* <Field name="UserID">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Nombre de la posición"
                                  disabled
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una posición
                                  </option>
                                  {data.positions.map((row, key) => {
                                    return (
                                      <option key={key} value={row.UserID}>
                                        {row.id}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field> */}
                          <Input
                            type="text"
                            placeholder="Id del colaborador"
                            readOnly={true}
                            className="form-control"
                            defaultValue={data.response.idColaborador}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idPositionName">
                            Nombre de la posición
                          </Label>
                          <Field name="idPositionName">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Nombre de la posición"
                                  disabled
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una posición
                                  </option>
                                  {data.positions.map((row, key) => {
                                    return (
                                      <option key={key} value={row.idPosition}>
                                        {row.position}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idPositionManager">
                            Gerente a quien reporta
                          </Label>
                          <Field name="idPositionManager">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Gerente a quien reporta"
                                  disabled
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un Gerente a quien reportar
                                  </option>
                                  {data.collaborators.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.PositionEmployee}
                                      >
                                        {row.NameEmployee}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idOrgUnit">Unidad Organizacional</Label>
                          <Field name="idOrgUnit">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Unidad Organizacional"
                                  disabled
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una unidad organizacional
                                  </option>
                                  {data.organizationalUnits.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idOrganizationalUnit}
                                      >
                                        {row.organizationalUnit}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idPersonalArea">Área de Personal</Label>
                          <Field name="idPersonalArea">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Área de Personal"
                                  disabled
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un área de personal
                                  </option>
                                  {data.personalAreas.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idPersonalArea}
                                      >
                                        {row.personalArea}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="changeRequestDate">
                            Fecha que rige el cambio
                          </Label>
                          <span
                            id="changeRequestDate"
                            className="btn-inner--icon mr-1"
                            style={{
                              display: "inlineBlock",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <i className="fas fa-question" />
                          </span>
                          <Field name="changeRequestDate">
                            {({ input, meta }) => (
                              <>
                                <ReactDatetime
                                  {...input}
                                  inputProps={{
                                    placeholder: "Elija una fecha",
                                    className:
                                      !meta.error &&
                                      meta.modified &&
                                      meta.touched
                                        ? "is-valid form-control"
                                        : meta.error && meta.touched
                                        ? "is-invalid form-control"
                                        : "form-control",
                                  }}
                                  timeFormat={false}
                                />
                                {meta.error && meta.touched && (
                                  <span
                                    style={{
                                      marginTop: "0.25rem",
                                      fontSize: "80%",
                                      color: "#fb6340",
                                    }}
                                  >
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="changeRequestDate"
                          >
                            La fecha máxima para recibir el workflow de cambio
                            de CECOS es el 05 de cada mes
                          </UncontrolledTooltip>
                        </FormGroup>
                      </Col>
                    </>
                  ) : null}
                </div>
                {Object.keys(data).length ? (
                  <>
                    <div className="form-row align-items-center">
                    <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idCeco">Centro de Costo Actual</Label>
                          <Field name="idCeco">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Centro de Costo Actual"
                                  disabled
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione el centro de costo actual
                                  </option>
                                  {data.cecos.map((row, key) => {
                                    return (
                                      <option key={key} value={row.idCeco}>
                                        {row.ceco}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idCecoN">Centro de Costo Nuevo</Label>
                          <Field name="idCecoN">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Centro de Costo Nuevo"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un nuevo centro de costo
                                  </option>
                                  {data.cecos.map((row, key) => {
                                    return (
                                      <option key={key} value={row.idCecoN}>
                                        {row.ceco}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="8">
                        <FormGroup>
                          <Label for="reasonForChanges">
                            Comentarios (500)
                          </Label>
                          <Field name="reasonForChanges">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  placeholder="Escriba aquí los comentarios de las asignaciones y reasignaciones de CECOS."
                                  maxLength={500}
                                  rows="3"
                                  resize="none"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="12">
                        <FormGroup>
                          <Label for="files">Adjuntar Archivos</Label>
                          <Dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">
                              Suelte los archivos aquí o haga clic para cargar.
                            </p>
                            <p className="ant-upload-hint">
                              Cargar todos aquellos archivos recolectados como
                              muestra de evidencia de la solicitud que está
                              realizando.
                            </p>
                          </Dragger>
                        </FormGroup>
                      </Col>
                    </div>
                  </>
                ) : null}
                {submitting ? (
                  <Progress animated max="100" value="100" color="info" />
                ) : null}
                {!Object.keys(data).length ? (
                  <div className="form-row align-items-center">
                    <Col className="mb-3" sm="12" md="3">
                      <Button
                        disabled={submitting}
                        className="btn-icon"
                        block
                        color="info"
                        type="button"
                        onClick={onBack}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-arrow-left" />
                        </span>
                        <span className="btn-inner--text">Volver</span>
                      </Button>
                    </Col>
                    <Col className="mb-3" sm="12" md="3">
                      <Button
                        disabled={submitting || validating}
                        className="btn-icon"
                        block
                        color="info"
                        type="submit"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-search" />
                        </span>
                        <span className="btn-inner--text">Buscar</span>
                      </Button>
                    </Col>
                  </div>
                ) : (
                  <div className="form-row align-items-center">
                    <Col className="mb-3" sm="12" md="3">
                      <Button
                        disabled={submitting}
                        className="btn-icon"
                        block
                        color="info"
                        type="button"
                        onClick={onCancel}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-window-close" />
                        </span>
                        <span className="btn-inner--text">Cancelar</span>
                      </Button>
                    </Col>
                    <Col className="mb-3" sm="12" md="3">
                      <Button
                        disabled={validating || submitting}
                        className="btn-icon"
                        block
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-save" />
                        </span>
                        <span className="btn-inner--text">Modificar CECO</span>
                      </Button>
                    </Col>
                  </div>
                )}
              </FormRS>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};

NewCecoPositionForm.defaultProps = {
  data: {},
  initialValues: {},
  teams: [],
};

NewCecoPositionForm.propTypes = {
  data: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onFileList: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

/**
 * Funcion para mapear el state de la aplicacion a los props del componente
 * recibe de parametro el estado de la aplicacion
 * @param {*} state
 */
const mapStateToProps = (state) => ({
  // data: getDataByPositionToUpdate(state),
  // initialValues: getInitialValuesUpdate(state),
  teams: getTeamsUserAccess(),
});

export default withRouter(connect(mapStateToProps, null)(NewCecoPositionForm));
