// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
// react tags
import TagsInput from "react-tagsinput";

const ContractDetail = (props) => {
  const {
    row,
    logs,
    status,
    onBack,
    inProgress,
    newActivity,
    targetStartLogs,
    newTargetStartDate,
  } = props;

  const state = status.find((ele) => ele.id === row.status);

  const numberWithCommas = (x) => {
    if (x >= 0)
      return `$${parseFloat(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    else return null;
  };

  return (
    <>
      <Row>
        <Col sm="12" md="4">
          <Card className="bg-gradient-success border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    Estado del Contrato
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {state.name}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className="ni ni-bell-55" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="4">
          <Card className="bg-gradient-warning border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0 text-white"
                  >
                    Objetivo de Fecha Inicio
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {row.targetStartDate
                      ? moment(row.targetStartDate)
                          .utc()
                          .utcOffset(moment().utcOffset())
                          .format("DD/MM/YYYY")
                      : "N/A"}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    {inProgress ? (
                      <i className="fas fa-edit" />
                    ) : (
                      <i className="fas fa-edit" onClick={newTargetStartDate} />
                    )}
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col sm="12">
                  <div className="d-flex justify-content-center">
                    <Button
                      className="btn-neutral btn-round btn-icon mt-2"
                      color="success"
                      onClick={newTargetStartDate}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-single-copy-04" />
                      </span>
                      <span className="btn-inner--text">Cambiar Fecha</span>
                    </Button>
                  </div>
                </Col>
              </Row> */}
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="4">
          <Card className="bg-gradient-danger border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    Días en On Hold
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {row.daysSinceOnHold}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className="ni ni-time-alarm" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="order-xl-2" md="4">
          <Card className="bg-gradient-default shadow">
            <CardHeader
              className="bg-transparent"
              style={{ borderColor: "darkgray" }}
            >
              <Row className="justify-content-center">
                <Col xs="12" md="9">
                  <h3 className="mb-0 text-white text-center">
                    Flujo de Actividades
                  </h3>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" md="9" className="d-flex justify-content-center">
                  <Button
                    className="btn-neutral btn-round btn-icon mt-2"
                    color="success"
                    onClick={newActivity}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-single-copy-04" />
                    </span>
                    <span className="btn-inner--text">Nuevo Comentario</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div
                className="timeline timeline-one-side"
                data-timeline-axis-style="dashed"
                data-timeline-content="axis"
              >
                {logs.map((row, key) => (
                  <div className="timeline-block" key={key}>
                    <span className={`timeline-step badge-${row.color}`}>
                      <i className={row.icon} />
                    </span>
                    <div className="timeline-content">
                      <small className="text-light font-weight-bold">
                        {moment(row.createdAt)
                          .utc()
                          .utcOffset(moment().utcOffset())
                          .format("LLL")}
                      </small>
                      <h5 className="text-white mt-3 mb-0">{row.commentary}</h5>
                      <div className="mt-3">
                        <Badge color={row.color} pill>
                          {row.status}
                        </Badge>
                        <Badge color={row.color} pill>
                          {row.createdBy}
                        </Badge>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="order-xl-1" md="8">
          <Card className="mb-4">
            <CardHeader>
              <Col xs="12" md="9">
                <h5 className="surtitle">Información del Contrato</h5>
              </Col>
            </CardHeader>
            <CardBody>
              <div className="form-row align-items-center">
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="contractNumber">
                      # Contrato
                    </Label>
                    <Input
                      id="contractNumber"
                      type="number"
                      className="form-control"
                      value={row.contractNumber}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="country">
                      País
                    </Label>
                    <Input
                      id="country"
                      type="text"
                      className="form-control"
                      value={row.country}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="customerName">
                      Cliente
                    </Label>
                    <Input
                      id="customerName"
                      type="text"
                      className="form-control"
                      value={row.customerName}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row align-items-center">
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      for="externalReference"
                    >
                      Refencia Externa
                    </Label>
                    <Input
                      id="externalReference"
                      type="text"
                      className="form-control"
                      value={
                        row.externalReference ? row.externalReference : "N/A"
                      }
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="startDate">
                      Fecha Inicio
                    </Label>
                    <Input
                      id="startDate"
                      type="text"
                      className="form-control"
                      value={moment(row.startDate)
                        .utc()
                        .utcOffset(moment().utcOffset())
                        .format("DD/MM/YYYY")}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="service">
                      Fecha Fin
                    </Label>
                    <Input
                      id="service"
                      type="text"
                      className="form-control"
                      value={moment(row.endDate)
                        .utc()
                        .utcOffset(moment().utcOffset())
                        .format("DD/MM/YYYY")}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row align-items-center">
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="service">
                      Fecha en On Hold
                    </Label>
                    <Input
                      id="service"
                      type="text"
                      className="form-control"
                      value={moment(row.onHoldDate)
                        .utc()
                        .utcOffset(moment().utcOffset())
                        .format("DD/MM/YYYY")}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="netValue">
                      Valor Contrato
                    </Label>
                    <Input
                      id="netValue"
                      type="text"
                      className="form-control"
                      value={numberWithCommas(row.netValue)}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="outsourcing">
                      Outsourcing
                    </Label>
                    <Input
                      id="outsourcing"
                      type="text"
                      className="form-control"
                      value={row.outsourcing === 1 ? "Sí" : "No"}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row align-items-center">
                <FormGroup>
                  <Label className="form-control-label" for="netValue">
                    Servicio
                  </Label>
                  {row.services.length ? (
                    <div>
                      <TagsInput
                        disabled
                        className="bootstrap-tagsinput"
                        // onChange={this.handleTagsinput}
                        tagProps={{ className: "tag badge mr-1" }}
                        value={row.services.map((row) => row.serviceGroup)}
                        inputProps={{
                          className: "",
                          placeholder: "",
                        }}
                      />
                    </div>
                  ) : (
                    <blockquote className="blockquote">
                      <footer className="blockquote-footer">
                        Sin servicios asociados al contrato
                      </footer>
                    </blockquote>
                  )}
                </FormGroup>
              </div>
              <div className="form-row align-items-center">
                <FormGroup>
                  <Label className="form-control-label" for="netValue">
                    Items
                  </Label>
                  {row.items.length ? (
                    <div>
                      <TagsInput
                        disabled
                        className="bootstrap-tagsinput"
                        // onChange={this.handleTagsinput}
                        tagProps={{ className: "tag badge mr-1" }}
                        value={row.items.map((row) => row.name)}
                        inputProps={{
                          className: "",
                          placeholder: "",
                        }}
                      />
                    </div>
                  ) : (
                    <blockquote className="blockquote">
                      <footer className="blockquote-footer">
                        Sin items asociados al contrato
                      </footer>
                    </blockquote>
                  )}
                </FormGroup>
              </div>
              <div className="form-row align-items-center justify-content-center">
                <Col
                  className="mb-3"
                  sm="12"
                  md="3"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignContent: "center",
                  }}
                >
                  <Button
                    className="btn-icon"
                    color="danger"
                    type="button"
                    block
                    onClick={onBack}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-arrow-left" />
                    </span>
                    <span className="btn-inner--text">Salir</span>
                  </Button>
                </Col>
              </div>
            </CardBody>
          </Card>
          {targetStartLogs.length ? (
            <Card className="bg-gradient-default shadow">
              <CardHeader
                className="bg-transparent"
                style={{ borderColor: "darkgray" }}
              >
                <Row className="justify-content-center">
                  <Col xs="12" md="9">
                    <h3 className="mb-0 text-white text-center">
                      Historico cambios Fecha de Inicio
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div
                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  {targetStartLogs.map((row, key) => (
                    <div className="timeline-block" key={key}>
                      <span className="timeline-step badge-success">
                        <i className="fas fa-info-circle" />
                      </span>
                      <div className="timeline-content">
                        <small className="text-light font-weight-bold">
                          {moment(row.createdAt)
                            .utc()
                            .utcOffset(moment().utcOffset())
                            .format("LLL")}
                        </small>
                        <h5 className="text-white mt-3 mb-0">{row.reason}</h5>
                        <div className="mt-3">
                          <Badge color="success" pill>
                            {row.createdBy}
                          </Badge>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

ContractDetail.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContractDetail;
