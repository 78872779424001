// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";

const Filters = (props) => {
  const { title, subtitle, filters, options, values, onClear, onChange } =
    props;

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col sm="12" md="10">
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{subtitle}</p>
          </Col>
          <Col
            xs="12"
            md="2"
            className="p-1"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              block
              type="button"
              onClick={onClear}
            >
              <span className="btn-inner--icon mr-">
                <i className="fas fa-trash" />
              </span>
              <span className="btn-inner--text">Limpiar</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          {filters.map((row, key) => (
            <Col
              key={key}
              className="text-right"
              xs="12"
              md={12 / filters.length}
            >
              <FormGroup>
                <label className="form-control-label" htmlFor={row.key}>
                  {row.name}
                </label>
                <Select
                  isSearchable
                  className="basic-single"
                  id={row.key}
                  type="select"
                  placeholder={row.placeholder}
                  options={options[row.key]}
                  value={values[row.key]}
                  onChange={(e) => onChange(row.key, e)}
                />
              </FormGroup>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

Filters.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Filters;
