export const getRequirementsEngineers = (state) => {
  if (Object.keys(state.requerimentsEngineers).length) {
    return state.requerimentsEngineers.requests;
  }
  return [];
};

export const getCriticalPartsKit = (state) => {
  if (Object.keys(state.criticalPartsKit).length) {
    return state.criticalPartsKit.kit;
  }
  return [];
};

export const getEquipmentsIDPartSelected = (state) => {
  if (
    Object.keys(state.partsEquipment).length &&
    Object.keys(state.equipments).length
  ) {
    const { ibm } = state.equipments;
    const { partsEquipment } = state;
    let partFiltered = partsEquipment.filter((row) =>
      ibm.some((ele) => row.idEquipment === ele.id)
    );
    partFiltered = partFiltered.map((row) => parseInt(row.idEquipment));
    return (
      partFiltered
        .map((e) => e)
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter((e) => partFiltered[e])
        .map((e) => partFiltered[e])
    );
  }
  return [];
};

export const getPartsEquipmentsRequest = (state) => {
  if (Object.keys(state.partsEquipmentsRequest).length) {
    const { selectedParts } = state.partsEquipmentsRequest;
    return selectedParts;
  }
  return [];
};

export const getPendingsPartsEquipmentsRequest = (state) => {
  if (Object.keys(state.pendingPartsEquipmentsRequest).length) {
    const { equipments } = state.pendingPartsEquipmentsRequest;
    return equipments;
  }
  return [];
};

export const getCriticalPartSelected = (state) => {
  if (
    Object.keys(state.partsEquipment).length &&
    Object.keys(state.equipments).length
  ) {
    const { partsEquipment } = state;
    return partsEquipment;
  }
  return [];
};

export const getHistoryPartsEquipmentsRequest = (state) => {
  if (Object.keys(state.historyPartsEquipmentsRequest).length) {
    const { hisortyParts } = state.historyPartsEquipmentsRequest;
    return hisortyParts;
  }
  return [];
};

export const getAllEquipmentsCreated = (state) => {
  if (Object.keys(state.equipments).length) {
    const { allEquipments } = state.equipments;
    return allEquipments;
  }
  return [];
};

export const getAllEquipmentsComplete = (state) => {
  if (Object.keys(state.equipments).length) {
    const { ibmEquipmentsComplete } = state.equipments;
    return ibmEquipmentsComplete;
  }
  return [];
};
