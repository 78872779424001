// react library
import React, { useState, useEffect } from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {
    Button, Col, FormGroup, Input, Row, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Form, Container, InputGroup
} from "reactstrap";
//Token 
import { getUserProtected } from "selectors/adminLayout";
import { useDispatch } from 'react-redux';
import { Upload, Icon } from 'antd';
import { urlBase } from "api/urls.jsx";
import { Spin } from 'antd';
import { Typeahead } from 'react-bootstrap-typeahead'
//actions for endpoint
import { newRequest, getPositionInfo } from 'actions/hcmHiring.jsx'
import Dropdown from 'reactstrap/lib/Dropdown';

const { Dragger } = Upload;

const NewHiringModal = ({
    showModal,
    setModal,
    Toast,
    setRealTable,
    rows,
    isAdmin,
    dropDownOptions
}) => {

    //ejecutar una api
    const dispatch = useDispatch();
    //roles e info del usuario
    const token = getUserProtected();
    //spin
    const [loading, setLoad] = useState(false);
    //estado principal
    const [state, setState] = useState({
        unmountOnClose: true,
        readyToSend: true, //se utiliza para mostar o no el boton de envíar
    });

    const { unmountOnClose, readyToSend } = state;

    //state que guarda la información de la solicitud para hacer el insert
    const [newInfo, setNewInfo] = useState({})

    const { position } = newInfo;

    //funcion para cerrar el modal
    const toggleNewDocumentModal = (() => {

        //limpiar
        setOptions((prevState) => ({
            ...prevState,
            plazaType: {
                value: "",
                label: ""
            },
            country:
            {
                value: "",
                label: ""
            },
            personalAreaXX:
            {
                value: "",
                label: ""
            },
            subArea:
            {
                value: "",
                label: ""
            },
            vacancyType: {
                value: "",
                label: ""
            },
            positionType: {
                value: "",
                label: ""
            }
        }));
        setNewInfo({})
        setPositionInfo({})
        setState((prevState) => ({
            ...prevState,
            readyToSend: true,
        }));

        const forms = document.querySelectorAll(".formInfo input,select");
        Array.from(forms).forEach((input) => {
            if (input.required === true) {
                input.classList.remove("is-invalid");
            }
        });

        //cerrar el modal
        setModal((prevState) => ({
            ...prevState,
            showNewModal: !prevState.showNewModal,
        }));
    });

    //state para extraer las opciones de sub area que depende del país
    const [subAreaOptions, setSubArea] = useState([
        {
            value: "",
            label: ""
        }
    ])

    //funcion para modificar el state principal
    const handleChangeInfo = (constant, e, constantName, f) => {
        console.log(e)
        //el sub área depende del país seleccionado
        if (constant === "country") {
            let subAreaOptions = [];
            for (const element of dropDownOptions.SubArea) {
                if (element["countryCode"] && element["countryCode"].toString().indexOf(e) !== -1 && !subAreaOptions.some((x) => JSON.stringify(x) === JSON.stringify(element))) {
                    subAreaOptions.push(element);
                }
            }
            setSubArea(subAreaOptions);
            dropDownOptions["subAreaOptions"] = subAreaOptions;
        }

        //se actualiza el state de la nueva informacion
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: e,
            [constantName]: f
        }))

    }
    //funcion enviar una nueva solicitud
    const handleSendNewRequest = (e) => {
        // setLoad(true);

        const forms = document.querySelectorAll(".formInfo input,Select");
        console.log(forms);
        let cont = 0;
        Array.from(forms).forEach((input) => {
            console.log(input);
            //
            if (input.value === "") {
                input.classList.add("is-invalid");
                cont++;
            }
            else {
                input.classList.remove("is-invalid");
            }
        });
        if (cont > 0) {
            setLoad(false);
            return;
        }
        const email = newInfo["emailCandidate"];
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!regex.test(email)) {
            const mensaje = "Por favor Ingresar un correo electronico valido";
            document.getElementById("emailCandidate").classList.add("is-invalid");
            setLoad(false);
            Toast.fire({
                title: "Atención",
                html: mensaje,
                type: 'warning'
            });
            return;
        }
        else {
            document.getElementById("emailCandidate").classList.remove("is-invalid");
        }
        const pPosition = newInfo["personalArea"] ? newInfo["personalArea"] : newInfo["country"] + newInfo["personalAreaXX"].toString().substring(2);
        newInfo["personalArea"] = pPosition;
        dispatch(newRequest({ newInfo: newInfo })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {

                setRealTable(prevCheck => !prevCheck);
                toggleNewDocumentModal();

                Toast.fire({
                    title: "Atención",
                    html: "Se creo correctamente",
                    type: 'success'
                });


            } else {
                //limpiar el upload
                setState((prevState) => ({
                    ...prevState,
                    readyToSend: true,
                }));

                Toast.fire({
                    title: "Atención",
                    html: payload.response.data.message,
                    type: 'warning'
                });
            }
            setLoad(false);

        })

    }

    //funcion para buscar la información de una posicion
    const [positionInfo, setPositionInfo] = useState({})

    useEffect(() => {

    }, [])

    const handleGetPositionInfo = () => {
        setLoad(true);
        const { position } = newInfo;

        try {
            dispatch(getPositionInfo(position)).then((resp) => {
                const { payload } = resp;
                console.log(resp)
                if (payload.status === 200) {

                    const pInfo = payload.data.positionInfo;
                    // console.log(pInfo);
                    // setNewInfo((prevState) => ({
                    //     ...prevState,
                    //     pInfo
                    // }))


                    let subAreaOptions = [];
                    for (const element of dropDownOptions.SubArea) {
                        if (element["countryCode"] && element["countryCode"].toString().indexOf(pInfo["country"]) !== -1 && !subAreaOptions.some((x) => JSON.stringify(x) === JSON.stringify(element))) {
                            subAreaOptions.push(element);
                        }
                    }
                    // console.log(subAreaOptions)
                    setSubArea(subAreaOptions);
                    dropDownOptions["subAreaOptions"] = subAreaOptions;


                    //llenar los dom elements
                    setPositionInfo(pInfo)

                    setOptions((prevState) => ({
                        ...prevState,
                        plazaType: {
                            value: pInfo["plazaType"],
                            label: pInfo["eGroupName"]
                        },
                        country:
                        {
                            value: pInfo["country"],
                            label: pInfo["countryName"]
                        },
                        personalAreaXX:
                        {
                            value: pInfo["xxPArea"],
                            label: pInfo["personalAreaName"]
                        },
                        subArea:
                        {
                            value: pInfo["subArea"],
                            label: pInfo["subAreaName"]
                        },
                    }));

                    //llenar el state del botón de enviar
                    Object.assign(newInfo, pInfo);


                    //habilitar el boton de enviar
                    setState((prevState) => ({
                        ...prevState,
                        readyToSend: false,
                    }));

                }
                else{
                    Toast.fire({
                        title: "Atención",
                        html: payload.response.data.message,
                        type: 'warning'
                    });
                }
                setLoad(false);
                setRealTable(prevCheck => !prevCheck);

            })
        }
        catch (error) {

        }

    }

    //campos del formulario

    const [fields, setFields] = useState([
        {
            colWidth: "6",
            elementType: "Input",
            label: "Email del candidato*",
            placeholder: "Ingrese el email del candidato",
            id: "emailCandidate",
            type: "email",
            min: 0,
            max: 1999999999,
            defaultValue: "emailCandidate",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "ComboPosition",
            label: "Número de Posición*",
            placeholder: "Ingrese la Posición y haga clic en Buscador",
            id: "position",
            type: "number",
            min: 0,
            max: 1999999999,
            defaultValue: "position",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: "Título del Puesto:*",
            placeholder: "Ingrese el titulo de la posición",
            id: "jobTitle",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "jobTitle",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "select",
            label: "Tipo de Vacante*",
            placeholder: "Seleccione el tipo de vacante",
            id: "vacancyType",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "vacancyType",
            disabled: false,
            options: "VacancyType",
            value: "VacancyType",
            defaultValueLabel: "vacancyName",
            defaultValueValue: "vacancyType",
        },
        {
            colWidth: "6",
            elementType: "select",
            label: "Tipo de Plaza*",
            placeholder: "Seleccione el tipo de plaza",
            id: "plazaType",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "plazaType",
            disabled: false,
            options: "EmployeeGroup",
            value: "EmployeeGroup",
            defaultValueLabel: "eGroupName",
            defaultValueValue: "plazaType",
        },
        {
            colWidth: "6",
            elementType: "select",
            label: "Tipo de Puesto*",
            placeholder: "Seleccione el tipo de puesto",
            id: "positionType",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "positionType",
            disabled: false,
            options: "EmployeeSubGroup",
            value: "EmployeeSubGroup",
            defaultValueLabel: "eSubGroupName",
            defaultValueValue: "positionType",
        },
        {
            colWidth: "6",
            elementType: "select",
            label: "País*",
            placeholder: "Seleccione el país",
            id: "country",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "country",
            disabled: false,
            options: "CountryApplication",
            value: "CountryApplication",
            defaultValueLabel: "countryName",
            defaultValueValue: "country",
        },
        {
            colWidth: "6",
            elementType: "select",
            label: "Personal Área*",
            placeholder: "Seleccione el área personal",
            id: "personalAreaXX",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "personalAreaXX",
            disabled: false,
            options: "PersonalArea",
            value: "PersonalArea",
            defaultValueLabel: "personalAreaName",
            defaultValueValue: "personalArea",
        },
        {
            colWidth: "6",
            elementType: "select",
            label: "Sub Área*",
            placeholder: "Seleccione el sub área",
            id: "subArea",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "subArea",
            disabled: false,
            options: "subAreaOptions", //las opciones salen hasta que se seleccione el país
            value: "subAreaOptions",
            defaultValueLabel: "subAreaName",
            defaultValueValue: "subArea",
        },
    ])

    //state para modificar los dropdowns
    const [options, setOptions] = useState({
        vacancyType: {
            value: "",
            label: ""
        },
        positionType: {
            value: "",
            label: ""
        }
    });
    console.log(newInfo)
    console.log(newInfo["country"])
    return (
        <Modal size="sm" scrollable isOpen={showModal} unmountOnClose={unmountOnClose} toggle={toggleNewDocumentModal}>
            <ModalHeader>
                <h3>Nueva solicitud PB10</h3>
                <h4>Estimado colaborador, por favor llene el siguiente formulario</h4>
            </ModalHeader>
            <Form noValidate className="formInfo needs-validation">
                <ModalBody
                 style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}
                >
                    <Spin size="large" spinning={loading}>
                        <Form noValidate className="formInfoContacts needs-validation">
                            <Row className="align-items-center">

                                {
                                    // field.colWidth
                                    fields.map((field, key) => (
                                        <Col xs={12} key={key}>
                                            <FormGroup key={key}>
                                                <label className="form-control-label">{field.label}</label>
                                                {

                                                    field.elementType === "ComboPosition" ?
                                                        <InputGroup>
                                                            <Button
                                                                color="primary"
                                                                outline
                                                                onClick={(e) => handleGetPositionInfo(e)}
                                                            >
                                                                <i className="fas fa-search" />
                                                            </Button>
                                                            <Input
                                                                id="position"
                                                                required={true}
                                                                className="form-control"
                                                                type="text"
                                                                maxLength="10"
                                                                placeholder="Ingrese la Posición y haga clic en Buscador"
                                                                onChange={(e) => handleChangeInfo("position", e.target.value)}
                                                            />
                                                            <div className="invalid-feedback">
                                                                Este campo no puede estar vacio
                                                            </div>
                                                        </InputGroup>
                                                        : field.elementType === "Input" ?
                                                            <>
                                                                <Input
                                                                    key={field.id}
                                                                    id={field.id}
                                                                    name={field.id}
                                                                    required={true}
                                                                    className="form-control"
                                                                    type={field.type}
                                                                    min={field.min}
                                                                    max={field.max}
                                                                    readOnly={field.disabled}
                                                                    placeholder={field.placeholder}
                                                                    defaultValue={positionInfo[field.defaultValue]}
                                                                    onChange={(e) => handleChangeInfo(field.id, e.target.value)}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    Este campo no puede estar vacio
                                                                </div>
                                                            </>
                                                            : field.id === "subArea" ?
                                                                <>
                                                                    <Input
                                                                        key={field.id}
                                                                        id={field.id}
                                                                        name={field.id}
                                                                        required={true}
                                                                        className="basic-multi-select text-dark"
                                                                        type={field.type}
                                                                        placeholder={field.placeholder}
                                                                        isSearchable
                                                                        classNamePrefix="select"
                                                                        isDisabled={field.disabled}
                                                                        options={subAreaOptions}
                                                                        value={newInfo[field.defaultValueLabel]}
                                                                        onChange={(e) => handleChangeInfo(field.id, e.target.children[e.target.selectedIndex].id, field.defaultValueLabel, e.target.children[e.target.selectedIndex].value)}
                                                                    >
                                                                        <option id=""></option>
                                                                        {subAreaOptions && subAreaOptions.map((value) => {
                                                                            return (
                                                                                <option id={value.value}>{value.label}</option>
                                                                            );
                                                                        })}
                                                                    </Input>
                                                                    {/* <Select
                                                                        key={field.id}
                                                                        id={field.id}
                                                                        name={field.id}
                                                                        className="basic-single"
                                                                        type={field.type}
                                                                        placeholder={field.placeholder}
                                                                        isSearchable
                                                                        required={true}
                                                                        isDisabled={field.disabled}
                                                                        options={subAreaOptions}
                                                                        value={options[field.id]}
                                                                        onChange={(e) => handleChangeInfo(field.id, e)}
                                                                    /> */}
                                                                    <div className="invalid-feedback">
                                                                        Este campo no puede estar vacio
                                                                    </div>
                                                                </>

                                                                : <>
                                                                    <Input
                                                                        key={field.id}
                                                                        id={field.id}
                                                                        name={field.id}
                                                                        required={true}
                                                                        className="basic-multi-select text-dark"
                                                                        type={field.type}
                                                                        placeholder={field.placeholder}
                                                                        isSearchable
                                                                        classNamePrefix="select"
                                                                        isDisabled={field.disabled}
                                                                        options={dropDownOptions[field.options]}
                                                                        value={newInfo[field.defaultValueLabel]}
                                                                        onChange={(e) => handleChangeInfo(field.id, e.target.children[e.target.selectedIndex].id, field.defaultValueLabel, e.target.children[e.target.selectedIndex].value)}
                                                                    >
                                                                        <option id=""></option>
                                                                        {dropDownOptions[field.options] && dropDownOptions[field.options].map((value) => {
                                                                            return (
                                                                                <option id={value.value}>{value.label}</option>
                                                                            );
                                                                        })}
                                                                    </Input>
                                                                    {/* <Select
                                                                        key={field.id}
                                                                        id={field.id}
                                                                        name={field.id}
                                                                        required={true}
                                                                        className="basic-single"
                                                                        type={field.type}
                                                                        placeholder={field.placeholder}
                                                                        isSearchable
                                                                        isDisabled={field.disabled}
                                                                        options={dropDownOptions[field.options]}
                                                                        value={options[field.id]}
                                                                        onChange={(e) => handleChangeInfo(field.id, e)}
                                                                    /> */}
                                                                    <div className="invalid-feedback">
                                                                        Este campo no puede estar vacio
                                                                    </div>
                                                                </>


                                                }

                                            </FormGroup>

                                        </Col>
                                    ))
                                }
                            </Row>
                            <Row className="align-items-center">
                                <Col xs={12} key={1001}>
                                <label className="form-control-label"></label>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="danger"
                        type="button"
                        onClick={(e) => toggleNewDocumentModal()}
                    >
                        Cancelar
                    </Button>

                    <Button
                        color="success"
                        disabled={readyToSend}
                        onClick={(e) => handleSendNewRequest(e)}
                    >
                        Enviar
                    </Button>

                </ModalFooter>
            </Form>
        </Modal>
    )
};

export default NewHiringModal;