/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Accesos Físicos en la solicitud
=========================================================

* Coded by Sergio Marin

*/
import React, { useState, useEffect, useRef } from "react";
import { Card, CardTitle, Col, Row, FormGroup } from "reactstrap";
import MultiSelect from 'react-select';

const PhysicalAccesses = ({ optionsJson, setData, isVisible, data, disabledFieldsJson }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif' };
    const h5Style = { fontSize: "1.25em", height: '1.0em', fontFamily: 'sans-serif' };


    //FUNCIONES
    const [mutiValues, setMutiValues] = useState([]);
    const [mutiSelected, setMutiSelected] = useState([]);
    const ref = useRef(data.country);

    //Inicializacion
    useEffect(() => {
        //PARSEO DE OPTIONSJSON PARA EL MULTISELECT
        const transformedObject = {};
        optionsJson["physicalAccess"].forEach(item => {
            const { idCountry, value, id } = item;
            if (!(idCountry in transformedObject)) {
                transformedObject[idCountry] = [];
            }
            transformedObject[idCountry].push({ label: value, value: Number(id) });
        });
        setMutiValues(transformedObject);

        //PARSEO DE DATA PARA EL MULTIVALUE
        if (data.physicalAccesses) {
            const accessTypeMap = optionsJson["physicalAccess"].reduce((acc, item) => {
                const { id, value, idCountry } = item;
                if (!acc[idCountry]) {
                    acc[idCountry] = [];
                }
                acc[idCountry].push({ label: value, value: id });
                return acc;
            }, {});

            const options = data.physicalAccesses.reduce((acc, id) => {
                for (const idCountry in accessTypeMap) {
                    const match = accessTypeMap[idCountry].find(item => item.value.toString() === id);
                    if (match) {
                        if (!acc[idCountry]) {
                            acc[idCountry] = [];
                        }
                        acc[idCountry].push(match);
                        break;
                    }
                }
                return acc;
            }, {});
            setMutiSelected(options);
        }

    }, []);

    //funcion del multiselect
    const handleMultiChange = (selectedOptions, idCountry) => {
        setMutiSelected((prevState) => ({ ...prevState, [idCountry]: selectedOptions }));
    };

    //Cargar los systemaccesses a data
    useEffect(() => {
        function getAllValues(obj) {
            const values = [];

            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    // Si es un array, iteramos sobre los elementos
                    for (const item of obj[key]) {
                        if (item.hasOwnProperty("value")) {
                            values.push(item.value);
                        }
                    }
                } else if (typeof obj[key] === "object") {
                    // Si es un objeto, llamamos a la función recursivamente
                    const subValues = getAllValues(obj[key]);
                    values.push(...subValues);
                }
            }

            return values;
        }

        // Paso 3: Extraer todos los valores dinámicamente
        const allValues = getAllValues(mutiSelected);
        const integersOnlyArray = allValues.map(item => parseInt(item));

        //guardar en data
        setData((prevState) => ({ ...prevState, physicalAccesses: integersOnlyArray }));
    }, [mutiSelected]);

    //borrar lo seleccionado si cambia de pais
    useEffect(() => {
        if (ref.current !== data.country) {
            setData((prevState) => ({ ...prevState, physicalAccesses: [] }));
            setMutiSelected([]);
        }
    }, [data.country]);

    //RETURN
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Accesos Físicos
                    </CardTitle>

                    <Row style={{ padding: '0px 45px 0px' }}>
                        <Col xs="3" style={{ padding: '0px 0px 0px' }} >
                            <h5 style={h5Style}>Accesos Físicos</h5>
                            <FormGroup  >
                                <MultiSelect
                                    isDisabled={(disabledFieldsJson && disabledFieldsJson.includes("PhysicalAccesses"))}
                                    closeMenuOnSelect={false}
                                    id={"physicalaccesses"}
                                    isMulti
                                    options={mutiValues[Number(data.country)]}
                                    value={mutiSelected[Number(data.country)]}
                                    onChange={(selectedOptions) => handleMultiChange(selectedOptions, Number(data.country))}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );
}
export default PhysicalAccesses;