// core antd
import { Tabs } from 'antd';
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Table, ListGroup, ListGroupItem, Row, } from "reactstrap";

const { TabPane } = Tabs;

const RecordDetail = props => {

  const {
    title,
    subtitle,
    data: {
      allergies,
      diseases,
      emergencyContacts,
      interventions,
      othersMedicalInfo,
      attachments
    },
    viewMedications,
    onBack,
    onDownload
  } = props;

  console.log(othersMedicalInfo);

  const [tabKey, setTabKey] = useState("1");

  const changeTabMedical = value => {
    setTabKey(value);
  };

  const renderTables = (cols, rows) => (
    <Table className="align-items-center mb-3" responsive>
      <thead className="thead-light">
        <tr>
          {
            cols.map((row, key) => <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>{row.name}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row, key) => (
            <tr key={key}>
              {
                cols.map((col, key) => (
                  (col.key === "createdAt" || col.key === "startedDate") ?
                    <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                      {
                        row[col.key] ? moment(row[col.key])
                          .utc()
                          .utcOffset(moment().utcOffset())
                          .format("DD/MM/YYYY H:mm A") : 'N/A'
                      }
                    </th>
                    :
                    col.key === "date" ?
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {
                          /* row[col.key] ? moment(row[col.key])
                            .utc()
                            .utcOffset(moment().utcOffset())
                            .format("L") : 'N/A' */
                          row[col.key] ? row[col.key] : 'N/A'
                        }
                      </th>
                      :
                      col.key === "medications" ?
                        <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                          {
                            row.medicated === 1 ?
                              <Button
                                size="sm"
                                color="info"
                                type="button"
                                onClick={() => viewMedications(row)}
                                className="btn-icon btn-2"
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-info" />
                                </span>
                              </Button>
                              :
                              'N/A'
                          }
                        </th>
                        :
                        <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                          {
                            col.type === 'bool' ?
                              row[col.key] === 1 ? "Sí" : "No"
                              :
                              row[col.key]
                          }
                        </th>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </Table>
  );

  return (
    <Card>
      <CardHeader className="border-0">
        <h6 className="surtitle">{title}</h6>
        <h5 className="h3 mb-0">{subtitle}</h5>
      </CardHeader>
      <CardBody>
        <Tabs defaultActiveKey={tabKey} onChange={changeTabMedical}>
          <TabPane tab="Contactos Emergencia" key="1">
            {
              renderTables([
                { key: "name", name: "Nombre" },
                { key: "phone", name: "Teléfono" },
                { key: "relation", name: "Parentesco" },
                { key: "address", name: "Dirección" },
                { key: "createdAt", name: "Fecha de Creación" },
              ], emergencyContacts)
            }
          </TabPane>
          <TabPane tab="Alergias" key="2">
            {
              renderTables([
                { key: "name", name: "Nombre" },
                { key: "startedDate", name: "Fecha de Inicio" },
                { key: "stillHappening", name: "¿Sigue Pasando?", type: 'bool' },
                { key: "medicated", name: "¿Medicado?", type: 'bool' },
                { key: "simptoms", name: "Sintoma" },
                { key: "medications", name: "Medicamentos" },
                { key: "createdAt", name: "Fecha de Creación" },
              ], allergies)
            }
          </TabPane>
          <TabPane tab="Enfermedades" key="3">
            {
              renderTables([
                { key: "disease", name: "Enfermedad" },
                { key: "startedDate", name: "Fecha de Inicio" },
                { key: "stillHappening", name: "¿Sigue Pasando?", type: 'bool' },
                { key: "reason", name: "Razón" },
                { key: "medications", name: "Medicamentos" },
                { key: "createdAt", name: "Fecha de Creación" },
              ], diseases)
            }
          </TabPane>
          <TabPane tab="Intervenciones" key="4">
            {
              renderTables([
                { key: "reason", name: "Razón" },
                { key: "date", name: "Fecha" },
                { key: "medicated", name: "¿Medicado?", type: 'bool' },
                { key: "notes", name: "Notas" },
                { key: "medications", name: "Medicamentos" },
                { key: "createdAt", name: "Fecha de Creación" },
              ], interventions)
            }
          </TabPane>
          <TabPane tab="Vacunas" key="5">
            {
              renderTables([
                { key: "typeName", name: "Tipo" },
                { key: "name", name: "Nombre" },
                { key: "description", name: "Descripción" },
                { key: "date", name: "Fecha" },
              ], othersMedicalInfo)
            }
          </TabPane>
          {
            attachments.length ?
              <TabPane tab="Documentos Adjuntos" key="6">
                <ListGroup className="list my--3 pt-3 pb-2" flush>
                  {
                    attachments.map((row, key) => (
                      <ListGroupItem key={key} className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="Notificación"
                              className="avatar rounded-circle"
                              src={require(`assets/img/theme/attachment.png`)}
                            />
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              {row.filename}
                            </h4>
                            <small>
                              {
                                `${moment(row.createdAt)
                                  .utc()
                                  .utcOffset(moment().utcOffset())
                                  .format("LLL")}`
                              }
                            </small>
                          </div>
                          <Col className="col-auto">
                            <Button
                              color="info"
                              size="sm"
                              onClick={() => onDownload(row)}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-file-download" />
                              </span>
                              <span className="btn-inner--text">Descargar</span>
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))
                  }
                </ListGroup>
              </TabPane>
              : null
          }
        </Tabs>
        <div className="form-row align-items-center">
          <Col className="m-2" sm="12" md="3">
            <Button
              className="btn-icon"
              color="success"
              onClick={onBack}
              size='sm'
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Regresar</span>
            </Button>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

RecordDetail.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RecordDetail;