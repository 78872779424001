// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
import ReactDatetime from "react-datetime";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";

const FiltersCard = props => {

  const {
    title,
    subtitle,
    options,
    values,
    onClear,
    onChangeFilters
  } = props;

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col sm="12" md="10">
            <h3 className="mb-0" >{title}</h3>
            <p className="text-sm mb-0">{subtitle}</p>
          </Col>
          <Col xs="12" md="2" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              block
              type="button"
              onClick={onClear}
            >
              <span className="btn-inner--icon mr-">
                <i className="fas fa-trash" />
              </span>
              <span className="btn-inner--text">
                Limpiar
                </span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="text-right" xs="12" md="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="opportunityNumber"
              >
                Oportunidad
              </label>
              <Select
                className="basic-single"
                id="opportunityNumber"
                type="select"
                placeholder={"Selecione un cliente"}
                isSearchable
                options={options.opps}
                value={values.client}
                onChange={(e) => onChangeFilters('oppNumberSelected', e)}
              />
            </FormGroup>
          </Col>
          <Col className="text-right" xs="12" md="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="client"
              >
                Cliente
                </label>
              <Select
                className="basic-single"
                id="client"
                type="select"
                placeholder={"Selecione un cliente"}
                isSearchable
                options={options.clients}
                value={values.client}
                onChange={(e) => onChangeFilters('clientSelected', e)}
              />
            </FormGroup>
          </Col>
          <Col className="text-right" xs="12" md="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="state"
              >
                Estado
                </label>
              <Select
                className="basic-single"
                id="state"
                type="select"
                placeholder={"Selecione un estado"}
                isSearchable
                options={options.states}
                value={values.state}
                onChange={(e) => onChangeFilters('stateSelected', e)}
              />
            </FormGroup>
          </Col>
          <Col className="text-right" xs="12" md="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="createdAt"
                md="2"
              >
                Fecha de Creación
              </label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Seleccione una fecha",
                    // className: "form-control"
                  }}
                  timeFormat={false}
                  value={values.date}
                  onChange={(e) => onChangeFilters('dateSelected', e)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

FiltersCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default FiltersCard;
