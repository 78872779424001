// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";

moment.locale("es");

const CardTargetLetter = (props) => {
  const { targetLetter, onBack, onOpenFlow, onDeleteNotification, isUser } =
    props;

  console.log(targetLetter.states);

  return (
    <>
      <Row>
        <Col className="order-xl-2" xl="5">
          <div className="card-deck flex-column flex-xl-row">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0 text-white">Información de la creación</h3>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center">
                  <Col sm="7">
                    <h4 className="text-uppercase text-muted text-white font-weight-bold">
                      Número de Solicitud
                    </h4>
                  </Col>
                  <Col sm="5">
                    <blockquote className="blockquote">
                      <p className="text-muted text-white mb-0">
                        {targetLetter.requestNumber}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="7">
                    <h4 className="text-uppercase text-muted text-white font-weight-bold">
                      Elaborada Por
                    </h4>
                  </Col>
                  <Col sm="5">
                    <blockquote className="blockquote">
                      <p className="text-muted text-white mb-0">
                        {targetLetter.createdBy}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="7">
                    <h4 className="text-uppercase text-muted text-white font-weight-bold">
                      Inicio de Carta
                    </h4>
                  </Col>
                  <Col sm="5">
                    <blockquote className="blockquote">
                      <p className="text-muted text-white mb-0">
                        {moment(targetLetter.startLetter)
                          .utc()
                          .utcOffset(moment().utcOffset())
                          .format("L")}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="7">
                    <h4 className="text-uppercase text-muted text-white font-weight-bold">
                      Fin de Carta
                    </h4>
                  </Col>
                  <Col sm="5">
                    <blockquote className="blockquote">
                      <p className="text-muted text-white mb-0">
                        {moment(targetLetter.endLetter)
                          .utc()
                          .utcOffset(moment().utcOffset())
                          .format("L")}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                {/* <Row className="align-items-center">
                  <Col sm="7">
                    <h4 className="text-uppercase text-muted text-white font-weight-bold">
                      Fecha de Solicitud
                  </h4>
                  </Col>
                  <Col sm="5">
                    <blockquote className="blockquote">
                      <p className="text-muted text-white mb-0">
                        {targetLetter.requestDate}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="7">
                    <h4 className="text-uppercase text-muted text-white font-weight-bold">
                      Hora de Solicitud
                  </h4>
                  </Col>
                  <Col sm="5">
                    <blockquote className="blockquote">
                      <p className="text-muted text-white mb-0">
                        {targetLetter.requestTime}
                      </p>
                    </blockquote>
                  </Col>
                </Row> */}
              </CardBody>
            </Card>
          </div>
          <div className="card-deck flex-column flex-xl-row">
            {Object.keys(targetLetter).length && targetLetter.states.length ? (
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <h3 className="mb-0 text-white">Flujo de actividades</h3>
                </CardHeader>
                <CardBody>
                  <div
                    className="timeline timeline-one-side"
                    data-timeline-axis-style="dashed"
                    data-timeline-content="axis"
                  >
                    {targetLetter.states.map((row, key) => {
                      return (
                        <div className="timeline-block" key={key}>
                          <span className="timeline-step badge-primary">
                            <i
                              className={
                                row.state === 0
                                  ? "fas fa-info-circle"
                                  : row.state === 1
                                  ? "fas fa-check"
                                  : row.state === 2
                                  ? "fas fa-times"
                                  : "fas fa-check-double"
                              }
                            />
                          </span>
                          <div className="timeline-content">
                            <h5 className="text-light text-muted font-weight-bold">
                              {moment(row.createdAt)
                                .utc()
                                .utcOffset(moment().utcOffset())
                                .format("LLLL")}
                            </h5>
                            <h5 className="text-white mt-3 mb-0">
                              {row.description}
                            </h5>
                            <h4 className="text-light text-muted font-weight-bold mt-3 mb-0">
                              Comentario: {row.comments}
                            </h4>
                            <div className="mt-3">
                              <Badge
                                className="badge-md"
                                color={
                                  row.state === 0
                                    ? "primary"
                                    : row.state === 1
                                    ? "success"
                                    : row.state === 2
                                    ? "danger"
                                    : "info"
                                }
                                pill
                              >
                                {row.state === 0
                                  ? "Iniciada"
                                  : row.state === 1
                                  ? "Aprobada"
                                  : row.state === 2
                                  ? "Rechazada"
                                  : "Aplicada"}
                              </Badge>
                              <Badge
                                className="badge-md"
                                color={
                                  row.state === 0
                                    ? "primary"
                                    : row.state === 1
                                    ? "success"
                                    : row.state === 2
                                    ? "danger"
                                    : "info"
                                }
                                pill
                              >
                                {row.reviewedBy}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Card className="bg-gradient-default">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-white" tag="h3">
                        Flujo de actividades
                      </CardTitle>
                      <blockquote className="blockquote text-white mb-0">
                        <p>La carta no cuenta con actividades.</p>
                      </blockquote>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fas fa-times" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </div>
          {targetLetter.flowStatus === 2 ? (
            <div className="card-deck flex-column flex-xl-row">
              <Card className="bg-gradient-default">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-white" tag="h3">
                        CANCELADA
                      </CardTitle>
                      <blockquote className="blockquote text-white mb-0">
                        <p>
                          La carta fue rechazada por el{" "}
                          {targetLetter.flow === "HC" ? "HC" : "Colaborador"}
                        </p>
                      </blockquote>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fas fa-times" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="btn-icon btn-3"
                        color="danger"
                        type="button"
                        onClick={onDeleteNotification}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-trash" />
                        </span>
                        <span className="btn-inner--text">
                          Eliminar Notificación
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          ) : targetLetter.flowStatus === 1 ? (
            <div className="card-deck flex-column flex-xl-row">
              <Card className="bg-gradient-default">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-white" tag="h3">
                        Aprobada
                      </CardTitle>
                      <blockquote className="blockquote text-white mb-0">
                        <p>La carta fue aprobada por el colaborador.</p>
                      </blockquote>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fas fa-check-double" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="btn-icon btn-3"
                        color="danger"
                        type="button"
                        onClick={onDeleteNotification}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-trash" />
                        </span>
                        <span className="btn-inner--text">
                          Eliminar Notificación
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          ) : (
            <div className="form-row align-items-center d-none d-xl-block">
              <Row className="align-items-center">
                <Col sm="6">
                  <Button
                    // size="lg"
                    block
                    className="btn-icon btn-3"
                    color="danger"
                    type="button"
                    onClick={onBack}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-arrow-left" />
                    </span>
                    <span className="btn-inner--text">Salir</span>
                  </Button>
                </Col>
                <Col sm="6">
                  <Button
                    // size="lg"
                    block
                    className="btn-icon btn-3"
                    color="success"
                    type="button"
                    onClick={onOpenFlow}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-share-square" />
                    </span>
                    <span className="btn-inner--text">Continuar</span>
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Col>
        <Col className="order-xl-1" xl="7">
          <div className="card-deck flex-column flex-xl-row">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Datos de solicitud</h5>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Colaborador
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {targetLetter.collaborator}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Posición
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">{targetLetter.position}</p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Fecha de Ingreso
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {moment(targetLetter.startDatePosition)
                          .utc()
                          .utcOffset(moment().utcOffset())
                          .format("L")}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Tipo de Solicitud
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {targetLetter.requestType}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                {/* <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Inicio de Carta
                  </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {targetLetter.startLetter}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Fin de Carta
                      </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {targetLetter.endLetter}
                      </p>
                    </blockquote>
                  </Col>
                </Row> */}
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Unidad de Negocio
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {targetLetter.organizationalUnit}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Función
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">{targetLetter.funtion}</p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Gerente
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">{targetLetter.manager}</p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Departamento
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {targetLetter.departament}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                {/* <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Sub Área de Personal
                  </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {targetLetter.persSubArea}
                      </p>
                    </blockquote>
                  </Col>
                </Row> */}
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Compensación por EPM
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">
                        {targetLetter.epmCompensation === 1 ? "SI" : "NO"}
                      </p>
                    </blockquote>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm="4">
                    <h4 className="text-uppercase text-muted font-weight-bold">
                      Comentario SAP
                    </h4>
                  </Col>
                  <Col sm="8">
                    <blockquote className="blockquote">
                      <p className="text-muted mb-0">{targetLetter.comments}</p>
                    </blockquote>
                  </Col>
                </Row>
                {isUser && (
                  <Row className="align-items-center">
                    <Col>
                      <blockquote className="blockquote text-center">
                        <span className="h3 text-red">
                          IMPORTANTE: Al aprobar este flujo se da por aceptado
                          que he leído y comprendido este documento, donde se
                          detallan los indicadores y la metas que se deben
                          cumplir para el periodo que comprende esta carta de
                          objetivos.
                        </span>
                      </blockquote>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
          <div className="card-deck flex-column flex-xl-row">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Objetivos del colaborador</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Tipo</th>
                    <th>Peso</th>
                    <th>Cuota Anual</th>
                    <th>Skew</th>
                    <th>Descripción</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {Object.keys(targetLetter).length &&
                  targetLetter.targets.length ? (
                    targetLetter.targets.map((row, key) => {
                      return (
                        <tr key={key}>
                          <td
                            className="budget"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "justify",
                            }}
                          >
                            {row.type}
                          </td>
                          <td
                            className="budget"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "justify",
                            }}
                          >
                            {row.weight}
                          </td>
                          <td
                            className="budget"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "justify",
                            }}
                          >
                            {row.quota}
                          </td>
                          <td
                            className="budget"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "justify",
                            }}
                          >
                            {row.skew === 0 ? "No" : "Sí"}
                          </td>
                          <td
                            className="budget"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "justify",
                            }}
                          >
                            {row.description}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="justify-content-md-center">
                        Sin objetivos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
          <div className="form-row align-items-center d-xl-none m-2">
            <Col xs="6">
              <Button
                // size="lg"
                block
                className="btn-icon btn-3"
                color="danger"
                type="button"
                onClick={onBack}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-arrow-left" />
                </span>
                <span className="btn-inner--text">Salir</span>
              </Button>
            </Col>
            <Col xs="6">
              <Button
                // size="lg"
                block
                className="btn-icon btn-3"
                color="success"
                type="button"
                onClick={onOpenFlow}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-share-square" />
                </span>
                <span className="btn-inner--text">Continuar</span>
              </Button>
            </Col>
          </div>
        </Col>
      </Row>
    </>
  );
};

CardTargetLetter.propTypes = {
  targetLetter: PropTypes.object.isRequired,
};

export default CardTargetLetter;
