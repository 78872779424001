import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findVariablesMasterData = createAction(constants.GET_ALL_VARIABLES_MASTER_DATA, method.get(urls.PRICING.getVariablesMasterData));

export const findLogsMasterDataById = createAction(constants.GET_LOGS_MASTER_DATA_BY_ID, (id) => method.get(`${urls.PRICING.getLogsMasterDataById}/${id}`)());

export const findOffersRequestById = createAction(constants.GET_OFFERS_REQUEST_BY_ID, (id) => method.get(`${urls.PRICING.getOffersRequestById}/${id}`)());

export const findAjustOfferWithLogById = createAction(constants.GET_LOGS_BY_AJUST_OFFER, (id) => method.get(`${urls.PRICING.getAjustOfferWithLogById}/${id}`)());

export const updateEquipmentSpareById = createAction(constants.UPDATE_VARIABLE_MASTER_DATA_BY_ID, (id, body) => method.put(`${urls.PRICING.updateVariableMasterDataById}/${id}`, body)());

export const updateOfferCalcRequestByPricer = createAction(constants.UPDATE_CALC_OFFERS_REQUEST_BY_ID, (id, idList, body) => method.post(`${urls.PRICING.updateOfferCalcRequestByPricer}/${id}/${idList}`, body)());

export const updateManualOfferCalcRequestByPricer = createAction(constants.UPDATE_MANUAL_CALC_OFFERS_REQUEST_BY_ID, (id, idList, body) => method.post(`${urls.PRICING.updateManualOfferCalcRequestByPricer}/${id}/${idList}`, body)());

export const updateStatePricerListById = createAction(constants.UPDATE_STATE_OFFER_IN_PRICING_LIST, (id, state, body) => method.put(`${urls.PRICING.updateStatePricerListById}/${id}/${state}`, body)());

export const findRequestOffersInAjustment = createAction(constants.GET_REQUEST_OFFERS_IN_AJUSTMENT, method.get(urls.PRICING.getRequestOffersInAjustment));

export const downloadCalsOfferByType = createAction(constants.DOWNLOAD_CALS_OFFER_BY_TYPE, (body) => method.downloadDataTables(`${urls.PRICING.downloadCalsOfferByType}`, body)());
