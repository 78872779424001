// javascript plugin
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Modal, Row } from "reactstrap";

const CorrectionFlow = props => {

  const {
    title,
    days,
    initialValues,
    showModal,
    toggleModal,
    loading,
    message,
    onWorkFlow
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.direction || values.direction === "")
      errors.action = "Debes ingresar la dirección.";
    if (!values.monday && !values.tuesday && !values.thursday && !values.wednesday && !values.friday)
      errors.direction = "Debes seleccionar al menos un día";
    if (!values.comments || values.comments === "")
      errors.comments = "Debes ingresar un comentario para el colaborador.";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={showModal}
      toggle={() => toggleModal("showModalCollaborator")}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent p-2">
            <div className="text-muted text-center mt-2 mb-2">
              <h6 className="surtitle">{title}</h6>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-3">
            <Form
              onSubmit={onWorkFlow}
              validate={validate}
              initialValues={initialValues}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="amountDays"
                      >
                        Días en la modalidad de Home Office
                      </Label>
                      <Row>
                        {
                          days.map((row, key) => (
                            <Col xs="6" md="4" key={key}>
                              <FormGroup check>
                                <Field name={row.key} type="checkbox">
                                  {({ input, meta }) => (
                                    <Label>
                                      <Input
                                        {...input}
                                        type="checkbox"
                                        invalid={meta.error && meta.touched}
                                      />{" "}
                                      {row.name}
                                    </Label>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          ))
                        }
                      </Row>
                    </FormGroup>
                    <FormGroup className="mb-1">
                      <Label className="form-control-label" for="direction">
                        Domicilio donde realizara el Home Office
                      </Label>
                      <Field name="direction">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="textarea"
                              maxLength={500}
                              rows="3"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup className="mb-1">
                      <Label className="form-control-label" for="comments">
                        Comentarios (500)
                        </Label>
                      <Field name="comments">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="textarea"
                              placeholder="Escriba aquí los comentarios para el colaborador."
                              maxLength={500}
                              rows="3"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="d-flex align-items-center justify-content-center text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="success"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Relanzar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

CorrectionFlow.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CorrectionFlow;