// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
  Table,
} from "reactstrap";

const TssManagement = (props) => {
  const { title, subtitle, cols, rows, addEscalation, onDelete } = props;
  return (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col style={{ justifyContent: "left" }} sm="12" md="8">
            <h5 className="surtitle text-white">{title}</h5>
            <h5 className="h3 mb-0 text-white">{subtitle}</h5>
          </Col>
          <Col
            className="d-flex"
            style={{ justifyContent: "flex-end" }}
            sm="12"
            md="4"
          >
            <Button
              className="btn-round btn-icon"
              // outline
              color="info"
              id="add"
              onClick={addEscalation}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-folder-plus" />
              </span>
              <span className="btn-inner--text">Agregar Nuevo</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-dark table-flush" responsive>
        <thead className="thead-dark">
          <tr>
            {cols.map((row, key) => (
              <th
                key={row.key}
                style={{ whiteSpace: "normal", textAlign: "center" }}
              >
                {row.name}
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {rows.map((row, key) => (
            <tr key={key}>
              {cols.map((col, key) => (
                <th
                  key={key}
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  {row[col.key]}
                </th>
              ))}
              <th
                key="action"
                style={{ whiteSpace: "normal", textAlign: "center" }}
              >
                <a
                  className="table-action table-action"
                  href="#"
                  onClick={() => onDelete(row)}
                >
                  <i className="fas fa-trash" />
                </a>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

TssManagement.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default TssManagement;
