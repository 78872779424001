import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const findAllCustomers = handleActions({
    [constants.GET_CUSTOMERS]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const findAllContactsByCustomer = handleActions({
    [constants.GET_CONTACTS]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const findAllFunctions = handleActions({
    [constants.GET_FUNCTIONS]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const findAllDepartaments = handleActions({
    [constants.GET_DEPARTAMENTS]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const findAllCountrys = handleActions({
    [constants.GET_COUNTRYS]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const findAllHistory = handleActions({
    [constants.GET_HISTORY]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const updateContact = handleActions({
    [constants.UPDATE_CONTACT]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const createNewContact = handleActions({
    [constants.CREATE_CONTACT]: (state, action) => {
        return state;
    },
    [constants.SIGN_OUT]: () => [],
}, []);
export const confirmThisContact = handleActions({
    [constants.CONFIRM_CONTACT]: (state, action) => {
        return state;
    },
    [constants.SIGN_OUT]: () => [],
}, []);
export const lockThisContact = handleActions({
    [constants.LOCK_CONTACT]: (state, action) => {
        return state;
    },
    [constants.SIGN_OUT]: () => [],
}, []);
export const sendEmailFileContact = handleActions({
    [constants.GET_FILE_UPDATE]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});

