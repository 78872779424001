import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateInterview,
  getDraftInterview,
  sendEmailToUser,
  sendEmailToHCM
} from "actions/exitInterview.jsx";
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  FormGroup,
  Input,
  Button,
  Row,
  CardFooter,
  Modal,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";

const DraftModal = ({
  openDraftModal,
  rowInfo,
  closeDraftModal,
  setRowsDraftModal,
  Toast,
  options,
  setRowsModal,
  isAdmin,
  setAlert,
  setLoad
}) => {
  const statusInterview =
    rowInfo.interviewStatus === "Completado por Colaborador" ? true : false;
  //Campos en el modal de informacion
  const rowsModal = [
    {
      colWidth: "4",
      label: "Id de Colaborador",
      id: "idCollaborator",
      disabled: true,
      type: "text",
    },
    {
      colWidth: "4",
      label: "Pais",
      id: "country",
      type: "select",
      value: "country",
      disabled: true,
      type: "text",
    },
    {
      colWidth: "4",
      label: "Nombre de Colaborador",
      id: "collaboratorName",
      disabled: true,
      type: "text",
    },
    {
      colWidth: "4",
      label: "Jefe Inmediato",
      id: "immediateBoss",
      disabled: true,
      type: "text",
    },
    {
      colWidth: "4",
      label: "Departamento",
      id: "department",
      disabled: true,
      type: "text",
    },
    {
      colWidth: "4",
      label: "Tiempo Laborado",
      id: "timeWorked",
      disabled: true,
      type: "text",
    },
    {
      colWidth: "4",
      label: "Fecha de Salida",
      id: "date",
      type: "date",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Tipo de Salida",
      id: "exitReason",
      value: "exitReasonID",
      type: "select",
      disabled: statusInterview === true ? true : false,
    },
    {
      colWidth: "4",
      label: "Entrevistador",
      id: "interviewer",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Es Recontratable?",
      id: "rehirable",
      value: "rehirable",
      type: "select",
    },
    {
      colWidth: "4",
      label: "Esta dispuesto a volver a GBM?",
      id: "goBackGBM",
      value: "goBackGBM",
      type: "select",
      disabled: statusInterview === true ? true : false,
    },
    {
      colWidth: "6",
      label: "Razón de Salida",
      id: "exitType",
      value: "exitTypeId",
      type: "select",
      disabled: true,
    },
    {
      colWidth: "6",
      label: "Comentarios",
      id: "comment",
      type: "textarea",
      disabled: statusInterview === true ? true : false,
    },
    {
      colWidth: "6",
      label: "Comentarios HC",
      type: "textarea",
      id: "commentHC",
    },  
    {
      colWidth: "6",
      label: "Fecha exacta de salida",
      id: "dateExact",
      type: "date",
      disabled: false,
    },
    {
      colWidth: "12",
      label:
        "¿Qué pudo haber hecho GBM para que usted no hubiera valorado otras opciones de trabajo?",
      id: "doNotValueGBM",
      type: "textarea",
      disabled: statusInterview === true ? true : false,
    },
  ];
  const [updateInfo, setUpdateInfo] = useState({});

  const dispatch = useDispatch();
  const handleOnChangeInfo = (constant, e, name) => {
    if (name === "exitType" || name === "companyType") {
      setUpdateInfo((prevState) => ({
        ...prevState,
        [constant]: e.id,
        [name]: e.label,
      }));
    } else {
      setUpdateInfo((prevState) => ({
        ...prevState,
        [constant]: e,
      }));
      setRowsDraftModal((prevState) => ({
        ...prevState,
        [constant]: e,
      }));
    }
  };
  const handleOnUpdateDraftInterview = () => {
    dispatch(updateInterview({ info: updateInfo, id: rowInfo.idInterview })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        dispatch(getDraftInterview()).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setRowsModal((prevState) => ({
              ...prevState,
              rowsDraft: payload.data.payload.data,
            }));
            closeDraftModal(false);
          }
          Toast.fire({
            title: "Atención",
            html: "Se actualizo correctamente",
            type: "success",
          });
        });
      } else {
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error no se pudo actualizar",
          type: "success",
        });
      }
    });
    setUpdateInfo({});

  };
  const handleOnSaveInterview = () => {
    updateInfo["status"] = 4;
    if (validate() === 0) {
      setLoad(true);
      dispatch(updateInterview({ info: updateInfo, id: rowInfo.idInterview  })).then((resp) => {
        const { payload } = resp;
        if (payload.status === 200) {
          setAlert("")
          closeDraftModal(false);
          dispatch(sendEmailToHCM({ info: rowInfo, complete: true })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
              dispatch(getDraftInterview()).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                  setRowsModal((prevState) => ({
                    ...prevState,
                    rowsDraft: payload.data.payload.data,
                  }));
                  setLoad(false);
                } else {
                  setRowsModal((prevState) => ({
                    ...prevState,
                    rowsDraft: [],
                  }));
                  setLoad(false);
                }
                Toast.fire({
                  title: "Atención",
                  html: "Se actualizo correctamente",
                  type: "success",
                });
              });
            } else {
              setLoad(false);
            }
          });
        } else {
          setLoad(false);
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error no se pudo actualizar",
            type: "success",
          });
        }
      });
    }
    setUpdateInfo({});

  };
  const validate = () => {
    let cont = 0;
    const forms = document.querySelectorAll(".validateDraft input, select");
    Array.from(forms).forEach((input) => {
      if (
        input.required === true &&
        (input.value === "" || input.value === "---")
      ) {
        input.classList.add("is-invalid");
        cont++;
      } else {
        input.classList.remove("is-invalid");
      }
    });

    return cont;
  };
  const [isRenounce, setIsRenounce] = useState(false);
  const [isOtherExitReason, setIsOtherExitReason] = useState(false);

  useEffect(() => {
    if (rowInfo.exitType === "Renuncia") {
      setIsRenounce(true);
    }
    setIsOtherExitReason(containsExitReason("Otros"));
  }, [rowInfo]);
  const containsExitReason = (word) => {
    let cont = 0;
    if (rowInfo.exitReason) {
      rowInfo.exitReason.map((item) => item.label === word && cont++);
      if (cont > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  const handleOnSendCollaboratorInterview = () => {
    setLoad(true);
    updateInfo["status"] = 1;
    dispatch(updateInterview({ info: updateInfo, id: rowInfo.idInterview  })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        dispatch(sendEmailToUser({ user: rowInfo.user })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setAlert("")
            closeDraftModal(false);
            dispatch(getDraftInterview()).then((resp) => {
              const { payload } = resp;
              if (payload.status === 200) {
                setRowsModal((prevState) => ({
                  ...prevState,
                  rowsDraft: payload.data.payload.data,
                }));
                setLoad(false);
              }
              Toast.fire({
                title: "Atención",
                html: "Se actualizo correctamente",
                type: "success",
              });
            });
          }
        });
      } else {
        setLoad(false);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error no se pudo actualizar",
          type: "success",
        });
      }
    });
  };
  const validateSend = (type) => {
    setAlert(
      <ReactBSAlert
        custom
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={
          type == "interview" ?
            "¿Está seguro(a) que desea guardar la entrevista como completa?"
            : "¿Está seguro(a) que desea enviar la entrevista al colaborador?"
        }
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        onConfirm={
          type == "interview" ? () => handleOnSaveInterview()
            : () => handleOnSendCollaboratorInterview()
        }
        onCancel={() => setAlert("")}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sí, guardar"
        cancelBtnText="No, cancelar"
        btnSize="md"
      />
    );
  }

  const buttons = [
    { text: "Guardar como borrador", id: "btn1", color: "primary", disabled: false, onclick: () => handleOnUpdateDraftInterview(), icon: "fas fa-check-circle", textHover: "Guardar cambios en borrador", xs: "12", md: "6" },
    { text: "Guardar Entrevista", id: "btn2", color: "success", disabled: rowInfo.interviewStatus === "Enviado a Colaborador" ? true : false, onclick: () => validateSend("interview"), icon: "fas fa-save", textHover: "Completar entrevista.", xs: "12", md: "6" },
    { text: "Enviar formulario a Colaborador", id: "btn3", color: "secondary", disabled: rowInfo.interviewStatus === "Completado por Colaborador" ? true : false, onclick: () => validateSend(), icon: "fas fa-file-import", textHover: "Envia la entrevista al colaborador para que la complete", xs: "12", md: "6" },
    { text: "Cerrar", color: "danger", id: "btn4", disabled: false, onclick: () => closeDraftModal(false), icon: "fas fa-times", textHover: "Cerrrar ventana", xs: "12", md: "6" }
  ];
  console.log(rowInfo);
  return (
    <div>
      <Modal size="lg" isOpen={openDraftModal}>
        <Card>
          <CardHeader>
            <h6 className="surtitle">{`Información de la entrevista de salida`}</h6>
          </CardHeader>
          <CardBody>
            <Form noValidate className="validateDraft needs-validation">
              <Row>
                {rowsModal.map((item, key) =>
                  item.type === "select" ? (
                    item.id === "exitReason" ? (
                      <>
                        <Col xs={item.colWidth} key={key}>
                          <FormGroup>
                            <label
                              style={{ fontSize: "12px" }}
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {item.label}
                            </label>
                            <Select
                              key={key}
                              className="basic-single is-invalid"
                              type="select"
                              isSearchable
                              isDisabled={
                                isAdmin === false ? item.disabled : false
                              }
                              isMulti
                              defaultValue={rowInfo["exitReason"]}
                              options={options.masterData[item.id]}
                              onChange={(e) => handleOnChangeInfo(item.id, e)}
                            />

                            <div className="invalid-feedback">
                              Este campo no puede estar vacio
                            </div>
                          </FormGroup>
                        </Col>
                        {isOtherExitReason == true ? (
                          <Col xs={4} key={`isOtherExitReason${key}`}>
                            <FormGroup key={`isOtherExitReason${key}`}>
                              <label
                                style={{ fontSize: "12px" }}
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                {`Especificación de tipo de salida:`}
                              </label>
                              <Input
                                key={`isOtherExitReason${key}`}
                                required={true}
                                disabled={
                                  isAdmin === false ? item.disabled : false
                                }
                                className="form-control"
                                type={"text"}
                                defaultValue={
                                  rowInfo["specify"] ? rowInfo["specify"] : ""
                                }
                                onChange={(e) =>
                                  handleOnChangeInfo("specify", e.target.value)
                                }
                              />
                              <div className="invalid-feedback">
                                Este campo no puede estar vacio
                              </div>
                            </FormGroup>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : item.id === "exitType" ? (
                      <>
                        <Col xs={item.colWidth} key={key}>
                          <FormGroup>
                            <label
                              style={{ fontSize: "12px" }}
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {item.label}
                            </label>
                            <Input
                              key={key}
                              required={true}
                              type={"select"}
                              disabled={
                                isAdmin === false ? item.disabled : false
                              }
                              options={options.masterData[item.id]}
                              defaultValue={rowInfo[item.id]}
                              className="basic-multi-select text-dark"
                              onChange={(e) =>
                                handleOnChangeInfo(
                                  item.value,
                                  e.target.children[e.target.selectedIndex],
                                  "exitType"
                                )
                              }
                            >
                              {options[item.id].map((value) => {
                                return (
                                  <option id={value.value} key={value.label}>
                                    {value.label}
                                  </option>
                                );
                              })}
                            </Input>

                            <div className="invalid-feedback">
                              Este campo no puede estar vacio
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={item.colWidth} key={key}>
                          <FormGroup>
                            <label
                              style={{ fontSize: "12px" }}
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {item.label}
                            </label>
                            <Input
                              key={key}
                              required={true}
                              type={"select"}
                              disabled={
                                isAdmin === false ? item.disabled : false
                              }
                              options={options.masterData[item.id]}
                              defaultValue={
                                item.id === "countriesData"
                                  ? rowInfo[item.value]
                                  : rowInfo[item.id]
                              }
                              className="basic-multi-select text-dark"
                              onChange={(e) =>
                                handleOnChangeInfo(
                                  item.id === "countriesData"
                                    ? item.id
                                    : item.value,
                                  e.target.children[e.target.selectedIndex].id
                                )
                              }
                            >
                              {options[item.id].map((value) => {
                                return (
                                  <option id={value.value} key={value.label}>
                                    {value.label}
                                  </option>
                                );
                              })}
                            </Input>
                            <div className="invalid-feedback">
                              Este campo no puede estar vacio
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    )
                  ) : (
                    <Col xs={item.colWidth} key={key}>
                      <FormGroup key={key}>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${item.label}:`}
                        </label>
                        <Input
                          id={item.id}
                          key={key}
                          required={true}
                          disabled={isAdmin === false ? item.disabled : false}
                          onChange={(e) =>
                            handleOnChangeInfo(item.id, e.target.value)
                          }
                          className="form-control"
                          defaultValue={
                            item.type === "date"
                              ? moment.utc(rowInfo[item.id]).format("YYYY-MM-DD")
                              : rowInfo[item.id]
                          }
                          type={item.type}
                        />
                      </FormGroup>
                    </Col>
                  )
                )}
                {isRenounce === true && (
                  <>
                    <Col xs={6} >
                      <FormGroup>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {"¿Qué tipo de compañía es su nueva oferta laboral?"}
                        </label>

                        <Input
                          required={true}
                          type={"select"}
                          options={options.masterData["companyType"]}
                          defaultValue={
                            rowInfo["companyType"]
                              ? rowInfo["companyType"]
                              : "---"
                          }
                          className="basic-multi-select text-dark"
                          disabled={
                            isAdmin === false
                              ? statusInterview === true
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) =>
                            handleOnChangeInfo(
                              "companyTypeId",
                              e.target.children[e.target.selectedIndex],
                              "companyType"
                            )
                          }
                        >
                          {options["companyType"].map((value) => {
                            return (
                              <option id={value.value} key={value.label}>{value.label}</option>
                            );
                          })}
                        </Input>
                        <div className="invalid-feedback">
                          Este campo no puede estar vacio
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`¿Qué beneficios adicionales o pluses adicionales ofrece la nueva oferta laboral?`}
                        </label>
                        <Input
                          id={"benefitsJob"}
                          required={true}
                          className="form-control"
                          type={"textarea"}
                          disabled={
                            isAdmin === false
                              ? statusInterview === true
                                ? true
                                : false
                              : false
                          }
                          defaultValue={
                            rowInfo["benefitsJob"] ? rowInfo["benefitsJob"] : ""
                          }
                          onChange={(e) =>
                            handleOnChangeInfo("benefitsJob", e.target.value)
                          }
                        />
                        <div className="invalid-feedback">
                          Este campo no puede estar vacio
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </CardBody>
          <CardFooter>
            <Row className="d-flex justify-content-center align-items-center">
              {
                buttons.map((item, key) => (
                  <Col xs={item.xs} md={item.md} className="mb-3">

                    <Button
                      key={key}
                      className="btn btn-icon"
                      color={item.color}
                      block
                      disabled={item.disabled}
                      id={item.id}
                      onClick={item.onclick}
                    >
                      <span className="btn-inner--icon mr-">
                        <i className={item.icon}></i>
                      </span>
                      <span className="btn-inner--text">{item.text}</span>
                    </Button>
                    <UncontrolledTooltip
                      key={key}
                      delay={0}
                      target={item.id}
                      style={{ zIndex: "9999" }}
                    >
                      {item.textHover}
                    </UncontrolledTooltip>


                  </Col>

                ))
              }
            </Row>
          </CardFooter>
        </Card>
      </Modal>
    </div>
  );
};

export default DraftModal;
