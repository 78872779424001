// core actions
import { deactivateTargetLetter, findTargetLetterHC, updateTargetLetterFlow } from "actions/targetLetter.jsx";
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import CardEmpty from 'components/Shared/Notifications/CardEmpty.jsx';
// core components TargetLetter
import CardLetters from 'components/TargetLetter/CardLetters.jsx';
import CardTargetLetter from 'components/TargetLetter/CardTargetLetter.jsx';
import WorkFlowModal from 'components/TargetLetter/WorkFlowModal.jsx';
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getTargetsLetterHC } from "selectors/targetLetter";

moment.locale("es");

class TargetLettersHC extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: true,
      showModal: false,
      selectedLetter: null,
    };
  }

  componentWillMount() {
    this.findTargets();
  }

  findTargets = () => {
    this.props.findTargetLetterHC().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false,
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  _handleOpenLetter = row => {
    this.setState({
      selectedLetter: row
    });
  };

  handleOnBack = () => {
    this.setState({
      selectedLetter: null
    });
  };

  _toggleModal = state => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  _handleOnOpenFlow = () => {
    this.setState({
      showModal: true
    });
  }

  _handleOnWorkFlow = values => {
    const { selectedLetter: { id } } = this.state;
    const {
      action,
      comments
    } = values;
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props.updateTargetLetterFlow(id, {
      action,
      desicion: 'HC',
      comments
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        this.findTargets();
      }
      this.setState({
        loading: false,
        showModal: false,
        selectedLetter: null
      });
    });
  }

  _deleteTargetLetterNotification = () => {
    const { selectedLetter: { id } } = this.state;
    console.log(id);
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props.deactivateTargetLetter(id, {}).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState((state) => ({
        loading: !state.loading,
        selectedLetter: null,
        alert: null
      }));
    });
  }

  _handleOnDeleteNotification = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de eliminar la notificación?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this._deleteTargetLetterNotification()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, volver"
          btnSize="md"
        />
      )
    });
  }

  render() {

    const {
      name,
      parentName,
      letters,
    } = this.props;

    const {
      alert,
      loading,
      showModal,
      selectedLetter,
    } = this.state;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <WorkFlowModal
          loading={loading}
          showModal={showModal}
          toggleModal={this._toggleModal}
          onWorkFlow={this._handleOnWorkFlow}
        />
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          {
            selectedLetter ?
              <CardTargetLetter
                targetLetter={selectedLetter}
                onBack={this.handleOnBack}
                onOpenFlow={this._handleOnOpenFlow}
                onDeleteNotification={this._handleOnDeleteNotification}
              />
              :
              letters.length ?
                <Row>
                  <Col>
                    <CardLetters
                      title="Cartas de objetivos pendientes de revisar"
                      letters={letters}
                      openLetter={this._handleOpenLetter}
                    />
                  </Col>
                </Row>
                :
                <Row className="justify-content-center">
                  <Col sm="12" md="8">
                    <CardEmpty
                      title="No hay cartas pendientes."
                      subtitle="Al día de hoy no tienes cartas de objetivos pendientes, en el momento que exista una nueva, aparecerán en esta ventana."
                    />
                  </Col>
                </Row>
          }
        </Container>
      </>
    );
  }
}

TargetLettersHC.defaultProps = {
  name: "Carta de objetivos",
  parentName: "Notificaciones",
  letters: [],
};

TargetLettersHC.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  letters: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  letters: getTargetsLetterHC(state)
});

export default withRouter(connect(mapStateToProps, {
  deactivateTargetLetter,
  findTargetLetterHC,
  updateTargetLetterFlow,
})(TargetLettersHC));