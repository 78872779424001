/* eslint-disable array-callback-return */
import { generateSignature } from "actions/auth";
import {
  createAllergy,
  createDisease,
  createEmergencyContact,
  createIntervention,
  createMedicalRecord,
  createOtherMedicalInfo,
  deleteAllergy,
  deleteDisease,
  deleteEmergencyContact,
  deleteIntervention,
  deleteOtherMedicalInfo,
  findAllAllergies,
  findAllDiseases,
  findAllInterventions,
  findAllOtherMedicalInfo,
  findEmergencyContacts,
  findMedicalRecord,
  findOffices,
  findOtherTypes,
  updateAllergy,
  updateDisease,
  updateEmergencyContact,
  updateIntervention,
  updateMedicalRecord,
  updateMedication,
  updateOtherMedicalInfo,
  downloadAttachment,
} from "actions/medicalRecords.jsx";
// core antd
import { message, Spin, Tabs } from "antd";
import CardImportSignature from "components/Profile/CardImportSignature.jsx";
// core components Profile
import EditAllergyModal from "components/Profile/EditAllergyModal.jsx";
import EditContactModal from "components/Profile/EditContactModal.jsx";
import EditDiseaseModal from "components/Profile/EditDiseaseModal.jsx";
import EditInterventionModal from "components/Profile/EditInterventionModal.jsx";
import EditMedicationModal from "components/Profile/EditMedicationModal.jsx";
import EditOtherMedicalInfo from "components/Profile/EditOtherMedicalInfo.jsx";
import MedicalReportForm from "components/Profile/MedicalReportForm.jsx";
import MedicineModal from "components/Profile/MedicineModal.jsx";
import MedicinesModal from "components/Profile/MedicinesModal.jsx";
import SignatureGenerate from "components/Profile/SignatureGenerate.jsx";
import TermsAndConditions from "components/Profile/TermsAndConditions.jsx";
// core components Shared
import ProfileHeader from "components/Shared/Header/ProfileHeader.jsx";
//library for screenshot
import html2canvas from "html2canvas";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
// core selectors
import { getSignature, getUserProtected } from "selectors/adminLayout";
import {
  getAllAllergies,
  getAllDiseases,
  getAllInterventions,
  getAllOffices,
  getAllOtherMedicalInfo,
  getAllOtherTypes,
  getContactInitialValues,
  getEmergencyContacts,
  getMedicalRecord,
} from "selectors/medicalRecords.jsx";
// core encryter
import { encrypter, desencrypt } from "helpers/desencrypt";

const { TabPane } = Tabs;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      parent: true,
      loading: false,
      loadingEditModal: false,
      message: null,
      stepMedicalRecord: 1,
      initialValues: {},
      showAll: false,
      uploading: false,
      allergies: [],
      diseases: [],
      interventions: [],
      showModalMedicines: false,
      medicinesValues: {},
      medicationsType: null,
      medicines: {
        allergyMedicated: [],
        diseaseMedicated: [],
        interventionsRequireMedication: [],
      },
      tabKey: "1",
      modalEditContact: {
        showModal: false,
        initialValues: {},
      },
      modalEditAllergy: {
        showModal: false,
        initialValues: {},
      },
      modalEditDisease: {
        showModal: false,
        initialValues: {},
      },
      modalEditIntervention: {
        showModal: false,
        initialValues: {},
      },
      modalViewMedicines: {
        showModal: false,
        values: [],
      },
      modalViewOtherMedicalInfo: {
        showModal: false,
        values: [],
      },
      modalViewMedication: {
        showModal: false,
        values: [],
      },
      celPhone: "",
      extention: "",
      name: "",
      codeCountry: "",
      modalTermsAndConditions: {
        showModal: false,
        row: {},
      },
      fileList: [],
      avatar: null,
    };
    this.changeCelPhone = this.changeCelPhone.bind(this);
    this.changeExtention = this.changeExtention.bind(this);
    this.handelOnToggleModalSignature =
      this.handelOnToggleModalSignature.bind(this);
    this.preViewSignature = this.preViewSignature.bind(this);
    this.changeCodeCountry = this.changeCodeCountry.bind(this);
  }

  componentWillMount() {
    if (localStorage.getItem("avatar")) {
      this.setState({ avatar: localStorage.getItem("avatar") });
    }
    const {
      user: { IDCOLABC },
    } = this.props;
    this.props.findMedicalRecord(IDCOLABC).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: {
            payload: { data },
          },
        } = res.payload;
        if (data) {
          const decrypt = desencrypt(data);
          const info = JSON.parse(decrypt);
         
          if (info) {
            this.props.findEmergencyContacts(encrypter(info.id));
            this.props.findAllAllergies(encrypter(info.id));
            this.props.findAllDiseases(encrypter(info.id));
            this.props.findAllInterventions(encrypter(info.id));
            this.props.findAllOtherMedicalInfo(encrypter(info.id));
            this.setState({
              stepMedicalRecord: 4,
            });
          }
        }
        this.setState({
          parent: false,
        });
      }
    });
    this.props.findOffices();
    this.props.findOtherTypes();
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnBack = () => {
    this.props.history.goBack();
  };

  handleOnSubmitMedicalReport = (values) => {
    const { stepMedicalRecord } = this.state;
    const {
      user: { IDCOLABC },
      medicalRecord,
    } = this.props;
    const { address, bloodType, bornDate, location } = values;
    const userKeyRegExp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}?$/;
    const date = userKeyRegExp.test(bornDate) ? bornDate.split("/") : bornDate;
    if (stepMedicalRecord === 4) {
      this.setState({
        loading: true,
        message: "Actualizando el expediente médico",
      });
      this.props
        .updateMedicalRecord(medicalRecord.id, {
          OfficeID: location,
          address,
          bornDate: userKeyRegExp.test(bornDate)
            ? `${date[2]}-${date[1]}-${date[0]}`
            : moment(bornDate).format("YYYY-MM-DD"),
          bloodType,
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("danger", "Falló", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
              this.setState({
                loading: false,
                message: null,
              });
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.props.findMedicalRecord(IDCOLABC);
            this.setState({
              loading: false,
              message: null,
            });
            this.notify("success", "Éxito", payload.message);
          }
        });
    } else {
      this.setState({
        modalTermsAndConditions: {
          showModal: true,
          row: {
            UserID: IDCOLABC,
            OfficeID: location,
            address,
            bornDate: moment(bornDate).format("YYYY-MM-DD"),
            bloodType,
          },
        },
      });
    }
  };

  handleGenerateSignature = () => {
    this.props
      .generateSignature({
        extention: this.state.extention,
        cellPhone: this.state.celPhone,
        codeCountry: this.state.codeCountry,
        name: this.state.name ? this.state.name : "",
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([res.payload.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `firma.png`);
          document.body.appendChild(link);
          link.click();
          this.preViewSignature();
          this.handelOnToggleModalSignature();
        }
      });
  };

  handleOnAddEmergencyContact = (values) => {
    const { medicalRecord } = this.props;
    const { contactName, telephone, relationship, contactAddress } = values;
    if (!telephone) {
      this.notify(
        "warning",
        "Atención",
        "El formato del telefono unicamente debe ser númerico, no se permiten caracteres especiales"
      );
    } else if (!contactName || !telephone || !relationship) {
      this.notify(
        "warning",
        "Atención",
        "Es requerido completar todos los campos (Nombre Completo, Teléfono, Parentesco)"
      );
    } else {
      this.setState({
        loading: true,
        message: "Creando el contacto de emergencia",
      });
      this.props
        .createEmergencyContact({
          RecordID: medicalRecord.id,
          name: contactName,
          phone: telephone,
          address: contactAddress,
          relation: relationship,
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("danger", "Falló", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            this.props.findEmergencyContacts(medicalRecord.id);
            this.setState({
              stepMedicalRecord: 3,
            });
          }
          this.setState({
            loading: false,
            message: null,
          });
        });
    }
  };

  handleOnShowAllEmergencyContacts = (action) => {
    this.setState({
      showAll: action === "all" ? true : false,
    });
  };

  handleOnToggleModal = () => {
    this.setState((state) => ({
      showModalMedicines: !state.showModalMedicines,
    }));
  };

  handleOnToggleContactModal = () => {
    this.setState({
      modalEditContact: {
        showModal: false,
        initialValues: {},
      },
    });
  };

  handleOnToggleAllergyModal = () => {
    this.setState({
      modalEditAllergy: {
        showModal: false,
        initialValues: {},
      },
    });
  };

  handleOnToggleDiseaseModal = () => {
    this.setState({
      modalEditDisease: {
        showModal: false,
        initialValues: {},
      },
    });
  };

  handleOnToggleInterventionModal = () => {
    this.setState({
      modalEditIntervention: {
        showModal: false,
        initialValues: {},
      },
    });
  };

  handleOnAddMedicine = () => {
    this.setState({
      showModalMedicines: true,
    });
  };

  handleOnSubmitMedicines = (values) => {
    const { medicationsType, medicines } = this.state;
    values.startedDate = moment(values.startedDate).format("YYYY-MM-DD");
    medicines[medicationsType] = [values, ...medicines[medicationsType]];
    this.setState({
      medicines,
      medicinesValues: {
        medication: "",
        startedDate: "",
        currently: "",
        dosis: "",
        sideEffects: "",
        test: "test",
      },
    });
  };

  handleOnChangeTab = (value) => {
    this.setState({
      tabKey: value,
    });
  };

  handleOnAddMedicalInfo = (values, type) => {
    const { medicalRecord } = this.props;
    const { medicines, medicationsType } = this.state;
    const valuesArray = Object.keys(values).filter((row) => row.includes(type));
    const json = {};
    valuesArray.map((row) => {
      json[row] = values[row];
    });
    if (type === "allergy") {
      const {
        allergyName,
        allergyStartedDate,
        allergyStillHappening,
        allergySymptom,
        allergyMedicated,
      } = json;
      if (
        allergyName &&
        allergyStartedDate &&
        allergyStillHappening &&
        allergySymptom &&
        allergyMedicated
      ) {
        this.setState({
          loading: true,
          message: "Registrando la alergia agregada",
        });
        this.props
          .createAllergy({
            RecordID: medicalRecord.id,
            name: allergyName,
            startedDate: moment(allergyStartedDate).format("YYYY-MM-DD"),
            stillHappening: allergyStillHappening,
            medicated: allergyMedicated,
            medications: medicines[medicationsType],
            simptoms: allergySymptom,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("danger", "Falló", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
            } else {
              const {
                data: { payload },
              } = res.payload;
              this.notify("success", "Éxito", payload.message);
              this.props.findAllAllergies(medicalRecord.id);
              medicines[medicationsType] = [];
              this.setState({
                medicines,
              });
            }
            this.setState({
              loading: false,
              message: null,
            });
          });
      } else {
        this.notify(
          "warning",
          "Atención",
          "Es requerido completar todos los campos"
        );
      }
    } else if (type === "disease") {
      const {
        diseaseMedicated,
        diseaseName,
        diseaseNameStartedDate,
        diseaseReason,
        diseaseStillHappening,
      } = json;
      if (
        diseaseMedicated &&
        diseaseName &&
        diseaseNameStartedDate &&
        diseaseReason &&
        diseaseStillHappening
      ) {
        this.setState({
          loading: true,
          message: "Registrando la enfermedad agregada",
        });
        this.props
          .createDisease({
            RecordID: medicalRecord.id,
            disease: diseaseName,
            startedDate: moment(diseaseNameStartedDate).format("YYYY-MM-DD"),
            stillHappening: diseaseStillHappening,
            medicated: diseaseMedicated,
            reason: diseaseReason,
            medications: medicines[medicationsType],
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("danger", "Falló", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
            } else {
              const {
                data: { payload },
              } = res.payload;
              this.notify("success", "Éxito", payload.message);
              this.props.findAllDiseases(medicalRecord.id);
              medicines[medicationsType] = [];
              this.setState({
                medicines,
              });
            }
            this.setState({
              loading: false,
              message: null,
            });
          });
      } else {
        this.notify(
          "warning",
          "Atención",
          "Es requerido completar todos los campos"
        );
      }
    } else if (type === "interventions") {
      const {
        interventionsReason,
        interventionsNotes,
        interventionsDate,
        interventionsRequireMedication,
      } = json;
      if (
        interventionsReason &&
        interventionsNotes &&
        interventionsDate &&
        interventionsRequireMedication
      ) {
        this.setState({
          loading: true,
          message: "Registrando la intervención agregada",
        });
        this.props
          .createIntervention({
            RecordID: medicalRecord.id,
            reason: interventionsReason,
            date: moment(interventionsDate).format("YYYY-MM-DD"),
            medicated: interventionsRequireMedication,
            notes: interventionsNotes,
            medications: medicines[medicationsType],
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("danger", "Falló", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
            } else {
              const {
                data: { payload },
              } = res.payload;
              this.notify("success", "Éxito", payload.message);
              this.props.findAllInterventions(medicalRecord.id);
              medicines[medicationsType] = [];
              this.setState({
                medicines,
              });
            }
            this.setState({
              loading: false,
              message: null,
            });
          });
      } else {
        this.notify(
          "warning",
          "Atención",
          "Es requerido completar todos los campos"
        );
      }
    } else if (type === "medical") {
      const { medicalType, medicalName, medicalDescription, medicalDate } =
        json;
      if (medicalType && medicalName && medicalDescription && medicalDate) {
        this.setState({
          loading: true,
          message: "Registrando la información",
        });
        this.props
          .createOtherMedicalInfo({
            RecordID: medicalRecord.id,
            name: medicalName,
            date: moment(medicalDate).format("YYYY-MM-DD"),
            description: medicalDescription,
            TypeID: medicalType,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("danger", "Falló", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
            } else {
              const {
                data: { payload },
              } = res.payload;
              this.notify("success", "Éxito", payload.message);
              this.props.findAllOtherMedicalInfo(medicalRecord.id);
            }
            this.setState({
              loading: false,
              message: null,
            });
          });
      } else {
        this.notify(
          "warning",
          "Atención",
          "Es requerido completar todos los campos"
        );
      }
    }
  };

  handleOnEditEmergencyContact = (row) => {
    const { RecordID, id, address, relation, phone, name } = row;
    this.setState({
      loadingEditModal: true,
      message: "Actualizando el contacto de emergencia",
    });
    this.props
      .updateEmergencyContact(id, {
        name,
        address,
        relation,
        phone,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findEmergencyContacts(RecordID);
          this.handleOnToggleContactModal();
        }
        this.setState({
          loadingEditModal: false,
          message: null,
        });
      });
  };

  handleOnShowEditEmergencyContact = (row) => {
    this.setState({
      modalEditContact: {
        showModal: true,
        initialValues: row,
      },
    });
  };

  handleOnShowEditAllergy = (row) => {
    // row.startedDate = moment(row.startedDate).format("L");
    this.setState({
      modalEditAllergy: {
        showModal: true,
        initialValues: row,
      },
    });
  };

  handleOnShowEditDisease = (row) => {
    // row.startedDate = moment(row.startedDate).format("L");
    this.setState({
      modalEditDisease: {
        showModal: true,
        initialValues: row,
      },
    });
  };

  handleOnShowEditIntervention = (row) => {
    // row.date = moment(row.date).format("L");
    this.setState({
      modalEditIntervention: {
        showModal: true,
        initialValues: row,
      },
    });
  };

  onDeleteEmergencyContact = (row) => {
    const { id, RecordID } = row;
    this.hideAlert();
    this.setState({
      loading: true,
      message: "Eliminando el contacto de emergencia",
    });
    this.props.deleteEmergencyContact(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findEmergencyContacts(RecordID);
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnDeleteEmergencyContact = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar el contacto de emergencia?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteEmergencyContact(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleMedicatedEvent = (event) => {
    const {
      target: { name, value },
    } = event;
    const { medicationsType } = this.state;
    if (value === "1" && !medicationsType) {
      this.setState({
        showModalMedicines: true,
        medicationsType: name,
      });
    } else {
      this.setState({
        showModalMedicines: false,
        medicationsType: null,
      });
    }
  };

  handleOnEditAllergy = (row) => {
    const {
      id,
      RecordID,
      medicated,
      name,
      simptoms,
      startedDate,
      stillHappening,
    } = row;
    const userKeyRegExp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}?$/;
    const dateRegex = userKeyRegExp.test(startedDate)
      ? startedDate.split("/")
      : startedDate;
    this.setState({
      loadingEditModal: true,
      message: "Actualizando la alergia",
    });
    this.props
      .updateAllergy(id, {
        name,
        startedDate: userKeyRegExp.test(startedDate)
          ? `${dateRegex[2]}-${dateRegex[1]}-${dateRegex[0]}`
          : moment(startedDate).format("YYYY-MM-DD"),
        stillHappening,
        medicated,
        simptoms,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findAllAllergies(RecordID);
          this.handleOnToggleAllergyModal();
        }
        this.setState({
          loadingEditModal: false,
          message: null,
        });
      });
  };

  handleOnEditDisease = (row) => {
    const {
      id,
      RecordID,
      medicated,
      disease,
      reason,
      startedDate,
      stillHappening,
    } = row;
    const userKeyRegExp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}?$/;
    const dateRegex = userKeyRegExp.test(startedDate)
      ? startedDate.split("/")
      : startedDate;
    this.setState({
      loadingEditModal: true,
      message: "Actualizando la enfermedad",
    });
    this.props
      .updateDisease(id, {
        RecordID,
        disease,
        startedDate: userKeyRegExp.test(startedDate)
          ? `${dateRegex[2]}-${dateRegex[1]}-${dateRegex[0]}`
          : moment(startedDate).format("YYYY-MM-DD"),
        stillHappening,
        medicated,
        reason,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findAllDiseases(RecordID);
          this.handleOnToggleDiseaseModal();
        }
        this.setState({
          loadingEditModal: false,
          message: null,
        });
      });
  };

  handleOnEditIntervertion = (row) => {
    const { id, RecordID, medicated, reason, notes, date } = row;
    const userKeyRegExp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}?$/;
    const dateRegex = userKeyRegExp.test(date) ? date.split("/") : date;
    this.setState({
      loadingEditModal: true,
      message: "Actualizando el contacto de emergencia",
    });
    this.props
      .updateIntervention(id, {
        RecordID,
        date: userKeyRegExp.test(date)
          ? `${dateRegex[2]}-${dateRegex[1]}-${dateRegex[0]}`
          : moment(date).format("YYYY-MM-DD"),
        notes,
        medicated,
        reason,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findAllInterventions(RecordID);
          this.handleOnToggleInterventionModal();
        }
        this.setState({
          loadingEditModal: false,
          message: null,
        });
      });
  };

  onDeleteAllergy = (row) => {
    const { id, RecordID } = row;
    this.hideAlert();
    this.setState({
      loading: true,
      message: "Eliminando la alergia",
    });
    this.props.deleteAllergy(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findAllAllergies(RecordID);
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnDeleteAllergy = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar la alergia?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteAllergy(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  onDeleteDisease = (row) => {
    const { id, RecordID } = row;
    this.hideAlert();
    this.setState({
      loading: true,
      message: "Eliminando la enfermedad",
    });
    this.props.deleteDisease(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findAllDiseases(RecordID);
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnDeleteDisease = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar la enfermedad?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteDisease(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  onDeleteIntervention = (row) => {
    const { id, RecordID } = row;
    this.hideAlert();
    this.setState({
      loading: true,
      message: "Eliminando la intervención",
    });
    this.props.deleteIntervention(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findAllInterventions(RecordID);
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnDeleteIntervention = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar la intervención?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteIntervention(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnViewMedications = (row) => {
    const { medications } = row;
    this.setState({
      modalViewMedicines: {
        showModal: true,
        values: medications,
      },
    });
  };

  handleOnToggleModalMedicines = () => {
    this.setState({
      modalViewMedicines: {
        showModal: false,
        values: [],
      },
    });
  };

  handleOnShowEditOtherMedicalInfo = (row) => {
    // row.date = moment(row.date).format("L");
    this.setState({
      modalViewOtherMedicalInfo: {
        showModal: true,
        initialValues: row,
      },
    });
  };

  handleOnToggleOtherMedicalInfoModal = () => {
    this.setState({
      modalViewOtherMedicalInfo: {
        showModal: false,
        initialValues: {},
      },
    });
  };

  handleOnEditOtherMedicalInfo = (row) => {
    const { id, RecordID, name, description, TypeID, date } = row;
    const userKeyRegExp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}?$/;
    const dateRegex = userKeyRegExp.test(date) ? date.split("/") : date;
    this.setState({
      loadingEditModal: true,
      message: "Actualizando la información",
    });
    this.props
      .updateOtherMedicalInfo(id, {
        RecordID,
        date: userKeyRegExp.test(date)
          ? `${dateRegex[2]}-${dateRegex[1]}-${dateRegex[0]}`
          : moment(date).format("YYYY-MM-DD"),
        name,
        description,
        TypeID,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findAllOtherMedicalInfo(RecordID);
          this.handleOnToggleOtherMedicalInfoModal();
        }
        this.setState({
          loadingEditModal: false,
          message: null,
        });
      });
  };

  onDeleteOtherMedicalInfo = (row) => {
    const { id, RecordID } = row;
    this.hideAlert();
    this.setState({
      loading: true,
      message: "Eliminando la información",
    });
    this.props.deleteOtherMedicalInfo(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findAllOtherMedicalInfo(RecordID);
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnDeleteOtherMedicalInfo = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar la información?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteOtherMedicalInfo(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  validationInputPhone = (e) => {
    const esValido = e.target.validity.valid;
    if (esValido) {
      this.setState({
        celPhone: e.target.value,
      });
    }
  };
  validationInpuCode = (e) => {
    const esValido = e.target.validity.valid;
    if (esValido) {
      this.setState({
        codeCountry: e.target.value,
      });
    }
  };
  validationInputExtention = (e) => {
    const esValido = e.target.validity.valid;
    if (esValido) {
      this.setState({
        extention: e.target.value,
      });
    }
  };
  validationInputName = (e) => {
    const esValido = e.target.validity.valid;
    if (esValido) {
      this.setState({
        name: e.target.value,
      });
    }
  };
  changeCelPhone(e) {
    this.setState({
      celPhone: e.target.value,
    });
  }
  changeCodeCountry(e) {
    this.setState({
      codeCountry: e.target.value,
    });
  }

  changeExtention(e) {
    this.setState({
      extention: e.target.value,
    });
  }
  changeName(e) {
    this.setState({
      name: e.target.value,
    });
  }
  screenshot() {
    const domElement = document.getElementById("signature");
    html2canvas(domElement).then((canvas) => {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "firma.png";
      link.href = canvas.toDataURL("image/png");
      link.target = "_blank";
      link.click();
    });
  }

  handelOnToggleModalSignature() {
    this.setState({
      modal: !this.state.modal,
      codeCountry: "",
    });
  }

  preViewSignature() {
    if (this.state.extention === "" || this.state.extention === null) {
      this.notify("warning", "Atención", "La extensión es obligatoria");
    } else {
      this.setState({
        modalPreView: !this.state.modalPreView,
      });
    }
  }

  setCountry = (country, location) => {
    var info = {
      setCountry: "",
      setLocation: "",
    };
    this.props.countries.map((item) => {
      if (item.key === country && item.sudivision === location) {
        info.setCountry = item.value;
        info.setLocation = item.location;
      }
    });
    return info;
  };

  handleOnShowEditMedication = (row) => {
    row.startedDate = moment(row.startedDate).format("L");
    this.setState({
      modalViewMedication: {
        showModal: true,
        values: row,
      },
    });
  };

  handleOnToggleMedicationModal = () => {
    this.setState({
      modalViewMedication: {
        showModal: false,
        initialValues: {},
      },
    });
  };

  handleOnEditMedication = (row) => {
    const { medicalRecord } = this.props;
    const { id, sideEffects, currently, medication, dosis, startedDate } = row;
    const userKeyRegExp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}?$/;
    const dateRegex = userKeyRegExp.test(startedDate)
      ? startedDate.split("/")
      : startedDate;
    this.setState({
      loadingEditModal: true,
      message: "Actualizando el medicamento",
    });
    this.props
      .updateMedication(id, {
        medication,
        startedDate: userKeyRegExp.test(startedDate)
          ? `${dateRegex[2]}-${dateRegex[1]}-${dateRegex[0]}`
          : moment(startedDate).format("YYYY-MM-DD"),
        currently,
        dosis,
        sideEffects,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findAllAllergies(medicalRecord.id);
          this.props.findAllDiseases(medicalRecord.id);
          this.props.findAllInterventions(medicalRecord.id);
          this.props.findAllOtherMedicalInfo(medicalRecord.id);
          this.handleOnToggleMedicationModal();
          this.handleOnToggleModalMedicines();
        }
        this.setState({
          loadingEditModal: false,
          message: null,
        });
      });
  };

  handleOnToggleModalTermsAndConditions = () => {
    this.setState({
      modalTermsAndConditions: {
        showModal: false,
        row: {},
      },
    });
  };

  handleOnSubmitMedicalRecord = () => {
    const {
      modalTermsAndConditions: { row },
    } = this.state;
    this.setState({
      loading: true,
      message: "Creando el expediente médico",
    });
    this.props.createMedicalRecord(row).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
          this.setState({
            loading: false,
            message: null,
          });
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.props.findMedicalRecord(row.UserID);
        this.setState({
          loading: false,
          message: null,
          stepMedicalRecord: 2,
          modalTermsAndConditions: {
            showModal: false,
            row: {},
          },
        });
        this.notify("success", "Éxito", payload.message);
      }
    });
  };

  handleOnFileListAttachments = (info) => {
    const {
      user: { IDCOLABC },
    } = this.props;
    this.setState({
      loading: true,
      message: "Cargando Archivo",
    });
    const {
      fileList,
      file: { status, name },
    } = info;
    if (status === "done") {
      message.success(`La carga del archivo ${name} fue exitosa.`);
      this.props.findMedicalRecord(IDCOLABC).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.setState({
            loading: false,
            message: null,
            fileList: [],
          });
        }
      });
    } else if (status === "error") {
      message.success(`Ocurrio un error cargando el archivo ${name}.`);
      this.setState({
        loading: false,
        message: null,
      });
    }
    this.setState({
      fileList,
      loading: false,
      message: null,
    });
  };

  handleOnDownloadAttachment = (row) => {
    const { id } = row;
    this.setState({
      loading: true,
      message: "Descargando el documento adjuntado",
    });
    this.props.downloadAttachment(encrypter(id)).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          this.notify(
            "warning",
            "Atención",
            "No se logro descargar el archivo adjunto seleccionado"
          );
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const url = window.URL.createObjectURL(new Blob([res.payload.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", row.filename);
        document.body.appendChild(link);
        link.click();
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  render() {
    const {
      user,
      diseases,
      allergies,
      interventions,
      medicalRecord,
      locations,
      bloodTypes,
      otherTypes,
      otherMedicalInfo,
      otherInitialValues,
      emergencyContacts,
    } = this.props;

    const {
      alert,
      avatar,
      parent,
      tabKey,
      showAll,
      loading,
      message,
      fileList,
      medicines,
      medicationsType,
      medicinesValues,
      loadingEditModal,
      modalEditAllergy,
      modalEditDisease,
      modalEditContact,
      stepMedicalRecord,
      showModalMedicines,
      modalViewMedicines,
      modalViewMedication,
      modalEditIntervention,
      modalTermsAndConditions,
      modalViewOtherMedicalInfo,
    } = this.state;
    const location = locations.find(
      (row) =>
        row.country === user.PAIS && row.subDivision === user.SUB_DIVISION
    );
    if (medicalRecord)
      medicalRecord.location = medicalRecord.OfficeID
        ? medicalRecord.OfficeID
        : location
        ? location.id
        : "";

    return (
      <>
        {alert}
        <script src="/path/to/html2canvas.min.js"></script>
        <TermsAndConditions
          loading={loading}
          message={message}
          modalTermsAndConditions={modalTermsAndConditions}
          toggleModal={this.handleOnToggleModalTermsAndConditions}
          onSubmit={this.handleOnSubmitMedicalRecord}
        />
        <EditOtherMedicalInfo
          title="Editar Información"
          options={modalViewOtherMedicalInfo}
          otherTypes={otherTypes}
          loading={loadingEditModal}
          message={message}
          toggleModal={this.handleOnToggleOtherMedicalInfoModal}
          onSubmit={this.handleOnEditOtherMedicalInfo}
        />
        <EditMedicationModal
          title="Editar medicamento"
          options={modalViewMedication}
          loading={loadingEditModal}
          message={message}
          toggleModal={this.handleOnToggleMedicationModal}
          onSubmit={this.handleOnEditMedication}
        />
        <EditAllergyModal
          title="Editar Alergia"
          options={modalEditAllergy}
          loading={loadingEditModal}
          message={message}
          toggleModal={this.handleOnToggleAllergyModal}
          onSubmit={this.handleOnEditAllergy}
        />
        <EditDiseaseModal
          title="Editar Enfermedad"
          options={modalEditDisease}
          loading={loadingEditModal}
          message={message}
          toggleModal={this.handleOnToggleDiseaseModal}
          onSubmit={this.handleOnEditDisease}
        />
        <EditInterventionModal
          title="Editar Intervención"
          options={modalEditIntervention}
          loading={loadingEditModal}
          message={message}
          toggleModal={this.handleOnToggleInterventionModal}
          onSubmit={this.handleOnEditIntervertion}
        />
        <EditContactModal
          title="Editar Contacto de Emergencia"
          options={modalEditContact}
          loading={loadingEditModal}
          message={message}
          toggleModal={this.handleOnToggleContactModal}
          onSubmit={this.handleOnEditEmergencyContact}
        />
        <MedicineModal
          title="Añadir Medicamentos"
          medicines={medicines}
          type={medicationsType}
          initialValues={medicinesValues}
          showModal={showModalMedicines}
          toggleModal={this.handleOnToggleModal}
          onSubmit={this.handleOnSubmitMedicines}
        />
        <MedicinesModal
          title="Medicamentos Agregados"
          modalOptions={modalViewMedicines}
          toggleModal={this.handleOnToggleModalMedicines}
          editMedication={this.handleOnShowEditMedication}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <ProfileHeader name={user.NOMBRE} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            <Row>
              <Col>
                <Card className="card-profile mt-5">
                  {avatar && window.innerWidth > 1400 ? (
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="5">
                        <div className="card-profile-image">
                          <img
                            alt="..."
                            className="rounded-circle border-secondary"
                            style={{ maxWidth: "180px" }}
                            src={avatar}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  <CardHeader>
                    <Row>
                      <Col xs="12" md="9">
                        <h5 className="surtitle">Mi Perfil</h5>
                        <h4
                          className="heading-small text-muted"
                          style={{ fontSize: ".85rem" }}
                        >
                          Información de Colaborador
                        </h4>
                      </Col>
                      <Col className="text-right" xs="12" md="3">
                        <Row className="d-flex justify-content-end">
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="info"
                            type="button"
                            onClick={this.handleOnBack}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-arrow-left" />
                            </span>
                            <span className="btn-inner--text">Volver</span>
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="success"
                            type="button"
                            onClick={this.handelOnToggleModalSignature}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-download" />
                            </span>
                            <span className="btn-inner--text">
                              Generar Firma
                            </span>
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div>
                        <Row>
                          <Col xs="12" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Departamento
                              </label>
                              <Input
                                defaultValue={user.DEPARTAMENTO}
                                id="input-username"
                                type="text"
                                disabled
                                className="form-control-label"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Posición
                              </label>
                              <Input
                                id="input-email"
                                defaultValue={user.POSICION}
                                type="text"
                                disabled
                                className="form-control-label"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Correo Electrónico
                              </label>
                              <Input
                                defaultValue={user.EMAIL}
                                id="input-first-name"
                                type="email"
                                disabled
                                className="form-control-label"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Supervisor
                              </label>
                              <Input
                                defaultValue={user.SUPERVISOR}
                                id="input-last-name"
                                type="text"
                                disabled
                                className="form-control-label"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                País
                              </label>
                              <Input
                                defaultValue={user.PAIS}
                                id="input-first-name"
                                type="text"
                                disabled
                                className="form-control-label"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                ID Colaborador
                              </label>
                              <Input
                                defaultValue={user.IDCOLABC}
                                id="input-last-name"
                                type="text"
                                disabled
                                className="form-control-label"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    {/* <h5 className="surtitle">Mi Perfil</h5> */}
                    <h4
                      className="heading-small text-muted"
                      style={{ fontSize: ".85rem" }}
                    >
                      Información Personal
                    </h4>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <Tabs
                      defaultActiveKey={tabKey}
                      onChange={this.handleOnChangeTab}
                    >
                      <TabPane tab="Expediente Médico" key="1">
                        {!parent && (
                          <MedicalReportForm
                            all={showAll}
                            id={user.IDCOLABC}
                            step={stepMedicalRecord}
                            options={{ locations, bloodTypes, otherTypes }}
                            initialValues={medicalRecord}
                            otherInitialValues={otherInitialValues}
                            emergencyContacts={emergencyContacts}
                            medicalInfo={{
                              allergies,
                              diseases,
                              interventions,
                              otherMedicalInfo,
                            }}
                            fileList={fileList}
                            medicatedEvent={this.handleMedicatedEvent}
                            onSubmit={this.handleOnSubmitMedicalReport}
                            editContact={this.handleOnShowEditEmergencyContact}
                            editAllergy={this.handleOnShowEditAllergy}
                            editDisease={this.handleOnShowEditDisease}
                            editIntervention={this.handleOnShowEditIntervention}
                            editOtherMedicalInfo={
                              this.handleOnShowEditOtherMedicalInfo
                            }
                            onAddMedicalInfo={this.handleOnAddMedicalInfo}
                            onShowAll={this.handleOnShowAllEmergencyContacts}
                            deleteContact={this.handleOnDeleteEmergencyContact}
                            deleteAllergy={this.handleOnDeleteAllergy}
                            deleteDisease={this.handleOnDeleteDisease}
                            deleteIntervention={this.handleOnDeleteIntervention}
                            deleteOtherMedicalInfo={
                              this.handleOnDeleteOtherMedicalInfo
                            }
                            addEmergencyContact={
                              this.handleOnAddEmergencyContact
                            }
                            viewMedications={this.handleOnViewMedications}
                            onFileList={this.handleOnFileListAttachments}
                            onDownload={this.handleOnDownloadAttachment}
                          />
                        )}
                      </TabPane>
                    </Tabs>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Spin>
          <Modal
            className="modal-dialog modal-sm"
            isOpen={this.state.modal}
            toggle={this.handelOnToggleModalSignature}
          >
            <ModalBody className="p-0 m-0">
              <CardImportSignature
                changeExtention={this.changeExtention}
                changeCelPhone={this.changeCelPhone}
                changeCodeCountry={this.changeCodeCountry}
                extension={this.state.extention}
                celPhone={this.state.celPhone}
                preView={this.preViewSignature}
                handleOnBack={this.handelOnToggleModalSignature}
                changeInputPhone={this.validationInputPhone}
                changeInputCode={this.validationInpuCode}
                changeInputExtention={this.validationInputExtention}
                name={this.state.name}
                changeName={this.changeName}
                changeInputName={this.validationInputName}
                valueName={user.NOMBRE.split(",")
                  .reverse()
                  .join()
                  .replace(",", " ")}
                codeCountry={
                  this.setCountry(
                    user.PAIS,
                    user.SUB_DIVISION
                  ).setCountry.split(" ")[0]
                }
              />
            </ModalBody>
          </Modal>

          <Modal
            className="modal-dialog"
            size="lg"
            isOpen={this.state.modalPreView}
            toggle={this.preViewSignature}
          >
            <CardHeader>
              <h2 className="heading-small text-muted">Generar Firma</h2>
            </CardHeader>

            <ModalBody className="p-0 m-0">
              <Row className="d-flex justify-content-center">
                <Col sm="12" md="12" id="signature" className="col-auto">
                  <SignatureGenerate
                    nombre={
                      this.state.name === ""
                        ? `${user.NOMBRE.split(",")[1]} ${
                            user.NOMBRE.split(",")[0]
                          }`
                        : this.state.name
                    }
                    extension={this.state.extention}
                    celular={
                      this.state.celPhone === ""
                        ? this.state.celPhone
                        : `| Cel: ${
                            this.state.codeCountry === ""
                              ? this.setCountry(
                                  user.PAIS,
                                  user.SUB_DIVISION
                                ).setCountry.split(" ")[0]
                              : this.state.codeCountry
                          } ${this.state.celPhone} `
                    }
                    telefono={
                      this.setCountry(user.PAIS, user.SUB_DIVISION).setCountry
                    }
                    location={
                      this.setCountry(user.PAIS, user.SUB_DIVISION).setLocation
                    }
                    puesto={user.POSICION}
                    email={`Email: ${user.EMAIL.toString().toLowerCase()}`}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row className=" d-flex align-item-center">
                <Button color="info" onClick={this.preViewSignature}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-arrow-left" />
                  </span>
                  Volver
                </Button>
                <Button color="success" onClick={this.handleGenerateSignature}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-download" />
                  </span>
                  Descargar
                </Button>
              </Row>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}

Profile.defaultProps = {
  user: {},
  locations: [],
  bloodTypes: [
    { id: "A+", name: "A+" },
    { id: "A-", name: "A-" },
    { id: "B+", name: "B+" },
    { id: "B-", name: "B-" },
    { id: "AB+", name: "AB+" },
    { id: "AB-", name: "AB-" },
    { id: "O+", name: "O+" },
    { id: "O-", name: "O-" },
    { id: "na", name: "N/A" },
  ],
  medicalRecord: {},
  otherInitialValues: {},
  emergencyContacts: [],
  allergies: [],
  diseases: [],
  interventions: [],
  otherTypes: [],
  otherMedicalInfo: [],
  countries: [
    {
      key: "CR",
      value: "(506) 2284-3999",
      location: "GBM Costa Rica",
      sudivision: "0001",
    },
    {
      key: "CR",
      value: "(506) 2504-4500",
      location: "GBM Corporación",
      sudivision: "0002",
    },
    {
      key: "SV",
      value: "(503) 2505-9600",
      location: "GBM El Salvador",
      sudivision: "0001",
    },
    {
      key: "GT",
      value: "(502) 2424-2222",
      location: "GBM Guatemala",
      sudivision: "0001",
    },
    {
      key: "HN",
      value: "(504) 2232-2319/09",
      location: "GBM Honduras",
      sudivision: "0001",
    },
    {
      key: "HN",
      value: "(504) 2556-5531",
      location: "GBM Honduras",
      sudivision: "0002",
    },
    {
      key: "MD",
      value: "(1) 305 597-3998",
      location: "GBM Miami",
      sudivision: "0001",
    },
    {
      key: "NI",
      value: "(505) 2255-6630",
      location: "GBM Nicaragua",
      sudivision: "0001",
    },
    {
      key: "PA",
      value: "(507) 300-4800",
      location: "GBM Panama",
      sudivision: "0001",
    },
    {
      key: "PA",
      value: "(507) 300-4800",
      location: "GBM Panama",
      sudivision: "0002",
    },
    {
      key: "PA",
      value: "(507) 300-4800",
      location: "GBM Panama",
      sudivision: "0003",
    },
    {
      key: "DR",
      value: "(809) 566-5161",
      location: "GBM Dominicana",
      sudivision: "0001",
    },
    {
      key: "DR",
      value: "(809) 566-5161",
      location: "GBM Dominicana",
      sudivision: "0002",
    },
    {
      key: "DR",
      value: "(809) 566-5161",
      location: "GBM Dominicana",
      sudivision: "0003",
    },
    {
      key: "CO",
      value: "(+57) 1 3832137",
      location: "GBM Colombia",
      sudivision: "0001",
    },
  ],
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  medicalRecord: PropTypes.object.isRequired,
  otherInitialValues: PropTypes.object.isRequired,
  emergencyContacts: PropTypes.array.isRequired,
  allergies: PropTypes.array.isRequired,
  diseases: PropTypes.array.isRequired,
  interventions: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  otherTypes: PropTypes.array.isRequired,
  otherMedicalInfo: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: getUserProtected(),
  medicalRecord: getMedicalRecord(state),
  otherInitialValues: getContactInitialValues(state),
  emergencyContacts: getEmergencyContacts(state),
  allergies: getAllAllergies(state),
  diseases: getAllDiseases(state),
  interventions: getAllInterventions(state),
  locations: getAllOffices(state),
  otherTypes: getAllOtherTypes(state),
  otherMedicalInfo: getAllOtherMedicalInfo(state),
  signature: getSignature(state),
});

export default withRouter(
  connect(mapStateToProps, {
    createAllergy,
    createDisease,
    createEmergencyContact,
    createIntervention,
    createMedicalRecord,
    createOtherMedicalInfo,
    deleteAllergy,
    deleteDisease,
    deleteEmergencyContact,
    deleteIntervention,
    deleteOtherMedicalInfo,
    findAllAllergies,
    findAllDiseases,
    findAllInterventions,
    findEmergencyContacts,
    findMedicalRecord,
    findOtherTypes,
    findOffices,
    findAllOtherMedicalInfo,
    updateAllergy,
    updateDisease,
    updateEmergencyContact,
    updateIntervention,
    updateMedicalRecord,
    updateMedication,
    updateOtherMedicalInfo,
    generateSignature,
    downloadAttachment,
  })(Profile)
);
