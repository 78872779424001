// core actions
import { createUserNotification, deactivateUserEscalationNotificationByID, findUsersNotificationMatrix, updateUserEscalationNotification, validateGbmCollaborator } from "actions/secoh.jsx";
// antd components
import { Spin } from "antd";
// core components SECOH
import MatrixEscalationsList from 'components/SECOH/MatrixEscalations.jsx';
import UserEscalationModal from 'components/SECOH/UserEscalationModal.jsx';
// core components Shared
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getCountriesNotificationEscalations, getServicesNotificationEscalations, getUsersNotifications } from "selectors/secoh.jsx";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

class MatrixNotifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      loadingModal: false,
      message: null,
      userModalEscalation: {
        showModal: false,
        data: {},
        type: null,
      },
    };
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  componentWillMount() {
    this.findUsersNotificationMatrix();
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  findUsersNotificationMatrix = () => {
    this.props.findUsersNotificationMatrix().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({
        willMount: false,
      });
    });
  };

  handleOnToggleModal = modal => {
    this.setState({
      [modal]: {
        showModal: false,
        data: {},
        type: null,
      },
    });
  };

  handleOnAddUserEscalation = () => {
    this.setState({
      userModalEscalation: {
        showModal: true,
        data: {},
        type: 'search'
      },
    });
  };

  handleOnCreateUserEscalation = (values, services, countries, type) => {
    this.setState({
      loadingModal: true,
    });
    if (type === 'add') {
      this.props.createUserNotification(values).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.findUsersNotificationMatrix();
        }
        this.setState({
          loadingModal: false,
          userModalEscalation: {
            showModal: false,
            data: {},
            type: null,
          }
        });
      });
    } else if (type === 'edit') {
      values.service = [...values.service, ...services.map((row) => row.id)];
      values.country = [...values.country, ...countries.map((row) => row.id)];
      this.props.updateUserEscalationNotification(values.id, values).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.findUsersNotificationMatrix();
        }
        this.setState({
          loadingModal: false,
          userModalEscalation: {
            showModal: false,
            data: {},
            type: null,
          }
        });
      });
    } else if (type === 'search') {
      this.props.validateGbmCollaborator({
        username: values.email.split("@")[0]
      }).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const { data: { payload } } = res.payload;
          const { EMAIL, IDCOLABC, NOMBRE } = payload.response;
          this.setState({
            userModalEscalation: {
              showModal: true,
              data: {
                idUser: IDCOLABC,
                fullname: NOMBRE,
                email: EMAIL,
              },
              type: 'add',
            }
          })
        }
        this.setState({
          loadingModal: false,
        });
      });
    }
  };

  handleOnEditUserEscalation = row => {
    this.setState({
      userModalEscalation: {
        showModal: true,
        data: row,
        type: 'edit',
      },
    });
  };

  onDeleteUserEscalation = row => {
    this.setState({
      alert: null,
      loading: true,
      message: 'Eliminando el usuario de la matriz de notificaciones'
    });
    this.props.deactivateUserEscalationNotificationByID(row.id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.findUsersNotificationMatrix();
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  handleOnDeleteUserEscalation = row => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de eliminar el usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() =>
            this.onDeleteUserEscalation(row)
          }
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  render() {

    const {
      name,
      parentName,
      cols,
      services,
      countries,
      rows,
    } = this.props;

    const {
      alert,
      loading,
      message,
      willMount,
      loadingModal,
      userModalEscalation,
    } = this.state;

    return (
      <>
        {alert}
        {
          userModalEscalation.showModal === true &&
          <UserEscalationModal
            title="Notificaciones Usuarios"
            loading={loadingModal}
            modal={userModalEscalation}
            options={{ services, countries }}
            toggleModal={this.handleOnToggleModal}
            onSubmit={this.handleOnCreateUserEscalation}
          />
        }
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {
              willMount ?
                <Row className="justify-content-center">
                  <Col>
                    <CardLoading title="Cargando la matriz de notificaciones para notificiones" />
                  </Col>
                </Row>
                :
                <MatrixEscalationsList
                  title="Lista de Usuarios para notificaciones"
                  subtitle="A continuación se muestran los usuarios que se encuentran disponibles para las notificaciones"
                  cols={cols}
                  rows={rows}
                  addUser={this.handleOnAddUserEscalation}
                  onEdit={this.handleOnEditUserEscalation}
                  onDelete={this.handleOnDeleteUserEscalation}
                />
            }
          </Spin>
        </Container>
      </>
    );
  }
}


MatrixNotifications.defaultProps = {
  name: "Matriz de Notificaciones",
  parentName: "Contratos On Hold",
  cols: [
    { key: "id", name: "#REF" },
    { key: "idUser", name: "ID Colaborador" },
    { key: "fullname", name: "Nombre Completo" },
    { key: "email", name: "Correo Electronico" },
    { key: "services", name: "Servicio" },
    { key: "countries", name: "País" },
  ],
  rows: [],
  services: [],
  countries: [],
};

MatrixNotifications.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  rows: getUsersNotifications(state),
  services: getServicesNotificationEscalations(state),
  countries: getCountriesNotificationEscalations(state),
});

export default withRouter(connect(mapStateToProps, {
  findUsersNotificationMatrix,
  createUserNotification,
  updateUserEscalationNotification,
  deactivateUserEscalationNotificationByID,
  validateGbmCollaborator
})(MatrixNotifications));