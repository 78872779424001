export const getRequirementsPlanners = (state) => {
  if (Object.keys(state.requerimentsPlanners).length) {
    return state.requerimentsPlanners.request;
  }
  return [];
};

export const getAllRequirementsPlanners = (state) => {
  if (Object.keys(state.allRequerimentsPlanners).length) {
    return state.allRequerimentsPlanners.request;
  }
  return [];
};

export const getEquipmentsByRequest = (state) => {
  if (Object.keys(state.equipmentsByRequestPlanners).length) {
    return state.equipmentsByRequestPlanners.equipments;
  }
  return [];
};

export const getUserAssignmentByRequest = (state) => {
  if (Object.keys(state.userAssignmentByRequestPlanners).length) {
    return state.userAssignmentByRequestPlanners.users;
  }
  return [];
};

export const getAllPartsEquipmentsByRequest = (state) => {
  if (Object.keys(state.allPartsEquipmentsByRequest).length) {
    return state.allPartsEquipmentsByRequest.parts;
  }
  return [];
};

export const getFiltersOptions = (state) => {
  if (Object.keys(state.allRequerimentsPlanners).length) {
    const { request } = state.allRequerimentsPlanners;
    const { dataAssigned } = state.allRequerimentsPlanners;
    let clients = request.map((row, key) => {
      return {
        value: key + 1,
        label: row.createdBy,
      };
    });
    clients = clients
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => clients[e])
      .map((e) => clients[e]);
    clients = clients.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let states = request.map((row, key) => {
      console.log(row);
      if (row.state !== 6) {
        return {
          value: row.state,
          label: row.status,
        };
      }
      if (!dataAssigned.some((ele) => ele.id === row.id)) {
        return {
          value: 1,
          label: "Pendiente de Asignación",
        };
      } else {
        let contAssigned = 0;
        let equipments = 0;
        for (const ele of dataAssigned) {
          if (ele.id === row.id) {
            equipments = parseInt(ele.equipments);
            contAssigned = contAssigned + parseInt(ele.equipAssigned);
          }
        }
        if (contAssigned != parseInt(equipments)) {
          return {
            value: 1,
            label: "Pendiente de Asignación",
          };
        }
        return {
          value: row.state,
          label: row.status,
        };
      }
    });
    states = states
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => states[e])
      .map((e) => states[e]);
    states = states.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let opps = request.map((row, key) => {
      return {
        value: key + 1,
        label: row.opportunityNumber,
      };
    });
    opps = opps
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => opps[e])
      .map((e) => opps[e]);
    opps = opps.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let countries = request.map((row, key) => {
      return {
        value: key + 1,
        label: row.country,
      };
    });
    countries = countries
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => countries[e])
      .map((e) => countries[e]);
    countries = countries.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return {
      customer: [{ value: 0, label: "Todos" }, ...clients],
      status: [{ value: 0, label: "Todos" }, ...states],
      opp: [{ value: 0, label: "Todos" }, ...opps],
      country: [{ value: 0, label: "Todos" }, ...countries],
    };
  }
  return {
    customer: [],
    status: [],
    opp: [],
    country: [],
  };
};

export const getFiltersOptionsEquipments = (state) => {
  if (Object.keys(state.equipmentsByRequestPlanners).length) {
    const { equipments } = state.equipmentsByRequestPlanners;
    let platforms = equipments.map((row, key) => {
      return {
        value: key + 1,
        label: row.platform,
      };
    });
    platforms = platforms
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => platforms[e])
      .map((e) => platforms[e]);
    platforms = platforms.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let engineers = equipments.map((row, key) => {
      return {
        value: key + 1,
        label: row.engineer,
      };
    });
    engineers = engineers
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => engineers[e])
      .map((e) => engineers[e]);
    engineers = engineers.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let status = equipments.map((row, key) => {
      return {
        value: key + 1,
        label: row.status,
      };
    });
    status = status
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => status[e])
      .map((e) => status[e]);
    status = status.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let countries = equipments.map((row, key) => {
      return {
        value: key + 1,
        label: row.country,
      };
    });
    countries = countries
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => countries[e])
      .map((e) => countries[e]);
    countries = countries.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return {
      platform: [{ value: 0, label: "Todos" }, ...platforms],
      engineer: [{ value: 0, label: "Todos" }, ...engineers],
      status: [{ value: 0, label: "Todos" }, ...status],
    };
  }
  return {
    platform: [],
    engineer: [],
    status: [],
  };
};

export const getEquipmentsJTRByRequest = (state) => {
  if (Object.keys(state.equipmentsByRequestPlanners).length) {
    return state.equipmentsByRequestPlanners.equipmentsJTR;
  }
  return [];
};

export const getAllDataRequirementsPlanners = (state) => {
  if (Object.keys(state.allRequerimentsPlanners).length) {
    return state.allRequerimentsPlanners.dataAssigned;
  }
  return [];
};
