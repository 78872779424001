import React, { useState } from 'react';
import { Card, CardBody, Col, Row, FormGroup, Input } from "reactstrap";
import moment from "moment";

const CompetitionEditFormOne = ({ dataModal }) => {
  const [rowsModal, setRowsModal] = useState([
    {
      colWidth: "4",
      label: "Proveedor",
      id: "vendor",
    },
    {
      colWidth: "4",
      label: "Entidad",
      id: "entity",
    },
    {
      colWidth: "4",
      label: "Convenio",
      id: "agreement",
    },
    {
      colWidth: "4",
      label: "Fecha desde",
      type: "date",
      id: "dateFrom",
    },
    {
      colWidth: "4",
      label: "Fecha de hasta",
      type: "date",
      id: "dateTo",
    },
    {
      colWidth: "4",
      label: "Fecha de registro",
      type: "date",
      id: "registrationDate",
    },
    {
      colWidth: "4",
      label: "Fecha de publicacion",
      id: "publicationDate",
      type: "date",
    }
  ]);
  return (
    <div>
      <Card>
        <CardBody >
          <Row>
            {rowsModal.map((row, key) =>
              row.type === "date" ?
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:` || ""}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      className="form-control"
                      disabled={true}
                      value={moment.utc(dataModal[row.id]).format("L") || ""}
                    />
                  </FormGroup>
                </Col>
                :

                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:` || ""}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      className="form-control"
                      disabled={true}
                      value={dataModal[row.id] || ""}
                    />
                  </FormGroup>
                </Col>
            )}
          </Row>
        </CardBody>
      </Card>

    </div>
  );
};


export default CompetitionEditFormOne;