// javascipt plugin for creating charts
import Chart from "chart.js";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin used to create charts
import { Bar, Pie } from "react-chartjs-2";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// core components
import {
  // global options for the charts
  chartOptions,
  // function that adds the global options to our charts
  parseOptions
} from "variables/charts.jsx";


class Charts extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {

    const { title, subtitle, type, bar, pie, object, dowload, onExport } = this.props;

    return (
      <Card>
        <CardHeader>
          <Row>
            <Col xs="12" md="8">
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            {
              dowload &&
              <Col xs="12" md="4" >
                <Row className="d-flex justify-content-end">
                  <Button
                    size="sm"
                    className="btn-icon"
                    color="success"
                    type="button"
                    onClick={() => onExport(type)}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-download" />
                    </span>
                    <span className="btn-inner--text">Exportar</span>
                  </Button>
                </Row>
              </Col>
            }
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {
              bar ?
                <Bar
                  data={object.data}
                  options={object.options}
                  className="chart-canvas"
                  id="chart-bar-stacked"
                /> : null
            }
            {
              pie ?
                <Pie
                  data={object.data}
                  options={object.options}
                  className="chart-canvas"
                  id="chart-pie"
                />
                : null
            }
          </div>
        </CardBody>
      </Card>
    );
  }
}

Charts.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  bar: PropTypes.bool,
  pie: PropTypes.bool,
};


export default Charts;
