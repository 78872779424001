/*!

=========================================================
*Autopp - LDRSCardSelect
=========================================================

* Componente que renderiza un card, el cual es diseñado para 
desplegar el nombre del LDR a seleccionar según los toggles en general
information.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React from 'react'

import { Card, Row, Col, CardBody, CardTitle } from "reactstrap";




export const CardSelectLDRS = React.memo(({
    handleSelectLDRForm,
    colorCardSelected,
    classNameCircle,
    classNameIcon,
    cardSelected,
    colorArrow,
    title,
    id,
}) => {

    const tempColorArrow = "text-" + colorArrow + " mr-2"
    return (

        <Col sm="auto" xs="6"             
        style={{ padding:0}}
        >
            <Card
                className="card-lift--hover card-stats p-0"
                style={{ backgroundColor: cardSelected === id ? colorCardSelected : '#ffffff', cursor: "pointer" , padding:0}}
                onClick={() => handleSelectLDRForm(id)
                }
                id={id+" cardLDR"}> 
                <CardBody >
                    <Row style={{ cursor: "pointer" }}>

                        <Col >
                            <div className={classNameCircle}>
                                <i class={classNameIcon}></i>
                            </div>
                        </Col>
                    </Row>
                    <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mt-2" 
                    >
                        {title}
                    </CardTitle>

                    <p className="mt-3 mb-0 text-sm">
                        <span className={tempColorArrow} >
                            <i className="fa fa-arrow-up" /> Abrir forms
                        </span>{" "}
                    </p>
                </CardBody>
            </Card>
        </Col>

    )

})
