
/*!
=========================================================
*Databot Main Table
=========================================================

* Componente para desplegar todos los bots y cada status.
=========================================================

* Coded by Diego Meza Castro & Eduardo Piedra Sanabria - Application Management GBM

*/

//Componentes de la líbrería de react.
import React, { useEffect, useState } from 'react';

import { MultiSelect } from "react-multi-select-component";

//Componentes de la líbrería de reactstrap.
import { Badge, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Pagination, PaginationItem, PaginationLink, Row, Table, Button, Tooltip, UncontrolledTooltip } from "reactstrap";

// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

// imports de actions
import {
    deleteBot

} from 'actions/databotDashboard';

import { useDispatch } from 'react-redux';

const MainTable = ({
    setRefreshInput,
    setFilters,
    colSelected,
    setSelected,
    openModal,
    setPage,
    setLoad,
    columns,
    rowsE,
    page,
    isAdmin,
    user,
    options
}) => {


    const [rows, setRows] = useState([])

    //Método para filtrar las filas en caso que sea un usuario funcional o empleado responsable.
    const filterRows = (localRows) => {

        if (!isAdmin) {
            let userResponsibleInBots = []

            //Encontrar los robots que tiene acceso el empleado responsable.
            if (options && Object.keys(options).length > 0) {
                if (options.responsibleEmployees != null) {
                    userResponsibleInBots = options.responsibleEmployees.filter(permission => {
                        return permission.employeeUser === user;
                    }).map(permission => permission.bot);
                }
            }

            localRows = localRows.filter(row => (row.functionalPersonUser == user || userResponsibleInBots.includes(row.id)))
        }

        return localRows;

    }

    useEffect(() => {
        setRows(filterRows(rowsE))
    }, [options, rowsE])



    //Dispatch al API.
    const dispatch = useDispatch();

    //#region Notificaciones

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    //#endregion

    //#region Pagination Table

    //Cantidad de filas por página.
    const [sizePerPage, setsizePerPage] = useState(5)

    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }
    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);

        //dispatch y traiga las otras filas del servidor
    };
    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        // setState((prevState) => ({
        //     ...prevState,
        //     sizePerPage: value
        // }))
        setsizePerPage(value)
    }
    //#endregion


    //#region
    //Función para refrescar la tabla
    const handleRefresh = (setPageOrNot) => {
        if (setPageOrNot == true || setPageOrNot == undefined) {
            setPage(1);
        }
        setRefreshInput(prevState => (!prevState))
    }

    //Capturar los valores de los inputs de los filtros
    const handleOnfilterDinamic = (constant, e) => {
        console.log(constant, e)
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }

    //Elimina un bot por su id
    const handleDeleteBot = (row) => {


        MySwal.fire({

            type: 'warning',
            title: `Eliminar robot`,
            html:

                `<h2>¿Está seguro que desea eliminar el robot ${row.class}?</h2>`,
            //

            confirmButtonText: 'Si, eliminar',
            confirmButtonColor: '#f5365c',//#2DCE89
            cancelButtonText: 'No, cancelar',
            showCancelButton: true,

            preConfirm: () => {

                setLoad(true);

                dispatch(deleteBot({ bot: row })).then((resp) => {
                    setLoad(false);

                    const { payload } = resp;
                    if (payload.status === 200) {
                        Toast.fire({
                            title: "Atención",
                            html: `¡Robot eliminado con éxito!`,
                            type: 'success'
                        });

                        // //Refresca las opciones y pone el spinner
                        handleRefresh(false);

                        // setRefreshOptions(true);
                        // setSpinnerTable(true);




                    } else {

                        Toast.fire({
                            title: "Atención",
                            html: payload.message,
                            type: 'warning'
                        });
                    };
                })


            },

        })


    }

    //#endregion

    //#region Botones de acciones en cada fila de la tabla.
    const [buttons, setButtons] = useState([
        {
            id: "editButton",
            label: "Editar",
            color: "success",
            icon: "fa fa-edit",
            func: openModal
        },
        {
            id: "deleteButton",
            label: "Eliminar",
            color: "danger",
            icon: "fa fa-trash",
            func: handleDeleteBot


        },

    ])

    //#endregion

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        {!isAdmin &&
                            <Col sm="auto" className=''>
                                <h6 className="surtitle">Databot Orchestrator</h6>
                                <h5 className="h3 mb-0">Orquestador de Robots</h5>
                            </Col>
                        }
                        <Col className=''>
                            <Row>
                                <Col xs="12" sm="10" className=''>
                                    <MultiSelect
                                        options={columns}
                                        value={colSelected}
                                        onChange={setSelected}
                                        labelledBy="Select"
                                    />
                                </Col>

                                <Col xs="12" sm="2" className='' style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        className="btn-icon"
                                        color="info"
                                        size="sm"
                                        type="button"
                                        onClick={() => handleRefresh(true)}
                                        style={{ height: "30px" }}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-sync-alt" />
                                        </span>
                                        <span className="btn-inner--text">
                                            {" "}Refrescar
                                        </span>
                                    </Button>
                                </Col>

                            </Row>


                        </Col>
                    </Row>


                </CardHeader>
                <CardBody>
                    <Table
                        style={{ whiteSpace: "normal", textAlign: "auto" }}
                        className="align-items-center table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">
                            <tr>
                                {colSelected.map((item, key) => {
                                    return (
                                        (item.value === "buttons") ?
                                            <th key={key} className="justify-content-md-center ">
                                            </th>
                                            : (item.type !== "action") ?
                                                <th key={key} className="justify-content-md-center ">
                                                    <FormGroup>
                                                        <label
                                                            style={{ fontSize: "12px" }}
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {`${item.label}:`}
                                                        </label>
                                                        <Input
                                                            id={item.id}
                                                            key={key}
                                                            className="form-control"
                                                            type="text"
                                                            placeholder={`Buscar ${item.label}...`}
                                                            onChange={(e) => handleOnfilterDinamic(item.value, e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </th>
                                                :
                                                <th className="text-center pt-0">


                                                    <FormGroup style={{ maxWidth: "30px" }} className="mt--3">


                                                        <label
                                                            style={{ fontSize: "12px" }}
                                                            className="form-control-label mt--9"
                                                            htmlFor="input-username"
                                                        >
                                                            {`${item.label}:`}
                                                        </label>


                                                    </FormGroup>
                                                    <Button
                                                        className="btn-info  btn-icon mt--3 ml-1"
                                                        color="info"
                                                        id={"helpRequestBtn" + item.id}
                                                        outline
                                                        size="sm"
                                                    >
                                                        <span className="btn-inner--icon ">
                                                            <i className="fa fa-question-circle" />
                                                        </span>
                                                    </Button>
                                                    <UncontrolledTooltip delay={0} target={"helpRequestBtn" + item.id}>
                                                        {item.legend}
                                                    </UncontrolledTooltip>




                                                </th>




                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="list">
                            {rows &&
                                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                                    <tr key={key} className="text-center">
                                        {colSelected.map((col, key2) => (

                                            col.type === "action" ?
                                                <th key={key2} >
                                                    <label className="custom-toggle custom-toggle-success">
                                                        <input disabled={!isAdmin && col.ableOnlyForAdmin} className='mb-2' type="checkbox" checked={(row[col.id] === 1) ? true : false} onChange={(e) => col.funcion(e, row, col)} />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On"></span>
                                                    </label>
                                                </th>
                                                : col.value === "buttons" ?

                                                    <th key={key2} >

                                                        {buttons.map((button, key3) => (
                                                            <>

                                                                <Button
                                                                    className="btn-icon-only"
                                                                    outline
                                                                    color={button.color}
                                                                    type="button"
                                                                    key={button.id + key + key3}
                                                                    onClick={(e) => button.func(row, "edit")}
                                                                    title={button.label}
                                                                    id={button.id + key + key3}
                                                                >
                                                                    <span className="btn-inner--icon">
                                                                        <i className={button.icon} />
                                                                    </span>
                                                                </Button>

                                                                <Tooltip key={"Tooltip" + key3} target={button.id + key + key3}>{button.label}</Tooltip>
                                                            </>
                                                        ))}
                                                    </th>

                                                    : col.value === "activeText" ?
                                                        <th key={key2} >

                                                            <Badge color={(row["active"] === 1) ? "success" : "danger"} pill>
                                                                {row[col.value]}
                                                            </Badge>
                                                        </th>


                                                        : col.type === "textarea" ?

                                                            <th key={key2} >

                                                                <Input
                                                                    key={key2}
                                                                    id={col.value}
                                                                    className="form-control text-dark"
                                                                    readOnly={false}
                                                                    value={row[col.value]}
                                                                    type={"textarea"}

                                                                />
                                                            </th>

                                                            :
                                                            <th key={key2} >

                                                                {row[col.value]}
                                                            </th>


                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </CardBody>
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Row className="align-items-center">
                            <Col xs='12' md='6' className="p-1">
                                <Row className="justify-content-start">
                                    <Col xs='12' md='6'>
                                        <span className='pagination mb-0'>
                                            Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                        </span>
                                    </Col>
                                    <Col xs='12' md='2'>
                                        <Input type="select" onChange={(e) => handleOnSetNumPagination(e)} value={sizePerPage}>
                                            <option>5</option>
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs='12' md='6' className="p-1">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {
                                        renderPaginations()
                                    }
                                    <PaginationItem className={page === paginations ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </nav>
                </CardFooter>
            </Card>
        </div>
    );
};

export default MainTable;