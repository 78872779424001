import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findAllInformationDigitalRequest = createAction(constants.GET_ALL_INFORMATIONS_DIGITAL_REQUESTS, method.get(urls.MANAGERS.getAllInformationDigitalRequest));

export const findAllDataByDigitalRequest = createAction(constants.GET_ALL_DATA_BY_DIGITAL_REQUESTS, (id) => method.get(`${urls.MANAGERS.findAllDataByDigitalRequest}/${id}`)());

export const findAllInformationCardsChart = createAction(constants.GET_ALL_INFORMATION_CARDS_AND_CHART, (year,country) => method.get(`${urls.MANAGERS.getAllInformationCardsChart}/${year}/${country}`)());

export const findAllInformationYearAndContries = createAction(constants.GET_ALL_INFORMATION_YEAR_AND_COUNTRIES, method.get(urls.MANAGERS.getInformationYearAndCountries));

