// javascript plugin that creates nice dropzones for files
import { Spin } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form as FormRS,
  FormGroup,
  Input,
  Col,
  Label,
  Modal,
} from "reactstrap";

const SupplierModal = (props) => {
  const {
    initialValues,
    message,
    loading,
    showModal,
    toggleModal,
    onWorkFlow,
  } = props;

  const validate = (values) => {
    const errors = {};
    if (!values.supplierName || values.supplierName === "")
      errors.supplierName = "Debes ingresar el nombre del proveedor";
    if (!values.name || values.name === "")
      errors.name =
        "Debes ingresar el nombre de la persona con la que se tiene la relación";
    if (!values.relationType || values.relationType === "")
      errors.relationType = "Debes ingresar el tipo de relación";
    if (!values.relationType || values.relationType === "")
      errors.relationType =
        "Debes ingresar el cargo de la persona con la que se tiene la relación";
    if (!values.position || values.position === "")
      errors.position =
        "Debes ingresar el cargo de la persona con la que se tiene la relación";
    if (!values.supplierInfo || values.supplierInfo === "")
      errors.supplierInfo =
        "Debes ingresar que servicio le brinda el proveedor a GBM";
    if (
      !values.isSupplierContractingLink ||
      values.isSupplierContractingLink === "0"
    )
      errors.isSupplierContractingLink = "Debes seleccionar una opción";
    if (!values.hasSupplierBenefit || values.hasSupplierBenefit === "0")
      errors.hasSupplierBenefit = "Debes seleccionar una opción";
    if (values.hasSupplierBenefit === "1") {
      if (!values.benefitDescription || values.benefitDescription === "")
        errors.benefitDescription = "Debes ingresar el detalle";
    }
    return errors;
  };
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>Crear Proveedor en Conflicto</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onWorkFlow}
              validate={validate}
              initialValues={initialValues}
              render={({
                handleSubmit,
                values,
                submitting,
                validating,
                valid,
              }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="supplierName"
                          >
                            Nombre del Proveedor
                          </Label>
                          <Field name="supplierName">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese el nombre del proveedor"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="name">
                            Nombre de la persona con la que se tiene la relación
                          </Label>
                          <Field name="name">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese el nombre de la persona"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="relationType"
                          >
                            Tipo de Relación
                          </Label>
                          <Field name="relationType">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese el tipo de la relación"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="position">
                            Cargo de la persona con la que se tiene la relación
                          </Label>
                          <Field name="position">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese el cargo de la persona"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="supplierInfo"
                          >
                            ¿Que servicio le brinda el proveedor a GBM?
                          </Label>
                          <Field name="supplierInfo">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese que servicio le brinda el proveedor a GBM"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="isSupplierContractingLink"
                          >
                            ¿Tiene usted desde su puesto de trabajo en GBM
                            participación en la contratación del proveedor?
                          </Label>
                          <Field name="isSupplierContractingLink">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="1">Sí</option>
                                  <option value="2">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            for="hasSupplierBenefit"
                          >
                            ¿Posee usted algun tipo de relación laboral o
                            beneficio económico con ese proveedor?
                          </Label>
                          <Field name="hasSupplierBenefit">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="1">Sí</option>
                                  <option value="2">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      {parseInt(values.hasSupplierBenefit) === 1 && (
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              for="benefitDescription"
                            >
                              Detalle el tipo de relación laboral o beneficio
                              económico con ese proveedor
                            </Label>
                            <Field name="benefitDescription">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="text"
                                    placeholder="Ingrese el detalle"
                                    max={250}
                                    valid={
                                      !meta.error &&
                                      meta.modified &&
                                      meta.touched
                                    }
                                    invalid={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <span className="invalid-feedback">
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      )}
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="comments">
                            Comentarios, si lo requiere
                          </Label>
                          <Field name="comments">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  placeholder="Escriba aquí los comentarios."
                                  maxLength={500}
                                  rows="3"
                                  resize="none"
                                  valid={
                                    !meta.error && meta.modified && meta.touched
                                  }
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center justify-content-center">
                      <Col className="col-auto">
                        <Button
                          disabled={validating}
                          className="btn-icon"
                          block
                          color="success"
                          // type="submit"
                          onClick={handleSubmit}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-signature" />
                          </span>
                          <span className="btn-inner--text">
                            Crear Proveedor
                          </span>
                        </Button>
                      </Col>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

SupplierModal.propTypes = {};

export default SupplierModal;
