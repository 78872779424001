/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

moment.locale("es");

const CriticalParts = (props) => {
  const {
    title,
    subtitle,
    isSuperAdmin,
    isLimitedAdmin,
    cols,
    rows,
    length,
    page,
    sizePerPage,
    paginations,
    onSetPage,
    onToggle,
    onEdit,
    onDelete,
    onExport,
  } = props;

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink onClick={() => onSetPage(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return options.length >= 5
      ? page >= 5
        ? options.slice(page - 3, page + 2)
        : options.slice(0, 5)
      : options;
  };

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="12" md="7">
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
              <Row className="d-flex justify-content-end">
                <Col>
                  <Button
                    size="sm"
                    className="btn-icon"
                    color="success"
                    type="button"
                    onClick={onExport}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-download" />
                    </span>
                    <span className="btn-inner--text">Exportar</span>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col className="text-right" xs="12" md="5">
              <Row>
                {(isSuperAdmin || isLimitedAdmin) && (
                  <>
                    <Col
                      xs="12"
                      md={isLimitedAdmin ? "6" : "4"}
                      className="p-1"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        className="btn-neutral btn-round btn-icon"
                        color="success"
                        id="add"
                        onClick={() => onToggle("createPart")}
                        size="sm"
                        block
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-folder-plus" />
                        </span>
                        <span className="btn-inner--text">Agregar</span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="add">
                        Agregar una parte critica a la matriz
                      </UncontrolledTooltip>
                    </Col>
                    <Col
                      xs="12"
                      md={isLimitedAdmin ? "6" : "4"}
                      className="p-1"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        className="btn-neutral btn-round btn-icon"
                        color="success"
                        id="load"
                        onClick={() => onToggle("loadPart")}
                        size="sm"
                        block
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-file-excel" />
                        </span>
                        <span className="btn-inner--text">Cargar</span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="load">
                        Cargar partes criticas a la matriz
                      </UncontrolledTooltip>
                    </Col>
                  </>
                )}
                {isSuperAdmin && (
                  <Col
                    xs="12"
                    md="4"
                    className="p-1"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      className="btn-neutral btn-round btn-icon"
                      color="success"
                      id="models"
                      onClick={() => onToggle("debuggedModels")}
                      size="sm"
                      block
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-settings" />
                      </span>
                      <span className="btn-inner--text">Depurados</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="models">
                      Gestión de los modelos depurados
                    </UncontrolledTooltip>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive striped>
          <thead className="thead-light">
            <tr>
              {cols.map((row, key) => {
                return <th key={key}>{row.name}</th>;
              })}
              {(isSuperAdmin || isLimitedAdmin) && <th />}
            </tr>
          </thead>
          <tbody className="list">
            {rows.length ? (
              rows.map((row, key) => {
                return (
                  <tr key={key}>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.engineer}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.modelType}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.platform}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.family}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.category}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.description}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.redundancy}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.classify}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.updatedBy ? row.updatedBy : row.createdBy}
                    </td>
                    <td>
                      {moment(row.updatedAt)
                        .utc()
                        .utcOffset(moment().utcOffset())
                        .format("DD/MM/YYYY hh:mm:ss A")}
                    </td>
                    {(isSuperAdmin || isLimitedAdmin) && (
                      <td className="table-actions">
                        <a
                          className="table-action table-action"
                          onClick={() => onEdit(row)}
                          href="#"
                          id="edit"
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          className="table-action table-action-delete"
                          onClick={() => onDelete(row)}
                          href="#"
                          id="reject"
                        >
                          <i className="fas fa-trash" />
                        </a>
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="justify-content-md-center">
                  Sin resultados encontrados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {length > 0 ? (
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Row className="align-items-center">
                <Col xs="12" md="6" className="p-1">
                  <span className="pagination mb-0">
                    Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                    {page * sizePerPage > length ? length : page * sizePerPage}{" "}
                    de {length} resultados
                  </span>
                </Col>
                <Col xs="12" md="6" className="p-1">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                      <PaginationLink
                        onClick={() => onSetPage(page === 1 ? page : page - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {renderPaginations()}
                    <PaginationItem
                      className={page === paginations ? "disabled" : ""}
                    >
                      <PaginationLink
                        onClick={() =>
                          onSetPage(page === paginations ? page : page + 1)
                        }
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </Col>
              </Row>
            </nav>
          </CardFooter>
        ) : null}
      </Card>
    </>
  );
};

CriticalParts.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default CriticalParts;
