/* eslint-disable no-loop-func */
// javascript library for dates
import moment from 'moment';
import "moment/locale/es";

moment.locale("es");

export const getAvailableFilters = state => state.filters;

export const getFiltersData = state => { return {} };

export const getDataChart = (state) => {
  if (Object.keys(state.dataChartPlannedRealHours).length) {
    const { hoursReported, plannedHours } = state.dataChartPlannedRealHours;
    let labels = [];
    const planned = [];
    const reported = []
    hoursReported.forEach(element => {
      labels.push(element.name);
    });
    plannedHours.forEach(element => {
      labels.push(element.name);
    });
    labels = [...new Set(labels)];
    labels.forEach(element => {
      planned.push(0);
      reported.push(0);
    });
    hoursReported.forEach(element => {
      const index = labels.findIndex((row) => row === element.name);
      reported[index] = Math.round(element.realHours * 100) / 100;
    });
    plannedHours.forEach(element => {
      const index = labels.findIndex((row) => row === element.name);
      planned[index] = Math.round(element.plannedHours * 100) / 100;
    });
    return {
      labels,
      datasets: [
        {
          label: "Horas Reales",
          data: reported,
          backgroundColor: "#f5365c"
        },
        {
          label: "Horas Planeadas",
          data: planned,
          backgroundColor: "#5e72e4"
        },
      ]
    }
  }
  return {
    labels: [],
    datasets: [
      {
        label: "Horas Reales",
        data: [],
        backgroundColor: "#f5365c"
      },
      {
        label: "Horas Planeadas",
        data: [],
        backgroundColor: "#5e72e4"
      },
    ]
  };
};

export const getHistoryDataChart = (state) => {
  // const labels = ['L', 'K', 'M', 'J', 'V', 'S', 'D'];
  let labels = [];
  // const realHours = [0, 0, 0, 0, 0, 0, 0];
  const realHours = [];
  // const plannedHour = [0, 0, 0, 0, 0, 0, 0];
  const plannedHour = [];
  if (Object.keys(state.dataChartHistory).length) {
    const { hoursReported, plannedHours } = state.dataChartHistory;
    hoursReported.forEach(element => {
      labels.push(element.day);
    });
    plannedHours.forEach(element => {
      for (let i = element.startDay; i <= element.endDay; i++) {
        labels.push(i);
      }
    });

    // Delete duplicates
    labels = [...new Set(labels)]

    // Sort array
    labels.sort((a, b) => a - b);
    labels.forEach(element => {
      plannedHour.push(0);
      realHours.push(0);
    });
    // hoursReported.forEach((row) => {
    //   labels.push(row.day);
    //   // realHours[row.day] = row.realHours;
    //   realHours.push(row.realHours);
    //   // plannedHours[row.day] = row.plannedHours;
    // });
    // plannedHours.forEach((row) => {
    //   // realHours[row.day] = row.realHours;
    //   plannedHour[row.day] = row.plannedHours;
    // });
    hoursReported.forEach(element => {
      const index = labels.findIndex((row) => row === element.day);
      realHours[index] = Math.round(element.realHours * 100) / 100;
    });
    let count = 0;
    plannedHours.forEach(element => {
      count = element.startDay;
      while (count <= element.endDay) {
        const index = labels.findIndex((row) => {
          return row === count;
        });
        // console.log("INDEX:", index);
        plannedHour[index] = Math.round((plannedHour[index] + element.plannedHours) * 100) / 100;
        count++;
        // console.log("COUNT: ", count);
      }
    });
  }
  return {
    labels,
    datasets: [
      {
        label: "Horas Reales",
        data: realHours,
        borderColor: "#f5365c",
        backgroundColor: '#f5365c',
        fill: false,
        pointRadius: 4,
      },
      {
        label: "Horas Planeadas",
        data: plannedHour,
        borderColor: "#5e72e4",
        backgroundColor: '#5e72e4',
        fill: false,
        pointRadius: 4,
      },
    ]
  };
};

export const getIndicatorDataChart = state => {
  if (Object.keys(state.dataChartIndicator).length) {
    let {
      labels,
      hoursReported,
      plannedHours,
      effectiveness,
      actualAvailability,
      availabilityPlanned
    } = state.dataChartIndicator;

    return {
      labels,
      datasets: [
        {
          label: "Horas Planeadas",
          data: plannedHours,
          backgroundColor: "#5e72e4"
        },
        {
          label: "Horas Reales",
          data: hoursReported,
          backgroundColor: "#f5365c"
        },
        {
          label: "Eficiencia",
          data: effectiveness,
          backgroundColor: "#fb6340"
        },
        {
          label: "Disponibilidad Planeada",
          data: availabilityPlanned,
          backgroundColor: "#11cdef"
        },
        {
          label: "Disponibilidad Real",
          data: actualAvailability,
          backgroundColor: "#2dce89"
        }
      ]
    }
  }
  return {
    labels: [],
    datasets: [
      {
        label: "Horas Planeadas",
        data: [],
        backgroundColor: "#5e72e4"
      },
      {
        label: "Horas Reales",
        data: [],
        backgroundColor: "#f5365c"
      },
      {
        label: "Eficiencia",
        data: [],
        backgroundColor: "#fb6340"
      },
      {
        label: "Disponibilidad Planeada",
        data: [],
        backgroundColor: "#11cdef"
      },
      {
        label: "Disponibilidad Real",
        data: [],
        backgroundColor: "#2dce89"
      }
    ]
  }
};

export const getEffectivenessPercent = state => {
  if (Object.keys(state.dataChartIndicator).length) {
    const {
      effectiveness
    } = state.dataChartIndicator;
    let percent = 0;
    effectiveness.forEach(element => {
      percent = Math.round((percent + element) * 100) / 100;
    });
    const totalPercent = Math.round((percent / effectiveness.length) * 100) / 100;
    const available = totalPercent > 100 ? 0 : 100 - totalPercent;
    return {
      data: {
        labels: [1, 2],
        datasets: [
          {
            label: "Disponibilidad",
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 0,
            data: [
              totalPercent,
              available
            ],
            backgroundColor: ["#fb6340", "#f4f3ef"],
          }
        ]
      },
      options: {
        elements: {
          center: {
            text: "80%",
            color: "#66615c", // Default is #000000
            fontStyle: "Arial", // Default is Arial
            sidePadding: 60 // Defualt is 20 (as a percentage)
          },
        },
        title: {
          display: true,
          text: `${totalPercent}%`,
          color: "#66615c", // Default is #000000
          fontStyle: "Arial", // Default is Arial
          fontSize: 12
        },
        cutoutPercentage: 90,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)"
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false
              }
            }
          ]
        }
      }
    }
  }
  return {
    data: {
      labels: [1, 2],
      datasets: [
        {
          label: "Disponibilidad",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 0,
          data: [
            0,
            100
          ],
          backgroundColor: ["#f5365c", "#f4f3ef"],
        }
      ]
    },
    options: {
      elements: {
        center: {
          text: "80%",
          color: "#66615c", // Default is #000000
          fontStyle: "Arial", // Default is Arial
          sidePadding: 60 // Defualt is 20 (as a percentage)
        },
      },
      title: {
        display: true,
        text: `0%`,
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        fontSize: 12
      },
      cutoutPercentage: 90,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              display: false
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)"
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    }
  }
}

export const getAvailabilityPercent = state => {
  if (Object.keys(state.dataChartIndicator).length) {
    const {
      actualAvailability,
    } = state.dataChartIndicator;
    let percent = 0;
    actualAvailability.forEach(element => {
      percent = Math.round((percent + element) * 100) / 100;
    });
    const totalPercent = Math.round((percent / actualAvailability.length) * 100) / 100;
    const available = totalPercent > 100 ? 0 : 100 - totalPercent;
    return {
      data: {
        labels: [1, 2],
        datasets: [
          {
            label: "Disponibilidad",
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 0,
            data: [
              totalPercent,
              available
            ],
            backgroundColor: ["#2dce89", "#f4f3ef"],
          }
        ]
      },
      options: {
        elements: {
          center: {
            text: "80%",
            color: "#66615c", // Default is #000000
            fontStyle: "Arial", // Default is Arial
            sidePadding: 60 // Defualt is 20 (as a percentage)
          },
        },
        title: {
          display: true,
          text: `${totalPercent}%`,
          color: "#66615c", // Default is #000000
          fontStyle: "Arial", // Default is Arial
          fontSize: 12
        },
        cutoutPercentage: 90,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)"
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false
              }
            }
          ]
        }
      }
    }
  }
  return {
    data: {
      labels: [1, 2],
      datasets: [
        {
          label: "Disponibilidad",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 0,
          data: [
            0,
            100
          ],
          backgroundColor: ["#f5365c", "#f4f3ef"],
        }
      ]
    },
    options: {
      elements: {
        center: {
          text: "80%",
          color: "#66615c", // Default is #000000
          fontStyle: "Arial", // Default is Arial
          sidePadding: 60 // Defualt is 20 (as a percentage)
        },
      },
      title: {
        display: true,
        text: `0%`,
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        fontSize: 12
      },
      cutoutPercentage: 90,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              display: false
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)"
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    }
  }
}

export const getHoursReportedPercent = state => {
  if (Object.keys(state.dataChartIndicator).length) {
    const {
      hoursReported,
      plannedHours
    } = state.dataChartIndicator;
    let totalReported = 0;
    let totalPlanned = 0;
    hoursReported.forEach(element => {
      totalReported = Math.round((totalReported + element) * 100) / 100;
    });
    plannedHours.forEach(element => {
      totalPlanned = Math.round((totalPlanned + element) * 100) / 100;
    });
    const totalPercent = totalPlanned > 0 ? Math.round(((totalReported * 100) / totalPlanned) * 100) / 100 : 0;
    const available = totalPercent > 100 ? 0 : 100 - totalPercent;
    return {
      data: {
        labels: [1, 2],
        datasets: [
          {
            label: "Disponibilidad",
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 0,
            data: [
              totalPercent,
              available
            ],
            backgroundColor: ["#f5365c", "#f4f3ef"],
          }
        ]
      },
      options: {
        elements: {
          center: {
            text: "80%",
            color: "#66615c", // Default is #000000
            fontStyle: "Arial", // Default is Arial
            sidePadding: 60 // Defualt is 20 (as a percentage)
          },
        },
        title: {
          display: true,
          text: `${totalPercent}%`,
          color: "#66615c", // Default is #000000
          fontStyle: "Arial", // Default is Arial
          fontSize: 12
        },
        cutoutPercentage: 90,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)"
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false
              }
            }
          ]
        }
      }
    }
  }
  return {
    data: {
      labels: [1, 2],
      datasets: [
        {
          label: "Disponibilidad",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 0,
          data: [
            0,
            100
          ],
          backgroundColor: ["#5e72e4", "#f4f3ef"],
        }
      ]
    },
    options: {
      elements: {
        center: {
          text: "80%",
          color: "#66615c", // Default is #000000
          fontStyle: "Arial", // Default is Arial
          sidePadding: 60 // Defualt is 20 (as a percentage)
        },
      },
      title: {
        display: true,
        text: `0%`,
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        fontSize: 12
      },
      cutoutPercentage: 90,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              display: false
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)"
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    }
  }
}

export const getCollaboratorsReported = state => {
  if (Object.keys(state.collaboratorsReported).length) {
    const { users } = state.collaboratorsReported;
    return users.map((row, key) => {
      row.id = key;
      // row.reportDate = moment(row.reportDate)
      //  .utc()
      //  .utcOffset(moment().utcOffset())
      //  .format("L");
      // row.createdDate = moment(row.createdDate);
      // row.reportDate = moment(row.reportDate)
      //   .utc()
      //   .utcOffset(moment().utcOffset())
      //   .format("L");
      row.createdDate = row.createdDate === 'N/A' ? 'N/A' : moment(row.createdDate)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("L");
      row.estimatedEndDate = row.estimatedEndDate === 'N/A' ? row.estimatedEndDate : moment(row.estimatedEndDate)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("L");
      row.actualEndDate = row.actualEndDate === 'N/A' ? row.actualEndDate : moment(row.actualEndDate)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("L");
      return row;
    });
  }
  return [];
}

export const getAllCoEs = state => {
  if (Object.keys(state.collaboratorsReported).length) {
    const { users } = state.collaboratorsReported;
    let coes = users.map((row, key) => {
      return {
        value: key + 1,
        label: row.coeName,
        equipment: row.equipmentName
      }
    });

    coes = coes.map(e => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => coes[e]).map(e => coes[e]);

    coes = coes.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...coes];
  }

  return [];
}

export const getAllEquipments = state => {
  if (Object.keys(state.collaboratorsReported).length) {
    const { users } = state.collaboratorsReported;
    let equipments = users.map((row, key) => {
      return {
        value: key + 1,
        label: row.equipmentName,
        coe: row.coeName
      }
    });

    equipments = equipments.map(e => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => equipments[e]).map(e => equipments[e]);

    equipments = equipments.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...equipments];
  }

  return [];
}

export const getHoursAccused = state => {
  if (Object.keys(state.hoursAccused).length) {
    const { hours } = state.hoursAccused;
    return hours.map((row, key) => {
      row.id = key;
      row.Rep_ItemFecha = moment(row.Rep_ItemFecha)
        // .utc()
        // .utcOffset(moment().utcOffset())
        .format("DD/MM/YYYY");
      return row;
    });
  }
  return [];
};

export const getAllTypeActivities = state => {
  if (Object.keys(state.collaboratorsReported).length) {
    const { users } = state.collaboratorsReported;
    let typeActivities = users.map((row, key) => {
      return {
        value: row.key + 1,
        label: row.typeActivity
      }
    });

    typeActivities = typeActivities.map(e => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => typeActivities[e]).map(e => typeActivities[e]);

    typeActivities = typeActivities.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...typeActivities];
  }

  return [];
};

export const getHoursByCollaborator = state => {
  if (Object.keys(state.collaboratorsReported).length) {
    const { users } = state.collaboratorsReported;
    const hoursByUser = [];
    console.log(users);
    for (const element of users) {
      const index = hoursByUser.findIndex((row) => row.username === element.username && row.typeActivity === element.typeActivity);
      const { username, name, coeName, equipmentName, hoursReported, typeActivity } = element;
      if (index === -1) {
        hoursByUser.push({
          id: hoursByUser.length,
          username,
          name,
          coeName,
          equipmentName,
          hoursReported,
          typeActivity
        });
      } else {
        hoursByUser[index].hoursReported = Math.round((hoursByUser[index].hoursReported + hoursReported) * 100) / 100;
      }
    }
    return hoursByUser.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
  return [];
};

export const getValuesFormMaintenance = state => {
  if (Object.keys(state.optionsFormMaintenance).length) {
    const { coes, equipments, countries, specialities } = state.optionsFormMaintenance;
    return {
      coes,
      equipments,
      countries,
      specialities
    };
  }
  return {
    coes: [],
    equipments: [],
    countries: [],
    specialities: []
  };
};

export const getActivitiesReport = state => {
  if (Object.keys(state.activitiesReport).length) {
    const { report } = state.activitiesReport;
    return report.map((row, key) => {
      row.id = key;
      row.Rep_ItemFecha = moment(row.Rep_ItemFecha).format("DD/MM/YYYY");
      row.Rep_Fecha = moment(row.Rep_Fecha).format("DD/MM/YYYY");
      return row;
    });
  }
  return [];
};

export const getYearsActivitiesReport = state => {
  if (Object.keys(state.activitiesReport).length) {
    const { years } = state.activitiesReport;
    const yearsList = [];
    years.map((row) => {
      yearsList.push({ value: row.year, label: row.year })
      return row;
    });
    return yearsList;

  }
  return [
    { value: moment().year(), label: moment().year() }
  ];
};

export const getInitialValuesForm = state => {
  if (Object.keys(state.userCreated).length) {
    const { initialValues } = state.userCreated;
    return initialValues;
  }
  return {
    coe: 0,
    country: 0,
    equipment: 0,
    idColab: null,
    username: "",
    fullname: "",
    email: "",
    email2: "",
    speciality: 0,
    lider: false,
  };
};

export const getAllEmaUsersActive = state => {
  if (Object.keys(state.allUsers).length) {
    return state.allUsers;
  }
  return [];
};