// core ant desing
import { Steps, Spin, Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// reactstrap components
import { Button, Card, CardBody, CardImg, CardTitle, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal } from "reactstrap";

const { Step } = Steps;

const CovidEvent = props => {

  const {
    page,
    event,
    open,
    loading,
    toggleModal,
    onChangePage,
    onCancel,
    onSubmit
  } = props;

  const [type, setType] = useState(event.type && event.type === "vacations" ? 2 : event.type === "monetary" ? 1 : 0);
  const [amount, setAmount] = useState(0);
  const [days, setDays] = useState(0);
  const [month, setMonth] = useState(1);
  const [enabled, setEnabled] = useState(false);
  const [message, setMessage] = useState("");

  const steps = [
    {
      title: "¿Quieres Donar?"
    },
    {
      title: "Realizar Donación"
    },
    {
      title: "¡Muchas Gracias!"
    },
  ];

  const onChangeValue = event => {
    const { id, value } = event.target;
    if (id === "amount" && (parseInt(value) >= 0 || value === "")) {
      setAmount(parseInt(value));
    } else if (id === "days" && parseInt(value) >= 0 && parseInt(value) <= 15) {
      setDays(parseInt(value));
    } else if (id === "month" && parseInt(value) >= 0 && parseInt(value) <= 2) {
      setMonth(parseInt(value));
    } else if (id === "message") {
      setMessage(value);
    } else {
      setEnabled(value === "false" ? true : false);
    }
  };

  const convertUnicode = (input) => {
    return input.replace(/\\u(\w{4,4})/g, function (a, b) {
      var charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  };

  const renderFirstPage = () => (
    <>
      <blockquote className="blockquote text-center">
        <p className="mb-1 mt-1 lead">
          {event.description}
        </p>
        <footer className="blockquote-footer">
          {event.message}.
      </footer>
      </blockquote>
      <div className="custom-control custom-checkbox mb-3 justify-content-center" style={{ display: 'flex' }}>
        <input
          className="custom-control-input"
          id="enabled"
          type="checkbox"
          defaultChecked={enabled}
          defaultValue={enabled}
          onChange={(e) => onChangeValue(e)}
        />
        <label
          className="custom-control-label"
          htmlFor="enabled"
        >
          No volver a mostrar este mensaje
      </label>
      </div>
    </>
  );

  const renderSecondPage = () => (
    <div>
      <Form>
        <div className="form-row align-items-center">
          <Col className="mb-1" xs="12" md="4">
            <div className="custom-control custom-radio mb-3">
              <input
                className="custom-control-input"
                id="monetary"
                type="radio"
                name="custom-radio-1"
                disabled={event.type === "vacations"}
                defaultChecked={event.type === "monetary"}
                onChange={() => setType(1)}
              />
              <label
                className="custom-control-label"
                htmlFor="monetary"
              >
                Donación Monetaria
              </label>
            </div>
            <div className="custom-control custom-radio mb-3">
              <input
                className="custom-control-input"
                id="vacations"
                type="radio"
                name="custom-radio-1"
                disabled={event.type === "monetary"}
                defaultChecked={event.type === "vacations"}
                onChange={() => setType(2)}
              />
              <label
                className="custom-control-label"
                htmlFor="vacations"
              >
                Donación de Vacaciones
              </label>
            </div>
          </Col>
          {
            (event.type === "monetary" || type === 1) &&
            <>
              <Col className="mb-1" xs="12" md="4">
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="amount"
                  >
                    Monto a Donar
            </Label>
                  {
                    Object.keys(event.currency).length ?
                      <InputGroup
                        className="form-control-muted"
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i>{convertUnicode(event.currency.unicode_icon)}</i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          className="m-0"
                          id="amount"
                          type="number"
                          value={amount}
                          onChange={(e) => onChangeValue(e)}
                          invalid={amount === 0}
                          valid={amount > 0}
                        />
                      </InputGroup>
                      :
                      <Input
                        required
                        className="m-0"
                        id="amount"
                        type="number"
                        value={amount}
                        onChange={(e) => onChangeValue(e)}
                        invalid={amount === 0}
                        valid={amount > 0}
                      />
                  }
                </FormGroup>
              </Col>
              {/* <Col className="mb-1" xs="12" md="4">
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="month"
                  >
                    Cantidad de Meses
            </Label>
                  <InputGroup
                    className="form-control-muted"
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-calendar" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      className="m-0"
                      id="month"
                      type="number"
                      value={month}
                      onChange={(e) => onChangeValue(e)}
                      invalid={month === 0}
                      valid={month > 0}
                    />
                  </InputGroup>
                </FormGroup>
              </Col> */}
            </>
          }
          {
            (event.type === "vacations" || type === 2) &&
            <Col className="mb-1" xs="12" md="4">
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="month"
                >
                  Cantidad de Días
                </Label>
                <InputGroup
                  className="form-control-muted"
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-calendar" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    className="m-0"
                    id="days"
                    type="number"
                    value={days}
                    onChange={(e) => onChangeValue(e)}
                    invalid={days === 0}
                    valid={days > 0}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          }
        </div>
        <div className="form-row align-items-center">
          <Col className="mb-1" xs="12" md="12">
            <FormGroup className="pr-2">
              <Label
                className="form-control-label"
                htmlFor="message"
              >
                Mensaje Solidario
            </Label>
              <Input
                id="message"
                rows="2"
                resize="none"
                type="textarea"
                placeholder="Escriba aquí si tienes algún mensaje de solidaridad. (Opcional)"
                value={message}
                onChange={(e) => onChangeValue(e)}
              />
            </FormGroup>
          </Col>
        </div>
      </Form>
    </div>
  );

  const renderThirdPage = () => (
    <Result
      className="p-3"
      icon={<SmileOutlined />}
      title={<span className="h3">Somos los que hacemos la diferencia</span>}
      subTitle={<p className="mt-0 mb-0 lead"><em>GBM te agradece la ayuda que has realizado.</em></p>}
    />
  )

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={open}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardImg
              alt="Icono de Evento"
              src={require(`assets/img/theme/solidarity-event.jpg`)}
              top
            />
            <Steps current={page} className="px-md-2 py-md-2">
              {
                steps.map((row, key) => {
                  return (
                    <Step key={key} title={row.title} />
                  )
                })
              }
            </Steps>
            <div>
              <Spin size="large" spinning={loading}>
                <CardBody className="px-md-2 py-md-2">
                  <CardTitle className="h2 mb-1 text-center">{event.name}</CardTitle>
                  {
                    page === 0 ?
                      renderFirstPage()
                      :
                      page === 1 ?
                        renderSecondPage()
                        : renderThirdPage()
                  }
                </CardBody>
              </Spin>
            </div>
            <div className="form-row align-items-center" style={{ justifyContent: "flex-end" }}>
              {
                page === 0 ?
                  <Col className="m-1" xs="12" md="2">
                    <Button
                      outline
                      block
                      size="sm"
                      color="primary"
                      onClick={() => onCancel(enabled)}
                    >
                      Cancelar
                      </Button>
                  </Col>
                  :
                  page === steps.length - 1 ?
                    null
                    :
                    <Col className="m-1" xs="12" md="2">
                      <Button
                        outline
                        block
                        size="sm"
                        color="primary"
                        onClick={() => onChangePage('back')}
                      >
                        Regresar
                      </Button>
                    </Col>
              }
              {
                page === 1 ?
                  <Col className="m-1" xs="12" md="2">
                    <Button
                      outline
                      block
                      size="sm"
                      color="primary"
                      onClick={() => onSubmit(amount, days, month, message, page, type)}
                    >
                      Donar
                    </Button>
                  </Col>
                  :
                  page === steps.length - 1 ?
                    <Col className="m-1" xs="12" md="2">
                      <Button
                        outline
                        block
                        size="sm"
                        color="primary"
                        onClick={() => toggleModal("notificationEvent")}
                      >
                        Terminar
                      </Button>
                    </Col>
                    :
                    <Col className="m-1" xs="12" md="2">
                      <Button
                        outline
                        block
                        size="sm"
                        color="primary"
                        onClick={() => onChangePage('next')}
                      >
                        Continuar
                      </Button>
                    </Col>
              }
            </div>
          </Card>
        </div>
      </Modal>
    </>
  );
};

CovidEvent.propTypes = {
  event: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default CovidEvent;