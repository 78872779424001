/*
=========================================================
*Hours Report - OS Network
=========================================================

*Description.
Component of a button that allows user to create a OS request for a specific item.
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { RiFileAddFill } from "react-icons/ri";

function SelectItemButton({ onSelect, item }) {
  return (
    <div
      style={{
        whiteSpace: "normal",
        textAlign: "justify",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <div>
        <Button
          className="btn-icon"
          color="success"
          size="sm"
          type="button"
          style={{
            backgroundColor: "rgb(45, 206, 137)",
            color: "white",
            height: "2rem",
            display: "flex",
            alignItems: "center",
            borderRadius: "10px",
            justifyContent: "center",
          }}
          onClick={() => onSelect(item)}
        >
          <span className="btn-inner--text">
            <RiFileAddFill
              style={{ color: "white", fontSize: "15px", marginBottom: "3px" }}
            />{" "}
            Crear reporte
          </span>
        </Button>
      </div>
    </div>
  );
}

SelectItemButton.propTypes = {
  onSelect: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
export default SelectItemButton;
