import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from "react-router-dom";
import { CostaRicaBidsRoutes } from "routes/routes.jsx";

const Index = () => {

const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/CostaRicaBids") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Switch>
        {
          getRoutes(CostaRicaBidsRoutes)
        }
        <Redirect from="/admin/CostaRicaBids" to="/admin/CostaRicaBids" />
      </Switch>
      <p className="lead"></p>
    </>
  );
};

export default Index;