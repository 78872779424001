/*!

=========================================================
*Autopp - ModalRequestBAW
=========================================================

* Este componente - modal despliega el status de cada requerimiento
de BAW
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Librerías de react.
import React, { useState } from "react";
import { useEffect } from "react";

//Import de Spinner
import { Spin } from "antd";

import {
  ModalFooter,
  ModalHeader,
  ModalBody,
  Button,
  Table,
  Badge,
  Modal,
} from "reactstrap";
import BawDevolutionForm from "../Baw/BawDevolutionForm";

export const ModalRequestBAW = ({
  setReloadTableRequests,
  setModalBAW,
  modalBAW,
}) => {
  //console.log(modalBAW);

  //#region states Locales
  const [stateBAW] = useState({
    unmountOnClose: true,
    fileList: [],
    idFileRequest: "",
    readyToSend: false,
  });

  //Establecer si aparezca la tabla el BAW Devolution Form
  const [showTableOrBawForm, setShowTableOrBawForm] = useState("table");

  //State para poner spinner cuando se envió el requerimiento a esoecialista, y se pasa a la vista de request.
  const [loadingChangingView, setLoadingChangingView] = useState(false);

  //Mostrar de manera predefinida la tabla
  useEffect(() => {
    setShowTableOrBawForm("table");
  }, [modalBAW.showBAWModal]);

  //Guarda los datos del requirimiento BAW a editar.
  const [requirementInfoDevolution, setRequirementInfoDevolution] = useState();

  const [columnsSelectedModalBAW] = useState([
    { value: "buttonsActions", label: "action" },
    { value: "id", label: "id" },
    { value: "statusName", label: "Estado" },
    { value: "caseNumber", label: "Número de caso" },
    { value: "specialist", label: "Especialista a cargo" },
    { value: "vendor", label: "Proveedor" },
    { value: "product", label: "Product" },
    { value: "requirementType", label: "Tipo de requerimiento" },
    { value: "quantity", label: "Cantidad" },
    { value: "comments", label: "Comentarios" },
    { value: "createdByUser", label: "Creado por" },
    { value: "createdAt", label: "Creado en" },
  ]);

  //#endregion

  //#region Handles
  const handleCloseModal = () => {
    setReloadTableRequests(true);

    setModalBAW((prevState) => ({
      ...prevState,
      length: 0,
      showBAWModal: false,
      rowsBAW: [],
    }));
  };

  //Handle para establacer vista de requests, o vista de formulario de devolución de BAW
  const handleSetTableOrBawFormDevolution = (row) => {
    //console.log(row);
    if (showTableOrBawForm === "table") {
      setShowTableOrBawForm("bawFormDevolution");
      setRequirementInfoDevolution(row);
    }

    if (showTableOrBawForm === "bawFormDevolution") {
      setRequirementInfoDevolution(row);
      setShowTableOrBawForm("table");
    }
  };
  //#endregion

  return (
    <>
      <Modal
        size="xl"
        style={{ maxWidth: "1500px", width: "100%" }}
        isOpen={modalBAW.showBAWModal}
        unmountOnClose={stateBAW.unmountOnClose}
        toggle={handleCloseModal}
      >
        <Spin size="large" spinning={loadingChangingView}>
          <ModalHeader className="d-flex justify-content-center">
            Estado de Requerimientos BAW -
            {" " +
              modalBAW?.opp?.company.charAt(0).toUpperCase() +
              modalBAW?.opp?.company.toLowerCase().slice(1) +
              " "}
            - {modalBAW?.opp?.opp}
          </ModalHeader>

          <ModalBody className="d-flex justify-content-center">
            {showTableOrBawForm === "table" && (
              <Table
                className="align-items-center  table-flush"
                responsive
                striped={true}
                hover={true}
              >
                <thead className="thead-light">
                  <tr>
                    {columnsSelectedModalBAW.map((column, key) => {
                      return (
                        <th key={key} className="justify-content-md-center">
                          {column.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="list">
                  {modalBAW.rowsBAW.length > 0 ? (
                    <>
                      {modalBAW.rowsBAW.map((row, key) => {
                        return (
                          <tr key={key}>
                            {columnsSelectedModalBAW.map((col, key) => {
                              return col.value === "buttonsActions" ? (
                                <td
                                  key={key}
                                  className="justify-content-md-center"
                                >
                                  {row.statusId === 4 && ( //Si tiene status de devolución, que aparezca el botón para editar el requerimiento.
                                    <Button
                                      color="default"
                                      type="button"
                                      size="sm"
                                      onClick={() =>
                                        handleSetTableOrBawFormDevolution(row)
                                      }
                                    >
                                      Editar
                                    </Button>
                                  )}
                                </td>
                              ) : col.value === "statusName" ? (
                                <td
                                  key={key}
                                  className="justify-content-md-center"
                                >
                                  <Badge color={row.statusType} pill>
                                    {row.statusName}
                                  </Badge>
                                </td>
                              ) : (
                                <td
                                  key={key}
                                  className="justify-content-md-center"
                                >
                                  {row[col.value]}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td className="justify-content-md-center">
                          Sin resultados encontrados
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            )}

            {showTableOrBawForm === "bawFormDevolution" && (
              <BawDevolutionForm
                requirementInfoDevolution={requirementInfoDevolution}
                handleSetTableOrBawFormDevolution={
                  handleSetTableOrBawFormDevolution
                }
                setLoadingChangingView={setLoadingChangingView}
              ></BawDevolutionForm>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              type="button"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Volver
            </Button>
          </ModalFooter>
        </Spin>
      </Modal>
    </>
  );
};
