// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Card, CardBody, CardHeader, Modal } from "reactstrap";

const StatesModal = props => {

  const {
    options: {
      showModal,
      data,
    },
    toggleModal,
  } = props;

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <Card className="bg-gradient-default shadow mb-0">
        <CardHeader className="bg-transparent">
          <h3 className="text-white text-center mb-0">Flujo de Actividades</h3>
        </CardHeader>
        <CardBody>
          <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
          >
            {
              data.map((row, key) => {
                return (
                  <div className="timeline-block" key={key}>
                    <span className="timeline-step badge-primary">
                      <i
                        className={
                          row.state === 0 ? "fas fa-info-circle"
                            : row.state === 1 ? "fas fa-check"
                              : row.state === 2 ? "fas fa-times"
                                : "fas fa-check-double"
                        } />
                    </span>
                    <div className="timeline-content">
                      <h5 className="text-light text-muted font-weight-bold">
                        {moment(row.createdAt)
                          .utc()
                          .utcOffset(moment().utcOffset())
                          .format("LLLL")}
                      </h5>
                      <h5 className="text-white mt-3 mb-0">
                        {row.description}
                      </h5>
                      <h4 className="text-light text-muted font-weight-bold mt-3 mb-0">
                        Comentario: {row.comments}
                      </h4>
                      <div className="mt-3">
                        <Badge
                          className="badge-md"
                          color={
                            row.state === 0 ? "primary"
                              : row.state === 1 ? "success"
                                : row.state === 2 ? "danger"
                                  : "info"
                          }
                          pill
                        >
                          {
                            row.state === 0 ? "Iniciada"
                              : row.state === 1 ? "Aprobada"
                                : row.state === 2 ? "Rechazada"
                                  : "Aplicada"
                          }
                        </Badge>
                        <Badge
                          className="badge-md"
                          color={
                            row.state === 0 ? "primary"
                              : row.state === 1 ? "success"
                                : row.state === 2 ? "danger"
                                  : "info"
                          }
                          pill
                        >
                          {row.reviewedBy}
                        </Badge>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

StatesModal.propTypes = {
  data: PropTypes.array.isRequired,
};

export default StatesModal;