// core actions
import { createExeptionUserSignature, createPolicyUserSignature } from "actions/digitalSignature.jsx";
// antd components
import { Spin } from 'antd';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import CardEmpty from 'components/Shared/Notifications/CardEmpty.jsx';
import CardSignatures from 'components/Shared/Notifications/CardSignatures.jsx';
import HideSignature from 'components/Shared/Notifications/HideSignature.jsx';
// javascript library for dates
import moment from 'moment';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getAudios, getPdfs, getSignaturesDocuments } from "selectors/notifications.jsx";

class DigitalSignatureNotification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      url: null,
      reproduced: [],
      selectedDocument: null,
      textDocument: null,
      loading: false,
      loadingFlow: false,
      projects: [],
      values: {
        name: '',
        nameState: null,
        date: '',
        dateState: null,
        addWitness: false,
        addWitnessState: null,
        witness: '',
        witnessState: null,
      },
      qdocSigned: false,
      hideEvent: false,
      totalDays: 0,
      frequency: 0,
      model: 0,
      exactDirection: "",
      modelDetail: "",
      daysValues: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false
      }
    };
    // this.myRef = React.createRef();
  }

  componentDidMount = () => {
    if (!this.props.documents.length) {
      this.props.history.goBack();
    }
  };

  /**
  * Funcion para el manejo de notificaciones parametrizadas
  * @memberof OptionalsPositions
  */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  _handleEnd = () => {
    this.setState({
      reproduced: [...this.state.reproduced, this.state.selectedDocument.idDocumento],
    });
  }

  _handleOpenFile = row => {
    const {
      audios,
      documents
    } = this.props;
    const audio = audios.filter((element) => element.id === row.idDocumento);
    const [docu] = documents.filter((element) => element.idDocumento === row.idDocumento);
    if (docu) {
      this.setState({
        selectedDocument: docu,
        textDocument: docu.Texto_Documento,
        url: audio[0].url,
        hideEvent: docu.Requiere_Info_HO === 1 ? true : false
      });
    }
  }

  _registerPolicySignature = id => {
    const { pdfs } = this.props;
    const { projects, totalDays, frequency, model, exactDirection, daysValues, modelDetail } = this.state;
    const [pdf] = pdfs.filter((element) => element.id === id);
    // console.log(pdf)
    if (pdf) {
      this.hideAlert();
      this.setState((state) => ({
        loading: !state.loading
      }));
      this.props.createPolicyUserSignature(id, {
        pdf,
        projects,
        totalDays,
        frequency,
        model,
        exactDirection,
        daysValues,
        modelDetail
      }).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
        }
        this.setState((state) => ({
          loading: !state.loading,
          url: null,
          selectedDocument: null,
          textDocument: null,
          projects: [],
          totalDays: 1,
          frequency: 0,
          model: 0,
          exactDirection: "",
          modelDetail: "",
          daysValues: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false
          },
        }));
      });
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Atención"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="warning"
            confirmBtnText="Entendido"
            btnSize=""
          >
            Ocurrio un error obteniendo el documento que firmo, por favor intentelo de nuevo. Si el problema persiste comuniquese con Application Management.
          </ReactBSAlert>
        )
      });
    }
  }

  _handleOnSignature = row => {
    const { idDocumento, Requiere_Info_HO } = row;
    const { totalDays, frequency, model, exactDirection, daysValues, modelDetail } = this.state;
    const { days } = this.props;
    let alwaysDay = true;
    let amountDays = 0;
    days.forEach(element => {
      if (daysValues[element.key]) {
        alwaysDay = false;
        amountDays += 1;
      }
    });
    if (Requiere_Info_HO === 1 && alwaysDay && frequency === 1) {
      this.notify("warning", "Atención", "Debes seleccionar al menos un día");
    } else if (Requiere_Info_HO === 1 && exactDirection.length <= 0) {
      this.notify("warning", "Atención", "Debes ingresar la dirección exacta");
    } else if (Requiere_Info_HO === 1 && (totalDays < 1 || totalDays > 5) && frequency === 1) {
      this.notify("warning", "Atención", "Debes ingresar la cantidad de días correcta");
    } else if (Requiere_Info_HO === 1 && frequency === 0) {
      this.notify("warning", "Atención", "Debes seleccionar una frecuencia de home office");
    } else if (Requiere_Info_HO === 1 && (model === 0 && frequency === 1)) {
      this.notify("warning", "Atención", "Debes seleccionar un modelo de home office");
    } else if (Requiere_Info_HO === 1 && (modelDetail.length <= 0 && model === 6)) {
      this.notify("warning", "Atención", "Debes ingresar el detalle del modelo seleccionado");
    } else if (Requiere_Info_HO === 1 && amountDays !== parseInt(totalDays) && frequency === 1) {
      this.notify("warning", "Atención", "La cantidad de días y los días seleccionados deben coincidir");
    } else {
      if (Requiere_Info_HO === 1) {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="¡Esto iniciara un flujo para la revisión de la información por su supervisor!"
              customIcon={
                <div
                  className="swal2-icon swal2-question swal2-animate-question-icon"
                  style={{ display: "flex" }}
                >
                  <span className="swal2-icon-text">?</span>
                </div>
              }
              onConfirm={() => {
                this.setState({
                  alert: null,
                });
                this._registerPolicySignature(idDocumento);
              }}
              onCancel={() => {
                this.hideAlert();
              }}
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Si, continuar"
              cancelBtnBsStyle="danger"
              cancelBtnText="No, cancelar"
              btnSize=""
            />
          )
        });
      } else {
        this._registerPolicySignature(idDocumento);
      }
    }
  }

  _handleOnCancel = () => {
    this.setState((state) => ({
      url: null,
      selectedDocument: null,
      textDocument: null
    }));
  }

  _handleOnAddProject = () => {
    let isInvalid = false;
    const { values } = this.state;
    if (values.name === '') {
      values.nameState = 'invalid';
      isInvalid = true;
    }
    if (values.date === '') {
      values.dateState = 'invalid';
      isInvalid = true;
    }
    if (values.addWitness === true && (values.witness === '')) {
      values.witnessState = 'invalid';
      isInvalid = true;
    }
    if (isInvalid === false) {
      this.setState((state) => ({
        projects: [...state.projects, values],
        values: {
          name: '',
          nameState: null,
          date: '',
          dateState: null,
          addWitness: false,
          addWitnessState: null,
          witness: '',
          witnessState: null,
        }
      }));
    } else {
      this.setState({
        values,
      });
    }
  };

  _handleOnDeleteProject = row => {
    const { projects } = this.state;
    const projectsFiltered = projects.filter((element) => element.name !== row.name);
    this.setState({
      projects: projectsFiltered,
    });
  };

  _handleOnChangeProjects = value => {
    const key = value.target.id;
    const val = value.target.value;
    const { values } = this.state;
    values[key] = key === 'addWitness' ? !values.addWitness : val;
    if (key !== 'addWitness')
      values[`${key}State`] = 'valid'
    else if (key === 'addWitness' && values.addWitness)
      values.witnessState = 'valid'
    this.setState({
      values,
    });
  };

  handleOnOpenQdoc = url => {
    const { qdocSigned } = this.state;
    if (qdocSigned) {
      console.log(url);
      window.open(url);
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="¡Asegurese de iniciar sesión en Qdoc!"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => {
              this.setState({
                alert: null,
                qdocSigned: true
              });
              window.open("https://qdoc.gbm.net/login.aspx");
            }}
            onCancel={() => {
              this.hideAlert();
              window.open(url);
            }}
            showCancel
            confirmBtnBsStyle="success"
            confirmBtnText="Iniciar Sesión"
            cancelBtnBsStyle="danger"
            cancelBtnText="Estoy logeado"
            btnSize=""
          />
        )
      });
    }
  };

  _handleOnChangeHOValues = event => {
    const { id, value } = event.target;
    if (id === "totalDays" || id === "frequency" || id === "model" || id === "exactDirection" || id === 'modelDetail') {
      if (id === "frequency" && parseInt(value) > 1) {
        this.setState({
          model: 6,
          totalDays: 0,
          daysValues: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false
          },
        });
      }
      this.setState({
        [id]: (id === 'frequency' || id === 'model') ? parseInt(value) : value
      });
    } else {
      let { daysValues } = this.state;
      daysValues[id] = value === "false" ? true : false;
      this.setState({
        daysValues
      });
    }
  };

  _handleOnDateChange = (id, value) => {
    const { values } = this.state;
    values[id] = value;
    values[`${id}State`] = 'valid';
    this.setState({
      values,
    });
  };

  _handleOnToggleModal = event => {
    this.setState((state) => ({
      [event]: !state[event]
    }));
  };

  _handleOnHideSignature = async values => {
    const { action, comments } = values;
    const { selectedDocument: { idDocumento } } = this.state;
    if (action === "1") {
      this._handleOnToggleModal('hideEvent');
    } else {
      this.setState((state) => ({
        loadingFlow: !state.loadingFlow,
      }));
      this.props.createExeptionUserSignature(idDocumento, {
        comments: comments ? comments : 'N/A'
      }).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
          this.setState((state) => ({
            loadingFlow: !state.loadingFlow,
          }));
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.setState((state) => ({
            loadingFlow: !state.loadingFlow,
            url: null,
            selectedDocument: null,
            textDocument: null,
            projects: [],
            hideEvent: false
          }));
        }
      });
    }
  };

  render() {

    const {
      alert,
      loading,
      loadingFlow,
      hideEvent,
      url,
      selectedDocument,
      textDocument,
      reproduced,
      projects,
      totalDays,
      frequency,
      model,
      exactDirection,
      modelDetail,
      values,
      daysValues
    } = this.state;

    const { documents, days, frequencies, models, name, parentName } = this.props;

    return (
      <>
        {alert}
        <HideSignature
          title="Nueva modalidad de Home Office"
          loading={loadingFlow}
          showModal={hideEvent}
          toggleModal={this._handleOnToggleModal}
          onWorkFlow={this._handleOnHideSignature}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Firma de políticas" parentName="Notificaciones" />
        <Container className="mt--6" fluid>
          {
            documents.length ?
              <Row>
                <Col>
                  <Spin size="large" spinning={loading}>
                    <CardSignatures
                      title="Políticas pendientes de firmar."
                      homeOfficeValues={{
                        totalDays,
                        frequency,
                        model,
                        exactDirection,
                        modelDetail,
                        daysValues,
                        days,
                        frequencies,
                        models,
                      }}
                      documents={documents}
                      loading={loading}
                      isPlay={url}
                      viwed={[...reproduced]}
                      selectedDocument={selectedDocument}
                      textDocument={textDocument}
                      values={values}
                      projects={projects}
                      openFile={this._handleOpenFile}
                      onChangeHO={this._handleOnChangeHOValues}
                      onChangeForm={this._handleOnChangeProjects}
                      reactDatetimeChange={this._handleOnDateChange}
                      onCancel={this._handleOnCancel}
                      addProject={this._handleOnAddProject}
                      openQdoc={this.handleOnOpenQdoc}
                      deleteProject={this._handleOnDeleteProject}
                      onSignature={this._handleOnSignature}
                      onEndAudio={this._handleEnd}
                    // ref={this.myRef}
                    />
                  </Spin>
                </Col>
              </Row>
              :
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay notificaciones pendientes."
                    subtitle="Al día de hoy no tienes notificaciones pendientes, en el momento que exista una nueva, aparecerán en esta ventana."
                  />
                </Col>
              </Row>
          }
        </Container>
      </>
    );
  }
}

DigitalSignatureNotification.defaultProps = {
  name: `Firma de políticas ${moment().utc().utcOffset(moment().utcOffset()).year()}`,
  parentName: "Notificaciones",
  documents: [],
  audios: [],
  pdfs: [],
  days: [
    { key: 'monday', name: 'Lunes' },
    { key: 'tuesday', name: 'Martes' },
    { key: 'wednesday', name: 'Miércoles' },
    { key: 'thursday', name: 'Jueves' },
    { key: 'friday', name: 'Viernes' }
  ],
  frequencies: [
    { id: 1, name: 'Semanal' },
    { id: 2, name: 'Quincenal' },
    { id: 3, name: 'Mensual' }
  ],
  models: [
    { id: 1, name: '1-4' },
    { id: 2, name: '2-3' },
    { id: 3, name: '3-2' },
    { id: 4, name: '4-1' },
    { id: 5, name: '5-0' },
    { id: 6, name: 'Otro' },
  ]
}

DigitalSignatureNotification.propTypes = {
  audios: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  pdfs: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  audios: getAudios(state),
  documents: getSignaturesDocuments(state),
  pdfs: getPdfs(state),
});

export default withRouter(connect(mapStateToProps, {
  createPolicyUserSignature,
  createExeptionUserSignature
})(DigitalSignatureNotification));
