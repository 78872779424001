/* eslint-disable jsx-a11y/anchor-is-valid */
// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
import Select2 from "react-select2-wrapper";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, Row } from "reactstrap";

const UserEscalationModal = props => {

  const {
    escalation,
    title,
    loading,
    modal: {
      showModal,
      data,
      type
    },
    options: {
      services,
      countries
    },
    toggleModal,
    onSubmit,
  } = props;
  const [servicesDefaults, setService] = useState(Object.keys(data).length ? data.services : []);
  const [countriesDefaults, setCountry] = useState(Object.keys(data).length ? data.countries : []);

  const validate = values => {
    const errors = {};
    if (!values.email)
      errors.email = "Debes ingresar el correo electrónico.";
    if (type !== 'search') {
      if (!values.idUser)
        errors.idUser = "Debes ingresar el ID.";
      if (!values.fullname)
        errors.fullname = "Debes ingresar el nombre completo.";
      // if (!values.service || values.service.length === 0)
      //   errors.service = "Debes seleccionar el sevicio.";
      // if (!values.country || values.country.length === 0)
      //   errors.country = "Debes seleccionar el país.";
      if (escalation)
        if (!values.type || values.type < 1 || values.type > 3)
          errors.type = "El tipo de escalación no es valido.";
    }
    return errors;
  };

  const onDeleteSelect = (row, type) => {
    if (type === 'service') {
      setService(servicesDefaults.filter((ele) => ele.id !== row.id));
    } else if (type === 'country') {
      setCountry(countriesDefaults.filter((ele) => ele.id !== row.id));
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={() => toggleModal('userModalEscalation')}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={(e) => onSubmit(e, servicesDefaults, countriesDefaults, type)}
              validate={validate}
              initialValues={data}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading}>
                  <FormRS role="form">
                    {
                      type === 'search' ?
                        <>
                          <FormGroup>
                            <Label for="email">Correo Electrónico</Label>
                            <Field name="email">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="text"
                                    placeholder="Ingrese aquí el correo electrónico del colaborador."
                                    invalid={meta.error && meta.touched}
                                  />
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          <div className="text-center">
                            <Button
                              disabled={validating || submitting}
                              className="my-4"
                              color="info"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Buscar Información Usuario
                            </Button>
                          </div>
                        </>
                        :
                        <>
                          <FormGroup>
                            <Label for="idUser">ID Colaborador</Label>
                            <Field name="idUser">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="number"
                                    style={{ margin: 0 }}
                                    placeholder="Ingrese aquí el identificador del colaborador."
                                    invalid={meta.error && meta.touched}
                                  />
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          <FormGroup>
                            <Label for="fullname">Nombre Completo</Label>
                            <Field name="fullname">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="text"
                                    style={{ margin: 0 }}
                                    placeholder="Ingrese aquí el nombre completo del colaborador."
                                    invalid={meta.error && meta.touched}
                                  />
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          <FormGroup>
                            <Label for="email">Correo Electrónico</Label>
                            <Field name="email">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="text"
                                    style={{ margin: 0 }}
                                    placeholder="Ingrese aquí el correo electrónico del colaborador."
                                    invalid={meta.error && meta.touched}
                                  />
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          {
                            escalation &&
                            <FormGroup>
                              <Label for="type">Tipo de Escalación</Label>
                              <Field name="type">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="number"
                                      style={{ margin: 0 }}
                                      placeholder="Ingrese aquí el correo electrónico del colaborador."
                                      invalid={meta.error && meta.touched}
                                      min={1}
                                      max={3}
                                    />
                                    {
                                      meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                    }
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          }
                          <FormGroup>
                            <Label for="service">Servicio</Label>
                            <Field name="service">
                              {({ input, meta }) => (
                                <>
                                  <div>
                                    <Select2
                                      {...input}
                                      multiple
                                      data={services.filter((row) => !servicesDefaults.some((ele) => row.id === ele.id))}
                                    />
                                    {
                                      meta.error && meta.touched && <span style={{
                                        marginTop: '0.25rem',
                                        fontSize: '80%',
                                        color: '#fb6340'
                                      }}>{meta.error}</span>
                                    }
                                  </div>
                                  <ListGroup flush>
                                    {
                                      servicesDefaults.length ?
                                        servicesDefaults.map((row, key) => (
                                          <ListGroupItem key={key} className="list-group-item-action flex-column align-items-start py-1 px-1">
                                            <div className="d-flex w-100 justify-content-between">
                                              <div>
                                                <div className="d-flex w-100 align-items-center">
                                                  <h5 className="mb-1">{row.name}</h5>
                                                </div>
                                              </div>
                                              <a
                                                className="table-action table-action-delete"
                                                style={{
                                                  color: "indianred"
                                                }}
                                                onClick={() => onDeleteSelect(row, 'service')}
                                              >
                                                <i className="fas fa-trash" />
                                              </a>
                                            </div>
                                          </ListGroupItem>
                                        )) : null
                                    }
                                  </ListGroup>
                                </>
                              )}
                            </Field>
                          </FormGroup>
                          <FormGroup>
                            <Label for="country">País</Label>
                            <Field name="country">
                              {({ input, meta }) => (
                                <>
                                  <div>
                                    <Select2
                                      {...input}
                                      multiple
                                      data={countries.filter((row) => !countriesDefaults.some((ele) => row.id === ele.id))}
                                    />
                                    {
                                      meta.error && meta.touched && <span style={{
                                        marginTop: '0.25rem',
                                        fontSize: '80%',
                                        color: '#fb6340'
                                      }}>{meta.error}</span>
                                    }
                                  </div>
                                  <ListGroup flush>
                                    {
                                      countriesDefaults.length ?
                                        countriesDefaults.map((row, key) => (
                                          <ListGroupItem key={key} className="list-group-item-action flex-column align-items-start py-1 px-1">
                                            <div className="d-flex w-100 justify-content-between">
                                              <div>
                                                <div className="d-flex w-100 align-items-center">
                                                  <h5 className="mb-1">{row.name}</h5>
                                                </div>
                                              </div>
                                              <a
                                                className="table-action table-action-delete"
                                                style={{
                                                  color: "indianred"
                                                }}
                                                onClick={() => onDeleteSelect(row, 'country')}
                                              >
                                                <i className="fas fa-trash" />
                                              </a>
                                            </div>
                                          </ListGroupItem>
                                        )) : null
                                    }
                                  </ListGroup>
                                </>
                              )}
                            </Field>
                          </FormGroup>
                          <div className="text-center">
                            <Button
                              disabled={validating || submitting}
                              className="my-4"
                              color="info"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              {type === 'add' ? 'Agregar Usuario' : 'Modificar Usuario'}
                            </Button>
                          </div>
                        </>
                    }
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

UserEscalationModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default UserEscalationModal;