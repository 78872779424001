// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
import "moment/locale/es";
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";


const ModalActionAccess = props => {

  const {
    options: {
      showModal,
      type,
      row,
      options: {
        title,
        btnTxt,
      }
    },
    locations,
    onSubmit,
    loading,
    message,
    toggleModal
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.username || values.username === "")
      errors.username = "Debes ingresar el nombre de usuario.";
    if (!values.location || values.location === "0")
      errors.location = "Debes seleccionar una opción.";
    return errors;
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={(e) => onSubmit(type, e)}
              validate={validate}
              initialValues={row}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label className="form-control-label" for="username">Nombre de Usuario</Label>
                      <Field name="username">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Escriba aquí el nombre de usuario"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" for="location">Localidad</Label>
                      <Field name="location">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              valid={!meta.error && meta.modified && meta.touched}
                              invalid={meta.error && meta.touched}
                            >
                              <option key="0" value="0">Seleccione una localidad</option>
                              {
                                locations.map((row, key) => {
                                  return (
                                    <option key={key} value={row.location}>{row.name}</option>
                                  )
                                })
                              }
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-2"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {btnTxt}
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

ModalActionAccess.propTypes = {

};

export default ModalActionAccess;