/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import { Button, Card, CardHeader, Col, Modal, Table, Row } from "reactstrap";

const ContactsLoad = (props) => {
  const {
    showModal,
    contacts,
    toggleModal,
    initialValues,
    onChange,
    onSaveContacts,
  } = props;

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={showModal}
        toggle={() => toggleModal("showModal")}
      >
        <Card>
          <CardHeader>
            <Row>
              <Col xs="12" md="7">
                <h5 className="h3 mb-0">Contactos Cargados</h5>
              </Col>
              <Col
                className="d-flex align-items-center justify-content-end"
                xs="12"
                md="5"
              >
                <Button
                  disabled={!initialValues.length}
                  className="btn-round btn-icon"
                  color="success"
                  onClick={onSaveContacts}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-save" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive striped>
            <thead className="thead-light">
              <tr>
                <th>Nombre Completo</th>
                <th>Télefono</th>
                <th>Dirección</th>
                <th />
              </tr>
            </thead>
            <tbody className="list">
              {contacts.map((row, key) => {
                return (
                  <tr key={key}>
                    <td style={{ whiteSpace: "normal", textAlign: "center" }}>
                      {row.name}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "center" }}>
                      {row.telephone}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.address}
                    </td>
                    <td style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <label className="custom-toggle">
                        <input
                          id={row.id}
                          type="checkbox"
                          onChange={(e) => onChange(e)}
                          checked={
                            initialValues.indexOf(row.id) > -1 ? true : false
                          }
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Sí"
                        />
                      </label>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </Modal>
    </>
  );
};

ContactsLoad.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default ContactsLoad;
