import React, { useRef, useState, useEffect } from 'react';
import { Col, Form, Row, Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { getUsersMatrix, createNewAccessMatriz, deleteItemAccessMatriz, updateItemAccessMatriz, getAllAccessMatrizHistory, downloadFileVersionMatriz, getAllCountries, getAllCloudVendor } from "actions/userCloudRequests";
import { Spin } from 'antd';
import Tables from "components/UserCloudRequest/Tables";
import Modal from "components/UserCloudRequest/AdminUserCloudRequest/Modals.jsx";
import NotificationAlert from "react-notification-alert";
import { useDispatch } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";

const AccessMatriz = () => {

    const dispatch = useDispatch();
    const notifyEl = useRef(null);
    const [loading, setLoad] = useState(false);
    const [info, setInfo] = useState([]);
    const [newInfo, setNewInfo] = useState([]);
    const [matrix, setMatrix] = useState([]);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({});
    const [openModal, setModal] = useState({
        createModal: false,
        editModal: false,
    });
    const [data, setData] = useState({
        countries: [],
        clouds: []
    });
    const [history, setHistory] = useState([]);
    const [itemInfo, setItemInfo] = useState({});
    const [modalConfirm, setModalConfirm] = useState("");
    const [tab, setTab] = useState("1");
    useEffect(() => {
        handleOnGetMatriz();
        handleOnGetCloudAndCountries();
        handleOnGetMatrizHistory();
    }, []);
    //Trae la informacion de la matriz para la tabla
    const handleOnGetMatriz = async () => {
        const array = [];
        setLoad(true);
        await dispatch(getUsersMatrix()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setMatrix(payload.data.payload.users);
                setLoad(false);
            } else {
                setLoad(false);
            }
        });
    }
    //Trae la informacion de los paises y los vendedores de nube
    const handleOnGetCloudAndCountries = async () => {
        setLoad(true);
        await dispatch(getAllCountries()).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("danger", "Error", payload.message);
                setLoad(false);
            } else {
                setData(prevState => ({
                    ...prevState,
                    countries: payload.data.data
                }))
            }
        });
        await dispatch(getAllCloudVendor()).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("danger", "Error", payload.message);
                setLoad(false);
            } else {
                let cloud = [];

                const data = payload.data.data
                for (const item of data) {
                    let json = {};
                    json["value"] = item.id
                    json["label"] = item.name
                    cloud.push(json);
                }
                setData(prevState => ({
                    ...prevState,
                    clouds: cloud
                }))
            }
        });

        setLoad(false);
    }
    const aditionalsButtons = [
        {
            color: "success",
            icon: "fas fa-edit",
            function: (e) => handleOnOpenModalEdit(e),
            tool: "Editar",
            id: "Editar",
        },
        {
            color: "danger",
            icon: "fas fa-trash-alt",
            function: (e) => handleOnDeleteItemToMatriz(e),
            tool: "Eliminar",
            id: "Eliminar",
        },
    ];
    const aditionalsButtonsHistory = [
        {
            color: "info",
            icon: "fas fa-download",
            function: (e) => downloadFile(e),
            tool: "Descargar",
            id: "Descargar",
        }
    ];
    const modalInformation = [
        { label: "Pais del Usuario", value: "country", valueInsert: "countryId", type: "select", options: data.countries, colWidth: "12", disabled: false },
        { label: "Identificador del empleado", value: "idEmployee", type: "input", colWidth: "12", disabled: false },
        { label: "Nombre", value: "NameEmployee", type: "input", colWidth: "12", disabled: false },
        { label: "Pisición GBM", value: "gbmPosition", type: "input", colWidth: "12", disabled: false },
        { label: "Correo", value: "mail", type: "input", colWidth: "12", disabled: false },
        { label: "Grupo del empleado", value: "groupEmployee", type: "input", colWidth: "12", disabled: false },
        { label: "Grupo de Cloud", value: "groupCloud", valueInsert: "groupCloud", type: "input", colWidth: "12", disabled: false },
        { label: "AWS", value: "AWS", valueInsert: "AWS", disabled: false , colWidth: "12"},
        { label: "Google", value: "Google", valueInsert: "Google", disabled: false , colWidth: "12"},
        { label: "IBM", value: "IBM", valueInsert: "IBM", disabled: false, colWidth: "12" },
        { label: "Azure", value: "Azure", valueInsert: "Azure", disabled: false , colWidth: "12"},
    ];
    const colums = [
        { label: "Pais del Usuario", value: "country" },
        { label: "Identificador del empleado", value: "idEmployee" },
        { label: "Nombre", value: "NameEmployee" },
        { label: "Pisición GBM", value: "gbmPosition" },
        { label: "Correo", value: "mail" },
        { label: "Grupo del empleado", value: "groupEmployee" },
        { label: "Grupo de Cloud", value: "groupCloud" },
        { label: "AWS", value: "AWS" },
        { label: "Google", value: "Google" },
        { label: "IBM", value: "IBM" },
        { label: "Azure", value: "Azure" },
    ]
    const columsHistory = [
        { label: "Version", value: "id" },
        { label: "Archivo", value: "nameFile", type: "a" },
        { label: "Usuario que edito la matriz", value: "nameEditUser" },
        { label: "Ultima Acción", value: "action" },
        { label: "Fecha de creación", value: "createdAt", type: "date" },

    ]
    //Notificacion de alertas
    const notify = (type, title, message) => {
        try {
            let options = {
                place: "br",
                message: (
                    <div className="alert-text">
                        <span className="alert-title" data-notify="title">
                            {title}
                        </span>
                        <span data-notify="message">{message}</span>
                    </div>
                ),
                type: type,
                icon: "ni ni-bell-55",
                autoDismiss: 7,
            };
            notifyEl.current.notificationAlert(options);
        } catch (error) { }
    };
    //Funcion para los filtros por columna
    const handleOnfilterDinamic = (constant, e) => {
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }
    //Funcion para deshabilitar un elemento de la matriz
    const handleOnDeleteItemToMatriz = async (item) => {
        const idItem = item.Id;
        await dispatch(deleteItemAccessMatriz(idItem)).then((resp) => {
            const { payload } = resp;
            if (payload.status !== 200) {
                notify("success", "Exito", "Se elimino correctamente.");
                setLoad(false);
            }
            else {
                setLoad(false);
            }
            handleOnGetMatriz();

        });
    }
    //Funcion para actualziar un elemento de la matriz
    const handleOnEditItemToMatriz = async () => {
        await dispatch(updateItemAccessMatriz({ info: itemInfo })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                notify("success", "Exito", "Se actualizo correctamente.");
                setLoad(false);
                closeModal();
                setModal((prevState) => ({
                    ...prevState,
                    editModal: !openModal.editModal,
                }));
            } else {
                setLoad(false);
            }
            handleOnGetMatriz();
        });
    }
    //Funcion para crear un elemento en la matriz

    const handleOnCreateNewMatrizItem = async () => {
        await dispatch(createNewAccessMatriz({ info: newInfo })).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                setLoad(false);
                notify("danger", "Error", payload.message);

            } else {
                notify("success", "Exito", "Se creo correctamente.");
                setLoad(false);
                closeModal();
                setModal((prevState) => ({
                    ...prevState,
                    createModal: !openModal.createModal,
                }));
                setLoad(false);
            }
            handleOnGetMatriz();
        });
    }
    //Funcion que trae la informacion del historial de matriz
    const handleOnGetMatrizHistory = async () => {
        setLoad(true);
        await dispatch(getAllAccessMatrizHistory()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setLoad(false);
                setHistory(payload.data.data);
            } else {
                setLoad(false);
            }
        });
    }
    //Funcion para hacer los cambios de informacion en los campos de input, select .etc
    // const handleOnChangeInfo = (constant, value) => {
    //     setNewInfo((prevState) => ({
    //         ...prevState,
    //         [constant]: value,
    //     }));
    // };
    //Funcion para abrir el modal de editar registro en la tabla principal
    const handleOnOpenModalEdit = (item) => {
        setModal((prevState) => ({
            ...prevState,
            editModal: !openModal.editModal,
        }));
        setItemInfo(item);
        if (openModal.editModal) {
            setItemInfo({});
            setNewInfo({});
        }
    };
    const handleOnOpenModal = (item) => {
        setModal((prevState) => ({
            ...prevState,
            createModal: !openModal.createModal,
        }));
        if (openModal.createModal) {
            setNewInfo({});
        }
    };
    //Funcion para cambiar la informacion que vamos a editar
    const handleOnChangeEditInfo = (constant, value) => {
        setItemInfo((prevState) => ({
            ...prevState,
            [constant]: value,
        }));
    };
    //Funcion para cambiar la informacion que vamos a editar
    const handleOnChangeNewInfo = (constant, value) => {
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: value,
        }));
    };
    //Funcion que muestra los modales de confirmacion
    const showModalConfirmation = (text, type) => {
        setModalConfirm(
            <ReactBSAlert
                custom
                showCancel
                title={text}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                placeHolder="Write something"
                onConfirm={type === 1 ? () => handleOnCreateNewMatrizItem() : () => handleOnEditItemToMatriz()}
                onCancel={() => closeModal()}
                confirmBtnText="Sí"
                cancelBtnText="No, cancelar"
                btnSize="md"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                openAnim={{ name: "showSweetAlert", duration: 1500 }}
                closeAnim={{ name: "hideSweetAlert", duration: 1000 }}
            ></ReactBSAlert>
        );
    };
    //Utiliza los filtros de las columnas en la tabla

    const renderRowsByFilters = (rows) => {

        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);
            for (const element of filterKeys) {
                let valueFiltered;
                if (isNaN(filters[element]) === false) {
                    valueFiltered = filters[element]
                    rows = rows.filter((item) => String(item[element]).indexOf(valueFiltered) != -1)
                } else {
                    valueFiltered = filters[element].toLowerCase()
                    rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
                }
            }
            return rows
        }
    }
    //Cerrar modal
    const closeModal = () => {
        setModalConfirm("");
    };
    const changeTab = (tab) => {
        setTab(tab);
        setFilters([]);
    }
    const downloadFile = async (item) => {
        const path = item.id;
        await dispatch(downloadFileVersionMatriz(path)).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("danger", "Error", payload.message);
                setLoad(false);
            }
            else {
                const url = window.URL.createObjectURL(new Blob([payload.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${item.nameFile}`);
                document.body.appendChild(link);
                link.click();
                notify("success", "Exito", "Se descargo correctamente.");
                setLoad(false);

            }
        });

    }
    return (
        <div>
            {modalConfirm}
            <div className="rna-wrapper">
                <NotificationAlert ref={notifyEl} />
            </div>
            <AdminHeader name="Solicitudes" parentName="Usuarios Cloud" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>

                    <div>
                        <Nav pills className="nav-fill flex-column flex-md-row " role="tablist">
                            <NavItem>
                                <NavLink
                                    className={("mb-sm-3 mb-md-0", {
                                        active: tab === "1"
                                    })}
                                    onClick={() => changeTab("1")}
                                >
                                    Tabla con Matriz de Accesos
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={("mb-sm-3 mb-md-0", {
                                        active: tab === "2"
                                    })}
                                    onClick={() => changeTab("2")}
                                >
                                    Historial de Matriz de Accesos
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={tab}>
                            <TabPane tabId="1">
                                {tab == 1 && (
                                    <Tables
                                        createButton={true}
                                        page={page}
                                        handleOnNewElement={handleOnOpenModal}
                                        setPage={setPage}
                                        colums={colums}
                                        aditionalsButtons={aditionalsButtons}
                                        filters={handleOnfilterDinamic}
                                        rows={renderRowsByFilters(matrix)}
                                        subtitle={"Matriz"}
                                        title={"Matriz de Accesos"}
                                        thText={"Acciones"}
                                    />
                                )}
                            </TabPane>
                            <TabPane tabId="2">
                                {tab == 2 && (
                                    <Tables
                                        page={page}
                                        setPage={setPage}
                                        colums={columsHistory}
                                        aditionalsButtons={aditionalsButtonsHistory}
                                        filters={handleOnfilterDinamic}
                                        rows={renderRowsByFilters(history)}
                                        subtitle={"Matriz"}
                                        title={"Historico Matriz de Accesos"}
                                        thText={"Acciones"}
                                    />
                                )}

                            </TabPane>
                        </TabContent>
                    </div>


                    <Modal
                        size={12}
                        openModal={openModal.editModal}
                        saveButtonDisabled={false}
                        handleOnOpenModal={handleOnOpenModalEdit}
                        itemModal={modalInformation}
                        text={"Editar elemento de matriz de accesos"}
                        itemInfo={itemInfo}
                        handleOnChangeInfo={handleOnChangeEditInfo}
                        saveButton={() => showModalConfirmation("¿Seguro que desea actualizar el usuario?", 2)}
                        nameButton1={"Cerrar"}
                        nameButton2={"Guardar"}
                    />
                    <Modal
                        size={12}
                        openModal={openModal.createModal}
                        saveButtonDisabled={false}
                        handleOnOpenModal={handleOnOpenModal}
                        itemModal={modalInformation}
                        text={"Crear nuevo elemento en la matriz de accesos"}
                        itemInfo={itemInfo}
                        handleOnChangeInfo={handleOnChangeNewInfo}
                        saveButton={() => showModalConfirmation("¿Seguro que desea crear el usuario?", 1)}
                        nameButton1={"Cerrar"}
                        nameButton2={"Guardar"}
                    />
                </Spin>
            </Container>
        </div>
    );
}

export default AccessMatriz;