/*!

=========================================================
*Autopp - BawItemForm
=========================================================

* Por cada ítem que agrega el reducer al state de BawItemsList,
se realiza un map y en cada elemento renderiza un componente de este
tipo (BawItemForm), de esta forma se puede crear, editar y eliminar 
cada item de la lista.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerías de React
import
React, { useContext, useEffect, useState } from 'react'
import { FormGroup, Button, Input, Col, Row } from "reactstrap";

//Import de componente contexto para extraer states globales
import { UserContext } from '../SharedComponents/UserContext';

export const BawItemForm = React.memo(({
  handleAddInformationToBaw,
  handleDeleteItem,
  bawItem,
  index
}) => {

  //#region Configuraciones
  //Asignación del titulo del ítem.
  const [state] = useState({
    title: "Caso BAW",
  });
  const { title } = state;
  //#endregion

  //#region States globales

  //states global de dropdownoptions
  const { dropDownOptions } = useContext(UserContext);

  //#endregion

  //#region States locales

  //Asignación de Campos del form.
  const [fieldsForm, setFieldsForm] = useState([
    {
      colWidth: "6",
      colHeight: "",
      label: "Proveedor: *",
      placeholder: "Seleccione el proveedor",
      id: 'vendors',
      defaultValue: 'vendors',
      type: "select",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      colHeight: "",
      label: "Nombre del Producto: *",
      placeholder: "Seleccione el producto",
      id: "ProductName",
      options: [],
      type: "select",
      required: true,
      defaultValue: 'ProductName',
      disabled: false,
    },
    {
      colWidth: "6",
      colHeight: "",
      label: "Tipo de Requerimiento: *",
      placeholder: "Seleccione el tipo de requerimiento",
      id: "RequirementType",
      options: [],
      type: "select",
      required: true,
      defaultValue: 'RequirementType',
      disabled: false,
    },
    {
      colWidth: "6",
      colHeight: "",
      label: "Cantidad: *",
      placeholder: "Digite la cantidad",
      id: "Quantity",
      name: "number",
      type: "number",
      min: 1,
      max: 1999999999,
      required: true,
      defaultValue: 'Quantity',
      disabled: false,
    },
    {
      colWidth: "6",
      colHeight: "",
      label: "Es parte de una integración? *",
      placeholder: "Seleccione si es una integración",
      id: "isIntegration",
      type: "select",
      required: true,
      defaultValue: 'isIntegration',
      disabled: false,
    },
    {
      colWidth: "10",
      label: "Comentarios adicionales: ",
      placeholder: "Si lo desea, puede agregar algunos comentarios",
      id: "AdditionalComments",
      name: "text",
      type: "text",
      required: false,
      defaultValue: 'AdditionalComments',
      disabled: false,
    },
  ]);

  //state que guarda la info del BawItemActual para luego integrarlo al campo información de BawItemsList
  const [newInfo, setNewInfo] = useState({})

  //#endregion

  //#region Todas las funciones Handle útiles para la administración de la clase.

  //Cada vez que cambia newInfo, agrega la info a al campo information de bawItemList para cada respectivo item
  useEffect(() => {

    //handleExistInfoNow previene que borre la información cuando se renderice por segunda vez.
    if (handleExistInfoNow()) {
      //Es un metodo que realiza un dispatch con un action que contiene la info al id.
      handleAddInformationToBaw(bawItem.id, newInfo);
    }

  }, [newInfo])

  //Verifica si el Item ya contiene Info.
  const handleExistInfoNow = () => {
    //Existe info en NewInfo   
    if (newInfo["vendors"] != undefined) {
      return true;
    } else {
      return false
    }
  }


  //Asigna los valores de cada campo del form en newInfo, y a la vez asigna los campos option a los demás dropdowns dependiendo de la selección.
  const handleChangeInfo = (constant, e) => {

    //Asigna las opciones de ProductName
    if (constant === "vendors") {
      //console.log("Asig opciones de product name")

      //#region Apartir de vendors establece los dropdowns a ProductsNames     
      
      const productNameOptions = dropDownOptions.productName
        .filter(
          (dropdown) => dropdown.vendor === e.label)
        .map(
          (dropdown) => { return { "label": dropdown.label, "value": dropdown.value } });


      const indexElement = fieldsForm.findIndex(dropdown => dropdown.id == "ProductName");

      let asignOptions = [...fieldsForm];

      asignOptions[indexElement] = { ...asignOptions[indexElement], options: productNameOptions }

      setFieldsForm(asignOptions);

      //#endregion

      //LimpiaLosDropDowns excepto vendors
      handleCleanDropdowns(constant);


    } else if (constant === "ProductName") { //Asigna las opciones de requirementType
      //Apartir de ProductName establece los dropdowns a requirementType

      //Averiguar el type del ProductSeleccionado
      const wichTypeofProduct = dropDownOptions.productName.filter((productName) => productName.label.replace(/\s+/g, '') === e.label.replace(/\s+/g, ''));

      //console.log(wichTypeofProduct);
      //console.log(dropDownOptions.requirementType);
      //Filtrar las que no son del tipo de producto y moldear data
      let requirementTypeOptions = ""

      try {
        requirementTypeOptions = dropDownOptions.requirementType
          .filter(
            (reqType) => reqType.reqtype === wichTypeofProduct[0].requirementType
          ).map(
            (reqType) => { return { "label": reqType.label, "value": reqType.value } });
      } catch (e) { }

      //Asignar las opciones al campo de RequirementType
      const indexElement = fieldsForm.findIndex(dropdown => dropdown.id == "RequirementType");
      let asignOptions = [...fieldsForm];

      asignOptions[indexElement] = { ...asignOptions[indexElement], options: requirementTypeOptions }
      setFieldsForm(asignOptions);

      //LimpiaLosDropDowns excepto vendors y productName
      handleCleanDropdowns(constant);

    }

    //Insert General
    setNewInfo((prevState) => ({
      ...prevState,
      [constant]: e
    }))





  }

    //Limpia los demás Dropdowns si fue seleccionado vendors o productName en el form.
    const handleCleanDropdowns = (constant) => {

      //Si selecciona vendors limpia todas las casillas
      if (constant === "vendors") {
          for (const element of fieldsForm) {
              (element.type === "select") ?
                  (setNewInfo((prevState) => ({
                      ...prevState,
                      [element.id]: { label: "", value: "" }
                  }))) :                  
                  (setNewInfo((prevState) => ({
                      ...prevState,
                      [element.id]: ""
                  })));
          }


      } else {
          //Si selecciona ProductName limpia todo menos Vendors.
          for (const element of fieldsForm) {
              if ((element.type === "select" && element.id !== "vendors" )) {
                  setNewInfo((prevState) => ({
                      ...prevState,
                      [element.id]: { label: "", value: "" }
                  }
                  ))
              } else if (element.id !== "vendors" ) {
                  setNewInfo((prevState) => ({
                      ...prevState,
                      [element.id]: ""
                  }
                  ))
              };
          }
      }



  }

  //#endregion


  return (

    <>
      <Row className="">
        <Col xs="7"sm="10" className="">
          <h3 id="titlebaw" className="mb-0 ">{title}</h3>
        </Col>
        <Col xs="5"sm="2" className="" >
          <Button id={"buttonDelete"+index} className="btn-icon" color="danger" size="sm" block type="button"
            onClick={() => handleDeleteItem(bawItem.id)}
          >
            <span className="btn-inner--icon mr-">
              <i className="fas fa-trash" />
            </span>
            <span className="btn-inner--text">
              Eliminar
            </span>
          </Button></Col>
        {fieldsForm.map((row, key) =>
          row.type === "text"||  row.type === "number" ? (
            <Col xs="12" sm={row.colWidth} md={row.colHeight} key={key} >
              <FormGroup key={key}>
                <label
                  style={{ fontSize: "12px" }}
                  className="form-control-label"
                  htmlFor="input-username"
                >
                  {`${row.label}`}
                </label>

                <Input
                  key={key}
                  id={row.id + index}
                  name={row.name}
                  required={row.required}
                  className="basic-multi-select text-dark"
                  min={row.min}
                  max={row.max}
                  type={row.type}
                  placeholder={row.placeholder}
                  onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                />
              </FormGroup>
            </Col>
          ) : row.id === "vendors" || row.id === "isIntegration" ? (
            //Este case es para traer las opciones completas ya que no dependen de ningun dropdown
            <Col xs="12" sm={row.colWidth} key={key}>
              <FormGroup key={key}>
                <label
                  style={{ fontSize: "12px" }}
                  className="form-control-label"
                  htmlFor="input-username"
                >
                  {row.label}
                </label>


                <Input
                  key={row.id + key}
                  id={row.id + index}
                  name={fieldsForm.id}
                  required={row.required}
                  className="basic-multi-select text-dark"
                  type={row.type}
                  placeholder={row.placeholder}
                  onChange={(e) => handleChangeInfo(row.id, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id })}
                >
                  <option id=""></option>
                  {
                    dropDownOptions[row.id] && dropDownOptions[row.id].map((value) => {
                      return (
                        <option id={value.value}>{value.label}</option>
                      );
                    })}
                </Input>
                <div className="invalid-feedback">
                  Este campo no puede estar vacío
                </div>


              </FormGroup>
            </Col>
          ) : (
            //Dependen de un dropdown
            <Col xs="12"sm={row.colWidth} key={key} >
              <FormGroup key={key}>
                <label
                  style={{ fontSize: "12px" }}
                  className="form-control-label"
                  htmlFor="input-username"
                >
                  {row.label}
                </label>

                <Input
                  key={row.id + key}
                  id={row.id + index}
                  name={fieldsForm.id}
                  required={row.required}
                  className="basic-multi-select text-dark"
                  type={row.type}
                  placeholder={row.placeholder}
                  options={row.options}
                  onChange={(e) => handleChangeInfo(row.id, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id })}
                >
                  <option id=""></option>
                  {
                    row.options && row.options.map((value) => {
                      return (
                        <option id={value.value}>{value.label}</option>
                      );
                    })

                  }
                </Input>
                <div className="invalid-feedback">
                  Este campo no puede estar vacío
                </div>


              </FormGroup>
            </Col>
          )
        )}
        <hr className='border'></hr>

      </Row>

      {/*JSON.stringify(newInfo)*/}
      <hr className='mt-1 ' />
    </>
  )
})

export default BawItemForm;
