import React, { useContext, useState } from "react";
// Componentes de reactstrap
import {
  Container,
  FormGroup,
  Col,
  Row,
  Button,
  Card,
  CardHeader,
} from "reactstrap";

import { useDispatch } from "react-redux";
import { Upload, Icon } from "antd";

//import del Json de GeneralStates
import formFields from "../SharedComponents/GeneralStates";

//Endpoint de masterData
import { deleteFileByTempId } from "actions/masterData";

//Importar swal para el toast
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//Urls
import urls from "api/urls.jsx";

import { encrypter } from 'helpers/desencrypt.jsx';


//Para subir archivos
const { Dragger } = Upload;

const MasiveInfo = (props) => {
  const {
    typeForm,
    metodo,
    setFileListRequest,
    fileListRequest,
    temporalRequestId,
    stillChargingDocuments,
    setStillChargingDocuments,
    formFieldsDb
  } = props;

  //Desestructuración del objeto de fileList
  const { fileList } = fileListRequest;

  //Para disparar al api de ss
  const dispatch = useDispatch();

  //#region Notificaciones Toast
  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  //#endregion

  //State diseñado ya que cuando sube un archivo por alguna razón se ejecuta handleOnFileList varias veces, entonces es un indicador para saber si es la primera vez que se ejecuta o la segunda vez o tercera...
  const [iterations, setIterations] = useState(0);

  const handleOnFileList = (info) => {
    // debugger;
    const { status } = info.file;
    console.log(info);
    console.log(fileList);
    // if (fileList.length > 0) {
    const existFileInFileListWithSameName = fileList.filter(itemDoc => itemDoc.name === info.file.name)

    if (existFileInFileListWithSameName.length > 0 && iterations === 0 && status !== "removed") {
      Toast.fire({
        title: "Atención",
        html: "Debe cambiar el nombre del archivo ya que hay uno en la lista con el mismo nombre.",
        type: "warning",
      });
      return;
    }

    setIterations(iterations + 1);
    // }

    //Mientras está cargando por alguna razón se ejecuta varias veces este método, entonces que lo ejecute solo una vez.
    if (stillChargingDocuments.chargingApproversDocs === false) {
      setStillChargingDocuments({ ...stillChargingDocuments, chargingMasivesDocs: true }) //Se indica que se está cargando el doc en generalForm
    }

    //console.log(status);
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status },
        },
      } = info;
      // if (stat<us === 200) {
      setStillChargingDocuments({ ...stillChargingDocuments, chargingMasivesDocs: false })
      setFileListRequest((prevState) => ({
        ...prevState,
        fileList: fileList.filter((row) => row.uid !== uid),
      }));
      setIterations(0);
      console.log(fileList);
      // } else {
      //   setStillChargingDocuments({ ...stillChargingDocuments, chargingMasivesDocs: false })
      //   setFileListRequest((prevState) =>
      //     fileList.filter((row) => row.uid !== uid)
      //   );
      //   setIterations(0);
      // }

      dispatch(
        deleteFileByTempId({ temporalRequestId: temporalRequestId, name: name })
      ).then((resp) => {
        const { payload } = resp;
        if (payload.status !== 200) {
          console.log("Error al intentar borrar el archivo:");
          console.log(payload);
          setIterations(0);
        }
        setStillChargingDocuments({ ...stillChargingDocuments, chargingMasivesDocs: false })

      });
    } else {
      //Éxito
      const {
        fileList,
        file: { status, name, uid },
      } = info;
      if (status === "error") {
        console.log(`Ocurrió un error cargando el archivo ${name}.`);


        Toast.fire("Error", info.file.response.message);


      } else if (status === "done") {
        //console.log("Éxito cargando")
        setStillChargingDocuments({ ...stillChargingDocuments, chargingMasivesDocs: false })
        setIterations(0);
      }


      setFileListRequest({ fileList: fileList });

      console.log(fileList);

    }
  };

  const allowedFileTypes = [
    ".xlsx", ".xls"
  ];

  const uploadProps = {
    name: "file",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    accept: allowedFileTypes.join(","),
    multiple: false,
    action: `${urls.MASTERDATA.uploadFiles}/${temporalRequestId}/${formFields[typeForm].motherTable}/1`,
    beforeUpload: file => {
      const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
      if (!allowedFileTypes.includes(fileType)) {
        console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
        return false;
      }
      return true;
    },
    onChange: info => {
      console.log(info)
      // Filtrar la lista de archivos válidos para mantener solo los que aún existen
      const filteredFiles = info.fileList.filter(file => {
        console.log(file)
        if (file.status) {
          return true;
        }
        return false;
      });
      info.fileList = filteredFiles;
      // Actualizar la lista de archivos válidos
      // Llamar a la función handleOnFileList con la lista de archivos válidos
      handleOnFileList(info);
    },

  };

  const handleDownloadTemplate = (e) => {

    const downloadUrl = `${urls.MASTERDATA.downloadTemplates}/${encrypter(formFieldsDb[typeForm].plantilla)}`;

    fetch(downloadUrl, {
      method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
    })
      .then(response => {
        if (response.ok) {
          window.location.assign(downloadUrl);
        } else {
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
            type: "warning",

          });
        }
      })
      .catch(error => {
        console.error('Error checking file existence:', error);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error al validar el archivo ",
          type: "warning",

        });
      });
  };

  return (
    <>
      <Row>
        <Col sm={metodo === "ambos" ? 6 : 0}></Col>
        <Col sm={metodo === "ambos" ? 6 : 12}>
          <Card>
            <CardHeader>
              <Row className="mb-4">
                <Col className="text-center" sm="12">
                  <Button
                    block
                    color="info"
                    className="mt-2"
                    onClick={(e) => handleDownloadTemplate(e)}
                  >
                    <span className="btn-inner--icon mr-2">
                      <i className="fas fa-download mr-2"></i>
                    </span>
                    Plantilla de {formFields[typeForm].titulo}
                  </Button>
                </Col>
              </Row>
              <Row></Row>
              <Row className="mb-4">
                <Col sm="12">
                  <Container>
                    <Dragger {...uploadProps} fileList={fileList}>
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-uploading-text">
                        Suelte el archivo aquí o haga clic para cargar.
                      </p>
                      <p className="ant-uploading-hint">
                        Por favor selecciona únicamente la plantilla del dato maestro
                      </p>
                    </Dragger>
                  </Container>
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MasiveInfo;
