/* eslint-disable jsx-a11y/anchor-is-valid */
// core antd
import { Tabs, Icon, Upload } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Input, ListGroup, ListGroupItem, Row, Table } from "reactstrap";

const { TabPane } = Tabs;
const { Dragger } = Upload;

const SpareEquipments = props => {

  const {
    add,
    edit,
    cols,
    rows,
    title,
    isView,
    options,
    subtitle,
    fileList,
    idRequest,
    references,
    referenceView,
    initialValues,
    initialEditValues,
    onFileList,
    onEdit,
    onBack,
    onSave,
    onChange,
    onCreate,
    onUpdate,
    onDelete,
    onComplete,
  } = props;

  const uploadProps = {
    name: 'reference',
    multiple: true,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    action: `${urlBase}/digital-request/upload-reference-spare/${idRequest}`,
    onChange: onFileList,
  };

  const [tabKey, setTabKey] = useState("1");

  const changeTab = value => {
    setTabKey(value);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col xs="12" md={isView ? '9' : '7'}>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
          <Col className="text-right" xs="12" md={isView ? '3' : '5'}>
            <Row>
              {
                !isView &&
                <>
                  <Col xs='12' md="6" className="p-1 d-flex justify-content-end">
                    <Button
                      block
                      className="btn-neutral btn-round btn-icon"
                      size="sm"
                      onClick={onComplete}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-check-double" />
                      </span>
                      <span className="btn-inner--text">Carga Finalizada-Continuar</span>
                    </Button>
                  </Col>
                  <Col xs='12' md="3" className="p-1 d-flex justify-content-end">
                    <Button
                      block
                      className="btn-neutral btn-round btn-icon"
                      size="sm"
                      onClick={onSave}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Guardar</span>
                    </Button>
                  </Col>
                </>
              }
              <Col xs='12' md={isView ? "12" : "3"} className="p-1 d-flex justify-content-end">
                <Button
                  block
                  className="btn-neutral btn-round btn-icon"
                  size="sm"
                  onClick={onBack}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-arrow-left" />
                  </span>
                  <span className="btn-inner--text">Cancelar</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Tabs defaultActiveKey={tabKey} onChange={changeTab}>
        <TabPane tab="Creación de Equipos" key="1">
          <Table
            className="align-items-center table-flush"
            responsive
            striped
          >
            <thead className="thead-light">
              <tr>
                {
                  cols.map((row, key) => (<th key={key}>{row.name}</th>))
                }
                {
                  !isView ? <th /> : null
                }
              </tr>
            </thead>
            <tbody className="list">
              {
                rows.map((row, key) => (
                  <tr key={key}>
                    {
                      edit && row.id === initialEditValues.id ?
                        options.map((row, key) => (
                          <td key={key}>
                            {
                              row.options ?
                                <div>
                                  <Input
                                    style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "auto" }}
                                    id={row.key}
                                    type={row.type}
                                    value={initialEditValues[row.key]}
                                    onChange={(e) => onChange(e, 'edit')}
                                  >
                                    <option key="0" value="0">{row.placeholder}</option>
                                    {
                                      row.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row.id}>{row.name}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                </div>
                                :
                                <Input
                                  style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "-webkit-fill-available" }}
                                  id={row.key}
                                  type={row.type}
                                  placeholder={row.placeholder}
                                  value={initialEditValues[row.key]}
                                  onChange={(e) => onChange(e, 'edit')}
                                  maxLength={row.max}
                                  max={row.max}
                                  min={1}
                                />
                            }
                          </td>
                        ))
                        :
                        cols.map((col, key) => (
                          <td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {
                              row[col.key]
                            }
                          </td>
                        ))
                    }
                    <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                      {
                        edit && row.id === initialEditValues.id ?
                          <a
                            className="table-action table-action"
                            href="#"
                            onClick={onUpdate}
                          >
                            <i className="fas fa-check-double" />
                          </a>
                          :
                          !isView ?
                            <>
                              <a
                                className="table-action table-action"
                                href="#"
                                onClick={() => onEdit(row)}
                              >
                                <i className="fas fa-edit" />
                              </a>
                              <a
                                className="table-action table-action"
                                href="#"
                                onClick={() => onDelete(row)}
                              >
                                <i className="fas fa-trash" />
                              </a>
                            </>
                            : null
                      }
                    </th>
                  </tr>
                ))
              }
              <tr>
                {
                  add &&
                  options.map((row, key) => (
                    <td key={key}>
                      {
                        row.options ?
                          <div>
                            <Input
                              style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "auto" }}
                              id={row.key}
                              type={row.type}
                              value={initialValues[row.key]}
                              onChange={(e) => onChange(e, 'add')}
                            >
                              <option key="0" value="0">{row.placeholder}</option>
                              {
                                row.options.map((row, key) => {
                                  return (
                                    <option key={key} value={row.id}>{row.name}</option>
                                  )
                                })
                              }
                            </Input>
                          </div>
                          :
                          <Input
                            style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "-webkit-fill-available" }}
                            id={row.key}
                            type={row.type}
                            placeholder={row.placeholder}
                            value={initialValues[row.key]}
                            onChange={(e) => onChange(e, 'add')}
                            maxLength={row.max}
                            max={row.max}
                            min={1}
                          />
                      }
                    </td>
                  ))
                }
                {
                  add &&
                  <td style={{ whiteSpace: "normal", textAlign: "center" }}>
                    <Button
                      className="btn-icon btn-3"
                      size="sm"
                      color="info"
                      disabled={Object.keys(initialValues).some((row) => initialValues[row] === undefined || initialValues[row] === "" || initialValues[row] === "0")}
                      type="button"
                      onClick={onCreate}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Crear</span>
                    </Button>
                  </td>
                }
              </tr>
            </tbody>
          </Table>
          {
            referenceView &&
            <CardBody className="px-lg-5 py-lg-3">
              <blockquote className="blockquote text-center">
                <p className="mb-0">
                  Cargar Referencia
                </p>
                <footer className="blockquote-footer">
                  <cite title="Source Title">Por favor, cargue la referencia de respaldo para el equipo o los equipos spare creados.</cite>
                </footer>
              </blockquote>
              <div className="form-row align-items-center">
                <Col className="m-1" sm="12">
                  <Dragger {...uploadProps} fileList={fileList}>
                    <p className="ant-uploading-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                    <p className="ant-uploading-hint">
                      *Se debe cargar al menos una referencia para el equipo creado.
                    </p>
                  </Dragger>
                </Col>
              </div>
            </CardBody>
          }
        </TabPane>
        {
          references.length &&
          <TabPane tab="Archivos Cargados" key="2">
            <Card>
              <CardBody>
                <ListGroup className="list my--3" flush>
                  {
                    references.map((row, key) => (
                      <ListGroupItem key={key} className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="Referencia"
                              className="avatar rounded-circle"
                              src={require(`assets/img/theme/attachment.png`)}
                            />
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              {row.name}
                            </h4>
                            <small>
                              {
                                `${row.createdBy} - ${moment(row.createdAt)
                                  .utc()
                                  .utcOffset(moment().utcOffset())
                                  .format("LLL")}`
                              }
                            </small>
                          </div>
                          <Col className="col-auto">
                            <Button
                              color="info"
                              size="sm"
                              href={`${urlBase}/digital-request/download-attachment/${window.btoa(row.path)}/${window.btoa(row.name)}`}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-file-download" />
                              </span>
                              <span className="btn-inner--text">Descargar</span>
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))
                  }
                </ListGroup>
              </CardBody>
            </Card>
          </TabPane>
        }
      </Tabs>
    </Card>
  );
};

SpareEquipments.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default SpareEquipments;
