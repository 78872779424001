import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { getDraftInterview } from "actions/exitInterview";
import { deleteDraftInterview } from "actions/exitInterview";
import moment from "moment";

const DraftTable = ({
  rows,
  handleOnDraftOpenModal,
  setPage,
  page,
  setRowsModal,
  Toast,
  setLoad,
  isAdmin
}) => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState("");
  const selected = [
    { value: "collaboratorName", label: "Nombre de Colaborador" },
    { value: "immediateBoss", label: "Jefe Inmediato" },
    { value: "date", label: "Fecha de entrevista" },
    { value: "interviewStatus", label: "Estatus de la Entrevista" },
  ];

  const deleDraftInterview = async (e) => {
    const id = e.idInterview;
    dispatch(deleteDraftInterview({ id: id })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        dispatch(getDraftInterview()).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setRowsModal((prevState) => ({
              ...prevState,
              rowsDraft: payload.data.payload.data,
            }));
          } else {
            setRowsModal((prevState) => ({
              ...prevState,
              rowsDraft: [],
            }));
          }
          Toast.fire({
            title: "Atención",
            html: "Se elimino correctamente",
            type: "success",
          });
        });
      } else {
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error, no se pudo eliminar",
          type: "danger",
        });
      }
      setAlert("");
    });
  };
  const handleOnDeleteDraftEnterview = (row) => {
    setAlert(
      <ReactBSAlert
        custom
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Está seguro(a) que desea eliminar el borrador?"
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        onConfirm={() => deleDraftInterview(row)}
        onCancel={() => setAlert("")}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sí, eliminar"
        cancelBtnText="No, cancelar"
        btnSize="md"
      />
    );
  };
  const [state, setState] = useState({
    sizePerPage: 10,
  });
  const { sizePerPage } = state;
  //#region Pagination

  const paginations =
    rows.length > sizePerPage
      ? rows.length / sizePerPage > Math.round(rows.length / sizePerPage)
        ? Math.round(rows.length / sizePerPage) + 1
        : Math.round(rows.length / sizePerPage)
      : 1;

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink onClick={() => handleOnSetPage(i, "page")}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return options.length >= 5
      ? page >= 5
        ? options.slice(page - 3, page + 2)
        : options.slice(0, 5)
      : options;
  };
  //Cambiar de pagina
  const handleOnSetPage = (page, who) => {
    setPage(page);
  };

  const handleOnSetNumPagination = (e) => {
    setPage(1);
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      sizePerPage: value,
    }));
  };
  //#endregion

  useEffect(() => {
    const getRowsData = async () => {
      await handleRowInfo();
    };
    getRowsData();
  }, []);

  const handleRowInfo = async () => {
    setLoad(true);
    dispatch(getDraftInterview()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal((prevState) => ({
          ...prevState,
          rowsDraft: payload.data.payload.data,
        }));
      } else {
        setRowsModal((prevState) => ({
          ...prevState,
          rowsDraft: [],
        }));
      }
      setLoad(false);
    });
  };
  //#endregion
  return (
    <div>
      {alert}
      <Card>
        <CardBody>
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">
              <tr>
                {selected.map((item, key) => {
                  return (
                    <th key={key} className="justify-content-md-center ">
                      <FormGroup key={key}>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${item.label}:`}
                        </label>
                      </FormGroup>
                    </th>
                  );
                })}
                <th>Modificar Borrador</th>
                {isAdmin === true &&
                  <th>Eliminar</th>
                }

              </tr>
            </thead>
            <tbody className="list">
              {rows
                .slice((page - 1) * sizePerPage, page * sizePerPage)
                .map((item, key) => (
                  <tr key={key} /*className="text-center"*/>
                    {selected.map((col, key) => (
                      <td key={key} /*className="text-center"*/>
                        {col.value === "date" ? (
                          moment.utc(item[col.value]).format("L")
                        ) : col.value === "interviewStatus" ? (
                          <button
                            type="button"
                            className={`btn btn-round btn-wd btn-sm  ${item.interviewStatusId === 1
                              ? "btn-warning"
                              : item.interviewStatusId ===
                                2 ? "btn-info" : "btn-success"
                              }`}
                          >
                            {" "}
                            {item[col.value]}{" "}
                          </button>
                        ) : (
                          item[col.value]
                        )}
                      </td>
                    ))}

                    <td key={`tdInfo${key}`} className="text-center">
                      <Button
                        key={key}
                        className="btn"
                        color="secondary"
                        id="botonTool"
                        onClick={() => handleOnDraftOpenModal(item)}
                      >
                        <span className="btn-inner--icon mr-">
                          <i className="far fa-edit"></i>
                        </span>
                        <span className="btn-inner--text"></span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="botonTool">
                        Editar entrevista
                      </UncontrolledTooltip>
                    </td>
                    {isAdmin === true &&
                      <td>
                        <Button
                          id="Delete"
                          className="btn"
                          color="danger"
                          onClick={() => handleOnDeleteDraftEnterview(item)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="far fa-trash-alt"></i>
                          </span>
                        </Button>
                      </td>
                    }

                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="p-1">
                <Row className="justify-content-start">
                  <Col xs="12" md="6">
                    <span className="pagination mb-0">
                      Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                      {page * sizePerPage > rows.length
                        ? rows.length
                        : page * sizePerPage}{" "}
                      de {rows.length} resultados
                    </span>
                  </Col>
                  <Col xs="12" md="2">
                    <Input
                      type="select"
                      onChange={(e) => handleOnSetNumPagination(e)}
                    >
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="6" className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() =>
                        handleOnSetPage(page === 1 ? page : page - 1, "page")
                      }
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {renderPaginations()}
                  <PaginationItem
                    className={page === paginations ? "disabled" : ""}
                  >
                    <PaginationLink
                      onClick={() =>
                        handleOnSetPage(
                          page === paginations ? page : page + 1,
                          "page"
                        )
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>
    </div>
  );
};

export default DraftTable;
