/* eslint-disable jsx-a11y/anchor-is-valid */
// core antd
import { Icon, Upload } from "antd";
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

const { Dragger } = Upload;

const Request = (props) => {
  const {
    title,
    subtitle,
    opp: { idList, opportunityNumber, state, customer, createdBy },
    commentary,
    comments,
    files,
    cols,
    rows,
    values,
    fileList,
    onBack,
    onSave,
    onChange,
    onChangeComment,
    onUpload,
    onComplete,
    onDownload,
    onFileList,
    onNewActivityFlow,
  } = props;

  const uploadProps = {
    name: "reference",
    multiple: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    action: `${urlBase}/inventories/upload-reference-sustitutes-and-cost/${idList}`,
    onChange: onFileList,
  };

  return (
    <>
      <Row>
        <Col sm="12" md="6">
          <Card className="border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Oportunidad - Sales Rep
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {opportunityNumber} - {createdBy}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                    <i className="fas fa-user-cog" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6">
          <Card className="border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Cliente
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">{customer}</span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-danger text-white rounded-circle shadow">
                    <i className="fas fa-building" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        {/* <Col sm='12' md='6'>
          <Card className="border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0"
                    tag="h5"
                  >
                    Estado Actual
                    </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {status}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                    <i className="ni ni-bell-55" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="12" md={state === 0 ? "7" : "9"}>
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            <Col className="text-right" xs="12" md={state === 0 ? "5" : "3"}>
              {state === 0 && (
                <Row>
                  <Col md="8"></Col>
                  <Col className="p-1 d-flex justify-content-end">
                    <Button
                      block
                      className="btn-neutral btn-round btn-icon"
                      id="flow"
                      size="sm"
                      onClick={onNewActivityFlow}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus-square" />
                      </span>
                      <span className="btn-inner--text">Agregar Nota</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="flow">
                      Crear nota al flujo de actividades
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  xs="12"
                  md={state === 0 ? "4" : "12"}
                  className="p-1 d-flex justify-content-end"
                >
                  <Button
                    block
                    className="btn-neutral btn-round btn-icon"
                    id="back"
                    size="sm"
                    onClick={onBack}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-arrow-left" />
                    </span>
                    <span className="btn-inner--text">Salir</span>
                  </Button>
                  <UncontrolledTooltip delay={0} target="back">
                    Salir de la cotización
                  </UncontrolledTooltip>
                </Col>
                {state === 0 && (
                  <Col
                    xs="12"
                    md="4"
                    className="p-1 d-flex justify-content-end"
                  >
                    <Button
                      block
                      className="btn-neutral btn-round btn-icon"
                      id="save"
                      size="sm"
                      onClick={onSave}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Guardar</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="save">
                      Guardar la cotización
                    </UncontrolledTooltip>
                  </Col>
                )}
                {state === 0 && (
                  <Col
                    xs="12"
                    md="4"
                    className="p-1 d-flex justify-content-end"
                  >
                    <Button
                      block
                      className="btn-neutral btn-round btn-icon"
                      id="finish"
                      size="sm"
                      onClick={onComplete}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-check-double" />
                      </span>
                      <span className="btn-inner--text">Finalizar</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="finish">
                      Finalizar la cotización
                    </UncontrolledTooltip>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              {cols.map((row, key) => (
                <th key={key}>{row.name}</th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody className="list">
            {rows.map((row, key) => (
              <tr key={key}>
                {key === 0 ? (
                  <>
                    <td
                      rowSpan={row.counts}
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      {row.amountEquipments}
                    </td>
                    <td
                      rowSpan={row.counts}
                      style={{ whiteSpace: "normal", textAlign: "justify" }}
                    >
                      {row.typeModel}
                    </td>
                  </>
                ) : (
                  rows[key - 1].idEquipment !== row.idEquipment && (
                    <>
                      <td
                        rowSpan={row.counts}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        {row.amountEquipments}
                      </td>
                      <td
                        rowSpan={row.counts}
                        style={{ whiteSpace: "normal", textAlign: "justify" }}
                      >
                        {row.typeModel}
                      </td>
                    </>
                  )
                )}
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.id}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.platform}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.category}
                </td>
                <td style={{ textAlign: "justify" }}>{row.description}</td>
                <td style={{ textAlign: "justify" }}>{row.classify}</td>
                {/* <td style={{ textAlign: "justify" }}>{row.definedClassify}</td> */}
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.fru}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {row.amount}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {state === 0 ? (
                    <Input
                      style={{ width: "auto", margin: "0 0" }}
                      id={`substitute1-${row.id}`}
                      placeholder="Ingrese el Sustituto 1"
                      type="text"
                      value={values[`substitute1-${row.id}`]}
                      onChange={(e) => onChange(e)}
                      maxLength={17}
                    />
                  ) : (
                    row.substitute1
                  )}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {state === 0 ? (
                    <Input
                      style={{ width: "auto", margin: "0 0" }}
                      id={`substitute2-${row.id}`}
                      placeholder="Ingrese el Sustituto 2"
                      type="text"
                      value={values[`substitute2-${row.id}`]}
                      onChange={(e) => onChange(e)}
                      maxLength={17}
                    />
                  ) : (
                    row.substitute2
                  )}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {state === 0 ? (
                    <Input
                      style={{ width: "auto", margin: "0 0" }}
                      id={`substitute3-${row.id}`}
                      placeholder="Ingrese el proveedor"
                      type="text"
                      value={values[`substitute3-${row.id}`]}
                      onChange={(e) => onChange(e)}
                      maxLength={17}
                    />
                  ) : (
                    row.substitute3
                  )}
                </td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  {state === 0 ? (
                    <Input
                      style={{ width: "auto", margin: "0 0" }}
                      id={`cost-${row.id}`}
                      placeholder="Ingrese el costo"
                      type="number"
                      value={values[`cost-${row.id}`]}
                      onChange={(e) => onChange(e)}
                      min={1}
                      max={99999999.99}
                    />
                  ) : (
                    row.cost
                  )}
                </td>
                {state === 0 ? (
                  key === 0 ? (
                    <td className="table-actions" rowSpan={row.counts}>
                      <a
                        className="table-action table-action"
                        href="#"
                        id="download"
                        onClick={() =>
                          onDownload(
                            row.idEquipment,
                            `CotizaciónOpp${opportunityNumber}_equipo${row.typeModel}.`
                          )
                        }
                      >
                        <i className="fas fa-download" />
                      </a>
                      <a
                        className="table-action table-action"
                        href="#"
                        id="upload"
                        onClick={onUpload}
                      >
                        <i className="fas fa-upload" />
                      </a>
                    </td>
                  ) : (
                    rows[key - 1].idEquipment !== row.idEquipment && (
                      <td className="table-actions" rowSpan={row.counts}>
                        <a
                          className="table-action table-action"
                          href="#"
                          id="download"
                          onClick={() =>
                            onDownload(
                              row.idEquipment,
                              `CotizaciónOpp${opportunityNumber}_equipo${row.typeModel}.`
                            )
                          }
                        >
                          <i className="fas fa-download" />
                        </a>
                        <a
                          className="table-action table-action-delete"
                          href="#"
                          id="upload"
                          onClick={onUpload}
                        >
                          <i className="fas fa-upload" />
                        </a>
                      </td>
                    )
                  )
                ) : null}
              </tr>
            ))}
          </tbody>
        </Table>
        {state === 0 && (
          <CardBody>
            <div className="form-row align-items-center pt-1">
              <Col sm="12" className="m-1">
                <blockquote className="blockquote text-center">
                  <p className="mb-0">Cargar Referencia</p>
                  <footer className="blockquote-footer">
                    <cite title="Source Title">
                      Por favor, cargue el archivo o los archivos de respaldo
                      para las cotizaciones
                    </cite>
                  </footer>
                </blockquote>
                <div className="form-row align-items-center">
                  <Col className="m-1" sm="12">
                    <Dragger {...uploadProps} fileList={fileList}>
                      <p className="ant-uploading-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-uploading-text">
                        Suelte el archivo aquí o haga clic para cargar.
                      </p>
                      <p className="ant-uploading-hint">
                        *Se debe cargar los respaldos.
                      </p>
                    </Dragger>
                  </Col>
                  <Col sm="12" className="m-1">
                    <FormGroup>
                      <label
                        className="d-flex justify-content-center"
                        htmlFor="commentary"
                      >
                        <blockquote className="blockquote text-center">
                          <p className="mb-0">Comentarios</p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">
                              En caso de contar con algún tipo de observación.
                              (Espacio opcional)
                            </cite>
                          </footer>
                        </blockquote>
                      </label>
                      <Input
                        className="m-0"
                        id="commentary"
                        resize="none"
                        rows="3"
                        type="textarea"
                        value={commentary}
                        onChange={(e) => onChangeComment(e)}
                        maxLength={200}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" className="m-1">
                    <ListGroup className="list my--3" flush>
                      {comments.length ? (
                        comments.map((row, key) => (
                          <ListGroupItem key={key} className="px-0">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="Notificación"
                                  className="avatar rounded-circle"
                                  src={require(`assets/img/theme/inventory-price.png`)}
                                />
                              </Col>
                              <div className="col ml--2">
                                <h4 className="mb-0">{row.comentario}</h4>
                                <small>
                                  {`${row.createdBy} - ${moment(row.createdAt)
                                    .utc()
                                    .utcOffset(moment().utcOffset())
                                    .format("LLL")}`}
                                </small>
                              </div>
                            </Row>
                          </ListGroupItem>
                        ))
                      ) : (
                        <div className="pt-4 pb-4">
                          <h5 className="h3 title">
                            <span className="d-block mb-1 text-center">
                              Sin documentos
                            </span>
                            <small className="h3 font-weight-light text-muted">
                              Al requerimiento que se seleccionó, no se le
                              realizo la carga de ningún archivo de referencia.
                            </small>
                          </h5>
                        </div>
                      )}
                    </ListGroup>
                  </Col>
                </div>
              </Col>
            </div>
            <div className="form-row align-items-center">
              <Col className="m-2" sm="12" md="3">
                <Button
                  className="btn-icon"
                  color="danger"
                  onClick={() => onBack(null)}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-arrow-left" />
                  </span>
                  <span className="btn-inner--text">Regresar</span>
                </Button>
              </Col>
            </div>
          </CardBody>
        )}
      </Card>
      {state === 1 && (
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="12" md="9">
                <h6 className="surtitle">Comentarios</h6>
                <h5 className="h3 mb-0">
                  Lista de comentarios agregados al requerimiento seleccionado
                </h5>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="form-row align-items-center pt-1">
              <Col sm="12" className="m-1">
                <ListGroup className="list my--3" flush>
                  {comments.length ? (
                    comments.map((row, key) => (
                      <ListGroupItem key={key} className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="Notificación"
                              className="avatar rounded-circle"
                              src={require(`assets/img/theme/inventory-price.png`)}
                            />
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">{row.comentario}</h4>
                            <small>
                              {`${row.createdBy} - ${moment(row.createdAt)
                                .utc()
                                .utcOffset(moment().utcOffset())
                                .format("LLL")}`}
                            </small>
                          </div>
                        </Row>
                      </ListGroupItem>
                    ))
                  ) : (
                    <div className="pt-4 pb-4">
                      <h5 className="h3 title">
                        <span className="d-block mb-1 text-center">
                          Sin comentarios
                        </span>
                        <small className="h3 font-weight-light text-muted">
                          Al requerimiento que se seleccionó, no se le agrego
                          ningun comentario
                        </small>
                      </h5>
                    </div>
                  )}
                </ListGroup>
              </Col>
            </div>
          </CardBody>
        </Card>
      )}
      {state === 1 && (
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="12" md="9">
                <h6 className="surtitle">Adjuntos</h6>
                <h5 className="h3 mb-0">
                  Lista de archivos agregados al requerimiento seleccionado
                </h5>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="form-row align-items-center pt-1">
              <Col sm="12" className="m-1">
                <ListGroup className="list my--3" flush>
                  {files.length ? (
                    files.map((row, key) => (
                      <ListGroupItem key={key} className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="Notificación"
                              className="avatar rounded-circle"
                              src={require(`assets/img/theme/attachment.png`)}
                            />
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">{row.nombre}</h4>
                            <small>
                              {`${row.createdBy} - ${moment(row.createdAt)
                                .utc()
                                .utcOffset(moment().utcOffset())
                                .format("LLL")}`}
                            </small>
                          </div>
                          <Col className="col-auto">
                            <Button
                              color="info"
                              size="sm"
                              href={`${urlBase}/inventories/download-attachment/${window.btoa(
                                row.ruta
                              )}/${window.btoa(row.nombre)}`}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-file-download" />
                              </span>
                              <span className="btn-inner--text">Descargar</span>
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))
                  ) : (
                    <div className="pt-4 pb-4">
                      <h5 className="h3 title">
                        <span className="d-block mb-1 text-center">
                          Sin comentarios
                        </span>
                        <small className="h3 font-weight-light text-muted">
                          Al requerimiento que se seleccionó, no se le agrego
                          ningun comentario
                        </small>
                      </h5>
                    </div>
                  )}
                </ListGroup>
              </Col>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

Request.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default Request;
