// core antd
import { Icon, Upload } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";

const { Dragger } = Upload;

const CardConfiguration = props => {

  const {
    title,
    subtitle,
    idRequest,
    fileList,
    onBack,
    onChange,
    onComplete,
    onFileList,
  } = props;

  const uploadProps = {
    name: 'configuration',
    multiple: true,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    action: `${urlBase}/digital-request/upload-configuration-files/${idRequest}`,
    onChange: onFileList,
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col xs="12" md='7'>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
          <Col className="text-right" xs="12" md='5'>
            <Button
              className="btn-icon"
              color="success"
              onClick={onComplete}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-check-double" />
              </span>
              <span className="btn-inner--text">Completar Proceso</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="form-row align-items-center pt-1">
          <Col sm='12' className="m-1">
            <blockquote className="blockquote text-center">
              <p className="mb-0">
                Cargar Configuración
                </p>
              <footer className="blockquote-footer">
                <cite title="Source Title">Por favor, cargue el archivo o los archivos de la configuración de equipos</cite>
              </footer>
            </blockquote>
            <div className="form-row align-items-center">
              <Col className="m-1" sm="12">
                <Dragger {...uploadProps} fileList={fileList}>
                  <p className="ant-uploading-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                  <p className="ant-uploading-hint">
                    *Se debe cargar la configuración.
                    </p>
                </Dragger>
              </Col>
              <Col sm='12' className="m-1">
                <FormGroup>
                  <label
                    className="d-flex justify-content-center"
                    htmlFor="commentary"
                  >
                    <blockquote className="blockquote text-center">
                      <p className="mb-0">
                        Comentarios
                    </p>
                      <footer className="blockquote-footer">
                        <cite title="Source Title">En caso de contar con algún tipo de observación. (Espacio opcional)</cite>
                      </footer>
                    </blockquote>
                  </label>
                  <Input
                    className="m-0"
                    id="commentary"
                    resize="none"
                    rows="3"
                    type="textarea"
                    onChange={(e) => onChange(e)}
                    maxLength={200}
                  />
                </FormGroup>
              </Col>
            </div>
          </Col>
        </div>
        <div className="form-row align-items-center">
          <Col className="m-2" sm="12" md="3">
            <Button
              className="btn-icon"
              color="danger"
              onClick={onBack}
              size='sm'
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Regresar</span>
            </Button>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

CardConfiguration.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default CardConfiguration;
