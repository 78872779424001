// core actions
import { createUserAssignmentByPlanner, findRequestsPendingAssignation, validateGbmCollaborator } from "actions/Sales/plannersFlow.jsx";
import { updateActivityFlow } from "actions/Sales/digitalRequest.jsx";
// core components Sales
import CardRequests from 'components/Sales/PlannersFlow/CardRequests.jsx';
import WorkFlow from 'components/Sales/PlannersFlow/WorkFlow.jsx';
// core components Shared
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import CardEmpty from 'components/Shared/Notifications/CardEmpty.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getRequirementsPlanners } from "selectors/Sales/plannersFlow.jsx";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

class PlannersFlow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      willMount: true,
      loading: false,
      message: null,
      showModal: false,
      requestSelected: null,
      disabled: true,
      initialValues: {},
      infoUser: {},
      alert: null
    };
  };

  componentDidMount() {
    this.props.findRequestsPendingAssignation().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState((state) => ({
        willMount: !state.willMount
      }));
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  _handleOnToggleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal
    }));
  }
  modalWorFlow = (row) => {
    this.setState((state) => ({
      showModal: !state.showModal,
      requestSelected: row,
      alert: null
    }));
  }
  _handleOnAllocateResources = row => {
    if (row.state === 9) {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="Asegúrese de validar con la JTR en la matriz de partes críticas"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.modalWorFlow(row)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, estoy seguro"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        )
      });
    }else{
      this.modalWorFlow(row);
    }
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  _handleOnValidateUser = async values => {
    const { username } = values;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Validando el usuario ingresado con la información de la compañia`,
      initialValues: { username },
      infoUser: {},
    }));
    await this.props.validateGbmCollaborator({ username: username.split('@')[0].toUpperCase() }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState({
          disabled: true,
        });
      } else {
        const { data: { payload: { response } } } = res.payload;
        this.setState({
          initialValues: { username: response.EMAIL },
          infoUser: response,
          disabled: false,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null
      }));
    });
  };

  _handleOnWorkFlow = async values => {
    const { infoUser, requestSelected: { id } } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Asignando el recurso a la oportunidad`
    }));
    await this.props.createUserAssignmentByPlanner(id, {
      user: infoUser
    }).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        await this.props.updateActivityFlow(id, {
          description: `Asignación del ingeníero ${infoUser.USERNAME} por Planner`,
          state: 'Asignación Completada',
          type: 'plannerFlow'
        });
        this.setState({
          initialValues: {},
          infoUser: {},
          disabled: true,
          requestSelected: null
        });
        this._handleOnToggleModal();
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null
      }));
    });
  };

  render() {

    const {
      name,
      parentName,
      requests
    } = this.props;

    const {
      loading,
      message,
      disabled,
      infoUser,
      willMount,
      showModal,
      initialValues,
      requestSelected,
      alert
    } = this.state;

    return (
      <>
        {alert}
        <WorkFlow
          type={
            requestSelected ? requestSelected.state === 5 ? 'Obtener Configuración' : requestSelected.state === 6 ? 'Identificar Partes' :'Validar JTR ' : ''
          }
          message={message}
          user={infoUser}
          isInvalid={disabled}
          loading={loading}
          showModal={showModal}
          initialValues={initialValues}
          toggleModal={this._handleOnToggleModal}
          onValidete={this._handleOnValidateUser}
          onWorkFlow={this._handleOnWorkFlow}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          {
            willMount ?
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de oportunidades en espera de asignación" />
                </Col>
              </Row>
              :
              requests.length ?
                <CardRequests
                  title="Lista de Oportunidades"
                  subtitle="Oportunidades pendientes de la asignación de un recurso"
                  rows={requests}
                  onAllocate={this._handleOnAllocateResources}
                />
                :
                <Row className="justify-content-center">
                  <Col sm="12" md="8">
                    <CardEmpty
                      title="No hay oportunidades pendientes."
                      subtitle="Actualmente al día de hoy, no tienes requerimientos digitales creados, cuando crees tus requerimientos se enlistarán en esta ventana."
                    />
                  </Col>
                </Row>
          }
        </Container>
      </>
    );
  }
}

PlannersFlow.defaultProps = {
  name: "Oportunidades Pendientes",
  parentName: "Notificaciones",
  columns: [
    { key: "id", name: "ID" },
  ],
  requests: [],
}

PlannersFlow.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  requests: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  requests: getRequirementsPlanners(state)
});

export default withRouter(connect(mapStateToProps, {
  findRequestsPendingAssignation,
  createUserAssignmentByPlanner,
  updateActivityFlow,
  validateGbmCollaborator
})(PlannersFlow));
