// javascript library for dates
import Moment from 'moment';
import { extendMoment } from 'moment-range';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

const moment = extendMoment(Moment);

const ViewModeCalendar = props => {

  const {
    viewMode,
    today,
    week,
    month,
    months,
    startDate,
    endDate,
    selectDay,
    selectWeek,
    selectMonth,
  } = props;

  const renderDaysCard = () => {
    const cards = [];
    let startDay = moment(startDate).format('d');
    const endDay = moment(endDate - 1).format('d');
    while (startDay <= endDay) {
      const actualDay = moment(startDate).add(startDay, 'days');
      const color = moment(actualDay).format('L') === moment(today).format('L') ? '#c9f5d0' : '#ffffff';
      cards.push(
        <Card
          key={startDay}
          className="card-stats card-lift--hover"
          onClick={() => selectDay(actualDay, actualDay.week())}
          style={{ backgroundColor: color, cursor: "pointer" }}
        >
          <CardBody>
            <Row>
              <Col sm='12'>
                <CardTitle
                  tag="h4"
                  className="text-uppercase text-muted mb-0 text-center"
                >
                  {moment(actualDay).format('dddd')}
                </CardTitle>
              </Col>
            </Row>
            <p className="mt-3 mb-0 text-sm  text-center">
              <strong>
                <span className="text-nowrap">{moment(actualDay).format('D')}</span>
              </strong>
            </p>
          </CardBody>
        </Card>
      )
      startDay++;
    }

    return (
      <Row>
        <Col sm='12' md='7'>
          <Row>
            {
              cards.slice(0, 4).map((row, key) => {
                return (
                  <Col sm='12' md='3' key={key}>
                    {row}
                  </Col>
                )
              })
            }
          </Row>
        </Col>
        <Col sm='12' md='5'>
          <Row>
            {
              cards.slice(4, 7).map((row, key) => {
                return (
                  <Col sm='12' md='4' key={key}>
                    {row}
                  </Col>
                )
              })
            }
          </Row>
        </Col>
      </Row>
    );
  }

  const renderWeeksCard = () => {
    // console.log("Fecha de Inicio", startDate);
    const firstDay = moment(startDate).startOf('month');
    const endDay = moment(endDate - 1).endOf('month');
    // console.log("Primer dia", firstDay.format());
    // console.log("Ultimo dia", endDay.format());
    const monthRange = moment.range(firstDay, endDay);
    const weeksNumbers = []
    for (let mday of monthRange.by('days')) {
      // console.log("mday", mday.week());
      if (weeksNumbers.indexOf(mday.week()) === -1) {
        weeksNumbers.push(mday.week());
      }
    }
    // console.log("Numero de semanas", weeksNumbers);
    const cards = [];
    for (const element in weeksNumbers) {
      let firstWeekDay = moment(startDate).week(weeksNumbers[element]).weekday(0);
      if (firstWeekDay.isBefore(firstDay)) {
        firstWeekDay = firstDay;
      }
      let lastWeekDay = moment(startDate).week(weeksNumbers[element]).weekday(6);
      if (lastWeekDay.isAfter(endDay)) {
        lastWeekDay = endDay;
      }
      // console.log("Primer dia de semana", firstWeekDay.format());
      // console.log("Ultimo dia de semana", lastWeekDay.format());
      const color = weeksNumbers[element] === week ? '#c9f5d0' : '#ffffff';
      cards.push(
        <Card
          key={weeksNumbers[element]}
          className="card-stats card-lift--hover"
          onClick={() => selectWeek(firstWeekDay, weeksNumbers[element])}
          style={{ backgroundColor: color, cursor: "pointer" }}
        >
          <CardBody>
            <Row>
              <Col sm='12'>
                <CardTitle
                  tag="h4"
                  className="text-uppercase text-muted mb-0 text-center"
                >
                  {`Semana ${parseInt(element) + 1}: ${moment(firstWeekDay).format('D')}-${moment(lastWeekDay).format('D')}`}
                </CardTitle>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )
    }
    return (
      <Row>
        <Col sm='12' md={cards.length < 6 ? '7' : '6'}>
          <Row>
            {
              cards.slice(0, 3).map((row, key) => {
                return (
                  <Col sm='12' md='4' key={key}>
                    {row}
                  </Col>
                )
              })
            }
          </Row>
        </Col>
        <Col sm='12' md={cards.length < 6 ? '5' : '6'}>
          <Row>
            {
              cards.slice(3, cards.length).map((row, key) => {
                return (
                  <Col sm='12' md={cards.length < 6 ? '6' : '4'} key={key}>
                    {row}
                  </Col>
                )
              })
            }
          </Row>
        </Col>
      </Row>
    );
  }

  const renderMonthsCard = () => {
    const index = months.length / 2;

    return (
      <>
        <Card>
          <CardHeader>
            <h6 className="surtitle">Meses disponibles para visualización</h6>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm='12' md='6'>
                <Row>
                  {
                    months.slice(0, index).map((row, key) => {
                      return (
                        <Col key={key} xs='12' sm='6' md={12 / index}>
                          <Badge
                            href=""
                            className="badge-lg"
                            style={{ cursor: "pointer" }}
                            color={row.key === month ? "success" : "primary"}
                            onClick={() => selectMonth(row.key)}
                          >
                            {row.name}
                          </Badge>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Col>
              <Col sm='12' md='6'>
                <Row>
                  {
                    months.slice(index, months.length).map((row, key) => {
                      return (
                        <Col key={key} xs='12' sm='6' md={12 / index}>
                          <Badge
                            href=""
                            style={{ cursor: "pointer" }}
                            className="badge-lg"
                            color={row.key === month ? "success" : "primary"}
                            onClick={() => selectMonth(row.key)}
                          >
                            {row.name}
                          </Badge>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }

  return (
    <>
      {
        viewMode === 'day' ?
          startDate && endDate ?
            renderDaysCard()
            : null
          : viewMode === 'week' ?
            renderWeeksCard()
            : renderMonthsCard()
      }
    </>
  );
};

ViewModeCalendar.propTypes = {
  viewMode: PropTypes.string.isRequired,
  today: PropTypes.object,
  week: PropTypes.number,
  month: PropTypes.number,
  months: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  selectDay: PropTypes.func,
  selectWeek: PropTypes.func,
  selectMonth: PropTypes.func,
};

export default ViewModeCalendar;