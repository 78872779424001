import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getOptionsData = createAction(constants.AUPP_GET_OPTIONS, method.get(urls.AUTOPPLDRS.getOptions));
export const getDataRows = createAction(constants.AUPP_GET_DATA, body => method.post(urls.AUTOPPLDRS.getRows, body)());
export const getDataBAWRows = createAction(constants.AUPP_GET_DATABAW, body => method.post(urls.AUTOPPLDRS.getBAWRows, body)());
export const getLDRsOptionsData = createAction(constants.AUPP_GET_LDRS_OPTIONS, method.get(urls.AUTOPPLDRS.getLdrsOptions));
export const getLDRFields = createAction(constants.AUPP_GET_LDR_FIELDS, method.get(urls.AUTOPPLDRS.getLdrFields));
export const getContacts = createAction(constants.AUPP_GET_CONTACTS, body => method.post(urls.AUTOPPLDRS.getContacts, body)());
export const getCostumers = createAction(constants.AUPP_GET_COSTUMERS, body => method.get(urls.AUTOPPLDRS.getCostumers, body)());
export const getEmployees = createAction(constants.AUPP_GET_EMPLOYEES, body => method.get(urls.AUTOPPLDRS.getEmployees, body)());
export const newRequest = createAction(constants.AUPP_NEW_REQUEST, body => method.post(urls.AUTOPPLDRS.newRequest, body)());
export const newBawDevolutionReq = createAction(constants.AUPP_NEW_DEVOLUTIONBAWREQ, body => method.post(urls.AUTOPPLDRS.newBawDevolutionReq, body)());
export const deleteFile = createAction(constants.AUPP_DELETE_FILE, body => method.post(urls.AUTOPPLDRS.deleteFile, body)());
export const deleteFolderBOM = createAction(constants.AUPP_DELETE_FOLDER_BOM, body => method.post(urls.AUTOPPLDRS.deleteFolderBOM, body)());

//AutoppCare
export const getAutoppCareOptions = createAction(constants.AUPP_CARE_OPTIONS, method.get(urls.AUTOPPLDRS.getAutoppCareOptions));
export const newLDRForm = createAction(constants.AUPP_NEW_LDR_FORM, body => method.post(urls.AUTOPPLDRS.newLDRForm, body)());
export const deleteLDR = createAction(constants.AUPP_DELETE_LDR, body => method.post(urls.AUTOPPLDRS.deleteLDR, body)());
export const getLDRForms = createAction(constants.AUPP_GET_LDR_FORMS, method.get(urls.AUTOPPLDRS.getLdrForms));
export const deleteBrand = createAction(constants.AUPP_DELETE_BRAND, body => method.post(urls.AUTOPPLDRS.deleteBrand, body)());
export const updateBrand = createAction(constants.AUPP_UPDATE_BRAND, body => method.post(urls.AUTOPPLDRS.updateBrand, body)());
export const newBrandLDR = createAction(constants.AUPP_NEW_BRAND_LDR, body => method.post(urls.AUTOPPLDRS.newBrandLDR, body)());

