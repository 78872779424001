// core actions
import { authUser } from "actions/auth.jsx";
// antd componentes
import { Spin } from 'antd';
//core components
import Footer from "components/Shared/Footer/Footer.jsx";
import Header from 'components/Shared/Header/Header.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react final form components
import { Field, Form } from "react-final-form";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, Col, Container, Form as FormRS, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
// core selectors
import { getTokenAuth } from "selectors/adminLayout";
// helpers
import { verifyToken } from "helpers/verifyToken.jsx"
class Login extends Component {

  state = {};

  componentWillMount() {
    const hasToken = verifyToken();
    if (hasToken) {
      this.props.history.push('/admin');
    }
  }

  componentDidMount() {
    document.body.classList.add("bg-default");
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  validate = values => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Este campo es obligatorio';
    }
    if (!values.password) {
      errors.password = 'Este campo es obligatorio';
    }
    return errors;
  }

  renderInputComponent = props => {
    const {
      placeholder,
      input,
      inputProps,
      meta
    } = props;
    return (
      <FormGroup>
        <InputGroup className="input-group-merge input-group-alternative">
          {inputProps}
          <Input
            {...input}
            placeholder={placeholder}
            invalid={meta.error && meta.touched}
          />
        </InputGroup>
        {meta.error && meta.touched && <span style={{
          marginTop: '0.25rem',
          fontSize: '80%',
          color: '#fb6340'
        }}>{meta.error}</span>}
      </FormGroup>
    );
  }

  handleOnSubmit = async values => {
    // console.log(values);
    await this.props.authUser(values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { token } = this.props;
        // console.log(token);
        localStorage.setItem('token', token);
        this.props.history.push(`${this.props.match.url}admin`);
      }
    });
  }

  render() {
    return (
      <div className="main-content" ref="mainContent">
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Header
          title="Bienvenido a Smart & Simple"
          lead="Para ingresar a realizar su gestión, por favor ingrese sus credenciales."
          isAuth
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h4>Inicio de Sesión</h4>
                  </div>
                  <div className="text-center text-muted mb-4">
                    <img
                      style={{
                        width: '200px'
                      }}
                      alt="..."
                      src={require("assets/img/icons/common/Logo-azul-GBM-2023.png")}
                    />
                  </div>
                  <Form
                    onSubmit={this.handleOnSubmit}
                    validate={this.validate}
                    render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <Spin spinning={submitting} size="large">
                        <FormRS role="form" onSubmit={handleSubmit}>
                          <Field
                            name="username"
                            placeholder="Usuario"
                            disabled={submitting}
                            type="text"
                            component={this.renderInputComponent}
                            inputProps={
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-single-02" />
                                </InputGroupText>
                              </InputGroupAddon>
                            }
                          />
                          <Field
                            name="password"
                            placeholder="Contraseña"
                            disabled={submitting}
                            type="password"
                            component={this.renderInputComponent}
                            inputProps={
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-lock-circle-open" />
                                </InputGroupText>
                              </InputGroupAddon>
                            }
                          />
                          <div className="text-center">
                            <Button
                              className="my-4"
                              color="info"
                              type="submit"
                              disabled={submitting || validating || !valid}
                            >
                              Iniciar Sesión
                          </Button>
                          </div>
                        </FormRS>
                      </Spin>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

Login.defaultProps = {
  token: ""
}

Login.propTypes = {
  token: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  token: getTokenAuth(state),
});

export default withRouter(connect(mapStateToProps, { authUser })(Login));
