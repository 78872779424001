/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component of the Form to create a new OS / Network report
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import {
  FormGroup,
  Form,
  Input,
  Label,
  Col,
  Row,
  Button,
  Card,
  CardHeader,
  FormFeedback,
} from "reactstrap";

import { FaExclamationCircle } from "react-icons/fa";

function CreateForm({
  title,
  description,
  onCancelClick,
  onSendNoMrs,
  onChangeInput,
  selectedNetwork,
  onSelectedItem,
  itemSelectedInRow,
  itemsList,
  errors,
  active,
  searchConflictReport,
  showConflicButton,
  reportType,
}) {
  return (
    <Card>
      <CardHeader style={{ marginBottom: "2rem" }}>
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{description}</p>
          </Col>
        </Row>
      </CardHeader>
      <Form>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            {showConflicButton && (
              <div className="text-center">
                <Button
                  color="danger"
                  style={{
                    backgroundColor: "#f2f21e",
                    borderColor: "#f2f21e",
                    borderRadius: "5px",
                    padding: "8px 16px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => searchConflictReport(reportType)}
                >
                  <FaExclamationCircle style={{ marginRight: "8px" }} />
                  <span style={{ color: "white" }}>Ver conflictos</span>
                </Button>
              </div>
            )}
            <FormGroup>
              <Label className="font-weight-bold">Fecha a reportar</Label>
              <Input
                id="date-input"
                type="date"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.date ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("date", e.target.value);
                }}
                invalid={errors.date}
              />
              {errors.date && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Hora de inicio</Label>
              <Input
                id="time-input"
                type="time"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.time ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("time", e.target.value);
                }}
                invalid={errors.time}
              />
              {errors.time && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Hora de fin</Label>
              <Input
                id="time-input"
                type="time"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.endTime ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("endTime", e.target.value);
                }}
                invalid={errors.endTime}
              />
              {errors.endTime && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            {selectedNetwork ? (
              <FormGroup>
                <Label className="font-weight-bold">
                  Item para reportar Network
                </Label>
                <Input
                  id="select-typeCATS"
                  type="select"
                  className="basic-multi-select text-dark"
                  style={{
                    color: "black",
                    borderColor: errors.item ? "red" : "#dee2e6",
                  }}
                  defaultValue={""}
                  onChange={(e) => {
                    onSelectedItem(e.target.value);
                  }}
                  invalid={errors.item}
                >
                  <option value="" disabled>
                    Selecciona un item
                  </option>
                  {itemsList.map((item, index) => (
                    <option key={index} value={index}>
                      {item.SUB_ACTIVITY
                        ? `${item.ITEM} - ${item.SUB_ACTIVITY}`
                        : item.ITEM}{" "}
                      || {item.ORD_NAME}
                    </option>
                  ))}
                </Input>
                {errors.item && (
                  <FormFeedback style={{ color: "red" }}>
                    Este campo es obligatorio
                  </FormFeedback>
                )}
              </FormGroup>
            ) : itemsList.length === 1 ? (
              <FormGroup>
                <Label className="font-weight-bold">
                  Item para reportar OS
                </Label>
                <div
                  style={{
                    border: "1px solid #ced4da",
                    padding: "0.375rem 0.75rem",
                    borderRadius: "0.25rem",
                  }}
                >
                  <span>{itemSelectedInRow.Id}</span>
                  <span>{"  ||   " + itemSelectedInRow.descrip}</span>
                </div>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label className="font-weight-bold">
                  Selecciona un item para reportar
                </Label>
                <Input
                  id="select-typeCATS"
                  type="select"
                  className="basic-multi-select text-dark"
                  style={{
                    color: "black",
                    borderColor: errors.item ? "red" : "#dee2e6",
                  }}
                  defaultValue={""}
                  onChange={(e) => {
                    onSelectedItem(e.target.value);
                  }}
                  invalid={errors.item}
                >
                  <option value="" disabled>
                    Selecciona un item
                  </option>
                  {itemsList.map((item, index) => (
                    <option key={index} value={index}>
                      {item.SUB_ACTIVITY
                        ? `${item.ITEM} - ${item.SUB_ACTIVITY}`
                        : item.ITEM}{" "}
                      || {item.ORD_NAME}
                    </option>
                  ))}
                </Input>
                {errors.item && (
                  <FormFeedback style={{ color: "red" }}>
                    Este campo es obligatorio
                  </FormFeedback>
                )}
              </FormGroup>
            )}
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Descripción (max. 100)</Label>
              <Input
                id="text-input"
                type="textarea"
                className="form-control"
                autoComplete="off"
                maxLength={100}
                resize="none"
                style={{
                  color: "black",
                  borderColor: errors.description ? "red" : "#dee2e6",
                  height: "6rem",
                }}
                onChange={(e) => {
                  onChangeInput("description", e.target.value);
                }}
                invalid={errors.description}
              />
              {errors.description && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xs={10} sm={6} className="text-center">
            <Button
              id="btn-cancel-cats"
              className="btn-icon mt-3 mr-3"
              color="danger"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                width: "125px",
                height: "35px",
                fontSize: "0.75rem",
                marginLeft: "0px",
              }}
              onClick={onCancelClick}
            >
              <span className="btn-inner--text">Cancelar</span>
            </Button>
            <Button
              id="btn-create-cats"
              className="btn-icon mt-3"
              color="success"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                width: "125px",
                height: "35px",
                fontSize: "0.75rem",
                marginLeft: "0px",
              }}
              onClick={onSendNoMrs}
              disabled={active}
            >
              <span className="btn-inner--text">Enviar reporte</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default CreateForm;
