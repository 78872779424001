/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Registro de Usuario en la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React, { useState, useEffect } from "react";
import { Card, CardTitle, Col } from "reactstrap";

const IsNewColab = ({ data, isVisible }) => {
    const titleStyle = {
        backgroundColor: '#1275bc',
        color: '#ffffff',
        height: '1.5em',
        fontSize: '1.25em',
        fontFamily: 'sans-serif',
        textAlign: 'center'
    };
    const labelStyle = {
        fontSize: '1.25em',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        marginBottom: "2px"
    };

    //FUNCIONES 
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (data.isNewColab) {
            if (data.isNewColab === "0") {
                setMsg("Colaborador: Reingreso");
            }
            else {
                setMsg("Colaborador: Nuevo");
            }
        }
    }, []);

    //RETURN
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={{ ...titleStyle }}>
                        Registro de Usuario
                    </CardTitle>

                    <Col xs="2" style={labelStyle}>
                        {msg}
                    </Col>
                </Card>
            )}
        </>
    );
};

export default IsNewColab;