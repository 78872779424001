// antd components
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Modal, Row } from "reactstrap";

const NewVersionModal = props => {

  const {
    loading,
    message,
    request,
    showModal,
    lastVersion,
    options: {
      wayPay,
      officeHours,
      validityService,
      physicalLocation,
      equipmentServiceCenter,
    },
    toggleModal,
    onNewVersion,
  } = props;

  const [typeVersion, setTypeVersion] = useState({
    type: '',
    name: ''
  });

  const onChageTypeVersion = (event, name) => {
    const { id } = event.target;
    setTypeVersion({
      type: id,
      name,
    });
  };

  const calcVersionNumber = version => {
    return (version + 0.1).toFixed(1);
  };

  const validate = values => {
    const errors = {};
    if (typeVersion.type === 'coverage')
      if (!values.officeHours || values.officeHours === "0")
        errors.officeHours = "Debes seleccionar el horario de atención.";
    if (typeVersion.type === 'termOfService')
      if (!values.validityService || values.validityService === "0")
        errors.validityService = "Debes seleccionar la vigencia del servicio.";
    if (typeVersion.type === 'wayPay')
      if (!values.wayPay || values.wayPay === "0")
        errors.wayPay = "Debes seleccionar la forma de pago del servicio.";
    if (typeVersion.type === 'addEquipments') {
      // if (!values.amountOfEquipment || values.amountOfEquipment === "0")
      //   errors.amountOfEquipment = "La cantidad de equipos a agregar debe ser mayor a cero.";
      if (!values.physicalLocation || values.physicalLocation === "0")
        errors.physicalLocation = "Debes seleccionar la ubicación fisica de los equipos.";
      if ((!values.equipmentServiceCenterIn || values.equipmentServiceCenterIn === "0") && values.physicalLocation !== "2")
        errors.equipmentServiceCenterIn = "Debes seleccionar la ubicación fisica de los equipos.";
      if ((!values.amountOfEquipmentIn || values.amountOfEquipmentIn === "") && values.physicalLocation !== "2")
        errors.amountOfEquipmentIn = "Debes ingresar la cantidad de equipos.";
      if ((!values.equipmentServiceCenterOut || values.equipmentServiceCenterOut === "0") && values.physicalLocation !== "1")
        errors.equipmentServiceCenterOut = "Debes seleccionar la ubicación fisica de los equipos.";
      if ((!values.amountOfEquipmentOut || values.amountOfEquipmentOut === "") && values.physicalLocation !== "1")
        errors.amountOfEquipmentOut = "Debes ingresar la cantidad de equipos.";
      if ((!values.localtionNotes || values.localtionNotes === "") && values.physicalLocation !== "1")
        errors.localtionNotes = "Debes ingresar las notas de las ubicaciones de los equipos.";
    }
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Spin size="large" spinning={loading} tip={message}>
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent p-1">
              <div className="text-muted text-center mt-2 mb-2">
                <h5 className="surtitle">OPP {request ? request.opportunityNumber : 'N/A'}</h5>
                <h4 className="h3 mb-0">Nueva Versión {calcVersionNumber(parseFloat(lastVersion))}</h4>
              </div>
            </CardHeader>
            <CardBody className="px-lg-3 py-lg-3">
              <Row>
                <div className="col">
                  <h5 className="h4 text-center">
                    Seleccione el Tipo de Versionamiento
                  </h5>
                </div>
              </Row>
              <Row className="mt-2">
                <FormRS>
                  <Row className="m-1">
                    <Col className="d-flex" sm="2">
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="coverage"
                          name="custom-radio-1"
                          type="radio"
                          checked={typeVersion.type === 'coverage'}
                          onChange={(e) => onChageTypeVersion(e, 'Cambio en cobertura')}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="coverage"
                        >
                          Cambio en cobertura
                        </label>
                      </div>
                    </Col>
                    <Col className="d-flex" sm="2">
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="termOfService"
                          name="custom-radio-1"
                          type="radio"
                          checked={typeVersion.type === 'termOfService'}
                          onChange={(e) => onChageTypeVersion(e, 'Plazo de Servicio')}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="termOfService"
                        >
                          Plazo de Servicio
                        </label>
                      </div>
                    </Col>
                    <Col className="d-flex" sm="2">
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="wayPay"
                          name="custom-radio-1"
                          type="radio"
                          checked={typeVersion.type === 'wayPay'}
                          onChange={(e) => onChageTypeVersion(e, 'Forma de Pago')}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="wayPay"
                        >
                          Forma de Pago
                        </label>
                      </div>
                    </Col>
                    <Col className="d-flex" sm="2">
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="updateEquipments"
                          name="custom-radio-1"
                          type="radio"
                          checked={typeVersion.type === 'updateEquipments'}
                          onChange={(e) => onChageTypeVersion(e, 'Actualizar Equipos')}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="updateEquipments"
                        >
                          Actualizar Equipos
                        </label>
                      </div>
                    </Col>
                    <Col className="d-flex" sm="2">
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="deleteEquipments"
                          name="custom-radio-1"
                          type="radio"
                          checked={typeVersion.type === 'deleteEquipments'}
                          onChange={(e) => onChageTypeVersion(e, 'Eliminar Equipos')}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="deleteEquipments"
                        >
                          Eliminar Equipos
                        </label>
                      </div>
                    </Col>
                    <Col className="d-flex" sm="2">
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="addEquipments"
                          name="custom-radio-1"
                          type="radio"
                          checked={typeVersion.type === 'addEquipments'}
                          onChange={(e) => onChageTypeVersion(e, 'Agregar Equipos')}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="addEquipments"
                        >
                          Agregar Equipos
                        </label>
                      </div>
                    </Col>
                  </Row>
                </FormRS>
              </Row>
              <Form
                onSubmit={(e) => onNewVersion(e, typeVersion, calcVersionNumber(parseFloat(lastVersion)))}
                validate={validate}
                initialValues={{
                  amountOfEquipment: 0,
                  amountOfEquipmentIn: 0,
                  amountOfEquipmentOut: 0
                }}
                render={({ handleSubmit, values }) => (
                  <FormRS onSubmit={handleSubmit}>
                    {
                      typeVersion.type === 'coverage' &&
                      <Row className="m-2">
                        <Col>
                          <FormGroup>
                            <Label for="officeHours">Horario de Atención</Label>
                            <Field name="officeHours">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="select"
                                    placeholder="Seleccione el horario de atención"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione el horario de atención</option>
                                    {
                                      officeHours.map((row, key) => {
                                        return (
                                          <option key={key} value={row.id}>{row.name}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                    }
                    {
                      typeVersion.type === 'termOfService' &&
                      <Row className="m-2">
                        <Col>
                          <FormGroup>
                            <Label for="validityService">Vigencia del Servicio</Label>
                            <Field name="validityService">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="select"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione la vigencia del servicio</option>
                                    {
                                      validityService.map((row, key) => {
                                        return (
                                          <option key={key} value={row.id}>{row.name}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                    }
                    {
                      typeVersion.type === 'wayPay' &&
                      <Row className="m-2">
                        <Col>
                          <FormGroup>
                            <Label for="wayPay">Forma de Pago del Servicio</Label>
                            <Field name="wayPay">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="select"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione la forma de pago del servicio</option>
                                    {
                                      wayPay.map((row, key) => {
                                        return (
                                          <option key={key} value={row.id}>{row.name}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                    }
                    {
                      typeVersion.type === 'addEquipments' &&
                      <Row className="m-2">
                        <Col className="mb-3" sm="12" md="4">
                          {/* <FormGroup>
                            <Label for="amountOfEquipment">Cantidad de Equipos a Agregar</Label>
                            <Field name="amountOfEquipment">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="number"
                                    placeholder="Ingrese la cantidad de nuevos equipos"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                    min={1}
                                  />
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup> */}
                          <FormGroup>
                            <Label for="physicalLocation">Ubicación Fisica de los Equipos</Label>
                            <Field name="physicalLocation">
                              {({ input, meta }) => (
                                <div >
                                  <Input
                                    {...input}
                                    type="select"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione la ubicación fisica de los equipos</option>
                                    {
                                      physicalLocation.map((row, key) => {
                                        return (
                                          <option key={key} value={row.id}>{row.name}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        {
                          values.physicalLocation && (values.physicalLocation === "1" || values.physicalLocation === "3") &&
                          <>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="amountOfEquipmentIn">Cantidad de equipos dentro del radio</Label>
                                <Field name="amountOfEquipmentIn">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="number"
                                        placeholder="Ingrese la cantidad de equipos"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                        min={1}
                                      />
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="equipmentServiceCenterIn">Distancia del Centro de Servicios Autorizado</Label>
                                <Field name="equipmentServiceCenterIn">
                                  {({ input, meta }) => (
                                    <div >
                                      <Input
                                        {...input}
                                        type="select"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option key="0" value="0">Seleccione la distancia del centro de servicios autorizado</option>
                                        {
                                          equipmentServiceCenter.map((row, key) => {
                                            if (row.type === 'IN') {
                                              return (
                                                <option key={key} value={row.id}>{row.name}</option>
                                              )
                                            }
                                            return null;
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </>
                        }
                      </Row>
                    }
                    {
                      typeVersion.type === 'addEquipments' &&
                      values.physicalLocation && (values.physicalLocation === "2" || values.physicalLocation === "3") &&
                      <div className="form-row align-items-center">
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label for="amountOfEquipmentOut">Cantidad de equipos fuera del radio</Label>
                            <Field name="amountOfEquipmentOut">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="number"
                                    placeholder="Ingrese la cantidad de equipos"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                    min={1}
                                  />
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label for="equipmentServiceCenterOut">Distancia del Centro de Servicios Autorizado</Label>
                            <Field name="equipmentServiceCenterOut">
                              {({ input, meta }) => (
                                <div >
                                  <Input
                                    {...input}
                                    type="select"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione la distancia del centro de servicios autorizado</option>
                                    {
                                      equipmentServiceCenter.map((row, key) => {
                                        if (row.type === 'OUT') {
                                          return (
                                            <option key={key} value={row.id}>{row.name}</option>
                                          )
                                        }
                                        return null;
                                      })
                                    }
                                  </Input>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label for="localtionNotes">Notas (300)</Label>
                            <Field name="localtionNotes">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="textarea"
                                    placeholder="Escriba aquí las notas."
                                    maxLength={300}
                                    rows="3"
                                    resize="none"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  />
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </div>
                    }
                    {
                      typeVersion.type === 'addEquipments' &&
                      <div className="form-row align-items-center justify-content-center">
                        <Label for="amountOfEquipment">Cantidad de Equipos: {request.amountOfEquipment + parseInt(values.physicalLocation !== "2" ? values.amountOfEquipmentIn : 0) + parseInt(values.physicalLocation !== "1" ? values.amountOfEquipmentOut : 0)}</Label>
                      </div>
                    }
                    <div className="form-row align-items-center justify-content-center">
                      <Col className="mb-3 d-flex align-content-center justify-content-center" sm="12" md="3">
                        <Button
                          type="submit"
                          color="success"
                          className="btn-icon"
                          disabled={typeVersion.type === ''}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-single-copy-04" />
                          </span>
                          <span className="btn-inner--text">Versionar</span>
                        </Button>
                      </Col>
                    </div>
                  </FormRS>
                )}
              />
            </CardBody>
          </Card>
        </Spin>
      </div>
    </Modal>
  );
};

NewVersionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default NewVersionModal;
