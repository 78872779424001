import { authenticate, urlCiscoInfo } from "reducers/Auth/auth.jsx";
import {
  dataChartHistory,
  dataChartIndicator,
  dataChartPlannedRealHours,
  filters,
  collaboratorsReported,
  hoursAccused,
  optionsFormMaintenance,
  activitiesReport,
  userCreated,
  allUsers,
} from "reducers/COE/index.jsx";
import {
  usersDigitalSignature,
  allYearSignatures,
  dataByDashboardSignature,
  registerSignature,
  signaturesUser,
  signaturesInFlow,
  signaturesInFlowCollaborator,
  signaturesFlowLogs,
  homeOfficeInfo,
} from "reducers/DigitalSignature/index.jsx";
import {
  allYearExtraSumHoursGraph,
  allYearExtraSumHoursWidget,
  countryExtrasSumHoursGraph,
  countryExtrasSumHoursWidget,
  dateExtras,
  extras,
  extrasToSend,
  findHoursByUser,
  findMonthWidget,
  findMonthWidgetCountry,
  findUserByYear,
  updatedExtrasSAP,
  userRolesExtra,
  yearsExtras,
  usersWithAccessExtraHours,
  extraHourCeo,
  extraHoursHC,
} from "reducers/ExtraHours/index.jsx";
import {
  countProjects,
  inactiveSystems,
  maintenance,
  projects,
  internalTeams,
} from "reducers/Mis/index.jsx";
import {
  allRequestsByUsers,
  dataUnplanned,
  historyTotalPositions,
  personal,
  relationByPosition,
  relationPosition,
  requestsByUsers,
  staffPosition,
  unplanned,
  unplannedPosition,
  vacantById,
  vacantPosition,
  usersAccess,
  allMasterDataPositions,
} from "reducers/NewPosition/index.jsx";
import { notifications } from "reducers/Notifications/index.jsx";
import { nodes, resourses } from "reducers/PlanningMRS/index.jsx";
import {
  allOrdersData,
  orderDetailsData,
  ordersByCountryData,
  ordersByUserData,
} from "reducers/SO/index.jsx";
import {
  daysTotalsData,
  executedTotalsData,
  locationsTotalsData,
  loginTotalsData,
  monthsTotalsData,
  mostExecutedTransactionData,
  movementsTotalsData,
  transactionCountData,
} from "reducers/wms/overviewWMS.jsx";
import { combineReducers } from "redux";
import {
  myRequirements,
  requeriment,
  equipmentsByRequirement,
  criticalParts,
  debuggedModels,
} from "./Sales/CriticalParts/index.jsx";
import {
  targetLetterByUser,
  targetsLetterHC,
  targetsLetterHeadShip,
  targetsLetterHCRM,
  targetsLetters,
  myTargetsLetters,
  dashboardTargetLetter,
  targetsLetterGeneralManager,
  targetsLetterManagementServicesDirector,
} from "./TargetLetter/index.jsx";
import {
  performance,
  signinsGraphs,
  transactionsGraphs,
} from "reducers/Performance/index.jsx";
import {
  createRequest,
  approversData,
  otherApproversData,
  requestData,
  completeRequestsData,
  pendingRequestsData,
  cancelledRequestsData,
  NotificationsData,
} from "reducers/AS/index.jsx";
import {
  activityFlow,
  configurations,
  equipments,
  equipmentSpare,
  formValuesEquipments,
  formValuesRequest,
  justifications,
  references,
  referencesSpare,
  requeriments,
  resumeOffers,
  lastVersion,
  formValuesVersion,
} from "reducers/Sales/Requests/index.jsx";
import {
  infoRequerimentsInQuotes,
  requerimentsInQuotes,
  commentsRequestInQuotes,
  filesRequestInQuotes,
} from "./Sales/Inventories/index.jsx";
import {
  masterData,
  offers,
  requestInAjustment,
  logsOffer,
} from "./Sales/Pricing/index.jsx";
import {
  requerimentsPlanners,
  allRequerimentsPlanners,
  equipmentsByRequestPlanners,
  userAssignmentByRequestPlanners,
  allPartsEquipmentsByRequest,
} from "./Sales/PlannersFlow/index.jsx";
import {
  masterVariables,
  usersEscalationsTss,
} from "reducers/Sales/Support/index.jsx";
import {
  criticalPartsKit,
  partsEquipment,
  partsEquipmentsRequest,
  pendingPartsEquipmentsRequest,
  requerimentsEngineers,
  selectedPartsKit,
  historyPartsEquipmentsRequest,
} from "./Sales/Engineers/index.jsx";
import {
  medicalRecord,
  emergencyContacts,
  allergies,
  diseases,
  interventions,
  offices,
  records,
  graphRecords,
  otherTypes,
  otherMedicalInfo,
  accessRecords,
} from "reducers/MedicalRecords/index.jsx";
import {
  requestManagers,
  dataRequestManagers,
  dataRequestDashboardContracts,
  dataRequestYearAndCountries,
} from "reducers/Sales/Managers/index.jsx";

import {
  fieldTypes,
  DocumentAdministration,
  CategoriesList,
  createDocument,
  createFieldType,
  BusinessUnitsList,
  CategoryBuTemplates,
  CategoryDocuments,
  createDocumentTemplate,
  deleteFieldType,
  DocumentTemplateList,
  RequestList,
  FinanceRequestData,
  PositionsList,
  TemplateQuery,
  getAllProfiles,
  createNewProfile,
  updateProfile,
  getAllStates,
  getMyRequest,
} from "reducers/FF/index.jsx";
//Contacts
import {
  findAllCustomers,
  findAllContactsByCustomer,
  findAllFunctions,
  findAllDepartaments,
  findAllCountrys,
  findAllHistory,
  createNewContact,
  updateContact,
  sendEmailFileContact,
} from "reducers/Contacts/index.jsx";
//Incidents Reports
import {
  findAllPlatforms,
  findAllProducts,
  findAllServices,
  findAllData,
} from "reducers/IncidentsReports/index.jsx";

import {
  contractsOnHold,
  dataMaster,
  statusContractsOnHold,
  activityFlowByContractOnHold,
  usersNotifications,
  usersEscalations,
  dataDashboardContracts,
  dataGraphContracts,
  dataGraphContractsByCountry,
  userFMAndPM,
  usersAccessOnHold,
} from "reducers/SECOH/index.jsx";
import {
  personalInfo,
  personalInfoComplete,
  relationShips,
  maritalStatus,
  familyGroups,
  emergencyContactsEmployee,
  employeeForms,
  emergencyContactsLoaded,
} from "reducers/KnowEmployee/index.jsx";

import {
  conflictInterestsInfo,
  allConflictInterestsInfo,
  conflictInterests,
} from "reducers/ConflictInterests/index.jsx";

export default combineReducers({
  authenticate,
  resourses,
  nodes,
  extras,
  extrasToSend,
  dateExtras,
  allYearExtraSumHoursWidget,
  allYearExtraSumHoursGraph,
  countryExtrasSumHoursWidget,
  countryExtrasSumHoursGraph,
  findUserByYear,
  findHoursByUser,
  findMonthWidget,
  findMonthWidgetCountry,
  userRolesExtra,
  yearsExtras,
  updatedExtrasSAP,
  inactiveSystems,
  projects,
  internalTeams,
  countProjects,
  maintenance,
  usersAccess,
  unplanned,
  dataUnplanned,
  historyTotalPositions,
  personal,
  relationByPosition,
  unplannedPosition,
  staffPosition,
  vacantPosition,
  relationPosition,
  transactionCountData,
  loginTotalsData,
  mostExecutedTransactionData,
  executedTotalsData,
  movementsTotalsData,
  locationsTotalsData,
  monthsTotalsData,
  daysTotalsData,
  allOrdersData,
  ordersByCountryData,
  ordersByUserData,
  orderDetailsData,
  allRequestsByUsers,
  requestsByUsers,
  vacantById,
  filters,
  dataChartPlannedRealHours,
  dataChartHistory,
  dataChartIndicator,
  collaboratorsReported,
  hoursAccused,
  optionsFormMaintenance,
  activitiesReport,
  userCreated,
  allUsers,
  usersDigitalSignature,
  allYearSignatures,
  dataByDashboardSignature,
  registerSignature,
  notifications,
  requeriment,
  myRequirements,
  equipmentsByRequirement,
  criticalParts,
  debuggedModels,
  targetLetterByUser,
  targetsLetterHC,
  targetsLetterHeadShip,
  targetsLetterHCRM,
  targetsLetters,
  myTargetsLetters,
  dashboardTargetLetter,
  signaturesUser,
  signaturesInFlow,
  signaturesInFlowCollaborator,
  signaturesFlowLogs,
  homeOfficeInfo,
  usersWithAccessExtraHours,
  extraHourCeo,
  extraHoursHC,
  performance,
  signinsGraphs,
  transactionsGraphs,
  createRequest,
  approversData,
  otherApproversData,
  requestData,
  completeRequestsData,
  pendingRequestsData,
  cancelledRequestsData,
  NotificationsData,
  activityFlow,
  configurations,
  equipments,
  equipmentSpare,
  formValuesEquipments,
  formValuesRequest,
  justifications,
  references,
  referencesSpare,
  requeriments,
  resumeOffers,
  lastVersion,
  formValuesVersion,
  infoRequerimentsInQuotes,
  requerimentsInQuotes,
  masterData,
  offers,
  requestInAjustment,
  logsOffer,
  requerimentsPlanners,
  masterVariables,
  requestManagers,
  dataRequestManagers,
  criticalPartsKit,
  partsEquipment,
  partsEquipmentsRequest,
  pendingPartsEquipmentsRequest,
  requerimentsEngineers,
  selectedPartsKit,
  medicalRecord,
  emergencyContacts,
  allergies,
  diseases,
  interventions,
  offices,
  records,
  graphRecords,
  otherTypes,
  otherMedicalInfo,
  accessRecords,
  fieldTypes,
  DocumentAdministration,
  CategoriesList,
  createDocument,
  createFieldType,
  BusinessUnitsList,
  CategoryBuTemplates,
  CategoryDocuments,
  createDocumentTemplate,
  deleteFieldType,
  DocumentTemplateList,
  RequestList,
  FinanceRequestData,
  PositionsList,
  TemplateQuery,
  getAllProfiles,
  createNewProfile,
  updateProfile,
  getAllStates,
  dataRequestYearAndCountries,
  dataRequestDashboardContracts,
  targetsLetterGeneralManager,
  targetsLetterManagementServicesDirector,
  findAllCustomers,
  findAllContactsByCustomer,
  findAllFunctions,
  findAllDepartaments,
  findAllCountrys,
  findAllHistory,
  createNewContact,
  updateContact,
  sendEmailFileContact,
  findAllPlatforms,
  findAllProducts,
  findAllServices,
  findAllData,
  contractsOnHold,
  dataMaster,
  statusContractsOnHold,
  activityFlowByContractOnHold,
  usersNotifications,
  usersEscalations,
  dataDashboardContracts,
  dataGraphContracts,
  dataGraphContractsByCountry,
  commentsRequestInQuotes,
  urlCiscoInfo,
  getMyRequest,
  historyPartsEquipmentsRequest,
  allRequerimentsPlanners,
  equipmentsByRequestPlanners,
  userAssignmentByRequestPlanners,
  allPartsEquipmentsByRequest,
  usersEscalationsTss,
  filesRequestInQuotes,
  personalInfo,
  personalInfoComplete,
  relationShips,
  maritalStatus,
  familyGroups,
  emergencyContactsEmployee,
  employeeForms,
  emergencyContactsLoaded,
  conflictInterestsInfo,
  allConflictInterestsInfo,
  userFMAndPM,
  conflictInterests,
  usersAccessOnHold,
  allMasterDataPositions,
});
