/*!

=========================================================
*ModalInfoInventoryAndInquiries
=========================================================

*Componente para importar almacenes y sus tipos.
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

import React, {
    useContext,
    useEffect,
    useState,
} from "react";

//dispatch hook
import { useDispatch } from 'react-redux';

import { FaSearch } from 'react-icons/fa';

// reactstrap components
import {
    InputGroupAddon,
    InputGroup,
    CardHeader,
    FormGroup,
    CardBody,
    Button,
    Table,
    Input,
    Modal,
    Card,
    Row,
    Col,
} from "reactstrap";

import { Spin } from "antd";

import { UserContext } from './UserContext';

//Imports de actions de consultas al API.
import {
    getAllDataModalInventory
} from 'actions/wms';


const ModalInfoInventoryAndInquiries = ({
    openModal,
    setOpenModal,
    newInfo,
    setNewInfo,
    modalInfo,
    getWareHouses,
    setFields,
    setIsStarted,
    setIsStoped,
    updateDisabledById,
    MySwal,
    setReadyToSend
}) => {

    const { title,
        subtitle,
        spinnerText,
        searchText,
        colunmName,
        id } = modalInfo;

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isComputer,
        notify,
    } = useContext(UserContext);
    //#endregion


    //#region Modal 

    const handleCloseModal = () => {
        setItems([])
        setItemsFiltered([])
        setOpenModal(false)
    }

    //#endregion

    //Spinner
    const [spinner, setSpinner] = useState(false)

    //Valor a buscar.
    const [valueToSearch, setValueToSearch] = useState("")

    //Columnas
    const [columns, setColumns] = useState(["action", colunmName])

    //Todas las purchase orders extraídas 
    const [items, setItems] = useState([])

    //Purchase orders filtradas.
    const [itemsFiltered, setItemsFiltered] = useState([])

    const getData = (id) => {

        setSpinner(true);

        dispatch(getAllDataModalInventory({ newInfo, id })).then((resp) => {
            const { payload } = resp;
            setSpinner(false);

            if (payload.status === 200) {
                console.log(payload)
                let items = payload.data.items;
                console.log(items)
                if (items.length == 0) {
                    notify("warning", "Alerta", "Error no se encontraron datos asociados")
                    handleCloseModal()
                }

                setItems(items)
                setItemsFiltered(items)
                setSpinner(false);



            } else {
                console.log(payload)
            }
        })
    }

    //Cuando arranque traiga los datos
    useEffect(() => {
        if (openModal) {
            getData(modalInfo.id);

        }
    }, [openModal])

    //Efecto para realizar el filtrado de items.
    useEffect(() => {
        if (valueToSearch != "") {
            let itemsTemp = items.filter(po => po.toString().includes(valueToSearch));
            setItemsFiltered(itemsTemp)
        } else {
            setItemsFiltered(items)
        }
    }, [valueToSearch])

    const handleChange = (item) => {
        if (id === "document") {
            let htmlMatTable = `<${isComputer ? 'h1' : 'h2'} class="mb-3">Aviso</${isComputer ? 'h1' : 'h2'}>
            ¿Desea continuar con la confirmación del documento: ${item}?
            <ul style="text-align: left;">`;

            //inventario no valorado 
            MySwal.fire({
                // title: `Aviso`,
                html: `${htmlMatTable}`,
                icon: "warning",
                showCancelButton: true,
                // width: 1000,
                confirmButtonText: `Si, confirmar`,
                confirmButtonColor: "#2dce89",
                cancelButtonText: "No, cancelar",
                cancelButtonColor: "#adb5bd",
                buttonsStyling: isComputer,
                customClass: {
                    confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                    cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
                },
                className: "p-0",
                style: {
                    overflow: 'unset',
                    padding: '0px'
                }
            }).then((result) => {
                if (result.value) {
                    setNewInfo(prev => {
                        return {
                            ...prev,
                            document: item,
                            status: "Iniciado"
                        }
                    })
                    setIsStarted(prev => !prev)
                    setIsStoped(prev => !prev)
                    updateDisabledById('location', false);
                    updateDisabledById('plate', false);
                    updateDisabledById('quantity', false);
                    setReadyToSend(prev => !prev);
                }
            });


        } else {
            setNewInfo({ ...newInfo, [id]: item })
        }
        handleCloseModal()
    }


    return (
        <>
            <Modal
                // className="modal-dialog-centered"
                className="modal-dialog-centered modal-secondary"
                // size="lg"
                centered={true}
                backdrop={true}
                isOpen={openModal}
                fullscreen={true}
                toggle={() => handleCloseModal()}
            >

                <Card className="shadow mb-0">
                    {/* bg-gradient-default  */}

                    <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                        <div>
                            {/* text-white */}
                            {isComputer && <h6 className="surtitle">{subtitle}</h6>}
                            <h5 className="h3 mb-0">{title}</h5>
                        </div>
                        <Button
                            outline
                            type="button"
                            size="sm"
                            onClick={() => handleCloseModal()}
                        >
                            <span className="btn-inner--icon ">
                                <i className="fas fa-times" />
                            </span>
                        </Button>

                    </CardHeader>
                    <Spin
                        className=""
                        spinning={spinner}
                        tip={spinnerText}
                        size="medium"
                    >
                        <CardBody className=" pt-0">


                            <Row className=" justify-content-center">


                                <Col xs="12" sm={"12"} className={""}>
                                    <FormGroup className="mb-2" >


                                        <InputGroup size="sm">
                                            <Input
                                                type="text"
                                                placeholder={searchText}
                                                onChange={(e) => setValueToSearch(e.target.value)}
                                                className="ml-0"

                                            />
                                            <InputGroupAddon addonType="append" className="ml--2">
                                                <span className="input-group-text">
                                                    <FaSearch />
                                                </span>
                                            </InputGroupAddon>
                                        </InputGroup>


                                    </FormGroup>
                                </Col>
                                <Col className="" sm="12">
                                    <Table
                                        className={"align-items-center  table-flush mt--1 "}
                                        responsive
                                        striped={true}
                                        hover={true}
                                        borderless
                                    >

                                        <tbody className='list  border'>
                                            {items.length > 0 ?

                                                <>
                                                    {itemsFiltered.sort((a, b) => a - b).map((item, keyItem) => {
                                                        return (

                                                            <tr key={keyItem} onClick={(e) => handleChange(item.CODE)} className="" >

                                                                {columns.map((col, key) => {
                                                                    return (

                                                                        col === "action" ? (
                                                                            <td key={key} className={"justify-content-md-center p-1 "}>

                                                                                <input className="mr-2" type="radio" id={"actionBtn" + key} />

                                                                                <label for={"actionBtn" + key}>{item.CODE}</label>

                                                                            </td>)


                                                                            //CUALQUIER TIPO 
                                                                            : (
                                                                                <td key={key} className={"text-left justify-content-md-left p-1 "} >
                                                                                    {item.DESCRIPTION}
                                                                                </td>
                                                                            )
                                                                    )
                                                                })}

                                                            </tr>

                                                        )
                                                    })}
                                                </>
                                                :
                                                <>

                                                </>
                                            }
                                        </tbody>

                                    </Table>
                                </Col>



                            </Row>

                        </CardBody>
                    </Spin>
                </Card>

                {/* </Drawer> */}
            </Modal>

        </>
    )
}
export default ModalInfoInventoryAndInquiries;