/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to show all Hours Report Modules (CATS, OS / NETWORK OR Extra Hours)
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import CardOption from "components/HoursReport/CardOption.jsx";
import { Col, Row } from "reactstrap";
import { CustomCard } from "components/Shared/Cards/CustomCard";

import { GoTasklist } from "react-icons/go";
import { IoTimeOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { CiCalendar } from "react-icons/ci";

function ReportOptions({ changeOption, extraRol }) {
  return (
    // <Row>
    //   <Col xs={12} md={extraRol ? 4 : 6}>
    //     <CardOption
    //       title="CATS"
    //       subtitle="Reporte de horas sin órdenes de servicio"
    //       id="castTable"
    //       image="CATS2.png"
    //       onSelect={changeOption}
    //     />
    //   </Col>
    //   <Col xs={12} md={extraRol ? 4 : 6}>
    //     <CardOption
    //       title="OS / Networks"
    //       subtitle="Reporte de horas con órdenes de servicio o networks"
    //       id="noMrsTable"
    //       image="calendarNoMrs.png"
    //       onSelect={changeOption}
    //     />
    //   </Col>
    //   {extraRol && (
    //     <Col xs={12} md={4}>
    //       <CardOption
    //         title="Horas extra"
    //         subtitle="Reporte de horas fuera del horario laboral"
    //         id="extraHoursRecord"
    //         image="ExtraHours.png"
    //         onSelect={changeOption}
    //       />
    //     </Col>
    //   )}
    // </Row>

    <Row className="d-flex justify-content-center">
      <CustomCard
        xs={"12"}
        sm={"4"}
        title="CATS"
        subtitle="Reporte de horas sin órdenes de servicio"
        Icon={GoTasklist}
        onClick={() => changeOption("castTable")}
      />
      <CustomCard
        xs={"12"}
        sm={"4"}
        title="OS / Networks"
        subtitle="Reporte de horas con órdenes de servicio o networks"
        Icon={CiCalendar}
        onClick={() => changeOption("noMrsTable")}
      />
      {extraRol && (
        <CustomCard
          xs={"12"}
          sm={"4"}
          title="Horas extra"
          subtitle="Reporte de horas fuera del horario laboral"
          onClick={() => changeOption("extraHoursRecord")}
          Icon={IoTimeOutline}
        />
      )}
    </Row>
  );
}

ReportOptions.propTypes = { changeOption: PropTypes.func.isRequired };

export default ReportOptions;
