// javascript plugin that creates nice dropzones for files
import { Icon, Spin, Upload } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const { Dragger } = Upload;

const TargetStartDateModal = props => {

  const {
    title,
    loading,
    fileList,
    showModal,
    toggleModal,
    updateTarget,
    onFileListReference,
  } = props;

  const uploadProps = {
    name: 'reference',
    multiple: true,
    accept: ".xlsx, .pdf",
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    action: `${urlBase}/secoh/upload-reference-request-target-start-date`,
    onChange: onFileListReference,
  };

  const validate = values => {
    const errors = {};
    if (!values.targetStartDate)
      errors.targetStartDate = "Debes seleccionar una fecha.";
    if (!values.reason)
      errors.reason = "Debes ingresar el motivo de la solicitud.";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={updateTarget}
              validate={validate}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label for="targetStartDate" >Fecha de solicitud</Label>
                      <Field name="targetStartDate">
                        {({ input, meta }) => (
                          <>
                            <ReactDatetime
                              {...input}
                              inputProps={{
                                placeholder: "Elija una fecha",
                                className: meta.error && meta.touched ? "is-invalid form-control" : "form-control"
                              }}
                              timeFormat={false}
                            />
                            {
                              meta.error && meta.touched && <span style={{
                                marginTop: '0.25rem',
                                fontSize: '80%',
                                color: '#fb6340'
                              }}>{meta.error}</span>
                            }
                          </>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label for="reason">Motivo (500)</Label>
                      <Field name="reason">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="textarea"
                              placeholder="Escriba aquí el comentario."
                              maxLength={500}
                              rows="3"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="form-row align-items-center">
                      <Col className="m-1" sm="12">
                        <Dragger {...uploadProps} fileList={fileList}>
                          <p className="ant-uploading-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                          <p className="ant-uploading-hint">
                            *Se debe cargar un adjunto.
                          </p>
                        </Dragger>
                      </Col>
                    </div>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Modificar Fecha
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

TargetStartDateModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TargetStartDateModal;