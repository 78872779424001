/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "MainComponent" el cual llama a los diferentes componentes principales dependiendo del tabPanel que se haya seleccionado en CardHeader
    
*Props:
    formSelected: useState que guarda el tab activo del tabContent del componente MainComponent
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    MySwal: funcion para las notificaciones personalizadas
    isL0: useState que guarda un booleano para verificar si el usuario logeado es aprobador L0
    isL2: useState que guarda un booleano para verificar si el usuario logeado es aprobador L2
    setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

// react library
import React from 'react';
// reactstrap components
import { TabContent, TabPane, Card, CardBody, CardHeader } from "reactstrap";
//Componentes principales de cada sección del portal y componentes de mantenimiento
import MainBilling from 'components/Freelance/Billing/Main.jsx';
import MainHes from 'components/Freelance/HES/Main.jsx';
import MainPoAssignation from 'components/Freelance/PoAssignation/Main.jsx';
import MainReportHours from 'components/Freelance/ReportHours/Main.jsx';
import Projects from 'components/Freelance/MaintainComponents/Projects.jsx';
import Access from 'components/Freelance/MaintainComponents/Access.jsx';
import EmailsBilling from 'components/Freelance/MaintainComponents/EmailsBilling.jsx';
import Approvers from 'components/Freelance/MaintainComponents/Approvers.jsx';
import Reporting from 'components/Freelance/MaintainComponents/Reporting.jsx';

import Altas from 'components/Freelance/MaintainComponents/Altas.jsx';

const MainComponent = ({
    formSelected, 
    setLoad, 
    Toast, 
    dropDownOptions,  
    setDropDownOptions,  
    rolArea, 
    isAdmin, 
    MySwal, 
    isL0, 
    isL2, 
    isAlta,  
    setRefreshOptions, 
}) => {

    //lista de componentes con su id de tabPanel respectivo, titulo y el componente como tal
    const components = [
        {
            tabId: "1",
            title: "GBM Consulting: Asignación de PO's, Items y Responsable",
            component: <MainPoAssignation setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} rolArea={rolArea} MySwal={MySwal} isAdmin={isAdmin}  setRefreshOptions={setRefreshOptions}/>,
        },
        {
            tabId: "2",
            title: "GBM Consulting: Detalle de reporte de horas",
            component: <MainReportHours setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} rolArea={rolArea} MySwal={MySwal} isAdmin={isAdmin} isL0={isL0} />,
        },
        {
            tabId: "3",
            title: "GBM Consulting: Control HES",
            component: <MainHes setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} rolArea={rolArea} isAdmin={isAdmin} MySwal={MySwal} />,
        },
        {
            tabId: "4",
            title: "GBM Consulting: Facturación",
            component: <MainBilling setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} rolArea={rolArea} isAdmin={isAdmin} MySwal={MySwal} isL2={isL2} />,
        },
        {
            tabId: "5",
            title: "GBM Consulting: Creacion de proveedores",
            component: <Access setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} rolArea={rolArea} isAdmin={isAdmin} MySwal={MySwal} setRefreshOptions={setRefreshOptions}/>,
        },
        {
            tabId: "6",
            title: "GBM Consulting: Mantenimiento correos bases finanzas",
            component: <EmailsBilling setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} isAdmin={isAdmin}/>,
        },
        {
            tabId: "7",
            title: "GBM Consulting: Mantenimiento de Proyectos",
            component: <Projects setLoad={setLoad} Toast={Toast}  dropDownOptions={dropDownOptions} rolArea={rolArea} isAdmin={isAdmin} MySwal={MySwal} setRefreshOptions={setRefreshOptions}/>,
        },
        {
            tabId: "8",
            title: "GBM Consulting: Mantenimiento de Coordinadores",
            component: <Approvers setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} rolArea={rolArea} isAdmin={isAdmin} MySwal={MySwal} setRefreshOptions={setRefreshOptions}/>,
        },
        {
            tabId: "9",
            title: "GBM Consulting: Reportes",
            component: <Reporting setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} isAdmin={isAdmin}/>,
        },
        {
            tabId: "10",
            title: "GBM Consulting: Altas",
            component: <Altas isAlta={isAlta} setLoad={setLoad} Toast={Toast} dropDownOptions={dropDownOptions} setDropDownOptions={setDropDownOptions} rolArea={rolArea} isAdmin={isAdmin} MySwal={MySwal} setRefreshOptions={setRefreshOptions}/>,
        },
    ];

    return (
        <>
            <TabContent activeTab={formSelected}>
                <TabPane tabId="0">
                </TabPane>
                {
                    components.map((row, key) => (
                        <TabPane key={key} tabId={row.tabId}>
                            <Card className="bg-gradient border-0">
                                <CardHeader>
                                    <h2>{row.title}</h2>
                                </CardHeader>
                                <CardBody>
                                    {row.component}
                                </CardBody>
                            </Card>
                        </TabPane>
                    ))
                }
            </TabContent>
        </>
    );
};

export default MainComponent;