import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const fieldTypes = handleActions(
  {
    [constants.FF_GET_FIELD_TYPES]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const DocumentAdministration = handleActions(
  {
    [constants.FF_GET_ADMIN_DOCS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const CategoriesList = handleActions(
  {
    [constants.FF_GET_CATEGORIES]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const BusinessUnitsList = handleActions(
  {
    [constants.FF_GET_BUSINESS_UNITS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const createDocument = handleActions(
  {
    [constants.FF_CREATE_DOCUMENT]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const createFieldType = handleActions(
  {
    [constants.FF_CREATE_FIELD_TYPE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const deleteFieldType = handleActions(
  {
    [constants.FF_DELETE_FIELD_TYPE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const CategoryBuTemplates = handleActions(
  {
    [constants.FF_GET_TEMPLATES_CATEGORY_BU]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const CategoryDocuments = handleActions(
  {
    [constants.FF_GET_DOCUMENTS_CATEGORY]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const DocumentTemplateList = handleActions(
  {
    [constants.FF_GET_TEMPLATE_DOCUMENT]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const createDocumentTemplate = handleActions(
  {
    [constants.FF_CREATE_DOCUMENT_TEMPLATE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const RequestList = handleActions(
  {
    [constants.FF_GET_REQUESTS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const FinanceNotificationsList = handleActions(
  {
    [constants.FF_NOTIFICATIONS]: (state, action) => {
      const { status, data } = action.payload;
      console.log("here");
      if (status === 200) {
        return data.payload.notifications;
      }
      return {};
    },
  },
  []
);

export const FinanceRequestData = handleActions(
  {
    [constants.FF_GET_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const PositionsList = handleActions(
  {
    [constants.FF_GET_POSITIONS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return [];
    },
  },
  []
);

export const TemplateQuery = handleActions(
  {
    [constants.FF_TEMPLATE_QUERY]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return [];
    },
  },
  []
);
export const getAllProfiles = handleActions(
  {
    [constants.FF_GET_PROFILE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.profiles;
      }
      return {};
    },
  },
  []
);
export const getAllStates = handleActions(
  {
    [constants.FF_GET_STATES]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const createNewProfile = handleActions(
  {
    [constants.FF_CREATE_PROFILE]: (state, action) => {
      return state;
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);
export const updateProfile = handleActions(
  {
    [constants.FF_UPDATE_PROFILE]: (state, action) => {
      return state;
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

export const enableProfile = handleActions(
  {
    [constants.FF_TOGGLE_PROFILE]: (state, action) => {
      return state;
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);
export const getMyRequest = handleActions(
  {
    [constants.FF_MY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);
