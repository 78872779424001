
import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Button,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup
} from "reactstrap";
import { Upload, Icon } from 'antd';
import { deleteFile } from 'actions/financialFlows';

import FormInformation from "components/FinanceFlows/FlowsAprobation/FormInformation";
import FormApprovers from "components/FinanceFlows/FlowsAprobation/FormApprovers";
const { Dragger } = Upload;
const FormToRequest = ({ disabled, InformationTamplet, handleOnChangeInfo, template, setApprovers, approvers, newInfo, insertNewRequest,uploadProps,fileList }) => {

    return (
        <div>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <h3 className="mb-0">Información Adicional</h3>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="descripcion-sol"
                                        >
                                            Descripción de Solicitud:
                                        </label>
                                        <Input
                                            id="descripcion-sol"
                                            resize="none"
                                            rows="3"
                                            type="textarea"
                                            onChange={(e) => handleOnChangeInfo("description", e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <h3 className="mb-0">
                                Flujo de Aprovación
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <FormApprovers
                                template={template}
                                approvers={approvers}
                                setApprovers={setApprovers}
                            />
                        </CardBody >
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardHeader>
                            <h3 className="mb-0">
                                Información de Solicitud
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <FormInformation
                                InformationTamplet={InformationTamplet}
                                handleOnChangeInfo={handleOnChangeInfo}
                                disabled={disabled}
                                newInfo={newInfo}
                                identificator={"unitTypeId"}
                                typeForm={1}
                                valueInsert={"id"}

                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Archivos Adjuntos</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Button
                                        color="primary"
                                        // onClick={(e) => { this.toggleAttachmentModal() }}
                                        size="sm"
                                    >
                                        <i className={"fa fa-upload"} />
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Dragger {...uploadProps} fileList={fileList}>
                                <p className="ant-uploading-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                                <p className="ant-uploading-hint">
                                    Puede seleccionar archivos de diferentes folders/carpetas
                                </p>
                            </Dragger>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs="12">
                                    <p className="mb-0">
                                        Favor verificar que el flujo de aprobación sea el
                                        correcto y los aprobadores se encuentren disponibles
                                        para atender la solicitud, de otro modo no se podra
                                        reemplazar el aprobador seleccionado y se tendrá que
                                        cancelar la solicitud.
                                        <br></br>
                                        <br></br>
                                        Enviaremos correos a los aprobadores seleccionados,
                                        si alguno de ellos rechaza la solicitud esta pasara
                                        inmediatamente a estado <code>RECHAZADA</code>. Si
                                        el 100% de los aprobadores aprueba la solicitud
                                        pasara automáticamente a estado{" "}
                                        <code>APROBADA</code>.
                                    </p>
                                    <br></br>
                                </Col>
                                <Col>
                                    <Button
                                        block
                                        color="success"
                                        size="lg"
                                        type="button"
                                        onClick={() => insertNewRequest()}
                                    >
                                        Generar Solicitud
                                    </Button>
                                </Col>

                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default FormToRequest;