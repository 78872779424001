// core actions
import { createDataMaster, findAllDataMasterByStatus, updateDataMasterById, updateStatusDataMasterByID } from 'actions/secoh.jsx';
// antd components
import { Spin, message } from "antd";
// core components SECOH
import AddServiceDrawer from 'components/SECOH/AddServiceDrawer.jsx';
import UploadServiceDrawer from 'components/SECOH/UploadServiceDrawer.jsx';
import ServicesManagements from 'components/SECOH/ServicesManagements.jsx';
// core components Shared
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getDataMasterServices } from 'selectors/secoh.jsx';
// javascript library to parser xlsx to json
const XLSX = require('xlsx');
class ServicesManagement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      upload: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      status: 1,
      addService: false,
      uploadService: false,
      initialValues: {
        code: null,
        description: '',
        serviceGroup: '',
        category: '',
        subcategory: '',
      },
      editService: false,
      selector: {
        visible: false,
        type: null,
        options: []
      },
      fileList: [],
      dataUpload: [],
      search: ''
    };
  };

  componentWillMount() {
    const { status } = this.state;
    this.findAllDataMasterByStatus(status);
  };

  findAllDataMasterByStatus = (status) => {
    this.props.findAllDataMasterByStatus(status).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({
        willMount: false,
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnSetPage = page => {
    this.setState({
      page,
    });
  };

  handleOnChangeView = event => {
    const { id } = event.target;
    this.setState({
      status: id === 'viewActive' ? 1 : 0,
      willMount: true,
      page: 1
    });
    this.findAllDataMasterByStatus(id === 'viewActive' ? 1 : 0);
  };

  handleOnToggleAddService = (type) => {
    if (type === 'add') {
      this.setState((state) => ({
        addService: !state.addService,
        initialValues: {
          code: null,
          description: '',
          serviceGroup: '',
          category: '',
          subcategory: '',
        },
      }));
    } else {
      this.setState((state) => ({
        editService: !state.editService
      }));
    }
  };

  onSaveService = values => {
    values.status = 1;
    this.setState({
      loading: true,
      message: 'Creando el grupo de servicio'
    });
    this.props.createDataMaster({ data: [values] }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const { status } = this.state;
        this.findAllDataMasterByStatus(status);
        this.handleOnToggleAddService('add');
        this.setState({
          initialValues: {
            code: null,
            description: '',
            serviceGroup: '',
            category: '',
            subcategory: '',
          },
        });
      }
      this.setState({
        loading: false,
        message: ''
      });
    });
  };

  onEditService = (id, values) => {
    this.setState({
      loading: true,
      message: 'Actualizando el grupo de servicio'
    });
    this.props.updateDataMasterById(id, { values }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const { status } = this.state;
        this.findAllDataMasterByStatus(status);
        this.handleOnToggleAddService('edit');
        this.setState({
          initialValues: {
            code: null,
            description: '',
            serviceGroup: '',
            category: '',
            subcategory: '',
          },
        });
      }
      this.setState({
        loading: false,
        message: ''
      });
    });
  };

  handleOnSaveService = values => {
    const { addService, editService } = this.state;
    if (addService) {
      this.onSaveService(values);
    } else if (editService) {
      this.onEditService(values.id_DataMaster, values);
    }
  };

  handleOnEditDataMasterOption = () => {
    this.setState({
      selector: {
        visible: false,
        type: null,
        options: []
      }
    });
  };

  handleOnSearchDataMasterOption = type => {
    const { rows } = this.props;
    let options = rows.map((row) => row[type]);
    options = options.map(e => e)
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => options[e]).map(e => options[e]);
    options = options.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    this.setState({
      selector: {
        visible: true,
        type,
        options
      },
    });
  };

  onUpdateStatusDataMasterById = (id, status) => {
    this.setState({
      loading: true,
      message: 'Actualizando el grupo de servicio seleccionado',
      alert: null
    });
    this.props.updateStatusDataMasterByID(id, status).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const { status } = this.state;
        this.findAllDataMasterByStatus(status);
      }
      this.setState({
        loading: false,
        message: ''
      });
    });
  };

  handleOnRelaunchService = row => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de activar el grupo de servicio?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() =>
            this.onUpdateStatusDataMasterById(row.id_DataMaster, 1)
          }
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, activar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnDeleteService = row => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de bloquear el grupo de servicio?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() =>
            this.onUpdateStatusDataMasterById(row.id_DataMaster, 0)
          }
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, bloquear"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnEditService = row => {
    this.setState({
      initialValues: row,
    });
    this.handleOnToggleAddService('edit');
  };

  handleOnToggleUploadService = () => {
    this.setState((state) => ({
      uploadService: !state.uploadService
    }));
  };

  handleOnUploadService = () => {
    this.setState({
      uploadService: true
    });
  };

  handleOnFileUpload = async info => {
    const { status } = info.file;
    if (status === "removed") {
      this.setState({
        fileList: [],
        fileLength: 0
      });
      message.success(`El archivo ${info.file.name} fue quitado exitosamente.`);
    } else if (status === "removed") {
      this.setState({
        fileList: [],
        fileLength: 0
      });
      message.success(`El archivo ${info.file.name} dio un error al ser cargado.`);
    } else {
      this.setState({
        upload: true,
      });
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = await fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          let { status } = file;
          if (status === 'done') {
            // this.notify("success", "Exito", `El archivo ${file.name} fue cargado exitosamente.`);
            message.success(`El archivo ${file.name} fue cargado exitosamente.`);
            const reader = new FileReader();
            reader.onload = async (event) => {
              const data = event.target.result;
              const workbook = XLSX.read(data, {
                type: 'binary',
              });
              workbook.SheetNames.forEach((sheetName) => {
                const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                // console.log(XL_row_object);
                // const json_object = JSON.stringify(XL_row_object);
                // console.log(json_object);
                this.setState({
                  dataUpload: XL_row_object,
                });
              });
            }
            reader.onerror = function (event) {
              this.notify("warning", "Atención", `No se logro leer el archivo ${file.name}, ${event.target.error.code}`);
            };
            reader.readAsBinaryString(file.originFileObj);
          } else if (status === 'error') {
            // this.notify("danger", "Falló", `La carga del archivo ${file.name} fallo.`);
            message.success(`La carga del archivo ${file.name} fallo.`);
          } else if (status === "removed") {
            // this.notify("warning", "Atención", `El archivo ${file.name} fue quitado exitosamente.`);
            message.success(`El archivo ${file.name} fue quitado exitosamente.`);
          }
        }
        return file;
      });
      this.setState({
        fileList,
        upload: false,
      });
    }
  };

  handleOnSaveUploadServices = () => {
    const { dataUpload } = this.state;
    this.setState({
      upload: true,
    });
    this.props.createDataMaster({ data: dataUpload }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const { status } = this.state;
        this.findAllDataMasterByStatus(status);
        this.handleOnToggleUploadService();
        this.setState({
          fileList: [],
          dataUpload: [],
        });
      }
      this.setState({
        upload: false,
      });
    });
  };

  handleOnSearchValue = event => {
    const { value } = event.target;
    this.setState({
      search: value,
      page: 1
    });
  };

  handleOnRenderRowsBySearch = rows => {
    const { search } = this.state;
    if (search !== '') {
      const newRows = rows.filter((row) => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()));
      return newRows;
    }
    return rows;
  }

  render() {

    const {
      name,
      parentName,
      cols,
      rows,
    } = this.props;

    const {
      alert,
      upload,
      loading,
      message,
      willMount,
      page,
      sizePerPage,
      status,
      search,
      selector,
      fileList,
      addService,
      editService,
      uploadService,
      initialValues,
    } = this.state;

    return (
      <>
        {alert}
        <AddServiceDrawer
          title="Crear nuevo Grupo de Servicio"
          visible={addService || editService}
          uploading={loading}
          initialValues={initialValues}
          selector={selector}
          onClose={() => this.handleOnToggleAddService(addService ? 'add' : 'edit')}
          onSave={this.handleOnSaveService}
          onEdit={this.handleOnEditDataMasterOption}
          onSearch={this.handleOnSearchDataMasterOption}
        />
        <UploadServiceDrawer
          title="Cargar nuevos Grupos de Servicios"
          visible={uploadService}
          uploading={upload}
          fileList={fileList}
          onClose={this.handleOnToggleUploadService}
          onSave={this.handleOnSaveUploadServices}
          onFileList={this.handleOnFileUpload}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {
              willMount ?
                <Row className="justify-content-center">
                  <Col>
                    <CardLoading title="Cargando la información de los servicios gestionados" />
                  </Col>
                </Row>
                :
                <ServicesManagements
                  title="Mantenimientos de Servicios"
                  subtitle="Gestión de servicios según los grupos de materiales"
                  cols={cols}
                  rows={this.handleOnRenderRowsBySearch(rows)}
                  page={page}
                  search={search}
                  statusFlag={status}
                  sizePerPage={sizePerPage}
                  onSearch={this.handleOnSearchValue}
                  onSetPage={this.handleOnSetPage}
                  onChangeView={this.handleOnChangeView}
                  onEditService={this.handleOnEditService}
                  onAddService={() => this.handleOnToggleAddService('add')}
                  onDeleteService={this.handleOnDeleteService}
                  onUploadService={this.handleOnUploadService}
                  onRelaunchService={this.handleOnRelaunchService}
                />
            }
          </Spin>
        </Container>
      </>
    );
  }
}

ServicesManagement.defaultProps = {
  name: "Gestión de Servicios",
  parentName: "Contratos On Hold",
  cols: [
    { key: "code", name: "Código" },
    { key: "description", name: "Descripción" },
    { key: "serviceGroup", name: "Servicio" },
    { key: "category", name: "Categoría" },
    { key: "subcategory", name: "Subcategoría" },
    { key: "grp1", name: "GRP1" },
    { key: "grp2", name: "GRP2" },
    { key: "grp3", name: "GRP3" },
    { key: "grp4", name: "GRP4" },
    { key: "grp5", name: "GRP5" },
    { key: "grp6", name: "GRP6" },
    { key: "grp7", name: "GRP7" },
    { key: "grp8", name: "GRP8" },
    { key: "grp9", name: "GRP9" },
    { key: "grp10", name: "GRP10" },
    { key: "grp11", name: "GRP11" },
    { key: "grp12", name: "GRP12" },
    { key: "grp13", name: "GRP13" },
    { key: "grp14", name: "GRP14" },
    { key: "grp15", name: "GRP15" },
    { key: "grp16", name: "GRP16" },
  ],
  rows: [],
};

ServicesManagement.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  rows: getDataMasterServices(state),
});

export default withRouter(connect(mapStateToProps, {
  findAllDataMasterByStatus,
  createDataMaster,
  updateStatusDataMasterByID,
  updateDataMasterById
})(ServicesManagement));
