/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to create a CARD for each role management section
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import { Card, CardBody } from "reactstrap";

function RolCards({ title, subtitle, id, image, onSelect }) {
  return (
    <Card className="card-stats card-lift--hover" onClick={() => onSelect(id)}>
      <CardBody>
        <img
          alt="..."
          className="img-center img-fluid shadow shadow-lg--hover"
          src={require(`assets/img/hoursReport/${image}`)}
          style={{
            objectFit: "contain",
            width: "110px",
            height: "80px",
            borderRadius: "20px",
            backgroundColor: "#f7f7f7",
          }}
        />

        <div className="pt-4 text-center">
          <h5 className="h3 title">
            <span className="d-block mb-1">{title}</span>
            <small className="h4 font-weight-light text-muted">
              {subtitle}
            </small>
          </h5>
        </div>
      </CardBody>
    </Card>
  );
}

export default RolCards;
