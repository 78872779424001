import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { HelpCircle } from 'react-feather'
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  Row,
  Col
} from 'reactstrap'

const UpdateGoal = ({ confirmados, total, selectedEmployee, isManager }) => {
  console.log(confirmados)

  // useEffect(() => {
  //   if (confirmados !== null && total !== null && confirmados !== undefined && confirmados !== 0 && total !== undefined && total !== 0) {
  //     setData(x => ({ ...x, completed: confirmados, total: total }))
  //   }else if(confirmados === 0 && total === 0){
  //       setData(x => ({ ...x, completed: 0, total: 1 }))
  //   }
  // }, [confirmados, total])

  useEffect(() => {
    if (selectedEmployee?.length > 0) {
      setData(x => ({ ...x, completed: confirmados, totalG: total }))
    } else if (isManager === false) {
      setData(x => ({ ...x, completed: confirmados, totalG: total }))
    } else {
      setData({ completed: 0, totalG: 1 })
    }
  }, [selectedEmployee, confirmados, total, isManager])

  const [data, setData] = useState({
    completed: 0,
    totalG: 1
  })

  const { completed, totalG } = data

  const calcPercentage = (comp, tot) => {
    return ((comp * 100) / tot).toFixed(1)
  }

  const setColor = () => {
    let color = null
    let percent = calcPercentage()
    if (percent <= 25) {
      color = '#e55151'
    } else if (percent >= 26 && percent <= 80) {
      color = '#e5db51'
    } else {
      color = '#51e5a8'
    }
    return color
  }

  const options = {
      chart: {
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1
        }
      },
      colors: [setColor()],
      plotOptions: {
        radialBar: {
          offsetY: 10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '77%'
          },
          track: {
            background: '#ebe9f1',
            strokeWidth: '50%'
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              color: '#5e5873',
              fontFamily: 'Montserrat',
              fontSize: '2.86rem',
              fontWeight: '600'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: 'success',
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      grid: {
        padding: {
          bottom: 30
        }
      }
    },
    series = [calcPercentage(completed, totalG)]

  return data !== null ? (
    <Card>
      <CardHeader>
        <CardTitle tag='h4'>Cumplimiento de Actualización</CardTitle>
      </CardHeader>
      <CardBody className='p-0'>
        <Chart
          options={options}
          series={series}
          type='radialBar'
          height={245}
        />
      </CardBody>
      <Row className='border-top text-center mx-0'>
        <Col xs='6' className='border-right py-1'>
          <CardText className='text-muted mb-0'>Completados</CardText>
          <h3 className='font-weight-bolder mb-0'>{completed}</h3>
        </Col>
        <Col xs='6' className='py-1'>
          <CardText className='text-muted mb-0'>Total de contactos</CardText>
          <h3 className='font-weight-bolder mb-0'>{totalG}</h3>
        </Col>
      </Row>
    </Card>
  ) : null
}
export default UpdateGoal
