// javascipt plugin for creating charts
import Chart from "chart.js";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin used to create charts
import { Bar, Pie, Line } from "react-chartjs-2";
// reactstrap components
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// core components
import {
  // global options for the charts
  chartOptions,
  // function that adds the global options to our charts
  parseOptions,
} from "variables/charts.jsx";

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {
    const {
      event,
      title,
      subtitle,
      bar,
      pie,
      line,
      object,
      typeDashboard,
      onChangeChart,
    } = this.props;

    console.log("OBJETO DEL CHART=============");
    console.log(object);

    return (
      <Card>
        <CardHeader>
          <Row>
            <Col xs="12" md="7">
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            {event && (
              <Col
                xs="12"
                md="5"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="viewOnHold"
                    type="radio"
                    checked={!typeDashboard}
                    onChange={onChangeChart}
                  />
                  <label className="custom-control-label" htmlFor="viewOnHold">
                    País
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="viewInProgress"
                    type="radio"
                    checked={typeDashboard}
                    onChange={onChangeChart}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="viewInProgress"
                  >
                    Servicio
                  </label>
                </div>
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {bar ? (
              <Bar
                data={object.data}
                options={object.options}
                className="chart-canvas"
                id="chart-bar-stacked"
              />
            ) : null}
            {pie ? (
              <Pie
                data={object.data}
                options={object.options}
                className="chart-canvas"
                id="chart-pie"
              />
            ) : null}
            {line ? (
              <Line
                data={object.data}
                options={object.options}
                id="chart-sales"
                className="chart-canvas"
              />
            ) : null}
          </div>
        </CardBody>
      </Card>
    );
  }
}

Charts.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  bar: PropTypes.bool,
  pie: PropTypes.bool,
};

export default Charts;
