/*!

=========================================================
*Databot Dashboard
=========================================================

*Vista Principal "Stats" la cual retorna:
    -Los gráficos de las estadisticas de ahorro y log del bot
    -Tablas con todos los robots en orden de cual tiene más ahorro en el año seleccionado.
    -Tablas con los detalles de lo que realiza el bot

=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/

//Componentes de la librería de react.
import React, { useState, useEffect } from 'react';

//Componente de encabezado
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

//Dispatch para hacer peticiones
import { useDispatch } from 'react-redux';

//Componente para emitir notificaciones
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//Componente de spinner de la librería antd
import { Spin } from 'antd';

//Componentes de la librería de reactstrap
import { Col, Container, Row, Button, } from "reactstrap";

//Actions para realizar peticiones al API
import { getDatabotOptions } from 'actions/databotDashboard.jsx';

//Import de componente contexto, para establecer variables globales
import { UserContext } from 'components/Databot/SharedComponents/UserContext';

//#region componentes desarrollados para mostrar estadísticas y tablas.
import { CardsInformations } from 'components/Databot/Stats/CardsInformations';
import { FiltersStatsDatabot } from 'components/Databot/Stats/FiltersStatsDatabot';
import { Graphs } from 'components/Databot/Stats/Graphs';
import { TableInformation } from 'components/Databot/Stats/TableInformation';
import { DrawerInfo } from 'components/Databot/Stats/DrawerInfo';
import { TableTopRobots } from 'components/Databot/Stats/TableTopRobots';
//#endregion

//Spinner
import CardLoading from "components/Shared/Cards/CardLoading.jsx";

// import numeral from 'numeral';
import { DrawerInfoToComponents } from 'components/Databot/Stats/DrawerInfoToComponents';



const Stats = () => {

    //#region Mensajes de notificaciones
    const MySwal = withReactContent(Swal);
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    //#endregion

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States Locales

    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(true);
    //#endregion


    //#region Filtros de Stats
    const [filterSelection, setFilterSelection] = useState();
    const [filterOptions, setFilterOptions] = useState({
        monthFilter: [
            { label: "Enero", value: 1 },
            { label: "Febrero", value: 2 },
            { label: "Marzo", value: 3 },
            { label: "Abril", value: 4 },
            { label: "Mayo", value: 5 },
            { label: "Junio", value: 6 },
            { label: "Julio", value: 7 },
            { label: "Agosto", value: 8 },
            { label: "Setiembre", value: 9 },
            { label: "Octubre", value: 10 },
            { label: "Noviembre", value: 11 },
            { label: "Diciembre", value: 12 },
        ]
    })
    //#endregion

    //State para el spinner de colores
    const [loadingColorSpinner, setLoadingColorSpinner] = useState(true);


    //State bandera para establecer si se muestra el drawer de más información
    const [showMoreInfoDatabot, setShowMoreInfoDatabot] = useState(false)
    //#endregion

    //State para activar o desactivar el state de ayuda de componentes
    const [drawerHelpComponents, setDrawerHelpComponents] = useState({ visible: false, type: "" })



    //#region Extracción de información de los APIS



    //#region ******** Extracción ARRANQUE ********

    useEffect(() => {
        const getDataAtStart = async () => {
            handleGetDatabotOptions()
        }
        getDataAtStart();
    }, [])

    //#endregion 

    //#region Función y efectos para extraer todas las opciones para todos los dropdowns
    //Estado que guarda los robots que hay en orchestrator
    const [databotOptions, setDatabotOptions] = useState([]);


    //Funcion para traer las rows de la data de la tabla
    const handleGetDatabotOptions = async () => {

        dispatch(getDatabotOptions()).then((resp) => {
            const { payload } = resp;

            setLoad(false);


            // setLoadingColorSpinner(false)
            if (payload.status === 200) {
                setDatabotOptions(payload.data.payload.databotData)
                // console.log("Aqui databot options")
            }
            else {
                Toast.fire({
                    title: payload.message,
                    type: 'warning'
                })
            }


        })
    }

    //#endregion


    return (
        <>

            <UserContext.Provider value={
                {
                    //Aqui se establecen los states globales o de tipo context

                    //State global almacena la selección de filtros
                    filterSelection,
                    setFilterSelection,

                    //Las opciones de los filtros
                    filterOptions,
                    setFilterOptions,

                    //Opciones del databot
                    databotOptions,
                    setDatabotOptions,

                    drawerHelpComponents,
                    setDrawerHelpComponents,


                    //Destinados al drawer
                    showMoreInfoDatabot,
                    setShowMoreInfoDatabot,

                    setLoad,

                    drawerHelpComponents,
                    setDrawerHelpComponents,

                    setLoadingColorSpinner

                }
            }>
                <DrawerInfo />
                <DrawerInfoToComponents />

                {
                    loadingColorSpinner && false ?
                        <Row className="justify-content-center">
                            <Col>
                                <CardLoading title="Cargando dashboard databot" />
                            </Col>
                        </Row> :
                        <>
                            <AdminHeader name="Inicio" parentName="Estadísticas Databot" />
                            <Spin size="large" spinning={loading}>

                                <Container className="mt--6" fluid>


                                    <CardsInformations 
                                    />

                                    <FiltersStatsDatabot
                                  
                                    />

                                    <Graphs

                                    />




                                    <Row>
                                        <Col xs="12" md="12">
                                            {/*Tabla de Top de Bots */}
                                            <Row>
                                                <Col sm='12' style={{ display: "flex", justifyContent: "flex-start" }}>
                                                    <Button
                                                        className="btn-info btn-round btn-icon"
                                                        color="info"
                                                        id="helpTopRobotsBtn"
                                                        outline
                                                        size="sm"
                                                        onClick={(e) => setDrawerHelpComponents({ ...drawerHelpComponents, visible: true, type: 'topRobotsTable' })}
                                                    >
                                                        <span className="btn-inner--icon ">
                                                            <i className="fa fa-info-circle" />
                                                        </span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <TableTopRobots
                                            />


                                        </Col>





                                    </Row>


                                    <Col xs="12" md="12">
                                        {/*Tabla de tipo bot details.*/}
                                        <Row>
                                            <Col sm='12' style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <Button
                                                    className="btn-info btn-round btn-icon"
                                                    color="info"
                                                    id="helpDetailsBtn"
                                                    outline
                                                    size="sm"
                                                    onClick={(e) => setDrawerHelpComponents({ ...drawerHelpComponents, visible: true, type: 'detailsTable' })}
                                                >
                                                    <span className="btn-inner--icon ">
                                                        <i className="fa fa-info-circle" />
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>

                                        <TableInformation
                                        />

                                    </Col>

                                </Container>

                            </Spin>
                        </>
                }
            </UserContext.Provider>

        </>

    );
};
export default Stats;




























































