import { createNewEntity, getContacts } from 'actions/panamaBids.jsx';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from 'react-redux';
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
    Button, Col, Form, FormGroup, Input, Modal, ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";

const EntitiesCreateModal = ({
    toggle,
    options,
    setOptions,
    rows,
    setShowModal,
    setRealTable,
    Toast
}) => {

    const { newModal } = toggle
    const dispatch = useDispatch();
    //Hook que alamacena la informacion para crear una nueva entidad
    const [newInfo, setNewInfo] = useState("");
    //
    const [validate, setValidate] = useState(0);
    //Hook para el spin del select de contactos
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");

    //useEffect que llama la funcion "handleOngetContacts" que trae los contactos cada vez que se cambia el cliente
    useEffect(() => {
        const getContacts = async () => {
            await handleOngetContacts();
        }
        getContacts();
    }, [newInfo.customer])
    //Funcion que valida que los campos no esten vacios y si no lo estan crea la entidad
    const handleSendNewEntity = () => {
        dispatch(createNewEntity({ newInfo: newInfo })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {

                handleOngetContacts();
                closeModal();
                setRealTable(1);
                Toast.fire({
                    title: "Atención",
                    html: "Se creo correctamente",
                    type: 'success'
                });
            }
        }
        )

        //}
    }
    //Funcion para guardar la informacion del newInfo
    const handleChangeInfo = (constant, e) => {
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: e,
        }))
    }
    //Funcion para traer los contactos de un cliente a la hora de seleccionarlo
    const handleOngetContacts = async () => {
        setLoading(true);
        dispatch(getContacts({ info: newInfo.customer, type: "1" })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    contacts: payload.data.payload.response,
                }))
            }
            setLoading(false);
        })
    }
    //Cierra el modal de nueva entidad y borra la informacion en newInfo
    const closeModal = () => {
        setShowModal(prevState => ({
            ...prevState,
            newModal: !newModal,
        }))
        setNewInfo([]);
    }
    const handleOnValidateNewInfo = () => {
        if (validate === 6) {
            handleSendNewEntity();
        } else {
            setAlert(
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Hay informacion incompleta"
                    onConfirm={() => setAlert("")}
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Cerrar"
                    btnSize="md"
                />
            )
        }
    }
    useEffect(() => {
        let cont = 0;
        if (Object.keys(newInfo)) {
            const items = Object.keys(newInfo);
            for (const item of items) {
                if (newInfo[item] || newInfo[item] !== "" || newInfo[item] !== undefined) {
                    cont++;
                }
            }
        }
        setValidate(cont)
    }, [newInfo])
    return (
        <div>
            {alert}
            <Modal size="lg" isOpen={newModal} >
                <ModalHeader>
                    <Row>
                        <Col>
                            <h3 className="mb-0" >Datos Generales</h3>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    <Form noValidate className="formInfo needs-validation">
                        <Row>
                            {
                                rows.map((row, key) => (
                                    row.elementType === "Input" ?
                                        <Col xs={row.colWidth} key={key}>
                                            < FormGroup key={key}>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                >
                                                    {`${row.label}:`}
                                                </label>
                                                <Input
                                                    id={row.id}
                                                    key={key}
                                                    className="form-control"
                                                    required={true}
                                                    type={row.type}
                                                    placeholder={row.placeholder}
                                                    onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                                                />
                                                <div className="invalid-feedback">
                                                    Este campo no puede estar vacío
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        : row.elementType === "Select" ?
                                            row.id === "contact" ?
                                                <Col xs={row.colWidth} key={key}>
                                                    <Spin size="small" spinning={loading}>
                                                        < FormGroup key={key}>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {`${row.label}:`}
                                                            </label>
                                                            <Input
                                                                id={row.id}
                                                                key={key}
                                                                required={true}
                                                                type={row.type}
                                                                defaultValue={{ label: "", value: "" }}
                                                                className="basic-multi-select text-dark"
                                                                placeholder={row.placeholder}
                                                                value={newInfo.customerName}
                                                                onChange={(e) => handleChangeInfo(row.id, e.target.children[e.target.selectedIndex].id)}
                                                            >
                                                                {options[row.id].map((value, key) => {
                                                                    return (
                                                                        <option key={key} id={value.ID_CONTACT_CRM}>{`${value.FIRST_NAME} ${value.LAST_NAME}`}</option>
                                                                    );
                                                                })
                                                                }
                                                            </Input>
                                                            <div className="invalid-feedback">
                                                                Este campo no puede estar vacío
                                                            </div>
                                                        </FormGroup>
                                                    </Spin>
                                                </Col>
                                                :
                                                <Col xs={row.colWidth} key={key}>
                                                    < FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {`${row.label}:`}
                                                        </label>
                                                        <Input
                                                            id={row.id}
                                                            key={key}
                                                            required={true}
                                                            type={row.type}
                                                            defaultValue={{ label: "", value: "" }}
                                                            className="basic-multi-select text-dark"
                                                            placeholder={row.placeholder}
                                                            value={newInfo.customerName}
                                                            onChange={(e) => handleChangeInfo(row.id, e.target.children[e.target.selectedIndex].id)}
                                                        >
                                                            {options[row.id].map((value, key) => {
                                                                return (
                                                                    <option key={key} id={value.Code}>{value.label}</option>
                                                                );
                                                            })
                                                            }
                                                        </Input>
                                                        <div className="invalid-feedback">
                                                            Este campo no puede estar vacío
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            : row.elementType === "typeahead" && (
                                                <Col xs={row.colWidth} key={key}>
                                                    <FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {`${row.label}:`}
                                                        </label>
                                                        <Typeahead
                                                            id={row.id}
                                                            required={true}
                                                            labelKey={option => `${option.name}`}
                                                            minLength={1}
                                                            onChange={(e) => handleChangeInfo(row.id, row.id === "customer" ? e[0] : e[0])}
                                                            options={options[row.id]}
                                                            placeholder="Escriba el nombre del cliente..."
                                                            value={newInfo.customerName}
                                                            renderMenuItemChildren={(item) => (
                                                                <div >
                                                                    {item.name}
                                                                    <div>
                                                                        {row.id === "customer" ?
                                                                            <small>ID: {item.id}</small>
                                                                            :
                                                                            <small>User: {item.user}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </FormGroup>
                                                    <div className="invalid-feedback">
                                                        Este campo no puede estar vacío
                                                    </div>
                                                </Col>
                                            )))
                            }
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            <Button
                                className="btn-icon"
                                color="danger"
                                block type="button"
                                onClick={() => closeModal()}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i class="fas fa-times"></i>
                                </span>Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="btn-icon"
                                color="success"
                                block type="button"
                                onClick={() => handleOnValidateNewInfo()}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-plus" />
                                </span>Crear
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </div >
    );
};


export default EntitiesCreateModal;