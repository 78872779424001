import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Card, CardTitle, CardBody, Col, CardHeader, Button, Row, ListGroup, ListGroupItem, Collapse, CardFooter } from 'reactstrap';
import FormInformation from 'components/FinanceFlows/FlowsAprobation/FormInformation.jsx';
import Modal from "components/FinanceFlows/Administration/Modals";
import Approvers from 'components/FinanceFlows/FlowsAprobation/Approvers';
import Timeline from 'components/FinanceFlows/FlowsAprobation/Timeline.jsx';
import moment from 'moment';
import { getUsernameLogged } from 'selectors/adminLayout.jsx';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { approveRequest, cancelRequest, relaunchRequest, downloadAttachmentByPath, getRequestByUser, getAllRequest, getRequestMyFlow, deleteFile, getRequestId, updateRevenueStatus } from "actions/financialFlows";
import { urlBase } from "api/urls.jsx";
import { Upload, Icon, Spin } from 'antd';
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';

const RequestDetails = ({ request, formRequester, formDocument, formDocumentDesc, setInfoToModule, setRequestSelected, typeRequest, countRows, setCountRows }) => {
    const [collapse, setCollapse] = useState(false);
    const [alert, setAlert] = useState("");
    const [nextApprover, setNextApprover] = useState({});
    const [commentRequest, setCommentRequest] = useState({
        comment: ""
    });
    const [openModal, setModal] = useState({
        acceptModal: false,
        declineModal: false,
        confirmRR: false,
        relaunchRR: false,
    });
    const [relaunchedValidate, setRelaunchedValidate] = useState(true);
    const [newInfo, setNewInfo] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoad] = useState(false);
    const [loadingText, setLoadingText] = useState("");
    const toggle = () => setCollapse(!collapse);
    const dispatch = useDispatch();
    const actualUser = getUsernameLogged();
    const MySwal = withReactContent(Swal);
    const { Dragger } = Upload;

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    //Funcion para hacer los cambios de informacion de la solicitud ya creada.
    const handleOnChangeCommentRequest = (constant, value) => {
        setCommentRequest({ [constant]: value.replace(/["']/g, "") });
    };

    //Funcion para abrir un modal para el rechazo de una solicitud
    const handleOnDeclinedRequest = () => {
        setModal(prevState => ({
            ...prevState,
            declineModal: !openModal.declineModal
        }));
        if (openModal.declineModal) {
            setCommentRequest({
                comment: ""
            });
        }
    }

    // Funcion que muestra una ventana de confirmacion para relanzar la solicitud con los datos nuevos.
    const handleOnConfirmRequestRR = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea confirmar la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnConfirmRR()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, confirmar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }

    // Funcion que muestra una ventana de confirmacion para relanzar la solicitud con los datos nuevos.
    const handleOnRelaunchRR = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea prolongar un mes más la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnRelaunchRevenue()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, prolongar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }

    //Funcion para abrir el modal de aceptacion de una solicitud
    const handleOnAcceptRequest = () => {
        setModal(prevState => ({
            ...prevState,
            acceptModal: !openModal.acceptModal
        }));
        if (openModal.acceptModal) {
            setCommentRequest({
                comment: ""
            });
        }
    }

    // Funcion que permite imprimir la vista de la solicitud
    const printRequest = () => {
        setLoadingText("Descargando");
        setLoad(true);

        const domElement = document.getElementById("print");
        window.scrollTo(0, 0);
        html2canvas(domElement, {
            scale: 2, // Incrementar la escala para una mejor calidad
            useCORS: true, // Habilitar CORS para cargar imágenes externas si las hay
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
        }).then(canvas => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPdf('p', 'mm', 'a4');

            // Dimensiones del PDF
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // Definir los márgenes laterales
            const sideMargin = 10; // en unidades de PDF (milímetros por defecto)

            // Ajustar el ancho de la imagen teniendo en cuenta los márgenes laterales
            const imgWidth = pdfWidth - 2 * sideMargin;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Altura de la imagen restante
            let heightLeft = imgHeight;

            // Posición inicial en el PDF
            let position = 5;

            // Dividir la imagen en múltiples páginas si es necesario
            while (heightLeft > 0) {
                const pageHeight = heightLeft > pdfHeight ? pdfHeight : heightLeft;
                pdf.addImage(imgData, 'PNG', sideMargin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                position -= pdfHeight;
                if (heightLeft > 0) {
                    pdf.addPage();
                }
            }
            pdf.save(`Solicitud-${request.id}.pdf`);
        });

        setTimeout(() => {
            setLoad(false);
            setLoadingText("Descargando");
            Toast.fire({
                title: "Completado",
                html: "Descarga procesada",
                type: "success",
            });
        }, 5000);

    }

    // Funcion que extrae los datos del proximo aprobador
    const selectNextApprover = (approvers) => {
        let findApprover = approvers.find((value) => value.status === 0);
        if (!findApprover) {
            findApprover = approvers[request.approvers.length - 1];
        }
        setNextApprover(findApprover);
    }

    // Funcion que valida que el comentario de aprobacion o rechazo esta vacio.
    const validateComment = () => {
        return (commentRequest.comment === "");
    }

    // Funcion que valida si el usuario legeado actual es el mismo que el proximo aprobador
    const validateApprover = () => {
        return nextApprover.approverUser === actualUser || nextApprover.secondaryUser === actualUser || isAdmin;
    }

    // Funcion que valida si la solicitud esta rechazada y relanzada
    const validateRelaunched = () => {
        return ((request.relaunched === 1 && request.requestStateId === 5) || (request.relaunched === 1 && request.requestStateId === 8)) && (request.requesterUser === actualUser || isAdmin);
    }

    // Funcion que valida si la solicitud esta rechazada y relanzada
    const validateButtomRelaunch = () => {
        return (request.documentCategoryId === 17 && request.requestStateId === 8) && ((state.fileList && state.fileList.length === 0) && (Object.keys(newInfo).length === 0));
    }

    // Funcion que valida si existe algun elemento dentro de los cambios de la solicitud.


    // Funcion que valida si ya la solicitud esta completada
    const validateIsComplete = (request) => {
        return request.requestStateId === 3 || request.requestStateId === 5 || request.requestStateId === 7 || request.requestStateId === 8 ? false : true;
    }

    // Funcion que valida si en la solicitud se puede eliminar archivos
    const validateDeleteFiles = () => {
        return request.requestStateId === 5 ? false : true;
    }

    // Funcion que valida si en la solicitud se puede eliminar archivos
    const validateRevenue = () => {
        const hoy = new Date(); //fecha actual
        const requestDate = new Date(request.createdat);
        const dia26MesAnterior = new Date(hoy.getFullYear(), hoy.getMonth() - 1, 26); //fecha de hace un mes
        const dia26DosMesesAntes = new Date(hoy.getFullYear(), hoy.getMonth() - 2, 26); //fecha de hace dos meses
        console.log(request)
        return (requestDate >= dia26DosMesesAntes && requestDate < dia26MesAnterior) && (request.documentCategoryId === 17) && (request.requestStateId === 7) && (request.requesterUser === actualUser || isAdmin)
    }

    // Funcion que valida si la solicitud es de Revenue Recognition
    const validateRevenueRequest = () => {
        return (request.documentCategoryId === 17) && (parseInt(request.relaunchedCount) > 0) && (validateApprover() && (request.requestStateId === 2));
    }

    // Funcion que valida si la solicitud esta rechazada y relanzada
    const validateRevenueCount = () => {
        return (request.documentCategoryId === 17) && (parseInt(request.relaunchedCount) >= 3) && (request.requestStateId === 2);
    }

    // Funcion que agrega la informacion editada de la solicitud si esta esta relanzada.
    const handleOnChangeNewInfo = (constant, value) => {
        setNewInfo(prevState => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, "")
        }));
    }

    // Funcion que muestra un mensaje de confirmacion al aceptar una solicitud
    const confirmAcceptRequest = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea aprobar la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnApproveRequest()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, aprobar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }

    // Funcion que se encarga del envio de los datos requeridos para la confirmacion de la solicitud
    const handleOnApproveRequest = async () => {

        setModal(prevState => ({
            ...prevState,
            acceptModal: !openModal.acceptModal
        }));
        setAlert("");
        setLoad(true);
        console.log(request.requestInformation);
        let body = {
            id: request.id,
            status: request.requestStateId,
            comment: commentRequest.comment,
            approver: request.nextApproverUser,
            requester: request.requesterName,
            description: request.description,
            information: request.requestInformation,
            document: {
                category: request.documentCategoryName,
                categoryId: request.documentCategoryId,
                description: request.documentApproverName,
                name: request.documentName,
                documentId: request.documentId
            },
            files:request.files,
            businessUnit: {
                name: request.buName
            },
            notify: request.notifyUsers,
            requesterEmail: request.requesterEmail
        }
        await dispatch(approveRequest(body)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setCommentRequest("");
                Toast.fire({
                    title: "Aprobado",
                    html: "Se aprobó la solicitud",
                    type: "success",
                });

                setCommentRequest({
                    comment: ""
                });
                setLoad(false);
            } else {
                setLoad(false);
            }
        });
        handleOnGetInformationToRequest();
    }

    // Funcion que muestra una ventana de confirmacion para el rechazo de la solicitud.
    const confirmCancelRequest = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea rechazar la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnCancelRequest()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, rechazar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }

    // Funcion que envia los datos para la cancelación de la aprobacion.
    const handleOnCancelRequest = async () => {
        setModal(prevState => ({
            ...prevState,
            declineModal: !openModal.declineModal
        }));
        setAlert("");
        setLoad(true);
        let body = {
            description: request.description,
            comment: commentRequest.comment,
            notify: request.notificationApprovalEmail,
            requesterEmail: request.requesterEmail,
            documentName: request.documentName
        }
        await dispatch(cancelRequest(request.id, body)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setCommentRequest("");

                Toast.fire({
                    title: "Rechazada",
                    html: "Se rechazo la solicitud",
                    type: "success",
                });

                setCommentRequest({
                    comment: ""
                });
                setLoad(false);
            } else {
                setLoad(false);
            }
        });
        handleOnGetInformationToRequest();
    }

    // Funcion que muestra una ventana de confirmacion para relanzar la solicitud con los datos nuevos.
    const confirmRelaunchedRequest = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea relanzar la solicitud?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnRelaunchRequest()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, relanzar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }

    // Funcion que envia los datos actualizados de la solicitud para relanzar de nuevo la solicitud.
    const handleOnRelaunchRequest = async () => {
        setAlert("");
        setLoad(true);
        const body = {
            id: request.id,
            newInfo: newInfo,
            description: request.description,
            notificationApproval: request.notificationApprovalEmail,
            document: {
                category: request.documentCategoryName,
                description: request.documentApproverName,
                name: request.documentName
            },
            businessUnit: {
                name: request.buName
            },
            requester: request.requesterName,
            requesterEmail: request.requesterEmail,
            files: fileList,
        }
        await dispatch(relaunchRequest(body)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                Toast.fire({
                    title: "Relanzado",
                    html: "Se relanzo la solicitud",
                    type: "success",
                });
                setLoad(false);
            } else {
                setLoad(false);
            }
            setRelaunchedValidate(true);
        });
        handleOnGetInformationToRequest();

    }
    const handleOnGetInformationToRequest = async () => {
        setLoad(true);
        let rows = [];
        let getRequests;

        switch (typeRequest) {
            case "request":
                getRequests = getAllRequest;
                break;
            case "Myrequest":
                getRequests = getRequestByUser;
                break;
            case "MyFlow":
                getRequests = getRequestMyFlow;
                break;
            default:
                break;
        }

        await dispatch(getRequests()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let requestsTemp = payload.data.data[0];
                for (const request of requestsTemp) {
                    if (!request.updateat) {
                        request.updateat = moment.utc(request.createdat).format("YYYY-MM-DD");
                    } else {
                        request.updateat = moment.utc(request.updateat).format("YYYY-MM-DD");
                    }
                    request.createdat = moment.utc(request.createdat).format("YYYY-MM-DD");
                }
                rows = requestsTemp;
                setInfoToModule((prevState) => ({
                    ...prevState,
                    rows: requestsTemp
                }));
            } else {
                setLoad(false);
            }
        });
        if (request.id) {
            if (typeRequest === "MyFlow") {
                await dispatch(getRequestId(request.id)).then((resp) => {
                    const { payload } = resp;
                    if (payload.status === 200) {
                        let request = payload.data.data;
                        if (!request.updateat) {
                            request.updateat = moment.utc(request.createdat).format("YYYY-MM-DD");
                        } else {
                            request.updateat = moment.utc(request.updateat).format("YYYY-MM-DD");
                        }
                        request.createdat = moment.utc(request.createdat).format("YYYY-MM-DD");

                        setRequestSelected(prevState => ({
                            ...prevState,
                            data: request
                        }));
                        selectNextApprover(request.approvers);
                        setLoad(false);
                    } else {
                        setLoad(false);
                    }
                });
            } else {
                const findRequestSelected = rows.find((item) => item.id === request.id);
                setRequestSelected(prevState => ({
                    ...prevState,
                    data: findRequestSelected
                }));
                selectNextApprover(findRequestSelected.approvers);
                setLoad(false);
            }
        }
    }

    // Funcion que permite descargar los archivos adjuntos de la solicitud.
    const downloadDocumentAttachment = (requestFileId, filename) => {
        dispatch(downloadAttachmentByPath(window.btoa(requestFileId))).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                const url = window.URL.createObjectURL(new Blob([payload.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${filename}`);
                document.body.appendChild(link);
                link.click();
            } else {
                setLoad(false);
            }
        });
    }

    // json con la informacion de los botones de accion (imprimir, aceptar, rechazar)
    const actionItems = [
        { label: "Imprimir Solicitud", isValidate: false, validate: "", colorIcon: "info", icon: "fas fa-print", method: () => printRequest(), disabled: false },
        { label: "Aceptar solicitud", isValidate: true, validate: validateApprover() && validateIsComplete(request), colorIcon: "success", icon: "fas fa-save", method: () => handleOnAcceptRequest(), disabled: false },
        { label: "Rechazar solicitud", isValidate: true, validate: validateApprover() && validateIsComplete(request), colorIcon: "danger", icon: "fas fa-times", method: () => handleOnDeclinedRequest(), disabled: false },
        { label: "Confirmar solicitud", isValidate: true, validate: validateRevenue(), colorIcon: "primary", icon: "fas fa-check", method: () => handleOnConfirmRequestRR(), disabled: false },
        { label: "Prolongar tiempo", isValidate: true, validate: validateRevenue(), colorIcon: "danger", icon: "fas fa-undo", method: () => handleOnRelaunchRR(), disabled: false },
    ]

    // json que carga las opciones de los modales.
    const modalInformation = [
        { label: "Por favor, agrega una descripcion", value: "comment", type: "textarea", colWidth: "12", required: true }
    ]

    //#region Funciones para la carga de archivos
    const [state, setState] = useState({
        fileList: [],
        idFileRequest: "",
        readyToSend: false,
    });
    const { fileList } = state

    //Funcion para agregar o borrar archivos del dragger
    const handleOnFileList = (info) => {
        const { status } = info.file;
        if (status === "removed") {
            const { file: { name, uid, response: { status, payload } } } = info;
            if (status === 200) {
                const { idFile } = payload;
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            }
            dispatch(deleteFile({ id: request.id, name: name })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    //deshabilitar el botón de enviar
                    if (fileList.length === 1) {
                        setState((prevState) => ({
                            ...prevState,
                            readyToSend: false,
                        }));
                    }
                } else {
                    setLoad(false);
                }
            })
        } else {
            const { fileList, file: { status, name } } = info;
            let response = "";
            if (status === 'done') {
                const { file } = info;
                response = file["response"]
                response = response.payload.idFile;
                //habilitar el botón de enviar
                setState((prevState) => ({
                    ...prevState,
                    readyToSend: true,
                }));
            } else if (status === 'error') {
                //  message.success(`Ocurrio un error cargando el archivo ${name}.`);
            }
            setState((prevState) => ({
                ...prevState,
                idFileRequest: response,
                fileList: fileList,
            }));
        }
    }


    // Funcion que muestra una ventana de confirmacion para relanzar la solicitud con los datos nuevos.
    const confirmDeleteFileRequest = (id, name) => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea eliminar el archivo seleccionado?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOndeleteFile(id, name)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }
    //Funcion que elimina un archivo seleccionado
    const handleOndeleteFile = (id, name) => {
        dispatch(deleteFile({ id: request.id, name: name, fileId: id })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setAlert("");
                Toast.fire({
                    title: "Eliminado",
                    html: "Se elimino el archivo seleccionado",
                    type: "success",
                });
            } else {
                setLoad(false);
            }
        })
        handleOnGetInformationToRequest();
    }

    //Funcion que elimina un archivo seleccionado
    const handleOnConfirmRR = async () => {
        setAlert("");
        setLoad(true);
        await dispatch(updateRevenueStatus(request.id, { status: true, relaunched: request.relaunched })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                Toast.fire({
                    title: "Confirmado",
                    html: "Se confirmo el proceso de Revenue Recognition",
                    type: "success",
                });
                setLoad(false);
            } else {
                setLoad(false);
            }
        })
        handleOnGetInformationToRequest();
    }

    //Funcion que elimina un archivo seleccionado
    const handleOnRelaunchRevenue = async () => {
        setAlert("");
        setLoad(true);
        await dispatch(updateRevenueStatus(request.id, { status: false, relaunched: request.relaunchedCount, description: request.description })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                Toast.fire({
                    title: "Confirmado",
                    html: "Se detuvo el proceso de Revenue Recognition",
                    type: "success",
                });
                setLoad(false);
            } else {
                setLoad(false);
            }
        })
        handleOnGetInformationToRequest();
    }

    const allowedFileTypes = [
        ".xlsx", ".xls", ".xlsb", ".xlt", ".pdf", ".doc", ".docx",
        ".cfr", ".csv", ".txt", ".eml", ".jpg", ".png", ".rtf",
        ".css", ".tif", ".ods", ".msg"
    ];
    //Funcion para llamar el endpoint que carga los archivos para crearlos en el back
    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: allowedFileTypes.join(","),
        multiple: true,
        action: `${urlBase}/finance-flows/add-files/${request.id}`,
        beforeUpload: file => {
            const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
            if (!allowedFileTypes.includes(fileType)) {
                console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
                return false;
            }
            return true;
        },
        onChange: info => {
            //Filtrar la lista de archivos válidos para mantener solo los que aún existen
            const filteredFiles = info.fileList.filter(file => {
                if (file.status) {
                    return true;
                }
                return false;
            });
            info.fileList = filteredFiles;
            // Actualizar la lista de archivos válidos
            // Llamar a la función handleOnFileList con la lista de archivos válidos
            handleOnFileList(info);
        },
    };
    //#endregion

    useEffect(() => {
        selectNextApprover(request.approvers);
        const teams = getTeamsUserAccess();
        if (teams.some((row) => row === "FF Admin")) {
            setIsAdmin(true);
        }
    }, [])

    //#region Informacion de las tablas en el formulario

    const handleOnChangeCountRows = (e, options) => {
        let array = [];
        for (let i = 1; i <= e; i++) {
            let json = {};
            for (const item of options) {
                const { label } = item;
                json[label] = "";
            }
            array.push(json);
        }
        setCountRows(array);
    }
    const handleOnChangeInfoTable = (constant, value, label, row) => {
        countRows[row][label.label] = value;
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: countRows,
        }))
    }
    //#endregion
    console.log(request)
    return (
        <>
            <div id="print">
                <Spin size="large" spinning={loading} tip={loadingText}>
                    {alert}
                    <Row className="mb-4">
                        <Col className="d-flex justify-content-start col">
                            <span className="h2 font-weight-bold mb-0 text-black">Solicitud #{request.id} - {request.documentName}</span>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg="6">
                            <Card className={request.requestStateColor}>
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                                solicitud - {request.requestStateName}
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0 text-white">{request.requestStateDesc}</span>
                                        </div>
                                        <Col className="col-auto">
                                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                <i className={request.requestStateIcon} />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card className="bg-gradient-primary">
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                                {nextApprover && nextApprover.name}
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0 text-white">Aprobador Actual</span>
                                        </div>
                                        <Col className="col-auto">
                                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                <i className="fa fa-user" />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-4">
                        {validateRevenueRequest() && (
                            <Col className="d-flex justify-content-start col">
                                <i
                                    className="fa fa-info-circle"
                                    style={{
                                        fontSize: "20px",
                                        color: "red",
                                        marginLeft: "20px",
                                        marginRight: "10px",
                                    }}
                                >
                                </i>
                                <h5>Esta solicitud se ha relanzado <strong>{request.relaunchedCount} veces</strong></h5>
                            </Col>
                        )}
                        {validateRelaunched() && (
                            <Col className="d-flex justify-content-end col">
                                <Button
                                    color="info"
                                    onClick={() => setRelaunchedValidate(false)}
                                    size="sm"
                                >
                                    <span>Editar campos</span>
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row className="mb-4">
                        <Col xs="6">
                            <Card>
                                <CardHeader>
                                    <h3>Información de Solicitud</h3>
                                </CardHeader>
                                <CardBody>
                                    <FormInformation
                                        InformationTamplet={request.requestInformation}
                                        disabled={relaunchedValidate}
                                        newInfo={request.requestInformation}
                                        handleOnChangeInfo={handleOnChangeNewInfo}
                                        identificator={"value"}
                                        typeForm={0}
                                        valueInsert={"requestInformationId"}
                                        countRows={countRows}
                                        handleOnChangeInfoTable={handleOnChangeInfoTable}
                                        handleOnChangeCountRows={handleOnChangeCountRows}
                                    />
                                </CardBody>
                                {validateRelaunched() && (
                                    <CardFooter>
                                        <Button
                                            color="success"
                                            onClick={() => confirmRelaunchedRequest()}
                                            size="sm"
                                            disabled={validateButtomRelaunch()}
                                        >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fa fa-rocket" aria-hidden="true" />
                                            </span>
                                            <span>Relanzar</span>
                                        </Button>
                                        {validateButtomRelaunch() && (
                                            <h5>* Por favor adjuntar documento o comentario que respalde el motivo para prolongar la solicitud</h5>
                                        )}

                                    </CardFooter>
                                )}
                            </Card>
                        </Col>
                        <Col xs="6">
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardHeader>
                                            <h3>Flujo de Aprobación</h3>
                                        </CardHeader>
                                        <CardBody>
                                            <Approvers
                                                approvers={request.approvers}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardHeader>
                                            <h3>Acciones</h3>
                                        </CardHeader>
                                        <CardBody>
                                            {actionItems.map((item, index) => (
                                                <Row className="align-items-center mb-2" key={index}>
                                                    {item.isValidate ?
                                                        <>
                                                            {item.validate && (
                                                                <>
                                                                    <Col xs="8">
                                                                        <h5 className="mb-0">{item.label}</h5>
                                                                    </Col>
                                                                    <Col>
                                                                        <Button
                                                                            className="btn-icon"
                                                                            block
                                                                            color={item.colorIcon}
                                                                            size="sm"
                                                                            type="button"
                                                                            onClick={item.method}
                                                                            disabled={item.disabled}
                                                                        >
                                                                            <i className={item.icon} />
                                                                        </Button>
                                                                    </Col>
                                                                </>
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            <Col xs="8">
                                                                <h5 className="mb-0">{item.label}</h5>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    className="btn-icon"
                                                                    block
                                                                    color={item.colorIcon}
                                                                    size="sm"
                                                                    type="button"
                                                                    onClick={item.method}
                                                                >
                                                                    <i className={item.icon} />
                                                                </Button>
                                                            </Col>
                                                        </>
                                                    }
                                                </Row>))}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <div className="accordion">
                                <Card className="card-plain">
                                    <CardHeader
                                        role="tab"
                                        onClick={() => toggle()}
                                    >
                                        <h5 className="mb-0" style={{
                                            whiteSpace: "normal",
                                            textAlign: "center",
                                        }}>Detalles de Solicitud</h5>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={collapse}
                                    >
                                        <CardBody>
                                            <h6 className="heading-small text-muted mb-4">
                                                DESCRIPCION DE SOLICITUD
                                            </h6>
                                            <FormInformation
                                                InformationTamplet={formDocumentDesc}
                                                disabled={true}
                                                newInfo={request}
                                                handleOnChangeInfo={handleOnChangeNewInfo}
                                                identificator={"value"}
                                                isCustom={false}
                                                typeForm={1}
                                            />
                                            <hr className="my-4" />
                                            <h6 className="heading-small text-muted mb-4">
                                                Solicitante
                                            </h6>
                                            <FormInformation
                                                InformationTamplet={formRequester}
                                                disabled={true}
                                                newInfo={request}
                                                handleOnChangeInfo={handleOnChangeNewInfo}
                                                identificator={"value"}
                                                typeForm={1}
                                                isCustom={false}
                                            />
                                            <hr className="my-4" />
                                            <h6 className="heading-small text-muted mb-4">
                                                INFORMACION DE DOCUMENTO
                                            </h6>
                                            <FormInformation
                                                InformationTamplet={formDocument}
                                                disabled={true}
                                                newInfo={request}
                                                handleOnChangeInfo={handleOnChangeNewInfo}
                                                identificator={"value"}
                                                isCustom={false}
                                                typeForm={1}
                                            />
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <Card>
                                <CardHeader>
                                    <h3>Bitacora de Cambios</h3>
                                </CardHeader>
                                <CardBody>
                                    {request.timeline.length === 0 ?
                                        <>
                                            <h5>Ninguna actividad registrada</h5>
                                        </>
                                        :
                                        <>
                                            <Timeline
                                                items={request.timeline}
                                            />
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs="6">
                            {validateRelaunched() && (
                                <Card>
                                    <CardHeader>
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3>Archivos Adjuntos</h3>
                                            </Col>
                                            <Col xs="4">
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Dragger {...uploadProps} fileList={fileList}>
                                            <p className="ant-uploading-drag-icon">
                                                <Icon type="inbox" />
                                            </p>
                                            <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                                            <p className="ant-uploading-hint">
                                                Puede seleccionar archivos de diferentes folders/carpetas
                                            </p>
                                        </Dragger>
                                    </CardBody>
                                </Card>
                            )}
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3>Archivos Adjuntos</h3>
                                        </Col>
                                        <Col xs="4">
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ListGroup className="list my--3" flush>
                                        {request.files && request.files.length === 0 ?
                                            <>
                                                <h5>Sin archivos adjuntos</h5>
                                            </>
                                            :
                                            <>
                                                {request.files.map((file, key) => (
                                                    <ListGroupItem className="px-0" key={key}>
                                                        <Row>
                                                            <Col>
                                                                {file.filename}
                                                            </Col>
                                                            <Col xs="4">
                                                                <div className="text-right">
                                                                    <Button
                                                                        className="btn-icon btn-xs"
                                                                        color="danger"
                                                                        onClick={() => confirmDeleteFileRequest(file.id, file.filename)}
                                                                        size="sm"
                                                                        disabled={validateDeleteFiles()}
                                                                    >
                                                                        <span className="btn-inner--icon">
                                                                            <i className="fas fa-trash" />
                                                                        </span>
                                                                    </Button>
                                                                    <Button
                                                                        className="btn-icon btn-xs"
                                                                        color="success"
                                                                        onClick={() => downloadDocumentAttachment(file.id, file.filename)}
                                                                        size="sm"
                                                                    >
                                                                        <span className="btn-inner--icon">
                                                                            <i className="fas fa-download" />
                                                                        </span>
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                ))}
                                            </>
                                        }
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </div>
            <Modal
                handleOnChangeInfo={handleOnChangeCommentRequest}
                text={"Aprobación de la Solicitud"}
                openModal={openModal.acceptModal}
                handleOnOpenModal={handleOnAcceptRequest}
                itemModal={modalInformation}
                itemInfo={commentRequest}
                nameButton1={"Cerrar"}
                nameButton2={"Guardar"}
                saveButton={confirmAcceptRequest}
                extramodal=""
                size="sm"
                saveButtonDisabled={validateComment()}
            />
            <Modal
                handleOnChangeInfo={handleOnChangeCommentRequest}
                text={"Rechazo de la Solicitud"}
                openModal={openModal.declineModal}
                handleOnOpenModal={handleOnDeclinedRequest}
                itemModal={modalInformation}
                itemInfo={commentRequest}
                nameButton1={"Cerrar"}
                nameButton2={"Guardar"}
                saveButton={confirmCancelRequest}
                extramodal=""
                size="sm"
                saveButtonDisabled={validateComment()}
            />
        </>
    );
};
export default RequestDetails;
