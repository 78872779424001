/*!

=========================================================
*TableInformation - Databot
=========================================================

*Componente de tipo server side para desplegar una tabla
con la información de los botdetailsy botlogs, la forma 
en que funciona es que en cada cambio de página hace una 
petición al API con un rango de fila establecidas según la
página en la que esté, además puede agregar filtros gracias 
a un state el cual al enviarse al API filtra la información 
con sentencias en el SQL con LIKE en las columnas, utiliza 
muchos hooks como useState y useEfects.
=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/

//Componentes de la librería de react.
import React, { useState, useContext, useEffect } from 'react'

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Dispatch para hacer peticiones
import { useDispatch } from 'react-redux';





//Componentes de la librería de reactstrap.
import {
    PaginationLink,
    PaginationItem,
    Pagination,
    CardHeader,
    FormGroup,
    Progress,
    Button,
    Badge,
    Table,
    Input,
    Card,
    Col,
    Row,

} from "reactstrap";

//Pie de la tabla.
import CardFooter from 'reactstrap/lib/CardFooter';

//Import de Spinner
import { Spin } from "antd";

//Componente para emitir notificaciones
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//Actions para realizar peticiones al API
import { getBotLogOrDetailsServerSide, } from 'actions/databotDashboard.jsx';


export const TableInformation = React.memo(({ /*handleDispatch,*/  spinnerTable }) => {




    const [radioButtons, setRadioButtons] = useState(
        {
            table: [
                { state: true, label: "Logs", id: "botlogs" },
                { state: false, label: "Detalle", id: "botdetails" },
            ],
            lineGraph: [
                { state: true, label: "Ahorro", id: "saving" },
                { state: false, label: "Iteraciones", id: "iterations" },
                { state: false, label: "Ahorro Líneas", id: "savingWithLines" },
                { state: false, label: "Iteraciones Líneas", id: "iterationsWithLines" },

            ],

        });





    //#region Mensajes de notificaciones
    const MySwal = withReactContent(Swal);
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    //#endregion



    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion


    const [tableActive, setTableActive] = useState("botlogs");

    const [columnsSelected, setcolumnsSelected] = useState([]);

    //#region Efectos y funciones de las tablas tanto la de serverside y común
    const [columnsSelectedBotDetails] = useState(
        [
            { value: "id", label: "id", type: "input", width: 45 },
            { value: "classText", label: "Robot", type: "input", width: 100 },
            { value: "departmentName", label: "Departamento", type: "input", width: 70 },
            { value: "createdBy", label: "Usuario", type: "input", width: 70 },
            { value: "sDate", label: "Inicio", type: "input", width: 60 },
            { value: "eDate", label: "Final", type: "input", width: 60 },
            { value: "comments", label: "Comentarios", type: "textarea", width: 300 },
        ]
    );

    const [columnsSelectedBotLog] = useState(
        [
            { value: "id", label: "id", type: "input", width: 45 },
            { value: "classText", label: "Robot", type: "input", width: 100 },
            { value: "departmentName", label: "Departamento", type: "input", width: 70 },
            { value: "createdBy", label: "Usuario", type: "input", width: 70 },
            { value: "caDate", label: "Fecha", type: "input", width: 60 },
            { value: "changeLog", label: "Cambio", type: "input", width: 60 },
            { value: "contents", label: "Contenido", type: "textarea", width: 300 },
            { value: "comments", label: "Comentarios", type: "textarea", width: 300 },

        ]
    );

    //#endregion


    //Cuando cambie que reasigne los colums selected.
    useEffect(() => {

        if (tableActive === 'botdetails') {
            setcolumnsSelected(columnsSelectedBotDetails)
        } else {
            setcolumnsSelected(columnsSelectedBotLog)

        }
    }, [tableActive])











    const [spinner, setspinner] = useState(false)

    const [rowsTable, setRowsTable] = useState([])

    const [rows, setRows] = useState([])

    useEffect(() => {
        setRows(rowsTable.botDetails?.rows)
    }, [rowsTable])





    //Funcion para traer las rows de la data de la tabla
    const handleGetBotLogsOrDetailsServerSide = async (index, limit, valuesToSearch) => {
        //Activar el spinner
        setspinner(true);

        dispatch(getBotLogOrDetailsServerSide({ index: index, limit: limit, valuesToSearch: valuesToSearch, table: tableActive }
        )).then((resp) => {
            const { payload } = resp;

            //Apagar el spinner
            setspinner(false);


            if (payload.status === 200) {


                setRowsTable(rowsTable => {
                    return {
                        ...rowsTable,
                        ['botDetails']: {
                            ['count']: payload.data.botDetailsCount[0].count,
                            ['rows']: payload.data.botDetailsServerSide,
                        },
                    }
                })
            }
            else {
                Toast.fire({
                    title: payload.message,
                    type: 'warning'
                })
            }
        })
    }
    //#endregion

    //#region States Globales
    const {

    } = useContext(UserContext);
    //#endregion

    //#region Textos

    const [text, setText] = useState({
        title: "Detalle de cada proceso",
        subtitle: "Estadísticas Databot"
    })
    const { title, subtitle } = text;
    //#endregion 

    //#region States locales
    //State para almacenar los valores a buscar y cuando realice el dispatch de petición el API lo filtre según este state.
    const [valuesToSearch, setValuesToSearch] = useState([])

    //Este state es una bandera para indicar que se está realizando una búsqueda
    const [searchingThisMoment, setSearchingThisMoment] = useState(false);

    //#endregion

    //#region states  y handles para calcular la cantidad de filas por página, y la cantidad de páginas.

    //Esto quiere decir que cada X filas va a hacer otro llamado por otras X filas. 
    const [limitToChange, setLimitToChange] = useState(200)

    //State para definir el tamaño por página
    const [sizePerPage, setSizePerPage] = useState(5)


    //state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);

    //rowsLength= rowsLength<=sizePerPage? rowsLength: rows?.length;

    //Es un state para manejar de manera local el tamaño de la página.
    const [localRowsLength, setLocalRowsLength] = useState(0)

    //State para almacenar en cual rango de filas está actualmente. Ejemplo: Si el limitToChange es 10, el sizePerPage es 5, y la pagina es 2, está en el range 1(porque es el primer lote), pero si es la pagina 3 está en el range 2 (se extrajo otro lote de filas).
    const [currentRange, setCurrentRange] = useState(1);


    const paginations = localRowsLength > sizePerPage ?
        localRowsLength / sizePerPage > Math.round(localRowsLength / sizePerPage) ?
            Math.round(localRowsLength / sizePerPage) + 1
            : Math.round(localRowsLength / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Establece el número de filas por pagina 
    const handleOnSetPage = (page) => {
        // console.log("handleOnSetPage")
        setPage(page);
    };

    //Establece el tamaño de la página.
    const handleSetNumPag = (e) => {
        // console.log("handleSetNumPag")
        setPage(1);
        const value = e.target.value;
        setSizePerPage(value);
    }

    useEffect(() => {
        //    console.log(sizePerPage)
    }, [sizePerPage])


    //#endregion

    //#region Handles del serverside
    //Agrega la info al state que se le envia al api para buscar registros
    const handleOnfilterDynamic = (id, value) => {

        // debugger
    
        setValuesToSearch(valuesToSearch => {
            return {
                ...valuesToSearch,
                [id]: value,
            }
        })

    }

    //Handle para ejecutar la búsqueda de los campos en la DB. 
    const handleOnSearch = () => {

        setSearchingThisMoment(true);
        setPage(1);

    }

    //Determina si values to search está vacío
    const valuesToSearchIsVoid = () => {

        if (valuesToSearch.length > 0) {
            return false;
        }

        let values = Object.values(valuesToSearch);

        let toValidate = values.filter((value, index) => value != "")

        // console.log(toValidate);
        return toValidate.length == 0;
    }

    //Handle para refrescar
    const handleRefresh = () => {

        columnsSelected.map(column => {
            setValuesToSearch(valuesToSearch => {
                return {
                    ...valuesToSearch,
                    [column.value]: '',
                }
            })
        })

        setPage(1)

        //Indicar que ya se buscó
        setSearchingThisMoment(false);



    }

    //Cambia la información de la tabla según la selección del radio
    const onChangeChart = event => {

        let radioItem = event.target;

        let aux = radioButtons.table.map((radioMap, index) => {
            if (radioMap.id != radioItem.id) { return { ...radioMap, state: false } }
            else { return { ...radioMap, state: true } }
        })

        setRadioButtons(radioButtons => { return { ...radioButtons, table: aux } })

        setTableActive(radioItem.id)

    }

    //Valida si se debe realizar otra extracción
    const validateDoRequest = () => {
        // debugger;
        let range = getRangeOfRows();

        let currentRangeDiferent = currentRange != range

        return currentRangeDiferent;
    }

    //Este método es para averiguar cual lote de filas está actualmente y extrae de la DB.
    const getRangeOfRows = () => {
        //Hace un split de por ejemplo 1.6 devuelve solo 1, para el numero entero anterior y no pase al siguiente.

        //La posición 0 es el número, y la 1 es lo que está después del '.' Ejemplo: 2.333333 
        let currentRageOfRows = (page / (limitToChange / sizePerPage)).toString().split('.');

        if (currentRageOfRows[1] != undefined) //No es un número entero como: 1.33333
        {
            currentRageOfRows = parseInt(currentRageOfRows[0]) + 1;
        } else //Es undefined, eso quiere decir que es un número entero como el 2.
        {
            currentRageOfRows = parseInt(currentRageOfRows[0]);

        }


        return currentRageOfRows
    }


    //Función para calcular el primer y segundo término de 
    const getToSplice = () => {
        let first = (page - 1) * sizePerPage - (limitToChange * (getRangeOfRows() - 1))
        let second = page * sizePerPage - (limitToChange * (getRangeOfRows() - 1))

        return [first, second]

    }

    //#endregion

    //#region Efectos claves para la ejecución del serverside

    //Cuando arranque el componente traiga la info server side
    useEffect(() => {
        handleGetBotLogsOrDetailsServerSide((page * sizePerPage) - sizePerPage, limitToChange, [])  //   Limit 5


    }, [])


    //Cuando cambie las filas de la tabla, establezca la cantidad de filas, para restablecer el length cuando sea busqueda o no.
    useEffect(() => {
        // debugger;
        if (rows?.length != undefined) {
            setLocalRowsLength(/*rowsLength*/rowsTable.botDetails?.count)
        }
    }, [rows])

    //Efecto para cuando cambie un radioButton, refresque la tabla
    useEffect(() => {
        handleRefresh();
    }, [radioButtons.table])

    //Cuando hay un cambio de página pero que la info no sea de una busqueda ejecute el server side
    useEffect(() => {
        if (rows?.length != undefined //Si ya llegaron las filas de la consulta

            //&& (rowsLength/sizePerPage)*getRangeOfRows() == page /*En cual página debe hacer una nueva consulta. Ej: Cada 5 páginas */
            && (validateDoRequest())
        ) {
            let currentRangeAux = getRangeOfRows();

            handleGetBotLogsOrDetailsServerSide((currentRangeAux - 1) * limitToChange, limitToChange, searchingThisMoment ? valuesToSearch : [])  //   Limit 5

            if (currentRangeAux != currentRange) {
                setCurrentRange(currentRangeAux);
            }
        }
    }, [page]) //Aplica para el cambio de página

    //Efecto para realizar la petición cuando se utiliza el buscador.
    useEffect(() => {
        // if (rows?.length != undefined) { //Llegaron las filas
        if (rows?.length != undefined && searchingThisMoment && !valuesToSearchIsVoid() //Cuando se el da el botón de buscar
            || rows?.length != undefined && !searchingThisMoment && valuesToSearchIsVoid() //Cuando todos los campos del buscador están vacíos 
        ) { //Llegaron las filas

            setSearchingThisMoment(false)
            handleGetBotLogsOrDetailsServerSide((page * sizePerPage) - sizePerPage, limitToChange, searchingThisMoment ? valuesToSearch : [])  //   Limit 5
        }

    },
        //[searchingThisMoment, valuesToSearch]) //Aplica para cuando se aplican filtros
        [searchingThisMoment, valuesToSearch]) //Aplica para cuando se aplican filtros
    //#endregion








    return (
        <>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col xs="12" sm='auto'>
                            <h6 className="surtitle">{subtitle}</h6>
                            <h5 className="h3 mb-0">{title}</h5>
                        </Col>

                        {<Col xs="12" sm='5' style={{ display: "flex", justifyContent: "flex-start" }}>
                            <div className="d-flex justify-content-center">

                                {
                                    radioButtons.table.map((radioItem, index) => {
                                        return (
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    className="custom-control-input"
                                                    id={radioItem.id}
                                                    key={index}
                                                    type="radio"
                                                    checked={radioItem.state}
                                                    onChange={onChangeChart}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={radioItem.id}
                                                >
                                                    {radioItem.label}
                                                </label>
                                            </div>)
                                    })
                                }
                            </div>

                        </Col>}

                        <Col xs="12" sm='' className="" style={{ display: "flex", justifyContent: "flex-end" }}>

                            <Row>
                                <Col sm="auto">
                                    <Button
                                        className="btn-icon"
                                        color="success"
                                        size="sm"
                                        type="button"
                                        onClick={handleOnSearch}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-search" />
                                        </span>
                                        <span className="btn-inner--text">
                                            {" "}Buscar
                                        </span>
                                    </Button>
                                </Col>
                                <Col sm="auto">
                                    <Button
                                        className="btn-icon ml--4"
                                        color="info"
                                        size="sm"
                                        type="button"
                                        onClick={handleRefresh}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-sync-alt" />
                                        </span>
                                        <span className="btn-inner--text">
                                            {" "}Refrescar
                                        </span>
                                    </Button>
                                </Col>
                            </Row>

                        </Col>



                    </Row>

                </CardHeader>



                <Spin size="large" spinning={spinner}>

                    <Table
                        className="align-items-center  table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >


                        <thead className="thead-light">

                            <tr>
                                {
                                    columnsSelected.map((item, key) => {
                                        return (
                                            <th key={key} className="justify-content-md-center">

                                                <FormGroup key={key}>
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label ml-2"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${item.label}:`}
                                                    </label>
                                                    <Input
                                                        id={item.id}
                                                        key={key}
                                                        className="form-control ml--1"
                                                        type="text"
                                                        value={valuesToSearch[item.value]}
                                                        placeholder={`Buscar ${item.label}...`}
                                                        onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                                                    />
                                                </FormGroup>
                                            </th>
                                        )
                                    })

                                }
                            </tr>

                        </thead>


                        <tbody className='list'>
                            {
                                localRowsLength > 0 ?
                                    (
                                        // rows.slice(   (page - 1) * sizePerPage   - (limitToChange * (getRangeOfRows()-1) ),
                                        //  page * sizePerPage - (limitToChange * (getRangeOfRows()-1))  )
                                        rows.slice(getToSplice()[0],
                                            getToSplice()[1])
                                            .map((row, key) => {
                                                return (
                                                    <tr key={key}>
                                                        {
                                                            columnsSelected.map((col, keyCol) => {
                                                                return (

                                                                    col.type == "textarea" ?

                                                                        <td key={key + keyCol} className="text-center text-break">
                                                                            <div style={{ width: col.width }} >

                                                                                <Input
                                                                                    key={key + keyCol}
                                                                                    id={col.value}
                                                                                    className="form-control text-dark"
                                                                                    readOnly={false}
                                                                                    value={row[col.value]}
                                                                                    type={"textarea"}

                                                                                />
                                                                            </div>

                                                                        </td>
                                                                        :
                                                                        <td key={key + keyCol} className="text-center text-break" style={{ whiteSpace: "normal", textAlign: "auto" }}>
                                                                            <div style={{ width: col.width }} >

                                                                                <span className="btn-inner--text">
                                                                                    {row[col.value]}
                                                                                </span>

                                                                            </div>
                                                                        </td>



                                                                )
                                                            })
                                                        }

                                                    </tr>

                                                )
                                            })

                                    )
                                    :
                                    <>
                                        <tr>
                                            <td className="justify-content-md-center">
                                                Sin resultados encontrados
                                            </td>
                                        </tr>
                                    </>
                            }

                        </tbody>




                    </Table>


                </Spin>

                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Row className="align-items-center">
                            <Col xs='12' md='6' className="p-1">
                                <Row className="justify-content-start">
                                    <Col xs='12' md='6'>
                                        <span className='pagination mb-0'>
                                            Mostrando del {((page - 1) * sizePerPage) + 1}  al {page * sizePerPage > localRowsLength ? localRowsLength : page * sizePerPage} de {localRowsLength} resultados
                                        </span>
                                    </Col>
                                    <Col xs='12' md='2'>
                                        <Input type="select"
                                            onChange={(e) => handleSetNumPag(e)}
                                        >
                                            <option>5</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs='12' md='6' className="p-1">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem
                                        className={page === 1 ? "disabled" : ""}
                                    >
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {
                                        renderPaginations()
                                    }
                                    <PaginationItem
                                        className={page === paginations ? "disabled" : ""}
                                    >
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>


                    </nav>


                </CardFooter>

            </Card >
        </>
    )
})
