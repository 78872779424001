import React, { Component } from 'react';
// reactstrap components
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="py-5" id="footer-main">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="4">
                <Nav className="copyright text-center text-xl-left text-muted">
                  <NavItem>
                    <NavLink
                      target="_blank">
                      © {new Date().getFullYear()}{" "}
                      GBM Digital
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col xl="8">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink
                      href="https://www.gbm.net/"
                      target="_blank"
                    >
                      GBM
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.gbm.net/nosotros/quienes-somos/"
                      target="_blank"
                    >
                      ¿Quiénes Somos?
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.gbm.net/es/oficinas"
                      target="_blank"
                    >
                      Contactos
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Footer;