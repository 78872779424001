//Actions
import { deleteEntity } from 'actions/panamaBids.jsx';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Input, Pagination, PaginationItem, PaginationLink, Row, Table, FormGroup } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

const EntitiesMainTable = ({
    rows,
    cols,
    handleOnLoad,
    Toast,
    setRealTable,
    page,
    setPage,
    filters,
    isAdmin
}) => {

    const dispatch = useDispatch();
    const [alert, setAlert] = useState("");
    const deleEntity = async (e) => {
        const id = e.id;
        dispatch(deleteEntity(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRealTable(3);
                Toast.fire({
                    title: "Atención",
                    html: "Se elimino correctamente",
                    type: 'success'
                });
            } else {
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrio un error, no se pudo eliminar",
                    type: 'danger'
                });
            }
            setAlert("");
        })
    }
    const handleOnEditDeleteEntity = (e, row) => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Está seguro(a) que desea eliminar la entidad?"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => deleEntity(e, row)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }
    const [state, setState] = useState({
        sizePerPage: 5,
    });
    const { sizePerPage } = state;
    //#region Pagination (no sirve)
    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1
    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }
    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);
    };
    //funcion para cambiar las filas por las paginas
    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            sizePerPage: value
        }))
    }
    return (
        <>
            {alert}
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm="12" md="10">
                            <h3 className="mb-0" >Tabla de Entidades</h3>
                            <p className="text-sm mb-0">Filtros para contactos sustitutos</p>
                        </Col>
                    </Row>

                </CardHeader>
                <CardBody>
                    <Table
                        className="align-items-center table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">
                            <tr>
                                {cols.map((item, key) => {
                                    return (
                                        <th key={key} className="justify-content-md-center">
                                            <FormGroup key={key}>
                                                <label
                                                    style={{ fontSize: "12px" }}
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                >
                                                    {`${item.nameInTable}:`}
                                                </label>
                                                <Input
                                                    id={item.id}
                                                    key={key}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={`Buscar ${item.nameInTable}...`}
                                                    onChange={(e) => filters(item.id, e.target.value)}
                                                />
                                            </FormGroup>
                                        </th>
                                    );
                                })}
                                {
                                    isAdmin === true && (
                                        <>
                                            <th>
                                                Editar
                                            </th>
                                            <th>
                                                Eliminar
                                            </th>
                                        </>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody className="list">
                            {
                                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                                    <tr key={key} className="text-center">
                                        {
                                            cols.map((col, key) => (
                                                <td key={key} className="text-center">
                                                    {
                                                        row[col.id]
                                                    }
                                                </td>
                                            ))
                                        }
                                        {
                                            isAdmin === true && (
                                                <>
                                                    <td>
                                                        <Button
                                                            className="btn"
                                                            color="secondary"
                                                            id="edit"
                                                            onClick={() => handleOnLoad(row)}
                                                        >
                                                            <span className="btn-inner--icon mr-1">
                                                                <i className="far fa-edit"></i>
                                                            </span>
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            id="Delete"
                                                            className="btn"
                                                            color="danger"
                                                            onClick={() => handleOnEditDeleteEntity(row)}
                                                        >
                                                            <span className="btn-inner--icon mr-1">
                                                                <i className="far fa-trash-alt"></i>
                                                            </span>
                                                        </Button>
                                                    </td>
                                                </>
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </CardBody>
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Row className="align-items-center">
                            <Col xs='12' md='6' className="p-1">
                                <Row className="justify-content-start">
                                    <Col xs='12' md='6'>
                                        <span className='pagination mb-0'>
                                            Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                        </span>
                                    </Col>
                                    <Col xs='12' md='2'>
                                        <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                                            <option>5</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs='12' md='6' className="p-1">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {
                                        renderPaginations()
                                    }
                                    <PaginationItem className={page === paginations ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </nav>
                </CardFooter>
            </Card>
        </>
    );
};


export default EntitiesMainTable;