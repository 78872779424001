import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const usersAccess = handleActions(
  {
    [constants.GET_USERS_WITH_ACCESS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

/**
 * Captura de la accion realizada para obtener las posiciones
 */
export const unplanned = handleActions(
  {
    [constants.GET_POSITIONS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

/**
 * Captura de la accion realizada para traer la informacion relacionada a una posicion en especifico
 */
export const dataUnplanned = handleActions(
  {
    [constants.GET_DATA_BY_POSITIONS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.CLEAR_DATA]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

/**
 * Captura de la accion realizada para traer la informacion de una posicion a modificar
 */
export const personal = handleActions(
  {
    [constants.GET_DATA_UPDATE_BY_POSITION]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.CLEAR_DATA]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

/**
 * Captura la accion realizada para traer toda la data asociada a una posicion
 */
export const relationByPosition = handleActions(
  {
    [constants.GET_RELATION_DATA_BY_POSITION]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.CLEAR_DATA]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

/**
 * Creacion  de una posicion no planificada
 */
export const unplannedPosition = handleActions(
  {
    [constants.CREATE_UNPLANNED_POSITION]: (state, action) => {
      // const { status, data } = action.payload;
      // console.log(state);
      // if (status === 200) {
      //   console.log(data);
      //   return [...state, ...data.payload.unplanned];
      // }
      return state;
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

/**
 * Modificacion de una posicion con personal
 */
export const staffPosition = handleActions(
  {
    [constants.CREATE_STAFF_POSITION]: (state, action) => {
      // const { status, data } = action.payload;
      // console.log(state);
      // if (status === 200) {
      // console.log(data);
      // return [...state, ...data.payload.unplanned];
      // }
      return state;
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

/**
 * Modificacion de una posicion vacante
 */
export const vacantPosition = handleActions(
  {
    [constants.GET_UNPPROVED_VACANT_POSITION]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.unpprovedVacants;
      }
      return state;
    },
    [constants.CREATE_VACANT_POSITION]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        // console.log(data.payload.vacant);
        return [...state, ...data.payload.vacant];
      }
      return state;
    },
    [constants.APPROVED_VACANT_POSITION]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { id } = data.payload;
        const newVacants = state.filter((el) => el.id !== id);
        return [...newVacants];
      }
      return state;
    },
    [constants.UNAPPROVED_VACANT_POSITION]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { id } = data.payload;
        const newVacants = state.filter((el) => el.id !== id);
        return [...newVacants];
      }
      return state;
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

/**
 * Captura de la accion relacionadas a las relaciones de las posiciones
 */
export const relationPosition = handleActions(
  {
    [constants.CREATE_RELATIONS_BY_POSITION]: (state, action) => {
      // const { status, data } = action.payload;
      // console.log(state);
      // if (status === 200) {
      //   console.log(data);
      //   // return [...state, ...data.payload.unplanned];
      // }
      return state;
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

/**
 * Captura de la accion para el total de posiciones creadas
 */
export const historyTotalPositions = handleActions(
  {
    [constants.GET_TOTAL_POSITIONS_CREATED]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

/**
 * Captura de la accion para el ranking de solicitudes por usuarios
 */
export const allRequestsByUsers = handleActions(
  {
    [constants.GET_ALL_REQUESTS_BY_USERS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.total;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

/**
 * Captura de la accion para las solicitudes del usuario
 */
export const requestsByUsers = handleActions(
  {
    [constants.GET_REQUESTS_BY_USER]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.requests;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

/**
 * Captura de la accion que trae la informacion de una posicion vacante en especifico
 */
export const vacantById = handleActions(
  {
    [constants.GET_VACANT_POSITION_BY_ID]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.CLEAR_DATA]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const allMasterDataPositions = handleActions(
  {
    [constants.GET_ALL_MASTER_DATA_POSITIONS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return [];
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);
