// javascript plugin that creates nice dropzones for files
import { Spin } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Modal,
} from "reactstrap";

const WorkFlowModal = (props) => {
  const { user, showModal, toggleModal, loading, onWorkFlow } = props;

  const validate = (values) => {
    const errors = {};
    if (!values.action || values.action === "0")
      errors.action = "Debes seleccionar la acción de Aprobar o Rechazar.";
    if (!values.comments)
      errors.comments =
        "Debes ingresar un comentario de justificación para la acción.";
    if (user) {
      if (!values.termsAndConditions || values.termsAndConditions === false)
        errors.termsAndConditions = "Debes aceptar los términos y condiciones.";
    }
    return errors;
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={showModal}
        toggle={() => toggleModal("showModal")}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-3">
                <h4>Flujo de la Carta de Objetivos</h4>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form
                onSubmit={onWorkFlow}
                validate={validate}
                render={({
                  handleSubmit,
                  values,
                  submitting,
                  validating,
                  valid,
                }) => (
                  <Spin size="large" spinning={loading}>
                    <FormRS role="form">
                      <FormGroup>
                        <Label for="action">Acción</Label>
                        <Field name="action">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="select"
                                // placeholder="Nombre de la posición"
                                valid={
                                  !meta.error && meta.modified && meta.touched
                                }
                                invalid={meta.error && meta.touched}
                              >
                                <option key="0" value="0">
                                  Seleccione una opción
                                </option>
                                <option key="1" value="1">
                                  Aprobar
                                </option>
                                <option key="2" value="2">
                                  Rechazar
                                </option>
                              </Input>
                              {meta.error && meta.touched && (
                                <span className="invalid-feedback">
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Label for="comments">Comentarios (500)</Label>
                        <Field name="comments">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="textarea"
                                placeholder="Escriba aquí si tiene algún comentario."
                                maxLength={500}
                                rows="3"
                                resize="none"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <span className="invalid-feedback">
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      {user && (
                        <FormGroup check className="text-center">
                          <Field name="termsAndConditions" type="checkbox">
                            {({ input, meta }) => (
                              <>
                                <Label>
                                  <Input
                                    {...input}
                                    className="red"
                                    type="checkbox"
                                    invalid={meta.error && meta.touched}
                                  />{" "}
                                  Acepto los términos y condiciones.
                                </Label>
                                <div style={{ marginBottom: "1rem" }}>
                                  {meta.error && meta.touched && (
                                    <span
                                      style={{
                                        fontSize: "80%",
                                        color: "#fb6340",
                                      }}
                                    >
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      )}
                      <div className="text-center">
                        <Button
                          disabled={validating || submitting}
                          className="my-4"
                          color="info"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Continuar
                        </Button>
                      </div>
                    </FormRS>
                  </Spin>
                )}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

WorkFlowModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default WorkFlowModal;
