
// ** React Imports
import React, { useState, useEffect } from 'react'

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// ** Table columns & Expandable Data
import { Edit, Package } from 'react-feather'
// ** Third Party Components
import ReactPaginate from 'react-paginate'
import { ChevronDown } from 'react-feather'
import DataTable from 'react-data-table-component'
import { Card, CardHeader, CardTitle, Col, Row, Input, Label } from 'reactstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { getCustomerInfo } from 'actions/contacts.jsx'
import { useDispatch } from 'react-redux'

const CustomerLog = ({ data }) => {
  // ** State
  const [currentPage, setCurrentPage] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(7)
  const [totalPages, setTotalPages] = useState(10)
  const [alert, setAlert] = useState([])

  const dispatch = useDispatch()

  const getInfo = async (id, confirmados) => {
    dispatch(
      getCustomerInfo({
        customerId: id,
        confirmed: confirmados
      })
    ).then(response => {
      const { payload } = response
      if (payload.status === 200) {
        let cnt = payload.data.payload.data.quantity

        // handelOnToggleModalSuccess(`Cantidad de contactos: ${cnt !== 0 ? cnt : 0}, Porcentage de actualizacion: ${cnt !== 0 ? payload.data.payload.data.percent : 0}%`);

        Toast.fire({
          title: `Cantidad de contactos: ${cnt !== 0 ? cnt : 0}, Porcentage de actualizacion: ${cnt !== 0 ? payload.data.payload.data.percent : 0}%`,
          type: 'success'
        })
      }
    })
  }

  // const handelOnToggleModalSuccess = (text) => {
  //   setAlert({
  //     alert: (
  //       <ReactBSAlert
  //         success
  //         style={{ display: "block", marginTop: "-100px" }}
  //         title={`${text}`}
  //         onConfirm={() => hideAlert()}
  //         confirmBtnBsStyle="success"
  //         confirmBtnText="Ok"
  //         btnSize="md"
  //       />
  //     )
  //   });
  // }
  // const hideAlert = () => {
  //   setAlert({
  //     alert: null
  //   });
  // };

  const handlePerPage = e => {
    setRowsPerPage(parseInt(e.target.value))
  }

  const handleFilter = e => {
    const value = e.target.value
    let updatedData = []
    setSearchValue(value)

    if (value.length) {
      updatedData = data.filter(item => {
        const startsWith =
          item.name.toLowerCase().startsWith(value.toLowerCase()) ||
          item.customerId.toLowerCase().startsWith(value.toLowerCase()) ||
          item.user.toLowerCase().startsWith(value.toLowerCase()) ||
          item.manager.toLowerCase().startsWith(value.toLowerCase()) ||
          item.territory.toLowerCase().startsWith(value.toLowerCase()) ||
          item.country.toLowerCase().startsWith(value.toLowerCase())

        const includes =
          item.name.toLowerCase().startsWith(value.toLowerCase()) ||
          item.customerId.toLowerCase().startsWith(value.toLowerCase()) ||
          item.user.toLowerCase().startsWith(value.toLowerCase()) ||
          item.manager.toLowerCase().startsWith(value.toLowerCase()) ||
          item.territory.toLowerCase().startsWith(value.toLowerCase()) ||
          item.country.toLowerCase().startsWith(value.toLowerCase())

        if (startsWith) {
          return startsWith
        } else if (!startsWith && includes) {
          return includes
        } else return null
      })
      setFilteredData(updatedData)
      setSearchValue(value)
    }
  }

  // ** Function to handle filter
  const handlePagination = page => {
    setCurrentPage(page.selected)
  }

  const MySwal = withReactContent(Swal)

  const Toast = MySwal.mixin({
    toast: false,
    showConfirmButton: true,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const renderData = () => {
    let dret = null
    if (searchValue.length) {
      dret = filteredData
    } else {
      dret = data
    }
    return dret //.slice(0, rowsPerPage)
  }

  const handleCustomer = async (id, confirmed) => {
    await getInfo(id, confirmed)
    //console.log(`El Id: ${id}`)
  }

  const columnsManager = [
    {
      name: 'Cliente',
      selector: row => row.name,
      sortable: true,
      minWidth: '600px',
      cell: row => (
        <div className='d-flex align-items-center'>
          <Package size={30} />
          <div className='user-info text-truncate ml-1'>
            <span className='d-block font-weight-bold text-truncate'>
              {row.name}
            </span>
            <small>{row.territory}</small>
          </div>
        </div>
      )
    },
    {
      name: 'Id Cliente SAP',
      selector: row => row.customerId,
      sortable: true,
      minWidth: '100px'
    },
    {
      name: 'Responsable',
      selector: row => row.user,
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Manager',
      selector: row => row.manager,
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'País',
      selector: row => row.country,
      sortable: true,
      minWidth: '100px'
    },
    {
      name: 'Confirmados',
      selector: row => row.confirmed,
      sortable: true,
      minWidth: '100px'
    },
    {
      name: 'Detalles',
      allowOverflow: true,
      selector: row => row.action,
      sortable: true,
      minWidth: '100px',
      cell: row => {
        return (
          <div className='d-flex'>
            <Edit
              size={15}
              onClick={() => handleCustomer(row.customerId, row.confirmed)}
            />
          </div>
        )
      }
    }
  ]

  // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
      previousLabel={''}
      nextLabel={''}
      forcePage={currentPage}
      onPageChange={page => handlePagination(page)}
      pageCount={renderData().length / 10}
      breakLabel={'...'}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      activeClassName={'active'}
      pageClassName={'page-item'}
      nextLinkClassName={'page-link'}
      nextClassName={'page-item next'}
      previousClassName={'page-item prev'}
      previousLinkClassName={'page-link'}
      pageLinkClassName={'page-link'}
      breakClassName='page-item'
      breakLinkClassName='page-link'
      containerClassName={
        'pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1'
      }
    />
  )

  return (
    <div>

      {alert}

      < Card >
        <CardHeader>
          <CardTitle tag='h4'>Listado de clientes</CardTitle>
        </CardHeader>
        <Row>
          <Col md='3'></Col>
          <Col md='5'></Col>
          <Col
            className='d-flex align-items-center justify-content-end mt-1'
            md='4'
          >
            <Label className='mr-1' for='search-input'>
              Búsqueda:
            </Label>
            <Input
              className='dataTable-filter mb-50 form-control'
              type='text'
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </Col>
        </Row>
        <DataTable
          noHeader
          pagination
          //paginationPerPage={rowsPerPage}
          //data={searchValue.length ? filteredData : data}
          data={renderData()}
          columns={columnsManager}
          expandOnRowClicked
          className='react-dataTable'
          sortIcon={<ChevronDown size={10} />}
          paginationDefaultPage={currentPage + 1}
          paginationRowsPerPageOptions={[7, 25, 50, 75, 100, 500, 1000, 5000]}
          paginationComponent={CustomPagination}
        />
      </Card >
    </div>

  )
}

export default CustomerLog

