/*!

=========================================================
*MasterData - CardTemplate
=========================================================

*Componente para descargar y subir nuevas plantillas de excel para el uso de "másivo" en el envío de solicitudes
*Este componente es utilizado en Templates.jsx de Maintenance Window
=========================================================

* Coded by Diego Meza Castro - Application Management GBM

*/

// react library
import React, { useState } from "react";

import { Upload, Icon, Spin } from "antd";
// reactstrap components
import { Card, CardBody, Col, Row, Container, Button } from "reactstrap";
//Urls
import urls from "api/urls.jsx";
//Import dispatch consulta al API.
import { useDispatch } from 'react-redux';
// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { deleteTemplate, saveTemplate } from "actions/masterData";
import { encrypter } from 'helpers/desencrypt.jsx';

export const CardTemplate = React.memo(
  ({
    title,
    version,
    image,
    templateName,
    motherTable, //es el id
    setFormFields,
    Toast,
    setReloadTemplates
  }) => {

    const MySwal = withReactContent(Swal);

    //Función para extraer solicitudes del API.
    const dispatch = useDispatch();

    //spinning value
    const [loading, setLoading] = useState(false);

    //#region dragger

    //inicializacion del dragger
    const { Dragger } = Upload;

    //estado para guardar que archivo se ha subido con el dragger
    const [state, setState] = useState({
      fileList: [],
      readyToSend: false
    });

    const { fileList, readyToSend } = state;
    //función para el dragger subir o eliminar un archivo subido
    const handleOnFileList = (info) => {

      const { status } = info.file;
      //console.log(status);
      if (status === "removed") {
        const { file: { name, uid, response: { status, payload } } } = info;

        setState((prevState) => ({
          ...prevState,
          fileList: fileList.filter((row) => row.uid !== uid),
          readyToSend: false,
        }));

        dispatch(deleteTemplate({ name: name })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            if (fileList.length === 1) {
              setState((prevState) => ({
                ...prevState,
                readyToSend: false,
              }));
            }
          } else {
            console.log("error al intentar borrar el archivo")
          }
        })
        console.log(`El archivo ${name} fue quitado exitosamente.`);
      } else {
        const { fileList, file: { status, name } } = info;
        let response = "";
        if (status === 'done') {
          const { file } = info;
          response = file["response"];
          //habilitar el botón de enviar
          setState((prevState) => ({
            ...prevState,
            readyToSend: true,
          }));
        } else if (status === 'error') {
          console.log(`Ocurrio un error cargando el archivo ${name}.`);
        }

        setState((prevState) => ({
          ...prevState,
          fileList: fileList
        }));
      }
    }

    const allowedFileTypes = [
      ".xlsx", ".xls"
    ];
    //config del dragger donde viene la accion/api que sube el archivo al server
    const uploadProps = {
      name: "file",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      accept: allowedFileTypes.join(","),
      multiple: false,
      action: `${urls.MASTERDATA.uploadTemplate}`,
      beforeUpload: file => {
        const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
        if (!allowedFileTypes.includes(fileType)) {
          console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
          return false;
        }
        return true;
      },
      onChange: info => {
        console.log(info)
        // Filtrar la lista de archivos válidos para mantener solo los que aún existen
        const filteredFiles = info.fileList.filter(file => {
          console.log(file)
          if (file.status) {
            return true;
          }
          return false;
        });
        info.fileList = filteredFiles;
        // Actualizar la lista de archivos válidos
        // Llamar a la función handleOnFileList con la lista de archivos válidos
        handleOnFileList(info);
      },
    };

    //#endregion

    //#region funcion para descargar template
    const handleDownloadTemplate = (e) => {
      window.location.assign(
        `${urls.MASTERDATA.downloadTemplates}/${encrypter(templateName)}`
      );
    };
    //#endregion

    //#region funcion de guardar template
    const handleSave = (e) => {
      MySwal.fire({

        type: 'success',
        title: `Actualizar Plantilla`,
        html:

          `<h2>¿Está seguro que desea actualizar la plantilla de ${title}?</h2>`,

        confirmButtonText: 'Si, actualizar',
        confirmButtonColor: '#2DCE89',
        cancelButtonText: 'No, cancelar',
        showCancelButton: true,

        preConfirm: () => {

          setLoading(true)
          let info = {
            fileList,
            motherTable, //ID DE LA TABLA
            version,
            oldTemplate: templateName
          }
          dispatch(saveTemplate({ info })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
              if (fileList.length === 1) {

                setState((prevState) => ({
                  ...prevState,
                  fileList: [],
                  readyToSend: false,
                }));

                //actualizar la version y la plantilla a descargar del card 
                setReloadTemplates(prev => !prev);

                Toast.fire({
                  title: "Atención",
                  html: "Plantilla Actualizada.",
                  type: "success",
                });

              }
            } else {
              console.log(resp);
              Toast.fire({
                title: "Atención",
                html: payload.response.data.message,
                type: "danger",
              });
            }
            setLoading(false);
          })


        },

      })


    }
    //#endregion

    return (
      <>
        <Row>
          <Col>
            <Card
              className="card-stats card-lift--hover"
              style={{
                backgroundColor: "#ffffff",
                // cursor: "pointer",
              }}
            >
              <Spin spinning={loading}>
                <CardBody>
                  <Row>
                    <img
                      alt="..."
                      className="rounded img-center img-fluid shadow shadow-lg--hover"
                      src={require(`assets/img/masterdata/${image}`)}
                      style={{ objectFit: "contain", width: "60px", height: "60px" }}
                    />
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Col>
                      <div className="pt-1 text-center centered-div">
                        <h4 className="h3 title">
                          <span className="d-block mb-1">{title}</span>
                          <small className="h5 font-weight-light text-muted">
                            {version}
                          </small>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3 ml-3 mr-3">

                    <Button
                      block
                      color="info"
                      onClick={(e) => handleDownloadTemplate(e)}
                    >
                      <span className="btn-inner--icon mr-2">
                        <i className="fas fa-download mr-2"></i>
                      </span>
                      Plantilla de {title}
                    </Button>

                  </Row>

                  <Row className="mb-5">
                    <Container>
                      <Dragger {...uploadProps} fileList={fileList}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-uploading-text">
                          Suelte el archivo aquí o haga clic para cargar.
                        </p>
                        <p className="ant-uploading-hint">
                          Por favor selecciona únicamente la nueva plantilla de Datos Maestros
                        </p>
                      </Dragger>
                    </Container>

                  </Row>

                  <Row className="mt-5 ml-4 mr-4">

                    <Button
                      block
                      color="success"
                      disabled={!readyToSend}
                      onClick={(e) => handleSave(e)}
                    >
                      Guardar
                    </Button>

                  </Row>

                </CardBody>
              </Spin>
            </Card>
          </Col>
        </Row >
      </>
    );
  }
);
