import PersonCard from "components/MIS/PersonCard.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// reactstrap components
import { Col, Container, Row } from "reactstrap";

/**
 * Clase para visualizar los componentes que
 * se utilizaran en el modulo de Planning MRS
 * @class Nodes
 * @extends {Component}
 */
class Main extends Component {
  render() {
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="¿Quines Somos?" parentName="GBM Digital" />
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col xs="12" md="12">
              <PersonCard
                name="Maria Gabriela Royo"
                subject="El equipo de GBM Digital es la unidad de servicio encargada de la plataforma tecnológica de uso interno. Estamos comprometidos con nuestros clientes, buscando la mejora continua, por medio de una atención cordial y oportuna, que permita a nuestros usuarios tener una excelente experiencia de servicio."
                email="groyo@gbm.net"
                img={require("assets/img/theme/team-gaby.jpg")}
                isLeader
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="12" md="6">
              <PersonCard
                name="Karen Padilla"
                subject="La unidad de Application Management es responsable del ciclo de vida de los sistemas de información de uso interno, a través del diseño de soluciones innovadoras que incorporan distintas perspectivas de la organización a nivel regional, buscando siempre nuevas ideas que apoyen a la organización en su proceso de mejora continua."
                email="kpadilla@gbm.net"
                img={require("assets/img/theme/team-karen.JPG")}
              />
            </Col>
            <Col xs="12" md="6">
              <PersonCard
                name="Jose Enrique Araya"
                subject="La unidad de Data Management and Automation es la encargada de la gestión de los datos maestros para los sistemas de uso interno, así como la automatización de tareas asociadas a los procesos del negocio, a través de la robótica (Robotic Process Automation), con el objetivo de lograr eficiencias en tiempo y especializar el talento en áreas de mayor análisis y productividad."
                email="jearaya@gbm.net"
                img={require("assets/img/theme/team-jose-enrique.jpg")}
                background="paleturquoise"
              />
            </Col>
          </Row>
          <Row className="card-wrapper">
            <Col xs="12" md="6">
              <PersonCard
                name="Dennis Villalobos"
                subject="La unidad de Internal Infrastructure es responsable de soportar e implementar   soluciones colaborativas y herramientas que apoyan a la compañía para realizar sus funciones de una manera segura y ágil."
                email="dvillalobos@gbm.net"
                img={require("assets/img/theme/team-dennis.jpeg")}
                background="paleturquoise"
              />
            </Col>
            <Col xs="12" md="6">
              <PersonCard
                name="Karen Padilla"
                subject="La unidad de Business Intelligence es el responsable de proveer la información para la toma de decisiones, por medio de la optimización de los procesos de transformación de datos primarios, con el objetivo de apoyar las necesidades de información de las áreas gerenciales, operativas, y estrategia en general de la compañía."
                email="kpadilla@gbm.net"
                img={require("assets/img/theme/team-karen.JPG")}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Main;
