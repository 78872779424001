/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// core antd
import { Drawer, Icon, Upload, Spin, Divider } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useState, useEffect } from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

moment.locale("es");

const { Dragger } = Upload;

const DebbugedModels = (props) => {
  const {
    title,
    debuggedModels,
    length,
    page,
    sizePerPage,
    paginations,
    initialValues,
    visible,
    uploading,
    fileList,
    fileLength,
    onSetPage,
    onClose,
    onDelete,
    onCreate,
    onFileList,
    onSave,
  } = props;

  const [view, setView] = useState(false);
  const [init, setInit] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    // Actualiza la variable end segun la cantidad de filas del archivo
    if (!fileList.length || end === 0)
      setEnd(fileLength > 50 ? init + 50 : fileLength);
    delete initialValues["model"];
  }, [fileList.length, end, fileLength, init, initialValues]);

  const validate = (values) => {
    const errors = {};
    if (!values.model) errors.model = "El campo es obligatorio.";
    return errors;
  };

  const validate2 = (values) => {
    const errors = {};
    if (!values.init && values.init !== 0)
      errors.init = "El campo es obligatorio.";
    if (!values.end && values.end !== 0)
      errors.end = "El campo es obligatorio.";
    if (values.end < values.init) {
      errors.init = "La fila de inicio no puede ser mayor a la fila de fin.";
      errors.end = "La fila de fin no puede ser menor a la fila de inicio.";
    }
    if (values.end - values.init > 50) {
      errors.init = "El rango de las filas no puede se mayor a 50.";
      errors.end = "El rango de las filas no puede se mayor a 50.";
    }
    if (values.init < 0)
      errors.init = "La fila de inicio no puede ser negativa.";
    if (values.end < 0) errors.end = "La fila de fin no puede ser negativa.";
    if (values.init === values.end) {
      errors.init = "El rango de filas no puede ser el mismo.";
      errors.end = "El rango de filas no puede ser el mismo.";
    }
    return errors;
  };

  const onChangeValues = (event) => {
    const { name, value } = event.target;
    if (name === "init") {
      if (parseInt(value) < fileLength) setInit(parseInt(value));
    } else if (name === "end") {
      if (parseInt(value) <= fileLength) setEnd(parseInt(value));
    }
  };

  const onBtnEvent = (action) => {
    if (action === "back") {
      if (init >= 50) {
        setInit(init - 50);
        setEnd(end - 50);
      } else {
        setInit(0);
        setEnd(end - 50);
      }
    } else if (action === "next") {
      if (fileLength - end > 50) {
        setInit(init + 50);
        setEnd(end + 50);
      } else {
        setInit(init + 50);
        setEnd(fileLength);
      }
    }
  };

  const uploadProps = {
    name: "file",
    accept: ".xlsx",
    multiple: false,
    // action: 'https://ss-api.gbm.net/auth/test',
    action: "https://smartsimple.gbm.net:43888/auth/test",
    onChange: onFileList,
  };

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink onClick={() => onSetPage(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return options.length >= 5
      ? page >= 5
        ? options.slice(page - 3, page + 2)
        : options.slice(0, 5)
      : options;
  };

  return (
    <>
      <Drawer
        title="Gestión de los Modelos Depurados"
        width={520}
        closable
        onClose={() => onClose("debuggedModels")}
        visible={visible}
      >
        <Spin size="large" spinning={uploading}>
          <Card className="bg-default shadow">
            <CardHeader className="bg-transparent border-0">
              <Row>
                <Col xs="12" md="8">
                  <h3 className="text-white mb-0">{title}</h3>
                </Col>
                <Col className="text-right" xs="12" md="4">
                  <Button
                    className="btn-icon btn-3"
                    color="info"
                    onClick={() => setView(true)}
                    size="sm"
                    block
                    outline
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-folder-plus" />
                    </span>
                    <span className="btn-inner--text">Nuevo</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table
              className="align-items-center table-dark table-flush"
              responsive
            >
              <thead className="thead-dark">
                <tr>
                  <th>Modelo</th>
                  <th>Creado</th>
                  <th>Creación</th>
                  <th />
                </tr>
              </thead>
              <tbody className="list">
                {debuggedModels.length ? (
                  debuggedModels.map((row, key) => {
                    return (
                      <tr key={key}>
                        <td
                          style={{ whiteSpace: "normal", textAlign: "justify" }}
                        >
                          {row.name}
                        </td>
                        <td
                          style={{ whiteSpace: "normal", textAlign: "justify" }}
                        >
                          {row.createdBy}
                        </td>
                        <td
                          style={{ whiteSpace: "normal", textAlign: "justify" }}
                        >
                          {moment(row.createdAt)
                            .utc()
                            .utcOffset(moment().utcOffset())
                            .format("L")}
                        </td>
                        <td className="table-actions">
                          <a
                            className="table-action table-action-delete"
                            onClick={() => onDelete(row)}
                            // href="#"
                            id="reject"
                          >
                            <i className="fas fa-trash" />
                          </a>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="justify-content-md-center">
                      Sin resultados encontrados
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {length > 0 ? (
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Row className="align-items-center">
                    <Col
                      xs="12"
                      className="p-1"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <span className="pagination mb-0">
                        Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                        {page * sizePerPage} de {length} resultados
                      </span>
                    </Col>
                    <Col
                      xs="12"
                      className="p-1"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem
                          className={page === 1 ? "disabled" : ""}
                        >
                          <PaginationLink
                            onClick={() =>
                              onSetPage(page === 1 ? page : page - 1)
                            }
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {renderPaginations()}
                        <PaginationItem
                          className={page === paginations ? "disabled" : ""}
                        >
                          <PaginationLink
                            onClick={() =>
                              onSetPage(page === paginations ? page : page + 1)
                            }
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </Col>
                  </Row>
                </nav>
              </CardFooter>
            ) : null}
          </Card>
          <Drawer
            title="Nuevo Modelo Depurado"
            width={360}
            closable
            onClose={() => setView(false)}
            visible={view}
          >
            <Spin
              size="large"
              spinning={uploading}
              tip="Creando los modelos depurados"
            >
              {/* <Divider>Crear Modelo</Divider> */}
              <Form
                onSubmit={onCreate}
                validate={validate}
                initialValues={initialValues}
                render={({
                  handleSubmit,
                  values,
                  submitting,
                  validating,
                  valid,
                }) => (
                  <FormRS role="form">
                    <div className="form-row">
                      <Col className="mb-1" sm="12">
                        <FormGroup>
                          <Label for="model">Modelo Depurado</Label>
                          <Field name="model">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  className="form-control-alternative"
                                  type="text"
                                  placeholder="Escriba aquí el modelo depurado."
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-1" sm="12">
                        <Button
                          disabled={submitting}
                          className="btn-icon"
                          block
                          color="success"
                          type="submit"
                          onClick={handleSubmit || validating}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-save" />
                          </span>
                          <span className="btn-inner--text">Crear Modelo</span>
                        </Button>
                      </Col>
                    </div>
                  </FormRS>
                )}
              />
              <Divider>Creación Multiple</Divider>
              <blockquote className="blockquote text-center">
                <p className="mb-0">Creación de varios modelos depurados.</p>
                <footer className="blockquote-footer">
                  <cite title="Source Title">Max 50 modelos a la vez</cite>
                </footer>
              </blockquote>
              <div className="form-row align-items-center">
                <Col className="m-1" sm="12">
                  <Dragger {...uploadProps} fileList={fileList}>
                    <p className="ant-uploading-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-uploading-text">
                      Suelte el archivo aquí o haga clic para cargar.
                    </p>
                    <p className="ant-uploading-hint">
                      *Se debe cargar el machote oficial de modelos depurados.
                    </p>
                  </Dragger>
                </Col>
              </div>
              {fileLength > 0 ? (
                <>
                  <DropdownItem divider />
                  <blockquote className="blockquote text-center">
                    <p className="mb-0">Numero de Filas {fileLength}</p>
                    <footer className="blockquote-footer">
                      Ingrese los rangos de filas a cargar
                    </footer>
                  </blockquote>
                  <Form
                    onSubmit={onSave}
                    validate={validate2}
                    initialValues={{
                      init,
                      end,
                    }}
                    render={({
                      handleSubmit,
                      values,
                      submitting,
                      validating,
                      valid,
                    }) => (
                      <FormRS role="form">
                        <div className="form-row align-items-center">
                          <Col className="mb-1" sm="12" md="6">
                            <FormGroup>
                              <Label for="init">Fila de Inicio</Label>
                              <Field name="init">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="number"
                                      className="form-control form-control-sm"
                                      onChange={(e) => onChangeValues(e)}
                                      valid={!meta.error ? true : false}
                                      invalid={meta.error ? true : false}
                                    />
                                    {meta.error && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="mb-1" sm="12" md="6">
                            <FormGroup>
                              <Label for="end">Fila de Fin</Label>
                              <Field name="end">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="number"
                                      className="form-control form-control-sm"
                                      onChange={(e) => onChangeValues(e)}
                                      valid={!meta.error ? true : false}
                                      invalid={meta.error ? true : false}
                                    />
                                    {meta.error && (
                                      <span className="invalid-feedback">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </div>
                        <div className="form-row align-items-center justify-content-center">
                          <Col
                            className="m-3"
                            sm="12"
                            md="4"
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              alignContent: "center",
                            }}
                          >
                            <Button
                              disabled={init === 0}
                              className="btn-icon"
                              block
                              size="sm"
                              color="info"
                              type="button"
                              onClick={() => onBtnEvent("back")}
                              outline
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-angle-left" />
                              </span>
                              <span className="btn-inner--text">-50</span>
                            </Button>
                          </Col>
                          <Col
                            className="m-3"
                            sm="12"
                            md="4"
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              alignContent: "center",
                            }}
                          >
                            <Button
                              disabled={end === fileLength}
                              className="btn-icon"
                              block
                              size="sm"
                              color="info"
                              type="button"
                              onClick={() => onBtnEvent("next")}
                              outline
                            >
                              <span className="btn-inner--text">+50</span>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-angle-right" />
                              </span>
                            </Button>
                          </Col>
                        </div>
                        <div className="form-row align-items-center">
                          <Col className="m-1" sm="12">
                            <Button
                              disabled={
                                uploading || !fileList.length || submitting
                              }
                              className="btn-icon"
                              block
                              outline
                              color="default"
                              type="submit"
                              onClick={handleSubmit || validating}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-file-excel" />
                              </span>
                              <span className="btn-inner--text">
                                Cargar Modelos
                              </span>
                            </Button>
                          </Col>
                        </div>
                      </FormRS>
                    )}
                  />
                </>
              ) : null}
            </Spin>
          </Drawer>
        </Spin>
      </Drawer>
    </>
  );
};

DebbugedModels.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default DebbugedModels;
