/*!

=========================================================
*Autopp - CardSelectForm
=========================================================

* En este componente se selecciona el formulario deseado y lo almacena en el state formSelected, el cual 
el componente MainTableAutopp partiendo del mismo, despliega las distintas vistas.

*Tiene el botón de envio de información, y el boton de cambio de formulario de a la vista de Requests y viceversa.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React, { useContext, useState, useEffect } from 'react'

//Componentes de formulario
import { Button, Card, CardHeader, Col, Row } from "reactstrap";

//Componente de contexto ubicado en AutoppStart
import { useSendForm } from './SharedComponents/useSendForm';
import { UserContext } from './SharedComponents/UserContext';

export const CardSelectForm = React.memo(() => {

    //#region states globales
    const {
        setReloadTableRequests,
        setRequestsOrForm,
        setFormSelected,
        requestsOrForm,
        formSelected,
        toggleCheck,
        cycle

    } = useContext(UserContext);
    //#endregion

    //#region states locales
    const [stateForms, setStateForms] = useState({
        titleForm: "Selección de Formulario",
        subtitleForm: "Creación de una nueva oportunidad y requerimiento de BAW",
    });
    const { titleForm, subtitleForm } = stateForms;

    const [stateRequests] = useState({
        titleRequests: "Mis Solicitudes",
        subtitleRequests: "Status de oportunidades generadas y requerimientos de BAW",
    });
    const { titleRequests, subtitleRequests } = stateRequests;

    //Handle para el envío de la petición 
    const { handleSendNewRequest } = useSendForm();


    //#endregion

    //Handle para establacer vista de form, o la vista de y a la vez refrescar la tabla
    const handleSetRequestOrForm = () => {

        if (requestsOrForm === "forms") { setRequestsOrForm("requests"); setReloadTableRequests(true); }

        if (requestsOrForm === "requests") {
            setRequestsOrForm("forms");
        }

    }

    //#region Campos Botones

    const [fieldsButtons] = useState([
        {
            id: "1",
            nameButton: 'Información General',
            className: 'fa fa-info-circle',
            exception: false
        },
        {
            id: "2",
            nameButton: 'Cliente',
            className: 'fa fa-id-card',
            exception: false
        },
        {
            id: "3",
            nameButton: 'Equipo de Ventas',
            className: 'fa fa-users',
            exception: false
        },
        {
            id: "4",
            nameButton: 'LDR',
            nameButton2: 'Documentos',
            className: 'fa fa-list-ol',
            exception: true,
            notAppearInCycle: [2/*Identify Opportunity*/, 3/*Qualification*/, 5/*Close*/] //Cuando se seleccione estos ids que no aparezca       

        },
        {
            id: "5",
            nameButton: 'BAW',
            className: 'fa fa-th-list',
            exception: true,
            notAppearInCycle: [2/*Identify Opportunity*/, 3/*Qualification*/, 4/*Quotation*/, 5/*Close*/, 6/*GESTO Quotation*/] //Cuando se seleccione estos ids que no aparezca        
        },

    ]);
    //#endregion


    //#endregion
    return (
        <>
            <Card className=" mb-1">

                <CardHeader className=" mb--10  h-25 ">
                    <Row className="align-items-center  mr--4">
                        <Col sm="7" className="">
                            <h3 className="mb-0"> {requestsOrForm === "forms" ? titleForm : titleRequests}</h3>
                            <p className="text-sm mb-0">{requestsOrForm === "forms" ? subtitleForm : subtitleRequests}</p>

                        </Col>

                        {<Col xs="12" sm="5" className="mr--2 p-1" style={{ display: "flex", justifyContent: "flex-end" }}>

                            {/* <Col xs="7" sm="9" className=" " style={{ display: "flex", justifyContent: "flex-end" }}> */}
                            <Button
                                color="primary"
                                className="btn-icon"
                                size="sm"
                                onClick={() => handleSetRequestOrForm()}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className={requestsOrForm === "forms" ? "fas fa-search" : "fa fa-reply"} />
                                </span>
                                <span className="btn-inner--text"> {requestsOrForm === "forms" ? "Ver solicitudes" : "Volver al formulario"}</span>
                            </Button>
                            {/* </Col> */}

                            {/* <Col xs="5" sm="5" className="mr--3  p-0" style={{ display: "flex", justifyContent: "flex-end" }}> */}
                            {requestsOrForm === "forms" &&
                                <Button
                                    id="sendRequestBtn"
                                    color="success"
                                    className="btn-icon"
                                    size="sm"
                                    onClick={() => handleSendNewRequest()}
                                >
                                    <span className="btn-inner--icon mr-1" onClick={() => handleSendNewRequest()}>
                                        <i className="fa fa-location-arrow" onClick={() => handleSendNewRequest()} />
                                    </span>
                                    <span className="btn-inner--text" onClick={() => handleSendNewRequest()} >Enviar gestión</span>
                                </Button>
                            }
                            {/* </Col> */}
                        </Col>}

                        <Col xs="6" sm="4" className=" p-0 " >
                            <label>  </label>

                        </Col>
                        {<Col xs="12" sm="2" className="" >
                            <label></label>
                        </Col>}






                    </Row>




                    {requestsOrForm === "forms" &&

                        //Row para el navtab
                        <Row className=" mb-3 mt-2" style={{ display: "flex", justifyContent: "center" }}>
                            <Col sm='' md='' className=" p-1" style={{ display: "flex", justifyContent: "center" }}>
                                <div className="wrapper  mt--5 mb--4">
                                    <nav className="tabs">
                                        <div className="selector"></div>
                                        {
                                            fieldsButtons.map((fields, key) => {
                                                return (
                                                    //Es el button del LDR 
                                                    fields.id === "4" ? (
                                                        <>

                                                            {
                                                                !fields.notAppearInCycle.includes(parseInt(cycle)) 
                                                                &&
                                                                <a
                                                                    onClick={() => setFormSelected(fields.id)}
                                                                    className=
                                                                    {formSelected === fields.id ?
                                                                        "w-18 active test bg-info" :
                                                                        " "}
                                                                    aria-current="page"
                                                                    key={key}
                                                                    id={fields.id} data-toggle="tab" href="#home1" role="tab" aria-controls="home1" aria-selected={formSelected === fields.id}

                                                                >

                                                                    <span className="btn-inner--icon mr-1">
                                                                        <i className={fields.className} />
                                                                    </span>
                                                                    <span className="btn-inner--text">{toggleCheck?.applyLDROption === true || (toggleCheck?.applyLDROption === false && toggleCheck?.applyBOMPNOption === false) ? fields.nameButton : fields.nameButton2}</span>
                                                                </a>
                                                            }

                                                        </>
                                                    ) : fields.exception == true ?
                                                        (
                                                            <>
                                                                {
                                                                    !fields.notAppearInCycle.includes(parseInt(cycle))
                                                                    &&
                                                                    <a
                                                                        onClick={() => setFormSelected(fields.id)}
                                                                        className=
                                                                        {formSelected === fields.id ?
                                                                            "w-20 active test bg-info" :
                                                                            " "}
                                                                        aria-current="page"
                                                                        key={key}
                                                                        id={fields.id} data-toggle="tab" href="#home1" role="tab" aria-controls="home1" aria-selected={formSelected === fields.id}

                                                                    >

                                                                        <span className="btn-inner--icon mr-1">
                                                                            <i className={fields.className} />
                                                                        </span>
                                                                        <span className="btn-inner--text">{fields.nameButton}</span>
                                                                    </a>


                                                                }


                                                            </>
                                                        )

                                                        : ( //es diferente al campo de LDR

                                                            <a
                                                                onClick={() => setFormSelected(fields.id)}
                                                                className=
                                                                {formSelected === fields.id ?
                                                                    "w-20 active test bg-info" :
                                                                    " "}
                                                                aria-current="page"
                                                                key={key}
                                                                id={fields.id} data-toggle="tab" href="#home1" role="tab" aria-controls="home1" aria-selected={formSelected === fields.id}

                                                            >

                                                                <span className="btn-inner--icon mr-1">
                                                                    <i className={fields.className} />
                                                                </span>
                                                                <span className="btn-inner--text">{fields.nameButton}</span>
                                                            </a>
                                                        )

                                                )
                                            })
                                        }
                                    </nav>
                                </div>

                            </Col>
                        </Row>

                    }

                </CardHeader>


            </Card>

        </>

    )
})
