import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

/**
 * Accion para buscar los recursos asocionados a los nodos especificos
 */
export const findResourses = createAction(constants.GET_RESOURSES, data => method.post(urls.PLANNINGMRS.getResourses, data)());

/**
 * Accion para buscar los nodos de la base de datos
 */
export const findNodes = createAction(constants.GET_NODES, method.get(urls.PLANNINGMRS.getNodes));
