import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const formValuesRequest = handleActions(
  {
    [constants.GET_FORM_VALUES_REQUEST_OPPORTUNITY]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.CLEAR_DATA]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const requeriments = handleActions(
  {
    [constants.GET_REQUERIMENTS_BY_USER]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.CREATE_REQUIREMENT]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { requestCreated, message } = data.payload;
        if (Object.keys(state).length) {
          const { message, requests } = state;
          return {
            requests: [...requests, ...requestCreated],
            message,
          };
        } else {
          return {
            requests: [...requestCreated],
            message,
          };
        }
      }
      return state;
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const formValuesEquipments = handleActions(
  {
    [constants.GET_FORM_VALUES_EQUIPMENTS_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.CLEAR_DATA]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const equipments = handleActions(
  {
    [constants.CREATE_ONE_EQUIPMENT]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { equipment, type, initialValues } = data.payload;
        if (Object.keys(state).length) {
          const { ibm, cisco } = state;
          if (type === "IBM") {
            return {
              ibm: [...ibm, ...equipment],
              cisco,
              initialValues,
            };
          } else if (type === "CISCO") {
            return {
              ibm,
              cisco: [...cisco, ...equipment],
              initialValues,
            };
          }
        } else {
          if (type === "IBM") {
            return {
              ibm: equipment,
              cisco: [],
              initialValues,
            };
          } else if (type === "CISCO") {
            return {
              ibm: [],
              cisco: equipment,
              initialValues,
            };
          }
        }
      }
      return {};
    },
    [constants.GET_EQUIPMENTS_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const {
          ibmEquipments,
          ciscoEquipments,
          allEquipments,
          ibmEquipmentsComplete,
        } = data.payload;
        return {
          ibm: ibmEquipments,
          cisco: ciscoEquipments,
          initialValues: {},
          allEquipments,
          ibmEquipmentsComplete,
        };
      }
      return {};
    },
    [constants.GET_EQUIPMENTS_CREATED_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { equipments } = data.payload;
        return {
          ibm: equipments,
          cisco: [],
          initialValues: {},
        };
      }
      return {};
    },
    [constants.CREATE_REQUIREMENT]: (state, action) => {
      const { status } = action.payload;
      if (status === 200) {
        return {
          ibm: [],
          cisco: [],
          initialValues: {},
        };
      }
      return {};
    },
    [constants.UPDATE_EQUIPMENT_BY_ID]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        let { ibm, cisco, initialValues } = state;
        const { equipment, type, id } = data.payload;
        if (type === "ibm") {
          return {
            initialValues,
            ibm: ibm.map((row) => {
              if (row.id === parseInt(id)) {
                return equipment;
              }
              return row;
            }),
            cisco,
          };
        } else if (type === "cisco") {
          return {
            initialValues,
            ibm,
            cisco: cisco.map((row) => {
              if (row.id === parseInt(id)) {
                return equipment;
              }
              return row;
            }),
          };
        }
      }
      return state;
    },
    [constants.GET_REQUERIMENTS_BY_USER]: (state, action) => {
      const { status } = action.payload;
      if (status === 200) {
        return {
          ibm: [],
          cisco: [],
          initialValues: {},
        };
      }
      return {};
    },
    [constants.DEACTIVATE_EQUIPMENT_BY_ID]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        let { ibm, cisco, initialValues } = state;
        const { id } = data.payload;
        return {
          initialValues,
          ibm: ibm.filter((row) => row.id !== parseInt(id)),
          cisco: cisco,
        };
      }
      return state;
    },
    // [constants.GET_FORM_VALUES_EQUIPMENTS_REQUEST]: () => { return {} },
    [constants.GET_FORM_VALUES_REQUEST_OPPORTUNITY]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const activityFlow = handleActions(
  {
    [constants.GET_ACTIVITY_FLOW_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const references = handleActions(
  {
    [constants.GET_REFERENCES_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.GET_REQUERIMENTS_BY_USER]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const referencesSpare = handleActions(
  {
    [constants.GET_REFERENCES_SPARE_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.CREATE_ONE_EQUIPMENT]: () => [],
    [constants.GET_REQUERIMENTS_BY_USER]: () => [],
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const configurations = handleActions(
  {
    [constants.GET_CONFIGURATIONS_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.GET_REQUERIMENTS_BY_USER]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const justifications = handleActions(
  {
    [constants.GET_JUSTIFICATION_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.GET_REQUERIMENTS_BY_USER]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const equipmentSpare = handleActions(
  {
    [constants.GET_EQUIPMENTS_SPARE_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { equipments } = data.payload;
        return equipments;
      }
      return state;
    },
    [constants.CREATE_EQUIPMENT_SPARE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { equipment } = data.payload;
        return [...state, ...equipment];
      }
      return state;
    },
    [constants.UPDATE_EQUIPMENT_SPARE_BY_ID]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { equipment, id } = data.payload;
        return state.map((row) => {
          if (row.id === parseInt(id)) {
            return equipment;
          }
          return row;
        });
      }
      return state;
    },
    [constants.DEACTIVATE_EQUIPMENT_SPARE_BY_ID]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const { id } = data.payload;
        return state.filter((row) => row.id !== parseInt(id));
      }
      return state;
    },
    [constants.CREATE_ONE_EQUIPMENT]: () => [],
    [constants.GET_REQUERIMENTS_BY_USER]: () => [],
    [constants.SIGN_OUT]: () => [],
  },
  []
);

export const resumeOffers = handleActions(
  {
    [constants.GET_RESUME_OFFERS_BY_REQUEST_ID]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return state;
    },
    [constants.GET_REQUERIMENTS_BY_USER]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const lastVersion = handleActions(
  {
    [constants.GET_LAST_VERSION_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return state;
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const formValuesVersion = handleActions(
  {
    [constants.GET_OPTIONS_REQUEST_TO_VERSION]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return state;
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);
