import moment from "moment";

export const getPersonalInfoEmployeeForms = (state) => {
  const { employeeForms } = state;
  if (Object.keys(employeeForms).length) {
    return employeeForms.employeeInfo;
  }
  return {};
};

export const getPersonalInfoByEmployee = (state) => {
  const { personalInfo } = state;
  if (Object.keys(personalInfo).length) {
    return personalInfo.info;
  }
  return {};
};

export const getPersonalInfoCompleteByEmployee = (state) => {
  const { personalInfoComplete } = state;
  if (Object.keys(personalInfoComplete).length) {
    return personalInfoComplete.info;
  }
  return {};
};

export const getRelationShips = (state) => {
  const { relationShips } = state;
  if (Object.keys(relationShips).length) {
    return relationShips.relationships;
  }
  return [];
};

export const getMaritalStatus = (state) => {
  const { maritalStatus } = state;
  if (Object.keys(maritalStatus).length) {
    return maritalStatus.maritalStatus;
  }
  return [];
};
export const getFamilyGroup = (state) => {
  const { familyGroups } = state;
  if (Object.keys(familyGroups).length) {
    return familyGroups.familyGroup;
  }
  return [];
};

export const getEmergencyContacts = (state) => {
  const { emergencyContactsEmployee } = state;
  if (Object.keys(emergencyContactsEmployee).length) {
    return emergencyContactsEmployee.emergencyContacts;
  }
  return [];
};

export const getEmergencyContactsLoaded = (state) => {
  const { emergencyContactsLoaded } = state;
  if (Object.keys(emergencyContactsLoaded).length) {
    return emergencyContactsLoaded.contactsLoaded;
  }
  return [];
};

export const getFiltersOptions = (state) => {
  const { employeeForms } = state;
  if (Object.keys(employeeForms).length) {
    const { employeeInfo } = employeeForms;
    console.log(employeeInfo);
    let dates = employeeInfo.map((row, key) => {
      return {
        value: key + 1,
        label: moment(row.createdAt).format("YYYY"),
      };
    });
    dates = dates
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => dates[e])
      .map((e) => dates[e]);
    dates = dates.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let employees = employeeInfo.map((row, key) => {
      return {
        value: key + 1,
        label: row.name,
      };
    });
    employees = employees
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => employees[e])
      .map((e) => employees[e]);
    employees = employees.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let positions = employeeInfo.map((row, key) => {
      return {
        value: key + 1,
        label: row.position,
      };
    });
    positions = positions
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => positions[e])
      .map((e) => positions[e]);
    positions = positions.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return {
      dates: [{ value: 0, label: "Todos" }, ...dates],
      employees: [{ value: 0, label: "Todos" }, ...employees],
      positions: [{ value: 0, label: "Todos" }, ...positions],
    };
  }
  return {
    dates: [{ value: 0, label: "Todos" }],
    employees: [{ value: 0, label: "Todos" }],
    positions: [{ value: 0, label: "Todos" }],
  };
};
