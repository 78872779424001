// antd components
import { Spin } from "antd";
// import core action
import {
  findPersonalInfoEmployee,
  findRelationships,
  findMaritalStatus,
  findFamilyGroupByEmployee,
  findEmergencyContactsByEmployee,
  createFamilyGroupByEmployee,
  createEmergencyContactByEmployee,
  createManyEmergencyContactByEmployee,
  completePersonalInfoByEmployee,
  loadEmergencyContactsByEmployee,
  deactivatedFamilyGroupByID,
  deactivatedEmergencyContactByID,
} from "actions/knowEmployee.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// core components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import ContactsLoad from "components/KnowEmployee/ContactsLoad";
import MyFormEmployee from "components/KnowEmployee/MyFormEmployee";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getPersonalInfoByEmployee,
  getRelationShips,
  getMaritalStatus,
  getFamilyGroup,
  getEmergencyContacts,
  getEmergencyContactsLoaded,
} from "selectors/knowEmployee.jsx";

class CompleteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: false,
      message: null,
      showContactsModal: false,
      contactsSelected: [],
      // initialValues: {},
    };
  }

  componentDidMount() {
    this.props.findPersonalInfoEmployee().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        const { personalInfo } = this.props;
        this.props.findFamilyGroupByEmployee(personalInfo.id);
        this.props.findEmergencyContactsByEmployee(personalInfo.id);
        this.notify("success", "Éxito", payload.message);
      }
    });
    this.props.findRelationships();
    this.props.findMaritalStatus();
  }

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof MySignatures
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof MySignatures
   */
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnToggleContactsModal = () => {
    this.setState((state) => ({
      showContactsModal: !state.showContactsModal,
    }));
  };

  handleOnSignatureForm = async (values) => {
    this.setState({
      loading: true,
      message: "Completando la información del colaborador",
    });
    this.props.completePersonalInfoByEmployee(values.id, values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findPersonalInfoEmployee();
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnCreateFamily = async (values) => {
    const {
      id,
      // parentName,
      // parentDocumentID,
      // parentDateOfBirth,
      // parentRelationShip,
      // relationShipDetail,
    } = values;
    this.setState({
      loading: true,
      message: "Creando el familiar en nuestro sistema",
    });
    this.props.createFamilyGroupByEmployee(id, values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading: false,
        message: null,
      });
      this.props.findFamilyGroupByEmployee(id);
    });
  };

  handleOnCreateContact = async (values) => {
    const { emergyContacts } = this.props;
    const {
      id,
      // emergencyName,
      // emergencyTelephone,
      // emergencyTelephone2,
      // emergencyAddress,
    } = values;
    if (emergyContacts.length < 3) {
      this.setState({
        loading: true,
        message: "Creando el contacto de emergencia en nuestro sistema",
      });
      this.props.createEmergencyContactByEmployee(id, values).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
        }
        this.setState({
          loading: false,
          message: null,
        });
        this.props.findEmergencyContactsByEmployee(id);
      });
    } else {
      this.notify(
        "warning",
        "Atención",
        "Ya se llego al límite máximo de contactos de emergencia."
      );
    }
  };

  handleOnLoadEmergencyContacts = async () => {
    const { personalInfo } = this.props;
    this.props.loadEmergencyContactsByEmployee(personalInfo.id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.handleOnToggleContactsModal();
        this.notify("success", "Éxito", payload.message);
      }
    });
  };

  handleOnChangeContactsLoad = (event) => {
    const { contactsSelected } = this.state;
    const { emergyContacts } = this.props;
    const { id } = event.target;
    const index = contactsSelected.indexOf(parseInt(id));
    if (index > -1) {
      contactsSelected.splice(index, 1);
    } else {
      if (contactsSelected.length + emergyContacts.length < 3) {
        contactsSelected.push(parseInt(id));
      } else {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Warning"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="warning"
              confirmBtnText="Ok"
              btnSize=""
            >
              El límite de contactos que se pueden agregar es de 3
            </ReactBSAlert>
          ),
        });
      }
    }
    this.setState({
      contactsSelected,
    });
  };

  handleOnSaveContactsLoad = async () => {
    const { personalInfo } = this.props;
    const { contactsSelected } = this.state;
    const { emergyContactsLoaded } = this.props;
    const values = emergyContactsLoaded.filter((row) =>
      contactsSelected.some((ele) => row.id === ele)
    );
    this.setState({
      loading: true,
      message: "Creando los contactos de emergencia en nuestro sistema",
    });
    for (const element of values) {
      await this.props
        .createManyEmergencyContactByEmployee(personalInfo.id, {
          emergencyName: element.name,
          emergencyTelephone: element.telephone,
          emergencyTelephone2: null,
          emergencyAddress: element.address,
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
          }
        });
    }
    this.setState({
      loading: false,
      message: null,
      contactsSelected: [],
    });
    this.props.findEmergencyContactsByEmployee(personalInfo.id);
    this.handleOnToggleContactsModal();
  };

  onDeleteFamilyGroup = (id) => {
    this.hideAlert();
    const { personalInfo } = this.props;
    this.setState({
      loading: true,
      message: "Eliminando el familiar seleccionado",
    });
    this.props.deactivatedFamilyGroupByID(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findFamilyGroupByEmployee(personalInfo.id);
      }
    });
    this.setState({
      loading: false,
      message: null,
    });
  };

  handleOnDeleteFamilyGroup = (row) => {
    const { id } = row;
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar el familiar?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteFamilyGroup(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  onDeleteEmergencyGroup = (id) => {
    this.hideAlert();
    const { personalInfo } = this.props;
    this.setState({
      loading: true,
      message: "Eliminando el contacto de emergencia seleccionado",
    });
    this.props.deactivatedEmergencyContactByID(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findEmergencyContactsByEmployee(personalInfo.id);
      }
    });
    this.setState({
      loading: false,
      message: null,
    });
  };

  handleOnDeleteEmergencyContact = (row) => {
    const { id } = row;
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar el familiar?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteEmergencyGroup(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  render() {
    const { alert, loading, message, showContactsModal, contactsSelected } =
      this.state;

    const {
      name,
      parentName,
      personalInfo,
      maritalStatus,
      relationShip,
      familyGroups,
      emergyContacts,
      emergyContactsLoaded,
    } = this.props;

    return (
      <>
        {alert}
        <ContactsLoad
          showModal={showContactsModal}
          contacts={emergyContactsLoaded}
          initialValues={contactsSelected}
          toggleModal={this.handleOnToggleContactsModal}
          onChange={this.handleOnChangeContactsLoad}
          onSaveContacts={this.handleOnSaveContactsLoad}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {Object.keys(personalInfo).length ? (
              <MyFormEmployee
                title="Completar Formulario"
                subtitle="Formulario Conocimiento de Empleado Debida Diligencia Estándar"
                initialValues={personalInfo}
                options={{
                  maritalStatus,
                  relationShip,
                  familyGroups,
                  emergyContacts,
                }}
                onSignature={this.handleOnSignatureForm}
                onCreateFamily={this.handleOnCreateFamily}
                onCreateContact={this.handleOnCreateContact}
                onLoadContacts={this.handleOnLoadEmergencyContacts}
                deleteFamilyGroup={this.handleOnDeleteFamilyGroup}
                deleteContact={this.handleOnDeleteEmergencyContact}
              />
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="Muchas gracias por completar la información requerida."
                    subtitle="Actualmente al día de hoy, no hay mas datos para completar, cuando hayan formularios se mostranran en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

CompleteForm.defaultProps = {
  name: "Conoce a tu Empleado",
  parentName: "Políticas GBM",
  maritalStatus: [],
  relationShip: [],
  personalInfo: {},
  familyGroups: [],
  emergyContacts: [],
  emergyContactsLoaded: [],
};

CompleteForm.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  maritalStatus: PropTypes.array.isRequired,
  relationShip: PropTypes.array.isRequired,
  personalInfo: PropTypes.object.isRequired,
  familyGroups: PropTypes.array.isRequired,
  emergyContacts: PropTypes.array.isRequired,
  emergyContactsLoaded: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  personalInfo: getPersonalInfoByEmployee(state),
  relationShip: getRelationShips(state),
  maritalStatus: getMaritalStatus(state),
  familyGroups: getFamilyGroup(state),
  emergyContacts: getEmergencyContacts(state),
  emergyContactsLoaded: getEmergencyContactsLoaded(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findPersonalInfoEmployee,
    findRelationships,
    findMaritalStatus,
    findFamilyGroupByEmployee,
    findEmergencyContactsByEmployee,
    createFamilyGroupByEmployee,
    createEmergencyContactByEmployee,
    createManyEmergencyContactByEmployee,
    completePersonalInfoByEmployee,
    loadEmergencyContactsByEmployee,
    deactivatedFamilyGroupByID,
    deactivatedEmergencyContactByID,
  })(CompleteForm)
);
