/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Input, Row, Table } from "reactstrap";

const AddEquipments = props => {

  const {
    title,
    subtitle,
    rows,
    cols,
    options,
    initialValues,
    onChangeValues,
    equipmentsAdded,
    addEquipment,
    onCreateVersion
  } = props;

  const practices = options.find((row) => row.key === "practice");
  const optionsPractices = practices ? practices.options ? practices.options.find((row) => row.id === parseInt(initialValues.practice)) : [] : [];

  return (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md='7'>
            <h6 className="surtitle text-white">{title}</h6>
            <h5 className="h4 mb-0 text-white">{subtitle}</h5>
          </Col>
          <Col xs="12" md='5' style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="sm"
              className="btn-round btn-icon"
              color="success"
              onClick={onCreateVersion}
              disabled={!equipmentsAdded.length}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-check-double" />
              </span>
              <span className="btn-inner--text">Finalizar</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table
        className="align-items-center  table-dark table-flush"
        responsive
      >
        <thead className="thead-dark">
          <tr>
            {
              cols.map((row, key) => (row.hide === false ? <th key={key}>{row.name}</th> : null))
            }
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {
            [...rows, ...equipmentsAdded].map((row, key) => (
              <tr key={key}>
                {
                  cols.map((col, key) => (
                    col.hide === false &&
                    <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row[col.key]}
                    </th>
                  ))
                }
                <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                  <a
                    className="table-action table-action"
                    href="#"
                  // onClick={onUpdate}
                  >
                    <i className="fas fa-check-double" />
                  </a>
                </th>
              </tr>
            ))
          }
          <tr>
            {
              options.map((row, key) => (
                row.hide === false &&
                <td key={key}>
                  {
                    row.options ?
                      row.key === "platform" ?
                        <div>
                          <Input
                            style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: row.key === 'productID' ? '250px' : "auto" }}
                            id={row.key}
                            type={row.type}
                            value={initialValues[row.key]}
                            disabled={!initialValues.practice || parseInt(initialValues.practice) < 1}
                            onChange={(e) => onChangeValues(e, 'add')}
                          >
                            <option key="0" value="0">{row.placeholder}</option>
                            {
                              row.options.filter((row) => row.fk_idPractice === parseInt(initialValues.practice)).map((row, key) => {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              })
                            }
                          </Input>
                        </div>
                        :
                        <div>
                          <Input
                            style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: row.key === 'productID' ? '250px' : "auto" }}
                            id={row.key}
                            type={row.type}
                            value={initialValues[row.key]}
                            onChange={(e) => onChangeValues(e, 'add')}
                          >
                            <option key="0" value="0">{row.placeholder}</option>
                            {
                              row.options.map((row, key) => {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              })
                            }
                          </Input>
                        </div>
                      :
                      row.key === "amountMaintenance" ?
                        <Input
                          style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "-webkit-fill-available" }}
                          id={row.key}
                          type={row.type}
                          placeholder={row.placeholder}
                          defaultValue={optionsPractices ? optionsPractices.prevents : row.min}
                          value={initialValues[row.key]}
                          onChange={(e) => onChangeValues(e, 'add')}
                          disabled={!initialValues.practice || parseInt(initialValues.practice) < 1}
                          maxLength={row.max}
                          max={row.max}
                          min={optionsPractices ? optionsPractices.prevents : row.min}
                        />
                        :
                        <Input
                          style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "-webkit-fill-available" }}
                          id={row.key}
                          type={row.type}
                          placeholder={row.placeholder}
                          value={initialValues[row.key]}
                          onChange={(e) => onChangeValues(e, 'add')}
                          maxLength={row.max}
                          max={row.max}
                          min={row.min}
                        />
                  }
                </td>
              ))
            }
            <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <Button
                className="btn-icon btn-3"
                size="sm"
                color="info"
                type="button"
                onClick={addEquipment}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-save" />
                </span>
                <span className="btn-inner--text">Agregar</span>
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

AddEquipments.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default AddEquipments;
