// core actions
import { createPolicySignature, findDataRegisterSignature } from "actions/digitalSignature.jsx";
import { clearData } from "actions/newPosition.jsx";
// javascript plugin that creates nice dropzones for files
import { message } from 'antd';
// core components DigitalSignature
import RegisterSignatureForm from "components/DigitalSignature/RegisterSignatureForm.jsx";
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getCountrys, getDocuments, getInitialValues, getOrganizationalUnits } from "selectors/digitalSignature.jsx";

class RegisterSignature extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      files: [],
      alert: null,
      initialValues: {}
    };
  }

  componentWillMount() { }

  /**
  * Funcion para el manejo de notificaciones parametrizadas
  * @memberof OptionalsPositions
  */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  convertFilesToBase64 = files => {
    for (const element of files) {
      const reader = new FileReader();
      reader.onloadend = (obj) => {
        element.base64 = obj.srcElement.result;
      };
      reader.readAsDataURL(element.originFileObj);
    }
    return files;
  }

  _registerPolicySignature = async values => {
    const { files } = this.state;
    this.setState((state) => ({
      loading: !state.loading
    }));
    this.hideAlert();
    await this.props.createPolicySignature({ ...values, files, documents: this.props.documents }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState((state) => ({
          loading: !state.loading,
        }));
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.clearData();
        this.setState((state) => ({
          loading: !state.loading,
          files: []
        }));
      }
    });
  }

  _handleOnRegisterSignature = async values => {
    const { files } = this.state;
    if (files.length === 0) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Atención"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="warning"
            confirmBtnText="Entendido"
            btnSize=""
          >
            Debe adjuntar documentos como evidencia de que el colaborador haya firmado.
          </ReactBSAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Estás seguro de registrar la firma?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this._registerPolicySignature(values)}
            onCancel={() => this.hideAlert()}
            showCancel
            confirmBtnBsStyle="success"
            confirmBtnText="Sí, registrar."
            cancelBtnBsStyle="danger"
            cancelBtnText="No, cancelar."
            btnSize=""
          >
            {`Si aceptas seguir con el proceso, se realizara el registro de las firmas para el colaborador ${values.idCollaborator}`}
          </ReactBSAlert>
        )
      });
    }
  }

  _handleOnFilesList = async info => {
    const { status } = info.file;
    this.setState({
      loading: true
    });
    if (status === 'done') {
      this.setState({
        files: this.convertFilesToBase64(info.fileList),
        loading: false
      });
      message.success(`${info.file.name} archivo cargado exitosamente.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} la carga del archivo fallo.`);
    } else if (status === "removed") {
      this.setState({
        files: this.convertFilesToBase64(info.fileList),
        loading: false
      });
      message.success(`${info.file.name} archivo quitado exitosamente.`);
    }
  }

  _handleOnCancel = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de cancelar?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.props.clearData() && this.hideAlert()}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="success"
          confirmBtnText="Si, cancelar."
          cancelBtnBsStyle="danger"
          cancelBtnText="No, continuar."
          btnSize=""
        >
          {`Si acepta volvera a la primer vista donde debera iniciar de nuevo`}
        </ReactBSAlert>
      )
    });

  }

  _handleOnSearch = values => {
    const { idCollaborator } = values;
    this.setState((state) => ({
      loading: !state.loading
    }));
    this.props.findDataRegisterSignature(idCollaborator).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState((state) => ({
        loading: !state.loading
      }));
    });
  }

  render() {

    const {
      name,
      parentName,
      countrys,
      organizationalUnits,
      documents,
      initialValues
    } = this.props;

    const { alert, loading, } = this.state;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <RegisterSignatureForm
                title="Formulario de registro de firmas escaneadas."
                subtitle="Aquí se deben registrar las firmas escaneadas de todos los colaboradores de GBM que no tienen acceso a firmar digitalmente."
                initialValues={initialValues}
                countrys={countrys}
                organizationalUnits={organizationalUnits}
                documents={documents}
                loading={loading}
                onFileList={this._handleOnFilesList}
                onSearch={this._handleOnSearch}
                onCancel={this._handleOnCancel}
                onSubmit={this._handleOnRegisterSignature}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

RegisterSignature.defaultProps = {
  name: "Registrar Firma",
  parentName: "Firma Digital",
  organizationalUnits: [],
  countrys: [],
  documents: [],
  initialValues: {}
};

RegisterSignature.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  organizationalUnits: PropTypes.array.isRequired,
  countrys: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  organizationalUnits: getOrganizationalUnits(state),
  countrys: getCountrys(state),
  documents: getDocuments(state),
  initialValues: getInitialValues(state),
});

export default withRouter(connect(mapStateToProps, {
  createPolicySignature,
  findDataRegisterSignature,
  clearData
})(RegisterSignature));