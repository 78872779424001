// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-select library for filters
import Select from "react-select";
// react date time library
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  FormGroup,
  Row,
} from "reactstrap";

const FiltersDashboard = (props) => {
  const { title, subtitle, options, values, onClear, onChange, onFilter } =
    props;

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col sm="12" md="10">
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{subtitle}</p>
          </Col>
          <Col
            xs="12"
            md="2"
            className="p-1"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              block
              type="button"
              onClick={onClear}
            >
              <span className="btn-inner--icon mr-">
                <i className="fas fa-trash" />
              </span>
              <span className="btn-inner--text">Limpiar</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="text-right" xs="12" md="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="country">
                País
              </label>
              <Select
                isMulti
                isSearchable
                className="basic-single"
                id="country"
                type="select"
                placeholder="Seleccione un País"
                options={options.countries}
                value={values.country}
                onChange={(e) => onChange("country", e)}
              />
            </FormGroup>
          </Col>
          <Col className="text-right" xs="12" md="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="service">
                Servicio
              </label>
              <Select
                isMulti
                isSearchable
                className="basic-single"
                id="service"
                type="select"
                placeholder="Seleccione un servicio"
                options={options.services}
                value={values.service}
                onChange={(e) => onChange("service", e)}
              />
            </FormGroup>
          </Col>
          <Col className="text-right" xs="12" md="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="status">
                Estado
              </label>
              <Select
                isMulti
                isSearchable
                className="basic-single"
                id="status"
                type="select"
                placeholder="Seleccione un estado"
                options={options.status}
                value={values.status}
                onChange={(e) => onChange("status", e)}
              />
            </FormGroup>
          </Col>
          <Col className="text-right" xs="12" md="3">
            <FormGroup>
              <label className="form-control-label">Año de Inicio</label>
              <ReactDatetime
                inputProps={{
                  placeholder: "Elija una fecha",
                  className: "form-control",
                }}
                defaultValue={values.year}
                timeFormat={true}
                dateFormat="YYYY"
                onChange={(e) => onChange("year", e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Button
              className="btn-icon"
              color="success"
              size="md"
              type="button"
              onClick={onFilter}
            >
              <span className="btn-inner--icon mr-">
                <i className="fas fa-search" />
              </span>
              <span className="btn-inner--text">Aplicar Filtro</span>
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

FiltersDashboard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FiltersDashboard;
