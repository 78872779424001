/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardHeader, Modal, Table } from "reactstrap";

const MedicinesModal = props => {

  const {
    title,
    modalOptions: {
      showModal,
      values
    },
    toggleModal,
    editMedication
  } = props;

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <Table
            className="align-items-center table-flush"
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th>Medicamento</th>
                <th>Fecha de Inicio</th>
                <th>¿Sigue Pasando?</th>
                <th>Dosis</th>
                <th>Efecto Secundario</th>
                <th />
              </tr>
            </thead>
            <tbody className="list">
              {
                values.map((row, key) => (
                  <tr key={key}>
                    <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.medication}
                    </td>
                    <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {moment(row.startedDate)
                        .utc()
                        .utcOffset(moment().utcOffset())
                        .format("L")}
                    </td>
                    <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.currently === 1 ? 'Sí' : 'No'}
                    </td>
                    <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.dosis}
                    </td>
                    <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row.sideEffects}
                    </td>
                    <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      <a
                        className="table-action table-action"
                        href="#"
                        id="edit"
                        onClick={() => editMedication(row)}
                      >
                        <i className="fas fa-edit" />
                      </a>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Card>
      </div>
    </Modal>
  );
};

MedicinesModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MedicinesModal;