/*!
=========================================================
*Databot CardsHeader
=========================================================

* Componente para desplegar cards por cada area del Databot para apagar o encender bots
=========================================================

* Coded by Diego Meza Castro & Eduardo Piedra Sanabria - Application Management GBM

*/
//Componentes de la líbrería de react.
import React, { useEffect, useState } from 'react';

//Import del card con el diseño para cada área del databot.
import CardBotArea from 'components/Databot/SharedComponents/CardBotArea.jsx';

//Componentes de la líbrería de reactstrap
import { Col } from "reactstrap";

const CardsHeader = ({
    databotAreas,
    handleTurnOnOffRobotByArea,
    rows
}) => {

    //Se define si los colores de los botones de encendido son rellenos o no.
    const [fillCircleButtons, setFillCircleButtons] = useState({});

    //Efecto para cuando cambie las rows definir si son rellenos o no los botones.
    useEffect(() => {
        let fillCircle = {};

        //#region Todas las databotareas
        databotAreas.map(area => {

            let quantityActive = rows.filter(row => (row.active == 1 && row.area == area.id)).length;
            fillCircle = { ...fillCircle, [area.id]: { on: quantityActive == 0, off: quantityActive > 0 } }
        })
        //#endregion

        //#region Área de SAP
        let quantityActive = rows.filter(row => (row.active == 1 && row.sap == 1)).length;
        fillCircle = { ...fillCircle, ["sap"]: { on: quantityActive == 0, off: quantityActive > 0 } }

        //#endregion

        setFillCircleButtons(fillCircle)

    }, [rows])

    return (
        <>
            {
                databotAreas.map((areas, key) => (
                    <Col key={key} sm="3">
                        <CardBotArea
                            id={areas.id}
                            title={areas.area}
                            subtitle={areas.areaText}
                            turnOnOffArea={handleTurnOnOffRobotByArea}
                            fillCircleButtons={fillCircleButtons}
                        />
                    </Col>
                ))
            }
            <Col sm="3">
                <CardBotArea
                    id={"sap"}
                    title={"SAP"}
                    subtitle={"Procesos de SAP"}
                    turnOnOffArea={handleTurnOnOffRobotByArea}
                    fillCircleButtons={fillCircleButtons}
                />
            </Col>
        </>
    );
}
export default CardsHeader;