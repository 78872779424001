import React from 'react';
import PropTypes from 'prop-types';

// reactstrap components
import { Card, CardBody, FormGroup, Col, Input, Row, Label, Button } from "reactstrap";
import CardHeader from 'reactstrap/lib/CardHeader';

//Token 
import { getTeamsUserAccess } from "selectors/adminLayout";
const ContactsFilters = props => {

    const {
        filter,
        filterList,
        filterListManager,
        previusPage,
        functionPreviusPage,
        title,
        subtitle
    } = props;

    const rolByUser = () => {
        const userRol = getTeamsUserAccess();
        if (userRol.indexOf("GBM Contact Sales Representative") != -1) {
            return filterList
        } else {
            return filterListManager
        }
    }

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm="12" md="10">
                            <h3 className="mb-0" >{title}</h3>
                            <p className="text-sm mb-0">{subtitle}</p>

                        </Col>
                        <Col sm="12" md="2">
                            {
                                previusPage === "true" ?
                                    <Button
                                        className="btn"
                                        color="danger"
                                        onClick={functionPreviusPage}>
                                        <span className="btn-inner--icon mr-1">
                                            <i className="fas fa-arrow-left" />
                                        </span>Regresar
                                    </Button>
                                    :
                                    <></>
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        {
                            previusPage === "true" ?
                                filterList.map((row) => (
                                    <Col >
                                        <FormGroup>
                                            <Label for="exampleEmail">{`${row.label}:`}</Label>
                                            <Input onChange={(e) => filter(e, row.value)} id={row.value} type="text" />
                                        </FormGroup>
                                    </Col>
                                ))
                                :
                                rolByUser().map((row) => (
                                    <Col >
                                        <FormGroup>
                                            <Label for="exampleEmail">{`${row.label}:`}</Label>
                                            <Input onChange={(e) => filter(e, row.value)} id={row.value} type="text" />
                                        </FormGroup>
                                    </Col>
                                ))
                        }
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

ContactsFilters.propTypes = {

};

export default ContactsFilters;