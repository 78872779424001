// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const AddUserModal = props => {

  const {
    showModal,
    toggleModal,
    loading,
    onSubmit
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.username)
      errors.username = "Debes ingresar un nombre de usuario.";
    return errors;
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={showModal}
        toggle={() => toggleModal("showModal")}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-3">
                <h4>Agregar Usuario a Horas Extras</h4>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <img
                  alt="AccessLogo"
                  src={require("assets/img/theme/key_access.png")}
                />
              </div>
              <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit, values, submitting, validating, valid }) => (
                  <Spin size="large" spinning={loading}>
                    <FormRS role="form">
                      <FormGroup>
                        <Label for="username">Nombre de Usuario</Label>
                        <Field name="username">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                placeholder="Ingrese el nombre de usuario"
                                invalid={meta.error && meta.touched}
                              />
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          disabled={validating || submitting}
                          className="my-4"
                          color="info"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Agregar rol
                          </Button>
                      </div>
                    </FormRS>
                  </Spin>
                )}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

AddUserModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default AddUserModal;