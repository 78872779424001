//Dispatch
import { getAllPurchaseOrderMacro } from 'actions/panamaBids.jsx';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import moment from "moment";
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';

const AgreementMainTable = ({ rows, openModal, setPage, page, refreshInput, setRowsModal, filters }) => {

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(
    [
      { value: "singleOrderRecord", label: "Registro Unico de Pedido" },
      { value: "sector", label: "Sector" },
      { value: "agreement", label: "Convenio" },
      { value: "entity", label: "Entidad" },
      { value: "orderSubtotal", label: "Sub Total de Orden" },
      { value: "purchaseOrder", label: "Orden de Compra" },
      { value: "publicationDate", label: "Fecha de publicación", type: "date" },
      { value: "performanceBond", label: "Cumplimiento de Fianza" },
    ]
  );
  const optionsSelect = [
    { value: "singleOrderRecord", label: "Registro Unico de Pedido", disabled: true },
    { value: "sector", label: "Sector" },
    { value: "agreement", label: "Convenio", disabled: true },
    { value: "entity", label: "Entidad", disabled: true },
    { value: "orderSubtotal", label: "Sub Total de Orden" },
    { value: "purchaseOrder", label: "Orden de Compra" },
    { value: "publicationDate", label: "Fecha de publicación", type: "date" },
    { value: "registrationDate", label: "Fecha de Registro", type: "date" },
    { value: "performanceBond", label: "Cumplimiento de Fianza" },
    { value: "oportunity", label: "Oportunidad" },
    { value: "quote", label: "Quote" },
    { value: "salesOrder", label: "Orden de Venta" },
    { value: "deliveryDay", label: "Dia de Entrega" },
    { value: "maximumDeliveryDate", label: "Fecha Maxima de Entrega", type: "date" },
    { value: "daysRemaining", label: "Días Faltantes" },
    { value: "forecast", label: "Forecast" },
    { value: "forecastType", label: "Tipo de Forecast" },
    { value: "gbmStatus", label: "Estado GBM" },
    { value: "orderStatus", label: "Estado de Orden" },
    { value: "state", label: "Provincia" },
    { value: "deliveryLocation", label: "Lugar de Entrega" },
    { value: "deliveryContact", label: "Contacto de Entrega" },
    { value: "phone", label: "Télefono" },
    { value: "email", label: "Email" },
    { value: "orderConfirmation", label: "Confirmacion de Orden" },
    { value: "actualDeliveryDate", label: "Fecha Real de Entrega", type: "date" },
    { value: "fineAmount", label: "Monto de Multa" },
    { value: "requestingUnit", label: "Unidad Solicitante" },
    { value: "accountContact", label: "Contacto de Cuenta" },
    { value: "emailAccount", label: "Email Cuenta" },
    { value: "vendorOrder", label: "Pedido del Proveedor" },
    { value: "documentLink", label: "Link del Documento" },
    { value: "comment", label: "Comentario" },
  ];
  const [state, setState] = useState({
    sizePerPage: 10
  });
  const { sizePerPage } = state;
  //#region Pagination 

  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => handleOnSetPage(i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }
  //Cambiar de pagina
  const handleOnSetPage = (page, who) => {
    setPage(page);
  };
  //Hook para refrescar la data de la tabla 
  useEffect(() => {
    const getRowsData = async () => {
      await handleRowData();
    }
    getRowsData();
  }, [refreshInput])
  //Funcion para traer las rows de la data de la tabla
  const handleRowData = async () => {
    const roles = getTeamsUserAccess();
    let isBF = false;
    if (roles.some((row) => row.indexOf("PanamaBids Display BF") !== -1)) {
      isBF = true;
    }
    dispatch(getAllPurchaseOrderMacro({ displayBf: isBF })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal(prevState => ({
          ...prevState,
          rows: payload.data.payload.data
        }))
      }
    })
  }
  const handleOnSetNumPagination = (e) => {
    setPage(1);
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      sizePerPage: value
    }))
  }
  //#endregion
  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="10">
              <p className="text-sm mb-0">
                Columnas Seleccionables
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="3">
              <MultiSelect
                options={optionsSelect}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">
              <tr>
                {selected.map((item, key) => {
                  return (
                    <th key={key} className="justify-content-md-center">
                      <FormGroup key={key}>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${item.label}:`}
                        </label>
                        <Input
                          id={item.id}
                          key={key}
                          className="form-control"
                          type="text"
                          placeholder={`Buscar ${item.label}...`}
                          onChange={(e) => filters(item.value, e.target.value)}
                        />
                      </FormGroup>
                    </th>
                  );
                })}

              </tr>
            </thead>
            <tbody className="list">

              {rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                <tr key={key} className="text-center">
                  {selected.map((col, key) => (
                    <td key={key} className="text-center">
                      {
                        col.value === "singleOrderRecord" ?
                          <a key={key} onClick={() => openModal(row)} style={{ color: "blue" }}>
                            {row[col.value]}
                          </a>
                          : col.type === "date" && moment.utc(row[col.value]).format('L') !== "Invalid date" ?
                            moment.utc(row[col.value]).format('L')
                            :
                            row[col.value]
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs='12' md='6' className="p-1">
                <Row className="justify-content-start">
                  <Col xs='12' md='6'>
                    <span className='pagination mb-0'>
                      Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                    </span>
                  </Col>
                  <Col xs='12' md='2'>
                    <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col xs='12' md='6' className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {
                    renderPaginations()
                  }
                  <PaginationItem className={page === paginations ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AgreementMainTable;
