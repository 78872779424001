/*!

=========================================================
*Autopp - SalesTeamItemForm
=========================================================

* Por cada ítem que agrega el reducer al state de SalesTeamList,
se realiza un map y en cada elemento renderiza un componente de este
tipo (SalesTeamItemForm), de esta forma se puede crear, editar y eliminar 
cada item de la lista.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/
//Imports de librerías de React
import React, { useContext, useState, useEffect } from 'react'

//Imports de elementos para el formulario
import { Button, Col, FormGroup, Row, Input } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";

//Import del contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Import de Spinner
import { Spin } from "antd";

export const SalesTeamItemForm = ({
    salesTItem,
    index,
    handleDeleteItem,
    handleAddInformationToSalesTeam, }) => {

    //#region States globales
    const { dropDownOptions, loadingEmployees, employeesOptions } = useContext(UserContext);
    //#endregion

    //#region States locales
    //State para guardar la información de manera local.
    const [newInfo, setNewInfo] = useState({})
    //Asignación de Campos del form.
    const [fieldsForm, setFieldsForm] = useState([
        {
            colWidth: "5",
            label: "Rol del colaborador*",
            placeholder: "Seleccione el rol",
            idFill: 'employeeRole1',
            id: 'employeeRole',
            defaultValue: '',
            type: "select",
            required: true,
            disabled: false,
        },
        {
            colWidth: "6",
            label: "Nombre del colaborador*",
            placeholder: "Digite el colaborador",
            id: "employees",
            type: "typeahead",
            required: true,
            disabled: false,
        },
    ]);
    //#endregion


    //#region Métodos útiles.

    //Cada vez que cambia newInfo, agrega la info a al campo information de SalesTeamList para cada respectivo item
    useEffect(() => {
        //Es un método que realiza un dispatch con un action que agrega la info actual en un arreglo global de todos los sales team.
        handleAddInformationToSalesTeam(salesTItem.id, newInfo);
    }, [newInfo])

    //Asigna el state del campo a NewInfo
    const handleChangeInfo = (constant, e) => {
        //Insert de elementos en arreglo local.
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: e,
        }))
    }

    //#endregion


    return (

        <>

            <Row className="p-1 pb-1 mt-3 mb-1 ">

                {fieldsForm.map((row, key) =>
                    row.type === "select" ? (
                        //Este case es para traer las opciones completas ya que no dependen de ningun dropdown
                        <Col xs="12" sm={row.colWidth} key={key}>
                            <FormGroup>
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {row.label}
                                </label>

                                <Input
                                    key={row.id + key}
                                    id={row.id + index}
                                    required={row.required}
                                    className="basic-multi-select text-dark"
                                    type={row.type}
                                    placeholder={row.placeholder}
                                    disabled={row.disabled}
                                    onChange={(e) => handleChangeInfo(row.id, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id })}
                                >
                                    <option id=""></option>
                                    {
                                        dropDownOptions[row.id] && dropDownOptions[row.id].map((value) => {
                                            if (value.label !== "Employee Responsible") {//Descartar employee responsible debido a que lo establece el componente Employee Resposible
                                                return (
                                                    <option id={value.value}>{value.label}</option>
                                                );
                                            }
                                        })}
                                </Input>
                                <div className="invalid-feedback">
                                    Este campo no puede estar vacío
                                </div>

                            </FormGroup>
                        </Col>
                    ) : (
                        //Tipo Typeahead
                        <Col xs="9" sm={row.colWidth} key={key} className="">
                            <FormGroup >
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {row.label}
                                </label>
                                <Spin size="small" spinning={loadingEmployees}>
                                    <Typeahead
                                        id={row.id + index}
                                        //labelKey={dropDownOptions => `${dropDownOptions.name}`}
                                        labelKey={employeesOptions => `${employeesOptions.name}`}
                                        minLength={0}
                                        required={row.required}
                                        //options={dropDownOptions["employees"]}
                                        options={employeesOptions}
                                        defaultInputValue={""}
                                        onChange={(e) => handleChangeInfo("employee", e[0])}
                                        renderMenuItemChildren={(value) => (
                                            <div >
                                                {value.name}
                                                <div>
                                                    {
                                                        //<small>Id: {value.id}</small>
                                                        <small>Id: {value.UserID}</small>}
                                                </div>
                                            </div>
                                        )}
                                    />
                                </Spin>
                                <div className="invalid-feedback">
                                    Este campo no puede estar vacío
                                </div>
                            </FormGroup>
                        </Col>
                    )
                )}

                <Col xs="3" sm="auto" className="mt-3  " >
                    <Button id={"btnDeleteEmployee" + index} className="btn-icon mt-3 " color="danger" size="sm" block type="button"
                        onClick={() => handleDeleteItem(salesTItem)}
                    >
                        <span className="btn-inner--icon mr-">
                            <i className="fas fa-trash" />
                        </span>
                    </Button>
                </Col>

            </Row>
            <hr className='mb--1 mt--1 ' />
        </>

    )
}

export default SalesTeamItemForm;
