import React, { useState } from 'react';
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Swal from "sweetalert2";
import MainPage from "components/BusinessSystem/MainPage";
import { Spin } from 'antd';

const Home = (props) => {

    const [loading, setLoad] = useState(true);

    return (
        <div>
            <AdminHeader name="Home" parentName="Business System" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    <MainPage setLoad={setLoad} />
                </Spin>
            </Container>
        </div>
    );
};


export default Home;