import React from 'react';
import PropTypes from 'prop-types';

// antd components
import { Spin } from 'antd';
import { Table, Button, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader, Card, Row, Col, Label, Input, CardBody, FormGroup, Pagination, PaginationItem, CardFooter, PaginationLink } from "reactstrap";
const ModalReplacement = props => {

    const {
        cols,
        rows,
        page,
        sizePerPage,
        onSetPage,
        contactReplacement,
        modalNewContact,
        handelOnToggleCloseModalReplacement,
        handleOnOpenModalNewContact,
        filter,
        loading,
        idSeleccionado
    } = props

    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1
    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => onSetPage(i, "pageLock")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }
    return (
        <div>
            <Modal
                size="lg"
                isOpen={modalNewContact}
                toggle={handleOnOpenModalNewContact}>

                <ModalHeader>Contacto sustituto</ModalHeader>
                <ModalBody>
                    <Spin size="large" spinning={loading}>
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0" >Filtros</h3>
                                <p className="text-sm mb-0">Filtros para contactos sustitutos</p>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {
                                        cols.map((row) => (
                                            row.value === "EMAIL" ?
                                                <></>
                                                :
                                                < Col >
                                                    <FormGroup>
                                                        <Label for="exampleEmail">{`${row.label}:`}</Label>
                                                        <Input onChange={(e) => filter(e, row.value)} id={row.value} type="text" />
                                                    </FormGroup>
                                                </Col>
                                        ))
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <Table
                                hover
                                className="align-items-center "
                                responsive
                            >
                                <div style={{ maxHeight: '450px', overflowY: 'auto', overflowX: 'hidden' }}>
                                    <thead >
                                        <tr style={{ position: "sticky", top: 0, zIndex: "10", backgroundColor: "white" }}>
                                            <th> Selecionado</th>
                                            {
                                                cols.map((row, key) => (
                                                    <th key={key}>
                                                        <label> {row.label} </label>
                                                    </th>
                                                )
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="list">
                                        {
                                            rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                                                row["ID_CONTACT_CRM"] === idSeleccionado["ID_CONTACT_CRM"] || row["STATUS"] === "X" ?
                                                    <></>
                                                    :
                                                    <tr id={key}>
                                                        <th>
                                                            <input name="radio" type="radio" onClick={() => contactReplacement(row)}></input>
                                                        </th>
                                                        {
                                                            cols.map((col, key) => (


                                                                < th key={key} >
                                                                    {row[col.value]}
                                                                </th>
                                                            ))
                                                        }

                                                    </tr>
                                            ))
                                        }
                                    </tbody>
                                </div>
                            </Table>
                            <CardFooter className="py-4">
                                <nav aria-label="...">
                                    <Row className="align-items-center">
                                        <Col xs='12' md='6' className="p-1">
                                            <span className='pagination mb-0'>
                                                Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                            </span>
                                        </Col>
                                        <Col xs='12' md='6' className="p-1">
                                            <Pagination
                                                className="pagination justify-content-end mb-0"
                                                listClassName="justify-content-end mb-0"
                                            >
                                                <PaginationItem className={page === 1 ? "disabled" : ""}>
                                                    <PaginationLink
                                                        onClick={() => onSetPage(page === 1 ? page : page - 1, "pageLock")}
                                                        tabIndex="-1"
                                                    >
                                                        <i className="fas fa-angle-left" />
                                                        <span className="sr-only">Previous</span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                                {
                                                    renderPaginations()
                                                }
                                                <PaginationItem className={page === paginations ? "disabled" : ""}>
                                                    <PaginationLink
                                                        onClick={() => onSetPage(page === paginations ? page : page + 1, "pageLock")}
                                                    >
                                                        <i className="fas fa-angle-right" />
                                                        <span className="sr-only">Next</span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </nav>
                            </CardFooter>
                        </Card>
                    </Spin>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="danger"
                        onClick={handelOnToggleCloseModalReplacement}>
                        <span className="btn-inner--icon mr-1">
                            <i className="fas fa-times" />
                        </span>Cancelar</Button>
                    <Button
                        color="info"
                        onClick={handleOnOpenModalNewContact} >
                        <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                        </span>Nuevo</Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

ModalReplacement.propTypes = {

};

export default ModalReplacement;