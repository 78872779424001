//Imports de React
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Row, Col, Button } from "reactstrap";
//usedispatch for fetch
import { useDispatch } from "react-redux";
//downloadFile
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// imports de actions
import { getConfirmRows } from "actions/contacts.jsx";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const ExportConstantsButton = ({

}) => {

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region Mensajes de notificacion

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    //#endregion

    //#region load state

    //#endregion
    
    //#region
    const columnTitles = [
        { value: "idCustomer", label: "Id Cliente" },
        { value: "customerName", label: "Nombre Cliente" },
        { value: "customerCountry", label: "País Cliente" },
        { value: "customerTerritory", label: "Territorio Cliente" },
        { value: "customerEmployeeName", label: "Empleado Responsable" },
        { value: "customerManager", label: "Gerente" },
    
        { value: "idContact", label: "Id Contacto" },
        // { value: "contactFullName", label: "Nombre Contacto" },
        { value: "personWhoConfirm", label: "Contacto Confirmado por" },
        { value: "dateToConfirm", label: "Fecha Confirmado en" },

    ]
    //#endregion

    const downloadConfirmFiles = (e, fileName) => {
      
        MySwal.fire({
            title: 'Espere mientras se genera el reporte de contactos confirmados (Esto puede tomar unos minutos)...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              MySwal.showLoading();
            },
          });
        dispatch(getConfirmRows()).then((resp) => {
            const { payload } = resp;
            console.log(payload)
            if (payload.status === 200) {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Sheet1');
                const data = payload.data.payload.rows;

                // Add custom column titles to the worksheet
                const headers = columnTitles.map((column) => column.label);
                worksheet.addRow(headers);

                // Filter data based on column titles and add data rows to the worksheet
                data.forEach((item) => {
                    const row = columnTitles.map((column) => item[column.value]);
                    worksheet.addRow(row);
                });

                // Generate and save the Excel file
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, fileName);
                });
            }
            else if (payload.status === 404) {
                Toast.fire({
                    title: "Atención",
                    html: "No se encontrarón registros",
                    type: "warning",
                });
            }
            else {
                Toast.fire({
                    title: "Atención",
                    html: "Error inesperado, por favor comuniquese con Application Managment",
                    type: "warning",
                });
            }
            MySwal.close();
        }).catch((error) => {
            console.log(error)
            alert('Error occurred during fetch:', error);
            // Handle error appropriately
          });
    }

    return (
        <>
            <Button color="success " onClick={(e) => downloadConfirmFiles(e, "ContactosConfirmados.xlsx")}>
                <span className="btn-inner--icon mr-1">
                    <i className="fas fa-download" />
                </span>Descargar Confirmados
            </Button>
        </>
    )
}

export default ExportConstantsButton;