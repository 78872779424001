// core actions
import { clearData, createUnplanned, findDataByPosition, findDataUpdateByPosition, findPositions, updateStaff, updateVacant, updateNewCecoPosition } from "actions/newPosition.jsx";
// javascript plugin that creates nice dropzones for files
import { message, Spin } from 'antd';
// core components NewPosition
import StaffForm from 'components/NewPosition/StaffForm.jsx';
import TypePosition from "components/NewPosition/TypePosition.jsx";
import UnplannedForm from 'components/NewPosition/UnplannedForm';
import VacantForm from 'components/NewPosition/VacantForm';
import NewCecoPositionForm from 'components/NewPosition/NewCecoPositionForm.jsx';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import NotFoundDB from "components/Shared/NotFoundDB/NotFoundDB";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// selectors
import { getUserProtected } from "selectors/adminLayout";
import { getCountrys, getInitialValuesUpdate, getPositions } from 'selectors/newPosition';
import { getDataByPositionToUpdate } from "selectors/newPosition";

/**
 * Componente para el manejo de las diferentes opciones disponibles para la creacion o modificacion de
 * posiciones por parte de capital humano
 * @class OptionalsPositions
 * @extends {Component}
 */
class OptionalsPositions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      position: "",
      positionSelected: false,
      files: [],
      uploading: false,
      loading: props.positions.length ? false : true
    };
  }

  componentWillMount() {
    if (!this.props.positions.length) {
      this.props.findPositions().then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        }
        this.setState({
          loading: false
        });
      });
    }
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  handleOnReload = () => {
    if (!this.props.positions.length) {
      this.setState({
        loading: true
      });
      this.props.findPositions().then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        }
        this.setState({
          loading: false
        });
      });
    }
  }

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para el manejo de cual opcion de creacion o modificacion de posicion se elije
   * @memberof OptionalsPositions
   */
  handleOnClick = event => {
    this.setState({
      position: event,
      positionSelected: true,
    });
  }

  /**
   * Funcion para regresar a la vista de escoger crear o modificar una posicion
   * @memberof OptionalsPositions
   */
  handleOnBack = () => {
    this.setState({
      position: '',
      positionSelected: false,
    });
  }

  /**
   * Funcion para la busqueda de la informacion relacionada a una posicion en especifico
   * @memberof OptionalsPositions
   */
  handleOnSearchDataByPosition = async values => {
    const { idPosition, idCountry } = values;
    await this.props.findDataByPosition(idPosition, idCountry).then(async res => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        await this.notify("success", "Éxito", payload.message);
      }
    });
  }

  convertFilesToBase64 = (files) => {
    for (const element of files) {
      const reader = new FileReader();
      reader.onloadend = (obj) => {
        element.base64 = obj.srcElement.result;
      };
      reader.readAsDataURL(element.originFileObj);
    }
    return files;
  }

  handleOnFilesList = async info => {
    const { status } = info.file;
    this.setState({
      uploading: true
    });
    if (status === 'done') {
      this.setState({
        files: this.convertFilesToBase64(info.fileList),
        uploading: false
      });
      message.success(`${info.file.name} archivo cargado exitosamente.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} la carga del archivo fallo.`);
    } else if (status === "removed") {
      this.setState({
        files: this.convertFilesToBase64(info.fileList),
        uploading: false
      });
      message.success(`${info.file.name} archivo quitado exitosamente.`);
    }
  }

  /**
   * Funcion para la creacion de una nueva posicion no planificada
   * @memberof OptionalsPositions
   */
  handleOnCreatedPositions = async values => {
    const { files } = this.state;
    const { EMAIL } = this.props.user;
    values.createdBy = EMAIL;
    await this.props.createUnplanned({
      values,
      files,
    }).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          files: []
        });
        await this.props.clearData();
      }
    });
  }


  /**
   * Funcion para buscar la informacion de una posicion en especifico para modificacion
   * @memberof OptionalsPositions
   */
  handleOnSearchDataUpdateByPosition = async values => {
    const { idPositionUser } = values;
    await this.props.findDataUpdateByPosition(idPositionUser).then(async res => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
    });
  }

  /**
   * Funcion para la modificion de una posicion con personal
   * @memberof OptionalsPositions
   */
  handleOnUpdateStaffPosition = async values => {
    const { files } = this.state;
    const { user: { EMAIL }, initialValues } = this.props;
    values.createdBy = EMAIL;
    await this.props.updateStaff({
      values,
      initialValues,
      files
    }).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          files: []
        });
        await this.props.clearData();
      }
    });
  }
  /**
   * Funcion para la modificion de una posicion vacante
   * @memberof OptionalsPositions
  */
  handleOnUpdateVacantPosition = async values => {
    const { EMAIL } = this.props.user;
    values.createdBy = EMAIL;
    // console.log(values);
    await this.props.updateVacant(values).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        await this.props.clearData();
      }
    });
  }
    /**
   * Funcion para la modificion de una posicion vacante
   * @memberof OptionalsPositions
  */
     handleOnUpdateNewCecoPosition = async values => {
      const { files } = this.state;
      const { EMAIL } = this.props.user;
      values.createdBy = EMAIL;
      // console.log(values);
      await this.props.updateNewCecoPosition ({values,files}).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
          await this.props.clearData();
        }
      });
    }

  /**
   * Limpiar la informacion del formulario
   * @memberof OptionalsPositions
   */
  handleOnCancelData = async () => {
    await this.props.clearData();
  }

  /**
   * Funcion para mostrar el componente de mantenimiento de posiciones
   * @memberof OptionalsPositions
   */
  handleOnSupportPosition = () => {
    this.props.history.push(`${this.props.match.url}/support-position`);
  }

  /**
   * Funcion para mostrar el componente de solicitudes de vacantes
   * @memberof OptionalsPositions
   */
  handleOnRequestView = () => {
    this.props.history.push(`${this.props.match.url}/work-flow`);
  }

  render() {

    const { positionSelected, position, loading, uploading } = this.state;

    const { positions, countrys, initialValues, data } = this.props;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Crear o modificar una nueva posición" parentName="Nueva Posición" />
        <Container className="mt--6" fluid>
          {!positionSelected ?
            (
              (positions.length && countrys.length) || loading ?
                <Spin size="large" spinning={loading}>
                  <Row className="justify-content-center">
                    <Col sm="12">
                      <Row>
                        <Col sm="12" md="3">
                          <TypePosition
                            icon="fas fa-user-plus"
                            type="unplanned"
                            title="Posición no Planificada."
                            subtitle="Creación de una nueva posición para un recurso no planificado."
                            keyworks={[
                              "Tipo de Posición",
                              "Nivel de Carrera",
                              "Fecha de Ingreso",
                              "Entre otros..."
                            ]}
                            onClick={this.handleOnClick}
                          />
                        </Col>
                        <Col sm="12" md="3">
                          <TypePosition
                            icon="fas fa-user-edit"
                            type="staff"
                            title="Posición con personal."
                            subtitle="Modificación de una posición ocupada por un recurso."
                            keyworks={[
                              "Nivel de Carrera",
                              "Área Funcional",
                              "Entre otros..."
                            ]}
                            onClick={this.handleOnClick}
                          />
                        </Col>
                        <Col sm="12" md="3">
                          <TypePosition
                            icon="fas fa-user-edit"
                            type="vacant"
                            title="Posición vacante."
                            subtitle="Modificación de una posición vacante por un recurso."
                            keyworks={[
                              "Nivel de Carrera",
                              "Fecha de Ingreso",
                              "Entre otros..."
                            ]}
                            onClick={this.handleOnClick}
                          />
                        </Col>
                        <Col sm="12" md="3">
                          <TypePosition
                            icon="fas fa-user-edit"
                            type="ceco"
                            title="Gestión de Centros de Costo."
                            subtitle="Asignación y reasignación de CECOS."
                            keyworks={[
                              "Centro de Costo",
                              "Area de Personal",
                              "Unidad Organizacional",
                              "Entre otros..."
                            ]}
                            onClick={this.handleOnClick}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Spin>
                :
                <NotFoundDB
                  title="Error inesperado en la carga."
                  body="No se logro cargar las posiciones y los paises de la base de datos debido a un error inesperado, por favor vuelva a intentarlo."
                  reload
                  onReload={this.handleOnReload}
                />
            )
            :
            (position === "staff" ?
              <StaffForm
                title="Posición con personal."
                subtitle="Modificación de una posición ocupada por un recurso."
                positionOptions={positions}
                onBack={this.handleOnBack}
                onSearch={this.handleOnSearchDataUpdateByPosition}
                onCancel={this.handleOnCancelData}
                onUpdate={this.handleOnUpdateStaffPosition}
                onFileList={this.handleOnFilesList}
                uploading={uploading}
              />
              : (position === "vacant" ?
                <VacantForm
                  title="Posición vacante."
                  subtitle="Modificación de una posición de la vacante de un recurso."
                  positionOptions={positions}
                  onBack={this.handleOnBack}
                  onSearch={this.handleOnSearchDataUpdateByPosition}
                  onCancel={this.handleOnCancelData}
                  onUpdate={this.handleOnUpdateVacantPosition}
                  onRequestView={this.handleOnRequestView}
                  initialValues={initialValues}
                  data={data}
                />
              :  (position === "ceco" ?
                <NewCecoPositionForm
                  title="Numero de Posición."
                  subtitle="Asignación y reasignación de CECOS."
                  onBack={this.handleOnBack}
                  onSearch={this.handleOnSearchDataUpdateByPosition}
                  onCancel={this.handleOnCancelData}
                  onUpdate={this.handleOnUpdateNewCecoPosition}
                  onFileList={this.handleOnFilesList}
                  onRequestView={this.handleOnRequestView}
                  initialValues={initialValues}
                  data={data}
                />
                : 
                <UnplannedForm
                  title="Posición no Planificada."
                  subtitle="Creación de una nueva posición de un recurso no planificado."
                  positionOptions={positions}
                  countrysOptions={countrys}
                  onBack={this.handleOnBack}
                  onSearch={this.handleOnSearchDataByPosition}
                  onCancel={this.handleOnCancelData}
                  onCreate={this.handleOnCreatedPositions}
                  onSupport={this.handleOnSupportPosition}
                  onFileList={this.handleOnFilesList}
                  uploading={uploading}
                />
                )
              )
            )
          }
        </Container>
      </>
    );
  }
}

OptionalsPositions.defaultProps = {
  positions: [],
  countrys: [],
  user: {}
}

OptionalsPositions.propTypes = {
  positions: PropTypes.array.isRequired,
  countrys: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  positions: getPositions(state),
  countrys: getCountrys(state),
  user: getUserProtected(),
  initialValues: getInitialValuesUpdate(state),
  data: getDataByPositionToUpdate(state),
  // initialValues: getInitialValuesUpdate(state),
});

export default withRouter(connect(
  mapStateToProps,
  {
    clearData,
    createUnplanned,
    findPositions,
    findDataByPosition,
    findDataUpdateByPosition,
    updateStaff,
    updateVacant,
    updateNewCecoPosition,
  })(OptionalsPositions));
