import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Row,
    Button,
    Form
} from "reactstrap";
import { updateInterview, sendEmailToHCM } from "actions/exitInterview"
import { useDispatch } from "react-redux";

const CompleteInverviewMainTable = ({
    handleOnChangeInfo,
    info, option, updateInfo
}) => {
    const dispatch = useDispatch();
    const navigate = useHistory();

    const rowsModal = [
        {
            colWidth: "4",
            label: "Id de Colaborador",
            id: "idCollaborator",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Nombre de Colaborador",
            id: "collaboratorName",
            value: "name",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Jefe Inmediato",
            id: "immediateBoss",
            value: "manager",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Departamento",
            id: "department",
            value: "department",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Tiempo Laborado",
            id: "timeWorked",
            value: "timeWorked",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Fecha",
            id: "date",
            type: "date",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Pais",
            id: "country",
            name: "countryName",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Tipo de Salida",
            name: "exitReasonName",
            id: "exitReason",
            type: "select",
            disabled: false,
        },
        {
            colWidth: "4",
            label: "Razón de Salida",
            name: "exitTypeId",
            id: "exitType",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Entrevistador",
            id: "interviewer",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "6",
            label: "Comentarios",
            id: "comment",
            type: "text",
            disabled: false,
        },
        {
            colWidth: "4",
            label: "Volveria usted a GBM?",
            name: "goBackGBM",
            id: "goBackGBM",
            type: "select",
            disabled: false,
        },
        {
            colWidth: "12",
            label: "¿Qué pudo haber hecho GBM para que usted no hubiera valorado salir de la compañia?",
            id: "doNotValueGBM",
            disabled: false,
            type: "text",
        },
    ];
    const handleOnSaveInterview = () => {
        let cont = 0;
        const forms = document.querySelectorAll(".isValid input, select");
        Array.from(forms).forEach((input) => {
            if (input.required === true && (input.value === "" || input.value === "---")) {
                input.classList.add("is-invalid");
                cont++;
            } else {
                input.classList.remove("is-invalid");
            }
        });

        if (cont === 0) {
            updateInfo["status"] = 2;
            dispatch(updateInterview({ info: updateInfo, id: info.idInterview })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    dispatch(sendEmailToHCM({ info: info })).then((res) => {
                        const { payload } = res;
                        if (payload.status === 200) {
                            navigate.push("/admin");
                        }
                    });
                }
                const forms = document.querySelectorAll(".isValid input, select");
                Array.from(forms).forEach((input) => { input.classList.remove("is-invalid") });
            });
        };

    };

    const [isRenounce, setIsRenounce] = useState(false);
    const [isOtherExitReason, setIsOtherExitReason] = useState(false);
    useEffect(() => {
        if (info.exitType === "Renuncia") {
            setIsRenounce(true);
        }
        setIsOtherExitReason(containsExitReason("Otros"));
    }, [info]);
    const containsExitReason = (word) => {
        let cont = 0;
        if (info.exitReason) {
            info.exitReason.map(item => item.label === word && cont++)
            if (cont > 0) {
                return true;
            } else {
                return false;
            }
        }
    }
    console.log(info)
    return (
        <div>
            <Card>
                <CardBody>
                    <Form noValidate className="isValid needs-validation">
                        <Row>
                            {rowsModal.map((row, key) =>
                                row.type === "text" ?
                                    <>
                                        <Col xs={row.colWidth} key={key}>
                                            <FormGroup key={key}>
                                                <label
                                                    style={{ fontSize: "12px" }}
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                >
                                                    {`${row.label}:`}
                                                </label>
                                                <Input
                                                    id={row.id}
                                                    disabled={row.disabled}
                                                    key={key}
                                                    required={true}
                                                    className="form-control"
                                                    type={row.type}
                                                    defaultValue={info[row.id] ? (info[row.id]) : ""}
                                                    onChange={(e) =>
                                                        handleOnChangeInfo(row.id, e.target.value)
                                                    }
                                                />
                                                <div className="invalid-feedback">
                                                    Este campo no puede estar vacio
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </>
                                    : row.type === "select" ?
                                        row.id === "exitReason" ?
                                            <>
                                                <Col xs={row.colWidth} key={key}>
                                                    <FormGroup key={key}>
                                                        <label
                                                            style={{ fontSize: "12px" }}
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {row.label}
                                                        </label>
                                                        <Select
                                                            style={{ border: "red" }}
                                                            className="basic-single is-invalid"
                                                            type="select"
                                                            key={key}
                                                            isSearchable
                                                            isMulti
                                                            defaultValue={row["exitReasonInterview"]}
                                                            options={option.masterData[row.id]}
                                                            onChange={(e) => handleOnChangeInfo(row.id, e)}
                                                        />

                                                        <div className="invalid-feedback">
                                                            Este campo no puede estar vacio
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                {isOtherExitReason == true ?
                                                    < Col xs={4} key={`isOtherExitReason${key}`}>
                                                        <FormGroup key={`isOtherExitReason${key}`}>
                                                            <label
                                                                style={{ fontSize: "12px" }}
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {`Especificación de tipo de salida:`}
                                                            </label>
                                                            <Input
                                                                key={`isOtherExitReason${key}`}
                                                                id={"specify"}
                                                                required={true}
                                                                className="form-control"
                                                                type={"text"}
                                                                onChange={(e) =>
                                                                    handleOnChangeInfo("specify", e.target.value)
                                                                }
                                                            />
                                                            <div className="invalid-feedback">
                                                                Este campo no puede estar vacio
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    : <></>}
                                            </>
                                            :
                                            <Col xs={row.colWidth} key={key}>
                                                <FormGroup key={key}>
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${row.label}:`}
                                                    </label>
                                                    <Input
                                                        key={key}
                                                        required={true}
                                                        type={"select"}
                                                        className="basic-multi-select text-dark"
                                                        defaultValue={info[row.id] ? (info[row.id]) : ""}
                                                        onChange={(e) => handleOnChangeInfo(row.name, e.target.children[e.target.selectedIndex].id)}
                                                    >
                                                        {option[row.id].map((value) => {
                                                            return (
                                                                <option id={value.value} key={value.label}>{value.label}</option>
                                                            );
                                                        })}
                                                    </Input>
                                                    <div className="invalid-feedback">
                                                        Este campo no puede estar vacio
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                        : (
                                            <Col xs={row.colWidth} key={key}>
                                                <FormGroup key={key}>
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${row.label}:`}
                                                    </label>
                                                    <Input
                                                        id={row.id}
                                                        disabled={row.disabled}
                                                        key={key}
                                                        type={row.type}
                                                        max={new Date().toISOString().split('T')[0]}
                                                        defaultValue={new Date().toISOString().split('T')[0]}
                                                        onChange={(e) =>
                                                            handleOnChangeInfo(row.id, e.target.value)
                                                        }
                                                        className="basic-multi-select text-dark "
                                                    ></Input>
                                                    <div className="invalid-feedback">
                                                        Este campo no puede estar vacio
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        )
                            )}
                            {
                                isRenounce === true &&
                                <>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                style={{ fontSize: "12px" }}
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >
                                                {"¿Qué tipo de compañía es su nueva oferta laboral?"}
                                            </label>

                                            <Input
                                                required={true}
                                                type={"select"}
                                                key={"companyType"}
                                                options={option.masterData["companyType"]}
                                                className="basic-multi-select text-dark"
                                                defaultValue={info["company"] ? (info["company"]) : ""}
                                                onChange={(e) => handleOnChangeInfo("companyType", e.target.children[e.target.selectedIndex].id)}
                                            >
                                                {option["companyType"].map((value) => {
                                                    return (
                                                        <option id={value.value} key={value.label}>{value.label}</option>
                                                    );
                                                })}
                                            </Input>
                                            <div className="invalid-feedback">
                                                Este campo no puede estar vacio
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup >
                                            <label
                                                style={{ fontSize: "12px" }}
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >
                                                {`¿Qué beneficios adicionales o pluses adicionales ofrece la nueva oferta laboral?`}
                                            </label>
                                            <Input
                                                key={"benefitsJob"}
                                                required={true}
                                                className="form-control"
                                                type={"text"}
                                                defaultValue={info["benefitsJob"] ? (info["benefitsJob"]) : ""}
                                                onChange={(e) =>
                                                    handleOnChangeInfo("benefitsJob", e.target.value)
                                                }
                                            />
                                            <div className="invalid-feedback">
                                                Este campo no puede estar vacio
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row className="align-item-center d-flex justify-content-center">
                            <Col s="12" md="2">

                                <Button
                                    className="btn btn-icon"
                                    color="success"
                                    block
                                    type="button"
                                    onClick={() => handleOnSaveInterview()}
                                >Enviar </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
};


export default CompleteInverviewMainTable;