// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const StatusModal = props => {

  const {
    title,
    options: {
      type,
      data,
      showModal,
    },
    loading,
    toggleModal,
    onSubmit,
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.name)
      errors.name = "Debes ingresar el nombre del estado.";
    return errors;
  };

  return (

    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={(e) => onSubmit(e, type)}
              validate={validate}
              initialValues={data}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label for="name">Nombre del Estado</Label>
                      <Field name="name">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Ingrese aquí el nombre del estado."
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {type === 'add' ? 'Agregar Estado' : 'Modificar Estado'}
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

StatusModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default StatusModal;