// javascript plugin that creates nice dropzones for files
import { Icon, Upload } from "antd";
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useState } from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

const { Dragger } = Upload;

const ResumeOffers = (props) => {
  const {
    title,
    subtitle,
    wayPay,
    // type,
    // opp,
    country,
    resume,
    offersCalcs,
    idRequest,
    state,
    fileList,
    onFileList,
    onSubmit,
    onBack,
    onOfferWon,
    generateDocuments,
  } = props;

  const [ajust, setAjust] = useState(false);
  const [provision, setProvision] = useState(false);
  const [price, setPrice] = useState(false);
  const [both, setBoth] = useState(false);

  const uploadProps = {
    name: "reference",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    multiple: true,
    action: `${urlBase}/digital-request/upload-reference-ajust-offer/${idRequest}`,
    onChange: onFileList,
  };

  const numberWithCommas = (x) => {
    if (x >= 0) return `$${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    else return null;
  };

  const onChangeAjust = () => {
    setAjust(!ajust);
    setBoth(false);
    setProvision(false);
    setPrice(false);
  };

  const onChageTypeAjust = (event) => {
    const { id } = event.target;
    if (id === "both") {
      setBoth(true);
      setProvision(false);
      setPrice(false);
    } else if (id === "provision") {
      setBoth(false);
      setProvision(true);
      setPrice(false);
    } else if (id === "price") {
      setBoth(false);
      setProvision(false);
      setPrice(true);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!price)
      if (!values.provisionAmount || values.provisionAmount <= 0)
        errors.provisionAmount = "Debes ingresar el monto de provisión.";
    if (!values.justification || values.justification === "")
      errors.justification = "Debes ingresar la justificación.";
    return errors;
  };
  console.log(offersCalcs);
  const totalCost = (list) => {
    let total = 0;
    for (const element of list) {
      total = total + element.totalCost;
    }
    return total;
  };

  const { totalEquipment, totalSpareParts } = resume;

  return (
    <Row className="justify-content-center">
      <Col lg="12">
        <Row>
          <Col sm="12" lg={state < 0 ? "7" : "12"}>
            <Card className="shadow border-0">
              <CardBody className="py-4">
                <div>
                  <Row>
                    <Col
                      sm="12"
                      md="8"
                      className="align-items-center"
                      style={{ display: "grid" }}
                    >
                      <h4 className="text-center surtitle text-success">
                        {title} - {wayPay}
                      </h4>
                      <h3 className="h3 text-center text-info text-uppercase">
                        {subtitle}
                      </h3>
                    </Col>
                    <Col sm="12" md="4" className="justify-content-center">
                      <Row>
                        {state >= 13 && <Col sm="6"></Col>}
                        <Col sm="6">
                          <Card className="shadow border-0">
                            <Button
                              block
                              size="sm"
                              className="btn-icon btn-3"
                              color="danger"
                              type="button"
                              onClick={onBack}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-arrow-left" />
                              </span>
                              <span className="btn-inner--text">Salir</span>
                            </Button>
                          </Card>
                        </Col>
                        {state < 13 && (
                          <Col sm="6">
                            <Card className="shadow border-0">
                              <Button
                                block
                                size="sm"
                                className="btn-icon btn-3"
                                color="success"
                                type="button"
                                disabled={
                                  totalCost(offersCalcs.equipmentsCalc) <
                                  totalCost(offersCalcs.sparePartsCalc)
                                }
                                onClick={onOfferWon}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-hands-helping" />
                                </span>
                                <span className="btn-inner--text">
                                  Oferta Ganada
                                </span>
                              </Button>
                            </Card>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Card className="shadow border-0">
                            <Button
                              block
                              size="sm"
                              className="btn-icon btn-3"
                              color="info"
                              type="button"
                              disabled={
                                totalCost(offersCalcs.equipmentsCalc) <
                                totalCost(offersCalcs.sparePartsCalc)
                              }
                              onClick={generateDocuments}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-envelope" />
                              </span>
                              <span className="btn-inner--text">
                                Generar Documentos
                              </span>
                            </Button>
                          </Card>
                        </Col>
                      </Row>
                      {totalCost(offersCalcs.equipmentsCalc) <
                        totalCost(offersCalcs.sparePartsCalc) && (
                        <Row className="p-0 m-0">
                          <Col sm="12">
                            <p className="h4" style={{ color: "red" }}>
                              El costo de las partes agregadas sobrepasa el
                              costo inicial de los equipos
                            </p>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </div>
                <Row className="mt-1">
                  <Col sm="12" md="8" className="d-flex justify-content-center">
                    <img
                      style={{
                        width: "150px",
                      }}
                      alt="..."
                      src={require("assets/img/icons/common/gbm-logo.svg")}
                    />
                  </Col>
                  <Col sm="12" md="4">
                    {/* <Row className="py-1 align-items-center">
                      <Col xs="6" className="d-flex justify-content-end">
                        <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                          Servicio
                        </h5>
                      </Col>
                      <Col xs="6" className="d-flex justify-content-center">
                        <h3 className="heading mb-0">
                          {resume.officeHours}
                        </h3>
                      </Col>
                    </Row> */}
                    <Row className="py-1 align-items-center">
                      <Col xs="6" className="d-flex justify-content-end">
                        <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                          País
                        </h5>
                      </Col>
                      <Col xs="6" className="d-flex justify-content-center">
                        <h3 className="heading mb-0">{country}</h3>
                      </Col>
                    </Row>
                    <Row className="py-1 align-items-center">
                      <Col xs="6" className="d-flex justify-content-end">
                        <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                          Años
                        </h5>
                      </Col>
                      <Col xs="6" className="d-flex justify-content-center">
                        <h3 className="heading mb-0">
                          {resume.validityService}
                        </h3>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Table
                  className="align-items-center mt-2"
                  responsive
                >
                  <thead className="thead-light">
                    <tr className="text-center">
                      <th>Servicio</th>
                      <th>Precio</th>
                      <th>Cuota</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    <tr>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        Equipos
                      </th>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        {numberWithCommas(resume.totalEquipment.totalPrice.toFixed(2))}
                      </th>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        {numberWithCommas(resume.totalEquipment.totalPayment.toFixed(2))}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        Servicios
                      </th>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        {numberWithCommas(resume.totalServices.totalPrice.toFixed(2))}
                      </th>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        {numberWithCommas(resume.totalServices.totalPayment.toFixed(2))}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        Partes / Spare
                      </th>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        {numberWithCommas(resume.totalSpareParts.totalPrice.toFixed(2))}
                      </th>
                      <th style={{ whiteSpace: "normal", textAlign: "center", "borderTop": "0px" }}>
                        {numberWithCommas(resume.totalSpareParts.totalPayment.toFixed(2))}
                      </th>
                    </tr>
                  </tbody>
                </Table> */}
                <Table className="align-items-center mt-2" responsive>
                  <thead className="thead-light">
                    <tr className="text-center">
                      <th>Cantidad Equipos</th>
                      <th>País</th>
                      <th>Tipo-Modelo</th>
                      <th>Serie</th>
                      <th>Plataforma</th>
                      <th>Horario Atención</th>
                      <th>Tiempo Cambio Partes</th>
                      <th>Vigencia</th>
                      <th>Renovación Automatica</th>
                      <th>Incluye Partes</th>
                      <th>Cuota</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {resume.equipments.map((row, key) => (
                      <tr key={key}>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.amountEquipments}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.country}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.typeModel}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.serial}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.platform}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.officeHour}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.timeChangePart}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.validityService}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.automaticRenewal}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {row.equipmentParts}
                        </th>
                        <th
                          style={{
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderTop: "0px",
                          }}
                        >
                          {numberWithCommas(row.payment.toFixed(2))}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row className="mt-1">
                  <Col sm="6"></Col>
                  <Col sm="6">
                    <Row className="py-1 align-items-center">
                      <Col xs="6" className="d-flex justify-content-end">
                        <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                          Cuota
                        </h5>
                      </Col>
                      <Col xs="6" className="d-flex justify-content-center">
                        <h3 className="heading mb-0">
                          {numberWithCommas(
                            (
                              resume.totalEquipment.totalPayment +
                              resume.totalServices.totalPayment +
                              resume.totalSpareParts.totalPayment
                            ).toFixed(2)
                          )}
                        </h3>
                      </Col>
                    </Row>
                    <Row className="py-1 align-items-center">
                      <Col xs="6" className="d-flex justify-content-end">
                        <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                          Precio Total
                        </h5>
                      </Col>
                      <Col xs="6" className="d-flex justify-content-center">
                        <h3 className="heading mb-0">
                          {numberWithCommas(
                            (
                              resume.totalEquipment.totalPrice +
                              resume.totalServices.totalPrice +
                              resume.totalSpareParts.totalPrice
                            ).toFixed(2)
                          )}
                        </h3>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {state < 0 && (
            <Col sm="12" lg="5">
              <Row>
                <Col sm="12">
                  <Card className="shadow border-0">
                    <CardBody className="py-4">
                      <div className="icon icon-shape bg-gradient-danger text-white rounded-circle mb-4">
                        <i className="ni ni-settings" />
                      </div>
                      <Row>
                        <div className="col">
                          <h4 className="h3 text-danger text-uppercase">
                            Solicitar Ajuste
                          </h4>
                        </div>
                        <Col className="col-auto">
                          <FormRS>
                            <label className="custom-toggle mr-1">
                              <input
                                id="ajust"
                                checked={ajust}
                                type="checkbox"
                                onChange={onChangeAjust}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Sí"
                              />
                            </label>
                          </FormRS>
                        </Col>
                      </Row>
                      {ajust && (
                        <Row className="mt-2">
                          <FormRS>
                            <Row className="m-1">
                              <Col sm="4">
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="provision"
                                    name="custom-radio-1"
                                    type="radio"
                                    checked={provision}
                                    onChange={(e) => onChageTypeAjust(e)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="provision"
                                  >
                                    Provisión
                                  </label>
                                </div>
                              </Col>
                              <Col sm="4">
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="price"
                                    name="custom-radio-1"
                                    type="radio"
                                    checked={price}
                                    onChange={(e) => onChageTypeAjust(e)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="price"
                                  >
                                    Ajuste de Precio
                                  </label>
                                </div>
                              </Col>
                              <Col sm="4">
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="both"
                                    name="custom-radio-1"
                                    type="radio"
                                    checked={both}
                                    onChange={(e) => onChageTypeAjust(e)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="both"
                                  >
                                    Ambas
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </FormRS>
                        </Row>
                      )}
                      {(provision || price || both) && (
                        <Form
                          onSubmit={(e) => onSubmit(e, provision, price, both)}
                          validate={validate}
                          // initialValues={initialValues}
                          render={({
                            handleSubmit,
                            values,
                            submitting,
                            validating,
                            valid,
                          }) => (
                            <FormRS role="form">
                              {!price && (
                                <FormGroup>
                                  <Label for="provisionAmount">
                                    Monto de Provisión
                                  </Label>
                                  <Field name="provisionAmount">
                                    {({ input, meta }) => (
                                      <div>
                                        <Input
                                          {...input}
                                          max={1000}
                                          min={0}
                                          type="number"
                                          placeholder="Ingrese el monto de la provisión"
                                          valid={
                                            !meta.error &&
                                            meta.modified &&
                                            meta.touched
                                          }
                                          invalid={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className="invalid-feedback">
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </FormGroup>
                              )}
                              <FormGroup>
                                <Label for="justification">
                                  Justificación (250)
                                </Label>
                                <Field name="justification">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="textarea"
                                        placeholder="Indique aquí el motivo del ajuste."
                                        maxLength={250}
                                        rows="3"
                                        resize="none"
                                        valid={
                                          !meta.error &&
                                          meta.modified &&
                                          meta.touched
                                        }
                                        invalid={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="invalid-feedback">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Label for="files">Adjuntar Archivos</Label>
                                <Dragger {...uploadProps} fileList={fileList}>
                                  <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                  </p>
                                  <p className="ant-upload-text">
                                    Suelte los archivos aquí o haga clic para
                                    cargar.
                                  </p>
                                  <p className="ant-upload-hint">
                                    Cargar todos aquellos archivos recolectados
                                    como muestra de evidencia de la solicitud
                                    que está realizando.
                                  </p>
                                </Dragger>
                              </FormGroup>
                              <div className="text-center">
                                <Button
                                  className="btn-icon btn-3"
                                  color="success"
                                  type="submit"
                                  onClick={handleSubmit}
                                  disabled={validating || submitting}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fas fa-sync-alt" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Continuar
                                  </span>
                                </Button>
                              </div>
                            </FormRS>
                          )}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
                {!ajust && (
                  <>
                    <Col sm="6">
                      <Card className="shadow border-0">
                        <Button
                          className="btn-icon btn-3"
                          color="danger"
                          type="button"
                          onClick={onBack}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-arrow-left" />
                          </span>
                          <span className="btn-inner--text">Salir</span>
                        </Button>
                      </Card>
                    </Col>
                    <Col sm="6">
                      <Card className="shadow border-0">
                        <Button
                          className="btn-icon btn-3"
                          color="success"
                          type="button"
                          onClick={onOfferWon}
                          disabled={
                            totalCost(offersCalcs.equipmentsCalc) <
                            totalCost(offersCalcs.sparePartsCalc)
                          }
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-hands-helping" />
                          </span>
                          <span className="btn-inner--text">Oferta Ganada</span>
                        </Button>
                      </Card>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

ResumeOffers.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ResumeOffers;
