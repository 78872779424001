/*
=========================================================
*Notifications - Extra Hours report pending to approve
=========================================================

*Description.
This component creates a modal where preapprover or approver can reject an extra hours report

=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

function ModalRejectReason({ isOpen, handleClose, buttonOnClick, onChange }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader toggle={handleClose}>
        <span className="font-weight-bold" style={{ fontSize: "1.20em" }}>
          Rechazando reporte
        </span>
      </ModalHeader>

      <ModalBody>
        <Card className="border-0 shadow">
          <CardBody>
            <Row className="align-items-center">
              <Col xs={12} sm={8}>
                <FormGroup>
                  <Label className="font-weight-bold">
                    Ingrese el motivo del rechazo (max. 300)
                  </Label>
                  <Input
                    id="text-input"
                    type="textarea"
                    className="form-control"
                    autoComplete="off"
                    maxLength={300}
                    resize="none"
                    style={{
                      color: "black",
                      borderColor: "#dee2e6",
                      height: "6rem",
                    }}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={4}>
                <Button
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    backgroundColor: "#2dce89",
                    color: "white",
                  }}
                  onClick={buttonOnClick}
                >
                  Enviar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button
          style={{ backgroundColor: "#1275bc", color: "white" }}
          type="button"
          onClick={handleClose}
        >
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalRejectReason;
