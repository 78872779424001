import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DashboardsComponent from 'components/ExitInterview/Dashboard/Dashboards';
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

const Dashboards = () => {
   
    return (
        <div>
            <AdminHeader name="Entrevistas de Salida" parentName="Administracion de Categorias" />
            <Container className="mt--6" fluid>
                <DashboardsComponent/>
            </Container>
        </div>
    );
};


export default Dashboards;
