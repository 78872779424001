import moment from "moment";
import "moment/locale/es";
moment.locale("es");
export const allOrdersSelector = state => state.allOrdersData;
export const ordersByCountrySelector = state => state.ordersByCountryData;
export const ordersByUserSelector = state => state.ordersByUserData;
//export const orderDetailsSelector = state => state.orderDetailsData;

export const orderDetailsSelector = state => {
  let data = state.orderDetailsData;
  data.formatedOffsetTime = moment(state.orderDetailsData.createdAt)
    .utc()
    .utcOffset(moment().utcOffset())
    .format("LLLL");
  data.formatedOffsetEndTime = moment(state.orderDetailsData.updatedAt)
    .utc()
    .utcOffset(moment().utcOffset())
    .format("LLLL");
  if (data.timeline) {
    // console.log(data.timeline);
    for (var x = 0; x < data.timeline.length; x++) {
      data.timeline[x].formatedDateTime = moment(data.timeline[x].createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("LLL");
    }
  }
  return data;
};
