/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to show all role sections at HC access modules
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/
import React from "react";
import PropTypes from "prop-types";
import RolCards from "./RolCards";
import { Container, Row, Col } from "reactstrap";

function RolManagementOptions({ changeOption }) {
  return (
    <Container className="d-flex justify-content-center">
      <Row>
        <Col xs={12} md={5} className="mb-3">
          <RolCards
            title="Asignar Rol Horas Extra"
            subtitle="Permite a un usuario reportar horas extra"
            id="ExtraHoursRol"
            image="Extra_Hours_Image.png"
            onSelect={changeOption}
          />
        </Col>
        <Col xs={12} md={5} className="mb-3">
          <RolCards
            title="Asignar Rol Preaprobador"
            subtitle="Permite a un usuario ejercer el rol de preaprobador"
            id="PreapproverRol"
            image="preapprover.png"
            onSelect={changeOption}
          />
        </Col>
      </Row>
    </Container>
  );
}

RolManagementOptions.propTypes = {
  changeOption: PropTypes.func.isRequired,
};

export default RolManagementOptions;
