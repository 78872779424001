/*!

=========================================================
*ItRequests
=========================================================

*Este componente es una ventana que aparace para confirmar cualquier accion generica
=========================================================

* Coded by Sergio Marin  

*/
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const GenericModal = ({ showModal, setShowModal, msg }) => {
    return (
        <>
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalHeader toggle={() => setShowModal(false)}>Confirmación acción</ModalHeader>
                <ModalBody>{msg}</ModalBody><ModalFooter>
                    <Button color="primary" onClick={() => setShowModal(false)}>Continuar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
export default GenericModal;