/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Main" de HES para la visualización y creación de las handleUnit en SAP (este componente
     solo lo visualiza los administradores)

*El cual llama los siguientes componentes:
    FiltersMain: Componente compartido para filtrar la tabla principal de asignaciones de ordenes de compra.
    MainTable: Componente compartido para mostrar las asignaciones de las ordenes de compra.
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    MySwal: funcion para las notificaciones personalizadas
=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState } from 'react';
//customized componentes
import FiltersMain from 'components/Freelance/SharedComponents/FiltersMain.jsx';
import MainTable from 'components/Freelance/SharedComponents/MainTable.jsx';
//accion para crear hes, reprocesar hes cuando dan error
import { hesActions } from 'actions/Freelance.jsx';
//usedispatch for fetch
import { useDispatch } from 'react-redux';
//url api
import urls from "api/urls.jsx";

import { encrypter } from 'helpers/desencrypt.jsx';

const MainHes = ({
    setLoad,
    Toast,
    dropDownOptions,
    rolArea,
    isAdmin,
    MySwal,
}) => {

    //#region states and functions

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region State para guardar los filtros seleccionados según cada tabla en FilterMain
    const [search, setSearch] = useState({});

    const handleOnChangeFilter = (constant, valor) => {
        setSearch((prevState) => ({
            ...prevState,
            [constant]: valor
        }))
    }
    //#endregion

    //#region state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);
    //#endregion

    //#region state para recargar la tabla principal cuando se necesite
    const [reloadTable, setRealTable] = useState(false);
    //#endregion

    //#region funcion para buscar las nuevas solicitudes de hes
    const handleSearch = () => {
        setPage(1);
        setRealTable((prevState) => (!prevState));
    }
    //#endregion

    //#region campos del filtro para el componente FilterMain
    const fields = [
        {
            colWidth: "6",
            label: "Área de negocio",
            placeholder: "Seleccione el Área de negocio",
            id: "purcharseOrderAssignation.area",
            options: "billsArea",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Orden de Compra",
            placeholder: "Seleccione la Orden de Compra",
            id: "purcharseOrderAssignation.purchaseOrder",
            options: "purchaseOrderAssignation",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Estado",
            placeholder: "Seleccione el Estado",
            id: "handleUnitMain.status",
            options: "handleUnitStatus",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Proveedores",
            placeholder: "Seleccione el Proveedor",
            id: "purcharseOrderAssignation.consultant",
            options: "accessFreelance",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Proyecto",
            placeholder: "Seleccione el Proyecto",
            id: "purcharseOrderAssignation.project",
            options: "assignationProjects",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Responsable",
            placeholder: "Seleccione el responsable",
            id: "purcharseOrderAssignation.responsible",
            options: "Responsables",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select",
          },
        {
            colWidth: "6",
            label: "Fecha Desde",
            placeholder: "Seleccione la fecha",
            id: "toDate",
            type: "date",
            disabled: false,
            visibleDefault: false,
            elementType: "StartDate"
        },
        {
            colWidth: "6",
            label: "Fecha Hasta",
            placeholder: "Seleccione la fecha",
            id: "toDate",
            type: "date",
            disabled: false,
            visibleDefault: false,
            elementType: "EndDate"
        },
    ];
    //#endregion

    //#region Botones del FilterMain para ejecutar las funciones anteriores
    const buttons = [
        {
            color: "primary",
            icon: "fas fa-search",
            title: "Buscar HES",
            function: handleSearch,
            type: "button",
            colWidth: "12"
        },
    ];
    //#endregion

    //#region funcion para crear una handleUnit (hoja) manualmente
    const createPage = (e, row, dropDown) => {
        const createPageSwal = MySwal.fire({
            title: `Desea crear la hoja HES de la Purchase Order ${row["purchaseOrderItem"]}?`,
            // text: "Se puede reversar con ayuda de Application Management",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, crear.',
            confirmButtonColor: '#2dce89',
            cancelButtonText: 'No, cancelar',
        }).then((result) => {
            if (result.value) {
                // setLoad(true);
                var info = {
                    row: row,
                    action: "c"
                };
                dispatch(hesActions({ info: info })).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {
                        setRealTable(prevCheck => !prevCheck);
                        Toast.fire({
                            title: "Atención",
                            html: payload.message,
                            type: 'success'
                        });
                    } else {
                        Toast.fire({
                            title: "Atención",
                            html: payload.message,
                            type: 'warning'
                        });
                    }
                    setLoad(false);
                })
            }
        })
    }
    //#endregion

    //#region funcion para reprocesar una hoja HES cuando da error en SAP
    const reprocessPage = (e, row, dropDown) => {
        const rePageSwal = MySwal.fire({
            title: `Desea reprocesar la hoja HES de la Purchase Order ${row["purchaseOrderItem"]}?`,
            // text: "Se puede reversar con ayuda de Application Management",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, reprocesar.',
            confirmButtonColor: '#2dce89',
            cancelButtonText: 'No, cancelar',
        }).then((result) => {
            if (result.value) {
                // setLoad(true);
                var info = {
                    row: row,
                    action: "r"
                };
                dispatch(hesActions({ info: info })).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {
                        setRealTable(prevCheck => !prevCheck);
                        Toast.fire({
                            title: "Atención",
                            html: payload.message,
                            type: 'success'
                        });
                    } else {
                        Toast.fire({
                            title: "Atención",
                            html: payload.message,
                            type: 'warning'
                        });
                    }
                    setLoad(false);
                })
            }
        })
    }
    //#endregion

    //#region funcion para descargar la imagen de error de una hes cuando aplique
    const errorDetail = (e, row, dropDown) => {
        const downloadUrl = urls.FREELANCE.downloadFile + `/${encrypter(row.id)}/${encrypter("1")}`;

        fetch(downloadUrl, {
                    method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
                })
                    .then(response => {
                        if (response.ok) {
                            window.location.assign(downloadUrl);
                        } else {
                            Toast.fire({
                                title: "Atención",
                                html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
                                type: "warning",
        
                            });
                        }
                    })
                    .catch(error => {
                        console.error('Error checking file existence:', error);
                        Toast.fire({
                            title: "Atención",
                            html: "Ocurrio un error al validar el archivo ",
                            type: "warning",
        
                        });
                    });
    }
    //#endregion

    //#region botones de la tabla MainTable por cada fila de la tabla que dependen del status de la solicitud
    const actions = [
        {
            color: "success",
            icon: "ni ni-fat-add",
            fun: createPage,
            add: 3,
            title: "Crear Hoja",
            id: "createHes"
        },
        {
            color: "warning",
            icon: "ni ni-bold-left",
            fun: reprocessPage,
            add: 2,
            title: "Reprocesar Hoja",
            id: "reprocessHes"
        },
        {
            color: "info",
            icon: "fas fa-image",
            fun: errorDetail,
            add: 2,
            title: "Detalle Error",
            id: "errorDetail"
        },
        {
            color: "info",
            icon: "fas fa-image",
            fun: errorDetail,
            add: 4,
            title: "Detalle Error",
            id: "errorDetail"
        }
    ];
    //#endregion

    //#region columnas seleccionados de la tabla MainTable
    const [selected, setSelected] = useState(
        [
            { value: "consultantName", label: "Consultor" },
            // { value: "Cdate", label: "Fecha", type: "date" },
            { value: "purchaseOrderItem", label: "Orden de Compra", disabled: true },
            { value: "description", label: "Cliente/Descripción" },
            { value: "responsible", label: "Responsable" },
            // { value: "dateText", label: "Fecha" },
            { value: "handleUnit", label: "HES" },
            { value: "hours", label: "Horas" },
            { value: "statusName", label: "Estado", type: "color", optionList: "handleUnitStatus", option: "statusType", val: "status" },
            { value: "actions", label: "Acciones", disabled: true, buttons: actions },
        ]
    );
    //#endregion

    //#region todas columnas de la tabla MainTable
    const optionsSelect = [
        { value: "consultantName", label: "Consultor" },
        { value: "Cdate", label: "Fecha", type: "date" },
        { value: "purchaseOrderItem", label: "Orden de Compra", disabled: true },
        { value: "description", label: "Cliente/Descripción" },
        { value: "responsible", label: "Responsable" },
        { value: "dateText", label: "Fecha" },
        { value: "handleUnit", label: "HES" },
        { value: "hours", label: "Horas" },
        { value: "statusName", label: "Estado", type: "color", optionList: "handleUnitStatus", option: "statusType", val: "status" },
        { value: "actions", label: "Acciones", disabled: true, buttons: actions },

    ];
    //#endregion

    //#endregion

    return (
        <>
            <FiltersMain
                dropDownOptions={dropDownOptions}
                fields={fields}
                buttons={buttons}
                handleOnChangeFilter={handleOnChangeFilter}
                columnTitles={optionsSelect}
                tableParameter={"hes"}
                search={search}
                Toast={Toast}
            />

            <MainTable
                setPage={setPage}
                page={page}
                reloadTable={reloadTable}
                search={search}
                dropDownOptions={dropDownOptions}
                selected={selected}
                setSelected={setSelected}
                optionsSelect={optionsSelect}
                tableParameter={"hes"}
                rolArea={rolArea}
                statusError={"2"}
                isAdmin={isAdmin}
            />
        </>

    );

}

export default MainHes;