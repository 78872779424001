/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Vista "Home" el cual llama a los siguientes componentes:
    -CardHeaders: contiene los card principales de la vista donde se encuentra cada sección
    -MaintainViewButton: (aplica solo para rol administrativo) botón donde se pueden seleccionar los diferentes componentes de mantenimiento
    -MainComponent: contiene el TabContent y TabPanels donde se llaman los componentes principales de cada sección del CardHeaders

*Se llaman las opciones (action) de todos los dropdowns y se establecen por contexto.
*Se establecen states tipo bandera, listas, y diferentes tipos para gestión de componentes hijos. 

=========================================================

* Coded by Diego Meza - Application Management GBM

*/

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// antd components
import { Spin } from 'antd';
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
//login user action
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';
// dropdown option action
import { getOptionsData } from 'actions/Freelance.jsx';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
// customized components
import CardHeaders from 'components/Freelance/CardHeader.jsx';
import MainComponent from 'components/Freelance/MainComponent.jsx';
import MaintainViewButton from 'components/Freelance/MaintainViewButton.jsx';


const Home = (props) => {

    //#region Functions

    useEffect(() => {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
    }, [])

    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(false);
    //#endregion

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region Mensajes de notificacion

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    //#endregion

    //#region roles
    //state que verifica si el usuario es admin
    const [isAdmin, setIsAdmin] = useState(false);
    //state que verifica si el usuario es aprobador L0
    const [isL0, setIsL0] = useState(false);
    //state que verifica si el usuario es aprobador L2
    const [isL2, setIsL2] = useState(false);
    //state que guarda el área del rol del usuario logeado
    const [rolArea, setRolArea] = useState("");
    //state que guarda si la persona es aprobadora de las altas 
    const [isAlta, setIsAlta] = useState(false); 
    useEffect(() => {
        const teams = getTeamsUserAccess();
        console.log(teams)
        //si el rol de admin 
        if (teams.some((row) => row.indexOf("Freelance Coordinator Admin") !== -1)) {
            setIsAdmin(true);
            setRolArea("IBU");
            setIsL0(true);
            setIsL2(true);
        }
        
        if (teams.some((row) => row.indexOf("Freelance Coordinator Altas") !== -1)) {
            setIsAdmin(true);
            setIsAlta(true);
        }
        //sino extrae el área del rol de la persona logeada
        else if (teams.some((row) => row.indexOf("Freelance Coordinator") !== -1)) {
            const match = teams.find(element => {
                if (element.includes("Freelance Coordinator")) {

                    return element;
                }
            });
            const chars = match.split('Freelance Coordinator ');
            setRolArea(chars[1])
        }
        //se verifica si es aprobador L0
        if (teams.some((row) => row.indexOf("Freelance L0") !== -1)) {
            setIsL0(true);
        }
        //se verifica si es aprobador L2
        if (teams.some((row) => row.indexOf("Freelance L2") !== -1)) {
            setIsL2(true);
        }
    }, []);
    //#endregion

    //#region estado de las listas de los dropdowns
    const [options, setOptions] = useState({
        dropDownOptions: {},
        areaOptions: [{
            value: "",
            label: "",
            areaType: "",
        }],
    });

    const { dropDownOptions, areaOptions } = options;
    //#endregion

    //#region funcion para extraer las opciones
    //state para refrescar las opciones
    const [refreshOptions, setRefreshOptions] = useState(false) 
    useEffect(() => {
        dispatch(getOptionsData()).then((resp) => {
            const { payload } = resp;
            console.log(resp);
            if (payload.status === 200) {
                setOptions({
                    dropDownOptions: payload.data.payload.masterData,
                    areaOptions: payload.data.payload.masterData.billsArea,
                })
            }

        })
    }, [refreshOptions]) 

    //#endregion

    //#region state para guarda el ID del card que selecciona el usuario
    const [formSelected, setFormSelected] = useState("0");
    //#endregion

    //#endregion

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert useRef="notificationAlert" />
            </div>
            <AdminHeader name="Inicio" parentName="GBM Portal Proveedores" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    <Row>
                        <CardHeaders
                            setFormSelected={setFormSelected}
                            formSelected={formSelected}
                            isAdmin={isAdmin}
                        />
                    </Row>
                    <Row>
                        <Col xs="12" md="12" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            {
                                (isAdmin) &&
                                <MaintainViewButton
                                    setFormSelected={setFormSelected}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="12" className="p-1">
                            <MainComponent
                                formSelected={formSelected} 
                                setLoad={setLoad} 
                                Toast={Toast} 
                                dropDownOptions={dropDownOptions} 
                                setDropDownOptions={setOptions} 
                                rolArea={rolArea} 
                                isAdmin={isAdmin} 
                                MySwal={MySwal} 
                                isL0={isL0} 
                                isL2={isL2} 
                                isAlta={isAlta}  
                                setRefreshOptions={setRefreshOptions}  
                            />
                        </Col>
                    </Row>
                </Spin>
            </Container>
        </>
    )
}

export default Home;