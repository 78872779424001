// core antd
import { Icon, Upload } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Modal } from "reactstrap";

const { Dragger } = Upload;

const UploadConfigurationSC = ({
  title,
  subtitle,
  fileList,
  idRequest,
  showModal,
  onFileList,
  onComplete,
}) => {

  const uploadProps = {
    name: 'configuration',
    multiple: true,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    accept: [".csv", ".xlsx"],
    action: `${urlBase}/digital-request/upload-configuration-files/${idRequest}`,
    onChange: onFileList,
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
    // toggle={() => toggleModal('referenceModal')}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h6 className="surtitle">{title}</h6>
              <h4>{subtitle}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-3">
            <blockquote className="blockquote text-center">
              <p className="mb-0">
                Cargar Configuración
                </p>
              <footer className="blockquote-footer">
                <cite title="Source Title">Por favor, cargue el archivo o los archivos de la configuración de equipos</cite>
              </footer>
            </blockquote>
            <div className="form-row align-items-center">
              <Col className="m-1" sm="12">
                <Dragger {...uploadProps} fileList={fileList}>
                  <p className="ant-uploading-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                  <p className="ant-uploading-hint">
                    *Se debe cargar la configuración.
                    </p>
                </Dragger>
              </Col>
            </div>
            {
              fileList.length ?
                <div className="mt-2 text-center">
                  <Button
                    className="btn-icon btn-3"
                    color="success"
                    type="submit"
                    onClick={onComplete}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-sync-alt" />
                    </span>
                    <span className="btn-inner--text">Finalizar</span>
                  </Button>
                </div>
                : null
            }
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

UploadConfigurationSC.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default UploadConfigurationSC;
