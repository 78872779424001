// core actions
import { applyMeasureTargeLetter, findAllTargetsLetters, rejectTargeLetterById } from 'actions/targetLetter.jsx';
// antd components
import { Spin } from 'antd';
// core components Shared
import DateRanges from "components/Shared/DateRanges/DateRanges.jsx";
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// import CardEmpty from 'components/Shared/Notifications/CardEmpty.jsx';
import Table from "components/Shared/Table/Table.jsx";
// core components Target Letter
import CardFilters from 'components/TargetLetter/CardFilters.jsx';
import ErrorsModal from 'components/TargetLetter/ErrorsModal.jsx';
import TargetsModal from 'components/TargetLetter/TargetsModal.jsx';
import StatesModal from 'components/TargetLetter/StatesModal.jsx';
// import CardTargertLetters from 'components/TargetLetter/CardTargertLetters.jsx';
// javascript library for dates
import moment from 'moment';
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
// core selectors
import { getFiltersOptions, getTargetsLetters, getUserIsHCManager, getUserIsHCPayrrol } from 'selectors/targetLetter.jsx';

class TargetLetterRequests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: true,
      message: `Cargando las cartas de objetivos`,
      page: 1,
      sizePerPage: 25,
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      filtersValues: {
        country: {
          value: 0,
          label: "Todos"
        },
        flow: {
          value: 0,
          label: "Todos"
        },
        status: {
          value: 0,
          label: "Todos"
        },
        requestNumber: {
          value: 0,
          label: "Todos"
        }
      },
      selected: [],
      toApply: false,
      targets: [],
      showTargets: false,
      showErrors: false,
      errorsApply: [],
      showActivityFlows: {
        showModal: false,
        data: []
      },
    };
  };

  componentDidMount = () => {
    this.findTargetLetters();
  };

  findTargetLetters = () => {
    const { startDate, endDate } = this.state;
    this.props.findAllTargetsLetters({
      startDate,
      endDate
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-name">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  /**
  * Funcion para cuando se selecciona una fecha ya sea de inicio o de fin, la agrega a la variable
  * respectiva en el estado
  * @memberof TargetLetterRequest
  */
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else {
      this.setState({
        [who]: date,
      });
      const { startDate, endDate } = this.state;
      this.setState({
        loading: true,
        message: 'Cargando las cartas de objetivos'
      });
      this.props.findAllTargetsLetters({
        startDate: who === 'startDate' ? date : startDate,
        endDate: who === 'endDate' ? date : endDate
      }).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        }
        this.setState({
          loading: false,
          message: null
        });
      });
    }
  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = date => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  handleOnClearFilters = () => {
    this.setState({
      filtersValues: {
        country: {
          value: 0,
          label: "Todos"
        },
        flow: {
          value: 0,
          label: "Todos"
        },
        status: {
          value: 0,
          label: "Todos"
        },
        requestNumber: {
          value: 0,
          label: "Todos"
        }
      }
    });
  };

  handleOnChangeFilters = (key, event) => {
    const { filtersValues } = this.state;
    filtersValues[key] = event;
    this.setState({
      filtersValues
    });
  };

  renderRowsFiltered = rows => {
    const { filtersValues: { country, status, requestNumber, flow } } = this.state;
    if (country.value !== 0) {
      const [countryKey, subDivision] = country.label.split("/");
      rows = rows.filter((row) => row.organizationalUnit.includes(countryKey) && row.persSubArea.includes(subDivision));
    }
    if (status.value !== 0) {
      rows = rows.filter((row) => row.flowStatus === status.label);
    }
    if (requestNumber.value !== 0) {
      rows = rows.filter((row) => row.requestNumber === requestNumber.label);
    }
    if (flow.value !== 0) {
      rows = rows.filter((row) => row.flow === flow.label);
    }
    return rows;
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      let allSelected = [];
      rows.forEach(function (row) {
        allSelected.push(row.id);
      });
      this.setState(() => ({
        selected: allSelected
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleOnFilterLetterToApply = () => {
    const { filtersOptions: { status, flow } } = this.props;
    const { filtersValues } = this.state;
    const statusAproval = status.filter((row) => row.label === "Aprobada");
    const flowColab = flow.filter((row) => row.label === "Colaborador");
    if (!statusAproval.length || !flowColab.length) {
      this.notify("warning", "Atención", "No hay cartas pendientes por aplicar medida");
    } else {
      const [status1] = statusAproval;
      const [flow1] = flowColab;
      filtersValues['status'] = status1;
      filtersValues['flow'] = flow1;
      this.setState({
        filtersValues,
        toApply: true
      });
    }
  };

  handleOnBack = () => {
    this.setState({
      toApply: false
    });
    this.handleOnClearFilters();
  };

  onApplyTargetLetters = ids => {
    this.setState({
      alert: null,
      loading: true,
      message: 'Aplicando medida a las cartas seleccionadas'
    });
    this.props.applyMeasureTargeLetter({
      ids,
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.setState({
            errorsApply: payload.errors,
            showErrors: true,
          });
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState({
          loading: false,
          message: null
        });
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          selected: [],
          errorsApply: payload.errors,
          showErrors: payload.errors.length ? true : false,
        });
        this.findTargetLetters();
      }
    });
  };

  handleOnApplyLetters = () => {
    const { selected } = this.state;
    if (!selected.length) {
      this.notify("warning", "Atención", "No hay cartas seleccionadas para aplicar medida");
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Está seguro de aplicar medida a las cartas selecionadas?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.onApplyTargetLetters(selected)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, aplicar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        )
      });
    }
  };

  onRejectTargerLetter = (row, comments) => {
    const { id, requestNumber } = row;
    this.setState({
      alert: null,
      loading: true,
      message: 'Rechazando la carta de objetivos'
    });
    this.props.rejectTargeLetterById(id, {
      comments,
      requestNumber
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  handleOnRejectLetter = row => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que quiere rechazar la carta?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={input => this.onRejectTargerLetter(row, input)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, rechazar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese el motivo de rechazo.
        </ReactBSAlert>
      )
    });
  };

  handleOnViewTargets = row => {
    const { targets } = row;
    this.setState({
      showTargets: true,
      targets,
    });
  };

  handleOnToggleTargetsModal = () => {
    this.setState({
      showTargets: false,
      targets: []
    });
  };

  handleOnToggleErrosModal = () => {
    this.setState({
      showErrors: false,
      errorsApply: []
    });
  };

  handleOnViewStates = row => {
    const { states } = row;
    this.setState({
      showActivityFlows: {
        showModal: true,
        data: states,
      }
    })
  };

  handleOnToggleStatesModal = () => {
    this.setState({
      showActivityFlows: {
        showModal: false,
        data: []
      },
    });
  };

  expandRow = {
    clickToExpand: true,
    parentClassName: "expand-parent",
    renderer: row => (
      <div>
        <span>
          <div className="row">
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>#REF</span> <br />
              {row.id}
            </div>
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>
                Posición
              </span>{" "}
              <br />
              {row.position}
            </div>
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>Fecha de Ingreso</span> <br />
              {row.startDatePosition}
            </div>
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>Departamento</span> <br />
              {row.departament}
            </div>
          </div>
        </span>
        <br />
        <span>
          <div className="row">
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>Tipo de Solicitud</span> <br />
              {row.requestType}
            </div>
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>
                Unidad de Negocio
              </span>{" "}
              <br />
              {row.organizationalUnit}
            </div>
            {/* <div className="col">
              <span style={{ color: "#0D45A4" }}>Sub Área de Personal</span> <br />
              {row.persSubArea}
            </div> */}
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>
                Creado Por
              </span>{" "}
              <br />
              {row.createdBy}
            </div>
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>
                Fecha de Creación
              </span>{" "}
              <br />
              {moment(row.createdAt)
                .utc()
                .utcOffset(moment().utcOffset())
                .format("DD/MM/YYYY | HH:mm")}
            </div>
          </div>
        </span>
        <br />
        <span>
          <div className="row">
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>
                Función
              </span>{" "}
              <br />
              {row.funtion}
            </div>
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>Compensación por EPM</span> <br />
              {row.epmCompensation}
            </div>
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>
                Comentario
              </span>{" "}
              <br />
              {row.comments}
            </div>
            <div className="col-sm-3" style={{ whiteSpace: "normal", textAlign: "justify" }}>
              <span style={{ color: "#0D45A4" }}>
                Objetivos / Flujo de Actividades
              </span>{" "}
              <br />
              <Button
                onClick={() => this.handleOnViewTargets(row)}
                className="btn-icon btn-2"
                color="primary"
                type="button"
                size="sm"
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-folder-open"></i>
                </span>
              </Button>
              <Button
                onClick={() => this.handleOnViewStates(row)}
                className="btn-icon btn-2"
                color="default"
                type="button"
                size="sm"
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-chart-line"></i>
                </span>
              </Button>
            </div>
          </div>
        </span>
      </div>
    )
  };

  render() {

    const {
      name,
      parentName,
      isHCPayroll,
      targetsLetters,
      filters,
      filtersOptions,
    } = this.props;

    const {
      alert,
      loading,
      message,
      startDate,
      endDate,
      page,
      sizePerPage,
      selected,
      filtersValues,
      toApply,
      showTargets,
      targets,
      showErrors,
      errorsApply,
      showActivityFlows,
    } = this.state;

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      clickToExpand: true,
      hideSelectAll: false,
      selected: selected,
      style: { backgroundColor: "#c8e6c9" },
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      selectColumnPosition: "left"
    };

    const defaultSorted = [
      {
        dataField: "flowStatus",
        order: "asc"
      }
    ];

    return (
      <>
        {alert}
        <StatesModal
          options={showActivityFlows}
          toggleModal={this.handleOnToggleStatesModal}
        />
        <ErrorsModal
          errors={errorsApply}
          showModal={showErrors}
          toggleModal={this.handleOnToggleErrosModal}
        />
        <TargetsModal
          targets={targets}
          showModal={showTargets}
          toggleModal={this.handleOnToggleTargetsModal}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name={name}
          parentName={parentName}
        />
        <Spin size="large" spinning={loading} tip={message}>
          <Container className="mt--6" fluid>
            {
              !toApply &&
              <Row>
                <Col sm='5'>
                  <DateRanges
                    title="Fechas de búsqueda"
                    subtitle="Ingrese un rango de fechas para la búsqueda."
                    startDate={startDate}
                    endDate={endDate}
                    reactDatetimeChange={this.handleReactDatetimeChange}
                    classNameReactDatetimeDays={this.getClassNameReactDatetimeDays}
                  />
                </Col>
                <Col sm='7'>
                  <CardFilters
                    title="Filtros Disponibles"
                    subtitle="Aplicación de Filtros a las cartas de objetivos"
                    filters={filters}
                    options={filtersOptions}
                    values={filtersValues}
                    onClear={this.handleOnClearFilters}
                    onChange={this.handleOnChangeFilters}
                  />
                </Col>
              </Row>
            }
            {/* <CardTargertLetters
                    isAdmin={isHCPayroll}
                    title="Carta de Objetivos"
                    subtitle="Solicitudes Realizadas"
                    cols={cols}
                    rows={this.renderRowsFiltered(targetsLetters)}
                  /> */}
            <Table
              showButtonToApply={isHCPayroll && !toApply && targetsLetters.some((row) => row.flowStatus === 'Aprobada' && row.flow === 'Colaborador')}
              showTableToApply={toApply}
              style={{
                whiteSpace: "normal",
                textAlign: "justify"
              }}
              title="Carta de Objetivos"
              subtitle={toApply ? "Solicitudes Pendientes de Aplicar Medida" : "Lista de Solicitudes Realizadas"}
              columns={[
                {
                  dataField: "actions", isDummyField: true, text: "Rechazar", hidden: !toApply, csvExport: false,
                  formatter: (cellContent, row) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Button
                        onClick={() => this.handleOnRejectLetter(row)}
                        className="btn-icon btn-2"
                        color="danger"
                        type="button"
                        size="sm"
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  )
                },
                { dataField: "id", text: "#REF", sort: true, hidden: true },
                { dataField: "requestNumber", text: "Número de Solicitud", sort: true, hidden: false },
                { dataField: "requestType", text: "Tipo de Solicitud", sort: true, hidden: true },
                { dataField: "collaborator", text: "Colaborador", sort: true, hidden: false },
                { dataField: "position", text: "Posición", sort: true, hidden: true },
                { dataField: "startDatePosition", text: "Fecha de Ingreso", sort: true, hidden: true },
                { dataField: "manager", text: "Gerente", sort: true, hidden: false },
                { dataField: "startLetter", text: "Inicio de Carta", sort: false, hidden: false },
                { dataField: "endLetter", text: "Fin de Carta", sort: false, hidden: false },
                { dataField: "departament", text: "Departamento", sort: true, hidden: true },
                { dataField: "organizationalUnit", text: "Unidad Organizativa", sort: true, hidden: true },
                { dataField: "epmCompensation", text: "Compensación EPM", sort: true, hidden: true },
                // { dataField: "persSubArea", text: "Sub área personal", sort: true, hidden: true },
                { dataField: "flow", text: "Nivel", sort: true, hidden: false },
                { dataField: "flowStatus", text: "Estado", sort: true, hidden: false },
                { dataField: "createdAt", text: "Fecha de Creación", sort: true, hidden: true },
              ]}
              data={this.renderRowsFiltered(targetsLetters)}
              sizePerPage={sizePerPage}
              page={page}
              totalSize={this.renderRowsFiltered(targetsLetters).length}
              defaultSorted={defaultSorted}
              expandFeatrue={this.expandRow}
              cvsFeatrue
              fileName="Reporte Cartas de Objetivos"
              filterToApply={this.handleOnFilterLetterToApply}
              onBack={this.handleOnBack}
              onApply={this.handleOnApplyLetters}
              selectRow={
                toApply === true
                  ? selectRow
                  : {
                    mode: "checkbox",
                    hideSelectColumn: true,
                    clickToExpand: true
                  }
              }
            />
          </Container>
        </Spin>
      </>
    );
  }
}

TargetLetterRequests.defaultProps = {
  name: "Solicitudes",
  parentName: "Cartas de Objetivos",
  isHCManager: false,
  isHCPayroll: false,
  targetsLetters: [],
  filters: [
    { key: "requestNumber", name: "N° Solicitud", placeholder: "Seleccione un número de oportunidad" },
    { key: "country", name: "País", placeholder: "Seleccione un país" },
    { key: "flow", name: "Nivel", placeholder: "Seleccione un Nivel" },
    { key: "status", name: "Estado", placeholder: "Seleccione un estado" },
  ],
  filtersOptions: {
    requestNumber: [],
    country: [],
    flow: [],
    status: [],
  },
};

TargetLetterRequests.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  isHCManager: PropTypes.bool.isRequired,
  isHCPayroll: PropTypes.bool.isRequired,
  targetsLetters: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  isHCManager: getUserIsHCManager(),
  isHCPayroll: getUserIsHCPayrrol(),
  targetsLetters: getTargetsLetters(state),
  filtersOptions: getFiltersOptions(state),
});

export default withRouter(connect(mapStateToProps, {
  applyMeasureTargeLetter,
  findAllTargetsLetters,
  rejectTargeLetterById
})(TargetLetterRequests));
