import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findRequestsAssignationUser = createAction(
  constants.GET_REQUESTS_ASSIGNATION_USER,
  method.get(urls.ENGINEERS.getRequestsAssignationUser)
);

export const findCriticalPartsKit = createAction(
  constants.GET_CRITICAL_PARTS_KIT,
  (typeModel) =>
    method.get(`${urls.ENGINEERS.getCriticalPartsKit}/${typeModel}`)()
);

export const findEquipmentsSelectedParts = createAction(
  constants.GET_EQUIPMENTS_IDS_SELECTED_PARTS,
  (id) => method.get(`${urls.ENGINEERS.getEquipmentsSelectedParts}/${id}`)()
);

export const findEquipmentsPendingParts = createAction(
  constants.GET_EQUIPMENTS_IDS_PENDINGS_PARTS,
  (id) => method.get(`${urls.ENGINEERS.getEquipmentsPendingParts}/${id}`)()
);

export const findEquipmentsByRequest = createAction(
  constants.GET_EQUIPMENTS_BY_REQUEST,
  (idRequest) =>
    method.get(`${urls.ENGINEERS.getEquipmentsIBMRequest}/${idRequest}`)()
);

export const findPartsEquipmentsRequest = createAction(
  constants.GET_SELECTED_PARTS_KIT_BY_REQUESTS,
  (idRequest) =>
    method.get(`${urls.ENGINEERS.getPartsEquipmentsRequest}/${idRequest}`)()
);

export const findSelectedPartsByEquipment = createAction(
  constants.GET_SELECTED_PARTS_KIT_BY_EQUIPMENT,
  (idEquipment) =>
    method.get(`${urls.ENGINEERS.getSelectedPartsByEquipment}/${idEquipment}`)()
);

export const findHistoryPartsEquipmentsRequest = createAction(
  constants.GET_HISTORY_SELECTED_PARTS_KIT_BY_REQUESTS,
  (idRequest) =>
    method.get(
      `${urls.ENGINEERS.getHistoryPartsEquipmentsRequest}/${idRequest}`
    )()
);

export const createSelectedPartsEquipment = createAction(
  constants.CREATE_SELECTED_PARTS_EQUIPMENT,
  (idR, idE, body) =>
    method.post(
      `${urls.ENGINEERS.createSelectedPartsEquipment}/${idR}/${idE}`,
      body
    )()
);

export const createPendingPartsEquipment = createAction(
  constants.CREATE_PENDING_PARTS_EQUIPMENT,
  (idR, idE, body) =>
    method.post(
      `${urls.ENGINEERS.createPendingPartsEquipment}/${idR}/${idE}`,
      body
    )()
);

export const createCommentToJTR = createAction(
  constants.CREATE_COMMENT_TO_JTR,
  (idR, body) =>
    method.post(`${urls.ENGINEERS.createCommentaryToJTR}/${idR}`, body)()
);

export const updateStateAssignationUser = createAction(
  constants.UPDATE_STATE_ASSIGNATION_USER,
  (id, state) =>
    method.put(`${urls.ENGINEERS.updateStateAssignationUser}/${id}/${state}`)()
);

export const updateJTRAssignationUser = createAction(
  constants.UPDATE_JTR_ASSIGNATION_USER,
  (id) => method.put(`${urls.ENGINEERS.updateJTRAssignationUser}/${id}`)()
);

export const updateFrusAndAmountsSelectedParts = createAction(
  constants.UPDATE_FRUS_AND_AMOUNTS_SELECTED_PARTS,
  (body) =>
    method.put(`${urls.ENGINEERS.updateFrusAndAmountsSelectedParts}`, body)()
);

export const updateAssignationUserReturnPlanning = createAction(
  constants.UPDATE_ASSIGNATION_USER_RETURN_PLANNING,
  (id) =>
    method.put(`${urls.ENGINEERS.updateAssignationUserReturnPlanning}/${id}`)()
);
