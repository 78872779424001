/*!

=========================================================
*Autopp - LDRMainForm
=========================================================

*Componente que renderiza LDRSCardSelect según los toggles establecidos en el state toggleCheck
en General Information.

*Renderiza botones para elegir form dependiendo si el card tiene subform 
(esto se establece en el state haveSubForms, en el campo haveSubForms).

*Si no tiene subform únicamente renderiza el form dependiendo de que card fue seleccionado.

*Todos los forms renderizados son componentes LDRItemForm, el cual se renderiza con un map, estos
almacenan su información en el state ldrsList, y posteriormente se agrega al state principal editData.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Import de librerias de react.
import React, { useContext, useState, useEffect } from "react";
import { Card, TabContent, TabPane, Row, Col, CardHeader } from "reactstrap";

//Import de contexto para extraer states globales.
import { UserContext } from "../SharedComponents/UserContext";

//Import de componentes
import { LDRItemForm } from "./LDRItemForm";
import { CardSelectLDRS } from "./CardSelectLDRS";
import { UploadBOM } from "./UploadBOM";

//Endpoint de autopp
import { deleteFolderBOM } from "actions/autoppLdrs";

import { useDispatch } from "react-redux";

export const LDRMainForm = React.memo(
  ({ setLdrsList, toggleCheck, ldrsList }) => {
    //Para disparar al api de ss
    const dispatch = useDispatch();

    //#region configuración del título en caso que no haya ningún LDR seleccionado previamente.
    const [state] = useState({
      title: "LDRS Gestiones",
      subtitle:
        "Por favor, si desea agregar LDR a la oportunidad, seleccione cual desea en Información General.",
      titleBOM: "Documentos oportunidad",
      subtitleBOM:
        "Por favor, agregue sus archivos de tipo Pliego Cartelario, RFP, RFI, Correo electrónico del cliente.",
    });
    const { title, subtitle, titleBOM, subtitleBOM } = state;

    //#endregion

    //#region States Globales
    const {
      resetSpecificStates,
      setFileListBOM,
      temporalFolderFilesId,
      setDeleteLDR,
      fileListBOM,
      deleteLDR,
      LDRfields
    } = useContext(UserContext);
    //#endregion

    //#region States locales
    //State antiguos de como funcionaban los campos de los cards a seleccionar
    const [cardToSelectBackup, setCardToSelectBackup] = useState([
      //Collaboration
      {
        id: "collaboration",
        title: "Collaboration",
        subtitle: "",
        haveSubForms: true, //Se indica para que haga un mapeo de los subforms relacionados, sino solo crea el form con los datos del card.
        showCard: false, //Se pone predefinido en false siempre.
        //classNameCircle: "icon icon-shape bg-gradient-green text-white rounded-circle shadow",
        classNameCircle:
          "icon icon-shape bg-gradient-primary text-white rounded-circle shadow",
        classNameIcon: "ni ni-active-40",
        colorCardSelected: "#d6d4fa", //'#c9f5d0',
        colorArrow: "primary", //"success"
      },

      //Networking SDN
      {
        id: "networkingSDN",
        title: "Networking SDN",
        subtitle: "",
        haveSubForms: true, //Se indica para que haga un mapeo de los subforms relacionados, sino solo crea el form con los datos del card.
        showCard: false, //Se pone predefinido en false siempre.
        classNameCircle:
          "icon icon-shape bg-gradient-primary text-white rounded-circle shadow",
        classNameIcon: "ni ni-active-40",
        colorCardSelected: "#d6d4fa",
        colorArrow: "primary",
      },

      //Networking SDN
      {
        id: "gtlVirtualization",
        title: "Virtualización GTL",
        subtitle: "",
        haveSubForms: true, //Se indica para que haga un mapeo de los subforms relacionados, sino solo crea el form con los datos del card.
        showCard: false, //Se pone predefinido en false siempre.
        classNameCircle:
          "icon icon-shape bg-gradient-primary text-white rounded-circle shadow",
        classNameIcon: "ni ni-active-40",
        colorCardSelected: "#d6d4fa",
        colorArrow: "primary",
      },

      //Compute
      {
        id: "compute",
        title: "Compute",
        subtitle: "",
        haveSubForms: true, //Se indica para que haga un mapeo de los subforms relacionados, sino solo crea el form con los datos del card.
        showCard: false, //Se pone predefinido en false siempre.
        classNameCircle:
          "icon icon-shape bg-gradient-primary text-white rounded-circle shadow",
        classNameIcon: "ni ni-active-40",
        colorCardSelected: "#d6d4fa",
        colorArrow: "primary",
      },

      //Storage
      {
        id: "storage",
        title: "Storage",
        subtitle: "",
        haveSubForms: true, //Se indica para que haga un mapeo de los subforms relacionados, sino solo crea el form con los datos del card.
        showCard: false, //Se pone predefinido en false siempre.
        classNameCircle:
          "icon icon-shape bg-gradient-primary text-white rounded-circle shadow",
        classNameIcon: "ni ni-active-40",
        colorCardSelected: "#d6d4fa",
        colorArrow: "primary",
      },
    ]);

    //State antiguos de como funcionaban Estos se ligan con cardToSelect para crear los botones después de seleccionar el form-card.
    const [subFormsBackup, setSubFormsBackup] = useState([
      //Collaboration
      {
        idCard: "collaboration",
        idSubForm: "meetings",
        title: "Meetings",
        className: "fa fa-bars",
        showSubForm: false,
      },
      {
        idCard: "collaboration",
        idSubForm: "telephony",
        title: "Telefonía",
        className: "fa fa-bars",
        showSubForm: false,
      },
      {
        idCard: "collaboration",
        idSubForm: "devices",
        title: "Dispositivos",
        className: "fa fa-bars",
        showSubForm: false,
      },
      {
        idCard: "collaboration",
        idSubForm: "contactCenter",
        title: "Contact Center",
        className: "fa fa-bars",
        showSubForm: false,
      },

      //Networking SDN

      {
        idCard: "networkingSDN",
        idSubForm: "dataCenter",
        title: "Datacenter",
        className: "fa fa-bars",
        showSubForm: false,
      },
      {
        idCard: "networkingSDN",
        idSubForm: "LANAndWLAN",
        title: "LAN y WLAN",
        className: "fa fa-bars",
        showSubForm: false,
      },
      {
        idCard: "networkingSDN",
        idSubForm: "WANEnterprise",
        title: "WAN Enterprise",
        className: "fa fa-bars",
        showSubForm: false,
      },

      //Virtualización GTL
      {
        idCard: "gtlVirtualization",
        idSubForm: "revenueVMware",
        title: "Renovación VMware",
        className: "fa fa-bars",
        showSubForm: false,
      },

      //Compute
      {
        idCard: "compute",
        idSubForm: "lenovo",
        title: "Lenovo",
        className: "fa fa-bars",
        showSubForm: false,
      },

      {
        idCard: "compute",
        idSubForm: "powerNew",
        title: "Power - Nuevo",
        className: "fa fa-bars",
        showSubForm: false,
      },

      {
        idCard: "compute",
        idSubForm: "powerMES",
        title: "Power - MES",
        className: "fa fa-bars",
        showSubForm: false,
      },

      //Storage
      {
        idCard: "storage",
        idSubForm: "newStorage",
        title: "Storage Nuevo",
        className: "fa fa-bars",
        showSubForm: false,
      },

      {
        idCard: "storage",
        idSubForm: "mesStorage",
        title: "MES Storage",
        className: "fa fa-bars",
        showSubForm: false,
      },
    ]);

    //State con los campos de los cards a seleccionar
    const [cardToSelect, setCardToSelect] = useState([]);

    //Estos se ligan con cardToSelect para crear los botones después de seleccionar el form-card.
    const [subForms, setSubForms] = useState([]);

    //Efecto para generar los subtítulos y campos, según los LDRS y Brands.
    useEffect(() => {
      try { //Se pone el try debido a que los campos aún no hay llegado.


        let cardToSelectTemp = []
        let subFormsTemp = []

        //Declarar los subtítulos
        LDRfields.LDRBrand.map(brand => {
          cardToSelectTemp = [...cardToSelectTemp,

          {
            id: brand.idName,
            title: brand.label,
            subtitle: "",
            haveSubForms: true, //Se indica para que haga un mapeo de los subforms relacionados, sino solo crea el form con los datos del card.
            showCard: false, //Se pone predefinido en false siempre.
            //classNameCircle: "icon icon-shape bg-gradient-green text-white rounded-circle shadow",
            classNameCircle:
              "icon icon-shape bg-gradient-primary text-white rounded-circle shadow",
            classNameIcon: "ni ni-active-40",
            colorCardSelected: "#d6d4fa", //'#c9f5d0',
            colorArrow: "primary", //"success"

          },]

        })

        //Declarar los fields form
        Object.values(LDRfields.useLDRFields).map(LDR => {
          subFormsTemp = [...subFormsTemp,

          {
            idCard: LDR.brandFather,
            idSubForm: LDR.id,
            title: LDR.label,
            className: "fa fa-bars",
            showSubForm: false,
          },]

        })

        if (cardToSelectTemp.length != 0) {
          setCardToSelect(cardToSelectTemp)
          setSubForms(subFormsTemp)
        }

      } catch (e) { }

    }, [LDRfields])


    //Determina la cantidad de cards activos para seleccionar.
    const [amountCardsActive, setAmountCardsActive] = useState(0);

    //Almacena el card seleccionado.
    const [cardSelected, setCardSelected] = useState("");

    //#endregion

    //#region Efectos
    //Efecto para habilitar los cards según el estado de toggleCheck (Ubicado en GeneralInformation)
    useEffect(() => {
      try {
        const tempFieldsCards = cardToSelect.map((card) => {
          let counterSubformsActive = 0;
          subForms.forEach((subform) => {
            if (subform.idCard === card.id) {
              //Mapea los subforms que pertenecen al card
              if (toggleCheck[subform.idSubForm] === true)
                //el subform actual está activo
                counterSubformsActive++;
            }
          });

          if (counterSubformsActive > 0) {
            //Verifica si en los toggles la posición del map está en true
            return { ...card, showCard: true };
          } else {
            //Verifica si en los toggles la posición del map está en false
            return { ...card, showCard: false };
          }
        });



        if (tempFieldsCards.length != 0) {
          setCardToSelect(tempFieldsCards)
        }


      } catch (e) { }
    }, [toggleCheck]);

    //Efecto para borrar del editData (JSON principal), el toggle que se ha puesto en off en general information.
    useEffect(() => {
      let localDeleteLDR = deleteLDR;
      setDeleteLDR(""); //Reset del state de LDR a borrar.

      if (localDeleteLDR !== "") {
        delete ldrsList[localDeleteLDR];
      }
    }, [deleteLDR]);

    //Efecto hacer un reset en card cuando no haya ningun toggle seleccionado
    useEffect(() => {
      const tempAmountCardsActive = cardToSelect.filter((card) => {
        if (card.showCard === true) {
          return card;
        }
      });

      //Para que aparezca o desparezca mensaje inicial
      setAmountCardsActive(tempAmountCardsActive.length);
      tempAmountCardsActive.length === 0 && setCardSelected("");
    }, [cardToSelect]);

    //#endregion

    //#region Handles y funciones
    //Muestra el LDR Form dependiendo del card seleccionado.
    const handleSelectLDRForm = (idCard) => {
      setCardSelected(idCard);
    };

    //Función para establecer un subform en showSubForm true o false.
    const handleShowSubform = (titleSubfrom, key) => {
      //console.log(titleSubfrom)
      const tempSubForms = subForms.map((e) => {
        if (e.title === titleSubfrom) {
          //Verifica si en los subforms la posición del map está en true
          return { ...e, showSubForm: true };
        } else {
          //Verifica si en los subforms la posición del map está en false
          return { ...e, showSubForm: false };
        }
      });

      //console.log(tempSubForms)

      setSubForms(tempSubForms);
    };

    //console.log(toggleCheck)

    //Función para saber el form activo de los cards que tienen dos botones, en caso que no se ha seleccionado ninguno, establece uno por
    //defecto, el primero que este en true en togglecheck.
    const handleGetActiveButtonSubform = (id) => {
      // compute, etc. console.log("Este es el id" + id);

      const toReturn = subForms.filter((e) => {
        //Recorre subforms para ver cual de los que tienen id esta activo
        //console.log(e)
        if (e.showSubForm === true && e.idCard === id) {
          return e.title;
        }
      });

      const toReturnDefault = subForms.filter((e) => {
        //Recorre subforms para ver cual de los que tienen id, e imprime el primero que ve
        if (e.idCard === id) {
          //Existe en tooglecheck
          if (toggleCheck[e.idSubForm] === true) {
            return e.title;
          }
        }
      });

      try {
        return toReturn[0].title;
      } catch (e) {
        try {
          return toReturnDefault[0].title;
        } catch (e) {
          return [];
        }
      }
    };

    //#endregion

    //Cuando se envia una solicitud hace un reset y establece la LDRSList por defecto.
    useEffect(() => {
      if (resetSpecificStates === true) {
        setLdrsList((prevState) => {
          return {};
        });
      }
    }, [resetSpecificStates]);

    //Efecto para borrar la carpeta temporal del server junto a sus archivos, esto porque seleccinaron la opción de aplica LDR
    useEffect(() => {
      //console.log(toggleCheck);
      //console.log(fileListBOM);

      if (
        toggleCheck.applyLDROption === true &&
        fileListBOM.fileList.length > 0
      ) {
        //console.log("A borrar")

        dispatch(
          deleteFolderBOM({ temporalFolderFilesId: temporalFolderFilesId })
        ).then((resp) => {
          const { payload } = resp;
          if (payload.status !== 200) {
            console.log(
              "Error al intentar borrar la carpeta del Temporal Id:" +
              temporalFolderFilesId
            );
            console.log(payload);
          } else {
            console.log(
              "Se borró del server la carpeta temporalId " +
              temporalFolderFilesId
            );
            setFileListBOM({ fileList: [] });
          }
        });
      }
    }, [toggleCheck]);

    return (
      <>
        {cardSelected === "" &&
          amountCardsActive === 0 &&
          toggleCheck?.applyBOMPNOption === false && ( //En caso de que no haya ningún LDR seleccionado.
            <>
              <Row className="mb-4">
                <Col sm="12">
                  <h3 className="mb-0">{title}</h3>
                  <p className="text-sm mb-0">{subtitle}</p>
                </Col>
              </Row>
              <hr width="65%" align="left" className="mt--2" />
            </>
          )}

        {
          //Solo aparece cuando está seleccionado el Aplica LDR
          toggleCheck?.applyLDROption === true && (
            <>
              <Row
                style={{ display: "flex", justifyContent: "center" }}
                className="p-0"
              >
                {cardToSelect.map(
                  (row, key) =>
                    row.showCard === true && (
                      <CardSelectLDRS
                        cardSelected={cardSelected}
                        id={row.id}
                        key={key}
                        title={row.title}
                        subtitle={row.subtitle}
                        handleSelectLDRForm={handleSelectLDRForm}
                        classNameCircle={row.classNameCircle}
                        classNameIcon={row.classNameIcon}
                        colorCardSelected={row.colorCardSelected}
                        colorArrow={row.colorArrow}
                      ></CardSelectLDRS>
                    )
                )}
              </Row>

              <Card className="border">
                <CardHeader className="">
                  <Row>
                    <Col sm="12" className="">
                      <TabContent activeTab={cardSelected}>
                        {cardToSelect.map((card, key) => (
                          //Este case es para que al seleccionar el card, aparezca dos botones para seleccionar dos subforms.
                          //row.id === "routingAndSwitching" || row.id === "collaboration" || row.id === "pointsSaleToshiba" ? (

                          <TabPane tabId={card.id}>
                            {" "}
                            {/*Todo un TabPane para Routing And Switching por ejemplo*/}
                            <>
                              {
                                /*toggleCheck[card.id]=== */ true && ( //Si el toggle sigue seleccionado
                                  <Row
                                    className=" mb-6 mt--5"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {" "}
                                    {/*Crea los dos botones con las dos alternativas de forms */}
                                    <Col
                                      key={key}
                                      className=" p-1"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        key={key}
                                        className="wrapper  mt--5 mb--4"
                                      >
                                        <nav key={key} className="tabs">
                                          <div
                                            key={key}
                                            className="selector"
                                          ></div>
                                          {subForms.map(
                                            (subform, keySubForm) => {
                                              return (
                                                subform.idCard === card.id &&
                                                toggleCheck[
                                                subform.idSubForm
                                                ] === true && (
                                                  <a
                                                    key={keySubForm}
                                                    onClick={(e) =>
                                                      handleShowSubform(
                                                        subform.title,
                                                        key
                                                      )
                                                    }
                                                    className={
                                                      handleGetActiveButtonSubform(
                                                        card.id
                                                      ) === subform.title //Pone activo el subform seleccionado
                                                        ? "w-90 active test bg-info"
                                                        : " "
                                                    }
                                                    aria-current="page"
                                                    id={subform.id}
                                                    data-toggle="tab"
                                                    href="#home1"
                                                    role="tab"
                                                    aria-controls="home1"
                                                  >
                                                    <span className="btn-inner--icon mr-1">
                                                      <i
                                                        className={
                                                          subform.className
                                                        }
                                                      />
                                                    </span>
                                                    <span className="btn-inner--text">
                                                      {subform.title}
                                                    </span>
                                                  </a>
                                                )
                                              );
                                            }
                                          )}
                                        </nav>
                                      </div>
                                    </Col>
                                  </Row>
                                )
                              }

                              {/*Imprime los dos forms asignados*/}

                              {
                                /*toggleCheck[card.id] ===*/ true && ( //Si el toggle sigue seleccionado
                                  <TabContent
                                    activeTab={handleGetActiveButtonSubform(
                                      card.id
                                    )}
                                  >
                                    {" "}
                                    {/* Determina cual form selecciono el usuario con los  2 botones de arriba*/}
                                    {subForms.map(
                                      (
                                        subForm,
                                        key //Imprime los dos forms de cada button
                                      ) =>
                                        subForm.idCard === card.id &&
                                        toggleCheck[subForm.idSubForm] ===
                                        true && ( //Solo imprima los Panes relacionados al button
                                          <TabPane
                                            tabId={subForm.title}
                                            key={subForm.title}
                                            id={subForm.title}
                                          >
                                            <LDRItemForm
                                              key={key}
                                              idLDR={subForm.idSubForm}
                                              title={subForm.title}
                                              idCard={subForm.idCard}
                                              ldrsList={ldrsList}
                                              setLdrsList={setLdrsList}
                                            ></LDRItemForm>
                                          </TabPane>
                                        )
                                    )}
                                  </TabContent>
                                )
                              }
                            </>
                          </TabPane>
                        ))}
                      </TabContent>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </>
          )
        }

        {toggleCheck?.applyBOMPNOption === true && (
          <UploadBOM titleBOM={titleBOM} subtitleBOM={subtitleBOM}></UploadBOM>
        )}

        {/*JSON.stringify(ldrsList)*/}
      </>
    );
  }
);

export default LDRMainForm;
