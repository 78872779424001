// core ant desing
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardImg, CardTitle, Modal } from "reactstrap";

const CardEventModal = props => {

  const {
    event: {
      visible,
      title,
      paragraph,
      type,
    },
    loading,
    toggleModal,
    onEvent,
    onHidden,
  } = props;

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={visible}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardImg
            alt="Icono de Evento"
            src={require(`assets/img/theme/medical-report.jpg`)}
            top
          />
          <div>
            <Spin size="large" spinning={loading}>
              <CardBody className="px-md-2 py-md-2">
                <CardTitle className="h2 mb-1 text-center">{title}</CardTitle>
                <blockquote className="blockquote text-center">
                  <p className="mb-1 mt-1 lead">
                    {paragraph}
                  </p>
                </blockquote>
                <div className="d-flex justify-content-center">
                  <Button
                    size="sm"
                    color="info"
                    onClick={() => onEvent(type)}
                  >
                    Agregar Información
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => onHidden(type)}
                  >
                    No volver a mostrar
                  </Button>
                </div>
              </CardBody>
            </Spin>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

CardEventModal.propTypes = {
  event: PropTypes.object.isRequired,
};

export default CardEventModal;