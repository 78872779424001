// actions
import { findHoursAccusedByDates } from "actions/coe.jsx";
// antd components
import { Spin } from 'antd';
// core components Shared
import DateRanges from "components/Shared/DateRanges/DateRanges.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Table from "components/Shared/Table/Table.jsx";
// javascript library for dates
import moment from 'moment';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from "reactstrap";
// core selectors
import { getHoursAccused } from "selectors/coe";

class HoursAccused extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      coesSelected: {
        value: 0,
        label: "Todos"
      },
    }
  };

  componentDidMount() {
    this.findHoursAccused();
  }

  findHoursAccused = () => {
    this.setState({
      loading: true,
      message: 'Cargando el Historial de Horas Imputadas'
    });
    this.props.findHoursAccusedByDates({
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
  * Funcion para cuando se selecciona una fecha ya sea de inicio o de fin, la agrega a la variable
  * respectiva en el estado
  * @memberof Nodes
  */
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else {
      const {
        startDate,
        endDate
      } = this.state;
      this.setState({
        [who]: date,
        loading: true,
        message: 'Cargando el Historial de Horas Imputadas'
      });
      this.props.findHoursAccusedByDates({
        startDate: who === 'startDate' ? date : startDate,
        endDate: who === 'endDate' ? date : endDate,
      }).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        }
        this.setState({
          loading: false,
          message: null
        });
      });
    }
  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = date => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  _handleOnClearFilters = () => {
    this.setState({
      coesSelected: {
        value: 0,
        label: "Todos"
      },
    });
  };

  renderCoeOptions = data => {
    let coes = [];
    coes = data.map((row, key) => {
      return {
        value: key + 1,
        label: row.CoE_Nombre,
      }
    });
    coes = coes.map(e => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => coes[e]).map(e => coes[e]);

    coes = coes.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...coes];
  };

  _handleOnChangeFilters = (who, value) => {
    this.setState({
      [who]: value
    });
  };

  _handleRenderDataByFilters = (data) => {
    const { coesSelected } = this.state;
    if (coesSelected.value === 0) {
      return data;
    }
    else {
      if (coesSelected.value !== 0) {
        data = data.filter((row) => row.CoE_Nombre === coesSelected.label);
      }
      return data;
    }
  };

  render() {

    const {
      name,
      parentName,
      cols,
      hours,
    } = this.props;

    const {
      loading,
      message,
      startDate,
      endDate,
      page,
      sizePerPage,
      coesSelected,
    } = this.state;

    const defaultSorted = [
      {
        dataField: "NOMBRE",
        order: "asc"
      }
    ];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            <Row>
              <Col xs='12' md='7'>
                <DateRanges
                  title="Fechas de búsqueda"
                  subtitle="Ingrese un rango de fechas para la búsqueda."
                  startDate={startDate}
                  endDate={endDate}
                  reactDatetimeChange={this.handleReactDatetimeChange}
                  classNameReactDatetimeDays={this.getClassNameReactDatetimeDays}
                />
              </Col>
              <Col xs='12' md='5'>
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col sm="12" md="8">
                        <h3 className="mb-0" >Filtros</h3>
                        <p className="text-sm mb-0">Filtros disponibles para los reportes.</p>
                      </Col>
                      <Col className="text-right" xs="12" md="4">
                        <Button
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={this._handleOnClearFilters}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-trash" />
                          </span>
                          <span className="btn-inner--text">
                            Limpiar
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-right" xs="12" md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            CoE
                      </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un CoE"}
                            isSearchable={false}
                            options={this.renderCoeOptions(hours)}
                            value={coesSelected}
                            onChange={(e) => this._handleOnChangeFilters('coesSelected', e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  style={{
                    whiteSpace: "normal",
                    textAlign: "justify"
                  }}
                  title="Horas Imputadas"
                  subtitle="Reportes de las horas imputadas del COE."
                  columns={cols}
                  data={this._handleRenderDataByFilters(hours)}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={this._handleRenderDataByFilters(hours).length}
                  defaultSorted={defaultSorted}
                  cvsFeatrue
                  fileName="Horas Imputadas"
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

HoursAccused.defaultProps = {
  name: "Reporte por colaboradores",
  parentName: "COE",
  cols: [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "NOMBRE",
      text: "Nombre",
      sort: true
    },
    {
      dataField: "Col_ID",
      text: "ID Colaborador",
      sort: true
    },
    {
      dataField: "CoE_Nombre",
      text: "COE",
      sort: true
    },
    {
      dataField: "Horas",
      text: "Horas",
      sort: true
    },
    {
      dataField: "Rep_ItemFecha",
      text: "Fecha de Reporte",
      sort: true
    },
  ],
  hours: [],
};

HoursAccused.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  hours: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  hours: getHoursAccused(state),
});

export default withRouter(connect(mapStateToProps, {
  findHoursAccusedByDates,
})(HoursAccused));