// javascript plugin that creates nice dropzones for files
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const EquipmentsUbicationsModal = props => {

  const {
    options: {
      showModal,
      amount,
    },
    toggleModal,
    onWorkFlow
  } = props;

  const validate = values => {
    const errors = {};
    if (parseInt(values.amount) !== (parseInt(values.amountIn) + parseInt(values.amountOut))) {
      errors.amountIn = "La suma de los equipos debe coincidir";
      errors.amountOut = "La suma de los equipos debe coincidir";
    }
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={showModal}
      toggle={() => toggleModal("modalEquipmentsErrors")}
    >
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0 text-center">Actualización de Equipos</h5>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={onWorkFlow}
            validate={validate}
            initialValues={{ amount, amountOut: 0, amountIn: 0 }}
            render={({ handleSubmit, values, submitting, validating, valid }) => (
              <FormRS role="form">
                <FormGroup>
                  <Label for="amount">Cantidad Equipos Nueva</Label>
                  <Field name="amount">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          className="form-control-alternative"
                          type="number"
                          disabled
                          invalid={meta.error && meta.touched}
                        />
                        {
                          meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                        }
                      </div>
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Label for="amountIn">Equipos Fuera del Radio</Label>
                  <Field name="amountIn">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          className="form-control-alternative"
                          type="number"
                          invalid={meta.error && meta.touched}
                        />
                        {
                          meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                        }
                      </div>
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Label for="amountOut">Equipos Dentro del Radio</Label>
                  <Field name="amountOut">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          className="form-control-alternative"
                          type="number"
                          invalid={meta.error && meta.touched}
                        />
                        {
                          meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                        }
                      </div>
                    )}
                  </Field>
                </FormGroup>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12">
                    <Button
                      disabled={submitting}
                      className="btn-icon"
                      block
                      color="success"
                      type="submit"
                      onClick={handleSubmit || validating}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Actualizar</span>
                    </Button>
                  </Col>
                </div>
              </FormRS>
            )}
          />
        </CardBody>
      </Card>
    </Modal>
  );
};

EquipmentsUbicationsModal.propTypes = {
  options: PropTypes.object.isRequired,
};

export default EquipmentsUbicationsModal;