import React from "react";
// react library for routing
import { Route, Redirect, Switch } from "react-router-dom";
// routes
import { UserCloudRequestAdminRoutes } from "routes/routes.jsx";

const Index = () => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/admin-cloud") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Switch>
        {getRoutes(UserCloudRequestAdminRoutes)}
        <Redirect
          from="/admin/admin-cloud"
          to="/admin/admin-cloud/admin-tenant-cloud"
        />
      </Switch>
      <p className="lead"></p>
    </>
  );
};

export default Index;
