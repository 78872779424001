
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Button,
    Table,
    UncontrolledTooltip,
    Input
} from "reactstrap";
import moment from "moment";
import CardHeader from 'reactstrap/lib/CardHeader';
//Librerías Exportar archivos .xls
import { saveAs } from "file-saver";
import XLSX from "xlsx";

const GenericTable = ({
    rows,
    colums,
    filters,
    setPage,
    page,
    aditionalButtons,
    text,
    handleOnViewModal,
    rol,
    type,
    showCreateButton,
    isExport
}) => {
    const [state, setState] = useState({
        sizePerPage: 10,
    });
    const { sizePerPage } = state;
    //#region Pagination

    const paginations =
        rows.length > sizePerPage
            ? rows.length / sizePerPage > Math.round(rows.length / sizePerPage)
                ? Math.round(rows.length / sizePerPage) + 1
                : Math.round(rows.length / sizePerPage)
            : 1;

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink onClick={() => handleOnSetPage(i, "page")}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return options.length >= 5
            ? page >= 5
                ? options.slice(page - 3, page + 2)
                : options.slice(0, 5)
            : options;
    };
    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);
    };

    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            sizePerPage: value,
        }));
    };
    //#endregion

    //#region exportar data
    const data = {
        fileName: text,
        documentInfo: [{sheetName:"Hoja1", data: mapFields(rows, colums)}]
    }
    const handleExport = () => {
        // Crear un nuevo libro de trabajo
        const wb = XLSX.utils.book_new();
        // Recorrer los datos y agregar cada hoja de cálculo al libro de trabajo
        for (const sheetData of data.documentInfo) {
            // Convertir los datos JSON a una hoja de cálculo
            const ws = XLSX.utils.json_to_sheet(sheetData.data);
            // Agregar la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(wb, ws, sheetData.sheetName);
        }
        // Convertir el libro de trabajo a un archivo Excel en formato array
        const wbout = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
        });
        // Descargar el archivo Excel
        saveAs(
        new Blob([wbout], {
            type: "application/octet-stream",
        }),
        `${data.fileName}.xlsx`
        );
    };
    //#endregion

    //#region formato para exportar
    function mapFields(data, fields) {
        return data.map(obj => {
          let newObj = {};
          fields.forEach(field => {
            if (obj.hasOwnProperty(field.value)) {
              newObj[field.label] = obj[field.value];
            }
          });
          return newObj;
        });
      }
    //#endregion
    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="justify-content-between" style={{ marginRight: '2vh' }}>
                        <Col sm="12" md="10">
                            <h4 className="surtitle">{text}</h4>
                        </Col>
                        {
                            showCreateButton && (

                                <Col sm="12" md="2">
                                    <Button
                                        className="btn-icon sm"
                                        color="dark"
                                        block
                                        type="button"
                                        onClick={() => handleOnViewModal()}
                                    >
                                        <span className="btn-inner--icon mr-1" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                            <i className="fas fa-plus"></i>
                                        </span >Crear nuevo registro
                                    </Button>
                                </Col>
                            )
                        }
                        {isExport && rows.length > 0 && (
                            <button
                                className="btn btn-success"
                                color="success"
                                type="button"
                                size="sm"
                                onClick={handleExport}
                                style={{
                                height: "1.75rem",
                                width: "4.8rem",
                                padding: "0.2rem",
                                fontSize: "0.8rem"
                                }}
                            >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-file-excel" />
                            </span>
                            <span className="btn-inner--text">Exportar</span>
                          </button>
                        )}
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table
                        className="align-items-center table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">
                            <tr>
                                {colums.map((item, key) => {
                                    return (
                                        //Si no es admin no pone todas las columnas


                                        rol !== "BS Admin" ?
                                            item.isBS === false &&
                                            (
                                                item.isTable === true &&
                                                (item.filter === true ?
                                                    <th key={key} className="justify-content-md-center ">
                                                        <FormGroup key={key} >
                                                            <label
                                                                style={{ fontSize: "12px" }}
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {`${item.label}:`}
                                                            </label>
                                                            <Input
                                                                id={item.id}
                                                                key={key}
                                                                className="form-control"
                                                                type="text"
                                                                placeholder={`Buscar ${item.label}...`}
                                                                onChange={(e) => filters(item.value, e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </th>
                                                    :
                                                    <th key={key}>{item.label}</th>)

                                            )
                                            :
                                            //Si es admin si pone todas las columnas
                                            item.isTable === true && (
                                                item.filter === true ?
                                                    <th key={key} className="justify-content-md-center ">
                                                        <FormGroup key={key} >
                                                            <label
                                                                style={{ fontSize: "12px" }}
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {`${item.label}:`}
                                                            </label>
                                                            <Input
                                                                id={item.id}
                                                                key={key}
                                                                className="form-control"
                                                                type="text"
                                                                placeholder={`Buscar ${item.label}...`}
                                                                onChange={(e) => filters(item.value, e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </th>
                                                    :
                                                    <th key={key}>{item.label}</th>
                                            )
                                    );
                                })}
                                {aditionalButtons && (<th>Acciones</th>)}

                            </tr>
                        </thead>
                        <tbody className="list">
                            {rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((item, key) => (
                                <tr key={`tr${key}$`} >
                                    {
                                        rol !== "BS Admin" ?
                                            colums.filter(item => item.isBS === false).map((col, index) => (
                                                col.isTable && (
                                                    <td key={`td${index}$`} >
                                                        {
                                                            col.type === "date" ?
                                                                moment.utc(item[col.value]).format("L") === "12/12/9999" ?
                                                                    type === 2 ?
                                                                        "Reserva"
                                                                        :
                                                                        "Sin Fecha"
                                                                    :     
                                                                    item[col.value]
                                                                    :                                                             
                                                                item[col.value]
                                                        }
                                                    </td>
                                                )

                                            ))
                                            :
                                            colums.map((col, index) => (
                                                col.isTable && (
                                                    <td key={`td${index}$`} >
                                                        {col.type === "date" ?
                                                            moment.utc(item[col.value]).format("L") === "12/12/9999" ?
                                                                type === 2 ?
                                                                    "Reserva"
                                                                    :
                                                                    "Sin Fecha"
                                                                :
                                                                moment.utc(item[col.value]).format("L")
                                                            :
                                                            item[col.value]
                                                        }
                                                    </td>
                                                )
                                            ))

                                    }
                                    {
                                        aditionalButtons &&
                                        (

                                            <td key={`td1${key}$`} className="text-center">
                                                {
                                                    aditionalButtons.map((btn) => (
                                                        btn.id === "botonTool3" ? (
                                                            item.showButton === 1 && (
                                                                btn.showButton && (
                                                                    <>
                                                                        <Button
                                                                            key={key}
                                                                            className="btn"
                                                                            color={btn.color}
                                                                            id={btn.id}
                                                                            onClick={() => btn.onclick(key, item)}
                                                                        >
                                                                            <span className="btn-inner--icon mr-">
                                                                                <i className={btn.icon}></i>
                                                                            </span>
                                                                            <span className="btn-inner--text"></span>
                                                                        </Button>
                                                                        <UncontrolledTooltip key={`tool1${key}$`} delay={0} target={btn.id}>
                                                                            {btn.tooltip}
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                                )
                                                            )
                                                        ):
                                                        (
                                                            btn.showButton && (
                                                                <>
                                                                    <Button
                                                                        key={key}
                                                                        className="btn"
                                                                        color={btn.color}
                                                                        id={btn.id}
                                                                        onClick={() => btn.onclick(key, item)}
                                                                    >
                                                                        <span className="btn-inner--icon mr-">
                                                                            <i className={btn.icon}></i>
                                                                        </span>
                                                                        <span className="btn-inner--text"></span>
                                                                    </Button>
                                                                    <UncontrolledTooltip key={`tool1${key}$`} delay={0} target={btn.id}>
                                                                        {btn.tooltip}
                                                                    </UncontrolledTooltip>
                                                                </>
                                                            )
                                                        )
                                                    ))}
                                            </td>
                                        )
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Row className="align-items-center">
                            <Col xs="12" md="6" className="p-1">
                                <Row className="justify-content-start">
                                    <Col xs="12" md="6">
                                        <span className="pagination mb-0">
                                            Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                                            {page * sizePerPage > rows.length
                                                ? rows.length
                                                : page * sizePerPage}{" "}
                                            de {rows.length} resultados
                                        </span>
                                    </Col>
                                    <Col xs="12" md="2">
                                        <Input
                                            type="select"
                                            onChange={(e) => handleOnSetNumPagination(e)}
                                        >
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" md="6" className="p-1">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() =>
                                                handleOnSetPage(page === 1 ? page : page - 1, "page")
                                            }
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {renderPaginations()}
                                    <PaginationItem
                                        className={page === paginations ? "disabled" : ""}
                                    >
                                        <PaginationLink
                                            onClick={() =>
                                                handleOnSetPage(
                                                    page === paginations ? page : page + 1,
                                                    "page"
                                                )
                                            }
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </nav>
                </CardFooter>
            </Card>
        </div>
    );
};

export default GenericTable;
