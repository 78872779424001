// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import { Button, Card, CardHeader, Col, Row, Table } from "reactstrap";

const UserMaintenance = (props) => {
  const { title, subtitle, cols, rows, addUser, deleteUser } = props;

  return (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row className="align-items-center">
          <Col sm="12" md="9">
            <h6 className="surtitle text-white">{title}</h6>
            <h5 className="h3 mb-0 text-white">{subtitle}</h5>
          </Col>
          <Col sm="12" md="3" className="d-flex justify-content-end">
            <Button
              id="add"
              className="btn-round btn-icon"
              color="info"
              onClick={addUser}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-user-plus" />
              </span>
              <span className="btn-inner--text">Agregar Usuario</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-dark table-flush" responsive>
        <thead className="thead-dark">
          <tr>
            {cols.map((row, key) => (
              <th
                key={key}
                style={{ whiteSpace: "normal", textAlign: "center" }}
              >
                {row.name}
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {rows.map((row, key) => (
            <tr key={key}>
              {cols.map((col, key) => (
                <th
                  key={key}
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  {row[col.key]}
                </th>
              ))}
              <th
                key="action"
                style={{ whiteSpace: "normal", textAlign: "center" }}
              >
                <a
                  className="table-action table-action"
                  href="#"
                  onClick={() => deleteUser(row)}
                >
                  <i className="fas fa-trash" />
                </a>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

UserMaintenance.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default UserMaintenance;
