
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Button,
    Table,
    UncontrolledTooltip,
    Input
} from "reactstrap";
import moment from "moment";
import CardHeader from 'reactstrap/lib/CardHeader';

const Tables = ({ handleOnNewElement, text, textCreateButton, createButton, colums, rows, aditionalsButtons, setPage, page, typeExtraButton }) => {

    //#region Pagination
    const [state, setState] = useState({
        sizePerPage: 10,
    });
    const { sizePerPage } = state;
    const paginations =
        rows.length > sizePerPage
            ? rows.length / sizePerPage > Math.round(rows.length / sizePerPage)
                ? Math.round(rows.length / sizePerPage) + 1
                : Math.round(rows.length / sizePerPage)
            : 1;

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink onClick={() => handleOnSetPage(i, "page")}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return options.length >= 5
            ? page >= 5
                ? options.slice(page - 3, page + 2)
                : options.slice(0, 5)
            : options;
    };
    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);
    };
    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            sizePerPage: value,
        }));
    };
    //#endregion
    // moment.utc(item.updateAt).format("YYYY-MM-DD")
    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="justify-content-between" style={{ marginRight: '2vh' }}>
                        <Col sm="12" md="10">
                            <h4 className="surtitle">{text}</h4>
                        </Col>
                        {
                            createButton === true &&
                            <Col sm="12" md="2">
                                <Button
                                    className="btn-icon sm"
                                    color="dark"
                                    block
                                    type="button"
                                    onClick={() => handleOnNewElement()}
                                >
                                    <span className="btn-inner--icon mr-1" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                        <i className="fas fa-plus"></i>
                                    </span >{textCreateButton}
                                </Button>
                            </Col>
                        }
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table
                        className="align-items-center table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">
                            <tr>
                                {colums.map((item, key) => {
                                    return (
                                        <th key={key}>{item.label}</th>
                                    );
                                })}
                                <th></th>
                            </tr>

                        </thead>
                        <tbody className="list">
                            {rows && rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((item, key) => (
                                <tr key={`tr${key}$`} >
                                    {
                                        colums.map((col, index) => (
                                            <td key={`td${index}$`} >

                                                {col.type === "date" ?
                                                    <a className="text-muted">{moment.utc(item[col.value]).format("L")}</a>
                                                    :
                                                    col.type === "a" ?
                                                        <a className="font-weight-bold">{item[col.value]}</a>
                                                        : col.type === "b" ?
                                                            <b>{item[col.value]}</b>
                                                            :
                                                            <a>{item[col.value]}</a>
                                                }
                                            </td>
                                        ))
                                    }
                                    {typeExtraButton === 1 ?
                                        <td key={`td1${key}$`} className="text-center">
                                            <div className="text-right">
                                                {aditionalsButtons.map((itemButton, key) => (
                                                    <Button
                                                        key={`Button${key}`}
                                                        className="btn-icon btn-xs"
                                                        color={item.away === 1 && itemButton.name === "away" ? "info" : "secondary"}
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => itemButton.function(item)}
                                                        disabled={itemButton.name === "away" && !item.substitudeId ? true : false}
                                                    >
                                                        <span className="btn-inner--icon" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                            <i className={itemButton.icon}></i>
                                                        </span >
                                                    </Button>
                                                ))}
                                            </div>
                                        </td>
                                        :
                                        <td key={`td1${key}$`} className="text-center">
                                            {aditionalsButtons.map((itemButton, key) => (
                                                <a
                                                    key={`Icon${key}`}
                                                    className={itemButton.className}
                                                    href="#"
                                                    id="tooltip601065234"
                                                    onClick={() => itemButton.function(item)}
                                                >
                                                    <i className={itemButton.icon} />
                                                </a>
                                            ))}
                                        </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Row className="align-items-center">
                            <Col xs="12" md="6" className="p-1">
                                <Row className="justify-content-start">
                                    <Col xs="12" md="6">
                                        <span className="pagination mb-0">
                                            Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                                            {page * sizePerPage > rows.length
                                                ? rows.length
                                                : page * sizePerPage}{" "}
                                            de {rows.length} resultados
                                        </span>
                                    </Col>
                                    <Col xs="12" md="2">
                                        <Input
                                            type="select"
                                            onChange={(e) => handleOnSetNumPagination(e)}
                                        >
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" md="6" className="p-1">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() =>
                                                handleOnSetPage(page === 1 ? page : page - 1, "page")
                                            }
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {renderPaginations()}
                                    <PaginationItem
                                        className={page === paginations ? "disabled" : ""}
                                    >
                                        <PaginationLink
                                            onClick={() =>
                                                handleOnSetPage(
                                                    page === paginations ? page : page + 1,
                                                    "page"
                                                )
                                            }
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </nav>
                </CardFooter>
            </Card>
        </div>
    );
};

export default Tables;