import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getFieldTypes = createAction(
  constants.FF_GET_FIELD_TYPES,
  (body) => method.get(`${urls.FF.getFieldTypes}`, body)()
);

export const getDocumentAdministration = createAction(
  constants.FF_GET_ADMIN_DOCS,
  (body) => method.get(`${urls.FF.getDocumentAdministration}`, body)()
);

export const getCategories = createAction(constants.FF_GET_CATEGORIES, (body) =>
  method.get(`${urls.FF.getCategories}`, body)()
);

export const getBusinessUnits = createAction(
  constants.FF_GET_BUSINESS_UNITS,
  (body) => method.get(`${urls.FF.getBusinessUnits}`, body)()
);

export const createDocument = createAction(
  constants.FF_CREATE_DOCUMENT,
  (body) => method.post(`${urls.FF.createDocument}`, body)()
);

export const updateDocument = createAction(
  constants.FF_UPDATE_DOCUMENT,
  (id, body) => method.put(`${urls.FF.createDocument}/${id}`, body)()
);

export const createFieldType = createAction(
  constants.FF_CREATE_FIELD_TYPE,
  (body) => method.post(`${urls.FF.createFieldType}`, body)()
);

export const deleteFieldType = createAction(
  constants.FF_DELETE_FIELD_TYPE,
  (id) => method.put(`${urls.FF.deleteFieldType}/${id}`, {})()
);

export const updateFieldType = createAction(
  constants.FF_UPDATE_FIELD_TYPE,
  (id, body) => method.put(`${urls.FF.getFieldTypes}/${id}`, body)()
);

// export const getDocumentTemplatesByCategoryBU = createAction(
//   constants.FF_GET_TEMPLATES_CATEGORY_BU,
//   (body, category, bu) =>
//     method.get(
//       `${urls.FF.getDocumentTemplatesByCategoryBU}${category}/bu/${bu}`,
//       body
//     )()
// );

export const getDocumentTemplatesByCategoryBU = createAction(
  constants.FF_GET_TEMPLATES_CATEGORY_BU,
  (category) =>
    method.get(`${urls.FF.getDocumentTemplatesByCategoryBU}${category}`)()
);

export const getDocumentsByCategory = createAction(
  constants.FF_GET_DOCUMENTS_CATEGORY,
  (category) => method.get(`${urls.FF.getDocumentsByCategory}${category}`)()
);

export const createDocumentTemplate = createAction(
  constants.FF_CREATE_DOCUMENT_TEMPLATE,
  (body) => method.post(`${urls.FF.createDocumentTemplate}`, body)()
);

export const updateDocumentTemplate = createAction(
  constants.FF_UPDATE_DOCUMENT_TEMPLATE,
  (id, body) => method.put(`${urls.FF.createDocumentTemplate}/${id}`, body)()
);

export const getDocumentIDTemplate = createAction(
  constants.FF_GET_TEMPLATE_DOCUMENT,
  (id) => method.get(`${urls.FF.getDocumentIDTemplate}${id}`)()
);

export const createRequest = createAction(constants.FF_CREATE_REQUEST, (body) =>
  method.post(`${urls.FF.createRequest}`, body)()
);

export const updateRequest = createAction(
  constants.FF_UPDATE_REQUEST,
  (body, id) => method.put(`${urls.FF.updateRequest}/${id}`, body)()
);

export const getRequests = createAction(constants.FF_GET_REQUESTS, () =>
  method.get(`${urls.FF.getRequests}`)()
);

export const getRequest = createAction(constants.FF_GET_REQUEST, (id) =>
  method.get(`${urls.FF.getRequest}${id}`)()
);

export const getRequestsNotifications = createAction(
  constants.FF_NOTIFICATIONS,
  () => method.get(`${urls.FF.notifications}`)()
);

//POSITIONS
export const getPositions = createAction(constants.FF_GET_POSITIONS, () =>
  method.get(`${urls.FF.positions}`)()
);

export const createPosition = createAction(
  constants.FF_CREATE_POSITIONS,
  (body) => method.post(`${urls.FF.positions}`, body)()
);

export const updatePosition = createAction(
  constants.FF_UPDATE_POSITIONS,
  (id, body) => method.put(`${urls.FF.positions}/${id}`, body)()
);

export const approvalAction = createAction(
  constants.FF_APPROVAL_ACTION,
  (id, body) => method.put(`${urls.FF.approvalAction}/${id}`, body)()
);

export const templateQuery = createAction(
  constants.FF_TEMPLATE_QUERY,
  (id, body) => method.put(`${urls.FF.templateQuery}/${id}`, body)()
);

//FILES
// export const downloadAttachment = createAction(
//   constants.FF_DOWNLOAD_ATTACHMENT,
//   (id) => method.get(`${urls.FF.downloadAttachment}/${id}`)()
// );

export const downloadAttachment = createAction(
  constants.FF_DOWNLOAD_ATTACHMENT,
  (id) => method.fileDownload(`${urls.FF.downloadAttachment}/${id}`)()
);

// export const addAttachment = createAction(
//   constants.FF_ADD_ATTACHMENT,
//   (RequestID, data) =>
//     method.put(`${urls.FF.downloadAttachment}/${RequestID}`, data)()
// );

export const addAttachment = createAction(
  constants.FF_ADD_ATTACHMENT,
  (RequestID, body) =>
    method.fileUpload(`${urls.FF.downloadAttachment}/${RequestID}`, body)()
);

export const getUserInformation = createAction(constants.FF_GET_USER, (user) =>
  method.get(`${urls.FF.getUserInformation}/${user}`)()
);

export const remindApprover = createAction(constants.FF_REMINDER, (id) =>
  method.put(`${urls.FF.reminder}/${id}`)()
);

export const toggleAway = createAction(constants.FF_TOGGLE_AWAY, (id, body) =>
  method.put(`${urls.FF.away}/${id}`, body)()
);

export const toggleTemplate = createAction(
  constants.FF_TOGGLE_TEMPLATE,
  (id, status) => method.put(`${urls.FF.toggleTemplate}/${status}/${id}`, {})()
);

export const preventRelaunch = createAction(
  constants.FF_PREVENT_RELAUNCH,
  (id) => method.put(`${urls.FF.preventRelaunch}/${id}`)()
);

export const relaunch = createAction(constants.FF_RELAUNCH, (id) =>
  method.put(`${urls.FF.relaunch}/${id}`)()
);

export const removeFile = createAction(constants.FF_REMOVE_FILE, (id) =>
  method.put(`${urls.FF.removeFile}/${id}`)()
);

//Profile
export const getProfile = createAction(
  constants.FF_GET_PROFILE,
  method.get(`${urls.FF.getProfile}`)
);

export const createProfile = createAction(constants.FF_CREATE_PROFILE, (body) =>
  method.post(`${urls.FF.createProfile}`, body)()
);
export const updateProfile = createAction(
  constants.FF_UPDATE_PROFILE,
  (id, body) => method.put(`${urls.FF.updateProfile}/${id}`, body)()
);
export const toggleProfile = createAction(
  constants.FF_TOGGLE_PROFILE,
  (id, body) => method.put(`${urls.FF.toggleProfile}/${id}`, body)()
);
export const getState = createAction(
  constants.FF_GET_STATES,
  method.get(urls.FF.getState)
);

export const getMyRequests = createAction(
  constants.FF_MY_REQUEST,
  method.get(urls.FF.getMyRequests)
);

export const RRAction = createAction(constants.FF_RR_ACTION, (id, body) =>
  method.put(`${urls.FF.RRAction}/${id}`, body)()
);

export const togglePosition = createAction(
  constants.FF_TOGGLE_POSITION,
  (id, body) => method.put(`${urls.FF.disablePosition}/${id}`, body)()
);

export const deleteTemplate = createAction(
  constants.FF_DELETE_TEMPLATE,
   (id) => method.put(`${urls.FF.deleteTemplate}/${id}`)()
   );
