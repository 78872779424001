/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Access" del boton de Mantenimiento en el cual se otorga, bloquea o elimina el acceso
 a los proveedores, tambien se cambia la contraseña de acceso de los mismos 
    
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    MySwal: funcion para las notificaciones personalizadas
    setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Kendall Carvajal - Application Management GBM

*/

// react library
import React, { useState } from "react";
// reactstrap components
import { Button, Card, CardBody, Col, Row } from "reactstrap";
//usedispatch for fetch
import { useDispatch } from "react-redux";
//customized Components
import FreelanceModal from "components/Freelance/SharedComponents/FreelanceModal.jsx";
import MainTable from "components/Freelance/SharedComponents/MainTable.jsx";
//acciones para Eliminar, bloquear el acceso y generar una nueva contraseña
import { deleteAccess, blockAccess, newPass, getSubcontractInfo } from "actions/Freelance.jsx";

const Access = ({
  setLoad,
  Toast,
  dropDownOptions,
  rolArea,
  isAdmin,
  MySwal,
  setRefreshOptions,
}) => {
  //#region Functions

  //#region State para guardar los filtros seleccionados según cada tabla en FilterMain
  const [search, setSearch] = useState({});
  //#endregion

  //#region state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);
  //#endregion

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#regionstate para recargar la tabla principal cuando se necesite
  const [reloadTable, setRealTable] = useState(false);
  //#endregion

  //#region funcion para buscar los accesos
  const handleSearch = () => {
    setPage(1);
    setRealTable((prevState) => !prevState);
  };
  //#endregion

  // #region state y funcion para abrir el modal de los proveedores
  const [accessModal, setModal] = useState({
    row: "",
    showModal: false,
    title: "",
    type: "",
  });
  const { row, showModal, title, type } = accessModal;
  //#endregion

  //#region Funcion para abrir el modal
  const openCreateModalProveedor = (e) => {
    setModal((prevState) => ({
      ...prevState,
      row: "",
      showModal: !prevState.showModal,
      title: "Crear proveedor",
      type: "Crear",
    }));
  };
  //#endregion

  //#region campo botones
  const buttons = [
    {
      color: "primary",
      icon: "fas fa-search",
      title: "Buscar",
      function: handleSearch,
      colWidth: "6",
    },
    {
      color: "success",
      icon: "fa fa-plus",
      title: "Crear Proveedor",
      function: openCreateModalProveedor,
      colWidth: "6",
    },
  ];
  //#endregion

  //#region Funcion para cambiar la Contraseña
  const changePass = (e, row) => {
    // alert("changePass");
    var randPassword = new Array(18)
      .fill(
        "$0$1$2$3$4$5$6$7$8$9$A$B$C$D$E$F$G$H$I$J$K$L$M$N$O$P$Q$R$S$T$U$V$W$X$Y$Z$a$b$c$d$e$f$g$h$i$j$k$l$m$n$o$p$q$r$s$t$u$v$w$x$y$z$<>#"
      )
      .map((x) =>
        (function (chars) {
          let umax = Math.pow(2, 32),
            r = new Uint32Array(1),
            max = umax - (umax % chars.length);
          do {
            crypto.getRandomValues(r);
          } while (r[0] > max);
          return chars[r[0] % chars.length];
        })(x)
      )
      .join("");

    let html = `
   <Row>
   <Col>
    <FormGroup>
    <label for="pass">Contraseña antigua </label><input disabled=true
     class="form-control" id="pass" placeholder="Contraseña antigua" 
     value="${Buffer.from(
      row.pass,
      "base64"
    ).toString()}"></input>
    </FormGroup>
    <FormGroup>
    <label for="newPass">Contraseña nueva </label>
    <input disabled=true class="form-control" id="newPass" placeholder="Nueva Contraseña" value="${randPassword}"></input>
    </FormGroup>
    </Col>
    </Row>
    `;

    MySwal.fire({
      title: `Cambio de Contraseña del usuario ${row.user}`,
      html: html,
      width: '800px',
      confirmButtonText: "Si, Modificar.",
      confirmButtonColor: "#50D050",
      cancelButtonText: "No, Cancelar",
      showCancelButton: true,
      preConfirm: () => {
        setLoad(true);
        if (document.getElementById("newPass").value === "") {
          MySwal.showValidationMessage("La Contraseña es obligatoria");
          setLoad(false);
        } else {
          var info = {
            row: row,
            newGeneratedPass: Buffer.from(
              document.getElementById("newPass").value.toString()
            ).toString("base64"),
          };
          dispatch(newPass({ info })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
              setRealTable((prevCheck) => !prevCheck);
              Toast.fire({
                title: "Atención",
                html: "Se cambio la Contraseña exitosamente",
                type: "success",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.message,
                type: "warning",
              });
            }
            setLoad(false);
          });
        }
      },
    });
  };
  //#endregion

  //#region Funcion para cambiar el acceso de un Freelance

  //state para guardar los subcontratados
  const [subcontracts, setSubcontracts] = useState([]);
  const editAccess = (e, row, dropDown) => {
    console.log(row);
    if (row.type === 2) {
      setLoad(true);
      dispatch(getSubcontractInfo({ id: row.id })).then((resp) => {
        const { payload } = resp;
        if (payload.status === 200) {
          setSubcontracts(payload.data.subContractPersonal)
        }
        setLoad(false);
        setModal((prevState) => ({
          ...prevState,
          row: row,
          showModal: !prevState.showModal,
          title: "Editar proveedor",
          type: "Editar",
        }));
      });
    }
    else {
      setModal((prevState) => ({
        ...prevState,
        row: row,
        showModal: !prevState.showModal,
        title: "Editar proveedor",
        type: "Editar",
      }));
    }
  };
  //#endregion

  //#region Funcion para bloquear el acceso de un Freelance
  const handleBlockAccess = (e, row) => {
    // alert("blockAccess");
    MySwal.fire({
      title: `Deseas ${row.active === 1 ? "Bloquear" : "Desbloquear"
        } el acceso? `,
      text: "Se puede reversar con ayuda de Application Management",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Si, ${row.active === 1 ? "Bloquear" : "Desbloquear"}`,
      confirmButtonColor: `${row.active === 1 ? "#fa0303" : "#50D050"}`,
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          row: row,
        };
        dispatch(blockAccess({ info })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setRealTable((prevState) => !prevState);
            setRefreshOptions((prevCheck) => !prevCheck);
            Toast.fire({
              title: "Atención",
              html: `Se ${row.active === 1 ? "Bloqueo" : "Desbloqueo"
                } con éxito`,
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.message,
              type: "warning",
            });
          }
          setLoad(false);
        });
      }
    });
  };
  //#endregion

  //#region Funcion para eliminar el acceso de un Freelance
  const handleDeleteAccess = (e, row) => {
    // alert("deleteAccess");
    MySwal.fire({
      title: `Deseas eliminar el acceso?`,
      text: "Una vez eliminado no se puede recuperar la información, se recomienda bloquear el acceso",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar.",
      confirmButtonColor: "#fa0303",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          row: row,
        };
        dispatch(deleteAccess({ info: info })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setRefreshOptions((prevCheck) => !prevCheck);
            setRealTable((prevCheck) => !prevCheck);
            Toast.fire({
              title: "Atención",
              html: "Proveedor eliminado con éxito",
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.message,
              type: "warning",
            });
          }
          setLoad(false);
        });
      }
    });
  };
  //#endregion

  //#region Funcion para controlar las acciones de los botones en Freelance
  const actions = [
    {
      color: "secondary",
      icon: "fas fa-edit",
      fun: editAccess,
      add: 0,
      title: "Editar Acceso",
      id: "accessEdit",
    },
    {
      color: "secondary",
      icon: "fas fa-key",
      fun: changePass,
      add: 0,
      title: "Cambiar Contraseña",
      id: "changePass",
    },
    {
      color: "secondary",
      icon: "fas fa-lock",
      fun: handleBlockAccess,
      add: 0,
      title: "Des/Bloquear acceso",
      id: "blockAccess",
    },
    {
      color: "secondary",
      icon: "fas fa-trash",
      fun: handleDeleteAccess,
      add: 0,
      title: "Eliminar Acceso",
      id: "deleteAccess",
    },
  ];
  //#endregion

  //#region columnas seleccionados de la tabla MainTable
  const [selected, setSelected] = useState([
    { value: "access", label: "Tipo" },
    { value: "email", label: "Email", disabled: true },
    { value: "vendor", label: "Nombre" },
    {
      value: "activeName",
      label: "Estado",
      type: "color",
      optionList: "activeStatus",
      option: "statusType",
      val: "active",
    },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ]);
  //#endregion

  //#region columnas de la tabla MainTable
  const optionsSelect = [
    { value: "access", label: "Tipo" },
    { value: "email", label: "Email", disabled: true },
    { value: "user", label: "Usuario" },
    { value: "vendor", label: "Nombre" },
    { value: "gbmId", label: "Id GBM" },
    { value: "consultants", label: "Consultores" },
    { value: "createdAt", label: "Fecha", type: "date" },
    { value: "createdBy", label: "Creado por" },
    {
      value: "activeName",
      label: "Estado",
      type: "color",
      optionList: "activeStatus",
      option: "activeType",
      val: "active",
    },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ];
  //#endregion

  //#endregion

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            {buttons.map((row, key) => (
              <Col sm={row.colWidth} key={key}>
                <Button
                  className="btn-icon"
                  color={row.color}
                  size="sm"
                  block
                  type="button"
                  onClick={(e) => row.function(e, row)}
                >
                  <span className="btn-inner--icon mr-">
                    <i className={row.icon} />
                  </span>
                  <span className="btn-inner--text">{row.title}</span>
                </Button>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      <MainTable
        setPage={setPage}
        page={page}
        reloadTable={reloadTable}
        search={search}
        Toast={Toast}
        dropDownOptions={dropDownOptions}
        selected={selected}
        setSelected={setSelected}
        optionsSelect={optionsSelect}
        tableParameter={"acc"}
        rolArea={rolArea}
        statusError={"NA"}
        isAdmin={isAdmin}
      />
      <FreelanceModal
        row={row}
        setModal={setModal}
        showModal={showModal}
        dropDownOptions={dropDownOptions}
        setRealTable={setRealTable}
        Toast={Toast}
        title={title}
        type={type}
        setRefreshOptions={setRefreshOptions}
        subcontracts={subcontracts}
        setSubcontracts={setSubcontracts}
      />
    </>
  );
};

export default Access;
