// core actions
import {
  updateActivityFlow,
  updateStateRequest,
} from "actions/Sales/digitalRequest.jsx";
import {
  downloadCalsOfferByType,
  findAjustOfferWithLogById,
  findOffersRequestById,
  findRequestOffersInAjustment,
  updateOfferCalcRequestByPricer,
  updateStatePricerListById,
  updateManualOfferCalcRequestByPricer,
} from "actions/Sales/pricing";
// antd
import { message, Spin } from "antd";
// core components Pricing
import OfferDetail from "components/Sales/Pricing/OfferDetail.jsx";
import OfferFilter from "components/Sales/Pricing/OfferFilter.jsx";
import OffersAjustmentCard from "components/Sales/Pricing/OffersAjustmentCard.jsx";
import UploadDrawerOffer from "components/Sales/Pricing/UploadDrawerOffer.jsx";
import WorkFlowReject from "components/Sales/Pricing/WorkFlowReject.jsx";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getAjustAndReferences,
  getFiltersOptions,
  getOffersCalc,
  getRequestInAjustment,
  getLogsOffers,
} from "selectors/Sales/pricing";
// javascript library to parser xlsx to json
const XLSX = require("xlsx");

class OffersAjustment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      loading2: false,
      message: null,
      message2: null,
      page: 1,
      sizePerPage: 25,
      offerSelected: null,
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
        country: {
          value: 0,
          label: "Todos",
        },
      },
      uploadOptions: {
        openUpload: false,
        name: null,
      },
      fileList: [],
      dataLoaded: {
        datas: {
          equipments: [],
          services: [],
          spareParts: [],
        },
        load: false,
      },
      showModal: false,
      initialValues: {
        option: "0",
        comment: "",
      },
      editAjustType: "",
      valuesEditAjust: {},
    };
  }

  componentDidMount = () => {
    this.findRequestOffersInAjustment();
  };

  onMainInitialValues = () => {
    this.setState({
      alert: null,
      willMount: true,
      loading: false,
      loading2: false,
      message: null,
      message2: null,
      page: 1,
      sizePerPage: 25,
      offerSelected: null,
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
        country: {
          value: 0,
          label: "Todos",
        },
      },
      uploadOptions: {
        openUpload: false,
        name: null,
      },
      fileList: [],
      dataLoaded: {
        datas: {
          equipments: [],
          services: [],
          spareParts: [],
        },
        load: false,
      },
      showModal: false,
      initialValues: {
        option: "0",
        comment: "",
      },
      editAjustType: "",
      valuesEditAjust: {},
    });
  };

  renderExportDataOfferByType = (type, equipments, services, spareParts) => {
    const {
      offerSelected: { idBusinessModel },
    } = this.state;
    if (type === "equipments") {
      return equipments.map((row) => {
        const json = {};
        if (idBusinessModel === 1) {
          json["#REF"] = row.id;
          json["Equipo"] = row.typeModel;
          json["Cantidad"] = row.quantity;
          json["Unitario"] = row.unitCost;
          json["Costo Total"] = row.totalCost;
          json["Años Servicio"] = row.quantityYears;
          json["Tipo de Servicio"] = row.byServices / 100;
          json["Provisión"] = row.provision;
          json["Precio Total"] = row.totalPrice;
          json["Cuota"] = row.payment;
        } else if (idBusinessModel === 2) {
          json["#REF"] = row.id;
          json["Equipo"] = row.typeModel;
          json["Cantidad"] = row.quantity;
          json["Unitario"] = row.unitCost;
          json["Costo Total"] = row.totalCost;
          json["Años Servicio"] = row.quantityYears;
          json["Tipo de Servicio"] = row.byServices / 100;
          json["Años Garantía Fabrica"] = row.quantityYearsWarranty;
          json["By Svcs Restante"] = row.byServicesRemaining / 100;
          json["Años Restantes"] = row.quantityYearsRemaining;
          json["Precio Total 1"] = row.totalPriceSC;
          json["% Shipping"] = row.shippingPercent / 100;
          json["$ Shipping"] = row.shippingCost;
          json["Total w/Shipping Landed"] = row.totalShippingLanded;
          json["% Uplift"] = row.upliftPercent / 100;
          json["$ Uplift"] = row.upliftCost;
          json["Total w/Uplift"] = row.totalUplift;
          json["% Fin"] = row.finPercent / 100;
          json["$ Fin"] = row.finCost;
          json["Provisión"] = row.provision;
          json["Precio Total"] = row.totalPrice;
          json["Cuota"] = row.payment;
        }
        return json;
      });
    } else if (type === "services") {
      return services.map((row) => {
        const json = {};
        json["#REF"] = row.id;
        json["Servicio"] = row.service;
        json["Cantidad Horas"] = row.quantityHours;
        json["Unitario"] = row.unitCost;
        json["Cantidad Años"] = row.quantityYears;
        json["Costo Total"] = row.totalCost;
        json["% Uplift"] = row.upliftPercent / 100;
        json["$ Uplift"] = row.upliftCost;
        json["Total w/Uplift"] = row.totalUplift;
        json["% Fin"] = row.finPercent / 100;
        json["$ Fin"] = row.finCost;
        json["Viáticos"] = row.viatic;
        json["Provisión"] = row.provision;
        json["Precio Total"] = row.totalPrice;
        json["Cuota"] = row.payment;
        return json;
      });
    } else if (type === "spareParts") {
      return spareParts.map((row) => {
        const json = {};
        json["#REF"] = row.id_CalculoSparePartes;
        json["Partes / Spare"] = row.partNumber;
        json["Cantidad"] = row.quantity;
        json["Unitario"] = row.unitCost;
        json["Costo Total"] = row.totalCost;
        json["Años Servicio"] = row.quantityYears;
        json["% Shipping"] = row.shippingPercent / 100;
        json["$ Shipping"] = row.shippingCost;
        json["Total w/Shipping Landed"] = row.totalShippingLanded;
        json["% Uplift"] = row.upliftPercent / 100;
        json["$ Uplift"] = row.upliftCost;
        json["Total w/Uplift"] = row.totalUplift;
        json["% Fin"] = row.finPercent / 100;
        json["$ Fin"] = row.finCost;
        json["Provisión"] = row.provision;
        json["Precio Total"] = row.totalPrice;
        json["Tasa"] = row.interest / 100;
        json["Cuota"] = row.payment;
        return json;
      });
    }
  };

  renderExportDataOfferArray = (data, type) => {
    const {
      offerSelected: { idBusinessModel },
    } = this.state;
    const result = [];
    if (type === "equipments") {
      if (idBusinessModel === 1) {
        result.push([
          "#REF",
          "Equipo",
          "Cantidad",
          "Unitario",
          "Costo Total",
          "Años Servicio",
          "Tipo de Servicio",
          "Provisión",
          "Precio Total",
          "Cuota",
        ]);
        for (const element of data) {
          result.push([
            element.id,
            element.typeModel,
            element.quantity,
            element.unitCost,
            element.totalCost,
            element.quantityYears,
            element.byServices / 100,
            element.provision,
            element.totalPrice,
            element.payment,
          ]);
        }
      } else if (idBusinessModel === 2) {
        result.push([
          "#REF",
          "Equipo",
          "Cantidad",
          "Unitario",
          "Costo Total",
          "Años Servicio",
          "Tipo de Servicio",
          "Años Garantía Fabrica",
          "By Svcs Restante",
          "Años Restantes",
          "Precio Total 1",
          "% Shipping",
          "$ Shipping",
          "Total w/Shipping Landed",
          "% Uplift",
          "$ Uplift",
          "Total w/Uplift",
          "% Fin",
          "$ Fin",
          "Provisión",
          "Precio Total",
          "Cuota",
        ]);
        for (const element of data) {
          result.push([
            element.id,
            element.typeModel,
            element.quantity,
            element.unitCost,
            element.totalCost,
            element.quantityYears,
            element.byServices / 100,
            element.quantityYearsWarranty,
            element.byServicesRemaining / 100,
            element.quantityYearsRemaining,
            element.totalPriceSC,
            element.shippingPercent / 100,
            element.shippingCost,
            element.totalShippingLanded,
            element.upliftPercent / 100,
            element.upliftCost,
            element.totalUplift,
            element.finPercent / 100,
            element.finCost,
            element.provision,
            element.totalPrice,
            element.payment,
          ]);
        }
      }
    } else if (type === "services") {
      result.push([
        "#REF",
        "Servicio",
        "Cantidad Horas",
        "Unitario",
        "Cantidad Años",
        "Costo Total",
        "% Uplift",
        "$ Uplift",
        "Total w/Uplift",
        "% Fin",
        "$ Fin",
        "Viáticos",
        "Provisión",
        "Precio Total",
        "Cuota",
      ]);
      for (const element of data) {
        result.push([
          element.id,
          element.service,
          element.quantityHours,
          element.unitCost,
          element.quantityYears,
          element.totalCost,
          element.upliftPercent / 100,
          element.upliftCost,
          element.totalUplift,
          element.finPercent / 100,
          element.finCost,
          element.viatic,
          element.provision,
          element.totalPrice,
          element.payment,
        ]);
      }
    } else if (type === "spareParts") {
      result.push([
        "#REF",
        "Partes / Spare",
        "Cantidad",
        "Unitario",
        "Costo Total",
        "Años Servicio",
        "% Shipping",
        "$ Shipping",
        "Total w/Shipping Landed",
        "% Uplift",
        "$ Uplift",
        "Total w/Uplift",
        "% Fin",
        "$ Fin",
        "Provisión",
        "Precio Total",
        "Tasa",
        "Cuota",
      ]);
      for (const element of data) {
        result.push([
          element.id_CalculoSparePartes,
          element.partNumber,
          element.quantity,
          element.unitCost,
          element.totalCost,
          element.quantityYears,
          element.shippingPercent / 100,
          element.shippingCost,
          element.totalShippingLanded,
          element.upliftPercent / 100,
          element.upliftCost,
          element.totalUplift,
          element.finPercent / 100,
          element.finCost,
          element.provision,
          element.totalPrice,
          element.interest / 100,
          element.payment,
        ]);
      }
    }
    return result;
  };

  verifyUploadOffers = (data) => {
    let flag = false;
    // for (const element of parts) {
    //   console.log(element)
    //   const { Sustituto1, Sustituto2, Sustituto3, Costo } = element;
    //   if (Sustituto1.length > 17 || Sustituto2.length > 17 || Sustituto3.length > 17 || parseInt(Costo) > 99999999.99) {
    //     flag = true
    //   }
    // }
    return flag;
  };

  findRequestOffersInAjustment = () => {
    this.props.findRequestOffersInAjustment().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        willMount: false,
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnOpenOffer = (row) => {
    const { id, idListPricing } = row;
    console.log(row);
    this.setState({
      loading: true,
      message: "Cargando las tablas de los calculos realizados",
    });
    this.props.findOffersRequestById(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({
          loading: false,
          message: null,
        });
      } else {
        // const { data: { payload } } = res.payload;
        // this.notify("success", "Éxito", payload.message);
        this.setState({
          offerSelected: row,
          loading: false,
          message: null,
        });
        this.props.findAjustOfferWithLogById(idListPricing);
      }
    });
  };

  handleOnClearFilters = () => {
    this.setState({
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
        country: {
          value: 0,
          label: "Todos",
        },
      },
    });
  };

  handleOnChangeFilters = (key, event) => {
    const { filtersValues } = this.state;
    filtersValues[key] = event;
    this.setState({
      filtersValues,
      page: 1,
    });
  };

  renderRowsFiltered = (rows) => {
    const {
      filtersValues: { customer, status, opp, country },
    } = this.state;
    if (customer.value !== 0) {
      rows = rows.filter((row) => row.customer === customer.label);
    }
    if (status.value !== 0) {
      rows = rows.filter((row) => row.status === status.label);
    }
    if (opp.value !== 0) {
      rows = rows.filter((row) => row.opportunityNumber === opp.label);
    }
    if (country.value !== 0) {
      rows = rows.filter((row) => row.country === country.label);
    }
    return rows;
  };

  handleOnBack = () => {
    this.setState({
      offerSelected: null,
    });
  };

  handleOnExportOffer = (name) => {
    const {
      offersCalcs: { equipmentsCalc, servicesCalc, sparePartsCalc },
    } = this.props;
    // const dataOffer = this.renderExportDataOfferByType(type, equipmentsCalc, servicesCalc, sparePartsCalc);
    this.setState({
      loading: true,
      message: "Descargando la oferta",
    });
    this.props
      .downloadCalsOfferByType({
        // dataOffer,
        equipmentsCalc: this.renderExportDataOfferArray(
          equipmentsCalc,
          "equipments"
        ),
        servicesCalc: this.renderExportDataOfferArray(servicesCalc, "services"),
        sparePartsCalc: this.renderExportDataOfferArray(
          sparePartsCalc,
          "spareParts"
        ),
        name,
      })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            this.notify(
              "warning",
              "Atención",
              `No se logro descargar el calculo de la oferta seleccionada`
            );
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([res.payload.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  handleOnImportOffer = (name) => {
    this.setState({
      uploadOptions: {
        openUpload: true,
        name,
      },
    });
  };

  handleOnUploadFileOffer = () => {
    this.setState({
      uploadOptions: {
        openUpload: false,
        name: null,
      },
    });
  };

  handleOnFileListEquipment = async (info) => {
    const { uploadOptions } = this.state;
    const { status, name } = info.file;
    let flag = false;
    if (name.includes(uploadOptions.name)) {
      if (status === "removed") {
        this.setState({
          fileList: [],
        });
        message.success(
          `El archivo ${info.file.name} fue quitado exitosamente.`
        );
      } else {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
          if (file.response) {
            file.url = file.response.url;
            let { status } = file;
            if (status === "done") {
              message.success(
                `El archivo ${file.name} fue cargado exitosamente.`
              );
              const reader = new FileReader();
              reader.onload = async (event) => {
                const data = event.target.result;
                const workbook = XLSX.read(data, {
                  type: "binary",
                });
                let {
                  dataLoaded: { datas, load },
                } = this.state;
                workbook.SheetNames.forEach((sheetName) => {
                  let XL_row_object = XLSX.utils.sheet_to_row_object_array(
                    workbook.Sheets[sheetName]
                  );
                  if (!XL_row_object.length) {
                    flag = true;
                    this.notify(
                      "warning",
                      "Atención",
                      `El archivo ${file.name}, se encuentra vacio`
                    );
                  } else if (this.verifyUploadOffers(XL_row_object)) {
                    flag = true;
                    this.notify(
                      "warning",
                      "Atención",
                      `El archivo ${file.name}, no cumple los parametros establecidos`
                    );
                  } else {
                    if (sheetName === "Equipos Base") {
                      datas["equipments"] = XL_row_object;
                    } else if (sheetName === "Servicios Tss") {
                      datas["services"] = XL_row_object;
                    } else if (sheetName === "Spare y Partes") {
                      datas["spareParts"] = XL_row_object;
                    }
                    load = true;
                  }
                });
                if (flag) {
                  this.setState({
                    fileList: [],
                  });
                }
                this.setState({
                  uploadOptions: {
                    openUpload: false,
                    name: null,
                  },
                  dataLoaded: {
                    load,
                    datas,
                  },
                });
              };
              reader.onerror = function (event) {
                this.notify(
                  "warning",
                  "Atención",
                  `No se logro leer el archivo ${file.name}, ${event.target.error.code}`
                );
              };
              reader.readAsBinaryString(file.originFileObj);
            } else if (status === "error") {
              // this.notify("danger", "Falló", `La carga del archivo ${file.name} fallo.`);
              message.success(`La carga del archivo ${file.name} fallo.`);
            } else if (status === "removed") {
              // this.notify("warning", "Atención", `El archivo ${file.name} fue quitado exitosamente.`);
              message.success(
                `El archivo ${file.name} fue quitado exitosamente.`
              );
            }
          }
          return file;
        });
        this.setState({
          fileList,
        });
      }
    } else {
      this.notify(
        "warning",
        "Atención",
        "El nombre del archivo no cumple con los parametros establecidos"
      );
    }
  };

  handleOnCancelUploadOffer = () => {
    this.setState({
      uploadOptions: {
        openUpload: false,
        name: null,
      },
      fileList: [],
      dataLoaded: {
        datas: {
          equipments: [],
          services: [],
          spareParts: [],
        },
        load: false,
      },
    });
  };

  onAjustOffersRequest = (values, description) => {
    const {
      offerSelected: { id, idListPricing },
    } = this.state;
    this.setState({
      loading: true,
      message: "Guardando el ajuste realizado a la oferta",
      alert: null,
    });
    this.props
      .updateOfferCalcRequestByPricer(id, idListPricing, {
        values,
        description,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findAjustOfferWithLogById(idListPricing);
          this.props.findOffersRequestById(id);
        }
        this.setState({
          loading: false,
          message: null,
          fileList: [],
          dataLoaded: {
            datas: {
              equipments: [],
              services: [],
              spareParts: [],
            },
            load: false,
          },
        });
      });
  };

  handleOnSaveChangesInOffers = (values) => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de actualizar la oferta?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.onAjustOffersRequest(values, input)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, ajustar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese un comentario al ajuste.
        </ReactBSAlert>
      ),
    });
  };

  handleOnFinishAjustProcess = () => {
    const {
      offerSelected: { id, idListPricing },
    } = this.state;
    this.setState({
      loading: true,
      message: "Finalizando el ajuste a la oferta de la oportunidad",
    });
    this.props.updateStateRequest(id, 12).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({
          loading: false,
          message: null,
        });
      } else {
        await this.props.updateActivityFlow(id, {
          description: "Ajuste a la oferta de la oportunidad finalizado",
          state: "Ajuste Finalizado",
          type: "pricer",
        });
        await this.props.updateStatePricerListById(idListPricing, 1, {
          description: "Ajuste Finalizado por Pricing",
          comment: "",
        });
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.onMainInitialValues();
        this.findRequestOffersInAjustment();
      }
    });
  };

  handleOnRejectAjustProcess = () => {
    this.setState({
      showModal: true,
    });
  };

  handleOnToggleModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleOnWorkFlowReject = (values) => {
    const {
      initialValues: { comment, option },
    } = this.state;
    const { optionsFlow } = this.props;
    const rejectType = optionsFlow.find((row) => row.key === option);
    const {
      offerSelected: { id, idListPricing },
    } = this.state;
    this.setState({
      loading2: true,
      message2: "Rechazando el ajuste a la oferta de la oportunidad",
    });
    this.props
      .updateStatePricerListById(idListPricing, 2, {
        description: rejectType.name,
        comment,
      })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState({
            loading2: false,
            message2: null,
          });
        } else {
          await this.props.updateStateRequest(id, 11);
          await this.props.updateActivityFlow(id, {
            description: "Ajuste a la oferta de la oportunidad rechazado",
            state: "Ajuste Rechazado",
            type: "pricer",
          });
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.onMainInitialValues();
          this.findRequestOffersInAjustment();
        }
      });
  };

  handleOnChangeTextOption = (event) => {
    const { name, value } = event.target;
    const { optionsFlow } = this.props;
    const { initialValues } = this.state;
    initialValues[name] = value;
    if (name === "option")
      initialValues["comment"] = optionsFlow.find(
        (row) => row.key === value
      ).comment;
    this.setState({
      initialValues,
    });
  };

  handleOnEditValuesAjust = (values, type) => {
    console.log(values);
    console.log(type);
    this.setState({
      editAjustType: type,
      valuesEditAjust: {
        id: type === "spareParts" ? values.id_CalculoSparePartes : values.id,
        id_CalculoSparePartes: values.id_CalculoSparePartes,
        unitCost: values.unitCost,
        byServices: values.byServices,
        byServicesRemaining: values.byServicesRemaining,
        shippingPercent: values.shippingPercent,
        upliftPercent: values.upliftPercent,
        finPercent: values.finPercent,
        provision: values.provision,
        viatic: values.viatic,
        interest: values.interest,
      },
    });
  };

  handleOnChangeEditValues = (event) => {
    const { id, value } = event.target;
    const { valuesEditAjust } = this.state;
    valuesEditAjust[id] = parseFloat(value);
    this.setState({
      valuesEditAjust,
    });
  };

  handleOnUpdateValuesAjust = () => {
    const {
      offerSelected: { id, idListPricing },
      valuesEditAjust,
      editAjustType,
    } = this.state;
    this.setState({
      loading: true,
      message: "Guardando el ajuste realizado a la oferta",
    });
    this.props
      .updateManualOfferCalcRequestByPricer(id, idListPricing, {
        values: valuesEditAjust,
        description: "Guardando cambios manuales al ajuste",
        type: editAjustType,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findAjustOfferWithLogById(idListPricing);
          this.setState({
            editAjustType: "",
            valuesEditAjust: {},
          });
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  render() {
    const {
      name,
      parentName,
      requests,
      cols,
      logs,
      offersCalcs,
      filters,
      ajustOffer,
      optionsFlow,
      filtersOptions,
    } = this.props;

    const {
      page,
      alert,
      loading,
      loading2,
      message,
      message2,
      fileList,
      showModal,
      willMount,
      dataLoaded,
      sizePerPage,
      editAjustType,
      uploadOptions,
      filtersValues,
      offerSelected,
      initialValues,
      valuesEditAjust,
    } = this.state;

    return (
      <>
        {alert}
        <WorkFlowReject
          message={message2}
          loading={loading2}
          showModal={showModal}
          options={optionsFlow}
          initialValues={initialValues}
          toggleModal={this.handleOnToggleModal}
          onWorkFlow={this.handleOnWorkFlowReject}
          onChangeTextOption={this.handleOnChangeTextOption}
        />
        <UploadDrawerOffer
          title="Cargar Oferta Exportada"
          options={uploadOptions}
          fileList={fileList}
          onToggle={this.handleOnUploadFileOffer}
          onFileList={this.handleOnFileListEquipment}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message} size="large">
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando las oportunidades pendientes de ajustar el costo" />
                </Col>
              </Row>
            ) : offerSelected ? (
              <OfferDetail
                title={`Forma de Pago: ${offerSelected.wayPay}`}
                subtitle="Tabla de los calculos realizados a la oferta por ajustar"
                type={offerSelected.idBusinessModel}
                offer={offerSelected}
                dataLoaded={dataLoaded}
                equipmentsBase={offersCalcs.equipmentsCalc}
                servicesTss={offersCalcs.servicesCalc}
                spareParts={offersCalcs.sparePartsCalc}
                ajustOffer={ajustOffer}
                logs={logs}
                ajustType={editAjustType}
                editValues={valuesEditAjust}
                onBack={this.handleOnBack}
                onExport={this.handleOnExportOffer}
                onImport={this.handleOnImportOffer}
                onEdit={this.handleOnEditValuesAjust}
                onChange={this.handleOnChangeEditValues}
                onUpdate={this.handleOnUpdateValuesAjust}
                onFinish={this.handleOnFinishAjustProcess}
                onReject={this.handleOnRejectAjustProcess}
                onCancelUpload={this.handleOnCancelUploadOffer}
                onSaveChange={this.handleOnSaveChangesInOffers}
              />
            ) : requests.length ? (
              <>
                <OfferFilter
                  title="Filtros Disponibles"
                  subtitle="Aplicación de Filtros a la lista de oportunidades pendientes de ajuste"
                  filters={filters}
                  options={filtersOptions}
                  values={filtersValues}
                  onClear={this.handleOnClearFilters}
                  onChange={this.handleOnChangeFilters}
                />
                <OffersAjustmentCard
                  title="Oportunidades pendientes de ajustar el costo"
                  cols={cols}
                  page={page}
                  sizePerPage={sizePerPage}
                  rows={this.renderRowsFiltered(requests)}
                  onOpen={this.handleOnOpenOffer}
                  onSetPage={this.handleOnSetPage}
                />
              </>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay oportunidades pendientes de ajustar el costo."
                    subtitle="Actualmente al día de hoy, no hay oportunidades pendientes de ajustar el costo, cuando existan se enlistarán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

OffersAjustment.defaultProps = {
  name: "Ofertas por ajustar costo",
  parentName: "Pricing",
  requests: [],
  cols: [
    { key: "id", name: "#REF" },
    { key: "version", name: "Versión" },
    { key: "opportunityNumber", name: "Oportunidad" },
    { key: "country", name: "País" },
    { key: "customer", name: "Cliente" },
    { key: "businessModel", name: "Modelo de Negocio" },
    { key: "status", name: "Estado" },
    { key: "createdBy", name: "Creado" },
    { key: "createdAt", name: "Fecha de Creación" },
    { key: "updatedAt", name: "Fecha de Actualización" },
  ],
  offersCalcs: {
    equipmentsCalc: [],
    servicesCalc: [],
    sparePartsCalc: [],
  },
  filters: [
    { key: "customer", name: "Cliente", placeholder: "Seleccione un cliente" },
    { key: "status", name: "Estado", placeholder: "Seleccione un estado" },
    {
      key: "opp",
      name: "Oportunidad",
      placeholder: "Seleccione una oportunidad",
    },
    { key: "country", name: "País", placeholder: "Seleccione un país" },
  ],
  filtersOptions: {
    customer: [],
    status: [],
    opp: [],
    country: [],
  },
  ajustOffer: {
    ajust: {},
    references: [],
  },
  logs: [],
  optionsFlow: [
    { key: "0", name: "Seleccione una opción", comment: "" },
    { key: "1", name: "Ya ajustado al mejor precio posible.", comment: "" },
    { key: "2", name: "No cuenta con soportes correspondientes.", comment: "" },
    { key: "3", name: "No aplica (despliegue justificación)", comment: "" },
  ],
};

OffersAjustment.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  requests: PropTypes.array.isRequired,
  offersCalcs: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  filtersOptions: PropTypes.object.isRequired,
  ajustOffer: PropTypes.object.isRequired,
  logs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  requests: getRequestInAjustment(state),
  offersCalcs: getOffersCalc(state),
  filtersOptions: getFiltersOptions(state),
  ajustOffer: getAjustAndReferences(state),
  logs: getLogsOffers(state),
});

export default withRouter(
  connect(mapStateToProps, {
    downloadCalsOfferByType,
    findAjustOfferWithLogById,
    findOffersRequestById,
    findRequestOffersInAjustment,
    updateStateRequest,
    updateActivityFlow,
    updateStatePricerListById,
    updateOfferCalcRequestByPricer,
    updateManualOfferCalcRequestByPricer,
  })(OffersAjustment)
);
