// core antd
import { Drawer, Icon, Spin, Upload } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useEffect } from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";

const { Dragger } = Upload;

const UploadServiceDrawer = (props) => {
  const { title, visible, uploading, fileList, onClose, onSave, onFileList } =
    props;

  const uploadProps = {
    name: "file",
    accept: ".xlsx",
    multiple: false,
    // action: 'https://ss-api.gbm.net/auth/test',
    action: "https://smartsimple.gbm.net:43888/auth/test",
    onChange: onFileList,
  };

  return (
    <>
      <Drawer
        title={title}
        width={340}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Spin
          size="large"
          spinning={uploading}
          tip="Creando los grupos de servicio"
        >
          <blockquote className="blockquote text-center">
            <p className="mb-0">Creación de varios grupos de servicios.</p>
            <footer className="blockquote-footer">
              <cite title="Source Title">Max 50 grupos a la vez</cite>
            </footer>
          </blockquote>
          <div className="form-row align-items-center">
            <Col className="m-1" sm="12">
              <Dragger {...uploadProps} fileList={fileList}>
                <p className="ant-uploading-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-uploading-text">
                  Suelte el archivo aquí o haga clic para cargar.
                </p>
                <p className="ant-uploading-hint">
                  *Se debe cargar el machote oficial de partes críticas.
                </p>
              </Dragger>
            </Col>
          </div>
          <div className="form-row align-items-center">
            <Col className="m-1" sm="12">
              <Button
                className="btn-icon"
                block
                outline
                color="default"
                type="submit"
                onClick={onSave}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-file-excel" />
                </span>
                <span className="btn-inner--text">Cargar Partes</span>
              </Button>
            </Col>
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

UploadServiceDrawer.propTypes = {
  title: PropTypes.string.isRequired,
};

export default UploadServiceDrawer;
