// core antd
import { Drawer, Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useEffect } from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Col, Form as FormRS, FormGroup, Input, Label } from "reactstrap";

const EditEquipment = props => {

  const {
    type,
    title,
    visible,
    options,
    loading,
    initialValues,
    onClose,
    onEdit
  } = props;

  const validate = values => {
    const errors = {};
    if (type === "ibm") {
      if (!values.country || values.country === "")
        errors.country = "Debes ingresar el nombre del país.";
      if (!values.typeModel || values.typeModel === "")
        errors.typeModel = "Debes ingresar el tipo de modelo.";
      if (!values.platform || values.platform === "")
        errors.platform = "Debes ingresar la plataforma.";
      if (!values.idTimeChangePart || values.idTimeChangePart === "0")
        errors.idTimeChangePart = "Debes seleccionar una opción.";
      if (!values.idAutomaticRenewal || values.idAutomaticRenewal === "0")
        errors.idAutomaticRenewal = "Debes seleccionar una opción.";
    } else if (type === "cisco") {
      if (!values.productNumber || values.productNumber === "")
        errors.productNumber = "Debes ingresar el número de producto.";
      if (!values.idCoverageLevel || values.idCoverageLevel === "0")
        errors.idCoverageLevel = "Debes seleccionar una opción.";
    }
    if (!values.serial || values.serial === "")
      errors.serial = "Debes ingresar la serie.";
    if (!values.description || values.description === "")
      errors.description = "Debes ingresar la descripción.";
    if (!values.amountMaintenance || values.amountMaintenance === "")
      errors.amountMaintenance = "Debes ingresar los Mantenimientos.";
    if (!values.amountHRMant || values.amountHRMant === "")
      errors.amountHRMant = "Debes ingresar la Cantidad/Hr/Mant.";
    if (!values.viatic || values.viatic === "")
      errors.viatic = "Debes ingresar los Viáticos.";
    if (!values.idProductID || values.idProductID === "0")
      errors.idProductID = "Debes seleccionar una opción.";
    if (!values.idOfficeHours || values.idOfficeHours === "0")
      errors.idOfficeHours = "Debes seleccionar una opción.";
    if (!values.idValidityService || values.idValidityService === "0")
      errors.idValidityService = "Debes seleccionar una opción.";
    if (!values.idEquipmentParts || values.idEquipmentParts === "0")
      errors.idEquipmentParts = "Debes seleccionar una opción.";
    // if (!values.price || values.price === "")
    //   errors.price = "Debes ingresar el precio.";
    // console.log(errors)
    return errors;
  };

  useEffect(() => {
    if (Object.keys(initialValues).length)
      initialValues.price = initialValues.price === 'N/A' ? null : parseInt(initialValues.price)
  });

  return (
    <Drawer
      title={title}
      width={340}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        onSubmit={onEdit}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, values, submitting, validating, valid }) => (
          <Spin size="large" spinning={loading} tip="Actualizando la parte crítica">
            <FormRS role="form">
              <div className="form-row">
                {
                  type === 'ibm' &&
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="country">País</Label>
                      <Field name="country">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Ingrese aquí el país."
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                }
                {
                  type === 'ibm' &&
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="typeModel">Tipo de Modelo</Label>
                      <Field name="typeModel">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Ingrese aquí el tipo de modelo."
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                }
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="serial">Serie</Label>
                    <Field name="serial">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            className="form-control-alternative"
                            type="text"
                            placeholder="Ingrese aquí la serie."
                            invalid={meta.error && meta.touched}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                {
                  type === 'cisco' &&
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="productNumber">Número de Producto</Label>
                      <Field name="productNumber">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Ingrese aquí el número de producto."
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                }
                {
                  type === 'ibm' &&
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="platform">Plataforma</Label>
                      <Field name="platform">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Ingrese aquí la plataforma."
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                }
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="amountMaintenance">Cantidad de Mantenimientos</Label>
                    <Field name="amountMaintenance">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            className="form-control-alternative"
                            type="number"
                            placeholder="Ingrese aquí los Mantenimientos."
                            invalid={meta.error && meta.touched}
                            min={1}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="description">Descripción</Label>
                    <Field name="description">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            className="form-control-alternative"
                            type="textarea"
                            maxLength={500}
                            rows="3"
                            resize="none"
                            placeholder="Ingrese aquí la Descripción."
                            invalid={meta.error && meta.touched}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="idProductID">Product ID</Label>
                    <Field name="idProductID">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="select"
                            invalid={meta.error && meta.touched}
                          >
                            <option value="0">Seleccione una opción</option>
                            {
                              options.productIds.map((row, key) => (
                                <option value={row.id} key={key}>{row.name}</option>
                              ))
                            }
                          </Input>
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="idOfficeHours">Horario de Atención</Label>
                    <Field name="idOfficeHours">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="select"
                            invalid={meta.error && meta.touched}
                          >
                            <option value="0">Seleccione una opción</option>
                            {
                              options.officeHours.map((row, key) => (
                                <option value={row.id} key={key}>{row.name}</option>
                              ))
                            }
                          </Input>
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                {
                  type === 'ibm' &&
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="idTimeChangePart">Tiempo para Cambio de Partes</Label>
                      <Field name="idTimeChangePart">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              invalid={meta.error && meta.touched}
                            >
                              <option value="0">Seleccione una opción</option>
                              {
                                options.timeChangePart.map((row, key) => (
                                  <option value={row.id} key={key}>{row.name}</option>
                                ))
                              }
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                }
                {
                  type === 'cisco' &&
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="idCoverageLevel">Nivel de Cobertura</Label>
                      <Field name="idCoverageLevel">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              invalid={meta.error && meta.touched}
                            >
                              <option value="0">Seleccione una opción</option>
                              {
                                options.coverageLevel.map((row, key) => (
                                  <option value={row.id} key={key}>{row.name}</option>
                                ))
                              }
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                }
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="idValidityService">Vigencia</Label>
                    <Field name="idValidityService">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="select"
                            invalid={meta.error && meta.touched}
                          >
                            <option value="0">Seleccione una opción</option>
                            {
                              options.validityService.map((row, key) => (
                                <option value={row.id} key={key}>{row.name}</option>
                              ))
                            }
                          </Input>
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                {
                  type === 'ibm' &&
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="idAutomaticRenewal">Renovación Automatica</Label>
                      <Field name="idAutomaticRenewal">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              invalid={meta.error && meta.touched}
                            >
                              <option value="0">Seleccione una opción</option>
                              {
                                options.automaticRenewal.map((row, key) => (
                                  <option value={row.id} key={key}>{row.name}</option>
                                ))
                              }
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                }
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="idEquipmentParts">Incluye Partes/Equipos</Label>
                    <Field name="idEquipmentParts">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="select"
                            invalid={meta.error && meta.touched}
                          >
                            <option value="0">Seleccione una opción</option>
                            {
                              options.includesParts.map((row, key) => (
                                <option value={row.id} key={key}>{row.name}</option>
                              ))
                            }
                          </Input>
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="amountHRMant">Cantidad/Hr/Mant</Label>
                    <Field name="amountHRMant">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            className="form-control-alternative"
                            type="number"
                            placeholder="Ingrese aquí la Cantidad/Hr/Mant."
                            invalid={meta.error && meta.touched}
                            min={1}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="viatic">Viáticos</Label>
                    <Field name="viatic">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            className="form-control-alternative"
                            type="number"
                            placeholder="Ingrese aquí los Viáticos."
                            invalid={meta.error && meta.touched}
                            min={1}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12">
                  <FormGroup>
                    <Label for="price">Precio</Label>
                    <Field name="price">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            className="form-control-alternative"
                            type="number"
                            placeholder="Ingrese aquí el precio."
                            invalid={meta.error && meta.touched}
                            min={1}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row align-items-center">
                <Col className="mb-1" sm="12">
                  <Button
                    disabled={submitting || loading}
                    className="btn-icon"
                    block
                    color="success"
                    type="submit"
                    onClick={handleSubmit || validating}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-save" />
                    </span>
                    <span className="btn-inner--text">Modificar Equipo</span>
                  </Button>
                </Col>
              </div>
            </FormRS>
          </Spin>
        )}
      />
    </Drawer>
  );
};

EditEquipment.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default EditEquipment;
