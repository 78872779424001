// javascipt plugin for creating charts
import Chart from "chart.js";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// reactstrap components
import { Card, CardBody, CardHeader, Row, Col, Input } from "reactstrap";
// core components
import {
    // global options for the charts
    chartOptions,
    // function that adds the global options to our charts
    parseOptions
} from "variables/charts.jsx";
import Select from "react-select";

class ChartBar extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }

    render() {

        const { title, subtitle, data, options, onChange, year, years, constant, checkButton, textRadio1, textRadio2, checked, stateCheck } = this.props;

        return (
            <>
                <Card>
                    <CardHeader>
                        {
                            checkButton === true &&
                            <Row className="d-flex justify-content-end">
                                <Col xs="2" sm="2">
                                    <div className="custom-control custom-radio mb-3">
                                        <Input
                                            className="custom-control-input"
                                            id="customRadio1"
                                            name="customRadio"
                                            type="radio"
                                            onClick={() => checked(1)}
                                            checked={stateCheck.check1}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadio1">
                                            {textRadio1}
                                        </label>
                                    </div>
                                </Col>
                                <Col xs="2" sm="2">

                                    <div className="custom-control custom-radio">
                                        <Input
                                            className="custom-control-input"
                                            id="customRadio2"
                                            name="customRadio"
                                            type="radio"
                                            onClick={() => checked(2)}
                                            checked={stateCheck.check2}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadio2">
                                            {textRadio2}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        }

                        <Row className="d-flex justify-content-between">
                            <Col xs="6" sm="6">
                                <h6 className="surtitle">{`Por ${title}`}</h6>
                                <h5 className="h3 mb-0">{subtitle}</h5>
                            </Col>
                            <Col xs="3" sm="3">
                                <Select
                                    className="basic-single"
                                    type="select"
                                    isSearchable
                                    options={years}
                                    defaultValue={{ label: year && year, value: 0 }}

                                    onChange={(e) => onChange(constant, e.value)}
                                />
                            </Col>

                        </Row>

                    </CardHeader>
                    <CardBody>
                        <div className="chart">
                            <Bar
                                data={data}
                                options={options}
                                className="chart-canvas"
                                id="chart-bar-stacked"
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }
}

ChartBar.propTypes = {
    title: PropTypes.string.isRequired,
    horizontal: PropTypes.bool,
};

export default ChartBar;
