import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import NotificationAlert from "react-notification-alert";
import { Container } from "reactstrap";
import { Spin } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  createCats,
  getCatsTasks,
  getCats,
  getOSNetworks,
  deleteOSNetworks,
  getOS,
  createOSNetworks,
  checkOSNetworks,
  getAllNoMrsReports,
  getExtraHours,
  updateVisibilityExtraHours,
  createExtraHours,
  getJustifications,
  getSchedule,
  validateTimeExtra,
  getPreapprovers,
} from "actions/HoursReport.jsx";

import ReportOptions from "components/HoursReport/ReportOptions.jsx";
import CATS from "components/HoursReport/CATS/CATS.jsx";
import DateTableFilters from "components/HoursReport/DateTableFilters";
import ActionsButton from "components/HoursReport/OSNetworks/ActionsButton.jsx";
import ActionButtons from "components/HoursReport/ExtraHours/ActionButtons.jsx";
import ModalNoMrs from "components/HoursReport/OSNetworks/ModalNoMrs.jsx";
import ModalDetailsExtra from "components/HoursReport/ExtraHours/ModalDetailsExtra.jsx";
import RecordTable from "components/HoursReport/RecordTable.jsx";
import ItemsTable from "components/HoursReport/OSNetworks/ItemsTable.jsx";
import SelectItemButton from "components/HoursReport/OSNetworks/SelectItemButton.jsx";
import ItemVerify from "components/HoursReport/OSNetworks/ItemVerify.jsx";
import CreateForm from "components/HoursReport/OSNetworks/CreateForm.jsx";
import CreationForm from "components/HoursReport/ExtraHours/CreationForm.jsx";
import CreationForm2 from "components/HoursReport/ExtraHours/CreationForm2.jsx";
import ModalRelateOrder from "components/HoursReport/ExtraHours/ModalRelateOrder.jsx";

import * as jwt from "jsonwebtoken";
import ButtonRelateOrder from "components/HoursReport/ExtraHours/ButtonRelateOrder";
import ModalVerifyApprover from "components/HoursReport/ExtraHours/ModalVerifyApprover";
import ActionButton from "components/HoursReport/CATS/ActionButton";
import ConflictDetails from "components/HoursReport/ConflictDetails";

import { desencrypt } from "helpers/desencrypt.jsx";

function Home({
  name,
  parentName,
  columnsCATS,
  columnsOSNetworks,
  columnsOSItems,
  columnsExtraHours,
  columnsRelateOrder,
  columnsRelateNetworkOrder,
}) {
  //=================================================================================================================

  //Functions and variables for general purpose

  //========================================================================================================
  const dispatch = useDispatch();
  const notifyEl = useRef(null);
  const [spinnerInfo, setSpinnerInfo] = useState({
    loading: false,
    message: null,
  });
  const [allNoMrsReports, setAllNoMrsReports] = useState([]);

  //Constants for editing page and amount of data showed at Record Tables
  const [page, setPage] = useState(1);
  const sizePerPage = 15;

  //Function to update page at RecordTable component
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  //Function to prepare the data that will be shown to the user at Record Tables in CATS, OS / Network and Extra Hours
  const preparePageData = (data, page, sizePerPage) => {
    const startIndex = (page - 1) * sizePerPage;
    const endIndex = startIndex + sizePerPage;
    const currentPageData = data.slice(startIndex, endIndex);
    return { startIndex, endIndex, currentPageData };
  };

  const notify = (type, title, message) => {
    try {
      let options = {
        place: "br",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {title}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 7,
      };
      notifyEl.current.notificationAlert(options);
    } catch (error) {}
  };

  // Calculate the difference between the end time and start time in hours
  const calculateQuantity = async (time1, time2) => {
    const format = "HH:mm:ss";

    // format times using Moment library to be able to use moment.diff
    const moment1 = moment(time1, format);
    const moment2 = moment(time2, format);

    const diffInMinutes = moment2.diff(moment1, "minutes");

    const diffInHours = diffInMinutes / 60;

    return diffInHours;
  };

  const [extraRol, setExtraRol] = useState(false);
  //This function checks if user has Extra Hours rol assigned
  const checkUserRolExtra = () => {
    const token = localStorage.getItem("token");
    try {
      const decoded = jwt.decode(token);
      const { teams } = decoded;
      if (teams.some((team) => team === "Extra Hours Report")) {
        setExtraRol(true);
      }
    } catch (error) {}
  };

  //Function to handle if show to the user CATS view,  OS-NETWORK view or Extra Hours view,
  //and reset some global variables
  const handleOnChangeOption = async (id) => {
    if (id === "castTable") {
      resetTimeFilterCats();
      setFilters({ filter1: "", filter2: "", filter3: "" });
      setOnCancel(false);
      setPage(1);
      setCatsTable(!catsTable);
    } else if (id === "noMrsTable") {
      resetTimeFilterNoMrs();
      setFilters({ filter1: "", filter2: "", filter3: "" });
      setNoMrsItemsDate("");
      setOnCancelNoMrs(false);
      setPage(1);
      setNoMrsTable(!noMrsTable);
    } else {
      setFilters({ filter1: "", filter2: "", filter3: "" });
      setNoMrsItemsDate("");
      resetTimeFilterCats();
      setExtraHoursTable(!extraHoursTable);
      setOnCancelExtra(false);
      setPage(1);
    }
  };

  const [conflictReportData, setConflictReportData] = useState(null);
  const [openConflictModal, setOpenConflictModal] = useState(false);
  const [showConflicButton, setShowConflicButton] = useState(false);
  //There are two global states for conflict time because times could be returned in two different formats
  const [conflictTime, setConflictTime] = useState("");
  const [conflictTime2, setConflictTime2] = useState("");

  //Function to show or hide Conflict modal in CATS, OS / Networks or Extra Hours creation Form
  const handleConflictModal = () => {
    setOpenConflictModal(!openConflictModal);
  };

  //Function to get the report that cause conflict, set it to a global state and show the conflict modal
  const searchConflictReport = async (reportType) => {
    let reportWithConflict;
    if (reportType === "Extra") {
      handleConflictModal();
    } else {
      //Search the report with conflicTime
      reportWithConflict = allNoMrsReports.find(
        (report) =>
          report.HORA_INICIO === conflictTime.time + ":00" &&
          report.HORA_FINAL === conflictTime.endTime + ":00" &&
          report.FECHA === conflictTime.date
      );

      //If the first conflictTime don´t found any report, the second conflicTime format is used
      if (reportWithConflict === undefined) {
        reportWithConflict = allNoMrsReports.find(
          (report) =>
            report.HORA_INICIO === conflictTime2.time + ":00" &&
            report.HORA_FINAL === conflictTime2.endTime + ":00" &&
            report.FECHA === conflictTime2.date
        );
      }

      setConflictReportData(reportWithConflict);
      handleConflictModal();
    }
  };

  //=================================================================================================================

  //Functions and variables for CATS Reports

  //=================================================================================================================
  const [errorsCATS, setErrorsCATS] = useState({
    CATSBEGUZ: false,
    CATSENDUZ: false,
    CATSINPDAT: false,
    CATSTASKTYPE: false,
    description: false,
  });

  const [taskTypes, setTasksTypes] = useState([]);
  const [catsTableData, setCatsDataTable] = useState([]);
  const [newCats, setNewCats] = useState({
    ASNUM: "",
    CATSBEGUZ: "",
    CATSENDUZ: "",
    CATSINPDAT: "",
    CATSTASKTYPE: "",
    CATSVARIAN: "ZGBM",
    SEBELN: "",
    SEBELP: "",
    taskType: "",
    INTERNALTYPE: "cats",
    quantity: "",
    description: "",
  });

  const [catsTable, setCatsTable] = useState(false);
  const [catsCreate, setCatsCreate] = useState(false);
  const [onCancel, setOnCancel] = useState(false);
  const [catsFilterTasks, setCatsFilterTasks] = useState([]);

  //Set endDate as actual date and endDate as 2 months ago by default
  const [filteredDateCats, setFilteredDateCats] = useState({
    date: moment().subtract(2, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  //Fuction for setting errors at input fields on CATS create Form
  const validateFieldsCats = () => {
    const newErrors = {
      CATSBEGUZ: newCats.CATSBEGUZ === "",
      CATSENDUZ: newCats.CATSENDUZ === "",
      CATSINPDAT: newCats.CATSINPDAT === "",
      taskType: newCats.taskType === "",
      description: newCats.description === "",
    };
    setErrorsCATS(newErrors);

    return Object.values(newErrors).some((value) => value === true);
  };

  //Fuction for going Back when user is on Cats Record Table
  const handleGoBackCatsTable = () => {
    setFilters({ filter1: "", filter2: "", filter3: "" });

    setCatsTable(!catsTable);
  };

  //Function to ask user to report again at the same OS or Network selected
  const handleReportAgainCats = async () => {
    const MySwal = withReactContent(Swal);
    const result = await MySwal.fire({
      title: "¡Atención!",
      text: `¿Quieres finalizar tu reporte o continuar reportando a esta misma actividad?`,
      showCancelButton: true,
      confirmButtonColor: "#2dce89",
      cancelButtonColor: "#fbc02d",
      confirmButtonText: "Finalizar reporte",
      cancelButtonText: "Continuar reportando",
    });

    if (result.value) {
      setCatsCreate(!catsCreate);
      setCatsTable(!catsTable);
      setNewCats({
        ASNUM: "",
        CATSBEGUZ: "",
        CATSENDUZ: "",
        CATSINPDAT: "",
        CATSTASKTYPE: "",
        CATSVARIAN: "ZGBM",
        SEBELN: "",
        SEBELP: "",
        taskType: "",
        INTERNALTYPE: "cats",
        quantity: "",
        description: "",
      });
      setFilters({ filter1: "", filter2: "", filter3: "" });
      setShowConflicButton(false);
      resetTimeFilterCats();
    }
  };

  //Function to get unique Cats tasks for Record table as a filter option
  function getTypeCatsTasks(data) {
    const uniqueTasks = new Set();
    data.forEach((item) => {
      uniqueTasks.add(item.TIPO_TAREA);
    });
    setCatsFilterTasks(Array.from(uniqueTasks));
  }

  //Fuction for apply selected filters to CATS  Record Table and return filtered data
  const applyFiltersCats = (filtersData) => {
    const filteredList = catsTableData.filter((item) => {
      return (
        (!filters.filter1 || item.ID_REPORTE.includes(filtersData.filter1)) &&
        (!filters.filter2 || item.HORA_INICIO?.includes(filtersData.filter2)) &&
        (!filters.filter3 || item.TIPO_TAREA?.includes(filtersData.filter3))
      );
    });
    return filteredList;
  };

  //Fuction setting new time filter at CATS Record Table
  const DatetimeChangeCATS = (key, value) => {
    setOnCancel(false);
    let date = moment(value);

    try {
      const formattedDate = date.format("YYYY-MM-DD");
      setFilteredDateCats((prevData) => ({
        ...prevData,
        [key]: formattedDate,
      }));
    } catch (error) {}
  };

  //Fuction for format columns and row displayed at CATS Record Table
  const formatRowDataCATS = (key, value, reporte) => {
    if (key == "FECHA") {
      const date = moment(value);
      return date.format("YYYY-MM-DD");
    } else if (key === "HORAS-REPORTADAS") {
      return `${reporte.HORA_INICIO.substring(
        0,
        5
      )} - ${reporte.HORA_FINAL.substring(0, 5)}`;
    } else if (key === "Acciones") {
      let buttonStatus = false;
      if (moment().date() > 2) {
        if (moment(reporte.FECHA).month() < moment().month()) {
          buttonStatus = true;
        }
      } else if (moment().date() == 1 || moment().date() == 2) {
        if (moment(reporte.FECHA).month() < moment().month() - 1) {
          buttonStatus = true;
        }
      }

      return (
        <ActionButton
          onDelete={handleDeleteCats}
          report={reporte}
          status={buttonStatus}
        />
      );
    } else if (key === "quantity") {
      const roundedValue = Math.round(value * 100) / 100;
      return `${roundedValue} horas`;
    } else {
      return value;
    }
  };

  //Function to show CATS creation Form
  const handleCreateCATS = async () => {
    setOnCancel(true);
    setCatsTable(!catsTable);
    setCatsCreate(!catsCreate);
    await getTasks();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // function to handle quantity time calc when initial time and end time are set
  const setQuantityCATS = async (id, value) => {
    if (id === "CATSENDUZ" && newCats.CATSBEGUZ != "") {
      const result = await calculateQuantity(newCats.CATSBEGUZ, value);
      setNewCats((prevData) => ({
        ...prevData,
        ["quantity"]: result,
      }));
    } else if (id === "CATSBEGUZ" && newCats.CATSENDUZ != "") {
      const result = await calculateQuantity(value, newCats.CATSENDUZ);
      setNewCats((prevData) => ({
        ...prevData,
        ["quantity"]: result,
      }));
    }
  };

  //Function to handle inputs when user enters data
  const handleInputCats = async (id, value) => {
    //Conditions to set time format
    if (id === "CATSBEGUZ" || id === "CATSENDUZ") {
      setShowConflicButton(false);
      value = `${value}:00`;
      await setQuantityCATS(id, value);
    } else if (id === "CATSINPDAT") {
      setShowConflicButton(false);
    }

    setNewCats((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrorsCATS((prevErrors) => ({
      ...prevErrors,
      [id]: false,
    }));
  };

  //Function to handle selected input when user selects a CATS task in creation Form
  const selectedTasks = (index) => {
    setNewCats((prevData) => ({
      ...prevData,
      CATSTASKTYPE: taskTypes[index].TASKTYPE,
      taskType: taskTypes[index].TEXT,
    }));

    setErrorsCATS((prevErrors) => ({
      ...prevErrors,
      taskType: false,
    }));
  };

  //Function get all CATS task types from SAP
  const getTasks = async () => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo tipos de CATS",
    });
    const resp = await dispatch(getCatsTasks());
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error al conectarse con el servidor");
      } else {
        const { message } = resp.payload.response.data.payload;
        const { status } = resp.payload.data.payload.status;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      const { data } = resp.payload;
      if (data.status === 200) {
        setTasksTypes(data.payload.data.ZTYPES.item);
        notify("success", "Obtenidos exitosamente", data.payload.message);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function get all CATS reports from MIS DB
  const getCatsRecord = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo reportes de CATS",
    });

    const resp = await dispatch(getCats(filteredDateCats));
    setOnCancel(true);
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error al conectarse con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
          setCatsDataTable([]);
          getTypeCatsTasks([]);
        } else if (status === 422) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      const { data } = resp.payload;
      if (data.status === 200) {
        // Ordenar el array por la propiedad FECHA, de más nuevo a más antiguo, esta data viene de SAP entonces se ordena en el FE
        const sortedData = data.payload.data.sort((a, b) => {
          return new Date(b.FECHA) - new Date(a.FECHA);
        });
        setCatsDataTable(sortedData);
        getTypeCatsTasks(sortedData);
        notify("success", "Obtenidos exitosamente", data.payload.message);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function reset time filters
  const resetTimeFilterCats = () => {
    setOnCancel(false);
    setFilteredDateCats({
      date: moment().subtract(2, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
  };

  //Function send a new Cats Report
  const handleOnSendCats = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const isError = validateFieldsCats();

    if (isError) {
      notify(
        "warning",
        "Atención",
        "Porfavor completa todos los campos del formulario antes de enviar tu reporte"
      );
    } else {
      setSpinnerInfo({
        loading: true,
        message: "Creando reporte de CATS",
      });

      const resp = await dispatch(createCats(newCats));

      if (resp.payload.isAxiosError) {
        if (resp.payload.response === undefined) {
          notify("danger", "Falló", "Error al conectarse con el servidor");
        } else {
          const { status } = resp.payload.response.data;
          const { message } = resp.payload.response.data.payload;
          if (status === 500) {
            notify("danger", "Falló", message);
          } else if (status === 404) {
            if (resp.payload.response.data.payload.data) {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              notify("warning", "Atención", message);
              if (resp.payload.response.data.payload.data.RESPONCE) {
                if (
                  resp.payload.response.data.payload.data.RESPONCE.includes(
                    "Time collision"
                  )
                ) {
                  const timeConflicts =
                    resp.payload.response.data.payload.data.RESPONCE.split(
                      "with"
                    )[0]
                      .split("collision")[1]
                      .split("-");
                  setConflictTime({
                    time: timeConflicts[0].trim(),
                    endTime: timeConflicts[1].trim(),
                    date: newCats.CATSINPDAT,
                  });

                  const timeConflicts2 =
                    resp.payload.response.data.payload.data.RESPONCE.split(
                      "with"
                    )[1].split("-");
                  setConflictTime2({
                    time: timeConflicts2[0].trim(),
                    endTime: timeConflicts2[1].trim(),
                    date: newCats.CATSINPDAT,
                  });

                  await getAllNoMrs();

                  setShowConflicButton(true);
                }
              }
            } else {
              notify("warning", "Atención", message);
            }
          } else if (status === 422) {
            notify("warning", "Atención", message);
          }
        }
      } else {
        if (resp.payload.data.status === 200) {
          notify(
            "success",
            "Creación exitosa",
            resp.payload.data.payload.message
          );
          handleReportAgainCats();
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      setSpinnerInfo({
        loading: false,
        message: null,
      });
    }
  };

  //Function to cancel or go back when user is inside CATS creation Form
  const handleOnCancelCats = () => {
    setOnCancel(true);
    setCatsCreate(!catsCreate);
    setShowConflicButton(false);
    setCatsTable(!catsTable);
    setNewCats({
      ASNUM: "",
      CATSBEGUZ: "",
      CATSENDUZ: "",
      CATSINPDAT: "",
      CATSTASKTYPE: "",
      CATSVARIAN: "ZGBM",
      SEBELN: "",
      SEBELP: "",
      taskType: "",
      INTERNALTYPE: "cats",
      quantity: "",
      description: "",
    });
    setErrorsCATS({
      CATSBEGUZ: false,
      CATSENDUZ: false,
      CATSINPDAT: false,
      CATSTASKTYPE: false,
    });
    setFilters({ filter1: "", filter2: "", filter3: "" });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDeleteCats = async (idReport) => {
    const MySwal = withReactContent(Swal);
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      text: `¿Quieres eliminar el reporte #${idReport}?`,
      showCancelButton: true,
      confirmButtonColor: "#2dce89",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });

    if (result.value) {
      await onDeletingNoMrs(idReport);
      await getCatsRecord();
    }
  };

  //=================================================================================================================

  //Functions and variables for OS / Networks Reports (NoMrs)

  //=================================================================================================================

  //Variable to store data of a new NoMrs report before send it
  const [newNoMrs, setNewNoMrs] = useState({
    description: "",
    transaction: 0,
    item: "",
    date: "",
    time: "",
    endTime: "",
    duration: 0,
    sub_active: "",
    itemDescription: "",
    utc: "",
    dateReport: moment().format("DD-MM-YYYY"),
  });

  const [errorsNoMrs, setErrorsNoMrs] = useState({
    date: false,
    time: false,
    endTime: false,
    item: false,
    description: false,
  });
  const [errorsVerification, setErrorsVerification] = useState({
    idReport: false,
  });
  const [idContainsLetter, setIdContainsLetter] = useState(false);
  const [idVerifyNetworkType, setIdVerifyNetworkType] = useState(false);
  const [listOSItems, setListOSItems] = useState([]);
  const [selectedReportTypeNetwork, setSelectedReportTypeNetwork] =
    useState(false);
  const [idNoMrs, setIdNoMrs] = useState("");
  const [sendButtonStatus, setSendButtonStatus] = useState(false);
  const [itemSelected, setItemSelected] = useState({ Id: "", descrip: "" });
  const [typeOSNetworkTasks, setTypeOSNetworkTasks] = useState([]);
  const [detallesMrs, setDetallesMrs] = useState({});
  const [isOS, setIsOS] = useState(true);
  const [noMrsCreate, setNoMrsCreate] = useState(false);
  const [noMrsItemsList, setNoMrsItemsList] = useState([]);
  const [noMrsItemsDate, setNoMrsItemsDate] = useState("");
  //fixedDatetFlag arreglo para problema fecha en items asignados
  const [fixedDateFlag, setFixedDateFlag] = useState(false);
  const [noMrsCheckItems, setNoMrsCheckItems] = useState([]);
  const reportType = ["Órden de Servicio", "Network"];
  const [onCancelNoMrs, setOnCancelNoMrs] = useState(false);
  const [openDetailsNoMrs, setOpenDetailsNoMrs] = useState(false);
  const [osNetworksTableData, setOSNetworksTableData] = useState([]);
  const [noMrsTable, setNoMrsTable] = useState(false);
  const [noMrsShowItems, setNoMrsShowItems] = useState(false);
  const [filters, setFilters] = useState({
    filter1: "",
    filter2: "",
    filter3: "",
  });
  const [filteredDateOSNetworks, setfilteredDateOSNetworks] = useState({
    date: moment().subtract(2, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  //Function to handle date filter at Items assigned
  const handleItemsDate = async (date) => {
    try {
      const formatDate = date.format("YYYY-MM-DD");
      //fixedDatetFlag arreglo para problema fecha en items asignados
      if (formatDate === "2015-01-01" && fixedDateFlag === true) {
        setNoMrsItemsDate("");
        setFixedDateFlag(false);
        setNoMrsItemsDate("");
        await getServiceOrders({ date: formatDate });
      } else {
        setFixedDateFlag(false);
        setNoMrsItemsDate(formatDate);
        await getServiceOrders({ date: formatDate });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Function to reset date filter at Items assigned
  const resetItemsDate = async () => {
    setNoMrsItemsDate("");
    setFilters({
      filter1: "",
      filter2: "",
      filter3: "",
    });
    setFixedDateFlag(true);
    await getServiceOrders({ date: "" });
  };

  // Function to validate if there are empty input field in NoMrs creation Form
  const validateFieldsNoMrs = () => {
    const newErrors = {
      description: newNoMrs.description === "",
      date: newNoMrs.date === "",
      time: newNoMrs.time === "",
      endTime: newNoMrs.endTime === "",
      item: newNoMrs.item === "",
    };
    setErrorsNoMrs(newErrors);

    return Object.values(newErrors).some((value) => value === true);
  };

  // Function to allow the user to go back from the NoMrs Record table
  const handleGoBackNoMrsTable = () => {
    setFilters({ filter1: "", filter2: "", filter3: "" });
    setNoMrsTable(!noMrsTable);
  };

  // Function to apply filters to NoMrs´s  Record Table data
  const applyFiltersNoMrs = (filtersData) => {
    const filteredList = osNetworksTableData.filter((item) => {
      return (
        (!filters.filter1 || item.DESCRIPCION?.includes(filtersData.filter1)) &&
        (!filters.filter2 || item.NETWORK?.includes(filtersData.filter2)) &&
        (!filters.filter3 || item.TIPO_TAREA?.includes(filtersData.filter3))
      );
    });
    return filteredList;
  };

  // Function to set filters input at NoMrs´s  Record Table View
  const handleSetFilters = (filter, value) => {
    setFilters((prevData) => ({
      ...prevData,
      [filter]: value,
    }));
  };

  //Function to allow the user to go back from the NoMrs´s assigned items list table
  const handleGoBackNoMrsItemsList = () => {
    setFilters({ filter1: "", filter2: "", filter3: "" });
    setFixedDateFlag(true);
    setErrorsVerification((prevData) => ({
      ...prevData,
      idReport: false,
    }));

    setSelectedReportTypeNetwork(false);
    setPage(1);
    setNoMrsShowItems(!noMrsShowItems);
    setNoMrsTable(!noMrsTable);
    setNoMrsItemsDate("");
    setFilters({
      filter1: "",
      filter2: "",
      filter3: "",
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Function to check if introduced ID is valid at Creation by ID section and set item data
  const checkOrder = async (id) => {
    setSpinnerInfo({
      loading: true,
      message: "Verificando ID",
    });
    const resp = await dispatch(checkOSNetworks({ os: id }));
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error al conectarse con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
        } else if (status === 422) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      if (resp.payload.data.status === 200) {
        notify(
          "success",
          "Verificación exitosa",
          resp.payload.data.payload.message
        );
        setNoMrsCheckItems(resp.payload.data.payload.data.DETAILS.item);
        if (selectedReportTypeNetwork === false) {
          if (resp.payload.data.payload.data.DETAILS.item.length == 1) {
            handleSelectOsFromCheck(
              resp.payload.data.payload.data.DETAILS.item[0]
            );
          } else {
            //Esta funcion lleva al usuario al formulario de creacion de reporte con una lista de items a seleccionar
            //Esto si es Orden de servicio
            handleSelectNetworkFromCheck();
          }
        } else {
          //Esta funcion lleva al usuario al formulario de creacion de reporte con una lista de items a seleccionar
          //Esto si es una Network
          handleSelectNetworkFromCheck();
        }
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function manage filters by date on NoMrs Record Table
  const DatetimeChangeNoMrs = (key, value) => {
    setOnCancelNoMrs(false);
    let date = moment(value);

    try {
      const formattedDate = date.format("YYYY-MM-DD");
      setfilteredDateOSNetworks((prevData) => ({
        ...prevData,
        [key]: formattedDate,
      }));
    } catch (error) {}
  };

  //Function to delete a NoMrs report
  const onDeletingNoMrs = async (idReport) => {
    const id = { counter: idReport };
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setSpinnerInfo({
      loading: true,
      message: "Eliminando registro",
    });
    const resp = await dispatch(deleteOSNetworks(id));
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error al conectarse con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
        } else if (status === 422) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      if (resp.payload.data.status === 200) {
        notify(
          "success",
          "Eliminación exitosa",
          resp.payload.data.payload.message
        );
      }
    }

    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function to show alert when trying to delete a NoMrs report from record table
  const handleDeleteNoMrs = async (idReport) => {
    const MySwal = withReactContent(Swal);
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      text: `¿Quieres eliminar el reporte #${idReport}?`,
      showCancelButton: true,
      confirmButtonColor: "#2dce89",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });

    if (result.value) {
      await onDeletingNoMrs(idReport);
      await getOSNetworksRecord();
    }
  };

  //Function to ask user to report again at the same OS or Network selected
  const handleReportAgain = async () => {
    const MySwal = withReactContent(Swal);
    const result = await MySwal.fire({
      title: "¡Atención!",
      text: `¿Quieres finalizar tu reporte o continuar reportando a esta misma orden?`,
      showCancelButton: true,
      confirmButtonColor: "#2dce89",
      cancelButtonColor: "#fbc02d",
      confirmButtonText: "Finalizar reporte",
      cancelButtonText: "Continuar reportando",
    });

    if (result.value) {
      setNoMrsCreate(!noMrsCreate);
      setOnCancelNoMrs(false);
      setNoMrsShowItems(!noMrsShowItems);
      setNewNoMrs({
        description: "",
        transaction: 0,
        date: "",
        time: "",
        endTime: "",
        duration: 0,
        itemDescription: "",
        utc: "",
        dateReport: moment().format("DD-MM-YYYY"),
      });
      setIdNoMrs("");
      setIdContainsLetter(false);
      setSelectedReportTypeNetwork(false);
      setNoMrsCheckItems([]);
      setNoMrsItemsDate("");
      setShowConflicButton(false);
      setFilters({
        filter1: "",
        filter2: "",
        filter3: "",
      });
    }
  };

  //Function to show modal with details of a NoMrs Report made by the user
  const handleDetailsNoMrs = (reporte) => {
    setDetallesMrs(reporte);
    if (reporte.NETWORK !== null) {
      setIsOS(false);
    } else {
      setIsOS(true);
    }
    setOpenDetailsNoMrs(!openDetailsNoMrs);
  };

  //Function to set UTC and data of OS Item assigned when user use Creation by ID section
  const handleSelectOsFromCheck = async (item) => {
    setNoMrsShowItems(!noMrsShowItems);
    await getUtc();
    updateSelectedItem(item);
    handleUpdateItemByCheck(item);
    setNoMrsCreate(!noMrsCreate);
  };

  //Function to set UTC of Network when user use Creation by ID section
  const handleSelectNetworkFromCheck = async () => {
    setNoMrsShowItems(!noMrsShowItems);
    await getUtc();
    setNoMrsCreate(!noMrsCreate);
  };

  //Fuction for format columns and row displayed at NoMrs Record Table
  const formatRowDataNoMrs = (key, value, reporte) => {
    if (key === "DESCRIPCION" || key === "NETWORK") {
      if (value === null) {
        return "N/A";
      } else {
        return value;
      }
    } else if (key === "Acciones") {
      let buttonStatus = false;
      if (moment().date() > 2) {
        if (moment(reporte.FECHA).month() < moment().month()) {
          buttonStatus = true;
        }
      } else if (moment().date() == 1 || moment().date() == 2) {
        if (moment(reporte.FECHA).month() < moment().month() - 1) {
          buttonStatus = true;
        }
      }
      return (
        <ActionsButton
          onDelete={handleDeleteNoMrs}
          onDetails={handleDetailsNoMrs}
          report={reporte}
          status={buttonStatus}
        />
      );
    } else {
      return value;
    }
  };

  //Fuction to get data of an item when user provide an ID
  const getItembyId = async () => {
    handleVerificationInputs();
    setFilters({
      filter1: "",
      filter2: "",
      filter3: "",
    });
    setFixedDateFlag(true);
    if (idContainsLetter && idNoMrs !== "") {
      if (idVerifyNetworkType === selectedReportTypeNetwork) {
        await checkOrder(idNoMrs);
      } else {
        notify(
          "warning",
          "Atención",
          "ID digitado no corresponde al tipo de reporte seleccionado"
        );
      }
    } else {
      notify("warning", "Atención", "Debe introducir un ID válido");
    }
  };

  //Function to set data of Item when user use Creation by ID section
  const handleUpdateItemByCheck = (item) => {
    setNewNoMrs((prevData) => ({
      ...prevData,
      transaction: item.ID,
    }));

    setNewNoMrs((prevData) => ({
      ...prevData,
      item: item.ITEM,
    }));

    setNewNoMrs((prevData) => ({
      ...prevData,
      itemDescription: item.ORD_NAME,
    }));

    if (item.SUB_ACTIVITY === null) {
      setNewNoMrs((prevData) => ({
        ...prevData,
        sub_active: "",
      }));
    } else if (item.SUB_ACTIVITY != null) {
      setNewNoMrs((prevData) => ({
        ...prevData,
        sub_active: item.SUB_ACTIVITY,
      }));
    }
  };

  //Function to handle item selected in Network report at NoMrs Creation Form
  const handleOnSelectedItem = (index) => {
    setErrorsNoMrs((prevErrors) => ({
      ...prevErrors,
      item: false,
    }));

    const item = noMrsCheckItems[index];
    handleUpdateItemByCheck(item);
  };

  //Function to set data of Item when user use Creation by assigned item section
  const handleUpdateItem = (item) => {
    setNewNoMrs((prevData) => ({
      ...prevData,
      transaction: item.IdOrden,
    }));

    setNewNoMrs((prevData) => ({
      ...prevData,
      item: item.IdItem,
    }));

    setNewNoMrs((prevData) => ({
      ...prevData,
      itemDescription: item.DescItem,
    }));

    if (item.SUB_ACTIVITY === undefined || item.SUB_ACTIVITY === null) {
      setNewNoMrs((prevData) => ({
        ...prevData,
        sub_active: "",
      }));
    } else if (item.SUB_ACTIVITY != null) {
      setNewNoMrs((prevData) => ({
        ...prevData,
        sub_active: item.SUB_ACTIVITY,
      }));
    }
  };

  //Function to get tasks filter in NoMrs´s Record Table
  function getTypeOSNetworkTasks(data) {
    const uniqueTasks = new Set();
    data.forEach((item) => {
      uniqueTasks.add(item.TIPO_TAREA);
    });
    setTypeOSNetworkTasks(Array.from(uniqueTasks));
  }

  //Function to set selected ID item when user clicks the button in row on Creation by Item assigned at Items selection Table
  const updateSelectedItem = (item) => {
    setItemSelected((prevData) => ({
      ...prevData,
      Id: item.IdItem ? item.IdItem : item.ITEM,
    }));

    setItemSelected((prevData) => ({
      ...prevData,
      descrip: item.DescItem ? item.DescItem : item.ORD_NAME,
    }));
  };
  const handleSelectItemFromTable = async (item) => {
    setNoMrsCheckItems([item]);
    setFilters({
      filter1: "",
      filter2: "",
      filter3: "",
    });
    setFixedDateFlag(true);
    setNoMrsShowItems(!noMrsShowItems);
    await getUtc();
    updateSelectedItem(item);
    handleUpdateItem(item);
    setNoMrsCreate(!noMrsCreate);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Fuction to format columns and row displayed at Items selection Table
  const formatRowItemsNoMrs = (key, value, item) => {
    if (key === "Acciones") {
      return (
        <SelectItemButton onSelect={handleSelectItemFromTable} item={item} />
      );
    } else if (key === "IdItem") {
      return value.slice(-4);
    } else {
      return value;
    }
  };

  const applyFiltersAssignedItemsTable = (filtersData) => {
    const filteredList = noMrsItemsList.filter((request) => {
      return !filters.filter1 || request.IdOrden?.includes(filtersData.filter1);
    });
    return filteredList;
  };

  //Function to show NoMrs Assigned Items Table
  const handleItemsAssigned = async () => {
    setFilters({
      filter1: "",
      filter2: "",
      filter3: "",
    });
    setFixedDateFlag(true);
    setOnCancelNoMrs(true);
    setNoMrsTable(!noMrsTable);
    setNoMrsShowItems(!noMrsShowItems);
    setPage(1);
    await getServiceOrders();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Handle the case when NoRms report type is chosen at Item Table view.
  const handleSelectReporType = (selected) => {
    if (selected === "0") {
      setSelectedReportTypeNetwork(false);
      setNetworkVerify(false);
    } else {
      setSelectedReportTypeNetwork(true);
    }
  };

  //Handle the ID clasification (Network or OS) at Creation by ID section
  const checkIDOSNoMrs = (id) => {
    const verifyNetwork = id.startsWith("4");
    if (verifyNetwork) {
      setIdVerifyNetworkType(true);
    } else {
      setIdVerifyNetworkType(false);
    }
  };

  //Handle the ID verification at Creation by ID section and set ID data
  const handleIdNoMrs = (value) => {
    checkIDOSNoMrs(value);
    setIdNoMrs(value);
    setErrorsVerification((prevErrors) => ({
      ...prevErrors,
      idReport: false,
    }));

    if (/^\d+$/.test(value)) {
      setIdContainsLetter(true);
    } else {
      setIdContainsLetter(false);
    }
  };

  //Handle the error of the ID given by the user to report by ID
  const handleVerificationInputs = async () => {
    const newErrors = {
      idReport: !idNoMrs,
    };
    setErrorsVerification(newErrors);
  };

  //Function to set duration of a NoMrs Report
  const setQuantityNoMrs = async (id, value) => {
    if (id === "endTime") {
      const result = await calculateQuantity(newNoMrs.time, value);
      setNewNoMrs((prevData) => ({
        ...prevData,
        ["duration"]: result,
      }));
    } else if (id === "time" && newNoMrs.endTime != "") {
      const result = await calculateQuantity(value, newNoMrs.endTime);
      setNewNoMrs((prevData) => ({
        ...prevData,
        ["duration"]: result,
      }));
    }
  };
  //Function to handle inputs at NoMrs Creation Form
  const handleInputNoMrsForm = async (id, value) => {
    if (id === "time" || id === "endTime") {
      setShowConflicButton(false);
      value = `${value}:00`;
      await setQuantityNoMrs(id, value);
      setShowConflicButton(false);
    } else if (id === "date") {
      setShowConflicButton(false);
    }

    setNewNoMrs((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrorsNoMrs((prevErrors) => ({
      ...prevErrors,
      [id]: false,
    }));
  };
  //Function to get UTC for a NoMrs report
  const getUtc = async () => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const hours = Math.abs(Math.floor(offsetInMinutes / 60));

    const offsetString =
      (offsetInMinutes < 0 ? "+" : "-") + ("00" + hours).slice(-2);

    setNewNoMrs((prevData) => ({
      ...prevData,
      utc: "UTC" + offsetString,
    }));
  };

  //Function get All NoMrs Reports
  const getAllNoMrs = async () => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo reporte con conflicto",
    });
    const dates = {
      date: moment().subtract(2, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
    const resp = await dispatch(getAllNoMrsReports(dates));

    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
          setAllNoMrsReports([]);
        } else if (status === 422) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      const { data } = resp.payload;
      if (resp.payload.data.status === 200) {
        setAllNoMrsReports(data.payload.data);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function get all OS / Network Reports
  const getOSNetworksRecord = async () => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo reportes de OS / NETWORKS",
    });

    const resp = await dispatch(getOSNetworks(filteredDateOSNetworks));
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
          setOSNetworksTableData([]);
          getTypeOSNetworkTasks([]);
        } else if (status === 422) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      const { data } = resp.payload;
      if (resp.payload.data.status === 200) {
        // Ordenar el array por la propiedad FECHA, de más nuevo a más antiguo, esta data viene de SAP entonces se ordena en el FE
        const sortedData = data.payload.data.sort((a, b) => {
          return new Date(b.FECHA) - new Date(a.FECHA);
        });
        setOSNetworksTableData(sortedData);
        getTypeOSNetworkTasks(sortedData);
        notify("success", "Obtenidos exitosamente", data.payload.message);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function get all Assigned Items
  const getServiceOrders = async (filtroDate = { date: "" }) => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo Órdenes de servicio",
    });
    const resp = await dispatch(getOS(filtroDate));
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
          setNoMrsItemsList([]);
        } else if (status === 422) {
          notify("warning", "Atención", message);
          setNoMrsItemsList([]);
        } else if (status === 404) {
          notify("warning", "Atención", message);
          setNoMrsItemsList([]);
        }
      }
    } else {
      const { data } = resp.payload;
      if (resp.payload.data.status === 200) {
        notify("success", "Obtenidas exitosamente", data.payload.message);
        setNoMrsItemsList(data.payload.data.EtDetails.item);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };
  //Function create a NoMrs Report
  const onSendOsNetwork = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const isError = validateFieldsNoMrs();

    if (isError) {
      notify(
        "warning",
        "Atención",
        "Porfavor completa todos los campos del formulario antes de enviar tu reporte"
      );
    } else {
      setSpinnerInfo({
        loading: true,
        message: "Creando registro",
      });

      const resp = await dispatch(createOSNetworks(newNoMrs));
      if (resp.payload.isAxiosError) {
        if (resp.payload.response === undefined) {
          notify("danger", "Falló", "Error de conexión con el servidor");
        } else {
          const { message } = resp.payload.response.data.payload;
          const { status } = resp.payload.response.data;
          if (status === 500) {
            notify("danger", "Falló", message);
          } else if (status === 404) {
            if (resp.payload.response.data.payload.error) {
              if (
                resp.payload.response.data.payload.error.includes(
                  "Time collision"
                )
              ) {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                notify("warning", "Atención", message);
                const timeConflicts = resp.payload.response.data.payload.error
                  .split("with")[1]
                  .split("-");
                setConflictTime({
                  time: timeConflicts[0].trim(),
                  endTime: timeConflicts[1].trim(),
                  date: newNoMrs.date,
                });

                const timeConflicts2 = resp.payload.response.data.payload.error
                  .split("with")[0]
                  .split("collision")[1]
                  .split("-");
                setConflictTime2({
                  time: timeConflicts2[0].trim(),
                  endTime: timeConflicts2[1].trim(),
                  date: newNoMrs.date,
                });

                await getAllNoMrs();
                setShowConflicButton(true);
              } else {
                notify("warning", "Atención", message);
              }
            } else {
              notify("warning", "Atención", message);
            }
          } else if (status === 422) {
            notify("warning", "Atención", message);
          }
        }
      } else {
        const { data } = resp.payload;
        if (data.status === 200) {
          notify("success", "Creación exitosa", data.payload.message);
          handleReportAgain();
        }
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };
  //Function reset time filters at NoMrs Record Table
  const resetTimeFilterNoMrs = () => {
    setOnCancelNoMrs(false);
    setfilteredDateOSNetworks({
      date: moment().subtract(2, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
  };
  //Function to cancel a NoMrs Creation Form
  const handleOnCancelNoMrs = () => {
    setNoMrsCreate(!noMrsCreate);
    setShowConflicButton(false);
    setNoMrsShowItems(!noMrsShowItems);
    setNewNoMrs({
      description: "",
      transaction: 0,
      date: "",
      time: "",
      endTime: "",
      duration: 0,
      itemDescription: "",
      utc: "UTC-6",
      dateReport: moment().format("DD-MM-YYYY"),
    });
    setErrorsNoMrs({
      date: false,
      time: false,
      endTime: false,
      item: false,
      description: false,
    });
    setIdNoMrs("");
    setIdContainsLetter(false);
    setSelectedReportTypeNetwork(false);
    setNoMrsCheckItems([]);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //=====================================================================================================================

  //Functions and variables for Extra Hours Reports

  //=====================================================================================================================
  const [allEmployees, setAllEmployees] = useState([]);
  const [extraHoursTable, setExtraHoursTable] = useState(false);
  const [extraHoursDetails, setExtraHoursDetails] = useState({});
  const [openDetailsExtraHours, setOpenDetailsExtraHours] = useState(false);
  const [extraHoursTableData, setExtraHoursTableData] = useState([]);
  const [extraHoursCreate, setExtraHoursCreate] = useState(false);
  const [extraHoursCreate2, setExtraHoursCreate2] = useState(false);
  const [justifications, setJustifications] = useState([]);
  const [preApprover, setApprover] = useState(null);
  const [manager, setManager] = useState("");
  const [showRelateOrder, setShowRelateOrder] = useState(false);
  const [showVerifyApprov, setShowVerifyApprov] = useState(false);
  const [networkVerify, setNetworkVerify] = useState(false);
  const [extraPreapproverStatus, setExtraPreapproverStatus] = useState([]);
  const [extraCEOStatus, setExtraCEOStatus] = useState([]);
  const [onCancelExtra, setOnCancelExtra] = useState(true);
  const [haveRelatedOrder, setHaveRelatedOrder] = useState(null);
  const [filterDateExtraHours, setFilterDateExtraHours] = useState({
    initDate: moment().subtract(2, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [showTimeExtra, setShowTimeExtra] = useState({
    horas: 0,
    minutos: 0,
  });
  const [errorsExtratForm1, setErrorsExtratForm1] = useState({
    date: false,
    time: false,
    endTime: false,
  });

  const [errorsExtratForm2, setErrorsExtratForm2] = useState({
    description: false,
    justification: false,
  });

  // Obtiene la fecha actual (fecha de hoy a las 00:00:00)
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Calcula la fecha máxima (hoy)
  const maxDateExtraCalendar = currentDate.toISOString().split("T")[0];

  // Calcula la fecha mínima (hace dos meses)
  const minDateExtraCalendar = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  )
    .toISOString()
    .split("T")[0];

  const handleVerifyApprov = () => {
    setShowVerifyApprov(!showVerifyApprov);
    setApprover(null);
  };

  const onInputPreapprover = (employee) => {
    setApprover(employee[0]);
  };

  //Function to get all preapprover employees
  const getAllEmployees = async () => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo preaprobadores",
    });
    const resp = await dispatch(getPreapprovers());
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 422) {
          notify("warning", "Atención", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      let data = desencrypt(resp.payload.data.payload.data);
      data = JSON.parse(data);
      if (resp.payload.data.status === 200) {
        notify(
          "success",
          "Obtenidos exitosamente",
          resp.payload.data.payload.message
        );
        setAllEmployees(data[0]);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function to handle preapprover data selected in select a preapprover section
  const handlePreapprover = async () => {
    if (preApprover && preApprover.user) {
      setNewExtraHours((prevData) => ({
        ...prevData,
        preApprover: preApprover.user,
        preApproverName: preApprover.name,
      }));
      setShowVerifyApprov(!showVerifyApprov);
    }
  };

  //Function to set justification selected at Extra Hours CreationForm2
  const selectedJustification = (index) => {
    setNewExtraHours((prevData) => ({
      ...prevData,
      justification: justifications[index].MOTIVO,
    }));

    setErrorsExtratForm2((prevErrors) => ({
      ...prevErrors,
      justification: false,
    }));
  };

  //Function to check if there is any blank input at Extra Hours CreationForm
  const validateFieldsExtraForm1 = () => {
    const newErrors = {
      date: newExtraHours.date === "",
      time: newExtraHours.time === "",
      endTime: newExtraHours.endTime === "",
    };
    setErrorsExtratForm1(newErrors);
    if (newErrors.date || newErrors.time || newErrors.endTime) {
      return false;
    } else {
      return true;
    }
  };

  //Function to check if there is any blank input at Extra Hours CreationForm2
  const validateFieldsExtraForm2 = () => {
    const newErrors = {
      description: newExtraHours.description === "",
      justification:
        newExtraHours.justification === "Selecciona una justificación",
    };
    setErrorsExtratForm2(newErrors);
    if (newErrors.description || newErrors.justification) {
      return false;
    } else {
      return true;
    }
  };

  //Variable to store data of a new extra hours report before send it
  const [newExtraHours, setNewExtraHours] = useState({
    date: "",
    time: "",
    endTime: "",
    hours: 0,
    info: "-/-",
    dayBefore: 0,
    holiday: "0",
    dayType: "0",
    dayTypeText: "",
    absence: "",
    absenceText: "",
    old: 0,
    justification: "Selecciona una justificación",
    description: "",
    preApprover: "N/A",
    preApproverName: "N/A",
    holidayText: "",
    schedule: "",
    workSchedule: "",
  });

  //Function to show and get data of assigned items that a user has.
  const handleOnShowRelateOrder = async () => {
    setHaveRelatedOrder(null);
    setPage(1);
    setSelectedReportTypeNetwork(false);
    setNetworkVerify(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    await getServiceOrders();
    setShowRelateOrder(!showRelateOrder);
  };

  //Function to cancel or go back when user is inside CATS creation Form
  const handleOnCancelExtra = () => {
    setOnCancelExtra(true);
    setShowConflicButton(false);
    setExtraHoursCreate(!extraHoursCreate);
    setExtraHoursTable(!extraHoursTable);
    setNewExtraHours({
      date: "",
      time: "",
      endTime: "",
      hours: 0,
      info: "-/-",
      dayBefore: 0,
      holiday: "0",
      dayType: "0",
      dayTypeText: "",
      absence: "",
      absenceText: "",
      old: 0,
      justification: "Selecciona una justificación",
      description: "",
      preApprover: "N/A",
      preApproverName: "N/A",
      holidayText: "",
      schedule: "",
      workSchedule: "",
    });
    setErrorsExtratForm1({
      date: false,
      time: false,
      endTime: false,
    });
    setHaveRelatedOrder(null);

    setFilters({ filter1: "", filter2: "", filter3: "" });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Function to get status filter options on Extra Hours Record Table
  const getStatusFilter = (data, attribute) => {
    const uniqueStatus = new Set();
    data.forEach((request) => {
      uniqueStatus.add(request[attribute]);
    });

    const uniqueStatusArray = Array.from(uniqueStatus);

    if (attribute === "preApproverStatus") {
      setExtraPreapproverStatus(uniqueStatusArray);
    } else {
      setExtraCEOStatus(uniqueStatusArray);
    }
  };

  //Function to show modal with details of an extra hours report
  const handleDetailsExtraHours = (request) => {
    setExtraHoursDetails(request);
    setOpenDetailsExtraHours(!openDetailsExtraHours);
  };

  //Function to clean and close Relate order Modal
  const handleRelateOrder = () => {
    setPage(1);
    setNoMrsItemsDate("");
    setFilters({ filter1: "", filter2: "", filter3: "" });
    setShowRelateOrder(!showRelateOrder);
  };

  //Function to reset time filters at Extra Hours Record Table
  const resetTimeFilterExtra = () => {
    setOnCancelExtra(false);
    setFilterDateExtraHours({
      initDate: moment().subtract(2, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
  };

  //Fuction for setting new time filter at ExtraHours Record Table
  const DatetimeChangeExtra = (key, value) => {
    setOnCancelExtra(false);
    let date = moment(value);

    try {
      const formattedDate = date.format("YYYY-MM-DD");
      setFilterDateExtraHours((prevData) => ({
        ...prevData,
        [key]: formattedDate,
      }));
    } catch (error) {}
  };

  //Function to check if time selected when creating a new Extra Hours report is valid or not
  const checkTimeExtra = async () => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo reportes de horas extra",
    });
    const resp = await dispatch(
      validateTimeExtra({
        time: newExtraHours.time,
        endTime: newExtraHours.endTime,
        date: newExtraHours.date,
        hours: newExtraHours.hours,
      })
    );
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        const { data } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
          setSpinnerInfo({
            loading: false,
            message: null,
          });
          return data;
        } else if (status === 404) {
          notify("warning", "Atención", message);
          setSpinnerInfo({
            loading: false,
            message: null,
          });
          if (resp.payload.response.data.payload.report) {
            setConflictReportData(resp.payload.response.data.payload.report);
            setShowConflicButton(true);
          }
          return data;
        } else if (status === 422) {
          notify("warning", "Atención", message);
          setSpinnerInfo({
            loading: false,
            message: null,
          });
          return data;
        }
      }
    } else {
      const { data } = resp.payload;
      if (resp.payload.data.status === 200) {
        setSpinnerInfo({
          loading: false,
          message: null,
        });
        return data.payload.data;
      }
    }
  };

  // Function to get all extra hours reports
  const getExtra = async () => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo reportes de horas extra",
    });
    const resp = await dispatch(getExtraHours(filterDateExtraHours));
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
          setExtraHoursTableData([]);
        } else if (status === 404) {
          notify("warning", "Atención", message);
          setExtraHoursTableData([]);
        }
      }
    } else {
      const { data } = resp.payload;
      if (resp.payload.data.status === 200) {
        notify("success", "Obtenidos exitosamente", data.payload.message);
        setExtraHoursTableData(data.payload.data);
        getStatusFilter(data.payload.data, "status");
        getStatusFilter(data.payload.data, "preApproverStatus");
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function used to create a new extra hours report
  const onSendExtraHours = async () => {
    if (validateFieldsExtraForm2()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setSpinnerInfo({
        loading: true,
        message: "Creando reporte de horas extra",
      });
      const resp = await dispatch(createExtraHours(newExtraHours));
      if (resp.payload.isAxiosError) {
        if (resp.payload.response === undefined) {
          notify("danger", "Falló", "Error de conexión con el servidor");
        } else {
          const { status } = resp.payload.response.data;
          const { message } = resp.payload.response.data.payload;
          if (status === 500) {
            notify("danger", "Falló", message);
          } else if (status === 404) {
            notify("warning", "Atención", message);
          } else if (status === 422) {
            notify("warning", "Atención", message);
          }
        }
      } else {
        const { data } = resp.payload;
        if (resp.payload.data.status === 200) {
          notify(
            "success",
            "Reporte creado exitosamente",
            data.payload.message
          );
          resetTimeFilterExtra();
          setExtraHoursCreate2(!extraHoursCreate2);
          setExtraHoursTable(!extraHoursTable);
          setNewExtraHours({
            date: "",
            time: "",
            endTime: "",
            hours: 0,
            info: "-/-",
            dayBefore: 0,
            holiday: "0",
            dayType: "0",
            dayTypeText: "",
            absence: "",
            absenceText: "",
            old: 0,
            justification: "Selecciona una justificación",
            description: "",
            preApprover: "N/A",
            preApproverName: "N/A",
            holidayText: "",
            schedule: "",
            workSchedule: "",
          });
          setHaveRelatedOrder(null);
          setApprover(null);
        }
      }
      setSpinnerInfo({
        loading: false,
        message: null,
      });
    }
  };

  //Function to handle when user wants to cancel those records that have not been sent to SAP or have not canceled already
  const handleCancelExtraHours = async (request) => {
    if (
      request.sapStatus === "No enviar" &&
      (request.status != "Cancelada" || request.status != "Expirada")
    ) {
      const MySwal = withReactContent(Swal);
      const result = await MySwal.fire({
        title: "¿Quieres cancelar este reporte de horas extra?",
        text: `Si confirmas, tu reporte ya no será procesado.`,
        showCancelButton: true,
        confirmButtonColor: "#2dce89",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      });

      if (result.value) {
        await cancelExtra(request.id);
        await getExtra();
      }
    } else {
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: "No puedes cancelar este reporte",
        text: `Tu reporte ya ha sido procesado`,
        showCancelButton: false,
        confirmButtonColor: "#2dce89",
        confirmButtonText: "Aceptar",
      });
    }
  };

  //Function that cancels the extra hours request
  const cancelExtra = async (requestId) => {
    setSpinnerInfo({
      loading: true,
      message: "Cancelando Solicitud",
    });
    const cancelExtraData = {
      itemID: requestId,
      visibility: 0,
      sapStatus: "No enviar",
      cancel: 1,

      visibility: 0,
      sapStatus: "No enviar",
      cancel: 1,
    };

    const resp = await dispatch(updateVisibilityExtraHours(cancelExtraData));
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      const { data } = resp.payload;
      if (resp.payload.data.status === 200) {
        notify("success", "Cancelada exitosamente", data.payload.message);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function to give format to data when its showed to the user at Extra Hours Record Table
  const formatRowDataExtraHours = (key, value, request) => {
    if (key === "time") {
      return `${request.time.substring(0, 5)} - ${request.endTime.substring(
        0,
        5
      )}`;
    } else if (key === "date") {
      return value.substring(0, 10);
    } else if (key === "Acciones") {
      return (
        <ActionButtons
          onDetails={handleDetailsExtraHours}
          onHide={handleCancelExtraHours}
          request={request}
          status={request.status}
        />
      );
    } else {
      return value;
    }
  };

  //Function to show the second Form when creating a new extra hours request, get all needed data before an then show the view
  const handleCreateExtraHours2 = async () => {
    if (validateFieldsExtraForm1()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const validation = await checkTimeExtra();
      if (validation) {
        getManagerData();
        await getAllEmployees();
        await getExtraSchedule();
        setExtraHoursCreate(!extraHoursCreate);
        setExtraHoursCreate2(!extraHoursCreate2);
        await getExtraJustifications();
      }
    }
  };

  //Format the data to show for extra hours details
  const formatDetailsExtra = (key, request) => {
    if (key === "time") {
      return `${request.time.substring(0, 5)} - ${request.endTime.substring(
        0,
        5
      )}`;
    } else if (key === "Orden/item") {
      return request.info === "-/-" ? "Ninguna orden reportada" : request.info;
    } else if (key === "motivoRechazo") {
      return request.ceoReason;
    } else {
      return request.preApproverName !== "N/A"
        ? request.preApproverName
        : "No necesita preaprobación";
    }
  };

  //Function to filter extra hours report showed at Record´s Table when user uses a filter
  const applyFiltersExtraHours = (filtersData) => {
    const filteredList = extraHoursTableData.filter((request) => {
      return (
        (!filters.filter1 ||
          request.id?.toString().includes(filtersData.filter1)) &&
        (!filters.filter2 ||
          request.preApproverStatus?.includes(filtersData.filter2)) &&
        (!filters.filter3 || request.status?.includes(filtersData.filter3))
      );
    });
    return filteredList;
  };

  //Function to show Extra Hours creation Form
  const handleCreateExtraHours = async () => {
    setOnCancelExtra(true);
    setExtraHoursTable(!extraHoursTable);
    setExtraHoursCreate(!extraHoursCreate);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleGoBackExtraTable = () => {
    setFilters({ filter1: "", filter2: "", filter3: "" });
    setExtraHoursTable(!extraHoursTable);
  };

  //Function to calculate the duration of a report and show it to the user dynamically in hours and minutes
  const calculateDurationExtra = async (time1, time2) => {
    const format = "HH:mm:ss";

    // Parse times using Moment library with proper format
    const moment1 = moment(time1, format);
    const moment2 = moment(time2, format);

    let result;

    // Check if time1 is earlier than time2
    if (moment1.isBefore(moment2)) {
      // Calculate difference between time2 and time1
      const startDate = moment(time1, format);
      const finalDate = moment(time2, format);
      result = moment.duration(finalDate.diff(startDate));
    } else {
      // Calculate difference between time1 and time2, assuming time2 is on the next day
      const startDate = moment(time1, format);
      const finalDate = moment(time2, format).add(1, "days");
      result = moment.duration(finalDate.diff(startDate));
    }
    return result;
  };

  //Function to calculate the duration of a report in hours and store it to create the new extra hours report
  const calculateDurationExtra2 = async (time1, time2) => {
    const format = "HH:mm:ss";

    // Parse times using Moment library with proper format
    const moment1 = moment(time1, format);
    const moment2 = moment(time2, format);

    let result;

    // Check if time1 is earlier than time2
    if (moment1.isBefore(moment2)) {
      // Calculate difference between time2 and time1
      const startDate = moment(time1, format);
      const finalDate = moment(time2, format);
      result = moment.duration(finalDate.diff(startDate));
    } else {
      // Calculate difference between time1 and time2, assuming time2 is on the next day
      const startDate = moment(time1, format);
      const finalDate = moment(time2, format).add(1, "days");
      result = moment.duration(finalDate.diff(startDate));
    }

    // Convert duration to hours
    const hours = result.asHours();

    return hours;
  };

  //Function to show alerts to the user when the date selected is not valid at CreationForm1
  const showDateWarning = (date) => {
    if (date == "") {
      notify(
        "warning",
        "Atención",
        "La fecha introducida no existe en el calendario actual"
      );
      setSendButtonStatus(true);
    } else {
      const inputDate = moment(date, "YYYY-MM-DD");

      if (
        inputDate.isBefore(moment().subtract(2, "months").subtract(1, "day"))
      ) {
        notify(
          "warning",
          "Atención",
          "No puede reportar en fechas menores a dos meses de la fecha actual. Valide la fecha e intentelo nuevamente"
        );
        setSendButtonStatus(true);
      } else if (inputDate.isAfter(moment())) {
        notify(
          "warning",
          "Atención",
          "Recuerde que no puede reportar horas extra a futuro. Valide la fecha a reportar e intentelo nuevamente"
        );
        setSendButtonStatus(true);
      } else {
        setSendButtonStatus(false);
      }
    }
  };

  //Function to handle and stored all inputs at CreationForm1 and calculate the duration of the extra hours
  const handleInputExtraHours1 = async (id, value) => {
    let response;
    let resultQuantity;
    if (id === "endTime" && newExtraHours.time !== "") {
      response = await calculateDurationExtra(newExtraHours.time, value);
      resultQuantity = await calculateDurationExtra2(newExtraHours.time, value);
      setShowConflicButton(false);
    } else if (id === "time" && newExtraHours.endTime !== "") {
      response = await calculateDurationExtra(value, newExtraHours.endTime);
      resultQuantity = await calculateDurationExtra2(
        value,
        newExtraHours.endTime
      );
      setShowConflicButton(false);
    } else if (id === "date") {
      showDateWarning(value);
      setShowConflicButton(false);
    }

    if (response) {
      const { hours, minutes } = response._data || { hours: 0, minutes: 0 };
      setShowTimeExtra((prevData) => ({
        ...prevData,
        horas: hours,
        minutos: minutes,
      }));
      setNewExtraHours((prevData) => ({
        ...prevData,
        hours: resultQuantity,
      }));
    }

    setNewExtraHours((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrorsExtratForm1((prevErrors) => ({
      ...prevErrors,
      [id]: false,
    }));
  };

  const handleInputExtraHours2 = async (id, value) => {
    setNewExtraHours((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrorsExtratForm2((prevErrors) => ({
      ...prevErrors,
      [id]: false,
    }));
  };

  const onSelectRelatedOrder = (order) => {
    setFilters({
      filter1: "",
      filter2: "",
      filter3: "",
    });
    setFixedDateFlag(true);
    setHaveRelatedOrder(order);
    if (selectedReportTypeNetwork) {
      setNewExtraHours((prevData) => ({
        ...prevData,
        info: `${order.ID}/${order.ITEM}`,
      }));
    } else {
      setNewExtraHours((prevData) => ({
        ...prevData,
        info: `${order.IdOrden}/${order.IdItem}`,
      }));
    }
    handleRelateOrder();
  };

  //Function to verify if a relate ID network is valid
  const checkNetwork = async (id) => {
    setSpinnerInfo({
      loading: true,
      message: "Verificando ID",
    });
    const resp = await dispatch(checkOSNetworks({ os: id }));
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { status } = resp.payload.response.data;
        const { message } = resp.payload.response.data.payload;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
        } else if (status === 422) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      if (resp.payload.data.status === 200) {
        notify(
          "success",
          "Verificación exitosa",
          resp.payload.data.payload.message
        );
        setNoMrsCheckItems(resp.payload.data.payload.data.DETAILS.item);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function to handle the process when a user tries to relate a network order
  const getNetworkbyId = async () => {
    handleVerificationInputs();
    if (idContainsLetter && idNoMrs !== "") {
      if (idVerifyNetworkType === selectedReportTypeNetwork) {
        await checkNetwork(idNoMrs);
        setNetworkVerify(true);
      } else {
        notify(
          "warning",
          "Atención",
          "ID digitado no corresponde al tipo de reporte seleccionado"
        );
      }
    } else {
      notify("warning", "Atención", "Debe introducir un ID válido");
      setNetworkVerify(false);
    }
  };

  //Function to format the data at the table when the user tries to see its Related orders when creating a extra hours report
  const formatRowDataExtraRelate = (key, value, order) => {
    if (key === "ID") {
      return idNoMrs;
    } else if (key === "Acciones") {
      return (
        <ButtonRelateOrder onSelect={onSelectRelatedOrder} order={order} />
      );
    } else if (key === "ITEM") {
      return value;
    } else {
      return value;
    }
  };

  //Function to get all extra hours justification options
  const getExtraJustifications = async () => {
    setSpinnerInfo({
      loading: true,
      message: "Obteniendo tipos de justificación",
    });
    const resp = await dispatch(getJustifications());
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { message } = resp.payload.response.data.payload;
        const { status } = resp.payload.data.payload.status;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      const { data } = resp.payload;
      if (data.status === 200) {
        setJustifications(data.payload.data.MOTIVOS.item);
        notify("success", "Obtenidas exitosamente", data.payload.message);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function to get information about the day requested in a new extra hour report
  const getExtraSchedule = async () => {
    var reportedDate = newExtraHours.date;

    var formatingDate = reportedDate.split("-");

    var formatDate = formatingDate[2] + formatingDate[1] + formatingDate[0];

    setSpinnerInfo({
      loading: true,
      message: "Obteniendo información del día reportado",
    });
    const resp = await dispatch(getSchedule({ date: formatDate }));
    if (resp.payload.isAxiosError) {
      if (resp.payload.response === undefined) {
        notify("danger", "Falló", "Error de conexión con el servidor");
      } else {
        const { message } = resp.payload.response.data.payload;
        const { status } = resp.payload.data.payload.status;
        if (status === 500) {
          notify("danger", "Falló", message);
        } else if (status === 404) {
          notify("warning", "Atención", message);
        }
      }
    } else {
      const { data } = resp.payload;
      if (data.status === 200) {
        handleScheduleData(data.payload.data);
      }
    }
    setSpinnerInfo({
      loading: false,
      message: null,
    });
  };

  //Function to stored the information of the day requested when creating a new extra hour at Extra Hours Module
  const handleScheduleData = (schedule) => {
    setNewExtraHours((prevData) => ({
      ...prevData,
      absence: schedule.ABSENCE,
      absenceText: schedule.ABSENCET,
      dayType: schedule.DAYTYPE,
      dayTypeText: schedule.DAYTYPET,
      workSchedule: schedule.WORKSR,
      schedule: schedule.HORARIO,
      holiday: schedule.HOLCLASS,
      holidayText: schedule.HOLCLASST,
    }));
  };

  //Function to handle when user go back from CreationForm2 on Extra Hours Module
  const handleOnCancelExtra2 = () => {
    setExtraHoursCreate2(!extraHoursCreate2);
    setExtraHoursCreate(!extraHoursCreate);
    setErrorsExtratForm2({
      justification: false,
      description: false,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Get manager´s user data
  const getManagerData = () => {
    const token = localStorage.getItem("token");
    try {
      const decoded = jwt.decode(token);
      const { SUPERVISOR } = decoded.protected;
      setManager(SUPERVISOR);
    } catch (error) {
      console.log(error);
    }
  };

  const sendExtraRequest = async () => {
    if (validateFieldsExtraForm1()) {
      await onSendExtraHours();
      setShowTimeExtra({
        horas: 0,
        minutos: 0,
      });
    }
  };

  useEffect(() => {
    checkUserRolExtra();
  }, []);

  useEffect(() => {
    //Variable onCancel is used to get all records only when user has made a new report, if user dont create a new report, return button won´t refresh
    const fetchData = async () => {
      if (onCancel === false) {
        if (catsTable) {
          await getCatsRecord();
        }
      }

      if (onCancelNoMrs === false) {
        if (noMrsTable) {
          await getOSNetworksRecord();
        }
      }

      if (onCancelExtra === false) {
        if (extraHoursTable) {
          await getExtra();
        }
      }
    };

    fetchData();
  }, [
    catsTable,
    noMrsTable,
    extraHoursTable,
    filteredDateCats,
    filteredDateOSNetworks,
    filterDateExtraHours,
  ]);

  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyEl} />
      </div>
      <AdminHeader name={name} parentName={parentName} />
      <Container className="mt--6" fluid>
        <Spin
          size="large"
          spinning={spinnerInfo.loading}
          tip={spinnerInfo.message}
        >
          {catsTable ? (
            <>
              <DateTableFilters
                DatetimeChange={DatetimeChangeCATS}
                startDate={filteredDateCats.date}
                endDate={filteredDateCats.endDate}
                resetTimeFilter={resetTimeFilterCats}
                dateLabels={["date", "endDate"]}
                title="Filtro por fechas"
                subtitle="Selecciona una fecha de inicio y una fecha de fin para filtrar sus reportes CATS."
              />
              <RecordTable
                title="Historial de reportes CATS"
                description="A continuación se muestra su matriz de reportes CATS:"
                buttonName="Crear CATS"
                module="CATS"
                buttonFunction={handleCreateCATS}
                goBackButton={handleGoBackCatsTable}
                page={page}
                sizePerPage={sizePerPage}
                setPage={handlePageChange}
                preparePageData={preparePageData}
                columns={columnsCATS}
                formatData={formatRowDataCATS}
                setFilter={handleSetFilters}
                filtersLabels={[
                  "ID del reporte",
                  "Hora de inicio reportada",
                  "Tipo de tarea",
                ]}
                filtersPlaceHolder={[
                  "Identificador",
                  "Hora de inicio reportada",
                  "Seleccionar Todas",
                ]}
                filtersData={filters}
                applyFilters={applyFiltersCats}
                typesOfTasks={catsFilterTasks}
                allRecords={catsTableData}
              />
            </>
          ) : catsCreate ? (
            <>
              {openConflictModal && (
                <ConflictDetails
                  isOpen={openConflictModal}
                  handleClose={handleConflictModal}
                  report={conflictReportData}
                  reportType={"CATS"}
                />
              )}
              <CATS
                catsTypes={taskTypes}
                title="Creación de CATS"
                description="Complete los datos para generar su reporte CATS."
                onCancelClick={handleOnCancelCats}
                onSendCats={handleOnSendCats}
                onChangeInput={handleInputCats}
                onSelectedTask={selectedTasks}
                searchConflictReport={searchConflictReport}
                conflictData={conflictReportData}
                errors={errorsCATS}
                showConflicButton={showConflicButton}
                reportType={"CATS"}
              />
            </>
          ) : noMrsTable ? (
            <>
              {openDetailsNoMrs && (
                <ModalNoMrs
                  isOpen={openDetailsNoMrs}
                  handleClose={handleDetailsNoMrs}
                  report={detallesMrs}
                  isOS={isOS}
                />
              )}
              <DateTableFilters
                DatetimeChange={DatetimeChangeNoMrs}
                startDate={filteredDateOSNetworks.date}
                endDate={filteredDateOSNetworks.endDate}
                dateLabels={["date", "endDate"]}
                resetTimeFilter={resetTimeFilterNoMrs}
                title="Filtro por fechas"
                subtitle="Selecciona una fecha de inicio y una fecha de fin para filtrar sus reportes OS / Networks."
              />
              <RecordTable
                title="Historial de reportes OS / Networks"
                description="A continuación se muestra su matriz de reportes OS / Networks:"
                buttonName="Crear reporte"
                module="OS_Networks"
                buttonFunction={handleItemsAssigned}
                goBackButton={handleGoBackNoMrsTable}
                page={page}
                sizePerPage={sizePerPage}
                setPage={handlePageChange}
                preparePageData={preparePageData}
                columns={columnsOSNetworks}
                formatData={formatRowDataNoMrs}
                setFilter={handleSetFilters}
                filtersLabels={[
                  "Orden de servicio / Item",
                  "Network",
                  "Tipo de tarea",
                ]}
                filtersPlaceHolder={[
                  "OS / Item",
                  "Identificador",
                  "Seleccionar Todas",
                ]}
                filtersData={filters}
                applyFilters={applyFiltersNoMrs}
                typesOfTasks={typeOSNetworkTasks}
                allRecords={osNetworksTableData}
              />
            </>
          ) : noMrsShowItems ? (
            <>
              <ItemVerify
                title="Reportar por ID"
                subtitle="Seleccione un tipo de reporte y digite el ID correspondiente para reportar sus horas."
                reportType={reportType}
                onSelect={handleSelectReporType}
                inputValidation={handleIdNoMrs}
                buttonOnClick={getItembyId}
                errors={errorsVerification}
              />
              <ItemsTable
                title="Reportar por item asignado"
                description="A continuación se muestra una matriz con sus items asignados:"
                goBackButton={handleGoBackNoMrsItemsList}
                page={page}
                record={noMrsItemsList}
                sizePerPage={sizePerPage}
                setPage={handlePageChange}
                preparePageData={preparePageData}
                columns={columnsOSItems}
                formatData={formatRowItemsNoMrs}
                setFilter={handleSetFilters}
                filtersLabels={[
                  "ID de orden de servicio",
                  "Items asignados desde",
                ]}
                filtersPlaceHolder={["Identificador", "Seleccionar fecha"]}
                filtersData={filters}
                applyFilters={applyFiltersAssignedItemsTable}
                setItemsTableDate={handleItemsDate}
                //setFixedDatetFlag arreglo para problema fecha en items asignados
                setFixedDateFlag={setFixedDateFlag}
                fixedDateFlag={fixedDateFlag}
                onClickDateButton={resetItemsDate}
                dateFieldValue={noMrsItemsDate}
                idFilterValue={filters.filter1}
              />
            </>
          ) : noMrsCreate ? (
            <>
              {openConflictModal && (
                <ConflictDetails
                  isOpen={openConflictModal}
                  handleClose={handleConflictModal}
                  report={conflictReportData}
                  reportType={"OS / Network"}
                />
              )}

              <CreateForm
                title="Creación de reporte OS / Network"
                description="Complete los datos para generar su reporte."
                onCancelClick={handleOnCancelNoMrs}
                onSendNoMrs={onSendOsNetwork}
                onChangeInput={handleInputNoMrsForm}
                errors={errorsNoMrs}
                selectedNetwork={selectedReportTypeNetwork}
                onSelectedItem={handleOnSelectedItem}
                itemsList={noMrsCheckItems}
                itemSelectedInRow={itemSelected}
                searchConflictReport={searchConflictReport}
                showConflicButton={showConflicButton}
                reportType={"OS / Network"}
              />
            </>
          ) : extraHoursTable ? (
            <>
              {openDetailsExtraHours && (
                <ModalDetailsExtra
                  isOpen={openDetailsExtraHours}
                  handleClose={handleDetailsExtraHours}
                  request={extraHoursDetails}
                  formatDetails={formatDetailsExtra}
                />
              )}

              <DateTableFilters
                DatetimeChange={DatetimeChangeExtra}
                startDate={filterDateExtraHours.initDate}
                endDate={filterDateExtraHours.endDate}
                resetTimeFilter={resetTimeFilterExtra}
                dateLabels={["initDate", "endDate"]}
                title="Filtro por fechas"
                subtitle="Selecciona una fecha de inicio y una fecha de fin para filtrar sus reportes de Horas Extra."
              />
              <RecordTable
                title="Historial de reportes de Horas Extra"
                description="A continuación se muestra su matriz de reportes de Horas Extra:"
                buttonName="Crear reporte"
                module="Horas_Extra"
                buttonFunction={handleCreateExtraHours}
                goBackButton={handleGoBackExtraTable}
                page={page}
                sizePerPage={sizePerPage}
                setPage={handlePageChange}
                preparePageData={preparePageData}
                columns={columnsExtraHours}
                formatData={formatRowDataExtraHours}
                setFilter={handleSetFilters}
                filtersLabels={["ID", "Estado preaprobador", "Estado jefatura"]}
                filtersPlaceHolder={[
                  "Identificador",
                  "Seleccionar todos",
                  "Seleccionar todos",
                ]}
                filtersData={filters}
                applyFilters={applyFiltersExtraHours}
                ceoStatus={extraCEOStatus}
                preapproverStatus={extraPreapproverStatus}
                allRecords={extraHoursTableData}
              />
            </>
          ) : extraHoursCreate ? (
            <>
              {openConflictModal && (
                <ConflictDetails
                  isOpen={openConflictModal}
                  handleClose={handleConflictModal}
                  report={conflictReportData}
                  reportType={"Extra"}
                  formatDetails={formatDetailsExtra}
                />
              )}
              {showRelateOrder && (
                <ModalRelateOrder
                  isOpen={showRelateOrder}
                  handleClose={handleRelateOrder}
                  noMrsItemsList={noMrsItemsList}
                  networkItemsList={noMrsCheckItems}
                  columns={columnsRelateOrder}
                  columnsNetwork={columnsRelateNetworkOrder}
                  page={page}
                  sizePerPage={sizePerPage}
                  preparePageData={preparePageData}
                  setPage={handlePageChange}
                  onSelectType={handleSelectReporType}
                  isNetwork={selectedReportTypeNetwork}
                  isNetworkVerify={networkVerify}
                  title="Selecciona tus datos."
                  subtitle="Una vez seleccionado el tipo de orden, proceda a seleccionar con el botón, el item a relacionar."
                  inputValidation={handleIdNoMrs}
                  errors={errorsVerification}
                  reportType={reportType}
                  buttonOnClick={getNetworkbyId}
                  formatData={formatRowDataExtraRelate}
                  setFilter={handleSetFilters}
                  filtersLabels={[
                    "ID de Orden de Servicio",
                    "Items asignados desde",
                  ]}
                  filtersPlaceHolder={["Identificador", "Seleccionar fecha"]}
                  filtersData={filters}
                  applyFilters={applyFiltersAssignedItemsTable}
                  setItemsTableDate={handleItemsDate}
                  onClickDateButton={resetItemsDate}
                  dateFieldValue={noMrsItemsDate}
                  idFilterValue={filters.filter1}
                  //setFixedDatetFlag arreglo para problema fecha en items asignados
                  setFixedDateFlag={setFixedDateFlag}
                  fixedDateFlag={fixedDateFlag}
                />
              )}
              <CreationForm
                title="Reportando Horas Extra"
                description="Complete los datos para generar su reporte de horas extra."
                activeButton={sendButtonStatus}
                onCancelClick={handleOnCancelExtra}
                onContinue={handleCreateExtraHours2}
                onChangeInput={handleInputExtraHours1}
                errors={errorsExtratForm1}
                showTime={showTimeExtra}
                relateOrder={handleOnShowRelateOrder}
                isNetwork={selectedReportTypeNetwork}
                isNetworkVerify={networkVerify}
                haveRelatedOrder={haveRelatedOrder}
                maxDate={maxDateExtraCalendar}
                minDate={minDateExtraCalendar}
                defaultDate={newExtraHours.date}
                defaultinitTime={newExtraHours.time}
                defaultendTime={newExtraHours.endTime}
                searchConflictReport={searchConflictReport}
                showConflicButton={showConflicButton}
                reportType={"Extra"}
              />
            </>
          ) : extraHoursCreate2 ? (
            <>
              {showVerifyApprov && (
                <ModalVerifyApprover
                  title="Sección de asignación del preaprobador"
                  subtitle="Filtra y selecciona tu preaprobador."
                  isOpen={showVerifyApprov}
                  employees={allEmployees}
                  handleClose={handleVerifyApprov}
                  onChange={onInputPreapprover}
                  buttonOnClick={handlePreapprover}
                />
              )}
              <CreationForm2
                title="Creación de reporte"
                description="Complete los datos para generar su reporte de horas extra."
                onCancelClick={handleOnCancelExtra2}
                onSendData={sendExtraRequest}
                errors={errorsExtratForm2}
                onSelectJustification={selectedJustification}
                justifications={justifications}
                onChangeInput={handleInputExtraHours2}
                manager={manager}
                havePreapprover={preApprover}
                ExtraHoursDefaultDescription={newExtraHours.description}
                extraHoursDefaultJustification={newExtraHours.justification}
                showModalVerify={handleVerifyApprov}
                approverName={newExtraHours.preApproverName}
              />
            </>
          ) : (
            <ReportOptions
              changeOption={handleOnChangeOption}
              extraRol={extraRol}
            />
          )}
        </Spin>
      </Container>
    </div>
  );
}

Home.defaultProps = {
  name: "Inicio",
  parentName: "Reporte de horas",
  columnsCATS: [
    { key: "ID_REPORTE", name: "Id del reporte" },
    { key: "FECHA", name: "Fecha reportada" },
    { key: "TIPO_TAREA", name: "Tipo de tarea" },
    { key: "HORAS-REPORTADAS", name: "Horas reportadas" },
    { key: "description", name: "Descripción" },
    { key: "Acciones", name: "" },
  ],
  columnsOSItems: [
    { key: "IdOrden", name: "Id de orden de servicio" },
    { key: "DescOrden", name: "Descripción de orden" },
    { key: "IdItem", name: "Id de item" },
    { key: "DescItem", name: "Descripción de item" },
    { key: "DescCliente", name: "Cliente" },
    { key: "Acciones", name: "" },
  ],

  columnsRelateOrder: [
    { key: "IdOrden", name: "Id de orden de servicio" },
    { key: "IdItem", name: "Id de item" },
    { key: "DescItem", name: "Descrip. Item" },
    { key: "Acciones", name: "" },
  ],

  columnsRelateNetworkOrder: [
    { key: "ID", name: "Id de network" },
    { key: "ITEM", name: "Id de item" },
    { key: "ORD_NAME", name: "Descrip. Item" },
    { key: "Acciones", name: "" },
  ],

  columnsOSNetworks: [
    { key: "ID_REPORTE", name: "Id de reporte" },
    { key: "FECHA", name: "Fecha reportada" },
    { key: "DESCRIPCION", name: "Órden de servicio / Item" },
    { key: "NETWORK", name: "Network" },
    { key: "TIPO_TAREA", name: "Tipo de tarea" },
    { key: "Acciones", name: "" },
  ],
  columnsExtraHours: [
    { key: "id", name: "Id de reporte" },
    { key: "date", name: "Fecha reportada" },
    { key: "time", name: "Horas reportadas" },
    { key: "preApproverStatus", name: "Preaprobador" },
    { key: "status", name: "Jefatura" },
    { key: "Acciones", name: "" },
  ],
};
Home.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  columnsCATS: PropTypes.array.isRequired,
};

export default Home;
