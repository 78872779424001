import React from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Col, CardBody } from "reactstrap";

const SignatureGenerate = props => {
  const {
    nombre,
    email,
    puesto,
    celular,
    extension,
    telefono,
    location
  } = props
  return (
    <Card>
      <CardBody >
        <Row>
          {/* <Col xs="12" lg="3" className="d-flex align-items-center">
            <img
              width="160"
              height="90"
              alt="Notificación"
              src={require(`assets/img/icons/common/Logo2.png`)}
            />
          </Col > */}

          <Col  lg="4" className="align-items-end">
            <img
            style={{ marginLeft: "80px", marginTop: "30px" }}
              width="180"
              height="150"
              alt="Notificación"
              src={require(`assets/img/icons/common/GPTW2024.png`)}
            />
          </Col>
          <Col xs="6">
            <Col xs="12">
              <h3 className="mb-1" style={{ fontWeight: "700", fontFamily: "Century Gothic", color: "#1964ff" }}>{nombre}</h3>
            </Col>
            <Col xs="12" >
              <h5 className="mb-1" style={{ fontWeight: "700", fontFamily: "Century Gothic" }}> {puesto} </h5>
            </Col>
            <Col className="mt-3" xs="12" ><h5 style={{ fontWeight: "700", fontFamily: "Century Gothic" }}>{location}</h5></Col>
            <Col xs="12"><h5 className="mb-1" style={{ fontFamily: "Century Gothic" }}>Tel: {telefono} Ext: {extension} {celular}</h5></Col>
            <Col xs="12"><h5 className="mb-1" style={{ fontFamily: "Century Gothic" }}>{email}</h5></Col>
            <Col className="mt-3" xs="12" ><h5 style={{ fontWeight: "700", fontFamily: "Century Gothic", color: "#1964ff" }}>Web | Facebook | Instagram</h5></Col>
            <Col className="mr--3" xs="12" style={{ marginLeft: "-10px" }}>
              <img
                width="150"
                height="50"
                alt="Notificación"
                src={require(`assets/img/icons/common/Logo-full color.png`)}
              />
            </Col>
          </Col>
        </Row>

      </CardBody >
    </Card >);
};

SignatureGenerate.propTypes = {
  nombre: PropTypes.string.isRequired,
};

export default SignatureGenerate;
