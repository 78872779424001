import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findRequestsPendingAssignation = createAction(
  constants.GET_REQUESTS_PENDING_ASSIGNATION,
  method.get(urls.PLANNERS.getRequestsPendingAssignation)
);

export const createUserAssignmentByPlanner = createAction(
  constants.CREATE_USER_ASSIGNMENT_BY_PLANNER,
  (id, body) =>
    method.post(`${urls.PLANNERS.createUserAssignmentByPlanner}/${id}`, body)()
);

export const validateGbmCollaborator = createAction(
  constants.VALIDATE_GBM_COLLABORATOR,
  (body) => method.post(urls.PLANNERS.validateGbmCollaborator, body)()
);

export const findAllRequestsAssignment = createAction(
  constants.GET_ALL_REQUESTS_PENDING_ASSIGNMENT,
  method.get(urls.PLANNERS.getAllRequestsAssignment)
);

export const findEquipmentsAssignmentByRequest = createAction(
  constants.GET_EQUIPMENTS_ASSIGNMENT_BY_REQUEST,
  (id) =>
    method.get(`${urls.PLANNERS.getEquipmentsAssignmentByRequest}/${id}`)()
);

export const createUserAssignmentWithEquipmentsByPlanner = createAction(
  constants.CREATE_USER_ASSIGNMENT_WITH_EQUIPMENTS,
  (id, body) =>
    method.post(
      `${urls.PLANNERS.createUserAssignmentWithEquipmentsByPlanner}/${id}`,
      body
    )()
);

export const findUserAssignmentByRequest = createAction(
  constants.GET_USER_ASSIGNMENT_BY_REQUEST,
  (id) => method.get(`${urls.PLANNERS.getUserAssignmentByRequest}/${id}`)()
);

export const findAllPartsEquipmentsByRequest = createAction(
  constants.GET_ALL_PARTS_EQUIPMENTS_BY_REQUEST,
  (id) => method.get(`${urls.PLANNERS.getAllPartsEquipmentsByRequest}/${id}`)()
);
