import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";


export const getPlatform = createAction(constants.GET_PLATFORMS, method.get(urls.INCIDENTREPORTS.getPlatform));

export const getProducts = createAction(constants.GET_PRODUCTS, method.get(urls.INCIDENTREPORTS.getProducts));

export const getServices = createAction(constants.GET_SERVICES, method.get(urls.INCIDENTREPORTS.getServices));

export const getData = createAction(constants.GET_DATA, body => method.post(urls.INCIDENTREPORTS.getData, body)());

export const createNewItem = createAction(constants.INSERT_DATA, body => method.post(urls.INCIDENTREPORTS.insertData, body)());

export const deleteItem = createAction(constants.REMOVE_DATA, (body) => method.post(urls.INCIDENTREPORTS.removeItem, body)());