/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
This is the component of the first Form used to create an extra hours report
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/
import React from "react";
import {
  FormGroup,
  Form,
  Input,
  Label,
  Col,
  Row,
  Button,
  Card,
  CardHeader,
  FormFeedback,
} from "reactstrap";

import { BiEdit } from "react-icons/bi";

function CreationForm({
  title,
  description,
  onCancelClick,
  onSendData,
  errors,
  havePreapprover,
  onSelectJustification,
  justifications,
  onChangeInput,
  manager,
  ExtraHoursDefaultDescription,
  extraHoursDefaultJustification,
  approverName,
  showModalVerify,
}) {
  return (
    <Card>
      <CardHeader style={{ marginBottom: "2rem" }}>
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{description}</p>
          </Col>
        </Row>
      </CardHeader>
      <Form>
        <Row className="justify-content-center mb-4">
          <Col xs={10} sm={6} className="text-center">
            <FormGroup className="mb-3">
              <Label
                className="font-weight-bold"
                style={{ fontSize: "14px", color: "#333" }}
              >
                Manager
              </Label>
              <div className="d-flex align-items-center justify-content-center">
                <span className="text-dark">{manager}</span>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup
              className="d-flex align-items-center"
              style={{ marginBottom: "2rem" }}
            >
              <span
                className="mr-3"
                style={{ fontWeight: "bold", fontSize: "14px" }}
              >
                ¿Necesitas preaprobación?
              </span>
              <Button
                color="primary"
                className="d-flex align-items-center"
                style={{
                  fontSize: "14px",
                  borderRadius: "15px",
                  width: "fit-content",
                  height: "30px",
                  fontSize: "0.75rem",
                }}
                onClick={showModalVerify}
              >
                <BiEdit
                  style={{ marginRight: "0.5rem", fontSize: "0.75rem" }}
                />
                Asignar preaprobador
              </Button>
            </FormGroup>
            <Row className="justify-content-center mb-4 mt-2">
              <Col xs={10} sm={6} className="text-center">
                {havePreapprover !== null && (
                  <FormGroup className="mb-3">
                    <Label
                      className="font-weight-bold"
                      style={{ fontSize: "14px", color: "#333" }}
                    >
                      Su preaprobador es:
                    </Label>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="text-dark">{approverName}</span>
                    </div>
                  </FormGroup>
                )}

                {havePreapprover === null && <div></div>}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Tipo de justificación</Label>
              <Input
                id="select-typeCATS"
                type="select"
                className="basic-multi-select text-dark"
                style={{
                  color: "black",
                  borderColor: errors.justification ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onSelectJustification(e.target.value);
                }}
                invalid={errors.justification}
              >
                <option
                  value={extraHoursDefaultJustification}
                  disabled
                  selected
                >
                  {extraHoursDefaultJustification}
                </option>
                {justifications.map((justification, index) => (
                  <option key={justification.ID} value={index}>
                    {justification.MOTIVO}
                  </option>
                ))}
              </Input>
              {errors.justification && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Descripción (max. 300)</Label>
              <Input
                id="text-input"
                type="textarea"
                className="form-control"
                autoComplete="off"
                maxLength={300}
                resize="none"
                style={{
                  color: "black",
                  borderColor: errors.description ? "red" : "#dee2e6",
                  height: "6rem",
                }}
                value={ExtraHoursDefaultDescription}
                onChange={(e) => {
                  onChangeInput("description", e.target.value);
                }}
                invalid={errors.description}
              />
              {errors.description && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col xs={10} sm={6} className="text-center">
            <Button
              id="btn-cancel-cats"
              className="btn-icon mt-3 mr-3"
              color="danger"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                width: "115px",
                height: "30px",
                fontSize: "0.75rem",
                marginLeft: "0px",
              }}
              onClick={onCancelClick}
            >
              <span className="btn-inner--text">Cancelar</span>
            </Button>
            <Button
              id="btn-create-cats"
              className="btn-icon mt-3"
              color="success"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                width: "115px",
                height: "30px",
                fontSize: "0.75rem",
                marginLeft: "0px",
              }}
              onClick={onSendData}
            >
              <span className="btn-inner--text">Enviar</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default CreationForm;
