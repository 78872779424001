import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Col, CardHeader, FormGroup, Input, Button, Row, UncontrolledTooltip } from 'reactstrap';
import Select from "react-select";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Filter from "components/FinanceFlows/Administration/Filters";
import Table from "components/FinanceFlows/Administration/Tables";
import Modal from "components/FinanceFlows/Administration/Modals";
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getAllTemplatePosition, isAway, getUserSign, deleteTemplatePosition, addTemplatePosition, getAllProfile, updateTemplatePosition, getProfilesByPositionId } from "actions/financialFlows.jsx";

const ApprovalRoles = () => {
    const [filterByTable, setFiltersByTable] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [openModal, setModal] = useState({
        createModal: false,
        editModal: false
    });
    const [itemInfo, setItemInfo] = useState({
        profiles: []
    });
    const [newInfo, setNewInfo] = useState({
        profiles: []
    });
    const [alert, setAlert] = useState("");
    const [changeUpdate, setChangeUpdate] = useState(false);
    const [infoToModule, setInfoToModule] = useState({
        rows: [],
        profiles: []
    });

    const [validateFindUser, setValidateFindUser] = useState({
        main: "",
        substitude: "",
        secondary: ""
    })

    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    // atributos para definir los parametros de los filtros
    const filter = [
        {
            label: "Nombre de la posición",
            type: "input",
            xs: "6",
            value: "position"
        },
        {
            label: "Nombre de la persona",
            type: "input",
            xs: "6",
            value: "name"
        }
    ];

    //Columnas de la tabla principal generica
    const colums = [
        {
            label: "Posición",
            value: "position",
            type: "b"
        },
        {
            label: "Nombre",
            value: "name",
            type: "a"
        },
        {
            label: "Substituto",
            value: "substitudeName",
            type: "a"
        }

    ];

    // json que carga las opciones de los modales.
    const modalInformation = [
        { label: "Nombre de Posición", value: "position", type: "input", colWidth: "12", required: true }
    ]

    // json con las opciones extra para el modal
    const extraModelOptions = [
        { label: "Usuario principal", value: "user", colWidth: "12", required: true, type: "input", userInfo: "main", inputAction: openModal.editModal ? (constant, value) => handleOnChangeItemInfo(constant, value) : (constant, value) => handleOnChangeInfo(constant, value), buttonAction: openModal.editModal ? (constant, value) => handleOnEditUser(constant, value) : (constant, value) => handleOnSearchUser(constant, value), clearAction: openModal.editModal ? (constant) => handleOnClearEditUser(constant) : (constant) => handleOnClearUserinfo(constant) },
        { label: "Reemplazo", value: "substitudeUser", colWidth: "12", required: true, type: "input", userInfo: "substitude", inputAction: openModal.editModal ? (constant, value) => handleOnChangeItemInfo(constant, value) : (constant, value) => handleOnChangeInfo(constant, value), buttonAction: openModal.editModal ? (constant, value) => handleOnEditUser(constant, value) : (constant, value) => handleOnSearchUser(constant, value), clearAction: openModal.editModal ? (constant) => handleOnClearEditUser(constant) : (constant) => handleOnClearUserinfo(constant) },
        { label: "¿Requiere doble aprobación?", value: "secondaryUser", colWidth: "12", required: false, type: "input", userInfo: "secondary", inputAction: openModal.editModal ? (constant, value) => handleOnChangeItemInfo(constant, value) : (constant, value) => handleOnChangeInfo(constant, value), buttonAction: openModal.editModal ? (constant, value) => handleOnEditUser(constant, value) : (constant, value) => handleOnSearchUser(constant, value), clearAction: openModal.editModal ? (constant) => handleOnClearEditUser(constant) : (constant) => handleOnClearUserinfo(constant) },
        { label: "Perfiles", value: "profiles", colWidth: "12", required: true, type: "select", multi: true, options: infoToModule.profiles, placeholder: "Selecciona el perfil", defaultValue: itemInfo.profiles, selectAction: openModal.editModal ? (constant, value) => handleOnChangeItemInfo(constant, value) : (constant, value) => handleOnChangeInfo(constant, value) }
    ]

    //Botones adicionales en la tabla principal
    const aditionalsButtons = [
        {
            color: "secondary",
            function: (e) => handleOnOpenModalEdit(e),
            icon: "fas fa-edit",
            className: "table-action table-action-edit",
            name: "edit"
        },
        {
            color: "secondary",
            function: (e) => handleOnIsAwayConfirm(e),
            icon: "fas fa-plane",
            className: "table-action table-action-edit",
            name: "away"
        },
        {
            color: "secondary",
            function: (e) => handleOnDeleteConfirm(e),
            icon: "fas fa-trash",
            className: "table-action table-action-delete",
            name: "delete"
        }
    ]

    // Notificaciones acerca de las acciones realizadas en la pagina.
    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    //Funcion para guardar los filtros de la tabla principal
    const handleOnfilterDinamic = (constant, e) => {
        setPage(1);
        setFiltersByTable(prevState => ({
            ...prevState,
            [constant]: `${e}`
        }))
    }

    //Funcion para aplicar los filtros que se utilizaran
    const renderRowsByFilters = (rows) => {
        if (Object.keys(filterByTable).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filterByTable);
            for (const element of filterKeys) {
                let valueFiltered;
                if (isNaN(filterByTable[element]) === false) {
                    valueFiltered = filterByTable[element]
                    rows = rows.filter((item) => String(item[element]).indexOf(valueFiltered) != -1)
                } else {
                    valueFiltered = filterByTable[element].toLowerCase()
                    rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
                }
            }
            return rows
        }
    }

    //Funcion para hacer los cambios de informacion en los campos de input, select .etc
    const handleOnChangeInfo = (constant, value) => {
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };

    //Funcion para hacer los cambios de informacion en un role ya creado.
    const handleOnChangeItemInfo = (constant, value) => {
        setItemInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };

    //Funcion que edita la información de un role.
    const handleOnEditItem = () => {
        setLoading(true);
        const body = {
            id: itemInfo.id,
            position: itemInfo.position,
            digitalSignId: itemInfo.main.id,
            substitudeId: !itemInfo.substitude ? null : itemInfo.substitude.id,
            secondaryId: !itemInfo.secondary ? null : itemInfo.secondary.id,
            profiles: itemInfo.profiles
        }
        dispatch(updateTemplatePosition(body)).then((response) => {
            const { payload } = response;
            if (payload.status === 200) {
                setModal(prevState => ({
                    ...prevState,
                    editModal: false
                }));
                Toast.fire({
                    title: "Actualizado",
                    html: "Se actualizo correctamente",
                    type: "success",
                });
                setChangeUpdate(!changeUpdate);
                setItemInfo({});
            }
            setLoading(false);
        });
    }

    //Funcion que trae la data de la tabla principal
    const handleOnGetTableInfo = async () => {
        setLoading(true);
        dispatch(getAllTemplatePosition()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    rows: payload.data.data[0]
                }));
            }
        })
        dispatch(getAllProfile()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                const profileOptions = payload.data.data[0].map((item) => ({ "value": item.id, "label": item.name }))
                setInfoToModule((prevState) => ({
                    ...prevState,
                    profiles: profileOptions
                }));
            }
            setLoading(false);
        })
    }

    //Funcion que confirma si se desea asignar el substituto para el role.
    const handleOnIsAwayConfirm = (row) => {
        const { id, away } = row;
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={away === 1 ? `¿Desea asignar al usuario principal?` : `¿Desea asignar el substituto?`}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnIsAway(id, away)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, asignar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }

    // funcion que asigna al substituto del role especificado.
    const handleOnIsAway = (id, away) => {
        setLoading(true);
        const body = { isAway: away === 1 ? 0 : 1 };
        dispatch(isAway(id, body)).then((response) => {
            const { payload } = response;
            if (payload.status === 200) {
                setLoading(false);
                setAlert("");
                setChangeUpdate(!changeUpdate);
            }
        });
    }

    //Funcion para abrir el modal de crear nueva registro en la tabla principal
    const handleOnOpenModalCreate = (constant) => {
        setModal(prevState => ({
            ...prevState,
            createModal: !openModal.createModal
        }));
        if (openModal.createModal) {
            setItemInfo({
                profiles: []
            });
            setNewInfo({
                profiles: []
            });
            setValidateFindUser({
                main: "",
                substitude: "",
                secondary: ""
            });
        }
    }

    //Funcion para abrir el modal de editar registro en la tabla principal
    const handleOnOpenModalEdit = (item) => {
        setModal(prevState => ({
            ...prevState,
            editModal: !openModal.editModal
        }));
        setItemInfo(item);
        if (openModal.editModal) {
            setItemInfo({
                profiles: []
            });
            setNewInfo({
                profiles: []
            });
            setValidateFindUser({
                main: "",
                substitude: "",
                secondary: ""
            });
        }
    }

    //Funcion que confirma si se desea eliminar el role seleccionado.
    const handleOnDeleteConfirm = (row) => {
        const { id, position } = row;
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={`¿Desea eliminar el usuario: ${position}?`}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnDeleteTemplate(id)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }

    //Funcion que elimina el role sellecionado.
    const handleOnDeleteTemplate = (id) => {
        setLoading(true);
        dispatch(deleteTemplatePosition(id)).then((response) => {
            const { payload } = response;
            if (payload.status === 200) {
                setAlert("");
                setLoading(false);
                setChangeUpdate(!changeUpdate);
            }
        });
    }

    //Funcion que crea un nuevo role.
    const handleOnCreateItem = () => {
        setLoading(true);
        const body = {
            position: newInfo.position,
            digitalSignId: newInfo.main.id,
            substitudeId: !newInfo.substitude ? null : newInfo.substitude.id,
            secondaryId: !newInfo.secondary ? null : newInfo.secondary.id,
            profiles: newInfo.profiles
        }
        dispatch(addTemplatePosition(body)).then((response) => {
            const { payload } = response;
            if (payload.status === 200) {
                setModal(prevState => ({
                    ...prevState,
                    createModal: false
                }));
                Toast.fire({
                    title: "Insertando",
                    html: "Se Inserto correctamente",
                    type: "success",
                });
                setChangeUpdate(!changeUpdate);
                setNewInfo({});
            }
            setLoading(false);
        });
    }

    //Funcion que busca un nuevo aprobador para el nuevo role.
    const handleOnSearchUser = (constant, value) => {
        setLoading(true);
        const user = { user: newInfo[value] };
        if (!user.user) {
            setValidateFindUser((prevState) => ({
                ...prevState,
                [constant]: "Debe ingresar el usuario de la persona.",
            }));
        } else {
            dispatch(getUserSign(user)).then((response) => {
                const { payload } = response;
                if (payload.status === 200) {
                    if (!payload.data.data) {
                        setValidateFindUser((prevState) => ({
                            ...prevState,
                            [constant]: "No se encontro la persona ingresada.",
                        }));
                    } else {
                        setValidateFindUser((prevState) => ({
                            ...prevState,
                            [constant]: "",
                        }));
                        setNewInfo((prevState) => ({
                            ...prevState,
                            [constant]: payload.data.data,
                        }));
                    }
                }
            });
        }
        setLoading(false);
    }

    //Funcion que busca el aprobador de un role ya creado.
    const handleOnEditUser = (constant, value) => {
        const user = { user: itemInfo[value] };
        if (!user.user) {
            setValidateFindUser((prevState) => ({
                ...prevState,
                [constant]: "Debe ingresar el usuario de la persona.",
            }));
        } else {
            dispatch(getUserSign(user)).then((response) => {
                const { payload } = response;
                if (payload.status === 200) {
                    if (!payload.data.data) {
                        setValidateFindUser((prevState) => ({
                            ...prevState,
                            [constant]: "No se encontro la persona ingresada.",
                        }));
                    } else {
                        setValidateFindUser((prevState) => ({
                            ...prevState,
                            [constant]: "",
                        }));
                        setItemInfo((prevState) => ({
                            ...prevState,
                            [constant]: payload.data.data,
                        }));
                    }
                    
                }
            });
        }
        setLoading(false);
    }

    //Funcion que elimina el aprobador asignado para un role ya existente.
    const handleOnClearEditUser = (constant) => {
        setItemInfo((prevState) => ({
            ...prevState,
            [constant]: "",
        }));
    }

    //Funcion que elimina un aprobador seleccionado para buscar uno nuevo, para la creacion de un nuevo role.
    const handleOnClearUserinfo = (constant) => {
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: "",
        }));
    }

    //valida que la infomación necesaria para la creación y edición de los roles.
    const validateInfo = (item) => {
        return !item.position || !item.main || (!item.profiles || item.profiles.length === 0)
    }

    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetTableInfo();
        };
        getInfo();
    }, [changeUpdate]);

    //Render con las opciones nuevas para el modal.
    let renderExtraModelOptions =
        <Row>
            {
                extraModelOptions.map((item, key) => (
                    <Col xs={item.colWidth} key={key}>
                        {
                            item.type === "input" ?
                                <>
                                    {!newInfo[item.userInfo] && !itemInfo[item.userInfo] ?
                                        <FormGroup>
                                            <label className="form-control-label">
                                                {item.label}
                                            </label>
                                            <Input
                                                className="m-0"
                                                required={item.required}
                                                type="text"
                                                defaultValue={itemInfo[item.value]}
                                                onChange={(e) => { item.inputAction(item.value, e.target.value) }}
                                            />
                                            {validateFindUser[item.userInfo] !== "" && (
                                                <div style={{ color: "red" }}>
                                                    {validateFindUser[item.userInfo]}
                                                </div>
                                            )}
                                            <Button
                                                className="btn  btn-icon  mt-2"
                                                color="primary"
                                                size="sm"
                                                type="button"
                                                onClick={() => item.buttonAction(item.userInfo, item.value)}
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-delete" />
                                                </span>
                                                <span>Buscar</span>
                                            </Button>
                                        </FormGroup>
                                        :
                                        <Card>
                                            <CardBody>
                                                <div className="text-center">
                                                    <h5 className="h3">
                                                        {newInfo[item.userInfo] && newInfo[item.userInfo].name || itemInfo[item.userInfo] && itemInfo[item.userInfo].name}
                                                        <span className="font-weight-light">
                                                            {" "}
                                                            {newInfo[item.userInfo] && newInfo[item.userInfo].UserID || itemInfo[item.userInfo] && itemInfo[item.userInfo].UserID}
                                                        </span>
                                                    </h5>
                                                    <div className="h5 font-weight-300">
                                                        <i className="ni location_pin mr-2" />
                                                        {newInfo[item.userInfo] && newInfo[item.userInfo].email || itemInfo[item.userInfo] && itemInfo[item.userInfo].email}
                                                    </div>
                                                    <div className="h5 mt-4">
                                                        <i className="ni business_briefcase-24 mr-2" />
                                                        {newInfo[item.userInfo] && newInfo[item.userInfo].position || itemInfo[item.userInfo] && itemInfo[item.userInfo].position}
                                                    </div>
                                                    <div>
                                                        <i className="ni education_hat mr-2" />
                                                        {newInfo[item.userInfo] && newInfo[item.userInfo].department || itemInfo[item.userInfo] && itemInfo[item.userInfo].department}
                                                    </div>
                                                </div>
                                                <Button
                                                    className="mt-3"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => item.clearAction(item.userInfo)}
                                                >
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-delete" />
                                                    </span>
                                                    <span>Limpiar</span>
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    }
                                </>
                                :
                                <>
                                    <FormGroup>
                                        <label
                                            style={{ fontSize: "12px" }}
                                            className="form-control-label"
                                        >
                                            {item.label}:
                                        </label>
                                        <Select
                                            isDisabled={item.disabled}
                                            key={key}
                                            className="basic-single is-invalid"
                                            type="select"
                                            placeholder={item.placeholder}
                                            required={item.required}
                                            isSearchable
                                            isMulti={item.multi}
                                            options={item.options}
                                            defaultValue={itemInfo[item.value]}
                                            onChange={(e) => item.selectAction(item.value, e)}
                                        />
                                        <div className="invalid-feedback">
                                            Este campo no puede estar vacio
                                        </div>
                                    </FormGroup>
                                </>
                        }
                    </Col>
                ))
            }
        </Row >;
    return (
        <div>
            <AdminHeader name="Usuarios" parentName="Finance Flows" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    {alert}
                    <Filter
                        jsonFilters={filter}
                        filters={handleOnfilterDinamic}
                    />
                    <Table
                        text={"Resultados"}
                        textCreateButton={"Nueva posición"}
                        createButton={true}
                        colums={colums}
                        rows={renderRowsByFilters(infoToModule.rows)}
                        page={page}
                        setPage={setPage}
                        handleOnNewElement={handleOnOpenModalCreate}
                        aditionalsButtons={aditionalsButtons}
                        typeExtraButton={1}
                    />
                    <Modal
                        handleOnChangeInfo={handleOnChangeInfo}
                        text={"Crear nueva posición"}
                        openModal={openModal.createModal}
                        handleOnOpenModal={handleOnOpenModalCreate}
                        itemModal={modalInformation}
                        itemInfo={itemInfo}
                        nameButton1={"Cerrar"}
                        nameButton2={"Guardar"}
                        saveButton={handleOnCreateItem}
                        extramodal={renderExtraModelOptions}
                        saveButtonDisabled={validateInfo(newInfo)}
                    />
                    <Modal
                        handleOnChangeInfo={handleOnChangeItemInfo}
                        text={"Editar posición"}
                        openModal={openModal.editModal}
                        handleOnOpenModal={handleOnOpenModalEdit}
                        itemModal={modalInformation}
                        itemInfo={itemInfo}
                        nameButton1={"Cerrar"}
                        nameButton2={"Guardar"}
                        saveButton={handleOnEditItem}
                        extramodal={renderExtraModelOptions}
                        saveButtonDisabled={validateInfo(itemInfo)}
                    />
                </Spin>
            </Container>
        </div>
    );
};

export default ApprovalRoles;