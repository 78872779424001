// core actions
import { findMyTargetsLetters } from 'actions/targetLetter.jsx';
// antd components
import { Spin } from 'antd';
// core components Target Letter
import CardMyTargetLetters from 'components/TargetLetter/CardMyTargetLetters.jsx';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import CardEmpty from 'components/Shared/Notifications/CardEmpty.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getMyTargetsLetters } from 'selectors/targetLetter.jsx';

class MyTargetLetters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      targetLetter: null
    };
  }

  componentDidMount() {
    this.props.findMyTargetsLetters().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnOpenLetter = row => {
    this.setState({
      targetLetter: row,
    });
  };

  handleOnBack = () => {
    this.setState({
      targetLetter: null,
    });
  };

  render() {

    const {
      name,
      parentName,
      targetLetters,
    } = this.props;

    const {
      loading,
      targetLetter,
    } = this.state;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name={name}
          parentName={parentName}
        />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading}>
            {
              targetLetters.length ?
                <Row>
                  <Col>
                    <CardMyTargetLetters
                      title="Cartas de Objetivos"
                      targetLetter={targetLetter}
                      targetLetters={targetLetters}
                      openFile={this.handleOnOpenLetter}
                      onBack={this.handleOnBack}
                    />
                  </Col>
                </Row>
                :
                <Row className="justify-content-center">
                  <Col sm="12" md="8">
                    <CardEmpty
                      title="No tienes cartas de objetivos."
                      subtitle="Al día de hoy no tienes cartas de objetivos, en el momento que realices la aprobación de una cartas de objetivos, aparecerán en esta ventana."
                    />
                  </Col>
                </Row>
            }
          </Spin>
        </Container>
      </>
    );
  }
}

MyTargetLetters.defaultProps = {
  name: "Mis Cartas de Objetivos",
  parentName: "Cartas de Objetivos",
  targetLetters: []
};

MyTargetLetters.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  targetLetters: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  targetLetters: getMyTargetsLetters(state),
});

export default withRouter(connect(mapStateToProps, {
  findMyTargetsLetters
})(MyTargetLetters));
