/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "FreelanceModal" es un componente compartido por el MaintainComponents de Access para abrir un modal el cual permite 
crear o modificar el tipo, nombre, email y usuario del mismo 

*Props:
  row:va toda la informacion para el llenado de los campos del modal
  setModal: State para definir el tipo de modal que se abre ya sea crear o modificar
  showModal: State false para tener el modal cerra hasta que se de click y se abra el mismo
  dropDownOptions: useState con las opciones de los dropdowns
  setRealTable: State para recargar la pagina principal cuando se modifique
  Toast: funcion para las notificaciones
  title: prop en el cual se envia el titulo que va a aparecer en el modal segun sea crear o modificar
  type:prop en el cual se envia el tipo del modal segun sea crear o modificar
  setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/

// react library
import React, { useState, useEffect } from "react";
//usedispatch for fetch
import { useDispatch } from "react-redux";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
// react-select library for filters
import Select from "react-select";
// antd components para que se muestre un spinner mientras se carga la pagina y su informacion
import { Spin } from "antd";
//accion que se utiliza tanto para la creacion o la modificacion del proveedor
import { updateAccessFreelance, getSubcontractInfo } from "actions/Freelance.jsx";
//action

const FreelanceModal = ({
  row,
  setModal,
  showModal,
  dropDownOptions,
  setRealTable,
  Toast,
  title,
  type,
  setRefreshOptions,
  subcontracts,
  setSubcontracts
}) => {
  //#region Funtions

  //#region state para guardar el modal cerrador y ocultar el boton de enviar
  const [state, setState] = useState({
    unmountOnClose: true,
    readyToSend: true, //se utiliza para mostar o no el boton de envíar
  });

  const { readyToSend, unmountOnClose } = state;

  //#endregion

  //#region state para almacenar la contraseña
  const [passValue, setPassValue] = useState("");
  //#endregion

  //#region state para subcontratados
  const [subcontratados, setSubcontratados] = useState([]);
  //#endregion

  //#region State para generar la contraseña cuando se crea un nuevo proveedor
  useEffect(() => {
    setPassValue(
      new Array(18)
        .fill(
          "$0$1$2$3$4$5$6$7$8$9$ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz<>#"
        )
        .map((x) =>
          (function (chars) {
            let umax = Math.pow(2, 32),
              r = new Uint32Array(1),
              max = umax - (umax % chars.length);
            do {
              crypto.getRandomValues(r);
            } while (r[0] > max);
            return chars[r[0] % chars.length];
          })(x)
        )
        .join("")
    );
    if (row !== "") {
      // let cArray = [];
      // let cArray2 = row["consultantsDropdown"].toString().split(";");
      // console.log(cArray2)
      // cArray2.map((item) => {
      //   let json = JSON.parse(item);
      //   cArray.push(json);
      // });
      // setSubcontratados(cArray);
      if (row.type === 2) {
        setLoad(true);
        dispatch(getSubcontractInfo({ id: row.id })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setSubcontracts(payload.data.subContractPersonal)
          }
          setLoad(false);
        });
      }
    }
  }, [showModal]);
  //#endregion

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region state para el estado del spin
  const [loading, setLoad] = useState(false);
  //#endregion

  //#region state para el cambio de proveedor
  const [freelanceInfo, setFreelanceInfo] = useState({});
  //#endregion

  //#region state para validar si es Persona Juridica
  const [providerType, setProviderType] = useState(false);
  //#endregion

  //#region Funcion para el cambio de informacion dependiendo del tipo de proveedor
  const handleChangeInfo = (field, e) => {
    let val = e;
    if (field === "type") {
      if (e === 2) {
        //persona juridica
        setProviderType(true);
      } else {
        //fisica o subcontratado
        setProviderType(false);
        setFreelanceInfo((prevState) => {
          const { subUser, ...rest } = prevState;
          return rest;
        });
      }
    }
    else if (field === "subUser") {
      // Determine newly selected options
      const newlySelectedOptions = e.filter(
        (option) => !subcontracts.some((sub) => sub.value === option.value)
      );

      // Determine removed options
      const removedOptions = subcontracts.filter(
        (sub) => !e.some((option) => option.value === sub.value)
      );
      const requestData = {
        addedOptions: newlySelectedOptions.map((option) => option.value),
        removedOptions: removedOptions.map((option) => option.value)
      };
      val = requestData; //caerle encima al e para llevar los valores

    }

    setFreelanceInfo((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };
  //#endregion

  //#region Funcion que ejecuta el dispatch para hacer la creacion o modificacion del proveedor
  const handleUpdateFreelance = () => {
    freelanceInfo["typeModal"] = type;
    if (type === "Editar") {
      freelanceInfo["id"] = row.id;
    } else {
      freelanceInfo["pass"] = Buffer.from(passValue.toString()).toString(
        "base64"
      );
    }
    dispatch(updateAccessFreelance({ freelanceInfo })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRealTable((prevState) => !prevState);
        setRefreshOptions((prevCheck) => !prevCheck);
        Toast.fire({
          title: "Atención",
          html: `Proveedor ${type === "Editar" ? "modificado" : "creado"
            } con éxito`,
          type: "success",
        });
      } else {
        Toast.fire({
          title: "Atención",
          html: payload.message,
          type: "warning",
        });
      }
      setLoad(false);
      toggleCloseModal();
    });
  };
  //#endregion

  //#region Funcion para cancelar o cerrar el modal
  const toggleCloseModal = () => {
    setPassValue("");
    setFreelanceInfo({});
    setProviderType(false);
    setModal((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
    }));
  };
  //#endregion

  //#region campos que se muestran en el modal
  const [fields, setFields] = useState([
    {
      colWidth: "6",
      label: "Tipo de proveedor",
      placeholder: "",
      modalType: "both",
      id: "type",
      options: "accessType",
      defaultValue: "access",
      type: "select",
      isabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Nombre del proveedor",
      placeholder: "",
      modalType: "both",
      id: "vendor",
      options: "",
      type: "text",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
    },
    {
      colWidth: "6",
      label: "Email del proveedor",
      modalType: "both",
      placeholder: "",
      id: "email",
      options: "",
      type: "input",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
    },
    {
      colWidth: "6",
      label: "Usuario (correo) del proveedor",
      modalType: "both",
      placeholder: "",
      id: "user",
      options: "",
      type: "input",
      disabled: false,
      visibleDefault: false,
      elementType: "Input",
    },
    {
      colWidth: "6",
      label: "Contraseña",
      modalType: "new",
      placeholder: "",
      id: "pass",
      options: "",
      type: "input",
      disabled: true,
      visibleDefault: false,
      elementType: "Input",
    },
  ]);
  //#endregion

  return (
    <>
      <Modal
        size="lg"
        isOpen={showModal}
        unmountOnClose={unmountOnClose}
        toggle={toggleCloseModal}
      >
        <Spin size="large" spinning={loading}>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody className="mb-lg-0">
            <Form className="needs-validation" noValidate>
              {/* <div className='nav-wrapper'> */}
              <Card className="shadow mb-lg-0">
                <CardBody>
                  <Row>
                    {fields.map((field, key) =>
                      field.id === "pass" && type === "Editar" ? (
                        <></>
                      ) : (
                        <Col sm={field.colWidth} key={key}>
                          <FormGroup key={key}>
                            <label className="form-control-label">
                              {field.label}
                            </label>
                            {field.id === "pass" && type === "Crear" ? (
                              <Input
                                key={field.id}
                                id={field.id}
                                className="form-control"
                                type={field.type}
                                readOnly={field.disabled}
                                value={passValue}
                                placeholder={field.placeholder}
                              />
                            ) : field.elementType === "Input" &&
                              field.id !== "pass" ? (
                              <Input
                                key={field.id}
                                id={field.id}
                                className="form-control"
                                type={field.type}
                                readOnly={field.disabled}
                                defaultValue={
                                  row &&
                                    field.type === "textarea" &&
                                    row[field.id] !== null
                                    ? Buffer.from(
                                      row[field.id],
                                      "base64"
                                    ).toString()
                                    : row[field.id]
                                }
                                onChange={(e) =>
                                  handleChangeInfo(field.id, e.target.value)
                                }
                                placeholder={field.placeholder}
                              />
                            ) : field.elementType === "Select" ? (
                              <Select
                                key={field.id}
                                id={field.id}
                                placeholder={field.placeholder}
                                name={field.id}
                                className="basic-single text-dark"
                                type="select"
                                isSearchable
                                isDisabled={field.disabled}
                                defaultValue={
                                  row && {
                                    label: row[field.defaultValue],
                                    value: row[field.id],
                                  }
                                }
                                onChange={(e) =>
                                  handleChangeInfo(field.id, e.value)
                                }
                                options={dropDownOptions[field.options]}
                              />
                            ) : (
                              <></>
                            )}
                          </FormGroup>
                        </Col>
                      )
                    )}
                    {row["type"] === 2 || providerType ? (
                      <Col sm="12">
                        <FormGroup>
                          <label className="form-control-label">
                            {"Usuario Subcontratados"}
                          </label>
                          <Select
                            id={"subUser"}
                            placeholder={"Usuarios Subcontratados"}
                            name={"subUser"}
                            className="basic-single text-dark"
                            type="select"
                            isSearchable
                            isMulti={true}
                            defaultValue={subcontracts}
                            onChange={(e) => handleChangeInfo("subUser", e)}
                            options={dropDownOptions["Subcontratados"]}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-icon"
              color="success"
              size="sm"
              block
              type="button"
              onClick={(e) => handleUpdateFreelance()}
            >
              <span className="btn-inner--text">
                {type === "Crear" ? "Crear Proveedor" : "Modificar Proveedor"}
              </span>
            </Button>

            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              block
              type="button"
              onClick={(e) => toggleCloseModal()}
            >
              <span className="btn-inner--text">Cerrar</span>
            </Button>
          </ModalFooter>
        </Spin>
      </Modal>
    </>
  );
};

export default FreelanceModal;
