import React from "react";
import { Card, CardBody, CardFooter, Row, Table } from "reactstrap";

const QuickQuoteEditFormTwo = ({ products }) => {
  const colTable = [
    {
      value: "productService",
      label: "Producto o Servicio",
    },
    {
      value: "clasification",
      label: "Clasifica",
    },
    {
      value: "ammount",
      label: "Cantidad",
    },
    {
      value: "unit",
      label: "Unidad",
    }
  ];
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            {/* {products.products.length > 0 && ( */}
            <Card>
              <CardBody>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  striped={true}
                  hover={true}
                >
                  <thead className="thead-light">
                    <tr>
                      {colTable.map((row, key) => {
                        return (
                          <th key={key} className="justify-content-md-center">
                            {row.label}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {products.products.map((row, key) => (
                      <tr key={key} className="text-center">
                        {colTable.map((col, key) => (
                          <td key={key} className="text-center">
                            {row[col.value]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <h4>{products.rowSelect.estimatePrice === "" ? "" : `SubTotal: ${products.rowSelect.estimatePrice}`}</h4>
              </CardFooter>
            </Card>
            {/* )} */}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default QuickQuoteEditFormTwo;
