// core actions
import { findRequestByUser } from "actions/newPosition.jsx";
// antd components
import { Spin } from 'antd';
// core components
import CardRequests from 'components/NewPosition/CardRequests.jsx';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import Table from "components/Shared/Table/Table.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
// core selectors
import { getUsernameLogged, getUserProtected } from "selectors/adminLayout.jsx";
import { getStaffRequestByUser, getUnplannedRequestByUser, getVacantRequestByUser, getNewCecoPositionRequestByUser } from "selectors/newPosition.jsx";
class MyRequests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: props.unplanned.length ? false : true,
      colors: ["#f5c9c9", "#ffffff", "#ffffff"],
      requestTypeSelected: "unplanned",
      page: 1,
      sizePerPage: 5,
      alert: null
    };
  }


  componentWillMount() {
    const { user } = this.props;
    this.props.findRequestByUser({
      user: user.EMAIL.toLowerCase()
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false
      });
    });
  }


  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  handleOnSelectData = key => {
    const newColors = ["#ffffff", "#ffffff", "#ffffff"];
    const colors = ["#f5c9c9", "#c9f5d0", "#d6d4fa" ,"#FFC2A5" ];
    if (key === "unplanned") {
      newColors[0] = colors[0];
    } else if (key === "staff") {
      newColors[1] = colors[1];
    } else if (key === "vacant") {
      newColors[2] = colors[2];
    }else if (key ==="newCecoPosition"){
      newColors[3] = colors[3];
    }
    this.setState({
      colors: newColors,
      requestTypeSelected: key,
    });
  }

  expandRowUnplanned = {
    parentClassName: '#2dce89',
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Fijo</span> <br />
              {row.fixed}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Variable</span>{" "}<br />
              {row.variable}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Employee SubGroup</span> <br />
              {row.employeeSubGroup}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>% Fijo</span> <br />
              {row.fixedPercent}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>% Variable</span> <br />
              {row.variablePercent}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tiene EPM</span> <br />
              {row.haveEPM}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>¿Es Gerente?</span> <br />
              {row.isManager}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Productividad</span> <br />
              {row.productivity}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Linea de Negocio</span> <br />
              {row.bussinessLine}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Dirección</span> <br />
              {row.direction}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tipo de Posición</span> <br />
              {row.positionType}
            </div>
          </div>
        </span>
      </div>
    ),
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  }

  expandRow = {
    parentClassName: '#2dce89',
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
      </div>
    ),
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  }

  expandRowStaffVacant = {
    parentClassName: '#2dce89',
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tiene EPM</span> <br />
              {row.haveEPM}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>¿Es Gerente?</span> <br />
              {row.isManager}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Productividad</span> <br />
              {row.productivity}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Local - Regional (PLA)</span> <br />
              {row.localRegionalType}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>% Fijo</span> <br />
              {row.fixedPercent}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>% Variable</span> <br />
              {row.variablePercent}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Área Personal</span> <br />
              {row.personalArea}
            </div>

          </div>
        </span>
      </div>
    ),
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  }

  expandRowNewCecoPosition = {
    parentClassName: '#2dce89',
    onlyOneExpanding: false,
    renderer: (row) => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tiene EPM</span> <br />
              {row.haveEPM}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>¿Es Gerente?</span> <br />
              {row.isManager}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Productividad</span> <br />
              {row.productivity}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Local - Regional (PLA)</span> <br />
              {row.localRegionalType}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>% Fijo</span> <br />
              {row.fixedPercent}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>% Variable</span> <br />
              {row.variablePercent}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Área Personal</span> <br />
              {row.personalArea}
            </div>

          </div>
        </span>
      </div>
    ),
    showExpandColumn: false,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  }

  editVacantRequest = row => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de editar la solicitud?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.props.history.push(`${this.props.match.url}/update-vacant/${row.id}`)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, editar"
          cancelBtnText="No, regresar"
          btnSize="md"
        />
      )
    });
  }

  render() {

    const { alert, requestTypeSelected, colors, loading, page, sizePerPage } = this.state;

    const { colsUnplanned, colsStaff, colsNewCecoPosition,unplanned, staff, vacant, newCecoPosition } = this.props;

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc"
      }
    ];

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Mis solicitudes" parentName="Nueva Posición" />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading}>
            <Row>
              <Col sm="12" md="3">
                <CardRequests
                  title="Creación de posición no planificada"
                  icon="fas fa-file-signature"
                  count={unplanned.length}
                  keyRequest="unplanned"
                  selectData={this.handleOnSelectData}
                  color={colors[0]}
                  gradiend="bg-gradient-red"
                />
              </Col>
              <Col sm="12" md="3">
                <CardRequests
                  title="Modificación de posición con personal"
                  icon="fas fa-file-alt"
                  count={staff.length}
                  keyRequest="staff"
                  selectData={this.handleOnSelectData}
                  color={colors[1]}
                  gradiend="bg-gradient-green"
                />
              </Col>
              <Col sm="12" md="3">
                <CardRequests
                  title="Modificación de posición vacante"
                  icon="fas fa-file"
                  count={vacant.length}
                  keyRequest="vacant"
                  selectData={this.handleOnSelectData}
                  color={colors[2]}
                  gradiend="bg-gradient-primary"
                />
              </Col>
              <Col sm="12" md="3">
                <CardRequests
                  title="Modificación de centro de costos"
                  icon="fas fa-file-upload"
                  count={newCecoPosition.length}
                  keyRequest="newCecoPosition"
                  selectData={this.handleOnSelectData}
                  color={colors[3]}
                  gradiend="bg-gradient-red"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Table
                  title="Solicitudes realizadas"
                  subtitle={`Todas las solicitudes realizadas.`}
                  columns={
                    requestTypeSelected === 'unplanned' ?
                      colsUnplanned
                      : requestTypeSelected === 'staff' ?
                        colsStaff
                        :requestTypeSelected === 'newCecoPosition' ? 
                        colsNewCecoPosition
                        : [
                          {
                            dataField: "positionNumber",
                            text: "Numero de posición",
                            sort: true
                          },
                          {
                            dataField: "changeRequestDate",
                            text: "Fecha de cambio",
                            sort: true
                          },
                          {
                            dataField: "managerPositionNumber",
                            text: "Posición de gerente",
                            sort: true
                          },
                          {
                            dataField: "position",
                            text: "Posición",
                            sort: true
                          },
                          {
                            dataField: "careerLevel",
                            text: "Nivel de Carrera",
                            sort: true
                          },
                          {
                            dataField: "uniOrg",
                            text: "Unidad Organizacional",
                            sort: true
                          },
                          {
                            dataField: "ceco",
                            text: "Centro de Costo",
                            sort: true
                          },
                          {
                            dataField: "localRegionalType",
                            text: "Local - Regional (PLA)",
                            hidden: true
                          },
                          {
                            dataField: "isManager",
                            text: "¿Es Gerente?",
                            hidden: true
                          },
                          {
                            dataField: "haveEPM",
                            text: "Tiene EPM",
                            hidden: true
                          },
                          {
                            dataField: "productivity",
                            text: "Productividad",
                            hidden: true
                          },
                          {
                            dataField: "fixedPercent",
                            text: "% Fijo",
                            hidden: true
                          },
                          {
                            dataField: "variablePercent",
                            text: "% Variable",
                            hidden: true
                          },
                          {
                            dataField: "approved",
                            text: "Status",
                            sort: true
                          },
                          {
                            dataField: "commentary",
                            text: "Comentario",
                            sort: true
                          },
                          {
                            dataField: "reviewedBy",
                            text: "Revisado por",
                            sort: true
                          },
                          {
                            dataField: "actions",
                            isDummyField: true,
                            text: "",
                            csvExport: false,
                            formatter: (cellContent, row) => {
                              return (
                                row.approved !== "Aprobada" ?
                                  <>
                                    <Button
                                      onClick={() => this.editVacantRequest(row)}
                                      className="btn-icon btn-2"
                                      color="success "
                                      type="button"
                                      size="sm"
                                    >
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-user-edit" />
                                      </span>
                                    </Button>
                                  </>
                                  :
                                  <span className="btn-inner--icon">
                                    <i className="fas fa-user-check" />
                                  </span>
                                // :
                                // row.approved === "Rechazada" ?
                                //   <span className="btn-inner--icon">
                                //     <i className="fas fa-user-times" />
                                //   </span>
                                //   :
                                //   <span className="btn-inner--icon">
                                //     <i className="fas fa-user-clock" />
                                //   </span>
                              );
                            }
                          }
                        ]
                  }
                  data={
                    requestTypeSelected === 'unplanned' ?
                      unplanned
                      : requestTypeSelected === 'staff' ?
                        staff
                       : requestTypeSelected === 'newCecoPosition' ?
                          newCecoPosition  
                       :vacant 
                  }
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={
                    requestTypeSelected === 'unplanned' ?
                      unplanned.length
                      : requestTypeSelected === 'staff' ?
                        staff.length
                        : requestTypeSelected === 'newCecoPosition' ?
                          newCecoPosition.length
                        : vacant.length
                  }
                  defaultSorted={defaultSorted}
                  expandFeatrue={
                    requestTypeSelected === 'unplanned' ?
                      this.expandRowUnplanned 
                      :requestTypeSelected === 'newCecoPosition' ?
                       this.expandRowNewCecoPosition
                      : this.expandRowStaffVacant 

                  }
                  cvsFeatrue
                  fileName={
                    requestTypeSelected === 'unplanned' ?
                      'Solicitudes No Planificadas'
                      : requestTypeSelected === 'staff' ?
                        'Solicitudes Con Personal'
                        : requestTypeSelected === 'newCecoPosition' ?
                            'Solicitudes de Centros de Costos' 
                            : 'Solicitudes Vacantes'
                  }
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

MyRequests.defaultProps = {
  unplanned: [],
  staff: [],
  vacant: [],
  newCecoPosition: [],
  user: '',
  username: '',
  colsUnplanned: [
    {
      dataField: "position",
      text: "Posición",
      sort: true
    },
    {
      dataField: "managerNumberPosition",
      text: "Posición del gerente",
      sort: true
    },
    {
      dataField: "localRegionalType",
      text: "Local - Regional (PLA)",
      sort: true
    },
    {
      dataField: "isManager",
      text: "¿Es Gerente?",
      hidden: true
    },
    {
      dataField: "haveEPM",
      text: "Tiene EPM",
      hidden: true
    },
    {
      dataField: "productivity",
      text: "Productividad",
      hidden: true
    },
    {
      dataField: "bussinessLine",
      text: "Linea de Negocio",
      hidden: true
    },
    {
      dataField: "direction",
      text: "Dirección",
      hidden: true
    },
    {
      dataField: "employeeSubGroup",
      text: "Employee SubGroup",
      hidden: true
    },
    {
      dataField: "positionType",
      text: "Tipo de Posición",
      hidden: true
    },
    {
      dataField: "fixed",
      text: "Fijo",
      hidden: true
    },
    {
      dataField: "variable",
      text: "Variable",
      hidden: true
    },
    {
      dataField: "fixedPercent",
      text: "% Fijo",
      hidden: true
    },
    {
      dataField: "variablePercent",
      text: "% Variable",
      hidden: true
    },
    {
      dataField: "changeRequestDate",
      text: "Fecha",
      sort: true
    },
    {
      dataField: "careerLevel",
      text: "Nivel de Carrera",
      sort: true
    },
    {
      dataField: "uniOrg",
      text: "Unidad Organizacional",
      sort: true
    },
    {
      dataField: "personalArea",
      text: "Área de Personal",
      sort: true
    },
    {
      dataField: "ceco",
      text: "Centro de Costo",
      sort: true
    },
    {
      dataField: "country",
      text: "País",
      sort: true
    },
    {
      dataField: "personalBranch",
      text: "Subdivisión de Personal",
      sort: true
    },
  ],
  colsStaff: [
    {
      dataField: "positionNumber",
      text: "Numero de posición",
      sort: true
    },
    {
      dataField: "changeRequestDate",
      text: "Fecha de cambio",
      sort: true
    },
    {
      dataField: "managerPositionNumber",
      text: "Posición de gerente",
      sort: true
    },
    {
      dataField: "position",
      text: "Posición",
      sort: true
    },
    {
      dataField: "careerLevel",
      text: "Nivel de Carrera",
      sort: true
    },
    {
      dataField: "uniOrg",
      text: "Unidad Organizacional",
      sort: true
    },
    {
      dataField: "ceco",
      text: "Centro de Costo",
      sort: true
    },
    {
      dataField: "localRegionalType",
      text: "Local - Regional (PLA)",
      hidden: true
    },
    {
      dataField: "isManager",
      text: "¿Es Gerente?",
      hidden: true
    },
    {
      dataField: "haveEPM",
      text: "Tiene EPM",
      hidden: true
    },
    {
      dataField: "productivity",
      text: "Productividad",
      hidden: true
    },
    {
      dataField: "fixedPercent",
      text: "% Fijo",
      hidden: true
    },
    {
      dataField: "variablePercent",
      text: "% Variable",
      hidden: true
    },
  ],
  colsNewCecoPosition: [
    {
      dataField: "positionNumber",
      text: "Numero de posición",
      sort: true
    },
    {
      dataField: "changeRequestDate",
      text: "Fecha de cambio",
      sort: true
    },
    {
      dataField: "managerNumberPosition",
      text: "Posición de gerente",
      sort: true
    },
    {
      dataField: "OrganizationalUnit",
      text: "Unidad Organizacional",
      sort: true
    },
    {
      dataField: "personalArea",
      text: "Area Personal",
      sort: true
    },
    {
      dataField: "actualCeco",
      text: "Centro de Costo Actual",
      sort: true
    },
    {
      dataField: "cecoN",
      text: "Centro de Costo Nuevo",
      sort: true
    },
    {
      dataField: "comments",
      text: "Centro de Costo Nuevo",
      sort: true
    },

  ]

}

MyRequests.propTypes = {
  title: PropTypes.string,
  unplanned: PropTypes.array.isRequired,
  staff: PropTypes.array.isRequired,
  vacant: PropTypes.array.isRequired,
  newCecoPosition: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  user: getUserProtected(),
  unplanned: getUnplannedRequestByUser(state),
  staff: getStaffRequestByUser(state),
  vacant: getVacantRequestByUser(state),
  newCecoPosition: getNewCecoPositionRequestByUser(state),
  username: getUsernameLogged(),
});

export default withRouter(connect(
  mapStateToProps,
  {
    findRequestByUser
  }
)(MyRequests));
