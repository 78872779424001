/*!

=========================================================
*FiltersStatsDatabot
=========================================================

*Componente para aplicar filtros a los gráficos de tipo 
lineales como los de ahorro, ahorro lineas, iteraciones, y 
también filtros a los cards de información de la parte superior
de la vista de estadísticas.
=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/

//Librería de React
import React, { useState, useContext, useEffect } from 'react'

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Librería de Reactstrap
import { Card, CardHeader, CardBody, Col, Row, FormGroup, Button } from "reactstrap";

import { MultiSelect } from "react-multi-select-component";



//Componente para desplegar un campo select.
import Select from "react-select";

export const FiltersStatsDatabot = () => {

    //#region States Globales
    const {
        setDrawerHelpComponents,
        setShowMoreInfoDatabot,
        drawerHelpComponents,
        setFilterSelection,
        setDatabotOptions,
        databotOptions,
        // graphsData,

    } = useContext(UserContext);
    //#endregion

    //#region Efectos y states 
    //#region Establecer los textos tipo título y subtítulos.

    const [text, setText] = useState({
        title: "Filtros a Aplicar",
        subtitle: "A continuación puede aplicar los filtros a los gráficos del Databot."
    })
    const { title, subtitle } = text;
    //#endregion


    //Arreglo para establecer los selects, además cada uno tiene propiedades como definir si sus opciones dependen de la selección 
    //de otro dropdown y en base al mismo se filtran sus opciones.
    const [fieldsFilters, setFieldsFilters] = useState(

        [
            {
                colWidth: "4",
                label: "Año",
                placeholder: "Seleccione un filtro",
                id: 'year',
                options: 'year',
                optionsDependsAnotherDropdown: "",
                type: "select",
                isDisabledByOtherId: ""
            },
            {
                colWidth: "4",
                label: "Torre",
                placeholder: "Seleccione el filtro",
                id: 'tower',
                options: 'tower',
                optionsDependsAnotherDropdown: "",
                type: "multiselect",
                isDisabledByOtherId: "functionalArea"
            },
            {
                colWidth: "4",
                label: "Área funcional",
                placeholder: "Seleccione el filtro",
                id: 'functionalArea',
                options: 'functionalArea',
                optionsDependsAnotherDropdown: "tower",
                type: "multiselect",
                isDisabledByOtherId: "department"
            },
            {
                colWidth: "4",
                label: "Departamento",
                placeholder: "Seleccione el filtro",
                id: 'department',
                options: 'department',
                optionsDependsAnotherDropdown: "functionalArea",
                type: "multiselect",
                isDisabledByOtherId: "robot"
            },
            {
                colWidth: "4",
                label: "Robot",
                placeholder: "Seleccione el filtro",
                id: 'bots',
                options: 'bots',
                optionsDependsAnotherDropdown: "department",
                type: "multiselect",
                isDisabledByOtherId: "department"
            },
        ]
    )


    //#region State local guarda la selección antes de enviarlo globalmente
    const [localFilterSelection, setLocalFilterSelection] = useState(null)
    //#endregion


    //State donde se almacenan las opciones moldeadas por los métodos recursivos.
    const [optionsGenerated, setOptionsGenerated] = useState(databotOptions);



    //Efecto el  arrancar el componente el cual establece las opciones predeterminadas para los filtros.
    useEffect(() => {

        if (Object.keys(databotOptions).length > 0 && databotOptions.year === undefined) {

            //Llenar state de la selección de filtros
            fieldsFilters.map(field => (
                setLocalFilterSelection(item => {
                    return { ...item, [field.id]: "" }
                })
            ))
            //#region Establecer los últimos 3 años en las options.
            let currentYear = new Date().getFullYear();
            let counter = 0;

            let years = []
            while (counter != 3) {
                years = [...years, { label: currentYear - counter, value: (counter + 1) }];
                counter++;
            }

            setOptionsGenerated(
                {
                    ...databotOptions, ["year"]: years
                })

            setDatabotOptions(options => {
                return { ...options, ["year"]: years }
            })


            //#endregion

        }
    }, [databotOptions])

    //#endregion

    //#region Métodos recursivos para establecer y limpiar opciones de los campos padres e hijos debido a que hay campos que dependen de otros.

    //Método recursivo que asigna las opciones de los campos "Hijos", según la selección del padre (filtros).
    const modifyOptions = (idFather, valueFather) => {

        // const currentField = fieldsFilters.filter(field => field.id == idFather)
        const childField = fieldsFilters?.filter(field => field.optionsDependsAnotherDropdown == idFather)

        if (childField[0] != undefined) //Verifica si ya no tiene hijos
        {

            if (Array.isArray(valueFather)) { //Si es un array significa que viene las opciones

                let ids = []
                valueFather.map(e => { //Extrae los ids de las opciones.
                    ids = [...ids, e.value]
                })

                const optsWithFilters = databotOptions[childField[0].id]?.filter(option => {

                    if (ids.includes(option[childField[0].optionsDependsAnotherDropdown])) // Si la opción padre de la opción actual es el id padre
                    {
                        return option;
                    }
                })

                // console.log(optsWithFilters)

                setOptionsGenerated(item => {
                    return {
                        ...item, [childField[0].id]:
                            valueFather.length > 0 ?
                                optsWithFilters :
                                databotOptions[childField[0].id]
                    }
                })

                modifyOptions(childField[0].id, optsWithFilters)



            } else { // Es un valor, normalmente cuando es un valor es la primer vuelta de la recursividad.



                const optsWithFilters = databotOptions[childField[0].id]?.filter(option => {

                    if (option[childField[0].optionsDependsAnotherDropdown] === valueFather) // Si la opción padre de la opción actual es el id padre
                    {
                        return option;
                    }
                })

                // console.log(optsWithFilters)

                setOptionsGenerated(item => {
                    return { ...item, [childField[0].id]: optsWithFilters }
                })

                //Recursividad del método
                modifyOptions(childField[0].id, optsWithFilters)
            }

        }
    }

    //Limpia los campos hijos
    const cleanChildInfo = (idFather) => {

        const childField = fieldsFilters?.filter(field => field.optionsDependsAnotherDropdown == idFather)

        if (childField.length > 0) {
            setLocalFilterSelection(item => {
                return { ...item, [childField[0].id]: "" }
            })

            cleanChildInfo(childField[0].id)

        }

    }

    //#endregion

    //#region Handles y métodos útiles

    //Handle para los cambios
    const handleOnChange = (idField, e, type) => {

        let selectAllOptions = false;
        //Determina si se selecciono la opción "Select all options"
        try {
            e && e.map(option => {
                if (option.value === 0 || option.label == "Select all options") {
                    selectAllOptions = true;
                }
            })
        } catch (e) { }

        if (selectAllOptions == true) {

            // debugger
            setLocalFilterSelection(item => {
                return { ...item, [idField]: optionsGenerated[idField] }
            })

            cleanChildInfo(idField)

            //Asigna las opciones a los campos hijos.
            modifyOptions(idField, optionsGenerated[idField])

        } else {
            // console.log(optionsGenerated[idField])

            setLocalFilterSelection(item => {
                return { ...item, [idField]: e }
            })


            //Limpia la selección hija
            cleanChildInfo(idField)

            //Asigna las opciones a los campos hijos.
            modifyOptions(idField, e)


        }




    }



    //Handle para limpiar los filtros
    const handleOnClean = () => {

        //Limpia el state local
        fieldsFilters.map(field => (
            setLocalFilterSelection(item => {
                return { ...item, [field.id]: "" }
            })
        ))

        //Limpia el state global también
        setFilterSelection(null);
        setOptionsGenerated(databotOptions); //Pone las opciones completas

        //Para que limpie el gráfico y ponga el spinner
        // delete graphsData.byMonth;

    }

    //Handle para enviar los filtros globalmente a los gráficos.
    const handleOnSetFilters = () => {
        setFilterSelection(localFilterSelection); //Entonces en el componente stats hay un efecto para cuando cambie filterSelection envie el dispatch

        //Para que limpie el gráfico y ponga el spinner
        // delete graphsData.byMonth;

    }
    //Método para mostrar el título o descripción en la parte derecha de los filtros cuando un robot cuando es seleccionado en el filtro.
    const showTitleOrDescription = (type) => {

        if (localFilterSelection != null &&
            localFilterSelection.bots != '' &&
            localFilterSelection.bots != null) {

            if (type == 'title') {
                return localFilterSelection.bots[localFilterSelection.bots.length - 1].label /*Muestra el título del último robot */
            } else if (type == 'description') {
                return localFilterSelection.bots[localFilterSelection.bots.length - 1].description /*Muestra la descripción del último robot */
            }
        } else {
            return ''
        }

    }

    //Método para ordenar las opciones de forma ascendente o descendente.
    function sortJSON(array, key, orden) {

        let toReturn = array?.sort(function (a, b) {
            var x = a[key].toString().toUpperCase(),
                y = b[key].toString().toUpperCase();

            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }

            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
        return toReturn;
    }

    //#endregion 



    return (
        <>

            <Row>
                <Col sm='8' style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Button
                        className="btn-info btn-round btn-icon"
                        color="info"
                        id="helpfilterstBtn"
                        size="sm"
                        outline
                        onClick={(e) => setDrawerHelpComponents({ ...drawerHelpComponents, visible: true, type: 'filters' })}
                    >
                        <span className="btn-inner--icon ">
                            <i className="fa fa-info-circle" />
                        </span>
                    </Button>
                </Col>
                <Col sm='4' style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        className="btn-info btn-round btn-icon"
                        color="default"
                        id="helpRequestBtn"
                        size="sm"
                        onClick={(e) => setShowMoreInfoDatabot(true)}
                    >
                        <span className="btn-inner--icon mr-1">
                            <i className="fa fa-info-circle" />
                        </span>
                        <span className="btn-inner--text">Más información del Databot</span>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col sm="9">

                    <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xs="12" md="10">
                                    <h3 className="mb-0" >{title}</h3>
                                    <p className="text-sm mb-0">{subtitle}</p>
                                </Col>

                                <Col xs="12" md="2" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>


                                    <Button
                                        className="btn-icon"
                                        color="danger"
                                        size="sm"
                                        block
                                        type="button"
                                        onClick={handleOnClean}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-trash" />
                                        </span>
                                        <span className="btn-inner--text">
                                            Limpiar
                                        </span>
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {
                                    fieldsFilters.map((fieldSelect) => (

                                        fieldSelect.type == 'multiselect' ?
                                            <Col className="text-right" xs="12" md={fieldSelect.colWidth}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="country"
                                                    >
                                                        {fieldSelect.label}
                                                    </label>
                                                    <Select
                                                        isMulti
                                                        isSearchable
                                                        className="basic-single"
                                                        id="country"
                                                        type="select"
                                                        placeholder={fieldSelect.placeholder}
                                                        options={optionsGenerated[fieldSelect.options] && [{ "label": "Select all options", "value": 0 }].concat(sortJSON(optionsGenerated[fieldSelect.options], "label", "asc"))}
                                                        value={localFilterSelection ? localFilterSelection[fieldSelect.id] : ""}
                                                        onChange={(e) => handleOnChange(fieldSelect.id, e, fieldSelect.type)}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            : //Select normal
                                            <Col className="text-right" xs="12" md={fieldSelect.colWidth}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="country"
                                                    >
                                                        {fieldSelect.label}
                                                    </label>
                                                    <Select
                                                        isSearchable
                                                        className="basic-single"
                                                        id="country"
                                                        type="select"
                                                        placeholder={fieldSelect.placeholder}
                                                        options={sortJSON(optionsGenerated[fieldSelect.options], "label", "asc")}
                                                        value={localFilterSelection ? localFilterSelection[fieldSelect.id] : ""}
                                                        onChange={(e) => handleOnChange(fieldSelect.id, e, fieldSelect.type)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                    ))
                                }
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-center align-items-center">
                                    <Button
                                        className="btn-icon"
                                        color="success"
                                        size="md"
                                        type="button"
                                        onClick={handleOnSetFilters}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-search" />
                                        </span>
                                        <span className="btn-inner--text">
                                            Aplicar Filtro
                                        </span>
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="3">

                    <Card className="card-profile bg-secondary p-2 pl-0 pr-0">
                        <CardBody
                            className="pl-1 pr-1"
                            style={{ backgroundColor: "#e8f5fa" }}
                        >
                            <div className="mb-4 pl-0 pr-0 justify-content-center " style={{ alignItems: "center", height: "100%" }}>

                                <h4 >
                                    {showTitleOrDescription('title') != '' ? showTitleOrDescription('title') : ''} {/*Muestra el último robot */}

                                </h4>


                                <p className="p-0" align="justify" style={{ fontSize: 14 }} >
                                    <span > {showTitleOrDescription('description')} {/*Muestra el último robot */}</span>

                                </p>





                            </div>
                        </CardBody>
                    </Card>
                </Col>

            </Row>

        </>
    )
}
