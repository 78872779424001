/*!

=========================================================
*MasterData - IndividualFormModal
=========================================================

* Este componente es un modal que se puede utilizar en cualquier 
*sitio de datos maestros para desplegar los formularios individuales
*de tipo lineal partiendo de un json enviado por props (generalStates).

Props: 
-IndividualFormModal: Es un Json que contiene valores como: 
        {
            showModal: false, //mostrar y desaparecer el modal *necesario
            view: view, //requests, approvals, newrequest       *necesario
            row: linealRow, //En caso de cargar información predefinida como en Mis Gestiones o aprobaciones. *vacío o lleno
            formName: reqGeneralInfo?.Formulario, //Nombre del formulario para hacer match con generalStates  *necesario
            newInfo: //En este json se guarda la nueva información del formulario llenada por el usuario para luego ser consumida *vacío o lleno
                info: {},
                updateInfo: false
            },                        
            typeOfManagementId: newInfo?.info.typeOfManagement.value,//reqGeneralInfo?.typeOfManagementId //Creación o modificación *necesario
            countryClientId: newInfo.info.sendingCountry?.value, //Para la excepción de clientes *vacío o lleno
            vendorGroup: newInfo.info.vendorGroup?.value, //Para la excepción de proveedores *vacío o lleno
            companyCode: newInfo.info.companyCode?.value //Para la excepción de proveedores *vacío o lleno
        }

-stateModalsIndvForms={stateModalsIndvForms} //Para gestionar el arreglo de modals 
-setStateModalsIndvForms={setStateModalsIndvForms}//Para gestionar el arreglo de modals 

-options={options} //Las opciones para los dropdowns
-employeesOptions={employeesOptions}    //La lista con los nombres de los empleados para el typeahead (no en todos los formularios se necesita).

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Librerías de react.
import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { Typeahead } from "react-bootstrap-typeahead";

//Import de Spinner
import { Spin } from "antd";

// imports de actions
import { existMasterDataByKey } from "actions/masterData";

// react plugin for creating notifications over the dashboard
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples'

//Componentes Reactstrap
import {
  ModalFooter,
  InputGroup,
  FormGroup,
  ModalBody,
  Button,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap";

//Json general con los compos para el formulario
import formFields from "./GeneralStates";

//Función para convertir un número de cuenta corriente a Iban
import convertToIban from "./convertToIban";

export const IndividualFormModal = ({
  individualFormModal,

  stateModalsIndvForms,
  setStateModalsIndvForms,

  employeesOptions,
  options,

  index,
}) => {
  //#region Desestructuraciones

  // Función para traer solicitudes de la DB
  const dispatch = useDispatch();
  //Extracción de librería swal.
  const MySwal = withReactContent(Swal);

  //Para declarar referencias.
  const ref = React.createRef();

  //Desestructuración clave inicial del campo de props: individualFormModal

  const {
    view, //Define la vista, si es de creación, mis gestiones, mis aprobaciones etc
    row, //Información con la fila del detalle
    formName, //Define si el formulario es clientes, proveedores, etc
    typeOfManagementId, //Si es creación o modificación.
    isEdit, //Para saber si es editar o nueva linea
  } = individualFormModal;

  let formNameFormat = formName.toLowerCase().replace(/ /g, "").normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace("de", "");

  let fields =
    formNameFormat === "ibase"
      ? parseInt(typeOfManagementId) === 1 //Verifica el tipo
        ? formFields[formNameFormat]?.formDataCreation //Tipo 1 que es creación
        : formFields[formNameFormat]?.formDataModification //Tipo 2 que es modificación
      : formFields[formNameFormat]?.formData; //todos como proveedores, clientes, materiales, etc

  //#endregion

  //#region Declaración de states.

  //State del modal
  const [stateDetail] = useState({
    unmountOnClose: true,
    readyToSend: false,
    idFileRequest: "",
    fileList: [],
  });
  //Útil para funcionar tipo bandera, para habilitar o deshablilitar campos.
  const [disableFields, setDisableFields] = useState(false);

  //Bandera para activar efecto y verificar si un campo llave es válido o no.
  const [verifyFieldKeyIsValid, setVerifyFieldKeyIsValid] = useState({
    exist: false,
    validateForm: false,
    searchingSpinner: false,
  });

  //State para guardar la información de los campos del componente.
  const [newInfo, setNewInfo] = useState({ info: {}, updateInfo: false });

  //#endregion

  //#region Efectos para llenar campos al arrancar, la habilitación de campos,limpiar constantes, etc.

  //Efecto diseñado para cuando arranque el modal, recorra los campos del json (generalStates), y vaya llenando el state newInfo
  //para que los campos del formulario se seleccionen automáticamente en base a newInfo que incialmente viene vacío

  useEffect(() => {

    //En caso de que antes se haya actualizado, que antes se generó el newInfo, por tanto no debe realizarse otra vez
    if (individualFormModal?.newInfo.updateInfo === true) {
      setNewInfo(individualFormModal.newInfo);
      return;
    }

    //En caso de que haya un campo llave que deshabilite todo por defecto
    const quantitySearchFieldExist = fields?.filter(
      (field) => field.type === "searchField"
    ).length;
    if (quantitySearchFieldExist > 0) {
      setDisableFields(true);
    }

    try {
      fields.map((field) => {
        if (field.type === "select") {
          //De tipo select  y el campo lo guarda como label: value
          //debugger;

          //console.log(newInfo[rowN[field.id]])
          setNewInfo((prevState) => ({
            ...prevState,
            info: {
              ...prevState.info,
              [field.id]: {
                value: row[field.id] === undefined ? null : row[field.id],
                label:
                  field.searchValue === true //Si searchvalue (establecido en GeneralStates) quiere decir que en la consulta al api solo viene el id del campo (ya que es una fk), por tanto consulta en el arreglo de opciones de los dropdowns la opción con el nombre del campo y id del campo
                    ? options[field.options]
                      ?.filter(
                        (e) =>
                          e.value === row[field.id] ||
                          e.value === row[field.options]
                      )[0]
                      ?.label.toString()
                      .trim() //Consulta al arreglo de opciones y extrae el que tiene el id
                    :
                    row[field.id] === undefined ? null : row[field.id], //El valor viene por defecto
              },
            },
            updateInfo: false,
          }));
        } else if (field.type === "date") {
          //De tipo fecha
          //debugger;

          setNewInfo((prevState) => ({
            ...prevState,
            info: {
              ...prevState.info,
              [field.id]:
                //row[field.id]?.split('T')[0]
                row &&
                (row[field.id] !== undefined
                  ? row[field.id]?.split("T")[0] //establezca la fecha que viene en el row
                  : new Date().toISOString().split("T")[0]), //si no tiene un campo en el row, que establezca la fecha del día de hoy por defecto.
            },
            updateInfo: false,
          }));
        } else if (field.type !== "Title" && field.type !== "select") {
          //Tipo input, number, etc

          setNewInfo((prevState) => ({
            ...prevState,
            info: {
              ...prevState.info,
              [field.id]: row[field.id] === undefined ? null : row[field.id]
            },
            updateInfo: false,
          }));
        }
      });
    } catch (e) {
      console.log(e);
    }


  }, [individualFormModal, fields]);

  //Efecto para en la vista de aprobaciones, para que al arrancar se modifique el formato de número 
  // de teléfono de la siguiente forma por ejemplo: +50686878889 => 86878889
  useEffect(() => {
    if (view === "approvals" && newInfo.updateInfo == false && Object.keys(newInfo?.info).length > 0) {
      let fieldsPhone = fields.filter(field => field.type == "phone");

      let newInfoE = { ...newInfo.info };

      fieldsPhone.map(phone => {
        let countryCode = newInfoE[phone.idCountryPhone].label;
        let expectedPhoneDigits = getExpectedPhoneDigitsByCountry(countryCode)

        let currentPhone = newInfoE[phone.id];
        currentPhone = currentPhone.substring(currentPhone.length - expectedPhoneDigits, currentPhone.length);

        newInfoE = { ...newInfoE, [phone.id]: currentPhone }

      })

      setNewInfo({ ...newInfo, info: newInfoE })

    }
  }, [individualFormModal, fields])


  //#endregion

  //#region Métodos para la gestión de los campos del form, como que des/aparezcan campos, el tipo de label, handles de campos, botones según excepciones.

  //Método para validar las excepciones establecidas en el campo valueHaveException para que aparezca o no aparezca un campo de generalStates, más que todo para clientes y proveedores.
  const validateException = (field) => {
    //debugger;
    let result = field.valueHaveException?.includes(
      parseInt(individualFormModal[field.idHaveException])
    );

    let result2 = field.valueHaveException?.includes(
      parseInt(newInfo?.info[field.idHaveException]?.value)
    );

    // if(!result && !result)
    // {
    //   console.log("No se habilita")

    //   setNewInfo((prevState) => ({
    //     ...prevState,
    //     info: { ...prevState.info, [field.id]: null },
    //   }));

    // }

    return result || result2;


    // return field.valueHaveException.includes(
    //   parseInt(individualFormModal[field.idHaveException])
    // );
  };

  //Para los campos de número IBAN, se necesita que su label cambie dependiendo del tipo de país seleccionado en información general.
  const getLabelForBankingField = (field) => {
    //debugger;
    let result = field.label.filter((item) =>
      item.idCompanyCode?.includes(parseInt(individualFormModal.companyCode))
    );

    if (result === undefined || result.length === 0) {
      result = field.label.filter((item) =>
        item.idCompanyCode?.includes("default")
      );
    }

    return result;
  };

  //Función diseñada para averiguar si hay otro campo searchField, esto para que solo aparezca una vez el botón de lupa de buscar el campo llave.
  const validateIfOtherBtnSearchField = (id) => {
    //materialId, searchField
    const result = fields.filter((field) => field.type === "searchField");
    try {
      return (
        result[0]?.id !== id || //Si hay dos campos searchfield solo retorne en true el segundo
        result.length < 2
      ); //Pero si hay un solo campo searchField imprimalo
    } catch (e) {
      return false;
    }
  };

  //Método clave para filtrar las opciones de un dropdown cuando depende de la selección de otro dropdown, se filtra por
  //el id del dropdown, o por un id de las props de individual form.
  const generateFieldsDependsOther = (field) => {
    //debugger;
    //Extrae el campo en formFields de general state en base al field que puede venir tipo string o sería un json igual al de formfiels
    let field2 = formFields[formNameFormat]?.formData.filter(
      (value) => value.id === field || value === field
    );

    //Extrae los campos de opciones en base a sus reglas
    const fields =
      options[field2[0].options] &&
      options[field2[0].options].map((opt) => {
        if (
          opt[field2[0].idDropdownDepends] ===
          parseInt(newInfo.info[field2[0].idDropdownDepends]?.value) || //Para buscar internamente en NewInfo
          opt[field2[0].idDropdownDepends] ===
          parseInt(individualFormModal[field2[0].idDropdownDepends])
        ) {
          //Para buscar en los props de individualFormModal
          return <option id={opt.value}>{opt.label}</option>;
        }
      });

    //Diseñado para verificar si el field tiene más de una opción y que sea util para habilitar o deshabilitar campos
    const enableFields = fields?.some((option) => option !== undefined);

    return { fields, enableFields };
  };

  //Método diseñado para los campos hijos que dependen del valor de otro campo, que al cambiar el campo padre, borre el valor de los campos hijos en newInfo
  const modifyValueChildField = (idFather) => {
    //debugger;
    let fieldToEmpty = fields.filter(
      (field) => field.idDropdownDepends === idFather
    );
    if (fieldToEmpty.length > 0) {
      try {
        //En este case tiene más de un hijo por limpiar
        fieldToEmpty.map((fieldE) => {
          setNewInfo((prevState) => ({
            ...prevState,
            info: { ...prevState.info, [fieldE.id]: null },
          }));

          //Se aplica recursividad del método en caso que hayan más hijos.
          modifyValueChildField(fieldE.id);
        });
      } catch (e) {
        //En este case tiene solo un hijo por limpiar
        fieldToEmpty = fieldToEmpty[0];
        setNewInfo((prevState) => ({
          ...prevState,
          info: { ...prevState.info, [fieldToEmpty.id]: null },
        }));

        //Se aplica recursividad del método en caso que hayan más hijos.
        modifyValueChildField(fieldToEmpty.id);
      }
    }
  };

  // Limpiar el typeahead.
  const handleResetValue = (event, ref, fieldId) => {
    event.preventDefault();
    ref.current.clear();
    //setNewInfo({ "id": 0, "name": "", "UserID": 0, "RoleId": employeeResponsibleId });

    setNewInfo((prevState) => ({
      ...prevState,
      info: { ...prevState.info, [fieldId]: "" },
    }));
  };

  //Establecer el colaborador por defecto en el Typeahead según sea el caso
  const getUser = (id) => {
    //debugger;
    let result = "";
    let compare = "";
    //si el empleado llega como NULL en aprobaciones se pone el id de TeleSales
    //no aplica para Inicio - Nueva Gestion
    if (newInfo?.info[id] === null && view !== "newRequest") {
      compare = "0000";
    }
    else if (newInfo?.info[id] !== undefined) {
      if (newInfo.info[id]?.id !== undefined) {
        compare = newInfo.info[id].id;
      } else {
        compare = newInfo.info[id];
      }
    }

    else {
      compare = row[id];
    }

    try {
      result = employeesOptions?.filter(
        (employee) => employee.id === compare /*row[id]*/
      )[0];
    } catch (e) { }
    return result ? result : "";
  };

  //#endregion

  //#region Métodos utiles como convertir a IBAN, verificación de un campo llave
  //Método para convertir una cuenta corriente a iba, partiendo del field donde está alojado el número.
  const handleConvertIBAN = (field) => {
    let currentAccountNumber = newInfo.info[field.id];
    //console.log(currentAccountNumber)

    let conversion = convertToIban(currentAccountNumber);
    //console.log(conversion);

    if (conversion.result === true) {
      //Actualizar el estado
      setNewInfo((prevState) => ({
        ...prevState,
        info: { ...prevState.info, [field.id]: conversion.ibanNumber },
      }));

      showToast(conversion.response, conversion.typeToast);
      addBorderField(field.id, "is-valid");
    } else {
      //El número es inválido
      showToast(conversion.response, conversion.typeToast);
      addBorderField(field.id, "is-invalid");
    }
  };

  //Consulta un API para verificar si el campo llave en SAP existe.
  const handleExistMasterDataByKey = () => {
    //poner el spinner del campo llave en true
    setVerifyFieldKeyIsValid((prev) => ({ ...prev, searchingSpinner: true }));

    //debugger;
    //En generalStates trae los fields que tengan namePropFuctionModule, esto quiere decir que es un campo llave, y lo moldea para adaptarlo a los parametros del API
    const parameters = fields
      .filter(
        (
          field //fitro
        ) => field.namePropFuctionModule !== undefined
      )
      .map(
        (
          field //Moldeo de información
        ) => ({
          namePropFuctionModule: field.namePropFuctionModule,
          value: newInfo.info[field.id],
        })
      );

    // console.log(parameters)

    //verificar si el usuario lleno el campo llave
    let isNull = false;
    let indexNull = 0;
    parameters.map((param, key) => {
      if (param.value === null) {
        isNull = true;
        indexNull = key;
      }

    });
    if (isNull) {
      let label = formFields[formName].formData.filter((x) => x.type === "searchField");
      if (formNameFormat === "equipos") {
        label = label[indexNull].label;
      }
      else {
        label = label[0].label;
      }
      console.log(label)
      showToast(`Por favor digite el ${label}`, "warning");
      setVerifyFieldKeyIsValid((prev) => ({
        ...prev,
        searchingSpinner: false,
      }));
      return;
    }

    if (formNameFormat === "equipos") {
      const existMasterDataByKeyEq = async (parameters, formName) => {
        await existMasterDataByKeyExcpEquipments(parameters, formName);
      };
      existMasterDataByKeyEq(parameters, "materiales");

    } else { //Los que no son equipos
      const existMasterDataByKey = async (parameters) => {
        await existMasterDataByKeyM(parameters);
      };
      existMasterDataByKey(parameters);

    }



  };

  //Diseñado para la excepción de materiales, donde ejecuta primero la consulta de si el material existe, y posterior existMasterDataByKeyM
  const existMasterDataByKeyExcpEquipments = async (parameters, formName) => {
    dispatch(
      existMasterDataByKey({ dataType: formName, parameters: [parameters[0]] })
    ).then((resp) => {


      const { payload } = resp;

      if (payload.status === 200) {
        console.log(payload)
        let payloadA = payload.data.payload

        if (payloadA.exist === true) {
          //showToast("El material existe en SAP!", "success");
          //setDisableFields(false);

          const existMasterDataByKey = async (parameters) => {
            await existMasterDataByKeyM(parameters);
          };
          existMasterDataByKey(parameters);


        } else {
          setDisableFields(true);
          setVerifyFieldKeyIsValid((prev) => ({
            ...prev,
            searchingSpinner: false,
          }));

          showToast("El material no existe en SAP", "warning");
        }
      } else {
        setVerifyFieldKeyIsValid((prev) => ({
          ...prev,
          searchingSpinner: false,
        }));
        showToast("No se pudo establecer una conexión con SAP.", "warning");

      }
    });
  };

  //Verifica si los keys existen en SAP.
  const existMasterDataByKeyM = async (parameters) => {
    // console.log(parameters);

    dispatch(
      existMasterDataByKey({ dataType: formName, parameters: parameters })
    ).then((resp) => {
      setVerifyFieldKeyIsValid((prev) => ({
        ...prev,
        searchingSpinner: false,
      }));

      const { payload } = resp;

      if (payload.status === 200) {
        console.log(payload)

        let exist = payload.data.payload.exist;

        //#region Validación para habilitar o deshabilitar campos


        if (parseInt(typeOfManagementId) === 1 /*Creación */) {
          if (exist === false) {
            //Exito
            //showToast("No existe en SAP!", "success");
            setDisableFields(false);

            if (formNameFormat === "equipos") {
              showToast("El material existe y la serie no existe en SAP!", "success");

            } else {
              showToast("No existe en SAP!", "success");
            }
          } else {
            //Fallo
            setDisableFields(true);
            if (formNameFormat === "equipos") {
              let idEquipment = payload.data.payload.response.EQ[0].EQUIPO.replace(/^(0+)/g, '');
              showToast(`Ya existe con el ID: ${idEquipment}`, "warning");

            } else if (formNameFormat == "clientes") {
              let client = payload.data.payload.response.BP[0]
              MySwal.fire({
                title: `Atención`,
                type: "warning",
                html: `    
                <h2><strong></strong>El cliente ya existe</strong></h2>
                <ul style="list-style-type:none; padding-left: 0;">
                <li><strong>Nombre:</strong> ${client.NOMBRE}</li>
                <li><strong>Número cliente:</strong> ${client.CLIENTE}</li>
                  <li><strong>NIF/NRC:</strong> ${client.CEDULA}</li>
                </ul>`,
                confirmButtonText: `Entendido`,
                confirmButtonColor: `#fb6340`,
              })
            } else {
              showToast("Existe en SAP!", "warning");
            }
          }
        } else if (parseInt(typeOfManagementId) === 2 /*Modificación */) {
          if (exist === true) {
            //exito
            if (formNameFormat === "equipos") {
              let idEquipment = payload.data.payload.response.EQ[0].EQUIPO.replace(/^(0+)/g, '');
              showToast(`Ya existe con el ID: ${idEquipment}`, "success");

            } else {
              showToast("Existe en SAP!", "success");
            }

            //console.log("Exito")
            setDisableFields(false);
          } else {
            //Fallo
            setDisableFields(true);
            //showToast("No existe en SAP!", "warning");

            if (formNameFormat === "equipos") {
              showToast("El material existe y la serie no existe en SAP!", "warning");

            } else {
              showToast("No existe en SAP!", "warning");
            }
          }
        }

        //#endregion

      } else {
        setVerifyFieldKeyIsValid((prev) => ({
          ...prev,
          searchingSpinner: false,
        }));
        showToast("No se pudo establecer una conexión con SAP.", "warning");

      }
    });
  };



  //#endregion

  //#region Métodos handles de tipo change, submit y close del modal

  //Handle para cerrar el modal.
  const handleCloseModal = () => {
    let newState = stateModalsIndvForms;
    if (view === "newRequest" && !isEdit) {
      newState = stateModalsIndvForms.filter(
        (modal, indexModal) => index !== indexModal
      );
    } else {
      newState = stateModalsIndvForms.map((modal) =>
        modal.row === row
          ? {
            ...modal,
            showModal: false,
          }
          : modal
      );
    }
    setStateModalsIndvForms(newState);
  };

  //handle para aprobar o rechazar la solicitud.
  const handleSubmit = () => {
    // debugger;

    if (validateForms() > 0) {
      showToast(
        "Por favor, debe llenar los campos obligatorios (*) ",
        "warning"
      );
      return;
    }

    if (disableFields === true && view !== "approvals") {
      showToast(
        "Por favor, debe realizar la consulta en el campo llave antes de realizar la solicitud.",
        "warning"
      );
      return;
    }


    if (validateBankFields() > 0 && formNameFormat === "proveedores" && individualFormModal.companyCode === 1) {
      showToast(
        "Los campos bancarios deben tener al menos 20 caracteres, además si no es obligatorio puede dejarlo en blanco.",
        "warning"
      );
      return;
    }

    if (!validatePhoneNumbers()) {
      return;
    }

    let newState = "";

    if (view === "newRequest") {
      newState = stateModalsIndvForms.map((modal, indexModal) =>
        index === indexModal
          ? {
            ...modal,
            showModal: false,
            newInfo: newInfo, //Al cerrar se guarda la nueva información capturada en el modal.
          }
          : modal
      );
    } else {
      newState = stateModalsIndvForms.map((modal) =>
        modal.row === row
          ? {
            ...modal,
            showModal: false,
            newInfo: newInfo, //Al cerrar se guarda la nueva información capturada en el modal.
          }
          : modal
      );
    }





    setStateModalsIndvForms(newState);

    showToast("Información guardada éxitosamente", "success");
  };

  //Asigna los valores de cada campo del form en newInfo, y a la vez asigna los campos option a los demás dropdowns dependiendo de la selección.
  const handleChangeInfo = (type, id, value, indexId, defaultValueLabel, idCountryPhone) => {
    // console.log("type", type)
    // console.log("id", id)
    // console.log("value", value)
    // console.log("indexId", indexId)
    // console.log("idCountryPhone", idCountryPhone)

    if (type == "phone") {
      // debugger


      if (value && !newInfo?.info[idCountryPhone].label
      ) {
        showToast(`Elija un país antes de digitar el teléfono.`, "warning");
        setNewInfo((prevState) => ({
          ...prevState,
          info: {
            ...prevState.info,
            [id]: ""
          },
          updateInfo: false,
        }));
      } else {
        let country = newInfo?.info[idCountryPhone].label
        let digitsExpected = getExpectedPhoneDigitsByCountry(country);

        if (value.replace(/\D/g, "").length > digitsExpected) {
          showToast(`La cantidad de dígitos de teléfono para el país ${country} debe ser ${digitsExpected}. No debe incluir el prefijo.`, "warning");

          setNewInfo((prevState) => ({
            ...prevState
          }));

        } else {
          setNewInfo((prevState) => ({
            ...prevState,
            info: {
              ...prevState.info,
              [id]: value.replace(/\D/g, "")
            },
            updateInfo: true,
          }));
        }

      }





    } else if (
      type === "select" &&
      (disableFields === false || view === "approvals")
    ) {
      // console.log("fieldsselect")

      let fieldToClean = fields.filter(field => field.idCountryPhone == id)
      if (fieldToClean[0]) //Limpiar el número de teléfono del country.
      {
        setNewInfo((prevState) => ({
          ...prevState,
          info: {
            ...prevState.info,
            [id]: { value: parseInt(indexId), label: value },
            [fieldToClean[0].id]: "" //Limpia el número de teléfono del country.
          },
          updateInfo: true,
        }));
      } else {
        setNewInfo((prevState) => ({
          ...prevState,
          info: { ...prevState.info, [id]: { value: parseInt(indexId), label: value } },
          updateInfo: true,
        }));
      }


      modifyValueChildField(id);
    } else if (type === "searchField") {
      view !== "approvals" && setDisableFields(true); //Es para cuando escriban en el campo llave se deshabilite por defecto
      setNewInfo((prevState) => ({
        ...prevState,
        info: { ...prevState.info, [id]: value },
        updateInfo: true,
      }));
    } else {
      // console.log("fieldslast")
      setNewInfo((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          [id]:
            type == "number" ? //En caso que sea número solo admita números.
            value.replace(/[^\d,]|(?<=,.*),/g, "")  // Permite números y una sola coma decimal
            :
              value
        },
        updateInfo: true,
      }));
    }
  };

  //#endregion

  //#region Métodos para notificaciones

  //Declaración de Toast para la librería Toast
  const Toast = MySwal.mixin({
    toast: true,
    position: "bottom-right",
    showConfirmButton: true,
    timer: 8000,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });



  //Método para hacer el llamado a notificación Toast de una manera más corta.
  const showToast = (message, type) => {
    Toast.fire({
      title: "Atención",
      html: message,
      type: type,
    });
  };

  //#endregion

  //#region Verificar si un campo es vacío y agregar bordes

  //Método para agregar borde amarillo o verde a un field
  const addBorderField = (idField, className) => {
    //debugger;

    //#region Valida los campos input y selects normales

    const forms2 = document.querySelectorAll(
      ".formInfo input,Select, textarea, input, number"
    );

    Array.from(forms2).forEach((input) => {
      console.log(input);
      if (input.id === idField) {
        input.classList.remove("is-invalid");
        input.classList.add(className);
      }
    });

    //#endregion
  };

  //Método para validar si los campos están de todos los forms están vacíos o no.
  const validateForms = () => {
    //debugger;
    let cont = 0;
    //Valida los Typeahead
    const forms = document.getElementsByClassName(
      "rbt-input-main form-control rbt-input"
    );

    Array.from(forms).forEach((input) => {
      if (input.defaultValue === "") {
        input.setAttribute("style", "border-color:#fb6340;");
        cont++;
      } else {
        input.removeAttribute("style");
      }
    });

    //Valida los campos input y selects normales

    const forms2 = document.querySelectorAll(
      ".formInfo input,Select, textarea, input, number"
    );

    Array.from(forms2).forEach((input) => {
      //Valide todos menos los LDRS.

      if (input.required === true) {
        //console.log(input);
        if (input.value === "") {
          //console.log(input)
          input.classList.add("is-invalid");
          cont++;
        } else {
          input.classList.remove("is-invalid");
        }
      }
    });

    return cont;
  };


  //Método para validar si los campos bancarios de proveedores cumple con la 17 o diferente de 0 caracteres.
  const validateBankFields = () => {
    //debugger;
    let cont = 0;

    //Valida los campos input y selects normales

    const forms2 = document.querySelectorAll(
      ".formInfo input,Select, textarea, input, number"
    );

    Array.from(forms2).forEach((input) => {

      if (input.id === "localCurrencyAccount" || input.id === "usdCurrencyAccount" && input.value.length > 0) {

        input.classList.remove("is-invalid");
        if (input.value.length < 20) {
          input.classList.add("is-invalid");
          cont++;
        }

      }
    });

    return cont;
  };

  //Método para obtener la cantidad de digitos que debe tener un número telefónico en base a su país.
  const getExpectedPhoneDigitsByCountry = (country) => {
    country = country.split(" - ")[0].trim()
    // Obtener un ejemplo de número telefónico para Costa Rica
    const exampleNumber = getExampleNumber(country, examples);

    if (exampleNumber) {
      const phoneNumberLength = exampleNumber.nationalNumber.length;
      // console.log('Longitud esperada del número de teléfono para ' + country + ":", phoneNumberLength);
      return phoneNumberLength;
    } else {
      console.log('No se pudo obtener un ejemplo de número de teléfono para' + country);
      return 0;
    }

  }

  //Validar los números de teléfono si tienen la cantidad correcta de dígitos.
  const validatePhoneNumbers = () => {
    // debugger;

    let phoneNumbers = fields.filter(field => field.type == "phone")

    if (phoneNumbers.length == 0) {
      return true
    } else { //Existen números de teléfono por validar.

      let contFailed = 0;

      phoneNumbers.map(fieldPhone => {
        let country = newInfo?.info[fieldPhone.idCountryPhone]?.label;

        //Cantidad de dígitos esperados.
        const quantityExpected = getExpectedPhoneDigitsByCountry(country);

        //Cantidad actuales.
        const quantityField = newInfo.info[fieldPhone.id].length;

        if (quantityExpected != quantityField) {
          showToast(`El número telefónico según el país ${country} debe tener ${quantityExpected} dígitos y actualmente tiene únicamente ${quantityField} dígitos.`, "warning")
          contFailed++;
        }
      })

      return contFailed == 0;

    }

  }
  //#endregion


  //#region consoles.log

  useEffect(() => {
    // console.log(newInfo);
  }, [newInfo]);

  useEffect(() => {
    // console.log("fields", fields);
  }, [fields]);

  //#endregion

  return (
    <>
      <Modal
        size="sm"
        scrollable
        isOpen={
          individualFormModal.showModal
        }
        unmountOnClose={stateDetail.unmountOnClose}
        toggle={handleCloseModal}
        backdrop="static"
      >
        <Spin size="large" spinning={false}>
          <ModalBody
            className="mt--4 "
            style={
              {
                // overflowY: 'auto'
              }
            }
          >
            <Row className="align-items-center">
              {fields?.map((field, key) => (
                <>
                  {field.type === "Title" ? ( //titulo centrado
                    <Col xs={12} className="mt-4 mb--3" key={key}>
                      <h3>{field.label}</h3>
                      <hr
                        width="100%"
                        xs="12"
                        className="mt-1"
                      />
                    </Col>
                  ) : field.type === "subtitle" ? ( //subtitulo letra azul centrado.
                    <Col xs={12} className="" key={key}>
                      <FormGroup key={key}>
                        <Row
                          className="mb--2"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Col
                            key={key}
                            sm="11"
                            className="mt-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <label className="form-control-label" id="subtitle">
                              {field.label}
                            </label>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  ) : field.type === "select" &&
                    field.haveException === true &&
                    field.optionsDependsOtherDropdown === true   //sus opciones dependen de otro dropdown por tanto se filtran en base al dato seleccionado anteriormente

                    ? ( //Tiene una excepción en base a lo seleccionado en el formulario general anterior.
                      validateException(field) && (

                        //Sus opciones dependen de la selección de otro dropdown
                        generateFieldsDependsOther(field).enableFields && (
                          <Col xs={12} className="" key={key}>
                            <FormGroup key={key}>
                              <label className="form-control-label">
                                {field.label}
                              </label>
                              <Input
                                key={field.id}
                                id={field.id}
                                required={field.required}
                                className="basic-multi-select text-dark"
                                type={field.type}
                                placeholder={field.placeholder}
                                disabled={
                                  field.disabled ||
                                  (disableFields === true &&
                                    view !== "approvals") ||
                                  view === "requests" ||
                                  !generateFieldsDependsOther(field).enableFields
                                }
                                value={newInfo.info[field.id]?.label}
                                onChange={(e) =>
                                  handleChangeInfo(
                                    field.type,
                                    field.id,
                                    e.target.children[e.target.selectedIndex].value,
                                    e.target.children[e.target.selectedIndex].id,
                                    field.defaultValueLabel
                                  )
                                }
                              >
                                <option id=""></option>
                                {generateFieldsDependsOther(field)?.fields}
                              </Input>
                              <div className="invalid-feedback">
                                Este campo no puede estar vacío
                              </div>
                            </FormGroup>
                          </Col>
                        )



                      )
                    ) : field.type === "input" &&
                      field.haveException === true ? ( //Tiene una excepción en base a lo seleccionado en el formulario general anterior.
                  /*field.valueHaveException.includes(parseInt( individualFormModal[field.idHaveException] ))*/ validateException(
                        field
                      ) && (
                        <Col xs={12} className="" key={key}>
                          <FormGroup key={key}>
                            <label className="form-control-label">
                              {field.label}
                            </label>
                            <Input
                              key={field.id}
                              id={field.id}
                              required={field.required}
                              className="basic-multi-select text-dark"
                              type={field.type}
                              placeholder={field.placeholder}
                              disabled={
                                field.disabled ||
                                (disableFields === true && view !== "approvals") ||
                                view === "requests"
                              }
                              value={newInfo?.info[field.id]}
                              onChange={(e) =>
                                handleChangeInfo(
                                  field.type,
                                  field.id,
                                  e.target.value
                                )
                              }
                            ></Input>
                            <div className="invalid-feedback">
                              Este campo no puede estar vacío
                            </div>
                          </FormGroup>
                        </Col>
                      )
                    )
                      : field.type === "select" &&
                        field.haveException === true &&
                        field.optionsDependsOtherDropdown === false   //sus opciones dependen de otro dropdown por tanto se filtran en base al dato seleccionado anteriormente

                        ? ( //Tiene una excepción en base a lo seleccionado en el formulario general anterior.
                          validateException(field) && (

                            //Sus opciones dependen de la selección de otro dropdown
                            true && (
                              <Col xs={12} className="" key={key}>
                                <FormGroup key={key}>
                                  <label className="form-control-label">
                                    {field.label}
                                  </label>
                                  <Input
                                    key={field.id}
                                    id={field.id}
                                    required={field.required}
                                    className="basic-multi-select text-dark"
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    disabled={
                                      field.disabled ||
                                      (disableFields === true &&
                                        view !== "approvals") ||
                                      view === "requests"
                                    }
                                    value={newInfo.info[field.id]?.label}
                                    onChange={(e) =>
                                      handleChangeInfo(
                                        field.type,
                                        field.id,
                                        e.target.children[e.target.selectedIndex].value,
                                        e.target.children[e.target.selectedIndex].id,
                                        field.defaultValueLabel
                                      )
                                    }
                                  >
                                    <option id=""></option>
                                    {options[field.options] &&
                                      options[field.options].map((value) => {
                                        return (
                                          <option id={value.value}>{value.label}</option>
                                        );
                                      })}
                                  </Input>
                                  <div className="invalid-feedback">
                                    Este campo no puede estar vacío
                                  </div>
                                </FormGroup>
                              </Col>
                            )



                          )
                        )

                        : field.type === "input" &&
                          field.dependsAnotherDropdown === true ? (
                          !generateFieldsDependsOther(field.idDropdownDepends)
                            .enableFields && (
                            <Col xs={12} className="" key={key}>
                              <FormGroup key={key}>
                                <label className="form-control-label">
                                  {field.label}
                                </label>
                                <Input
                                  key={field.id}
                                  id={field.id}
                                  required={field.required}
                                  className="basic-multi-select text-dark"
                                  type={field.type}
                                  placeholder={field.placeholder}
                                  disabled={
                                    field.disabled ||
                                    (disableFields === true &&
                                      view !== "approvals") ||
                                    view === "requests"
                                  }
                                  value={newInfo?.info[field.id]}
                                  onChange={(e) =>
                                    handleChangeInfo(
                                      field.type,
                                      field.id,
                                      e.target.value
                                    )
                                  }
                                ></Input>
                                <div className="invalid-feedback">
                                  Este campo no puede estar vacío
                                </div>
                              </FormGroup>
                            </Col>
                          )
                        ) : field.type === "input" ||
                          field.type === "number" ||
                          field.type === "textarea" ? (
                          <Col xs={12} className="" key={key}>
                            <FormGroup key={key}>
                              <label className="form-control-label">
                                {field.label}
                              </label>
                              <Input
                                key={field.id}
                                id={field.id}
                                //name={"LDR"}
                                required={field.required}
                                className="basic-multi-select text-dark"
                                type={field.type == "number" ? "input" : field.type}
                                placeholder={field.placeholder}
                                disabled={
                                  field.disabled ||
                                  (disableFields === true && view !== "approvals") ||
                                  view === "requests"
                                }
                                value={newInfo?.info[field.id]}
                                onChange={(e) =>
                                  handleChangeInfo(
                                    field.type,
                                    field.id,
                                    e.target.value
                                  )
                                }
                              ></Input>
                              <div className="invalid-feedback">
                                Este campo no puede estar vacío
                              </div>
                            </FormGroup>
                          </Col>


                        ) :
                          field.type === "phone" ?
                            (
                              (
                                <Col xs={12} className="" key={key}>
                                  <FormGroup key={key}>
                                    <label className="form-control-label">
                                      {field.label}
                                    </label>
                                    <Input
                                      key={field.id}
                                      id={field.id}
                                      //name={"LDR"}
                                      required={field.required}
                                      className="basic-multi-select text-dark"
                                      type={field.type == "number" ? "input" : field.type}
                                      placeholder={field.placeholder}
                                      disabled={
                                        field.disabled ||
                                        (disableFields === true && view !== "approvals") ||
                                        view === "requests"
                                      }
                                      value={newInfo?.info[field.id]}
                                      onChange={(e) =>
                                        handleChangeInfo(
                                          field.type,
                                          field.id,
                                          e.target.value,
                                          null,
                                          null,
                                          field.idCountryPhone
                                        )
                                      }
                                    ></Input>
                                    <div className="invalid-feedback">
                                      Este campo no puede estar vacío
                                    </div>
                                  </FormGroup>
                                </Col>
                              )
                            )
                            : field.type === "typeahead" ? (
                              <Col xs={12} className="" key={key}>
                                <FormGroup>
                                  <label className="form-control-label">
                                    {field.label}
                                  </label>
                                  <InputGroup>
                                    <Typeahead
                                      id={field.id}
                                      style={{ width: "84%" }}
                                      labelKey={(employeesOptions) =>
                                        `${employeesOptions.name}`
                                      }
                                      minLength={0}
                                      required={row.required}
                                      disabled={
                                        field.disabled ||
                                        (disableFields === true &&
                                          view !== "approvals") ||
                                        view === "requests"
                                      }
                                      ref={ref}
                                      options={employeesOptions && employeesOptions}
                                      defaultSelected={getUser(field.id) !== "" ? [getUser(field.id)] : ""}
                                      onChange={(e) =>
                                        handleChangeInfo(field.type, field.id, e[0])
                                      }
                                      renderMenuItemChildren={(value) => (
                                        <div>
                                          {value.name}
                                          <div>{<small>Id: {value.UserID}</small>}</div>
                                        </div>
                                      )}
                                    />

                                    {(disableFields === false ||
                                      view === "approvals") && (
                                        <Button
                                          id="btnDelete"
                                          color="danger"
                                          className="btn-icon border "
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                          onClick={(e) =>
                                            handleResetValue(e, ref, field.id)
                                          }
                                        >
                                          <span className="btn-inner--icon mr-">
                                            <i className="fa fa-times" />
                                          </span>
                                        </Button>
                                      )}
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            ) : field.type === "searchField" ? ( //De tipo nif o id de material etc
                              <Col xs={12} className="" key={key}>
                                <Spin
                                  size="large"
                                  spinning={verifyFieldKeyIsValid.searchingSpinner}
                                >
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-username"
                                    >
                                      {field.label}
                                    </label>
                                    <InputGroup>
                                      <Input
                                        key={field.id}
                                        id={field.id}
                                        required={field.required}
                                        className="basic-multi-select text-dark"
                                        name="keyField"
                                        type={field.elementType}
                                        placeholder={field.placeholder}
                                        disabled={view === "requests"}
                                        value={newInfo?.info[field.id]}
                                        onChange={(e) =>
                                          handleChangeInfo(
                                            field.type,
                                            field.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                      <div className="invalid-feedback">
                                        Este campo no puede estar vacio
                                      </div>

                                      {validateIfOtherBtnSearchField(field.id) && //Hay forms que tienen 2 campos llave, entonces esto es para que aparezca solo un botón de lupa en alguno de esos dos
                                        view !== "requests" && (
                                          <Button
                                            color="primary"
                                            id="searchButton"
                                            outline
                                            disabled={view === "requests"}
                                            className=""
                                            onClick={(e) => handleExistMasterDataByKey()}
                                          >
                                            <i className="fas fa-search" />
                                          </Button>
                                        )}
                                    </InputGroup>
                                  </FormGroup>
                                </Spin>
                              </Col>
                            ) : field.type === "bankingFields" ? ( //De tipo cuenta bancaria
                              (field.notShowWithIdsCompanyCode.includes("Nothing") || //En caso que quiera que se muestre con cualquier company code
                                !field.notShowWithIdsCompanyCode.includes(
                                  parseInt(individualFormModal.companyCode)
                                )) && (
                                <Col xs={12} className="" key={key}>
                                  <Spin
                                    size="large"
                                    spinning={false}
                                  >
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                      >
                                        {
                                          getLabelForBankingField(
                                            field
                                          )[0]?.name
                                        }
                                      </label>
                                      <InputGroup>
                                        {view !== "requests" &&
                                          getLabelForBankingField(field)[0]
                                            ?.showButtonSearchIban && (
                                            <Button
                                              title="Convertir cuenta SINPE a cuenta IBAN"
                                              id="ibanButton"
                                              outline
                                              disabled={view === "requests"}
                                              className=""
                                              onClick={(e) => handleConvertIBAN(field)}
                                              color="secondary"
                                              type="button"
                                            >
                                              <span>CR</span>
                                            </Button>
                                          )}

                                        <Input
                                          key={field.id}
                                          id={field.id}
                                          required={field.required}
                                          className="basic-multi-select text-dark"
                                          name="keyField"
                                          type={field.elementType}
                                          placeholder={field.placeholder}
                                          disabled={
                                            field.disabled ||
                                            (disableFields === true &&
                                              view !== "approvals") ||
                                            view === "requests"
                                          }
                                          value={newInfo?.info[field.id]}
                                          onChange={(e) =>
                                            handleChangeInfo(
                                              field.type,
                                              field.id,
                                              e.target.value
                                            )
                                          }
                                        />
                                        <div className="invalid-feedback"></div>
                                        <div className="valid-feedback"></div>
                                      </InputGroup>
                                    </FormGroup>
                                  </Spin>
                                </Col>
                              )
                            ) : field.type === "date" ? (
                              <Col xs="12" sm={row.colWidth} key={key}>
                                <FormGroup key={key}>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                  >
                                    {`${field.label}`}
                                  </label>
                                  <Input
                                    key={key}
                                    id={field.id}
                                    name={field.id}
                                    required={field.required}
                                    className="basic-multi-select text-dark"
                                    disabled={
                                      field.disabled ||
                                      (disableFields === true && view !== "approvals") ||
                                      view === "requests"
                                    }
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    value={newInfo?.info[field.id]}
                                    onChange={(e) =>
                                      handleChangeInfo(
                                        field.type,
                                        field.id,
                                        e.target.value,
                                        e.target.id,
                                        field.defaultValueLabel
                                      )
                                    }
                                  ></Input>
                                  <div className="invalid-feedback">
                                    La fecha no puede estar vacía
                                  </div>
                                </FormGroup>
                              </Col>
                            ) : field.type === "select" &&
                              field.optionsDependsOtherDropdown === true ? ( //sus opciones dependen de otro dropdown por tanto se filtran en base al dato seleccionado anteriormente
                              generateFieldsDependsOther(field).enableFields && (
                                <Col xs={12} className="" key={key}>
                                  <FormGroup key={key}>
                                    <label className="form-control-label">
                                      {field.label}
                                    </label>
                                    <Input
                                      key={field.id}
                                      id={field.id}
                                      required={field.required}
                                      className="basic-multi-select text-dark"
                                      type={field.type}
                                      placeholder={field.placeholder}
                                      disabled={
                                        field.disabled ||
                                        (disableFields === true &&
                                          view !== "approvals") ||
                                        view === "requests" ||
                                        !generateFieldsDependsOther(field).enableFields
                                      }
                                      value={newInfo.info[field.id]?.label}
                                      onChange={(e) =>
                                        handleChangeInfo(
                                          field.type,
                                          field.id,
                                          e.target.children[e.target.selectedIndex].value,
                                          e.target.children[e.target.selectedIndex].id,
                                          field.defaultValueLabel
                                        )
                                      }
                                    >
                                      <option id=""></option>
                                      {

                                        generateFieldsDependsOther(field)?.fields
                                      }
                                    </Input>
                                    <div className="invalid-feedback">
                                      Este campo no puede estar vacío
                                    </div>
                                  </FormGroup>
                                </Col>
                              )
                            ) : (
                              <Col xs={12} className="" key={key}>
                                <FormGroup key={key}>
                                  <label className="form-control-label">
                                    {field.label}
                                  </label>
                                  <Input
                                    key={field.id}
                                    id={field.id}
                                    //name={"LDR"}
                                    required={field.required}
                                    className="basic-multi-select text-dark"
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    disabled={
                                      field.disabled ||
                                      (disableFields === true && view !== "approvals") ||
                                      view === "requests"
                                    }
                                    value={
                                      newInfo.info[field.id]
                                        ?.label
                                    }
                                    onChange={(e) =>
                                      handleChangeInfo(
                                        field.type,
                                        field.id,
                                        e.target.children[e.target.selectedIndex].value,
                                        e.target.children[e.target.selectedIndex].id,
                                        field.defaultValueLabel
                                      )
                                    }
                                  >
                                    <option id=""></option>
                                    {options[field.options] &&
                                      options[field.options].map((value) => {
                                        return (
                                          <option id={value.value}>{value.label}</option>
                                        );
                                      })}
                                  </Input>
                                  <div className="invalid-feedback">
                                    Este campo no puede estar vacío
                                  </div>
                                </FormGroup>
                              </Col>
                            )}
                </>
              ))}
            </Row>
          </ModalBody>

          <ModalFooter className="border mt--5">
            <Button
              color="danger"
              type="button"
              onClick={() => {
                handleCloseModal();
              }}
            >
              {view === "requests" ? "Cerrar" : "Descartar"}
            </Button>

            {view !== "requests" && (
              <Button
                color="success"
                type="button"
                disabled={disableFields === true && view !== "approvals"}
                //size="sm"

                onClick={() => {
                  handleSubmit();
                }}
              >
                Guardar
              </Button>
            )}
          </ModalFooter>
        </Spin>
      </Modal>
    </>
  );
};
