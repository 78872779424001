var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();
var d = today.getDate();

const events = [
  {
    id: 1,
    title: "Call with Dave",
    start: new Date(y, m, 1),
    allDay: true,
    className: "bg-red",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 2,
    title: "Lunch meeting",
    start: new Date(y, m, d - 1, 10, 30),
    allDay: true,
    className: "bg-orange",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 3,
    title: "All day conference",
    start: new Date(y, m, d + 7, 12, 0),
    allDay: true,
    className: "bg-green",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 4,
    title: "Meeting with Mary",
    start: new Date(y, m, d - 2),
    allDay: true,
    className: "bg-blue",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 5,
    title: "Winter Hackaton",
    start: new Date(y, m, d + 1, 19, 0),
    allDay: true,
    className: "bg-red",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 6,
    title: "Digital event",
    start: new Date(y, m, 21),
    allDay: true,
    className: "bg-warning",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 7,
    title: "Marketing event",
    start: new Date(y, m, 21),
    allDay: true,
    className: "bg-purple",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 8,
    title: "Dinner with Family",
    start: new Date(y, m, 19),
    allDay: true,
    className: "bg-red",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 9,
    title: "Black Friday",
    start: new Date(y, m, 23),
    allDay: true,
    className: "bg-blue",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  },

  {
    id: 10,
    title: "Cyber Week",
    start: new Date(y, m, 2),
    allDay: true,
    className: "bg-yellow",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
  }
];

const dataNodes = [
  {
    node: "Support Costa Rica",
    tower: "Support",
    idTower: 0,
    id: 50003936
  },
  {
    node: "Support Panama",
    tower: "Support",
    idTower: 0,
    id: 50003937
  },
  {
    node: "Support Dominicana",
    tower: "Support",
    idTower: 0,
    id: 50003938
  },
  {
    node: "Support Honduras",
    tower: "Support",
    idTower: 0,
    id: 50003939
  },
  {
    node: "Support Nicaragua",
    tower: "Support",
    idTower: 0,
    id: 50003940
  },
  {
    node: "Support Guatemala",
    tower: "Support",
    idTower: 0,
    id: 50003941
  },
  {
    node: "Support El Salvador",
    tower: "Support",
    idTower: 0,
    id: 50003942
  },
  {
    node: "Networking Advisor",
    tower: "IT Advisory Services",
    idTower: 1,
    id: 50003945
  },
  {
    node: "System Advisor",
    tower: "IT Advisory Services",
    idTower: 1,
    id: 50005025
  },
  {
    node: "Telecomunicaciones (TSG)",
    tower: "Managed Services",
    idTower: 2,
    id: 50003943
  },
  {
    node: "Servidores (SSG)",
    tower: "Managed Services",
    idTower: 2,
    id: 50003944
  },
  {
    node: "Monitoreo",
    tower: "Managed Services",
    idTower: 2,
    id: 70006079
  },
  {
    node: "Enterprise",
    tower: "Managed Services",
    idTower: 2,
    id: 70005414
  },
  {
    node: "Outsourcing",
    tower: "Managed Services",
    idTower: 2,
    id: 70005415
  },
  {
    node: "Workplace",
    tower: "Managed Services",
    idTower: 2,
    id: 70005413
  },
  {
    node: "Smart Ops",
    tower: "System Management",
    idTower: 3,
    id: 70002476
  },
  {
    node: "Field Manager",
    tower: "Recursos no planificables",
    idTower: 4,
    id: 70006016
  },
  {
    node: "Otros recursos",
    tower: "Recursos no planificables",
    idTower: 4,
    id: 70006017
  }
];

const dataResourses = [
  {
    id: 60000815,
    name: "Freddy Villalobos",
    idNode: 70006016,
    country: "Costa Rica",
    countryCode: "CR"
  },
  {
    id: 60000814,
    name: "Luis Diego Navarro",
    idNode: 70002476,
    country: "Costa Rica",
    countryCode: "CR"
  },
  {
    id: 70000716,
    name: "Jose Juan Jaen",
    idNode: 70005414,
    country: "Panama",
    countryCode: "PA"
  },
  {
    id: 60000877,
    name: "Eliomar Antonio Rodriguez",
    idNode: 50003945,
    country: "Costa Rica",
    countryCode: "CR"
  },
  {
    id: 40000876,
    name: "Nepomuseno Gorgojo",
    idNode: 50003938,
    country: "Republica Dominicana",
    countryCode: "DR"
  },
  {
    id: 40000825,
    name: "Catalina Corjante",
    idNode: 50003938,
    country: "Republica Dominicana",
    countryCode: "DR"
  }
];

const dataTasks = [
  {
    id: 1234,
    idResourse: 60000815,
    name: "Freddy Villalobos",
    task: "Lista de requerimientos",
    startTime: "09:00 AM",
    endTime: "17:00 PM",
    startDay: "Lunes",
    endDay: "Miercoles",
    description: "Se detallan los requerimientos del proyecto",
    assignmentDetail: "Asignacion del Project Manager"
  },
  {
    id: 1935,
    idResourse: 60000815,
    name: "Freddy Villalobos",
    task: "Diseño de prototipos",
    startTime: "09:00 AM",
    endTime: "13:00 PM",
    startDay: "Jueves",
    endDay: "Viernes",
    description: "Diseño de los protitpos del modulo de extras",
    assignmentDetail: "Asignacion del Project Manager"
  },
  {
    id: 1759,
    idResourse: 60000815,
    name: "Freddy Villalobos",
    task: "Desarrollo de frontend",
    startTime: "13:00 PM",
    endTime: "17:00 PM",
    startDay: "Lunes",
    endDay: "Miercoles",
    description: "Desarrollo de los prototipos diseñados",
    assignmentDetail: "Asignacion del Project Manager"
  }
];

const dataExtraHours = [
  {
    id: 1,
    date: "02/02/19",
    user: "LNAVARRO",
    ceo: "JCORRALES",
    preApprover: "JJAEN",
    hours: "2",
    status: "Aprobado",
    sapStatus: "Registrada",
    createdAt: "2019-07-30 11:26:54",
    time: "9:00:00",
    endTime: "11:00:00"
  },
  {
    id: 2,
    date: "05/02/19",
    user: "LNAVARRO",
    ceo: "JCORRALES",
    preApprover: "JJAEN",
    hours: "2",
    status: "Rechazada",
    sapStatus: "N/A",
    createdAt: "2019-07-30 11:26:54",
    time: "9:00:00",
    endTime: "11:00:00"
  },
  {
    id: 3,
    date: "08/02/19",
    user: "LNAVARRO",
    ceo: "JCORRALES",
    preApprover: "JJAEN",
    hours: "2",
    status: "Aprobado",
    sapStatus: "Registrada",
    createdAt: "2019-07-30 11:26:54",
    time: "9:00:00",
    endTime: "11:00:00"
  },
  {
    id: 4,
    date: "12/03/19",
    user: "LNAVARRO",
    ceo: "JCORRALES",
    preApprover: "JJAEN",
    hours: "2",
    status: "Aprobado",
    sapStatus: "Registrada",
    createdAt: "2019-07-30 11:26:54",
    time: "9:00:00",
    endTime: "11:00:00"
  },
  {
    id: 5,
    date: "12/03/19",
    user: "LNAVARRO",
    ceo: "JCORRALES",
    preApprover: "JJAEN",
    hours: "2",
    status: "Aprobado",
    sapStatus: "Registrada",
    createdAt: "2019-07-30 11:26:54",
    time: "9:00:00",
    endTime: "11:00:00"
  },
  {
    id: 6,
    date: "12/03/19",
    user: "LNAVARRO",
    ceo: "JCORRALES",
    preApprover: "JJAEN",
    hours: "2",
    status: "Aprobado",
    sapStatus: "Registrada",
    createdAt: "2019-07-30 11:26:54",
    time: "9:00:00",
    endTime: "11:00:00"
  }
];

export { dataNodes, dataResourses, dataTasks, dataExtraHours, events };
