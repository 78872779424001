// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library that dates formats
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// reactstrap components
import {
  Badge,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Media,
  Nav,
  Navbar,
  NavItem,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

//Report Hours SE Migration
import { motion } from "framer-motion";
import { TbClockEdit } from "react-icons/tb";

moment.locale("es");

class AdminNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
      isClicked: false, //this.props.location.pathname === "/admin/notifications"? false : true ,
      buttonAnimations: {
        rest: { x: 0, rotate: 0 },
        shake: {
          x: [-0.5, 0.5 - 1, 1 - 2, 2, -3, 3, 0],
          rotate: [-0.5, 0.5 - 1, 1 - 2, 2, -3, 3, 0],
          transition: { duration: 2, repeat: Infinity, repeatType: "reverse" },
        },
      },
      messageAnimations: {
        hidden: { opacity: 1, x: 0 },
        visible: { opacity: 0, x: 25 },
      },
      showMessage: true,
    };
  }

  componentWillMount() {
    if (localStorage.getItem("avatar")) {
      this.setState({ avatar: localStorage.getItem("avatar") });
    }
  }

  handleOnClickAnimation = () => {
    this.setState({ isClicked: true });
  };

  render() {
    const {
      theme,
      isCEO,
      isCOEAdmin,
      isSuperAdmin,
      knowEmployee,
      isRecordsAdmin,
      userWithLetters,
      userPositionsAdmin,
      digitalSignatureAdmin,
      extraHoursAdmin,
      conflictInsterestForm,
      criticalPartsAdmin,
      notifications,
      sidenavOpen,
      toggleSidenav,
      onMyProfile,
      onMySignatures,
      onExtraHours,
      onCOEMaintenance,
      onRecordsMaintenance,
      onTargetLetter,
      onUsabilityRecord,
      onPositionAdmin,
      onDigitalSignatureAdmin,
      onExtraHoursAdmin,
      onKnowEmployee,
      onConflictInsterest,
      onCriticalPartsAdmin,
      onSignOut,
      onSelectNotification,
      handleOnAdminBS,
      isBSAdmin,
      handleOnAdminExitInterview,
      handleOnAdminSalaryApprovers,
      isExitInterviewAdmin,
      isSalaryApprovalAdmin,
      onReportHours,
    } = this.props;
    let { avatar } = this.state;
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom nav-height",
            { "navbar-dark gbm-background": theme === "dark" },
            { "navbar-dark bg-gradient-default": theme === "alternative" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              {" "}
              <Nav className="align-items-center ml-md-auto " navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler sidenav-toggler-dark",
                      { active: sidenavOpen }
                    )}
                    onClick={toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>

                <motion.button
                  className="rotating-button"
                  style={{ border: "0px", backgroundColor: "transparent" }}
                  animate={this.state.isClicked ? "rest" : "shake"}
                  whileTap="rest"
                  onClick={() => {
                    this.handleOnClickAnimation();
                    onReportHours();
                  }}
                >
                  <motion.div
                    variants={this.state.buttonAnimations}
                    className="icon-container"
                  >
                    <TbClockEdit color="white" size={20} />
                  </motion.div>
                </motion.button>

                {/* <motion.div
                    className="message-container"
                    initial="hidden"
                    animate="visible"
                    variants={this.state.messageAnimations}
                    transition={{ duration: 1, delay: 200000 }}
                  >
                    <div
                      className="message-bubble"
                      style={{
                        backgroundColor: "#5daf60",
                        color: "#fff",
                        borderRadius: "10px",
                        padding: "2px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="message-content"
                        style={{
                          flex: "1",
                          textAlign: "center",
                          backgroundColor: "#5daf60",
                          userSelect: "none",
                        }}
                      >
                        ¡Registra tus horas aquí!
                      </div>
                    </div>
                  </motion.div> */}

                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" color="" tag="a">
                    <i className="ni ni-bell-55" />
                    {notifications.length > 0 && (
                      <Badge
                        color="info"
                        className="badge-md badge-circle badge-floating border-white"
                      >
                        {notifications.length}
                      </Badge>
                    )}
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right
                  >
                    <div className="px-3 py-3">
                      {notifications.length ? (
                        <h6 className="text-sm text-muted m-0">
                          Tienes{" "}
                          <strong className="text-info">
                            {notifications.length}
                          </strong>{" "}
                          {notifications.length > 1
                            ? "notificaciones"
                            : "notificación"}
                          .
                        </h6>
                      ) : (
                        <h6 className="text-sm text-muted m-0">
                          <strong className="text-info">No</strong> tienes
                          notificaciones.
                        </h6>
                      )}
                    </div>
                    <ListGroup flush>
                      {notifications.map((row, key) => {
                        return (
                          <ListGroupItem
                            className="list-group-item-action"
                            onClick={() => onSelectNotification(row)}
                            tag="a"
                            key={key}
                          >
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="Notificación"
                                  className="avatar rounded-circle"
                                  src={require(`assets/img/theme/${row.type}.png`)}
                                />
                              </Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="mb-0 text-sm">
                                      {row.title}
                                    </h4>
                                  </div>
                                  <div className="text-right text-muted">
                                    <small>
                                      {moment(row.createdAt).format("L")}
                                    </small>
                                  </div>
                                </div>
                                <p className="text-sm mb-0">
                                  {row.description}
                                </p>
                              </div>
                            </Row>
                          </ListGroupItem>
                        );
                      })}
                    </ListGroup>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="..."
                          src={
                            avatar
                              ? avatar
                              : require("assets/img/theme/user.svg")
                          }
                        />
                      </span>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">¡Bienvenido(a)!</h6>
                    </DropdownItem>
                    <DropdownItem
                      // href="#pablo"
                      onClick={onMyProfile}
                    >
                      <i className="ni ni-single-02" />
                      <span>Mi Perfil</span>
                    </DropdownItem>
                    <DropdownItem
                      // href="#pablo"
                      onClick={onMySignatures}
                    >
                      <i className="fas fa-file-signature" />
                      <span>Políticas Firmadas</span>
                    </DropdownItem>
                    {isCOEAdmin && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onCOEMaintenance}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Mantenimientos COE</span>
                      </DropdownItem>
                    )}
                    {isRecordsAdmin && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onRecordsMaintenance}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Mantenimiento Expedientes</span>
                      </DropdownItem>
                    )}
                    {userWithLetters && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onTargetLetter}
                      >
                        <i className="fas fa-envelope-open-text" />
                        <span>Mis Cartas de Objetivos</span>
                      </DropdownItem>
                    )}
                    {isCEO && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onExtraHours}
                      >
                        <i className="fas fa-user-clock" />
                        <span>Horas Extras</span>
                      </DropdownItem>
                    )}
                    {isSuperAdmin && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onUsabilityRecord}
                      >
                        <i className="ni ni-lock-circle-open" />
                        <span>Registro de Usabilidad</span>
                      </DropdownItem>
                    )}
                    {userPositionsAdmin && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onPositionAdmin}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Mantenimiento Posiciones</span>
                      </DropdownItem>
                    )}
                    {digitalSignatureAdmin && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onDigitalSignatureAdmin}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Mantenimiento Accesos Firmas</span>
                      </DropdownItem>
                    )}
                    {extraHoursAdmin && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onExtraHoursAdmin}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Mantenimiento Horas Extras</span>
                      </DropdownItem>
                    )}
                    {knowEmployee && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onKnowEmployee}
                      >
                        <i className="fas fa-address-card" />
                        <span>Conoce a tu Empleado</span>
                      </DropdownItem>
                    )}
                    {conflictInsterestForm && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onConflictInsterest}
                      >
                        <i className="fas fa-address-card" />
                        <span>Conflicto de Intereses</span>
                      </DropdownItem>
                    )}
                    {isBSAdmin === true && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={handleOnAdminBS}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Gestión de Pedidos para inventario</span>
                      </DropdownItem>
                    )}
                    {isExitInterviewAdmin === true && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={handleOnAdminExitInterview}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Gestión de Entrevista de salida</span>
                      </DropdownItem>
                    )}
                    {isSalaryApprovalAdmin === true && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={handleOnAdminSalaryApprovers}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Aprobadores Salariales</span>
                      </DropdownItem>
                    )}
                    {criticalPartsAdmin && (
                      <DropdownItem
                        // href="#pablo"
                        onClick={onCriticalPartsAdmin}
                      >
                        <i className="fas fa-users-cog" />
                        <span>Mantenimiento Junta Técnica</span>
                      </DropdownItem>
                    )}
                    <DropdownItem divider />
                    <DropdownItem
                      // href="#pablo"
                      onClick={onSignOut}
                    >
                      <i className="ni ni-user-run" />
                      <span>Cerrar Sesión</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};

AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light", "alternative"]),
  notifications: PropTypes.array.isRequired,
};

export default AdminNavbar;
