/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";

const MatrixEscalations = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    addUser,
    onEdit,
    onDelete,
  } = props;

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="12" md='7'>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
          <Col xs="12" md='5' style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="btn-round btn-icon"
              color="success"
              onClick={addUser}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-user-plus" />
              </span>
              <span className="btn-inner--text">Agregar Usuario</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {
        rows.length ?
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                {
                  cols.map((row, key) => (<th key={key}>{row.name}</th>))
                }
                <th />
              </tr>
            </thead>
            <tbody className="list">
              {
                rows.map((row, key) => (
                  <tr key={key}>
                    {
                      cols.map((col, key) => (
                        <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                          {
                            (col.key === 'services' || col.key === 'countries') ?
                              row[col.key].map((row) =>
                                <Badge color="info">{row.name}</Badge>
                              )
                              :
                              row[col.key]}
                        </th>
                      ))
                    }
                    <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <>
                        <a
                          className="table-action table-action"
                          href="#"
                          onClick={() => onEdit(row)}
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          className="table-action table-action"
                          href="#"
                          onClick={() => onDelete(row)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                      </>
                    </th>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          :
          <CardBody>
            <div className="pt-4 pb-4">
              <h5 className="h3 title">
                <span className="d-block mb-1 text-center">Sin Usuarios Registrados</span>
                <small className="h3 font-weight-light text-muted d-flex justify-content-center">
                  Actualmente al día de hoy, no existen usuarios registrados en la matriz de notificaciones.
                </small>
              </h5>
            </div>
          </CardBody>
      }
    </Card>
  );
};

MatrixEscalations.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MatrixEscalations;