/*!

=========================================================
*Autopp - LDRItemForm
=========================================================

* Componente LDR que despliega un form según el title enviado por props,
partiendo del mismo consulta al hook useLDRFields cuales son los campos
asociados a ese title y lo renderiza mediante un map.

*Agrega su información a un componente local y posteriormente a la lista 
llamada setldrsList del componente padre LDRMainForm.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Import de librerías propias de React.
import React, { useState, useEffect, useContext } from 'react'
import { Divider } from 'antd';

import { Col, FormGroup, Input, Row, Form } from "reactstrap";
import Select from "react-select";


//Import de contexto para extraer states globales.
import { UserContext } from '../SharedComponents/UserContext';

export const LDRItemForm = ({
  setLdrsList,
  idLDR,
  title,
  localFields,
}) => {

  //#region States Globales
  let {

    setToggleCheck,
    readyToSend,
    LDRfields,
    Toast

  } = useContext(UserContext);

  // Para asignar precargados.
  // ldrsDropdownOptions = localOptions != undefined ? localOptions : ldrsDropdownOptions;


  //#endregion

  //#region Configuración del subtítulo

  const [subtitle, setSubtitle] = useState({ label: " A continuación se solicitan datos del LDR.", width: "35%" });

  //#endregion

  //#region Hook de los campos del formulario
  let [fields, setFields] = useState([])

  const [idLDRNumber, setIdLDRNumber] = useState(0);


  //#endregion

  //#region State con info local del form
  const [newInfo, setNewInfo] = useState("");

  useEffect(() => {

    // debugger;
    //En caso que localFields venga lleno, se utiliza el localFields.
    if (localFields != undefined) {
      fields = localFields;
      setFields(localFields)

    } else {

      let indexLDR = 0;

      //Declarar los fields form
      Object.values(LDRfields.useLDRFields).map((LDR, index) => {

        if (LDR.id == idLDR) {

          fields = { [idLDR]: [...LDR.fields] }
          setFields({ [idLDR]: [...LDR.fields] })

          //Localizar el index del LDR
          indexLDR = index

        }
      })


      //Declarar el idLDRNumber. Ej: el 1 es el de Meetings
      setIdLDRNumber(Object.keys(LDRfields.useLDRFields)[indexLDR])



    }

  }, [localFields])


  useEffect(() => {

    //Establecer por defecto en el newInfo, todos los campos del LDR de forma vacia.
    fields[idLDR].filter(e => (e.type !== "mainSubtitle" && e.type !== "Title" && e.type !== "subtitle" && e.type !== "exceptionToActivateOtherLDR")).map((field) => (

      setNewInfo((prevState) => ({
        ...prevState,
        [field.id]: ""
      })
      )));


    //Efecto para establecer el subtitulo por defecto o si en el JSON de los LDRS viene un campo establecido de mainSubtitle.
    const subtitle = fields[idLDR] && fields[idLDR].filter(e => e.type === "mainSubtitle");
    if (subtitle?.length > 0) {
      setSubtitle({ label: subtitle[0].label, width: subtitle[0].width });
    }

  }, [fields])


  //#endregion

  //#region Cuando cambie newInfo la agregue al state padre ldrsList cuando esté listo para enviar.
  useEffect(() => {
    if (readyToSend === true) {

      setLdrsList(prevState => {
        return {
          ...prevState,
          // [idLDR]: newInfo
          [idLDRNumber]: buildStructure()
        };
      });

    }

  }, [newInfo, readyToSend])
  //#endregion

  //#region Handle para modificar el state principal
  const handleChangeInfo = (constant, e, constantName, f, type) => {

    // if (e === "reqExtStorageException") { //excepción en storage 
    if (type === "exceptionToActivateOtherLDR") { //excepción en storage 

      //console.log("aqui")

      if (f === "Si") {
        setToggleCheck(prevState => ({
          ...prevState,
          ["newStorage"]: true
        }))

        Toast.fire({
          title: "Atención",
          html: "Se habilitó el LDR de storage en la parte superior por el almacenamiento externo.",
          type: 'info'
        });


      } else {
        setToggleCheck(prevState => ({
          ...prevState,
          ["newStorage"]: false
        }))

        Toast.fire({
          title: "Atención",
          html: "Se desactivó el LDR de storage en la parte superior por el almacenamiento externo.",
          type: 'warning'
        });

      }
    }


    //se actualiza el state de la nueva informacion
    if (f === undefined) { //Select
      //console.log(e)
      // debugger;
      let temp = "";
      try {
        e[0].value === undefined ? temp = e : temp = e.map(e => { return e.label })//El primer case es para un select normal, y el segundo es para moldear la data para un multiselect.
      } catch (e) {
        temp = e
      }
      setNewInfo((prevState) => ({
        ...prevState,
        [constant]: temp
      })
      )

    } else { //Input

      setNewInfo((prevState) => ({
        ...prevState,
        [constant]: f// si se quiere el id cambiar f por e
      })
      )
    }


  }
  //#endregion

  //#region Handle para refrescar y verificar si hay un elemento del formulario que ya no está activo, 
  //que lo restablezca en blanco en el state newInfo, ejemplo: typeConnNewStorage="Direct To Host" => typeConnNewStorage="" 
  const handleRefreshStateByElement = (field) => {

    if (newInfo[field.id] !== ""
      && field.type !== "mainSubtitle" && field.type !== "title" && field.type !== "subtitle") {

      setNewInfo(prevState => {
        return {
          ...prevState,
          [field.id]: ""
        };
      });

      // console.log(field.id)
    }
  }
  //#endregion 


  const generateFields = () => {
    //  debugger;

    return fields[idLDR]?.filter(e => (e.type !== "mainTitle" && e.type !== "mainSubtitle")).map((field, key) => (
      <>
        {

          (field.disabled === 0/*false*/ && //No está deshabilitado el campo 
            field.dependsAnotherDropdown === 1 /*true*/)

            ?

            (  //DEPENDE DE UN DROPDOWN
              (newInfo !== "" &&
                (
                  //Multiselect
                  (
                    Array.isArray(newInfo[field.idDropdownDepends]) &&
                    (newInfo[field.idDropdownDepends].includes(field.valueThatDepends) ||
                      newInfo[field.idDropdownDepends].includes(field?.valueThatDepends?.lableSingle))
                  )

                  ||

                  //Select
                  (!Array.isArray(newInfo[field.idDropdownDepends]) &&
                    (newInfo[field.idDropdownDepends] === field.valueThatDepends ||
                      newInfo[field.idDropdownDepends] === field?.valueThatDepends?.lableSingle)
                  )
                )

              ) ?
                //Ejemplo:
                //equipmentTypeStorage:"Entry"     === Entry  
                (

                  field.type === "title" ? //titulo centrado

                    <Col sm={field.colWidth} className="" key={key}>
                      <FormGroup key={key}>
                        <Row className="mb--4" style={{ display: "flex", justifyContent: "center" }}>
                          <Col key={key} sm="12" className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                            {/* <h2 className="mb-0" id="title"> {field.label}</h2> */}

                            <Divider><h2>{field.label}</h2></Divider>


                          </Col>

                        </Row>
                        {/* <hr width="35%" /> */}
                      </FormGroup>
                    </Col>

                    : field.type === "subtitle" ? //subtitulo letra azul centrado.
                      <Col sm={field.colWidth} className="" key={key}>
                        <FormGroup key={key}>
                          <Row className="mb--2" style={{ display: "flex", justifyContent: "center" }}>
                            <Col key={key} sm="11" className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                              <label className="form-control-label" id="subtitle">{field.label}</label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      :




                      field.type.toLowerCase() === "date" || field.type.toLowerCase() === "text" || field.type.toLowerCase() === "input" || field.type.toLowerCase() === "number" || field.type.toLowerCase() === "textarea" ?
                        <Col sm={field.colWidth} className="" key={key}>
                          <FormGroup key={key}>
                            {field.id === "modelConference" ? //Este campo tiene enlazado un link para verificar modelo
                              <label className="form-control-label" > <a href={field.link} target="_blank">
                                {/* {field.label} */}
                                {field.required == true ? field.label + '*' : field.label}
                              </a></label>
                              :
                              <label className="form-control-label">
                                {/* {field.label} */}
                                {field.required == true ? field.label + '*' : field.label}
                              </label>}

                            <Input
                              key={field.id}
                              id={field.id}
                              name={"LDR"}
                              title={title}
                              required={field.required}
                              className="form-control text-dark"
                              type={field?.inputType != undefined ? field?.inputType : field.type}
                              min={field.min}
                              max={field.max}
                              readOnly={field.disabled == 1}
                              onChange={(e) => handleChangeInfo(field.id, e.target.value)}
                            />
                            <div className="invalid-feedback">
                              Este campo no puede estar vacío
                            </div>
                          </FormGroup>
                        </Col>

                        : field.type === "multiselect" ?
                          <Col sm={field.colWidth} className="" key={key}>
                            <FormGroup key={key}>
                              <label className="form-control-label">
                                {/* {field.label} */}
                                {field.required == true ? field.label + '*' : field.label}
                              </label>


                              <Select
                                key={field.id}
                                id={field.id}
                                name={"LDR"}
                                title={title}
                                required={field.required}
                                className="basic-single text-dark"
                                type="Multiselect"
                                isMulti
                                isSearchable
                                onChange={(e) => handleChangeInfo(field.id, e)}
                                options={newInfo[field.id]?.length === field.max ? [] :  //Si no supera el maximo

                                  field.options
                                }

                                noOptionsMessage={() => {
                                  return newInfo[field.id]?.length === field.max
                                    ? 'No puede seleccionar más de estas opciones'
                                    : 'No options available';
                                }}
                              />

                            </FormGroup>
                          </Col>




                          :
                          <Col sm={field.colWidth} className="" key={key}>
                            <FormGroup key={key}>
                              <label className="form-control-label">
                                {/* {field.label} */}
                                {field.required == true ? field.label + '*' : field.label}

                              </label>
                              <Input
                                key={field.id}
                                id={field.id}
                                name={"LDR"}
                                title={title}
                                required={field.required}
                                className="basic-multi-select text-dark"
                                type={field.type}
                                isDisabled={field.disabled == 1}
                                onChange={(e) => handleChangeInfo(field.id, e.target.children[e.target.selectedIndex]?.id, field.defaultValueLabel, e.target.children[e.target.selectedIndex]?.value)}
                              >
                                <option id=""></option>


                                {
                                  //Estas son las opciones locales

                                  field.options && field.options?.map((value) => {
                                    return (
                                      <option id={value.value}>{value.label}</option>
                                    );
                                  })

                                }
                              </Input>
                              <div className="invalid-feedback">
                                Este campo no puede estar vacío
                              </div>
                            </FormGroup>
                          </Col>


                ) :
                //RESTABLECER en blanco si un elemento no está activo en el state.
                (
                  newInfo !== "" &&
                  handleRefreshStateByElement(field)
                )

            )



            : //NO DEPENDE DE NINGÚN DROPDOWN




            (
              field.disabled == 0/*false*/ && //No está deshabilitado
              (


                field.type === "title" ? //titulo centrado

                  <Col sm={field.colWidth} className="" key={key}>
                    <FormGroup key={key}>
                      <Row className="mb--4" style={{ display: "flex", justifyContent: "center" }}>
                        <Col key={key} sm="12" className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                          {/* <h2 className="mb-0" id="title"> {field.label}</h2> */}
                          <Divider><h3>{field.label}</h3></Divider>

                        </Col>

                      </Row>
                      {/* <hr width="35%" /> */}
                    </FormGroup>
                  </Col>

                  : field.type === "subtitle" ? //subtitulo letra azul centrado.
                    <Col sm={field.colWidth} className="" key={key}>
                      <FormGroup key={key}>
                        <Row className="mb--2" style={{ display: "flex", justifyContent: "center" }}>
                          <Col key={key} sm="11" className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                            <label className="form-control-label" id="subtitle">{field.label}</label>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>

                    : field.type === "exceptionToActivateOtherLDR" ? //Esta excepción es para activar un toggleCheck de un LDR.
                      <Col sm={field.colWidth} className="" key={key}>
                        <FormGroup key={key}>
                          <label className="form-control-label">
                            {/* {field.label} */}
                            {field.required == true ? field.label + '*' : field.label}
                          </label>
                          <Input
                            key={field.id}
                            id={field.id}
                            name={"LDR"}
                            title={title}
                            required={field.required}
                            className="basic-multi-select text-dark"
                            type="select"
                            isDisabled={field.disabled == 1}
                            onChange={(e) => handleChangeInfo(field.id, e.target.children[e.target.selectedIndex].id, field.defaultValueLabel, e.target.children[e.target.selectedIndex].value, field.type)}
                          >
                            <option id=""></option>
                            <option id="siOption">Si</option>
                            <option id="noOption">No</option>
                          </Input>
                          <div className="invalid-feedback">
                            Este campo no puede estar vacío
                          </div>


                        </FormGroup>
                      </Col>

                      :

                      (field.type.toLowerCase() === "date" || field.type.toLowerCase() === "text" || field.type.toLowerCase() === "input" || field.type.toLowerCase() === "number" || field.type.toLowerCase() === "textarea" ?
                        <Col sm={field.colWidth} className="" key={key}>
                          <FormGroup key={key}>
                            {field.id === "modelConference" ? //Este campo tiene enlazado un link para verificar modelo
                              <label className="form-control-label" > <a href={field.link} target="_blank">
                                {/* {field.label} */}
                                {field.required == true ? field.label + '*' : field.label}

                              </a></label>
                              :
                              <label className="form-control-label">
                                {/* {field.label} */}
                                {field.required == true ? field.label + '*' : field.label}

                              </label>}

                            <Input
                              key={field.id}
                              id={field.id}
                              name={"LDR"}
                              title={title}
                              required={field.required}
                              className="form-control text-dark"
                              type={field?.inputType != undefined ? field?.inputType : field.type} //Los inputType fueron creados en la vista de mantenimiento
                              min={field.min}
                              max={field.max}
                              readOnly={field.disabled == 1}
                              onChange={(e) => handleChangeInfo(field.id, e.target.value)}
                            />
                            <div className="invalid-feedback">
                              Este campo no puede estar vacío
                            </div>
                          </FormGroup>
                        </Col>

                        : field.type === "multiselect" ?
                          <Col sm={field.colWidth} className="" key={key}>
                            <FormGroup key={key}>
                              <label className="form-control-label">
                                {/* {field.label} */}
                                {field.required == true ? field.label + '*' : field.label}

                              </label>


                              <Select
                                key={field.id}
                                id={field.id}
                                name={"LDR"}
                                title={title}
                                required={field.required}
                                className="basic-single text-dark"
                                type="Multiselect"
                                isMulti
                                placeholder={field.placeholder}
                                min={field.min}
                                max={field.max}
                                isSearchable
                                onChange={(e) => handleChangeInfo(field.id, e)}
                                options={newInfo[field.id]?.length === field.max ? [] :  //Si no supera el maximo

                                  field.options //opciones locales
                                }
                                noOptionsMessage={() => {
                                  return newInfo[field.id]?.length === field.max
                                    ? 'No puede seleccionar más de dos opciones'
                                    : 'No options available';
                                }}

                              />

                            </FormGroup>
                          </Col>

                          :
                          <Col sm={field.colWidth} className="" key={key}>
                            <FormGroup key={key}>
                              <label className="form-control-label">
                                {/* {field.label} */}
                                {field.required == true ? field.label + '*' : field.label}

                              </label>
                              <Input
                                key={field.id}
                                id={field.id}
                                name={"LDR"}
                                title={title}
                                required={field.required}
                                className="basic-multi-select text-dark"
                                type={field.type}
                                placeholder={field.placeholder}
                                isDisabled={field.disabled == 1}
                                onChange={(e) => handleChangeInfo(field?.id, e.target.children[e.target.selectedIndex]?.id, field.defaultValueLabel, e.target.children[e.target.selectedIndex]?.value)}
                              >
                                <option id=""></option>

                                {
                                  //Estas son las opciones locales

                                  field.options && field.options?.map((value) => {
                                    return (
                                      <option id={value.value}>{value.label}</option>
                                    );
                                  })

                                }
                              </Input>
                              <div className="invalid-feedback">
                                Este campo no puede estar vacío
                              </div>


                            </FormGroup>
                          </Col>)


              )
            )

        }



      </>
    ))
  }


  //Localiza los campos activos, sus respectivos ids, y sus valores, para enviar los campos ordenados y no los desactivados.
  const buildStructure = () => {

    let structure = [];
    // debugger;

    fields[idLDR] && fields[idLDR].map((field, key) => {

      if (field.disabled === 0 /*false */ && //No está deshabilitado el campo 
        field.dependsAnotherDropdown === 1/*true*/

      ) {
        if (

          newInfo !== "" &&

          (
            //Multiselect
            (
              Array.isArray(newInfo[field.idDropdownDepends]) &&
              (newInfo[field.idDropdownDepends].includes(field.valueThatDepends) ||
                newInfo[field.idDropdownDepends].includes(field?.valueThatDepends?.lableSingle))
            )

            ||

            //Select
            (!Array.isArray(newInfo[field.idDropdownDepends]) &&
              (newInfo[field.idDropdownDepends] === field.valueThatDepends ||
                newInfo[field.idDropdownDepends] === field?.valueThatDepends?.lableSingle)
            )
          )

        ) {

          structure = [...structure,
          {
            idName: field.id,
            id: field.idNumber,
            value: ["title", "subtitle", "mainSubtitle"].includes(field.type) ? field.label : newInfo[field.id],

          }
          ]

        }

      } else {
        if (field.disabled === 0 /*false*/) {
          structure = [...structure,
          {
            idName: field.id,
            id: field.idNumber,
            value: ["title", "subtitle", "mainSubtitle"].includes(field.type) ? field.label : newInfo[field.id],

          }

          ]


        }
      }



    })

    // console.log("Estructura a enviar", structure);
    return structure
  }

  return (
    <>

      <Row className="mb-2" >

        <Col xs="12" sm='8'>
          <h3 className="mb-0">{title}</h3>
          <p className="text-sm mb-0">{subtitle.label}</p>


        </Col>
      </Row>
      <hr align="left" width={subtitle.width} xs="12" className='mt-1 mb-4' />

      <Form noValidate className="formInfo needs-validation">
        <Row className="align-items-center">

          {

            generateFields()

          }
        </Row>
      </Form>



      {/*JSON.stringify(newInfo)*/}
    </>
  )
}
