
import classnames from 'classnames'
import Avatar from 'components/Shared/Avatar/index'
import React, { useEffect, useState } from 'react'
import { TrendingUp, User, Box, Lock, DollarSign } from 'react-feather'
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  Row,
  Col,
  Media
} from 'reactstrap'

const StatsCard = ({ cols, info, title }) => {
  const [data, setData] = useState([
    {
      title: info[0].title,
      subtitle: info[0].subTitle,
      color: info[0].color,
      icon: <TrendingUp size={24} />
    },
    {
      title: info[1].title,
      subtitle: info[1].subTitle,
      color: info[1].color,
      icon: <User size={24} />
    },
    {
      title: info[2].title,
      subtitle: info[2].subTitle,
      color: info[2].color,
      icon: <Lock size={24} />
    },
    {
      title: info[3].title,
      subtitle: info[3].subTitle,
      color: info[3].color,
      icon: <DollarSign size={24} />
    }
  ])

  useEffect(() => {
    setData([
      {
        title: info[0].title,
        subtitle: info[0].subTitle,
        color: info[0].color,
        icon: <TrendingUp size={24} />
      },
      {
        title: info[1].title,
        subtitle: info[1].subTitle,
        color: info[1].color,
        icon: <User size={24} />
      },
      {
        title: info[2].title,
        subtitle: info[2].subTitle,
        color: info[2].color,
        icon: <Lock size={24} />
      },
      {
        title: info[3].title,
        subtitle: info[3].subTitle,
        color: info[3].color,
        icon: <DollarSign size={24} />
      }
    ])
  }, [info])

  //   useEffect(() => {
  //     data =

  //       setLoading(false);

  //   }, [info,loading])

  const renderData = data => {
    return data.map((item, index) => {
      const margin = Object.keys(cols)
      return (
        <Col
          key={index}
          {...cols}
          className={classnames({
            [`mb-2 mb-${margin[0]}-0`]: index !== data.length - 1
          })}
        >
          <Media>
            <Avatar color={item.color} icon={item.icon} className='mr-2' />
            <Media className='my-auto' body>
              <h4 className='font-weight-bolder mb-0'>{item.title}</h4>
              <CardText className='font-small-3 mb-0'>{item.subtitle}</CardText>
            </Media>
          </Media>
        </Col>
      )
    })
  }

  return (
    <>
      <Card className='card-statistics'>
        <CardHeader>
          <CardTitle tag='h4'>{title}</CardTitle>
          <CardText className='card-text font-small-2 mr-25 mb-0'>
            Updated Today
          </CardText>
        </CardHeader>
        <CardBody className='statistics-body'>
          <Row>{renderData(data)}</Row>
        </CardBody>
      </Card>
    </>
  )
}

export default StatsCard

