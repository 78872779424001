import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Filter from "components/FinanceFlows/Administration/Filters";
import Table from "components/FinanceFlows/FlowsAprobation/Tables";
import RequestDetails from "views/FinanceFlows/ApprovalFlows/RequestDetails.jsx";
import { Spin } from 'antd';
import { useDispatch } from "react-redux";
import { getOptions, getAllRequest, getAccessRequestByUser, getAllRequestByRangeDate } from "actions/financialFlows";
import moment from 'moment';
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';

const Requests = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [loading, setLoad] = useState(false);
    const [filterByTable, setFiltersByTable] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [countRows, setCountRows] = useState([]);

    const [infoToModule, setInfoToModule] = useState({
        rows: [],
        profileState: [],
        category: [],
        businessUnit: [],
        profileType: [],
        initialDateFilter: "",
        finalDateFilter: ""
    });
    const [requestSelected, setRequestSelected] = useState({
        isSelected: false,
        data: {}
    });

    //Columnas de la tabla principal generica
    const colums = [
        {
            label: "Identificador",
            value: "id",
            type: "b"
        },
        {
            label: "Solicitante",
            value: "requesterName",
            type: "b"
        },
        {
            label: "Fecha",
            value: "createdat",
            type: "date"
        },
        {
            label: "Aprobador en Linea",
            value: "nextApproverUser",
            type: "a"
        },
        {
            label: "Estado",
            value: "requestStateName",
            type: "a"
        },
        {
            label: "Categoria",
            value: "documentCategoryName",
            type: "a"
        }
    ]

    //Json que genera los filtros que se utilizaran en la tabla
    const filter = [
        {
            label: "Identificador",
            type: "number",
            xs: "4",
            value: "id"
        },
        {
            label: "Solicitante",
            type: "input",
            xs: "4",
            value: "requesterName"
        },
        {
            label: "Fecha de Solicitud",
            type: "date",
            xs: "4",
            value: "createdat"
        },
        {
            label: "Aprobador en Linea",
            type: "input",
            xs: "4",
            value: "nextApproverUser"
        },
        {
            label: "Estado Actual",
            type: "select",
            xs: "4",
            value: "profileState",
            valueFilter: "requestStateName"
        },
        {
            label: "Categoria",
            type: "select",
            xs: "4",
            value: "category",
            valueFilter: "documentCategoryName"
        },
        ,
        {
            label: "Filtro rango de fecha: Fecha inicial",
            value: "finalDateFilter",
            type: "date",
            xs: "4",
        },
        {
            label: "Filtro rango de fecha: Fecha final",
            value: "initialDateFilter",
            type: "date",
            xs: "4",
        }
    ]

    //Botones adicionales en la tabla principal
    const aditionalsButtons = [
        {
            color: "secondary",
            function: (e) => handleOnSetRequest(e),
            icon: "fas fa-edit",
            className: "table-action table-action-edit",
            label: "Ver Detalles"
        }
    ]

    // json con la informacion de la persona dueña de la solicitud
    const formRequester = [
        { UnitTypeName: "Usuario", typeUnitType: "input", type: "input", value: "requesterName", required: true },
        { UnitTypeName: "Email", typeUnitType: "input", type: "input", value: "requesterEmail", required: true },
        { UnitTypeName: "Posición", typeUnitType: "input", type: "input", value: "requesterPosition", required: true },
        { UnitTypeName: "Departamento", typeUnitType: "input", type: "input", value: "requesterDepartment", required: true }
    ]

    // json con la informacion del documento relacionado a la solicitud
    const formDocument = [
        { UnitTypeName: "Nombre", typeUnitType: "input", type: "input", value: "documentName", required: true },
        { UnitTypeName: "Categoria", typeUnitType: "input", type: "input", value: "documentCategoryName", required: true },
        { UnitTypeName: "Ultima actualización", typeUnitType: "input", type: "input", value: "updateat", required: true },
        { UnitTypeName: "Tipo de Flujo", typeUnitType: "input", type: "input", value: "documentFlow", required: true }
    ]

    // json con la informacion general de la solicitud
    const formDocumentDesc = [
        { UnitTypeName: "Fecha Solicitud * ", typeUnitType: "input", type: "date", value: "createdat", required: true },
        { UnitTypeName: "Solicitante * ", typeUnitType: "input", type: "input", value: "requesterName", required: true },
        { UnitTypeName: "País * ", typeUnitType: "input", type: "input", value: "buName", required: true },
        { UnitTypeName: "DESCRIPCION DE SOLICITUD", typeUnitType: "input", type: "textarea", value: "description", required: true }
    ]

    // Funcion que asigna la solicitud seleccionada
    const handleOnSetRequest = (request) => {
        setRequestSelected(prevState => ({
            ...prevState,
            data: request,
            isSelected: true
        }));
        const isTable = request.requestInformation.filter((item) => item.typeName === "Tabla")
        if(isTable.length > 0) {
            setCountRows(isTable[0].value);
        }
    }

    // Funcion que limpia la solicitud seleccionada
    const handleOnClearRequest = () => {
        setRequestSelected({
            data: {},
            isSelected: false
        });
    }
    //Funcion que trae la data de los campos selects
    const handleOnGetInfo = async () => {
        setLoad(true);
        let profileCategories = [];
        let profileStates = [];
        let profileBU = [];
        await dispatch(getAccessRequestByUser()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {

                for (const item of payload.data.data[0]) {
                    if (item.profileTypeId !== 2) {
                        for (const pcategory of item.profileCategories) {
                            profileCategories.push(pcategory.categoryId);
                        }
                        for (const pstates of item.profileState) {
                            profileStates.push(pstates.stateId);
                        }
                        for (const pBusinessUnit of item.profileBusinessUnit) {
                            profileBU.push(pBusinessUnit.BusinessUnitId);
                        }
                    }
                }
            } else {
                setLoad(false);
            }
        });
        dispatch(getOptions()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    area: payload.data.data.area,
                    businessUnit: payload.data.data.businessUnit,
                    category: payload.data.data.category,
                    profileType: payload.data.data.profileType,
                    profileState: payload.data.data.state,
                    typeActive: payload.data.data.typeActive,
                    typeUnitType: payload.data.data.typeUnitType,
                    unitType: payload.data.data.unitType,
                }));
            } else {
                setLoad(false);
            }
        });
        await dispatch(getAllRequest()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let requestsTemp = payload.data.data[0];
                for (const request of requestsTemp) {
                    if (!request.updateat) {
                        request.updateat = moment.utc(request.createdat).format("YYYY-MM-DD");
                    } else {
                        request.updateat = moment.utc(request.updateat).format("YYYY-MM-DD");
                    }
                    request.createdat = moment.utc(request.createdat).format("YYYY-MM-DD");
                }
                if (isAdmin === true) {
                    setInfoToModule((prevState) => ({
                        ...prevState,
                        rows: requestsTemp
                    }));
                } else {
                    let request = requestsTemp.filter((item) => {
                        let ok = false;
                        for (const pStatus of profileStates) {
                            pStatus === item.requestStateId &&
                                (ok = true)
                        }
                        return ok
                    })
                    request = request.filter((item) => {
                        let ok = false;
                        for (const pCategory of profileCategories) {
                            pCategory === item.documentCategoryId &&
                                (ok = true)
                        }
                        return ok
                    })
                    request = request.filter((item) => {
                        let ok = false;
                        for (const info of item.requestInformation) {
                            if (info.unitTypeId == 5) {
                                for (const pBusinessUnit of profileBU) {
                                    pBusinessUnit == parseInt(info.valueId) &&
                                        (ok = true)
                                }
                            }
                        }
                        return ok
                    })
                    setInfoToModule((prevState) => ({
                        ...prevState,
                        rows: request
                    }));
                }
            } else {
                setLoad(false);
            }
            setLoad(false);
        });
    };

    //Funcion que obtiene solicitudes segun su rango de fechas
    const handleOnGetAllRequestRangeDate = async () => {
        setLoad(true);
        let profileCategories = [];
        let profileStates = [];
        let profileBU = [];

        let requestsTemp = [];
        await dispatch(getAccessRequestByUser()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                for (const item of payload.data.data[0]) {
                    if (item.profileTypeId !== 2) {
                        for (const pcategory of item.profileCategories) {
                            profileCategories.push(pcategory.categoryId);
                        }
                        for (const pstates of item.profileState) {
                            profileStates.push(pstates.stateId);
                        }
                        for (const pBusinessUnit of item.profileBusinessUnit) {
                            profileBU.push(pBusinessUnit.BusinessUnitId);
                        }
                    }
                }
            } else {
                setLoad(false);
            }
        });
        await dispatch(getAllRequestByRangeDate({ initialDate: infoToModule.initialDateFilter, finalDate: infoToModule.finalDateFilter })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                requestsTemp = payload.data.data[0];
                for (const request of requestsTemp) {
                    if (!request.updateat) {
                        request.updateat = moment.utc(request.createdat).format("YYYY-MM-DD");
                    } else {
                        request.updateat = moment.utc(request.updateat).format("YYYY-MM-DD");
                    }
                    request.createdat = moment.utc(request.createdat).format("YYYY-MM-DD");
                }
                if (isAdmin === true) {
                    setInfoToModule((prevState) => ({
                        ...prevState,
                        rows: requestsTemp
                    }));
                } else {
                    let request = requestsTemp.filter((item) => {
                        let ok = false;
                        for (const pStatus of profileStates) {
                            pStatus === item.requestStateId &&
                                (ok = true)
                        }
                        return ok
                    })
                    request = requestsTemp.filter((item) => {
                        let ok = false;
                        for (const pCategory of profileCategories) {
                            pCategory === item.documentCategoryId &&
                                (ok = true)
                        }
                        return ok
                    })
                    request = requestsTemp.filter((item) => {
                        let ok = false;
                        for (const pBusinessUnit of profileBU) {
                            pBusinessUnit === item.businessUnitId &&
                                (ok = true)
                        }
                        return ok
                    })
                    setInfoToModule((prevState) => ({
                        ...prevState,
                        rows: request
                    }));
                }
            } else {
                setLoad(false);
            }
        });
        setLoad(false);
        return requestsTemp;
    };
    //Funcion para ver si e sun super Admin
    useEffect(() => {
        const fetchTeams = async () => {
            const teams = await getTeamsUserAccess();
            
            if (Array.isArray(teams) && teams.some((row) => row === "FF Admin")) {
                setIsAdmin(true);
            }
        };
    
        fetchTeams();
    }, [])
    //Funcion para aplicar los filtros que se utilizaran
    const renderRowsByFilters = (rows) => {
        if (Object.keys(filterByTable).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filterByTable);
            for (const element of filterKeys) {
                let valueFiltered;
                if (isNaN(filterByTable[element]) === false) {
                    valueFiltered = filterByTable[element]
                    rows = rows.filter((item) => String(item[element]).indexOf(valueFiltered) != -1)
                } else {
                    valueFiltered = filterByTable[element].toLowerCase()
                    rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
                }
            }
            return rows
        }
    }

    //Funcion para guardar los filtros de la tabla principal
    const handleOnfilterDinamic = (constant, e) => {
        setPage(1);

        if ((constant === "requestStateName" && e === "Todos") || (constant === "documentCategoryName" && e === "Todos")) {
            setFiltersByTable(prevState => ({
                ...prevState,
                [constant]: ``
            }))
        } else {
            if (constant === "initialDateFilter" || constant === "finalDateFilter") {
                setInfoToModule(prevState => ({
                    ...prevState,
                    [constant]: e
                }))
            } else {
                setFiltersByTable(prevState => ({
                    ...prevState,
                    [constant]: `${e}`
                }))
            }
        }
    }

    useEffect(() => {
        if (infoToModule.initialDateFilter !== "" || infoToModule.finalDateFilter !== "") {
            const getRangeDate = async () => {
                await handleOnGetAllRequestRangeDate();
            };
            getRangeDate();
        }
        if (infoToModule.initialDateFilter === "" && infoToModule.finalDateFilter === "") {
            const getInfo = async () => {
                await handleOnGetInfo();
            };
            getInfo();
        }
    }, [infoToModule.initialDateFilter, infoToModule.finalDateFilter, isAdmin]);
    return (
        <div>
            <AdminHeader name="Solicitudes" parentName="Finance Flows" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    {!requestSelected.isSelected ?
                        <>
                            <Filter
                                jsonFilters={filter}
                                filters={handleOnfilterDinamic}
                                optionsFilters={infoToModule}

                            />
                            <Table
                                text={"Resultados"}
                                createButton={false}
                                colums={colums}
                                rows={infoToModule.rows.length > 0 && renderRowsByFilters(infoToModule.rows)}
                                page={page}
                                setPage={setPage}
                                aditionalsButtons={aditionalsButtons}
                            />
                        </>
                        :
                        <>
                            <Row className="mt-2 mb-4">
                                <Col className="d-flex justify-content-end col">
                                    <Button
                                        color="warning"
                                        onClick={() => handleOnClearRequest()}
                                    >
                                        <span className="btn-inner--icon mr-1">
                                            <i className="fas fa-chevron-left" />
                                        </span>
                                        <span>Regresar</span>
                                    </Button>
                                </Col>
                            </Row>
                            <RequestDetails
                                request={requestSelected.data}
                                formRequester={formRequester}
                                formDocument={formDocument}
                                formDocumentDesc={formDocumentDesc}
                                getRequest={handleOnGetInfo}
                                setInfoToModule={setInfoToModule}
                                setLoad={setLoad}
                                setRequestSelected={setRequestSelected}
                                typeRequest="request"
                                countRows={countRows}
                                setCountRows={setCountRows}
                            />
                        </>
                    }

                </Spin>
            </Container>
        </div>
    );
}


export default Requests;