import React, { useState, useEffect } from 'react';
import FormUser from "components/UserCloudRequest/Forms";
import { Card, CardBody, Col, CardHeader, Button, Row, CardFooter, FormGroup } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { Divider } from "antd";
const ExternalUser = ({ info, backPage, handleOnChangeInfo, createNewRequest, fileList, uploadProps, data }) => {

    const [alert, setAlert] = useState("");
    const [alReadyExist, setAlReadyExist] = useState("");
    const itemsForms = [
        { label: "Cloud Vendor", type: "select", required: true, options: data.clouds, colWidth: 6, value: "Cloud Vendor", valueInsert: 8  },
        { label: "País", type: "select", required: true, multi: false, colWidth: 4, value: "País", valueInsert: 9, options: data.countries },
        { label: "Nombre de la cuenta", type: "input", required: true, colWidth: 4, value: "Nombre de la cuenta", valueInsert: 10 },
        { label: "Número de la cuenta Tenant", type: "input", required: true, colWidthmd: 4, value: "Nombre de la cuenta de Tenant", valueInsert: 11 },
        { label: "Motivo de la apertura", type: "input", required: true, colWidth: 4, value: "Motivo de la apertura", valueInsert: 12 },
        { label: "Nombre del usuario", type: "input", required: true, colWidth: 4, value: "Nombre del usuario", valueInsert: 13 },
        { label: "Rol del usuario en la empresa", type: "input", required: true, colWidth: 4, value: "Rol del usuario en la empresa", valueInsert: 14 },
        { label: "Correo electronico del usuario", type: "input", required: true, colWidth: 4, value: "Correo electronico del usuario", valueInsert: 15 },
        { label: "Teléfono del usuario", type: "input", required: true, colWidth: 4, value: "Teléfono del usuario", valueInsert: 16 },
        { label: "ZIP/Postal Code", type: "input", required: true, colWidth: 4, value: "ZIP/Postal Code", valueInsert: 17 },
        { label: "Rol requerido", type: "textarea", required: true, colWidth: 4, value: "Rol requerido", valueInsert: 18 },
        { label: "Comentarios", type: "textarea", required: true, colWidth: 4, value: "Comentarios", valueInsert: 19 },
        { label: "Por favor adjuntar solicitud del cliente", type: "file", required: true, colWidth: 12, value: "file", valueInsert: 19 },
    ];
    

    const itemsAlReadyExist = [
        { label: "Cloud Vendor", type: "select", required: true, options: data.clouds, colWidth: 6, value: "Cloud Vendor", valueInsert: 8 },
        { label: "País", type: "select", required: true, multi: false, colWidth: 6, value: "País", valueInsert: 9, options: data.countries },
        { label: "Nombre de la cuenta", type: "input", required: true, colWidth: 6, value: "Nombre de la cuenta", valueInsert: 10 },
        { label: "Número de la cuenta Tenant", type: "input", required: true, colWidthmd: 6, value: "Nombre de la cuenta de Tenant", valueInsert: 11 },
        { label: "Por favor adjuntar solicitud del cliente", type: "file", required: true, colWidth: 12, value: "file", valueInsert: 19 },
    ];

    const optionsExist = [{ label: "Sí", value: 1 }, { label: "No", value: 2 }]
    const createUser = () => {
        createNewRequest(2, 3);
        setAlert("")
    }
    const createConfirmation = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={`¿Desea crear el nuevo usuario?`}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => createUser()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, crear"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }
    const changeIsExist = (e) => {
        setAlReadyExist(e.value);
    }
    return (
        <div>
            <div>
                {alert}
                <Row className="d-flex justify-content-end">
                    <Col sm="12" md="1">
                        <Button
                            className="btn  btn-icon"
                            color="warning"
                            block
                            type="button"
                            onClick={() => backPage()}
                        >
                            <span className="btn-inner--icon mr-">
                                <i className="fas fa-chevron-left"></i>
                            </span>
                            <span className="btn-inner--text">Inicio </span>
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <h6 className="surtitle">Formulario</h6>
                                <h5 className="h3 mb-0">"{"Usuarios Externos (Cliente)"}</h5>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Row>
                                        <Col sm="12" md="6">
                                            <FormGroup>
                                                <label
                                                    style={{ fontSize: "12px" }}
                                                    className="form-control-label"
                                                > ¿El usuario ya cuenta con un perfil creado?

                                                </label>
                                                <Select
                                                    className="basic-single is-invalid"
                                                    type="select"
                                                    isSearchable
                                                    options={optionsExist}
                                                    defaultValue={{ label: "No", value: 2 }}
                                                    onChange={(e) => changeIsExist(e)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <Divider>Información del formulario</Divider>

                                    <FormUser
                                        itemForm={alReadyExist === 1 ? itemsAlReadyExist : itemsForms}
                                        itemInfo={info}
                                        handleOnChangeInfo={handleOnChangeInfo}
                                        fileList={fileList}
                                        uploadProps={uploadProps}
                                    />

                                </div>

                            </CardBody>
                            <CardFooter>
                                <Row className="d-flex justify-content-end">

                                    <Col sm="12" md="3">
                                        <Button
                                            className="btn  btn-icon"
                                            color="success"
                                            block
                                            type="button"
                                            onClick={() => createConfirmation()}
                                        >
                                            <span className="btn-inner--icon mr-">
                                                <i className="fas fa-share"></i>
                                            </span>
                                            <span className="btn-inner--text">Crear </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>


            </div>
        </div>
    );
};

export default ExternalUser;