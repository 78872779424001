// actions
import { findCollaboratorsReported } from "actions/coe.jsx";
// antd components
import { Spin } from 'antd';
// core components Shared
import DateRanges from "components/Shared/DateRanges/DateRanges.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Table from "components/Shared/Table/Table.jsx";
// javascript library for dates
import moment from 'moment';
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from "reactstrap";
// core selectors
import { getAllCoEs, getAllEquipments, getCollaboratorsReported, getHoursByCollaborator, getAllTypeActivities } from '../../selectors/coe.jsx';

moment.locale("es");

class CollaboratorsReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      sizePerPage: 25,
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      coesSelected: {
        value: 0,
        label: "Todos"
      },
      equipmentsSelected: {
        value: 0,
        label: "Todos"
      },
      typeActivitiesSelected: {
        value: 0,
        label: "Todos"
      },
    }
  }


  componentWillMount() {
    this.props.findCollaboratorsReported({
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false,
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
  * Funcion para cuando se selecciona una fecha ya sea de inicio o de fin, la agrega a la variable
  * respectiva en el estado
  * @memberof Nodes
  */
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else {
      const {
        startDate,
        endDate
      } = this.state;
      this.setState({
        [who]: date,
        loading: true
      });
      this.props.findCollaboratorsReported({
        startDate: who === 'startDate' ? date : startDate,
        endDate: who === 'endDate' ? date : endDate,
      }).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        }
        this.setState({
          loading: false,
        });
      });
    }
  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = date => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  _handleOnClearFilters = () => {
    this.setState({
      coesSelected: {
        value: 0,
        label: "Todos"
      },
      equipmentsSelected: {
        value: 0,
        label: "Todos"
      },
      typeActivitiesSelected: {
        value: 0,
        label: "Todos"
      },
    });
  };

  _handleOnChangeFilters = (who, value) => {
    this.setState({
      [who]: value
    });
  };

  _handleRenderDataByFilters = (data) => {
    const { coesSelected, equipmentsSelected, typeActivitiesSelected } = this.state;
    if (coesSelected.value === 0 && equipmentsSelected.value === 0 && typeActivitiesSelected.value === 0) {
      return data;
    }
    else {
      if (coesSelected.value !== 0) {
        data = data.filter((row) => row.coeName === coesSelected.label);
      }
      if (equipmentsSelected.value !== 0) {
        data = data.filter((row) => row.equipmentName === equipmentsSelected.label);
      }
      if (typeActivitiesSelected.value !== 0) {
        data = data.filter((row) => row.typeActivity === typeActivitiesSelected.label);
      }
      return data;
    }
  };

  renderCoeOptions = data => {
    const { equipmentsSelected } = this.state;
    let coes = [];
    let i = 1;
    if (equipmentsSelected.value === 0) {
      coes = data.map((row, key) => {
        return {
          value: key + 1,
          label: row.coeName,
        }
      });
    } else {
      for (const element of data) {
        if (equipmentsSelected.label === element.equipmentName) {
          coes.push({
            value: i,
            label: element.coeName,
          });
          i += 1;
        }
      }
    }
    coes = coes.map(e => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => coes[e]).map(e => coes[e]);

    coes = coes.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...coes];
  };

  renderEquipmentsOptions = data => {
    const { coesSelected } = this.state;
    let equipments = [];
    let i = 0;
    if (coesSelected.value === 0) {
      equipments = data.map((row, key) => {
        return {
          value: key + 1,
          label: row.equipmentName,
        }
      });
    } else {
      for (const element of data) {
        if (coesSelected.label === element.coeName) {
          equipments.push({
            value: i,
            label: element.equipmentName,
          });
          i += 1;
        }
      }
    }
    equipments = equipments.map(e => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => equipments[e]).map(e => equipments[e]);

    equipments = equipments.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...equipments];
  };

  expandRows = {
    clickToExpand: true,
    parentClassName: "expand-parent",
    onlyOneExpanding: true,
    renderer: row => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Nombre de la Actividad</span> <br />
              {row.activityName}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Fecha de Creación</span> <br />
              {row.createdDate}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Fecha de Fin Estimada</span>{" "} <br />
              {row.estimatedEndDate}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Fecha de Fin Real</span> <br />
              {row.actualEndDate}
            </div>
          </div>
        </span>
        <br />

        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Acumulativo de Horas Reportadas</span> <br />
              {row.totalHoursReported}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Horas Estimadas</span> <br />
              {row.hoursPlanned}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Total de días estimados</span>{" "} <br />
              {row.estimatedTotalDays}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Total de días reales</span> <br />
              {row.actualTotalDays}
            </div>
          </div>
        </span>
        <br />
      </div>
    )
  };

  render() {

    const {
      name,
      parentName,
      collaborators,
      cols,
      // coesOptions,
      // equipmentsOptions,
      colsHours,
      hoursByCollaborator,
      typeActivities,
    } = this.props;

    const {
      loading,
      page,
      sizePerPage,
      startDate,
      endDate,
      coesSelected,
      equipmentsSelected,
      typeActivitiesSelected,
    } = this.state;

    const defaultSorted = [
      {
        dataField: "reportID",
        order: "asc"
      }
    ];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading}>
            <Row>
              <Col xs='12' md='5'>
                <DateRanges
                  title="Fechas de búsqueda"
                  subtitle="Ingrese un rango de fechas para la búsqueda."
                  startDate={startDate}
                  endDate={endDate}
                  reactDatetimeChange={this.handleReactDatetimeChange}
                  classNameReactDatetimeDays={this.getClassNameReactDatetimeDays}
                />
              </Col>
              {/* </Row> */}
              <Col xs='12' md='7'>
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col sm="12" md="8">
                        <h3 className="mb-0" >Filtros</h3>
                        <p className="text-sm mb-0">Filtros disponibles para los reportes.</p>
                      </Col>
                      <Col className="text-right" xs="12" md="4">
                        <Button
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={this._handleOnClearFilters}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-trash" />
                          </span>
                          <span className="btn-inner--text">
                            Limpiar
                      </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-right" xs="12" md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            CoE
                      </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un CoE"}
                            isSearchable={false}
                            options={this.renderCoeOptions(collaborators)}
                            value={coesSelected}
                            onChange={(e) => this._handleOnChangeFilters('coesSelected', e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="text-right" xs="12" md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Equipo
                      </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un Equipo"}
                            isSearchable={false}
                            options={this.renderEquipmentsOptions(collaborators)}
                            value={equipmentsSelected}
                            onChange={(e) => this._handleOnChangeFilters('equipmentsSelected', e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="text-right" xs="12" md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Tipo Actividad
                          </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un Equipo"}
                            isSearchable={false}
                            options={typeActivities}
                            value={typeActivitiesSelected}
                            onChange={(e) => this._handleOnChangeFilters('typeActivitiesSelected', e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  style={{
                    whiteSpace: "normal",
                    textAlign: "justify"
                  }}
                  title="Horas Reportadas"
                  subtitle="Reporte de las Horas Reportadas por Colaborador."
                  columns={colsHours}
                  data={this._handleRenderDataByFilters(hoursByCollaborator)}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={this._handleRenderDataByFilters(hoursByCollaborator).length}
                  defaultSorted={defaultSorted}
                  cvsFeatrue
                  fileName="Reporte de Horas Reportadas"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  style={{
                    whiteSpace: "normal",
                    textAlign: "justify"
                  }}
                  total
                  title="Colaboradores"
                  subtitle="Reportes de los colaboradores del COE."
                  columns={cols}
                  data={this._handleRenderDataByFilters(collaborators)}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={this._handleRenderDataByFilters(collaborators).length}
                  defaultSorted={defaultSorted}
                  expandFeatrue={this.expandRows}
                  cvsFeatrue
                  fileName="Reportes de colaboradores"
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

CollaboratorsReport.defaultProps = {
  name: "Reporte por colaboradores",
  parentName: "COE",
  collaborators: [],
  cols: [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true
    },
    {
      dataField: "reportID",
      text: "Reporte",
      sort: true
    },
    {
      dataField: "reportDate",
      text: "Fecha de Reporte",
      sort: true
    },
    {
      dataField: "username",
      text: "Usuario",
      sort: true
    },
    {
      dataField: "name",
      text: "Nombre Completo",
      sort: true
    },
    {
      dataField: "activityID",
      text: "Actividad",
      sort: true,
    },
    {
      dataField: "coeName",
      text: "CoE",
      sort: true,
    },
    {
      dataField: "equipmentName",
      text: "Equipo",
      sort: true
    },
    {
      dataField: "activityName",
      text: "Nombre de la actividad",
      sort: true,
      hidden: true
    },
    {
      dataField: "hoursReported",
      text: "Horas Reportadas",
      sort: true
    },
    {
      dataField: "totalHoursReported",
      text: "Total de Horas Reportadas",
      sort: true,
      hidden: true
    },
    {
      dataField: "createdDate",
      text: "Fecha de Creación",
      sort: true,
      hidden: true
    },
    {
      dataField: "estimatedEndDate",
      text: "Fecha de Fin Estimada",
      sort: true,
      hidden: true
    },
    {
      dataField: "actualEndDate",
      text: "Fecha de Fin Real",
      sort: true,
      hidden: true
    },
    {
      dataField: "estimatedTotalDays",
      text: "Total de Días estimados",
      sort: true,
      hidden: true
    },
    {
      dataField: "actualTotalDays",
      text: "Total de Días reales",
      sort: true,
      hidden: true
    },
    {
      dataField: "hoursPlanned",
      text: "Total de Horas Estimadas",
      sort: true,
      hidden: true
    },
  ],
  colsHours: [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true
    },
    {
      dataField: "name",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "coeName",
      text: "COE",
      sort: true,
    },
    {
      dataField: "equipmentName",
      text: "Equipo",
      sort: true,
    },
    {
      dataField: "typeActivity",
      text: "Tipo de Actividad",
      sort: true,
    },
    {
      dataField: "hoursReported",
      text: "Horas Reportadas",
      sort: true,
    },
  ],
  coesOptions: [],
  equipmentsOptions: [],
  hoursByCollaborator: [],
  typeActivities: []
}

CollaboratorsReport.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  collaborators: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  coesOptions: PropTypes.array.isRequired,
  equipmentsOptions: PropTypes.array.isRequired,
  hoursByCollaborator: PropTypes.array.isRequired,
  typeActivities: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  collaborators: getCollaboratorsReported(state),
  coesOptions: getAllCoEs(state),
  equipmentsOptions: getAllEquipments(state),
  hoursByCollaborator: getHoursByCollaborator(state),
  typeActivities: getAllTypeActivities(state),
});

export default withRouter(connect(mapStateToProps, {
  findCollaboratorsReported
})(CollaboratorsReport));
