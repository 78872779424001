/*!

=========================================================
*Autopp - UseSendBawDevolutionForm
=========================================================

* Custom hook el cual realiza un update en el registro de 
*requerimiento en la tabla de BAW, esto realiza despues de 
*que por alguna razón el especialista haya devuelto la solicitud,
*entonces el vendedor actualiza nueva información, actualiza el estado 
*en baw, y esto le llega al robot para que le envie la nueva información 
*al especialista y lo apruebe, rechace, o devuelva nuevamente.
*

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import { useContext } from 'react'
import { useDispatch } from 'react-redux';

//Action para el envio de la oportunidad apartir del JSON Principal EditData.
import { newBawDevolutionReq } from 'actions/autoppLdrs';
import { UserContext } from '../SharedComponents/UserContext';


//Import para consulta de BAW en DB
import { getDataBAWRows } from 'actions/autoppLdrs';

export const useSendBawDevolutionForm = () => {


    //#region states globales
    const {
        setModalBAW,        
        setLoad,
        Toast
    } =
        useContext(UserContext);
    //#endregion

    //#region states locales
    const dispatch = useDispatch();
    //#endregion



    //#region Este botón gestiona toda la cadena para la acutlización de el requerimento BAW, haciendo una validación del formulario, y si es correcto lo envia al robot.

    const handleSendBawDevolutionForm = (newInfo, requirementInfoDevolution, handleSetTableOrBawFormDevolution, setLoadingChangingView) => {
        //console.log("pressBawDevoltionForm");


        let amountFieldsEmpty = validateForms();

        if (amountFieldsEmpty > 0) {
            
            Toast.fire({
                title: "Atención",
                html: "Hay " + amountFieldsEmpty + " campos del formulario de devolución BAW vacíos, por favor complételos.",
                type: 'warning'
            });
            return;
        }


        if (newInfo !== undefined) { 
      
            setLoadingChangingView(true);


            dispatch(newBawDevolutionReq({ newInfo: newInfo, requirementInfoDevolution: requirementInfoDevolution, type: "1" })).then((resp) => {
              

                const { payload } = resp;

                if (payload.status === 200) {
                    
                    //Refrescar los status de los rows del modal de BAW
                    handleRefreshBawStatus(requirementInfoDevolution, setLoadingChangingView);

                    //Volver a la vista de solicitudes de BAW
                    handleSetTableOrBawFormDevolution();

                    Toast.fire({
                        title: "Atención",
                        html: `Requerimiento #${payload.data.payload.caseNumber} enviado exitosamente al especialista.`,
                        type: 'success'
                    });
                    //setLoadingChangingView(false);


                } else {
                    setLoadingChangingView(false);
                    Toast.fire({
                        title: "Atención",
                        html: "No se pudo enviar la devolución, por favor contacte a Application Management.",
                        type: 'warning'
                    });
                };
            })

        } else {
            console.log("Error new Info vacío")
        }



    }

    //#endregion




    //Este handle realiza la actualización del requerimiento BAW.
    const handleRefreshBawStatus = async (requirementInfoDevolution, setLoadingChangingView) => {
        //console.log("En refrescamiento")

        dispatch(getDataBAWRows({ oppId: requirementInfoDevolution.oppId })).then((resp) => {

            const { payload } = resp;
            //console.log(payload);

            if (payload.status === 200) {
                //console.log(payload.data.payload.rows);
                const value = payload.data.payload.rows;
                setModalBAW((prevState) => ({
                    ...prevState,
                    length: payload.data.payload.rows.length,
                    rowsBAW: value,
                }), setLoadingChangingView(false))
            }else {
                setLoadingChangingView(false)
            }
        })


    };


    //#region Método para validar si los campos están de todos los forms están vacíos o no.
    const validateForms = () => {

        let cont = 0;

        //#region Valida los campos input y selects normales

        const forms2 = document.querySelectorAll(".formInfo input,Select, textarea, number")

        Array.from(forms2).forEach((input) => {
            //Valide todos menos los LDRS.

            if (input.name !== "LDR" && input.required === true) {
 
                if (input.name === "BawDevolution" && input.value === "") {
                    input.classList.add("is-invalid");
                    cont++;

                } else {
                    input.classList.remove("is-invalid");
                }
            }
        });

        //#endregion
        return cont;

    }

    //#endregion




    return { handleSendBawDevolutionForm }


}


