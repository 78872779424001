// javascript plugin that creates nice dropzones for files
import { Icon, Upload } from 'antd';
// javascript library for dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Progress, Row, UncontrolledTooltip } from "reactstrap";
// core selectors
import { getDataByPositionToUpdate, getInitialValuesUpdate } from 'selectors/newPosition';

const { Dragger } = Upload;

/**
 * Componente para el formulario de la modificacion de una posicion
 * con personal
 * @param {*} props
 * @returns
 */
const StaffForm = props => {

  const {
    data,
    initialValues,
    title,
    subtitle,
    onBack,
    onSearch,
    onCancel,
    onUpdate,
    onFileList,
    uploading,
    documentId,
    urlBase,
  } = props;

  const uploadProps = {
    name: 'file',
    multiple: true,
    action: `${urlBase}/document-system/upload-files/${documentId}`,
    onChange: onFileList,
  };

  /**
   * Funcion para las validaciones de los campos de los formularios
   * recibe como parametro los valores de cada campo del formulario
   * retorna un json con los errores detectados para mostrarlos en el formulario
   * @param {*} values
   * @returns
   */
  const validate = values => {
    const errors = {};
    if (!values.idPositionUser || values.idPositionUser === "0")
      errors.idPositionUser = "Debes ingresar el id de la posición.";
    if (Object.keys(data).length) {
      if (!values.user || values.user === "0")
        errors.user = "Debes seleccionar un colaborador";
      if (!values.idPositionName || values.idPositionName === "0")
        errors.idPositionName = "Debes seleccionar una posición";
      if (!values.changeRequestDate)
        errors.changeRequestDate = "Debes seleccionar una fecha."
      if (moment(values.changeRequestDate).format("YYYY-MM-DD") === "Invalid date")
        errors.changeRequestDate = "Debes seleccionar una fecha valida."
      if (!values.idCareerLevel || values.idCareerLevel === "0")
        errors.idCareerLevel = "Debes seleccionar un nivel de carrera.";
      if (!values.idPositionManager || values.idPositionManager === "0")
        errors.idPositionManager = "Debes seleccionar un gerente a reportar.";
      if (!values.idOrgUnit || values.idOrgUnit === "0")
        errors.idOrgUnit = "Debes seleccionar una unidad organizacional.";
      if (!values.idCeco || values.idCeco === "0")
        errors.idCeco = "Debes seleccionar un centro de costo.";
      if (!values.idPersonalArea || values.idPersonalArea === "0")
        errors.idPersonalArea = "Debes seleccionar un área personal.";
      if (values.haveEPM === "0")
        errors.haveEPM = "Debes seleccionar si tiene EPM.";
      if (values.isManager === "0")
        errors.isManager = "Debes seleccionar si tiene EPM.";
      if (values.productivity === "0")
        errors.productivity = "Debes seleccionar si tiene productividad.";
      if (!values.localRegionalType || values.localRegionalType === "0")
        errors.localRegionalType = "Debes seleccionar si tiene productividad.";
      if (!values.fixedPercent || values.fixedPercent === "000") {
        errors.fixedPercent = "Debes seleccionar el % fijo.";
        errors.variablePercent = "Debes seleccionar el % fijo.";
      }
    }
    return errors;
  };

  const zfill = (number, width) => {
    const numberOutput = Math.abs(number); /* Valor absoluto del número */
    const { length } = number.toString(); /* Largo del número */
    const zero = "0"; /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return (`-${numberOutput.toString()}`);
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return (`-${zero.repeat(width - length)}${numberOutput.toString()}`);
      } else {
        return ((zero.repeat(width - length)) + numberOutput.toString());
      }
    }
  };

  return (
    <Row>
      <div className="col">
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={Object.keys(data).length ? onUpdate : onSearch}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit, values, submitting, validating, valid }) => (
                  <FormRS onSubmit={handleSubmit}>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idPositionUser">Número de posición</Label>
                          <Field name="idPositionUser">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="number"
                                  placeholder="Ejemplo: 70009999"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      {
                        Object.keys(data).length ?
                          <>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="user">Colaborador</Label>
                                <Field name="user">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        placeholder="Colaborador"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option key="0" value="0">Seleccione un Colaborador</option>
                                        {
                                          data.collaborators.map((row, key) => {
                                            return (
                                              <option key={key} value={row.IdEmployee}>{row.NameEmployee}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="idPositionName">Nombre de la posición</Label>
                                <Field name="idPositionName">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        placeholder="Nombre de la posición"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option key="0" value="0">Seleccione una posición</option>
                                        {
                                          data.positions.map((row, key) => {
                                            return (
                                              <option key={key} value={row.idPosition}>{row.position}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </>
                          :
                          null
                      }
                    </div>
                    {
                      Object.keys(data).length ?
                        <>
                          <div className="form-row align-items-center">
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="changeRequestDate">Fecha que rige el cambio</Label>
                                <span
                                  id="changeRequestDate"
                                  className="btn-inner--icon mr-1"
                                  style={{
                                    display: "inlineBlock",
                                    marginLeft: "0.5rem"
                                  }}
                                >
                                  <i className="fas fa-question" />
                                </span>
                                <Field name="changeRequestDate" >
                                  {({ input, meta }) => (
                                    <>
                                      <ReactDatetime
                                        {...input}
                                        inputProps={{
                                          placeholder: "Elija una fecha",
                                          className: !meta.error && meta.modified && meta.touched ? "is-valid form-control" : meta.error && meta.touched ? "is-invalid form-control" : "form-control"
                                        }}
                                        timeFormat={false}
                                      />
                                      {
                                        meta.error && meta.touched && <span style={{
                                          marginTop: '0.25rem',
                                          fontSize: '80%',
                                          color: '#fb6340'
                                        }}>{meta.error}</span>
                                      }
                                    </>
                                  )}
                                </Field>
                                <UncontrolledTooltip
                                  delay={0}
                                  placement="top"
                                  target="changeRequestDate"
                                >
                                  Digite la fecha en la que entra a regir el cambio (1° del mes siguiente al actual).
                            </UncontrolledTooltip>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="idCareerLevel">Nivel de carrera</Label>
                                <Field name="idCareerLevel">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        placeholder="Nivel de carrera"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option key="0" value="0">Seleccione un nivel de carrera</option>
                                        {
                                          data.careerLevels.map((row, key) => {
                                            return (
                                              <option key={key} value={row.idCareerLevel}>{row.careerLevel}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="idPositionManager">Gerente a quien reporta</Label>
                                <Field name="idPositionManager">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        placeholder="Gerente a quien reporta"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option key="0" value="0">Seleccione un Gerente a quien reportar</option>
                                        {
                                          data.collaborators.map((row, key) => {
                                            return (
                                              <option key={key} value={row.PositionEmployee}>{row.NameEmployee}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </div>
                          <div className="form-row align-items-center">
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="idOrgUnit">Unidad Organizacional</Label>
                                <Field name="idOrgUnit">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        placeholder="Unidad Organizacional"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option key="0" value="0">Seleccione una unidad organizacional</option>
                                        {
                                          data.organizationalUnits.map((row, key) => {
                                            return (
                                              <option key={key} value={row.idOrganizationalUnit}>{row.organizationalUnit}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="idCeco">Centro de Costo</Label>
                                <Field name="idCeco">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        placeholder="Centro de Costo"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        disabled
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option key="0" value="0">Seleccione un centro de costo</option>
                                        {
                                          data.cecos.map((row, key) => {
                                            return (
                                              <option key={key} value={row.idCeco}>{row.ceco}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="idPersonalArea">Área de Personal</Label>
                                <Field name="idPersonalArea">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        placeholder="Área de Personal"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option key="0" value="0">Seleccione un área de personal</option>
                                        {
                                          data.personalAreas.map((row, key) => {
                                            return (
                                              <option key={key} value={row.idPersonalArea}>{row.personalArea}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </div>
                          <div className="form-row align-items-center">
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="haveEPM">¿Tiene EPM?</Label>
                                <Field name="haveEPM">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option value="0">Seleccione una opción</option>
                                        <option value="001">Sí</option>
                                        <option value="002">No</option>
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="isManager">¿Es Gerente?</Label>
                                <Field name="isManager">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option value="0">Seleccione una opción</option>
                                        <option value="001">Sí</option>
                                        <option value="002">No</option>
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="productivity">Productividad</Label>
                                <Field name="productivity">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option value="0">Seleccione una opción</option>
                                        <option value="001">Sí</option>
                                        <option value="002">No</option>
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </div>
                          <div className="form-row align-items-center">
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="localRegionalType">Local - Regional (PLA)</Label>
                                <Field name="localRegionalType">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option value="0">Seleccione una opción</option>
                                        <option value="001">Local</option>
                                        <option value="002">Regional</option>
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="fixedPercent">% Pago Fijo</Label>
                                <span
                                  id="fixedPercent"
                                  className="btn-inner--icon mr-1"
                                  style={{
                                    display: "inlineBlock",
                                    marginLeft: "0.5rem"
                                  }}
                                >
                                  <i className="fas fa-question" />
                                </span>
                                <Field name="fixedPercent">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option value="000">Seleccione una opción</option>
                                        {
                                          data.fixedPercents.map((row, key) => {
                                            return (
                                              <option key={key} value={row.keyFixedPercent}>{row.fixedPercent}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                                <UncontrolledTooltip
                                  delay={0}
                                  placement="top"
                                  target="fixedPercent"
                                >
                                  De acuerdo al Plan de Compensación
                            </UncontrolledTooltip>
                              </FormGroup>
                            </Col>
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="variablePercent">% Pago Variable</Label>
                                <Field name="variablePercent">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="select"
                                        value={values.fixedPercent !== "000" ? zfill("020" - values.fixedPercent, 3) : "000"}
                                        disabled
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option value="000">
                                          {
                                            values.fixedPercent === "020" ? '0%' : 'Seleccione un porcentaje fijo'
                                          }
                                        </option>
                                        {
                                          data.variablePercents.map((row, key) => {
                                            return (
                                              <option key={key} value={row.keyVariablePercent}>{row.variablePercent}</option>
                                            )
                                          })
                                        }
                                      </Input>
                                      {
                                        meta.error && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </div>
                          <div className="form-row align-items-center">
                            <Col className="mb-3" sm="12" md="12">
                              <FormGroup>
                                <Label for="commentary">Comentarios (500)</Label>
                                <Field name="commentary">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="textarea"
                                        placeholder="Escriba aquí si tiene algún comentario."
                                        maxLength={500}
                                        rows="3"
                                        resize="none"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      />
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </div>
                          <div className="form-row align-items-center">
                            <Col className="mb-3" sm="12" md="12">
                              <FormGroup>
                                <Label for="files">Adjuntar Archivos</Label>
                                <Dragger {...uploadProps}>
                                  <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                  </p>
                                  <p className="ant-upload-text">Suelte los archivos aquí o haga clic para cargar.</p>
                                  <p className="ant-upload-hint">
                                    Cargar todos aquellos archivos recolectados como muestra de evidencia de la solicitud que está realizando.
                                  </p>
                                </Dragger>
                              </FormGroup>
                            </Col>
                          </div>
                        </>
                        :
                        null
                    }
                    {
                      submitting ?
                        <Progress animated max="100" value="100" color="info" />
                        :
                        null
                    }
                    {
                      !Object.keys(data).length ?
                        <div className="form-row align-items-center">
                          <Col className="mb-3" sm="12" md="3">
                            <Button
                              disabled={submitting}
                              className="btn-icon"
                              block
                              color="info"
                              type="button"
                              onClick={onBack}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-arrow-left" />
                              </span>
                              <span className="btn-inner--text">Volver</span>
                            </Button>
                          </Col>
                          <Col className="mb-3" sm="12" md="3">
                            <Button
                              disabled={!valid || submitting || validating}
                              className="btn-icon"
                              block
                              color="info"
                              type="submit"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-search" />
                              </span>
                              <span className="btn-inner--text">Buscar</span>
                            </Button>
                          </Col>
                        </div>
                        :
                        <div className="form-row align-items-center">
                          <Col className="mb-3" sm="12" md="3">
                            <Button
                              disabled={submitting || uploading}
                              className="btn-icon"
                              block
                              color="info"
                              type="button"
                              onClick={onCancel}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-window-close" />
                              </span>
                              <span className="btn-inner--text">Cancelar</span>
                            </Button>
                          </Col>
                          <Col className="mb-3" sm="12" md="3">
                            <Button
                              disabled={submitting || uploading}
                              className="btn-icon"
                              block
                              color="info"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-save" />
                              </span>
                              <span className="btn-inner--text">Modificar posición</span>
                            </Button>
                          </Col>
                        </div>
                    }
                  </FormRS>
                )}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </Row >
  );
};

StaffForm.defaultProps = {
  data: {},
  initialValues: {}
}

StaffForm.propTypes = {
  data: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onFileList: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
};

/**
 * Funcion para mapear el state de la aplicacion a los props del componente
 * recibe de parametro el estado de la aplicacion
 * @param {*} state
 */
const mapStateToProps = state => ({
  data: getDataByPositionToUpdate(state),
  initialValues: getInitialValuesUpdate(state)
});

export default withRouter(connect(mapStateToProps, null)(StaffForm));