/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
// core antd
// import { Tabs } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
} from "reactstrap";
// core selectors
import { getAmortizacion } from "selectors/Sales/pricing.jsx";

// const { TabPane } = Tabs;

const OfferDetail = (props) => {
  const {
    title,
    subtitle,
    type,
    offer: { opportunityNumber, wayPay, state },
    dataLoaded: {
      datas,
      load,
      // typeUpload
    },
    ajustOffer: { ajust, references },
    logs,
    ajustType,
    editValues,
    equipmentsBase,
    servicesTss,
    spareParts,
    onBack,
    onEdit,
    onExport,
    onImport,
    onFinish,
    onChange,
    onReject,
    onUpdate,
    onSaveChange,
    onCancelUpload,
  } = props;

  let equipmentsLoad = [];
  let servicesLoad = [];
  let sparePartsLoad = [];
  const [viewEquipment, setViewEquipment] = useState(false);
  const [viewServices, setViewServices] = useState(false);
  const [viewSpareParts, setViewSpareParts] = useState(false);
  const [tabKey, setTabKey] = useState("2");

  if (load) {
    // if (typeUpload === "equipments") {
    for (const row of equipmentsBase) {
      const json = Object.assign({}, row);
      const [element] = datas.equipments.filter(
        (ele) => ele["#REF"] === row.id
      );
      if (element) {
        if (type === 1) {
          json.unitCost = element["Unitario"];
          json.totalCost = json.quantity * element["Unitario"];
          json.byServices = element["Tipo de Servicio"] * 100; //.toString();
          json.provision = element["Provisión"];
          json.totalPrice =
            json.totalCost * (json.byServices / 100) * json.quantityYears +
            json.provision;
          json.payment = json.totalPrice / (json.quantityYears * 12);
        } else if (type === 2) {
          json.unitCost = element["Unitario"];
          json.totalCost = json.quantity * element["Unitario"];
          json.byServices = element["Tipo de Servicio"] * 100; //.toString();
          json.byServicesRemaining = element["By Svcs Restante"] * 100;
          json.shippingPercent = element["% Shipping"] * 100;
          json.upliftPercent = element["% Uplift"] * 100;
          json.finPercent = element["% Fin"] * 100;
          json.provision = element["Provisión"];
          json.totalPriceSC =
            json.totalCost *
              (json.byServices / 100) *
              json.quantityYearsWarranty +
            json.totalCost *
              json.byServicesRemaining *
              json.quantityYearsRemaining;
          json.shippingCost = json.totalPriceSC * (json.shippingPercent / 100);
          json.totalShippingLanded = json.totalPriceSC + json.shippingCost;
          json.upliftCost =
            json.totalShippingLanded / (1 - json.upliftPercent / 100) -
            json.totalShippingLanded;
          json.totalUplift = json.totalShippingLanded + json.upliftCost;
          json.finCost = json.totalUplift * (json.finPercent / 100);
          json.totalPrice = json.totalUplift + json.finCost + json.provision;
          json.payment = json.totalPrice / (json.quantityYears * 12);
        }
      }
      equipmentsLoad.push(json);
    }
    // } else if (typeUpload === "services") {
    for (const row of servicesTss) {
      const json = Object.assign({}, row);
      const [element] = datas.services.filter((ele) => ele["#REF"] === row.id);
      if (element) {
        json.unitCost = element["Unitario"];
        json.totalCost = json.quantityHours * element["Unitario"];
        json.upliftPercent = element["% Uplift"] * 100;
        json.finPercent = element["% Fin"] * 100;
        json.viatic = element["Viáticos"];
        json.provision = element["Provisión"];
        json.upliftCost =
          json.totalCost / (1 - json.upliftPercent / 100) - json.totalCost;
        json.totalUplift = json.totalCost + json.upliftCost;
        json.finCost = json.totalUplift * (json.finPercent / 100);
        json.totalPrice =
          json.totalUplift + json.finCost + json.viatic + json.provision;
        json.payment = json.totalPrice / (json.quantityYears * 12);
        servicesLoad.push(json);
      }
    }
    // } else if (typeUpload === "spareParts") {
    const wayPayNum =
      wayPay === "Mensual"
        ? 12
        : wayPay === "Bimensual"
        ? 6
        : wayPay === "Trimestral"
        ? 4
        : wayPay === "Cuatrimestral"
        ? 3
        : wayPay === "Semestral"
        ? 2
        : 1;
    for (const row of spareParts) {
      const json = Object.assign({}, row);
      const [element] = datas.spareParts.filter(
        (ele) => ele["#REF"] === row.id_CalculoSparePartes
      );
      if (element) {
        json.unitCost = element["Unitario"];
        json.totalCost = json.quantity * element["Unitario"];
        json.shippingPercent = element["% Shipping"] * 100;
        json.upliftPercent = element["% Uplift"] * 100;
        json.finPercent = element["% Fin"] * 100;
        json.provision = element["Provisión"];
        json.interest = element["Tasa"] * 100;
        json.shippingCost = json.totalCost * (json.shippingPercent / 100);
        json.totalShippingLanded = json.totalCost + json.shippingCost;
        json.upliftCost =
          json.totalShippingLanded / (1 - json.upliftPercent / 100) -
          json.totalShippingLanded;
        json.totalUplift = json.totalShippingLanded + json.upliftCost;
        json.finCost = json.totalUplift * (json.finPercent / 100);
        json.totalPrice = json.totalUplift + json.finCost + json.provision;
        const items = getAmortizacion(
          json.totalPrice,
          json.interest,
          json.quantityYears * wayPayNum,
          wayPay,
          "anual"
        );
        json.payment = items[0][3];
        sparePartsLoad.push(json);
      }
    }
    // }
  }

  const changeTab = (value) => {
    setTabKey("2");
  };

  const onchange = (type) => {
    if (type === "equipments") setViewEquipment(!viewEquipment);
    else if (type === "services") setViewServices(!viewServices);
    else if (type === "spareParts") setViewSpareParts(!viewSpareParts);
  };

  const numberWithCommas = (x) => {
    if (x >= 0)
      return `$${parseFloat(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    else return null;
  };

  const equipmentsTotalSum = (equipments) => {
    equipments.map((row) => {
      totalSumEquipments.totalCost += parseFloat(row.totalCost);
      totalSumEquipments.totalPriceSC += parseFloat(row.totalPriceSC);
      totalSumEquipments.shippingCost += parseFloat(row.shippingCost);
      totalSumEquipments.totalShippingLanded += parseFloat(
        row.totalShippingLanded
      );
      totalSumEquipments.upliftCost += parseFloat(row.upliftCost);
      totalSumEquipments.totalUplift += parseFloat(row.totalUplift);
      totalSumEquipments.finCost += parseFloat(row.finCost);
      totalSumEquipments.provision += parseFloat(row.provision);
      totalSumEquipments.totalPrice += parseFloat(row.totalPrice);
      totalSumEquipments.payment += parseFloat(row.payment);
      return row;
    });
  };

  const servicesTotalSum = (services) => {
    services.map((row) => {
      totalSumServices.totalCost += parseFloat(row.totalCost);
      totalSumServices.upliftCost += parseFloat(row.upliftCost);
      totalSumServices.totalUplift += parseFloat(row.totalUplift);
      totalSumServices.finCost += parseFloat(row.finCost);
      totalSumServices.viatic += parseFloat(row.viatic);
      totalSumServices.provision += parseFloat(row.provision);
      totalSumServices.totalPrice += parseFloat(row.totalPrice);
      totalSumServices.payment += parseFloat(row.payment);
      return row;
    });
  };

  const sparePartsTotalSum = (spareParts) => {
    console.log(spareParts);
    if (spareParts.length)
      spareParts.map((row) => {
        totalSumSpareParts.totalCost += parseFloat(row.totalCost);
        totalSumSpareParts.shippingCost += parseFloat(row.shippingCost);
        totalSumSpareParts.totalShippingLanded += parseFloat(
          row.totalShippingLanded
        );
        totalSumSpareParts.upliftCost += parseFloat(row.upliftCost);
        totalSumSpareParts.totalUplift += parseFloat(row.totalUplift);
        totalSumSpareParts.finCost += parseFloat(row.finCost);
        totalSumSpareParts.provision += parseFloat(row.provision);
        totalSumSpareParts.totalPrice += parseFloat(row.totalPrice);
        totalSumSpareParts.payment += parseFloat(row.payment);
        return row;
      });
  };

  const totalSumEquipments = {
    totalCost: 0,
    totalPriceSC: 0,
    shippingCost: 0,
    totalShippingLanded: 0,
    upliftCost: 0,
    totalUplift: 0,
    finCost: 0,
    provision: 0,
    totalPrice: 0,
    payment: 0,
  };

  const totalSumServices = {
    totalCost: 0,
    upliftCost: 0,
    totalUplift: 0,
    finCost: 0,
    viatic: 0,
    provision: 0,
    totalPrice: 0,
    payment: 0,
  };

  const totalSumSpareParts = {
    totalCost: 0,
    shippingCost: 0,
    totalShippingLanded: 0,
    upliftCost: 0,
    totalUplift: 0,
    finCost: 0,
    provision: 0,
    totalPrice: 0,
    payment: 0,
  };

  if (load) {
    equipmentsTotalSum(equipmentsLoad);
  } else {
    equipmentsTotalSum(equipmentsBase);
  }

  if (load) {
    servicesTotalSum(servicesLoad);
  } else {
    servicesTotalSum(servicesTss);
  }

  if (load) {
    sparePartsTotalSum(sparePartsLoad);
  } else {
    sparePartsTotalSum(spareParts);
  }

  // servicesTss.map((row) => {
  //   totalSumServices.totalCost += parseFloat(row.totalCost);
  //   totalSumServices.upliftCost += parseFloat(row.upliftCost);
  //   totalSumServices.totalUplift += parseFloat(row.totalUplift);
  //   totalSumServices.finCost += parseFloat(row.finCost);
  //   totalSumServices.viatic += parseFloat(row.viatic);
  //   totalSumServices.provision += parseFloat(row.provision);
  //   totalSumServices.totalPrice += parseFloat(row.totalPrice);
  //   totalSumServices.payment += parseFloat(row.payment);
  //   return row;
  // });

  // spareParts.map((row) => {
  //   totalSumSpareParts.totalCost += parseFloat(row.totalCost);
  //   totalSumSpareParts.shippingCost += parseFloat(row.shippingCost);
  //   totalSumSpareParts.totalShippingLanded += parseFloat(row.totalShippingLanded);
  //   totalSumSpareParts.upliftCost += parseFloat(row.upliftCost);
  //   totalSumSpareParts.totalUplift += parseFloat(row.totalUplift);
  //   totalSumSpareParts.finCost += parseFloat(row.finCost);
  //   totalSumSpareParts.provision += parseFloat(row.provision);
  //   totalSumSpareParts.totalPrice += parseFloat(row.totalPrice);
  //   totalSumSpareParts.payment += parseFloat(row.payment);
  //   return row;
  // });

  const renderEquipmentCalcs = (name, cols, rows) => (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md="8">
            <h5 className="h3 mb-0 text-white">{name}</h5>
          </Col>
          <Col xs="12" md="4">
            <Row>
              <Col
                className="d-flex align-items-center justify-content-end"
                xs="12"
                md="12"
              >
                <Button
                  outline
                  size="sm"
                  color="info"
                  className="btn-icon btn-3"
                  onClick={() => onchange("equipments")}
                >
                  <span className="btn-inner--icon mr-1">
                    <i
                      className={viewEquipment ? "fas fa-times" : "fas fa-plus"}
                    />
                  </span>
                  <span className="btn-inner--text">
                    {viewEquipment ? "Ver menos" : "Ver más"}
                  </span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-dark table-flush" responsive>
        <thead className="thead-dark">
          <tr>
            {cols.map(
              (row, key) =>
                row.hide === false && (
                  <th
                    key={key}
                    style={{ whiteSpace: "normal", textAlign: "center" }}
                  >
                    {row.name}
                  </th>
                )
            )}
            {state === 0 && <th />}
          </tr>
        </thead>
        <tbody className="list">
          {rows.length
            ? rows.slice(0, viewEquipment ? rows.length : 2).map((row, key) => (
                <tr key={key}>
                  <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                    {row.id}
                  </th>
                  <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                    {row.typeModel}
                  </th>
                  <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                    {row.quantity}
                  </th>
                  {ajustType === "equipments" && row.id === editValues.id ? (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <Input
                        id="unitCost"
                        style={{
                          backgroundColor: "hsla(0,0%,100%,.1)",
                          border: "1px solid #1394b6",
                          width: "-webkit-fill-available",
                        }}
                        type="number"
                        // placeholder={row.placeholder}
                        value={editValues["unitCost"]}
                        onChange={(e) => onChange(e)}
                        min={0}
                        // step=".1"
                        // max={100}
                      />
                    </th>
                  ) : (
                    <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                      {numberWithCommas(row.unitCost)}
                    </th>
                  )}
                  <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                    {numberWithCommas(row.totalCost)}
                  </th>
                  <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                    {row.quantityYears}
                  </th>
                  {ajustType === "equipments" && row.id === editValues.id ? (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <Input
                        id="byServices"
                        style={{
                          backgroundColor: "hsla(0,0%,100%,.1)",
                          border: "1px solid #1394b6",
                          width: "-webkit-fill-available",
                        }}
                        type="number"
                        // placeholder={row.placeholder}
                        value={editValues["byServices"]}
                        onChange={(e) => onChange(e)}
                        min={0}
                        step=".1"
                        max={100}
                      />
                    </th>
                  ) : (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      {row.byServices.toFixed(2)}%
                    </th>
                  )}
                  {type === 2 && (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      {row.quantityYearsWarranty}
                    </th>
                  )}
                  {type === 2 ? (
                    ajustType === "equipments" && row.id === editValues.id ? (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        <Input
                          id="byServicesRemaining"
                          style={{
                            backgroundColor: "hsla(0,0%,100%,.1)",
                            border: "1px solid #1394b6",
                            width: "-webkit-fill-available",
                          }}
                          type="number"
                          // placeholder={row.placeholder}
                          value={editValues["byServicesRemaining"]}
                          onChange={(e) => onChange(e)}
                          min={0}
                          step=".1"
                          max={100}
                        />
                      </th>
                    ) : (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {row.byServicesRemaining.toFixed(2)}%
                      </th>
                    )
                  ) : null}
                  {type === 2 && (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      {row.quantityYearsRemaining}
                    </th>
                  )}
                  {type === 2 && (
                    <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                      {numberWithCommas(row.totalPriceSC)}
                    </th>
                  )}
                  {type === 2 ? (
                    ajustType === "equipments" && row.id === editValues.id ? (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        <Input
                          id="shippingPercent"
                          style={{
                            backgroundColor: "hsla(0,0%,100%,.1)",
                            border: "1px solid #1394b6",
                            width: "-webkit-fill-available",
                          }}
                          type="number"
                          // placeholder={row.placeholder}
                          value={editValues["shippingPercent"]}
                          onChange={(e) => onChange(e)}
                          min={0}
                          step=".1"
                          max={100}
                        />
                      </th>
                    ) : (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {row.shippingPercent.toFixed(2)}%
                      </th>
                    )
                  ) : null}
                  {type === 2 && (
                    <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                      {numberWithCommas(row.shippingCost)}
                    </th>
                  )}
                  {type === 2 && (
                    <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                      {numberWithCommas(row.totalShippingLanded)}
                    </th>
                  )}
                  {type === 2 ? (
                    ajustType === "equipments" && row.id === editValues.id ? (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        <Input
                          id="upliftPercent"
                          style={{
                            backgroundColor: "hsla(0,0%,100%,.1)",
                            border: "1px solid #1394b6",
                            width: "-webkit-fill-available",
                          }}
                          type="number"
                          // placeholder={row.placeholder}
                          value={editValues["upliftPercent"]}
                          onChange={(e) => onChange(e)}
                          min={0}
                          step=".1"
                          max={100}
                        />
                      </th>
                    ) : (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {row.upliftPercent.toFixed(2)}%
                      </th>
                    )
                  ) : null}
                  {type === 2 && (
                    <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                      {numberWithCommas(row.upliftCost)}
                    </th>
                  )}
                  {type === 2 && (
                    <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                      {numberWithCommas(row.totalUplift)}
                    </th>
                  )}
                  {type === 2 ? (
                    ajustType === "equipments" && row.id === editValues.id ? (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        <Input
                          id="finPercent"
                          style={{
                            backgroundColor: "hsla(0,0%,100%,.1)",
                            border: "1px solid #1394b6",
                            width: "-webkit-fill-available",
                          }}
                          type="number"
                          // placeholder={row.placeholder}
                          value={editValues["finPercent"]}
                          onChange={(e) => onChange(e)}
                          min={0}
                          step=".1"
                          max={100}
                        />
                      </th>
                    ) : (
                      <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {row.finPercent.toFixed(2)}%
                      </th>
                    )
                  ) : null}
                  {type === 2 && (
                    <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                      {numberWithCommas(row.finCost)}
                    </th>
                  )}
                  {ajustType === "equipments" && row.id === editValues.id ? (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <Input
                        id="provision"
                        style={{
                          backgroundColor: "hsla(0,0%,100%,.1)",
                          border: "1px solid #1394b6",
                          width: "-webkit-fill-available",
                        }}
                        type="number"
                        // placeholder={row.placeholder}
                        value={editValues["provision"]}
                        onChange={(e) => onChange(e)}
                        min={0}
                        step="50"
                        max={99999}
                      />
                    </th>
                  ) : (
                    <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                      {numberWithCommas(row.provision.toFixed(2))}
                    </th>
                  )}
                  <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                    {numberWithCommas(row.totalPrice)}
                  </th>
                  <th style={{ whiteSpace: "normal", textAlign: "right" }}>
                    {numberWithCommas(row.payment)}
                  </th>
                  {state === 0 && (
                    <th
                      key="action"
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      {ajustType === "equipments" &&
                      row.id === editValues.id ? (
                        <a
                          className="table-action table-action"
                          href="#"
                          onClick={onUpdate}
                        >
                          <i className="fas fa-check-double" />
                        </a>
                      ) : (
                        <>
                          <a
                            className="table-action table-action"
                            href="#"
                            onClick={() => onEdit(row, "equipments")}
                          >
                            <i className="fas fa-edit" />
                          </a>
                        </>
                      )}
                    </th>
                  )}
                </tr>
              ))
            : null}
          <tr>
            {cols.map(
              (col, key) =>
                col.hide === false && (
                  <th
                    key={key}
                    style={{ whiteSpace: "normal", textAlign: "right" }}
                  >
                    {totalSumEquipments[col.key]
                      ? numberWithCommas(totalSumEquipments[col.key].toFixed(2))
                      : totalSumEquipments[col.key] === 0
                      ? "$0.00"
                      : ""}
                  </th>
                )
            )}
          </tr>
        </tbody>
      </Table>
    </Card>
  );

  const renderServicesAndSparePartsCalcs = (name, cols, rows, type) => (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md="8">
            <h5 className="h3 mb-0 text-white">{name}</h5>
          </Col>
          <Col xs="12" md="4">
            <Row>
              <Col
                className="d-flex align-items-center justify-content-end"
                xs="12"
                md="12"
              >
                <Button
                  outline
                  size="sm"
                  color="info"
                  className="btn-icon btn-3"
                  onClick={() => onchange(type)}
                >
                  <span className="btn-inner--icon mr-1">
                    <i
                      className={
                        type === "services"
                          ? viewServices
                            ? "fas fa-times"
                            : "fas fa-plus"
                          : viewSpareParts
                          ? "fas fa-times"
                          : "fas fa-plus"
                      }
                    />
                  </span>
                  <span className="btn-inner--text">
                    {type === "services"
                      ? viewServices
                        ? "Ver menos"
                        : "Ver más"
                      : viewSpareParts
                      ? "Ver menos"
                      : "Ver más"}
                  </span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-dark table-flush" responsive>
        <thead className="thead-dark">
          <tr>
            {cols.map(
              (row, key) =>
                row.hide === false && (
                  <th
                    key={key}
                    style={{ whiteSpace: "normal", textAlign: "center" }}
                  >
                    {row.name}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody className="list">
          {rows
            .slice(
              0,
              type === "services"
                ? viewServices
                  ? rows.length
                  : 2
                : viewSpareParts
                ? rows.length
                : 2
            )
            .map((row, key) => (
              <tr key={key}>
                {cols.map((col, key) =>
                  col.key === "action" ? (
                    col.hide === false && (
                      <th
                        key="action"
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        {ajustType === type &&
                        (row.id === editValues.id ||
                          row.id_CalculoSparePartes === editValues.id) ? (
                          <a
                            className="table-action table-action"
                            href="#"
                            onClick={onUpdate}
                          >
                            <i className="fas fa-check-double" />
                          </a>
                        ) : (
                          <>
                            <a
                              className="table-action table-action"
                              href="#"
                              onClick={() => onEdit(row, type)}
                            >
                              <i className="fas fa-edit" />
                            </a>
                          </>
                        )}
                      </th>
                    )
                  ) : ajustType === type &&
                    (row.id === editValues.id ||
                      row.id_CalculoSparePartes === editValues.id) &&
                    col.edit ? (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <Input
                        id={col.key}
                        style={{
                          backgroundColor: "hsla(0,0%,100%,.1)",
                          border: "1px solid #1394b6",
                          width: "-webkit-fill-available",
                        }}
                        type="number"
                        // placeholder={row.placeholder}
                        value={editValues[col.key]}
                        onChange={(e) => onChange(e)}
                        min={0}
                        step={col.step}
                        max={99999}
                      />
                    </th>
                  ) : (
                    <th
                      key={key}
                      style={{
                        whiteSpace: "normal",
                        textAlign: `${col.number ? "right" : "center"}`,
                      }}
                    >
                      {col.number
                        ? numberWithCommas(row[col.key])
                        : `${
                            col.percent
                              ? `${row[col.key].toFixed(2)}%`
                              : `${row[col.key]}`
                          }`}
                    </th>
                  )
                )}
              </tr>
            ))}
          <tr>
            {cols.map(
              (col, key) =>
                col.hide === false && (
                  <th
                    key={key}
                    style={{ whiteSpace: "normal", textAlign: "right" }}
                  >
                    {type === "services"
                      ? totalSumServices[col.key]
                        ? numberWithCommas(totalSumServices[col.key].toFixed(2))
                        : totalSumServices[col.key] === 0
                        ? "$0.00"
                        : ""
                      : type === "spareParts"
                      ? totalSumSpareParts[col.key]
                        ? numberWithCommas(
                            totalSumSpareParts[col.key].toFixed(2)
                          )
                        : totalSumSpareParts[col.key] === 0
                        ? "$0.00"
                        : ""
                      : null}
                  </th>
                )
            )}
          </tr>
        </tbody>
      </Table>
    </Card>
  );

  const renderResumeCalcs = () => (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12">
            <h5 className="h3 mb-0 text-white text-center">Resumen Calculos</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-dark table-flush" responsive>
        <thead className="thead-dark">
          <tr>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}>
              Servicio
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}>
              Costo Total
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}>
              Provisión
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}>
              Precio Total
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}>Cuota</th>
          </tr>
        </thead>
        <tbody className="list">
          <tr>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}>
              Equipos Base
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumEquipments["totalCost"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumEquipments["provision"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumEquipments["totalPrice"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumEquipments["payment"].toFixed(2))}
            </th>
          </tr>
          <tr>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}>
              Servicios
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumServices["totalCost"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumServices["provision"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumServices["totalPrice"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumServices["payment"].toFixed(2))}
            </th>
          </tr>
          <tr>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}>
              Partes / Spare
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumSpareParts["totalCost"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumSpareParts["provision"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumSpareParts["totalPrice"].toFixed(2))}
            </th>
            <th style={{ whiteSpace: "normal", textAlign: "right" }}>
              {numberWithCommas(totalSumSpareParts["payment"].toFixed(2))}
            </th>
          </tr>
        </tbody>
      </Table>
    </Card>
  );

  const renderAjustDetail = () => (
    <>
      <div className="form-row align-items-center">
        <Col className="mb-1" sm="12" md="3">
          <FormGroup>
            <Label for="officeHours">Monto de Provisión</Label>
            <Input
              disabled
              id="provision"
              type="number"
              defaultValue={ajust.provision}
            />
          </FormGroup>
        </Col>
        <Col className="mb-1" sm="12" md="3">
          <FormGroup>
            <Label for="officeHours">Tipo de Ajuste</Label>
            <Input
              disabled
              id="provision"
              type="text"
              defaultValue={ajust.type}
            />
          </FormGroup>
        </Col>
        <Col className="mb-1" sm="12" md="6">
          <FormGroup>
            <Label for="officeHours">Justificación</Label>
            <Input
              disabled
              id="provision"
              type="textarea"
              rows="3"
              resize="none"
              defaultValue={ajust.justification}
            />
          </FormGroup>
        </Col>
      </div>
      <ListGroup className="list my--3" flush>
        {references.map((row, key) => (
          <ListGroupItem key={key} className="px-0">
            <Row className="align-items-center">
              <Col className="col-auto">
                <img
                  alt="Referencia"
                  className="avatar rounded-circle"
                  src={require(`assets/img/theme/attachment.png`)}
                />
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">{row.name}</h4>
                <small>
                  {`${row.createdBy} - ${moment(row.createdAt)
                    .utc()
                    .utcOffset(moment().utcOffset())
                    .format("LLL")}`}
                </small>
              </div>
              <Col className="col-auto">
                <Button
                  color="info"
                  size="sm"
                  href={`${urlBase}/digital-request/download-attachment/${window.btoa(
                    row.path
                  )}/${window.btoa(row.name)}`}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-file-download" />
                  </span>
                  <span className="btn-inner--text">Descargar</span>
                </Button>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </>
  );

  // console.log(logs);

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="12" md="7">
            <h5 className="surtitle">{title}</h5>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
          <Col xs="12" md="5">
            {state === 0 && (
              <Row>
                {tabKey === "2" ? (
                  load ? (
                    <>
                      <Col
                        className="d-flex align-items-center justify-content-end"
                        xs="4"
                      >
                        <Button
                          block
                          size="sm"
                          color="danger"
                          className="btn-icon btn-3"
                          onClick={onCancelUpload}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-times" />
                          </span>
                          <span className="btn-inner--text">Cancelar</span>
                        </Button>
                      </Col>
                      <Col
                        className="d-flex align-items-center justify-content-end"
                        xs="4"
                      >
                        <Button
                          block
                          size="sm"
                          color="success"
                          className="btn-icon btn-3"
                          onClick={() =>
                            onSaveChange({
                              equipmentsLoad,
                              servicesLoad,
                              sparePartsLoad,
                            })
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-save" />
                          </span>
                          <span className="btn-inner--text">Guardar</span>
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col
                        className="d-flex align-items-center justify-content-end"
                        xs="4"
                      >
                        <Button
                          block
                          size="sm"
                          color="danger"
                          className="btn-icon btn-3"
                          onClick={() =>
                            onExport(`Tablas_Calculos_Opp${opportunityNumber}`)
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-file-download" />
                          </span>
                          <span className="btn-inner--text">Exportar</span>
                        </Button>
                      </Col>
                      <Col
                        className="d-flex align-items-center justify-content-end"
                        xs="4"
                      >
                        <Button
                          block
                          size="sm"
                          color="success"
                          className="btn-icon btn-3"
                          onClick={() =>
                            onImport(`Tablas_Calculos_Opp${opportunityNumber}`)
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-file-upload" />
                          </span>
                          <span className="btn-inner--text">Importar</span>
                        </Button>
                      </Col>
                    </>
                  )
                ) : null}
                {logs.some((row) => row.ajustE || row.ajustS || row.ajustP) ? (
                  <Col
                    className="d-flex align-items-center justify-content-end"
                    xs={tabKey === "2" ? "4" : "12"}
                  >
                    <Button
                      block={tabKey === "2" ? true : false}
                      size="sm"
                      color="info"
                      className="btn-icon btn-3"
                      onClick={onFinish}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Finalizar</span>
                    </Button>
                  </Col>
                ) : (
                  <Col
                    className="d-flex align-items-center justify-content-end"
                    xs={tabKey === "2" ? "4" : "12"}
                  >
                    <Button
                      block={tabKey === "2" ? true : false}
                      size="sm"
                      color="danger"
                      className="btn-icon btn-3"
                      onClick={onReject}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Rechazar</span>
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {/* <Tabs defaultActiveKey={tabKey} onChange={changeTab}> */}
        {/* <TabPane tab="Datos del Ajuste" key="1">
            {
              renderAjustDetail()
            }
          </TabPane> */}
        {/* <TabPane tab="Tablas de Calculos" key="2"> */}
        {renderEquipmentCalcs(
          `Calculo Equipos Base`,
          [
            { key: "id", name: "#REF", hide: false },
            { key: "typeModel", name: "Equipo", hide: false },
            { key: "quantity", name: "Cantidad", hide: false },
            { key: "unitCost", name: "Unitario", hide: false },
            { key: "totalCost", name: "Costo Total", hide: false },
            { key: "quantityYears", name: "Años Servicio", hide: false },
            { key: "byServices", name: "Tipo de Servicio", hide: false },
            {
              key: "quantityYearsWarranty",
              name: "Años Garantía Fabrica",
              hide: type === 1 ? true : false,
            },
            {
              key: "byServicesRemaining",
              name: "By Svcs Restante",
              hide: type === 1 ? true : false,
            },
            {
              key: "quantityYearsRemaining",
              name: "Años Restantes",
              hide: type === 1 ? true : false,
            },
            {
              key: "totalPriceSC",
              name: "Precio Total",
              hide: type === 1 ? true : false,
            },
            {
              key: "shippingPercent",
              name: "% Shipping",
              hide: type === 1 ? true : false,
            },
            {
              key: "shippingCost",
              name: "$ Shipping",
              hide: type === 1 ? true : false,
            },
            {
              key: "totalShippingLanded",
              name: "Total w/Shipping Landed",
              hide: type === 1 ? true : false,
            },
            {
              key: "upliftPercent",
              name: "% Uplift",
              hide: type === 1 ? true : false,
            },
            {
              key: "upliftCost",
              name: "$ Uplift",
              hide: type === 1 ? true : false,
            },
            {
              key: "totalUplift",
              name: "Total w/Uplift",
              hide: type === 1 ? true : false,
            },
            {
              key: "finPercent",
              name: "% Fin",
              hide: type === 1 ? true : false,
            },
            { key: "finCost", name: "$ Fin", hide: type === 1 ? true : false },
            { key: "provision", name: "Provisión", hide: false },
            { key: "totalPrice", name: "Precio Total", hide: false },
            { key: "payment", name: "Cuota", hide: false },
          ],
          load ? equipmentsLoad : equipmentsBase,
          totalSumEquipments
        )}
        {renderServicesAndSparePartsCalcs(
          "Calculo Servicios",
          [
            {
              key: "id",
              name: "#REF",
              number: false,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "service",
              name: "Servicio",
              number: false,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "quantityHours",
              name: "Cantidad Horas",
              number: false,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "unitCost",
              name: "Unitario",
              number: true,
              percent: false,
              edit: true,
              hide: false,
            },
            {
              key: "quantityYears",
              name: "Cantidad Años",
              number: false,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "totalCost",
              name: "Costo Total",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "upliftPercent",
              name: "% Uplift",
              number: false,
              percent: true,
              edit: true,
              hide: false,
              step: "0.1",
            },
            {
              key: "upliftCost",
              name: "$ Uplift",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "totalUplift",
              name: "Total w/Uplift",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "finPercent",
              name: "% Fin",
              number: false,
              percent: true,
              edit: true,
              hide: false,
              step: "0.1",
            },
            {
              key: "finCost",
              name: "$ Fin",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "viatic",
              name: "Viáticos",
              number: true,
              percent: false,
              edit: true,
              hide: false,
              step: "50",
            },
            {
              key: "provision",
              name: "Provisión",
              number: true,
              percent: false,
              edit: true,
              hide: false,
              step: "50",
            },
            {
              key: "totalPrice",
              name: "Precio Total",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "payment",
              name: "Cuota",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "action",
              name: "",
              number: false,
              percent: false,
              edit: false,
              hide: state === 0 ? false : true,
            },
          ],
          load ? servicesLoad : servicesTss,
          "services"
        )}
        {renderServicesAndSparePartsCalcs(
          "Calculo Partes / Spare",
          [
            {
              key: "id_CalculoSparePartes",
              name: "#REF",
              number: false,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "partNumber",
              name: "Partes / Spare",
              number: false,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "quantity",
              name: "Cantidad",
              number: false,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "unitCost",
              name: "Unitario",
              number: true,
              percent: false,
              edit: true,
              hide: false,
            },
            {
              key: "totalCost",
              name: "Costo Total",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "quantityYears",
              name: "Años Servicio",
              hide: false,
              edit: false,
            },
            {
              key: "shippingPercent",
              name: "% Shipping",
              number: false,
              percent: true,
              edit: true,
              hide: false,
              step: "0.1",
            },
            {
              key: "shippingCost",
              name: "$ Shipping",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "totalShippingLanded",
              name: "Total w/Shipping Landed",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "upliftPercent",
              name: "% Uplift",
              number: false,
              percent: true,
              edit: true,
              hide: false,
              step: "0.1",
            },
            {
              key: "upliftCost",
              name: "$ Uplift",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "totalUplift",
              name: "Total w/Uplift",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "finPercent",
              name: "% Fin",
              number: false,
              percent: true,
              edit: true,
              step: "0.1",
              hide: false,
            },
            {
              key: "finCost",
              name: "$ Fin",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "provision",
              name: "Provisión",
              number: true,
              percent: false,
              edit: true,
              step: "50",
              hide: false,
            },
            {
              key: "totalPrice",
              name: "Precio Total",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "interest",
              name: "Tasa",
              number: false,
              percent: true,
              edit: true,
              hide: false,
              step: "0.1",
            },
            {
              key: "payment",
              name: "Cuota",
              number: true,
              percent: false,
              edit: false,
              hide: false,
            },
            {
              key: "action",
              name: "",
              number: false,
              percent: false,
              edit: false,
              hide: state === 0 ? false : true,
            },
          ],
          load ? sparePartsLoad : spareParts,
          "spareParts"
        )}
        {renderResumeCalcs()}
        {/* </TabPane> */}
        {/* </Tabs> */}
        <div className="form-row align-items-center justify-content-center">
          <Col
            className="m-2 d-flex align-items-center justify-content-center"
            sm="12"
          >
            <Button
              className="btn-icon"
              color="danger"
              onClick={onBack}
              size="md"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Regresar</span>
            </Button>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

OfferDetail.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default OfferDetail;
