import moment from "moment";
import "moment/locale/es";
moment.locale("es");

// Colors charts
const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

// options charts Bar
const optionsBar = {
  legend: {
    position: "top",
    display: false,
    // fullWidth: true,
    labels: {
      usePointStyle: false,
    },
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          },
        },
      },
    ],
  },
};

// options charts Pie
const optionsPie = {
  responsive: true,
  legend: {
    position: "top",
    labels: {
      usePointStyle: false,
    },
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const { labels } = data;
        const dataset = data.datasets[tooltipItem.datasetIndex];
        return `${labels[tooltipItem.index]} ${
          dataset.data[tooltipItem.index]
        }%`;
      },
    },
  },
};

export const getCountrys = (state) => {
  if (Object.keys(state.registerSignature).length) {
    return state.registerSignature.countrys;
  }
  return [];
};

export const getOrganizationalUnits = (state) => {
  if (Object.keys(state.registerSignature).length) {
    return state.registerSignature.organizationalsUnits;
  }
  return [];
};

export const getInitialValues = (state) => {
  if (Object.keys(state.registerSignature).length) {
    return state.registerSignature.initialValues;
  }
  return {};
};

export const getDocuments = (state) => {
  if (Object.keys(state.registerSignature).length) {
    return state.registerSignature.documents;
  }
  return [];
};

export const getCountrysSignatures = (state) => {
  if (Object.keys(state.allYearSignatures).length) {
    const { countrys } = state.allYearSignatures;
    return countrys;
  }
  return {};
};

export const getYearsSignatures = (state) => {
  if (Object.keys(state.allYearSignatures).length) {
    const { years } = state.allYearSignatures;
    return years;
  }
  return [];
};

export const getAllSignaturesByCountry = (state) => {
  const total = [];
  const titles = [];
  if (Object.keys(state.dataByDashboardSignature).length) {
    const { signatures } = state.dataByDashboardSignature;
    if (signatures.length) {
      for (const el of signatures) {
        titles.push(el.country);
        total.push(el.signatures);
      }
    }
  }
  return {
    data: {
      labels: titles,
      datasets: [
        {
          label: "Total",
          backgroundColor: colors.theme["success"],
          borderWidth: 1,
          hoverBackgroundColor: colors.theme["info"],
          hoverBorderColor: colors.theme["primary"],
          data: total,
        },
      ],
    },
    options: optionsBar,
  };
};

export const getResponseRateSginatures = (state) => {
  const data = [];
  const labels = [];
  const colors = [];
  if (Object.keys(state.dataByDashboardSignature).length) {
    const { signatures, totalSignatures } = state.dataByDashboardSignature;
    if (signatures.length) {
      for (const el of signatures) {
        data.push(((el.signatures * 100) / totalSignatures).toFixed(2));
        labels.push(el.country);
        colors.push("#" + Math.floor(Math.random() * 16777215).toString(16));
      }
    }
  }
  return {
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
        },
      ],
    },
    options: optionsPie,
  };
};

export const getAllSignatures = (state) => {
  if (Object.keys(state.dataByDashboardSignature).length) {
    const { allSignatures } = state.dataByDashboardSignature;
    const newAllSignatures = allSignatures.map((row) => {
      row.createdAt = moment(row.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("YYYY-MM-DD h:mm:ss a");
      return row;
    });
    return newAllSignatures;
  }
  return [];
};

export const getAllSignaturesMan = (state) => {
  if (Object.keys(state.dataByDashboardSignature).length) {
    let { allSignaturesMan } = state.dataByDashboardSignature;
    allSignaturesMan = allSignaturesMan.map((row) => {
      row.createdAt = moment(row.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("YYYY-MM-DD h:mm:ss a");
      return row;
    });
    return allSignaturesMan;
  }
  return [];
};

export const getAllDepartaments = (state) => {
  if (Object.keys(state.dataByDashboardSignature).length) {
    const { allSignatures, allSignaturesMan } = state.dataByDashboardSignature;
    let departaments = [
      ...allSignatures.map((row, key) => {
        return {
          value: row.id,
          label: row.organizationalUnit,
        };
      }),
      ...allSignaturesMan.map((row) => {
        return {
          value: row.id,
          label: row.organizationalUnit,
        };
      }),
    ];

    departaments = departaments
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => departaments[e])
      .map((e) => departaments[e]);

    departaments = departaments.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...departaments];
  }

  return [];
};

export const getAllDocuments = (state) => {
  if (Object.keys(state.dataByDashboardSignature).length) {
    const { allSignatures, allSignaturesMan } = state.dataByDashboardSignature;
    let documents = [
      ...allSignatures.map((row, key) => {
        return {
          value: row.id,
          label: row.documents,
        };
      }),
      ...allSignaturesMan.map((row) => {
        return {
          value: row.id,
          label: row.documents,
        };
      }),
    ];

    documents = documents
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => documents[e])
      .map((e) => documents[e]);

    documents = documents.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...documents];
  }

  return [];
};

export const getAllCountries = (state) => {
  if (Object.keys(state.dataByDashboardSignature).length) {
    const { allSignatures, allSignaturesMan } = state.dataByDashboardSignature;
    let countries = [
      ...allSignatures.map((row, key) => {
        return {
          value: row.id,
          label: row.country,
        };
      }),
      ...allSignaturesMan.map((row) => {
        return {
          value: row.id,
          label: row.country,
        };
      }),
    ];

    countries = countries
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => countries[e])
      .map((e) => countries[e]);

    countries = countries.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...countries];
  }

  return [];
};

export const getAllSignaturesByUser = (state) => {
  if (Object.keys(state.signaturesUser).length) {
    const { signatures, signaturesFlow } = state.signaturesUser;
    // let all = [...signatures, ...signaturesFlow];
    const all = [...signatures, ...signaturesFlow].sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      return 0;
    });
    return all;
  }
  return [];
};

export const getSignaturesByInSupervisorFlow = (state) => {
  if (Object.keys(state.signaturesInFlow).length) {
    const { signatures } = state.signaturesInFlow;
    return signatures.map((row) => {
      row.createdAt = moment(row.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("YYYY-MM-DD h:mm:ss a");
      return row;
    });
  }
  return [];
};

export const getSignaturesByCollaboratorFlow = (state) => {
  if (Object.keys(state.signaturesInFlowCollaborator).length) {
    const { signatures } = state.signaturesInFlowCollaborator;
    return signatures.map((row) => {
      row.createdAt = moment(row.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("YYYY-MM-DD h:mm:ss a");
      return row;
    });
  }
  return [];
};

export const getFlowLogsBySignature = (state) => {
  if (Object.keys(state.signaturesFlowLogs).length) {
    const { logs } = state.signaturesFlowLogs;
    return logs.map((row) => {
      row.createdAt = moment(row.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .fromNow();
      return row;
    });
  }
  return [];
};

export const getAllSignaturesSupervisor = (state) => {
  if (Object.keys(state.signaturesUser).length) {
    const { supervisorSig } = state.signaturesUser;
    return supervisorSig;
  }
  return [];
};

export const getAllUsersWithAccess = (state) => {
  if (Object.keys(state.usersDigitalSignature).length) {
    const { users } = state.usersDigitalSignature;
    return users;
  }
  return [];
};

export const getHomeOfficeInformation = (state) => {
  if (Object.keys(state.homeOfficeInfo).length) {
    const {
      homeOfficeInformation: [info],
    } = state.homeOfficeInfo;
    return info;
  }
  return {};
};

export const getAllEmployeesNoHomeOffice = (state) => {
  if (Object.keys(state.signaturesUser).length) {
    const { employeeNoHomeOffice } = state.signaturesUser;
    return employeeNoHomeOffice;
  }
  return [];
};
