// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useState } from "react";
// reactstrap components
import { Col, Form, Row } from "reactstrap";
import GeneralForm from "./GeneralForm";
import { getIsExternal } from "selectors/adminLayout";
import { CustomCard } from "components/Shared/Cards/CustomCard";

//#region icons
import { MdComputer } from "react-icons/md";
import { IoIosContact } from "react-icons/io";
import { FaTools } from "react-icons/fa";
import { BiSolidContact } from "react-icons/bi";
import { RiContactsFill } from "react-icons/ri";
import { MdOutlineContactPhone } from "react-icons/md";
import { MdOutlineDevicesOther } from "react-icons/md";
import { TbDeviceImac } from "react-icons/tb";
import { FaServer } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import { PiComputerTowerLight } from "react-icons/pi";
import { IoCallOutline } from "react-icons/io5";
import { SlPeople } from "react-icons/sl";
import { IoDocumentOutline } from "react-icons/io5";
import { CiServer } from "react-icons/ci";
import { IoPersonOutline } from "react-icons/io5";
//#endregion

const CardHeader = ({ setFormSelected, formSelected, isAdmin, optionsDropdowns, Toast, employees, setEmployees }) => {
  const [fields, setFields] = useState([
    {
      title: "Materiales",
      subtitle: "Creación y modificación de Materiales en SAP",
      id: "materiales",
      image: "elearning.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: MdComputer
    },
    {
      title: "Contratos - Ordenes de Servicio",
      subtitle: "Creación y modificación de Servicios en SAP",
      id: "servicios",
      image: "exam.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: IoIosContact
    },
    {
      title: "Repuestos",
      subtitle: "Creación y modificación de repuestos en SAP",
      id: "repuestos",
      image: "tool-box.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: PiComputerTowerLight
    },
    {
      title: "Clientes",
      subtitle: " Creación y modificación de Clientes en SAP",
      id: "clientes",
      image: "idea.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: IoPersonOutline,
      externalView: true
    },
    {
      title: "Proveedores",
      subtitle: "Creación de Proveedores en SAP",
      id: "proveedores",
      image: "teacher.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: SlPeople
    },
    {
      title: "Contactos",
      subtitle: "Creación de contactos en SAP",
      id: "contactos",
      image: "contacts.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: IoCallOutline,
      externalView: true
    },
    {
      title: "Materiales de Servicios (PS)",
      subtitle: "Creación y modificación de Materiales de Terceros (LEIS) en SAP",
      id: "materialesservicio",
      image: "solar-panel.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: MdOutlineDevicesOther
    },
    {
      title: "Equipos",
      subtitle: " Creación y modificación de Equipos en SAP",
      id: "equipos",
      image: "data-storage.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: TbDeviceImac
    },
    {
      title: "Ibase",
      subtitle: " Creación y modificación de Ibase en SAP",
      id: "ibase",
      image: "ibase.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: CiServer
    },
    {
      title: "Garantías",
      subtitle: "Modificación de Garantías en SAP",
      id: "garantias",
      image: "warranty.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      icon: IoDocumentOutline
    },
  ]);

  const [openArm, setOpenArm] = useState(false);

  const [typeForm, setTypeForm] = useState("");

  const handleOnClick = (type) => {
    setOpenArm(true);
    setTypeForm(type);
  };

  const handleOnBack = () => {
    setOpenArm(false);
  };

  return (
    <>
      {openArm == false ? (
        <Row>
          {fields.map((row) => (
            ((getIsExternal() && row.externalView) // => Si es external(/sales-cloud) entonces sólo muestre clientes y contactos.
              || (!getIsExternal())) // => Si no es external(/sales-cloud) entonces todos los cards.
            &&
            <>
              <CustomCard
                title={row.title}
                subtitle={row.subtitle}
                onClick={() => handleOnClick(row.id)}
                Icon={row.icon}
                xs={"6"}
                sm={"3"}
              // image={row.image}
              // colorCardSelected={row.colorCardSelected}
              // formSelected={formSelected} 
              />
            </>

            /*
                       <Col sm="14" md="3">
                         <CardMain
                           title={row.title}
                           subtitle={row.subtitle}
                           setFormSelected={() => handleOnClick(row.id)}
                           id={row.id}
                           image={row.image}
                           colorCardSelected={row.colorCardSelected}
                           formSelected={formSelected}
                         ></CardMain>
           
                       </Col> 
                       */
          ))}
        </Row>
      ) : (
        <GeneralForm
          typeForm={typeForm}
          handleOnBack={handleOnBack}
          optionsDropdowns={optionsDropdowns}
          Toast={Toast}
          employees={employees}
          setEmployees={setEmployees}
        ></GeneralForm>
      )}
    </>
  );
};

export default CardHeader;
