import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, CardHeader, FormGroup, Row } from "reactstrap";
import { useDispatch } from 'react-redux';
import ChartBar from "./ChartBar.jsx"
import ChartPie from "./ChartPie"
import Select from "react-select";
import { getDataChartCountry, getDataChartExitType, getMasterData, getDataChatExitReasons, getDataChartWords, getChartDraft } from 'actions/exitInterview';
import { Spin } from 'antd';
import ReactWordcloud from 'react-wordcloud';

const Dashboards = () => {
    var today = new Date();
    var yearToday = today.getFullYear();

    const dispatch = useDispatch();
    const [year, setYear] = useState({
        yearDonut: yearToday,
        yearExitReason: yearToday,
        yearReasonsOfExit: yearToday,
        draft: yearToday
    });
    const [loading, setLoad] = useState({
        loadingDonut: true,
        loadingExitReason: true,
        loadingReasonsOfExit: true,
        loadingWords: true,
        draft: true
    });
    const [dataCharts, setDataCharts] = useState({
        chart1: [],
        chart2: [],
        chart3: [],
        chart4: [],
        chartDraft: [],
        chartWords: [],
        masterData: [],
        countries: [],
        exitTypeData: [],
        exitReasonData: [],
        typeChart3: 1
    });
    const [stateCheck, setStateCheck] = useState(
        {
            check1: true,
            check2: false
        }
    );
    const colors = ['#DFA267', "#67AEDF", "#67DF86", "#B967DF", "#67DFBB", "#F15FC7", "#F43232", "#E7F13D", "#3DF1E1"];

    //#region  Funciones
    const onChangeYear = (who, e) => {
        setYear(prevState => ({
            ...prevState,
            [who]: e
        }))
    }
    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
            await handleOnDataChartWords();
            await getDataChartDraft();
        }
        getRowsData();
    }, [])
    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
            await getDataExitReason();
            await getDataReasonsOfExit();
            await getDataChartDraft();
        }
        getRowsData();
    }, [year])
    useEffect(() => {
        const getRowsData = async () => {
            await handleOnChartReasonCountry();
        }
        getRowsData();
    }, [dataCharts.exitReasonData])
    //cargar 4 grafito de paises segun razones 
    const handleOnChartReasonCountry = async () => {
        let vector = [];
        setLoad(prevState => ({
            ...prevState,
            loadingReasonsOfExit: true
        }))
        for (const item of dataCharts.exitReasonData) {
            vector.push({
                label: item,
                data: sortDataChartDraft(dataCharts.chart3, labelsReasonCountry, item),
                backgroundColor: colors[dataCharts.exitReasonData.indexOf(item)],
            })
        }
        setDataCharts(prevState => ({
            ...prevState,
            chart4: vector
        }));
        setLoad(prevState => ({
            ...prevState,
            loadingReasonsOfExit: false
        }))
    }
    //Funcion para traer la informacion de lo selects y el nombre de las columnas de la tabla
    const handleRowInfo = async () => {
        dispatch(getDataChartCountry({ year: year.yearDonut })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDataCharts(prevState => ({
                    ...prevState,
                    chart1: payload.data.payload.data
                }))
            } else {
                setDataCharts(prevState => ({
                    ...prevState,
                    chart1: []
                }))
            }
        })
        dispatch(getMasterData()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDataCharts(prevState => ({
                    ...prevState,
                    masterData: payload.data.payload.masterData,
                    countries: payload.data.payload.masterData.countries,
                    exitTypeData: payload.data.payload.masterData.exitTypeData,
                    exitReasonData: payload.data.payload.masterData.exitReasonData
                }))
            }
            setLoad(prevState => ({
                ...prevState,
                loadingDonut: false
            }))
        })

    }
    //Funcion para traer la informacion del grafico de palabras
    const handleOnDataChartWords = async () => {
        dispatch(getDataChartWords()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDataCharts(prevState => ({
                    ...prevState,
                    chartWords: payload.data.payload.request
                }))
            }
            setLoad(prevState => ({
                ...prevState,
                loadingWords: false
            }))
        })

        //Funcion para traer la informacion del grafico de palabras
    }
    //Funcion para traer la informacion del grafico de motivo de salida
    const getDataExitReason = async () => {
        dispatch(getDataChartExitType({ year: year.yearExitReason })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDataCharts(prevState => ({
                    ...prevState,
                    chart2: payload.data.payload.masterData
                }))
            }
            setLoad(prevState => ({
                ...prevState,
                loadingExitReason: false
            }))
        })
    }
    //Funcion para traer la informacion del grafico de razon de salida
    const getDataReasonsOfExit = async () => {
        setLoad(prevState => ({
            ...prevState,
            loadingReasonsOfExit: true
        }))
        dispatch(getDataChatExitReasons({ year: year.yearReasonsOfExit })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDataCharts(prevState => ({
                    ...prevState,
                    chart3: payload.data.payload.masterData
                }))
                setLoad(prevState => ({
                    ...prevState,
                    loadingReasonsOfExit: false
                }))
            }

        })
    }
    const getDataChartDraft = async () => {
        setLoad(prevState => ({
            ...prevState,
            draft: true
        }))
        dispatch(getChartDraft({ year: year.draft })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setDataCharts(prevState => ({
                    ...prevState,
                    chartDraft: payload.data.payload.data
                }))
                setLoad(prevState => ({
                    ...prevState,
                    draft: false
                }))
            }

        })

    }
    //Funcion para acomodar la data de los graficos de dona
    const sortChartDonuts = () => {
        let vector = [];
        let value = "";
        let cont = 0;
        for (const label of labels) {
            for (const item of dataCharts.chart1) {
                if (label === item.country) {
                    value = item.amount;
                    cont++;
                    break;
                }
            }
            if (cont > 0) {
                vector.push(value)
            }
            cont = 0;
        }
        return vector;
    }
    //Funcion para acomodar la data de los graficos de bara
    const sortDataChartBar = (json, labels, country) => {
        let vector = [];
        let value = "";
        let cont = 0;

        for (const label of labels) {
            if (json[`${country}`]) {
                for (const item of json[`${country}`]) {
                    if (label === item.label) {
                        value = item.value;
                        cont++;
                        break;
                    }
                }
            }
            if (cont > 0) {
                vector.push(value)
            } else {
                vector.push(0)
            }
            cont = 0;
        }
        return vector;
    }
    //Funcion para acomodar la data de los graficos de bara

    const sortDataChartDraft = (json, labels, variable) => {

        let vector = [];

        for (const label of labels) {
            let found = false;
            if (json[label]) {
                for (const item of json[label]) {
                    if (item.label === variable) {
                        vector.push(item.value);
                        found = true;
                        break;
                    }
                }
            }
            if (found === false) {
                vector.push(0);
            }
        }
        return vector;


    };
    const handleOnTypeChart3 = (value) => {
        setDataCharts(prevState => ({
            ...prevState,
            typeChart3: value
        }))

        setStateCheck(prevState => ({
            ...prevState,
            check1: !stateCheck.check1,
            check2: !stateCheck.check2
        }))
    }

    //#endregion
    //#region  Informacion del primer grafico
    const years = [
        { label: "2020", value: "2020" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "2024", value: "2024" },
        { label: "2025", value: "2025" },
        { label: "2026", value: "2026" },
        { label: "2027", value: "2027" },
    ];
    const labels = dataCharts.countries.length > 0 ? dataCharts.countries : [];

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Salidas",
                data: sortChartDonuts(),
                backgroundColor: colors,
            },
        ],
    };
    //#endregion

    //#region  Informacion del segundo grafico
    const labels2 = dataCharts.exitTypeData.length > 0 ? dataCharts.exitTypeData : [];
    const dataChart2 = {
        labels: labels2,
        datasets: [
            {
                label: "Costa Rica",
                data: sortDataChartBar(dataCharts.chart2, labels2, "Costa Rica"),
                backgroundColor: colors[0],
            },
            {
                label: "Guatemala",
                data: sortDataChartBar(dataCharts.chart2, labels2, "Guatemala"),
                backgroundColor: colors[1],
            },
            {
                label: "Panamá",
                data: sortDataChartBar(dataCharts.chart2, labels2, "Panamá"),
                backgroundColor: colors[2],
            },
            {
                label: "Rep. Dominicana",
                data: sortDataChartBar(dataCharts.chart2, labels2, "Rep. Dominicana"),
                backgroundColor: colors[3],
            },
            {
                label: "Nicaragua",
                data: sortDataChartBar(dataCharts.chart2, labels2, "Nicaragua"),
                backgroundColor: colors[4],
            },
            {
                label: "Honduras",
                data: sortDataChartBar(dataCharts.chart2, labels2, "Honduras"),
                backgroundColor: colors[5],
            },
            {
                label: "El Salvador",
                data: sortDataChartBar(dataCharts.chart2, labels2, "El Salvador"),
                backgroundColor: colors[6],
            },
            {
                label: "Miami",
                data: sortDataChartBar(dataCharts.chart2, labels2, "Miami"),
                backgroundColor: colors[7],
            },
            {
                label: "Colombia",
                data: sortDataChartBar(dataCharts.chart2, labels2, "Colombia"),
                backgroundColor: colors[8],
            },
        ],
    };
    //#endregion

    //#region  Informacion del tercer grafico (Razon de salida)
    //Acomoda los piases segun las razon
    const labels3 = dataCharts.exitReasonData.length > 0 ? dataCharts.exitReasonData : [];
    const dataChart3 = {
        labels: labels3,
        datasets: [
            {
                label: "Costa Rica",
                data: sortDataChartBar(dataCharts.chart3, labels3, "Costa Rica"),
                backgroundColor: colors[0],
            },
            {
                label: "Guatemala",
                data: sortDataChartBar(dataCharts.chart3, labels3, "Guatemala"),
                backgroundColor: colors[1],
            },
            {
                label: "Panamá",
                data: sortDataChartBar(dataCharts.chart3, labels3, "Panamá"),
                backgroundColor: colors[2],
            },
            {
                label: "Rep. Dominicana",
                data: sortDataChartBar(dataCharts.chart3, labels3, "Rep. Dominicana"),
                backgroundColor: colors[3],
            },
            {
                label: "Nicaragua",
                data: sortDataChartBar(dataCharts.chart3, labels3, "Nicaragua"),
                backgroundColor: colors[4],
            },
            {
                label: "Honduras",
                data: sortDataChartBar(dataCharts.chart3, labels3, "Honduras"),
                backgroundColor: colors[5],
            },
            {
                label: "El Salvador",
                data: sortDataChartBar(dataCharts.chart3, labels3, "El Salvador"),
                backgroundColor: colors[6],
            },
            {
                label: "Miami",
                data: sortDataChartBar(dataCharts.chart3, labels3, "Miami"),
                backgroundColor: colors[7],
            },
            {
                label: "Colombia",
                data: sortDataChartBar(dataCharts.chart3, labels3, "Colombia"),
                backgroundColor: colors[8],
            },
        ],
    };
    //Acomoda las razones segun los paises
    const labelsReasonCountry = dataCharts.countries.length > 0 && dataCharts.countries;
    const dataChartReasonCountry = {
        labels: labelsReasonCountry,
        datasets: dataCharts.chart4
    };

    //#endregion

    //#region DRAFT
    const labelDraft = ["Enero", "February", "March", "April", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const dataChartDraft = {
        labels: labelDraft,
        datasets: [
            {
                label: "Costa Rica",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "Costa Rica"),
                backgroundColor: colors[0],
            },
            {
                label: "Guatemala",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "Guatemala"),
                backgroundColor: colors[1],
            },
            {
                label: "Panamá",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "Panamá"),
                backgroundColor: colors[2],
            },
            {
                label: "Rep. Dominicana",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "Rep. Dominicana"),
                backgroundColor: colors[3],
            },
            {
                label: "Nicaragua",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "Nicaragua"),
                backgroundColor: colors[4],
            },
            {
                label: "Honduras",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "Honduras"),
                backgroundColor: colors[5],
            },
            {
                label: "El Salvador",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "El Salvador"),
                backgroundColor: colors[6],
            },
            {
                label: "Miami",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "Miami"),
                backgroundColor: colors[7],
            },
            {
                label: "Colombia",
                data: sortDataChartDraft(dataCharts.chartDraft, labelDraft, "Colombia"),
                backgroundColor: colors[8],
            },
        ],
    };
    //#endregion

    //#region  Options Graficos
    const optionsChart = {
        legend: {
            position: "top",
            display: true,
            labels: {
                usePointStyle: false
            }
        },
        tooltips: {
            mode: "index",
            intersect: false
        },
        responsive: true,
        scales: {
            xAxes: [
                {
                    stacked: false
                }
            ],
            yAxes: [
                {
                    stacked: false,
                    ticks: {
                        callback: function (value) {
                            if (!(value % 1)) {
                                return value;
                            }
                        }
                    }
                }
            ]
        }
    }
    const optionsPie = {
        responsive: true,
        legend: {
            position: "top",
            display: true,
            // fullWidth: true,
            labels: {
                usePointStyle: false
            }
        },
        animation: {
            animateScale: true,
            animateRotate: true
        },
        // tooltips: {
        //     callbacks: {
        //         label: function (tooltipItem, data) {
        //             const dataset = data.datasets[tooltipItem.datasetIndex];
        //             return `${dataset.data[tooltipItem.index]}`;
        //         }
        //     }
        // }
    }
    const optionsWords = {
        colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
        enableTooltip: true,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [20, 60],
        fontStyle: "normal",
        fontWeight: "normal",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
    };
    //#endregion
    return (
        <div>
            <Row className="d-flex justify-content-center">
                <Col xs="12" md="6">
                    <Spin size="large" spinning={loading.loadingDonut}>

                        <ChartPie
                            title={`Cantidad de Salidas por año: ${year.yearDonut}`}
                            data={data}
                            options={optionsPie}
                            year={year.yearDonut}
                            years={years}
                            onChange={onChangeYear}
                            constant={"yearDonut"} />
                    </Spin>
                </Col>
                <Col xs="12" md="6">

                    <Spin size="large" spinning={loading.loadingWords}>
                        <Card>
                            <CardHeader>
                                <h6 className="surtitle">{`Grafica de palabras más comunes`}</h6>
                            </CardHeader>
                            <CardBody>
                                <ReactWordcloud options={optionsWords} words={dataCharts.chartWords} />
                            </CardBody>
                        </Card>
                    </Spin>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center">
                <Col xs="12" md="12">
                    <Spin size="large" spinning={loading.loadingExitReason}>
                        <ChartBar
                            title={`Motivo de salida del: ${year.yearReasonsOfExit}`}
                            data={dataChart2}
                            options={optionsChart}
                            year={year.yearReasonsOfExit}
                            years={years}
                            constant={"yearExitReason"}
                            onChange={onChangeYear}
                        />
                    </Spin>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs="12" md="12">
                    <Spin size="large" spinning={loading.loadingReasonsOfExit}>

                        {
                            dataCharts.typeChart3 === 1 ?
                                <ChartBar
                                    title={`Razón de salida del: ${year.yearReasonsOfExit}`}
                                    data={dataChart3}
                                    checked={handleOnTypeChart3}
                                    options={optionsChart}
                                    onChangeYear={onChangeYear}
                                    year={year.yearReasonsOfExit}
                                    years={years}
                                    constant={"yearReasonsOfExit"}
                                    onChange={onChangeYear}
                                    checkButton={true}
                                    stateCheck={stateCheck}
                                    textRadio1={"País:"}
                                    textRadio2={"Razones:"}
                                />

                                :
                                <ChartBar
                                    title={`Razones por cada país: ${year.yearReasonsOfExit}`}
                                    data={dataChartReasonCountry}
                                    checked={handleOnTypeChart3}
                                    options={optionsChart}
                                    onChangeYear={onChangeYear}
                                    year={year.yearReasonsOfExit}
                                    years={years}
                                    constant={"yearReasonsOfExit"}
                                    onChange={onChangeYear}
                                    checkButton={true}
                                    stateCheck={stateCheck}
                                    textRadio1={"País:"}
                                    textRadio2={"Razones:"}
                                />
                        }

                    </Spin>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs="12" md="12">
                    <Spin size="large" spinning={loading.draft}>
                        <ChartBar
                            title={`Entrevistas en borrador: ${year.draft}`}
                            data={dataChartDraft}
                            options={optionsChart}
                            onChangeYear={onChangeYear}
                            year={year.yearReasonsOfExit}
                            years={years}
                            constant={"draft"}
                            onChange={onChangeYear}
                        />
                    </Spin>
                </Col>
            </Row>
        </div>
    );
};


export default Dashboards;