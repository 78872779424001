import React, { Component } from 'react';
import PropTypes from 'prop-types';
// reactstrap components
import { Container } from "reactstrap";
// react-select library for filters
import Select from "react-select";
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import { Row, Card, CardFooter, Button, FormGroup, Input, Col, CardHeader, CardBody, Label } from "reactstrap";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// antd components
import { Spin } from 'antd';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
//Selector 
import { getAllPlatforms, getAllProducts, getAllServices, getAllData } from "selectors/incidentsReports.jsx";
//Actions 
import { getPlatform, getProducts, getServices, getData, createNewItem, deleteItem } from "actions/incidentsReports.jsx";
class admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            productSelect: "",
            platformSelect: "",
            servicesSelect: "",
            newItem: "",
            products: { label: "Seleccione...", value: "0" },
            platform: { label: "Seleccione...", value: "0" },
            deleteList: { label: "Seleccione...", value: "0" },
            services: { label: "Seleccione...", value: "0" },
            listDataInsert: { label: "Seleccione...", value: "0" },
            listDataSelect: { label: "Seleccione...", value: "0" },
            loading: false,
        }
    }
    //Mensajes de notificacion
    notify = (type, title, message) => {
        let options = {
            place: "br",
            message: (
                <div className="alert-text" >
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {title}
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (Object.keys(this.refs).length)
            if (this.props.history.location.pathname === this.props.location.pathname)
                this.refs.notificationAlert.notificationAlert(options);
    };
    //Captura lo escrito en el input de agregar
    handelOnChangeData = (e) => {
        let { newItem } = this.state
        newItem = e.target.value
        this.setState({
            newItem
        })
    }
    //Llama al endPoint de insertar una nuevo Plataforma, productos o servicio
    handleOnInsert = () => {
        const { newItem, listDataInsert } = this.state


        if (newItem === "" || newItem === " ") {
            this.notify("danger", " Fallo: ", "Escriba un nombre antes para poder agregar");
        } else {
            this.setState((state) => ({
                loading: !state.loading
            }));
            this.props.createNewItem({
                info: {
                    name: newItem,
                    type: listDataInsert
                }
            }).then((res) => {
                if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                        this.notify("warning", " Atención: ", res.payload.data.payload.message);
                    } else {
                        this.notify("danger", " Falló: ", "No se logro establecer conexion con el servidor.");
                    }
                } else {
                    this.notify("success", " Éxito: ", res.payload.data.payload.message);
                    this.handleOnCallSelects();
                    document.getElementById('nameInsert').value = "";
                }
                this.setState((state) => ({
                    loading: !state.loading,
                    newItem: ""
                }));
            });
        }

    }
    //Guarda el valor del item seleccionado en los selects
    handleOnGetDataSelect = (id, value) => {
        this.setState({
            [id]: value,
        });
    }
    //Cambia el item del select de eliminar
    handleOnChangeSelectDelete = (id, value) => {
        this.setState((state) => ({
            loading: !state.loading,
            [id]: value,
            deleteList: { label: "Seleccione...", value: "0" }
        }));
        this.handleOnGetDataSelectDelete(value.label);
    }
    //Llama al endPoint para eliminar una Plataforma, un productos o un servicio
    handleOnDeleteItem = () => {

        const { deleteList, listDataSelect } = this.state

        if (deleteList.label === "Seleccione...") {
            this.notify("danger", " Fallo: ", "Seleccione un elemento antes de eliminar");
        } else {
            this.setState((state) => ({
                loading: !state.loading
            }));
            this.props.deleteItem({
                type: listDataSelect,
                id: deleteList
            }).then((res) => {
                if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                        this.notify("warning", " Atención: ", res.payload.data.payload.message);
                    } else {
                        this.notify("danger", " Falló: ", "No se logro establecer conexion con el servidor.");
                    }
                } else {
                    this.notify("success", " Éxito: ", res.payload.data.payload.message);
                    this.handleOnCallSelects();
                    this.setState(() => ({
                        deleteList: { label: "Seleccione...", value: "0" }
                    }))
                }
                this.setState((state) => ({
                    loading: !state.loading
                }));
            });
        }
    }
    //Llama al endPoint para cargar el select donde se selecciona el item a eliminar
    handleOnGetDataSelectDelete = (type) => {
        this.props.getData({
            type: type
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", " Atención: ", payload.message);
                } else {
                    this.notify("danger", " Falló: ", "No se logro establecer conexion con el servidor.");
                }
            } else {
                const { data: { payload } } = res.payload;
                document.getElementById('deleteList').value = "";
            }
            this.setState((state) => ({
                loading: !state.loading
            }));
        });
    }
    //Llama los endPoints para cargar los selects de Plataforma, productos y servicios
    handleOnCallSelects = () => {
        this.props.getPlatform({
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "", payload.message);
                } else {
                    this.notify("danger", " Falló: ", "No se logro establecer conexion con el servidor.");
                }
            }
        });
        this.props.getProducts({
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "", payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            }
        });
        this.props.getServices({
        }).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    this.notify("warning", "", payload.message);
                } else {
                    this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
                }
            }
        });
    }
    //Funcion que se carga antes de cargar la pagina 
    componentWillMount() {
        this.handleOnCallSelects();
    }

    render() {
        const {
            listPlatform,
            listProducts,
            listServices,
            listData,
            listaData
        } = this.props
        const {
            platform,
            deleteList,
            listDataInsert,
            listDataSelect,
            products,
            services,
            loading,
            newItem
        } = this.state

        return (
            <div>
                <NotificationAlert ref="notificationAlert" />
                <AdminHeader name="Administracion" parentName="Data de Reportes de incidentes" />
                <Spin size="large" spinning={loading}>
                    <Container className="mt--6" fluid>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        < FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >Productos:</label>
                                            <Select
                                                className="basic-single"
                                                id="products"
                                                isSearchable
                                                options={listProducts}
                                                value={products}
                                                onChange={(e) => this.handleOnGetDataSelect("products", e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        < FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >  Plataformas: </label>
                                            <Select
                                                className="basic-single"
                                                id="platform"
                                                type="select"
                                                options={listPlatform}
                                                value={platform}
                                                onChange={(e) => this.handleOnGetDataSelect("platform", e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        < FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >  Servicios: </label>
                                            <Select
                                                className="basic-single"
                                                id="services"
                                                isSearchable
                                                options={listServices}
                                                value={services}
                                                onChange={(e) => this.handleOnGetDataSelect("services", e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col>
                                <Card >
                                    <CardHeader>
                                        <h3 className="mb-0" >Agregar</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className="justify-content-center d-flex">
                                            <Col m="12" md="4">
                                                <Select
                                                    className="basic-single"
                                                    id="listDataInsert"
                                                    type="select"
                                                    placeholder={"Selecione..."}
                                                    isSearchable
                                                    options={listData}
                                                    value={listDataInsert}
                                                    onChange={(e) => this.handleOnGetDataSelect("listDataInsert", e)}
                                                />
                                            </Col>
                                            <Col m="12" md="8">
                                                <Input id="nameInsert" onChange={(e) => this.handelOnChangeData(e)}></Input>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Row className="align-items-center justify-content-center d-flex">
                                            <Button
                                                className="btn"
                                                color="success"
                                                onClick={this.handleOnInsert}
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-plus" />
                                                </span>Agregar
                                            </Button>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col>
                                <Card >
                                    <CardHeader>
                                        <h3 className="mb-0" >Eliminar</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className="justify-content-center d-flex">
                                            <Col m="12" md="4">
                                                <Select
                                                    className="basic-single"
                                                    id="listDataSelect"
                                                    type="select"
                                                    placeholder={"Selecione..."}
                                                    isSearchable
                                                    options={listData}
                                                    value={listDataSelect}
                                                    onChange={(e) => this.handleOnChangeSelectDelete('listDataSelect', e)}
                                                />
                                            </Col>
                                            <Col m="12" md="8">
                                                <Select
                                                    className="basic-single"
                                                    id="deleteList"
                                                    type="select"
                                                    placeholder={"Selecione..."}
                                                    isSearchable
                                                    options={listaData}
                                                    value={deleteList}
                                                    onChange={(e) => this.handleOnGetDataSelect("deleteList", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Row className="align-items-center justify-content-center d-flex">
                                            <Button
                                                className="btn"
                                                color="danger"
                                                onClick={this.handleOnDeleteItem}
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="far fa-trash-alt" />
                                                </span>Eliminar
                                            </Button>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Spin>
            </div>
        );
    }
}
admin.defaultProps = {
    listData: [
        {
            label: "Plataformas",
            value: "1"
        },
        {
            label: "Productos",
            value: "2"
        },
        {
            label: "Servicios",
            value: "3"
        }
    ],
    listPlatform: [],
    listProducts: [],
    listServices: [],
    listaData: []
}
admin.propTypes = {
    listPlatform: PropTypes.array.isRequired,
    listProducts: PropTypes.array.isRequired,
    listServices: PropTypes.array.isRequired,
    listaData: PropTypes.array.isRequired
};
//selector
const mapStateToProps = state => ({
    listPlatform: getAllPlatforms(state),
    listProducts: getAllProducts(state),
    listServices: getAllServices(state),
    listaData: getAllData(state),
});
//action
export default withRouter(connect(mapStateToProps, {
    getPlatform,
    getProducts,
    getServices,
    createNewItem,
    getData,
    deleteItem
})(admin));
