import React from 'react';
// antd components
import { Spin } from 'antd';
import {
    Row, Button, FormGroup, Input, Form,
    Col, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";

const ModalNewContact = props => {

    const {
        cols,
        modalNewContact,
        handleOnOpenModalNewContact,
        saveCustomer,
        changeData,
        options,
        saveLockContact,
        invalid,
        isLock,
        loading,
        newContactInfo,
    } = props;

    return (
        <div>
            <Modal
                // zIndex={1050}
                size="lg"
                isOpen={modalNewContact}
                toggle={handleOnOpenModalNewContact}
            // className={"mmm"}
            >
                <ModalHeader toggle={handleOnOpenModalNewContact}>Nuevo contacto</ModalHeader>
                <Form novalidate className="formInfoContacts needs-validation">
                    <ModalBody>
                        <Spin size="large" spinning={loading}>
                            <Row>
                                {
                                    cols.map((row) => (

                                        row.value === "STATUS" || row.value === "CONFIRM" || row.value === "ID_CONTACT_CRM" || row.value === "SAM" || row.value === "UPDATEBY" || row.value === "UPDATEDATE" ?
                                            <></>
                                            :
                                            <Col xl="4" mb="12">

                                                < FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${row.label}:`}
                                                    </label>
                                                    {
                                                        row.type === "select" ?
                                                            <>
                                                                <Input
                                                                    invalid={invalid[row.value]}
                                                                    required={row.required === true ? true : false}
                                                                    type={row.type}
                                                                    options={options[row.value]}
                                                                    className="basic-multi-select text-dark"
                                                                    classNamePrefix="select"
                                                                    id={row.value}
                                                                    // value={newContactInfo[row.value]}
                                                                    onChange={(e) => changeData(e.target.children[e.target.selectedIndex].id, row.value)}
                                                                >
                                                                    {options[row.value].map((value) => {
                                                                        return (
                                                                            <option id={value.value}>{value.label}</option>
                                                                        );
                                                                    })}
                                                                </Input>
                                                                <div className="invalid-feedback">
                                                                    Este campo no puede estar vacio
                                                                </div>
                                                            </>
                                                            :
                                                            row.value === "FIRST_NAME" || row.value === "LAST_NAME" ?
                                                                <>
                                                                    <Input
                                                                        invalid={invalid[row.value]}
                                                                        required={row.required === true ? true : false}
                                                                        className="form-control"
                                                                        type={row.type}
                                                                        id={row.value}
                                                                        onKeyDown={e => /^[0-9]$/.test(e.key) && e.preventDefault()}
                                                                        placeholder={row.placeholder}
                                                                        onChange={(e) => changeData(e.target.value, row.value)}
                                                                        maxlength={row.maxlength}
                                                                        value={newContactInfo[row.value]}
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Este campo no puede estar vacio
                                                                    </div>
                                                                </>

                                                                :
                                                                row.value === "EMAIL" ?
                                                                    <>
                                                                        <Input
                                                                            invalid={invalid[row.value]}
                                                                            required={row.required === true ? true : false}
                                                                            className="form-control"
                                                                            id={row.value}
                                                                            type={row.type}
                                                                            placeholder={row.placeholder}
                                                                            onChange={(e) => changeData(e.target.value, row.value)}
                                                                            maxlength={row.maxlength}
                                                                            value={newContactInfo[row.value]}
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Este campo no puede estar vacio y ocupa formato de correo electrónico
                                                                        </div>
                                                                    </>
                                                                    : row.value === "ADDRESS" ?
                                                                        <>
                                                                            <Input
                                                                                invalid={invalid[row.value]}
                                                                                required={row.required === true ? true : false}
                                                                                className="form-control"
                                                                                id={row.value}
                                                                                type={row.type}
                                                                                placeholder={row.placeholder}
                                                                                onChange={(e) => changeData(e.target.value, row.value)}
                                                                                maxlength={row.maxlength}
                                                                                value={newContactInfo[row.value]}
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Este campo no puede estar vacio
                                                                            </div>
                                                                        </> :
                                                                        <>
                                                                            <Input
                                                                                invalid={invalid[row.value]}
                                                                                required={row.required === true ? true : false}
                                                                                className="form-control"
                                                                                id={row.value}
                                                                                onKeyDown={e => /^[a-zA-Z]$/.test(e.key) && e.preventDefault()}
                                                                                placeholder={row.placeholder}
                                                                                onChange={(e) => changeData(e.target.value, row.value)}
                                                                                maxlength={row.maxlength}
                                                                                value={newContactInfo[row.value]}
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Este campo no puede estar vacio
                                                                            </div>
                                                                        </>
                                                    }
                                                </FormGroup>
                                            </Col>
                                    ))
                                }
                            </Row>
                        </Spin>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleOnOpenModalNewContact}>Cancelar</Button>
                        <Button color="success" onClick={isLock === 1 ? saveCustomer : saveLockContact}>Crear</Button>
                    </ModalFooter>
                </Form>
            </Modal>

        </div >
    );
};

ModalNewContact.propTypes = {

};

export default ModalNewContact;