
import React from 'react';
import {
    Col,
    Row,
    ListGroup,
    ListGroupItem,
    Button,
} from "reactstrap";
const Approvers = ({ approvers }) => {
    const approverStatusColor = (statusApprover, value) => {
        let status = "";
        switch (statusApprover) {
            case 0:
                value === "status" ? status = "En espera" : status = "primary"
                break;
            case 1:
                value === "status" ? status = "Aprobado" : status = "success"
                break;
            case 2:
                value === "status" ? status = "Rechazado" : status = "danger"
                break;
            default:
                break;
        }
        return status;
    }
    return (
        <div>
            <ListGroup className="list my--3" flush>
                {approvers.map((item, key) => (
                    < ListGroupItem
                        className="px-0"
                        key={key}
                    >
                        <Row className="align-items-center">
                            <Col className="col-auto">
                                <p
                                    className="avatar rounded-circle"
                                    onClick={(e) =>
                                        e.preventDefault()
                                    }
                                >
                                    <img
                                        alt="..."
                                        src={require("assets/img/theme/user.svg")}
                                    />
                                </p>
                            </Col>
                            <Col xs="7">
                                <h4 className="mb-0">
                                    <strong>{item.secondary !== "" ? `${item.name} - ${item.secondary}` : item.name}</strong>
                                </h4>
                            </Col>
                            <Col xs="2">
                                <Button
                                    color={approverStatusColor(item.status)}
                                    size="sm"
                                    disabled={true}
                                >
                                    <span>{approverStatusColor(item.status, "status")}</span>
                                </Button>
                            </Col>
                        </Row>
                    </ListGroupItem>
                ))}
            </ListGroup>
        </div>
    );
};


export default Approvers;