import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

/**
 * Captura de la accion realizada para obtener los recursos de uno o mas nodos en especifico
 */
export const resourses = handleActions({
  [constants.GET_RESOURSES]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.resourses;
    }
    return [];
  },
  [constants.SIGN_OUT]: () => [],
}, []);

/**
 * Captura de la accion realizada para obtener todos los nodos
 */
export const nodes = handleActions({
  [constants.GET_NODES]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.nodes;
    }
    return [];
  },
  [constants.SIGN_OUT]: () => [],
}, []);
