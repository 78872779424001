import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
//reactstrap components
import { Container } from "reactstrap";
// antd components
import { Spin } from 'antd';
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
//Components
import EntitiesMainTable from "components/PanamaBids/Entities/EntitiesMainTable.jsx"
import EntitesFilter from "components/PanamaBids/Entities/EntitiesFilter.jsx"
import EntitiesCreateModal from 'components/PanamaBids/Entities/EntitiesCreateModal';
import EntitiesEditModal from 'components/PanamaBids/Entities/EntitiesEditModal';
//Actions
import { getMDataEntities, getAllEntities, getContacts } from 'actions/panamaBids.jsx';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';

const Entities = () => {
    //PanamaBids Display
    const MySwal = withReactContent(Swal);

    const dispatch = useDispatch();
    const [loading, setLoad] = useState(true);
    const [options, setOptions] = useState({
        Sector: [{ label: "", value: "" }],
        contacts: [{ label: "", value: "" }],
        employee: [{ label: "", value: "" }],
        rows: []
    });
    //#region 
    const [page, setPage] = useState(1);
    //#endregion
    const [editInfo, setEditInfo] = useState("");
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [rowsModal, setRowsModal] = useState([
        {
            colWidth: "12",
            elementType: "Input",
            label: "Entidad de Panama compra",
            nameInTable: "Entidad de Panama compra",
            placeholder: "Entidad",
            id: "entities",
            type: "text"
        },
        {
            colWidth: "12",
            elementType: "typeahead",
            label: "Cliente SAP",
            nameInTable: "Cliente SAP",
            placeholder: "Nombre del cliente",
            id: "customer",
            type: "typeahead"
        },
        {
            colWidth: "6",
            elementType: "Select",
            label: "Sector",
            nameInTable: "Sector",
            id: "sector",
            type: "select"
        },
        {
            colWidth: "6",
            elementType: "Select",
            label: "Contacto",
            nameInTable: "Contacto",
            id: "contact",
            type: "select"
        },
        {
            colWidth: "6",
            elementType: "typeahead",
            label: "Empleado Responsable Convenio",
            nameInTable: "Convenio Sales Rep",
            placeholder: "Empleado Responsable",
            id: "salesRep",
            type: "typeahead"
        },
        {
            colWidth: "6",
            elementType: "typeahead",
            label: "Empleado Responsable Cotizaciones",
            nameInTable: "Empleado Responsable SAP",
            placeholder: "Empleado Responsable",
            id: "salesRepCoti",
            type: "typeahead"
        }
    ])
    //Hook para filtros de las columnas de la tabla
    const [filters, setFilters] = useState([]);
    //Hook para recargar la tabla
    const [reloadTable, setRealTable] = useState();
    //Hook para abrir el modal
    const [showModal, setShowModal] = useState(
        {
            newModal: false,
            editModal: false
        }
    )

    //#region effect to display edit checkbox


    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const teams = getTeamsUserAccess();
        // const token = getUserProtected();
        if (teams.some((row) => row.indexOf("PanamaBids Admin") !== -1)) {
            setIsAdmin(true)
        }

    }, []);

    //#endregion

    //Hook para cargar la data de todos los selects cuando inicia la vista
    useEffect(() => {
        const getMasterDataR = async () => {
            await handelOngetMasterData();
        }
        getMasterDataR();
    }, [])
    //Funcion para traer lsa opciones de todos los selects
    const handelOngetMasterData = async () => {
        dispatch(getMDataEntities()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    Sector: payload.data.payload.masterData.Sector,
                    customers: payload.data.payload.masterData.customers,
                    employee: payload.data.payload.masterData.employee,
                }))
            }
            setLoad(false);
        })
    }
    //Hook para refrescar la data de la tabla 
    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
        }
        getRowsData();
    }, [reloadTable])
    //Funcion para traer las rows de la data de la tabla
    const handleRowInfo = async () => {
        dispatch(getAllEntities()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    rows: payload.data.payload.data
                }))
            }
        })
    }
    const { newModal, editModal } = showModal
    //Abrir el modal de crear nueva entidad
    const handleOnLoadNewModal = () => {
        setShowModal(prevState => ({
            ...prevState,
            newModal: !newModal,
        }))
    }
    const handleOnLoadEditModal = (e) => {
        setEditInfo(e)
        setRealTable(4);
        dispatch(getContacts({ info: e.customerId, type: "2" })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    contacts: payload.data.payload.response,
                }))
            }
            setShowModal(prevState => ({
                ...prevState,
                editModal: !editModal,
            }))
        })
    }
    //Aplicar los filtros a la data de la tabla
    const renderRowsByFilters = (rows) => {
        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);
            for (const element of filterKeys) {
                const valueFiltered = filters[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    //Capturar los valores de los inputs de los filtros
    const handleOnfilterDinamic = (constant, e) => {
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }
    return (
        <div>
            <EntitiesCreateModal
                rows={rowsModal}
                toggle={showModal}
                Toast={Toast}
                setShowModal={setShowModal}
                setOptions={setOptions}
                setRealTable={setRealTable}
                options={{
                    sector: [{ label: "Seleccione...", value: 0 }, ...options.Sector],
                    contact: [{ FIRST_NAME: "Seleccione...", LAST_NAME: "", value: 0 }, ...options.contacts],
                    salesRep: options.employee, salesRepCoti: options.employee, customer: options.customers
                }}
            />
            <EntitiesEditModal
                rows={rowsModal}
                toggle={showModal}
                Toast={Toast}
                editInfo={editInfo}
                setEditInfo={setEditInfo}
                setShowModal={setShowModal}
                setOptions={setOptions}
                setRealTable={setRealTable}
                options={{
                    sector: [{ label: "Seleccione...", value: 0 }, ...options.Sector], contact: [{ FIRST_NAME: "Seleccione...", LAST_NAME: "", value: 0 }, ...options.contacts],
                    salesRep: options.employee, salesRepCoti: options.employee, customer: options.customers
                }} />
            <AdminHeader name="Home" parentName="Entidades" />
            <Spin size="large" spinning={loading}>
                <Container className="mt--6" fluid>
                    
                    <EntitesFilter
                        handleOnLoad={() => handleOnLoadNewModal()}
                        isAdmin={isAdmin} />

                    <EntitiesMainTable
                        filters={handleOnfilterDinamic}
                        page={page}
                        setPage={setPage}
                        handleOnLoad={handleOnLoadEditModal}
                        cols={rowsModal}
                        Toast={Toast}
                        setRealTable={setRealTable}
                        rows={renderRowsByFilters(options.rows)}
                        isAdmin={isAdmin} />


                </Container>
            </Spin>
        </div>
    );
};

export default Entities;