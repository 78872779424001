import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// routes
import { KnowEmployeeRoutes } from "routes/routes.jsx";

/**
 * Componente para renderizar las rutas que estan disponibles en el modulo de planning board
 * @param {*} props
 * @returns
 */
const Index = (props) => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/know-employee") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Switch>
        {getRoutes(KnowEmployeeRoutes)}
        <Redirect from="/admin/know-employee" to="/admin/know-employee/forms" />
      </Switch>
      <p className="lead"></p>
    </>
  );
};

export default Index;
