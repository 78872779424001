// antd components
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Modal, Row } from "reactstrap";

const FiltersModal = props => {

  const {
    open,
    filters,
    loading,
    toggleModal,
    values,
    onChangeFilter
  } = props;

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={() => toggleModal()}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center">
                <h4 className="surtitle">Filtros</h4>
              </div>
            </CardHeader>
            {
              Object.keys(filters).length ?
                <CardBody className="px-lg-5 py-lg-5">
                  <Spin size="large" spinning={loading}>
                    <Form>
                      <Row className="align-items-center">
                        <Col sm="12">
                          <FormGroup
                            className="row"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <Label md="4" for="clients">Clientes</Label>
                            <Col md="8">
                              <Input
                                id="clients"
                                type="select"
                                defaultValue={values.clients}
                                onChange={e => onChangeFilter(e)}
                              >
                                <option key="0" value="0">
                                  {
                                    filters.clients.length ? `Seleccione un elemento` : `Sin elementos`
                                  }
                                </option>
                                {
                                  filters.clients.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup
                            className="row"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <Label md="4" for="contracts">Contratos</Label>
                            <Col md="8">
                              <Input
                                id="contracts"
                                type="select"
                                defaultValue={values.contracts}
                                disabled={!filters.contracts.length}
                                onChange={e => onChangeFilter(e)}
                              >
                                <option key="0" value="0">
                                  {
                                    filters.contracts.length ? `Seleccione un elemento` : `Sin elementos`
                                  }
                                </option>
                                {
                                  filters.contracts.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                        {/* <Col sm="12">
                          <FormGroup
                            className="row"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <Label md="4" for="services">Servicios</Label>
                            <Col md="8">
                              <Input
                                id="services"
                                type="select"
                                defaultValue={values.services}
                                disabled={!filters.services.length}
                                onChange={e => onChangeFilter(e)}
                              >
                                <option key="0" value="0">
                                  {
                                    filters.services.length ? `Seleccione un elemento` : `Sin elementos`
                                  }
                                </option>
                                {
                                  filters.services.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col> */}
                        <Col sm="12">
                          <FormGroup
                            className="row"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <Label md="4" for="coes">CoEs</Label>
                            <Col md="8">
                              <Input
                                id="coes"
                                type="select"
                                defaultValue={values.coes}
                                disabled={!filters.coes.length}
                                onChange={e => onChangeFilter(e)}
                              >
                                <option key="0" value="0">
                                  {
                                    filters.coes.length ? `Seleccione un elemento` : `Sin elementos`
                                  }
                                </option>
                                {
                                  filters.coes.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup
                            className="row"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <Label md="4" for="equipments">Equipos</Label>
                            <Col md="8">
                              <Input
                                id="equipments"
                                type="select"
                                defaultValue={values.equipments}
                                disabled={!filters.equipments.length}
                                onChange={e => onChangeFilter(e)}
                              >
                                <option key="0" value="0">
                                  {
                                    filters.equipments.length ? `Seleccione un elemento` : `Sin elementos`
                                  }
                                </option>
                                {
                                  filters.equipments.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup
                            className="row"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <Label md="4" for="specialities">Especialidades</Label>
                            <Col md="8">
                              <Input
                                id="specialities"
                                type="select"
                                defaultValue={values.specialities}
                                disabled={!filters.specialities.length}
                                onChange={e => onChangeFilter(e)}
                              >
                                <option key="0" value="0">
                                  {
                                    filters.specialities.length ? `Seleccione un elemento` : `Sin elementos`
                                  }
                                </option>
                                {
                                  filters.specialities.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup
                            className="row"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <Label md="4" for="collaborators">Colaboradores</Label>
                            <Col md="8">
                              <Input
                                id="collaborators"
                                type="select"
                                defaultValue={values.collaborators}
                                disabled={!filters.collaborators.length}
                                onChange={e => onChangeFilter(e)}
                              >
                                <option key="0" value="0">
                                  {
                                    filters.collaborators.length ? `Seleccione un elemento` : `Sin elementos`
                                  }
                                </option>
                                {
                                  filters.collaborators.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup
                            className="row"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <Label md="4" for="activities">Actividades</Label>
                            <Col md="8">
                              <Input
                                id="activities"
                                type="select"
                                defaultValue={values.activities}
                                disabled={!filters.activities.length}
                                onChange={e => onChangeFilter(e)}
                              >
                                <option key="0" value="0">
                                  {
                                    filters.activities.length ? `Seleccione un elemento` : `Sin elementos`
                                  }
                                </option>
                                {
                                  filters.activities.map((row, key) => {
                                    return (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </CardBody>
                : null}
          </Card>
        </div>
      </Modal>
    </>
  );
};

FiltersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
};

export default FiltersModal;