import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, CardHeader, FormGroup, Input, Button, Row, CardFooter, Modal } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import { updateInterview, getInterview } from "actions/exitInterview.jsx";
import { useDispatch } from "react-redux";

const HistoryInfoModal = ({ openModal, rowInfo, closeModal, options, setRowsInfotModal, Toast, setRowsModal, isAdmin }) => {

    const dispatch = useDispatch();

    const rowsModal = [
        {
            colWidth: "4",
            label: "Id de Colaborador",
            id: "idCollaborator",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Nombre de Colaborador",
            id: "collaboratorName",
            value: "name",
            type: "text",
            disabled: false,
        },
        {
            colWidth: "4",
            label: "Jefe Inmediato",
            id: "immediateBoss",
            value: "manager",
            type: "text",
            disabled: false,
        },
        {
            colWidth: "4",
            label: "Departamento",
            id: "department",
            value: "department",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Tiempo Laborado",
            id: "timeWorked",
            value: "timeWorked",
            type: "text",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Fecha",
            id: "date",
            type: "date",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Razón de Salida",
            name: "exitReasonName",
            id: "exitReason",
            type: "select",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Tipo de Salida",
            name: "exitTypeName",
            id: "exitType",
            type: "select",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Pais",
            id: "countriesData",
            value: "country",
            type: "select",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Recontratable?",
            name: "rehirableName",
            id: "rehirable",
            type: "select",
            disabled: true,
        },
        {
            colWidth: "4",
            label: "Volveria usted a GBM?",
            name: "goBackGBMName",
            id: "goBackGBM",
            type: "select",
            disabled: true,
        },
        {
            colWidth: "12",
            label: "Comentarios",
            id: "comment",
            type: "textarea",
            disabled: true,
        },
        {
            colWidth: "12",
            label: "¿Qué pudo haber hecho GBM para que usted no hubiera valorado otras opciones de trabajo?",
            id: "doNotValueGBM",
            type: "textarea",
        },
        {
            colWidth: "12",
            label: "Comentarios HC",
            id: "commentHC",
            type: "textarea",
            disabled: false,
        },
    ];
    //Campos en el modal de informacion
    const handleOnChangeInfo = (constant, e) => {
        setRowsInfotModal((prevState) => ({
            ...prevState,
            [constant]: e,
        }));
    };
    const handleOnUpdateInterview = () => {
        dispatch(updateInterview({ info: rowInfo })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                dispatch(getInterview({ dates: "" })).then((resp) => {
                    const { payload } = resp;
                    if (payload.status === 200) {
                        setRowsModal((prevState) => ({
                            ...prevState,
                            rows: payload.data.payload.data,
                        }));
                        closeModal(false)
                    }
                    Toast.fire({
                        title: "Atención",
                        html: "Se actualizo correctamente",
                        type: "success",
                    });
                });

            } else {
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrio un error no se pudo actualizar",
                    type: "success",
                });
            }
        });
    };
    const [isRenounce, setIsRenounce] = useState(false);
    const [isOtherExitReason, setIsOtherExitReason] = useState(false);
    useEffect(() => {
        if (rowInfo.exitType === "Renuncia") {
            setIsRenounce(true);
        }
        setIsOtherExitReason(containsExitReason("Otros"));
    }, [rowInfo]);

    const containsExitReason = (word) => {
        let cont = 0;
        if (rowInfo.exitReason) {
            rowInfo.exitReason.map(item => item.label === word && cont++)
            if (cont > 0) {
                return true;
            } else {
                return false;
            }
        }
    }
    const invalid = isAdmin === true ? false : true;

    return (
        <div>
            <Modal size="lg" isOpen={openModal}>
                <Card>
                    <CardHeader>
                        <h6 className="surtitle">{`Información de la entrevista de salida`}</h6>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {rowsModal.map((item, key) => (
                                item.type === "select" ?
                                    item.id === "exitReason" ?
                                        <>
                                            <Col xs={item.colWidth} key={key}>
                                                <FormGroup>
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {item.label}
                                                    </label>
                                                    <Select
                                                        style={{ border: "red" }}
                                                        className="basic-single is-invalid"
                                                        type="select"
                                                        isSearchable
                                                        isMulti
                                                        isDisabled={invalid}
                                                        defaultValue={rowInfo["exitReason"]}
                                                        options={options.masterData[item.id]}
                                                        onChange={(e) => handleOnChangeInfo(item.id, e)}
                                                    />

                                                    <div className="invalid-feedback">
                                                        Este campo no puede estar vacio
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            {isOtherExitReason == true ?
                                                < Col xs={4} key={`${key}specify`}>
                                                    <FormGroup key={key}>
                                                        <label
                                                            style={{ fontSize: "12px" }}
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {`Especificación de tipo de salida:`}
                                                        </label>
                                                        <Input
                                                            id={"specify"}
                                                            required={true}
                                                            className="form-control"
                                                            type={"text"}
                                                            disabled={invalid}
                                                            key={`${key}specify`}
                                                            defaultValue={rowInfo["specify"] ? (rowInfo["specify"]) : ""}
                                                            onChange={(e) =>
                                                                handleOnChangeInfo("specify", e.target.value)
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            Este campo no puede estar vacio
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                : <></>}
                                        </>
                                        :
                                        item.id === "exitReason" ?
                                            <>
                                                <Col xs={item.colWidth} key={key}>
                                                    <FormGroup>
                                                        <label
                                                            style={{ fontSize: "12px" }}
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {item.label}
                                                        </label>
                                                        <Input
                                                            key={key}
                                                            required={true}
                                                            type={"select"}
                                                            disabled={isAdmin === false ? item.disabled : false}
                                                            options={options.masterData[item.id]}
                                                            defaultValue={rowInfo[item.id]}
                                                            className="basic-multi-select text-dark"
                                                            onChange={(e) => handleOnChangeInfo(item.value, e.target.children[e.target.selectedIndex], "exitReason")}
                                                        >
                                                            {options[item.id].map((value) => {
                                                                return (
                                                                    <option id={value.value}>{value.label}</option>
                                                                );
                                                            })}
                                                        </Input>

                                                        <div className="invalid-feedback">
                                                            Este campo no puede estar vacio
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                            :
                                            <Col xs={item.colWidth} key={key}>
                                                <FormGroup>
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {item.label}
                                                    </label>
                                                    <Input
                                                        key={key}
                                                        required={true}
                                                        type={"select"}
                                                        disabled={invalid}
                                                        options={options.masterData[item.id]}
                                                        defaultValue={rowInfo[item.id]}
                                                        className="basic-multi-select text-dark"
                                                        onChange={(e) => handleOnChangeInfo(item.id === "countriesData" ? item.value : item.id, e.target.children[e.target.selectedIndex].id)}
                                                    >
                                                        {options[item.id].map((value) => {
                                                            return (
                                                                <option id={value.value}>{value.label}</option>
                                                            );
                                                        })}
                                                    </Input>
                                                    <div className="invalid-feedback">
                                                        Este campo no puede estar vacio
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                    :
                                    <Col xs={item.colWidth} key={key}>
                                        <FormGroup key={key}>
                                            <label
                                                style={{ fontSize: "12px" }}
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >
                                                {`${item.label}:`}
                                            </label>
                                            <Input
                                                id={item.id}
                                                key={key}
                                                required={true}
                                                disabled={invalid}
                                                onChange={(e) => handleOnChangeInfo(item.id, e.target.value)}
                                                className="form-control"
                                                defaultValue={item.type === "date" ? moment.utc(rowInfo.date).format("YYYY-MM-DD") : rowInfo[item.id]}
                                                type={item.type}
                                            />
                                        </FormGroup>
                                    </Col>

                            ))}
                            {
                                isRenounce === true &&
                                <>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                style={{ fontSize: "12px" }}
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >
                                                {"¿Qué tipo de compañía es su nueva oferta laboral?"}
                                            </label>

                                            <Input
                                                required={true}
                                                type={"select"}
                                                options={options.masterData["companyType"]}
                                                disabled={invalid}
                                                defaultValue={rowInfo["companyType"] ? rowInfo["companyType"] : "---"}
                                                className="basic-multi-select text-dark"
                                                onChange={(e) => handleOnChangeInfo("companyTypeId", e.target.children[e.target.selectedIndex].id)}
                                            >
                                                {options["companyType"].map((value) => {
                                                    return (
                                                        <option id={value.value}>{value.label}</option>
                                                    );
                                                })}
                                            </Input>
                                            <div className="invalid-feedback">
                                                Este campo no puede estar vacio
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup >
                                            <label
                                                style={{ fontSize: "12px" }}
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >
                                                {`¿Qué beneficios adicionales o pluses adicionales ofrece la nueva oferta laboral?`}
                                            </label>
                                            <Input
                                                id={"benefitsJob"}
                                                required={true}
                                                className="form-control"
                                                type={"textarea"}
                                                disabled={invalid}
                                                defaultValue={rowInfo["benefitsJob"] ? (rowInfo["benefitsJob"]) : ""}
                                                onChange={(e) =>
                                                    handleOnChangeInfo("benefitsJob", e.target.value)
                                                }
                                            />
                                            <div className="invalid-feedback">
                                                Este campo no puede estar vacio
                                            </div>
                                        </FormGroup>
                                    </Col>

                                </>
                            }
                        </Row>

                    </CardBody>
                    <CardFooter>
                        <Row className="d-flex justify-content-center">
                            {isAdmin === true && (
                                <Col xs="12" md="4"
                                >
                                    <Button
                                        className="btn btn-icon"
                                        color="success"
                                        block
                                        id="botonTool2"
                                        onClick={() => handleOnUpdateInterview()}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-save"></i>
                                        </span>
                                        <span className="btn-inner--text">
                                            Guardar  cambios
                                        </span>
                                    </Button>
                                </Col>
                            )}
                            <Col xs="12" md="4">
                                <Button
                                    className="btn  btn-icon"
                                    color="danger"
                                    block type="button"
                                    onClick={() => closeModal(false)}
                                >
                                    Cerrar
                                </Button>
                            </Col>


                        </Row>
                    </CardFooter>
                </Card>
            </Modal>
        </div>
    );
};


export default HistoryInfoModal;