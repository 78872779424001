/*!

=========================================================
*Serch
=========================================================

*Componente para gestionar la búsqueda.
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

import React from "react";
// reactstrap components
import {
    InputGroupAddon,
    InputGroup,
    FormGroup,
    Label,
    Button,
    Input,
    Row,
    Col,
} from "reactstrap";

import { Button as ButtonGeist } from "@geist-ui/react";

//#region icons
import { IoSearchSharp } from "react-icons/io5";

//#endregion


// antd components
import { Spin } from 'antd';

const Search = ({
    readyToSend,
    fields,
    isMobile,
    isComputer,
    newInfo,
    handleOnChange,
    modeButtonAddon,
    handleConfirmNoValidated,
    options,
    localSpinner
}) => {

    return (
        <>

            <Row>
                {
                    fields.map((field, key) =>
                        <Col xs={field.width}>
                            <FormGroup className="mb-2" >

                                <Label className="font-weight-bold ml-2" >

                                    {field.icon && //Icono 
                                        <field.icon className="mr-2 mb-1" style={{ fontSize: "16px" }} />
                                    }

                                    {field.label + ':'}
                                </Label>


                                <InputGroup className="input-group-merge">
                                    <Input
                                        type={field.type}
                                        className=""
                                        disabled={field.disabled || (field.dependsEnableOf && !newInfo[field.dependsEnableOf])}
                                        size={isMobile && "sm"}
                                        value={newInfo && newInfo[field.id]}
                                        onChange={(e) => {
                                            if (field.type === "number") {
                                                handleOnChange(field.id, e.target.value.replace(/\D/g, ""));
                                            } else {
                                                handleOnChange(field.id, e.target.value);
                                            }
                                        }}

                                        min={1}
                                    >
                                        {field.deleteEmptyOption && <option id=""></option>}
                                        {
                                            options[field.options] && options[field.options].map((value) => {
                                                return (
                                                    <option id={value.value}>{value.label}</option>
                                                );
                                            })}
                                    </Input>

                                    {
                                        (field.elementType == "inputKey") &&
                                        <InputGroupAddon size="sm" addonType="append" className={'ml--2'}>
                                            <Button color={modeButtonAddon(field, "color")}
                                                size="sm"
                                                outline
                                                onClick={(e) => field.function(e, newInfo, field.id)}
                                                disabled={modeButtonAddon(field, "disabled")}>
                                                <span className="btn-inner--icon">
                                                    <i className={modeButtonAddon(field, "icon")} />
                                                </span>

                                            </Button>
                                        </InputGroupAddon>
                                    }
                                </InputGroup>


                            </FormGroup>
                        </Col>
                    )
                }
            </Row>
            <Row className="align-items-center mt-1">
                <Col xs={12} className={" d-flex justify-content-center align-items-center  " + (isMobile && "mt-1")}>
                    {/* <Spin size="small" spinning={localSpinner} tip={""} style={{ color: '' }}> */}
                    {/* <Button color="success" disabled={!readyToSend} block onClick={() => handleConfirmNoValidated(newInfo)}>
                            <span className="btn-inner--icon mr-1">
                                <i className={"fas fa-search"} />
                            </span>
                        </Button> */}

                    {/* <ButtonGeist loading={localSpinner} onClick={() => handleConfirmNoValidated(newInfo)} type={"success"} size={"sm"} style={{ width: isComputer ? "30%" : '100%' }} >
                        Consultar
                        <IoSearchSharp className="ml-2 mt-1" style={{ fontSize: "16px" }} />
                    </ButtonGeist> */}

                    <ButtonGeist
                        type={"success"}
                        size="small"
                        loading={localSpinner}
                        block
                        style={{ width: isComputer ? "30%" : '100%' }}
                        onClick={() => handleConfirmNoValidated(newInfo)} className=""
                        disabled={!readyToSend}
                    >
                        <span className="btn-inner--icon mr-2 " >
                            <i className={"fas fa-search"} />
                        </span>
                        <span className="btn-inner--text ">
                            Consultar
                        </span>
                    </ButtonGeist>
                </Col>
            </Row >
        </>
    )
}

export default Search;