/*!

=========================================================
*CardsInformations
=========================================================

*Componente diseñado para en la vista de estadísticas
 para visualizar los cards con información útil como:

    - Cantidad de bots.
    - Promedio ahorro anual.
    - Promedio ahorro mensual. 
=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/

//Libreria de react
import React, { useState, useContext, useEffect } from 'react';
//Componentes de reactstrap
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Dispatch para hacer peticiones
import { useDispatch } from 'react-redux';

//Actions para realizar peticiones al API
import { getGraphsData } from 'actions/databotDashboard.jsx';

//Componente para emitir notificaciones
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const CardsInformations = ({

}) => {

    //#region States Globales
    const {
        databotOptions,
        filterSelection,

    } = useContext(UserContext);
    //#endregion

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //State para los gráficos
    const [graphsData, setGraphsData] = useState([])

    useEffect(() => {
        const getDataAtStart = async () => {
            // debugger;
            await handleGetGraphsDataStart();

        }
        getDataAtStart();
    }, [])

    //Funcion para traer las rows al arrancar
    const handleGetGraphsDataStart = async () => {

        //#region Mensajes de notificaciones
        const MySwal = withReactContent(Swal);
        const Toast = MySwal.mixin({
            toast: true,
            position: 'bottom-right',
            showConfirmButton: true,
            timer: 5000,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });
        //#endregion

        //Dispatch de saving
        await dispatch(getGraphsData({
            year: new Date().getFullYear(),
            tower: '',
            functionalArea: '',
            department: '',
            bots: '',
            view: 'saving',
            mode: 'start'
        }
        )).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {

                setGraphsData(grapData => {
                    return {
                        ...grapData,
                        ['byMonth']: payload.data.saving.byMonth,
                        ['byYears']: payload.data.saving.byYears[0],
                    }
                })


            }
            else {
                Toast.fire({
                    title: payload.message,
                    type: 'warning'
                })
            }
        })

    }


    //Método para darle a un número un formato tipo dólar($9876,54.56).
    const numberWithCommas = (number) => {
        if (number >= 0)
            return `$${parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        else
            return null;
    };

    //Arreglo para desplegar cada card con sus respectivos label, colores, e iconos.
    const [fieldsCards, setFieldsCards] = useState([
        {
            id: "botsQuantity",
            description: "Cantidad de robots",
            styleCard: "success",
            styleIcon: "ni ni-bell-55"
        },
        {
            id: "montlySavingAVG",
            description: "Promedio de ahorro mensual",
            styleCard: "warning",
            styleIcon: "fas fa-exclamation"
        },
        {
            id: "yearSaving",
            description: "Ahorro del año",
            styleCard: "danger",
            styleIcon: "fas fa-exclamation-triangle"
        },
    ]);

    //State para almacenar los datos de cada card.
    const [amount, setAmount] = useState(
        {
            botsQuantity:0,// botsRows?.filter(bot => bot.area != 7).length,
            montlySavingAVG: 0,
            yearSavingAVG: 0,
        }
    )


    // //Efecto para establecer los datos de cada card cada que cambie graphsDataToCards (el cual es definido en la view stats)
    // useEffect(() => {

    //     if (graphsDataToCards?.byMonth != undefined) {

    //         let botsQuantity = botsRows?.filter(bot => bot.area != 7).length

    //         let graphsDataM = 0;
    //         let resultAVGMonths = 0;

    //         graphsDataM = Object.values(graphsDataToCards?.byMonth[0]).filter(month => month != 0);
    //         resultAVGMonths = graphsDataM?.reduce((a, b) => a + b, 0) / graphsDataM?.length

    //         resultAVGMonths = resultAVGMonths != NaN ? resultAVGMonths : 0

    //         debugger;
    //         let resultYears = ''
    //         try {
    //             resultYears = (graphsData?.byYears != undefined && filterSelection?.year != undefined && filterSelection?.year != '') ? (graphsData?.byYears[  filterSelection?.year?.label+'Y']) : (graphsData?.byYears[ new Date().getFullYear() +'Y'])
    //         } catch (e) { }


    //         setAmount(amount => {
    //             return {
    //                 ...amount,
    //                 ["botsQuantity"]: botsQuantity,
    //                 ["montlySavingAVG"]: numberWithCommas(resultAVGMonths),
    //                 ["yearSaving"]: numberWithCommas(resultYears)
    //             }
    //         })
    //     }
    // }, [graphsDataToCards])


    const [renderFirstTime, setRenderFirstTime] = useState(false)
    //Efecto para establecer los datos de cada card cada que cambie graphsDataToCards (el cual es definido en la view stats)
    useEffect(() => {

        // debugger;
        if (graphsData?.byMonth != undefined && databotOptions?.bots?.length != undefined && renderFirstTime == false
             ) {

            let botsQuantity = databotOptions?.bots?.length

            let graphsDataM = 0;
            let resultAVGMonths = 0;

            graphsDataM = Object.values(graphsData?.byMonth[0]).filter(month => month != 0);
            resultAVGMonths = graphsDataM?.reduce((a, b) => a + b, 0) / graphsDataM?.length

            resultAVGMonths = resultAVGMonths != NaN ? resultAVGMonths : 0

            let resultYears = ''
            try {
                resultYears = (graphsData?.byYears != undefined && filterSelection?.year != undefined && filterSelection?.year != '') ? (graphsData?.byYears[filterSelection?.year?.label + 'Y']) : (graphsData?.byYears[new Date().getFullYear() + 'Y'])
            } catch (e) { }


            setAmount(amount => {
                return {
                    ...amount,
                    ["botsQuantity"]: botsQuantity,
                    ["montlySavingAVG"]: numberWithCommas(resultAVGMonths),
                    ["yearSaving"]: numberWithCommas(resultYears)
                }
            })
            setRenderFirstTime(true)
        }
    }, [graphsData, databotOptions])




    return (
        <>
            <Row>
                {
                    fieldsCards.map((card) => (

                        <Col sm='12' md='4'>
                            <Card className={`bg-gradient-${card.styleCard} border-0 `}>
                                <CardBody>
                                    <Row>
                                        <Col className="col pr-0 pl-1" sm="10">
                                            <CardTitle
                                                className="text-uppercase text-muted mb-0 text-white"
                                                tag="h5"
                                            >
                                                {/* Excepciones */}
                                                {/* {card.id != 'yearSaving' ? card.description : card.description + ' ' + (filterSelection?.year != undefined && filterSelection?.year != '' ? filterSelection?.year.label : new Date().getFullYear())} */}
                                                {card.id != 'yearSaving' ? card.description : card.description + ' ' + (filterSelection?.year != undefined && filterSelection?.year != '' ? new Date().getFullYear() : new Date().getFullYear())}
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0 text-white">
                                                {amount[card.id]}
                                            </span>
                                        </Col>
                                        <Col className="col-auto pl-0" sm="2">
                                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                <i className={card.styleIcon} />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                    ))
                }
            </Row>
        </>
    )
}


export default CardsInformations;



