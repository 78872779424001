import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const employeeForms = handleActions(
  {
    [constants.GET_ALL_PERSONAL_INFO_EMPLOYEE_FORMS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const personalInfo = handleActions(
  {
    [constants.GET_PERSONAL_INFO_BY_EMPLOYEE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.CREATE_FAMILY_GROUP_BY_EMPLOYEE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.CREATE_EMERGENCY_CONTACT_BY_EMPLOYEE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const personalInfoComplete = handleActions(
  {
    [constants.GET_PERSONAL_INFO_COMPLETE_BY_EMPLOYEE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const maritalStatus = handleActions(
  {
    [constants.GET_MARITAL_STATUS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const relationShips = handleActions(
  {
    [constants.GET_RELATIONSHIPS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const familyGroups = handleActions(
  {
    [constants.GET_FAMILY_GROUP_BY_EMPLOYEE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const emergencyContactsEmployee = handleActions(
  {
    [constants.GET_EMERGENCY_CONTACTS_BY_EMPLOYEE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const emergencyContactsLoaded = handleActions(
  {
    [constants.LOAD_EMERGENCY_CONTACTS_BY_EMPLOYEE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);
