// core actions
import { findAllDataDashboard } from 'actions/targetLetter.jsx';
// antd components
import { Spin } from 'antd';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// core components TargetLetters
import Charts from 'components/TargetLetter/Charts.jsx';
import TransactionsLetters from 'components/TargetLetter/TransactionsLetters.jsx';
// javascript library for dates
import moment from 'moment';
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core selectors
import { getAllDataStatusByYear, getAllDataFlowsByYear, getAllCountriesOfTargetsLetters, getAllYearsOfTargetsLetters, getTransactionsByFlow, getTransactionsByStatus } from 'selectors/targetLetter.jsx';

class DashboardLetters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      typeTrasaction: 1,
      typeDashboard: {
        value: 1,
        label: 'Estado'
      },
      countrySelected: {
        value: 0,
        label: "Todos"
      },
      yearSelected: {
        value: 1,
        label: moment().year()
      }
    };
  };

  componentDidMount = () => {
    const { countrySelected, yearSelected } = this.state;
    this.findAllDataDashboardTargetLetters(countrySelected.label, yearSelected.label);
  };

  findAllDataDashboardTargetLetters = (country, year) => {
    this.setState({
      loading: true,
      message: "Cargando el dashboard de cartas"
    });
    this.props.findAllDataDashboard({
      country,
      year
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnSelectionTypeView = (who, type) => {
    console.log(who);
    const { countrySelected, yearSelected } = this.state;
    if (who === "typeDashboard") {
      this.setState({
        typeTrasaction: type.value,
        typeDashboard: type,
      });
    } else {
      this.setState({
        [who]: type
      });
      this.findAllDataDashboardTargetLetters(
        who === "countrySelected" ? type.label : countrySelected.label,
        who === "yearSelected" ? type.label : yearSelected.label,
      );
    }
  };

  render() {

    const {
      name,
      parentName,
      transactionsFlow,
      transactionsState,
      graphFlows,
      graphStatus,
      yearsOptions,
      countriesOptions,
    } = this.props;

    const {
      loading,
      message,
      typeDashboard,
      typeTrasaction,
      countrySelected,
      yearSelected,
    } = this.state;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          letterDashboard
          name={name}
          parentName={parentName}
          typeDashboardSelected={typeDashboard}
          countries={countriesOptions}
          yearsStatus={yearsOptions}
          countrySelected={countrySelected}
          yearSelected={yearSelected}
          selectionChange={this.handleOnSelectionTypeView}
        />
        <Spin size="large" spinning={loading} tip={message}>
          <Container className="mt--6" fluid>
            <TransactionsLetters
              type={typeTrasaction}
              data={typeTrasaction === 1 ? transactionsState : transactionsFlow}
            />
            <Charts
              bar
              title="Cartas de Objetivos"
              subtitle="Gráfico anual de las cartas de objetivos"
              object={typeTrasaction === 1 ? graphStatus : graphFlows}
            />
          </Container>
        </Spin>
      </>
    );
  }
}

DashboardLetters.defaultProps = {
  name: "Dashboard",
  parentName: "Cartas de Objetivos",
  transactionsFlow: {
    totalRequest: 0,
    totalRequestMonth: 0,
    totalManagers: 0,
    totalManagersMonth: 0,
    totalCollaborator: 0,
    totalCollaboratorMonth: 0,
    totalPayrrols: 0,
    totalPayrrolsMonth: 0,
  },
  transactionsState: {
    awaitingTotal: 0,
    awaitingTotalMonth: 0,
    rejectedTotal: 0,
    rejectedTotalMonth: 0,
    approvedTotal: 0,
    approvedTotalMonth: 0,
    appliedTotal: 0,
    appliedTotalMonth: 0,
  },
  countriesOptions: [],
  yearsOptions: [],
  graphStatus: {},
  graphFlows: {},
};

DashboardLetters.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  transactionsFlow: PropTypes.object.isRequired,
  transactionsState: PropTypes.object.isRequired,
  countriesOptions: PropTypes.array.isRequired,
  graphStatus: PropTypes.object.isRequired,
  graphFlows: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  transactionsFlow: getTransactionsByFlow(state),
  transactionsState: getTransactionsByStatus(state),
  countriesOptions: getAllCountriesOfTargetsLetters(state),
  yearsOptions: getAllYearsOfTargetsLetters(state),
  graphStatus: getAllDataStatusByYear(state),
  graphFlows: getAllDataFlowsByYear(state),
});

export default withRouter(connect(mapStateToProps, {
  findAllDataDashboard
})(DashboardLetters));