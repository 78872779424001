//Clave para encriptacion y desencriptacion
export const KEY = "JnhdQHb0srZs0EwKscsteUPLoPknlD0g";
// AUTH CONSTANTS
export const AUTH_USER = "AUTH_USER";
export const SIGN_OUT = "SIGN_OUT";
export const SIGNATURE = "SIGNATURE";
export const URL_CISCO = "URL_CISCO";
export const UUID = "UUID";
// PLANNING MRS CONTANTS
export const GET_NODES = "GET_NODES";
export const GET_RESOURSES = "GET_RESOURSES";

// EXTRA HOURS CONTANTS
export const GET_EXTRAS = "GET_EXTRAS";
export const GET_DATE_EXTRAS = "GET_DATE_EXTRAS";
export const GET_ALL_YEAR_EXTRAS_SUM_HOURS_WIDGET =
  "GET_ALL_YEAR_EXTRAS_SUM_HOURS_WIDGET";
export const GET_ALL_YEAR_EXTRAS_SUM_HOURS_GRAPH =
  "GET_ALL_YEAR_EXTRAS_SUM_HOURS_GRAPH";
export const GET_COUNTRY_YEAR_EXTRAS_SUM_HOURS_WIDGET =
  "GET_COUNTRY_YEAR_EXTRAS_SUM_HOURS_WIDGET";
export const GET_COUNTRY_YEAR_EXTRAS_SUM_HOURS_GRAPH =
  "GET_COUNTRY_YEAR_EXTRAS_SUM_HOURS_GRAPH";
export const GET_USER_YEAR = "GET_USER_YEAR";
export const GET_HOUR_USER = "GET_HOUR_USER";
export const GET_MONTH_WIDGET = "GET_MONTH_WIDGET";
export const GET_MONTH_WIDGET_COUNTRY = "GET_MONTH_WIDGET_COUNTRY";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const GET_EXTRAS_TO_SEND = "GET_EXTRAS_TO_SEND";
export const UPDATE_EXTRAS_SAP = "UPDATE_EXTRAS_SAP";
export const UPDATE_EXTRAS = "UPDATE_EXTRAS";
export const GET_YEARS_EXTRAS = "GET_YEARS_EXTRAS";
export const GET_USERS_WITH_ACCESS_EXTRA_HOURS =
  "GET_USERS_WITH_ACCESS_EXTRA_HOURS";
export const DEACTIVATE_USER_ROL_EXTRA_HOURS =
  "DEACTIVATE_USER_ROL_EXTRA_HOURS";
export const CREATE_USER_ROL_EXTRAS = "CREATE_USER_ROL_EXTRAS";
export const GET_EXTRA_HOURS_BY_CEO = "GET_EXTRA_HOURS_BY_CEO";
export const EH_UPDATE_STATUS = "EH_UPDATE_STATUS";
export const EH_GET_REASON_FOR_CHANGE_SAP = "EH_GET_REASON_FOR_CHANGE_SAP";

//MIS CONSTANTS
export const GET_INACTIVE = "GET_INACTIVE";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_MAINTENANCE = "GET_MAINTENANCE";

export const UPDATE_INACTIVE = "UPDATE_INACTIVE";
export const INSERT_INACTIVE = "INSERT_INACTIVE";

export const UPDATE_MAINTENANCE = "UPDATE_MAINTENANCE";
export const INSERT_MAINTENANCE = "INSERT_MAINTENANCE";

export const UPDATE_PROJECTS = "UPDATE_PROJECTS";
export const INSERT_PROJECTS = "INSERT_PROJECTS";
export const GET_INTERNAL_TEAMS = "GET_INTERNAL_TEAMS";

//NEW POSITION CONSTANTS
export const GET_USERS_WITH_ACCESS = "GET_USERS_WITH_ACCESS";
export const GET_POSITIONS = "GET_POSITIONS";
export const GET_DATA_BY_POSITIONS = "GET_DATA_BY_POSITIONS";
export const GET_DATA_UPDATE_BY_POSITION = "GET_DATA_UPDATE_BY_POSITION";
export const GET_RELATION_DATA_BY_POSITION = "GET_RELATION_DATA_BY_POSITION";
export const GET_TOTAL_POSITIONS_CREATED = "GET_TOTAL_POSITIONS_CREATED";
export const GET_UNPPROVED_VACANT_POSITION = "GET_UNPPROVED_VACANT_POSITION";
export const GET_ALL_REQUESTS_BY_USERS = "GET_ALL_REQUESTS_BY_USERS";
export const GET_REQUESTS_BY_USER = "GET_REQUESTS_BY_USER";
export const GET_VACANT_POSITION_BY_ID = "GET_VACANT_POSITION_BY_ID";
export const CREATE_UNPLANNED_POSITION = "CREATE_UNPLANNED_POSITION";
export const CREATE_STAFF_POSITION = "CREATE_STAFF_POSITION";
export const CREATE_VACANT_POSITION = "CREATE_VACANT_POSITION";
export const APPROVED_VACANT_POSITION = "APPROVED_VACANT_POSITION";
export const UNAPPROVED_VACANT_POSITION = "UNAPPROVED_VACANT_POSITION";
export const CREATE_RELATIONS_BY_POSITION = "CREATE_RELATIONS_BY_POSITION";
export const CLEAR_DATA = "CLEAR_DATA";
export const GET_COUNT_PROJECTS = "GET_COUNT_PROJECTS";
export const UPDATE_VACANT_POSITION = "UPDATE_VACANT_POSITION";
export const CREATE_USER_WITH_ACCESS = "CREATE_USER_WITH_ACCESS";
export const DEACTIVATED_USER_WITH_ACCESS = "DEACTIVATED_USER_WITH_ACCESS";
export const UPDATE_CECO_POSITION = "UPDATE_CECO_POSITION";
export const GET_ALL_MASTER_DATA_POSITIONS = "GET_ALL_MASTER_DATA_POSITIONS";
export const CREATE_MASTER_DATA_POSITIONS = "CREATE_MASTER_DATA_POSITIONS";
export const CREATE_PREAPPROVER_ACCESS = "CREATE_PREAPPROVER_ACCESS";
export const DELETE_PREAPPROVER_ACCESS = "DELETE_PREAPPROVER_ACCESS";

//WMS CONSTANTS
export const WMS_DASHBOARD_COUNT_TRANSACTIONS =
  "WMS_DASHBOARD_COUNT_TRANSACTIONS";
export const WMS_DASHBOARD_LOGIN_TOTALS = "WMS_DASHBOARD_LOGIN_TOTALS";
export const WMS_DASHBOARD_MOST_EXECUTED = "WMS_DASHBOARD_MOST_EXECUTED";
export const WMS_DASHBOARD_EXECUTED_TOTALS = "WMS_DASHBOARD_EXECUTED_TOTALS";
export const WMS_DASHBOARD_MOVEMENT_TOTALS = "WMS_DASHBOARD_MOVEMENT_TOTALS";
export const WMS_DASHBOARD_LOCATION_TOTALS = "WMS_DASHBOARD_LOCATION_TOTALS";
export const WMS_DASHBOARD_MONTHS_TOTALS = "WMS_DASHBOARD_MONTHS_TOTALS";
export const WMS_DASHBOARD_DAYS_TOTALS = "WMS_DASHBOARD_DAYS_TOTALS";

//SO Constanst
export const SO_ALL_ORDERS = "SO_ALL_ORDERS";
export const SO_COUNTRY_ORDERS = "SO_COUNTRY_ORDERS";
export const SO_USER_ORDERS = "SO_USER_ORDERS";
export const SO_ORDER_DETAILS = "SO_ORDER_DETAILS";

// COE CONSTANTS
export const GET_AVAILABLE_FILTERS = "GET_AVAILABLE_FILTERS";
export const ACTIVATE_AVAILABLE_FILTERS = "ACTIVATE_AVAILABLE_FILTERS";
export const GET_FILTERS_DATA_BY_FILTER = "GET_FILTERS_DATA_BY_FILTER";
export const GET_DATA_BY_CALENDAR_MODE = "GET_DATA_BY_CALENDAR_MODE";
export const GET_HISTORY_DATA_BY_CALENDAR_MODE =
  "GET_HISTORY_DATA_BY_CALENDAR_MODE";
export const GET_INDICATOR_DATA_BY_CALENDAR_MODE =
  "GET_INDICATOR_DATA_BY_CALENDAR_MODE";
export const GET_COLLABORATORS_REPORTED = "GET_COLLABORATORS_REPORTED";
export const GET_HOURS_ACCUSED = "GET_HOURS_ACCUSED";
export const GET_VALUES_MAINTENANCE_USERS = "GET_VALUES_MAINTENANCE_USERS";
export const GET_ACTIVITIES_REPORT = "GET_ACTIVITIES_REPORT";
export const CREATE_USER_COE = "CREATE_USER_COE";
export const GET_ALL_EMA_USERS_ACTIVE = "GET_ALL_EMA_USERS_ACTIVE";
export const UPDATE_USER_COE = "UPDATE_USER_COE";
export const CREATE_MASTER_VARIABLE = "CREATE_MASTER_VARIABLE";
export const UPDATE_MASTER_VARIABLE_BY_ID = "UPDATE_MASTER_VARIABLE_BY_ID";
export const DEACTIVATE_MASTER_VARIABLE_BY_ID =
  "DEACTIVATE_MASTER_VARIABLE_BY_ID";

// Digital Signature Constanst
export const GET_ALL_YEAR_SIGNATURES = "GET_ALL_YEAR_SIGNATURES";
export const GET_DATA_BY_DASHBOARD_SIGNATURE =
  "GET_DATA_BY_DASHBOARD_SIGNATURE";
export const GET_DATA_DASHBOARD_BY_YEAR = "GET_DATA_DASHBOARD_BY_YEAR";
export const GET_DATA_REGISTER_SIGNATURE = "GET_DATA_REGISTER_SIGNATURE";
export const CREATE_POLICY_SIGNATURE = "CREATE_POLICY_SIGNATURE";
export const CREATE_POLICY_USER_SIGNATURE = "CREATE_POLICY_USER_SIGNATURE";
export const CREATE_EXEPTION_USER_SIGNATURE = "CREATE_EXEPTION_USER_SIGNATURE";
export const GET_SIGNATURES_BY_USER = "GET_SIGNATURES_BY_USER";
export const SEND_ALL_DOCUMENTS_SIGNED = "SEND_ALL_DOCUMENTS_SIGNED";
export const GET_SIGNATURES_PENDING_BY_SUPERVISOR =
  "GET_SIGNATURES_PENDING_BY_SUPERVISOR";
export const GET_SIGNATURES_PENDING_BY_COLLABORATOR =
  "GET_SIGNATURES_PENDING_BY_COLLABORATOR";
export const UPDATE_STATE_SIGNATURE_IN_FLOW = "UPDATE_STATE_SIGNATURE_IN_FLOW";
export const GET_LOGS_FLOW_BY_SIGNATURE = "GET_LOGS_FLOW_BY_SIGNATURE";
export const UPDATE_SIGNATURE_BY_COLLABORATOR =
  "UPDATE_SIGNATURE_BY_COLLABORATOR";
export const RELAUNCH_SIGNATURE_BY_COLLABORATOR =
  "RELAUNCH_SIGNATURE_BY_COLLABORATOR";
export const GET_HOME_OFFICE_INFO_BY_SIGNATURE =
  "GET_HOME_OFFICE_INFO_BY_SIGNATURE";

// Notifications CONSTANTS
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";

// Critical Parts CONSTANTS
export const GET_INITIAL_VALUES_REQUERIMENT = "GET_INITIAL_VALUES_REQUERIMENT";
export const GET_ALL_REQUIREMENTS_BY_USER = "GET_ALL_REQUIREMENTS_BY_USER";
export const GET_VALUES_AND_EQUIPMENTS_BY_REQUIREMENT =
  "GET_VALUES_AND_EQUIPMENTS_BY_REQUIREMENT";
export const CREATE_REQUIREMENT = "CREATE_REQUIREMENT";
export const CREATE_IBM_EQUIPMENT = "CREATE_IBM_EQUIPMENT";
export const CREATE_CISCO_EQUIPMENT = "CREATE_CISCO_EQUIPMENT";
export const GET_ALL_CRITICAL_PARTS = "GET_ALL_CRITICAL_PARTS";
export const GET_ALL_DEBUGGED_MODELS = "GET_ALL_DEBUGGED_MODELS";
export const CREATE_CRITICAL_PART = "CREATE_CRITICAL_PART";
export const UPDATE_CRITICAL_PART = "UPDATE_CRITICAL_PART";
export const DELETE_CRITICAL_PART = "DELETE_CRITICAL_PART";
export const CREATE_DEBUGGED_MODEL = "CREATE_DEBUGGED_MODEL";
export const DELETE_DEBUGGED_MODEL = "DELETE_DEBUGGED_MODEL";
export const DOWNLOAD_CRITICAL_PARTS = "DOWNLOAD_CRITICAL_PARTS";

// Targets Letters CONSTANTS
export const GET_MY_TARGETS_LETTERS = "GET_MY_TARGETS_LETTERS";
export const GET_ALL_TARGETS_LETTERS = "GET_ALL_TARGETS_LETTERS";
export const GET_TARGET_LETTER_BY_USER = "GET_TARGET_LETTER_BY_USER";
export const GET_TARGETS_LETTER_HC = "GET_TARGETS_LETTER_HC";
export const GET_TARGETS_LETTER_HEADSHIP = "GET_TARGETS_LETTER_HEADSHIP";
export const GET_TARGETS_LETTER_HCRM = "GET_TARGETS_LETTER_HCRM";
export const UPDATE_TARGET_LETTER_FLOW = "UPDATE_TARGET_LETTER_FLOW";
export const DEACTIVATE_TARGET_LETTER = "DEACTIVATE_TARGET_LETTER";
export const APPLY_MEASURE_TARGET_LETTER = "APPLY_MEASURE_TARGET_LETTER";
export const REJECT_TARGET_LETTER_BY_ID = "REJECT_TARGET_LETTER_BY_ID";
export const GET_ALL_DATA_DASHBOARD_TARGET_LETTERS =
  "GET_ALL_DATA_DASHBOARD_TARGET_LETTERS";
export const GET_TARGETS_LETTER_GENERAL_MANAGER =
  "GET_TARGETS_LETTER_GENERAL_MANAGER";
export const GET_TARGETS_LETTER_MANAGEMENT_SERVICES_DIRECTOR =
  "GET_TARGETS_LETTER_MANAGEMENT_SERVICES_DIRECTOR";

// Events CONSTANTS
export const DEACTIVATE_EVENT_BY_ID = "DEACTIVATE_EVENT_BY_ID";
export const MAKE_MONETARY_DONATION_EVENT = "MAKE_MONETARY_DONATION_EVENT";
export const MAKE_DAYS_DONATION_EVENT = "MAKE_DAYS_DONATION_EVENT";

// Performance CONSTANTS
export const GET_ALL_DATA_DASHBOARD_PERFORMANCE =
  "GET_ALL_DATA_DASHBOARD_PERFORMANCE";
export const GET_ALL_SIGNIN_DASHBOARD_PERFORMANCE =
  "GET_ALL_SIGNIN_DASHBOARD_PERFORMANCE";
export const GET_ALL_TRANSACTIONS_DASHBOARD_PERFORMANCE =
  "GET_ALL_TRANSACTIONS_DASHBOARD_PERFORMANCE";

//SALARY CONSTANTS
export const AS_CREATE_REQUEST = "AS_CREATE_REQUEST";
export const AS_GET_APPROVERS = "AS_GET_APPROVERS";
export const AS_GET_OTHER_APPROVERS = "AS_GET_OTHER_APPROVERS";
export const AS_GET_REQUEST = "AS_GET_REQUEST";
export const AS_COMPLETE_REQUESTS = "AS_COMPLETE_REQUESTS";
export const AS_PENDING_REQUESTS = "AS_PENDING_REQUESTS";
export const AS_CANCELLED_REQUESTS = "AS_CANCELLED_REQUESTS";
export const AS_CHECK_SESSION = "AS_CHECK_SESSION";
export const AS_FULL_DOC = "AS_FULL_DOC";
export const AS_CANCEL_REQUEST = "AS_CANCEL_REQUEST";
export const AS_APPROVER_ACTION = "AS_APPROVER_ACTION";
export const AS_BYPASS_ACTION = "AS_BYPASS_ACTION";
export const AS_REMINDER = "AS_REMINDER";
export const AS_NOTIFICATIONS = "AS_NOTIFICATIONS";
export const AS_DOCUMENT_DOWNLOAD = "AS_DOCUMENT_DOWNLOAD";
export const AS_DOCUMENT_DELETE = "AS_DOCUMENT_DELETE";

// Sales Digital Request CONSTANS
export const GET_FORM_VALUES_REQUEST_OPPORTUNITY =
  "GET_FORM_VALUES_REQUEST_OPPORTUNITY";
export const GET_FORM_VALUES_EQUIPMENTS_REQUEST =
  "GET_FORM_VALUES_EQUIPMENTS_REQUEST";
export const GET_REQUERIMENTS_BY_USER = "GET_REQUERIMENTS_BY_USER";
export const GET_ACTIVITY_FLOW_REQUEST = "GET_ACTIVITY_FLOW_REQUEST";
export const GET_EQUIPMENTS_BY_REQUEST = "GET_EQUIPMENTS_BY_REQUEST";
export const GET_EQUIPMENTS_CREATED_BY_REQUEST =
  "GET_EQUIPMENTS_CREATED_BY_REQUEST";
export const GET_REFERENCES_BY_REQUEST = "GET_REFERENCES_BY_REQUEST";
export const GET_JUSTIFICATION_BY_REQUEST = "GET_JUSTIFICATION_BY_REQUEST";
export const GET_CONFIGURATIONS_BY_REQUEST = "GET_CONFIGURATIONS_BY_REQUEST";
export const GET_RESUME_OFFERS_BY_REQUEST_ID =
  "GET_RESUME_OFFERS_BY_REQUEST_ID";
export const GET_LAST_VERSION_BY_REQUEST = "GET_LAST_VERSION_BY_REQUEST";
export const GET_OPTIONS_REQUEST_TO_VERSION = "GET_OPTIONS_REQUEST_TO_VERSION";
export const CREATE_ONE_EQUIPMENT = "CREATE_ONE_EQUIPMENT";
export const CREATE_MANY_EQUIPMENTS = "CREATE_MANY_EQUIPMENTS";
export const CREATE_JUSTIFY_REQUEST = "CREATE_JUSTIFY_REQUEST";
export const CREATE_COMMENTARY_REQUEST = "CREATE_COMMENTARY_REQUEST";
export const CREATE_SERVICE_ORDER_REQUEST = "CREATE_SERVICE_ORDER_REQUEST";
export const CREATE_AJUST_OFFER_BY_REQUEST = "CREATE_AJUST_OFFER_BY_REQUEST";
export const CREATE_NEW_VERSION_BY_REQUEST = "CREATE_NEW_VERSION_BY_REQUEST";
export const UPDATE_EQUIPMENT_BY_ID = "UPDATE_EQUIPMENT_BY_ID";
export const UPDATE_ACTIVITY_FLOW = "UPDATE_ACTIVITY_FLOW";
export const UPDATE_STATE_REQUEST = "UPDATE_STATE_REQUEST";
export const DEACTIVATE_REFERENCE_BY_ID = "DEACTIVATE_REFERENCE_BY_ID";
export const DEACTIVATE_CONFIGURATIONS_BY_ID =
  "DEACTIVATE_CONFIGURATIONS_BY_ID";
export const CREATE_EQUIPMENT_SPARE = "CREATE_EQUIPMENT_SPARE";
export const GET_REFERENCES_SPARE_BY_REQUEST =
  "GET_REFERENCES_SPARE_BY_REQUEST";
export const UPDATE_EQUIPMENT_SPARE_BY_ID = "UPDATE_EQUIPMENT_SPARE_BY_ID";
export const DEACTIVATE_EQUIPMENT_SPARE_BY_ID =
  "DEACTIVATE_EQUIPMENT_SPARE_BY_ID";
export const GET_EQUIPMENTS_SPARE_BY_REQUEST =
  "GET_EQUIPMENTS_SPARE_BY_REQUEST";
export const DEACTIVATE_EQUIPMENT_BY_ID = "DEACTIVATE_EQUIPMENT_BY_ID";
export const DEACTIVATE_REFERENCES_AJUST_OFFER_BY_ID =
  "DEACTIVATE_REFERENCES_AJUST_OFFER_BY_ID";
export const SEND_EMAIL_OFFER_WON_REQUEST = "SEND_EMAIL_OFFER_WON_REQUEST";
export const UPDATE_AMOUNT_EQUIPMENTS_BY_REQUEST =
  "UPDATE_AMOUNT_EQUIPMENTS_BY_REQUEST";
export const CREATE_NEW_VERSION_BY_REJECT_REQUEST =
  "CREATE_NEW_VERSION_BY_REJECT_REQUEST";

// PLANNERS FLOW NOTIFICATIONS
export const GET_REQUESTS_PENDING_ASSIGNATION =
  "GET_REQUESTS_PENDING_ASSIGNATION";
export const CREATE_USER_ASSIGNMENT_BY_PLANNER =
  "CREATE_USER_ASSIGNMENT_BY_PLANNER";
export const VALIDATE_GBM_COLLABORATOR = "VALIDATE_GBM_COLLABORATOR";
export const GET_ALL_REQUESTS_PENDING_ASSIGNMENT =
  "GET_ALL_REQUESTS_PENDING_ASSIGNMENT";
export const GET_EQUIPMENTS_ASSIGNMENT_BY_REQUEST =
  "GET_EQUIPMENTS_ASSIGNMENT_BY_REQUEST";
export const CREATE_USER_ASSIGNMENT_WITH_EQUIPMENTS =
  "CREATE_USER_ASSIGNMENT_WITH_EQUIPMENTS";
export const GET_USER_ASSIGNMENT_BY_REQUEST = "GET_USER_ASSIGNMENT_BY_REQUEST";
export const GET_ALL_PARTS_EQUIPMENTS_BY_REQUEST =
  "GET_ALL_PARTS_EQUIPMENTS_BY_REQUEST";

// ENGINEERS FLOW NOTIFICATIONS
export const GET_REQUESTS_ASSIGNATION_USER = "GET_REQUESTS_ASSIGNATION_USER";
export const GET_CRITICAL_PARTS_KIT = "GET_CRITICAL_PARTS_KIT";
export const GET_EQUIPMENTS_IDS_SELECTED_PARTS =
  "GET_EQUIPMENTS_IDS_SELECTED_PARTS";
export const GET_EQUIPMENTS_IDS_PENDINGS_PARTS =
  "GET_EQUIPMENTS_IDS_PENDINGS_PARTS";
export const GET_SELECTED_PARTS_KIT_BY_REQUESTS =
  "GET_SELECTED_PARTS_KIT_BY_REQUESTS";
export const GET_SELECTED_PARTS_KIT_BY_EQUIPMENT =
  "GET_SELECTED_PARTS_KIT_BY_EQUIPMENT";
export const GET_HISTORY_SELECTED_PARTS_KIT_BY_REQUESTS =
  "GET_HISTORY_SELECTED_PARTS_KIT_BY_REQUESTS";
export const CREATE_SELECTED_PARTS_EQUIPMENT =
  "CREATE_SELECTED_PARTS_EQUIPMENT";
export const CREATE_COMMENT_TO_JTR = "CREATE_COMMENT_TO_JTR";
export const CREATE_PENDING_PARTS_EQUIPMENT = "CREATE_PENDING_PARTS_EQUIPMENT";
export const UPDATE_STATE_ASSIGNATION_USER = "UPDATE_STATE_ASSIGNATION_USER";
export const UPDATE_JTR_ASSIGNATION_USER = "UPDATE_JTR_ASSIGNATION_USER";
export const UPDATE_FRUS_AND_AMOUNTS_SELECTED_PARTS =
  "UPDATE_FRUS_AND_AMOUNTS_SELECTED_PARTS";
export const UPDATE_ASSIGNATION_USER_RETURN_PLANNING =
  "UPDATE_ASSIGNATION_USER_RETURN_PLANNING";

// INVENTORIES CONSTANTS
export const GET_REQUESTS_IN_QUOTES = "GET_REQUESTS_IN_QUOTES";
export const GET_INFO_REQUEST_IN_QUOTE = "GET_INFO_REQUEST_IN_QUOTE";
export const GET_COMMENTS_REQUEST_IN_QUOTE = "GET_COMMENTS_REQUEST_IN_QUOTE";
export const DOWNLOAD_PARTS_BY_MODEL_IN_QUOTE =
  "DOWNLOAD_PARTS_BY_MODEL_IN_QUOTE";
export const UPDATE_SUSTITUTES_AND_COSTS_PARTS =
  "UPDATE_SUSTITUTES_AND_COSTS_PARTS";
export const GET_FILES_REQUEST_IN_QUOTE = "GET_FILES_REQUEST_IN_QUOTE";

// PRICING CONSTANTS
export const GET_ALL_VARIABLES_MASTER_DATA = "GET_ALL_VARIABLES_MASTER_DATA";
export const GET_LOGS_MASTER_DATA_BY_ID = "GET_LOGS_MASTER_DATA_BY_ID";
export const GET_OFFERS_REQUEST_BY_ID = "GET_OFFERS_REQUEST_BY_ID";
export const GET_LOGS_BY_AJUST_OFFER = "GET_LOGS_BY_AJUST_OFFER";
export const UPDATE_VARIABLE_MASTER_DATA_BY_ID =
  "UPDATE_VARIABLE_MASTER_DATA_BY_ID";
export const UPDATE_CALC_OFFERS_REQUEST_BY_ID =
  "UPDATE_CALC_OFFERS_REQUEST_BY_ID";
export const UPDATE_MANUAL_CALC_OFFERS_REQUEST_BY_ID =
  "UPDATE_MANUAL_CALC_OFFERS_REQUEST_BY_ID";
export const UPDATE_STATE_OFFER_IN_PRICING_LIST =
  "UPDATE_STATE_OFFER_IN_PRICING_LIST";
export const GET_REQUEST_OFFERS_IN_AJUSTMENT =
  "GET_REQUEST_OFFERS_IN_AJUSTMENT";
export const DOWNLOAD_CALS_OFFER_BY_TYPE = "DOWNLOAD_CALS_OFFER_BY_TYPE";

// SUPPORT CONSTANTS
export const GET_MASTER_VARIABLES_BY_MASTER_TABLE =
  "GET_MASTER_VARIABLES_BY_MASTER_TABLE";
export const GET_ALL_USER_ESCALATION_TSS = "GET_ALL_USER_ESCALATION_TSS";
export const CREATE_MASTER_VARIABLE_BY_MASTER_TABLE =
  "CREATE_MASTER_VARIABLE_BY_MASTER_TABLE";
export const UPDATE_MASTER_VARIABLE_BY_MASTER_TABLE =
  "UPDATE_MASTER_VARIABLE_BY_MASTER_TABLE";
export const DELETE_MASTER_VARIABLE_BY_MASTER_TABLE =
  "DELETE_MASTER_VARIABLE_BY_MASTER_TABLE";
export const CREATE_USER_ESCALATION_TSS = "CREATE_USER_ESCALATION_TSS";
export const DELETE_USER_ESCALATION_TSS = "DELETE_USER_ESCALATION_TSS";

// MANAGERS CONSTANTS
export const GET_ALL_INFORMATIONS_DIGITAL_REQUESTS =
  "GET_ALL_INFORMATIONS_DIGITAL_REQUESTS";
export const GET_ALL_DATA_BY_DIGITAL_REQUESTS =
  "GET_ALL_DATA_BY_DIGITAL_REQUESTS";

// MEDICAL RECORDS CONTANS
export const GET_OFFICES = "GET_OFFICES";
export const GET_MEDICAL_RECORD = "GET_MEDICAL_RECORD";
export const CREATE_MEDICAL_RECORD = "CREATE_MEDICAL_RECORD";
export const UPDATE_MEDICAL_RECORD = "UPDATE_MEDICAL_RECORD";
export const GET_EMERGENCY_CONTACTS = "GET_EMERGENCY_CONTACTS";
export const CREATE_EMERGENCY_CONTACT = "CREATE_EMERGENCY_CONTACT";
export const UPDATE_EMERGENCY_CONTACT = "UPDATE_EMERGENCY_CONTACT";
export const DELETE_EMERGENCY_CONTACT = "DELETE_EMERGENCY_CONTACT";
export const GET_ALL_ALLERGIES = "GET_ALL_ALLERGIES";
export const CREATE_ALLERGY = "CREATE_ALLERGY";
export const UPDATE_ALLERGY = "UPDATE_ALLERGY";
export const DELETE_ALLERGY = "DELETE_ALLERGY";
export const GET_ALL_DISEASES = "GET_ALL_DISEASES";
export const CREATE_DISEASE = "CREATE_DISEASE";
export const UPDATE_DISEASE = "UPDATE_DISEASE";
export const DELETE_DISEASE = "DELETE_DISEASE";
export const GET_ALL_INTERVENTIONS = "GET_ALL_INTERVENTIONS";
export const CREATE_INTERVENTION = "CREATE_INTERVENTION";
export const UPDATE_INTERVENTION = "UPDATE_INTERVENTION";
export const DELETE_INTERVENTION = "DELETE_INTERVENTION";
export const GET_RECORDS_BY_ROL = "GET_RECORDS_BY_ROL";
export const GET_INFORMATION_DASHBOARD = "GET_INFORMATION_DASHBOARD";
export const GET_OTHER_TYPES = "GET_OTHER_TYPES";
export const GET_ALL_OTHER_MEDICAL_INFO = "GET_ALL_OTHER_MEDICAL_INFO";
export const CREATE_OTHER_MEDICAL_INFO = "CREATE_OTHER_MEDICAL_INFO";
export const UPDATE_OTHER_MEDICAL_INFO = "UPDATE_OTHER_MEDICAL_INFO";
export const DELETE_OTHER_MEDICAL_INFO = "DELETE_OTHER_MEDICAL_INFO";
export const UPDATE_MEDICATION = "UPDATE_MEDICATION";
export const GET_USERS_ACCESS_MEDICAL_RECORD =
  "GET_USERS_ACCESS_MEDICAL_RECORD";
export const CREATE_USER_ACCESS_MEDICAL_RECORD =
  "CREATE_USER_ACCESS_MEDICAL_RECORD";
export const HIDDEN_EVENT_MEDICAL_RECORD = "HIDDEN_EVENT_MEDICAL_RECORD";
export const DELETE_USER_ACCESS_MEDICAL_RECORD =
  "DELETE_USER_ACCESS_MEDICAL_RECORD";
export const UPLOAD_ATTACHMENTS_MEDICAL_RECORD =
  "UPLOAD_ATTACHMENTS_MEDICAL_RECORD";
export const DOWNLOAD_ATTACHMENT_MEDICAL_RECORD =
  "DOWNLOAD_ATTACHMENT_MEDICAL_RECORD";
export const GET_ALL_INFORMATION_CARDS_AND_CHART =
  "GET_ALL_INFORMATION_CARDS_AND_CHART";
export const GET_ALL_INFORMATION_YEAR_AND_COUNTRIES =
  "GET_ALL_INFORMATION_YEAR_AND_COUNTRIES";
//FF
export const FF_GET_OPTIONS = "FF_GET_OPTIONS";
export const FF_GET_UNIT_TYPE = "FF_GET_UNIT_TYPE";
export const FF_INSERT_UNIT_TYPE = "FF_INSERT_UNIT_TYPE";
export const FF_UPDATE_UNIT_TYPE = "FF_UPDATE_UNIT_TYPE";
export const FF_DELETE_UNIT_TYPE = "FF_DELETE_UNIT_TYPE";
export const FF_GET_ALL_DOCUMENTS = "FF_GET_ALL_DOCUMENTS";
export const FF_GET_DOCUMENT_BY_ID = "FF_GET_DOCUMENT_BY_ID";
export const FF_INSERT_DOCUMENT = "FF_INSERT_DOCUMENT";
export const FF_UPDATE_DOCUMENT = "FF_UPDATE_DOCUMENT";
export const FF_GET_ALL_PROFILES = "FF_GET_ALL_PROFILES";
export const FF_GET_PROFILE_BY_ID = "FF_GET_PROFILE_BY_ID";
export const FF_UPDATE_PROFILE = "FF_UPDATE_DOCUMENT";
export const FF_INSERT_PROFILE = "FF_INSERT_PROFILE";
export const FF_DELETE_PROFILE = "FF_DELETE_PROFILE";
export const FF_GET_ALL_TEMPLATE_POSITION = "FF_GET_ALL_TEMPLATE_POSITION";
export const FF_IS_AWAY = "FF_IS_AWAY";
export const FF_GET_USER_SIGN = "FF_GET_USER_SIGN";
export const FF_DELETE_TEMPLATE_POSITION = "FF_DELETE_TEMPLATE_POSITION";
export const FF_ADD_TEMPLATE_POSITION = "FF_ADD_TEMPLATE_POSITION";
export const FF_GET_ALL_PROFILE = "FF_GET_ALL_PROFILE";
export const FF_UPDATE_TEMPLATE_POSITION = "FF_UPDATE_TEMPLATE_POSITION";
export const FF_GET_PROFILE_BY_POSITION_ID = "FF_GET_PROFILE_BY_POSITION_ID";
export const FF_GET_ALL_CATEGORIES = "FF_GET_ALL_CATEGORIES";
export const FF_GET_DOCUMENT_APPROVAL_TEMPLATE_CATEGORY =
  "FF_GET_DOCUMENT_APPROVAL_TEMPLATE_CATEGORY";
export const FF_GET_UNIT_TYPE_BY_DOCUMENT = "FF_GET_UNIT_TYPE_BY_DOCUMENT";
export const FF_ADD_DOCUMENT_APPROVAL_TEMPLATE =
  "FF_ADD_DOCUMENT_APPROVAL_TEMPLATE";
export const FF_UPDATE_DOCUMENT_APPROVAL_TEMPLATE =
  "FF_UPDATE_DOCUMENT_APPROVAL_TEMPLATE";
export const FF_DELETE_DOCUMENT_APPROVAL_TEMPLATE =
  "FF_DELETE_DOCUMENT_APPROVAL_TEMPLATE";
export const FF_DOCUMENT_FIELD = "FF_DOCUMENT_FIELD";
export const FF_GET_RULES_AND_APPROVERS_BY_TEMPLATE =
  "FF_GET_RULES_AND_APPROVERS_BY_TEMPLATE";
export const FF_GET_REQUEST_BY_USER = "FF_GET_REQUEST_BY_USER";
export const FF_CREATE_REQUEST = "FF_CREATE_REQUEST";
export const FF_ADD_FILES = "FF_ADD_FILES";
export const FF_DELETE_FILE = "FF_DELETE_FILE";
export const FF_REQUEST_MY_FLOWS = "FF_REQUEST_MY_FLOWS";
export const FF_APPROVE_REQUEST = "FF_APPROVE_REQUEST";
export const FF_CANCEL_REQUEST = "FF_CANCEL_REQUEST";
export const FF_RELAUNCH_REQUEST = "FF_RELAUNCH_REQUEST";
export const FF_DOWNLOAD_ATTACHMENT = "FF_DOWNLOAD_ATTACHMENT";
export const FF_GET_ALL_REQUEST = "FF_GET_ALL_REQUEST";
export const FF_GET_ACCESS_REQUEST = "FF_GET_ACCESS_REQUEST";
export const FF_DELETE_FILE_REQUEST = "FF_DELETE_FILE_REQUEST";
export const FF_DELETE_DOCUMENT_BY_ID = "FF_DELETE_DOCUMENT_BY_ID";
export const FF_GET_REQUEST_ID = "FF_GET_REQUEST_ID";
export const FF_BUSINESSUNIT_BY_USER = "FF_BUSINESSUNIT_BY_USER";
export const FF_GET_REQUEST_RANGE_DATE = "FF_GET_REQUEST_RANGE_DATE";

//FINANCIAL FLOWS
export const FF_GET_FIELD_TYPES = "FF_GET_FIELD_TYPES";
export const FF_GET_ADMIN_DOCS = "FF_GET_ADMIN_DOCS";
export const FF_GET_CATEGORIES = "FF_GET_CATEGORIES";
export const FF_GET_BUSINESS_UNITS = "FF_GET_BUSINESS_UNITS";

export const FF_CREATE_DOCUMENT = "FF_CREATE_DOCUMENT";
// export const FF_UPDATE_DOCUMENT = "FF_UPDATE_DOCUMENT";

export const FF_CREATE_FIELD_TYPE = "FF_CREATE_FIELD_TYPE";
export const FF_DELETE_FIELD_TYPE = "FF_DELETE_FIELD_TYPE";
export const FF_UPDATE_FIELD_TYPE = "FF_UPDATE_FIELD_TYPE";

export const FF_GET_TEMPLATES_CATEGORY_BU = "FF_GET_TEMPLATES_CATEGORY_BU";
export const FF_GET_DOCUMENTS_CATEGORY = "FF_GET_DOCUMENTS_CATEGORY";
export const FF_CREATE_DOCUMENT_TEMPLATE = "FF_CREATE_DOCUMENT_TEMPLATE";
export const FF_UPDATE_DOCUMENT_TEMPLATE = "FF_UPDATE_DOCUMENT_TEMPLATE";
export const FF_GET_TEMPLATE_DOCUMENT = "FF_GET_TEMPLATE_DOCUMENT";
export const FF_DELETE_TEMPLATE = "FF_DELETE_TEMPLATE";

//export const FF_CREATE_REQUEST = "FF_CREATE_REQUEST";
export const FF_GET_REQUEST = "FF_GET_REQUEST";
export const FF_GET_REQUESTS = "FF_GET_REQUESTS";
export const FF_UPDATE_REQUEST = "FF_UPDATE_REQUEST";
export const FF_MY_REQUEST = "FF_MY_REQUEST";

export const FF_CREATE_POSITIONS = "FF_CREATE_POSITIONS";
export const FF_GET_POSITIONS = "FF_GET_POSITIONS";
export const FF_UPDATE_POSITIONS = "FF_UPDATE_POSITIONS";

export const FF_APPROVAL_ACTION = "FF_APPROVAL_ACTION";
export const FF_TEMPLATE_QUERY = "FF_TEMPLATE_QUERY";

//export const FF_DOWNLOAD_ATTACHMENT = "FF_DOWNLOAD_ATTACHMENT";
export const FF_ADD_ATTACHMENT = "FF_ADD_ATTACHMENT";
export const FF_GET_USER = "FF_GET_USER";
export const FF_NOTIFICATIONS = "FF_NOTIFICATIONS";
export const FF_REMINDER = "FF_REMINDER";
export const FF_TOGGLE_AWAY = "FF_TOGGLE_AWAY";
export const FF_TOGGLE_TEMPLATE = "FF_TOGGLE_TEMPLATE";
export const FF_PREVENT_RELAUNCH = "FF_PREVENT_RELAUNCH";
export const FF_RELAUNCH = "FF_RELAUNCH";
export const FF_REMOVE_FILE = "FF_REMOVE_FILE";

export const FF_GET_PROFILE = "FF_GET_PROFILE";
export const FF_CREATE_PROFILE = "FF_CREATE_PROFILE";
// export const FF_UPDATE_PROFILE = "FF_UPDATE_PROFILE";
export const FF_TOGGLE_PROFILE = "FF_TOGGLE_PROFILE";
export const FF_GET_STATES = "FF_GET_STATES";
export const FF_RR_ACTION = "FF_RR_ACTION";
export const FF_TOGGLE_POSITION = "FF_TOGGLE_POSITION";

//Contacts
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_FUNCTIONS = "GET_FUNCTIONS";
export const GET_DEPARTAMENTS = "GET_DEPARTAMENTS";
export const GET_COUNTRYS = "GET_COUNTRYS";
export const GET_HISTORY = "GET_HISTORY";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONFIRM_CONTACT = "CONFIRM_CONTACT";
export const DELETE_CONFIRM_CONTACT = "DELETE_CONFIRM_CONTACT";
export const LOCK_CONTACT = "LOCK_CONTACT";
export const GET_FILE_UPDATE = "GET_FILE_UPDATE";
export const DOWNLOAD_EXCEL = "DOWNLOAD_EXCEL";
export const CONSTAT_EMPLOYEE = "CONSTAT_EMPLOYEE";
export const CONSTAT_MANAGER = "CONSTAT_MANAGER";
export const CONSTAT_CUSTOMER = "CONSTAT_CUSTOMER";
export const REPORT_HISTORY = "REPORT_HISTORY";
export const GET_CONFIRM_ROWS = "GET_CONFIRM_ROWS";

//Incidents Reports
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PLATFORMS = "GET_PLATFORMS";
export const GET_SERVICES = "GET_SERVICES";
export const INSERT_DATA = "INSERT_DATA";
export const REMOVE_DATA = "REMOVE_DATA";
export const GET_DATA = "GET_DATA";

//Document System
export const DS_GET_DATA = "DS_GET_DATA";
export const DS_GET_OPTIONS = "DS_GET_OPTIONS";
export const DS_NEW_REQUEST = "DS_NEW_REQUEST";
export const DS_UPDATE_REQUEST = "DS_UPDATE_REQUEST";
export const DS_GET_LOG = "DS_GET_LOG";
export const DS_DOWNLOAD_FILE = "DS_DOWNLOAD_FILE";
export const DS_GET_CUSTOMERS = "DS_GET_CUSTOMERS";
export const DELETE_FILE = "DELETE_FILE";
export const DELETE_ALL_FILES = "DELETE_ALL_FILES";
export const DS_GET_FILES_CUSTOMER = "DS_GET_FILES_CUSTOMER";
export const DS_REPORTING = "DS_REPORTING";
export const DS_GET_REPORT_COLUMNS = "DS_GET_REPORT_COLUMNS";
export const DS_GET_REPORTS = "DS_GET_REPORTS";
export const DS_DELETE_REPORT = "DS_DELETE_REPORT";
export const DS_SAVE_REPORT = "DS_SAVE_REPORT";

// SECOH CONSTANTS
export const GET_ALL_CONTRACTS_ON_HOLD = "GET_ALL_CONTRACTS_ON_HOLD";
export const GET_ALL_DATA_MASTER_BY_STATUS = "GET_ALL_DATA_MASTER_BY_STATUS";
export const GET_ALL_STATUS_AVAILABLE = "GET_ALL_STATUS_AVAILABLE";
export const GET_ACTIVITY_FLOW_BY_CONTRACT_ID =
  "GET_ACTIVITY_FLOW_BY_CONTRACT_ID";
export const GET_USERS_NOTIFICATION_ESCALATIONS =
  "GET_USERS_NOTIFICATION_ESCALATIONS";
export const GET_USERS_ESCALATIONS = "GET_USERS_ESCALATIONS";
export const GET_DASHBOARD_CONTRACTS_ON_HOLD_BY_TYPE =
  "GET_DASHBOARD_CONTRACTS_ON_HOLD_BY_TYPE";
export const GET_CONTRACTS_GRAPH_DETAIL = "GET_CONTRACTS_GRAPH_DETAIL";
export const GET_CONTRACTS_GRAPH_DETAIL_BY_COUNTRY =
  "GET_CONTRACTS_GRAPH_DETAIL_BY_COUNTRY";
export const CREATE_ACTIVITY_LOG_BY_CONTRACT_ID =
  "CREATE_ACTIVITY_LOG_BY_CONTRACT_ID";
export const CREATE_USER_ESCALATION_NOTIFICATION =
  "CREATE_USER_ESCALATION_NOTIFICATION";
export const CREATE_USER_ESCALATION = "CREATE_USER_ESCALATION";
export const UPDATE_STATUS_CONTRACT_ON_HOLD_BY_ID =
  "UPDATE_STATUS_CONTRACT_ON_HOLD_BY_ID";
export const UPDATE_TARGET_START_DATE_CONTRACT_ON_HOLD =
  "UPDATE_TARGET_START_DATE_CONTRACT_ON_HOLD";
export const UPDATE_USER_ESCALATION_NOTIFICATION_BY_ID =
  "UPDATE_USER_ESCALATION_NOTIFICATION_BY_ID";
export const UPDATE_STATUS_BY_ID = "UPDATE_STATUS_BY_ID";
export const DEACTIVATE_USER_ESCALATION_NOTIFICATION_BY_ID =
  "DEACTIVATE_USER_ESCALATION_NOTIFICATION_BY_ID";
export const DEACTIVATE_STATUS_BY_ID = "DEACTIVATE_STATUS_BY_ID";
export const UPDATE_DATA_MASTER_BY_ID = "UPDATE_DATA_MASTER_BY_ID";
export const DEACTIVATE_DATA_MASTER_BY_ID = "DEACTIVATE_DATA_MASTER_BY_ID";
export const CREATE_STATUS_CONTRACT_ON_HOLD = "CREATE_STATUS_CONTRACT_ON_HOLD";
export const CREATE_DATA_MASTER = "CREATE_DATA_MASTER";
export const GET_ALL_USER_FM_AND_PM = "GET_ALL_USER_FM_AND_PM";
export const CREATE_USER_FM_PM_ASSIGN = "CREATE_USER_FM_PM_ASSIGN";
export const GET_ALL_USERS_ACCESS_ON_HOLD = "GET_ALL_USERS_ACCESS_ON_HOLD";
export const DEACTIVATE_ACCESS_USER_ON_HOLD_BY_ID =
  "DEACTIVATE_ACCESS_USER_ON_HOLD_BY_ID";
export const CREATE_ACCESS_USER_ON_HOLD = "CREATE_ACCESS_USER_ON_HOLD";
export const EDIT_ACCESS_USER_ON_HOLD = "EDIT_ACCESS_USER_ON_HOLD";

//HCM Hiring PB10
export const HC_GET_DATA = "HC_GET_DATA";
export const HC_GET_OPTIONS = "HC_GET_OPTIONS";
export const HC_NEW_REQUEST = "HC_NEW_REQUEST";
export const HC_UPDATE_REQUEST = "HC_UPDATE_REQUEST";
export const HC_DOWNLOAD_FILE = "HC_DOWNLOAD_FILE";
export const HC_DELETE_FILE = "HC_DELETE_FILE";
export const HC_DELETE_ALL_FILE = "HC_DELETE_ALL_FILE";
export const HC_GET_CANDIDATE_INFO = "HC_GET_CANDIDATE_INFO";
export const HC_GET_POSITION_INFO = "HC_GET_POSITION_INFO";
export const HC_CHANGE_STATUS_BOT = "HC_CHANGE_STATUS_BOT";

//PANAMA BIDS
export const GET_MASTER_DATA = "GET_MASTER_DATA";
export const GET_ALL_ENTITIES = "GET_ALL_ENTITIES";
export const UPDATE_ENTITY = "UPDATE_ENTITY";
export const CREATE_ENTITY = "CREATE_ENTITY";
export const GET_CONTACTS_ENTITY = "GET_CONTACTS_ENTITY";
export const GET_ALL_PURCHASE_ORDER_MACRO = "GET_ALL_PURCHASE_ORDER_MACRO";
export const GET_ALL_PURCHASE_ORDER_PRODUCT = "GET_ALL_PURCHASE_ORDER_PRODUCT";
export const GET_ALL_PRODUCTSPB = "GET_ALL_PRODUCTSPB";
export const DELETE_ENTITY = "DELETE_ENTITY";
export const GET_COLUMS_PURCHASE_ORDER_MACRO =
  "GET_COLUMS_PURCHASE_ORDER_MACRO";
export const GET_DATA_ENTITIES = "GET_DATA_ENTITIES";
export const UPDATE_PURCHASE_ORDER_MACRO = "UPDATE_PURCHASE_ORDER_MACRO";
export const GET_ALL_DELIVERY_METHOD = "GET_ALL_DELIVERY_METHOD";
export const GET_COMPETITIONS = "GET_COMPETITIONS";
export const GET_COMPETITIONS_PRODUCTS = "GET_COMPETITIONS_PRODUCTS";
export const GET_COLUMS_COMPETITION = "GET_COLUMS_COMPETITION";
export const GET_ALL_FAST_COTIZATION_REPORT = "GET_ALL_FAST_COTIZATION_REPORT";
export const GET_ALL_PRODUCTS_FAST_COTYZATION =
  "GET_ALL_PRODUCTS_FAST_COTYZATION";
export const GET_COLUMS_FAST_COTYZATION_REPORT = "GET_COLUMS_FAST_COLUMS";
export const UPDATE_FAST_COTYZATION_REPORT = "UPDATE_FAST_COTYZATION_REPORT";
export const UPDATE_FAST_COTYZATION_REPORT_STATUS =
  "UPDATE_FAST_COTYZATION_REPORT_STATUS";
export const GET_ALL_FILES = "GET_ALL_FILES";
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";
export const GET_DATA_REPORT = "GET_DATA_REPORT";
export const GET_DATA_COMPETITION_REPORT = "GET_DATA_COMPETITION_REPORT";

//COSTA RICA BIDS
export const GET_MASTER_DATA_CR_BIDS = "GET_MASTER_DATA_CR_BIDS";
export const GET_ALL_PURCHASE_ORDER_CR_BIDS = "GET_ALL_PURCHASE_ORDER_CR_BIDS";
export const UPDATE_PURCHASE_ORDER_CR_BIDS = "UPDATE_PURCHASE_ORDER_CR_BIDS";
export const GET_CONTACTS_CR_BIDS = "GET_CONTACTS_CR_BIDS";
export const DELETE_FILES_CRBIDS = "DELETE_FILES_CRBIDS";
export const DELETE_ALL_FILES_CRBIDS = "DELETE_ALL_FILES_CRBIDS";
export const GET_PRODUCTS_CR_BIDS = "GET_PRODUCTS_CR_BIDS";
export const GET_FILES_CR_BIDS_BY_PO = "GET_FILES_CR_BIDS_BY_PO";
export const GET_EXCEL_REPORT_CR_BIDS = "GET_EXCEL_REPORT_CR_BIDS";
export const INSERT_SALES_TEAM_CR_BIDS = "INSERT_SALES_TEAM_CR_BIDS";
export const DELETE_SALES_TEAM_CR_BIDS = "DELETE_SALES_TEAM_CR_BIDS";

//EXIT INTERVIEW
export const GET_DATA_EXIT_INTERVIEW = "GET_DATA_EXIT_INTERVIEW";
export const GET_INTERVIEW = "GET_INTERVIEW";
export const GET_DRAFT_INTERVIEW = "GET_DRAFT_INTERVIEW";
export const GET_DRAFT_INTERVIEW_STATUS = "GET_DRAFT_INTERVIEW_STATUS";
export const INSERT_EXIT_TYPE = "INSERT_EXIT_TYPE";
export const INSERT_EXIT_REASON = "INSERT_EXIT_REASON";
export const CHANGE_STATUS_EXIT_TYPE = "CHANGE_STATUS_EXIT_TYPE";
export const CHANGE_STATUS_EXIT_REASON = "CHANGE_STATUS_EXIT_REASON";
export const GET_DATA_CHART1_EXIT_INTERVIEW = "GET_DATA_CHART1_EXIT_INTERVIEW";
export const GET_DATA_CHART_EXIT_TYPE_INTERVIEW =
  "GET_DATA_CHART_EXIT_TYPE_INTERVIEW";
export const GET_DATA_CHART_EXIT_REASONS_INTERVIEW =
  "GET_DATA_CHART_EXIT_REASONS_INTERVIEW";
export const INSERT_DRAFT_INTERVIEW = "INSERT_DRAFT_INTERVIEW";
export const INSERT_INTERVIEW = "INSERT_INTERVIEW";
export const GET_DATA_CHART_WORDS = "GET_DATA_CHART_WORDS";
export const UPDATE_DRAFT_INTERVIEW = "UPDATE_DRAFT_INTERVIEW";
export const DELETE_DRAFT_INTERVIEW = "DELETE_DRAFT_INTERVIEW";
export const DRAFTINTERVIEW_TO_INTERVIEW = "DRAFTINTERVIEW_TO_INTERVIEW";
export const GET_INFO_BY_USER_EXIT_INTERVIEW =
  "GET_INFO_BY_USER_EXIT_INTERVIEW";
export const SEND_EMAIL_USER_EXIT_INTERVIEW = "SEND_EMAIL_USER_EXIT_INTERVIEW";
export const SEND_EMAIL_HCM_EXIT_INTERVIEW = "SEND_EMAIL_HCM_EXIT_INTERVIEW";
export const UPDATE_INTERVIEW = "UPDATE_INTERVIEW";
export const GETCOUNTRIESBYHCM = "GETCOUNTRIESBYHCM";
//AUTOPP LDRS
export const AUPP_GET_DATA = "AUPP_GET_DATA";
export const AUPP_GET_DATABAW = "AUPP_GET_DATABAW";
export const AUPP_GET_OPTIONS = "AUPP_GET_OPTIONS";
export const AUPP_GET_LDRS_OPTIONS = "AUPP_GET_LDRS_OPTIONS";
export const AUPP_GET_LDR_FIELDS = "AUPP_GET_LDR_FIELDS";
export const AUPP_GET_CONTACTS = "AUPP_GET_CONTACTS";
export const AUPP_GET_COSTUMERS = "AUPP_GET_COSTUMERS";
export const AUPP_GET_EMPLOYEES = "AUPP_GET_EMPLOYEES";
export const AUPP_NEW_REQUEST = "AUPP_NEW_REQUEST";
export const AUPP_NEW_DEVOLUTIONBAWREQ = "AUPP_NEW_DEVOLUTIONBAWREQ";
export const AUPP_DELETE_FILE = "AUPP_DELETE_FILE";
export const AUPP_DELETE_FOLDER_BOM = "AUPP_DELETE_FOLDER_BOM";
export const AUPP_CARE_OPTIONS = "AUPP_CARE_OPTIONS";
export const AUPP_NEW_LDR_FORM = "AUPP_NEW_LDR_FORM";
export const AUPP_DELETE_LDR = "AUPP_DELETE_LDR";
export const AUPP_GET_LDR_FORMS = "AUPP_GET_LDR_FORMS";
export const AUPP_DELETE_BRAND = "AUPP_DELETE_BRAND";
export const AUPP_UPDATE_BRAND = "AUPP_UPDATE_BRAND";
export const AUPP_NEW_BRAND_LDR = "AUPP_NEW_BRAND_LDR";

//DATABOT DASHBOARDS
export const DB_GET_AREAS = "DB_GET_AREAS";
export const DB_TURN_BOT = "DB_TURN_BOT";
export const DB_GET_BOTS = "DB_GET_BOTS";
export const DB_GET_BOT_DETAILS = "DB_GET_BOT_DETAILS";
export const DB_GET_BOT_LOG_OR_DETAILS_SERVERSIDE =
  "DB_GET_BOT_LOG_OR_DETAILS_SERVERSIDE";
export const DB_GET_MANDANTES = "DB_GET_MANDANTES";
export const DB_TURN_MAND = "DB_TURN_MAND";
export const DB_GET_OPTIONS = "DB_GET_OPTIONS";
export const DB_GET_GRAPHS_DATA = "DB_GET_GRAPHS_DATA";
export const DB_NEW_BOT = "DB_NEW_BOT";
export const DB_GET_TOP_SAVING_BOTS = "DB_GET_TOP_SAVING_BOTS";
export const DB_ACTIVATE_BOT = "DB_ACTIVATE_BOT";
export const DB_DELETE_BOT = "DB_DELETE_BOT";

//KNOW EMPLOYEE
export const GET_ALL_PERSONAL_INFO_EMPLOYEE_FORMS =
  "GET_ALL_PERSONAL_INFO_EMPLOYEE_FORMS";
export const GET_MARITAL_STATUS = "GET_MARITAL_STATUS";
export const GET_RELATIONSHIPS = "GET_RELATIONSHIPS";
export const GET_PERSONAL_INFO_BY_EMPLOYEE = "GET_PERSONAL_INFO_BY_EMPLOYEE";
export const GET_PERSONAL_INFO_COMPLETE_BY_EMPLOYEE =
  "GET_PERSONAL_INFO_COMPLETE_BY_EMPLOYEE";
export const GET_FAMILY_GROUP_BY_EMPLOYEE = "GET_FAMILY_GROUP_BY_EMPLOYEE";
export const GET_EMERGENCY_CONTACTS_BY_EMPLOYEE =
  "GET_EMERGENCY_CONTACTS_BY_EMPLOYEE";
export const CREATE_FAMILY_GROUP_BY_EMPLOYEE =
  "CREATE_FAMILY_GROUP_BY_EMPLOYEE";
export const CREATE_EMERGENCY_CONTACT_BY_EMPLOYEE =
  "CREATE_EMERGENCY_CONTACT_BY_EMPLOYEE";
export const COMPLETE_PERSONAL_INFO_BY_EMPLOYEE =
  "COMPLETE_PERSONAL_INFO_BY_EMPLOYEE";
export const LOAD_EMERGENCY_CONTACTS_BY_EMPLOYEE =
  "LOAD_EMERGENCY_CONTACTS_BY_EMPLOYEE";
export const DEACTIVATED_FAMILY_GROUP_BY_ID = "DEACTIVATED_FAMILY_GROUP_BY_ID";
export const DEACTIVATED_EMERGENCY_CONTACT_BY_ID =
  "DEACTIVATED_EMERGENCY_CONTACT_BY_ID";
export const CREATE_MANY_EMERGENCY_CONTACT_BY_EMPLOYEE =
  "CREATE_MANY_EMERGENCY_CONTACT_BY_EMPLOYEE";

// Conflict Interests Contants
export const GET_CONFLICT_INTERESTS_PENDING_INFO =
  "GET_CONFLICT_INTERESTS_PENDING_INFO";
export const GET_ALL_CONFLICT_INTERESTS_INFO =
  "GET_ALL_CONFLICT_INTERESTS_INFO";
export const CREATE_CONFLICT_INTERESTS_INFO_BY_ID =
  "CREATE_CONFLICT_INTERESTS_INFO_BY_ID";
export const GET_CONFLICT_INTERESTS_INFO = "GET_CONFLICT_INTERESTS_INFO";

//MASTER DATA
export const MD_GET_DATA_ROWS = "MD_GET_DATA_ROWS";
export const MD_GET_LINEAL_MASIVE_DATA = "MD_GET_LINEAL_MASIVE_DATA";
export const MD_GET_OPTIONS = "MD_GET_OPTIONS";
export const MD_GET_DATA_APPROVALS_ROWS = "MD_GET_DATA_ROWS";
export const MD_APPROVAL_REJECT_REQUEST = "MD_APPROVAL_REJECT_REQUEST";
export const MD_DELETE_FILE = "MD_DELETE_FILE";
export const MD_DELETE_FILE_BYTEMPID = "MD_DELETE_FILE_BYTEMPID";
export const MD_GET_EMPLOYEES = "MD_GET_EMPLOYEES";
export const MD_EXIST_MASTERDATA = "MD_EXIST_MASTERDATA";
export const MD_NEW_REQUEST = "MD_NEW_REQUEST";
export const MD_GET_DATA_ALL_ROWS = "MD_GET_DATA_ALL_ROWS";
export const MD_GET_QUANTITY_ALL_ROWS = "MD_GET_QUANTITY_ALL_ROWS";
export const MD_UPDATE_STATUS = "MD_UPDATE_STATUS";
export const GET_APPROVERS = "GET_APPROVERS";
export const GET_MOTHERTABLE = "GET_MOTHERTABLE";
export const DELETE_APPROVERS = "DELETE_APPROVERS";
export const MD_DELETE_TEMPLATE = "MD_DELETE_TEMPLATE";
export const MD_SAVE_TEMPLATE = "MD_SAVE_TEMPLATE";
export const MD_GET_MAINTENANCE_TABLE_INFO = "MD_GET_MAINTENANCE_TABLE_INFO";
export const MD_MAINTENANCE_ACTION = "MD_MAINTENANCE_ACTION";
export const MD_GET_FORM_FIELDS = "MD_GET_FORM_FIELDS";
export const MD_NEW_APPROVERS = "MD_NEW_APPROVERS";
export const MD_OPTIONS_MAINTENANCE_VIEW = "MD_OPTIONS_MAINTENANCE_VIEW";
export const MD_GET_GENERAL_INFO = "MD_GET_GENERAL_INFO";

//Business System
export const BS_GET_DATA = "BS_GET_DATA";
export const BS_GET_ORDERS_ASSIGN = "BS_GET_ORDERS_ASSIGN";
export const BS_GET_REPORT_US = "BS_GET_REPORT_US";
export const BS_GET_REPORT_FLOW = "BS_GET_REPORT_FLOW";
export const BS_UPDATE_REQUEST = "BS_UPDATE_REQUEST";
export const BS_INSERT_REQUEST = "BS_INSERT_REQUEST";

//FREELANCE
export const FREELANCE_GET_DATA = "FREELANCE_GET_DATA";
export const FREELANCE_GET_OPTIONS = "FREELANCE_GET_OPTIONS";
export const FREELANCE_GET_PO_INFO = "FREELANCE_GET_PO_INFO";
export const FREELANCE_NEW_PO_ASSIG = "FREELANCE_NEW_PO_ASSIG";
export const FREELANCE_NEW_RESPONSIBLE = "FREELANCE_NEW_RESPONSIBLE";
export const FREELANCE_INACTIVE_PO = "FREELANCE_INACTIVE_PO";
export const FREELANCE_REPORT_ACTIONS = "FREELANCE_REPORT_ACTIONS";
export const FREELANCE_APPROVE_MASS = "FREELANCE_APPROVE_MASS";
export const FREELANCE_HES_ACTION = "FREELANCE_HES_ACTION";
export const FREELANCE_BILLS_ACTION = "FREELANCE_BILLS_ACTION";
export const FREELANCE_GET_FINANCE_EMAILS = "FREELANCE_GET_FINANCE_EMAILS";
export const FREELANCE_BLOCK_ACCESS = "FREELANCE_BLOCK_ACCESS";
export const FREELANCE_DELETE_ACCESS = "FREELANCE_DELETE_ACCESS";
export const FREELANCE_NEW_PASS = "FREELANCE_NEW_PASS";
export const FREELANCE_UPDATE_ACCESS_FREELANCE =
  "FREELANCE_UPDATE_ACCESS_FREELANCE";
export const FREELANCE_NEW_PROJECT = "FREELANCE_NEW_PROJECT";
export const FREELANCE_BLOCK_PROJECT = "FREELANCE_BLOCK_PROJECT";
export const FREELANCE_NEW_APPROVER = "FREELANCE_NEW_APPROVER";
export const FREELANCE_BLOCK_APPROVER = "FREELANCE_BLOCK_APPROVER";
export const FREELANCE_UPDATE_EMAIL_BILLING = "FREELANCE_UPDATE_EMAIL_BILLING";
export const FREELANCE_GET_INFO_REPORT = "FREELANCE_GET_INFO_REPORT";
export const FREELANCE_DELETE_FILE = "FREELANCE_DELETE_FILE";
export const FREELANCE_DELETE_FILE_ACTA = "FREELANCE_DELETE_FILE_ACTA";
export const FREELANCE_DELETE_ALL_FILES = "FREELANCE_DELETE_ALL_FILES";
export const FREELANCE_UPLOAD_ACTA = "FREELANCE_UPLOAD_ACTA";
export const FREELANCE_GET_ACTA = "FREELANCE_GET_ACTA";
export const FREELANCE_GET_REPORT_STATUS = "FREELANCE_GET_REPORT_STATUS";
export const FREELANCE_GET_SUBCONTRACT = "FREELANCE_GET_SUBCONTRACT";
export const FREELANCE_UP_ALTA_REQUEST = "FREELANCE_UP_ALTA_REQUEST";
export const FREELANCE_SEND_ALTA_REQUEST = "FREELANCE_SEND_ALTA_REQUEST";
export const FREELANCE_DELETE_ALTA_FILE = "FREELANCE_DELETE_ALTA_FILE";

//IT REQUESTS
export const REQUEST_DATA = "REQUEST_DATA";
export const GET_OPTIONS = "GET_OPTIONS";
export const GET_STEP_CONFIG = "GET_STEP_CONFIG";
export const GET_EMPLOYEE_REQUEST = "GET_EMPLOYEE_REQUEST";
export const GET_OPEN_REQUESTS = "GET_OPEN_REQUESTS";
export const GET_SEARCH_REQUESTS = "GET_SEARCH_REQUESTS";
export const GET_ID_REQUEST = "GET_ID_REQUEST";
export const GET_COUNTRY_UPDATE_LIST = "GET_COUNTRY_UPDATE_LIST";
export const GET_ROLES = "GET_ROLES";
export const GET_EXCEPTIONS = "GET_EXCEPTIONS";
export const CHECK_COLAB_ID = "CHECK_COLAB_ID";
export const GET_SEARCH_REQUEST_FILE = "GET_SEARCH_REQUEST_FILE";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const GET_DISTINCT_USERS = "GET_DISTINCT_USERS";
export const ADD_MATRIX_ROLE = "ADD_MATRIX_ROLE";
export const ADD_MATRIX_EXCEPTION = "ADD_MATRIX_EXCEPTION";
export const DELETE_MATRIX_ROLE = "DELETE_MATRIX_ROLE";
export const DELETE_MATRIX_EXCEPTION = "DELETE_MATRIX_EXCEPTION"; 

//USER CLOUD REQUEST


export const USER_CLOUD_GET_USER_MATRIX = "USER_CLOUD_GET_USER_MATRIX";
export const USER_CLOUD_CREATE_REQUEST = "SER_CLOUD_CREATE_REQUEST";
export const USER_CLOUD_REMOVE_FILE = "USER_CLOUD_REMOVE_FILE";
export const USER_CLOUD_REMOVE_APPROVE_FILE = "USER_CLOUD_REMOVE_APPROVE_FILE";
export const CLOUD_APPROVE_REQUEST = "CLOUD_APPROVE_REQUEST";
export const CLOUD_GET_ALL_REQUEST = "CLOUD_GET_ALL_REQUEST";
export const CLOUD_DECLINE_REQUEST = "CLOUD_DECLINE_REQUEST";
export const CLOUD_RETURN_REQUEST = "CLOUD_RETURN_REQUEST";
export const CLOUD_GET_ALL_TENANT = "CLOUD_GET_ALL_TENANT";
export const CLOUD_CREATE_ACCESS_MATRIZ = "CLOUD_CREATE_ACCESS_MATRIZ";
export const CLOUD_DELETE_ACCESS_MATRIZ = "CLOUD_DELETE_ACCESS_MATRIZ";
export const CLOUD_UPDATE_ACCESS_MATRIZ = "CLOUD_UPDATE_ACCESS_MATRIZ";
export const CLOUD_GET_MATRIZ_ACCESS_HISTORY = "CLOUD_GET_MATRIZ_ACCESS_HISTORY";
export const CLOUD_DOWNLOAD_VERSION_MATRIZ = "CLOUD_DOWNLOAD_VERSION_MATRIZ";
export const CLOUD_GET_COUNTRIES = "CLOUD_GET_COUNTRIES";
export const CLOUD_GET_ALL_CLOUD_VENDOR = "CLOUD_GET_ALL_CLOUD_VENDOR";
export const CLOUD_CREATE_CLOUD_VENDOR = "CLOUD_CREATE_CLOUD_VENDOR";
export const CLOUD_UPDATE_CLOUD_VENDOR = "CLOUD_UPDATE_CLOUD_VENDOR";
export const CLOUD_DELETE_CLOUD_VENDOR = "CLOUD_DELETE_CLOUD_VENDOR";
export const CLOUD_CREATE_TENANT = "CLOUD_CREATE_TENANT";
export const CLOUD_UPDATE_TENANT = "CLOUD_UPDATE_TENANT";
export const CLOUD_DELETE_TENANT = "CLOUD_DELETE_TENANT";
export const GET_MY_REQUEST = "GET_MY_REQUEST";
export const CLOUD_GET_TENANTS_BY_CLOUD = "CLOUD_GET_TENANTS_BY_CLOUD";
export const CLOUD_DOWNLOAD_FILE_REQUEST = "CLOUD_DOWNLOAD_FILE_REQUEST"
export const CLOUD_UPDATE_REQUEST = "CLOUD_UPDATE_REQUEST";
