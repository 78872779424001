import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findMyTargetsLetters = createAction(constants.GET_MY_TARGETS_LETTERS, method.get(urls.TARGETLETTER.getMyTargetsLetters));

export const findAllTargetsLetters = createAction(constants.GET_ALL_TARGETS_LETTERS, (body) => method.post(urls.TARGETLETTER.getAllTargetLetter, body)());

export const findTargetLetterByUser = createAction(constants.GET_TARGET_LETTER_BY_USER, method.get(urls.TARGETLETTER.getTargetLetter));

export const findTargetLetterHC = createAction(constants.GET_TARGETS_LETTER_HC, method.get(urls.TARGETLETTER.getTargetsLetterHC));

export const findTargetsLetterHeadShip = createAction(constants.GET_TARGETS_LETTER_HEADSHIP, method.get(urls.TARGETLETTER.getTargetsLetterHeadShip));

export const findTargetsLetterHCRegional = createAction(constants.GET_TARGETS_LETTER_HCRM, method.get(urls.TARGETLETTER.getTargetsLetterHCRegional));

export const findAllDataDashboard = createAction(constants.GET_ALL_DATA_DASHBOARD_TARGET_LETTERS, body => method.post(urls.TARGETLETTER.getAllDataDashboard, body)());

export const updateTargetLetterFlow = createAction(constants.UPDATE_TARGET_LETTER_FLOW, (id, body) => method.put(`${urls.TARGETLETTER.updateTargetLetterFlow}/${id}`, body)());

export const deactivateTargetLetter = createAction(constants.DEACTIVATE_TARGET_LETTER, (id, body) => method.put(`${urls.TARGETLETTER.deactivateTargetLetter}/${id}`, body)());

export const applyMeasureTargeLetter = createAction(constants.APPLY_MEASURE_TARGET_LETTER, (body) => method.put(urls.TARGETLETTER.applyMeasureTargeLetter, body)());

export const rejectTargeLetterById = createAction(constants.REJECT_TARGET_LETTER_BY_ID, (id, body) => method.put(`${urls.TARGETLETTER.rejectTargeLetterById}/${id}`, body)());

export const findTargetLetterByGeneralManager = createAction(constants.GET_TARGETS_LETTER_GENERAL_MANAGER, method.get(urls.TARGETLETTER.getTargetsLetterGeneralManager));

export const findTargetLetterByManagementServicesDirector = createAction(constants.GET_TARGETS_LETTER_MANAGEMENT_SERVICES_DIRECTOR, method.get(urls.TARGETLETTER.getTargetLetterByManagementServicesDirector));