// core actions
import { clearData, findVacantPositionById, updateVacantPositionById } from "actions/newPosition.jsx";
// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
import VacantForm from 'components/NewPosition/VacantForm.jsx';
// core components
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import NotFoundDB from "components/Shared/NotFoundDB/NotFoundDB";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core selectors
import { getUsernameLogged, getUserProtected } from 'selectors/adminLayout.jsx';
import { getEditVacantData, getEditVacantInitialValues, getEditVacantRequest } from 'selectors/newPosition.jsx';

class EditVacantPosition extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: Object.keys(props.initialValues).length ? false : true,
      alert: null
    };
  }


  componentWillMount() {
    const { id } = this.props.request;
    this.props.findVacantPositionById(id).then(async res => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.clearData();
  }


  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  handleOnUpdateVacantPosition = values => {
    this.setState({
      loading: true
    });
    this.props.updateVacantPositionById(values.id, { values }).then(async res => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.clearData();
        this.props.history.goBack();
      }
      this.setState({
        loading: false
      });
    });
  }

  handleOnBack = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de regresar?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => {
            this.props.clearData();
            this.props.history.goBack();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, regresar"
          cancelBtnText="No, continuar"
          btnSize="md"
        />
      )
    });
  }

  render() {

    const { alert, loading } = this.state;

    const { initialValues, data } = this.props;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Edición de solicitud vacante" parentName="Nueva Posición" />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading}>
            {
              Object.keys(initialValues).length && Object.keys(data).length ?
                <VacantForm
                  title="Posición vacante."
                  subtitle="Modificación de una posición de la vacante de un recurso."
                  onBack={(this.handleOnBack)}
                  onSearch={this.handleOnBack}
                  onCancel={this.handleOnBack}
                  onUpdate={this.handleOnUpdateVacantPosition}
                  onRequestView={this.handleOnBack}
                  initialValues={initialValues}
                  data={data}
                />
                :
                <NotFoundDB
                  title="Cargando"
                  body="Se esta cargando la información."
                />
            }
          </Spin>
        </Container>
      </>
    );
  }
}

EditVacantPosition.defaultProps = {
  request: {},
  initialValues: {},
  data: {},
}

EditVacantPosition.propTypes = {
  request: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => ({
  user: getUserProtected(),
  username: getUsernameLogged(),
  request: getEditVacantRequest(state, props),
  initialValues: getEditVacantInitialValues(state),
  data: getEditVacantData(state),
});

export default withRouter(connect(
  mapStateToProps,
  {
    clearData,
    findVacantPositionById,
    updateVacantPositionById
  })(EditVacantPosition));