// core actions
import { findAllRequestByUsers, findTotalPositionsCreated } from "actions/newPosition.jsx";
// antd components
import { Spin } from 'antd';
// core components
import CardTransaction from "components/NewPosition/CardTransaction.jsx";
import ChartBar from "components/NewPosition/ChartBar.jsx";
import ChartPie from "components/NewPosition/ChartPie.jsx";
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import Table from "components/Shared/Table/Table.jsx";
// moment library
import moment from 'moment';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getAllRequestsByCounrys, getAllRequestsByUsers, getResponseRate, getTotalsPositionsCreated, getTotalYearRequests } from "selectors/newPosition.jsx";
import { getTeamsUserAccess } from "selectors/adminLayout";

class History extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      sizePerPage: 5,
    };
  }


  componentWillMount() {
    this.props.findTotalPositionsCreated({ date: moment().format("YYYY") }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.props.findAllRequestByUsers().then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        }
      });
      this.setState({
        loading: false
      });
    });
  }

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  render() {

    const {
      cols,
      dataByUsers,
      dataTransactions,
      dataRequests,
      dataResponseRate,
      dataByCountrys,
      teams
    } = this.props;

    const { page, sizePerPage } = this.state;

    const defaultSorted = [
      {
        dataField: "requests",
        order: "desc"
      }
    ];

    console.log(dataTransactions)

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Historial de solicitudes" parentName="Nueva Posición" />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={this.state.loading}>
            <CardTransaction data={dataTransactions} />
            <Row>
              <Col xs="12" md="7">
                <ChartBar
                  title="Solicitudes"
                  subtitle="Historial anual de solicitudes"
                  dataRequests={dataRequests}
                />
              </Col>
              <Col sm="12" md="5">
                <ChartPie
                  title="Response Rate"
                  subtitle="Tasa de % del total de solicitudes"
                  dataResponseRate={dataResponseRate}
                />
              </Col>
            </Row>
            {
              teams.find((element) => element === "New Position Admin") ?
                <Row>
                  <Col xs="12" md="6">
                    <Table
                      title="Solicitudes"
                      subtitle="Total de solicitudes realizadas por usuario."
                      columns={cols}
                      data={dataByUsers}
                      sizePerPage={sizePerPage}
                      page={page}
                      totalSize={dataByUsers.length}
                      defaultSorted={defaultSorted}
                      hiddenTotal
                    />
                  </Col>
                  <Col xs="12" md="6">
                    <ChartBar
                      title="Solicitudes"
                      subtitle="Total de Solicitudes por país"
                      dataRequests={dataByCountrys}
                    />
                  </Col>
                </Row>
                : null
            }
          </Spin>
        </Container>
      </>
    );
  }
}

History.defaultProps = {
  dataTransactions: {},
  dataRequests: {},
  dataResponseRate: {},
  cols: [
    {
      dataField: "username",
      text: "Usuario",
      sort: true
    },
    {
      dataField: "requests",
      text: "Cantidad de Solicitudes",
      sort: true
    },
  ],
  dataByUsers: [],
  dataByCountrys: {},
}

History.propTypes = {
  dataTransactions: PropTypes.object.isRequired,
  dataRequests: PropTypes.object.isRequired,
  dataResponseRate: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  dataByUsers: PropTypes.array.isRequired,
  dataByCountrys: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  teams: getTeamsUserAccess(),
  dataTransactions: getTotalsPositionsCreated(state),
  dataRequests: getTotalYearRequests(state),
  dataResponseRate: getResponseRate(state),
  dataByUsers: getAllRequestsByUsers(state),
  dataByCountrys: getAllRequestsByCounrys(state),
});

export default withRouter(connect(
  mapStateToProps,
  {
    findAllRequestByUsers,
    findTotalPositionsCreated,
  })(History));
