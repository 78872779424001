// javascript library dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  DropdownItem,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
// nodejs library for utf8
import utf8 from "utf8";

const CardSignatures = (props) => {
  const {
    title,
    documents,
    loading,
    isPlay,
    // viwed,
    selectedDocument,
    textDocument,
    values,
    projects,
    homeOfficeValues: {
      totalDays,
      frequency,
      model,
      exactDirection,
      days,
      frequencies,
      models,
      daysValues,
      modelDetail,
    },
    openFile,
    onChangeForm,
    onChangeHO,
    reactDatetimeChange,
    addProject,
    openQdoc,
    deleteProject,
    onCancel,
    onSignature,
    onEndAudio,
    // ref
  } = props;

  const ref = React.useRef();

  const validate = (values) => {
    const errors = {};
    if (!values.termsAndConditions || values.termsAndConditions === false)
      errors.termsAndConditions = "Debes aceptar los términos y condiciones.";
    return errors;
  };

  const verifyUtf8 = (text) => {
    try {
      return utf8.decode(text);
    } catch (error) {
      return text;
    }
  };

  const renderCardsNotifications = () => (
    <div className="card-deck flex-column flex-xl-row">
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">{title}</h5>
        </CardHeader>
        <CardBody>
          <ListGroup className="list my--3" flush>
            {documents.map((row, key) => {
              return (
                <ListGroupItem key={key} className="px-0">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        alt="Notificación"
                        className="avatar rounded-circle"
                        src={require(`assets/img/theme/signature.png`)}
                      />
                    </Col>
                    <div className="col ml--2">
                      <h4 className="mb-0">{row.Descripcion}</h4>
                      <small>{moment(row.Year).format("L")}</small>
                    </div>
                    <Col className="col-auto">
                      <Button
                        color="success"
                        // size="sm"
                        type="button"
                        onClick={() => openFile(row)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-signature" />
                        </span>
                        <span className="btn-inner--text">Firmar</span>
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  );

  const renderList = (row, key) => {
    if (row.text) {
      if (row.doc) {
        return (
          <Row key={key}>
            <Col xs="9">
              <li>
                <CardText className="mt-4">{verifyUtf8(row.text)}</CardText>
              </li>
            </Col>
            <Col
              xs="3"
              style={{
                marginTop: "1.5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                color="success"
                size="sm"
                type="button"
                onClick={() => openQdoc(row.url)}
                // onClick={() => window.open(require(`assets/docs/${row.doc}`))}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-file-pdf" />
                </span>
                <span className="btn-inner--text">Ver</span>
              </Button>
            </Col>
          </Row>
        );
      }
      return (
        <li key={key}>
          <CardText className="mt-4">{verifyUtf8(row.text)}</CardText>
        </li>
      );
    } else {
      const options = row.options.map((row, key) => {
        return renderList(row, key);
      });
      return <ul key={key}>{options}</ul>;
    }
  };

  const renderContent = (row) => {
    const content = [];
    for (const key in row) {
      if (key === "text") {
        content.push(
          <CardText key={key} className="mt-4 text-justify">
            {verifyUtf8(row[key])}
          </CardText>
        );
      } else if (key === "options") {
        const list = row[key].map((row, key) => {
          return renderList(row, key);
        });
        content.push(<ul key={key}>{list}</ul>);
      }
    }
    return content;
  };

  const renderFormProjects = () => (
    <div className="card-deck flex-column flex-xl-row">
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">Proyectos</h5>
        </CardHeader>
        <CardBody>
          <FormRS>
            <div className="form-row align-items-center">
              <Col className="mb-3" xs="12">
                <FormGroup>
                  <Label for="name">Nombre</Label>
                  <div>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Ingrese el nombre del desarrollo"
                      value={values.name}
                      onChange={(e) => onChangeForm(e)}
                      invalid={values.nameState === "invalid"}
                    />
                    {values.nameState === "invalid" && (
                      <span
                        style={{
                          margin: "1.25rem",
                          fontSize: "80%",
                          color: "#fb6340",
                        }}
                      >
                        Debe ingresar el nombre del proyecto
                      </span>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </div>
            <div className="form-row align-items-center">
              <Col className="mb-3" xs="12">
                <FormGroup>
                  <Label for="date">Fecha</Label>
                  <ReactDatetime
                    id="date"
                    inputProps={{
                      id: "date",
                      placeholder: "Elija una fecha",
                      className:
                        values.dateState === "invalid"
                          ? "is-invalid form-control"
                          : "form-control",
                    }}
                    value={values.date}
                    onChange={(e) => reactDatetimeChange("date", e)}
                    timeFormat={false}
                  />
                  {values.dateState === "invalid" && (
                    <span
                      style={{
                        margin: "1.25rem",
                        fontSize: "80%",
                        color: "#fb6340",
                      }}
                    >
                      Debe seleccionar una fecha
                    </span>
                  )}
                </FormGroup>
              </Col>
            </div>
            <div className="form-row align-items-center">
              <Col className="mb-3" xs="12">
                <FormGroup>
                  <Label for="addWitness">Agregar un testigo</Label>
                  <label
                    className="custom-toggle mr-1"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <input
                      id="addWitness"
                      type="checkbox"
                      checked={values.addWitness}
                      onChange={(e) => onChangeForm(e)}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Si"
                    />
                  </label>
                </FormGroup>
              </Col>
            </div>
            {values.addWitness ? (
              <div className="form-row align-items-center">
                <Col className="mb-3" xs="12">
                  <FormGroup>
                    <Label for="witness">Nombre del testigo</Label>
                    <Input
                      id="witness"
                      type="text"
                      placeholder="Ingrese el nombre del testigo"
                      value={values.witness}
                      onChange={(e) => onChangeForm(e)}
                      invalid={values.witnessState === "invalid"}
                    />
                    {values.witnessState === "invalid" && (
                      <span
                        style={{
                          margin: "1.25rem",
                          fontSize: "80%",
                          color: "#fb6340",
                        }}
                      >
                        Debe ingresar el nombre del testigo
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </div>
            ) : null}
            <div className="form-row align-items-center">
              <Col className="mb-3" sm="12" md="3">
                <Button
                  disabled={loading}
                  className="btn-icon"
                  size="sm"
                  color="success"
                  type="button"
                  onClick={() => addProject()}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-folder-plus" />
                  </span>
                  <span className="btn-inner--text">Añadir</span>
                </Button>
              </Col>
            </div>

            <DropdownItem divider />
            <ListGroup className="list my--3" flush>
              {projects.map((row, key) => {
                return (
                  <ListGroupItem key={key} className="px-0">
                    <Row className="align-items-center">
                      <div className="col ml--2">
                        <h4 className="mb-0">{row.name}</h4>
                        <span className="text-success mr-1">●</span>
                        <small>{moment(row.date).format("L")}</small>
                      </div>
                      <Col className="col-auto">
                        <Button
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={() => deleteProject(row)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-trash" />
                          </span>
                          <span className="btn-inner--text">Eliminar</span>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </FormRS>
        </CardBody>
      </Card>
    </div>
  );

  const renderFormHO = () => (
    <div className="card-deck flex-column flex-xl-row">
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">Información Requerida</h5>
        </CardHeader>
        <CardBody>
          <div className="form-row align-items-center">
            <Col className="mb-1">
              <FormGroup>
                <Label className="form-control-label" for="frequency">
                  Frecuencia con la que trabajará desde la casa
                </Label>
                <Input
                  required
                  className="m-0"
                  id="frequency"
                  type="select"
                  value={frequency}
                  onChange={(e) => onChangeHO(e)}
                  invalid={frequency === 0}
                  valid={frequency > 0}
                >
                  <option key="0" value="0">
                    Seleccione una opción
                  </option>
                  {frequencies.map((row, key) => (
                    <option key={key} value={row.id}>
                      {row.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </div>
          {frequency === 1 && (
            <div className="form-row align-items-center">
              <Col className="mb-1">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="totalDays">
                    Cantidad de días de Home Office
                  </Label>
                  <Input
                    required
                    className="m-0"
                    id="totalDays"
                    type="number"
                    max={5}
                    min={1}
                    placeholder="Ingrese la cantidad de días de Home Office"
                    value={totalDays}
                    onChange={(e) => onChangeHO(e)}
                    invalid={totalDays < 1 || totalDays > 5}
                    valid={totalDays >= 1 || totalDays <= 5}
                  />
                </FormGroup>
              </Col>
            </div>
          )}
          <div className="form-row align-items-center">
            <Col className="mb-1">
              <FormGroup>
                <Label className="form-control-label" for="model">
                  Modelo de Home Office
                  <p style={{ color: "red" }}>
                    *El primer número indica la cantidad de días de Home Office
                  </p>
                </Label>
                <Input
                  required
                  className="m-0"
                  id="model"
                  type="select"
                  value={frequency > 1 ? 6 : model}
                  disabled={frequency > 1}
                  onChange={(e) => onChangeHO(e)}
                  invalid={model === 0}
                  valid={model > 0}
                >
                  <option key="0" value="0">
                    Seleccione una opción
                  </option>
                  {models.map((row, key) => (
                    <option key={key} value={row.id}>
                      {row.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </div>
          {model === 6 && (
            <div className="form-row align-items-center">
              <Col className="mb-1">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="modelDetail">
                    Detalle
                  </Label>
                  <Input
                    required
                    className="m-0"
                    id="modelDetail"
                    type="textarea"
                    resize="none"
                    rows="2"
                    maxLength="500"
                    placeholder="Ingrese el detalle para el modelo seleccionado"
                    value={modelDetail}
                    onChange={(e) => onChangeHO(e)}
                    invalid={modelDetail.length ? false : true}
                    valid={modelDetail.length ? true : false}
                  />
                </FormGroup>
              </Col>
            </div>
          )}
          {frequency === 1 && (
            <div className="form-row align-items-center">
              <Col className="mb-1">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="amountDays">
                    Días en la modalidad de Home Office
                  </Label>
                  {days.map((row, key) => (
                    <div
                      key={key}
                      className="custom-control custom-checkbox mb-3"
                      style={{ marginLeft: "12px" }}
                    >
                      <input
                        className="custom-control-input"
                        id={row.key}
                        type="checkbox"
                        defaultChecked={daysValues[row.key]}
                        value={daysValues[row.key]}
                        onChange={(e) => onChangeHO(e)}
                      />
                      <label className="custom-control-label" htmlFor={row.key}>
                        {row.name}
                      </label>
                    </div>
                  ))}
                </FormGroup>
              </Col>
            </div>
          )}
          <div className="form-row align-items-center">
            <Col className="mb-1">
              <FormGroup>
                <Label className="form-control-label" htmlFor="exactDirection">
                  Domicilio donde realiza el Home Office
                  <p style={{ color: "red" }}>
                    *(Detalle claramente sobre la distribución
                    político-geográfica: Provincia, cantón, distrito,
                    delegación, departamento, estado, municipio, corregimiento,
                    zona, barrio y señas extras según corresponda
                  </p>
                </Label>
                <Input
                  required
                  className="m-0"
                  id="exactDirection"
                  type="textarea"
                  resize="none"
                  rows="3"
                  maxLength="500"
                  placeholder="Ingrese la dirección exacta del domicilio"
                  value={exactDirection}
                  onChange={(e) => onChangeHO(e)}
                  invalid={exactDirection.length ? false : true}
                  valid={exactDirection.length ? true : false}
                />
              </FormGroup>
            </Col>
          </div>
          <div className="form-row align-items-center">
            <Col className="mb-1">
              <Button
                color="info"
                size="sm"
                type="button"
                block
                onClick={() =>
                  window.open(
                    "https://qdoc.gbm.net/ControlDocumental/Documento.aspx?Id=3745"
                  )
                }
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-file-pdf" />
                </span>
                <span className="btn-inner--text">
                  Abrir Adendum al Contrato de Home Office
                </span>
              </Button>
            </Col>
          </div>
        </CardBody>
      </Card>
    </div>
  );

  const renderTextCard = () => (
    <Row>
      <Col className="order-xl-2" xl="5">
        {selectedDocument.Requiere_Proyectos === 1
          ? renderFormProjects()
          : selectedDocument.Requiere_Info_HO === 1
          ? renderFormHO()
          : null}
        <div className="card-deck flex-column flex-xl-row">
          <Card>
            <CardHeader>
              <h5 className="h3 mb-0">Reproducir la política</h5>
            </CardHeader>
            <CardBody className="text-center">
              <Row className="justify-content-center">
                <Col>
                  <audio ref={ref} src={isPlay} controls onEnded={onEndAudio} />
                </Col>
              </Row>
              <Form
                onSubmit={() => onSignature(selectedDocument)}
                validate={validate}
                render={({ handleSubmit, submitting, validating }) => (
                  <>
                    <div className="form-row align-items-center">
                      <Col xs="12">
                        <FormGroup check>
                          <Field name="termsAndConditions" type="checkbox">
                            {({ input, meta }) => (
                              <>
                                <Label>
                                  <Input
                                    {...input}
                                    type="checkbox"
                                    invalid={meta.error && meta.touched}
                                  />{" "}
                                  Acepto los términos y condiciones.
                                </Label>
                                <div style={{ marginBottom: "1rem" }}>
                                  {meta.error && meta.touched && (
                                    <span
                                      style={{
                                        fontSize: "80%",
                                        color: "#fb6340",
                                      }}
                                    >
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center justify-content-center">
                      <Col className="col-auto">
                        <Button
                          disabled={submitting || loading}
                          className="btn-icon"
                          block
                          color="danger"
                          type="button"
                          onClick={onCancel}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-arrow-left" />
                          </span>
                          <span className="btn-inner--text">Volver</span>
                        </Button>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          disabled={validating || loading}
                          className="btn-icon"
                          block
                          color="success"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-signature" />
                          </span>
                          <span className="btn-inner--text">Firmar</span>
                        </Button>
                      </Col>
                    </div>
                  </>
                )}
              />
            </CardBody>
          </Card>
        </div>
      </Col>
      <Col className="order-xl-1" xl="7">
        <div className="card-deck flex-column flex-xl-row">
          <Card>
            <CardHeader>
              <h5 className="h3 mb-0">{verifyUtf8(textDocument.title)}</h5>
            </CardHeader>
            <CardBody>
              {typeof textDocument.body === "object" &&
              textDocument.body !== null
                ? textDocument.body.map((row, key) => {
                    return <div key={key}>{renderContent(row)}</div>;
                  })
                : JSON.parse(textDocument.body).map((row, key) => {
                    return <div key={key}>{renderContent(row)}</div>;
                  })}
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );

  return (
    <>
      {selectedDocument === null
        ? renderCardsNotifications()
        : renderTextCard()}
    </>
  );
};

CardSignatures.propTypes = {
  title: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  // isPlay: PropTypes.string.isRequired,
  viwed: PropTypes.array.isRequired,
  openFile: PropTypes.func.isRequired,
  onSignature: PropTypes.func.isRequired,
};

export default CardSignatures;
