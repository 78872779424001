// core actions
import {
  updateActivityFlow,
  updateStateRequest,
} from "actions/Sales/digitalRequest.jsx";
import {
  deactivateReferences,
  downloadPartsByModelInQuote,
  findCommentsRequestInQuote,
  findFilesRequestInQuote,
  findInfoRequestInQuote,
  findRequestsInQuotes,
  updateSustituteCostParts,
} from "actions/Sales/inventories.jsx";
// core antd
import { message, Spin } from "antd";
// core components Inventories
import Filters from "components/Sales/Inventories/Filters.jsx";
import Request from "components/Sales/Inventories/Request.jsx";
import Requests from "components/Sales/Inventories/Requests.jsx";
import UploadDrawer from "components/Sales/Inventories/UploadDrawer.jsx";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getFiltersOptions,
  getInfoRequestInQuote,
  getRequestInQuotes,
  getCommentsRequestInQuotes,
  getFilesRequestInQuotes,
} from "selectors/Sales/inventories.jsx";
// javascript library to parser xlsx to json
const XLSX = require("xlsx");

class Quotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      requestSelected: null,
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
      },
      commentary: "",
      openUpload: false,
      fileList: [],
      initialValues: {},
      saved: true,
      fileReferenceList: [],
      fileReferenceListRemoved: [],
    };
  }

  componentDidMount() {
    this.findRequestsInQuotes();
  }

  mainInitialValuesStates = () => {
    this.setState({
      willMount: true,
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      requestSelected: null,
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
      },
      commentary: "",
      openUpload: false,
      fileList: [],
      initialValues: {},
      saved: true,
      fileReferenceList: [],
      fileReferenceListRemoved: [],
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  findRequestsInQuotes = () => {
    this.props.findRequestsInQuotes().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState((state) => ({
        willMount: !state.willMount,
      }));
    });
  };

  renderRowsFiltered = (rows) => {
    const {
      filtersValues: { customer, status, opp },
    } = this.state;
    if (customer.value !== 0) {
      rows = rows.filter((row) => row.customer === customer.label);
    }
    if (status.value !== 0) {
      rows = rows.filter((row) => row.status === status.label);
    }
    if (opp.value !== 0) {
      rows = rows.filter((row) => row.opportunityNumber === opp.label);
    }
    return rows;
  };

  verifyUploadParts = (parts) => {
    let flag = false;
    for (const element of parts) {
      const { Sustituto1, Sustituto2, Proveedor, Costo } = element;
      if (
        (Sustituto1 && Sustituto1.length > 17) ||
        (Sustituto2 && Sustituto2.length > 17) ||
        (Proveedor && Proveedor.length > 17) ||
        (Costo && parseInt(Costo) > 99999999.99)
      ) {
        flag = true;
      }
    }
    return flag;
  };

  verifyAllSubstitutesAndCosts = (ids, values) => {
     let flag = false;
    for (const id of ids) {
      // if (!values[`substitute1-${id}`] || !values[`substitute2-${id}`] || !values[`substitute3-${id}`] || !values[`cost-${id}`]) {
      if (
        !values[`cost-${id}`] ||
        values[`cost-${id}`] == "" ||
        !values[`substitute3-${id}`] ||
        values[`substitute3-${id}`] == ""
      ) {
        flag = true;
      }
    }
    return false;
  };

  handleOnMainQuotes = () => {
    this.setState({
      requestSelected: null,
    });
  };

  handleOnClearFilters = () => {
    this.setState({
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
      },
    });
  };

  handleOnChangeFilters = (key, event) => {
    const { filtersValues } = this.state;
    filtersValues[key] = event;
    this.setState({
      filtersValues,
      page: 1,
    });
  };

  handleOnOpenRequestSelected = async (row) => {
    const { id, idList } = row;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Cargando la información del requerimiento seleccionado",
      initialValues: {},
    }));
    await this.props.findInfoRequestInQuote(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: {
            payload: { request },
          },
        } = res.payload;
        const initialValues = {};
        request.map((row) => {
          initialValues[`substitute1-${row.id}`] = row.substitute1;
          initialValues[`substitute2-${row.id}`] = row.substitute2;
          initialValues[`substitute3-${row.id}`] = row.substitute3;
          initialValues[`cost-${row.id}`] = row.cost;
          return row;
        });
        this.props.findCommentsRequestInQuote(idList);
        this.props.findFilesRequestInQuote(idList);
        this.setState({
          requestSelected: row,
          initialValues,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  handleOnDownloadEquipment = (idEquipment, name) => {
    const { request } = this.props;
    const { initialValues } = this.state;
    // console.log(request);
    let parts = request.filter((row) => row.idEquipment === idEquipment);
    // console.log(parts);
    parts = parts.map((row) => {
      const json = {};
      // if (row.typeModel === typeModel) {
      json["ID"] = row.id;
      json["Modelo"] = row.typeModel;
      json["Plataforma"] = row.platform;
      json["Categoria"] = row.category;
      json["Descripción"] = row.description;
      json["FRU"] = row.fru;
      json["Cantidad"] = row.amount;
      json["Sustituto1"] = initialValues[`substitute1-${row.id}`]
        ? initialValues[`substitute1-${row.id}`]
        : row.substitute1;
      json["Sustituto2"] = initialValues[`substitute2-${row.id}`]
        ? initialValues[`substitute2-${row.id}`]
        : row.substitute2;
      json["Proveedor"] = initialValues[`substitute3-${row.id}`]
        ? initialValues[`substitute3-${row.id}`]
        : row.substitute3;
      // json['Sustituto2'] = row.substitute2;
      // json['Proveedor'] = row.substitute3;
      json["Costo"] = initialValues[`cost-${row.id}`]
        ? initialValues[`cost-${row.id}`]
        : row.cost;
      // json['Costo'] = row.cost;
      // }
      return json;
    });
    this.props
      .downloadPartsByModelInQuote({
        parts, //: parts.filter((row) => row.Modelo === typeModel),
        name,
      })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            this.notify(
              "warning",
              "Atención",
              `No se logro descargar la información del modelo seleccionado`
            );
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([res.payload.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  handleOnUploadFileEquipment = () => {
    this.setState((state) => ({
      openUpload: !state.openUpload,
    }));
  };

  handleOnFileListEquipment = async (info) => {
    const { status, name } = info.file;
    let flag = false;
    if (name.includes("Cotización") && name.includes("_equipo")) {
      if (status === "removed") {
        this.setState({
          fileList: [],
        });
        message.success(
          `El archivo ${info.file.name} fue quitado exitosamente.`
        );
      } else {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
          if (file.response) {
            file.url = file.response.url;
            let { status } = file;
            if (status === "done") {
              message.success(
                `El archivo ${file.name} fue cargado exitosamente.`
              );
              const reader = new FileReader();
              reader.onload = async (event) => {
                const data = event.target.result;
                const workbook = XLSX.read(data, {
                  type: "binary",
                });
                workbook.SheetNames.forEach((sheetName) => {
                  let XL_row_object = XLSX.utils.sheet_to_row_object_array(
                    workbook.Sheets[sheetName]
                  );
                  if (!XL_row_object.length) {
                    flag = true;
                    this.notify(
                      "warning",
                      "Atención",
                      `El archivo ${file.name}, se encuentra vacio`
                    );
                  } else if (this.verifyUploadParts(XL_row_object)) {
                    flag = true;
                    this.notify(
                      "warning",
                      "Atención",
                      `El archivo ${file.name}, no cumple los parametros establecidos`
                    );
                  } else {
                    // let json = {};
                    const { initialValues } = this.state;
                    XL_row_object.map((row) => {
                      initialValues[`substitute1-${row.ID}`] = row.Sustituto1
                        ? row.Sustituto1
                        : null;
                      initialValues[`substitute2-${row.ID}`] = row.Sustituto2
                        ? row.Sustituto2
                        : null;
                      initialValues[`substitute3-${row.ID}`] = row.Proveedor
                        ? row.Proveedor
                        : null;
                      initialValues[`cost-${row.ID}`] = row.Costo
                        ? row.Costo
                        : 1;
                      return row;
                    });
                    this.setState({
                      initialValues,
                      saved: false,
                    });
                  }
                });
                if (flag) {
                  this.setState({
                    fileList: [],
                  });
                }
                this.setState((state) => ({
                  openUpload: !state.openUpload,
                }));
              };
              reader.onerror = function (event) {
                this.notify(
                  "warning",
                  "Atención",
                  `No se logro leer el archivo ${file.name}, ${event.target.error.code}`
                );
              };
              reader.readAsBinaryString(file.originFileObj);
            } else if (status === "error") {
              // this.notify("danger", "Falló", `La carga del archivo ${file.name} fallo.`);
              message.success(`La carga del archivo ${file.name} fallo.`);
            } else if (status === "removed") {
              // this.notify("warning", "Atención", `El archivo ${file.name} fue quitado exitosamente.`);
              message.success(
                `El archivo ${file.name} fue quitado exitosamente.`
              );
            }
          }
          return file;
        });
        this.setState({
          fileList,
        });
      }
    } else {
      this.notify(
        "warning",
        "Atención",
        "El nombre del archivo no cumple con los parametros establecidos"
      );
    }
  };

  handleOnChangeSustituteAndCost = (event) => {
    const { id, value } = event.target;
    const { initialValues } = this.state;
    if (id.includes("cost") && value > 99999999.99) {
      this.notify(
        "warning",
        "Atención",
        "La cantidad a ingresar no puede ser mayor a 99 999 999.99"
      );
    } else {
      initialValues[id] = value.toUpperCase();
      this.setState({
        initialValues,
        saved: false,
      });
    }
  };

  handleOnChangeCommentary = (event) => {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
      saved: false,
    });
  };

  handleOnSaveParts = async () => {
    const {
      initialValues,
      saved,
      requestSelected: { idList },
      fileReferenceListRemoved,
      commentary,
    } = this.state;
    // console.log(commentary);
    // const { commentary } = initialValues;
    if (saved) {
      this.notify(
        "warning",
        "Atención",
        "No se a detectado la actualización de valores que se requieran guardar"
      );
    } else {
      let values = Object.keys(initialValues).filter(
        (row) =>
          (row.includes("substitute") || row.includes("cost")) &&
          initialValues[row] !== null
      );
      let ids = [];
      values.forEach((element) => {
        let id = element.split("-")[1];
        if (!ids.some((row) => row === parseInt(id))) {
          ids.push(parseInt(id));
        }
      });
      if (ids.length) {
        const parts = ids.map((row) => {
          const json = {};
          json.id = row;
          json.substitute1 = initialValues[`substitute1-${row}`];
          json.substitute2 = initialValues[`substitute2-${row}`];
          json.substitute3 = initialValues[`substitute3-${row}`];
          json.cost = initialValues[`cost-${row}`];
          return json;
        });
        this.setState((state) => ({
          loading: !state.loading,
          message: "Guardando la información de sustitutos y costos ingresados",
        }));
        if (fileReferenceListRemoved.length) {
          await this.props.deactivateReferences({
            referencesIds: fileReferenceListRemoved,
          });
        }
        await this.props
          .updateSustituteCostParts({ parts, commentary, idList })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
            } else {
              const {
                data: { payload },
              } = res.payload;
              this.notify("success", "Éxito", payload.message);
              this.setState({
                saved: true,
                initialValues,
                commentary: "",
              });
              this.props.findCommentsRequestInQuote(idList);
              this.props.findFilesRequestInQuote(idList);
            }
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          });
      }
    }
  };

  handleOnCompleteQuote = async () => {
    const {
      requestSelected: { id },
      initialValues,
      saved,
    } = this.state;
    const { request } = this.props;
    if (!saved) {
      this.notify(
        "warning",
        "Atención",
        "Primero debe guardar los valores actualizados para poder continuar"
      );
    } else {
      const ids = request.map((row) => row.id);
      if (this.verifyAllSubstitutesAndCosts(ids, initialValues)) {
        this.notify(
          "warning",
          "Atención",
          "Se requiere que todos los costos y proveedores sean completados para poder continuar"
        );
      } else {
        this.setState((state) => ({
          loading: !state.loading,
          message: "Finalizando la cotización de la oportunidad",
        }));
        await this.props.updateStateRequest(id, 8).then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          } else {
            await this.props.updateActivityFlow(id, {
              description: "Cotización de la oportunidad completada",
              state: "Cotización Completada",
              type: "quotes",
            });
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            this.mainInitialValuesStates();
            this.findRequestsInQuotes();
          }
        });
      }
    }
  };

  handleOnFileListReference = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idReference } = payload;
        this.setState((state) => ({
          fileReferenceList: state.fileReferenceList.filter(
            (row) => row.uid !== uid
          ),
          fileReferenceListRemoved: [
            ...state.fileReferenceListRemoved,
            idReference,
          ],
        }));
      } else {
        this.setState((state) => ({
          fileReferenceList: state.fileReferenceList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        fileReferenceList: fileList,
        saved: false,
      });
    }
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  onCreateNewActivityFlow = async (input) => {
    const { requestSelected } = this.state;
    if (input.length > 80) {
      this.notify(
        "warning",
        "Atención",
        "La nota es muy larga, no debe sobrepasar los 80 caracteres"
      );
    } else {
      this.hideAlert();
      this.setState((state) => ({
        loading: !state.loading,
        message: `Creando nota al flujo de actividades`,
      }));
      await this.props
        .updateActivityFlow(requestSelected.id, {
          description: `${input}`,
          state: "Nota Creada",
          type: "quotes",
        })
        .then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
    }
  };

  handleOnNewActivityFlow = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-180px" }}
          title="Ingrese la nota deseada para el flujo de actividades"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.onCreateNewActivityFlow(input)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, enviar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese una nota
        </ReactBSAlert>
      ),
    });
  };

  render() {
    const {
      name,
      parentName,
      request,
      requests,
      colsRequest,
      colsRequests,
      filters,
      filtersOptions,
      comments,
      files,
    } = this.props;

    const {
      page,
      alert,
      willMount,
      loading,
      message,
      openUpload,
      fileList,
      commentary,
      sizePerPage,
      initialValues,
      filtersValues,
      requestSelected,
      fileReferenceList,
    } = this.state;

    return (
      <>
        {alert}
        <UploadDrawer
          title="Cargar cotización"
          visible={openUpload}
          fileList={fileList}
          onToggle={this.handleOnUploadFileEquipment}
          onFileList={this.handleOnFileListEquipment}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message} size="large">
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de oportunidades en cotización" />
                </Col>
              </Row>
            ) : requestSelected ? (
              <Request
                title="Información de Oportunidad"
                subtitle="Gestión de Costos y Sustitutos a oportunidad en cotización"
                opp={requestSelected}
                values={initialValues}
                cols={colsRequest}
                rows={request}
                commentary={commentary}
                comments={comments}
                files={files}
                fileList={fileReferenceList}
                onBack={this.handleOnMainQuotes}
                onSave={this.handleOnSaveParts}
                onComplete={this.handleOnCompleteQuote}
                onDownload={this.handleOnDownloadEquipment}
                onUpload={this.handleOnUploadFileEquipment}
                onChange={this.handleOnChangeSustituteAndCost}
                onChangeComment={this.handleOnChangeCommentary}
                onFileList={this.handleOnFileListReference}
                onNewActivityFlow={this.handleOnNewActivityFlow}
              />
            ) : requests.length ? (
              <>
                <Filters
                  title="Filtros Disponibles"
                  subtitle="Aplicación de Filtros a la lista de oportunidades en cotización o cotizadas"
                  filters={filters}
                  options={filtersOptions}
                  values={filtersValues}
                  onClear={this.handleOnClearFilters}
                  onChange={this.handleOnChangeFilters}
                />
                <Requests
                  title="Oportunidades"
                  subtitle="Lista de las oportunidades en estado de cotización y cotizadas"
                  cols={colsRequests}
                  page={page}
                  sizePerPage={sizePerPage}
                  rows={this.renderRowsFiltered(requests)}
                  onOpen={this.handleOnOpenRequestSelected}
                  onSetPage={this.handleOnSetPage}
                />
              </>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay oportunidades pendientes."
                    subtitle="Actualmente al día de hoy, no tienes requerimientos digitales creados, cuando crees tus requerimientos se enlistarán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

Quotes.defaultProps = {
  name: "Cotización de Oportunidades",
  parentName: "Administración de Inventarios",
  requests: [],
  request: [],
  colsRequest: [
    { key: "amountEquipments", name: "Cantidad Equipos" },
    { key: "typeModel", name: "Modelo" },
    { key: "id", name: "#REF" },
    { key: "platform", name: "Plataforma" },
    { key: "category", name: "Categoría" },
    { key: "description", name: "Descripción" },
    { key: "classify", name: "Clasificación de la Parte" },
    // { key: "definedClassify", name: "Definida por el Ing" },
    { key: "fru", name: "FRU" },
    { key: "amount", name: "Cantidad" },
    { key: "substitute1", name: "Sustituto 1" },
    { key: "substitute2", name: "Sustituto 2" },
    { key: "substitute3", name: "Proveedor" },
    { key: "cost", name: "Costo" },
  ],
  colsRequests: [
    { key: "id", name: "#REF" },
    { key: "version", name: "Versión" },
    { key: "opportunityNumber", name: "Oportunidad" },
    { key: "customer", name: "Cliente" },
    { key: "status", name: "Estado" },
    { key: "createdBy", name: "Creado" },
    { key: "createdAt", name: "Fecha de Creación" },
    { key: "updatedAt", name: "Fecha de Actualización" },
  ],
  filters: [
    { key: "customer", name: "Cliente", placeholder: "Seleccione un cliente" },
    { key: "status", name: "Estado", placeholder: "Seleccione un estado" },
    {
      key: "opp",
      name: "Oportunidad",
      placeholder: "Seleccione una oportunidad",
    },
  ],
  filtersOptions: {
    customer: [],
    status: [],
    opp: [],
  },
  comments: [],
  files: [],
};

Quotes.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  request: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  filtersOptions: PropTypes.object.isRequired,
  comments: PropTypes.array.isRequired,
  files: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  request: getInfoRequestInQuote(state),
  requests: getRequestInQuotes(state),
  filtersOptions: getFiltersOptions(state),
  comments: getCommentsRequestInQuotes(state),
  files: getFilesRequestInQuotes(state),
});

export default withRouter(
  connect(mapStateToProps, {
    deactivateReferences,
    downloadPartsByModelInQuote,
    findRequestsInQuotes,
    findInfoRequestInQuote,
    updateSustituteCostParts,
    updateActivityFlow,
    updateStateRequest,
    findCommentsRequestInQuote,
    findFilesRequestInQuote,
  })(Quotes)
);
