// core actions
import {
  findAllUsersAccessOnHold,
  deactivateAccessUserOnHoldByID,
  validateGbmCollaborator,
  createAccessUserOnHold,
  editAccessUserOnHold,
} from "actions/secoh.jsx";
// antd components
import { Spin } from "antd";
import EditUserModal from "components/SECOH/EditUserModal";
// core components
import AddUserModal from "components/SECOH/AddUserModal.jsx";
import UsersAccessList from "components/SECOH/UsersAccessList.jsx";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getUsersAccessOnHold,
  getAllRolesAvailable,
} from "selectors/secoh.jsx";

class UsersMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      loadingModal: false,
      addUserModal: false,
      editUserModal: false,
      initialValues: {},
      infoUser: {},
      flagEditUserModal: true,
    };
  }

  componentWillMount() {
    this.props.findAllUsersAccessOnHold().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      }
      this.setState({
        willMount: false,
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  onDeleteUser = (row) => {
    this.setState({
      alert: null,
      loading: true,
      message: "Eliminando el usuario de la lista",
    });
    this.props.deactivateAccessUserOnHoldByID(row.userID).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findAllUsersAccessOnHold();
      }
      this.setState({
        loading: false,
        message: "",
        page: 1,
      });
    });
  };

  handleOnDeleteUser = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de eliminar el usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => {
            this.onDeleteUser(row);
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnToggleModalAddUser = () => {
    this.setState((state) => ({
      addUserModal: !state.addUserModal,
      initialValues: {},
      infoUser: {},
    }));
  };

  handleOnToggleModalEditUser = (infoUser) => {
    this.setState((state) => ({
      editUserModal: !state.editUserModal,
      initialValues: {},
      infoUser: { infoUser },
    }));
  };

  handleOnValidateUser = async (values) => {
    const { username } = values;
    this.setState((state) => ({
      loadingModal: !state.loadingModal,
      message: `Validando el usuario ingresado con la información de la compañía`,
      initialValues: { username },
    }));
    await this.props
      .validateGbmCollaborator({
        username: username.split("@")[0].toUpperCase(),
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexión con el servidor."
            );
          }
        } else {
          const {
            data: {
              payload: { response },
            },
          } = res.payload;
          this.setState({
            initialValues: { username: response.USERNAME },
            infoUser: response,
          });
        }
        this.setState((state) => ({
          loadingModal: !state.loadingModal,
          message: null,
        }));
      });
  };

  onCreateNewUserRol = async (values) => {
    this.setState({
      alert: null,
      loadingModal: true,
      message: "Creando el acceso para el usuario",
    });
    this.props.createAccessUserOnHold(values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.handleOnToggleModalAddUser();
        this.props.findAllUsersAccessOnHold();
      }
      this.setState({
        loadingModal: false,
        message: "",
        page: 1,
      });
    });
  };

  onEditUserRol = async (values) => {
    this.setState({
      alert: null,
      loadingModal: true,
      message: "Editando datos del acceso para el usuario",
    });
    this.props.editAccessUserOnHold(values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.handleOnToggleModalEditUser();
        this.props.findAllUsersAccessOnHold();
      }
      this.setState({
        loadingModal: false,
        message: "",
        page: 1,
      });
    });
  };

  handleOnCreateUser = (values) => {
    const { infoUser } = this.state;
    values.email = infoUser.EMAIL;
    values.userID = infoUser.IDCOLABC;
    values.name = infoUser.NOMBRE;
    values.country = values.country;
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de crear el usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => {
            this.onCreateNewUserRol(values);
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, crear"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnEditUser = (values) => {
    console.log(this.state.infoUser);
    console.log("INFO USER");
    console.log(values);
    console.log("DATA OBTENIDA FORMULARIO");
    const { infoUser } = this.state;
    values.email = infoUser.EMAIL;
    values.userID = infoUser.IDCOLABC;
    values.name = infoUser.NOMBRE;
    values.country = values.country;
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que quiere editar la información del usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => {
            this.onEditUserRol(values);
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, crear"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  render() {
    const { name, parentName, cols, users, roles } = this.props;

    const {
      page,
      alert,
      loading,
      message,
      infoUser,
      willMount,
      sizePerPage,
      loadingModal,
      addUserModal,
      editUserModal,
      initialValues,
    } = this.state;

    return (
      <>
        {alert}
        <AddUserModal
          user={infoUser}
          title="Agregar Acceso Usuario"
          roles={roles}
          message={message}
          loading={loadingModal}
          showModal={addUserModal}
          isInvalid={loadingModal}
          initialValues={initialValues}
          toggleModal={this.handleOnToggleModalAddUser}
          onValidate={this.handleOnValidateUser}
          onWorkFlow={this.handleOnCreateUser}
        />

        <EditUserModal
          user={infoUser}
          title="Editar Acceso Usuario"
          roles={roles}
          message={message}
          loading={loadingModal}
          showModal={editUserModal}
          isInvalid={loadingModal}
          initialValues={initialValues}
          toggleModal={this.handleOnToggleModalEditUser}
          onValidate={this.handleOnValidateUser}
          onWorkFlow={this.handleOnEditUser}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading}>
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de los usuarios con acceso" />
                </Col>
              </Row>
            ) : (
              <UsersAccessList
                title="Usuarios On Hold"
                subtitle="Lista de usuarios con acceso"
                cols={cols}
                rows={users}
                page={page}
                sizePerPage={sizePerPage}
                onDelete={this.handleOnDeleteUser}
                addUser={this.handleOnToggleModalAddUser}
                editUser={this.handleOnToggleModalEditUser}
                onSetPage={this.handleOnSetPage}
              />
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

UsersMaintenance.defaultProps = {
  name: "Mantenimiento de Usuarios",
  parentName: "Contratos On Hold",
  cols: [
    { key: "id_User", name: "#REF" },
    { key: "userID", name: "ID Colaborador" },
    { key: "username", name: "Usuario" },
    { key: "name", name: "Nombre" },
    { key: "email", name: "Correo Electrónico" },
    { key: "role", name: "Rol" },
    { key: "country", name: "País" },
    { key: "createdAt", name: "Fecha de Creación" },
  ],
  users: [],
  userInfo: {},
  roles: [],
};

UsersMaintenance.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  users: getUsersAccessOnHold(state),
  roles: getAllRolesAvailable(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllUsersAccessOnHold,
    deactivateAccessUserOnHoldByID,
    validateGbmCollaborator,
    createAccessUserOnHold,
    editAccessUserOnHold,
  })(UsersMaintenance)
);
