/*!

=========================================================
*ItRequests
=========================================================

*Este componente es una ventana que aparace para confirmar cualquier accion generica
=========================================================

* Coded by Sergio Marin  

*/
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({ showModal, setShowModal, msg, okFunct }) => {

    const [anyParams, setAnyParams] = useState("");

    const continueButton = () => {
        setShowModal(false); //Cierra el modal
        okFunct(anyParams); //Llama a la función que se ejecuta en OK
    };
    
    return (
        <>
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalHeader toggle={() => setShowModal(false)}>Confirmación acción</ModalHeader>
                <ModalBody>{msg}</ModalBody><ModalFooter>
                    <Button color="primary" onClick={continueButton}>Continuar</Button>
                    {' '}
                    <Button color="secondary" onClick={() => setShowModal(false)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
export default ConfirmationModal;