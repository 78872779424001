import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Filter from "components/FinanceFlows/Administration/Filters";
import Table from "components/FinanceFlows/FlowsAprobation/Tables";
import { Spin } from 'antd';
import { useDispatch } from "react-redux";
import { getOptions, getRequestMyFlow } from "actions/financialFlows"
import { getUserProtected } from 'selectors/adminLayout.jsx';
import moment from 'moment';
import RequestDetails from "views/FinanceFlows/ApprovalFlows/RequestDetails.jsx";

const MyFlows = () => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [loading, setLoad] = useState(false);
    const [filterByTable, setFiltersByTable] = useState([]);
    const [infoToModule, setInfoToModule] = useState({
        rows: [],
        profileState: [],
        category: [], 
        businessUnit: [],
        profileType: []
    });
    const [requestSelected, setRequestSelected] = useState({
        isSelected: false,
        data: {}
    });

    //Columnas de la tabla principal generica
    const colums = [
        {
            label: "Identificador",
            value: "id",
            type: "b"
        },
        {
            label: "Solicitante",
            value: "requesterName",
            type: "a"
        },
        {
            label: "Fecha",
            value: "createdat",
            type: "date"
        },
        {
            label: "Tipo de documento",
            value: "documentName",
            type: "a"
        },
        {
            label: "Estado",
            value: "requestStateName",
            type: "a"
        },
        {
            label: "Categoria",
            value: "documentCategoryName",
            type: "a"
        }

    ]
    //Json que genera los filtros que se utilizaran en la tabla
    const filter = [
        {
            label: "Identificador",
            type: "input",
            xs: "4",
            value: "id"
        },
        {
            label: "Solicitante",
            type: "input",
            xs: "4",
            value: "requesterName"
        },
        {
            label: "Fecha de Solicitud",
            type: "date",
            xs: "4",
            value: "createdat",
            valueFilter: "createdat"
        },
        {
            label: "Tipo de documento",
            type: "input",
            xs: "4",
            value: "documentName",
            valueFilter: "documentName"
        },
        {
            label: "Estado",
            type: "select",
            xs: "4",
            value: "profileState",
            valueFilter: "requestStateName"
        },
        {
            label: "Categoria",
            type: "select",
            xs: "4",
            value: "category",
            valueFilter: "documentCategoryName"
        },
    ]

    //Botones adicionales en la tabla principal
    const aditionalsButtons = [
        {
            color: "secondary",
            function: (e) => handleOnSetRequest(e),
            icon: "fas fa-edit",
            className: "table-action table-action-edit",
            label: "Ver Detalles"
        },
    ]
    const formRequester = [
        { UnitTypeName: "Usuario", typeUnitType: "input", type: "input", value: "requesterName", required: true },
        { UnitTypeName: "Email", typeUnitType: "input", type: "input", value: "requesterEmail", required: true },
        { UnitTypeName: "Posición", typeUnitType: "input", type: "input", value: "requesterPosition", required: true },
        { UnitTypeName: "Departamento", typeUnitType: "input", type: "input", value: "requesterDepartment", required: true }
    ]

    const formDocument = [
        { UnitTypeName: "Nombre", typeUnitType: "input", type: "input", value: "documentName", required: true },
        { UnitTypeName: "Categoria", typeUnitType: "input", type: "input", value: "documentCategoryName", required: true },
        { UnitTypeName: "Ultima actualización", typeUnitType: "input", type: "input", value: "updateat", required: true },
        { UnitTypeName: "Tipo de Flujo", typeUnitType: "input", type: "input", value: "documentFlow", required: true }
    ]

    const formDocumentDesc = [
        { UnitTypeName: "Fecha Solicitud * ", typeUnitType: "input", type: "date", value: "createdat", required: true },
        { UnitTypeName: "Solicitante * ", typeUnitType: "input", type: "input", value: "requesterName", required: true },
        { UnitTypeName: "País * ", typeUnitType: "input", type: "input", value: "buName", required: true },
        { UnitTypeName: "DESCRIPCION DE SOLICITUD", typeUnitType: "input", type: "textarea", value: "description", required: true }
    ]
    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetInfo();
        };
        getInfo();
    }, []);
    //Funcion que trae la data de los campos selects
    const handleOnGetInfo = async () => {
        const user = getUserProtected().EMAIL.split("@")[0];
        setLoad(true);
        dispatch(getOptions()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    area: payload.data.data.area,
                    businessUnit: payload.data.data.businessUnit,
                    category: payload.data.data.category,
                    profileType: payload.data.data.profileType,
                    profileState: payload.data.data.state,
                    typeActive: payload.data.data.typeActive,
                    typeUnitType: payload.data.data.typeUnitType,
                    unitType: payload.data.data.unitType,
                }));
            } else {
                setLoad(false);
            }
            setLoad(false);

        });
        setLoad(true);

        dispatch(getRequestMyFlow()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let requestsTemp = payload.data.data[0];
                for (const request of requestsTemp) {
                    if (!request.updateat) {
                        request.updateat = moment.utc(request.createdat).format("YYYY-MM-DD");
                    } else {
                        request.updateat = moment.utc(request.updateat).format("YYYY-MM-DD");
                    }
                    request.createdat = moment.utc(request.createdat).format("YYYY-MM-DD");
                }
                setInfoToModule((prevState) => ({
                    ...prevState,
                    rows: requestsTemp
                }));
            } else {
                setLoad(false);
            }
            setLoad(false);
        });
    };
    //Funcion para guardar los filtros de la tabla principal
    const handleOnfilterDinamic = (constant, e) => {
        setPage(1);

        setFiltersByTable(prevState => ({
            ...prevState,
            [constant]: `${e}`
        }))
    }
    //Funcion para aplicar los filtros que se utilizaran
    const renderRowsByFilters = (rows) => {
        if (Object.keys(filterByTable).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filterByTable);
            for (const element of filterKeys) {
                const valueFiltered = filterByTable[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    const handleOnSetRequest = (request) => {
        setRequestSelected(prevState => ({
            ...prevState,
            data: request,
            isSelected: true
        }));
    }
    const handleOnClearRequest = () => {
        setRequestSelected({
            data: {},
            isSelected: false
        });
    }
    
    return (
        <div>
            <AdminHeader name="Mis Flujos" parentName="Finance Flows" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    {!requestSelected.isSelected ?
                        <>
                            <Filter
                                jsonFilters={filter}
                                filters={handleOnfilterDinamic}
                                optionsFilters={infoToModule}
                            />
                            <Table
                                text={"Mis Flujos"}
                                createButton={false}
                                colums={colums}
                                rows={infoToModule.rows.length > 0 && renderRowsByFilters(infoToModule.rows)}
                                page={page}
                                setPage={setPage}
                                aditionalsButtons={aditionalsButtons}
                            />
                        </>
                        :
                        <>
                            <Row className="mt-2 mb-4">
                                <Col className="d-flex justify-content-end col">
                                    <Button
                                        color="warning"
                                        onClick={() => handleOnClearRequest()}
                               
                                    >
                                        <span className="btn-inner--icon mr-1">
                                            <i className="fas fa-chevron-left" />
                                        </span>
                                        <span>Regresar</span>
                                    </Button>
                                </Col>
                            </Row>
                            <RequestDetails
                                request={requestSelected.data}
                                formRequester={formRequester}
                                formDocument={formDocument}
                                formDocumentDesc={formDocumentDesc}
                                getRequest={handleOnGetInfo}
                                setInfoToModule={setInfoToModule}
                                setLoad={setLoad}
                                setRequestSelected={setRequestSelected}
                                typeRequest="MyFlow"
                            />
                        </>
                    }
                </Spin>
            </Container>
        </div>
    );
}


export default MyFlows;