/*!

=========================================================
*Portal WMS
=========================================================

*Componente "CardHeader" el cual llama a los siguientes componentes:
    -CardMain: el cual contiene el diseño y la función para cambiar los TabPanels del componente MainComponent

*Props:
    -setFormSelected: useState que actualiza el tab activo del tabContent del componente MainComponent
    -formSelected: useState que guarda el tab activo del tabContent del componente MainComponent
    -isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
=========================================================

* Coded by Diego Meza / Eduardo Piedra - Application Management GBM

*/

// React library
import React, { useState, useEffect } from 'react';

// Reactstrap components
import {  Row } from "reactstrap";

// Componentes locales.
import { CardMain } from 'components/WMS/SharedComponents/CardMain.jsx';
import InventoryMain  from './Inventory/InventoryMain.jsx';
import InquiriesMain  from './Inquiries/InquiriesMain.jsx';
import ExpandMaterial  from './ExpandMaterial/ExpandMaterial.jsx';
import { ManageForms } from './ManageForms';

const CardHeader = ({
    formSelected,
}) => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Puedes ajustar este valor según tus necesidades
        };

        // Llama a handleResize al principio y cada vez que se redimensiona la ventana
        handleResize();
        window.addEventListener('resize', handleResize);

        // Limpia el listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //array con la información de cada Card Principal (4 cards por cada componente Main)
    const cards = [
        {
            title: "Ingreso de Mercancía",
            subtitle: "Mercancía entrante a las bodegas de GBM.",
            id: "inbound",
            image: "handheld1.jpeg",
            admin: false,
            colorCardSelected: "#d6d4fa",
            component: ManageForms,
            icon: "fa fa-arrow-down",
            colorMobile: "info",
            colorPC: 'info'

        },
        {
            title: "Salida Mercancía",
            subtitle: "Mercancía saliente de las bodegas de GBM.",
            id: "outbound",
            image: "out.jpeg",
            admin: false,
            colorCardSelected: "#d6d4fa",
            component: ManageForms,
            icon: "fa fa-paper-plane",
            colorMobile: "info",
            colorPC: 'info'

        },
        {
            title: "Tareas y Movimientos Spare",
            subtitle: "Tareas y movimientos de GBM.",
            id: "tasks",
            image: "fondo18.jpeg",
            admin: true,
            colorCardSelected: "#d6d4fa",
            component: ManageForms, //FieldExample
            icon: "fa fa-tasks ",
            colorMobile: "info",
            colorPC: 'info'
        },
        {
            title: "Movimiento entre Almacenes",
            subtitle: "Movimiento de mercancía entre los almacenes de GBM.",
            id: "warehousesMovements",
            image: "move1.jpeg",
            admin: false,
            colorCardSelected: "#d6d4fa",
            component: ManageForms,
            icon: "fa fa-retweet ",
            colorMobile: "info",
            colorPC: 'info'
        },
        {
            title: "Tomas Físicas",
            subtitle: "Inventario Valorado y No Valorado",
            id: "5",
            image: "physicalshots.jpeg",
            admin: false,
            colorCardSelected: "#d6d4fa",
            component: InventoryMain,
            icon: "fa fa-archive ",
            colorMobile: "info",
            colorPC: 'info'
        },
        {
            title: "Consultar Stock",
            subtitle: "Consulta por Material y Planta",
            id: "6",
            image: "search.jpeg",
            admin: false,
            colorCardSelected: "#d6d4fa",
            component: InquiriesMain,
            icon: "fa fa-search ",
            colorMobile: "info",
            colorPC: 'info'
        },
        {
            title: "Ampliar Materiales",
            subtitle: "Ampliar material a WMS",
            id: "7",
            image: "plusmaterials.jpeg",
            admin: false,
            colorCardSelected: "#d6d4fa",
            component: ExpandMaterial,
            icon: "fa fa-arrows-alt ",
            colorMobile: "info",
            colorPC: 'info'
        },

    ];

    //State para guardar el card seleccionado.
    const [selectedCard, setSelectedCard] = useState(
       null
    );

    //Función para abrir un componente de un card.
    const handleOpenComponent = (card) => {
        setSelectedCard(card)
    };

    //Función para volver a la vista principal de cards.
    const handleOnBack = () => {
        setSelectedCard(null);
    };

    return (
        
        <>
            {selectedCard ?
                (

                    <selectedCard.component
                        //#region Props para el card
                        handleOnBack={handleOnBack}
                        // idCard={selectedCard.id}
                        propsCard={selectedCard}

                    //#endregion
                    //#region Props de otros componentes

                    //#endregion       
                    />

                )

                : (
                    <Row>
                        {
                            cards.map((row, key) => (
                                // (!row.admin || row.admin === isAdmin) &&
                                // <Col key={key} >
                                <CardMain
                                    key={key}
                                    formSelected={formSelected}
                                    handleOnClick={() => handleOpenComponent(row)}
                                    isMobile={isMobile}
                                    props={row}

                                >
                                </CardMain>
                                // </Col>

                            ))
                        }
                    </Row>
                )
            }

        </>
    );
};

export default CardHeader;