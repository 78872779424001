/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "MainTable" es un componente compartido por los Main de cada sección donde se imprime
la tabla principal con sus respectivas acciones y su información según los filtros de FilterMain

*Props:
        setPage: useState para actualizar la página activa de la tabla
        page: state que guarda la página activa de la tabla
        reloadTable: useState para actualizar las filas de la tabla MainTable automaticamente
        search: state con los filtros seleccionados en FilterMain
        dropDownOptions: useState con las opciones de los dropdowns
        selected: state con las columnas que aparecen por defecto en la tabla 
        setSelected: useState para modificar las columnas que se visualizan
        optionsSelect: state con todas las columnas de la tabla
        tableParameter: state que se utiliza para determinar que tabla realiza la solicitud para traer su información
        rolArea: useState que guarda el área del usuario logeado
        statusError: el id del status de error que tiene la DB para así ocultar las filas con error
        isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
        isL0: useState que guarda un booleano para verificar si el usuario logeado es aprobador L0
        isL2: useState que guarda un booleano para verificar si el usuario logeado es aprobador L2

=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useEffect, useState, useRef } from 'react';
// react MultiSelect
import { MultiSelect } from "react-multi-select-component";
// hook para fetch
import { useDispatch } from 'react-redux';
// reactstrap components
import { Tooltip, Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
// react date moment
import moment from "moment";
// accion para extraer las filas de la tabla principal de cada sección 
import { getRows } from 'actions/Freelance.jsx';
// antd components
import { Spin } from 'antd';

const MainTable = (
    {
        setPage,
        page,
        reloadTable,
        search,
        dropDownOptions,
        selected,
        setSelected,
        optionsSelect,
        tableParameter,
        rolArea,
        statusError,
        isAdmin,
        isL0,
        isL2,
        checkboxState,
        isAlta,
    }) => {

    //#region Functions and States
console.log(isAlta)
    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(false);
    //#endregion

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region estado para guardar las filas de la tabla y la cantidad de filas por página 
    const [state, setState] = useState({
        sizePerPage: 10,
        rows: [],
        rowsError: []
    });
    const { sizePerPage, rows, rowsError } = state;
    //#endregion

    //#region Pagination de la tabla principal
    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }
    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);
    };
    //funcion para cambiar la cantidad de filas por pagina
    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            sizePerPage: value
        }))
    }
    //#endregion

    //#region filtros por cada columna de la tabla
    const [filters, setFilters] = useState([]);

    //funcion para buscar por columna en la tabla
    const handleOnfilterDinamic = (constant, e) => {
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }

    //Aplicar los filtros a la data de la tabla
    const renderRowsByFilters = (rows) => {

        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);
            for (const element of filterKeys) {
                const valueFiltered = filters[element].toString().toLowerCase();
                rows = rows.filter((item) => item[element].toString().toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }

    //#endregion

    //#region traer las rows de la data de la tabla

    //hook para que no se ejecute el useEffect abajo cuando se carga la página por primera vez
    const count = useRef(0);
    //Hook para refrescar la data de la tabla 
    useEffect(() => {
        setLoad(true)
        if (count.current != 0) {
            const getRowsData = async () => {
                await handleRowData();
            }

            getRowsData();
        }
        else{
            setLoad(false)
        }
        count.current = 1;
    }, [reloadTable])

    //Funcion para traer las rows de la data de la tabla
    const handleRowData = async () => {

        dispatch(getRows({ filters: search, table: tableParameter })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setState(prevState => ({
                    ...prevState,
                    rows: payload.data.rows,
                    rowsError: payload.data.rows
                }))

            }
            else {
                setState(prevState => ({
                    ...prevState,
                    rows: [],
                    rowsError: []
                }))
            }
            setLoad(false);
        })
    }
    //#endregion

    //#region funcion para ocultar filas con error
    const handlerHideError = (e) => {
        e.persist();
        setPage(1);
        if (e.target.checked === false) {
            setState((prevState) => ({
                ...prevState,
                rows: rowsError,
                rowsError: rows
            }))
        } else {
            let Newrows = rows.filter((item) => item["status"].toString().toLowerCase().indexOf(statusError) === -1)
            setState((prevState) => ({
                ...prevState,
                rows: Newrows,
                rowsError: rows
            }))

        }

    }
    //#endregion

    //#endregion

    return (
        <>

            <Card>
                <Spin size="large" spinning={loading}>
                    {
                        rows.length > 0 &&
                        <>
                            <CardHeader>
                                <Row>
                                    <Col sm="11">
                                        <p className="text-sm mb-0">
                                            Columnas Seleccionables
                                        </p>
                                    </Col>
                                    {
                                        statusError !== "NA" &&
                                        <Col sm="1">
                                            <p className="text-sm mb-0">
                                                Ocultar Error
                                            </p>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col sm="11">
                                        <MultiSelect
                                            options={optionsSelect}
                                            value={selected}
                                            onChange={setSelected}
                                            labelledBy="Select"
                                        />
                                    </Col>
                                    {
                                        statusError !== "NA" &&
                                        <Col sm="1">
                                            <div style={{ alignItems: "center", marginTop: "3px" }}>

                                                <label className="custom-toggle custom-toggle-danger">
                                                    <input className='mb-2' type="checkbox" onChange={(e) => handlerHideError(e)} />
                                                    <span className="custom-toggle-slider rounded-circle" data-label-off="" data-label-on=""></span>
                                                </label>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    className="align-items-center table-flush"
                                    responsive
                                    striped={true}
                                    hover={true}
                                >
                                    <thead className="thead-light">
                                        <tr>
                                            {
                                                selected.map((item, key) => {
                                                    return (
                                                        <th key={"title" + key} className="justify-content-md-center">
                                                            <FormGroup>
                                                                <label
                                                                    style={{ fontSize: "12px" }}
                                                                    className="form-control-label"
                                                                    htmlFor="input-username"

                                                                >
                                                                    {`${item.label}:`}
                                                                </label>
                                                                <Input
                                                                    id={item.id}

                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder={`Buscar ${item.label}...`}
                                                                    onChange={(e) => handleOnfilterDinamic(item.value, e.target.value)}
                                                                />
                                                            </FormGroup>
                                                        </th>
                                                    );
                                                })}

                                        </tr>
                                    </thead>
                                    <tbody className="list">

                                        {
                                            //rows.map para imprimir cada fila de la consulta
                                            renderRowsByFilters(rows).slice((page - 1) * sizePerPage, page * sizePerPage).map((row, keyRow) => (

                                                <tr key={"row" + keyRow} className="text-center">
                                                    {

                                                        //selected.map para imprimir cada columna de cada fila  
                                                        selected.map((col, key2) => (
                                                            <td key={"col" + key2}
                                                                className={(col.value === "actions" || col.type === "checkBox") ? "table-actions" : "text-center"}>
                                                                {
                                                                    col.type === "date" && moment.utc(row[col.value]).format('L') !== "Invalid date" ?
                                                                        moment.utc(row[col.value]).format('L')
                                                                        :
                                                                        col.type === "checkBox" ?
                                                                            (rolArea === row["areaCode"] && row["status"] === 3 || isAdmin && row["status"] === 3 || isL0 && row["status"] === 14) &&
                                                                            <label className="custom-toggle custom-toggle-info">
                                                                                <input className='mb-2' checked={checkboxState[row.id] || false} type="checkbox" onChange={(e) => col.fun(e, row)} />
                                                                                <span className="custom-toggle-slider rounded-circle" data-label-off="" data-label-on=""></span>
                                                                            </label>
                                                                            :
                                                                            col.value === "actions" ?
                                                                                col.buttons.map((butt, key3) => {
                                                                                    return (
                                                                                        //si el area del coordinador es igual al area de la solicitud, si el status (add) es igual a 0 significa que el boton es general para todas las filas
                                                                                        //si es admin siempre va y por ultimo para ReportHoursMain si es el status (add) es igual a true significa que si debe de haber accion
                          
                                                                                        (rolArea === row["areaCode"] || butt.add === 0 || isAdmin || butt.add === true || butt.add === "hitoActa" && row["byHito"].toString() === "1" || butt.add === "admin" && isAdmin || butt.add === "isAlta" && isAlta) &&
                                                                                      
                                                                                        (butt.add === row["status"] || butt.add === 0 || butt.add === "po" || butt.add === isL0 && row["status"] === 14 || 
                                                                                        
                                                                                        butt.add === isL2 && row["status"] === 4 || butt.add === "hito" && row["byHito"].toString() === "1" 
                                                                                        
                                                                                        || butt.add === "hitoActa" && row["byHito"].toString() === "1" || butt.add === "alta" && row["type"] === 1 || butt.add === "isAlta" && row["status"] === 1 && isAlta ||
                                                                                       
                                                                                        butt.add === "admin" && isAdmin) &&

                                                                                        <>
                                                                                            <Button
                                                                                                className="btn-icon-only"
                                                                                                outline
                                                                                                color={butt.color}
                                                                                                type="button"
                                                                                                key={"button" + key3}
                                                                                                onClick={(e) => butt.fun(e, row, dropDownOptions)}
                                                                                                title={butt.title}
                                                                                                id={butt.id}
                                                                                            >
                                                                                                <span className="btn-inner--icon">
                                                                                                    <i className={butt.icon} />
                                                                                                </span>
                                                                                            </Button>

                                                                                            <Tooltip key={"Tooltip" + key3} target={butt.id}>{butt.title}</Tooltip>
                                                                                        </>)
                                                                                })

                                                                                : col.type === "color" ?
                                                                                    <button type="button" className={"btn btn-round btn-wd btn-sm btn-" + (typeof dropDownOptions[col.optionList].find(dt => dt.value.toString() === row[col.val].toString()) !== "undefined" ? dropDownOptions[col.optionList].find(dt => dt.value.toString() === row[col.val].toString())[col.option] : "")}>
                                                                                        {row[col.value]}
                                                                                    </button>
                                                                                    :
                                                                                    row[col.value]
                                                                }
                                                            </td>
                                                        ))

                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter className="py-4">
                                <nav aria-label="...">
                                    <Row className="align-items-center">
                                        <Col sm='12' md='6' className="p-1">
                                            <Row className="justify-content-start">
                                                <Col sm='12' md='6'>
                                                    <span className='pagination mb-0'>
                                                        Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                                    </span>
                                                </Col>
                                                <Col sm='12' md='2'>
                                                    <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                                                        <option selected>10</option>
                                                        <option>25</option>
                                                        <option>50</option>
                                                        <option>100</option>
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm='12' md='6' className="p-1">
                                            <Pagination
                                                className="pagination justify-content-end mb-0"
                                                listClassName="justify-content-end mb-0"
                                            >
                                                <PaginationItem className={page === 1 ? "disabled" : ""}>
                                                    <PaginationLink
                                                        onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                                        tabIndex="-1"
                                                    >
                                                        <i className="fas fa-angle-left" />
                                                        <span className="sr-only">Previous</span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                                {
                                                    renderPaginations()
                                                }
                                                <PaginationItem className={page === paginations ? "disabled" : ""}>
                                                    <PaginationLink
                                                        onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                                    >
                                                        <i className="fas fa-angle-right" />
                                                        <span className="sr-only">Next</span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </nav>
                            </CardFooter>
                        </>
                    }
                </Spin>
            </Card>
        </>
    );
};

export default MainTable;
