
import React, { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Pagination, PaginationItem, PaginationLink, Row, Table, Button } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import { getPurchaseOrder } from 'actions/costaRicaBids';
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';
import Exportexcel from "react-export-excel";

const AgreementCRMainTable = ({ rows, openModal, setPage, page, options, handleOnParticipation,
  filters, setRowsModal, rowsExcel, refreshInput, isDisplay, isAdmin, setRefreshInput, setRole,
  role, token, setLoading }) => {

  const ExcelFile = Exportexcel.ExcelFile;
  const ExcelSheet = Exportexcel.ExcelSheet;
  const ExcelColumn = Exportexcel.ExcelColumn;

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(
    [
      { value: "bidNumber", label: "Número de concurso" },
      { value: "institution", label: "Institución" },
      { value: "description", label: "Descripcion" },
      { value: "participation", label: "Participa" },
      { value: "processType", label: "Tipo de Procedimiento" },
      { value: "gbmStatus", label: "Interes de GBM" }
    ]
  );
  const optionsSelect = [
    { value: "bidNumber", label: "Número de concurso", disabled: true },
    { value: "institution", label: "Institución", disabled: true },
    { value: "processType", label: "Tipo de Procedimiento", disabled: true },
    { value: "description", label: "Descripcion", disabled: true },
    { value: "timeAdjudge", label: "Plazo de adjudicación" },
    { value: "budget", label: "Presupuesto" },
    { value: "timeDelivery", label: "Plazo de entrega" },
    { value: "offerValidity", label: "Vigencia de oferta", disabled: true },
    { value: "expirationThird", label: "Vencimiento del tercio" },
    { value: "openningDays", label: "Días de apertura" },
    { value: "month", label: "Mes" },
    { value: "receptionClosing", label: "Mejora de PreciosCierre de Recepción de ofertas", type: "date" },
    { value: "offerOpening", label: "Apertura de oferta", type: "date" },
    { value: "receptionClarification", label: "Fecha/hora límite de recepción de Aclaración", type: "date" },
    { value: "receptionExt", label: "Recepción de extemporáneas	" },
    { value: "receptionObjections", label: "Fecha/hora límite de recepción de objeciones", type: "date" },
    { value: "publicationDate", label: "Fecha de publicación", type: "date" },
    { value: "changeDate", label: "Fecha de cambio", type: "date" },
    { value: "participateUser", label: "Usuario participante" },
    { value: "valueTeam", label: "	Value team" },
    { value: "salesType", label: "Sales Type" },
    { value: "oppType", label: "Sales Team" },
    { value: "noParticipationReason", label: "Motivo no participa	" },
    { value: "gbmStatus", label: "Interes de GBM", disabled: true },
    { value: "accountManager", label: "Account Manager" },
    { value: "managerSector", label: "Gerente de sector" },
    { value: "participation", label: "Participa", disabled: true },
    { value: "opp", label: "Opp" },
    { value: "quote", label: "Quote" },
    { value: "salesOrder", label: "Saler Order" },
    { value: "customerInstitute", label: "Institución del cliente" },
    { value: "contactInstitute", label: "Institución del contacto" },
    { value: "improvePrices", label: "Mejora Precios" },
    { value: "participationWarranty", label: "Garantía de participación	" },
    { value: "complianceAmount", label: "Monto de cumplimiento" },
    { value: "workPoster", label: "Cartel de trabajo" },
    { value: "notParticipate", label: "No participa" },
    { value: "comment", label: "Comentario" },
    { value: "complianceWarranty", label: "Garantía de cumplimiento" },
    { value: "participationAmount", label: "Monto de participación" },

  ];
  const optionsExcel = [
    { value: "bidNumber", label: "Número de concurso", disabled: true },
    { value: "institution", label: "Institución", disabled: true },
    { value: "processType", label: "Tipo de Procedimiento", disabled: true },
    { value: "description", label: "Descripcion", disabled: true },
    { value: "timeAdjudge", label: "Plazo de adjudicación" },
    { value: "budget", label: "Presupuesto" },
    { value: "timeDelivery", label: "Plazo de entrega" },
    { value: "offerValidity", label: "Vigencia de oferta", disabled: true },
    { value: "expirationThird", label: "Vencimiento del tercio" },
    { value: "openningDays", label: "Días de apertura" },
    { value: "month", label: "Mes" },
    { value: "receptionClosing", label: "Mejora de PreciosCierre de Recepción de ofertas", type: "date" },
    { value: "offerOpening", label: "Apertura de oferta", type: "date" },
    { value: "receptionClarification", label: "Fecha/hora límite de recepción de Aclaración", type: "date" },
    { value: "receptionExt", label: "Recepción de extemporáneas	" },
    { value: "receptionObjections", label: "Fecha/hora límite de recepción de objeciones", type: "date" },
    { value: "publicationDate", label: "Fecha de publicación", type: "date" },
    { value: "changeDate", label: "Fecha de cambio", type: "date" },
    { value: "participateUser", label: "Usuario participante" },
    { value: "valueTeam", label: "	Value team" },
    { value: "salesType", label: "Sales Type" },
    { value: "oppType", label: "Sales Team" },
    { value: "noParticipationReason", label: "Motivo no participa	" },
    { value: "gbmStatus", label: "Interes de GBM", disabled: true },
    { value: "accountManager", label: "Account Manager" },
    { value: "managerSector", label: "Gerente de sector" },
    { value: "participation", label: "Participa", disabled: true },
    { value: "opp", label: "Opp" },
    { value: "quote", label: "Quote" },
    { value: "salesOrder", label: "Saler Order" },
    { value: "customerInstitute", label: "Institución del cliente" },
    { value: "contactInstitute", label: "Institución del contacto" },
    { value: "improvePrices", label: "Mejora Precios" },
    { value: "participationWarranty", label: "Garantía de participación	" },
    { value: "complianceAmount", label: "Monto de cumplimiento" },
    { value: "workPoster", label: "Cartel de trabajo" },
    { value: "notParticipate", label: "No participa" },
    { value: "comment", label: "Comentario" },
    { value: "complianceWarranty", label: "Garantía de cumplimiento" },
    { value: "participationAmount", label: "Monto de participación" },
    { value: "productCode", label: "Codigo de Producto" },
    { value: "productName", label: "Nombre de Producto" },
    { value: "productAmount", label: "Monto de Producto" },
    { value: "productUnit", label: "Unidad de Producto" },
    { value: "productUnitPrice", label: "Precio de unidad de Producto" },
    { value: "detailDeparture", label: "Detalle de partida de producto" },
    { value: "productLine", label: "Linea de producto" },
    { value: "productDetailLine", label: "Detalle de Linea de producto" },

  ];
  const [state, setState] = useState({
    sizePerPage: 10
  });
  const { sizePerPage } = state;
  const [check, setCheck] = useState(false);
  //#region Pagination 

  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1
  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => handleOnSetPage(i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }
  //Cambiar de pagina
  const handleOnSetPage = (page, who) => {
    setPage(page);
  };
  const handleOnSetNumPagination = (e) => {
    setPage(1);
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      sizePerPage: value
    }))
  }
  //#endregion

  //Hook para refrescar la data de la tabla 
  useEffect(() => {
    const getRowsData = async () => {
      await handleRowData();
    }
    getRowsData();
  }, [refreshInput])
  //Funcion para traer las rows de la data de la tabla
  const handleRowData = async () => {
    let role = "";
    if (check === true) {
      role = "";
    } else {
      const teams = getTeamsUserAccess();
      if (teams.some((row) => row.indexOf("CostaRicaBids GBM Direct/Premium Account") !== -1)) {
        setRole("CostaRicaBids GBM Direct/Premium Account")
        role = "CostaRicaBids GBM Direct/Premium Account";
      }
      if (teams.some((row) => row.indexOf("CostaRicaBids VT TELCO") !== -1)) {
        setRole("VT TELCO")
        role = "VT TELCO";
      }
      if (teams.some((row) => row.indexOf("CostaRicaBids VT Bnkng & Finance") !== -1)) {
        setRole("VT Bnkng & Finance")
        role = "VT Bnkng & Finance";
      }
      if (teams.some((row) => row.indexOf("CostaRicaBids VT Public Sector") !== -1)) {
        setRole("VT Public Sector")
        role = "VT Public Sector";
      }
      if (teams.some((row) => row.indexOf("CostaRicaBids AM") !== -1)) {
        setRole("CostaRicaBids AM")
        role = "CostaRicaBids AM";
      }
    }
    dispatch(getPurchaseOrder({ role: role })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal(prevState => ({
          ...prevState,
          rows: payload.data.payload.data
        }))
      }
    })
  }
  const handleOnGetAllProcedures = (e) => {
    if (check === false) {
      setCheck(!check);
      setPage(1)
      setRefreshInput(5);
    } else {
      setCheck(!check);
      setPage(1)
      setRefreshInput(4);
    }
  }
  const handleOnRefreshTable = () => {
    setLoading(true);
    dispatch(getPurchaseOrder({ role: role })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal(prevState => ({
          ...prevState,
          rows: payload.data.payload.data
        }))
      }
      setCheck(false);
      setLoading(false);
    })
  }
  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="10">
              <p className="text-sm mb-0">
                Columnas Seleccionables
              </p>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col sm="12" md="1">
              <MultiSelect
                options={optionsSelect}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
              />
            </Col>
            <Col sm="12" md="5">
              <Row>
                <label ><strong> Mostrar todos los procedimiento   </strong></label>
                <label className="custom-toggle custom-toggle-info">
                  <input className='mb-2' id="edit" type="checkbox" onChange={(e) => handleOnGetAllProcedures(e.target)} disabled={isAdmin === true ? true : false} checked={isAdmin === true ? true : check}
                  />
                  <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Si"></span>
                </label>

              </Row>
            </Col>
            <Col xs="12" md="3">
              <Button
                className="btn btn-icon"
                color="info"
                block
                type="button"
                onClick={() => handleOnRefreshTable()}
              >
                <span className="btn-inner--icon mr-">
                  <i class="fas fa-sync-alt"></i>
                </span>
                <span className="btn-inner--text">Refrescar</span>

              </Button>
            </Col>
            <Col xs="12" md="3" className="p-1">
              <ExcelFile 
              
              element={
              <Button
                className="btn btn-icon"
                color="success"
                block
                type="button"
              >
                <span className="btn-inner--icon mr-">
                  <i class="fas fa-download"></i>
                </span>
                <span className="btn-inner--text">Descargar</span>
              </Button>
              }
              
              filename="Reporte Convenio CR"
              >

                <ExcelSheet data={rowsExcel} name="Reporte convenio CR">
                  {optionsExcel.map((col, key) => (
                    <ExcelColumn key={key} label={col.label} value={col.value} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            style={{ whiteSpace: "normal", textAlign: "auto" }}
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">
              <tr>
                {selected.map((item, key) => {
                  return (
                    <th key={key} className="justify-content-md-center">
                      <FormGroup key={key}>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${item.label}:`}
                        </label>
                        <Input
                          id={item.id}
                          key={key}
                          className="form-control"
                          type="text"
                          placeholder={`Buscar ${item.label}...`}
                          onChange={(e) => filters(item.value, e.target.value)}
                        />
                      </FormGroup>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="list">
              {rows &&
                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                  <tr key={key} className="text-center">
                    {selected.map((col, key) => (
                      <td key={key} className="text-center text-break" style={{ whiteSpace: "normal", textAlign: "auto" }}>
                        {
                          col.value === "bidNumber" ?
                            <a key={key} onClick={() => openModal(row)} style={{ color: "blue" }}>
                              {row[col.value]}
                            </a>
                            :
                            col.type === "date" && moment.utc(row[col.value]).format('L') !== "Invalid date" ?

                              moment.utc(row[col.value]).format('L')

                              : col.value === "participation" ?
                                <Select
                                  id="selectCustom"
                                  style={{ position: 'absolute', zIndex: 2 }}
                                  key={key}
                                  isDisabled={isDisplay === true ? true : isAdmin === true ? false : role === row["valueTeam"] ? false :
                                    (role === "CostaRicaBids AM" && row.accountManager === token) ? false :
                                      (role === "CostaRicaBids GBM Direct/Premium Account" && (row["valueTeam"] === "Premiun Account" || row["valueTeam"] === "GBM Direct")) ? false : true}

                                  className="basic-single"
                                  value={{ label: row[col.value], value: 1 }}
                                  options={options}
                                  onChange={(e) => handleOnParticipation(e, row)}
                                />
                                :
                                row[col.value]
                        }
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs='12' md='6' className="p-1">
                <Row className="justify-content-start">
                  <Col xs='12' md='6'>
                    <span className='pagination mb-0'>
                      Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                    </span>
                  </Col>
                  <Col xs='12' md='2'>
                    <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col xs='12' md='6' className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {
                    renderPaginations()
                  }
                  <PaginationItem className={page === paginations ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AgreementCRMainTable;