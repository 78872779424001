import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { ItRequestsRoutes } from "routes/routes.jsx";

const Index = () => {
    //FUNCIONES
    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin/itrequests") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            return null;
        });
    };

    //RETURN
    return (
        <>
            <Switch>
                {
                    getRoutes(ItRequestsRoutes)
                }
                <Redirect from="/admin/itrequests" to="/admin/itrequests/Home" />
            </Switch>
            <p className="lead"></p>
        </>
    );
};

export default Index;