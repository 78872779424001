// core selectors
import {
  findAllUserEscalationTss,
  createUserEscalationTSS,
  deactivatedUserEscalationTss,
} from "actions/Sales/support.jsx";
import { validateGbmCollaborator } from "actions/Sales/plannersFlow";
// core antd
import { Spin } from "antd";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// core components Support
import AddEscaltionTss from "components/Sales/Support/AddEscaltionTss.jsx";
import TssManagement from "components/Sales/Support/TssManagement.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getUsersEscalationsTSS } from "selectors/Sales/support";

class TSSEscalations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      loadingModal: false,
      message: null,
      modalAdd: false,
      initialValues: {
        username: "",
      },
      infoUser: {},
      isInvalidUser: true,
    };
  }

  componentDidMount() {
    this.props.findAllUserEscalationTss().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState((state) => ({
        willMount: !state.willMount,
      }));
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnToggleModalAddEscalation = () => {
    this.setState({
      modalAdd: false,
      infoUser: {},
      initialValues: {},
    });
  };

  handleOnAddEscalationTss = () => {
    this.setState({
      modalAdd: true,
    });
  };

  handleOnValidateUserEscalationTss = async (values) => {
    const { username } = values;
    this.setState((state) => ({
      loadingModal: !state.loadingModal,
      message: `Validando el usuario ingresado con la información de la compañia`,
      initialValues: { username },
      infoUser: {},
    }));
    await this.props
      .validateGbmCollaborator({
        username: username.split("@")[0].toUpperCase(),
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: {
              payload: { response },
            },
          } = res.payload;
          this.setState({
            initialValues: { username: response.EMAIL },
            infoUser: response,
            isInvalidUser: false,
          });
        }
        this.setState((state) => ({
          loadingModal: !state.loadingModal,
          message: null,
        }));
      });
  };

  handleOnAddUserEscalationTss = async (values) => {
    const { infoUser } = this.state;
    this.setState((state) => ({
      loadingModal: !state.loadingModal,
      message: `Creando el usuario ingresado para escalaciones`,
    }));
    await this.props
      .createUserEscalationTSS({
        idUser: infoUser.IDCOLABC,
        name: infoUser.NOMBRE,
        email: infoUser.EMAIL,
        country: values.country,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.setState({
            initialValues: {},
            infoUser: {},
            isInvalidUser: true,
            modalAdd: false,
          });
          this.props.findAllUserEscalationTss();
        }
        this.setState((state) => ({
          loadingModal: !state.loadingModal,
          message: null,
        }));
      });
  };

  onDeleteUserEscalationTss = async (row) => {
    this.hideAlert();
    this.setState((state) => ({
      loadingModal: !state.loadingModal,
      message: `Eliminando el usuario seleccionado`,
    }));
    await this.props.deactivatedUserEscalationTss(row.id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findAllUserEscalationTss();
      }
      this.setState((state) => ({
        loadingModal: !state.loadingModal,
        message: null,
      }));
    });
  };

  handleOnDeleteUserEscalationTss = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar el usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.onDeleteUserEscalationTss(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  render() {
    const {
      alert,
      loading,
      loadingModal,
      message,
      willMount,
      modalAdd,
      infoUser,
      initialValues,
      isInvalidUser,
    } = this.state;
    const { name, parentName, cols, rows } = this.props;

    return (
      <>
        {alert}
        <AddEscaltionTss
          loading={loadingModal}
          message={message}
          showModal={modalAdd}
          isInvalid={isInvalidUser}
          initialValues={initialValues}
          user={infoUser}
          countries={[
            { id: "CR", name: "Costa Rica", key: "CR" },
            { id: "SV", name: "El Salvador", key: "SV" },
            { id: "GT", name: "Guatemala", key: "GT" },
            { id: "HN", name: "Honduras", key: "HN" },
            { id: "MD", name: "Miami", key: "MD" },
            { id: "NI", name: "Nicaragua", key: "NI" },
            { id: "PA", name: "Panamá", key: "PA" },
            { id: "DO", name: "República Dominicana", key: "DO" },
          ]}
          toggleModal={this.handleOnToggleModalAddEscalation}
          addUser={this.handleOnAddUserEscalationTss}
          onValidete={this.handleOnValidateUserEscalationTss}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message} size="large">
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de usuarios" />
                </Col>
              </Row>
            ) : rows.length ? (
              <Row className="justify-content-center">
                <Col>
                  <TssManagement
                    title="Mantenimiento"
                    subtitle="Gestión de usuarios para escalaciones TSS"
                    cols={cols}
                    rows={rows}
                    addEscalation={this.handleOnAddEscalationTss}
                    onDelete={this.handleOnDeleteUserEscalationTss}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay usuarios por visualizar."
                    subtitle="Actualmente al día de hoy, no hay usuarios para visualizar, cuando hayan se enlistarán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

TSSEscalations.defaultProps = {
  name: "Gestión de Escalaciones",
  parentName: "Mantenimientos",
  cols: [
    { key: "id", name: "#REF" },
    { key: "idColaborador", name: "ID Colaborador" },
    { key: "nombre", name: "Nombre" },
    { key: "email", name: "Correo" },
    { key: "pais", name: "País" },
  ],
  rows: [],
};

TSSEscalations.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  rows: getUsersEscalationsTSS(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllUserEscalationTss,
    validateGbmCollaborator,
    createUserEscalationTSS,
    deactivatedUserEscalationTss,
  })(TSSEscalations)
);
