import React from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// reactstrap components
import lifecycle from "react-pure-lifecycle";
// reactstrap components
import { PropTypes } from "prop-types";

import { Card, CardHeader, CardBody, Row } from "reactstrap";

// core components

import { chartOptions, parseOptions } from "variables/charts.jsx";

const methods = {
  componentWillMount(props) {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
};

const ExtraHoursChartStates = props => {
  var manageDataXaxis = () => {
    return [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Oct",
      "Nov",
      "Dic"
    ];
  };

  var manageDataApproved = () => {
    var arrayApproved = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 1; i < 13; i++) {
      for (var j = 0; j < props.data.length; j++) {
        if (props.data[j].month === i && props.data[j].approved != null) {
          arrayApproved[i - 1] = arrayApproved[i - 1] + props.data[j].approved;
        }
      }
    }
    arrayApproved = arrayApproved.map(element => element.toFixed(2));
    return arrayApproved;
  };

  var manageDataDenied = () => {
    var arrayDenied = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 1; i < 13; i++) {
      for (var j = 0; j < props.data.length; j++) {
        if (props.data[j].month === i && props.data[j].denied != null) {
          arrayDenied[i - 1] = arrayDenied[i - 1] + props.data[j].denied;
        }
      }
    }
    arrayDenied = arrayDenied.map(element => element.toFixed(2));
    return arrayDenied;
  };

  var manageDataWait = () => {
    var arrayWait = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 1; i < 13; i++) {
      for (var j = 0; j < props.data.length; j++) {
        if (props.data[j].month === i && props.data[j].wait != null) {
          arrayWait[i - 1] = arrayWait[i - 1] + props.data[j].wait;
        }
      }
    }
    arrayWait = arrayWait.map(element => element.toFixed(2));
    return arrayWait;
  };

  var chart = {
    options: {
      tooltips: {
        mode: "index",
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: false,
            categoryPercentage: 0.4
          }
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              callback: function(value) {
                if (!(value % 1)) {
                  return value + "hrs";
                }
              }
            }
          }
        ]
      }
    },
    data: {
      labels: manageDataXaxis(),
      datasets: [
        {
          label: "Aprobadas",
          backgroundColor: "#32CD32",
          data: manageDataApproved()
        },
        {
          label: "Rechazadas",
          backgroundColor: "#FF0000",
          data: manageDataDenied()
        },
        {
          label: "En espera",
          backgroundColor: "#FFFF00",
          data: manageDataWait()
        }
      ]
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <div className="col">
              <h6 className="text-light text-uppercase ls-1 mb-1">
                Horas extra
              </h6>
              <h5 className="h3 text-blue mb-0">Estado de horas por mes</h5>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Bar
              data={chart.data}
              options={chart.options}
              className="chart-canvas"
              id="chart-bar-stacked"
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};
ExtraHoursChartStates.propTypes = {
  data: PropTypes.array.isRequired
};
export default lifecycle(methods)(ExtraHoursChartStates);
