import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const transactionCountData = handleActions(
  {
    [constants.WMS_DASHBOARD_COUNT_TRANSACTIONS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  [null]
);

export const loginTotalsData = handleActions(
  {
    [constants.WMS_DASHBOARD_LOGIN_TOTALS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  []
);

export const mostExecutedTransactionData = handleActions(
  {
    [constants.WMS_DASHBOARD_MOST_EXECUTED]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  []
);

export const executedTotalsData = handleActions(
  {
    [constants.WMS_DASHBOARD_EXECUTED_TOTALS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  []
);

export const movementsTotalsData = handleActions(
  {
    [constants.WMS_DASHBOARD_MOVEMENT_TOTALS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  []
);

export const locationsTotalsData = handleActions(
  {
    [constants.WMS_DASHBOARD_LOCATION_TOTALS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  []
);

export const monthsTotalsData = handleActions(
  {
    [constants.WMS_DASHBOARD_MONTHS_TOTALS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  []
);

export const daysTotalsData = handleActions(
  {
    [constants.WMS_DASHBOARD_DAYS_TOTALS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  []
);

// export const monthTotals = handleActions(
//   {
//     [constants.WMS_DASHBOARD]: (state, action) => {
//       const { status, data } = action.payload;
//       if (status === 200) {
//         return data.payload.data;
//       }
//       return {};
//     }
//   },
//   []
// );
