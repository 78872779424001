/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Reporting" del boton de Mantenimiento en el cual se genera un reporte de excel segun
 los filtros o de toda la informacion en BD si es que no se selecciono nada en los mismos
    
*Props:
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
=========================================================

* Coded by Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState, useEffect } from "react";
// reactstrap components
import { Card, CardBody, Col, Row } from "reactstrap";
//Importacion de el componente de los Filtros
import FiltersMain from "components/Freelance/SharedComponents/FiltersMain.jsx";
//accion para extraer la informacion necesaria para el reporte segun los filtros
import { getInfoReport } from "actions/Freelance";
//usedispatch for fetch
import { useDispatch } from "react-redux";

const Reporting = ({ Toast, dropDownOptions }) => {
  //#region Funtions
  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region state y su funcion para el reporte de excel en la cual se establecen los filtros para el llenado del mismo
  const [stateExcel, setStateExcel] = useState({
    sizePerPage: 10,
    rows: [],
    rowsError: [],
  });

  const { sizePerPage, rows, rowsError } = stateExcel;

  const [infoExcel, setInfoExcel] = useState({});

  const handleOnChangeFilter = (constant, valor) => {
    setInfoExcel((prevState) => ({
      ...prevState,
      [constant]: valor,
    }));
  };
  //#endregion

  //#region Funcion en la cual se ejecuta el dispatch con la informacion de los filtros para el llenado del reporte de excel
  // useEffect(() => {
  //   dispatch(getInfoReport({ info: infoExcel })).then((resp) => {
  //     const { payload } = resp;
  //     if (payload.status === 200) {
  //       setStateExcel((prevState) => ({
  //         ...prevState,
  //         rows: payload.data.rows,
  //         rowsError: payload.data.rows,
  //       }));
  //     } else if (payload.status === 404) {
  //       Toast.fire({
  //         title: "Atención",
  //         html: "No se encontrarón registros para el filtro seleccionado",
  //         type: "warning",
  //       });
  //       setStateExcel((prevState) => ({
  //         ...prevState,
  //         rows: [],
  //         rowsError: [],
  //       }));
  //     } else {
  //       // Toast.fire({
  //       //   title: "Atención",
  //       //   html: "No se cargaron los datos necesarios, por favor comuniquese con Application Managment",
  //       //   type: "warning",
  //       // });
  //       setStateExcel((prevState) => ({
  //         ...prevState,
  //         rows: [],
  //         rowsError: [],
  //       }));
  //     }
  //   });
  // }, [infoExcel]);

  //#endregion

  //#region botones de la tabla MainTable
  const buttons = [
    {
      color: "info",
      icon: "fa fa-download",
      title: "Descargar Reporte",
      docTitle:
        "Reporte Control Horas " + new Date().toISOString().slice(0, 10),
      type: "download",
      colWidth: "12",
    },
  ];
  //#endregion

  //#region Campos seleccionables de los filtros
  const [fields, setFields] = useState([
    {
      colWidth: "6",
      label: "Orden de Compra",
      placeholder: "Seleccione la Orden de Compra",
      id: "purcharseOrderAssignation.purchaseOrder",
      options: "purchaseOrderAssignation",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Proveedores",
      placeholder: "Seleccione el Proveedor",
      id: "purcharseOrderAssignation.consultant",
      options: "accessFreelance",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Proyecto",
      placeholder: "Seleccione el nombre del proyecto",
      id: "purcharseOrderAssignation.project",
      options: "assignationProjects",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Área de negocio",
      placeholder: "Seleccione el Área de negocio",
      id: "purcharseOrderAssignation.area",
      options: "billsArea",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Fecha Desde",
      placeholder: "Seleccione la fecha",
      id: "toDate",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "StartDate",
    },
    {
      colWidth: "6",
      label: "Fecha Hasta",
      placeholder: "Seleccione la fecha",
      id: "toDate",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "EndDate",
    },
  ]);
  //#endregion

  //#region Campos para las columnas del excel
  const optionsSelect = [
    { value: "consultantName", label: "Nombre del Proveedor" },
    { value: "subContractor", label: "Subcontratista" },
    { value: "responsible", label: "Responsable" },
    { value: "areaText", label: "Área" },
    { value: "purchaseOrderItem", label: "Orden de Compra" },
    { value: "description", label: "Descripción" },
    { value: "projects", label: "Proyecto" },
    { value: "hito", label: "Hito" },
    { value: "createdDate", label: "Fecha de Asignación" },
    { value: "totalHours", label: "Horas Cargadas en el mes" },
    { value: "approvedHours", label: "Horas Aprobadas en el Mes" },
    { value: "rejectHours", label: "Horas Rechazadas en el Mes" },
    { value: "statusHours", label: "Estado de Aprobación de horas" },
    { value: "approvalDate", label: "Fecha de Aprobación de horas" },
    { value: "bill", label: "Numero de Factura" },
    { value: "billStatus", label: "Estado de Aprobación de Factura" },
    { value: "dateL1", label: "Fecha de Aprobación de Factura L1" },
    { value: "dateL2", label: "Fecha de Aprobación de Factura L2" },
    { value: "hes", label: "HES" },
    { value: "statusHes", label: "Estado de HES" },
    { value: "createdHes", label: "Fecha de Creacion HES" },
    { value: "dateL2", label: "Fecha de envio a Finanzas" },
  ];
  //#endregion
  //#endregion

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col sm="12">
              <label className="form-control-label">
                Reporte Seguimiento Mensual
              </label>
              <FiltersMain
                dropDownOptions={dropDownOptions}
                fields={fields}
                buttons={buttons}
                handleOnChangeFilter={handleOnChangeFilter}
                columnTitles={optionsSelect}
                tableParameter={"report"}
                search={infoExcel}
                Toast={Toast}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Reporting;
