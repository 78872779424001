import React, { useState, useEffect, useRef } from "react";
import { Col, Form, Row, Input, FormGroup } from "reactstrap";
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Tables from "components/UserCloudRequest/Tables";
import Modal from "components/UserCloudRequest/AdminUserCloudRequest/Modals.jsx";
// import Filters from "components/UserCloudRequest/Filters"
import { useDispatch } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  getAllCloudVendor,
  createCloudVendor,
  updateCloudVendor,
  deleteCloudVendor,
} from "actions/userCloudRequests.jsx";
import { Spin } from "antd";

const AdminProviderCloud = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [modalConfirm, setModalConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [openModal, setModal] = useState({
    createModal: false,
    editModal: false,
  });
  const [itemInfo, setItemInfo] = useState({});
  const [newInfo, setNewInfo] = useState({});
  const [infoToModule, setInfoToModule] = useState({
    rows: [],
    allProviders: [],
  });

  const notifyEl = useRef(null);

  const jsonFilters = [{ label: "Nombre proveedor" }];
  const colums = [{ label: "Nombre proveedor", value: "name", type: "text" }];

  const notify = (type, title, message) => {
    try {
      let options = {
        place: "br",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {title}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 7,
      };
      notifyEl.current.notificationAlert(options);
    } catch (error) {}
  };

  //Campos del modal generico para esta vista
  const modalInformation = [
    {
      label: "Nombre del proveedor",
      value: "name",
      type: "input",
      colWidth: "12",
      required: true,
    },
  ];

  const dispatch = useDispatch();

  const aditionalsButtons = [
    {
      color: "success",
      icon: "fas fa-pencil-alt",
      function: (e) => handleOnOpenModalEdit(e),
      tool: "Editar",
      id: "edit",
    },
    {
      color: "danger",
      icon: "fas fa-trash-alt",
      function: (e) => showModalConfirmationDelete(e),
      tool: "Eliminar",
      id: "delete",
    },
  ];
  //Funcion para los filtros por columna
  const handleOnfilterDinamic = (constant, e) => {
    setFilters((prevState) => ({
      ...prevState,
      [constant]: e,
    }));
  };

  //Aplicar los filtros a la data de la tabla
  const renderRowsByFilters = (rows) => {
    if (Object.keys(filters).length === 0) {
      return rows;
    } else {
      const filterKeys = Object.keys(filters);
      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();
        rows = rows.filter(
          (item) =>
            item[element].toString().toLowerCase().indexOf(valueFiltered) != -1
        );
      }
      return rows;
    }
  };

  //Funcion para hacer los cambios de informacion en los campos de input, select .etc
  const handleOnChangeInfo = (constant, value) => {
    setNewInfo((prevState) => ({
      ...prevState,
      [constant]: value,
    }));
  };
  //Funcion para cambiar la informacion que vamos a editar
  const handleOnChangeEditInfo = (constant, value) => {
    setItemInfo((prevState) => ({
      ...prevState,
      [constant]: value,
    }));
  };

  const closeModal = () => {
    setModalConfirm("");
  };

  //Funcion que muestra los modales de confirmacion
  const showModalConfirmation = () => {
    setModalConfirm(
      <ReactBSAlert
        custom
        showCancel
        title={"¿Seguro que desea crear el nuevo proveedor?"}
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        placeHolder="Write something"
        onConfirm={() => createNewProvider()}
        onCancel={() => closeModal()}
        confirmBtnText="Crear"
        cancelBtnText="Cancelar"
        btnSize="md"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        openAnim={{ name: "showSweetAlert", duration: 1500 }}
        closeAnim={{ name: "hideSweetAlert", duration: 1000 }}
      ></ReactBSAlert>
    );
  };

  //Funcion que muestra los modales de confirmacion
  const showModalConfirmationEdit = () => {
    setModalConfirm(
      <ReactBSAlert
        custom
        showCancel
        title={"¿Seguro que desea editar el proveedor?"}
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        placeHolder="Write something"
        onConfirm={() => editProvider()}
        onCancel={() => closeModal()}
        confirmBtnText="Editar"
        cancelBtnText="Cancelar"
        btnSize="md"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        openAnim={{ name: "showSweetAlert", duration: 1500 }}
        closeAnim={{ name: "hideSweetAlert", duration: 1000 }}
      ></ReactBSAlert>
    );
  };

  //Funcion que muestra los modales de confirmacion
  const showModalConfirmationDelete = (e) => {
    setModalConfirm(
      <ReactBSAlert
        custom
        showCancel
        title={"¿Seguro que desea eliminar el proveedor?"}
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        placeHolder="Write something"
        onConfirm={() => deleteProvider(e)}
        onCancel={() => closeModal()}
        confirmBtnText="Eliminar"
        cancelBtnText="Cancelar"
        btnSize="md"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        openAnim={{ name: "showSweetAlert", duration: 1500 }}
        closeAnim={{ name: "hideSweetAlert", duration: 1000 }}
      ></ReactBSAlert>
    );
  };

  //Funcion para abrir el modal de crear nueva registro en la tabla principal
  const handleOnOpenModalCreate = (constant) => {
    setModal((prevState) => ({
      ...prevState,
      createModal: !openModal.createModal,
    }));
    if (openModal.createModal) {
      setNewInfo({});
    }
  };

  //Funcion para abrir el modal de editar registro en la tabla principal
  const handleOnOpenModalEdit = (item) => {
    setModal((prevState) => ({
      ...prevState,
      editModal: !openModal.editModal,
    }));
    setItemInfo(item);
    if (openModal.editModal) {
      setItemInfo({});
      setNewInfo({});
    }
  };

  //Funcion que elimina un registro de la tabla principal
  const deleteProvider = (item) => {
    setLoading(true);
    dispatch(deleteCloudVendor(item.id)).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setModalConfirm("");
        setReload(!reload);
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    const getTenant = async () => {
      await handleOnGetTenant();
    };
    getTenant();
  }, [reload]);
  //Funcion que trae la data de los campos selects
  const handleOnGetTenant = async () => {
    setLoading(true);
    dispatch(getAllCloudVendor()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setInfoToModule((prevState) => ({
          ...prevState,
          rows: payload.data.data,
        }));
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
    setLoading(true);
  };

  //
  const createNewProvider = () => {
    setLoading(true);
    dispatch(createCloudVendor({ name: newInfo.name })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setNewInfo({});
        setModalConfirm("");
        setModal((prevState) => ({
          ...prevState,
          createModal: !openModal.createModal,
        }));
        notify("success", "Éxito", "Se creó correctamente");
        setReload(!reload);
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
  };

  //
  const editProvider = () => {
    setLoading(true);
    dispatch(updateCloudVendor({ id: itemInfo.id, name: itemInfo.name })).then(
      (resp) => {
        const { payload } = resp;
        if (payload.status === 200) {
          setItemInfo({});
          setModalConfirm("");
          setModal((prevState) => ({
            ...prevState,
            editModal: !openModal.editModal,
          }));
          notify("success", "Éxito", "Se actualizó correctamente");
          setReload(!reload);
        } else {
          setLoading(false);
        }
        setLoading(false);
      }
    );
  };

  const validateInfo = (item) => {
    return item.name === "";
  };

  return (
    <div>
      {modalConfirm}
      <AdminHeader name="Tenants" parentName="Administración cloud" />
      <Spin size="large" spinning={loading}>
        <Container className="mt--6" fluid>
          <Tables
            page={page}
            setPage={setPage}
            colums={colums}
            aditionalsButtons={aditionalsButtons}
            filters={handleOnfilterDinamic}
            rows={renderRowsByFilters(infoToModule.rows)}
            subtitle={"proveedores"}
            title={"Todos los proveedores"}
            thText={"Acciones"}
            createButton={true}
            handleOnNewElement={handleOnOpenModalCreate}
          />
          <Modal
            size={12}
            openModal={openModal.createModal}
            saveButtonDisabled={validateInfo(newInfo)}
            handleOnOpenModal={handleOnOpenModalCreate}
            itemModal={modalInformation}
            text={"Nuevo proveedor"}
            itemInfo={newInfo}
            handleOnChangeInfo={handleOnChangeInfo}
            saveButton={showModalConfirmation}
            nameButton1={"Cerrar"}
            nameButton2={"Guardar"}
          />
          <Modal
            size={12}
            openModal={openModal.editModal}
            saveButtonDisabled={validateInfo(itemInfo)}
            handleOnOpenModal={handleOnOpenModalEdit}
            itemModal={modalInformation}
            text={"Editar proveedor"}
            itemInfo={itemInfo}
            handleOnChangeInfo={handleOnChangeEditInfo}
            saveButton={showModalConfirmationEdit}
            nameButton1={"Cerrar"}
            nameButton2={"Guardar"}
          />
        </Container>
      </Spin>
    </div>
  );
};

export default AdminProviderCloud;
