import React from 'react';
import {
    Card,
    CardBody,
    Col,
    CardHeader,
    FormGroup,
    Input,
    Button,
    Row,
    CardFooter,
    Modal,
    Collapse
} from "reactstrap";
import Select from "react-select";

const ModalUserAccess = ({ data, openModal, handleOnViewModal, textModal, changeData, userInfo, saveButton }) => {

    return (
        <Modal size="m" isOpen={openModal}>
            <Card>
                <CardHeader>
                    <h6 className="surtitle">{textModal}</h6>
                </CardHeader>
                <CardBody>
                    <Row>
                        {
                            data.map((item, key) => (

                                <Col xs={item.colWidth} key={`col${key}`} >
                                    {
                                        item.type === "input" ?
                                            <FormGroup key={`fg${key}`} >
                                                <label
                                                    key={`lb${key}`}
                                                    style={{ fontSize: "12px" }}
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                >
                                                    {`${item.label}:`}
                                                </label>
                                                <Input
                                                    id={item.id}
                                                    key={`input${key}`}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={`Agregar ${item.label}...`}
                                                    onChange={(e) => changeData(item.label, e.target.value)}
                                                />
                                            </FormGroup>
                                            :
                                            item.type === "select" ?
                                                <FormGroup key={`fg${key}`} >
                                                    <label
                                                        key={`lb${key}`}
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${item.label}:`}
                                                    </label>
                                                    <Select
                                                        key={`sl${key}`}
                                                        className="basic-single is-invalid"
                                                        type="select"
                                                        isSearchable
                                                        defaultValue={userInfo && { label: userInfo[item.value], value: 1 }}
                                                        options={item.options}
                                                        onChange={(e) => changeData(item.insertValue, e.id)}
                                                    />
                                                </FormGroup>
                                                :
                                                <FormGroup key={`fg${key}`} >
                                                    <label
                                                        key={`lb${key}`}
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${item.label}:`}
                                                    </label>
                                                    <Select
                                                        key={`slm${key}`}
                                                        className="basic-single is-invalid"
                                                        type="select"
                                                        isSearchable
                                                        isMulti
                                                        options={item.options}
                                                        defaultValue={userInfo && userInfo[item.value]}
                                                        onChange={(e) => changeData(item.insertValue, e)}
                                                    />
                                                </FormGroup>
                                    }

                                </Col >


                            ))}

                    </Row>

                </CardBody>
                <CardFooter>
                    <Row className="justify-content-between">
                        <Col xs="12" md="4" className="p-1">
                            <Button
                                className="btn  btn-icon"
                                color="danger"
                                block
                                type="button"
                                onClick={() => handleOnViewModal()}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-times" />
                                </span>
                                <span className="btn-inner--text">Cancelar</span>
                            </Button>
                        </Col>
                        <Col xs="12" md="4" className="p-1">
                            <Button
                                className="btn  btn-icon"
                                color="success"
                                block
                                type="button"
                                onClick={() => saveButton()}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="far fa-save" />
                                </span>
                                <span className="btn-inner--text">Guardar</span>
                            </Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        </Modal >
    );
};

export default ModalUserAccess;