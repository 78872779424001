import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// react library
import React, { Component } from "react";

import _ from "lodash";
import moment from "moment";

import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Badge,
  Table,
} from "reactstrap";

import ReactDatetime from "react-datetime";

import { getCompleteRequests } from "actions/ASActions.jsx";

import { completeRequestsSelector } from "selectors/AS.jsx";

class CompleteSalaryRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeRequestsData: [],
      filter: { requester: "", date: undefined, identifier: null },
    };
  }

  componentWillMount() {
    this.props.getCompleteRequests().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
  }

  handleOnSelect = () => {};

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnCLick = () => {};

  renderTableData() {
    return this.props.executedTotalsData.total.map((row) => {
      const { key, doc_count } = row;
      return (
        <tr key={key}>
          <td>{key}</td>
          <td>{doc_count}</td>
        </tr>
      );
    });
  }

  handleSelectRequest = (item) => {
    this.props.history.push(`/admin/salary/details/${item}`);
  };

  handleFilters(value) {
    switch (value.target.id) {
      case "user":
        var user = this.state.filter;
        user.requester = value.target.value;
        this.setState({ user });
        break;
      case "identifier":
        var filter = this.state.filter;
        filter.identifier = value.target.value;
        this.setState({ filter });
        break;
      default:
        break;
    }
  }

  handleDateFilter(date) {
    var formatDate;
    if (moment(date).isValid()) {
      formatDate = this.state.filter;
      formatDate.date = moment(date).format("LL");
      this.setState({ formatDate });
    } else {
      formatDate = this.state.filter;
      formatDate.date = undefined;
      this.setState({ formatDate });
    }
  }

  render() {
    const { completeRequestsData } = this.props;
    let { filter } = this.state;
    let tableRows = [];
    let foundResults = [];
    var search;
    if (completeRequestsData) {
      if (filter.requester.length > 0 && filter.requester !== null) {
        if (filter.date) {
          search = new RegExp(filter.requester, "i"); // prepare a regex object
          let founds = completeRequestsData.filter((item) =>
            search.test(item.requester)
          );

          foundResults = _.filter(founds, {
            formatedCreatedAt: filter.date,
          });
        } else {
          search = new RegExp(filter.requester, "i"); // prepare a regex object
          let founds = completeRequestsData.filter((item) =>
            search.test(item.requester)
          );
          foundResults = founds;
        }
      } else if (filter.date) {
        foundResults = _.filter(completeRequestsData, {
          formatedCreatedAt: filter.date,
        });
      } else if (filter.identifier) {
        foundResults = _.filter(completeRequestsData, {
          id: parseInt(filter.identifier),
        });
      } else {
        foundResults = completeRequestsData;
      }
      foundResults.forEach((request) => {
        tableRows.push(
          <tr key={request.id}>
            <td>{request.id}</td>
            <td>{request.requester}</td>
            <td>{request.formatedCreatedAt}</td>
            <td>{request.formatedupdatedAt}</td>
            <td>
              {request.formateStatus === "Aprobado" ? (
                <Badge color="success" pill>
                  Aprobado
                </Badge>
              ) : (
                <Badge color="danger" pill>
                  Rechazado
                </Badge>
              )}
            </td>
            <td className="text-right">
              <Button onClick={() => this.handleSelectRequest(request.id)}>
                {" "}
                Ver Detalles
              </Button>
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name="Solicitudes Completadas"
          parentName="Sistema de Aprobación Salarial"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-12">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h5 className="h3 mb-0">Filtros</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="user">
                      Solicitante
                    </label>
                    <Input
                      id="user"
                      placeholder="Nombre de Usuario"
                      type="text"
                      defaultValue={filter.requester}
                      onChange={(item) => this.handleFilters(item)}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="date">
                      Fecha de Solicitud
                    </label>
                    <ReactDatetime
                      id="date"
                      inputProps={{
                        placeholder: "Seleccione una fecha",
                      }}
                      timeFormat={false}
                      defaultValue={filter.date}
                      onChange={(item) => this.handleDateFilter(item)}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="date">
                      Identificador de solicitud
                    </label>
                    <Input
                      id="identifier"
                      placeholder="Ingrese un valor"
                      type="number"
                      defaultValue={filter.identifier}
                      onChange={(item) => this.handleFilters(item)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">Solicitudes de Aprobación</h3>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>ID Sol.</th>
                      <th>Solicitante</th>
                      <th>Fecha</th>
                      <th>Ultima Actualización</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {tableRows.length > 0 ? (
                      tableRows
                    ) : (
                      <tr>
                        <td className="justify-content-md-center">
                          Ninguna Solicitud Disponible
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

CompleteSalaryRequest.defaultProps = {
  completeRequestsData: [],
};

const mapStateToProps = (state) => ({
  completeRequestsData: completeRequestsSelector(state),
});

export default withRouter(
  connect(mapStateToProps, { getCompleteRequests })(CompleteSalaryRequest)
);
