// antd components
import { Spin } from 'antd';
// core actions
import { findUserWithAccessRecords, findOffices, createUserAccessRecords, deleteUserAccess } from "actions/medicalRecords.jsx";
// core components Shared
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// core components MedicalRecord
import ModalActionAccess from 'components/MedicalRecords/ModalActionAccess.jsx';
import UserMaintenance from 'components/MedicalRecords/UserMaintenance.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getAllUsersAccessRecords, getAllOffices } from "selectors/medicalRecords.jsx";

class Maintenance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      loadingModal: false,
      message: null,
      modalActions: {
        showModal: false,
        row: {},
        type: null,
        options: {
          title: null,
          btnTxt: null,
        }
      }
    };
  };

  componentWillMount() {
    this.props.findUserWithAccessRecords().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          willMount: false,
        });
        this.props.findOffices();
      }
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnToggleModalAction = () => {
    this.setState({
      modalActions: {
        showModal: false,
        row: {},
        type: null,
        options: {
          title: null,
          btnTxt: null,
        }
      }
    });
  };

  handleOnAddUserAccess = () => {
    this.setState({
      modalActions: {
        showModal: true,
        row: {},
        type: 'add',
        options: {
          title: 'Agregar Acceso a Usuario',
          btnTxt: 'Agregar Acceso',
        }
      }
    });
  };

  handleOnSubmitModalActionAccess = (type, values) => {
    if (type === 'add') {
      this.setState({
        loadingModal: true,
        message: 'Creando el acceso al usuario',
      });
      this.props.createUserAccessRecords(values).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findUserWithAccessRecords();
          this.handleOnToggleModalAction();
        }
        this.handleOnToggleModalAction();
        this.setState({
          loadingModal: false,
          message: null,
        });
      });
    }
  };

  onDeleteUserAccess = id => {
    this.setState({
      alert: null,
      loading: true,
      message: 'Eliminando el acceso al usuario',
    });
    this.props.deleteUserAccess(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.props.findUserWithAccessRecords();
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnDeleteUserAccess = row => {
    const { id } = row;
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar el acceso al usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteUserAccess(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      )
    });
  };

  render() {

    const {
      name,
      parentName,
      cols,
      users,
      offices,
    } = this.props;

    const {
      alert,
      loading,
      message,
      willMount,
      loadingModal,
      modalActions,
    } = this.state;

    return (
      <>
        {alert}
        <ModalActionAccess
          options={modalActions}
          loading={loadingModal}
          message={message}
          locations={offices.map((row) => {
            const json = {};
            json.location = row.name;
            json.name = row.name;
            return json;
          })}
          toggleModal={this.handleOnToggleModalAction}
          onSubmit={this.handleOnSubmitModalActionAccess}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          {
            !willMount ?
              <Spin size="large" spinning={loading} tip={message}>
                <UserMaintenance
                  title="Administración de Accesos"
                  subtitle="Gestión de accesos para los usuarios de expediente médico"
                  cols={cols}
                  rows={users}
                  addUserAccess={this.handleOnAddUserAccess}
                  onDelete={this.handleOnDeleteUserAccess}
                />
              </Spin>
              :
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando los accesos de los expedientes médicos" />
                </Col>
              </Row>
          }
        </Container>
      </>
    );
  }
}


Maintenance.defaultProps = {
  name: "Mantenimiento Usuarios",
  parentName: "Expediente Médico",
  cols: [
    { key: "user", name: "Nombre de Usuario" },
    { key: "name", name: "Colaborador" },
    { key: "position", name: "Posición" },
    { key: "location", name: "Localidad" },
  ],
  users: [],
  offices: [],
};

Maintenance.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  offices: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  users: getAllUsersAccessRecords(state),
  offices: getAllOffices(state),
});

export default withRouter(connect(mapStateToProps, {
  findUserWithAccessRecords,
  findOffices,
  createUserAccessRecords,
  deleteUserAccess
})(Maintenance));