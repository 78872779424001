// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Col,
  FormGroup,
  Input,
  Row,
  Form,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// antd components
import { Spin } from "antd";

import classnames from "classnames";

import Select from "react-select";

import React, { useState, useEffect } from "react";

import MasiveInfo from "./MasiveInfo";
import ForLineInfo from "./ForLineInfo";

//Importacion del Json General
import formFields from "../SharedComponents/GeneralStates";

const InfoGestion = ({
   typeForm ,
   method,
   setMethod ,globalInfo, setGlobalInfo, setFileListRequest, fileListRequest, temporalRequestId,stateModalsIndvForms,setStateModalsIndvForms,generalDataValues,setGeneralDataValues, stillChargingDocuments, 
  setStillChargingDocuments, formFieldsDb }) => {
  //#region estado para el boolean del spinner
  const [loading, setLoad] = useState(false);
  //#endregion

  //state para determinar en que tab estoy
  const [activeTab, setActiveTab] = useState("0");

  //funcion que cambia el tab
  const handleTab = (event, tab) => {
    setGlobalInfo((prev) => ({
      ...prev,
      "method": tab,
    }))
    if (activeTab !== tab) setActiveTab(tab);
  };


  return (
    <div>
      <Card style={{ backgroundColor: "#E8E8E8" }}>
        <Row className="mb-2 mt-4">
          <Col xs="12" sm="12">
            <h3 class="text-center">
              Seleccione el método de ingreso de la información
            </h3>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm="12">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              {formFields[typeForm].metodo === "linea" ? (
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "1"}
                    className={classnames("ml-2 mb-sm-3 mb-md-0 btn-success", {
                      active: activeTab === "1",
                    })}
                    onClick={(e) => handleTab(e, "1")}
                    role="tab"
                  >
                    <i className="ni ni-ungroup mr-2" />
                    Por Líneas 
                  </NavLink>
                </NavItem>
              ) : formFields[typeForm].metodo === "masivo" ? (
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "2"}
                    className={classnames("mr-2 mb-sm-3 mb-md-0 btn-warning", {
                      active: activeTab === "2",
                    })}
                    onClick={(e) => handleTab(e, "2")}
                    role="tab"
                  >
                    <i className="ni ni-building mr-2" />
                    Masivo
                  </NavLink>
                </NavItem>
              ) : (
                <>
                  <NavItem>
                    <NavLink
                      aria-selected={activeTab === "1"}
                      className={classnames(
                        "ml-2 mb-sm-3 mb-md-0 btn-success",
                        {
                          active: activeTab === "1",
                        }
                      )}
                      onClick={(e) => handleTab(e, "1")}
                      role="tab"
                    >
                      <i className="ni ni-ungroup mr-2" />
                      Por Líneas
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      aria-selected={activeTab === "2"}
                      className={classnames(
                        "mr-2 mb-sm-3 mb-md-0 btn-warning",
                        {
                          active: activeTab === "2",
                        }
                      )}
                      onClick={(e) => handleTab(e, "2")}
                      role="tab"
                    >
                      <i className="ni ni-building mr-2" />
                      Masivo
                    </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>
          </Col>
        </Row>
        <CardBody>
          <TabContent activeTab={activeTab}>
            {/* Gestion por Linea */}
            <TabPane tabId="1">
              <ForLineInfo
              metodo={formFields[typeForm].metodo}
              stateModalsIndvForms={stateModalsIndvForms}
              setStateModalsIndvForms={setStateModalsIndvForms}
              form={typeForm}
              setGeneralDataValues={setGeneralDataValues}
              generalDataValues={generalDataValues}
              ></ForLineInfo>
            </TabPane>
            {/* Gestion Masiva */}
            <TabPane tabId="2">
              <MasiveInfo typeForm={typeForm}
                metodo={formFields[typeForm].metodo}
                setFileListRequest={setFileListRequest}
                fileListRequest={fileListRequest}
                temporalRequestId={temporalRequestId}
                stillChargingDocuments={stillChargingDocuments}
                setStillChargingDocuments={setStillChargingDocuments}
                formFieldsDb={formFieldsDb}
              ></MasiveInfo>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
};

export default InfoGestion;
