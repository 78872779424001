import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Table from "components/Shared/Table/Table.jsx";
import Form from "components/MIS/FormMISProjects.jsx";
import moment from "moment";
import ProjectsWidget from "components/MIS/WidgetProjects.jsx";
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  findProjects,
  findCountProjects,
  updateProjectsInfo,
  insertProjects
} from "actions/mis.jsx";
import { getProjects, getCountProjects } from "selectors/mis.jsx";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// reactstrap components
import { Button, Container, Progress } from "reactstrap";
// core selctors
import { getTeamsUserAccess } from "selectors/adminLayout";

/**
 * Clase para visualizar los componentes que
 * se utilizaran en el modulo de Planning MRS
 * @class Nodes
 * @extends {Component}
 */
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddForm: false,
      dep: "AM",
      name: "",
      description: "",
      client: "",
      progress: 0,
      releaseDate: moment().format("YYYY-MM-DD"),
      initDate: moment().format("YYYY-MM-DD"),
      comment: "",
      visibility: 1,
      colors: ["#c9f5d0", "#ffffff", "#ffffff", "#ffffff"],
      depName: "Application Management",
      showButtonsEdit: props.teams.find(row => row === "General MIS AM"),
      objectValidation: {}
    };
  }

  componentWillMount() {
    this.setState({
      loading2: true
    });
    const data = {
      dep: "AM"
    };
    this.props.findCountProjects().then(async res => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload }
          } = res.payload.response;
          await this.notify("danger", "Atención", payload.message);
        } else {
          await this.notify(
            "danger",
            "Atención",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
    this.props.findProjects(data).then(async res => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload }
          } = res.payload.response;
          await this.notify("danger", "Atención", payload.message);
        } else {
          await this.notify(
            "danger",
            "Atención",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload }
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading2: false
      });
    });
  }

  progressColor(prog) {
    if (prog > 0 && prog < 31) {
      return "danger";
    } else if (prog > 31 && prog < 61) {
      return "yellow";
    } else {
      return "success";
    }
  }

  publishProjectsData = data => {
    var allOk = true;
    var objectFail = {};
    var key;
    for (key in this.state) {
      if (this.state[key] === "" && key !== "comment") {
        objectFail[key] = true;
      } else {
        objectFail[key] = false;
      }
    }
    this.setState({
      objectValidation: objectFail
    });
    for (key in objectFail) {
      if (objectFail[key] === true) {
        allOk = false;
      }
    }
    if (allOk) {
      data.releaseDate = moment(data.releaseDate)
        .utc()
        .utcOffset("-05:00")
        .format("YYYY-MM-DD");
      data.initDate = moment(data.initDate)
        .utc()
        .utcOffset("-05:00")
        .format("YYYY-MM-DD");
      this.setState({
        loading2: true,
        showAddForm: false,
        alert: null
      });

      if (this.state.agreggate === true) {
        this.sleep(500).then(() => {
          this.props.insertProjects(data).then(async res => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload }
                } = res.payload.response;
                await this.notify("danger", "Atención", payload.message);
              } else {
                await this.notify(
                  "danger",
                  "Atención",
                  "No se logro establecer conexion con el servidor."
                );
              }
            }
            this.props.findCountProjects().then(async res => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload }
                  } = res.payload.response;
                  await this.notify("danger", "Atención", payload.message);
                } else {
                  await this.notify(
                    "danger",
                    "Atención",
                    "No se logro establecer conexion con el servidor."
                  );
                }
              }
            });
            this.setState({
              loading2: false,
              showAddForm: false
            });
          });
        });
      } else {
        this.sleep(500).then(() => {
          this.props.updateProjectsInfo(data).then(async res => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload }
                } = res.payload.response;
                await this.notify("danger", "Atención", payload.message);
              } else {
                await this.notify(
                  "danger",
                  "Atención",
                  "No se logro establecer conexion con el servidor."
                );
              }
            }
            this.props.findCountProjects().then(async res => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload }
                  } = res.payload.response;
                  await this.notify("danger", "Atención", payload.message);
                } else {
                  await this.notify(
                    "danger",
                    "Atención",
                    "No se logro establecer conexion con el servidor."
                  );
                }
              }
            });
            this.setState({
              loading2: false,
              showAddForm: false
            });
          });
        });
      }
    }
  };

  onChangeValuesProjects = value => {
    if (value.target.id === "releaseDate" || value.target.id === "initDate") {
      this.setState({ [value.target.id]: value.target.value }, () => {
        if (
          new Date(this.state.initDate + "") >
          new Date(this.state.releaseDate + "")
        ) {
          this.setState({
            initDate: moment(value.target.value).format("YYYY-MM-DD"),
            releaseDate: moment(value.target.value).format("YYYY-MM-DD")
          });
        }
      });
    } else {
      this.setState({ [value.target.id]: value.target.value });
    }
  };

  editRow(row, visibility) {
    this.setState(
      {
        agreggate: false,
        id: row.id,
        showAddForm: true,
        dep: row.dep,
        name: row.name,
        description: row.description,
        client: row.client,
        progress: row.progress,
        releaseDate: moment(row.releaseDate)
          .utc()
          .utcOffset("-05:00"),
        initDate: moment(row.initDate)
          .utc()
          .utcOffset("-05:00"),
        comment: row.comment,
        visibility: visibility
      },
      () => {
        if (visibility === 1) {
          this.setState({
            showAddForm: true
          });
        } else {
          this.setState({
            showAddForm: false,
            alert: (
              <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Está seguro que quiere eliminar el proyecto?"
                customIcon={
                  <div
                    className="swal2-icon swal2-question swal2-animate-question-icon"
                    style={{ display: "flex" }}
                  >
                    <span className="swal2-icon-text">?</span>
                  </div>
                }
                onConfirm={() => this.publishProjectsData(this.state)}
                onCancel={() => this.setState({ alert: null })}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No, cancelar"
                btnSize="md"
              />
            )
          });
        }
      }
    );
  }

  cancelProjectsForm = () => {
    this.setState({
      showAddForm: false
    });
  };

  addButtonFunction = () => {
    this.setState({
      agreggate: true,
      showAddForm: true,
      name: "",
      description: "",
      client: "",
      progress: 0,
      releaseDate: moment().format("YYYY-MM-DD"),
      initDate: moment().format("YYYY-MM-DD"),
      comment: "",
      visibility: 1
    });
  };
  sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  selectData = optionSelected => {
    const { teams } = this.props;
    if (optionSelected === "AM") {
      const flag = teams.find(row => row === "General MIS AM");
      this.setState({
        colors: ["#c9f5d0", "#ffffff", "#ffffff", "#ffffff"],
        depName: "Application Management",
        showButtonsEdit: flag
      });
    }
    if (optionSelected === "BI") {
      const flag = teams.find(row => row === "General MIS BI");
      this.setState({
        colors: ["#ffffff", "#f5c9c9", "#ffffff", "#ffffff"],
        depName: "Business Intelligence",
        showButtonsEdit: flag
      });
    }
    if (optionSelected === "DM") {
      const flag = teams.find(row => row === "General MIS DM");
      this.setState({
        colors: ["#ffffff", "#ffffff", "#f5f4c9", "#ffffff"],
        depName: "Data Management & Automation",
        showButtonsEdit: flag
      });
    }
    if (optionSelected === "IF") {
      const flag = teams.find(row => row === "General MIS IF");
      this.setState({
        colors: ["#ffffff", "#ffffff", "#ffffff", "#d6d4fa"],
        depName: "Infraestructura",
        showButtonsEdit: flag
      });
    }
    this.setState({
      loading2: true,
      dep: optionSelected
    });
    this.sleep(500).then(() => {
      const data = {
        dep: optionSelected
      };
      this.props.findProjects(data).then(async res => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload }
            } = res.payload.response;
            await this.notify("danger", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Atención",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState({
          loading2: false
        });
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    const { showAddForm, loading2, showButtonsEdit, alert } = this.state;
    const { projects, countProjects } = this.props;
    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>

        <AdminHeader name="Información MIS" parentName="Proyectos" />

        <Container className="mt--6" fluid>
          {showAddForm === false ? (
            <div>
              <ProjectsWidget
                selectData={this.selectData}
                colors={this.state.colors}
                count={countProjects}
              ></ProjectsWidget>
              {loading2 ? (
                <Progress animated max="100" value="100" color="info" />
              ) : (
                <Table
                  title={"Listado de proyectos de " + this.state.depName}
                  subtitle="Implementaciones finalizadas, en proceso y pendientes."
                  columns={[
                    {
                      dataField: "id",
                      text: "Id",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "name",
                      text: "Proyecto",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="d-flex align-items-center"
                          >
                            <span className="completion mr-2">{row.name}</span>
                          </div>
                        );
                      }
                    },
                    {
                      dataField: "description",
                      text: "Descripción",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="d-flex align-items-center"
                          >
                            <span className="completion mr-2">
                              {row.description}
                            </span>
                          </div>
                        );
                      }
                    },

                    {
                      dataField: "client",
                      text: "Cliente",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="d-flex align-items-center"
                          >
                            <span className="completion mr-2">
                              {row.client}
                            </span>
                          </div>
                        );
                      }
                    },
                    {
                      dataField: "progress",
                      text: "Estado del proyecto",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="d-flex align-items-center"
                          >
                            <span className="completion mr-2">
                              {row.progress}%
                            </span>
                            <div>
                              <Progress
                                max="100"
                                value={row.progress}
                                color={this.progressColor(row.progress)}
                              />
                            </div>
                          </div>
                        );
                      }
                    },
                    {
                      dataField: "initDate",
                      text: "Inicio",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="d-flex align-items-center"
                          >
                            <span className="completion mr-2">
                              {moment(row.initDate)
                                .utc()
                                .utcOffset("-05:00")
                                .format("DD/MM/YYYY")}
                            </span>
                          </div>
                        );
                      }
                    },
                    {
                      dataField: "releaseDate",
                      text: "Lanzamiento",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="d-flex align-items-center"
                          >
                            <span className="completion mr-2">
                              {moment(row.releaseDate)
                                .utc()
                                .utcOffset("-05:00")
                                .format("DD/MM/YYYY")}
                            </span>
                          </div>
                        );
                      }
                    },
                    {
                      dataField: "actions",
                      isDummyField: true,
                      text: "ADMIN",
                      hidden: !showButtonsEdit,
                      formatter: (cellContent, row) => {
                        return (
                          <div>
                            <Button
                              onClick={() => this.editRow(row, 0)}
                              className="btn-icon btn-2"
                              color="danger"
                              type="button"
                              size="sm"
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-times"></i>
                              </span>
                            </Button>
                            <Button
                              onClick={() => this.editRow(row, 1)}
                              className="btn-icon btn-2"
                              color="info"
                              type="button"
                              size="sm"
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-edit"></i>
                              </span>
                            </Button>
                          </div>
                        );
                      }
                    }
                  ]}
                  data={projects}
                  sizePerPage={5}
                  page={1}
                  totalSize={projects.length}
                  showSearch={true}
                  showAddButton={showButtonsEdit}
                  buttonAddFunct={this.addButtonFunction}
                  buttonBack={() => this.props.history.goBack()}
                />
              )}
            </div>
          ) : (
            <Form
              cancel={this.cancelProjectsForm}
              savedData={this.publishProjectsData}
              infoProjects={this.state}
              onChangeForm={this.onChangeValuesProjects}
              objectFailValidation={this.state.objectValidation}
            ></Form>
          )}
        </Container>
      </>
    );
  }
}

Projects.defaultProps = {
  projects: [],
  countProjects: {},
  teams: []
};

const mapStateToProps = state => ({
  projects: getProjects(state),
  countProjects: getCountProjects(state),
  teams: getTeamsUserAccess()
});

export default withRouter(
  connect(
    mapStateToProps,
    { findProjects, findCountProjects, insertProjects, updateProjectsInfo }
  )(Projects)
);
