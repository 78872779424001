import React, { useState } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";


const CompetitionEditFormThree = ({ dataModal }) => {
    const [rowsModal, setRowsModal] = useState([
        {
            colWidth: "12",
            label: "Observaciones",
            id: "comment",
        }
    ]);
    return (
        <div>
            <Card>
                <CardBody>
                    <Row>
                        {rowsModal.map((row, key) =>
                            <Col xs={row.colWidth} key={key}>
                                <FormGroup key={key}>
                                    <label
                                        style={{ fontSize: "12px" }}
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >
                                        {`${row.label}:`|| ""}
                                    </label>
                                    <Input
                                        id={row.id}
                                        key={key}
                                        className="form-control"
                                        disabled={true}
                                        value={dataModal[row.id]|| ""}
                                    />
                                </FormGroup>
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};


export default CompetitionEditFormThree;