// core components
import {
  findDateExtras,
  findExtras,
  findExtrasToSend,
  findHoursByUser,
  findUserByYear,
  findYearsExtras,
  updateExtraInfo,
  updateExtrasSAP,
  userRolesExtra,
  updateStatus,
  getReasonForChangeSap,
} from "actions/extraHours.jsx";

import { getTeamsUserAccess } from "selectors/adminLayout.jsx";

import { Spin } from "antd";
import Chart from "components/ExtraHours/ExtraHoursChart";
import DateRanges from "components/Shared/DateRanges/DateRanges.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Table from "components/Shared/Table/Table.jsx";
import moment from "moment";
// react library
import React, { Component, useEffect } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  Progress,
  Row,
  Badge,
} from "reactstrap";
import {
  getDateExtras,
  getExtras,
  getExtrasToSend,
  getHoursByUser,
  getUpdateExtrasSAP,
  getUserRolesExtra,
  getUsersByYear,
  getYearsExtras,
} from "selectors/extraHours.jsx";
import { dataExtraHours } from "variables/general.jsx";

// react plugin for creating notifications over the dashboard
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

/**
 * Clase para visualizar los componentes que
 * se utilizaran en el modulo de Planning MRS
 * @class PlanningMrs
 * @extends {Component}
 */
class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      page: 1,
      sizePerPage: 15,
      loading2: false,
      show: null,
      tableDate: false,
      tableFilter: false,
      tableFilterInfo: [],
      toSend: false,
      selected: [],
      searchingDateFlag: false,
      waitSendExtras: false,
      showResult: false,
      showButtonToSend: true,
      extrasGlobal: [],
      extrasToSendGlobal: [],
      statusSelected: "Seleccione un estado",
      countrySelected: "Seleccione un pais",
      showTable: true,
      showGraph: false,
      rol: "userName",
      userList: [],
      selectedUser: "Seleccione rol",
      clickedRol: false,
      yearsOptions: [],
      yearSelected: 2020,
      startDate: moment().subtract(1, "months").startOf("month"),
      endDate: moment().endOf("month"),
      sapAdmin: false,
      showLogSapStatus: false,
      logSapStatus: [],
    };
  }

  componentWillMount() {
    const { startDate, endDate } = this.state;
    let sapAdmin = getTeamsUserAccess().some(
      (role) => role == "Extra Hours SAP Administrator"
    );
    this.setState({
      loading2: true,
      sapAdmin: sapAdmin,
    });
    this.props.userRolesExtra().then(async (res) => {
      // this takes the hours for widget
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("warning", "Atención", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
          this.setState({
            loading2: false,
          });
        }
      } else {
        var teams = this.props.rolesExtra;
        var regional = false;
        var managers = false;
        for (var i = 0; i < teams.length; i++) {
          if (teams[i] === "Extra Hours") {
            regional = true;
          }
          if (teams[i] === "Extra Hours Managers") {
            regional = true;
          }
        }
        if (regional || managers) {
          this.setState({ showButtonToSend: false });
        }

        this.props.findExtras({ startDate, endDate }).then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              await this.notify("warning", "Atención", payload.message);
            } else {
              await this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          }
          this.setState({ extrasGlobal: this.props.extras });
          if (this.state.extrasGlobal.length > 0) {
            this.props.findYearsExtras().then(async (res) => {
              // this takes the hours for widget
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  await this.notify("warning", "Atención", payload.message);
                } else {
                  await this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
              }
              let yearsOptionsTemp = [];
              this.props.yearsExtras.forEach((e) =>
                yearsOptionsTemp.push({ value: e.years, label: e.years })
              );
              var currentYear =
                yearsOptionsTemp[yearsOptionsTemp.length - 1].value;
              this.setState({
                yearsOptions: yearsOptionsTemp,
                yearSelected: currentYear,
              });
              const data = {
                year: currentYear,
                type: "userName",
              };
              this.props.findUserByYear(data).then(async (res) => {
                // this takes the hours for widget
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    await this.notify("warning", "Atención", payload.message);
                  } else {
                    await this.notify(
                      "danger",
                      "Falló",
                      "No se logro establecer conexion con el servidor."
                    );
                  }
                }
                this.setState({
                  loading2: false,
                });
              });
            });
          } else {
            this.setState({
              loading2: false,
            });
          }
        });
      }
    });
  }

  /**
   * Funcion para cuando se selecciona una fecha ya sea de inicio o de fin, la agrega a la variable
   * respectiva en el estado
   * @memberof PlanningMrs
   */
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
    }
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id],
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      let allSelected = [];
      rows.forEach(function (row) {
        allSelected.push(row.id);
      });
      this.setState(() => ({
        selected: allSelected,
      }));
    } else {
      this.setState(() => ({
        selected: [],
      }));
    }
  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  /**
   * Funcion que se realiza cuando se presiona el boton de buscar los recursos de los nodos seleccionados
   *
   * @memberof PlanningMrs
   */
  handleOnCLick = () => {
    const { startDate, endDate } = this.state;
    if (
      startDate === undefined ||
      endDate === null ||
      startDate === undefined ||
      endDate === null
    ) {
      this.setState({
        alert: (
          <ReactBSAlert
            title="Por favor selecciona un rango de fechas."
            type="warning"
            onConfirm={() => this.setState({ alert: null })}
            onCancel={() => this.setState({ alert: null })}
            confirmBtnBsStyle="success"
            btnSize=""
          />
        ),
      });
    } else {
      this.setState({
        loading: true,
        loading2: true,
        tableDate: true,
        toSend: false,
        searchingDateFlag: true,
        tableFilter: false,
        statusSelected: "Seleccione un estado",
        countrySelected: "Seleccione un pais",
      });
      const data = {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      };
      this.props.findDateExtras(data).then(async (res) => {
        if (res.payload.isAxiosError) {
          this.setState({
            loading: false,
            loading2: false,
          });
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            this.setState({
              loading: false,
              loading2: false,
            });
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.setState({ extrasGlobal: this.props.dateExtras });
          this.setState({
            loading: false,
            loading2: false,
          });
        }
      });
    }
  };

  buttonReturnList = () => {
    const { startDate, endDate } = this.state;
    this.setState({
      toSend: false,
      showResult: false,
    });
    this.setState({
      loading2: true,
    });
    this.clearSearch();
    this.props.findExtras({ startDate, endDate }).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({ extrasGlobal: this.props.extras });
      this.setState({
        loading2: false,
      });
    });
  };

  closeLogsModal = () => {
    this.setState({
      showLogSapStatus: false,
      logSapStatus: [],
    });
  };

  clearSearchFilters = () => {
    this.setState({
      tableFilter: false,
      toSend: false,
      searchingDateFlag: this.state.searchingDateFlag,
      statusSelected: "Seleccione un estado",
      countrySelected: "Seleccione un pais",
    });
  };

  clearSearch = () => {
    this.setState({
      tableDate: false,
      tableFilter: false,
      toSend: false,
      // startDate: null,
      // endDate: null,
      extrasGlobal: this.props.extras,
      searchingDateFlag: false,
      statusSelected: "Seleccione un estado",
      countrySelected: "Seleccione un pais",
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnViewLogSapStatus = (row) => {
    this.setState({
      showLogSapStatus: true,
    });

    //#region Actualiza el status de SAP.
    this.props
      .getReasonForChangeSap({ idRequest: row.id })
      .then(async (res) => {
        // this takes the hours for widget
        if (res.payload.isAxiosError) {
          //Error

          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          //success
          let logs = res.payload.data.getReasonForChangeSap;
          this.setState({
            showLogSapStatus: true,
            logSapStatus: logs,
          });
        }
      });
    //#endregion
  };

  expandRow = {
    clickToExpand: true,
    parentClassName: "expand-parent",
    renderer: (row) => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Hora de inicio</span> <br />
              {moment(row.time, "HH:mm:ss").format("HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Fecha de creacion de solicitud/reporte
              </span>{" "}
              <br />
              {moment(row.createdAt)
                .utc()
                .utcOffset(moment().utcOffset())
                .format("DD/MM/YYYY | HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Preaprobador</span> <br />
              {row.preApproverName === "N/A"
                ? "No asignado / No requerido"
                : row.preApproverName}
            </div>
          </div>
        </span>
        <br />

        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Hora final</span> <br />
              {moment(row.endTime, "HH:mm:ss").format("HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Fecha y hora de aprobación supervisor
              </span>{" "}
              <br />
              {row.updatedStatus === null
                ? "N/A"
                : moment(row.updatedStatus)
                    .utc()
                    .utcOffset(moment().utcOffset())
                    .format("DD/MM/YYYY | HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Justificacion</span> <br />
              {row.justification}
            </div>
          </div>
        </span>
        <br />
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Aumento de tiempo</span> <br />
              {row.endTime === row.newEndTime ? "SI" : "NO"}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Fecha de aprobación preaprobador
              </span>{" "}
              <br />
              {row.updatedPreapproverStatus === null
                ? "N/A"
                : moment(row.updatedPreapproverStatus)
                    .utc()
                    .utcOffset(moment().utcOffset())
                    .format("DD/MM/YYYY | HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Orden/Item relacionado</span>{" "}
              <br />
              {row.info}
            </div>
          </div>
        </span>
        <br />
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tipo</span> <br />
              Reporte de horas
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Actualización de estado en SAP
              </span>{" "}
              <br />
              {row.updatedSapStatus === null
                ? "N/A"
                : moment(row.updatedSapStatus)
                    .utc()
                    .utcOffset(moment().utcOffset())
                    .format("DD/MM/YYYY | HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Día anterior marcado</span>{" "}
              <br />
              {row.dayBefore === 0 ? "No" : "Sí"}
            </div>
          </div>
        </span>
        <br />
        <span>
          <div className="row">
            <div className="col">
              {row.status === "Aprobada" && row.ceoReason !== "N/A" ? (
                <span style={{ color: "#0D45A4" }}>
                  Motivo de rechazo aumento de tiempo
                </span>
              ) : (
                <span style={{ color: "#0D45A4" }}>Motivo de rechazo</span>
              )}

              <br />
              {row.ceoReason}
              <br />
              <br />
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Descripción del trabajo</span>
              <br />
              {row.jobDescription}
              <br />
              <br />
            </div>

            <div className="col">
              <span style={{ color: "#0D45A4" }}>Motivo aumento de tiempo</span>
              <br />
              {row.userReason}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Codigo Feriado</span>
              <br />
              {row.holidayClass === null
                ? `Sin informacion previa`
                : row.holidayClass}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tipo de Pago (Day Type)</span>
              <br />
              {row.holidayClassName === null
                ? `Sin informacion previa`
                : row.holidayClassName}
            </div>

            <div className="col">
              <span style={{ color: "#0D45A4" }}>Horario</span>
              <br />
              {row.schedule === null ? `Sin informacion previa` : row.schedule}
            </div>
          </div>
          <div className="row mt-3">
            <div
              className="col-sm-4"
              style={{ whiteSpace: "normal", textAlign: "justify" }}
            >
              <span style={{ color: "#0D45A4" }}>Work Schedule Rule</span>
              <br />
              {row.workSchedule === null
                ? `Sin informacion previa`
                : row.workSchedule}
            </div>

            {row.hadChangesSapStatus > 0 && this.state.sapAdmin && (
              <div
                className="col-sm-4"
                style={{ whiteSpace: "normal", textAlign: "justify" }}
              >
                <span style={{ color: "#0D45A4" }}>
                  Log de cambio de estado de SAP
                </span>{" "}
                <br />
                <Button
                  onClick={() => this.handleOnViewLogSapStatus(row)}
                  className="btn-icon btn-2"
                  color="default"
                  type="button"
                  size="sm"
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-chart-line"></i>
                  </span>
                </Button>
              </div>
            )}
          </div>
        </span>
        <br />
      </div>
    ),
  };

  rejectExtraConfirmed = (row, input) => {
    let date = moment().format("DD/MM/YYYY hh:mm A");
    let data = { id: row.id, reason: input, extraReject: row, date: date };

    this.props.updateExtraInfo(data).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("warning", "Atención", payload.message);
        } else {
          await this.notify(
            "danger",
            "Atención",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({
        loading2: false,
        showAddForm: false,
        alert: null,
      });
      this.filterToSend();
      this.setState({
        showAddForm: false,
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="La hora extra se ha rechazado, se le notificará a los interesados respectivos (solicitante y aprobadores) mediante un correo."
            onConfirm={() => this.setState({ alert: null })}
            confirmBtnBsStyle="primary"
            confirmBtnText="Cerrar"
            btnSize="md"
          />
        ),
      });
    });
  };

  rejectExtraConfirmation = (row) => {
    this.setState({
      showAddForm: false,
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que quiere rechazar la extra seleccionada?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.rejectExtraConfirmed(row, input)}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, rechazar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese el motivo de rechazo.
        </ReactBSAlert>
      ),
    });
  };

  sendToSAPConfirmed = (data) => {
    this.setState({ alert: null });
    this.setState({ waitSendExtras: true });
    this.props.updateExtrasSAP(data).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("warning", "Atención", payload.message);
        } else {
          await this.notify(
            "danger",
            "Atención",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({
        loading2: false,
        showAddForm: false,
        waitSendExtras: false,
      });
      this.setState({ showResult: true, selected: [] });
    });
  };

  sendToSAP = () => {
    if (this.state.selected.length > 0) {
      let date1 = moment().format("DD/MM/YYYY hh:mm A");
      let data = { toSend: this.state.selected, date: date1 };
      this.setState({
        showAddForm: false,
        alert: (
          <ReactBSAlert
            warning
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Está seguro que quiere envíar a SAP los registros seleccionados?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.sendToSAPConfirmed(data)}
            onCancel={() => this.setState({ alert: null })}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, enviar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        ),
      });
    } else {
      this.setState({
        showAddForm: false,
        alert: (
          <ReactBSAlert
            custom
            style={{ display: "block", marginTop: "-100px" }}
            title="No tienes registros seleccionados, por favor selecciona los que necesita envíar a SAP."
            customIcon={
              <div
                className="swal2-icon swal2-warning swal2-animate-warning-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.setState({ alert: null })}
            confirmBtnBsStyle="primary"
            confirmBtnText="Cerrar"
            btnSize="md"
          />
        ),
      });
    }
  };

  selectionChange = (who, value) => {
    console.log("VALOR PARA FILTRAR");
    console.log(value);
    console.log(who);
    if (who === "sapStatus") {
      this.setState(
        {
          statusSelected: value.label,
        },
        () => {
          if (this.state.countrySelected === "Seleccione un pais") {
            if (this.state.statusSelected === "Rechazada por el Payroll") {
              let array = this.state.extrasGlobal.filter(
                (el) => el.sapStatus === "No enviar HC"
              );
              console.log("EXTRAS GLOBAL STATE");
              console.log(this.state.extrasGlobal);
              this.setState({ toSend: false, tableFilter: true });
              this.setState({ tableFilterInfo: array });
            } else {
              let array = this.state.extrasGlobal.filter(
                (el) => el.sapStatus === value.value
              );
              this.setState({ toSend: false, tableFilter: true });
              this.setState({ tableFilterInfo: array });
            }
          } else {
            if (this.state.statusSelected === "Rechazada por el Payroll") {
              let array = this.state.extrasGlobal.filter(
                (el) =>
                  el.sapStatus === "No enviar HC" &&
                  el.country === this.state.countrySelected
              );
              this.setState({ toSend: false, tableFilter: true });
              this.setState({ tableFilterInfo: array });
            } else {
              let statusCompare =
                this.state.statusSelected === "Lista para sincronizar"
                  ? "Enviar"
                  : this.state.statusSelected === "Sincronizada"
                  ? "Enviada"
                  : "No enviar";
              let array = this.state.extrasGlobal.filter(
                (el) =>
                  el.sapStatus === statusCompare &&
                  el.country === this.state.countrySelected
              );
              this.setState({ toSend: false, tableFilter: true });
              this.setState({ tableFilterInfo: array });
            }
          }
        }
      );
    }
    if (who === "country") {
      this.setState(
        {
          countrySelected: value.label,
        },
        () => {
          if (this.state.statusSelected === "Seleccione un estado") {
            let array = this.state.extrasGlobal.filter(
              (el) => el.country === value.value
            );
            this.setState({ toSend: false, tableFilter: true });
            this.setState({ tableFilterInfo: array });
          } else {
            if (this.state.statusSelected === "Rechazada por el Payroll") {
              let array = this.state.extrasGlobal.filter(
                (el) =>
                  el.sapStatus === "No enviar HC" &&
                  el.country === this.state.countrySelected
              );
              this.setState({ toSend: false, tableFilter: true });
              this.setState({ tableFilterInfo: array });
            } else {
              let statusCompare =
                this.state.statusSelected === "Lista para sincronizar"
                  ? "Enviar"
                  : this.state.statusSelected === "Sincronizada"
                  ? "Enviada"
                  : "No enviar";
              let array = this.state.extrasGlobal.filter(
                (el) =>
                  el.country === value.value && el.sapStatus === statusCompare
              );
              this.setState({ toSend: false, tableFilter: true });
              this.setState({ tableFilterInfo: array });
            }
          }
        }
      );
    }
  };

  filterToSend = () => {
    this.setState(() => ({
      loading2: true,
      loading: true,
      toSend: true,
      tableDate: false,
    }));
    if (
      this.state.searchingDateFlag === false &&
      this.state.statusSelected === "Seleccione un estado" &&
      this.state.countrySelected === "Seleccione un pais"
    ) {
      let data = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      this.props.findExtrasToSend(data).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState({
          loading2: false,
          extrasToSendGlobal: this.props.extrasToSend,
        });
      });
    } else {
      let data = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      this.props.findExtrasToSend(data).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        if (this.state.countrySelected !== "Seleccione un pais") {
          this.setState({
            loading2: false,
            extrasToSendGlobal: this.props.extrasToSend.filter(
              (el) =>
                el.sapStatus === "Enviar" &&
                el.country === this.state.countrySelected
            ),
          });
        } else {
          this.setState({
            loading2: false,
            extrasToSendGlobal: this.props.extrasToSend.filter(
              (el) => el.sapStatus === "Enviar"
            ),
          });
        }
      });
    }
  };

  changeUsersList = () => {
    let year = this.state.yearSelected;
    const data = {
      year: year,
      type: this.state.rol,
    };
    this.props.findUserByYear(data).then(async (res) => {
      // this takes the hours for widget
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("warning", "Atención", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }

      var arrayUsers = this.createUserList();
      this.setState({
        userList: arrayUsers,
      });
    });
  };

  handleDaysOnMonth = (date) => {
    var end = moment(date, "YYYY-MM").daysInMonth();
    var start = 1;
    return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleSelectionChange = (who, value) => {
    this.setState({
      clickedRol: false,
    });
    if (who === "year") {
      this.setState({
        yearSelected: value.value,
        clickedRol: false,
        rol: "userName",
        showGraph: false,
        selectedUser: "Seleccione rol",
        userList: [],
      });
    }

    if (who === "rol") {
      this.setState(
        {
          rol: value.value,
          selectedUser: "Seleccione nombre",
          clickedRol: true,
        },
        () => this.changeUsersList()
      );
    }
    if (who === "col") {
      this.setState({
        showGraph: true,
        selectedUser: value.label,
      });
      const data = {
        col: value.label,
        year: this.state.yearSelected,
        type: this.state.rol,
      };

      this.props.findHoursByUser(data).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
      });
    }
  };

  createUserList = () => {
    var userList = [];
    var i = 0;
    var element = {};
    if (this.state.rol === "userName") {
      for (i = 0; i < this.props.usersByYear.length; i++) {
        element = {
          value: this.props.usersByYear[i].userName,
          label: this.props.usersByYear[i].userName,
        };
        userList.push(element);
      }
      return userList;
    } else if (this.state.rol === "ceoName") {
      for (i = 0; i < this.props.usersByYear.length; i++) {
        element = {
          value: this.props.usersByYear[i].ceoName,
          label: this.props.usersByYear[i].ceoName,
        };
        userList.push(element);
      }

      return userList;
    } else if (this.state.rol === "preApproverName") {
      for (i = 0; i < this.props.usersByYear.length; i++) {
        element = {
          value: this.props.usersByYear[i].preApproverName,
          label: this.props.usersByYear[i].preApproverName,
        };
        if (element.value !== "N/A") {
          userList.push(element);
        }
      }

      return userList;
    }
  };

  render() {
    const {
      alert,
      startDate,
      endDate,
      page,
      sizePerPage,
      loading2,
      toSend,
      selected,
      showButtonToSend,
      tableFilterInfo,
      extrasGlobal,
      showGraph,
      yearsOptions,
      yearSelected,
    } = this.state;

    const { updatedExtrasSAP, rolesExtra, hoursByUser } = this.props;

    //#region Modal Sweet Alert y toast.
    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: true,
      timer: 5000,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    //#endregion

    //#region

    //Variable para llevar un rastreo de sapStatus de la db y Label a mostrar en el S&S
    const optionsStatus = [
      { value: "Enviar", label: "Lista para sincronizar" },
      { value: "No enviar", label: "No se sincronizará" },
      { value: "Enviada", label: "Sincronizada" },
      { value: "No enviar HC", label: "Rechazada por el Payroll" },
    ];

    const getCountries = () => {
      let countries = [];
      var arrayAllTeams = rolesExtra.filter((e) => e.includes("Extra Hours"));
      var arrayCountryRols = arrayAllTeams.map((e) =>
        e.split(" ")[2] === undefined ? "REG" : e.split(" ")[2]
      );
      if (arrayCountryRols.some((e) => e === "Managers")) {
        let arrayReturn = [
          ...new Set(extrasGlobal.map((data) => data.country)),
        ];
        arrayReturn.forEach((e) => countries.push({ value: e, label: e }));
        return countries;
      } else if (arrayCountryRols.some((e) => e === "REG")) {
        let arrayReturn = ["CR", "DO", "DR", "GT", "HN", "NI", "PA", "SV"];
        arrayReturn.forEach((e) => countries.push({ value: e, label: e }));
        return countries;
      } else {
        arrayCountryRols.forEach((e) => countries.push({ value: e, label: e }));
        return countries;
      }
    };

    const changeTableGraph = () => {
      this.setState({ showTable: !this.state.showTable });
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      clickToExpand: true,
      hideSelectAll: false,
      selected: selected,
      style: { backgroundColor: "#c8e6c9" },
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      selectColumnPosition: "left",
    };

    const defaultSorted = [
      {
        dataField: "date",
        order: "desc",
      },
    ];

    //region Handle para cambiar el estado de una solicitud.
    const handleChangeStatus = (row) => {
      if (this.state.sapAdmin) {
        let html = `
        <div class="container mt-4">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <h2 class="text-center mb-4">Cambio de estado de SAP a:</h2>
              <div class="input-group mb-3">
              <select class="form-select" id="estadoSeleccionado" style="width: 100%; padding: 8px 32px 8px 12px; border: 1px solid #ced4da; border-radius: 4px; cursor: pointer; outline: none;">
            <option value="" disabled selected>Seleccione un estado</option>
            ${optionsStatus
              .map(
                (option) =>
                  `<option value="${option.value}">${option.label}</option>`
              )
              .join("")}
          </select>
              </div>
              <h2 class="mb-2">Motivo del cambio:</h2>
              <textarea class="form-control mb-4" rows="3" id="reasonforchange" style="resize: none;" placeholder="Motivo del cambio..."></textarea>
            </div>
          </div>
        </div>
      `;
        let newInfo = {
          id: row.id,
          reasonForChange: "",
          state: "",
        };

        MySwal.fire({
          type: "info",
          title: `Solicitud: ${row.id} - ${row.user}`,
          html: html,
          confirmButtonText: "Cambiar estado",
          confirmButtonColor: "#2DCE89",
          cancelButtonText: "Cancelar",
          showCancelButton: true,

          preConfirm: () => {
            newInfo = {
              ...newInfo,
              reasonForChange: document.getElementById("reasonforchange").value,
              state: document.getElementById("estadoSeleccionado").value,
            };

            // Validación de si está lleno el motivo.
            if (!newInfo.reasonForChange) {
              Swal.showValidationMessage("Por favor, ingrese un motivo");
              return false;
            }
            // Validación de si está lleno el nuevo estado.
            if (!newInfo.state) {
              Swal.showValidationMessage("Por favor, seleccione un estado");
              return false;
            }
          },
        }).then((result) => {
          const data = { newInfoUpdated: newInfo, report: row };
          if (result?.value) {
            MySwal.fire({
              type: "warning",
              title: `Solicitud: ${row.id}`,
              html: `<h2>¿Está seguro(a) que desea cambiar el estado de SAP a ${
                optionsStatus.find((status) => status.value === newInfo.state)
                  .label
              }?</h2>`,

              confirmButtonText: "Si, cambiar",
              confirmButtonColor: "#2DCE89",
              cancelButtonText: "No, cancelar", //#fb6340
              showCancelButton: true,

              preConfirm: () => {
                // if (newInfo.state === "Enviada") {
                //   const dataToSendSap = {
                //     toSend: [newInfo.id],
                //     date: moment().format("DD/MM/YYYY hh:mm A"),
                //   };
                //   this.sendToSAPConfirmed(dataToSendSap);
                // }
                // Actualiza el status de SAP.
                this.props.updateStatus({ data }).then(async (res) => {
                  this.setState({
                    loading2: false,
                  });
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      await this.notify("warning", "Atención", payload.message);
                    } else {
                      await this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                      this.setState({
                        loading2: false,
                      });
                    }
                  } else {
                    await this.notify(
                      "success",
                      "Éxito",
                      "Estado de SAP actualizado exitosamente."
                    );
                    //#endregion
                    //Puedo hacer que este boton vuelva a cargar la tabla
                    //Refrescar las gestiones.
                    this.buttonReturnList();
                  }
                });
              },
            });
          }
        });
      }
    };

    return (
      <>
        {/* <LogStatusSAPModal
          // targets={targets}
          showModal={this.showLogSapStatus}
        // toggleModal={this.handleOnToggleTargetsModal}
        /> */}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>

        {this.state.showTable === true ? (
          <div>
            <AdminHeader
              name="Información horas extra"
              parentName="Extra Hours"
            />
            <Container className="mt--6" fluid>
              <Spin
                spinning={this.state.waitSendExtras}
                tip="Espere mientras se envian los registros"
                size="large"
              >
                <Row>
                  <div className="col">
                    {toSend === false && (
                      <div>
                        <DateRanges
                          title="Fechas de busqueda"
                          subtitle="Ingrese un rango de fechas para la busqueda."
                          startDate={startDate}
                          endDate={endDate}
                          reactDatetimeChange={this.handleReactDatetimeChange}
                          classNameReactDatetimeDays={
                            this.getClassNameReactDatetimeDays
                          }
                          haveButtons={true}
                          handleOnCLick={this.handleOnCLick}
                          clearSearch={this.clearSearch}
                          buttonSwitchFunc={changeTableGraph}
                        />
                        <Card>
                          <CardBody>
                            <Row className="align-items-center">
                              <Col className="text-right" xs="12" md="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect1"
                                  >
                                    Estado SAP
                                  </label>
                                  <Select
                                    className="basic-single"
                                    id="example-month-input"
                                    type="select"
                                    placeholder={"Estado de SAP"}
                                    isSearchable={false}
                                    options={optionsStatus}
                                    value={{
                                      value: this.state.statusSelected,
                                      label: this.state.statusSelected,
                                    }}
                                    onChange={(e) =>
                                      this.selectionChange("sapStatus", e)
                                    }
                                  ></Select>
                                </FormGroup>
                              </Col>
                              <Col className="text-right" xs="12" md="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect1"
                                  >
                                    País
                                  </label>
                                  <Select
                                    className="basic-single"
                                    id="example-month-input"
                                    type="select"
                                    placeholder={"Estado de SAP"}
                                    isSearchable={false}
                                    options={getCountries()}
                                    value={{
                                      value: this.state.countrySelected,
                                      label: this.state.countrySelected,
                                    }}
                                    onChange={(e) =>
                                      this.selectionChange("country", e)
                                    }
                                  ></Select>
                                </FormGroup>
                              </Col>
                              <Col>
                                <Button
                                  className="btn-icon"
                                  color="danger"
                                  size="sm"
                                  type="button"
                                  onClick={this.clearSearchFilters}
                                >
                                  <span className="btn-inner--icon mr-">
                                    <i className="fas fa-trash" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Limpiar
                                  </span>
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                    )}
                    {loading2 ? (
                      <Progress animated max="100" value="100" color="info" />
                    ) : (
                      <Table
                        title="Horas extras"
                        subtitle={
                          toSend === true
                            ? "Horas extras listas para ser sincronizadas con SAP."
                            : "Información de los reportes y solicitudes de horas extra."
                        }
                        columns={[
                          {
                            dataField: "actions",
                            isDummyField: true,
                            text: "Rechazar",
                            hidden: !toSend,
                            csvExport: false,
                            formatter: (cellContent, row) => {
                              const rejectExtra = (e) => {
                                e.stopPropagation();
                                this.rejectExtraConfirmation(row);
                              };
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    onClick={rejectExtra}
                                    className="btn-icon btn-2"
                                    color="danger"
                                    type="button"
                                    size="sm"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-times"></i>
                                    </span>
                                  </Button>
                                </div>
                              );
                            },
                          },
                          {
                            dataField: "id",
                            text: "Id",
                            sort: true,
                          },
                          {
                            dataField: "date",
                            text: "Fecha",
                            sort: true,
                            formatter: (cellContent, row) => {
                              return moment(
                                cellContent.substring(0, 10)
                              ).format("DD/MM/YYYY");
                            },
                          },
                          {
                            dataField: "dayBefore",
                            text: "Dia anterior",
                            hidden: true,
                          },
                          {
                            dataField: "userName",
                            text: "Usuario solicitante",
                            sort: true,
                            formatter: (cellContent, row) => {
                              return (
                                <div
                                  style={{ whiteSpace: "pre-wrap" }}
                                  className="d-flex align-items-center"
                                >
                                  <span className="completion mr-2">
                                    {cellContent}
                                  </span>
                                </div>
                              );
                            },
                          },
                          {
                            dataField: "sapIdUser",
                            text: "ID SAP",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "country",
                            text: "Pais",
                            sort: true,
                          },
                          {
                            dataField: "subDivision",
                            text: "Sub División",
                            sort: true,
                          },
                          {
                            dataField: "ceoName",
                            text: "Supervisor",
                            sort: true,
                            formatter: (cellContent, row) => {
                              return (
                                <div
                                  style={{ whiteSpace: "pre-wrap" }}
                                  className="d-flex align-items-center"
                                >
                                  <span className="completion mr-2">
                                    {cellContent}
                                  </span>
                                </div>
                              );
                            },
                          },
                          {
                            dataField: "preApproverName",
                            text: "Preaprobador",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "time",
                            text: "Tiempo inicio",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "createdAt",
                            text: "Fecha de solicitud/reporte",
                            sort: true,
                            hidden: true,
                            formatter: (cellContent, row) => {
                              return moment(cellContent).format("DD/MM/YYYY");
                            },
                          },
                          {
                            dataField: "endTime",
                            text: "Tiempo final",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "hoursComma",
                            text: "Horas",
                            sort: true,
                          },
                          {
                            dataField: "justification",
                            text: "Justificacion",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "info",
                            text: "OS y Item",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "jobDescription",
                            text: "Descripcion del trabajo",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "ceoReason",
                            text: "Motivo rechazo (extra o aumento de tiempo)",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "userReason",
                            text: "Motivo aumento de tiempo",
                            sort: true,
                            hidden: true,
                            searchable: false,
                          },
                          {
                            dataField: "preApproverStatus",
                            text: "Estado preaprobador",
                            sort: true,
                            searchable: false,
                            formatter: (cellContent, row) => {
                              if (cellContent === "Cancelada") {
                                return (
                                  <div
                                    style={{ whiteSpace: "pre-wrap" }}
                                    className="d-flex align-items-center"
                                  >
                                    <span className="completion mr-2">
                                      Cancelada por colaborador
                                    </span>
                                  </div>
                                );
                              } else {
                                return cellContent;
                              }
                            },
                          },
                          {
                            dataField: "status",
                            text: "Estado jefatura",
                            sort: true,
                            formatter: (cellContent, row) => {
                              if (cellContent === "Cancelada") {
                                return (
                                  <div
                                    style={{ whiteSpace: "pre-wrap" }}
                                    className="d-flex align-items-center"
                                  >
                                    <span className="completion mr-2">
                                      Cancelada por colaborador
                                    </span>
                                  </div>
                                );
                              } else {
                                return cellContent;
                              }
                            },
                          },
                          {
                            //En esta row de la tabla se obtiene foundOption que es el estado de la solicitud en SAP
                            //Convertido con el label que corresponde de la variable optionsStatus
                            dataField: "sapStatus",
                            text: "Estado en SAP",
                            sort: true,
                            searchable: true,
                            formatter: (cellContent, row) => {
                              const foundStatus = optionsStatus.find(
                                (option) => option.value === cellContent
                              );
                              if (
                                foundStatus &&
                                cellContent !== "Enviada" &&
                                cellContent !== "Enviar"
                              ) {
                                return (
                                  <div
                                    style={{ whiteSpace: "pre-wrap" }}
                                    className="d-flex align-items-center"
                                    onClick={() => handleChangeStatus(row)}
                                  >
                                    <Badge color={"warning"} pill>
                                      {this.state.sapAdmin && (
                                        <span className="btn-inner--icon mr-">
                                          <i className="fas fa-edit" />
                                        </span>
                                      )}

                                      <span className="btn-inner--text">
                                        {" "}
                                        {foundStatus.label}
                                      </span>
                                    </Badge>
                                  </div>
                                );
                              } else if (cellContent === "Enviada") {
                                return (
                                  <div
                                    style={{ whiteSpace: "pre-wrap" }}
                                    className="d-flex align-items-center"
                                  >
                                    <Badge color={"success"} pill>
                                      <span className="btn-inner--text">
                                        {" "}
                                        Sincronizada
                                      </span>
                                    </Badge>
                                  </div>
                                );
                              } else if (cellContent === "Enviar") {
                                return (
                                  <div
                                    style={{ whiteSpace: "pre-wrap" }}
                                    className="d-flex align-items-center"
                                    onClick={() => handleChangeStatus(row)}
                                  >
                                    <Badge color={"info"} pill>
                                      {this.state.sapAdmin && (
                                        <span className="btn-inner--icon mr-">
                                          <i className="fas fa-edit" />
                                        </span>
                                      )}

                                      <span className="btn-inner--text">
                                        {" "}
                                        Lista para sincronizar
                                      </span>
                                    </Badge>
                                  </div>
                                );
                              } else {
                                return cellContent;
                              }
                            },
                          },
                          {
                            //Muestra en el campo de motivo de rechazo el motivo de rechazo de SAP o el de ceo o preaprobador
                            dataField: "reasonRejected",
                            text: "Motivo de rechazo",
                            sort: false,
                            hidden: false,
                            searchable: false,
                            formatter: (cellContent, row) => {
                              const reason =
                                cellContent !== null
                                  ? cellContent
                                  : row.ceoReason;
                              return <span>{reason}</span>;
                            },
                          },
                        ]}
                        data={
                          this.state.toSend === true
                            ? this.state.extrasToSendGlobal
                            : this.state.tableFilter === true
                            ? tableFilterInfo
                            : extrasGlobal
                        }
                        alert={alert}
                        sizePerPage={sizePerPage}
                        page={page}
                        totalSize={dataExtraHours.length}
                        defaultSorted={defaultSorted}
                        expandFeatrue={this.expandRow}
                        cvsFeatrue={true}
                        extraTable={true}
                        showButtonToSend={showButtonToSend}
                        filterToSend={this.filterToSend}
                        extrasToSendList={toSend}
                        sendToSAP={this.sendToSAP}
                        functionBackTable={this.buttonReturnList}
                        selectRow={
                          toSend === true
                            ? selectRow
                            : {
                                mode: "checkbox",
                                hideSelectColumn: true,
                                clickToExpand: true,
                              }
                        }
                      />
                    )}
                  </div>
                </Row>
              </Spin>
            </Container>
          </div>
        ) : (
          <div>
            <AdminHeader
              name="Dashboard horas extra"
              parentName="Extra Hours"
              selectionOptionalUser={true}
              selectionChange={this.handleSelectionChange}
              usersList={this.state.userList}
              selectedUser={this.state.selectedUser}
              yearsStatus={yearsOptions}
              yearSelected={yearSelected}
              buttonSwitchFunc={changeTableGraph}
            />
            <div></div>
            {showGraph && (
              <Container className="mt--6" fluid>
                <Chart
                  data={this.state.clickedRol === true ? {} : hoursByUser}
                />
              </Container>
            )}
          </div>
        )}

        {this.state.showResult ? (
          <div>
            <Modal isOpen={this.state.showResult}>
              <div style={{ textAlign: "right" }}>
                <Button
                  // className="btn-icon"
                  color="danger"
                  size="sm"
                  type="button"
                  onClick={this.buttonReturnList}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-times" />
                  </span>
                  <span className="btn-inner--text">Cerrar</span>
                </Button>
              </div>

              <ModalBody>
                <Table
                  title="Resultado del envío a SAP."
                  columns={[
                    {
                      dataField: "id",
                      text: "ID",
                    },
                    {
                      dataField: "sapStatus",
                      text: "Estado en SAP",
                      formatter: (cellContent, row) => {
                        if (cellContent === "No enviar") {
                          return (
                            <div
                              style={{ whiteSpace: "pre-wrap" }}
                              className="d-flex align-items-center"
                            >
                              <span className="completion mr-2">
                                NO se sincronizará
                              </span>
                            </div>
                          );
                        } else if (cellContent === "Enviada") {
                          return (
                            <div
                              style={{ whiteSpace: "pre-wrap" }}
                              className="d-flex align-items-center"
                            >
                              <span className="completion mr-2">
                                Sincronizada
                              </span>
                            </div>
                          );
                        } else if (cellContent === "Enviar") {
                          return (
                            <div
                              style={{
                                whiteSpace: "pre-wrap",
                                backgroundColor: "yellow",
                              }}
                              className="d-flex align-items-center"
                            >
                              <span className="completion mr-2">
                                No se sincronizó
                              </span>
                            </div>
                          );
                        } else {
                          return cellContent;
                        }
                      },
                    },
                    {
                      dataField: "sapMessage",
                      text: "Mensaje SAP",
                    },
                  ]}
                  data={updatedExtrasSAP}
                  sizePerPage={12}
                  page={1}
                  totalSize={updatedExtrasSAP.length}
                  deleteSearch={true}
                />
              </ModalBody>
            </Modal>
          </div>
        ) : null}

        {this.state.showLogSapStatus ? (
          <div>
            <Modal
              className="modal-dialog-centered"
              size="md"
              isOpen={this.state.showLogSapStatus}
              toggle={this.closeLogsModal}
            >
              <Card className="bg-gradient-default shadow mb-0">
                <CardHeader className="bg-transparent">
                  <h3 className="text-white text-center mb-0">
                    Log de cambios de estado de SAP
                  </h3>
                </CardHeader>
                <CardBody>
                  <Spin
                    spinning={this.state.logSapStatus.length == 0}
                    tip="Cargando historial de cambios"
                    size="small"
                  >
                    <div
                      className="timeline timeline-one-side"
                      data-timeline-axis-style="dashed"
                      data-timeline-content="axis"
                    >
                      {this.state.logSapStatus.map((row, key) => {
                        return (
                          <div className="timeline-block" key={key}>
                            <span className="timeline-step badge-primary">
                              <i className={"fas fa-info-circle"} />
                            </span>
                            <div className="timeline-content">
                              <h5 className="text-light text-muted font-weight-bold">
                                {moment(row.createdAt)
                                  .utc()
                                  .utcOffset(moment().utcOffset())
                                  .format("LLLL")}
                              </h5>
                              <h5 className="text-white mt-3 mb-0">
                                Razón de cambio
                              </h5>
                              <h4 className="text-light text-muted font-weight-bold mt-3 mb-0">
                                {row.reason}
                              </h4>
                              <div className="mt-3">
                                <Badge
                                  className="badge-md"
                                  color={
                                    row.changedStatus === "Enviada"
                                      ? "success"
                                      : row.changedStatus === "Enviar"
                                      ? "info"
                                      : "warning"
                                  }
                                  pill
                                >
                                  {
                                    optionsStatus.find(
                                      (status) =>
                                        status.value === row.changedStatus
                                    ).label
                                  }
                                </Badge>
                                <Badge className="badge-md" color={"info"} pill>
                                  {row.createdBy}
                                </Badge>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Spin>
                </CardBody>
              </Card>
            </Modal>
          </div>
        ) : null}
      </>
    );
  }
}

Information.defaultProps = {
  extras: [],
  dateExtras: [],
  extrasToSend: [],
  updatedExtrasSAP: [],
  rolesExtra: [],
  usersByYear: [],
  hoursByUser: {},
  yearsExtras: [],
};

const mapStateToProps = (state) => ({
  extras: getExtras(state),
  dateExtras: getDateExtras(state),
  extrasToSend: getExtrasToSend(state),
  updatedExtrasSAP: getUpdateExtrasSAP(state),
  rolesExtra: getUserRolesExtra(state),
  usersByYear: getUsersByYear(state),
  hoursByUser: getHoursByUser(state),
  yearsExtras: getYearsExtras(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findExtras,
    findDateExtras,
    findExtrasToSend,
    updateExtrasSAP,
    userRolesExtra,
    updateStatus,
    updateExtraInfo,
    findUserByYear,
    findHoursByUser,
    findYearsExtras,
    getReasonForChangeSap,
  })(Information)
);
