import { getMasterData, getInfoByUser, getCountriesByHCM } from "actions/exitInterview";
import DigitalFormButtons from "components/ExitInterview/DigitalFormButtons";
import DigitalFormMainTable from "components/ExitInterview/DigitalFormMainTable.jsx";
import DraftTable from "components/ExitInterview/DraftTable.jsx";
import SaveAndDraftButtons from "components/ExitInterview/SaveAndDraftButtons";
import SaveTable from "components/ExitInterview/SaveTable.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import React, { useEffect, useState } from "react";
import { CardFooter, Container, UncontrolledTooltip, Row, Button, Col } from "reactstrap";
//Dispatch
import { useDispatch } from "react-redux";
//Actions
import { Spin } from 'antd';
import InfoModal from "components/ExitInterview/History/InfoModal";
import DraftModal from "components/ExitInterview/DraftModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getTeamsUserAccess, getUserProtected } from 'selectors/adminLayout.jsx';

const DigitalForm = () => {
  const MySwal = withReactContent(Swal);

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoad] = useState(true);
  const [alert, setAlert] = useState("");
  const [rowsModal, setRowsModal] = useState({
    colums: [],
    rows: [],
    rowsDraft: [],
    products: [],
    subtotal: 0,
    filters: [],
    files: [],
    rowsExcel: [],
    isInvalid: ""
  });
  const [rowsDraftModal, setRowsDraftModal] = useState({});
  const [rowsInfoModal, setRowsInfoModal] = useState({});

  const [info, setInfo] = useState({
    collaboratorName: "",
    department: "",
    immediateBoss: "",
    timeWorked: "",
    idCollaborator: "",
    benefitsJob: "",
    specify: "",
    date: new Date().toISOString().split('T')[0]
  });
  const { filters } = rowsModal;
  const [openModal, setOpenModal] = useState(false);
  const [openDraftModal, setDraftOpenModal] = useState(false);
  const [options, setOptions] = useState({
    goBackGBM: [],
    exitReason: [],
    countriesData: [],
    exitType: [],
    rehirable: [],
    masterData: [],
    countries: [],
    companyType: []
  });
  const [countries, setCountries] = useState([]);

  const Toast = MySwal.mixin({
    toast: true,
    position: "bottom-right",
    showConfirmButton: true,
    timer: 5000,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  useEffect(() => {
    const getRowsData = async () => {
      await handleRowInfo();
    };
    getRowsData();
  }, []);

  const handleRowInfo = async () => {
    dispatch(getMasterData()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setOptions((prevState) => ({
          ...prevState,
          masterData: payload.data.payload.masterData,
          goBackGBM: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.goBackGBM],
          exitReason: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.exitReason],
          countriesData: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.countriesData],
          exitType: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.exitType],
          rehirable: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.rehirable],
          companyType: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.companyType],
          countries: payload.data.payload.masterData.countries,
        }));
      }
    });
    const nombre = getUserProtected().NOMBRE;
    dispatch(getCountriesByHCM({ name: nombre })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setCountries(payload.data.payload.data)
      }
    })
    setLoad(false);
  };
  const renderRowsByFilters = (rows, type) => {
    let rows2 = [];
    let typeIndex = type === 1 ? "country" : "countriesData"
    if (Object.keys(filters).length === 0) {
      rows = rows.filter(
        (item) => {
          for (const x of countries) {
            if (item[typeIndex] === x.countryId) {
              rows2.push(item)
            }
          }
        }
      )

      return rows2
    }
    else {
      const filterKeys = Object.keys(filters);
      for (const element of filterKeys) {
        const valueFiltered = filters[element].toLowerCase();
        rows.filter(
          (item) => {
            for (const x of countries) {
              if (item.country === x.countryId) {
                rows2.push(item)
              }
            }
          }
        )
        rows2 = rows2.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
      }
    }
    return rows2

  }
  //Funcion para los filtros por columna
  const handleOnfilterDinamic = (constant, e) => {
    let text = e;
    filters[constant] = text;
    setRowsModal((prevState) => ({
      ...prevState,
      filters: filters,
    }));
  };

  const handleOnOpenModal = (row) => {
    setOpenModal(true);
    setRowsInfoModal(row);
  }
  const handleOnDraftOpenModal = (row) => {
    setDraftOpenModal(true);
    setRowsDraftModal(row);
  }

  const handleOnChangeInfo = (constant, e, name) => {
    if (constant === "goBackGBM" || constant === "countriesData" || constant === "rehirable" || constant === "exitTypeId" || constant === "companyTypeId") {
      setInfo((prevState) => ({
        ...prevState,
        [constant]: e.id,
        [name]: e.label
      }));
    } else {
      setInfo((prevState) => ({
        ...prevState,
        [constant]: e,
      }));
    }

  };
  const handleOnGetInfoByUser = () => {
    dispatch(getInfoByUser({ user: info.user })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setInfo((prevState) => ({
          ...prevState,
          collaboratorName: payload.data.payload.data.collaboratorName,
          department: payload.data.payload.data.department,
          immediateBoss: payload.data.payload.data.immediateBoss,
          timeWorked: payload.data.payload.data.timeWorked,
          idCollaborator: payload.data.payload.data.idCollaborator,
          userBoss: payload.data.payload.data.userBoss
        }));
      } else {
        setInfo((prevState) => ({
          ...prevState,
          name: "",
          department: "",
          manager: "",
          timeWorked: ""
        }));
        Toast.fire({
          title: "Atención",
          html: "Ese usuario no existe",
          type: "danger",
        });
      }
    });
  }
  //#region
  const [page, setPage] = useState(1);
  //#endregion

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const teams = getTeamsUserAccess();
    if (teams.some((row) => row.indexOf("ExitInterview Admin") !== -1)) {
      setIsAdmin(true)
    }
  }, []);
  return (
    <div>
      {alert}
      <AdminHeader name="Home" parentName="Formulario Digital" />
      <Container className="mt--6" fluid>
        <Spin size="large" spinning={loading}>
          <InfoModal
            openModal={openModal}
            closeModal={setOpenModal}
            rowInfo={rowsInfoModal}
            options={options}
            Toast={Toast}
            setRowsModal={setRowsModal}
            setRowsInfotModal={setRowsInfoModal}
            isAdmin={isAdmin}
          />
          <DraftModal
            setOpenModal={setOpenModal}
            openDraftModal={openDraftModal}
            closeDraftModal={setDraftOpenModal}
            setRowsDraftModal={setRowsDraftModal}
            rowInfo={rowsDraftModal}
            Toast={Toast}
            setRowsModal={setRowsModal}
            options={options}
            setAlert={setAlert}
            isAdmin={isAdmin}
            setLoad={setLoad}
          />

          <Row className="d-flex justify-content-end mb-3">
            <Col xs="12" md="2">
              <Button
                className="btn-icon"
                color={"dark"}
                block
                onClick={() => window.open("https://gbmcorp-my.sharepoint.com/personal/fnavarro_gbm_net1/_layouts/15/stream.aspx?id=%2Fpersonal%2Ffnavarro%5Fgbm%5Fnet1%2FDocuments%2FDocuments%2FPROYECTOS%2F1%2E%20Proyectos%202024%2FMejoras%20%2D%20Entrevista%20de%20salida%2FVideo%20tutorial%20%2D%20entrevista%20de%20salida%2FGesti%C3%B3n%20de%20entrevistas%20de%20salida%20en%20Smart%20and%20Simple%20V2%2Emp4&referrer=Teams%2ETEAMS%2DELECTRON&referrerScenario=p2p%5Fns%2Dbim&ga=1")}
                type="button"
                id={"instructivo"}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-info-circle"></i>
                </span>
                <span className="btn-inner--text">
                  {"Video tutorial"}
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target={"instructivo"}>
                {"Ver guía para generar una entrevista"}
              </UncontrolledTooltip>
            </Col>
          </Row>

          <DigitalFormButtons
            setActiveTab={setActiveTab}
            setInfo={setInfo}
            setLoad={setLoad}
          />
          {activeTab === 1 && (
            <>
              <Spin size="large" spinning={loading}>
                <DigitalFormMainTable
                  setInfo={setInfo}
                  option={options}
                  handleOnChangeInfo={handleOnChangeInfo}
                  info={info}
                  handleOnGetInfoByUser={handleOnGetInfoByUser}
                  setRowsModal={setRowsModal}
                />
                <CardFooter>
                  <SaveAndDraftButtons
                    setAlert={setAlert}
                    setInfo={setInfo}
                    Toast={Toast}
                    info={info}
                  />
                </CardFooter>
              </Spin>
            </>
          )}
          {activeTab === 2 && (
            <Spin size="large" spinning={loading}>
              <SaveTable
                setPage={setPage}
                page={page}
                setRowsModal={setRowsModal}
                rows={renderRowsByFilters(rowsModal.rows, 1)}
                handleOnOpenModal={handleOnOpenModal}
                setLoad={setLoad}


              />
            </Spin>
          )}
          {activeTab === 3 && (
            <Spin size="large" spinning={loading}>
              <DraftTable
                isAdmin={isAdmin}
                setPage={setPage}
                page={page}
                setRowsModal={setRowsModal}
                Toast={Toast}
                setLoad={setLoad}
                rows={renderRowsByFilters(rowsModal.rowsDraft, 2)}
                filters={handleOnfilterDinamic}
                handleOnDraftOpenModal={handleOnDraftOpenModal}
              />
            </Spin>
          )}
        </Spin>
      </Container>
    </div>
  );
};

export default DigitalForm;
