// core actions
import { findExtraHourByCeo } from "actions/extraHours";
// antd components
import { Spin } from 'antd';
// core components Shared
import DateRanges from "components/Shared/DateRanges/DateRanges.jsx";
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import Table from "components/Shared/Table/Table.jsx";
// javascript library for dates
import moment from 'moment';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// core reactstrap
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getExtraHoursByCeo } from "selectors/extraHours";

class ExtraHoursCeo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    };
  };

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.findExtraHours(startDate, endDate);
  };

  findExtraHours = (startDate, endDate) => {
    this.setState({
      loading: true,
      message: "Cargando el reporte de horas extras."
    });
    this.props.findExtraHourByCeo({
      startDate,
      endDate
    }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  /**
  * Funcion para el manejo de notificaciones parametrizadas
  * @memberof OptionalsPositions
  */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
  * Funcion para cuando se selecciona una fecha ya sea de inicio o de fin, la agrega a la variable
  * respectiva en el estado
  * @memberof Nodes
  */
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else {
      const {
        startDate,
        endDate
      } = this.state;
      this.setState({
        [who]: date,
        loading: true
      });
      this.findExtraHours(who === 'startDate' ? date : startDate, who === 'endDate' ? date : endDate,)
    }
  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = date => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  expandRow = {
    clickToExpand: true,
    parentClassName: "expand-parent",
    renderer: row => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Hora de inicio</span> <br />
              {moment(row.time, "HH:mm:ss").format("HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Fecha de creacion de solicitud/reporte
              </span>{" "}
              <br />
              {moment(row.createdAt)
                .utc()
                .utcOffset(moment().utcOffset())
                .format("DD/MM/YYYY | HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Preaprobador</span> <br />
              {row.preApproverName === "N/A"
                ? "No asignado / No requerido"
                : row.preApproverName}
            </div>
          </div>
        </span>
        <br />

        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Hora final</span> <br />
              {moment(row.endTime, "HH:mm:ss").format("HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Fecha y hora de aprobación supervisor
              </span>{" "}
              <br />
              {row.updatedStatus === null
                ? "N/A"
                : moment(row.updatedStatus)
                  .utc()
                  .utcOffset(moment().utcOffset())
                  .format("DD/MM/YYYY | HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Justificacion</span> <br />
              {row.justification}
            </div>
          </div>
        </span>
        <br />
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Aumento de tiempo</span> <br />
              {row.endTime === row.newEndTime ? "SI" : "NO"}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Fecha de aprobación preaprobador
              </span>{" "}
              <br />
              {row.updatedPreapproverStatus === null
                ? "N/A"
                : moment(row.updatedPreapproverStatus)
                  .utc()
                  .utcOffset(moment().utcOffset())
                  .format("DD/MM/YYYY | HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Orden/Item relacionado</span>{" "}
              <br />
              {row.info}
            </div>
          </div>
        </span>
        <br />
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tipo</span> <br />
              {row.old === 1 ? "Reporte" : "Solicitud a futuro"}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Actualización de estado en SAP
              </span>{" "}
              <br />
              {row.updatedSapStatus === null
                ? "N/A"
                : moment(row.updatedSapStatus)
                  .utc()
                  .utcOffset(moment().utcOffset())
                  .format("DD/MM/YYYY | HH:mm")}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Día anterior marcado</span>{" "}
              <br />
              {row.dayBefore}
            </div>
          </div>
        </span>
        <br />
        <span>
          <div className="row">
            <div className="col">
              {row.status === "Aprobada" && row.ceoReason !== "N/A" ? (
                <span style={{ color: "#0D45A4" }}>
                  Motivo de rechazo aumento de tiempo
                </span>
              ) : (
                  <span style={{ color: "#0D45A4" }}>Motivo de rechazo</span>
                )}

              <br />
              {row.ceoReason}
              <br />
              <br />
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Descripción del trabajo</span>
              <br />
              {row.jobDescription}
              <br />
              <br />
            </div>

            <div className="col">
              <span style={{ color: "#0D45A4" }}>Motivo aumento de tiempo</span>
              <br />
              {row.userReason}
            </div>
          </div>
        </span>
        <br />
      </div>
    )
  };

  render() {

    const {
      name,
      parentName,
      extraHours
    } = this.props;

    const {
      page,
      loading,
      message,
      endDate,
      startDate,
      sizePerPage,
    } = this.state;

    const defaultSorted = [
      {
        dataField: "ID",
        order: "asc"
      }
    ];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            <Row>
              <Col>
                <DateRanges
                  title="Fechas de búsqueda"
                  subtitle="Ingrese un rango de fechas para la búsqueda."
                  startDate={startDate}
                  endDate={endDate}
                  reactDatetimeChange={this.handleReactDatetimeChange}
                  classNameReactDatetimeDays={this.getClassNameReactDatetimeDays}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  total2
                  cvsFeatrue
                  style={{
                    whiteSpace: "normal",
                    textAlign: "justify"
                  }}
                  title="Horas Reportadas"
                  subtitle="Reporte de las Horas Reportadas por mis recursos."
                  columns={[
                    {
                      dataField: "id",
                      text: "Id",
                      sort: true
                    },
                    {
                      dataField: "date",
                      text: "Fecha",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return moment(
                          cellContent.substring(0, 10)
                        ).format("DD/MM/YYYY");
                      }
                    },
                    {
                      dataField: "dayBefore",
                      text: "Dia anterior",
                      hidden: true
                    },
                    {
                      dataField: "userName",
                      text: "Usuario solicitante",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="d-flex align-items-center"
                          >
                            <span className="completion mr-2">
                              {cellContent}
                            </span>
                          </div>
                        );
                      }
                    },

                    {
                      dataField: "sapIdUser",
                      text: "ID SAP",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },

                    {
                      dataField: "country",
                      text: "Pais",
                      sort: true
                    },
                    {
                      dataField: "ceoName",
                      text: "Supervisor",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="d-flex align-items-center"
                          >
                            <span className="completion mr-2">
                              {cellContent}
                            </span>
                          </div>
                        );
                      }
                    },
                    {
                      dataField: "preApproverName",
                      text: "Preaprobador",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "time",
                      text: "Tiempo inicio",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "createdAt",
                      text: "Fecha de solicitud/reporte",
                      sort: true,
                      hidden: true,
                      formatter: (cellContent, row) => {
                        return moment(cellContent).format("DD/MM/YYYY");
                      }
                    },
                    {
                      dataField: "endTime",
                      text: "Tiempo final",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "hoursComma",
                      text: "Horas",
                      sort: true
                    },
                    {
                      dataField: "justification",
                      text: "Justificacion",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "info",
                      text: "OS y Item",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "jobDescription",
                      text: "Descripcion del trabajo",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "ceoReason",
                      text: "Motivo rechazo (extra o aumento de tiempo)",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "userReason",
                      text: "Motivo aumento de tiempo",
                      sort: true,
                      hidden: true,
                      searchable: false
                    },
                    {
                      dataField: "preApproverStatus",
                      text: "Estado preaprobador",
                      sort: true,
                      searchable: false,
                      formatter: (cellContent, row) => {
                        if (cellContent === "Cancelada") {
                          return (
                            <div
                              style={{ whiteSpace: "pre-wrap" }}
                              className="d-flex align-items-center"
                            >
                              <span className="completion mr-2">
                                Cancelada por colaborador
                                    </span>
                            </div>
                          );
                        } else {
                          return cellContent;
                        }
                      }
                    },
                    {
                      dataField: "status",
                      text: "Estado jefatura",
                      sort: true,
                      formatter: (cellContent, row) => {
                        if (cellContent === "Cancelada") {
                          return (
                            <div
                              style={{ whiteSpace: "pre-wrap" }}
                              className="d-flex align-items-center"
                            >
                              <span className="completion mr-2">
                                Cancelada por colaborador
                                    </span>
                            </div>
                          );
                        } else {
                          return cellContent;
                        }
                      }
                    },
                    {
                      dataField: "sapStatus",
                      text: "Estado en SAP",
                      sort: true,
                      searchable: true,
                    }
                  ]}
                  data={extraHours}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={extraHours.length}
                  defaultSorted={defaultSorted}
                  expandFeatrue={this.expandRow}
                  fileName="Reporte de Horas Extras"
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
};

ExtraHoursCeo.defaultProps = {
  name: "Reporte de mis recursos",
  parentName: "Horas Extras",
  extraHours: []
};

ExtraHoursCeo.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  extraHours: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  extraHours: getExtraHoursByCeo(state),
});

export default withRouter(connect(mapStateToProps, {
  findExtraHourByCeo
})(ExtraHoursCeo));