// core actions
import { findAllPersonalInfoEmployeeForms } from "actions/knowEmployee.jsx";
// antd components
import { Spin } from "antd";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// core components
import Filters from "components/KnowEmployee/Filters.jsx";
import FormsList from "components/KnowEmployee/FormsList";
// dates library
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getPersonalInfoEmployeeForms,
  getFiltersOptions,
} from "selectors/knowEmployee.jsx";

class Forms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      filtersValues: {
        dates: {
          value: 0,
          label: "Todos",
        },
        employees: {
          value: 0,
          label: "Todos",
        },
        positions: {
          value: 0,
          label: "Todos",
        },
      },
    };
  }

  componentDidMount() {
    this.findAllEmployeeForms();
  }

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof MySignatures
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof MySignatures
   */
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  findAllEmployeeForms = () => {
    this.props.findAllPersonalInfoEmployeeForms().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState((state) => ({
        willMount: false,
      }));
    });
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  handleOnClearFilters = () => {
    this.setState({
      filtersValues: {
        dates: {
          value: 0,
          label: "Todos",
        },
        employees: {
          value: 0,
          label: "Todos",
        },
        positions: {
          value: 0,
          label: "Todos",
        },
      },
    });
  };

  handleOnChangeFilters = (key, event) => {
    const { filtersValues } = this.state;
    filtersValues[key] = event;
    this.setState({
      filtersValues,
      page: 1,
    });
  };

  handleOnOpenFormSelected = (row) => {
    console.log(row);
  };

  renderRowsFiltered = (rows) => {
    const {
      filtersValues: { dates, employees, positions },
    } = this.state;
    if (dates.value !== 0) {
      rows = rows.filter(
        (row) => moment(row.createdAt).format("YYYY") === dates.label
      );
    }
    if (employees.value !== 0) {
      rows = rows.filter((row) => row.name === employees.label);
    }
    if (positions.value !== 0) {
      rows = rows.filter((row) => row.position === positions.label);
    }
    return rows;
  };

  render() {
    const { name, parentName, cols, forms, filters, filtersOptions } =
      this.props;
    const { loading, message, willMount, page, sizePerPage, filtersValues } =
      this.state;

    console.log(forms);
    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Spin size="large" spinning={loading} tip={message}>
          <Container className="mt--6" fluid>
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de formularios completados por los colaboradores" />
                </Col>
              </Row>
            ) : forms.length ? (
              <>
                <Filters
                  title="Filtros Disponibles"
                  subtitle="Aplicación de Filtros a la lista de requerimientos"
                  filters={filters}
                  options={filtersOptions}
                  values={filtersValues}
                  onClear={this.handleOnClearFilters}
                  onChange={this.handleOnChangeFilters}
                />
                <FormsList
                  title="Oportunidades"
                  subtitle="Lista de las oportunidades en estado de cotización y cotizadas"
                  cols={cols}
                  page={page}
                  sizePerPage={sizePerPage}
                  rows={this.renderRowsFiltered(forms)}
                  onOpen={this.handleOnOpenFormSelected}
                  onSetPage={this.handleOnSetPage}
                />
              </>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay formularios completados por visualizar."
                    subtitle="Actualmente al día de hoy, no hay formulario completados para visualizar, cuando hayan formularios se enlistarán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Container>
        </Spin>
      </>
    );
  }
}

Forms.defaultProps = {
  name: "Formularios",
  parentName: "Conoce a tu Empleado",
  cols: [
    { key: "id", name: "#REF" },
    { key: "name", name: "Nombre Completo" },
    { key: "area", name: "Área" },
    { key: "position", name: "Posición" },
    { key: "completedAt", name: "Completado El" },
  ],
  forms: [],
  filters: [
    {
      key: "dates",
      name: "Año",
      placeholder: "Seleccione un año",
    },
    {
      key: "employees",
      name: "Empleado",
      placeholder: "Seleccione un empleado",
    },
    {
      key: "positions",
      name: "Posición",
      placeholder: "Seleccione una posición",
    },
  ],
  filtersOptions: {
    dates: [],
    employees: [],
    positions: [],
  },
};

Forms.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  filtersOptions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  forms: getPersonalInfoEmployeeForms(state),
  filtersOptions: getFiltersOptions(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllPersonalInfoEmployeeForms,
  })(Forms)
);
