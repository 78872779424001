/*!

=========================================================
*Inbound
=========================================================

*FormGeneral: Inbound contiene el state llamado fields, 
el cual tiene los campospara llamar al FormGeneral y 
desplegar el formulario para Ingreso de Mercancía. 
Los dields también tienen funciones con acciones para 
cuando se toca un button. 

*ModalList: En casos específicos donde se necesita abrir 
un modal para seleccionar ots, docs, en base al warehouse, 
se utiliza ese componente reutilizable.

*Props: 
    -Stage: Es un estado, el cual crea etapas en las cuales
    ciertos campos del formulario aparece según la etapa.
    Ejemplo: Etapa 1, aparece los campos 1 y 2. Etapa 2, 
    aparece los campos 3 y 4.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React, { useContext, useState, useEffect } from 'react'

//dispatch hook
import { useDispatch } from 'react-redux';

//#region Icons
import {
    MdAssignmentInd,
    MdOutlineListAlt,
    MdOutlineNumbers,
    MdOutlineComputer,
    MdWarehouse,
    MdContacts,
} from "react-icons/md";

import { IoIosSend } from "react-icons/io";

import {
    BsChatRightText,
    BsCheckLg
} from "react-icons/bs";

import {
    FaArrowRight
} from "react-icons/fa";

import {
    GrLocationPin,
    GrTransaction
} from "react-icons/gr";

import {
    SiEthiopianairlines
} from "react-icons/si";

import {
    HiOutlineClipboardDocumentList,
    HiOutlineDocumentDuplicate
} from "react-icons/hi2";

import { GiPositionMarker } from "react-icons/gi";
//#endregion

//Estados contexto
import { UserContext } from '../SharedComponents/UserContext';

//Imports de actions de consultas al API.
import {
    //PoInbound
    inboundConfirmation,
    getSupplierByPo,
    getPositionsByPo,

    //CancelationInbound
    getItemInfoByOt,
    sendRequestInbound,

} from 'actions/wms';


//Componentes reutilizables.
import { ModalList } from '../SharedComponents/ModalList';
import { FormGeneral } from '../SharedComponents/FormGeneral';

import { encrypter } from 'helpers/desencrypt.jsx';



export const Inbound = ({ stage, setStage, selectedComponent, mainView }) => {

    //States globles de contexto.
    const {
        strictedMaterialValidation,
        notValidateQuantity,
        setPlant,
        modal,
    } = selectedComponent

    //Renombrar variables
    const idComponent = selectedComponent.id;
    const title = selectedComponent.label;
    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isMobile,
        isComputer,
        options,
        notify,
        MySwal,
        warehouse,
        sapPassword
    } = useContext(UserContext);
    //#endregion

    //#region states locales

    const [openModal, setOpenModal] = useState(false)
    const [localSpinner, setLocalSpinner] = useState(false)
    const [buttonSpinner, setButtonSpinner] = useState(false)
    //State para guardar la información.
    const [newInfo, setNewInfo] = useState({})
    //La posición actual en el form.
    const [position, setPosition] = useState(0)
    //Lista de positions para validar al final.
    const [positions, setPositions] = useState([])

    //#endregion 

    //#region Efectos
    //Establecer datos iniciales.
    useEffect(() => {

        let movement = fields[idComponent].filter(field => field.movementOptions)
        movement = movement.length > 0 ? movement[0].movementOptions[0] : ""

        setNewInfo({
            plant: options.plants[0].label,
            ot: "",
            po: "",
            document: "",
            reservation: "",
            movement: movement
        })
    }, [idComponent])

    //Efecto excepción para cuando cargue el componente establezca la planta.
    useEffect(() => {
        if (options?.plants && setPlant == true) {
            //  handleOnChange("plant", options.plants[0].label, idComponent)
        }

    }, [options])

    //Efecto excepción para cuando la po sea vacía regrese al stage anterior.
    useEffect(() => {
        if ((idComponent == "po" && newInfo.po == "") ||
            (idComponent == "reservationOutboundAssets" && newInfo.document == "") ||
            (idComponent == "reservationOutboundOthers" && newInfo.reservation == "")
        ) {
            setStage(1)
            delete newInfo.numberSupplier;
            delete newInfo.nameSupplier;
        }
    }, [newInfo])
    //#endregion

    //#region ------------------------Funciones-----------------------------------------------

    //Método para extraer una PO.
    const handleSearchPo = (newInfo) => {
        if (newInfo.po) {
            // alert("consultando a sap")
            getSupplierByPoE(newInfo);
        } else {
            setOpenModal(true)
        }
    }

    //Ir al stage de posiciones (3) y extraer la información.
    const handleGoPositions = (newInfo) => {
        getPositionsByPoE(newInfo)
    }

    //Extraer Purchase Orders por plant.
    const getSupplierByPoE = (newInfo) => {
        // setSpinner(true);
        setLocalSpinner(true)
        dispatch(getSupplierByPo({ view: mainView, po: newInfo.po })).then((res) => {

            const { payload } = res;
            if (res.payload.isAxiosError) {
                console.log(payload)
            } else {
                setLocalSpinner(false);

                let apiInfo = { ...payload.data.payload };

                if (!apiInfo.numberSupplier && !apiInfo.nameSupplier) {
                    notify("warning", "Alerta", "No existe la PO en SAP.")

                } else {
                    setNewInfo(Object.assign(newInfo, apiInfo))
                    setStage(2)
                }
            }
        })
    }

    //Extraer la información de la entrada por po.
    const getPositionsByPoE = (newInfo) => {
        // setSpinner(true);
        // setLocalSpinner(true)
        setButtonSpinner(true);
        dispatch(getPositionsByPo({ view: mainView, po: newInfo.po, plant: newInfo.plant })).then((res) => {
            const { payload } = res;
            if (res.payload.isAxiosError) {
                console.log(payload)
            } else {
                // setLocalSpinner(false);
                setButtonSpinner(false)

                let apiInfo = { ...payload.data.payload };
                let firstPosition = { ...apiInfo.positions[0] };

                if (!firstPosition) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la PO en SAP.")
                } else {
                    setPositions(apiInfo.positions)
                    setNewInfo(Object.assign(newInfo, firstPosition))
                    setStage(3)
                }

            }
        })
    }

    //Validar la cantidad y picking de las posiciones.
    const validatePositions = (positions) => {

        let incorrectQuantity = false;
        let incorrectPicking = false;
        let allPositionsEmpties = positions.filter(position => !position.pickingMaterial).length == positions.length; //Valida si todas las posiciones están vacías con el material pickeado.
        positions.map(position => {

            if (position.pickingMaterial //Si se pickeo un material
                && position.pickingMaterial !== position.material // Pero los materiales son diferentes
                && !incorrectPicking // Y Validar que no se haya detectado una posición incorrecta antes.
            ) {
                incorrectPicking = 'Picking incorrecto en la posición: ' + position.position;
            }

            if (
                position.pickingMaterial //Si se pickeo un material 
                &&
                (
                    (
                        parseInt(position.quantity) > parseInt(position.pendingQuantity) // Pero las cantidad es mayor a la esperada.
                        && !incorrectQuantity // Y Validar que no se haya detectado una posición incorrecta antes.
                    )
                    ||
                    !position.quantity // O si no hay una cantidad ingresada.
                )
            ) {
                incorrectQuantity = 'Cantidad incorrecta en la posición: ' + position.position;
            }

        })



        if (positions.length == 0) {
            notify("danger", "Error", "Error de comunicación, no existen posiciones a validar.");
            return false;
        }

        if (incorrectQuantity || incorrectPicking) {
            notify("warning", "Alerta", incorrectPicking || incorrectQuantity)
            return false;
        }

        if (allPositionsEmpties) {
            notify("warning", "Alerta", "No existe ningún material pickeado.")
            return false;
        }



        return true;
    }

    //Refrescar el componente.
    const handleRefresh = (newInfo) => {
        setStage(1)
        setPositions([])
        setPosition(0)
        setNewInfo({ ...newInfo, po: "", document: "", ot: "", reservation: "" })
    }

    //Estructura de posiciones para enviar a SAP.
    const buildPositionsStructure = (positions) => {

        let structure = positions.map(position => {
            if (position.pickingMaterial && position.quantity) //Doble validación
            {
                return {
                    "EBELP": position.position,
                    "MATERIAL": position.pickingMaterial,
                    "CANTIDAD": parseInt(position.quantity)
                }
            }
        })
        structure = [
            {
                "item": structure
            }
        ]

        return structure;
    }

    //Handle para enviar la petición de ingreso de mercancía.
    const handleSendInbound = async (newInfo, positions, isComputer) => {

        let positionsConfirmated = positions.filter(position => (position.pickingMaterial && position.quantity))
        let positionsPendings = positions.filter(position => (!position.pickingMaterial))

        let positionsStructure = buildPositionsStructure(positionsConfirmated);

        if (validatePositions(positions)) {

            let positionsRowsHTML = `<h2 class="mb-3">Confirmación de posiciones</h2>`;
            positionsRowsHTML += getPositionsRowsHTML("Posiciones a confirmar", "confirm", positionsConfirmated);
            positionsRowsHTML += getPositionsRowsHTML("Posiciones pendientes", "pending", positionsPendings);

            MySwal.fire({
                html: positionsRowsHTML,
                icon: "warning",
                showCancelButton: true,
                // width: 1000,
                confirmButtonText: `Si, confirmar`,
                confirmButtonColor: "#2dce89",
                cancelButtonText: "No, cancelar",
                cancelButtonColor: "#adb5bd",
                buttonsStyling: isComputer,
                customClass: {
                    confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                    cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
                },
                className: "p-0",
                style: {
                    overflow: 'unset',
                    padding: '0px'
                }
            }).then((result) => {

                if (result.value) {
                    // setLocalSpinner(true)
                    // setspinnerCard(true);
                    setButtonSpinner(true)

                    dispatch(inboundConfirmation({ view: mainView, po: newInfo.po, plant: newInfo.plant, positionsConfirmated: positionsStructure, password: encrypter(sapPassword) })).then((resp) => {

                        const { payload } = resp;
                        // setLocalSpinner(false)
                        setButtonSpinner(false);

                        if (resp.payload.isAxiosError) {
                            notify("danger", "Atención", "Ocurrió un error al realizar la confirmación.")

                            console.log(payload)
                        } else {
                            let apiInfo = { ...payload.data.payload };

                            notify("success", "Confirmación éxitosa!", "Número de confirmación: " + apiInfo.confirmationNumber)

                            //Restablecer.
                            handleRefresh(newInfo);
                        }


                    })
                }
            });

        }

    }

    //Función para construir un html de las rows a eliminar.
    function getPositionsRowsHTML(title, type, positions) {

        let htmlReturn = `
                            <h4 class="mb--3">${title}:</h4>
                                  <div class="table-responsive ${type == 'pending' ? 'mb--3' : 'mb-2'} ">
                                
                                      <table class="align-items-left table-flush table table-striped table-hover">
                                      <thead>
                                          <tr>
                                              <th>Posición</th>
                                              <th>Material</th>
                                              <th>Cantidad</th>
                                          </tr>
                                      </thead>
                                      

                                      <tbody class="list">`;

        positions.forEach(function (position) {
            htmlReturn =
                htmlReturn +
                `<tr>
                                              <td>${position.position}</td>
                                              <td>${type == "confirm" ? position.pickingMaterial : position.material}</td>
                                              <td>${type == "confirm" ? position.quantity : position.pendingQuantity}</td>
                    </tr>`;

        });


        htmlReturn =
            htmlReturn +
            `</tbody>
                              <br>
                              </table>
                              </div>
                          `;
        return htmlReturn;
    }

    //Extraer la información de la cancelación por OT.
    const getItemInfoByOtE = (newInfo, warehouse) => {
        // setSpinner(true);
        // setLocalSpinner(true)
        setButtonSpinner(true)
        dispatch(getItemInfoByOt({ view: mainView, warehouse: warehouse, orderTransport: newInfo.ot, movement: newInfo.movement })).then((res) => {
            const { payload } = res;
            // setLocalSpinner(false);
            setButtonSpinner(false)


            if (res.payload.isAxiosError) {
                // Fallo
                console.log(payload)

                if (res.payload.response) {
                    const {
                        data: { payload }
                    } = res.payload.response;
                    notify("warning", "Atención", payload.message);

                } else {
                    notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexión con el servidor."
                    );
                }



            } else {
                // Éxito

                let apiInfo = { ...payload.data.payload };

                if (!payload.data.success) {
                    notify("warning", "Alerta", apiInfo.message)
                } else if (!apiInfo.material) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la cancelación desde SAP.")
                } else {
                    setNewInfo(Object.assign(newInfo, apiInfo))
                    setStage(2)
                }

            }
        })
    }

    //Handle para enviar la petición de cancelación de mercancía.
    const handleSendRequestInbound = async (newInfo, positions, isComputer, warehouse) => {

        if (validatePicking(newInfo)) {

            setButtonSpinner(true)
            dispatch(sendRequestInbound({ view: mainView, warehouse: warehouse, material: newInfo.material, orderTransport: newInfo.ot, movement: newInfo.movement, password: encrypter(sapPassword) })).then((resp) => {

                const { payload } = resp;
                // setLocalSpinner(false)
                setButtonSpinner(false)

                if (resp.payload.isAxiosError) {
                    notify("danger", "Atención", "Ocurrió un error al realizar la confirmación.")

                    console.log(payload)
                } else {
                    let apiInfo = { ...payload.data.payload };

                    notify("success", "Confirmación éxitosa!", "Número de confirmación: " + apiInfo.confirmationNumber)

                    //Restablecer.
                    handleRefresh(newInfo);
                }


            })


        } else {
            notify("warning", "Atención", "El material pickeado es incorrecto.")
        }

    }

    //Ir al stage de cancelación (2) y extraer la información.
    const handleGoItemInfo = (newInfo, positions, isComputer, warehouse) => {
        if (newInfo.ot) {
            getItemInfoByOtE(newInfo, warehouse)
        } else {
            notify("warning", "Atención", "Debe ingresar una OT.")
        }
    }

    //Método para extraer una OT.
    const handleSearchOt = (newInfo, positions, isComputer, warehouse, idComponent) => {
        if (newInfo.ot) {
            // alert("consultando a sap")
            if (idComponent == "cancelation") {
                getItemInfoByOtE(newInfo);
            }
        } else {
            setOpenModal(true)
        }
    }

    //Cambia el estado newInfo según el campo.
    const handleOnChange = (idField, label, idComponent) => {


        //Excepción para placa.
        if (idField == "plate") {
            if (isNaN(label.toString())) //No es sólo números.
            {
                notify("danger", "Alerta", "Debe digitar únicamente números.")
                return
            }

            if (label.length > 7) {
                notify("danger", "Alerta", "La cantidad de dígitos de la placa no debe ser mayor a 7.")
                return
            }
        }

        let stageMustChangePosition = fields[idComponent].filter(field => field.type == "positionsSelector")

        if (stageMustChangePosition.length > 0 && stageMustChangePosition[0].stage == stage //En este stage debe gestionarse las posiciones.
        ) {
            let currentPosition = positions[position];
            currentPosition = { ...currentPosition, [idField]: label }

            setPositions(

                positions.map((pos, key) => {
                    if (key == position) {
                        return currentPosition
                    }
                    return pos

                })
            )


        }

        setNewInfo({ ...newInfo, [idField]: label })

    }

    //Método para validar si el picking actual está correcto.
    const validatePicking = (newInfo) => {
        return newInfo.pickingMaterial == newInfo.material
    }

    //#endregion

    //#region State *clave* los campos de el form y vista, y están clasificados por stages (escenarios), es decir, algunos campos aparecen según el stage.
    const [fields, setFields] = useState(
        {
            //Inbound
            po: [
                {
                    id: 'plant',
                    label: "Planta",
                    width: "4",
                    type: "select",
                    options: "plants",
                    disabled: false,
                    required: true,
                    icon: MdWarehouse,
                    deleteEmptyOption: true,
                    stage: [1, 2]
                },
                {
                    id: 'po',
                    label: "PO",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchPo,
                    disabled: false,
                    required: true,
                    icon: HiOutlineClipboardDocumentList,
                    stage: [1, 2]
                },
                {
                    id: 'numberSupplier',
                    label: "Proveedor",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdAssignmentInd,
                    stage: [2]
                },
                {
                    id: 'nameSupplier',
                    label: "Nombre",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdContacts,
                    stage: [2]
                },
                {
                    id: 'btnPositions',
                    label: "Posiciones",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleGoPositions,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [2]
                },
                {
                    id: 'btnPositionsSelector',
                    label: "Posiciones",
                    width: "12",
                    type: "positionsSelector",
                    color: "success",
                    stage: [3]
                },
                {
                    id: 'po',
                    label: "Número PO",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: HiOutlineDocumentDuplicate,
                    stage: [3]
                },
                {
                    id: 'pendingQuantity',
                    label: "Pendiente",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineListAlt,
                    stage: [3]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [3]
                },
                {
                    id: 'material',
                    label: "Número Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [3]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [3]
                },
                {
                    id: 'plant',
                    label: "Ubicación",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GrLocationPin,
                    stage: [3]
                },
                {
                    id: 'pickingMaterial',
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [3]
                },
                {
                    id: 'quantity',
                    label: "Cantidad",
                    width: "4",
                    type: "number",
                    disabled: false,
                    required: true,
                    icon: MdOutlineNumbers,
                    matchNumbers: "pendingQuantity",
                    dependsEnableOf: "pickingMaterial",
                    stage: [3]
                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendInbound,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [3]
                },
            ],
            return: [
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "input",
                    movementOptions: [122],
                    disabled: true,
                    required: true,
                    icon: GrTransaction,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "OT",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchOt,
                    disabled: false,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [1]
                },
                {
                    id: 'btnOpen',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleGoItemInfo,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "Número OT",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [2]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [2]
                },
                {
                    id: 'material',
                    label: "Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2]
                },
                {
                    id: 'pickingMaterial',
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [2]
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad esperada",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [2]
                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendRequestInbound,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [2]
                },
            ],
            cancelation: [
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "input",
                    movementOptions: [102],
                    disabled: true,
                    required: true,
                    icon: GrTransaction,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "OT",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchOt,
                    disabled: false,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [1]
                },
                {
                    id: 'btnOpen',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleGoItemInfo,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "Número OT",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [2]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [2]
                },
                {
                    id: 'material',
                    label: "Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2]
                },
                {
                    id: 'pickingMaterial',
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [2]
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad esperada",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [2]
                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendRequestInbound,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [2]
                },
            ],



        }
    )
    //#endregion

    //#region consoles a borrar.
    useEffect(() => {
        console.log("New info", newInfo)
    }, [newInfo])

    useEffect(() => {
        console.log("IdComponent", idComponent)
    }, [idComponent])

    useEffect(() => {
        console.log("Positions", positions)
    }, [positions])

    //#endregion
    return (
        <>

            <ModalList
                id={idComponent}
                title={modal.title}
                subtitle={modal.subtitle}
                spinnerText={modal.spinnerText}
                searchText={modal.searchText}
                mainView={mainView}


                openModal={openModal}
                setOpenModal={setOpenModal}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                getSupplierByPoE={getSupplierByPoE}

            //Outbound
            //getPositionsByDocE={getPositionsByDocE}

            //Reservation
            // getReservationActiveByDocE={getReservationActiveByDocE}
            // getMovementByReservationE={getMovementByReservationE}

            />


            <FormGeneral
                fields={fields}
                stage={stage}
                isMobile={isMobile}
                title={title}
                position={position}
                setPositions={setPositions}
                buttonSpinner={buttonSpinner}
                localSpinner={localSpinner}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                positions={positions}
                setPosition={setPosition}
                isComputer={isComputer}
                warehouse={warehouse}
                idComponent={idComponent}
                handleOnChange={handleOnChange}
                options={options}
                notValidateQuantity={notValidateQuantity}
                strictedMaterialValidation={strictedMaterialValidation}
            />

        </>
    )
}
