/*!

=========================================================
*Autopp - CreateLDR
=========================================================

*Ventana de mantenimiento para crear o editar un formulario de 
LDR de forma dinámica, tiene librería claves como react beautifull
para poder arrastrar los fields de los campos.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de React
import React, { useState, useEffect, useContext, useRef, } from 'react';
import {
  FormGroup,
  CardBody,
  Button,
  Input,
  Card,
  Row,
  Col,
} from "reactstrap";

// core antd
import { Tabs } from 'antd';

//Import de contexto para extraer states globales.
import { UserContext } from "../../SharedComponents/UserContext";

//Componente para desplegar un campo select.
import Select from "react-select";

//React beatiful para arrastrar elementos 
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//Usado para generar la vista previa del form.
import { LDRItemForm } from 'components/AutoppLdrs/LDRForms/LDRItemForm';

// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';

import Swal from 'sweetalert2';

import { ModalSendForm } from './ModalSendForm';

const { TabPane } = Tabs;

export const CreateLDR = ({ options, type, infoLDRToEdit, setRefreshOptions }) => {

  useEffect(() => {

    if (type == "edit") {
      setFields(adjustedFields())
    }
  }, [])

  //State contador usado para asignar los ids temporales a cada campo nuevo. Ej: input1, input2...
  const [countersId, setCountersId] = useState(

    {
      "mainTitle": 0,
      "mainSubtitle": 0,
      "title": 0,
      "subtitle": 0,
      "input": 0,
      "textarea": 0,
      "select": 0,
      "multiselect": 0
    }
  )


  //Método para moldear la data extraída
  const adjustedFields = () => {

    const defineMainTitle = {
      id: "title",
      type: "mainTitle",
      name: "título",
      label: infoLDRToEdit.label,
      placeholder: "Título del LDR.",
      required: 1,
      disabled: 0,
      colWidth: "6",
      dependsAnotherDropdown: 0,
      idDropdownDepends: "",
      valueThatDepends: "",

    };

    //Establece los tipos de opciones (text, number, date, textarea)
    let inputTypeValues = options.inputType.map(option => { return option.value });

    let toReturn = infoLDRToEdit.fields.map(field => ({
      ...field,
      name: buttonsOptions.filter(button => button.id == field.type)[0]?.name
    }))
      .map(field => {

        //En caso que sea (text, number, date, textarea)
        if (inputTypeValues.includes(field.type)) {
          return {
            ...field,
            name: "campo abierto",
            type: "input",
            inputType: field.type,
            // id: extractTextBeforeNumber(field.id)

          }
        } else {
          //Los demás de tipo select, multiselect, titles, etc.
          let nameSearch = buttonsOptions.filter(button => button.id == field.type)[0]?.name;

          return {
            ...field,
            name: nameSearch ? nameSearch : field.type,
            // id: extractTextBeforeNumber(field.id)
          }
        }
      }

      )

    //Acomodar el dependsAnotherDropdown con el formato
    toReturn = toReturn.map(field => {
      if (field.dependsAnotherDropdown == 1) {

        return {
          ...field,
          valueThatDepends: {
            ...field.valueThatDepends,

            label:
              field?.valueThatDepends?.label != '' ? // Se fija si tiene título
                getNumberField(field) + field?.valueThatDepends?.value + " - " + field?.valueThatDepends?.label //Tiene asignado un título.
                : getNumberField(field) + " sin nombre - " + field?.valueThatDepends?.label,
            lableSingle: field?.valueThatDepends?.label
          }
        }
      }

      return field;
    });

    //#region Establecer el contador de variables;
    // debugger;
    let countersIdE = { ...countersId }

    Object.keys(countersIdE).map(typeId => {
      let quantity = 1;

      toReturn.map(field => {
        if (field.type == typeId) {
          quantity++;
        }
      })


      countersIdE = { ...countersIdE, [typeId]: quantity }
    })

    setCountersId(countersIdE);

    //#endregion


    console.log("countersIdE", countersIdE)

    console.log("toReturn", toReturn)


    return [defineMainTitle, ...toReturn];

  }

  // useEffect(() => {
  //   console.log("countersId", countersId);
  // }, [countersId]);


  //#region States Globales
  const {
    setShowComponent
  } = useContext(UserContext);
  //#endregion

  //Cual tab está activo.
  const [activeTab, setActiveTab] = useState('1');

  //#region Notificaciones

  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  //#endregion


  const [statusFields, setStatusFields] = useState({});


  const handleMouseEnter = (id) => {

    setStatusFields((prevStatusFields) => {
      const updatedFields = {
        ...prevStatusFields,
        [id]: { isHovered: true, type: "card" }
      };

      // Establecer isHovered en false para los demás campos
      Object.keys(updatedFields).forEach((fieldId) => {
        if (fieldId !== id) {
          updatedFields[fieldId].isHovered = false;
        }
      });

      return updatedFields;
    });
  };

  const handleMouseLeave = (id) => {

    setStatusFields({
      ...statusFields,
      [id]: { isHovered: false, type: "card" }

    });
  };

  //Cambia la vista del card, sea de edición ó de solo mostrar el campo.
  const handleChangeTypeField = (id, type) => {

    let allFields = [];

    if (Object.keys(statusFields).length > 0) {
      allFields = Object.keys(statusFields).reduce((acc, key) => {
        acc[key] = {
          ...statusFields[key],
          type: 'card',
        };
        return acc;
      }, {});
    }


    allFields = {
      ...allFields,
      [id]: { ...allFields[id], type: type }

    }

    setStatusFields(allFields)

  }



  const cardRef = useRef(null);

  //Validar si se hizo click fuera del card para desactivar el color background del card.
  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      // Se hizo clic en un lugar fuera del Card
      // console.log('Se hizo clic fuera del Card');
    }
  };


  //Handle para agregar toda la info de los forms al state fields.
  const handleNewInfo = (field, value) => {

    let updatedFields = fields.map((fieldM) => {
      if (fieldM.id === field.id) {
        return {
          ...fieldM,
          label: value,
        };
      }
      return fieldM;
    });

    //Limpia los campos que indican que la opción actual a eliminar era disparadora de si aparezca el otro campo.
    updatedFields = updatedFields.map(fieldU => {
      if (fieldU?.valueThatDepends?.id == field.id) {
        return {
          ...fieldU,
          valueThatDepends: {
            ...fieldU.valueThatDepends,
            label: getNumberField(field) + value + " - " + fieldU.valueThatDepends.lableSingle,
          }
        }
      }

      return fieldU;
    });

    setFields(updatedFields);

  }





  //State para expandir el botón de opciones o no. 
  const [expandButton, setExpandButton] = useState(false)


  // State *PRINCIPAL* donde almacenan los campos del form a crear.
  const [fields, setFields] = useState([

    {
      id: "title" + Date.now().toString(36),
      type: "mainTitle",
      name: "título",
      label: "",
      placeholder: "Título del LDR.",
      required: 1,
      disabled: 0,
      colWidth: "6",
      dependsAnotherDropdown: 0,
      idDropdownDepends: "",
      valueThatDepends: "",

    },
    {
      id: "input" + Date.now().toString(36),
      type: "input",
      name: " campo abierto",
      label: "",
      placeholder: "Escriba un título del input",
      required: 1,
      disabled: 0,
      inputType: "text",
      colWidth: "6",
      dependsAnotherDropdown: 0,
      idDropdownDepends: "",
      valueThatDepends: "",


    },
    {
      id: "select" + Date.now().toString(36),
      type: "select",
      name: "selección",
      label: "",
      placeholder: "Escriba un título del select",
      required: 1,
      disabled: 0,
      options: [{ label: "", value: "" }],
      colWidth: "6",
      dependsAnotherDropdown: 0,
      idDropdownDepends: "",
      valueThatDepends: "",

    },
  ])


  //Agrega campos al formulario dependiendo del tipo de button
  const handleManageButtons = (button) => {

    //Agrega el campo al form
    setFields(prevState => (
      button.id == 'input' ?
        [
          ...prevState,
          {
            // id: button.id + (parseInt(countersId[button.id]) + 1),
            id: button.id + Date.now().toString(36),
            type: button.id,
            required: 1,
            disabled: 0,
            label: "",
            name: button.name,
            placeholder: "Ingrese el título del " + button.name.toLowerCase(),
            inputType: "text", //por defecto poner el input tipo texto,
            colWidth: "6",
            dependsAnotherDropdown: 0,
            idDropdownDepends: "",
            valueThatDepends: "",


          }
        ] :
        (button.id == 'select' || button.id == 'multiselect') ?
          [
            ...prevState,
            {
              // id: button.id + (parseInt(countersId[button.id]) + 1),
              id: button.id + Date.now().toString(36),
              type: button.id,
              required: 1,
              disabled: 0,
              label: "",
              name: button.name,
              placeholder: "Ingrese el título del " + button.name.toLowerCase(),
              options: [{ label: "", value: "" }],
              max: 100,
              colWidth: "6",
              dependsAnotherDropdown: 0,
              idDropdownDepends: "",
              valueThatDepends: "",
            }
          ] : //titulo y subtitulo
          [
            ...prevState,
            {
              // id: button.id + (parseInt(countersId[button.id]) + 1),
              id: button.id + Date.now().toString(36),
              type: button.id,
              required: 1,
              disabled: 0,
              label: "",
              name: button.name,
              placeholder: "Ingrese el nombre del " + button.name.toLowerCase(),
              colWidth: "12",
              dependsAnotherDropdown: 0,
              idDropdownDepends: "",
              valueThatDepends: "",
            }
          ]

    ))

    //Este state lleva un registro de un contador por cada tipeField (text, select, etc), para asignar un id temporal a cada field. Ejm: input1, input2, input3...
    setCountersId(prevState => ({
      ...prevState,
      [button.id]: parseInt(prevState[button.id]) + 1
    }));

  }

  //A la par del campo hay un icono de basurero, este metodo elimina el campo al tocar ese botón.
  const handleDeleteField = (idField) => {
    let updatedFields = fields.filter(field => field.id != idField)

    //Limpia los campos que indican que la opción actual a eliminar era disparadora de si aparezca el otro campo.
    updatedFields = updatedFields.map(fieldU => {
      if (fieldU?.valueThatDepends?.id == idField) {

        return {
          ...fieldU,
          dependsAnotherDropdown: false,
          idDropdownDepends: "",
          valueThatDepends: "",
        }
      }

      return fieldU;
    });

    setFields(updatedFields)
  }

  //Handle para seleccionar si un campo es obligatorio o no
  const handleRequiredField = (idToggle) => {

    const updatedFields = fields.map((field) => {
      if (field.id === idToggle) {

        return {
          ...field,
          required: !field.required == true ? 1 : 0,
        };
      }
      return field;
    });

    setFields(updatedFields);
  }

  //Handle para seleccionar si un campo depende de otro campo o no.
  const handleDependsAnotherDropdown = (idToggle) => {

    const updatedFields = fields.map((field) => {
      if (field.id === idToggle) {

        if (field.dependsAnotherDropdown) //Significa que estaba en true, o sea estaba en si y ya va a pasar a no
        {
          field.idDropdownDepends = "";
          field.valueThatDepends = "";

        }
        return {
          ...field,
          dependsAnotherDropdown: (!field.dependsAnotherDropdown == true ? 1 : 0),
        };
      }
      return field;
    });

    setFields(updatedFields);
  }

  //Handle para cambiar los radio buttons de los input.
  const handleOnChangeRadioButton = (idField, radioButton) => {

    const updatedFields = fields.map((field) => {
      if (field.id === idField && radioButton.value != 'textarea') {
        return {
          ...field,
          type: "input",
          inputType: radioButton.value,
        };
      } else if (field.id === idField && radioButton.value == 'textarea') {
        return {
          ...field,
          type: radioButton.value,
          inputType: radioButton.value,

        };
      }
      return field;
    });

    setFields(updatedFields);

  }

  //Handle para agregar un nuevo campo de opción al field.
  const handleAddOptionSelect = (field) => {
    // debugger;
    const updatedFields = fields.map((fieldOption) => {
      if (fieldOption.id === field.id) {

        if (fieldOption?.options != null) //Tiene opciones
        {
          return {
            ...fieldOption,
            options: [...fieldOption?.options, { label: "", value: "" }],
          };
        } else //No tiene ni una opción
        {
          return {
            ...fieldOption,
            options: [{ label: "", value: "" }],
          };

        }

      }
      return fieldOption;
    });

    setFields(updatedFields);
  }

  //State para desplegar los botones de las opciones y sus atributo
  const [buttonsOptions, setButtonsOptions] = useState([
    {
      id: "input",
      color: "primary",
      className: "p-2 ml-3",
      classNameIcon: "fa fa-text-width",
      label: "Campo abierto",
      name: "Campo abierto"
    },
    {
      id: "select",
      color: "primary",
      className: "p-2 mt-0",
      classNameIcon: "fa fa-list",
      label: "Selección",
      name: "selección"
    },
    {
      id: "multiselect",
      color: "primary",
      className: "p-2",
      classNameIcon: "fa fa-list",
      label: "Multiselección",
      name: "Multiselección"
    },
    {
      id: "title",
      color: "warning",
      className: "p-2",
      classNameIcon: "fa fa-clone",
      label: "Sección",
      name: "título"
    },
    {
      id: "subtitle",
      color: "warning",
      className: "p-2",
      classNameIcon: "fa fa-clone",
      label: "Subsección",
      name: "subtítulo"
    },

  ])

  //Handle para agregar el valor al option
  const handleAddValueSelectOption = (indexfield, field, indexOption, value) => {

    //Extrae una copia de las options del presente field select
    let options = [...field.options];

    //En base al index del option le agrega el value y su label
    options[indexOption].label = value
    options[indexOption].value = field.id + "Option" + indexOption;

    //Extrae una copia de fields para editar.
    let fieldsUpdated = [...fields];

    //Asigna las nuevas options al index del field, es decir donde está el presente select.
    fieldsUpdated[indexfield].options = options;

    //Limpia los campos que indican que la opción actual a eliminar era disparadora de si aparezca el otro campo.
    fieldsUpdated = fieldsUpdated.map(fieldU => {
      if (fieldU?.valueThatDepends?.value == (field.id + "Option" + indexOption /*Value*/)) {

        return {
          ...fieldU,
          valueThatDepends: {
            ...fieldU.valueThatDepends,

            label:
              field.label != '' ? // Se fija si tiene título
                getNumberField(field) + field.label + " - " + value //Tiene asignado un título.
                : getNumberField(field) + " sin nombre - " + value,
            lableSingle: value
          }
        }
      }

      return fieldU;
    });


    //Asigna los fields actualizados.
    setFields(fieldsUpdated);


  }

  //Handle para eliminar una option de un select 
  const handleDeleteSelectOption = (indexfield, field, indexOption, option) => {
    debugger;

    //Extrae una copia de las options del presente field select
    let options = [...field.options];

    //En base al index del option le agrega el value y su label
    options = options.filter(opt => opt != option);

    //Extrae una copia de fields para editar.
    let fieldsUpdated = [...fields];

    //Asigna las nuevas options al index del field, es decir donde está el presente select.
    fieldsUpdated[indexfield].options = options;

    // //Modifica los campos que indican que la opción actual a eliminar era disparadora de si aparezca el otro campo.
    fieldsUpdated = fieldsUpdated.map(fieldU => {

      if (fieldU?.valueThatDepends?.value == option.value) {
        return {
          ...fieldU,
          dependsAnotherDropdown: false,
          idDropdownDepends: "",
          valueThatDepends: ""
        }
      }
      return fieldU;
    });



    //Asigna los fields actualizados.
    setFields(fieldsUpdated);


  }

  //Genera los campos para el field
  const generateOptions = (field, indexField) => {

    // console.log("Este es el field", field)
    let optionsToShow = []
    let fieldsWithSelect = fields.filter(fieldA => ((fieldA.type == "select" || fieldA.type == "multiselect") && fieldA.id != field.id));

    //Recorre los fields conseguidos
    fieldsWithSelect.map((fieldA, fieldI) => {

      //Recorre las options de cada field y los agrega a optionsToShow
      fieldA.options.map(opt => {
        optionsToShow = [...optionsToShow,
        {
          label:
            fieldA.label != '' ? // Se fija si tiene título
              // (indexField) + ". " + fieldA.label + " - " + opt.label //Tiene asignado un título.
              // : (indexField) + ". sin nombre - " + opt.label,
              getNumberField(fieldA) + fieldA.label + " - " + opt.label //Tiene asignado un título.
              : getNumberField(fieldA) + " sin nombre - " + opt.label,

          value: opt.value, //No tiene asignado un título aún.
          id: fieldA.id,
          lableSingle: opt.label
        }]
      })
    }
    )

    return optionsToShow
  }

  //Handle para indicar si el aparecer del campo actual depende de la selección de otro campo.
  const handleChangeDependsAnotherDropdown = (field, e) => {

    const updatedFields = fields.map((fieldOption) => {
      if (fieldOption.id === field.id) {
        return {
          ...fieldOption,
          idDropdownDepends: e.id,
          valueThatDepends: e
        };
      }
      return fieldOption;
    });

    setFields(updatedFields);

  }

  //Actualiza el atributo label de valueThatDepends con el nuevo index. 
  //Ej: '4- campo abierto - opción dependente' -> '2 - campo abierto - opción dependiente'
  const updateNamesFields = (index, indexDestination, fieldsList) => {
    // debugger;
    const theField = fieldsList[indexDestination];

    const updatedFields = fieldsList.map((fieldOption) => {
      if (fieldOption.idDropdownDepends === theField.id) {
        return {
          ...fieldOption,
          valueThatDepends: {
            ...fieldOption.valueThatDepends,

            label:
              theField.label != '' ? // Se fija si tiene título
                getNumberField(theField, fieldsList) + theField.label + " - " + fieldOption.valueThatDepends.lableSingle //Tiene asignado un título.
                : getNumberField(theField, fieldsList) + " sin nombre - " + fieldOption.valueThatDepends.lableSingle,
          }
        };
      }
      return fieldOption;
    });

    return updatedFields;

  }

  //Devuelve el número de campo sin tomar en cuenta los titulos y subtitulos
  const getNumberField = (field, fieldsList) => {

    //Si la lista del parámetro es válida, tome esa, sino la lista fields
    let fieldsL = fieldsList != undefined ? fieldsList : fields;

    let number = 0;

    //Filtra solo los input select y multiselect
    const fieldsFiltered = fieldsL.filter(fieldF => (fieldF.type == 'input' || fieldF.type == 'textarea' || fieldF.type == 'select' || fieldF.type == 'multiselect'));

    //Averigua en cual posición esta el field en los filtrados
    fieldsFiltered.map((fieldM, fieldI) => {
      if (fieldM.id == field.id) {
        number = fieldI
      }
    })

    return (field.type != "title" && field.type != "subtitle" && field.type != "mainSubtitle" && field.type != "mainTitle") ?
      (number + 1) + ". " :
      "";

  }

  //Método para reordenar la lista de campos 
  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  //#region Métodos de creación o modificación del LDR.

  const [openModal, setOpenModal] = useState(false);

  //Abre el modal para enviar la petición.
  const openToSendPetition = () => {

    if (validateFields()) {
      setOpenModal(true)
    }

  }

  //Cuando se toque guardar, valide si el formulario está lleno.
  const validateFields = () => {

    let existFieldsWithoutTitle = fields.filter(field => field.label.replace(/\s/g, '') == "");

    let selectWhithoutOptions =
      fields.filter(field => (field.type == "select" || field.type == "multiselect")) //=> Identifica los select y multiselect
        .filter(field =>  //=> Recorre cada select y multiselect
        // (field.options.length == 0 || //=> Valida si todos tienen al menos una opción
        (
          (field?.options == null) ||
          (field?.options?.length == 0) || //=> Valida si todos tienen al menos una opción

          field.options.filter(option => option.label.replace(/\s/g, '') == "").length > 0) //=> Valida que en cada opción tenga un texto.
        )
    console.log("Fields", fields)
    let fieldsDependsAnotherOptionsWhithoutId =
      fields.filter(field => field.dependsAnotherDropdown == 1) //=> Localiza los que dependen de otro
        .filter(field =>
          (field.valueThatDepends == "") //=> Localiza los que tienen la opción vacía.
          || (!field.valueThatDepends?.lableSingle) //=> Es indefinido 
        )

    let quantityFields =
      fields.filter(field => field.type != "mainTitle") //=> Cuenta los fields excepto el mainTitle

    if (fields[0].label.replace(/\s/g, '') == "") //=> Valida si tiene título.
    {
      Toast.fire({
        title: "Atención",
        html: "Antes de guardar, debe asignar un título al LDR.",
        type: 'warning'
      });
      return false;

    } else if (existFieldsWithoutTitle.length > 0) //=> Valida si hay campos sin título.
    {
      Toast.fire({
        title: "Atención",
        html: `Existen ${existFieldsWithoutTitle.length} campos que no poseen título, por favor revisar.`,
        type: 'warning'
      });
      return false;

    } else if (selectWhithoutOptions.length > 0) //=> Valida selects y multiselects sin options.
    {
      Toast.fire({
        title: "Atención",
        html: `Existen ${selectWhithoutOptions.length} campo(s) tipo selección sin opciones o vacías para escoger, por favor revisar.`,
        type: 'warning'
      });
      return false;

    } else if (fieldsDependsAnotherOptionsWhithoutId.length > 0) //=> Valida selects y multiselects sin options.
    {
      Toast.fire({
        title: "Atención",
        html: `Existen ${fieldsDependsAnotherOptionsWhithoutId.length} campos que indica que depende de otro campo pero sin indicar el campo que depende, por favor revisar.`,
        type: 'warning'
      });
      return false;

    } else if (quantityFields.length == 0) //=> Valida si no hay ningún campo excepto el título
    {
      Toast.fire({
        title: "Atención",
        html: `Antes de guardar, debe agregar al menos un campo al LDR.`,
        type: 'warning'
      });
      return false;

    }



    return true;
  }

  //#endregion





  //Handle para volver al inicio de la vista.
  const handleReturnToStart = () => {

    setShowComponent({ dinamycForm: false, brandsMaintenance: false })

  }


  return (
    <>
      <ModalSendForm
        options={options}
        openModal={openModal}
        setOpenModal={setOpenModal}
        type={type}
        fields={fields}
        handleReturnToStart={handleReturnToStart}
        infoLDRToEdit={infoLDRToEdit}
        setRefreshOptions={setRefreshOptions}

      >

      </ModalSendForm>

      <Row className=" " >
        <Col sm="9" className="mx-auto">
          <div>
            <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
              <TabPane
                tab={
                  <span>
                    <span className="btn-inner--icon mb-2 mr-2">
                      <i className="fa fa-edit" aria-hidden="true" />
                    </span>
                    {type === 'edit' ? 'Editar' : 'Crear Formulario'}
                  </span>
                }
                key="1">

                <Card>
                  <CardBody>
                    <div onClick={handleClickOutside}>


                      <DragDropContext
                        onDragEnd={(result) => {
                          const { source, destination } = result;
                          // console.log("DATOS", source, destination);
                          if (!destination ||
                            source.index == 0 || destination.index == 0 //||Para que no mueva el título del LDR
                          ) {
                            return;
                          }
                          if (
                            source.index === destination.index &&
                            source.droppableId === destination.droppableId
                          ) {
                            return;
                          }

                          let toUpdate = reorder(fields, source.index, destination.index);

                          toUpdate = updateNamesFields(source.index, destination.index, toUpdate);

                          setFields(toUpdate);
                        }}
                      >
                        <Droppable droppableId="fields">
                          {(droppableProvided) => (
                            <div
                              {...droppableProvided.droppableProps}
                              ref={droppableProvided.innerRef}
                              className="field-container"
                            >
                              {fields.map((field, iField) => (
                                <Draggable key={field.id} draggableId={field.id} index={iField}>
                                  {(draggableProvided) => (
                                    <div
                                      {...draggableProvided.draggableProps}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.dragHandleProps}
                                      className="field-item"
                                    >

                                      {
                                        (statusFields[field.id]?.type == "card" || statusFields[field.id]?.type == undefined) ?
                                          <div>
                                            {/* Card de frente */}
                                            <Card
                                              ref={cardRef}
                                              id={field.id}
                                              className="mt--3 field-item"
                                              style={{ backgroundColor: statusFields[field.id]?.isHovered && '#e5f8e1' }}
                                              onMouseEnter={(e) => handleMouseEnter(field.id)}
                                              onMouseLeave={(e) => handleMouseLeave(field.id)}
                                              onClick={() => handleChangeTypeField(field.id, "input")}
                                            >

                                              <CardBody
                                                className="border p-2"
                                              >

                                                {field.type != 'mainTitle' && field.type != 'mainSubtitle' &&

                                                  <Row>
                                                    <Col sm="12" style={{ display: "flex", justifyContent: "center" }}>
                                                      {/* Icono del arrastre */}
                                                      <span className="btn-inner--icon mr-1 mb--2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '20px', height: '20px', fill: statusFields[field.id]?.isHovered ? 'gray' : 'white' }} viewBox="0 0 24 24" class="-lS-177"><path d="M7 16a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm0-8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm7 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm0-8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm7 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm0-8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"></path></svg>
                                                      </span>
                                                    </Col>
                                                  </Row>
                                                }

                                                {field.type == "mainTitle" ?
                                                  <h2 className=''>{field.label != '' ? field.label : field.placeholder}</h2>
                                                  : field.type == "mainSubtitle" ?
                                                    <p>{field.label != '' ? field.label : field.placeholder}</p>

                                                    : field.type == "title" ?


                                                      <Row>
                                                        <Col sm="11" className="">

                                                          <h2 style={{ textAlign: 'center' }}> {field.label != '' ?
                                                            field.label
                                                            : "Toque para editar el título"}</h2>
                                                        </Col>
                                                        <Col sm="1" className="" style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                                          <a className=''>
                                                            <span className="btn-inner--icon mt-5" onClick={() => handleDeleteField(field.id)}>
                                                              <i className={"fa fa-trash"} aria-hidden="true" />
                                                            </span>
                                                          </a>
                                                        </Col>

                                                      </Row>

                                                      : field.type == "subtitle" ?


                                                        <Row>
                                                          <Col sm="11" className="">
                                                            <h4 style={{ textAlign: 'center' }}>  {field.label != '' ?
                                                              field.label
                                                              : "Toque para editar el subtítulo"}</h4>
                                                          </Col>
                                                          <Col sm="1" className="" style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                                            <a className=''>
                                                              <span className="btn-inner--icon mt-5" onClick={() => handleDeleteField(field.id)}>
                                                                <i className={"fa fa-trash"} aria-hidden="true" />
                                                              </span>
                                                            </a>
                                                          </Col>

                                                        </Row>


                                                        : (field.type == "input" || field.type == 'textarea') ?
                                                          <>
                                                            <Row>
                                                              <Col sm="10">
                                                                <h5 >{getNumberField(field)} {field.label != '' ?
                                                                  (field.required == true ? field.label + '*' : field.label)
                                                                  : "Toque para editar el nombre"}
                                                                </h5>
                                                              </Col>



                                                            </Row>


                                                            <Row>
                                                              <Col sm="7">
                                                                <Input
                                                                  className="form-control text-dark mb-3"
                                                                  type={"input"}
                                                                  readOnly={true}
                                                                />
                                                              </Col>

                                                              <Col sm="5" className="" style={{ display: "flex", justifyContent: "flex-end" }}>


                                                                <a className='mt-5'  >
                                                                  <span className="btn-inner--icon mt-5" onClick={() => handleDeleteField(field.id)}>
                                                                    <i className={"fa fa-trash"} aria-hidden="true" />
                                                                  </span>
                                                                  <span className="btn-inner--text mt-5">   |   </span>

                                                                  <span className="btn-inner--icon mt-5">
                                                                    <i className={"fa fa-info-circle"} aria-hidden="true" />
                                                                  </span>
                                                                  <span className="btn-inner--text mt-5"> {" " + field.name}</span>
                                                                </a>
                                                              </Col>

                                                            </Row>


                                                          </>
                                                          : field.type == "select" || field.type == "multiselect" ?
                                                            <>
                                                              <h5 > {getNumberField(field)}{field.label != '' ?
                                                                (field.required == true ? field.label + '*' : field.label)
                                                                : "Toque para editar el nombre"}
                                                              </h5>
                                                              <Row>
                                                                <Col sm="7">
                                                                  <Input
                                                                    className="form-control text-dark mb-3"
                                                                    type={"select"}
                                                                    readOnly={true}
                                                                  />
                                                                </Col>

                                                                <Col sm="5" className="" style={{ display: "flex", justifyContent: "flex-end" }}>


                                                                  <a className='mt-5'  >

                                                                    <span className="btn-inner--icon mt-5" onClick={() => handleDeleteField(field.id)}>
                                                                      <i className={"fa fa-trash"} aria-hidden="true" />
                                                                    </span>

                                                                    <span className="btn-inner--text mt-5"> | </span>

                                                                    <span className="btn-inner--icon mt-5">
                                                                      <i className={"fa fa-info-circle"} aria-hidden="true" />
                                                                    </span>
                                                                    <span className="btn-inner--text mt-5"> {" " + field.name}</span>
                                                                  </a>
                                                                </Col>

                                                              </Row>





                                                            </>
                                                            : <>Desconocido</>

                                                }

                                              </CardBody>
                                            </Card>
                                          </div>
                                          :
                                          <div>
                                            {/* Card de edición */}

                                            <Card
                                              style={{ backgroundColor: '' }}
                                              className="p-2"

                                            >



                                              <FormGroup
                                                className="border"
                                              >

                                                <Row>
                                                  <Col xs="12" sm="10" style={{ display: "flex", justifyContent: "start" }}>
                                                    <h4> {getNumberField(field) + " "}Editar {field.name.toLowerCase()}</h4>
                                                  </Col>

                                                  {field.type !== "mainTitle" && field.type !== "mainSubtitle" &&
                                                    <Col xs="12" sm="auto" className="p-0 ml-auto mr-3" style={{ display: "flex", alignItems: "flex-end" }}>
                                                      <Button color="success" className="p-1" onClick={() => handleChangeTypeField(field.id, "card")}>
                                                        <span className="btn-inner--icon mb-0">
                                                          <i className="fa fa-check" aria-hidden="true" />
                                                        </span>
                                                      </Button>
                                                      <Button color="danger" className="p-1" onClick={() => handleDeleteField(field.id)}>
                                                        <span className="btn-inner--icon">
                                                          <i className="fa fa-trash" aria-hidden="true" />
                                                        </span>
                                                      </Button>
                                                    </Col>
                                                  }
                                                </Row>


                                                <Row>





                                                  <Col sm="7">

                                                    <Input
                                                      className="form-control text-dark"
                                                      placeholder={field.placeholder}
                                                      value={field.label}
                                                      onChange={(e) => handleNewInfo(field, e.target.value)}
                                                    />
                                                    <div className="invalid-feedback">
                                                      Este campo no puede estar vacío
                                                    </div>
                                                  </Col>


                                                  {(field.type == "mainTitle" || field.type == "mainSubtitle") &&
                                                    <Col sm="1">

                                                      <Button color="success"
                                                        className="p-2 ml--2"
                                                        onClick={() => handleChangeTypeField(field.id, "card")}
                                                      >

                                                        <span className="btn-inner--icon mb-2">
                                                          <i className={"fa fa-check"} aria-hidden="true" />
                                                        </span>

                                                      </Button>
                                                    </Col>}
                                                </Row>
                                                <Row>


                                                  {field.type != 'mainTitle' && field.type != 'mainSubtitle' && field.type != 'subtitle' && field.type != 'title' &&

                                                    <Col xs="12" sm="auto" key={field.id + "col"} className="mt-3 ml-2 mr--2 pr-0" style={{ display: "flex", justifyContent: "center" }}>


                                                      <label ><strong> Obligatorio </strong></label>

                                                      <label className="custom-toggle custom-toggle-success  mr-2 ml-1" >
                                                        <input
                                                          key={field.id + "inputcheckbox"}
                                                          checked={field.required}
                                                          className=''
                                                          id="edit2"
                                                          type="checkbox"
                                                          onChange={(e) => handleRequiredField(field.id)}
                                                        >
                                                        </input>
                                                        <span id={field.id + "Toggle"} className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Si"></span>
                                                      </label>




                                                    </Col>}



                                                  {field.type != 'mainTitle' && field.type != 'mainSubtitle' &&
                                                    // DEPENDE DE OTRO CAMPO
                                                    <Col xs="12" sm="7" key={field.id + "col"} className="mt-3 ml-1" style={{ display: "flex", justifyContent: "start" }}>


                                                      <label ><strong> ¿Depende de otro campo? </strong></label>

                                                      <label className="custom-toggle custom-toggle-success ml-2 mr-2" >
                                                        <input
                                                          key={field.id + "inputcheckbox"}
                                                          checked={field.dependsAnotherDropdown}
                                                          className=''
                                                          id="edit"
                                                          type="checkbox"
                                                          onChange={(e) => handleDependsAnotherDropdown(field.id)}
                                                        >
                                                        </input>
                                                        <span id={field.id + "Toggle"} className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Si"></span>
                                                      </label>




                                                    </Col>




                                                  }

                                                  {field.dependsAnotherDropdown == true &&
                                                    <Col className="text-right ml-2 mt-3" xs="12" sm={"7"}>


                                                      <FormGroup>
                                                        <Select

                                                          isSearchable
                                                          className="basic-single"
                                                          id=""
                                                          type=""
                                                          placeholder={"Seleccione el campo dependiente"}
                                                          options={generateOptions(field, iField)}
                                                          value={field.dependsAnotherDropdown ? field.valueThatDepends : ""}
                                                          onChange={(e) => handleChangeDependsAnotherDropdown(field, e)}
                                                        />
                                                      </FormGroup>




                                                    </Col>

                                                  }
                                                </Row>
                                                {
                                                  (field.type == 'select' || field.type == 'multiselect') &&

                                                  //OPCIONES
                                                  <>
                                                    <hr align="left" width={"58%"} xs="12" className='mt-2 mb-4' />
                                                    <Row>
                                                      <Col xs="12" sm="auto" key={iField} className="mt--3 ml-3" style={{ display: "flex", justifyContent: "center" }}>

                                                        <h4>Opciones</h4>


                                                      </Col>
                                                    </Row>

                                                    {/* Map de opciones del field */}
                                                    {field.options?.map((option, indexOption) => (

                                                      <Row>


                                                        {/* Aqui se despliegan las opciones  */}
                                                        <Col sm="6" className="pr-0 ">

                                                          <Input
                                                            id={field.id + "Option" + indexOption}
                                                            className="form-control text-dark"
                                                            placeholder={"Ingrese la opción"}
                                                            value={option?.label}
                                                            onChange={(e) => handleAddValueSelectOption(iField, field, indexOption, e.target.value)}
                                                          />
                                                        </Col>
                                                        <Col sm="1" className="pl--3">
                                                          <Button color="danger" size="" outline className="mt-1"
                                                            onClick={() => handleDeleteSelectOption(iField, field, indexOption, option)}
                                                          >
                                                            <span className="btn-inner--icon ">
                                                              <i className={"fa fa-trash"} aria-hidden="true" />
                                                            </span>


                                                          </Button>
                                                        </Col>

                                                      </Row>
                                                    ))

                                                    }
                                                    <Row>

                                                      <Col xs="12" sm="auto" key={iField} className="mt-2 ml-7" style={{ display: "flex", justifyContent: "center" }}>

                                                        <Button color="success" size="" outline

                                                          onClick={() => handleAddOptionSelect(field)}>
                                                          <span className="btn-inner--icon mr-1">
                                                            <i className={"fa fa-plus"} aria-hidden="true" />
                                                          </span>

                                                          <span className="btn-inner--text">  Nueva opción  </span>

                                                        </Button>
                                                      </Col>


                                                    </Row>
                                                  </>
                                                }
                                                <Row>





                                                  {
                                                    (field.type == 'input' || field.type == 'textarea') &&


                                                    <Col xs="12" sm="auto" key={iField} className="mt-2" style={{ display: "flex", justifyContent: "center" }}>

                                                      {
                                                        options.inputType.map(radio => (

                                                          <div className="custom-control custom-radio custom-control-inline">
                                                            <input
                                                              className="custom-control-input"
                                                              id={radio.value + field.id}
                                                              type="radio"
                                                              checked={field.inputType == radio.value}
                                                              onChange={() => handleOnChangeRadioButton(field.id, radio)}
                                                            />
                                                            <label
                                                              className="custom-control-label"
                                                              htmlFor={radio.value + field.id}
                                                            >
                                                              {radio.label}
                                                            </label>
                                                          </div>
                                                        ))
                                                      }


                                                    </Col>
                                                  }


                                                </Row>
                                              </FormGroup>
                                            </Card>
                                          </div>
                                      }



                                    </div>

                                  )}
                                </Draggable>
                              ))}
                              {droppableProvided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>


















                      <Button color="default" className="p-2" onClick={() => { setExpandButton(!expandButton); setStatusFields({}); }}>
                        <span className="btn-inner--icon mr-1">
                          <i className={"fa fa-plus"} aria-hidden="true" />
                        </span>

                        {!expandButton && <span className="btn-inner--text"> Opciones </span>}

                        {expandButton &&
                          <>
                            {buttonsOptions.map((button, indexButton) => (
                              <Button key={indexButton} color={button.color} className={button.className} onClick={() => handleManageButtons(button)}>
                                <span className="btn-inner--icon mr-1">
                                  <i className={button.classNameIcon} />
                                </span>
                                <span className="btn-inner--text"> {button.label} </span>

                              </Button>
                            ))
                            }
                          </>
                        }

                      </Button>



                    </div>

                  </CardBody>
                </Card>

              </TabPane>
              <TabPane
                tab={
                  <span>
                    <span className="btn-inner--icon mb-2 mr-2">
                      <i className="fa fa-eye" aria-hidden="true" />
                    </span>
                    Vista Previa
                  </span>
                }

                key="2">

                {!openModal &&

                  <Card>
                    <CardBody>
                      <LDRItemForm
                        idLDR={"edit"}
                        title={fields[0].label}
                        setLdrsList={""}
                        localFields={{ edit: [...fields] }}
                      >
                      </LDRItemForm>
                    </CardBody>
                  </Card>
                }

              </TabPane>
            </Tabs>
          </div>
        </Col>

        <Col sm="auto" className="" style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", paddingTop: "10px" }} >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button color="default" size="sm" onClick={() => handleReturnToStart()} style={{ marginBottom: "10px", width: "100px" }}>
              <span className="btn-inner--icon mr-1">
                <i className={"fa fa-reply"} />
              </span>
              <span className="btn-inner--text"> Volver </span>
            </Button>
            <Button color="success" size="sm" onClick={() => openToSendPetition()} style={{ marginBottom: "10px", width: "100px" }}>
              <span className="btn-inner--icon mr-1 mt-5">
                <i className={"fa fa-save"} />
              </span>
              <span className="btn-inner--text"> Guardar </span>
            </Button>
          </div>
        </Col>



      </Row >
    </>
  )

}