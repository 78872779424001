import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
// antd components
import { Spin } from "antd";
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CardHeaders from "components/NewMasterData/CardHeader.jsx";
import { createContext } from "react";
import { getOptions,getEmployees } from "actions/masterData";
import { desencrypt } from 'helpers/desencrypt.jsx';

// react-select library for filters
import Select from "react-select";
const HomeMasterData = (props) => {
  //#region Functions

  const UserContext = createContext(null);

  //#region estado para el boolean del spinner
  const [loading, setLoad] = useState(false);
  //#endregion

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region Mensajes de notificacion

  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: "bottom-right",
    showConfirmButton: true,
    timer: 8000,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  //#endregion

  //#region Extraer los nombres de los empleados del API

  const [employees, setEmployees] = useState({
    employeesOptions: {},

    loadingEmployees: false,
  });

  const { employeesOptions, loadingEmployees } = employees;

  //trae los empleados de GBM de último porque son muy pesados

  const getEmployeesOptions = async () => {
    setEmployees({
      employeesOptions: {},
      loadingEmployees: true,
    });
    dispatch(getEmployees()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setEmployees({
          employeesOptions: JSON.parse(desencrypt(payload.data.payload.employees)),
          loadingEmployees: false,
        });
      } else {
        console.log("Ocurrió un error al extraer los empleados: " + payload);
        setEmployees({
          employeesOptions: {},
          loadingEmployees: false,
        });
      }
    });
  };

  //#endregion

  //#region Extraer Opciones de DropDowns
  const [optionsDropdowns, setOptionsDropdowns] = useState([]);

  useEffect(() => {
    dispatch(getOptions()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setOptionsDropdowns(payload.data.payload.masterData);
      }
    });
    getEmployeesOptions();
  }, []);

  //#region roles
  const [isAdmin, setIsAdmin] = useState(false);
  //#endregion

  //#region estado principal
  const initialState = {};

  const [state, setState] = useState(initialState);
  //#endregion

  //state para guarda el ID del card que selecciona el usuario
  const [formSelected, setFormSelected] = useState("0");
  //#endregion

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert useRef="notificationAlert" />
      </div>
      <AdminHeader name="Inicio" parentName="GBM Datos Maestros" />
      <Container className="mt--6" fluid>
        <Spin size="large" spinning={loading}>
          <CardHeaders
            setFormSelected={setFormSelected}
            formSelected={formSelected}
            isAdmin={isAdmin}
            optionsDropdowns={optionsDropdowns}
            Toast={Toast}
            employees={employeesOptions}
            setEmployees={setEmployees}
          />
        </Spin>
      </Container>
    </>
  );
};

export default HomeMasterData;
