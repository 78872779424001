import moment from "moment";
import { desencrypt } from "helpers/desencrypt.jsx";

export const getNotifications = (state) => {
  if (Object.keys(state.notifications).length) {
    const { notifications } = state.notifications;
    const decrypt = desencrypt(notifications);
    return JSON.parse(decrypt).sort((a, b) => {
      if (moment(a.createdAt) < moment(b.createdAt)) {
        return -1;
      }
      if (moment(a.createdAt) > moment(b.createdAt)) {
        return 1;
      }
      return 0;
    });
  }
  return [];
};

export const getSignaturesDocuments = (state) => {
  if (Object.keys(state.notifications).length) {
    const { documents } = state.notifications;
    return documents;
  }
  return [];
};

export const getAudios = (state) => {
  if (Object.keys(state.notifications).length) {
    const { documents } = state.notifications;
    return documents.map((row) => {
      return {
        id: row.idDocumento,
        url: require(`assets/audio/${row.Nombre_Podcast}.mp3`),
      };
    });
  }
  return [];
};

export const getPdfs = (state) => {
  if (Object.keys(state.notifications).length) {
    const { documents } = state.notifications;
    return documents.map((row) => {
      const name = `${row.Nombre_Podcast}.pdf`;
      const path = require(`assets/docs/${name}`);
      return {
        id: row.idDocumento,
        name,
        url: path,
      };
    });
  }
  return [];
};

export const getCovidEvent = (state) => {
  if (Object.keys(state.notifications).length) {
    const { events } = state.notifications;
    if (events.length) return events[0];
    else return {};
  }
  return {};
};
