import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Card, CardFooter, Pagination, Table, PaginationItem, Button, FormGroup, Input, Form,
  Col, CardHeader, PaginationLink, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Label
} from "reactstrap";
import ModalEditContact from 'components/Contacts/ModalEditContact.jsx';

const requestContactsList = props => {


  const {
    cols,
    rows,
    page,
    sizePerPage,
    onSetPage,
    getInfo,
    handleOnOpenModalNewContact,
    modalInfo,
    saveUpdate,
    changeStatus,
    dataInformationModal,
    options,
    changeData,
    invalid,
    getConfirm,
    showModalImport,
    subtitle,
    functionPreviusPage,
    filter,
    filterList,
    downloadFile,
    setNumPagination,
    isAdmin
  } = props

  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1
  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => onSetPage(i, "pageContacts")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }
  console.log(dataInformationModal)

  const phones = ["PHONE1", "MOBILE1", "PHONE2", "MOBILE2"];

  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="10">
              <h3 className="mb-0" >Filtros</h3>
              <p className="text-sm mb-0">Filtros para contactos sustitutos</p>
            </Col>
            <Col m="12" md="2">
              <Button
                className="btn"
                color="danger"
                onClick={functionPreviusPage}>
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-arrow-left" />
                </span>Regresar
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            {
              filterList.map((row, key) => (
                < Col >
                  <FormGroup id={key}>
                    <Label for="exampleEmail">{`${row.label}:`}</Label>
                    <Input onChange={(e) => filter(e, row.value)} id={row.value} type="text" />
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="bg-transparent border-0">
          <Row>
            <Col >
              <h3 className="mb-0">Lista de contactos</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            <Col>
              <Row className="justify-content-end mb-3" style={{ marginRight: '2px' }}>
                <Button color="info " onClick={handleOnOpenModalNewContact}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>Nuevo
                </Button>
                <Button color="primary " onClick={() => downloadFile("Lista de contactos")}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-download" />
                  </span>Exportar
                </Button>
                <Button color="success"
                  onClick={showModalImport}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-upload" />
                  </span>Importar
                </Button>
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            hover
            id="tableContacts"
            responsive
          >
            <thead >
              <tr style={{ position: "sticky", top: 0, zIndex: "10", backgroundColor: "white" }}>
                {
                  cols.map((row, key) => (
                    <th id={key}>
                      <label>
                        {row.label === "EX1" || row.label === "EX2" ?
                          <></>
                          :
                          row.label
                        }

                      </label>
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody className="list">
              {
                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                  <tr id={key} >
                    {
                      cols.map((col, key) => (
                        col.value === "STATUS" ?
                          <th>
                            {row[col.value] === "" ?
                              <Button id={`confirm${key}`} color="primary"
                                onClick={(e) => changeStatus(e, row, key)}>
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-check" />
                                </span>
                              </Button>
                              :
                              <Button id={`confirm${key}`} color="secondary" disabled>
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-minus" />
                                </span>
                              </Button>
                            }
                          </th>
                          : col.value === "CONFIRM" ?
                            <th>
                              <Button id={`confirm${key}`} color={row[col.value] === "X" ? "success" : "secondary"}
                                onClick={(e) => getConfirm(e, row)}>
                                <span className="btn-inner--icon mr-1">
                                  <i className={row[col.value] === "X" ? "fas fa-check" : "fas fa-minus"} />
                                </span>
                              </Button>
                            </th>
                            :
                            <th id={key} onClick={() => getInfo(row)}>
                              {
                                row[col.value] === "" || row[col.value] === undefined ?
                                  "N/A"
                                  :
                                  row[col.value]
                              }
                            </th>
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>

          </Table>
        </CardBody>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs='12' md='6' className="p-1">
                <Row className="justify-content-start">
                  <Col xs='12' md='6'>
                    <span className='pagination mb-0'>
                      Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                    </span>
                  </Col>
                  <Col xs='12' md='2'>
                    <Input type="select" onChange={(e) => setNumPagination(e)} defaultValue={sizePerPage}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col xs='12' md='6' className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => onSetPage(page === 1 ? page : page - 1, "pageContacts")}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {
                    renderPaginations()
                  }
                  <PaginationItem className={page === paginations ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => onSetPage(page === paginations ? page : page + 1, "pageContacts")}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>

      {/* Modal de editar */}
      <ModalEditContact
        getInfo={getInfo}
        saveUpdate={saveUpdate}
        dataInformationModal={dataInformationModal}
        invalid={invalid}
        options={options}
        changeData={changeData}
        cols={cols}
        modalInfo={modalInfo}
        phones={phones}
      />
    </div >
  );
};

requestContactsList.propTypes = {

};

export default requestContactsList;
