import React from "react";
import {
  Modal,
  Col,
  Row,
  Input,
  FormGroup,
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { Upload, Icon } from "antd";

const ModalApprove = ({
  modal,
  toggle,
  title,
  textFirstButton,
  textSecondButton,
  actionFirstButton,
  actionSecondButton,
  item,
  uploadProps,
  fileList,
  confirmationText,
  isComplete,
}) => {
  const { Dragger } = Upload;
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <Card>
          <CardHeader>
            <Container className="mt-2">
              <h4 className="surtitle">{title}</h4>
            </Container>
          </CardHeader>
          <CardBody>
            <Container>
              <Row>
                <Col xs="12" md="12">
                  <FormGroup>
                    <label className="form-control-label">
                      Agrega un comentario:
                    </label>
                    <Input
                      type="textarea"
                      onChange={(e) => confirmationText(e.target.value)}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              {/* {(item.isCustom === 1 && fileList) && (
                <Row>
                  <Col xs="12" md="12">
                    <h3>Adjuntar aprobación</h3>
                  </Col>
                  <Col xs="12" md="12">
                    <FormGroup>
                      <Dragger {...uploadProps} fileList={fileList}>
                        <p className="ant-uploading-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-uploading-text">
                          Suelte el archivo aquí o haga clic para cargar.
                        </p>
                        <p className="ant-uploading-hint">
                          Puede seleccionar archivos de diferentes
                          folders/carpetas
                        </p>
                      </Dragger>
                    </FormGroup>
                  </Col>
                </Row>
              )} */}
            </Container>
          </CardBody>
          <CardFooter>
            <Row className="d-flex justify-content-between">
              <Col xs="12" md="5" className="p-1">
                <Button
                  onClick={actionFirstButton}
                  className="btn-icon"
                  color="danger"
                  block
                  type="button"
                >
                  <span className="btn-inner--icon mr-">
                    <i className="fas fa-times"></i>
                  </span>
                  <span className="btn-inner--text">{textFirstButton}</span>
                </Button>
              </Col>
              <Col xs="12" md="5" className="p-1">
                <Button
                  onClick={actionSecondButton}
                  disabled={isComplete}
                  className="btn-icon"
                  color="success"
                  block
                  type="button"
                >
                  <span className="btn-inner--icon mr-">
                    <i className="fas fa-save"></i>
                  </span>
                  <span className="btn-inner--text">{textSecondButton}</span>
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Modal>
    </div>
  );
};
export default ModalApprove;
