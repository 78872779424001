// core actions
import {
  createCriticalPart,
  createDebuggedModel,
  deleteCriticalPart,
  deleteDebuggedModel,
  findCriticalParts,
  findFilteredModels,
  updateCriticalPart,
  downloadCriticalParts,
} from "actions/Sales/criticalParts.jsx";
// antd components
import { message, Spin } from "antd";
// core components Critical Parts
import CardFilters from "components/CriticalParts/CardFilters.jsx";
import CriticalParts from "components/CriticalParts/CriticalParts.jsx";
import DebbugedModels from "components/CriticalParts/DebbugedModels.jsx";
import EditCriticalPart from "components/CriticalParts/EditCriticalPart.jsx";
import ErrorModal from "components/CriticalParts/ErrorModal.jsx";
import LoadCriticalParts from "components/CriticalParts/LoadCriticalParts.jsx";
import NewCriticalPart from "components/CriticalParts/NewCriticalPart.jsx";
// core components Shared
import DateRanges from "components/Shared/DateRanges/DateRanges.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// javascript library for dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getCriticalParts,
  getCriticalPartsErrors,
  getDataFiltersCriticalParts,
  getDebuggedModels,
  getDebuggedModelsErrors,
  getInitialValuesCriticalParts,
  getIsLimitedAdmin,
  getIsSuperAdmin,
  getLengthCriticalParts,
  getLengthDebuggedModels,
} from "selectors/Sales/criticalParts.jsx";
// javascript library to parser xlsx to json
const XLSX = require("xlsx");

class Matrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: true,
      createPart: false,
      editPart: false,
      loadPart: false,
      debuggedModels: false,
      upload: false,
      fileList: [],
      showModal: false,
      typeError: null,
      fileLength: 0,
      page: 1,
      pageDebugged: 1,
      sizePerPage: 50,
      platformSelected: {
        value: 0,
        label: "Todos",
      },
      familySelected: {
        value: 0,
        label: "Todos",
      },
      categorySelected: {
        value: 0,
        label: "Todos",
      },
      classifySelected: {
        value: 0,
        label: "Todos",
      },
      redundancySelected: {
        value: 2,
        label: "Todos",
      },
      model: "",
      description: "",
      criticalPartEdit: {},
      startDate: moment("2020-05-05").startOf("month"),
      endDate: moment().endOf("month"),
    };
  }

  componentWillMount() {
    const { page, sizePerPage, startDate, endDate } = this.state;
    this.props
      .findCriticalParts(page - 1, sizePerPage, {
        platform: "",
        family: "",
        category: "",
        classify: 0,
        redundancy: 2,
        model: "",
        description: "",
        startDate,
        endDate,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState((state) => ({
          loading: !state.loading,
        }));
      });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  _handleOnClearFilters = () => {
    const {
      sizePerPage,
      platformSelected,
      familySelected,
      categorySelected,
      classifySelected,
      redundancySelected,
      model,
      description,
      startDate,
      endDate,
    } = this.state;
    if (
      platformSelected.value !== 0 ||
      familySelected.value !== 0 ||
      categorySelected.value !== 0 ||
      classifySelected.value !== 0 ||
      redundancySelected.value !== 2 ||
      model !== "" ||
      description !== ""
    ) {
      this.setState({
        platformSelected: {
          value: 0,
          label: "Todos",
        },
        familySelected: {
          value: 0,
          label: "Todos",
        },
        categorySelected: {
          value: 0,
          label: "Todos",
        },
        classifySelected: {
          value: 0,
          label: "Todos",
        },
        redundancySelected: {
          value: 2,
          label: "Todos",
        },
        model: "",
        description: "",
        page: 1,
      });
      this._onSearchCriticalPartsAction(
        0,
        sizePerPage,
        "",
        "",
        "",
        0,
        0,
        "",
        "",
        startDate,
        endDate
      );
    } else {
      this.notify(
        "warning",
        "Atención",
        "Aún no se han aplicado filtros que deban ser limpiados"
      );
    }
  };

  _handleOnChangeFilters = (who, event) => {
    const {
      platformSelected,
      familySelected,
      categorySelected,
      classifySelected,
      redundancySelected,
      model,
      description,
      sizePerPage,
      startDate,
      endDate,
    } = this.state;
    if (who === "model" || who === "description") {
      const { id, value } = event.target;
      this.setState({
        [id]: value.toUpperCase(),
        page: 1,
      });
      // this._onSearchCriticalPartsAction(
      //   0,
      //   sizePerPage,
      //   platformSelected.label === "Todos" ? "" : platformSelected.label,
      //   familySelected.label === "Todos" ? "" : familySelected.label,
      //   categorySelected.label === "Todos" ? "" : categorySelected.label,
      //   value.toUpperCase()
      // );
    } else {
      this.setState({
        [who]: event,
        page: 1,
      });
      const valuePlatform =
        who === "platformSelected"
          ? event.label
          : platformSelected.label === "Todos"
          ? ""
          : platformSelected.label;
      const valueFamily =
        who === "familySelected"
          ? event.label
          : familySelected.label === "Todos"
          ? ""
          : familySelected.label;
      const valueCategory =
        who === "categorySelected"
          ? event.label
          : categorySelected.label === "Todos"
          ? ""
          : categorySelected.label;
      const valueClassify =
        who === "classifySelected"
          ? event.value
          : classifySelected.label === "Todos"
          ? 0
          : classifySelected.value;
      const valueRedundancy =
        who === "redundancySelected"
          ? event.value
          : redundancySelected.label === "Todos"
          ? 2
          : redundancySelected.value;
      this._onSearchCriticalPartsAction(
        0,
        sizePerPage,
        valuePlatform === "Todos" ? "" : valuePlatform,
        valueFamily === "Todos" ? "" : valueFamily,
        valueCategory === "Todos" ? "" : valueCategory,
        valueClassify === 0 ? 0 : valueClassify,
        valueRedundancy === 2 ? 2 : valueRedundancy,
        model,
        description,
        startDate,
        endDate
      );
    }
  };

  _handleOnSearchCriticalPartsByModel = () => {
    const {
      platformSelected,
      familySelected,
      categorySelected,
      classifySelected,
      redundancySelected,
      model,
      description,
      sizePerPage,
      startDate,
      endDate,
    } = this.state;
    this._onSearchCriticalPartsAction(
      0,
      sizePerPage,
      platformSelected.label === "Todos" ? "" : platformSelected.label,
      familySelected.label === "Todos" ? "" : familySelected.label,
      categorySelected.label === "Todos" ? "" : categorySelected.label,
      classifySelected.label === "Todos" ? 0 : classifySelected.value,
      redundancySelected.label === "Todos" ? 2 : redundancySelected.value,
      model,
      description,
      startDate,
      endDate
    );
  };

  _onSearchCriticalPartsAction = (
    page,
    sizePerPage,
    platform,
    family,
    category,
    classify,
    redundancy,
    model,
    description,
    startDate,
    endDate
  ) => {
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props
      .findCriticalParts(page, sizePerPage, {
        platform,
        family,
        category,
        classify,
        redundancy,
        model,
        description,
        startDate,
        endDate,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState((state) => ({
          loading: !state.loading,
        }));
      });
  };

  _handleOnSetPage = (page) => {
    const {
      platformSelected,
      familySelected,
      categorySelected,
      classifySelected,
      redundancySelected,
      model,
      description,
      sizePerPage,
      startDate,
      endDate,
    } = this.state;
    if (page !== this.state.page) {
      this._onSearchCriticalPartsAction(
        page - 1,
        sizePerPage,
        platformSelected.label === "Todos" ? "" : platformSelected.label,
        familySelected.label === "Todos" ? "" : familySelected.label,
        categorySelected.label === "Todos" ? "" : categorySelected.label,
        classifySelected.label === "Todos" ? 0 : classifySelected.value,
        redundancySelected.label === "Todos" ? 2 : redundancySelected.value,
        model.toUpperCase(),
        description.toUpperCase(),
        startDate,
        endDate
      );
      this.setState({
        page,
      });
    }
  };

  _handleOnSetPageDebugged = (page) => {
    if (page !== this.state.pageDebugged) {
      this.setState((state) => ({
        upload: !state.upload,
      }));
      this.props
        .findFilteredModels(page - 1, this.state.sizePerPage)
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.setState((state) => ({
              upload: !state.upload,
            }));
          } else {
            this.setState((state) => ({
              pageDebugged: page,
              upload: !state.upload,
            }));
          }
        });
    }
  };

  _handleOnToggleVisibility = (event) => {
    if (
      event === "debuggedModels" &&
      !this.state[event] &&
      !this.props.models.length
    ) {
      this.setState((state) => ({
        loading: !state.loading,
      }));
      this.props
        .findFilteredModels(this.state.pageDebugged - 1, this.state.sizePerPage)
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          }
          this.setState((state) => ({
            loading: !state.loading,
            [event]: !state[event],
          }));
        });
    } else {
      this.setState((state) => ({
        [event]: !state[event],
      }));
    }
  };

  _handleOnCreateCriticalPart = (values) => {
    const { page, sizePerPage, startDate, endDate } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props
      .createCriticalPart({
        parts: [values],
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
          }));
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findCriticalParts(page - 1, sizePerPage, {
            platform: "",
            family: "",
            category: "",
            classify: 0,
            redundancy: 2,
            model: "",
            description: "",
            startDate,
            endDate,
          });
          this.setState((state) => ({
            loading: !state.loading,
            createPart: false,
            showModal: payload.errors.length ? true : false,
            typeError: payload.errors.length ? "parts" : null,
          }));
        }
      });
  };

  _onDeleteDebuggedModel = (row) => {
    const { id } = row;
    this.setState((state) => ({
      upload: !state.upload,
      alert: null,
    }));
    this.props.deleteDebuggedModel(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          upload: !state.upload,
        }));
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState((state) => ({
          upload: !state.upload,
        }));
      }
    });
  };

  _handleOnDeleteModel = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de eliminar el modelo depurado?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this._onDeleteDebuggedModel(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  _onDeleteCriticalPart = (row) => {
    const { id } = row;
    this.setState((state) => ({
      loading: !state.loading,
      alert: null,
    }));
    this.props.deleteCriticalPart(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
        }));
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState((state) => ({
          loading: !state.loading,
        }));
      }
    });
  };

  _handleOnEditCriticalPart = (row) => {
    this.setState((state) => ({
      criticalPartEdit: row,
      editPart: !state.editPart,
    }));
  };

  _handleOnSaveEditCriticalPart = (values) => {
    const { id } = values;
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props.updateCriticalPart(id, values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
        }));
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState((state) => ({
          loading: !state.loading,
          editPart: !state.editPart,
        }));
      }
    });
  };

  _handleOnDeleteCriticalPart = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de eliminar la parte crítica?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this._onDeleteCriticalPart(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  _handleOnCreateDebuggedModel = async (values) => {
    this.setState((state) => ({
      upload: !state.upload,
    }));
    await this.props
      .createDebuggedModel({
        models: [values.model],
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            upload: !state.upload,
          }));
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Atención", payload.message);
          this.setState((state) => ({
            upload: !state.upload,
            showModal: payload.errors.length ? true : false,
            typeError: payload.errors.length ? "models" : null,
          }));
        }
      });
  };

  _handleOnFileUpload = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      this.setState({
        fileList: [],
        fileLength: 0,
      });
      message.success(`El archivo ${info.file.name} fue quitado exitosamente.`);
    } else if (status === "removed") {
      this.setState({
        fileList: [],
        fileLength: 0,
      });
      message.success(
        `El archivo ${info.file.name} dio un error al ser cargado.`
      );
    } else {
      this.setState({
        upload: true,
      });
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = await fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          let { status } = file;
          if (status === "done") {
            // this.notify("success", "Exito", `El archivo ${file.name} fue cargado exitosamente.`);
            message.success(
              `El archivo ${file.name} fue cargado exitosamente.`
            );
            const reader = new FileReader();
            reader.onload = async (event) => {
              const data = event.target.result;
              const workbook = XLSX.read(data, {
                type: "binary",
              });
              workbook.SheetNames.forEach((sheetName) => {
                const XL_row_object = XLSX.utils.sheet_to_row_object_array(
                  workbook.Sheets[sheetName]
                );
                // console.log(XL_row_object);
                // const json_object = JSON.stringify(XL_row_object);
                // console.log(json_object);
                this.setState({
                  fileLength: XL_row_object.length,
                });
              });
            };
            reader.onerror = function (event) {
              this.notify(
                "warning",
                "Atención",
                `No se logro leer el archivo ${file.name}, ${event.target.error.code}`
              );
            };
            reader.readAsBinaryString(file.originFileObj);
          } else if (status === "error") {
            // this.notify("danger", "Falló", `La carga del archivo ${file.name} fallo.`);
            message.success(`La carga del archivo ${file.name} fallo.`);
          } else if (status === "removed") {
            // this.notify("warning", "Atención", `El archivo ${file.name} fue quitado exitosamente.`);
            message.success(
              `El archivo ${file.name} fue quitado exitosamente.`
            );
          }
        }
        return file;
      });
      this.setState({
        fileList,
        upload: false,
      });
    }
  };

  _handleOnLoadDebuggedModel = (values) => {
    const { init, end } = values;
    const { fileList } = this.state;
    const models = [];
    if (fileList.length) {
      this.setState({
        upload: true,
      });
      const [file] = fileList;
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });
        workbook.SheetNames.forEach((sheetName) => {
          const XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          // console.log(XL_row_object);
          // const json_object = JSON.stringify(XL_row_object);
          // console.log(json_object);
          for (const element of XL_row_object) {
            models.push(element["Modelos"]);
          }
        });
        if (end - init <= 50) {
          await this.props
            .createDebuggedModel({
              models: models.slice(init, end),
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState((state) => ({
                  upload: !state.upload,
                }));
              } else {
                const {
                  data: { payload },
                } = res.payload;
                this.notify("success", "Atención", payload.message);
                this.setState((state) => ({
                  upload: !state.upload,
                  // fileList: [],
                  showModal: payload.errors.length ? true : false,
                  typeError: payload.errors.length ? "models" : null,
                }));
              }
            });
        } else {
          this.notify(
            "warning",
            "Atención",
            `El maximo de modelos por archivo es de 50 modelos a la vez, está intentando crear ${models.length} modelos.`
          );
          this.setState((state) => ({
            upload: !state.upload,
          }));
        }
      };
      reader.onerror = function (event) {
        this.notify(
          "warning",
          "Atención",
          `No se logro leer el archivo xlxs, ${event.target.error.code}`
        );
        this.setState((state) => ({
          upload: !state.upload,
        }));
      };
      reader.readAsBinaryString(file.originFileObj);
    }
  };

  _handleOnLoadCriticalParts = (values) => {
    const { init, end } = values;
    const { page, sizePerPage, fileList, startDate, endDate } = this.state;
    const parts = [];
    if (fileList.length) {
      this.setState({
        upload: true,
      });
      const [file] = fileList;
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });
        workbook.SheetNames.forEach((sheetName) => {
          const XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          // console.log(XL_row_object);
          // const json_object = JSON.stringify(XL_row_object);
          // console.log(json_object);
          for (const element of XL_row_object) {
            const {
              Ingeniero,
              TipoModelo,
              Plataforma,
              Familia,
              Categoría,
              Descripción,
              TieneRedundancia,
              ClasifiacionParte,
            } = element;
            parts.push({
              engineer: Ingeniero ? Ingeniero : "N/A",
              modelType: TipoModelo ? TipoModelo : "N/A",
              platform: Plataforma ? Plataforma : "N/A",
              family: Familia ? Familia : "N/A",
              category: Categoría ? Categoría : "N/A",
              description: Descripción ? Descripción : "N/A",
              redundancy: TieneRedundancia
                ? TieneRedundancia.toLowerCase() === "no"
                  ? 0
                  : 1
                : null,
              classify: ClasifiacionParte
                ? ClasifiacionParte.toLowerCase() === "sensitiva"
                  ? 2
                  : 1
                : null,
            });
          }
        });
        if (end - init <= 50) {
          this.props
            .createCriticalPart({
              parts: parts.slice(init, end),
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState((state) => ({
                  upload: !state.upload,
                }));
              } else {
                const {
                  data: { payload },
                } = res.payload;
                this.notify("success", "Éxito", payload.message);
                this.props.findCriticalParts(page - 1, sizePerPage, {
                  platform: "",
                  family: "",
                  category: "",
                  classify: 0,
                  redundancy: 2,
                  model: "",
                  description: "",
                  startDate,
                  endDate,
                });
                this.setState((state) => ({
                  upload: !state.upload,
                  // fileList: [],
                  showModal: payload.errors.length ? true : false,
                  typeError: payload.errors.length ? "parts" : null,
                }));
              }
            });
        } else {
          this.notify(
            "warning",
            "Atención",
            `El maximo de modelos por archivo es de 50 modelos a la vez, está intentando crear ${parts.length} modelos.`
          );
          this.setState((state) => ({
            upload: !state.upload,
          }));
        }
      };
      reader.onerror = function (event) {
        message.success(
          `No se logro leer el archivo xlxs, ${event.target.error.code}`
        );
        this.setState((state) => ({
          upload: !state.upload,
        }));
      };
      reader.readAsBinaryString(file.originFileObj);
    }
  };

  /**
   * Funcion para cuando se selecciona una fecha ya sea de inicio o de fin, la agrega a la variable
   * respectiva en el estado
   * @memberof Nodes
   */
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
      const {
        platformSelected,
        familySelected,
        categorySelected,
        classifySelected,
        redundancySelected,
        model,
        description,
        sizePerPage,
        startDate,
        endDate,
      } = this.state;
      this._onSearchCriticalPartsAction(
        0,
        sizePerPage,
        platformSelected.label === "Todos" ? "" : platformSelected.label,
        familySelected.label === "Todos" ? "" : familySelected.label,
        categorySelected.label === "Todos" ? "" : categorySelected.label,
        classifySelected.label === "Todos" ? 0 : classifySelected.value,
        redundancySelected.label === "Todos" ? 2 : redundancySelected.value,
        model.toUpperCase(),
        description.toUpperCase(),
        who === "startDate" ? date : startDate,
        who === "endDate" ? date : endDate
      );
    }
  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  handleOnExportCriticalParts = () => {
    this.setState({
      loading: true,
      message: "Descargando Partes Criticas",
    });
    const {
      platformSelected,
      familySelected,
      categorySelected,
      classifySelected,
      redundancySelected,
      model,
      description,
      startDate,
      endDate,
    } = this.state;
    this.props
      .downloadCriticalParts({
        platform:
          platformSelected.label === "Todos" ? "" : platformSelected.label,
        family: familySelected.label === "Todos" ? "" : familySelected.label,
        category:
          categorySelected.label === "Todos" ? "" : categorySelected.label,
        classify:
          classifySelected.label === "Todos" ? 0 : classifySelected.value,
        redundancy:
          redundancySelected.label === "Todos" ? 2 : redundancySelected.value,
        model: model.toUpperCase(),
        description: description.toUpperCase(),
        startDate,
        endDate,
      })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            this.notify(
              "warning",
              "Atención",
              `No se logro descargar la información del modelo seleccionado`
            );
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([res.payload.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Lista Contratos On Hold.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
        this.setState({
          loading: false,
          message: "",
        });
      });
  };

  render() {
    const {
      name,
      parentName,
      criticalParts,
      models,
      cols,
      initialValues,
      errorsModels,
      errorsParts,
      lengthCriticalParts,
      lengthDebuggedModels,
      dataFilters,
      isSuperAdmin,
      isLimitedAdmin,
    } = this.props;

    const {
      alert,
      loading,
      loadPart,
      createPart,
      editPart,
      debuggedModels,
      upload,
      fileList,
      showModal,
      typeError,
      fileLength,
      page,
      pageDebugged,
      sizePerPage,
      platformSelected,
      familySelected,
      categorySelected,
      classifySelected,
      redundancySelected,
      model,
      description,
      criticalPartEdit,
      startDate,
      endDate,
    } = this.state;

    return (
      <>
        {alert}
        <ErrorModal
          showModal={showModal}
          type={typeError}
          errors={typeError === "models" ? errorsModels : errorsParts}
          toggleModal={this._handleOnToggleVisibility}
        />
        <EditCriticalPart
          visible={editPart}
          uploading={loading}
          initialValues={criticalPartEdit}
          onClose={this._handleOnToggleVisibility}
          onEdit={this._handleOnSaveEditCriticalPart}
        />
        <NewCriticalPart
          visible={createPart}
          uploading={loading}
          initialValues={initialValues}
          onClose={this._handleOnToggleVisibility}
          onFinish={this._handleOnCreateCriticalPart}
        />
        <LoadCriticalParts
          title="Cargar partes críticas"
          visible={loadPart}
          uploading={upload}
          fileList={fileList}
          fileLength={fileLength}
          onClose={this._handleOnToggleVisibility}
          onFileList={this._handleOnFileUpload}
          onSave={this._handleOnLoadCriticalParts}
        />
        <DebbugedModels
          title="Lista de los Modelos Depurados"
          debuggedModels={models}
          length={lengthDebuggedModels}
          page={pageDebugged}
          sizePerPage={sizePerPage}
          paginations={
            lengthDebuggedModels > sizePerPage
              ? lengthDebuggedModels / sizePerPage >
                Math.round(lengthDebuggedModels / sizePerPage)
                ? Math.round(lengthDebuggedModels / sizePerPage) + 1
                : Math.round(lengthDebuggedModels / sizePerPage)
              : 1
          }
          initialValues={{ model: "Ejemplo" }}
          visible={debuggedModels}
          fileList={fileList}
          fileLength={fileLength}
          uploading={upload}
          onSetPage={this._handleOnSetPageDebugged}
          onClose={this._handleOnToggleVisibility}
          onDelete={this._handleOnDeleteModel}
          onCreate={this._handleOnCreateDebuggedModel}
          onFileList={this._handleOnFileUpload}
          onSave={this._handleOnLoadDebuggedModel}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Spin size="large" spinning={loading}>
          <Container className="mt--6" fluid>
            <Row>
              <Col sm="12">
                <DateRanges
                  title="Fechas de búsqueda"
                  subtitle="Ingrese un rango de fechas para la búsqueda."
                  startDate={startDate}
                  endDate={endDate}
                  reactDatetimeChange={this.handleReactDatetimeChange}
                  classNameReactDatetimeDays={
                    this.getClassNameReactDatetimeDays
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CardFilters
                  title="Filtros"
                  subtitle="Aplicar filtros a las partes críticas"
                  platform={platformSelected}
                  family={familySelected}
                  category={categorySelected}
                  classify={classifySelected}
                  redundancy={redundancySelected}
                  modelo={model}
                  description={description}
                  filters={dataFilters}
                  onClear={this._handleOnClearFilters}
                  onChangeFilters={this._handleOnChangeFilters}
                  onSearch={this._handleOnSearchCriticalPartsByModel}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CriticalParts
                  title="Matriz de Partes Críticas"
                  subtitle="A continuación se muestra la matriz de las partes críticas."
                  isSuperAdmin={isSuperAdmin}
                  isLimitedAdmin={isLimitedAdmin}
                  cols={cols}
                  rows={criticalParts}
                  length={lengthCriticalParts}
                  page={page}
                  sizePerPage={sizePerPage}
                  paginations={
                    lengthCriticalParts > sizePerPage
                      ? lengthCriticalParts / sizePerPage >
                        Math.round(lengthCriticalParts / sizePerPage)
                        ? Math.round(lengthCriticalParts / sizePerPage) + 1
                        : Math.round(lengthCriticalParts / sizePerPage)
                      : 1
                  }
                  onDebuggedModels={this._handleOnDebuggedModels}
                  onSetPage={this._handleOnSetPage}
                  onToggle={this._handleOnToggleVisibility}
                  onEdit={this._handleOnEditCriticalPart}
                  onDelete={this._handleOnDeleteCriticalPart}
                  onExport={this.handleOnExportCriticalParts}
                />
              </Col>
            </Row>
          </Container>
        </Spin>
      </>
    );
  }
}

Matrix.defaultProps = {
  name: "Matriz de Partes Críticas",
  parentName: "Contratos de Mantenimiento",
  criticalParts: [],
  models: [],
  cols: [
    // { key: "id", name: "ID" },
    { key: "engineer", name: "Ingeniero" },
    { key: "modelType", name: "Modelo" },
    { key: "platform", name: "Plataforma" },
    { key: "family", name: "Familia" },
    { key: "category", name: "Categoría" },
    { key: "description", name: "Descripción" },
    { key: "redundancy", name: "Redundancia" },
    { key: "classify", name: "Clasificación de la parte" },
    { key: "updateBy", name: "Última Actualización" },
    { key: "updatedAt", name: "Fecha" },
  ],
  initialValues: {},
  errorsModels: [],
  errorsParts: [],
  lengthCriticalParts: 0,
  lengthDebuggedModels: 0,
  dataFilters: {},
  isSuperAdmin: false,
  isLimitedAdmin: false,
};

Matrix.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  criticalParts: PropTypes.array.isRequired,
  models: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  errorsModels: PropTypes.array.isRequired,
  errorsParts: PropTypes.array.isRequired,
  lengthCriticalParts: PropTypes.number.isRequired,
  lengthDebuggedModels: PropTypes.number.isRequired,
  dataFilters: PropTypes.object.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  isLimitedAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  criticalParts: getCriticalParts(state),
  models: getDebuggedModels(state),
  initialValues: getInitialValuesCriticalParts(state),
  errorsModels: getDebuggedModelsErrors(state),
  errorsParts: getCriticalPartsErrors(state),
  lengthCriticalParts: getLengthCriticalParts(state),
  lengthDebuggedModels: getLengthDebuggedModels(state),
  dataFilters: getDataFiltersCriticalParts(state),
  isSuperAdmin: getIsSuperAdmin(),
  isLimitedAdmin: getIsLimitedAdmin(),
});

export default withRouter(
  connect(mapStateToProps, {
    createCriticalPart,
    createDebuggedModel,
    updateCriticalPart,
    deleteCriticalPart,
    deleteDebuggedModel,
    findCriticalParts,
    findFilteredModels,
    downloadCriticalParts,
  })(Matrix)
);
