// javascript plugin that creates nice dropzones for files
import { Spin } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form as FormRS,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
} from "reactstrap";

const WorkFlow = (props) => {
  const {
    user,
    type,
    message,
    loading,
    showModal,
    toggleModal,
    initialValues,
    isInvalid,
    onValidete,
    onWorkFlow,
  } = props;

  const validate = (values) => {
    const errors = {};
    if (!values.username || values.username === "0")
      errors.username = "Debes ingresar el nombre de usuario.";
    if (!values.startDate || values.startDate === "")
      errors.startDate = "Debes ingresar la fecha.";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{type}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onWorkFlow}
              validate={validate}
              initialValues={initialValues}
              render={({
                handleSubmit,
                values,
                submitting,
                validating,
                valid,
              }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label className="form-control-label" for="username">
                        Nombre de Usuario
                      </Label>
                      <Field name="username">
                        {({ input, meta }) => (
                          <InputGroup className="input-group-merge">
                            <Input
                              {...input}
                              type="text"
                              className="m-0"
                              placeholder="Ejemplo: FVILLALOBOS"
                              valid={
                                !meta.error && meta.modified && meta.touched
                              }
                              invalid={meta.error && meta.touched}
                            />
                            <InputGroupAddon addonType="append">
                              <Button
                                outline
                                size="sm"
                                color="success"
                                type="button"
                                disabled={
                                  values.username
                                    ? values.username.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                                // onClick={handleSubmit}
                                onClick={() => onValidete(values)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-user-check" />
                                </span>
                              </Button>
                            </InputGroupAddon>
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </InputGroup>
                        )}
                      </Field>
                    </FormGroup>
                    {Object.keys(user).length ? (
                      <FormGroup>
                        <Label className="form-control-label" for="startDate">
                          Fecha de Inicio
                        </Label>
                        <Field name="startDate">
                          {({ input, meta }) => (
                            <InputGroup className="input-group-merge">
                              <Input
                                {...input}
                                type="datetime-local"
                                className="m-0"
                                valid={
                                  !meta.error && meta.modified && meta.touched
                                }
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <span className="invalid-feedback">
                                  {meta.error}
                                </span>
                              )}
                            </InputGroup>
                          )}
                        </Field>
                      </FormGroup>
                    ) : null}
                    {Object.keys(user).length ? (
                      <blockquote className="blockquote text-center">
                        <p className="mb-0">{user.NOMBRE}</p>
                        <footer className="blockquote-footer">
                          {user.POSICION}
                        </footer>
                      </blockquote>
                    ) : null}
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting || isInvalid}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Continuar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

WorkFlow.propTypes = {
  type: PropTypes.string.isRequired,
};

export default WorkFlow;
