/* eslint-disable no-unused-expressions */
// core actions
import {
  clearData,
  createCommentaryByRequest,
  createEquipmentSpare,
  createJustifyByRequest,
  createManyEquipments,
  createOneEquipment,
  createRequirement,
  createServiceOrderRequest,
  deactivateEquipmentById,
  deactivateEquipmentSpareById,
  deactivateReferences,
  deactivateReferencesSpare,
  findEquipmentsCreatedByRequest,
  findFormValuesEquipmentsRequest,
  findFormValuesRequestOpportunity,
  findReferencesSpareByRequest,
  updateActivityFlow,
  updateEquipmentById,
  updateEquipmentSpareById,
  updateStateRequest,
  updateAmountEquipmentsByRequest,
} from "actions/Sales/digitalRequest.jsx";
// antd components
import { message, Spin } from "antd";
// core components Sales Request
import CardConfiguration from "components/Sales/Request/CardConfiguration.jsx";
import EquipmentsError from "components/Sales/Request/EquipmentsError.jsx";
import EquipmentsListForm from "components/Sales/Request/EquipmentsListForm.jsx";
import EquipmentsUbicationsModal from "components/Sales/Request/EquipmentsUbicationsModal.jsx";
import RequestForm from "components/Sales/Request/RequestForm.jsx";
import SpareEquipments from "components/Sales/Request/SpareEquipments.jsx";
import WorkFlowModal from "components/Sales/Request/WorkFlowModal.jsx";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getDataFormRequirement,
  getEquipmentsCreated,
  getEquipmentsSpare,
  getInitialValuesEquipments,
  getInitialValuesFormEquipments,
  getInitialValuesRequirement,
  getReferencesSpareByRequest,
  getRequerimentsByUser,
} from "selectors/Sales/requests";
// javascript library to parser xlsx to json
const XLSX = require("xlsx");

class CreateRequirement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      enabled: false,
      loading: false,
      page: 1,
      message: null,
      equipments: false,
      idRequest: null,
      criticalParts: false,
      amountEquipments: 0,
      amountEquipmentsCont: 0,
      typeContract: null,
      loadFile: false,
      fileList: [],
      justify: null,
      commentary: null,
      referencesRemoved: [],
      referenceFilesList: [],
      referenceSpareFileList: [],
      referenceSpareRemoved: [],
      configurationsFilesList: [],
      configurationsRemoved: [],
      ibmEquipments: [],
      ciscoEquipments: [],
      flow: {
        IBM: "created",
        CISCO: "created",
      },
      equipmentAdded: false,
      equipmentsValues: {
        country: undefined,
        typeModel: undefined,
        serial: undefined,
        platform: undefined,
        amountMaintenance: undefined,
        amountEquipments: undefined,
        // description: null,
        practice: undefined,
        officeHours: undefined,
        timeChangePart: undefined,
        validityService: undefined,
        automaticRenewal: undefined,
        equipmentParts: undefined,
        // amountHRMant: null,
        viatic: undefined,
        validWarranty: undefined,
        price: undefined,
      },
      equipmentsEditValues: {
        id: undefined,
        country: undefined,
        typeModel: undefined,
        serial: undefined,
        platform: undefined,
        amountMaintenance: undefined,
        amountEquipments: undefined,
        practice: undefined,
        officeHours: undefined,
        timeChangePart: undefined,
        validityService: undefined,
        automaticRenewal: undefined,
        equipmentParts: undefined,
        viatic: undefined,
        validWarranty: undefined,
        price: undefined,
      },
      editEquipment: false,
      spareVisibility: false,
      addSpare: false,
      editSpare: false,
      spareValues: {
        partNumber: undefined,
        description: undefined,
        amountEquipments: undefined,
        cost: undefined,
      },
      spareEditValues: {
        id: undefined,
        partNumber: undefined,
        description: undefined,
        amountEquipments: undefined,
        cost: undefined,
      },
      referenceView: false,
      configurationView: false,
      flowModal: {
        type: null,
        showModal: false,
        title: null,
        options: [],
      },
      initialValuesFlow: {},
      flavor: null,
      configSC: false,
      amountInEdit: 0,
      equipmentsErrors: [],
      modalEquipmentsErrors: false,
      modalEquipmentsUbications: {
        showModal: false,
        amount: 0,
      },
      request: null,
    };
  }

  componentWillMount() {
    this.props.findFormValuesEquipmentsRequest().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  onMainInitialValues = () => {
    this.setState({
      alert: null,
      enabled: false,
      loading: false,
      page: 1,
      message: null,
      equipments: false,
      idRequest: null,
      criticalParts: false,
      amountEquipments: 0,
      amountEquipmentsCont: 0,
      typeContract: null,
      loadFile: false,
      fileList: [],
      justify: null,
      commentary: null,
      referencesRemoved: [],
      referenceFilesList: [],
      referenceSpareFileList: [],
      referenceSpareRemoved: [],
      configurationsFilesList: [],
      configurationsRemoved: [],
      ibmEquipments: [],
      ciscoEquipments: [],
      flow: {
        IBM: "created",
        CISCO: "created",
      },
      equipmentAdded: false,
      equipmentsValues: {
        country: undefined,
        typeModel: undefined,
        serial: undefined,
        platform: undefined,
        amountMaintenance: undefined,
        amountEquipments: undefined,
        // description: null,
        practice: undefined,
        officeHours: undefined,
        timeChangePart: undefined,
        validityService: undefined,
        automaticRenewal: undefined,
        equipmentParts: undefined,
        // amountHRMant: null,
        viatic: undefined,
        validWarranty: undefined,
        price: undefined,
      },
      equipmentsEditValues: {
        id: undefined,
        country: undefined,
        typeModel: undefined,
        serial: undefined,
        platform: undefined,
        amountMaintenance: undefined,
        amountEquipments: undefined,
        practice: undefined,
        officeHours: undefined,
        timeChangePart: undefined,
        validityService: undefined,
        automaticRenewal: undefined,
        equipmentParts: undefined,
        viatic: undefined,
        validWarranty: undefined,
        price: undefined,
      },
      editEquipment: false,
      spareVisibility: false,
      addSpare: false,
      editSpare: false,
      spareValues: {
        partNumber: undefined,
        description: undefined,
        amountEquipments: undefined,
        cost: undefined,
      },
      spareEditValues: {
        id: undefined,
        partNumber: undefined,
        description: undefined,
        amountEquipments: undefined,
        cost: undefined,
      },
      referenceView: false,
      configurationView: false,
      flowModal: {
        type: null,
        showModal: false,
        title: null,
        options: [],
      },
      initialValuesFlow: {},
      flavor: null,
      configSC: false,
      amountInEdit: 0,
      equipmentsErrors: [],
      modalEquipmentsErrors: false,
      modalEquipmentsUbications: {
        showModal: false,
        amount: 0,
      },
      request: null,
    });
  };

  onVerifyValidPrecies = (equipment) => {
    let flag = false;
    equipment.forEach((element) => {
      if (element.Precio) {
        if (
          parseInt(element.Precio) > 99999999 ||
          parseInt(element.Precio) <= 0
        ) {
          flag = true;
        }
      }
    });
    return flag;
  };

  onVerifyCriticalParts = (equipment) => {
    const { criticalParts } = this.state;
    for (const element of equipment) {
      if (
        (["Partes", "Equipos", "Partes y Equipos"].some(
          (row) => row === element.IncluyePartesEquipos
        ) &&
          criticalParts) ||
        (element.IncluyePartesEquipos === "No aplica" && !criticalParts)
      ) {
        return true;
      }
      if (
        (parseInt(element.idEquipmentParts) !== 4 && criticalParts) ||
        (element.idEquipmentParts === 4 && !criticalParts)
      ) {
        return true;
      }
    }
    return false;
  };

  onVerifyAllPrices = (ibm, cisco, key) => {
    let flag = false;
    ibm.forEach((element) => {
      if (element[key] === "N/A" || !element[key]) {
        flag = true;
      }
    });
    cisco.forEach((element) => {
      if (element[key] === "N/A" || !element[key]) {
        flag = true;
      }
    });
    // } else {
    //   ibm.forEach(element => {
    //     if (parseInt(element.Precio) <= 0) {
    //       flag = true;
    //     }
    //   });
    //   cisco.forEach(element => {
    //     if (parseInt(element.Precio) <= 0) {
    //       flag = true;
    //     }
    //   });
    // }
    return flag;
  };

  onVerifyPartsAndEquipments = (
    equipmentsFile,
    equipmentsNormal,
    key,
    key2
  ) => {
    return (
      equipmentsFile.some((row) => row["IncluyePartesEquipos"] === key) ||
      equipmentsNormal.some((row) => row["idEquipmentParts"] === key2)
    );
  };

  onVerifyAmountsEquipmentsLoaded = (equipments) => {
    const { typeContract } = this.state;
    if (typeContract === 2) {
      let amount = 0;
      for (const element of equipments) {
        amount += element.CantidadEquipos;
      }
      return amount;
    } else {
      return equipments.length;
    }
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  _handleOnChangePage = (action) => {
    this.setState((state) => ({
      page: action === "next" ? state.page + 1 : state.page - 1,
    }));
  };

  _handleOnCancelRequeriment = async () => {
    await this.props.clearData();
  };

  _handleOnSearchOptionsForm = (values) => {
    const { opportunityNumber } = values;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Validando el número de oportunidad ingresado",
    }));
    this.props
      .findFormValuesRequestOpportunity(opportunityNumber)
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      });
  };

  _handleOnCreateRequeriment = async (values) => {
    // 24x7 = 2 -  4 horas = 1 - 2 horas = 1, BE = 4
    const { officeHours, timeChangePart, responseTime, country } = values;
    const { equipmentsValues } = this.state;
    const flavor =
      officeHours === "2" && timeChangePart === "4" && responseTime === "1"
        ? "gold"
        : officeHours === "2" && responseTime === "1" && timeChangePart === "1"
        ? "platinum"
        : null;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Creando el requerimiento",
    }));
    await this.props.createRequirement({ values }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        const {
          data: { payload },
        } = res.payload;
        const {
          requestCreated: [request],
        } = payload;
        equipmentsValues["amountMaintenance"] =
          parseInt(request.criticalParts) === 0
            ? 0
            : flavor === "platinum"
            ? 2
            : flavor === "gold"
            ? 1
            : undefined;
        equipmentsValues["country"] = country;
        this.notify("success", "Éxito", payload.message);
        this.setState((state) => ({
          page: 1,
          equipments: true,
          flavor,
          idRequest: request.id,
          criticalParts: parseInt(request.criticalParts) === 1 ? true : false,
          typeContract: parseInt(request.idBusinessModel),
          amountEquipments: parseInt(request.amountOfEquipment),
          equipmentsValues,
          request,
        }));
        this.props.clearData();
        this.props.findFormValuesEquipmentsRequest().then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("danger", "Falló", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
      }
    });
  };

  _handleOnCancelEquipmentForm = () => {
    this.onMainInitialValues();
  };

  _handleOnCreateEquipment = () => {
    const {
      equipmentsCreated: { ibm },
    } = this.props;
    const {
      idRequest,
      equipmentsValues,
      typeContract,
      amountEquipmentsCont,
      amountEquipments,
      criticalParts,
    } = this.state;
    const {
      request,
      formOptionsEquipments: { practices },
    } = this.props;
    const practiceSelect = practices.find(
      (row) => row.id === parseInt(equipmentsValues.practice)
    );
    const cont =
      typeContract === 1
        ? parseInt(amountEquipmentsCont) + 1
        : parseInt(amountEquipmentsCont) +
          parseInt(equipmentsValues.amountEquipments);
    const requeriment = request[request.length - 1];
    const { country, officeHours, timeChangePart, responseTime } = requeriment;
    const flavor =
      officeHours === "24x7" &&
      timeChangePart === "Mejor Esfuerzo" &&
      responseTime === "2 horas"
        ? "gold"
        : officeHours === "24x7" &&
          responseTime === "2 horas" &&
          timeChangePart === "Hasta 4 horas"
        ? "platinum"
        : null;
    const values = Object.keys(equipmentsValues).filter(
      (row) => row !== "price"
    );
    console.log(ibm);
    console.log(equipmentsValues);
    const { typeModel, serial } = equipmentsValues;
    console.log(
      ibm.some((row) => row.typeModel === typeModel && row.serial === serial)
    );
    if (
      timeChangePart !== "Mejor Esfuerzo" &&
      practiceSelect.prevents > parseInt(equipmentsValues.amountMaintenance)
    ) {
      this.notify(
        "warning",
        "Atención",
        `El minimo de cantidad de mantenimientos para la práctica seleccionada es ${practiceSelect.prevents}`
      );
    } else if (
      values.some(
        (row) =>
          (equipmentsValues[row] === null ||
            equipmentsValues[row] === undefined) &&
          ((typeContract === 1 && row !== "amountEquipments") ||
            (typeContract === 2 && row !== "serial"))
      )
    ) {
      this.notify(
        "warning",
        "Atención",
        "Primero debes completar todos los valores"
      );
    } else if (
      ibm.some((row) => row.typeModel === typeModel && row.serial === serial)
    ) {
      this.notify(
        "warning",
        "Atención",
        "No se puede crear un equipo con el mismo modelo y serial anteriormente creado"
      );
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Creando la linea de equipo`,
      }));
      this.props
        .createOneEquipment(idRequest, {
          type: "IBM",
          values: equipmentsValues,
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            this.setState({
              flow: {
                IBM: "created",
                CISCO: "created",
              },
              amountEquipmentsCont: cont,
              enabled: true,
              equipmentAdded: true,
              equipmentsValues: {
                country,
                typeModel: undefined,
                serial: undefined,
                platform: undefined,
                amountMaintenance:
                  criticalParts === false
                    ? 0
                    : flavor === "platinum"
                    ? 2
                    : flavor === "gold"
                    ? 1
                    : undefined,
                amountEquipments: undefined,
                // description: null,
                practice: undefined,
                officeHours: undefined,
                timeChangePart: undefined,
                validityService: undefined,
                automaticRenewal: undefined,
                equipmentParts: undefined,
                // amountHRMant: null,
                viatic: undefined,
                price: undefined,
              },
            });
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
    }
  };

  _handleOnChageEquipmentsValues = (event, key) => {
    const { id, value } = event.target;
    if (key === "edit") {
      const { equipmentsEditValues } = this.state;
      equipmentsEditValues[id] = value;
      this.setState({
        equipmentsEditValues,
        // equipmentAdded: false,
      });
    } else if (key === "add") {
      const { equipmentsValues } = this.state;
      equipmentsValues[id] = value;
      this.setState({
        equipmentsValues,
        // equipmentAdded: false,
      });
    }
  };

  _handleOnAddEquipments = () => {
    // const { amountEquipments, amountEquipmentsCont } = this.state;
    // const cont = amountEquipmentsCont + 1;
    // if (cont > amountEquipments) {
    //   this.notify("warning", "Atención", "La cantidad de equipos no debe sobrepasar a la ingresada en el LDR");
    // } else {
    //   this.setState({
    //     equipmentAdded: false,
    //   });
    // }
    this.setState({
      equipmentAdded: false,
    });
  };

  _handleOnToggleLoadView = () => {
    this.setState((state) => ({
      loadFile: !state.loadFile,
    }));
  };

  _handleOnChangeJustify = (event) => {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  };

  _handleOnFileUpload = async (info) => {
    const { status } = info.file;
    let { flow, amountEquipments } = this.state;
    if (status === "removed") {
      this.setState({
        fileList: [],
        ibmEquipments: [],
        ciscoEquipments: [],
      });
      message.success(`El archivo ${info.file.name} fue quitado exitosamente.`);
    } else {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
          let { status } = file;
          if (status === "done") {
            message.success(
              `El archivo ${file.name} fue cargado exitosamente.`
            );
            const reader = new FileReader();
            reader.onload = async (event) => {
              const data = event.target.result;
              const workbook = XLSX.read(data, {
                type: "binary",
              });
              let flag = false;
              workbook.SheetNames.forEach((sheetName) => {
                let XL_row_object = XLSX.utils.sheet_to_row_object_array(
                  workbook.Sheets[sheetName]
                );
                // XL_row_object = XL_row_object.map((row) => {
                //   if (!row.Precio) {
                //     row.Precio = 'N/A';
                //   }
                //   return row;
                // });
                if (sheetName === "Lista de Equipos") {
                  if (
                    this.onVerifyAmountsEquipmentsLoaded(XL_row_object) ===
                    amountEquipments
                  ) {
                    const countries = [
                      { id: "CR", name: "Costa Rica", key: "CR" },
                      { id: "SV", name: "El Salvador", key: "SV" },
                      { id: "GT", name: "Guatemala", key: "GT" },
                      { id: "HN", name: "Honduras", key: "HN" },
                      { id: "MD", name: "Miami", key: "MD" },
                      { id: "NI", name: "Nicaragua", key: "NI" },
                      { id: "PA", name: "Panamá", key: "PA" },
                      { id: "DO", name: "República Dominicana", key: "DO" },
                    ];
                    if (this.onVerifyValidPrecies(XL_row_object)) {
                      flag = true;
                      this.notify(
                        "warning",
                        "Atención",
                        `Se detecto en las lineas de equipos uno o mas precios invalidos`
                      );
                    } else if (!this.onVerifyCriticalParts(XL_row_object)) {
                      flag = true;
                      this.notify(
                        "warning",
                        "Atención",
                        `${
                          this.state.criticalParts
                            ? "Se detecto que el requerimiento requiere partes criticas y no se detecta al menos una linea de equipos que incluya partes críticas"
                            : "Se detecto que el requerimiento no requiere partes criticas y se detecta al menos una linea de equipos que incluye partes críticas"
                        }`
                      );
                    } else {
                      flow["IBM"] = "load";
                      this.setState({
                        ibmEquipments: XL_row_object.map((row) => {
                          const country = countries.find(
                            (ele) => ele.name === row["Pais"]
                          );
                          row["Pais"] = country.id;
                          return row;
                        }),
                      });
                    }
                  } else if (sheetName === "Equipos CISCO") {
                    if (this.onVerifyValidPrecies(XL_row_object)) {
                      flag = true;
                      this.notify(
                        "warning",
                        "Atención",
                        `Se detecto en las lineas de equipos CISCO uno o mas precios invalidos`
                      );
                    } else {
                      flow["CISCO"] = "load";
                      this.setState({
                        ciscoEquipments: XL_row_object,
                      });
                    }
                  } else {
                    this.notify(
                      "warning",
                      "Atención",
                      `La cantidad de equipos ingresados aun no cumple con los indicados en el LDR (${amountEquipments})`
                    );
                  }
                }
              });
              if (flag) {
                this.setState({
                  fileList: [],
                });
              }
              this.setState((state) => ({
                loadFile: !state.loadFile,
                flow,
              }));
            };
            reader.onerror = function (event) {
              this.notify(
                "warning",
                "Atención",
                `No se logro leer el archivo ${file.name}, ${event.target.error.code}`
              );
            };
            reader.readAsBinaryString(file.originFileObj);
          } else if (status === "error") {
            // this.notify("danger", "Falló", `La carga del archivo ${file.name} fallo.`);
            message.success(`La carga del archivo ${file.name} fallo.`);
          } else if (status === "removed") {
            // this.notify("warning", "Atención", `El archivo ${file.name} fue quitado exitosamente.`);
            message.success(
              `El archivo ${file.name} fue quitado exitosamente.`
            );
          }
        }
        return file;
      });
      this.setState({
        fileList,
        // upload: false,
      });
    }
  };

  _handleOnCreateEquipments = (type) => {
    const { idRequest, ibmEquipments, ciscoEquipments, amountEquipments } =
      this.state;
    // if (ibmEquipments.length > amountEquipments) {
    //  this.notify("warning", "Atención", "La cantidad de equipos no debe sobrepasar a la ingresada en el LDR");
    // } else {
    let { flow, enabled } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Creando todos los equipos cargados`,
    }));
    this.props
      .createManyEquipments(idRequest, {
        type,
        equipments: type === "IBM" ? ibmEquipments : ciscoEquipments,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: {
                payload: { errors, message },
              },
            } = res.payload.response;
            this.notify("warning", "Atención", message);
            if (errors.length) {
              this.setState({
                modalEquipmentsErrors: true,
                equipmentsErrors: errors,
              });
            }
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: {
              payload: { errors, message, created },
            },
          } = res.payload;
          if (errors.length) {
            this.setState({
              modalEquipmentsErrors: true,
              equipmentsErrors: errors,
            });
          } else if (created.length) {
            this.notify("success", "Éxito", message);
            flow[type] = "created";
            enabled = true;
            this.props.findEquipmentsCreatedByRequest(idRequest);
          }
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
          flow,
          enabled,
        }));
      });
    // }
  };

  _handleOnCompleteEquipments = () => {
    this.hideAlert();
    const {
      idRequest,
      flow,
      criticalParts,
      ibmEquipments,
      amountEquipments,
      typeContract,
    } = this.state;
    const {
      equipmentsCreated: { ibm },
    } = this.props;
    console.log(amountEquipments);
    if (
      !this.onVerifyCriticalParts(ibm) &&
      !this.onVerifyCriticalParts(ibmEquipments)
    ) {
      this.notify(
        "warning",
        "Atención",
        `${
          criticalParts
            ? "Se detecto que el requerimiento requiere partes criticas y no se detecta al menos una linea de equipo que incluya partes"
            : "Se detecto que el requerimiento no requiere partes y se detecta al menos una linea de equipo que incluye partes"
        }`
      );
      // } else if (typeContract === 1 && ((ibm.length && amountEquipments > ibm.length) || (ibmEquipments.length && amountEquipments > ibmEquipments.length))) {
      // this.notify("warning", "Atención", `Cantidad de equipos creados es menor a la cantidad ingresada en el LDR`);
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Completando la tarea de Equipos`,
      }));
      this.props
        .updateActivityFlow(idRequest, {
          description: `Carga de la lista de Equipos al requerimiento`,
          state: `Equipos Cargados`,
          type: "IBM",
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("danger", "Falló", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            flow["IBM"] = "complete";
            this.props.updateStateRequest(idRequest, 1);
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
            flow: flow,
            equipmentAdded: flow["IBM"] === "complete" ? null : true,
          }));
        });
    }
  };

  _handleOnSaveAndBack = async () => {
    const { idRequest, referenceFilesList, referencesRemoved, justify } =
      this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Guardando el proceso del requerimiento",
    }));
    if (!referenceFilesList.length && !referencesRemoved.length && !justify) {
      this.props
        .updateActivityFlow(idRequest, {
          description: `Requerimiento pendiente de completar`,
          state: `Pendiente`,
          type: "pending",
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("danger", "Falló", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          } else {
            this.onMainInitialValues();
          }
        });
    } else {
      const errors = [];
      if (justify) {
        await this.props
          .createJustifyByRequest(idRequest, {
            justify,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                errors.push({ type: "justify", error: payload.message });
              } else {
                errors.push({
                  type: "justify",
                  error: "No se logro establecer conexion con el servidor.",
                });
              }
            }
          });
      }
      if (referencesRemoved.length) {
        await this.props
          .deactivateReferences({
            referencesIds: referencesRemoved,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                errors.push({ type: "references", error: payload.message });
              } else {
                errors.push({
                  type: "references",
                  error: "No se logro establecer conexion con el servidor.",
                });
              }
            }
          });
      }
      if (errors.length) {
        this.notify(
          "danger",
          "Error",
          "Se presento un error intentando guardar la información"
        );
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        this.props
          .updateActivityFlow(idRequest, {
            description: `Requerimiento pendiente de completar`,
            state: `Pendiente`,
            type: "pending",
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("danger", "Falló", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              this.onMainInitialValues();
            }
          });
      }
    }
  };

  _handleOnCompleteRequest = async (flag) => {
    const {
      idRequest,
      flow,
      referenceFilesList,
      referencesRemoved,
      justify,
      ibmEquipments,
      ciscoEquipments,
      typeContract,
      amountEquipments,
      amountEquipmentsCont,
    } = this.state;
    const {
      equipmentsCreated: { ibm, cisco },
    } = this.props;
    if (flow["IBM"] !== "complete" && (ibmEquipments.length || ibm.length)) {
      this.notify(
        "warning",
        "Atención",
        "Aun no se han completado los equipos"
      );
    } else if (
      flow["CISCO"] !== "complete" &&
      (ciscoEquipments.length || cisco.length)
    ) {
      this.notify(
        "warning",
        "Atención",
        "Aun no se han completado los equipos CISCO"
      );
    } else if (amountEquipments < amountEquipmentsCont) {
      this.notify(
        "warning",
        "Atención",
        "La cantidad de equipos es menor a la cantidad ingresada en el LDR"
      );
    } else {
      if (
        this.onVerifyAllPrices(ibmEquipments, ciscoEquipments, "Precio") ||
        this.onVerifyAllPrices(ibm, cisco, "price")
      ) {
        this.notify(
          "warning",
          "Atención",
          "Se detectaron lineas de equipos sin precios, para avanzar es necesario que se ingresen todos los precios"
        );
      } else if (flag && !referenceFilesList.length && justify === null) {
        this.notify(
          "warning",
          "Atención",
          "Es obligatorio adjuntar la referencia o escribir una justificación para el o los precios agregados"
        );
      } else {
        const partsFlow = this.onVerifyPartsAndEquipments(
          ibmEquipments,
          ibm,
          "Partes",
          1
        );
        const equipmentsFlow = this.onVerifyPartsAndEquipments(
          ibmEquipments,
          ibm,
          "Equipos (Spare)",
          2
        );
        const partsEquipmentsFlow = this.onVerifyPartsAndEquipments(
          ibmEquipments,
          ibm,
          "Partes y Equipos (Spare)",
          3
        );
        if (!partsFlow && !equipmentsFlow && !partsEquipmentsFlow) {
          console.log("No lleva partes y equipos");
          this.setState({
            loading: true,
            message: `Completando el proceso de la oportunidad`,
          });
          const errors = [];
          if (justify) {
            await this.props
              .createJustifyByRequest(idRequest, {
                justify,
              })
              .then((res) => {
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    errors.push({ type: "justify", error: payload.message });
                  } else {
                    errors.push({
                      type: "justify",
                      error: "No se logro establecer conexion con el servidor.",
                    });
                  }
                }
              });
          }
          if (referencesRemoved.length) {
            await this.props
              .deactivateReferences({
                referencesIds: referencesRemoved,
              })
              .then((res) => {
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    errors.push({ type: "references", error: payload.message });
                  } else {
                    errors.push({
                      type: "references",
                      error: "No se logro establecer conexion con el servidor.",
                    });
                  }
                }
              });
          }
          if (errors.length) {
            this.notify(
              "danger",
              "Error",
              "Se presento un error intentando guardar la información"
            );
            this.setState({
              loading: false,
              message: null,
            });
          } else {
            await this.props.updateStateRequest(idRequest, 8).then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState({
                  loading: false,
                  message: null,
                });
              } else {
                const {
                  data: { payload },
                } = res.payload;
                this.notify("success", "Éxito", payload.message);
                this.props.updateActivityFlow(idRequest, {
                  description: `Levantamiento de requerimiento completado`,
                  state: `Completado`,
                  type: "complete",
                });
                this.onMainInitialValues();
              }
            });
          }
        } else {
          if (typeContract === 1) {
            this.setState({
              spareVisibility: equipmentsFlow || partsEquipmentsFlow,
              addSpare: equipmentsFlow || partsEquipmentsFlow,
              configurationView: partsFlow || partsEquipmentsFlow,
              flowModal: {
                type: "configuration",
                showModal: partsFlow && !partsEquipmentsFlow & !equipmentsFlow,
                title: "Verificación de Configuración",
                options: [
                  {
                    parent: true,
                    label: "Tiene la Configuración",
                    type: "select",
                    key: "configuration",
                    visible: true,
                    required: true,
                    options: ["Seleccione una opción", "No", "Sí"],
                  },
                  {
                    parent: false,
                    label: "Orden de Servicio",
                    type: "text",
                    key: "serviceOrder",
                    visible: false,
                    required: true,
                    max: 10,
                    min: 10,
                    options: [],
                    placeholder: "Ingrese la orden de servicio",
                  },
                  {
                    parent: false,
                    label: "Comentarios (Opcional)",
                    type: "textarea",
                    key: "commentary",
                    visible: false,
                    required: false,
                    max: 200,
                    options: [],
                    placeholder: "Ingrese la orden de servicio",
                  },
                ],
              },
              equipments: partsFlow && !partsEquipmentsFlow & !equipmentsFlow,
              loading: true,
              message: "Actualizando el proceso del requerimiento",
            });
            const errors = [];
            if (justify) {
              await this.props
                .createJustifyByRequest(idRequest, {
                  justify,
                })
                .then((res) => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      errors.push({ type: "justify", error: payload.message });
                    } else {
                      errors.push({
                        type: "justify",
                        error:
                          "No se logro establecer conexion con el servidor.",
                      });
                    }
                  }
                });
            }
            if (referencesRemoved.length) {
              await this.props
                .deactivateReferences({
                  referencesIds: referencesRemoved,
                })
                .then((res) => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      errors.push({
                        type: "references",
                        error: payload.message,
                      });
                    } else {
                      errors.push({
                        type: "references",
                        error:
                          "No se logro establecer conexion con el servidor.",
                      });
                    }
                  }
                });
            }
            if (errors.length) {
              this.notify(
                "danger",
                "Error",
                "Se presento un error intentando guardar la información"
              );
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              await this.props
                .updateStateRequest(
                  idRequest,
                  equipmentsFlow ? 2 : partsEquipmentsFlow ? 3 : 4
                )
                .then((res) => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      this.notify("warning", "Atención", payload.message);
                    } else {
                      this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                    }
                  } else {
                    const {
                      data: { payload },
                    } = res.payload;
                    this.notify("success", "Éxito", payload.message);
                  }
                  this.setState({
                    loading: false,
                    message: null,
                  });
                });
            }
          } else if (typeContract === 2) {
            if (partsFlow && !equipmentsFlow && !partsEquipmentsFlow) {
              console.log(
                "Se envía el requerimiento a asignación de recurso para validar partes, no para obtner configuración"
              );
              this.setState({
                loading: true,
                message: "Actualizando el estado del requerimiento",
              });
              const errors = [];
              if (justify) {
                await this.props
                  .createJustifyByRequest(idRequest, {
                    justify,
                  })
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        errors.push({
                          type: "justify",
                          error: payload.message,
                        });
                      } else {
                        errors.push({
                          type: "justify",
                          error:
                            "No se logro establecer conexion con el servidor.",
                        });
                      }
                    }
                  });
              }
              if (referencesRemoved.length) {
                await this.props
                  .deactivateReferences({
                    referencesIds: referencesRemoved,
                  })
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        errors.push({
                          type: "references",
                          error: payload.message,
                        });
                      } else {
                        errors.push({
                          type: "references",
                          error:
                            "No se logro establecer conexion con el servidor.",
                        });
                      }
                    }
                  });
              }
              if (errors.length) {
                this.notify(
                  "danger",
                  "Error",
                  "Se presento un error intentando guardar la información"
                );
                this.setState((state) => ({
                  loading: !state.loading,
                  message: null,
                }));
              } else {
                await this.props
                  .updateStateRequest(idRequest, 6)
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        this.notify("warning", "Atención", payload.message);
                      } else {
                        this.notify(
                          "danger",
                          "Falló",
                          "No se logro establecer conexion con el servidor."
                        );
                      }
                      this.setState({
                        loading: false,
                        message: null,
                      });
                    } else {
                      const {
                        data: { payload },
                      } = res.payload;
                      this.notify("success", "Éxito", payload.message);
                      this.props.updateActivityFlow(idRequest, {
                        description: `Levantamiento de requerimiento completado`,
                        state: `Completado`,
                        type: "complete",
                      });
                      this.onMainInitialValues();
                    }
                  });
              }
            } else {
              this.setState({
                spareVisibility: equipmentsFlow || partsEquipmentsFlow,
                addSpare: equipmentsFlow || partsEquipmentsFlow,
                configurationView: false,
                flowModal: {
                  type: "configuration",
                  showModal: false,
                  title: "Verificación de Configuración",
                  options: [
                    {
                      parent: true,
                      label: "Tiene la Configuración",
                      type: "select",
                      key: "configuration",
                      visible: true,
                      required: true,
                      options: ["Seleccione una opción", "No", "Sí"],
                    },
                    {
                      parent: false,
                      label: "Orden de Servicio",
                      type: "text",
                      key: "serviceOrder",
                      visible: false,
                      required: true,
                      max: 10,
                      min: 10,
                      options: [],
                      placeholder: "Ingrese la orden de servicio",
                    },
                    {
                      parent: false,
                      label: "Comentarios (Opcional)",
                      type: "textarea",
                      key: "commentary",
                      visible: false,
                      required: false,
                      max: 200,
                      options: [],
                      placeholder: "Ingrese la orden de servicio",
                    },
                  ],
                },
                loading: true,
                message: "Actualizando el proceso del requerimiento",
              });
              const errors = [];
              if (justify) {
                await this.props
                  .createJustifyByRequest(idRequest, {
                    justify,
                  })
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        errors.push({
                          type: "justify",
                          error: payload.message,
                        });
                      } else {
                        errors.push({
                          type: "justify",
                          error:
                            "No se logro establecer conexion con el servidor.",
                        });
                      }
                    }
                  });
              }
              if (referencesRemoved.length) {
                await this.props
                  .deactivateReferences({
                    referencesIds: referencesRemoved,
                  })
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        errors.push({
                          type: "references",
                          error: payload.message,
                        });
                      } else {
                        errors.push({
                          type: "references",
                          error:
                            "No se logro establecer conexion con el servidor.",
                        });
                      }
                    }
                  });
              }
              if (errors.length) {
                this.notify(
                  "danger",
                  "Error",
                  "Se presento un error intentando guardar la información"
                );
                this.setState((state) => ({
                  loading: !state.loading,
                  message: null,
                }));
              } else {
                await this.props
                  .updateStateRequest(
                    idRequest,
                    equipmentsFlow ? 2 : partsEquipmentsFlow ? 3 : 7
                  )
                  .then((res) => {
                    if (res.payload.isAxiosError) {
                      if (res.payload.response) {
                        const {
                          data: { payload },
                        } = res.payload.response;
                        this.notify("warning", "Atención", payload.message);
                      } else {
                        this.notify(
                          "danger",
                          "Falló",
                          "No se logro establecer conexion con el servidor."
                        );
                      }
                      this.setState({
                        loading: false,
                        message: null,
                      });
                    } else {
                      const {
                        data: { payload },
                      } = res.payload;
                      this.notify("success", "Éxito", payload.message);
                      this.setState({
                        loading: false,
                        message: null,
                        equipments: false,
                        partsEquipmentsFlow,
                        configSC: false,
                      });
                    }
                  });
              }
              // this.props.updateStateRequest(
              //   idRequest,
              //   equipmentsFlow ? 2
              //     : partsEquipmentsFlow ? 3
              //       : 5
              // ).then((res) => {
              //   if (res.payload.isAxiosError) {
              //     if (res.payload.response) {
              //       const { data: { payload } } = res.payload.response;
              //       this.notify("warning", "Atención", payload.message);
              //     } else {
              //       this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
              //     }
              //   } else {
              //     const { data: { payload } } = res.payload;
              //     this.notify("success", "Éxito", payload.message);
              //   }
              //   this.setState((state) => ({
              //     loading: !state.loading,
              //     message: null,
              //   }));
              // });
            }
          }
        }
        // this.setState((state) => ({
        //   loading: !state.loading,
        //   message: `Completando el proceso del requerimiento`
        // }));
        // const errors = [];
        // if (justify) {
        //   await this.props.createJustifyByRequest(idRequest, {
        //     justify
        //   }).then((res) => {
        //     if (res.payload.isAxiosError) {
        //       if (res.payload.response) {
        //         const { data: { payload } } = res.payload.response;
        //         errors.push({ type: 'justify', error: payload.message });
        //       } else {
        //         errors.push({ type: 'justify', error: "No se logro establecer conexion con el servidor." });
        //       }
        //     }
        //   });
        // }
        // if (referencesRemoved.length) {
        //   await this.props.deactivateReferences({
        //     referencesIds: referencesRemoved
        //   }).then((res) => {
        //     if (res.payload.isAxiosError) {
        //       if (res.payload.response) {
        //         const { data: { payload } } = res.payload.response;
        //         errors.push({ type: 'references', error: payload.message });
        //       } else {
        //         errors.push({ type: 'references', error: "No se logro establecer conexion con el servidor." });
        //       }
        //     }
        //   });
        // }
        // if (errors.length) {
        //   this.notify('danger', 'Error', 'Se presento un error intentando guardar la información');
        //   this.setState((state) => ({
        //     loading: !state.loading,
        //     message: null,
        //   }));
        // } else {
        //   this.props.updateStateRequest(idRequest, 2).then((res) => {
        //     if (res.payload.isAxiosError) {
        //       if (res.payload.response) {
        //         const { data: { payload } } = res.payload.response;
        //         this.notify("danger", "Falló", payload.message);
        //       } else {
        //         this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        //       }
        //       this.setState((state) => ({
        //         loading: !state.loading,
        //         message: null,
        //       }));
        //     } else {
        //       const { data: { payload } } = res.payload;
        //       this.notify("success", "Éxito", payload.message);
        //       this.props.updateActivityFlow(idRequest, {
        //         description: `Levantamiento de requerimiento completado`,
        //         state: `Completado`,
        //         type: 'complete'
        //       });
        //       this.onMainInitialValues();
        //     }
        //   });
        // }
      }
    }
  };

  _handleOnReferenceFileList = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idRerence } = payload;
        this.setState((state) => ({
          referenceFilesList: state.referenceFilesList.filter(
            (row) => row.uid !== uid
          ),
          referencesRemoved: [...state.referencesRemoved, idRerence],
        }));
      } else {
        this.setState((state) => ({
          referenceFilesList: state.referenceFilesList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        referenceFilesList: fileList,
      });
    }
  };

  handleOnChangeEquipmentSpare = (event, type) => {
    const { id, value } = event.target;
    if (type === "add") {
      const { spareValues } = this.state;
      spareValues[id] = value;
      this.setState({
        spareValues,
      });
    } else if (type === "edit") {
      const { spareEditValues } = this.state;
      spareEditValues[id] = value;
      this.setState({
        spareEditValues,
      });
    }
  };

  handleOnBackEquipmentSpare = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que desea cancelar?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onMainInitialValues()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, cancelar"
          cancelBtnText="No, continuar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnCreateEquipmentSpare = () => {
    const { idRequest, spareValues } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Creando el Equipo Spare`,
    }));
    this.props.createEquipmentSpare(idRequest, spareValues).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          spareValues: {
            partNumber: "",
            description: "",
            amountEquipments: "",
            cost: "",
          },
          referenceView: true,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  handleOnReferenceSpareUpload = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idReference } = payload;
        this.setState((state) => ({
          referenceSpareFileList: state.referenceSpareFileList.filter(
            (row) => row.uid !== uid
          ),
          referenceSpareRemoved: [...state.referenceSpareRemoved, idReference],
        }));
      } else {
        this.setState((state) => ({
          referenceSpareFileList: state.referenceSpareFileList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
        await this.props
          .findReferencesSpareByRequest(this.state.idRequest)
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexión con el servidor."
                );
              }
            }
          });
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        referenceSpareFileList: fileList,
      });
    }
  };

  handleOnCompleteUploadReference = async () => {
    const { idRequest, referenceSpareRemoved } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Cargando archivos de referencia`,
      referenceView: false,
    }));
    if (referenceSpareRemoved.length) {
      await this.props
        .deactivateReferencesSpare({
          referencesIds: referenceSpareRemoved,
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexión con el servidor."
              );
            }
          }
        });
    }
    await this.props.findReferencesSpareByRequest(idRequest).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      }
    });
    this.setState((state) => ({
      loading: !state.loading,
      message: null,
    }));
  };

  handleOnUpdateEquipmentSpare = async () => {
    const { spareEditValues } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Actualizando la linea de equipo spare`,
    }));
    await this.props
      .updateEquipmentSpareById(spareEditValues.id, spareEditValues)
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexión con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
            spareEditValues: {
              id: undefined,
              partNumber: undefined,
              description: undefined,
              amountEquipments: undefined,
              cost: undefined,
            },
            editSpare: false,
          }));
        }
      });
  };

  handleOnEditEquipmentSpare = (equipment) => {
    const { spareEditValues } = this.state;
    Object.keys(spareEditValues).forEach((element) => {
      spareEditValues[element] = equipment[element];
    });
    this.setState({
      editSpare: true,
      spareEditValues,
    });
  };

  onDeleteEquipmentSpare = (id) => {
    this.setState((state) => ({
      loading: !state.loading,
      message: `Eliminando la linea de equipo spare`,
    }));
    this.props.deactivateEquipmentSpareById(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexión con el servidor."
          );
        }
      } else {
        this.hideAlert();
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  handleOnDeleteEquipmentSpare = (equipment) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que desea eliminar el equipo?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteEquipmentSpare(equipment.id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnSaveEquipmentSpareProcess = async () => {
    const { idRequest, referenceSpareRemoved } = this.state;
    const { equipmentsSpare } = this.props;
    if (equipmentsSpare.length) {
      this.setState((state) => ({
        loading: !state.loading,
        message: "Guardando el proceso del requerimiento",
      }));
      if (referenceSpareRemoved.length) {
        await this.props
          .deactivateReferencesSpare({
            referencesIds: referenceSpareRemoved,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexión con el servidor."
                );
              }
            }
          });
      }
      this.props
        .updateActivityFlow(idRequest, {
          description: `Equipos Spare pendiente de completar`,
          state: `Pendiente`,
          type: "pending",
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          } else {
            this.onMainInitialValues();
          }
        });
    } else {
      this.notify(
        "warning",
        "Atención",
        "No se detectaron cambios que deban ser guardados"
      );
    }
  };

  handleOnCompleteEquipmentSpare = async () => {
    console.log("Completar Equipos Spare");
    const {
      idRequest,
      configurationView,
      referenceSpareFileList,
      partsEquipmentsFlow,
      referenceSpareRemoved,
    } = this.state;
    const { referenceSpare } = this.props;
    if (!referenceSpareFileList.length && !referenceSpare.length) {
      this.notify(
        "warning",
        "Atención",
        "Es obligatorio adjuntar al menos una referencia para el o los equipos agregados"
      );
    } else {
      if (configurationView) {
        this.props.updateActivityFlow(idRequest, {
          description: `Carga de lineas de Equipos Spare al requerimiento`,
          state: `Equipos Spare Cargados`,
          type: "spare",
        });
        this.setState({
          // spareVisibility: false,
          // addSpare: false,
          flowModal: {
            type: "configuration",
            showModal: true,
            title: "Verificación de Configuración",
            options: [
              {
                parent: true,
                label: "Tiene la Configuración",
                type: "select",
                key: "configuration",
                visible: true,
                required: true,
                options: ["Seleccione una opción", "No", "Sí"],
              },
              {
                parent: false,
                label: "Orden de Servicio",
                type: "text",
                key: "serviceOrder",
                visible: false,
                required: true,
                max: 10,
                min: 10,
                options: [],
                placeholder: "Ingrese la orden de servicio",
              },
              {
                parent: false,
                label: "Comentarios (Opcional)",
                type: "textarea",
                key: "commentary",
                visible: false,
                required: false,
                max: 200,
                options: [],
                placeholder: "Ingrese la orden de servicio",
              },
            ],
          },
        });
      } else {
        console.log("Solo equipos Spare, finaliza");
        this.setState((state) => ({
          loading: !state.loading,
          message: `Completando carga de Equipos Spare`,
        }));
        if (referenceSpareRemoved.length) {
          await this.props
            .deactivateReferencesSpare({
              referencesIds: referenceSpareRemoved,
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexión con el servidor."
                  );
                }
              }
            });
        }
        await this.props
          .updateStateRequest(idRequest, partsEquipmentsFlow ? 6 : 8)
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              this.props.updateActivityFlow(idRequest, {
                description: `Carga de lineas de Equipos Spare al requerimiento`,
                state: `Equipos Spare Cargados`,
                type: "spare",
              });
              this.onMainInitialValues();
            }
          });
      }
    }
  };

  _handleOnChangeValueFlowModal = (event) => {
    const { name, value } = event.target;
    let { flowModal, initialValuesFlow } = this.state;
    if (name === "configuration") {
      flowModal.options.map((row) => {
        if (!row.parent) {
          row.visible = value === "1" ? true : false;
        }
        return row;
      });
      this.setState({
        flowModal,
      });
    }
    initialValuesFlow[name] = value;
    this.setState({
      initialValuesFlow,
    });
  };

  _handleOnToggleModal = (type) => {
    this.setState({
      [type]: {
        type: null,
        showModal: false,
        title: null,
        options: [],
      },
      initialValuesFlow: {},
    });
  };

  _handleOnWorkFlow = async (values, type) => {
    const { idRequest } = this.state;
    if (type === "configuration") {
      const { configuration } = values;
      if (configuration === "1") {
        const { commentary, serviceOrder } = values;
        this.setState((state) => ({
          loading: !state.loading,
          message:
            "Actualizando el flujo y notificando a la oficina de planificación",
        }));
        const errors = [];
        if (commentary) {
          await this.props
            .createCommentaryByRequest(idRequest, {
              commentary,
              hasConfiguration: 0,
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  errors.push({ type: "commentary", error: payload.message });
                } else {
                  errors.push({
                    type: "commentary",
                    error: "No se logro establecer conexion con el servidor.",
                  });
                }
              }
            });
        }
        if (errors.length) {
          this.notify(
            "danger",
            "Error",
            "Se presento un error intentando guardar la información"
          );
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          await this.props
            .createServiceOrderRequest(idRequest, {
              serviceOrder,
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  errors.push({ type: "serviceOrder", error: payload.message });
                } else {
                  errors.push({
                    type: "serviceOrder",
                    error: "No se logro establecer conexion con el servidor.",
                  });
                }
              }
            });
          if (errors.length) {
            this.notify(
              "danger",
              "Error",
              "Se presento un error intentando guardar la información"
            );
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          } else {
            await this.props
              .updateStateRequest(idRequest, 5)
              .then(async (res) => {
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    this.notify("warning", "Atención", payload.message);
                  } else {
                    this.notify(
                      "danger",
                      "Falló",
                      "No se logro establecer conexion con el servidor."
                    );
                  }
                  this.setState((state) => ({
                    loading: !state.loading,
                    message: null,
                  }));
                } else {
                  await this.props.updateActivityFlow(idRequest, {
                    description: "En espera de la asignación de un ingeniero",
                    state: "Asignación Pendiente",
                    type: "upgrade",
                  });
                  const {
                    data: { payload },
                  } = res.payload;
                  this.notify("success", "Éxito", payload.message);
                  this.onMainInitialValues();
                  // this.findMyRequest();
                }
              });
          }
        }
      } else if (configuration === "2") {
        this.setState({
          spareVisibility: false,
          addSpare: false,
          equipments: false,
          configurationView: true,
        });
        this._handleOnToggleModal("flowModal");
      }
    }
  };

  _handleOnCloseConfiguration = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que desea salir?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onMainInitialValues()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, salir"
          cancelBtnText="No, continuar"
          btnSize="md"
        />
      ),
    });
  };

  _handleOnChangeJustify = (event) => {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  };

  _handleOnConfigurationUpload = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idConfiguration } = payload;
        this.setState((state) => ({
          configurationsFilesList: state.configurationsFilesList.filter(
            (row) => row.uid !== uid
          ),
          configurationsRemoved: [
            ...state.configurationsRemoved,
            idConfiguration,
          ],
        }));
      } else {
        this.setState((state) => ({
          configurationsFilesList: state.configurationsFilesList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        configurationsFilesList: fileList,
      });
    }
  };

  _handleOnCompleteConfiguration = async () => {
    const {
      configurationsFilesList,
      configurationsRemoved,
      commentary,
      idRequest,
    } = this.state;
    if (!configurationsFilesList.length) {
      this.notify(
        "warning",
        "Atención",
        "Es requerido que se adjunte la configuración para avanzar"
      );
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Completando el proceso de la configuración`,
      }));
      const errors = [];
      if (commentary) {
        await this.props
          .createCommentaryByRequest(idRequest, {
            commentary,
            hasConfiguration: 1,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                errors.push({ type: "commentary", error: payload.message });
              } else {
                errors.push({
                  type: "commentary",
                  error: "No se logro establecer conexion con el servidor.",
                });
              }
            }
          });
      }
      if (errors.length) {
        this.notify(
          "danger",
          "Error",
          "Se presento un error intentando guardar la información"
        );
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        if (configurationsRemoved.length) {
          await this.props
            .deactivateConfigurations({
              configurationsIds: configurationsRemoved,
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  errors.push({ type: "references", error: payload.message });
                } else {
                  errors.push({
                    type: "references",
                    error: "No se logro establecer conexion con el servidor.",
                  });
                }
              }
            });
        }
        if (errors.length) {
          this.notify(
            "danger",
            "Error",
            "Se presento un error intentando guardar la información"
          );
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          await this.props
            .updateStateRequest(idRequest, 6)
            .then(async (res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState((state) => ({
                  loading: !state.loading,
                  message: null,
                }));
              } else {
                await this.props.updateActivityFlow(idRequest, {
                  description: "Archivos de Configuración Cargados",
                  state: "Configuración Cargada",
                  type: "configuration",
                });
                await this.props.updateActivityFlow(idRequest, {
                  description: "En espera de la asignación de un ingeniero",
                  state: "Asignación Pendiente",
                  type: "configuration",
                });
                const {
                  data: { payload },
                } = res.payload;
                this.notify("success", "Éxito", payload.message);
                this.onMainInitialValues();
                // this.findMyRequest();
              }
            });
        }
      }
    }
  };

  handleOnEditEquipment = (values) => {
    const { equipmentsEditValues } = this.state;
    const { country } = values;
    const countries = [
      { id: "CR", name: "Costa Rica", key: "CR" },
      { id: "SV", name: "El Salvador", key: "SV" },
      { id: "GT", name: "Guatemala", key: "GT" },
      { id: "HN", name: "Honduras", key: "HN" },
      { id: "MD", name: "Miami", key: "MD" },
      { id: "NI", name: "Nicaragua", key: "NI" },
      { id: "PA", name: "Panamá", key: "PA" },
      { id: "DO", name: "República Dominicana", key: "DO" },
    ];
    const countrySelect = countries.find((row) => row.name === country);
    Object.keys(equipmentsEditValues).forEach((element) => {
      equipmentsEditValues[element] = values[element];
    });
    equipmentsEditValues["country"] = countrySelect.key;
    equipmentsEditValues["price"] = parseInt(values["price"]);
    equipmentsEditValues["practice"] = values["idPractice"];
    equipmentsEditValues["platform"] = values["idPlatform"];
    equipmentsEditValues["officeHours"] = values["idOfficeHours"];
    equipmentsEditValues["equipmentParts"] = values["idEquipmentParts"];
    equipmentsEditValues["automaticRenewal"] = values["idAutomaticRenewal"];
    equipmentsEditValues["timeChangePart"] = values["idTimeChangePart"];
    equipmentsEditValues["validityService"] = values["idValidityService"];
    this.setState({
      editEquipment: true,
      equipmentsEditValues,
      amountInEdit: equipmentsEditValues.amountEquipments,
    });
  };

  handleOnUpdateEquipment = async () => {
    const { equipmentsEditValues, amountInEdit, amountEquipmentsCont } =
      this.state;
    const { amountEquipments } = equipmentsEditValues;
    const amountNew = amountEquipments - amountInEdit;
    equipmentsEditValues["idPractice"] = equipmentsEditValues["practice"];
    equipmentsEditValues["idPlatform"] = equipmentsEditValues["platform"];
    equipmentsEditValues["idOfficeHours"] = equipmentsEditValues["officeHours"];
    equipmentsEditValues["idEquipmentParts"] =
      equipmentsEditValues["equipmentParts"];
    equipmentsEditValues["idAutomaticRenewal"] =
      equipmentsEditValues["automaticRenewal"];
    equipmentsEditValues["idTimeChangePart"] =
      equipmentsEditValues["timeChangePart"];
    equipmentsEditValues["idValidityService"] =
      equipmentsEditValues["validityService"];
    this.setState((state) => ({
      loading: !state.loading,
    }));
    await this.props
      .updateEquipmentById(equipmentsEditValues.id, {
        values: equipmentsEditValues,
        type: "ibm",
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.setState({
            equipmentsEditValues: {
              id: undefined,
              country: undefined,
              typeModel: undefined,
              serial: undefined,
              platform: undefined,
              amountMaintenance: undefined,
              amountEquipments: undefined,
              practice: undefined,
              officeHours: undefined,
              timeChangePart: undefined,
              validityService: undefined,
              automaticRenewal: undefined,
              equipmentParts: undefined,
              viatic: undefined,
              validWarranty: undefined,
              price: undefined,
            },
            editEquipment: false,
            amountEquipmentsCont: amountEquipmentsCont + amountNew,
            amountInEdit: 0,
          });
          // this._handleOnToggleOpenEditEquipment();
        }
        this.setState((state) => ({
          loading: !state.loading,
        }));
      });
  };

  onDeleteEquipment = async (id) => {
    const { idRequest, amountEquipmentsCont } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: "Eliminando la linea de equipo",
    }));
    await this.props.deactivateEquipmentById(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          alert: null,
          amountEquipmentsCont: amountEquipmentsCont - 1,
        });
        this.props.findEquipmentsCreatedByRequest(idRequest);
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  handleOnDeleteEquipment = (values) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro que desea eliminar el equipo?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onDeleteEquipment(values.id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnConfigSC = (flag) => {
    const {
      flow,
      referenceFilesList,
      justify,
      ibmEquipments,
      ciscoEquipments,
    } = this.state;
    const {
      equipmentsCreated: { ibm, cisco },
    } = this.props;
    if (flow["IBM"] !== "complete" && (ibmEquipments.length || ibm.length)) {
      this.notify(
        "warning",
        "Atención",
        "Aun no se han completado los equipos"
      );
    } else {
      if (
        this.onVerifyAllPrices(ibmEquipments, ciscoEquipments, "Precio") ||
        this.onVerifyAllPrices(ibm, cisco, "price")
      ) {
        this.notify(
          "warning",
          "Atención",
          "Se detectaron lineas de equipos sin precios, para avanzar es necesario que se ingresen todos los precios"
        );
      } else if (flag && !referenceFilesList.length && justify === null) {
        this.notify(
          "warning",
          "Atención",
          "Es obligatorio adjuntar la referencia o escribir una justificación para el o los precios agregados"
        );
      } else {
        this.setState({
          configSC: true,
        });
      }
    }
  };

  handleOnToggleModalErrors = () => {
    this.setState((state) => ({
      modalEquipmentsErrors: !state.modalEquipmentsErrors,
    }));
  };

  handleOnToggleEquipmentsUbicationsModal = () => {
    this.setState({
      modalEquipmentsUbications: {
        showModal: false,
        amount: 0,
      },
    });
  };

  onVerifyCompleteEquipments = (amount) => {
    this.hideAlert();
    this.setState({
      modalEquipmentsUbications: {
        showModal: true,
        amount,
      },
    });
    // this.setState({
    //   alert: (
    //     <ReactBSAlert
    //       custom
    //       showCancel
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="¿Está seguro que desea avanzar?"
    //       customIcon={
    //         <div
    //           className="swal2-icon swal2-question swal2-animate-question-icon"
    //           style={{ display: "flex" }}
    //         >
    //           <span className="swal2-icon-text">?</span>
    //         </div>
    //       }
    //       onConfirm={() => this._handleOnCompleteEquipments()}
    //       onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="success"
    //       cancelBtnBsStyle="danger"
    //       confirmBtnText="Sí, avanzar"
    //       cancelBtnText="No, cancelar"
    //       btnSize="md"
    //     >
    //       El requerimiento se actualizaria a la cantidad de {amount} equipos
    //     </ReactBSAlert>
    //   )
    // });
  };

  handleOnCompleteEquipments = () => {
    const { amountEquipments, ibmEquipments } = this.state;
    const {
      equipmentsCreated: { ibm },
    } = this.props;
    if (
      amountEquipments !== ibm.length &&
      amountEquipments !== ibmEquipments.length
    ) {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Está seguro que desea avanzar?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() =>
              this.onVerifyCompleteEquipments(
                ibm.length
                  ? ibm.length
                  : ibmEquipments.length
                  ? ibmEquipments.length
                  : 0
              )
            }
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, avanzar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          >
            En el requerimiento se indicaron {amountEquipments} y estas
            agregando{" "}
            {ibm.length
              ? ibm.length
              : ibmEquipments.length
              ? ibmEquipments.length
              : 0}
          </ReactBSAlert>
        ),
      });
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Creación de equipos"
            onConfirm={() => this._handleOnCompleteEquipments()}
            onCancel={() => this._handleOnCompleteEquipments()}
            btnSize=""
            text="Al dar continuar esta garantizando que los equipos aun esten legibles a tener soporte según la politica"
          >
            Al dar continuar esta garantizando que los equipos aun esten
            legibles a tener soporte según la politica
          </ReactBSAlert>
        ),
      });
      // this._handleOnCompleteEquipments();
    }
  };

  handleOnWorkFlowEquipmentsUbications = (values) => {
    const { idRequest } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Actualizando la información`,
    }));
    this.props
      .updateAmountEquipmentsByRequest(idRequest, values)
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this._handleOnCompleteEquipments();
          this.handleOnToggleEquipmentsUbicationsModal();
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      });
  };

  render() {
    const {
      name,
      parentName,
      formOptionsRequest,
      initialValuesRequest,
      formOptionsEquipments,
      equipmentsCreated,
      equipmentsSpare,
      referenceSpare,
    } = this.props;

    const {
      page,
      alert,
      enabled,
      loading,
      message,
      flow,
      flavor,
      addSpare,
      configSC,
      editSpare,
      flowModal,
      equipments,
      fileList,
      loadFile,
      idRequest,
      spareValues,
      typeContract,
      criticalParts,
      ibmEquipments,
      editEquipment,
      referenceView,
      equipmentAdded,
      ciscoEquipments,
      spareEditValues,
      spareVisibility,
      amountEquipments,
      equipmentsValues,
      configurationView,
      initialValuesFlow,
      referenceFilesList,
      equipmentsEditValues,
      referenceSpareFileList,
      configurationsFilesList,
      modalEquipmentsErrors,
      equipmentsErrors,
      modalEquipmentsUbications,
      request,
    } = this.state;

    return (
      <>
        {alert}
        <WorkFlowModal
          type={flowModal.type}
          title={flowModal.title}
          showModal={flowModal.showModal}
          options={flowModal.options}
          loading={loading}
          initialValues={initialValuesFlow}
          onChange={this._handleOnChangeValueFlowModal}
          toggleModal={this._handleOnToggleModal}
          onWorkFlow={this._handleOnWorkFlow}
        />
        <EquipmentsUbicationsModal
          options={modalEquipmentsUbications}
          toggleModal={this.handleOnToggleEquipmentsUbicationsModal}
          onWorkFlow={this.handleOnWorkFlowEquipmentsUbications}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        {idRequest && (
          <EquipmentsError
            showModal={modalEquipmentsErrors}
            toggleModal={this.handleOnToggleModalErrors}
            errors={equipmentsErrors}
            cols={[
              { name: "Tipo de Modelo", key: "TipoModelo", hide: false },
              {
                name: "Serie",
                key: "Serie",
                hide: typeContract === 2 ? true : false,
              },
              { name: "Práctica", key: "Practica", hide: false },
              {
                name: "Cantidad de Mantenimientos",
                key: "CantidadMantenimientos",
                hide: false,
              },
              {
                name: "Cantidad de Equipos",
                key: "CantidadEquipos",
                hide: typeContract === 1 ? true : false,
              },
              { name: "Plataforma", key: "Plataforma", hide: false },
              {
                name: "Horario de Atención",
                key: "HorarioAtencion",
                hide: false,
              },
              {
                name: "Tiempo Cambio de Parte",
                key: "TiempoCambioParte",
                hide: false,
              },
              { name: "Años del Servicio", key: "AñosServicio", hide: false },
              {
                name: "Renovación Automatica",
                key: "RenovacionAutomatica",
                hide: false,
              },
              {
                name: "Incluye Partes/Equipos",
                key: "IncluyePartesEquipos",
                hide: false,
              },
              {
                name: `${typeContract === 1 ? "Precio $" : "Costo $"}`,
                key: "Precio",
                hide: false,
              },
              { name: "Error", key: "msg", hide: false },
            ]}
          />
        )}
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            <Row>
              <Col>
                {equipments ? (
                  <EquipmentsListForm
                    parts={criticalParts}
                    edit={editEquipment}
                    flow={flow}
                    configSC={configSC}
                    type={typeContract}
                    flavor={flavor}
                    state={equipmentAdded}
                    enabled={enabled}
                    idRequest={idRequest}
                    request={request}
                    title="Creación de Equipos"
                    subtitle="Agregar la lista de equipos al requerimiento"
                    formOptions={formOptionsEquipments}
                    initialValues={equipmentsValues}
                    amountEquipments={amountEquipments}
                    initialEditValues={equipmentsEditValues}
                    loadView={loadFile}
                    fileList={fileList}
                    referenceFilesList={referenceFilesList}
                    equipments={{ ibmEquipments, ciscoEquipments }}
                    equipmentsCreated={equipmentsCreated}
                    // initialValues={initialValuesEquipments}
                    configurationsFilesList={configurationsFilesList}
                    onConfigSC={this.handleOnConfigSC}
                    onCancel={this._handleOnCancelEquipmentForm}
                    onCreate={this._handleOnCreateEquipment}
                    onEditEquipment={this.handleOnEditEquipment}
                    onUpdateEquipment={this.handleOnUpdateEquipment}
                    onDeleteEquipment={this.handleOnDeleteEquipment}
                    onAddEquipment={this._handleOnAddEquipments}
                    onChangeValues={this._handleOnChageEquipmentsValues}
                    toggleLoadView={this._handleOnToggleLoadView}
                    onChangeJustify={this._handleOnChangeJustify}
                    onFileList={this._handleOnFileUpload}
                    onFileListReference={this._handleOnReferenceFileList}
                    onFileListConfigSC={this._handleOnConfigurationUpload}
                    onCreateEquipments={this._handleOnCreateEquipments}
                    onCompleteEquipments={this.handleOnCompleteEquipments}
                    onSaveAndBack={this._handleOnSaveAndBack}
                    onCompleteRequest={this._handleOnCompleteRequest}
                  />
                ) : spareVisibility ? (
                  <SpareEquipments
                    title="Equipos Spare"
                    subtitle="Asignación de Equipos Spare al requerimiento"
                    add={addSpare}
                    edit={editSpare}
                    cols={[
                      { name: "Partnumber", key: "partNumber" },
                      { name: "Descripción", key: "description" },
                      { name: "Cantidad de Equipos", key: "amountEquipments" },
                      { name: "Costo Unitario", key: "cost" },
                    ]}
                    options={[
                      {
                        name: "Partnumber",
                        key: "partNumber",
                        placeholder: "Ingrese el Partnumber",
                        type: "text",
                        max: 17,
                      },
                      {
                        name: "Descripción",
                        key: "description",
                        placeholder: "Ingrese el Descripción",
                        type: "text",
                        max: 500,
                      },
                      {
                        name: "Cantidad de Equipos",
                        key: "amountEquipments",
                        placeholder: "Ingrese la Cantidad de Equipos",
                        type: "number",
                        max: 99999,
                      },
                      {
                        name: "Costo",
                        key: "cost",
                        placeholder: "Ingrese el Costo",
                        type: "number",
                        max: 9999999.99,
                      },
                    ]}
                    initialValues={spareValues}
                    initialEditValues={spareEditValues}
                    rows={equipmentsSpare}
                    references={referenceSpare}
                    referenceView={referenceView}
                    idRequest={idRequest}
                    fileList={referenceSpareFileList}
                    onFileList={this.handleOnReferenceSpareUpload}
                    onEdit={this.handleOnEditEquipmentSpare}
                    onBack={this.handleOnBackEquipmentSpare}
                    onSave={this.handleOnSaveEquipmentSpareProcess}
                    onDelete={this.handleOnDeleteEquipmentSpare}
                    onCreate={this.handleOnCreateEquipmentSpare}
                    onUpdate={this.handleOnUpdateEquipmentSpare}
                    onChange={this.handleOnChangeEquipmentSpare}
                    onComplete={this.handleOnCompleteEquipmentSpare}
                  />
                ) : configurationView ? (
                  <CardConfiguration
                    title="Configuración"
                    subtitle="Adjuntar los archivos referentes a la configuración"
                    idRequest={idRequest}
                    fileList={configurationsFilesList}
                    onBack={this._handleOnCloseConfiguration}
                    onChange={this._handleOnChangeJustify}
                    onComplete={this._handleOnCompleteConfiguration}
                    onFileList={this._handleOnConfigurationUpload}
                  />
                ) : (
                  <RequestForm
                    title="Requerimientos Digitales"
                    subtitle="Creación de un requerimiento digital a una Oportunidad"
                    page={page}
                    data={formOptionsRequest}
                    initialValues={initialValuesRequest}
                    notify={this.notify}
                    onSearch={this._handleOnSearchOptionsForm}
                    onChangePage={this._handleOnChangePage}
                    onCancel={this._handleOnCancelRequeriment}
                    onCreate={this._handleOnCreateRequeriment}
                  />
                )}
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

CreateRequirement.defaultProps = {
  name: "Levantamiento de Requerimiento",
  parentName: "Requerimientos Digitales",
  formOptionsRequest: {},
  initialValuesRequest: {},
  initialValuesEquipments: {},
  formOptionsEquipments: {
    coverageLevel: [],
    officeHours: [],
    automaticRenewal: [],
    timeChangePart: [],
    validityService: [],
    productIds: [],
    includesParts: [],
  },
  equipmentsCreated: {
    ibm: [],
    cisco: [],
  },
  equipmentsSpare: [],
  referenceSpare: [],
  request: [],
};

CreateRequirement.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  formOptionsRequest: PropTypes.object.isRequired,
  initialValuesRequest: PropTypes.object.isRequired,
  initialValuesEquipments: PropTypes.object.isRequired,
  formOptionsEquipments: PropTypes.object.isRequired,
  equipmentsCreated: PropTypes.object.isRequired,
  equipmentsSpare: PropTypes.array.isRequired,
  referenceSpare: PropTypes.array.isRequired,
  request: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  formOptionsRequest: getDataFormRequirement(state),
  initialValuesRequest: getInitialValuesRequirement(state),
  formOptionsEquipments: getInitialValuesEquipments(state),
  initialValuesEquipments: getInitialValuesFormEquipments(state),
  equipmentsCreated: getEquipmentsCreated(state),
  equipmentsSpare: getEquipmentsSpare(state),
  referenceSpare: getReferencesSpareByRequest(state),
  request: getRequerimentsByUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    clearData,
    createManyEquipments,
    createEquipmentSpare,
    createOneEquipment,
    createRequirement,
    createJustifyByRequest,
    createCommentaryByRequest,
    createServiceOrderRequest,
    findEquipmentsCreatedByRequest,
    findFormValuesRequestOpportunity,
    findFormValuesEquipmentsRequest,
    findReferencesSpareByRequest,
    updateActivityFlow,
    updateStateRequest,
    updateEquipmentById,
    updateEquipmentSpareById,
    deactivateReferences,
    deactivateReferencesSpare,
    deactivateEquipmentById,
    deactivateEquipmentSpareById,
    updateAmountEquipmentsByRequest,
  })(CreateRequirement)
);
