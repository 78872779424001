import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const requerimentsEngineers = handleActions({
  [constants.GET_REQUESTS_ASSIGNATION_USER]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const criticalPartsKit = handleActions({
  [constants.GET_CRITICAL_PARTS_KIT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const partsEquipment = handleActions({
  [constants.GET_EQUIPMENTS_IDS_SELECTED_PARTS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.equipments;
    }
    return [];
  },
  [constants.CREATE_SELECTED_PARTS_EQUIPMENT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return [...state, data.payload.data];
    }
    return [];
  },
  [constants.GET_REQUESTS_ASSIGNATION_USER]: () => [],
  [constants.SIGN_OUT]: () => [],
}, []);

export const selectedPartsKit = handleActions({
  [constants.GET_SELECTED_PARTS_KIT_BY_EQUIPMENT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const partsEquipmentsRequest = handleActions({
  [constants.GET_SELECTED_PARTS_KIT_BY_REQUESTS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const pendingPartsEquipmentsRequest = handleActions({
  [constants.GET_EQUIPMENTS_IDS_PENDINGS_PARTS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const historyPartsEquipmentsRequest = handleActions({
  [constants.GET_HISTORY_SELECTED_PARTS_KIT_BY_REQUESTS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});
