// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

moment.locale("es");

const EditMedicationModal = props => {

  const {
    title,
    options: {
      showModal,
      values,
    },
    loading,
    message,
    toggleModal,
    onSubmit,
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.medication || values.medication === "")
      errors.medication = "Debes ingresar el nombre.";
    if (!values.startedDate || values.startedDate === "")
      errors.startedDate = "Debes ingresar la fecha.";
    if (!values.currently || values.currently === "0")
      errors.currently = "Debes seleccionar una opción.";
    if (!values.dosis || values.dosis === "")
      errors.dosis = "Debes ingresar la dosis.";
    if (!values.sideEffects || values.sideEffects === "")
      errors.sideEffects = "Debes ingresar un efecto secundario.";
    return errors;
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={values}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label className="form-control-label" for="medication">Medicamento</Label>
                      <Field name="medication">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Escriba aquí el nombre del medicamento"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" for="startedDate">Fecha de Inicio</Label>
                      <Field name="startedDate">
                        {({ input, meta }) => (
                          <>
                            <ReactDatetime
                              {...input}
                              inputProps={{
                                placeholder: "Elija una fecha",
                                className: !meta.error && meta.modified && meta.touched ? "is-valid form-control" : meta.error && meta.touched ? "is-invalid form-control" : "form-control"
                              }}
                              timeFormat={false}
                            />
                            {
                              meta.error && meta.touched && <span style={{
                                marginTop: '0.25rem',
                                fontSize: '80%',
                                color: '#fb6340'
                              }}>{meta.error}</span>
                            }
                          </>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" for="currently">¿Sigue Actualmente?</Label>
                      <Field name="currently">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              valid={!meta.error && meta.modified && meta.touched}
                              invalid={meta.error && meta.touched}
                            >
                              <option key="0" value="0">Seleccione una opción</option>
                              <option key="1" value="1">Sí</option>
                              <option key="2" value="2">No</option>
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" for="dosis">Dosis</Label>
                      <Field name="dosis">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Escriba aquí la dosis"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" for="sideEffects">Efectos Secundarios</Label>
                      <Field name="sideEffects">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Escriba aquí si tiene efectos secundarios"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-2"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Actualizar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

EditMedicationModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default EditMedicationModal;