/*!

=========================================================
*Autopp - MyRequestsAutopp
=========================================================

* Este componente contiene una tabla con todas las gestiones
de los que han generado opps (si es admin), y si no tiene rol 
de admin, solo le da un reporte de las opps propias.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerías de react.
import React, { useContext, useState, useEffect } from 'react'
import {
  PaginationItem,
  PaginationLink,
  Pagination,
  CardHeader,
  FormGroup,
  Button,
  Input,
  Table,
  Badge,
  Card,
  Col,
  Row
} from "reactstrap";

import CardFooter from 'reactstrap/lib/CardFooter';

//Multiselect
import { MultiSelect } from "react-multi-select-component";

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Import de card de estadisticas.
import CardsRequestsStats from './CardsRequestsStats';

//Import de Spinner
import { Spin } from "antd";

//Url base para get o post
import { urlBase } from "api/urls.jsx";


//Import para consulta de BAW en DB
import { useDispatch } from 'react-redux';
import { getDataBAWRows } from 'actions/autoppLdrs';

//Urls
import urls from "api/urls.jsx";



export const MyRequestsAutopp = () => {


  //#region States Globales
  const {
    setColumnsSelectedTableRequests,
    columnsSelectedTableRequests,
    setReloadTableRequests,
    optionsRequestsSelect,
    handleOnfilterDynamic,
    reloadTableRequests,
    renderRowsByFilters,
    rowsRequests,
    setModalBAW,
    roles

  } = useContext(UserContext);
  //#endregion

  //#region States Locales

  const [sizePerPage, setSizePerPage] = useState(5)
  const rows = renderRowsByFilters(rowsRequests.rows);

  //state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);

  //console.log(rows);

  //#endregion

  //#region Métodos
  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => handleOnSetPage(i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  //#endregion

  //#region Handles 

  //Establece el número de filas por pagina 
  const handleOnSetPage = (page) => {
    setPage(page);
  };

  const handleSetNumPag = (e) => {
    setPage(1);
    const value = e.target.value;
    setSizePerPage(value);
  }


  // Función para abrir el modal de ver el status de BAW por gestión.
  const dispatch = useDispatch();

  const handleOpenBawStatus = async (event, row) => {

    dispatch(getDataBAWRows({ oppId: row.id })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        //console.log(payload.data.payload.rows);
        const value = payload.data.payload.rows;
        setModalBAW((prevState) => ({
          ...prevState,
          length: payload.data.payload.rows.length,
          showBAWModal: !prevState.editModal,
          rowsBAW: value,
          opp: row
        }))
      }
    })


  };

  //#endregion 

  //#region Efecto para cargar filas cuando arranque por primera vez.
  useEffect(() => {
    setReloadTableRequests(true);
  }, [])
  //#endregion

  //Metodo para verificar si es admin o user
  const isAdmin = () => {
    if (roles.some((row) => row.indexOf("AutoppLdrs Admin") !== -1)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>


      <Card className=" ">

        <CardHeader className="bg-transparent border-0">
          <h3 className=" mb-0">Status de oportunidades</h3>
        </CardHeader>

        {isAdmin() && //Solo los admin ven las estadisticas 
          <CardsRequestsStats />
        }

        <CardHeader className="bg-transparent border-0">


          <Row className=" ">
            <Col xs="7" sm="8" style={{ display: "flex", justifyContent: "flex-start" }}>
              <Col xs="auto" sm="auto" className="bg-transparent border-0">
                <h3 className=" mb-0">
                  Gestiones </h3>
              </Col>


              <Col xs="auto" sm='auto '>
                <MultiSelect
                  size="sm"
                  id="multiselectFilter"
                  options={optionsRequestsSelect}
                  value={columnsSelectedTableRequests}
                  onChange={setColumnsSelectedTableRequests}
                  labelledBy="Select"
                />

              </Col>

            </Col>


            <Col xs="auto" sm="4" className=" " style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button id="refreshBtn" className="btn-neutral" size="sm" block type="button" style={{ width: "100px ", height: "30px" }}
                onClick={(e) => setReloadTableRequests(true)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-sync-alt" />
                </span>
                <span className="btn-inner--text">
                  {" "}Refrescar
                </span>
              </Button>
            </Col>
          </Row>

        </CardHeader>


        <Spin size="large" spinning={reloadTableRequests}>
          <Table
            className="align-items-center  table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">

              <tr>
                {
                  columnsSelectedTableRequests.map((item, key) => {
                    return (
                      item.value !== "seeStatusBaw" && item.value !== "downloadLDR" ?

                        <th key={key} className="justify-content-md-center">

                          {/*item.label*/}

                          <FormGroup key={key}>
                            <label
                              style={{ fontSize: "12px" }}
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {`${item.label}:`}
                            </label>
                            <Input
                              id={item.id}
                              key={key}
                              className="form-control"
                              type="text"
                              placeholder={`Buscar ${item.label}...`}
                              onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                            />
                          </FormGroup>
                        </th>
                        :
                        <th key={key} className="justify-content-md-center">

                          {/* <label
                            style={{ fontSize: "12px" }}
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {`${item.label}:`}
                          </label>*/}



                        </th>


                    )
                  })

                }
              </tr>
            </thead>
            <tbody className='list'>

              {rows.length > 0 ?

                
                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {
                  return (
                    <tr key={key}>
                      {
                        columnsSelectedTableRequests.map((col, keyCol) => {
                          return (
                            col.value === "status" ? (
                              <td key={key + keyCol} className="justify-content-md-center">

                                <Badge key={key + keyCol} color={row.statusType} pill>
                                  {row.status}
                                </Badge>
                              </td>
                            ) : col.value === "seeStatusBaw" ? ( //Este status es para los dos botones de LDR y BAW.



                              (row.salesCycleId === 1 /*GTL Quotation*/ ||
                                row.salesCycleId === 4 /*Quotation*/ ||
                                row.salesCycleId === 6 /*GESTO Quotation*/
                              ) ? (

                                <td key={key + keyCol} className="justify-content-md-center">
                                  <Row key={key + keyCol + "row"}>
                                    {row.statusId !== "1" && //No lo muestre cuando apenas se está generando la opp
                                      row.salesCycleId === 1 /*GTL Quotation*/ &&

                                      <Col key={key + keyCol + "col"}>

                                        <a
                                          key={key + keyCol}
                                          className="table-action table-action"
                                          onClick={(e) => handleOpenBawStatus(e, row)}
                                          href="#"
                                          id="edit"
                                        >
                                          <i className="fas fa-edit" /> Baw
                                        </a>
                                      </Col>
                                    }




                                    {

                                      (
                                        (
                                          (row.statusId === 2) //Status casos devueltos
                                          || (row.statusId === 3) //Status casos rechazados
                                          || (row.statusId === 4)//Que muestre el botón LDR despues que creó el LDR
                                          || (row.statusId === 14) //Creando casos en BAW
                                          || (row.statusId === 5) //Casos completados
                                        )
                                        &&
                                        (row.haveLDRS !== 0)
                                      )
                                      && (
                                        <Col >
                                          <a
                                            title="Descargar LDR"
                                            key={key + keyCol}
                                            id={"downloadLDR " + keyCol}
                                            className="table-action table-action"
                                            href={`${urlBase}/autopp-ldrs/download-ldr/${window.btoa(row.opp)}/${window.btoa(row.id)}`}

                                          >
                                            <i className="fas fa-download" /> LDR
                                          </a>
                                          { }

                                        </Col>
                                      )}

                                    {
                                      //Si tiene archivos BOM
                                      (row.haveBOM > 0
                                      )
                                      && (
                                        <Col >
                                          <a
                                            title="Descargar documentos de la oportunidad"
                                            key={key + keyCol}
                                            id={"downloadFiles " + keyCol}
                                            className="table-action table-action"
                                            href={`${urls.AUTOPPLDRS.downloadBOM}/${window.btoa(row.id)}/${window.btoa(row.company)}`}
                                          >
                                            <i className="fas fa-download" /> Docs
                                          </a>
                                          { }

                                        </Col>
                                      )}
                                  </Row>
                                </td>)

                                :

                                (<td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }} className="ml--3  p-1 mb-1 mr--2" >
                                </td>)

                            ) : (

                              <td key={key + keyCol} className="justify-content-md-center">
                                {row[col.value]}
                              </td>
                            )
                          )
                        })
                      }

                    </tr>

                  )
                })
                :
                <>
                  <tr>
                    <td className="justify-content-md-center">
                      Sin resultados encontrados
                    </td>
                  </tr>
                </>
              }

            </tbody>




          </Table>
        </Spin>
      </Card>

      <Card>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs='12' md='6' className="p-1">
                <Row className="justify-content-start">
                  <Col xs='12' md='6'>
                    <span className='pagination mb-0'>
                      Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                    </span>
                  </Col>
                  <Col xs='12' md='2'>
                    <Input type="select"
                      onChange={(e) => handleSetNumPag(e)}>
                      <option>5</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>

              <Col xs='12' md='6' className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {
                    renderPaginations()
                  }
                  <PaginationItem className={page === paginations ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>


          </nav>


        </CardFooter>

      </Card>


      {/*JSON.stringify(rowsRequests)*/}

    </>
  )
}
