/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Table, Row } from "reactstrap";

const UserMaintenance = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    addUserAccess,
    onDelete,
  } = props;

  return (
    <>
      <Card className="pb-5">
        <CardHeader>
          <Row className="align-items-center">
            <Col sm="12" md="10">
              <h3 className="mb-0" >{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            <Col xs="12" md="2" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                block
                size="sm"
                className="btn-icon"
                color="success"
                type="button"
                onClick={addUserAccess}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-user-plus" />
                </span>
                <span className="btn-inner--text">
                  Agregar Usuario
                </span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              {
                cols.map((row, key) => (<th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>{row.name}</th>))
              }
              <th />
            </tr>
          </thead>
          <tbody className="list">
            {
              rows.map((row, key) => (
                <tr key={key}>
                  {
                    cols.map((col, key) => (
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {row[col.key]}
                      </th>
                    ))
                  }
                  <td className="table-actions">
                    <a
                      className="table-action table-action-delete"
                      onClick={() => onDelete(row)}
                      href="#"
                      id="reject"
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Card>
    </>
  );
};

UserMaintenance.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default UserMaintenance;