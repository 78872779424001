import React, { Component } from "react";
// reactstrap components
import {
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Card,
  Input,
  Col,
  Row,
  Label,
  Button
} from "reactstrap";
// core components
import { PropTypes } from "prop-types";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Slider from "react-slider-simple";

class FormMISProjects extends Component {
  state = {
    percent: this.props.infoProjects.progress
  };

  onChange = percent => {
    var roundedPercent = Math.round(percent);
    this.setState({ percent: roundedPercent });
    this.props.onChangeForm({
      target: {
        id: "progress",
        value: roundedPercent
      }
    });
  };
  render() {
    const {
      cancel,
      savedData,
      infoProjects,
      onChangeForm,
      objectFailValidation
    } = this.props;
    const { percent } = this.state;
    return (
      <>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              Ingrese los espacios correspondientes para modificar el registro.
            </h3>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <label className="form-control-label" htmlFor="name">
                  Nombre del proyecto
                </label>
                <Input
                  maxLength="249"
                  id="name"
                  placeholder="Ingrese el nombre del proyecto"
                  type="text"
                  value={infoProjects.name}
                  onChange={e => onChangeForm(e)}
                  invalid={objectFailValidation.name}
                />
                <div className="invalid-feedback">
                  Ingrese el nombre del proyecto
                </div>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="description">
                  Breve descripción del proyecto
                </label>
                <Input
                  maxLength="499"
                  id="description"
                  rows="3"
                  type="textarea"
                  placeholder="Ingrese los sitios afectados"
                  value={infoProjects.description}
                  onChange={e => onChangeForm(e)}
                  invalid={objectFailValidation.description}
                />
                <div className="invalid-feedback">
                  Ingrese la descripción del proyecto
                </div>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="client">
                  Cliente del proyecto
                </label>
                <Input
                  maxLength="149"
                  id="client"
                  placeholder="Ingrese el nombre del proyecto"
                  type="text"
                  value={infoProjects.client}
                  onChange={e => onChangeForm(e)}
                  invalid={objectFailValidation.client}
                />
                <div className="invalid-feedback">
                  Ingrese el cliente del proyecto
                </div>
              </FormGroup>
              {/* <FormGroup>
                <Label className="form-control-label" htmlFor="progress">
                  Porcentaje de progreso del proyecto (0-100)
                </Label>
                <Input
                  max={100}
                  min={0}
                  id="progress"
                  type="number"
                  value={infoProjects.progress}
                  onChange={e => onChangeForm(e)}
                />
              </FormGroup> */}
              <FormGroup>
                <Label className="form-control-label" htmlFor="progress">
                  Porcentaje de progreso del proyecto
                </Label>
                <br></br>
                <span className="range-slider-value value-low">
                  {infoProjects.progress}%
                </span>

                <Slider
                  value={percent}
                  onChange={this.onChange}
                  thumbColor="#2DCE89"
                  shadowColor="#11CDEF"
                  sliderPathColor="#CCCCCC"
                  style={{ marginTop: "10px" }}
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <label className="form-control-label">
                      Fecha inicial del proyecto
                    </label>
                    <ReactDatetime
                      value={moment(infoProjects.initDate).format("DD/MM/YYYY")}
                      timeFormat={false}
                      onChange={e =>
                        onChangeForm({
                          target: {
                            id: "initDate",
                            value: moment(e._d).format("YYYY-MM-DD")
                          }
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <label className="form-control-label">
                      Fecha de lanzamiento del proyecto
                    </label>
                    <ReactDatetime
                      value={moment(infoProjects.releaseDate).format(
                        "DD/MM/YYYY"
                      )}
                      timeFormat={false}
                      onChange={e =>
                        onChangeForm({
                          target: {
                            id: "releaseDate",
                            value: moment(e._d).format("YYYY-MM-DD")
                          }
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="comment">
                  Comentario (opcional)
                </label>
                <Input
                  maxLength="999"
                  id="comment"
                  rows="3"
                  type="textarea"
                  placeholder="Ingrese un comentario al respecto"
                  value={infoProjects.comment}
                  onChange={e => onChangeForm(e)}
                />
              </FormGroup>
            </Form>
            <Button
              color="success"
              size="sm"
              type="button"
              onClick={e => savedData(infoProjects)}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-upload"></i>
              </span>
              <span className="btn-inner--text">Publicar</span>
            </Button>
            <Button color="danger" size="sm" type="button" onClick={cancel}>
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-times"></i>
              </span>
              <span className="btn-inner--text">Cancelar</span>
            </Button>
          </CardBody>
        </Card>
      </>
    );
  }
}

FormMISProjects.propTypes = {
  cancel: PropTypes.func.isRequired,
  savedData: PropTypes.func.isRequired,
  infoProjects: PropTypes.object.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  objectFailValidation: PropTypes.object.isRequired
};

export default FormMISProjects;
