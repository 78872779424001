
import React, { useState } from 'react';
import {
    Col,
    Row,
    Input,
    Table
} from "reactstrap";
import Select from "react-select";
import moment from "moment";

const FormInformation = ({ InformationTamplet, handleOnChangeInfo, disabled, newInfo, identificator, valueInsert, typeForm, handleOnChangeInfoTable, handleOnChangeCountRows, countRows, disabledForm }) => {


    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    const arrayTable = ["Seleccione una opcion:", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const fecha = moment(Date.now).utc().utcOffset(moment().utcOffset()).format("DD-MM-YYYY hh:mm a")

    return (
        <div>
            {
                InformationTamplet.map((item, key) => (
                    <Row className="mt-3" key={key}>
                        {item.typeUnitType === "select" ?
                            <>
                                <Col xs="12" md="4" key={key}> {item.required === 1 ? `${item.UnitTypeName} * ` : `${item.UnitTypeName}`}:</Col>
                                <Col xs="12" md="8" key={`colSelect${key}`}>
                                    <Select
                                        isDisabled={typeForm === 2 ? disabledForm : disabled}
                                        key={key}
                                        className="basic-single is-invalid"
                                        type="select"
                                        options={item.options}
                                        defaultValue={{ label: typeForm === 1 ? newInfo[item[identificator]] : typeForm === 2 ? "" : item[identificator], value: 1 }}
                                        onChange={(e) => handleOnChangeInfo(typeForm === 2 ? item : item[valueInsert], typeForm === 2 ? e : e.value)}
                                    />
                                </Col>

                            </>
                            : item.typeUnitType === "money" ?
                                <>
                                    <Col key={key} xs="12" md="4">{item.required === 1 ? `${item.UnitTypeName} * ` : `${item.UnitTypeName}`}:</Col>
                                    <Col xs="12" md="4" key={`colSelect${key}`}>
                                        <Input
                                            disabled={typeForm === 2 ? disabledForm : disabled}
                                            key={key}
                                            required={item.required}
                                            className="m-0"
                                            type={"number"}
                                            defaultValue={typeForm === 1 ? newInfo[item[identificator]] : typeForm === 2 ? "" : item[identificator]}
                                            onChange={(e) => handleOnChangeInfo(typeForm === 2 ? item : item[valueInsert], e.target.value)}
                                        />
                                    </Col>
                                    <Col xs="12" md="4" key={`colSelect2${key}`}>
                                        <Input
                                            disabled={true}
                                            key={key}
                                            required={item.required}
                                            className="m-0"
                                            type={"text"}
                                            defaultValue={typeForm === 1 ? formatter.format(newInfo[item[identificator]]) : typeForm === 2 ? "" : formatter.format(item[identificator])}
                                            value={`${typeForm !== 0 ? newInfo[item[identificator]] === undefined ? 0 : formatter.format(newInfo[item[identificator]]) : formatter.format(item[identificator])}`}
                                            onChange={(e) => handleOnChangeInfo(typeForm === 2 ? item : item[valueInsert], e.target.value)}
                                        />
                                    </Col>
                                </>
                                :
                                item.typeUnitType === "table" ?
                                    <>
                                        <Col xs="8" md="4" key={key}> {item.required === 1 ? `${item.UnitTypeName} * ` : `${item.UnitTypeName}`}:</Col>
                                        <Col xs="4" md="1">
                                            <Input
                                                disabled={typeForm === 1 || typeForm === 0 ? true : typeForm === 2 ? disabledForm : false}
                                                type="select"
                                                onChange={(e) => handleOnChangeCountRows(e.target.value, item.options)}
                                            >
                                                {arrayTable.map((element) => (
                                                    <option>{element}</option>
                                                ))

                                                }

                                            </Input>
                                        </Col>
                                        <Col xs="12" md="7" key={`colTable${key}`}>
                                            <Table
                                                className="align-items-center table-flush"
                                                responsive
                                                striped={true}
                                                hover={true}
                                            >
                                                <thead className="thead-light ">
                                                    <tr>
                                                        {item.options.map((element, key) => {
                                                            return (
                                                                <th key={key}>{element.label}</th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className="list">
                                                    {
                                                        typeForm === 1 ?
                                                            newInfo[item[valueInsert]].map((element) => (
                                                                <tr key={`tr${key}$`}>
                                                                    {
                                                                        item.options.map((colName, key2) => (
                                                                            <td key={key2}>{element[colName.label]}</td>
                                                                        ))
                                                                    }

                                                                </tr>
                                                            ))
                                                            :
                                                            typeForm === 0 ?
                                                                item.value.map((information,key) => (
                                                                    <tr key={`tr${key}$`}>
                                                                        {
                                                                            item.options.map((element, key2) => (
                                                                                <td key={key2}>
                                                                                    <Input
                                                                                     key={`input${key2}`}
                                                                                        disabled={typeForm === 2 ? disabledForm : disabled}
                                                                                        defaultValue={ information[element.label]}
                                                                                        onChange={(e) => handleOnChangeInfoTable(item[valueInsert], e.target.value, element, key)}
                                                                                    /></td>
                                                                            ))
                                                                        }

                                                                    </tr>
                                                                ))
                                                                :
                                                                countRows && countRows.map((row, key) => (

                                                                    <tr key={`tr${key}$`} >
                                                                        {item.options.map((element, key2) => (
                                                                            <td key={key2}>
                                                                                <Input
                                                                                    disabled={typeForm === 2 ? disabledForm : disabled}
                                                                                    onChange={(e) => handleOnChangeInfoTable(item[valueInsert], e.target.value, element, key)}
                                                                                /></td>
                                                                        )
                                                                        )}
                                                                    </tr>
                                                                ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col key={key} xs="12" md="4">{item.required === 1 ? `${item.UnitTypeName} * ` : `${item.UnitTypeName}`}:</Col>
                                        <Col xs="12" md="8" key={`colSelect${key}`}>
                                            <Input
                                                disabled={typeForm === 2 ? disabledForm : disabled}
                                                key={key}
                                                required={item.required}
                                                className="m-0"
                                                type={item.typeUnitType}
                                                defaultValue={typeForm === 1 ? newInfo[item[identificator]] : typeForm === 2 ? "" : item[identificator]}
                                                onChange={(e) => handleOnChangeInfo(typeForm === 2 ? item : item[valueInsert], e.target.value)}
                                            />
                                        </Col>
                                    </>
                        }
                    </Row>
                ))
            }
        </div >
    );
};

export default FormInformation;