import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
// routes
import { ServiceOrderRoutes } from "routes/routes.jsx";

const Index = props => {
  const getRoutes = routes => {
    // console.log(routes);
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/service-orders") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Switch>{getRoutes(ServiceOrderRoutes)}</Switch>
      <p className="lead"></p>
    </>
  );
};

export default Index;
