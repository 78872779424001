import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getOptionsData = createAction(constants.DS_GET_OPTIONS, method.get(urls.DOCUMENTSY.getOptions));
export const getReportColumnsAction = createAction(constants.DS_GET_REPORT_COLUMNS, method.get(urls.DOCUMENTSY.getReportColumns));
export const getReportsAction = createAction(constants.DS_GET_REPORTS, method.get(urls.DOCUMENTSY.getReports));
export const getDataRows = createAction(constants.DS_GET_DATA, body => method.post(urls.DOCUMENTSY.getRows, body)());
export const getDataLog = createAction(constants.DS_GET_LOG, body => method.post(urls.DOCUMENTSY.getLog, body)());
export const newRequest = createAction(constants.DS_NEW_REQUEST, body => method.post(urls.DOCUMENTSY.newRequest, body)());
export const updateRequest = createAction(constants.DS_UPDATE_REQUEST, body => method.post(urls.DOCUMENTSY.updateRequest, body)());
export const downLoadAllFiles = createAction(constants.DS_DOWNLOAD_FILE, (documentId) => method.get(`${urls.DOCUMENTSY.downLoadAllFiles}/${documentId}`)());
export const getFilesByCustomer = createAction(constants.DS_GET_FILES_CUSTOMER, (documentId) => method.get(`${urls.DOCUMENTSY.getFilesByCustomer}/${documentId}`)());
export const getDataCustomers = createAction(constants.DS_GET_CUSTOMERS, method.get(urls.DOCUMENTSY.getCustomers));
export const deleteFile = createAction(constants.DELETE_FILE, body => method.post(urls.DOCUMENTSY.deleteFile, body)());
export const deleteAllFiles = createAction(constants.DELETE_ALL_FILES, body => method.post(urls.DOCUMENTSY.deleteAllFiles, body)());
export const reporting = createAction(constants.DS_REPORTING, body => method.post(urls.DOCUMENTSY.reporting, body)());
export const deleteReportAction = createAction(constants.DS_DELETE_REPORT, body => method.post(urls.DOCUMENTSY.deleteReport, body)());
export const saveReportAction = createAction(constants.DS_SAVE_REPORT, body => method.post(urls.DOCUMENTSY.saveReport, body)());