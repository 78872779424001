import React from 'react';
import moment from "moment";
import { Badge } from "reactstrap";

const Timeline = ({ items }) => {
    return (
        <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
        >
            {items ?
                <>
                    {items.map((item, key) => (
                        <div key={key} className="timeline-block">
                            <span className={`timeline-step badge-${item.color}`}>
                                <i className={`${item.icon}`}></i>
                            </span>
                            <div className="timeline-content">
                                <span className="text-light font-weight-bold">
                                    {item.activity}
                                </span>
                                <div>
                                    <small className="text-light font-weight-bold">
                                        <i className="fas fa-clock mr-1" />{moment.utc(item.createdAt).format("YYYY-MM-DD HH:mm")}
                                    </small>
                                    <div className="mt-3">
                                        <Badge color={`${item.color}`} pill>
                                            {item.timelineUser}
                                        </Badge>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    ))}
                </>
                :
                <>
                    <h3>Sin registros</h3>
                </>}
        </div>
    );
}

export default Timeline;