/*!

=========================================================
*Autopp - EmployeeResponsibleForm
=========================================================

* Componente para establecer por defecto el empleado responsable
esto en el componente de SalesTeamItem.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerías de React
import React, { useContext, useState, useEffect } from 'react'

//Imports de elementos para el formulario
import { Button, Col, FormGroup, Row, Input } from "reactstrap";
import { Typeahead, useAsync } from "react-bootstrap-typeahead";

//Import del contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Import de Spinner
import { Spin } from "antd";

//User que de la sesión actual.
import { getUsernameLogged } from 'selectors/adminLayout';


export const EmployeeResponsibleForm = React.memo(() => {

    //#region States globales
    const {

        loadingEmployees,
        employeesOptions,
        dropDownOptions,
        readyToSend,
        editData

    } = useContext(UserContext);
    //#endregion

    //#region States locales

    const [userDefault, setUserDefault] = useState([{}])
    const [employeeResponsibleId, setEmployeeResponsibleId] = useState("0")
    //State para guardar la información de manera local.
    const [newInfo, setNewInfo] = useState({ "id": 0, "name": "", "UserID": 0, "RoleId": 0 })

    //Asignación de Campos del form.
    const [fieldsForm,] = useState([
        {
            colWidth: "5",
            label: "Rol del colaborador*",
            placeholder: "Seleccione el rol",
            idFill: 'employeeRole1',
            id: 'employeeRoleResponsible',
            defaultValue: '',
            type: "select",
            required: true,
            disabled: false,
        },
        {
            colWidth: "6",
            label: "Nombre del colaborador*",
            placeholder: "Digite el colaborador",
            id: "employeeResponsibleName",
            type: "typeahead",
            required: true,
            disabled: false,
        },
    ]);
    //#endregion

    //#region Efectos
    //#region Efecto para cuando el form esté listo para enviar, que lo agregue al state principal editData.

    useEffect(() => {
        if (readyToSend) {
            const temp = { employeeResponsible: newInfo };
            Object.assign(editData, temp);
        }
    }, [readyToSend])

    //#endregion

    //#region Efecto para que averigue el id del employee responsible en la tabla de Employee Role. 

    useEffect(() => {
        // debugger;
        if (dropDownOptions["employeeRole"] !== undefined) {
            const role = dropDownOptions["employeeRole"].filter(e => e.label === "Employee Responsible");
             setEmployeeResponsibleId(role[0].value);

            setNewInfo(prevState => ({
                ...prevState,
                ["RoleId"]: role[0].value
            }))

        }
    }, [dropDownOptions])

    //#endregion

    //#region Efecto para que establezca de forma predeterminada el empleado responsable al arrancar.

    useEffect(() => {

        const currentUser = getUsernameLogged();

        if (employeesOptions.length > 0) {

            try {
                const temp = employeesOptions.filter(e => e.user === currentUser);
                const aux = { "id": temp[0].id, "name": temp[0].name, "UserID": temp[0].UserID, "RoleId": employeeResponsibleId };
                setUserDefault(aux);
                setNewInfo(aux);
            } catch (e) {
                console.log("El employee responsible no está en la tabla de digital_sign");
            }
        }

    }, [employeesOptions])

    //#endregion
    //#endregion

    //#region Métodos útiles.

    const ref = React.createRef();
    //Asigna el state del campo a NewInfo
    const handleChangeInfo = (e) => {
        try {
            setNewInfo(Object.assign(e, { "RoleId": employeeResponsibleId }))
        } catch (e) {
            setNewInfo({ "id": 0, "name": "", "UserID": 0, "RoleId": 41 });
            ref.current.clear();
        }
    }

    // Limpiar el typeahead.
    const handleResetValue = (event, ref) => {
        event.preventDefault();
        ref.current.clear();
        setNewInfo({ "id": 0, "name": "", "UserID": 0, "RoleId": employeeResponsibleId });

        //#endregion
    }


    return (
        <>
            <Row className="p-1 pb-1 mt-3 mb-1 ">

                {fieldsForm.map((row, key) =>
                    row.type === "select" ? (
                        //Este case es para traer las opciones completas ya que no dependen de ningun dropdown
                        <Col xs ="12"sm={row.colWidth} key={key}>
                            <FormGroup>
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {row.label}
                                </label>
                                <Input
                                    key={row.id}
                                    id={row.id}
                                    name={row.id}
                                    disabled={true}
                                    className="basic-multi-select text-dark"
                                    type={-"input"}
                                    placeholder={row.placeholder}
                                    value="Empleado Responsable"
                                >
                                </Input>
                                <div className="invalid-feedback">
                                    Este campo no puede estar vacio
                                </div>

                            </FormGroup>
                        </Col>
                    ) : (
                        //Tipo Typeahead
                        <Col xs="12"sm={row.colWidth} key={key}>
                            <FormGroup >
                                <label
                                 style={{ fontSize: "12px" }}
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {row.label}
                                </label>
                                <Spin size="small" spinning={loadingEmployees}>
                                    <Row className=" ml-1 p--1 " style={{ display: "flex", justifyContent: "flex-center" }}>
                                        {userDefault.id !== undefined &&
                                            <>
                                                <Col xs="10" sm="10" className=" p-0" >
                                                    <Typeahead
                                                        id={row.id}
                                                        labelKey={employeesOptions => `${employeesOptions.name}`}
                                                        minLength={0}
                                                        required={row.required}
                                                        ref={ref}
                                                        options={employeesOptions}
                                                        defaultSelected={[userDefault]}
                                                        onChange={(e) => handleChangeInfo(e[0])}
                                                        renderMenuItemChildren={(value) => (
                                                            <div >
                                                                {value.name}
                                                                <div>
                                                                    {
                                                                        <small>Id: {value.UserID}</small>}
                                                                </div>
                                                            </div>
                                                        )}


                                                    />
                                                </Col>
                                                <Col xs="2" sm="2" className=" p-0" style={{ display: "flex", justifyContent: "flex-start" }}>
                                                    <Button
                                                    id="btnDeleteResponsible"
                                                        color="danger"
                                                        className="btn-icon"
                                                        style={{ display: "flex", justifyContent: "flex-end" }}
                                                        onClick={(e) => handleResetValue(e, ref)}
                                                    >
                                                        <span className="btn-inner--icon mr-">
                                                            <i className="fa fa-times" /> 
                                                        </span>
                                                    </Button>


                                                </Col>



                                            </>}
                                    </Row>
                                </Spin>
                                <div className="invalid-feedback">
                                    Este campo no puede estar vacio
                                </div>
                            </FormGroup>
                        </Col>
                    )
                )}

            </Row>
            <hr className='mb--1 mt--1 ' />
            {/*JSON.stringify(newInfo)*/}
        </>
    )
})

export default EmployeeResponsibleForm;
