// nodejs library to set properties for components
import PropTypes from "prop-types";
import { FaLongArrowAltLeft } from "react-icons/fa";
// react library
import React from "react";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";

const FiltersUsers = (props) => {
  const {
    title,
    subtitle,
    countriesOptions,
    countriesSelected,
    managerOptions,
    managerSelected,
    usernamesOptions,
    usernamesSelected,
    onClear,
    onReturn,
    onChangeFilters,
    showOnReturnButton,
  } = props;

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col sm="12" md="8">
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>

            {showOnReturnButton ? (
              <>
                <Col className="text-right" xs="12" md="2">
                  <Button
                    className="btn-icon"
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={onReturn}
                  >
                    <span className="btn-inner--text">
                      <FaLongArrowAltLeft
                        style={{
                          color: "white",
                          fontSize: "15px",
                          marginRight: "2px",
                        }}
                      />
                      Retroceder
                    </span>
                  </Button>
                </Col>

                <Col className="text-right" xs="12" md="2">
                  <Button
                    className="btn-icon"
                    color="info"
                    size="sm"
                    type="button"
                    onClick={onClear}
                  >
                    <span className="btn-inner--icon mr-">
                      <i className="fas fa-trash" />
                    </span>
                    <span className="btn-inner--text">Limpiar filtros</span>
                  </Button>
                </Col>
              </>
            ) : (
              <Col className="text-right" xs="12" md="4">
                <Button
                  className="btn-icon"
                  color="info"
                  size="sm"
                  type="button"
                  onClick={onClear}
                >
                  <span className="btn-inner--icon mr-">
                    <i className="fas fa-trash" />
                  </span>
                  <span className="btn-inner--text">Limpiar filtros</span>
                </Button>
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="text-right" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="countries">
                  País
                </label>
                <Select
                  className="basic-single"
                  id="countries"
                  type="select"
                  placeholder={"Selecione un País"}
                  isSearchable={true}
                  options={countriesOptions}
                  value={countriesSelected}
                  onChange={(e) => onChangeFilters("countriesSelected", e)}
                />
              </FormGroup>
            </Col>
            <Col className="text-right" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="countries">
                  Nombre de Usuario
                </label>
                <Select
                  className="basic-single"
                  id="usernames"
                  type="select"
                  placeholder={"Selecione un nombre de usuario"}
                  isSearchable={true}
                  options={usernamesOptions}
                  value={usernamesSelected}
                  onChange={(e) => onChangeFilters("usernamesSelected", e)}
                />
              </FormGroup>
            </Col>
            <Col className="text-right" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="managers">
                  Supervisor
                </label>
                <Select
                  className="basic-single"
                  id="managers"
                  type="select"
                  placeholder={"Selecione un Supervisor"}
                  isSearchable={true}
                  options={managerOptions}
                  value={managerSelected}
                  onChange={(e) => onChangeFilters("managerSelected", e)}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

FiltersUsers.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default FiltersUsers;
