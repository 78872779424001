/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Datos Generales en la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React, { useState, useEffect } from "react";
import { Card, CardTitle, Label, Col, Row, Input, FormGroup, FormFeedback, FormText } from "reactstrap";

const GenData = ({ isVisible, requiredJson, data, optionsJson, setData, disabledFieldsJson, enableComments }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff' };
    const groupStyle = { marginBottom: '0.1rem' };
    const labelStyle = { marginBottom: '0.1rem' };

    const forms = [
        {
            type: "text",
            label: "Nombre",
            id: "firstName",
            colWidth: 4,
            elementType: "Input",
            disabled: false,
            maxLength: 29

        },
        {
            type: "text",
            label: "Apellidos",
            id: "lastName",
            colWidth: 4,
            elementType: "Input",
            disabled: false,
            maxLength: 34
        },
        {
            type: "select",
            label: "Área Personal",
            id: "personalArea",
            colWidth: 4,
            elementType: "Input",
            disabled: false
        },
        {
            type: "select",
            label: "Posición",
            id: "jobPosition",
            colWidth: 4,
            elementType: "Input",
            disabled: false
        },
        {
            type: "select",
            label: "Tipo Plaza",
            id: "jobType",
            colWidth: 4,
            elementType: "Input",
            disabled: false
        },
        {
            type: "select",
            label: "País",
            id: "country",
            colWidth: 4,
            elementType: "Input",
            disabled: false
        },
        {
            type: "text",
            label: "Manager",
            id: "manager",
            colWidth: 4,
            elementType: "Input",
            disabled: false,
            maxLength: 50
        },
        {
            type: "textarea",
            label: "Observaciones (max 500 caracteres) ",
            id: "genDataComments",
            colWidth: 8,
            elementType: "Input",
            disabled: enableComments,
            maxLength: 500
        }
    ]
    //FUNCIONES

    //Inicializacion, eliminar pais 14, location 5-6 y ordenar por orden alfabetico
    useEffect(() => {
        optionsJson["country"] = optionsJson["country"]
            .filter(item => item.id !== 14)
            .sort((a, b) => a.value.localeCompare(b.value));
        optionsJson["jobPosition"] = optionsJson["jobPosition"].sort((a, b) => a.value.localeCompare(b.value));
        optionsJson["location"] = optionsJson["location"].filter(item => item.id !== 5 && item.id !== 6);
    }, []);

    //actualiza la varible data cada que se escribe algo
    const handleOnChange = (id, e) => {
        e.persist();
        setData(prevState => ({ ...prevState, [id]: e.target.value }));
    }

    //RETURN
    return (
        <>
            {isVisible && (
                <Card>
                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Datos Generales
                    </CardTitle>

                    <Row style={{ padding: '0px 10px 0px' }}>
                        {
                            forms.map((formsJson, index) => {
                                return (
                                    <Col key={index} xs={formsJson.colWidth} style={formsJson.style}>
                                        <div style={{ padding: '0px 15px 0px' }}>

                                            <FormGroup style={{ ...groupStyle }}>
                                                <Label htmlFor={formsJson.id} style={labelStyle}>{formsJson.label}
                                                    {formsJson.required && <span style={{ color: 'red' }}> *</span>}
                                                </Label>

                                                {formsJson.type === "select" ? (

                                                    <Input
                                                        type="select"
                                                        id={formsJson.id}
                                                        invalid={requiredJson && requiredJson.includes(formsJson.id)}
                                                        name={formsJson.name}
                                                        disabled={formsJson.disabled || (disabledFieldsJson && disabledFieldsJson.includes("GenData"))}
                                                        className="form-control"
                                                        key={index}
                                                        onChange={(e) => handleOnChange(formsJson.id, e)}
                                                        value={data[formsJson.id]}
                                                        maxLength={formsJson.maxLength}
                                                    >
                                                        <option></option>
                                                        {optionsJson[formsJson.id] && optionsJson[formsJson.id].map((optionsJson, index) => (
                                                            <option key={index} value={optionsJson.id}>{optionsJson.value}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <Input
                                                        type={formsJson.type}
                                                        placeholder={formsJson.placeHolder}
                                                        id={formsJson.id}
                                                        invalid={requiredJson && requiredJson.includes(formsJson.id)}
                                                        name={formsJson.name}
                                                        disabled={!formsJson.disabled && (disabledFieldsJson && disabledFieldsJson.includes("GenData"))}
                                                        className="form-control"
                                                        key={index}
                                                        onChange={(e) => handleOnChange(formsJson.id, e)}
                                                        value={data[formsJson.id]}
                                                        maxLength={formsJson.maxLength}
                                                    />

                                                )}

                                                <FormFeedback>
                                                    Este campo no puede estar vacío
                                                </FormFeedback>
                                            </FormGroup>

                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                    {/* COMPLETADO */}
                    <Row style={{ padding: '0px 10px 0px' }}>
                        <Col xs="4" >
                            <FormGroup style={{ padding: '10px 10px 0px' }}>
                                <Label for="changedBy">Completado</Label>
                                <Input
                                    type="text"
                                    id="changedBy"
                                    disabled
                                    className="form-control"
                                    value={data.managerApprover}
                                    maxLength="31"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );

}
export default GenData;