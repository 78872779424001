/*!

=========================================================
*Inbound
=========================================================

*FormGeneral: TasksSpare contiene el state llamado fields, 
el cual tiene los campospara llamar al FormGeneral y 
desplegar el formulario para Ingreso de Mercancía. 
Los dields también tienen funciones con acciones para 
cuando se toca un button. 

*ModalList: En casos específicos donde se necesita abrir 
un modal para seleccionar ots, docs, en base al warehouse, 
se utiliza ese componente reutilizable.

*Props: 
    -Stage: Es un estado, el cual crea etapas en las cuales
    ciertos campos del formulario aparece según la etapa.
    Ejemplo: Etapa 1, aparece los campos 1 y 2. Etapa 2, 
    aparece los campos 3 y 4.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React, { useContext, useState, useEffect } from 'react'

//dispatch hook
import { useDispatch } from 'react-redux';

//#region Icons
import { MdOutlineNumbers } from "react-icons/md";

import { MdOutlineComputer } from "react-icons/md";

import { BsChatRightText, BsCheckLg } from "react-icons/bs";

import { FaArrowRight } from "react-icons/fa";

import { GrTransaction } from "react-icons/gr";

import { SiEthiopianairlines } from "react-icons/si";

import { GiPositionMarker } from "react-icons/gi";

import { FaDoorOpen } from "react-icons/fa";

import { UserContext } from '../SharedComponents/UserContext';

import { BiSolidArrowToRight } from "react-icons/bi";

import { IoIosSend } from "react-icons/io";
//#endregion

//Imports de actions de consultas al API.
import {
    //CancelationInbound
    getItemInfoByOt,

    //Outbound
    getPositionsByOt,

    sendRequestOutboundByOt
} from 'actions/wms';

//Componentes reutilizables.
import { ModalList } from '../SharedComponents/ModalList';
import { FormGeneral } from '../SharedComponents/FormGeneral';

import { encrypter } from 'helpers/desencrypt.jsx';


export const TasksSpare = ({ stage, setStage, selectedComponent, mainView }) => {

    const { modal, setPlant, strictedMaterialValidation, notValidateQuantity } = selectedComponent

    //Renombrar variables
    const idComponent = selectedComponent.id;
    const title = selectedComponent.label;

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isMobile,
        isComputer,
        options,
        notify,
        MySwal,
        warehouse,
        sapPassword
    } = useContext(UserContext);
    //#endregion

    //#region states locales
    const [openModal, setOpenModal] = useState(false)
    const [localSpinner, setLocalSpinner] = useState(false)
    const [buttonSpinner, setButtonSpinner] = useState(false)
    //State para guardar la información.
    const [newInfo, setNewInfo] = useState({})
    //La posición actual en el form.
    const [position, setPosition] = useState(0)

    //Lista de positions para validar al final.
    const [positions, setPositions] = useState([])


    //#enregion 

    //#region Efectos

    //Establecer datos iniciales.
    useEffect(() => {

        let movement = fields[idComponent].filter(field => field.movementOptions)
        movement = movement.length > 0 ? movement[0].movementOptions[0] : ""

        setNewInfo({
            plant: options.plants[0].label,
            ot: "",
            po: "",
            document: "",
            reservation: "",
            movement: movement
        })



    }, [idComponent])

    //Efecto excepción para cuando cargue el componente establezca la planta.
    useEffect(() => {
        if (options?.plants && setPlant == true) {
            //  handleOnChange("plant", options.plants[0].label, idComponent)
        }

    }, [options])

    //Efecto excepción para cuando la po sea vacía regrese al stage anterior.
    useEffect(() => {
        if ((idComponent == "po" && newInfo.po == "") ||
            (idComponent == "reservationOutboundAssets" && newInfo.document == "") ||
            (idComponent == "reservationOutboundOthers" && newInfo.reservation == "")
        ) {
            setStage(1)
            delete newInfo.numberSupplier;
            delete newInfo.nameSupplier;
        }
    }, [newInfo])

    //#endregion

    //#region ------------------------Funciones-----------------------------------------------

    //Refrescar el componente.
    const handleRefresh = (newInfo) => {
        setStage(1)
        setPositions([])
        setPosition(0)
        setNewInfo({ ...newInfo, po: "", document: "", ot: "", reservation: "" })
    }

    //Extraer la información de la cancelación por OT.
    const getItemInfoByOtE = (newInfo, warehouse) => {
        // setSpinner(true);
        // setLocalSpinner(true)
        setButtonSpinner(true)
        dispatch(getItemInfoByOt({ view: mainView, warehouse: warehouse, orderTransport: newInfo.ot, movement: newInfo.movement })).then((res) => {
            const { payload } = res;
            // setLocalSpinner(false);
            setButtonSpinner(false)


            if (res.payload.isAxiosError) {
                // Fallo
                console.log(payload)

                if (res.payload.response) {
                    const {
                        data: { payload }
                    } = res.payload.response;
                    notify("warning", "Atención", payload.message);

                } else {
                    notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexión con el servidor."
                    );
                }



            } else {
                // Éxito

                let apiInfo = { ...payload.data.payload };

                if (!payload.data.success) {
                    notify("warning", "Alerta", apiInfo.message)
                } else if (!apiInfo.material) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la cancelación desde SAP.")
                } else {
                    setNewInfo(Object.assign(newInfo, apiInfo))
                    setStage(2)
                }

            }
        })
    }

    //Método para extraer una OT.
    const handleSearchOt = (newInfo, positions, isComputer, warehouse, idComponent) => {
        if (newInfo.ot) {
            // alert("consultando a sap")
            if (idComponent == "cancelation") {
                getItemInfoByOtE(newInfo);
            }
        } else {
            // if (newInfo?.movement == "" || !newInfo?.movement) {
            //     notify("warning", "Alerta", "Por favor, ingrese un movimiento.");
            // } else {
            //     setOpenModal(true)
            // }
            setOpenModal(true)

        }
    }

    //Extraer la información de la entrada por po.
    const getPositionsByOtE = (newInfo, positions, isComputer, warehouse) => {
        let movement = newInfo.movement

        if (mainView == "tasks") //Excepción de Tareas y Movimientos Spare, cuando manda a traer las posiciones, cambia el movement.
        {
            movement = 653
        }

        if (mainView == "tasks" && newInfo.movement == "") {
            notify("danger", "Alerta", "Por favor, ingrese un movimiento.")
            return;
        }

        if (newInfo.ot == "") {
            notify("danger", "Alerta", "Por favor, ingrese una OT.")
            return;
        }

        if (newInfo.movement == "") {
            notify("danger", "Alerta", "Por favor, ingrese un movimiento.")
            return;
        }
        // setSpinner(true);
        // setLocalSpinner(true)
        setButtonSpinner(true);

        dispatch(getPositionsByOt({ view: mainView, ot: newInfo.ot, movement: movement, warehouse: warehouse })).then((res) => {
            const { payload } = res;
            setButtonSpinner(false)

            if (res.payload.isAxiosError) {
                console.log(payload)
                notify("warning", "Alerta", "Existe un problema al extraer la información de la OT en SAP.")

            } else {
                // console.log(payload)
                // setLocalSpinner(false);

                let apiInfo = { ...payload.data.payload };
                let firstPosition = { ...apiInfo.positions[0] };

                if (!firstPosition) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la OT en SAP.")
                } else {
                    setPositions(apiInfo.positions)
                    setNewInfo(Object.assign(newInfo, firstPosition))
                    setStage(2)
                }

            }
        })
    }

    //Valida si debe mostrar o no la placa.
    const plateValidation = (field) => {

        if (field.id == "plate" && positions.length > 0/*Hay posiciones*/) {
            if (field.visibleInMovement && (newInfo.movement != field.visibleInMovement)) { //Sólo aparezca en un movimiento en específico.
                return false
            }

            if (field.notVisibleInMovement && (newInfo.movement == field.notVisibleInMovement)) { //Sólo aparezca en un movimiento en específico.
                return false
            }

            if (position !== 0) { //No aparece serie porque no es la posición 0 osea la primera
                return false
            }
        }

        return true
    }

    //Método para verificar si los ids de un array tienen valor en newInfo.
    const verifyEnableIfDontHaveValuesArray = (items) => {
        let resultCounter = 0

        items.map(item => {
            if (!newInfo[item]) {
                resultCounter++;
            }
        }
        )
        return resultCounter > 0
    }

    //Handle para enviar la petición de salida de mercancía de las que tienen OT.
    const handleSendOutboundByOt = async (newInfo, positions, isComputer, warehouse, idComponent, positionsSapFormat, fields) => {

        const firstPosition = positions[0];

        const platePicking = fields.filter(field => field.id == "platePicking").length > 0
        const seriePicking = fields.filter(field => field.id == "seriePicking").length > 0
        const pickingMaterial = fields.filter(field => field.id == "pickingMaterial").length > 0

        if (platePicking && firstPosition.platePicking != firstPosition.plate) {
            notify("danger", "Alerta", "La placa es incorrecta, favor validar.")
            return;
        }

        if (seriePicking && firstPosition.seriePicking != firstPosition.serie) {
            notify("danger", "Alerta", "La serie es incorrecta, favor validar.")
            return;
        }

        let counterErrorMaterial = 0;

        positions.forEach(position => {
            if (pickingMaterial && position.pickingMaterial != position.material) {
                notify("danger", "Alerta", "Pick de material " + position.pickingMaterial + " es incorrecto, favor validar.")
                counterErrorMaterial++;
                return;
            }
        }
        )

        if(counterErrorMaterial>0)
        {
            return;
        }

        // const incorrectPositions = positions.filter(position => position.pickingMaterial != position.material)

        // if (newInfo?.platePicking && newInfo?.platePicking?.length < 7) {
        //     notify("danger", "Alerta", "Debe ingresar al menos 7 números en la placa.")
        //     return;
        // }

        // if (newInfo?.platePicking && isNaN(newInfo?.platePicking)) {
        //     notify("danger", "Alerta", "La placa debe ser únicamente números en la placa.")
        //     return;
        // }

        // if (newInfo?.plate != newInfo?.platePicking) {
        //     notify("danger", "Alerta", "Placa incorrecta ingresada, favor validar.")
        //     return;
        // }

        // if (incorrectPositions.length == 0 && validatePicking(newInfo)) {


        MySwal.fire({
            title: `Atención`,
            icon: 'info',
            showCancelButton: true,
            text: "¿Está seguro(a) que desea enviar este documento?",
            // width: 1000,
            confirmButtonText: `Si, confirmar`,
            confirmButtonColor: "#2dce89",
            cancelButtonText: "No, cancelar",
            cancelButtonColor: "#adb5bd",
            buttonsStyling: isComputer,
            customClass: {
                confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
            },
            className: "p-0",
            style: {
                overflow: 'unset',
                padding: '0px'
            }
        }).then((result) => {

            if (result.value) {
                // setLocalSpinner(true)
                // setspinnerCard(true);
                setButtonSpinner(true)

                dispatch(sendRequestOutboundByOt({ view: mainView, movement: newInfo.movement, warehouse: warehouse, ot: newInfo.ot, password: encrypter(sapPassword) })).then((resp) => {

                    const { payload } = resp;
                    // setLocalSpinner(false)
                    setButtonSpinner(false);

                    if (resp.payload.isAxiosError) {
                        notify("danger", "Atención", "Ocurrió un error al realizar la confirmación.")

                        console.log(payload)
                    } else {
                        let apiInfo = { ...payload.data.payload };

                        notify("success", "Confirmación éxitosa!", apiInfo.message)

                        //Restablecer.
                        handleRefresh(newInfo);
                    }


                })
            }
        });

        // } else {
        //     notify("danger", "Alerta", positions.length > 0 ? "Existen posiciones con el picking de material incorrecto, favor validar." : "El picking del material es incorrecto, favor validar.")

        // }

    }

    //Cambia el estado newInfo según el campo.
    const handleOnChange = (idField, label, idComponent) => {


        //Excepción para placa.
        if (idField == "plate") {
            if (isNaN(label.toString())) //No es sólo números.
            {
                notify("danger", "Alerta", "Debe digitar únicamente números.")
                return
            }

            if (label.length > 7) {
                notify("danger", "Alerta", "La cantidad de dígitos de la placa no debe ser mayor a 7.")
                return
            }
        }

        let stageMustChangePosition = fields[idComponent].filter(field => field.type == "positionsSelector")

        if (stageMustChangePosition.length > 0 && stageMustChangePosition[0].stage == stage //En este stage debe gestionarse las posiciones.
        ) {
            let currentPosition = positions[position];
            currentPosition = { ...currentPosition, [idField]: label }

            setPositions(

                positions.map((pos, key) => {
                    if (key == position) {
                        return currentPosition
                    }
                    return pos

                })
            )


        }

        setNewInfo({ ...newInfo, [idField]: label })

    }

    //Método para validar si el picking actual está correcto.
    const validatePicking = (newInfo) => {
        return newInfo.pickingMaterial == newInfo.material
    }

    //#endregion    ------------------------fin funciones-----------------------------------------------

    //#region State *clave* los campos de el form y vista, y están clasificados por stages (escenarios), es decir, algunos campos aparecen según el stage.
    const [fields, setFields] = useState(
        {


            //Tasks
            otTasks: [
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "input",
                    movementOptions: [],
                    disabled: false,
                    required: true,
                    icon: GrTransaction,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "OT",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchOt,
                    disabled: false,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [1]
                },
                {
                    id: 'btnOpen',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: getPositionsByOtE,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [1]
                },
                {
                    id: 'btnPositionsSelector',
                    label: "Confirmación de salida",
                    width: "12",
                    type: "positionsSelector",
                    color: "success",
                    stage: [2]
                },
                {
                    id: 'ot',
                    label: "Número OT",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [2]
                },
                {
                    id: 'origen',
                    label: "Orígen",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: BiSolidArrowToRight,
                    stage: [2]
                },
                {
                    id: 'destination',
                    label: "Destino",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: FaDoorOpen,
                    stage: [2]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [2]
                },
                {
                    id: 'material',
                    label: "Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2]
                },
                {
                    id: 'serie',
                    label: "Serie",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2],
                    disableIfDontHaveValue: "serie"
                },
                {
                    id: 'plate',
                    label: "Placa",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2],
                    disableIfDontHaveValue: "plate"
                },
                {
                    id: 'serialNumberProfile',
                    label: "Perfil de serie",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2],
                    disableIfDontHaveValue: "serialNumberProfile"
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2]
                },
                {
                    id: 'pickingMaterial',
                    sapField: "",
                    label: "Pick material",
                    width: "4",
                    type: "input",
                    // visibleInMovement: "Z09",
                    // notVisibleInMovement: "Z09",
                    positionVisible: 1,
                    disabled: false,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2],
                    enableIfDontHaveValue: "plate"

                },
                {
                    id: 'platePicking',
                    sapField: "",
                    label: "Pick placa",
                    width: "4",
                    type: "input",
                    // visibleInMovement: "Z09",
                    // notVisibleInMovement: "Z09",
                    positionVisible: 1,
                    disabled: false,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2],
                    disableIfDontHaveValue: "plate" //=> En caso que no 

                },
                {
                    id: 'seriePicking',
                    sapField: "",
                    label: "Pick serie",
                    width: "4",
                    type: "input",
                    // visibleInMovement: "Z09",
                    // notVisibleInMovement: "Z09",
                    positionVisible: 1,
                    disabled: false,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2],
                    enableIfDontHaveValuesArray: ["serie", "plate"] //Si los 2 son vacíos aparezca.

                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendOutboundByOt,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [2]
                },
            ],



        }
    )
    //#endregion



    //#region consoles a borrar.

    useEffect(() => {
        // console.log("New info", newInfo)
    }, [newInfo])

    useEffect(() => {
        // console.log("IdComponent", idComponent)
    }, [idComponent])

    useEffect(() => {
        // console.log("Positions", positions)
    }, [positions])
    //#endregion




    const generateFields = () => {
        console.log("newInfo")
    }


    return (
        <>

            <ModalList
                id={idComponent}
                title={modal.title}
                subtitle={modal.subtitle}
                spinnerText={modal.spinnerText}
                searchText={modal.searchText}
                mainView={mainView}


                openModal={openModal}
                setOpenModal={setOpenModal}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
            // getSupplierByPoE={getSupplierByPoE}

            //Outbound
            // getPositionsByDocE={getPositionsByDocE}

            //Reservation
            // getReservationActiveByDocE={getReservationActiveByDocE}
            // getMovementByReservationE={getMovementByReservationE}

            />

            <FormGeneral
                fields={fields}
                stage={stage}
                isMobile={isMobile}
                title={title}
                position={position}
                setPositions={setPositions}
                buttonSpinner={buttonSpinner}
                localSpinner={localSpinner}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                positions={positions}
                setPosition={setPosition}
                isComputer={isComputer}
                warehouse={warehouse}
                idComponent={idComponent}
                handleOnChange={handleOnChange}
                options={options}
                notValidateQuantity={notValidateQuantity}
                strictedMaterialValidation={strictedMaterialValidation}
            />

        </>
    )
}
