import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { downloadFileRequesById } from "actions/userCloudRequests.jsx";
import { useDispatch } from "react-redux";

const ModalInformation = ({
  requestDetails,
  toggle,
  modal,
  headerText,
  buttonCancelText,
  buttonConfirmText,
  showButtonConfirm,
  handleOnConfirmButton,
  valueInsert,
  valueName,
  labelValueInsert,
  handleOnChangeInfo,
  editing,
  requestSelected,
  notify,
  setLoad,
  files,
  validateConfirm,
  customMethod,
}) => {

  const dispatch = useDispatch();

  const downloadFile = async (item) => {
    const id = requestSelected.id;
    await dispatch(downloadFileRequesById(id)).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        notify("danger", "Error", payload.message);
        setLoad(false);
      }
      else {
        const url = window.URL.createObjectURL(new Blob([payload.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item}`);
        document.body.appendChild(link);
        link.click();
        notify("success", "Exito", "Se descargo correctamente.");
        setLoad(false);

      }
    });

  }
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <Card>
          <CardHeader>
            <Container className="mt-2">
              <h4 className="surtitle">{headerText}</h4>
            </Container>
          </CardHeader>
          <CardBody>
            <Container>
              {requestDetails.map((item, key) => (
                <Row key={`formRequest-${key}`}>
                  <Col className="mt-2" xs="12" md="12">
                    {item.typeRequestDataName === "Texto" ? (
                      <FormGroup>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor={`input-${item["value"]}`}
                        >
                          {`${item[labelValueInsert]}:`}
                        </label>
                        <Input
                          id={item.id}
                          key={key}
                          className="form-control"
                          type="text"
                          defaultValue={item[valueInsert]}
                          onChange={(e) =>
                            handleOnChangeInfo(item.id, e.target.value)
                          }
                          disabled={item.isEditable === 0 || editing ||
                            (requestSelected && requestSelected.statusId !== 4)}
                        />
                      </FormGroup>
                    ) : item.typeRequestDataName ==="Select" || item.typeRequestDataName ==="SelectMulti"   ?
                    (
                    <FormGroup>
                      <label
                        style={{ fontSize: "12px" }}
                        className="form-control-label"
                        htmlFor={`input-${item["value"]}`}
                      >
                        {`${item[labelValueInsert]}:`}
                      </label>
                      <Select
                        isDisabled={
                          item.isEditable === 0 ||
                          editing ||
                          (requestSelected && requestSelected.statusId !== 4)
                        }
                        key={key}
                        className="basic-single is-invalid"
                        type="select"
                        isSearchable
                        required
                        isMulti={item.typeRequestDataId === 8 ? true : false}
                        options={item.options}
                        defaultValue={ item.typeRequestDataId === 8 ? item.value:{
                          label: item.label,
                          value: item[valueInsert],
                        }}
                        onChange={ item.customMethod === 1 ? (e) => customMethod(item, e.value, requestDetails[key + 1]) : (e) => handleOnChangeInfo(item.id, item.typeRequestDataId === 8 ? e :e.value)}
                      />
                    </FormGroup>
                    )
                    :
                    item.typeRequestDataName === "Textarea" && (
                      <FormGroup>
                        <label className="form-control-label">
                          {item[labelValueInsert]}:
                        </label>
                        <Input
                          defaultValue={item[valueInsert]}
                          key={key}
                          className="m-0"
                          type={"textarea"}
                          onChange={(e) => handleOnChangeInfo(item.id, e.target.value)}
                          disabled={item.isEditable === 0 || editing ||
                            (requestSelected && requestSelected.statusId !== 4)}
                        />
                      </FormGroup>)}
                  </Col>
                </Row>
              ))}

              {
                (files && files.length > 0) &&
                <FormGroup>
                  <label
                    style={{ fontSize: "12px" }}
                    className="form-control-label"
                  >
                    Adjuntos:
                  </label>

                </FormGroup>
              }

              {(files && files.length > 0) &&
                files.map((item, keyFile) => (
                  <>
                    <Row>
                      <Col className="mt-2" xs="12" md="12">

                        <span className="btn-inner--icon mr-">
                          <i className="fas fa-file-pdf" key={keyFile}> <a onClick={(e) => downloadFile(item.filename)}>{item.filename}</a></i>
                        </span>
                        <span className="btn-inner--text"></span>
                      </Col>
                    </Row>
                  </>

                ))
              }
            </Container>
          </CardBody>
          <CardFooter>
            <Row className="d-flex justify-content-between">
              <Col xs="12" md="5" className="p-1">
                <Button
                  className="btn  btn-icon"
                  color="danger"
                  block
                  type="button"
                  onClick={toggle}
                >
                  <span className="btn-inner--icon mr-">
                    <i className="fas fa-times"></i>
                  </span>
                  <span className="btn-inner--text">{buttonCancelText}</span>
                </Button>
              </Col>
              {
                showButtonConfirm && (
                  <Col xs="12" md="5" className="p-1">
                    <Button
                      className="btn-icon"
                      color="success"
                      block
                      type="button"
                      onClick={() => handleOnConfirmButton()}
                      disabled={validateConfirm}
                    >
                      <span className="btn-inner--icon mr-">
                        <i className="fas fa-save"></i>
                      </span>
                      <span className="btn-inner--text">{buttonConfirmText}</span>
                    </Button>
                  </Col>
                )
              }
            </Row>
          </CardFooter>
        </Card>
      </Modal>
    </div>
  );
};
export default ModalInformation;
