export const getInactive = state => {
  const inactiveVisibles = [];
  for (const element of state.inactiveSystems) {
    if (element) {
      if (element.visibility === 1) {
        inactiveVisibles.push(element);
      }
    }
  }
  return inactiveVisibles;
};

export const getProjects = state => {
  const projectsVisibles = [];
  for (const element of state.projects) {
    if (element) {
      if (element.visibility === 1) {
        projectsVisibles.push(element);
      }
    }
  }
  return projectsVisibles;
};

export const getCountProjects = state => state.countProjects;

export const getMaintenance = state => {
  const maintenanceVisibles = [];
  for (const element of state.maintenance) {
    if (element) {
      if (element.visibility === 1) {
        maintenanceVisibles.push(element);
      }
    }
  }
  return maintenanceVisibles;
};

export const getInternalTeams = state => {
  if (Object.keys(state.internalTeams).length) {
    const { internalTeams } = state.internalTeams;
    return internalTeams;
  }
  return []
}