import * as jwt from 'jsonwebtoken';
import * as constants from "constants/index.jsx";

export const getTokenAuth = state => state.authenticate;


export const getUsernameLogged = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      // const { routes } = decoded;
      return decoded.user;
    }
    return "";
  } catch (err) {
    return "";
  }
};

export const getSignature = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      return decoded.user;
    }
    return "";
  } catch (err) {
    return "";
  }
};

export const getModulesUserAccess = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      // const { routes } = decoded;
      return decoded.routes;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getTeamsUserAccess = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      // const { teams } = decoded;
      return decoded.teams;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getUserProtected = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      return decoded.protected;
    }
    return {};
  } catch (err) {
    return {};
  }
}

export const getInitialsLettersUser = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      const { EMAIL } = decoded.protected;
      return EMAIL.slice(0, 2);
    }
    return "";
  } catch (err) {
    return "";
  }
}

export const getIsExternal = () => {
  const token = localStorage.getItem('token');
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      // const { routes } = decoded;
      return parseInt(decoded.isExternal);
    }
    return 0;
  } catch (err) {
    console.log("Error al parsear el isExternal" + err)
    return 0;
  }
};