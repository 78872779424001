import React from 'react';
import {
    Col,
    FormGroup,
    Input,
    Row,
    Form,
    Button
} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { Upload, Icon } from 'antd';

const { Dragger } = Upload;
const FormTemplate = ({ itemForm, itemInfo, handleOnChangeInfo, uploadProps, fileList }) => {
   
    return (
        <div>
            <Form noValidate className="isValid needs-validation">
                <Row>
                    {itemForm.map((item, key) => (
                        <Col xs={item.colWidth} key={key}>
                            {
                                !item.isDependency  ?
                                    (
                                        item.type === "selectCustom" ? (
                                            <FormGroup>
                                                <label
                                                    style={{ fontSize: "12px" }}
                                                    className="form-control-label"
                                                >
                                                    {item.label}:
                                                </label>
                                                <Select
                                                    key={key}
                                                    className="basic-single is-invalid"
                                                    type="select"
                                                    required={item.required}
                                                    isDisabled={item.disabled}
                                                    isSearchable
                                                    isMulti={item.multi}
                                                    options={item.options}
                                                    placeholder={item.placeholder}
                                                    value={itemInfo[item.value] && { label: itemInfo[item.value], value: 1 }}
                                                    onChange={(e) => item.onChange(item.value, e, item.valueInsert, item.groupCloud)}
                                                />
                                                <div className="invalid-feedback">
                                                    Este campo no puede estar vacio
                                                </div>
                                            </FormGroup>
                                        ) :
                                            item.type === "select" ?
                                                <FormGroup>
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                    >
                                                        {item.label}:
                                                    </label>
                                                    <Select
                                                        key={key}
                                                        className="basic-single is-invalid"
                                                        type="select"
                                                        required={item.required}
                                                        isDisabled={item.disabled}
                                                        isSearchable
                                                        isMulti={item.multi}
                                                        options={item.options}
                                                        placeholder={item.placeholder}
                                                        value={itemInfo[item.value] && itemInfo[item.value]}
                                                        onChange={(e) => handleOnChangeInfo(item.value, e, item.valueInsert)}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Este campo no puede estar vacio
                                                    </div>
                                                </FormGroup>
                                                : item.type === "input" ?
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            {item.label}:
                                                        </label>
                                                        <Input
                                                            // defaultValue={item.type === "date" ? moment.utc(itemInfo[item.value]).format("YYYY-MM-DD")
                                                            // : itemInfo[item.value]}
                                                            key={key}
                                                            disabled={item.disabled}
                                                            required={item.required}
                                                            className="m-0"
                                                            type={item.type}
                                                            value={itemInfo[item.value] && itemInfo[item.value]}
                                                            onChange={(e) => handleOnChangeInfo(item.value, e.target.value, item.valueInsert)}
                                                        />
                                                        <div className="invalid-feedback">
                                                            Este campo no puede estar vacio
                                                        </div>
                                                    </FormGroup>
                                                    : item.type === "inputButton" ?
                                                        <>
                                                            <Row className="d-flex align-items-center">
                                                                <Col xs={item.colWidth} key={key}>
                                                                    <FormGroup>
                                                                        <label className="form-control-label">
                                                                            {item.label}:
                                                                        </label>
                                                                        <Input
                                                                            key={key}
                                                                            required={item.required}
                                                                            className="m-0"
                                                                            type={item.type}
                                                                        // onChange={(e) => handleOnChangeInfo(item.value, e.target.value)}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xs={1} key={`${key}button`}>

                                                                    <Button
                                                                        key={`${key}button`}
                                                                        className="btn mt-2"
                                                                        color="primary"
                                                                    // onClick={() => handleOnGetInfoByUser()}
                                                                    >
                                                                        <span className="btn-inner--icon mr-">
                                                                            <i className="fas fa-search"></i>
                                                                        </span>
                                                                        <span className="btn-inner--text"></span>
                                                                    </Button>

                                                                </Col>
                                                            </Row>

                                                        </>
                                                        : item.type === "textarea" ?
                                                            <FormGroup>
                                                                <label className="form-control-label">
                                                                    {item.label}:
                                                                </label>
                                                                <Input
                                                                    // defaultValue={item.type === "date" ? moment.utc(itemInfo[item.value]).format("YYYY-MM-DD")
                                                                    // : itemInfo[item.value]}
                                                                    key={key}
                                                                    required={item.required}
                                                                    className="m-0"
                                                                    type={item.type}
                                                                    onChange={(e) => handleOnChangeInfo(item.value, e.target.value, item.valueInsert)}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    Este campo no puede estar vacio
                                                                </div>
                                                            </FormGroup>
                                                            :
                                                            <FormGroup>
                                                                <label className="form-control-label">
                                                                    {item.label}:
                                                                </label>
                                                                <Dragger {...uploadProps} fileList={fileList}>
                                                                    <p className="ant-uploading-drag-icon">
                                                                        <Icon type="inbox" />
                                                                    </p>
                                                                    <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                                                                    <p className="ant-uploading-hint">
                                                                        Puede seleccionar archivos de diferentes folders/carpetas
                                                                    </p>
                                                                </Dragger>
                                                                <div className="invalid-feedback">
                                                                    Este campo no puede estar vacio
                                                                </div>
                                                            </FormGroup>

                                    )
                                    :  item.meetsDependency === true &&
                                    <FormGroup>
                                        <label className="form-control-label">
                                            {item.label}:
                                        </label>
                                        <Input
                                            key={key}
                                            disabled={item.disabled}
                                            required={item.required}
                                            className="m-0"
                                            type={item.type}
                                            value={itemInfo[item.value] && itemInfo[item.value]}
                                            onChange={(e) => handleOnChangeInfo(item.value, e.target.value, item.valueInsert)}
                                        />
                                    </FormGroup>
                            }

                        </Col>
                    ))}
                </Row>
            </Form>
        </div>
    );
};
export default FormTemplate;