/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to show as a Modal, conflict details when creating a report in any module (CATS, OS / NETWORK OR Extra Hours)
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import ModalNoMrs from "./OSNetworks/ModalNoMrs";
import ModalDetailsCats from "./CATS/ModalDetailsCATS";
import ModalDetailsExtra from "./ExtraHours/ModalDetailsExtra";

function ConflictDetails({
  isOpen,
  report,
  reportType,
  formatDetails,
  handleClose,
}) {
  return (
    <div>
      {reportType === "OS / Network" && (
        <ModalNoMrs
          isOpen={isOpen}
          report={report}
          handleClose={handleClose}
          isOS={report.ORDEN_INTERNA ? true : false}
        />
      )}

      {reportType === "OS / Network" &&
        report.ORDEN_INTERNA === null &&
        report.NETWORK === null && (
          <ModalDetailsCats
            isOpen={isOpen}
            report={report}
            handleClose={handleClose}
            isOS={report.ORDEN_INTERNA ? true : false}
          />
        )}

      {reportType === "CATS" &&
        (report.ORDEN_INTERNA != null || report.NETWORK != null) && (
          <ModalNoMrs
            isOpen={isOpen}
            report={report}
            handleClose={handleClose}
            isOS={report.ORDEN_INTERNA ? true : false}
          />
        )}

      {reportType === "CATS" &&
        report.ORDEN_INTERNA === null &&
        report.NETWORK === null && (
          <ModalDetailsCats
            isOpen={isOpen}
            report={report}
            handleClose={handleClose}
            isOS={report.ORDEN_INTERNA ? true : false}
          />
        )}

      {reportType === "Extra" && (
        <ModalDetailsExtra
          isOpen={isOpen}
          request={report}
          handleClose={handleClose}
          formatDetails={formatDetails}
        />
      )}
    </div>
  );
}

export default ConflictDetails;
