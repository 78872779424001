import { Col, FormGroup, Input, Row } from "reactstrap";
// react-select library for filters
import Select from "react-select";
// react library
import React, { useState } from 'react';

const ExperienceForm = ({
    data,
    row,
    handleOnChange,
    dropDownOptions
}) => {

    const [fields, setFields] = useState([
        {
            colWidth: "4",
            elementType: "Input",
            label: "Fecha de Inicio",
            placeholder: "Fecha de Inicio",
            id: "experienceStartDate",
            type: "date",
            min: 0,
            max: 1999999999,
            defaultValue: "sDate",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Fecha de finalización",
            placeholder: "Fecha de finalización",
            id: "experienceFinDate",
            type: "date",
            min: 0,
            max: 1999999999,
            defaultValue: "fDate",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Nombre de la Empresa",
            placeholder: "Nombre de la Empresa",
            id: "companyName",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "companyName",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "País donde laboró",
            placeholder: "País donde laboró",
            id: "companyCountry",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "companyCountry",
            disabled: false,
            options: "CountryCompany",
            value: "CountryCompany",
            defaultValueLabel: "countryName",
            defaultValueValue: "companyCountry",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "Puesto desempeñado",
            placeholder: "Puesto desempeñado",
            id: "companyJob",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "companyJob",
            disabled: false,
            options: "LastEmployeePosition",
            value: "LastEmployeePosition",
            defaultValueLabel: "positionName",
            defaultValueValue: "companyJob",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Referencia laboral",
            placeholder: "Referencia laboral",
            id: "companyContact",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "companyContact",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Teléfono de referencia",
            placeholder: "Teléfono de referencia",
            id: "contactPhone",
            type: "number",
            min: 0,
            max: 1999999999,
            defaultValue: "contactPhone",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        }
    ]);


    return (


        <>
            <Row className="align-items-center">
                {
                    fields.map((field, key) => (


                        <Col xs={field.colWidth} key={key}>
                            <FormGroup key={key}>
                                <label className="form-control-label">{field.label}</label>
                                {
                                    field.elementType === "Input" ?
                                        <Input
                                            key={field.id}
                                            id={field.id}
                                            className="form-control"
                                            type={field.type}
                                            readOnly={field.disabled}
                                            defaultValue={data && data[field.defaultValue]}
                                            placeholder={field.placeholder}
                                            onChange={(e) => handleOnChange("CandidateExperience", field.id, e.target.value)}
                                        />
                                        :
                                        <Select
                                            key={field.id}
                                            className="basic-single"
                                            id={field.id}
                                            type={field.type}
                                            placeholder={field.placeholder}
                                            isSearchable
                                            isDisabled={field.disabled}
                                            options={dropDownOptions[field.options]}
                                            defaultValue={{ label: data && data[field.defaultValueLabel], value: data && data[field.defaultValueValue] }}
                                            onChange={(e) => handleOnChange("CandidateExperience", field.id, e.value)}
                                        />
                                }
                            </FormGroup>
                        </Col>
                    ))
                }
            </Row>
        </>
    );
};

export default ExperienceForm;