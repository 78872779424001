/*!

=========================================================
*Autopp - AutoppStart
=========================================================

*Componente "Inicio" el cual llama a CardSelectForm (botones para seleccionar form), 
y MainTableAutopp (el cual despliega las diferentes vistas).
*Gracias al customHook UserContext puede establecer por contexto (globales) states,
lo cual ayuda para que todos los componentes hijos puedan tener acceso sin pasar por props.
*Se llaman las opciones (actions) de todos los dropdowns y se establecen por contexto.
*Se establecen states tipo bandera, listas, y diferentes tipos para gestión de componentes hijos. 

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de React
import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Container, Row, Col, PaginationItem,
  PaginationLink,
  Pagination,
  Button,
} from "reactstrap";

//Imports de Estilos para los botones.
import "../../assets/css/StylesAutopp.css"

// imports de Alerts y  Spin
import { Spin } from 'antd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// imports de actions
import {
  getLDRsOptionsData,
  getOptionsData,
  getLDRFields,
  getCostumers,
  getEmployees,
  getDataRows,
} from 'actions/autoppLdrs';

//Imports de las diferentes vistas
import { CardSelectForm } from 'components/AutoppLdrs/CardSelectForm';
import MainTableAutopp from 'components/AutoppLdrs/MainTableAutopp';
import HelpRequest from 'components/AutoppLdrs/SharedComponents/HelpRequest';
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

//Import de componente contexto, para establecer variables globales
import { UserContext } from 'components/AutoppLdrs/SharedComponents/UserContext';

import { getUsernameLogged, getTeamsUserAccess } from 'selectors/adminLayout';
import { useDispatch } from 'react-redux';
import { ModalRequestBAW } from 'components/AutoppLdrs/Requests/ModalRequestBAW';

export const AutoppStart = React.memo(() => {

  const dispatch = useDispatch();

  //#region Generación componente Alert.
  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 10000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  //#endregion  

  //#region ref de typeaheads del proyecto

  const refTypeaheadClientForm = React.createRef();
  const [cleanTypeaheads, setCleanTypeaheads] = useState(false)

  //#endregion

  //#region states globales para todos los forms usados en contexto y props.    

  const userName = getUsernameLogged();

  //Número random temporal, para usarlo de idTemporal y subir los archivos BOM al ftp
  const [temporalFolderFilesId] = useState(new Date().getTime());
  const [fileListBOM, setFileListBOM] = useState({ fileList: [] })
  useEffect(() => {
    console.log("temporalFolderFilesId:" + temporalFolderFilesId);
  }, [])


  const roles = getTeamsUserAccess();

  //state ***PRINCIPAL*** para guardar info de todos los forms.
  const [editData, setEditData] = useState({ "costumers": { "country": "", "name": "" }, "userName": userName, "LDRS": "" });

  //state de la lista de LDRS 
  const [ldrsList, setLdrsList] = useState({})

  //State para establecer si mostrar la vista de requests o la vista del formulario.
  const [requestsOrForm, setRequestsOrForm] = useState("forms");

  //State que establece el form a mostrar.
  const [formSelected, setFormSelected] = useState("1");

  //Toggles seleccionados en GeneralInformation para escoger LDRs o BOM
  const [toggleCheck, setToggleCheck] = useState({ "applyLDROption": false, "applyBOMPNOption": false });

  //Contador de toggles LDRS activos en General Information.
  const [countLDRActives, setCountLDRActives] = useState(0);

  //state de comunicación para eliminar LDRS según el toggle.
  const [deleteLDR, setDeleteLDR] = useState("")

  //#region banderas
  //bandera para indicar a los componentes que agreguen su información local al state principal editData.
  const [readyToSend, setReadyToSend] = useState(false);
  //Bandera para cuando se envie la solicitud reinicie los toggles de General Information y la lista de LDRMainForm
  const [resetSpecificStates, setResetSpecificStates] = useState(false);
  //#endregion 

  //States para el Spinners para diferentes componentes.
  const [loading, setLoad] = useState(false); //pantalla completa

  const [loadingCostumers, setLoadingCostumers] = useState(false);

  const [loadingEmployees, setLoadingEmployees] = useState(false);

  //State para comunicar el ciclo de ventas de la oportunidad
  const [cycle, setCycle] = useState(1)

  //#endregion

  //#region states de dropdowns
  const [options, setOptions] = useState({
    dropDownOptions: { contacts: "" },
  });

  const { dropDownOptions } = options;

  const [ldrsOptions, setLdrsOptions] = useState({
    LDRfields: {},
  });
  const { LDRfields } = ldrsOptions;

  const [costumers, setCostumers] = useState({
    costumersOptions: {},
  });
  const { costumersOptions } = costumers;

  const [employees, setEmployees] = useState({
    employeesOptions: {},
  });
  const { employeesOptions } = employees;

  //#endregion

  //#region Efecto para traer todas las peticiones al API cuando arranque.
  useEffect(() => {
    const getOptionsGeneral = async () => {
      await getOptions();
      await getLDRFieldsF();
      await getCostumersOptions();
      await getEmployeesOptions();


    }
    getOptionsGeneral();

  }, [])


  //trae la data general para todos los dropdowns exceptuando ldrs
  const getOptions = () => {
    setLoad(true);
    dispatch(getOptionsData()).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {
        setOptions({
          dropDownOptions: payload.data.payload.masterData
        })
      }
      setLoad(false);

      // //Cuando termine de extraer la general que termine extrayendo la de LDRS
      // const getOptionsLDR = async () => {
      //   await getLDRsDropdowns();
      // }
      // getOptionsLDR();

    })
  }

  //trae los dropdowns para el componente ldrs porque son muy pesados
  const getLDRFieldsF = () => {

    dispatch(getLDRFields()).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {
        // console.log("LOS LDRSSS", payload.data.payload.LDRFields)

        let LDRFieldsArray = payload.data.payload.LDRFields;

        //Si vienen LDRS que son null que no los tome en cuenta.
        Object.keys(LDRFieldsArray.useLDRFields).map(LDRId => {
          if (LDRFieldsArray.useLDRFields[LDRId] == null) {
            console.log("El LDR con id:" + LDRId + " tiene valor null por tanto es eliminado de la lista, revisar los campos si están inactivos esa puede ser la causa que viene null.");
            delete LDRFieldsArray.useLDRFields[LDRId];
          }
        })

        setLdrsOptions({
          ...ldrsOptions,
          LDRfields: LDRFieldsArray

        })
      }

      // const getCostumersOpt = async () => {
      //   await getCostumersOptions();
      // }
      // getCostumersOpt();
    })
  }

  //trae los clientes de GBM porque son muy pesados
  const getCostumersOptions = () => {
    //console.log("aqui costumers")
    setLoadingCostumers(true)
    setLoadingEmployees(true);

    dispatch(getCostumers()).then((resp) => {
      const { payload } = resp;
      //console.log(payload)

      if (payload.status === 200) {

        setCostumers({
          costumersOptions: payload.data.payload.costumers
        })
      }

      setLoadingCostumers(false)

      // const getEmployeesOpt = async () => {
      //   await getEmployeesOptions();
      // }
      // getEmployeesOpt();

    })
  }

  //trae los empleados de GBM porque son muy pesados
  const getEmployeesOptions = () => {
    //console.log("aqui employees")

    dispatch(getEmployees()).then((resp) => {
      const { payload } = resp;
      //console.log(payload)
      if (payload.status === 200) {

        setEmployees({
          employeesOptions: payload.data.payload.employees
        })
      }

      setLoadingEmployees(false);
    })
  }

  //#endregion

  //#region tabla de reporte de solicitudes de oportunidades y que extrae las estadisticas para BAW

  // rowsRequests, setRowsRequests, reloadTableRequests, setRealoadTableRequests 
  const [rowsRequests, setRowsRequests] = useState({
    rows: [],
    statsBaw: [],
    length: 0,
  })

  //state para refrescar la tabla de Requests
  const [reloadTableRequests, setReloadTableRequests] = useState(false);

  //efecto para llenar la tabla principal de requests

  useEffect(() => {
    if (reloadTableRequests) {

      const getDataR = async () => {
        await getData();
      }
      getDataR();
    }
  }, [reloadTableRequests]);


  const getData = async () => {

    dispatch(getDataRows({ teams: getTeamsUserAccess(), user: getUsernameLogged() })).then((resp) => {

      const { payload } = resp;
      //console.log(payload)
      if (payload.status === 200) {
        setRowsRequests((e) => ({
          ...e,
          rows: payload.data.payload.rows,
          statsBaw: payload.data.payload.statsBaw,
          length: payload.data.payload.rows.length,
        }))

      } else {
        setRowsRequests({
          rows: [],
          statsBaw: [],
          length: 0,
        })

      }
      setReloadTableRequests(false);
      //setLoad(false);
    })
  }

  //Filtros request tables
  const [filters, setFilters] = useState([]);


  //Capturar los valores de los inputs de los filtros
  const handleOnfilterDynamic = (constant, e) => {
    console.log(constant, e)
    setFilters(prevState => ({
      ...prevState,
      [constant]: e
    }))
  }

  //Aplicar los filtros a la data de la tabla
  const renderRowsByFilters = (rows) => {

    if (Object.keys(filters).length === 0) {
      return rows
    } else {
      const filterKeys = Object.keys(filters);
      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();
        rows = rows.filter((item) => item[element].toString().toLowerCase().indexOf(valueFiltered) != -1)
      }
      return rows
    }
  }

  //#endregion

  //#region modal de BAW en la pantalla de requests.
  //State para establecer los datos del modal de BAW.
  const [modalBAW, setModalBAW] = useState({
    length: 0,
    showBAWModal: false,
    rowsBAW: ""
  })

  //columnas de la tabla principal y multicolumn
  const optionsRequestsSelect = [

    { value: "id", label: "# Gestión" },
    { value: "opp", label: "Oportunidad", disabled: true },
    { value: "typeOportunity", label: "Tipo de oportunidad" },
    { value: "description", label: "Descripción" },
    { value: "initialDate", label: "Fecha inicial" },
    { value: "finalDate", label: "Fecha final" },
    { value: "salesCycle", label: "Ciclo de ventas" },
    { value: "sourceOportunity", label: "Origen oportunidad" },
    { value: "salesType", label: "Tipo de venta" },
    { value: "applyOutsourcing", label: "Outsourcing" },
    { value: "status", label: "Estado", disabled: true },
    { value: "createdAt", label: "Fecha de Creación", disabled: true },
    { value: "createdByName", label: "Creado por", disabled: true },
    { value: "seeStatusBaw", label: "Ver botones", disabled: true },
    { value: "EmployeeResponsible", label: "Empleado responsable" },
    { value: "company", label: "Cliente", disabled: true },
    { value: "requestExecutive", label: "Ejecutivo solicitud", disabled: false },
    { value: "positionExecutive", label: "Posición del Ejecutivo", disabled: false },
    { value: "emailExecutive", label: "Correo electrónico del ejecutivo", disabled: false },
    { value: "phoneExecutive", label: "Número de Teléfono del ejecutivo", disabled: false },
    { value: "deliveryAddress", label: "Dirección de entrega del servicio", disabled: false },
    { value: "openingHours", label: "Horario de Atención", disabled: false },
    { value: "clientWebSide", label: "Dirección de la Página web del cliente", disabled: false },
    { value: "clientProblem", label: "Problema o dolor asociado", disabled: false },
    { value: "basicNecesity", label: "Necesidad básica", disabled: false },
    { value: "expectationDate", label: "Expectativa solución operativa", disabled: false },
    { value: "haveAnySolution", label: "Tiene alguna solución", disabled: false },
    { value: "anothersNotes", label: "Notas Adicional", disabled: false },

  ];
  const [columnsSelectedTableRequests, setColumnsSelectedTableRequests] = useState(
    [

      { value: "seeStatusBaw", label: "Ver botones" },
      { value: "opp", label: "Oportunidad" },
      { value: "company", label: "Cliente" },
      { value: "status", label: "Estado" },
      { value: "EmployeeResponsible", label: "Empleado responsable" },
      { value: "createdByName", label: "Creado por" },
      { value: "createdAt", label: "Fecha de Creación" },
      { value: "description", label: "Descripción" },
    ]
  );
  //#endregion

  //#region Botones 
  //Botones de siguiente y anterior.
  const buttonLeftOrRight = (direction) => {

    let limitUp = 6;

    if (cycle == 1/*GTL Quotation*/) { limitUp = 6; }
    else if (cycle == 4 /*Quotation */ || cycle == 6 /*Gesto */) { limitUp = 5; }
    else  /*==> Todos los demás */ { limitUp = 4; }


    return (
      <Col sm="1" className="p-0 d-flex align-items-center">

        {((direction == "left" && parseInt(formSelected) != 1) || //Si es la primer página que no muestre el botón anterior
          (direction == "right" && parseInt(formSelected) != (limitUp - 1)))  //Si es la última página que no muestre el botón siguiente

          &&
          <Button size="sm" color="default"
            onClick={() =>

              direction == "left" ?

                ((parseInt(formSelected) - 1) > 0 &&

                  setFormSelected((parseInt(formSelected) - 1) + "")
                )
                :
                ((parseInt(formSelected) + 1) < limitUp &&

                  setFormSelected((parseInt(formSelected) + 1) + "")
                )
            }
          >

            {
              direction == "left" ?
                (<span> &larr; Anterior </span>)
                :
                (<span> Siguiente &rarr; </span>)
            }

          </Button>

        }

      </Col>)
  }
  //#endregion



  //Almacenar la información general cuando esté lista para enviarse.
  const [generalInformationInfo, setGeneralInformationInfo] = useState(null)

  //Almacenar la información del cliente cuando esté lista para enviarse.
  const [clientInfo, setClientInfo] = useState(null)

  //Reiniciar información general y de cliente.
  const resetInfo = () => {
    setGeneralInformationInfo(null)
    setClientInfo(null)

  }

  //Lista de sales teams.
  const [salesTeamsList, setSalesTeamsList] = useState(
    [{
      id: new Date().getTime(),
      information: {}
    },
    ])

  //Reiniciar state.
  const handleResetSalesTeams = () => {

    setSalesTeamsList([{
      id: new Date().getTime(),
      information: {}
    },
    ])

  }

  //Lista de baw.
  const [bawItemsList, setBawItemsList] = useState([
    {
      id: new Date().getTime(),
      information: {}
    }
  ])

  //Reiniciar state de baw.
  const handleResetBaw = () => {

    setBawItemsList([
      {
        id: new Date().getTime(),
        information: {}
      }
    ])
  }

  //Bandera para decirle al sistema que ya todos los LDRS se agregaron al state principal en MainTableAutopp.
  const [LDRSAreReady, setLDRSAreReady] = useState(false)


  return (
    <>
      <UserContext.Provider value={
        {
          userName,
          roles,

          readyToSend,
          setReadyToSend,

          loading,
          setLoad,
          loadingEmployees,
          loadingCostumers,
          dropDownOptions,
          options,
          setOptions,
          costumersOptions,
          employeesOptions,

          Toast,

          //RequestsViews
          requestsOrForm,
          setRequestsOrForm,
          optionsRequestsSelect,
          columnsSelectedTableRequests,
          setColumnsSelectedTableRequests,
          rowsRequests,
          setRowsRequests,
          reloadTableRequests,
          setReloadTableRequests,
          handleOnfilterDynamic,
          renderRowsByFilters,
          modalBAW,
          setModalBAW,


          formSelected,
          setFormSelected,

          //General Information & LDRMainForm
          resetSpecificStates,
          setResetSpecificStates,

          //ClientForm
          refTypeaheadClientForm,
          cleanTypeaheads,
          setCleanTypeaheads,

          //LDRS
          countLDRActives,
          setCountLDRActives,
          toggleCheck,
          setToggleCheck,

          LDRfields,

          ldrsList,
          setLdrsList,

          deleteLDR,
          setDeleteLDR,

          //BOM/Cartel de licitación, etc
          temporalFolderFilesId,
          fileListBOM,
          setFileListBOM,

          //State principal de datos.
          editData,
          setEditData,

          //State para comunicar el ciclo de ventas
          cycle,
          setCycle,





          generalInformationInfo,
          setGeneralInformationInfo,
          clientInfo,
          setClientInfo,
          resetInfo,

          bawItemsList,
          setBawItemsList,
          salesTeamsList,
          setSalesTeamsList,

          handleResetSalesTeams,
          handleResetBaw,

          setLDRSAreReady,
          LDRSAreReady


        }
      }>

        <ModalRequestBAW
          setReloadTableRequests={setReloadTableRequests}
          modalBAW={modalBAW}
          setModalBAW={setModalBAW}

        ></ModalRequestBAW>

        <AdminHeader name="Inicio" parentName="Fábrica de Propuestas - Ventas" />
        <Spin size="large" spinning={loading}>

          <Container className="mt--6" fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col sm='12'>
                    <CardSelectForm />
                  </Col>

                </Row>
                {requestsOrForm === "forms" && //Solo aparezca si está seleccionado los forms
                  <Row>
                    <Col sm='12' style={{ display: "flex", justifyContent: "flex-end" }}>
                      <HelpRequest />
                    </Col>
                  </Row>
                }

                <Row>
                  <Col sm='12'>
                    <MainTableAutopp

                      ldrsList={ldrsList}
                      setLdrsList={setLdrsList}
                    />
                  </Col>
                </Row>

                {requestsOrForm === "forms" && //Solo aparezca si está seleccionado los forms
                  <Row className="ml-1 mr-2 mt--4">



                    {buttonLeftOrRight("left")}
                    <Col sm="10" />
                    {buttonLeftOrRight("right")}


                  </Row>
                }


              </CardBody>
            </Card>
          </Container>

        </Spin>

      </UserContext.Provider>

      {/*JSON.stringify(editData)*/}
    </>

  )
})

export default AutoppStart;


//ventas stateBotones
//ventas especificas
//vista de precios
//botones 
