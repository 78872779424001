/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to create the filters of Date for the hours report (CATS, OS / NETWORK OR Extra Hours)
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
  Button,
} from "reactstrap";
import ReactDatetime from "react-datetime";
function DateTableFilters({
  DatetimeChange,
  startDate,
  endDate,
  dateLabels,
  title,
  subtitle,
  resetTimeFilter,
}) {
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{subtitle}</p>
          </Col>
          <Col
            sm="12"
            md="2"
            className="d-flex justify-content-end align-items-center"
          >
            <Button
              className="btn-icon"
              color="info"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                height: "2rem",
                fontSize: "0.75rem",
                marginTop: "1rem",
                justifyContent: "center",
              }}
              onClick={resetTimeFilter}
            >
              <span className="btn-inner--text">Restablecer fechas</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="input-daterange datepicker align-items-center">
          <Col xs={12} sm={6}>
            <label className="form-control-label">Fecha de Inicio</label>
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  placeholder: "Elija una fecha",
                }}
                value={startDate}
                onChange={(date) => DatetimeChange(dateLabels[0], date)}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <label className="form-control-label">Fecha de Fin</label>
              <ReactDatetime
                inputProps={{
                  placeholder: "Elija una fecha",
                }}
                value={endDate}
                onChange={(date) => DatetimeChange(dateLabels[1], date)}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

DateTableFilters.propTypes = {
  DatetimeChange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default DateTableFilters;
