//Imports de React
import React, { useState, useEffect } from 'react';

//Reactstrap librería
import {
    Container,
    CardBody,
    Card,
} from "reactstrap";

import { useDispatch } from 'react-redux';


// imports de Alerts y  Spin
import { Spin } from 'antd';

import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// imports de actions
import {
    getOptions,
    getEmployees,
} from 'actions/masterData';

//Funciones para averiguar el usuario y sus roles en SS
//import { getTeamsUserAccess } from 'selectors/adminLayout';

//Componentes hijos.
import { RequestDetailModal } from 'components/NewMasterData/SharedComponents/RequestDetailModal';
import { ApprovalsTableMasterData } from 'components/NewMasterData/Approvals/ApprovalsTableMasterData';
import { IndividualFormModal } from 'components/NewMasterData/SharedComponents/IndividualFormModal';


import { getTeamsUserAccess } from 'selectors/adminLayout';
import { NotAccessView } from 'components/NewMasterData/SharedComponents/NotAccessView';


import { desencrypt } from 'helpers/desencrypt.jsx';


const ApprovalsMasterData = () => {

    // Función para traer solicitudes de la DB
    const dispatch = useDispatch();

    //state para refrescar la tabla de Requests
    const [reloadTableRequests, setReloadTableRequests] = useState(false);

    //State para establecer los datos del modal de Detalle de Gestión de mis gestiones.
    const [modalDetail, setModalDetail] = useState({
        //length: 0,
        showDetailModal: false,
        view: "",
    })

    //#region Extraer opociones de los dropdowns y el nombre de los empleados

    const [options, setOptions] = useState([]);

    useEffect(() => {
        getOptionsD()
    }, [])

    //Trae las opciones de los dropdowns
    const getOptionsD = async () => {

        dispatch(getOptions()).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
                //console.log(payload)

                setOptions(payload.data.payload.masterData)

            } else {
                console.log(payload)
            }

            getEmployeesOptions()
            //setReloadTableRequests(false);
            //setLoad(false);
        })
    }
    //#endregion






    //#region Extraer los nombres de los empleados del API
    const [employees, setEmployees] = useState({
        employeesOptions: {},
        loadingEmployees: false
    });
    const { employeesOptions, loadingEmployees } = employees;



    //trae los empleados de GBM de último porque son muy pesados
    const getEmployeesOptions = async () => {
        //console.log("aqui employees")
        setEmployees({
            employeesOptions: {},
            loadingEmployees: true
        });

        dispatch(getEmployees()).then((resp) => {
            const { payload } = resp;
            //console.log(payload)
            if (payload.status === 200) {

                setEmployees({
                    employeesOptions: JSON.parse(desencrypt(payload.data.payload.employees)),
                    loadingEmployees: false
                })
            } else {
                console.log("Ocurrió un error al extraer los empleados: " + payload)
                setEmployees({
                    employeesOptions: {},
                    loadingEmployees: false
                });
            }
        })
    }

    //#endregion 

    useEffect(() => {
        //console.log(employees)

    }, [employees])






    //#region Abrir el modal de el formulario individual

    //State para el formularion individual tipo lineal.
    // const [individualFormModal, setIndividualFormModal] = useState({
    //     showModal: false,
    //     view: "",
    //     row: "",
    //     formName: "",
    //     newInfo: { info: {}, updateInfo: false }
    // })

    const [stateModalsIndvForms, setStateModalsIndvForms] = useState([])


    //#endregion

    //Filtra para averiguar si el usuario posee permisos de master data y mostrar la vista o no 
    const haveMasterDataPerm = getTeamsUserAccess().filter(permission => permission.includes("Master Data"))


    return (
        <>
            <RequestDetailModal
                options={options}
                modalDetail={modalDetail}
                setModalDetail={setModalDetail}
                setReloadTableRequests={setReloadTableRequests}

                // //Para poder abrir desde este modal el formulario individual
                // individualFormModal={individualFormModal}
                // setIndividualFormModal={setIndividualFormModal}


                stateModalsIndvForms={stateModalsIndvForms}
                setStateModalsIndvForms={setStateModalsIndvForms}
            >

            </RequestDetailModal>

            {/* <IndividualFormModal
            options={options}
            individualFormModal={individualFormModal}
            setIndividualFormModal={setIndividualFormModal}
            //setReloadTableRequests={setReloadTableRequests}


            > 

            </IndividualFormModal>*/}

            {
                //Este arreglo se genera en RequestDetailModal
                stateModalsIndvForms.length > 0 &&
                stateModalsIndvForms.map((individualFormModal) => (
                    <IndividualFormModal


                        //Modal actual 
                        individualFormModal={individualFormModal}

                        //Para ver/modificar el arreglo con todos los modalesLineales
                        stateModalsIndvForms={stateModalsIndvForms}
                        setStateModalsIndvForms={setStateModalsIndvForms}

                        //Para los dropdowns
                        options={options}
                        employeesOptions={employeesOptions}
                    //setReloadTableRequests={setReloadTableRequests}


                    >

                    </IndividualFormModal>
                )
                )
            }





            <AdminHeader name="Aprobaciones" parentName="Datos Maestros" />
            <Spin size="large" spinning={false}>
                <Container className="mt--6" fluid>
                    <Card>
                        <CardBody>
                            {
                            haveMasterDataPerm.length>0?
                            <ApprovalsTableMasterData
                                reloadTableRequests={reloadTableRequests}
                                setReloadTableRequests={setReloadTableRequests}
                                setModalDetail={setModalDetail}
                            ></ApprovalsTableMasterData>
                            :
                            <NotAccessView/>
}
                        </CardBody>
                    </Card>
                </Container>
            </Spin>
        </>
    );
};



export default ApprovalsMasterData;