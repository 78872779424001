// actions get resourses
import {
  allYearExtraSumHoursWidget,
  allYearExtraSumHoursGraph,
  countryExtrasSumHoursWidget,
  countryExtrasSumHoursGraph,
  findMonthWidget,
  findMonthWidgetCountry,
  userRolesExtra,
  findYearsExtras
} from "actions/extraHours";
// core components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import ChartStates from "components/ExtraHours/ExtraHoursChartStates";
import WidgetsInfo from "components/ExtraHours/WidgetsInfo";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
// reactstrap components
import { Container, Progress } from "reactstrap";
//moment lib
import moment from "moment";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";

import NotificationAlert from "react-notification-alert";

import {
  getSumAllYearHoursWidget,
  getSumAllYearHoursGraph,
  getSumCountryYearHoursWidget,
  getSumCountryYearHoursGraph,
  getMonthWidgets,
  getMonthWidgetsCountry,
  getUserRolesExtra,
  getYearsExtras
} from "selectors/extraHours";

/**
 * Clase para visualizar los componentes que
 * se utilizaran en el modulo de Planning MRS
 * @class PlanningMrs
 * @extends {Component}
 */
class ExtraHoursDashboardStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading1: false,
      loading2: false,
      selection: "Todos",
      monthHoursWidget: {},
      countries: [],
      countriesOptions: [],
      yearsOptions: [],
      yearSelected: 2020
    };
  }

  componentWillMount() {
    this.setState({
      loading2: true,
      loading1: true
    });

    this.props.findYearsExtras().then(async res => {
      // this takes the hours for widget
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          this.setState({
            loading2: false,
            loading1: false
          });
          const {
            data: { payload }
          } = res.payload.response;
          await this.notify("warning", "Atención", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
          this.setState({
            loading2: false,
            loading1: false
          });
        }
      } else {
        // console.log("NOW");
        if (this.props.yearsExtras.length > 0) {
          let yearsOptionsTemp = [];
          this.props.yearsExtras.forEach(e =>
            yearsOptionsTemp.push({ value: e.years, label: e.years })
          );
          var currentYear = yearsOptionsTemp[yearsOptionsTemp.length - 1].value;
          this.setState({
            yearsOptions: yearsOptionsTemp,
            yearSelected: currentYear
          });
          this.props.userRolesExtra().then(async res => {
            // this takes the hours for widget
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload }
                } = res.payload.response;
                await this.notify("warning", "Atención", payload.message);
              } else {
                await this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
                this.setState({
                  loading2: false,
                  loading1: false
                });
              }
            } else {
              var teams = this.props.rolesExtra;
              var regional = false;
              var managers = false;
              for (var i = 0; i < teams.length; i++) {
                if (teams[i] === "Extra Hours") {
                  regional = true;
                }
                if (teams[i] === "Extra Hours Managers") {
                  managers = true;
                }
              }
              if (regional || managers) {
                this.setState({
                  countries: ["Todos", "CR", "DO", "GT", "HN", "NI", "PA", "SV"]
                });
                if (managers === true) {
                  this.setState({
                    countries: []
                  });
                }
                let countriesOptionsTemp = [];
                this.state.countries.forEach(e =>
                  countriesOptionsTemp.push({ value: e, label: e })
                );
                this.setState({
                  countriesOptions: countriesOptionsTemp
                });
                const data = {
                  year: this.state.yearSelected
                };
                this.props.allYearExtraSumHoursWidget(data).then(async res => {
                  // this takes the hours for widget
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload }
                      } = res.payload.response;
                      await this.notify("warning", "Atención", payload.message);
                    } else {
                      await this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                      this.setState({
                        loading2: false,
                        loading1: false
                      });
                    }
                  }
                });

                this.props.allYearExtraSumHoursGraph(data).then(async res => {
                  // this takes the hours for graph
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload }
                      } = res.payload.response;
                      await this.notify("warning", "Atención", payload.message);
                    } else {
                      await this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                      this.setState({
                        loading2: false,
                        loading1: false
                      });
                    }
                  }
                });
                var currentMonth = moment().format("M");
                var year = moment().format("YYYY");
                var monthData = {
                  year: year,
                  month: currentMonth
                };
                this.props.findMonthWidget(monthData).then(async res => {
                  // this takes the hours for graph
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload }
                      } = res.payload.response;
                      await this.notify("warning", "Atención", payload.message);
                    } else {
                      await this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                      this.setState({
                        loading2: false,
                        loading1: false
                      });
                    }
                  }
                  this.setState({
                    loading2: false,
                    loading1: false,
                    monthHoursWidget: this.props.monthWidget
                  });
                });
              } else {
                var countries = teams.map(e =>
                  e.split(" ")[2] === undefined ? "REG" : e.split(" ")[2]
                );
                countries = countries.sort();
                this.setState({
                  countries: countries
                });
                var countryToFind = countries[0];
                let countriesOptionsTemp = [];
                countries.forEach(e =>
                  countriesOptionsTemp.push({ value: e, label: e })
                );
                this.setState({
                  countriesOptions: countriesOptionsTemp
                });
                const data = {
                  year: currentYear,
                  country: countryToFind
                };
                this.props.countryExtrasSumHoursGraph(data).then(async res => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload }
                      } = res.payload.response;
                      await this.notify("warning", "Atención", payload.message);
                    } else {
                      await this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                      this.setState({
                        loading2: false,
                        loading1: false
                      });
                    }
                  }
                  this.setState({
                    selection: countryToFind
                  });
                });
                currentMonth = moment().format("M");
                monthData = {
                  year: currentYear,
                  month: currentMonth,
                  country: countryToFind
                };
                this.props.findMonthWidgetCountry(monthData).then(async res => {
                  // this takes the hours for graph
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload }
                      } = res.payload.response;
                      await this.notify("warning", "Atención", payload.message);
                    } else {
                      await this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                      this.setState({
                        loading2: false,
                        loading1: false
                      });
                    }
                  }
                });
                this.props.countryExtrasSumHoursWidget(data).then(async res => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload }
                      } = res.payload.response;
                      await this.notify("warning", "Atención", payload.message);
                    } else {
                      await this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                      this.setState({
                        loading2: false,
                        loading1: false
                      });
                    }
                  }
                  this.setState({
                    selection: countryToFind
                  });
                });
                this.setState({
                  loading2: false,
                  loading1: false
                });
              }
            }
          });
        } else {
          // console.log("ehere");
          this.setState({
            loading2: false,
            loading1: false
          });
        }
      }
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleDaysOnMonth = date => {
    var end = moment(date, "YYYY-MM").daysInMonth();
    var start = 1;
    return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleSelectionChange = (who, value) => {
    var currentYear = "";
    let countryToFind = "";
    if (who === "country") {
      countryToFind = value.value;
      currentYear = this.state.yearSelected;
    }
    if (who === "year") {
      countryToFind = this.state.selection;
      currentYear = value.value;
    }
    if (countryToFind !== "Todos") {
      const data = {
        year: currentYear,
        country: countryToFind
      };
      this.props.countryExtrasSumHoursGraph(data).then(async res => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload }
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState({
          selection: countryToFind,
          yearSelected: currentYear
        });
      });
      var currentMonth = moment().format("M");
      var year = moment().format("YYYY");
      var monthData = {
        year: year,
        month: currentMonth,
        country: countryToFind
      };
      this.props.findMonthWidgetCountry(monthData).then(async res => {
        // this takes the hours for graph
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload }
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
      });
      this.props.countryExtrasSumHoursWidget(data).then(async res => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload }
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState({
          loading2: false,
          loading1: false
        });
        this.setState({
          selection: countryToFind,
          yearSelected: currentYear
        });
      });
    } else {
      this.setState({
        loading2: false,
        loading1: false
      });
      this.setState({
        selection: "Todos",
        yearSelected: currentYear
      });
      const data = {
        year: currentYear
      };
      this.props.allYearExtraSumHoursWidget(data).then(async res => {
        // this takes the hours for widget
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload }
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
      });

      this.props.allYearExtraSumHoursGraph(data).then(async res => {
        // this takes the hours for graph
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload }
            } = res.payload.response;
            await this.notify("warning", "Atención", payload.message);
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
      });
    }
  };

  render() {
    const {
      loading2,
      loading1,
      countriesOptions,
      yearsOptions,
      selection,
      yearSelected
    } = this.state;
    const {
      sumAllYearHoursWidget,
      sumAllYearHoursGraph,
      sumCountryYearHoursWidget,
      sumCountryYearHoursGraph,
      monthWidget,
      monthWidgetCountry
    } = this.props;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name="Dashboard horas extra"
          parentName="Horas extra"
          optionalInput={true}
          selectionChange={this.handleSelectionChange}
          countries={countriesOptions}
          countrySelected={selection}
          yearsStatus={yearsOptions}
          yearSelected={yearSelected}
        />

        {loading1 ? (
          <Progress animated max="100" value="100" color="info" />
        ) : (
          <Container className="mt--6" fluid>
            <WidgetsInfo
              hours={
                this.state.selection === "Todos"
                  ? sumAllYearHoursWidget
                  : sumCountryYearHoursWidget
              }
              subHoursMonth={
                this.state.selection === "Todos"
                  ? monthWidget
                  : monthWidgetCountry
              }
            />
            {loading2 ? (
              <Progress animated max="100" value="100" color="danger" />
            ) : (
              <div>
                <br></br>
              </div>
            )}
            <ChartStates
              data={
                this.state.selection === "Todos"
                  ? sumAllYearHoursGraph
                  : sumCountryYearHoursGraph
              }
            />
          </Container>
        )}
      </>
    );
  }
}

ExtraHoursDashboardStatus.defaultProps = {
  sumAllYearHoursWidget: {},
  sumAllYearHoursGraph: [],
  sumCountryYearHoursWidget: {},
  sumCountryYearHoursGraph: [],
  monthWidget: {},
  monthWidgetCountry: {},
  rolesExtra: [],
  yearsExtras: []
};

const mapStateToProps = state => ({
  sumAllYearHoursWidget: getSumAllYearHoursWidget(state),
  sumAllYearHoursGraph: getSumAllYearHoursGraph(state),
  sumCountryYearHoursWidget: getSumCountryYearHoursWidget(state),
  sumCountryYearHoursGraph: getSumCountryYearHoursGraph(state),
  monthWidget: getMonthWidgets(state),
  monthWidgetCountry: getMonthWidgetsCountry(state),
  rolesExtra: getUserRolesExtra(state),
  yearsExtras: getYearsExtras(state)
});

export default withRouter(
  connect(mapStateToProps, {
    allYearExtraSumHoursWidget,
    allYearExtraSumHoursGraph,
    countryExtrasSumHoursWidget,
    countryExtrasSumHoursGraph,
    findMonthWidget,
    findMonthWidgetCountry,
    userRolesExtra,
    findYearsExtras
  })(ExtraHoursDashboardStatus)
);
