// antd components
import { Spin } from "antd";
// core actions
import {
  findConflictInterestsPendingInfo,
  createConflictInterestsInfoByID,
  createSupplierConflictInterestsByID,
} from "actions/conflictInterests.jsx";
// core components shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// core components main
import ConflictInterestsForm from "components/ConflictInterests/ConflictInterestsForm.jsx";
import SupplierModal from "components/ConflictInterests/SupplierModal.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getConflictInterestsInfo } from "selectors/conflictInterests.jsx";

class FormConflictInterests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      loadingModal: false,
      message: null,
      modalSupplier: false,
    };
  }

  componentDidMount() {
    this.findConflictInterestsInfo();
  }

  findConflictInterestsInfo = () => {
    this.props.findConflictInterestsPendingInfo().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        // this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        willMount: false,
      });
    });
  };

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof MySignatures
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof MySignatures
   */
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnSignature = async (values) => {
    const { info } = this.props;
    this.setState({
      loading: true,
      message: "Completando la información del formulario",
    });
    await this.props
      .createConflictInterestsInfoByID(info.id, values)
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findConflictInterestsPendingInfo();
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  handleOnToggleModalSupplier = () => {
    this.setState({
      modalSupplier: false,
    });
  };

  handleOnAddSupplier = () => {
    this.setState({
      modalSupplier: true,
    });
  };

  handleOnCreateSupplier = async (values) => {
    console.log(values);
    const { info } = this.props;
    this.setState({
      loadingModal: true,
      message: "Creando la información del proveedor",
    });
    await this.props
      .createSupplierConflictInterestsByID(info.id, values)
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.findConflictInterestsInfo();
        }
        this.handleOnToggleModalSupplier();
        this.setState({
          loadingModal: false,
          message: null,
        });
      });
  };

  render() {
    const { alert, willMount, loading, message, modalSupplier, loadingModal } =
      this.state;

    const { name, parentName, info, cols } = this.props;

    console.log(info);

    return (
      <>
        {alert}
        <SupplierModal
          initialValues={{
            supplierName: "",
            name: "",
            relationType: "",
            position: "",
            supplierInfo: "",
            isSupplierContractingLink: 0,
            hasSupplierBenefit: 0,
            benefitDescription: "",
            comments: "",
          }}
          message={message}
          loading={loadingModal}
          showModal={modalSupplier}
          toggleModal={this.handleOnToggleModalSupplier}
          onWorkFlow={this.handleOnCreateSupplier}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la información pendiente por completar" />
                </Col>
              </Row>
            ) : Object.keys(info).length ? (
              <ConflictInterestsForm
                title="Conflicto de Intereses con Proveedores"
                subtitle="Completar Formulario Conflicto de Intereses con Proveedores"
                initialValues={info}
                cols={cols}
                onAddSupplier={this.handleOnAddSupplier}
                onSignature={this.handleOnSignature}
              />
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="Muchas gracias por completar la información requerida."
                    subtitle="Actualmente al día de hoy, no hay mas datos para completar, cuando hayan formularios se mostranran en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

FormConflictInterests.defaultProps = {
  name: "Conflicto de Intereses con Proveedores",
  parentName: "Políticas GBM",
  info: {},
  cols: [
    { key: "supplierName", name: "Nombre del Proveedor" },
    { key: "name", name: "Nombre de la persona" },
    { key: "relationType", name: "Tipo de Relación" },
    { key: "position", name: "Cargo de la persona" },
    { key: "supplierInfo", name: "Servicio que brinda" },
    {
      key: "isSupplierContractingLink",
      name: "¿Tiene participación en la contratación?",
    },
    {
      key: "hasSupplierBenefit",
      name: "¿Posee relación laboral o beneficio económico?",
    },
    {
      key: "benefitDescription",
      name: "Detalle la relación laboral o beneficio",
    },
    { key: "comments", name: "Comentarios" },
  ],
};

FormConflictInterests.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  info: getConflictInterestsInfo(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findConflictInterestsPendingInfo,
    createConflictInterestsInfoByID,
    createSupplierConflictInterestsByID,
  })(FormConflictInterests)
);
