import React, { useState, useEffect } from 'react';
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Filter from "components/FinanceFlows/Administration/Filters";
import Table from "components/FinanceFlows/Administration/Tables";
import Modal from "components/FinanceFlows/Administration/Modals";
import { Spin } from 'antd';
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getOptions, getAllDocument, insertDocument, updateDocument, deleteDocument } from "actions/financialFlows"
import ReactBSAlert from "react-bootstrap-sweetalert";


const Documents = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState("");
    const [filterByTable, setFiltersByTable] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoad] = useState(false);
    const [openModal, setModal] = useState({
        createModal: false,
        editModal: false
    });
    const [itemInfo, setItemInfo] = useState("");
    const [newInfo, setNewInfo] = useState({});
    const [infoToModule, setInfoToModule] = useState({
        rows: [],
        category: [],
        unitType: []
    });
    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetInfo();
        };
        getInfo();
    }, []);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    //Json que genera los filtros que se utilizaran en la tabla
    const filter = [
        {
            label: "Nombre de Documento",
            type: "input",
            xs: "4",
            value: "name"
        },
        {
            label: "Categoria",
            type: "select",
            xs: "4",
            value: "category",
            valueFilter: "categoryName"
        },
        {
            label: "Descripción",
            type: "input",
            xs: "4",
            value: "description",
            valueFilter: "description"
        }

    ]
    //Columnas de la tabla principal generica
    const colums = [
        {
            label: "Nombre",
            value: "name",
            type: "b"
        },
        {
            label: "Categoria",
            value: "categoryName",
            type: "a"
        },
        {
            label: "Descripcion",
            value: "description",
            type: "a"
        },
        {
            label: "Creado el",
            value: "createdAt",
            type: "date"
        }

    ]
    //Campos del modal generico para esta vista
    const modalInformation = [
        { label: "Categoria", value: "categoryName", valueInsert: "categoryId", type: "select", colWidth: "12", required: true, multi: false, options: infoToModule.category },
        { label: "Nombre", value: "name", type: "input", colWidth: "12", required: true },
        { label: "Descripciòn", value: "description", type: "textarea", colWidth: "12", required: true },
        { label: "Flujo Abierto", value: "conditional", valueInsert: "conditional", type: "select", colWidth: "12", required: true, multi: false, options: [{ label: "Sí", value: "1" }, { label: "No", value: "0" }] },
        { label: "Tipos de Datos", value: "documentField", valueInsert: "documentField", type: "select", colWidth: "12", required: true, multi: true, options: infoToModule.unitType },

    ]
    //Botones adicionales en la tabla principal
    const aditionalsButtons = [
        {
            color: "secondary",
            function: (e) => handleOnOpenModalEdit(e),
            icon: "fas fa-edit",
            className: "table-action table-action-edit"
        },
        {
            color: "secondary",
            function: (e) => handleOnDeleteConfirm(e),
            icon: "fas fa-trash",
            className: "table-action table-action-delete"
        }
    ]
    //Funcion para aplicar los filtros que se utilizaran
    const renderRowsByFilters = (rows) => {
        if (Object.keys(filterByTable).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filterByTable);
            for (const element of filterKeys) {
                const valueFiltered = filterByTable[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    //Funcion que trae la data de los campos selects
    const handleOnGetInfo = async () => {
        setLoad(true);
        dispatch(getOptions()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    area: payload.data.data.area,
                    businessUnit: payload.data.data.businessUnit,
                    category: payload.data.data.category,
                    profileType: payload.data.data.profileType,
                    state: payload.data.data.state,
                    typeActive: payload.data.data.typeActive,
                    typeUnitType: payload.data.data.typeUnitType,
                    unitType: payload.data.data.unitType,
                }));
            }
            setLoad(false);
        });
        handleOnGetTableInfo();
    };
    //Funcion que trae la data de la tabla principal
    const handleOnGetTableInfo = async () => {
        dispatch(getAllDocument()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    rows: payload.data.data[0]
                }));
            }
            setLoad(false);
        })
    }
    const handleOnEditItem = () => {
        setLoad(true);
        dispatch(updateDocument(itemInfo)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setModal(prevState => ({
                    ...prevState,
                    editModal: false
                }));
                Toast.fire({
                    title: "Actualizado",
                    html: "Se actualizo correctamente",
                    type: "success",
                });
            }
            setAlert("");
            handleOnGetTableInfo();
            setLoad(false);
        })
    }
    //Funcion para agregar un nuevo documento
    const handleOnCreateItem = () => {
        setLoad(true);
        dispatch(insertDocument(newInfo)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setModal(prevState => ({
                    ...prevState,
                    createModal: false
                }));
                Toast.fire({
                    title: "Insertando",
                    html: "Se Inserto correctamente",
                    type: "success",
                });
                setNewInfo({});
            }
            handleOnGetTableInfo();
            setLoad(false);
        })
    }
    //Funcion que confirma si se desea eliminar el role seleccionado.
    const handleOnDeleteConfirm = (row) => {
        const { id } = row;
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={`¿Desea eliminar el documento seleccionado?`}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleOnDelete(id)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, eliminar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }
    //Funcion que elimina el role sellecionado.
    const handleOnDelete = (id) => {
        setLoad(true);
        const body = {
            documentId: id
        }
        dispatch(deleteDocument(body)).then((response) => {
            const { payload } = response;
            if (payload.status === 200) {
                setAlert("");
                handleOnGetTableInfo();
                setLoad(false);
            }
        });
    }
    //Funcion para guardar los filtros de la tabla principal
    const handleOnfilterDinamic = (constant, e) => {
        setPage(1);
        constant === "categoryName" && e === "Todos" ?
            setFiltersByTable("")
            :
            setFiltersByTable(prevState => ({
                ...prevState,
                [constant]: `${e}`
            }))
    }
    //Funcion para abrir el modal de crear nueva registro en la tabla principal
    const handleOnOpenModalCreate = () => {

        setModal(prevState => ({
            ...prevState,
            createModal: !openModal.createModal
        }))
    }
    //Funcion para abrir el modal de crear nueva registro en la tabla principal
    const handleOnOpenModalEdit = (item) => {
        setModal(prevState => ({
            ...prevState,
            editModal: !openModal.editModal
        }))
        setItemInfo(item)
    }
    //Funcion para hacer los cambios de informacion en los campos de input, select .etc
    const handleOnChangeInfo = (constant, value) => {
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };
    //Funcion para cambiar la informacion que vamos a editar
    const handleOnChangeEditInfo = (constant, value) => {

        setItemInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };
    //Mensaje de doble confirmacion para enviar la edicion de un elemento
    const validateSend = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea editar el documento?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={handleOnEditItem}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, guardar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }
    let disabled = !newInfo.categoryId || !newInfo.conditional || !newInfo.documentField || !newInfo.description || !newInfo.name ? true : false;
    let disabledEdit = itemInfo.categoryId === "" || itemInfo.conditional === "" || itemInfo.documentField === "" || itemInfo.description === "" || itemInfo.name === "" ? true : false;

    return (
        <div>
            {alert}
            <AdminHeader name="Documetos" parentName="Finance Flows" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    <Filter
                        jsonFilters={filter}
                        filters={handleOnfilterDinamic}
                        optionsFilters={infoToModule}

                    />
                    <Table
                        text={"Resultados"}
                        createButton={true}
                        textCreateButton={"Nuevo Documento"}
                        colums={colums}
                        rows={renderRowsByFilters(infoToModule.rows)}
                        page={page}
                        setPage={setPage}
                        handleOnNewElement={handleOnOpenModalCreate}
                        aditionalsButtons={aditionalsButtons}
                    />
                    <Modal
                        handleOnChangeInfo={handleOnChangeInfo}
                        text={"Crear nuevo documento"}
                        openModal={openModal.createModal}
                        handleOnOpenModal={handleOnOpenModalCreate}
                        itemModal={modalInformation}
                        itemInfo={itemInfo}
                        nameButton1={"Cerrar"}
                        nameButton2={"Guardar"}
                        saveButton={handleOnCreateItem}
                        extramodal=""
                        size="sm"
                        saveButtonDisabled={disabled}
                    />
                    <Modal
                        handleOnChangeInfo={handleOnChangeEditInfo}
                        text={"Editar documento"}
                        openModal={openModal.editModal}
                        handleOnOpenModal={handleOnOpenModalEdit}
                        itemModal={modalInformation}
                        itemInfo={itemInfo}
                        nameButton1={"Cerrar"}
                        nameButton2={"Guardar"}
                        saveButton={validateSend}
                        extramodal=""
                        size="sm"
                        saveButtonDisabled={disabledEdit}

                    />
                </Spin>
            </Container>
        </div>
    );
};


export default Documents;