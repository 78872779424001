import * as jwt from 'jsonwebtoken';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as constants from "constants/index.jsx";

const MySwal = withReactContent(Swal);

export const verifyToken = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }
  try {
    const { exp } = jwt.decode(token);
    if (Date.now() >= exp * 1000) {
      const Toast = MySwal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000
      });
      Toast.fire({
        type: 'warning',
        title: `¡La sesión ha expirado!`,
      });
      localStorage.clear();
      return false;
    } else {
      return true;
    }
  } catch (err) {
    const Toast = MySwal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000
    });
    Toast.fire({
      type: 'warning',
      title: `¡La sesión ha expirado!`,
    });
    localStorage.clear();
    return false;
  }
}
