// core actions
import { createMasterVariablesByMasterTable, deactivatedMasterVariablesByMasterTable, findMasterVariablesByMasterTable, updateMasterVariablesByMasterTable } from "actions/Sales/support.jsx";
// core antd
import { Spin } from "antd";
// core components Support
import AddVariableModal from 'components/Sales/Support/AddVariableModal.jsx';
import EditVariableModal from 'components/Sales/Support/EditVariableModal.jsx';
import OptionsAvaible from 'components/Sales/Support/OptionsAvaible.jsx';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getMasterVariablesByMasterTable } from "selectors/Sales/support.jsx";

class MasterVariables extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: false,
      message: null,
      loadingModal: false,
      messageModal: null,
      masterTable: {
        value: 0,
        label: "Seleccione la tabla maestra a gestionar"
      },
      showModalEdit: {
        type: null,
        showModal: false,
        options: []
      },
      showModalAdd: {
        type: null,
        showModal: false,
        options: []
      },
      eventSelected: { type: null, id: null }
    }
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  findMasterVariablesByMasterTable = () => {
    this.setState({
      loading: true,
      message: "Cargando los datos para la opción selecccionada"
    });
    this.props.findMasterVariablesByMasterTable().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  handleOnChangeOptionsValue = event => {
    this.setState({
      masterTable: event
    });
  };

  handleOnSearchMasterVariablesByTable = () => {
    this.findMasterVariablesByMasterTable();
  };

  onDeleteMasterVariableByMasterTable = (row, type) => {
    this.setState({
      loading: true,
      message: 'Eliminando la variable seleccionada',
      alert: null
    });
    this.props.deactivatedMasterVariablesByMasterTable(row.id, type).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState({
          loading: false,
          message: null
        });
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.findMasterVariablesByMasterTable();
      }
    });
  };

  handleOnDeleteMasterVariableByTable = (row, type) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro(a) de eliminar la variable?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={input => this.onDeleteMasterVariableByMasterTable(row, type)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      )
    });
  }

  handleOnEditMasterVariableByTable = (row, type) => {
    if (type === 'practices') {
      this.setState({
        showModalEdit: {
          type,
          showModal: true,
          options: [
            {
              label: 'Descripción',
              type: 'text',
              key: 'description',
              visible: true,
              required: true,
              options: []
            },
            {
              label: 'Práctica',
              type: 'text',
              key: 'practices',
              visible: true,
              required: true,
              options: []
            },
            {
              label: 'Preventivos',
              type: 'number',
              key: 'prevents',
              visible: true,
              required: true,
              min: 1,
              options: []
            },
            {
              label: 'Horas',
              type: 'number',
              key: 'hours',
              visible: true,
              required: true,
              max: 24,
              min: 1,
              options: []
            },
          ],
          initialValues: row
        }
      });
    } else if (type === 'costs') {
      this.setState({
        showModalEdit: {
          type,
          showModal: true,
          options: [
            {
              label: 'País',
              type: 'select',
              key: 'country',
              visible: true,
              required: true,
              options: [
                { key: "0", name: 'Seleccione una opción' },
                { key: "CR", name: 'Costa Rica' },
                { key: "GT", name: 'Guatemala' },
                { key: "SV", name: 'El Salvador' },
                { key: "HN", name: 'Honduras' },
                { key: "NI", name: 'Nicaragua' },
                { key: "PA", name: 'Panamá' },
                { key: "DR", name: 'República Dominicana' },
              ]
            },
            {
              label: 'Costo $',
              type: 'number',
              key: 'cost',
              visible: true,
              required: true,
              min: 1,
              options: []
            },
          ],
          initialValues: row
        }
      });
    } else if (type === 'platforms') {
      this.setState({
        showModalEdit: {
          type,
          showModal: true,
          options: [
            {
              label: 'Plataforma',
              type: 'text',
              key: 'platforms',
              visible: true,
              required: true,
              options: []
            },
            {
              label: 'Preventivos',
              type: 'number',
              key: 'prevents',
              visible: true,
              required: true,
              min: 1,
              options: []
            },
            {
              label: 'Horas',
              type: 'number',
              key: 'hours',
              visible: true,
              required: true,
              max: 24,
              min: 1,
              options: []
            },
          ],
          initialValues: row
        }
      });
    } else if (type === 'operatingSystems' || type === 'activities') {
      this.setState({
        showModalEdit: {
          type,
          showModal: true,
          options: [
            {
              label: 'Nombre',
              type: 'text',
              key: 'name',
              visible: true,
              required: true,
              options: []
            }
          ],
          initialValues: row
        }
      });
    }
  };

  handleOnToggleEditModal = () => {
    this.setState({
      showModalEdit: {
        type: null,
        showModal: false,
        options: []
      }
    });
  };

  handleOnToggleAddModal = () => {
    this.setState({
      showModalAdd: {
        type: null,
        showModal: false,
        options: []
      }
    });
  };

  handleOnEditFlowMasterVariable = (values, type) => {
    this.setState({
      loadingModal: true,
      messageModal: 'Actualizando la variable maestra seleccionada'
    });
    this.props.updateMasterVariablesByMasterTable({
      values,
      type
    }).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.findMasterVariablesByMasterTable();
        this.handleOnToggleEditModal();
      }
      this.setState({
        loadingModal: false,
        messageModal: null
      });
    });
  };

  handleOnAddMasterVariableByTable = (type, eventSelected) => {
    if (type === 'practices') {
      this.setState({
        showModalAdd: {
          type,
          showModal: true,
          options: [
            {
              label: 'Descripción',
              type: 'text',
              key: 'description',
              visible: true,
              required: true,
              options: []
            },
            {
              label: 'Práctica',
              type: 'text',
              key: 'practices',
              visible: true,
              required: true,
              options: []
            },
            {
              label: 'Preventivos',
              type: 'number',
              key: 'prevents',
              visible: true,
              required: true,
              min: 1,
              options: []
            },
            {
              label: 'Horas',
              type: 'number',
              key: 'hours',
              visible: true,
              required: true,
              max: 24,
              min: 1,
              options: []
            },
          ],
        }
      });
    } else if (type === 'costs') {
      this.setState({
        eventSelected,
        showModalAdd: {
          type,
          showModal: true,
          options: [
            {
              label: 'País',
              type: 'select',
              key: 'country',
              visible: true,
              required: true,
              options: [
                { key: "0", name: 'Seleccione una opción' },
                { key: "CR", name: 'Costa Rica' },
                { key: "GT", name: 'Guatemala' },
                { key: "SV", name: 'El Salvador' },
                { key: "HN", name: 'Honduras' },
                { key: "NI", name: 'Nicaragua' },
                { key: "PA", name: 'Panamá' },
                { key: "DR", name: 'República Dominicana' },
              ]
            },
            {
              label: 'Costo $',
              type: 'number',
              key: 'cost',
              visible: true,
              required: true,
              min: 1,
              options: []
            },
          ],
        }
      });
    } else if (type === 'platforms') {
      this.setState({
        eventSelected,
        showModalAdd: {
          type,
          showModal: true,
          options: [
            {
              label: 'Plataforma',
              type: 'text',
              key: 'platforms',
              visible: true,
              required: true,
              options: []
            },
            {
              label: 'Preventivos',
              type: 'number',
              key: 'prevents',
              visible: true,
              required: true,
              min: 1,
              options: []
            },
            {
              label: 'Horas',
              type: 'number',
              key: 'hours',
              visible: true,
              required: true,
              max: 24,
              min: 1,
              options: []
            },
          ],
        }
      });
    } else if (type === 'operatingSystems' || type === 'activities') {
      this.setState({
        eventSelected,
        showModalAdd: {
          type,
          showModal: true,
          options: [
            {
              label: 'Nombre',
              type: 'text',
              key: 'name',
              visible: true,
              required: true,
              options: []
            }
          ],
        }
      });
    }
  };

  handleOnAddFlowMasterVariable = (values, type) => {
    const { eventSelected: { id } } = this.state;
    values.fkID = id;
    this.setState({
      loadingModal: true,
      messageModal: 'Creando la variable maestra seleccionada'
    });
    this.props.createMasterVariablesByMasterTable({
      values,
      type
    }).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.findMasterVariablesByMasterTable();
        this.handleOnToggleAddModal();
      }
      this.setState({
        loadingModal: false,
        messageModal: null
      });
    });
  };

  render() {

    const {
      name,
      parentName,
      colsAvaibles,
      masterVariables,
    } = this.props;

    const {
      alert,
      loading,
      message,
      loadingModal,
      messageModal,
      masterTable,
      showModalAdd,
      showModalEdit,
    } = this.state;

    return (
      <>
        {alert}
        <AddVariableModal
          loading={loadingModal}
          message={messageModal}
          modalOptions={showModalAdd}
          toggleModal={this.handleOnToggleAddModal}
          onAddFlow={this.handleOnAddFlowMasterVariable}
        />
        <EditVariableModal
          loading={loadingModal}
          message={messageModal}
          modalOptions={showModalEdit}
          toggleModal={this.handleOnToggleEditModal}
          onEditFlow={this.handleOnEditFlowMasterVariable}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message} size="large">
            <Row className="justify-content-center">
              <Col>
                <OptionsAvaible
                  title="Mantenimiento"
                  subtitle="Gestión de datos para las tablas maestras"
                  optionSelected={masterTable}
                  options={masterVariables}
                  colsAvaibles={colsAvaibles}
                  onChange={this.handleOnChangeOptionsValue}
                  onSearch={this.handleOnSearchMasterVariablesByTable}
                  onEdit={this.handleOnEditMasterVariableByTable}
                  onAddVariable={this.handleOnAddMasterVariableByTable}
                  onDelete={this.handleOnDeleteMasterVariableByTable}
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

MasterVariables.defaultProps = {
  name: "Variables Maestras",
  parentName: "Mantenimiento",
  masterVariables: {},
  colsAvaibles: {
    practices: [
      { name: "#REF", key: "id" },
      { name: "Descripción", key: "description" },
      { name: "Práctica", key: "practices" },
      { name: "Preventivos", key: "prevents" },
      { name: "Horas", key: "hours" },
      { name: "Ver Costos", key: "costs", btn: true },
      { name: "Ver Plataformas", key: "platforms", btn: true },
    ],
    costs: [
      { name: "#REF", key: "id" },
      { key: "practices", name: "Práctica" },
      { key: "country", name: "País" },
      { key: "cost", name: "Costo $" },
    ],
    platforms: [
      { name: "#REF", key: "id" },
      { key: "practices", name: "Práctica" },
      { key: "platforms", name: "Plataforma" },
      { key: "prevents", name: "Preventivos" },
      { key: "hours", name: "Horas" },
      { name: "Ver Actividades", key: "activities", btn: true },
    ],
    activities: [
      { name: "#REF", key: "id" },
      { key: "platforms", name: "Plataforma" },
      { key: "name", name: "Actividad" },
    ],
    operatingSystems: [
      { name: "#REF", key: "id" },
      { key: "name", name: "Sistema Operativo" },
    ]
  }
};

MasterVariables.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  masterVariables: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  masterVariables: getMasterVariablesByMasterTable(state),
});

export default withRouter(connect(mapStateToProps, {
  createMasterVariablesByMasterTable,
  deactivatedMasterVariablesByMasterTable,
  findMasterVariablesByMasterTable,
  updateMasterVariablesByMasterTable,
})(MasterVariables));
