import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CategoryManagement from 'components/ExitInterview/CategoryManagement.jsx';
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { Spin } from 'antd';
import { getMasterData } from 'actions/exitInterview';

const CategoryManagment = () => {

    const dispatch = useDispatch();

    const [loading, setLoad] = useState(true);
    const [options, setOptions] = useState({
        exitType: [{ label: "", value: "" }],
        exitReasonDisabled: [{ label: "", value: "" }],
        exitReason: [{ label: "", value: "" }],
        exitTypeDisabled: [{ label: "", value: "" }]
    });
    const [enabled, setEnabled] = useState({});

    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
        }
        getRowsData();
    }, [])
    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
        }
        getRowsData();
    }, [enabled])
    //Funcion para traer la informacion de lo selects y el nombre de las columnas de la tabla
    const handleRowInfo = async () => {
        setLoad(true);
        dispatch(getMasterData()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    exitType: payload.data.payload.masterData.exitType,
                    exitReasonDisabled: payload.data.payload.masterData.exitReasonDisabled,
                    exitReason: payload.data.payload.masterData.exitReason,
                    exitTypeDisabled: payload.data.payload.masterData.exitTypeDisabled,
                }))
            }
            setLoad(false);
        })
    }
    return (
        <div>
            <AdminHeader name="Entrevistas de Salida" parentName="Administracion de Categorias" />
            <Spin size="large" spinning={loading}>
                <Container className="mt--6" fluid>
                    <CategoryManagement
                        options={options}
                        setEnabled={setEnabled}
                        setOptions={setOptions}
                    />
                </Container>
            </Spin>
        </div>
    );
};

export default CategoryManagment;