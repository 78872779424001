// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Button, Card, CardFooter, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";

const Requests = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    page,
    sizePerPage,
    onOpen,
    onSetPage
  } = props;

  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => onSetPage(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="12" md='7'>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {
              cols.map((row, key) => (<th key={key}>{row.name}</th>))
            }
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
              <tr key={key} onClick={() => onOpen(row)}>
                {
                  cols.map((col, key) => (
                    (col.key === "createdAt" || col.key === "updatedAt") ?
                      <th key={key}>
                        {
                          moment(row[col.key])
                            .utc()
                            .utcOffset(moment().utcOffset())
                            .format("DD/MM/YYYY H:mm A")
                        }
                      </th>
                      :
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                        {
                          col.key === "version" ?
                            <Badge color="info">
                              {`v${row[col.key]}`}
                            </Badge>
                            :
                            col.key === "status" ?
                              <Badge color="" className="badge-dot mr-4">
                                <i className={row.color} />
                                {row[col.key]}
                              </Badge>
                              :
                              <>
                                {row[col.key]}
                              </>
                        }
                      </th>
                  ))
                }
                <th style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  <Button
                    className="btn-icon btn-3"
                    size="sm"
                    color="info"
                    type="button"
                    onClick={() => onOpen(row)}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-folder-open" />
                    </span>
                    <span className="btn-inner--text">Abrir</span>
                  </Button>
                </th>
              </tr>
            ))
          }
        </tbody>
      </Table>
      <CardFooter className="py-4">
        <nav aria-label="...">
          <Row className="align-items-center">
            <Col xs='12' md='6' className="p-1">
              <span className='pagination mb-0'>
                Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                    </span>
            </Col>
            <Col xs='12' md='6' className="p-1">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={page === 1 ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => onSetPage(page === 1 ? page : page - 1)}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {
                  renderPaginations()
                }
                <PaginationItem className={page === paginations ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => onSetPage(page === paginations ? page : page + 1)}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        </nav>
      </CardFooter>
    </Card>
  );
};

Requests.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default Requests;
