// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
// reactstrap components
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const TransactionsByZone = props => {

  const {
    title,
    subtitle,
    markers,
    mapData
  } = props;

  return (
    <Card className="widget-calendar">
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="8">
            <h5 className="text-uppercase surtitle">{title}</h5>
            <h5 className=" h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-0">
        <VectorMap
          containerClassName="vector-map"
          containerStyle={{
            width: "100%",
            height: "600px"
          }}
          map={"world_mill"}
          zoomOnScroll={false}
          scaleColors={["#f00", "#0071A4"]}
          normalizeFunction="polynomial"
          hoverOpacity={0.7}
          hoverColor={false}
          backgroundColor="transparent"
          regionStyle={{
            initial: {
              fill: "#e9ecef",
              "fill-opacity": 0.8,
              stroke: "none",
              "stroke-width": 0,
              "stroke-opacity": 1
            },
            hover: {
              fill: "#dee2e6",
              "fill-opacity": 0.8,
              cursor: "pointer"
            },
            selected: {
              fill: "yellow"
            },
            selectedHover: {}
          }}
          markerStyle={{
            initial: {
              fill: "#fb6340",
              "stroke-width": 0
            },
            hover: {
              fill: "#11cdef",
              "stroke-width": 0
            }
          }}
          markers={markers}
          series={{
            regions: [
              {
                values: mapData,
                scale: ["#ced4da", "#adb5bd"],
                normalizeFunction: "polynomial"
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
};

TransactionsByZone.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TransactionsByZone;