/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Badge,
  Input,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const Contracts = (props) => {
  const {
    edit,
    title,
    subtitle,
    cols,
    rows,
    options: { states, inProgressFlag },
    page,
    sizePerPage,
    onOpen,
    onEdit,
    onExport,
    onSetPage,
    newActivity,
    onChangeView,
    onAssignEmployee,
  } = props;

  const paginations =
    rows.length > sizePerPage
      ? rows.length / sizePerPage > Math.round(rows.length / sizePerPage)
        ? Math.round(rows.length / sizePerPage) + 1
        : Math.round(rows.length / sizePerPage)
      : 1;

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink onClick={() => onSetPage(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return options.length >= 5
      ? page >= 5
        ? options.slice(page - 3, page + 2)
        : options.slice(0, 5)
      : options;
  };

  const numberWithCommas = (x) => {
    if (x >= 0)
      return `$${parseFloat(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    else return null;
  };

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="12" md="7">
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            <Col
              xs="12"
              md="5"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Row>
                <Col>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      className="custom-control-input"
                      id="viewOnHold"
                      type="radio"
                      checked={!inProgressFlag}
                      onChange={onChangeView}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="viewOnHold"
                    >
                      On Hold
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      className="custom-control-input"
                      id="viewInProgress"
                      type="radio"
                      checked={inProgressFlag}
                      onChange={onChangeView}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="viewInProgress"
                    >
                      In Progress
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Col>
                  <Button
                    size="sm"
                    className="btn-icon"
                    color="success"
                    type="button"
                    onClick={onExport}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-download" />
                    </span>
                    <span className="btn-inner--text">Exportar</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th />
              {!inProgressFlag ? <th /> : null}
              {cols.map((row, key) => (
                <th
                  key={key}
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  {row.name}
                </th>
              ))}
              {!inProgressFlag && <th />}
            </tr>
          </thead>
          <tbody className="list">
            {rows
              .slice((page - 1) * sizePerPage, page * sizePerPage)
              .map((row, key) => (
                <tr key={key}>
                  <th style={{ whiteSpace: "normal", textAlign: "justify" }}>
                    <Button
                      className="btn-icon btn-3"
                      size="sm"
                      color="info"
                      type="button"
                      onClick={() => onOpen(row)}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-folder-open" />
                      </span>
                      <span className="btn-inner--text">Abrir</span>
                    </Button>
                  </th>
                  {!inProgressFlag ? (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <a
                        className="table-action table-action"
                        href="#"
                        onClick={() => newActivity(row)}
                      >
                        <i className="ni ni-single-copy-04" />
                      </a>
                    </th>
                  ) : null}
                  {cols.map((col, key) =>
                    col.editable ? (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        <div>
                          <Input
                            style={{ width: "250px" }}
                            id={col.key}
                            type={col.type}
                            value={
                              edit.id === row.id && edit.idStatus
                                ? edit.idStatus
                                : row[col.key]
                            }
                            disabled={
                              !(edit.flag === true && edit.id === row.id)
                            }
                          >
                            <option key="0" value="0">
                              Seleccione una opción
                            </option>
                            {states.map((row, key) => {
                              return (
                                <option key={key} value={row.id}>
                                  {row.name}
                                </option>
                              );
                            })}
                          </Input>
                        </div>
                      </th>
                    ) : col.type === "array" ? (
                      col.key === "items" ? (
                        <th
                          key={key}
                          style={{ whiteSpace: "normal", textAlign: "center" }}
                        >
                          {row.items.slice(0, 4).map((row) => (
                            <Badge color="info">{row.name}</Badge>
                          ))}
                        </th>
                      ) : (
                        <th
                          key={key}
                          style={{ whiteSpace: "normal", textAlign: "center" }}
                        >
                          {row.services.length ? (
                            row.services
                              .slice(0, 4)
                              .map((row) => (
                                <Badge color="success">
                                  {row.serviceGroup}
                                </Badge>
                              ))
                          ) : (
                            <Badge color="success">N/A</Badge>
                          )}
                        </th>
                      )
                    ) : col.type === "date" ? (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        {row[col.key]
                          ? moment(row[col.key])
                              .utc()
                              .utcOffset(moment().utcOffset())
                              .format("DD/MM/YYYY")
                          : "N/A"}
                      </th>
                    ) : col.type === "money" ? (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "right" }}
                      >
                        {numberWithCommas(row[col.key])}
                      </th>
                    ) : col.type === "checkbox" ? (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        {row[col.key] === 1 ? "Sí" : "No"}
                      </th>
                    ) : col.type === "button" ? (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="info"
                          type="button"
                          onClick={() => onAssignEmployee(row)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-single-02" />
                          </span>
                          <span className="btn-inner--text">FM / PM</span>
                        </Button>
                      </th>
                    ) : (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "center" }}
                      >
                        {row[col.key] ? row[col.key] : "N/A"}
                      </th>
                    )
                  )}
                  {!inProgressFlag ? (
                    <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                      <a
                        className="table-action table-action"
                        href="#"
                        onClick={() => onEdit(row)}
                      >
                        <i className="fas fa-edit" />
                      </a>
                    </th>
                  ) : null}
                </tr>
              ))}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="p-1">
                <span className="pagination mb-0">
                  Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                  {page * sizePerPage > rows.length
                    ? rows.length
                    : page * sizePerPage}{" "}
                  de {rows.length} resultados
                </span>
              </Col>
              <Col xs="12" md="6" className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => onSetPage(page === 1 ? page : page - 1)}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {renderPaginations()}
                  <PaginationItem
                    className={page === paginations ? "disabled" : ""}
                  >
                    <PaginationLink
                      onClick={() =>
                        onSetPage(page === paginations ? page : page + 1)
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>
    </>
  );
};

Contracts.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Contracts;
