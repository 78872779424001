import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as method from "api/index.jsx";

//Methods to connect with the API and create,delete,update and get data for the Hours Report Section in S&S

export const getCatsTasks = createAction(
  "GET_CATS_TYPES",
  method.get(urls.HOURSREPORT.getCatsTypes)
);
export const createCats = createAction("CREATE_CATS", (body) =>
  method.post(urls.HOURSREPORT.createCats, body)()
);
export const getCats = createAction("GET_CATS", (body) =>
  method.post(urls.HOURSREPORT.getCats, body)()
);
export const getOSNetworks = createAction("GET_OS_NETWORKS", (body) =>
  method.post(urls.HOURSREPORT.getOSNetworks, body)()
);
export const deleteOSNetworks = createAction("DELETE_OS_NETWORKS", (body) =>
  method.post(urls.HOURSREPORT.deleteOSNetworks, body)()
);
export const getOS = createAction("GET_OS", (body) =>
  method.post(urls.HOURSREPORT.getOS, body)()
);
export const createOSNetworks = createAction("CREATE_OS_NETWORKS", (body) =>
  method.post(urls.HOURSREPORT.createOSNetworks, body)()
);
export const checkOSNetworks = createAction("CHECK_OS_NETWORKS", (body) =>
  method.post(urls.HOURSREPORT.checkOSNetworks, body)()
);

export const getAllNoMrsReports = createAction(
  "GET_ALL_NO_MRS_REPORTS",
  (body) => method.post(urls.HOURSREPORT.getAllNoMrsReports, body)()
);
export const getExtraHours = createAction("GET_EXTRA_HOURS", (body) =>
  method.post(urls.HOURSREPORT.getExtraHours, body)()
);
export const updateVisibilityExtraHours = createAction(
  "UPDATE_EXTRA_VISIBILITY",
  (body) => method.put(urls.HOURSREPORT.updateVisibilityExtraHours, body)()
);
export const createExtraHours = createAction("CREATE_EXTRA_HOURS", (body) =>
  method.post(urls.HOURSREPORT.createExtraHours, body)()
);
export const getJustifications = createAction(
  "GET_EXTRA_JUSTIFICATIONS",
  method.get(urls.HOURSREPORT.getJustifications)
);
export const getSchedule = createAction("GET_SCHEDULE", (body) =>
  method.post(urls.HOURSREPORT.getSchedule, body)()
);
export const verifyApprover = createAction("VERIFY_APPROVER", (body) =>
  method.post(urls.HOURSREPORT.verifyApprover, body)()
);
export const validateTimeExtra = createAction("VALIDATE_TIME_EXTRA", (body) =>
  method.post(urls.HOURSREPORT.validateTimeExtra, body)()
);
export const getRequestPendingForApproval = createAction(
  "GET_REQUEST_PENDING_FOR_APPROVAL",
  method.get(urls.HOURSREPORT.getRequestPendingForApproval)
);

export const setExtraPreApprover = createAction(
  "SET_EXTRA_PREAPPROVER",
  (body) => method.put(urls.HOURSREPORT.setExtraPreApprover, body)()
);

export const setExtraCEO = createAction("SET_EXTRA_CEO", (body) =>
  method.put(urls.HOURSREPORT.setExtraCEO, body)()
);

export const getPreapprovers = createAction(
  "GET_PREAPPROVERS",
  method.get(urls.HOURSREPORT.getPreapprovers)
);
