import React from "react";
// react library for routing
import { Route,Redirect, Switch } from "react-router-dom";
// routes
import { UserCloudRequestRoutes } from "routes/routes.jsx";

const Index = () => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/cloud") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  return (
      <>
      <Switch>
        {
          getRoutes(UserCloudRequestRoutes)
        }
        <Redirect from="/admin/cloud" to="/admin/cloud/requests" />
      </Switch>
      <p className="lead"></p>
    </>
  );
};

export default Index;
