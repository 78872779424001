/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Table } from "reactstrap";

const OptionsAvaible = props => {

  const {
    title,
    subtitle,
    optionSelected,
    options,
    colsAvaibles,
    onEdit,
    onChange,
    onSearch,
    onDelete,
    onAddVariable,
  } = props;

  const [eventSelected, setEventSelectd] = useState({ type: null, id: null });

  const renderTablesWithMasterOptions = (cols, rows, type) => (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md='7'>
            <h3 className="text-white mb-0">Tabla Maestra</h3>
          </Col>
          <Col className="text-right" xs="12" md='5'>
            <Row>
              {
                eventSelected.type &&
                <Col xs='12' md="6" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="btn-round btn-icon"
                    // outline
                    color="danger"
                    id="add"
                    onClick={() => setEventSelectd({ type: null, id: null })}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-arrow-left" />
                    </span>
                    <span className="btn-inner--text">Regresar</span>
                  </Button>
                </Col>
              }
              <Col xs='12' md={eventSelected.type ? "6" : "12"} className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className="btn-round btn-icon"
                  // outline
                  color="info"
                  id="add"
                  onClick={() => onAddVariable(type, eventSelected)}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-folder-plus" />
                  </span>
                  <span className="btn-inner--text">Agregar Nuevo</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Table
        className="align-items-center table-dark table-flush"
        responsive
      >
        <thead className="thead-dark">
          <tr>
            {
              cols.map((row, key) => <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>{row.name}</th>)
            }
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.map((row, key) => (
              <tr key={key}>
                {
                  cols.map((col, key) => (
                    col.btn ?
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                        <Button
                          size="sm"
                          color="info"
                          type="button"
                          onClick={() => setEventSelectd({ type: col.key, id: row.id })}
                          className="btn-icon btn-2"
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-info" />
                          </span>
                        </Button>
                      </th>
                      :
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {row[col.key]}
                      </th>
                  ))
                }
                <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                  <a
                    className="table-action table-action"
                    href="#"
                    onClick={() => onEdit(row, type)}
                  >
                    <i className="fas fa-edit" />
                  </a>
                  <a
                    className="table-action table-action"
                    href="#"
                    onClick={() => onDelete(row, type)}
                  >
                    <i className="fas fa-trash" />
                  </a>
                </th>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Card >
  );

  return (
    <Card>
      <CardHeader>
        <h5 className="surtitle">{title}</h5>
        <h5 className="h3 mb-0">{subtitle}</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="text-right" xs="12" md="8">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="masterTable"
              >
                Tablas Maestras Disponibles
              </label>
              <Select
                isSearchable
                className="basic-single"
                id="masterTable"
                type="select"
                placeholder="Seleccione la tabla maestra a gestionar"
                options={[
                  { value: 1, key: 'practices', label: "Prácticas y Plataformas", type: 'custom' },
                  { value: 2, key: 'operatingSystems', label: "Sistemas Operativos", type: 'generic' },
                ]}
                value={optionSelected}
                onChange={(e) => onChange(e)}
              />
            </FormGroup>
          </Col>
          <Col className="text-right d-flex justify-content-center align-items-center" xs="12" md="4">
            <Button
              block
              className="btn-icon"
              color="success"
              size="md"
              type="button"
              onClick={onSearch}
            >
              <span className="btn-inner--icon mr-">
                <i className="fas fa-search" />
              </span>
              <span className="btn-inner--text">
                Buscar
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              optionSelected.value > 0 && Object.keys(options).length ?
                eventSelected.type ?
                  renderTablesWithMasterOptions(colsAvaibles[eventSelected.type], options[eventSelected.type].filter((row) => row.fkID === eventSelected.id), eventSelected.type)
                  :
                  renderTablesWithMasterOptions(colsAvaibles[optionSelected.key], options[optionSelected.key], optionSelected.key)
                : null
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

OptionsAvaible.propTypes = {
  title: PropTypes.string.isRequired,
};

export default OptionsAvaible;
