// javascript plugin
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Modal, Row } from "reactstrap";

const WorkFlow = props => {

  const {
    title,
    showModal,
    toggleModal,
    loading,
    message,
    values,
    options,
    onWorkFlow
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.action || values.action === "0")
      errors.action = "Debes seleccionar la acción de aprobación.";
    if (!values.comments && (values.action === "2" || values.action === "3"))
      errors.comments = "Debes ingresar un comentario para el colaborador.";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={showModal}
      toggle={() => toggleModal("flowSignature")}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent p-2">
            <div className="text-muted text-center mt-2 mb-2">
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{values ? values.nameUser : ''}</h5>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-3">
            <Form
              onSubmit={onWorkFlow}
              validate={validate}
              initialValues={values}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <Row>
                      <Col xs="12" md={values.frequency === 1 ? "4" : "6"}>
                        <FormGroup className="mb-1">
                          <Label className="form-control-label" for="frequency">
                            Frecuencia del Home Office
                          </Label>
                          <Field name="frequency">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  disabled
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">Seleccione una opción</option>
                                  {
                                    options.frequencies.map((row, key) => (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    ))
                                  }
                                </Input>
                                {
                                  meta.error && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      {
                        values.frequency === 1 &&
                        <Col xs="12" md="4">
                          <FormGroup className="mb-1">
                            <Label className="form-control-label" for="totalDays">
                              Cantidad de días de Home Office
                            </Label>
                            <Field name="totalDays">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="number"
                                    disabled
                                    max={5}
                                    min={1}
                                    invalid={meta.error && meta.touched}
                                  />
                                  {
                                    meta.error && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      }
                      <Col xs="12" md={values.frequency === 1 ? "4" : "6"}>
                        <FormGroup className="mb-1">
                          <Label className="form-control-label" for="model">
                            Modelo de Home Office
                          </Label>
                          <Field name="model">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  disabled
                                  invalid={meta.error && meta.touched}
                                  value={values.frequency > 1 ? 6 : values.model}
                                >
                                  <option key="0" value="0">Seleccione una opción</option>
                                  {
                                    options.models.map((row, key) => (
                                      <option key={key} value={row.id}>{row.name}</option>
                                    ))
                                  }
                                </Input>
                                {
                                  meta.error && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    {
                      values.modelDetail &&
                      <FormGroup className="mb-1">
                        <Label className="form-control-label" for="modelDetail">
                          Detalle del Modelo
                        </Label>
                        <Field name="modelDetail">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="textarea"
                                maxLength={500}
                                rows="2"
                                resize="none"
                                disabled
                                invalid={meta.error && meta.touched}
                              />
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    }
                    {
                      options.days && values.frequency === 1 ?
                        <FormGroup className="mb-1">
                          <Label
                            className="form-control-label"
                            htmlFor="amountDays"
                          >
                            Días seleccionados en modalidad de Home Office
                          </Label>
                          <Row>
                            {
                              options.days.map((row, key) => (
                                <Col xs="6" md="4" key={key}>
                                  <div
                                    className="custom-control custom-checkbox mb-3"
                                    style={{ marginLeft: "12px" }}
                                  >
                                    <input
                                      className="custom-control-input"
                                      id={row.key}
                                      type="checkbox"
                                      defaultChecked={values[row.key]}
                                      disabled
                                      value={values[row.key]}
                                    // onChange={e => onChangeHO(e)}
                                    />
                                    <label className="custom-control-label" htmlFor={row.key}>
                                      {row.name}
                                    </label>
                                  </div>
                                </Col>
                              ))
                            }
                          </Row>
                        </FormGroup>
                        : null
                    }
                    {
                      values.direction &&
                      <FormGroup className="mb-1">
                        <Label className="form-control-label" for="direction">
                          Domicilio donde realizara el Home Office
                          <p style={{ color: "red" }}>*(Detalle claramente sobre la distribución político-geográfica: Provincia, cantón, distrito, delegación, departamento,
                            estado, municipio, corregimiento, zona, barrio y señas extras según corresponda</p>
                        </Label>
                        <Field name="direction">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="textarea"
                                maxLength={500}
                                rows="3"
                                resize="none"
                                disabled
                                invalid={meta.error && meta.touched}
                              />
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    }
                    <FormGroup className="mb-1">
                      <Label className="form-control-label" for="action">
                        Flujo de Aprobación
                      </Label>
                      <Field name="action">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              // placeholder="Nombre de la posición"
                              valid={!meta.error && meta.modified && meta.touched}
                              invalid={meta.error && meta.touched}
                            >
                              <option key="0" value="0">Seleccione una opción</option>
                              <option key="1" value="1">Aprobar</option>
                              <option key="2" value="2">Rechazar (No tiene Home Office)</option>
                              <option key="3" value="3">Solicitar corrección</option>
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup className="mb-1">
                      <Label className="form-control-label" for="comments">
                        Comentarios (500)
                      </Label>
                      <Field name="comments">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="textarea"
                              placeholder="Escriba aquí los comentarios para el colaborador."
                              maxLength={500}
                              rows="3"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Continuar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div >
    </Modal >
  );
};

WorkFlow.propTypes = {
  title: PropTypes.string.isRequired,
};

export default WorkFlow;