/*!

=========================================================
*Autopp - BawDevolutionForm
=========================================================

* Este form es hecho para cuando un especialista devuelva una solicitud de requerimiento baw (case number),
*en el row de solicitudes pueda darle editar, y aparezca esta vista como una copia del formulario de baw, 
*esto para que pueda realizar los cambios en el requerimiento, haga la actualización, lo envie al robot, y el robot
*lo envie al especialista con los nuevos cambios para su posterior aprobación o devolución del especialista.
*En el form tiene datos precargados, del requerimiento, por tanto todos los inputs estan seleccionados en base a el requerimiento.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerías de React
import
React, { useContext, useEffect, useState } from 'react'
import { Form, FormGroup, Button, Input, Col, Row } from "reactstrap";
//import { useSendBawDevolutionForm } from './useSendBawDevolutionForm';

//Import de componente contexto para extraer states globales
import { UserContext } from '../SharedComponents/UserContext';

//Custom hook para realizar la actualización de datos al caseNumber de BAW.
import { useSendBawDevolutionForm } from './useSendBawDevolutionForm';

export const BawDevolutionForm = React.memo(({
    handleSetTableOrBawFormDevolution,
    requirementInfoDevolution,
    setLoadingChangingView
}) => {

    //Custom Hook para enviar la devolución de requerimiento BAW
    const { handleSendBawDevolutionForm } = useSendBawDevolutionForm();


    //#region Configuraciones
    //Asignación del titulo del ítem.
    const [state] = useState({
        title: "Editar requerimiento Baw",
        subtitle: "A continuación puede editar el requerimiento devuelto número de caso: " + requirementInfoDevolution?.caseNumber
    });
    const { title, subtitle } = state;
    //#endregion

    //#region States globales

    //states global de dropdownoptions
    const { dropDownOptions } = useContext(UserContext);

    //#endregion

    //#region States locales

    //Asignación de Campos del form.
    const [fieldsForm, setFieldsForm] = useState([
        {
            colWidth: "6",
            colHeight: "",
            label: "Proveedor: *",
            placeholder: "Seleccione el proveedor",
            id: 'vendors',
            defaultValue: 'vendors',
            type: "select",
            required: true,
            disabled: false,
        },
        {
            colWidth: "6",
            colHeight: "",
            label: "Nombre del Producto: *",
            placeholder: "Seleccione el producto",
            id: "ProductName",
            options: [],
            type: "select",
            required: true,
            defaultValue: 'ProductName',
            disabled: false,
        },
        {
            colWidth: "6",
            colHeight: "",
            label: "Tipo de Requerimiento: *",
            placeholder: "Seleccione el tipo de requerimiento",
            id: "RequirementType",
            options: [],
            type: "select",
            required: true,
            defaultValue: 'RequirementType',
            disabled: false,
        },
        {
            colWidth: "6",
            colHeight: "",
            label: "Cantidad: *",
            placeholder: "Digite la cantidad",
            id: "Quantity",
            name: "number",
            type: "number",
            min: 1,
            max: 1999999999,
            required: true,
            defaultValue: 'Quantity',
            disabled: false,
        },
        {
            colWidth: "6",
            colHeight: "",
            label: "Es parte de una integración? *",
            placeholder: "Seleccione si es una integración",
            id: "isIntegration",
            type: "select",
            required: true,
            defaultValue: 'isIntegration',
            disabled: false,
        },
        {
            colWidth: "10",
            label: "Comentarios adicionales: ",
            placeholder: "Si lo desea, puede agregar algunos comentarios",
            id: "AdditionalComments",
            name: "text",
            type: "text",
            required: false,
            defaultValue: 'AdditionalComments',
            disabled: false,
        },
    ]);

    //state que guarda la info del BawItemActual para luego integrarlo al campo información de BawItemsList
    const [newInfo, setNewInfo] = useState({
        "vendors": {
            "label": requirementInfoDevolution?.vendor,
            "value": requirementInfoDevolution?.idVendor
        },
        "ProductName": {
            "label": requirementInfoDevolution?.product,
            "value": requirementInfoDevolution?.idProduct
        },
        "RequirementType": {
            "label": requirementInfoDevolution?.requirementType,
            "value": requirementInfoDevolution?.idRequirementType
        },
        "Quantity": requirementInfoDevolution?.quantity,
        "isIntegration": {
            "label": requirementInfoDevolution?.isIntegration,
            "value": requirementInfoDevolution?.idIsIntegration
        },
        "AdditionalComments": requirementInfoDevolution?.comments
    }
    )

    //#endregion

    //#region Handles utiles
    //Asigna los valores de cada campo del form en newInfo, y a la vez asigna los campos option a los demás dropdowns dependiendo de la selección.
    const handleChangeInfo = (constant, e) => {

        //Asigna las opciones de ProductName
        if (constant === "vendors") {
            //console.log("Asig opciones de product name")

            //#region Apartir de vendors establece los dropdowns a ProductsNames     

            const productNameOptions = dropDownOptions.productName
                .filter(
                    (dropdown) => dropdown.vendor === e.label)
                .map(
                    (dropdown) => { return { "label": dropdown.label, "value": dropdown.value } });


            const indexElement = fieldsForm.findIndex(dropdown => dropdown.id == "ProductName");

            let asignOptions = [...fieldsForm];

            asignOptions[indexElement] = { ...asignOptions[indexElement], options: productNameOptions }

            setFieldsForm(asignOptions);

            //#endregion

            //LimpiaLosDropDowns excepto vendors
            handleCleanDropdowns(constant);


        } else if (constant === "ProductName") { //Asigna las opciones de requirementType
            //Apartir de ProductName establece los dropdowns a requirementType

            //Averiguar el type del ProductSeleccionado
            const wichTypeofProduct = dropDownOptions.productName.filter((productName) => productName.label.replace(/\s+/g, '') === e.label.replace(/\s+/g, ''));

            //console.log(wichTypeofProduct);
            //console.log(dropDownOptions.requirementType);
            //Filtrar las que no son del tipo de producto y moldear data
            let requirementTypeOptions = ""

            try {
                requirementTypeOptions = dropDownOptions.requirementType
                    .filter(
                        (reqType) => reqType.reqtype === wichTypeofProduct[0].requirementType
                    ).map(
                        (reqType) => { return { "label": reqType.label, "value": reqType.value } });
            } catch (e) { }

            //Asignar las opciones al campo de RequirementType
            const indexElement = fieldsForm.findIndex(dropdown => dropdown.id == "RequirementType");
            let asignOptions = [...fieldsForm];

            asignOptions[indexElement] = { ...asignOptions[indexElement], options: requirementTypeOptions }
            setFieldsForm(asignOptions);

            //LimpiaLosDropDowns excepto vendors y productName
            handleCleanDropdowns(constant);

        }

        //Insert General
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: e
        }))





    }

    //Limpia los demás Dropdowns si fue seleccionado vendors o productName en el form.
    const handleCleanDropdowns = (constant) => {

        //Si selecciona vendors limpia todas las casillas
        if (constant === "vendors") {
            for (const element of fieldsForm) {
                (element.type === "select" && element.id !== "AdditionalComments") ?
                    (setNewInfo((prevState) => ({
                        ...prevState,
                        [element.id]: { label: "", value: "" }
                    }))) :
                    element.id !== "AdditionalComments"&&
                    (setNewInfo((prevState) => ({
                        ...prevState,
                        [element.id]: ""
                    })));
            }


        } else {
            //Si selecciona ProductName limpia todo menos Vendors.
            for (const element of fieldsForm) {
                if ((element.type === "select" && element.id !== "vendors" && element.id !== "AdditionalComments")) {
                    setNewInfo((prevState) => ({
                        ...prevState,
                        [element.id]: { label: "", value: "" }
                    }
                    ))
                } else if (element.id !== "vendors" && element.id !== "AdditionalComments") {
                    setNewInfo((prevState) => ({
                        ...prevState,
                        [element.id]: ""
                    }
                    ))
                };
            }
        }



    }

    //#endregion

    //#region Effectos
    //Efecto para establecer el productName y Requierement Type por defecto, con la información precargada.
    useEffect(() => {
        try {
            //#region Establece el ProductName por defecto
            const productNameOptions = dropDownOptions.productName
                .filter(
                    (dropdown) => dropdown.vendor === newInfo.vendors.label)
                .map(
                    (dropdown) => { return { "label": dropdown.label, "value": dropdown.value } });

            const indexElement = fieldsForm.findIndex(dropdown => dropdown.id == "ProductName");

            let asignOptions = [...fieldsForm];

            asignOptions[indexElement] = { ...asignOptions[indexElement], options: productNameOptions }

            //#endregion

            //#region Establecer requierementType por defecto

            let requirementTypeOptions = "";
            const wichTypeofProduct = dropDownOptions.productName.filter((productName) => productName.label.replace(/\s+/g, '') === newInfo.ProductName.label.replace(/\s+/g, ''));

            try {
                requirementTypeOptions = dropDownOptions.requirementType
                    .filter(
                        (reqType) => reqType.reqtype === wichTypeofProduct[0].requirementType
                    ).map(
                        (reqType) => { return { "label": reqType.label, "value": reqType.value } });
            } catch (e) { }

            //console.log(requirementTypeOptions);

            const indexElement2 = fieldsForm.findIndex(dropdown => dropdown.id == "RequirementType");

            asignOptions[indexElement2] = { ...asignOptions[indexElement2], options: requirementTypeOptions }

            //#endregion
            //console.log(asignOptions);

            setFieldsForm(asignOptions);
        } catch (e) {

        }
    }, [])



    return (

        <>
            <Form noValidate className="formInfo needs-validation">


                <Row className="">

                    <Col xs="7" sm="10" className="">
                        <h3 className="mb-0">{title}</h3>
                        <p className="text-sm mb-0">{subtitle}</p>
                    </Col>

                    {fieldsForm.map((row, key) =>
                        row.type === "text" || row.type === "number" ? (
                            <Col xs="12" sm={row.colWidth} md={row.colHeight} key={key} >
                                <FormGroup key={key}>
                                    <label
                                        style={{ fontSize: "12px" }}
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >
                                        {`${row.label}`}
                                    </label>

                                    <Input
                                        key={key}
                                        id={row.id}
                                        name={"BawDevolution"}
                                        required={row.required}
                                        className="basic-multi-select text-dark"
                                        value={newInfo[row.id]}
                                        min={row.min}
                                        max={row.max}
                                        type={row.type}
                                        placeholder={row.placeholder}
                                        onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        ) : row.id === "vendors" || row.id === "isIntegration" ? (
                            //Este case es para traer las opciones completas ya que no dependen de ningun dropdown
                            <Col xs="12" sm={row.colWidth} key={key}>
                                <FormGroup key={key}>
                                    <label
                                        style={{ fontSize: "12px" }}
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >
                                        {row.label}
                                    </label>


                                    <Input
                                        key={row.id + key}
                                        id={row.id}
                                        //name={fieldsForm.id}
                                        name={"BawDevolution"}
                                        required={row.required}
                                        className="basic-multi-select text-dark"
                                        value={newInfo[row.id].label}
                                        type={row.type}
                                        placeholder={row.placeholder}
                                        onChange={(e) => handleChangeInfo(row.id, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id })}
                                    >
                                        <option id=""></option>
                                        {
                                            dropDownOptions[row.id] && dropDownOptions[row.id].map((value) => {
                                                return (
                                                    <option id={value.value}>{value.label}</option>
                                                );
                                            })}
                                    </Input>
                                    <div className="invalid-feedback">
                                        Este campo no puede estar vacío
                                    </div>


                                </FormGroup>
                            </Col>
                        ) : (
                            //Dependen de un dropdown
                            <Col xs="12" sm={row.colWidth} key={key} >
                                <FormGroup key={key}>
                                    <label
                                        style={{ fontSize: "12px" }}
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >
                                        {row.label}
                                    </label>

                                    <Input
                                        key={row.id + key}
                                        id={row.id}
                                        name={"BawDevolution"}
                                        //name={fieldsForm.id}
                                        required={row.required}
                                        value={newInfo[row.id].label}
                                        className="basic-multi-select text-dark"
                                        type={row.type}
                                        placeholder={row.placeholder}
                                        options={row.options}
                                        onChange={(e) => handleChangeInfo(row.id, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id })}
                                    >
                                        <option id=""></option>
                                        {
                                            row.options && row.options.map((value) => {
                                                return (
                                                    <option id={value.value}>{value.label}</option>
                                                );
                                            })

                                        }
                                    </Input>
                                    <div className="invalid-feedback">
                                        Este campo no puede estar vacío
                                    </div>


                                </FormGroup>
                            </Col>
                        )
                    )}

                    <Col sm="12" className="" style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            color="success"
                            type="button"
                            className=""
                            onClick={() => handleSendBawDevolutionForm(newInfo, requirementInfoDevolution, handleSetTableOrBawFormDevolution, setLoadingChangingView)}
                        >
                            Enviar requerimiento
                        </Button>

                        <Button
                            color="danger"
                            type="button"
                            className=""
                            onClick={() => handleSetTableOrBawFormDevolution()}

                        >
                            Cancelar
                        </Button>
                    </Col>


                </Row>
            </Form>

            {/*JSON.stringify(newInfo*/}
        </>
    )
})

export default BawDevolutionForm;
