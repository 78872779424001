/*!

=========================================================
*Portal WMS
=========================================================

*Componente "WmsLogin" para el inicio de sesión.

* Props:
    - No se utilizan props directamente en este componente.
=========================================================

* Coded by Diego Meza/Eduardo Piedra - Application Management GBM

*/
// React library
import React, { useContext, useState, useEffect } from 'react';
// Reactstrap components
import { CardImg, Card, CardBody, Col, Row, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { LoadingOutlined } from '@ant-design/icons';
import { PiLockKeyOpen } from "react-icons/pi";
import { Spin } from 'antd'; // Assuming you're using antd for loading spinner
import { UserContext } from './UserContext';

import { getUserProtected } from "selectors/adminLayout";

import { encrypter } from 'helpers/desencrypt.jsx';

// Dispatch hook
import { useDispatch } from 'react-redux';

// Action imports for API calls
import { signInWms } from 'actions/wms';

import { desencrypt } from 'helpers/desencrypt.jsx';

export const WmsLogin = React.memo(() => {
    //#region States globales
    const { isMobile, notify, setSapPassword } = useContext(UserContext);
    //#endregion

    //#region Ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    function formatText(input) {
        // Check if input contains a comma to determine if it's a name or a phrase
        if (input.includes(',')) {
            const [lastName, firstName] = input.split(', ').map(part => part.trim());
            const formattedLastName = lastName
                .split(' ')
                .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
                .join(' ');

            const formattedFirstName = firstName
                .split(' ')
                .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
                .join(' ');

            return `${formattedFirstName} ${formattedLastName}`;
        } else {
            return input
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        }
    }

    //At the start, assign the password of sap if before was assigned in local storage.
    useEffect(() => {
        const sapPassStorage = localStorage.getItem("sap");
        if (sapPassStorage) //=> If exist sap's password.
        {
            let sapPasswordLocalStorage = desencrypt(
                decodeURIComponent(sapPassStorage)
            )
            setSapPassword(sapPasswordLocalStorage)
        }
    }, [])

    const [localPassword, setLocalPassword] = useState("");
    const [spinner, setSpinner] = useState(false);

    const handleSignInWMS = () => {
        if (!localPassword) {
            notify("alert", "Atención", "Debe ingresar su contraseña de SAP para iniciar sesión.");
            return;
        }
        setSpinner(true);

        dispatch(signInWms({ password: encrypter(localPassword) })).then((resp) => {
            const { payload } = resp;
            setSpinner(false);

            if (resp.payload.isAxiosError) {
                notify("danger", "Atención", "Ocurrió un error con el inicio de sesión.");
                console.log(payload);
            } else {
                if (payload.data.payload.auth) {
                    localStorage.setItem("sap", encrypter(localPassword));
                    notify("success", "Éxito", "¡Inicio de sesión exitoso!");
                    setSapPassword(localPassword);

                } else {
                    notify("danger", "Error", "Contraseña incorrecta, favor validar.");
                }
            }
        });
    };

    return (
        // <Row className="justify-content-center ">
        //     <Col className="d-flex justify-content-center ">
        <Row
            className="justify-content-center align-items-center"
            style={{ height: isMobile ? '80vh' : '' }} // Makes the Row take full height of the viewport
        >
            <Col className="d-flex justify-content-center" xl="4" lg="5" md="6">

                <Card className="card-profile shadow" style={{ width: '100%', maxWidth: '500px' }}>
                    <CardImg
                        alt="..."
                        src={require(`assets/img/WMS/fondo21.jpeg`)}
                        top
                        style={{ height: isMobile ? '50px' : '90px' }}
                    />
                    <Row className="justify-content-center">
                        <Col className="order-lg-2" lg="2">
                            <div className="card-profile-image">
                                <a href="#pablo">
                                    <img
                                        style={{
                                            width: `${isMobile ? '80px' : '100px'}`,
                                            height: `${isMobile ? '80px' : '100px'}`,
                                        }}
                                        alt="..."
                                        className="rounded-circle"
                                        src={require("../../../assets/img/WMS/others/5.png")}
                                    />
                                </a>
                            </div>

                        </Col>
                    </Row>

                    <CardBody className="pt-0 pt-md-4 mt-5">
                        <div className="text-center">
                            <h3>
                                {formatText(getUserProtected().NOMBRE)}
                            </h3>
                            <div className="h5 mt-2">
                                <i className="ni business_briefcase-24 mr-2" />
                                {formatText(getUserProtected().POSICION)}
                            </div>
                            <hr className="my-3" />
                        </div>

                        <InputGroup className="mb-3 mt-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-lock-circle-open" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                className='ml--2'
                                type="password"
                                placeholder="Ingrese su contraseña de SAP"
                                value={localPassword}
                                onChange={(e) => setLocalPassword(e.target.value)}
                                style={{ borderRadius: '0.375rem' }}
                            />
                        </InputGroup>

                        <Row className="justify-content-center">
                            <Col className="d-flex justify-content-center">
                                {!spinner ? (
                                    <Button color="default" onClick={handleSignInWMS}>
                                        <PiLockKeyOpen style={{ marginRight: '10px', fontSize: '16px' }} />
                                        Ingresar
                                    </Button>
                                ) : (
                                    <div style={{ textAlign: 'center' }} className="mt-4">
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#32325d' }} spin />} />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
});
