/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Application Support Administrator en la solicitud
=========================================================

* Coded by Sergio Marin  

*/

import React, { useState, useEffect } from "react";
import { Card, CardTitle, Label, Col, Row, Input, FormGroup, FormFeedback } from "reactstrap";
import MultiSelect from 'react-select';

const AppSupportAdministrator = ({ requiredJson, data, isVisible, disabledFieldsJson, setData, enableComments, optionsJson }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif' };
    const groupStyle = { marginBottom: '0.1rem', fontFamily: 'sans-serif' };
    const labelStyle = { height: '1.5em', marginBottom: '0.1rem', fontFamily: 'sans-serif' };

    const forms = [
        {
            type: "text",
            label: "User SAP",
            id: "sapUser",
            colWidth: 4,
            elementType: "Input",
            disabled: false
        }
    ]

    const completedForm = {
        type: "text",
        label: "Completado:",
        id: "completed",
        colWidth: 4,
        disabled: true,
    };

    const comments = {
        type: "textarea",
        label: "Observaciones (max 500 caracteres)",
        id: "asComments",
        colWidth: 8,
        elementType: "Input",
        disabled: enableComments,
        maxLength: 500
    };

    const [multiOptions, setMultiOption] = useState([]);
    const [multiSelected, setMultiSelected] = useState([]);

    //FUNCIONES

    //ini
    useEffect(() => {
        let parsedAppsOptions = [];
        optionsJson.appSupportOptions.map((application) => {
            parsedAppsOptions.push({ label: application.value, value: application.id });
        });
        setMultiOption(parsedAppsOptions);

        //las opciones seleccionadas
        let selectedArray = [];
        data.appSupportOptions && data.appSupportOptions.map((apps) => {
            const selectedJson = optionsJson.appSupportOptions.find(item => item.id === Number(apps));
            selectedArray.push({ label: selectedJson.value, value: selectedJson.id });
        });
        setMultiSelected(selectedArray);

    }, []);

    //Cargar los additional software a data
    useEffect(() => {
        setData((prevState) => ({ ...prevState, appSupportOptions: multiSelected ? multiSelected.map(group => group.value.toString()) : [] }));
    }, [multiSelected]);

    const handleInputOnChange = (id, e) => {
        e.persist();
        //setAppData(prevState => ({ ...prevState, [id]: e.target.value }));
        setData(prevState => ({ ...prevState, [id]: e.target.value }));
    }

    const handleMulti = (selectedOptions) => {
        setMultiSelected(selectedOptions);
    };

    //RETURN
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Application Support Administrator
                    </CardTitle>
                    {/*LOS CAMPOS*/}
                    <Row style={{ padding: '0px 10px 0px' }}>
                        {
                            forms.map((formsJson, index) => {
                                return (
                                    <Col key={index} xs={formsJson.colWidth} style={formsJson.style}>
                                        <div style={{ padding: '0px 15px 0px' }}>
                                            <FormGroup style={{ ...groupStyle }} key={formsJson.id}>
                                                <Label htmlFor={formsJson.id} style={labelStyle}>{formsJson.label}
                                                    {formsJson.required && <span style={{ color: 'red' }}> *</span>}
                                                </Label>
                                                <Input
                                                    type={formsJson.type}
                                                    placeholder={formsJson.placeHolder}
                                                    id={formsJson.id}
                                                    invalid={requiredJson && requiredJson.includes(formsJson.id)}
                                                    name={formsJson.name}
                                                    disabled={(disabledFieldsJson && disabledFieldsJson.includes("AppSupportAdministrator"))}
                                                    className="form-control"
                                                    onChange={(e) => handleInputOnChange(formsJson.id, e)}
                                                    defaultValue={data[formsJson.id]}
                                                />
                                                <FormFeedback>
                                                    Este campo no puede estar vacío
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                    {/*el multiSelect*/}
                    <Row style={{ padding: '0px 45px 0px' }}>
                        <Col xs="3" style={{ padding: '0px 0px 0px' }} >
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor="colabaccesses"  >
                                    Accesos Colaborador
                                </Label>
                                <MultiSelect
                                    closeMenuOnSelect={false}
                                    id={"colabaccesses"}
                                    isDisabled={(disabledFieldsJson && disabledFieldsJson.includes("AppSupportAdministrator"))}
                                    isMulti
                                    options={multiOptions}
                                    value={multiSelected}
                                    onChange={handleMulti}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {/*Comments*/}
                    <Row style={{ padding: '0px 10px 0px' }}>
                        <Col xs={comments.colWidth} style={comments.style}>
                            <div style={{ padding: '0px 15px 0px' }}>
                                <FormGroup style={{ ...groupStyle }} key={comments.id}>
                                    <Label htmlFor={comments.id} style={labelStyle}>{comments.label}
                                        {comments.required && <span style={{ color: 'red' }}> *</span>}
                                    </Label>
                                    <Input
                                        type={comments.type}
                                        placeholder={comments.placeHolder}
                                        id={comments.id}
                                        invalid={requiredJson && requiredJson.includes(comments.id)}
                                        name={comments.name}
                                        disabled={!comments.disabled && (disabledFieldsJson && disabledFieldsJson.includes("AppSupportAdministrator"))}
                                        className="form-control"
                                        onChange={(e) => handleInputOnChange(comments.id, e)}
                                        defaultValue={data[comments.id]}
                                        maxLength={comments.maxLength}
                                    />
                                    <FormFeedback>
                                        Este campo no puede estar vacío
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    {/*COMPLETADO*/}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={completedForm.colWidth}>
                            <FormGroup style={{ ...groupStyle }} key={completedForm.id}>
                                <Label htmlFor={completedForm.id} style={completedForm.labelStyle || labelStyle}>
                                    {completedForm.label}
                                </Label>
                                <Input
                                    type={completedForm.type}
                                    id={completedForm.id}
                                    disabled={completedForm.disabled}
                                    className="form-control"
                                    value={data.asApprover}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );
}

export default AppSupportAdministrator;
