import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findFormValuesRequestOpportunity = createAction(constants.GET_FORM_VALUES_REQUEST_OPPORTUNITY, id => method.get(`${urls.DIGITALREQUEST.getFormValuesRequestOpportunity}/${id}`)());

export const findFormValuesEquipmentsRequest = createAction(constants.GET_FORM_VALUES_EQUIPMENTS_REQUEST, method.get(urls.DIGITALREQUEST.getFormValuesEquipmentsRequest));

export const findRequerimentsByUser = createAction(constants.GET_REQUERIMENTS_BY_USER, method.get(urls.DIGITALREQUEST.getRequerimentsByUser));

export const findActivityFlowRequest = createAction(constants.GET_ACTIVITY_FLOW_REQUEST, id => method.get(`${urls.DIGITALREQUEST.getActivityFlowRequest}/${id}`)());

export const findEquipmentsByRequest = createAction(constants.GET_EQUIPMENTS_BY_REQUEST, id => method.get(`${urls.DIGITALREQUEST.getEquipmentsByRequest}/${id}`)());

export const findEquipmentsCreatedByRequest = createAction(constants.GET_EQUIPMENTS_CREATED_BY_REQUEST, id => method.get(`${urls.DIGITALREQUEST.getEquipmentsCreatedByRequest}/${id}`)());

export const findReferencesByRequest = createAction(constants.GET_REFERENCES_BY_REQUEST, id => method.get(`${urls.DIGITALREQUEST.getReferencesByRequest}/${id}`)());

export const findReferencesSpareByRequest = createAction(constants.GET_REFERENCES_SPARE_BY_REQUEST, id => method.get(`${urls.DIGITALREQUEST.getReferencesSpareByRequest}/${id}`)());

export const findEquipmentsSpareByRequest = createAction(constants.GET_EQUIPMENTS_SPARE_BY_REQUEST, id => method.get(`${urls.DIGITALREQUEST.getEquipmentsSpareByRequest}/${id}`)());

export const findConfigurationsByRequest = createAction(constants.GET_CONFIGURATIONS_BY_REQUEST, id => method.get(`${urls.DIGITALREQUEST.getConfigurationByRequest}/${id}`)());

export const findJustificationsByRequest = createAction(constants.GET_JUSTIFICATION_BY_REQUEST, id => method.get(`${urls.DIGITALREQUEST.getJustificationByRequest}/${id}`)());

export const findResumeOffersRequestById = createAction(constants.GET_RESUME_OFFERS_BY_REQUEST_ID, id => method.get(`${urls.DIGITALREQUEST.getResumeOffersRequestById}/${id}`)());

export const findLastVersionByRequest = createAction(constants.GET_LAST_VERSION_BY_REQUEST, opp => method.get(`${urls.DIGITALREQUEST.getLastVersionByRequest}/${opp}`)());

export const findOptionsRequestToVersion = createAction(constants.GET_OPTIONS_REQUEST_TO_VERSION, method.get(`${urls.DIGITALREQUEST.getOptionsRequestToVersion}`));

export const createRequirement = createAction(constants.CREATE_REQUIREMENT, body => method.post(urls.DIGITALREQUEST.createRequirement, body)());

export const createOneEquipment = createAction(constants.CREATE_ONE_EQUIPMENT, (id, body) => method.post(`${urls.DIGITALREQUEST.createOneEquipment}/${id}`, body)());

export const createEquipmentSpare = createAction(constants.CREATE_EQUIPMENT_SPARE, (id, body) => method.post(`${urls.DIGITALREQUEST.createEquipmentSpare}/${id}`, body)());

export const createManyEquipments = createAction(constants.CREATE_MANY_EQUIPMENTS, (id, body) => method.post(`${urls.DIGITALREQUEST.createManyEquipments}/${id}`, body)());

export const createJustifyByRequest = createAction(constants.CREATE_JUSTIFY_REQUEST, (id, body) => method.post(`${urls.DIGITALREQUEST.createJustifyRequest}/${id}`, body)());

export const createCommentaryByRequest = createAction(constants.CREATE_COMMENTARY_REQUEST, (id, body) => method.post(`${urls.DIGITALREQUEST.createCommentaryRequest}/${id}`, body)());

export const createServiceOrderRequest = createAction(constants.CREATE_SERVICE_ORDER_REQUEST, (id, body) => method.post(`${urls.DIGITALREQUEST.createServiceOrderRequest}/${id}`, body)());

export const createAjustOfferRequest = createAction(constants.CREATE_AJUST_OFFER_BY_REQUEST, (id, body) => method.post(`${urls.DIGITALREQUEST.createAjustOfferRequest}/${id}`, body)());

export const createNewVersionByRequest = createAction(constants.CREATE_NEW_VERSION_BY_REQUEST, (id, body) => method.post(`${urls.DIGITALREQUEST.createNewVersionByRequest}/${id}`, body)());

export const updateEquipmentById = createAction(constants.UPDATE_EQUIPMENT_BY_ID, (id, body) => method.post(`${urls.DIGITALREQUEST.updateEquipmentById}/${id}`, body)());

export const updateActivityFlow = createAction(constants.UPDATE_ACTIVITY_FLOW, (id, body) => method.post(`${urls.DIGITALREQUEST.updateActivityFlow}/${id}`, body)());

export const updateStateRequest = createAction(constants.UPDATE_STATE_REQUEST, (id, state) => method.put(`${urls.DIGITALREQUEST.updateStateRequest}/${id}/${state}`)());

export const updateEquipmentSpareById = createAction(constants.UPDATE_EQUIPMENT_SPARE_BY_ID, (id, body) => method.post(`${urls.DIGITALREQUEST.updateEquipmentSpareById}/${id}`, body)());

export const deactivateReferences = createAction(constants.DEACTIVATE_REFERENCE_BY_ID, (body) => method.put(`${urls.DIGITALREQUEST.deactivateReferences}`, body)());

export const deactivateConfigurations = createAction(constants.DEACTIVATE_CONFIGURATIONS_BY_ID, (body) => method.put(`${urls.DIGITALREQUEST.deactivateConfigurations}`, body)());

export const deactivateReferencesSpare = createAction(constants.DEACTIVATE_REFERENCE_BY_ID, (body) => method.put(`${urls.DIGITALREQUEST.deactivateReferencesSpare}`, body)());

export const deactivateReferencesAjustOffer = createAction(constants.DEACTIVATE_REFERENCES_AJUST_OFFER_BY_ID, (body) => method.put(`${urls.DIGITALREQUEST.deactivateReferencesAjustOffer}`, body)());

export const deactivateEquipmentSpareById = createAction(constants.DEACTIVATE_EQUIPMENT_SPARE_BY_ID, (id) => method.put(`${urls.DIGITALREQUEST.deactivateEquipmentSpareById}/${id}`)());

export const deactivateEquipmentById = createAction(constants.DEACTIVATE_EQUIPMENT_BY_ID, (id) => method.put(`${urls.DIGITALREQUEST.deactivateEquipmentById}/${id}`)());

export const sendEmailOfferWonRequest = createAction(constants.SEND_EMAIL_OFFER_WON_REQUEST, (id, body) => method.post(`${urls.DIGITALREQUEST.sendEmailOfferWonRequest}/${id}`, body)());

export const updateAmountEquipmentsByRequest = createAction(constants.UPDATE_AMOUNT_EQUIPMENTS_BY_REQUEST, (id, body) => method.post(`${urls.DIGITALREQUEST.updateAmountEquipmentsByRequest}/${id}`, body)());

export const createNewVersionByRejectRequest = createAction(constants.CREATE_NEW_VERSION_BY_REJECT_REQUEST, (id, body) => method.post(`${urls.DIGITALREQUEST.createNewVersionByRejectRequest}/${id}`, body)());
/**
 * Accion para limpiar la informacion
 */
export const clearData = createAction(constants.CLEAR_DATA);