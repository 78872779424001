// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import { Button, Card, CardBody, Col, Row } from "reactstrap";

const PersonCard = props => {
  const { name, subject, email, img, isLeader } = props;

  return (
    <>
      {isLeader ? (
        <Card className="card-profile bg-secondary mt-5">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <img
                  alt="..."
                  className="rounded-circle border-secondary"
                  src={img}
                />
              </div>
            </Col>
          </Row>
          <CardBody
            className="pt-7 px-5"
            style={{ backgroundColor: "#e8f5fa" }}
          >
            <div className="text-center mb-4">
              <h3>{name}</h3>
              <small className="h4 font-weight-light text-muted">
                {subject}
              </small>
              <div className="mt-3">
                <Button
                  href={"mailto:" + email}
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                >
                  <i className="far fa-envelope" />
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      ) : (
        <Card className="card-profile bg-secondary mt-5">
          <CardBody style={{ backgroundColor: "#e8f5fa", height: "500px" }}>
            <img
              alt="..."
              className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
              src={img}
              style={{ width: "140px" }}
            />
            <div className="pt-4 text-center">
              <h5 className="h3 title">
                <span className="d-block mb-1">{name}</span>
                <small className="h4 font-weight-light text-muted">
                  {subject}
                </small>
              </h5>
              <div className="mt-3">
                <Button
                  href={"mailto:" + email}
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                >
                  <i className="far fa-envelope" />
                </Button>
              </div>
              <div></div>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

PersonCard.propTypes = {
  name: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired
};

export default PersonCard;
