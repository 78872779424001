import React from 'react';
// react library for routing
import { Redirect, Route, Switch } from "react-router-dom";
// routes
import { FreelanceRoutes } from "routes/routes.jsx";

const Index = () => {

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/freelance") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Switch>
        {
          getRoutes(FreelanceRoutes)
        }
        <Redirect from="/admin/freelance" to="/admin/freelance/Home" />
      </Switch>
      <p className="lead"></p>
    </>
  );
};

export default Index;
