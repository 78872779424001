/*!

=========================================================
*Autopp - HelpRequest
=========================================================

* Gracias a UncontrolledTooltip, en las 5 vistas tiene un boton de Ayuda,
el cual despliega un mensaje personalizado acorde a cada vista, el mismo 
personalizado por el state global formSelected, establecido en el componente 
CardSelectForm.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/


//Import de librerias de react
import React, { useContext, useState, useEffect } from 'react';

import { Button, UncontrolledTooltip, } from "reactstrap";

//Import de contexto
import { UserContext } from '../SharedComponents/UserContext';


const HelpRequest = () => {

    //#region States Globales
    const { formSelected, toggleCheck } = useContext(UserContext);
    //#endregion  

    //#region States Locales
    //Aqui se asignan los mensajes para cada form.
    const [messageForEachForm] = useState([
        {
            component: "1",
            message: "Para activar LDRS, primeramente debe seleccionar GTL Quotation en Ciclo de Ventas."
        },
        {
            component: "2",
            message: "En caso de que note que los campos están bloqueados, escriba cúal es el cliente primeramente, y luego los demás campos se desbloquearán."
        },
        {
            component: "3",
            message: "En caso de que un colaborador no aparezca en opciones, asegúrese de que haya ingresado por primera vez a Smart And Simple de GBM. De lo contrario contáctese support.",
        },
        {
            component: "4",
            message: "Para agregar LDRS, actívelos en la sección de Información general y seleccione GTL Quotation en Ciclo de Ventas.",
            message2: "Para eliminar un archivo cargado, por favor de click en el botón del basurero."
        },
        {
            component: "5",
            message: "Todos los productos y tipos de requerimiento, están basados en el proveedor de marca."
        },
    ]);

    //En este state se alamcena el mensaje a mostrar.
    const [messageToShow, setMessageToShow] = useState("");

    //#endregion

    //#region Effect para cuando cambie el FormSelected asigne el mensaje de ayuda según el form.
    useEffect(() => {
        //console.log(formSelected)
        const tempMsg = messageForEachForm.filter((value) => value.component === formSelected);

        //En caso que sea el componente de LDRMainForm hay una excepción
        if (formSelected === "4") {
            if (toggleCheck.applyLDROption === true ||
                (toggleCheck.applyLDROption === false && toggleCheck.applyBOMPNOption === false)) //Es que cuando arranca por primera vez todo, esas dos opciones siempre están en false, y se requiere que por defecto LDR tenga mensaje de ayuda
            {
                setMessageToShow(tempMsg[0].message)
            } else {
                setMessageToShow(tempMsg[0].message2)
            }

        } else {
            setMessageToShow(tempMsg[0].message)
        }
    }, [formSelected])
    //#endregion

    return (

        <>
            <Button
                className="btn-info btn-round btn-icon"
                color="default"
                id="helpRequestBtn"
                size="sm"
            >
                <span className="btn-inner--icon mr-1">
                    <i className="fa fa-question-circle" />
                </span>
                <span className="btn-inner--text">Ayuda</span>
            </Button>
            <UncontrolledTooltip delay={0} target="helpRequestBtn">
                {messageToShow}
            </UncontrolledTooltip>

        </>
    );
};

export default HelpRequest;