/*
=========================================================
*Notifications - Extra Hours report pending to approve
=========================================================

*Description.
This component creates a Card where information of an extra hours report that is pending to approve is displayed

=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";

function SolicitudCard({ title, report, image, onSelect, idRol }) {
  const [h1, m1, s1] = report.time.split(":").map(Number);
  const [h2, m2, s2] = report.endTime.split(":").map(Number);

  const startTimeMs = h1 * 3600 * 1000 + m1 * 60 * 1000 + s1 * 1000;
  const endTimeMs = h2 * 3600 * 1000 + m2 * 60 * 1000 + s2 * 1000;

  let diffMs = endTimeMs - startTimeMs;

  if (diffMs < 0) diffMs += 24 * 3600 * 1000;

  // Convierte la diferencia a horas
  const diffHours = diffMs / (3600 * 1000);

  return (
    <Card
      className="card-reports"
      style={{
        height: "24rem",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        transition: "box-shadow 0.3s ease",
        cursor: "pointer",
      }}
      onClick={() => onSelect(report, idRol)}
    >
      <CardBody className="solicitud-card-body">
        <div className="solicitud-card-content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "3px",
            }}
          >
            <CardTitle className="solicitud-card-title">
              <strong style={{ fontSize: "1rem", marginBottom: "5px" }}>
                {title}
              </strong>
            </CardTitle>
            <img
              src={image}
              alt="Icon"
              className="icon-image"
              style={{
                width: "50px",
                height: "50px",
              }}
            />
          </div>
          <hr
            className="solicitud-card-divider"
            style={{ borderColor: "#ccc" }}
          />
          <CardText className="solicitud-card-text">
            <strong>Colaborador:</strong> {report.userName}
          </CardText>
          <CardText className="solicitud-card-text">
            <strong>Fecha reportada:</strong> {report.date.substring(0, 10)}
          </CardText>
          <CardText className="solicitud-card-text">
            <strong>Horas reportadas:</strong>{" "}
            {`${report.time} - ${report.endTime}`}
          </CardText>
          <CardText className="solicitud-card-text">
            <strong>Tiempo solicitado:</strong>{" "}
            {`${diffHours.toFixed(2)} horas`}
          </CardText>
        </div>
      </CardBody>
    </Card>
  );
}

SolicitudCard.propTypes = {
  title: PropTypes.string.isRequired,
  report: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
};

export default SolicitudCard;
