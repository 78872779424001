import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const notifications = handleActions({
  [constants.GET_ALL_NOTIFICATIONS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CREATE_POLICY_USER_SIGNATURE]: (state, action) => {
    const { status, data } = action.payload;
    const { events, message } = state;
    if (status === 200 || status === 412) {
      const { documentSigning } = data.payload;
      const documents = [];
      for (const element of state.documents) {
        if (element.idDocumento !== documentSigning) {
          documents.push(element);
        }
      }
      let notifications = [];
      for (const element of state.notifications) {
        if (element.type === "signature" && documents.length) {
          notifications.push(element);
        }
      }
      return {
        notifications,
        documents,
        events,
        message
      };
    }
    return { ...state };
  },
  [constants.CREATE_EXEPTION_USER_SIGNATURE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { documentHide } = data.payload;
      const documents = [];
      for (const element of state.documents) {
        if (element.idDocumento !== documentHide) {
          documents.push(element);
        }
      }
      let notifications = [];
      for (const element of state.notifications) {
        if (element.type === "signature" && documents.length) {
          notifications.push(element);
        }
      }
      return {
        notifications,
        documents,
      };
    }
    return { ...state };
  },
  [constants.DEACTIVATE_EVENT_BY_ID]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { documents, notifications, events, message } = state;
      const { id } = data.payload;
      return {
        documents,
        notifications,
        events: events.filter((row) => row.id !== parseInt(id)),
        message
      }
    }
    return state;
  },
  [constants.MAKE_MONETARY_DONATION_EVENT]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      const { documents, notifications, events, message } = state;
      const { event } = data.payload;
      return {
        documents,
        notifications,
        events: events.filter((row) => row.id !== parseInt(event)),
        message
      }
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});