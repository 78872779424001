// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useEffect } from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Row } from "reactstrap";

const MaintenanceForm = props => {

  const {
    title,
    subtitle,
    options,
    initialValues,
    onSubmit,
    onBack,
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.idColab || values.idColab === "0")
      errors.idColab = "Debes ingresar el id del colaborador.";
    if (!values.username || values.username === "")
      errors.username = "Debes ingresar el nombre de usuario.";
    if (!values.fullname || values.fullname === "")
      errors.fullname = "Debes ingresar el nombre completo.";
    if (!values.email || values.email === "")
      errors.email = "Debes ingresar el correo electrónico.";
    if (!values.coe || values.coe === "0")
      errors.coe = "Debes seleccionar un COE.";
    if (!values.equipment || values.equipment === "0")
      errors.equipment = "Debes seleccionar un Equipo.";
    if (!values.country || values.country === "0")
      errors.country = "Debes seleccionar un País.";
    if (!values.speciality || values.speciality === "0")
      errors.speciality = "Debes seleccionar una Especialidad.";
    return errors;
  };

  useEffect(() => {
    delete initialValues['test'];
  });

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col sm="12" md="8">
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, values, submitting, validating, valid }) => (
            <FormRS onSubmit={handleSubmit}>
              <div className="form-row align-items-center">
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="idColab">ID Colaborador</Label>
                    <Field name="idColab">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="number"
                            placeholder="Ingrese el número de colaborador"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="username">Nombre Usuario</Label>
                    <Field name="username">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="text"
                            placeholder="Ingrese el nombre de usuario"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="fullname">Nombre Completo</Label>
                    <Field name="fullname">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="text"
                            placeholder="Ingrese el nombre completo"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row align-items-center">
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="email">Correo Electrónico</Label>
                    <Field name="email">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="email"
                            placeholder="Ingrese el correo electrónico"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="email2">Correo Electrónico Secundario</Label>
                    <Field name="email2">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="email"
                            placeholder="Ingrese el correo electrónico secundario"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          />
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="coe">COE</Label>
                    <Field name="coe">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="select"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          >
                            <option key="0" value="0">Seleccione un COE</option>
                            {
                              options.coes.map((row, key) => {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              })
                            }
                          </Input>
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row align-items-center">
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="equipment">Equipo</Label>
                    <Field name="equipment">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="select"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          >
                            <option key="0" value="0">Seleccione un Equipo</option>
                            {
                              options.equipments.map((row, key) => {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              })
                            }
                          </Input>
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="country">País</Label>
                    <Field name="country">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="select"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          >
                            <option key="0" value="0">Seleccione un País</option>
                            {
                              options.countries.map((row, key) => {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              })
                            }
                          </Input>
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup>
                    <Label className="form-control-label" for="speciality">Especialidad</Label>
                    <Field name="speciality">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            type="select"
                            valid={!meta.error && meta.modified && meta.touched}
                            invalid={meta.error && meta.touched}
                          >
                            <option key="0" value="0">Seleccione una Especialidad</option>
                            {
                              options.specialities.map((row, key) => {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              })
                            }
                          </Input>
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </div>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row align-items-center justify-content-center">
                <Col className="mb-1" sm="12" md="4">
                  <FormGroup className="d-flex align-items-center justify-content-center">
                    <Field name="lider" type="checkbox">
                      {({ input, meta }) => (
                        <Label>
                          <Input
                            {...input}
                            type="checkbox"
                            className="form-control-label"
                            invalid={meta.error && meta.touched}
                          />{" "}
                          Líder COE
                          {
                            meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                          }
                        </Label>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row align-items-center justify-content-center">
                <Col className="mb-1" sm="12" md="4">
                  <Button
                    className="btn-icon"
                    block
                    color="danger"
                    type="submit"
                    onClick={onBack}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-times" />
                    </span>
                    <span className="btn-inner--text">Cancelar</span>
                  </Button>
                </Col>
                <Col className="mb-1" sm="12" md="4">
                  <Button
                    disabled={validating || submitting}
                    className="btn-icon"
                    block
                    color="success"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-save" />
                    </span>
                    <span className="btn-inner--text">Crear Usuario</span>
                  </Button>
                </Col>
              </div>
            </FormRS>
          )}
        />
      </CardBody>
    </Card>
  );
};

MaintenanceForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default MaintenanceForm;