import React, { useState, useEffect, useRef } from "react";
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Tables from "components/UserCloudRequest/Tables";
import ModalInformation from "components/UserCloudRequest/ModalInformation.jsx";
import {
  getAllCloudVendor,
  getTenantsByCloud,
  getMyRequest,
  updateRequest,
} from "actions/userCloudRequests.jsx";
import { useDispatch } from "react-redux";
import { getUsernameLogged } from "selectors/adminLayout.jsx";
import { Spin } from "antd";
import ReactBSAlert from "react-bootstrap-sweetalert";

const MyRequests = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalConfirm, setModalConfirm] = useState("");
  const [itemInfo, setItemInfo] = useState({
    requestInfo: [],
  });
  const [editableData, setEditableData] = useState({});
  const [data, setData] = useState({
    clouds: [],
    tenants: [],
  });
  const actualUser = getUsernameLogged();
  const [reload, setReload] = useState(false);
  const [allRequest, setAllRequest] = useState([]);

  const dispatch = useDispatch();
  const jsonFilters = [
    { label: "Estatus" },
    { label: "Correo GBM" },
    { label: "Nombre" },
    { label: "Área" },
    { label: "Permiso-Perfil" },
    { label: "Nube" },
    { label: "Número de tenant" },
    { label: "Suscripción" },
  ];
  const colums = [
    { label: "Identificador", value: "id", type: "text" },
    { label: "Estatus", value: "status", type: "text" },
    { label: "Correo GBM", value: "requesterEmail", type: "text" },
    { label: "Tipo solicitud", value: "typeRequest", type: "text" },
    { label: "Nombre", value: "nameUser", type: "text" },
    {
      label: "Detalles",
      value: "requestInfo",
      type: "button",
      method: (e) => detailsRequest(e),
      legend: "Información detallada de la solicitud",
    },
  ];
  const row = [
    {
      a: "Pendiente",
      b: "joarojas@gbm.net",
      c: "Alejandro Rojas Arroyo",
      d: "MIS",
      e: "Admin",
      f: "N/A",
      g: "123456",
      h: "N/A",
    },
  ];

  const notifyEl = useRef(null);

  const notify = (type, title, message) => {
    try {
      let options = {
        place: "br",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {title}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 7,
      };
      notifyEl.current.notificationAlert(options);
    } catch (error) {}
  };

  //
  const detailsRequest = (e) => {
    setItemInfo(e);
    setIsOpen(true);
  };

  const handleOnCloseModal = () => {
    setIsOpen(!isOpen);
    setEditableData({});
  };

  //Funcion para hacer los cambios de informacion en los campos de input, select .etc
  const handleOnChangeInfo = (constant, value) => {
    setEditableData((prevState) => ({
      ...prevState,
      [constant]: value,
    }));
  };

  const closeModal = () => {
    setModalConfirm("");
  };

  //Funcion que muestra los modales de confirmacion
  const showModalUpdateModal = () => {
    setModalConfirm(
      <ReactBSAlert
        custom
        showCancel
        title={"¿Seguro que desea actualizar la solicitud?"}
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        placeHolder="Write something"
        onConfirm={() => handleOnUpdateRequest()}
        onCancel={() => closeModal()}
        confirmBtnText="actualizar"
        cancelBtnText="Cancelar"
        btnSize="md"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        openAnim={{ name: "showSweetAlert", duration: 1500 }}
        closeAnim={{ name: "hideSweetAlert", duration: 1000 }}
      ></ReactBSAlert>
    );
  };

  //
  const handleOnUpdateRequest = () => {
    setLoading(true);
    let body = {
      requestId: itemInfo.id,
      requestInfo: editableData,
      requestFile: itemInfo.requestFile,
    };
    setModalConfirm("");
    setIsOpen(!isOpen);
    dispatch(updateRequest(body)).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        setLoading(false);
        notify("warning", "Error", "No se logró actualizar la solicitud.");
      } else {
        setLoading(false);
        notify("success", "Exito", "Se actualizó correctamente");
        setReload(!reload);
        setEditableData({});
        setItemInfo({
          requestInfo: [],
        });
      }
      setLoading(false);
    });
    setLoading(true);
  };

  //
  const validateInfo = (item) => {
    return !(item && Object.values(item).length > 0);
  };

  //Funcion para los filtros por columna
  const handleOnfilterDinamic = (constant, e) => {
    setFilters((prevState) => ({
      ...prevState,
      [constant]: e,
    }));
  };

  useEffect(() => {
    const getMyRequest = async () => {
      await handleOnGetMyRequest();
      await handleOnGetCloud();
    };
    getMyRequest();
  }, [reload]);
  //Funcion que trae la data de los campos selects
  const handleOnGetMyRequest = async () => {
    setLoading(true);
    dispatch(getMyRequest({ user: actualUser })).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        setLoading(false);
        notify("danger", "Error", "No se logro cargar tus solicitudes.");
      } else {
        setAllRequest(payload.data.data);
        setLoading(false);
      }
    });
  };

  const handleOnGetCloud = async () => {
    setLoading(true);
    await dispatch(getAllCloudVendor()).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        notify("danger", "Error", payload.message);
        setLoading(false);
      } else {
        let cloud = [];

        const data = payload.data.data;
        for (const item of data) {
          let json = {};
          json["value"] = item.id;
          json["label"] = item.name;
          cloud.push(json);
        }
        setData((prevState) => ({
          ...prevState,
          clouds: cloud,
        }));
        setLoading(false);
      }
    });
  };
  //Utiliza los filtros de las columnas en la tabla
  const renderRowsByFilters = (rows) => {
    if (Object.keys(filters).length === 0) {
      return rows;
    } else {
      const filterKeys = Object.keys(filters);
      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();
        rows = rows.filter(
          (item) =>
            item[element].toString().toLowerCase().indexOf(valueFiltered) != -1
        );
      }
      return rows;
    }
  };
  const searchTenantsByCloud = async (item, value, nextItem) => {
    await dispatch(getTenantsByCloud(value)).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        notify("danger", "Error", payload.message);
      } else {
        setData((prevState) => ({
          ...prevState,
          tenants: payload.data.data,
        }));
        setEditableData((prevState) => ({
          ...prevState,
          [item.id]: value,
        }));
      }
      let temp = itemInfo.requestInfo;
      for (const item of temp) {
        if (item.formRequestDataId === 6) {
            item.options = payload.data.data === null ? [] : payload.data.data
        }
      }
      setItemInfo((prevState) => ({
        ...prevState,
        requestInfo: temp,
      }))
    });
  };

  return (
    <div>
      {modalConfirm}
      <AdminHeader name="Solicitudes" parentName="Usuarios Cloud" />
      <Spin size="large" spinning={loading}>
        <Container className="mt--6" fluid>
          <Tables
            page={page}
            setPage={setPage}
            colums={colums}
            filters={handleOnfilterDinamic}
            rows={renderRowsByFilters(allRequest)}
            subtitle={"Mis Solicitudes"}
            title={"Todas mis solicitudes creadas"}
            isEditing={true}
          />
          <ModalInformation
            requestDetails={itemInfo.requestInfo}
            toggle={handleOnCloseModal}
            modal={isOpen}
            headerText={"Detalle de la solicitud"}
            buttonCancelText={"Cerrar"}
            buttonConfirmText={"Guardar"}
            handleOnConfirmButton={showModalUpdateModal}
            showButtonConfirm={true}
            valueInsert={"value"}
            labelValueInsert={"formRequestDataName"}
            handleOnChangeInfo={handleOnChangeInfo}
            valueName="formRequestDataName"
            editing={false}
            cloudData={data}
            files={itemInfo.requestFile}
            requestSelected={itemInfo}
            validateConfirm={validateInfo(editableData)}
            customMethod={searchTenantsByCloud}
            notify={notify}
            setLoad={setLoading}
          />
        </Container>
      </Spin>
    </div>
  );
};

export default MyRequests;
