import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// react library
import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import moment from "moment";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  ListGroupItem,
  ListGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import {
  getCategories,
  getDocumentsByCategory,
  getDocumentIDTemplate,
  createRequest,
  templateQuery,
  addAttachment,
} from "actions/FinancialFlowsActions.jsx";

import {
  CategoriesSelector,
  CategoryDocumentsSelector,
  DocumentIDTemplatesSelector,
  TemplateQuerySelector,
} from "selectors/FinancialFlows.jsx";

import { getOtherApprovers } from "actions/ASActions.jsx";

import { Spin } from "antd";

import { getUsernameLogged } from "selectors/adminLayout.jsx";

class FinanceNewRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //actually used
      SelectedCategory: null,
      SelectedDocument: null,
      SelectedFlow: null,
      showApproversModal: false,
      otherApproversList: [],
      selectedOtherApproversList: [],
      userFilter: "",
      description: "",
      showConfirmAlert: false,
      createdRequest: null,
      documentTemplates: [],
      selectedTemplate: null,

      //not used
      loadingApprovers: false,

      //new
      showTableModal: false,
      selectedTable: null,
      selectedTableValues: [],
      showNewRowTable: false,
      createRequestLoading: false,
      bu: [],
      selectedBu: null,
      countries: [
        { id: 1, name: "GBMCR" },
        { id: 2, name: "GBMDR" },
        { id: 3, name: "GBMSV" },
        { id: 4, name: "GBMGT" },
        { id: 5, name: "GBMHN" },
        { id: 6, name: "GBMNI" },
        { id: 7, name: "GBMPA" },
        { id: 8, name: "GBMCOL" },
        { id: 9, name: "GBMCO" },
        { id: 10, name: "GBMMD" },
        { id: 11, name: "GBMBVI" },
      ],
      files: [],
      showAttachmentModal: false,
      selectedFile: null,
      formattedAmount: "$0.00",
    };

    this.addOtherApprover = this.addOtherApprover.bind(this);
    this.removeOtherApprover = this.removeOtherApprover.bind(this);
    this.saveOtherApprovers = this.saveOtherApprovers.bind(this);
    this.toggleAttachmentModal = this.toggleAttachmentModal.bind(this);
  }

  componentDidMount() {
    // this.setState((state) => ({
    //   loadingApprovers: !state.loadingApprovers,
    // }));
    this.props.getCategories().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          // console.log(res);
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
  }

  getCategoryDocuments = (category) => {
    this.props.getDocumentsByCategory(category).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          // console.log(res);
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        // console.log(res.payload.data.payload.data);
      }
    });
  };

  getDocumentIDTemplates = (id) => {
    this.props.getDocumentIDTemplate(id).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleRequestTypeSelection = (type, name, bu) => {
    this.setState({ SelectedCategory: { type: type, name: name }, bu: bu });
    this.getCategoryDocuments(type);
  };

  handleRequestDocumentSelection = (document) => {
    let { bu } = this.state;
    if (
      document.fieldsData.find((value) => {
        return value.name === "Unidad de Negocio";
      })
    ) {
      let fieldID = document.fieldsData.findIndex((value) => {
        return value.name === "Unidad de Negocio";
      });
      document.fieldsData[fieldID].options = [];
      bu.map((value) => {
        document.fieldsData[fieldID].options.push({
          id: value.id,
          value: value.country,
        });
      });
    }
    if (
      document.fieldsData.find((value) => {
        return value.name === "Monto US$";
      })
    ) {
      let amountID = document.fieldsData.findIndex((value) => {
        return value.name === "Monto US$";
      });
      //document.fieldsData[amountID].value = "0.00";
      document.fieldsData[amountID].error = true;
    }
    this.setState({ SelectedDocument: document, selectedBu: bu[0].BUID });
    this.getDocumentIDTemplates(document.id);
  };

  handleFlowSelection = (type) => {
    this.setState({ SelectedFlow: type });
  };

  handleRemoveCategorySelection = () => {
    this.setState({ SelectedCategory: null });
  };

  handleRemoveDocumentSelection = () => {
    this.setState({
      SelectedDocument: null,
      selectedTemplate: null,
      SelectedFlow: null,
      documentTemplates: [],
    });
  };

  handleRemoveTemplateSelection = () => {
    this.setState({ SelectedFlow: null });
  };

  otherApproversModal = () => {
    this.props.getOtherApprovers().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        // console.log(res.payload.data.payload.approvers);
        this.setState({
          otherApproversList: res.payload.data.payload.approvers,
        });
        this.setState((prevState) => ({
          showApproversModal: !prevState.showApproversModal,
        }));
      }
    });
  };

  removeApprover = (approverToRemove) => {
    let approvers = this.state.SelectedFlow.approversData;
    approvers = approvers.filter(
      (approver) => approver.name !== approverToRemove
    );
    this.setState({ SelectedFlow: { approversData: approvers } });
  };

  addOtherApprover = (approver) => {
    var approvers = this.state.SelectedFlow.approversData;
    approvers.push(approver);
    this.setState({ SelectedFlow: { approversData: approvers } });
  };

  removeOtherApprover = (approver) => {
    var approvers = this.state.SelectedFlow.approversData;
    approvers.push(approver);
    // this.setState({ SelectedFlow: { approversData: approvers } });
  };

  saveOtherApprovers = () => {
    this.setState((prevState) => ({
      showApproversModal: !prevState.showApproversModal,
    }));
  };

  handleOnCreateRequest = async () => {
    this.setState({ createRequestLoading: true });
    console.log(this.state.SelectedFlow)
    let { selectedBu, files } = this.state;
    let info = {
      BussinessUnitID: selectedBu,
      requester: "",
      description: this.state.description,
      DocumentID: this.state.SelectedDocument.id,
      approvers: this.state.SelectedFlow.approvers,
      data: this.state.SelectedDocument.fieldsData,
      conditionals: "{}",
      SelectedFlow: this.state.SelectedFlow
    };
    await this.props.createRequest(info).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.notify("success", "Solicitud creada Exitosamente.", "OK");
        let data = res.payload.data.payload.request;
        // console.log(data);
        if (data)
          if (files.length > 0) {
            for (let index = 0; index < files.length; index++) {
              let file = new FormData();
              file.append("doc", files[index]);
              await this.props.addAttachment(data, file).then(async (res) => {
                if (res.payload.isAxiosError) {
                  if (res.payload.response) {
                    const {
                      data: { payload },
                    } = res.payload.response;
                    this.notify("danger", "Falló", payload.message);
                  } else {
                    this.notify(
                      "danger",
                      "Falló",
                      "No se logro establecer conexion con el servidor."
                    );
                  }
                }
              });
            }
          }
        this.setState({
          createRequestLoading: false,
          showConfirmAlert: true,
          createdRequest: data,
        });
        // let data = res.payload.data.payload.data;
        // if (data.request.id) this.setState({ showConfirmAlert: true });
        // this.setState({
        //   createdRequest: data.request.id,
        //   creatingRequest: false,
        // });
      }
    });
  };

  updateFieldValue = (updatingField, index) => {
    let SelectedDocument = this.state.SelectedDocument;
    SelectedDocument.fieldsData[index].value = updatingField;
    this.setState({ SelectedDocument });
  };

  updateAmountFieldValue = (updatingField, index) => {
    let { SelectedDocument, formattedAmount } = this.state;
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    // let regex = /\(?\b[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}\b\)?/;
    // console.log(updatingField);
    // console.log(SelectedDocument.fieldsData[index]);
    // if (regex.test(updatingField)) {
    //   console.log("dollar amount");
    //   SelectedDocument.fieldsData[index].error = false;
    // } else {
    //   console.log("not dollar amount");
    //   SelectedDocument.fieldsData[index].error = true;
    // }
    SelectedDocument.fieldsData[index].value = updatingField;
    if (isNaN(updatingField)) {
      SelectedDocument.fieldsData[index].error = true;
      formattedAmount = formatter.format(0);
    } else {
      SelectedDocument.fieldsData[index].error = false;
      formattedAmount = formatter.format(updatingField);
    }
    this.setState({ SelectedDocument, formattedAmount });
  };

  updateCountry = (updatingField, index) => {
    let { countries } = this.state;
    let selectedCountry = countries.find((value) => {
      return value.name === updatingField;
    });
    let SelectedDocument = this.state.SelectedDocument;
    SelectedDocument.fieldsData[index].value = updatingField;
    this.setState({ SelectedDocument, selectedBu: selectedCountry.id });
  };

  renderFieldType = (field, index) => {
    let { bu, formattedAmount, SelectedFlow } = this.state;
    const status = this.state.SelectedFlow !== null;
    var renderField = null;
    switch (field.type) {
      case "text":
        renderField = (
          <FormGroup className="row" key={field.id + "-text"}>
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="4"
            >
              {field.required ? (
                <div>
                  {field.name} <small> * </small>
                </div>
              ) : (
                <div>{field.name}</div>
              )}
            </Label>
            <Col md="8">
              <Input
                defaultValue={field.value}
                id="example-text-input"
                type="text"
                className={
                  field.required && field.value.length === 0 ? "is-invalid" : ""
                }
                onChange={(e) => this.updateFieldValue(e.target.value, index)}
                disabled={status || field.name === "Solicitante"}
              />
            </Col>
          </FormGroup>
        );
        break;
      case "textarea":
        renderField = (
          <FormGroup className="row" key={field.id + "-text"}>
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="4"
            >
              {field.required ? (
                <div>
                  {field.name} <small> * </small>
                </div>
              ) : (
                <div>{field.name}</div>
              )}
            </Label>
            <Col md="8">
              <Input
                defaultValue={field.value}
                id="example-text-input"
                type="textarea"
                className={
                  field.required && field.value.length === 0 ? "is-invalid" : ""
                }
                onChange={(e) => this.updateFieldValue(e.target.value, index)}
                disabled={status || field.name === "Solicitante"}
              />
            </Col>
          </FormGroup>
        );
        break;
      case "date":
        renderField = (
          <FormGroup className="row" key={field.id + "-date"}>
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="4"
            >
              {/* {field.name} {field.required && <small>requerido</small>} */}
              {field.required ? (
                <div>
                  {field.name} <small> * </small>
                </div>
              ) : (
                <div>{field.name}</div>
              )}
            </Label>
            <Col md="8">
              <Input
                defaultValue={field.value}
                id="example-date-input"
                type="date"
                className={
                  field.required && field.value.length === 0 ? "is-invalid" : ""
                }
                onChange={(e) => this.updateFieldValue(e.target.value, index)}
                disabled={status || field.name === "Fecha de Solicitud"}
              />
            </Col>
          </FormGroup>
        );
        break;
      case "currency":
        renderField = (
          <FormGroup className="row" key={field.id + "-number"}>
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="4"
            >
              {/* {field.name} {field.required && <small>requerido</small>} */}
              {field.required ? (
                <div>
                  {field.name} <small> * </small>
                </div>
              ) : (
                <div>{field.name}</div>
              )}
            </Label>
            <Col md="8">
              <Row>
                {" "}
                {!SelectedFlow && (
                  <Col>
                    <Input
                      value={field.value}
                      id="number-input-render"
                      type="number"
                      step="0.01"
                      placeholder="0.00"
                      className={
                        (field.required && field.value.length === 0) ||
                          field.error === true
                          ? "is-invalid"
                          : ""
                      }
                      onChange={(e) =>
                        this.updateAmountFieldValue(e.target.value, index)
                      }
                      disabled={status}
                    />
                  </Col>
                )}
                <Col>
                  <Input
                    value={formattedAmount}
                    id="example-number-input"
                    type="text"
                    className={!field.error ? "is-valid" : ""}
                    onChange={(e) => null}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
        );
        break;
      case "number":
        renderField = (
          <FormGroup className="row" key={field.id + "-number"}>
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="4"
            >
              {/* {field.name} {field.required && <small>requerido</small>} */}
              {field.required ? (
                <div>
                  {field.name} <small> * </small>
                </div>
              ) : (
                <div>{field.name}</div>
              )}
            </Label>
            <Col md="8">
              <Input
                defaultValue={field.value}
                id="example-number-input"
                type="number"
                className={
                  field.required && field.value.length === 0 ? "is-invalid" : ""
                }
                onChange={(e) => this.updateFieldValue(e.target.value, index)}
                disabled={status}
              />
            </Col>
          </FormGroup>
        );
        break;
      case "float":
        renderField = (
          <FormGroup className="row" key={field.id + "-float"}>
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="4"
            >
              {field.required ? (
                <div>
                  {field.name} <small> * </small>
                </div>
              ) : (
                <div>{field.name}</div>
              )}
            </Label>
            <Col md="8">
              <Input
                defaultValue={field.value}
                id="example-number-input"
                type="number"
                className={
                  field.required && field.value.length === 0 ? "is-invalid" : ""
                }
                onChange={(e) => this.updateFieldValue(e.target.value, index)}
                disabled={status}
              />
            </Col>
          </FormGroup>
        );
        break;
      case "combobox":
        if (field.name === "Unidad de Negocio") {
          renderField = (
            <FormGroup className="row" key={field.id + "-combobox"}>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
                md="4"
              >
                {field.required ? (
                  <div>
                    {field.name} <small> * </small>
                  </div>
                ) : (
                  <div>{field.name}</div>
                )}
              </Label>
              <Col md="8">
                <Input
                  type="select"
                  value={field.value}
                  onChange={(e) => this.updateCountry(e.target.value, index)}
                  disabled={status}
                >
                  {bu.map((options, list) => {
                    return (
                      <option value={options.country} key={"select-" + list}>
                        {options.country}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
          );
        } else {
          renderField = (
            <FormGroup className="row" key={field.id + "-combobox"}>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
                md="4"
              >
                {field.required ? (
                  <div>
                    {field.name} <small> * </small>
                  </div>
                ) : (
                  <div>{field.name}</div>
                )}
              </Label>
              <Col md="8">
                <Input
                  type="select"
                  value={field.value}
                  onChange={(e) => this.updateFieldValue(e.target.value, index)}
                  disabled={status}
                >
                  {field.options.map((options, list) => {
                    return (
                      <option value={options.value} key={"select-" + list}>
                        {options.value}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
          );
        }

        break;
      case "table":
        renderField = (
          <FormGroup className="row" key={field.id + "-combobox"}>
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="4"
            >
              {field.name}
            </Label>
            <Col md="8">
              <Button
                color="warning"
                href="#"
                onClick={() => this.showTableRender(field)}
                size="lg"
                disabled={status}
              >
                Datos de tabla
              </Button>
            </Col>
          </FormGroup>
        );
        break;
      default:
        renderField = (
          <FormGroup className="row" key={field.id + "-default"}>
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="4"
            >
              {/* {field.name} {field.required && <small>requerido</small>} */}
              {field.required ? (
                <div>
                  {field.name} <small> * </small>
                </div>
              ) : (
                <div>{field.name}</div>
              )}
            </Label>
            <Col md="8">
              <Input
                defaultValue={field.value}
                id="example-text-input"
                type="text"
                className={field.required ? "is-invalid" : ""}
                onChange={(e) => this.updateFieldValue(e.target.value, index)}
                disabled={status}
              />
            </Col>
          </FormGroup>
        );
        break;
    }

    return renderField;
  };

  goToRequest = (request) => {
    this.props.history.push(`/admin/finance/request/${request}`);
  };

  goToRequestsView = () => {
    this.props.history.push(`/admin/finance/myRequests`);
  };

  templateQuery = (document, fields) => {
    // console.log(fields);
    this.props.templateQuery(document, { fields }).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        let data = res.payload.data.payload.data;
        this.setState({ documentTemplates: data, selectedTemplate: null });
      }
    });
  };

  selectTemplate = (template) => {
    this.setState({ selectedTemplate: template });
  };

  resetTemplateSelection = () => {
    this.setState({ selectedTemplate: null });
  };

  showTableRender = (field) => {
    if (typeof field.value === "string") {
      field.value = [];
      let elements = {};
      field.options.forEach((element) => {
        elements[element.value] = element.value;
      });
      //  field.value.push(elements);
    }

    field.options.map((value) => {
      value.data = "";
    });

    this.setState({
      showTableModal: true,
      selectedTable: field,
      selectedTableValues: field.value,
      showNewRowTable: false,
    });
  };

  addRow = (values) => {
    let selectedTable = this.state.selectedTable;
    let newRow = {};
    values.map((value, key) => {
      newRow[value.value] = value.data;
    });
    selectedTable.value.push(newRow);
    this.setState({ selectedTable, showNewRowTable: false });
  };

  updateNewRowValue = (value, index) => {
    let selectedTable = this.state.selectedTable;
    selectedTable.options[index].data = value;
    this.setState({ selectedTable });
  };

  toggleShowAddRow = () => {
    this.setState({ showNewRowTable: !this.state.showNewRowTable });
  };

  saveTable = () => {
    this.setState({ showTableModal: false });
  };

  checkCompleteRequired = () => {
    let allow = false;
    let document = this.state.SelectedDocument;
    if (document.fieldsData) {
      let fields = document.fieldsData;
      fields.map((value) => {
        if (value.required && value.value.length === 0) allow = true;
      });
      fields.map((value) => {
        if (value.error && value.error === true) {
          allow = true;
        }
      });
    }
    return allow;
  };

  toggleAttachmentModal() {
    this.setState((prevState) => ({
      showAttachmentModal: !prevState.showAttachmentModal,
    }));
  }

  handleSelectFile = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };

  handleAddAttachment = async () => {
    const { files, selectedFile } = this.state;
    if (selectedFile) {
      files.push(selectedFile);
    }
    this.setState({ files, showAttachmentModal: false, selectedFile: null });
    // let data = new FormData();
    // data.append("doc", this.state.selectedFile);
    // await this.props.addAttachment(id, data).then(async (res) => {
    //   if (res.payload.isAxiosError) {
    //     if (res.payload.response) {
    //       const {
    //         data: { payload },
    //       } = res.payload.response;
    //       this.notify("danger", "Falló", payload.message);
    //     } else {
    //       this.notify(
    //         "danger",
    //         "Falló",
    //         "No se logro establecer conexion con el servidor."
    //       );
    //     }
    //   } else {
    //     this.setState({ showAttachmentModal: false });
    //     this.componentWillMount();
    //   }
    // });
  };

  handleRemoveFile = async (index) => {
    const { files } = this.state;
    files.splice(index, 1);
    this.setState({ files });
  };

  render() {
    const {
      CategoriesList,
      CategoryDocuments,
      DocumentTemplateList,
      username,
    } = this.props;

    let {
      notes,
      loadingApprovers,
      SelectedCategory,
      SelectedDocument,
      SelectedFlow,
      showConfirmAlert,
      createdRequest,
      selectedTemplate,
      documentTemplates,
      showTableModal,
      selectedTable,
      selectedTableValues,
      showNewRowTable,
      createRequestLoading,
      files,
      showAttachmentModal,
      selectedFile,
      formattedAmount,
    } = this.state;

    if (SelectedDocument) {
      SelectedDocument.fieldsData.map((value, key) => {
        if (value.name === "Solicitante" && value.value.length === 0) {
          value.value = username;
        }

        if (value.name === "Fecha de Solicitud" && value.value.length === 0) {
          value.value = moment().format("YYYY-MM-DD");
        }
        if (value.type === "combobox" && value.value.length === 0) {
          value.value = value.options[0].value;
        }
      });
    }
    console.log(this.state.SelectedFlow)
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AttachmentModal
          showModal={showAttachmentModal}
          toggleModal={this.toggleAttachmentModal}
          selectedFile={selectedFile}
          handleSelectFile={this.handleSelectFile}
          uploadFile={this.handleAddAttachment}
        />
        <AddOtherApprovers
          showApproversModal={this.state.showApproversModal}
          otherApproversList={this.state.otherApproversList}
          selectedOtherApproversList={this.state.selectedOtherApproversList}
          userFilter={this.state.userFilter}
          addToList={this.addOtherApprover}
          removeFromList={this.removeOtherApprover}
          updateApprovers={this.saveOtherApprovers}
        />
        <TableFieldModal
          showTableModal={showTableModal}
          selectedTable={selectedTable}
          selectedTableValues={selectedTableValues}
          updateNewRowValue={this.updateNewRowValue}
          addRow={this.addRow}
          showNewRowTable={showNewRowTable}
          toggleShowAddRow={this.toggleShowAddRow}
          saveTable={this.saveTable}
        />
        <SweetAlert
          show={showConfirmAlert}
          success
          showCancel
          showCloseButton
          confirmBtnText="Si"
          cancelBtnText="Ir Atras"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Solicitud Creada Exitosamente"
          onConfirm={() => this.goToRequest(createdRequest)}
          onCancel={() => this.goToRequestsView()}
        >
          Desea visualizar la solicitud?
        </SweetAlert>
        <AdminHeader name="Nueva Solicitud" parentName="Finance Flows" />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={createRequestLoading}>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          Paso 1: Seleccione la categoria
                        </h3>
                      </Col>
                      {SelectedCategory && !SelectedDocument && (
                        <Col className="text-right" xs="4">
                          <Button
                            color="warning"
                            href="#"
                            onClick={() => this.handleRemoveCategorySelection()}
                            size="sm"
                          >
                            Cambiar
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </CardHeader>
                  {SelectedCategory ? (
                    <CardBody>
                      <div className="my-4">
                        <span className="h6 surtitle text-muted">
                          Tipo Seleccionado:
                        </span>
                        <div className="h1">{SelectedCategory.name}</div>
                      </div>
                      <Row>
                        <div className="col">
                          <span className="h6 surtitle text-muted">
                            Código:
                          </span>
                          <span className="d-block h3">
                            {SelectedCategory.type}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <Row className="icon-examples">
                        {CategoriesList.length > 0 &&
                          !SelectedCategory &&
                          CategoriesList.map((category) => {
                            return (
                              <Col
                                lg="6"
                                md="6"
                                key={category.id + "-category"}
                              >
                                <button
                                  className="btn-icon-clipboard"
                                  type="button"
                                  onClick={() =>
                                    this.handleRequestTypeSelection(
                                      category.id,
                                      category.name,
                                      category.bu
                                    )
                                  }
                                >
                                  <div>
                                    <i className={category.icon} />
                                    <span>{category.name}</span>
                                  </div>
                                </button>
                              </Col>
                            );
                          })}
                      </Row>
                      {CategoriesList.length === 0 && (
                        <div className="h1">
                          No cuenta con ningún perfil de creación asignado
                        </div>
                      )}
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>

            {SelectedCategory && (
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            Paso 2: Documento de Aprobación
                          </h3>
                        </Col>
                        {SelectedDocument && !SelectedFlow && (
                          <Col className="text-right" xs="4">
                            <Button
                              color="warning"
                              href="#"
                              onClick={() =>
                                this.handleRemoveDocumentSelection()
                              }
                              size="sm"
                            >
                              Cambiar
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardHeader>

                    <CardBody>
                      {SelectedCategory &&
                        CategoryDocuments &&
                        !SelectedDocument ? (
                        <Row className="icon-examples">
                          {CategoryDocuments.map((document) => {
                            return (
                              <Col
                                lg="6"
                                md="6"
                                key={document.id + "-document"}
                              >
                                <button
                                  className="btn-icon-clipboard"
                                  type="button"
                                  onClick={(e) =>
                                    this.handleRequestDocumentSelection(
                                      document
                                    )
                                  }
                                >
                                  <div>
                                    <i
                                      className={
                                        document.icon | "ni ni-archive-2"
                                      }
                                    />
                                    <span>{document.name}</span>
                                  </div>
                                </button>
                              </Col>
                            );
                          })}
                        </Row>
                      ) : (
                        <div className="my-4">
                          <span className="h6 surtitle text-muted">
                            Documento Seleccionado:
                          </span>
                          <div className="h1">{SelectedDocument.name}</div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            {SelectedDocument && SelectedFlow === null && (
              <div>
                <Row>
                  <Col lg="6">
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Información de Solicitud</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                            <Button
                              color="primary"
                              href="#"
                              onClick={() =>
                                this.templateQuery(
                                  SelectedDocument.id,
                                  SelectedDocument.fieldsData
                                )
                              }
                              size="sm"
                              disabled={this.checkCompleteRequired()}
                            //disabled={SelectedDocument.fieldsData.length === 0}
                            >
                              Buscar Plantillas
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {SelectedDocument.fieldsData.map((field, index) => {
                            return this.renderFieldType(field, index);
                          })}
                          {SelectedDocument.fieldsData.length === 0 && (
                            <p>
                              Documento sin formulario asignad, favor contactar
                              al administrador de la plataforma.
                            </p>
                          )}
                        </Form>
                      </CardBody>
                      <CardFooter>
                        <p>Los campos que incluyen * son requeridos.</p>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Plantilla de Aprobación</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                            {selectedTemplate && (
                              <Button
                                color="primary"
                                href="#"
                                onClick={() => this.resetTemplateSelection()}
                                size="sm"
                              >
                                Cambiar selección
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {selectedTemplate ? (
                          <div>
                            <ListGroup className="list my--3" flush>
                              {selectedTemplate.approvers.length ? (
                                <div>
                                  {selectedTemplate.approvers.map(
                                    (approver) => {
                                      return (
                                        <ListGroupItem
                                          className="px-0"
                                          key={approver.id + "-approver"}
                                        >
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <p
                                                className="avatar rounded-circle"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <img
                                                  alt="..."
                                                  src={require("assets/img/theme/user.svg")}
                                                />
                                              </p>
                                            </Col>
                                            <div className="col ml--2">
                                              <h4 className="mb-0">
                                                <strong>{approver.name}</strong>
                                              </h4>
                                              <h4 className="mb-0">
                                                {approver.secondary !==
                                                  null && (
                                                    <strong>
                                                      {approver.secondary.name}
                                                    </strong>
                                                  )}
                                              </h4>
                                              <small>{approver.position}</small>
                                            </div>
                                          </Row>
                                        </ListGroupItem>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <p>
                                  Ningun aprobador asignado a esta plantilla
                                </p>
                              )}
                            </ListGroup>
                          </div>
                        ) : documentTemplates.length > 0 ? (
                          <div>
                            {documentTemplates.map((value, index) => {
                              return (
                                <Col lg="12" md="12" key={index + "-template"}>
                                  <button
                                    className="btn-icon-clipboard"
                                    type="button"
                                    onClick={() => this.selectTemplate(value)}
                                  >
                                    <div>
                                      <span>{value.description}</span>
                                    </div>
                                  </button>
                                </Col>
                              );
                            })}
                          </div>
                        ) : (
                          <p>
                            Ninguna plantilla encontrada para el formulario
                            ingresado.
                          </p>
                        )}
                      </CardBody>

                      {selectedTemplate && (
                        <CardFooter>
                          {" "}
                          <Button
                            color="success"
                            onClick={() =>
                              this.handleFlowSelection(selectedTemplate)
                            }
                            disabled={selectedTemplate.approvers.length === 0}
                          >
                            Seleccionar Plantilla
                          </Button>{" "}
                        </CardFooter>
                      )}
                    </Card>
                  </Col>
                </Row>
              </div>
            )}

            {SelectedFlow && (
              <div>
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">
                              Paso 3: Flujos de Aprobación
                            </h3>
                          </Col>
                          {SelectedFlow && (
                            <Col className="text-right" xs="4">
                              <Button
                                color="warning"
                                href="#"
                                onClick={() =>
                                  this.handleRemoveTemplateSelection()
                                }
                                size="sm"
                              >
                                Plantillas
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {SelectedFlow ? (
                          <div className="my-4">
                            <span className="h6 surtitle text-muted">
                              Flujo Seleccionado:
                            </span>
                            <div className="h1">{SelectedFlow.description}</div>
                          </div>
                        ) : (
                          <div>
                            {DocumentTemplateList.length ||
                              SelectedDocument.customApprovals === 1 ? (
                              <Row className="icon-examples">
                                {SelectedDocument.customApprovals === 1 && (
                                  <Col lg="6" md="6">
                                    <button
                                      className="btn-icon-clipboard"
                                      type="button"
                                      onClick={() =>
                                        this.handleFlowSelection(
                                          {
                                            open: true,
                                            description: "Flujo Abierto",
                                            approversData: [],
                                          },
                                          true
                                        )
                                      }
                                    >
                                      <div>
                                        <i className="ni ni-key-25" />
                                        <span>Flujo Abierto</span>
                                      </div>
                                    </button>
                                  </Col>
                                )}

                                {DocumentTemplateList.map((template) => {
                                  return (
                                    <Col
                                      lg="6"
                                      md="6"
                                      key={template.id + "-template"}
                                    >
                                      <button
                                        className="btn-icon-clipboard"
                                        type="button"
                                        onClick={() =>
                                          this.handleFlowSelection(
                                            template,
                                            false
                                          )
                                        }
                                      >
                                        <div>
                                          <i className="ni ni-key-25" />
                                          <span>{template.description}</span>
                                        </div>
                                      </button>
                                    </Col>
                                  );
                                })}
                              </Row>
                            ) : (
                              <p>Ninguna plantilla disponible</p>
                            )}{" "}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}

            {SelectedFlow && (
              <div>
                <Row>
                  <Col lg="6">
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Información Adicional</h3>
                          </Col>
                          <Col className="text-right" xs="4"></Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <div>
                            <Col>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="descripcion-sol"
                                >
                                  Descripción de Solicitud:
                                </label>
                                <Input
                                  id="descripcion-sol"
                                  resize="none"
                                  rows="3"
                                  type="textarea"
                                  value={this.state.description}
                                  onChange={(e) =>
                                    this.setState({
                                      description: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs="8">
                            <h5 className="h3 mb-0">
                              Flujo de Aprobación{" "}
                              {SelectedDocument.conditionals === 0 && (
                                <small>(Flujo Cerrado)</small>
                              )}
                            </h5>
                          </Col>
                          <Col className="text-right" xs="4">
                            <Button
                              color="primary"
                              onClick={(e) => this.otherApproversModal()}
                              size="sm"
                              disabled={SelectedDocument.conditionals === 0}
                            >
                              Agregar Otros
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <ListGroup className="list my--3" flush>
                          {SelectedFlow.approvers.length ? (
                            <div>
                              {" "}
                              {SelectedFlow.approvers.map((approver) => {
                                return (
                                  <ListGroupItem
                                    className="px-0"
                                    key={approver.id + "-approver"}
                                  >
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <a
                                          className="avatar rounded-circle"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <img
                                            alt="..."
                                            src={require("assets/img/theme/user.svg")}
                                          />
                                        </a>
                                      </Col>
                                      <div className="col ml--2">
                                        <h4 className="mb-0">
                                          <strong>{approver.name}</strong>
                                        </h4>
                                        <h4 className="mb-0">
                                          {approver.secondary !== null && (
                                            <strong>
                                              {approver.secondary.name}
                                            </strong>
                                          )}
                                        </h4>
                                        <small>{approver.position}</small>
                                      </div>
                                      {SelectedDocument.customApprovals ===
                                        1 && (
                                          <Col className="col-auto">
                                            <Button
                                              color="danger"
                                              size="sm"
                                              type="button"
                                              onClick={(e) =>
                                                this.removeApprover(approver.name)
                                              }
                                            >
                                              <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-times" />
                                              </span>
                                            </Button>
                                          </Col>
                                        )}
                                    </Row>
                                  </ListGroupItem>
                                );
                              })}
                            </div>
                          ) : (
                            <p>
                              Favor seleccione los aprobadores del flujo
                              abierto.
                            </p>
                          )}
                        </ListGroup>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Información de Solicitud</h3>
                          </Col>
                          <Col className="text-right" xs="4"></Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {SelectedDocument.fieldsData.map((field, index) => {
                            return this.renderFieldType(field, index);
                          })}
                        </Form>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Archivos Adjuntos</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                            <Button
                              color="primary"
                              onClick={(e) => {
                                this.toggleAttachmentModal();
                              }}
                              size="sm"
                            >
                              <i className={"fa fa-upload"} />
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <ListGroup className="list my--3" flush>
                          <ListGroupItem className="px-0" key={"attachment-1"}>
                            {files.length === 0 && (
                              <div>
                                <h4>Ningún archivo adjuntado a la solicitud</h4>
                              </div>
                            )}
                            {files.map((value, index) => {
                              return (
                                <Row
                                  className="align-items-center"
                                  key={"file-" + index}
                                >
                                  <div className="col ml--2">
                                    <h4 className="mb-0">{value.name}</h4>
                                    <small>{moment().format("lll")}</small>
                                  </div>
                                  <Col className="col-auto">
                                    <Button
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      key="reminder"
                                      onClick={(e) =>
                                        this.handleRemoveFile(index)
                                      }
                                    >
                                      X
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            })}
                          </ListGroupItem>
                        </ListGroup>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <Col>
                          <p className="mb-0">
                            Favor verificar que el flujo de aprobación sea el
                            correcto y los aprobadores se encuentren disponibles
                            para atender la solicitud, de otro modo no se podra
                            reemplazar el aprobador seleccionado y se tendrá que
                            cancelar la solicitud.
                            <br></br>
                            <br></br>
                            Enviaremos correos a los aprobadores seleccionados,
                            si alguno de ellos rechaza la solicitud esta pasara
                            inmediatamente a estado <code>RECHAZADA</code>. Si
                            el 100% de los aprobadores aprueba la solicitud
                            pasara automáticamente a estado{" "}
                            <code>APROBADA</code>.
                          </p>
                          <br></br>
                        </Col>
                        <Col>
                          <Button
                            block
                            color="success"
                            size="lg"
                            type="button"
                            onClick={(e) => this.handleOnCreateRequest()}
                          >
                            Generar Solicitud
                          </Button>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}

            {SelectedDocument && false ? (
              <Row>
                <Col lg="6">
                  <Card>
                    <Spin size="large" spinning={loadingApprovers}>
                      <CardHeader>
                        <Row>
                          <Col xs="8">
                            <h5 className="h3 mb-0">Orden de Aprobadores</h5>
                          </Col>
                          <Col className="text-right" xs="4">
                            <Button
                              color="success"
                              onClick={(e) => this.toggleApproversModal()}
                              size="sm"
                            >
                              Agregar Otros
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody></CardBody>
                    </Spin>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Información Adicional</h3>
                        </Col>
                        <Col className="text-right" xs="4"></Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <div>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="exampleFormControlTextarea3"
                              >
                                Notas:
                              </label>
                              <Input
                                id="exampleFormControlTextarea3"
                                resize="none"
                                rows="3"
                                type="textarea"
                                value={notes}
                                onChange={(e) =>
                                  this.setState({ notes: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">Enviar Solicitud</h3>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <p className="mb-0">
                            Favor verificar el flujo de aprobación según Power
                            Reserve Antes de enviar la solicitud. El Flujo de
                            aprobación se realizara en el orden indicado.
                            <br></br>
                            <br></br>
                            Enviaremos correos a los aprobadores seleccionados,
                            si alguno de ellos rechaza la solicitud esta pasara
                            inmediatamente a estado <code>RECHAZADA</code>. Si
                            el 100% de los aprobadores aprueba la solicitud
                            pasara automáticamente a <code>APROBADA</code>.
                          </p>
                        </Col>
                      </Row>
                      <hr />
                      <Form className="needs-validation" noValidate>
                        <Button
                          color="primary"
                          type="button"
                          //onClick={this.handleOnCreateRequest}
                          onClick={() =>
                            this.setState({ showCreateRequestAlert: true })
                          }
                          disabled={loadingApprovers}
                        >
                          Enviar
                        </Button>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <div></div>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

FinanceNewRequest.defaultProps = {
  CategoriesList: [],
  CategoryDocuments: [],
  DocumentTemplateList: [],
  TemplateQueryList: [],
  username: "",
  //  otherApproversData: [],
};

const mapStateToProps = (state) => ({
  CategoriesList: CategoriesSelector(state),
  CategoryDocuments: CategoryDocumentsSelector(state),
  DocumentTemplateList: DocumentIDTemplatesSelector(state),
  TemplateQueryList: TemplateQuerySelector(state),
  username: getUsernameLogged(),
  // otherApproversData: otherApproversSelector(state),
});

export default withRouter(
  connect(mapStateToProps, {
    getCategories,
    getDocumentsByCategory,
    getDocumentIDTemplate,
    getOtherApprovers,
    createRequest,
    templateQuery,
    addAttachment,
  })(FinanceNewRequest)
);

function AddOtherApprovers({
  showApproversModal,
  otherApproversList,
  selectedOtherApproversList,
  filter,
  addToList,
  removeFromList,
  updateApprovers,
}) {
  function selectedUser(user) {
    return false;
  }

  return (
    <Modal isOpen={showApproversModal}>
      <ModalHeader>Aprobadores Adicionales</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            {selectedOtherApproversList.length > 0 ? (
              <ListGroup className="list my--3" flush>
                {selectedOtherApproversList.map((approver, index) => (
                  <ListGroupItem className="px-0" key={"seleccionado-" + index}>
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            src={require("assets/img/theme/user.svg")}
                          />
                        </a>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <p onClick={(e) => e.preventDefault()}>
                            {approver.name}
                          </p>
                        </h4>
                        <small>{approver.position} </small>
                      </div>
                      <Col className="col-auto">
                        <Button
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={() =>
                            this.handleRemoveOtherApprover(approver)
                          }
                        >
                          X
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              <p> Ningún aprobador adicional seleccionado. </p>
            )}
          </CardBody>
        </Card>{" "}
        <Card>
          <CardHeader className="py-0">
            <Form>
              <FormGroup className="mb-0">
                <InputGroup className="input-group-lg input-group-flush">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <span className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="user"
                    placeholder="Buscar..."
                    type="search"
                    defaultValue={filter}
                    onChange={(value) => this.handleUserFilters(value)}
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          </CardHeader>
          <CardBody>
            {otherApproversList.length > 0 ? (
              <ListGroup data-toggle="checklist" flush>
                {otherApproversList.map((value, index) => (
                  <ListGroupItem className="px-0" key={"lista-" + index}>
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <p
                          className="avatar rounded-circle"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            src={require("assets/img/theme/user.svg")}
                          />
                        </p>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <p
                            onClick={(e) => e.preventDefault()}
                            href="e.preventDefault()"
                          >
                            {value.name}
                          </p>
                        </h4>
                        <small>{value.position} </small>
                      </div>
                      <Col className="col-auto">
                        {selectedUser(value) ? (
                          <Button
                            color="default"
                            size="sm"
                            type="button"
                            disabled
                          >
                            Seleccionar
                          </Button>
                        ) : (
                          <Button
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => addToList(value)}
                          >
                            Seleccionar
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              <p> Resultados no Encontrados </p>
            )}
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button color="default" type="button" onClick={() => updateApprovers()}>
          Aceptar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function TableFieldModal({
  showTableModal,
  selectedTable,
  selectedTableValues,
  updateNewRowValue,
  addRow,
  showNewRowTable,
  toggleShowAddRow,
  saveTable,
}) {
  return (
    <Modal isOpen={showTableModal}>
      <ModalHeader>Detalles de tabla</ModalHeader>
      <ModalBody>
        <Table className="align-items-center " responsive>
          <thead>
            <tr>
              {selectedTable &&
                selectedTable.options.map((row, key) => {
                  return <th key={"row-" + key}>{row.value}</th>;
                })}
            </tr>
          </thead>
          <tbody className="list">
            {selectedTableValues.length > 0 ? (
              selectedTableValues.map((value, key) => {
                return (
                  <tr key={"value-" + key}>
                    {Object.values(value).map((element, keyElement) => {
                      return (
                        <td key={"keyElement-" + keyElement}>{element}</td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="justify-content-md-center">
                  Ningún dato ingresado
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div>
          {" "}
          {!showNewRowTable && (
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={(e) => toggleShowAddRow()}
            >
              +
            </Button>
          )}
          {showNewRowTable && (
            <Button
              color="success"
              size="sm"
              type="button"
              onClick={(e) => addRow(selectedTable.options)}
            >
              Agregar
            </Button>
          )}
        </div>
        <div>
          {showNewRowTable &&
            selectedTable.options.map((option, index) => {
              return (
                <FormGroup key={"input" + index}>
                  <label className="form-control-label">{option.value}:</label>
                  <Input
                    className="form-control"
                    type="text"
                    value={option.data}
                    onChange={(e) => updateNewRowValue(e.target.value, index)}
                  />
                </FormGroup>
              );
            })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="default" type="button" onClick={() => saveTable()}>
          Guardar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function AttachmentModal({
  showModal,
  toggleModal,
  selectedFile,
  handleSelectFile,
  uploadFile,
}) {
  return (
    <Modal isOpen={showModal}>
      <ModalHeader toggle={toggleModal}>
        Adjuntar archivos a la solicitud
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <div className="custom-file">
              <input
                className="custom-file-input"
                id="customFileLang"
                lang="en"
                type="file"
                name="file"
                onChange={handleSelectFile}
              />
              <label className="custom-file-label" htmlFor="customFileLang">
                {selectedFile ? selectedFile.name : "Seleccione un Archivo"}
              </label>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="default" onClick={() => uploadFile(selectedFile)}>
          Agregar Archivo
        </Button>
      </ModalFooter>
    </Modal>
  );
}
