// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const CardTransaction = props => {

  const { data } = props;

  return (
    <div className="header-body">
      <Row>
        <Col sm="12" md="7" >
          <Row>
          <Col sm="12" md="4" >
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total de Solicitudes
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {data.totalCount}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                    <i className="fas fa-file-export" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.countNotPlannedMonth + data.countStaffMonth + data.countVacantMonth  + data.countNewCecoPositiontMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="4" >
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total No planificadas
                          </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {data.countNotPlanned}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                    <i className="fas fa-file-signature" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.countNotPlannedMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="4" >
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total Con Personal
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">{data.countStaff}</span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                    <i className="fas fa-file-alt" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.countStaffMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        </Row>
        </Col>
        <Col sm="12" md="5">
          <Row>
          <Col sm="12" md="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total de Vacantes
                          </CardTitle>
                  <span className="h2 font-weight-bold mb-0">{data.countVacant}</span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                    <i className="fas fa-file" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.countVacantMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>

        </Col>
        <Col sm="12" md="6" >
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total centros de costos
                          </CardTitle>
                  <span className="h2 font-weight-bold mb-0">{data.countNewCecoPosition}</span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                    <i className="fas fa-file-import" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-clock" /> {data.countNewCecoPositiontMonth}
                </span>{" "}
                <span className="text-nowrap">En el presente mes.</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        </Row>
        </Col>
      </Row>
    </div>
  );
};

CardTransaction.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CardTransaction;