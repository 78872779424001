import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const allOrdersData = handleActions(
  {
    [constants.SO_ALL_ORDERS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  { orders: [] }
);

export const ordersByCountryData = handleActions(
  {
    [constants.SO_COUNTRY_ORDERS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const ordersByUserData = handleActions(
  {
    [constants.SO_USER_ORDERS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  []
);

export const orderDetailsData = handleActions(
  {
    [constants.SO_ORDER_DETAILS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
  },
  {
    assigned: {
      name: null,
      email: null,
    },
    ticketInfo: { ORDER_DETAIL: { item: [{ DESCRIPTION: "" }] } },
  }
);
