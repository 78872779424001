/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Communication Support Administrator en la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React, { useState, useEffect } from "react";
import { Card, CardTitle, Label, Button, ButtonGroup, Col, Row, Input, FormGroup, FormFeedback } from "reactstrap";

const CommunicationSupportAdministrator = ({ requiredJson, setData, data, disabledFieldsJson, isVisible, enableComments }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif' };
    const groupStyle = { marginBottom: '0.1rem', fontFamily: 'sans-serif' };
    const labelStyle = { height: '1.5em', marginBottom: '0.1rem', fontFamily: 'sans-serif' };

    const formFields = [
        {
            type: "buttonGroup",
            label: "Teléfono",
            id: "phoneType",
            options: ["Sí", "No"],
            value: ["SI", "NO"],
            colWidth: 4,
            required: true,
            disabled: false,
        },
        {
            type: "text",
            label: "Modelo de Teléfono",
            id: "phoneModel",
            maxLength: 27,
            colWidth: 4,
            required: false,
            disabled: true,
        },
        {
            type: "text",
            label: "Extensión",
            id: "phoneExt",
            colWidth: 4,
            maxLength: 10,
            required: false,
            disabled: true,
        },
        {
            type: "text",
            label: "Mac Address",
            id: "macAddress",
            colWidth: 4,
            maxLength: 15,
            required: false,
            disabled: true,
        },
        {
            type: "textarea",
            label: "Observaciones (máx 500 caracteres)",
            id: "csaComments",
            maxLength: 500,
            colWidth: 12,
            required: false,
            disabled: enableComments,
        },
        {
            type: "text",
            label: "Completado:",
            id: "completed",
            colWidth: 4,
            required: false,
            disabled: true,
        }
    ];

    const comments = formFields.find(form => form.id === "csaComments");

    const [isDisabled, setIsDisabled] = useState(false);
    const [phoneData, setPhoneData] = useState("");

    useEffect(() => {
        if (data.phoneType === "SI") {
            setPhoneData({ phoneExt: data.phoneExt, phoneModel: data.phoneModel, macAddress: data.macAddress })
        } else {
            setPhoneData({ phoneExt: "", phoneModel: "", macAddress: "" })
        }

        setData((prevState) => ({ ...prevState, phoneExt: data.phoneExt, phoneModel: data.phoneModel, macAddress: data.macAddress }));

    }, [data.phoneType]);

    const handleButtonOnChange = (id, disabled, e) => {
        if (!disabled) {
            e.persist();
            if (id === 'phoneType' && e.target.value === 'NO') {
                setIsDisabled(true);
                setPhoneData(prevData => ({ ...prevData, phoneModel: "", phoneExt: "", macAddress: "" }));
                setData(prevState => ({ ...prevState, phoneModel: "", phoneExt: "", macAddress: "", [id]: e.target.value }));
            } else if (id === 'phoneType' && e.target.value === 'SI') {
                setIsDisabled(false);
                setData(prevState => ({ ...prevState, [id]: e.target.value }));
            } else {
                setPhoneData(prevData => ({ ...prevData, [id]: e.target.value }));
                setData(prevState => ({ ...prevState, [id]: e.target.value }));
            }
        }
    };

    useEffect(() => {
        if (!data.phoneType) {
            setData(prevState => ({ ...prevState, phoneType: 'NO'  }));
        }
    }, [data.phoneType, setData]);


    //RETURN
    const disabled = disabledFieldsJson && disabledFieldsJson.includes("CommunicationSupportAdministrator");
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Communication Support Administrator
                    </CardTitle>

                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={formFields[0].colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={formFields[0].id} style={formFields[0].labelStyle || labelStyle}>
                                    {formFields[0].label}
                                    {formFields[0].required && <span style={{ color: 'red' }}> *&nbsp;</span>}
                                </Label>
                                <ButtonGroup
                                >
                                    {formFields[0].options.map((option, i) => (
                                        <Button
                                            key={`${formFields[0].id}-${i}`}
                                            id={formFields[0].id}
                                            value={formFields[0].value[i]}
                                            outline
                                            color={data[formFields[0].id] === formFields[0].value[i] ? (option === 'Sí' ? 'success' : 'danger') : 'light'}
                                            onClick={(e) => handleButtonOnChange(formFields[0].id, disabled, e)}
                                            active={data[formFields[0].id] === formFields[0].value[i]}
                                        >
                                            {option}
                                        </Button>

                                    ))}
                                </ButtonGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row style={{ padding: '0px 25px 0px' }}>
                        {formFields.slice(1, 4).map((formField, index) => (
                            <Col key={index} xs={formField.colWidth}>
                                <FormGroup style={{ ...groupStyle }}>
                                    <Label htmlFor={formField.id} style={formField.labelStyle || labelStyle}>
                                        {formField.label}
                                        {formField.required && <span style={{ color: 'red' }}> * </span>}
                                    </Label>
                                    <Input
                                        key={formField.id}
                                        type={formField.type}
                                        id={formField.id}
                                        invalid={requiredJson && requiredJson.includes(formField.id)}
                                        maxLength={formField.maxLength}
                                        disabled={(isDisabled || data.phoneType === "NO") || ((disabledFieldsJson && disabledFieldsJson.includes("CommunicationSupportAdministrator")))}
                                        className="form-control"
                                        value={phoneData[formField.id] || ""}
                                        onChange={(e) => handleButtonOnChange(formField.id, false, e)}
                                    />
                                    <FormFeedback>
                                        Este campo no puede estar vacío
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        ))}
                    </Row>

                    {/* comments */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={comments.colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={comments.id} style={comments.labelStyle || labelStyle}>
                                    {comments.label}
                                    {comments.required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    key={comments.id}
                                    type={comments.type}
                                    id={comments.id}
                                    maxLength={comments.maxLength}
                                    value={data[comments.id]}
                                    disabled={!comments.disabled && (disabledFieldsJson && disabledFieldsJson.includes("CommunicationSupportAdministrator"))}
                                    className="form-control"
                                    onChange={(e) => handleButtonOnChange(comments.id, false, e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* aprobador */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={formFields[5].colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={formFields[5].id} style={formFields[5].labelStyle || labelStyle}>
                                    {formFields[5].label}
                                    {formFields[5].required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    type={formFields[5].type}
                                    id={formFields[5].id}
                                    disabled
                                    className="form-control"
                                    value={data.csaApprover}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );
}
export default CommunicationSupportAdministrator;