/*!

=========================================================
*Autopp - ClientForm
=========================================================

* Este componente extrae los clientes, y en base al cliente consulta 
un Webservice que extrae los contactos, porteriormente
si el país del cliente coincide con Organización de ventas y servicios, 
lo asigna automáticamente, de lo contrario despliega las opciones para que 
el user lo asigne.
*Posee validaciones para que todas estas reglas sean cumplidas, además que 
asigne o limpie las opciones dependiendo de si el cliente fue asignado o borrado.

*
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de componentes de React.
import React, { useContext, useState, useEffect } from 'react'
import { Col, FormGroup, Input, Row, Form, Button } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";

//Import de Spinner
import { Spin, Divider } from "antd";

//Import de componente de contexto para variables globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de actions para extraer los contactos cada que el cliente cambie.
import { getContacts } from 'actions/autoppLdrs';
import { useDispatch } from 'react-redux';


export const ClientForm = React.memo(() => {

  //#region configuración del título
  const [state] = useState({
    title: "Cliente y organización",
    subtitle: "A continuación se solicitan datos como contacto y la organización."
  });
  const { title, subtitle } = state;
  //#endregion

  //#region States Globales
  const {
    refTypeaheadClientForm,
    setCleanTypeaheads,
    loadingCostumers,
    costumersOptions,
    cleanTypeaheads,
    dropDownOptions,
    readyToSend,
    setOptions,
    editData,
    options,
    setClientInfo,
  } = useContext(UserContext);
  //#endregion

  //#region States Locales
  const [loadingLocalContacts, setLoadingLocalContacts] = useState(false);
  const [salesServOrgLocked, setSalesServOrgLocked] = useState(true);
  const [fieldsForm] = useState([
    {
      colWidth: "6",
      label: "Cliente de la oportunidad*:",
      placeholder: "Busqueda de Cliente",
      id: 'costumers',
      defaultValue: 'Standard',
      type: "typeahead",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Contactos del cliente*:",
      id: "contacts",
      type: "select",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Organización de Ventas*:",
      id: "salesOrganizations",
      type: "select",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Organización de servicios*:",
      id: "servicesOrganizations",
      type: "select",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Información del Cliente",
      id: "titleClientInformation",
      type: "title",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Ejecutivo de la solicitud*:",
      placeholder: "Ingrese el nombre",
      id: 'requestExecutive',
      type: "input",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Posición del Ejecutivo*:",
      placeholder: "Ingrese la posición",
      id: 'positionExecutive',
      type: "input",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Correo electrónico del ejecutivo*:",
      placeholder: "Ingrese el correo",
      id: 'emailExecutive',
      type: "input",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Número de Teléfono*:",
      placeholder: "Ingrese el número",
      id: 'phoneExecutive',
      type: "input",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Dirección de entrega del servicio*:",
      placeholder: "Ingrese el nombre",
      id: 'deliveryAddress',
      type: "textarea",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Horario de Atención*:",
      placeholder: "Ingrese el horario",
      id: 'openingHours',
      type: "input",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Dirección de la Página web del cliente (Opcional):",
      placeholder: "Ingrese la página",
      id: 'clientWebSide',
      type: "input",
      required: false,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Requerimiento general",
      id: "titleGeneralRequirement",
      type: "title",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "¿Existe un problema o dolor asociado?*:",
      placeholder: "Ingrese el problema",
      id: 'clientProblem',
      type: "input",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "¿Cuál es la necesidad básica?*:",
      placeholder: "Ingrese la necesidad",
      id: 'basicNecesity',
      type: "input",
      required: true,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "¿Tiene alguna expectativa para cuándo requiere la solución operativa?:",
      placeholder: "Ingrese expectativa",
      id: 'expectationDate',
      type: "input",
      required: false,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "¿Se cuenta actualmente con una solución que cumpla (parcial/total) estos requerimientos?:",
      placeholder: "Ingrese la solución",
      id: 'haveAnySolution',
      type: "input",
      required: false,
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Notas Adicional:",
      placeholder: "Ingrese notas",
      id: 'anothersNotes',
      type: "textarea",
      required: false,
      disabled: false,
    },

  ]);
  //const [valuesSelected, setValuesSelected] = useState();

  //State Local de datos para cuando esté listo para enviar, lo agregue al state principal
  const [newInfo, setNewInfo] = useState("");

  //#endregion

  //#region Traer Contactos
  const dispatch = useDispatch();
  useEffect(() => {

    const getContacts = async () => {
      await handleOnGetContacts();
    }

    getContacts();

  }, [newInfo.costumers]);

  //Función para traer los contacts desde SAP.
  const handleOnGetContacts = async () => {

    setLoadingLocalContacts(true);
    let idCustomer = "";
    if (newInfo.costumers) {
      idCustomer = newInfo.costumers.idClient

    } else {
      idCustomer = ""
    }

    try {
      ///Para que cuando inicie por primera vez, no ejecute el dispatch
      if (newInfo.costumers.name !== "") {
        dispatch(getContacts({ info: idCustomer, type: "2" })).then((resp) => {
          const { payload } = resp;

          if (payload.status === 200) {
            setOptions(prevState => ({
              ...prevState,
              contacts: payload.data.payload.response,
            }),);

            const contactDefault = payload.data.payload.response[0];
            if (contactDefault) {

              setNewInfo(prevState => ({
                ...prevState,
                contactName: `${contactDefault.FIRST_NAME} ${contactDefault.LAST_NAME}`,
                contactId: contactDefault.ID_CONTACT_CRM
              }))


            }
          };

          setLoadingLocalContacts(false);
        });
      } else {
        setLoadingLocalContacts(false);
      }

    } catch (e) { //Este catch cae porque no existe costumers.name ya que fue borrado al borrar el costumet, por tanto se elimina el contactSelection

      setOptions(prevState => ({
        ...prevState,
        contacts: [],
      }))


      setNewInfo(prevState => ({
        ...prevState,
        contactName: '',
        contactId: ''
      }))


      setLoadingLocalContacts(false);
    }


  };

  //#endregion

  //#region Funciones relacionadas a Sales y Services organizations

  //En caso que el pais coincida, asigna las opciones de serv y sales org automáticamente en EditData
  useEffect(() => {

    try {

      const salesOrganizationsOptions = dropDownOptions.salesOrganizations.filter(e => e.country === newInfo.costumers.country);
      const servicesOrganizationsOptions = dropDownOptions.servicesOrganizations.filter(e => e.country === newInfo.costumers.country)

      //Verifica si el country del client existe en sales o serv organizations.
      salesOrganizationsOptions.length === 1 && servicesOrganizationsOptions.length === 1 ? (setSalesServOrgLocked(true)) : (setSalesServOrgLocked(false));


      setNewInfo(prevState => ({
        ...prevState,
        ["salesOrganizations"]: salesOrganizationsOptions[0]
      }))

      setNewInfo(prevState => ({
        ...prevState,
        ["servicesOrganizations"]: servicesOrganizationsOptions[0]
      }))


    } catch (e) {

      setNewInfo(prevState => ({
        ...prevState,
        ["salesOrganizations"]: ""
      }))

      setNewInfo(prevState => ({
        ...prevState,
        ["servicesOrganizations"]: ""
      }))
    }
  }, [newInfo.costumers])




  //Asigna a serv o sales org la opción de EditData en base a al pais.
  const asignOption = (constant) => {

    try {


      if (constant === "salesOrganizations") {
        if (newInfo["salesOrganizations"].code !== undefined) {
          return newInfo["salesOrganizations"].code + " - " + newInfo["salesOrganizations"].name
        } else { return "" }
      } else if (constant === "servicesOrganizations") {
        if (newInfo["servicesOrganizations"].name !== undefined) {
          return newInfo["servicesOrganizations"].servOrgId + " - " + newInfo["servicesOrganizations"].name
        } else { return "" }
      }
    } catch (e) { return "" }
  }


  //dropdown de opciones para sales o serv org en caso de que el pais no coincida.
  const getOptions = (constant) => {

    if (constant === "salesOrganizations") {

      return dropDownOptions[constant] !== undefined && (dropDownOptions[constant].map((item) => {

        return (

          <option value={item.id}>{item.code + " - " + item.name}</option>

        );
      }))
    } else if (constant === "servicesOrganizations") {


      return dropDownOptions[constant] !== undefined && (dropDownOptions[constant].map((item) => {

        return (
          <option value={item.id}>{item.servOrgId + " - " + item.name}</option>
        );
      }))

    }
  }

  //#endregion

  //#region Efecto para cuando el form esté listo para enviar, que lo agregue al state principal editData.

  //Cuando esté listo para enviar, que lo agregue al stateprincipal editData.
  useEffect(() => {
    if (readyToSend)
      {
        // Object.assign(editData, newInfo)
        // setFlagDataReady({ ...flagDataReady, client: true })

        setClientInfo(newInfo)

      }
  }, [readyToSend])

  //#endregion

  //#region Handles para inserts
  const handleChangeInfo = (constant, e) => {
    //console.log(constant + e);
    if (constant === "contactId") {

      setNewInfo(prevState => ({
        ...prevState,
        contactName: e.value,
        contactId: `${e.id}`
      }))

    }

    else if (constant === "salesOrganizations" || constant === "servicesOrganizations") {

      setNewInfo(prevState => ({
        ...prevState,
        [constant]: {
          "id": e.value
        }
      }))


    } else { //Clients    

      setNewInfo(prevState => ({
        ...prevState,
        [constant]: e
      }))
    }
  }
  //#endregion

  //#region Limpiar el typeahead.

  useEffect(() => {
    if (cleanTypeaheads === true) {
      setCleanTypeaheads(false);
      refTypeaheadClientForm.current.clear();
      setNewInfo([{ "contactName": "", "contactId": "", "salesOrganizations": "", "servicesOrganizations": "" }]);
    }
  }, [cleanTypeaheads])// El cleanTypeAheads viene del componente CardSelectForm cuando ya envia la gestión.

  const handleResetClient = (event) => {
    console.log(event)
    event.preventDefault();
    refTypeaheadClientForm.current.clear();
    setNewInfo([{ "contactName": "", "contactId": "", "salesOrganizations": "", "servicesOrganizations": "" }]);
  }

  //#endregion

  return (

    <>
      <Row className="mb-4" >

        <Col xs="12" sm='6'>
          <h3 className="mb-0">{title}</h3>
          <p className="text-sm mb-0">{subtitle}</p>

        </Col>
      </Row>

      <Form noValidate className="formInfo needs-validation">

        <Row className="">
          {fieldsForm.map((row, key) =>
            row.type === "title" ? (
              //Titles
              <Col xs="12" sm="12" key={key}>
     
                      <Divider><h3>{row.label}</h3></Divider>

              </Col>

            )
              : row.type === "typeahead" ? (
                //Costumers
                <Col xs="12" sm={row.colWidth} key={key}>
                  <FormGroup >
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`Cliente*:`}
                    </label>

                    <Spin size="small" spinning={loadingCostumers} key={key}>
                      <Row className=" ml-1 p--1 " style={{ display: "flex", justifyContent: "flex-center" }} key={key + "row"}>

                        <Col xs="10" sm="10" className=" p-0" key={key} >

                          <Typeahead
                            key={row.id + key}
                            id={row.id}
                            className="text-dark"
                            labelKey={costumersOptions => `${costumersOptions.name}`}
                            minLength={0}
                            required={row.required}
                            ref={refTypeaheadClientForm}
                            onChange={(e) => handleChangeInfo("costumers", e[0])}
                            options={costumersOptions}


                            renderMenuItemChildren={(value) => (
                              <div >
                                {value.name}
                                <div>
                                  {
                                    <small>País: {value.countryName} - Id: {value.idClient}</small>
                                  }
                                </div>
                              </div>
                            )}
                          />
                        </Col>

                        <Col xs="2" sm="2" className=" p-0" style={{ display: "flex", justifyContent: "flex-start" }} key={key + "col"}>
                          <Button
                            id="resetClient"
                            key={"resetBtn" + key}
                            color="danger"
                            className="btn-icon"
                            style={{ display: "flex", justifyContent: "flex-end" }}
                            onClick={(e) => handleResetClient(e)}
                          >
                            <span className="btn-inner--icon mr-" key={key}>
                              <i className="fa fa-times" />
                            </span>
                          </Button>


                        </Col>

                      </Row>

                    </Spin>
                    <div className="invalid-feedback">
                      Este campo no puede estar vacío
                    </div>
                  </FormGroup>
                </Col>

              ) : row.id === "contacts" ?
                (
                  <Col xs="12" sm={row.colWidth} key={key}>
                    <Spin size="small" spinning={loadingLocalContacts} key={key}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {" "}
                          {`Contacto*:`}
                        </label>
                        <Input
                          id={row.id}
                          key={key}
                          type={"select"}
                          className="basic-multi-select text-dark"
                          //value={editData["contactName"]}
                          required={row.required}
                          onChange={(e) =>
                            handleChangeInfo(
                              "contactId",
                              e.target.children[e.target.selectedIndex]
                            )
                          }
                        >
                          <option id=""></option>
                          {

                            options["contacts"] !== undefined && (options["contacts"].map((value, key) => {
                              return (
                                <option
                                  key={key}
                                  id={value.ID_CONTACT_CRM}
                                >{`${value.FIRST_NAME} ${value.LAST_NAME}`}</option>
                              );
                            }))

                          }
                        </Input>
                        <div className="invalid-feedback">
                          Este campo no puede estar vacío
                        </div>
                      </FormGroup>
                    </Spin>
                  </Col>)
                : ["servicesOrganizations", "salesOrganizations"].includes(row.id) ? (
                  //Campos de salesOrganizations y serviceOrganization
                  salesServOrgLocked === true ? (
                    //input seleccionado automaticamente
                    <Col xs="12" sm={row.colWidth} key={key}>
                      <FormGroup key={key}>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${row.label}`}
                        </label>
                        <Input
                          id={row.id}
                          key={key}
                          type={"input"}
                          className="basic-multi-select text-dark"
                          value={asignOption(row.id)}
                          disabled={true}

                        />
                      </FormGroup>
                      <div className="invalid-feedback">
                        Este campo no puede estar vacío
                      </div>
                    </Col>
                  ) :
                    //Select salesOrganizations y serviceOrganization porque no coincide el país.
                    (<Col xs="12" sm={row.colWidth} key={key}>

                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${row.label}`}
                        </label>
                        <Input
                          key={key}
                          id={row.id}
                          type={"select"}
                          className="basic-multi-select text-dark"
                          required={row.required}
                          //value={handleGetValueField(row.id)}

                          onChange={(e) =>
                            handleChangeInfo(
                              row.id,
                              e.target.children[e.target.selectedIndex]
                            )
                          }
                          disabled={row.disabled}
                        >
                          {<option id=""></option>}
                          {getOptions(row.id)}

                        </Input>
                        <div className="invalid-feedback">
                          Este campo no puede estar vacío
                        </div>
                      </FormGroup>
                    </Col>
                    )
                )
                  : //Campos de tipo input
                  (
                    <Col xs="12" sm={row.colWidth} key={key}>
                      <FormGroup key={key}>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${row.label}`}
                        </label>
                        <Input
                          key={key}
                          id={row.id}
                          name={row.id}
                          required={row.required}
                          placeholder={row.placeholder}
                          className="form-control text-dark"
                          readOnly={row.disabled}
                          onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                          type={row.type}
                        />
                        <div className="invalid-feedback">
                          Este campo no puede estar vacío
                        </div>
                      </FormGroup>
                    </Col>
                  )
          )}
        </Row>
      </Form>
      {/*JSON.stringify(newInfo)*/}
    </>
  )
})

export default ClientForm;
