import React, { useState } from "react";
import Select from "react-select";
import { Card, CardBody, CardFooter, Col, FormGroup, Input, Row, Table, PaginationItem, PaginationLink, Pagination } from "reactstrap";
import { urlBase } from "api/urls.jsx";
import { encrypter } from 'helpers/desencrypt.jsx';

const AgreementCREditFormThree = ({
  info,
  handleChangeInfo,
  evaluations,
  options,
  isDisabled,
  files,
  Toast
}) => {



  const rowsModal = [
    {
      colWidth: "4",
      label: "Recepción de extemporáneas",
      id: "receptionExt",
      type: "text",
      disabled: true
    },
    {
      colWidth: "2",
      label: "Mejora de Precios",
      id: "improvePrices",
      type: "text",
      disabled: true
    },
    {
      colWidth: "2",
      label: "Cartel Trabajado",
      id: "workPoster",
      type: "select"
    },
    {
      colWidth: "4",
      label: "Motivo de no participacion",
      id: "noParticipationReason",
      type: "select"
    },
    {
      colWidth: "12",
      label: "Justificacion de no participacion",
      id: "notParticipate",
      type: "text"
    },
  ];
  const rowsModalFooter = [
    {
      colWidth: "12",
      label: "Observaciones",
      id: "comment",
      type: "text"
    },
  ];
  const colTable = [
    {
      value: "numFactor",
      label: "Numero de Factor",
    },
    {
      value: "evaluationPercent",
      label: "Porcentaje de evaluacion (%)",
    },
    {
      value: "evaluationFactor",
      label: "Factores de Evaluacion",
    },
    {
      value: "referenceValue",
      label: "Valor de Referencia",
    }
  ];
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 3,
    idFileRequest: "",
  })
  const { page, sizePerPage } = state;

  const paginations = files.length > sizePerPage ?
    files.length / sizePerPage > Math.round(files.length / sizePerPage) ?
      Math.round(files.length / sizePerPage) + 1
      : Math.round(files.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink href="#"
            onClick={(e) => handleOnSetPage(e, i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  //Cambiar de pagina
  const handleOnSetPage = (event, page, who) => {

    setState((prevState) => ({
      ...prevState,
      [who]: page
    }))
  };

  const handleDownload = (e, bidNumber, file) => {

    const downloadUrl = `${urlBase}/costa-rica-bids/download-file/${encrypter(bidNumber)}/${encrypter(file)}`;

    fetch(downloadUrl, {
      method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
    })
      .then(response => {
        if (response.ok) {
          window.location.assign(downloadUrl);
        } else {
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
            type: "warning",

          });
        }
      })
      .catch(error => {
        console.error('Error checking file existence:', error);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error al validar el archivo ",
          type: "warning",

        });
      });
  }
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            {rowsModal.map((row, key) =>
              row.type === "text" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      disabled={row.disabled === true ? true : isDisabled}
                      key={key}
                      className="form-control"
                      type={row.type}
                      defaultValue={info[row.id] || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : row.type === "select" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Select
                      key={`input${key}`}
                      isDisabled={isDisabled}
                      className="basic-single"
                      type="select"
                      isSearchable
                      defaultValue={{ label: info[row.id], value: 1 } || ""}
                      options={options[row.id]}
                      onChange={(e) => handleChangeInfo(row.id, e.value)}
                    />
                  </FormGroup>
                </Col>
              ) : (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      disabled={isDisabled}
                      key={key}
                      type={row.type}
                      className="basic-multi-select text-dark"
                    ></Input>
                  </FormGroup>
                </Col>
              )
            )}
          </Row>
        </CardBody>
        <CardFooter>
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">
              <tr>
                {colTable.map((row, key) => {
                  return (
                    <th key={key} className="justify-content-md-center">
                      {row.label}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="list">
              {evaluations.map((row, key) =>
                <tr key={key} className="text-center">
                  {colTable.map((col, key) => (
                    <td key={key} className="text-center">
                      {
                        row[col.value]
                      }
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </Table>
          <Table
            className="align-items-center table-flush mt-5"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">
              <tr>
                <th>
                </th>
                <th className="text-center">
                  Gárantia
                </th>
                <th className="text-center">
                  Monto
                </th>
              </tr>
            </thead>
            <tbody className="list">
              <tr className="text-center">
                <td className="text-center">PARTICIPACIÓN</td>
                <td className="text-center">{info.participationWarranty}</td>
                <td className="text-center">{info.participationAmount}</td>
              </tr>
              <tr className="text-center">
                <td className="text-center">CUMPLIMENTO</td>
                <td className="text-center">{info.complianceWarranty}</td>
                <td className="text-center">{info.complianceAmount}</td>
              </tr>
            </tbody>
          </Table>
          <Row>
            {rowsModalFooter.map((row, key) =>
              <Col xs={row.colWidth} key={key}>
                <FormGroup key={key}>
                  <label
                    style={{ fontSize: "12px" }}
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    {`${row.label}:`}
                  </label>
                  <Input
                    id={row.id}
                    key={key}
                    disabled={isDisabled}
                    className="form-control"
                    type={row.type}
                    value={info[row.id] || ""}
                    onChange={(e) =>
                      handleChangeInfo(row.id, e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
            style={{ marginTop: "7px" }}
          >
            <thead className="thead-light">
            </thead>
            <tbody className="list">
              {
                files.length ?
                  files.slice((page - 1) * sizePerPage, page * sizePerPage).map((file, key2) => (
                    <tr key={key2}>
                      <td>
                        <a
                          
                          onClick={(e) => handleDownload(e, info.bidNumber, file)}
                        >
                          {file}
                        </a>
                      </td>
                    </tr>
                  ))
                  :
                  <>
                    <tr>
                      <td className="justify-content-md-center">
                        No tiene documentos asociados
                      </td>
                    </tr>
                  </>
              }
            </tbody>
          </Table>
          {files.length > 0 && (

            <nav aria-label="...">
              <Row className="align-items-center">
                <Col xs='12' md='12' className="p-1">
                  <Pagination
                    style={{ marginLeft: "30px" }}
                    className="pagination "
                    listClassName=" mb-0"
                  >
                    {
                      renderPaginations()
                    }
                  </Pagination>
                </Col>
              </Row>
            </nav>
          )}


        </CardFooter>
      </Card>
    </div>
  );
};

export default AgreementCREditFormThree;
