/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Altas" del boton de Mantenimiento en el cual se realiza el alta del freelance en SAP y Nuevo BPM
    
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    MySwal: funcion para las notificaciones personalizadas
    setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

// react library
import React, { useState } from "react";
// reactstrap components
import { Button, Card, CardBody, Col, Row } from "reactstrap";
//usedispatch for fetch
import { useDispatch } from "react-redux";
//customized Components
import FreelanceAltas from "components/Freelance/SharedComponents/FreelanceAltas.jsx";
import MainTable from "components/Freelance/SharedComponents/MainTable.jsx";
//acciones para Eliminar, bloquear el acceso y generar una nueva contraseña
import { approvedAltaRequest, getRows } from "actions/Freelance.jsx";
//api urls for download
import urls from "api/urls.jsx";
//downloadFile
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

import { encrypter } from 'helpers/desencrypt.jsx';

const Altas = ({
  isAlta,
  setLoad,
  Toast,
  dropDownOptions,
  setDropDownOptions,
  rolArea,
  isAdmin,
  MySwal,
  setRefreshOptions,
}) => {
  //#region Functions

  //#region estado para guardar las opciones de SubArea
  const [subareaOptions, setSubareaOptions] = useState([]);

  //#region State para guardar los filtros seleccionados según cada tabla en FilterMain
  const [search, setSearch] = useState({});
  //#endregion

  //#region state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);
  //#endregion

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#regionstate para recargar la tabla principal cuando se necesite
  const [reloadTable, setRealTable] = useState(false);
  //#endregion

  //#region funcion para buscar los accesos
  const handleSearch = () => {
    setPage(1);
    setRealTable((prevState) => !prevState);
  };
  //#endregion

  // #region state y funcion para abrir el modal de los proveedores
  const [accessModal, setModal] = useState({
    row: {},
    showModal: false,
    title: "",
    type: "",
  });
  const { row, showModal, title, type } = accessModal;
  //#endregion

  //#region Funcion para abrir el modal
  const openCreateModalProveedor = (e, type, row, dropDown) => {
    let actualRow = row;
    if (type === 2) {
      const newOptions = dropDown["SubArea"].filter(x => x.companyCode === row.companyCode);
      setSubareaOptions(newOptions);

    }
    else if (type === 1) {
      const formattedDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];
      actualRow["hireDate"] = formattedDate;
      actualRow["contractEndDate"] = "9999-12-31";
    }
    setModal((prevState) => ({
      ...prevState,
      row: actualRow,
      showModal: !prevState.showModal,
      title: (type === 1) ? "Dar Alta Freelance" : "Editar Alta Freelance",
      type: type,
    }));
  };

  const openModalAltaCreate = (e) => {
    openCreateModalProveedor(e, 1, {}, undefined); //crear
  }
  const openModalAltaEdit = (e, row, dropDown) => {
    openCreateModalProveedor(e, 2, row, dropDown); //editar
  }
  //#endregion

  const downloadReport = () => {
    setLoad(true);
    dispatch(getRows({ filters: {}, table: "altas" })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        const data = payload.data.rows;

        const filteredOptions = optionsSelect.filter(option => option.value !== "actions");
        // Add custom column titles to the worksheet
        const headers = filteredOptions.map((column) => column.label);
        worksheet.addRow(headers);

        // Filter data based on column titles and add data rows to the worksheet
        data.forEach((item) => {
          const row = filteredOptions.map((column) => item[column.value]);
          worksheet.addRow(row);
        });

        // Generate and save the Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, "Altas Freelance " + new Date().toISOString().slice(0, 10));
        });
      }
      else if (payload.status === 404) {
        Toast.fire({
          title: "Atención",
          html: "No se encontrarón registros para el filtro seleccionado",
          type: "warning",
        });
      }
      else {
        Toast.fire({
          title: "Atención",
          html: "Error inesperado, por favor comuniquese con Application Managment",
          type: "warning",
        });
      }
      setLoad(false);
    });

  };

  //#region campo botones superiores
  const buttons = [
    {
      color: "primary",
      icon: "fas fa-search",
      title: "Buscar",
      function: handleSearch,
      colWidth: "4",
    },
    {
      color: "success",
      icon: "fa fa-plus",
      title: "Dar de Alta",
      function: openModalAltaCreate,
      colWidth: "4",
    },
    {
      color: "info",
      icon: "fa fa-download",
      title: "Descargar Altas",
      type: "download",
      function: downloadReport,
      colWidth: "4",
    },
  ];
  //#endregion

  //#region Funcion para aprobar una solicitud de alta de freelance
  const approveRequest = (e, row, dropDown) => {

    MySwal.fire({
      title: `Desea aprobar el alta de ${row["fullName"]}?`,
      // text: "Se puede reversar con ayuda de Application Management",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Si, aprobar.`,
      confirmButtonColor: `#2dce89`,
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          row: row,
          action: 1,
        };
        dispatch(approvedAltaRequest({ info })).then((resp) => {
          const { payload } = resp;
          console.log(resp)
          if (payload.status === 200) {
            Toast.fire({
              title: "Atención",
              html: `Se aprobó con éxito.`,
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.response.data.message,
              type: "warning",
            });
          }
          setRealTable((prevCheck) => !prevCheck);
          setLoad(false);
        });
      }
    });
  };
  //#endregion

  //#region Funcion para rechazar una solicitud de alta de freelance
  const rejectRequest = (e, row, dropDown) => {
    MySwal.fire({
      title: `Desea rechazar el alta de ${row["fullName"]}?`,
      html: '<textarea class="form-control" rows="3" id="rejectedReason" placeholder="Ingrese el mensaje de rechazo..."></textarea>',
      confirmButtonText: "Si, rechazar.",
      confirmButtonColor: "#f5365c",
      cancelButtonText: "No, cancelar",
      showCancelButton: true,
      preConfirm: () => {
        setLoad(true);
        if (document.getElementById("rejectedReason").value === "") {
          MySwal.showValidationMessage("la Razón es obligatoria");
          setLoad(false);
        } else {
          var info = {
            row: row,
            action: 2,
            rejectedReason: document.getElementById("rejectedReason").value.toString().replace("'", "")
          };
          dispatch(approvedAltaRequest({ info: info })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {
              Toast.fire({
                title: "Atención",
                html: "Se rechazó con éxito",
                type: "success",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.response.data.message,
                type: "warning",
              });
            }

            setRealTable((prevCheck) => !prevCheck);
            setLoad(false);
          });
        }
      },
    });
  };
  //#endregion

  //#region Funcion para controlar las acciones de los botones en Freelance
  const actions = [

  ];
  //#endregion

  //#region Funcion para descargar el archivo de carga de una(s) solicitudes
  const downloadFileAlta = (e, row, dropDown) => {
    if (row.fileName !== null) {
      const downloadUrl = urls.FREELANCE.downloadAltaFile + `/${encrypter(row.fileName)}`;

      fetch(downloadUrl, {
        method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
      })
        .then(response => {
          if (response.ok) {
            window.location.assign(downloadUrl);
          } else {
            Toast.fire({
              title: "Atención",
              html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
              type: "warning",

            });
          }
        })
        .catch(error => {
          console.error('Error checking file existence:', error);
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error al validar el archivo ",
            type: "warning",

          });
        });
    }
    else {
      Toast.fire({
        title: "Atención",
        html: `Solicitud No Masiva`,
        type: "warning",
      });
    }
  };
  //#endregion

  //#region Funcion para controlar las acciones de los botones en Freelance
  const actionsButtons = [
    {
      color: "secondary",
      icon: "fas fa-edit",
      fun: openModalAltaEdit,
      add: 0,
      title: "Ver Detalle",
      id: "view",
    },
    {
      color: "secondary",
      icon: "ni ni-cloud-download-95",
      fun: downloadFileAlta,
      add: "alta",
      title: "Descargar archivo",
      id: "downloadFile",
    },
    {
      color: "success",
      icon: "fas fa-check",
      fun: approveRequest,
      add: "isAlta",
      title: "Aprobar Creación",
      id: "approveRequest",
    },
    {
      color: "danger",
      icon: "ni ni-fat-remove",
      fun: rejectRequest,
      add: "isAlta",
      title: "Rechazar Creación",
      id: "rejectRequest",
    },
  ];
  //#endregion

  //#region columnas seleccionados de la tabla MainTable
  const [selected, setSelected] = useState([
    { value: "actions", label: "Acciones", disabled: true, buttons: actionsButtons },
    { value: "fullName", label: "Proveedor", disabled: true },
    { value: "countryName", label: "País" },
    { value: "idSap", label: "Id Colaborador" },
    { value: "statusText", label: "Estado", type: "color", optionList: "altasStatus", option: "statusType", val: "status", },
    { value: "createdAtFormat", label: "Fecha de Carga" },
    { value: "createdBy", label: "Solicitante" },
  ]);
  //#endregion

  //#region columnas de la tabla MainTable
  const optionsSelect = [
    { value: "actions", label: "Acciones", disabled: true, buttons: actionsButtons },
    { value: "id", label: "Id" },
    { value: "fullName", label: "Proveedor", disabled: true },
    { value: "countryName", label: "País" },
    { value: "idSap", label: "Id Colaborador" },
    { value: "altaTypeText", label: "Tipo" },
    { value: "fileName", label: "Nombre del Archivo" },
    { value: "personnelSubareaCode", label: "Personal Sub Area" },
    { value: "birthDateFormat", label: "Fecha de Nacimiento" },
    { value: "address", label: "Dirección" },
    { value: "nationalityName", label: "Nacionalidad" },
    { value: "idTypeCode", label: "ID type" },
    { value: "identification", label: "Identificación" },
    { value: "personalEmail", label: "Email" },
    { value: "hireDateFormat", label: "Fecha de Contratación" },
    { value: "contractEndDateFormat", label: "Fecha Fin de Contrato" },
    { value: "companyCodeText", label: "Company Code" },
    { value: "eeSubGroupText", label: "EE Subgroup" },
    { value: "supplier", label: "Supplier" },
    { value: "subAreaName", label: "SubArea" },
    { value: "personnelSubareaName", label: "Personeel SubArea" },
    { value: "sapUserName", label: "Usuario SAP" },
    { value: "statusText", label: "Estado", type: "color", optionList: "altasStatus", option: "statusType", val: "status", },
    { value: "createdAtFormat", label: "Fecha de Carga" },
    { value: "createdBy", label: "Solicitante" },
  ];
  //#endregion

  //#endregion

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            {buttons.map((row, key) => (
              <Col sm={row.colWidth} key={key}>
                <Button
                  className="btn-icon"
                  color={row.color}
                  size="sm"
                  block
                  type="button"
                  onClick={(e) => row.function(e, row)}
                >
                  <span className="btn-inner--icon mr-">
                    <i className={row.icon} />
                  </span>
                  <span className="btn-inner--text">{row.title}</span>
                </Button>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>

      <MainTable
        setPage={setPage}
        page={page}
        reloadTable={reloadTable}
        search={search}
        Toast={Toast}
        dropDownOptions={dropDownOptions}
        selected={selected}
        setSelected={setSelected}
        optionsSelect={optionsSelect}
        tableParameter={"altas"}
        rolArea={rolArea}
        statusError={"NA"}
        isAdmin={isAdmin}
        isAlta={isAlta}
      />

      <FreelanceAltas
        row={row}
        setModal={setModal}
        showModal={showModal}
        dropDownOptions={dropDownOptions}
        setDropDownOptions={setDropDownOptions}
        setRealTable={setRealTable}
        Toast={Toast}
        title={title}
        type={type}
        setRefreshOptions={setRefreshOptions}
        subcontracts={""}
        setSubcontracts={""}
        subareaOptions={subareaOptions}
        setSubareaOptions={setSubareaOptions}
      />
    </>
  );
};

export default Altas;
