import { Col, FormGroup, Input, Row, Button, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
// react-select library for filters
import Select from "react-select";
// react library
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// url variables api
import { urlBase } from "api/urls.jsx";
import { deleteFile } from 'actions/hcmHiring.jsx';
import UploadFilesModal from 'components/HCMHiring/UploadFileModal';
import { encrypter } from 'helpers/desencrypt.jsx';

const EducationForm = ({
    data,
    filesData,
    row,
    handleOnChange,
    isAdmin,
    isManager,
    Toast,
    dropDownOptions
}) => {

    //#region Funciones

    //state para las archivos de la solicitud
    const [files, setFiles] = useState(
        filesData && filesData.length ? filesData : []
    )
    console.log(files)
    const dispatch = useDispatch();

    const [fields, setFields] = useState([
        {
            colWidth: "4",
            elementType: "Select",
            label: "Formación academica",
            placeholder: "Formación academica",
            id: "academicTraining",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "academicTraining",
            disabled: false,
            options: "AcademicTraining",
            value: "AcademicTraining",
            // defaultValueLabel: "academicName",
            defaultValueLabel: "academicName",//dropDownOptions.AcademicTraining.find(el => el.value === data["academicTraining"]).label,
            defaultValueValue: "academicTraining",
        },
        {
            colWidth: "4",
            elementType: "Select",
            label: "Institución Educativa",
            placeholder: "Institución Educativa",
            id: "educationalInstitution",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "educationalInstitution",
            disabled: false,
            options: "EducationalInstitution",
            value: "EducationalInstitution",
            defaultValueLabel: "eduInstiName",
            defaultValueValue: "educationalInstitution",
        },
        {
            colWidth: "4",
            elementType: "Select",
            label: "Grado académico",
            placeholder: "Grado académico",
            id: "academicDegree",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "academicDegree",
            disabled: false,
            options: "AcademicDegree",
            value: "AcademicDegree",
            defaultValueLabel: "academicDegreeName",
            defaultValueValue: "academicDegree",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Nombre del Instituto",
            placeholder: "Nombre del Instituto",
            id: "institutionName",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "institutionName",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Titulo Obtenido",
            placeholder: "Titulo Obtenido",
            id: "degree",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "degree",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        }, 
        {
            colWidth: "4",
            elementType: "Input",
            label: "Fecha de inicio de carrera",
            placeholder: "Fecha de inicio de carrera",
            id: "graduationDate",
            type: "date",
            min: 0,
            max: 1999999999,
            defaultValue: "gDate",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Select",
            label: "Idioma Nativo",
            placeholder: "Idioma Nativo",
            id: "nativeLang",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "nativeLang",
            disabled: false,
            options: "NativeLanguage",
            value: "NativeLanguage",
            defaultValueLabel: "natLangName",
            defaultValueValue: "nativeLang",
        },
        {
            colWidth: "4",
            elementType: "Select",
            label: "Segundo Idioma",
            placeholder: "Segundo Idioma",
            id: "secondLang",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "secondLang",
            disabled: false,
            options: "SecondLanguage",
            value: "SecondLanguage",
            defaultValueLabel: "secLangName",
            defaultValueValue: "secondLang",
        },
        {
            colWidth: "4",
            elementType: "Select",
            label: "Nivel Dominio",
            placeholder: "Nivel Dominio",
            id: "domainLevel",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "domainLevel",
            disabled: false,
            options: "DomainLevel",
            value: "DomainLevel",
            defaultValueLabel: "domainName",
            defaultValueValue: "domainLevel",
        },
        {
            colWidth: "12",
            elementType: "List",
            label: "Documentación",
            id: "CandidateFiles",
            defaultValue: "CandidateFiles",
        },
    ]);


    //#region Pagination

    const [state, setState] = useState({
        page: 1,
        sizePerPage: 3,
        idFileRequest: "",
    })
    const { page, sizePerPage } = state;


    const paginations = files.length > sizePerPage ?
        files.length / sizePerPage > Math.round(files.length / sizePerPage) ?
            Math.round(files.length / sizePerPage) + 1
            : Math.round(files.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink href="#"
                        onClick={(e) => handleOnSetPage(e, i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Cambiar de pagina
    const handleOnSetPage = (event, page, who) => {

        setState((prevState) => ({
            ...prevState,
            [who]: page
        }))
    };
    //#endregion


    //funcion para eliminar un archivo de una solicitud
    const handleDeleteFile = (id, file) => {
        dispatch(deleteFile({ id: id, name: file })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                console.log("Se borró el archivo del BE");
                // const docArray = filesData["CandidateFiles"].split(",");
                const newDocArray = filesData.filter(function (ele) {
                    return ele.name !== file;
                });
                setFiles(newDocArray);
                filesData = newDocArray;

            } else {
                Toast.fire({
                    title: "Atención",
                    html: "Error al intentar borrar el archivo",
                    type: 'error'
                });
            }
        })
    }

    //funcion para abrir el componente de subir archivos nuevos
    const [uploadModal, setUploadModal] = useState({
        modalUploadToggel: false,
    })
    const { modalUploadToggel } = uploadModal

    //funcion para abrir modal de upload
    const handleOnUploadFiles = () => {
        setUploadModal((prevState) => ({
            modalUploadToggel: !prevState.modalUploadToggel
        }));

    }

    
    const handleDownloadAllFiles = (e, id) => {
        const downloadUrl = `${urlBase}/hcm-hiring/download-all-files/${encrypter(id)}`;

        fetch(downloadUrl, {
            method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
        })
            .then(response => {
                if (response.ok) {
                    window.location.assign(downloadUrl);
                } else {
                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
                        type: "warning",

                    });
                }
            })
            .catch(error => {
                console.error('Error checking file existence:', error);
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrio un error al validar el archivo ",
                    type: "warning",

                });
            });
    }

    
    const handleDownloadFile = (e, id, name) => {
        const downloadUrl =   `${urlBase}/hcm-hiring/download-file/${encrypter(id)}/${encrypter(name)}`;

        fetch(downloadUrl, {
            method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
        })
            .then(response => {
                if (response.ok) {
                    window.location.assign(downloadUrl);
                } else {
                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
                        type: "warning",

                    });
                }
            })
            .catch(error => {
                console.error('Error checking file existence:', error);
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrio un error al validar el archivo ",
                    type: "warning",

                });
            });
    }
    //#endregion

    return (

        <>

            <UploadFilesModal
                row={row}
                data={data}
                showModalUpload={modalUploadToggel}
                setShowModalUpload={setUploadModal}
                files={files}
                setFiles={setFiles}
            />
            <Row className="">
                {
                    fields.map((field, key) => (
                        <Col xs={field.colWidth} key={key}>
                            <FormGroup key={key}>
                                <label className="form-control-label" style={field.id === "CandidateFiles" ? { marginRight: "20px" } : { marginRight: 0 }}>{field.label}</label>
                                {field.id === "CandidateFiles" ?
                                    <>

                                        <a
                                            title="Descargar todos los archivos"
                                            id="downloadAllFiles"
                                            
                                            onClick={(e) => handleDownloadAllFiles(e, data.id)}
                                            style={{ marginRight: "5px" }}
                                        >
                                            <span className="btn btn-primary">
                                                <i className="fas fa-download" />
                                            </span>
                                        </a>

                                        <Button title="Subir nuevos archivos" id="uploadFiles" color="info" onClick={() => handleOnUploadFiles()}>
                                            <i className="fas fa-upload" />
                                        </Button>

                                        <Table
                                            className="align-items-center table-flush"
                                            responsive
                                            striped={true}
                                            hover={true}
                                            key={field.id}
                                            style={{ marginTop: "7px" }}
                                        >
                                            <thead className="thead-light">
                                                {/* <tr>
                                                    Documentos
                                                </tr> */}
                                            </thead>
                                            <tbody className="list">
                                                {
                                                    files.length ?
                                                        files.slice((page - 1) * sizePerPage, page * sizePerPage).map((file, key2) => (
                                                            <tr key={key2}>
                                                                <td>
                                                                    <a
                                                                     
                                                                        onClick={(e) => handleDownloadFile(e, data.id, file.name)}
                                                                    >
                                                                        {file.name}
                                                                    </a>
                                                                </td>

                                                                <td>
                                                                    <a onClick={(e) => handleDeleteFile(data.id, file.name)} >
                                                                        <span className="">
                                                                            <i className="fas fa-trash-alt" />
                                                                        </span>
                                                                    </a>
                                                                </td>


                                                            </tr>
                                                        ))
                                                        :
                                                        <>
                                                            <tr>
                                                                <td className="justify-content-md-center">
                                                                    No tiene documentos asociados
                                                                </td>
                                                            </tr>
                                                        </>
                                                }
                                            </tbody>
                                        </Table>

                                        <nav aria-label="...">
                                            <Row className="align-items-center">
                                                <Col xs='12' md='12' className="p-1">
                                                    <Pagination
                                                        className="pagination justify-content-end mb-0"
                                                        listClassName="justify-content-end mb-0"
                                                    >
                                                        {
                                                            renderPaginations()
                                                        }
                                                    </Pagination>
                                                </Col>
                                            </Row>
                                        </nav>
                                    </>
                                    : field.elementType === "Input" ?
                                        <Input
                                            key={field.id}
                                            id={field.id}
                                            className="form-control"
                                            type={field.type}
                                            readOnly={field.disabled}
                                            defaultValue={data && data[field.defaultValue]}
                                            placeholder={field.placeholder}
                                            onChange={(e) => handleOnChange("CandidateEducation", field.id, e.target.value)}
                                        />

                                        : field.elementType === "Select" ?
                                            <Select
                                                key={field.id}
                                                className="basic-single"
                                                id={field.id}
                                                type="select"
                                                placeholder={field.placeholder}
                                                isDisabled={field.disabled}
                                                isSearchable
                                                options={dropDownOptions[field.options]}
                                                defaultValue={{ label: data && data[field.defaultValueLabel], value: data && data[field.defaultValueValue] }}
                                                onChange={(e) => handleOnChange("CandidateEducation", field.id, e.value)}
                                            />

                                            :
                                            <></>
                                }
                            </FormGroup>
                        </Col>
                    ))
                }
            </Row>
        </>
    )
};

export default EducationForm;