//labels Chart
const labels = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
// Colors charts
const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529"
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340"
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent"
};

// options charts Bar
const optionsBarLegend = {
  legend: {
    position: "top",
    display: true,
    // fullWidth: true,
    labels: {
      usePointStyle: false
    }
  },
  tooltips: {
    mode: "index",
    intersect: false
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: false
      }
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          }
        }
      }
    ]
  }
};
const getInterest = (interest, interestType, period) => {
  let newInterest = interest;
  if (interestType === "anual") {
    newInterest /= 12;
  }
  newInterest /= 100.0;
  if (period === "Mensual") {
    // console.log("El interés es mensual");
  } else if (period === "Bimensual") {
    newInterest *= 2;
  } else if (period === "Trimestral") {
    newInterest *= 3;
  } else if (period === "Cuatrimestral") {
    newInterest *= 4;
  } else if (period === "Semestral") {
    newInterest *= 6;
  } else if (period === "Anual") {
    newInterest *= 12;
  }
  return newInterest;
};

const getQuotaValue = (amount, interest, quota, period, interestType) => {
  const newtasa = getInterest(interest, interestType, period);
  const value = amount * ((newtasa * Math.pow(1 + newtasa, quota)) / (Math.pow(1 + newtasa, quota) - 1));
  // return value.toFixed(2);
  return value;
};

const getAmortizacion = (amount, interest, quota, period, interestType) => {
  const valor_de_cuota = getQuotaValue(amount, interest, quota, period, interestType);
  let saldo_al_capital = amount;
  const items = [];

  for (let i = 0; i < quota; i++) {
    let interes = saldo_al_capital * getInterest(interest, interestType, period);
    let abono_al_capital = valor_de_cuota - interes;
    saldo_al_capital -= abono_al_capital;
    const numero = i + 1;

    // interes = interes.toFixed(2);
    // abono_al_capital = abono_al_capital.toFixed(2);
    // saldo_al_capital = saldo_al_capital.toFixed(2);

    const item = [
      numero,
      interes,
      abono_al_capital,
      valor_de_cuota,
      saldo_al_capital
    ];
    items.push(item);
  }
  return items;
};

const getTotalPriceEquipments = (equipments, request) => {
  const { wayPay, idBusinessModel } = request;
  equipments = equipments.map((row) => {
    const totalCost = row.quantity * row.unitCost;
    const totalPriceCM = (totalCost * (row.byServices / 100) * row.quantityYears) + row.provision;
    const totalPriceSC1 = idBusinessModel === 1 ? 0 : (totalCost * (row.byServices / 100) * row.quantityYearsWarranty) + (totalCost * (row.byServicesRemaining / 100) * (row.quantityYears - row.quantityYearsWarranty));
    const shippingCost = totalPriceSC1 * (row.shippingPercent / 100);
    const totalShippingLanded = totalPriceSC1 + shippingCost;
    const upliftCost = totalShippingLanded / (1 - (row.upliftPercent / 100)) - totalShippingLanded;
    const totalUplift = totalShippingLanded + upliftCost;
    const finCost = totalUplift * (row.finPercent / 100);
    const totalPriceSC2 = totalUplift + finCost + row.provision;
    const wayPayNum = wayPay === 'Mensual' ? 12
      : wayPay === 'Bimensual' ? 6
        : wayPay === 'Trimestral' ? 4
          : wayPay === 'Cuatrimestral' ? 3
            : wayPay === 'Semestral' ? 2 : 1;
    const payment = idBusinessModel === 1 ? wayPay === "Un solo pago" ? totalPriceCM : totalPriceCM / (row.quantityYears * wayPayNum)
      : wayPay === "Un solo pago" ? totalPriceSC2 : totalPriceSC2 / (row.quantityYears * wayPayNum);
    row.totalCost = totalCost;
    row.quantityYearsRemaining = row.quantityYears - row.quantityYearsWarranty;
    row.totalPriceSC = totalPriceSC1;
    row.shippingCost = shippingCost;
    row.totalShippingLanded = totalShippingLanded;
    row.upliftCost = upliftCost;
    row.totalUplift = totalUplift;
    row.finCost = finCost;
    row.totalPrice = idBusinessModel === 1 ? totalPriceCM : totalPriceSC2;
    row.payment = payment;
    return row;
  });
  let totalPrice = 0;
  let totalPayment = 0;
  for (const element of equipments) {
    totalPrice += element.totalPrice;
    totalPayment += element.payment;
  }
  return { totalPrice, totalPayment };
};

const getTotalPriceByEquipment = (equipments, request) => {
  const { wayPay, idBusinessModel } = request;
  equipments = equipments.map((row) => {
    const totalCost = row.quantity * row.unitCost;
    const totalPriceCM = (totalCost * (row.byServices / 100) * row.quantityYears) + row.provision;
    const totalPriceSC1 = idBusinessModel === 1 ? 0 : (totalCost * (row.byServices / 100) * row.quantityYearsWarranty) + (totalCost * (row.byServicesRemaining / 100) * (row.quantityYears - row.quantityYearsWarranty));
    const shippingCost = totalPriceSC1 * (row.shippingPercent / 100);
    const totalShippingLanded = totalPriceSC1 + shippingCost;
    const upliftCost = totalShippingLanded / (1 - (row.upliftPercent / 100)) - totalShippingLanded;
    const totalUplift = totalShippingLanded + upliftCost;
    const finCost = totalUplift * (row.finPercent / 100);
    const totalPriceSC2 = totalUplift + finCost + row.provision;
    const wayPayNum = wayPay === 'Mensual' ? 12
      : wayPay === 'Bimensual' ? 6
        : wayPay === 'Trimestral' ? 4
          : wayPay === 'Cuatrimestral' ? 3
            : wayPay === 'Semestral' ? 2 : 1;
    const payment = idBusinessModel === 1 ? wayPay === "Un solo pago" ? totalPriceCM : totalPriceCM / (row.quantityYears * wayPayNum)
      : wayPay === "Un solo pago" ? totalPriceSC2 : totalPriceSC2 / (row.quantityYears * wayPayNum);
    row.totalCost = totalCost;
    row.quantityYearsRemaining = row.quantityYears - row.quantityYearsWarranty;
    row.totalPriceSC = totalPriceSC1;
    row.shippingCost = shippingCost;
    row.totalShippingLanded = totalShippingLanded;
    row.upliftCost = upliftCost;
    row.totalUplift = totalUplift;
    row.finCost = finCost;
    row.totalPrice = idBusinessModel === 1 ? totalPriceCM : totalPriceSC2;
    row.payment = payment;
    return row;
  });
  return equipments.map((row) => {
    const json = {};
    json.id = row.idEquipment;
    json.payment = row.payment;
    return json;
  });
};

const getTotalPriceServicesTss = (services, request) => {
  const { wayPay } = request;
  services = services.map((row) => {
    const totalCost = row.quantityHours * row.unitCost * row.quantityYears;
    const upliftCost = totalCost / (1 - (row.upliftPercent / 100)) - totalCost
    const totalUplift = totalCost + upliftCost;
    const finCost = totalUplift * (row.finPercent / 100);
    const totalPrice = totalUplift + finCost + row.provision + row.viatic;
    const wayPayNum = wayPay === 'Mensual' ? 12
      : wayPay === 'Bimensual' ? 6
        : wayPay === 'Trimestral' ? 4
          : wayPay === 'Cuatrimestral' ? 3
            : wayPay === 'Semestral' ? 2 : 1;
    const payment = wayPay === "Un solo pago" ? totalPrice : totalPrice / (row.quantityYears * wayPayNum);
    row.totalCost = totalCost;
    row.upliftCost = upliftCost;
    row.totalUplift = totalUplift;
    row.finCost = finCost;
    row.totalPrice = totalPrice;
    row.payment = payment;
    return row;
  });
  let totalPrice = 0;
  let totalPayment = 0;
  for (const element of services) {
    totalPrice += element.totalPrice;
    totalPayment += element.payment;
  }
  return { totalPrice, totalPayment };
};

const getTotalPriceByServicesTss = (services, request) => {
  const { wayPay } = request;
  services = services.map((row) => {
    const totalCost = row.quantityHours * row.unitCost * row.quantityYears;
    const upliftCost = totalCost / (1 - (row.upliftPercent / 100)) - totalCost
    const totalUplift = totalCost + upliftCost;
    const finCost = totalUplift * (row.finPercent / 100);
    const totalPrice = totalUplift + finCost + row.provision + row.viatic;
    const wayPayNum = wayPay === 'Mensual' ? 12
      : wayPay === 'Bimensual' ? 6
        : wayPay === 'Trimestral' ? 4
          : wayPay === 'Cuatrimestral' ? 3
            : wayPay === 'Semestral' ? 2 : 1;
    const payment = wayPay === "Un solo pago" ? totalPrice : totalPrice / (row.quantityYears * wayPayNum);
    row.totalCost = totalCost;
    row.upliftCost = upliftCost;
    row.totalUplift = totalUplift;
    row.finCost = finCost;
    row.totalPrice = totalPrice;
    row.payment = payment;
    return row;
  });
  return services.map((row) => {
    const json = {};
    json.id = row.idService;
    json.payment = row.payment;
    return json;
  });
};

const getTotalPriceSpareParts = (spareParts, request) => {
  const { wayPay } = request;
  spareParts = spareParts.map((row) => {
    const totalCost = row.quantity * row.unitCost;
    const shippingCost = totalCost * (row.shippingPercent / 100);
    const totalShippingLanded = totalCost + shippingCost;
    const upliftCost = totalShippingLanded / (1 - (row.upliftPercent / 100)) - totalShippingLanded;
    const totalUplift = totalShippingLanded + upliftCost;
    const finCost = totalUplift * (row.finPercent / 100);
    const totalPrice = totalUplift + finCost + row.provision;
    const wayPayNum = wayPay === 'Mensual' ? 12
      : wayPay === 'Bimensual' ? 6
        : wayPay === 'Trimestral' ? 4
          : wayPay === 'Cuatrimestral' ? 3
            : wayPay === 'Semestral' ? 2 : 1;
    const items = getAmortizacion(totalPrice, row.interest, (row.quantityYears * wayPayNum), wayPay, "anual");
    row.totalCost = totalCost;
    row.shippingCost = shippingCost;
    row.totalShippingLanded = totalShippingLanded;
    row.upliftCost = upliftCost;
    row.totalUplift = totalUplift;
    row.finCost = finCost;
    row.totalPrice = wayPay === "Un solo pago" ? totalPrice : items[0][3] * (wayPayNum * row.quantityYears);
    row.payment = wayPay === "Un solo pago" ? totalPrice : items[0][3];
    return row;
  });
  let totalPrice = 0;
  let totalPayment = 0;
  for (const element of spareParts) {
    totalPrice += element.totalPrice;
    totalPayment += element.payment;
  }
  return { totalPrice, totalPayment };
};

const getTotalPriceByParts = (spareParts, request) => {
  const { wayPay } = request;
  spareParts = spareParts.map((row) => {
    const totalCost = row.quantity * row.unitCost;
    const shippingCost = totalCost * (row.shippingPercent / 100);
    const totalShippingLanded = totalCost + shippingCost;
    const upliftCost = totalShippingLanded / (1 - (row.upliftPercent / 100)) - totalShippingLanded;
    const totalUplift = totalShippingLanded + upliftCost;
    const finCost = totalUplift * (row.finPercent / 100);
    const totalPrice = totalUplift + finCost + row.provision;
    const wayPayNum = wayPay === 'Mensual' ? 12
      : wayPay === 'Bimensual' ? 6
        : wayPay === 'Trimestral' ? 4
          : wayPay === 'Cuatrimestral' ? 3
            : wayPay === 'Semestral' ? 2 : 1;
    const items = getAmortizacion(totalPrice, row.interest, (row.quantityYears * wayPayNum), wayPay, "anual");
    row.totalCost = totalCost;
    row.shippingCost = shippingCost;
    row.totalShippingLanded = totalShippingLanded;
    row.upliftCost = upliftCost;
    row.totalUplift = totalUplift;
    row.finCost = finCost;
    row.totalPrice = wayPay === "Un solo pago" ? totalPrice : items[0][3] * wayPayNum;
    row.payment = wayPay === "Un solo pago" ? totalPrice : items[0][3];
    return row;
  });
  const parts = [];
  let spare = 0;
  for (const element of spareParts) {
    if (element.tipo === 2) {
      parts.push({
        id: element.fk_id_SpareParte,
        payment: element.payment
      });
    } else if (element.tipo === 1) {
      spare += element.payment;
    }
  }
  return [parts, spare];
};

const getTotalPaymentByEquipment = (idBusinessModel, equipments, partsEquipment, paymentEquipments, paymentServices, paymentParts, paymentSpare) => {
  return equipments.map((row) => {
    row.payment = 0;
    for (const element of paymentEquipments) {
      if (element.id === row.id) {
        row.payment += element.payment;
      }
    }
    // for (const element in paymentServices) {
    //   if (paymentServices[element].id === row.idPractice && parseInt(element) < row.amountEquipments) {
    //     row.payment += paymentServices[element].payment;
    //   }
    // }
    const services = paymentServices.filter((ele) => ele.id === row.idPractice);
    for (const element in services) {
      if (parseInt(element) < row.amountEquipments) {
        row.payment += services[element].payment;
      }
    }
    for (const element of paymentParts) {
      const { idE } = partsEquipment.find((ele) => ele.idP === element.id);
      if (idBusinessModel === 1) {
        if (idE === row.idC) {
          row.payment += element.payment;
        }
      } else if (idBusinessModel === 2) {
        if (idE === row.id) {
          row.payment += element.payment;
        }
      }
    }
    row.payment += paymentSpare / equipments.length;
    return row;
  });
};
export const getAllCountries = (state) => {
  const { dataRequestYearAndCountries } = state;
  if (Object.keys(dataRequestYearAndCountries).length) {
    const allCountries = dataRequestYearAndCountries.countries.map((row) => {
      return {
        label: row.countryName,
        value: row.countryCode
      }
    });
    return [{ label: "Todos", value: "NA" }, ...allCountries]
  }
  return []
};
export const getAllYears = (state) => {
  const { dataRequestYearAndCountries } = state;
  if (Object.keys(dataRequestYearAndCountries).length) {
    const allYears = dataRequestYearAndCountries.years.map((row) => {
      return {
        label: row.year,
        value: row.year
      }
    });
    return [...allYears]
  }
  return []
};
export const dataRequestDashboardContracts = state => {
  const dataReqCreate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataWinOferts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataNotConsideretOferts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  if (Object.keys(state.dataRequestDashboardContracts).length) {
    const { graph } = state.dataRequestDashboardContracts;
    const { requestByMonth, wonByMonth, rejectByMonth } = graph;
    if (requestByMonth.length) {
      for (const row of requestByMonth) {
        dataReqCreate[row.month - 1] = row.total;
      }
    }
    if (wonByMonth.length) {
      for (const row of wonByMonth) {
        dataWinOferts[row.month - 1] = row.total;
      }
    }
    if (rejectByMonth.length) {
      for (const row of rejectByMonth) {
        dataNotConsideretOferts[row.month - 1] = row.total;
      }
    }
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Requerimientos creados",
            data: dataReqCreate,
            backgroundColor: colors.theme["info"]
          },
          {
            label: "Ofertas Ganadoras",
            data: dataWinOferts,
            backgroundColor: colors.theme["success"]
          },
          {
            label: "Ofertas no consideradas",
            data: dataNotConsideretOferts,
            backgroundColor: colors.theme["danger"]
          }
        ]
      },
      options: optionsBarLegend,
    };
  } else {
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Requerimientos creados",
            data: dataReqCreate,
            backgroundColor: colors.theme["warning"]
          },
          {
            label: "Ofertas Ganadoras",
            data: dataWinOferts,
            backgroundColor: colors.theme["danger"]
          },
          {
            label: "Ofertas no consideradas",
            data: dataNotConsideretOferts,
            backgroundColor: colors.theme["success"]
          }
        ]
      },
      options: optionsBarLegend,
    };
  }
};
export const getAllInformationCards = state => {
  if (Object.keys(state.dataRequestDashboardContracts).length) {
    return state.dataRequestDashboardContracts.dataCards;
  }
  return {
    amountRequest: {
      total: 0
    },
    amountRequestMonth: {
      total: 0
    },
    amountWon: {
      total: 0
    },
    amountWonMonth: {
      total: 0
    },
    amountReject: {
      total: 0
    },
    amountRejectMonth: {
      total: 0
    },
  };
};
export const getAllInfoDigitalRequests = state => {
  if (Object.keys(state.requestManagers).length) {
    return state.requestManagers.requests;
  }
  return [];
};

export const getActivitiesFlowByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    return state.dataRequestManagers.activityFlow;
  }
  return [];
};

export const getEquipmenstByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    return state.dataRequestManagers.equipments;
  }
  return [];
};

export const getEquipmenstSpareByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    return state.dataRequestManagers.equipmentSpare;
  }
  return [];
};

export const getReferencesByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    return state.dataRequestManagers.references;
  }
  return [];
};

export const getReferencesSpareByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    return state.dataRequestManagers.referenceSpare;
  }
  return [];
};

export const getJustificationsByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    return state.dataRequestManagers.justifications;
  }
  return [];
};

export const getConfigurationsByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    return state.dataRequestManagers.configurations;
  }
  return [];
};

export const getCommentsByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    return state.dataRequestManagers.comments;
  }
  return [];
};

export const getResumeOfferByRequest = state => {
  if (Object.keys(state.dataRequestManagers).length) {
    // return state.dataRequestManagers.comments;
    const { resume: { equipments, servicesTss, spareParts, equipmentsDetail, partByEquipment, request } } = state.dataRequestManagers;
    const { officeHours, idValidityService, idBusinessModel } = request;
    const totalEquipment = getTotalPriceEquipments(equipments, request);
    const totalByEquipment = getTotalPriceByEquipment(equipments, request);
    const totalServices = getTotalPriceServicesTss(servicesTss, request);
    const totalByServices = getTotalPriceByServicesTss(servicesTss, request);
    const totalSpareParts = getTotalPriceSpareParts(spareParts, request);
    const [totalByParts, totalBySpare] = getTotalPriceByParts(spareParts, request);
    const totalEquipments = getTotalPaymentByEquipment(idBusinessModel, equipmentsDetail, partByEquipment, totalByEquipment, totalByServices, totalByParts, totalBySpare);
    return {
      officeHours,
      validityService: idValidityService <= 2 ? 1 : idValidityService - 1,
      totalEquipment,
      totalServices,
      totalSpareParts,
      equipments: totalEquipments
    }
  }
  return {
    officeHours: "",
    validityService: 0,
    totalEquipment: {
      totalPayment: 0,
      totalPrice: 0,
    },
    totalServices: {
      totalPayment: 0,
      totalPrice: 0,
    },
    totalSpareParts: {
      totalPayment: 0,
      totalPrice: 0,
    },
    equipments: []
  };
};

export const getFiltersOptions = state => {
  if (Object.keys(state.requestManagers).length) {
    const { requests } = state.requestManagers;
    let clients = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.customer
      }
    });
    clients = clients.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => clients[e]).map(e => clients[e]);
    clients = clients.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let states = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.status
      }
    });
    states = states.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => states[e]).map(e => states[e]);
    states = states.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let opps = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.opportunityNumber
      }
    });
    opps = opps.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => opps[e]).map(e => opps[e]);
    opps = opps.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let countries = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.country
      }
    });
    countries = countries.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => countries[e]).map(e => countries[e]);
    countries = countries.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return {
      customer: [
        { value: 0, label: "Todos" },
        ...clients
      ],
      status: [
        { value: 0, label: "Todos" },
        ...states
      ],
      opp: [
        { value: 0, label: "Todos" },
        ...opps
      ],
      country: [
        { value: 0, label: "Todos" },
        ...countries
      ],
    };
  }
  return {
    customer: [],
    status: [],
    opp: [],
    country: [],
  }
};
