/*!

=========================================================
*Autopp - AutoppStart
=========================================================

*Componente AutoppCare el cual redirige a AutoppCareMain. 

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de React
import React,{useState, useEffect} from 'react';

import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

import AutoppCareMain from 'components/AutoppLdrs/AutoppCare/AutoppCareMain';



export const AutoppCare = React.memo(() => {

  return (
    <>


      <AdminHeader name="Vista de Mantenimiento" parentName="Fábrica de Propuestas" />

      <AutoppCareMain />


    </>

  )
})

export default AutoppCare;


//ventas stateBotones
//ventas especificas
//vista de precios
//botones 
