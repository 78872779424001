// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// reactstrap components
import { Card, CardFooter, CardHeader, Col, Row, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";

const VisitCard = props => {

  const {
    title,
    cols,
    rows,
    length,
    sizePerPage,
    paginations,
  } = props;

  const [page, onSetPage] = useState(1);

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => onSetPage(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 3 ? page >= 3 ? options.slice(page - 2, page + 1) : options.slice(0, 3) : options;
  }

  return (
    <Card>
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">{title}</h3>
          </div>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {
              cols.map((row, key) => (
                <th key={key} scope="col">{row.name}</th>
              ))
            }
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.length ?
              rows.slice((page - 1) * sizePerPage, (page) * sizePerPage).map((row, key) => (
                <tr key={key}>
                  {
                    cols.map((col, key) => (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "justify" }}
                      >
                        {row[col.key]}
                      </th>
                    ))
                  }
                </tr>
              ))
              :
              <tr>
                <td className="justify-content-md-center">
                  Sin resultados encontrados
                </td>
              </tr>
          }
        </tbody>
      </Table>
      {
        length > 0 ?
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Row className="align-items-center">
                <Col xs='12' md='6' className="p-1">
                  <span className='pagination mb-0'>
                    Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > length ? length : page * sizePerPage} de {length} resultados
                    </span>
                </Col>
                <Col xs='12' md='6' className="p-1">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                      <PaginationLink
                        onClick={() => onSetPage(page === 1 ? page : page - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {
                      renderPaginations()
                    }
                    <PaginationItem className={page === paginations ? "disabled" : ""}>
                      <PaginationLink
                        onClick={() => onSetPage(page === paginations ? page : page + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </Col>
              </Row>
            </nav>
          </CardFooter>
          : null
      }
    </Card>
  );
};

VisitCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default VisitCard;