// core action
import { findFlowLogByIdFlow, findSignaturesPendingByCollaborator, updateSigntureByCollaborator, updateStateSignatureInFlow } from "actions/digitalSignature.jsx";
// antd components
import { Spin } from 'antd';
import SignaturesFlowList from "components/DigitalSignature/SignaturesFlowList.jsx";
// core components DigitalSignatures
import UserCorrectionView from "components/DigitalSignature/UserCorrectionView.jsx";
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import CardEmpty from 'components/Shared/Notifications/CardEmpty.jsx';
// javascript library for dates
import moment from 'moment';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getFlowLogsBySignature, getSignaturesByCollaboratorFlow } from "selectors/digitalSignature.jsx";

class CollaboratorFlow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      parent: true,
      loading: false,
      loadingFlow: false,
      message: null,
      openSignature: false,
      signatureSelected: null,
    };
  };

  componentWillMount() {
    this.findSignaturesPending();
  };

  findSignaturesPending = () => {
    this.setState((state) => ({
      loading: !state.loading,
      message: 'Cargando las firmas pendientes en flujo de aprobación'
    }));
    this.props.findSignaturesPendingByCollaborator().then((res) => {
      if (res.payload.isAxiosError && this.state.parent) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
        parent: false
      }));
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state],
      // signatureSelected: null
    });
  };

  handleOnSelectSignature = row => {
    this.setState((state) => ({
      loading: !state.loading,
      message: 'Cargando el flujo de actividades'
    }));
    this.props.findFlowLogByIdFlow(row.idFlow).then((res) => {
      if (res.payload.isAxiosError && this.state.parent) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
        signatureSelected: row,
        openSignature: true
      }));
    });
  };

  handleOnUpdateSignature = values => {
    console.log(values);
    const { id } = values;
    this.setState((state) => ({
      loading: !state.loading,
      message: 'Actualizando la información de la política'
    }));
    this.props.updateSigntureByCollaborator(id, values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
          openSignature: false,
          signatureSelected: null,
        }));
        this.findSignaturesPending();
      }
    });
  };

  render() {

    const {
      name,
      parentName,
      signatures,
      logs,
      days,
      frequencies,
      models,
    } = this.props;

    const {
      loading,
      loadingFlow,
      message,
      openSignature,
      signatureSelected
    } = this.state;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {
              signatures.length ?
                openSignature ?
                  <Row>
                    <Col>
                      <UserCorrectionView
                        title="Corrección de la Firma"
                        loading={loadingFlow}
                        message={message}
                        values={signatureSelected}
                        logs={logs}
                        options={{ days, frequencies, models }}
                        onUpdate={this.handleOnUpdateSignature}
                      />
                    </Col>
                  </Row>
                  :
                  <Row>
                    <Col>
                      <SignaturesFlowList
                        title="Flujo de corrección"
                        subtitle="Firmas pendientes de corrección"
                        signatures={signatures}
                        openSignature={this.handleOnSelectSignature}
                      />
                    </Col>
                  </Row>
                :
                <Row className="justify-content-center">
                  <Col sm="12" md="8">
                    <CardEmpty
                      title="No tienes firmas pendientes."
                      subtitle="Al día de hoy no tienes firmas en flujo de corrección, en el momento que exista una nueva, aparecerán en esta ventana."
                    />
                  </Col>
                </Row>
            }
          </Spin>
        </Container>
      </>
    );
  }
}

CollaboratorFlow.defaultProps = {
  name: `Corrección de Firmas ${moment().utc().utcOffset(moment().utcOffset()).year()}`,
  parentName: "Notificaciones",
  signatures: [],
  logs: [],
  days: [
    { key: 'monday', name: 'Lunes' },
    { key: 'tuesday', name: 'Martes' },
    { key: 'wednesday', name: 'Miércoles' },
    { key: 'thursday', name: 'Jueves' },
    { key: 'friday', name: 'Viernes' }
  ],
  frequencies: [
    { id: 1, name: 'Semanal' },
    { id: 2, name: 'Quincenal' },
    { id: 3, name: 'Mensual' }
  ],
  models: [
    { id: 1, name: '1-4' },
    { id: 2, name: '2-3' },
    { id: 3, name: '3-2' },
    { id: 4, name: '4-1' },
    { id: 5, name: '5-0' },
    { id: 6, name: 'Otro' },
  ]
};

CollaboratorFlow.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  signatures: PropTypes.array.isRequired,
  logs: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  signatures: getSignaturesByCollaboratorFlow(state),
  logs: getFlowLogsBySignature(state),
});

export default withRouter(connect(
  mapStateToProps, {
  findFlowLogByIdFlow,
  findSignaturesPendingByCollaborator,
  updateSigntureByCollaborator,
  updateStateSignatureInFlow,
})(CollaboratorFlow));
