
export const getAllPlatforms = state => {

    const { findAllPlatforms } = state
    if (Object.keys(findAllPlatforms).length) {
        const allPlatforms = findAllPlatforms.data.map((row) => {
            return {
                label: row.Name,
                value: row.Id
            }
        })
        return [{ label: "Seleccione...", value: "0" }, ...allPlatforms]
    }
    return [];
}
export const getAllProducts = state => {

    const { findAllProducts } = state
    if (Object.keys(findAllProducts).length) {
        const allProducts = findAllProducts.data.map((row) => {
            return {
                label: row.Name,
                value: row.Id
            }
        })
        return [{ label: "Seleccione...", value: "0" }, ...allProducts]
    }
    return [];
}
export const getAllServices = state => {

    const { findAllServices } = state
    if (Object.keys(findAllServices).length) {
        const allServices = findAllServices.data.map((row) => {
            return {
                label: row.Name,
                value: row.Id
            }
        })
        return [{ label: "Seleccione...", value: "0" }, ...allServices]
    }
    return [];
}
export const getAllData = state => {

    const { findAllData } = state
    if (Object.keys(findAllData).length) {
        const allData = findAllData.data.map((row) => {
            return {
                label: row.Name,
                value: row.Id
            }
        })
        return [{ label: "Seleccione...", value: "0" }, ...allData]
    }
    return [];
}