/*!

=========================================================
*MasterData - MaintenanceWindowMasterData
=========================================================

*Componente inicio de la vista de mantenimiento de MasterData, 
donde verifica si tiene permisos de Master Data Admin, de lo 
contrario se le muestra una vista de que no tiene permisos.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//#region Imports de librerías externas.

//Imports del Framework de React.
import React, { useState, useEffect } from 'react';

//Imports de la Librería raíz Reactstrap.
import {
    Container,
} from "reactstrap";

//Import dispatch consulta al API.
import { useDispatch } from 'react-redux';

//Import del spinner de la vista.
import { Spin } from 'antd';

//#endregion

//#region Imports de componentes internos.

//Import de header superior de la vista.
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

//Import de vista de accesi denegado.
import { NotAccessView } from 'components/NewMasterData/SharedComponents/NotAccessView';

//Import de componente contexto, para establecer variables globales
import { UserContext } from 'components/NewMasterData/SharedComponents/UserContext';

import formFields from "components/NewMasterData/SharedComponents/GeneralStates";

//Imports de actions de consultas al API.
import {
    getOptions,
    getEmployees,
} from 'actions/masterData';

//Imports de helpers para verificación de usuario.
import { getTeamsUserAccess } from 'selectors/adminLayout';
import CardsMaintenance from 'components/NewMasterData/MaintenanceWindow/CardsMaintenance';

//#endregion

// react plugin for creating notifications over the dashboard
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

// Importar funcion para desencriptar
import { desencrypt } from 'helpers/desencrypt.jsx';

const MaintenanceWindowMasterData = () => {

    //Extracción de librería swal.
    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    //Función para extraer solicitudes del API.
    const dispatch = useDispatch();

    //spinning value
    const [loading, setLoading] = useState(false);

    //#region Extraer las opciones de dropdown.

    const [refreshOptions, setRefreshOptions] = useState(false);

    const [options, setOptions] = useState([]);

    //Trae las opciones y en cadena también los empleados al arrancar. 
    useEffect(() => {
        getOptionsD()
    }, [])

    //Trae solo las opciones cuando refresca.
    useEffect(() => {
        if (refreshOptions) {
            getOptionsA()
        }
    }, [refreshOptions])

    //Trae sólo las opciones de los dropdowns.
    const getOptionsA = () => {

        dispatch(getOptions()).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
                //console.log(payload)

                setOptions(payload.data.payload.masterData);


            } else {
                console.log(payload)
            }
            setRefreshOptions(false)
        })
    }

    //Trae las opciones de los dropdowns y en cadena de los empleados.
    const getOptionsD = () => {
        // alert("OPCIONES Y EMPLEADOS")
        setLoading(true)

        ///PRUEBA DE JSON GRANDE
        // dispatch(getFormFields()).then((resp) => {
        //     const { payload } = resp;
        //     if (payload.status === 200) {
        //       console.log(payload.data.payload.formFieldsDb);
        //       console.log(formFields);
        //     } else {
        //       Toast.fire({
        //         title: "Atención",
        //         html: payload.response.data.message,
        //         type: "danger",
        //       });
        //     }
        //   });
        //////--------------------

        // dispatch(getOptionsMaintenanceView()).then((resp) => {

        dispatch(getOptions()).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
                //console.log(payload)

                setOptions(payload.data.payload.masterData);


            } else {
                console.log(payload)
            }

            getEmployeesOptions();

            setLoading(false);
        })
    }
    //#endregion

    //#region Extraer los nombres de los empleados del API
    const [employees, setEmployees] = useState([]);


    //trae los empleados de GBM de último porque son muy pesados
    const getEmployeesOptions = async () => {
        //console.log("aqui employees")

        dispatch(getEmployees()).then((resp) => {
            const { payload } = resp;
            //console.log(payload)
            if (payload.status === 200) {

                setEmployees(JSON.parse(desencrypt(payload.data.payload.employees)))
            } else {
                console.log("Ocurrió un error al extraer los empleados: " + payload)
                setEmployees([]);
            }
        })
    }

    //#endregion 


    //Verifica si es un usuario admin para mostrar esta vista.
    const isAdmin = getTeamsUserAccess().filter(permission => permission.includes("Master Data Admin")).length > 0



    return (
        <>
            <UserContext.Provider value={{
                loading,
                setLoading,
                Toast,

                //Opciones para dropdowns.
                options,
                employees,
                refreshOptions,
                setRefreshOptions
            }}
            >
                <AdminHeader name="Vista de Mantenimiento" parentName="Datos Maestros" />
                <Spin size="large" spinning={loading}>
                    <Container className="mt--6" fluid>
                        {
                            isAdmin ?
                                <>
                                    <CardsMaintenance />
                                </>
                                :
                                <NotAccessView />
                        }
                    </Container>
                </Spin>
            </UserContext.Provider>



        </>
    );
};



export default MaintenanceWindowMasterData;