/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Fechas Solicitud en la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React from "react";
import { Card, CardTitle, Label, Col, Row, Input, FormGroup } from "reactstrap";

const RequestDate = ({ isVisible, data }) => {

    const fields = [
        {
            type: "text",
            label: "Fecha Manager (HC)",
            id: "managerDate",
            value: data.managerDate
        },
        {
            type: "text",
            label: "Fecha Payroll Analyst",
            id: "hcDate",
            value: data.hcDate
        },
        {
            type: "text",
            label: "Fecha Server Support",
            id: "ssDate",
            value: data.ssDate
        },
        {
            type: "text",
            label: "Fecha Application Support",
            id: "asDate",
            value: data.asDate
        },
        {
            id: "csDate",
            label: "Fecha Communication Support",
            type: "text",
            value: data.csDate
        }
    ]

    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={
                        { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif', height: '1.5em', fontSize: '1.25em', textAlign: 'center' }
                    }>
                        Fechas Solicitud
                    </CardTitle>

                    <Row style={{ padding: '0px 25px 0px' }}>
                        {fields.map((field, index) => (
                            <Col key={index} xs={4}>
                                <FormGroup style={{ marginBottom: '0.1rem', fontFamily: 'sans-serif' }}>
                                    <Label htmlFor={field.id} style={{ height: '1.5em', marginBottom: '0.1rem', fontFamily: 'sans-serif' }}>
                                        {field.label}
                                    </Label>
                                    <Input
                                        type={field.type}
                                        id={field.id}
                                        defaultValue={field.value}
                                        disabled
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col>
                        ))}
                    </Row>
                </Card>
            )}
        </>
    );
}
export default RequestDate;