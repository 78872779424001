import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getOptions = createAction(constants.FF_GET_OPTIONS, method.get(urls.FINANCIALFLOWS.getOptions));
//GENERAL CONFIGURATIONS
export const getAllUnitType = createAction(constants.FF_GET_UNIT_TYPE, method.get(urls.FINANCIALFLOWS.getAllUnitType));
export const insertUnitType = createAction(constants.FF_INSERT_UNIT_TYPE, (body) => method.post(`${urls.FINANCIALFLOWS.insertUnitType}`, body)());
export const updateUnitType = createAction(constants.FF_UPDATE_UNIT_TYPE, (body) => method.post(`${urls.FINANCIALFLOWS.updateUnitType}`, body)());
export const deleteUnitType = createAction(constants.FF_DELETE_UNIT_TYPE, (id) => method.put(`${urls.FINANCIALFLOWS.deleteUnitType}/${id}`)());
//DOCUMENTS
export const getAllDocument = createAction(constants.FF_GET_ALL_DOCUMENTS, method.get(urls.FINANCIALFLOWS.getAllDocument));
export const getDocumentById = createAction(constants.FF_GET_DOCUMENT_BY_ID, (id) => method.put(`${urls.FINANCIALFLOWS.getDocumentById}/${id}`)());
export const insertDocument = createAction(constants.FF_INSERT_DOCUMENT, (body) => method.post(`${urls.FINANCIALFLOWS.insertDocument}`, body)());
export const updateDocument = createAction(constants.FF_UPDATE_DOCUMENT, (body) => method.post(`${urls.FINANCIALFLOWS.updateDocument}`, body)());
export const deleteDocument = createAction(constants.FF_DELETE_DOCUMENT_BY_ID, (body) => method.post(`${urls.FINANCIALFLOWS.deleteDocumentById}`, body)());
//PROFILES
export const getAllProfile = createAction(constants.FF_GET_ALL_PROFILES, method.get(urls.FINANCIALFLOWS.getAllProfile));
export const getProfileById = createAction(constants.FF_GET_PROFILE_BY_ID, (id) => method.put(`${urls.FINANCIALFLOWS.getProfileById}/${id}`)());
export const updateProfile = createAction(constants.FF_UPDATE_PROFILE, (body) => method.post(`${urls.FINANCIALFLOWS.updateProfile}`, body)());
export const insertProfile = createAction(constants.FF_INSERT_PROFILE, (body) => method.post(`${urls.FINANCIALFLOWS.insertProfile}`, body)());
export const deleteProfile = createAction(constants.FF_DELETE_PROFILE, (id) => method.put(`${urls.FINANCIALFLOWS.deleteProfile}/${id}`)());
//Template positions
export const getAllTemplatePosition = createAction(constants.FF_GET_ALL_TEMPLATE_POSITION, method.get(urls.FINANCIALFLOWS.getAllTemplatePosition));
export const isAway = createAction(constants.FF_IS_AWAY, (id, body) => method.post(`${urls.FINANCIALFLOWS.isAway}/${id}`, body)());
export const getUserSign = createAction(constants.FF_GET_USER_SIGN, (body) => method.post(`${urls.FINANCIALFLOWS.getUserSign}`, body)());
export const deleteTemplatePosition = createAction(constants.FF_DELETE_TEMPLATE_POSITION, (id) => method.put(`${urls.FINANCIALFLOWS.deleteTemplatePosition}/${id}`)());
export const addTemplatePosition = createAction(constants.FF_ADD_TEMPLATE_POSITION, (body) => method.post(`${urls.FINANCIALFLOWS.addTemplatePosition}`, body)());
export const updateTemplatePosition = createAction(constants.FF_UPDATE_TEMPLATE_POSITION, (body) => method.post(`${urls.FINANCIALFLOWS.updateTemplatePosition}`, body)());
export const getProfilesByPositionId = createAction(constants.FF_GET_PROFILE_BY_POSITION_ID, (id) => method.post(`${urls.FINANCIALFLOWS.getProfilesByPositionId}/${id}`)());
//Document Approval Template
export const getAllCategories = createAction(constants.FF_GET_ALL_CATEGORIES, method.get(urls.FINANCIALFLOWS.getAllCategories));
export const getAllDocumentApprovalTemplateByCategoryId = createAction(constants.FF_GET_DOCUMENT_APPROVAL_TEMPLATE_CATEGORY, (id) => method.post(`${urls.FINANCIALFLOWS.getAllDocumentApprovalTemplateByCategoryId}/${id}`)());
export const getUnitTypeByDocumentId = createAction(constants.FF_GET_UNIT_TYPE_BY_DOCUMENT, (id) => method.post(`${urls.FINANCIALFLOWS.getUnitTypeByDocumentId}/${id}`)());
export const addDocumentApprovalTemplate = createAction(constants.FF_ADD_DOCUMENT_APPROVAL_TEMPLATE, (body) => method.post(`${urls.FINANCIALFLOWS.addDocumentApprovalTemplate}`, body)());
export const updateDocumentApprovalTemplate = createAction(constants.FF_UPDATE_DOCUMENT_APPROVAL_TEMPLATE, (body) => method.post(`${urls.FINANCIALFLOWS.updateDocumentApprovalTemplate}`, body)());
export const deleteDocumentApprovalTemplate = createAction(constants.FF_DELETE_DOCUMENT_APPROVAL_TEMPLATE, (id) => method.put(`${urls.FINANCIALFLOWS.deleteDocumentApprovalTemplate}/${id}`)());
// Nueva Solicitud
export const getDocumentField = createAction(constants.FF_DOCUMENT_FIELD, (id) => method.put(`${urls.FINANCIALFLOWS.getDocumentField}/${id}`)());
export const getRulesAndApproverByTemplate = createAction(constants.FF_GET_RULES_AND_APPROVERS_BY_TEMPLATE, (id) => method.put(`${urls.FINANCIALFLOWS.getRulesAndApproverByTemplate}/${id}`)());
export const createNewRequest = createAction(constants.FF_CREATE_REQUEST, (body) => method.post(`${urls.FINANCIALFLOWS.createRequest}`, body)());
export const deleteFile = createAction(constants.FF_DELETE_FILE, body => method.post(urls.FINANCIALFLOWS.deleteFile, body)());
export const deleteFileRequest = createAction(constants.FF_DELETE_FILE_REQUEST, body => method.post(urls.FINANCIALFLOWS.deleteFileRequest, body)());
export const getBusinessUnitByUser = createAction(constants.FF_BUSINESSUNIT_BY_USER, method.get(urls.FINANCIALFLOWS.getBusinessUnitByUser));

//My Flows 
export const getRequestMyFlow = createAction(constants.FF_REQUEST_MY_FLOWS, method.get(urls.FINANCIALFLOWS.getRequestMyFlow));
export const getRequestId = createAction(constants.FF_GET_REQUEST_ID, (id) => method.put(`${urls.FINANCIALFLOWS.getRequestId}/${id}`)());
// My request
export const getRequestByUser = createAction(constants.FF_GET_REQUEST_BY_USER, method.get(urls.FINANCIALFLOWS.getRequestByUser));
export const approveRequest = createAction(constants.FF_APPROVE_REQUEST, (body) => method.post(urls.FINANCIALFLOWS.approveRequest, body)());
export const cancelRequest = createAction(constants.FF_CANCEL_REQUEST, (id, body) => method.put(`${urls.FINANCIALFLOWS.cancelRequest}/${id}`, body)());
export const relaunchRequest = createAction(constants.FF_RELAUNCH_REQUEST, (body) => method.post(urls.FINANCIALFLOWS.relaunchRequest, body)());
export const downloadAttachmentByPath = createAction(constants.FF_DOWNLOAD_ATTACHMENT, (requestFileId) => method.fileDownload(`${urls.FINANCIALFLOWS.downloadAttachmentByPath}/${requestFileId}`)());

// All requests
export const getAllRequest = createAction(constants.FF_GET_ALL_REQUEST, method.get(urls.FINANCIALFLOWS.getAllRequest));
export const getAllRequestByRangeDate = createAction(constants.FF_GET_REQUEST_RANGE_DATE, (body) => method.post(urls.FINANCIALFLOWS.getAllRequestByRangeDate, body)());

// Access request views
export const getAccessRequestByUser = createAction(constants.FF_GET_ACCESS_REQUEST, method.get(urls.FINANCIALFLOWS.getAccessRequestByUser));

// Revenue recognition
export const updateRevenueStatus = createAction("UPDATE-REVENUE-STATUS", (id, body) => method.put(`${urls.FINANCIALFLOWS.updateRevenueStatus}/${id}`, body)());

// Sección de JAEN
export const getRequests = createAction(constants.FF_GET_REQUESTS, method.get(urls.FINANCIALFLOWS.getRequests));
export const getCategories = createAction(constants.FF_GET_CATEGORIES, (body) => method.get(`${urls.FINANCIALFLOWS.getCategories}`, body)());
export const addAttachment = createAction(constants.FF_ADD_ATTACHMENT, (RequestID, body) => method.fileUpload(`${urls.FINANCIALFLOWS.downloadAttachment}/${RequestID}`, body)());
export const approvalAction = createAction(constants.FF_APPROVAL_ACTION, (id, body) => method.put(`${urls.FINANCIALFLOWS.approvalAction}/${id}`, body)());
export const downloadAttachment = createAction(constants.FF_DOWNLOAD_ATTACHMENT, (id) => method.fileDownload(`${urls.FINANCIALFLOWS.downloadAttachment}/${id}`)());
export const getRequest = createAction(constants.FF_GET_REQUEST, (id) => method.get(`${urls.FINANCIALFLOWS.getRequest}${id}`)());
export const preventRelaunch = createAction(constants.FF_PREVENT_RELAUNCH, (id) => method.put(`${urls.FINANCIALFLOWS.preventRelaunch}/${id}`)());
export const relaunch = createAction(constants.FF_RELAUNCH, (id) => method.put(`${urls.FINANCIALFLOWS.relaunch}/${id}`)());
export const remindApprover = createAction(constants.FF_REMINDER, (id) => method.put(`${urls.FINANCIALFLOWS.reminder}/${id}`)());
export const removeFile = createAction(constants.FF_REMOVE_FILE, (id) => method.put(`${urls.FINANCIALFLOWS.removeFile}/${id}`)());
export const RRAction = createAction(constants.FF_RR_ACTION, (id, body) => method.put(`${urls.FINANCIALFLOWS.RRAction}/${id}`, body)());