/* eslint-disable jsx-a11y/anchor-is-valid */
// core actions
import {
  createUserRol,
  deactivateUserRol,
  findUserWithAccess,
  createPreapproverRole,
  deletePreapproverRole,
} from "actions/extraHours.jsx";

import * as jwt from "jsonwebtoken";

// antd components
import { Spin } from "antd";
import AddUserModal from "components/ExtraHours/AddUserModal.jsx";
// core components Extra Hours
import Filters from "components/ExtraHours/FiltersUsers.jsx";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// core selectors
import {
  getCountriesUsers,
  getManagersUsers,
  getUsernamesUsers,
  getUsersWithAccess,
} from "selectors/extraHours";

import { getPreapprovers } from "actions/HoursReport.jsx";
import { desencrypt } from "helpers/desencrypt.jsx";

import RolManagementOptions from "components/ExtraHours/RolManagementOptions";

class AccessManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: true,
      preapprovers: [],
      filterByUser: [],
      filterByCountry: [],
      filterBySupervisor: [],
      filterData: {},
      haveAllPermissions: false,
      showReturnButton: false,
      showPreapproversView: false,
      countriesSelected: {
        value: 0,
        label: "Todos",
      },
      managerSelected: {
        value: 0,
        label: "Todos",
      },
      usernamesSelected: {
        value: 0,
        label: "Todos",
      },
      showModal: false,
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const decoded = jwt.decode(token);
    const { teams } = decoded;
    if (teams.some((team) => team === "Preapprover Role Asignator")) {
      this.setState(() => ({
        haveAllPermissions: true,
        showReturnButton: true,
      }));
    } else {
      this.getUsersWithExtraRole();
    }
  }

  getUsersWithExtraRole() {
    this.props.findUserWithAccess().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState(() => ({
        loading: false,
      }));
    });
  }

  //Funcion para crear datos con los cuales llenar filtros de búsqueda
  setFilters(data, clave) {
    if (data.length) {
      let filterData = data.map((row, key) => {
        return {
          value: key + 1,
          label:
            clave === "manager"
              ? row.manager
              : clave === "country"
              ? row.country
              : row.user,
        };
      });
      filterData = filterData
        .map((e) => e["label"])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter((e) => filterData[e])
        .map((e) => filterData[e]);

      filterData = filterData.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      return [{ value: 0, label: "TODOS" }, ...filterData];
    }
    return [];
  }

  getUserWithPreapproverRole() {
    // Aquí se llama getPreapprovers y se actualiza el estado
    this.props.getPreapprovers().then((res) => {
      console.log("");
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logró establecer conexión con el servidor."
          );
        }
      } else {
        // Actualización del estado con los preapprovers obtenidos
        let data = desencrypt(res.payload.data.payload.data);
        data = JSON.parse(data);

        const filterByUser = this.setFilters(data[0], "user");
        const filterByCountry = this.setFilters(data[0], "country");
        const filterBySupervisor = this.setFilters(data[0], "manager");

        this.setState({
          preapprovers: data[0],
          filterByUser: filterByUser,
          filterByCountry: filterByCountry,
          filterBySupervisor: filterBySupervisor,
        });
      }
      this.setState(() => ({
        loading: false,
      }));
    });
  }
  componentDidMount() {
    new List(this.refs["first-list"], {
      valueNames: ["id", "username", "name", "email", "manager", "country"],
      listClass: "list",
    });
  }

  //Función para el manejo de las Cards que accesan a los módulos de asignación de extras o preaprobores.
  handleOnChangeOption = async (id) => {
    if (id === "ExtraHoursRol") {
      this.setState(() => ({
        loading: true,
      }));
      this.getUsersWithExtraRole();
      this.setState(() => ({
        haveAllPermissions: false,
        showPreapproversView: false,
      }));
    } else {
      this.setState(() => ({
        loading: true,
      }));
      this.getUserWithPreapproverRole();
      this.setState(() => ({
        loading: true,
        showPreapproversView: true,
        haveAllPermissions: false,
      }));
    }
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  _handleOnClearFilters = () => {
    this.setState({
      countriesSelected: {
        value: 0,
        label: "Todos",
      },
      managerSelected: {
        value: 0,
        label: "Todos",
      },
      usernamesSelected: {
        value: 0,
        label: "Todos",
      },
    });
  };

  _handleReturn = () => {
    this.setState({
      haveAllPermissions: true,
      showPreapproversView: false,
    });
  };

  _handleOnChangeFilters = (who, value) => {
    this.setState({
      [who]: value,
    });
  };

  _handleRenderDataByFilters = (data) => {
    const { countriesSelected, managerSelected, usernamesSelected } =
      this.state;
    if (
      countriesSelected.value === 0 &&
      managerSelected.value === 0 &&
      usernamesSelected.value === 0
    ) {
      return data;
    } else {
      if (countriesSelected.value !== 0) {
        data = data.filter((row) => row.country === countriesSelected.label);
      }
      if (managerSelected.value !== 0) {
        data = data.filter((row) => row.manager === managerSelected.label);
      }
      if (usernamesSelected.value !== 0) {
        data = data.filter((row) => row.username === usernamesSelected.label);
      }
      return data;
    }
  };

  _onDeleteUserRol = (row) => {
    this.setState((state) => ({
      loading: !state.loading,
      alert: null,
    }));
    this.props.deactivateUserRol(row.id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState((state) => ({
        loading: !state.loading,
      }));
    });
  };

  //Función para eliminar un usuario preaprobador
  _onDeletePreapproverRole = (row) => {
    this.setState((state) => ({
      loading: !state.loading,
      alert: null,
    }));
    this.props.deletePreapproverRole(row.UserID).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState((state) => ({
        loading: !state.loading,
      }));

      this.getUserWithPreapproverRole();
    });
  };

  _handleDeleteRol = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de eliminarle el rol al usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this._onDeleteUserRol(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  _handleDeletePreapproverRole = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de eliminarle el rol al usuario?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this._onDeletePreapproverRole(row)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  _handleOnOpenModal = () => {
    this.setState({
      showModal: true,
    });
  };

  _toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  _handleAddUserRol = (values) => {
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props.createUserRol(values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState((state) => ({
        loading: !state.loading,
        showModal: !state.showModal,
      }));
    });
  };

  //Función para asignarle a un usuario el rol de preaprobador
  _handleAddPreapproverRole = (values) => {
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props.createPreapproverRole(values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState((state) => ({
        loading: !state.loading,
        showModal: !state.showModal,
      }));

      this.getUserWithPreapproverRole();
    });
  };

  render() {
    const {
      alert,
      loading,
      haveAllPermissions,
      showReturnButton,
      showPreapproversView,
      countriesSelected,
      managerSelected,
      usernamesSelected,
      showModal,
      preapprovers,
      filterByCountry,
      filterBySupervisor,
      filterByUser,
    } = this.state;

    const {
      name,
      parentName,
      users,
      cols,
      countriesOptions,
      managerOptions,
      usernamesOptions,
    } = this.props;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          {haveAllPermissions ? (
            <RolManagementOptions changeOption={this.handleOnChangeOption} />
          ) : !showPreapproversView ? (
            <>
              <AddUserModal
                showModal={showModal}
                loading={loading}
                toggleModal={this._toggleModal}
                onSubmit={this._handleAddUserRol}
              />
              <Spin
                size="large"
                spinning={loading}
                tip="Espere mientras se cargan los usuarios"
              >
                <Row>
                  <Col>
                    <Filters
                      title="Filtros disponibles"
                      subtitle="Aplicación de filtros a la lista de usuarios."
                      countriesOptions={countriesOptions}
                      managerOptions={managerOptions}
                      usernamesOptions={usernamesOptions}
                      countriesSelected={countriesSelected}
                      managerSelected={managerSelected}
                      usernamesSelected={usernamesSelected}
                      onClear={this._handleOnClearFilters}
                      onReturn={this._handleReturn}
                      onChangeFilters={this._handleOnChangeFilters}
                      showOnReturnButton={showReturnButton}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {users.length ? (
                      <Card>
                        <CardHeader className="border-0">
                          <Row>
                            <Col xs="6">
                              <h3 className="mb-0">Usuarios con acceso</h3>
                            </Col>
                            <Col className="text-right" xs="6">
                              <Button
                                className="btn-neutral btn-round btn-icon"
                                color="default"
                                id="tooltip"
                                onClick={this._handleOnOpenModal}
                                size="sm"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-user-plus" />
                                </span>
                                <span className="btn-inner--text">
                                  Agregar Usuario
                                </span>
                              </Button>
                              <UncontrolledTooltip delay={0} target="tooltip">
                                Darle acceso a un usuario
                              </UncontrolledTooltip>
                            </Col>
                          </Row>
                        </CardHeader>
                        <div className="table-responsive" ref="first-list">
                          <Table className="align-items-center table-flush">
                            <thead className="thead-light">
                              <tr>
                                {cols.map((row, key) => (
                                  <th key={key} scope="col">
                                    {row.name}
                                  </th>
                                ))}
                                <th />
                              </tr>
                            </thead>
                            <tbody className="list">
                              {this._handleRenderDataByFilters(users).map(
                                (row, key) => (
                                  <tr key={key}>
                                    <td className="id">{row.id}</td>
                                    <td className="username">{row.username}</td>
                                    <td className="name">{row.name}</td>
                                    <td className="email">{row.email}</td>
                                    <td className="manager">{row.manager}</td>
                                    <td className="country">{row.country}</td>
                                    <td className="table-actions">
                                      <a
                                        className="table-action table-action-delete"
                                        style={{ color: "indianred" }}
                                        onClick={() =>
                                          this._handleDeleteRol(row)
                                        }
                                      >
                                        <i className="fas fa-trash" />
                                      </a>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Card>
                    ) : null}
                  </Col>
                </Row>
              </Spin>
            </>
          ) : null}

          {showPreapproversView && (
            <>
              {" "}
              <AddUserModal
                showModal={showModal}
                loading={loading}
                toggleModal={this._toggleModal}
                onSubmit={this._handleAddPreapproverRole}
              />
              <Spin
                size="large"
                spinning={loading}
                tip="Espere mientras se cargan los usuarios"
              >
                <Row>
                  <Col>
                    <Filters
                      title="Filtros disponibles"
                      subtitle="Aplicación de filtros a la lista de usuarios."
                      countriesOptions={filterByCountry}
                      managerOptions={filterBySupervisor}
                      usernamesOptions={filterByUser}
                      countriesSelected={countriesSelected}
                      managerSelected={managerSelected}
                      usernamesSelected={usernamesSelected}
                      onClear={this._handleOnClearFilters}
                      onReturn={this._handleReturn}
                      onChangeFilters={this._handleOnChangeFilters}
                      showOnReturnButton={showReturnButton}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {preapprovers.length ? (
                      <Card>
                        <CardHeader className="border-0">
                          <Row>
                            <Col xs="6">
                              <h3 className="mb-0">
                                Usuarios con rol de preaprobador
                              </h3>
                            </Col>
                            <Col className="text-right" xs="6">
                              <Button
                                className="btn-neutral btn-round btn-icon"
                                color="default"
                                id="tooltip"
                                onClick={this._handleOnOpenModal}
                                size="sm"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-user-plus" />
                                </span>
                                <span className="btn-inner--text">
                                  Agregar Usuario
                                </span>
                              </Button>
                              <UncontrolledTooltip delay={0} target="tooltip">
                                Darle rol de preaprobador a un usuario
                              </UncontrolledTooltip>
                            </Col>
                          </Row>
                        </CardHeader>
                        <div className="table-responsive" ref="first-list">
                          <Table className="align-items-center table-flush">
                            <thead className="thead-light">
                              <tr>
                                {cols.map((row, key) => {
                                  return (
                                    <th key={key} scope="col">
                                      {row.name}
                                    </th>
                                  );
                                })}
                                <th />
                              </tr>
                            </thead>
                            <tbody className="list">
                              {this._handleRenderDataByFilters(
                                preapprovers
                              ).map((row, key) => (
                                <tr key={key}>
                                  <td className="id">{row.UserID}</td>
                                  <td className="username">{row.user}</td>
                                  <td className="name">{row.name}</td>
                                  <td className="email">{row.email}</td>
                                  <td className="manager">{row.manager}</td>
                                  <td className="country">{row.country}</td>
                                  <td className="table-actions">
                                    <a
                                      className="table-action table-action-delete"
                                      style={{ color: "indianred" }}
                                      onClick={() =>
                                        this._handleDeletePreapproverRole(row)
                                      }
                                    >
                                      <i className="fas fa-trash" />
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Card>
                    ) : null}
                  </Col>
                </Row>
              </Spin>
            </>
          )}
        </Container>
      </>
    );
  }
}

AccessManagement.defaultProps = {
  name: "Gestión de Accesos",
  parentName: "Horas Extras",
  users: [],
  preapprovers: [],
  cols: [
    { key: "id", name: "ID" },
    { key: "username", name: "Usuario" },
    { key: "name", name: "Nombre" },
    { key: "email", name: "Email" },
    { key: "manager", name: "Supervisor" },
    { key: "country", name: "País" },
  ],
  countriesOptions: [],
  managerOptions: [],
  usernamesOptions: [],
};

AccessManagement.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  preapprovers: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  countriesOptions: PropTypes.array.isRequired,
  managerOptions: PropTypes.array.isRequired,
  usernamesOptions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  users: getUsersWithAccess(state),
  preapprovers: getPreapprovers(state),
  countriesOptions: getCountriesUsers(state),
  managerOptions: getManagersUsers(state),
  usernamesOptions: getUsernamesUsers(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findUserWithAccess,
    getPreapprovers,
    deactivateUserRol,
    createUserRol,
    createPreapproverRole,
    deletePreapproverRole,
  })(AccessManagement)
);
