
import React, { useState, useEffect } from 'react';
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import AgreementCREditFormOne from "components/CostaRicaBids/AgreementCREditFormOne";
import AgreementCREditFormThree from "components/CostaRicaBids/AgreementCREditFormThree";
import AgreementCREditFormTwo from "components/CostaRicaBids/AgreementCREditFormTwo";
import { updatePurchaseOrder } from 'actions/costaRicaBids';
import { useDispatch } from 'react-redux';
import "../../assets/css/DocumentSystem.css"

const AgreementCREditModal = ({
  showModal,
  rowsModal,
  setRowsModal,
  setActiveTab,
  activeTab,
  info,
  handleCloseModal,
  options,
  handleOnLoadEditModal,
  setShowEditModal,
  Toast,
  setRefreshInput,
  isDisplay,
  token,
  role,
  isAdmin,
  setOptions
}) => {

  const dispatch = useDispatch();
  const isDisabled = isDisplay === true ? true : isAdmin === true ? false : role == info["valueTeam"] ? false : (role === "CostaRicaBids AM" && info.accountManager === token) ? false
    : (role === "CostaRicaBids GBM Direct/Premium Account" && (info["valueTeam"] === "Premiun Account" || info["valueTeam"] === "GBM Direct")) ? false : true;
  const [editInfo, setEditInfo] = useState(
    {
      bidNumber: info.bidNumber,
      contactName: info.contactName,
      customerName: info.customerName,
      id: info.id
    });

  const handleTab = (event, tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    let value = "";
    for (const item of rowsModal.products) {
      value += item.total;
    }
    setRowsModal((prevState) => ({
      ...prevState,
      subtotal: value
    }));
  }, [activeTab]);

  const handleChangeEditInfo = (constant, e) => {
    if (e && constant === "customerInstitute") {
      setEditInfo(prevState => ({
        ...prevState,
        customerName: e.name,
        customerInstitute: `00${e.id}`
      }))
    } else if (e && constant === "accountManager" || constant === "managerSector") {
      setEditInfo(prevState => ({
        ...prevState,
        [constant]: e.user
      }))
    } else if (e && constant === "contactId") {
      setEditInfo(prevState => ({
        ...prevState,
        contactName: e.value,
        contactId: `00${e.id}`
      }))
    } else if (e && constant !== "institution") {
      setEditInfo(prevState => ({
        ...prevState,
        [constant]: e
      }))
    }
  }
  const handleOnUpdate = () => {
    dispatch(updatePurchaseOrder({ info: editInfo, type: "1" })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        Toast.fire({
          title: "Atención",
          html: "Se guardó correctamente",
          type: 'success'
        });
        setRefreshInput(3);
        setShowEditModal(false);
      };
    });
  }
  return (
    <div>
      {alert}

      <Modal
        id="modal-main"
        isOpen={showModal}
      >
        <ModalHeader className="d-flex justify-content-center">
          {"Número de procedimiento: "}
          <a rel="noreferrer" target="_blank" style={{ color: "blue" }}>
            {info.bidNumber}
          </a>
        </ModalHeader>

        <ModalBody>
          <Card>
            <CardBody>
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "1"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "1",
                    })}
                    onClick={(e) => handleTab(e, "1")}
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos Generales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "2"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "2",
                    })}
                    onClick={(e) => handleTab(e, "2")}
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos SAP
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "3"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "3",
                    })}
                    onClick={(e) => handleTab(e, "3")}
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos Adicionales
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <AgreementCREditFormOne
                isDisabled={isDisabled}
                info={info}
                handleChangeInfo={handleChangeEditInfo}
                role={role}
                token={token}
                options={options}
              />
            </TabPane>
            <TabPane tabId="2">
              <AgreementCREditFormTwo
                info={info}
                isDisabled={isDisabled}
                dataProducts={rowsModal}
                options={options}
                handleChangeInfo={handleChangeEditInfo}
                handleOnLoadEditModal={handleOnLoadEditModal}
                role={role}
                token={token}
                setOptions={setOptions}
                editInfo={editInfo}
                setEditInfo={setEditInfo}
                setRowsModal={setRowsModal}
              />
            </TabPane>
            <TabPane tabId="3">
              <AgreementCREditFormThree
                isDisabled={isDisabled}
                info={info}
                evaluations={rowsModal.evaluations}
                options={options}
                handleChangeInfo={handleChangeEditInfo}
                role={role}
                token={token}
                files={rowsModal.files}
                Toast={Toast}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Button color="danger" onClick={() => handleCloseModal()}>
              Cancelar
            </Button>
            <Button color="success" onClick={() => handleOnUpdate()}>
              Guardar
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AgreementCREditModal;
