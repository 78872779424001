// react library
import React, { useState, useEffect } from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {Button, UncontrolledTooltip,} from "reactstrap";

const NewRequest = ({setNewRow, setRealTable}) => {

    const handleOnNewDocument = (event) => {
        setNewRow((prevState) => ({
            ...prevState,
            showNewModal: !prevState.showNewModal,
        }))
    };

    return (

        <>
                <Button
                    className="btn-info btn-round btn-icon"
                    color="default"
                    id="tooltip969372949"
                    onClick={(e) => handleOnNewDocument()}
                    size="sm"
                >
                    <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                    </span>
                    <span className="btn-inner--text">Nueva Solicitud</span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip969372949">
                   Envíar una nueva solicitud PB10
                </UncontrolledTooltip>
       
        </>
    );
};

export default NewRequest;