// nodejs library to format dates
import moment from "moment";
import React, { useState } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

const AgreementEditFormOne = (
  { dataModal, handleChangeInfo, isDisplay, isDisplayBF }
) => {
  const [rowsModal, setRowsModal] = useState([
    {
      colWidth: "4",
      label: "Entidad",
      id: "entity",
      type: "text",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Convenio",
      id: "agreement",
      type: "text",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Orden de compra",
      id: "purchaseOrder",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Fecha maxima de entrega",
      id: "maximumDeliveryDate",
      type: "date",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Fecha de registro",
      id: "registrationDate",
      type: "date",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Fecha de publicacion",
      id: "publicationDate",
      type: "date",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Dias faltantes",
      id: "daysRemaining",
      type: "label",
    },
    {
      colWidth: "4",
      label: "Dia de entrega",
      id: "deliveryDay",
      type: "label",
    },
  ]);
  return (
    <div>
      <Card>
        <CardBody >
          <Row>
            {rowsModal.map((row, key) =>
              row.type === "text" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      className="form-control"
                      type={row.type}
                      disabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                      value={dataModal[row.id] || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : row.type === "date" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      type={row.type}
                      disabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                      value={moment.utc(dataModal[row.id]).format("YYYY-MM-DD") || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                      className="basic-multi-select text-dark"
                    ></Input>
                  </FormGroup>
                </Col>
              ) : (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <h5 htmlFor="">
                      {dataModal[row.id]}
                    </h5>
                  </FormGroup>
                </Col>
              )
            )}
          </Row>
        </CardBody>
      </Card>

    </div>
  );
};
export default AgreementEditFormOne;