/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Modal, Table } from "reactstrap";

const ErrorModal = props => {

  const {
    showModal,
    type,
    errors,
    toggleModal,
  } = props;

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={showModal}
        toggle={() => toggleModal("showModal")}
      >
        {
          type === 'models' ?
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Modelos sin crear</h5>
              </CardHeader>
              <CardBody>
                <div
                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  {
                    errors.map((row, key) => {
                      return (
                        <div className="timeline-block" key={key}>
                          <span className="timeline-step badge-danger">
                            <i className="ni ni-fat-remove" />
                          </span>
                          <div className="timeline-content">
                            <div className="d-flex justify-content-between pt-1">
                              <div>
                                <span className="text-muted text-sm font-weight-bold">
                                  {row.model}
                                </span>
                              </div>
                            </div>
                            <h6 className="text-sm mt-1 mb-0">
                              {row.error}
                            </h6>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </CardBody>
            </Card>
            :
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Partes Críticas sin crear</h5>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>
                      Ingeniero
                    </th>
                    <th>
                      TipoModelo
                    </th>
                    <th>
                      Descripción
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody className="list">
                  {
                    errors.map((row, key) => {
                      return (
                        <tr key={key}>
                          <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row.engineer}
                          </td>
                          <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row.modelType}
                          </td>
                          <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row.description}
                          </td>
                          <td className="table-actions">
                            <Button
                              className="btn-icon btn-2"
                              color="danger"
                              type="button"
                              size="sm"
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-times" />
                              </span>
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Card>
        }
      </Modal>
    </>
  );
};

ErrorModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default ErrorModal;