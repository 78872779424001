/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "CardHeader" el cual llama a los siguientes componentes:
    -CardMain: el cual contiene el diseño y la función para cambiar los TabPanels del componente MainComponent

*Props:
    -setFormSelected: useState que actualiza el tab activo del tabContent del componente MainComponent
    -formSelected: useState que guarda el tab activo del tabContent del componente MainComponent
    -isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

// react library
import React from 'react';
// reactstrap components
import { Col } from "reactstrap";
// Customized Component
import { CardMain } from 'components/Freelance/SharedComponents/CardMain.jsx';

const CardHeader = ({
    setFormSelected,
    formSelected,
    isAdmin
}) => {

    //array con la información de cada Card Principal (4 cards por cada componente Main)
    const cards = [
        {
            title: "Asignación de Items",
            subtitle: "Asignación de Ordenes de Compra, Items, Hitos, Tickets a Proveedores.",
            id: "1",
            image: "asignations.png",
            admin: false,
            colorCardSelected: "#d6d4fa"
        },
        {
            title: "Reporte de tiempos",
            subtitle: "Ver, aprobar, rechazar y exportar reportes de tiempos.",
            id: "2",
            image: "hourRegister.png",
            admin: false,
            colorCardSelected: "#d6d4fa"
        },
        {
            title: "HES",
            subtitle: "Crear, ver, reprocesar y exportar Hojas de Entrada del Portal.",
            id: "3",
            image: "hes.png",
            admin: true,
            colorCardSelected: "#d6d4fa"
        },
        {
            title: "Facturación",
            subtitle: "Aprobar, devolver, ver y exportar facturación L1 y L2.",
            id: "4",
            image: "billing.png",
            admin: false,
            colorCardSelected: "#d6d4fa"
        }

    ];

    return (
        <>

            {
                cards.map((row, key) => (
                    (!row.admin || row.admin === isAdmin) &&
                    <Col key={key} sm={(isAdmin) ? "3" : "4"}>
                        <CardMain
                            title={row.title}
                            subtitle={row.subtitle}
                            setFormSelected={setFormSelected}
                            id={row.id}
                            image={row.image}
                            colorCardSelected={row.colorCardSelected}
                            formSelected={formSelected}
                        >
                        </CardMain>
                    </Col>

                ))
            }

        </>
    );
};

export default CardHeader;