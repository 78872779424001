import CryptoJS from "crypto-js";
import * as constants from "constants/index.jsx";

export const encrypter = (info) => {
  let encrypted = CryptoJS.AES.encrypt(
    info ? info.toString() : "",
    constants.KEY
  ).toString();
  let encodedEncrypted = encodeURIComponent(encrypted); //Esto es para que funcione cuando esta funcion se llama en una API
  return encodedEncrypted;
};
export const desencrypt = (encryptedData) => {
  const decryptedBytes = CryptoJS.AES.decrypt(
    encryptedData ? encryptedData.toString() : "",
    constants.KEY
  );
  const str = decryptedBytes.toString(CryptoJS.enc.Utf8);

  return str;
};
