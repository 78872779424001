/*!

=========================================================
*MasterData - UploadFile
=========================================================

* Este componente se encarga de subir los archivos que el usuario
*desee en el modulo de aprobaciones

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

// Librería de React
import React, { useContext, useState, useEffect } from 'react';

// Componentes de reactstrap
import {
    Container,
    FormGroup,
    Col,
    Row,
} from "reactstrap";

import { useDispatch } from 'react-redux';
import { Upload, Icon } from 'antd';

//Endpoint de masterData
import { deleteFile } from 'actions/masterData';

//Urls
import urls from "api/urls.jsx";

//Importar swal para el toast
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
//Para subir archivos
const { Dragger } = Upload;


export const UploadFile = ({
    id,
    setFileListM,
    fileListM,
    gestion,
    subtitle,
    title,
    stillChargingDocuments,
    setStillChargingDocuments,
    motherTable,
    type,
    documentsNames //Trae los nombres de los documentos que ya han sido subidos anteriormente o sea que están en la tabla, por ejemplos los masivos o de aprobación
}) => {

    //Para disparar al api de ss
    const dispatch = useDispatch();

    //Desestructuración del objeto de fileList
    const { fileList } = fileListM;

    //#region Notificacionr
    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    //#endregion

    // //State diseñado ya que cuando sube un archivo por alguna razón se ejecuta handleOnFileList varias veces, entonces es un indicador para saber si es la primera vez que se ejecuta o la segunda vez o tercera...
    // const [iterations, setIterations] = useState({});

    const [fileListUploaded, setFileListUploaded] = useState([]) //State diseñado para agregar los archivos que ya han sido subidos completamente, no los que están en proceso.

    //Función para subir o eliminar archivos del servidor, gracias a un temporalBOMId
    //se crea una carpeta en el server donde se almacenan los archivos, luego de que se envia la gestión,
    //se cambia el nombre de la carpeta a ese temporalBOMId al id de la gestión de oppRequests
    const handleOnFileList = (info) => {
        // debugger;
        const { status, name } = info.file;

        const existFileinTablesWithSameName = documentsNames.filter(itemDoc => itemDoc.name === info.file.name)
        const existFileInFileListWithSameName = fileList.filter(itemDoc => itemDoc.name === info.file.name)


        if (existFileinTablesWithSameName.length > 0 && status !== "removed") {
            Toast.fire({
                title: "Atención",
                html: "Debe cambiar el nombre del archivo ya que hay uno en la tabla con el mismo nombre.",
                type: "warning",
            });
            return;
        }


        if (existFileInFileListWithSameName.length > 0 && fileListUploaded.includes(name) && status !== "removed") {
            Toast.fire({
                title: "Atención",
                html: "Debe cambiar el nombre del archivo ya que hay uno en la lista con el mismo nombre.",
                type: "warning",
            });
            return;
        }

        console.log(existFileinTablesWithSameName)
        //console.log(existFileInFileListWithSameName)


        console.log(info.file.name)


        if (id === "documentation" && stillChargingDocuments.chargingDocumentationDocs === false) {
            setStillChargingDocuments({ ...stillChargingDocuments, chargingDocumentationDocs: true }) //Se indica que se está cargando el doc en requestDetailModal
        }

        if (id === "corrections" && stillChargingDocuments.chargingCorrectionsDocs === false) {
            setStillChargingDocuments({ ...stillChargingDocuments, chargingCorrectionsDocs: true }) //Se indica que se está cargando el doc en requestDetailModal
        }

        //const { status } = info.file;
        //console.log(status);
        if (status === "removed") {

            const { file: { name, uid, response: { status } } } = info;
            // if (status === 200) {
            id === "documentation" ? (setStillChargingDocuments({ ...stillChargingDocuments, chargingDocumentationDocs: false })) : (/*"Corrections" */setStillChargingDocuments({ ...stillChargingDocuments, chargingCorrectionsDocs: false }))
            setFileListM((prevState) => ({
                ...prevState,
                fileList: fileList.filter((row) => row.uid !== uid),
            }));


            setFileListUploaded(fileListUploaded.filter(file => file != name))

            // setIterations(0);
            // } else {
            //     id=== "documentation" ? (setStillChargingDocuments({ ...stillChargingDocuments, chargingDocumentationDocs: false })): (/*"Corrections" */setStillChargingDocuments({ ...stillChargingDocuments, chargingCorrectionsDocs: false }))
            //     setFileListM((prevState) => (
            //         fileList.filter((row) => row.uid !== uid)
            //     ));
            //     setIterations(0);
            // }


            dispatch(deleteFile({ gestion: gestion, name: name })).then((resp) => {
                const { payload } = resp;
                if (payload.status !== 200) {
                    console.log("Error al intentar borrar el archivo:");
                    console.log(payload)

                    setFileListUploaded(fileListUploaded.filter(file => file != name))
                }
                id === "documentation" ? (setStillChargingDocuments({ ...stillChargingDocuments, chargingDocumentationDocs: false })) : (/*"Corrections" */setStillChargingDocuments({ ...stillChargingDocuments, chargingCorrectionsDocs: false }))
            })



        } else { //Éxito
            const { fileList, file: { status, name } } = info;
            //console.log(info)
            if (status === 'error') {
                console.log(`Ocurrió un error cargando el archivo ${name}.`);
                console.log(info);
                id === "documentation" ? (setStillChargingDocuments({ ...stillChargingDocuments, chargingDocumentationDocs: false })) : (/*"Corrections" */setStillChargingDocuments({ ...stillChargingDocuments, chargingCorrectionsDocs: false }))
                Toast.fire({
                    title: "Atención",
                    html: info.file.response.message,
                    type: "warning",
                });
            } else if (status === "done") {
                id === "documentation" ? (setStillChargingDocuments({ ...stillChargingDocuments, chargingDocumentationDocs: false })) : (/*"Corrections" */setStillChargingDocuments({ ...stillChargingDocuments, chargingCorrectionsDocs: false }))

                setFileListUploaded([...fileListUploaded, name]) //Se agrega en este state los files que ya han sido subidos completamente.

            }
            setFileListM({ fileList: fileList });

        }
    }

    const allowedFileTypes = [
        ".xlsx", ".xls", ".xlsb", ".xlt", ".ppt", ".pptx", ".pdf", ".doc", ".docx",
        ".rar", ".zip", ".cfr", ".csv", ".txt", ".eml", ".jpg", ".png", ".html", ".rtf", ".xml",
        ".css", ".tif", ".ods", ".msg"
    ];


    //Props utilizados para el drag de subido de archivos.
    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: id === "corrections" ? ".xlsx, .xls" : allowedFileTypes.join(","),
        multiple: true,
        action: `${urls.MASTERDATA.uploadFiles}/${gestion}/${motherTable}/${type}`,
        beforeUpload: file => {
            const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
            if (!allowedFileTypes.includes(fileType)) {
                console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
                return false;
            }
            return true;
        },
        onChange: info => {
            console.log(info)
            // Filtrar la lista de archivos válidos para mantener solo los que aún existen
            const filteredFiles = info.fileList.filter(file => {
                console.log(file)
                if (file.status) {
                    return true;
                }
                return false;
            });
            info.fileList = filteredFiles;
            // Actualizar la lista de archivos válidos
            // Llamar a la función handleOnFileList con la lista de archivos válidos
            handleOnFileList(info);
        },
    };
    //console.log(fileListM)
    return (
        <>
            <Row className="mb-2" >

                <Col xs="12" sm='12'>
                    <h3 className="mb-0">{title}</h3>
                    <p className="text-sm mb-0">{subtitle}</p>


                </Col>
            </Row>
            <hr align="left" width="85%" xs="12" className='mt-1 mb-4' />
            <Row className="align-items-center">
                <Col xs="12">
                    <FormGroup>
                        <div className="form-row align-items-center">
                            <Col className="m-1" sm="12">
                                <Container>

                                    <Dragger {...uploadProps} fileList={fileList}>
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox" />
                                        </p>
                                        <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                                        <p className="ant-uploading-hint">
                                            Puede seleccionar archivos de diferentes folders/carpetas
                                        </p>
                                    </Dragger>

                                </Container>
                            </Col>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}
