import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const masterVariables = handleActions(
  {
    [constants.GET_MASTER_VARIABLES_BY_MASTER_TABLE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.CLEAR_DATA]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const usersEscalationsTss = handleActions(
  {
    [constants.GET_ALL_USER_ESCALATION_TSS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.data;
      }
      return {};
    },
    [constants.CLEAR_DATA]: () => {
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);
