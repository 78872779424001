// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

moment.locale("es");

const EditContactModal = props => {

  const {
    title,
    options: {
      showModal,
      initialValues,
    },
    loading,
    message,
    toggleModal,
    onSubmit,
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.name || values.name === "")
      errors.name = "Debes ingresar el nombre de contacto.";
    if (!values.phone || values.phone === "")
      errors.phone = "Debes ingresar el teléfono de contacto.";
    if (!values.relation || values.relation === "")
      errors.relation = "Debes ingresar el parentesco.";
    if (!values.address || values.address === "")
      errors.address = "Debes ingresar la dirección.";
    return errors;
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={initialValues}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label className="form-control-label" for="name">Nombre Completo</Label>
                      <Field name="name">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Escriba aquí el nombre completo"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" for="phone">Teléfono</Label>
                      <Field name="phone">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="number"
                              placeholder="Escriba aquí el número de teléfono"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" for="relation">Parentesco</Label>
                      <Field name="relation">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="text"
                              placeholder="Escriba aquí el tipo de parentesco"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" for="address">Dirección (500)</Label>
                      <Field name="address">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="textarea"
                              placeholder="Escriba aquí la dirección exacta."
                              maxLength={500}
                              rows="2"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-2"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Actualizar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

EditContactModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default EditContactModal;