// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";

const getMonths = () => {
  const array = [];
  for (let i = 0; i < 12; i++) {
    array.push(0);
  }
  return array;
};

// labels chart
const labels = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Colors charts
const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

// options charts Bar
const optionsBarLegend = {
  legend: {
    position: "top",
    display: true,
    // fullWidth: true,
    labels: {
      usePointStyle: false,
    },
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: false,
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          },
        },
      },
    ],
  },
};

// options charts Pie
const optionsPie = {
  responsive: true,
  legend: {
    position: "top",
    display: true,
    // fullWidth: true,
    labels: {
      usePointStyle: false,
    },
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        return `${dataset.data[tooltipItem.index]}`;
      },
    },
  },
};

export const getContractsOnHold = (state) => {
  const { contractsOnHold } = state;
  if (Object.keys(contractsOnHold).length) {
    const { contracts } = contractsOnHold;
    return contracts.map((row) => {
      row.daysSinceOnHold = moment().diff(moment(row.onHoldDate), "days");
      return row;
    });
  }
  return [];
};

export const getStatusAvailable = (state) => {
  const { statusContractsOnHold } = state;
  if (Object.keys(statusContractsOnHold).length) {
    const { status } = statusContractsOnHold;
    return status;
  }
  return [];
};

export const getStatusAvailableManagement = (state) => {
  const { statusContractsOnHold } = state;
  if (Object.keys(statusContractsOnHold).length) {
    const { statusManagement } = statusContractsOnHold;
    return statusManagement;
  }
  return [];
};

export const getActivityFlowsByContractID = (state) => {
  const { activityFlowByContractOnHold } = state;
  if (Object.keys(activityFlowByContractOnHold).length) {
    const { activityLogs } = activityFlowByContractOnHold;
    return activityLogs;
  }
  return [];
};

export const getUsersNotifications = (state) => {
  const { usersNotifications } = state;
  if (Object.keys(usersNotifications).length) {
    const { users } = usersNotifications;
    return users;
  }
  return [];
};

export const getUsersEscalations = (state) => {
  const { usersEscalations } = state;
  if (Object.keys(usersEscalations).length) {
    const { users } = usersEscalations;
    return users;
  }
  return [];
};

export const getServicesNotificationEscalations = (state) => {
  const { usersEscalations, usersNotifications } = state;
  if (Object.keys(usersNotifications).length) {
    const { services } = usersNotifications;
    return services;
  } else if (Object.keys(usersEscalations).length) {
    const { services } = usersEscalations;
    return services;
  }
  return [];
};

export const getCountriesNotificationEscalations = (state) => {
  const { usersNotifications, usersEscalations } = state;
  if (Object.keys(usersNotifications).length) {
    const { countries } = usersNotifications;
    return countries;
  } else if (Object.keys(usersEscalations).length) {
    const { countries } = usersEscalations;
    return countries;
  }
  return [];
};

export const getTransacctionsContractsOnHold = (state) => {
  const { contractsOnHold } = state;
  if (Object.keys(contractsOnHold).length) {
    const { contracts } = contractsOnHold;
    let mountlyValues = 0;
    let netValues = 0;
    for (const element of contracts) {
      mountlyValues += parseInt(element.monthlyValue);
      netValues += parseInt(element.netValue);
    }
    return {
      amountContracts: contracts.length,
      amountMonthlyValue: mountlyValues,
      amountNetValue: netValues,
    };
  }
  return {};
};

export const getDataDashboard = (state) => {
  const { dataDashboardContracts } = state;
  const labelsContracts = [];
  const dataContracts = [];
  const dataContractsDays = [];
  if (Object.keys(dataDashboardContracts).length) {
    const { data, dataDays } = dataDashboardContracts;
    for (const element of data) {
      labelsContracts.push(element.label);
      dataContracts.push(element.total);
      dataContractsDays.push(0);
    }
    for (const element of dataDays) {
      if (element) {
        const index = labelsContracts.findIndex((row) => row === element.label);
        const daysSinceOnHold = moment().diff(
          moment(element.onHoldDate),
          "days"
        );
        dataContractsDays[index] = dataContractsDays[index] += daysSinceOnHold;
      }
    }
    return {
      data: {
        labels: labelsContracts,
        datasets: [
          {
            label: "Contratos On Hold",
            data: dataContracts,
            backgroundColor: colors.theme["success"],
          },
        ],
      },
      options: optionsBarLegend,
    };
  }
  return {
    data: {
      labelsContracts,
      datasets: [
        {
          label: "Contratos On Hold",
          data: dataContracts,
          backgroundColor: colors.theme["success"],
        },
      ],
    },
    options: optionsBarLegend,
  };
};

export const getDataDaysDashboard = (state) => {
  const { dataDashboardContracts } = state;
  const data = [];
  const labels = [];
  const colors = [];
  if (Object.keys(dataDashboardContracts).length) {
    const { dataDays } = dataDashboardContracts;
    for (const element of dataDays) {
      if (labels.some((row) => row === element.label)) {
        const index = labels.findIndex((row) => row === element.label);
        const daysSinceOnHold = moment().diff(
          moment(element.onHoldDate),
          "days"
        );
        data[index] = data[index] += daysSinceOnHold;
        colors.push("#" + Math.floor(Math.random() * 16777215).toString(16));
      } else {
        labels.push(element.label);
        const daysSinceOnHold = moment().diff(
          moment(element.onHoldDate),
          "days"
        );
        data.push(daysSinceOnHold);
        colors.push("#" + Math.floor(Math.random() * 16777215).toString(16));
      }
    }
    return {
      data: {
        labels: labels,
        datasets: [
          {
            data,
            backgroundColor: colors,
          },
        ],
      },
      options: optionsPie,
    };
  }
  return {
    data: {
      labels: labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
        },
      ],
    },
    options: optionsPie,
  };
};
export const getDataGraph = (state) => {
  const { dataGraphContracts } = state;
  const graph = [];
  let totalsByMonth = {};

  if (Object.keys(dataGraphContracts).length) {
    const { data, totalsByMonth: totals } = dataGraphContracts;
    totalsByMonth = totals; // Guardar totalsByMonth en una variable local

    for (const element of data) {
      if (graph.some((row) => row.country === element.country)) {
        const index = graph.findIndex((row) => row.country === element.country);
        graph[index][element.month.trim()] = element.sum;
        graph[index]["total"] += parseInt(element.sum);
      } else {
        graph.push({
          country: element.country,
          [element.month.trim()]: element.sum,
          total: parseInt(element.sum),
        });
      }
    }
  }

  return { graph, totalsByMonth }; // Retornar un objeto con ambos valores
};

export const getDataGraphByCountry = (state) => {
  const { dataGraphContractsByCountry } = state;
  const graph = [];
  if (Object.keys(dataGraphContractsByCountry).length) {
    const { data } = dataGraphContractsByCountry;
    for (const element of data) {
      if (graph.some((row) => row.country === element.customerName)) {
        const index = graph.findIndex(
          (row) => row.country === element.customerName
        );
        graph[index][element.month.trim()] = element.sum;
        graph[index]["total"] += parseInt(element.sum);
      } else {
        graph.push({
          country: element.customerName,
          [element.month.trim()]: element.sum,
          total: parseInt(element.sum),
        });
      }
    }
    return graph;
  }
  return [];
};

export const getDataGraphTrend = (state) => {
  let monthsS = getMonths();
  let monthsS2 = getMonths();
  let monthsS3 = getMonths();
  const { dataDashboardContracts } = state;
  if (Object.keys(dataDashboardContracts).length) {
    const { dataTrend, dataTrend2, dataTrend3 } = dataDashboardContracts;
    for (const element of dataTrend) {
      monthsS[element.month - 1] = element.total;
    }
    for (const element of dataTrend2) {
      monthsS2[element.month - 1] = element.total;
    }
    for (const element of dataTrend3) {
      monthsS3[element.month - 1] = element.total;
    }
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Contratos On Hold",
            data: monthsS,
            borderColor: colors.theme["primary"],
            backgroundColor: colors.theme["primary"],
            fill: false,
            pointRadius: 4,
          },
          {
            label: "Delayed",
            data: monthsS2,
            borderColor: colors.theme["warning"],
            backgroundColor: colors.theme["warning"],
            fill: false,
            pointRadius: 4,
          },
          {
            label: "No Delayed",
            data: monthsS3,
            borderColor: colors.theme["success"],
            backgroundColor: colors.theme["success"],
            fill: false,
            pointRadius: 4,
          },
        ],
      },
      options: {
        responsive: true,
        hoverMode: "index",
        stacked: false,
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          position: "top",
          display: true,
          fullWidth: true,
          labels: {
            usePointStyle: false,
          },
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              stacked: false,
            },
          ],
          yAxes: [
            {
              stacked: false,
              ticks: {
                callback: function (value) {
                  if (!(value % 1)) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
    };
  }
  return {
    data: {
      labels,
      datasets: [
        {
          label: "Contratos On Hold",
          data: monthsS,
          borderColor: colors.theme["primary"],
          backgroundColor: colors.theme["primary"],
          fill: false,
          pointRadius: 4,
        },
      ],
    },
    options: {
      responsive: true,
      hoverMode: "index",
      stacked: false,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        position: "top",
        display: true,
        fullWidth: true,
        labels: {
          usePointStyle: false,
        },
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      scales: {
        xAxes: [
          {
            stacked: false,
          },
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              callback: function (value) {
                if (!(value % 1)) {
                  return value;
                }
              },
            },
          },
        ],
      },
    },
  };
};
export const getDataGraphTrendTotalValue = (state) => {
  let monthsS = getMonths();
  let monthsS2 = getMonths();
  let monthsS3 = getMonths();
  const { dataDashboardContracts } = state;
  if (Object.keys(dataDashboardContracts).length) {
    const { dataTrend, dataTrend2, dataTrend3 } = dataDashboardContracts;
    for (const element of dataTrend) {
      monthsS[element.month - 1] = element.totalNetValueForMonth;
    }
    for (const element of dataTrend2) {
      monthsS2[element.month - 1] = element.totalNetValueForMonth;
    }
    for (const element of dataTrend3) {
      monthsS3[element.month - 1] = element.totalNetValueForMonth;
    }
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Valor Total",
            data: monthsS,
            borderColor: "#1f77b4",
            backgroundColor: "#1f77b4",
            fill: false,
            pointRadius: 4,
          },
          {
            label: "Valor Delayed",
            data: monthsS2,
            borderColor: "#ff7f0e",
            backgroundColor: "#ff7f0e",
            fill: false,
            pointRadius: 4,
          },
          {
            label: "Valor No Delayed",
            data: monthsS3,
            borderColor: "#2ca02c",
            backgroundColor: "#2ca02c",
            fill: false,
            pointRadius: 4,
          },
        ],
      },
      options: {
        responsive: true,
        hoverMode: "index",
        stacked: false,
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          position: "top",
          display: true,
          fullWidth: true,
          labels: {
            usePointStyle: false,
          },
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              stacked: false,
            },
          ],
          yAxes: [
            {
              stacked: false,
              ticks: {
                callback: function (value) {
                  if (!(value % 1)) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
    };
  }
  return {
    data: {
      labels,
      datasets: [
        {
          label: "Contratos On Hold",
          data: monthsS,
          borderColor: "#1f77b4",
          backgroundColor: "#1f77b4",
          fill: false,
          pointRadius: 4,
        },
      ],
    },
    options: {
      responsive: true,
      hoverMode: "index",
      stacked: false,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        position: "top",
        display: true,
        fullWidth: true,
        labels: {
          usePointStyle: false,
        },
      },
      tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (item, data) {
            var datasetLabel = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var formattedYLabel = yLabel.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            });
            return `${datasetLabel}: ${formattedYLabel}`;
          },
        },
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      scales: {
        xAxes: [
          {
            stacked: false,
          },
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              callback: function (value) {
                if (!(value % 1)) {
                  return value;
                }
              },
            },
          },
        ],
      },
    },
  };
};

export const getFiltersDashboard = (state) => {
  const { dataDashboardContracts } = state;
  if (Object.keys(dataDashboardContracts).length) {
    let {
      filters: { countries, services, status },
    } = dataDashboardContracts;
    countries = countries.map((row, key) => {
      return {
        value: key + 1,
        label: row.country,
      };
    });
    countries = countries
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => countries[e])
      .map((e) => countries[e]);
    countries = countries.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    services = services.map((row, key) => {
      return {
        value: key + 1,
        label: row.service,
      };
    });
    services = services
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => services[e])
      .map((e) => services[e]);
    services = services.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    status = status.map((row, key) => {
      return {
        value: key + 1,
        label: row.name,
      };
    });
    status = status
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => status[e])
      .map((e) => status[e]);
    status = status.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return {
      countries,
      services,
      status,
    };
  }
  return {
    countries: [],
    services: [],
    status: [],
  };
};

export const getDataMasterServices = (state) => {
  const { dataMaster } = state;
  if (Object.keys(dataMaster).length) {
    return dataMaster.dataMaster;
  } else {
    return [];
  }
};

export const getServicesAvailable = (state) => {
  const { contractsOnHold } = state;
  if (Object.keys(contractsOnHold).length) {
    const { contracts } = contractsOnHold;
    let services = [];
    for (const element of contracts) {
      if (!element.services.length) {
        services = [...services, "N/A"];
      } else {
        for (const service of element.services) {
          services = [...services, service.serviceGroup];
        }
      }
    }
    services = services
      .map((e) => e)
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => services[e])
      .map((e) => services[e]);
    services = services.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return services;
  }
  return [];
};

export const getAllUsersFMAndPM = (state) => {
  const { userFMAndPM } = state;
  if (Object.keys(userFMAndPM).length) {
    const { usersFM, usersPM } = userFMAndPM;
    return {
      usersFM,
      usersPM,
    };
  }
  return {
    usersFM: [],
    usersPM: [],
  };
};

export const getTargetStartLogsByContractID = (state) => {
  const { activityFlowByContractOnHold } = state;
  if (Object.keys(activityFlowByContractOnHold).length) {
    const { targetStartLogs } = activityFlowByContractOnHold;
    return targetStartLogs;
  }
  return [];
};

export const getUsersAccessOnHold = (state) => {
  const { usersAccessOnHold } = state;
  if (Object.keys(usersAccessOnHold).length) {
    const { users } = usersAccessOnHold;
    return users;
  }
  return [];
};

export const getAllRolesAvailable = (state) => {
  const { usersAccessOnHold } = state;
  if (Object.keys(usersAccessOnHold).length) {
    const { roles } = usersAccessOnHold;
    return roles;
  }
  return [];
};
