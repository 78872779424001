// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const NotFoundDB = props => {
  const { title, body, reload, onReload } = props;
  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col sm="12">
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{body}</p>
            </Col>
          </Row>
          <CardBody>
            {
              reload ?
                <Row className="align-items-center">
                  <Col className="mb-3" sm="12" md="3">
                    <Button
                      className="btn-icon"
                      block
                      color="info"
                      type="button"
                      onClick={onReload}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-redo-alt" />
                      </span>
                      <span className="btn-inner--text">Volver a intentar</span>
                    </Button>
                  </Col>
                </Row>
                : null
            }
          </CardBody>
        </CardHeader>
      </Card>
    </>
  );
};

NotFoundDB.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default NotFoundDB;