import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody, Col, Container, Row, FormGroup, InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
// antd components
import { Spin } from 'antd';
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// core components Document System
import CardFilters from 'components/HCMHiring/CardFilters.jsx';
import MainTable from 'components/HCMHiring/MainTable.jsx';
import NewRequest from 'components/HCMHiring/NewRequest.jsx';
import NewHiringModal from 'components/HCMHiring/NewHiringModal.jsx';
import EditHiringModal from 'components/HCMHiring/EditHiringModal.jsx';

import { MultiSelect } from "react-multi-select-component";

import { getUserProtected, getTeamsUserAccess } from 'selectors/adminLayout.jsx';

import { getDataRows, getOptionsData } from 'actions/hcmHiring.jsx';

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import "../../assets/css/DocumentSystem.css"

const Inicio = (props) => {

    //#region Funciones

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region Mensajes de notificacion

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    //#endregion

    //#region Verificar si es Admin o no

    const [isAdmin, setIsAdmin] = useState(false);
    const [isManager, setIsManager] = useState(false);
    useEffect(() => {
        const teams = getTeamsUserAccess();
        if (teams.some((row) => row.indexOf("HCM Hiring Admin") !== -1)) {
            setIsAdmin(true)
        }
        else if (teams.some((row) => row.indexOf("HCM Hiring Manager") !== -1)) {
            setIsManager(true)
        }

    }, []);
    //#endregion

    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(true);
    //#endregion

    //#region estado principal
    const initialState = {
        rowsTable: [],
        rows: [],
        editRow: {},
        editModal: false,
        showNewModal: false,
        listId: [],
        candidatePersonalData: {},
        candidateEducation: {},
        candidateExperience: {},
        candidateInfo: {},
        filesData: []
    }

    const [state, setState] = useState(initialState);

    const { rowsTable, rows, editRow, editModal, showNewModal, listId, candidatePersonalData, candidateEducation, candidateExperience, filesData } = state;
    //#endregion

    //#region estado de las listas de los dropdowns
    const [options, setOptions] = useState({
        dropDownOptions: {},
        statusOptions: [{
            value: "",
            label: "",
            statusType: "",
        }],
    });

    const { dropDownOptions, statusOptions } = options;
    //#endregion

    //#region funcion para extraer las opciones
    useEffect(() => {
        const getOptionsR = async () => {
            await getOptions();
            // setLoad(false);
        }
        getOptionsR();
    }, [])

    const getOptions = async () => {

        dispatch(getOptionsData()).then((resp) => {
            const { payload } = resp;
           
            if (payload.status === 200) {
                setOptions({
                    dropDownOptions: payload.data.payload.masterData,
                    statusOptions: payload.data.payload.masterData.StatusApplication,
                })
                // setOptions(payload.data.payload.vendors)
            }

        })
    }
//    console.log(dropDownOptions);
    //#endregion

    //#region estado de las listas para los filtros del componente CardFilters (si se usa)
    const [lists, setLists] = useState({

        status: {
            value: statusOptions[0],
            statusOptions
        },
        country: {
            value: "",
            // countryOptions
        },
        iDateSelected: {
            value: ""
        },
        fDateSelected: {
            value: ""
        }
    })
    const { status, country } = lists;

    //state para recargar la tabla principal cuando se necesite
    const [reloadTable, setRealTable] = useState(false);
    //#endregion

    //#region state para llenar la tabla principal
    useEffect(() => {
        setLoad(true);
        const getDataR = async () => {
            await getData(lists);
        }
        getDataR();
    }, [reloadTable]);
    //#endregion

    //#region funcion para extraer las solicitudes de acuerdo a los filtros
    const getData = async (lists) => {
        dispatch(getDataRows({})).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
                setState((filt) => ({
                    ...filt,
                    rows: payload.data.payload.rows,
                    rowsTable: payload.data.payload.rows,
                    lengthTable: payload.data.payload.rows.length,
                }))

            } else {
                setState(
                    initialState
                )

            }
            setLoad(false);
        })
    }
    //#endregion

    //#region funcion search client side
    const [search, setSearch] = useState("");

    //función para buscar por texto los useEffect corren una vez
    useEffect(() => {
        let rowss = [];
        const searchLower = search.toLowerCase();
        for (const element of rowsTable) {
            for (const value in element) {
                if (element[value] && element[value].toString().toLowerCase().indexOf(searchLower) !== -1 && !rowss.some((e) => JSON.stringify(e) === JSON.stringify(element))) {
                    rowss.push(element);
                }
            }
        }
        setState((filt) => ({
            ...filt,
            rows: searchLower.length ? rowss : rowsTable
        }))
    }, [search]);
    //#endregion

    //#region state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);
    //#endregion

    //#region columnas de la tabla principal y multicolumn
    const optionsSelect = [

        { value: "detalle", label: "Detalle del Candidato", disabled: true },
        { value: "id", label: "Id" },
        { value: "countryName", label: "País" },
        { value: "emailCandidate", label: "Email" },
        { value: "position", label: "Num. Posición", disabled: true },
        { value: "jobTitle", label: "Puesto" },
        { value: "vacancyName", label: "Tipo Vacante" },
        { value: "eGroupName", label: "Tipo Plaza" },
        { value: "eSubGroupName", label: "Tipo Posicion" },
        { value: "personalAreaName", label: "Personal Área" },
        { value: "subAreaName", label: "Sub Área" },
        { value: "statusName", label: "Estado" },
        { value: "response", label: "Respuesta" },
        { value: "createdBy", label: "Solicitante" },
        { value: "cDate", label: "Fecha" },
        { value: "cDate", label: "Fecha Candidato" },
        { value: "cDate", label: "Fecha Finalizada" },
        { value: "createPB10", label: "Crear PB10", disabled: true },

    ];
    const [columnSelected, setSelected] = useState(
        [
            { value: "detalle", label: "Detalle" },
            { value: isManager ? "vacancyName" : "countryName", label: isManager ? "Tipo Vacante" : "País" },
            { value: "position", label: "Posición" },
            { value: "jobTitle", label: "Puesto" },
            { value: (isAdmin || isManager) ? "createdBy" : "eGroupName", label: (isAdmin || isManager) ? "Solicitante" : "Tipo Plaza" },
            { value: "cDate", label: "Fecha" },
            { value: "statusName", label: "Estado" },
            { value: "response", label: "Respuesta" },
            { value: "createPB10", label: "Crear PB10" },
        ]
    );
    //#endregion
    

    //#endregion
    return (

        <>
            <NewHiringModal
                showModal={showNewModal}
                setModal={setState}
                Toast={Toast}
                setRealTable={setRealTable}
                rows={rows}
                isAdmin={isAdmin}
                dropDownOptions={dropDownOptions}
         
            />
            <EditHiringModal
                showModal={editModal}
                setModal={setState}
                row={editRow}
                Toast={Toast}
                isAdmin={isAdmin}
                isManager={isManager}
                candidatePersonalData= {candidatePersonalData}
                candidateEducation= {candidateEducation}
                candidateExperience= {candidateExperience}
                filesData={filesData}
                dropDownOptions={dropDownOptions}
            />
            <div className="rna-wrapper">
                <NotificationAlert useRef="notificationAlert" />
            </div>
            <AdminHeader name="Inicio" parentName="Contrataciones PB10" />
            <Spin size="large" spinning={loading}>
                <Container className="mt--6" fluid>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm='12'>
                                    <CardFilters
                                        lists={lists}
                                        setLists={setLists}
                                        setLoad={setLoad}
                                        Toast={Toast}
                                        isAdmin={isAdmin}
                                        isManager={isManager}
                                        page={page}
                                        setPage={setPage}
                                        dropDownOptions={dropDownOptions}
                                        setRealTable={setRealTable}
                                        rows = {rows}
                                        rowsTable={rowsTable}
                                        setRows = {setState}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='12' style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <NewRequest 
                                     setNewRow={setState}
                                     setRealTable={setRealTable}
                                    />
                                </Col>
                            </Row>
                            <Row>

                                <Col sm='12'>

                                    <Card>
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col xs="6" md='6'>
                                                    <h3 className="mb-0">{"Solicitudes del país"}</h3>
                                                    <p className="text-sm mb-0">{"A continuación se muestra la tabla de contrataciones de GBM"}</p>

                                                </Col>
                                                <Col xs="6" md="6" style={{ display: "flex", justifyContent: "flex-end" }}>

                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="search">
                                                            Buscar
                                                        </label>
                                                        <InputGroup className="input-group-merge">
                                                            <Input
                                                                id="search"
                                                                className="m-0"
                                                                style={{ height: "calc(2.25rem + 2px)" }}
                                                                placeholder="Busque por palabra clave"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                            />
                                                            {/* <InputGroupAddon addonType="append">
                                                                <Button color="success" size="sm" outline> 
                                                                    <span className="btn-inner--icon">
                                                                        <i className="fas fa-search" />
                                                                    </span></Button>
                                                            </InputGroupAddon> */}
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>



                                            </Row>
                                            <Row>
                                                <Col sm='2'>
                                                    <MultiSelect
                                                        options={optionsSelect}
                                                        value={columnSelected}
                                                        onChange={setSelected}
                                                        labelledBy="Select"
                                                    />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {/* el cardbody y card footter se encuentran en el componente maintable */}
                                        <MainTable
                                            columns={columnSelected}
                                            rows={rows}
                                            setEditRow={setState}
                                            status={statusOptions}
                                            page={page}
                                            setPage={setPage}
                                            dropDownOptions={dropDownOptions}
                                            Toast={Toast}
                                            setRealTable={setRealTable}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </Spin>
        </>
    )
}

export default Inicio;
