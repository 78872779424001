// core antd
import { Drawer, Spin } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useEffect } from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const EditCriticalPart = (props) => {
  const { visible, uploading, initialValues, onClose, onEdit } = props;
  useEffect(() => {
    if (Object.keys(initialValues).length) {
      initialValues.redundancy = initialValues.redundancy === "SI" ? "1" : "0";
      initialValues.classify = initialValues.classify === "Crítica" ? "1" : "2";
    }
  });

  const validate = (values) => {
    const errors = {};
    if (!values.engineer || values.engineer === "")
      errors.engineer = "El campo es obligatorio.";
    if (!values.modelType || values.modelType === "")
      errors.modelType = "El campo es obligatorio.";
    if (!values.platform || values.platform === "")
      errors.platform = "El campo es obligatorio.";
    if (!values.family || values.family === "")
      errors.family = "El campo es obligatorio.";
    if (!values.category || values.category === "")
      errors.category = "El campo es obligatorio.";
    if (!values.description || values.description === "")
      errors.description = "El campo es obligatorio.";
    if (!values.redundancy || values.redundancy === "2")
      errors.redundancy = "El campo es obligatorio.";
    if (!values.classify || values.classify === "0")
      errors.classify = "El campo es obligatorio.";
    return errors;
  };

  return (
    <>
      <Drawer
        title="Modificar una parte crítica"
        width={340}
        onClose={() => onClose("editPart")}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          onSubmit={onEdit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, values, submitting, validating, valid }) => (
            <Spin
              size="large"
              spinning={uploading}
              tip="Actualizando la parte crítica"
            >
              <FormRS role="form">
                <div className="form-row">
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="engineer">Ingeniero</Label>
                      <Field name="engineer">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Escriba aquí el nombre del ingeniero."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="modelType">Tipo de Modelo</Label>
                      <Field name="modelType">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Escriba aquí el tipo de modelo."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="platform">Plataforma</Label>
                      <Field name="platform">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Escriba aquí la plataforma."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="family">Familia</Label>
                      <Field name="family">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Escriba aquí el nombre de la familia."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="category">Categoria</Label>
                      <Field name="category">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Escriba aquí la categoria."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="description">Descripción</Label>
                      <Field name="description">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="textarea"
                              placeholder="Escriba aquí la descripción."
                              maxLength={2500}
                              rows="3"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="redundancy">Tiene Redundancia</Label>
                      <Field name="redundancy">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              invalid={meta.error && meta.touched}
                            >
                              <option value="2">Seleccione una opción</option>
                              <option value="0">No</option>
                              <option value="1">Sí</option>
                            </Input>
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="classify">Clasificación de la parte</Label>
                      <Field name="classify">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              invalid={meta.error && meta.touched}
                            >
                              <option value="0">Seleccione una opción</option>
                              <option value="1">Crítica</option>
                              <option value="2">Sensitiva</option>
                            </Input>
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12">
                    <Button
                      disabled={submitting || uploading}
                      className="btn-icon"
                      block
                      color="success"
                      type="submit"
                      onClick={handleSubmit || validating}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">
                        Modificar Parte Crítica
                      </span>
                    </Button>
                  </Col>
                </div>
              </FormRS>
            </Spin>
          )}
        />
      </Drawer>
    </>
  );
};

EditCriticalPart.propTypes = {
  visible: PropTypes.bool.isRequired,
  uploading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default EditCriticalPart;
