/*
=========================================================
*Hours Report - (CATS, OS / NETWORK OR Extra Hours)
=========================================================

*Description.
Component to show all reports at a Table format in any of the Hours Report Modules (CATS, OS / NETWORK OR Extra Hours)
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import ImportExcel from "react-html-table-to-excel";

import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Label,
  Input,
} from "reactstrap";

import { FaLongArrowAltLeft } from "react-icons/fa";
import { RiFileAddFill } from "react-icons/ri";
import { MdInfoOutline } from "react-icons/md";
import { PiExportBold } from "react-icons/pi";

function RecordTable({
  title,
  description,
  buttonName,
  module,
  buttonFunction,
  page,
  sizePerPage,
  setPage,
  preparePageData,
  columns,
  formatData,
  goBackButton,
  filtersLabels,
  filtersPlaceHolder,
  filtersData,
  setFilter,
  applyFilters,
  typesOfTasks,
  ceoStatus,
  preapproverStatus,
  allRecords,
}) {
  const dataWithFilters = applyFilters(filtersData);

  const { startIndex, endIndex, currentPageData } = preparePageData(
    dataWithFilters,
    page,
    sizePerPage
  );

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col sm="12" md="10">
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{description}</p>
          </Col>
          <Col
            sm="12"
            md="2"
            className="d-flex justify-content-end align-items-center mt-1 mb-1"
          >
            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                height: "2rem",
                fontSize: "0.75rem",
                marginRight: "1rem",
                marginTop: "1rem",
                justifyContent: "center",
              }}
              onClick={goBackButton}
            >
              <span className="btn-inner--text">
                <FaLongArrowAltLeft
                  style={{
                    color: "white",
                    fontSize: "15px",
                    marginRight: "2px",
                  }}
                />
                Retroceder
              </span>
            </Button>
            <Button
              className="btn-icon"
              color="success"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                height: "2rem",
                fontSize: "0.75rem",
                marginTop: "1rem",
                justifyContent: "center",
              }}
              onClick={buttonFunction}
            >
              <span className="btn-inner--text">
                <RiFileAddFill
                  style={{
                    color: "white",
                    fontSize: "15px",
                    marginBottom: "3px",
                    marginRight: "2px",
                  }}
                />
                {buttonName}
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            <span className="font-weight-bold">Filtrar por:</span>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col sm="4" style={{ marginBottom: "1rem" }}>
            <Label
              for="os_item"
              className="font-weight-bold"
              style={{ fontSize: "13px" }}
            >
              {filtersLabels[0]}
            </Label>
            <Input
              type="text"
              id="os_item"
              placeholder={filtersPlaceHolder[0]}
              autoComplete="off"
              bsSize="sm"
              onChange={(e) => {
                setFilter("filter1", e.target.value);
              }}
            />
          </Col>

          {typesOfTasks && (
            <>
              <Col sm="4" style={{ marginBottom: "1rem" }}>
                <Label
                  for="network"
                  className="font-weight-bold"
                  style={{ fontSize: "13px" }}
                >
                  {filtersLabels[1]}
                </Label>
                <Input
                  type="text"
                  id="network"
                  placeholder={filtersPlaceHolder[1]}
                  autoComplete="off"
                  bsSize="sm"
                  onChange={(e) => {
                    setFilter("filter2", e.target.value);
                  }}
                />
              </Col>
              <Col sm="4" style={{ marginBottom: "1rem" }}>
                <Label
                  for="task_type"
                  className="font-weight-bold"
                  style={{ fontSize: "13px" }}
                >
                  {filtersLabels[2]}
                </Label>
                <Input
                  type="select"
                  id="task_type"
                  autoComplete="off"
                  bsSize="sm"
                  onChange={(e) => {
                    setFilter("filter3", e.target.value);
                  }}
                  defaultValue={""}
                >
                  <option value="">{filtersPlaceHolder[2]}</option>
                  {typesOfTasks.map((task) => (
                    <option key={task} value={task}>
                      {task}
                    </option>
                  ))}
                </Input>
              </Col>
            </>
          )}

          {ceoStatus && (
            <>
              <Col sm="4" style={{ marginBottom: "1rem" }}>
                <Label
                  for="network"
                  className="font-weight-bold"
                  style={{ fontSize: "13px" }}
                >
                  {filtersLabels[1]}
                </Label>
                <Input
                  type="select"
                  id="task_type"
                  bsSize="sm"
                  autoComplete="off"
                  onChange={(e) => {
                    setFilter("filter2", e.target.value);
                  }}
                  defaultValue={""}
                >
                  <option value="">{filtersPlaceHolder[1]}</option>
                  {preapproverStatus.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col sm="4" style={{ marginBottom: "1rem" }}>
                <Label
                  for="task_type"
                  className="font-weight-bold"
                  style={{ fontSize: "13px" }}
                >
                  {filtersLabels[2]}
                </Label>
                <Input
                  type="select"
                  id="task_type"
                  autoComplete="off"
                  bsSize="sm"
                  onChange={(e) => {
                    setFilter("filter3", e.target.value);
                  }}
                  defaultValue={""}
                >
                  <option value="">{filtersPlaceHolder[2]}</option>
                  {ceoStatus.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Input>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                height: "2.4rem",
              }}
            >
              <ImportExcel
                id="BotonExportarHoursReport"
                className="btn btn-primary"
                table="tablaReportesExportar"
                filename={`Historial de reportes ${module}`}
                sheet="Hoja 1"
                buttonText={
                  <>
                    <PiExportBold
                      style={{
                        color: "white",
                        fontSize: "18px",
                        marginRight: "2px",
                      }}
                    />{" "}
                    Exportar
                  </>
                }
              />
            </div>
          </Col>
        </Row>
      </CardHeader>
      <Table
        id="tablaReportes"
        className="align-items-center table-flush"
        responsive
        striped
      >
        <thead className="thead-light">
          <tr>
            {columns.map((column, index) => (
              <th key={index} className="font-weight-bold">
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="list">
          {currentPageData.length ? (
            currentPageData.map((row, key) => (
              <React.Fragment key={key}>
                {/* Renderiza el mensaje de rechazo al principio de la fila */}
                {row.sapStatus === "No enviar HC" && (
                  <tr style={{ backgroundColor: "rgba(255, 0, 0, 0.1)" }}>
                    <td
                      colSpan={columns.length}
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        paddingTop: "5px",
                        paddingLeft: "10px",
                      }}
                    >
                      Reporte rechazado por Payroll
                    </td>
                  </tr>
                )}
                {/* Renderiza el contenido de la fila */}
                <tr
                  style={
                    row.sapStatus === "No enviar HC"
                      ? { backgroundColor: "rgba(255, 0, 0, 0.1)" }
                      : {}
                  }
                >
                  {columns.map((column, index) => (
                    <td
                      key={index}
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify",
                        wordWrap: "break-word",
                      }}
                    >
                      {/* Muestra el contenido normal de la celda */}
                      {formatData(column.key, row[column.key], row)}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={columns.length}>
                <MdInfoOutline className="mr-2" size={25} />
                <span style={{ fontSize: "15px" }}>
                  No se han encontrado registros en el sistema
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {true ? (
        <div style={{ display: "none" }}>
          <Table
            id="tablaReportesExportar"
            className="align-items-center table-flush"
            responsive
            striped
          >
            <thead className="thead-light">
              <tr>
                {columns.map((column, index) => (
                  <th key={index} className="font-weight-bold">
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="list">
              {allRecords.map((row, key) => (
                <tr key={key}>
                  {columns.map((column, index) => (
                    <td
                      key={index}
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify",
                        wordWrap: "break-word",
                      }}
                    >
                      {formatData(column.key, row[column.key], row)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}

      {dataWithFilters.length > 0 ? (
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="p-1">
                <span className="pagination mb-0">
                  Mostrando del {startIndex + 1} al{" "}
                  {endIndex > dataWithFilters.length
                    ? dataWithFilters.length
                    : endIndex}{" "}
                  de {dataWithFilters.length} resultados
                </span>
              </Col>
              <Col xs="12" md="6" className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => setPage(page === 1 ? page : page - 1)}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {Array.from({
                    length: Math.ceil(dataWithFilters.length / sizePerPage),
                  }).map((item, index) => (
                    <PaginationItem key={index} active={page === index + 1}>
                      <PaginationLink onClick={() => setPage(index + 1)}>
                        {index + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    className={
                      page === Math.ceil(dataWithFilters.length / sizePerPage)
                        ? "disabled"
                        : ""
                    }
                  >
                    <PaginationLink
                      onClick={() =>
                        setPage(
                          page ===
                            Math.ceil(dataWithFilters.length / sizePerPage)
                            ? page
                            : page + 1
                        )
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      ) : null}
    </Card>
  );
}

RecordTable.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  buttonFunction: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  preparePageData: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  formatData: PropTypes.func.isRequired,
};

export default RecordTable;
