import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const requerimentsInQuotes = handleActions(
  {
    [constants.GET_REQUESTS_IN_QUOTES]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const infoRequerimentsInQuotes = handleActions(
  {
    [constants.GET_INFO_REQUEST_IN_QUOTE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const commentsRequestInQuotes = handleActions(
  {
    [constants.GET_COMMENTS_REQUEST_IN_QUOTE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const filesRequestInQuotes = handleActions(
  {
    [constants.GET_FILES_REQUEST_IN_QUOTE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);
