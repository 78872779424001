import React, { useState } from 'react';
import { Card, CardBody, Col, CardHeader, Input, Button, Row, ListGroup, ListGroupItem } from "reactstrap";
import { changeStatusExitType, changeStatusExitReason, insertExitReason, insertExitType, getMasterData } from 'actions/exitInterview';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const CategoryManagement = ({ options, setEnabled, setOptions }) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const [newInfo, setNewInfo] = useState({
        exitType: "",
        exitReason: ""
    });
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    //Funcion para habilitar o deshabilitar un motivo de salida
    const handleOnDisableExitType = (status, id) => {
        dispatch(changeStatusExitType({ status: status, id: id })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                Toast.fire({
                    title: "Atención:",
                    html: `Se cambiado con éxito`,
                    type: 'success'
                });
            }
        })
        setEnabled(`${status}${id}`);
    }
    //Funcion para habilitar o deshabilitar una razon de salida
    const handleOnDisableExitReason = (status, id) => {
        dispatch(changeStatusExitReason({ status: status, id: id })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                Toast.fire({
                    title: "Atención:",
                    html: `Se cambiado con éxito`,
                    type: 'success'
                });
            }
        })
        setEnabled(`${status}${id}`);
    }
    //Funcion para almacenar el valor del nuevo motivo de salida en un hook
    const handleOnNewExitType = (e, type) => {
        if (type === 1) {
            setNewInfo(prevState => ({
                ...prevState,
                exitType: e
            }))
        } else {
            setNewInfo(prevState => ({
                ...prevState,
                exitReason: e
            }))
        }
    }
    //Funcion para insertar un nuevo motivo de salida a la base de datos
    const handleOnInsertExitType = () => {
        dispatch(insertExitType({ reason: newInfo.exitType })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                dispatch(getMasterData()).then((resp) => {
                    const { payload } = resp;
                    if (payload.status === 200) {
                        setOptions(prevState => ({
                            ...prevState,
                            exitType: payload.data.payload.masterData.exitType,
                            exitReasonDisabled: payload.data.payload.masterData.exitReasonDisabled,
                            exitReason: payload.data.payload.masterData.exitReason,
                            ExitTypeDisabled: payload.data.payload.masterData.ExitTypeDisabled,
                        }))
                    }
                })
                Toast.fire({
                    title: "Atención:",
                    html: `Se agrego correctamente`,
                    type: 'success'
                });
                setNewInfo(prevState => ({
                    ...prevState,
                    exitType: ""
                }));
            }
        })
    }
    //Funcion para insertar una nueva razon de salida a la base de datos
    const handleOnInsertExitReason = () => {
        dispatch(insertExitReason({ reason: newInfo.exitReason })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                dispatch(getMasterData()).then((resp) => {
                    const { payload } = resp;
                    if (payload.status === 200) {
                        setOptions(prevState => ({
                            ...prevState,
                            exitType: payload.data.payload.masterData.exitType,
                            exitReasonDisabled: payload.data.payload.masterData.exitReasonDisabled,
                            exitReason: payload.data.payload.masterData.exitReason,
                            ExitTypeDisabled: payload.data.payload.masterData.ExitTypeDisabled,
                        }))
                    }
                })
                Toast.fire({
                    title: "Atención:",
                    html: `Se agrego correctamente`,
                    type: 'success'
                });
                setNewInfo(prevState => ({
                    ...prevState,
                    exitReason: ""
                }));
            }
        })
    }
    return (
        <div>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <Col sm="12" md="8">
                                <h3 className="mb-0">Lista desplegable: Motivo de Salida</h3>
                            </Col>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col className="col-auto" sm="12" md="8">
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={newInfo.exitType}
                                        onChange={(e) => handleOnNewExitType(e.target.value, 1)}
                                    />
                                </Col>
                                <Col className="col-auto" sm="12" md="2">
                                    <Button
                                        color="success"
                                        type="button"
                                        onClick={() => handleOnInsertExitType()}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i class="fas fa-plus"></i>
                                        </span>
                                        <span className="btn-inner--text">Agregar</span>
                                    </Button>
                                </Col>
                            </Row>
                            <ListGroup className="list mt-3" flush>
                                {options.exitType.map((item, key) => {
                                    return (
                                        <ListGroupItem
                                            className="px-0"
                                            key={key}
                                        >
                                            <Row className="d-flex justify-content-between">
                                                <Col className="col-auto">
                                                    <h5 key={key}>{item.label}</h5>
                                                </Col>
                                                <Col className="col-auto">
                                                    <Button
                                                        key={key}
                                                        color="danger"
                                                        size="sm"
                                                        type="button"
                                                        disabled={options.exitType.length <= 1}
                                                        onClick={() => handleOnDisableExitType(0, item.value)}
                                                    >
                                                        X
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    );
                                })}

                            </ListGroup>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Col sm="12" md="8">
                                <h3 className="mb-0">Inactivos</h3>
                            </Col>
                        </CardHeader>
                        <CardBody>
                            <ListGroup className="list my--3" flush>
                                {options.ExitTypeDisabled &&
                                    options.ExitTypeDisabled.map((item, key) => {
                                        return (
                                            <ListGroupItem
                                                className="px-0"
                                                key={key}
                                            >
                                                <Row className="d-flex justify-content-between">
                                                    <Col className="col-auto">
                                                        <h5 key={key}>{item.label}</h5>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button
                                                            key={key}
                                                            color="danger"
                                                            size="sm"
                                                            type="button"
                                                            onClick={() => handleOnDisableExitType(1, item.value)}
                                                        >
                                                            X
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        );
                                    })}
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardHeader>
                            <Col sm="12" md="8">
                                <h3 className="mb-0">Lista desplegable: Razón de Salida</h3>
                            </Col>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col className="col-auto" sm="12" md="8">
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={newInfo.exitReason}
                                        onChange={(e) => handleOnNewExitType(e.target.value, 2)}
                                    />
                                </Col>
                                <Col className="col-auto" sm="12" md="2">
                                    <Button
                                        color="success"
                                        type="button"
                                        onClick={() => handleOnInsertExitReason()}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i class="fas fa-plus"></i>
                                        </span>
                                        <span className="btn-inner--text">Agregar</span>
                                    </Button>
                                </Col>
                            </Row>
                            <ListGroup className="list mt-3" flush>
                                {options.exitReason.map((item, key) => {
                                    return (
                                        <ListGroupItem
                                            className="px-0"
                                            key={key}
                                        >
                                            <Row className="d-flex justify-content-between">
                                                <Col className="col-auto">
                                                    <h5 key={key}>{item.label}</h5>
                                                </Col>
                                                <Col className="col-auto">
                                                    <Button
                                                        key={key}
                                                        color="danger"
                                                        size="sm"
                                                        type="button"
                                                        disabled={options.exitReason.length <= 1}
                                                        onClick={() => handleOnDisableExitReason(0, item.value)}
                                                    >
                                                        X
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Col sm="12" md="8">
                                <h3 className="mb-0">Inactivos</h3>
                            </Col>
                        </CardHeader>
                        <CardBody>
                            <ListGroup className="list my--3" flush>
                                {options.exitReasonDisabled &&
                                    options.exitReasonDisabled.map((item, key) => {
                                        return (
                                            <ListGroupItem
                                                className="px-0"
                                                key={key}
                                            >
                                                <Row className="d-flex justify-content-between">
                                                    <Col className="col-auto">
                                                        <h5 key={key}>{item.label}</h5>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button
                                                            key={key}
                                                            color="danger"
                                                            size="sm"
                                                            type="button"
                                                            onClick={() => handleOnDisableExitReason(1, item.value)}
                                                        >
                                                            X
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        );
                                    })}
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
export default CategoryManagement;