/*!

=========================================================
*FormGeneral
=========================================================

* Este componente despliega los campos del formulario, es 
reutilzado por el ingreso de mercancía (inbound), salida de
mercancía (outbound), tareas y movimientos spare (TasksSpare),
y movimiento entre almacenes(WarehousesMovements).

*Props: Todos los utilizados en todos los proyectos.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React, { useContext, useEffect } from 'react'

import {
    InputGroupAddon,
    InputGroup,
    FormGroup,
    Button,
    Input,
    Label,
    Row,
    Col,
} from "reactstrap";

// antd components
import { Spin } from 'antd';

//#region Icons
import { TbPointFilled } from "react-icons/tb";
import { Button as ButtonGeist } from "@geist-ui/react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
//#endregion

//Componente reutilizable
import { UserContext } from '../SharedComponents/UserContext';



export const FormGeneral = ({ fields, stage, isMobile, title, position, setPosition, buttonSpinner, localSpinner, newInfo, setNewInfo, positionsSapFormat, positions, setPositions, isComputer, warehouse, idComponent, handleOnChange, options, notValidateQuantity, strictedMaterialValidation }) => {


    //#region States globales
    const {
        notify,
    } = useContext(UserContext);
    //#endregion

    //#region Funciones para los diferentes componentes.

    //Método para validar si el picking actual está correcto.
    const validatePicking = (newInfo) => {
        return newInfo.pickingMaterial == newInfo.material
    }
    //Para la salida por reserva se valida las serials si están repetidas.
    const validateSerialsRepetitives = (positions, positionNumber) => {
        if (positionNumber != null) //Validar por número de posición
        {
            //Validar las seriales de un número de posición
            let pos = { ...positions[position] }

            //Eliminar las que no tienen serialValidation.
            for (let key in pos) {
                if (!key.startsWith("serialValidation")) {
                    delete pos[key];
                }
            }

            //Solo valores.
            let serials = Object.values(pos)

            //Verifica si hay series repetidas
            if (new Set(serials).size !== serials.length) {
                return true
            }
            return false


        } else {
            //Validar todas las posiciones si tienen serial.
            let repetitiveSerials = false;

            positions.map(pos => {

                //Validar las seriales de un número de posición
                let posAux = { ...pos }

                //Eliminar las que no tienen serialValidation.
                for (let key in posAux) {
                    if (!key.startsWith("serialValidation")) {
                        delete posAux[key];
                    }
                }


                //Solo valores.
                let serials = Object.values(posAux)

                //Verifica si hay series repetidas
                if (new Set(serials).size !== serials.length) {
                    repetitiveSerials = true
                }
                return repetitiveSerials

            })

            return repetitiveSerials

        }

    }

    //Para la salida por reserva se valida las serials si están vacías.
    const validateSerialsEmpties = (positions, positionNumber) => {

        if (positionNumber != null) //Validar por número de posición
        {
            //Validar las seriales de un número de posición
            let pos = positions[position]
            let serials = Object.keys(pos).filter(key => key.includes("serialValidation"));
            let emptySerial = false;

            if (serials.length > 0) {
                serials.map(serialId => {
                    if (pos[serialId] == "") {
                        emptySerial = true;
                    }
                })
            }

            return emptySerial



        } else {
            //Validar todas las posiciones si tienen serial.
            let emptySerial = false;

            positions.map(pos => {
                let serials = Object.keys(pos).filter(key => key.includes("serialValidation"));

                if (serials.length > 0) {
                    serials.map(serialId => {
                        if (pos[serialId] == "") {
                            emptySerial = true;
                        }
                    })
                }

            })

            return emptySerial

        }

    }

    //Condiciones de color, icono y disabled.
    const modeButtonAddon = (field, type) => {
        if (field.matchNumbers) {
            if (parseInt(newInfo[field.id]) == parseInt(newInfo[field.matchNumbers])) {
                return type == "color" ? "success" : type == "icon" ? "fas fa-check" : true
            }
            else if
                (parseInt(newInfo[field.id]) < parseInt(newInfo[field.matchNumbers])) {
                return type == "color" ? "warning" : type == "icon" ? "fas fa-times" : true
            } else if
                (parseInt(newInfo[field.id]) > parseInt(newInfo[field.matchNumbers])) {
                return type == "color" ? "danger" : type == "icon" ? "fas fa-times" : true

            } else {
                return type == "color" ? "default" : type == "icon" ? "fas fa-times" : true
            }
        } else {
            return type == "color" ? "success" : type == "icon" ? "fas fa-search" : false
        }
    }
    //Método para validar si el picking actual está correcto.
    const validateQuantity = () => {
        return (newInfo.quantity <= newInfo.pendingQuantity && newInfo.quantity > 0) //Que sea menor o igual a la cantidad esperada y mayor a 0.
    }

    //Cambiar la vista para visualizar cada position através de las flechas.
    const handleChangePosition = (direction) => {
        if (newInfo.pickingMaterial || strictedMaterialValidation/*Valide al cambiar la pantalla*/) {
            if (!validatePicking(newInfo)) {
                notify("danger", "Alerta", "Material pickeado incorrecto, favor validar.")
                return;
            }
        }

        if (newInfo.pickingMaterial && !notValidateQuantity) {
            if (!validateQuantity()) {
                notify("danger", "Alerta", "La cantidad es incorrecta, favor validar.")
                return;
            }
        }

        if (validateSerialsEmpties(positions, position)) {
            notify("danger", "Alerta", "Debe ingresar todas las series en la posición " + (position + 1))
            return;
        }

        if (validateSerialsRepetitives(positions, position) && ["reservationOutboundOthers"].includes(idComponent)) {
            notify("danger", "Alerta", "Existen series repetidas en la posición " + (position + 1))
            return;
        }


        if (direction == "right") {
            setPosition(position + 1)
            asignPositionInfo(position + 1)
        } else {
            setPosition(position - 1)
            asignPositionInfo(position - 1)

        }
    }

    //Asignar en newInfo la nueva posición.
    const asignPositionInfo = (positionNumber) => {
        let positionAsign = positions[positionNumber];
        setNewInfo(Object.assign(newInfo, positionAsign))
    }

    //Valida si debe mostrar o no la placa.
    const plateValidation = (field) => {

        if (field.id == "plate" && positions.length > 0/*Hay posiciones*/) {
            if (field.visibleInMovement && (newInfo.movement != field.visibleInMovement)) { //Sólo aparezca en un movimiento en específico.
                return false
            }

            if (field.notVisibleInMovement && (newInfo.movement == field.notVisibleInMovement)) { //Sólo aparezca en un movimiento en específico.
                return false
            }

            if (position !== 0) { //No aparece serie porque no es la posición 0 osea la primera
                return false
            }
        }

        return true
    }

    //Devuelve los fields al formulario, se hace en método porque hay excepciones.
    const getFields = () => {
        let fieldsReturn = fields[idComponent];

        //Esta excepción es sólo para salidas por reserva, donde se ocupan agregar unos nuevos campos al formulario, las cuales son todas las series en base a la cantidad.
        if (positions && positions.length > 0 && positions[position] && positions[position].serieType == "ZGBM") {
            let positionTemp = positions[position];

            let serials = [];
            for (let key in positionTemp) {
                if (key.includes("serialValidation")) {
                    serials.push(
                        {
                            id: key,
                            label: "Serie #" + key.split("serialValidation")[1],
                            width: "4",
                            type: "input",
                            disabled: false,
                            required: true,
                            icon: TbPointFilled,
                            stage: [3]
                        },
                    );
                }
            }

            fieldsReturn = [...fieldsReturn, ...serials]

            //Poner el botón enviar al final del array.
            const index = fieldsReturn.findIndex(obj => obj.id.includes("btnSend"));
            if (index !== -1) {
                const sendButton = fieldsReturn.splice(index, 1)[0];
                fieldsReturn.push(sendButton);
            }

        }

        //#region Validations
        fieldsReturn = fieldsReturn.filter(field => (

            //Si en la etapa actual debe aparecer el campo.
            field.stage.includes(stage) &&

            plateValidation(field) && //Habilitar o deshabilitar la placa

            (!field.disableIfDontHaveValue ||
                (field.disableIfDontHaveValue && newInfo[field.disableIfDontHaveValue])) && //=> En caso que no tenga valor no lo muestre.

            (!field.enableIfDontHaveValue ||
                (field.enableIfDontHaveValue && !newInfo[field.enableIfDontHaveValue])) && //=> En caso que tenga valor  lo muestre.

            (!field.enableIfDontHaveValuesArray ||
                (field.enableIfDontHaveValuesArray && verifyEnableIfDontHaveValuesArray(field.enableIfDontHaveValuesArray))) //=> Sólo aparezca si ninguno de los que están en el array no tienen value.

        ))
        //#endregion

        return fieldsReturn;
    }

    //#endregion

    //Método para verificar si los ids de un array tienen valor en newInfo.
    const verifyEnableIfDontHaveValuesArray = (items) => {
        let resultCounter = 0

        items.map(item => {
            if (!newInfo[item]) {
                resultCounter++;
            }
        }
        )
        return resultCounter > 0
    }

    return (
        <>


            {isMobile &&
                <Row className={' mt--3'}>
                    <Col className={'text-center'}>
                        <h3 className='h4'>{title}</h3>
                    </Col>
                </Row>
            }


            <Spin size="small" spinning={localSpinner} tip={""} style={{ color: '' }}>
                <Row>

                    {getFields().map((field, fieldKey) => (

                        // //Si en la etapa actual debe aparecer el campo.
                        // field.stage.includes(stage) &&

                        // plateValidation(field) && //Habilitar o deshabilitar la placa

                        // (!field.disableIfDontHaveValue ||
                        //     (field.disableIfDontHaveValue && newInfo[field.disableIfDontHaveValue])) && //=> En caso que no tenga valor no lo muestre.

                        // (!field.enableIfDontHaveValue ||
                        //     (field.enableIfDontHaveValue && !newInfo[field.enableIfDontHaveValue])) && //=> En caso que tenga valor  lo muestre.

                        // (!field.enableIfDontHaveValuesArray ||
                        //     (field.enableIfDontHaveValuesArray && verifyEnableIfDontHaveValuesArray(field.enableIfDontHaveValuesArray))) && //=> Sólo aparezca si ninguno de los que están en el array no tienen value.

                        <Col xs="12" sm={field.width} className={((field.type == "button" || field.type == "positionsSelector") && "d-flex justify-content-center align-items-center ") + (isMobile && 'p-0')}>


                            {field.type == "positionsSelector" ?

                                <Row className='mb-2'>
                                    <Col xs="auto" className='text-right '>
                                        <Button disabled={position == 0} color={field.color} size={"sm"} outline onClick={() => handleChangePosition("left")} >
                                            <FaArrowLeft className=" " style={{ fontSize: "16px" }} />
                                        </Button>
                                    </Col>
                                    <Col xs="auto" className='text-center '>
                                        <Label className="font-weight-bold mt-1" >
                                            {field.label}
                                        </Label>
                                    </Col>
                                    <Col xs="auto" className='text-left '>
                                        <Button disabled={position === positions.length - 1} color={field.color} size={"sm"} outline onClick={() => handleChangePosition("right")}>
                                            <FaArrowRight className="" style={{ fontSize: "16px" }} />
                                        </Button>
                                    </Col>
                                </Row>

                                : field.type === "button" ?
                                    (
                                        <ButtonGeist loading={buttonSpinner} onClick={() => field.function(newInfo, positions, isComputer, warehouse, idComponent, positionsSapFormat, getFields())} type={field.color} size={"small"} style={{ width: isComputer ? "30%" : '100%' }} className={(isMobile ? "mr-4 ml-4 mt-2" : "mt-4")}>
                                            {field.label}
                                            <field.icon className="ml-2 " style={{ fontSize: "16px" }} />
                                        </ButtonGeist>
                                    )
                                    :
                                    (<FormGroup className="mb-2" >

                                        <Label className="font-weight-bold ml-2" >

                                            {field.icon && //Icono 
                                                <field.icon className="mr-2 mb-1" style={{ fontSize: "16px" }} />
                                            }

                                            {field.label + ':'}
                                        </Label>


                                        <InputGroup className="input-group-merge">
                                            <Input
                                                type={field.type}
                                                className=""
                                                disabled={field.disabled || (field.dependsEnableOf && !newInfo[field.dependsEnableOf])}
                                                size={isMobile && "sm"}
                                                value={newInfo && newInfo[field.id]}
                                                onChange={(e) => {
                                                    if (field.type === "number") {
                                                        handleOnChange(field.id, e.target.value.replace(/\D/g, ""), idComponent);
                                                    } else {
                                                        handleOnChange(field.id, e.target.value, idComponent);
                                                    }
                                                }}

                                                min={1}
                                            >
                                                {!field.deleteEmptyOption && <option id=""></option>}
                                                {
                                                    options[field.options] && options[field.options].map((value) => {
                                                        return (
                                                            <option id={value.value}>{value.label}</option>
                                                        );
                                                    })}

                                                {/*Los campos de tipo movimiento tienen números por defecto.*/}
                                                {field.movementOptions && field.movementOptions.map((value) => {
                                                    return (
                                                        <option id={value}>{value}</option>
                                                    );
                                                })}
                                            </Input>

                                            {(field.type == "inputKey" || field.matchNumbers) &&
                                                <InputGroupAddon size="sm" addonType="append" className={'ml--2'}>
                                                    <Button color={modeButtonAddon(field, "color")}
                                                        size="sm"
                                                        outline
                                                        onClick={(e) => !field.matchNumbers && field.function(newInfo)}
                                                        disabled={modeButtonAddon(field, "disabled")}>
                                                        <span className="btn-inner--icon">
                                                            <i className={modeButtonAddon(field, "icon")} />
                                                        </span>

                                                    </Button>
                                                </InputGroupAddon>}
                                        </InputGroup>


                                    </FormGroup>)
                            }
                        </Col>

                    ))}

                </Row>
            </Spin >



        </>
    )
}
