import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from "reactstrap";
import { updatePurchaseOrder, deleteFile, deleteAllFiles } from 'actions/costaRicaBids';
import { useDispatch } from 'react-redux';
import { Upload, Icon } from 'antd';
import { urlBase } from "api/urls.jsx";
import "../../assets/css/custom.css"
import ReactBSAlert from "react-bootstrap-sweetalert";

const { Dragger } = Upload;

const ModalNoParticipate = ({ rows, options, setShowModal, showNoParticipation, Toast, setRefreshInput }) => {

    const dispatch = useDispatch();
    const [state, setState] = useState({
        fileList: [],
        idFileRequest: "",
        readyToSend: false,
    });
    const [editData, setEditData] = useState({ bidNumber: rows.bidNumber });
    const { fileList } = state
    const [alert, setAlert] = useState("");

    const handleCloseModal = () => {
        dispatch(deleteAllFiles({ id: rows.bidNumber })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setShowModal(false);
            };
        });
        setEditData([]);
        setShowModal(false);
    }
    const handleChangeInfo = (constant, e) => {
        setEditData(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }
    const sendNoParticipation = () => {
        editData["participation"] = "NO";
        editData["statusRobot"] = 1;
        dispatch(updatePurchaseOrder({ info: editData, type: "3" })).then((resp) => {
            const { payload } = resp;
            console.log(payload)
            if (payload.status === 200) {
                Toast.fire({
                    title: "Atención",
                    html: "Se guardó correctamente",
                    type: 'success'
                });
                setRefreshInput(`${editData.bidNumber}${7}`);
                setShowModal(false);
            } else if (!payload.status) {
                setAlert(
                    <ReactBSAlert
                        danger
                        style={{ display: "block", marginTop: "-100px" }}
                        title={`Concurso rechazado`}
                        onConfirm={() => closeAlert()}
                        confirmBtnBsStyle="info"
                        confirmBtnText="ok"
                        btnSize="md"
                    >
                        {`El concurso ya fue rechazado con anterioridad.`}
                    </ReactBSAlert>
                )
                setRefreshInput(`${editData.bidNumber}${7}`);
            }
        });
    }
    const handleOnFileList = (info) => {
        const { status } = info.file;
        if (status === "removed") {
            const { file: { name, uid, response: { status, payload } } } = info;
            if (status === 200) {
                const { idFile } = payload;
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            }
            dispatch(deleteFile({ id: rows.bidNumber, name: name })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    //deshabilitar el botón de enviar
                    if (fileList.length === 1) {
                        setState((prevState) => ({
                            ...prevState,
                            readyToSend: false,
                        }));
                    }
                } else {
                }
            })
        } else {
            const { fileList, file: { status, name } } = info;
            let response = "";
            if (status === 'done') {
                const { file } = info;
                response = file["response"]
                response = response.payload.idFile;
                //habilitar el botón de enviar
                setState((prevState) => ({
                    ...prevState,
                    readyToSend: true,
                }));
            } else if (status === 'error') {
                //  message.success(`Ocurrio un error cargando el archivo ${name}.`);
            }
            setState((prevState) => ({
                ...prevState,
                idFileRequest: response,
                fileList: fileList,
            }));
        }
    }

    const allowedFileTypes = [
        ".xlsx", ".xls", ".xlsb", ".xlt", ".ppt", ".pptx", ".pdf", ".doc", ".docx",
        ".rar", ".zip", ".cfr", ".csv", ".txt", ".eml", ".jpg", ".png", ".html", ".rtf", ".xml",
        ".css", ".tif", ".ods", ".msg"
    ];

    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: allowedFileTypes.join(","),
        multiple: true,
        action: `${urlBase}/costa-rica-bids/upload-files/${rows.bidNumber}`,
        beforeUpload: file => {
            const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
            if (!allowedFileTypes.includes(fileType)) {
                console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
                return false;
            }
            return true;
        },
        onChange: info => {
            console.log(info)
            // Filtrar la lista de archivos válidos para mantener solo los que aún existen
            const filteredFiles = info.fileList.filter(file => {
                console.log(file)
                if (file.status) {
                    return true;
                }
                return false;
            });
            info.fileList = filteredFiles;
            // Actualizar la lista de archivos válidos
            // Llamar a la función handleOnFileList con la lista de archivos válidos
            handleOnFileList(info);
        },
    };

    const closeAlert = () => {
        setAlert("")
        setShowModal(false);
    }
    console.log(editData.noParticipationReason)
    return (
        <>
            {alert}
            <Modal className="modal-xl" isOpen={showNoParticipation}>

                <ModalHeader className="d-flex justify-content-center">
                    {"No participación del concurso: "}
                    <a rel="noreferrer" target="_blank" style={{ color: "blue" }}>
                        {rows.bidNumber}
                    </a>
                </ModalHeader>
                <ModalBody className="d-flex justify-content-center">
                    <Row>
                        <Col xs={12} >
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >  {`Razón de No Participación`}</label>
                                <Select
                                    className="basic-single"
                                    type="select"
                                    isSearchable
                                    onChange={(e) => handleChangeInfo("noParticipationReason", e.value)}
                                    options={options.noParticipationReason}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} >
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {`Motivo de No Participación:`}
                                </label>
                                <Input
                                    type="textarea"
                                    placeholder="Indique el motivo..."
                                    className="basic-multi-select text-dark"
                                    onChange={(e) => handleChangeInfo("notParticipate", e.target.value)}
                                ></Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12} >
                            {
                                parseInt(rows.budget.replaceAll(".", "").split(" ")[0], 10) >= 30000000 ?
                                    <label>{`Presupuesto:  ${rows.budget} por favor adjuntar el visto bueno del Gerente respectivo: ${rows.managerSector}`}</label>

                                    : parseInt(rows.budget.replaceAll(".", "").split(" ")[0], 10) >= 120000000 ?
                                        <label>{`Presupuesto:  ${rows.budget} por favor adjuntar el visto bueno del Gerente respectivo: ${rows.managerSector} y del gerente general`}</label>
                                        :
                                        <></>
                            }
                        </Col>
                        <Col xs={12} >
                            <Dragger style={{ whiteSpace: "normal", textAlign: "auto" }} {...uploadProps} fileList={fileList}>
                                <Icon type="inbox" />
                                <p className="ant-uploading-text">Seleccione el archivo que va a cargar.</p>
                                <p className="ant-uploading-hint">
                                    *Puede cargarlo o arrastrar el archivo
                                </p>
                            </Dragger>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Button
                            color="danger"
                            onClick={() => handleCloseModal()}
                        >
                            Cancelar
                        </Button>
                        <Button
                            color="info"
                            disabled={(editData.noParticipationReason === 8 && (!editData.notParticipate || editData.notParticipate === ""))
                                || (parseInt(rows.budget.replaceAll(".", "").split(" ")[0], 10) >= 30000000 && fileList.length === 0 && rows.gbmStatus === "No" && editData.noParticipationReason != 1)
                                || (parseInt(rows.budget.replaceAll(".", "").split(" ")[0], 10) >= 30000000 && fileList.length === 0 && rows.gbmStatus === "Si")
                                || !editData.noParticipationReason ? true : false
                            }
                            onClick={() => sendNoParticipation()}
                        >
                            Enviar
                        </Button >
                    </Row>
                </ModalFooter>
            </Modal>
        </>

    );
};

export default ModalNoParticipate;