// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const FormsList = (props) => {
  const { title, subtitle, cols, rows, page, sizePerPage, onOpen, onSetPage } =
    props;

  const paginations =
    rows.length > sizePerPage
      ? rows.length / sizePerPage > Math.round(rows.length / sizePerPage)
        ? Math.round(rows.length / sizePerPage) + 1
        : Math.round(rows.length / sizePerPage)
      : 1;

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink onClick={() => onSetPage(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return options.length >= 5
      ? page >= 5
        ? options.slice(page - 3, page + 2)
        : options.slice(0, 5)
      : options;
  };

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="12" md="7">
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {cols.map((row, key) => (
              <th
                key={key}
                style={{ whiteSpace: "normal", textAlign: "center" }}
              >
                {row.name}
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {rows
            .slice((page - 1) * sizePerPage, page * sizePerPage)
            .map((row, key) => (
              <tr key={key} onClick={() => onOpen(row)}>
                {cols.map((col, key) =>
                  col.key === "completedAt" ? (
                    <th
                      key={key}
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      {moment(row[col.key])
                        .utc()
                        .utcOffset(moment().utcOffset())
                        .format("DD/MM/YYYY H:mm A")}
                    </th>
                  ) : (
                    <th
                      key={key}
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      {col.key === "version" ? (
                        <Badge color="info">{`v${row[col.key]}`}</Badge>
                      ) : col.key === "status" ? (
                        <Badge color="" className="badge-dot mr-4">
                          <i className={row.color} />
                          {row[col.key]}
                        </Badge>
                      ) : (
                        <>{row[col.key]}</>
                      )}
                    </th>
                  )
                )}
                <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                  <Button
                    color="info"
                    size="sm"
                    href={`${urlBase}/know-employee/download-pdf-form-info/${row.id}`}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-file-download" />
                    </span>
                    <span className="btn-inner--text">Descargar</span>
                  </Button>
                </th>
              </tr>
            ))}
        </tbody>
      </Table>
    </Card>
  );
};

FormsList.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default FormsList;
