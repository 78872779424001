/*!

=========================================================
*MasterData - Approvals
=========================================================

*Componente para desplegar una tabla con todos los aprobadores 
de datos maestros, para su adición, modificación y eliminación.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//#region Imports de librerías externas.

//Imports del Framework de React.
import React, { useState, useEffect, useContext, useRef, Form } from "react";

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de la Librería raíz Reactstrap.
import {
    UncontrolledDropdown,
    DropdownMenu,
    PaginationItem,
    PaginationLink,
    Pagination,
    CardHeader,
    CardFooter,
    FormGroup,
    Button,
    Input,
    Table,
    Card,
    Col,
    Row,
    CardBody,

} from "reactstrap";

//Import de Spinner
import { Spin, Checkbox } from "antd";

//Multiselect
import { MultiSelect } from "react-multi-select-component";

//Import dispatch consulta al API.
import { useDispatch } from 'react-redux';

// react plugin for creating notifications over the dashboard
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

//#endregion

//#region Imports de componentes internos.
//Imports de actions de consultas al API.
import {
    getApprovers,
    deleteApprovers,
    newApprovers
} from 'actions/masterData';
import { PermissionsItems } from "./PermissionsItems";

//#endregion


export const Approvals = ({ handleOnBack }) => {
    //#region States Globales
    const {
        options,
        employees

    } = useContext(UserContext);
    //#endregion


    //Definición de textos.
    const [text, SetText] = useState({
        titleDropdown: "Aprobaciones",
        subtitleDropdown: "Permisos de aprobadores",
        sendButtonName: "Crear permisos",
        newRowName: "Agregar nueva fila"
    })
    const { titleDropdown, subtitleDropdown, sendButtonName, newRowName } = text;


    //Extracción de librería swal.
    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    //Ref del dropdown de permisos.
    const dropdownRef = useRef(null);

    //Spinner para la pantalla compaleta.
    const [spinnerCard, setspinnerCard] = useState(false);

    //Función para extraer solicitudes del API.
    const dispatch = useDispatch();

    //#region Consultas al API.







    //#region Extraer los aprobadores.
    const [approvers, setApprovers] = useState([]);


    //Refrescar los aprobadores.
    const [refreshApprovers, setRefreshApprovers] = useState(true)

    useEffect(() => {
        if (refreshApprovers) {
            // debugger;
            getApproversD()
        }
    }, [refreshApprovers])

    //Trae los aprobadores
    const getApproversD = async () => {


        dispatch(getApprovers()).then((resp) => {

            const { payload } = resp;
            setRefreshApprovers(false);

            if (payload.status === 200) {
                //console.log(payload)

                setApprovers(payload.data.payload.approvers)

            } else {
                console.log(payload)
            }
        })
    }




    //#endregion





















    //Elimina los aprobadores.
    const deleteApproversD = async (checkBoxsOn, rows, filters) => {
        if (checkBoxsOn.length > 0) {


            let deleteRowsHTML = getDeleteRowsHTML(checkBoxsOn, rows);
            MySwal.fire({
                title: `¿Desea eliminar los permisos seleccionados?`,
                html: deleteRowsHTML,
                icon: "warning",
                showCancelButton: true,
                width: 1000,
                confirmButtonText: `Si, eliminar`,
                confirmButtonColor: "#2dce89",
                cancelButtonText: "No, cancelar",
            }).then((result) => {

                if (result.value) {
                    setspinnerCard(true);

                    dispatch(deleteApprovers({ idsToDelete: checkBoxsOn })).then((resp) => {

                        const { payload } = resp;
                        setspinnerCard(false);

                        if (payload.status === 200) {
                            Toast.fire({
                                title: "Atención",
                                html: "Selección eliminada con éxito.",
                                type: "success",
                            });

                            //Restablecer.
                            handleRefresh(filters);


                        } else {
                            Toast.fire({
                                title: "Atención",
                                html: "Ocurrió un error al eliminar la selección.",
                                type: "warning",
                            });
                            console.log(payload)
                        }
                    })
                }
            });

        } else {
            Toast.fire({
                title: "Atención",
                html: "Debe seleccionar al menos una fila.",
                type: "warning",
            });
        }

    }

    //Función para construir un html de las rows a eliminar.
    function getDeleteRowsHTML(checkBoxsOn, rows) {

        const rowsSelected = rows.filter(row => checkBoxsOn.includes(row.id)) //=> Trae las rows de la tabla seleccionadas con el checkbox.



        let htmlReturn = `<div  class="card">
                                  <div class="card-body">
                                      <table class="align-items-center table-flush table table-striped table-hover">
                                      <thead>
                                          <tr>
                                              <th>Usuario</th>
                                              <th>Permiso</th>
                                              <th>Factor</th>
                                          </tr>
                                      </thead>
                                      
                                      <tfoot>
                                          <tr>
                                              <th>Total de Permisos: ${rowsSelected.length}</th>
                                              <th></th>
                                              <th></th>
                                          </tr>
                                      
                                      </tfoot>
                                      <tbody class="list">`;

        rowsSelected.forEach(function (json) {
            htmlReturn =
                htmlReturn +
                `<tr>
                                              <td>${json["user"]}</td>
                                              <td>${json["permission"]}</td>
                                              <td>${json["factor"]}</td>
                                              </tr>`;
        });

        htmlReturn =
            htmlReturn +
            `</tbody>
                              <br>
                              </table>
                              </div>
                              </div>`;
        return htmlReturn;
    }


    //Crea los nuevos permisos de aprobadores.
    const newApproversD = async (checkBoxsOn, rows, filters) => {
        // console.log(checkBoxsOn)

        setspinnerCard(true);
        dispatch(newApprovers({ permissionsRequests: permissionsRequests })).then((resp) => {

            setspinnerCard(false);

            const { payload } = resp;

            if (payload.status === 200) {
                Toast.fire({
                    title: "Atención",
                    html: "Aprobadores creados con éxito.",
                    type: "success",
                });

                //Restablecer.
                handleRefresh(filters);


                //Cerrar el dropdown menú.
                if (dropdownRef.current) {
                    dropdownRef.current.toggle(false); // Cierra el DropdownMenu
                }

            } else {
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrió un error al crear los aprobadores.",
                    type: "warning",
                });
                console.log(payload)
            }
        })



    }
    //#endregion

    //#region States Globales
    const {
        // approvers,

        //Refrescar aprobadores.
        // setRefreshApprovers,
        // refreshApprovers

    } = useContext(UserContext);
    //#endregion




    const [rowsRequests, setRowsRequests] = useState({
        rows: [],
        statsBaw: [],
        length: 0,
    })



    //#region Establecer arreglo de columnas
    //Columnas para el multicolumn y si la seleccionan se agrega al columnsSelectedTableRequests
    const optionsRequestsSelect = [
        { value: "actions", label: "Botones", disabled: true },
        { value: "user", label: "Usuario", disabled: true },
        { value: "id", label: "Id" },
        { value: "factor", label: "Factor" },
        { value: "permission", label: "Permiso" },
        { value: "createdBy", label: "Creado por" },
        { value: "createdAt", label: "Creado en" },
    ];

    //Columnas seleccionadas por defecto en la tabla.
    const [columnsSelectedTableRequests, setColumnsSelectedTableRequests] = useState(
        [
            { value: "actions", label: "Usuario", width: 50 },
            { value: "user", label: "Usuario", width: 50 },
            { value: "factor", label: "Factor", width: 50 },
            { value: "permission", label: "Permiso", width: 60 },
        ]
    );

    //#endregion

    //#region Funciones para aplicar flitros a la tabla

    //Filtros request tables
    const [filters, setFilters] = useState([]);

    //Aplicar los filtros a la data de la tabla
    const renderRowsByFilters = (rows) => {


        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);

            for (const element of filterKeys) {
                const valueFiltered = filters[element].toString().toLowerCase();

                rows = rows.filter(

                    (item) => {
                        let temp;

                        try {
                            temp = item[element]?.toString().toLowerCase().indexOf(valueFiltered);
                        } catch (e) { temp = -1 }

                        //Si se le da un espacio en el campo, imprima tdoos los vacios
                        if (valueFiltered === " ") {
                            return temp === undefined
                        } else { //No es un espacio, traiga todos los de la palabra clave
                            return temp != -1 && temp != undefined
                        }

                    }
                )
            }
            return rows
        }
    }


    //#endregion 


    //#region Realizar paginations en la tabla

    const [sizePerPage, setSizePerPage] = useState(10)
    const rows = renderRowsByFilters(rowsRequests.rows);

    //state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);

    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Establece el número de filas por pagina 
    const handleOnSetPage = (page) => {
        setPage(page);
    };

    const handleSetNumPag = (e) => {
        setPage(1);
        const value = e.target.value;
        setSizePerPage(value);
    }

    //Capturar los valores de los inputs de los filtros
    const handleOnfilterDynamic = (constant, e) => {
        // console.log(constant, e)

        //Restablecer página.
        handleOnSetPage(1);

        //Restablecer la selección.
        setCheckBoxsOn([]);
        setAllCheckBoxOn(false);

        //Establecer filtros.
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }

    //#endregion

    useEffect(() => {
        if (approvers) {
            setRowsRequests((e) => ({
                ...e,
                rows: approvers,
                length: approvers.length,
            }))
        }
    }, [approvers])


    //Lista de los checkbox id activos.
    const [checkBoxsOn, setCheckBoxsOn] = useState([])

    //Bandera para determinar si todos los checkbox están encendidos.
    const [allCheckBoxOn, setAllCheckBoxOn] = useState(false)

    //#region Handles para la gestión de aprobaciones.

    //Función para agregar o desagregar un checkbox activo.
    const handleActivateAllCheckbox = (checked) => {

        setAllCheckBoxOn(checked)
        if (checked) {
            let ids = rows.map((row, key) => row.id)
            setCheckBoxsOn(ids)
        } else {
            setCheckBoxsOn([])
        }
    }

    //Función para agregar o desagregar un checkbox activo.
    const handleOnChangeCheckbox = (checked, id) => {
        if (checked) {
            setCheckBoxsOn([...checkBoxsOn, id])
        } else {
            setCheckBoxsOn(checkBoxsOn.filter((checkbox) => checkbox != id))

            //El checkbox de todas las rows.
            setAllCheckBoxOn(false);
        }
    }

    //Función para agregar un nuevo permiso.
    const handleNewPermission = () => {
        if (dropdownRef.current) {
            dropdownRef.current.toggle(); // Abre el DropdownMenu
        }


        //Crear una fila por defecto.
        setPermissionsRequests([{
            id: new Date().getTime(),
            employee: null,
            permission: null,
            factors: [{
                label: "", value: ""
            },
            ]
        },
        ])
    }


    //Handle para copiar los permisos y factores de la selección.
    const handleCopySelection = (checkBoxsOn, rows) => {
        if (checkBoxsOn.length == 0) {
            Toast.fire({
                title: "Atención",
                html: `Se debe seleccionar al menos una fila para copiar.`,
                type: "warning",
            });
        } else {


            const rowsSelected = rows.filter(row => checkBoxsOn.includes(row.id)) //=> Trae las rows de la tabla seleccionadas con el checkbox.

            let permissionsToCopy = [];

            rowsSelected.map(row => { //= >Pone los nombres de los permisos en el array una única vez.
                if (!permissionsToCopy.includes(row.permission)) {
                    permissionsToCopy = [...permissionsToCopy, row.permission]
                }
            })


            permissionsToCopy = permissionsToCopy.map((permissionToCopy, keyPermissionToCopy) => {
                return {
                    id: new Date().getTime() + keyPermissionToCopy,
                    employee: null,
                    factors:

                        rowsSelected
                            .filter(row => row.permission == permissionToCopy) //=> Obtiene las filas del permiso actual.
                            .map(factor => //=>Moldea cada factor.

                                options["factor"].filter(option => option.label == factor.factor)[0]//=> Obtiene la opción del factor.
                            ),

                    permission: options["permissions"]?.filter(permission => permission.label == permissionToCopy)[0]
                }


            })

            //Establece la copia al dropdown.
            setPermissionsRequests(permissionsToCopy)

            if (dropdownRef.current) {
                dropdownRef.current.toggle(); // Abre el DropdownMenu
            }
        }

    }

    //Handle para agregar una nueva fila a la lista de aprobaciones.
    const handleAddNewRow = () => {
        setPermissionsRequests([...permissionsRequests,
        {
            id: new Date().getTime(),
            employee: null,
            permission: null,
            factors: [{
                label: "", value: ""
            }]
        }
        ])
        // setPermissionsRequests2([...permissionsRequests2,
        //     {
        //         id: new Date().getTime(),
        //         employee: null,
        //         permission: null,
        //         factors: [{
        //             label: "", value: ""
        //         }]
        //     }
        //     ])
    }

    //Handle para refrescar la tabla.
    const handleRefresh = (filters) => {

        //Restablecer.
        restartStates(filters);

        setRefreshApprovers(true)
    }

    //Handle para agregar una nueva fila a la lista de aprobaciones.
    const handleSendPermissions = () => {
        if (validateFieldsPermission()) {

            newApproversD()
        }
    }


    //#endregion

    //Función para validar si los campos de los permisos están llenos.
    const validateFieldsPermission = () => {
        let employeeEmpty = permissionsRequests.filter(permissionsRequest => permissionsRequest.employee == null).length;
        let permissionEmpty = permissionsRequests.filter(permissionsRequest => permissionsRequest.permission == null).length;

        let factorsEmpty = 0;
        permissionsRequests.map(permissionsRequestMap => {
            permissionsRequestMap.factors.map(factor => {
                if (factor.label == "") {
                    factorsEmpty = factorsEmpty + 1;
                }
            })
        })
        if (permissionsRequests.length == 0) {
            Toast.fire({
                title: "Atención",
                html: `Deben al menos agregar un permiso.`,
                type: "warning",
            });

            return false;
        } else if (employeeEmpty > 0) {
            Toast.fire({
                title: "Atención",
                html: `Existen ${employeeEmpty} campo${employeeEmpty > 1 ? 's' : ''} de usuario${employeeEmpty > 1 ? 's' : ''} vacío${employeeEmpty > 1 ? 's' : ''}.`,
                type: "warning",
            });

            return false;
        } else if (permissionEmpty > 0) {
            Toast.fire({
                title: "Atención",
                html: `Existen ${permissionEmpty} campo${permissionEmpty > 1 ? 's' : ''} de permiso${permissionEmpty > 1 ? 's' : ''} vacío${permissionEmpty > 1 ? 's' : ''}.`,
                type: "warning",
            });

            return false;

        } else if (factorsEmpty > 0) {
            Toast.fire({
                title: "Atención",
                html: `Existen ${factorsEmpty} campo${factorsEmpty > 1 ? 's' : ''} de factor vacío${factorsEmpty > 1 ? 's' : ''}.`,
                type: "warning",
            });

            return false;

        }
        return true;



    }


    //Función para restablecer states por defecto.
    const restartStates = (filters) => {
        setCheckBoxsOn([])
        setAllCheckBoxOn(false)

        //#region Reiniciar los filtros.
        const filterAux = {};
        for (const key in filters) { filterAux[key] = ""; }
        setFilters(filterAux);
        //#endregion

        setPermissionsRequests([{
            id: new Date().getTime(),
            employee: null,
            permission: null,
            factors: [{ label: "", value: "" },]
        },])
        setPage(1);
    }

    //Butones superiores.
    const [buttons, setButtons] = useState([

        {
            color: "danger",
            icon: "fas fa-trash",
            text: "Eliminar selección",
            width: "3",
            funct: deleteApproversD
        },
        {
            color: "primary",
            icon: "fas fa-copy",
            text: "Copiar selección",
            width: "3",
            funct: handleCopySelection
        },
        {
            color: "success",
            icon: "fas fa-plus",
            text: "Nuevo permiso",
            width: "3",
            funct: handleNewPermission
        },
        {
            color: "info",
            icon: "fas fa-sync-alt",
            text: "Refrescar",
            width: "3",
            funct: handleRefresh
        },
    ])

    //State ***clave*** y central para capturar los permisos a crear.
    const [permissionsRequests, setPermissionsRequests] = useState(
        [{
            id: 1,
            employee: null,
            permission: null,
            factors: [{ label: "", value: "" },]
        },

        {
            id: 2,
            employee: null,
            permission: null,
            factors: [{ label: "", value: "" },]
        },]
    )






    const [permissionsRequests2, setPermissionsRequests2] = useState(
        [{
            id: 123,
            information: {},
            employee: null,
            permission: null,
            factors: [{ label: "", value: "" },]
        },
        {
            id: 456,
            information: {},
            employee: null,
            permission: null,
            factors: [{ label: "", value: "" },]
        },
        ])

    const handleAddInformationToSalesTeam = () => {

    }

    const handleDelete = (toDelete) => {
        // debugger;
        // let filtered = salesTeamsList.filter(st => (st.id != toDelete.id))


        // setsalesTeamsList(prevState => (filtered));

    }



    return (
        <>



            {/* TodoList, todos, handleDelete, handleToggle  */}

            {/* </Form> */}
            <Spin size="large" spinning={spinnerCard}>

                <Card className="bg-gradient border-0">
                    <CardHeader>

                        <Row>
                            <Col xs="3">
                                <Button
                                    color="danger"
                                    onClick={() => handleOnBack()}
                                    size="sm"
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-arrow-left"></i>
                                    </span>
                                    Regresar
                                </Button>
                            </Col>
                            <Col xs="6" className="text-center">
                                <Row>
                                    <Col sm="10">
                                        <h3>Portal de Mantenimiento de Aprobadores</h3>
                                    </Col>
                                    <Col sm="2">
                                        <MultiSelect
                                            size="sm"
                                            id="multiselectFilter"
                                            options={optionsRequestsSelect}
                                            value={columnsSelectedTableRequests}
                                            onChange={setColumnsSelectedTableRequests}
                                            labelledBy="Select"
                                        />
                                    </Col>

                                </Row>



                            </Col>
                        </Row>



                    </CardHeader>

                    {/* <Card> */}
                    <Spin size="large" spinning={refreshApprovers}>

                        <CardHeader className="bg-transparent border-0">

                            <Row>
                                {
                                    buttons.map((button) => {
                                        return (
                                            <Col sm={button.width}>
                                                <Button className="btn-icon" color={button.color} size="sm" block type="button"
                                                    onClick={() => button.funct(checkBoxsOn, rows, filters)}
                                                >
                                                    <span className="btn-inner--icon mr-">
                                                        <i className={button.icon} />
                                                    </span>
                                                    <span className="btn-inner--text">
                                                        {button.text}
                                                    </span>
                                                </Button>
                                            </Col>
                                        )
                                    })
                                }

                                <Col sm="12" className="mx-auto">
                                    <>
                                        {/* Vista de agregar permiso o agregar permiso */}
                                        <UncontrolledDropdown ref={dropdownRef} style={{ position: 'absolute', left: "5%" }}>
                                            <DropdownMenu style={{ minWidth: 850 }} container="body" >
                                                <Card className="">
                                                    <CardHeader className="">
                                                        <Row>
                                                            <Col sm="9" className="">
                                                                <h6 className="surtitle">{subtitleDropdown}</h6>
                                                                <h5 className="h3 mb-0">{titleDropdown}</h5>
                                                            </Col>
                                                            <Col sm="3" className="d-flex justify-content-end align-items-center">
                                                                <Button className="btn-icon" color="success" size="sm" block type="button"
                                                                    onClick={() => handleSendPermissions()}
                                                                >
                                                                    <span className="btn-inner--icon mr-">
                                                                        <i className="fa fa-location-arrow" />
                                                                    </span>
                                                                    <span className="btn-inner--text">
                                                                        {sendButtonName}
                                                                    </span>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <PermissionsItems
                                                            permissionsRequests={permissionsRequests}
                                                            setPermissionsRequests={setPermissionsRequests}
                                                        >

                                                        </PermissionsItems>
                                                        {/* <PermissionsItems2
                                                            permissionsRequests={permissionsRequests}
                                                            setPermissionsRequests={setPermissionsRequests}

                                                        >

                                                        </PermissionsItems2> */}
                                                        {/* <PermissionsItems
                                                            permissionsRequests={permissionsRequests2}
                                                            setPermissionsRequests={setPermissionsRequests2}
                                                        >

                                                        </PermissionsItems> */}
                                                    </CardBody>
                                                    <CardFooter>
                                                        <Row>

                                                            <Col sm="12">
                                                                <Button className="btn-icon" color="default" size="sm" block type="button"
                                                                    onClick={() => handleAddNewRow()}
                                                                >
                                                                    <span className="btn-inner--icon mr-">
                                                                        <i className="fa fa-plus" />
                                                                    </span>
                                                                    <span className="btn-inner--text">
                                                                        {newRowName}
                                                                    </span>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardFooter>
                                                </Card>







                                            </DropdownMenu>
                                        </UncontrolledDropdown >
                                    </>
                                </Col>
                            </Row>

                        </CardHeader>



                        <Table
                            className="align-items-center  table-flush"
                            responsive
                            striped={true}
                            hover={true}
                        >
                            <thead className="thead-light p-0">

                                <tr>
                                    {
                                        columnsSelectedTableRequests.map((item, key) => {
                                            return (
                                                item.value !== "actions" ?

                                                    <th key={key} className="justify-content-md-center">

                                                        <FormGroup key={key}>
                                                            <label
                                                                style={{ fontSize: "12px" }}
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {`${item.label}:`}
                                                            </label>
                                                            <Input
                                                                id={item.id}
                                                                key={key}
                                                                className="form-control"
                                                                type="text"
                                                                placeholder={`Buscar ${item.label}...`}
                                                                // value={filters[item.value]}
                                                                value={Object.keys(filters).length > 0 ? filters[item.value] : ""}
                                                                onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </th>
                                                    : //Son las actions tipo checkbox
                                                    <th key={key} className="justify-content-md-center">
                                                        <Checkbox
                                                            checked={allCheckBoxOn}
                                                            onChange={(e) => handleActivateAllCheckbox(e.target.checked)}
                                                        >
                                                        </Checkbox>
                                                    </th>


                                            )
                                        })

                                    }
                                </tr>
                            </thead>
                            <tbody className='list'>

                                {rows.length > 0 ?

                                    rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {
                                        return (
                                            <tr key={key}>
                                                {
                                                    columnsSelectedTableRequests.map((col, keyCol) => {
                                                        return (
                                                            col.value === "actions" ? (
                                                                <td key={key + keyCol} className="justify-content-md-center">
                                                                    <Checkbox
                                                                        checked={checkBoxsOn.includes(row.id)}
                                                                        onChange={(e) => handleOnChangeCheckbox(e.target.checked, row.id)}
                                                                    >
                                                                    </Checkbox>                                                        </td>
                                                            )
                                                                : (

                                                                    <td key={key + keyCol} className="text-center text-break" style={{ whiteSpace: "normal", textAlign: "auto" }}>

                                                                        <span className="btn-inner--text">
                                                                            {row[col.value]}
                                                                        </span>

                                                                    </td>

                                                                )
                                                        )
                                                    })
                                                }

                                            </tr>

                                        )
                                    })
                                    :
                                    <>
                                        <tr>
                                            <td className="justify-content-md-center">
                                                Sin resultados encontrados
                                            </td>
                                        </tr>
                                    </>
                                }

                            </tbody>




                        </Table>
                    </Spin>
                    <CardFooter>

                        <Row className="align-items-center ">
                            <Col xs='12' md='6' className="p-1 ">
                                <Row className="justify-content-start">
                                    <Col xs='12' md='6'>
                                        <span className='pagination mb-0'>
                                            Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                        </span>
                                    </Col>
                                    <Col xs='12' md='2'>
                                        <Input type="select"
                                            onChange={(e) => handleSetNumPag(e)}>
                                            <option>5</option>
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs='12' md='6' className="p-1 ">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {
                                        renderPaginations()
                                    }
                                    <PaginationItem className={page === paginations ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>

                    </CardFooter>
                </Card >
            </Spin>
        </>

    )
}
