/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la tabla de Roles en roles por posicion
=========================================================
 
* Coded by Eduardo Piedra Sanabria - Application Management GBM

* Modificado por Sergio Marin para It requests

*/

import React, { useState, useEffect } from 'react' //Imports de librerías de react.
import {
    PaginationItem, PaginationLink, Pagination, CardHeader, FormGroup, Button,
    Input, Table, Badge, Card, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import CardFooter from 'reactstrap/lib/CardFooter';
import { Spin } from "antd";//Import de Spinner
import { useDispatch } from 'react-redux';//Import para consulta  en DB
import MultiSelect from 'react-select';
import ConfirmationModal from 'components/ItRequests/modals/ConfirmationModal';
import GenericModal from 'components/ItRequests/modals/GenericModal';
import { getExceptions, getDistinctUsers, addMatrixException, deleteMatrixException } from 'actions/ItRequests';// imports de actions 
import { encrypter } from 'helpers/desencrypt.jsx';

const RoleExceptionTable = ({ optionsJson, writePermission }) => {

    const dispatch = useDispatch();//Declaración de disparador para el api

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false); //para ConfirmationModal
    const [ConfirmationModalMsg, setConfirmationModalMsg] = useState(""); //para ConfirmationModal
    const [genModalOpen, setGenModalOpen] = useState(false); //para generic modal
    const [msgGenModal, setMsgGenModal] = useState(""); //para generic modal

    //#region Inicialización
    useEffect(() => {
        //Cargar filas cuando arranque por primera vez.
        setReloadTableRequests(true);

        //Parsear las lista de Roles para los multiselects
        let parsedJson = [];
        optionsJson.sapRoles.map((role) => {
            parsedJson.push({ label: role.value, value: role.id });
        });
        setRolesOption(parsedJson);

        //Parsear las lista de los User para los multiselects
        dispatch(getDistinctUsers()).then((resp) => {
            const { payload } = resp;
            setUsersOption(payload.data.options);
        });

    }, [])
    //#endregion

    //#region Columnas seleccionadas por defecto en la tabla.
    const selectedColumns = (condition) => {
        const baseColumns = [
            { value: "UserName", label: "Nombre Completo" },
            { value: "userID", label: "Usuario" },
            { value: "jobPosition", label: "Posición" },
            { value: "roleName", label: "Rol" },
            { value: "sapSystem", label: "Nombre Sistema" },
        ];

        if (condition) {
            return [
                { value: "deleteButton", label: "Botón" },
                ...baseColumns,
            ];
        } else {
            return baseColumns;
        }
    };

    const [columnsSelectedTableRequests, setColumnsSelectedTableRequests] = useState(
        // [
        //     { value: "UserName", label: "Nombre Completo" },
        //     { value: "userID", label: "Usuario" },
        //     { value: "jobPosition", label: "Posición" },
        //     { value: "roleName", label: "Rol" },
        //     { value: "sapSystem", label: "Nombre Sistema" },
        // ]
        selectedColumns(writePermission)
    );
    //#endregion

    //#region Funciones para aplicar flitros a la tabla

    //Filtros request tables
    const [filters, setFilters] = useState([]);

    //Capturar los valores de los inputs de los filtros
    const handleOnfilterDynamic = (constant, e) => {
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }

    //Aplicar los filtros a la data de la tabla
    const renderRowsByFilters = (rows) => {

        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);

            for (const element of filterKeys) {
                const valueFiltered = filters[element].toString().toLowerCase();

                rows = rows.filter(

                    (item) => {
                        let temp;

                        try {
                            temp = item[element]?.toString().toLowerCase().indexOf(valueFiltered);
                        } catch (e) { temp = -1 }

                        //Si se le da un espacio en el campo, imprima tdoos los vacios
                        if (valueFiltered === " ") {
                            return temp === undefined
                        } else { //No es un espacio, traiga todos los de la palabra clave
                            return temp != -1 && temp != undefined
                        }

                    }
                )
            }
            return rows
        }
    }

    //#endregion 

    //#region Extraer las filas de la tabla 

    //state para refrescar la tabla de Requests
    const [reloadTableRequests, setReloadTableRequests] = useState(false);

    const [rowsRequests, setRowsRequests] = useState({
        rows: [],
        length: 0,
    })

    //Cuando reloadTableRequests es true, ejecuta este efecto 
    useEffect(() => {
        if (reloadTableRequests) {

            const getDataR = async () => {
                await getData();
            }
            getDataR();
        }
    }, [reloadTableRequests]);


    //Traer las filas de la tabla de mis gestiones en base al usuario.
    const getData = async () => {

        dispatch(getExceptions()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsRequests((e) => ({
                    ...e,
                    rows: payload.data.exceptions[0],
                    length: payload.data.exceptions[0].length,
                }))
            } else {
                setRowsRequests({
                    rows: [],
                    length: 0,
                })

            }

            setReloadTableRequests(false);
        })
    }

    //#endregion

    //#region Realizar paginations en la tabla

    const [sizePerPage, setSizePerPage] = useState(10)
    const rows = renderRowsByFilters(rowsRequests.rows);

    //state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);

    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Establece el número de filas por pagina 
    const handleOnSetPage = (page) => {
        setPage(page);
    };

    const handleSetNumPag = (e) => {
        setPage(1);
        const value = e.target.value;
        setSizePerPage(value);
    }

    //#endregion

    //#region Funciones del modal de añadir excepciones
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [rolesOption, setRolesOption] = useState([]);
    const [usersOption, setUsersOption] = useState([]);
    const [comment, setComment] = useState("");
    const [modal, setModal] = useState(false);

    const toggleAddModal = () => setModal(!modal);

    //Funcion para agregar la excepciones seleccionados en el modal
    const addExecepButton = async () => {
        const encryptedSelectedUser = encrypter(JSON.stringify(selectedUser));
        const encryptedSelectedRoles = encrypter(JSON.stringify(selectedRoles));
        const encryptedComment = encrypter(JSON.stringify(comment));
        await dispatch(addMatrixException({ user: encryptedSelectedUser, roles: encryptedSelectedRoles, comment: encryptedComment })).then((resp) => {
            if (resp.payload.request.status !== 200) {
                if (resp.payload.response.data.code == "ER_DUP_ENTRY") {
                    setMsgGenModal(resp.payload.response.data.message)
                }
                else {
                    setMsgGenModal("No se pudo procesar su solicitud, si el problema persiste comuníquese con Application Management")
                }
                setGenModalOpen(true);
            }
        });

        setSelectedRoles([]);
        setSelectedUser([]);
        setReloadTableRequests(true);
        toggleAddModal();//Cierra el modal
    };

    //Funcion que carga la info de lo ingresado en los multiselects
    const handleMultiSelects = (id, selectedOptions) => {
        if (id === "rolesSelect") {
            setSelectedRoles(selectedOptions);
        }
        else if (id === "usersSelect") {
            setSelectedUser(selectedOptions);
        }
    };
    //#endregion

    //#region Funciones de borrar excepciones

    const [deletionExcep, setDeletionExcep] = useState({}); //para generic modal

    //Función que ejecuta el boton de eliminar de las filas
    const handleDelButton = (row) => {
        setDeletionExcep(row);
        setConfirmationModalMsg("Seguro desea eliminar el Rol: " + row.roleName.toUpperCase() + " para el colaborador " + row.UserName.toUpperCase());
        setConfirmationModalOpen(true);
    }
    //Funcion para borrar los roles de la fila
    const deleteRow = async () => {
        const encryptedDeletionExcep = encrypter(JSON.stringify(deletionExcep));
        await dispatch(deleteMatrixException({ deletionExcep: encryptedDeletionExcep })).then((resp) => {
            if (resp.payload.request.status !== 200) {
                setMsgGenModal("No se pudo procesar su solicitud, si el problema persiste comuníquese con Application Management")
                setGenModalOpen(true);
            }
        });

        setReloadTableRequests(true);
    };

    //#endregion

    return (
        <>
            <Card className=" ">
                <CardHeader className="bg-transparent border-0">
                    <Row className=" ">
                        <Col xs="7" sm="10" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <Col xs="auto" sm="auto" className="bg-transparent border-0">
                                <h3 className=" mb-0">
                                    Excepciones por Usuario, Rol y Posición</h3>
                            </Col>
                        </Col>

                        <Col xs="auto" sm="1" className=" " style={{ display: "flex", justifyContent: "flex-end" }}>
                            {/* ESTE BOTON DEPENDE DEL ROL */}
                            {writePermission && (
                                <Button id="addBtn" className="btn-success" size="sm" block type="button" style={{ width: "150px ", height: "30px" }}
                                    onClick={toggleAddModal}
                                >
                                    <span className="btn-inner--icon mr-">
                                        <i className="fas fa-plus" />
                                    </span>
                                    <span className="btn-inner--text">
                                        {" "}Agregar Excepción
                                    </span>
                                </Button>
                            )}
                        </Col>

                        <Col xs="auto" sm="1" className=" " style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button id="refreshBtn" className="btn-info" size="sm" block type="button" style={{ width: "100px ", height: "30px" }}
                                onClick={(e) => setReloadTableRequests(true)}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-sync-alt" />
                                </span>
                                <span className="btn-inner--text">
                                    {" "}Refrescar
                                </span>
                            </Button>
                        </Col>
                    </Row>

                </CardHeader>

                <Spin size="large" spinning={reloadTableRequests}>
                    <Table
                        className="align-items-center  table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">
                            <tr>
                                {
                                    columnsSelectedTableRequests.map((item, key) => {
                                        return (
                                            item.value !== "deleteButton" ?
                                                <th key={key} className="justify-content-md-center">
                                                    <div style={{ width: item.width }}>
                                                        <FormGroup key={key}>
                                                            <label
                                                                style={{ fontSize: "12px" }}
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {`${item.label}:`}
                                                            </label>
                                                            <Input
                                                                id={item.id}
                                                                key={key}
                                                                className="form-control"
                                                                type="text"
                                                                placeholder={`Buscar ${item.label}...`}
                                                                onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </th>
                                                :
                                                <th key={key} className="justify-content-md-center">
                                                </th>
                                        )
                                    })

                                }
                            </tr>
                        </thead>
                        <tbody className='list'>

                            {rows.length > 0 ?
                                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {
                                    return (
                                        <tr key={key}>
                                            {
                                                columnsSelectedTableRequests.map((col, keyCol) => {
                                                    return (
                                                        col.value === "Estado" ? (
                                                            <td key={key + keyCol} className="justify-content-md-center">
                                                                <div>
                                                                    {
                                                                        <Badge key={key + keyCol} color={row.statusType} pill>
                                                                            {row.Estado}
                                                                        </Badge>
                                                                    }

                                                                </div>
                                                            </td>
                                                        ) : col.value === "deleteButton" ? ( //Este status es para el botón de detalle
                                                            <td key={key + keyCol} className="justify-content-md-center">
                                                                <div style={{ width: col.width }}>
                                                                    <Row key={key + keyCol + "row"}>
                                                                        {row.statusId !== "1" && //No lo muestre cuando apenas se está generando la opp
                                                                            <Col key={key + keyCol + "col"}>
                                                                                <Button
                                                                                    id="refreshBtn"
                                                                                    className="btn-danger"
                                                                                    size="sm"
                                                                                    block type="button"
                                                                                    style={{ width: "80px ", height: "30px" }}
                                                                                    onClick={(e) => handleDelButton(row)}
                                                                                >
                                                                                    <span className="btn-inner--icon mr-">
                                                                                        <i className="fas fa-trash" />
                                                                                    </span>
                                                                                    <span className="btn-inner--text">
                                                                                        {" "} Eliminar
                                                                                    </span>
                                                                                </Button>
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                </div>
                                                            </td>

                                                        ) : (

                                                            <td key={key + keyCol} className="text-center text-break" style={{ whiteSpace: "normal", textAlign: "auto" }}>
                                                                <div style={{ width: col.width }} >

                                                                    <span className="btn-inner--text">
                                                                        {row[col.value]}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        )
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                                :
                                <>
                                    <tr>
                                        <td className="justify-content-md-center">
                                            Sin resultados encontrados
                                        </td>
                                    </tr>
                                </>
                            }

                        </tbody>

                    </Table>
                </Spin>
                <CardFooter>

                    <Row className="align-items-center ">
                        <Col xs='12' md='6' className="p-1 ">
                            <Row className="justify-content-start">
                                <Col xs='12' md='6'>
                                    <span className='pagination mb-0'>
                                        Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                    </span>
                                </Col>
                                <Col xs='12' md='2'>
                                    <Input type="select"
                                        onChange={(e) => handleSetNumPag(e)}>
                                        <option>5</option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs='12' md='6' className="p-1 ">
                            <Pagination
                                className="pagination justify-content-end mb-0"
                                listClassName="justify-content-end mb-0"
                            >
                                <PaginationItem className={page === 1 ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                        tabIndex="-1"
                                    >
                                        <i className="fas fa-angle-left" />
                                        <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                </PaginationItem>
                                {
                                    renderPaginations()
                                }
                                <PaginationItem className={page === paginations ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                    >
                                        <i className="fas fa-angle-right" />
                                        <span className="sr-only">Next</span>
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
            <Modal isOpen={modal} toggle={toggleAddModal}  >
                <ModalHeader toggle={toggleAddModal}>Añadir nuevo rol</ModalHeader>
                <ModalBody>
                    Colaborador
                    <MultiSelect
                        closeMenuOnSelect
                        id="usersSelect"
                        isMulti={false}
                        options={usersOption}
                        value={selectedUser}
                        onChange={(selectedOption) => handleMultiSelects("usersSelect", selectedOption)}
                    />
                    Rol
                    <MultiSelect
                        closeMenuOnSelect={false}
                        id="rolesSelect"
                        isMulti
                        options={rolesOption}
                        value={selectedRoles}
                        onChange={(selectedOption) => handleMultiSelects("rolesSelect", selectedOption)}
                    />
                    Comentario
                    <Input
                        style={{ marginLeft: '-1px' }}
                        type="textarea"
                        placeholder="Observaciones (max 500 caracteres)"
                        id="excepComments"
                        className="form-control"
                        onChange={(e) => setComment(e.target.value)}
                        maxLength="500"
                    />

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={addExecepButton}>Añadir</Button>
                    {' '}
                    <Button color="secondary" onClick={toggleAddModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <ConfirmationModal
                showModal={confirmationModalOpen}
                setShowModal={setConfirmationModalOpen}
                msg={ConfirmationModalMsg}
                okFunct={deleteRow}
            />
            <GenericModal
                showModal={genModalOpen}
                setShowModal={setGenModalOpen}
                msg={msgGenModal}
            />
        </>
    );
};

export default RoleExceptionTable;