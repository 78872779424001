import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findAvailableFilters = createAction(constants.GET_AVAILABLE_FILTERS, method.get(urls.COE.getAvailableFilters));

export const activedAvailableFilters = createAction(constants.ACTIVATE_AVAILABLE_FILTERS, key => key);

export const findFiltersDataByFilter = createAction(constants.GET_FILTERS_DATA_BY_FILTER, (body) => method.post(urls.COE.getFiltersDataByFilter, body)());

export const findDataByCalendarMode = createAction(constants.GET_DATA_BY_CALENDAR_MODE, (body) => method.post(urls.COE.getDataByCalendarMode, body)());

export const findHistoryDataByCalendarMode = createAction(constants.GET_HISTORY_DATA_BY_CALENDAR_MODE, (body) => method.post(urls.COE.getHistoryDataByCalendarMode, body)());

export const findIndicatorDataByCalendarMode = createAction(constants.GET_INDICATOR_DATA_BY_CALENDAR_MODE, (body) => method.post(urls.COE.getIndicatorDataByCalendarMode, body)());

export const findCollaboratorsReported = createAction(constants.GET_COLLABORATORS_REPORTED, (body) => method.post(urls.COE.getCollaboratorsReported, body)());

export const findHoursAccusedByDates = createAction(constants.GET_HOURS_ACCUSED, (body) => method.post(urls.COE.getHoursAccusedByDates, body)());

export const findValuesMaintenanceUsers = createAction(constants.GET_VALUES_MAINTENANCE_USERS, method.get(urls.COE.getValuesMaintenanceUsers));

export const findAllEmaUsersActive = createAction(constants.GET_ALL_EMA_USERS_ACTIVE, method.get(urls.COE.getAllEmaUsersActive));

export const findActivitiesReportByDates = createAction(constants.GET_ACTIVITIES_REPORT, (body) => method.post(urls.COE.getActivitiesReportByDates, body)());

export const createUserCoe = createAction(constants.CREATE_USER_COE, (body) => method.post(urls.COE.createUserCoe, body)());

export const updateUserCoe = createAction(constants.UPDATE_USER_COE, (body) => method.put(urls.COE.updateUserCoe, body)());

export const createMasterVariable = createAction(constants.CREATE_MASTER_VARIABLE, (body) => method.post(urls.COE.createMasterVariable, body)());

export const updateMasterVariableByID = createAction(constants.UPDATE_MASTER_VARIABLE_BY_ID, (id, body) => method.put(`${urls.COE.updateMasterVariableByID}/${id}`, body)());

export const deactivateMasterVariableByID = createAction(constants.DEACTIVATE_MASTER_VARIABLE_BY_ID, (id, type) => method.put(`${urls.COE.deactivateMasterVariableByID}/${id}/${type}`)());
