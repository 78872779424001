// ** Custom Components
import Avatar from 'components/Shared/Avatar/index'
import React from 'react'
import {
  Badge
} from 'reactstrap'


export const columns = [
    {
      name: 'Responsable',
      selector: row=> row.responsable,
      sortable: true,
      minWidth: '250px',
      cell: row => (
        <div className='d-flex align-items-center'>
          <Avatar
            color={`${row.status}`}
            content={row.username}
            initials
          />
          <div className='user-info text-truncate ml-1'>
            <span className='d-block font-weight-bold text-truncate'>
              {row.sales_name}
            </span>
            <small>{row.position}</small>
          </div>
        </div>
      )
    },
    {
      name: 'Cliente',
      selector:  row=> row.customer_id,
      sortable: true,
      minWidth: '250px'
    },
    {
      name: 'Contacto',
      selector:  row=> row.contact_id,
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Acción',
      selector: row=> row.action,
      sortable: true,
      minWidth: '150px',
      cell: row => {
        return (
          <Badge color={row.status} pill>
            {row.actionTitle}
          </Badge>
        )
      }
    }
  ]