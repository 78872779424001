// javascript plugin that creates nice dropzones for files
import { Spin, Upload, message, Icon } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal, Col } from "reactstrap";

const { Dragger } = Upload;

const ChangeStatusModal = props => {

  const {
    title,
    status,
    loading,
    showModal,
    initialValues,
    toggleModal,
    onUpdate,
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.status || values.status === "0")
      errors.status = "Debes seleccionar un estado.";
    if (!values.comments)
      errors.comments = "Debes ingresar un comentario.";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onUpdate}
              validate={validate}
              initialValues={initialValues}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label for="status">Estado</Label>
                      <Field name="status">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              valid={!meta.error && meta.modified && meta.touched}
                              invalid={meta.error && meta.touched}
                            >
                              <option key="0" value="0">Seleccione una posición</option>
                              {
                                status.filter((row) => row.id !== 5 && row.id !== 6 && row.id !== 7).map((row, key) => {
                                  return (
                                    <option key={key} value={row.id}>{row.name}</option>
                                  )
                                })
                              }
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label for="comments">Comentario (500)</Label>
                      <Field name="comments">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="textarea"
                              placeholder="Escriba aquí si tiene algún comentario."
                              maxLength={500}
                              rows="3"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Actualizar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

ChangeStatusModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ChangeStatusModal;