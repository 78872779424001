// core antd
import { Divider } from "antd";
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

moment.locale("es");

const Request = (props) => {
  const {
    request,
    onBack,
    onViewEquipments,
    onConfigurationView,
    onNewActivityFlow,
  } = props;

  console.log(request);

  const today =
    request.state <= 10
      ? moment().format("DD/MM/YYYY HH:mm:ss")
      : moment(request.updatedAt).format("DD/MM/YYYY HH:mm:ss");
  const createdAt = moment(request.assignmentCreation).format(
    "DD/MM/YYYY HH:mm:ss"
  );
  const ms = moment(today, "DD/MM/YYYY HH:mm:ss").diff(
    moment(createdAt, "DD/MM/YYYY HH:mm:ss")
  );
  const {
    _data: { days, hours, minutes },
  } = moment.duration(ms);
  const daysText = `${days} ${days === 1 ? "día" : "días"}`;
  const hoursText = `${hours} ${hours === 1 ? "hora" : "horas"}`;
  const minutesText = `${minutes} ${minutes === 1 ? "minuto" : "minutos"}`;
  const time = `${
    days > 0
      ? `${daysText}, ${hoursText} y ${minutesText}`
      : hours > 0
      ? `${hoursText} y ${minutesText}`
      : minutesText
  }`;

  return (
    <Row>
      <Col className="order-xl-2" md="4">
        <Row>
          <Col>
            <Card className="bg-gradient-success border-0">
              <CardBody>
                <Row>
                  <Col sm="9">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Estado del Requerimiento
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {request.status}
                    </span>
                  </Col>
                  <Col sm="3">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className="ni ni-bell-55" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-gradient-danger border-0">
              <CardBody>
                <Row>
                  <Col sm="9">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Tiempo Transcurrido desde la asignación
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {time}
                    </span>
                  </Col>
                  <Col sm="3">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className="ni ni-time-alarm" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-gradient-warning border-0">
              <CardBody>
                <Row>
                  <Col sm="9">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Requiere Partes Críticas
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {request.criticalParts}
                    </span>
                  </Col>
                  <Col sm="3">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className="fas fa-exclamation-triangle" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-gradient-default">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-white" tag="h3">
                      Agregar Nota
                    </CardTitle>
                    <blockquote className="blockquote text-white mb-0">
                      <p>
                        Creación de notas al flujo de actividades del
                        requerimiento
                      </p>
                    </blockquote>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className="fas fa-file-code" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      // outline
                      className="btn-icon btn-3"
                      color="info"
                      type="button"
                      size="sm"
                      onClick={onNewActivityFlow}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-plus-square" />
                      </span>
                      <span className="btn-inner--text">Crear Nota</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {request.state > 5 && (
          <Row>
            <Col>
              <Card className="bg-gradient-default">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-white" tag="h3">
                        Configuraciones Agregadas
                      </CardTitle>
                      <blockquote className="blockquote text-white mb-0">
                        <p>
                          Visualizar los archivos de configuración adjuntados al
                          requerimiento
                        </p>
                      </blockquote>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fas fa-paperclip" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        outline
                        className="btn-icon btn-3"
                        color="info"
                        type="button"
                        size="sm"
                        onClick={() => onConfigurationView("view")}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-folder-open" />
                        </span>
                        <span className="btn-inner--text">Ver Archivos</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Card className="bg-gradient-default">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-white" tag="h3">
                      Equipos Agregados
                    </CardTitle>
                    <blockquote className="blockquote text-white mb-0">
                      <p>Visualizar los equipos agregados al requerimiento</p>
                    </blockquote>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className="fas fa-desktop" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      outline
                      className="btn-icon btn-3"
                      color="info"
                      type="button"
                      size="sm"
                      onClick={onViewEquipments}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-clipboard-list" />
                      </span>
                      <span className="btn-inner--text">Ver Equipos</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {request.state === 5 && (
          <Row>
            <Col>
              <Card className="bg-gradient-default">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-white" tag="h3">
                        Archivo de configuración
                      </CardTitle>
                      <blockquote className="blockquote text-white mb-0">
                        <p>
                          Cargar el archivo requerido de configuración para la
                          Oportunidad respectiva.
                        </p>
                      </blockquote>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fas fa-cogs" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        outline
                        className="btn-icon btn-3"
                        color="info"
                        type="button"
                        onClick={() => onConfigurationView("add")}
                        size="sm"
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-file-upload" />
                        </span>
                        <span className="btn-inner--text">Cargar Archivo</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Col>
      <Col className="order-xl-1" md="8">
        <Row>
          <Col>
            <Card className="mb-4">
              <CardHeader>
                <Row>
                  <Col xs="12" md="9">
                    <h5 className="surtitle">Información del Requerimiento</h5>
                    <Badge className="badge-lg mt-1" color="primary" pill>
                      v{request.version}
                    </Badge>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="opportunityNumber">
                        Número de Oportunidad
                      </Label>
                      <Input
                        id="opportunityNumber"
                        type="number"
                        className="form-control"
                        value={request.opportunityNumber}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="customer">Nombre del Cliente</Label>
                      <Input
                        id="customer"
                        type="text"
                        className="form-control"
                        value={request.customer}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="requestedExecutive">
                        Ejecutivo de la Solicitud
                      </Label>
                      <Input
                        id="requestedExecutive"
                        type="text"
                        className="form-control"
                        value={request.requestedExecutive}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="salesRep">Representante de Ventas</Label>
                      <Input
                        id="salesRep"
                        type="text"
                        className="form-control"
                        value={request.salesRep}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="typeSupport">Tipo de Soporte</Label>
                      <Input
                        id="typeSupport"
                        type="text"
                        className="form-control"
                        value={request.typeSupport}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="businessModel">Modelo de Negocio</Label>
                      <Input
                        id="businessModel"
                        type="text"
                        className="form-control"
                        value={request.businessModel}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="operatingSystemType">
                        Tipo de Sistema Operativo
                      </Label>
                      <Input
                        id="operatingSystemType"
                        type="text"
                        className="form-control"
                        value={request.operatingSystemType}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="amountOfEquipment">Cantidad de Equipos</Label>
                      <Input
                        id="amountOfEquipment"
                        type="text"
                        className="form-control"
                        value={request.amountOfEquipment}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="officeHours">Horario de Atención</Label>
                      <Input
                        id="officeHours"
                        type="text"
                        className="form-control"
                        value={request.officeHours}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="responseTime">Tiempo de Respuesta</Label>
                      <Input
                        id="responseTime"
                        type="text"
                        className="form-control"
                        value={request.responseTime}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="8">
                    <FormGroup>
                      <Label for="applicationNotes">Notas</Label>
                      <Input
                        id="applicationNotes"
                        type="textarea"
                        className="form-control"
                        value={request.applicationNotes}
                        disabled
                        maxLength={300}
                        rows="3"
                        resize="none"
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="timeChangePart">
                        Tiempo para Cambio de Partes
                      </Label>
                      <Input
                        id="timeChangePart"
                        type="text"
                        className="form-control"
                        value={request.timeChangePart}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="validityService">Vigencia del Servicio</Label>
                      <Input
                        id="validityService"
                        type="text"
                        className="form-control"
                        value={request.validityService}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="wayPay">Forma de Pago del Servicio</Label>
                      <Input
                        id="officwayPayeHours"
                        type="text"
                        className="form-control"
                        value={request.wayPay}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </div>
                <Divider>Detalles de Ubicación</Divider>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="locationType">
                        Ubicación Fisica de los Equipos
                      </Label>
                      <Input
                        id="locationType"
                        type="text"
                        className="form-control"
                        value={request.locationType}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="amountOfEquipmentIn">
                        Cantidad de equipos dentro del radio
                      </Label>
                      <Input
                        id="amountOfEquipmentIn"
                        type="number"
                        className="form-control"
                        value={request.amountOfEquipmentIn}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="distanceInside">
                        Distancia del Centro de Servicios Autorizado
                      </Label>
                      <Input
                        id="distanceInside"
                        type="text"
                        className="form-control"
                        value={request.distanceInside}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="amountOfEquipmentOut">
                        Cantidad de equipos fuera del radio
                      </Label>
                      <Input
                        id="amountOfEquipmentOut"
                        type="text"
                        className="form-control"
                        value={request.amountOfEquipmentOut}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="distanceOutside">
                        Distancia del Centro de Servicios Autorizado
                      </Label>
                      <Input
                        id="distanceOutside"
                        type="text"
                        className="form-control"
                        value={request.distanceOutside}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="4">
                    <FormGroup>
                      <Label for="localtionNotes">Notas</Label>
                      <Input
                        id="localtionNotes"
                        type="text"
                        className="form-control"
                        value={request.localtionNotes}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </div>
                <Divider>Detalles de Mantenimientos</Divider>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12" md="6">
                    <FormGroup>
                      <Label for="amountMaintenance">
                        Cantidad de Mantenimientos Preventivos
                      </Label>
                      <Input
                        id="amountMaintenance"
                        type="text"
                        className="form-control"
                        value={request.amountMaintenance}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12" md="6">
                    <FormGroup>
                      <Label for="scheduleMaintenance">
                        Horario del Mantenimiento Preventivo
                      </Label>
                      <Input
                        id="scheduleMaintenance"
                        type="text"
                        className="form-control"
                        value={request.scheduleMaintenance}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row align-items-center justify-content-center">
                  <Col
                    className="mb-3"
                    sm="12"
                    md="3"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    <Button
                      className="btn-icon"
                      color="danger"
                      type="button"
                      block
                      onClick={() => onBack("requestSelected")}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-arrow-left" />
                      </span>
                      <span className="btn-inner--text">Salir</span>
                    </Button>
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Request.propTypes = {
  request: PropTypes.object.isRequired,
};

export default Request;
