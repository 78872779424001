// core actions
import { findAllDataAdminDashboard, downloadVaccineDataLocation } from 'actions/medicalRecords.jsx';
// antd components
import { Spin } from 'antd';
// core components Medical Records
import Charts from 'components/MedicalRecords/Charts.jsx';
import Transactions from 'components/MedicalRecords/Transactions.jsx';
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// javascript library for dates
import moment from 'moment';
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getAllCountriesGraph, getDataChartDashboard, getDataPercentChartDashboard, getTransactionsGraph, getDataVaccineChartDashboard } from "selectors/medicalRecords.jsx";
class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      yearSelected: {
        value: 1,
        label: moment().year()
      }
    };
  };

  componentWillMount() {
    const { yearSelected } = this.state;
    this.props.findAllDataAdminDashboard(yearSelected.label).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          willMount: false,
        });
      }
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnChangeYearView = (who, type) => {
    this.setState({
      willMount: true,
    });
    this.props.findAllDataAdminDashboard(type.label).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          [who]: type
        });
      }
      this.setState({
        willMount: false,
      });
    });
  };

  handleOnExportData = type => {
    const { yearSelected } = this.state;
    if (type === "vaccine") {
      this.props.downloadVaccineDataLocation(yearSelected.label).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            this.notify("warning", "Atención", `No se logro descargar la información del modelo seleccionado`);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([res.payload.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Reporte Vacunas Localidad.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
    }
  };

  render() {

    const {
      name,
      parentName,
      dataMonth,
      dataPercent,
      dataVaccine,
      yearsOptions,
      transactions,
    } = this.props;

    const {
      loading,
      message,
      willMount,
      yearSelected,
    } = this.state;


    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          recordsDashboard
          name={name}
          parentName={parentName}
          yearsStatus={yearsOptions}
          yearSelected={yearSelected}
          selectionChange={this.handleOnChangeYearView}
        />
        <Container className="mt--6" fluid>
          {
            !willMount ?
              <Spin size="large" spinning={loading} tip={message}>
                <Row>
                  <Col>
                    <Transactions
                      data={transactions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Charts
                      bar
                      dowload
                      title="Vacunas"
                      subtitle="Gráfico anual referente a la información de vacunas por localidad"
                      type="vaccine"
                      object={dataVaccine}
                      onExport={this.handleOnExportData}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="7">
                    <Charts
                      bar
                      title="Expedientes Médicos"
                      subtitle="Gráfico anual referente a la información médica por mes"
                      type="records"
                      object={dataMonth}
                    />
                  </Col>
                  <Col xs="12" md="5">
                    <Charts
                      pie
                      title="Expedientes Médicos"
                      subtitle="Gráfico porcentual referente a los expedientes médicos por localidad"
                      type="percents"
                      object={dataPercent}
                    />
                  </Col>
                </Row>
              </Spin>
              :
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando los datos de los expedientes médicos" />
                </Col>
              </Row>
          }
        </Container>
      </>
    );
  }
}

Dashboard.defaultProps = {
  name: "Dashboard",
  parentName: "Expediente Médico",
  dataMonth: {},
  dataPercent: {},
  dataVaccine: {},
  yearsOptions: [],
  transactions: {},
};

Dashboard.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  dataMonth: PropTypes.object.isRequired,
  dataPercent: PropTypes.object.isRequired,
  dataVaccine: PropTypes.object.isRequired,
  yearsOptions: PropTypes.array.isRequired,
  transactions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  dataMonth: getDataChartDashboard(state),
  dataPercent: getDataPercentChartDashboard(state),
  dataVaccine: getDataVaccineChartDashboard(state),
  yearsOptions: getAllCountriesGraph(state),
  transactions: getTransactionsGraph(state),
});

export default withRouter(connect(mapStateToProps, {
  findAllDataAdminDashboard,
  downloadVaccineDataLocation
})(Dashboard));
