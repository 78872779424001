import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Bar, Pie, Line } from "react-chartjs-2";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { chartOptions, parseOptions } from "variables/charts.jsx";
import Chart from "chart.js";

const ChartTotalValue = ({ title, subtitle, bar, pie, line, object }) => {
  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }, []);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col xs="12" md="7">
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart">
          {bar && (
            <Bar
              data={object.data}
              options={object.options}
              className="chart-canvas"
              id="chart-bar-stacked"
            />
          )}
          {pie && (
            <Pie
              data={object.data}
              options={object.options}
              className="chart-canvas"
              id="chart-pie"
            />
          )}
          {line && (
            <Line
              data={object.data}
              options={object.options}
              id="chart-line"
              className="chart-canvas"
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

ChartTotalValue.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  bar: PropTypes.bool,
  pie: PropTypes.bool,
  line: PropTypes.bool,
};

export default ChartTotalValue;
