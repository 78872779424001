// javascript plugin that creates nice dropzones for files
import { Icon, Upload, Spin } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  DropdownItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

const { Dragger } = Upload;

const RegisterSignatureForm = (props) => {
  const {
    title,
    subtitle,
    initialValues,
    countrys,
    organizationalUnits,
    documents,
    loading,
    onFileList,
    onSearch,
    onCancel,
    onSubmit,
  } = props;

  const uploadProps = {
    name: "file",
    multiple: true,
    // action: 'https://ss-api.gbm.net/auth/test',
    action: "https://smartsimple.gbm.net:43888/auth/test",
    onChange: onFileList,
  };

  const validate = (values) => {
    const errors = {};
    if (!values.idCollaborator)
      errors.idCollaborator = "El número de colaborador es obligatorio.";
    if (Object.keys(initialValues).length) {
      if (!values.collaborator)
        errors.collaborator =
          "El nombre completo del colaborador es obligatorio.";
      if (!values.country || values.country === 0)
        errors.country = "El país es obligatorio, debes seleccionar uno.";
    }
    return errors;
  };

  const index = (documents.length / 2).toFixed(0);

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Spin size="large" spinning={loading}>
            <Form
              onSubmit={Object.keys(initialValues).length ? onSubmit : onSearch}
              initialValues={initialValues}
              validate={validate}
              render={({
                handleSubmit,
                values,
                submitting,
                validating,
                valid,
              }) => (
                <FormRS onSubmit={handleSubmit}>
                  <div className="form-row align-items-center">
                    <Col className="mb-3" sm="12" md="4">
                      <FormGroup>
                        <Label for="idCollaborator">
                          Número de Colaborador
                        </Label>
                        <Field name="idCollaborator">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="number"
                                placeholder="Ingrese el número del colaborador"
                                disabled={
                                  Object.keys(initialValues).length &&
                                  initialValues.idCollaborator !== null
                                }
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <span className="invalid-feedback">
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    {Object.keys(initialValues).length ? (
                      <>
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label for="username">Nombre de Usuario</Label>
                            <span
                              id="username"
                              className="btn-inner--icon mr-1"
                              style={{
                                display: "inlineBlock",
                                marginLeft: "0.5rem",
                              }}
                            >
                              <i className="fas fa-question" />
                            </span>
                            <Field name="username">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="text"
                                    placeholder="Ingrese el nombre de usuario"
                                    disabled={
                                      Object.keys(initialValues).length &&
                                      initialValues.username !== "NA" &&
                                      initialValues.username !== null
                                    }
                                    invalid={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <span className="invalid-feedback">
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              )}
                            </Field>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="username"
                            >
                              Si no tiene nombre de usuario, omitir.
                            </UncontrolledTooltip>
                          </FormGroup>
                        </Col>
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label for="collaborator">Colaborador</Label>
                            <Field name="collaborator">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="text"
                                    placeholder="Ingrese el nombre completo del colaborador"
                                    disabled={
                                      Object.keys(initialValues).length &&
                                      initialValues.collaborator !== null
                                    }
                                    invalid={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <span className="invalid-feedback">
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </>
                    ) : null}
                  </div>
                  {Object.keys(initialValues).length ? (
                    <>
                      <div className="form-row align-items-center">
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label for="email">Correo electrónico</Label>
                            <span
                              id="email"
                              className="btn-inner--icon mr-1"
                              style={{
                                display: "inlineBlock",
                                marginLeft: "0.5rem",
                              }}
                            >
                              <i className="fas fa-question" />
                            </span>
                            <Field name="email">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="email"
                                    placeholder="Ingrese el correo electrónico"
                                    disabled={
                                      Object.keys(initialValues).length &&
                                      initialValues.email !== "NA" &&
                                      initialValues.email !== null
                                    }
                                    invalid={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <span className="invalid-feedback">
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              )}
                            </Field>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="email"
                            >
                              Si no tiene correo electrónico, omitir.
                            </UncontrolledTooltip>
                          </FormGroup>
                        </Col>
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label for="country">País</Label>
                            <Field name="country">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="select"
                                    disabled={
                                      Object.keys(initialValues).length &&
                                      initialValues.country !== null
                                    }
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">
                                      Seleccione un país
                                    </option>
                                    {countrys.map((row, key) => {
                                      return (
                                        <option key={key} value={row.key}>
                                          {row.name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                  {meta.error && meta.touched && (
                                    <span className="invalid-feedback">
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col className="mb-3" sm="12" md="4">
                          <FormGroup>
                            <Label for="office">Departamento</Label>
                            <span
                              id="office"
                              className="btn-inner--icon mr-1"
                              style={{
                                display: "inlineBlock",
                                marginLeft: "0.5rem",
                              }}
                            >
                              <i className="fas fa-question" />
                            </span>
                            <Field name="office">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="select"
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">
                                      Seleccione un departamento
                                    </option>
                                    {organizationalUnits.map((row, key) => {
                                      return (
                                        <option key={key} value={row.id}>
                                          {row.name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                  {meta.error && meta.touched && (
                                    <span className="invalid-feedback">
                                      {meta.error}
                                    </span>
                                  )}
                                </div>
                              )}
                            </Field>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="office"
                            >
                              Si no tiene departamento, omitir.
                            </UncontrolledTooltip>
                          </FormGroup>
                        </Col>
                      </div>
                      {documents.length ? (
                        <>
                          <div className="form-row align-items-center">
                            Seleccione los documentos firmados.
                          </div>
                          <DropdownItem divider />
                        </>
                      ) : null}
                      <div className="form-row align-items-center">
                        {documents.slice(0, index).map((row, key) => {
                          return (
                            <Col
                              key={key}
                              className="mb-3"
                              sm="12"
                              md={(12 / index).toFixed(0)}
                            >
                              <FormGroup check>
                                <Field
                                  name={`document_${row.idDocumento}`}
                                  type="checkbox"
                                >
                                  {({ input, meta }) => (
                                    <Label>
                                      <Input
                                        {...input}
                                        type="checkbox"
                                        invalid={meta.error && meta.touched}
                                      />{" "}
                                      {row.Descripcion}
                                      {meta.error && meta.touched && (
                                        <span>{meta.error}</span>
                                      )}
                                    </Label>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          );
                        })}
                      </div>
                      <div className="form-row align-items-center">
                        {documents
                          .slice(index, documents.lengt)
                          .map((row, key) => {
                            return (
                              <Col
                                key={key}
                                className="mb-3"
                                sm="12"
                                md={(12 / index).toFixed(0)}
                              >
                                <FormGroup check>
                                  <Field
                                    name={`document_${row.idDocumento}`}
                                    type="checkbox"
                                  >
                                    {({ input, meta }) => (
                                      <Label>
                                        <Input
                                          {...input}
                                          type="checkbox"
                                          invalid={meta.error && meta.touched}
                                        />{" "}
                                        {row.Descripcion}
                                        {meta.error && meta.touched && (
                                          <span>{meta.error}</span>
                                        )}
                                      </Label>
                                    )}
                                  </Field>
                                </FormGroup>
                              </Col>
                            );
                          })}
                      </div>
                      <div className="form-row align-items-center">
                        <Col className="mb-3" sm="12" md="12">
                          <FormGroup>
                            <Label for="files">Adjuntar documentos</Label>
                            <span
                              id="files"
                              className="btn-inner--icon mr-1"
                              style={{
                                display: "inlineBlock",
                                marginLeft: "0.5rem",
                              }}
                            >
                              <i className="fas fa-question" />
                            </span>
                            <Dragger {...uploadProps}>
                              <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                              </p>
                              <p className="ant-upload-text">
                                Suelte los archivos aquí o haga clic para
                                cargar.
                              </p>
                              <p className="ant-upload-hint">
                                Cargar todos los archivos firmados por el
                                colaborador como evidencia de la solicitud que
                                está realizando.
                              </p>
                            </Dragger>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="files"
                            >
                              Debes adjuntar las políticas firmadas por el
                              colaborador.
                            </UncontrolledTooltip>
                          </FormGroup>
                        </Col>
                      </div>
                    </>
                  ) : null}
                  <div className="form-row align-items-center">
                    {Object.keys(initialValues).length ? (
                      <>
                        <Col className="mb-3" sm="12" md="3">
                          <Button
                            disabled={submitting || loading}
                            className="btn-icon"
                            block
                            color="info"
                            type="button"
                            onClick={onCancel}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-window-close" />
                            </span>
                            <span className="btn-inner--text">Cancelar</span>
                          </Button>
                        </Col>
                        <Col className="mb-3" sm="12" md="3">
                          <Button
                            disabled={validating || loading}
                            className="btn-icon"
                            block
                            color="info"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-save" />
                            </span>
                            <span className="btn-inner--text">
                              Registrar firmas
                            </span>
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <Col className="mb-3" sm="12" md="3">
                        <Button
                          disabled={validating || loading}
                          className="btn-icon"
                          block
                          color="info"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-search" />
                          </span>
                          <span className="btn-inner--text">
                            Buscar colaborador
                          </span>
                        </Button>
                      </Col>
                    )}
                  </div>
                </FormRS>
              )}
            />
          </Spin>
        </CardBody>
      </Card>
    </>
  );
};

RegisterSignatureForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default RegisterSignatureForm;
