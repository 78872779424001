import React, { useState, useEffect } from 'react';
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Filter from "components/FinanceFlows/Administration/Filters";
import Tables from "components/FinanceFlows/Administration/Tables";
import Modal from "components/FinanceFlows/Administration/Modals";
import { Card, CardBody, Col, CardHeader, FormGroup, Input, Button, Row, Table } from "reactstrap";
import { Spin } from 'antd';
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getOptions, getAllUnitType, insertUnitType, updateUnitType, deleteUnitType } from "actions/financialFlows"

const GeneralConfiguration = (props) => {

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState("");
    const [loading, setLoad] = useState(false);
    const [page, setPage] = useState(1);
    const [openModal, setModal] = useState({
        createModal: false,
        editModal: false
    });
    const [newInfo, setNewInfo] = useState({});
    const [itemInfo, setItemInfo] = useState("");
    const [optionsExtraModal, setOptionExtraModal] = useState([]);
    const [reload, setReLoad] = useState(10);
    const [filterByTable, setFiltersByTable] = useState([]);
    const [infoToModule, setInfoToModule] = useState({
        rows: [],
        typeUnitType: []
    });

    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetInfo();
        };
        getInfo();
    }, []);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    //Columnas de la tabla principal generica
    const colums = [
        {
            label: "Nombre",
            value: "name",
            type: "b"
        },
        {
            label: "Creado el",
            value: "createdAt",
            type: "date"
        },
        {
            label: "Tipo",
            value: "typeUnitTypeName",
            type: "a"
        }
    ]
    //Campos del modal generico para esta vista
    const modalInformation = [
        { label: "Tipo", value: "typeUnitTypeName", valueInsert: "typeUnitTypeId", type: "select", colWidth: "12", required: true, options: infoToModule.typeUnitType },
        { label: "Nombre", value: "name", type: "input", colWidth: "12", required: true },
        { label: "Obligatorio en Documento", value: "required", valueInsert: "required", type: "select", colWidth: "12", required: true, options: [{ label: "Sí", value: "1" }, { label: "No", value: "2" }] }
    ]
    //Json que genera los filtros que se utilizaran en la tabla
    const filter = [
        {
            label: "Nombre",
            type: "input",
            xs: "6",
            value: "name"
        },
        {
            label: "Tipo",
            type: "select",
            xs: "6",
            value: "typeUnitType",
            valueFilter: "typeUnitTypeName"
        }
    ]
    //Botones adicionales en la tabla principal
    const aditionalsButtons = [
        {
            color: "danger",
            function: (e) => handleOnDeleteItem(e),
            icon: "fas fa-trash",
            className: "table-action table-action-delete"
        },
        {
            color: "secondary",
            function: (e) => handleOnOpenModalEdit(e),
            icon: "fas fa-edit",
            className: "table-action table-action-edit"
        }
    ]
    //Funcion que trae la data de los campos selects
    const handleOnGetInfo = async () => {
        setLoad(true);
        dispatch(getOptions()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    area: payload.data.data.area,
                    businessUnit: payload.data.data.businessUnit,
                    category: payload.data.data.category,
                    profileType: payload.data.data.profileType,
                    state: payload.data.data.state,
                    typeActive: payload.data.data.typeActive,
                    typeUnitType: payload.data.data.typeUnitType,
                    unitType: payload.data.data.unitType,
                }));
            }
            setLoad(false);
        });
        handleOnGetTableInfo();
    };
    //Funcion que trae la data de la tabla principal
    const handleOnGetTableInfo = async () => {
        dispatch(getAllUnitType()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoToModule((prevState) => ({
                    ...prevState,
                    rows: payload.data.data[0]
                }));
            }
            setLoad(false);
        })
    }
    //Funcion para guardar los filtros de la tabla principal
    const handleOnfilterDinamic = (constant, e) => {
        setPage(1);
        constant === "typeUnitTypeName" && e === "Todos" ?
            setFiltersByTable("")
            :
            setFiltersByTable(prevState => ({
                ...prevState,
                [constant]: `${e}`
            }))
    }
    //Funcion para aplicar los filtros que se utilizaran
    const renderRowsByFilters = (rows) => {
        if (Object.keys(filterByTable).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filterByTable);
            for (const element of filterKeys) {
                const valueFiltered = filterByTable[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    //Funcion para abrir el modal de crear nueva registro en la tabla principal
    const handleOnOpenModalCreate = (constant) => {
        setOptionExtraModal([]);
        setModal(prevState => ({
            ...prevState,
            createModal: !openModal.createModal
        }))
    }
    //Funcion para abrir el modal de editar registro en la tabla principal
    const handleOnOpenModalEdit = (item) => {
        let array = [];
        if (item.options) {
            for (const index of item.options) {
                array.push(index["label"]);
            }
        }
        setOptionExtraModal(array);
        setModal(prevState => ({
            ...prevState,
            editModal: true
        }))
        setItemInfo(item)
    }
    const handleOnCloseModalEdit = (item) => {
        setOptionExtraModal(0);
        setModal(prevState => ({
            ...prevState,
            editModal: false
        }))
        setItemInfo([])
    }
    //Funcion para eliminar un elemento de la tabla principal
    const handleOnDeleteItem = (item) => {
        setLoad(true);
        dispatch(deleteUnitType(item.id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                Toast.fire({
                    title: "Actualizado",
                    html: "Se elimino correctamente",
                    type: "success",
                });
            }
            handleOnGetTableInfo();
            setLoad(false);
        })
    }
    //Funcion para crear nuevo registro en la tabla principal  
    const handleOnCreateItem = () => {
        setLoad(true);
        newInfo["options"] = optionsExtraModal
        dispatch(insertUnitType(newInfo)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setModal(prevState => ({
                    ...prevState,
                    createModal: false
                }));
                Toast.fire({
                    title: "Actualizado",
                    html: "Se Inserto correctamente",
                    type: "success",
                });
            }
            handleOnGetTableInfo();
            setLoad(false);
        })
    }
    //Funcion para editar un registro
    const handleOnUpdateItem = () => {
        setLoad(true);
        itemInfo["options"] = optionsExtraModal
        dispatch(updateUnitType(itemInfo)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setModal(prevState => ({
                    ...prevState,
                    editModal: false
                }));
                Toast.fire({
                    title: "Actualizado",
                    html: "Se Inserto correctamente",
                    type: "success",
                });
            }
            setAlert("");
            handleOnGetTableInfo();
            setLoad(false);
        })
    }
    //Mensaje de doble confirmacion para enviar la edicion de un elemento
    const validateSend = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={"¿Está seguro(a) que desea editar este tipo de dato?"}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={handleOnUpdateItem}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, guardar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        );
    }
    //Funcion para hacer los cambios de informacion en los campos de input, select .etc
    const handleOnChangeInfo = (constant, value) => {
      
        if(constant ==="typeUnitTypeId"){
            setOptionExtraModal([]);
        }
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };
    //Funcion para eliminar un elemento de combobox o table en el insertar
    const removeOptionsExtraModal = (index) => {
        delete itemInfo.options[index];
        setItemInfo((prevState) => ({
            ...prevState,
           options:  itemInfo.options
        }));
        optionsExtraModal.splice(index, 1);
        setOptionExtraModal(optionsExtraModal);
        setReLoad(optionsExtraModal.length + index);

    }
    //Funcion para insertar elemento de combobox o table en el insertar
    const insertOptionsExtraModal = () => {
        let newOption = optionsExtraModal;
        newOption.push("");
        setOptionExtraModal(newOption);
        reload === 1 ? setReLoad(9) :
            setReLoad(optionsExtraModal.length);
    }
    //Funcion para editar la informacion de elementos de combobox o talbe en el insertar
    const updateOptionExtraModal = (value, index) => {
        optionsExtraModal[index] = value;
        setReLoad(value);
    }
    //Funcion para cambiar la informacion que vamos a editar
    const handleOnChangeEditInfo = (constant, value) => {
        setItemInfo((prevState) => ({
            ...prevState,
            [constant]: !isNaN(value) || value.length >= 0 ? value : value.replace(/["']/g, ""),
        }));
    };
    //Elemento extra y unico de esta vista para agregar al elemento modal generico
    let extramodal = newInfo.typeUnitTypeId === 6 || itemInfo.typeUnitTypeId === 6 ?
        (<Card>
            <CardHeader>
                <Row className="align-items-center">
                    <Col xs="6">
                        {" "}
                        <div className="col">
                            <h3 className="mb-0">Elementos de Combobox</h3>
                        </div>
                    </Col>
                    <Col className="text-right" xs="6">
                        <Button
                            className="btn-neutral btn-round btn-icon"
                            color="default"
                            onClick={() => insertOptionsExtraModal()}
                            size="sm"
                        >
                            <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                            </span>
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {optionsExtraModal.map((item, index) => {
                    return (
                        <FormGroup key={"input" + index}>
                            <label className="form-control-label">
                                {" "}
                                <Button
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={() => removeOptionsExtraModal(index)}
                                >
                                    x
                                </Button>{"  "}
                                Valor {index + 1}:
                            </label>
                            <Input
                                className="form-control"
                                type="text"
                                defaultValue={itemInfo.options[index] && itemInfo.options[index].label}
                                onChange={(e) => {
                                    updateOptionExtraModal(e.target.value, Object.keys(optionsExtraModal)[index]);
                                }}
                            />
                        </FormGroup>
                    );
                })}
            </CardBody>
        </Card>)

        : newInfo.typeUnitTypeId === 5 || itemInfo.typeUnitTypeId === 5 ? (
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col xs="6">
                            {" "}
                            <div className="col">
                                <h3 className="mb-0">Tabla de datos</h3>
                            </div>
                        </Col>
                        <Col className="text-right" xs="6">
                            <Button
                                className="btn-neutral btn-round btn-icon"
                                color="default"
                                onClick={() => insertOptionsExtraModal()}
                                size="sm"
                                disabled={optionsExtraModal.length > 4}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-plus" />
                                </span>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {optionsExtraModal.map((option, index) => {
                        return (
                            <FormGroup key={"input" + index}>
                                <label className="form-control-label">
                                    {" "}
                                    <Button
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={() => removeOptionsExtraModal(index)}
                                    >
                                        X
                                    </Button>{" "}
                                    Columna {index + 1}:
                                </label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={itemInfo.options[index] && itemInfo.options[index].label}
                                    onChange={(e) => {
                                        updateOptionExtraModal(e.target.value, index);
                                    }}
                                />
                            </FormGroup>
                        );
                    })}
                    <div>
                        {" "}
                        <Table
                            className="align-items-center table-flush"
                            responsive
                        >
                            <thead className="thead-light">
                                <tr>
                                    {optionsExtraModal.map((option, index) => {
                                        return <th key={`th${index}`}>{option}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-user">
                                        <b></b>
                                    </td>
                                    <td>
                                        <span className="text-muted"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        ) : "";

    let disabled = !newInfo.typeUnitTypeId || !newInfo.required || !newInfo.name ? true : false;
    let disabledEdit = !itemInfo.typeUnitTypeId || !itemInfo.required || !itemInfo.name ? true : false;

    return (
        <div>
            {alert}
            <AdminHeader name="Configuración General" parentName="Finance Flows" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    <Filter
                        jsonFilters={filter}
                        filters={handleOnfilterDinamic}
                        optionsFilters={infoToModule}
                    />
                    <Tables
                        text={"Tipos de Datos"}
                        createButton={true}
                        textCreateButton={"Agregar"}
                        colums={colums}
                        rows={renderRowsByFilters(infoToModule.rows)}
                        page={page}
                        setPage={setPage}
                        handleOnNewElement={handleOnOpenModalCreate}
                        aditionalsButtons={aditionalsButtons}
                    />
                    <Modal
                        handleOnChangeInfo={handleOnChangeInfo}
                        text={"Crear nuevo Tipo"}
                        openModal={openModal.createModal}
                        handleOnOpenModal={handleOnOpenModalCreate}
                        itemModal={modalInformation}
                        itemInfo={itemInfo}
                        nameButton1={"Cerrar"}
                        nameButton2={"Guardar"}
                        saveButton={handleOnCreateItem}
                        saveButtonDisabled={disabled}
                        extramodal={extramodal}
                        size="sm"
                    />
                    <Modal
                        handleOnChangeInfo={handleOnChangeEditInfo}
                        text={"Editar"}
                        openModal={openModal.editModal}
                        handleOnOpenModal={handleOnCloseModalEdit}
                        itemModal={modalInformation}
                        itemInfo={itemInfo}
                        nameButton1={"Cerrar"}
                        nameButton2={"Guardar"}
                        saveButton={validateSend}
                        extramodal={extramodal}
                        size="sm"
                        saveButtonDisabled={disabledEdit}
                    />
                </Spin>
            </Container>
        </div>
    );
};


export default GeneralConfiguration;