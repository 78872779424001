/*
=========================================================
*Hours Report - CATS
=========================================================

*Description.
This is the component that shows CATS report details
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { FaClipboard, FaCalendarAlt, FaClock } from "react-icons/fa";
import { BiTask } from "react-icons/bi";

function ModalDetailsCats({ isOpen, handleClose, report }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
      id="Modal-Cats"
    >
      <ModalHeader toggle={handleClose}>
        <span
          className="font-weight-bold"
          style={{
            fontSize: "1.20em",
            marginTop: "0.80em",
            marginBottom: "0.80em",
          }}
        >
          Detalles del reporte CATS
        </span>
      </ModalHeader>

      <ModalBody id="Modal-Cats" className="Modal-Cats">
        <Row>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <FaClipboard
                  className="mr-2 mb-1"
                  style={{ fontSize: "14px" }}
                />
                ID Reporte:
              </Label>
              <Input type="text" value={report.ID_REPORTE} readOnly />
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <FaClipboard
                  className="mr-2 mb-1"
                  style={{ fontSize: "14px" }}
                />
                ID Documento:
              </Label>
              <Input type="text" value={report.ID_DOCUMENTO} readOnly />
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <FaCalendarAlt
                  className="mr-2 mb-1"
                  style={{ fontSize: "14px" }}
                />
                Fecha reportada:
              </Label>
              <Input type="text" value={report.FECHA} readOnly />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <FaCalendarAlt
                  className="mr-2 mb-1"
                  style={{ fontSize: "14px" }}
                />
                Fecha de Aprobación:
              </Label>
              <Input type="text" value={report.FECHA_APROBACION} readOnly />
            </FormGroup>
          </Col>{" "}
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <BiTask className="mr-2 mb-1" style={{ fontSize: "18px" }} />
                Tipo de Tarea:
              </Label>
              <Input type="text" value={report.TIPO_TAREA} readOnly />
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup className="mb-3">
              <Label className="font-weight-bold">
                <FaClock className="mr-2 mb-1" style={{ fontSize: "14px" }} />
                Horas reportadas:
              </Label>
              <Input
                type="text"
                value={`${report.HORA_INICIO.substring(
                  0,
                  5
                )} - ${report.HORA_FINAL.substring(0, 5)}`}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button
          style={{ backgroundColor: "#1275bc", color: "white" }}
          type="button"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ModalDetailsCats.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
};

export default ModalDetailsCats;
