// react library
import React, { useState, useEffect } from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {
    Button, Col, FormGroup, Input, Row, Modal,
    ModalHeader, InputGroupAddon,
    ModalBody, Table, InputGroup,
    ModalFooter, Form, Container, Label
} from "reactstrap";
//Token 
import { getUserProtected } from "selectors/adminLayout";
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { Typeahead } from 'react-bootstrap-typeahead';
//downloadFile
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
//actions for endpoint
import { reporting, deleteReportAction, saveReportAction } from 'actions/DocumentSystem.jsx';


const ReportingModal = ({
    showModal,
    setModal,
    documentType,
    estado,
    country,
    Toast,
    isAdmin,
    isDisplay,
    MySwal,
    dropDownOptions,
    reportingColumns,
    reports,
    setReports
}) => {
    const dispatch = useDispatch();

    useEffect(() => {

    }, [])



    const [loading, setLoad] = useState(false);

    const [selected, setSelected] = useState([]);

    //state que guarda la información de la solicitud para hacer el insert
    const [newInfo, setNewInfo] = useState({})

    //funcion para cerrar el modal
    const toggleNewDocumentModal = (() => {
        setLoad(false);
        setNewInfo({});
        setFields([]);
        setModal(prevState => !prevState);
    });

    //funcion para agregar PO extras del SV
    const [fields, setFields] = useState([]);

    //funcion para agregar nuevos campos de filtros
    const handleChangeInfo = (constant, e) => {
        //se actualiza el state de la nueva informacion
        const json = {
            label: constant,
            value: e,
            columnType: reportingColumns.find(obj => obj.value === e).columnType,
            isSelected: reportingColumns.find(obj => obj.value === e).is_foreign_key,
            referencedTable: reportingColumns.find(obj => obj.value === e).referenced_table
        }
        // console.log(json)
        setFields(current => [...current, json]);

    }

    //funcion para quitar campos a filtrar
    const handleDeleteField = (val) => {
        setFields((current) => current.filter((x) => x.value !== val));
        const updatedInfo = { ...newInfo }; // Create a copy of newInfo object
        delete updatedInfo[val]; // Delete the 'comment' key from the copied object
        setNewInfo(updatedInfo);
        
    }

    //funcion para agregar filtro 
    const handleOnChangeFilter = (lab, val) => {
        // console.log(val);
        let newLab = lab;
        if (lab.includes("Start") || lab.includes("End")) {
            newLab = (lab.includes("Start")) ? lab.split("Start")[0] : lab.split("End")[0];
        }
        setNewInfo((prevState) => ({
            ...prevState,
            [newLab]: (lab.includes("Start") || lab.includes("End")) ? { ...prevState[newLab], [lab]: val } : val,
        }))
    }

    //funcion para crear reporte
    const createReport = (campos, filters, reportName) => {
        setLoad(true);

        dispatch(reporting({ fields: campos, newInfo: filters })).then((resp) => {
            const { payload } = resp;
            // console.log(payload);
            if (payload.status === 200) {
                //Crear reporte de Excel con los parametros

                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Sheet1');

                const data = payload.data.rows;

                // Add custom column titles to the worksheet
                const headers = reportingColumns.map((column) => column.label);
                worksheet.addRow(headers);

                // Filter data based on column titles and add data rows to the worksheet
                data.forEach((item) => {
                    const row = reportingColumns.map((column) => item[column.value.split('.')[1]]);
                    worksheet.addRow(row);
                });

                // Format the columns with columnType as "timestamp"
                // reportingColumns.forEach((column, index) => {
                //     if (column.columnType === 'timestamp') {
                //         const columnIndex = index + 1; // Add 1 to adjust for 1-based indexing in Excel
                //         const dateColumn = worksheet.getColumn(columnIndex);
                //         dateColumn.eachCell({ includeEmpty: true }, (cell) => {
                //             if (cell.type === Workbook.CellType.Date) {
                //                 cell.numFmt = 'yyyy-mm-dd'; // Replace with your desired date format
                //             }
                //         });
                //     }
                // });
                // Generate and save the Excel file
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, reportName);
                });


            } else {

                Toast.fire({
                    title: "Atención",
                    html: "No se encontrarón registros con los filtros establecidos",
                    type: 'warning'
                });
            }
            setLoad(false);
        })
    }
    //funcion enviar una nueva solicitud
    const handleCreateReport = (e) => {

        createReport(fields, newInfo, `documentSystemReport${new Date().getTime()}`);

    }

    //funcion para guardar el reporte como favorito
    const handleSaveReport = (e) => {
        console.log(fields);
        console.log(newInfo);
        MySwal.fire({
            title: `Guardar Reporte`,
            html: '<input class="form-control" id="reportName" placeholder="Ingrese el nombre del reporte"></input>',
            confirmButtonText: "Si, guardar.",
            confirmButtonColor: "#2dce89",
            cancelButtonText: "No, cancelar",
            showCancelButton: true,
            preConfirm: () => {
                setLoad(true);
                if (document.getElementById("reportName").value === "") {
                    MySwal.showValidationMessage("El nombre es obligatoria");
                    setLoad(false);
                } else {
                    let reportName = document.getElementById("reportName").value.toString();
                    var info = {
                        filters: newInfo,
                        reportName: reportName
                    };
                    dispatch(saveReportAction({ info })).then((resp) => {
                        const { payload } = resp;
                        console.log(payload);
                        if (payload.status === 200) {
                            let id = payload.data.payload.id;
                            let jj = {
                                id: id,
                                reportName: reportName,
                                filters: JSON.stringify(newInfo)
                            }
                            setReports(current => [...current, jj]);
                            Toast.fire({
                                title: "Atención",
                                html: "Se creo con éxito",
                                type: "success",
                            });
                        } else {
                            Toast.fire({
                                title: "Atención",
                                html: payload.response.data.payload.message,
                                type: "warning",
                            });
                        }
                        setLoad(false);
                    });
                }
            },
        });
    }

    const handleDownloadReport = (e, item) => {
        createReport([], JSON.parse(item.filters), item.reportName);
    }

    const handleDeleteReport = (e, item) => {
        setLoad(true);
        MySwal.fire({
            title: `Desea eliminar el reporte ${item["reportName"]}?`,
            // text: "Se puede reversar con ayuda de Application Management",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar.",
            confirmButtonColor: "#f5365c",
            cancelButtonText: "No, cancelar",
        }).then((result) => {
            if (result.value) {
                dispatch(deleteReportAction({ info: item })).then((resp) => {
                    const { payload } = resp;
                    console.log(payload);
                    if (payload.status === 200) {
                        setReports((current) => current.filter((x) => x.id !== item.id));
                    } else {

                        Toast.fire({
                            title: "Atención",
                            html: payload.response.data.message,
                            type: 'warning'
                        });
                    }
                    setLoad(false);
                })
            }
        });

    }


    return (
        <Modal size="lg" isOpen={showModal} toggle={toggleNewDocumentModal}>
            <ModalHeader>Document System Reporting</ModalHeader>
            <ModalBody>
                <Spin size="large" spinning={loading}>
                    <Form noValidate className="formInfoContacts needs-validation formInfo">
                        <Row className="align-items-center">
                            <Col xs="6">
                                <FormGroup>
                                    <label className="form-control-label">Campo a filtrar</label>
                                    <Select
                                        required={true}
                                        className="basic-single"
                                        id="columns"
                                        type="select"
                                        placeholder={"Seleccione las columnas que desea agregar al reporte"}
                                        isSearchable
                                        options={reportingColumns}
                                        // value={documentType.label}
                                        onChange={(e) => handleChangeInfo(e.label, e.value)}
                                    />

                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                {
                                    reports.length > 0 &&
                                    <Table
                                        className="align-items-center table-flush"
                                        responsive
                                        striped={true}
                                        hover={true}
                                    // style={{ marginTop: "7px" }}
                                    >
                                        <tbody className="list">

                                            {
                                                reports.map((item, key) => (
                                                    <>

                                                        <tr key={key}>
                                                            <td>
                                                                <a href="#" onClick={(e) => handleDownloadReport(e, item)}>{item.reportName}</a>
                                                            </td>

                                                            <td>
                                                                <a onClick={(e) => handleDeleteReport(e, item)} >
                                                                    <span className="">
                                                                        <i className="fas fa-trash-alt" />
                                                                    </span>
                                                                </a>
                                                            </td>


                                                        </tr>
                                                    </>

                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                }

                            </Col>
                        </Row>
                        {/* <p className="text-center">Filtros</p> */}
                        <hr />
                        <Row className="align-items-center">
                            {
                                fields.map((row, key) => (
                                    <Col key={key} sm="4">
                                        <FormGroup>
                                            <label className="form-control-label">{row.label}</label>

                                            {
                                                row.isSelected === 0 ?
                                                    row.columnType === "timestamp" ?
                                                        <>
                                                            <Input
                                                                id={row.value + "Start"}
                                                                className="form-control"
                                                                style={{ margin: 0 }}
                                                                type={"date"}
                                                                onChange={(e) => handleOnChangeFilter(row.value + "Start", e.target.value)}
                                                            />
                                                            <Input
                                                                id={row.value + "End"}
                                                                className="form-control"
                                                                style={{ margin: 0 }}
                                                                type={"date"}
                                                                onChange={(e) => handleOnChangeFilter(row.value + "End", e.target.value)}
                                                            />
                                                        </>
                                                        :
                                                        <Input
                                                            id={row.value}
                                                            className="form-control mr-4"
                                                            style={{ margin: 0 }}
                                                            type={"text"}
                                                            onChange={(e) => handleOnChangeFilter(row.value, e.target.value)}
                                                        />
                                                    :
                                                    <Select
                                                        isMulti={true}
                                                        id={row.value}
                                                        name={row.value}
                                                        className="basic-single text-dark"
                                                        type="Select"

                                                        isSearchable
                                                        onChange={(e) => handleOnChangeFilter(row.value, e)}
                                                        options={dropDownOptions[row.referencedTable] && [{ value: "", label: "Seleccione una opción", }, ...dropDownOptions[row.referencedTable]]}
                                                    />
                                            }
                                            <Button className="btn-icon" color="danger" size="sm" block type="button"
                                                onClick={() => handleDeleteField(row.value)}
                                            >
                                                <span className="btn-inner--icon">
                                                    <i className="fas fa-trash" />
                                                </span>
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Form>
                </Spin>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    type="button"
                    onClick={(e) => toggleNewDocumentModal()}
                >
                    Cancelar
                </Button>
                <Button color="info" onClick={(e) => handleSaveReport(e)} >Guardar</Button>
                <Button color="success" onClick={(e) => handleCreateReport(e)} >Crear</Button>

            </ModalFooter>
        </Modal >
    )
};

export default ReportingModal;