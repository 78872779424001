/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Software Adicional en la solicitud
=========================================================

* Coded by Sergio Marin  

*/

import React, { useState, useEffect } from "react";
import { Card, CardTitle, Label, Col, Row, Input, FormGroup } from "reactstrap";
import MultiSelect from 'react-select';

const AdditionalSoftware = ({ isVisible, data, optionsJson, setData, disabledFieldsJson }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif', height: '1.5em', fontSize: '1.25em', textAlign: 'center' };
    const groupStyle = { marginBottom: '0.1rem', fontFamily: 'sans-serif' };
    const labelStyle = { fontSize: "1.25em", height: '1.0em', fontFamily: 'sans-serif' };

    //FUNCIONES
    const [softwareOption, setSoftwareOption] = useState([]);
    const [selectedGroups, setSelected] = useState([]);

    useEffect(() => {
        let parsedSoftwareOptions = [];
        optionsJson.additionalSoftware.map((softwareJson) => {
            parsedSoftwareOptions.push({ label: softwareJson.value, value: softwareJson.id });
        });
        setSoftwareOption(parsedSoftwareOptions);

        //las opciones seleccionadas
        let selectedArray = [];
        data.additionalSoftware && data.additionalSoftware.map((additionalSoftware) => {
            const selectedJson = optionsJson.additionalSoftware.find(item => item.id === Number(additionalSoftware));
            selectedArray.push({ label: selectedJson.value, value: selectedJson.id });
        });
        setSelected(selectedArray);

    }, []);

    //Cargar los additional software a data
    useEffect(() => {
        setData((prevState) => ({ ...prevState, additionalSoftware: selectedGroups ? selectedGroups.map(group => group.value.toString()) : [] }));
    }, [selectedGroups]);

    const handleChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };

    const handleObservationsChange = (id, e) => {
        e.persist();
        setData(prevState => ({ ...prevState, [id]: e.target.value }));
    }

    //RETURN
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 0px' }}>
                    <CardTitle tag="h5" style={{ ...titleStyle }}>
                        Software Adicional
                    </CardTitle>
                    <Row style={{ padding: '0px 30px 0px' }}>
                        <Col xs={4}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label tag="h5" htmlFor="softwareSelect" style={labelStyle}>
                                    Software Adicional
                                </Label>
                                <MultiSelect
                                    closeMenuOnSelect={false}
                                    id="softwareSelect"
                                    isMulti
                                    isDisabled={(disabledFieldsJson && disabledFieldsJson.includes("AdditionalSoftware"))}
                                    options={softwareOption}
                                    value={selectedGroups}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs="8">
                            <h5 style={{ fontSize: "1.25em" }}>Observaciones</h5>
                            <div style={{ padding: '0px 15px 0px' }}>
                                <FormGroup>
                                    <Input type="textarea"
                                        id={"additionalSoftwareComments"}
                                        value={data.additionalSoftwareComments}
                                        disabled={(disabledFieldsJson && disabledFieldsJson.includes("AdditionalSoftware"))}
                                        onChange={(e) => handleObservationsChange("additionalSoftwareComments", e)}
                                        maxLength="500"
                                    />
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );
}

export default AdditionalSoftware;
