import { getAllCompetitions, getDataCompetitionReport } from 'actions/panamaBids.jsx';
import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import Select from "react-select";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import Exportexcel from "react-export-excel";

const CompetitionFilter = ({ rows, contInput, setContInput, setrefreshInput, dataSelect, setRowsModal, Toast, dataRows, setLoading }) => {

    const excelReport = [
        { label: "Registro unico de pedido", value: "singleOrderRecord" },
        { label: "Convenio", value: "agreement" },
        { label: "Proveedor", value: "vendor" },
        { label: "Fecha Desde", value: "dFrom" },
        { label: "Fecha Hasta", value: "dTo" },
        { label: "Entidad", value: "entity" },
        { label: "Fecha de registro", value: "rDate" },
        { label: "Fecha de publicación", value: "pDate" },
        { label: "Reglón", value: "product" },
        { label: "Producto/Servicio", value: "productDescription" },
        { label: "Cantidad", value: "amount" },
        { label: "Precio Unitario", value: "unitPrice" },
        { label: "Total", value: "total" },
        { label: "Sub total de orden", value: "subtotal" },
        { label: "Línea de Producto", value: "productLine" },
        { label: "Tipo de Producto", value: "typeProduct" },
        { label: "GBM Participa", value: "gbmParticipate" },
        { label: "Link del documento", value: "documentLink" }
    ]
    const ExcelFile = Exportexcel.ExcelFile;
    const ExcelSheet = Exportexcel.ExcelSheet;
    const ExcelColumn = Exportexcel.ExcelColumn;

    const dispatch = useDispatch();
    const [dataFilter, setDataFilter] = useState([]);
    const [dataSelectFiler, setDataSelectFiler] = useState([]);
    const [conditionFilter, setConditionFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState([]);
    const [typeDate, setTypeDate] = useState("");
    const handleOnPlusInput = () => {
        let amount = contInput;
        amount.push(2);
        setContInput(amount);
        setrefreshInput(`num${amount}`);
    };
    const handleOnMinusInput = () => {
        let amount = contInput;
        amount.pop(1);
        setContInput(amount);
        setrefreshInput(`num${amount}`);
    };
    const handleChangeInfo = (constant, e) => {
        setDataFilter((prevState) => ({
            ...prevState,
            [constant]: e
        }))
    }
    const handleChangeInfoSelects = (constant, e) => {
        setDataSelectFiler((prevState) => ({
            ...prevState,
            [constant]: { id: e.id, value: e.value }
        }))
    }
    const handleChangeInfoCondition = (constant, e) => {
        setConditionFilter((prevState) => ({
            ...prevState,
            [constant]: e
        }))
    }
    const handleChangeInfoDate = (constant, e) => {
        setDateFilter((prevState) => ({
            ...prevState,
            [constant]: e
        }));
    }
    const cleanFilters = () => {
        setDataFilter([]);
        setDataSelectFiler([]);
        setConditionFilter([]);
        setDateFilter([]);
        setContInput([1]);
        setrefreshInput(5);
        dataRows();
        setTypeDate([]);
        //DateEnd
    }
    const handleOnTypeDate = (e) => {
        setTypeDate(e);
    }
    const getDataTable = () => {
        setLoading(true);
        const date = typeDate.value
        dispatch(getAllCompetitions({ data: dataFilter, selects: dataSelectFiler, condition: conditionFilter, dates: dateFilter, typeDate: date })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    rows: payload.data.payload.data
                    // rowsExcel: payload.data.payload.data
                }))
            } else if (resp.payload.response.data.status === 404) {
                setRowsModal(prevState => ({
                    ...prevState,
                    rows: []
                }))
                Toast.fire({
                    title: "Atención:",
                    html: "La información no se encontro",
                    type: 'warning'
                });
            } else {
                Toast.fire({
                    title: "Atención:",
                    html: "Debe completar todos los campos",
                    type: 'warning'
                });
            }
            setLoading(false);
        })
    }
    useEffect(() => {
        const getRowsData = async () => {
            await handleOnDownloadExcel();
        }
        getRowsData();
    }, [rows.rows], [])

    const handleOnDownloadExcel = async () => {
        const date = typeDate.value
        dispatch(getDataCompetitionReport({ data: dataFilter, selects: dataSelectFiler, condition: conditionFilter, dates: dateFilter, typeDate: date })).then((resp) => {
            console.log(resp);
            const { payload } = resp;
            if (payload.status === 200) {
                const data = payload.data.payload.data
                console.log(data)
                setRowsModal(prevState => ({
                    ...prevState,
                    rowsExcel: data
                }))
            }
            else if (resp.payload.response.data.status === 404) {
                Toast.fire({
                    title: "Atención:",
                    html: "La información no se encontro",
                    type: 'warning'
                });
            }
        })
    }
    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm="12" md="8">
                            <h3 className="mb-0">Busqueda Avanzada</h3>
                            <p className="text-sm mb-0">
                                Busqueda personalizada en los registros de la tabla inferior
                            </p>
                        </Col>
                        <Col xs="12" md="1" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Button
                                className="btn-icon"
                                color="info"
                                size="sm"
                                block
                                type="button"
                                onClick={() => getDataTable()}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i class="fas fa-search"></i>
                                </span>
                                <span className="btn-inner--text">Buscar</span>
                            </Button>
                        </Col>
                        <Col xs="12" md="2" className="p-1">
                            <ExcelFile element={<Button
                                className="btn btn-icon"
                                color="success"
                                block
                                type="button"
                            >
                                <span className="btn-inner--icon mr-">
                                    <i class="fas fa-download"></i>
                                </span>
                                <span className="btn-inner--text">Descargar</span>
                            </Button>} filename="Reporte Convenio Competencia">

                                <ExcelSheet data={rows.rowsExcel} name="Reporte convenio competencia">
                                    {excelReport.map((col, key) => (
                                        <ExcelColumn key={key} label={col.label} value={col.value} />
                                    ))}
                                </ExcelSheet>
                            </ExcelFile>
                        </Col>
                        <Col xs="12"md="1" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Button
                                className="btn-icon"
                                color="danger"
                                size="sm"
                                block
                                type="button"
                                onClick={() => cleanFilters()}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-trash" />
                                </span>
                                <span className="btn-inner--text">Limpiar</span>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className="d-flex justify-content-center align-items-center">
                        {
                            contInput.map((item, key) => (
                                <>
                                    {
                                        item === 1 ?
                                            <>
                                                <Col xs="12" md="4">
                                                    <FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="startDate">
                                                        </label>
                                                        <Input
                                                            key={key}
                                                            className="m-0"
                                                            type="select"
                                                            value={dataSelectFiler[`Select${key}`] ? (dataSelectFiler[`Select${key}`].value) : ""}
                                                            onChange={(e) => handleChangeInfoSelects(`Select${key}`, e.target.children[e.target.selectedIndex])}
                                                        >
                                                            {dataSelect.map((value, key) => {
                                                                return (
                                                                    <option key={key} id={value.Field}>{`${value.Comment}`}</option>
                                                                );
                                                            })
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6">
                                                    <FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="startDate">
                                                        </label>
                                                        <Input
                                                            value={dataFilter[`Text${key}`] || ""}
                                                            key={key}
                                                            className="m-0"
                                                            onChange={(e) => handleChangeInfo(`Text${key}`, e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="1" className=" d-flex justify-content-end">
                                                    <Button
                                                        className="btn ml-5 "
                                                        color="success"
                                                        key={key}
                                                        disabled={contInput.length === 4 ? true : false}
                                                        onClick={() => handleOnPlusInput()}
                                                    >
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-plus"></i>
                                                        </span>
                                                    </Button>
                                                </Col>
                                                <Col xs="12" md="1">
                                                    <Button
                                                        className="btn mr-5"
                                                        color="danger"
                                                        key={key}
                                                        disabled={contInput.length === 1 ? true : false}
                                                        onClick={() => handleOnMinusInput()}
                                                    >
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-minus"></i>
                                                        </span>
                                                    </Button>
                                                </Col>
                                            </>
                                            : item === 2 &&
                                            <>
                                                <Col xs="12" md="4">
                                                    <FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="startDate">
                                                        </label>
                                                        <Input
                                                            key={key}
                                                            className="m-0"
                                                            type="select"
                                                            value={dataSelectFiler[`Select${key}`] ? (dataSelectFiler[`Select${key}`].value) : ""}
                                                            onChange={(e) => handleChangeInfoSelects(`Select${key}`, e.target.children[e.target.selectedIndex])}
                                                        >
                                                            {dataSelect.map((value, key) => {
                                                                return (
                                                                    <option key={key} id={value.Field}>{`${value.Comment}`}</option>
                                                                );
                                                            })
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6">
                                                    <FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="startDate">
                                                        </label>
                                                        <Input
                                                            value={dataFilter[`Text${key}`] || ""}
                                                            key={key}
                                                            className="m-0"
                                                            onChange={(e) => handleChangeInfo(`Text${key}`, e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="2">
                                                    <FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="startDate">
                                                        </label>
                                                        <Select
                                                            key={key}
                                                            className="basic-single"
                                                            type="select"
                                                            isSearchable
                                                            value={{ label: conditionFilter[`condition${key}`], value: 1 } || ""}
                                                            options={[{ label: "AND", value: 1 }, { label: "OR", value: 2 }]}
                                                            onChange={(e) => handleChangeInfoCondition(`condition${key}`, e.label)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                    }
                                </>
                            ))
                        }

                    </Row>
                    <Row>
                        <Col className="text-left" xs="12" md="4">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="startDate">
                                    {"Tipo de Fecha:"}
                                </label>
                                <Select
                                    className="basic-single"
                                    type="select"
                                    isSearchable
                                    value={{ label: typeDate.label, value: 1 } || ""}
                                    options={[{ label: "Fecha Registro", value: "registrationDate" }, { label: "Fecha Publicación", value: "publicationDate" }]}
                                    onChange={(e) => handleOnTypeDate(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="text-left" xs="12" md="4">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="startDate">
                                    Fecha Inicio:
                                </label>
                                <Input
                                    className="m-0"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    type="date"
                                    value={dateFilter["DateStart"] || ""}
                                    onChange={(e) => handleChangeInfoDate("DateStart", e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="text-left" xs="12" md="4">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="endDate">
                                    Fecha Final:
                                </label>
                                <Input
                                    className="m-0"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    value={dateFilter["DateEnd"] || ""}
                                    type="date"
                                    onChange={(e) => handleChangeInfoDate("DateEnd", e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};


export default CompetitionFilter;