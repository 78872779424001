import React from 'react';
// react library for routing
import { Redirect, Route, Switch } from "react-router-dom";
// routes
import { ExitInterviewRoutes } from "routes/routes.jsx";

const Index = () => {
    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin/exit-Interview") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            return null;
        });
    };
    return (
        <>
            <Switch>
                {
                    getRoutes(ExitInterviewRoutes)
                }
                <Redirect from="/admin/exit-Interview" to="/admin/exit-Interview" />
            </Switch>
            <p className="lead"></p>
        </>
    );
};

export default Index; 