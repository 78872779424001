import React, { useState } from "react";
import { Card, CardBody, Col, FormGroup, Input, Row, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { urlBase } from "api/urls.jsx";
import { encrypter } from 'helpers/desencrypt.jsx';

const AgreementEditFormThree = (
  { dataModal, handleChangeInfo, files, isDisplay, isDisplayBF, Toast }
) => {
  const [rowsModal, setRowsModal] = useState([
    {
      colWidth: "6",
      label: "Provincia",
      id: "state",
      type: "text",
      disabled: true,
    },
    {
      colWidth: "6",
      label: "Lugar de Entrega",
      id: "deliveryLocation",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Contacto de entrega",
      id: "deliveryContact",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Telefono",
      id: "phone",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Email",
      id: "email",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Sector",
      id: "sector",
      type: "text",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Unidad solicitante",
      id: "requestingUnit",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Contacto de Cuenta",
      id: "accountContact",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Email de cuenta",
      id: "emailAccount",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Comentario",
      id: "comment",
      type: "text",
      disabled: false,
    },
  ]);
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 3,
    idFileRequest: "",
  })
  const { page, sizePerPage } = state;

  const paginations = files.length > sizePerPage ?
    files.length / sizePerPage > Math.round(files.length / sizePerPage) ?
      Math.round(files.length / sizePerPage) + 1
      : Math.round(files.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink href="#"
            onClick={(e) => handleOnSetPage(e, i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  //Cambiar de pagina
  const handleOnSetPage = (event, page, who) => {

    setState((prevState) => ({
      ...prevState,
      [who]: page
    }))
  };

  const handleDownloadAllFiles = (e, singleOrderRecord) => {

    const downloadUrl = `${urlBase}/panama-bids/download-all-files/${encrypter(singleOrderRecord)}/${"agreement"}`;

    fetch(downloadUrl, {
      method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
    })
      .then(response => {
        if (response.ok) {
          window.location.assign(downloadUrl);
        } else {
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
            type: "warning",

          });
        }
      })
      .catch(error => {
        console.error('Error checking file existence:', error);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error al validar el archivo ",
          type: "warning",

        });
      });
  }
  const handleDownloadFile = (e, singleOrderRecord, files) => {

    const downloadUrl = `${urlBase}/panama-bids/download-file/${encrypter(singleOrderRecord)}/${encrypter(files)}/${"agreement"}`;

    fetch(downloadUrl, {
      method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
    })
      .then(response => {
        if (response.ok) {
          window.location.assign(downloadUrl);
        } else {
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
            type: "warning",

          });
        }
      })
      .catch(error => {
        console.error('Error checking file existence:', error);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error al validar el archivo ",
          type: "warning",

        });
      });
  }
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            {rowsModal.map((row, key) =>
              row.type === "text" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      className="form-control"
                      type={row.type}
                      disabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                      value={dataModal[row.id] || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : row.type === "select" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      type={row.type}
                      disabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                      className="basic-multi-select text-dark"
                    ></Input>
                  </FormGroup>
                </Col>
              ) : (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      type={row.type}
                      disabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                      className="basic-multi-select text-dark"
                    ></Input>
                  </FormGroup>
                </Col>
              )
            )}
          </Row>
        </CardBody>
        <>
          {
            <>
              <a
                disabled={files[0] && (files[0].files === null ? true : false)}
                title="Descargar todos los archivos"
                id="downloadAllFiles"

                onClick={(e) => handleDownloadAllFiles(e, dataModal.singleOrderRecord)}
                style={{ marginRight: "5px" }}
              >
                <span className="btn btn-primary" >
                  <i className="fas fa-download" />
                </span>
              </a>
            </>
          }
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
            style={{ marginTop: "7px" }}
          >
            <thead className="thead-light">
            </thead>
            <tbody className="list">
              {
                files.length ?
                  files.slice((page - 1) * sizePerPage, page * sizePerPage).map((file, key2) => (
                    <tr key={key2}>
                      <td>
                        <a

                          onClick={(e) => handleDownloadFile(e, dataModal.singleOrderRecord, file.files)}
                        >
                          {file.files}
                        </a>
                      </td>
                    </tr>
                  ))
                  :
                  <>
                    <tr>
                      <td className="justify-content-md-center">
                        No tiene documentos asociados
                      </td>
                    </tr>
                  </>
              }
            </tbody>
          </Table>

          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs='12' md='12' className="p-1">
                <Pagination
                  style={{ marginLeft: "30px" }}
                  className="pagination "
                  listClassName=" mb-0"
                >
                  {
                    renderPaginations()
                  }
                </Pagination>
              </Col>
            </Row>
          </nav>
        </>
      </Card>
    </div>
  );
};



export default AgreementEditFormThree;