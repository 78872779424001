// antd components
import { Skeleton } from 'antd';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardImg, CardTitle } from "reactstrap";

const CardLoading = props => {

  const {
    title
  } = props;

  return (
    <Card>
      <div className="d-flex align-items-center justify-content-center">
        <CardImg
          alt="Cargando"
          src={require("assets/img/gif/loading-animation2.gif")}
          top
          style={{ width: '256px' }}
        />
      </div>
      <CardBody>
        <div className="d-flex align-items-center justify-content-center">
          <CardTitle
            tag="h3"
          >
            {title}
          </CardTitle>
        </div>
        <Skeleton active avatar />
      </CardBody>
    </Card>
  );
};

export default CardLoading;
