import React, { Component } from "react";
// reactstrap components
import { CardBody, CardTitle, Row, Col, Card } from "reactstrap";
// core components
import { PropTypes } from "prop-types";

class WidgetsProjects extends Component {
  render() {
    const { selectData, colors, count } = this.props;
    return (
      <>
        <Row>
          <Col md="6" xl="3">
            <Card
              className="card-stats card-lift--hover"
              onClick={e => selectData("AM")}
              style={{ backgroundColor: colors[0], cursor: "pointer" }}
            >
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Application Management
                    </CardTitle>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i className="ni ni-mobile-button" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-success mr-2">
                    <i className="fa fa-calendar" /> {count.AM}
                  </span>{" "}
                  <span className="text-nowrap">proyectos en proceso</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card
              className="card-stats card-lift--hover"
              onClick={e => selectData("BI")}
              style={{ backgroundColor: colors[1], cursor: "pointer" }}
            >
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Business Intelligence
                    </CardTitle>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i className="ni ni-chart-bar-32" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-danger mr-2">
                    <i className="fa fa-calendar" /> {count.BI}
                  </span>{" "}
                  <span className="text-nowrap">proyectos en proceso</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card
              className="card-stats card-lift--hover"
              onClick={e => selectData("DM")}
              style={{ backgroundColor: colors[2], cursor: "pointer" }}
            >
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Data Management {"&"} Automation
                    </CardTitle>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-yellow text-white mobile-button shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-warning mr-2">
                    <i className="fa fa-calendar" /> {count.DM}
                  </span>{" "}
                  <span className="text-nowrap">proyectos en proceso</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card
              className="card-stats card-lift--hover"
              onClick={e => selectData("IF")}
              style={{ backgroundColor: colors[3], cursor: "pointer" }}
            >
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Infraestructura
                    </CardTitle>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                      <i className="ni ni-world-2" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-primary mr-2">
                    <i className="fa fa-calendar" /> {count.Inf}
                  </span>{" "}
                  <span className="text-nowrap">proyectos en proceso</span>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

WidgetsProjects.propTypes = {
  selectData: PropTypes.func.isRequired,
  colors: PropTypes.array.isRequired,
  count: PropTypes.object.isRequired
};

export default WidgetsProjects;
