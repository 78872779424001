/*!
=========================================================
*Databot Main Table
=========================================================

* Componente para desplegar el status de los mandantes del databot.
=========================================================

* Coded by Diego Meza Castro & Eduardo Piedra Sanabria - Application Management GBM

*/

//Componentes de la líbrería de react.
import React, { useState } from 'react';

//Componentes de la líbrería de reactstrap.
import { Badge, Card, CardBody, Col, PaginationItem, PaginationLink, Row, Table, Button } from "reactstrap";

const MandTable = ({
    colSelected,
    rows,
    page,
    setPage,
    setRefreshInput,
}) => {

    //#region Pagination Table
    const [state, setState] = useState({
        sizePerPage: 10
    });
    const { sizePerPage } = state;

    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1
    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }
    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);
    };
    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            sizePerPage: value
        }))
    }
    //#endregion

    //Función para refrescar la tabla
    const handleRefresh = (e) => {
        setPage(1);
        setRefreshInput(prevState => (!prevState))
    }

    return (
        <div>
            <Card>

                <CardBody>
                    <Row className="d-flex align-items-center mb-2">
                        <Col xs="12" md='10'>
                            <h6 className="surtitle">Databot Orchestrator</h6>
                            <h5 className="h3 mb-0">Mandantes de SAP</h5>
                        </Col>
    


                        <Col xs="12" sm='' style={{ display: "flex", justifyContent: "flex-end" }}>


                            <Button
                                className="btn-icon"
                                color="info"
                                size="sm"
                                type="button"
                                onClick={(e) => handleRefresh(e)}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-sync-alt" />
                                </span>
                                <span className="btn-inner--text">
                                    {" "}Refrescar
                                </span>
                            </Button>
                        </Col>

                    </Row>
                    <Table
                        style={{ whiteSpace: "normal", textAlign: "auto" }}
                        className="align-items-center table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">
                            <tr>
                                {colSelected.map((item, key) => {
                                    return (
                                        item.value !== "action" ?
                                            <th key={key} className="text-center ">
                                                {item.label}
                                            </th>
                                            :
                                            <th></th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="list">
                            <>
                                {rows &&
                                    rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                                        <tr key={key} className="justify-content-md-center">
                                            {colSelected.map((col, key) => (
                                                <td key={key} className="text-center text-break " style={{ whiteSpace: "normal", textAlign: "start" }}>
                                                    {
                                                        col.value === "action" ?
                                                            <label className="custom-toggle custom-toggle-success">
                                                                <input className='mb-2' type="checkbox" checked={(row["active"] === 1) ? true : false} onChange={(e) => col.funcion(e, row)} />
                                                                <span className="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On"></span>
                                                            </label>
                                                            : col.value === "activeText" ?
                                                                <Badge color={(row["active"] === 1) ? "danger" : "success"} pill>
                                                                    {row[col.value]}
                                                                </Badge>
                                                                :
                                                                row[col.value]
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                            </>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    );
};

export default MandTable;