/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to see the list of preapprovers and select one to be assigned to an extra hours request
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import { Typeahead } from "react-bootstrap-typeahead";
import { Spin } from "antd";

function ModalVerifyApprover({
  isOpen,
  handleClose,
  employees,
  onChange,
  buttonOnClick,
  title,
  subtitle,
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      size="xl"
      style={{ maxWidth: "1500px", width: "100%" }}
    >
      <ModalHeader toggle={handleClose}>
        <span className="font-weight-bold" style={{ fontSize: "1.20em" }}>
          Reporte de horas extra
        </span>
      </ModalHeader>

      <ModalBody>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h3 className="mb-0">{title}</h3>
                <p className="text-sm mb-0">{subtitle}</p>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className=" align-items-center">
              <Col xs={12} sm={4}>
                <FormGroup>
                  <Label className="font-weight-bold">
                    Selecciona tu preaprobador
                  </Label>
                  <Spin size="small" spinning={false}>
                    <Typeahead
                      id="typeahead-approvers"
                      labelKey={(employees) => `${employees.user}`}
                      minLength={0}
                      required={true}
                      options={employees.filter(
                        (employee, employeeKey) => employeeKey != 0
                      )}
                      defaultInputValue={""}
                      onChange={(e) => onChange(e)}
                      renderMenuItemChildren={(value) => (
                        <div>
                          {value.name}
                          <div>{<small>User: {value.user}</small>}</div>
                        </div>
                      )}
                    />
                  </Spin>
                </FormGroup>
              </Col>

              <Col
                xs={12}
                sm={2}
                style={{
                  display: "flex",
                  gap: "3rem",
                }}
              >
                <Button
                  className="btn-icon"
                  color="primary"
                  size="sm"
                  type="button"
                  style={{
                    height: "2rem",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    justifyContent: "center",
                    width: "6rem",
                    marginTop: "1rem",
                  }}
                  onClick={buttonOnClick}
                >
                  <span className="btn-inner--text">Asignar</span>
                </Button>

                <Button
                  className="btn-icon"
                  color="danger"
                  size="sm"
                  type="button"
                  style={{
                    height: "2rem",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    justifyContent: "center",
                    width: "6rem",
                    marginTop: "1rem",
                  }}
                  onClick={handleClose}
                >
                  <span className="btn-inner--text">Cancelar</span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button
          style={{ backgroundColor: "#1275bc", color: "white" }}
          type="button"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalVerifyApprover;
