/*!

=========================================================
*Expand Material
=========================================================

*Componente para ampliar material a WMS
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

//Import de react
import React, { useState, useContext } from "react";

// Reactstrap Components
import {
    InputGroupAddon,
    CardHeader,
    InputGroup,
    FormGroup,
    CardBody,
    Label,
    Button,
    Input,
    Card,
    Row,
    Col,
} from "reactstrap";

//Componente para estados globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de actions de consultas al API.
import {
    expandMaterials
} from 'actions/wms';

import { useDispatch } from 'react-redux';

import { MdComputer } from "react-icons/md";

const ExpandMaterial = ({ handleOnBack }) => {

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isMobile,
        isComputer,
        notify,
        MySwal,
        setLoad
    } = useContext(UserContext);
    //#endregion

    //El material
    const [material, setMaterial] = useState("");

    const [idComponent, setidComponent] = useState("expandMaterial")

    //Lista de materiales
    const [materialList, setMaterialList] = useState([]);

    //Función para gestionar el material.
    const handleChange = (val) => {
        setMaterial(val)
    }

    //Función para agregar materiales.
    const handleAddMaterial = () => {


        setMaterial(prev => {
            if (prev === "") {
                notify("warning", "Alerta", "Ingrese al menos un material.")
                return ""
            };
            const data = {
                MATERIAL: prev
            }
            setMaterialList(prevSet => {
                return [...prevSet, data];
            })
            return "";
        })
    }

    //Función para eliminar los materiales.
    const handleDeleteMaterial = (e, index) => {
        setMaterialList(prevArray => prevArray.filter((item, i) => i !== index));
    }

    //Función para confirmar la petición.
    const handleConfirm = () => {
        if (materialList.length === 0) {
            notify("warning", "Alerta", "Ingrese al menos un material.")
            return;
        }

        let htmlMatTable = `<${isComputer ? 'h1' : 'h2'} class="mb-3">Aviso</${isComputer ? 'h1' : 'h2'}>
        Materiales por ampliar:
        <ul style="text-align: left;">`;

        materialList.map((item, index) => (
            htmlMatTable += `<li key=${index}>${item.MATERIAL}</li>`
        ));

        htmlMatTable += "  </ul>"

        MySwal.fire({
            // title: `Aviso`,
            html: `${htmlMatTable}`,
            icon: "warning",
            showCancelButton: true,
            // width: 1000,
            confirmButtonText: `Si, confirmar`,
            confirmButtonColor: "#2dce89",
            cancelButtonText: "No, cancelar",
            cancelButtonColor: "#adb5bd",
            buttonsStyling: isComputer,
            customClass: {
                confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
            },
            className: "p-0",
            style: {
                overflow: 'unset',
                padding: '0px'
            }
        }).then((result) => {
            if (result.value) {

                var data = {
                    MATERIALES: { item: materialList },
                };
                executeMaterials(data)
            }
        });
    }

    //Función para ampliar los materiales.
    const executeMaterials = (data) => {
        setLoad(prev => !prev);
        dispatch(expandMaterials(data)).then((resp) => {
            const { payload } = resp;
            if (resp.payload.isAxiosError) {

                notify("danger", "Alerta", "Error al ejecutar, comuniquese con Application Management.");
            }
            else {
                let response = payload.data.payload.response;
                console.log(response);
                // let response = payload.data.RESPUESTA.item;
                let htmlMatTable = `<${isComputer ? 'h1' : 'h2'} class="mb-3">Mensaje de confirmación</${isComputer ? 'h1' : 'h2'}>
                <ul style="text-align: left;">`;
                for (const resp of response) {
                    htmlMatTable += `<li><strong>${resp.MATERIAL}</strong>: <span>${resp.RESPUESTA}</span></li>`
                }
                htmlMatTable += "  </ul>"
                MySwal.fire({
                    // title: `Mensaje de confirmación`,
                    html: htmlMatTable,
                    icon: "warning",
                    showCancelButton: false,
                    // width: 1000,
                    confirmButtonText: `Entendido`,
                    confirmButtonColor: "#2dce89",
                    buttonsStyling: isComputer,
                    customClass: {
                        confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                        cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
                    },
                    className: "p-0",
                    style: {
                        overflow: 'unset',
                        padding: '0px'
                    }
                }).then((result) => {
                    if (result.value) {
                        setMaterialList([]);
                    }
                });

            }
            setLoad(prev => !prev);
        });
    }
    return (
        <>
            <Row className="d-flex justify-content-center">
                <Col xs={isMobile ? "12" : "6"}>
                    <Card className="bg-gradient ">


                        <CardHeader>
                            <Row>
                                <Col xs="3">
                                    <Button color="secondary" size="sm" outline onClick={() => handleOnBack()}>
                                        <span className="btn-inner--icon mr-1">
                                            <i className={"fas fa-arrow-left"} />
                                        </span>
                                        {"Inicio"}
                                    </Button>
                                </Col>
                                <Col xs="7">
                                    <div className="text-center">
                                        <h5 className="h3 mb-0">{"Ampliar Materiales"}</h5>
                                        {!isMobile && <h6 className="surtitle">{"Ampliar Materiales a WMS"}</h6>}
                                    </div>
                                </Col>
                                <Col xs="2" style={isMobile ? {} : { display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        className="btn-icon-only rounded-circle"
                                        color="info"
                                        outline
                                        type="button"
                                        onClick={() => handleConfirm()}
                                    >
                                        <span className="btn-inner--icon">
                                            <i className="fas fa-check" />
                                        </span>
                                    </Button>
                                </Col>

                            </Row>
                        </CardHeader>

                        <CardBody className="">
                            <Row className="mt--3">
                                <Col>
                                    <FormGroup className="mb-0" >

                                        <Label className="font-weight-bold ml-2" >

                                            <MdComputer className="mr-2 mb-1" style={{ fontSize: "16px" }} />


                                            {'Material:'}
                                        </Label>


                                        <InputGroup className="input-group-merge">
                                            <Input
                                                type={"text"}
                                                className=""
                                                size={isMobile && "sm"}
                                                value={material}
                                                onChange={(e) => handleChange(e.target.value)}
                                                placeHolder="Ingrese un material"
                                                min={1}
                                            />

                                            <InputGroupAddon size="sm" addonType="append" className={'ml--2'}>
                                                <Button
                                                    size="sm"
                                                    outline
                                                    onClick={(e) => handleAddMaterial(e)}
                                                    color="success"
                                                // disabled={modeButtonAddon(field, "disabled")}
                                                >
                                                    <span className="btn-inner--icon">
                                                        <i className={"fas fa-plus"} />
                                                    </span>

                                                </Button>
                                            </InputGroupAddon>

                                        </InputGroup>


                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Card className="mt-0">
                                        <CardHeader className="mb-0">
                                            <h3>Lista de materiales por ampliar:</h3>
                                        </CardHeader>
                                        <CardBody className="pt-0">
                                            {
                                                materialList.length === 0 ?
                                                    <>

                                                        <span>Ningún material agregado...</span>

                                                    </>
                                                    :
                                                    materialList.map((material, key) => (
                                                        <>
                                                            <Row className="mb-1 mt-1">
                                                                <Col xs="12">
                                                                    <FormGroup className="mb-1 mt-1" >
                                                                        <InputGroup className="input-group-merge">
                                                                            <Input
                                                                                type={"text"}
                                                                                className=""
                                                                                size={isMobile && "sm"}
                                                                                value={material.MATERIAL}
                                                                                disabled={true}
                                                                            />

                                                                            <InputGroupAddon size="sm" addonType="append" className={'ml--2'}>
                                                                                <Button
                                                                                    size="sm"
                                                                                    outline
                                                                                    onClick={(e) => handleDeleteMaterial(e, key)}
                                                                                    color="danger"
                                                                                >
                                                                                    <span className="btn-inner--icon">
                                                                                        <i className={"fas fa-times"} />
                                                                                    </span>

                                                                                </Button>
                                                                            </InputGroupAddon>

                                                                        </InputGroup>


                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <hr className="mb-1 mt-1" />
                                                        </>
                                                    ))
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
    )
}
export default ExpandMaterial;