// javascript plugin that creates nice dropzones for files
import { Icon, Upload } from "antd";
// javascript library for dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core selectors
import { getTeamsUserAccess } from "selectors/adminLayout.jsx";
import {
  getDataByPosition,
  getInitialValuesCreate,
} from "selectors/newPosition.jsx";

const { Dragger } = Upload;
/**
 * Componente para el formulario de la creacion de una posicion
 * no planificada
 * @param {*} props
 * @returns
 */
const UnplannedForm = (props) => {
  const {
    data,
    teams,
    initialValues,
    title,
    subtitle,
    positionOptions,
    countrysOptions,
    onBack,
    onSearch,
    onCancel,
    onCreate,
    onSupport,
    onFileList,
    uploading,
  } = props;

  const uploadProps = {
    name: "file",
    multiple: true,
    // action: 'https://ss-api.gbm.net/auth/test',
    action: "https://smartsimple.gbm.net:43888/auth/test",
    onChange: onFileList,
  };

  /**
   * Funcion para las validaciones de los campos de los formularios
   * recibe como parametro los valores de cada campo del formulario
   * retorna un json con los errores detectados para mostrarlos en el formulario
   * @param {*} values
   * @returns
   */
  const validate = (values) => {
    const errors = {};
    if (!values.idPosition || values.idPosition === "0")
      errors.idPosition = "Debes seleccionar una posición.";
    if (!values.idCountry || values.idCountry === "0")
      errors.idCountry = "Debes seleccionar un país.";
    if (Object.keys(data).length) {
      if (!values.idCareerLevel || values.idCareerLevel === "0")
        errors.idCareerLevel = "Debes seleccionar un nivel de carrera.";
      if (!values.idPositionType || values.idPositionType === "0")
        errors.idPositionType = "Debes seleccionar un tipo de posición.";
      if (!values.idRequestType || values.idRequestType === "0")
        errors.idRequestType = "Debes seleccionar un tipo de solicitud.";
      if (!values.userManager || values.userManager === "0")
        errors.userManager = "Debes seleccionar un gerente a reportar.";
      if (!values.changeRequestDate)
        errors.changeRequestDate = "Debes seleccionar una fecha.";
      if (
        moment(values.changeRequestDate).format("YYYY-MM-DD") === "Invalid date"
      )
        errors.changeRequestDate = "Debes seleccionar una fecha valida.";
      if (!values.idOrganizacionalUnit || values.idOrganizacionalUnit === "0")
        errors.idOrganizacionalUnit =
          "Debes seleccionar una unidad organizacional.";
      if (!values.idCeco || values.idCeco === "0")
        errors.idCeco = "Debes seleccionar un centro de costo.";
      if (!values.idPersonalArea || values.idPersonalArea === "0")
        errors.idPersonalArea = "Debes seleccionar un área personal.";
      if (!values.haveEPM || values.haveEPM === "0")
        errors.haveEPM = "Debes seleccionar si tiene EPM.";
      if (!values.protection || values.protection === "003")
        errors.protection = "Debes seleccionar si tiene EPM.";
      if (!values.productivity || values.productivity === "0")
        errors.productivity = "Debes seleccionar si tiene productividad.";
      if (!values.idBussinessLine || values.idBussinessLine === "0")
        errors.idBussinessLine = "Debes seleccionar una línea de negocio.";
      if (!values.idAccess || values.idAccess === "0")
        errors.idAccess =
          "Debes seleccionar un tipo de administrativo o productivo.";
      if (!values.idDirection || values.idDirection === "0")
        errors.idDirection = "Debes seleccionar un tipo de dirección.";
      if (!values.idBudgetedResource || values.idBudgetedResource === "0")
        errors.idBudgetedResource = "Debes seleccionar un headcount.";
      if (!values.isManager || values.isManager === "0")
        errors.isManager = "Debes seleccionar un gerente a reportar.";
      if (!values.fixed || values.fixed === "000") {
        errors.fixed = "Debes seleccionar si es costo o gasto.";
        errors.spendTypeCost = "Debes seleccionar una opción en el campo Fijo.";
        errors.idEmployeeSubGroup =
          "Debes seleccionar una opción en el campo Fijo.";
      }
      if (!values.variable || values.variable === "000")
        errors.variable = "Debes seleccionar si es costo o gasto.";
      // if (!values.spendTypeCost || values.spendTypeCost === "000") {
      //   errors.spendTypeCost = "Debes seleccionar si es costo o gasto.";
      //   errors.idEmployeeSubGroup = "Debes seleccionar si es costo o gasto.";
      // }
      if (!values.fixedPercent || values.fixedPercent === "000") {
        errors.fixedPercent = "Debes seleccionar el % fijo.";
        errors.variablePercent = "Debes seleccionar el % fijo.";
      }
      if (
        (!values.idEmployeeSubGroup || values.idEmployeeSubGroup === "0") &&
        values.fixed &&
        values.fixed !== "000"
      )
        errors.idEmployeeSubGroup = "Debes seleccionar un Employee SubGroup.";
      if (!values.idPersonalBranch || values.idPersonalBranch === "0")
        errors.idPersonalBranch =
          "Debes seleccionar una Subdivisión de personal.";
    }
    return errors;
  };

  const zfill = (number, width) => {
    const numberOutput = Math.abs(number); /* Valor absoluto del número */
    const { length } = number.toString(); /* Largo del número */
    const zero = "0"; /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return `-${numberOutput.toString()}`;
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return `-${zero.repeat(width - length)}${numberOutput.toString()}`;
      } else {
        return zero.repeat(width - length) + numberOutput.toString();
      }
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            {!Object.keys(data).length &&
            teams.find((row) => row === "New Position Admin") ? (
              <Col className="text-right" xs="4">
                <Button color="info" size="sm" onClick={onSupport}>
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-settings-gear-65" />
                  </span>
                  <span className="btn-inner--text">Soporte</span>
                </Button>
              </Col>
            ) : null}
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={Object.keys(data).length ? onCreate : onSearch}
            initialValues={initialValues}
            validate={validate}
            render={({
              handleSubmit,
              values,
              submitting,
              validating,
              valid,
            }) => (
              <FormRS onSubmit={handleSubmit}>
                <div className="form-row align-items-center">
                  <Col className="mb-3" sm="12" md="6">
                    <FormGroup>
                      <Label for="idPosition">Posición</Label>
                      <Field name="idPosition">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              placeholder="Posición"
                              invalid={meta.error && meta.touched}
                              disabled={Object.keys(data).length}
                            >
                              <option key="0" value="0">
                                Seleccione una posición
                              </option>
                              {positionOptions.map((row, key) => {
                                return (
                                  <option key={key} value={row.idPosition}>
                                    {row.position}
                                  </option>
                                );
                              })}
                            </Input>
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12" md="6">
                    <FormGroup>
                      <Label for="idCountry">País</Label>
                      <Field name="idCountry">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              placeholder="País"
                              invalid={meta.error && meta.touched}
                              disabled={Object.keys(data).length}
                            >
                              <option key="0" value="0">
                                Seleccione un país
                              </option>
                              {countrysOptions.map((row, key) => {
                                return (
                                  <option key={key} value={row.idCountry}>
                                    {row.country}
                                  </option>
                                );
                              })}
                            </Input>
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
                {Object.keys(data).length ? (
                  <>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idPositionType">Tipo de Posición</Label>
                          <Field name="idPositionType">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Tipo de Posición"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un tipo de posición
                                  </option>
                                  {data.positionTypes.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idPositionType}
                                      >
                                        {row.positionType}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="8">
                        <FormGroup>
                          <Label for="idRequestType">Tipo de Solicitud</Label>
                          <Field name="idRequestType">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Tipo de Solicitud"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un tipo de solicitud
                                  </option>
                                  {data.requestType.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idRequestType}
                                      >
                                        {row.requestType}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idCareerLevel">Nivel de Carrera</Label>
                          <Field name="idCareerLevel">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Nivel de Carrera"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un nivel de carrera
                                  </option>
                                  {data.careerLevel.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idCareerLevel}
                                      >
                                        {row.careerLevel}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="userManager">Gerente a reportar</Label>
                          <Field name="userManager">
                            {({ input, meta }) => (
                              <>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Gerente a reportar"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un gerente a reportar
                                  </option>
                                  {data.collaborators.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.PositionEmployee}
                                      >
                                        {row.NameEmployee}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {/* <Select2
                                    {...input}
                                    className="form-control"
                                    options={{
                                      placeholder: "Seleccione un gerente a reportar"
                                    }}
                                    data={data.collaborators}
                                  /> */}
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="changeRequestDate">
                            Fecha de solicitud
                          </Label>
                          <span
                            id="changeRequestDate"
                            className="btn-inner--icon mr-1"
                            style={{
                              display: "inlineBlock",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <i className="fas fa-question" />
                          </span>
                          <Field name="changeRequestDate">
                            {({ input, meta }) => (
                              <>
                                <ReactDatetime
                                  {...input}
                                  inputProps={{
                                    placeholder: "Elija una fecha",
                                    className:
                                      meta.error && meta.touched
                                        ? "is-invalid form-control"
                                        : "form-control",
                                  }}
                                  timeFormat={false}
                                />
                                {meta.error && meta.touched && (
                                  <span
                                    style={{
                                      marginTop: "0.25rem",
                                      fontSize: "80%",
                                      color: "#fb6340",
                                    }}
                                  >
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="changeRequestDate"
                          >
                            Digite la fecha de hoy (No en la que ingresa el
                            colaborador)
                          </UncontrolledTooltip>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idOrganizacionalUnit">
                            Unidad Organizacional
                          </Label>
                          <Field name="idOrganizacionalUnit">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Unidad Organizacional"
                                  disabled={
                                    Object.keys(data.organizationalUnits)
                                      .length === 1
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una unidad organizacional
                                  </option>
                                  {data.organizationalUnits.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idOrganizationalUnit}
                                      >
                                        {row.organizationalUnit}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idCeco">Centro de Costo</Label>
                          <Field name="idCeco">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Centro de Costo"
                                  disabled={
                                    Object.keys(data.cecos).length === 1
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un centro de costo
                                  </option>
                                  {data.cecos.map((row, key) => {
                                    return (
                                      <option key={key} value={row.idCeco}>
                                        {row.ceco}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idPersonalArea">Área Personal</Label>
                          <Field name="idPersonalArea">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Área Personal"
                                  disabled={
                                    Object.keys(data.personalArea).length === 1
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un área personal
                                  </option>
                                  {data.personalArea.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idPersonalArea}
                                      >
                                        {row.personalArea}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="localRegionalType">
                            Local - Regional (PLA)
                          </Label>
                          <Field name="localRegionalType">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  invalid={meta.error && meta.touched}
                                  disabled
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="001">Local</option>
                                  <option value="002">Regional</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="haveEPM">¿Tiene EPM?</Label>
                          <Field name="haveEPM">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="001">Sí</option>
                                  <option value="002">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="productivity">Productividad</Label>
                          <Field name="productivity">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="001">Sí</option>
                                  <option value="002">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="protection">Protección</Label>
                          <Field name="protection">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  disabled={true}
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="003">
                                    Seleccione una opción
                                  </option>
                                  <option value="000">NA</option>
                                  <option value="001">Sí</option>
                                  <option value="002">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idBussinessLine">Línea de negocio</Label>
                          <Field name="idBussinessLine">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Línea de negocio"
                                  disabled={
                                    Object.keys(data.bussinessLine).length === 1
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una linea de negocio
                                  </option>
                                  {data.bussinessLine.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idBussinessLine}
                                      >
                                        {row.bussinessLine}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idAccess">
                            Administrativo / Productivo
                          </Label>
                          <Field name="idAccess">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Administrativo / Productivo"
                                  disabled={
                                    Object.keys(data.access).length === 1
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una linea de negocio
                                  </option>
                                  {data.access.map((row, key) => {
                                    return (
                                      <option key={key} value={row.idAccess}>
                                        {row.access}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idDirection">Dirección</Label>
                          <Field name="idDirection">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Dirección"
                                  disabled={
                                    Object.keys(data.direction).length === 1
                                  }
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione una dirección
                                  </option>
                                  {data.direction.map((row, key) => {
                                    return (
                                      <option key={key} value={row.idDirection}>
                                        {row.direction}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idBudgetedResource">Headcount</Label>
                          <Field name="idBudgetedResource">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Headcount"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">
                                    Seleccione un tipo de recurso presupuestado
                                  </option>
                                  {data.budgetedResource.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idBudgetedResource}
                                      >
                                        {row.budgetedResource}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="isManager">¿Es Gerente?</Label>
                          <Field name="isManager">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  <option value="001">Sí</option>
                                  <option value="002">No</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="fixed">Fijo</Label>
                          <span
                            id="fixed"
                            className="btn-inner--icon mr-1"
                            style={{
                              display: "inlineBlock",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <i className="fas fa-question" />
                          </span>
                          <Field name="fixed">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="000">
                                    Seleccione una opción
                                  </option>
                                  <option value="001">Gasto</option>
                                  <option value="002">Costo</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="fixed"
                          >
                            Indique si el salario fijo se debe cargar al costo o
                            al gasto
                          </UncontrolledTooltip>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="variable">Variable</Label>
                          <span
                            id="variable"
                            className="btn-inner--icon mr-1"
                            style={{
                              display: "inlineBlock",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <i className="fas fa-question" />
                          </span>
                          <Field name="variable">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="000">
                                    Seleccione una opción
                                  </option>
                                  <option value="001">Gasto</option>
                                  <option value="002">Costo</option>
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="variable"
                          >
                            Indique si el salario variable se debe cargar al
                            costo o al gasto
                          </UncontrolledTooltip>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="spendTypeCost">Costo o Gasto</Label>
                          <span
                            id="spendTypeCost"
                            className="btn-inner--icon mr-1"
                            style={{
                              display: "inlineBlock",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <i className="fas fa-question" />
                          </span>
                          <Field name="spendTypeCost">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  value={
                                    values.fixed === "001"
                                      ? "002"
                                      : values.fixed === "002"
                                      ? "001"
                                      : "000"
                                  }
                                  disabled
                                  invalid={meta.error}
                                >
                                  <option value="000">
                                    Seleccione una opción
                                  </option>
                                  <option value="002">Gasto</option>
                                  <option value="001">Costo</option>
                                </Input>
                                {meta.error && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="spendTypeCost"
                          >
                            Si se indica que el Fijo es de Costo, seleccione
                            Costo. Si se indica que el fijo es de Gasto,
                            seleccione Gasto.
                          </UncontrolledTooltip>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="fixedPercent">% Pago Fijo</Label>
                          <span
                            id="fixedPercent"
                            className="btn-inner--icon mr-1"
                            style={{
                              display: "inlineBlock",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <i className="fas fa-question" />
                          </span>
                          <Field name="fixedPercent">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="000">
                                    Seleccione una opción
                                  </option>
                                  {data.fixedPercents.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.keyFixedPercent}
                                      >
                                        {row.fixedPercent}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="fixedPercent"
                          >
                            De acuerdo al Plan de Compensación
                          </UncontrolledTooltip>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="variablePercent">% Pago Variable</Label>
                          <Field name="variablePercent">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  value={
                                    values.fixedPercent !== "000"
                                      ? zfill("020" - values.fixedPercent, 3)
                                      : "000"
                                  }
                                  disabled
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="000">
                                    {values.fixedPercent === "020"
                                      ? "0%"
                                      : "Seleccione un porcentaje fijo"}
                                  </option>
                                  {data.variablePercents.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.keyVariablePercent}
                                      >
                                        {row.variablePercent}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="4">
                        <FormGroup>
                          <Label for="idEmployeeSubGroup">
                            Employee SubGroup
                          </Label>
                          <Field name="idEmployeeSubGroup">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  placeholder="Employee SubGroup"
                                  invalid={meta.error}
                                  disabled={
                                    values.fixed === "001"
                                      ? false
                                      : values.fixed === "002"
                                      ? false
                                      : true
                                  }
                                >
                                  <option key="0" value="0">
                                    Seleccione un Employee SubGroup
                                  </option>
                                  {values.fixed === "002"
                                    ? data.employeeSubGroupCO.map(
                                        (row, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={row.idEmployeeSubGroup}
                                            >
                                              {row.employeeSubGroup}
                                            </option>
                                          );
                                        }
                                      )
                                    : values.fixed === "001"
                                    ? data.employeeSubGroupGT.map(
                                        (row, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={row.idEmployeeSubGroup}
                                            >
                                              {row.employeeSubGroup}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Input>
                                {meta.error && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" sm="12" md={4}>
                        <FormGroup>
                          <Label for="idPersonalBranch">
                            Subdivisión de Personal
                          </Label>
                          <Field name="idPersonalBranch">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  invalid={meta.error && meta.touched}
                                >
                                  <option value="0">
                                    Seleccione una opción
                                  </option>
                                  {data.personalBranch.map((row, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={row.idPersonalBranch}
                                      >
                                        {row.personalBranch}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="8">
                        <FormGroup>
                          <Label for="commentary">Comentarios (500)</Label>
                          <Field name="commentary">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  placeholder="Escriba aquí si tiene algún comentario."
                                  maxLength={500}
                                  rows="3"
                                  resize="none"
                                  invalid={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="12">
                        <FormGroup>
                          <Label for="files">Adjuntar Archivos</Label>
                          <Dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">
                              Suelte los archivos aquí o haga clic para cargar.
                            </p>
                            <p className="ant-upload-hint">
                              Cargar todos aquellos archivos recolectados como
                              muestra de evidencia de la solicitud que está
                              realizando.
                            </p>
                          </Dragger>
                        </FormGroup>
                      </Col>
                    </div>
                  </>
                ) : null}
                {submitting ? (
                  <Progress animated max="100" value="100" color="info" />
                ) : null}
                {!Object.keys(data).length ? (
                  <div className="form-row align-items-center">
                    <Col className="mb-3" sm="12" md="3">
                      <Button
                        disabled={submitting}
                        className="btn-icon"
                        block
                        color="info"
                        type="button"
                        onClick={onBack}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-arrow-left" />
                        </span>
                        <span className="btn-inner--text">Volver</span>
                      </Button>
                    </Col>
                    <Col className="mb-3" sm="12" md="3">
                      <Button
                        disabled={!valid || submitting || validating}
                        className="btn-icon"
                        block
                        color="info"
                        type="submit"
                        onClick={handleSubmit || validating}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-search" />
                        </span>
                        <span className="btn-inner--text">Buscar</span>
                      </Button>
                    </Col>
                  </div>
                ) : (
                  <div className="form-row align-items-center">
                    <Col className="mb-3" sm="12" md="3">
                      <Button
                        disabled={submitting || uploading}
                        className="btn-icon"
                        block
                        color="info"
                        type="button"
                        onClick={onCancel}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-window-close" />
                        </span>
                        <span className="btn-inner--text">Cancelar</span>
                      </Button>
                    </Col>
                    <Col className="mb-3" sm="12" md="3">
                      <Button
                        disabled={submitting || uploading}
                        className="btn-icon"
                        block
                        color="info"
                        type="submit"
                        onClick={handleSubmit || validating}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-save" />
                        </span>
                        <span className="btn-inner--text">Crear posición</span>
                      </Button>
                    </Col>
                  </div>
                )}
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </FormRS>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};

UnplannedForm.defaultProps = {
  data: {},
  initialValues: {},
  teams: [],
};

UnplannedForm.propTypes = {
  data: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  positionOptions: PropTypes.array.isRequired,
  countrysOptions: PropTypes.array.isRequired,
  onBack: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onSupport: PropTypes.func.isRequired,
  onFileList: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
};

/**
 * Funcion para mapear el state de la aplicacion a los props del componente
 * recibe de parametro el estado de la aplicacion
 * @param {*} state
 */
const mapStateToProps = (state) => ({
  data: getDataByPosition(state),
  initialValues: getInitialValuesCreate(state),
  teams: getTeamsUserAccess(),
});

export default withRouter(connect(mapStateToProps, null)(UnplannedForm));
