// core components
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import Table from 'components/Shared/Table/Table.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react component for creating dynamic tables
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
// react-redux library for connect component
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
// selectors get resourses
import { getNodesOptions, getResourses } from "selectors/planningMRS.jsx";

/**
 * Clase para mostrar los recursos asociados a nodos espeficios en una tabla
 * @class Resourses
 * @extends {Component}
 */
class Resourses extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      page: 1,
      sizePerPage: 5,
      resourses: props.resourses
    };
  }

  /**
   * Funcion para regresar a la vista de nodos
   * @memberof Resourses
   */
  handleOnBack = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de regresar?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.props.history.goBack()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, regresar"
          cancelBtnText="No, continuar"
          btnSize="md"
        />
      )
    });
  }

  /**
   * Funcion para ingresar a ver las tareas y ordenes asignadas a un recurso en especifico
   * @memberof Resourses
   */
  handleOnSelect = row => {
    this.props.history.push(`${this.props.match.url}/tasks/${row.EMPLOYEE_ID}`);
  }

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  render() {

    const { alert, page, sizePerPage, resourses } = this.state;

    const { nodesOptions } = this.props;

    const defaultSorted = [{
      dataField: 'EMPLOYEE_ID',
      order: 'asc'
    }];

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      hideSelectColumn: true,
      onSelect: this.handleOnSelect,
    };

    return (
      <>
        {alert}
        <AdminHeader name="Lista de Recursos" parentName="Planning MRS" />
        <div className="header pb-6">
          <Container className="mt--6" fluid>
            <Row>
              <div className="col">
                <Table
                  title="Recursos"
                  subtitle="Seleccione el recurso del cual desea ver las tareas."
                  columns={[
                    {
                      dataField: "EMPLOYEE_ID",
                      text: "ID",
                      sort: true
                    },
                    {
                      dataField: "EMPLOYEE_NAME",
                      text: "Nombre",
                      sort: true
                    },
                    {
                      dataField: "ORG_ID",
                      text: "Nodo",
                      formatter: cell => nodesOptions[cell],
                      filter: selectFilter({
                        options: nodesOptions,
                        placeholder: "Seleccione un nodo",
                        style: {
                          height: 'calc(1.8125rem + 2px)',
                          padding: '0.25rem 0.5rem',
                          fontSize: '0.875rem',
                          lineHeight: 1.5,
                          borderRadius: '0.25rem',
                        }
                      })
                    },
                  ]}
                  data={resourses}
                  selectRow={selectRow}
                  filterFactory={filterFactory}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={resourses.length}
                  defaultSorted={defaultSorted}
                />
              </div>
            </Row>
            <Row>
              <Col xs={12} sm={3}>
                <Button
                  className="btn-icon"
                  color="info"
                  type="button"
                  block
                  onClick={this.handleOnBack}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-bold-left" />
                  </span>
                  <span className="btn-inner--text">Atrás</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

Resourses.defaultProps = {
  resourses: [],
  nodesOptions: {}
};

Resourses.propTypes = {
  resourses: PropTypes.array.isRequired,
  nodesOptions: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  resourses: getResourses(state),
  nodesOptions: getNodesOptions(state)
});

export default withRouter(connect(mapStateToProps, null)(Resourses));