import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Table,
  CardFooter,
  CardBody,
  Col,
  Row,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";



const CompetitionMainTable = ({ rows, page, setPage, openModal }) => {
  const [selected, setSelected] = useState([
    { value: "singleOrderRecord", label: "Registro Unico de Pedido" },
    { value: "vendor", label: "Proveedor" },
    { value: "dateFrom", label: "Fecha desde", type: "date" },
    { value: "dateTo", label: "Fecha Hasta", type: "date" },
    { value: "entity", label: "Entidad" },
    { value: "registrationDate", label: "Fecha de Registro", type: "date" },
    { value: "publicationDate", label: "Fecha de publicación", type: "date" },
  ]);
  const optionsSelect = [
    {
      value: "singleOrderRecord",
      label: "Registro Unico de Pedido",
      disabled: true,
    },
    { value: "agreement", label: "Convenio" },
    { value: "dateFrom", label: "Fecha desde", disabled: true, type: "date" },
    { value: "dateTo", label: "Fecha Hasta", disabled: true, type: "date" },
    { value: "entity", label: "Entidad" },
    { value: "vendor", label: "Proveedor" },
    { value: "registrationDate", label: "Fecha de Registro", type: "date" },
    { value: "publicationDate", label: "Fecha de publicación", type: "date" },
    { value: "documentLink", label: "Link del Documento" },
    { value: "comment", label: "Comentario" },
  ];
  const [state, setState] = useState({
    sizePerPage: 10,
  });
  const { sizePerPage } = state;

  const paginations =
    rows.length > sizePerPage
      ? rows.length / sizePerPage > Math.round(rows.length / sizePerPage)
        ? Math.round(rows.length / sizePerPage) + 1
        : Math.round(rows.length / sizePerPage)
      : 1;

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink onClick={() => handleOnSetPage(i, "page")}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return options.length >= 5
      ? page >= 5
        ? options.slice(page - 3, page + 2)
        : options.slice(0, 5)
      : options;
  };
  //Cambiar de pagina
  const handleOnSetPage = (page) => {
    setPage(page);
  };
  const handleOnSetNumPagination = (e) => {
    setPage(1);
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      sizePerPage: value
    }))
  }
  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="10">
              <p className="text-sm mb-0">
                Columnas Seleccionables
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="3">
              <MultiSelect
                options={optionsSelect}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">
              <tr>
                {selected.map((item, key) => {
                  return (
                    <th key={key} className="justify-content-md-center">
                      {item.label}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="list">
              {rows
                .slice((page - 1) * sizePerPage, page * sizePerPage)
                .map((row, key) => (
                  <tr key={key} className="text-center">
                    {selected.map((col, key) => (
                      <td key={key} className="text-center">
                        {col.value === "singleOrderRecord" ? (
                          <a
                            key={key}
                            onClick={() => openModal(row)}
                            style={{ color: "blue" }}
                          >
                            {row[col.value]}
                          </a>
                        ) : col.type === "date" && moment.utc(row[col.value]).format('L') !== "Invalid date" ?
                          moment.utc(row[col.value]).format('L')
                          : (
                            row[col.value]
                          )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="p-1">
                <Row className="justify-content-start">
                  <Col xs="12" md="6">
                    <span className="pagination mb-0">
                      Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                      {page * sizePerPage > rows.length
                        ? rows.length
                        : page * sizePerPage}{" "}
                      de {rows.length} resultados
                    </span>
                  </Col>
                  <Col xs='12' md='2'>
                    <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="6" className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() =>
                        handleOnSetPage(page === 1 ? page : page - 1, "page")
                      }
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {renderPaginations()}
                  <PaginationItem
                    className={page === paginations ? "disabled" : ""}
                  >
                    <PaginationLink
                      onClick={() =>
                        handleOnSetPage(
                          page === paginations ? page : page + 1,
                          "page"
                        )
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>
    </div>
  );
};

export default CompetitionMainTable;
