/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardHeader, Modal, Table } from "reactstrap";

const EquipmentsError = props => {

  const {
    showModal,
    errors,
    toggleModal,
    cols,
  } = props;

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={showModal}
        toggle={() => toggleModal("modalEquipmentsErrors")}
      >
        <Card>
          <CardHeader>
            <h5 className="h3 mb-0">Equipos sin crear</h5>
          </CardHeader>
          <CardBody>
            <Table
              className="align-items-center table-flush"
              responsive
            >
              <thead className="thead-light">
                <tr className="text-center">
                  {
                    cols.map((row, key) => (row.hide === false ? <th key={key}>{row.name}</th> : null))
                  }
                  <th />
                </tr>
              </thead>
              <tbody className="list">
                {
                  errors.map((row, key) => (
                    <tr key={key}>
                      {
                        cols.map((col, key) => (
                          col.hide === false ?
                            col.key === 'msg' ?
                              <th key={key} >
                                {row[col.key]}
                              </th>
                              :
                              <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                {row[col.key]}
                              </th>
                            : null
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

EquipmentsError.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default EquipmentsError;
