// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const WorkFlowModal = props => {

  const {
    type,
    title,
    showModal,
    toggleModal,
    loading,
    options,
    initialValues,
    onChange,
    onWorkFlow
  } = props;

  const validate = () => {
    const errors = {};
    options.forEach(element => {
      if ((!initialValues[element.key] || initialValues[element.key] === "0" || initialValues[element.key] === "") && element.required && element.visible) {
        errors[element.key] = 'Este campo es de carácter obligatorio'
      }
      if (element.min && initialValues[element.key]) {
        // console.log(element.min);
        // console.log(initialValues[element.key].length);
        if (element.min !== initialValues[element.key].length)
          errors[element.key] = `Es obligatorio que cumpla el minimo de ${element.min} caracteres`;
      }
    });
    return errors;
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={showModal}
        toggle={() => toggleModal('flowModal')}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-3">
                <h4>{title}</h4>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form
                onSubmit={() => onWorkFlow(initialValues, type)}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit, values, submitting, validating, valid }) => (
                  <Spin size="large" spinning={loading}>
                    <FormRS role="form">
                      {
                        options.map((row, key) => (
                          row.visible ?
                            <FormGroup key={key}>
                              <Label for={row.key}>{row.label}</Label>
                              <Field name={row.key}>
                                {({ input, meta }) => (
                                  <div>
                                    {
                                      row.type === 'select' ?
                                        <Input
                                          {...input}
                                          type={row.type}
                                          valid={!meta.error && meta.modified && meta.touched}
                                          invalid={meta.error && meta.touched}
                                          onChange={(e) => onChange(e)}
                                          value={values[row.key]}
                                        >
                                          {
                                            row.options.map((element, key) => (
                                              <option key={key} value={key}>{element}</option>
                                            ))
                                          }
                                        </Input>
                                        :
                                        row.type === 'textarea' ?
                                          <Input
                                            {...input}
                                            maxLength={row.max}
                                            type={row.type}
                                            rows="3"
                                            resize="none"
                                            placeholder={row.placeholder}
                                            onChange={(e) => onChange(e)}
                                            value={values[row.key]}
                                            invalid={meta.error && meta.touched}
                                          />
                                          :
                                          <Input
                                            {...input}
                                            maxLength={row.max}
                                            minLength={row.min}
                                            type={row.type}
                                            placeholder={row.placeholder}
                                            onChange={(e) => onChange(e)}
                                            value={values[row.key]}
                                            invalid={meta.error && meta.touched}
                                          />
                                    }
                                    {
                                      meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                    }

                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            : null
                        ))
                      }
                      <div className="text-center">
                        <Button
                          className="btn-icon btn-3"
                          color="success"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={validating || submitting}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-sync-alt" />
                          </span>
                          <span className="btn-inner--text">Continuar</span>
                        </Button>
                      </div>

                    </FormRS>
                  </Spin>
                )}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

WorkFlowModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default WorkFlowModal;
