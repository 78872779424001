/*!

=========================================================
*Autopp - AutoppCareMain
=========================================================

*Componente Inicio 

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de React
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Row, Col } from "reactstrap";

// imports de Alerts y  Spin
import { Spin } from 'antd';

// imports de actions
import {
  getAutoppCareOptions,
  getLDRForms,

} from 'actions/autoppLdrs';

// react MultiSelect

//Import de componente contexto, para establecer variables globales
import { UserContext } from 'components/AutoppLdrs/SharedComponents/UserContext';

import { useDispatch } from 'react-redux';

import { CreateLDR } from './DinamycForm/CreateLDR';
import LDRTableStatus from './LDRTableStatus/LDRTableStatus';
import { BrandsMaintenance } from './Brands/BrandsMaintenance';

export const AutoppCareMain = React.memo(() => {

  const dispatch = useDispatch();

  //#region States locales

  const [spinner, setSpinner] = useState(true)
  const [spinnerTable, setSpinnerTable] = useState(false)
  //#endregion


  //#region Extraer options al API

  const [options, setOptions] = useState({
    inputType:
      [
        { label: "Texto", value: "text" },
        { label: "Númerico", value: "number" },
        { label: "Fecha", value: "date" },
        { label: "Texto largo", value: "textarea" },
      ],
    autoppCareOptions: [],
    LDRForms: { LDRForms: [] }
  })

  const [refreshOptions, setRefreshOptions] = useState(true);


  useEffect(() => {
    if (refreshOptions) {

      const getOptionsGeneral = async () => {
        await getOptions();
        await getLDRFormsF();
      }
      getOptionsGeneral();

      setRefreshOptions(false)

    }

  }, [refreshOptions])

  useEffect(() => {
    const getOptionsGeneral = async () => {
      await getOptions();
      await getLDRFormsF();
    }
    getOptionsGeneral();

  }, [])


  //Trae el AutoppCareOptions
  const getOptions = () => {
    dispatch(getAutoppCareOptions()).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {

        setOptions(prevState => ({
          ...prevState,
          autoppCareOptions: payload.data.payload.autoppCareOptions
        }))
      }

    })
  }

//trae los dropdowns para el componente ldrs porque son muy pesados
const getLDRFormsF = () => {
  dispatch(getLDRForms()).then((resp) => {
    const { payload } = resp;


    if (payload.status === 200) {

      let LDRFormsArray= payload.data.payload.LDRForms.LDRForms;

      //Si vienen LDRS que son null que no los tome en cuenta.
     Object.keys(LDRFormsArray).map(LDRId=> {
        if(LDRFormsArray[LDRId]==null)
        {
          console.log("El LDR con id:" + LDRId + " tiene valor null por tanto es eliminado de la lista, revisar los campos si están inactivos esa puede ser la causa que viene null.");
          delete LDRFormsArray[LDRId];         
        }
      })

      setOptions(prevState => ({
        ...prevState,
        LDRForms: LDRFormsArray
      }))
    }

    //Apagar spinner
    setSpinner(false);
    setSpinnerTable(false)
  })
}

  //#endregion


  //State para que mostrar el formulario dinámico o no.
  const [showComponent, setShowComponent] = useState({ dinamycForm: false, brandsMaintenance: false })





  useEffect(() => {
    // console.log("las options", options)
  }, [options])

  //State para indicar si es creación o una edición de LDR.
  const [typeLDR, setTypeLDR] = useState("create")

  //State para indicar la información del LDR a editar.
  const [infoLDRToEdit, setInfoLDRToEdit] = useState(null)



  //Función para abrir el componente para crear un LDR.
  const handleCreateLDR = () => {

    //Indicar abrir el componente
    setShowComponent({ ...showComponent, dinamycForm: true })
    //No tiene información a editar porque es una creación 
    setInfoLDRToEdit(null)
    //Indicar que es una creación
    setTypeLDR("create")

  }

  //Función para abrir el componente para crear un LDR.
  const handleBrandsMaintenance = () => {

    //Indicar abrir el componente
    setShowComponent({ dinamycForm: false, brandsMaintenance: true })

  }



  //State donde almacena los cards superiores con las distintas opciones a elegir.
  const [cardsToChoice, setCardsToChoice] = useState([
    {
      title: "Crear Formulario",
      subtitle: "Crear nuevo formulario LDR para la Fábrica de Propuestas",
      openText: "Abrir",
      function: handleCreateLDR,
      icon: "fa fa-edit",
      color: "default"
    },
    {
      title: "Mantenimiento de Brands",
      subtitle: "Crear-cambiar-eliminar brands para los formularios LDR",
      openText: "Abrir",
      function: handleBrandsMaintenance,
      icon: "fa fa-edit",
      color: "info"
    },


  ])




  return (
    <>
      <UserContext.Provider value={
        {
          showComponent,
          setShowComponent,
          setRefreshOptions,
          spinnerTable,
          setSpinnerTable,
          setInfoLDRToEdit,
          setTypeLDR,
        }
      }>

        <Spin size="large" spinning={spinner}>
          {showComponent.dinamycForm == true ?
            <Container className="mt--6" fluid>

              <CreateLDR
                options={options}
                type={typeLDR}
                infoLDRToEdit={infoLDRToEdit}
                setRefreshOptions={setRefreshOptions}
              />
            </Container>

            : showComponent.brandsMaintenance == true ?
              <Container className="mt--6" fluid>

                <BrandsMaintenance
                options={options}
                />
              </Container>
              :

              <Container className="mt--6" fluid>

                <Row className="align-items-center">


                  {cardsToChoice.map((card, i) => (
                    <Col sm="4">
                      <Card className={`bg-gradient-${card.color} shadow card-lift--hover card-stats p-0`}
                        style={{ cursor: "pointer", padding: 0 }}
                        onClick={() => card.function()}

                      >
                        <CardBody style={{}} className="mr--4 mt--2">
                          <Row>

                            <Col sm="8">
                              <h4 className="text-white mt-3 mb-0">{card.title}</h4>
                              <small className="text-light text-sm mt-1 mb-0">
                                {card.subtitle}</small>

                              <p className="mt-3 mb-0 text-sm text-white">
                                <span className="" >
                                  <i className="fa fa-arrow-up " /> {card.openText}
                                </span>{" "}
                              </p>

                            </Col>
                            <Col sm="4">

                              <div className="icon icon-shape bg-white text-dark rounded-circle shadow mt-4">
                                <i className={card.icon} />
                              </div>

                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>

                <Row>
                  <Col sm="12">
                    <LDRTableStatus options={options} />
                  </Col>
                </Row>

              </Container>
          }
        </Spin>

      </UserContext.Provider>

    </>

  )
})

export default AutoppCareMain;


