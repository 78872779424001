
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Button
} from "reactstrap";
import { getOptions, getAccessRequestByUser } from "actions/financialFlows"
import { useDispatch } from "react-redux";


const ShowCategories = ({ setCategory, info, setNewInfoToInsert, setNewInfo, setLoad }) => {
    const dispatch = useDispatch();
    const [optionsInfo, setOptionsInfo] = useState({
        category: []
    });
    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetInfo();
        };
        getInfo();
    }, []);
    //Funcion que trae la data de los campos selects
    const handleOnGetInfo = async () => {
        setLoad(true);
        let categoriesProfile = [];
        await dispatch(getAccessRequestByUser()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let jsonProfileCategory = {};
                for (const item of payload.data.data[0]) {
                    if (item.profileTypeId !== 1) {
                        for (const pcategory of item.profileCategories) {
                            jsonProfileCategory["categoryId"] = pcategory.categoryId
                            jsonProfileCategory["categoryName"] = pcategory.categoryName
                            if (!categoriesProfile.includes(jsonProfileCategory)) {
                                categoriesProfile.push(jsonProfileCategory);
                            }
                            jsonProfileCategory = {};
                        }
                    }
                }
            } else {
                setLoad(false);
            }
        })
        await dispatch(getOptions()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let filterCategory = [];
                payload.data.data.category.filter((item) => {
                    for (const profile of categoriesProfile) {
                        if (item.value === profile.categoryId) {
                            if (!filterCategory.includes(item)) {
                                filterCategory.push(item);
                            }
                        }
                    }
                });
                setOptionsInfo((prevState) => ({
                    ...prevState,
                    area: payload.data.data.area,
                    businessUnit: payload.data.data.businessUnit,
                    category: filterCategory,
                    profileType: payload.data.data.profileType,
                    state: payload.data.data.state,
                    typeActive: payload.data.data.typeActive,
                    typeUnitType: payload.data.data.typeUnitType,
                    unitType: payload.data.data.unitType,
                }));
            } else {
                setLoad(false);
            }
            setLoad(false);
        });
    };
    const handleOnChangeCategory = () => {
        setNewInfoToInsert([]);
        setNewInfo([])
        setCategory("Category", "")
    }
    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h3 className="mb-0">
                                Seleccione la categoria
                            </h3>
                        </Col>
                        {
                            info.Category !== "" &&
                            <Col xs="2">
                                <Button
                                    className="btn  btn-icon"
                                    color="dark"
                                    block
                                    type="button"
                                    onClick={() => handleOnChangeCategory()}
                                >
                                    <span className="btn-inner--icon mr-">
                                        <i className="fas fa-sync-alt"></i>
                                    </span>
                                    <span className="btn-inner--text">Cambiar</span>
                                </Button>
                            </Col>
                        }
                    </Row>
                </CardHeader>
                {info.Category === ""
                    ?
                    <CardBody>
                        <Row>
                            {
                                optionsInfo.category.map((item, key) => (
                                    <Col xs="6" key={key}>
                                        <button
                                            key={key}
                                            className="btn-icon-clipboard"
                                            type="button"
                                            onClick={() => setCategory("Category", item)}
                                        >
                                            <div>
                                                <span>{item.label}</span>
                                            </div>
                                        </button>
                                    </Col>
                                ))
                            }
                        </Row>
                    </CardBody>
                    :
                    <CardBody>
                        <Row className="mb-3">
                            <span className="h6 surtitle text-muted">
                                Categoria Seleccionada:
                            </span>
                        </Row>
                        <Row className="mb-3">
                            <h1>{info.Category.label}</h1>
                        </Row>
                    </CardBody>
                }
            </Card>
        </div>
    );
};


export default ShowCategories;