/*!

=========================================================
*UserContext
=========================================================

*Componente para poder si se desea y se requiere, los props
de forma global.

=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/
import { createContext} from 'react'

export const UserContext= createContext(null);
