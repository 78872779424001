/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la ventana de confirmacion que aparece al dar continuar a la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({ showModal, toggle, okFunct }) => {
    //FUNCIONES

    const continueButton = () => {
        toggle(); // Cierra el modal
        okFunct(); // Llama a la función que se ejecuta en OK
    };

    //RETURN
    return (
        <>
            <Modal isOpen={showModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirmación acción</ModalHeader>
                <ModalBody>
                    ¿Estás seguro que desea continuar, los cambios realizados no podrán ser revertidos?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={continueButton}>Continuar</Button>
                    {' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
export default ConfirmationModal;