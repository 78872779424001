// javascript library for dates
import moment from 'moment';
// labels chart
const labelsMonths = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

let labelsDays = [];

// Colors charts
const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529"
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340"
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent"
};

const getMonths = () => {
  const array = [];
  for (let i = 0; i < 12; i++) {
    array.push(0);
  }
  return array;
};

const getDays = month => {
  labelsDays = [];
  const days = moment().daysInMonth(month);
  const array = [];
  for (let i = 0; i < days; i++) {
    array.push(0);
    labelsDays.push(i + 1);
  }
  return array;
};

export const getDataWitget = state => {
  if (Object.keys(state.performance).length) {
    const { dataWitget } = state.performance;
    if (dataWitget.length) {
      const [data] = dataWitget
      return data;
    }
  }
  return {};
};

export const getMapData = state => {
  if (Object.keys(state.performance).length) {
    const { dataMaps2 } = state.performance;
    if (dataMaps2.length) {
      const data = {};
      dataMaps2.forEach(element => {
        data[element.country] = element.amountCountry
      });
      return data;
    }
  }
  return {};
};

export const getDataMaps = state => {
  if (Object.keys(state.performance).length) {
    const { dataMaps } = state.performance;
    return dataMaps.map((row) => {
      row.latLng = [row.latitude, row.longitude];
      row.name = row.city;
      return row;
    });
  }
  return [];
};

export const getDataVisitedModules = state => {
  if (Object.keys(state.performance).length) {
    const { dataTable } = state.performance;
    return dataTable;
  }
  return [];
};

export const getDataSignInsUsers = state => {
  if (Object.keys(state.performance).length) {
    const { dataTable2 } = state.performance;
    return dataTable2;
  }
  return [];
};

export const getSignInGraphs = state => {
  let days = getDays(moment().month() + 1);
  if (Object.keys(state.signinsGraphs).length) {
    const { dataYear, dataMonth, month } = state.signinsGraphs;
    let months = getMonths();
    let days = getDays(month);
    for (const row of dataYear) {
      months[row.month - 1] = row.total;
    }
    for (const row of dataMonth) {
      days[row.day - 1] = row.total;
    }
    return {
      months: {
        labels: labelsMonths,
        datasets: [
          {
            label: "Inicios de Sesión",
            data: months,
            borderColor: "#f5365c",
            backgroundColor: '#f5365c',
            fill: false,
            pointRadius: 4,
          },
        ]
      },
      days: {
        labels: labelsDays,
        datasets: [
          {
            label: "Inicios de Sesión",
            data: days,
            borderColor: "#f5365c",
            backgroundColor: '#f5365c',
            fill: false,
            pointRadius: 4,
          },
        ]
      }
    };
  }
  return {
    months: {
      labels: labelsMonths,
      datasets: [
        {
          label: "Inicios de Sesión",
          data: [],
          borderColor: "#f5365c",
          backgroundColor: '#f5365c',
          fill: false,
          pointRadius: 4,
        },
      ]
    },
    days: {
      labels: labelsDays,
      datasets: [
        {
          label: "Inicios de Sesión",
          data: days,
          borderColor: "#f5365c",
          backgroundColor: '#f5365c',
          fill: false,
          pointRadius: 4,
        },
      ]
    }
  };
};

export const getTransactionsGraphs = state => {
  let days = getDays(moment().month() + 1);
  if (Object.keys(state.transactionsGraphs).length) {
    const { dataYear, dataMonth, month } = state.transactionsGraphs;
    let months = getMonths();
    let days = getDays(month);
    for (const row of dataYear) {
      months[row.month - 1] = row.total;
    }
    for (const row of dataMonth) {
      days[row.day - 1] = row.total;
    }
    return {
      months: {
        labels: labelsMonths,
        datasets: [
          {
            label: "Inicios de Sesión",
            data: months,
            borderColor: "#f5365c",
            backgroundColor: '#f5365c',
            fill: false,
            pointRadius: 4,
          },
        ]
      },
      days: {
        labels: labelsDays,
        datasets: [
          {
            label: "Inicios de Sesión",
            data: days,
            borderColor: "#f5365c",
            backgroundColor: '#f5365c',
            fill: false,
            pointRadius: 4,
          },
        ]
      }
    };
  }
  return {
    months: {
      labels: labelsMonths,
      datasets: [
        {
          label: "Inicios de Sesión",
          data: [],
          borderColor: "#f5365c",
          backgroundColor: '#f5365c',
          fill: false,
          pointRadius: 4,
        },
      ]
    },
    days: {
      labels: labelsDays,
      datasets: [
        {
          label: "Inicios de Sesión",
          data: days,
          borderColor: "#f5365c",
          backgroundColor: '#f5365c',
          fill: false,
          pointRadius: 4,
        },
      ]
    }
  };
};

export const getGraphsDashboard = state => {
  let monthsS = getMonths();
  let monthsT = getMonths();
  let daysS = getDays(moment().month() + 1);
  let daysT = getDays(moment().month() + 1);
  if (Object.keys(state.transactionsGraphs).length) {
    const { dataYear, dataMonth, month } = state.transactionsGraphs;
    daysT = getDays(month);
    for (const row of dataYear) {
      monthsT[row.month - 1] = row.total;
    }
    for (const row of dataMonth) {
      daysT[row.day - 1] = row.total;
    }
  }
  if (Object.keys(state.signinsGraphs).length) {
    const { dataYear, dataMonth, month } = state.signinsGraphs;
    daysS = getDays(month);
    for (const row of dataYear) {
      monthsS[row.month - 1] = row.total;
    }
    for (const row of dataMonth) {
      daysS[row.day - 1] = row.total;
    }
  }
  return {
    months: {
      labels: labelsMonths,
      datasets: [
        {
          label: "Inicios de Sesión",
          data: monthsS,
          borderColor: colors.theme["primary"],
          backgroundColor: colors.theme["primary"],
          fill: false,
          pointRadius: 4,
        },
        {
          label: "Transacciones",
          data: monthsT,
          borderColor: colors.theme["success"],
          backgroundColor: colors.theme["success"],
          fill: false,
          pointRadius: 4,
        },
      ]
    },
    days: {
      labels: labelsDays,
      datasets: [
        {
          label: "Inicios de Sesión",
          data: daysS,
          borderColor: colors.theme["primary"],
          backgroundColor: colors.theme["primary"],
          fill: false,
          pointRadius: 4,
        },
        {
          label: "Transacciones",
          data: daysT,
          borderColor: colors.theme["success"],
          backgroundColor: colors.theme["success"],
          fill: false,
          pointRadius: 4,
        },
      ]
    }
  };
};