import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const findAllProducts = handleActions({
    [constants.GET_PRODUCTS]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const findAllServices = handleActions({
    [constants.GET_SERVICES]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const findAllPlatforms = handleActions({
    [constants.GET_PLATFORMS]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {});
export const findAllData = handleActions({
    [constants.GET_DATA]: (state, action) => {
        const { status, data } = action.payload;
        if (status === 200) {

            return data.payload;
        }
        return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
}, {})