// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const StatusCard = props => {

  const {
    parent,
    title,
    total,
    totalMonth,
    color,
    icon,
  } = props;

  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {total}
            </span>
          </div>
          <Col className="col-auto">
            <div className={`icon icon-shape ${color} text-white rounded-circle shadow`}>
              <i className={icon} />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <span className="text-success mr-2">
            <i className="fa fa-clock" /> {totalMonth}
          </span>{" "}
          <span className="text-nowrap">{parent ? 'transacciones en el presente mes' : 'En el presente mes'}</span>
        </p>
      </CardBody>
    </Card>
  );
};

StatusCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default StatusCard;