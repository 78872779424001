import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findMaritalStatus = createAction(
  constants.GET_MARITAL_STATUS,
  method.get(`${urls.KNOWEMPLOYEE.getMaritalStatus}`)
);

export const findRelationships = createAction(
  constants.GET_RELATIONSHIPS,
  method.get(`${urls.KNOWEMPLOYEE.getRelationships}`)
);

export const findPersonalInfoEmployee = createAction(
  constants.GET_PERSONAL_INFO_BY_EMPLOYEE,
  method.get(`${urls.KNOWEMPLOYEE.getPersonalInfoByEmployee}`)
);

export const findPersonalInfoCompleteByEmployee = createAction(
  constants.GET_PERSONAL_INFO_COMPLETE_BY_EMPLOYEE,
  method.get(`${urls.KNOWEMPLOYEE.getPersonalInfoCompleteByEmployee}`)
);

export const findAllPersonalInfoEmployeeForms = createAction(
  constants.GET_ALL_PERSONAL_INFO_EMPLOYEE_FORMS,
  method.get(`${urls.KNOWEMPLOYEE.getAllPersonalInfoEmployeeForms}`)
);

export const findFamilyGroupByEmployee = createAction(
  constants.GET_FAMILY_GROUP_BY_EMPLOYEE,
  (id) => method.post(`${urls.KNOWEMPLOYEE.getFamilyGroupByEmployee}/${id}`)()
);

export const findEmergencyContactsByEmployee = createAction(
  constants.GET_EMERGENCY_CONTACTS_BY_EMPLOYEE,
  (id) =>
    method.post(`${urls.KNOWEMPLOYEE.getEmergencyContactsByEmployee}/${id}`)()
);

export const loadEmergencyContactsByEmployee = createAction(
  constants.LOAD_EMERGENCY_CONTACTS_BY_EMPLOYEE,
  (id) =>
    method.post(`${urls.KNOWEMPLOYEE.loadEmergencyContactsByEmployee}/${id}`)()
);

export const createFamilyGroupByEmployee = createAction(
  constants.CREATE_FAMILY_GROUP_BY_EMPLOYEE,
  (id, body) =>
    method.post(
      `${urls.KNOWEMPLOYEE.createFamilyGroupByEmployee}/${id}`,
      body
    )()
);

export const createEmergencyContactByEmployee = createAction(
  constants.CREATE_EMERGENCY_CONTACT_BY_EMPLOYEE,
  (id, body) =>
    method.post(
      `${urls.KNOWEMPLOYEE.createEmergencyContactByEmployee}/${id}`,
      body
    )()
);

export const createManyEmergencyContactByEmployee = createAction(
  constants.CREATE_MANY_EMERGENCY_CONTACT_BY_EMPLOYEE,
  (id, body) =>
    method.post(
      `${urls.KNOWEMPLOYEE.createEmergencyContactByEmployee}/${id}`,
      body
    )()
);

export const completePersonalInfoByEmployee = createAction(
  constants.COMPLETE_PERSONAL_INFO_BY_EMPLOYEE,
  (id, body) =>
    method.post(
      `${urls.KNOWEMPLOYEE.completePersonalInfoByEmployee}/${id}`,
      body
    )()
);

export const deactivatedFamilyGroupByID = createAction(
  constants.DEACTIVATED_FAMILY_GROUP_BY_ID,
  (id) => method.put(`${urls.KNOWEMPLOYEE.deactivatedFamilyGroupByID}/${id}`)()
);

export const deactivatedEmergencyContactByID = createAction(
  constants.DEACTIVATED_EMERGENCY_CONTACT_BY_ID,
  (id) =>
    method.put(`${urls.KNOWEMPLOYEE.deactivatedEmergencyContactByID}/${id}`)()
);
