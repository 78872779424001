// core antd
import { Drawer, Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useEffect } from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Col, Form as FormRS, FormGroup, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";

const AddServiceDrawer = props => {

  const {
    title,
    visible,
    uploading,
    selector,
    initialValues,
    onSave,
    onClose,
    onEdit,
    onSearch,
  } = props;

  useEffect(() => {
    delete initialValues['value'];
  });

  const validate = values => {
    const errors = {};
    return errors;
  };

  return (
    <>
      <Drawer
        title={title}
        width={340}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          onSubmit={onSave}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, values, submitting, validating, valid }) => (
            <Spin size="large" spinning={uploading} tip="Creando la parte crítica">
              <FormRS role="form">
                <div className="form-row">
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="code">Código</Label>
                      <Field name="code">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="m-0"
                              type="text"
                              style={{ height: "calc(2.25rem + 2px)" }}
                              placeholder="Escriba aquí el código."
                              // maxLength={2500}
                              // rows="3"
                              // resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="description" className="form-control-label">Descripción</Label>
                      <Field name="description">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'description' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí la descripción."
                                    // maxLength={2500}
                                    // rows="3"
                                    // resize="none"
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('description')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                            }
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="serviceGroup">Servicio</Label>
                      <Field name="serviceGroup">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'serviceGroup' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el Grupo de Servicio."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('serviceGroup')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="category">Categoría</Label>
                      <Field name="category">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'category' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí la categoria."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('category')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="subcategory">Subcategoría</Label>
                      <Field name="subcategory">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'subcategory' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí la subcategoria."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('subcategory')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp1">GRP1</Label>
                      <Field name="grp1">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp1' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp1')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp2">GRP2</Label>
                      <Field name="grp2">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp2' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp2')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp3">GRP3</Label>
                      <Field name="grp3">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp3' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp3')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp4">GRP4</Label>
                      <Field name="grp4">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp4' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp4')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp5">GRP5</Label>
                      <Field name="grp5">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp5' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp5')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp6">GRP6</Label>
                      <Field name="grp6">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp6' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp6')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp7">GRP7</Label>
                      <Field name="grp7">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp7' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp7')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp8">GRP8</Label>
                      <Field name="grp8">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp8' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp8')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp9">GRP9</Label>
                      <Field name="grp9">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp9' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp9')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp10">GRP10</Label>
                      <Field name="grp10">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp10' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp10')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp11">GRP11</Label>
                      <Field name="grp11">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp11' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp11')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp12">GRP12</Label>
                      <Field name="grp12">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp12' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp12')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp13">GRP13</Label>
                      <Field name="grp13">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp13' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp13')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp14">GRP14</Label>
                      <Field name="grp14">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp14' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp14')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp15">GRP15</Label>
                      <Field name="grp15">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp15' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp15')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="grp16">GRP16</Label>
                      <Field name="grp16">
                        {({ input, meta }) => (
                          <div>
                            {
                              selector.type === 'grp16' ?
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    type="select"
                                    // placeholder="Nombre de la posición"
                                    valid={!meta.error && meta.modified && meta.touched}
                                    invalid={meta.error && meta.touched}
                                  >
                                    <option key="0" value="0">Seleccione una opción</option>
                                    {
                                      selector.options.map((row, key) => {
                                        return (
                                          <option key={key} value={row}>{row}</option>
                                        )
                                      })
                                    }
                                  </Input>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={onEdit}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-edit" />
                                      </span></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                :
                                <InputGroup className="input-group-merge">
                                  <Input
                                    {...input}
                                    className="m-0"
                                    type="text"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    placeholder="Escriba aquí el grupo."
                                    invalid={meta.error && meta.touched}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" size="sm" outline onClick={() => onSearch('grp16')}>
                                      <span className="btn-inner--icon">
                                        <i className="fas fa-search" />
                                      </span></Button>
                                  </InputGroupAddon>
                                  {
                                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                  }
                                </InputGroup>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row align-items-center">
                  <Col className="mb-1" sm="12">
                    <Button
                      disabled={submitting || uploading}
                      className="btn-icon"
                      block
                      color="success"
                      type="submit"
                      onClick={handleSubmit || validating}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Guardar</span>
                    </Button>
                  </Col>
                </div>
              </FormRS>
            </Spin>
          )}
        />
      </Drawer>
    </>
  );
};

AddServiceDrawer.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AddServiceDrawer;