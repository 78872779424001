import React, { useRef, useEffect, useState } from 'react';
import { Container } from "reactstrap";
import UserAccess from 'components/Shared/UserAccess/UserAccess.jsx';
import ModalUserAccess from 'components/Shared/UserAccess/ModalUserAccess.jsx';
import { useDispatch } from "react-redux";
import { getOtherApprovers, insertOtherApprovers, deleteOtherApprovers, getUserSign } from "actions/ASActions"
import { Spin } from 'antd';
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import NotificationAlert from "react-notification-alert";
import { desencrypt } from 'helpers/desencrypt.jsx';

const AccessOthersApprovers = () => {
    const dispatch = useDispatch();
    const notifyEl = useRef(null);

    const [filtersByTable, setFiltersByTable] = useState([]);
    const [loading, setLoad] = useState(true);
    const [modalUserAccess, getModalUserAccess] = useState(false);
    const [newInfo, setNewInfo] = useState([]);
    const [users, setUser] = useState({
        approvers: [],
        users: []
    }
    )
    const [page, setPage] = useState(1);

    const tableAdmin = [
        { label: "Usuario", value: "user", filter: true, isTable: true },
        { label: "Nombre", value: "name", isTable: true, filter: true }
    ]
    const modal = [
        { label: "Nombre", value: "name", insertValue: "userId", colWidth: "12", type: "select", options: users.users },

    ]
    //Carga la data inicial de las vistas
    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetInfoTables();
            await hangleOnGetUserSign();
        };
        getInfo();
    }, []);
    //Trae la informacion de los endpoints de las tablas y los selects
    const handleOnGetInfoTables = async () => {
        setLoad(true);
        dispatch(getOtherApprovers()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setUser((prevState) => ({
                    ...prevState,
                    approvers: payload.data.payload.approvers
                }));
            }
            setLoad(false);
        });
    };
    //Trae los usuarios de digital_sign para agregarlos como aprobadores 
    const hangleOnGetUserSign = async () =>{
        setLoad(true);
        dispatch(getUserSign()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let data = desencrypt(payload.data.payload.users);
                data = JSON.parse(data)
                setUser((prevState) => ({
                    ...prevState,
                    users: data
                }));
            }
            setLoad(false);
        });
    }
    //Aplica los filtros a la tabla principal
    const renderRowsByFiltersUsers = (rows) => {

        if (Object.keys(filtersByTable).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filtersByTable);
            for (const element of filterKeys) {
                const valueFiltered = filtersByTable[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    //Guarda los filtros en un state
    const handleOnfilterDinamic = (constant, e) => {
        setFiltersByTable(prevState => ({
            ...prevState,
            [constant]: `${e}`
        }))
        setPage(1);
    }
    //Abrir modal y cerrar modal de creación
    const viewModalNewUser = () => {
        getModalUserAccess(!modalUserAccess)
    }
    //Cambia los campos en el modal de creación
    const handleOnChangeData = (constant, value) => {
        setNewInfo(prevState => ({
            ...prevState,
            [constant]: value
        }))
    }

    //Insertar nuevo rol de BS a usuario
    const insertNewRoleUser = async () => {
        setLoad(true);
        await dispatch(insertOtherApprovers({ newInfo })).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("warning", "Atención", payload.response.data.payload.message);

            } else {
                notify("success", "Exito", payload.data.payload.message);
                handleOnGetInfoTables();
            }
            getModalUserAccess(!modalUserAccess)
            setNewInfo("");
            setLoad(false);
        });
    }
    //Inactiva el role del usuario seleccionado
    const handleOnInactiveUserRole = (item) => {
        setLoad(true);
        dispatch(deleteOtherApprovers({ item })).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("warning", "Atención", payload.response.data.payload.message);
            } else {
                notify("success", "Exito", payload.data.payload.message);
                handleOnGetInfoTables();
            }

            setLoad(false);
        });
    }

    //Notificacion de alertas
    const notify = (type, title, message) => {
        try {
            let options = {
                place: "br",
                message: (
                    <div className="alert-text">
                        <span className="alert-title" data-notify="title">
                            {title}
                        </span>
                        <span data-notify="message">{message}</span>
                    </div>
                ),
                type: type,
                icon: "ni ni-bell-55",
                autoDismiss: 7,
            };
            notifyEl.current.notificationAlert(options);
        } catch (error) { }
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notifyEl} />
            </div>
            <AdminHeader name="Administración de Accesos" parentName="Aprobadores salariales" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    <UserAccess
                        titleTable={"Otros Aprobadores"}
                        textButtonAdd ={"Agregar aprobador"}
                        colums={tableAdmin}
                        rows={renderRowsByFiltersUsers(users.approvers)}
                        filters={handleOnfilterDinamic}
                        handleOnViewModal={viewModalNewUser}
                        handleOnInactiveUserRole={handleOnInactiveUserRole}
                        page={page}
                        setPage={setPage}
                        isDelete={true}
                        isEdit={false}
                    />
                    <ModalUserAccess
                        handleOnViewModal={viewModalNewUser}
                        data={modal}
                        openModal={modalUserAccess}
                        textModal={"Agregar Aprobador"}
                        changeData={handleOnChangeData}
                        saveButton={insertNewRoleUser}
                    />
                </Spin>
            </Container>
        </div>
    );
};


export default AccessOthersApprovers;