/*
=========================================================
*Hours report: action button in CATS Record table
=========================================================

*Description.
This is the component of the button used to delete a Cats report
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import { FaRegTrashAlt } from "react-icons/fa";
import { Button } from "reactstrap";

function ActionButton({ onDelete, report, status }) {
  return (
    <div
      style={{
        whiteSpace: "normal",
        textAlign: "justify",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <div>
        <Button onClick={() => onDelete(report.ID_REPORTE)} disabled={status}>
          <FaRegTrashAlt
            style={{ color: status ? "grey" : "red", fontSize: "15px" }}
          />
        </Button>
      </div>
    </div>
  );
}

ActionButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
};
export default ActionButton;
