// react plugin used to create Transfer List
import { Transfer } from 'antd';
import 'antd/dist/antd.css';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Progress, Row } from "reactstrap";
// core selectors
import { getGeneralData } from 'selectors/newPosition';

const CreateRelations = props => {

  let {
    data,
    orgUnitKeys,
    cecoKeys,
    personalAreasKeys,
    directionsKeys,
    bussinessLinesKeys,
    accessKeys,
    title,
    subtitle,
    positions,
    valid,
    onBack,
    onSearch,
    onCancel,
    onChange,
    onCreate
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.idPosition || values.idPosition === "0")
      errors.idPosition = "Debe seleccionar una posición";
    if (Object.keys(data).length) {
      if (!values.idOrganizacionalUnit || values.idOrganizacionalUnit === "0")
        errors.idOrganizacionalUnit = "Debes seleccionar como minimo una unidad organizacional.";
    }
    return errors;
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={onSearch}
            validate={validate}
            render={({ handleSubmit, values, submitting, validating, valid }) => (
              <FormRS onSubmit={handleSubmit}>
                <div className="form-row align-items-center">
                  <Col className="mb-3" sm="12" md="6">
                    <FormGroup>
                      <Label for="idPosition">Posición</Label>
                      <Field name="idPosition">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              placeholder="Posición"
                              invalid={meta.error && meta.touched}
                              disabled={Object.keys(data).length}
                            >
                              <option key="0" value="0">Seleccione una posición</option>
                              {
                                positions.map((row, key) => {
                                  return (
                                    <option key={key} value={row.idPosition}>{row.position}</option>
                                  )
                                })
                              }
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
                {
                  submitting ?
                    <Progress animated max="100" value="100" color="info" />
                    :
                    null
                }
                {
                  !Object.keys(data).length ?
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="3">
                        <Button
                          disabled={submitting}
                          className="btn-icon"
                          block
                          color="info"
                          type="button"
                          onClick={onBack}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-arrow-left" />
                          </span>
                          <span className="btn-inner--text">Volver</span>
                        </Button>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <Button
                          disabled={!valid || submitting || validating}
                          className="btn-icon"
                          block
                          color="info"
                          type="submit"
                          onClick={handleSubmit || validating}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-search" />
                          </span>
                          <span className="btn-inner--text">Buscar</span>
                        </Button>
                      </Col>
                    </div> : null}
              </FormRS>
            )}
          />
          {
            Object.keys(data).length ?
              <Form
                onSubmit={onCreate}
                render={({ handleSubmit, submitting }) => (
                  <FormRS onSubmit={handleSubmit}>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="6">
                        <FormGroup>
                          <Label for="idOrganizacionalUnit">Unidad Organizacional</Label>
                          <Transfer
                            titles={['Disponibles', 'Utilizados']}
                            dataSource={data.organizationalUnits}
                            targetKeys={orgUnitKeys}
                            showSearch
                            render={item => item.organizationalUnit}
                            rowHeight={35}
                            locale={{ searchPlaceholder: 'Buscar...' }}
                            onChange={onChange('organizationalUnit')}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="6">
                        <FormGroup>
                          <Label for="idCeco">Centro de Costo</Label>
                          <Transfer
                            titles={['Disponibles', 'Utilizados']}
                            dataSource={data.cecos}
                            targetKeys={cecoKeys}
                            showSearch
                            render={item => item.ceco}
                            rowHeight={113}
                            locale={{ searchPlaceholder: 'Buscar...' }}
                            onChange={onChange('ceco')}
                          />
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="6">
                        <FormGroup>
                          <Label for="idPersonalArea">Áreas Personales</Label>
                          <Transfer
                            titles={['Disponibles', 'Utilizados']}
                            dataSource={data.personalAreas}
                            targetKeys={personalAreasKeys}
                            showSearch
                            render={item => item.personalArea}
                            rowHeight={7}
                            locale={{ searchPlaceholder: 'Buscar...' }}
                            onChange={onChange('personalArea')}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="6">
                        <FormGroup>
                          <Label for="idDirection">Dirección</Label>
                          <Transfer
                            titles={['Disponibles', 'Utilizados']}
                            dataSource={data.directions}
                            targetKeys={directionsKeys}
                            showSearch
                            render={item => item.direction}
                            rowHeight={7}
                            locale={{ searchPlaceholder: 'Buscar...' }}
                            onChange={onChange('direction')}
                          />
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="6">
                        <FormGroup>
                          <Label for="idBussinessLine">Líneas de Negocio</Label>
                          <Transfer
                            titles={['Disponibles', 'Utilizados']}
                            dataSource={data.bussinessLines}
                            targetKeys={bussinessLinesKeys}
                            showSearch
                            render={item => item.bussinessLine}
                            rowHeight={11}
                            locale={{ searchPlaceholder: 'Buscar...' }}
                            onChange={onChange('bussinessLine')}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-3" sm="12" md="6">
                        <FormGroup>
                          <Label for="idAccess">Administrativo / Productivo</Label>
                          <Transfer
                            titles={['Disponibles', 'Utilizados']}
                            dataSource={data.access}
                            targetKeys={accessKeys}
                            showSearch
                            render={item => item.access}
                            rowHeight={7}
                            locale={{ searchPlaceholder: 'Buscar...' }}
                            onChange={onChange('access')}
                          />
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-3" sm="12" md="3">
                        <Button
                          className="btn-icon"
                          block
                          color="info"
                          type="button"
                          onClick={onCancel}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-window-close" />
                          </span>
                          <span className="btn-inner--text">Cancelar</span>
                        </Button>
                      </Col>
                      <Col className="mb-3" sm="12" md="3">
                        <Button
                          disabled={!valid || submitting}
                          className="btn-icon"
                          block
                          color="info"
                          type="submit"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-save" />
                          </span>
                          <span className="btn-inner--text">Guardar relación</span>
                        </Button>
                      </Col>
                    </div>
                  </FormRS>
                )}
              /> : null}
        </CardBody>
      </Card>
    </>
  );
};

CreateRelations.defaultProps = {
  data: {},
}

CreateRelations.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  positions: PropTypes.array.isRequired,
  onBack: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  data: getGeneralData(state),
});

export default withRouter(connect(mapStateToProps, null)(CreateRelations));