import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getOptionsData = createAction(constants.HC_GET_OPTIONS, method.get(urls.HCMHIRING.getOptions));
export const getDataRows = createAction(constants.HC_GET_DATA, body => method.get(urls.HCMHIRING.getRows, body)());
export const newRequest = createAction(constants.HC_NEW_REQUEST, body => method.post(urls.HCMHIRING.newRequest, body)());
export const updateRequest = createAction(constants.HC_UPDATE_REQUEST, body => method.post(urls.HCMHIRING.updateRequest, body)());
export const getCandidateInfo = createAction(constants.HC_GET_CANDIDATE_INFO, (id) => method.get(`${urls.HCMHIRING.getCandidateInfo}/${id}`)());
export const getPositionInfo = createAction(constants.HC_GET_POSITION_INFO, (position) => method.get(`${urls.HCMHIRING.getPositionInfo}/${position}`)());
export const changeStatusBot = createAction(constants.HC_CHANGE_STATUS_BOT, body => method.post(urls.HCMHIRING.changeStatusBot, body)());
export const deleteFile = createAction(constants.HC_DELETE_FILE, body => method.post(urls.HCMHIRING.deleteFile, body)());
export const deleteAllFiles = createAction(constants.HC_DELETE_ALL_FILE, body => method.post(urls.HCMHIRING.deleteAllFiles, body)());