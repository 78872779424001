/*!

=========================================================
*Autopp - BrandsMaintenance
=========================================================

*Componente para agregar-modificar-eliminar los brands
de cada uno de los formularions LDRS asociados.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de React
import React, { useState, useEffect, useContext } from 'react';
import {
    PaginationItem,
    PaginationLink,
    CardFooter,
    Pagination,
    FormGroup,
    CardBody,
    Tooltip,
    Button,
    Table,
    Input,
    Card,
    Row,
    Col,
} from "reactstrap";

import { Spin } from 'antd';

//Import de contexto para extraer states globales.
import { UserContext } from "../../SharedComponents/UserContext";

// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

//Dispatch al api
import { useDispatch } from 'react-redux';

// react MultiSelect
import { MultiSelect } from "react-multi-select-component";

// imports de actions
import {
    deleteBrand,
    updateBrand

} from 'actions/autoppLdrs';

//Modal de new brand
import { ModalNewBrand } from './ModalNewBrand';



export const BrandsMaintenance = ({ options }) => {

    //Dispatch al API.
    const dispatch = useDispatch();



    //#region States Globales
    const {
        setRefreshOptions,
        setShowComponent,
        setSpinnerTable,
        showComponent,
        spinnerTable,
    } = useContext(UserContext);
    //#endregion


    // console.log("CreateLDR")

    //State para guardar la nueva información de la fila brand a editar.
    const [newInfoEdited, setNewInfoEdited] = useState({})

    //#region Notificaciones

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    //#endregion

    //Rows de la tabla 
    const [rowsRequests, setRowsRequests] = useState([])


    //Función para verificar si un array es ascendente.
    function isAscending(arr) {
        for (let i = 1; i < arr.length; i++) {
            if (arr[i] < arr[i - 1]) {
                return false; // No está en orden ascendente
            }
        }
        return true; // Está en orden ascendente
    }

    //Efecto para inicializar los rows de la tabla
    useEffect(() => {
        if (Object.values(options?.autoppCareOptions).length > 0) {

            if (isAscending((options?.autoppCareOptions?.brand).map(item => item.value))) {
                setRowsRequests(
                    (options?.autoppCareOptions?.brand).reverse()
                )
            } else {
                setRowsRequests(
                    options?.autoppCareOptions?.brand)
            }
        }
    }, [options])




    //Función para eliminar un formulario LDR.
    const deleteBrandF = (row) => {


        MySwal.fire({

            type: 'warning',
            title: `Eliminar Brand`,
            html:

                `<h2>¿Está seguro que desea eliminar la brand ${row.label}?</h2>`,

            confirmButtonText: 'Si, eliminar',
            confirmButtonColor: '#f5365c',//#2DCE89
            cancelButtonText: 'No, cancelar',
            showCancelButton: true,

            preConfirm: () => {

                dispatch(deleteBrand({ idBrand: row.value })).then((resp) => {

                    const { payload } = resp;
                    if (payload.status === 200) {
                        Toast.fire({
                            title: "Atención",
                            html: `¡Brand eliminada con éxito!`,
                            type: 'success'
                        });

                        //Refresca las opciones y pone el spinner
                        setRefreshOptions(true);



                        setSpinnerTable(true);



                    } else {

                        Toast.fire({
                            title: "Atención",
                            html: payload.message,
                            type: 'warning'
                        });
                    };
                })


            },

        })


    }

    //Valida si no se llenaron campos en este modal
    const validateFieldsModal = (newInfoE) => {
        let result = Object.values(newInfoE).filter(field => field == "")

        if (result.length > 0) {
            Toast.fire({
                title: "Atención",
                html: "Existen campos vacíos en la fila.",
                type: 'warning'
            });
            return false;
        }

        return true;

    }

    //Función para actualizar un brand de los LDRS.
    const handleSaveBrand = (row, newInfoE) => {

        if (validateFieldsModal(newInfoE)) {

            MySwal.fire({

                type: 'success',
                title: `Actualizar Brand`,
                html:

                    `<h2>¿Está seguro que desea actualizar la brand ${row.label}?</h2>`,

                confirmButtonText: 'Si, actualizar',
                confirmButtonColor: '#2DCE89',//#2DCE89
                cancelButtonText: 'No, cancelar',
                showCancelButton: true,

                preConfirm: () => {

                    dispatch(updateBrand({ idBrand: row.value, info: newInfoE })).then((resp) => {

                        const { payload } = resp;
                        if (payload.status === 200) {
                            Toast.fire({
                                title: "Atención",
                                html: `Brand actualizada con éxito!`,
                                type: 'success'
                            });

                            //Refresca las opciones y pone el spinner
                            setRefreshOptions(true);

                            setSpinnerTable(true);

                            //Para que salga de la row a editar.
                            setRowToEdit(0);



                        } else {

                            Toast.fire({
                                title: "Atención",
                                html: payload.message,
                                type: 'warning'
                            });
                        };
                    })


                },

            })

        }

    }

    //Función para editar una fila de brand
    const handleEditBrand = (row) => {
        setRowToEdit(row.value)
    }


    //Función para cancelar un guardado debrand
    const handleCancellSave = () => {
        //Refresca que no hayan rows editables
        setRowToEdit(0);
    }



    //#region botones de la tabla MainTable por cada fila de la tabla que dependen del status de la solicitud
    const actions = [
        {
            color: "default",
            icon: "fa fa-edit",
            fun: handleEditBrand,
            title: "Editar documento LDR",
            id: "editLDR",
            haveException: false

        },
        {
            color: "danger",
            icon: "fa fa-trash",
            fun: deleteBrandF,
            title: "Eliminar formulario",
            id: "deleteLDR",
            haveException: true
        },
        {
            color: "success",
            icon: "fa fa-save",
            fun: handleSaveBrand,
            title: "Guardar Brand",
            id: "saveBrand",
            haveException: true
        },
        {
            color: "warning",
            icon: "fa fa-times",
            fun: handleCancellSave,
            title: "Cancelar",
            id: "cancellSave",
            haveException: true
        },
    ];
    //#endregion


    //Columnas para el multicolumn y si la seleccionan se agrega al columnsSelected
    const optionsToSelect = [

        { label: "Acciones", value: "actions", buttons: actions, disabled: true, width: 40, editable: false },
        { label: "Id", value: "value", width: 20, editable: false },
        { label: "Brand", value: "brand", width: 30, editable: true, fieldType: "input", exception: false },
        { label: "Ciclo de Ventas", value: "cycle", width: 50, editable: true, fieldType: "select", options: "cycle", exception: true },
        { label: "Creado por", value: "createdBy", width: 30, editable: false },
        { label: "Creado en", value: "createdAt", width: 30, editable: false },

    ];


    //Columnas seleccionadas por defecto en la tabla.
    const [columnsSelected, setColumnsSelected] = useState(
        [

            { label: "Acciones", value: "actions", buttons: actions, disabled: true, width: 40, editable: false },
            { label: "Id", value: "value", width: 20, editable: false },
            { label: "Brand", value: "brand", width: 30, editable: true, fieldType: "input", exception: false },
            { label: "Ciclo de Ventas", value: "cycle", width: 50, editable: true, fieldType: "select", options: "cycle", exception: true },
        ]
    );

    //Para refrescar la tabla
    const handleRefreshTable = () => {
        setSpinnerTable(true)
        setRefreshOptions(true)
    }


    //Filtros request tables
    const [filters, setFilters] = useState([]);

    const renderRowsByFilters = (rows) => {

        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);
            for (const element of filterKeys) {
                const valueFiltered = filters[element].toString().toLowerCase();
                rows = rows.filter((item) => item[element].toString().toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }

    //#region States Locales

    const [sizePerPage, setSizePerPage] = useState(5)
    const rows = renderRowsByFilters(rowsRequests);



    //state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);

    //console.log(rows);

    //#endregion

    //#region Métodos
    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //#endregion

    //#region Handles 

    //Establece el número de filas por pagina 
    const handleOnSetPage = (page) => {
        setPage(page);
    };

    const handleSetNumPag = (e) => {
        setPage(1);
        const value = e.target.value;
        setSizePerPage(value);
    }

    //Capturar los valores de los inputs de los filtros
    const handleOnfilterDynamic = (constant, e) => {
        console.log(constant, e)
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }

    //#endregion

    //Handle para volver al inicio de la vista.
    const handleReturnToStart = () => {
        setShowComponent({ ...showComponent, brandsMaintenance: false })
    }

    //Es el id de la fila a editar.
    const [rowToEdit, setRowToEdit] = useState(0);

    const [text, settext] = useState(
        {
            title: "Mantenimiento de Brands",
            subtitle: "Creación/Edición/Eliminación de brands"

        })

    const { title, subtitle } = text;




    //Diseñado para cuando cambie la row a editar, que asigne la información de los campos de la fila editables al state newInfoEdited, 
    //para almacenar ahí la info y posterior al dar el botón guardar que mande esa info al API.
    useEffect(() => {
        if (rowToEdit != 0) //Si es 0 no fila seleccionada. 
        {

            let finalJson = {};

            //Averiguar los compos de la fila seleccionada
            let rowSelected = rows.filter((row, i) => row.value == rowToEdit)[0];

            //Averigua los campos editables de la fila actual
            let columnsEditables = columnsSelected.filter(column => column.editable == true);


            columnsEditables.map(column => {
                //Valor del campo editable
                // debugger;
                let valueToAsign = "";

                valueToAsign = rowSelected[column.value]

                //Asigna el campo al array.
                finalJson = { ...finalJson, [column.value]: valueToAsign }
            })


            if (Object.keys(finalJson).length > 0) {
                setNewInfoEdited(finalJson);
            }

        }
    }, [rowToEdit])

    //Handle para asignar las nuevas filas editables en el state newInfo.
    const handleInfoEdited = (id, e) => {

        setNewInfoEdited(prevState => ({
            ...prevState,
            [id]: e
        }));

    }

    //State para abrir el modal
    const [openModal, setOpenModal] = useState(false);

    //Handle para abrir o cerrar el modal
    const handleOpenModal = () => {
        setOpenModal(!openModal)
    }

    return (
        <>
            <ModalNewBrand
                options={options}
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleRefreshTable={handleRefreshTable}
                rows={rows}

            >

            </ModalNewBrand>


            <Card>

                <CardBody>

                    <Row>
                        <Col md="auto" xl="auto" className="text-md-left">
                            <div className="ml-3">
                                <h6 className="surtitle">{subtitle}</h6>
                                <h5 className="h3 mb-0">{title}</h5>
                            </div>

                        </Col>
                        <Col md="auto" xl="auto" className="text-md-left">
                            <MultiSelect
                                options={optionsToSelect}
                                value={columnsSelected}
                                onChange={setColumnsSelected}
                                labelledBy="Select"
                            />


                        </Col>
                        <Col md="auto" xl="auto" className="text-md-left">

                            <Button
                                className="btn-icon-only"
                                outline
                                color="info"
                                type="button"
                                title={"Refrescar tabla"}
                                id={"buttonRefresh"}
                                onClick={(e) => handleRefreshTable()}

                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-sync-alt" />
                                </span>
                            </Button>

                            <Tooltip target={"buttonRefresh"} placement="top">
                                {"Refrescar tabla"}
                            </Tooltip>
                        </Col>

                        <Col md="auto" xl="auto" className="ml-md-auto text-md-right">
                            <Button color="default"
                                onClick={() => handleOpenModal()}
                            >

                                <span className="btn-inner--icon mr-1">
                                    <i className={"fa fa-plus"} />
                                </span>
                                <span className="btn-inner--text"> Nuevo Brand </span>

                            </Button>

                            <Button color="success"
                                onClick={() => handleReturnToStart()}
                            >

                                <span className="btn-inner--icon mr-1">
                                    <i className={"fa fa-reply"} />
                                </span>
                                <span className="btn-inner--text"> Volver </span>

                            </Button>
                        </Col>

                    </Row>

                    <Spin size="large" spinning={spinnerTable}>
                        <Table
                            className="align-items-center table-flush mt-3"
                            responsive
                            striped={true}
                            hover={true}

                        >
                            <thead className="thead-light" style={{ height: '10px' }}>

                                <tr>
                                    {columnsSelected.map((col, key) => (


                                        col.value != "actions" ?

                                            <th className="pb-0 pt-2" style={{ height: '10px' }}>
                                                <FormGroup key={key}>
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${col.label}:`}
                                                    </label>
                                                    <Input
                                                        id={col.id}
                                                        key={key}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder={`Buscar ${col.label}...`}
                                                        onChange={(e) => handleOnfilterDynamic(col.value, e.target.value)}
                                                    />
                                                </FormGroup>
                                            </th>
                                            :
                                            <th className="pb-0 pt-2" style={{ height: '10px' }}>
                                                {/* Si es actions que no imprima la columna */}
                                            </th>



                                    ))
                                    }
                                </tr>
                            </thead>


                            <tbody className="list">
                                {
                                    rows && rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (

                                        <tr key={"row" + key} className="text-center p-0" style={{ backgroundColor: row.value != rowToEdit ? '#FFFFFF' : '#d0f7fd' }}
                                        >
                                            {
                                                row && row != undefined && columnsSelected.map((col, key) => (

                                                    col.value === "actions" ?
                                                        <th key={key} className="" >



                                                            {col.buttons.map((butt, key3) => {
                                                                return (


                                                                    ((butt.haveException == false && row.value != rowToEdit) ||
                                                                        (butt.id == "deleteLDR" && row["quantityLDRFoms"] == 0 && row.value != rowToEdit) || //Excepción para el botón eliminar.
                                                                        (butt.id == "saveBrand" && row.value == rowToEdit) ||
                                                                        (butt.id == "cancellSave" && row.value == rowToEdit)
                                                                    ) &&
                                                                    <>
                                                                        < Button
                                                                            className="btn-icon-only"
                                                                            outline
                                                                            color={butt.color}
                                                                            type="button"
                                                                            key={"button" + key3}
                                                                            onClick={(e) => butt.fun(row, newInfoEdited)}
                                                                            title={butt.title}
                                                                            id={butt.id}
                                                                        >
                                                                            <span className="btn-inner--icon">
                                                                                <i className={butt.icon} />
                                                                            </span>
                                                                        </Button >

                                                                        <Tooltip key={"Tooltip" + key3} target={butt.id}>{butt.title}</Tooltip>
                                                                    </>


                                                                )
                                                            })
                                                            }




                                                            {row[col.value]}

                                                        </th>
                                                        :
                                                        <th key={key} className="p-0">

                                                            {row.value == rowToEdit && col.editable == true ?
                                                                <Row className="d-flex justify-content-center p-0">

                                                                    <Col xs="12" sm="6" className="p-0">
                                                                        <Input
                                                                            id={"editableField"}
                                                                            className="form-control text-dark mb-3 ml--3 mr--6 mt-3 "
                                                                            type={col.fieldType}
                                                                            value={newInfoEdited[col.value]}
                                                                            options={col.fieldType == "select" && options?.autoppCareOptions[col.value]}
                                                                            disabled={col.exception == true && row["quantityLDRFoms"] > 0}
                                                                            onChange={(e) => handleInfoEdited(col.value, e.target.value)}
                                                                        >
                                                                            <option id=""></option>
                                                                            {
                                                                                //Estas son las opciones locales

                                                                                col.fieldType == "select" && options?.autoppCareOptions[col.value].map((value) => {
                                                                                    return (
                                                                                        <option id={value.value}>{value.label}</option>
                                                                                    );
                                                                                })

                                                                            }
                                                                        </Input>
                                                                    </Col>
                                                                </Row>

                                                                :
                                                                row[col.value]}


                                                        </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </Table>
                    </Spin>
                    <CardFooter>

                        <Row className="align-items-center ">
                            <Col xs='12' md='6' className="p-1 ">
                                <Row className="justify-content-start">
                                    <Col xs='12' md='6'>
                                        <span className='pagination mb-0'>
                                            Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                        </span>
                                    </Col>
                                    <Col xs='12' md='2'>
                                        <Input type="select"
                                            onChange={(e) => handleSetNumPag(e)}>
                                            <option>5</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs='12' md='6' className="p-1 ">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {
                                        renderPaginations()
                                    }
                                    <PaginationItem className={page === paginations ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>

                    </CardFooter>

                </CardBody>
            </Card >
        </>
    )

}

