// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";

const CardFilters = (props) => {
  const {
    title,
    subtitle,
    platform,
    family,
    category,
    classify,
    redundancy,
    modelo,
    description,
    filters,
    onClear,
    onChangeFilters,
    onSearch,
  } = props;

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col sm="12" md="10">
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>
            <Col
              xs="12"
              md="2"
              className="p-1"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-icon"
                color="danger"
                size="sm"
                block
                type="button"
                onClick={onClear}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text">Limpiar</span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="text-right" xs="12" md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="platform">
                  Plataforma
                </label>
                <Select
                  className="basic-single"
                  id="platform"
                  type="select"
                  placeholder={"Seleccione una plataforma"}
                  isSearchable
                  options={filters.platforms}
                  value={platform}
                  onChange={(e) => onChangeFilters("platformSelected", e)}
                />
              </FormGroup>
            </Col>
            <Col className="text-right" xs="12" md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="familiy">
                  Familia
                </label>
                <Select
                  className="basic-single"
                  id="familiy"
                  type="select"
                  placeholder={"Seleccione una familia"}
                  isSearchable
                  options={filters.families}
                  value={family}
                  onChange={(e) => onChangeFilters("familySelected", e)}
                />
              </FormGroup>
            </Col>
            <Col className="text-right" xs="12" md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="category">
                  Categoría
                </label>
                <Select
                  className="basic-single"
                  id="category"
                  type="select"
                  placeholder={"Seleccioné una categoría"}
                  isSearchable
                  options={filters.categories}
                  value={category}
                  onChange={(e) => onChangeFilters("categorySelected", e)}
                />
              </FormGroup>
            </Col>
            <Col className="text-right" xs="12" md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="model">
                  Modelo
                </label>
                <InputGroup className="input-group-merge">
                  <Input
                    id="model"
                    className="m-0"
                    style={{ height: "calc(2.25rem + 2px)" }}
                    placeholder="Ingrese un modelo"
                    type="text"
                    value={modelo}
                    onChange={(e) => onChangeFilters("model", e)}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="success"
                      size="sm"
                      outline
                      onClick={onSearch}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-search" />
                      </span>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="description">
                  Descripción
                </label>
                <InputGroup className="input-group-merge">
                  <Input
                    id="description"
                    className="m-0"
                    style={{ height: "calc(2.25rem + 2px)" }}
                    placeholder="Ingrese una descripción"
                    type="text"
                    value={description}
                    onChange={(e) => onChangeFilters("description", e)}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="success"
                      size="sm"
                      outline
                      onClick={onSearch}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-search" />
                      </span>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col className="text-right" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="redundancy">
                  Redundancia
                </label>
                <Select
                  className="basic-single"
                  id="redundancy"
                  type="select"
                  placeholder={"Seleccione la redundancia"}
                  isSearchable
                  options={filters.redundancy}
                  value={redundancy}
                  onChange={(e) => onChangeFilters("redundancySelected", e)}
                />
              </FormGroup>
            </Col>
            <Col className="text-right" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="classify">
                  Clasificación de la Parte
                </label>
                <Select
                  className="basic-single"
                  id="classify"
                  type="select"
                  placeholder={"Seleccione la clasificación"}
                  isSearchable
                  options={filters.classify}
                  value={classify}
                  onChange={(e) => onChangeFilters("classifySelected", e)}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

CardFilters.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
};

export default CardFilters;
