import { getContacts, updateEntity } from 'actions/panamaBids.jsx';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from 'react-redux';
// reactstrap components
import {
    Button, Col, Form, FormGroup, Input, Modal, ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
const EntitiesEditModal = ({
    toggle,
    options,
    setOptions,
    rows,
    setShowModal,
    setRealTable,
    editInfo,
    setEditInfo,
    Toast
}) => {
    const { editModal } = toggle
    const dispatch = useDispatch();
    //Hook para validar el boton de guardar
    const [validate, setValidate] = useState(0);
    //Hook para el spin del select de contactos
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");
    //useEffect que llama la funcion "handelOngetContacts" que trae los contactos cada vez que se cambia el cliente
    useEffect(() => {
        const getContacts = async () => {
            await handelOngetContacts();
        }
        getContacts();
    }, [editInfo.customer]);

    useEffect(() => {
        const getContacts = async () => {
            await handelOngetContacts();
        }
        getContacts();
    }, [options.contacts]);
    //Valida que algun campo no este vacio y deshabilita el boton de guardar
    useEffect(() => {
        let cont = 0
        if (Object.keys(editInfo)) {
            const items = Object.keys(editInfo);
            for (const item of items) {
                if (editInfo[item] === "") {
                    cont++;
                }
            }
        }
        if (cont !== 0) {
            setValidate(1);
        } else {
            setValidate(0);
        }
    }, [editInfo]);
    //Funcion para guardar el editar de la entidad
    const handleSendEditEntity = () => {
        dispatch(updateEntity({ info: editInfo })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setAlert("")
                handelOngetContacts();
                closeModal();
                setRealTable(2);
                Toast.fire({
                    title: "Atención",
                    html: "Se guardó correctamente",
                    type: 'success'
                });
            }
        }
        )
    };
    //Funcion para guardar la informacion del editInfo
    const handleChangeInfo = (constant, e) => {

        setEditInfo((prevState) => ({
            ...prevState,
            [constant]: e,
        }))

    };
    //Funcion para traer los contactos de un cliente a la hora de seleccionarlo
    const handelOngetContacts = async () => {
        setLoading(true);
        dispatch(getContacts({ info: editInfo.customer, type: "1" })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    contacts: payload.data.payload.response,
                }));
            };
            setLoading(false);
        });
    };
    //Cierra el modal de edit entidad 
    const closeModal = () => {
        setShowModal(prevState => ({
            ...prevState,
            editModal: !editModal,
        }))
        // setEditInfo([]);
    };
    const handleOnEditEntity = (e, row) => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Está seguro(a) que desea crear el candidato en SAP?"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleSendEditEntity(e, row)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, guardar"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    };
    return (
        <div>
            {alert}
            <Modal size="lg" isOpen={editModal} >
                <ModalHeader>
                    <Row>
                        <Col>
                            <h3 className="mb-0" >Datos Generales</h3>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    <Form noValidate className="formInfo needs-validation">
                        <Row>
                            {
                                rows.map((row, key) => (
                                    row.elementType === "Input" ?
                                        <Col xs={row.colWidth} key={key}>
                                            < FormGroup key={key}>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                >
                                                    {`${row.label}:`}
                                                </label>
                                                <Input
                                                    id={row.id}
                                                    key={key}
                                                    className="form-control"
                                                    required={true}
                                                    type={row.type}
                                                    placeholder={row.placeholder}
                                                    value={editInfo[row.id]}
                                                    onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                                                />
                                                <div className="invalid-feedback">
                                                    Este campo no puede estar vacío
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        : row.elementType === "Select" ?
                                            row.id === "contact" ?
                                                <Col xs={row.colWidth} key={key}>
                                                    <Spin size="small" spinning={loading}>
                                                        < FormGroup key={key}>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {`${row.label}:`}
                                                            </label>
                                                            <Input
                                                                id={row.id}
                                                                key={key}
                                                                required={true}
                                                                type={row.type}
                                                                className="basic-multi-select text-dark"
                                                                value={editInfo["contact"]}
                                                                onChange={(e) => handleChangeInfo(row.id, e.target.children[e.target.selectedIndex].id)}
                                                            >
                                                                {options[row.id].map((value, key) => {
                                                                    return (
                                                                        <option key={key} id={value.ID_CONTACT_CRM}>{`${value.FIRST_NAME} ${value.LAST_NAME}`}</option>
                                                                    );
                                                                })
                                                                }
                                                            </Input>
                                                            <div className="invalid-feedback">
                                                                Este campo no puede estar vacío
                                                            </div>
                                                        </FormGroup>
                                                    </Spin>
                                                </Col>
                                                :
                                                <Col xs={row.colWidth} key={key}>
                                                    < FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {`${row.label}:`}
                                                        </label>
                                                        <Input
                                                            id={row.id}
                                                            key={key}
                                                            required={true}
                                                            type={row.type}
                                                            className="basic-multi-select text-dark"
                                                            options={options[row.value]}
                                                            value={editInfo[row.id]}
                                                            onChange={(e) => handleChangeInfo(row.id, e.target.children[e.target.selectedIndex].value)}
                                                        >
                                                            {options[row.id].map((value, key) => {
                                                                return (
                                                                    <option key={key} id={value.Code}> {value.label}</option>
                                                                );
                                                            })
                                                            }
                                                        </Input>
                                                        <div className="invalid-feedback">
                                                            Este campo no puede estar vacío
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            : row.elementType === "typeahead" && (
                                                <Col xs={row.colWidth} key={key}>
                                                    <FormGroup key={key}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {`${row.label}:`}
                                                        </label>
                                                        <Typeahead
                                                            id={row.id}
                                                            required={true}
                                                            labelKey={option => `${option.name}`}
                                                            minLength={1}
                                                            onChange={(e) => handleChangeInfo(row.id, row.id === "customer" ? e[0] : e[0])}
                                                            options={options[row.id]}
                                                            placeholder="Escriba el nombre del cliente..."
                                                            value={editInfo[row.id]}
                                                            defaultInputValue={editInfo[row.id]}
                                                            renderMenuItemChildren={(item) => (
                                                                <div >
                                                                    {item.name}
                                                                    <div>
                                                                        {row.id === "customer" ?
                                                                            <small>ID: {item.id}</small>
                                                                            :
                                                                            <small>User: {item.user}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </FormGroup>
                                                    <div className="invalid-feedback">
                                                        Este campo no puede estar vacío
                                                    </div>
                                                </Col>
                                            )))
                            }
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            <Button
                                className="btn-icon"
                                color="danger"
                                block type="button"
                                onClick={() => closeModal()}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-times"></i>
                                </span>Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                disabled={validate !== 0 ? true : false}
                                className="btn-icon"
                                color="success"
                                block type="button"
                                onClick={() => handleOnEditEntity()}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="far fa-save"></i>
                                </span>Guardar
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </div >
    );
};


export default EntitiesEditModal;