import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findInitialValuesRequirimient = createAction(
  constants.GET_INITIAL_VALUES_REQUERIMENT,
  method.get(urls.CRITICALPARTS.getInitialValuesRequirimient)
);

export const findRequerimentsByUser = createAction(
  constants.GET_ALL_REQUIREMENTS_BY_USER,
  method.get(urls.CRITICALPARTS.getAllRequerimentByUser)
);

export const createRequirement = createAction(
  constants.CREATE_REQUIREMENT,
  (body) => method.post(urls.CRITICALPARTS.createRequirement, body)()
);

export const findValuesAndEquipmentsByRequirements = createAction(
  constants.GET_VALUES_AND_EQUIPMENTS_BY_REQUIREMENT,
  (id, body) =>
    method.post(
      `${urls.CRITICALPARTS.getValuesAndEquipmentsByRequirement}/${id}`,
      body
    )()
);

export const findCriticalParts = createAction(
  constants.GET_ALL_CRITICAL_PARTS,
  (page, sizePerPage, body) =>
    method.post(
      `${urls.CRITICALPARTS.getCriticalParts}/${page}/${sizePerPage}`,
      body
    )()
);

export const findFilteredModels = createAction(
  constants.GET_ALL_DEBUGGED_MODELS,
  (page, sizePerPage) =>
    method.get(
      `${urls.CRITICALPARTS.getFilteredModels}/${page}/${sizePerPage}`
    )()
);

export const createCriticalPart = createAction(
  constants.CREATE_CRITICAL_PART,
  (body) => method.post(urls.CRITICALPARTS.createCriticalParts, body)()
);

export const createDebuggedModel = createAction(
  constants.CREATE_DEBUGGED_MODEL,
  (body) => method.post(urls.CRITICALPARTS.createModelFilter, body)()
);

export const updateCriticalPart = createAction(
  constants.UPDATE_CRITICAL_PART,
  (id, body) =>
    method.put(`${urls.CRITICALPARTS.updateCriticalPart}/${id}`, body)()
);

export const deleteCriticalPart = createAction(
  constants.DELETE_CRITICAL_PART,
  (id) => method.put(`${urls.CRITICALPARTS.deleteCriticalPart}/${id}`)()
);

export const deleteDebuggedModel = createAction(
  constants.DELETE_DEBUGGED_MODEL,
  (id) => method.put(`${urls.CRITICALPARTS.deleteDebuggedModel}/${id}`)()
);

export const downloadCriticalParts = createAction(
  constants.DOWNLOAD_CRITICAL_PARTS,
  (body) =>
    method.downloadDataTables(
      `${urls.CRITICALPARTS.downloadCriticalParts}`,
      body
    )()
);
/**
 * Accion para limpiar la informacion
 */
export const clearData = createAction(constants.CLEAR_DATA);

export const findAllUsersMaintenance = createAction(
  "GET_ALL_USERS_MAINTENANCE_PARTS",
  method.get(urls.CRITICALPARTS.findAllUsersMaintenance)
);

export const validateUserInSystemByUsername = createAction(
  "VALIDATE_USER_INFO_IN_SYSTEM_BY_USER",
  (body) =>
    method.post(urls.CRITICALPARTS.validateUserInSystemByUsername, body)()
);

export const createUserInCriticalPartsAdminBySignID = createAction(
  "CREATE_USER_IN_CRITICAL_PARTS_ADMIN_BY_ID",
  (id) =>
    method.get(
      `${urls.CRITICALPARTS.createUserInCriticalPartsAdminBySignID}/${id}`
    )()
);

export const deactivateUserCriticalPartsByID = createAction(
  "DEACTIVATE_USER_CRITICAL_PARTS_BY_ID",
  (id) =>
    method.put(`${urls.CRITICALPARTS.deactivateUserCriticalPartsByID}/${id}`)()
);
