/*!

=========================================================
*Inbound
=========================================================

*FormGeneral: Outbound contiene el state llamado fields, 
el cual tiene los campospara llamar al FormGeneral y 
desplegar el formulario para Ingreso de Mercancía. 
Los dields también tienen funciones con acciones para 
cuando se toca un button. 

*ModalList: En casos específicos donde se necesita abrir 
un modal para seleccionar ots, docs, en base al warehouse, 
se utiliza ese componente reutilizable.

*Props: 
    -Stage: Es un estado, el cual crea etapas en las cuales
    ciertos campos del formulario aparece según la etapa.
    Ejemplo: Etapa 1, aparece los campos 1 y 2. Etapa 2, 
    aparece los campos 3 y 4.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React, { useContext, useState, useEffect } from 'react'

//dispatch hook
import { useDispatch } from 'react-redux';

//#region Icons
import {
    MdWarehouse,
    MdOutlineNumbers
}
    from "react-icons/md";

import { IoIosSend } from "react-icons/io";


import {
    BsChatRightText,
    BsCheckLg
} from "react-icons/bs";

import { RiBillLine } from "react-icons/ri";

import { FaArrowRight } from "react-icons/fa";

import {
    GrLocationPin,
    GrTransaction
} from "react-icons/gr";

import { SiEthiopianairlines } from "react-icons/si";

import { GiPositionMarker } from "react-icons/gi";

import { AiOutlineBlock } from "react-icons/ai";

import { TbPointFilled } from "react-icons/tb";

import { PiWarehouseFill } from "react-icons/pi";

import { CiDeliveryTruck } from "react-icons/ci";

import { GrNetwork } from "react-icons/gr";

import { BsFillRecord2Fill } from "react-icons/bs";

import { IoDocumentTextOutline } from "react-icons/io5";

import { MdOutlineComputer } from "react-icons/md";
//#endregion


//Imports de actions de consultas al API.
import {


    //CancelationInbound
    getItemInfoByOt,

    //Outbound
    getPositionsByDoc,
    sendRequestOutboundByDoc,
    getPositionsByOt,

    getReservationActiveByDoc,
    getMovementByReservation,

    getPositionsByReservation,
    sendReservationOutboundConfirmation,

    sendRequestOutboundByOt

} from 'actions/wms';

//Componentes reutilizables 
import { ModalList } from '../SharedComponents/ModalList';
import { FormGeneral } from '../SharedComponents/FormGeneral';
import { UserContext } from '../SharedComponents/UserContext';
import { encrypter } from 'helpers/desencrypt.jsx';


export const Outbound = ({ stage, setStage, selectedComponent, mainView }) => {

    const { modal, setPlant, strictedMaterialValidation, notValidateQuantity } = selectedComponent

    //Renombrar variables
    const idComponent = selectedComponent.id;
    const title = selectedComponent.label;

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isMobile,
        isComputer,
        options,
        notify,
        MySwal,
        warehouse,
        sapPassword
    } = useContext(UserContext);
    //#endregion

    //#region states locales
    const [openModal, setOpenModal] = useState(false)
    const [localSpinner, setLocalSpinner] = useState(false)
    const [buttonSpinner, setButtonSpinner] = useState(false)
    //State para guardar la información.
    const [newInfo, setNewInfo] = useState({})
    //La posición actual en el form.
    const [position, setPosition] = useState(0)
    //Lista de positions para validar al final.
    const [positions, setPositions] = useState([])
    //Lista de positions con formato de SAP para enviar al ws de commit a SAP.
    const [positionsSapFormat, setPositionsSapFormat] = useState([])

    //#endregion 

    //#region Efectos

    //Establecer datos iniciales.
    useEffect(() => {

        let movement = fields[idComponent].filter(field => field.movementOptions)
        movement = movement.length > 0 ? movement[0].movementOptions[0] : ""

        setNewInfo({
            plant: options.plants[0].label,
            ot: "",
            po: "",
            document: "",
            reservation: "",
            movement: movement
        })



    }, [idComponent])

    //Efecto excepción para cuando cargue el componente establezca la planta.
    useEffect(() => {
        if (options?.plants && setPlant == true) {
            //  handleOnChange("plant", options.plants[0].label, idComponent)
        }

    }, [options])

    //Efecto excepción para cuando la po sea vacía regrese al stage anterior.
    useEffect(() => {
        if ((idComponent == "po" && newInfo.po == "") ||
            (idComponent == "reservationOutboundAssets" && newInfo.document == "") ||
            (idComponent == "reservationOutboundOthers" && newInfo.reservation == "")
        ) {
            setStage(1)
            delete newInfo.numberSupplier;
            delete newInfo.nameSupplier;
        }
    }, [newInfo])

    //#endregion

    //#region ------------------------Funciones-----------------------------------------------

    //Refrescar el componente.
    const handleRefresh = (newInfo) => {
        setStage(1)
        setPositions([])
        setPosition(0)
        setNewInfo({ ...newInfo, po: "", document: "", ot: "", reservation: "" })
    }

    //Extraer la información de la cancelación por OT.
    const getItemInfoByOtE = (newInfo, warehouse) => {
        // setSpinner(true);
        // setLocalSpinner(true)
        setButtonSpinner(true)
        dispatch(getItemInfoByOt({ view: mainView + "." + idComponent, warehouse: warehouse, orderTransport: newInfo.ot, movement: newInfo.movement })).then((res) => {
            const { payload } = res;
            // setLocalSpinner(false);
            setButtonSpinner(false)


            if (res.payload.isAxiosError) {
                // Fallo
                console.log(payload)

                if (res.payload.response) {
                    const {
                        data: { payload }
                    } = res.payload.response;
                    notify("warning", "Atención", payload.message);

                } else {
                    notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexión con el servidor."
                    );
                }



            } else {
                // Éxito

                let apiInfo = { ...payload.data.payload };

                if (!payload.data.success) {
                    notify("warning", "Alerta", apiInfo.message)
                } else if (!apiInfo.material) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la cancelación desde SAP.")
                } else {
                    setNewInfo(Object.assign(newInfo, apiInfo))
                    setStage(2)
                }

            }
        })
    }

    //Método para extraer una OT.
    const handleSearchOt = (newInfo, positions, isComputer, warehouse, idComponent) => {
        if (newInfo.ot) {
            // alert("consultando a sap")
            if (idComponent == "cancelation") {
                getItemInfoByOtE(newInfo);
            }
        } else {
            setOpenModal(true)
        }
    }

    //Extraer el campo active.
    const getReservationActiveByDocE = (newInfo) => {
        // setSpinner(true);
        setLocalSpinner(true)
        // setButtonSpinner(true)
        dispatch(getReservationActiveByDoc({ view: mainView + "." + idComponent, document: newInfo.document })).then((res) => {
            const { payload } = res;
            setLocalSpinner(false);
            // setButtonSpinner(false)


            if (res.payload.isAxiosError) {
                // Fallo
                console.log(payload)

                if (res.payload.response) {
                    const {
                        data: { payload }
                    } = res.payload.response;
                    notify("warning", "Atención", payload.message);

                } else {
                    notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexión con el servidor."
                    );
                }



            } else {
                // Éxito

                let apiInfo = { ...payload.data.payload };

                if (!payload.data.success) {
                    notify("warning", "Alerta", apiInfo.message)
                } else if (!apiInfo.asset) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la cancelación desde SAP.")
                } else {
                    setNewInfo(Object.assign(newInfo, apiInfo))
                    setStage(2)
                }

            }
        })
    }

    const getMovementByReservationE = (newInfo) => {
        // setSpinner(true);
        setLocalSpinner(true)
        // setButtonSpinner(true)
        dispatch(getMovementByReservation({ reservation: newInfo.reservation })).then((res) => {
            const { payload } = res;
            setLocalSpinner(false);
            // setButtonSpinner(false)


            if (res.payload.isAxiosError) {
                // Fallo
                console.log(payload)

                if (res.payload.response) {
                    const {
                        data: { payload }
                    } = res.payload.response;
                    notify("warning", "Atención", payload.message);

                } else {
                    notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexión con el servidor."
                    );
                }



            } else {
                // Éxito

                let apiInfo = { ...payload.data.payload };

                if (!payload.data.success) {
                    notify("warning", "Alerta", apiInfo.message)
                } else if (!apiInfo.movement) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la cancelación desde SAP.")
                } else {
                    setNewInfo(Object.assign(newInfo, apiInfo))
                    setStage(2)
                }

            }
        })
    }

    //Extraer la información de la entrada por po.
    const getPositionsByReservationE = (newInfo, positions, isComputer, warehouse, idComponent) => {
        if (newInfo.document || newInfo.reservation) {
            // setSpinner(true);
            // setLocalSpinner(true)
            setButtonSpinner(true);
            dispatch(getPositionsByReservation({ view: mainView + "." + idComponent, idComponent: idComponent, reservation: newInfo.reservation, document: newInfo.document, movement: newInfo.movement, plant: newInfo.plant })).then((res) => {
                const { payload } = res;
                setButtonSpinner(false)
                if (res.payload.isAxiosError) {
                    console.log(payload)

                    if (res.payload.response) {
                        const {
                            data: { payload }
                        } = res.payload.response;
                        notify("warning", "Atención", payload.message);

                    } else {
                        notify(
                            "danger",
                            "Falló",
                            "No se logro establecer conexión con el servidor."
                        );
                    }
                } else {

                    let apiInfo = { ...payload.data.payload };
                    let positionsSapFormatA = apiInfo.positionsSapFormat; //Se guarda el formato para enviarlo al commit.

                    let firstPosition = { ...apiInfo.positions[0] };

                    // console.log("FIRST POSITION", firstPosition);

                    if (firstPosition.pendingQuantity > 1 && newInfo.movement == 241) //=> Exception for movement 241.
                    {
                        notify("warning", "Atención", "Corregir DOC de Reserva, cantidad en primera posición diferente a 1. Contacte al Sales Administrator.")
                        return;
                    }

                    if (apiInfo.positions.length > 0) {


                        if (!firstPosition) {
                            notify("warning", "Alerta", "Existe un problema al extraer la información de la PO en SAP.")
                        } else {

                            apiInfo.positions = apiInfo.positions.map(position => {
                                if (position.serieType == "ZGBM") { //Si es esto crea campos seriales en arreglo positions.

                                    let serials = {}
                                    Array.from({ length: position.pendingQuantity }).forEach((serial, index) => {
                                        serials[`serialValidation${index + 1}`] = "";
                                    });

                                    return { ...position, ...serials }
                                }

                                return position
                            })

                            setPositions(apiInfo.positions)
                            setPositionsSapFormat(positionsSapFormatA)
                            setNewInfo(Object.assign(newInfo, firstPosition))
                            setStage(3)
                        }
                    } else {
                        notify("warning", "Atención", "No existen posiciones para ese documento.")

                    }

                }
            })
        } else {
            notify("warning", "Atención", "Debe seleccionar un documento.")
        }
    }

    //Método para extraer una PO.
    const handleSearchDoc = (newInfo) => {
        // debugger;
        if (newInfo.document !== "" || newInfo.reservation !== "") {
            if (idComponent == 'reservationOutboundAssets') {
                //Go Activos
                getReservationActiveByDocE(newInfo)
            } else if (idComponent == 'reservationOutboundOthers') {
                getMovementByReservationE(newInfo)
            } else {
                // alert("consultando a sap")
                getPositionsByDocE(newInfo);
            }

        } else {
            setOpenModal(true)
        }
    }

    //Extraer la información de la entrada por po.
    const getPositionsByDocE = (newInfo) => {
        if (newInfo.document) {
            // setSpinner(true);
            // setLocalSpinner(true)
            setButtonSpinner(true);
            dispatch(getPositionsByDoc({ view: mainView + "." + idComponent, document: newInfo.document, plant: newInfo.plant })).then((res) => {
                const { payload } = res;
                if (res.payload.isAxiosError) {
                    console.log(payload)
                } else {

                    // setLocalSpinner(false);

                    let apiInfo = { ...payload.data.payload };
                    let firstPosition = { ...apiInfo.positions[0] };
                    setButtonSpinner(false)
                    if (apiInfo.positions.length > 0) {

                        if (!firstPosition) {
                            notify("warning", "Alerta", "Existe un problema al extraer la información de la PO en SAP.")
                        } else {
                            setPositions(apiInfo.positions)
                            setNewInfo(Object.assign(newInfo, firstPosition))
                            setStage(2)
                        }
                    } else {
                        notify("warning", "Atención", "No existen posiciones para ese documento.")

                    }

                }
            })
        } else {
            notify("warning", "Atención", "Debe seleccionar un documento.")
        }
    }

    //Handle para enviar la petición de salida de mercancía.
    const handleSendOutbound = async (newInfo, positions, isComputer) => {

        const incorrectPositions = positions.filter(position => position.pickingMaterial != position.material)

        if (incorrectPositions.length == 0) {


            MySwal.fire({
                title: `Atención`,
                icon: 'info',
                showCancelButton: true,
                text: "¿Está seguro(a) que desea enviar este documento?",
                // width: 1000,
                confirmButtonText: `Si, confirmar`,
                confirmButtonColor: "#2dce89",
                cancelButtonText: "No, cancelar",
                cancelButtonColor: "#adb5bd",
                buttonsStyling: isComputer,
                customClass: {
                    confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                    cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
                },
                className: "p-0",
                style: {
                    overflow: 'unset',
                    padding: '0px'
                }
            }).then((result) => {

                if (result.value) {
                    // setLocalSpinner(true)
                    // setspinnerCard(true);
                    setButtonSpinner(true)

                    dispatch(sendRequestOutboundByDoc({ view: mainView + "." + idComponent, document: newInfo.document, plant: newInfo.plant, password: encrypter(sapPassword) })).then((resp) => {

                        const { payload } = resp;
                        // setLocalSpinner(false)
                        setButtonSpinner(false);

                        if (resp.payload.isAxiosError) {
                            notify("danger", "Atención", "Ocurrió un error al realizar la confirmación.")

                            console.log(payload)
                        } else {
                            let apiInfo = { ...payload.data.payload };

                            notify("success", "Confirmación éxitosa!", apiInfo.message)

                            //Restablecer.
                            handleRefresh(newInfo);
                        }


                    })
                }
            });

        } else {
            notify("danger", "Alerta", "Existen posiciones con el picking de material incorrecto, favor validar.")

        }

    }

    //Extraer la información de la entrada por po.
    const getPositionsByOtE = (newInfo, positions, isComputer, warehouse) => {
        let movement = newInfo.movement

        if (mainView == "tasks") //Excepción de Tareas y Movimientos Spare, cuando manda a traer las posiciones, cambia el movement.
        {
            movement = 653
        }

        if (mainView == "tasks" && newInfo.movement == "") {
            notify("danger", "Alerta", "Por favor, ingrese un movimiento.")
            return;
        }

        if (newInfo.ot == "") {
            notify("danger", "Alerta", "Por favor, ingrese una OT.")
            return;
        }
        // setSpinner(true);
        // setLocalSpinner(true)
        setButtonSpinner(true);

        dispatch(getPositionsByOt({ view: mainView + "." + idComponent, ot: newInfo.ot, movement: movement, warehouse: warehouse })).then((res) => {
            const { payload } = res;
            setButtonSpinner(false)

            if (res.payload.isAxiosError) {
                console.log(payload)
                notify("warning", "Alerta", "Existe un problema al extraer la información de la OT en SAP.")

            } else {
                // console.log(payload)
                // setLocalSpinner(false);

                let apiInfo = { ...payload.data.payload };
                let firstPosition = { ...apiInfo.positions[0] };

                if (!firstPosition) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la OT en SAP.")
                } else {
                    setPositions(apiInfo.positions)
                    setNewInfo(Object.assign(newInfo, firstPosition))
                    setStage(2)
                }

            }
        })
    }

    //Handle para enviar la petición de salida de mercancía de las que tienen OT.
    const handleSendOutboundByOt = async (newInfo, positions, isComputer) => {

        const incorrectPositions = positions.filter(position => position.pickingMaterial != position.material)

        if (incorrectPositions.length == 0 && validatePicking(newInfo)) {


            MySwal.fire({
                title: `Atención`,
                icon: 'info',
                showCancelButton: true,
                text: "¿Está seguro(a) que desea enviar este documento?",
                // width: 1000,
                confirmButtonText: `Si, confirmar`,
                confirmButtonColor: "#2dce89",
                cancelButtonText: "No, cancelar",
                cancelButtonColor: "#adb5bd",
                buttonsStyling: isComputer,
                customClass: {
                    confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                    cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
                },
                className: "p-0",
                style: {
                    overflow: 'unset',
                    padding: '0px'
                }
            }).then((result) => {

                if (result.value) {
                    // setLocalSpinner(true)
                    // setspinnerCard(true);
                    setButtonSpinner(true)

                    dispatch(sendRequestOutboundByOt({ view: mainView + "." + idComponent, movement: newInfo.movement, warehouse: warehouse, ot: newInfo.ot, password: encrypter(sapPassword) })).then((resp) => {

                        const { payload } = resp;
                        // setLocalSpinner(false)
                        setButtonSpinner(false);

                        if (resp.payload.isAxiosError) {
                            notify("danger", "Atención", "Ocurrió un error al realizar la confirmación.")

                            console.log(payload)
                        } else {
                            let apiInfo = { ...payload.data.payload };

                            notify("success", "Confirmación éxitosa!", apiInfo.message)

                            //Restablecer.
                            handleRefresh(newInfo);
                        }


                    })
                }
            });

        } else {
            notify("danger", "Alerta", positions.length > 0 ? "Existen posiciones con el picking de material incorrecto, favor validar." : "El picking del material es incorrecto, favor validar.")

        }

    }

    //Handle para enviar la petición de salida de mercancía de las que tienen reservation.
    const handleSendOutboundByReservation = async (newInfo, positions, isComputer, warehouse, idComponent, positionsSapFormat) => {

        const incorrectPositions = positions.filter(position => position.pickingMaterial != position.material)

        if (validateSerialsEmpties(positions)) {
            notify("danger", "Alerta", "Debe ingresar todas las series.")
            return;
        }

        if (validateSerialsRepetitives(positions) && ["reservationOutboundOthers"].includes(idComponent)) {
            notify("danger", "Alerta", "Existen series repetidas en alguna posición.")
            return;
        }

        if (newInfo.movement != "Z09" && newInfo?.plate && newInfo?.plate?.length < 7) {
            notify("danger", "Alerta", "Debe ingresar al menos 7 números en la placa.")
            return;
        }

        if (newInfo.movement != "Z09" && newInfo?.plate && isNaN(newInfo?.plate)) {
            notify("danger", "Alerta", "La placa debe ser únicamente números en la placa.")
            return;
        }

        if (incorrectPositions.length == 0) {


            MySwal.fire({
                title: `Atención`,
                icon: 'info',
                showCancelButton: true,
                text: "¿Está seguro(a) que desea enviar este documento?",
                // width: 1000,
                confirmButtonText: `Si, confirmar`,
                confirmButtonColor: "#2dce89",
                cancelButtonText: "No, cancelar",
                cancelButtonColor: "#adb5bd",
                buttonsStyling: isComputer,
                customClass: {
                    confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                    cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
                },
                className: "p-0",
                style: {
                    overflow: 'unset',
                    padding: '0px'
                }
            }).then((result) => {

                if (result.value) {
                    // setLocalSpinner(true)
                    // setspinnerCard(true);
                    setButtonSpinner(true)

                    dispatch(sendReservationOutboundConfirmation({ view: mainView + "." + idComponent, idComponent: idComponent, reservation: newInfo.reservation, document: newInfo.document, movement: newInfo.movement, plant: newInfo.plant, positionsSapFormat: generatePositionsSapFormat(positionsSapFormat, newInfo.movement, true/*convertir en null los vacíos del json*/), serials: generateSerialsSapFormat(positions), password: encrypter(sapPassword) })).then((resp) => {

                        const { payload } = resp;
                        // setLocalSpinner(false)
                        setButtonSpinner(false);

                        if (resp.payload.isAxiosError) {
                            notify("danger", "Atención", "Ocurrió un error al realizar la confirmación.")

                            console.log(payload)
                        } else {
                            let apiInfo = { ...payload.data.payload };

                            let haveError = ['Error', 'Posting only'].some(keyword => apiInfo.message.includes(keyword));

                            if (haveError) {
                                notify("warning", "Atención", "No se confirmó, SAP indica: " + apiInfo.message)

                            } else {

                                notify("success", "Confirmación éxitosa!", apiInfo.message)

                                //Restablecer.
                                handleRefresh(newInfo);
                            }
                        }


                    })
                }
            });

        } else {
            notify("danger", "Alerta", "Existen posiciones con el picking de material incorrecto, favor validar.")

        }

    }

    //Función para generar las seriales con el formato SAP.
    const generateSerialsSapFormat = (positionsA) => {
        let serialsSapFormat = []

        positionsA.map((pos, posKey) => {
            let json = {}

            let haveSerialValidation = Object.keys(pos).
                filter(serial => serial.includes("serialValidation"))

            if (haveSerialValidation.length > 0) {
                haveSerialValidation.map(key => {
                    serialsSapFormat = [...serialsSapFormat,
                    {
                        "SERNP": pos[key],
                        "RSNUM": pos.reservation,
                        "RSPOS": "0001",
                        "BDMNG": 1
                    }
                    ]
                })

            } else {
                //Crear serialValidation para las posiciones que no tienen.
                Array.from(Array(pos.pendingQuantity).keys())
                    .map(number => {

                        serialsSapFormat = [...serialsSapFormat,
                        {
                            "SERNP": '',
                            "RSNUM": pos.reservation,
                            "RSPOS": (posKey + 1).toString().padStart(4, '0').slice(-4),
                            "BDMNG": 1
                        }
                        ]

                    })
            }




        })

        return serialsSapFormat

    }
    //Función moldear data de posiciones de SAP format.
    const generatePositionsSapFormat = (positionsSapFormatA, movement, convertToNull) => {

        positionsSapFormatA = positionsSapFormatA.map(pos => {
            let haveMovement = Object.keys(pos).includes("BWART")

            if (haveMovement && !pos.BWART) {
                pos.BWART = movement
            }

            return pos;
        })

        if (convertToNull) {
            positionsSapFormatA = positionsSapFormatA.map(item => {
                return Object.keys(item).reduce((acc, key) => {
                    acc[key] = item[key] === '' ? null : item[key];
                    return acc;
                }, {});

            });
        }

        return positionsSapFormatA

    }

    //Cambia el estado newInfo según el campo.
    const handleOnChange = (idField, label, idComponent) => {


        //Excepción para placa.
        if (idField == "plate") {
            if (isNaN(label.toString())) //No es sólo números.
            {
                notify("danger", "Alerta", "Debe digitar únicamente números.")
                return
            }

            if (label.length > 7) {
                notify("danger", "Alerta", "La cantidad de dígitos de la placa no debe ser mayor a 7.")
                return
            }
        }

        let stageMustChangePosition = fields[idComponent].filter(field => field.type == "positionsSelector")

        if (stageMustChangePosition.length > 0 && stageMustChangePosition[0].stage == stage //En este stage debe gestionarse las posiciones.
        ) {
            let currentPosition = positions[position];
            currentPosition = { ...currentPosition, [idField]: label }

            setPositions(

                positions.map((pos, key) => {
                    if (key == position) {
                        return currentPosition
                    }
                    return pos

                })
            )


        }

        setNewInfo({ ...newInfo, [idField]: label })

    }

    //Método para validar si el picking actual está correcto.
    const validatePicking = (newInfo) => {
        return newInfo.pickingMaterial == newInfo.material
    }


    //Para la salida por reserva se valida las serials si están vacías.
    const validateSerialsEmpties = (positions, positionNumber) => {

        if (positionNumber != null) //Validar por número de posición
        {
            //Validar las seriales de un número de posición
            let pos = positions[position]
            let serials = Object.keys(pos).filter(key => key.includes("serialValidation"));
            let emptySerial = false;

            if (serials.length > 0) {
                serials.map(serialId => {
                    if (pos[serialId] == "") {
                        emptySerial = true;
                    }
                })
            }

            return emptySerial



        } else {
            //Validar todas las posiciones si tienen serial.
            let emptySerial = false;

            positions.map(pos => {
                let serials = Object.keys(pos).filter(key => key.includes("serialValidation"));

                if (serials.length > 0) {
                    serials.map(serialId => {
                        if (pos[serialId] == "") {
                            emptySerial = true;
                        }
                    })
                }

            })

            return emptySerial

        }

    }

    //Para la salida por reserva se valida las serials si están repetidas.
    const validateSerialsRepetitives = (positions, positionNumber) => {
        if (positionNumber != null) //Validar por número de posición
        {
            //Validar las seriales de un número de posición
            let pos = { ...positions[position] }

            //Eliminar las que no tienen serialValidation.
            for (let key in pos) {
                if (!key.startsWith("serialValidation")) {
                    delete pos[key];
                }
            }

            //Solo valores.
            let serials = Object.values(pos)

            //Verifica si hay series repetidas
            if (new Set(serials).size !== serials.length) {
                return true
            }
            return false


        } else {
            //Validar todas las posiciones si tienen serial.
            let repetitiveSerials = false;

            positions.map(pos => {

                //Validar las seriales de un número de posición
                let posAux = { ...pos }

                //Eliminar las que no tienen serialValidation.
                for (let key in posAux) {
                    if (!key.startsWith("serialValidation")) {
                        delete posAux[key];
                    }
                }


                //Solo valores.
                let serials = Object.values(posAux)

                //Verifica si hay series repetidas
                if (new Set(serials).size !== serials.length) {
                    repetitiveSerials = true
                }
                return repetitiveSerials

            })

            return repetitiveSerials

        }

    }


    //#endregion    ------------------------fin funciones-----------------------------------------------


    //#region State *clave* los campos de el form y vista, y están clasificados por stages (escenarios), es decir, algunos campos aparecen según el stage.
    const [fields, setFields] = useState(
        {


            //Outbound
            reservationOutboundAssets: [
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "select",
                    deleteEmptyOption: true,
                    movementOptions: [241, 'Z09'],
                    disabled: false,
                    required: true,
                    icon: GrTransaction,
                    stage: [1, 2]
                },
                {
                    id: 'document',
                    label: "Documento",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchDoc,
                    disabled: false,
                    required: true,
                    icon: IoDocumentTextOutline,
                    stage: [1, 2]
                },
                {
                    id: 'plant',
                    label: "Planta",
                    width: "4",
                    type: "select",
                    options: "plants",
                    disabled: false,
                    required: true,
                    icon: MdWarehouse,
                    deleteEmptyOption: true,
                    stage: [1, 2]
                },
                {
                    id: 'asset',
                    label: "Activo",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: BsFillRecord2Fill,
                    stage: [2]
                },
                {
                    id: 'btnPositions',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: getPositionsByReservationE,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [2]
                },
                {
                    id: 'btnPositionsSelector',
                    label: "Confirmación de salida",
                    width: "12",
                    type: "positionsSelector",
                    color: "success",
                    stage: [3]
                },
                {
                    id: 'reservation',
                    label: "Reserva",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: AiOutlineBlock,
                    stage: [3]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [3]
                },
                {
                    id: 'material',
                    label: "Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [3]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [3]
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [3]
                },
                {
                    id: 'pickingMaterial',
                    sapField: "",
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [3]
                },
                {
                    id: 'plate',
                    sapField: "",
                    label: "Placa",
                    width: "4",
                    type: "input",
                    // visibleInMovement: "Z09",
                    notVisibleInMovement: "Z09",
                    positionVisible: 1,
                    disabled: false,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [3],


                },
                {
                    id: 'btnSend',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendOutboundByReservation,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [3]
                },
            ],
            reservationOutboundOthers: [
                {
                    id: 'reservation',
                    label: "Reserva",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchDoc,
                    disabled: false,
                    required: true,
                    icon: AiOutlineBlock,
                    stage: [1, 2]
                },
                {
                    id: 'plant',
                    label: "Planta",
                    width: "4",
                    type: "select",
                    options: "plants",
                    disabled: false,
                    required: true,
                    icon: MdWarehouse,
                    deleteEmptyOption: true,
                    stage: [1, 2]
                },
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "input",
                    deleteEmptyOption: true,
                    movementOptions: [],
                    disabled: true,
                    required: true,
                    icon: GrTransaction,
                    stage: [2]
                },
                {
                    id: 'network',
                    label: "Network",
                    width: "4",
                    type: "input",
                    deleteEmptyOption: true,
                    movementOptions: [],
                    disabled: true,
                    required: true,
                    icon: GrNetwork,
                    stage: [2]
                },
                {
                    id: 'btnPositions',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: getPositionsByReservationE,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [2]
                },
                {
                    id: 'btnPositionsSelector',
                    label: "Confirmación de salida",
                    width: "12",
                    type: "positionsSelector",
                    color: "success",
                    stage: [3]
                },
                {
                    id: 'reservation',
                    label: "Reserva",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: AiOutlineBlock,
                    stage: [3]
                },
                {
                    id: 'plant',
                    label: "Planta",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: PiWarehouseFill,
                    stage: [3]
                },
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GrTransaction,
                    stage: [3]
                },
                {
                    id: 'cc',
                    label: "CC",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: RiBillLine,
                    stage: [3]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [3]
                },
                {
                    id: 'material',
                    label: "Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [3]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [3]
                },
                {
                    id: 'pickingMaterial',
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [3]
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [3]
                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendOutboundByReservation,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [3]
                },
            ],
            deliveryOutbound: [
                {
                    id: 'plant',
                    label: "Planta",
                    width: "4",
                    type: "select",
                    options: "plants",
                    disabled: false,
                    required: true,
                    icon: MdWarehouse,
                    deleteEmptyOption: true,
                    stage: [1]
                },
                {
                    id: 'document',
                    label: "Documento",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchDoc,
                    disabled: false,
                    required: true,
                    icon: IoDocumentTextOutline,
                    stage: [1]
                },
                {
                    id: 'btnPositions',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: getPositionsByDocE,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [1]
                },
                {
                    id: 'btnPositionsSelector',
                    label: "Confirmación de salida",
                    width: "12",
                    type: "positionsSelector",
                    color: "success",
                    stage: [2]
                },
                {
                    id: 'ubication',
                    label: "Ubicación",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GrLocationPin,
                    stage: [2]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [2]
                },
                {
                    id: 'material',
                    label: "Número Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2]
                },
                {
                    id: 'delivery',
                    label: "Entrega",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: CiDeliveryTruck,
                    stage: [2]
                },
                {
                    id: 'serieProfile',
                    label: "Perfil de serie",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: TbPointFilled,
                    stage: [2]
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [2]
                },
                {
                    id: 'pickingMaterial',
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [2]
                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendOutbound,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [2]
                },
            ],
            cancelationOutbound: [
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "select",
                    deleteEmptyOption: true,
                    movementOptions: [602, 632],
                    disabled: false,
                    required: true,
                    icon: GrTransaction,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "OT",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchOt,
                    disabled: false,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [1]
                },
                {
                    id: 'btnOpen',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: getPositionsByOtE,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [1]
                },
                {
                    id: 'btnPositionsSelector',
                    label: "Confirmación de salida",
                    width: "12",
                    type: "positionsSelector",
                    color: "success",
                    stage: [2]
                }, {
                    id: 'ubication',
                    label: "Ubicación",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GrLocationPin,
                    stage: [2]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [2]
                },
                {
                    id: 'material',
                    label: "Número Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2]
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [2]
                },
                {
                    id: 'pickingMaterial',
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [2]
                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendOutboundByOt,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [2]
                },
            ],
            returnOutbound: [
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "select",
                    deleteEmptyOption: true,
                    movementOptions: [653, 632],
                    disabled: false,
                    required: true,
                    icon: GrTransaction,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "OT",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchOt,
                    disabled: false,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [1]
                },
                {
                    id: 'btnOpen',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: getPositionsByOtE,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [1]
                },
                {
                    id: 'btnPositionsSelector',
                    label: "Confirmación de salida",
                    width: "12",
                    type: "positionsSelector",
                    color: "success",
                    stage: [2]
                }, {
                    id: 'ubication',
                    label: "Ubicación",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GrLocationPin,
                    stage: [2]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [2]
                },
                {
                    id: 'material',
                    label: "Número Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2]
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [2]
                },
                {
                    id: 'pickingMaterial',
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [2]
                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendOutboundByOt,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [2]
                },
            ],


        }
    )

    //#region consoles a borrar.


    useEffect(() => {
        console.log("New info", newInfo)
    }, [newInfo])

    useEffect(() => {
        console.log("IdComponent", idComponent)
    }, [idComponent])

    useEffect(() => {
        console.log("Positions", positions)
    }, [positions])

    //#endregion



    return (
        <>

            <ModalList
                id={idComponent}
                title={modal.title}
                subtitle={modal.subtitle}
                spinnerText={modal.spinnerText}
                searchText={modal.searchText}
                mainView={mainView}


                openModal={openModal}
                setOpenModal={setOpenModal}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                // getSupplierByPoE={getSupplierByPoE}

                //Outbound
                getPositionsByDocE={getPositionsByDocE}

                //Reservation
                getReservationActiveByDocE={getReservationActiveByDocE}
                getMovementByReservationE={getMovementByReservationE}

            />

            <FormGeneral
                fields={fields}
                stage={stage}
                isMobile={isMobile}
                title={title}
                position={position}
                setPositions={setPositions}
                buttonSpinner={buttonSpinner}
                localSpinner={localSpinner}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                positionsSapFormat={positionsSapFormat}
                positions={positions}
                setPosition={setPosition}
                isComputer={isComputer}
                warehouse={warehouse}
                idComponent={idComponent}
                handleOnChange={handleOnChange}
                options={options}
                notValidateQuantity={notValidateQuantity}
                strictedMaterialValidation={strictedMaterialValidation}
            />



        </>
    )
}
