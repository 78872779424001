/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Application Support Administrator en la solicitud de freelance
=========================================================

* Coded by Sergio Marin  

*/
import React from "react";
import { Card, CardTitle, Label, Col, Row, Input, FormGroup, FormFeedback } from "reactstrap";

const AppSupportFreelance = ({ requiredJson, data, setData, isVisible, disabledFieldsJson, enableComments }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif' };
    const groupStyle = { marginBottom: '0.1rem', fontFamily: 'sans-serif' };
    const labelStyle = { height: '1.5em', marginBottom: '0.1rem', fontFamily: 'sans-serif' };

    const forms = [
        {
            type: "text",
            label: "User SAP",
            id: "sapUser",
            colWidth: 4,
            elementType: "Input",
            disabled: false
        },
        {
            type: "textarea",
            label: "Observaciones (máx 500 caracteres)",
            id: "asComments",
            maxLength: 500,
            colWidth: 12,
            required: false,
            disabled: enableComments,
            maxLength: 500
        },
        {
            type: "text",
            label: "Completado ASA:",
            id: "asApprover",
            colWidth: 4,
            required: false,
            disabled: true,
        }
    ]


    //FUNCIONES

    const commentsForm = forms.find(form => form.id === "asComments");
    const completedForm = forms.find(form => form.id === "asApprover");
    const sapForm = forms.find(form => form.id === "sapUser");

    const handleInputOnChange = (id, e) => {
        e.persist();
        setData(prevState => ({ ...prevState, [id]: e.target.value }));
    }

    //Return
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Application Support Administrator
                    </CardTitle>

                    {/*SapUser*/}
                    <Row style={{ padding: '0px 10px 0px' }}>
                        <Col xs={sapForm.colWidth} style={sapForm.style}>
                            <div style={{ padding: '0px 15px 0px' }}>
                                <FormGroup style={{ ...groupStyle }} key={sapForm.id}>
                                    <Label htmlFor={sapForm.id} style={labelStyle}>{sapForm.label}
                                        {sapForm.required && <span style={{ color: 'red' }}> *</span>}
                                    </Label>
                                    <Input
                                        type={sapForm.type}
                                        placeholder={sapForm.placeHolder}
                                        id={sapForm.id}
                                        invalid={requiredJson && requiredJson.includes(sapForm.id)}
                                        name={sapForm.name}
                                        disabled={(disabledFieldsJson && disabledFieldsJson.includes("AppSupportFreelance"))}
                                        className="form-control"
                                        onChange={(e) => handleInputOnChange(sapForm.id, e)}
                                        defaultValue={data[sapForm.id]}
                                    />
                                    <FormFeedback>
                                        Este campo no puede estar vacío
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>



                    {/* comments */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={commentsForm.colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={commentsForm.id} style={commentsForm.labelStyle || labelStyle}>
                                    {commentsForm.label}
                                    {commentsForm.required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    type={commentsForm.type}
                                    id={commentsForm.id}
                                    maxLength={commentsForm.maxLength}
                                    disabled={!commentsForm.disabled && (disabledFieldsJson && disabledFieldsJson.includes("AppSupportFreelance"))}
                                    className="form-control"
                                    onChange={(e) => handleInputOnChange(commentsForm.id, e)}
                                    defaultValue={data[commentsForm.id]}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* completed BY */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={completedForm.colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={completedForm.id} style={completedForm.labelStyle || labelStyle}>
                                    {completedForm.label}
                                    {completedForm.required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    type={completedForm.type}
                                    id={completedForm.id}
                                    maxLength={completedForm.maxLength}
                                    disabled={completedForm.disabled}
                                    className="form-control"
                                    value={data.asApprover}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );
}

export default AppSupportFreelance;