import { updateFastCotyzationReport } from 'actions/panamaBids.jsx';
import classnames from "classnames";
import React, { useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody, Modal, ModalBody,
  ModalFooter, ModalHeader, Nav,
  NavItem,
  NavLink,
  Row, TabContent,
  TabPane
} from "reactstrap";
import QuickQuoteEditFormOne from "./QuickQuoteEditFormOne";
import QuickQuoteEditFormThree from "./QuickQuoteEditFormThree";
import QuickQuoteEditFormTwo from "./QuickQuoteEditFormTwo";

const QuickQuoteEditModal = ({
  showModal,
  dataModal,
  options,
  setDataModal,
  handleCloseModal,
  setrefreshInput,
  rowsModal,
  activeTab,
  setActiveTab,
  Toast,
  isAdmin
}) => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState("");

  const handleTab = (event, tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleChangeInfo = (constant, e, constantSelect, num) => {
    if (constant === "gbmStatus" || constant === "performanceBond" || constant === "orderStatus" || constant === "forecastType") {
      setDataModal((prevState) => ({
        ...prevState,
        [constant]: e,
        [constantSelect]: num
      }));
    } else {
      setDataModal((prevState) => ({
        ...prevState,
        [constant]: e
      }));
    }

  }
  const handleOnEdit = () => {
    dispatch(updateFastCotyzationReport({ info: dataModal })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        handleCloseModal();
        setrefreshInput(4);
        Toast.fire({
          title: "Atención",
          html: "Se guardó correctamente",
          type: 'success'
        });
        setAlert("");
      }
    }
    )
  }
  const handleOnEditQuickQuote = (e, row) => {
    setAlert(
      <ReactBSAlert
        custom
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Está seguro(a) que desea guardar los cambios?"
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        onConfirm={() => handleOnEdit(e, row)}
        onCancel={() => setAlert("")}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sí, guardar"
        cancelBtnText="No, cancelar"
        btnSize="md"
      />
    )
  }
  return (
    <div>
      {alert}
      <Modal className="modal-xl" isOpen={showModal} style={{ minWidth: "900px", maxWidth: "900px" }}>
        <ModalHeader className="d-flex justify-content-center">
          {"Registro Unico de Pedido: "}
          <a rel="noreferrer" href={dataModal.cotiLink} target="_blank" style={{ color: "blue" }}>
            {dataModal.numCotyzation}
          </a>
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "1"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "1",
                    })}
                    onClick={(e) => handleTab(e, "1")}
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos Generales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "2"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "2",
                    })}
                    onClick={(e) => handleTab(e, "2")}
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos Producto
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "3"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "3",
                    })}
                    onClick={(e) => handleTab(e, "3")}
                    // href='#AUopp'
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos Adicionales
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <QuickQuoteEditFormOne
                dataModal={dataModal}
                handleChangeInfo={handleChangeInfo}
                isAdmin={isAdmin}
              />
            </TabPane>
            <TabPane tabId="2">
              <QuickQuoteEditFormTwo
                dataModal={dataModal}
                products={rowsModal}
                options={options}
                isAdmin={isAdmin}
                handleChangeInfo={handleChangeInfo}

              />
            </TabPane>
            <TabPane tabId="3">
              <QuickQuoteEditFormThree
                dataModal={dataModal}
                filesQuote={rowsModal.files}
                isAdmin={isAdmin}
                handleChangeInfo={handleChangeInfo}
                Toast={Toast}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Button color="danger" onClick={() => handleCloseModal()}>
              Cancelar
            </Button>
            <Button color="success" onClick={() => handleOnEditQuickQuote()}>
              Guardar
            </Button >
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};


export default QuickQuoteEditModal;