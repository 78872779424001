import React, { useState, useEffect } from "react";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { getOptions, getStepConfig } from 'actions/ItRequests'
import { getTeamsUserAccess } from 'selectors/adminLayout';
import NotificationAlert from "react-notification-alert";
import { Container, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

import Menu from "components/ItRequests/Menu.jsx";
import Request from "components/ItRequests/Request";
import UpdateRequest from "components/ItRequests/UpdateRequest";
import OpenRequests from "components/ItRequests/CurrentRequests";
import SearchRequests from "components/ItRequests/SearchRequests/SearchRequests";
import AccessMatrixMenu from "components/ItRequests/AccessMatrix/AccessMatrixMenu";
import RolePositionTable from "components/ItRequests/AccessMatrix/RolePositionTable";
import RoleExceptionTable from "components/ItRequests/AccessMatrix/RoleExceptionTable";


const Home = () => {
    //FUNCIONES************************

    const dispatch = useDispatch();
    const [showMenu, setShowMenu] = useState("menu");//para mostrar el menu
    const [loading, setLoading] = useState(true);//para mostrar el spin
    const [optionsJson, setOptionsList] = useState({});//para guardar los drop downs
    const [configJson, setConfigJson] = useState({});//para guardar las config de los pasos del flujo
    const [step, setStep] = useState("");//para guardar el paso que se visualiza
    const [data, setData] = useState({}); //donde se guardan los datos ingresados
    const [roles, setRoles] = useState({}); //donde se guardan los roles

    //Carga las cosas (opciones de los drop down, configuracionesy roles)

    useEffect(() => {
        Promise.all([
            dispatch(getOptions()),
            dispatch(getStepConfig())
        ]).then(([optionsResp, configResp]) => {
            setOptionsList(JSON.parse(optionsResp.payload.data.options[0][0].json_result));
            setConfigJson(configResp.payload.data.configs);
            setLoading(false);
        });

        //Setear permisos de los roles

        const rolesList = getTeamsUserAccess();

        let rolesPermissions = {
            countries: [],
            steps: [],
            buttons: [],
            matrixWritePermission: false
        }

        if (rolesList.includes("ItRequest Admin")) {
            rolesPermissions.buttons.push("initial", "UpdateReqs", "SearchReqs", "openReqs", "initialFreelance", "UpdateReqsFrelance", "AccessMatrix");
            rolesPermissions.steps.push(""); //vacio significa todos
            rolesPermissions.countries.push(""); //vacio significa todos
            rolesPermissions.matrixWritePermission = true;

        } if (rolesList.includes("ItRequest Manager")) {
            rolesPermissions.steps.push("28", "27");
            rolesPermissions.buttons.push("initial");

        } if (rolesList.includes("ItRequest ReadOnly")) {
            rolesPermissions.buttons.push("SearchReqs");

        } if (rolesList.includes("ItRequest HC")) {
            rolesPermissions.countries.push("");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC CO")) {
            rolesPermissions.countries.push("13"); //--> de donde reciben
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC Corp")) {
            rolesPermissions.countries.push("7");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC CR")) {
            rolesPermissions.countries.push("1");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC DO")) {
            rolesPermissions.countries.push("2");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC Freelance")) {
            rolesPermissions.countries.push("");
            rolesPermissions.steps.push("15", "18", "21");
            rolesPermissions.buttons.push("UpdateReqsFrelance", "openReqs");

        } if (rolesList.includes("ItRequest Manager Freelance")) {
            rolesPermissions.buttons.push("initialFreelance");

        } if (rolesList.includes("ItRequest HC GT")) {
            rolesPermissions.countries.push("3");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC HN")) {
            rolesPermissions.countries.push("4");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC NI")) {
            rolesPermissions.countries.push("9");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC PA")) {
            rolesPermissions.countries.push("6");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC SV")) {
            rolesPermissions.countries.push("5");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest HC VE")) {
            rolesPermissions.countries.push("8");
            rolesPermissions.steps.push("1");
            rolesPermissions.buttons.push("UpdateReqs", "openReqs");

        } if (rolesList.includes("ItRequest SS")) {
            rolesPermissions.steps.push("2", "6", "10", "15", "18", "21", "25", "26");
            rolesPermissions.buttons.push("openReqs");

        } if (rolesList.includes("ItRequest AS")) {
            rolesPermissions.steps.push("3", "7", "11", "16", "19", "22");
            rolesPermissions.buttons.push("openReqs");

        } if (rolesList.includes("ItRequest CS")) {
            rolesPermissions.steps.push("5", "9", "13", "17", "20", "23");
            rolesPermissions.buttons.push("openReqs");

        } if (rolesList.includes("ItRequest AccessMatrix")) {
            rolesPermissions.buttons.push("AccessMatrix");
        } if (rolesList.includes("ItRequest AccessMatrix Write")) {
            rolesPermissions.matrixWritePermission = true;
        }

        rolesPermissions.countries = Array.from(new Set(rolesPermissions.countries));
        rolesPermissions.buttons = Array.from(new Set(rolesPermissions.buttons));
        rolesPermissions.steps = Array.from(new Set(rolesPermissions.steps));

        setRoles(rolesPermissions);
    }, []);

    //RETURN
    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert useRef="notificationAlert" />
            </div>
            <AdminHeader name="Inicio" parentName="Solicitudes de TI" />
            <Container className="mt--6" fluid>
                {/*Boton de Regresar */}
                {
                    showMenu !== "menu" &&

                <Button
                    className="btn-info"
                    size="sm"
                    block
                    type="button"
                    style={{ width: "100px ", height: "30px", marginBottom: "30px" }}
                    onClick={() => setShowMenu("menu")}
                >
                    <span className="btn-inner--icon mr-">
                        <i className="fa fa-arrow-left" />
                    </span>
                    <span className="btn-inner--text">
                        {" "}Regresar
                    </span>
                </Button>
                }
                <Spin size="large" spinning={loading}>
                    {showMenu === "menu" ? (
                        <Menu
                            roles={roles}
                            setData={setData}
                            setShowMenu={setShowMenu}
                            setStep={setStep}
                            setLoading={setLoading}
                        />
                    ) : showMenu === "request" ? (
                        <Request
                            setShowMenu={setShowMenu} //para regresar al menu
                            setData={setData}//funcion para llenar data
                            data={data}//el objecto con todos los datos de la solicitud
                            step={step}//paso del flujo
                            configJson={configJson}//Json con toda la info de como debe comportarse Request
                            optionsJson={optionsJson}//informacion de las lista para dropdows, radio buttons, checks, etc
                            setLoading={setLoading}
                        />
                    ) : showMenu === "openReqs" ? (
                        <OpenRequests
                            roles={roles}
                            setData={setData}
                            setShowMenu={setShowMenu}
                            setStep={setStep}
                            setLoading={setLoading}
                        />
                    ) : showMenu === "updateReq" ? (
                        <UpdateRequest
                            setData={setData}
                            setShowMenu={setShowMenu}
                            setStep={setStep}
                            setLoading={setLoading}
                            countries={roles.countries}
                            freelance={false}
                        />
                    ) : showMenu === "updateReqF" ? (
                        <UpdateRequest
                            setData={setData}
                            setShowMenu={setShowMenu}
                            setStep={setStep}
                            setLoading={setLoading}
                            countries={roles.countries}
                            freelance={true}
                        />
                    ) : showMenu === "searchReqs" ? (
                        <SearchRequests
                            roles={roles}
                            setData={setData}
                            setShowMenu={setShowMenu}
                            setStep={setStep}
                            setLoading={setLoading}
                            optionsJson={optionsJson}
                        />
                    ) : showMenu === "accessMatrix" ? (
                        <AccessMatrixMenu
                            setShowMenu={setShowMenu}
                            setLoading={setLoading}
                        />
                    ) : showMenu === "rolePositionTable" ? (
                        <RolePositionTable
                            setShowMenu={setShowMenu}
                            setLoading={setLoading}
                            optionsJson={optionsJson}
                            writePermission={roles.matrixWritePermission}
                        />
                    ) : showMenu === "roleExceptionTable" ? (
                        <RoleExceptionTable
                            setShowMenu={setShowMenu}
                            setLoading={setLoading}
                            optionsJson={optionsJson}
                            writePermission={roles.matrixWritePermission}
                        />
                    ) : (
                        <Menu
                            setData={setData}
                            setShowMenu={setShowMenu}
                            setStep={setStep}
                            setLoading={setLoading}
                        />
                    )}
                </Spin>
            </Container >
        </>
    );
};

export default Home;
