import React, { useEffect, useState } from "react";
// react-select library for filters
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
  Button,
  Form
} from "reactstrap";

const DigitalFormMainTable = ({
  option,
  handleOnChangeInfo,
  info,
  handleOnGetInfoByUser,
  setInfo
}) => {
  const rowsModal = [
    {
      colWidth: "3",
      label: "Usuario",
      id: "user",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Id de Colaborador",
      id: "idCollaborator",
      type: "text",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Nombre de Colaborador",
      id: "collaboratorName",
      value: "name",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Jefe Inmediato",
      id: "immediateBoss",
      value: "manager",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Departamento",
      id: "department",
      value: "department",
      type: "text",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Tiempo Laborado",
      id: "timeWorked",
      value: "timeWorked",
      type: "text",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Fecha",
      id: "date",
      type: "date",
      max: (new Date().toISOString().split('T')[0]),
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Pais",
      id: "countriesData",
      name: "countryName",
      type: "select",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Razón de Salida",
      name: "exitTypeName",
      id: "exitTypeId",
      type: "select",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Tipo de Salida",
      name: "ExitReasonName",
      id: "exitReason",
      type: "select",
      disabled: false,
    },
    {
      colWidth: "4",
      label: "Recontratable?",
      name: "rehirableName",
      id: "rehirable",
      type: "select",
      disabled: true,
    },
    {
      colWidth: "4",
      label: "Volveria usted a GBM?",
      name: "goBackGBMName",
      id: "goBackGBM",
      type: "select",
      disabled: true,
    },
    {
      colWidth: "12",
      label: "Comentarios",
      id: "comment",
      type: "text",
      disabled: true,
    },
    {
      colWidth: "12",
      label: "¿Qué pudo haber hecho GBM para que usted no hubiera valorado salir de la compañia?",
      id: "doNotValueGBM",
      type: "text",
    },
    {
      colWidth: "6",
      label: "Comentarios HC",
      id: "commentHC",
      type: "text",
      disabled: false,
    },
    {
      colWidth: "6",
      label: "Fecha exacta de salida",
      id: "dateExact",
      type: "date",
      disabled: true,
    },
  ];
  const [isRenounce, setIsRenounce] = useState(false);
  const [isOtherExitReason, setIsOtherExitReason] = useState(false);
  useEffect(() => {
    if (info.exitType === "Renuncia") {
      setIsRenounce(true);
    }
    setIsOtherExitReason(containsExitReason("Otros"))
  }, [info]);

  useEffect(() => {
    setInfo((prevState) => ({
      ...prevState,
      collaboratorName: "",
      department: "",
      immediateBoss: "",
      timeWorked: "",
      idCollaborator: "",
      benefitsJob: "",
      companyTypeId: "",
      specify: "",
      date: new Date().toISOString().split('T')[0]
    }));

  }, []);

  const containsExitReason = (word) => {
    let cont = 0;
    if (info.exitReason) {
      info.exitReason.map(item => item.label === word && cont++)
      if (cont > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  return (
    <div>
      <Card>
        <CardBody>
          <Form noValidate className="isValid needs-validation">
            <Row>
              {rowsModal.map((row, key) =>
                row.id === "user" ?
                  <>
                    <Col xs={row.colWidth} key={key}>
                      <FormGroup key={key}>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${row.label}:`}
                        </label>
                        <Input
                          id={row.id}
                          key={key}
                          required={true}
                          className="form-control"
                          type={row.type}
                          value={info[row.id] ? (info[row.id]) : ""}
                          onChange={(e) =>
                            handleOnChangeInfo(row.id, e.target.value)
                          }
                        />
                        <div className="invalid-feedback">
                          Este campo no puede estar vacio
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={1} key={`${key}button`}>

                      <Button
                        key={`${key}button`}
                        className="btn mt-4"
                        color="primary"
                        onClick={() => handleOnGetInfoByUser()}
                      >
                        <span className="btn-inner--icon mr-">
                          <i className="fas fa-search"></i>
                        </span>
                        <span className="btn-inner--text"></span>
                      </Button>

                    </Col>
                  </>
                  :
                  row.type === "text" ? (
                    <Col xs={row.colWidth} key={key}>
                      <FormGroup key={key}>
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${row.label}:`}
                        </label>
                        <Input
                          id={row.id}
                          key={key}
                          required={true}
                          className="form-control"
                          type={row.type}
                          value={info[row.id] ? (info[row.id]) : ""}
                          onChange={(e) =>
                            handleOnChangeInfo(row.id, e.target.value)
                          }
                        />
                        <div className="invalid-feedback">
                          Este campo no puede estar vacio
                        </div>
                      </FormGroup>
                    </Col>
                  ) :
                    row.type === "select" ?
                      row.id === "exitReason" ?
                        <>
                          <Col xs={row.colWidth} key={key}>
                            <FormGroup key={key}>
                              <label
                                style={{ fontSize: "12px" }}
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                {row.label}
                              </label>
                              <Select
                                style={{ border: "red" }}
                                className="basic-single is-invalid"
                                type="select"
                                key={key}
                                isSearchable
                                isMulti
                                options={option.masterData[row.id]}
                                onChange={(e) => handleOnChangeInfo(row.id, e)}
                              />

                              <div className="invalid-feedback">
                                Este campo no puede estar vacio
                              </div>
                            </FormGroup>
                          </Col>
                          {isOtherExitReason == true ?
                            < Col xs={4} key={`${key}specify`}>
                              <FormGroup key={`${key}specify`}>
                                <label
                                  style={{ fontSize: "12px" }}
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {`Especificación de tipo de salida:`}
                                </label>
                                <Input
                                  id={"specify"}
                                  key={`${key}specify`}
                                  required={`${key}specify`}
                                  className="form-control"
                                  type={"text"}
                                  value={info["specify"] ? (info["specify"]) : ""}
                                  onChange={(e) =>
                                    handleOnChangeInfo("specify", e.target.value)
                                  }
                                />
                                <div className="invalid-feedback">
                                  Este campo no puede estar vacio
                                </div>
                              </FormGroup>
                            </Col>
                            : <></>}
                        </>
                        :
                        row.id === "exitTypeId" ?
                          <>
                            <Col xs={row.colWidth} key={key}>
                              <FormGroup key={key}>
                                <label
                                  style={{ fontSize: "12px" }}
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {row.label}
                                </label>
                                <Input
                                  key={key}
                                  required={true}
                                  type={"select"}
                                  options={option.masterData[row.id]}
                                  value={info[row.id] ? info[row.name] : "---"}
                                  className="basic-multi-select text-dark"
                                  onChange={(e) => handleOnChangeInfo(row.id, e.target.children[e.target.selectedIndex], "exitType")}
                                >
                                  {option["exitType"].map((value) => {
                                    return (
                                      <option id={value.value} key={value.label}>{value.label}</option>
                                    );
                                  })}
                                </Input>

                                <div className="invalid-feedback">
                                  Este campo no puede estar vacio
                                </div>
                              </FormGroup>
                            </Col>
                          </>
                          :
                          (
                            <>
                              <Col xs={row.colWidth} key={key}>
                                <FormGroup key={key}>
                                  <label
                                    style={{ fontSize: "12px" }}
                                    className="form-control-label"
                                    htmlFor="input-username"
                                  >
                                    {row.label}
                                  </label>

                                  <Input
                                    key={key}
                                    required={true}
                                    type={"select"}
                                    options={option.masterData[row.id]}
                                    value={info[row.id] ? info[row.name] : "---"}
                                    className="basic-multi-select text-dark"
                                    onChange={(e) => handleOnChangeInfo(row.id, e.target.children[e.target.selectedIndex], row.name)}
                                  >
                                    {option[row.id].map((value) => {
                                      return (
                                        <option key={value.label} id={value.value}>{value.label}</option>
                                      );
                                    })}
                                  </Input>
                                  <div className="invalid-feedback">
                                    Este campo no puede estar vacio
                                  </div>
                                </FormGroup>
                              </Col>
                            </>
                          ) : (
                        <Col xs={row.colWidth} key={key}>
                          <FormGroup key={key}>
                            <label
                              style={{ fontSize: "12px" }}
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {`${row.label}:`}
                            </label>
                            <Input
                              id={row.id}
                              required={true}
                              key={key}
                              type={row.type}
                              max={row.max && row.max}
                              defaultValue={info[row.id]}
                              onChange={(e) =>
                                handleOnChangeInfo(row.id, e.target.value)
                              }
                              className="basic-multi-select text-dark "
                            ></Input>
                            <div className="invalid-feedback">
                              Este campo no puede estar vacio
                            </div>
                          </FormGroup>
                        </Col>
                      )
              )}
              {
                isRenounce === true &&
                <>
                  <Col xs={6}>
                    <FormGroup>
                      <label
                        style={{ fontSize: "12px" }}
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        {"¿Qué tipo de compañía es su nueva oferta laboral?"}
                      </label>

                      <Input
                        required={true}
                        type={"select"}
                        options={option.masterData["companyType"]}
                        value={info["companyTypeName"] ? info["companyTypeName"] : "---"}
                        className="basic-multi-select text-dark"
                        onChange={(e) => handleOnChangeInfo("companyTypeId", e.target.children[e.target.selectedIndex], "companyTypeName")}
                      >
                        {option["companyType"].map((value) => {
                          return (
                            <option id={value.value}>{value.label}</option>
                          );
                        })}
                      </Input>
                      <div className="invalid-feedback">
                        Este campo no puede estar vacio
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup >
                      <label
                        style={{ fontSize: "12px" }}
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        {`¿Qué beneficios adicionales o pluses adicionales ofrece la nueva oferta laboral?`}
                      </label>
                      <Input
                        id={"benefitsJob"}
                        required={true}
                        className="form-control"
                        type={"text"}
                        value={info["benefitsJob"] ? (info["benefitsJob"]) : ""}
                        onChange={(e) =>
                          handleOnChangeInfo("benefitsJob", e.target.value)
                        }
                      />
                      <div className="invalid-feedback">
                        Este campo no puede estar vacio
                      </div>
                    </FormGroup>
                  </Col>
                </>
              }

            </Row>
          </Form>
        </CardBody>
      </Card>
    </div >
  );
};

export default DigitalFormMainTable;

