// react library
import React, { useState } from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";


const CardFilters = ({
  lists,
  setLists,  //funcion que modifica el state 
  setLoad,
  Toast,
  isAdmin,
  isManager,
  page,
  setPage,
  dropDownOptions,
  setRealTable,
  rows,
  rowsTable,
  setRows
}) => {

  //#region funciones
  //estado inicial
  const [searchTxt, setSearchTxt] = useState({});

  const [state, setState] = useState({
    title: "Busqueda Avanzada",
    subtitle: "Aplicar filtros al sistema de contrataciones PB10",
  });
  const { title, subtitle } = state;

  //funcion para buscar por filtro (no texto)
  const handleOnChange = (constant, valor) => {
    console.log(constant);
    console.log(valor);
    setPage(1);
    let rowss = rowsTable;
    // setLists(prevState => ({
    //   ...prevState,
    //   [constant]: {
    //     ...prevState[constant],
    //     value: e
    //   }
    // }));
    if (valor === '') {
      delete searchTxt[constant];
    }
    else {
      searchTxt[constant] = valor;
      setSearchTxt(searchTxt);
    }
    console.log(searchTxt);
    if (Object.keys(searchTxt).length === 0) {

      setRows((filt) => ({
        ...filt,
        rows: rowsTable,
        lengthTable: rowsTable.length,
      }))
    } else {
      Object.keys(searchTxt).forEach(function (key) {
        const valueFiltered = searchTxt[key].toString().toLowerCase();

        rowss = rowss.filter((item) => item[key].toString().toLowerCase().indexOf(valueFiltered) != -1);

      });
      console.log(rowss)
      setRows((filt) => ({
        ...filt,
        rows: rowss,
        lengthTable: rowss.length,
      }))
    }

  }

  //funcion para replicar cambios
  const handleOnLoad = () => {
    // setSearch("");
    setPage(1);
    // handleOnClearFilters()
    setRealTable(prevState => ({
      reloadTable: !prevState.reloadTable,
    }));
  }

  //funcion para limpiar filtros:
  const handleOnClearFilters = (e) => {
    setPage(1);
    console.log(searchTxt);
    if (Object.keys(searchTxt).length === 0) {
      // setSearch("");
      Toast.fire({
        title: "Atención",
        html: "Aún no se han aplicado filtros que deban ser limpiados",
        type: 'warning'
      });

    } else {
      setSearchTxt({});
      setRows((filt) => ({
        ...filt,
        rows: rowsTable,
        lengthTable: rowsTable.length,
      }))

    }
  };

  //campos del filtro
  const [fields, setFields] = useState([
    {
      colWidth: "2",
      elementType: "Select",
      label: "País",
      placeholder: "País",
      id: "country",
      type: "text",
      min: 0,
      max: 1999999999,
      defaultValue: "country",
      disabled: false,
      options: "CountryApplication",
      value: "CountryApplication",
      defaultValueLabel: "countryName",
      defaultValueValue: "country",
    },
    {
      colWidth: "2",
      elementType: "Input",
      label: "Num. Posición",
      placeholder: "Posición",
      id: "position",
      type: "text",
      min: 0,
      max: 1999999999,
      defaultValue: "position",
      disabled: false,
      options: [],
      value: [],
      defaultValueLabel: "",
      defaultValueValue: "",
    },
    {
      colWidth: "2",
      elementType: "Input",
      label: "Puesto",
      placeholder: "Puesto",
      id: "jobTitle",
      type: "text",
      min: 0,
      max: 1999999999,
      defaultValue: "jobTitle",
      disabled: false,
      options: [],
      value: [],
      defaultValueLabel: "",
      defaultValueValue: "",
    },
    {
      colWidth: "2",
      elementType: "Select",
      label: "Tipo de Plaza",
      placeholder: "Tipo Plaza",
      id: "plazaType",
      type: "text",
      min: 0,
      max: 1999999999,
      defaultValue: "plazaType",
      disabled: false,
      options: "EmployeeGroup",
      value: "EmployeeGroup",
      defaultValueLabel: "eGroupName",
      defaultValueValue: "eGroupCode",
    },
    {
      colWidth: "2",
      elementType: "Select",
      label: "Tipo de Posición",
      placeholder: "Tipo Posición",
      id: "positionType",
      type: "text",
      min: 0,
      max: 1999999999,
      defaultValue: "positionType",
      disabled: false,
      options: "EmployeeSubGroup",
      value: "EmployeeSubGroup",
      defaultValueLabel: "eSubGroupCode",
      defaultValueValue: "eSubGroupName",
    },
    {
      colWidth: "2",
      elementType: "Select",
      label: "Estado",
      placeholder: "Estado",
      id: "status",
      type: "text",
      min: 0,
      max: 1999999999,
      defaultValue: "status",
      disabled: false,
      options: "StatusApplication",
      value: "StatusApplication",
      defaultValueLabel: "statusName",
      defaultValueValue: "status",
    },
  ]);
  //#endregion
  return (
    <>

      <Card>

        <CardHeader>
          <Row className="align-items-center">
            <Col sm="6" md="8">
              <h3 className="mb-0" >{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>

            <Col xs="3" md="2" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className="btn-icon" color="info" size="sm" block type="button"
                onClick={(e) => handleOnLoad(e)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-sync-alt" />
                </span>
                <span className="btn-inner--text">
                  Refrescar
                </span>
              </Button>
            </Col>
            <Col xs="3" md="2" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className="btn-icon" color="danger" size="sm" block type="button"
                onClick={(e) => handleOnClearFilters(e)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text">
                  Limpiar
                </span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            {
              fields.map((field, key) => (
                field.id === "country" ?
                  isAdmin ?
                    <Col xs={field.colWidth} key={key}>
                      <FormGroup key={key}>
                        <label className="form-control-label">{field.label}</label>
                        <Select
                          key={field.id}
                          className="basic-single"
                          id={field.id}
                          type={field.type}
                          placeholder={field.placeholder}
                          isSearchable
                          isDisabled={field.disabled}
                          options={dropDownOptions[field.options] && [...dropDownOptions[field.options], {
                            value: "",
                            label: "",
                          }]}
                          value={{ value: searchTxt[field.id] ? searchTxt[field.id] : "", label: dropDownOptions[field.options] ? dropDownOptions[field.options].find(dt => dt.value === searchTxt[field.id]) ? dropDownOptions[field.options].find(dt => dt.value === searchTxt[field.id]).label : "" : "" }}
                          // defaultValue={{ label: data[field.defaultValueLabel], value: data[field.defaultValueValue] }}
                          onChange={(e) => handleOnChange(field.id, e.value)}
                        />
                      </FormGroup>
                    </Col>
                    :
                    <></>
                  :
                  field.id === "country" ?
                  isAdmin ?
                    <Col xs={field.colWidth} key={key}>
                      <FormGroup key={key}>
                        <label className="form-control-label">{field.label}</label>
                        <Select
                          key={field.id}
                          className="basic-single"
                          id={field.id}
                          type={field.type}
                          placeholder={field.placeholder}
                          isSearchable
                          isDisabled={field.disabled}
                          options={dropDownOptions[field.options] && [...dropDownOptions[field.options], {
                            value: "",
                            label: "",
                          }]}
                          value={{ value: searchTxt[field.id] ? searchTxt[field.id] : "", label: dropDownOptions[field.options] ? dropDownOptions[field.options].find(dt => dt.value === searchTxt[field.id]) ? dropDownOptions[field.options].find(dt => dt.value === searchTxt[field.id]).label : "" : "" }}
                          // defaultValue={{ label: data[field.defaultValueLabel], value: data[field.defaultValueValue] }}
                          onChange={(e) => handleOnChange(field.id, e.value)}
                        />
                      </FormGroup>
                    </Col>
                    :
                    <></>
                  :

                  <Col xs={(field.id === "plazaType" || field.id === "jobTitle" ) ? (isAdmin) ? "2" : "3" : field.colWidth} key={key}>
                    <FormGroup key={key}>
                      <label className="form-control-label">{field.label}</label>
                      {
                        field.elementType === "Input" ?
                          <Input
                            key={field.id}
                            id={field.id}
                            className="basic-single"
                            type={field.type}
                            readOnly={field.disabled}
                            // defaultValue={data[field.defaultValue]}
                            placeholder={field.placeholder}
                            value={searchTxt[field.id] ? searchTxt[field.id] : ""}
                            onChange={(e) => handleOnChange(field.id, e.target.value)}
                          />
                          :
                          <Select
                            key={field.id}
                            className="basic-single"
                            id={field.id}
                            type={field.type}
                            placeholder={field.placeholder}
                            isSearchable
                            isDisabled={field.disabled}
                            // options={dropDownOptions[field.options]}
                            options={dropDownOptions[field.options] && [...dropDownOptions[field.options], {
                              value: "",
                              label: "",
                            }]}
                            value={{ value: searchTxt[field.id] ? searchTxt[field.id] : "", label: dropDownOptions[field.options] ? dropDownOptions[field.options].find(dt => dt.value === searchTxt[field.id]) ? dropDownOptions[field.options].find(dt => dt.value === searchTxt[field.id]).label : "" : "" }}
                            // defaultValue={{ label: data[field.defaultValueLabel], value: data[field.defaultValueValue] }}
                            onChange={(e) => handleOnChange(field.id, e.value)}
                          />
                      }
                    </FormGroup>
                  </Col>

              ))
            }

          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default CardFilters;