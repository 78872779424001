import { Col, FormGroup, Input, Row } from "reactstrap";
// react-select library for filters
import Select from "react-select";
// react library
import React, { useState } from 'react';

const EditFormTwo = ({
    row,
    displayI,
    handleOnChange,
}) => {

    const [fields, setFields] = useState([
        {
            colWidth: "4",
            elementType: "Input",
            label: "Sales Order OnShore",
            placeholder: "Sales Order OnShore",
            id: "salesOrderOn",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "salesOrderOn",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Sales Order Trading",
            placeholder: "Sales Order Trading",
            id: "salesOrderTrad",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "salesOrderTrad",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Purchasing Requisition",
            placeholder: "Purchasing Requisition",
            id: "purchReq",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "purchReq",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Oportunidad",
            placeholder: "Oportunidad",
            id: "opp",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "opp",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "Quotation",
            placeholder: "Quotation",
            id: "quote",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "quote",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "Input",
            label: "PO Asociadas (SV)",
            placeholder: "PO Asociadas (SV)",
            id: "documentSv",
            type: "textarea",
            min: 0,
            max: 1999999999,
            defaultValue: "documentSv",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "8",
            elementType: "Input",
            label: "Quotes Asociados",
            placeholder: "Quotes Asociados",
            id: "documentQuotes",
            type: "textarea",
            min: 0,
            max: 1999999999,
            defaultValue: "documentQuotes",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
    ]);


    return (


        <>
            <Row className="align-items-center">
                {
                    fields.map((field, key) => (


                        <Col xs={field.colWidth} key={key}>
                            <FormGroup key={key}>
                                <label className="form-control-label">{field.label}</label>
                                {
                                    field.id === "documentSv" || field.id === "documentQuotes" ?

                                        row[field.defaultValue] !== null && typeof row[field.defaultValue] !== 'undefined' ?
                                            <Input
                                                key={field.id}
                                                id={field.id}
                                                className="form-control"
                                                type={field.type}
                                                // min={field.min}
                                                // max={field.max}
                                                readOnly={!field.disabled ? true : displayI}
                                                // {...field.disabled}
                                                defaultValue={row[field.defaultValue].split(",").join('\n')}
                                                placeholder={field.placeholder}
                                            />
                                            :
                                            <Input
                                                key={field.id}
                                                id={field.id}
                                                className="form-control"
                                                type={field.type}
                                                // min={field.min}
                                                // max={field.max}
                                                readOnly={!field.disabled ? true : displayI}
                                                // {...field.disabled}
                                                defaultValue={""}
                                                placeholder={field.placeholder}
                                            />

                                        : field.elementType === "Input" ?
                                            <Input
                                                key={field.id}
                                                id={field.id}
                                                className="form-control"
                                                type={field.type}
                                                readOnly={!field.disabled? true : displayI}
                                                defaultValue={row[field.defaultValue]}
                                                placeholder={field.placeholder}
                                                onChange={(e) => handleOnChange(field.id, e.target.value)}
                                            />
                                            :
                                            <Select
                                                key={field.id}
                                                className="basic-single"
                                                id={field.id}
                                                type={field.type}
                                                placeholder={field.placeholder}
                                                isSearchable
                                                options={field.options}
                                                isDisabled={!field.disabled ? true : displayI}
                                                // value={field.value}
                                                defaultValue={{ label: row[field.defaultValueLabel], value: row[field.defaultValueValue] }}
                                                onChange={(e) => handleOnChange(field.id, e.value)}
                                            />
                                }
                            </FormGroup>
                        </Col>
                    ))
                }
            </Row>
        </>
    );
};

export default EditFormTwo;