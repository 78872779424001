import * as jwt from "jsonwebtoken";
// import * as constants from "constants/index.jsx";
import { desencrypt } from "helpers/desencrypt.jsx";

export const getInitialValues = (state) => {
  if (Object.keys(state.requirementForm).length) {
    return state.requirementForm.initialValues;
  }
  return {};
};

export const getInitialValuesEquipment = (state) => {
  if (Object.keys(state.equipmentsByRequirement).length) {
    return state.equipmentsByRequirement.initialValues;
  }
  return {};
};

export const getValuesEquipments = (state) => {
  if (Object.keys(state.equipmentsByRequirement).length) {
    return state.equipmentsByRequirement.values;
  }
  return {};
};

export const getRequeriments = (state) => state.myRequirements;

export const getEquipments = (state) => {
  if (Object.keys(state.equipmentsByRequirement).length) {
    const { equipments } = state.equipmentsByRequirement;
    return equipments;
  }
  return [];
};

export const getCriticalParts = (state) => {
  if (Object.keys(state.criticalParts).length) {
    const { criticalParts } = state.criticalParts;
    const decrypt = desencrypt(criticalParts);
    return JSON.parse(decrypt);
  }
  return [];
};

export const getLengthCriticalParts = (state) => {
  if (Object.keys(state.criticalParts).length) {
    const { length } = state.criticalParts;
    return length;
  }
  return 0;
};

export const getDataFiltersCriticalParts = (state) => {
  if (Object.keys(state.criticalParts).length) {
    const { platforms, families, categories } = state.criticalParts;
    const platformsDecrypt = desencrypt(platforms);
    const familiesDecrypt = desencrypt(families);
    const categoriesDecrypt = desencrypt(categories);
    return {
      platforms: JSON.parse(platformsDecrypt),
      families: JSON.parse(familiesDecrypt),
      categories: JSON.parse(categoriesDecrypt),
      classify: [
        { value: 0, label: "Todos" },
        { value: 1, label: "Crítica" },
        { value: 2, label: "Sensitiva" },
      ],
      redundancy: [
        { value: 2, label: "Todos" },
        { value: 1, label: "Si" },
        { value: 0, label: "No" },
      ],
    };
  }
  return {
    platforms: [],
    families: [],
    categories: [],
    classify: [],
    redundancy: [],
  };
};

export const getCriticalPartsErrors = (state) => {
  if (Object.keys(state.criticalParts).length) {
    const { errors } = state.criticalParts;
    if (errors) return errors;
    else return [];
  }
  return [];
};

export const getInitialValuesCriticalParts = (state) => {
  if (Object.keys(state.criticalParts).length) {
    return { family: "Hola" };
  }
  return {};
};

export const getDebuggedModels = (state) => {
  if (Object.keys(state.debuggedModels).length) {
    const { models } = state.debuggedModels;
    return models;
  }
  return [];
};

export const getDebuggedModelsErrors = (state) => {
  if (Object.keys(state.debuggedModels).length) {
    const { errors } = state.debuggedModels;
    if (errors) return errors;
    else return [];
  }
  return [];
};

export const getLengthDebuggedModels = (state) => {
  if (Object.keys(state.debuggedModels).length) {
    const { length } = state.debuggedModels;
    return length;
  }
  return 0;
};

export const getIsSuperAdmin = () => {
  const token = localStorage.getItem("token");
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      const { teams } = decoded;
      const index = teams.findIndex(
        (row) => row === "CriticalParts Super Admin"
      );
      return index >= 0 ? true : false;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getIsLimitedAdmin = () => {
  const token = localStorage.getItem("token");
  try {
    const decoded = jwt.decode(token);
    if (Object.keys(decoded).length) {
      const { teams } = decoded;
      const index = teams.findIndex(
        (row) => row === "CriticalParts Limited Admin"
      );
      return index >= 0 ? true : false;
    }
    return false;
  } catch (err) {
    return false;
  }
};
