//Imports de React
import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col,  Button, Input, FormGroup, Modal, ModalBody, ModalHeader
} from "reactstrap";

// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

//Dispatch al API
import { useDispatch } from 'react-redux';

//User actual
import { getUsernameLogged } from 'selectors/adminLayout';

//Action para el envío de la oportunidad apartir del JSON Principal EditData.
import { newBrandLDR } from 'actions/autoppLdrs';

export const ModalNewBrand = ({ options, openModal, setOpenModal,  handleRefreshTable,  rows }) => {

  //Dispatch al API.
  const dispatch = useDispatch();

  const userName = getUsernameLogged();


  //#region Notificaciones

  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  //#endregion



  //Lo construye en cammel case.
  const toCammelCase = (word) => {

    //Agrupa y realiza un cammel case 
    word = word.split(' ').map(wordA => {
      return wordA.charAt(0).toUpperCase() + wordA.slice(1);
    }).join("");

    return word
  }


  //#endregion


  //Campos para la pantalla del modal de guardar el LDR.
  const [fieldsModal, setfieldsModal] = useState([
    {
      id: "brandName",
      type: "input",
      label: "Nombre",
      placeholder: "",
      width: 12,
      required: true,
      options: "cycle",
      optionsDepends: false,
    },
    {
      id: "cycle",
      type: "select",
      label: "Ciclo de ventas",
      placeholder: "Seleccione una opción",
      width: 12,
      required: true,
      options: "cycle",
      optionsDepends: false,
      idDropdownThatDepends: ""
    }

  ])

  //Almacena la selección del modal
  const [newInfoModal, setNewInfoModal] = useState([])

  //Efecto para cargar newInfoModal con info
  useEffect(() => {
    let newInfoA = []

    fieldsModal.map(field => {
      newInfoA =
      {
        ...newInfoA,
        [field.id]:  ""

      }
    })


    setNewInfoModal(newInfoA);
  }, [])

  //Handle para asignar las nuevas filas editables en el state newInfo.
  const handleModalInfo = (id, e) => {

    setNewInfoModal(prevState => ({
      ...prevState,
      [id]: e
    }));

  }

  //Valida si no se llenaron campos en este modal
  const validateFieldsModal = (newInfoModalE, rowsE) => {
    let result = Object.values(newInfoModalE).filter(field => field == "")

    if (result.length > 0 || newInfoModalE.length == 0) {
      Toast.fire({
        title: "Atención",
        html: "Por favor, seleccione los anteriores campos obligatorios.",
        type: 'warning'
      });
      return false;
    }

    let similarName = rowsE.filter(row => row.brand.toLowerCase().replace(/\s+/g, "")  == newInfoModalE.brandName.toLowerCase().replace(/\s+/g, ""))

    if (similarName.length > 0 ) {
      Toast.fire({
        title: "Atención",
        html: `El nombre ingresado es similar a otro brand existente llamado ${similarName[0].brand} del ciclo ${similarName[0].cycle}, por favor cambie el nombre.`,
        type: 'warning'
      });
      return false;
    }

    return true;

  }

  //Handle para enviar la petición
  const handleToSendPetition = (typeName, newInfoModalE, rowsE) => {

    if (validateFieldsModal(newInfoModalE, rowsE)) {

      MySwal.fire({

        type: 'success',
        title: `Guardar Brand`,
        html:

          `<h2>¿Está seguro que desea guardar el Brand ${typeName}?</h2>`,

        confirmButtonText: 'Si, guardar',
        confirmButtonColor: '#2DCE89',
        cancelButtonText: 'No, cancelar',
        cancelButtonColor: '#f5365c',
        showCancelButton: true,

        preConfirm: () => {

          dispatch(newBrandLDR({ salesCycle: newInfoModalE.cycle, brandName: newInfoModalE.brandName, brandId: toCammelCase(newInfoModalE.brandName), userName: userName })).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
              setOpenModal(false);
              handleRefreshTable();

              Toast.fire({
                title: "Atención",
                html: `¡Brand creada con éxito!`,
                type: 'success'
              });


            } else {

              Toast.fire({
                title: "Atención",
                html: payload.message,
                type: 'warning'
              });
            };
          })


        },

      })
    }

  }

  //Handle para cerrar el modal
  const handleToCloseModal = () => {
    setOpenModal(false)
  }

  //Para desplegar botones finales en el modal.
  const [modalButtons, setModalButtons] = useState(
    [
      {
        idNumber: 1,
        label: "Crear Brand",
        color: "success",
        id: "Production",
        icon: "fa fa-cogs",
        typeFunction: "send",
        width: 5,
        fun: handleToSendPetition,

      },
      {
        label: "Cancelar",
        color: "danger",
        id: "cancel",
        icon: "fa fa-reply",
        typeFunction: "cancell",
        width: 3,
        fun: handleToCloseModal

      }


    ]
  )

  return (
    <>
      <Modal isOpen={openModal} >
        <ModalHeader>
          <Row>
            <Col sm="12" className="ml-3">
              <h5 className="h3 mb-0">Guardado de Brand</h5>
              <h6 className="surtitle">Edición de brand</h6>
            </Col>

          </Row>

        </ModalHeader>


        <ModalBody className="d-flex justify-content-center mt--4 mb--2">

          <Container className=" ml--4 mr--4 p-0" >
            <Row>
              <Col sm="12">
                {
                  fieldsModal.map((field, key) => (
                    <>
                      <FormGroup >
                        <label
                          className="form-control-label ml-3"
                          htmlFor="input-username"
                        >
                          {field.label}{field.required ? "*" : ""}
                        </label>
                        <Input
                          id={field.id}
                          required={field.required}
                          placeholder={field.placeholder}
                          className="form-control text-dark"
                          value={newInfoModal[field.id]}
                          onChange={(e) => handleModalInfo(field.id, e.target.value)}
                          type={field.type}

                        >
                          <option id=""></option>
                          {

                            options.autoppCareOptions &&
                            options.autoppCareOptions[field.options] &&
                            options.autoppCareOptions[field.options]
                              .map((value) => {
                                if (!field.optionsDepends ||
                                  (value[field.idDropdownThatDepends] == newInfoModal[field.idDropdownThatDepends])
                                )
                                  return (
                                    <option id={value.value}>{value.label}</option>
                                  );
                              })


                          }

                        </Input>
                        <div className="invalid-feedback">
                          Este campo no puede estar vacío
                        </div>
                      </FormGroup>



                    </>
                  ))


                }
              </Col>




            </Row>
            <Row className="d-flex justify-content-center p-0">
              <Col xs="12" sm="auto">
                {modalButtons.map((button) => (
                  <Button
                    key={button.label}
                    color={button.color}
                    type="button"
                    className="mr-2 mb-2"
                    onClick={(e) => button.fun(button.label.toLowerCase(), newInfoModal, rows)}

                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={button.icon} />
                    </span>
                    {" "}
                    <span className="btn-inner--text">{button.label}</span>
                  </Button>
                ))}
              </Col>
            </Row>
          </Container>

        </ModalBody>

      </Modal>
    </>
  )

}

