/*!

=========================================================
*CardBotArea
=========================================================

*Componente para desplegar cada card con el nombre de la área de databot.

=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/

//Librerías de react.
import React from 'react';

//Componentes de la librería reactstrap
import { Button, Card, CardBody } from "reactstrap";

const CardBotArea = ({
    //Props
    id,
    title,
    subtitle,
    turnOnOffArea,
    fillCircleButtons
}) => {

    return (
        <>
            <Card
                className="card-stats"
            >
                <CardBody>

                    <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow"
                        src={require(`assets/img/databot/${title}.png`)}
                        style={{ objectFit: "contain", width: "60px", height: "60px" }}
                    />

                    <div className="pt-4 text-center">
                        <h5 className="h3 title">
                            <span className="d-block mb-1">{title}</span>
                            <small className="h4 font-weight-light text-muted">
                                {"Des/Activar los " + subtitle}
                            </small>
                        </h5>
                        <div className="mt-3">
                            <Button
                                outline={fillCircleButtons[id]?.on}
                                className="btn-icon-only rounded-circle"
                                color="success"
                                onClick={e => turnOnOffArea(id, title, "1")}
                            >
                                <i className="fas fa-power-off" />
                            </Button>
                            <Button
                                outline={fillCircleButtons[id]?.off}
                                className="btn-icon-only rounded-circle"
                                color="danger"
                                onClick={e => turnOnOffArea(id, title, "0")}
                            >
                                <i className="fas fa-power-off" />
                            </Button>
                        </div>
                    </div>

                </CardBody>
            </Card>
        </>
    )
};
export default CardBotArea;