// core actions
import { findActivitiesReportByDates } from "actions/coe.jsx";
// antd components
import { Spin } from "antd";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Table from "components/Shared/Table/Table.jsx";
// javascript library for dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
// core selectors
import {
  getActivitiesReport,
  getYearsActivitiesReport,
} from "selectors/coe.jsx";

class ActivitiesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      coesSelected: {
        value: 0,
        label: "TODOS",
      },
      statusSelected: {
        value: 0,
        label: "TODOS",
      },
      custumerSelected: {
        value: 0,
        label: "TODOS",
      },
      yearSelected: {
        value: moment().year(),
        label: moment().year(),
      },
      monthSelected: {
        value: 0,
        label: "TODOS", //moment().format("MMMM").toUpperCase()
      },
    };
  }

  componentDidMount() {
    const { monthSelected, yearSelected } = this.state;
    this.findActivitiesReport(monthSelected.value, yearSelected.value);
  }

  findActivitiesReport = (month, year) => {
    this.setState({
      loading: true,
      message: "Cargando el Reporte de Actividades",
    });
    this.props
      .findActivitiesReportByDates({
        month,
        year,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  _handleOnChangeDates = (who, value) => {
    const { monthSelected, yearSelected } = this.state;
    this.setState({
      [who]: value,
    });
    this.findActivitiesReport(
      who === "monthSelected" ? value.value : monthSelected.value,
      who === "yearSelected" ? value.value : yearSelected.value
    );
  };

  _handleOnChangeFilters = (who, value) => {
    this.setState({
      [who]: value,
    });
  };

  _handleOnClearFilters = () => {
    this.setState({
      coesSelected: {
        value: 0,
        label: "TODOS",
      },
      statusSelected: {
        value: 0,
        label: "TODOS",
      },
      custumerSelected: {
        value: 0,
        label: "TODOS",
      },
    });
  };

  renderCoeOptions = (data) => {
    const { statusSelected, custumerSelected } = this.state;
    let coes = [];
    let i = 1;
    if (statusSelected.value === 0 && custumerSelected.value === 0) {
      coes = data.map((row, key) => {
        return {
          value: key + 1,
          label: row.CoE_Nombre,
        };
      });
    } else {
      for (const element of data) {
        if (statusSelected.label === element.Estado) {
          coes.push({
            value: i,
            label: element.CoE_Nombre,
          });
          i += 1;
        }
        if (custumerSelected.label === element.Cli_Nombre) {
          coes.push({
            value: i,
            label: element.CoE_Nombre,
          });
          i += 1;
        }
      }
    }
    coes = coes
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => coes[e])
      .map((e) => coes[e]);

    coes = coes.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...coes];
  };

  renderStatusOptions = (data) => {
    const { coesSelected, custumerSelected } = this.state;
    let status = [];
    let i = 1;
    if (coesSelected.value === 0 && custumerSelected.value === 0) {
      status = data.map((row, key) => {
        return {
          value: key + 1,
          label: row.Estado,
        };
      });
    } else {
      for (const element of data) {
        if (coesSelected.label === element.CoE_Nombre) {
          status.push({
            value: i,
            label: element.Estado,
          });
          i += 1;
        }
        if (custumerSelected.label === element.Cli_Nombre) {
          status.push({
            value: i,
            label: element.Estado,
          });
          i += 1;
        }
      }
    }
    status = status
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => status[e])
      .map((e) => status[e]);

    status = status.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...status];
  };

  renderCustomerOptions = (data) => {
    const { coesSelected, statusSelected } = this.state;
    let customer = [];
    let i = 1;
    if (coesSelected.value === 0 && statusSelected.value === 0) {
      customer = data.map((row, key) => {
        return {
          value: key + 1,
          label: row.Cli_Nombre,
        };
      });
    } else {
      for (const element of data) {
        if (coesSelected.label === element.CoE_Nombre) {
          customer.push({
            value: i,
            label: element.Cli_Nombre,
          });
          i += 1;
        }
        if (statusSelected.label === element.Estado) {
          customer.push({
            value: i,
            label: element.Cli_Nombre,
          });
          i += 1;
        }
      }
    }
    customer = customer
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => customer[e])
      .map((e) => customer[e]);

    customer = customer.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...customer];
  };

  handleRenderDataByFilters = (data) => {
    const { coesSelected, statusSelected, custumerSelected } = this.state;
    if (
      coesSelected.value === 0 &&
      statusSelected.value === 0 &&
      custumerSelected.value === 0
    ) {
      return data;
    } else {
      if (coesSelected.value !== 0) {
        data = data.filter((row) => row.CoE_Nombre === coesSelected.label);
      }
      if (statusSelected.value !== 0) {
        data = data.filter((row) => row.Estado === statusSelected.label);
      }
      if (custumerSelected.value !== 0) {
        data = data.filter((row) => row.Cli_Nombre === custumerSelected.label);
      }
      return data;
    }
  };

  render() {
    const { name, parentName, activities, cols, years, months } = this.props;

    const {
      loading,
      message,
      page,
      sizePerPage,
      yearSelected,
      monthSelected,
      statusSelected,
      coesSelected,
      custumerSelected,
    } = this.state;

    const defaultSorted = [
      {
        dataField: "Rep_ItemFecha",
        order: "asc",
      },
    ];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            <Row>
              <Col xs="12" md="5">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col sm="12">
                        <h3 className="mb-0">Fechas de búsqueda</h3>
                        <p className="text-sm mb-0">
                          Seleccione el Mes y Año para la busqueda.
                        </p>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-right" xs="12" md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Mes
                          </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un mes"}
                            isSearchable={false}
                            options={months}
                            value={monthSelected}
                            onChange={(e) =>
                              this._handleOnChangeDates("monthSelected", e)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="text-right" xs="12" md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Año
                          </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un año"}
                            isSearchable={false}
                            options={years}
                            value={yearSelected}
                            onChange={(e) =>
                              this._handleOnChangeDates("yearSelected", e)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" md="7">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col sm="12" md="8">
                        <h3 className="mb-0">Filtros</h3>
                        <p className="text-sm mb-0">
                          Filtros disponibles para los reportes.
                        </p>
                      </Col>
                      <Col className="text-right" xs="12" md="4">
                        <Button
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={this._handleOnClearFilters}
                        >
                          <span className="btn-inner--icon mr-">
                            <i className="fas fa-trash" />
                          </span>
                          <span className="btn-inner--text">Limpiar</span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-right" xs="12" md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Estado
                          </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un estdo"}
                            isSearchable={false}
                            options={this.renderStatusOptions(activities)}
                            value={statusSelected}
                            onChange={(e) =>
                              this._handleOnChangeFilters("statusSelected", e)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="text-right" xs="12" md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            COE
                          </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un COE"}
                            isSearchable={false}
                            options={this.renderCoeOptions(activities)}
                            value={coesSelected}
                            onChange={(e) =>
                              this._handleOnChangeFilters("coesSelected", e)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="text-right" xs="12" md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Cliente
                          </label>
                          <Select
                            className="basic-single"
                            id="example-month-input"
                            type="select"
                            placeholder={"Selecione un cliente"}
                            isSearchable={false}
                            options={this.renderCustomerOptions(
                              activities.filter((row) => row.Cargable === 1)
                            )}
                            value={custumerSelected}
                            onChange={(e) =>
                              this._handleOnChangeFilters("custumerSelected", e)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  style={{
                    whiteSpace: "normal",
                    textAlign: "justify",
                  }}
                  totalA
                  title="Reporte Actividades"
                  subtitle="Reportes de actividades del COE."
                  columns={cols}
                  data={this.handleRenderDataByFilters(activities)}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={this.handleRenderDataByFilters(activities).length}
                  defaultSorted={defaultSorted}
                  cvsFeatrue
                  fileName="Reporte Actividades"
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

ActivitiesReport.defaultProps = {
  name: "Reporte por Actividades",
  parentName: "COE",
  activities: [],
  cols: [
    { dataField: "id", text: "ID", hidden: true },
    { dataField: "NOMBRE", text: "Usuario", hidden: false },
    { dataField: "Estado", text: "Estado", hidden: false },
    { dataField: "Rep_ItemFecha", text: "Fecha Item", hidden: false },
    { dataField: "Rep_Fecha", text: "Fecha Reporte", hidden: false },
    { dataField: "Horas", text: "Horas", hidden: false },
    { dataField: "DiasDiff", text: "Días Diff", hidden: false },
    { dataField: "CoE_Nombre", text: "COE", hidden: false },
    { dataField: "Cli_Nombre", text: "Cliente", hidden: false },
    { dataField: "Act_id", text: "ID Actividad", hidden: false },
    { dataField: "Actividad", text: "Actividad", hidden: false },
  ],
  years: [],
  months: [
    { value: 0, label: "TODOS" },
    { value: 1, label: "ENERO" },
    { value: 2, label: "FEBRERO" },
    { value: 3, label: "MARZO" },
    { value: 4, label: "ABRIL" },
    { value: 5, label: "MAYO" },
    { value: 6, label: "JUNIO" },
    { value: 7, label: "JULIO" },
    { value: 8, label: "AGOSTO" },
    { value: 9, label: "SETIEMBRE" },
    { value: 10, label: "OCTUBRE" },
    { value: 11, label: "NOVIEMBRE" },
    { value: 12, label: "DICIEMBRE" },
  ],
};

ActivitiesReport.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  activities: PropTypes.array.isRequired,
  years: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  activities: getActivitiesReport(state),
  years: getYearsActivitiesReport(state),
});

export default withRouter(
  connect(mapStateToProps, { findActivitiesReportByDates })(ActivitiesReport)
);
