// javascript library for dates
import moment from "moment";
import "moment/locale/es";
import { desencrypt } from "helpers/desencrypt.jsx";

// labels chart
const labels = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Colors charts
const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

// options charts Bar
const optionsBarLegend = {
  legend: {
    position: "top",
    display: true,
    // fullWidth: true,
    labels: {
      usePointStyle: false,
    },
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: false,
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          },
        },
      },
    ],
  },
};

// options charts Pie
const optionsPie = {
  responsive: true,
  legend: {
    position: "top",
    labels: {
      usePointStyle: false,
    },
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const { labels } = data;
        const dataset = data.datasets[tooltipItem.datasetIndex];
        return `${labels[tooltipItem.index]} ${
          dataset.data[tooltipItem.index]
        }%`;
      },
    },
  },
};

export const getMedicalRecord = (state) => {
  const { medicalRecord } = state;
  if (Object.keys(medicalRecord).length && medicalRecord.data) {
    // console.log(medicalRecord.data)
    // medicalRecord.data.bornDate = moment(medicalRecord.data.bornDate)
    //   .utc()
    //   .utcOffset(moment().utcOffset())
    //   .format("DD/MM/YYYY");
    const decrypt = desencrypt(medicalRecord.data);
    // console.log(JSON.parse(decrypt));
    return JSON.parse(decrypt);
  }
  return {};
};

export const getContactInitialValues = (state) => {
  return {
    contactName: "",
    telephone: "",
    relationship: "",
    contactAddress: "",
    allergyName: "",
    allergyStartedDate: "",
    allergyStillHappening: "",
    allergyMedicated: "",
    allergySymptom: "",
    diseaseName: "",
    diseaseNameStartedDate: "",
    diseaseStillHappening: "",
    diseaseMedicated: "",
    diseaseReason: "",
    interventionsReason: "",
    interventionsDate: "",
    interventionsRequireMedication: "",
    interventionsNotes: "",
    test: "probando",
  };
};

export const getEmergencyContacts = (state) => {
  const { emergencyContacts } = state;
  if (Object.keys(emergencyContacts).length && emergencyContacts.data) {
    const decrypt = desencrypt(emergencyContacts.data);
    return JSON.parse(decrypt);
  }
  return [];
};

export const getAllAllergies = (state) => {
  const { allergies } = state;
  if (Object.keys(allergies).length && allergies.data) {
    const decrypt = desencrypt(allergies.data);
    return JSON.parse(decrypt);
  }
  return [];
};

export const getAllDiseases = (state) => {
  const { diseases } = state;
  if (Object.keys(diseases).length && diseases.data) {
    const decrypt = desencrypt(diseases.data);
    return JSON.parse(decrypt);
  }
  return [];
};

export const getAllInterventions = (state) => {
  const { interventions } = state;
  if (Object.keys(interventions).length && interventions.data) {
    const decrypt = desencrypt(interventions.data);
    return JSON.parse(decrypt);
  }
  return [];
};

export const getAllOffices = (state) => {
  const { offices } = state;
  if (Object.keys(offices).length && offices.data) {
    const decrypt = desencrypt(offices.data);
    return JSON.parse(decrypt);
  }
  return [];
};

export const getDataChartDashboard = (state) => {
  const dataRecords = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataAllergies = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataDiseases = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataInterventions = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const { graphRecords } = state;
  if (Object.keys(graphRecords).length) {
    const { graphData } = graphRecords;
    const [records, allergies, diseases, interventions] = graphData;
    if (records.length) {
      for (const row of records) {
        dataRecords[row.month - 1] = row.totalRecords;
      }
    }
    if (allergies.length) {
      for (const row of allergies) {
        dataAllergies[row.month - 1] = row.totalAllergies;
      }
    }
    if (diseases.length) {
      for (const row of diseases) {
        dataDiseases[row.month - 1] = row.totalDiseases;
      }
    }
    if (interventions.length) {
      for (const row of interventions) {
        dataInterventions[row.month - 1] = row.totalInterventions;
      }
    }
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Expedientes",
            data: dataRecords,
            backgroundColor: colors.theme["success"],
          },
          {
            label: "Alergias",
            data: dataAllergies,
            backgroundColor: colors.theme["warning"],
          },
          {
            label: "Enfermedades",
            data: dataDiseases,
            backgroundColor: colors.theme["danger"],
          },
          {
            label: "Intervenciones",
            data: dataInterventions,
            backgroundColor: colors.theme["default"],
          },
        ],
      },
      options: optionsBarLegend,
    };
  } else {
    return {
      data: {
        labels,
        datasets: [
          {
            label: "Expedientes",
            data: dataRecords,
            backgroundColor: colors.theme["success"],
          },
          {
            label: "Alergias",
            data: dataAllergies,
            backgroundColor: colors.theme["warning"],
          },
          {
            label: "Enfermedades",
            data: dataDiseases,
            backgroundColor: colors.theme["danger"],
          },
          {
            label: "Intervenciones",
            data: dataInterventions,
            backgroundColor: colors.theme["default"],
          },
        ],
      },
      options: optionsBarLegend,
    };
  }
};

export const getRecordsByRol = (state) => {
  const { records } = state;
  if (Object.keys(records).length && records.data) {
    return records.data;
  }
  return [];
};

export const getFiltersOptions = (state) => {
  if (Object.keys(state.records).length) {
    const { data } = state.records;
    let offices = data.map((row, key) => {
      return {
        value: key + 1,
        label: row.officeName,
      };
    });
    offices = offices
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => offices[e])
      .map((e) => offices[e]);
    offices = offices.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let colabs = data.map((row, key) => {
      return {
        value: key + 1,
        label: row.name,
      };
    });
    colabs = colabs
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => colabs[e])
      .map((e) => colabs[e]);
    colabs = colabs.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let bloodTypes = data.map((row, key) => {
      return {
        value: key + 1,
        label: row.bloodType,
      };
    });
    bloodTypes = bloodTypes
      .map((e) => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => bloodTypes[e])
      .map((e) => bloodTypes[e]);
    bloodTypes = bloodTypes.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return {
      offices: [{ value: 0, label: "Todos" }, ...offices],
      colabs: [{ value: 0, label: "Todos" }, ...colabs],
      bloodTypes: [{ value: 0, label: "Todos" }, ...bloodTypes],
    };
  }
  return {
    offices: [],
    colabs: [],
    bloodTypes: [],
  };
};

export const getAllCountriesGraph = (state) => {
  const { graphRecords } = state;
  if (Object.keys(graphRecords).length) {
    const { years } = graphRecords;
    return years.map((row, key) => {
      const json = {};
      json.value = key + 1;
      json.label = row.year;
      return json;
    });
  }
  return [];
};

export const getTransactionsGraph = (state) => {
  const { graphRecords } = state;
  if (Object.keys(graphRecords).length) {
    const { transactions } = graphRecords;
    if (transactions.length) {
      const [data] = transactions;
      return data;
    }
  }
  return {};
};

export const getAllOtherTypes = (state) => {
  const { otherTypes } = state;
  if (Object.keys(otherTypes).length && otherTypes.data) {
    const decrypt = desencrypt(otherTypes.data);
    return JSON.parse(decrypt);
  }
  return [];
};

export const getAllOtherMedicalInfo = (state) => {
  const { otherMedicalInfo } = state;
  if (Object.keys(otherMedicalInfo).length && otherMedicalInfo.data) {
    const decrypt = desencrypt(otherMedicalInfo.data);
    return JSON.parse(decrypt);
  }
  return [];
};

export const getDataPercentChartDashboard = (state) => {
  const countries = [];
  const data = [];
  const colorsRandom = [];
  const { graphRecords } = state;
  if (Object.keys(graphRecords).length) {
    const { graphPercentData } = graphRecords;
    const [records, totalRecords] = graphPercentData;
    if (records.length && totalRecords.length) {
      const [{ total }] = totalRecords;
      for (const el of records) {
        data.push(((el.count * 100) / total).toFixed(2));
        countries.push(el.location);
        colorsRandom.push(
          "#" + Math.floor(Math.random() * 16777215).toString(16)
        );
      }
    }
  }
  return {
    data: {
      labels: countries,
      datasets: [
        {
          data,
          backgroundColor: colorsRandom,
        },
      ],
    },
    options: optionsPie,
  };
};

export const getDataVaccineChartDashboard = (state) => {
  const { graphRecords } = state;
  const labelsLocations = [];
  const dataCovid = [];
  const dataFever = [];
  const dataOthers = [];
  // const dataOphth = [];
  if (Object.keys(graphRecords).length) {
    const { graphVaccineData } = graphRecords;
    const [offices, data] = graphVaccineData;
    for (const element of offices) {
      labelsLocations.push(element.name);
      dataCovid.push(0);
      dataFever.push(0);
      dataOthers.push(0);
      // dataOphth.push(0);
    }
    for (const element of data) {
      const indexLocation = labelsLocations.findIndex(
        (row) => element.location === row
      );
      if (
        element.vaccineName !== "Fiebre amarilla" &&
        element.vaccineName !== "Otra Vacuna"
      ) {
        dataCovid[indexLocation] = dataCovid[indexLocation] + element.total;
      } else if (element.vaccineName === "Fiebre amarilla") {
        dataFever[indexLocation] = dataFever[indexLocation] + element.total;
      } else if (element.vaccineName === "Otra Vacuna") {
        dataOthers[indexLocation] = dataOthers[indexLocation] + element.total;
      } // else if (element.vaccineName === 'Oftalmología') {
      //   dataOphth[indexLocation] = element.total;
      // }
    }
  }
  return {
    data: {
      labels: labelsLocations,
      datasets: [
        {
          label: "Vacuna COVID",
          data: dataCovid,
          backgroundColor: colors.theme["success"],
        },
        {
          label: "Vacuna Fiebre Amarilla",
          data: dataFever,
          backgroundColor: colors.theme["warning"],
        },
        {
          label: "Otras vacunas",
          data: dataOthers,
          backgroundColor: colors.theme["danger"],
        },
        // {
        //   label: "Oftalmología",
        //   data: dataOphth,
        //   backgroundColor: colors.theme["default"]
        // }
      ],
    },
    options: optionsBarLegend,
  };
};

export const getAllUsersAccessRecords = (state) => {
  const { accessRecords } = state;
  if (Object.keys(accessRecords).length) {
    return accessRecords.users;
  }
  return [];
};
