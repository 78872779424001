import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

//importaciones de reacstrap
import {
  Col,
  FormGroup,
  Input,
  Row,
  InputGroup,
  InputGroupAddon,
  Button,
  Card,
  CardBody,
  Form,
} from "reactstrap";

//importacion del Json para los campos del form general
import formFields from "../SharedComponents/GeneralStates";

const GeneralDataForm = (props) => {
  const {
    typeForm,
    optionsDropdowns,
    setValidate,
    generalDataValues,
    setGeneralDataValues,
    stateRequired,
    setStateRequired,
    InsertGeneralDataValues,
    setInsertGeneralDataValues,
    globalInfo,
    setGlobalInfo,
    setBawFlow,
    bawFlow
  } = props;

  //Funcion para guardar la data general del primer paso y guardar los datos requeridos para enviar la gestion (Campos necesarios para el insert en MasterDataRequest)
  const handleOnChange = (val, field, required, factor, label) => {


    //Si posee Baw valida el id y habilita  
    if (field === "baw" && val === 1) {
      setBawFlow(true);
    } else if (field === "baw" && val === 2) {
      //si no posee baw eliminar el campo del id de baw del array y deshabilita el bawFlow
      const temp = stateRequired.filter(state => state !== "bawManagement")
      setStateRequired(temp);
      setBawFlow(false);
    }
    if (required) {
      //si el campo es requerido y no esta en el array stateRequired lo agrega
      if (!stateRequired.includes(field)) {
        setStateRequired((prev) => ([
          ...prev, field]
        ));
      } else {
        //Si stateRequired si posee el campo y el valor es blanco lo quita del array stateRequired
        if (val === "") {
          const temp = stateRequired.filter(state => state !== field)
          setStateRequired(temp);
        }
      }

    }
    setGeneralDataValues((prev) => ({
      ...prev,
      [field]: val,
    }));

    if (field === "typeOfManagement" || field === "comment" || factor) {
      setGlobalInfo((prev) => ({
        ...prev,
        [factor ? "factor" : field]: factor ? label.split(" - ")[0] : val,
      }));
    }
  };



  return (
    <div>
      <Card style={{ backgroundColor: "#E8E8E8" }}>
        <CardBody>
          <Form noValidate className="needs-validation formInfo">
            {formFields[typeForm].generalData.map((jsonField, key) => (
              <Row class="align-items-center">
                <Col sm={4}></Col>
                {jsonField.dependsAnotherDropdown === true ? (
                  jsonField.idDropdownDepends === generalDataValues.baw && (
                    <Col sm={jsonField.colWidth} key={key}>
                      <FormGroup>
                        <label className="form-control-label">
                          {jsonField.label}
                        </label>
                        <Input
                          onChange={(e) =>
                            handleOnChange(
                              e.target.value,
                              jsonField.id,
                              jsonField.required,
                              jsonField.factor
                            )
                          }
                          label={jsonField.labelBaw}
                          key={jsonField.id}
                          id={jsonField.id}
                          name={jsonField.id}
                          factor={jsonField.id}
                          colWidth={jsonField.colWidth}
                          required={false}
                          className="form-control"
                          type={jsonField.type}
                          placeholder={jsonField.placeholder}
                        />
                      </FormGroup>
                    </Col>
                  )
                ) : jsonField.id === "typeOfManagement" ? (
                  <Col sm={jsonField.colWidth} key={key}>
                    <FormGroup>
                      <label className="form-control-label">
                        {jsonField.label}
                      </label>
                      <Select
                        onChange={(e) =>
                          handleOnChange(
                            e.value,
                            jsonField.id,
                            jsonField.required,
                            jsonField.factor,
                            e.label
                          )
                        }
                        key={jsonField.id}
                        id={jsonField.id}
                        factor={jsonField.id}
                        colWidth={jsonField.colWidth}
                        name={jsonField.id}
                        required={false}
                        options={
                          formFields[typeForm].creacionm === "creacion"
                            ? [{ label: "Creación", value: "1" }]
                            : formFields[typeForm].creacionm === "modificacion"
                              ? [{ label: "Modificación", value: "2" }]
                              : [
                                { label: "Creación", value: "1" },
                                { label: "Modificación", value: "2" },
                              ]
                        }
                        className="basic-single"
                        type={jsonField.type}
                        placeholder={jsonField.placeholder}
                      />
                    </FormGroup>
                  </Col>
                ) : jsonField.elementType === "input" ? (
                  <Col sm={jsonField.colWidth} key={key}>
                    <FormGroup>
                      <label className="form-control-label">
                        {jsonField.label}
                      </label>
                      <Input
                        onChange={(e) =>
                          handleOnChange(
                            e.target.value,
                            jsonField.id,
                            jsonField.required,
                            jsonField.factor
                          )
                        }
                        key={jsonField.id}
                        id={jsonField.id}
                        name={jsonField.id}
                        factor={jsonField.id}
                        colWidth={jsonField.colWidth}
                        required={false}
                        className="form-control"
                        type={jsonField.type}
                        placeholder={jsonField.placeholder}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  <Col sm={jsonField.colWidth} key={key}>
                    <FormGroup>
                      <label className="form-control-label">
                        {jsonField.label}
                      </label>
                      <Select
                        onChange={(e) =>
                          handleOnChange(
                            e.value,
                            jsonField.id,
                            jsonField.required,
                            jsonField.factor,
                            e.label
                          )
                        }
                        key={jsonField.id}
                        id={jsonField.id}
                        factor={jsonField.id}
                        colWidth={jsonField.colWidth}
                        name={jsonField.id}
                        required={false}
                        options={
                          optionsDropdowns &&
                          optionsDropdowns[jsonField.options]
                        }
                        className="basic-single"
                        type={jsonField.type}
                        placeholder={jsonField.placeholder}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col sm={4}></Col>
              </Row>
            ))}
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default GeneralDataForm;
