// core actions
import { approvedVacantPosition, findUnpprovedVacants, unapprovedVacantPosition } from "actions/newPosition.jsx";
// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// core components Shared
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import Table from "components/Shared/Table/Table.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Container, Form as FormRS, FormGroup, Input, Label, Modal, Row } from "reactstrap";
// core selectors
import { getVacantsPositions } from 'selectors/newPosition.jsx';

class WorkFlow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      action: null,
      showModal: false,
      row: {},
      loading: props.vacants.length ? false : true,
      page: 1,
      sizePerPage: 5
    };
  }

  componentWillMount() {
    this.props.findUnpprovedVacants().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Sin Resultados", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState({
        loading: false
      });
    });
  }

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state],
      row: {}
    });
  };

  approvedRequestVacantPosition = values => {
    const { row: { id } } = this.state;
    this.setState({
      loading: true
    });
    this.props.approvedVacantPosition(id, values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading: false,
        showModal: false,
        row: {},
      });
    });
  }

  unapprovedRequestVacantPosition = values => {
    const { row: { id } } = this.state;
    this.setState({
      loading: true
    });
    this.props.unapprovedVacantPosition(id, values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState({
          loading: false,
        });
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          loading: false,
          showModal: false,
          row: {},
        });
      }
    });
  }

  approvedOrUnapprovedRequest = (row, action) => {
    this.setState({
      showModal: true,
      row,
      action
    });
  }

  validate = values => {
    const errors = {};
    if (!values.commentary)
      errors.commentary = "Debes ingresar un comentario para la acción.";
    return errors;
  }

  handleOnBack = () => {
    this.props.history.goBack()
  }

  expandRowStaffVacant = {
    parentClassName: '#2dce89',
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tiene EPM</span> <br />
              {row.haveEPM}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>¿Es Gerente?</span> <br />
              {row.isManager}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Productividad</span> <br />
              {row.productivity}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Local - Regional (PLA)</span> <br />
              {row.localRegionalType}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>% Fijo</span> <br />
              {row.fixedPercent}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>% Variable</span> <br />
              {row.variablePercent}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Cambios realizados</span> <br />
              {row.reasonForChanges}
            </div>
          </div>
        </span>
      </div>
    ),
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  }

  render() {

    const { vacants } = this.props;

    const { action, showModal, loading, page, sizePerPage } = this.state;

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc"
      }
    ];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={showModal}
          toggle={() => this.toggleModal("showModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <h4>Justificación de la acción realizada</h4>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form
                  onSubmit={action === 1 ? this.approvedRequestVacantPosition : (action === 2 ? this.unapprovedRequestVacantPosition : null)}
                  validate={this.validate}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <Spin size="large" spinning={loading}>
                      <FormRS role="form">
                        <FormGroup>
                          <Label for="commentary">Comentarios (500)</Label>
                          <Field name="commentary">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="textarea"
                                  placeholder="Escriba aquí si tiene algún comentario."
                                  maxLength={500}
                                  rows="3"
                                  resize="none"
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                        <div className="text-center">
                          <Button
                            disabled={validating || submitting}
                            className="my-4"
                            color="info"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Guardar
                    </Button>
                        </div>
                      </FormRS>
                    </Spin>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </Modal>
        <AdminHeader name="Aprobación de solicitudes vacantes" parentName="Nueva Posición" />
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col sm="12">
              <Spin size="large" spinning={loading}>
                <Table
                  title="Solicitudes para la modificación de posiciones vacantes"
                  subtitle="Lista de todas las solicitudes realizadas por Human Capital, que se encuentran pendientes de una aprobación."
                  columns={[
                    {
                      dataField: "positionNumber",
                      text: "Numero de posición",
                      sort: true
                    },
                    {
                      dataField: "changeRequestDate",
                      text: "Fecha de cambio",
                      sort: true
                    },
                    {
                      dataField: "managerPositionNumber",
                      text: "Posición de gerente",
                      sort: true
                    },
                    // {
                    //   dataField: "haveEPM",
                    //   text: "Tiene EPM",
                    //   sort: true
                    // },
                    // {
                    //   dataField: "isManager",
                    //   text: "Es Gerente",
                    //   sort: true
                    // },
                    // {
                    //   dataField: "productivity",
                    //   text: "Productividad",
                    //   sort: true
                    // },
                    // {
                    //   dataField: "localRegionalType",
                    //   text: "Local - Regional (PLA)",
                    //   sort: true
                    // },
                    // {
                    //   dataField: "fixedPercent",
                    //   text: "% Fijo",
                    //   sort: true
                    // },
                    // {
                    //   dataField: "variablePercent",
                    //   text: "% Variable",
                    //   sort: true
                    // },
                    {
                      dataField: "position",
                      text: "Posición",
                      sort: true
                    },
                    {
                      dataField: "careerLevel",
                      text: "Nivel de Carrera",
                      sort: true
                    },
                    {
                      dataField: "uniOrg",
                      text: "Unidad Organizacional",
                      sort: true
                    },
                    {
                      dataField: "ceco",
                      text: "Centro de Costo",
                      sort: true
                    },
                    {
                      dataField: "personalArea",
                      text: "Area Personal",
                      sort: true
                    },
                    {
                      dataField: "createdBy",
                      text: "Solicitante",
                      sort: true
                    },
                    // {
                    //   dataField: "reasonForChanges",
                    //   text: "Cambios realizados",
                    //   sort: true
                    // },
                    {
                      dataField: "actions",
                      isDummyField: true,
                      text: "",
                      // hidden: !showButtonsEdit,
                      formatter: (cellContent, row) => {
                        return (
                          <div>
                            <Button
                              onClick={() => this.approvedOrUnapprovedRequest(row, 2)}
                              className="btn-icon btn-2"
                              color="danger"
                              type="button"
                              size="sm"
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-times"></i>
                              </span>
                            </Button>
                            <Button
                              onClick={() => this.approvedOrUnapprovedRequest(row, 1)}
                              className="btn-icon btn-2"
                              color="info"
                              type="button"
                              size="sm"
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-check"></i>
                              </span>
                            </Button>
                          </div>
                        );
                      }
                    }
                  ]}
                  data={vacants}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={vacants.length}
                  defaultSorted={defaultSorted}
                  buttonBack={this.handleOnBack}
                  expandFeatrue={this.expandRowStaffVacant}
                />
              </Spin>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

WorkFlow.defaultProps = {
  vacants: [],
}

WorkFlow.propTypes = {
  vacants: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  vacants: getVacantsPositions(state)
});

export default withRouter(connect(
  mapStateToProps,
  {
    approvedVacantPosition,
    findUnpprovedVacants,
    unapprovedVacantPosition
  })(WorkFlow));
