import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const performance = handleActions({
  [constants.GET_ALL_DATA_DASHBOARD_PERFORMANCE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const signinsGraphs = handleActions({
  [constants.GET_ALL_SIGNIN_DASHBOARD_PERFORMANCE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const transactionsGraphs = handleActions({
  [constants.GET_ALL_TRANSACTIONS_DASHBOARD_PERFORMANCE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});