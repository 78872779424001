import { Col, FormGroup, Input, Row, InputGroup, InputGroupAddon, Button } from "reactstrap";
// react-select library for filters
import Select from "react-select";
//Hook
import React, { useState } from 'react';

const EditFormOne = ({
    row,
    documentType,
    country,
    estado,
    displayI,
    handleOnChange,
    setUpdateInfo
}) => {

    //inputs y elementos del formulario
    const [fields, setFields] = useState([
        {
            colWidth: "4",
            id: "documentId",
            defaultValue: "documentId",
            placeholder: "Id de la PO / SO",
            type: "number",
            min: 0,
            max: 1999999999,
            label: "Id del documento",
            elementType: "Input",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            id: "documentType",
            defaultValue: "documentType",
            placeholder: "Tipo de Documento",
            type: "select",
            min: 0,
            max: 1999999999,
            label: "Tipo de Documento",
            elementType: "select",
            disabled: true,
            options: documentType,
            value: documentType.label,
            defaultValueLabel: "typeText",
            defaultValueValue: "documentType",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "País",
            placeholder: "País",
            id: "country",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "country",
            disabled: true,
            options: country,
            value: country.label,
            defaultValueLabel: "countryName",
            defaultValueValue: "country",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: "Cliente",
            placeholder: "Cliente",
            id: "customerName",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "customerName",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "6",
            elementType: "Input",
            label: "Proveedores",
            placeholder: "Proveedores",
            id: "documentVendor",
            type: "textarea",
            min: 0,
            max: 1999999999,
            defaultValue: "documentVendor",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "3",
            elementType: "Input",
            label: "Usuario Solicitante",
            placeholder: "Usuario Solicitante",
            id: "createdBy",
            type: "text",
            min: 0,
            max: 1999999999,
            defaultValue: "createdBy",
            disabled: false,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
        {
            colWidth: "4",
            elementType: "select",
            label: "Estado",
            placeholder: "Seleccione un Estado",
            id: "status",
            type: "select",
            min: 0,
            max: 1999999999,
            defaultValue: "status",
            disabled: true,
            options: estado,
            value: estado.label,
            defaultValueLabel: "statusName",
            defaultValueValue: "status",
        },
        {
            colWidth: "5",
            id: "documentTrading",
            defaultValue: "documentTrading",
            placeholder: "PO Trading",
            type: "number",
            min: 0,
            max: 11,
            label: "PO Trading",
            elementType: "Input",
            disabled: true,
            options: [],
            value: [],
            defaultValueLabel: "",
            defaultValueValue: "",
        },
    ]);

    
    const [poTradings, setPoTradings] = useState(
        row["documentTrading"] !== null && typeof row["documentTrading"] !== 'undefined' ? row["documentTrading"].split(",") : [""]
    );

    //funcion para agregar o eliminar POtrading
    const plusMinusButton = (typee, i) => {

        if (typee === "plus") {
            setPoTradings([...poTradings, ""]); //agrega un nuevo valor en el array
        }
        else {
            //minus
            let newPoTradings = [...poTradings];
            newPoTradings.splice(i, 1);
            setPoTradings(newPoTradings);
            //elimina el valor del array poTradings de acuerdo al indice seleccionado

            //eliminar la PO del state que se envia al BE
            // delete updateInfo["documentTrading" + i];
            setUpdateInfo(prevState => ({
                ...prevState,
                documentTrading: newPoTradings
            }));
        }
    }
    const handleChangePoTrading = (i, value) => {
        let newPoTradings = [...poTradings];
        newPoTradings[i] = value;
        setPoTradings(newPoTradings);

        setUpdateInfo(prevState => ({
            ...prevState,
            documentTrading: newPoTradings
        }));
    }
    return (
        <>
            <Row className="align-items-center">
                {
                    fields.map((field, key) => (
                        <Col xs={field.colWidth} key={key}>
                            <FormGroup key={key} >
                                <label className="form-control-label">{field.label}</label>
                                {
                                    field.id === "documentTrading" ?
                                        poTradings.length > 0 ?
                                            poTradings.map((row, key3) => {
                                                return (
                                                    <InputGroup key={key3} className="input-group-merge">
                                                        <Input
                                                            key={field.id + key3}
                                                            id={field.id + key3}
                                                            name={field.id + key3}
                                                            className="form-control"
                                                            type={field.type}
                                                            min={field.min}
                                                            max={field.max}
                                                            readOnly={!field.disabled ? true : displayI}
                                                            defaultValue={row}
                                                            placeholder={field.placeholder}
                                                            onChange={(e) => handleChangePoTrading(key3, e.target.value)}
                                                        />
                                                        <InputGroupAddon size="sm" addonType="append">
                                                            <Button key={"button" + key3} color={key3 === 0 ? "success" : "danger"} //key3 = 0 significa el primer valor
                                                                size="sm"
                                                                // style={{ borderRadius: "40px" }}
                                                                onClick={(e) => plusMinusButton(key3 === 0 ? "plus" : "minus", key3)}
                                                                disabled={poTradings.length === 15 && key3 === 0 ? true : false}>
                                                                <span className="btn-inner--icon mr-">
                                                                    <i className={key3 === 0 ? "fas fa-plus" : "fas fa-minus"} />
                                                                </span>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                )
                                            }) :
                                            <InputGroup className="input-group-merge">
                                                <Input
                                                    key={field.id + "0"}
                                                    id={field.id + "0"}
                                                    name={field.id + "0"}
                                                    className="form-control"
                                                    type={field.type}
                                                    min={field.min}
                                                    max={field.max}
                                                    readOnly={!field.disabled ? true : displayI}
                                                    // defaultValue={""}
                                                    placeholder={field.placeholder}
                                                    onChange={(e) => handleChangePoTrading(field.id + "0", e.target.value)}
                                                />
                                                <InputGroupAddon size="sm" addonType="append">
                                                    <Button color={"success"}
                                                        size="sm"
                                                        // style={{ borderRadius: "40px" }}
                                                        onClick={(e) => plusMinusButton("plus", 0)}
                                                        disabled={false}>
                                                        <span className="btn-inner--icon mr-">
                                                            <i className={"fas fa-plus"} />
                                                        </span>
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>

                                        : field.id === "documentVendor" ?
                                            row[field.defaultValue] !== null && typeof row[field.defaultValue] !== 'undefined' ?
                                                <Input
                                                    key={field.id}
                                                    id={field.id}
                                                    name={field.id}
                                                    className="form-control"
                                                    type={field.type}
                                                    readOnly={!field.disabled ? true : displayI}
                                                    defaultValue={row[field.defaultValue].split(",").join('\n')}
                                                    placeholder={field.placeholder}
                                                />
                                                :
                                                <Input
                                                    key={field.id}
                                                    id={field.id}
                                                    name={field.id}
                                                    className="form-control"
                                                    type={field.type}
                                                    readOnly={!field.disabled ? true : displayI}
                                                    defaultValue={""}
                                                    placeholder={field.placeholder}
                                                />

                                            : field.elementType === "Input" ?
                                                <Input
                                                    key={field.id}
                                                    id={field.id}
                                                    name={field.id}
                                                    className="form-control"
                                                    type={field.type}
                                                    min={field.min}
                                                    max={field.max}
                                                    readOnly={!field.disabled ? true : displayI}
                                                    defaultValue={row[field.defaultValue]}
                                                    placeholder={field.placeholder}
                                                    onChange={(e) => handleOnChange(field.id, e.target.value)}
                                                />
                                                :
                                                <Select
                                                    key={field.id}
                                                    id={field.id}
                                                    name={field.id}
                                                    className="basic-single"
                                                    type={field.type}
                                                    placeholder={field.placeholder}
                                                    isSearchable
                                                    isDisabled={!field.disabled ? true : displayI}
                                                    options={field.options}
                                                    defaultValue={{ label: row[field.defaultValueLabel], value: row[field.defaultValueValue] }}
                                                    onChange={(e) => handleOnChange(field.id, e.value)}
                                                />
                                }
                            </FormGroup>
                        </Col>
                    ))
                }
            </Row>
        </>
    );
};

export default EditFormOne;