import { updatePurchaseOrderMacro } from 'actions/panamaBids.jsx';
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Modal, ModalBody,
  ModalFooter, ModalHeader, Nav,
  NavItem,
  NavLink,
  Row, TabContent,
  TabPane
} from "reactstrap";
import AgreementEditFormOne from "./AgreementEditFormOne";
import AgreementEditFormThree from "./AgreementEditFormThree";
import AgreementEditFormTwo from "./AgreementEditFormTwo";
const AgreementEditModal = ({
  showModal,
  dataModal,
  options,
  setDataModal,
  Toast,
  setrefreshInput,
  handleCloseModal,
  setRowsModal,
  rowsModal,
  activeTab,
  setActiveTab,
  isDisplay,
  isDisplayBF
}) => {
  const dispatch = useDispatch();
  const [editProducts, setEditProducts] = useState([]);
  const [alert, setAlert] = useState("");
  const handleTab = (event, tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleChangeInfo = (constant, e, constantSelect, num) => {
    if (constant === "gbmStatus" || constant === "performanceBond" || constant === "orderStatus" || constant === "forecastType") {
      setDataModal((prevState) => ({
        ...prevState,
        [constant]: e,
        [constantSelect]: num
      }))
    } else {
      setDataModal((prevState) => ({
        ...prevState,
        [constant]: e
      }))
    }
  }
  useEffect(() => {
    let value = 0;
    for (const item of rowsModal.products) {
      value += item.Total
    }
    setRowsModal(prevState => ({
      ...prevState,
      subtotal: value
    }))
  }, [activeTab])

  const handleOnEditPurchase = () => {
    dispatch(updatePurchaseOrderMacro({ info: dataModal, products: editProducts })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        handleCloseModal();
        setrefreshInput(3);
        Toast.fire({
          title: "Atención",
          html: "Se guardó correctamente",
          type: 'success'
        });
        setAlert("")
      }
    }
    )
  }
  const handleOnEditAgreement = (e, row) => {
    setAlert(
      <ReactBSAlert
        custom
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Está seguro(a) que desea guardar los cambios?"
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        onConfirm={() => handleOnEditPurchase(e, row)}
        onCancel={() => setAlert("")}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sí, guardar"
        cancelBtnText="No, cancelar"
        btnSize="md"
      />
    )
  }
  const handleOnChangeProduct = (constant, e) => {
    setEditProducts((prevState) => ({
      ...prevState,
      [constant]: e
    }))
  }
  return (
    <div>
      {alert}
      <Modal className="modal-xl" isOpen={showModal} style={{ minWidth: "900px", maxWidth: "900px" }}>
        <ModalHeader className="d-flex justify-content-center">
          {"Registro Unico de Pedido: "}
          <a rel="noreferrer" href={dataModal.documentLink} target="_blank" style={{ color: "blue" }}>
            {dataModal.singleOrderRecord}
          </a>
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "1"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "1",
                    })}
                    onClick={(e) => handleTab(e, "1")}
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos Generales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "2"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "2",
                    })}
                    onClick={(e) => handleTab(e, "2")}
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos SAP
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={activeTab === "3"}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: activeTab === "3",
                    })}
                    onClick={(e) => handleTab(e, "3")}
                    // href='#AUopp'
                    role="tab"
                  >
                    <i className="ni ni-badge mr-2" />
                    Datos Adicionales
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <AgreementEditFormOne
                dataModal={dataModal}
                handleChangeInfo={handleChangeInfo}
                isDisplay={isDisplay}
                isDisplayBF={isDisplayBF}
              />
            </TabPane>
            <TabPane tabId="2">
              <AgreementEditFormTwo
                dataModal={dataModal}
                options={options}
                products={rowsModal}
                handleChangeInfo={handleChangeInfo}
                onChangeProduct={handleOnChangeProduct}
                isDisplay={isDisplay}
                isDisplayBF={isDisplayBF}

              />
            </TabPane>
            <TabPane tabId="3">
              <AgreementEditFormThree
                dataModal={dataModal}
                files={rowsModal.files}
                handleChangeInfo={handleChangeInfo}
                isDisplay={isDisplay}
                isDisplayBF={isDisplayBF}
                Toast={Toast}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Button color="danger" onClick={() => handleCloseModal()}>
              Cancelar
            </Button>
            {isDisplay === true || isDisplayBF == true ?
              <></>
              : (
                <Button color="success" onClick={() => handleOnEditAgreement()}>
                  Guardar
                </Button >
              )
            }
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AgreementEditModal;
