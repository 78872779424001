import React from "react";
// reactstrap components
import { CardBody, CardTitle, Row, Col, Card } from "reactstrap";
// core components
import { PropTypes } from "prop-types";

const WidgetsInfo = props => {
  const { hours, subHoursMonth } = props;
  return (
    <>
      <Row>
        <Col md="6" xl="3">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Horas aprobadas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {hours.approved === null ? 0 : hours.approved}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                    <i className="ni ni-check-bold" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-3">
                  <i className="fa fa-clock" />{" "}
                  {subHoursMonth.approved === null ? 0 : subHoursMonth.approved}{" "}
                  horas
                </span>{" "}
                <span className="text-nowrap">mes actual</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md="6" xl="3">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Horas rechazadas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {hours.denied === null ? 0 : hours.denied}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                    <i className="ni ni-fat-remove" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-danger mr-3">
                  <i className="fa fa-clock" />{" "}
                  {subHoursMonth.denied === null ? 0 : subHoursMonth.denied}{" "}
                  horas
                </span>{" "}
                <span className="text-nowrap">mes actual</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md="6" xl="3">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Horas en espera
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {hours.wait === null ? 0 : hours.wait}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-yellow text-white rounded-circle shadow">
                    <i className="ni ni-watch-time" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-yellow mr-3">
                  <i className="fa fa-clock" />{" "}
                  {subHoursMonth.wait === null ? 0 : subHoursMonth.wait} horas
                </span>{" "}
                <span className="text-nowrap">mes actual</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md="6" xl="3">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Registradas en SAP
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {hours.sendedSAP === null ? 0 : hours.sendedSAP}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                    <i className="ni ni-chart-bar-32" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-primary mr-2">
                  <i className="fa fa-clock" />{" "}
                  {hours.toSendSAP === null ? 0 : hours.toSendSAP} horas
                </span>{" "}
                <span className="text-nowrap">por registrar</span>
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

WidgetsInfo.propTypes = {
  hours: PropTypes.object.isRequired,
  subHoursMonth: PropTypes.object.isRequired
};

export default WidgetsInfo;
