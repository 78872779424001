import AdminHeader from 'components/Shared/Header/AdminHeader.jsx'
import {
    Container,
    Col,
    Row,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap'

// antd components
import { message, Spin } from 'antd';
//Token 
import { getUserProtected, getTeamsUserAccess } from "selectors/adminLayout";
import Select2 from 'react-select2-wrapper'
import React, { useState, useEffect, Fragment } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import BlockUi from 'react-block-ui'
import { Loader } from 'react-loaders'
import StatsCard from 'components/Shared/Cards/StatsCard.jsx'
import HistoryLog from 'components/Contacts/HistoryLog.jsx'
import CustomerLog from 'components/Contacts/CustomerLog.jsx'
import YearChart from 'components/Contacts/YearChart.jsx'
import UpdateGoal from 'components/Contacts/UpdateGoal.jsx'
import {
    getEmployeeStats,
    statsCardInfo,
    statsYears,
    defaultEmployees,
    getManagerStats,
    getCustomerInfo,
    colors
} from 'actions/contacts.jsx'
import { useDispatch } from 'react-redux'

const Analytics = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [loaderType, setLoaderType] = useState('line-scale')
    const [data, setData] = useState()

    const [general, setGeneral] = useState({
        creaciones: 0,
        modificaciones: 0,
        inactivaciones: 0,
        clientes: 0,
        confirmados: 0,
        totalContactos: 0,
        annos: [],
        infoAnual: [],
        info: statsCardInfo
    })

    const [managerial, setManagerial] = useState({
        isManager: false,
        employees: defaultEmployees,
        employeesAll: [],
        years: statsYears,
        customerTable: [],
        selectedEmployee: [],
        selectedCustomer: null,
        selectedYear: null,
        employeeData: []
    })

    const { selectedEmployee, selectedYear, employeeData } = managerial
    const { confirmados, totalContactos } = general

    useEffect(() => {
        const teams = getTeamsUserAccess();
        const token = getUserProtected();

        if (teams.some((row) => row.indexOf("GBM Contact Sales Representative") !== -1)) {
            // es un vendedor ESTO ES LO DEL IF
            const getInfo = async () => {
                dispatch(
                    getEmployeeStats({
                        employeeId: token.IDCOLABC,
                        user: token.EMAIL.split("@")[0],
                        name: token.NOMBRE,
                        position: token.POSICION
                    })
                ).then(response => {
                    const { payload } = response
                    if (payload.status === 200) {
                        setGeneral(x => ({
                            ...x,
                            creaciones: payload.data.payload.cantidadCreaciones,
                            modificaciones: payload.data.payload.cantidadModificaciones,
                            inactivaciones: payload.data.payload.cantidadInactivaciones,
                            confirmados: payload.data.payload.cantidadConfirmados,
                            totalContactos: payload.data.payload.totalContactos,
                            clientes: payload.data.payload.totalClientes,
                            annos: payload.data.payload.cantidadAnnos,
                            infoAnual: payload.data.payload.informacionAnual,
                            info: [
                                {
                                    title: payload.data.payload.cantidadCreaciones,
                                    subTitle: 'Creaciones mes actual',
                                    color: 'success'
                                },
                                {
                                    title: payload.data.payload.cantidadModificaciones,
                                    subTitle: 'Modificaciones mes actual',
                                    color: 'warning'
                                },
                                {
                                    title: payload.data.payload.cantidadInactivaciones,
                                    subTitle: 'Inactivaciones mes actual',
                                    color: 'danger'
                                },
                                {
                                    title: payload.data.payload.totalClientes,
                                    subTitle: 'Clientes asignados',
                                    color: 'info'
                                }
                            ]
                        }))
                        setData(payload.data.payload.infoTabla)
                        setLoading(false)
                    }
                })
            }
            const getData = async () => {
                await getInfo()
            }
            getData()
        } else {
            //ESTE ES EL ELSE
            const getInfoManager = async () => {
                dispatch(
                    getManagerStats()
                ).then(response => {
                    const { payload } = response
                    if (payload.status === 200) {
                        setManagerial(x => ({
                            ...x,
                            customerTable: payload.data.payload.statInfo,
                            employeesAll: payload.data.payload.empleados,
                            employees: payload.data.payload.empleadoSelect,
                            isManager: true
                        }))
                        setData(payload.data.payload.infoTabla)
                        setLoading(false)
                    }
                })
            }
            const getData = async () => {
                await getInfoManager()
            }
            getData()
        }
    }, [])
    //Funcion para llamar estadisticas de un empleado
    useEffect(() => {

        if (
            selectedEmployee.length !== 0 &&
            selectedYear !== undefined &&
            selectedYear !== null &&
            selectedYear !== ''
        ) {
            setLoading(true)
            let user = selectedEmployee[0].id
            let id = selectedEmployee[0].uid

            const getInfo = async (id, user) => {
                dispatch(
                    getEmployeeStats({
                        employeeId: id,
                        user: user,
                        name: user,
                        position: 'Sales Representative'
                    })
                ).then(response => {
                    const { payload } = response
                    if (payload.status === 200) {
                        const infoYears = payload.data.payload.informacionAnual
                        let yearsGraph = null
                        if (infoYears.length > 0) {
                            yearsGraph = infoYears.filter(x => x.year == selectedYear)
                            if (yearsGraph.length > 0) {
                                let dSets = [
                                    {
                                        label: 'Creaciones',
                                        data: yearsGraph[0].data.create,
                                        borderColor: colors.theme['success'],
                                        backgroundColor: colors.theme['success'],
                                        fill: false
                                    },
                                    {
                                        label: 'Modificaciones',
                                        data: yearsGraph[0].data.modify,
                                        borderColor: colors.theme['info'],
                                        backgroundColor: colors.theme['info'],
                                        fill: false
                                    }
                                ]

                                setManagerial(x => ({ ...x, employeeData: dSets }))
                            } else {
                                let dSets = [
                                    {
                                        label: 'Creaciones',
                                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                        borderColor: colors.theme['success'],
                                        backgroundColor: colors.theme['success'],
                                        fill: false
                                    },
                                    {
                                        label: 'Modificaciones',
                                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                        borderColor: colors.theme['info'],
                                        backgroundColor: colors.theme['info'],
                                        fill: false
                                    }
                                ]

                                setManagerial(x => ({ ...x, employeeData: dSets }))
                            }
                        } else {
                            let dSets = [
                                {
                                    label: 'Creaciones',
                                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                    borderColor: colors.theme['success'],
                                    backgroundColor: colors.theme['success'],
                                    fill: false
                                },
                                {
                                    label: 'Modificaciones',
                                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                    borderColor: colors.theme['info'],
                                    backgroundColor: colors.theme['info'],
                                    fill: false
                                }
                            ]

                            setManagerial(x => ({ ...x, employeeData: dSets }))
                        }

                        setGeneral(x => ({
                            ...x,
                            creaciones: payload.data.payload.cantidadCreaciones,
                            modificaciones: payload.data.payload.cantidadModificaciones,
                            inactivaciones: payload.data.payload.cantidadInactivaciones,
                            confirmados: payload.data.payload.cantidadConfirmados,
                            totalContactos: payload.data.payload.totalContactos,
                            clientes: payload.data.payload.totalClientes,
                            annos: payload.data.payload.cantidadAnnos,
                            info: [
                                {
                                    title: payload.data.payload.cantidadCreaciones,
                                    subTitle: 'Creaciones mes actual',
                                    color: 'success'
                                },
                                {
                                    title: payload.data.payload.cantidadModificaciones,
                                    subTitle: 'Modificaciones mes actual',
                                    color: 'warning'
                                },
                                {
                                    title: payload.data.payload.cantidadInactivaciones,
                                    subTitle: 'Inactivaciones mes actual',
                                    color: 'danger'
                                },
                                {
                                    title: payload.data.payload.totalClientes,
                                    subTitle: 'Clientes asignados',
                                    color: 'info'
                                }
                            ]
                        }))
                        setData(payload.data.payload.infoTabla)
                    }
                    setLoading(false)
                })
            }

            const getData = async () => {
                await getInfo(id, user)
            }
            getData()
        } else {
            setGeneral(x => ({ ...x, confirmados: 0, totalContactos: 1 }))
            setData([])
        }
    }, [selectedEmployee, selectedYear])

    const handleEmployeeChange = event => {
        if (event.length > 0) {
            setManagerial(x => ({ ...x, selectedEmployee: event }))
        } else {
            setManagerial(x => ({ ...x, selectedEmployee: [], employeeData: [] }))
        }
    }

    const handleYearChange = event => {
        if (
            event.target.value !== undefined &&
            event.target.value !== null &&
            event.target.value !== ''
        ) {
            setManagerial(x => ({ ...x, selectedYear: event.target.value }))
        }
    }

    return (
        <>
            <AdminHeader name='Estadisticas' parentName='Contactos' />
            <Spin size="large" spinning={loading}>

                <Container className='mt--6' fluid>
                    <Row className='justify-content-center'>
                        <Col xl='12' md='6' xs='12'>
                            {!managerial.isManager && (
                                <StatsCard
                                    cols={{ xl: '3', sm: '6' }}
                                    info={general.info}
                                    title='Estadisticas de la plataforma'
                                />
                            )}
                            {managerial.isManager && (
                                <>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle>Estadisticas de la plataforma</CardTitle>
                                            <CardText className='card-text font-small-2 mr-25 mb-0'>
                                                Seleccione el empleado y el año correspondientes
                                            </CardText>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col lg='4'></Col>
                                                <Col lg='4'>
                                                    <Typeahead
                                                        clearButton
                                                        multiple={false}
                                                        minLength={2}
                                                        id='select-employee'
                                                        labelKey='text'
                                                        options={managerial.employees}
                                                        placeholder='Seleccionar empleado'
                                                        onChange={x => handleEmployeeChange(x)}
                                                        value={managerial.selectedEmployee}
                                                        renderMenuItemChildren={option => (
                                                            <Fragment>
                                                                <div>
                                                                    {option.text}
                                                                    <div>
                                                                        <small>Id: {option.uid}</small>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )}
                                                    />
                                                </Col>
                                                <Col lg='4'>
                                                    <Select2
                                                        className='form-control text-end float-right'
                                                        id='select-year'
                                                        options={{
                                                            placeholder: 'Seleccionar año'
                                                        }}
                                                        data={managerial.years}
                                                        value={managerial.selectedYear}
                                                        onChange={handleYearChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col xl='12'>
                            <YearChart
                                years={general.annos}
                                yearsInfo={general.infoAnual}
                                isManager={managerial.isManager}
                                selectedData={employeeData}
                            />
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col xl='3'>
                            <UpdateGoal
                                confirmados={confirmados}
                                total={totalContactos}
                                selectedEmployee={managerial.selectedEmployee}
                                isManager={managerial.isManager}
                            />
                        </Col>
                        <Col xl='9'>
                            <HistoryLog data={data} />
                        </Col>
                    </Row>
                    {managerial.isManager && (
                        <Row className='justify-content-center'>
                            <Col xl='12'>
                                <CustomerLog data={managerial.customerTable} />
                            </Col>
                        </Row>
                    )}
                    <Row className='justify-content-center'>
                        <Col xl='12'>
                            <Card>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Spin>
        </>
    )
}

export default Analytics
