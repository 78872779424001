import React, { useState } from 'react';
import {
    Card,
    CardBody,
    Row,
    Table,
    CardFooter
} from "reactstrap";
const CompetitionEditFormTwo = ({ products }) => {
    const colTable = [
        {
            value: "productDescription",
            label: "Producto o Servicio"
        },
        {
            value: "gbmParticipate",
            label: "GBM participa?"
        },
        {
            value: "productLine",
            label: "Linea producto"
        },
        {
            value: "unitPrice",
            label: "Costo Unitario"
        },
        {
            value: "amount",
            label: "Cantidad"
        },
        {
            value: "typeProduct",
            label: "Tipo Producto"
        },
        {
            value: "total",
            label: "Total"
        },
    ]
    return (
        <div>
            <Card>
                <CardBody>
                    <Row>
                        {
                            products.products.length > 0 && (
                                <Card>
                                    <CardBody>

                                        <Table
                                            className="align-items-center table-flush"
                                            responsive
                                            striped={true}
                                            hover={true}
                                        >
                                            <thead className="thead-light">
                                                <tr>
                                                    {
                                                        colTable.map((row, key) => {
                                                            return (
                                                                <th key={key} className="justify-content-md-center">
                                                                    {row.label}
                                                                </th>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className="list">
                                                {
                                                    products.products.map((row, key) => (
                                                        <tr key={key} className="text-center">
                                                            {
                                                                colTable.map((col, key) => (
                                                                    <td key={key} className="text-center">
                                                                        {
                                                                            row[col.value]

                                                                        }
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                    <CardFooter>
                                        <h4>{`SubTotal: ${products.subtotal}`}</h4>
                                    </CardFooter>
                                </Card>
                            )
                        }
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};


export default CompetitionEditFormTwo;