import moment from "moment";
import React from "react";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import Select from "react-select";

const AgreementCREditFormOne = ({
  info,
  handleChangeInfo,
  isDisabled,
  role,
  options
}) => {
  const rowsModal = [
    {
      colWidth: "8",
      label: "Institucion",
      id: "institution",
      type: "text",
      disabled: true
    },
    {
      colWidth: "4",
      label: "Tipo de Procedimiento",
      id: "processType",
      type: "select",
      disabled: true
    },
    {
      colWidth: "12",
      label: "Descripcion",
      id: "description",
      type: "text",
      disabled: true
    },
    {
      colWidth: "4",
      label: "Fecha de Publicacion",
      id: "publicationDate",
      type: "date",

    },
    {
      colWidth: "4",
      label: "Fecha de apertura de ofertas",
      id: "offerOpening",
      type: "date",

    },
    {
      colWidth: "4",
      label: "Cierre de recepcion de ofertas",
      id: "receptionClosing",
      type: "date",

    },
    {
      colWidth: "4",
      label: "Fecha limite de recepcion de Aclaracion",
      id: "receptionClarification",
      type: "date",

    },
    {
      colWidth: "4",
      label: "Fecha limite de recepcion de objeciones",
      id: "receptionObjections",
      type: "date",

    },
    {
      colWidth: "4",
      label: "Fecha de participacion",
      id: "changeDate",
      type: "date"

    },
    {
      colWidth: "4",
      label: "Dias Hábiles Para Vencimiento Del Tercio",
      id: "expirationThird",
      type: "text",

    },
    {
      colWidth: "4",
      label: "Dias Hábiles Para La Apertura",
      id: "openningDays",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Presupuesto total estimado",
      id: "budget",
      type: "text",
      disabled: true

    },
    {
      colWidth: "4",
      label: "Plazo de adjudicacion",
      id: "timeAdjudge",
      type: "text",
      disabled: true

    },
    {
      colWidth: "4",
      label: "Plazo maximo de entrega",
      id: "timeDelivery",
      type: "text",
      disabled: true

    },
    {
      colWidth: "4",
      label: "Vigencia",
      id: "offerValidity",
      type: "text",
      disabled: true

    },
  ];
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            {rowsModal.map((row, key) =>
              row.type === "text" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      className="form-control"
                      type={row.type}
                      disabled={row.disabled === true ? true : isDisabled}
                      defaultValue={info[row.id] || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : row.type === "date" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      type={row.type}
                      disabled={row.disabled === true ? true : isDisabled}
                      defaultValue={
                        moment.utc(info[row.id]).format("YYYY-MM-DD") || ""
                      }
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                      className="basic-multi-select text-dark"
                    ></Input>
                  </FormGroup>
                </Col>
              ) : (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Select
                      key={`input${key}`}
                      isDisabled={row.disabled === true ? true : isDisabled}
                      className="basic-single"
                      type="select"
                      isSearchable
                      defaultValue={{ label: info[row.id], value: 1 } || ""}
                      options={options[row.id]}
                      onChange={(e) => handleChangeInfo(row.id, e.value)}
                    />
                  </FormGroup>
                </Col>
              )
            )}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
export default AgreementCREditFormOne;
