export const getMasterVariablesByMasterTable = (state) => {
  if (Object.keys(state.masterVariables).length) {
    return state.masterVariables;
  }
  return {};
};

export const getUsersEscalationsTSS = (state) => {
  if (Object.keys(state.usersEscalationsTss).length) {
    const { users } = state.usersEscalationsTss;
    return users;
  }
  return {};
};
