// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const TransactionsLetters = props => {

  const { type, data } = props;

  return (
    <div className="header-body">
      {
        type === 1 ?
          <Row>
            <Col sm="12" md="6" xl="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Solicitudes Pendientes
                  </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {data.awaitingTotal}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow">
                        <i className="fas fa-exclamation" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fas fa-clock" /> {data.awaitingTotalMonth}
                    </span>{" "}
                    <span className="text-nowrap">En el presente mes.</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="6" xl="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Solicitudes Rechazadas
                  </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {data.rejectedTotal}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-danger text-white rounded-circle shadow">
                        <i className="fas fa-times" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fas fa-clock" /> {data.rejectedTotalMonth}
                    </span>{" "}
                    <span className="text-nowrap">En el presente mes.</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="6" xl="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Solicitudes Aprobadas
                  </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {data.approvedTotal}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                        <i className="fas fa-check" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fas fa-clock" /> {data.approvedTotalMonth}
                    </span>{" "}
                    <span className="text-nowrap">En el presente mes.</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="6" xl="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Solicitudes Aplicadas
                  </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {data.appliedTotal}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                        <i className="fas fa-check-double" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fas fa-clock" /> {data.appliedTotalMonth}
                    </span>{" "}
                    <span className="text-nowrap">En el presente mes.</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          :
          <Row>
            <Col sm="12" md="6" xl="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Solicitudes Creadas
                  </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {data.totalRequest}
                      </span>
                    </Col>
                    <Col sm="4">
                      <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow">
                        <i className="fas fa-file-pdf" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fas fa-clock" /> {data.totalRequestMonth}
                    </span>{" "}
                    <span className="text-nowrap">En el presente mes.</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="6" xl="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Pendiente HC Manager
                  </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {data.totalManagers}
                      </span>
                    </Col>
                    <Col sm="4">
                      <div className="icon icon-shape bg-gradient-danger text-white rounded-circle shadow">
                        <i className="fas fa-user-shield" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fas fa-clock" /> {data.totalManagersMonth}
                    </span>{" "}
                    <span className="text-nowrap">En el presente mes.</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="6" xl="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Pendiente Colaborador
                  </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {data.totalCollaborator}
                      </span>
                    </Col>
                    <Col sm="4">
                      <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                        <i className="fas fa-user-clock" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fas fa-clock" /> {data.totalCollaboratorMonth}
                    </span>{" "}
                    <span className="text-nowrap">En el presente mes.</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="6" xl="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Pendiente HC Payrrol
                  </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {data.totalPayrrols}
                      </span>
                    </Col>
                    <Col sm="4">
                      <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                        <i className="fas fa-user-check" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fas fa-clock" /> {data.totalPayrrolsMonth}
                    </span>{" "}
                    <span className="text-nowrap">En el presente mes.</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
      }
    </div>
  );
};

TransactionsLetters.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TransactionsLetters;