// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardTitle, Form as Col, FormGroup, Input, Label, Modal } from "reactstrap";

moment.locale("es");

const TermsAndConditions = props => {

  const {
    modalTermsAndConditions: {
      showModal,
    },
    loading,
    message,
    toggleModal,
    onSubmit
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.termsAndConditions || values.termsAndConditions === false)
      errors.termsAndConditions = "Debes aceptar los términos y condiciones."
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <Spin size="large" spinning={loading} tip={message}>
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <div>
              <CardBody className="px-md-2 py-md-2">
                <CardTitle className="h4 mb-1 text-center">DECLARACION DEL COLABORADOR CON RESPECTO A LA INFORMACIÓN SUMINISTRADA</CardTitle>
                <blockquote className="blockquote text-justify m-2" style={{ overflow: 'auto', height: '180px' }}>
                  <p className="m-2">
                    A través del formulario doy mi consentimiento informado para que GBM hago uso de la información acá indicada solamente para efectos de atender una eventual emergencia de salud, pandemia u otro evento que implique la atención médica del colaborador. La información será utilizada solamente por el Human Capital Manager y Lider del E-team y compartida con equipo médico sólo en caso de ser necesario. Como consecuencia de lo anterior autorizo a GBM para que almacene y utilice los datos médicos personales aquí previstos para los fines supra descritos.
                  </p>
                  <p className="m-2">
                    Además, estoy consciente de que información referente a enfermedades pandémicas o de fácil transmisión de persona a persona por motivos de salud pública deben ser reportadas a GBM lo más pronto posible.
                  </p>
                  <p className="m-2">
                    De igual forma. por este medio hago constar que las respuestas anteriores son correctas y verdaderas, que exonero a GBM de cualquier responsabilidad por omisión de información y estoy consciente que es mi responsabilidad como colaborador actualizar y mantener válida la información antes proporcionada.
                  </p>
                  <p className="m-2 text-danger">
                    Nota: Se informa a la persona que autoriza que, de conformidad con los derechos que le asisten, esta autorización puede ser revocada en el momento y por las razones que estime pertinentes
                  </p>
                </blockquote>
                <Form
                  onSubmit={onSubmit}
                  validate={validate}
                  render={({ handleSubmit, submitting, validating }) => (
                    <>
                      <div className="form-row align-items-center justify-content-center">
                        <FormGroup check>
                          <Field name="termsAndConditions" type="checkbox">
                            {({ input, meta }) => (
                              <>
                                <Label>
                                  <Input
                                    {...input}
                                    type="checkbox"
                                    invalid={meta.error && meta.touched}
                                  />{" "}
                                  Acepto los términos y condiciones.
                                </Label>
                                <div style={{ marginBottom: '1rem' }}>
                                  {
                                    meta.error && meta.touched && <span style={{
                                      fontSize: '80%',
                                      color: '#fb6340'
                                    }}>{meta.error}</span>
                                  }
                                </div>
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>
                      <div className="form-row align-items-center justify-content-center">
                        <Col className="col-auto">
                          <Button
                            disabled={validating}
                            className="btn-icon"
                            block
                            color="success"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-signature" />
                            </span>
                            <span className="btn-inner--text">Registrar</span>
                          </Button>
                        </Col>
                      </div>
                    </>
                  )}
                />
              </CardBody>
            </div>
          </Card>
        </div>
      </Spin>
    </Modal>
  );
};

TermsAndConditions.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default TermsAndConditions;