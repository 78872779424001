export const getNodes = state => state.nodes;

/**
 * Funcion para crear una lista con las opciones disponibles de torres para pasarlas a las tablas
 * @param {*} state
 */
export const getTowerOptions = state => {
  const towerOptions = {};
  for (const element of state.nodes) {
    if (element) {
      if (!towerOptions[element.ORG_ID]) {
        towerOptions[element.ORG_ID] = element.ORG_NAME;
      }
    }
  }
  return towerOptions;
};

/**
 * Funcion para crear una lista con las opciones disponibles de nodos para pasarlas a las tablas
 * @param {*} state
 */
export const getNodesOptions = state => {
  const nodesOptions = {};
  for (const element of state.resourses) {
    if (element) {
      for (const row of state.nodes) {
        if (row.id === parseInt(element.ORG_ID) && !nodesOptions[element.ORG_ID]) {
          nodesOptions[element.ORG_ID] = row.name;
        }
      }
    }
  }
  return nodesOptions;
}

/**
 * Funcion para obtener todos los recursos asociados a uno o mas nodos, se optienen del estado de la
 * aplicacion y pasarlos a los props
 * @param {*} state
 */
export const getResourses = state => state.resourses;

/**
 * Funcion para crear una lista con las tareas asignadas a  un recurso para pasarlas a las tablas
 * @param {*} state
 */
export const getTasks = (state, props) => {
  const resourse = state.resourses.find(data => data.EMPLOYEE_ID === props.match.params.id);
  if (resourse) {
    const { TASKS } = resourse;
    if (TASKS) {
      const data = TASKS.item.map((element, key) => {
        const object = JSON.parse(JSON.stringify(element));
        object.id = key;
        return object;
      });
      return data;
    }
  }
  return [];
}

/**
 * Funcion para crear una lista con las ordenes asignadas a un recurso para pasarlas a las tablas
 * @param {*} state
 */
export const getOrders = (state, props) => {
  const resourse = state.resourses.find(data => data.EMPLOYEE_ID === props.match.params.id);
  if (resourse) {
    const { ORDERS } = resourse;
    if (ORDERS) {
      const data = ORDERS.item.map((element, key) => {
        const object = JSON.parse(JSON.stringify(element));
        object.id = key;
        return object;
      });
      return data;
    }
  }
  return [];
}