import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const createRequest = createAction(constants.AS_CREATE_REQUEST, (body) =>
  method.post(urls.SALARY.createRequest, body)()
);

export const getApprovers = createAction(constants.AS_GET_APPROVERS, (body) =>
  method.put(`${urls.SALARY.getApprovers}`, body)()
);

// export const getApprovers = createAction(
//   constants.AS_GET_APPROVERS,
//   method.get(urls.SALARY.getApprovers)
// );

export const getOtherApprovers = createAction(
  constants.AS_GET_OTHER_APPROVERS,
  method.get(urls.SALARY.getOtherApprovers)
);

export const getRequest = createAction(constants.AS_GET_REQUEST, (id) =>
  method.get(`${urls.SALARY.getRequest}/${id}`)()
);

export const getCompleteRequests = createAction(
  constants.AS_COMPLETE_REQUESTS,
  method.get(urls.SALARY.getCompleteRequests)
);

export const getPendingRequests = createAction(
  constants.AS_PENDING_REQUESTS,
  method.get(urls.SALARY.getPendingRequests)
);

export const getCancelledRequests = createAction(
  constants.AS_CANCELLED_REQUESTS,
  method.get(urls.SALARY.getCancelledRequests)
);

//SESSION
// export const checkSession = createAction(
//   constants.AS_CHECK_SESSION,
//   method.get(urls.SALARY.verifySession)
// );

export const checkSession = createAction(
  constants.AS_CHECK_SESSION,
  (document) => method.get(`${urls.SALARY.verifySession}/${document}`)()
);

// export const showFullDocument = createAction(
//   constants.AS_FULL_DOC,
//   method.put(urls.SALARY.showFullDocument)
// );

export const showFullDocument = createAction(
  constants.AS_FULL_DOC,
  (document, body) =>
    method.put(`${urls.SALARY.showFullDocument}/${document}`, body)()
);

export const cancelRequest = createAction(constants.AS_CANCEL_REQUEST, (id) =>
  method.put(`${urls.SALARY.cancelRequest}/${id}`, {})()
);

export const approverAction = createAction(
  constants.AS_APPROVER_ACTION,
  (id, body) => method.post(`${urls.SALARY.approverAction}/${id}`, body)()
);

export const bypassAction = createAction(
  constants.AS_BYPASS_ACTION,
  (id, body) => method.fileUpload(`${urls.SALARY.documentUpload}/${id}`, body)()
);

export const reminder = createAction(constants.AS_REMINDER, (id) =>
  method.put(`${urls.SALARY.reminder}/${id}`, {})()
);

export const salaryNotifications = createAction(
  constants.AS_NOTIFICATIONS,
  () => method.get(`${urls.SALARY.notifications}`)()
);

export const downloadBypassDocument = createAction(
  constants.AS_DOCUMENT_DOWNLOAD,
  (id) => method.fileDownload(`${urls.SALARY.documentDownload}/${id}`)()
);

export const deleteDocument = createAction(constants.AS_DOCUMENT_DELETE, (id) =>
  method.put(`${urls.SALARY.documentDelete}/${id}`)()
);

export const insertOtherApprovers = createAction("OTHER_APPROVERS_INSERT", (body) =>
  method.post(urls.SALARY.otherApproversInsert, body)()
);
export const  deleteOtherApprovers= createAction("OTHER_APPROVERS_DELETE", (body) =>
  method.post(urls.SALARY.otherApproversDelete, body)()
);
export const getUserSign = createAction("GET_USERS_SIGN",
  () => method.get(`${urls.SALARY.getUserSign}`)()
);


