/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useState } from "react";
// reactstrap components
import { Badge, Button, Card, CardHeader, Col, Row, Table } from "reactstrap";

const EquipmentsList = (props) => {
  const {
    title,
    subtitle,
    cols,
    rows,
    status,
    assignUser,
    equipmentsJTR,
    equipmentsChecked,
    onBack,
    onAssignUser,
    onNoAssignUser,
    onAssignUserEquipment,
    onChangeEquipment,
  } = props;

  return (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md="7">
            <h6 className="text-white surtitle">{title}</h6>
            <h5 className="text-white h3 mb-0">{subtitle}</h5>
          </Col>
          {status !== 5 && !assignUser && (
            <Col
              className="d-flex align-items-center justify-content-end"
              xs="12"
              md="5"
            >
              <Button
                outline
                className="btn-round btn-icon"
                color="info"
                onClick={onAssignUser}
                size="sm"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-user-cog" />
                </span>
                <span className="btn-inner--text">
                  Asignación de Ingenieros
                </span>
              </Button>
            </Col>
          )}
          {status !== 5 && assignUser && (
            <Col
              className="d-flex align-items-center justify-content-end"
              xs="12"
              md="5"
            >
              <Row>
                <Col
                  className="d-flex align-items-center justify-content-end"
                  xs="12"
                  md="6"
                >
                  <Button
                    outline
                    className="btn-round btn-icon"
                    color="info"
                    onClick={onNoAssignUser}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-user-times" />
                    </span>
                    <span className="btn-inner--text">Cancelar Asignación</span>
                  </Button>
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-end"
                  xs="12"
                  md="6"
                >
                  <Button
                    outline
                    className="btn-round btn-icon"
                    disabled={!equipmentsChecked.length}
                    color="info"
                    onClick={onAssignUserEquipment}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-user-check" />
                    </span>
                    <span className="btn-inner--text">Asignar Ingeniero</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </CardHeader>
      <Table className="align-items-center table-dark table-flush" responsive>
        <thead className="thead-dark">
          <tr>
            {cols.map(
              (row) =>
                row.hide === false && (
                  <th
                    key={row.key}
                    style={{ whiteSpace: "normal", textAlign: "center" }}
                  >
                    {row.name}
                  </th>
                )
            )}
            {assignUser && <th>Asignar</th>}
          </tr>
        </thead>
        <tbody className="list">
          {assignUser
            ? rows
                .filter((ele) => ele.status !== "Completado")
                .map((row, key) => (
                  <tr key={key}>
                    {cols.map(
                      (col, key) =>
                        col.hide === false && (
                          <th
                            key={key}
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            {col.key === "status" ? (
                              <Badge color="info">{row[col.key]}</Badge>
                            ) : (
                              row[col.key]
                            )}
                          </th>
                        )
                    )}
                    {assignUser ? (
                      row.status === "Completado" ? (
                        <th
                          key={key}
                          style={{ whiteSpace: "normal", textAlign: "justify" }}
                        >
                          <a
                            className="table-action table-action"
                            // onClick={() => onSelectEquipment(row)}
                            // href="#"
                            id="edit"
                          >
                            <i className="fas fa-check-double" />
                          </a>
                        </th>
                      ) : (
                        <th
                          key={key}
                          style={{ whiteSpace: "normal", textAlign: "justify" }}
                        >
                          <label className="custom-toggle">
                            <input
                              id={row.id}
                              type="checkbox"
                              defaultChecked={equipmentsChecked.some(
                                (ele) => parseInt(ele.id) === row.id
                              )}
                              onChange={(e) => onChangeEquipment(row)}
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Sí"
                            />
                          </label>
                        </th>
                      )
                    ) : null}
                  </tr>
                ))
            : rows.map((row, key) => (
                <tr key={key}>
                  {cols.map(
                    (col, key) =>
                      col.hide === false && (
                        <th
                          key={key}
                          style={{ whiteSpace: "normal", textAlign: "center" }}
                        >
                          {col.key === "status" ? (
                            <Badge color="info">
                              {equipmentsJTR.some((ele) => ele.id === row.id)
                                ? "En Espera"
                                : row[col.key]}
                            </Badge>
                          ) : col.key === "engineer" ? (
                            equipmentsJTR.some((ele) => ele.id === row.id) ? (
                              "JTR"
                            ) : (
                              row[col.key]
                            )
                          ) : (
                            row[col.key]
                          )}
                        </th>
                      )
                  )}
                  {assignUser ? (
                    row.status === "Completado" ? (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "justify" }}
                      >
                        <a
                          className="table-action table-action"
                          // onClick={() => onSelectEquipment(row)}
                          // href="#"
                          id="edit"
                        >
                          <i className="fas fa-check-double" />
                        </a>
                      </th>
                    ) : (
                      <th
                        key={key}
                        style={{ whiteSpace: "normal", textAlign: "justify" }}
                      >
                        <label className="custom-toggle">
                          <input
                            id={row.id}
                            type="checkbox"
                            defaultChecked={equipmentsChecked.some(
                              (ele) => parseInt(ele.id) === row.id
                            )}
                            onChange={(e) => onChangeEquipment(e)}
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Sí"
                          />
                        </label>
                      </th>
                    )
                  ) : null}
                </tr>
              ))}
        </tbody>
      </Table>
      {!assignUser && (
        <div className="d-flex form-row justify-content-center align-items-center">
          <Col
            className="m-2 d-flex justify-content-center align-items-center"
            sm="12"
            md="3"
          >
            <Button
              block
              className="btn-icon"
              color="success"
              onClick={onBack}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Regresar</span>
            </Button>
          </Col>
        </div>
      )}
    </Card>
  );
};

EquipmentsList.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default EquipmentsList;
