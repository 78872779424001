// core antd
import { Drawer, Spin } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useEffect } from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const AddMasterDataDrawer = (props) => {
  const {
    type,
    title,
    visible,
    uploading,
    initialValues,
    toggleDrawer,
    onFinish,
  } = props;

  useEffect(() => {
    delete initialValues["test"];
  });

  const validate = (values) => {
    const errors = {};
    if (type === "cecos")
      if (!values.ceco || values.ceco === "")
        errors.ceco = "Debes ingresar un número de ceco";
    if (type === "ins") {
      if (!values.insNumber || values.insNumber === "")
        errors.insNumber = "Debes ingresar un número de INS";
      if (!values.key || values.key === "")
        errors.key = "Debes ingresar el key del ins";
    }
    if (type === "organizationalUnits") {
      if (!values.name || values.name === "")
        errors.name = "Debes ingresar un nombre de la unidad";
      if (!values.key || values.key === "")
        errors.key = "Debes ingresar el key de la unidad";
    }
    if (type === "positions") {
      if (!values.name || values.name === "")
        errors.name = "Debes ingresar el nombre de la posición";
      if (!values.insNumber || values.insNumber === "")
        errors.insNumber = "Debes ingresar un número de INS";
      if (!values.protection || values.protection === "0")
        errors.protection = "Debes seleccionar una opción";
      if (!values.localRegionalType || values.localRegionalType === "0")
        errors.localRegionalType = "Debes seleccionar una opción";
    }
    return errors;
  };

  return (
    <Drawer
      title={title}
      width={340}
      onClose={toggleDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        onSubmit={onFinish}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, values, submitting, validating, valid }) => (
          <Spin size="large" spinning={uploading} tip="Creando el dato maestro">
            <FormRS role="form">
              {type === "cecos" ? (
                <div className="form-row">
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="ceco">Número Ceco</Label>
                      <Field name="ceco">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Escriba aquí el ceco."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
              ) : null}
              {type === "ins" ? (
                <div className="form-row">
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="insNumber">Número INS</Label>
                      <Field name="insNumber">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="number"
                              placeholder="Escriba aquí el número de INS."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="key">Key</Label>
                      <Field name="key">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="number"
                              placeholder="Escriba aquí el key del INS."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
              ) : null}
              {type === "organizationalUnits" ? (
                <div className="form-row">
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="name">Unidad Organizativa</Label>
                      <Field name="name">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Escriba aquí el nombre de la unidad organizativa."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="key">Key</Label>
                      <Field name="key">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="number"
                              placeholder="Escriba aquí el key de la unidad."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
              ) : null}
              {type === "positions" ? (
                <div className="form-row">
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="name">Nombre Posición</Label>
                      <Field name="name">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="text"
                              placeholder="Escriba aquí el nombre de la posición."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-1" sm="12">
                    <FormGroup>
                      <Label for="insNumber">Número INS</Label>
                      <Field name="insNumber">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              className="form-control-alternative"
                              type="number"
                              placeholder="Escriba aquí el número de INS."
                              invalid={meta.error && meta.touched}
                            />
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="protection">Tiene Protección</Label>
                      <Field name="protection">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              invalid={meta.error && meta.touched}
                            >
                              <option value="0">Seleccione una opción</option>
                              <option value="1">Sí</option>
                              <option value="2">No</option>
                            </Input>
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col className="mb-3" sm="12">
                    <FormGroup>
                      <Label for="localRegionalType">Local / Regional</Label>
                      <Field name="localRegionalType">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              invalid={meta.error && meta.touched}
                            >
                              <option value="0">Seleccione una opción</option>
                              <option value="001">Local</option>
                              <option value="002">Regional</option>
                            </Input>
                            {meta.error && meta.touched && (
                              <span className="invalid-feedback">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </div>
              ) : null}
              <div className="form-row align-items-center">
                <Col className="mb-1" sm="12">
                  <Button
                    disabled={submitting || uploading}
                    className="btn-icon"
                    block
                    color="success"
                    type="submit"
                    onClick={handleSubmit || validating}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-save" />
                    </span>
                    <span className="btn-inner--text">Crear Dato Maestro</span>
                  </Button>
                </Col>
              </div>
            </FormRS>
          </Spin>
        )}
      />
    </Drawer>
  );
};

AddMasterDataDrawer.propTypes = {
  title: PropTypes.array.isRequired,
};

export default AddMasterDataDrawer;
