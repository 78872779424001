// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  // CardImg,
  Col,
  FormGroup,
  Row,
  Table,
} from "reactstrap";
// react-select library for filters
import Select from "react-select";

const CreateElements = (props) => {
  const {
    title,
    subtitle,
    masterData,
    generalCols,
    optionSelected,
    onChange,
    onSearch,
    onAddMasterData,
    onBack,
  } = props;

  return (
    <>
      {/* <Col xs="12" md="3"></Col>
        <Col xs="12" md="6">
          <Card className="card-pricing bg-gradient-success border-0 text-center mb-4">
            <CardImg
              alt="..."
              src={require("assets/img/theme/coming-soon.jpg")}
              top
            />
            <CardHeader className="bg-transparent">
              <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                Próximamente
              </h4>
            </CardHeader>
            <CardBody className="px-lg-7">
              <div className="display-4 text-white">{title}</div>
              <span className="text-white">{subtitle}</span>
              <ul className="list-unstyled my-4">
                <li>
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                        <i className="fas fa-terminal" />
                      </div>
                    </div>
                    <div>
                      <span className="pl-2 text-sm text-white">
                        Solicitud de un puesto
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                        <i className="fas fa-pen-fancy" />
                      </div>
                    </div>
                    <div>
                      <span className="pl-2 text-sm text-white">
                        DO y Compensación
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                        <i className="fas fa-hdd" />
                      </div>
                    </div>
                    <div>
                      <span className="pl-2 text-sm text-white">
                        Creación de Job en SAP
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <Button
                className="mb-3"
                block
                color="primary"
                type="button"
                onClick={onBack}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-arrow-left" />
                </span>
                Volver
              </Button>
            </CardBody>
          </Card>
        </Col> */}
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="9">
              <h5 className="surtitle">{title}</h5>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            <Col sm="12" md="3">
              <Button
                block
                size="sm"
                className="mb-3"
                color="info"
                type="button"
                onClick={onBack}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-arrow-left" />
                </span>
                Volver
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="text-right" xs="12" md="8">
              <FormGroup>
                <label className="form-control-label" htmlFor="masterTable">
                  Tablas Maestras Disponibles
                </label>
                <Select
                  isSearchable
                  className="basic-single"
                  id="masterTable"
                  type="select"
                  placeholder="Seleccione la tabla maestra a gestionar"
                  options={[
                    { value: 1, key: "cecos", label: "Ceco", type: "generic" },
                    {
                      value: 2,
                      key: "ins",
                      label: "Número INS",
                      type: "generic",
                    },
                    {
                      value: 3,
                      key: "organizationalUnits",
                      label: "Unidad Organizativa",
                      type: "generic",
                    },
                    {
                      value: 4,
                      key: "positions",
                      label: "Posición",
                      type: "generic",
                    },
                  ]}
                  value={optionSelected}
                  onChange={(e) => onChange(e)}
                />
              </FormGroup>
            </Col>
            <Col
              className="text-right d-flex justify-content-center align-items-center"
              xs="12"
              md="4"
            >
              <Button
                block
                className="btn-icon"
                color="success"
                size="md"
                type="button"
                disabled={optionSelected.value === 0}
                onClick={onSearch}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-search" />
                </span>
                <span className="btn-inner--text">Buscar</span>
              </Button>
            </Col>
          </Row>
          {optionSelected.value > 0 && masterData[optionSelected.key].length ? (
            <Row>
              <Col>
                <Card className="bg-default shadow">
                  <CardHeader className="bg-transparent border-0">
                    <Row>
                      <Col xs="12" md="7">
                        <h3 className="text-white mb-0">Tabla Maestra</h3>
                      </Col>
                      <Col className="text-right" xs="12" md="5">
                        <Button
                          className="btn-round btn-icon"
                          // outline
                          color="info"
                          id="add"
                          onClick={() => onAddMasterData(optionSelected)}
                          size="sm"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-folder-plus" />
                          </span>
                          <span className="btn-inner--text">Agregar Nuevo</span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        {generalCols[optionSelected.key].map((row, key) => (
                          <th
                            key={key}
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            {row.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="list">
                      {masterData[optionSelected.key].map((row, key) => (
                        <tr key={key}>
                          {generalCols[optionSelected.key].map((col, key) => (
                            <th
                              key={key}
                              style={{
                                whiteSpace: "normal",
                                textAlign: "center",
                              }}
                            >
                              {row[col.key]}
                            </th>
                          ))}
                          {/* <th
                            key="action"
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            <a
                              className="table-action table-action"
                              href="#"
                              onClick={() => onEdit(row, type)}
                            >
                              <i className="fas fa-edit" />
                            </a>
                            <a
                              className="table-action table-action"
                              href="#"
                              onClick={() => onDelete(row, type)}
                            >
                              <i className="fas fa-trash" />
                            </a>
                          </th> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
};

CreateElements.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default CreateElements;
