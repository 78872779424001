import React, { useContext, useState } from "react";
// Componentes de reactstrap
import {
  Col,
  Row,
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import newRequest from "views/FinancePages/newRequest";

const ForLineInfo = ({
  metodo,
  stateModalsIndvForms,
  setStateModalsIndvForms,
  form,
  setGeneralDataValues,
  generalDataValues,
}) => {
  const handleAddNewItem = () => {
    setStateModalsIndvForms((prevState) => [
      ...prevState,
      {
        showModal: true,
        view: "newRequest", //requests, approvals, newrequest
        row: "", //En caso de cargar información predefinida como en Mis Gestiones o aprobaciones.
        formName: form, //Nombre del formulario para hacer match con generalStates
        isEdit: false,
        newInfo: {
          info: {},
          updateInfo: false,
        },
        typeOfManagementId: generalDataValues?.typeOfManagement, //reqGeneralInfo?.typeOfManagementId //Creación o modificación
        countryClientId: generalDataValues?.sendingCountry, //Para la excepción de clientes
        vendorGroup: generalDataValues?.vendorGroup, //Para la excepción de proveedores
        companyCode: generalDataValues?.companyCode, //Para la excepción de proveedores
      },
    ]);
  };

  //Columnas para la tabla de filas de tipo lineal
  const [columnsLinealRequest, setcolumnsLinealRequest] = useState([
    //{ value: "ID", label: "#", width: 90 },
    { value: "linealDescription", label: "Linea", width: 90 },
    { value: "seeDetail", label: "Detalle", width: 70 },
  ]);


    //En el detalle de la tabla tipo lineal, hay algunos caso en los cuales se pide que en la fila
  //se concatenen dos valores de campos de el state reqGeneralInfo, por tanto este método establece
  //cuales se concatenan o de lo contrario se muestran solos.
  const asignLinealValueTable = (row, indexRow) => {
    //debugger;

    // console.log(indexRow);
    let type = parseInt(generalDataValues?.typeOfManagement);

    // console.log(row);

    // if (row.ID === undefined ){
    //   return ""
    // }

    //Extrae el state de los modales en base al ID del row, para verificar si el campo que va a la tabla lineal fue modificado
    // const result = stateModalsIndvForms?.filter(
    //   (modal) => modal.row.ID === row.ID
    // )[0]?.newInfo.info;
    // let result = row;


    const result = stateModalsIndvForms?.filter(modal => modal.newInfo.info === row)[0]?.newInfo.info

    const nameLine = {
      //Creation
      1: {
        clientes: ["businessName"],
        contactos: ["firstName", "lastName"],
        materiales: ["idMaterial"],
        equipos: ["materialId", "equipmentSeries"],
        ibase: ["description"],
        materialesdeservicio: ["idMaterial"],
        servicios: ["idMaterial"],
        repuestos: ["spareId"],
        proveedores: ["socialReason"],
      },

      //Modification

      2: {
        clientes: ["businessName"],
        contactos: ["firstName", "lastName"],
        materiales: ["idMaterial"],
        equipos: ["materialId", "equipmentSeries"],

        //Ibase: ['description']
        materialesdeservicio: ["idMaterial"],
        servicios: ["idMaterial"],
        repuestos: ["spareId"],
        proveedores: ["socialReason"],
        ibase: ["ibase"],
      },
    };

    try {
      //[1 (creation)][Clientes]
      let getIdToSearch = nameLine[type][form.replace(/ /g, "")];
      // console.log(getIdToSearch);
      // //Verifica si ha sido actualizado el state en newInfo del modal
      if (result[getIdToSearch[0]] !== undefined) {
        row = result;
      }

      let valueToReturn = "";

      //Es solo un id a retornar
      if (getIdToSearch.length === 1) {
        valueToReturn = row[getIdToSearch];
      } else {
        //Se concatena varios campos
        getIdToSearch.map(
          (e) => (valueToReturn = valueToReturn + " " + row[e.toString()])
        );
      }

      //const valueToReturn = row[getIdToSearch[0]];
      if(valueToReturn[0]!== undefined && valueToReturn!== " undefined undefined")
      {
        return valueToReturn;
      }else {return ""}
    } catch (e) {
      //console.log(e);
      return "";
    }
  };

  //Funcion para eliminar el Json de la Tabla
  const handleDeleteLinealItem = (row, indexRow) => {
    // const newState = stateModalsIndvForms.splice(indexRow, 1);
    const newState = stateModalsIndvForms.filter(
      (modal, index) => index !== indexRow
    );
    setStateModalsIndvForms(newState);
  };

  const handleOpenIndividualFormModal = (row,indexRow) => {
    let newState = "";
    newState = stateModalsIndvForms.map((modal,index) =>
      indexRow === index
        ? {
            ...modal,
            showModal: true,
            isEdit: true,
          }
        : modal
    );
    setStateModalsIndvForms(newState);
  };


  return (
    <div>
      <Row>
        <Col sm={metodo === "ambos" ? 6 : 12}>
          <Card>
            <CardHeader>
              <Row>
                <Col sm="12">
                  <Button onClick={handleAddNewItem} block color="info">
                    Agregar Gestión
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  {stateModalsIndvForms.length > 0 && (
                    <Table
                      className="align-items-center  table-flush mt-3 "
                      responsive
                      //style={{ width: "500px"}}
                      striped={true}
                      hover={true}
                    >
                      <thead className="thead-light">
                        <tr>
                          {columnsLinealRequest.map((column, key) => {
                            return (
                              <th
                                key={key}
                                className="justify-content-md-center"
                              >
                                {column.label}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="list">
                        {stateModalsIndvForms.length > 0 ? (
                          <>
                            {stateModalsIndvForms.map(
                              (row, indexRow /*key*/) => {
                                return (
                                  <tr key={indexRow}>
                                    {columnsLinealRequest.map((col, key) => {
                                      return (
                                        //LINEAL
                                        col.value === "seeDetail" ? (
                                          <td
                                            key={key}
                                            className="justify-content-md-center"
                                          >
                                            <Row
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Col
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Button
                                                  id="refreshBtn"
                                                  className="btn-info mt-2"
                                                  size="sm"
                                                  block
                                                  type="button"
                                                  style={{ height: "30px" }}
                                                  onClick={(e) =>
                                                    handleOpenIndividualFormModal(
                                                      row,
                                                      indexRow
                                                    )
                                                  }
                                                >
                                                  <span className="btn-inner--icon mr-">
                                                    <i className="fas fa-edit" />
                                                  </span>
                                                  <span className="btn-inner--text">
                                                    {" "}
                                                    Editar
                                                  </span>
                                                </Button>

                                                <Button
                                                  id="refreshBtn"
                                                  className="btn-danger"
                                                  size="sm"
                                                  block
                                                  type="button" /*style={{ width: "80px " }}*/
                                                  onClick={(e) =>
                                                    handleDeleteLinealItem(
                                                      row,
                                                      indexRow
                                                    )
                                                  }
                                                >
                                                  <span className="btn-inner--icon mr-">
                                                    <i className="fas fa-trash" />
                                                  </span>
                                                  <span className="btn-inner--text">
                                                    {" "}
                                                    Eliminar
                                                  </span>
                                                </Button>
                                              </Col>
                                            </Row>
                                          </td>
                                        ) : //LINEAL
                                        col.value === "linealDescription" ? (
                                          <td
                                            key={key}
                                            className="justify-content-md-center"
                                          >
                                            <span className="btn-inner--icon mr-">
                                              <i className="ni ni-check-bold" />
                                            </span>
                                            <span className="btn-inner--text">
                                              {" "}
                                              {asignLinealValueTable(
                                                row.newInfo?.info,
                                                indexRow
                                              )}
                                            </span>
                                          </td>
                                        ) : (
                                          <td
                                            key={key}
                                            className="justify-content-md-center"
                                          >
                                            {row[col.value]}
                                          </td>
                                        )
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td className="justify-content-md-center">
                                Sin resultados encontrados
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ForLineInfo;
