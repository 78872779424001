import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findAllYearSignatures = createAction(
  constants.GET_ALL_YEAR_SIGNATURES,
  method.get(urls.DIGITALSIGNATURE.getAllYearsSignatures)
);

export const findDataByDashboard = createAction(
  constants.GET_DATA_BY_DASHBOARD_SIGNATURE,
  method.get(urls.DIGITALSIGNATURE.getDataByDashboard)
);

export const findSignaturesByUser = createAction(
  constants.GET_SIGNATURES_BY_USER,
  method.get(urls.DIGITALSIGNATURE.getSignaturesByUser)
);

export const sendAllDocumentsSigned = createAction(
  constants.SEND_ALL_DOCUMENTS_SIGNED,
  method.get(urls.DIGITALSIGNATURE.sendAllDocumentsSigned)
);

export const findDataDashboardByYear = createAction(
  constants.GET_DATA_DASHBOARD_BY_YEAR,
  (body) => method.post(urls.DIGITALSIGNATURE.getDataDashboardByYear, body)()
);

export const findDataRegisterSignature = createAction(
  constants.GET_DATA_REGISTER_SIGNATURE,
  (id) =>
    method.get(`${urls.DIGITALSIGNATURE.getDataByRegisterSignature}/${id}`)()
);

export const findSignaturesPendingBySupervisor = createAction(
  constants.GET_SIGNATURES_PENDING_BY_SUPERVISOR,
  method.get(urls.DIGITALSIGNATURE.getSignaturesPendingBySupervisor)
);

export const findSignaturesPendingByCollaborator = createAction(
  constants.GET_SIGNATURES_PENDING_BY_COLLABORATOR,
  method.get(urls.DIGITALSIGNATURE.getSignaturesPendingByCollaborator)
);

export const findFlowLogByIdFlow = createAction(
  constants.GET_LOGS_FLOW_BY_SIGNATURE,
  (id) => method.get(`${urls.DIGITALSIGNATURE.getFlowLogByIdFlow}/${id}`)()
);

export const findHomeOfficeInfoById = createAction(
  constants.GET_HOME_OFFICE_INFO_BY_SIGNATURE,
  (id) => method.get(`${urls.DIGITALSIGNATURE.getHomeOfficeInfoById}/${id}`)()
);

export const createPolicySignature = createAction(
  constants.CREATE_POLICY_SIGNATURE,
  (body) => method.post(urls.DIGITALSIGNATURE.createPolicySignature, body)()
);

export const createPolicyUserSignature = createAction(
  constants.CREATE_POLICY_USER_SIGNATURE,
  (id, body) =>
    method.post(
      `${urls.DIGITALSIGNATURE.createPolicyUserSignature}/${id}`,
      body
    )()
);

export const findUsersWithAccess = createAction(
  constants.GET_USERS_WITH_ACCESS,
  method.get(urls.DIGITALSIGNATURE.getUsersWithAccess)
);

export const createUserAccess = createAction(
  constants.CREATE_USER_WITH_ACCESS,
  (body) => method.post(urls.DIGITALSIGNATURE.createUserAccess, body)()
);

export const deactivatedUserWithAccess = createAction(
  constants.DEACTIVATED_USER_WITH_ACCESS,
  (id) =>
    method.put(`${urls.DIGITALSIGNATURE.deactivatedUserWithAccess}/${id}`)()
);
export const createExeptionUserSignature = createAction(
  constants.CREATE_EXEPTION_USER_SIGNATURE,
  (id, body) =>
    method.post(
      `${urls.DIGITALSIGNATURE.createExeptionUserSignature}/${id}`,
      body
    )()
);

export const updateStateSignatureInFlow = createAction(
  constants.UPDATE_STATE_SIGNATURE_IN_FLOW,
  (idSignature, idFlow, body) =>
    method.put(
      `${urls.DIGITALSIGNATURE.updateStateSignatureInFlow}/${idSignature}/${idFlow}`,
      body
    )()
);

export const updateSigntureByCollaborator = createAction(
  constants.UPDATE_SIGNATURE_BY_COLLABORATOR,
  (idSignature, body) =>
    method.put(
      `${urls.DIGITALSIGNATURE.updateSigntureByCollaborator}/${idSignature}`,
      body
    )()
);

export const relaunchSigntureByCollaborator = createAction(
  constants.RELAUNCH_SIGNATURE_BY_COLLABORATOR,
  (idSignature, body) =>
    method.put(
      `${urls.DIGITALSIGNATURE.relaunchSigntureByCollaborator}/${idSignature}`,
      body
    )()
);

export const relaunchHomeOfficeSignature = createAction(
  constants.RELAUNCH_SIGNATURE_BY_COLLABORATOR,
  (idException, body) =>
    method.put(
      `${urls.DIGITALSIGNATURE.relaunchHomeOfficeSignature}/${idException}`,
      body
    )()
);
