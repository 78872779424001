/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "FiltersMain" es un componente compartido por los Main de cada sección donde se imprime 
cada unos de los inputs y select de los filtros para las tabla principal de cada sección en MainTable

*Props:
    dropDownOptions: useState con las opciones de los dropdowns
    fields: la lista de los campos (inputs y selects) del filtro
    buttons: la lista de los botones del filtro
    handleOnChangeFilter: la función de los selects por cada vez que se cambia los filtros
    stateExcel: state que guarda las filas del excel a descargar que se actualiza cada vez que se cambia los filtros
    optionsSelect: state con las columnas del excel a descargar

=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState } from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
//accion para extraer los reportes de tiempos, para aprobar/devolver/rechazar reportes, y para aprobar multiples registros
import { getRows } from "actions/Freelance.jsx";
//usedispatch for fetch
import { useDispatch } from "react-redux";
//downloadFile
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// antd components
import { Spin } from 'antd';

const FiltersMain = ({
    dropDownOptions,
    fields,
    buttons,
    handleOnChangeFilter,
    columnTitles,
    tableParameter,
    search,
    Toast
}) => {

    //#region funciones

    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(false);
    //#endregion

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region state principal con el titulo del componente de filtros y las fechas de inicio y fin del filtro
    const [state, setState] = useState({
        title: "Filtros de búsqueda y acciones",
        startDate: {},
        endDate: {},
    });
    const { title, startDate, endDate } = state;
    //#endregion



    //#region funcion para seleccionar un rango de fechas
    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate._d + "") > new Date(date._d + "")
        ) {
            setState((prevState) => ({
                ...prevState,
                startDate: date,
                endDate: date
            }))
            handleOnChangeFilter("startDate", date._d);
            handleOnChangeFilter("endDate", date._d);
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate._d + "") < new Date(date._d + "")
        ) {
            setState((prevState) => ({
                ...prevState,
                startDate: date,
                endDate: date
            }))
            handleOnChangeFilter("startDate", date._d);
            handleOnChangeFilter("endDate", date._d);
        } else {
            console.log(new Date(date._d).toString("yyyy-MM-dd"));
            setState((prevState) => ({
                ...prevState,
                [who]: date
            }))
            handleOnChangeFilter(who, date._d);
        }
    };

    const getClassNameReactDatetimeDays = date => {
        if (startDate && endDate) {
        }
        if (
            startDate &&
            endDate &&
            startDate._d + "" !== endDate._d + ""
        ) {
            if (
                new Date(endDate._d + "") > new Date(date._d + "") &&
                new Date(startDate._d + "") < new Date(date._d + "")
            ) {
                return " middle-date";
            }
            if (endDate._d + "" === date._d + "") {
                return " end-date";
            }
            if (startDate._d + "" === date._d + "") {
                return " start-date";
            }
        }
        return "";
    };
    //#endregion

    //#region para descargar un JSON Array en un Excel
    const downloadExcelFile = (e, fileName) => {
        setLoad(true);
        dispatch(getRows({ filters: search, table: tableParameter })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Sheet 1');

                const data = payload.data.rows;

                // Add custom column titles to the worksheet
                const headers = columnTitles.map((column) => column.label);
                worksheet.addRow(headers);

                // Filter data based on column titles and add data rows to the worksheet
                data.forEach((item) => {
                    const row = columnTitles.map((column) => item[column.value]);
                    worksheet.addRow(row);
                });

                // Generate and save the Excel file
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, fileName);
                });
            }
            else if (payload.status === 404) {
                Toast.fire({
                    title: "Atención",
                    html: "No se encontrarón registros para el filtro seleccionado",
                    type: "warning",
                });
            }
            else {
                Toast.fire({
                  title: "Atención",
                  html: "Error inesperado, por favor comuniquese con Application Managment",
                  type: "warning",
                });
            }
            setLoad(false);
        });
    }
    //#endregion

    //#endregion

    return (
        <>
            <Card>
                <Spin size="large" spinning={loading}>
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col sm="6" md="8">
                                <h3 className="mb-0" >{title}</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {
                                fields.map((row, key) => (
                                    <Col sm={row.colWidth} key={key}>
                                        <FormGroup key={key}>
                                            <label className="form-control-label">{row.label}</label>
                                            {
                                                row.elementType === "StartDate" ?
                                                    <ReactDatetime
                                                        id={row.id}
                                                        inputProps={{
                                                            placeholder: row.placeholder
                                                        }}
                                                        value={startDate}
                                                        timeFormat={false}
                                                        // type="date"
                                                        onChange={e =>
                                                            handleReactDatetimeChange("startDate", e)
                                                        }
                                                        renderDay={(props, currentDate, selectedDate) => {
                                                            let classes = props.className;
                                                            classes += getClassNameReactDatetimeDays(
                                                                currentDate
                                                            );
                                                            return (
                                                                <td {...props} className={classes}>
                                                                    {currentDate.date()}
                                                                </td>
                                                            );
                                                        }}
                                                    />
                                                    :
                                                    row.elementType === "EndDate" ?
                                                        <ReactDatetime
                                                            id={row.id}
                                                            inputProps={{
                                                                placeholder: row.placeholder
                                                            }}
                                                            value={endDate}
                                                            timeFormat={false}
                                                            // type="date"
                                                            onChange={e =>
                                                                handleReactDatetimeChange("endDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                        :
                                                        row.elementType === "Input" ?
                                                            <Input
                                                                key={row.id}
                                                                id={row.id}
                                                                className="form-control"
                                                                type={row.type}
                                                                readOnly={row.disabled}
                                                                // defaultValue={data && data[field.defaultValue]}
                                                                placeholder={row.placeholder}
                                                                onChange={(e) => handleOnChangeFilter(row.id, e.target.value)}
                                                            />
                                                            :
                                                            <Select
                                                                key={row.id}
                                                                id={row.id}
                                                                placeholder={row.placeholder}
                                                                name={row.id}
                                                                className="basic-single text-dark"
                                                                type="Select"
                                                                isSearchable
                                                                isDisabled={row.disabled}
                                                                onChange={(e) => handleOnChangeFilter(row.id, e.value)}
                                                                options={dropDownOptions[row.options] && [{ value: "", label: "", }, ...dropDownOptions[row.options]]}
                                                            />
                                            }
                                        </FormGroup>
                                    </Col>
                                ))
                            }
                        </Row>
                        <Row>
                            {
                                buttons.map((row, key) => (
                                    <Col sm={row.colWidth} key={key}>
                                        {
                                            row.type === "download" ?

                                                <Button className="btn-icon" color={row.color} size="sm" block type="button"
                                                    onClick={(e) => downloadExcelFile(e, row.docTitle)}
                                                >
                                                    <span className="btn-inner--icon mr-">
                                                        <i className={row.icon} />
                                                    </span>
                                                    <span className="btn-inner--text">
                                                        {row.title}
                                                    </span>
                                                </Button>
                                                :
                                                <Button className="btn-icon" color={row.color} size="sm" block type="button"
                                                    onClick={(e) => row.function(e)}
                                                >
                                                    <span className="btn-inner--icon mr-">
                                                        <i className={row.icon} />
                                                    </span>
                                                    <span className="btn-inner--text">
                                                        {row.title}
                                                    </span>
                                                </Button>
                                        }
                                    </Col>
                                ))
                            }
                        </Row>
                    </CardBody>
                </Spin>
            </Card>
        </>
    );
};

export default FiltersMain;