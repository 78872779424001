import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getMasterData = createAction(constants.GET_MASTER_DATA, method.get(urls.PANAMABIDS.getMasterData));

export const getAllEntities = createAction(constants.GET_ALL_ENTITIES, method.get(urls.PANAMABIDS.getEntities));

export const createNewEntity = createAction(constants.CREATE_ENTITY, body => method.post(urls.PANAMABIDS.createNewEntity, body)());

export const updateEntity = createAction(constants.UPDATE_ENTITY, (body) => method.post(urls.PANAMABIDS.updateEntity, body)());

export const getContacts = createAction(constants.GET_CONTACTS_ENTITY, (body) => method.post(urls.PANAMABIDS.getContacts, body)());

export const deleteEntity = createAction(constants.DELETE_ENTITY, (id) => method.put(`${urls.PANAMABIDS.deleteEntity}/${id}`)());

export const getAllPurchaseOrderMacro = createAction(constants.GET_ALL_PURCHASE_ORDER_MACRO, (body) => method.post(urls.PANAMABIDS.getPurchaseOrderMacro, body)());

export const getAllPurchaseOrderProduct = createAction(constants.GET_ALL_PURCHASE_ORDER_PRODUCT, (id) => method.put(`${urls.PANAMABIDS.getPurchaseOrderProduct}/${id}`)());

export const getAllProductsPb = createAction(constants.GET_ALL_PRODUCTSPB, method.get(urls.PANAMABIDS.getAllProductsPb));

export const getColumsPurchaseOrderProduct = createAction(constants.GET_COLUMS_PURCHASE_ORDER_MACRO, method.get(urls.PANAMABIDS.getColumsPurchaseOrderProduct));

export const getMDataEntities = createAction(constants.GET_DATA_ENTITIES, method.get(urls.PANAMABIDS.getDataEntities));

export const updatePurchaseOrderMacro = createAction(constants.UPDATE_PURCHASE_ORDER_MACRO, (body) => method.post(urls.PANAMABIDS.updatePurchaseOrderMacro, body)());

export const getAllDeliveryMethod = createAction(constants.GET_ALL_DELIVERY_METHOD, method.get(urls.PANAMABIDS.getAllDeliveryMethod));

export const getAllCompetitions = createAction(constants.GET_COMPETITIONS, (body) => method.post(urls.PANAMABIDS.getPurchaseOrderCompetition, body)());

export const getAllCompetitionsProduct = createAction(constants.GET_COMPETITIONS_PRODUCTS, (id) => method.put(`${urls.PANAMABIDS.getCompetitionProducts}/${id}`)());

export const getColumsCompetition = createAction(constants.GET_COLUMS_COMPETITION, method.get(urls.PANAMABIDS.getColumsCompetition));

export const getAllFastCotyzationReport = createAction(constants.GET_ALL_FAST_COTIZATION_REPORT, (body) => method.post(urls.PANAMABIDS.getAllFastCotyzationReport, body)());

export const getAllProductsFastCotyzation = createAction(constants.GET_ALL_PRODUCTS_FAST_COTYZATION, (id) => method.post(`${urls.PANAMABIDS.getAllProductsFastCotyzation}/${id}`)());

export const getColumsFastCotyzationReport = createAction(constants.GET_COLUMS_FAST_COTYZATION_REPORT, method.get(urls.PANAMABIDS.getColumsFastCotyzationReport));

export const updateFastCotyzationReport = createAction(constants.UPDATE_FAST_COTYZATION_REPORT, (body) => method.post(urls.PANAMABIDS.updateFastCotyzationReport, body)());

export const updateFastCotyzationReportStatus = createAction(constants.UPDATE_FAST_COTYZATION_REPORT_STATUS, (body) => method.post(urls.PANAMABIDS.updateGBMStatusFastCotyzation, body)());

export const getAllFilesByID = createAction(constants.GET_ALL_FILES, (id) => method.get(`${urls.PANAMABIDS.getFilesById}/${id}`)());

export const getDataReport = createAction(constants.GET_DATA_REPORT, (body) => method.post(urls.PANAMABIDS.getDataReport, body)());

export const getDataCompetitionReport = createAction(constants.GET_DATA_COMPETITION_REPORT, (body) => method.post(urls.PANAMABIDS.getDataCompetitionReport, body)());









