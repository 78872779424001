import { getColumsFastCotyzationReport, getAllFilesByID, getAllFastCotyzationReport, getAllProductsFastCotyzation } from "actions/panamaBids";
import QuickQuoteEditModal from "components/PanamaBids/QuickQuote/QuickQuoteEditModal";
import QuickQuoteFilters from "components/PanamaBids/QuickQuote/QuickQuoteFilters";
import QuickQuoteMainTable from "components/PanamaBids/QuickQuote/QuickQuoteMainTable";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Container
} from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';

const QuickQuotes = (props) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const [refreshInput, setrefreshInput] = useState("");
    const [activeTab, setActiveTab] = useState("1");
    const [contInput, setContInput] = useState([1]);
    const [showModal, setShowModal] = useState(false);
    const [rowsModal, setRowsModal] = useState(
        {
            colums: [],
            products: [],
            rows: [],
            rowSelect: "",
            files: []
        }
    );

    const [page, setPage] = useState(1);

    //#region effect to display edit checkbox


    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const teams = getTeamsUserAccess();
        // const token = getUserProtected();
        if (teams.some((row) => row.indexOf("PanamaBids Admin") !== -1)) {
            setIsAdmin(true)
        }
    }, []);

    //#endregion
    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
        }
        getRowsData();
    }, [refreshInput])

    const [dataModal, setDataModal] = useState([]);
    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
        };
        getRowsData();
    }, []);

    //Funcion para traer la informacion de lo selects y el nombre de las columnas de la tabla
    const handleRowInfo = async () => {
        dispatch(getColumsFastCotyzationReport()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal((prevState) => ({
                    ...prevState,
                    colums: [
                        { Comment: "Seleccione...", Field: 0 },
                        ...payload.data.payload.data,
                    ],
                }));
            }
        });
        dispatch(getAllFastCotyzationReport()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal((prevState) => ({
                    ...prevState,
                    rows: payload.data.payload.data,
                }));
            }
        });
    };

    const getProductsFastCotyzation = async (id) => {
        dispatch(getAllProductsFastCotyzation(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal((prevState) => ({
                    ...prevState,
                    products: payload.data.payload.data,
                }));
            }
        });
        dispatch(getAllFilesByID(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal((prevState) => ({
                    ...prevState,
                    files: payload.data.rows,
                }));
            }
        });
    };
    const handleOpenModal = (e) => {
        setDataModal(e);
        setRowsModal((prevState) => ({
            ...prevState,
            rowSelect: e,
        }));
        getProductsFastCotyzation(e.numCotyzation);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setDataModal([]);
        setrefreshInput(2);
        setShowModal(false);
        setActiveTab("1");
        setRowsModal((prevState) => ({
            ...prevState,
            products: [],
        }));
    };
    return (
        <div>
            <AdminHeader name="Home" parentName="Cotizaciones Rapidas" />
            <Container className="mt--6" fluid>
                <QuickQuoteEditModal
                    setDataModal={setDataModal}
                    handleCloseModal={handleCloseModal}
                    rowsModal={rowsModal}
                    showModal={showModal}
                    closeModal={handleOpenModal}
                    dataModal={dataModal}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setrefreshInput={setrefreshInput}
                    setRowsModal={setRowsModal}
                    Toast={Toast}
                    isAdmin={isAdmin}
                />
                <QuickQuoteFilters
                    dataSelect={rowsModal.colums}
                    setrefreshInput={setrefreshInput}
                    setContInput={setContInput}
                    contInput={contInput}
                    setRowsModal={setRowsModal}
                    Toast={Toast}
                />
                {
                    <QuickQuoteMainTable
                        isAdmin={isAdmin}
                        Toast={Toast}
                        setrefreshInput={setrefreshInput}
                        rows={rowsModal.rows}
                        openModal={handleOpenModal}
                        page={page}
                        setPage={setPage}
                        setRowsModal={setRowsModal}
                    />
                }
            </Container>
        </div >
    );
};

export default QuickQuotes;
