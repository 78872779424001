// core actions
import { findLogsMasterDataById, findVariablesMasterData, updateEquipmentSpareById } from "actions/Sales/pricing.jsx";
// core antd
import { Spin } from "antd";
// core components Pricing
import MasterDataCard from 'components/Sales/Pricing/MasterDataCard.jsx';
import MasterDataLogs from 'components/Sales/Pricing/MasterDataLogs.jsx';
// core components Shared
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import CardEmpty from 'components/Shared/Notifications/CardEmpty.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selector
import { getMasterData } from "selectors/Sales/pricing.jsx";

class MasterData extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      edit: false,
      editValues: {
        id: undefined,
        value: undefined,
      },
      logs: [],
      showLogs: false,
    }
  };

  componentWillMount() {
    this.findMasterData();
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  findMasterData = () => {
    this.props.findVariablesMasterData().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        willMount: false
      });
    });
  };

  handleOnEditValue = row => {
    const { editValues } = this.state;
    editValues['id'] = row.id;
    editValues['value'] = row.value;
    this.setState({
      edit: true,
      editValues,
    });
  };

  handleOnChangeValue = event => {
    const { editValues } = this.state;
    const { id, value } = event.target;
    editValues[id] = value;
    this.setState({
      editValues
    });
  };

  handleOnUpdateValue = () => {
    const { editValues } = this.state;
    const { id, value } = editValues;
    this.setState({
      loading: true,
      message: 'Actualizando el valor de la variable'
    });
    this.props.updateEquipmentSpareById(id, { value }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState({
          loading: false,
          message: null
        });
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          loading: false,
          message: null,
          edit: false,
          editValues: {
            id: undefined,
            value: undefined,
          }
        });
      }
    });
  };

  handleOnCancelEdit = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Desea guardar los cambios?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => {
            this.handleOnUpdateValue();
            this.hideAlert();
          }}
          onCancel={() => {
            this.setState({
              alert: null,
              edit: false,
              editValues: {
                id: undefined,
                value: undefined,
              }
            })
          }}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, guardar"
          cancelBtnText="No, salir"
          btnSize="md"
        />
      )
    });
  };

  handleOnFlowLogsData = id => {
    this.setState({
      loading: true,
      message: 'Cargando la bitacora de acciones'
    });
    this.props.findLogsMasterDataById(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
        this.setState({
          loading: false,
          message: null
        });
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          loading: false,
          message: null,
          logs: payload.logs,
          showLogs: true,
        });
      }
    });
  };

  handleOnBackModalLogs = () => {
    this.setState({
      logs: [],
      showLogs: false,
    });
  };

  render() {

    const {
      name,
      cols,
      parentName,
      masterData,
    } = this.props;

    const {
      logs,
      edit,
      alert,
      loading,
      message,
      showLogs,
      willMount,
      editValues,
    } = this.state;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message} size="large">
            {
              willMount ?
                <Row className="justify-content-center">
                  <Col>
                    <CardLoading title="Cargando los datos de la tabla master" />
                  </Col>
                </Row>
                :
                showLogs ?
                  <MasterDataLogs
                    title="Bitácora de Acciones"
                    subtitle="Lista de las acciones realizadas a la variable maestra"
                    rows={logs}
                    cols={[
                      { key: "description", name: "Descripción" },
                      { key: "valueAnt", name: "Valor Anterior" },
                      { key: "valueNew", name: "Valor Nuevo" },
                      { key: "idUser", name: "ID Colaborador" },
                      { key: "user", name: "Colaborador" },
                      { key: "createdAt", name: "Fecha" },
                    ]}
                    onBack={this.handleOnBackModalLogs}
                  />
                  :
                  masterData.length ?
                    <MasterDataCard
                      title="Tabla Maestra"
                      subtitle="Gestión de las variables maestras"
                      cols={cols}
                      rows={masterData}
                      edit={edit}
                      editValues={editValues}
                      onEdit={this.handleOnEditValue}
                      onBack={this.handleOnCancelEdit}
                      onChange={this.handleOnChangeValue}
                      onUpdate={this.handleOnUpdateValue}
                      onFlowLogs={this.handleOnFlowLogsData}
                    />
                    :
                    <Row className="justify-content-center">
                      <Col sm="12" md="8">
                        <CardEmpty
                          title="No hay variables."
                          subtitle="Actualmente al día de hoy, no hay variables creadas en la tabla master, cuando existan se enlistarán en esta ventana."
                        />
                      </Col>
                    </Row>
            }
          </Spin>
        </Container>
      </>
    );
  }
}

MasterData.defaultProps = {
  name: "Variables Maestras",
  parentName: "Pricing",
  masterData: [],
  cols: [
    { key: "id", name: "#REF" },
    { key: "description", name: "Descripción" },
    { key: "variable", name: "Variable" },
    { key: "value", name: "Valor" },
    { key: "updatedAt", name: "Fecha Actualización" },
    { key: "action", name: "" },
  ]
};

MasterData.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  masterData: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  masterData: getMasterData(state),
});

export default withRouter(connect(mapStateToProps, {
  findLogsMasterDataById,
  findVariablesMasterData,
  updateEquipmentSpareById
})(MasterData));
