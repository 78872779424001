// core actions
import { findInternalTeams } from "actions/mis.jsx";
// antd components
import { Spin } from 'antd';
// core components
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
import Table from "components/Shared/Table/Table.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getInternalTeams } from "selectors/mis.jsx";

class InternalTeam extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 25,
      loading: true,
    };
  }


  componentWillMount() {
    this.props.findInternalTeams().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      }
      this.setState((state) => ({
        loading: !state.loading
      }));
    });
  }

  /**
  * Funcion para el manejo de notificaciones parametrizadas
  * @memberof InternalTeam
  */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  expandRows = {
    clickToExpand: true,
    parentClassName: "expand-parent",
    renderer: row => (
      <div>
        <span>
          <Row>
            <Col sm="12" md="8">
              <span style={{ color: "#0D45A4" }}>Descripción</span> <br />
              {row.description}
            </Col>
          </Row>
        </span>
      </div>
    ),
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  }

  render() {

    const {
      name,
      parentName,
      columns,
      data
    } = this.props;

    const {
      loading,
      page,
      sizePerPage,
    } = this.state;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name={name}
          parentName={parentName}
        />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading}>
            <Row>
              <Col>
                <Table
                  title="Equipos Internos"
                  subtitle="A continuación, se muestra el equipo interno de GBM."
                  columns={columns}
                  data={data}
                  sizePerPage={sizePerPage}
                  page={page}
                  totalSize={data.length}
                  expandFeatrue={this.expandRows}
                  defaultSorted={[]}
                  cvsFeatrue
                  fileName="Equipo Interno"
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

InternalTeam.defaultProps = {
  name: "Equipo Interno",
  parentName: "MIS",
  columns: [
    {
      dataField: "id",
      text: "ID",
      sort: false,
      hidden: true
    },
    {
      dataField: "reference",
      text: "Referencia Miami",
      sort: false
    },
    {
      dataField: "partNumber",
      text: "Número de Pieza",
      sort: false
    },
    {
      dataField: "description",
      text: "Descripción",
      sort: false,
      hidden: true
    },
    {
      dataField: "unitCost",
      text: "Costo Unitario KPL",
      sort: false
    },
    {
      dataField: "totalCost",
      text: "Costo Total",
      sort: false
    },
    {
      dataField: "type",
      text: "Tipo",
      sort: false
    },
  ],
  data: []
}

InternalTeam.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  data: getInternalTeams(state),
});

export default withRouter(connect(mapStateToProps, {
  findInternalTeams,
})(InternalTeam));
