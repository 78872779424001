/*!

=========================================================
*Autopp - SalesTeamsItems
=========================================================

* Existe un state en AutoppViews llamado salesTeamsList,
el cual los dispatch envian una accion al item reducer, y agrega, 
elimina, edita los items que hay en esta lista (state), de esta forma 
se gestiona los colaboradores en la vista de equipos de ventas. 

*Esta lista es agregada a editData (state principal de información),
en el componente MainTableAutopp cuando el form indica que está listo para enviar.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerias de react
import React, { useState, useReducer, useEffect } from 'react'
import { Button, Form, Col, Row } from "reactstrap";
import EmployeeResponsibleForm from './EmployeResponsibleForm';

//Import de componente hijo
import SalesTeamItemForm from './SalesTeamItemForm';



export const SalesTeamsItems = (
    {
        salesTeamsList, 
        setSalesTeamsList
    }

) => {

    //#region Configuración del título
    const [state, setState] = useState({
        title: "Equipo de ventas en oportunidad",
        subtitle: "A continuación se solicitan datos de su equipo de ventas."
    });
    const { title, subtitle } = state;
    //#endregion




    const handleDelete = (todoId) => {

        const salesTeams= salesTeamsList.filter(item => item.id !== todoId.id);
        setSalesTeamsList(salesTeams)
    }

    const handleAddSalesTeam = () => {

        setSalesTeamsList([...salesTeamsList, 
            {
            id: new Date().getTime(),
            information: {}
        }])

    }

    const handleAddInformationToSalesTeam = (id, information) => {

        const salesTeamInformation = {
            id: id,
            information: information
        }

        setSalesTeamsList( salesTeamsList.map(item => {

            if (item.id === id) {
                return salesTeamInformation
            } else {
                return item;
            }
        }) )

    }





    return (
        <>
            <Row className="mb-4">

                <Col xs="12" sm='6'>
                    <h3 className="mb-0">{title}</h3>
                    <p className="text-sm mb-0">{subtitle}</p>

                </Col>
            </Row>



            <Form noValidate className="formInfo needs-validation">

                <EmployeeResponsibleForm />

                {
                    salesTeamsList.map((todo, i) => (
                        <SalesTeamItemForm
                            key={todo.id}
                            salesTItem={todo}
                            index={i}
                            handleAddInformationToSalesTeam={handleAddInformationToSalesTeam}
                            handleDeleteItem={handleDelete}
                        ></SalesTeamItemForm>

                    ))
                }


                {/* TodoList, todos, handleDelete, handleToggle  */}

            </Form>


            <Row className="align-items-center mt-3 " style={{ display: "flex", justifyContent: "center" }} >
                <Row className="ml-4" style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        color="success"
                        className="btn-icon"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        onClick={(e) => { handleAddSalesTeam(e) }}
                    >
                        Agregar colaborador
                    </Button>

                </Row>

            </Row>

        </>
    )
}

export default SalesTeamsItems;
 
