/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Row, Table, Input } from "reactstrap";

const AddEquipmentsTable = props => {

  const {
    edit,
    title,
    cols,
    state,
    options,
    initialValues,
    initialEditValues,
    rows,
    onChange,
    onAddEquipment,
    onCreate,
    onEdit,
    onUpdate,
    onDelete,
    onCompleteEquipments,
  } = props;

  const practices = options.find((row) => row.key === "practice");
  const optionsPractices = practices ? practices.options ? practices.options.find((row) => row.id === parseInt(initialValues.practice)) : [] : [];

  return (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md='7' lg="8">
            <h3 className="text-white mb-0">{title}</h3>
          </Col>
          <Col className="text-right" xs="12" md='5' lg="4">
            {
              state === true &&
              <Row>
                <Col xs='12' md="6" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="btn-round btn-icon"
                    // outline
                    color="success"
                    id="add"
                    onClick={onCompleteEquipments}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-folder-plus" />
                    </span>
                    <span className="btn-inner--text">Completar Equipos</span>
                  </Button>
                </Col>
                <Col xs='12' md="6" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="btn-round btn-icon"
                    // outline
                    color="info"
                    id="add"
                    onClick={onAddEquipment}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-folder-plus" />
                    </span>
                    <span className="btn-inner--text">Agregar Nuevo</span>
                  </Button>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </CardHeader>
      <Table
        className="align-items-center table-dark table-flush"
        responsive
      >
        <thead className="thead-dark">
          <tr>
            {
              cols.map((row, key) => (row.hide === false ? <th key={key}>{row.name}</th> : null))
            }
            <th />
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.map((row, key) => (
              <tr key={key}>
                {
                  edit && row.id === initialEditValues.id ?
                    options.map((row, key) => (
                      row.hide === false &&
                      <td key={key}>
                        {
                          row.options ?
                            row.key === "platform" ?
                              <div>
                                <Input
                                  style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: row.key === 'productID' ? '250px' : "auto" }}
                                  id={row.key}
                                  type={row.type}
                                  value={initialEditValues[row.key]}
                                  disabled={!initialEditValues.practice || parseInt(initialEditValues.practice) < 1}
                                  onChange={(e) => onChange(e, 'edit')}
                                >
                                  <option key="0" value="0">{row.placeholder}</option>
                                  {
                                    row.options.filter((row) => row.fk_idPractice === parseInt(initialEditValues.practice)).map((row, key) => {
                                      return (
                                        <option key={key} value={row.id}>{row.name}</option>
                                      )
                                    })
                                  }
                                </Input>
                              </div>
                              :
                              <div>
                                <Input
                                  style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: row.key === 'productID' ? '250px' : "auto" }}
                                  id={row.key}
                                  type={row.type}
                                  value={initialEditValues[row.key]}
                                  onChange={(e) => onChange(e, 'edit')}
                                >
                                  <option key="0" value="0">{row.placeholder}</option>
                                  {
                                    row.options.map((row, key) => {
                                      return (
                                        <option key={key} value={row.id}>{row.name}</option>
                                      )
                                    })
                                  }
                                </Input>
                              </div>
                            :
                            <Input
                              style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "-webkit-fill-available" }}
                              id={row.key}
                              type={row.type}
                              placeholder={row.placeholder}
                              value={initialEditValues[row.key]}
                              onChange={(e) => onChange(e, 'edit')}
                              maxLength={row.max}
                              max={row.max}
                              min={row.min ? row.min : 1}
                            />
                        }
                      </td>
                    ))
                    :
                    cols.map((col, key) => (
                      col.hide === false &&
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                        {row[col.key]}
                      </th>
                    ))
                }
                <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                  {
                    edit && row.id === initialEditValues.id ?
                      <a
                        className="table-action table-action"
                        href="#"
                        onClick={onUpdate}
                      >
                        <i className="fas fa-check-double" />
                      </a>
                      :
                      <>
                        <a
                          className="table-action table-action"
                          href="#"
                          onClick={() => onEdit(row)}
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          className="table-action table-action"
                          href="#"
                          onClick={() => onDelete(row)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                      </>
                  }
                </th>
              </tr>
            ))
          }
          <tr>
            {
              state === false &&
              options.map((row, key) => (
                row.hide === false &&
                <td key={key}>
                  {
                    row.options ?
                      row.key === "platform" ?
                        <div>
                          <Input
                            style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: row.key === 'productID' ? '250px' : "auto" }}
                            id={row.key}
                            type={row.type}
                            value={initialValues[row.key]}
                            disabled={!initialValues.practice || parseInt(initialValues.practice) < 1}
                            onChange={(e) => onChange(e, 'add')}
                          >
                            <option key="0" value="0">{row.placeholder}</option>
                            {
                              row.options.filter((row) => row.fk_idPractice === parseInt(initialValues.practice)).map((row, key) => {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              })
                            }
                          </Input>
                        </div>
                        :
                        <div>
                          <Input
                            style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: row.key === 'productID' ? '250px' : "auto" }}
                            id={row.key}
                            type={row.type}
                            value={initialValues[row.key]}
                            onChange={(e) => onChange(e, 'add')}
                          >
                            <option key="0" value="0">{row.placeholder}</option>
                            {
                              row.options.map((row, key) => {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              })
                            }
                          </Input>
                        </div>
                      :
                      row.key === "amountMaintenance" ?
                        <Input
                          style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "-webkit-fill-available" }}
                          id={row.key}
                          type={row.type}
                          placeholder={row.placeholder}
                          defaultValue={row.min}
                          value={initialValues[row.key] ? initialValues[row.key] : row.min}
                          onChange={(e) => onChange(e, 'add')}
                          disabled={!initialValues.practice || parseInt(initialValues.practice) < 1}
                          maxLength={row.max}
                          max={row.max}
                          min={row.min === 0 ? row.min : optionsPractices ? optionsPractices.prevents : row.min}
                        />
                        :
                        <Input
                          style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "-webkit-fill-available" }}
                          id={row.key}
                          type={row.type}
                          placeholder={row.placeholder}
                          value={initialValues[row.key]}
                          onChange={(e) => onChange(e, 'add')}
                          maxLength={row.max}
                          max={row.max}
                          min={row.min}
                        />
                  }
                </td>
              ))
            }
            {
              state === false &&
              <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                <Button
                  className="btn-icon btn-3"
                  size="sm"
                  color="info"
                  type="button"
                  onClick={onCreate}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-save" />
                  </span>
                  <span className="btn-inner--text">Crear</span>
                </Button>
              </td>
            }
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

AddEquipmentsTable.propTypes = {
  title: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default AddEquipmentsTable;
