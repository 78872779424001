// react library
import React, { useState } from 'react';
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";


const CardFilters = ({
  lists,
  setLists,  //funcion que modifica el state 
  setLoad,
  Toast,
  statusOptions,
  countryOptions,
  status,
  country,
  setSearch,
  isAdmin,
  isDisplay,
  page,
  setPage,
  setReportingModal
}) => {

  //#region funciones
  //estado inicial
  const [state, setState] = useState({
    title: "Busqueda Avanzada",
    subtitle: "Aplicar filtros al sistema de documentos",
  });
  const { title, subtitle } = state;

  //funcion para buscar por filtro (no texto)
  const handleOnChange = (constant, e) => {

    setPage(1);
    setLists(prevState => ({
      ...prevState,
      [constant]: {
        ...prevState[constant],
        value: e
      }
    }));


  }

  //funcion para replicar cambios
  const handleOnLoad = () => {
    setSearch("");
    setPage(1);
    setLists(prevState => ({
      ...prevState
    }));
  }

  //funcion para limpiar filtros:
  const handleOnClearFilters = (e) => {
    setPage(1);
    const { status, country, iDateSelected, fDateSelected } = lists;
    if (status.value !== "Todos" && status.value !== "" || country.value !== "Todos" && country.value !== "" || iDateSelected !== null && fDateSelected !== "") {
      setSearch("");
      setLists(prevState => ({
        status: {
          ...prevState.status,
          value: {
            value: "",
            label: ""
          }
        },
        country: {
          ...prevState.country,
          value: null
        },
        iDateSelected: {
          value: ""
        },
        fDateSelected: {
          value: ""
        }
      }));

    } else {
      Toast.fire({
        title: "Atención",
        html: "Aún no se han aplicado filtros que deban ser limpiados",
        type: 'Warning'
      });
    }
  };

  //abrir modal de reporteria
  const handleOpenReportModal = (e) => {
    setReportingModal(prevState => !prevState);
  }
  //#endregion
  return (
    <>

      <Card>

        <CardHeader>
          <Row className="align-items-center">
            <Col sm="2" md="6">
              <h3 className="mb-0" >{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </Col>

            <Col sm="2" >
              <Button className="btn-icon" color="info" size="sm" block type="button"
                onClick={(e) => handleOnLoad(e)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-sync-alt" />
                </span>
                <span className="btn-inner--text">
                  Aplicar
                </span>
              </Button>
            </Col>
            <Col sm="2"  >
              <Button className="btn-icon" color="danger" size="sm" block type="button"
                onClick={(e) => handleOnClearFilters(e)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text">
                  Limpiar
                </span>
              </Button>
            </Col>
            <Col sm="2"  >
              <Button className="btn-icon" color="primary" size="sm" block type="button"
                onClick={(e) => handleOpenReportModal(e)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-download" />
                </span>
                <span className="btn-inner--text">
                  Reportería
                </span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="text-right" xs="12" md={(isAdmin || isDisplay) ? "3" : "4"}>
              <FormGroup>
                <label className="form-control-label" htmlFor="status">Estado</label>
                <Select
                  className="basic-single"
                  id="status"
                  type="select"
                  // placeholder={"Seleccione un Estado"}
                  isSearchable
                  options={[...statusOptions, {
                    value: "",
                    label: "",
                    statusType: "",
                  }]}
                  value={status.value}
                  onChange={(e) => handleOnChange("status", e)}
                />
              </FormGroup>
            </Col>
            {
              (isAdmin || isDisplay) ?
                <Col className="text-right" xs="12" md={(isAdmin || isDisplay)? "3" : "4"}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="country">
                      País
                    </label>
                    <Select
                      isMulti={true}
                      className="basic-single"
                      id="country"
                      type="select"
                      placeholder={""}
                      isSearchable
                      options={countryOptions}
                      value={country.value}
                      onChange={(e) => handleOnChange('country', e)}
                    />
                  </FormGroup>
                </Col>
                :
                <></>
            }
            <Col className="text-right" xs="12" md={(isAdmin || isDisplay) ? "3" : "4"}>
              <FormGroup>
                <label className="form-control-label" htmlFor="startDate">
                  Fecha Inicio
                </label>
                <Input
                  id="startDate"
                  className="m-0"
                  style={{ height: "calc(2.25rem + 2px)" }}
                  type="date"
                  value={lists.iDateSelected.value}
                  onChange={(e) => handleOnChange('iDateSelected', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="text-right" xs="12" md={(isAdmin || isDisplay) ? "3" : "4"}>
              <FormGroup>
                <label className="form-control-label" htmlFor="endDate">
                  Fecha Final
                </label>
                <Input
                  id="endDate"
                  className="m-0"
                  style={{ height: "calc(2.25rem + 2px)" }}
                  type="date"
                  value={lists.fDateSelected.value}
                  onChange={(e) => handleOnChange('fDateSelected', e.target.value)}
                />
              </FormGroup>
            </Col>

          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default CardFilters;