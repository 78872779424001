// core actions
import {
  findAllDataByDigitalRequest,
  findAllInformationDigitalRequest,
} from "actions/Sales/managers.jsx";
import { findOffersRequestById } from "actions/Sales/pricing.jsx";
// core antd
import { Spin } from "antd";
// core components Managers
import ConfigurationsList from "components/Sales/Managers/ConfigurationsList";
import EquipmentsList from "components/Sales/Managers/EquipmentsList";
import FiltersCard from "components/Sales/Managers/FiltersCard";
import RequestDetail from "components/Sales/Managers/RequestDetail";
import Requests from "components/Sales/Managers/Requests";
import ResumeDetail from "components/Sales/Managers/ResumeDetail";
import SpareList from "components/Sales/Managers/SpareList";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getActivitiesFlowByRequest,
  getAllInfoDigitalRequests,
  getCommentsByRequest,
  getConfigurationsByRequest,
  getEquipmenstByRequest,
  getEquipmenstSpareByRequest,
  getFiltersOptions,
  getJustificationsByRequest,
  getReferencesByRequest,
  getReferencesSpareByRequest,
  getResumeOfferByRequest,
} from "selectors/Sales/managers.jsx";
import { getOffersCalc } from "selectors/Sales/pricing.jsx";

class OppsHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      willMount: true,
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      requestSelected: null,
      offerDetailView: false,
      equipmentsView: false,
      configurationsView: false,
      equipmentsSpareView: false,
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
        country: {
          value: 0,
          label: "Todos",
        },
      },
    };
  }

  componentDidMount = () => {
    this.findDigitalRequests();
  };

  findDigitalRequests = () => {
    this.props.findAllInformationDigitalRequest().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        willMount: false,
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnBackMainView = () => {
    this.setState({
      requestSelected: null,
    });
  };

  handleOnOpenRequest = (row) => {
    const { id } = row;
    this.setState({
      loading: true,
      message: `Cargando la información del requerimiento`,
    });
    this.props.findAllDataByDigitalRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          requestSelected: row,
        });
        this.props.findOffersRequestById(id);
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnViewEquipments = () => {
    this.setState({
      equipmentsView: true,
    });
  };

  handleOnViewEquipmentsSpare = () => {
    this.setState({
      equipmentsSpareView: true,
    });
  };

  handleOnViewConfigurations = () => {
    this.setState({
      configurationsView: true,
    });
  };

  handleOnOpenOfferDetail = () => {
    this.setState({
      offerDetailView: true,
    });
  };

  handleOnBackEquipmentsSpareView = () => {
    this.setState({
      equipmentsSpareView: false,
    });
  };

  handleOnBackEquipmentsView = () => {
    this.setState({
      equipmentsView: false,
    });
  };

  handleOnBackConfigurationsView = () => {
    this.setState({
      configurationsView: false,
    });
  };

  handleOnBackOfferDetailView = () => {
    this.setState({
      offerDetailView: false,
    });
  };

  handleOnClearFilters = () => {
    this.setState({
      filtersValues: {
        customer: {
          value: 0,
          label: "Todos",
        },
        status: {
          value: 0,
          label: "Todos",
        },
        opp: {
          value: 0,
          label: "Todos",
        },
        country: {
          value: 0,
          label: "Todos",
        },
      },
    });
  };

  handleOnChangeFilters = (key, event) => {
    const { filtersValues } = this.state;
    filtersValues[key] = event;
    this.setState({
      filtersValues,
      page: 1,
    });
  };

  renderRowsFiltered = (rows) => {
    const {
      filtersValues: { customer, status, opp, country },
    } = this.state;
    if (customer.value !== 0) {
      rows = rows.filter((row) => row.customer === customer.label);
    }
    if (status.value !== 0) {
      rows = rows.filter((row) => row.status === status.label);
    }
    if (opp.value !== 0) {
      rows = rows.filter((row) => row.opportunityNumber === opp.label);
    }
    if (country.value !== 0) {
      rows = rows.filter((row) => row.country === country.label);
    }
    return rows;
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  render() {
    const {
      name,
      parentName,
      resume,
      columns,
      filters,
      requests,
      comments,
      equipments,
      references,
      activityFlow,
      filtersOptions,
      referenceSpare,
      justifications,
      configurations,
      equipmentsSpare,
      offersCalcs,
    } = this.props;

    const {
      page,
      loading,
      message,
      willMount,
      sizePerPage,
      filtersValues,
      requestSelected,
      equipmentsView,
      offerDetailView,
      configurationsView,
      equipmentsSpareView,
    } = this.state;

    console.log(offersCalcs);

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin spinning={loading} tip={message} size="large">
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando el historial de requerimientos completados" />
                </Col>
              </Row>
            ) : offerDetailView ? (
              <ResumeDetail
                title="Calculo Oferta"
                subtitle="Resumen del calculo realizado a la oportunidad"
                wayPay={requestSelected.wayPay}
                country={requestSelected.country}
                resume={resume}
                offersCalcs={offersCalcs}
                onBack={this.handleOnBackOfferDetailView}
              />
            ) : equipmentsSpareView ? (
              <SpareList
                title="Equipos Spare"
                subtitle="Asignación de Equipos Spare al requerimiento"
                cols={[
                  { name: "Partnumber", key: "partNumber" },
                  { name: "Descripción", key: "description" },
                  { name: "Cantidad de Equipos", key: "amountEquipments" },
                  { name: "Costo Unitario", key: "cost" },
                ]}
                rows={equipmentsSpare}
                references={referenceSpare}
                onBack={this.handleOnBackEquipmentsSpareView}
              />
            ) : configurationsView ? (
              <ConfigurationsList
                title="Configuraciones"
                subtitle="Documentos de configuración cargados al requerimiento"
                configurations={configurations}
                comments={comments}
                onBack={this.handleOnBackConfigurationsView}
              />
            ) : equipmentsView ? (
              <EquipmentsList
                title="Equipos Cargados"
                subtitle="Lista de los Equipos Cargados al Requerimiento"
                cols={[
                  {
                    name: "Cantidad de Equipos",
                    key: "amountEquipments",
                    hide: requestSelected.idBusinessModel === 1 ? true : false,
                  },
                  { name: "País", key: "country", hide: false },
                  { name: "Tipo de Modelo", key: "typeModel", hide: false },
                  {
                    name: "Serie",
                    key: "serial",
                    hide: requestSelected.idBusinessModel === 1 ? false : true,
                  },
                  { name: "Práctica", key: "practice", hide: false },
                  {
                    name: "Cantidad de Mantenimientos",
                    key: "amountMaintenance",
                    hide: false,
                  },
                  { name: "Plataforma", key: "platform", hide: false },
                  {
                    name: "Horario de Atención",
                    key: "officeHour",
                    hide: false,
                  },
                  {
                    name: "Tiempo Cambio de Parte",
                    key: "timeChangePart",
                    hide: false,
                  },
                  {
                    name: "Años del Servicio",
                    key: "validityService",
                    hide: false,
                  },
                  {
                    name: "Renovación Automatica",
                    key: "automaticRenewal",
                    hide: false,
                  },
                  {
                    name: "Incluye Partes/Equipos",
                    key: "equipmentParts",
                    hide: false,
                  },
                  { name: "Viáticos $", key: "viatic", hide: false },
                  {
                    name: `${
                      requestSelected.idBusinessModel === 1
                        ? "Precio $"
                        : "Costo $"
                    }`,
                    key: "price",
                    hide: false,
                  },
                ]}
                rows={equipments}
                references={references}
                justifications={justifications}
                onBack={this.handleOnBackEquipmentsView}
              />
            ) : requestSelected ? (
              <RequestDetail
                request={requestSelected}
                equipments={equipments}
                configurations={configurations}
                activityFlow={activityFlow}
                onBack={this.handleOnBackMainView}
                onOpenOffer={this.handleOnOpenOfferDetail}
                onViewEquipments={this.handleOnViewEquipments}
                onViewConfigurations={this.handleOnViewConfigurations}
                onViewEquipmentsSpare={this.handleOnViewEquipmentsSpare}
              />
            ) : requests.length ? (
              <>
                <FiltersCard
                  title="Filtros Disponibles"
                  subtitle="Aplicación de Filtros a la lista de requerimientos"
                  filters={filters}
                  options={filtersOptions}
                  values={filtersValues}
                  onClear={this.handleOnClearFilters}
                  onChange={this.handleOnChangeFilters}
                />
                <Requests
                  page={page}
                  title="Lista de los requerimientos creados"
                  columns={columns}
                  sizePerPage={sizePerPage}
                  data={this.renderRowsFiltered(requests)}
                  onOpen={this.handleOnOpenRequest}
                  onSetPage={this.handleOnSetPage}
                />
              </>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay requerimientos por visualizar."
                    subtitle="Actualmente al día de hoy, no hay requerimientos digitales para visualizar, cuando hayan requerimientos se enlistarán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

OppsHistory.defaultProps = {
  name: "Historial de Requerimientos",
  parentName: "Managers",
  requests: [],
  columns: [
    "#REF",
    "Versión",
    "Oportunidad",
    "País",
    "Representante",
    "Cliente",
    "Estado",
    "Partes Críticas",
    "Modelo Negocio",
    "Fecha de Creación",
    // "Fecha de Actualización",
    "Creado Por",
  ],
  equipments: [],
  equipmentsSpare: [],
  activityFlow: [],
  references: [],
  referenceSpare: [],
  justifications: [],
  configurations: [],
  comments: [],
  resume: {},
  filters: [
    { key: "customer", name: "Cliente", placeholder: "Seleccione un cliente" },
    { key: "status", name: "Estado", placeholder: "Seleccione un estado" },
    {
      key: "opp",
      name: "Oportunidad",
      placeholder: "Seleccione una oportunidad",
    },
    { key: "country", name: "País", placeholder: "Seleccione un país" },
  ],
  filtersOptions: {
    customer: [],
    status: [],
    opp: [],
    country: [],
  },
  offersCalcs: {},
};

OppsHistory.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  requests: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  activityFlow: PropTypes.array.isRequired,
  equipments: PropTypes.array.isRequired,
  equipmentsSpare: PropTypes.array.isRequired,
  references: PropTypes.array.isRequired,
  referenceSpare: PropTypes.array.isRequired,
  justifications: PropTypes.array.isRequired,
  configurations: PropTypes.array.isRequired,
  comments: PropTypes.array.isRequired,
  resume: PropTypes.object.isRequired,
  offersCalcs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtersOptions: getFiltersOptions(state),
  requests: getAllInfoDigitalRequests(state),
  activityFlow: getActivitiesFlowByRequest(state),
  equipments: getEquipmenstByRequest(state),
  equipmentsSpare: getEquipmenstSpareByRequest(state),
  references: getReferencesByRequest(state),
  referenceSpare: getReferencesSpareByRequest(state),
  justifications: getJustificationsByRequest(state),
  configurations: getConfigurationsByRequest(state),
  comments: getCommentsByRequest(state),
  resume: getResumeOfferByRequest(state),
  offersCalcs: getOffersCalc(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllInformationDigitalRequest,
    findAllDataByDigitalRequest,
    findOffersRequestById,
  })(OppsHistory)
);
