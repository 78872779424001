/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "MainComponent" el cual llama a los diferentes componentes principales dependiendo del tabPanel que se haya seleccionado en CardHeader
    
*Props:
    setFormSelected: useState que actualiza el tab activo del tabContent del componente MainComponent
=========================================================

* Coded by Diego Meza - Application Management GBM

*/
import React from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";

const MaintainViewButton = ({ setFormSelected }) => {

    //#region Functions
    //Funcion para modificar el tab activo del tabContent del componente MainComponent
    const handleChange = (event, id) => {
        event.preventDefault();
        setFormSelected(id);
    };

    //Lista de las opciones del dropDown Button con el id del TabPanel del MainComponent, el icono y el titulo de la opción
    const dropdownList = [

        {
            index: "5",
            icon: "ni ni-single-02",
            title: "Accesos Proveedores",
        },
        {
            index: "6",
            icon: "ni ni-email-83",
            title: "Emails Facturación",
        },
        {
            index: "7",
            icon: "ni ni-calendar-grid-58",
            title: "Proyectos",
        },
        {
            index: "8",
            icon: "ni ni-circle-08",
            title: "Coordinadores",
        },
        {
            index: "9",
            icon: "ni ni-books",
            title: "Reporteria",
        },
        {
            index: "10",
            icon: "ni ni-single-02",
            title: "Altas",
        },
        
    ];

    //#endregion

    return (
        <>
            <UncontrolledDropdown nav>
                <DropdownToggle
                    color="info"
                    id="dropdownMenuButton"
                    type="button"
                    className="btn-icon-only rounded-circle"
                >
                    <span className="btn-inner--icon">
                        <i className="ni ni-settings-gear-65" />
                    </span>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow m-0">Vistas de Mantenimiento</h6>
                    </DropdownItem>
                    {
                        dropdownList.map((row, key) => (
                            <DropdownItem
                                key={key}
                                onClick={e => handleChange(e, row.index)}
                            >
                                <i className={row.icon} />
                                <span>{row.title}</span>
                            </DropdownItem>
                        ))
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    );
}

export default MaintainViewButton;