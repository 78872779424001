import React, { useState, useEffect } from 'react';
//reactstrap components
import { Container } from "reactstrap";
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CompetitionMainTable from 'components/PanamaBids/Competition/CompetitionMainTable.jsx';
import CompetitionModal from 'components/PanamaBids/Competition/CompetitionModal';
import CompetitionFilter from 'components/PanamaBids/Competition/CompetitionFilter';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//Dispatch
import { useDispatch } from 'react-redux';
import { getColumsCompetition, getAllCompetitionsProduct, getAllCompetitions } from 'actions/panamaBids.jsx';
import { Spin } from 'antd';

const Competition = () => {
    const MySwal = withReactContent(Swal);

    const dispatch = useDispatch();
    const [contInput, setContInput] = useState([1]);
    const [activeTab, setActiveTab] = useState("1");
    const [loading, setLoad] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [rowsModal, setRowsModal] = useState(
        {
            colums: [],
            rows: [],
            products: [],
            subtotal: 0,
            rowsExcel: []
        }
    );
    const [page, setPage] = useState(1);
    const [refreshInput, setrefreshInput] = useState("");
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    //Hook para traer la informacion de lo selects
    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
        }
        getRowsData();
    }, [])
    //Funcion para traer la informacion de lo selects y el nombre de las columnas de la tabla
    const handleRowInfo = async () => {
        setLoad(true);

        dispatch(getColumsCompetition()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    colums: [{ Comment: "Seleccione...", Field: 0 }, ...payload.data.payload.data]
                }))
            }
        })
        dispatch(getAllCompetitions()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    rows: payload.data.payload.data
                }))
            }
            setLoad(false);

        })

    }
    const handleOpenModal = (e) => {
        setDataModal(e);
        setrefreshInput(1);
        getPurchaseProductos(e.singleOrderRecord);
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setDataModal([]);
        setrefreshInput(2);
        setShowModal(false);
        setActiveTab("1");
        setRowsModal(prevState => ({
            ...prevState,
            products: []
        }))
    }
    const getPurchaseProductos = async (id) => {
        dispatch(getAllCompetitionsProduct(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    products: payload.data.payload.data
                }))
            }
        })
    }
    return (
        <div>
            <AdminHeader name="Home" parentName="Convenio Macro" />
            <Container className="mt--6" fluid>
                <CompetitionFilter
                    rows={rowsModal}
                    dataSelect={rowsModal.colums}
                    setrefreshInput={setrefreshInput}
                    setContInput={setContInput}
                    contInput={contInput}
                    setRowsModal={setRowsModal}
                    Toast={Toast}
                    dataRows={handleRowInfo}
                    setLoading={setLoad}
                />
                <Spin size="large" spinning={loading}>
                    <CompetitionMainTable
                        setRowsModal={setRowsModal}
                        rows={rowsModal.rows}
                        page={page}
                        setPage={setPage}
                        openModal={handleOpenModal} />
                </Spin>
                <CompetitionModal
                    dataModal={dataModal}
                    rowsModal={rowsModal}
                    showModal={showModal}
                    setRowsModal={setRowsModal}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    handleCloseModal={handleCloseModal}
                />


            </Container>
        </div>
    );
};


export default Competition;