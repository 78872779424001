import React from "react";
// react library for routing
import { Redirect, Route, Switch } from "react-router-dom";
// routes
import { CoeRoutes } from "routes/routes.jsx";

// nodejs library to set properties for components
import PropTypes from 'prop-types';

const Index = props => {

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/coe") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Switch>
        {
          getRoutes(CoeRoutes)
        }
        <Redirect from="/admin/coe" to="/admin/coe/planned-real-hours" />
      </Switch>
      <p className="lead"></p>
    </>
  );
};

Index.propTypes = {
  CoeRoutes: PropTypes.object,
};

export default Index;