// core antd
import { Tabs } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row, Table } from "reactstrap";

const { TabPane } = Tabs;

const EquipmentsList = props => {

  const [tabKey, setTabKey] = useState("1");

  const changeTab = value => {
    setTabKey(value);
  };

  const {
    title,
    subtitle,
    cols,
    rows,
    justifications,
    references,
    onBack,
  } = props;

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col xs="12" md='7'>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Tabs defaultActiveKey={tabKey} onChange={changeTab}>
          <TabPane tab="Lista de Equipos" key="1">
            <Table
              className="align-items-center table-dark table-flush"
              responsive
            >
              <thead className="thead-dark">
                <tr>
                  {
                    cols.map((row, key) => (row.hide === false && <th key={key}>{row.name}</th>))
                  }
                </tr>
              </thead>
              <tbody className="list">
                {
                  rows.map((row, key) => (
                    <tr key={key}>
                      {
                        cols.map((col, key) => (
                          col.hide === false &&
                          <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row[col.key]}
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </TabPane>
          <TabPane tab="Referencias" key="2">
            <div className="card-deck flex-column flex-xl-row">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col sm="12" md="8">
                      <h5 className="h3 mb-0">Documentos Cargados</h5>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ListGroup className="list my--3" flush>
                    {
                      references.length ?
                        references.map((row, key) => (
                          <ListGroupItem key={key} className="px-0">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="Notificación"
                                  className="avatar rounded-circle"
                                  src={require(`assets/img/theme/attachment.png`)}
                                />
                              </Col>
                              <div className="col ml--2">
                                <h4 className="mb-0">
                                  {row.name}
                                </h4>
                                <small>
                                  {
                                    `${row.createdBy} - ${moment(row.createdAt)
                                      .utc()
                                      .utcOffset(moment().utcOffset())
                                      .format("LLL")}`
                                  }
                                </small>
                              </div>
                              <Col className="col-auto">
                                <Button
                                  color="info"
                                  size="sm"
                                  href={`${urlBase}/digital-request/download-attachment/${window.btoa(row.path)}/${window.btoa(row.name)}`}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-file-download" />
                                  </span>
                                  <span className="btn-inner--text">Descargar</span>
                                </Button>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        ))
                        :
                        <div className="pt-4 pb-4">
                          <h5 className="h3 title">
                            <span className="d-block mb-1 text-center">Sin documentos</span>
                            <small className="h3 font-weight-light text-muted">
                              Al requerimiento que se seleccionó, no se le realizo la carga de ningún archivo de referencia.
                                  </small>
                          </h5>
                        </div>
                    }
                  </ListGroup>
                </CardBody>
                {
                  justifications.length ?
                    <>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col sm="12" md="8">
                            <h5 className="h3 mb-0">Justificaciones Agregadas</h5>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {
                          justifications.map((row, key) => (
                            <FormGroup key={key}>
                              <Input
                                disabled
                                id="justify"
                                rows="3"
                                resize="none"
                                type="textarea"
                                maxLength={500}
                                defaultValue={row.justify}
                              />
                            </FormGroup>
                          ))
                        }
                      </CardBody>
                    </> : null
                }
              </Card>
            </div>
          </TabPane>
        </Tabs>
        <div className="form-row align-items-center">
            <Col className="m-2" sm="12" md="3">
              <Button
                className="btn-icon"
                color="success"
                onClick={onBack}
                size='sm'
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-arrow-left" />
                </span>
                <span className="btn-inner--text">Regresar</span>
              </Button>
            </Col>
          </div>
      </CardBody>
    </Card>
  );
};

EquipmentsList.propTypes = {
  equipments: PropTypes.array.isRequired,
};

export default EquipmentsList;
