/*!

=========================================================
*ItRequests
=========================================================

*Este componente es el menu principal de solicitude de TI
=========================================================

* Coded by Sergio Marin  

*/
import { CustomCard } from "components/Shared/Cards/CustomCard";
import React from "react";
import { Card, Row, Col, CardBody } from "reactstrap";

import { IoIosPersonAdd } from "react-icons/io";
import { IoMdPersonAdd } from "react-icons/io";
import { LiaTasksSolid } from "react-icons/lia";
import { MdOutlinePersonSearch } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosCheckmark } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";

const Menu = ({ setData, setShowMenu, setStep, setLoading, roles }) => {
    //FUNCIONES 

    const menuButtons =
        [
            {
                id: "initial", //manager
                title: "Alta Colaborador",
                subtitle: "Crear nuevo colaborador",
                image: "elearning.png",
                colorCardSelected: "#d6d4fa",
                icon: IoIosPersonAdd
            },
            {
                id: "initialFreelance", //manager
                title: "Alta Colaborador Freelance - Externo",
                subtitle: "Crear nuevo colaborador Freelance",
                image: "teacher.png",
                colorCardSelected: "#d6d4fa",
                icon: IoMdPersonAdd
            },
            {
                id: "openReqs", //Todos
                title: "Mis Tareas",
                subtitle: "Mis solicitudes pendientes",
                image: "exam.png",
                roles: true,
                colorCardSelected: "#d6d4fa",
                icon: LiaTasksSolid
            },
            {
                id: "SearchReqs", //read only
                title: "Consultar Colaborador",
                subtitle: "Consultar solicitudes realizadas anteriormente",
                image: "tool-box.png",
                colorCardSelected: "#d6d4fa",
                icon: MdOutlinePersonSearch
            },
            {
                id: "UpdateReqs",
                title: "Baja o Modificación",
                subtitle: "Dar de Baja o Modificar datos de un colaborador",
                image: "idea.png",
                colorCardSelected: "#d6d4fa",
                icon: MdKeyboardArrowDown
            },
            {
                id: "UpdateReqsFrelance",
                title: "Baja o Modificación Freelance",
                subtitle: "Dar de Baja o Modificar un colaborador freelance o externo",
                image: "solar-panel.png",
                colorCardSelected: "#d6d4fa",
                icon: MdKeyboardArrowDown
            },
            {
                id: "AccessMatrix", //read only
                title: "Matriz de Accesos",
                subtitle: "Consultar rol por posición",
                image: "contacts.png",
                colorCardSelected: "#d6d4fa",
                icon: IoIosCheckmark
            }
        ];

    const setFormSelected = (id) => {
        if (id === "initial") {
            setLoading(true);
            setStep("InitialEnroll");
            setData({ requestState: "Alta" });
            setLoading(false);
            setShowMenu("request");
        } else if (id === "openReqs") {
            setShowMenu("openReqs");
        } else if (id === "SearchReqs") {
            setShowMenu("searchReqs");
        } else if (id === "UpdateReqs") {
            setShowMenu("updateReq");
        } else if (id === "initialFreelance") {
            setLoading(true);
            setStep("InitialFreelanceEnroll");
            setData({ isFreelance: 1, requestState: "Alta" });
            setLoading(false);
            setShowMenu("request");
        } else if (id === "UpdateReqsFrelance") {
            setShowMenu("updateReqF");
        } else if (id === "AccessMatrix") {
            setShowMenu("accessMatrix");
        }
    };

    //RETURN
    return (
        <Row>
            {menuButtons.map((menuButton) => (
                (roles.buttons && roles.buttons.includes(menuButton.id)) && (

                    // <Col sm="14" md="3" key={menuButton.id}>
                    //     <Row>
                    //         <div className="col">
                    //             <Card
                    //                 className="card-stats card-lift--hover"
                    //                 style={{ backgroundColor: "#ffffff", cursor: "pointer", }}
                    //                 onClick={() => setFormSelected(menuButton.id)}
                    //             >
                    //                 <CardBody>
                    //                     <img
                    //                         alt="..."
                    //                         className="rounded img-center img-fluid shadow shadow-lg--hover"
                    //                         src={require(`assets/img/masterdata/${menuButton.image}`)}
                    //                         style={{ objectFit: "contain", width: "60px", height: "60px" }}
                    //                     />

                    //                     <div className="pt-1 text-center">
                    //                         <h4 className="h3 title">
                    //                             <span className="d-block mb-1">{menuButton.title}</span>
                    //                             <small className="h5 font-weight-light text-muted">
                    //                                 {menuButton.subtitle}
                    //                             </small>
                    //                         </h4>
                    //                     </div>
                    //                 </CardBody>
                    //             </Card>
                    //         </div>
                    //     </Row>
                    // </Col>

                    <CustomCard
                        title={menuButton.title}
                        subtitle={menuButton.subtitle}
                        onClick={() => setFormSelected(menuButton.id)}
                        Icon={menuButton.icon}
                        xs={"6"}
                        sm={"3"}
                    // image={row.image}
                    // colorCardSelected={row.colorCardSelected}
                    // formSelected={formSelected} 
                    />
                )
            ))}
        </Row>
    );
};

export default Menu;