import moment from "moment";
import "moment/locale/es";
moment.locale("es");

export const getAllCustomers = state => {

    const { findAllCustomers, findAllCountrys } = state
    if (Object.keys(findAllCustomers).length) {
        const { CUSTOMERS } = findAllCustomers;
        const { listas } = findAllCountrys;
        if (listas && CUSTOMERS) {
            if (listas.length && CUSTOMERS.length) {
                for (let items of CUSTOMERS) {
                    const idCustomer = items["idClient"];
                    
                    if (items["active"] === "0") {
                        items["active"] = "Bloqueado";
                    } else {
                        items["active"] = "Activo";
                    }
                    if (typeof idCustomer === 'number') {
                        items["idClient"] = `00${items["idClient"]}`
                    }
                }
            }
        }
        return findAllCustomers.CUSTOMERS
    }
    return [];
}
export const getFileUpdateContact = state => {

    const { sendEmailFileContact } = state
    if (Object.keys(sendEmailFileContact).length) {

        return sendEmailFileContact
    }
    return [];
}

export const getAllContactsByCustomer = state => {

    const { findAllContactsByCustomer, findAllFunctions, findAllDepartaments, findAllCountrys } = state

    if (Object.keys(findAllContactsByCustomer).length) {
        for (let items of findAllContactsByCustomer.response) {
            for (const countrys of findAllCountrys.listas) {
                if (items["COUNTRY"] === countrys["Code_Country"]) {
                    items["COUNTRY"] = countrys["Name_Country"]
                }
            }
            for (const functions of findAllFunctions.listas) {
                if (items["FUNCTION"] === functions["Code_Function"]) {
                    items["FUNCTION"] = functions["Name_Function"]
                }
            }
            for (const departaments of findAllDepartaments.listas) {
                if (items["DEPARTAMENT"] === departaments["Code_Departament"]) {
                    items["DEPARTAMENT"] = departaments["Name_Departament"]
                }
            }
            if (items["TITLE"] === "0001") {
                items["TITLE"] = "Señora"
            } else if (items["TITLE"] === "0002") {
                items["TITLE"] = "Señor"
            }
            if (items["LANGUAGE"] === "EN") {
                items["LANGUAGE"] = "Inglés"
            } else if (items["LANGUAGE"] === "ES") {
                items["LANGUAGE"] = "Español"
            }
        }
        let newAllSignatures = findAllContactsByCustomer.response.map((row) => {
            if (row.UPDATEDATE !== "") {
                row.UPDATEDATE = moment(row.UPDATEDATE)
                    .utc()
                    .utcOffset(moment().utcOffset())
                    .format('YYYY-MM-DD h:mm:ss a');
            }
            return row;

        });

        return newAllSignatures
    }
    return [];
}
export const getAllFunctions = state => {

    const { findAllFunctions } = state
    if (Object.keys(findAllFunctions).length) {
        const allFunction = findAllFunctions.listas.map((row) => {
            return {
                label: row.Name_Function,
                value: row.Code_Function
            }
        })
        return [{ label: "", value: "" }, ...allFunction]
    }
    return [];
}

export const getAllDepartaments = state => {

    const { findAllDepartaments } = state
    if (Object.keys(findAllDepartaments).length) {
        const allDepartaments = findAllDepartaments.listas.map((row) => {
            return {
                label: row.Name_Departament,
                value: row.Code_Departament
            }
        })
        return [{ label: "", value: "" }, ...allDepartaments]
    }
    return [];
}
export const getAllCountrys = state => {

    const { findAllCountrys } = state
    if (Object.keys(findAllCountrys).length) {
        const allCountry = findAllCountrys.listas.map((row) => {
            return {
                label: row.Name_Country,
                value: row.Code_Country
            }
        })
        return [{ label: "", value: "" }, ...allCountry]
    }
    return [];
}
export const getAllHistory = state => {

    const { findAllHistory } = state
    if (Object.keys(findAllHistory).length) {

        return findAllHistory.response
    }
    return [];
}
export const getContactUpdate = state => {

    const { updateContact } = state
    if (Object.keys(updateContact).length) {

        return updateContact
    }
    return [];
}