// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const numberWithCommas = (x) => {
  if (x >= 0)
    return `$${parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  else
    return null;
};

const CardTransactions = props => {

  const {
    data
  } = props;

  return (
    <>
      <Row>
        <Col sm='12' md='4'>
          <Card className="bg-gradient-success border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    Contratos On Hold
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {data.amountContracts}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className="ni ni-bell-55" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm='12' md='4'>
          <Card className="bg-gradient-warning border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0 text-white"
                  >
                    Monthly Value (USD)
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {numberWithCommas(data.amountMonthlyValue)}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className="fas fa-exclamation" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm='12' md='4'>
          <Card className="bg-gradient-danger border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0 text-white"
                  >
                    Contract Value (USD)
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {numberWithCommas(data.amountNetValue)}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className="fas fa-exclamation-triangle" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

CardTransactions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CardTransactions;