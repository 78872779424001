// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

const SignaturesFlowList = props => {

  const {
    boss,
    title,
    subtitle,
    signatures,
    openSignature
  } = props;

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col sm="12" md="8">
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <ListGroup className="list my--3" flush>
          {
            signatures.map((row, key) => (
              <ListGroupItem key={key} className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      alt="Notificación"
                      className="avatar rounded-circle"
                      src={require(`assets/img/theme/signature-supervisor-flow.png`)}
                    />
                  </Col>
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      {row.document}
                    </h4>
                    <small>{row.createdAt} - {row.nameUser}</small>
                  </div>
                  <Col className="col-auto">
                    <Button
                      color="success"
                      // size="sm"
                      type="button"
                      onClick={() => openSignature(row)}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className={boss ? "fas fa-user-check" : "fas fa-user-edit"} />
                      </span>
                      <span className="btn-inner--text">{boss ? 'Verificar' : 'Corregir'}</span>
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            ))
          }
        </ListGroup>
      </CardBody>
    </Card>
  );
};

SignaturesFlowList.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SignaturesFlowList;