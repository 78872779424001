// javascript plugin
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const RelaunchFlow = props => {

  const {
    title,
    showModal,
    toggleModal,
    loading,
    message,
    onWorkFlow
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.action || values.action === "0")
      errors.action = "Debes seleccionar la acción de aprobación.";
    if (!values.comments || values.comments === "")
      errors.comments = "Debes ingresar un comentario para el colaborador.";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={() => toggleModal("showModal")}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent p-2">
            <div className="text-muted text-center mt-2 mb-2">
              <h6 className="surtitle">{title}</h6>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-3">
            <Form
              onSubmit={onWorkFlow}
              validate={validate}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <FormGroup className="mb-1">
                      <Label className="form-control-label" for="action">
                        Flujo de Aprobación
                      </Label>
                      <Field name="action">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              // placeholder="Nombre de la posición"
                              valid={!meta.error && meta.modified && meta.touched}
                              invalid={meta.error && meta.touched}
                            >
                              <option key="0" value="0">Seleccione una opción</option>
                              <option key="1" value="1">Aprobar</option>
                              {/* <option key="2" value="2">Rechazar</option> */}
                              <option key="3" value="3">Solicitar corrección</option>
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup className="mb-1">
                      <Label className="form-control-label" for="comments">
                        Comentarios (500)
                        </Label>
                      <Field name="comments">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="textarea"
                              placeholder="Escriba aquí los comentarios para el colaborador."
                              maxLength={500}
                              rows="3"
                              resize="none"
                              invalid={meta.error && meta.touched}
                            />
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Continuar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

RelaunchFlow.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RelaunchFlow;