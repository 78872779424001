/*!

=========================================================
*Autopp - UploadBOM
=========================================================

* Este componente se encarga de subir los archivos que el usuario
*desee, ya que hay veces que en las opps no llevan LDRS, si no que se 
*suben archivos BOM que los reemplaza.

*La forma en que sube los archivos es gracias a un temporalBOMId
*se crea una carpeta en el server con el nombre del temporalBOMId donde se almacenan los archivos, 
*luego de que se envia la gestión,
*se cambia el nombre de la carpeta de ese temporalBOMId al id de la gestión de oppRequests.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

// Librería de React
import React, { useContext } from 'react';

// Componentes de reactstrap
import {
    Container,
    FormGroup,
    Col,
    Row,
} from "reactstrap";

import { useDispatch } from 'react-redux';
import { Upload, Icon } from 'antd';

//Endpoint de autopp
import { deleteFile } from 'actions/autoppLdrs';

//Urls
import urls from "api/urls.jsx";

//Para extraer estados globales por contexto
import { UserContext } from '../SharedComponents/UserContext';

//Para subir archivos
const { Dragger } = Upload;


export const UploadBOM = ({
    subtitleBOM,
    titleBOM,
}) => {

    //Para disparar al api de ss
    const dispatch = useDispatch();

    //#region States globales
    const {
        setFileListBOM,
        temporalFolderFilesId,
        toggleCheck,
        fileListBOM,
    } = useContext(UserContext);
    //#endregion

    //Desestructuración del objeto de fileList
    const { fileList } = fileListBOM;



    //Función para subir o eliminar archivos del servidor, gracias a un temporalBOMId
    //se crea una carpeta en el server donde se almacenan los archivos, luego de que se envia la gestión,
    //se cambia el nombre de la carpeta a ese temporalBOMId al id de la gestión de oppRequests
    const handleOnFileList = (info) => {

        const { status } = info.file;
        //console.log(status);
        if (status === "removed") {

            const { file: { name, uid, response: { status } } } = info;
            if (status === 200) {
                setFileListBOM((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            } else {
                setFileListBOM((prevState) => (
                    fileList.filter((row) => row.uid !== uid)
                ));
            }


            dispatch(deleteFile({ temporalFolderFilesId: temporalFolderFilesId, name: name })).then((resp) => {
                const { payload } = resp;
                if (payload.status !== 200) {
                    console.log("Error al intentar borrar el archivo:");
                    console.log(payload)
                }
            })



        } else { //Éxito
            const { fileList, file: { status, name } } = info;
            //console.log(info)
            if (status === 'error') {
                console.log(`Ocurrió un error cargando el archivo ${name}.`);
                console.log(info);
            }

            setFileListBOM({ fileList: fileList });
        }
    }

    const allowedFileTypes = [
        ".xlsx", ".xls", ".xlsb", ".xlt", ".ppt", ".pptx", ".pdf", ".doc", ".docx",
        ".rar", ".zip", ".cfr", ".csv", ".txt", ".eml", ".jpg", ".png", ".html", ".rtf", ".xml",
        ".css", ".tif", ".ods", ".msg"
    ];

    //Props utilizados para el drag de subido de archivos.
    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: allowedFileTypes.join(","),  multiple: true,
        action: `${urls.AUTOPPLDRS.uploadFiles}/${temporalFolderFilesId}`,
        beforeUpload: file => {
            const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo

            if (!allowedFileTypes.includes(fileType)) {
                console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
                return false;
            }
            return true;
        },
        onChange: info => {
            console.log(info)
            // Filtrar la lista de archivos válidos para mantener solo los que aún existen
            const filteredFiles = info.fileList.filter(file => {
                console.log(file)
                if (file.status) {
                    return true;
                }
                return false;
            });
            info.fileList = filteredFiles;
            // Actualizar la lista de archivos válidos
            // Llamar a la función handleOnFileList con la lista de archivos válidos
            handleOnFileList(info);
        },
    };





    //console.log(fileListBOM)


    return (

        <>
            <Row className="mb-2" >

                <Col xs="12" sm='8'>
                    <h3 className="mb-0">{titleBOM}</h3>
                    <p className="text-sm mb-0">{subtitleBOM}</p>


                </Col>
            </Row>
            <hr align="left" width="65%" xs="12" className='mt-1 mb-4' />
            <Row className="align-items-center">
                <Col xs="12">
                    <FormGroup>
                        <div className="form-row align-items-center">
                            <Col className="m-1" sm="12">
                                <Container>

                                    <Dragger {...uploadProps} fileList={fileList}>
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox" />
                                        </p>
                                        <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                                        <p className="ant-uploading-hint">
                                            Puede seleccionar archivos de diferentes folders/carpetas
                                        </p>
                                    </Dragger>

                                </Container>
                            </Col>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}
