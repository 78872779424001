/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Projects" del boton de Mantenimiento en el cual se crea,modifica o bloquea un proyecto
    
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    MySwal: funcion para las notificaciones personalizadas
    setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState } from "react";
// reactstrap components
import { Button, Card, CardBody, Col, Row } from "reactstrap";
//usedispatch for fetch
import { useDispatch } from "react-redux";
//accion para bloquear y crear un proyecto respectivamente
import { blockProject, newProject } from "actions/Freelance";
//customized Component
import MainTable from "components/Freelance/SharedComponents/MainTable.jsx";

const Projects = ({
  setLoad,
  Toast,
  dropDownOptions,
  rolArea,
  isAdmin,
  MySwal,
  setRefreshOptions,
}) => {
  //#region Funtions
  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region State para guardar los filtros seleccionados según cada tabla en FilterMain
  const [search, setSearch] = useState({});
  //#endregion

  //#region state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);
  //#endregion

  //state para recargar la tabla principal cuando se necesite
  const [reloadTable, setRealTable] = useState(false);
  //#endregion

  //#region funcion para buscar los proyectos
  const handleSearch = () => {
    setPage(1);
    setRealTable((prevState) => !prevState);
  };
  //#endregion
  //#region Funciones para abrir el SwalAlert segun el tipo que se le envia en la misma
  const openCreateModal = (e, row, dropDown) => {
    openAlert(e, "crear", dropDown, row);
  };

  const editProject = (e, row, dropDown) => {
    openAlert(e, "editar", dropDown, row);
  };

  const openAlert = (e, type, dropDown, row) => {
    let selectHtml = `<label for="weekendAble">Reporta Fin de Semana</label> <select id="weekendAble" class="form-control">  <option value=""></option> <option ${
      type === "editar" ? (row.weekendAble === 1 ? "selected" : "") : ""
    } value="1">Si</option> <option ${
      type === "editar" ? (row.weekendAble === 0 ? "selected" : "") : ""
    } value="0">No</option>  </select>`;

    MySwal.fire({
      title: `${
        type === "crear" ? "Crear nuevo proyecto" : "Modificar proyecto "
      } `,
      html:
        `<FormGroup>` +
        selectHtml +
        `<label for="newProject">Descripción del Proyecto</label><input class="form-control" rows="3" id="newProject" placeholder="Ingrese la descripcion del proyecto..." value="${
          type === "editar" ? row.project : ""
        }"  ></input></FormGroup>`,
      confirmButtonText: `${type === "crear" ? "Crear" : "Modificar"} `,
      confirmButtonColor: "#50D050",
      cancelButtonText: `Cancelar`,
      showCancelButton: true,
      preConfirm: () => {
        setLoad(true);
        if (
          document.getElementById("weekendAble").value === "" ||
          document.getElementById("newProject").value === ""
        ) {
          MySwal.showValidationMessage("Los campos son obligatorios");
          setLoad(false);
        } else {
          var info = {
            row: row,
            action: type,
            project: document.getElementById("newProject").value,
            weekendAble: document.getElementById("weekendAble").value,
          };
          dispatch(newProject({ info: info })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
              setRealTable((prevCheck) => !prevCheck);
              setRefreshOptions((prevCheck) => !prevCheck);
              Toast.fire({
                title: "Atención",
                html: `Se ${type === "crear" ? "Creo" : "Modifico"} con éxito`,
                type: "success",
              });
            } else if (payload.response.status === 409) {
              Toast.fire({
                title: "Atención",
                html: payload.response.data.message,
                type: "warning",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.message,
                type: "warning",
              });
            }
            setLoad(false);
          });
        }
      },
    });
  };
  //#endregion
 
  //#region Funcion para bloquear un proyecto
  const handleBlockProject = (e, row) => {
    MySwal.fire({
      title: `Deseas  el acceso del proyecto? `,
      text: "Se puede reversar con ayuda de Application Management",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Si, ${row.active === 1 ? "Bloquear" : "Desbloquear"}`,
      confirmButtonColor: `${row.active === 1 ? "#fa0303" : "#50D050"}`,
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          row: row,
        };
        dispatch(blockProject({ info })).then((resp) => {
          const { payload } = resp;
          if (payload.status === 200) {
            setRealTable((prevState) => !prevState);
            setRefreshOptions((prevCheck) => !prevCheck);
            Toast.fire({
              title: "Atención",
              html: `Se ${
                row.active === 1 ? "Bloqueo" : "Desbloqueo"
              } con éxito`,
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.message,
              type: "warning",
            });
          }
          setLoad(false);
        });
      }
    });
  };
  //#endregion

   //#region Botones del FilterMain para ejecutar las funciones
  const buttons = [
    {
      color: "primary",
      icon: "fas fa-search",
      title: "Buscar",
      function: handleSearch,
      colWidth: "6",
    },
    {
      color: "secondary",
      icon: "fa fa-plus",
      title: "Crear Proyecto",
      function: openCreateModal,
      colWidth: "6",
    },
  ];
  //#endregion
  
  //#region botones de la tabla MainTable
  const actions = [
    {
      color: "secondary",
      icon: "fas fa-edit",
      fun: editProject,
      add: 0,
      title: "Editar Proyecto",
      id: "editProject",
    },
    {
      color: "secondary",
      icon: "fas fa-lock",
      fun: handleBlockProject,
      add: 0,
      title: "Des/Bloquear Proyecto",
      id: "blockProject",
    },
  ];
  //#endregion

 //#region columnas seleccionados de la tabla MainTable
  const [selected, setSelected] = useState([
    { value: "id", label: "Id" },
    { value: "project", label: "Proyecto", disabled: true },
    {
      value: "activeName",
      label: "Estado",
      type: "color",
      optionList: "activeStatus",
      option: "statusType",
      val: "active",
    },
    { value: "createdAt", label: "Fecha", type: "date" },
    { value: "createdBy", label: "Creado por" },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ]);
  //#endregion
  
  //#region columnas de la tabla MainTable
  const optionsSelect = [
    { value: "id", label: "Id" },
    { value: "project", label: "Proyecto", disabled: true },
    {
      value: "activeName",
      label: "Estado",
      type: "color",
      optionList: "activeStatus",
      option: "statusType",
      val: "active",
    },
    { value: "createdAt", label: "Fecha", type: "date" },
    { value: "createdBy", label: "Creado por" },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ];
  //#endregion
  //#endregion

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            {buttons.map((row, key) => (
              <Col sm={row.colWidth} key={key}>
                <Button
                  className="btn-icon"
                  color={row.color}
                  size="sm"
                  block
                  type="button"
                  onClick={(e) => row.function(e, undefined, dropDownOptions)}
                >
                  <span className="btn-inner--icon mr-">
                    <i className={row.icon} />
                  </span>
                  <span className="btn-inner--text">{row.title}</span>
                </Button>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      <MainTable
        setPage={setPage}
        page={page}
        reloadTable={reloadTable}
        search={search}
        Toast={Toast}
        dropDownOptions={dropDownOptions}
        selected={selected}
        setSelected={setSelected}
        optionsSelect={optionsSelect}
        tableParameter={"pro"}
        rolArea={rolArea}
        statusError={"NA"}
        isAdmin={isAdmin}
      />
    </>
  );
};

export default Projects;
