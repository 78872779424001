// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react plugin used to create charts
import { Bar, Line, Doughnut } from "react-chartjs-2";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const Chars = props => {

  const {
    name,
    filter,
    filters,
    viewMode,
    onSearch,
    changeviewMode,
    actionsDates,
    options,
    data,
    bar,
    line,
    doughnut
  } = props;

  const title = filter ? filters.find(e => e.key === filter).name : 'Clientes (Todos)';

  return (
    <>
      {doughnut ?
        <Card>
          <CardHeader>
            <h5 className="surtitle">{name}</h5>
          </CardHeader>
          <CardBody>
            <div className="chart">
              <Doughnut
                data={data}
                options={options}
                className="chart-canvas"
                id="chart-doughnut"
              />
            </div>
          </CardBody>
        </Card>
        :
        <Card>
          <CardHeader>
            <Row className="align-items-center py-4">
              <Col xs='12' sm='6' md={12 - actionsDates.length - 1}>
                <Row>
                  <Col sm='6' md='4'>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {`${name} ${title}`}
                    </h6>
                  </Col>
                  <Col sm='6' md='3'>
                    <Button
                      color="info"
                      size="sm"
                      onClick={onSearch}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-search" />
                      </span>
                      <span className="btn-inner--text">Buscar</span>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs='12' sm='6' md={actionsDates.length + 1}>
                <Row>
                  {
                    actionsDates.map((row, key) => {
                      return (
                        <Col key={key} sm='4' md={row.key === 'day' ? 2 : actionsDates.length > 2 ? 3 : 4}>
                          <Button
                            color={viewMode === row.key ? "success" : "secondary"}
                            size="sm"
                            onClick={() => changeviewMode(row.key)}
                          >
                            <span className="btn-inner--text">{row.name}</span>
                          </Button>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="chart">
              {bar &&
                <Bar
                  data={data}
                  options={options}
                  className="chart-canvas"
                  id="chart-bar-stacked"
                />
              }
              {line &&
                <Line
                  data={data}
                  options={options}
                  id="chart-sales"
                  className="chart-canvas"
                />
              }
            </div>
          </CardBody>
        </Card>
      }
    </>
  );
};

Chars.propTypes = {
  filters: PropTypes.array.isRequired,
  viewMode: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  changeviewMode: PropTypes.func.isRequired,
  actionsDates: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default Chars;