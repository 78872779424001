/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Table,
} from "reactstrap";

const Filters = (props) => {
  const {
    title,
    subtitle,
    cols,
    options: {
      valueFilter,
      typeFilter,
      filtersApplied,
      conditionFilter,
      optionsFilter,
    },
    onClear,
    changeTypeFilter,
    addFilter,
    deleteFilter,
  } = props;

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col sm="12" md="10">
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{subtitle}</p>
          </Col>
          <Col
            xs="12"
            md="2"
            className="p-1"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              block
              type="button"
              onClick={onClear}
            >
              <span className="btn-inner--icon mr-">
                <i className="fas fa-trash" />
              </span>
              <span className="btn-inner--text">Limpiar</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            sm="4"
            md={
              typeFilter.type === "text" || typeFilter.type === "select"
                ? "4"
                : "3"
            }
          >
            <FormGroup>
              <label className="form-control-label" htmlFor="typeFilter">
                Columna
              </label>
              <Select
                isSearchable
                className="basic-single"
                id="typeFilter"
                type="select"
                placeholder="Seleccione la columna que deseas filtrar"
                options={cols}
                value={typeFilter}
                onChange={(e) => changeTypeFilter(e, "typeFilter")}
              />
            </FormGroup>
          </Col>
          {typeFilter.type !== "text" && typeFilter.type !== "select" && (
            <Col sm="4" md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="condition">
                  Condición
                </label>
                <Select
                  isSearchable
                  className="basic-single"
                  id="condition"
                  type="select"
                  placeholder="Seleccione la condición a aplicar"
                  options={[
                    { value: 1, label: "Mayor que", simbol: ">" },
                    { value: 2, label: "Menor que", simbol: "<" },
                    { value: 3, label: "Igual que", simbol: "===" },
                  ]}
                  value={conditionFilter}
                  onChange={(e) => changeTypeFilter(e, "conditionFilter")}
                />
              </FormGroup>
            </Col>
          )}
          <Col
            sm="4"
            md={
              typeFilter.type === "text" || typeFilter.type === "select"
                ? "4"
                : "3"
            }
          >
            {typeFilter.type === "select" ? (
              <FormGroup>
                <label className="form-control-label" htmlFor="value">
                  Valor
                </label>
                <Select
                  isSearchable
                  className="basic-single"
                  id="value"
                  style={{ height: "calc(2.25rem + 2px)" }}
                  type={typeFilter.type}
                  placeholder="Seleccione el valor a filtrar"
                  options={optionsFilter}
                  value={valueFilter}
                  onChange={(e) => changeTypeFilter(e, "valueFilter")}
                />
              </FormGroup>
            ) : (
              <FormGroup>
                <label className="form-control-label" htmlFor="value">
                  Valor
                </label>
                <InputGroup className="input-group-merge">
                  <Input
                    id="value"
                    className="m-0"
                    style={{ height: "calc(2.25rem + 2px)" }}
                    placeholder="Ingrese el valor a filtrar en la columna"
                    type={typeFilter.type}
                    value={valueFilter}
                    options={optionsFilter}
                    onChange={(e) => changeTypeFilter(e, "valueFilterText")}
                  />
                </InputGroup>
              </FormGroup>
            )}
          </Col>
          <Col sm="4" md="2" className="d-flex align-items-center">
            <Button
              className="btn-icon"
              color="success"
              size="md"
              block
              disabled={!valueFilter || typeFilter.value === "all"}
              type="button"
              onClick={() =>
                addFilter(valueFilter, typeFilter, conditionFilter)
              }
            >
              <span className="btn-inner--icon mr-">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">Agregar</span>
            </Button>
          </Col>
        </Row>
        {filtersApplied.length ? (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                  Columna
                </th>
                <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                  Condición
                </th>
                <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                  Valor
                </th>
                <th />
              </tr>
            </thead>
            <tbody className="list">
              {filtersApplied.map((row, key) => (
                <tr key={key}>
                  <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                    {row.name}
                  </th>
                  <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                    {row.simbol === "NA" ? "Igual" : row.condition}
                  </th>
                  <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                    {row.value}
                  </th>
                  <th style={{ whiteSpace: "normal", textAlign: "center" }}>
                    <a
                      className="table-action table-action"
                      href="#"
                      onClick={() => deleteFilter(row)}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}
      </CardBody>
    </Card>
  );
};

Filters.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Filters;
