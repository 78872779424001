import React from 'react';
import PropTypes from 'prop-types';
import { Row, Card, CardFooter, Pagination, Table, PaginationItem, Col, PaginationLink, CardBody, Input, CardHeader, Button } from "reactstrap";
import ExportConstantsButton from 'components/Contacts/ExportConstantsButton.jsx';
import ExportExcel from "react-html-table-to-excel";
const CustomersRequest = props => {
    const {
        cols,
        rows,
        page,
        sizePerPage,
        onSetPage,
        showContacts,
        setNumPagination,
        downloadFile,
        isAdmin
    } = props

    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1
    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => onSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }
    return (
        <div>
            <Card> <CardHeader className="bg-transparent border-0">
                <Row>
                    <Col >
                        <h3 className="mb-0">Lista de clientes</h3>
                    </Col>
                    <Col>
                        <Row className="justify-content-end mb-3" style={{ marginRight: '2px' }}>
                            {
                                isAdmin &&
                                <ExportConstantsButton />
                            }

                            <Button color="primary " onClick={() => downloadFile("Lista de clientes")}>
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-download" />
                                </span>Exportar Lista Clientes
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
                <CardBody>
                    <Table
                        hover
                        id="tableCustomers"
                        className="align-items-center "
                        responsive
                    >

                        <thead>
                            <tr style={{ position: "sticky", top: 0, zIndex: "10", backgroundColor: "white" }}>
                                {
                                    cols.map((row, key) => (
                                        <th id={key}>
                                            <label> {row.label} </label>
                                        </th>
                                    )
                                    )
                                }
                            </tr>
                        </thead>

                        <tbody className="list">
                            {
                                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                                    <tr key={key} onClick={(e) => showContacts(e, row)} >
                                        {
                                            cols.map((col, key) => (
                                                <th key={key} >
                                                    {
                                                        row[col.value]
                                                    }
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>

                    </Table>
                </CardBody>
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Row className="align-items-center">
                            <Col xs='12' md='6' className="p-1">
                                <Row className="justify-content-start">
                                    <Col xs='12' md='6'>
                                        <span className='pagination mb-0'>
                                            Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                        </span>
                                    </Col>
                                    <Col xs='12' md='2'>
                                        <Input type="select" onChange={(e) => setNumPagination(e)} defaultValue={sizePerPage}>
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs='12' md='6' className="p-1">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => onSetPage(page === 1 ? page : page - 1, "page")}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {
                                        renderPaginations()
                                    }
                                    <PaginationItem className={page === paginations ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() => onSetPage(page === paginations ? page : page + 1, "page")}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </nav>
                </CardFooter>
            </Card>
        </div >

    );
};

CustomersRequest.propTypes = {

};

export default CustomersRequest;