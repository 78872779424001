/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component used to showed to the user different actions in OS / Network Record´s Table
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import { FaEye } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { Button } from "reactstrap";

function ActionsButton({ onDelete, onDetails, report, status }) {
  return (
    <div
      style={{
        whiteSpace: "normal",
        textAlign: "justify",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <div>
        <Button onClick={() => onDetails(report)}>
          <FaEye style={{ color: "dodgerblue", fontSize: "15px" }} />
        </Button>
      </div>
      <div>
        <Button onClick={() => onDelete(report.ID_REPORTE)} disabled={status}>
          <FaRegTrashAlt
            style={{ color: status ? "grey" : "red", fontSize: "15px" }}
          />
        </Button>
      </div>
    </div>
  );
}

ActionsButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onDetails: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
};
export default ActionsButton;
