/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
This is the component of the button used to relate an order when creating an extra hour report
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

function ButtonRelateOrder({ onSelect, order }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        color="success"
        style={{
          borderRadius: "12px",
          height: "40px",
          fontSize: "13px",
          width: "120px",
        }}
        onClick={() => onSelect(order)}
      >
        Seleccionar
      </Button>
    </div>
  );
}

ButtonRelateOrder.propTypes = {
  onSelect: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};
export default ButtonRelateOrder;
