/*!

=========================================================
*Home WMS
=========================================================

*Componente view para administrar las vistas de wms.
=========================================================

* Coded by Diego Meza / Eduardo Piedra - Application Management GBM

*/

// react library
import React, { useState, useEffect, useRef } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
//dispatch hook
import { useDispatch } from 'react-redux';
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// antd components
import { Spin } from 'antd';
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// customized components
import CardHeaders from 'components/WMS/CardHeader.jsx';

//Import de componente contexto, para establecer variables globales
import { UserContext } from 'components/WMS/SharedComponents/UserContext';

//Imports de actions de consultas al API.
import {
    getLocationsUser,
} from 'actions/wms';
import { NotAccessView } from "components/WMS/SharedComponents/NotAccessView";

import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';

import { isBrowser } from 'react-device-detect';
import { WmsLogin } from "components/WMS/SharedComponents/WmsLogin";


const Home = () => {

    useEffect(() => {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
    }, [])

    const [isMobile, setIsMobile] = useState(false);
    const [isComputer, setIsComputer] = useState(false);

    const [NotAccessViewMessage, setNotAccessViewMessage] = useState(null);

    const [sapPassword, setSapPassword] = useState(null);

    //Efecto para validar si es mobile o pc
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            setIsComputer(window.innerWidth >= 768);

        };

        // Llama a handleResize al principio y cada vez que se redimensiona la ventana
        handleResize();
        window.addEventListener('resize', handleResize);

        // Limpia el listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(true);
    //#endregion

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region Extraer datos de API

    const [options, setOptions] = useState([])
    const [warehouse, setWarehouse] = useState(null);

    useEffect(() => {
        getLocationsUserE()
    }, [])

    const getLocationsUserE = () => {

        dispatch(getLocationsUser()).then((resp) => {

            const { payload } = resp;
            setLoad(false);

            if (payload.status === 200) {
                // console.log(payload)
                let binLocations = payload.data.payload.binLocations;
                let noBinLocations = payload.data.payload.noBinLocations;

                let plants = payload.data.payload.plants;
                let warehouses = payload.data.payload.warehouses;

                if (warehouses.length == 0/*No tiene bodegas asignadas*/) {
                    setNotAccessViewMessage("No tiene bodegas asignadas en SAP.");
                    return;
                }

                setWarehouse(warehouses[0]) //En wms antiguo usan el primer warehouse.

                //Convertirlo a options.
                plants = plants.map((plant, plantKey) => ({ value: plant, label: plant }));

                //Convertirlo a options.
                warehouses = warehouses.map((warehouse, warehouseKey) => ({ value: warehouse, label: warehouse }));

                setOptions({ ...options, plants: plants, warehouses: warehouses, binLocations: binLocations, noBinLocations: noBinLocations })

            } else {
                console.log(payload)
                notify("warning", "Error", "Error al conectarse con el servidor.")

            }
        })
    }


    //#endregion

    //#region Mensajes de notificación

    const MySwal = withReactContent(Swal);
    //#endregion

    //#region state para guarda el ID del card que selecciona el usuario
    const [formSelected, setFormSelected] = useState("0");
    //#endregion

    //Referencia para el alert.
    const notifyProps = useRef(null);

    //Método para generar notificaciones con NotificationAlert.
    const notify = (type, title, message) => {
        try {
            let options = {
                place: "br",
                message: (
                    <div className="alert-text">
                        <span className="alert-title" data-notify="title">
                            {title}
                        </span>
                        <span data-notify="message">{message}</span>
                    </div>
                ),
                type: type,
                icon: "ni ni-bell-55",
                autoDismiss: 10,
            };
            notifyProps.current.notificationAlert(options);
        } catch (error) { }
    };

    //Efecto para determinar si el usuario sólo puede usar un handheld.
    useEffect(() => {
        const pcPermission = getTeamsUserAccess().includes("WMS admin");
        if (isBrowser /*Es una computadora*/ && !pcPermission /*Y no tiene permiso para computadora*/) {
            setNotAccessViewMessage("Debe utilizar únicamente el handheld.")
        }
    }, [])

    useEffect(() => {
        // console.log("SAP PASSWORD", sapPassword)
    }, [sapPassword])

    return (
        <>
            <UserContext.Provider value={
                {
                    isMobile,
                    isComputer,

                    options,
                    notify,
                    MySwal,

                    warehouse,
                    loading,
                    setLoad,

                    sapPassword,
                    setSapPassword

                }
            }>

                <div className="rna-wrapper">
                    <NotificationAlert ref={notifyProps} />
                </div>
                {
                    (!isMobile ?

                        <AdminHeader name="Inicio" parentName={warehouse ? ("GBM Warehouse: " + warehouse) : "GBM Gestor de bodegas"} />
                        :
                        <Row className="mt-2 justify-content-between">
                            <Col xs="3" className="mr--3">
                                <h3 className="h3 mb-0 ml-3">GBM</h3>
                            </Col>
                            <Col className="mt-1">
                                <a><i class="fas fa-home"></i></a>
                                <small className="text-muted ml-3 ">{warehouse ? ("Warehouse: " + warehouse) : "Gestor de bodegas"}</small>
                            </Col>
                        </Row>)
                }
                {
                    NotAccessViewMessage == null ?

                        (
                            loading ?
                                <Spin size="large" spinning={loading} tip={"GBM Gestor de bodegas..."} style={{ color: '', marginLeft: isMobile ? '10%' : '40%', marginTop: isMobile ? '50%' : '' }} >
                                </Spin >
                                :
                                <Container className={isMobile ? "mt-2" : "mt--6"} fluid>

                                    {sapPassword ? //=>If there is password show cards.
                                        <Row>
                                            <Col sm="12">
                                                <CardHeaders
                                                    setFormSelected={setFormSelected}
                                                    formSelected={formSelected}
                                                />
                                            </Col>
                                        </Row>
                                        :
                                        <WmsLogin />
                                    }
                                </Container>



                        )

                        :

                        <NotAccessView text={NotAccessViewMessage} isMobile={isMobile} />
                }


            </UserContext.Provider>
        </>
    )
}

export default Home;