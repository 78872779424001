import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getMasterData = createAction(constants.GET_MASTER_DATA_CR_BIDS, method.get(urls.COSTARICABIDS.getMasterData));

export const getPurchaseOrder = createAction(constants.GET_ALL_PURCHASE_ORDER_CR_BIDS, body => method.post(urls.COSTARICABIDS.getPurchaseOrder, body)());

export const updatePurchaseOrder = createAction(constants.UPDATE_PURCHASE_ORDER_CR_BIDS, body => method.post(urls.COSTARICABIDS.updatePurchaseOrder, body)());

export const getContacts = createAction(constants.GET_CONTACTS_CR_BIDS, body => method.post(urls.COSTARICABIDS.getContacts, body)());

export const deleteFile = createAction(constants.DELETE_FILES_CRBIDS, body => method.post(urls.COSTARICABIDS.deleteFile, body)());

export const deleteAllFiles = createAction(constants.DELETE_ALL_FILES_CRBIDS, body => method.post(urls.COSTARICABIDS.deleteAllFiles, body)());

export const getProducts = createAction(constants.GET_PRODUCTS_CR_BIDS, body => method.post(urls.COSTARICABIDS.getProducts, body)());

export const getFilesByCustomer = createAction(constants.GET_FILES_CR_BIDS_BY_PO, (documentId) => method.get(`${urls.COSTARICABIDS.getFilesByPurchaseOrder}/${documentId}`)());

export const getExcelReport = createAction(constants.GET_EXCEL_REPORT_CR_BIDS, body => method.post(urls.COSTARICABIDS.getExcelReport, body)());

export const insertSalesTeam = createAction(constants.INSERT_SALES_TEAM_CR_BIDS, body => method.post(urls.COSTARICABIDS.deleteSalesTeam, body)());

export const deleteSalesTeam = createAction(constants.DELETE_SALES_TEAM_CR_BIDS, body => method.post(urls.COSTARICABIDS.insertSalesTeam, body)());



