// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

const CardConfigurations = (props) => {
  const { title, subtitle, comments, configurations, onBack } = props;

  return (
    <div className="card-deck flex-column flex-xl-row">
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col sm="12" md="8">
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="form-row align-items-center pt-1">
            <Col>
              <ListGroup className="list my--3" flush>
                {configurations.length ? (
                  configurations.map((row, key) => (
                    <ListGroupItem key={key} className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            alt="Notificación"
                            className="avatar rounded-circle"
                            src={require(`assets/img/theme/attachment.png`)}
                          />
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">{row.name}</h4>
                          <small>
                            {`${row.createdBy} - ${moment(row.createdAt)
                              .utc()
                              .utcOffset(moment().utcOffset())
                              .format("LLL")}`}
                          </small>
                        </div>
                        <Col className="col-auto">
                          <Button
                            color="info"
                            size="sm"
                            href={`${urlBase}/digital-request/download-attachment/${window.btoa(
                              row.path
                            )}/${window.btoa(row.name)}`}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-file-download" />
                            </span>
                            <span className="btn-inner--text">Descargar</span>
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))
                ) : (
                  <div className="pt-4 pb-4">
                    <h5 className="h3 title">
                      <span className="d-block mb-1 text-center">
                        Sin documentos
                      </span>
                      <small className="h3 font-weight-light text-muted">
                        Al requerimiento que se seleccionó, no se le realizo la
                        carga de ningún archivo.
                      </small>
                    </h5>
                  </div>
                )}
              </ListGroup>
            </Col>
          </div>
        </CardBody>
        {comments.length ? (
          <div className="p-2">
            <CardHeader>
              <Row className="align-items-center">
                <Col sm="12" md="8">
                  <h5 className="h3 mb-0">Comentarios</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {comments.map((row, key) => (
                <FormGroup key={key}>
                  <Input
                    disabled
                    id="justify"
                    rows="3"
                    resize="none"
                    type="textarea"
                    maxLength={500}
                    defaultValue={row.comment}
                  />
                </FormGroup>
              ))}
            </CardBody>
          </div>
        ) : null}
        <CardBody>
          <div className="form-row align-items-center mt-3">
            <Col className="m-2" sm="12" md="3">
              <Button
                className="btn-icon"
                color="success"
                onClick={onBack}
                size="sm"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-arrow-left" />
                </span>
                <span className="btn-inner--text">Regresar</span>
              </Button>
            </Col>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

CardConfigurations.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  configurations: PropTypes.array.isRequired,
};

export default CardConfigurations;
