/*!

=========================================================
*Databot DrawerInfo
=========================================================

*Es un tipo "modal" para desplegar más información de cada 
componente del dashboard del databot

=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/


//Librería antd, para desplegar el "modal" de información.
import { Drawer } from 'antd';

//Librería de react.
import React, { useState, useContext, useEffect } from 'react';

//Librería de reactstrap.
import { Card, CardBody, Col, Row, Button } from "reactstrap";

//Import de componente contexto, para establecer variables globales
import { UserContext } from 'components/Databot/SharedComponents/UserContext';

export const DrawerInfoToComponents = () => {

    //#region States Globales
    const {

        showMoreInfoDatabot,
        setShowMoreInfoDatabot,

        drawerHelpComponents,
        setDrawerHelpComponents

    } = useContext(UserContext);
    //#endregion


    const [HTML, setHTML] = useState(
        {
            filters:
            {
                title: "Sección de Filtros",
                html: <div className="mb-4">
                    <div className="justify-content-center row">
                        <h2>Sección de Filtros</h2>
                    </div>


                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            En esta sección puede seleccionar diferentes filtros los cuales van a reflejarse en la sección de gráficos. En cada filtro
                            tiene un modo 'multiselección', esto quiere decir que puede seleccionar una opción o varias opciones en cada campo según sea su consulta.
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            Por ejemplo: En el campo de "Robot", puede seleccionar un robot, o varios robots para medir el ahorro de los mismos.
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            Los filtros tienen una dinámica tipo "cascada", esto quiere decir que si se selecciona una opción (o varias) tomando como ejemplo el filtro de Torre, las opciones del área funcional, departamento, y robot van a ser modificadas y solo aparecerán las que están relacionadas a la torre. Esto aplica sucesivamente de modo que si se selecciona más opciones en el área funcional o en el departamento van a ir modificando las opciones de los "hijos" en forma de "cascada".
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            Existen dos botones importantes:
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            1. Limpiar: Este botón limpia cualquier filtro aplicado con anterioridad, y los gráficos mostrarán la información de todos los robots sin filtros.
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            2. Aplicar Filtro: Después de seleccionar el filtro, debe tocar este botón para que los gráficos reflejen la consulta.
                        </font>
                    </p>


                </div>,
                image: "search.png",
                width: 740

            },
            byMonth:
            {
                title: "Gráfico por mes",
                html:
                    <div className="mb-4">
                        <div className="justify-content-center row">
                            <h2>Gráfico por mes</h2>
                        </div>
                        <p className="mb-3" align="justify">
                            <font face="Arial">
                                Este gráfico refleja diferentes tipos de datos por mes, de forma general o por el filtro seleccionado, tiene diferentes tipos de modos:
                            </font>
                        </p>

                        <p className="mb-3" align="justify">
                            <font face="Arial">
                                1.  Ahorro: Refleja de forma "general" la suma del ahorro de todos los robots , de esta forma se puede visualizar cuanto ahorro refleja por mes.
                            </font>
                        </p>

                        <p className="mb-3" align="justify">
                            <font face="Arial">
                                2. Iteraciones: Refleja de forma "general" la suma de iteraciones (cantidad de veces que se ejecutan) de todos los robots, de esta forma se puede visualizar cuantas iteraciones refleja por mes.

                            </font>
                        </p>
                        <p className="mb-3" align="justify">
                            <font face="Arial">
                                3.  Ahorro líneas: A diferencia del modo ahorro, el modo ahorro líneas proyecta cada uno de los robots con una respectiva línea, de esta forma se puede ver ahorro de cada uno de los robots, de esta forma se puede visualizar cuanto ahorro refleja por mes.

                            </font>
                        </p>
                        <p className="mb-3" align="justify">

                            <font face="Arial">
                                4.  Iteraciones líneas: A diferencia del modo iteraciones, el modo iteraciones líneas proyecta cada uno de los robots con una respectiva línea, de esta forma se puede las iteraciones de cada uno de los robots, de esta forma se puede visualizar cuantas iteraciones refleja por mes cada robot.

                            </font>
                        </p>
                        <div className="justify-content-center row">
                            <h3> Fórmula de Cálculo del Ahorro</h3>
                        </div>

                        <p className="mb-1" align="justify">

                            <img
                                alt="..."
                                className="rounded img-center img-fluid shadow shadow-lg--hover"
                                src={require(`assets/img/databot/savingForm1.jpg`)}
                                style={{ objectFit: "contain", width: "600px", height: "300px" }}
                            />
                        </p>

                        <p className="mb-1" align="justify">

                            <img
                                alt="..."
                                className="rounded img-center img-fluid shadow shadow-lg--hover"
                                src={require(`assets/img/databot/savingForm2.jpg`)}
                                style={{ objectFit: "contain", width: "600px", height: "300px" }}
                            />
                        </p>


                    </div>,
                image: "information2.png",
                width: 740

            },
            byYears:
            {
                title: "Gráfico por año",
                html: <div className="mb-4">
                    <div className="justify-content-center row">
                        <h2>Gráfico por año</h2>
                    </div>


                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            Este gráfico refleja el ahorro de los robots (o por los robots pertenecientes al departamento, al área funcional, a la torre, esto si en la parte superior se aplica el filtro) en los últimos tres años. Es dependendiente de igual manera a la selección que se realice en la sección de filtros en la parte superior.
                        </font>
                    </p>
                    <div className="justify-content-center row">
                        <h3> Fórmula de Cálculo del Ahorro</h3>
                    </div>

                    <p className="mb-1" align="justify">

                        <img
                            alt="..."
                            className="rounded img-center img-fluid shadow shadow-lg--hover"
                            src={require(`assets/img/databot/savingForm1.jpg`)}
                            style={{ objectFit: "contain", width: "600px", height: "300px" }}
                        />
                    </p>

                    <p className="mb-1" align="justify">

                        <img
                            alt="..."
                            className="rounded img-center img-fluid shadow shadow-lg--hover"
                            src={require(`assets/img/databot/savingForm2.jpg`)}
                            style={{ objectFit: "contain", width: "600px", height: "300px" }}
                        />
                    </p>


                </div>,
                image: "information2.png",
                width: 740


            },
            topRobotsTable:
            {
                title: "Tabla Top de Robots",
                html: <div className="mb-4">
                    <div className="justify-content-center row">
                        <h2>Tabla de Top de Robots</h2>
                    </div>


                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            Esta tabla fue diseñada para mostrar todos los robots acompañado del ahorro que ha realizado en el año actual (o el año seleccionado en el filtro superior, son dependientes), además están ordenados de forma descendente para mostrar los robots con más ahorro y los que menos ha ahorrado.
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            También tiene un dropdown seguido del título de la tabla, este tiene la utilidad de agregar más columnas útiles a la tabla. Por ejemplo: Agregar la tarifa por hora (la cual se utiliza para el cálculo de ahorro), o los minutos de ahorro (cuantos minutos ahorra por proceso a empleados de GBM), o la descripción amplia del robot, o su respectivo usuario funcional.
                        </font>
                        <font face="Arial">

                        </font>
                    </p>

                    <div className="justify-content-center row">
                        <h3> Fórmula de Cálculo del Ahorro</h3>
                    </div>

                    <p className="mb-1" align="justify">

                        <img
                            alt="..."
                            className="rounded img-center img-fluid shadow shadow-lg--hover"
                            src={require(`assets/img/databot/savingForm1.jpg`)}
                            style={{ objectFit: "contain", width: "600px", height: "300px" }}
                        />
                    </p>

                    <p className="mb-1" align="justify">

                        <img
                            alt="..."
                            className="rounded img-center img-fluid shadow shadow-lg--hover"
                            src={require(`assets/img/databot/savingForm2.jpg`)}
                            style={{ objectFit: "contain", width: "600px", height: "300px" }}
                        />
                    </p>

                </div>,
                image: "information.png",
                width: 740


            },
            detailsTable:
            {
                title: "Tabla de log y detalle",
                html: <div className="mb-4">
                    <div className="justify-content-center row">
                        <h2>Tabla de Logs y Detalle</h2>
                    </div>



                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            Esta tabla tiene como fin mostrar el registro de cada proceso que realiza cada robot, esta información es valiosa ya que apartir de ella se calculan los gráficos y ahorro del presente dashboard, posee dos modos:
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            1. Detalle: Cada fila de la tabla es un registro de cuando un robot termina de realizar un proceso, junto a datos relevantes como el de comentarios que muestra infomación útil recabada durante el proceso.
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            2. Log: Cada fila de la tabla es un log de cada acción y movimiento realizado en un proceso, es decir es mucho más detallado.
                        </font>
                    </p>
                    <p className="mb-3" align="justify">
                        <font face="Arial">
                            La diferencia entre los dos modos es que detalle muestra cuando se finalizó un proceso, y el log detalla cada acción realizada en el proceso. Es importante destacar que la tabla que se utiliza para calcular las estadísticas es la del log, por tanto todo coincide por ejemplo en la cantidad de registros de esta tabla con el gráfico de iteraciones de la parte superior.
                        </font>
                    </p>
                </div>,
                image: "information.png",
                width: 740

            }
        }
    )






    return (
        <>
            <Drawer
                title={HTML[drawerHelpComponents.type] && HTML[drawerHelpComponents.type].title}
                width={HTML[drawerHelpComponents.type] && HTML[drawerHelpComponents.type].width}
                onClose={() => setDrawerHelpComponents({ ...drawerHelpComponents, visible: false, type: '' })}
                visible={drawerHelpComponents.visible}
                bodyStyle={{ paddingBottom: 80 }}
            >


                <Card className="card-profile bg-secondary mt-5">
                    <Row className="justify-content-center">
                        <Col className="order-lg-2" lg="3">
                            <div className="card-profile-image">
                                <img
                                    alt="..."
                                    className="rounded-circle border-secondary"
                                    src={require(`assets/img/databot/${HTML[drawerHelpComponents.type] != undefined ? HTML[drawerHelpComponents.type].image : 'information.png'}`)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <CardBody
                        className="pt-7 px-5"
                        style={{ backgroundColor: "#e8f5fa" }}
                    >
                        {HTML[drawerHelpComponents.type] && HTML[drawerHelpComponents.type].html}
                    </CardBody>
                </Card>

            </Drawer>
        </>
    )
}

