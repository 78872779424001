import moment from "moment";
import "moment/locale/es";
moment.locale("es");
export const fieldTypesSelector = (state) => {
  if (state.fieldTypes) {
  }
  return state.fieldTypes;
};

export const DocumentAdministrationSelector = (state) => {
  if (state.DocumentAdministration) {
  }
  return state.DocumentAdministration;
};

export const CategoriesSelector = (state) => {
  if (state.CategoriesList) {
  }
  return state.CategoriesList;
};

export const BusinessUnitsSelector = (state) => {
  if (state.BusinessUnitsList) {
  }
  return state.BusinessUnitsList;
};

export const CategoryBuTemplatesSelector = (state) => {
  if (state.CategoryBuTemplates) {
  }
  return state.CategoryBuTemplates;
};

export const DocumentIDTemplatesSelector = (state) => {
  if (state.DocumentTemplateList) {
  }
  return state.DocumentTemplateList;
};

export const CategoryDocumentsSelector = (state) => {
  return state.CategoryDocuments;
};

export const RequestsSelector = (state) => {
  return state.RequestList;
};

export const FinanceNotificationsSelector = (state) => {
  console.log(state);
  return state.FinanceNotificationsList;
};

export const RequestSelector = (state) => {
  return state.FinanceRequestData;
};

export const PositionsSelector = (state) => {
  return state.PositionsList;
};

export const TemplateQuerySelector = (state) => {
  return state.TemplateQueryList;
};

export const RequestsMySelector = (state) => {
  const { getMyRequest } = state;
  if (Object.keys(getMyRequest).length) {
    return getMyRequest;
  }
};

export const getProfiles = (state) => {
  const { getAllProfiles } = state;
  if (Object.keys(getAllProfiles).length) {
    return getAllProfiles;
  }
};
export const getStates = (state) => {
  const { getAllStates } = state;
  if (Object.keys(getAllStates).length) {
    return getAllStates;
  }
  return [];
};
