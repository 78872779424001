import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// react library
import React, { Component } from "react";
import moment from "moment";

import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Table,
} from "reactstrap";

import ReactDatetime from "react-datetime";

import { getRequests, getCategories } from "actions/financialFlows.jsx";

import {
  RequestsSelector,
  CategoriesSelector,
} from "selectors/FinancialFlows.jsx";
import { Spin } from "antd";

import _ from "lodash";

class FincanceRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        requester: "",
        date: undefined,
        category: "Todos",
        status: "Todos",
        id: "",
        approver: "",
        createRequestLoading: false
      },
      states: [],
      categories: [],
    };
  }
  componentWillMount() {
    this.setState({ createRequestLoading: true });
    this.props.getRequests().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({ createRequestLoading: false });

      } else {
        const { RequestList } = this.props;
        let statesResult = _.uniqBy(
          RequestList.map((value) => {
            return value.statusDescription;
          })
        );
        let categoriesResult = _.uniqBy(
          RequestList.map((value) => {
            return value.CategoryName;
          })
        );
        this.setState({ states: statesResult, categories: categoriesResult, createRequestLoading: false });
      }
    });
    this.props.getCategories().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
  }

  handleOnSelect = () => { };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnCLick = () => { };

  renderTableData() {
    return this.props.executedTotalsData.total.map((row) => {
      const { key, doc_count } = row;
      return (
        <tr key={key}>
          <td>{key}</td>
          <td>{doc_count}</td>
        </tr>
      );
    });
  }

  handleSelectRequest = (item) => {
    this.props.history.push(`/admin/finance/request/${item}`);
  };

  handleFilters(value) {
    let { filter } = this.state;
    switch (value.target.id) {
      case "user":
        filter.requester = value.target.value;
        this.setState({ filter });
        break;
      case "category":
        filter.category = value.target.value;
        this.setState({ filter });
        break;
      case "status":
        filter.status = value.target.value;
        this.setState({ filter });
        break;
      case "id":
        filter.id = value.target.value;
        this.setState({ filter });
        break;
      case "approver":
        filter.approver = value.target.value;
        this.setState({ filter });
        break;
      default:
        break;
    }
  }

  handleDateFilter(date) {
    let { filter } = this.state;
    if (moment(date).isValid()) {
      filter.date = moment(date);
      this.setState({ filter });
    } else {
      filter.date = null;
      this.setState({ filter });
    }
  }

  render() {
    const { RequestList } = this.props;
    let { filter, states, categories, createRequestLoading } = this.state;
    let filtered = [];

    if (RequestList.length) {
      filtered = RequestList;
      if (
        filter.requester !== null &&
        filter.requester !== undefined &&
        filter.requester !== ""
      ) {
        const regexp = new RegExp(filter.requester, "i");
        filtered = filtered.filter((x) => {
          return regexp.test(x.requester);
        });
      }
      if (
        filter.category !== null &&
        filter.category !== undefined &&
        filter.category !== "" &&
        filter.category !== "Todos"
      ) {
        const regexp = new RegExp(filter.category, "i");
        filtered = filtered.filter((x) => {
          return regexp.test(x.CategoryName);
        });
      }
      if (
        filter.status !== null &&
        filter.status !== undefined &&
        filter.status !== "" &&
        filter.status !== "Todos"
      ) {
        const regexp = new RegExp(filter.status, "i");
        filtered = filtered.filter((x) => {
          return regexp.test(x.statusDescription);
        });
      }
      if (filter.date) {
        filtered = filtered.filter((value, key) => {
          const createdDate = moment(value.createdAt).format("DD-MM-YYYY");
          const filterDate = moment(filter.date).format("DD-MM-YYYY");
          if (createdDate === filterDate) return value;
        });
      }
      if (filter.id !== null && filter.id !== undefined && filter.id !== "") {
        const regexp = new RegExp(filter.id, "i");
        filtered = filtered.filter((x) => {
          return regexp.test(x.id);
        });
      }
      if (
        filter.approver !== null &&
        filter.approver !== undefined &&
        filter.approver !== ""
      ) {
        const regexp = new RegExp(filter.approver, "i");
        filtered = filtered.filter((x) => {
          return regexp.test(x.nextApprover.username);
        });
      }
    }
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Todas las solicitudes" parentName="Finance Flows " />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={createRequestLoading}>
            <Card className="mb-12">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h5 className="h3 mb-0">Filtros</h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="user">
                        Solicitante
                      </label>
                      <Input
                        id="user"
                        placeholder="Nombre de Usuario"
                        type="text"
                        defaultValue={filter.requester}
                        onChange={(item) => this.handleFilters(item)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="date">
                        Fecha de Solicitud
                      </label>
                      <ReactDatetime
                        id="date"
                        inputProps={{
                          placeholder: "Seleccione una fecha",
                        }}
                        timeFormat={false}
                        defaultValue={filter.date}
                        onChange={(item) => this.handleDateFilter(item)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="user">
                        Aprobador en linea
                      </label>
                      <Input
                        id="approver"
                        placeholder="Nombre de Usuario"
                        type="text"
                        defaultValue={filter.approver}
                        onChange={(item) => this.handleFilters(item)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="status">
                        Estado Actual
                      </label>
                      <Input
                        id="status"
                        type="select"
                        onChange={(value) => this.handleFilters(value)}
                        value={filter.status}
                      >
                        <option>Todos</option>
                        {states.map((value, key) => {
                          return <option key={"filter-" + key}>{value}</option>;
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="user">
                        Identificador
                      </label>
                      <Input
                        id="id"
                        placeholder="#"
                        type="text"
                        defaultValue={filter.requester}
                        onChange={(item) => this.handleFilters(item)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="category">
                        Categoria
                      </label>
                      <Input
                        id="category"
                        type="select"
                        onChange={(value) => this.handleFilters(value)}
                        value={filter.category}
                      >
                        <option>Todos</option>
                        {categories.map((value, key) => {
                          return <option key={"category-" + key}>{value}</option>;
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <div className="col">
                <Card className="bg-default shadow">
                  <CardHeader className="bg-transparent border-0">
                    <h3 className="text-white mb-0">Resultados</h3>
                  </CardHeader>
                  <div className="table-responsive" style={{ maxHeight: "80vh" }}>
                    <Table
                      className="align-items-center table-dark table-flush"
                      responsive
                    >
                      <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                        <tr>
                          <th>Identificador</th>
                          <th>Solicitante</th>
                          <th>Fecha</th>
                          <th>Tipo de Documento</th>
                          <th>Aprobador en linea</th>
                          <th>Estado</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {filtered.length > 0 ? (
                          filtered.map((request) => {
                            return (
                              <tr key={request.id}>
                                <td>#{request.id}</td>
                                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>{request.requester}</td>
                                <td>
                                  {moment(request.createdAt).format("DD-MM-YYYY")}
                                </td>
                                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>{request.docName}</td>
                                <td>{request.nextApprover.username}</td>
                                <td>{request.statusDescription}</td>

                                <td className="text-right">
                                  <Button
                                    onClick={() =>
                                      this.handleSelectRequest(request.id)
                                    }
                                  >
                                    {" "}
                                    Ver Detalles
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="justify-content-md-center">
                              Resultados no encontrados
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </div>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

FincanceRequests.defaultProps = {
  RequestList: [],
  CategoriesList: [],
};

const mapStateToProps = (state) => ({
  RequestList: RequestsSelector(state),
  CategoriesList: CategoriesSelector(state),
});

export default withRouter(
  connect(mapStateToProps, { getRequests, getCategories })(FincanceRequests)
);
