import React from 'react';
// react-redux library for conect redux
import { connect } from 'react-redux';
// react library for routing
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
// routes
import { NewPositionRoutes } from 'routes/routes.jsx';
// core selectors
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';
import { getVacantRequestByUser } from 'selectors/newPosition.jsx';

/**
 * Componente funcional para el manejo de las rutas disponibles en el modulo de nueva posicion
 * @param {*} props
 * @returns
 */
const Index = props => {

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/new-position") {
        if (prop.path === "/requests/support-position" || prop.path === "/requests/work-flow") {
          if (props.teams.some((e) => e === "New Position Admin")) {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          } else {
            return (
              <Redirect
                key={key}
                from={prop.layout + prop.path}
                to="/admin/new-position/requests"
              />
            );
          }
        } else if (prop.path === "/my-requests/update-vacant/:id" && !props.requestVacantUpdate.length) {
          return (
            <Redirect
              key={key}
              from={prop.layout + prop.path}
              to="/admin/new-position/my-requests"
            />
          );
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      }
      return null;
    });
  };

  return (
    <>
      <Switch>
        {
          getRoutes(NewPositionRoutes)
        }
        <Redirect from="/admin/new-position" to="/admin/new-position/requests" />
      </Switch>
      <p className="lead"></p>
    </>
  );
}

/**
 * Funcion para mapear el state de la aplicacion a los props del componente
 * recibe de parametro el estado de la aplicacion
* @param {*} state
  */
const mapStateToProps = (state) => ({
  teams: getTeamsUserAccess(),
  requestVacantUpdate: getVacantRequestByUser(state),
});

export default withRouter(connect(mapStateToProps, null)(Index));