/*
=========================================================
*Notifications - Extra Hours report pending to approve
=========================================================

*Description.
This component creates a section for the pending extra hours reports, dividing them by preapprover or approver

=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import SolicitudCard from "./CardRequest.jsx";

function SolicitudSection({ title, cards, onSelect, idRol }) {
  const image = require("../../../assets/img/hoursReport/pendiente.png");
  return (
    <div style={{ marginBottom: "20px" }}>
      <h2 style={titleStyle}>{title}</h2>
      <Row style={{ marginBottom: "20px" }}>
        {cards.map((card, index) => (
          <Col key={index} sm="6" md="4">
            <SolicitudCard
              title={"Pendiente de aprobación"}
              report={card}
              image={image}
              onSelect={onSelect}
              idRol={idRol}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

SolicitudSection.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
};

const titleStyle = {
  padding: "10px",
  backgroundColor: "#FFD700",
  color: "#fff",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
  border: "2px solid #FFA500",
  borderRadius: "10px",
  marginBottom: "2rem",
};

export default SolicitudSection;
