import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const conflictInterestsInfo = handleActions(
  {
    [constants.GET_CONFLICT_INTERESTS_PENDING_INFO]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const allConflictInterestsInfo = handleActions(
  {
    [constants.GET_ALL_CONFLICT_INTERESTS_INFO]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const conflictInterests = handleActions(
  {
    [constants.GET_CONFLICT_INTERESTS_INFO]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);
