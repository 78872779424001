/*!

=========================================================
*MasterData - useConvertToIban
=========================================================

* Custom Hook diseñado para convertir una cuenta corriente 
*de Costa Rica a IBAN.
*Se le envía por parámetro el numero de cuenta corriente,
*y devuelve una respuesta con un message, result(true o false),
*y el ibanNumber.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import { useState } from 'react'

const convertToIban = (accountNumber) => {

    //const convertToIban = (accountNumber) => {
    let result = {

        response: "",
        typeToast: "",
        ibanNumber: 0,
        result: false,
    }

    //Limpiar caracteres diferentes.
    accountNumber = myTrim(accountNumber);
    //debugger;


    let parameters = new Country("Costa Rica", "CR", "0  0 0", "0  17   0 ");

    let response = null;

    if (20 != accountNumber.length) { //No es una cuenta IBAN


        if (ExtOk(parameters, accountNumber)) {
            //No tiene los 17 caracteres
            response = "La cuenta ingresada tiene " + accountNumber.length + " caracteres, deben ser " + parameters.acc_lng + " dígitos."
            // console.log(response)

            result = {
                response: response,
                typeToast: "warning",
                ibanNumber: 0,
                result: false, //Salió mal
            }

        }
        else if (!sinpeOK(accountNumber)) {
            //La cuenta sinpe tiene un error o no es valida
            response = "La cuenta SINPE " + accountNumber + " no es válida."
            // console.log(response)

            result = {
                response: response,
                typeToast: "warning",
                ibanNumber: 0,
                result: false, //Salió mal
            }
        }
        else {
            //si la cuenta SINPE está bien digitada

            //convertir la cuenta a IBAN con los parametros construídos anteriormente
            var ibanNumber = ToIBAN(parameters, accountNumber);
            //le quita los 2 primeros caracteres a la cuenta IBAN calculada anteriormente
            ibanNumber = ibanNumber.substring(2, ibanNumber.length);
            console.log(ibanNumber)
            response = "Convertida con éxito!";
            // console.log(response);

            result = {
                response: response,
                typeToast: "success",
                ibanNumber: ibanNumber,
                result: true, //Salió bien
            }
        }
    } else { //Es una cuent IBAN

            response = "Ya es una cuenta IBAN!";
            // console.log(response);

            result = {
                response: response,
                typeToast: "success",
                ibanNumber: accountNumber,
                result: true, //Salió bien
            }
    }







    //#region Funciones para convertir a IBAN, este algoritmo fue encontrado en internet, no es un desarrollo propio de GBM.



    function myTrim(_0xc1efx2) {
        return _0xc1efx2.replace(/^\s+|\s+$/gm, '')
    }

    function Country(_0xc1efx8, _0xc1efx9, _0xc1efxa, num_cuenta) {
        this['name'] = _0xc1efx8;
        this['code'] = _0xc1efx9;
        this['bank'] = cDecode(_0xc1efxa);
        this['acc'] = cDecode(num_cuenta);
        this['bank_lng'] = CountryLength(this['bank']);
        this['acc_lng'] = CountryLength(this['acc']);
        this['total_lng'] = 4 + this['bank_lng'] + this['acc_lng']
    }


    function cDecode(_0xc1efx8) {
        var _0xc1efx9 = new Array;
        var _0xc1efxa = _0xc1efx8['split'](' ');
        for (var num_cuenta = 0; num_cuenta < _0xc1efxa['length']; ++num_cuenta) {
            var parametros = _0xc1efxa[num_cuenta];
            if (parametros != '') {
                var _0xc1efxc = parametros['charAt'](parametros['length'] - 1);
                if (_0xc1efxc == 'a' || _0xc1efxc == 'n') {
                    parametros = parametros['substring'](0, parametros['length'] - 1)
                } else {
                    _0xc1efxc = 'c'
                };
                var _0xc1efx14 = parseInt(parametros);
                _0xc1efx9[_0xc1efx9['length']] = new Array(_0xc1efx14, _0xc1efxc)
            }
        };
        return _0xc1efx9
    }

    function CountryLength(_0xc1efx8) {
        var _0xc1efx16 = 0;
        for (var _0xc1efxa = 0; _0xc1efxa < _0xc1efx8['length']; ++_0xc1efxa) {
            _0xc1efx16 += _0xc1efx8[_0xc1efxa][0]
        };
        return _0xc1efx16
    }

    function ExtOk(_0xc1efx8, _0xc1efx9) {
        return _0xc1efx9['length'] < 1 || _0xc1efx9['length'] > _0xc1efx8['acc_lng'] || _0xc1efx9['length'] < _0xc1efx8['acc_lng']
    }

    function checkDigit(num_cuenta) {
        var _0xc1efxc = null;
        var parametros = 0;
        for (let i = 0; i < num_cuenta['length']; i++) {
            parametros += parseInt(num_cuenta['charAt'](i)) * parseInt('91234567891234567'['charAt'](i + 1))
        };
        _0xc1efxc = parametros % 11;
        if (_0xc1efxc == 10) {
            _0xc1efxc = 1
        };
        return _0xc1efxc
    }

    function validateDigits(_0xc1efx8) {
        var _0xc1efx9 = '([1-9]d*|0)';
        var _0xc1efxa = new RegExp(_0xc1efx9);
        return _0xc1efx8.toString()['match'](_0xc1efxa)
    }

    function validateCheckDigit(_0xc1efx8) {
        var _0xc1efx9;
        var _0xc1efxa;
        if (validateDigits(_0xc1efx8)) {
            _0xc1efx9 = _0xc1efx8['substring'](_0xc1efx8['length'] - 1, _0xc1efx8['length']);
            _0xc1efxa = checkDigit(_0xc1efx8['substring'](0, _0xc1efx8['length'] - 1));
            return parseInt(_0xc1efxa) == parseInt(_0xc1efx9)
        };
        return false
    }

    function sinpeOK(_0xc1efx2) {
        if (_0xc1efx2.toString()['length'] == 17) {
            if (validateCheckDigit(_0xc1efx2.toString())) {
                return true
            }
        };
        return false
    }

    function ToIBAN(_0xc1efx8, _0xc1efx9) {
        var _0xc1efx11 = _0xc1efx8['acc_lng'] + 1;
        var _0xc1efxa = filler0(_0xc1efx9, _0xc1efx11);
        var num_cuenta = ChecksumIBAN(_0xc1efx8['code'] + '00' + _0xc1efxa);
        return _0xc1efx8['code'] + num_cuenta + _0xc1efxa
    }

    function ChecksumIBAN(_0xc1efx8) {
        var _0xc1efx9 = _0xc1efx8['substring'](0, 2);
        var _0xc1efxa = _0xc1efx8['substring'](2, 4);
        var num_cuenta = _0xc1efx8['substring'](4);
        var parametros = '';
        for (var _0xc1efxc = 0; _0xc1efxc < num_cuenta['length']; ++_0xc1efxc) {
            var _0xc1efx14 = num_cuenta['charAt'](_0xc1efxc)['toUpperCase']();
            if ('0' <= _0xc1efx14 && _0xc1efx14 <= '9') {
                parametros += _0xc1efx14
            } else {
                parametros += lt2dt(_0xc1efx14)
            }
        };
        for (var _0xc1efxc = 0; _0xc1efxc < _0xc1efx9['length']; ++_0xc1efxc) {
            var _0xc1efx14 = _0xc1efx9['charAt'](_0xc1efxc);
            parametros += lt2dt(_0xc1efx14)
        };
        parametros += _0xc1efxa;
        _0xc1efxa = 98 - magic97(parametros);
        return filler0('' + _0xc1efxa, 2)
    }

    function filler0(_0xc1efx2, _0xc1efx16) {
        while (_0xc1efx2['length'] < _0xc1efx16) {
            _0xc1efx2 = '0' + _0xc1efx2
        };
        return _0xc1efx2
    }

    function lt2dt(_0xc1efx8) {
        var _0xc1efx18 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var _0xc1efx19 = 0; _0xc1efx19 < _0xc1efx18['length']; ++_0xc1efx19) {
            if (_0xc1efx8 == _0xc1efx18['charAt'](_0xc1efx19)) {
                break
            }
        };
        return _0xc1efx19 + 10
    }

    function magic97(_0xc1efx8) {
        var _0xc1efx9 = 0;
        for (var _0xc1efxa = 0; _0xc1efxa < _0xc1efx8['length']; ++_0xc1efxa) {
            _0xc1efx9 = (_0xc1efx9 * 10 + parseInt(_0xc1efx8['charAt'](_0xc1efxa))) % 97
        };
        return _0xc1efx9
    }

    //#endregion

    return result;




};

export default convertToIban;