// javascript plugin that creates nice dropzones for files
import { Spin } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";

const UsersAssignModal = (props) => {
  const { title, data, showModal, loading, toggleModal, onSubmit } = props;

  const validate = (values) => {
    const errors = {};
    if (!values.userType || values.userType === "")
      errors.userType = "Debes selecionar el tipo de usuario";
    if (values.userType === "userFM" || values.userType === "userPM")
      if (!values.user || values.user === "0")
        errors.user = "Debes seleccionar un usuario";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({
                handleSubmit,
                values,
                submitting,
                validating,
                valid,
              }) => (
                <Spin size="large" spinning={loading}>
                  <FormRS role="form">
                    <Row className="d-flex align-items-center justify-content-center">
                      <Col className="d-flex align-items-center justify-content-center">
                        <label>Tipo de Usuario</label>
                      </Col>
                    </Row>
                    <FormGroup className="d-flex align-items-center justify-content-center">
                      <Row>
                        <div className="custom-control custom-radio custom-control-inline">
                          <Field
                            name="userType"
                            component="input"
                            type="radio"
                            value="userFM"
                            className="custom-control-input"
                            id="userFM"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="userFM"
                          >
                            FM Responsable
                          </label>
                        </div>
                        <div className="mb-3 custom-control custom-radio custom-control-inline">
                          <Field
                            name="userType"
                            component="input"
                            type="radio"
                            value="userPM"
                            className="custom-control-input"
                            id="userPM"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="userPM"
                          >
                            PM/Coord Instalación
                          </label>
                        </div>
                      </Row>
                    </FormGroup>
                    {values.userType === "userFM" ? (
                      <FormGroup>
                        <Label for="user">Colaborador</Label>
                        <Field name="user">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="select"
                                valid={
                                  !meta.error && meta.modified && meta.touched
                                }
                                invalid={meta.error && meta.touched}
                              >
                                <option key="0" value="0">
                                  Seleccione un colaborador
                                </option>
                                {data.usersFM.map((row, key) => {
                                  return (
                                    <option key={key} value={row.id_User}>
                                      {row.name}
                                    </option>
                                  );
                                })}
                              </Input>
                              {meta.error && meta.touched && (
                                <span className="invalid-feedback">
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    ) : null}
                    {values.userType === "userPM" ? (
                      <FormGroup>
                        <Label for="user">Colaborador</Label>
                        <Field name="user">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="select"
                                valid={
                                  !meta.error && meta.modified && meta.touched
                                }
                                invalid={meta.error && meta.touched}
                              >
                                <option key="0" value="0">
                                  Seleccione un colaborador
                                </option>
                                {data.usersPM.map((row, key) => {
                                  return (
                                    <option key={key} value={row.id_User}>
                                      {row.name}
                                    </option>
                                  );
                                })}
                              </Input>
                              {meta.error && meta.touched && (
                                <span className="invalid-feedback">
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    ) : null}
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Crear Asignación
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

UsersAssignModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default UsersAssignModal;
