// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Row, Table } from "reactstrap";

const MasterDataLogs = props => {

  const {
    rows,
    cols,
    title,
    subtitle,
    onBack,
  } = props;

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col xs="12" md='10'>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
          <Col xs="12" md='2' className="p-1 d-flex justify-content-end">
            <Button
              block
              color="danger"
              size="sm"
              onClick={onBack}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Salir</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {
              cols.map((row, key) => (<th key={key}>{row.name}</th>))
            }
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.map((row, key) => (
              <tr key={key}>
                {
                  cols.map((col, key) => (
                    col.key === "createdAt" ?
                      <th key={key}>
                        {
                          moment(row[col.key])
                            .utc()
                            .utcOffset(moment().utcOffset())
                            .format("DD/MM/YYYY H:mm A")
                        }
                      </th>
                      :
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                        {row[col.key]}
                      </th>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Card>
  );
};

MasterDataLogs.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MasterDataLogs;
