// actions get resourses
import { findNodes, findResourses } from "actions/planningMRS.jsx";
// core components
import DateRanges from "components/Shared/DateRanges/DateRanges.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import NotFoundDB from "components/Shared/NotFoundDB/NotFoundDB";
import Table from "components/Shared/Table/Table.jsx";
// moment library for format
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react component for creating dynamic tables
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Col, Container, Progress, Row } from "reactstrap";
// selector get nodes
import { getNodes, getTowerOptions } from "selectors/planningMRS.jsx";
// data table
import { dataNodes } from "variables/general.jsx";

/**
 * Clase para visualizar los componentes que
 * se utilizaran en el modulo de Planning MRS
 * @class Nodes
 * @extends {Component}
 */
class Nodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      alert: null,
      page: 1,
      sizePerPage: 5,
      loading: false,
      loading2: false
    };
  }

  componentWillMount() {
    if (!this.props.nodes.length) {
      this.setState({
        loading2: true,
      });
      this.props.findNodes().then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
        }
        this.setState({
          loading2: false,
        });
      });
    }
  }

  /**
   * Funcion para seleccionar elementos de una tabla, verifica si se ha seleccionado el limite
   * si no se ha superado se agrega a la lista de elementos seleccionados, o lo elimina si lo
   * que realiza es la accion de deseleccionar
   * @memberof Nodes
   */
  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect && selected.length >= 4) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="No se pueden seleccionar mas de 4 nodos"
            onConfirm={() => this.setState({ alert: null })}
            onCancel={() => this.setState({ alert: null })}
            confirmBtnBsStyle="success"
            btnSize=""
          />
        )
      });
      return false;
    } else if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  /**
   * Funcion para cuando se selecciona una fecha ya sea de inicio o de fin, la agrega a la variable
   * respectiva en el estado
   * @memberof Nodes
   */
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else {
      this.setState({
        [who]: date
      });
    }
  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = date => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  /**
   * Funcion para renderizar una notificacion parametrizada
   * @memberof Nodes
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion que se realiza cuando se presiona el boton de buscar los recursos de los nodos seleccionados
   *
   * @memberof Nodes
   */
  handleOnCLick = () => {
    const { startDate, endDate, selected } = this.state;
    this.setState({
      loading: true
    });
    const data = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      nodes: selected
    }
    this.props.findResourses(data).then((res) => {
      if (res.payload.isAxiosError) {
        this.setState({
          loading: false
        });
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        this.setState({
          loading: false
        });
        this.props.history.push(`${this.props.match.url}/resourses`);
      }
    });
  };

  handleOnReload = () => {
    this.setState({
      loading2: true,
    });
    this.props.findNodes().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading2: false,
      });
    });
  }

  render() {
    const {
      selected,
      alert,
      startDate,
      endDate,
      page,
      sizePerPage,
      loading,
      loading2
    } = this.state;

    const { nodes, towerOptions } = this.props;

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      selected: selected,
      style: { backgroundColor: "#c8e6c9" },
      onSelect: this.handleOnSelect,
      selectColumnPosition: "left"
    };

    const defaultSorted = [
      {
        dataField: "ORG_ID",
        order: "asc"
      }
    ];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name="Lista de Nodos" parentName="Planning MRS" />
        <div className="header pb-6">
          <Container className="mt--6" fluid>
            <Row>
              <Col xs={12} sm={12}>
                <DateRanges
                  title="Fechas de búsqueda"
                  subtitle="Ingrese un rango de fechas para la búsqueda."
                  startDate={startDate}
                  endDate={endDate}
                  reactDatetimeChange={this.handleReactDatetimeChange}
                  classNameReactDatetimeDays={this.getClassNameReactDatetimeDays}
                />
                {
                  loading2 ?
                    <Progress animated max="100" value="100" color="info" />
                    :
                    (nodes.length ?
                      <Table
                        title="Nodos"
                        subtitle="Seleccione los nodos de los cuales desea ver los recursos."
                        columns={[
                          {
                            dataField: "name",
                            text: "Nodo",
                            sort: true
                          },
                          {
                            dataField: "ORG_ID",
                            text: "Torre",
                            formatter: cell => towerOptions[cell],
                            filter: selectFilter({
                              options: towerOptions,
                              placeholder: "Seleccione una torre",
                              style: {
                                height: 'calc(1.8125rem + 2px)',
                                padding: '0.25rem 0.5rem',
                                fontSize: '0.875rem',
                                lineHeight: 1.5,
                                borderRadius: '0.25rem',
                              }
                            })
                          },
                        ]}
                        data={nodes}
                        selectRow={selectRow}
                        filterFactory={filterFactory}
                        alert={alert}
                        sizePerPage={sizePerPage}
                        page={page}
                        totalSize={dataNodes.length}
                        defaultSorted={defaultSorted}
                      /> :
                      <NotFoundDB
                        title="Sin resultados"
                        body="No se encontraron nodos en la base de datos."
                      />
                    )
                }
              </Col>
            </Row>
            <Row>
              {
                loading &&
                <Col xs={12} sm={12}>
                  <Progress className="lead" animated max="100" value="100" color="info" />
                </Col>
              }
              <Col xs={12} sm={3}>
                {
                  nodes.length ?
                    <Button
                      className="btn-icon"
                      block
                      disabled={
                        !selected.length > 0 || (startDate === undefined || endDate === undefined) ? true : false || loading
                      }
                      color="info"
                      type="button"
                      onClick={this.handleOnCLick}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-search" />
                      </span>
                      <span className="btn-inner--text">Buscar</span>
                    </Button>
                    :
                    <Button
                      className="btn-icon"
                      disabled={loading2}
                      block
                      color="info"
                      type="button"
                      onClick={this.handleOnReload}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-redo-alt" />
                      </span>
                      <span className="btn-inner--text">Volver a intentar</span>
                    </Button>
                }
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

Nodes.defaultProps = {
  nodes: [],
  towerOptions: {}
};

Nodes.propTypes = {
  nodes: PropTypes.array.isRequired,
  towerOptions: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  nodes: getNodes(state),
  towerOptions: getTowerOptions(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    { findResourses, findNodes }
  )(Nodes)
);
