import React, { useState, useEffect } from 'react';
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { Container } from "reactstrap";
import AgreementMainTable from 'components/CostaRicaBids/AgreementCRMainTable';
import AgreementCREditModal from 'components/CostaRicaBids/AgreementCREditModal';
//Dispatch
import { useDispatch } from 'react-redux';
//Actions
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Spin } from 'antd';
import { getMasterData, getPurchaseOrder, getContacts, getProducts, getFilesByCustomer, getExcelReport } from 'actions/costaRicaBids';
import { getTeamsUserAccess, getUserProtected } from 'selectors/adminLayout.jsx';
import ModalParticipate from 'components/CostaRicaBids/modalParticipate';
import ModalNoParticipate from 'components/CostaRicaBids/ModalNoParticipate';

const AgreementCR = () => {
    const token = getUserProtected().EMAIL.split("@")[0];
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const [refreshInput, setRefreshInput] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showNoParticipation, setShowNoParticipation] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [loading, setLoad] = useState(false);
    const [rowsModal, setRowsModal] = useState(
        {
            colums: [],
            rows: [],
            subtotal: 0,
            filters: [],
            files: [],
            products: [],
            salesTeam: [],
            evaluations: [],
            excel: []
        }
    );
    const [info, setInfo] = useState();
    const { filters } = rowsModal

    const [options, setOptions] = useState({
        gbmStatus: [{ label: "", value: "" }],
        noParticipationReason: [{ label: "", value: "" }],
        oppType: [{ label: "", value: "" }],
        processType: [{ label: "", value: "" }],
        salesType: [{ label: "", value: "" }],
        status: [{ label: "", value: "" }],
        productLine: [{ label: "", value: "" }],
        valueTeam: [{ label: "", value: "" }],
        customers: [{ label: "", value: "" }],
        employee: [{ label: "", value: "" }],
        newOportunity: [{ label: "Si", value: "Si" }, { label: "No", value: "No" }],
        contacts: [{ label: "", value: "" }],
        participation: [{ label: "SI", value: "SI" }, { label: "NO", value: "NO" }],
        workPoster: [{ label: "SI", value: "SI" }, { label: "NO", value: "NO" }]
    });
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });


    //#region Roles
    const [isDisplay, setIsDisplay] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [role, setRole] = useState("");
    useEffect(() => {
        const teams = getTeamsUserAccess();
        if (teams.some((row) => row.indexOf("CostaRicaBids Display") !== -1)) {
            setIsDisplay(true)
        }
        if (teams.some((row) => row.indexOf("CostaRicaBids Admin") !== -1)) {
            setIsAdmin(true)
        }
    }, []);
    //#endregion

    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
        }
        getRowsData();
    }, [])

    const handleRowInfo = async () => {
        let role = "";
        const teams = getTeamsUserAccess();
        if (teams.some((row) => row.indexOf("CostaRicaBids GBM Direct/Premium Account") !== -1)) {
            setRole("CostaRicaBids GBM Direct/Premium Account")
            role = "CostaRicaBids GBM Direct/Premium Account";

        }
        if (teams.some((row) => row.indexOf("CostaRicaBids VT TELCO") !== -1)) {
            setRole("VT TELCO")
            role = "VT TELCO";
        }
        if (teams.some((row) => row.indexOf("CostaRicaBids VT Bnkng & Finance") !== -1)) {
            setRole("VT Bnkng & Finance")
            role = "VT Bnkng & Finance";
        }
        if (teams.some((row) => row.indexOf("CostaRicaBids VT Public Sector") !== -1)) {
            setRole("VT Public Sector")
            role = "VT Public Sector";
        }
        if (teams.some((row) => row.indexOf("CostaRicaBids AM") !== -1)) {
            setRole("CostaRicaBids AM")
            role = "CostaRicaBids AM";
        }
        setLoad(true);
        dispatch(getPurchaseOrder({ role: role })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    rows: payload.data.payload.data
                }))
            }
        })
        dispatch(getExcelReport({ role: role })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    excel: payload.data.payload.data
                }))
            }
        })
        dispatch(getMasterData()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    gbmStatus: payload.data.payload.masterData.gbmStatus,
                    noParticipationReason: payload.data.payload.masterData.noParticipationReason,
                    oppType: payload.data.payload.masterData.oppType,
                    processType: payload.data.payload.masterData.processType,
                    salesType: payload.data.payload.masterData.salesType,
                    status: payload.data.payload.masterData.status,
                    productLine: payload.data.payload.masterData.productLine,
                    valueTeam: payload.data.payload.masterData.valueTeam,
                    customers: payload.data.payload.masterData.customers,
                    employee: payload.data.payload.masterData.employee,
                }))
            }
            setLoad(false);
        })
    }
    //#region 
    const [page, setPage] = useState(1);
    //#endregion

    //#region effect to display edit checkbox

    //Funcion para traer la informacion de lo selects y el nombre de las columnas de la tabla

    const renderRowsByFilters = (rows) => {
        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);
            for (const element of filterKeys) {
                const valueFiltered = filters[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    const handleOpenModal = (e) => {
        setInfo(e);
        setRefreshInput(1);
        getPurchaseProductos(e);
        setShowEditModal(true);
        const numCustomer = parseInt(e.customerInstitute);
        dispatch(getContacts({ info: numCustomer, type: "2" })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    contacts: payload.data.payload.response,
                }))
            }
        })
    }
    const getPurchaseProductos = (e) => {
        dispatch(getProducts({ info: e.id })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    products: payload.data.payload.products,
                    salesTeam: payload.data.payload.salesTeam,
                    evaluations: payload.data.payload.evaluations
                }))
            }
        })
        dispatch(getFilesByCustomer(e.bidNumber)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    files: payload.data.data
                }))
            }
        })
    }
    const handleCloseModal = () => {
        setInfo([]);
        setRefreshInput(2);
        setShowEditModal(false);
        setActiveTab("1");
        setRowsModal(prevState => ({
            ...prevState,
            products: []
        }))
    }
    const handleChangeInfo = (constant, e) => {
        if (constant === "customerInstitute") {
            setInfo(prevState => ({
                ...prevState,
                customerName: e.value,
                customerInstitute: e.id
            }))
        }
        setInfo(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }
    //Funcion para los filtros por columna
    const handleOnfilterDinamic = (constant, e) => {
        let text = e
        filters[constant] = text;
        setRowsModal(prevState => ({
            ...prevState,
            filters: filters
        }))
        setPage(1);
    }
    const handleOnLoadEditModal = (e, row) => {
        setInfo(row);
        if (e.label === "SI") {
            dispatch(getContacts({ info: row.customerInstitute, type: "2" })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    setOptions(prevState => ({
                        ...prevState,
                        contacts: payload.data.payload.response,
                    }))
                }
            })
            setShowModal(true)
        } else if (e.label === "NO") {
            setShowNoParticipation(true)
        }
    }
    return (
        <div>
            <AdminHeader name="Home" parentName="Convenio Macro" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    <AgreementMainTable
                        filters={handleOnfilterDinamic}
                        options={options.participation}
                        page={page}
                        setRowsModal={setRowsModal}
                        setPage={setPage}
                        rows={renderRowsByFilters(rowsModal.rows)}
                        rowsExcel={rowsModal.excel}
                        openModal={handleOpenModal}
                        handleOnParticipation={handleOnLoadEditModal}
                        isDisplay={isDisplay}
                        isAdmin={isAdmin}
                        refreshInput={refreshInput}
                        setRefreshInput={setRefreshInput}
                        setRole={setRole}
                        role={role}
                        token={token}
                        setLoading={setLoad}
                    />
                </Spin>
                {
                    showModal && (
                        <ModalParticipate
                            showModal={showModal}
                            options={options}
                            setOptions={setOptions}
                            rows={info}
                            setShowModal={setShowModal}
                            handleChangeInfo={handleChangeInfo}
                            Toast={Toast}
                            setRefreshInput={setRefreshInput}
                            setRowsModal={setRowsModal}

                        />
                    )
                }
                {
                    showNoParticipation && (
                        <ModalNoParticipate
                            showNoParticipation={showNoParticipation}
                            options={options}
                            rows={info}
                            setShowModal={setShowNoParticipation}
                            setInfo={setInfo}
                            Toast={Toast}
                            setRefreshInput={setRefreshInput}
                        />
                    )
                }
                {showEditModal && (
                    <AgreementCREditModal
                        info={info}
                        rowsModal={rowsModal}
                        showModal={showEditModal}
                        setRowsModal={setRowsModal}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        options={options}
                        setShowEditModal={setShowEditModal}
                        handleCloseModal={handleCloseModal}
                        handleOnLoadEditModal={handleOnLoadEditModal}
                        Toast={Toast}
                        isDisplay={isDisplay}
                        isAdmin={isAdmin}
                        setRefreshInput={setRefreshInput}
                        role={role}
                        token={token}
                        setOptions={setOptions}
                    />
                )
                }
            </Container >
        </div >
    );
};
export default AgreementCR;