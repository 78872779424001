/*!

=========================================================
*CustomCard
=========================================================

*Componente card reutilizable para todas las vistas de 
Smart & Simple. 
*Props:
        title: (Obligatorio) Título del card.
        subtitle: (Obligatorio) Subtítulo del card.
        onClick: función al darle click.
        Icon: (Poner la primera letra en mayúscula) texto icon, o un objeto tipo de React icons.
        color: info, default, warning, success, primary.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM
*/


// react library
import React, { useState, useEffect } from "react";
// reactstrap components
import { Card, CardBody, CardTitle, Col, Row, CardImg } from "reactstrap";

//Icono por defecto.
import { FaAngleDown } from "react-icons/fa";

export const CustomCard = React.memo(
    ({
        title,
        subtitle,
        onClick,
        Icon,
        color,
        xs,
        sm
    }) => {

        //color por defecto.
        const localColor = color ? color : "default";

        //icono por defecto.
        Icon = Icon ? Icon : FaAngleDown


        //rutas de las imagenes según color.
        const [image, setImage] = useState({
            info: "info.png",
            default: "white.png",
            primary: "primary.png",
            warning: "warning.png",
            success: "success.png",
        })

        //#region Verifica si es pc o mobile.
        const [isMobile, setIsMobile] = useState(false)

        //Efecto para validar si es mobile o pc
        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth < 768);
                // setIsComputer(window.innerWidth >= 768);

            };

            // Llama a handleResize al principio y cada vez que se redimensiona la ventana
            handleResize();
            window.addEventListener('resize', handleResize);

            // Limpia el listener cuando el componente se desmonta
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);
        //#endregion

        return (

            <Col /*key={key}*/ xs={xs} sm={sm} className={` ${isMobile ? 'p-1 mb--2' : ''}`} style={{ height: isMobile ? '135px' : '' }} >
                <Card
                    className="card-profile p-0 card-lift--hover text-center"
                    style={{
                        height: isMobile ? '130px' : '182px',
                        backgroundColor: '#ffffff', cursor: "pointer"
                    }}
                    onClick={() => onClick()}
                >
                    {!isMobile &&
                        /* Si se quiere habilitar la imagen en mobile eliminar la condición */

                        < CardImg
                            alt="..."
                            src={require(`assets/img/cards/${image[localColor]}`)}
                            top

                            style={{ height: isMobile ? '50px' : '47px' }}
                        // style={{ height: '55px', width: 'auto', display: 'block', margin: '0 auto' }}
                        />
                    }

                    <Row className="justify-content-center align-items-center" style={{}}>
                        <Col className={isMobile ? "mt-2 d-flex justify-content-center align-items-center" : "mt--4 d-flex justify-content-center align-items-center"} lg="3">
                            <div className="card-profile-image d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                <a>
                                    <div className={`icon icon-shape bg-gradient-${localColor} text-white rounded-circle shadow`}
                                        style={{
                                            width: `${isMobile ? '50px' : '58px'}`,
                                            height: `${isMobile ? '50px' : '55px'}`,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {typeof Icon === 'string' ?
                                            <i className={Icon + " rounded-circle"} />
                                            :
                                            <Icon />}
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <CardBody className={`pt-0 mt-2 ${isMobile ? 'p-0' : 'pr-0 pl-0 pb-2'}  `}>

                        <div className={`text-center ${isMobile && 'p-0'}  p-0`} >
                            <h3 className={`${isMobile ? 'h4' : 'h3'}`}>
                                {title}
                            </h3>
                            {!isMobile &&
                                <div className="h5 font-weight-300">
                                    {subtitle}
                                </div>}
                        </div>

                    </CardBody>
                </Card>
            </Col>

            //ORIGINAL
            // <Col /*key={key}*/ xs="6" sm="3" className={` ${isMobile ? 'p-1 mb--2' : ''}`} style={{ height: isMobile ? '135px' : '' }} >
            //     <Card
            //         className="card-profile p-0 card-lift--hover text-center"
            //         style={{
            //             height: isMobile ? '130px' : '190px',
            //             backgroundColor: '#ffffff', cursor: "pointer"
            //         }}
            //         onClick={() => onClick()}
            //     >
            //         {!isMobile &&
            //             /* Si se quiere habilitar la imagen en mobile eliminar la condición */

            //             < CardImg
            //                 alt="..."
            //                 src={require(`assets/img/cards/${image[localColor]}`)}
            //                 top

            //                 style={{ height: isMobile ? '50px' : '55px' }}
            //             // style={{ height: '55px', width: 'auto', display: 'block', margin: '0 auto' }}
            //             />
            //         }

            //         <Row className="justify-content-center" >
            //             {/* Si se quiere habilitar la imagen en mobile poner las mismas que en el pc mode. */}
            //             <Col className={isMobile ? "mt-2" : "mt--4 "} lg="3" >



            //                 {/* <div className="card-profile-image p-0 " > */}
            //                 <div className="card-profile-image " >

            //                     <a >

            //                         <div className={`icon icon-shape bg-gradient-${localColor} text-white rounded-circle shadow justify-content-center `}
            //                             // ml--2
            //                             style={{
            //                                 width: `${isMobile ? '50px' : '58px'}`,
            //                                 height: `${isMobile ? '50px' : '55px'}`,
            //                                 display: 'flex',
            //                                 justifyContent: 'center',
            //                                 alignItems: 'center'
            //                             }}

            //                         >
            //                             {typeof Icon === 'string' ?
            //                                 <i className={Icon + " rounded-circle"} />
            //                                 :
            //                                 <Icon />}
            //                         </div>
            //                     </a>
            //                 </div>

            //             </Col>
            //         </Row>
            //         <CardBody className={`pt-0 mt-2 ${isMobile ? 'p-0' : 'pr-0 pl-0 pb-2'}  `}>

            //             <div className={`text-center ${isMobile && 'p-0'}  p-0`} >
            //                 <h3 className={`${isMobile ? 'h4' : 'h3'}`}>
            //                     {title}
            //                 </h3>
            //                 {!isMobile &&
            //                     <div className="h5 font-weight-300">
            //                         {subtitle}
            //                     </div>}
            //             </div>

            //         </CardBody>
            //     </Card>
            // </Col>



        );
    }
);
