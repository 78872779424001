import {
    addAttachment,
    approvalAction,
    downloadAttachment,
    getRequest,
    preventRelaunch,
    relaunch,
    remindApprover,
    removeFile,
    RRAction,
  } from "actions/FinancialFlowsActions.jsx";
  import { Spin } from "antd";
  import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
  import moment from "moment";
  // react library
  import React, { Component } from "react";
  import NotificationAlert from "react-notification-alert";
  // react-redux library for conect redux
  import { connect } from "react-redux";
  // react-router-dom library for router
  import { withRouter } from "react-router-dom";
  // reactstrap components
  import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
  } from "reactstrap";
  import { getUsernameLogged } from "selectors/adminLayout.jsx";
  import { RequestSelector } from "selectors/FinancialFlows.jsx";
  
  class SalaryRequestDetails extends Component {
    componentRef = null;
    constructor(props) {
      super(props);
      const { match } = this.props;
      this.state = {
        loading: true,
        urlID: match.params.id,
        showDetails: false,
        nextApprover: {},
        showApprovalModal: false,
        showDeclineModal: false,
        selectedApproval: null,
        showAttachmentModal: false,
        selectedFile: null,
        approvalNotes: "",
        showTableModal: false,
        selectedTable: null,
        selectedTableValues: [],
        FinanceRequestData: {},
        showApproveRRModal: false,
        showDeclineRRModal: false,
      };
      this.toggleAttachmentModal = this.toggleAttachmentModal.bind(this);
    }
  
    componentWillMount() {
      if (this.state.urlID) {
        const id = this.state.urlID;
        this.props.getRequest(id).then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              await this.notify("danger", "Falló", payload.message);
            } else {
              await this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            this.setState({
              loading: false,
              FinanceRequestData: res.payload.data.payload.data,
            });
          }
        });
      } else {
        //MENSAJE DE ERROR
      }
    }
    notify = (type, title, message) => {
      let options = {
        place: "br",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {" "}
              {title}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    };
  
    renderFieldType = (field) => {
      var renderField = null;
      switch (field.type) {
        case "text":
          renderField = (
            <FormGroup className="row" key={field.id + "-text"}>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
                md="4"
              >
                {/* {field.name} {field.required ? <small>*</small> : null} */}
                {field.required ? (
                  <div>
                    {field.name} <small> * </small>
                  </div>
                ) : (
                  <div>{field.name}</div>
                )}
              </Label>
              <Col md="8">
                <Input
                  defaultValue={field.value}
                  id="example-text-input"
                  type="text"
                  // className={field.required ? "is-invalid" : ""}
                  disabled
                />
              </Col>
            </FormGroup>
          );
          break;
        case "textarea":
          renderField = (
            <FormGroup className="row" key={field.id + "-text"}>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
                md="4"
              >
                {/* {field.name} {field.required ? <small>*</small> : null} */}
                {field.required ? (
                  <div>
                    {field.name} <small> * </small>
                  </div>
                ) : (
                  <div>{field.name}</div>
                )}
              </Label>
              <Col md="8">
                <Input
                  defaultValue={field.value}
                  id="example-text-input"
                  type="textarea"
                  // className={field.required ? "is-invalid" : ""}
                  disabled
                />
              </Col>
            </FormGroup>
          );
          break;
        case "date":
          renderField = (
            <FormGroup className="row" key={field.id + "-date"}>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
                md="4"
              >
                {/* {field.name} {field.required ? <small>requerido</small> : null} */}
                {field.required ? (
                  <div>
                    {field.name} <small> * </small>
                  </div>
                ) : (
                  <div>{field.name}</div>
                )}
              </Label>
              <Col md="8">
                <Input
                  defaultValue={field.value}
                  id="example-date-input"
                  type="date"
                  // className={field.required ? "is-invalid" : ""}
                  disabled
                />
              </Col>
            </FormGroup>
          );
          break;
        case "number":
          if (field.name === "Monto US$") {
            var formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            });
            let formatted = formatter.format(field.value);
            renderField = (
              <FormGroup className="row" key={field.id + "-number"}>
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                  md="4"
                >
                  {/* {field.name}
                {field.required ? <small>requerido</small> : null} */}
                  {field.required ? (
                    <div>
                      {field.name} <small> * </small>
                    </div>
                  ) : (
                    <div>{field.name}</div>
                  )}
                </Label>
                <Col md="8">
                  <Input
                    value={formatted}
                    id="example-number-input"
                    type="text"
                    disabled
                  />
                </Col>
              </FormGroup>
            );
            break;
          } else {
            renderField = (
              <FormGroup className="row" key={field.id + "-number"}>
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                  md="4"
                >
                  {/* {field.name}
                {field.required ? <small>requerido</small> : null} */}
                  {field.required ? (
                    <div>
                      {field.name} <small> * </small>
                    </div>
                  ) : (
                    <div>{field.name}</div>
                  )}
                </Label>
                <Col md="8">
                  <Input
                    defaultValue={field.value}
                    id="example-number-input"
                    type="number"
                    // className={field.required ? "is-invalid" : ""}
                    disabled
                  />
                </Col>
              </FormGroup>
            );
            break;
          }
        case "currency":
          if (field.name) {
            var formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            });
            let formatted = formatter.format(field.value);
            renderField = (
              <FormGroup className="row" key={field.id + "-number"}>
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                  md="4"
                >
                  {/* {field.name}
                {field.required ? <small>requerido</small> : null} */}
                  {field.required ? (
                    <div>
                      {field.name} <small> * </small>
                    </div>
                  ) : (
                    <div>{field.name}</div>
                  )}
                </Label>
                <Col md="8">
                  <Input
                    value={formatted}
                    id="example-number-input"
                    type="text"
                    disabled
                  />
                </Col>
              </FormGroup>
            );
            break;
          } else {
            renderField = (
              <FormGroup className="row" key={field.id + "-number"}>
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                  md="4"
                >
                  {/* {field.name}
                {field.required ? <small>requerido</small> : null} */}
                  {field.required ? (
                    <div>
                      {field.name} <small> * </small>
                    </div>
                  ) : (
                    <div>{field.name}</div>
                  )}
                </Label>
                <Col md="8">
                  <Input
                    defaultValue={field.value}
                    id="example-number-input"
                    type="number"
                    // className={field.required ? "is-invalid" : ""}
                    disabled
                  />
                </Col>
              </FormGroup>
            );
            break;
          }
        case "float":
          renderField = (
            <FormGroup className="row" key={field.id + "-float"}>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
                md="4"
              >
                {field.name}
              </Label>
              <Col md="8">
                <Input
                  defaultValue={field.value}
                  id="example-number-input"
                  type="number"
                  // className={field.required ? "is-invalid" : ""}
                  disabled
                />
              </Col>
            </FormGroup>
          );
          break;
        case "table":
          renderField = (
            <FormGroup className="row" key={field.id + "-combobox"}>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
                md="4"
              >
                {/* {field.name} */}
                {field.required ? (
                  <div>
                    {field.name} <small> * </small>
                  </div>
                ) : (
                  <div>{field.name}</div>
                )}
              </Label>
              <Col md="8">
                <Button
                  color="warning"
                  href="#"
                  onClick={() => this.showTableRender(field)}
                  size="lg"
                >
                  Datos de tabla
                </Button>
              </Col>
            </FormGroup>
          );
          break;
        default:
          renderField = (
            <FormGroup className="row" key={field.id + "-default"}>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
                md="4"
              >
                {/* {field.name}
                {field.required ? <small>requerido</small> : null} */}
                {field.required ? (
                  <div>
                    {field.name} <small> * </small>
                  </div>
                ) : (
                  <div>{field.name}</div>
                )}
              </Label>
              <Col md="8">
                <Input
                  defaultValue={field.value}
                  id="example-text-input"
                  type="text"
                  // className={field.required ? "is-invalid" : ""}
                  disabled
                />
              </Col>
            </FormGroup>
          );
          break;
      }
      return renderField;
    };
  
    getNextApprover() {
      const { FinanceRequestData } = this.state;
      var nextAppr = FinanceRequestData.approvers.find((value) => {
        return value.status === null;
      });
      // console.log(nextAppr);
      if (
        nextAppr &&
        FinanceRequestData.StateID !== 3 &&
        FinanceRequestData.StateID !== 4 &&
        FinanceRequestData.StateID !== 5
      ) {
        if (nextAppr.secondary) {
          return (
            <Card className="bg-gradient-success border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Siguientes Aprobadores
                    </CardTitle>
                    <span className="p font-weight-bold mb-0 text-white">
                      {`${nextAppr.name.substring(
                        0,
                        25
                      )} - ${nextAppr.secondary.name.substring(0, 25)}`}
                    </span>
                    <span className="h2 font-weight-bold mb-0 text-white"></span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className={"fa fa-user"} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        } else {
          return (
            <Card className="bg-gradient-success border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Siguiente Aprobador
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {`${nextAppr.name.substring(0, 25)}`}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className={"fa fa-user"} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        }
      } else {
        return (
          <Card className="bg-gradient-primary border-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    Fecha de Solicitud
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {moment(FinanceRequestData.createdAt).format("lll")}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className={"fa fa-user"} />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        );
      }
    }
  
    requestStatusCard() {
      const { FinanceRequestData } = this.state;
      switch (FinanceRequestData.statusName) {
        case "pending":
          return (
            <Card className="bg-gradient-warning border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Solicitud Pentiente
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      Estado Inicial
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className={"fa fa-play"} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        case "in process":
          return (
            <Card className="bg-gradient-info-gbm border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Solicitud En Proceso
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      En proceso de aprobación
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className={"fa fa-people-carry"} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        case "complete":
          return (
            <Card className="bg-gradient-success border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Solicitud Completada
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      Aprobado
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className={"fa fa-check"} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        case "cancelled":
          return (
            <Card className="bg-gradient-danger border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Solicitud Cancelada
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      Flujo Detenido
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className={"fa fa-stop"} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        case "declined":
          return (
            <Card className="bg-gradient-danger border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      Solicitud Completada
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      Rechazada
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i className={"fa fa-ban"} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        default:
          return null;
      }
    }
  
    approverAction = async (value, comment) => {
      const { FinanceRequestData } = this.state;
      let info = {
        RequestID: FinanceRequestData.id,
        status: value,
        comment: comment,
        document: FinanceRequestData.document
      };
      this.setState({
        loading: true,
        showApprovalModal: false,
        showDeclineModal: false,
      });
  
      await this.props
        .approvalAction(this.state.selectedApproval, info)
        .then(async (res) => {
          
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("danger", "Falló", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            this.notify(
              "success",
              "Éxito",
              "Cambio de estado enviado exitosamente."
            );
            this.setState({
              loading: false,
              selectedApproval: null,
            });
            this.componentWillMount();
          }
        });
    };
  
    toggleApprovalModal(id) {
      // console.log(id);
      this.setState((prevState) => ({
        showApprovalModal: !prevState.showApprovalModal,
      }));
      if (id) this.setState({ selectedApproval: id });
    }
  
    toggleDeclineModal(id) {
      this.setState((prevState) => ({
        showDeclineModal: !prevState.showDeclineModal,
      }));
      if (id) this.setState({ selectedApproval: id });
    }
  
    toggleAttachmentModal() {
      this.setState((prevState) => ({
        showAttachmentModal: !prevState.showAttachmentModal,
      }));
    }
  
    handleDocumentDownload = async (id, name) => {
      await this.props.downloadAttachment(id).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([res.payload.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        }
      });
    };
  
    handleAddAttachment = async () => {
      const { id } = this.state.FinanceRequestData;
      // console.log(id);
      let data = new FormData();
      data.append("doc", this.state.selectedFile);
      await this.props.addAttachment(id, data).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.setState({ showAttachmentModal: false });
          this.componentWillMount();
        }
      });
    };
  
    handleSelectFile = (event) => {
      this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      });
    };
  
    handleNoteChanges = (value) => {
      // console.log(value);
      this.setState({
        approvalNotes: value,
      });
    };
  
    handleRemindApprover = async (approver) => {
      // console.log(approver);
      await this.props.remindApprover(approver).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.notify("success", "Éxito", "Recordatorio enviado exitosamente.");
          this.componentWillMount();
        }
      });
    };
  
    showTableRender = (field) => {
      if (typeof field.value === "string") {
        field.value = [];
        let elements = {};
        field.options.forEach((element) => {
          elements[element.value] = element.value;
        });
        field.value.push(elements);
      }
  
      this.setState({
        showTableModal: true,
        selectedTable: field,
        selectedTableValues: field.value,
      });
    };
  
    closeTable = () => {
      this.setState({ showTableModal: false });
    };
  
    handlePreventRelaunch = async () => {
      const id = this.state.urlID;
      await this.props.preventRelaunch(id).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.notify("danger", "Éxito", "Esta solicitud ha sido finalizada.");
          this.componentWillMount();
        }
      });
    };
  
    handleRelaunch = async () => {
      const id = this.state.urlID;
      await this.props.relaunch(id).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.notify("success", "Éxito", "creado");
          this.componentWillMount();
          //cambiar de pantalla
          let relaunched = res.payload.data.payload.newRequest;
          if (relaunched) {
            this.props.history.push(
              `/admin/finance/request/update/${relaunched}`
            );
          }
        }
      });
    };
  
    handleRemoveFile = async (id) => {
      await this.props.removeFile(id).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.notify("danger", "Éxito", "Archivo eliminado exitosamente.");
          this.componentWillMount();
        }
      });
    };
  
    downloadPdfDocument = () => {
      if (document.getElementById("botDiv"))
        document.getElementById("botDiv").outerHTML = "";
      setTimeout(function () {
        window.print();
      }, 1000);
    };
  
    toggleDeclineRRModal() {
      this.setState((prevState) => ({
        showDeclineRRModal: !prevState.showDeclineRRModal,
      }));
    }
  
    toggleApproveRRModal() {
      this.setState((prevState) => ({
        showApproveRRModal: !prevState.showApproveRRModal,
      }));
    }
  
    RRAction = async (value, comment) => {
      const { id } = this.state.FinanceRequestData;
      let info = {
        status: value,
        comment: comment,
      };
      await this.props.RRAction(id, info).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.notify(
            "success",
            "Éxito",
            "Cambio de estado enviado exitosamente."
          );
          this.setState({
            showApproveRRModal: false,
            showDeclineRRModal: false,
          });
          this.componentWillMount();
        }
      });
    };
    goToListRequest = (request) => {
      this.props.history.push(`/admin/finance/config/requests-history`);
    };
    render() {
      const { username } = this.props;
      const { FinanceRequestData } = this.state;
      let {
        loading,
        showDetails,
        showApprovalModal,
        showDeclineModal,
        showAttachmentModal,
        selectedFile,
        approvalNotes,
        showTableModal,
        selectedTable,
        selectedTableValues,
        showDeclineRRModal,
        showApproveRRModal,
      } = this.state;
      let actions = [];
      let approvalActions = false;
  
      if (FinanceRequestData.approvers) {
        var approvers = FinanceRequestData.approvers;
        var nextApprover = approvers.find((value) => {
          return value.status === null;
        });
  
        if (
          FinanceRequestData.RRrequired &&
          FinanceRequestData.RRstatus === null
        ) {
          actions.push(
            <ListGroupItem className="px-0" key="RR-status-approval">
              <Row className="align-items-center">
                <div className="col ml--2">
                  <h4 className="mb-0">
                    <p onClick={(e) => e.preventDefault()}>Proceso completado</p>
                  </h4>
                </div>
                <Col className="col-auto">
                  <Button
                    color="success"
                    size="sm"
                    type="button"
                    onClick={(e) => this.toggleApproveRRModal()}
                  >
                    <i className="fa fa-check" />
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          );
          actions.push(
            <ListGroupItem className="px-0" key="RR-status-decline">
              <Row className="align-items-center">
                <div className="col ml--2">
                  <h4 className="mb-0">
                    <p onClick={(e) => e.preventDefault()}>Proceso incompleto</p>
                  </h4>
                </div>
                <Col className="col-auto">
                  <Button
                    color="warning"
                    size="sm"
                    type="button"
                    onClick={(e) => this.toggleDeclineRRModal()}
                  >
                    <i className="fa fa-times" />
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          );
        }
  
        if (
          FinanceRequestData.statusName !== "complete" &&
          FinanceRequestData.statusName !== "cancelled" &&
          FinanceRequestData.statusName !== "declined"
        ) {
          if (nextApprover)
            if (
              (nextApprover && nextApprover.username === username) ||
              (nextApprover.secondary &&
                nextApprover.secondary.user === username) ||
              username === "LARNESTO" || username === "JCAGUILA"
            ) {
              actions.push(
                <ListGroupItem className="px-0" key="aprobar-solicitud">
                  <Row className="align-items-center">
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        <p onClick={(e) => e.preventDefault()}>
                          Aprobar Solicitud
                        </p>
                      </h4>
                    </div>
                    <Col className="col-auto">
                      <Button
                        color="success"
                        size="sm"
                        type="button"
                        onClick={(e) => this.toggleApprovalModal(nextApprover.id)}
                      >
                        <i className="fa fa-check" />
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              );
              actions.push(
                <ListGroupItem className="px-0" key="rechazar-solicitud">
                  <Row className="align-items-center">
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        <p onClick={(e) => e.preventDefault()}>
                          Rechazar Solicitud
                        </p>
                      </h4>
                    </div>
                    <Col className="col-auto">
                      <Button
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={(e) => this.toggleDeclineModal(nextApprover.id)}
                      >
                        <i className="fa fa-times" />
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              );
              approvalActions = true;
            }
  
          if (
            FinanceRequestData.sign &&
            FinanceRequestData.sign.user === username
          ) {
            actions.push(
              <ListGroupItem className="px-0" key="rechazar-recordatorio">
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      <p onClick={(e) => e.preventDefault()}>
                        Recordatorio al siguiente aprobador
                      </p>
                    </h4>
                  </div>
                  <Col className="col-auto">
                    <Button
                      color="warning"
                      size="sm"
                      type="button"
                      onClick={(e) => this.handleRemindApprover(nextApprover.id)}
                    >
                      <i className="fa fa-eye" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            );
          }
        }
      }
  
      return (
        <>
          <AttachmentModal
            showModal={showAttachmentModal}
            toggleModal={this.toggleAttachmentModal}
            selectedFile={selectedFile}
            handleSelectFile={this.handleSelectFile}
            uploadFile={this.handleAddAttachment}
          />
          <TableFieldModal
            showTableModal={showTableModal}
            selectedTable={selectedTable}
            selectedTableValues={selectedTableValues}
            closeTable={this.closeTable}
          />
          <Modal isOpen={showApprovalModal}>
            <ModalHeader toggle={() => this.toggleApprovalModal(null)}>
              Actualización de estado:
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <label className="form-control-label" htmlFor="approval-input">
                    Estado
                  </label>
                  <Input
                    id="approval-name-input"
                    placeholder="Aprobado"
                    type="text"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea3"
                  >
                    Notas:
                  </label>
                  <Input
                    id="comment"
                    resize="none"
                    rows="3"
                    type="textarea"
                    value={approvalNotes}
                    onChange={(e) => this.handleNoteChanges(e.target.value)}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="default"
                onClick={() => this.approverAction(true, approvalNotes)}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={showDeclineModal}>
            <ModalHeader toggle={() => this.toggleDeclineModal(null)}>
              Actualización de estado:
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <label className="form-control-label" htmlFor="approval-input">
                    Estado
                  </label>
                  <Input
                    id="approval-name-input"
                    placeholder="Rechazado"
                    type="text"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea3"
                  >
                    Notas:
                  </label>
  
                  <Input
                    id="comment"
                    resize="none"
                    rows="3"
                    type="textarea"
                    value={approvalNotes}
                    onChange={(e) => this.handleNoteChanges(e.target.value)}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="default"
                onClick={() => this.approverAction(false, approvalNotes)}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={showApproveRRModal}>
            <ModalHeader toggle={() => this.toggleApprovalModal(null)}>
              Actualización de Revenue Recognition:
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <label className="form-control-label" htmlFor="approval-input">
                    Estado
                  </label>
                  <Input
                    id="approval-name-input"
                    placeholder="Proceso Completado"
                    type="text"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea3"
                  >
                    comentario:
                  </label>
                  <Input
                    id="comment"
                    resize="none"
                    rows="3"
                    type="textarea"
                    value={approvalNotes}
                    onChange={(e) => this.handleNoteChanges(e.target.value)}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="default"
                onClick={() => this.RRAction(true, approvalNotes)}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={showDeclineRRModal}>
            <ModalHeader toggle={() => this.toggleApprovalModal(null)}>
              Actualización de Revenue Recognition:
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <label className="form-control-label" htmlFor="approval-input">
                    Estado
                  </label>
                  <Input
                    id="approval-name-input"
                    placeholder="Proceso Incompleto"
                    type="text"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea3"
                  >
                    comentario:
                  </label>
                  <Input
                    id="comment"
                    resize="none"
                    rows="3"
                    type="textarea"
                    value={approvalNotes}
                    onChange={(e) => this.handleNoteChanges(e.target.value)}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="default"
                onClick={() => this.RRAction(false, approvalNotes)}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <AdminHeader
            name={
              FinanceRequestData.document
                ? "Detalles de Solicitud #" + FinanceRequestData.id + " " + FinanceRequestData.document["name"]
                : "Detalles de Solicitud"
            }
            parentName="Finance Flows"
          />
  
          <Container className="mt--6" fluid id="printable">
  
            <Spin size="large" spinning={loading}>
              {FinanceRequestData.id !== undefined && (
                <div>
                  <Row>
                    <Col lg="6">{this.requestStatusCard()}</Col>
                    <Col lg="6">{this.getNextApprover()}</Col>
                  </Row>
                  <Row className="mt-2 mb-4">
                    <Col className="d-flex justify-content-end col">
                      <Button
                        color="info"
                        onClick={(e) => this.goToListRequest()}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-chevron-left" />
                        </span>
                        <span>Regresar</span>
                      </Button>
                    </Col>
                  </Row>
                  {approvalActions && (
                    <Row>
                      <Col lg="12">
                        <Card>
                          <CardHeader>
                            <Row className="align-items-center">
                              <Col xs="8">
                                <h3 className="mb-0">Acción Requerida</h3>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              {" "}
                              <Col lg="6">
                                <Button
                                  block
                                  color="success"
                                  size="lg"
                                  type="button"
                                  onClick={(e) =>
                                    this.toggleApprovalModal(nextApprover.id)
                                  }
                                >
                                  Aprobar Solicitud
                                </Button>
                              </Col>
                              <Col lg="6">
                                <Button
                                  block
                                  color="danger"
                                  size="lg"
                                  type="button"
                                  onClick={(e) =>
                                    this.toggleDeclineModal(nextApprover.id)
                                  }
                                >
                                  Rechazar Solicitud
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  {FinanceRequestData.statusName === "declined" &&
                    FinanceRequestData.relaunched === null &&
                    FinanceRequestData.sign &&
                    FinanceRequestData.sign.user === username && (
                      <Row>
                        <Col lg="12">
                          <Card>
                            <CardHeader>
                              <Row className="align-items-center">
                                <Col xs="8">
                                  <h3 className="mb-0">Acción Requerida</h3>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                {" "}
                                <Col lg="6">
                                  <Button
                                    block
                                    color="success"
                                    size="lg"
                                    type="button"
                                    onClick={(e) => this.handleRelaunch()}
                                  >
                                    Relanzar solicitud (con cambios)
                                  </Button>
                                </Col>
                                <Col lg="6">
                                  <Button
                                    block
                                    color="danger"
                                    size="lg"
                                    type="button"
                                    onClick={(e) => this.handlePreventRelaunch()}
                                  >
                                    Terminar aqui, no se requieren nada adicional
                                  </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
  
                  {FinanceRequestData.RRstatus === false &&
                    FinanceRequestData.sign &&
                    FinanceRequestData.sign.user === username && (
                      <Row>
                        <Col lg="12">
                          <Card>
                            <CardHeader>
                              <Row className="align-items-center">
                                <Col xs="8">
                                  <h3 className="mb-0">Acción Requerida</h3>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                {" "}
                                <Col lg="6">
                                  <Button
                                    block
                                    color="warning"
                                    size="lg"
                                    type="button"
                                    onClick={(e) => this.handleRelaunch()}
                                  >
                                    Relanzar solicitud (con cambios)
                                  </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
  
                  <Row>
                    <Col lg="6">
                      <Card>
                        <CardHeader>
                          <Row className="align-items-center">
                            <Col xs="8">
                              <h3 className="mb-0">Información de Solicitud</h3>
                            </Col>
                            <Col className="text-right" xs="4"></Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            {" "}
                            {FinanceRequestData.data.map((field, index) => {
                              return this.renderFieldType(field, index);
                            })}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col xs="8">
                              <h5 className="h3 mb-0">Flujo de Aprobación</h5>
                            </Col>
                            <Col className="text-right" xs="4"></Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <ListGroup className="list my--3" flush>
                            {FinanceRequestData.approvers.length &&
                              FinanceRequestData.approvers.map((value, index) => {
                                return (
                                  <ListGroupItem
                                    className="px-0"
                                    key={"approver-" + index}
                                  >
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <p
                                          className="avatar rounded-circle"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <img
                                            alt="..."
                                            src={require("assets/img/theme/user.svg")}
                                          />
                                        </p>
                                      </Col>
                                      <div className="col ml--2">
                                        <h4 className="mb-0">
                                          <strong>{value.name}</strong>
                                        </h4>
                                        <h4 className="mb-0">
                                          {value.secondary !== null && (
                                            <strong>
                                              {value.secondary.name}
                                            </strong>
                                          )}
                                        </h4>
                                        <small>{value.position} </small>
                                      </div>
                                      <Col className="col-auto">
                                        {value.status === null && (
                                          <Button
                                            color="default"
                                            size="sm"
                                            type="button"
                                            key="reminder"
                                            disabled
                                          >
                                            En Espera
                                          </Button>
                                        )}
  
                                        {value.status === 1 && (
                                          <Button
                                            color="success"
                                            size="sm"
                                            type="button"
                                            key="reminder"
                                            disabled
                                          >
                                            Aprobado
                                          </Button>
                                        )}
  
                                        {value.status === 0 && (
                                          <Button
                                            color="danger"
                                            size="sm"
                                            type="button"
                                            key="reminder"
                                            disabled
                                          >
                                            Rechazado
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </ListGroupItem>
                                );
                              })}
                          </ListGroup>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <Row className="align-items-center">
                            <Col xs="8">
                              <h3 className="mb-0">Acciones</h3>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <ListGroup className="list my--3" flush>
                            {actions.length ? (
                              actions
                            ) : (
                              // <div
                              //   className="timeline timeline-one-side"
                              //   data-timeline-axis-style="dashed"
                              //   data-timeline-content="axis"
                              //   key="none"
                              // >
                              //   <div className="timeline-block">
                              //     <span className="text-muted text-sm font-weight-bold">
                              //       Ninguna Acción Disponible
                              //     </span>
                              //   </div>
                              // </div>
                              <ListGroupItem
                                className="px-0"
                                key="aprobar-solicitud"
                              >
                                <Row className="align-items-center">
                                  <div className="col ml--2">
                                    <h4 className="mb-0">
                                      <p onClick={(e) => e.preventDefault()}>
                                        Imprimir Solicitud
                                      </p>
                                    </h4>
                                  </div>
                                  <Col className="col-auto">
                                    <Button
                                      color="info"
                                      size="sm"
                                      type="button"
                                      onClick={(e) => this.downloadPdfDocument()}
                                    >
                                      <i className="fa fa-print" />
                                    </Button>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            )}
                          </ListGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Card>
                        <CardHeader>
                          <Row className="align-items-center">
                            <Col xs="8">
                              <h3 className="mb-0">Detalles de Solicitud</h3>
                            </Col>
                            {showDetails && (
                              <Col className="text-right" xs="4">
                                <Button
                                  color="primary"
                                  onClick={(e) =>
                                    this.setState({ showDetails: false })
                                  }
                                  size="sm"
                                >
                                  Ocultar
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {showDetails ? (
                            <Form>
                              <h6 className="heading-small text-muted mb-4">
                                Solicitante
                              </h6>
                              <div className="pl-lg-4">
                                <Row>
                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                      >
                                        Usuario
                                      </label>
                                      <Input
                                        defaultValue={
                                          FinanceRequestData.sign.user
                                        }
                                        id="input-username"
                                        type="text"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                      >
                                        Email
                                      </label>
                                      <Input
                                        id="input-email"
                                        defaultValue={
                                          FinanceRequestData.sign.email
                                        }
                                        type="email"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Posición
                                      </label>
                                      <Input
                                        id="input-first-name"
                                        defaultValue={
                                          FinanceRequestData.sign.position
                                        }
                                        type="text"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-last-name"
                                      >
                                        Departamento
                                      </label>
                                      <Input
                                        id="input-last-name"
                                        defaultValue={
                                          FinanceRequestData.sign.department
                                        }
                                        type="text"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                              <hr className="my-4" />
  
                              <h6 className="heading-small text-muted mb-4">
                                Informacion de Documento
                              </h6>
                              <div className="pl-lg-4">
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-address"
                                      >
                                        Nombre
                                      </label>
                                      <Input
                                        defaultValue={
                                          FinanceRequestData.document.name
                                        }
                                        id="input-address"
                                        type="text"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-city"
                                      >
                                        Categoria
                                      </label>
                                      <Input
                                        defaultValue={
                                          FinanceRequestData.document.category
                                        }
                                        id="input-city"
                                        type="text"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-city"
                                      >
                                        Ultima actualización:
                                      </label>
                                      <Input
                                        defaultValue={
                                          FinanceRequestData.document.updatedAt
                                            ? moment(
                                              FinanceRequestData.document
                                                .updatedAt
                                            ).format("lll")
                                            : "Ningún cambio realizado."
                                        }
                                        id="input-city"
                                        type="text"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                      >
                                        Creado por:
                                      </label>
                                      <Input
                                        defaultValue={
                                          FinanceRequestData.document.createdBy
                                        }
                                        id="input-country"
                                        type="text"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                      >
                                        Tipo de Flujo
                                      </label>
                                      {FinanceRequestData.document
                                        .conditionals === 1 ? (
                                        <Input
                                          defaultValue="Flujo Abierto"
                                          id="input-postal-code"
                                          placeholder="Postal code"
                                          type="text"
                                          disabled
                                        />
                                      ) : (
                                        <Input
                                          defaultValue="Flujo Cerrado"
                                          id="input-postal-code"
                                          placeholder="Postal code"
                                          type="text"
                                          disabled
                                        />
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                              <hr className="my-4" />
  
                              <h6 className="heading-small text-muted mb-4">
                                Descripcion de Solicitud
                              </h6>
                              <div className="pl-lg-4">
                                <FormGroup>
                                  <Input
                                    rows="4"
                                    type="textarea"
                                    defaultValue={FinanceRequestData.description}
                                    disabled
                                  />
                                </FormGroup>
                              </div>
                            </Form>
                          ) : (
                            <Col className="text-right" md="7">
                              <Button
                                color="primary"
                                onClick={(e) =>
                                  this.setState({ showDetails: true })
                                }
                                size="lg"
                              >
                                Mostrar Detalles
                              </Button>
                            </Col>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
  
                  <Row>
                    <Col lg="6">
                      <Card className="bg-gradient-default shadow">
                        <CardHeader className="bg-transparent">
                          <h3 className="mb-0 text-white">Bitacora de Cambios</h3>
                        </CardHeader>
                        <CardBody>
                          <div
                            className="timeline timeline-one-side"
                            data-timeline-axis-style="dashed"
                            data-timeline-content="axis"
                          >
                            {/* ACTIVIDADES */}
                            {FinanceRequestData.timeline.map((value, key) => {
                              return (
                                <RenderTimelineActivity
                                  time={moment(value.createdAt).format("lll")}
                                  type={value.step}
                                  activity={value.activity}
                                  status={value.requestStatus}
                                  user={value.name}
                                />
                              );
                            })}
  
                            {/* ACTIVIDADES */}
                          </div>
                          {FinanceRequestData.timeline.length === 0 && (
                            <div className="timeline-block">
                              <span className="text-muted text-sm font-weight-bold">
                                Ninguna Actividad realizada
                              </span>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card>
                        <CardHeader>
                          <Row className="align-items-center">
                            <Col xs="8">
                              <h3 className="mb-0">Archivos Adjuntos</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                              {(FinanceRequestData.StateID === 1 ||
                                FinanceRequestData.StateID === 2) &&
                                FinanceRequestData.sign &&
                                FinanceRequestData.sign.user === username && (
                                  <Button
                                    color="primary"
                                    onClick={(e) => {
                                      this.toggleAttachmentModal();
                                    }}
                                    size="sm"
                                  >
                                    <i className={"fa fa-upload"} />
                                  </Button>
                                )}
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <ListGroup className="list my--3" flush>
                            <ListGroupItem className="px-0" key={"attachment-1"}>
                              {FinanceRequestData.files.length === 0 && (
                                <div>
                                  <h4>Ningún archivo adjuntado a la solicitud</h4>
                                </div>
                              )}
                              {FinanceRequestData.files.map((value, index) => {
                                return (
                                  <Row
                                    className="align-items-center"
                                    key={"file-" + index}
                                  >
                                    <div className="col ml--2">
                                      <h4 className="mb-0">{value.filename}</h4>
                                      <small>
                                        {moment(value.createdAt).format("lll")}
                                      </small>
                                    </div>
                                    <Col className="col-auto">
                                      <Button
                                        color="success"
                                        size="sm"
                                        type="button"
                                        key="reminder"
                                        onClick={(e) =>
                                          this.handleDocumentDownload(
                                            value.id,
                                            value.filename
                                          )
                                        }
                                      //disabled
                                      >
                                        Descargar Adjunto
                                      </Button>
                                      {FinanceRequestData.sign &&
                                        FinanceRequestData.sign.user ===
                                        username &&
                                        (FinanceRequestData.StateID === 1 ||
                                          FinanceRequestData.StateID === 2) && (
                                          <Button
                                            color="danger"
                                            size="sm"
                                            type="button"
                                            key="reminder"
                                            onClick={(e) =>
                                              this.handleRemoveFile(value.id)
                                            }
                                          >
                                            X
                                          </Button>
                                        )}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </ListGroupItem>
                          </ListGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )}
            </Spin>
          </Container>
        </>
      );
    }
  }
  
  SalaryRequestDetails.defaultProps = {
    FinanceRequestData: [],
  };
  
  const mapStateToProps = (state) => ({
    FinanceRequestData: RequestSelector(state),
    username: getUsernameLogged(),
  });
  
  export default withRouter(
    connect(mapStateToProps, {
      getRequest,
      approvalAction,
      downloadAttachment,
      addAttachment,
      remindApprover,
      preventRelaunch,
      relaunch,
      removeFile,
      RRAction,
    })(SalaryRequestDetails)
  );
  
  function AttachmentModal({
    showModal,
    toggleModal,
    selectedFile,
    handleSelectFile,
    uploadFile,
  }) {
    return (
      <Modal isOpen={showModal}>
        <ModalHeader toggle={toggleModal}>
          Adjuntar archivos a la solicitud
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <div className="custom-file">
                <input
                  className="custom-file-input"
                  id="customFileLang"
                  lang="en"
                  type="file"
                  name="file"
                  onChange={handleSelectFile}
                />
                <label className="custom-file-label" htmlFor="customFileLang">
                  {selectedFile ? selectedFile.name : "Seleccione un Archivo"}
                </label>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => uploadFile(selectedFile)}>
            Subir Archivo
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
  
  function RenderTimelineActivity({ time, type, activity, status, user }) {
    // console.log(status);
    switch (type) {
      case "request":
        if (status === 1) {
          return (
            <div className="timeline-block">
              <span className="timeline-step badge-info">
                <i className="ni ni-bell-55" />
              </span>
              <div className="timeline-content">
                <small className="text-light font-weight-bold">{time}</small>
                <h5 className="text-white mt-3 mb-0">Creación de Solicitud</h5>
                <p className="text-light text-sm mt-1 mb-0">{activity}</p>
                <div className="mt-3">
                  <Badge color="default" pill>
                    Inicio de Trabajos: {user}
                  </Badge>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="timeline-block">
              <span className="timeline-step badge-info">
                <i className="ni ni-bell-55" />
              </span>
              <div className="timeline-content">
                <small className="text-light font-weight-bold">{time}</small>
                <h5 className="text-white mt-3 mb-0">Cambio de Estado</h5>
                <p className="text-light text-sm mt-1 mb-0">{activity}</p>
                <div className="mt-3">
                  <Badge color="default" pill>
                    Detalles de Solicitud
                  </Badge>
                </div>
              </div>
            </div>
          );
        }
  
      case "approval":
        return (
          <div className="timeline-block">
            {status !== 5 ? (
              <span className="timeline-step badge-success">
                <i className="ni ni-check-bold" />
              </span>
            ) : (
              <span className="timeline-step badge-danger">
                <i className="ni ni-fat-remove" />
              </span>
            )}
  
            <div className="timeline-content">
              <small className="text-light font-weight-bold">{time}</small>
              <h5 className="text-white mt-3 mb-0">Aprobaciones</h5>
              <p className="text-light text-sm mt-1 mb-0">{activity}</p>
              <div className="mt-3">
                {status !== 5 ? (
                  <Badge color="success" pill>
                    Aprobador: {user}
                  </Badge>
                ) : (
                  <Badge color="danger" pill>
                    Rechazado por: {user}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        );
      case "reminder":
        return (
          <div className="timeline-block">
            <span className="timeline-step badge-primary">
              <i className="ni ni-bell-55" />
            </span>
            <div className="timeline-content">
              <small className="text-light font-weight-bold">{time}</small>
              <h5 className="text-white mt-3 mb-0">Recordatorio de aprobación</h5>
              <p className="text-light text-sm mt-1 mb-0">{activity}</p>
              <div className="mt-3">
                <Badge color="danger" pill>
                  Recordatorio enviado a: {user}
                </Badge>
              </div>
            </div>
          </div>
        );
      case "files":
        return (
          <div className="timeline-block">
            <span className="timeline-step badge-danger">
              <i className="ni ni-image" />
            </span>
            <div className="timeline-content">
              <small className="text-light font-weight-bold">{time}</small>
              <h5 className="text-white mt-3 mb-0">
                Se Agrego un nuevo archivo adjunto
              </h5>
              <p className="text-light text-sm mt-1 mb-0">
                Estos adjuntos permiten complementar la información de la
                solicitud.
              </p>
              <div className="mt-3">
                <Badge color="info" pill>
                  Creado por: {user}
                </Badge>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
  
  function TableFieldModal({
    showTableModal,
    selectedTable,
    selectedTableValues,
    closeTable,
  }) {
    // console.log(selectedTable);
    return (
      <Modal isOpen={showTableModal}>
        <ModalHeader>Detalles de tabla</ModalHeader>
        <ModalBody>
          <Table className="align-items-center " responsive>
            <thead>
              <tr>
                {selectedTable &&
                  selectedTable.options.map((row, key) => {
                    return <th key={"row-" + key}>{row.value}</th>;
                  })}
              </tr>
            </thead>
            <tbody className="list">
              {selectedTableValues.length > 0 ? (
                selectedTableValues.map((value, key) => {
                  return (
                    <tr key={"value-" + key}>
                      {Object.values(value).map((element, keyElement) => {
                        return (
                          <td key={"keyElement-" + keyElement}>{element}</td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="justify-content-md-center">
                    Ningún dato ingresado
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="default" type="button" onClick={() => closeTable()}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
  