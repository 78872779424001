import moment from "moment";
import "moment/locale/es";
moment.locale("es");
export const approversSelector = (state) => {
  if (state.approversData) {
  }
  return state.approversData;
};

export const otherApproversSelector = (state) => {
  if (state.otherApproversData) {
  }
  return state.otherApproversData;
};

//state.approversData;
export const requestSelector = (state) => {
  if (state.requestData) {
    state.requestData.formatedCreatedAt = moment(state.requestData.createdAt)
      .utc()
      .utcOffset(moment().utcOffset())
      .format("LL");
    if (state.requestData.activities) {
      state.requestData.activities.forEach((activity) => {
        activity.formatedCreatedAt = moment(activity.createdAt)
          .utc()
          .utcOffset(moment().utcOffset())
          .fromNow();
      });
    }
    //console.log(state.requestData.document);
    //state.requestData.document = JSON.parse(state.requestData.document);
  }
  return state.requestData;
};

export const completeRequestsSelector = (state) => {
  if (state.completeRequestsData) {
    state.completeRequestsData.forEach((request) => {
      request.formatedCreatedAt = moment(request.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("LL");

      request.formatedupdatedAt = moment(request.updatedAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("LLL");
    });
  }
  return state.completeRequestsData;
};
export const pendingRequestsSelector = (state) => {
  if (state.pendingRequestsData) {
    state.pendingRequestsData.forEach((request) => {
      request.formatedCreatedAt = moment(request.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("LL");

      request.formatedupdatedAt = request.updatedAt
        ? moment(request.updatedAt)
            .utc()
            .utcOffset(moment().utcOffset())
            .format("LLL")
        : "Sin Cambios";
    });
  }
  return state.pendingRequestsData;
};

export const cancelledRequestsSelector = (state) => {
  if (state.cancelledRequestsData) {
    state.cancelledRequestsData.forEach((request) => {
      request.formatedCreatedAt = moment(request.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("LL");

      request.formatedupdatedAt = request.updatedAt
        ? moment(request.updatedAt)
            .utc()
            .utcOffset(moment().utcOffset())
            .format("LLL")
        : "Sin Cambios";
    });
  }
  return state.cancelledRequestsData;
};

export const Notificationselector = (state) => {
  if (state.NotificationsData) {
    console.log(state.NotificationsData);
    state.NotificationsData.forEach((request) => {
      request.formatedCreatedAt = moment(request.createdAt)
        .utc()
        .utcOffset(moment().utcOffset())
        .format("LL");

      request.formatedupdatedAt = request.updatedAt
        ? moment(request.updatedAt)
            .utc()
            .utcOffset(moment().utcOffset())
            .format("LLL")
        : "Sin Cambios";
    });
  }
  return state.NotificationsData;
};
