import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const authenticate = handleActions({
  [constants.AUTH_USER]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.token;
    }
    return "";
  },
  [constants.SIGN_OUT]: () => "",
}, "");
export const generateSignature = handleActions({
  [constants.SIGNATURE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload.token;
    }
    return "";
  },
  [constants.SIGN_OUT]: () => "",
}, "");
export const urlCiscoInfo = handleActions({
  [constants.URL_CISCO]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return "";
  },
  [constants.SIGN_OUT]: () => "",
}, "");
// export const userProtected = handleActions({
//   [constants.AUTH_USER]: (state, action) => {
//     const { status, data } = action.payload;
//     if (status === 200) {
//       return data.payload.protected;
//     }
//     return {};
//   },
// }, {});