import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import { useDispatch } from 'react-redux';
import { getInterview, getDataCardsHistory } from 'actions/exitInterview';
import ReactExport from "react-export-excel";
import moment from "moment";
import Select from "react-select";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelSheet;
const ExcelColumn = ReactExport.ExcelColumn;

const HistoryQueryFilters = ({ rows, setLoad, setRowsModal, setFilters, filterDinamic, department }) => {

  const dispatch = useDispatch();

  //Campos que va a tener el archivo de excel a la hora de crearse
  const excelReport = [
    { value: "idInterview", label: "Id de entrevista" },
    { value: "idCollaborator", label: "Id de Colaborador" },
    { value: "collaboratorName", label: "Nombre de Colaborador" },
    { value: "countriesData", label: "Pais" },
    { value: "immediateBoss", label: "Jefe Inmediato" },
    { value: "department", label: "Departamento" },
    { value: "timeWorked", label: "Tiempo Laborado" },
    { value: "date", label: "Fecha", type: "Fecha de entrevista" },
    { value: "exitType", label: "Tipo de Salida" },
    // { value: "exitReason", label: "Razón de Salida" },
    { value: "specify", label: "Especificacion de razón de salida" },
    { value: "goBackGBM", label: "Volveria a trabajar en GBM?" },
    { value: "comment", label: "Comentario" },
    { value: "rehirable", label: "Recontratable?" },
    { value: "interviewer", label: "Entrevistador" },
  ]

  //hook para alamacenar los valores de los filtros de fecha
  const [dates, setDates] = useState({});
  //Funcion encargada de cambiar el valor en el hook de filtros de fecha
  const handleChangeInfoDate = (constant, e) => {
    setDates((prevState) => ({
      ...prevState,
      [constant]: e
    }))
  }
  //Elimina los valores de los filtros de fecha
  const cleanFilters = () => {
    setDates([]);
  }
  //Funcion encargada de traer la informacion de la tabla principal a la hora de aplicar o borrar filtros
  const handleGetData = async (index) => {
    if (index === 1) {
      cleanFilters();
      setFilters({})
    }
    setLoad(true);
    await dispatch(getInterview({ dates: index === 1 ? [] : dates })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal(prevState => ({
          ...prevState,
          rows: payload.data.payload.data
        }))
      } else {
        setRowsModal(prevState => ({
          ...prevState,
          rows: []
        }))
      }
      setLoad(false);
    })

    await dispatch(getDataCardsHistory({ dates: index === 1 ? [] : dates })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal(prevState => ({
          ...prevState,
          cards: payload.data.payload.data
        }))
      }
    })


  }
  //
  const filters = [
    { type: "date", label: "Fecha de Inicio:", constant: "DateStart", onChange: handleChangeInfoDate, xs: "3" },
    { type: "date", label: "Fecha de Final:", constant: "DateEnd", onChange: handleChangeInfoDate, xs: "3" },
    {
      type: "select", label: "Tipo de solicitud:", constant: "status", onChange: filterDinamic,
      options: [{ label: "Todos", value: "1", filter: "" }, { label: "Completada", value: "2", filter: "Completada" }, { label: "Guardado como Borrador", value: "3", filter: "Guardado como Borrador" }], xs: "3"
    },
    { type: "select", label: "Unidad Organizativa:", constant: "department", onChange: filterDinamic, options: department, xs: "3" },
    
  ]
  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="8">
              <h3 className="mb-0">Busqueda Avanzada</h3>
              <p className="text-sm mb-0">
                Busqueda personalizada en los registros de las entrevistas guardadas y en draft
              </p>
            </Col>
            <Col xs="12" md="1" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-icon"
                color="primary"
                size="sm"
                block
                type="button"
                onClick={() => handleGetData(0)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-search"></i>
                </span>
                <span className="btn-inner--text">Buscar</span>
              </Button>
            </Col>
            <Col xs="12" md="2" className="p-1">
              <ExcelFile element={<Button
                className="btn btn-icon"
                color="success"
                block
                type="button"
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-download"></i>
                </span>
                <span className="btn-inner--text">Descargar</span>
              </Button>} filename="Reporte Entrevistas de Salida">

                <ExcelSheet data={rows} name="Reporte Entrevistas de Salida">
                  {excelReport.map((col, key) => (
                    <ExcelColumn key={key} label={col.label} value={col.value} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
            </Col>
            <Col xs="12" md="1" className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-icon"
                color="danger"
                size="sm"
                block
                type="button"
                onClick={() => handleGetData(1)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text">Limpiar</span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>

            {filters.map((item, key) => (

              <Col key={key} className="text-left" xs="12" md={item.xs}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="startDate">
                    {item.label}
                  </label>
                  {
                    item.type === "select" ?
                      <Select
                        className="basic-single"
                        type={item.type}
                        isSearchable
                        options={item.options}
                        onChange={(e) => item.onChange(item.constant, e.filter)}
                      />
                      :
                      <Input
                        className="m-0"
                        value={dates[item.constant] || ""}
                        style={{ height: "calc(2.25rem + 2px)" }}
                        type={item.type}
                        onChange={(e) => item.onChange(item.constant, e.target.value)}
                      />
                  }

                </FormGroup>
              </Col>

            ))}
            {/* <Col className="text-left" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="startDate">
                  Fecha Inicio:
                </label>
                <Input
                  className="m-0"
                  value={dates["DateStart"] || ""}
                  style={{ height: "calc(2.25rem + 2px)" }}
                  type="date"
                  onChange={(e) => handleChangeInfoDate("DateStart", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="text-left" xs="12" md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="endDate">
                  Fecha Final:
                </label>
                <Input
                  className="m-0"
                  style={{ height: "calc(2.25rem + 2px)" }}
                  value={dates["DateEnd"] || ""}
                  type="date"
                  onChange={(e) => handleChangeInfoDate("DateEnd", e.target.value)}
                />
              </FormGroup>
            </Col> */}

          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default HistoryQueryFilters;