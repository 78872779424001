/* eslint-disable jsx-a11y/anchor-is-valid */
// core antd
import { Tabs } from "antd";
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  ListGroup,
  ListGroupItem,
  FormGroup,
  Input,
} from "reactstrap";

const { TabPane } = Tabs;

const CardEquipments = (props) => {
  const {
    title,
    subtitle,
    state,
    idBusinessModel,
    version,
    equipments,
    references,
    justifications,
    equipmentsSaved,
    equipmentsPendings,
    onBack,
    onSelectEquipment,
    onReject,
    onRedirectJTR,
    onPartsHistory,
    onRedirectPlanning,
    onCompleteParts,
    uploadReference,
  } = props;

  const [tabKey, setTabKey] = useState("1");

  const changeTab = (value) => {
    setTabKey(value);
  };

  const renderTableEquipments = (cols, rows, name, type) => (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md="7">
            <h3 className="text-white mb-0">{name}</h3>
          </Col>
          {version > 1 ? (
            <Col
              className="d-flex align-items-center justify-content-end"
              xs="12"
              md="5"
            >
              <Button
                outline
                className="btn-round btn-icon"
                color="info"
                onClick={onPartsHistory}
                size="sm"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-search" />
                </span>
                <span className="btn-inner--text">Histórico de Partes</span>
              </Button>
            </Col>
          ) : null}
        </Row>
      </CardHeader>
      <Table className="align-items-center table-dark table-flush" responsive>
        <thead className="thead-dark">
          <tr>
            {cols.map(
              (row, key) => row.hide === false && <th key={key}>{row.name}</th>
            )}
            {state === 6 && type === "ibm" && <th />}
          </tr>
        </thead>
        <tbody className="list">
          {rows.map((row, key) => (
            <tr key={key}>
              {cols.map(
                (col, key) =>
                  col.hide === false && (
                    <th
                      key={key}
                      style={{ whiteSpace: "normal", textAlign: "justify" }}
                    >
                      {row[col.key]}
                    </th>
                  )
              )}
              {state === 6 && type === "ibm" ? (
                equipmentsSaved.find((element) => element === row.id) ? (
                  <th
                    key="action"
                    style={{ whiteSpace: "normal", textAlign: "justify" }}
                  >
                    <a
                      className="table-action table-action"
                      onClick={() => onSelectEquipment(row)}
                      href="#"
                      id="edit"
                    >
                      <i className="fas fa-edit" />
                    </a>
                  </th>
                ) : (
                  <th
                    key="action"
                    style={{ whiteSpace: "normal", textAlign: "justify" }}
                  >
                    <a
                      className="table-action table-action"
                      onClick={() => onSelectEquipment(row)}
                      disabled={equipmentsPendings.find(
                        (element) => element.idEquipment === row.id
                      )}
                      href="#"
                      id="edit"
                    >
                      <i
                        className={
                          equipmentsPendings.find(
                            (element) => element.idEquipment === row.id
                          )
                            ? "fas fa-exclamation-triangle"
                            : "fas fa-vote-yea"
                        }
                      />
                    </a>
                  </th>
                )
              ) : null}
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col xs="12" md="7">
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
          {state === 6 &&
          equipmentsSaved.length + equipmentsPendings.length ===
            equipments.ibm.length ? (
            <Col
              className="d-flex align-items-center justify-content-end"
              xs="12"
              md="5"
            >
              <Row>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  xs="12"
                  md="6"
                >
                  <Button
                    className="btn-round btn-icon"
                    color="danger"
                    disabled={
                      equipmentsSaved.length + equipmentsPendings.length !==
                        equipments.ibm.length ||
                      equipmentsSaved.length === equipments.ibm.length
                    }
                    onClick={onRedirectJTR}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-user-shield" />
                    </span>
                    <span className="btn-inner--text">Dirigir a la JTR</span>
                  </Button>
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  xs="12"
                  md="6"
                >
                  <Button
                    className="btn-round btn-icon"
                    color="success"
                    disabled={equipmentsSaved.length !== equipments.ibm.length}
                    onClick={onCompleteParts}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-caret-square-right" />
                    </span>
                    <span className="btn-inner--text">
                      Completar Validación
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              className="d-flex align-items-end justify-content-end"
              xs="12"
              md="5"
            >
              <Row>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  xs="12"
                  md="6"
                >
                  <Button
                    className="btn-round btn-icon"
                    color="success"
                    onClick={onRedirectPlanning}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-user-alt-slash" />
                    </span>
                    <span className="btn-inner--text">
                      Devolver caso a planificación
                    </span>
                  </Button>
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  xs="12"
                  md="6"
                >
                  <Button
                    className="btn-round btn-icon"
                    color="danger"
                    onClick={onReject}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-user-times" />
                    </span>
                    <span className="btn-inner--text">Rechazar</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody>
        <Tabs defaultActiveKey={tabKey} onChange={changeTab}>
          <TabPane tab="Equipos" key="1">
            {equipments.ibm.length ? (
              renderTableEquipments(
                [
                  {
                    name: "Cantidad de Equipos",
                    key: "amountEquipments",
                    hide: false,
                  },
                  { name: "País", key: "country", hide: false },
                  { name: "Tipo de Modelo", key: "typeModel", hide: false },
                  {
                    name: "Serie",
                    key: "serial",
                    hide: idBusinessModel === 1 ? false : true,
                  },
                  {
                    /* { name: "Plataforma", key: "platform", hide: false }, */
                  },
                  {
                    name: "Cantidad de Mantenimientos",
                    key: "amountMaintenance",
                    hide: false,
                  },
                  {
                    /* { name: "Product ID", key: "productID", hide: false }, */
                  },
                  {
                    name: "Horario de Atención",
                    key: "officeHour",
                    hide: false,
                  },
                  {
                    name: "Tiempo Cambio de Parte",
                    key: "timeChangePart",
                    hide: false,
                  },
                  {
                    name: "Años del Servicio",
                    key: "validityService",
                    hide: false,
                  },
                  {
                    name: "Renovación Automatica",
                    key: "automaticRenewal",
                    hide: false,
                  },
                  {
                    name: "Incluye Partes/Equipos",
                    key: "equipmentParts",
                    hide: false,
                  },
                  {
                    /* { name: "Viáticos", key: "viatic", hide: false }, */
                  },
                  {
                    /* { name: "Precio", key: "price", hide: false } */
                  },
                ],
                equipments.ibm,
                "Lista de Equipos",
                "ibm"
              )
            ) : (
              <div className="pt-4 pb-4">
                <h5 className="h3 title">
                  <span className="d-block mb-1 text-center">Sin Equipos </span>
                  <small className="h3 font-weight-light text-muted">
                    Al requerimiento que se selecciono, no se le realizo la
                    carga de ninguna lista de equipos
                  </small>
                </h5>
              </div>
            )}
          </TabPane>
          {(references.length || justifications.length) && (
            <TabPane tab="Referencias" key="2">
              <div className="card-deck flex-column flex-xl-row">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col sm="12" md="8">
                        <h5 className="h3 mb-0">Documentos Cargados</h5>
                      </Col>
                      <Col
                        sm="12"
                        md="4"
                        className="d-flex justify-content-end"
                        onClick={uploadReference}
                      >
                        <Button color="info" size="sm">
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-file-upload" />
                          </span>
                          <span className="btn-inner--text">
                            Cargar Referencia
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <ListGroup className="list my--3" flush>
                      {references.length ? (
                        references.map((row, key) => (
                          <ListGroupItem key={key} className="px-0">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="Notificación"
                                  className="avatar rounded-circle"
                                  src={require(`assets/img/theme/attachment.png`)}
                                />
                              </Col>
                              <div className="col ml--2">
                                <h4 className="mb-0">{row.name}</h4>
                                <small>
                                  {`${row.createdBy} - ${moment(row.createdAt)
                                    .utc()
                                    .utcOffset(moment().utcOffset())
                                    .format("LLL")}`}
                                </small>
                              </div>
                              <Col className="col-auto">
                                <Button
                                  color="info"
                                  size="sm"
                                  href={`${urlBase}/digital-request/download-attachment/${window.btoa(
                                    row.path
                                  )}/${window.btoa(row.name)}`}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-file-download" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Descargar
                                  </span>
                                </Button>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        ))
                      ) : (
                        <div className="pt-4 pb-4">
                          <h5 className="h3 title">
                            <span className="d-block mb-1 text-center">
                              Sin documentos
                            </span>
                            <small className="h3 font-weight-light text-muted">
                              Al requerimiento que se seleccionó, no se le
                              realizo la carga de ningún archivo de referencia.
                            </small>
                          </h5>
                        </div>
                      )}
                    </ListGroup>
                  </CardBody>
                  {justifications.length ? (
                    <>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col sm="12" md="8">
                            <h5 className="h3 mb-0">
                              Justificaciones Agregadas
                            </h5>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {justifications.map((row, key) => (
                          <FormGroup key={key}>
                            <Input
                              disabled
                              id="justify"
                              rows="3"
                              resize="none"
                              type="textarea"
                              maxLength={500}
                              defaultValue={row.justify}
                            />
                          </FormGroup>
                        ))}
                      </CardBody>
                    </>
                  ) : null}
                </Card>
              </div>
            </TabPane>
          )}
        </Tabs>
        <div className="form-row align-items-center">
          <Col className="m-2" sm="12" md="3">
            <Button
              className="btn-icon"
              color="info"
              onClick={onBack}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Regresar</span>
            </Button>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

CardEquipments.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  equipments: PropTypes.object.isRequired,
};

export default CardEquipments;
