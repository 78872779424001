// nodejs library that dates formats
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React from 'react';
// reactstrap components
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

const CardNotifications = props => {

  const {
    title,
    notifications,
    onSelectNotification
  } = props;

  return (
    <div className="card-deck flex-column flex-xl-row">
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">{title}</h5>
        </CardHeader>
        <CardBody>
          <ListGroup className="list my--3" flush>
            {
              notifications.map((row, key) => {
                return (
                  <ListGroupItem
                    className="list-group-item-action"
                    onClick={() => onSelectNotification(row)}
                    tag="a"
                    key={key}
                  >
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          alt="Notificación"
                          className="avatar rounded-circle"
                          src={require(`assets/img/theme/${row.type}.png`)}
                        />
                      </Col>
                      <div className="col ml--2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="mb-0 text-sm">{row.title}</h4>
                          </div>
                          <div className="text-right text-muted">
                            <small>{moment(row.createdAt).format('L')}</small>
                          </div>
                        </div>
                        <p className="text-sm mb-0">{row.description}</p>
                      </div>
                    </Row>
                  </ListGroupItem>
                )
              })
            }
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  );
};

CardNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default CardNotifications;