import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const dataRequestDashboardContracts = handleActions({
  [constants.GET_ALL_INFORMATION_CARDS_AND_CHART]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CLEAR_DATA]: () => { return {} }
}, {});
export const dataRequestYearAndCountries = handleActions({
  [constants.GET_ALL_INFORMATION_YEAR_AND_COUNTRIES]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CLEAR_DATA]: () => { return {} }
}, {});

export const requestManagers = handleActions({
  [constants.GET_ALL_INFORMATIONS_DIGITAL_REQUESTS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CLEAR_DATA]: () => { return {} },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const dataRequestManagers = handleActions({
  [constants.GET_ALL_DATA_BY_DIGITAL_REQUESTS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CLEAR_DATA]: () => { return {} },
  [constants.SIGN_OUT]: () => { return {} },
}, {});
