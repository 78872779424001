/*!

=========================================================
*MasterData - MaintenanceWindowMasterData
=========================================================

*Componente main donde se agrupan todos los cards de mantenimiento
de formularios, aprobaciones, plantillas en el portal de Datos
Maestros.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//#region Imports de librerías externas.

//Imports del Framework de React.
import React, { useState, useEffect } from "react";

//Imports de la Librería raíz Reactstrap.
import {
  PaginationItem,
  PaginationLink,
  Pagination,
  Table,
  Col,
  Row
} from "reactstrap";

//#endregion

//#region Imports de componentes internos.

import { Approvals } from "./Approvals";
import Templates from "./Templates";
import MaintainView from "components/NewMasterData/MaintenanceWindow/MaintainView.jsx";


//#region icons
import { MdComputer } from "react-icons/md";
import { IoIosContact } from "react-icons/io";
import { FaTools } from "react-icons/fa";
import { BiSolidContact } from "react-icons/bi";
import { RiContactsFill } from "react-icons/ri";
import { MdOutlineContactPhone } from "react-icons/md";
import { MdOutlineDevicesOther } from "react-icons/md";
import { TbDeviceImac } from "react-icons/tb";
import { FaServer } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import { GrDocumentVerified } from "react-icons/gr";
import { FaCheck } from "react-icons/fa";
import { SlPeople } from "react-icons/sl";
import { IoDocumentOutline } from "react-icons/io5";
import { CiServer } from "react-icons/ci";
import { PiComputerTowerLight } from "react-icons/pi";
import { IoCallOutline } from "react-icons/io5";
import { CustomCard } from "components/Shared/Cards/CustomCard";
//#endregion

//#endregion
const CardsMaintenance = ({ }) => {

  //Grupo de cards a desplegar.
  const [cards, setCards] = useState([
    {
      title: "Aprobadores",
      subtitle: "Mantenimiento de aprobadores",
      id: "approvals",
      image: "approvals2.png",
      admin: false,
      component: Approvals,
      icon: FaCheck 
    },
    {
      title: "Plantillas",
      subtitle: "Mantenimiento de las plantillas de másivo",
      id: "templates",
      image: "templates.png",
      admin: false,
      component: Templates,
      icon: GrDocumentVerified 
    },
    {
      title: "Materiales",
      subtitle: "Mantenimiento de los campos del formulario de Materiales",
      id: "materiales",
      image: "elearning.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "Tipo de Material",
          table: "materialType",
        },
        {
          label: "Grupo de Articulo",
          table: "materialGroup",
          isFactor: true
        },
        {
          label: "Grupo material 1",
          table: "materialGroup1"
        },
        {
          label: "Grupo material 2",
          table: "materialGroup2"
        },
        {
          label: "Grupo posición",
          table: "positionGroup"
        },
        {
          label: "Garantía",
          table: "warrantyType"
        }
      ],
      icon: MdComputer
    },
    {
      title: "Contratos - Ordenes de Servicio",
      subtitle: "Mantenimiento de los campos del formulario de Servicios",
      id: "servicios",
      image: "exam.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "Tipo de Servicio",
          table: "materialTypeService"
        },
        {
          label: "Grupo de Articulo",
          table: "materialGroup",
          isFactor: true
        },
        {
          label: "Unidad de medida",
          table: "meditUnit"
        },
        {
          label: "Service Profile",
          table: "serviceProfile"
        },
        {
          label: "Response Profile",
          table: "responseProfile"
        },
        {
          label: "Jerarquía",
          table: "hierarchy"
        },
        {
          label: "Grupo material 1",
          table: "materialGroup1"
        }
      ],
      icon: IoIosContact
    },
    {
      title: "Repuestos",
      subtitle: "Mantenimiento de los campos del formulario de Repuestos",
      id: "repuestos",
      image: "tool-box.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "Grupo de Articulo",
          table: "materialGroupSpartParts",
          isFactor: true
        }
      ],
      icon: PiComputerTowerLight
    },
    {
      title: "Clientes",
      subtitle: "Mantenimiento de los campos del formulario de Clientes",
      id: "clientes",
      image: "idea.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "País que envía la solicitud",
          table: "sendingCountry",
          isFactor: true,
        },
        {
          label: "Grupo de cliente",
          table: "databot_db.valueTeam",
          /*Exception */
          columnsException: [
            { value: "valueTeam", label: "Value Team", disabled: true, type: "input", editable: true },
            { value: "key", label: "Key", disabled: true, type: "input", editable: true }

          ]
        },
        {
          label: "Canal",
          table: "channel"
        },
        {
          label: "Tratamiento",
          table: "generalTreatment"
        },
        {
          label: "País",
          table: "country"
        },
        {
          label: "Región",
          table: "region",
          /*Exception */
          columnsException: [
            { value: "description", label: "Descripción", disabled: true, type: "input", editable: true },
            { value: "country", label: "País", disabled: true, type: "select", editable: true },
          ]
        },
        {
          label: "Sub Región",
          table: "subRegion",
          /*Exception */
          columnsException: [
            { value: "description", label: "Descripción", disabled: true, type: "input", editable: true },
            { value: "region", label: "Región", disabled: true, type: "select", editable: true },
          ]
        },
        {
          label: "Ramo",
          table: "branch"
        },
        {
          label: "Tipo de cliente (Facturación electrónica)",
          table: "clientType",
          /*Exception */
          columnsException: [
            { value: "description", label: "Descripción", disabled: true, type: "input", editable: true },
            { value: "country", label: "País", disabled: true, type: "select", editable: true },
          ]
        },
        {
          label: "Actividad Económica",
          table: "economicActivity"
        },
        {
          label: "Organización de Ventas",
          table: "salesOrganizations",
          /*Exception */
          columnsException: [
            { value: "name", label: "Nombre", disabled: true, type: "input", editable: true },
          ]
        },
        {
          label: "Distrito de Ventas",
          table: "salesDistrict"
        },
        {
          label: "Grupo de Clientes",
          table: "clientsGroup"
        },
        {
          label: "Customer Group",
          table: "customerGroup"
        },
        {
          label: "Centro suministrador",
          table: "supplyingCenter"
        },
        {
          label: "Tratamiento de Contacto",
          table: "contactTreatment"
        },
        {
          label: "Idioma",
          table: "language"
        },
      ],
      icon: BiSolidContact
    },
    {
      title: "Proveedores",
      subtitle: "Mantenimiento de los campos del formulario de Proveedores",
      id: "proveedores",
      image: "teacher.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "Sociedad donde se va a utilizar el proveedor",
          table: "databot_db.companyCode",
          /*Exception */
          columnsException: [
            { value: "name", label: "Nombre", disabled: true, type: "input", editable: true },
          ],
          isFactor: true,
        },
        {
          label: "Vendor Group",
          table: "vendorGroup"
        },
        {
          label: "Tratamiento",
          table: "generalTreatment"
        },
        {
          label: "País",
          table: "country"
        },
        {
          label: "Región",
          table: "region",
          /*Exception */
          columnsException: [
            { value: "description", label: "Descripción", disabled: true, type: "input", editable: true },
            { value: "country", label: "País", disabled: true, type: "select", editable: true },
          ]
        },
        {
          label: "Categoría del proveedor",
          table: "vendorCategory",
          /*Exception */
          columnsException: [
            { value: "description", label: "Descripción", disabled: true, type: "input", editable: true },
            { value: "codeDescription", label: "Descripción Código", disabled: true, type: "input", editable: true },
          ]
        },
        {
          label: "Tipo de proveedor",
          table: "vendorType",
          /*Exception */
          columnsException: [
            { value: "description", label: "Descripción", disabled: true, type: "input", editable: true },
            { value: "vendorGroup", label: "Vendor Group", disabled: true, type: "select", editable: true },
          ]
        },
        {
          label: "Nombre Banco Beneficiario",
          table: "bankName",
          /*Exception */
          columnsException: [
            { value: "description", label: "Descripción", disabled: true, type: "input", editable: true },
            { value: "companyCode", label: "Company Code", disabled: true, type: "select", editable: true },
          ]
        },
        {
          label: "Condición de pago",
          table: "paymentCondition"
        },
      ],
      icon: SlPeople
    },
    {
      title: "Contactos",
      subtitle: "Mantenimiento de los campos del formulario de Contactos",
      id: "contactos",
      image: "contacts.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "País que envia la solicitud",
          table: "sendingCountry",
          isFactor: true,
        },
        {
          label: "Tratamiento de Contacto",
          table: "contactTreatment"
        },
        {
          label: "País",
          table: "country"
        },
        {
          label: "Idioma",
          table: "language"
        },
        {
          label: "Función / Puesto",
          table: "position"
        },
      ],
      icon: IoCallOutline
    },
    {
      title: "Materiales de Servicios (PS)",
      subtitle: "Mantenimiento de los campos del formulario de Materiales de Terceros (LEIS)",
      id: "materialesservicio",
      image: "solar-panel.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "Grupo de Articulo",
          table: "materialGroup",
          isFactor: true,
        },
        {
          label: "Unidad de medida",
          table: "meditUnit"
        },
        {
          label: "Jerarquía",
          table: "hierarchy"
        },
        {
          label: "Grupo material 1",
          table: "materialGroup1"
        },
        {
          label: "Sector comercial",
          table: "commercialSector"
        },
      ],
      icon: MdOutlineDevicesOther
    },
    {
      title: "Equipos",
      subtitle: "Mantenimiento de los campos del formulario de Equipos",
      id: "equipos",
      image: "data-storage.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "País que envía la solicitud",
          table: "sendingCountry",
          isFactor: true,
        },
        {
          label: "Company Code",
          table: "databot_db.companyCode",
          /*Exception */
          columnsException: [
            { value: "name", label: "Nombre", disabled: true, type: "input", editable: true },
          ]
        },
      ],
      icon: TbDeviceImac
    },
    {
      title: "Ibase",
      subtitle: "Mantenimiento de los campos del formulario de Ibase",
      id: "ibase",
      image: "ibase.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "País que envía la solicitud",
          table: "sendingCountry",
          isFactor: true,
        },
        {
          label: "País GBM",
          table: "gbmCountries"
        },
        {
          label: "Método de Ingreso",
          table: "incomeMethod"
        },
        {
          label: "Acción",
          table: "action"
        },
      ],
      icon: CiServer
    },
    {
      title: "Garantías",
      subtitle: "Mantenimiento de los campos del formulario de Garantías",
      id: "garantias",
      image: "warranty.png",
      admin: false,
      component: MaintainView,
      fields: [
        {
          label: "País que envía la solicitud",
          table: "sendingCountry",
          isFactor: true,
        },
      ],
      icon: IoDocumentOutline
    },
  ]);

  //State para guardar el card seleccionado.
  const [selectedCard, setSelectedCard] = useState(null);

  //Función para abrir un componente de un card.
  const handleOpenForm = (card) => {
    setSelectedCard(card)
  };

  const handleOnBack = () => {
    setSelectedCard(null);
  };

  useEffect(() => {
    // console.log("CardsMaintenance", CardsMaintenance)
  }, [selectedCard])


  return (
    <>
      {selectedCard ?
        (<selectedCard.component
          //#region Props de aprobaciones

          //#endregion
          handleOnBack={handleOnBack}
          fields={selectedCard.fields}
          title={selectedCard.title}
        //#region Props de otros componentes

        //#endregion       
        />)
        : (
          <Row>
            {cards.map((card) => (
              <CustomCard
                title={card.title}
                subtitle={card.subtitle}
                onClick={() => handleOpenForm(card)}
                Icon={card.icon}
                xs={"6"}
                sm={"3"}

              />

              // <Col sm="14" md="3">
              //   <CardMain
              //     title={card.title}
              //     subtitle={card.subtitle}
              //     setFormSelected={() => handleOpenForm(card)}
              //     id={card.id}
              //     image={card.image}

              //   />
              // </Col>
            ))}
          </Row>
        )
      }
    </>
  );
};

export default CardsMaintenance;
