import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const usersDigitalSignature = handleActions({
  [constants.GET_USERS_WITH_ACCESS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const allYearSignatures = handleActions({
  [constants.GET_ALL_YEAR_SIGNATURES]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const dataByDashboardSignature = handleActions({
  [constants.GET_DATA_BY_DASHBOARD_SIGNATURE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.GET_DATA_DASHBOARD_BY_YEAR]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const registerSignature = handleActions({
  [constants.GET_DATA_REGISTER_SIGNATURE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CLEAR_DATA]: () => { return {} },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const createPolicySignature = handleActions({
  [constants.CREATE_POLICY_SIGNATURE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const signaturesUser = handleActions({
  [constants.GET_SIGNATURES_BY_USER]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const signaturesInFlow = handleActions({
  [constants.GET_SIGNATURES_PENDING_BY_SUPERVISOR]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const signaturesInFlowCollaborator = handleActions({
  [constants.GET_SIGNATURES_PENDING_BY_COLLABORATOR]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const signaturesFlowLogs = handleActions({
  [constants.GET_LOGS_FLOW_BY_SIGNATURE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const homeOfficeInfo = handleActions({
  [constants.GET_HOME_OFFICE_INFO_BY_SIGNATURE]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});