// core actions
import { createStatusContractOnHold, deactivateStatusByID, findAllStatusAvailable, updateStatusByID } from "actions/secoh.jsx";
// antd components
import { Spin } from "antd";
// core components
import Status from "components/SECOH/Status.jsx";
import StatusModal from "components/SECOH/StatusModal.jsx";
// core components Shared
import CardLoading from 'components/Shared/Cards/CardLoading.jsx';
import AdminHeader from 'components/Shared/Header/AdminHeader.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getStatusAvailableManagement } from "selectors/secoh.jsx";

class StatusMaintenance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      statusModal: {
        showModal: false,
        data: {},
        type: ""
      }
    };
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  componentWillMount() {
    this.props.findAllStatusAvailable().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({
        willMount: false,
      });
    });
  };

  findAllStatusAvailable = () => {
    this.props.findAllStatusAvailable().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnSetPage = page => {
    this.setState({
      page
    });
  };

  handleOnToggleModalStatus = () => {
    this.setState({
      statusModal: {
        showModal: false,
        data: {},
        type: ""
      }
    });
  };

  handleOnAddStatus = () => {
    this.setState({
      statusModal: {
        showModal: true,
        data: {},
        type: "add"
      }
    });
  };

  handleOnEditStatus = row => {
    this.setState({
      statusModal: {
        showModal: true,
        data: row,
        type: "edit"
      }
    });
  };

  handleOnSubmitAddStatus = (values, type) => {
    if (type === "add") {
      this.setState({
        loading: true
      });
      this.props.createStatusContractOnHold(values).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.findAllStatusAvailable();
          this.handleOnToggleModalStatus();
        }
        this.setState({
          loading: false,
        });
      });
    } else if (type === 'edit') {
      this.setState({
        loading: true
      });
      this.props.updateStatusByID(values.id, values).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.findAllStatusAvailable();
          this.handleOnToggleModalStatus();
        }
        this.setState({
          loading: false,
        });
      });
    }
  };

  onDeleteStatusByID = row => {
    this.setState({
      alert: null,
      loading: true,
      message: 'Eliminando el estado de la lista'
    });
    this.props.deactivateStatusByID(row.id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.findAllStatusAvailable();
      }
      this.setState({
        loading: false,
        message: ""
      });
    });
  };

  handleOnDeleteStatus = row => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de eliminar el estado?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() =>
            this.onDeleteStatusByID(row)
          }
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  render() {

    const {
      name,
      parentName,
      cols,
      states,
    } = this.props;

    const {
      page,
      alert,
      loading,
      willMount,
      statusModal,
      sizePerPage,
    } = this.state;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <StatusModal
          title="Gestión de Estados"
          options={statusModal}
          loading={loading}
          toggleModal={this.handleOnToggleModalStatus}
          onSubmit={this.handleOnSubmitAddStatus}
        />
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading}>
            {
              willMount ?
                <Row className="justify-content-center">
                  <Col>
                    <CardLoading title="Cargando la lista de los estados disponibles" />
                  </Col>
                </Row>
                :
                <Status
                  title="Estados disponibles"
                  subtitle="Gestión de estados disponibles para contratos On Hold"
                  cols={cols}
                  rows={states}
                  page={page}
                  sizePerPage={sizePerPage}
                  onEdit={this.handleOnEditStatus}
                  onDelete={this.handleOnDeleteStatus}
                  addStatus={this.handleOnAddStatus}
                  onSetPage={this.handleOnSetPage}
                />
            }
          </Spin>
        </Container>
      </>
    );
  }
}

StatusMaintenance.defaultProps = {
  name: "Mantenimiento de Estados",
  parentName: "Contratos On Hold",
  cols: [
    { key: "id", name: "# REF" },
    { key: "name", name: "Estado" },
    { key: "createdBy", name: "Creado Por" },
    { key: "updatedBy", name: "Actualizado Por" },
    { key: "createdAt", name: "Fecha de Creación" },
    { key: "updatedAt", name: "Fecha de Actualización" }
  ],
  states: [],
};

StatusMaintenance.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  states: getStatusAvailableManagement(state),
});

export default withRouter(connect(mapStateToProps, {
  findAllStatusAvailable,
  createStatusContractOnHold,
  updateStatusByID,
  deactivateStatusByID
})(StatusMaintenance));