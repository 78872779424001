// react library
import React, { useState, useEffect } from 'react';
// reactstrap components
import {
    Button, Col, FormGroup, Input, Row, Modal,
    ModalHeader,
    ModalBody, Table,
    ModalFooter, Form, Container, Card, CardBody
} from "reactstrap";
//Token 
import { useDispatch } from 'react-redux';
import { Upload, Icon } from 'antd';
import { Spin } from 'antd';
//actions for endpoint
import { uploadActa, deleteFile, getActaFile, deleteFileActa } from 'actions/Freelance.jsx';
//api urls for download
import urls from "api/urls.jsx";

import { encrypter } from 'helpers/desencrypt.jsx';

const { Dragger } = Upload;

const HitoUploadModal = ({
    setModal,
    showModal,
    dropDownOptions,
    setRealTable,
    Toast,
    MySwal,
    setRefreshOptions,
    row,
    actaFilesName
}) => {
    //#region Functions
    //#region initialize the modal with new data every time I click an element
    const [state, setState] = useState({
        unmountOnClose: true,
        fileList: [],
        readyToSend: false
    });

    const { unmountOnClose, fileList, readyToSend } = state;
    //#endregion

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(false);
    //#endregion

    //#region para cargar los archivos si es que ya tiene
    // const [actaFiles, setActaFiles] = useState([]);

    // useEffect(() => {


    // }, [])

    const downloadActa = (e, name) => {
        const downloadUrl = urls.FREELANCE.downloadFileByName + `/${encrypter(row.id)}/${encrypter(name)}`

        fetch(downloadUrl, {
            method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
        })
            .then(response => {
                if (response.ok) {
                    window.location.assign(downloadUrl);
                } else {
                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
                        type: "warning",

                    });
                }
            })
            .catch(error => {
                console.error('Error checking file existence:', error);
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrio un error al validar el archivo ",
                    type: "warning",

                });
            });
    }
    //#endregion

    //#region para eliminar acta de cierre
    const handleDeleteFile = (fileInfo) => {
        console.log(fileInfo)
        const fileName = fileInfo.name;
        dispatch(deleteFileActa({ id: fileInfo.id, poId: row.id, name: fileName, })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                console.log("Se borró el archivo del BE");
                // const docArray = row["documentFiles"].split(",");
                const resultJson = actaFilesName.filter((po) => po.name !== fileName);

                console.log(actaFilesName);
                console.log(resultJson);
                // setFiles(newDocArray);
                setModal((prevState) => ({
                    ...prevState,
                    actaFilesName: resultJson,
                }));
                // row["documentFiles"] = newDocArray.join(",");

            } else {
                Toast.fire({
                    title: "Atención",
                    html: "Error al intentar borrar el archivo",
                    type: 'error'
                });
            }
        })
    }
    //#endregion

    //#region funcion para cancelar o cerrar el modal
    const toggleCloseModal = () => {

        const { fileList } = state;
        if (fileList.length > 0) {
            dispatch(deleteFile({ id: row.id, name: fileList[0].name })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    console.log("Se borraron los archivos del BE")
                } else {
                    console.log("error al borrar los archivos del BE")
                }
            })
        }

        //limpiar el upload
        setState((prevState) => ({
            ...prevState,
            fileList: [],
            readyToSend: false
        }));

        setModal((prevState) => ({
            ...prevState,
            actaFilesName: [],
        }));

        setMountHito("");

        setModal((prevState) => ({
            ...prevState,
            showModalActaHito: !prevState.showModalActaHito,
            row: {}
        }));
    };
    //#endregion

    //#region upload dragger options and function
    //funcion para eliminar o subir nuevos archivos
    const handleOnFileList = (info) => {

        const { status } = info.file;
        //console.log(status);
        if (status === "removed") {
            const { file: { name, uid, response: { status, payload } } } = info;

            setState((prevState) => ({
                ...prevState,
                fileList: fileList.filter((row) => row.uid !== uid),
                readyToSend: false,
            }));



            dispatch(deleteFile({ id: row.id, name: name })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    if (fileList.length === 1) {
                        setState((prevState) => ({
                            ...prevState,
                            readyToSend: false,
                        }));
                    }
                } else {
                    console.log("error al intentar borrar el archivo")
                }
            })
            console.log(`El archivo ${name} fue quitado exitosamente.`);
        } else {
            const { fileList, file: { status, name } } = info;
            let response = "";
            if (status === 'done') {
                const { file } = info;
                response = file["response"];
                //habilitar el botón de enviar
                setState((prevState) => ({
                    ...prevState,
                    readyToSend: true,
                }));
            } else if (status === 'error') {
                console.log(`Ocurrio un error cargando el archivo ${name}.`);
            }

            setState((prevState) => ({
                ...prevState,
                fileList: fileList,
                readyToSend: true,
            }));
        }
    }
    const allowedFileTypes = [
        ".pdf"
    ];
    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: allowedFileTypes.join(","),
        multiple: true,
        action: `${urls.FREELANCE.uploadFile}/${row.id}`,
        beforeUpload: file => {
            const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
            if (!allowedFileTypes.includes(fileType)) {
                console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
                return false;
            }
            return true;
        },
        onChange: info => {
            console.log(info)
            // Filtrar la lista de archivos válidos para mantener solo los que aún existen
            const filteredFiles = info.fileList.filter(file => {
                console.log(file)
                if (file.status) {
                    return true;
                }
                return false;
            });
            info.fileList = filteredFiles;
            // Actualizar la lista de archivos válidos
            // Llamar a la función handleOnFileList con la lista de archivos válidos
            handleOnFileList(info);
        },
    };
    //#endregion

    //#region funcion para agregar el monto a un state 
    const [mountHito, setMountHito] = useState("");
    const handleChangeInfo = (valor) => {
        const { fileList } = state;
        if (fileList.length !== 0 || actaFilesName.length !== 0) {
            setState((prevState) => ({
                ...prevState,
                readyToSend: true
            }));
        }

        setMountHito(valor);
    }

    useEffect(() => {
        setMountHito(row.mountHito);
    }, [row]);
    //#endregion

    //#region funcion para terminar de subir la acta
    const handleUploadActa = () => {
        setLoad(true);
        // console.log(mountHito);
        if (mountHito === "" || mountHito === null) {
            Toast.fire({
                title: "Atención",
                html: "Por favor digite el monto del hito",
                type: 'warning'
            });
            setLoad(false);
            return;
        }
        const { fileList } = state;
        console.log(fileList);
        if (fileList.length === 0 && actaFilesName.length === 0) {
            Toast.fire({
                title: "Atención",
                html: "Adjunte el Acta de Cierre y el Contrato de la Orden",
                type: 'warning'
            });
            setLoad(false);
            return;
        }
        dispatch(uploadActa({ mountHito: mountHito, row: row, names: fileList })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {

                setRealTable(prevState => !prevState);
                //limpiar el upload
                setState((prevState) => ({
                    ...prevState,
                    fileList: [],
                    readyToSend: false
                }));

                setModal((prevState) => ({
                    ...prevState,
                    showModalActaHito: !prevState.showModalActaHito,
                    row: {}
                }));

                Toast.fire({
                    title: "Atención",
                    html: "Los documentos fueron envíados al proveedor para subir la factura",
                    type: 'success'
                });


            } else {

                Toast.fire({
                    title: "Atención",
                    html: payload.response.data.message,
                    type: 'warning'
                });
            }
            setLoad(false);

        })
    }
    //#endregion



    //#endregion

    return (
        <>
            <Modal
                size="lg"
                isOpen={showModal}
                unmountOnClose={unmountOnClose}
                toggle={toggleCloseModal}
            >
                <Spin size="large" spinning={loading}>
                    <ModalHeader>
                        Subir Acta de Cierre de la Orden de Compra por Hito
                    </ModalHeader>
                    <ModalBody className="mb-lg-0">
                        <Row>
                            <Col xs="4">
                                <Input
                                    key={"mountHito"}
                                    id={"mountHito"}
                                    className="form-control"
                                    type={"text"}
                                    placeholder={"Ingrese el monto del Hito"}
                                    defaultValue={row && row.mountHito}
                                    onChange={(e) =>
                                        handleChangeInfo(e.target.value)
                                    }
                                />
                                <div className="invalid-feedback">
                                    {"Por favor ingrese el monto del Acta"}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6">
                                <Table
                                    className="align-items-center table-flush"
                                    responsive
                                    striped={true}
                                    hover={true}
                                    style={{ marginTop: "7px" }}
                                >
                                    <tbody className="list">

                                        {
                                            actaFilesName.map((item, key) => (
                                                <>

                                                    <tr key={key}>
                                                        <td>
                                                            <a href="#" onClick={(e) => downloadActa(e, item.name)}>Descargar Documento: {item.name}</a>
                                                        </td>

                                                        <td>
                                                            <a onClick={(e) => handleDeleteFile(item)} >
                                                                <span className="">
                                                                    <i className="fas fa-trash-alt" />
                                                                </span>
                                                            </a>
                                                        </td>


                                                    </tr>
                                                </>

                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                        <Row>
                            <Col>
                                <Form noValidate className="needs-validation formInfo">
                                    <Card className="shadow mb-lg-0">
                                        <CardBody>

                                            <Dragger {...uploadProps} fileList={fileList}>
                                                <p className="ant-uploading-drag-icon">
                                                    <Icon type="inbox" />
                                                </p>
                                                <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                                                <p className="ant-uploading-hint">
                                                    Puede seleccionar archivos de diferentes folders/carpetas
                                                </p>
                                            </Dragger>

                                        </CardBody>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        {
                            <Button
                                className="btn-icon"
                                color="success"
                                size="sm"
                                block
                                type="button"
                                disabled={(readyToSend) ? false : true}
                                onClick={(e) => handleUploadActa(e)}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-check" />
                                </span>
                                <span className="btn-inner--text">Subir Acta</span>
                            </Button>
                        }

                        <Button
                            className="btn-icon"
                            color="danger"
                            size="sm"
                            block
                            type="button"
                            onClick={(e) => toggleCloseModal()}
                        >
                            <span className="btn-inner--icon mr-">
                                <i className="ni ni-fat-remove" />
                            </span>
                            <span className="btn-inner--text">Cancelar</span>
                        </Button>
                    </ModalFooter>
                </Spin>
            </Modal>
        </>
    );

}

export default HitoUploadModal;