import React, { useState } from 'react';

import {
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Button,
    Table,
    UncontrolledTooltip,
    Input,
    CardHeader
} from "reactstrap";

const UserAccess = ({ titleTable, colums, rows, filters, handleOnViewModal, handleTakeInfoRow, handleOnInactiveUserRole, setPage, page, isEdit, isDelete, textButtonAdd }) => {



    //#region Pagination


    const [state, setState] = useState({
        sizePerPage: 10,
    });
    const { sizePerPage } = state;
    const paginations =
        rows.length > sizePerPage
            ? rows.length / sizePerPage > Math.round(rows.length / sizePerPage)
                ? Math.round(rows.length / sizePerPage) + 1
                : Math.round(rows.length / sizePerPage)
            : 1;

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink onClick={() => handleOnSetPage(i, "page")}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return options.length >= 5
            ? page >= 5
                ? options.slice(page - 3, page + 2)
                : options.slice(0, 5)
            : options;
    };
    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);
    };

    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            sizePerPage: value,
        }));
    };
    //#endregion

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="justify-content-between" style={{ marginRight: '2vh' }}>
                        <Col sm="12" md="10">
                            <h4 className="surtitle">{titleTable}</h4>
                        </Col>
                        <Col sm="12" md="2">
                            <Button
                                className="btn-icon sm"
                                color="dark"
                                block
                                type="button"
                                onClick={() => handleOnViewModal()}
                            >
                                <span className="btn-inner--icon mr-1" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                    <i className="fas fa-plus"></i>
                                </span >{textButtonAdd}
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table
                        className="align-items-center table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">
                            <tr>
                                {colums.map((item, key) => {
                                    return (
                                        item.isTable === true && (
                                            <th key={key} className="justify-content-md-center ">
                                                <FormGroup key={key} >
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${item.label}:`}
                                                    </label>
                                                    <Input
                                                        id={item.id}
                                                        key={key}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder={`Buscar ${item.label}...`}
                                                        onChange={(e) => filters(item.value, e.target.value)}
                                                    />
                                                </FormGroup>
                                            </th>
                                        )
                                    )
                                })}
                                {
                                    isEdit === true && <th>Editar</th>
                                }
                                {
                                    isDelete === true && <th>Eliminar</th>
                                }
                            </tr>
                        </thead>
                        <tbody className="list">
                            {rows && rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((item, key) => (
                                <tr key={`tr${key}$`} >
                                    {
                                        colums.map((col, index) => (
                                            col.isTable === true && (
                                                <td key={`td${index}$`} >
                                                    {
                                                        item[col.value]
                                                    }
                                                </td>
                                            )

                                        ))
                                    }
                                    {
                                        isEdit === true &&
                                        <td key={`td1${key}$`} className="text-center">
                                            <Button
                                                key={key}
                                                className="btn"
                                                color="secondary"
                                                id="botonTool"
                                                onClick={() => handleTakeInfoRow(item)}
                                            >
                                                <span className="btn-inner--icon mr-">
                                                    <i className="far fa-edit"></i>
                                                </span>
                                                <span className="btn-inner--text"></span>
                                            </Button>
                                            <UncontrolledTooltip key={`tool1${key}$`} delay={0} target="botonTool">
                                                Editar
                                            </UncontrolledTooltip>
                                        </td>
                                    }
                                    {
                                        isDelete === true &&

                                        <td key={`td2${key}$`} className="text-center">
                                            <Button
                                                key={key}
                                                className="btn"
                                                color="danger"
                                                id="botonTool"
                                                onClick={() => handleOnInactiveUserRole(item)}
                                            >
                                                <span className="btn-inner--icon mr-">
                                                    <i className="fas fa-trash-alt"></i>
                                                </span>
                                                <span className="btn-inner--text"></span>
                                            </Button>
                                            <UncontrolledTooltip key={`tool2${key}$`} delay={0} target="botonTool">
                                                Eliminar
                                            </UncontrolledTooltip>
                                        </td>
                                    }

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Row className="align-items-center">
                            <Col xs="12" md="6" className="p-1">
                                <Row className="justify-content-start">
                                    <Col xs="12" md="6">
                                        <span className="pagination mb-0">
                                            Mostrando del {(page - 1) * sizePerPage + 1} al{" "}
                                            {page * sizePerPage > rows.length
                                                ? rows.length
                                                : page * sizePerPage}{" "}
                                            de {rows.length} resultados
                                        </span>
                                    </Col>
                                    <Col xs="12" md="2">
                                        <Input
                                            type="select"
                                            onChange={(e) => handleOnSetNumPagination(e)}
                                        >
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" md="6" className="p-1">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={page === 1 ? "disabled" : ""}>
                                        <PaginationLink
                                            onClick={() =>
                                                handleOnSetPage(page === 1 ? page : page - 1, "page")
                                            }
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {renderPaginations()}
                                    <PaginationItem
                                        className={page === paginations ? "disabled" : ""}
                                    >
                                        <PaginationLink
                                            onClick={() =>
                                                handleOnSetPage(
                                                    page === paginations ? page : page + 1,
                                                    "page"
                                                )
                                            }
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </nav>
                </CardFooter>
            </Card>
        </div>
    );
};

export default UserAccess;