import React, { useRef, useEffect, useState } from 'react';
import { Container } from "reactstrap";
import UserAccess from 'components/Shared/UserAccess/UserAccess.jsx';
import ModalUserAccess from 'components/Shared/UserAccess/ModalUserAccess.jsx';
import { useDispatch } from "react-redux";
import { getData, newUserRole, inactiveRoleByUser, updateUserRoleByUser } from "actions/BusinessSystem"
import { Spin } from 'antd';
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import NotificationAlert from "react-notification-alert";
import { desencrypt } from 'helpers/desencrypt.jsx';

const AccessManagement = () => {
    const dispatch = useDispatch();
    const notifyEl = useRef(null);
    const [filtersByTable, setFiltersByTable] = useState([]);
    const [loading, setLoad] = useState(true);
    const [modalUserAccess, getModalUserAccess] = useState({
        create: false,
        edit: false
    });
    const [userInfo, setUserInfo] = useState([]);
    const [newInfo, setNewInfo] = useState([]);
    const [valueSelect, setValueSelect] = useState(
        {
            countryOA: [],
            statusReports: [],
            statusOrderAssign: [],
            roles: [],
            users: []
        }
    )
    const [page, setPage] = useState(1);
    const optionsRoles = [
        { id: 177, label: "BS Ventas" },
        { id: 178, label: "BS Admin" },
        { id: 179, label: "BS Pais" },
    ]
    const tableAdmin = [
        { label: "Nombre", value: "name", insertValue: "name", colWidth: "12", type: "select", options: valueSelect.users, isTable: true, filter: true },
        { label: "Role", value: "role", insertValue: "fk_Permissions", colWidth: "12", type: "select", options: optionsRoles, isTable: true, filter: true },
        { label: "Pais", value: "country", insertValue: "country", colWidth: "12", type: "selectMulti", options: valueSelect.countryOA, isTable: false, filter: true },
    ]

    //Carga la data inicial de las vistas
    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetInfoTables();
        };
        getInfo();
    }, []);
    //Trae la informacion de los endpoints de las tablas y los selects
    const handleOnGetInfoTables = async () => {
        setLoad(true);
        dispatch(getData()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let data = desencrypt(payload.data.payload.masterData);
                data = JSON.parse(data)
                setValueSelect((prevState) => ({
                    ...prevState,
                    countryOA: data.Countries,
                    statusReports: data.StatusReports,
                    StatusOrderAssign: data.StatusOrderAssign,
                    roles: data.roles,
                    users: data.users
                }));
            }
            setLoad(false);
        });
    };
    //Aplica los filtros a la tabla principal
    const renderRowsByFiltersUsers = (rows) => {

        if (Object.keys(filtersByTable).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filtersByTable);
            for (const element of filterKeys) {
                const valueFiltered = filtersByTable[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    //Guarda los filtros en un state
    const handleOnfilterDinamic = (constant, e) => {
        setFiltersByTable(prevState => ({
            ...prevState,
            [constant]: `${e}`
        }))
        setPage(1);
    }
    //Abrir modal y cerrar modal de creación
    const viewModalNewUser = () => {
        getModalUserAccess(prevState => ({
            ...prevState,
            create: !modalUserAccess.create
        }))
    }
    //Abrir y cerrar modal de edicion de usuario
    const viewModalEditUser = () => {
        getModalUserAccess(prevState => ({
            ...prevState,
            edit: !modalUserAccess.edit
        }))
    }
    //Cambia los campos en el modal de creación
    const handleOnChangeData = (constant, value) => {
        setNewInfo(prevState => ({
            ...prevState,
            [constant]: value
        }))
    }
    //Cambia los campos en el modal de edicion
    const handleOnChangeDataEdit = (constant, value) => {
        setUserInfo(prevState => ({
            ...prevState,
            [constant]: value
        }))
    }
    //Toma la información de la linea que se esta editando
    const handleTakeInfoRow = (info) => {
        setUserInfo(info);
        getModalUserAccess(prevState => ({
            ...prevState,
            edit: !modalUserAccess.edit
        }))
    }
    //Insertar nuevo rol de BS a usuario
    const insertNewRoleUser = async () => {
        setLoad(true);
        await dispatch(newUserRole({ newInfo })).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("warning", "Atención", payload.response.data.payload.message);

            } else {
                notify("success", "Exito", payload.data.payload.message);
                handleOnGetInfoTables();
            }
            getModalUserAccess(prevState => ({
                ...prevState,
                create: !modalUserAccess.create
            }))
            setNewInfo("");
            setLoad(false);
        });
    }
    //Inactiva el role del usuario seleccionado
    const handleOnInactiveUserRole = (item) => {
        setLoad(true);
        dispatch(inactiveRoleByUser({ item })).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("warning", "Atención", payload.response.data.payload.message);
            } else {
                notify("success", "Exito", payload.data.payload.message);
                handleOnGetInfoTables();
            }

            setLoad(false);
        });
    }
    //Actualiza el role del usuario seleccionado
    const handleOnUpdateUserRoleByUser = () => {
        setLoad(true);
        // userInfo["fk_Permissions"] = userInfo["role"];
        dispatch(updateUserRoleByUser({ userInfo })).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("warning", "Atención", payload.response.data.payload.message);
            } else {
                notify("success", "Exito", payload.data.payload.message);
                handleOnGetInfoTables();
            }
            getModalUserAccess(prevState => ({
                ...prevState,
                edit: !modalUserAccess.edit
            }))
            setLoad(false);
        });
    }
    //Notificacion de alertas
    const notify = (type, title, message) => {
        try {
            let options = {
                place: "br",
                message: (
                    <div className="alert-text">
                        <span className="alert-title" data-notify="title">
                            {title}
                        </span>
                        <span data-notify="message">{message}</span>
                    </div>
                ),
                type: type,
                icon: "ni ni-bell-55",
                autoDismiss: 7,
            };
            notifyEl.current.notificationAlert(options);
        } catch (error) { }
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notifyEl} />
            </div>
            <AdminHeader name="Administración de Accesos" parentName="Pedidos para inventario" />
            <Container className="mt--6" fluid>
                <Spin size="large" spinning={loading}>
                    <UserAccess
                        titleTable={"Accesos de los usuarios"}
                        textButtonAdd ={"Agregar acceso"}
                        colums={tableAdmin}
                        rows={renderRowsByFiltersUsers(valueSelect.roles)}
                        filters={handleOnfilterDinamic}
                        handleOnViewModal={viewModalNewUser}
                        handleTakeInfoRow={handleTakeInfoRow}
                        handleOnInactiveUserRole={handleOnInactiveUserRole}
                        page={page}
                        setPage={setPage}
                        isDelete={true}
                        isEdit={true}
                    />
                    <ModalUserAccess
                        handleOnViewModal={viewModalNewUser}
                        data={tableAdmin}
                        openModal={modalUserAccess.create}
                        textModal={"Agregar role a usuario"}
                        changeData={handleOnChangeData}
                        saveButton={insertNewRoleUser}
                    />
                    <ModalUserAccess
                        handleOnViewModal={viewModalEditUser}
                        data={tableAdmin}
                        openModal={modalUserAccess.edit}
                        textModal={"Editar role a usuario"}
                        changeData={handleOnChangeDataEdit}
                        userInfo={userInfo}
                        saveButton={handleOnUpdateUserRoleByUser}
                    />
                </Spin>
            </Container>
        </div>
    );
};


export default AccessManagement;