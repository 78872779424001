/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Datos Generales en la solicitud de freelance
=========================================================

* Coded by Sergio Marin  

*/
import React, { useState } from "react";
import { Card, CardTitle, Label, Col, Row, Input, FormGroup, FormFeedback, FormText, Button } from "reactstrap";

const GenDataFreelance = ({ isVisible, requiredJson, data, optionsJson, setData, disabledFieldsJson, checkIdColab, enableComments }) => {
    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff' };
    const groupStyle = { marginBottom: '0.1rem' };
    const labelStyle = { marginBottom: '0.1rem' };

    const forms = [
        {
            type: "text",
            label: "Id Colaborador",
            id: "employeeId",
            maxLength: 10,
            colWidth: 4,
            elementType: "Input",
            required: true,
            disabled: false,
        },
        {
            type: "text",
            label: "Nombre",
            id: "firstName",
            colWidth: 4,
            elementType: "Input",
        },
        {
            type: "text",
            label: "Apellidos",
            id: "lastName",
            colWidth: 4,
            elementType: "Input",
        },
        {
            type: "select",
            label: "Área Personal",
            id: "personalArea",
            colWidth: 4,
            elementType: "Input",
        },
        {
            type: "select",
            label: "Tipo Plaza",
            id: "jobTypeFreelance",
            colWidth: 4,
            elementType: "Input",
        },
        {
            type: "select",
            label: "País",
            id: "country",
            colWidth: 4,
            elementType: "Input",
        },
        {
            type: "date",
            label: "Fin Contrato",
            id: "endDate",
            colWidth: 4,
            required: true,
            disabled: false,
        },
        {
            type: "textarea",
            label: "Observaciones (max 500 caracteres) ",
            id: "genDataComments",
            colWidth: 8,
            elementType: "Input",
            disabled: enableComments,
            maxLength: 500
        }
    ]
    //FUNCIONES
    const [checkColab, SetCheckColab] = useState(0); //state del boton de comprobar
    const disabled = disabledFieldsJson && disabledFieldsJson.includes("GenDataFreelance");//la seccion es disabled?

    //actualiza la varible data cada que se escribe algo
    const handleOnChange = (id, e) => {
        e.persist();
        setData(prevState => ({ ...prevState, [id]: e.target.value }));
    }

    //boton de comprobarID
    const handleCheckButton = async (employeeId) => {
        const validId = await checkIdColab(employeeId);
        if (validId) { SetCheckColab(2); }
        else { SetCheckColab(1); }
    };

    //RETURN
    return (
        <>
            {isVisible && (
                <Card>
                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Datos Generales
                    </CardTitle>

                    <Row style={{ padding: '0px 25px 0px' }}>
                        {/* ID COLAB */}
                        <Col xs={forms[0].colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={forms[0].id} style={labelStyle}>
                                    {forms[0].label}
                                    {forms[0].required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    type={forms[0].type}
                                    id={forms[0].id}
                                    maxLength={forms[0].maxLength}
                                    disabled={(disabledFieldsJson && disabledFieldsJson.includes("GenDataFreelance"))|| (data.requestState === "Modificacion")}
                                    invalid={requiredJson && requiredJson.includes(forms[0].id)}
                                    className="form-control"
                                    onChange={(e) => handleOnChange(forms[0].id, e)}
                                    value={data[forms[0].id]}
                                />
                                <FormFeedback>
                                    Este campo no puede estar vacío
                                </FormFeedback>
                            </FormGroup>
                        </Col>

                        {/* boton comprobar */}
                        {!(data.requestState === "Modificacion") && (!disabled && (
                            <>
                                <Col xs="2">
                                    <Button
                                        color="info"
                                        onClick={() => handleCheckButton(data.employeeId)}
                                        style={{ marginTop: '25px' }}>
                                        Comprobar Id de Colaborador
                                    </Button>
                                </Col>

                                {checkColab === 2 ? (
                                    <Col
                                        xs="2"
                                        style={
                                            {
                                                fontWeight: 'bold',
                                                color: "red",
                                                display: 'flex',
                                                flexDirection: 'column-reverse',
                                                alignItems: 'flex-start',
                                                marginBottom: "2px"
                                            }
                                        }
                                    >
                                        El Id de colaborador ya existe
                                    </Col>
                                ) : checkColab === 1 ? (
                                    <Col
                                        xs="2"
                                        style={
                                            {
                                                fontWeight: 'bold',
                                                color: "green",
                                                display: 'flex',
                                                flexDirection: 'column-reverse',
                                                alignItems: 'flex-start',
                                                marginBottom: "2px"
                                            }
                                        }
                                    >
                                        El Id de colaborador esta disponible
                                    </Col>
                                ) : (<></>)
                                }
                            </>
                        ))}
                    </Row>

                    <Row style={{ padding: '0px 10px 0px' }}>
                        {
                            forms.slice(1, 8).map((formsJson, index) => {

                                //const isRequired = requiredJson && requiredJson.find(item => item.id === formsJson.id)?.required === 'true';
                                return (
                                    <Col key={index} xs={formsJson.colWidth} style={formsJson.style}>
                                        <div style={{ padding: '0px 15px 0px' }}>

                                            <FormGroup style={{ ...groupStyle }}>
                                                <Label htmlFor={formsJson.id} style={labelStyle}>{formsJson.label}
                                                    {formsJson.required && <span style={{ color: 'red' }}> *</span>}
                                                </Label>

                                                {formsJson.type === "select" ? (

                                                    <Input
                                                        type="select"
                                                        id={formsJson.id}
                                                        invalid={requiredJson && requiredJson.includes(formsJson.id)}
                                                        name={formsJson.name}
                                                        disabled={(disabledFieldsJson && disabledFieldsJson.includes("GenDataFreelance"))}
                                                        className="form-control"
                                                        key={index}
                                                        onChange={(e) => handleOnChange(formsJson.id, e)}
                                                        value={data[formsJson.id]}
                                                    >
                                                        <option></option>
                                                        {optionsJson[formsJson.id] && optionsJson[formsJson.id].map((optionsJson, index) => (
                                                            <option key={index} value={optionsJson.id}>{optionsJson.value}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <Input
                                                        type={formsJson.type}
                                                        placeholder={formsJson.placeHolder}
                                                        id={formsJson.id}
                                                        invalid={requiredJson && requiredJson.includes(formsJson.id)}
                                                        name={formsJson.name}
                                                        disabled={!formsJson.disabled && (disabledFieldsJson && disabledFieldsJson.includes("GenDataFreelance"))}
                                                        className="form-control"
                                                        key={index}
                                                        onChange={(e) => handleOnChange(formsJson.id, e)}
                                                        value={data[formsJson.id]}
                                                    />

                                                )}

                                                <FormFeedback>
                                                    Este campo no puede estar vacío
                                                </FormFeedback>
                                            </FormGroup>

                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>

                    <Row style={{ padding: '0px 10px 0px' }}>
                        <Col xs="4" >
                            <FormGroup style={{ padding: '10px 10px 0px' }}>

                                <Label for="changedBy">Completado</Label>
                                <Input
                                    type="text"
                                    id="changedBy"
                                    disabled
                                    className="form-control"
                                    value={data.hcApprover}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );

}
export default GenDataFreelance;