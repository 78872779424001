/*!

=========================================================
*MasterData - PermissionItem
=========================================================

*Componente donde se gestiona cada fila de permisos, con 
su gestión de facores, permisos, y usuarios.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//#region Imports de librerías externas.

//Imports del Framework de React.
import React, { useState, useContext, useRef, useEffect } from "react";

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de la Librería raíz Reactstrap.
import {
    InputGroupAddon,
    InputGroup,
    FormGroup,
    Button,
    Input,
    Col,
    Row

} from "reactstrap";

//Import de Spinner
import { Spin } from "antd";

// react plugin for creating notifications over the dashboard
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Typeahead } from "react-bootstrap-typeahead";
import { truncate } from "lodash";
import { reporting } from "actions/DocumentSystem";

//#endregion

//#region Imports de componentes internos.

//#endregion


export const PermissionItem = ({ permission, setPermissionsRequests, keyPermission, permissionsRequests }) => {

    //#region States Globales
    const {
        options,
        employees

    } = useContext(UserContext);
    //#endregion

    //Extracción de librería swal.
    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });


    //#region Consultas al API.


    //#endregion


    //Asignación de Campos del form.
    const [fieldsForm, setFieldsForm] = useState([
        {
            colWidth: "4",
            label: "Usuario*",
            placeholder: "Digite el usuario",
            id: "employee",
            options: "employees",
            type: "typeahead",
            required: true,
            disabled: false,
        },
        {
            colWidth: "4",
            label: "Permiso*",
            placeholder: "Seleccione el permiso",
            idFill: '',
            id: 'permission',
            options: 'permissions',
            defaultValue: '',
            type: "select",
            required: true,
            disabled: false,
        },
        {
            colWidth: "3",
            label: "Factor*",
            placeholder: "Seleccione el factor",
            idFill: '',
            id: 'factor',
            options: 'factor',
            defaultValue: '',
            type: "manageFactors",
            required: true,
            disabled: false,

            /*Esta excepción es para que las opciones del campo de factor 
            se filtren con las opciones que sean igual a la respectiva tabla.
            
            Ejemplo: 
            Si el usuario selecciona el permiso MasterDataClientesBilling, entonces 
            en el dropdown de factor van a aparecer las factores que tengan en común 
            con la tabla sendingCountry.
            */
            filterOptionsByPermission: {
               /*"Master Data Clientes Billing"*/ 157: "sendingCountry",
                /*Master Data Clientes Controller*/ 159: "sendingCountry",
                /*Master Data Clientes Price List*/ 160: "sendingCountry",
                /*Master Data Clientes Sales Manager*/ 158: "sendingCountry",
                /*Master Data Materiales*/ 156: "materialGroup",
                /*Master Data Materiales de Servicio*/ 163: "materialGroup",
                /*Master Data Proveedores Accountants*/ 166: "companyCode",
                /*Master Data Proveedores Manager*/ 167: "companyCode",
                /*Master Data Repuestos*/ 165: "materialGroupSpartParts",
                /*Master Data Sales Admin*/ 168: "sendingCountry",
                /*Master Data Servicios*/ 164: "materialGroup",
            }
        },
    ]);



    //#region Handles para la gestión del permiso.

    //Handle para agregar un nuevo factor al PermissionItem actual.
    const handleAddFactor = () => {


        let permissionsRequestsAux =
            permissionsRequests.map((permissionMap, keyPermissionMap) => {
                if (keyPermission === keyPermissionMap) { //=> Hallar el key actual.
                    return {
                        ...permissionMap,
                        factors: [...permissionMap.factors,
                        { //=> Asigna un nuevo factor a la lista.
                            label: "", value: ""
                        }
                        ]
                    }
                }
                return permissionMap
            })

        setPermissionsRequests(permissionsRequestsAux);

    }

    //Handle para eliminar un factor al PermissionItem actual.
    const handleDeleteFactor = (keyToDelete) => {


        let permissionsRequestsAux =
            permissionsRequests.map((permissionMap, keyPermissionMap) => {
                if (keyPermission === keyPermissionMap) { //=> Hallar el key actual.
                    return {
                        ...permissionMap,
                        factors:

                            //=> Eliminar el factor del key.
                            permissionMap.factors.filter((factorFilter, factorFilterKey) => factorFilterKey != keyToDelete)

                    }
                }
                return permissionMap
            })

        setPermissionsRequests(permissionsRequestsAux);

    }


    //Handle para cambiar el factor de un permission.
    const handleChangeFactor = (keyFactorToChange, valueToReplace) => {


        let permissionsRequestsAux =
            permissionsRequests.map((permissionMap, keyPermissionMap) => {
                if (keyPermission === keyPermissionMap) { //=> Hallar el key del permission item actual.
                    return {
                        ...permissionMap,
                        factors:
                            //=> cambiar el factor del keyFactorToChange.
                            permissionMap.factors.map((factorMap, factorMapKey) => {
                                if (keyFactorToChange == factorMapKey) {
                                    return valueToReplace
                                }
                                return factorMap

                            })
                    }
                }
                return permissionMap
            })


        setPermissionsRequests(permissionsRequestsAux);

    }

    //Handle para cambiar la info de los demás campos que no son factor.
    const handleChangeInfo = (rowField, value) => {
        let permissionsRequestsAux =
            permissionsRequests.map((permissionMap, keyPermissionMap) => {
                if (keyPermission === keyPermissionMap) { //=> Hallar el key del permission item actual.
                    
                    if (rowField.id == "permission") { /*En este caso limpian los factors.*/
                    // debugger;    
                    return {
                            ...permissionMap,
                            [rowField.id]: value,
                            factors: [{ label: "", value: "" },]

                        }
                    } else {

                        return {
                            ...permissionMap,
                            [rowField.id]: value

                        }
                    }
                }
                return permissionMap
            })

        setPermissionsRequests(permissionsRequestsAux);
    }

    //Handle para eliminar la fila actual.
    const handleDeleteRow = () => {
        setPermissionsRequests(permissionsRequests.filter((permissionFilter, keyPermissionFilter) => keyPermissionFilter != keyPermission));

    }

    //Este método es para generar las opciones de factores con las opciones del permiso en común.
    const buildOptions = (factors, row) => {
        // debugger;
        let result = [];
        if (permission?.permission?.value != undefined) {
            result =
                factors.filter(localFactor =>

                    /*Averiguar las options*/
                    options[
                        /*sendingCountry */
                        row.filterOptionsByPermission[permission?.permission?.value /*157*/]]

                        .some(commonOption /*sendingCountryItem */ => {

                            return localFactor.label == commonOption.code
                        }
                        ))
        }


        return result
    }
    //#endregion


    return (

        <Row className="mr-2">

            {
                fieldsForm.map((row, key) =>
                    row.type === "select" ? (
                        //Este case es para traer las opciones completas ya que no dependen de ningun dropdown
                        <Col xs="12" sm={row.colWidth} key={key}>
                            <FormGroup>
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {row.label}
                                </label>

                                <Input
                                    key={row.id + key}
                                    //id={row.id + index}
                                    required={row.required}
                                    className="basic-multi-select text-dark"
                                    type={row.type}
                                    placeholder={row.placeholder}
                                    disabled={row.disabled}
                                    value={permission[row.id]?.label}
                                    onChange={(e) => handleChangeInfo(row, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id })}
                                // onChange={(e) => handleChangeInfo(row.id, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id })}
                                >
                                    <option id=""></option>
                                    {
                                        options[row.options] && options[row.options].map((value) => {
                                            return (
                                                <option id={value.value}>{value.label}</option>
                                            );

                                        })}
                                </Input>
                                <div className="invalid-feedback">
                                    Este campo no puede estar vacío
                                </div>

                            </FormGroup>
                        </Col>
                    ) : row.type === "manageFactors" ? (

                        // <h1>permissions</h1>
                        <Col xs="12" sm={row.colWidth} key={key}>
                            <FormGroup>
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {row.label}
                                </label>
                                {permission.factors.map((factor, keyFactor) => (

                                    <>

                                        <InputGroup className="input-group-merge">
                                            <Input
                                                key={key + keyFactor}
                                                className="basic-multi-select text-dark"
                                                type={"select"}
                                                placeholder={row.placeholder}
                                                value={factor.label}
                                                onChange={(e) => handleChangeFactor(keyFactor, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id })}

                                            >
                                                <option id=""></option>
                                                {
                                                    options[row.options]
                                                    &&
                                                    buildOptions(options[row.options], row)
                                                        .map((value) => {
                                                            return (
                                                                <option id={value.value}>{value.label}</option>
                                                            );

                                                        })}
                                            </Input>
                                            <InputGroupAddon size="sm" addonType="append">
                                                <Button color={"danger"}
                                                    size="sm"
                                                    outline
                                                    onClick={(e) => handleDeleteFactor(keyFactor)}
                                                    disabled={false}>
                                                    <span className="btn-inner--icon mr-">
                                                        <i className={"fa fa-times"} />
                                                    </span>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>

                                    </>


                                ))}
                            </FormGroup>
                        </Col>
                    ) : (
                        //Tipo Typeahead
                        <Col xs="9" sm={row.colWidth} key={key} className="">
                            <FormGroup >
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    {row.label}
                                </label>
                                <Spin size="small" spinning={false}>
                                    <Typeahead
                                        // id={row.id + index}
                                        id={row.id + keyPermission}
                                        labelKey={employees => `${employees.user}`}
                                        minLength={0}
                                        required={row.required}
                                        options={employees.filter((employee, employeeKey) => employeeKey != 0)} //Elimina el primer campo de telecoverage que viene por defecto.
                                        defaultInputValue={""}
                                        onChange={(e) => handleChangeInfo(row, e[0])}
                                        renderMenuItemChildren={(value) => (
                                            <div >
                                                {value.name}
                                                <div>
                                                    {
                                                        <small>User: {value.user}</small>}
                                                </div>
                                            </div>
                                        )}
                                    />
                                </Spin>
                                <div className="invalid-feedback">
                                    Este campo no puede estar vacío
                                </div>
                            </FormGroup>
                        </Col>
                    )
                )
            }

            <Col xs="2" sm="1" className=" d-flex align-items-center justify-content-start">
                <Button outline color="success" size="sm" style={{ borderRadius: "40px" }} onClick={() => handleAddFactor()} disabled={false}>
                    <span className="btn-inner--icon mr-">
                        <i className="fas fa-plus" />
                    </span>
                </Button>
                <Button outline color="warning" size="sm" style={{ borderRadius: "40px" }} onClick={() => handleDeleteRow()} disabled={false}>
                    <span className="btn-inner--icon mr-">
                        <i className="fas fa-trash" />
                    </span>
                </Button>
            </Col>





        </Row>



    )
}
