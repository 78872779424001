// import core action
import {
  findPersonalInfoCompleteByEmployee,
  findRelationships,
  findMaritalStatus,
  findFamilyGroupByEmployee,
  findEmergencyContactsByEmployee,
} from "actions/knowEmployee.jsx";
// antd components
import { Spin } from "antd";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// core components
import FormComplete from "components/KnowEmployee/FormComplete.jsx";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// react component used to create sweet alerts
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import ReactBSAlert from "react-bootstrap-sweetalert";
// nodejs library to set properties for components
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// core selectors
import {
  getPersonalInfoCompleteByEmployee,
  getRelationShips,
  getMaritalStatus,
  getFamilyGroup,
  getEmergencyContacts,
} from "selectors/knowEmployee.jsx";

class MyFormData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willmount: true,
      loading: false,
      message: null,
    };
  }

  componentDidMount() {
    this.findEmployeeFormInfo();
  }

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof MySignatures
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof MySignatures
   */
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  findEmployeeFormInfo = () => {
    this.props.findPersonalInfoCompleteByEmployee().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        const { personalInfo } = this.props;
        console.log(personalInfo);
        this.props.findFamilyGroupByEmployee(personalInfo.id);
        this.props.findEmergencyContactsByEmployee(personalInfo.id);
        this.notify("success", "Éxito", payload.message);
      }
      this.props.findRelationships();
      this.props.findMaritalStatus();
      this.setState((state) => ({
        willMount: false,
      }));
    });
  };

  handleOnSignatureForm = (values) => {
    console.log(values);
  };

  render() {
    const {
      name,
      parentName,
      personalInfo,
      maritalStatus,
      relationShip,
      familyGroups,
      emergyContacts,
    } = this.props;
    const { loading, message, willMount } = this.state;

    console.log(personalInfo);

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Spin size="large" spinning={loading} tip={message}>
          <Container className="mt--6" fluid>
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de formularios completados por los colaboradores" />
                </Col>
              </Row>
            ) : Object.keys(personalInfo).length ? (
              <FormComplete
                title="Completar Formulario"
                subtitle="Formulario Conocimiento de Empleado Debida Diligencia Estándar"
                initialValues={personalInfo}
                options={{
                  maritalStatus,
                  relationShip,
                  familyGroups,
                  emergyContacts,
                }}
                onSignature={this.handleOnSignatureForm}
              />
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No has completado tu formulario para visualizar."
                    subtitle="Actualmente al día de hoy, no hay un formulario completado para visualizar, cuando completes el formulario se enlistarán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Container>
        </Spin>
      </>
    );
  }
}

MyFormData.defaultProps = {
  name: "Formulario",
  parentName: "Conoce a tu Empleado",
  maritalStatus: [],
  relationShip: [],
  personalInfo: {},
  familyGroups: [],
  emergyContacts: [],
};

MyFormData.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  maritalStatus: PropTypes.array.isRequired,
  relationShip: PropTypes.array.isRequired,
  personalInfo: PropTypes.object.isRequired,
  familyGroups: PropTypes.array.isRequired,
  emergyContacts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  personalInfo: getPersonalInfoCompleteByEmployee(state),
  relationShip: getRelationShips(state),
  maritalStatus: getMaritalStatus(state),
  familyGroups: getFamilyGroup(state),
  emergyContacts: getEmergencyContacts(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findPersonalInfoCompleteByEmployee,
    findRelationships,
    findMaritalStatus,
    findFamilyGroupByEmployee,
    findEmergencyContactsByEmployee,
  })(MyFormData)
);
