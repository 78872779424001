/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la tabla consulta colaborador
=========================================================
 
* Coded by Eduardo Piedra Sanabria - Application Management GBM

* Modificado por Sergio Marin para It requests

*/


import React, { useState, useEffect } from 'react' //Imports de librerías de react.
import { PaginationItem, PaginationLink, Pagination, CardHeader, FormGroup, Label, Button, Input, Table, Badge, Card, Col, Row, Collapse } from "reactstrap";
import CardFooter from 'reactstrap/lib/CardFooter';
import { Spin } from "antd";//Import de Spinner
import { useDispatch } from 'react-redux';//Import para consulta  en DB
import { getTeamsUserAccess } from 'selectors/adminLayout';//Funciones para obtener el user actual
// import withReactContent from 'sweetalert2-react-content';// react plugin for creating notifications over the dashboard
// import Swal from 'sweetalert2';
import { getSearchRequests, getIdRequest } from 'actions/ItRequests';// imports de actions 
import AdvanceFilter from 'components/ItRequests/SearchRequests/AdvanceFilter';


const SearchRequests = ({ roles, setData, setShowMenu, setStep, setLoading, optionsJson }) => {

    const dispatch = useDispatch();//Declaración de disparador para el api

    //Columnas seleccionadas por defecto en la tabla.
    const [columnsSelectedTableRequests, setColumnsSelectedTableRequests] = useState(
        [
            { value: "employeeId", label: "Id Colaborador" },
            { value: "requestState", label: "Tipo Solicitud" },
            { value: "firstName", label: "Nombre" },
            { value: "lastName", label: "Apellido" },
            { value: "country", label: "País" },
            { value: "initialDate", label: "Fecha Creación " },
            { value: "requestEndDate", label: "Fecha Finalización" },
            { value: "displayName", label: "Flujo" },
            { value: "isFinished", label: "Estado Solicitud" },
            { value: "seeDetail", label: "Botón" },
        ]
    );

    //#region Funciones y efectos para abrir el modal y recarga las filas

    //Función que ejecuta el boton de detail
    const handleDetailButton = (row) => {
        setLoading(true);

        if (row.isFreelance === 1) {
            setStep("ReadRequestFreelance");
        }
        else {
            setStep("ReadRequest");
        };

        dispatch(getIdRequest(row.id)).then((resp) => {
            setData(resp.payload.data.request);
            setLoading(false);
            setShowMenu("request");
        });
    }

    //Efecto para cargar filas cuando arranque por primera vez.
    useEffect(() => {
        setReloadTableRequests(true);
    }, [])

    //#endregion

    //#region Funciones para aplicar flitros a la tabla

    //Filtros request tables
    const [filters, setFilters] = useState([]);

    //Capturar los valores de los inputs de los filtros
    const handleOnfilterDynamic = (constant, e) => {
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }

    //Aplicar los filtros a la data de la tabla
    const renderRowsByFilters = (rows) => {

        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);

            for (const element of filterKeys) {
                const valueFiltered = filters[element].toString().toLowerCase();

                rows = rows.filter(

                    (item) => {
                        let temp;

                        try {
                            temp = item[element]?.toString().toLowerCase().indexOf(valueFiltered);
                        } catch (e) { temp = -1 }

                        //Si se le da un espacio en el campo, imprima tdoos los vacios
                        if (valueFiltered === " ") {
                            return temp === undefined
                        } else { //No es un espacio, traiga todos los de la palabra clave
                            return temp != -1 && temp != undefined
                        }

                    }
                )
            }
            return rows
        }
    }

    //#endregion 

    //#region Extraer las filas de la tabla 

    //state para refrescar la tabla de Requests
    const [reloadTableRequests, setReloadTableRequests] = useState(false);

    const [rowsRequests, setRowsRequests] = useState({
        rows: [],
        statsBaw: [],
        length: 0,
    })

    //verifica si es rol admin
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        const userRol = getTeamsUserAccess();
        if (userRol.some((row) => row.indexOf("Master Data Admin") !== -1)) {
            setIsAdmin(true)
        }
    }, [])

    //Cuando reloadTableRequests es true, ejecuta este efecto 
    useEffect(() => {
        if (reloadTableRequests) {
            const getDataR = async () => {
                await getData();
            }
            getDataR();
        }
    }, [reloadTableRequests]);

    //Traer las filas de la tabla de mis gestiones en base al usuario. (Aqui esta el Action)
    const getData = async () => {

        //Filtro de los paises de los roles
        let ctrFilter = {};
        if (!(Array.isArray(roles.countries) && roles.countries.length === 1 && roles.countries[0] === '')) {
            ctrFilter = {
                country: roles.countries.map(item => ({
                    value: item,
                    label: ''
                }))
            };
        }

        dispatch(getSearchRequests(ctrFilter)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsRequests((e) => ({
                    ...e,
                    rows: payload.data.rows,
                    length: payload.data.rows.length,
                }))
            } else {
                setRowsRequests({
                    rows: [],
                    length: 0,
                })

            }

            setReloadTableRequests(false);
        })
    }

    //#endregion

    //#region Realizar paginations en la tabla

    const [sizePerPage, setSizePerPage] = useState(10)
    const rows = renderRowsByFilters(rowsRequests.rows);

    //state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);

    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Establece el número de filas por pagina 
    const handleOnSetPage = (page) => {
        setPage(page);
    };

    const handleSetNumPag = (e) => {
        setPage(1);
        const value = e.target.value;
        setSizePerPage(value);
    }

    //#endregion

    //#region Lo nuevo del buscador
    const [visibleFilter, setVisibleFilter] = useState(false);
    const showFilterOptions = () => setVisibleFilter(!visibleFilter);
    //#endregion

    return (
        <>
            {/* Menu del filtro */}
            <Collapse isOpen={visibleFilter}>
                <AdvanceFilter
                    roles={roles}
                    optionsJson={optionsJson}
                    rowsRequests={rowsRequests}
                    setReloadTableRequests={setReloadTableRequests}
                    setRowsRequests={setRowsRequests}
                    getSearchRequests={getSearchRequests}
                    setLoading={setLoading}
                />
            </Collapse>

            {/* La tabla */}
            <Card className=" ">
                <CardHeader className="bg-transparent border-0">
                    <Row className=" ">
                        <Col xs="7" sm="10" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <Col xs="auto" sm="auto" className="bg-transparent border-0">
                                <h3 className=" mb-0">
                                    Gestiones</h3>
                            </Col>
                        </Col>
                        <Col xs="auto" sm="1" className=" " style={{ display: "flex", justifyContent: "flex-end" }} >
                            <Button className="btn-info" size="sm" block type="button" style={{ width: "100px ", height: "30px" }}
                                onClick={showFilterOptions}
                            >
                                <span className="btn-inner--text">
                                    {" "}Filtro avanzado
                                </span>
                            </Button>
                        </Col>
                        <Col xs="auto" sm="1" className=" " style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button id="refreshBtn" className="btn-info" size="sm" block type="button" style={{ width: "100px ", height: "30px" }}
                                onClick={(e) => setReloadTableRequests(true)}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-sync-alt" />
                                </span>
                                <span className="btn-inner--text">
                                    {" "}Refrescar
                                </span>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>

                <Spin size="large" spinning={reloadTableRequests}>
                    <Table
                        className="align-items-center  table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">
                            <tr>
                                {
                                    columnsSelectedTableRequests.map((item, key) => {
                                        return (
                                            item.value !== "seeDetail" ?
                                                <th key={key} className="justify-content-md-center">
                                                    <div style={{ width: item.width }}>
                                                        <FormGroup key={key}>
                                                            <label
                                                                style={{ fontSize: "12px" }}
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {`${item.label}:`}
                                                            </label>
                                                            <Input
                                                                id={item.id}
                                                                key={key}
                                                                className="form-control"
                                                                type="text"
                                                                placeholder={`Buscar ${item.label}...`}
                                                                onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </th>
                                                :
                                                <th key={key} className="justify-content-md-center">
                                                </th>
                                        )
                                    })

                                }
                            </tr>
                        </thead>
                        <tbody className='list'>

                            {rows.length > 0 ?
                                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {
                                    return (
                                        <tr key={key}>
                                            {
                                                columnsSelectedTableRequests.map((col, keyCol) => {
                                                    return (
                                                        col.value === "Estado" ? (
                                                            <td key={key + keyCol} className="justify-content-md-center">
                                                                <div style={{ width: (isAdmin) ? 220 : col.width }}>
                                                                    {
                                                                        <Badge key={key + keyCol} color={row.statusType} pill>
                                                                            {row.Estado}
                                                                        </Badge>
                                                                    }

                                                                </div>
                                                            </td>
                                                        ) : col.value === "seeDetail" ? ( //Este status es para el botón de detalle
                                                            <td key={key + keyCol} className="justify-content-md-center">
                                                                <div style={{ width: col.width }}>
                                                                    <Row key={key + keyCol + "row"}>
                                                                        {row.statusId !== "1" && //No lo muestre cuando apenas se está generando la opp
                                                                            <Col key={key + keyCol + "col"}>

                                                                                <Button id="refreshBtn" className="btn-default" size="sm" block type="button" style={{ width: "80px ", height: "30px" }}
                                                                                    onClick={(e) => handleDetailButton(row)}
                                                                                >
                                                                                    <span className="btn-inner--icon mr-">
                                                                                        <i className="fas fa-edit" />
                                                                                    </span>
                                                                                    <span className="btn-inner--text">
                                                                                        {" "} Detalle
                                                                                    </span>
                                                                                </Button>
                                                                            </Col>
                                                                        }

                                                                    </Row>
                                                                </div>
                                                            </td>

                                                        ) : (

                                                            <td key={key + keyCol} className="text-center text-break" style={{ whiteSpace: "normal", textAlign: "auto" }}>
                                                                <div style={{ width: col.width }} >

                                                                    <span className="btn-inner--text">
                                                                        {row[col.value]}
                                                                    </span>

                                                                </div>
                                                            </td>
                                                        )
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                                :
                                <>
                                    <tr>
                                        <td className="justify-content-md-center">
                                            Sin resultados encontrados
                                        </td>
                                    </tr>
                                </>
                            }

                        </tbody>

                    </Table>
                </Spin>
                <CardFooter>

                    <Row className="align-items-center ">
                        <Col xs='12' md='6' className="p-1 ">
                            <Row className="justify-content-start">
                                <Col xs='12' md='6'>
                                    <span className='pagination mb-0'>
                                        Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                    </span>
                                </Col>
                                <Col xs='12' md='2'>
                                    <Input type="select"
                                        onChange={(e) => handleSetNumPag(e)}>
                                        <option>5</option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs='12' md='6' className="p-1 ">
                            <Pagination
                                className="pagination justify-content-end mb-0"
                                listClassName="justify-content-end mb-0"
                            >
                                <PaginationItem className={page === 1 ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                        tabIndex="-1"
                                    >
                                        <i className="fas fa-angle-left" />
                                        <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                </PaginationItem>
                                {
                                    renderPaginations()
                                }
                                <PaginationItem className={page === paginations ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                    >
                                        <i className="fas fa-angle-right" />
                                        <span className="sr-only">Next</span>
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        </>
    );
};

export default SearchRequests;