/*!

=========================================================
*Databot Dashboard
=========================================================

*Vista Principal "Home" la cual retorna:
    -CheckBox para apagar todos los bots
    -Modal para crear un nuevo robot
    -MandTable para des/activar los mandantes de SAP
    -CardsHeader: componente que imprime un Card por cada área del databot y una de SAP siempre fija
    -MainTable: componente que imprime una tabla con cada robot que hay en productivo
*Aqui se inicializan las variables globales que se pasan por props a los componentes
*Se extraen las áreas al inicio mediante un hook useEffect
*Se determina si el usuario es Admin 

=========================================================

* Coded by Diego Meza - Application Management GBM

*/

import React, { useState, useEffect } from 'react';
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Spin } from 'antd';
import { getTeamsUserAccess, getUsernameLogged } from 'selectors/adminLayout.jsx';
import { Card, CardHeader, Col, Container, Row, Button, } from "reactstrap";
import NotificationAlert from "react-notification-alert";
//Actions API
import { getDatabotAreas, updateRobot, activateRobot, getMandantes, getBots, updateMand, getDatabotOptions } from 'actions/databotDashboard.jsx';
//Componentes
import MainTable from 'components/Databot/MainTable.jsx';
import CardsHeader from 'components/Databot/CardsHeader.jsx';
import NewRobotModal from 'components/Databot/NewRobotModal.jsx';
import MandTable from 'components/Databot/MandTable.jsx';
import { set } from 'lodash';

const Orchestrator = () => {

    //#region funciones 
    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(true);
    //#endregion

    //#region Roles del usuario y data inicial
    const user = getUsernameLogged();
    //Estado para determinar si el usuario logeado es Administrador
    const [isAdmin, setIsAdmin] = useState(false);
    //Estado para guardar los registros de la tabla databotAreas
    const [databotAreas, setDatabotAreas] = useState([]);
    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion
    //Determina si el usuario logeado es admin
    useEffect(() => {
        const teams = getTeamsUserAccess();
        if (teams.some((row) => row.indexOf("Databot Orchestrator Admin") !== -1)) {
            setIsAdmin(true)
        }
        //extraer las areas del Databot
        dispatch(getDatabotAreas()).then((resp) => {
            const { payload } = resp;
            //console.log(resp)
            if (payload.status === 200) {
                setDatabotAreas(payload.data.areas)
                // setOptions(payload.data.payload.vendors)
            }

        })

        setLoad(false);
    }, []);
    //#endregion

    //#region Mensajes de notificacion
    const MySwal = withReactContent(Swal);
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    //#endregion

    //#region funcion para apagar/encender robot por id
    const turnOffOnBot = (e, row) => {
        e.persist();
        console.log(e.target.checked)
        MySwal.fire({
            title: `Atención`,
            icon: 'info',
            // width: 800,
            html: `Esta seguro(a) que desea ${(e.target.checked) ? "encender" : "apagar"} el robot ${row.class}?`,
            confirmButtonText: `Si, ${(e.target.checked) ? "encender" : "apagar"}`,
            confirmButtonColor: `${(e.target.checked) ? "#2dce89" : "#f5365c"} `,
            cancelButtonText: 'No, cancelar',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.value) {
                //por alguna razon cambia de booleano
                e.target.checked = !e.target.checked;
                console.log(e.target.checked)
                dispatch(updateRobot({ areaId: "bot", type: (e.target.checked) ? "1" : "0", id: row.id })).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {

                        Toast.fire({
                            title: `Se ${(e.target.checked) ? "encendió" : "apagó"} el robot ${row.class} correctamente`,
                            type: 'success'
                        })
                        setPage(1);
                        setRefreshInput(prevState => (!prevState))

                    } else {
                        Toast.fire({
                            title: payload.message,
                            type: 'warning'
                        })
                    }
                })
            }
        })

    }
    //#endregion

    //#region función para desactivar/activar robot por id
    const activateBot = (e, row) => {
        e.persist();
        console.log(e.target.checked)
        MySwal.fire({
            title: `Atención`,
            icon: 'info',
            // width: 800,
            html: `Está seguro(a) que desea ${(e.target.checked) ? "ejecutar en este momento" : "abortar la ejecución manual de"} el robot ${row.class}?`,
            confirmButtonText: `Si, ${(e.target.checked) ? "ejecutar ahora" : "abortar"}`,
            confirmButtonColor: `${(e.target.checked) ? "#2dce89" : "#f5365c"} `,
            cancelButtonText: 'No, cancelar',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.value) {
                //por alguna razon cambia de booleano
                e.target.checked = !e.target.checked;
                console.log(e.target.checked)
                dispatch(activateRobot({ status: (e.target.checked) ? "1" : "0", id: row.id })).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {

                        Toast.fire({
                            title: `Se ${(e.target.checked) ? "activó" : "canceló el activar el proceso de"} el robot ${row.class} correctamente`,
                            type: 'success'
                        })
                        setPage(1);
                        setRefreshInput(prevState => (!prevState))

                    } else {
                        Toast.fire({
                            title: payload.message,
                            type: 'warning'
                        })
                    }
                })
            }
        })

    }
    //#endregion

    //#region datos para la tabla (MainTable)
    //Estado para actualizar los datos de la tabla MainTable
    const [refreshInput, setRefreshInput] = useState(false);

    //Estado para guardar la página de la tabla
    const [page, setPage] = useState(1);
    //Columnas de la tabla principal de bots
    const [columns, setColumns] = useState([
        { value: "buttons", label: "Acciones", funcion: turnOffOnBot, type: "input", restrictOnlyForAdmin: true },
        { value: "botOn", label: "Encendido", id: "active", funcion: turnOffOnBot, type: "action", legend: "Encender la automatización de un bot del proyecto", ableOnlyForAdmin: false},
        { value: "botActivate", label: "Activar", id: "activate", funcion: activateBot, type: "action", legend: "Ignorar el planner y ejecutar un robot en este momento", ableOnlyForAdmin: false },
        { value: "projectName", label: "Proyecto", type: "input", restrictOnlyForAdmin: true },
        { value: "class", label: "Clase", type: "input" },
        { value: "areaCode", label: "Área", type: "input", restrictOnlyForAdmin: true },
        { value: "activeText", label: "Estado", type: "input", restrictOnlyForAdmin: true },
        { value: "activate", label: "Activar", type: "input", restrictOnlyForAdmin: true },
        { value: "sapText", label: "Usa SAP?", type: "input", restrictOnlyForAdmin: true },
        { value: "cDate", label: "Fecha de creación", type: "input", restrictOnlyForAdmin: true },
        { value: "createdBy", label: "Creador por", type: "input", restrictOnlyForAdmin: true },
        { value: "uDate", label: "Fecha de actualización", type: "input", restrictOnlyForAdmin: true },
        { value: "updatedBy", label: "Actualizado Por", type: "input", restrictOnlyForAdmin: true },
        { value: "departmentName", label: "Departamento", type: "input" },
        { value: "planner", label: "Planificador", type: "textarea"  },
        { value: "savingInMinutes", label: "Tiempo real de ahorro", type: "input" , restrictOnlyForAdmin: true},
        { value: "manualTime", label: "Tiempo manual", type: "input" , restrictOnlyForAdmin: true},
        { value: "automatedTime", label: "Tiempo invierte automatizado", type: "input", restrictOnlyForAdmin: true },
        { value: "functionalUser", label: "Usuario Funcional", type: "input" },
        { value: "projectDescription", label: "Descripción del robot", type: "input" },



    ])
    //columnas seleccionadas de la tabla principal
    const [colSelected, setSelected] = useState(
        [
            { value: "buttons", label: "Acciones", funcion: turnOffOnBot, type: "input", restrictOnlyForAdmin: true },
            { value: "botOn", label: "Encendido", id: "active", funcion: turnOffOnBot, type: "action", legend: "Encender la automatización de un bot del proyecto", ableOnlyForAdmin: false},
            { value: "botActivate", label: "Activar", id: "activate", funcion: activateBot, type: "action", legend: "Ignorar el planner y ejecutar un robot en este momento", ableOnlyForAdmin: false },
            { value: "projectName", label: "Proyecto", type: "input" , restrictOnlyForAdmin: true},
            { value: "class", label: "Clase", type: "input" },
            { value: "areaCode", label: "Área", type: "input", restrictOnlyForAdmin: true },
            { value: "activeText", label: "Estado", type: "input", restrictOnlyForAdmin: true },
            { value: "activate", label: "Activar", type: "input", restrictOnlyForAdmin: true },
            { value: "sapText", label: "Usa SAP?", type: "input", restrictOnlyForAdmin: true },
            { value: "departmentName", label: "Departamento", type: "input" },
            { value: "functionalUser", label: "Usuario Funcional", type: "input" },
            { value: "projectDescription", label: "Descripción del robot", type: "input" },
        ]
    );

    //Efecto para dejar las columnas solo para administradores.
    useEffect(() => {    
        if(!isAdmin)
        {
            setColumns(columns.filter(column=> column.restrictOnlyForAdmin!= true));
            setSelected(colSelected.filter(colSelect=> colSelect.restrictOnlyForAdmin!= true));
        }
    }, [])
    
    //Estado que guarda los robots que hay en orchestrator
    const [rows, setRows] = useState([]);
    //state para los filtros
    const [filters, setFilters] = useState([]);
    //Aplicar los filtros a la data de la tabla
    const renderRowsByFilters = (rows) => {

        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);
            for (const element of filterKeys) {
                const valueFiltered = filters[element].toString().toLowerCase();
                rows = rows.filter((item) => item[element].toString().toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }

    //Hook para refrescar la data de la tabla 
    useEffect(() => {
        setLoad(true);
        const getRowsData = async () => {
            await handleRowData();
        }
        getRowsData();
    }, [refreshInput])

    //Funcion para traer las rows de la data de la tabla
    const handleRowData = async () => {

        dispatch(getBots()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRows(payload.data.bots)
            }
            else {
                Toast.fire({
                    title: payload.message,
                    type: 'warning'
                })
            }
            setLoad(false)
        })
    }

    const statusOptions = [
        {
            label: "Encendido",
            value: "1",
            statusType: "success"
        },
        {
            label: "Apagado",
            value: "0",
            statusType: "danger"
        },
    ]

    //#endregion

    //#region funcion para activar/desactivar robots por área
    ///sumary--------------------------------------------------------
    //areaId: es el id de la area en la tabla databotAreas que se utiliza para el update
    //area: codigo de databotAreas para mensaje Toast
    //Type: 1 enciende los bots, 0 apaga los bots
    ///---------------------------------------------------------------
    const handleTurnOnOffRobotByArea = (areaId, area, type) => {
        MySwal.fire({
            title: `Atención`,
            icon: 'info',
            // width: 800,
            html: `Esta seguro(a) que desea ${(type === "1") ? "encender" : "apagar"} los robots de ${area}?`,
            confirmButtonText: `Si, ${(type === "1") ? "encender" : "apagar"}`,
            confirmButtonColor: `${(type === "1") ? "#2dce89" : "#f5365c"} `,
            cancelButtonText: 'No, cancelar',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.value) {
                dispatch(updateRobot({ areaId, type, id: "" })).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {

                        Toast.fire({
                            title: `Se ${(type === "1") ? "encendieron" : "apagaron"} los robots de ${area} correctamente`,
                            type: 'success'
                        })
                        setPage(1);
                        setRefreshInput(prevState => (!prevState))

                    } else {
                        Toast.fire({
                            title: payload.message,
                            type: 'warning'
                        })
                    }
                })
            }
        })

    }
    //#endregion

    //#region Funcion apagar todos los bots
    const [valueOnOrOffAllBots, setValueOnOrOffAllBots] = useState(true)

    //Efecto para cuando hay algún bot activo ponga el checkbox off, y si no hay ninguno activo que lo ponga en On.
    useEffect(() => {

        //Verifica cuantos robots están encendidos.
        let quantityActive = rows.filter(row => row.active == 1).length;
        setValueOnOrOffAllBots(quantityActive == 0 ? false : true)

    }, [rows])


    const desActivarAllBot = (e) => {
        //  console.log(e.target.value);

        let value = !valueOnOrOffAllBots

        //  valueOnOrOffAllBots==="on"? setValueOnOrOffAllBots("off"):"on";
        e.persist();
        console.log(e.target.checked)
        MySwal.fire({
            title: `Atención`,
            icon: 'info',
            // width: 800,
            html: `Esta seguro(a) que desea ${(value) ? "encender" : "apagar"} todos los robots?`,
            confirmButtonText: `Si, ${(value) ? "encender" : "apagar"}`,
            confirmButtonColor: `${(value) ? "#2dce89" : "#f5365c"} `,
            cancelButtonText: 'No, cancelar',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.value) {
                //por alguna razon cambia de booleano
                // e.target.checked = !e.target.checked;
                console.log(e.target.checked)
                dispatch(updateRobot({ areaId: "all", type: (value) ? "1" : "0", id: "" })).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {

                        Toast.fire({
                            title: `Se ${(value) ? "encendió" : "apagó"} todos los robots correctamente`,
                            type: 'success'
                        })
                        setPage(1);
                        setRefreshInput(prevState => (!prevState))
                        setValueOnOrOffAllBots(value)

                    } else {
                        Toast.fire({
                            title: payload.message,
                            type: 'warning'
                        })
                    }
                })
            }
        })

    }
    //#endregion

    //#region abrir modal para nuevo robot
    const [showModal, setModal] = useState(false);
    const openModal = (bot, mode) => {
        setModal((prevState) => ({
            ...prevState,
            showModal: !prevState.showModal,
            mode: mode,
            bot: bot
        }));


    }

    //#endregion

    //#region datos para tabla de Mandantes
    //Estado para actualizar los datos de la tabla MainTable
    const [refreshInputMand, setRefreshInputMand] = useState(false);
    //Estado para guardar los registros de la tabla databotAreas
    const [mandantesSap, setMandantesSap] = useState([]);
    //Estado para guardar la página de la tabla
    const [pageMand, setPageMand] = useState(1);
    //#region funcion para activar/desactivar robot id
    const desActivarMand = (e, row) => {
        e.persist();
        console.log(e.target.checked)
        MySwal.fire({
            title: `Atención`,
            icon: 'info',
            // width: 800,
            html: `Esta seguro(a) que desea ${(e.target.checked) ? "bloquear" : "desbloquear"} el mandante ${row.client}?`,
            confirmButtonText: `Si, ${(e.target.checked) ? "bloquear" : "desbloquear"}`,
            confirmButtonColor: `${(e.target.checked) ? "#2dce89" : "#f5365c"} `,
            cancelButtonText: 'No, cancelar',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.value) {
                //por alguna razon cambia de booleano
                e.target.checked = !e.target.checked;
                console.log(e.target.checked)
                dispatch(updateMand({ type: (e.target.checked) ? "1" : "0", id: row.id })).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {

                        Toast.fire({
                            title: `Se ${(e.target.checked) ? "bloqueó" : "desbloqueó"} el mandante ${row.client} correctamente`,
                            type: 'success'
                        })
                        setPageMand(1);
                        setRefreshInputMand(prevState => (!prevState))

                    } else {
                        Toast.fire({
                            title: payload.message,
                            type: 'warning'
                        })
                    }
                })
            }
        })

    }
    //#endregion
    //Hook para refrescar la data de la tabla 
    useEffect(() => {
        setLoad(true);
        const getRowsMand = async () => {
            await handleRowDataMand();
        }
        getRowsMand();
        // console.log(mandantesSap)
    }, [refreshInputMand])

    //Funcion para traer las rows de la data de la tabla
    const handleRowDataMand = async () => {

        //Extraer los mandantes
        dispatch(getMandantes()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setMandantesSap(payload.data.mand)
            }
            setLoad(false);
        })
    }


    //Columnas de la tabla principal de bots
    const columnsMand = [
        { value: "action", label: "Des/Activar", funcion: desActivarMand },
        { value: "client", label: "Mandante" },
        { value: "activeText", label: "Estado" },
        { value: "class", label: "Clase" }
    ];
    //columnas seleccionadas de la tabla principal
    const [colSelectedMand, setSelectedMand] = useState(
        [
            { value: "action", label: "Des/Activar", funcion: desActivarMand },
            { value: "client", label: "Mandante" },
            { value: "activeText", label: "Estado" },
            { value: "class", label: "Clase" }
        ]
    );

    //#endregion

    //#region datos para el modal de nuevo robot

    //Spinner para el modal
    const [spinnerModal, setSpinnerModal] = useState(false)

    //Estado para las opciones del formulario del modal newRobot
    const [databotOptions, setDatabotOptions] = useState([]);

    //Estado para guardar la lista de empleados de GBM
    const [employees, setEmployees] = useState([]);
    //Efecto para cuando aranque traiga las opciones
    useEffect(() => {
        //setLoad(true);
        const getRowsData = async () => {
            await handleGetDatabotOptions();
        }
        getRowsData();
    }, [])

    //Función para traer las rows de la data de la tabla
    const handleGetDatabotOptions = async () => {
        setSpinnerModal(true);

        dispatch(getDatabotOptions()).then((resp) => {
            const { payload } = resp;

            setSpinnerModal(false)
            // setLoadingColorSpinner(false)
            if (payload.status === 200) {
                setDatabotOptions(payload.data.payload.databotData)
                setEmployees(payload.data.payload.employees)
            }
            else {
                Toast.fire({
                    title: payload.message,
                    type: 'warning'
                })
            }
        })
    }

    //#endregion
    return (
        <>

            <NewRobotModal
                setModal={setModal}
                showModal={showModal}
                dropDownOptions={[]}
                setRealTable={setRefreshInput}
                Toast={Toast}
                setPage={setPage}
                databotAreas={databotAreas}
                options={databotOptions}
                employees={employees}
                spinner={spinnerModal}

            />
            <div className="rna-wrapper">
                <NotificationAlert useRef="notificationAlert" />
            </div>
            <AdminHeader name="Inicio" parentName="Databot Orchestrator" />
            <Spin size="large" spinning={loading || Object.keys(databotOptions).length==0} /*En caso que no haya options que ponga el spinner*/>
                <Container className="mt--6" fluid>
                    {isAdmin &&
                        <>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardHeader>
                                            <Row>
                                                <Col md='10'>

                                                    <Row>
                                                        <label><strong> Encender/Apagar todos los robots   </strong></label>
                                                    </Row>
                                                    <label className="custom-toggle custom-toggle-success ml-2">
                                                        <input className='mb-2' type="checkbox" onChange={(e) => desActivarAllBot(e)} checked={valueOnOrOffAllBots} />
                                                        <span className="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On"></span>
                                                    </label>

                                                </Col>

                                                <Col xs="12" md="2" className="p-1 mb-3 mt-3" style={{ display: "flex", justifyContent: "flex-end" }}>


                                                    <Button className="btn-icon" color="success" size="sm" block type="button"
                                                        onClick={() => openModal(null, "create")}
                                                    >
                                                        <span className="btn-inner--icon mr-">
                                                            <i className="fas fa-plus" />
                                                        </span>
                                                        <span className="btn-inner--text">
                                                            Agregar Nuevo Robot
                                                        </span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                    </Card>
                                    <Row>
                                        <CardsHeader
                                            databotAreas={databotAreas}
                                            handleTurnOnOffRobotByArea={handleTurnOnOffRobotByArea}
                                            rows={rows}
                                        />
                                    </Row>
                                </Col>
                                <Col sm="12">
                                    <MandTable
                                        colSelected={colSelectedMand}
                                        setSelected={setSelectedMand}
                                        columns={columnsMand}
                                        rows={mandantesSap}
                                        page={pageMand}
                                        setPage={setPageMand}
                                        Toast={Toast}
                                        isAdmin={isAdmin}
                                        setRefreshInput={setRefreshInputMand}
                                    />
                                </Col>

                            </Row>
                            <Row>

                            </Row>
                        </>
                    }

                    <Row>
                        <Col sm='12'>

                            <MainTable
                                setFilters={setFilters}
                                colSelected={colSelected}
                                setSelected={setSelected}
                                columns={columns}
                                rowsE={renderRowsByFilters(rows)}
                                page={page}
                                setPage={setPage}
                                Toast={Toast}
                                isAdmin={isAdmin}
                                setRefreshInput={setRefreshInput}
                                setLoad={setLoad}
                                openModal={openModal}
                                user={user}
                                options={databotOptions}
                            />

                        </Col>
                    </Row>
                </Container>
            </Spin>
        </>
    )


};
export default Orchestrator;