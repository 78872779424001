/*!

=========================================================
*Portal WMS
=========================================================

*Componente "CardMain" el cual Posee el diseño de los Cards de la vista principal

*Props:
    subtitle: el subtitulo del Card
    setFormSelected: useState que actualiza el tab activo del tabContent del componente MainComponent
    id: el id del card seleccionado que concuerda con el del tabPanel del tabContent del componente MainComponent
    image: la imagen del card
    colorCardSelected: el color del card al ser seleccionado
    formSelected: useState que guarda el tab activo del tabContent del componente MainComponent
=========================================================

* Coded by Diego Meza/Eduardo Piedra - Application Management GBM

*/
// react library
import React, { useContext } from 'react';
// reactstrap components
import { Card, CardBody, Col, Row, CardImg } from "reactstrap";

import { UserContext } from './UserContext';


export const CardMain = React.memo((
    {
        key,
        props,
        formSelected,
        handleOnClick,
    }) => {

    //#region States globales
    const {
        isMobile
    } = useContext(UserContext);
    //#endregion



    return (
        <>


            <Col key={key} xs="6" sm="3" className={` ${isMobile ? 'p-1 mb--2' : ''}`} style={{ height: isMobile ? '145px' : '' }} >
                <Card
                    className="card-profile p-1 card-lift--hover text-center"
                    style={{
                        height: isMobile ? '130px' : '',
                        backgroundColor: (formSelected === props.id) ? props.colorCardSelected : '#ffffff', cursor: "pointer"
                    }}
                    onClick={() => handleOnClick(props.id)}
                >
                    {!isMobile &&
                        /* Si se quiere habilitar la imagen en mobile eliminar la condición */

                        < CardImg
                            alt="..."
                            src={require(`assets/img/WMS/${props.image}`)}
                            top
                            style={{ height: isMobile ? '50px' : '100px' }}
                        />
                    }

                    <Row className="justify-content-center">
                        {/* Si se quiere habilitar la imagen en mobile poner las mismas que en el pc mode. */}
                        <Col className={isMobile ? "mt-2" : "mt--4"} lg="3" >
                            <div className="card-profile-image p-0 " >
                                <a >

                                    <div className={`icon icon-shape bg-gradient-${isMobile ? props.colorMobile : props.colorPC} text-white rounded-circle shadow`}
                                        style={{
                                            width: `${isMobile ? '50px' : '55px'}`,
                                            height: `${isMobile ? '50px' : '55px'}`,
                                        }}

                                    >
                                        <i className={props.icon + " rounded-circle"} />
                                    </div>
                                </a>
                            </div>

                        </Col>
                    </Row>
                    <CardBody className={`pt-0 mt-2 ${isMobile && 'p-0'}`}>

                        <div className={`text-center ${isMobile && 'p-0'}`} >
                            <h3 className={`${isMobile ? 'h4' : 'h3'}`}>
                                {props.title}
                            </h3>
                            {!isMobile &&
                                <div className="h5 font-weight-300">
                                    <i className="ni location_pin mr-2" />
                                    Administración Warehouse
                                </div>}
                        </div>

                    </CardBody>
                </Card>
            </Col>


        </>
    )
})