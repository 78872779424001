
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Card, CardFooter, Pagination, Table, PaginationItem, Button, FormGroup, Input, Form,
  Col, CardHeader, PaginationLink, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Label
} from "reactstrap";

const ModalEditContact = ({
    getInfo,
    saveUpdate,
    dataInformationModal,
    invalid,
    options,
    changeData,
    cols,
    modalInfo,
    phones
}) => {

    return (
        <>
            <Modal
                size="lg"
                isOpen={modalInfo}>

                <ModalHeader >Información de contacto</ModalHeader>
                <Form novalidate className="formInfoContacts needs-validation">
                    <ModalBody>

                        <Row>
                            {
                                cols.map((row, key) => (
                                    <Col xl="4" mb="12">
                                        < FormGroup id={row.value}>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >
                                                {row.value === "STATUS" || row.value === "CONFIRM" ?
                                                    <></>
                                                    :
                                                    `${row.label}:`}
                                            </label>
                                            {row.value === "ID_CONTACT_CRM" || row.value === "SAM" || row.value === "UPDATEBY" || row.value === "UPDATEDATE" ?
                                                <>
                                                    <Input
                                                        className="form-control"
                                                        id={row.value}
                                                        value={dataInformationModal[row.value]}
                                                        onChange={(e) => changeData(e)}
                                                        readOnly
                                                    />
                                                </>
                                                :
                                                phones.includes(row.value) ?
                                                    <>
                                                        <Input
                                                            invalid={invalid[row.value]}
                                                            required={row.required === true ? true : false}
                                                            className="form-control"
                                                            id={row.value}
                                                            type={"text"}
                                                            onKeyDown={e => /^[a-zA-Z]$/.test(e.key) && e.preventDefault()}
                                                            placeholder={row.placeholder}
                                                            onChange={(e) => changeData(e.target.value, row.value)}
                                                            value={dataInformationModal[row.value]}
                                                            maxlength={row.maxlength}
                                                        />
                                                        <div className="invalid-feedback">
                                                            Este campo no puede estar vacio
                                                        </div>
                                                    </>
                                                    :
                                                    row.value === "STATUS" || row.value === "CONFIRM" ?
                                                        <></>
                                                        :
                                                        row.type === "select" ?
                                                            <>
                                                                <Input
                                                                    invalid={invalid[row.value]}
                                                                    required={row.required === true ? true : false}
                                                                    type={row.type}
                                                                    options={options[row.value]}
                                                                    className="basic-multi-select text-dark"
                                                                    classNamePrefix="select"
                                                                    id={row.value}
                                                                    onChange={(e) => changeData(e.target.children[e.target.selectedIndex].id, row.value)}
                                                                    defaultValue={dataInformationModal[row.value]}
                                                                >
                                                                    {options[row.value].map((value) => {
                                                                        return (
                                                                            <option id={value.value} >{value.label}</option>
                                                                        );
                                                                    })}
                                                                </Input>
                                                                <div className="invalid-feedback">
                                                                    Este campo no puede estar vacio
                                                                </div>
                                                            </>
                                                            :
                                                            row.value === "FIRST_NAME" || row.value === "LAST_NAME" ?
                                                                <>
                                                                    <Input
                                                                        invalid={invalid[row.value]}
                                                                        required={row.required === true ? true : false}
                                                                        className="form-control"
                                                                        id={row.value}
                                                                        type={row.type}
                                                                        onKeyDown={e => /^[0-9]$/.test(e.key) && e.preventDefault()}
                                                                        placeholder={row.placeholder}
                                                                        onChange={(e) => changeData(e.target.value, row.value)}
                                                                        value={dataInformationModal[row.value]}
                                                                        maxlength={row.maxlength}
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Este campo no puede estar vacio
                                                                    </div>
                                                                </>
                                                                :
                                                                row.value === "EMAIL" ?
                                                                    <>
                                                                        <Input
                                                                            invalid={invalid[row.value]}
                                                                            required={row.required === true ? true : false}
                                                                            className="form-control"
                                                                            id={row.value}
                                                                            type={row.type}
                                                                            placeholder={row.placeholder}
                                                                            onChange={(e) => changeData(e.target.value, row.value)}
                                                                            value={dataInformationModal[row.value]}
                                                                            maxlength={row.maxlength}
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Este campo no puede estar vacio y ocupa formato de correo electrónico
                                                                        </div>
                                                                    </>
                                                                    : row.value === "ADDRESS" ?
                                                                        <>
                                                                            <Input
                                                                                invalid={invalid[row.value]}
                                                                                required={row.required === true ? true : false}
                                                                                className="form-control"
                                                                                id={row.value}
                                                                                type={row.type}
                                                                                placeholder={row.placeholder}
                                                                                onChange={(e) => changeData(e.target.value, row.value)}
                                                                                value={dataInformationModal[row.value]}
                                                                                maxlength={row.maxlength}
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Este campo no puede estar vacio
                                                                            </div>
                                                                        </> :
                                                                        <>
                                                                            <Input
                                                                                invalid={invalid[row.value]}
                                                                                required={row.required === true ? true : false}
                                                                                className="form-control"
                                                                                id={row.value}
                                                                                type={row.type}
                                                                                onKeyDown={e => /^[a-zA-Z]$/.test(e.key) && e.preventDefault()}
                                                                                placeholder={row.placeholder}
                                                                                onChange={(e) => changeData(e.target.value, row.value)}
                                                                                value={dataInformationModal[row.value]}
                                                                                maxlength={row.maxlength}
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Este campo no puede estar vacio
                                                                            </div>
                                                                        </>
                                            }
                                        </FormGroup>
                                    </Col>
                                ))
                            }
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => getInfo({})}>Cancelar</Button>
                        <Button color="success" onClick={saveUpdate}>Actualizar</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}

export default ModalEditContact;