import React from 'react';
// react library for routing
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
// react-redux library for conect redux
import { connect } from 'react-redux';
// core routes
import { NotificationsRoutes } from "routes/routes.jsx";
// core selectors
import { getNotifications } from "selectors/notifications.jsx";

const Index = props => {

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/notifications") {
        if (prop.path === '/target-letter-user' && !props.notifications.some((row) => row.type === 'target-letter-user')) {
          return (
            <Redirect
              key={key}
              from={prop.layout + prop.path}
              to="/admin/notifications"
            />
          );
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Switch>
        {getRoutes(NotificationsRoutes)}
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
});

export default withRouter(connect(mapStateToProps, null)(Index));