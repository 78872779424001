import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar, Line, Pie } from "react-chartjs-2";
import { Card, CardBody, CardHeader } from "reactstrap";
import Chart from "chart.js";
import {
    // global options for the charts
    chartOptions,
    // function that adds the global options to our charts
    parseOptions
} from "variables/charts.jsx";

class Charts extends Component {

    componentWillMount() {
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }
    render() {
        const {
            title,
            object,
            subtitle,
            bar,
            line,
            pie
        } = this.props

        return (
            <>
                <Card>
                    <CardHeader>
                        <h6 className="surtitle">{title}</h6>
                        <h5 className="h3 mb-0">{subtitle}</h5>
                    </CardHeader>
                    <CardBody>
                        <div className="chart">
                            {
                                bar ?
                                    <Bar
                                        data={object.data}
                                        options={object.options}
                                        className="chart-canvas"
                                        id="chart-bar-stacked"
                                    /> : null
                            }
                            {
                                line ?
                                    <Line
                                        data={object.data}
                                        options={object.options}
                                        className="chart-canvas"
                                        id="chart-bar-stacked"
                                    /> : null
                            }
                            {
                                pie ?
                                    <Pie
                                        data={object.data}
                                        options={object.options}
                                        className="chart-canvas"
                                        id="chart-pie"
                                    />
                                    : null
                            }
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    };
}

Charts.propTypes = {
    options: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};

export default Charts;