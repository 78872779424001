// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Col,
  FormGroup,
  Input,
  Row,
  Form,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// antd components
import { Spin, Checkbox } from "antd";

import { useDispatch } from "react-redux";

import classnames from "classnames";

import React, { useState, useEffect } from "react";

import formFields from "./SharedComponents/GeneralStates";

//Import para enviar la gestion
import { newRequest, getFormFields } from "actions/masterData";

//imports de los 3 tabnvas
import GeneralDataForm from "./NewRequest/GeneralDataForm.jsx";
import InfoGestion from "./NewRequest/InfoGestion.jsx";
import AproverDocuments from "./NewRequest/AproverDocuments.jsx";
import { stringify } from "uuid";
import { IndividualFormModal } from "./SharedComponents/IndividualFormModal";

//Formato para números de teléfono
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const GeneralForm = (props) => {

  const { typeForm, handleOnBack, optionsDropdowns, Toast, employees, setEmployees } = props;

  //#region State y legends para la política de la gestión de clientes.
  const [policyLegend, setPolicyLegend] = useState("“Entiendo y acepto que para la creación del cliente he cumplido con las políticas y procedimientos de GBM para creación de clientes incluida pero no limitada la búsqueda y revisión de los clientes en las listas de Denied Persons, Entities y OFAC list del gobierno de los Estados Unidos de América.”");

  const [policyClientAcepted, setPolicyClientAcepted] = useState(false);
  //#endregion

  //#region estado para el boolean del spinner
  const [loading, setLoad] = useState(false);
  //#endregion

  //Número random temporal, para usarlo de idTemporal y subir los archivos
  const [temporalRequestId] = useState(new Date().getTime());

  //State para el uploadFiles del metodo masivo
  const [fileListRequest, setFileListRequest] = useState({ fileList: [] });

  //state para guardar el json grande de cada formulario
  const [formFieldsDb, setFormFieldsDb] = useState();
  useEffect(() => {

    dispatch(getFormFields()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        // console.log(payload.data.payload.formFieldsDb);
        setFormFieldsDb(payload.data.payload.formFieldsDb)
      } else {
        Toast.fire({
          title: "Atención",
          html: payload.response.data.message,
          type: "danger",
        });
      }
    });

    // console.log("temporalRequestId:" + temporalRequestId);
  }, []);

  //State para el uploadFiles de Documentos de aprovador
  const [fileListApprover, setFileListApprover] = useState({ fileList: [] });
  useEffect(() => {
    console.log("temporalRequestId:" + temporalRequestId);
  }, []);

  //state para validar campos llenos del primer formulario
  const [validate, setValidate] = useState(false);

  //state para determinar en que tab estoy
  const [activeTab, setActiveTab] = useState("1");

  //state para determinar si es masivo o en linea
  const [method, setMethod] = useState(false);

  //state para determinar los campos requeridos del primer formulario
  const [stateRequired, setStateRequired] = useState([]);

  //state para guardar la data global de cada form
  const [globalInfo, setGlobalInfo] = useState({});

  //state "bandera" para determinar si aún se está cargando un documento y si se le da enviar gestión indique que debe esperar.
  const [stillChargingDocuments, setStillChargingDocuments] = useState({ chargingApproversDocs: false, chargingMasivesDocs: false })

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  const [bawFlow, setBawFlow] = useState(false);

  //funcion que cambia el tab
  const handleTab = (event, tab) => {
    if (tab === "2") {
      //si se selecciona el paso 2
      //si el usuario selecciona que si tiene flujo de baw (bawFlow = true) extrae todos los campos obligatorios del formulario
      //si el usuario selecciona que no tiene flujo de baw (bawFlow = false) extrae todos los campos obligatorios excepto el id de baw (bawManagement)
      const geLength = formFields[typeForm].generalData.filter(
        (x) => x.required === true && ((bawFlow) ? true : x.id !== "bawManagement")
      ).length;
      //si el length de los campos obligatorios (geLength) es igual a el length del array stateRequired de los campos obligatorios que el usuario lleno, entonces se habilita el paso 2
      if (geLength === stateRequired.length) {
        setValidate(true);
      } else {
        setValidate(false);
      }
    }
    if (activeTab !== tab) setActiveTab(tab);
  };

  //state para guardar los valores del GeneralData
  const [generalDataValues, setGeneralDataValues] = useState({});

  //State para generar los formularios individuales
  const [stateModalsIndvForms, setStateModalsIndvForms] = useState([]);

  //Funcion para enviar la solicitud
  const handleOnSubmit = () => {
    globalInfo["dataType"] = formFields[typeForm].motherTable;
    globalInfo["formulario"] = formFields[typeForm].titulo;
    globalInfo["generalTable"] = formFields[typeForm].generalTable;
    globalInfo["formTable"] = formFields[typeForm].formTable;
    globalInfo["generalData"] = generalDataValues;
    globalInfo["TemporalRequest"] = temporalRequestId;
    globalInfo["fileListRequest"] = fileListRequest;
    globalInfo["fileListApprover"] = fileListApprover;
    globalInfo["stateModalsIndvForms"] = stateModalsIndvForms;

    if (typeForm == "clientes" && !policyClientAcepted) {
      Toast.fire({
        title: "Atención",
        html: "Debe aceptar la política y procedimientos de GBM para creación de clientes.",
        type: "warning",
      });
    } else if (globalInfo.method === undefined) {
      Toast.fire({
        title: "Atención",
        html: "Se necesita seleccionar el método de ingreso de la información para enviar la gestión",
        type: "warning",
      });
    }
    else if (globalInfo.method === "1" && stateModalsIndvForms.length === 0) {

      Toast.fire({
        title: "Atención",
        html: "Se necesita al menos una linea para enviar la gestión",
        type: "warning",
      });
    }
    else if (globalInfo.method === "2" && fileListRequest.fileList.length === 0) {
      Toast.fire({
        title: "Atención",
        html: "Se necesita adjuntar la plantilla de masivo para enviar la gestión",
        type: "warning",
      });
    }
    else if (stillChargingDocuments.chargingApproversDocs === true) {
      Toast.fire({
        title: "Atención",
        html: "Por favor, debe esperar que termine de cargar los documentos de aprobación.",
        type: "warning",
      });
    }
    else if (stillChargingDocuments.chargingMasivesDocs === true) {
      Toast.fire({
        title: "Atención",
        html: "Por favor, debe esperar que termine de cargar los documentos masivos.",
        type: "warning",
      });
    }
    else {

      //Si es clientes o contactos, se debe aplicar formato internacional a los números de teléfono.
      if (["2"/*Clientes*/, "3"/*Contactos*/].includes(globalInfo.dataType)) {
        // globalInfo["stateModalsIndvForms"] = applyE164FormatPhoneNumbers(); //=> 23-2-2024 - EPIEDRA: se inactiva debido a que sap hay un campo interno donde le aplica el prefijo segun el pais, es decir solo debe mandarse el número
      }

      setLoad(true);
      dispatch(newRequest(globalInfo)).then((resp) => {
        const { payload } = resp;
        console.log(resp);
        setLoad(false);
        if (payload.status === 200) {
          Toast.fire({
            title: "Atención",
            html: payload.data.message,
            type: "success",
          });
          handleOnBack();
        } else {
          Toast.fire({
            title: "Atención",
            html: payload.response.data.message,
            type: "danger",
          });
        }
      });
    }
  };

  //Aplica el formato E.164 a los números telefónicos.  
  const applyE164FormatPhoneNumbers = () => {
    // console.log("state", stateModalsIndvForms);

    const newStateModalsIndvForms = stateModalsIndvForms.map(item => {
      if (item.newInfo && item.newInfo.info) {
        // Verificar si existe phone o phoneContact en el objeto actual
        const info = item.newInfo.info;
        if ('phone' in info) {
          let countryCode= info.country.label.split(' - ')[0];
          info.phone = parsePhoneNumberFromString(info.phone, countryCode).format('E.164');
        }
        if ('phoneContact' in info) {
          let countryCode= info.countryContact.label.split(' - ')[0];
          info.phoneContact = parsePhoneNumberFromString(info.phoneContact, countryCode).format('E.164');
        }
      }
      return item;
    });


    // console.log("NEW STATE", newStateModalsIndvForms)
    return newStateModalsIndvForms
  }



  return (
    <div>
      <Spin size="large" spinning={loading}>
        <Card
          xs="12"
          md="3"
          className="p-1"
          style={{ display: "flex", justifyContent: "flex-right" }}
        >
          <CardHeader>
            <Button color="danger" onClick={handleOnBack}>

              <span className="">
                <i className="fa fa-arrow-left"></i>
              </span>
              <span className="btn-inner--text">
                {" "} Volver a inicio
              </span>
            </Button>
            <Button color="success" onClick={handleOnSubmit}>
              <span className="">
                <i className="fa fa-paper-plane"></i>
              </span>
              <span className="btn-inner--text">
                {" "} Enviar gestión
              </span>
            </Button>
          </CardHeader>
          <CardBody>
            <Card
              xs="12"
              md="3"
              className="p-1"
              style={{ display: "flex", justifyContent: "flex-right" }}
            >
              <CardHeader>
                {typeForm == "clientes" ?
                  <>
                    <h3 class="text-center">
                      Nueva Gestión de {formFields[typeForm].titulo}
                    </h3>
                    <Row className="mt--2 mb--2">
                      <Col sm="12">

                        <Row className="py-3 align-items-center">
                          <Col sm="auto">
                            <Checkbox
                              checked={policyClientAcepted}
                              onChange={(e) => setPolicyClientAcepted(!policyClientAcepted)}
                            >
                            </Checkbox>
                          </Col>
                          <Col sm="11">
                            <p
                              className="text-muted mb-0"  
                              // className="text-primary"
                              // className="text-info"

                              style={{ fontStyle: "italic", textAlign: "justify" }}>
                              {policyLegend}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col sm="6">
                      <h3 class="text-center">
                        Nueva Gestión de {formFields[typeForm].titulo}
                      </h3>

                    </Col> */}
                    </Row>
                  </>

                  :

                  <h3 class="text-center">
                    Nueva Gestión de {formFields[typeForm].titulo}
                  </h3>
                }

              </CardHeader>
              <CardBody>
                <Card className="shadow mb-lg-0">
                  <CardHeader className="border-0">
                    <Nav
                      className="nav-fill flex-column flex-md-row"
                      id="tabs-icons-text"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          aria-selected={activeTab === "1"}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: activeTab === "1",
                          })}
                          onClick={(e) => handleTab(e, "1")}
                          role="tab"
                        >
                          <span className="btn-inner--icon mr-2">
                            <i className="ni ni-archive-2" />
                          </span>
                          <span className="btn-inner--text mr-2">
                            Datos Generales
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-selected={activeTab === "2"}
                          className={classnames("mb-sm-3 mb-md-0 ", {
                            active: activeTab === "2",
                          })}
                          onClick={(e) => handleTab(e, "2")}
                          role="tab"
                        >
                          <span className="btn-inner--icon mr-2">
                            <i className="ni ni-single-copy-04 mr-2" />
                          </span>
                          <span className="btn-inner--text">
                            Información de Gestión
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-selected={activeTab === "3"}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: activeTab === "3",
                          })}
                          onClick={(e) => handleTab(e, "3")}
                          role="tab"
                        >
                          <span className="btn-inner--icon mr-2">
                            <i className="ni ni-book-bookmark mr-2" />
                          </span>
                          <span className="btn-inner--text">
                            Documentos Aprobador
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      {/* Datos Generales */}
                      <TabPane tabId="1">
                        <GeneralDataForm
                          setValidate={setValidate}
                          typeForm={typeForm}
                          optionsDropdowns={optionsDropdowns}
                          generalDataValues={generalDataValues}
                          setGeneralDataValues={setGeneralDataValues}
                          setStateRequired={setStateRequired}
                          stateRequired={stateRequired}
                          globalInfo={globalInfo}
                          setGlobalInfo={setGlobalInfo}
                          setBawFlow={setBawFlow}
                          bawFlow={bawFlow}
                        ></GeneralDataForm>
                      </TabPane>
                      {/* Informacion de Gestion */}
                      <TabPane tabId="2">
                        {validate ? (
                          <InfoGestion
                            typeForm={typeForm}
                            method={method}
                            setMethod={setMethod}
                            globalInfo={globalInfo}
                            setGlobalInfo={setGlobalInfo}
                            setFileListRequest={setFileListRequest}
                            fileListRequest={fileListRequest}
                            temporalRequestId={temporalRequestId}
                            stateModalsIndvForms={stateModalsIndvForms}
                            setStateModalsIndvForms={setStateModalsIndvForms}
                            generalDataValues={generalDataValues}
                            setGeneralDataValues={setGeneralDataValues}
                            stillChargingDocuments={stillChargingDocuments}
                            setStillChargingDocuments={setStillChargingDocuments}
                            formFieldsDb={formFieldsDb}
                          ></InfoGestion>
                        ) : (
                          <Card
                            className="text-center"
                            style={{ backgroundColor: "#E8E8E8" }}
                          >
                            <CardBody>
                              <h3>
                                No todos los campos estan completados en el paso
                                1, favor modificar antes de continuar
                              </h3>
                            </CardBody>
                          </Card>
                        )}
                      </TabPane>
                      {/* Documentos Aprobador */}
                      <TabPane tabId="3">
                        <AproverDocuments
                          typeForm={typeForm}
                          globalInfo={globalInfo}
                          setGlobalInfo={setGlobalInfo}
                          setFileListApprover={setFileListApprover}
                          fileListApprover={fileListApprover}
                          temporalRequestId={temporalRequestId}
                          stillChargingDocuments={stillChargingDocuments}
                          setStillChargingDocuments={setStillChargingDocuments}
                        ></AproverDocuments>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Spin>
      {/* Este arreglo se genera en RequestDetailModal */}
      {stateModalsIndvForms.length > 0 &&
        stateModalsIndvForms.map((individualFormModal, index) => (
          <IndividualFormModal
            index={index}
            //Modal actual
            individualFormModal={individualFormModal}
            //Para ver/modificar el arreglo con todos los modalesLineales
            stateModalsIndvForms={stateModalsIndvForms}
            setStateModalsIndvForms={setStateModalsIndvForms}
            //Para los dropdowns
            options={optionsDropdowns}
            employeesOptions={employees}
          //setReloadTableRequests={setReloadTableRequests}
          ></IndividualFormModal>
        ))}
    </div>
  );
};

export default GeneralForm;
