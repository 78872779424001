import { handleActions } from 'redux-actions';
import * as constants from "constants/index.jsx";

export const medicalRecord = handleActions({
  [constants.GET_MEDICAL_RECORD]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.CREATE_EMERGENCY_CONTACT]: (state, action) => {
    const { status } = action.payload;
    if (status === 200) {
      const { data, message } = state;
      data.test = "";
      data.contactName = "";
      data.telephone = "";
      data.relationship = "";
      data.contactAddress = "";
      return {
        data,
        message
      }
    }
    return state;
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const offices = handleActions({
  [constants.GET_OFFICES]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const emergencyContacts = handleActions({
  [constants.GET_EMERGENCY_CONTACTS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const allergies = handleActions({
  [constants.GET_ALL_ALLERGIES]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const diseases = handleActions({
  [constants.GET_ALL_DISEASES]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const interventions = handleActions({
  [constants.GET_ALL_INTERVENTIONS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const records = handleActions({
  [constants.GET_RECORDS_BY_ROL]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const graphRecords = handleActions({
  [constants.GET_INFORMATION_DASHBOARD]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const otherTypes = handleActions({
  [constants.GET_OTHER_TYPES]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const otherMedicalInfo = handleActions({
  [constants.GET_ALL_OTHER_MEDICAL_INFO]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});

export const accessRecords = handleActions({
  [constants.GET_USERS_ACCESS_MEDICAL_RECORD]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return [];
  },
  [constants.SIGN_OUT]: () => [],
}, {});