import React, { useState, useEffect } from 'react';
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { Container } from "reactstrap";
import AgreementMainTable from 'components/PanamaBids/Agreement/AgreementMainTable';
import AgreementFilters from 'components/PanamaBids/Agreement/AgreementFilters';
import AgreementEditModal from 'components/PanamaBids/Agreement/AgreementEditModal';
//Dispatch
import { useDispatch } from 'react-redux';
//Actions
import { getColumsPurchaseOrderProduct, getAllPurchaseOrderProduct, getMasterData, getAllFilesByID } from 'actions/panamaBids.jsx';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Spin } from 'antd';
import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';

const Agreement = () => {

    const MySwal = withReactContent(Swal);

    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("1");
    const [refreshInput, setrefreshInput] = useState("");
    const [contInput, setContInput] = useState([1]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoad] = useState(true);
    const [rowsModal, setRowsModal] = useState(
        {
            colums: [],
            rows: [],
            products: [],
            subtotal: 0,
            filters: [],
            files: [],
            rowsExcel:[]
        }
    );
    const { filters, rows } = rowsModal
    const [dataModal, setDataModal] = useState("");
    const [options, setOptions] = useState({
        sector: [{ label: "", value: "" }],
        brand: [{ label: "", value: "" }],
        forecastType: [{ label: "", value: "" }],
        gbmStatus: [{ label: "", value: "" }],
        keyWords: [{ label: "", value: "" }],
        orderStatus: [{ label: "", value: "" }],
        orderType: [{ label: "", value: "" }],
        performanceBond: [{ label: "", value: "" }],
    });
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    //#region 
    const [page, setPage] = useState(1);
    //#endregion

    //#region effect to display edit checkbox


    const [isDisplay, setIsDisplay] = useState(false);
    const [isDisplayBF, setIsDisplayBF] = useState(false);


    useEffect(() => {
        const teams = getTeamsUserAccess();
        // const token = getUserProtected();
        if (teams.some((row) => row.indexOf("PanamaBids Display") !== -1)) {
            setIsDisplay(true)
        }
        if (teams.some((row) => row.indexOf("PanamaBids Display BF") !== -1)) {
            setIsDisplayBF(true)
        }
    }, []);

    //#endregion
    //Hook para traer la informacion de lo selects
    useEffect(() => {
        const getRowsData = async () => {
            await handleRowInfo();
        }
        getRowsData();
    }, [])
    //Funcion para traer la informacion de lo selects y el nombre de las columnas de la tabla
    const handleRowInfo = async () => {
        setLoad(true);
        dispatch(getColumsPurchaseOrderProduct()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    colums: [{ Comment: "Seleccione...", Field: 0 }, ...payload.data.payload.data]
                }))
            }
        })
        dispatch(getMasterData()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setOptions(prevState => ({
                    ...prevState,
                    sector: payload.data.payload.masterData.Sector,
                    brand: payload.data.payload.masterData.brand,
                    forecastType: payload.data.payload.masterData.ForecastType,
                    gbmStatus: payload.data.payload.masterData.GBMStatus,
                    keyWords: payload.data.payload.masterData.KeyWords,
                    orderStatus: payload.data.payload.masterData.OrderStatus,
                    orderType: payload.data.payload.masterData.OrderType,
                    performanceBond: payload.data.payload.masterData.PerformanceBond,
                }))
            }
            setLoad(false);
        })
    }
    //Funcion para abrir el modal
    const handleOpenModal = (e) => {
        setDataModal(e);
        setrefreshInput(1);
        getPurchaseProductos(e.singleOrderRecord);
        setShowModal(true);
    }
    const getPurchaseProductos = async (id) => {
        dispatch(getAllPurchaseOrderProduct(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal(prevState => ({
                    ...prevState,
                    products: payload.data.payload.data
                }))
            }
        })
        dispatch(getAllFilesByID(id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsModal((prevState) => ({
                    ...prevState,
                    files: payload.data.rows,
                }));
            }
        });
    }
    //Funcion para cerrar el modal
    const handleCloseModal = () => {
        setDataModal([]);
        setrefreshInput(2);
        setShowModal(false);
        setActiveTab("1");
        setRowsModal(prevState => ({
            ...prevState,
            products: []
        }))
    }
    const renderRowsByFilters = (rows) => {
        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);
            for (const element of filterKeys) {
                const valueFiltered = filters[element].toLowerCase();
                rows = rows.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
            }
            return rows
        }
    }
    //Funcion para los filtros por columna
    const handleOnfilterDinamic = (constant, e) => {
        let text = e
        filters[constant] = text;
        setRowsModal(prevState => ({
            ...prevState,
            filters: filters
        }))
    }
    return (

        <div>
            <AdminHeader name="Home" parentName="Convenio Marco" />
            <Container className="mt--6" fluid>
                < AgreementEditModal
                    rowsModal={rowsModal}
                    showModal={showModal}
                    openModal={handleOpenModal}
                    setDataModal={setDataModal}
                    dataModal={dataModal}
                    options={options}
                    setrefreshInput={setrefreshInput}
                    handleCloseModal={handleCloseModal}
                    Toast={Toast}
                    setRowsModal={setRowsModal}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    isDisplay={isDisplay}
                    isDisplayBF={isDisplayBF}
                />
                <AgreementFilters
                    options={options}
                    dataSelect={rowsModal.colums}
                    setrefreshInput={setrefreshInput}
                    setContInput={setContInput}
                    contInput={contInput}
                    setRowsModal={setRowsModal}
                    Toast={Toast}
                    setLoading={setLoad}
                    isDisplayBF={isDisplayBF}
                    rows={rowsModal}

                />
                <Spin size="large" spinning={loading}>
                    <AgreementMainTable
                        filters={handleOnfilterDinamic}
                        page={page}
                        setRowsModal={setRowsModal}
                        setPage={setPage}
                        cols={rowsModal.colums}
                        rows={renderRowsByFilters(rowsModal.rows)}
                        openModal={handleOpenModal}
                        refreshInput={refreshInput}
                        isDisplayBF={isDisplayBF}
                    />
                </Spin>
            </Container>
        </div>
    );
};




export default Agreement;