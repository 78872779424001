import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findRequestsInQuotes = createAction(
  constants.GET_REQUESTS_IN_QUOTES,
  method.get(urls.INVENTORIES.getRequestsInQuotes)
);

export const findInfoRequestInQuote = createAction(
  constants.GET_INFO_REQUEST_IN_QUOTE,
  (id) => method.get(`${urls.INVENTORIES.getInfoRequestInQuote}/${id}`)()
);

export const findCommentsRequestInQuote = createAction(
  constants.GET_COMMENTS_REQUEST_IN_QUOTE,
  (id) => method.get(`${urls.INVENTORIES.getCommentsRequestInQuote}/${id}`)()
);

export const findFilesRequestInQuote = createAction(
  constants.GET_FILES_REQUEST_IN_QUOTE,
  (id) => method.get(`${urls.INVENTORIES.getFilesRequestInQuote}/${id}`)()
);

export const downloadPartsByModelInQuote = createAction(
  constants.DOWNLOAD_PARTS_BY_MODEL_IN_QUOTE,
  (body) =>
    method.downloadDataTables(
      `${urls.INVENTORIES.downloadPartsByModelInQuote}`,
      body
    )()
);

export const updateSustituteCostParts = createAction(
  constants.UPDATE_SUSTITUTES_AND_COSTS_PARTS,
  (body) => method.put(`${urls.INVENTORIES.updateSustituteCostParts}`, body)()
);

export const deactivateReferences = createAction(
  constants.DEACTIVATE_REFERENCE_BY_ID,
  (body) => method.put(`${urls.INVENTORIES.deactivateReferences}`, body)()
);
