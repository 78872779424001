/*!

=========================================================
*Databot - TableTopRobots
=========================================================

* Esta tabla despliega a todos los robots, sin embargo en un orden 
donde los primeros son los robots que tienen más ahorro en el año 
seleccionado en el filtro superior de la vista estadísticas.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Librerías de react.
import React, { useState, useContext,  useEffect } from 'react';

//Import de Spinner
import { Spin } from "antd";

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';
//Multiselect
import { MultiSelect } from "react-multi-select-component";

import { getTopSavingBots } from 'actions/databotDashboard.jsx';

//Dispatch para hacer peticiones
import { useDispatch } from 'react-redux';

//Componente para emitir notificaciones
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


//Librerías de reactstrap
import {
    PaginationItem,
    PaginationLink,
    Pagination,
    CardHeader,
    CardFooter,
    FormGroup,
    Button,
    Table,
    Input,
    Badge,
    Row,
    Col,
    Card,
} from "reactstrap";

export const TableTopRobots = ({}) => {


    //#region Mensajes de notificaciones
    const MySwal = withReactContent(Swal);
    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    //#endregion

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion




    //#region States Globales
    const {

        filterSelection,
    } = useContext(UserContext);
    //#endregion

    useEffect(() => {
        const getDataAtStart = async () => {
            await handleGetTopRobots();
        }
        getDataAtStart();
    }, [])

    //Spinner
    const [spinner, setspinner] = useState(false)

    //State donde se almacena la info de los topRobots
    const [topRobots, setTopRobots] = useState([])

    //Guardar el año previo 
    const [prevYear, setPrevYear] = useState("");

    useEffect(() => {
        // console.log("prevYear", prevYear)
    }, [prevYear])

    // useEffect(() => {
    //     debugger;
    //     if (( ( (prevYear !== filterSelection?.year)  &&  (filterSelection?.year != undefined) )  ) ){
    //         const getTopRobotsR = async () => {
    //             await handleGetTopRobots();
    //         }
    //         getTopRobotsR();

    //         setPrevYear(filterSelection?.year);
    //     }

    //     // if (filterSelection?.year != undefined) {
    //     //     setPrevYear(filterSelection?.year);
    //     // }

    // }, [filterSelection?.year]);

    //Funcion para traer las rows de la data de la tabla
    const handleGetTopRobots = () => {
        //Activar el spinner
        // setSpinnerTable(spinnerTable => { return { ...spinnerTable, ["topRobots"]: true } })
        setspinner(true);

        dispatch(getTopSavingBots({
            year: (filterSelection?.year.label) != undefined ? filterSelection?.year.label : new Date().getFullYear(),
        }
        )).then((resp) => {
            const { payload } = resp;

            // setSpinnerTable(spinnerTable => { return { ...spinnerTable, ["topRobots"]: false } })
            setspinner(false);


            if (payload.status === 200) {
                // console.log("Aqui TopRobots")


                setTopRobots(payload.data?.payload.topSavingBots)
            }
            else {
                Toast.fire({
                    title: payload.message,
                    type: 'warning'
                })
            }

        })
    }
    //#endregion


    //#region Textos

    const [text, setText] = useState({
        title: "Top de robots con más ahorro",
        subtitle: "Estadísticas Databot"
    })
    const { title, subtitle } = text;
    //#endregion 

    //#region Establecer arreglo de columnas
    //Columnas para el multicolumn y si la seleccionan se agrega al columnsSelectedTableRequests
    const optionsRequestsSelect = [

        { value: "id", label: "id", width: 20, disabled: false },
        { value: "class", label: "Robot", width: 40, disabled: false },
        { value: "savingMoney", label: "Ahorro", width: 40, disabled: false },
        { value: "savingInMinutesE", label: "Tiempo ahorro", width: 40, disabled: false },
        { value: "department", label: "Departamento", width: 65, disabled: false },
        { value: "functionalArea", label: "Area Funcional", width: 60, disabled: false },
        { value: "tower", label: "Torre", width: 60, disabled: false },
        { value: "name", label: "Usuario Funcional", width: 80, disabled: false },
        { value: "projectDescription", label: "Descripción", width: 130, disabled: false },
        { value: "caDate", label: "Creado en", width: 60, disabled: false },

    ];

    //Columnas seleccionadas por defecto en la tabla.
    const [columnsSelectedTableRequests, setColumnsSelectedTableRequests] = useState(
        [

            { value: "id", label: "id", width: 20, disabled: false },
            { value: "class", label: "Robot", width: 40, disabled: false },
            { value: "savingMoney", label: "Ahorro", width: 40, disabled: false },
            { value: "department", label: "Departamento", width: 65, disabled: false },
            { value: "name", label: "Usuario Funcional", width: 80, disabled: false },
        ]
    );

    //#endregion 

    //#region Funciones para aplicar flitros a la tabla

    //Filtros request tables
    const [filters, setFilters] = useState([]);


    //Capturar los valores de los inputs de los filtros
    const handleOnfilterDynamic = (constant, e) => {
        console.log(constant, e)
        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
        setPage(1);
    }

    //Aplicar los filtros a la data de la tabla
    const renderRowsByFilters = (rows) => {

        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);

            for (const element of filterKeys) {
                const valueFiltered = filters[element].toString().toLowerCase();

                rows = rows.filter(

                    (item) => {
                        let temp;

                        try {
                            temp = item[element]?.toString().toLowerCase().indexOf(valueFiltered);
                        } catch (e) { temp = -1 }

                        //Si se le da un espacio en el campo, imprima tdoos los vacios
                        if (valueFiltered === " ") {
                            return temp === undefined
                        } else { //No es un espacio, traiga todos los de la palabra clave
                            return temp != -1 && temp != undefined
                        }

                    }
                )
            }
            return rows
        }
    }

    //#endregion 

    //#region Realizar paginations en la tabla

    const [sizePerPage, setSizePerPage] = useState(10)
    const rows = renderRowsByFilters(topRobots);

    //state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);

    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Establece el número de filas por pagina 
    const handleOnSetPage = (page) => {
        setPage(page);
    };

    const handleSetNumPag = (e) => {
        setPage(1);
        const value = e.target.value;
        setSizePerPage(value);
    }

    //#endregion

    //#region Handles y funciones importantes
    //Función para generar a un número un formato de tipo dolar y comas.
    const numberWithCommas = (x) => {
        if (x >= 0)
            return `$${parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        else


            return null;
    };


    //Handle para refrescar la tabla
    const handleRefresh = () => {
        handleGetTopRobots();
    }

    //#endregion


    return (
        <>

            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col xs="12" sm='7'>
                            <Row>
                                <Col sm="auto" className="">
                                    <h6 className="surtitle">{subtitle}</h6>
                                    {/* <h5 className="h3 mb-0">{title + (filterSelection?.year.label ? filterSelection?.year.label : new Date().getFullYear())}</h5> */}
                                    <h5 className="h3 mb-0">{title }</h5>

                                </Col>
                                <Col sm="auto" className="ml-2">
                                    <MultiSelect
                                        size="sm"
                                        id="multiselectFilter"
                                        options={optionsRequestsSelect}
                                        value={columnsSelectedTableRequests}
                                        onChange={setColumnsSelectedTableRequests}
                                        labelledBy="Select"
                                    />
                                </Col>
                            </Row>



                        </Col>

                        <Col xs="12" sm='5' className="mr--2" style={{ display: "flex", justifyContent: "flex-end" }}>

                            <Button
                                className="btn-icon"
                                color="info"
                                size="sm"
                                type="button"
                                onClick={handleRefresh}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-sync-alt" />
                                </span>
                                <span className="btn-inner--text">
                                    {" "}Refrescar
                                </span>
                            </Button>
                        </Col>

                    </Row>

                </CardHeader>

                <Spin size="large" spinning={spinner}>
                    <Table
                        className="align-items-center  table-flush"
                        responsive
                        striped={true}
                        hover={true}
                    >
                        <thead className="thead-light">

                            <tr>
                                {
                                    columnsSelectedTableRequests.map((item, key) => {
                                        return (

                                            <th key={key} className="justify-content-md-center pr-1 pl-3" style={{ width: item.width }}>

                                                <FormGroup key={key}>
                                                    <label
                                                        style={{ fontSize: "12px" }}
                                                        className="form-control-label ml-2"
                                                        htmlFor="input-username"
                                                    >
                                                        {`${item.label}:`}
                                                    </label>
                                                    <Input
                                                        id={item.id}
                                                        key={key}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder={`Buscar ${item.label}...`}
                                                        onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                                                    />
                                                </FormGroup>
                                            </th>


                                        )
                                    })

                                }
                            </tr>
                        </thead>
                        <tbody className='list'>

                            {rows.length > 0 ?

                                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {
                                    return (
                                        <tr key={key}>
                                            {
                                                columnsSelectedTableRequests.map((col, keyCol) => {
                                                    return (
                                                        col.value === "Estado" ? (
                                                            <td key={key + keyCol} className="justify-content-md-center">
                                                                <div style={{ width: col.width }}>
                                                                    <Badge key={key + keyCol} color={row.statusType} pill>
                                                                        {row.Estado}
                                                                    </Badge>
                                                                </div>
                                                            </td>
                                                        )
                                                            : col.value === "savingMoney" ? (
                                                                <td key={key + keyCol} className="justify-content-md-center">

                                                                    <div style={{ width: col.width }}>

                                                                        <Badge color="" className="badge-dot mr-4">
                                                                            <i className="bg-warning" />
                                                                            {numberWithCommas(row[col.value])}
                                                                        </Badge>
                                                                    </div>


                                                                </td>
                                                            )
                                                                : (

                                                                    <td key={key + keyCol} className="text-center text-break pr-3 pl-1" style={{ whiteSpace: "normal", textAlign: "auto", width: col.width }}>
                                                                        {row[col.value]}
                                                                    </td>

                                                                )
                                                    )
                                                })
                                            }

                                        </tr>

                                    )
                                })
                                :
                                <>
                                    <tr>
                                        <td className="justify-content-md-center">
                                            Sin resultados encontrados
                                        </td>
                                    </tr>
                                </>
                            }

                        </tbody>




                    </Table>
                </Spin>
                <CardFooter>

                    <Row className="align-items-center ">
                        <Col xs='12' md='6' className="p-1 ">
                            <Row className="justify-content-start">
                                <Col xs='12' md='6'>
                                    <span className='pagination mb-0'>
                                        Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                    </span>
                                </Col>
                                <Col xs='12' md='2'>
                                    <Input type="select"
                                        onChange={(e) => handleSetNumPag(e)}>
                                        <option>5</option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs='12' md='6' className="p-1 ">
                            <Pagination
                                className="pagination justify-content-end mb-0"
                                listClassName="justify-content-end mb-0"
                            >
                                <PaginationItem className={page === 1 ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                        tabIndex="-1"
                                    >
                                        <i className="fas fa-angle-left" />
                                        <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                </PaginationItem>
                                {
                                    renderPaginations()
                                }
                                <PaginationItem className={page === paginations ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                    >
                                        <i className="fas fa-angle-right" />
                                        <span className="sr-only">Next</span>
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </Col>
                    </Row>

                </CardFooter>
            </Card>
        </>
    )
}
