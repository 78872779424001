// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, Row } from "reactstrap";

const CoeHeader = props => {

  const {
    date,
    name,
    parentName,
    mode,
    actionCalendar
  } = props;

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="8">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                  {mode === "month" ? date ? date.split(" ")[1] : "NULL" : date}
                </h6>
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {name}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {parentName}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="4">
                <Button
                  className="fullcalendar-btn-prev btn-neutral"
                  color="default"
                  onClick={() => actionCalendar('prev')}
                  size="sm"
                >
                  <i className="fas fa-angle-left" />
                </Button>
                <Button
                  className="fullcalendar-btn-next btn-neutral"
                  color="default"
                  onClick={() => actionCalendar('next')}
                  size="sm"
                >
                  <i className="fas fa-angle-right" />
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CoeHeader.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
};

export default CoeHeader;