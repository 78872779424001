/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion del filto avanzado en la consulta del colaborador
=========================================================

* Coded by Sergio Marin  

*/
import { Card, Col, Row, CardFooter, CardHeader, Input, Label, FormGroup, Button } from "reactstrap";
import { getSearchRequestFile } from 'actions/ItRequests';
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import MultiSelect from 'react-select';

const AdvanceFilter = ({ roles, optionsJson, rowsRequests, setReloadTableRequests, setRowsRequests, getSearchRequests, setLoading }) => {
    //FUNCIONES

    const filterFields = [
        {
            type: "text",
            label: "tipo solicitud:",
            id: "requestState",
            colWidth: 4,
            elementType: "MultiSelect",
            value: "",
            isVisible: false
        },
        {
            type: "date",
            label: "fecha creación de la solicitud:",
            id: "initialDate",
            colWidth: 4,
            elementType: "Input",
            value: "",
            isVisible: false
        },
        {
            type: "date",
            label: "fecha finalización de la solicitud:",
            id: "requestEndDate",
            colWidth: 4,
            elementType: "Input",
            value: "",
            isVisible: false
        },
        {
            type: "date",
            label: "fecha finalización del contrato:",
            id: "endDate",
            colWidth: 4,
            elementType: "Input",
            value: "",
            isVisible: false
        },
        {
            type: "text",
            label: "paises:",
            id: "country",
            colWidth: 4,
            elementType: "MultiSelect",
            value: "",
            isVisible: false
        },
        {
            type: "text",
            label: "posición:",
            id: "jobPosition",
            colWidth: 4,
            elementType: "MultiSelect",
            value: "",
            isVisible: false
        },
        {
            type: "text",
            label: "área personal:",
            id: "personalArea",
            colWidth: 4,
            elementType: "MultiSelect",
            value: "",
            isVisible: false
        },
        {
            type: "text",
            label: "tipo de plaza:",
            id: "jobType",
            colWidth: 4,
            elementType: "MultiSelect",
            value: "",
            isVisible: false
        }
    ]
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({});

    //Ini
    useEffect(() => {
        //dejar solo paises de los roles
        if (!(Array.isArray(roles.countries) && roles.countries.length === 1 && roles.countries[0] === '')) {
            optionsJson["country"] = optionsJson["country"].filter(item => roles.countries.includes(item.id.toString()));
        }

        optionsJson["country"] = optionsJson["country"].sort((a, b) => a.value.localeCompare(b.value));
        optionsJson["jobPosition"] = optionsJson["jobPosition"].sort((a, b) => a.value.localeCompare(b.value));
        optionsJson.requestState = [
            { "id": "Alta", "value": "Alta" },
            { "id": "Baja", "value": "Baja" },
            { "id": "Modificacion", "value": "Modificacion" }];
    }, []);

    //func para llenar las opciones de los multiSelects
    function setMultiOptions(optionsData) {
        const options = optionsData.map(item => ({
            value: item.id,
            label: item.value,
        }));
        return options;
    }

    //actualiza el estado filters cada que se escribe algo
    const handleOnChange = (id, e) => {
        e.persist();
        setFilters(prevState => ({ ...prevState, [id]: e.target.value }));
    }

    //func del boton de Consulta
    const handleQueryOnClick = async () => {
        setLoading(true);

        //filtro de los roles de los paises
        if (!filters.country || filters.country.length === 0) {
            filters.country = roles.countries.map(item => ({
                value: parseInt(item, 10),
                label: ""
            }));
        }

        await dispatch(getSearchRequests(filters)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setRowsRequests((e) => ({
                    ...e,
                    rows: payload.data.rows,
                    length: payload.data.rows.length,
                }))
            } else {
                setRowsRequests({ rows: [], statsBaw: [], length: 0, });
            }

            setReloadTableRequests(false);
        });
        setLoading(false);
    };

    //func del boton de Descarga
    const handleDownloadOnClick = async () => {

        const options = {};

        setLoading(true);
        await dispatch(getSearchRequestFile([options, rowsRequests])).then((resp) => {
            if (resp.payload.request.status !== 200) {
                console.log(resp);
            }
            else {
                const base64Content = resp.payload.data.base64File;
                const dataUri = `data:application/pdf;base64,${base64Content}`;

                const link = document.createElement('a');
                link.href = dataUri;
                link.setAttribute('download', 'Solicitudes.pdf'); // Cambia el nombre del archivo si es necesario
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }
        }).catch((error) => {
            console.error(error);
        });
        setLoading(false);
    };

    //func del onChange de los multiselect
    const handleMultiChange = (selectedOptions, id) => {
        setFilters(prevState => ({ ...prevState, [id]: selectedOptions }));
    };

    //RETURN
    return (
        <>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h3 className=" mb-0">
                                Opciones Avanzadas
                            </h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardFooter>
                    <Row style={{ padding: '0px 10px 0px' }}>
                        {
                            filterFields.map((filterField, index) => {
                                return (
                                    <Col key={index} xs={filterField.colWidth}>
                                        <div style={{ padding: '0px 15px 0px' }}>
                                            <FormGroup>
                                                <Label htmlFor={filterField.id}>
                                                    {filterField.label}
                                                </Label>

                                                {filterField.elementType === "MultiSelect" ? (
                                                    <MultiSelect
                                                        isMulti
                                                        isSearchable
                                                        closeMenuOnSelect={false}
                                                        id={filterField.id}
                                                        options={optionsJson[filterField.id] && setMultiOptions(optionsJson[filterField.id])}
                                                        onChange={selectedOptions => handleMultiChange(selectedOptions, filterField.id)}
                                                    />
                                                ) : (
                                                    <Row>
                                                        <Col  >
                                                            <Input
                                                                type="date"
                                                                onChange={(e) => handleOnChange(filterField.id + "From", e)}
                                                            />
                                                        </Col>
                                                        <Col xs="1" style={{ padding: '10px 15px 0px' }}>
                                                            al
                                                        </Col>
                                                        <Col  >
                                                            <Input
                                                                type="date"
                                                                onChange={(e) => handleOnChange(filterField.id + "To", e)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </Col>
                                );
                            })

                        }
                        <Col className="d-flex justify-content-center align-items-center">
                            <div style={{ padding: '10px 15px 0px' }}>
                                <Button
                                    color="primary"
                                    onClick={handleQueryOnClick}
                                >
                                    Consultar
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={handleDownloadOnClick}
                                >
                                    Descargar
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        </>
    );
};

export default AdvanceFilter;