/*!

=========================================================
*Autopp - BawItems
=========================================================

* Existe un state en AutoppViews llamado BawItemsList,
el cual los dispatch envian una accion al item reducer, y agrega, 
elimina, edita los items que hay en esta lista (state), de esta forma 
se gestiona los colaboradores en la vista de equipos de ventas. 

*Esta lista es agregada a editData (state principal de información),
en el componente MainTableAutopp cuando el form indica que está listo para enviar.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerias de React
import React, { useState, useReducer } from 'react'
import { Button, Col, Row, Form } from "reactstrap";

//Import de componente hijo
import BawItemForm from "./BawItemForm";


export const BawItems = ({
  bawItemsList,
  setBawItemsList,
}) => {


  //#region Asignación del titulo del ítem.
  const [state, setState] = useState({
    title: "Casos de BAW - GTL",
    subtitle: "A continuación puede ingresar todos los casos de BAW GTL."
  });
  const { title, subtitle } = state;
  //#endregion

  //#region Handles utiles para cada BawItemForm y que puedan gestionar sus dispatch para modificar el BawItemList.
  const handleAddBawItem = () => {


    setBawItemsList([...bawItemsList,{
      id: new Date().getTime(),
      information: {}
    }])

  }

  const handleAddInformationToBaw = (id, information) => {

    const bawInformation = {
      id: id,
      information: information
    }

    
    setBawItemsList( bawItemsList.map(item => {

      if (item.id === id) {
          return bawInformation
      } else {
          return item;
      }
  }) )



  }

  const handleDeleteItem = (bawId) => {
    
    const bawItems= bawItemsList.filter(item => item.id !== bawId);
    setBawItemsList(bawItems)

  }
  //#endregion

  return (

    <>

      <Row className="mb-4">

        <Col xs="12" md='6'>
          <h3 className="mb-0">{title}</h3>
          <p className="text-sm mb-0">{subtitle}</p>
        </Col>

      </Row>

      <Form noValidate className="formInfo needs-validation">
        {
          bawItemsList.map((bawItem, key) => (

            <BawItemForm
              bawItem={bawItem}     
              key={bawItem.id}         
              bawItemsList={bawItemsList}
              index={key}
              handleAddInformationToBaw={handleAddInformationToBaw}
              handleDeleteItem={handleDeleteItem}
            ></BawItemForm>
          ))
        }
      </Form>


      <Row className="align-items-center " style={{ display: "flex", justifyContent: "center" }} >
        <Row className="ml-4" style={{ display: "flex", justifyContent: "center" }}>


          <Button
            color="success"
            className="btn-icon"
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={(e) => { handleAddBawItem(e) }}
          >
            <span className="btn-inner--icon mr-1">
              <i className="fa fa-plus" />
            </span>
            <span className="btn-inner--text">Nuevo caso</span>
          </Button>

        </Row>

      </Row>

      {/*JSON.stringify(bawItemsList) */}
    </>



  )
}

export default BawItems;
