// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";

const ResumeDetail = (props) => {
  const { title, subtitle, wayPay, country, resume, offersCalcs, onBack } =
    props;

  const numberWithCommas = (x) => {
    if (x >= 0) return `$${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    else return null;
  };

  const totalCost = (list) => {
    let total = 0;
    for (const element of list) {
      total = total + element.totalCost;
    }
    return total;
  };

  return (
    <Row className="justify-content-center">
      <Col lg="12">
        <Card className="shadow border-0">
          <CardBody className="py-4">
            <div>
              <Row>
                <Col sm="12" md="8">
                  <h5 className="text-center surtitle text-success">
                    {title} - {wayPay}
                  </h5>
                  <h4 className="h3 text-center text-info text-uppercase">
                    {subtitle}
                  </h4>
                </Col>
                <Col sm="12" md="4" className="justify-content-center">
                  <Row>
                    <Col sm="6"></Col>
                    <Col sm="6">
                      <Card className="shadow border-0">
                        <Button
                          block
                          size="sm"
                          className="btn-icon btn-3"
                          color="danger"
                          type="button"
                          onClick={onBack}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-arrow-left" />
                          </span>
                          <span className="btn-inner--text">Salir</span>
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                  {totalCost(offersCalcs.equipmentsCalc) <
                    totalCost(offersCalcs.sparePartsCalc) && (
                    <Row className="p-0 m-0">
                      <Col sm="12">
                        <p className="h4" style={{ color: "red" }}>
                          El costo de las partes agregadas sobrepasa el costo
                          inicial de los equipos
                        </p>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
            <Row className="mt-1">
              <Col sm="12" md="6" className="d-flex justify-content-center">
                <img
                  style={{
                    width: "150px",
                  }}
                  alt="..."
                  src={require("assets/img/icons/common/gbm-logo.svg")}
                />
              </Col>
              <Col sm="12" md="6">
                <Row className="py-1 align-items-center">
                  <Col xs="6" className="d-flex justify-content-end">
                    <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                      País
                    </h5>
                  </Col>
                  <Col xs="6" className="d-flex justify-content-center">
                    <h3 className="heading mb-0">{country}</h3>
                  </Col>
                </Row>
                <Row className="py-1 align-items-center">
                  <Col xs="6" className="d-flex justify-content-end">
                    <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                      Años
                    </h5>
                  </Col>
                  <Col xs="6" className="d-flex justify-content-center">
                    <h3 className="heading mb-0">{resume.validityService}</h3>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Table className="align-items-center mt-2" responsive>
              <thead className="thead-light">
                <tr className="text-center">
                  <th>Cantidad Equipos</th>
                  <th>País</th>
                  <th>Tipo-Modelo</th>
                  <th>Serie</th>
                  <th>Plataforma</th>
                  <th>Horario Atención</th>
                  <th>Tiempo Cambio Partes</th>
                  <th>Vigencia</th>
                  <th>Renovación Automatica</th>
                  <th>Incluye Partes</th>
                  <th>Cuota</th>
                </tr>
              </thead>
              <tbody className="list">
                {resume.equipments.map((row) => (
                  <tr>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.amountEquipments}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.country}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.typeModel}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.serial}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.platform}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.officeHour}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.timeChangePart}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.validityService}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.automaticRenewal}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {row.equipmentParts}
                    </th>
                    <th
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderTop: "0px",
                      }}
                    >
                      {numberWithCommas(row.payment.toFixed(2))}
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row className="mt-1">
              <Col sm="6"></Col>
              <Col sm="6">
                <Row className="py-1 align-items-center">
                  <Col xs="6" className="d-flex justify-content-end">
                    <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                      Cuota
                    </h5>
                  </Col>
                  <Col xs="6" className="d-flex justify-content-center">
                    <h3 className="heading mb-0">
                      {numberWithCommas(
                        (
                          resume.totalEquipment.totalPayment +
                          resume.totalServices.totalPayment +
                          resume.totalSpareParts.totalPayment
                        ).toFixed(2)
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row className="py-1 align-items-center">
                  <Col xs="6" className="d-flex justify-content-end">
                    <h5 className="text-uppercase text-muted font-weight-bold mb-0">
                      Precio Total
                    </h5>
                  </Col>
                  <Col xs="6" className="d-flex justify-content-center">
                    <h3 className="heading mb-0">
                      {numberWithCommas(
                        (
                          resume.totalEquipment.totalPrice +
                          resume.totalServices.totalPrice +
                          resume.totalSpareParts.totalPrice
                        ).toFixed(2)
                      )}
                    </h3>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

ResumeDetail.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ResumeDetail;
