// core actions
import {
  findAllContractsOnHold,
  findDashboardContractsOnHoldByType,
  findContractGraphDetail,
  findContractGraphDetailByCountry,
} from "actions/secoh.jsx";
// antd components
import { Spin } from "antd";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// core components
import CardTransactions from "components/SECOH/CardTransactions.jsx";
import Charts from "components/SECOH/Charts.jsx";

import ChartTotalValue from "components/SECOH/ChartTotalValue.jsx"; // Asegúrate de que la ruta sea correcta

import ContracGraph from "components/SECOH/ContracGraph.jsx";
import FiltersDashboard from "components/SECOH/FiltersDashboard.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// javascript library for dates
import moment from "moment";
import "moment/locale/es";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getTransacctionsContractsOnHold,
  getDataDashboard,
  getDataDaysDashboard,
  getDataGraph,
  getDataGraphByCountry,
  getDataGraphTrend,
  getDataGraphTrendTotalValue,
  getFiltersDashboard,
  getServicesAvailable,
} from "selectors/secoh";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      selectedChart: "trend", // 'trend' o 'totalValue'
      message: null,
      serviceDashboard: false,
      visibleGraphByCountry: {
        visible: false,
        country: "",
      },
      filtersValues: {
        country: [],
        service: [],
        status: [],
        year: moment(),
      },
    };
  }

  componentWillMount() {
    const { filtersValues } = this.state;
    this.props.findAllContractsOnHold(false, { filters: [] }).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.props.findDashboardContractsOnHoldByType(false, {
          filters: filtersValues,
        });
        this.props.findContractGraphDetail({ filters: filtersValues });
      }
      this.setState({
        willMount: false,
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleChartChange = (event) => {
    this.setState({ selectedChart: event.target.value });
  };

  handleOnChangeChartType = () => {
    const { serviceDashboard, filtersValues } = this.state;
    this.props
      .findDashboardContractsOnHoldByType(!serviceDashboard, {
        filters: filtersValues,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.setState((state) => ({
            serviceDashboard: !state.serviceDashboard,
          }));
        }
      });
  };

  handleOnOpenGraphInfoByCountry = (row) => {
    const { visibleGraphByCountry, filtersValues } = this.state;
    if (
      visibleGraphByCountry.visible &&
      visibleGraphByCountry.country === row.country
    ) {
      this.setState({
        visibleGraphByCountry: {
          visible: false,
          country: "",
        },
      });
    } else {
      this.setState({
        loading: true,
      });
      this.props
        .findContractGraphDetailByCountry(row.country, {
          filters: filtersValues,
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            this.setState({
              visibleGraphByCountry: {
                visible: true,
                country: row.country,
              },
            });
          }
          this.setState({
            loading: false,
          });
        });
    }
  };

  handleOnChangeFilters = (key, event) => {
    const { filtersValues } = this.state;
    filtersValues[key] = event;
    this.setState({
      filtersValues,
      page: 1,
    });
  };

  handleOnClearFilters = () => {
    const { serviceDashboard } = this.state;
    this.setState({
      filtersValues: {
        country: [],
        service: [],
        status: [],
      },
      loading: true,
      message: "Quitando los filtros aplicados al dashboard",
      visibleGraphByCountry: {
        visible: false,
        country: "",
      },
    });
    this.props
      .findDashboardContractsOnHoldByType(serviceDashboard, {
        filters: { country: [], service: [], status: [], year: moment() },
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findContractGraphDetail({
            filters: { country: [], service: [], status: [], year: moment() },
          });
        }
        this.setState({
          loading: false,
          message: "",
        });
      });
  };

  handleOnFilterDataDashboard = () => {
    const { filtersValues, serviceDashboard } = this.state;
    this.setState({
      loading: true,
      message: "Aplicando los filtros seleccionados",
    });
    this.props
      .findDashboardContractsOnHoldByType(serviceDashboard, {
        filters: filtersValues,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findContractGraphDetail({ filters: filtersValues });
        }
        this.setState({
          loading: false,
          message: "",
          visibleGraphByCountry: {
            visible: false,
            country: "",
          },
        });
      });
  };

  render() {
    const {
      name,
      parentName,
      transactions,
      dataContracts,
      dataDays,
      dataGraph,
      colsReport,
      dataGraphByCountry,
      dataGraphTrend,
      dataGraphTrendTotalValue,
      filters,
    } = this.props;

    const {
      loading,
      message,
      willMount,
      serviceDashboard,
      visibleGraphByCountry,
      filtersValues,
    } = this.state;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando dashboard Contratos On Hold" />
                </Col>
              </Row>
            ) : (
              <>
                <CardTransactions data={transactions} />
                <FiltersDashboard
                  title="Filtros Disponibles"
                  subtitle="Aplicación de Filtros al Dashboard de Contratos On Hold"
                  options={filters}
                  values={filtersValues}
                  onClear={this.handleOnClearFilters}
                  onChange={this.handleOnChangeFilters}
                  onFilter={this.handleOnFilterDataDashboard}
                />
                <Row>
                  <Col xs="12" md="8">
                    <Charts
                      bar
                      event
                      title="Contratos On Hold"
                      subtitle="Cantidad de contratos On Hold"
                      typeDashboard={serviceDashboard}
                      object={dataContracts}
                      onChangeChart={this.handleOnChangeChartType}
                    />
                  </Col>
                  <Col xs="12" md="4">
                    <Charts
                      pie
                      title="Contratos On Hold"
                      subtitle="Cantidad de Días de retraso"
                      object={dataDays}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" md="6">
                    <Charts
                      line
                      title="Contratos On Hold"
                      subtitle="Tendencia Anual de Contratos por Start Date"
                      object={dataGraphTrend}
                    />
                  </Col>
                  <Col xs="12" md="6">
                    <ChartTotalValue
                      line
                      title="Contratos On Hold"
                      subtitle="Valor Total de Contratos Mensual (USD)"
                      object={dataGraphTrendTotalValue}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <ContracGraph
                      title="Contratos On Hold"
                      subtitle="Grafico Mensual por País"
                      cols={colsReport}
                      rows={dataGraph}
                      rowsCountry={dataGraphByCountry}
                      graphByCountry={visibleGraphByCountry}
                      onOpen={this.handleOnOpenGraphInfoByCountry}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

Dashboard.defaultProps = {
  name: "Dashboard",
  parentName: "Contratos On Hold",
  transactions: {},
  dataContracts: {},
  dataDays: {},
  colsReport: [
    { key: "country", name: "País" },
    { key: "January", name: "Enero" },
    { key: "February", name: "Febrero" },
    { key: "March", name: "Marzo" },
    { key: "April", name: "Abril" },
    { key: "May", name: "Mayo" },
    { key: "June", name: "Junio" },
    { key: "July", name: "Julio" },
    { key: "August", name: "Agosto" },
    { key: "September", name: "Setiembre" },
    { key: "October", name: "Octubre" },
    { key: "November", name: "Noviembre" },
    { key: "December", name: "Diciembre" },
    { key: "total", name: "Total" },
  ],
  dataGraph: [],
  dataGraphTotalValue: [],
  dataGraphByCountry: [],
  dataGraphTrend: {},
  dataGraphTrendTotalValue: {},
  filters: {},
};

Dashboard.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  transactions: PropTypes.object.isRequired,
  dataContracts: PropTypes.object.isRequired,
  dataDays: PropTypes.object.isRequired,
  dataGraph: PropTypes.array.isRequired,
  dataGraphByCountry: PropTypes.array.isRequired,
  dataGraphTrend: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { graph } = getDataGraph(state);
  return {
    transactions: getTransacctionsContractsOnHold(state),
    dataContracts: getDataDashboard(state),
    dataDays: getDataDaysDashboard(state),
    dataGraph: graph,
    dataGraphByCountry: getDataGraphByCountry(state),
    dataGraphTrend: getDataGraphTrend(state),
    dataGraphTrendTotalValue: getDataGraphTrendTotalValue(state),
    filters: getFiltersDashboard(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    findAllContractsOnHold,
    findContractGraphDetail,
    findContractGraphDetailByCountry,
    findDashboardContractsOnHoldByType,
  })(Dashboard)
);
