const getInterest = (interest, interestType, period) => {
  let newInterest = interest;
  if (interestType === "anual") {
    newInterest /= 12;
  }
  newInterest /= 100.0;
  if (period === "Mensual") {
    // console.log("El interés es mensual");
  } else if (period === "Bimensual") {
    newInterest *= 2;
  } else if (period === "Trimestral") {
    newInterest *= 3;
  } else if (period === "Cuatrimestral") {
    newInterest *= 4;
  } else if (period === "Semestral") {
    newInterest *= 6;
  } else if (period === "Anual") {
    newInterest *= 12;
  }
  return newInterest;
};

const getQuotaValue = (amount, interest, quota, period, interestType) => {
  const newtasa = getInterest(interest, interestType, period);
  const value = amount * ((newtasa * Math.pow(1 + newtasa, quota)) / (Math.pow(1 + newtasa, quota) - 1));
  // return value.toFixed(2);
  return value;
};

export const getAmortizacion = (amount, interest, quota, period, interestType) => {
  const valor_de_cuota = getQuotaValue(amount, interest, quota, period, interestType);
  let saldo_al_capital = amount;
  const items = [];

  for (let i = 0; i < quota; i++) {
    let interes = saldo_al_capital * getInterest(interest, interestType, period);
    let abono_al_capital = valor_de_cuota - interes;
    saldo_al_capital -= abono_al_capital;
    const numero = i + 1;

    // interes = interes.toFixed(2);
    // abono_al_capital = abono_al_capital.toFixed(2);
    // saldo_al_capital = saldo_al_capital.toFixed(2);

    const item = [
      numero,
      interes,
      abono_al_capital,
      valor_de_cuota,
      saldo_al_capital
    ];
    items.push(item);
  }
  return items;
};

const getEquipmentsBaseCalc = (equipments, request) => {
  const { wayPay, idBusinessModel } = request;
  return equipments.map((row) => {
    const totalCost = row.quantity * row.unitCost;
    const totalPriceCM = (totalCost * (row.byServices / 100) * row.quantityYears) + row.provision;
    const totalPriceSC1 = idBusinessModel === 1 ? 0 : (totalCost * (row.byServices / 100) * row.quantityYearsWarranty) + (totalCost * (row.byServicesRemaining / 100) * (row.quantityYears - row.quantityYearsWarranty));
    const shippingCost = totalPriceSC1 * (row.shippingPercent / 100);
    const totalShippingLanded = totalPriceSC1 + shippingCost;
    const upliftCost = totalShippingLanded / (1 - (row.upliftPercent / 100)) - totalShippingLanded;
    const totalUplift = totalShippingLanded + upliftCost;
    const finCost = totalUplift * (row.finPercent / 100);
    const totalPriceSC2 = totalUplift + finCost + row.provision;
    const wayPayNum = wayPay === 'Mensual' ? 12
      : wayPay === 'Bimensual' ? 6
        : wayPay === 'Trimestral' ? 4
          : wayPay === 'Cuatrimestral' ? 3
            : wayPay === 'Semestral' ? 2 : 1;
    const payment = idBusinessModel === 1 ? wayPay === "Un solo pago" ? totalPriceCM : totalPriceCM / (row.quantityYears * wayPayNum)
      : wayPay === "Un solo pago" ? totalPriceSC2 : totalPriceSC2 / (row.quantityYears * wayPayNum);
    row.totalCost = totalCost;
    row.quantityYearsRemaining = row.quantityYears - row.quantityYearsWarranty;
    row.totalPriceSC = totalPriceSC1;
    row.shippingCost = shippingCost;
    row.totalShippingLanded = totalShippingLanded;
    row.upliftCost = upliftCost;
    row.totalUplift = totalUplift;
    row.finCost = finCost;
    row.totalPrice = idBusinessModel === 1 ? totalPriceCM : totalPriceSC2;
    row.payment = payment;
    return row;
  });
};

const getServicesTssCalc = (services, request) => {
  const { wayPay } = request;
  return services.map((row) => {
    const totalCost = row.quantityHours * row.unitCost * row.quantityYears;
    const upliftCost = totalCost / (1 - (row.upliftPercent / 100)) - totalCost
    const totalUplift = totalCost + upliftCost;
    const finCost = totalUplift * (row.finPercent / 100);
    const totalPrice = totalUplift + finCost + row.provision + row.viatic;
    const wayPayNum = wayPay === 'Mensual' ? 12
      : wayPay === 'Bimensual' ? 6
        : wayPay === 'Trimestral' ? 4
          : wayPay === 'Cuatrimestral' ? 3
            : wayPay === 'Semestral' ? 2 : 1;
    const payment = wayPay === "Un solo pago" ? totalPrice : totalPrice / (row.quantityYears * wayPayNum);
    row.totalCost = totalCost;
    row.upliftCost = upliftCost;
    row.totalUplift = totalUplift;
    row.finCost = finCost;
    row.totalPrice = totalPrice;
    row.payment = payment;
    return row;
  });

};

const getSparePartsCalc = (spareParts, request) => {
  const { wayPay } = request;
  if (spareParts.length) {
    return spareParts.map((row) => {
      const totalCost = row.quantity * row.unitCost;
      const shippingCost = totalCost * (row.shippingPercent / 100);
      const totalShippingLanded = totalCost + shippingCost;
      const upliftCost = totalShippingLanded / (1 - (row.upliftPercent / 100)) - totalShippingLanded;
      const totalUplift = totalShippingLanded + upliftCost;
      const finCost = totalUplift * (row.finPercent / 100);
      const totalPrice = totalUplift + finCost + row.provision;
      const wayPayNum = wayPay === 'Mensual' ? 12
        : wayPay === 'Bimensual' ? 6
          : wayPay === 'Trimestral' ? 4
            : wayPay === 'Cuatrimestral' ? 3
              : wayPay === 'Semestral' ? 2 : 1;
      const items = getAmortizacion(totalPrice, row.interest, (row.quantityYears * wayPayNum), wayPay, "anual");
      row.totalCost = totalCost;
      row.shippingCost = shippingCost;
      row.totalShippingLanded = totalShippingLanded;
      row.upliftCost = upliftCost;
      row.totalUplift = totalUplift;
      row.finCost = finCost;
      row.totalPrice = totalPrice;
      row.payment = wayPay === "Un solo pago" ? totalPrice : items[0][3];
      return row;
    });
  } else {
    return spareParts;
  }

};

export const getMasterData = state => {
  const { masterData } = state;
  return masterData;
};

export const getRequestInAjustment = state => {
  const { requestInAjustment } = state;
  if (Object.keys(requestInAjustment).length) {
    const { requests } = requestInAjustment;
    return requests;
  }
  return [];
};

export const getOffersCalc = state => {
  const { offers } = state;
  if (Object.keys(offers).length) {
    const { resume: [equipments, servicesTss, spareParts], request } = offers;
    const equipmentsCalc = getEquipmentsBaseCalc(equipments, request);
    const servicesCalc = getServicesTssCalc(servicesTss, request);
    const sparePartsCalc = getSparePartsCalc(spareParts, request);
    return {
      equipmentsCalc,
      servicesCalc,
      sparePartsCalc
    }
  }
  return {
    equipmentsCalc: [],
    servicesCalc: [],
    sparePartsCalc: []
  };
};

export const getFiltersOptions = state => {
  if (Object.keys(state.requestInAjustment).length) {
    const { requests } = state.requestInAjustment;
    let clients = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.customer
      }
    });
    clients = clients.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => clients[e]).map(e => clients[e]);
    clients = clients.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let states = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.status
      }
    });
    states = states.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => states[e]).map(e => states[e]);
    states = states.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let opps = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.opportunityNumber
      }
    });
    opps = opps.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => opps[e]).map(e => opps[e]);
    opps = opps.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let countries = requests.map((row, key) => {
      return {
        value: key + 1,
        label: row.country
      }
    });
    countries = countries.map(e => e["label"])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => countries[e]).map(e => countries[e]);
    countries = countries.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return {
      customer: [
        { value: 0, label: "Todos" },
        ...clients
      ],
      status: [
        { value: 0, label: "Todos" },
        ...states
      ],
      opp: [
        { value: 0, label: "Todos" },
        ...opps
      ],
      country: [
        { value: 0, label: "Todos" },
        ...countries
      ],
    };
  }
  return {
    customer: [],
    status: [],
    opp: [],
    country: [],
  }
};

export const getAjustAndReferences = state => {
  const { offers } = state;
  if (Object.keys(offers).length) {
    const { ajustOffer } = offers;
    return ajustOffer;
  }
  return {
    ajust: {},
    references: []
  };
};

export const getLogsOffers = state => {
  const { logsOffer } = state;
  if (Object.keys(logsOffer).length) {
    const { logs } = logsOffer;
    return logs;
  }
  return [];
};
