import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";

const HistoryQueryMainTable = ({ rows, setPage, page, handleOnOpenModal, filters, filtersValue }) => {
  //Columnas de la tabla principal
  const selected = [
    { value: "idInterview", label: "ID de la entrevista" },
    { value: "status", label: "Tipo de entrevista" },
    { value: "idCollaborator", label: "Id de Colaborador" },
    { value: "collaboratorName", label: "Nombre de Colaborador" },
    { value: "countriesData", label: "Pais" },
    { value: "immediateBoss", label: "Jefe Inmediato" },
    { value: "department", label: "Unidad Organizativa" },
  ];
  //Hook para la cantidad de elementos que van a aparecer en la lista
  const [state, setState] = useState({
    sizePerPage: 10
  });
  const { sizePerPage } = state;

  //#region Pagination 
  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1
  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => handleOnSetPage(i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }
  //Cambiar de pagina
  const handleOnSetPage = (page, who) => {
    setPage(page);
  };
  const handleOnSetNumPagination = (e) => {
    setPage(1);
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      sizePerPage: value
    }))
  }
  //#endregion
  return (
    <div>
      <Card>
        <CardBody>
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">
              <tr>
                <th>
                  Detalle
                </th>
                {selected.map((item, key) => {
                  return (
                    item.value !== "idInterview" ?
                      <th key={key} className="justify-content-md-center ">
                        <FormGroup key={key} >
                          <label
                            style={{ fontSize: "12px" }}
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {`${item.label}:`}
                          </label>
                          <Input
                            id={item.id}
                            key={key}
                            className="form-control"
                            type="text"
                            value={filtersValue[item.id]}
                            placeholder={`Buscar ${item.label}...`}
                            onChange={(e) => filters(item.value, e.target.value)}
                          />
                        </FormGroup>
                      </th>
                      :
                      <th key={key} className="justify-content-md-center ">
                        <label
                          style={{ fontSize: "12px" }}
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {`${item.label}:`}
                        </label>
                      </th>
                  );
                })
                }

              </tr>

            </thead>
            <tbody className="list">
              {rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((item, key) => (
                <tr key={`tr${key}`} /*className="text-center"*/>
                  <td key={`tdInfo${key}`} className="text-center">
                    <Button
                      key={key}
                      className="btn"
                      color="primary"
                      onClick={() => handleOnOpenModal(item)}
                    >
                      <span className="btn-inner--icon mr-">
                        <i className="fas fa-info-circle"></i>
                      </span>
                      <span className="btn-inner--text"></span>
                    </Button>
                  </td>
                  {selected.map((col, index) => (
                    <td key={`${key}td${index}`} /*className="text-center"*/>
                      {
                        item[col.value]
                      }
                    </td>
                  ))
                  }

                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs='12' md='6' className="p-1">
                <Row className="justify-content-start">
                  <Col xs='12' md='6'>
                    <span className='pagination mb-0'>
                      Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                    </span>
                  </Col>
                  <Col xs='12' md='2'>
                    <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col xs='12' md='6' className="p-1">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page === 1 ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {
                    renderPaginations()
                  }
                  <PaginationItem className={page === paginations ? "disabled" : ""}>
                    <PaginationLink
                      onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </nav>
        </CardFooter>
      </Card>
    </div>
  );
};

export default HistoryQueryMainTable;