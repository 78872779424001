// core antd
import { Divider } from "antd";
// nodejs library for dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

const ConflictInterestInfo = (props) => {
  const { title, subtitle, initialValues, cols, onAddSupplier } = props;

  return (
    <Row>
      <div className="col">
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <h3 className="mb-0">{title}</h3>
              <p className="text-sm mb-0">{subtitle}</p>
            </CardHeader>
            <CardBody>
              <div className="form-row justify-content-center align-items-center">
                <Col className="mt-3" sm="12" md="12">
                  <h3 className="mb-4">
                    GBM como parte del procedimiento PR-PRV-001 de Selección y
                    Registro de Proveedores y en aras evitar posibles conflictos
                    de interés le solicita que responder lo siguiente:
                  </h3>
                </Col>
              </div>
              <div className="form-row align-items-center">
                <Col className="mb-3" sm="12" md="3">
                  <FormGroup>
                    <Label className="form-control-label" for="employeeID">
                      ID del Colaborador
                    </Label>
                    <Input
                      disabled
                      type="number"
                      value={initialValues.employeeID}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-3" sm="12" md="3">
                  <FormGroup>
                    <Label className="form-control-label" for="nameEmployee">
                      Nombre del Colaborador
                    </Label>
                    <Input
                      disabled
                      type="text"
                      value={initialValues.nameEmployee}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-3" sm="12" md="3">
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      for="positionEmployee"
                    >
                      Posición
                    </Label>
                    <Input
                      disabled
                      type="text"
                      value={initialValues.positionEmployee}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-3" sm="12" md="3">
                  <FormGroup>
                    <Label className="form-control-label" for="country">
                      País
                    </Label>
                    <Input disabled type="text" value={initialValues.country} />
                  </FormGroup>
                </Col>
              </div>
              <div className="form-row justify-content-center align-items-center">
                <Col
                  className="mt-3 d-flex align-items-center justify-content-center"
                  sm="12"
                  md="12"
                >
                  <Label className="pl-1 mb-4" style={{ textAlign: "justify" }}>
                    Conflicto de interés se entiende como aquella o aquellas
                    situaciones en las que el juicio de una persona se puede ver
                    afectado o influenciado por un interés personal o
                    particular. Es decir, una persona incurre en un conflicto de
                    interés cuando en vez de cumplir con lo debido, podría guiar
                    sus decisiones o actuar en beneficio propio o de un tercero.
                  </Label>
                </Col>
              </div>
              <Divider>
                Relación familiar, romantica o amistosa con una persona física o
                jurídica que es proveedor de GBM
              </Divider>
              {initialValues.isSupplierRelationship === 1 ? (
                <>
                  {moment().month() < 5 && (
                    <Row className="m-3">
                      <Col
                        className="d-flex align-items-center justify-content-end"
                        xs="6"
                        md="9"
                      ></Col>
                      <Col
                        className="d-flex align-items-center justify-content-end"
                        xs="6"
                        md="3"
                      >
                        <Button
                          block
                          size="sm"
                          color="info"
                          className="btn-icon btn-3"
                          onClick={onAddSupplier}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-user-plus" />
                          </span>
                          <span className="btn-inner--text">
                            Agregar un nuevo proveedor
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        {cols.map((row, key) => (
                          <th
                            key={key}
                            style={{
                              whiteSpace: "normal",
                              textAlign: "center",
                            }}
                          >
                            {row.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="list">
                      {initialValues.suppliersExtra.map((row, key) => (
                        <tr key={key}>
                          {cols.map((col, key) => (
                            <th
                              key={key}
                              style={{
                                whiteSpace: "normal",
                                textAlign: "center",
                              }}
                            >
                              {row[col.key]}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className="form-row justify-content-center align-items-center">
                  <Col
                    className="mt-3 d-flex align-items-center justify-content-center"
                    sm="12"
                    md="12"
                  >
                    <Label
                      className="pl-1 mb-4"
                      style={{ textAlign: "justify" }}
                    >
                      No posee ninguna relación familiar, romantica o amistosa
                      con una persona física o jurídica que es proveedor de GBM
                    </Label>
                  </Col>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </Row>
  );
};

ConflictInterestInfo.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ConflictInterestInfo;
