// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import { Col, Container, Row } from "reactstrap";

class ProfileHeader extends React.Component {
  render() {
    const { name } = this.props;

    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: "400px",
            backgroundImage:
              'url("' + require("assets/img/icons/common/gbm-logo.svg") + '")',
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          <span className="mask bg-gradient-info-gbm opacity-8" />

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col xs="12" md="8">
                <h1 className="display-2 text-white">Hola {name}</h1>
                <p className="text-white mt-0 mb-5">
                  Esta es tu página de perfil. Smart & Simple busca facilitarte
                  muchos de los procesos de GBM de una manera automatizada.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

ProfileHeader.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProfileHeader;
