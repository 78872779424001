import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';


const formFields =
{
    materiales: {
        titulo: "Materiales",
        plantilla: "Plantilla_Material-HW-SW-PR_v3.xlsx",
        creacionm: "crearymod",
        metodo: "ambos",
        motherTable: "1",
        generalTable: "generalDataMaterials",
        formTable: "formMaterials",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                required: true,
                type: "select",
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Grupo de artículo",
                placeholder: "Seleccione el Grupo de artículo",
                id: "materialGroup",
                options: "materialGroup",
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                type: "textarea",
                required: false,
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },
            {
                colWidth: "4",
                label: "Flujo de Gestión de ofertas (BAW)",
                placeholder: "Seleccione si tiene Flujo de Gestión de ofertas (BAW)",
                id: "baw",
                options: "baw",
                type: "select",
                required: true,
                optionsDependsOtherDropdown: true,
                dependsAnotherDropdown: false,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "ID de la gestión de BAW ",
                placeholder: "ID de la gestión de BAW ",
                id: "bawManagement",
                options: "bawManagement",
                required: true,
                optionsDependsOtherDropdown: true,
                idDropdownDepends: 1,
                dependsAnotherDropdown: true,
                type: "text",
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ],
        formData: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Tipo de material*",
                placeholder: "Seleccione el Tipo de material",
                id: "materialType",
                type: "select",
                options: "materialType",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "Select",
                required: true
            },
            {
                colWidth: "6",
                label: "Id del material*",
                placeholder: "Ingrese el Id del material",
                id: "idMaterial",
                type: "searchField",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                namePropFuctionModule: "MATERIAL",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Serializable*",
                placeholder: "Seleccione si es serializable",
                id: "serializable",
                type: "select",
                options: "serializable",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Modelo equipo",
                placeholder: "Ingrese el Modelo equipo",
                id: "equipModel",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "6",
                label: "Grupo material 1*",
                placeholder: "Seleccione el Grupo material 1",
                id: "materialGroup1",
                type: "select",
                options: "materialGroup1",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "4",
                label: "Grupo material 2",
                placeholder: "Seleccione el Grupo material 2",
                id: "materialGroup2",
                type: "select",
                options: "materialGroup2",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Grupo posición*",
                placeholder: "Seleccione el Grupo posición",
                id: "positionGroup",
                type: "select",
                options: "positionGroup",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Descripción*",
                id: "description",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Precio",
                placeholder: "Ingrese el precio",
                id: "price",
                type: "number",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "number",
                required: false
            },
            {
                colWidth: "6",
                label: "Proveedor",
                placeholder: "Ingrese el proveedor",
                id: "supplier",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "6",
                label: "Garantía",
                placeholder: "Seleccione el tipo de garantía",
                id: "warrantyType", //Antes era warranty
                type: "select",
                options: "warrantyType",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Observaciones",
                placeholder: "Máximo 255 caracteres",
                id: "observations",
                type: "textarea",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },

        ]
    },
    servicios: {
        titulo: "Ordenes de Servicios y Contratos",
        plantilla: "Plantilla_Servicios_Contratos_Ordenes_Servicios.xlsx",
        creacionm: "crearymod",
        metodo: "ambos",
        motherTable: "7",
        generalTable: "generalDataServices",
        formTable: "formServices",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                required: true,
                type: "select",
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Grupo de artículo",
                placeholder: "Seleccione el Grupo de artículo",
                id: "materialGroup",
                options: "materialGroup",
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                type: "textarea",
                required: false,
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ],
        formData: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Tipo de servicio*",
                placeholder: "Seleccione el Tipo de servicio",
                id: "materialType",
                type: "select",
                options: "materialTypeService",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Id del servicio*",
                placeholder: "ingrese el Id del servicio",
                id: "idMaterial",
                type: "searchField",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                namePropFuctionModule: "MATERIAL",
                required: true
            },
            {
                colWidth: "6",
                label: "Descripción*",
                id: "description",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Unidad de medida*",
                placeholder: "Seleccione la Unidad de medida",
                id: "meditUnit",
                type: "select",
                options: "meditUnit",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Service Profile",
                placeholder: "Seleccione el Service Profile",
                id: "serviceProfile",
                type: "select",
                options: "serviceProfile",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Response Profile",
                placeholder: "Seleccione el Response Profile",
                id: "responseProfile",
                type: "select",
                options: "responseProfile",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Jerarquía*",
                placeholder: "Seleccione la Jerarquía",
                id: "hierarchy",
                type: "select",
                options: "hierarchy",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Grupo material 1*",
                placeholder: "Seleccione el Grupo material 1",
                id: "materialGroup1",
                type: "select",
                options: "materialGroup1",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Descripción Larga*",
                placeholder: "Ingrese una Descripción Larga",
                id: "largeDescription",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Precio",
                placeholder: "Ingrese el Precio",
                id: "price",
                type: "number",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "number",
                required: false
            },
            {
                colWidth: "6",
                label: "Observaciones",
                placeholder: "Máximo 255 caracteres",
                id: "observations",
                type: "textarea",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },

        ]
    },
    repuestos: {
        titulo: "Repuestos",
        plantilla: "Plantilla_Repuestos.xlsx",
        creacionm: "crearymod",
        metodo: "ambos",
        motherTable: "8",
        generalTable: "generalDataSpareParts",
        formTable: "formSpareParts",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                type: "select",
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Grupo de artículo",
                placeholder: "Seleccione el Grupo de artículo",
                id: "materialGroupSpartParts",
                options: "materialGroupSpartParts",
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                type: "textarea",
                required: false,
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ],
        formData: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Id de repuesto*",
                placeholder: "Ingrese el ID del Repuesto",
                id: "spareId",
                options: "",
                type: "searchField",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                namePropFuctionModule: "MATERIAL",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Descripción*",
                id: "description",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Observaciones",
                placeholder: "Máximo 255 caracteres",
                id: "observations",
                type: "textarea",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "textarea",
                required: false
            },

        ]
    },
    clientes: {
        titulo: "Clientes",
        plantilla: "Plantilla_Clientes.xlsx",
        creacionm: "creacion",
        metodo: "ambos",
        motherTable: "2",
        generalTable: "generalDataClients",
        formTable: "formClients",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                type: "select",
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "País que envía la solicitud",
                placeholder: "Seleccione el País que envía la solicitud",
                id: "sendingCountry",
                options: "sendingCountry",
                factor: true,
                type: "select",
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Grupo de cliente",
                placeholder: "Seleccione el Grupo de cliente",
                id: "valueTeam", //antes clientGroup 
                options: "valueTeam",
                type: "select",
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Canal",
                placeholder: "Seleccione el canal",
                id: "channel",
                options: "channel",
                type: "select",
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Sujeto a IVA",
                placeholder: "Seleccione si es Sujeto a IVA",
                id: "subjectVat",
                options: "subjectVat",
                required: true,
                type: "select",
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                type: "textarea",
                required: false,
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ],
        formData: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Tratamiento*",
                placeholder: "Seleccione el Tipo de Tratamiento",
                id: "generalTreatment",
                options: "generalTreatment", //Antes era solo treatment
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "Select",
                required: true
            },
            {
                colWidth: "6",
                label: "Nombre / Razón Social*",
                placeholder: "Ingrese el Nombre / Razón Social",
                id: "businessName",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Representante ventas*",
                placeholder: "Seleccione el Representante ventas",
                id: "salesRepresentative",
                options: "salesRepresentative",
                type: "typeahead",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "typeahead",
                required: true
            },
            {
                colWidth: "6",
                label: "NIF / NRC*",
                placeholder: "Ingrese un NIF / NRC",
                id: "identificationCard",
                options: "",
                type: "searchField",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                namePropFuctionModule: "CED",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Dirección*",
                placeholder: "Ingrese una Dirección",
                id: "address",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Complemento de Dirección/Ciudad*",
                placeholder: "Ingrese el complemento de Dirección/Ciudad",
                id: "additionalAddress",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "País*",
                placeholder: "Seleccione el País*",
                id: "country",
                options: "country",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },

            {
                colWidth: "6",
                label: "Región*",
                placeholder: "Seleccione la región",
                id: "region",
                options: "region",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: true,
                idDropdownDepends: "country",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },

            {
                colWidth: "6",
                label: "Región",
                placeholder: "Digite la región",
                id: "otherRegion",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "region",
                dependsAnotherDropdown: true,
                valueThatDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },

            {
                colWidth: "6",
                label: "Complemento de Región*",
                placeholder: "Seleccione el complemento de región",
                id: "subRegion",
                options: "subRegion",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: true,
                idDropdownDepends: "region",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Email*",
                placeholder: "Ingrese el email",
                id: "email",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Teléfono* (Ingresar sin guiones, parentesis o prefijos)",
                placeholder: "Ingrese el Teléfono",
                id: "phone",
                options: "",
                type: "phone",
                idCountryPhone:"country",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "number",
                required: true
            },
            {
                colWidth: "6",
                label: "Ramo*",
                placeholder: "Seleccione el Ramo",
                id: "branch",
                options: "branch",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Giro de negocio*",
                placeholder: "Seleccione el Giro de negocio",
                id: "businessLine",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Tipo de cliente (Facturación electrónica)*",
                placeholder: "Seleccione el Tipo de cliente (Facturación electrónica)",
                id: "clientType",
                options: "clientType",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: true,
                idDropdownDepends: "country",
                disabled: false,
                haveException: true,
                idHaveException: "country",
                valueHaveException: [251/*Salvador*/, 216/*Panamá*/],
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Actividad económica*",
                placeholder: "Seleccione la actividad económica",
                id: "economicActivity",
                options: "economicActivity",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "country",
                disabled: false,
                haveException: true,
                idHaveException: "country",
                valueHaveException: [251/*Salvador*/],
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "N.NIT 2 (Registro)*:",
                placeholder: "Ingrese el N.NIT 2 (Registro)",
                id: "nit",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "12",
                label: "Datos Comerciales*",
                id: "comercialInfoTitle",
                options: "",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
                required: true
            },
            {
                colWidth: "6",
                label: "Organización de Ventas*",
                placeholder: "Seleccione la Organización de Ventas",
                id: "salesOrganizations", //Antes era salesOrganization
                options: "salesOrganizations",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Distrito de Ventas*",
                placeholder: "Seleccione el Distrito de Ventas",
                id: "salesDistrict",
                options: "salesDistrict",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Grupo de Clientes*",
                placeholder: "Seleccione el Grupo de Clientes",
                id: "clientsGroup", //Antes era clientGroup
                options: "clientsGroup",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Customer Group*",
                placeholder: "Seleccione el Customer Group",
                id: "customerGroup", //Antes era clientGroup
                options: "customerGroup",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Centro suministrador*",
                placeholder: "Seleccione el Centro suministrador",
                id: "supplyingCenter",
                options: "supplyingCenter",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "12",
                label: "Datos de contacto*",
                id: "contactInformationTitle",
                options: "",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
                required: true
            },
            {
                colWidth: "6",
                label: "Tratamiento*",
                placeholder: "Seleccione el Tipo de Tratamiento",
                id: "contactTreatment",
                options: "contactTreatment",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "Select",
                required: true
            },
            {
                colWidth: "6",
                label: "Nombre*",
                placeholder: "Ingrese el nombre",
                id: "name",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Apellido*",
                placeholder: "Ingrese el apellido",
                id: "lastName",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "País*",
                placeholder: "Seleccione el País",
                id: "countryContact",
                options: "country",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },

            {
                colWidth: "6",
                label: "Dirección*",
                placeholder: "Seleccione la Dirección",
                id: "addressContact",
                options: "",
                type: "input",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Email*",
                placeholder: "Ingrese el Email",
                id: "emailContact",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Teléfono* (Ingresar sin guiones, parentesis o prefijos)",
                placeholder: "Ingrese el Telefono",
                id: "phoneContact",
                options: "",
                type: "phone",
                idCountryPhone:"countryContact",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "number",
                required: true
            },
            {
                colWidth: "6",
                label: "Idioma*",
                placeholder: "Seleccione el Idioma",
                id: "language",
                options: "language",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Observaciones",
                placeholder: "Máximo 255 caracteres",
                id: "observations",
                options: "",
                type: "textarea",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
        ]
    },
    proveedores: {
        titulo: "Proveedores",
        plantilla: "Plantilla_Proveedor_v3.xlsx",
        creacionm: "creacion",
        metodo: "linea",
        motherTable: "9",
        generalTable: "generalDataVendors",
        formTable: "formVendors",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                type: "select",
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Sociedad donde se va a utilizar el proveedor",
                placeholder: "Seleccione la Sociedad donde se va a utilizar el proveedor",
                id: "companyCode",
                options: "companyCode", //Antes era supplierCompany
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Vendor Group",
                placeholder: "Seleccione el Vendor Group",
                id: "vendorGroup",
                options: "vendorGroup",
                type: "select",
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                required: false,
                type: "textarea",
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },
        ],
        formData: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },

            {
                colWidth: "6",
                label: "Tratamiento*",
                placeholder: "Seleccione el Tipo de Tratamiento",
                id: "generalTreatment", //antes era treatment
                type: "select",
                options: "generalTreatment",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "Select",
                required: true
            },
            {
                colWidth: "6",
                label: "Nombre / Razón Social*",
                placeholder: "Ingrese el Nombre / Razón Social",
                id: "socialReason",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "NIF / NRC*",
                placeholder: "Ingrese el NIF / NRC",
                id: "nif",
                type: "searchField",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                namePropFuctionModule: "CED",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "NIF / NRC 2*",
                placeholder: "Ingrese el NIF / NRC 2",
                id: "nif2",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Dirección*",
                placeholder: "Ingrese la Dirección",
                id: "address",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Complemento de Dirección/Ciudad",
                placeholder: "Ingrese el Complemento de Dirección/Ciudad",
                id: "additionalAddress",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "6",
                label: "País*",
                placeholder: "Seleccione el País*",
                id: "country",
                options: "country",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },

            {
                colWidth: "6",
                label: "Región*",
                placeholder: "Seleccione la región",
                id: "region",
                options: "region",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: true,
                idDropdownDepends: "country",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },

            {
                colWidth: "6",
                label: "Región*",
                placeholder: "Digite la región",
                id: "otherRegion",
                options: "",
                type: "input",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "region",
                dependsAnotherDropdown: true,
                valueThatDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Email*",
                placeholder: "Ingrese el Email",
                id: "email",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Teléfono* (Ingresar sin guiones, parentesis o prefijos)",
                placeholder: "Ingrese el Teléfono",
                id: "phone",
                type: "phone",
                idCountryPhone:"country",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "number",
                required: true
            },
            {
                colWidth: "6",
                label: "Sujeto a IVA*",
                placeholder: "Seleccione si es Sujeto a IVA",
                id: "subjectVat",
                type: "select",
                options: "subjectVat",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "Select",
                required: true
            },
            {
                colWidth: "6",
                label: "Giro de Negocio*",
                placeholder: "Seleccione el giro de negocio",
                id: "lineOfBusiness",
                type: "input",
                options: "lineOfBusiness",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                haveException: true,
                idHaveException: "companyCode",
                valueHaveException: [5/*Salvador*/],
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Categoría del proveedor*",
                placeholder: "Seleccione la Categoría del proveedor",
                id: "vendorCategory",
                type: "select",
                options: "vendorCategory",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Tipo de proveedor*",
                placeholder: "Seleccione el Tipo de proveedor",
                id: "vendorType",
                options: "vendorType",
                type: "select",
                options: "vendorType",
                searchValue: true,
                optionsDependsOtherDropdown: true,
                idDropdownDepends: "vendorGroup",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Propósito de compra al proveedor*:",
                placeholder: "Máximo 255 caracteres",
                id: "purchasingPurpose",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "12",
                label: "Datos bancarios",
                id: "bankInfoTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Nombre Banco Beneficiario*:",
                placeholder: "Seleccione el Nombre del Banco Beneficiario:",
                id: "bankName",
                options: "bankName",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: true,
                idDropdownDepends: "companyCode",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },

            {
                colWidth: "6",
                //label: "Cuenta Bancaria Moneda Local*",
                label:
                    [
                        {
                            name: "Iban Moneda Local*",
                            idCompanyCode: [1],
                            showButtonSearchIban: true
                        },
                        {
                            name: "Cuenta Bancaria Moneda Local*",
                            idCompanyCode: ["default"],
                            showButtonSearchIban: false
                        }

                    ],
                id: "localCurrencyAccount",
                type: "bankingFields",
                options: "",
                notShowWithIdsCompanyCode: ["Nothing"],
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                //label: "Cuenta bancaria USD*",
                label:
                    [
                        {
                            name: "Iban USD",
                            idCompanyCode: [1],
                            showButtonSearchIban: true
                        },
                        {
                            name: "Cuenta bancaria USD",
                            idCompanyCode: ["default"],
                            showButtonSearchIban: false
                        }

                    ],
                id: "usdCurrencyAccount",
                options: "",
                type: "bankingFields",
                options: "",
                notShowWithIdsCompanyCode: [5, 3, 10],
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },


            {
                colWidth: "6",
                label: "Datos Bancarios Adicionales",
                placeholder: "Máximo 255 caracteres",
                id: "additionalDetails",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "12",
                label: "Datos Compras",
                id: "paymentInfoTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Condición de pago*",
                placeholder: "Seleccione la condición de pago",
                id: "paymentCondition",
                type: "select",
                options: "paymentCondition",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
        ]
    },
    contactos: {
        titulo: "Contactos",
        plantilla: "Plantilla_Contactos.xlsx",
        creacionm: "creacion",
        metodo: "ambos",
        motherTable: "3",
        generalTable: "generalDataContact",
        formTable: "formContact",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                type: "select",
                required: true,
                disabled: true,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "País que envia la solicitud",
                placeholder: "Seleccione el País que envia la solicitud",
                id: "sendingCountry",
                options: "sendingCountry",
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                type: "textarea",
                required: false,
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ],
        formData: [
            {
                colWidth: "12",
                label: "Datos Generales",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.    
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Id del cliente*",
                placeholder: "Ingrese el Id del Cliente",
                id: "clientId",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Nombre del Cliente",
                placeholder: "Ingrese el Nombre del Cliente",
                id: "nameClient",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "12",
                label: "Datos Contacto*",
                id: "contactInformationTitle",
                type: "Title", //subtitle normal.
                options: "function",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
                required: true
            },
            {
                colWidth: "6",
                label: "Tratamiento*",
                placeholder: "Seleccione el Tipo de Tratamiento",
                id: "contactTreatment", //Antes era treatment
                type: "select",
                options: "contactTreatment",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                disabled: false,
                visibleDefault: false,
                elementType: "Select",
                required: true
            },
            {
                colWidth: "6",
                label: "Nombre*",
                placeholder: "Ingrese el Nombre",
                id: "firstName",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Apellido*",
                placeholder: "Ingrese el Apellido",
                id: "lastName",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "País*",
                placeholder: "Seleccione el País",
                id: "country",
                type: "select",
                options: "country",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Dirección*",
                placeholder: "Seleccione el Dirección",
                id: "address",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Email*",
                placeholder: "Ingrese el Email",
                id: "email",
                type: "input",
                searchValue: false,
                options: "",
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Teléfono* (Ingresar sin guiones, parentesis o prefijos)",
                placeholder: "Ingrese el Teléfono",
                id: "phone",
                type: "phone",
                idCountryPhone:"country",
                searchValue: false,
                options: "",
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "number",
                required: true
            },
            {
                colWidth: "6",
                label: "Idioma*",
                placeholder: "Seleccione el Idioma",
                id: "language",
                type: "select",
                options: "language",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Función / Puesto",
                placeholder: "Seleccione la Función / Puesto",
                id: "position",
                type: "select",
                options: "position",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Observaciones",
                placeholder: "Máximo 255 caracteres",
                id: "observations",
                type: "textarea",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "textarea",
                required: false
            },
        ]


    },
    materialesservicio: {
        titulo: "Materiales de Servicio",
        plantilla: "Plantilla_Mat_Tercero.xlsx",
        creacionm: "crearymod",
        metodo: "ambos",
        motherTable: "6",
        generalTable: "generalDataServiceMaterials",
        formTable: "formServiceMaterials",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                type: "select",
                required: true,
                disabled: true,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Grupo de artículo",
                placeholder: "Seleccione el Grupo de artículo",
                id: "materialGroup",
                options: "materialGroup",
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                required: false,
                type: "textarea",
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ],
        formData: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            // {
            //     colWidth: "6",
            //     label: "Tipo de material",
            //     placeholder: "Seleccione el Tipo de material",
            //     id: "materialType",
            //     options: "materialType",
            //     type: "select",
            //     disabled: false,
            //     visibleDefault: false,
            //     elementType: "Select"
            // },
            {
                colWidth: "6",
                label: "ID del material*",
                placeholder: "Ingrese el ID del material",
                id: "idMaterial",
                type: "searchField",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                namePropFuctionModule: "MATERIAL",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Descripción*",
                placeholder: "Máximo 255 caracteres",
                id: "description",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Unidad Medida*",
                placeholder: "Seleccione la Unidad de Medida",
                id: "meditUnit",
                type: "select",
                options: "meditUnit",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Jerarquía*",
                placeholder: "Seleccione la Jerarquía",
                id: "hierarchy",
                type: "select",
                options: "hierarchy",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Grupo material 1*",
                placeholder: "Seleccione el Grupo material 1",
                id: "materialGroup1",
                type: "select",
                options: "materialGroup1",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Sector comercial",
                placeholder: "Seleccione el Sector comercial",
                id: "commercialSector", //Antes era commercialSection
                type: "select",
                options: "commercialSector",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Precio",
                placeholder: "Ingrese el Precio",
                id: "price",
                type: "number",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "number",
                required: false
            },
            {
                colWidth: "6",
                label: "Descripción Larga*",
                id: "longDescription",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Observaciones",
                placeholder: "Máximo 255 caracteres",
                id: "observations",
                type: "textarea",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "textarea",
                required: false
            },
        ]


    },
    equipos: {
        titulo: "Equipos",
        plantilla: "Plantilla_Equipos.xlsx",
        creacionm: "crearymod",
        metodo: "ambos",
        motherTable: "4",
        generalTable: "generalDataEquipment",
        formTable: "formEquipment",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                type: "select",
                required: true,
                disabled: true,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "País",
                placeholder: "Seleccione el País",
                id: "sendingCountry",
                options: "sendingCountry",
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                required: false,
                type: "textarea",
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ],
        formData: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Id del material*",
                placeholder: "Ingrese el ID del material",
                id: "materialId",
                type: "searchField",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                namePropFuctionModule: "MATERIAL",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Serie de equipo*",
                placeholder: "Ingrese la Serie de equipo",
                id: "equipmentSeries",
                type: "searchField",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                namePropFuctionModule: "SERIE",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Descripción*",
                id: "description",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Sold to Party",
                placeholder: "Ingrese Sold to Party",
                id: "soldToParty",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "6",
                label: "Ship to Party",
                placeholder: "Ingrese Ship to Party",
                id: "shipToParty",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "6",
                label: "Fecha de instalación*",
                placeholder: "Seleccione la Fecha de instalación",
                id: "instalationDate",
                type: "date",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Fin de garantía",
                placeholder: "Seleccione el Fin de garantía",
                id: "endOfWarranty",
                type: "date",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },


            {
                colWidth: "6",
                label: "Company Code",
                placeholder: "Seleccione el Company Code",
                id: "companyCode",
                options: "companyCode",
                type: "select",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Asset",
                placeholder: "Ingrese el Asset",
                id: "asset",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "6",
                label: "Placa",
                placeholder: "Ingrese la Placa",
                id: "plate",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
            {
                colWidth: "6",
                label: "Observaciones",
                placeholder: "Máximo 255 caracteres",
                id: "observations",
                type: "textarea",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: false
            },
        ]


    },
    ibase: {
        titulo: "Ibase",
        plantilla: "Plantilla_Ibase_New.xlsx",
        creacionm: "crearymod",
        metodo: "linea",
        motherTable: "5",
        generalTable: "generalDataIbase",
        formTable: "formIbaseCreation,formIbaseModification",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                type: "select",
                required: true,
                disabled: true,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "País",
                placeholder: "Seleccione el País",
                id: "sendingCountry",
                options: "sendingCountry", //Antes era country
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                type: "textarea",
                required: false,
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ],
        formDataCreation: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Descripción*",
                id: "description",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Nombre*",
                placeholder: "Ingrese el Nombre",
                id: "name",
                type: "input",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "País",
                placeholder: "Seleccione el País",
                id: "gbmCountries", //Antes era country
                type: "select",
                options: "gbmCountries",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Cliente",
                placeholder: "Ingrese el Cliente",
                id: "client",
                type: "number",
                options: "",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "number",
                required: false
            },
            {
                colWidth: "6",
                label: "Método de Ingreso",
                placeholder: "Seleccione el Metodo de Ingreso",
                id: "incomeMethod",
                type: "select",
                options: "incomeMethod",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: false
            },
            {
                colWidth: "6",
                label: "Equipos",
                placeholder: "Ingrese los Equipos",
                id: "equipments",
                type: "textarea",
                options: "equipments",
                searchValue: false,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "textarea",
                required: false
            },
        ],
        formDataModification: [
            {
                colWidth: "12",
                label: "Información General",
                id: "generalInformationTitle",
                type: "Title", //subtitle normal.
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                valueThatDepends: "",
                disabled: false,
            },
            {
                colWidth: "6",
                label: "Ibase*",
                placeholder: "Ingrese el Ibase",
                id: "ibase",
                type: "input",
                disabled: false,
                visibleDefault: false,
                elementType: "input",
                required: true
            },
            {
                colWidth: "6",
                label: "Acción*",
                placeholder: "Seleccione la Acción",
                id: "action",
                type: "select",
                options: "action",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Método de Ingreso*",
                placeholder: "Seleccione el Método de Ingreso",
                id: "incomeMethod",
                type: "select",
                options: "incomeMethod",
                searchValue: true,
                optionsDependsOtherDropdown: false,
                idDropdownDepends: "",
                disabled: false,
                visibleDefault: false,
                elementType: "select",
                required: true
            },
            {
                colWidth: "6",
                label: "Equipos*",
                placeholder: "Ingrese los Equipos",
                id: "equipment",
                type: "textarea",
                disabled: false,
                visibleDefault: false,
                elementType: "textarea",
                required: true
            },
        ]

    },
    garantias: {
        titulo: "Garantías",
        plantilla: "Plantilla_Garantias.xlsx",
        creacionm: "modificacion",
        metodo: "masivo",
        motherTable: "10",
        generalTable: "generalDataWarranties",
        generalData: [
            {
                colWidth: "4",
                label: "Tipo de gestión",
                placeholder: "Seleccione el Tipo de gestión",
                id: "typeOfManagement",
                options: "typeOfManagement",
                type: "select",
                required: true,
                disabled: true,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "País",
                placeholder: "Seleccione el País",
                id: "sendingCountry",   //Antes era solo country
                options: "sendingCountry",
                type: "select",
                factor: true,
                required: true,
                disabled: false,
                visibleDefault: false,
                elementType: "Select"
            },
            {
                colWidth: "4",
                label: "Comentarios",
                id: "comment",
                type: "textarea",
                required: false,
                disabled: false,
                visibleDefault: false,
                elementType: "input"
            },

        ]
    },
}


export default formFields;



