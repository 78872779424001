// core antd
import { Icon, Upload } from "antd";
// url variables api
import { urlBase } from "api/urls.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Modal,
} from "reactstrap";

const { Dragger } = Upload;

const UploadReference = (props) => {
  const {
    title,
    showModal,
    idRequest,
    referenceFilesList,
    toggleModal,
    onFileListReference,
    onFinishReference,
  } = props;

  const uploadProps2 = {
    name: "reference",
    multiple: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    action: `${urlBase}/digital-request/upload-references-files/${idRequest}`,
    onChange: onFileListReference,
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent p-1">
            <div className="text-muted text-center mt-2 mb-2">
              <h5 className="surtitle">{title}</h5>
            </div>
          </CardHeader>
          <CardBody className="px-lg-3 py-lg-3">
            <div className="form-row align-items-center">
              <Col sm="12" className="m-1">
                <blockquote className="blockquote text-center">
                  <p className="mb-0">Cargar Referencia</p>
                  <footer className="blockquote-footer">
                    <cite title="Source Title">
                      Por favor, cargue la referencia que desea
                    </cite>
                  </footer>
                </blockquote>
                <div className="form-row align-items-center">
                  <Col className="m-1" sm="12">
                    <Dragger {...uploadProps2} fileList={referenceFilesList}>
                      <p className="ant-uploading-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-uploading-text">
                        Suelte el archivo aquí o haga clic para cargar.
                      </p>
                      <p className="ant-uploading-hint">
                        *Se debe cargar la referencia deseada.
                      </p>
                    </Dragger>
                  </Col>
                </div>
              </Col>
            </div>
            <div className="form-row align-items-center justify-content-center">
              <Col
                className="mb-3 d-flex align-content-center justify-content-center"
                sm="12"
                md="3"
              >
                <Button
                  size="md"
                  color="success"
                  className="btn-icon"
                  onClick={onFinishReference}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-cloud-upload-alt" />
                  </span>
                  <span className="btn-inner--text">Finalizar</span>
                </Button>
              </Col>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

UploadReference.propTypes = {
  title: PropTypes.string.isRequired,
};

export default UploadReference;
