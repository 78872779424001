import React, { useState, useEffect } from "react";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { Container } from "reactstrap";

import { Spin } from 'antd';

import HistoryQueryMainTable from "components/ExitInterview/History/HistoryQueryMainTable.jsx";
import InfoModal from "components/ExitInterview/History/InfoModal";
import CardsInfo from "components/Shared/Cards/CardInfo";
import HistoryQueryFilters from "components/ExitInterview/History/HistoryQueryFilters.jsx";
//Dispatch
import { useDispatch } from "react-redux";
//Actions
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getInterview, getMasterData, getCountriesByHCM, getDataCardsHistory } from 'actions/exitInterview';
import { getTeamsUserAccess, getUserProtected } from 'selectors/adminLayout.jsx';

const HistoryQuery = ({ title }) => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [rowsInfoModal, setRowsInfoModal] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoad] = useState(true);
  const [rowsModal, setRowsModal] = useState({
    rows: [],
    cards: [],
    infoRow: ""
  });
  const [department, setDepartment] = useState([]);
  const [options, setOptions] = useState({
    goBackGBM: [],
    exitReason: [],
    countriesData: [],
    exitType: [],
    rehirable: [],
    masterData: [],
    countries: [],
    companyType: []
  });
  const [filters, setFilters] = useState({
  });
  const Toast = MySwal.mixin({
    toast: true,
    position: "bottom-right",
    showConfirmButton: true,
    timer: 5000,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  //#region
  const [page, setPage] = useState(1);
  //#endregion

  const getUniqueElementsToArray = (data, key) => {
    // Filtra los valores únicos de cada data de la tabla
    let uniqueValues = [...new Set(data.map((item) => item[key]))];

    // Filtra los valores null
    uniqueValues = uniqueValues.filter((value) => value !== null);

    // Mapea los valores únicos y los convierte en un array de json con la estructura de label: value, para ser recibido como filtro en UserAccess
    let uniqueElements = [{ label: "Todas", value: 200, filter: "" }, ...uniqueValues.map((value, key) => ({ label: value, filter: value, value: key }))];
    console.log(uniqueElements);
    return uniqueElements;
  };
  useEffect(() => {
    const getRowsData = async () => {
      await handleRowInfo();
    }
    getRowsData();
  }, [])
  const handleRowInfo = async () => {

    dispatch(getInterview({ dates: "" })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        console.log(payload.data.payload.data);
        setRowsModal(prevState => ({
          ...prevState,
          rows: payload.data.payload.data
        }))
        setDepartment(getUniqueElementsToArray(payload.data.payload.data, "department"));
      }
    })
    dispatch(getDataCardsHistory({})).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal(prevState => ({
          ...prevState,
          cards: payload.data.payload.data
        }))
      }
    })


    dispatch(getMasterData()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setOptions((prevState) => ({
          ...prevState,
          masterData: payload.data.payload.masterData,
          goBackGBM: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.goBackGBM],
          exitReason: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.exitReason],
          countriesData: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.countriesData],
          exitType: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.exitType],
          rehirable: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.rehirable],
          companyType: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.companyType],
          countries: payload.data.payload.masterData.countries,
        }));

        setLoad(false);
      }
    });
    const nombre = getUserProtected().NOMBRE;
    dispatch(getCountriesByHCM({ name: nombre })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setCountries(payload.data.payload.data)
      }
    })
  }
  const renderRowsByFilters = (rows) => {
    let rows2 = [];
    if (Object.keys(filters).length === 0) {
      rows = rows.filter(
        (item) => {
          for (const x of countries) {
            if (item.country === x.countryId) {
              rows2.push(item)
            }
          }
        }
      )
      return rows2
    }
    else {
      const filterKeys = Object.keys(filters);
      for (const element of filterKeys) {
        const valueFiltered = filters[element].toLowerCase();
        rows.filter(
          (item) => {
            for (const x of countries) {
              if (item.country === x.countryId) {
                rows2.push(item)
              }
            }
          }
        )
        rows2 = rows2.filter((item) => item[element].toLowerCase().indexOf(valueFiltered) != -1)
      }
    }
    return rows2

  }
  //Funcion para los filtros por columna
  const handleOnfilterDinamic = (constant, e) => {
    console.log(constant, e);
    setFilters(prevState => ({
      ...prevState,
      [constant]: e
    }))
  }
  const handleOnOpenModal = (row) => {
    setOpenModal(true);
    setRowsInfoModal(row)
  }
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const teams = getTeamsUserAccess();
    if (teams.some((row) => row.indexOf("ExitInterview Admin") !== -1)) {
      setIsAdmin(true)
    }
  }, []);

  const cardInformation = [
    { color: "bg-gradient-info", title: "Total de entrevistas", text: rowsModal.cards.allInterviews, icon: "fas fa-clipboard", xes: "4" },
    { color: "bg-gradient-success", title: "Entrevistas Completadas", text: rowsModal.cards.completed, icon: "far fa-check-circle", xes: "4" },
    { color: "bg-gradient-warning", title: "Entrevistas en Borrador", text: rowsModal.cards.draft, icon: "far fa-pause-circle", xes: "4" },
  ]
  console.log(filters);
  return (
    <div>
      <AdminHeader name={title} parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Spin size="large" spinning={loading}>
          <InfoModal
            openModal={openModal}
            closeModal={setOpenModal}
            rowInfo={rowsInfoModal}
            options={options}
            Toast={Toast}
            setRowsModal={setRowsModal}
            setRowsInfotModal={setRowsInfoModal}
            isAdmin={isAdmin}
          />
          <CardsInfo
            cardInformation={cardInformation}
          />

          <HistoryQueryFilters
            dataSelect={rowsModal.colums}
            setRowsModal={setRowsModal}
            Toast={Toast}
            setLoad={setLoad}
            rows={rowsModal.rows}
            setFilters={setFilters}
            filterDinamic={handleOnfilterDinamic}
            department={department}
          />
          <HistoryQueryMainTable
            handleOnOpenModal={handleOnOpenModal}
            setPage={setPage}
            page={page}
            setRowsModal={setRowsModal}
            Toast={Toast}
            setLoading={setLoad}
            filtersValue={filters}
            rows={renderRowsByFilters(rowsModal.rows)}
            filters={handleOnfilterDinamic}
          />
        </Spin>
      </Container>
    </div>
  );
};

HistoryQuery.defaultProps = {
  title: "Home"
}

export default HistoryQuery;