import moment from "moment";
import React, { useState } from 'react';
import { Card, CardBody, Table, Pagination, Col, FormGroup, Input, Row, PaginationItem, PaginationLink } from "reactstrap";
import { urlBase } from "api/urls.jsx";
import { encrypter } from 'helpers/desencrypt.jsx';

const QuickQuoteEditFormThree = (
  { dataModal, handleChangeInfo, filesQuote, isAdmin, Toast }
) => {
  const [rowsModal, setRowsModal] = useState([
    {
      colWidth: "4",
      label: "Forma de Entrega",
      id: "method",
      type: "text"
    },
    {
      colWidth: "4",
      label: "Dias de Entrega",
      id: "deliveryDays",
      type: "text"
    },
    {
      colWidth: "4",
      label: "Ver Adjunto",
      id: "attachments",
      type: "text"
    },
    {
      colWidth: "4",
      label: "Nombre de contacto",
      id: "contactName",
      type: "text"
    },
    {
      colWidth: "4",
      label: "Telefono",
      id: "contactPhone",
      type: "text"
    },
    {
      colWidth: "4",
      label: "Email de contacto",
      id: "mailContact",
      type: "text"
    },
    {
      colWidth: "12",
      label: "Comentarios",
      id: "comments",
      type: "text"
    },
  ]);
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 3,
    idFileRequest: "",
  })
  const { page, sizePerPage } = state;

  const [files, setFiles] = useState(
    filesQuote
  )
  const paginations = filesQuote.length > sizePerPage ?
    filesQuote.length / sizePerPage > Math.round(filesQuote.length / sizePerPage) ?
      Math.round(filesQuote.length / sizePerPage) + 1
      : Math.round(filesQuote.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink href="#"
            onClick={(e) => handleOnSetPage(e, i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  //Cambiar de pagina
  const handleOnSetPage = (event, page, who) => {

    setState((prevState) => ({
      ...prevState,
      [who]: page
    }))
  };

  const handleDownloadAllFiles = (e, numCotyzation) => {

    const downloadUrl = `${urlBase}/panama-bids/download-all-files/${encrypter(numCotyzation)}/${"quickQuote"}`;

    fetch(downloadUrl, {
      method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
    })
      .then(response => {
        if (response.ok) {
          window.location.assign(downloadUrl);
        } else {
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
            type: "warning",

          });
        }
      })
      .catch(error => {
        console.error('Error checking file existence:', error);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error al validar el archivo ",
          type: "warning",

        });
      });
  }
  const handleDownloadFile = (e, numCotyzation, files) => {

    const downloadUrl =  `${urlBase}/panama-bids/download-file/${encrypter(numCotyzation)}/${encrypter(files)}/${"quickQuote"}`;

    fetch(downloadUrl, {
      method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
    })
      .then(response => {
        if (response.ok) {
          window.location.assign(downloadUrl);
        } else {
          Toast.fire({
            title: "Atención",
            html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
            type: "warning",

          });
        }
      })
      .catch(error => {
        console.error('Error checking file existence:', error);
        Toast.fire({
          title: "Atención",
          html: "Ocurrio un error al validar el archivo ",
          type: "warning",

        });
      });
  }
  return (
    <div>
      <Card>
        <CardBody >
          <Row>
            {rowsModal.map((row, key) =>
              row.type === "text" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      className="form-control"
                      type={row.type}
                      disabled={isAdmin === true ? false : true}
                      value={dataModal[row.id] || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : row.type === "date" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      type={row.type}
                      disabled={isAdmin === true ? false : true}
                      value={moment.utc(dataModal[row.id]).format("YYYY-MM-DD") || ""}
                      className="basic-multi-select text-dark"
                    ></Input>
                  </FormGroup>
                </Col>
              ) : (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <h5 htmlFor="" key={key}>
                      {dataModal[row.id] || ""}
                    </h5>
                  </FormGroup>
                </Col>
              )
            )}
          </Row>
        </CardBody>
        <>
          {
            <>
              <a
                disabled={filesQuote[0] && (filesQuote[0].files === null ? true : false)}
                title="Descargar todos los archivos"
                id="downloadAllFiles"
                onClick={(e) => handleDownloadAllFiles(e, dataModal.numCotyzation)}
                style={{ marginRight: "5px" }}
              >
                <span className="btn btn-primary">
                  <i className="fas fa-download" />
                </span>
              </a>
            </>
          }
          <Table
            className="align-items-center table-flush"
            responsive
            striped={true}
            hover={true}
            style={{ marginTop: "7px" }}
          >
            <thead className="thead-light">
            </thead>
            <tbody className="list">
              {
                filesQuote.length ?
                  filesQuote.slice((page - 1) * sizePerPage, page * sizePerPage).map((file, key2) => (
                    <tr key={key2}>
                      <td>
                        <a
                        
                          onClick={(e) => handleDownloadFile(e, dataModal.numCotyzation, file.files)}
                        >
                          {file.files}
                        </a>
                      </td>
                    </tr>
                  ))
                  :
                  <>
                    <tr>
                      <td className="justify-content-md-center">
                        No tiene documentos asociados
                      </td>
                    </tr>
                  </>
              }
            </tbody>
          </Table>

          <nav aria-label="...">
            <Row className="align-items-center">
              <Col xs='12' md='12' className="p-1">
                <Pagination
                  style={{ marginLeft: "30px" }}
                  className="pagination mb-0"
                  listClassName=" mb-0"
                >
                  {
                    renderPaginations()
                  }
                </Pagination>
              </Col>
            </Row>
          </nav>
        </>
      </Card>

    </div>
  );
};

export default QuickQuoteEditFormThree;