// javascipt plugin for creating charts
import Chart from "chart.js";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin used to create charts
import { Pie } from "react-chartjs-2";
// reactstrap components
import { Card, CardBody, CardHeader } from "reactstrap";
// core components
import {
  // global options for the charts
  chartOptions,
  // function that adds the global options to our charts
  parseOptions
} from "variables/charts.jsx";

class ChartPie extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {

    const { title, subtitle, dataResponseRate } = this.props;

    return (
      <>
        <Card>
          <CardHeader>
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </CardHeader>
          <CardBody>
            <div className="chart">
              <Pie
                data={dataResponseRate.data}
                options={dataResponseRate.options}
                className="chart-canvas"
                id="chart-pie"
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

ChartPie.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  dataResponseRate: PropTypes.object.isRequired,
};

export default ChartPie;