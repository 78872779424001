/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Row, Table } from "reactstrap";

const DeleteEquipments = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    initialValues,
    onDelete,
  } = props;

  const onChange = (event) => {
    const { id } = event.target;
    initialValues[id] = !initialValues[id];
  };

  return (
    <>
      <Card className="bg-default shadow">
        <CardHeader className="bg-transparent border-0">
          <Row>
            <Col xs="12" md='9'>
              <h6 className="surtitle text-white">{title}</h6>
              <h5 className="h3 mb-0 text-white">{subtitle}</h5>
            </Col>
            <Col className="d-flex align-items-center justify-content-end" xs="12" md="3">
              <Button
                className="btn-neutral btn-round btn-icon"
                color="success"
                onClick={() => onDelete(Object.keys(initialValues), initialValues)}
                size="sm"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text">Eliminar</span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <Table
          className="align-items-center  table-dark table-flush"
          responsive
        >
          <thead className="thead-dark">
            <tr>
              {
                cols.map((row, key) => (row.hide === false && <th key={key}>{row.name}</th>))
              }
              <th />
            </tr>
          </thead>
          <tbody className="list">
            {
              rows.map((row, key) => (
                <tr key={key}>
                  {
                    cols.map((col, key) => (
                      col.hide === false &&
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                        {row[col.key]}
                      </th>
                    ))
                  }
                  <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                    <label className="custom-toggle">
                      <input
                        id={row.id}
                        type="checkbox"
                        defaultChecked={initialValues[row.id]}
                        onChange={(e) => onChange(e)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Sí"
                      />
                    </label>
                  </th>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Card>
    </>
  );
};

DeleteEquipments.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DeleteEquipments;
