// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardBody, CardImg } from "reactstrap";

const CardEmpty = props => {

  const { title, subtitle } = props;

  return (
    <>
      <Card className="flex align-items-center">
        <CardImg
          top
          alt="Sin resultados"
          src={require("assets/img/theme/empty.svg")}
          style={{ width: "280px", margin: "1rem" }}
        />
        <CardBody>
          <>
            {/* <img
              alt="..."
              className="img-center img-fluid shadow shadow-lg--hover"
              src={require("assets/img/theme/empty.svg")}
              style={{ width: "140px" }}
            /> */}
            <div className="pt-4">
              <h5 className="h3 title">
                <span className="d-block mb-1 text-center">{title}</span>
                <small className="h4 font-weight-light text-muted">
                  {subtitle}
                </small>
              </h5>
            </div>
          </>
        </CardBody>
      </Card>
    </>
  );
};

CardEmpty.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default CardEmpty;