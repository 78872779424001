// core actions
import {
  findAllAllergies,
  findAllDiseases,
  findAllInterventions,
  findEmergencyContacts,
  findRecordsByRol,
  findAllOtherMedicalInfo,
  downloadAttachment,
  downloadMedicalRecordsAdmin,
  downloadAttachmentRecords,
} from "actions/medicalRecords.jsx";
// antd components
import { Spin } from "antd";
// momentjs library
import moment from "moment";
// core components Medical Records
import FiltersCard from "components/MedicalRecords/FiltersCard.jsx";
import RecordDetail from "components/MedicalRecords/RecordDetail.jsx";
import RecordsCard from "components/MedicalRecords/RecordsCard.jsx";
import MedicinesModal from "components/Profile/MedicinesModal.jsx";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
import DateRanges from "components/Shared/DateRanges/DateRanges.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getAllAllergies,
  getAllDiseases,
  getAllInterventions,
  getEmergencyContacts,
  getFiltersOptions,
  getRecordsByRol,
  getAllOtherMedicalInfo,
} from "selectors/medicalRecords.jsx";
class Records extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      parent: true,
      loading: true,
      message: null,
      page: 1,
      sizePerPage: 25,
      filtersValues: {
        offices: {
          value: 0,
          label: "Todos",
        },
        colabs: {
          value: 0,
          label: "Todos",
        },
        bloodType: {
          value: 0,
          label: "Todos",
        },
      },
      recordSelected: null,
      modalViewMedicines: {
        showModal: false,
        values: [],
      },
      modalDateRange: false,
      startDate: moment().subtract(3, "months").startOf("month"),
      endDate: moment().endOf("month"),
    };
  }

  componentWillMount() {
    this.props.findRecordsByRol("PA").then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          parent: false,
          loading: false,
        });
      }
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnClearFilters = () => {
    this.setState({
      filtersValues: {
        offices: {
          value: 0,
          label: "Todos",
        },
        colabs: {
          value: 0,
          label: "Todos",
        },
        bloodType: {
          value: 0,
          label: "Todos",
        },
      },
    });
  };

  handleOnChangeFilters = (key, event) => {
    const { filtersValues } = this.state;
    filtersValues[key] = event;
    this.setState({
      filtersValues,
      page: 1,
    });
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  renderRowsFiltered = (rows) => {
    const {
      filtersValues: { offices, colabs, bloodType },
    } = this.state;
    if (offices.value !== 0) {
      rows = rows.filter((row) => row.officeName === offices.label);
    }
    if (colabs.value !== 0) {
      rows = rows.filter((row) => row.name === colabs.label);
    }
    if (bloodType.value !== 0) {
      rows = rows.filter((row) => row.bloodType === bloodType.label);
    }
    return rows;
  };

  handleOnOpenRecord = (row) => {
    const { id } = row;
    this.setState({
      loading: true,
      message: "Cargando la información del registro médico",
    });
    this.props.findEmergencyContacts(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.props.findAllAllergies(id);
        this.props.findAllDiseases(id);
        this.props.findAllInterventions(id);
        this.props.findAllOtherMedicalInfo(id);
        this.setState({
          recordSelected: row,
        });
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnBackMedicalInfo = () => {
    this.setState({
      recordSelected: null,
    });
  };

  handleOnViewMedications = (row) => {
    const { medications } = row;
    this.setState({
      modalViewMedicines: {
        showModal: true,
        values: medications,
      },
    });
  };

  handleOnToggleModalMedicines = () => {
    this.setState({
      modalViewMedicines: {
        showModal: false,
        values: [],
      },
    });
  };

  handleOnDownloadAttachment = (row) => {
    const { id } = row;
    this.setState({
      loading: true,
      message: "Descargando el documento adjuntado",
    });
    this.props.downloadAttachment(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          this.notify(
            "warning",
            "Atención",
            "No se logro descargar el archivo adjunto seleccionado"
          );
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const url = window.URL.createObjectURL(new Blob([res.payload.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", row.filename);
        document.body.appendChild(link);
        link.click();
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnExportData = () => {
    this.props.downloadMedicalRecordsAdmin().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          this.notify(
            "warning",
            "Atención",
            `No se logro descargar la información del modelo seleccionado`
          );
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const url = window.URL.createObjectURL(new Blob([res.payload.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Reporte Expedientes Medicos.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  handleOnDownloadAttachmentsRecords = () => {
    const { startDate, endDate } = this.state;
    this.setState({
      loading: true,
      message:
        "Descargando los documentos adjuntados, este proceso puede demorar unos minutos",
    });
    this.props
      .downloadAttachmentRecords({ startDate, endDate })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            this.notify(
              "warning",
              "Atención",
              `No se logro descargar la información del modelo seleccionado`
            );
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([res.payload.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Adjuntos.zip`);
          document.body.appendChild(link);
          link.click();
          this.notify(
            "success",
            "Exito",
            "Se descargaron los archivos adjuntados."
          );
        }
        this.setState({
          loading: false,
          message: null,
        });
      });
  };

  /**
   * Funcion para cuando se selecciona una fecha ya sea de inicio o de fin, la agrega a la variable
   * respectiva en el estado
   * @memberof Nodes
   */
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: moment(date).subtract(3, "months"),
        endDate: date,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: moment(date).add(3, "months"),
      });
    } else {
      if (who === "endDate") {
        this.setState({
          startDate: moment(date).subtract(3, "months"),
          [who]: date,
        });
      } else {
        this.setState({
          [who]: date,
          endDate: moment(date).add(3, "months"),
        });
      }
    }
  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  render() {
    const {
      name,
      parentName,
      columns,
      records,
      filterOptions,
      allergies,
      diseases,
      interventions,
      othersMedicalInfo,
      emergencyContacts,
    } = this.props;

    const {
      loading,
      message,
      parent,
      page,
      startDate,
      endDate,
      sizePerPage,
      filtersValues,
      modalDateRange,
      recordSelected,
      modalViewMedicines,
    } = this.state;

    return (
      <>
        <MedicinesModal
          title="Medicamentos Agregados"
          modalOptions={modalViewMedicines}
          toggleModal={this.handleOnToggleModalMedicines}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {!parent ? (
              <>
                {recordSelected ? (
                  <RecordDetail
                    title="Expediente Médico"
                    subtitle="Información Médica del Expediente"
                    data={{
                      emergencyContacts,
                      allergies,
                      diseases,
                      interventions,
                      othersMedicalInfo,
                      attachments: recordSelected.attachments
                        ? recordSelected.attachments
                        : [],
                    }}
                    viewMedications={this.handleOnViewMedications}
                    onBack={this.handleOnBackMedicalInfo}
                    onDownload={this.handleOnDownloadAttachment}
                  />
                ) : (
                  <>
                    <Row>
                      <Col>
                        <FiltersCard
                          title="Filtros"
                          subtitle="Aplicar filtros a los expedientes médicos"
                          values={filtersValues}
                          options={filterOptions}
                          onClear={this.handleOnClearFilters}
                          onChangeFilters={this.handleOnChangeFilters}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <DateRanges
                          title="Rango de fechas descarga de adjuntos"
                          subtitle="Ingrese un rango de fechas para descargar los adjuntos."
                          startDate={startDate}
                          endDate={endDate}
                          reactDatetimeChange={this.handleReactDatetimeChange}
                          classNameReactDatetimeDays={
                            this.getClassNameReactDatetimeDays
                          }
                        />
                      </Col>
                      <Col>
                        <RecordsCard
                          title="Expedientes Médicos"
                          subtitle="Lista de los expedientes médicos registrados"
                          cols={columns}
                          rows={this.renderRowsFiltered(records)}
                          page={page}
                          sizePerPage={sizePerPage}
                          onSetPage={this.handleOnSetPage}
                          onOpen={this.handleOnOpenRecord}
                          onExport={this.handleOnExportData}
                          onDownloadAttachment={
                            this.handleOnDownloadAttachmentsRecords
                          }
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay expedientes médicos."
                    subtitle="Al día de hoy no tienes expedientes médicos, en el momento que exista un registro médico, aparecerán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

Records.defaultProps = {
  name: "Expedientes",
  parentName: "Expediente Médico",
  columns: [
    { key: "id", name: "#REF" },
    { key: "name", name: "Colaborador" },
    { key: "office", name: "Localidad" },
    { key: "country", name: "País" },
    { key: "bloodType", name: "Tipo de Sangre" },
    { key: "bornDate", name: "Fecha de Nacimiento" },
    { key: "address", name: "Dirección" },
    { key: "createdAt", name: "Creado" },
    { key: "updatedAt", name: "Actualizado" },
  ],
  records: [],
  allergies: [],
  diseases: [],
  interventions: [],
  othersMedicalInfo: [],
  emergencyContacts: [],
};

Records.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  records: PropTypes.array.isRequired,
  allergies: PropTypes.array.isRequired,
  diseases: PropTypes.array.isRequired,
  interventions: PropTypes.array.isRequired,
  othersMedicalInfo: PropTypes.array.isRequired,
  emergencyContacts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  records: getRecordsByRol(state),
  filterOptions: getFiltersOptions(state),
  allergies: getAllAllergies(state),
  diseases: getAllDiseases(state),
  interventions: getAllInterventions(state),
  othersMedicalInfo: getAllOtherMedicalInfo(state),
  emergencyContacts: getEmergencyContacts(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllAllergies,
    findAllDiseases,
    findAllInterventions,
    findEmergencyContacts,
    findRecordsByRol,
    findAllOtherMedicalInfo,
    downloadAttachment,
    downloadMedicalRecordsAdmin,
    downloadAttachmentRecords,
  })(Records)
);
