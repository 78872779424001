/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Input, Button, Card, CardFooter, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";

const Status = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    page,
    onEdit,
    onDelete,
    addStatus,
    sizePerPage,
    onSetPage,
  } = props;

  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => onSetPage(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  };

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="12" md='7'>
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            <Col xs="12" md='5' style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="btn-round btn-icon"
                color="success"
                onClick={addStatus}
                size="sm"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-folder-plus" />
                </span>
                <span className="btn-inner--text">Agregar Estado</span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              {
                cols.map((row, key) => (<th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>{row.name}</th>))
              }
              <th />
            </tr>
          </thead>
          <tbody className="list">
            {
              rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                <tr key={key}>
                  {
                    cols.map((col, key) => (
                      (col.key === "createdAt" || col.key === "updatedAt") ?
                        <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                          {
                            row[col.key] ?
                              moment(row[col.key])
                                .utc()
                                .utcOffset(moment().utcOffset())
                                .format("DD/MM/YYYY") : 'N/A'
                          }
                        </th>
                        :
                        <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                          {row[col.key] ? row[col.key] : 'N/A'}
                        </th>
                    ))
                  }
                  <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                    <a
                      className="table-action table-action"
                      href="#"
                      onClick={() => onEdit(row)}
                    >
                      <i className="fas fa-edit" />
                    </a>
                    <a
                      className="table-action table-action"
                      href="#"
                      onClick={() => onDelete(row)}
                    >
                      <i className="fas fa-trash" />
                    </a>
                  </th>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Card>
    </>
  );
};

Status.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Status;