import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody, Col, Container, Row, FormGroup, InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
// javascript library for dates
import "moment/locale/es";
// antd components
import { Spin } from 'antd';
// shared components
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// core components Document System
import CardFilters from 'components/DocumentSystem/CardFilters.jsx';
import MainTable from 'components/DocumentSystem/MainTable.jsx';
import NewRequest from 'components/DocumentSystem/NewRequest.jsx';
import NewDocumentModal from 'components/DocumentSystem/NewDocumentModal';
import EditDocumentModal from 'components/DocumentSystem/EditDocumentModal';
import ReportingModal from 'components/DocumentSystem/ReportingModal';

import { getTeamsUserAccess } from 'selectors/adminLayout.jsx';

import { getDataRows, getOptionsData, getReportColumnsAction } from 'actions/DocumentSystem.jsx';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { MultiSelect } from "react-multi-select-component";
import "../../assets/css/DocumentSystem.css"

const Home = (props) => {

    //#region Funciones

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region Mensajes de notificacion

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    //#endregion

    //#region effect to display edit checkbox

    const [isAdmin, setIsAdmin] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    useEffect(() => {
        const teams = getTeamsUserAccess();
        // const token = getUserProtected();

        if (teams.some((row) => row.indexOf("Document System Admin") !== -1)) {
            setIsAdmin(true)
        }
        else if (teams.some((row) => row.indexOf("Document System Display") !== -1)) {
            setIsDisplay(true)
        }

    }, []);
    //#endregion
    //#region estado para el boolean del spinner
    const [loading, setLoad] = useState(true);
    //#endregion

    //#region estado principal
    const initialState = {
        rowsTable: [],
        rows: [],
        lengthTable: 1,
        // allRows: true,
        editRow: {},
        editModal: false,
        showNewDocumentModal: false,
        listId: [],
        filesCustomer: [],
        discountTypeNew: {}
    }

    const [state, setState] = useState(initialState);

    const { rowsTable, rows, filesCustomer, editRow, editModal, showNewDocumentModal, listId, discountTypeNew } = state;
    //#endregion

    //#region estado filtros
    const [filters, setFilters] = useState([]);
    //#endregion
    //#region estado de los proveedores, paises, estados, y tipos de documentos
    const [dropdowns, setDropdowns] = useState([]);
    const [options, setOptions] = useState({
        vendors: [],
        documentType: [{
            value: "",
            label: ""
        }],
        discountType: [{
            value: "",
            label: ""
        }],
        statusOptions: [{
            value: "",
            label: "",
            statusType: "",
        }],
        countryOptions: [],
        rejectedReason: []
    });

    const { vendors, documentType, discountType, statusOptions, countryOptions, rejectedReason } = options;

    //#endregion

    //#region descargar las columnas de reporteria
    const [reportingColumns, setReportingColumn] = useState([])
    const [reports, setReports] = useState([])
    // const getReportColumns = async () => {
    //     dispatch(getReportColumnsAction()).then((resp) => {
    //         const { payload } = resp;
    //         //console.log(resp)
    //         if (payload.status === 200) {
    //             console.log(payload.data.payload.reportColumns);
    //             let reportColumns = payload.data.payload.reportColumns;
    //             let j = {
    //                 value: "document_vendors",
    //                 label: "Proveedores", 
    //                 is_foreign_key: 1,
    //                 referenced_table: "vendors",
    //                 referenced_column: "document_vendors"
    //             }
    //             reportColumns.push(j);
    //             setReportingColumn(reportColumns);
    //         }

    //     })
    // }
    //#endregion

    //#region funcion para extraer las opciones
    useEffect(() => {
        const getOptionsR = async () => {
            await getOptions();
            // setLoad(false);
        }
        getOptionsR();

        // const getReportColumnsR = async () => {
        //     await getReportColumns();
        //     // setLoad(false);
        // }
        // getReportColumnsR();

    }, [])

    const getOptions = async () => {

        dispatch(getOptionsData()).then((resp) => {
            const { payload } = resp;
            //console.log(resp)
            if (payload.status === 200) {
                setOptions({
                    vendors: payload.data.payload.masterData.vendors,
                    documentType: payload.data.payload.masterData.document_type,
                    discountType: payload.data.payload.masterData.discountType,
                    statusOptions: payload.data.payload.masterData.document_status,
                    countryOptions: payload.data.payload.masterData.document_country,
                    rejectedReason: payload.data.payload.masterData.rejectedReason,
                })

                setDropdowns(payload.data.payload.masterData);

                let reportColumns = payload.data.payload.reportColumns;
                //columnas extras que no estan en la tabla principal document_system
                let jArray = [
                    {
                        value: "vendors.vendorId",
                        label: "Proveedores",
                        columnType: "int",
                        is_foreign_key: 1,
                        referenced_table: "vendors",
                        referenced_column: "fk_vendor"
                    },
                    {
                        value: "document_sv.documentSV",
                        label: "PO adicionales El Salvador",
                        columnType: "varchar",
                        is_foreign_key: 0,
                        referenced_table: null,
                        referenced_column: "documentSV"
                    },
                    {
                        value: "document_trading.poTrading",
                        label: "Purchase Order Trading",
                        columnType: "varchar",
                        is_foreign_key: 0,
                        referenced_table: null,
                        referenced_column: "poTrading"
                    },
                    {
                        value: "document_quotes.quotes",
                        label: "Quotes Adicionales",
                        columnType: "varchar",
                        is_foreign_key: 0,
                        referenced_table: null,
                        referenced_column: "quotes"
                    }
                ]
                let combinedArray = reportColumns.concat(jArray);
                // reportColumns.push(j);
                console.log(combinedArray)
                setReportingColumn(combinedArray);

                setReports(payload.data.payload.reports);
            }

        })
    }
    //#endregion

    //#region estado de las listas para los filtros del componente CardFilters (si se usa)
    const [lists, setLists] = useState({

        status: {
            value: statusOptions[0],
            statusOptions
        },
        country: {
            value: "",
            countryOptions
        },
        iDateSelected: {
            value: ""
        },
        fDateSelected: {
            value: ""
        }
    })
    const [reloadTable, setRealTable] = useState(false);
    const { status, country } = lists;
    //#endregion

    //#region función para llenar la tabla al cambiar los filtros
    useEffect(() => {
        setLoad(true);
        const getDataR = async () => {
            await getData(lists);

        }
        const { iDateSelected, fDateSelected } = lists;
        if (iDateSelected.value === "" && fDateSelected.value !== "" || iDateSelected.value !== "" && fDateSelected.value === "") {
            setLoad(false);
        }
        else {
            getDataR();
        }


    }, [lists, reloadTable]); //el segundo parametro del useEffect indica que se ejecuta al cambiar el state lists
    //#endregion

    //#region funcion para extraer las solicitudes de acuerdo a los filtros
    const getData = async (lists) => {

        dispatch(getDataRows({ filtros: lists })).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
                setState((filt) => ({
                    ...filt,
                    rows: payload.data.payload.rows,
                    rowsTable: payload.data.payload.rows,
                    lengthTable: payload.data.payload.rows.length,
                }))

            } else {
                setState(
                    initialState
                )
                //Toast.fire({
                //  title: "Atención",
                //  html: payload.response.data.message,
                //    type: 'error'
                //   });

            }
            setLoad(false);
        })
    }
    //#endregion

    //#region funcion search client side
    const [search, setSearch] = useState("");

    //función para buscar por texto los useEffect corren una vez
    useEffect(() => {
        let rowss = [];
        const searchLower = search.toLowerCase();
        for (const element of rowsTable) {
            if (element["documentId"] && element["documentId"].toString().toLowerCase().indexOf(searchLower) !== -1 && !rowss.some((e) => JSON.stringify(e) === JSON.stringify(element))) {
                rowss.push(element);
            }
        }
        setState((filt) => ({
            ...filt,
            rows: searchLower.length ? rowss : rowsTable
        }))
    }, [search]);
    //#endregion

    //#region 
    const [page, setPage] = useState(1);
    //#endregion

    //#endregion

    //console.log(loading)
    const optionsSelect = [
        { value: "isEcommerce", label: "" },
        { value: "detalle", label: "Detalle", disabled: true },
        { value: "id", label: "Id Gestion" },
        { value: "countryName", label: "País" },
        { value: "documentId", label: "Id Documento", disabled: true },
        { value: "customerName", label: "Cliente" },
        { value: "documentVendor", label: "Proveedor" },
        { value: "statusName", label: "Estado" },
        { value: "comment", label: "Comentario" },
        { value: "Cdate", label: "Fecha de creación" },
        { value: "updatedAt", label: "Fecha de Finalización" },
        { value: "createdBy", label: "Solicitante" },
        { value: "documentTrading", label: "PO Trading" },
        { value: "salesOrderOn", label: "SO Onshore" },
        { value: "salesOrderTrad", label: "SO Trading" },
        { value: "purchReq", label: "Purchasing Requesition" },
        { value: "opp", label: "Oportunidad" },
        { value: "quote", label: "Quote" },
        { value: "documentSv", label: "PO de El Salvador" },
        { value: "documentQuotes", label: "Quotes Asociados" },
    ];
    const [selected, setSelected] = useState(
        [
            { value: "isEcommerce", label: "" },
            { value: "detalle", label: "Detalle" },
            { value: "countryName", label: "País" },
            { value: "documentId", label: "Id Documento" },
            { value: "documentVendor", label: "Proveedor" },
            { value: "statusName", label: "Estado" },
            { value: "Cdate", label: "Fecha de creación" },
            { value: "createdBy", label: "Solicitante" },
        ]
    );
    //Capturar los valores de los inputs de los filtros
    const handleOnfilterDinamic = (constant, e) => {
        console.log(constant, e);

        setFilters(prevState => ({
            ...prevState,
            [constant]: e
        }))
    }
    //Aplicar los filtros a la data de la tabla
    const renderRowsByFilters = (rows) => {
        console.log(rows);
        if (Object.keys(filters).length === 0) {
            return rows
        } else {
            const filterKeys = Object.keys(filters);
            for (const element of filterKeys) {
                console.log(element)
                const valueFiltered = filters[element].toString().toLowerCase();
                console.log(valueFiltered)
                // rows = rows.filter((item) => item[element]?.toString().toLowerCase().indexOf(valueFiltered) !== -1); 
                if (element === 'isEcommerce') {
                    // If the filter is 'isEcommerce', apply special logic
                    if (valueFiltered === '') {
                        // If valueFiltered is blank, return all rows
                        return rows;
                    } else if (valueFiltered === 'x') {
                        // Filter rows where isEcommerce is 1
                        rows = rows.filter((item) => item.isEcommerce === 1);
                    } else if (valueFiltered === 'n') {
                        // Filter rows where isEcommerce is 0 or null
                        rows = rows.filter((item) => item.isEcommerce === 0 || item.isEcommerce === null);
                    }
                } else {
                    // Regular filtering for other elements
                    rows = rows.filter((item) => {
                        return item[element]?.toString().toLowerCase().indexOf(valueFiltered) !== -1;
                    });
                }
            }
            return rows
        }
    }

    //estado para abrir modal de reporteria
    const [reportingModal, setReportingModal] = useState(false);
    return (
        <>
            <NewDocumentModal
                showModal={showNewDocumentModal}
                setModal={setState}
                vendors={vendors}
                documentType={documentType}
                discountType={discountType}
                listIdDocument={listId}
                Toast={Toast}
                setRealTable={setRealTable}
                rows={rows}
                isAdmin={isAdmin}
            />
            <EditDocumentModal
                showModal={editModal}
                setModal={setState}
                row={editRow}
                documentType={documentType}
                discountType={discountTypeNew}
                estado={statusOptions}
                country={countryOptions}
                rejectedReason={rejectedReason}
                Toast={Toast}
                isAdmin={isAdmin}
                isDisplay={isDisplay}
                filesCustomer={filesCustomer}
                MySwal={MySwal}
                dropdowns={dropdowns}
                setRealTable={setRealTable}
            />
            <ReportingModal
                showModal={reportingModal}
                setModal={setReportingModal}
                documentType={documentType}
                estado={statusOptions}
                country={countryOptions}
                Toast={Toast}
                isAdmin={isAdmin}
                isDisplay={isDisplay}
                MySwal={MySwal}
                dropDownOptions={dropdowns}
                reportingColumns={reportingColumns}
                reports={reports}
                setReports={setReports}
            />
            <div className="rna-wrapper">
                <NotificationAlert useRef="notificationAlert" />
            </div>
            <AdminHeader name="Home" parentName="Sistema de Documentos" />
            <Spin size="large" spinning={loading}>
                <Container className="mt--6" fluid>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm='12'>
                                    <CardFilters
                                        lists={lists}
                                        setLists={setLists}
                                        setLoad={setLoad}
                                        Toast={Toast}
                                        statusOptions={statusOptions}
                                        countryOptions={countryOptions}
                                        status={status}
                                        country={country}
                                        setSearch={setSearch}
                                        isAdmin={isAdmin}
                                        isDisplay={isDisplay}
                                        page={page}
                                        setPage={setPage}
                                        setReportingModal={setReportingModal}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='12' style={{ display: "flex", justifyContent: "flex-end" }}>

                                    <NewRequest
                                        setEditRow={setState}
                                        setRealTable={setRealTable}
                                    />
                                </Col>
                            </Row>
                            <Row>

                                <Col sm='12'>

                                    <Card>
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col xs="6" md='6'>
                                                    <h3 className="mb-0">{"Solicitudes del país"}</h3>
                                                    <p className="text-sm mb-0">{"A continuación se muestra la tabla de solicitudes de colocación"}</p>

                                                </Col>
                                                <Col xs="6" md="6" style={{ display: "flex", justifyContent: "flex-end" }}>

                                                    {/* <FormGroup>
                                                        <label className="form-control-label" htmlFor="search">
                                                            Buscar
                                                        </label>
                                                        <InputGroup className="input-group-merge">
                                                            <Input
                                                                id="search"
                                                                className="m-0"
                                                                style={{ height: "calc(2.25rem + 2px)" }}
                                                                placeholder="Busque una PO/SO"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup> */}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm='2'>
                                                    <MultiSelect
                                                        options={optionsSelect}
                                                        value={selected}
                                                        onChange={setSelected}
                                                        labelledBy="Select"
                                                    />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {/* el cardbody y card footter se encuentran en el componente maintable */}
                                        <MainTable
                                            filters={handleOnfilterDinamic}
                                            columnas={selected}
                                            rows={renderRowsByFilters(rows)}
                                            setEditRow={setState}
                                            status={statusOptions}
                                            page={page}
                                            setPage={setPage}
                                            setRealTable={setRealTable}
                                            discountType={discountType}
                                        />


                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </Spin>
        </>
    )
}

export default Home;

