/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Server Support Administrator en la solicitud de freelance
=========================================================

* Coded by Sergio Marin  

*/
import React from "react";
import { Card, CardTitle, Label, Col, Row, Input, FormGroup, FormFeedback } from "reactstrap";

const ServerSupportFreelance = ({ setData, isVisible, data, requiredJson, disabledFieldsJson, enableComments }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif' };
    const groupStyle = { marginBottom: '0.1rem', fontFamily: 'sans-serif' };
    const labelStyle = { height: '1.5em', marginBottom: '0.1rem', fontFamily: 'sans-serif' };

    const forms = [
        {
            type: "text",
            label: "Username",
            id: "userId",
            colWidth: 8,
            maxLength: 100,
            required: true,
            disabled: false,
            value: data.userId
        },
        {
            type: "text",
            label: "Password",
            id: "password",
            colWidth: 4,
            maxLength: 100,
            required: true,
            disabled: false,
            value: data.password
        },
        {
            type: "textarea",
            label: "Observaciones (máx 500 caracteres)",
            id: "sserverSupportComments",
            colWidth: 12,
            maxLength: 500,
            required: false,
            disabled: enableComments,
        },
        {
            type: "text",
            label: "Completado:",
            id: "completed",
            colWidth: 4,
            maxLength: 100,
            required: false,
            disabled: true,
            value: data.ssaApprover
        }
    ]

    const usernameForm = forms.find(form => form.id === "userId");
    const passwordForm = forms.find(form => form.id === "password");
    const commentsForm = forms.find(form => form.id === "sserverSupportComments");
    const completedForm = forms.find(form => form.id === "completed");

    //FUNCIONES

    const handleOnChange = (id, e) => {
        e.persist();
        setData(prevState => ({ ...prevState, [id]: e.target.value }))
    }

    //Return
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Server Support
                    </CardTitle>

                    {/* User y pass */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        {[usernameForm, passwordForm].map((json, index) => (
                            <Col key={index} xs={json.colWidth}>
                                <FormGroup style={{ ...groupStyle }}>
                                    <Label htmlFor={json.id} style={json.labelStyle || labelStyle}>
                                        {json.label}
                                        {json.required && <span style={{ color: 'red' }}> *</span>}
                                    </Label>
                                    <Input
                                        key={json.id}
                                        type={json.type}
                                        id={json.id}
                                        invalid={requiredJson && requiredJson.includes(json.id)}
                                        maxLength={json.maxLength}
                                        disabled={(disabledFieldsJson && disabledFieldsJson.includes("ServerSupportFreelance"))}
                                        className="form-control"
                                        defaultValue={data[json.id]}
                                        onChange={(e) => handleOnChange(json.id, e)}
                                    />
                                    <FormFeedback>
                                        Este campo no puede estar vacío
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        ))}
                    </Row>

                    {/* comments */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={commentsForm.colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={commentsForm.id} style={commentsForm.labelStyle || labelStyle}>
                                    {commentsForm.label}
                                    {commentsForm.required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    type={commentsForm.type}
                                    id={commentsForm.id}
                                    maxLength={commentsForm.maxLength}
                                    value={data[commentsForm.id]}
                                    disabled={!commentsForm.disabled && (disabledFieldsJson && disabledFieldsJson.includes("ServerSupportFreelance"))}
                                    className="form-control"
                                    onChange={(e) => handleOnChange(commentsForm.id, e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* completed BY */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={completedForm.colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={completedForm.id} style={completedForm.labelStyle || labelStyle}>
                                    {completedForm.label}
                                    {completedForm.required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    type={completedForm.type}
                                    id={completedForm.id}
                                    maxLength={completedForm.maxLength}
                                    disabled={(completedForm.disabled === true) ? true : false}
                                    className="form-control"
                                    value={completedForm.value}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );
}
export default ServerSupportFreelance;
