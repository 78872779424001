// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useEffect } from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Modal, Table } from "reactstrap";

moment.locale("es");

const MedicineModal = props => {

  const {
    type,
    title,
    medicines,
    showModal,
    toggleModal,
    initialValues,
    onSubmit,
  } = props;

  useEffect(() => {
    delete initialValues.test;
  });

  const validate = values => {
    const errors = {};
    if (!values.medication || values.medication === "")
      errors.medication = "Debes ingresar un medicamento.";
    if (!values.startedDate || values.startedDate === "")
      errors.startedDate = "Debes seleccionar una fecha.";
    if (!values.currently || values.currently === "")
      errors.currently = "Debes seleccionar una opción.";
    if (!values.dosis || values.dosis === "")
      errors.dosis = "Debes ingresar la dosis.";
    if (!values.sideEffects || values.sideEffects === "")
      errors.sideEffects = "Debes ingresar los efectos secundarios.";
    return errors;
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-3 py-lg-3">
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={initialValues}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <FormRS role="form">
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label className="form-control-label" for="medication">Medicamento</Label>
                        <Field name="medication">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                placeholder="Escriba aquí el nombre del medicamento"
                                invalid={meta.error && meta.touched}
                              />
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label className="form-control-label" for="startedDate">Fecha de Inicio</Label>
                        <Field name="startedDate">
                          {({ input, meta }) => (
                            <>
                              <ReactDatetime
                                {...input}
                                inputProps={{
                                  placeholder: "Elija una fecha",
                                  className: !meta.error && meta.modified && meta.touched ? "is-valid form-control" : meta.error && meta.touched ? "is-invalid form-control" : "form-control"
                                }}
                                timeFormat={false}
                              />
                              {
                                meta.error && meta.touched && <span style={{
                                  marginTop: '0.25rem',
                                  fontSize: '80%',
                                  color: '#fb6340'
                                }}>{meta.error}</span>
                              }
                            </>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label className="form-control-label" for="currently">¿Sigue Actualmente?</Label>
                        <Field name="currently">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="select"
                                valid={!meta.error && meta.modified && meta.touched}
                                invalid={meta.error && meta.touched}
                              >
                                <option key="0" value="0">Seleccione una opción</option>
                                <option key="1" value="1">Sí</option>
                                <option key="2" value="2">No</option>
                              </Input>
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label className="form-control-label" for="dosis">Dosis</Label>
                        <Field name="dosis">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                placeholder="Escriba aquí la dosis"
                                invalid={meta.error && meta.touched}
                              />
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label className="form-control-label" for="sideEffects">Efectos Secundarios</Label>
                        <Field name="sideEffects">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                placeholder="Escriba aquí si tiene efectos secundarios"
                                invalid={meta.error && meta.touched}
                              />
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col className="mb-1 d-flex align-items-center justify-content-center" sm="12" md="4">
                      <Button
                        disabled={submitting}
                        className="btn-icon"
                        color="info"
                        type="submit"
                        onClick={handleSubmit || validating}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Agregar</span>
                      </Button>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Button
                      className="btn-icon"
                      color="danger"
                      type="button"
                      onClick={toggleModal}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-times" />
                      </span>
                      <span className="btn-inner--text">Finalizar</span>
                    </Button>
                  </div>
                </FormRS>
              )}
            />
            {
              (medicines[type] && medicines[type].length) ?
                <Table
                  className="align-items-center table-flush"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Medicamento</th>
                      <th>Fecha de Inicio</th>
                      <th>¿Sigue Pasando?</th>
                      <th>Dosis</th>
                      <th>Efecto Secundario</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {
                      medicines[type].map((row, key) => (
                        <tr key={key}>
                          <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row.medication}
                          </td>
                          <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {moment(row.startedDate)
                              .utc()
                              .utcOffset(moment().utcOffset())
                              .format("L")}
                          </td>
                          <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row.currently}
                          </td>
                          <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row.dosis}
                          </td>
                          <td className="budget" style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row.sideEffects}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                : null
            }
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

MedicineModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MedicineModal;