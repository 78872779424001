/* eslint-disable jsx-a11y/anchor-is-valid */
// core antd
import { Drawer, Icon, Spin, Upload } from "antd";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { useEffect, useState } from "react";
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import {
  Button,
  Col,
  DropdownItem,
  Form as FormRS,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const { Dragger } = Upload;

const LoadCriticalParts = (props) => {
  const {
    title,
    visible,
    uploading,
    fileList,
    fileLength,
    onClose,
    onFileList,
    onSave,
  } = props;

  const [init, setInit] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    // Actualiza la variable end segun la cantidad de filas del archivo
    if (!fileList.length || end === 0)
      setEnd(fileLength > 50 ? init + 50 : fileLength);
  }, [fileList.length, end, fileLength, init]);

  const uploadProps = {
    name: "file",
    accept: ".xlsx",
    multiple: false,
    // action: 'https://ss-api.gbm.net/auth/test',
    action: "https://smartsimple.gbm.net:43888/auth/test",
    onChange: onFileList,
  };

  const validate = (values) => {
    const errors = {};
    if (!values.init && values.init !== 0)
      errors.init = "El campo es obligatorio.";
    if (!values.end && values.end !== 0)
      errors.end = "El campo es obligatorio.";
    if (values.end < values.init) {
      errors.init = "La fila de inicio no puede ser mayor a la fila de fin.";
      errors.end = "La fila de fin no puede ser menor a la fila de inicio.";
    }
    if (values.end - values.init > 50) {
      errors.init = "El rango de las filas no puede se mayor a 50.";
      errors.end = "El rango de las filas no puede se mayor a 50.";
    }
    if (values.init < 0)
      errors.init = "La fila de inicio no puede ser negativa.";
    if (values.end < 0) errors.end = "La fila de fin no puede ser negativa.";
    if (values.init === values.end) {
      errors.init = "El rango de filas no puede ser el mismo.";
      errors.end = "El rango de filas no puede ser el mismo.";
    }
    return errors;
  };

  const onChangeValues = (event) => {
    const { name, value } = event.target;
    if (name === "init") {
      setInit(parseInt(value));
    } else if (name === "end") {
      if (parseInt(value) <= fileLength) setEnd(parseInt(value));
    }
  };

  const onBtnEvent = (action) => {
    if (action === "back") {
      if (init >= 50) {
        setInit(init - 50);
        setEnd(end - 50);
      } else {
        setInit(0);
        setEnd(end - 50);
      }
    } else if (action === "next") {
      if (fileLength - end > 50) {
        setInit(init + 50);
        setEnd(end + 50);
      } else {
        setInit(init + 50);
        setEnd(fileLength);
      }
    }
  };

  return (
    <>
      <Drawer
        title={title}
        width={340}
        onClose={() => onClose("loadPart")}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Spin
          size="large"
          spinning={uploading}
          tip="Creando las partes críticas"
        >
          <blockquote className="blockquote text-center">
            <p className="mb-0">Creación de varias partes críticas.</p>
            <footer className="blockquote-footer">
              <cite title="Source Title">Max 50 partes a la vez</cite>
            </footer>
          </blockquote>
          <div className="form-row align-items-center">
            <Col className="m-1" sm="12">
              <Dragger {...uploadProps} fileList={fileList}>
                <p className="ant-uploading-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-uploading-text">
                  Suelte el archivo aquí o haga clic para cargar.
                </p>
                <p className="ant-uploading-hint">
                  *Se debe cargar el machote oficial de partes críticas.
                </p>
              </Dragger>
            </Col>
          </div>
          {fileLength > 0 ? (
            <>
              <DropdownItem divider />
              <blockquote className="blockquote text-center">
                <p className="mb-0">Numero de Filas {fileLength}</p>
                <footer className="blockquote-footer">
                  Ingrese los rangos de filas a cargar
                </footer>
              </blockquote>
              <Form
                onSubmit={onSave}
                validate={validate}
                initialValues={{
                  init,
                  end,
                }}
                render={({
                  handleSubmit,
                  values,
                  submitting,
                  validating,
                  valid,
                }) => (
                  <FormRS role="form">
                    <div className="form-row align-items-center">
                      <Col className="mb-1" sm="12" md="6">
                        <FormGroup>
                          <Label for="init">Fila de Inicio</Label>
                          <Field name="init">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="number"
                                  className="form-control form-control-sm"
                                  onChange={(e) => onChangeValues(e)}
                                  valid={!meta.error ? true : false}
                                  invalid={meta.error ? true : false}
                                />
                                {meta.error && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="6">
                        <FormGroup>
                          <Label for="end">Fila de Fin</Label>
                          <Field name="end">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="number"
                                  className="form-control form-control-sm"
                                  onChange={(e) => onChangeValues(e)}
                                  valid={!meta.error ? true : false}
                                  invalid={meta.error ? true : false}
                                />
                                {meta.error && (
                                  <span className="invalid-feedback">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center justify-content-center">
                      <Col
                        className="m-3"
                        sm="12"
                        md="4"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignContent: "center",
                        }}
                      >
                        <Button
                          disabled={init === 0}
                          className="btn-icon"
                          block
                          size="sm"
                          color="info"
                          type="button"
                          onClick={() => onBtnEvent("back")}
                          outline
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-angle-left" />
                          </span>
                          <span className="btn-inner--text">-50</span>
                        </Button>
                      </Col>
                      <Col
                        className="m-3"
                        sm="12"
                        md="4"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignContent: "center",
                        }}
                      >
                        <Button
                          disabled={end === fileLength}
                          className="btn-icon"
                          block
                          size="sm"
                          color="info"
                          type="button"
                          onClick={() => onBtnEvent("next")}
                          outline
                        >
                          <span className="btn-inner--text">+50</span>
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-angle-right" />
                          </span>
                        </Button>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="m-1" sm="12">
                        <Button
                          disabled={uploading || !fileList.length || submitting}
                          className="btn-icon"
                          block
                          outline
                          color="default"
                          type="submit"
                          onClick={handleSubmit || validating}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-file-excel" />
                          </span>
                          <span className="btn-inner--text">Cargar Partes</span>
                        </Button>
                      </Col>
                    </div>
                  </FormRS>
                )}
              />
            </>
          ) : null}
        </Spin>
      </Drawer>
    </>
  );
};

LoadCriticalParts.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LoadCriticalParts;
