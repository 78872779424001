import React from "react";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";

const CardInfo = ({ cardInformation }) => {

  return (
    <div>
      <Row className="mb-4">
        {cardInformation.map((item, key) => (
          <Col xs={item.xes}>
            <Card className={item.color} >
              <CardBody id={item.id}>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0 text-white">
                      {item.title}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">{item.text}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow" onClick={item.click}>
                      <i className={item.icon} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>      
    </div>
  );
};


export default CardInfo;