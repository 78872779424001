// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const HideSignature = props => {

  const {
    title,
    loading,
    showModal,
    // toggleModal,
    onWorkFlow,
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.action || values.action === "0")
      errors.action = "Debes seleccionar un opción.";
    return errors;
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
    // toggle={() => toggleModal("hideEvent")}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{title}</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={onWorkFlow}
              validate={validate}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading}>
                  <FormRS role="form">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        for="action"
                      >
                        ¿Cuenta con días de Home Office aprobados por su jefatura?
                        </Label>
                      <Field name="action">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              {...input}
                              type="select"
                              // placeholder="Nombre de la posición"
                              valid={!meta.error && meta.modified && meta.touched}
                              invalid={meta.error && meta.touched}
                            >
                              <option key="0" value="0">Seleccione una opción</option>
                              <option key="1" value="1">Sí</option>
                              <option key="2" value="2">No</option>
                            </Input>
                            {
                              meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                            }
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    {
                      values.action === "2" &&
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          for="comments"
                        >
                          Comentarios (250)
                        </Label>
                        <Field name="comments">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="textarea"
                                placeholder="Ingrese sus comentarios, este campo es opcional"
                                resize="none"
                                rows="3"
                                maxLength={250}
                              />
                              {
                                meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                              }
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    }
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="info"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Continuar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

HideSignature.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onWorkFlow: PropTypes.func.isRequired,
};

export default HideSignature;