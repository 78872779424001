import React, { Component } from "react";
// reactstrap components
import {
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Card,
  Input,
  Col,
  Row,
  Label,
  Button
} from "reactstrap";
// core components
import { PropTypes } from "prop-types";
import moment from "moment";
import ReactDatetime from "react-datetime";

class FormMISMaintenance extends Component {
  render() {
    const {
      cancel,
      savedData,
      infoMaintenance,
      onChangeForm,
      objectFailValidation
    } = this.props;

    return (
      <>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              Ingrese los espacios correspondientes para modificar el registro.
            </h3>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <label className="form-control-label" htmlFor="target">
                  Objetivos
                </label>
                <Input
                  maxLength="999"
                  id="target"
                  rows="3"
                  type="textarea"
                  placeholder="Ingrese los objetivos del mantenimiento"
                  value={infoMaintenance.target}
                  onChange={e => onChangeForm(e)}
                  invalid={objectFailValidation.target}
                />
                <div className="invalid-feedback">Ingrese los objetivos</div>
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="affectedServices"
                >
                  Servicios afectados
                </label>
                <Input
                  maxLength="299"
                  id="affectedServices"
                  placeholder="Ingrese los servicios afectados"
                  type="text"
                  value={infoMaintenance.affectedServices}
                  invalid={objectFailValidation.affectedServices}
                  onChange={e => onChangeForm(e)}
                />
                <div className="invalid-feedback">
                  Ingrese los servicios afectados
                </div>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <label className="form-control-label">
                      Fecha inicial de la afectación
                    </label>
                    <ReactDatetime
                      value={moment(infoMaintenance.startDate).format(
                        "DD/MM/YYYY"
                      )}
                      timeFormat={false}
                      onChange={e =>
                        onChangeForm({
                          target: {
                            id: "startDate",
                            value: moment(e._d).format("YYYY-MM-DD")
                          }
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <Label className="form-control-label" htmlFor="startTime">
                      Hora inicial de la afectación
                    </Label>

                    <Input
                      id="startTime"
                      type="time"
                      value={infoMaintenance.startTime}
                      onChange={e => onChangeForm(e)}
                    />
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col>
                    <label className="form-control-label">
                      Fecha final de la afectación
                    </label>
                    <ReactDatetime
                      value={moment(infoMaintenance.endDate).format(
                        "DD/MM/YYYY"
                      )}
                      timeFormat={false}
                      onChange={e =>
                        onChangeForm({
                          target: {
                            id: "endDate",
                            value: moment(e._d).format("YYYY-MM-DD")
                          }
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <Label className="form-control-label" htmlFor="endTime">
                      Hora final de la afectación
                    </Label>

                    <Input
                      id="endTime"
                      type="time"
                      value={infoMaintenance.endTime}
                      onChange={e => onChangeForm(e)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example2cols1Input"
                >
                  Ambientes afectados
                </label>
                <div
                  className="custom-control custom-checkbox mb-3"
                  style={{ marginLeft: "12px" }}
                >
                  <input
                    className="custom-control-input"
                    id="envDEV"
                    type="checkbox"
                    defaultChecked={infoMaintenance.envDEV}
                    value={infoMaintenance.envDEV}
                    onChange={e => onChangeForm(e)}
                  />
                  <label className="custom-control-label" htmlFor="envDEV">
                    Desarrollo
                  </label>
                </div>
                <div
                  className="custom-control custom-checkbox mb-3"
                  style={{ marginLeft: "12px" }}
                >
                  <input
                    className="custom-control-input"
                    id="envQA"
                    type="checkbox"
                    defaultChecked={infoMaintenance.envQA}
                    value={infoMaintenance.envQA}
                    onChange={e => onChangeForm(e)}
                  />
                  <label className="custom-control-label" htmlFor="envQA">
                    Calidad
                  </label>
                </div>
                <div
                  className="custom-control custom-checkbox mb-3"
                  style={{ marginLeft: "12px" }}
                >
                  <input
                    className="custom-control-input"
                    id="envPRD"
                    type="checkbox"
                    defaultChecked={infoMaintenance.envPRD}
                    value={infoMaintenance.envPRD}
                    onChange={e => onChangeForm(e)}
                  />
                  <label className="custom-control-label" htmlFor="envPRD">
                    Producción
                  </label>
                </div>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="affectedSites">
                  Sitios afectados
                </label>
                <Input
                  maxLength="499"
                  id="affectedSites"
                  rows="3"
                  type="textarea"
                  placeholder="Ingrese los sitios afectados"
                  value={infoMaintenance.affectedSites}
                  onChange={e => onChangeForm(e)}
                  invalid={objectFailValidation.affectedSites}
                />
                <div className="invalid-feedback">
                  Ingrese los sitios afectados
                </div>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <label className="form-control-label" htmlFor="stateSys">
                      Estado
                    </label>
                    <Input
                      id="status"
                      maxLength="49"
                      placeholder="Ingrese el estado del mantenimiento (ej: aprobado)"
                      type="text"
                      value={infoMaintenance.status}
                      onChange={e => onChangeForm(e)}
                      invalid={objectFailValidation.status}
                    />
                    <div className="invalid-feedback">Ingrese el estado</div>
                  </Col>
                  <Col>
                    <label className="form-control-label" htmlFor="color">
                      Color de etiqueta
                    </label>
                    <Input
                      id="color"
                      type="select"
                      value={infoMaintenance.color}
                      onChange={e => onChangeForm(e)}
                    >
                      <option value="R">Rojo</option>
                      <option value="G">Verde</option>
                      <option value="B">Azul</option>
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="otherCon">
                  Conexión alterna
                </label>
                <Input
                  id="otherCon"
                  maxLength="199"
                  placeholder="Ingrese si va a haber conexión alterna"
                  type="text"
                  value={infoMaintenance.otherCon}
                  onChange={e => onChangeForm(e)}
                  invalid={objectFailValidation.status}
                />
                <div className="invalid-feedback">
                  Ingrese si va haber conexión alterna
                </div>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="comment">
                  Comentario (opcional)
                </label>
                <Input
                  maxLength="999"
                  id="comment"
                  rows="3"
                  type="textarea"
                  placeholder="Ingrese un comentario al respecto"
                  value={infoMaintenance.comment}
                  onChange={e => onChangeForm(e)}
                />
              </FormGroup>
            </Form>
            <Button
              color="success"
              size="sm"
              type="button"
              onClick={e => savedData(infoMaintenance)}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-upload"></i>
              </span>
              <span className="btn-inner--text">Publicar</span>
            </Button>
            <Button color="danger" size="sm" type="button" onClick={cancel}>
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-times"></i>
              </span>
              <span className="btn-inner--text">Cancelar</span>
            </Button>
          </CardBody>
        </Card>
      </>
    );
  }
}

FormMISMaintenance.propTypes = {
  cancel: PropTypes.func.isRequired,
  savedData: PropTypes.func.isRequired,
  infoMaintenance: PropTypes.object.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  objectFailValidation: PropTypes.object.isRequired
};

export default FormMISMaintenance;
