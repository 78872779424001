/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
Component to create a section when user can introduce an OS or Network ID and validate it
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";

import { RiFileAddFill } from "react-icons/ri";

function ItemVerify({
  title,
  subtitle,
  onSelect,
  reportType,
  inputValidation,
  buttonOnClick,
  errors,
}) {
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{subtitle}</p>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className=" align-items-center">
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label className="font-weight-bold">Tipo de reporte</Label>
              <Input
                id="select-typeReport"
                type="select"
                className="basic-multi-select text-dark"
                style={{
                  color: "black",
                  borderColor: "#dee2e6",
                }}
                defaultValue={"0"}
                onChange={(e) => {
                  onSelect(e.target.value);
                }}
                invalid={errors.idReport}
              >
                {reportType.map((report, index) => (
                  <option key={report} value={index}>
                    {report}
                  </option>
                ))}
              </Input>
              {errors.idReport && (
                <FormFeedback style={{ color: "white" }}>.</FormFeedback>
              )}
            </FormGroup>
          </Col>

          <Col xs={12} sm={4}>
            <FormGroup>
              <Label className="font-weight-bold">
                Digita el ID a reportar
              </Label>
              <Input
                id="text-input"
                className="form-control"
                autoComplete="off"
                placeholder="Número de ID"
                style={{
                  color: "black",
                  borderColor: errors.idReport ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  inputValidation(e.target.value);
                }}
                invalid={errors.idReport}
              />
              {errors.idReport && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>

          <Col xs={12} sm={2}>
            <Button
              className="btn-icon"
              color="success"
              size="sm"
              type="button"
              style={{
                backgroundColor: "rgb(45, 206, 137)",
                color: "white",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
                justifyContent: "center",
              }}
              onClick={buttonOnClick}
            >
              <span className="btn-inner--text">
                <RiFileAddFill
                  style={{
                    color: "white",
                    fontSize: "15px",
                    marginBottom: "3px",
                    marginRight: "2px",
                  }}
                />
                Crear reporte
              </span>
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

ItemVerify.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  reportType: PropTypes.array.isRequired,
  inputValidation: PropTypes.func.isRequired,
  buttonOnClick: PropTypes.func.isRequired,
};

export default ItemVerify;
