import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getDataRows = createAction(constants.MD_GET_DATA_ROWS, body => method.post(urls.MASTERDATA.getRows, body)());
export const getLinealAndMasiveData = createAction(constants.MD_GET_LINEAL_MASIVE_DATA, body => method.post(urls.MASTERDATA.getLinealAndMasiveData, body)());
export const getGeneralInfo = createAction(constants.MD_GET_GENERAL_INFO, body => method.post(urls.MASTERDATA.getGeneralInfo, body)());

//Para los dropdowns
export const getOptions = createAction(constants.MD_GET_OPTIONS, method.get(urls.MASTERDATA.getOptions));
export const newRequest = createAction(constants.MD_NEW_REQUEST, body => method.post(urls.MASTERDATA.newRequest, body)());
export const getDataApprovalsRows = createAction(constants.MD_GET_DATA_APPROVALS_ROWS, body => method.post(urls.MASTERDATA.getApprovalsRows, body)());
export const approvalRejectRequest = createAction(constants.MD_APPROVAL_REJECT_REQUEST, body => method.post(urls.MASTERDATA.approvalRejectRequest, body)());
export const deleteFile = createAction(constants.MD_DELETE_FILE, body => method.post(urls.MASTERDATA.deleteFile, body)());
export const getEmployees = createAction(constants.MD_GET_EMPLOYEES, body => method.get(urls.MASTERDATA.getEmployees, body)());
export const existMasterDataByKey = createAction(constants.MD_EXIST_MASTERDATA, body => method.post(urls.MASTERDATA.existMasterDataByKey, body)());
export const deleteFileByTempId = createAction(constants.MD_DELETE_FILE_BYTEMPID, body => method.post(urls.MASTERDATA.deleteFileByTempId, body)());
export const getDataAllRows = createAction(constants.MD_GET_DATA_ALL_ROWS, body => method.post(urls.MASTERDATA.getAllRows, body)());
export const getQuantityAllRows = createAction(constants.MD_GET_QUANTITY_ALL_ROWS, body => method.post(urls.MASTERDATA.getQuantityAllRows, body)());

export const updateStatus = createAction(constants.MD_UPDATE_STATUS, body => method.post(urls.MASTERDATA.updateStatus, body)());


export const getFormFields = createAction(constants.MD_GET_FORM_FIELDS, method.get(urls.MASTERDATA.getFormFields));

//Vista de mantenimiento
export const getApprovers = createAction(constants.GET_APPROVERS, method.get(urls.MASTERDATA.getApprovers));
export const getMotherTables = createAction(constants.GET_MOTHERTABLE, method.get(urls.MASTERDATA.getMotherTables));
export const deleteApprovers = createAction(constants.DELETE_APPROVERS, body => method.post(urls.MASTERDATA.deleteApprovers, body)());
export const deleteTemplate = createAction(constants.MD_DELETE_TEMPLATE, body => method.post(urls.MASTERDATA.deleteTemplate, body)());
export const saveTemplate = createAction(constants.MD_SAVE_TEMPLATE, body => method.post(urls.MASTERDATA.saveTemplate, body)());
export const getMaintenanceTableInfo = createAction(constants.MD_GET_MAINTENANCE_TABLE_INFO, body => method.post(urls.MASTERDATA.getMaintenanceTableInfo, body)());
export const maintenanceAction = createAction(constants.MD_MAINTENANCE_ACTION, body => method.post(urls.MASTERDATA.maintenanceAction, body)());
export const newApprovers = createAction(constants.MD_NEW_APPROVERS, body => method.post(urls.MASTERDATA.newApprovers, body)());
export const getOptionsMaintenanceView = createAction(constants.MD_OPTIONS_MAINTENANCE_VIEW, method.get(urls.MASTERDATA.getOptionsMaintenanceView));
