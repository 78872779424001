// node.js library that concatenates classes (strings)
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// reactstrap components
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Charts = props => {

  const {
    title,
    subtitle,
    date,
    type,
    line,
    data,
    options,
    onChangeType,
    onChangeValue
  } = props;

  return (
    <Card className="bg-default">
      <CardHeader className="bg-transparent">
        <Row>
          <Col xs="12" md='9'>
            <h5 className="text-light text-uppercase surtitle">{title}</h5>
            <h5 className="text-white h3 mb-0">{subtitle}</h5>
          </Col>
          <Col xs="12" md='3'>
            <Nav className="justify-content-center" pills>
              <NavItem className="mr-2 mr-md-0">
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: type === 'month'
                  })}
                  href="#"
                  onClick={() => onChangeType('month')}
                >
                  <span className="d-none d-md-block">Anual</span>
                  <span className="d-md-none">A</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: type === 'day'
                  })}
                  data-toggle="tab"
                  href="#"
                  onClick={() => onChangeType('day')}
                >
                  <span className="d-none d-md-block">Mensual</span>
                  <span className="d-md-none">M</span>
                </NavLink>
              </NavItem>
            </Nav>
            <Pagination
              className="pagination justify-content-center mb-0 pt-1"
              listClassName="justify-content-center mb-0 pt-1"
            >
              <PaginationItem>
                <PaginationLink
                  onClick={() => onChangeValue('back', type)}
                  tabIndex="-1"
                >
                  <i className="fas fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
              <h5 className="d-flex align-items-center text-white h3 mb-0">{date}</h5>
              <PaginationItem>
                <PaginationLink
                  onClick={() => onChangeValue('next', type)}
                >
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart">
          {line &&
            <Line
              data={data}
              options={options}
              id="chart-sales"
              className="chart-canvas"
            />
          }
        </div>
      </CardBody>
    </Card>
  );
};

Charts.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  line: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default Charts;