// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import { Button, Card, CardHeader, Col, Row, Table } from "reactstrap";

const ContracGraph = (props) => {
  const { title, subtitle, cols, rows, rowsCountry, onOpen, graphByCountry } =
    props;

  const numberWithCommas = (x) => {
    if (x >= 0)
      return `$${parseFloat(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    else return null;
  };

  const totalSum = {};

  for (const col of cols) {
    if (col.key !== "country") {
      totalSum[col.key] = 0;
    }
  }

  for (const col of cols) {
    if (col.key !== "country") {
      for (const row of rows) {
        if (row[col.key]) {
          totalSum[col.key] += parseInt(row[col.key]);
        }
      }
    }
  }

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="12" md="7">
            <h6 className="surtitle">{title}</h6>
            <h5 className="h3 mb-0">{subtitle}</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th />
            {cols.map((row, key) => (
              <th
                key={key}
                style={{ whiteSpace: "normal", textAlign: "center" }}
              >
                {row.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="list">
          {rows.map((row, key) => (
            <>
              <tr
                key={key}
                style={{
                  backgroundColor: `${
                    graphByCountry.visible === true &&
                    graphByCountry.country === row.country
                      ? "aquamarine"
                      : "white"
                  }`,
                }}
              >
                <th style={{ whiteSpace: "normal", textAlign: "justify" }}>
                  <Button
                    className="btn-icon btn-3"
                    size="sm"
                    color={
                      graphByCountry.visible === true &&
                      graphByCountry.country === row.country
                        ? "warning"
                        : "success"
                    }
                    type="button"
                    onClick={() => onOpen(row)}
                  >
                    <span className="btn-inner--icon">
                      <i
                        className={
                          graphByCountry.visible === true &&
                          graphByCountry.country === row.country
                            ? "fas fa-eye-slash"
                            : "fas fa-eye"
                        }
                      />
                    </span>
                  </Button>
                </th>
                {cols.map((col, key) => (
                  <th
                    key={key}
                    style={{ whiteSpace: "normal", textAlign: "center" }}
                  >
                    {col.key === "country"
                      ? row[col.key]
                      : numberWithCommas(row[col.key])}
                  </th>
                ))}
              </tr>
              {graphByCountry.visible === true &&
                graphByCountry.country === row.country &&
                rowsCountry.map((row, key) => (
                  <tr key={key}>
                    <th
                      style={{ whiteSpace: "normal", textAlign: "justify" }}
                    ></th>
                    {cols.map((col, key) =>
                      col.key === "country" ? (
                        <th
                          key={key}
                          style={{ whiteSpace: "auto", textAlign: "center" }}
                        >
                          {row[col.key]}
                        </th>
                      ) : (
                        <th
                          key={key}
                          style={{ whiteSpace: "normal", textAlign: "center" }}
                        >
                          {numberWithCommas(row[col.key])}
                        </th>
                      )
                    )}
                  </tr>
                ))}
            </>
          ))}
          <tr style={{ backgroundColor: "whitesmoke" }}>
            <th style={{ whiteSpace: "normal", textAlign: "center" }}></th>
            {cols.map((col, key) =>
              col.key === "country" ? (
                <th
                  key={key}
                  style={{ whiteSpace: "auto", textAlign: "center" }}
                >
                  <h4 className="surtitle">Total</h4>
                </th>
              ) : (
                <th
                  key={key}
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  {numberWithCommas(totalSum[col.key])}
                </th>
              )
            )}
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

ContracGraph.propTypes = {
  title: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
};

export default ContracGraph;
