/*
=========================================================
*Hours Report - Extra Hours
=========================================================

*Description.
This is the component of the first Form used to create an extra hours report
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import {
  FormGroup,
  Form,
  Input,
  Label,
  Col,
  Row,
  Button,
  Card,
  CardHeader,
  FormFeedback,
} from "reactstrap";

import { BiEdit } from "react-icons/bi";
import { FaExclamationCircle } from "react-icons/fa";

function CreationForm({
  title,
  description,
  onCancelClick,
  onContinue,
  onChangeInput,
  errors,
  showTime,
  relateOrder,
  haveRelatedOrder,
  isNetwork,
  isNetworkVerify,
  minDate,
  maxDate,
  defaultDate,
  defaultinitTime,
  defaultendTime,
  showConflicButton,
  searchConflictReport,
  reportType,
  activeButton,
}) {
  return (
    <Card>
      <CardHeader style={{ marginBottom: "2rem" }}>
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{description}</p>
          </Col>
        </Row>
      </CardHeader>
      <Form>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            {showConflicButton && (
              <div className="text-center">
                <Button
                  color="danger"
                  style={{
                    backgroundColor: "#f2f21e",
                    borderColor: "#f2f21e",
                    borderRadius: "5px",
                    padding: "8px 16px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => searchConflictReport(reportType)}
                >
                  <FaExclamationCircle style={{ marginRight: "8px" }} />
                  <span style={{ color: "white" }}>Ver conflictos</span>
                </Button>
              </div>
            )}
            <FormGroup>
              <Label className="font-weight-bold">Fecha a reportar </Label>
              <Input
                id="date-input-extra-hours"
                type="date"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.date ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("date", e.target.value);
                }}
                invalid={errors.date}
                //Min and Max date for calendary options
                min={minDate}
                max={maxDate}
                defaultValue={defaultDate}
              />
              {errors.date && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Hora de inicio</Label>
              <Input
                id="time-input"
                type="time"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.time ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("time", e.target.value);
                }}
                invalid={errors.time}
                defaultValue={defaultinitTime}
              />
              {errors.time && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Hora de fin</Label>
              <Input
                id="time-input"
                type="time"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.endTime ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("endTime", e.target.value);
                }}
                invalid={errors.endTime}
                defaultValue={defaultendTime}
              />
              {errors.endTime && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup style={{ textAlign: "center", marginBottom: "2rem" }}>
              <Label className="font-weight-bold mr-2">
                Duración de la actividad
              </Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#007bff",
                  }}
                >
                  {showTime.horas}
                </span>
                <span
                  style={{ fontSize: "16px", color: "#555", margin: "0 8px" }}
                >
                  horas
                </span>
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#007bff",
                  }}
                >
                  {showTime.minutos}
                </span>
                <span
                  style={{ fontSize: "16px", color: "#555", margin: "0 8px" }}
                >
                  minutos
                </span>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup
              className="d-flex align-items-center"
              style={{ marginBottom: "2rem" }}
            >
              <span
                className="mr-3"
                style={{ fontWeight: "bold", fontSize: "14px" }}
              >
                ¿Necesitas relacionar una orden?
              </span>
              <Button
                color="primary"
                className="d-flex align-items-center"
                style={{
                  fontSize: "14px",
                  borderRadius: "15px",
                  width: "fit-content",
                  height: "30px",
                  fontSize: "0.75rem",
                }}
                onClick={relateOrder}
              >
                <BiEdit
                  style={{ marginRight: "0.5rem", fontSize: "0.75rem" }}
                />
                Relacionar orden
              </Button>
            </FormGroup>
            <Row className="justify-content-center mb-4">
              <Col xs={10} sm={6} className="text-center">
                {haveRelatedOrder !== null && !isNetwork && (
                  <FormGroup className="mb-3">
                    <Label
                      className="font-weight-bold"
                      style={{ fontSize: "14px", color: "#333" }}
                    >
                      Orden relacionada:
                    </Label>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="text-dark">
                        {haveRelatedOrder.IdOrden}
                      </span>
                      <span className="text-dark mx-2">-</span>
                      <span className="text-dark">
                        {haveRelatedOrder.IdItem}
                      </span>
                      <span className="text-dark mx-2">-</span>
                      <span className="text-dark">
                        {haveRelatedOrder.DescItem}
                      </span>
                    </div>
                  </FormGroup>
                )}

                {haveRelatedOrder !== null && isNetworkVerify && (
                  <FormGroup className="mb-3">
                    <Label
                      className="font-weight-bold"
                      style={{ fontSize: "14px", color: "#333" }}
                    >
                      Network relacionada:
                    </Label>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="text-dark">{haveRelatedOrder.ID}</span>
                      <span className="text-dark mx-2">-</span>
                      <span className="text-dark">{haveRelatedOrder.ITEM}</span>
                      <span className="text-dark mx-2">-</span>
                      <span className="text-dark">
                        {haveRelatedOrder.ORD_NAME}
                      </span>
                    </div>
                  </FormGroup>
                )}
                {haveRelatedOrder === null && (
                  <FormGroup className="mb-3">
                    <Label
                      className="font-weight-bold"
                      style={{ fontSize: "14px" }}
                    >
                      Ninguna orden relacionada
                    </Label>
                  </FormGroup>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col xs={10} sm={6} className="text-center">
            <Button
              id="btn-cancel-cats"
              className="btn-icon mt-3 mr-3"
              color="danger"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                width: "115px",
                height: "30px",
                fontSize: "0.75rem",
                marginLeft: "0px",
              }}
              onClick={onCancelClick}
            >
              <span className="btn-inner--text">Cancelar</span>
            </Button>
            <Button
              id="btn-create-extra-hours"
              className="btn-icon mt-3"
              disabled={activeButton}
              color="success"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                width: "115px",
                height: "30px",
                fontSize: "0.75rem",
                marginLeft: "0px",
              }}
              onClick={onContinue}
            >
              <span className="btn-inner--text">Continuar</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default CreationForm;
