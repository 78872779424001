// core action
import {
  findFlowLogByIdFlow,
  findHomeOfficeInfoById,
  findSignaturesByUser,
  relaunchSigntureByCollaborator,
  sendAllDocumentsSigned,
  updateStateSignatureInFlow,
  relaunchHomeOfficeSignature,
} from "actions/digitalSignature.jsx";
// antd components
import { Spin } from "antd";
// core components Digital Signatures
import CorrectionFlow from "components/DigitalSignature/CorrectionFlow.jsx";
import MySignaturesCard from "components/DigitalSignature/MySignaturesCard.jsx";
import RelaunchFlow from "components/DigitalSignature/RelaunchFlow.jsx";
import SignatureResource from "components/DigitalSignature/SignatureResource.jsx";
import SignaturesBoss from "components/DigitalSignature/SignaturesBoss.jsx";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
import { getUserProtected } from "selectors/adminLayout";
// core selectors
import {
  getAllSignaturesByUser,
  getAllSignaturesSupervisor,
  getFlowLogsBySignature,
  getHomeOfficeInformation,
  getAllEmployeesNoHomeOffice,
} from "selectors/digitalSignature.jsx";
class MySignatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: true,
      message: null,
      signature: null,
      flowSignature: null,
      showModal: false,
      showModalCollaborator: false,
    };
  }

  componentDidMount() {
    this.findSignaturesByUser();
  }

  findSignaturesByUser = () => {
    this.props.findSignaturesByUser().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState((state) => ({
        loading: false,
      }));
    });
  };

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof MySignatures
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof MySignatures
   */
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  toggleModal = (name) => {
    this.setState((state) => ({
      [name]: !state[name],
    }));
  };

  _handleOnOpenFile = (row) => {
    this.setState({
      signature: row,
    });
  };

  _handleOnBack = () => {
    this.setState({
      signature: null,
    });
  };

  _handleOnBackFlow = () => {
    this.setState({
      flowSignature: null,
    });
  };

  _handleOnSendSignatures = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de enviar a su correo electrónico las firmas vigentes?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this._sendSignaturesToEmail()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, enviar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  _sendSignaturesToEmail = () => {
    this.setState((state) => ({
      loading: !state.loading,
      alert: null,
    }));
    this.props.sendAllDocumentsSigned().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Atención", payload.message);
      }
      this.setState((state) => ({
        loading: !state.loading,
      }));
    });
  };

  _handleOnOpenSignatureFlow = (row) => {
    const { idFlow } = row;
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props.findFlowLogByIdFlow(idFlow).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Atención", payload.message);
        this.setState({
          flowSignature: row,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
      }));
    });
  };

  _handleOnRelaunchSignature = () => {
    this.setState({
      showModal: true,
    });
  };

  _handleOnRelaunchWorkFlow = (values) => {
    const { flowSignature } = this.state;
    values["type"] = "supervisor";
    const { id, idFlow } = flowSignature;
    values = Object.assign({}, values, flowSignature);
    this.setState((state) => ({
      loading: !state.loading,
      message: "Relanzando el flujo de la firma",
    }));
    this.props.updateStateSignatureInFlow(id, idFlow, values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState((state) => ({
          showModal: !state.showModal,
          message: null,
          flowSignature: null,
        }));
        this.findSignaturesByUser();
      }
    });
  };

  _handleOnRelaunchCollaboratorSignature = () => {
    const { signature } = this.state;
    const { user } = this.props;
    if (signature.position === user.POSICION) {
      this.notify(
        "warning",
        "Atención",
        "No has cambiado de posición recientemente para relanzar la firma."
      );
    } else {
      if (signature.isHO === 1) {
        this.setState((state) => ({
          loading: !state.loading,
          message: "Cargando la información de la firma",
        }));
        this.props.findHomeOfficeInfoById(signature.id).then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            this.setState((state) => ({
              showModalCollaborator: !state.showModalCollaborator,
            }));
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
      }
    }
  };

  _handleOnRelaunchSignatureByCollaborator = (values) => {
    console.log(values);
    this.setState((state) => ({
      loading: !state.loading,
      message: "Relanzando la firma de la política",
    }));
    this.props
      .relaunchSigntureByCollaborator(values.idSignature, values)
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.setState({
            showModalCollaborator: false,
            signature: null,
          });
          this.findSignaturesByUser();
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      });
  };

  _handleOnRelaunchSignatureHomeOffice = (values) => {
    console.log(values.idException);
    this.setState((state) => ({
      loading: !state.loading,
      message: "Relanzando la firma de la política",
    }));
    this.props
      .relaunchHomeOfficeSignature(values.idException, values)
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.findSignaturesByUser();
        }
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      });
  };

  render() {
    const {
      name,
      parentName,
      logs,
      days,
      frequencies,
      models,
      homeOffice,
      signatures,
      supervisorSignatures,
      employeeNoHomeOffice,
    } = this.props;

    const {
      alert,
      loading,
      message,
      showModal,
      signature,
      flowSignature,
      showModalCollaborator,
    } = this.state;

    return (
      <>
        {alert}
        <CorrectionFlow
          title="Relanzamiento de la Firma"
          showModal={showModalCollaborator}
          toggleModal={this.toggleModal}
          days={days}
          initialValues={homeOffice}
          loading={loading}
          message={message}
          onWorkFlow={this._handleOnRelaunchSignatureByCollaborator}
        />
        <RelaunchFlow
          title="Relanzamiento del Flujo"
          showModal={showModal}
          toggleModal={this.toggleModal}
          loading={loading}
          message={message}
          onWorkFlow={this._handleOnRelaunchWorkFlow}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading}>
            {supervisorSignatures.length && !signature && !flowSignature ? (
              <SignaturesBoss
                title="Políticas de Recursos en Flujo"
                rows={supervisorSignatures}
                noHomeOffice={employeeNoHomeOffice}
                onRelaunch={this._handleOnRelaunchSignatureHomeOffice}
                openFile={this._handleOnOpenSignatureFlow}
              />
            ) : flowSignature && !signature ? (
              <SignatureResource
                signature={flowSignature}
                logs={logs}
                options={{ days, frequencies, models }}
                onBack={this._handleOnBackFlow}
                onRelaunch={this._handleOnRelaunchSignature}
              />
            ) : null}
            {signatures.length && !flowSignature ? (
              <Row>
                <Col>
                  <MySignaturesCard
                    title="Mis Políticas Firmadas"
                    signature={signature}
                    signatures={signatures}
                    openFile={this._handleOnOpenFile}
                    onBack={this._handleOnBack}
                    onSend={this._handleOnSendSignatures}
                    onRelaunch={this._handleOnRelaunchCollaboratorSignature}
                  />
                </Col>
              </Row>
            ) : !flowSignature ? (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No tienes firmas registradas."
                    subtitle="Al día de hoy no tienes firmas registradas, en el momento que realices la firma de tus políticas, aparecerán en esta ventana. Si considera que esto es un error comuniquese con la unidad de Application Management."
                  />
                </Col>
              </Row>
            ) : null}
          </Spin>
        </Container>
      </>
    );
  }
}

MySignatures.defaultProps = {
  name: "Políticas Digitales",
  parentName: "Firma Digital",
  signatures: [],
  supervisorSignatures: [],
  logs: [],
  days: [
    { key: "monday", name: "Lunes" },
    { key: "tuesday", name: "Martes" },
    { key: "wednesday", name: "Miércoles" },
    { key: "thursday", name: "Jueves" },
    { key: "friday", name: "Viernes" },
  ],
  frequencies: [
    { id: 1, name: "Semanal" },
    { id: 2, name: "Quincenal" },
    { id: 3, name: "Mensual" },
  ],
  models: [
    { id: 4, name: "1-4" },
    { id: 1, name: "2-3" },
    { id: 2, name: "3-2" },
    { id: 3, name: "4-1" },
    { id: 5, name: "5-0" },
    { id: 6, name: "Otro" },
  ],
  user: {},
  homeOffice: {},
  employeeNoHomeOffice: [],
};

MySignatures.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  signatures: PropTypes.array.isRequired,
  supervisorSignatures: PropTypes.array.isRequired,
  employeeNoHomeOffice: PropTypes.array.isRequired,
  logs: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  homeOffice: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  signatures: getAllSignaturesByUser(state),
  supervisorSignatures: getAllSignaturesSupervisor(state),
  logs: getFlowLogsBySignature(state),
  user: getUserProtected(),
  homeOffice: getHomeOfficeInformation(state),
  employeeNoHomeOffice: getAllEmployeesNoHomeOffice(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findFlowLogByIdFlow,
    findHomeOfficeInfoById,
    findSignaturesByUser,
    sendAllDocumentsSigned,
    updateStateSignatureInFlow,
    relaunchSigntureByCollaborator,
    relaunchHomeOfficeSignature,
  })(MySignatures)
);
