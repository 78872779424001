// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// react library
import React, { useState } from 'react';
// reactstrap components
import { CardFooter, CardBody, Col, Pagination, PaginationItem, PaginationLink, Row, Table, Input, Badge, Button } from "reactstrap";
import { useDispatch } from 'react-redux';
import { getCandidateInfo, changeStatusBot } from 'actions/hcmHiring.jsx';
import ReactBSAlert from "react-bootstrap-sweetalert";

moment.locale("es");

const MainTable = ({
    columns,
    rows,
    setEditRow,
    status,
    page,
    setPage,
    dropDownOptions,
    Toast,
    setRealTable
}) => {

    const dispatch = useDispatch();

    //#region State Principal
    const [state, setState] = useState({
        sizePerPage: 5,
    });
    const [alert, setAlert] = useState("");
    const { sizePerPage } = state;
    //#endregion


    //#region Funcion para abrir el modal para editar
    const handleOnEditDocument = async (event, row) => {

        dispatch(getCandidateInfo(row["id"])).then((resp) => {
            const { payload } = resp;
            console.log(payload);
            if (payload.status === 200) {
                const candidatePersonalData = payload.data.candidatePersonalData[0];
                const candidateEducation = payload.data.candidateEducation[0];
                const candidateExperience = payload.data.candidateExperience[0];
                const filesData = payload.data.filesData;
                console.log(filesData)
                setEditRow((prevState) => ({
                    ...prevState,
                    candidatePersonalData: candidatePersonalData,
                    candidateEducation: candidateEducation,
                    candidateExperience: candidateExperience,
                    filesData: filesData,
                    editModal: !prevState.editModal,
                    editRow: row,
                }))
            }
            else {
                Toast.fire({
                    title: "Atención",
                    html: "El candidato no ha llenado la información",
                    type: 'warning'
                });

            }
        })


    };
    //#endregion

    //#region funcion para modificar el status de la solicitud


    const handleSendToBot = async (event, row) => {
        console.log(row);

        //alerta para preguntar si esta seguro

        dispatch(changeStatusBot({ idRequest: row["id"] })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                row["status"] = "EP";
                row["statusName"] = "EN PROCESO";
                setRealTable(prevCheck => !prevCheck);
                Toast.fire({
                    title: "Atención",
                    html: "procesando por el robot de MIS",
                    type: 'success'
                });
                setAlert("")
            }
            else {
                Toast.fire({
                    title: "Atención",
                    html: "Error",
                    type: 'danger'
                });

            }
        })
    };


    //#endregion

    //#region Pagination (no sirve)
    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);
    };

    //funcion para cambiar las filas por las paginas
    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            sizePerPage: value
        }))
    }
    const handleOnEditDocumentReady = (e, row) => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Está seguro(a) que desea crear el candidato en SAP?"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleSendToBot(e, row)}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, crear"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }


    //#endregion
    console.log(alert)
    return (
        <>
            {alert}
            <CardBody>

                <Table
                    className="align-items-center table-flush"
                    responsive
                    striped={true}
                    hover={true}
                >
                    <thead className="thead-light">
                        <tr>
                            {
                                columns.map((row, key) => {
                                    return (
                                        <th key={key} className="justify-content-md-center">
                                            {row.label}
                                        </th>
                                    )
                                })
                            }

                        </tr>
                    </thead>
                    <tbody className="list">
                        {
                            rows.length ?
                                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                                    <tr key={key}>
                                        {
                                            columns.map((col, key) => (

                                                col.value === "detalle" ?
                                                    <td key={key} className="table-actions">
                                                        <a
                                                            className="table-action table-action"
                                                            onClick={(e) => handleOnEditDocument(e, row)}
                                                            href="#"
                                                            id="edit"
                                                        >
                                                            <i className="fas fa-edit" />
                                                        </a>
                                                    </td>
                                                    : col.value === "statusName" ?
                                                        <td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                            {/* <button type="button" className={"btn btn-round btn-wd btn-sm btn-" + (typeof status.find(dt => dt.value === row["status"]) !== "undefined" ? status.find(dt => dt.value === row["status"]).statusType : "")}>

                                                                <span className="btn-label">
                                                                    <i className="fa fa-warning"></i>
                                                                </span>
                                                                
                                                            </button> */}
                                                            <Badge color={(typeof status.find(dt => dt.value === row["status"]) !== "undefined" ? status.find(dt => dt.value === row["status"]).statusType : "")} pill>
                                                                {row["statusName"]}
                                                            </Badge>
                                                        </td>
                                                        : col.value === "createPB10" ?
                                                            <td key={key} className="table-actions d-flex  justify-content-center">
                                                                {/* <button
                                                                    id="sendToBot"
                                                                    className="btn btn-success mb-2 mr-2 rounded-circle"
                                                                    disabled={(row["status"] === "PE") ? false : true}
                                                                    onClick={(e) => handleSendToBot(e, row)}
                                                                // style={{width: "20px", height: "25px"}}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                                                                </button> */}
                                                                {/* <a
                                                                    className="table-action table-action"
                                                                    onClick={(e) => handleSendToBot(e, row)}
                                                                    href="#"
                                                                    id="sendToBot"
                                                                    disabled={(row["status"] === "PE") ? false : true}
                                                                    style={{ width: "110px", height: "115px" }}
                                                                >
                                                                    <i className="fas fa-paper-plane" />
                                                                </a> */}
                                                                <Button
                                                                    className="btn btn-sm"
                                                                    color="success"
                                                                    disabled={(row["status"] === "PE") ? false : true}
                                                                    onClick={(e) => handleOnEditDocumentReady(e, row)}>
                                                                    <span className="btn-inner--icon mr-1">
                                                                        <i className="fas fa-paper-plane" />
                                                                    </span>
                                                                </Button>
                                                            </td>
                                                            :
                                                            <td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                                {
                                                                    row[col.value]
                                                                }
                                                            </td>
                                            ))
                                        }
                                    </tr>
                                ))
                                :
                                <>
                                    <tr>
                                        <td className="justify-content-md-center">
                                            Sin resultados encontrados
                                        </td>
                                    </tr>
                                </>
                        }
                    </tbody>
                </Table>
            </CardBody>
            <CardFooter className="py-4">
                <nav aria-label="...">
                    <Row className="align-items-center">
                        <Col xs='12' md='6' className="p-1">
                            <Row className="justify-content-start">
                                <Col xs='12' md='6'>
                                    <span className='pagination mb-0'>
                                        Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                    </span>
                                </Col>
                                <Col xs='12' md='2'>
                                    <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                                        <option>5</option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs='12' md='6' className="p-1">
                            <Pagination
                                className="pagination justify-content-end mb-0"
                                listClassName="justify-content-end mb-0"
                            >
                                <PaginationItem className={page === 1 ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                        tabIndex="-1"
                                    >
                                        <i className="fas fa-angle-left" />
                                        <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                </PaginationItem>
                                {
                                    renderPaginations()
                                }
                                <PaginationItem className={page === paginations ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                    >
                                        <i className="fas fa-angle-right" />
                                        <span className="sr-only">Next</span>
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </Col>
                    </Row>
                </nav>
            </CardFooter>
        </>
    );
};

export default MainTable;