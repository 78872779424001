import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findConflictInterestsPendingInfo = createAction(
  constants.GET_CONFLICT_INTERESTS_PENDING_INFO,
  method.get(urls.CONFLICTINTERESTS.getConflictInterestsPendingInfo)
);

export const findAllConflictInterestsInfo = createAction(
  constants.GET_ALL_CONFLICT_INTERESTS_INFO,
  method.get(urls.CONFLICTINTERESTS.getAllConflictInterestsInfo)
);

export const createConflictInterestsInfoByID = createAction(
  constants.CREATE_CONFLICT_INTERESTS_INFO_BY_ID,
  (id, body) =>
    method.post(
      `${urls.CONFLICTINTERESTS.createConflictInterestsInfoByID}/${id}`,
      body
    )()
);

export const findConflictInterestsInfo = createAction(
  constants.GET_CONFLICT_INTERESTS_INFO,
  method.get(urls.CONFLICTINTERESTS.getConflictInterestsInfo)
);

export const createSupplierConflictInterestsByID = createAction(
  constants.CREATE_CONFLICT_INTERESTS_INFO_BY_ID,
  (id, body) =>
    method.post(
      `${urls.CONFLICTINTERESTS.createSupplierConflictInterestsByID}/${id}`,
      body
    )()
);
