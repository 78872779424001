/*!

=========================================================
*ItRequests
=========================================================

*Este componente es el menu de martriz de accesos
=========================================================

* Coded by Sergio Marin  

*/

import React from "react";
import { Card, CardTitle, Col, Row, CardBody } from "reactstrap";
import AdvanceFilter from 'components/ItRequests/SearchRequests/AdvanceFilter';

const AccessMatrixMenu = ({ setShowMenu }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif' };

    const forms = [
        {
            id: "position",
            title: "Roles por Posición",
            subtitle: "Consulta roles por posición",
            image: "roles.png",
            colorCardSelected: "#d6d4fa",
            menu: "rolePositionTable"
        },
        {
            id: "exception",
            title: "Excepciones",
            subtitle: "Consulta roles por excepción",
            image: "excep.png",
            colorCardSelected: "#d6d4fa",
            menu: "roleExceptionTable"
        }
    ]

    //RETURN
    return (
        <>
            <CardTitle sm="12" tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                Consultas
            </CardTitle>

            <Row>
                {forms.map((menuButton) => (
                    <Col sm="6" key={menuButton.id}>
                        <Card
                            className="card-stats card-lift--hover"
                            style={{ backgroundColor: "#ffffff", cursor: "pointer", }}
                            onClick={() => setShowMenu(menuButton.menu)}
                        >
                            <CardBody>
                                <img
                                    alt="..."
                                    className="rounded img-center img-fluid shadow shadow-lg--hover"
                                    src={require(`assets/img/itRequests/${menuButton.image}`)}
                                    style={{ objectFit: "contain", width: "60px", height: "60px" }}
                                />

                                <div className="pt-1 text-center">
                                    <h4 className="h3 title">
                                        <span className="d-block mb-1">{menuButton.title}</span>
                                        <small className="h5 font-weight-light text-muted">
                                            {menuButton.subtitle}
                                        </small>
                                    </h4>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )
                )}
            </Row>
        </>
    );
}
export default AccessMatrixMenu;