
import React, { useState } from 'react';
import {
    Button, Col, Row, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container
} from "reactstrap";
import { urlBase } from "api/urls.jsx";
import { Upload, Icon } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteFile, deleteAllFiles } from 'actions/DocumentSystem.jsx'
import { message } from 'antd';

const { Dragger } = Upload;
const UploadFilleModal = ({
    showModalUpload,
    setShowModalUpload,
    row,
    filesCustomer,
    setFiles,
}) => {

    const dispatch = useDispatch();
    const [state, setState] = useState({
        fileList: [],
        idFileRequest: "",
        readyToSend: false,
    });
    const { fileList, readyToSend, idFileRequest } = state
    const documentId = row.documentId;

    const handleOnUploadFiles = () => {
        if (fileList.length !== 0) {
            for (const item in fileList) {
                let name = fileList[item].name;
          
                setFiles(prevState => {
                    return {
                      ...prevState,
                      filesCustomer: [...prevState.filesCustomer, name]
                    };
                  });
            }

            setShowModalUpload((prevState) => ({
                modalUploadToggel: !prevState.modalUploadToggel
            }));
        }
        setState((prevState) => ({
            ...prevState,
            fileList: [],
            readyToSend: false,
        }))
    }
    const toggleNewDocumentModal = (() => {

        const { idFileRequest } = state;
        const { fileList } = state;

        if (documentId !== "" && fileList.length > 0) {
            dispatch(deleteAllFiles({ id: idFileRequest })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
                    console.log("Se borraron los archivos del BE")
                } else {
                    console.log("error al borrar los archivos del BE")
                }
            })
        }

        //limpiar fileList
        setState((prevState) => ({
            ...prevState,
            fileList: [],
            readyToSend: false,
        }))
        setShowModalUpload((prevState) => ({
            showNewDocumentModal: !prevState.showNewDocumentModal,
            listIdDocument: [],
        }));
    });
    const handleOnFileList = (info) => {
        const { status } = info.file;
        if (status === "removed") {
            const { file: { name, uid, response: { status, payload } } } = info;
            if (status === 200) {
                const { idFile } = payload;
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    fileList: fileList.filter((row) => row.uid !== uid),
                }));
            }

            dispatch(deleteFile({ id: documentId, name: name })).then((resp) => {
                const { payload } = resp;
                if (payload.status === 200) {
               
                    //deshabilitar el botón de enviar
                    if (fileList.length === 1) {
                        setState((prevState) => ({
                            ...prevState,
                            readyToSend: false,
                        }));
                    }
                } else {
                    console.log("error")
                }
            })
            message.success(`El archivo ${name} fue quitado exitosamente.`);
        } else {
            const { fileList, file: { status, name } } = info;
            let response = "";
            if (status === 'done') {
                const { file } = info;
                response = file["response"]
                response = response.payload.idFile;
                //habilitar el botón de enviar
                setState((prevState) => ({
                    ...prevState,
                    readyToSend: true,
                }));
            } else if (status === 'error') {
                message.success(`Ocurrio un error cargando el archivo ${name}.`);
            }
            setState((prevState) => ({
                ...prevState,
                idFileRequest: response,
                fileList: fileList,
            }));
        }
    }

    const allowedFileTypes = [
        ".xlsx", ".xls", ".xlsb", ".xlt", ".ppt", ".pptx", ".pdf", ".doc", ".docx",
        ".rar", ".zip", ".cfr", ".csv", ".txt", ".eml", ".jpg", ".png", ".html", ".rtf", ".xml",
        ".css", ".tif", ".ods", ".msg"
    ];

    const uploadProps = {
        name: 'file',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        accept: allowedFileTypes.join(","),
        multiple: true,
        action: `${urlBase}/document-system/upload-files/${documentId}`,
        beforeUpload: file => {
            const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
            if (!allowedFileTypes.includes(fileType)) {
                console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
                return false;
            }
            return true;
        },
        onChange: info => {
            console.log(info)
            // Filtrar la lista de archivos válidos para mantener solo los que aún existen
            const filteredFiles = info.fileList.filter(file => {
                console.log(file)
                if (file.status) {
                    return true;
                }
                return false;
            });
            info.fileList = filteredFiles;
            // Actualizar la lista de archivos válidos
            // Llamar a la función handleOnFileList con la lista de archivos válidos
            handleOnFileList(info);
        },
    };

 
    return (
        <div>
            <Modal size="sm" isOpen={showModalUpload} toggle={toggleNewDocumentModal}>
                <ModalHeader >Cargar nuevos documentos</ModalHeader>
                <ModalBody className="mb-2">

                    <Row className="mb-1">
                        <Col xs="12">
                            <Container>
                                <Dragger {...uploadProps} fileList={fileList}>
                                    <Icon type="inbox" />
                                    <p className="ant-uploading-text">Seleccione el archivo que va a cargar.</p>
                                    <p className="ant-uploading-hint">
                                        *Puede cargarlo o arrastrar el archivo
                                    </p>
                                </Dragger>
                            </Container>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter >
                    <Row >

                        <Button
                            className="btn-info btn-round btn-icon"
                            id='example'
                            // disabled={fileList.length > 0 ? true : false}
                            color="danger"
                            onClick={() => toggleNewDocumentModal()}
                        >
                            Cancelar
                        </Button>
                        {/* <Tooltip isOpen={tooltipOpen} delay={0} target='example'
                            toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                            Tiene archivos pendientes
                        </Tooltip> */}
                        {
                            readyToSend &&
                            <Button color="success" onClick={() => handleOnUploadFiles()} >Cargar</Button>
                        }
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    );
};
export default UploadFilleModal;