import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// react library
import React, { Component } from "react";

import _ from "lodash";

import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  getRequest,
  checkSession,
  showFullDocument,
  cancelRequest,
  approverAction,
  bypassAction,
  reminder,
  downloadBypassDocument,
  deleteDocument,
} from "actions/ASActions.jsx";

import { requestSelector } from "selectors/AS.jsx";

import {
  getUsernameLogged,
  getTeamsUserAccess,
} from "selectors/adminLayout.jsx";

import { Spin } from "antd";

import { Badge, Button, Table, Text, Toggle } from "@geist-ui/react";

const { SearchBar } = Search;

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

class SalaryRequestDetails extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      orderDetailsData: {},
      urlID: match.params.id,
      rows: null,
      cols: null,
      showReminderAlert: false,
      showBypassModal: false,
      showApprovalModal: false,
      showDeclineModal: false,
      showFullDocumentAlert: false,
      tempDocument: null,
      showConfirmCancelRequest: false,
      notes: "",
      bypassSelection: "true",
      selectedApproval: null,
      showInfo: false,
      showSalaryViewAlert: false,
      selectedFile: null,
      simpleView: true,
      loading: false,
      showConfirmStatusChange: false,
      showUserDetails: false,
      userDetails: {},
      loadingAction: false,
      loadingSalary: false,
      loadingCancelation: false,
      loadingReminder: false,
      loadingBypass: false,
      loadingView: true,
      errorView: false,
      errorMessage: "",
    };
    this.toggleBypassModal = this.toggleBypassModal.bind(this);
    this.toggleApprovalModal = this.toggleApprovalModal.bind(this);
    this.toggleDeclineModal = this.toggleDeclineModal.bind(this);
    this.toggleUserDetailsModal = this.toggleUserDetailsModal.bind(this);
    // const urlID = match.params.id;
  }

  componentWillMount() {
    if (this.state.urlID) {
      const id = this.state.urlID;
      this.props.getRequest(id).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            await this.notify("danger", "Falló", payload.message);
            this.setState({
              loadingView: true,
              errorView: true,
              errorMessage: payload.message,
            });
          } else {
            await this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.setState({ loadingView: false });
        }
      });
    } else {
      //MENSAJE DE ERROR
    }
  }

  //ALERTS
  hideReminderAlert() {
    this.setState({ showReminderAlert: false });
  }
  hideSalaryViewAlert() {
    this.setState({ showSalaryViewAlert: false });
  }
  hideFullDocumentAlert() {
    this.setState({ showFullDocumentAlert: false });
  }

  handleFullDocumentValidation = async (document, value) => {
    var confirmation = { confirmation: value };
    await this.props
      .showFullDocument(document, confirmation)
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          if (res.payload.data.payload.request) {
            if (res.payload.data.payload.request.decripted) {
              this.setState({ showFullDocumentAlert: false, showInfo: true });
              this.notify(
                "success",
                "Token Activo, Mostrando Información Sensitiva",
                "Valido por 15 minutos."
              );
            } else {
              this.notify(
                "danger",
                "Numero de confirmación Incorrecto",
                "Inténtelo nuevamente"
              );
            }
          }
        }
      });
  };

  //MODAL

  toggleBypassModal() {
    // this.setState({ selectedApproval: id });
    this.setState((prevState) => ({
      showBypassModal: !prevState.showBypassModal,
    }));
  }

  toggleApprovalModal(id) {
    if (this.state.showInfo) {
      this.setState({ selectedApproval: id });
      this.setState((prevState) => ({
        showApprovalModal: !prevState.showApprovalModal,
      }));
    } else {
      this.setState({ showSalaryViewAlert: true });
    }
  }

  toggleDeclineModal(id) {
    if (this.state.showInfo) {
      this.setState({ selectedApproval: id });
      this.setState((prevState) => ({
        showDeclineModal: !prevState.showDeclineModal,
      }));
    } else {
      this.setState({ showSalaryViewAlert: true });
    }
  }

  toggleUserDetailsModal() {
    this.setState((prevState) => ({
      showUserDetails: !prevState.showUserDetails,
    }));
  }

  //MODAL

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  BackToMain = () => {
    window.location.href = "/admin/salary/notifications";
  };

  handleShowConfirmation = async (document) => {
    this.setState({ loadingSalary: true });
    await this.props.checkSession(document).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({ loadingSalary: false });
      } else {
        if (res.payload.data.payload.data) {
          this.setState({ loadingSalary: false });
          if (res.payload.data.payload.data.openSession) {
            this.setState({ showInfo: true });
            //ACTUALIZAR AQUI DOCUMENTO
            this.handleFullDocumentValidation(
              this.props.requestData.id,
              res.payload.data.payload.data.confirmation
            );
          } else {
            this.setState({ showFullDocumentAlert: true });
          }
          if (res.payload.data.payload.message.error) {
            this.notify(
              "secondary",
              "Mensaje: ",
              res.payload.data.payload.message.message
            );
          }
        }
      }
    });
  };

  handleCancelConfirmation = () => {
    this.setState({ showConfirmCancelRequest: false });
  };

  handleCancelRequest = async (id) => {
    this.setState({ loadingCancelation: true });
    await this.props.cancelRequest(id).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({ loadingCancelation: false });
      } else {
        this.setState({
          showConfirmCancelRequest: false,
          loadingCancelation: false,
        });
        this.notify(
          "warning",
          "Flujo Detenido",
          "Solicitud Cancelada Correctamente."
        );
        this.componentWillMount();
      }
    });
  };

  handleApproveRequest = async (note, status) => {
    this.setState({ loadingAction: true });
    let data = {
      note: note,
      status: status,
      id: this.state.selectedApproval,
    };
    await this.props.approverAction(data.id, data).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({ loadingAction: false });
      } else {
        this.setState({
          showApprovalModal: false,
          loadingAction: false,
          showConfirmStatusChange: true,
        });
        this.notify("success", "Éxito", "Solicitud Aprobada Correctamente.");
        this.componentWillMount();
      }
    });
  };

  handleDeclineRequest = async (note, status) => {
    this.setState({ loadingAction: true });
    let data = {
      note: note,
      status: status,
      id: this.state.selectedApproval,
    };
    await this.props.approverAction(data.id, data).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({ loadingAction: false });
      } else {
        this.setState({
          showDeclineModal: false,
          loadingAction: false,
          showConfirmStatusChange: true,
        });
        this.notify("success", "Éxito", "Solicitud Rechazada Correctamente.");
        this.componentWillMount();
      }
    });
  };

  handleSelectFile = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };

  handleBypassRequestOld = async (note, status) => {
    this.setState({ loadingBypass: true });
    let data = new FormData();
    data.append("doc", this.state.selectedFile);
    data.append(
      "note",
      note + " ( VISTO BUENO ADJUNTO POR: " + this.props.username + " )."
    );
    data.append("status", status === "true" ? true : false);
    await this.props
      .bypassAction(this.state.selectedApproval, data)
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.setState({
            showBypassModal: false,
            notes: "",
            selectedFile: null,
            loaded: 0,
            loadingBypass: false,
          });
          this.notify(
            "success",
            "Éxito",
            "Se Adjunto el visto bueno Correctamente."
          );
          this.componentWillMount();
        }
      });
  };

  handleBypassRequest = async () => {
    this.setState({ loadingBypass: true });
    const id = this.state.urlID;
    let data = new FormData();
    data.append("doc", this.state.selectedFile);
    await this.props.bypassAction(id, data).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          showBypassModal: false,
          selectedFile: null,
          loaded: 0,
          loadingBypass: false,
        });
        this.notify("success", "Éxito", "Se adjunto el archivo correctamente.");
        this.componentWillMount();
      }
    });
  };

  handleRemindeApprover = async (id) => {
    this.setState({ loadingReminder: true });
    await this.props.reminder(id).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState({ loadingReminder: false });
      } else {
        this.setState({ showReminderAlert: true, loadingReminder: false });
        this.componentWillMount();
      }
    });
  };

  handleDocumentDownload = async (id, name) => {
    await this.props.downloadBypassDocument(id).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const url = window.URL.createObjectURL(new Blob([res.payload.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  handleDocumentDelete = async (id) => {
    await this.props.deleteDocument(id).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.notify("success", "Éxito", "Se elimino el archivo correctamente.");
        this.componentWillMount();
      }
    });
  };

  toggleSimpleView() {
    this.setState((prevState) => ({
      simpleView: !prevState.simpleView,
    }));
  }

  showUserDetails(user) {
    this.setState({ userDetails: user, showUserDetails: true });
  }

  userDetailButton = (value) => {
    return (
      <Button
        type="success"
        auto
        size="mini"
        onClick={(e) => {
          this.showUserDetails(value);
        }}
      >
        Detalles
      </Button>
    );
  };

  UserDetailsFields(user) {
    let fields = [];
    for (const property in user) {
      fields.push(
        <FormGroup className="row" key={property + "-text"}>
          <Label
            className="form-control-label"
            htmlFor="example-text-input"
            md="4"
          >
            {property.split("_").join(" ")}
          </Label>
          <Col md="8">
            <Input
              defaultValue={user[property]}
              id={property}
              type="text"
              disabled
              style={{ color: "black", backgroundColor: "white" }}
            />
          </Col>
        </FormGroup>
      );
    }
    return fields;
  }

  showButton() {
    const { requestData, username, userTeams } = this.props;
    let result = false;
    if (requestData.cancelled === 0) {
      if (requestData.username === username) {
        result = true;
      } else if (
        _.findIndex(requestData.approvers, function (approver) {
          return approver.username === username;
        }) !== -1
      ) {
        result = true;
      } else if (_.includes(userTeams, "HC Salary Approval")) {
        result = true;
      } else {
        let founds = [];
        userTeams.forEach((team) => {
          if (/^HC Salary Approval /.test(team)) {
            founds.push(team.slice(-2));
          }
        });
        if (founds.find((bu) => bu === requestData.country)) {
          result = true;
        }
      }
    }
    return result;
  }

  render() {
    const { requestData, username } = this.props;

    let {
      notes,
      bypassSelection,
      showInfo,
      selectedFile,
      loading,
      simpleView,
      userDetails,
      loadingAction,
      loadingSalary,
      loadingCancelation,
      loadingReminder,
      loadingBypass,
      loadingView,
      errorView,
      errorMessage,
    } = this.state;

    let approvers = [];
    let activities = [];
    let otherActions = [];
    let simpleViewInfo = [];
    var nextAprrover = null;

    console.log(requestData);

    if (requestData.id) {
      requestData.document.rows.map((value) => {
        let button = this.userDetailButton(value);
        simpleViewInfo.push({
          employee: <Text b>{value.Employee_Id}</Text>,
          name: value.Nombre,
          supervisor: value.Jefe,
          percent: value.Porcentaje_de_aumento,
          total: value.Salario_Total_nuevo,
          operation: button,
          info: value,
          date: value.Mes_aplicación_aumento,
          currency: value.Moneda,
        });
      });

      nextAprrover = _.findIndex(requestData.approvers, function (approver) {
        return approver.status === null;
      });
      var nextAprroverInfo = _.find(requestData.approvers, function (approver) {
        return approver.status === null;
      });
      if (nextAprrover === -1) nextAprrover = null;

      requestData.approvers.forEach((approver, index) => {
        var buttons = [];
        if (approver.status !== null) {
          approver.status
            ? buttons.push(
                <Badge
                  style={{ backgroundColor: "green" }}
                  size="large"
                  type="success"
                  key={"approved-" + index}
                >
                  Aprobado
                </Badge>
              )
            : buttons.push(
                <Badge size="large" type="error" key={"declined-" + index}>
                  Rechazado
                </Badge>
              );
        } else {
          if (
            requestData.status === null &&
            requestData.cancelled === 0 &&
            requestData.username === username
          ) {
            if (
              nextAprrover === index &&
              nextAprroverInfo.username !== username
            ) {
              // if (!approver.payroll)
              //   buttons.push(
              //     <Button
              //       type="warning"
              //       auto
              //       key={approver.id}
              //       onClick={(e) => this.toggleBypassModal(approver.id)}
              //     >
              //       Visto Bueno
              //     </Button>
              //   );
              buttons.push(
                <Button
                  type="secondary"
                  auto
                  key="reminder"
                  onClick={(e) => this.handleRemindeApprover(approver.id)}
                  loading={loadingReminder}
                >
                  Recordatorio
                </Button>
              );
            } else {
              buttons.push(
                <Button type="default" auto key="reminder" disabled>
                  En Espera
                </Button>
              );
            }
          }
        }

        if (!approver.payroll)
          approvers.push(
            <ListGroupItem className="px-0" key={approver.id}>
              <Row className="align-items-center">
                <Col className="col-auto">
                  <p
                    className="avatar rounded-circle"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img alt="..." src={require("assets/img/theme/user.svg")} />
                  </p>
                </Col>
                <div className="col ml--2">
                  <h4 className="mb-0">
                    <p>{approver.requester}</p>
                  </h4>
                  {approver.status !== null ? (
                    <small>
                      <strong>Comentario:</strong> {approver.note}
                      <br></br>
                      <strong> Fecha: </strong>
                      {approver.date}
                      <br></br>
                      {approver.bypass && (
                        <Button
                          type="secondary"
                          size="mini"
                          ghost
                          onClick={(e) =>
                            this.handleDocumentDownload(
                              approver.id,
                              approver.bypass.filename
                            )
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-file" />
                          </span>
                          <span className="btn-inner--text">Descargar VB</span>
                        </Button>
                      )}
                    </small>
                  ) : (
                    <small>
                      {requestData.cancelled === 1 ||
                      requestData.status === 0 ? (
                        <small>
                          <strong>No Requerido</strong>
                        </small>
                      ) : (
                        <small>
                          <strong>Pendiente Por Aprobación</strong>
                        </small>
                      )}
                    </small>
                  )}
                </div>
                <Col className="col-auto">{buttons}</Col>
              </Row>
            </ListGroupItem>
          );
      });

      requestData.activities.forEach((activity, index) => {
        activities.push(
          <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
            key={activity.id}
          >
            <div className="timeline-block">
              <span
                className={
                  activity.status === 1
                    ? "timeline-step  badge-success"
                    : "timeline-step badge-danger "
                }
              >
                <i
                  className={
                    activity.status === 1 ? "ni ni-check-bold" : "fa fa-times"
                  }
                />
              </span>
              <div className="timeline-content">
                <div className="d-flex justify-content-between pt-1">
                  <div>
                    <span className="text-muted text-sm font-weight-bold">
                      {activity.user}
                    </span>
                  </div>
                  <div className="text-right">
                    <small className="text-muted">
                      <i className="fas fa-clock mr-1" />
                      {activity.formatedCreatedAt}
                    </small>
                  </div>
                </div>
                <h6 className="text-sm mt-1 mb-0">
                  {activity.formatedTextStatus}
                </h6>
              </div>
            </div>
          </div>
        );
      });
      if (activities.length === 0)
        activities.push(
          <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
            key="none"
          >
            <div className="timeline-block">
              <span className="text-muted text-sm font-weight-bold">
                Ninguna Actividad Realizada
              </span>
            </div>
          </div>
        );
      if (requestData.status === null) {
        if (requestData.cancelled === 0 && requestData.username === username) {
          otherActions.push(
            <ListGroupItem className="px-0" key="cancelar-flujo">
              <Row style={{ justifyContent: "center" }}>
                <Button
                  type="error"
                  ghost
                  onClick={() =>
                    this.setState({ showConfirmCancelRequest: true })
                  }
                  loading={loadingCancelation}
                >
                  {" "}
                  Cancelar Flujo
                </Button>
              </Row>
            </ListGroupItem>
          );
        }
        //VALIDAR SI EL APROBADOR ESTA EN EL FLUJO
        let approverAction = _.findIndex(
          requestData.approvers,
          function (approver) {
            return approver.username === username && approver.status === null;
          }
        );

        var nextAprroverStatus = _.find(
          requestData.approvers,
          function (approver) {
            return approver.status === null;
          }
        );
        if (nextAprroverStatus === -1) nextAprrover = null;

        if (
          approverAction !== -1 &&
          nextAprroverStatus.username === username &&
          requestData.cancelled === 0
        ) {
          otherActions.push(
            <ListGroupItem className="px-0" key="aprobar-solicitud">
              <Row style={{ justifyContent: "center" }}>
                <Button
                  style={{
                    backgroundColor: "green",
                    color: "white",
                  }}
                  onClick={(e) =>
                    this.toggleApprovalModal(
                      requestData.approvers[approverAction].id
                    )
                  }
                >
                  {" "}
                  Aprobar Solicitud
                </Button>
              </Row>
            </ListGroupItem>
          );

          otherActions.push(
            <ListGroupItem className="px-0" key="rechazar-solicitud">
              <Row style={{ justifyContent: "center" }}>
                <Button
                  type="error"
                  onClick={(e) =>
                    this.toggleDeclineModal(
                      requestData.approvers[approverAction].id
                    )
                  }
                >
                  {" "}
                  Rechazar Solicitud
                </Button>
              </Row>
            </ListGroupItem>
          );
        }

        //PUEDE HABER N CANTIDAD DE OTROS FLUJOS
        //SE DEBE VALIDAR CADA UNA
      }
    }

    return (
      <>
        <SweetAlert
          show={this.state.showReminderAlert}
          success
          showCloseButton
          confirmBtnText="OK"
          confirmBtnBsStyle="success"
          title="Recordatorio Enviado Exitosamente!"
          onConfirm={() => this.hideReminderAlert()}
        >
          {/* Hemos enviado un recordatorio al usuario. */}
        </SweetAlert>
        <SweetAlert
          show={this.state.showSalaryViewAlert}
          error
          showCloseButton
          confirmBtnText="Entendido."
          confirmBtnBsStyle="danger"
          title="Para aprobar o rechazar una solicitud debe visualizar los salarios asignados al documento."
          onConfirm={() => this.hideSalaryViewAlert()}
        ></SweetAlert>
        <SweetAlert
          show={this.state.showFullDocumentAlert}
          input
          showCancel
          required
          cancelBtnText="Salir"
          confirmBtnText="Validar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="link"
          title="Token de confirmación:"
          validationMsg="Ingrese el número de confirmación"
          onConfirm={(response) =>
            this.handleFullDocumentValidation(requestData.id, response)
          }
          onCancel={() => this.hideFullDocumentAlert()}
        >
          Hemos enviado via:{" "}
          <span style={{ color: "blue" }}>
            <strong>E-Mail</strong>
          </span>{" "}
          un numero unico que le permitira por <strong>15 minutos</strong> tener
          visibilidad de información sensitiva.
        </SweetAlert>
        <SweetAlert
          show={this.state.showConfirmCancelRequest}
          warning
          showCancel
          cancelBtnText="Cancelar"
          confirmBtnText="Si, Estoy Seguro!"
          confirmBtnBsStyle="danger"
          title="Esta Seguro?"
          onConfirm={(e) => this.handleCancelRequest(requestData.id)}
          onCancel={() => this.handleCancelConfirmation()}
          focusCancelBtn
        >
          Esta seguro que desea eliminar esta Solicitud?
        </SweetAlert>
        <SweetAlert
          show={this.state.showConfirmStatusChange}
          success
          showCancel
          cancelBtnText="Seguir aca"
          confirmBtnText="Salir"
          title="Confirmación"
          onConfirm={(e) => this.BackToMain()}
          onCancel={() => this.setState({ showConfirmStatusChange: false })}
          focusCancelBtn
        >
          Hemos recibido correctamente la acción realizada, ¿Desea volver al
          menú de solicitudes?
        </SweetAlert>
        <Modal isOpen={this.state.showUserDetails}>
          <ModalHeader toggle={this.toggleUserDetailsModal}>
            Detalles de Usuario
          </ModalHeader>
          <ModalBody>
            <Form>{this.UserDetailsFields(userDetails)}</Form>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showBypassModal}>
          <ModalHeader toggle={this.toggleBypassModal}>
            Adjuntar archivo
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="customFileLang"
                    lang="en"
                    type="file"
                    name="file"
                    onChange={this.handleSelectFile}
                  />
                  <label className="custom-file-label" htmlFor="customFileLang">
                    {selectedFile ? selectedFile.name : "Seleccione un Archivo"}
                  </label>
                </div>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              type="success"
              onClick={() => this.handleBypassRequest(notes, bypassSelection)}
              loading={loadingBypass}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showApprovalModal}>
          <ModalHeader toggle={this.toggleApprovalModal}>
            Actualización de estado:
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <label className="form-control-label" htmlFor="approval-input">
                  Estado
                </label>
                <Input
                  id="approval-name-input"
                  placeholder="Aprobado"
                  type="text"
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea3"
                >
                  Notas:
                </label>
                <Input
                  id="exampleFormControlTextarea3"
                  resize="none"
                  rows="3"
                  type="textarea"
                  value={notes}
                  onChange={(e) => this.setState({ notes: e.target.value })}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              type="success"
              onClick={() => this.handleApproveRequest(notes, true)}
              loading={loadingAction}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showDeclineModal}>
          <ModalHeader toggle={this.toggleDeclineModal}>
            Actualización de estado:
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <label className="form-control-label" htmlFor="approval-input">
                  Estado
                </label>
                <Input
                  id="approval-name-input"
                  placeholder="Rechazado"
                  type="text"
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea3"
                >
                  Notas:
                </label>
                <Input
                  id="exampleFormControlTextarea3"
                  resize="none"
                  rows="3"
                  type="textarea"
                  value={notes}
                  onChange={(e) => this.setState({ notes: e.target.value })}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              type="success"
              onClick={() => this.handleDeclineRequest(notes, false)}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name="Detalles de Solicitud"
          parentName="Sistema de Aprobación Salarial"
        />
        {loadingView ? (
          errorView ? (
            <h1>Error: {errorMessage}</h1>
          ) : (
            <h1>Cargando Información...</h1>
          )
        ) : (
          <Container className="mt--6" fluid>
            <Spin size="large" spinning={loading}>
              <Row>
                {requestData.cancelled ? (
                  <Col lg="6">
                    <Card
                      className={
                        requestData.status === 1
                          ? "bg-gradient-success border-0"
                          : "bg-gradient-danger border-0"
                      }
                    >
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              className="text-uppercase text-muted mb-0 text-white"
                              tag="h5"
                            >
                              Solicitud Cancelada
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              Flujo Detenido
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                              <i className={"fa fa-times"} />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ) : (
                  <Col lg="6">
                    <Card
                      className={
                        requestData.status === 1
                          ? "bg-gradient-success border-0"
                          : "bg-gradient-danger border-0"
                      }
                    >
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              className="text-uppercase text-muted mb-0 text-white"
                              tag="h5"
                            >
                              Estado Actual
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {requestData.formatedStatus}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                              <i
                                className={
                                  requestData.status === 1
                                    ? "fa fa-check"
                                    : "fa fa-times"
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                {requestData.status === null ? (
                  <Col lg="6">
                    <Card className="bg-gradient-primary border-0" tag="h5">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                              Fecha de Solicitud
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {requestData.formatedCreatedAt}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                              <i className="fa fa-calendar-alt" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ) : requestData.status === 1 ? (
                  <Col lg="6">
                    <Card className="bg-gradient-primary border-0" tag="h5">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                              Payroll
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {requestData.formatedPayroll}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                              <i className="fa fa-comments-dollar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ) : (
                  <Col lg="6">
                    <Card className="bg-gradient-primary border-0" tag="h5">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                              Payroll
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              No Requerido
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                              <i className="fa fa-comments-dollar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8" lg="8">
                          <h5 className="h3 mb-0">Documento de Aprobación</h5>
                          <p className="d-block text-muted">
                            Solicitante: {requestData.requester}
                            {"  "}
                            <small className="d-block text-muted">
                              Identificador #{requestData.id} - Tipo:{" "}
                              {requestData.typeFormat}
                            </small>
                            {requestData.date && (
                              <small className="d-block text-muted">
                                Fecha de Aplicación: {requestData.date}
                              </small>
                            )}
                          </p>
                          {/* <Button
                            type="success"
                            size="mini"
                            ghost
                            onClick={() => this.toggleSimpleView()}
                          >
                            {simpleView ? "Vista Detallada" : "Vista Sencilla"}
                          </Button> */}
                        </Col>

                        {this.showButton() ? (
                          <Col xs="4" lg="4">
                            <Row style={{ justifyContent: "flex-end" }}>
                              {" "}
                              <Button
                                type="error"
                                size="small"
                                onClick={() =>
                                  this.handleShowConfirmation(requestData.id)
                                }
                                loading={loadingSalary}
                                disabled={showInfo}
                              >
                                Mostrar Salarios
                              </Button>
                            </Row>
                            <Row
                              style={{
                                justifyContent: "flex-end",
                                marginTop: "10px",
                              }}
                            >
                              {" "}
                              <Text style={{ display: "flex" }}></Text>
                              <Toggle
                                initialChecked={simpleView}
                                size="large"
                                onChange={() => this.toggleSimpleView()}
                              />
                              {"  "}
                              {simpleView
                                ? " Vista Resumida"
                                : " Vista Detallada"}
                            </Row>
                          </Col>
                        ) : (
                          <Col className="text-right" xs="4">
                            <Button type="error" size="small" disabled>
                              No Visible
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {!simpleView && (
                        <div>
                          {" "}
                          <Row>
                            <ToolkitProvider
                              data={requestData.document.rows}
                              keyField="key"
                              columns={requestData.document.cols}
                              search
                            >
                              {(props) => (
                                <div className="py-4 table-responsive">
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1"
                                  >
                                    <label>
                                      Buscar:
                                      <SearchBar
                                        className="form-control-sm"
                                        placeholder="Buscar"
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                  <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    pagination={pagination}
                                    bordered={false}
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          </Row>
                        </div>
                      )}
                      {simpleView && (
                        <div>
                          <Table data={simpleViewInfo}>
                            <Table.Column prop="employee" label="Empleado" />
                            <Table.Column
                              prop="name"
                              label="Nombre"
                              width={150}
                            />
                            <Table.Column
                              prop="supervisor"
                              label="Jefe"
                              width={150}
                            />
                            <Table.Column
                              prop="percent"
                              label="Porcentaje Aumento"
                            />
                            <Table.Column prop="total" label="Salario Nuevo" />
                            <Table.Column prop="currency" label="Moneda" />
                            <Table.Column
                              prop="date"
                              label="Mes de Aplicación"
                            />
                            <Table.Column prop="operation" label="" />
                          </Table>
                        </div>
                      )}
                    </CardBody>
                    <CardFooter className="bg-transparent align-items-center">
                      <Row>
                        <p className="">
                          <strong> Comentario de Solicitud:</strong>{" "}
                          {requestData.note}
                        </p>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="8">
                  <Card>
                    <CardHeader>
                      <h5 className="h3 mb-0">Lista de Aprobadores</h5>
                    </CardHeader>

                    <CardBody>
                      <ListGroup className="list my--3" flush>
                        {approvers}
                      </ListGroup>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="h3 mb-0">Aplicación de Solicitud</h5>
                    </CardHeader>

                    <CardBody>
                      <ListGroup className="list my--3" flush>
                        {requestData.approvers.map((value) => {
                          if (value.payroll)
                            return (
                              <ListGroupItem
                                className="px-0"
                                key="payroll-approver"
                              >
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <p
                                      className="avatar rounded-circle"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/theme/user.svg")}
                                      />
                                    </p>
                                  </Col>
                                  <div className="col ml--2">
                                    <h4 className="mb-0">
                                      <p>{value.requester}</p>
                                    </h4>
                                    {value.status !== null ? (
                                      <small>
                                        <strong>Comentario:</strong>{" "}
                                        {value.note}
                                        <br></br>
                                        <strong> Fecha: </strong>
                                        {value.date}
                                      </small>
                                    ) : (
                                      <small>
                                        {requestData.cancelled === 1 ||
                                        requestData.status === 0 ? (
                                          <small>
                                            <strong>No Requerido</strong>
                                          </small>
                                        ) : (
                                          <small>
                                            <strong>
                                              Pendiente Por Aplicación
                                            </strong>
                                          </small>
                                        )}
                                      </small>
                                    )}
                                  </div>
                                  {value.status !== null &&
                                    (value.status ? (
                                      <Col className="col-auto">
                                        <Badge
                                          style={{ backgroundColor: "green" }}
                                          size="large"
                                          type="success"
                                          key={"approved-ok"}
                                        >
                                          Aplicado
                                        </Badge>
                                      </Col>
                                    ) : (
                                      <Col className="col-auto">
                                        <Badge
                                          size="large"
                                          type="error"
                                          key={"declined-payroll"}
                                        >
                                          Rechazado
                                        </Badge>
                                      </Col>
                                    ))}
                                </Row>
                              </ListGroupItem>
                            );
                        })}
                      </ListGroup>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Archivos Adjuntos</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          {requestData.username === username && (
                            <Button
                              color="primary"
                              onClick={(e) => this.toggleBypassModal()}
                              size="sm"
                              disabled={
                                requestData.status !== null ||
                                requestData.cancelled === 1
                              }
                            >
                              <i className={"fa fa-upload"} />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      <ListGroup className="list my--3" flush>
                        {requestData.files.map((value, key) => {
                          return (
                            <ListGroupItem className="px-0" key={key + "-file"}>
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <p
                                    className="avatar rounded-circle"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img
                                      alt="..."
                                      src={require("assets/img/theme/attachment.png")}
                                    />
                                  </p>
                                </Col>
                                <div className="col ml--2">
                                  <h4 className="mb-0">
                                    <p>{value.filename}</p>
                                  </h4>
                                </div>
                                <Col className="col-auto">
                                  <Button
                                    type="success"
                                    size="sm"
                                    key="reminder"
                                    onClick={(e) =>
                                      this.handleDocumentDownload(
                                        value.id,
                                        value.filename
                                      )
                                    }
                                    //disabled
                                  >
                                    Descargar Adjunto
                                  </Button>
                                  {requestData.username === username && (
                                    <Button
                                      type="error"
                                      size="sm"
                                      key="delete"
                                      onClick={(e) =>
                                        this.handleDocumentDelete(value.id)
                                      }
                                    >
                                      X
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          );
                        })}
                        {requestData.files.length === 0 && (
                          <Row>
                            {" "}
                            <h4>Ningun archivo adjunto</h4>
                          </Row>
                        )}
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card>
                    <CardHeader>
                      <h5 className="h3 mb-0">Flujo de Aprobaciones</h5>
                    </CardHeader>
                    <CardBody>{activities}</CardBody>
                    <CardFooter className="bg-transparent align-items-center">
                      <p className="">Total: {requestData.approversPercent}%</p>
                    </CardFooter>
                  </Card>

                  {otherActions.length > 0 && (
                    <Card>
                      <CardHeader>
                        <h5 className="h3 mb-0">Otras Acciones</h5>
                      </CardHeader>
                      <CardBody>
                        <ListGroup className="list my--3" flush>
                          {otherActions}
                        </ListGroup>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
            </Spin>
          </Container>
        )}
      </>
    );
  }
}

SalaryRequestDetails.defaultProps = {
  requestData: {},
  username: "",
  userTeams: [],
};

const mapStateToProps = (state) => ({
  requestData: requestSelector(state),
  username: getUsernameLogged(),
  userTeams: getTeamsUserAccess(),
});

export default withRouter(
  connect(mapStateToProps, {
    getRequest,
    checkSession,
    showFullDocument,
    cancelRequest,
    approverAction,
    bypassAction,
    reminder,
    downloadBypassDocument,
    deleteDocument,
  })(SalaryRequestDetails)
);
