// javascript library dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

const SignaturesBoss = (props) => {
  const { title, rows, noHomeOffice, openFile, onRelaunch } = props;

  return (
    <div className="card-deck flex-column flex-xl-row">
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col sm="12" md="8">
              <h5 className="h3 mb-0">{title}</h5>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ListGroup className="list my--3" flush>
            {rows.map((row, key) => (
              <ListGroupItem key={key} className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      alt="Notificación"
                      className="avatar rounded-circle"
                      src={require(`assets/img/theme/signature.png`)}
                    />
                  </Col>
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      {row.description} - {row.state}
                    </h4>
                    <small className="text-muted">
                      {moment(row.createdAt).format("LLLL")} - {row.nameUser}
                    </small>
                  </div>
                  <Col className="col-auto">
                    {row.active === 1 ? (
                      <Button
                        color="success"
                        // size="sm"
                        type="button"
                        onClick={() => openFile(row)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-search" />
                        </span>
                        <span className="btn-inner--text">Explorar</span>
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        // size="sm"
                        type="button"
                        disabled
                        // onClick={() => openFile(row)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-times-circle" />
                        </span>
                        <span className="btn-inner--text">Expirada</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
            {noHomeOffice.map((row, key) => (
              <ListGroupItem key={key} className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      alt="Notificación"
                      className="avatar rounded-circle"
                      src={require(`assets/img/theme/signature.png`)}
                    />
                  </Col>
                  <div className="col ml--2">
                    <h4 className="mb-0">{row.document} - No Home Office</h4>
                    <small className="text-muted">
                      {moment(row.date).format("LLLL")} - {row.name}
                    </small>
                  </div>
                  <Col className="col-auto">
                    <Button
                      color="info"
                      // size="sm"
                      type="button"
                      onClick={() => onRelaunch(row)}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-external-link-alt" />
                      </span>
                      <span className="btn-inner--text">Relanzar</span>
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  );
};

SignaturesBoss.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SignaturesBoss;
