import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

/**
 * Accion para obtener todas las posiciones de la base de datos
 */
export const findUsersWithAccess = createAction(
  constants.GET_USERS_WITH_ACCESS,
  method.get(urls.NEWPOSITION.getUsersWithAccess)
);

export const findPositions = createAction(
  constants.GET_POSITIONS,
  method.get(urls.NEWPOSITION.getPositions)
);

export const findUnpprovedVacants = createAction(
  constants.GET_UNPPROVED_VACANT_POSITION,
  method.get(urls.NEWPOSITION.getUnpprovedVacantsRequest)
);

export const findAllRequestByUsers = createAction(
  constants.GET_ALL_REQUESTS_BY_USERS,
  method.get(urls.NEWPOSITION.getAllRequestByUsers)
);

export const findRequestByUser = createAction(
  constants.GET_REQUESTS_BY_USER,
  (body) => method.post(urls.NEWPOSITION.getRequestByUser, body)()
);

/**
 * Accion para buscar la informacion asociada a una posicion especifica
 */
export const findDataByPosition = createAction(
  constants.GET_DATA_BY_POSITIONS,
  (idPosition, idCountry) =>
    method.get(
      `${urls.NEWPOSITION.getDataByPosition}/${idPosition}/${idCountry}`
    )()
);

/**
 * Accion para buscar la informacion de una vacante
 */
export const findVacantPositionById = createAction(
  constants.GET_VACANT_POSITION_BY_ID,
  (id) => method.get(`${urls.NEWPOSITION.getVacantById}/${id}`)()
);

/**
 * Accion para buscar la informacion de una posicion en especifico para modificar
 */
export const findDataUpdateByPosition = createAction(
  constants.GET_DATA_UPDATE_BY_POSITION,
  (idPosition) =>
    method.get(`${urls.NEWPOSITION.getDataUpdateByPosition}/${idPosition}`)()
);

/**
 * Accion para buscar la informacion de una posicion para realizar nuevas asociaciones o modificar las existentes
 */
export const findRelationDataByPosition = createAction(
  constants.GET_RELATION_DATA_BY_POSITION,
  (idPosition) =>
    method.get(`${urls.NEWPOSITION.getRelationDataByPosition}/${idPosition}`)()
);

/**
 * Accion para recuperar el total de solicitudes de posiciones creadas
 */
export const findTotalPositionsCreated = createAction(
  constants.GET_TOTAL_POSITIONS_CREATED,
  (date) => method.post(urls.NEWPOSITION.getTotalPositionsCreated, date)()
);
/**
 * Accion para crear una posicion no planificada
 */
export const createUnplanned = createAction(
  constants.CREATE_UNPLANNED_POSITION,
  (body) => method.post(urls.NEWPOSITION.createUnplannedPosition, body)()
);

/**
 * Accion para modificar una posicion con personal
 */
export const updateStaff = createAction(
  constants.CREATE_STAFF_POSITION,
  (body) => method.post(urls.NEWPOSITION.createStaffPosition, body)()
);

/**
 * Accion para modificar una posicion con personal
 */
export const updateVacant = createAction(
  constants.CREATE_VACANT_POSITION,
  (body) => method.post(urls.NEWPOSITION.createVacantPosition, body)()
);

/**
 * Accion para aceptar la modificiacion de una posicion vacante
 */
export const approvedVacantPosition = createAction(
  constants.APPROVED_VACANT_POSITION,
  (id, body) =>
    method.put(`${urls.NEWPOSITION.approvedVacantPosition}/${id}`, body)()
);

/**
 * Accion para rechazar la modificiacion de una posicion vacante
 */
export const unapprovedVacantPosition = createAction(
  constants.UNAPPROVED_VACANT_POSITION,
  (id, body) =>
    method.put(`${urls.NEWPOSITION.unapprovedVacantPosition}/${id}`, body)()
);

/**
 * Accion para la gestion de las relaciones de una posicion
 */
export const createRelation = createAction(
  constants.CREATE_RELATIONS_BY_POSITION,
  (body) => method.post(urls.NEWPOSITION.createRelationsByPosition, body)()
);

/**
 * Accion para la gestion de las relaciones de una posicion
 */
export const updateVacantPositionById = createAction(
  constants.UPDATE_VACANT_POSITION,
  (id, body) =>
    method.put(`${urls.NEWPOSITION.updateVacantPosition}/${id}`, body)()
);

export const createUserWithAccess = createAction(
  constants.CREATE_USER_WITH_ACCESS,
  (body) => method.post(urls.NEWPOSITION.createUserWithAccess, body)()
);

export const deactivatedUserWithAccess = createAction(
  constants.DEACTIVATED_USER_WITH_ACCESS,
  (id) => method.put(`${urls.NEWPOSITION.deactivatedUserWithAccess}/${id}`)()
);
/**
 * Accion para modificar un centro de costo
 */
export const updateNewCecoPosition = createAction(
  constants.UPDATE_CECO_POSITION,
  (body) => method.post(urls.NEWPOSITION.updateNewCecoPosition, body)()
);
/**
 * Accion para limpiar la informacion
 */
export const clearData = createAction(constants.CLEAR_DATA);

export const findAllMasterData = createAction(
  constants.GET_ALL_MASTER_DATA_POSITIONS,
  method.get(urls.NEWPOSITION.getAllMasterData)
);

export const createMasterData = createAction(
  constants.CREATE_MASTER_DATA_POSITIONS,
  (body) => method.post(urls.NEWPOSITION.createMasterData, body)()
);
