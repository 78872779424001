/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Exclusivo Human Capital en la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React, { useState, useEffect, useRef } from "react";
import { Card, CardTitle, Button, ButtonGroup, Label, Col, Row, Input, FormGroup, FormFeedback } from "reactstrap";
import MultiSelect from 'react-select';

const ExclusiveHC = ({ data, optionsJson, setData, isVisible, disabledFieldsJson, requiredJson, enableComments, checkIdColab }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif' };
    const groupStyle = { marginBottom: '0.1rem', fontFamily: 'sans-serif' };
    const labelStyle = { height: '1.5em', marginBottom: '0.1rem', fontFamily: 'sans-serif' };
    const completedForm = {
        type: "text",
        label: "Completado:",
        id: "completed",
        colWidth: 4,
        required: false,
        disabled: true,
        maxLength: 25
    };
    const forms = [
        {
            type: "text",
            label: "Id Colaborador",
            id: "employeeId",
            maxLength: 10,
            colWidth: 4,
            required: true,
            disabled: false,
            maxLength: 10
        },
        {
            type: "text",
            label: "Teléfono Móvil",
            id: "mobileNumber",
            colWidth: 4,
            required: false,
            disabled: false,
            maxLength: 19
        },
        {
            type: "textarea",
            label: "Observaciones (máx 500 caracteres)",
            id: "hcComments",
            maxLength: 500,
            colWidth: 12,
            required: false,
            disabled: enableComments,
        },
        {
            type: "date",
            label: "Fin Contrato",
            id: "endDate",
            colWidth: 6,
            required: false,
            disabled: false,
        }
    ];
    const endDate = forms.find(form => form.id === "endDate");

    //FUNCIONES
    const [isEndDateVisible, setIsEndDateVisible] = useState(false);
    const [multiValues, setMultiValues] = useState([]);
    const [multiSelected, setMultiSelected] = useState([]);
    const countryRef = useRef(data.country);
    const [countryOptionLocation, setCountryOptionLocation] = useState([]);
    const [labelColor, setLabelColor] = useState({ color: '' });
    const jobRef = useRef(data.jobType);
    const [isNewColab, setIsNewColab] = useState(true); //state del boton reingreso
    const [checkColab, setCheckColab] = useState(0); //state del boton de comprobar

    useEffect(() => {
        if (requiredJson.includes("emailGroups")) {
            setLabelColor({ color: '#fb6340' })
        } else {
            setLabelColor({ color: '' })
        }
    }, [requiredJson]);

    //Inicializacion
    useEffect(() => {

        const newEmailGroupOptions = optionsJson.emailGroup.filter(emailGroups => emailGroups.idCountry === Number(data.country));

        let parsedEmailGroupOptions = [];
        newEmailGroupOptions.map((countryJson) => {
            parsedEmailGroupOptions.push({ label: countryJson.value, value: countryJson.id });
        });
        setMultiValues(parsedEmailGroupOptions);

        //las opciones del multiselect
        let selectedArray = [];
        data.emailGroups && data.emailGroups.map((emailGroup) => {
            const selectedJson = newEmailGroupOptions.find(item => item.id === Number(emailGroup));
            selectedArray.push({ label: selectedJson.value, value: selectedJson.id });
        });
        setMultiSelected(selectedArray);

        //las opciones del radio button
        setCountryOptionLocation(optionsJson.location.filter(location => location.idCountry === Number(data.country)))

        //setear el endate
        if (data.jobType !== "1") {
            setIsEndDateVisible(true);
        }
        //setear el estado inicial del radio button
        handleNewColab(isNewColab);
    }, []);

    useEffect(() => {
        if (countryRef.current !== data.country) {
            countryRef.current = data.country;
            const newEmailGroupOptions = optionsJson.emailGroup.filter(emailGroups => emailGroups.idCountry === Number(data.country));
            const newLocationOptions = optionsJson.location.filter(location => location.idCountry === Number(data.country));

            let parsedEmailGroupOptions = [];
            newEmailGroupOptions.map((countryJson) => {
                parsedEmailGroupOptions.push({ label: countryJson.value, value: countryJson.id });
            });
            setMultiValues(parsedEmailGroupOptions);
            setCountryOptionLocation(newLocationOptions)

            //las opciones seleccionadas
            setMultiSelected([]);
            if (newLocationOptions.length > 0) {
                setData((prevState) => ({ ...prevState, location: newLocationOptions[0].id }));
            }
        }
    }, [data.country]);

    //Cargar los email groups a data
    useEffect(() => {
        setData((prevState) => ({ ...prevState, emailGroups: multiSelected ? multiSelected.map(group => group.value.toString()) : [] }));
    }, [multiSelected]);

    const handleOnChange = (id, disabled, e) => {
        if (!disabled) {
            e.persist();
            setData((prevState) => ({ ...prevState, [id]: e.target.value }));
        }
    };

    const handleMultiChange = (selectedOptions) => {
        setMultiSelected(selectedOptions);
    };

    //para visible el endDate
    useEffect(() => {
        if (jobRef.current !== data.jobType) {
            jobRef.current = data.jobType;
            if (data.jobType !== "1") {
                setIsEndDateVisible(true);
            }
            else {
                setIsEndDateVisible(false);
            }
        }
    }, [data.jobType]);

    //boton de comprobarID
    const handleCheckButton = async (employeeId) => {
        const validId = await checkIdColab(employeeId);
        if (validId) { setCheckColab(2); }
        else { setCheckColab(1); }
    };

    //boton de reingreso
    const handleNewColab = (isNewColab) => {
        setIsNewColab(isNewColab);
        if (isNewColab) {
            setData((prevState) => ({ ...prevState, isNewColab: '1' }));
        } else {
            setData((prevState) => ({ ...prevState, isNewColab: '0' }));
        }
    };


    //RETURN 
    const disabled = disabledFieldsJson && disabledFieldsJson.includes("ExclusiveHC");
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Exclusivo Human Capital
                    </CardTitle>

                    {/* reingreso */}
                    {!(data.requestState === "Modificacion") && (!disabled && (
                        <Row style={{ padding: '0px 25px 0px' }}>
                            <Col xs="4">
                                <FormGroup style={{ ...groupStyle }}>
                                    <Label style={{ ...groupStyle }}>
                                        Colaborador:
                                    </Label>
                                    <ButtonGroup style={{ marginLeft: '5px' }}>
                                        <Button
                                            color="info"
                                            outline
                                            onClick={() => handleNewColab(true)}
                                            active={isNewColab}
                                        >
                                            Nuevo
                                        </Button>
                                        <Button
                                            color="info"
                                            outline
                                            onClick={() => handleNewColab(false)}
                                            active={!isNewColab}
                                        >
                                            Reingreso
                                        </Button>
                                    </ButtonGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    ))}

                    <Row style={{ padding: '0px 25px 0px' }}>
                        {/* id colab */}
                        <Col xs={forms[0].colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={forms[0].id} style={labelStyle}>
                                    {forms[0].label}
                                    {forms[0].required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    type={forms[0].type}
                                    id={forms[0].id}
                                    maxLength={forms[0].maxLength}
                                    disabled={(disabledFieldsJson && disabledFieldsJson.includes("ExclusiveHC")) || (data.requestState === "Modificacion")}
                                    invalid={requiredJson && requiredJson.includes(forms[0].id)}
                                    className="form-control"
                                    onChange={(e) => handleOnChange(forms[0].id, false, e)}
                                    defaultValue={data[forms[0].id]}
                                />
                                <FormFeedback>
                                    Este campo no puede estar vacío
                                </FormFeedback>
                            </FormGroup>
                        </Col>

                        {/* boton comprobar */}
                        {!(data.requestState === "Modificacion") && (isNewColab && !disabled && (
                            <Row style={{ padding: '0px 15px 15px' }}>

                                <Button
                                    color="info"
                                    onClick={() => handleCheckButton(data.employeeId)}
                                    style={{ marginTop: '25px' }}>
                                    Comprobar Id de Colaborador
                                </Button>


                                {checkColab === 2 ? (
                                    <h3
                                        style={
                                            {
                                                fontWeight: 'bold',
                                                color: "red",
                                                display: 'flex',
                                                flexDirection: 'column-reverse',
                                                alignItems: 'flex-start',
                                                marginBottom: "2px"
                                            }
                                        }
                                    >
                                        El Id de colaborador ya existe
                                    </h3>
                                ) : checkColab === 1 ? (
                                    <h3
                                        style={
                                            {
                                                fontWeight: 'bold',
                                                color: "green",
                                                display: 'flex',
                                                flexDirection: 'column-reverse',
                                                alignItems: 'flex-start',
                                                marginBottom: "2px"
                                            }
                                        }
                                    >
                                        El Id de colaborador esta disponible
                                    </h3>
                                ) : (
                                    <></>
                                )

                                }
                            </Row>
                        ))}
                    </Row>

                    <Row style={{ padding: '0px 25px 0px' }}>
                        {/* telefono movil */}
                        <Col xs={forms[1].colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={forms[1].id} style={labelStyle}>
                                    {forms[1].label}
                                    {forms[1].required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <Input
                                    type={forms[1].type}
                                    invalid={requiredJson && requiredJson.includes(forms[1].id)}
                                    id={forms[1].id}
                                    disabled={(disabledFieldsJson && disabledFieldsJson.includes("ExclusiveHC"))}
                                    className="form-control"
                                    onChange={(e) => handleOnChange(forms[1].id, false, e)}
                                    defaultValue={data[forms[1].id]}
                                    maxLength={forms[1].maxLength}
                                />
                                <FormFeedback>
                                    Este campo no puede estar vacío
                                </FormFeedback>


                            </FormGroup>
                        </Col>
                        {/* email group */}
                        <Col xs={4}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor="emailsSelect" style={labelColor}>
                                    Email groups
                                    {forms[1].required && <span style={{ color: 'red' }}> *</span>}
                                </Label>
                                <MultiSelect
                                    closeMenuOnSelect={false}
                                    id="emailsSelect"
                                    isMulti
                                    options={multiValues}
                                    value={multiSelected}
                                    onChange={handleMultiChange}
                                    isDisabled={(disabledFieldsJson && disabledFieldsJson.includes("ExclusiveHC"))}
                                />
                            </FormGroup>
                        </Col>
                        {/* location*/}
                        <Col xs={4}>
                            <FormGroup style={{ ...groupStyle }}>
                                <ButtonGroup
                                    vertical
                                >
                                    <Label htmlFor={"location"} style={labelStyle}>
                                        Localidad
                                        <span style={{ color: 'red' }}> *</span>
                                    </Label>

                                    {countryOptionLocation.map((location) => (
                                        <Button
                                            key={location.id}
                                            id={location.id}
                                            value={location.id}
                                            color={disabled ? "light" : "info"}
                                            outline
                                            onClick={(e) => handleOnChange("location", disabled, e)}
                                            active={location.id == Number(data.location)}
                                        >
                                            {location.value}
                                        </Button>
                                    ))}
                                </ButtonGroup>

                            </FormGroup>
                        </Col>
                    </Row>

                    {/* contract end */}
                    {isEndDateVisible && (
                        <Row style={{ padding: '0px 25px 0px' }}>
                            <Col xs={completedForm.colWidth}>
                                <FormGroup>
                                    <Label htmlFor={endDate.id} style={labelStyle}>
                                        {endDate.label}
                                    </Label>
                                    <Input
                                        id={endDate.id}
                                        type={endDate.type}
                                        invalid={requiredJson && requiredJson.includes(endDate.id)}
                                        disabled={(disabledFieldsJson && disabledFieldsJson.includes("ExclusiveHC"))}
                                        className="form-control"
                                        onChange={(e) => handleOnChange(endDate.id, false, e)}
                                        defaultValue={data[endDate.id]}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

                    {/* comments */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={forms[2].colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={forms[2].id} style={labelStyle}>
                                    {forms[2].label}
                                </Label>
                                <Input
                                    type={forms[2].type}
                                    id={forms[2].id}
                                    maxLength={forms[2].maxLength}
                                    disabled={!forms[2].disabled && (disabledFieldsJson && disabledFieldsJson.includes("ExclusiveHC"))}
                                    className="form-control"
                                    onChange={(e) => handleOnChange(forms[2].id, false, e)}
                                    defaultValue={data[forms[2].id]}

                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* completed by */}
                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col xs={completedForm.colWidth}>
                            <FormGroup style={{ ...groupStyle }}>
                                <Label htmlFor={completedForm.id} style={labelStyle}>
                                    {completedForm.label}
                                </Label>
                                <Input
                                    type={completedForm.type}
                                    id={completedForm.id}
                                    disabled
                                    className="form-control"
                                    defaultValue={data.hcApprover}
                                    maxLength={completedForm.maxLength}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );

}

export default ExclusiveHC;
