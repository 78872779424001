/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Main" de ReportHours para la aprobación del reporte de tiempos que realizan los freelance externos
*El cual llama los siguientes componentes:
    FiltersMain: Componente compartido para filtrar la tabla principal de asignaciones de ordenes de compra.
    MainTable: Componente compartido para mostrar las asignaciones de las ordenes de compra
    DetailsModal: Componente Modal para visualizar el detalle de un reporte de tiempos
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    MySwal: funcion para las notificaciones personalizadas
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    isL0: useState que guarda un booleano para verificar si el usuario logeado es aprobador L0
=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState, useEffect } from "react";
//customized Component
import FiltersMain from "components/Freelance/SharedComponents/FiltersMain.jsx";
import MainTable from "components/Freelance/SharedComponents/MainTable.jsx";
import DetailsModal from "components/Freelance/ReportHours/DetailsModal.jsx";
//accion para extraer los reportes de tiempos, para aprobar/devolver/rechazar reportes, y para aprobar multiples registros
import { reportHoursActions, approveMassAction, getReportStatus } from "actions/Freelance.jsx";
//usedispatch for fetch
import { useDispatch } from "react-redux";


const MainReportHours = ({
  setLoad,
  Toast,
  dropDownOptions,
  rolArea,
  MySwal,
  isAdmin,
  isL0,
}) => {

  //#region Functions

  const [pos, setPos] = useState([]);
  const initialValue = [];
  const [reportInfo, setReportInfo] = useState(initialValue);
  const [totalHours, setTotalHours] = useState(0);

  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region State para guardar los filtros seleccionados según cada tabla en FilterMain
  const [search, setSearch] = useState({});
  //#endregion

  //#region funcion para los dropdowns que modifican el state de los filtros (se utiliza en FilterMain)
  const handleOnChangeFilter = (constant, valor) => {
    setSearch((prevState) => ({
      ...prevState,
      [constant]: valor,
    }));
  };
  //#endregion

  //#region state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);
  //#endregion

  //#region state para recargar la tabla principal cuando se necesite
  const [reloadTable, setRealTable] = useState(false);
  //#endregion

  //#region funciona para eliminar un registro de tiempos cuando da error en CATS en SAP
  const deleteHours = (e, row, dropDown) => {
    const inactive = MySwal.fire({
      title: `Deseas eliminar el registro de la Purchase Order ${row["purchaseOrderItem"]}?`,
      text: "Se puede reversar con ayuda de Application Management",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar.",
      confirmButtonColor: "#fa0303",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          row: row,
          action: "del",
        };
        dispatch(reportHoursActions({ info: info })).then((resp) => {
          const { payload } = resp;

          if (payload.status === 200) {
            setRealTable((prevCheck) => !prevCheck);
            Toast.fire({
              title: "Atención",
              html: "Se eliminó con éxito",
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.response.data.message,
              type: "warning",
            });
          }
          setLoad(false);
        });
      }
    });
  };
  //#endregion

  //#region reprocesar horas cuando se haya arreglado en SAP
const reprocessHours = (e, row, dropDown) => {
  const inactive = MySwal.fire({
    title: `Deseas reprocesar el registro de la Purchase Order ${row["purchaseOrderItem"]}?`,
    text: "El robot de MIS intentará crear de nuevo el registro de CATS y el registro estará aprobado",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Si, reprocesar.",
    confirmButtonColor: "#2dce89",
    cancelButtonText: "No, cancelar",
  }).then((result) => {
    if (result.value) {
      setLoad(true);
      var info = {
        row: row,
        action: "rep",
      };
      dispatch(reportHoursActions({ info: info })).then((resp) => {
        const { payload } = resp;

        if (payload.status === 200) {
          setRealTable((prevCheck) => !prevCheck);
          Toast.fire({
            title: "Atención",
            html: "Se reprocesó con éxito",
            type: "success",
          });
        } else {
          Toast.fire({
            title: "Atención",
            html: payload.response.data.message,
            type: "warning",
          });
        }
        setLoad(false);
      });
    }
  });
}
  //#endregion

//#region cambiar estado del registro solo si es admin
const changeStatusHours = (e, row, dropDown) => {
  let selectHtml = `<select id="newStatus" class="form-control">
                      <option value=""></option>
                      <option value="1">Aprobado</option>
                      <option value="5">Devolver</option>
                      <option value="10">Rechazar</option>
                      <option value="3">En aprobación</option>
                      <option value="14">En aprobación L0</option>
                      <option value="4">Error de SAP</option>
                    </select><br>`;

  let newStatusFinal = "";

  MySwal.fire({
    title: `Cambiar Estado de la PO: ${row["purchaseOrderItem"]} del día ${row["Cdate"]}`,
    html: selectHtml,
    confirmButtonText: "Si, cambiar.",
    confirmButtonColor: "#5e72e4",
    cancelButtonText: "No, cancelar",
    showCancelButton: true,
    preConfirm: () => {

      return new Promise((resolve) => {
        const newStatusValue = document.getElementById("newStatus").value;
  
        if (newStatusValue === "") {
          MySwal.showValidationMessage("El Estado es obligatorio!");
        } else {
          newStatusFinal = newStatusValue;
          resolve(); // Resolve the Promise to indicate completion
        }
      });
    },
  }) .then(() => {
    const admin = true;
    // The user has interacted with the modal, and newStatusFinal is set
    if (newStatusFinal !== "") {
      switch (newStatusFinal) {
        case "10":
          //rechazar
          rejecteHours(e, row, dropDown, admin);
          break;
        case "5":
          //devolver
          returnHours(e, row, dropDown, admin);
          break;
        case "1":
          //aprobar la solicitud
          approvedHours(e, row, dropDown, admin);
          break;
        default:
          MySwal.fire({
            title: 'Loading...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              MySwal.showLoading();
            },
          });
          setLoad(true);
          var info = {
            row: row,
            action: "chSt",
            newStatus: parseInt(newStatusFinal),
            admin: true
          };
          dispatch(reportHoursActions({ info: info })).then((resp) => {
            const { payload } = resp;
            
            if (payload.status === 200) {
              Toast.fire({
                title: "Atención",
                html: "Se modificó el estado con éxito",
                type: "success",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.response.data.message,
                type: "warning",
              });
            }
            setRealTable((prevCheck) => !prevCheck);
            setPos((current) => []);
            setReportInfo(initialValue);
            setTotalHours(0);
            setCheckboxState({});
            MySwal.close();
            setLoad(false);
          });
          break;
      }
    }
  })
  .catch((error) => {
    // Handle errors if necessary
    console.error(error);
  });

 


}

//#endregion

  //#region state y funcion para abrir los detalles del reporte
  const [detailModal, setModal] = useState({
    row: "",
    showModal: false,
  });
  const { row, showModal } = detailModal;
  const detailHours = (e, row, dropDown) => {
    setModal((prevState) => ({
      ...prevState,
      row: row,
      showModal: !prevState.showModal,
    }));
  };
  //#endregion

  //#region state y funcion para seleccionar cuantas horas aprobar


  //funcion para seleccionar las PO que se van aprobar masivamente
  const [checkboxState, setCheckboxState] = useState({});
  const handleSelPos = (e, row) => {
    e.persist();
    const { checked } = e.target;
    let checkedBool = checked
    setCheckboxState(prevState => ({
      ...prevState,
      [row.id]: checkedBool
    }));
    if (!checkedBool) {
      setPos((current) => current.filter((po) => po !== row["id"]));
      //eliminar información del json para mostrar en el alert
      setReportInfo((current) => current.filter((po) => po.id !== row["id"]));
      setTotalHours(prev => prev - row["hours"]);
    }
    else {
      //agregar el PO id de la reportHours del array de aprobacion
      setPos(current => [...current, row["id"]]);
      //agregar información del json para mostrar en el alert
      setReportInfo(reportInfo => [...reportInfo, row])
      setTotalHours(prev => prev + row["hours"]);
    }
  };
  //#endregion

  //#region funcion para aprobar un registro de tiempos
  const approvedHours = (e, row, dropDown, admin) => {
    console.log(pos);
    if (pos.includes(row["id"])) {
      Toast.fire({
        title: "Atención",
        html: "Deseleccione primero el checkBox de su aprobación másiva",
        type: "danger",
      });
      return;
    }
    const approvedSwal = MySwal.fire({
      title: `Desea aprobar las ${row["hours"]} horas de la Purchase Order ${row["purchaseOrderItem"]} del día ${row["Cdate"]}?`,
      // text: "Se puede reversar con ayuda de Application Management",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, aprobar.",
      confirmButtonColor: "#2dce89",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          row: row,
          action: "add",
          admin: admin
        };
        dispatch(reportHoursActions({ info: info })).then((resp) => {
          const { payload } = resp;
          console.log(resp)
          if (payload.status === 200) {
            Toast.fire({
              title: "Atención",
              html: "Se aprobó con éxito",
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.response.data.message,
              type: "warning",
            });
          }
          setRealTable((prevCheck) => !prevCheck);

          //Error no se puede quitar la PO del state de pos por lo que se obta por limpiarlo todo
          setPos((current) => []);

          setReportInfo(initialValue);
          setTotalHours(0);

          setCheckboxState({});
          setLoad(false);
        });
      }
    });
  };
  //#endregion

  //#region funcion para devolver un registro de tiempos
  const returnHours = (e, row, dropDown, admin) => {
    MySwal.fire({
      title: `Devolver la(s) ${row["hours"]} horas de la PO: ${row["purchaseOrderItem"]} del día ${row["Cdate"]}`,
      html: '<textarea class="form-control" rows="3" id="returnReason" placeholder="Ingrese el mensaje de la devolución para el proveedor..."></textarea>',
      confirmButtonText: "Si, devolver.",
      confirmButtonColor: "#f5365c",
      cancelButtonText: "No, cancelar",
      showCancelButton: true,
      preConfirm: () => {
        setLoad(true);
        if (document.getElementById("returnReason").value === "") {
          MySwal.showValidationMessage("la Razón es obligatoria");
          setLoad(false);
        } else {
          var info = {
            row: row,
            action: "ret",
            admin: admin,
            returnReason: Buffer.from(
              document.getElementById("returnReason").value.toString()
            ).toString("base64"),
          };
          dispatch(reportHoursActions({ info: info })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {
              Toast.fire({
                title: "Atención",
                html: "Se devolvió con éxito",
                type: "success",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.response.data.message,
                type: "warning",
              });
            }

            setRealTable((prevCheck) => !prevCheck);
            setPos((current) => []);
            setReportInfo(initialValue);
            setTotalHours(0);
            setCheckboxState({});
            setLoad(false);
          });
        }
      },
    });
  };
  //#endregion

  //#region funcion para rechazar un registro de tiempos
  const rejecteHours = (e, row, dropDown, admin) => {
    //crear el json para el dropdown del sweeralert

    let selectHtml = '<select id="rejectedReason" class="form-control"><option value=""></option>';

    dropDown["cancelationReasons"].forEach(function (json) {
      selectHtml =
        selectHtml +
        `<option value="${json["value"]}">${json["label"]}</option>`;
    });
    selectHtml = selectHtml + "</select><br>";

    MySwal.fire({
      title: `Rechazar la(s) ${row["hours"]} horas de la PO: ${row["purchaseOrderItem"]} del día ${row["Cdate"]}`,
      html:
        selectHtml +
        '<textarea class="form-control" rows="3" id="returnReason" placeholder="Ingrese el mensaje del rechazo para el proveedor..."></textarea>',
      confirmButtonText: "Si, rechazar.",
      confirmButtonColor: "#f5365c",
      cancelButtonText: "No, cancelar",
      showCancelButton: true,
      preConfirm: () => {
        setLoad(true);
        if (
          document.getElementById("returnReason").value === "" ||
          document.getElementById("rejectedReason").value === ""
        ) {
          MySwal.showValidationMessage("la Razón es obligatoria");
          setLoad(false);
        } else {
          var info = {
            row: row,
            action: "rej",
            admin: admin,
            returnReason: Buffer.from(
              document.getElementById("returnReason").value.toString()
            ).toString("base64"),
            rejectedReasonId: document.getElementById("rejectedReason").value,
            rejectedReason:
              document.getElementById("rejectedReason").options[
                document.getElementById("rejectedReason").selectedIndex
              ].innerHTML,
          };
          dispatch(reportHoursActions({ info: info })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {
              Toast.fire({
                title: "Atención",
                html: "Se rechazó con éxito",
                type: "success",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.response.data.message,
                type: "warning",
              });
            }
            setRealTable((prevCheck) => !prevCheck);
            setPos((current) => []);
            setReportInfo(initialValue);
            setTotalHours(0);
            setCheckboxState({});
            setLoad(false);
          });
        }
      },
    });
  };
  //#endregion


  //#region botones de la tabla MainTable por cada fila de la tabla que dependen del status de la solicitud
  const actions = [
    {
      color: "success",
      icon: "ni ni-check-bold",
      fun: approvedHours,
      add: 3,
      title: "Aprobar Horas",
      id: "approveHours",
    },
    {
      color: "warning",
      icon: "ni ni-bold-left",
      fun: returnHours,
      add: 3,
      title: "Devolver Horas",
      id: "returnHours",
    },
    {
      color: "danger",
      icon: "ni ni-fat-remove",
      fun: rejecteHours,
      add: 3,
      title: "Rechazar Horas",
      id: "rejectedHours",
    },
    {
      color: "success",
      icon: "ni ni-check-bold",
      fun: approvedHours,
      add: true, //status aprobacion L0
      title: "Aprobar Horas",
      id: "approveHours",
    },
    {
      color: "warning",
      icon: "ni ni-bold-left",
      fun: returnHours,
      add: true, //status aprobacion L0
      title: "Devolver Horas",
      id: "returnHours",
    },
    {
      color: "danger",
      icon: "ni ni-fat-remove",
      fun: rejecteHours,
      add: true, //status aprobacion L0
      title: "Rechazar Horas",
      id: "rejectedHours",
    },
    {
      color: "warning",
      icon: "fas fa-sync-alt",
      fun: reprocessHours,
      add: 4, //status error
      title: "Reprocesar Registro",
      id: "reprocessHours",
    },
    {
      color: "warning",
      icon: "ni ni-settings",
      fun: changeStatusHours,
      add: "admin", //status admin
      title: "Cambiar Estado Registro",
      id: "changeStatusHours",
    },
    {
      color: "danger",
      icon: "fas fa-trash",
      fun: deleteHours,
      add: 4, //status error
      title: "Eliminar Registro",
      id: "deleteHours",
    },
  ];
  //#endregion

  //#region lista de botones para ver el detalle de cada reporte de la tabla
  const details = [
    {
      color: "primary",
      icon: "fa fa-bars",
      fun: detailHours,
      add: 0,
      title: "Detalle Registro",
      id: "detailHours",
    },
  ];
  //#endregion

  //#region funcion para buscar nuevos registro de tiempos
  const handleSearch = () => {
    setPage(1);
    setRealTable((prevState) => !prevState);
  };
  //#endregion

  //#region state para el reporte de excel
  const [stateExcel, setStateExcel] = useState({
    sizePerPage: 10,
    rows: [],
    rowsError: [],
  });

  const { sizePerPage, rows, rowsError } = stateExcel;

  const handleDownload = () => {

  };

  useEffect(() => {
    handleDownload();
  }, [search]);

  const downloadReport = () => {
    // handleDownload()

  };
  //#endregion

  //#region funcion para aprobar varios registros de tiempos

  const approveMass = () => {
    mass(1);
  }
  const rejectMass = () => {
    mass(0);
  }
  const mass = (type) => {
    console.log(totalHours)
    console.log(reportInfo)
    console.log(pos)
    if (pos.length === 0) {
      MySwal.fire({
        icon: "warning",
        title: "Error...",
        text: `Seleccione la(s) orden(es) a ${type === 1 ? "aprobar" : "rechazar"}`,
      });
      return;
    }
    let htmlPoInfo = poInfoMassCreate(reportInfo, totalHours);
    MySwal.fire({
      title: `Desea ${type === 1 ? "aprobar" : "rechazar"} las horas de las ordenes de compra seleccionadas?`,
      html: htmlPoInfo,
      icon: "warning",
      showCancelButton: true,
      width: 1000,
      confirmButtonText: `${type === 1 ? "Si, aprobar." : "Si, rechazar"}`,
      confirmButtonColor: `${type === 1 ? "#2dce89" : "#f5365c"}`,
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        setLoad(true);
        var info = {
          ids: pos,
        };
        dispatch(approveMassAction({ info: info, type: type, poInfoMass: reportInfo })).then((resp) => {
          const { payload } = resp;

          if (payload.status === 200) {
            Toast.fire({
              title: "Atención",
              html: payload.data.message, //en el be hace una validacion
              type: "success",
            });
          } else {
            Toast.fire({
              title: "Atención",
              html: payload.response.data.message,
              type: "warning",
            });
          }
          setPos((current) => []);

          setReportInfo(initialValue);
          setTotalHours(0);

          setCheckboxState({});

          setRealTable((prevCheck) => !prevCheck);
          setLoad(false);
        });
      }
    });
  };
  //#endregion

  //#region campos del filtro para el componente FilterMain
  const fields = [
    {
      colWidth: "6",
      label: "Área de negocio",
      placeholder: "Seleccione el Área de negocio",
      id: "purcharseOrderAssignation.area",
      options: "billsArea",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select"
    },
    {
      colWidth: "6",
      label: "Orden de Compra",
      placeholder: "Seleccione la Orden de Compra",
      id: "purcharseOrderAssignation.purchaseOrder",
      options: "purchaseOrderAssignation",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Estado",
      placeholder: "Seleccione el Estado",
      id: "hourReportFreelance.status",
      options: "hourReportStatus",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Proveedores",
      placeholder: "Seleccione el Proveedor",
      id: "purcharseOrderAssignation.consultant",
      options: "accessFreelance",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Proyecto",
      placeholder: "Seleccione el Proyecto",
      id: "purcharseOrderAssignation.project",
      options: "assignationProjects",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Responsable",
      placeholder: "Seleccione el responsable",
      id: "purcharseOrderAssignation.responsible",
      options: "Responsables",
      type: "select",
      disabled: false,
      visibleDefault: false,
      elementType: "Select",
    },
    {
      colWidth: "6",
      label: "Fecha Desde",
      placeholder: "Seleccione la fecha",
      id: "toDate",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "StartDate",
    },
    {
      colWidth: "6",
      label: "Fecha Hasta",
      placeholder: "Seleccione la fecha",
      id: "toDate",
      type: "date",
      disabled: false,
      visibleDefault: false,
      elementType: "EndDate",
    },
  ];
  //#endregion

  //#region Botones del FilterMain para ejecutar las funciones anteriores
  const buttons = [
    {
      color: "primary",
      icon: "fas fa-search",
      title: "Buscar Registro Horas",
      function: handleSearch,
      type: "button",
      colWidth: "4",
    },
    {
      color: "info",
      icon: "fa fa-download",
      title: "Descargar Registro Horas",
      docTitle: "Reporte de Tiempos " + new Date().toISOString().slice(0, 10),
      type: "download",
      function: downloadReport,
      colWidth: "4",
    },
    {
      color: "success",
      icon: "fa fa-check",
      title: "Aprobar Selección",
      function: approveMass,
      type: "button",
      colWidth: "4",
    },
    // {
    //   color: "danger",
    //   icon: "fa fa-check",
    //   title: "Rechazar Selección",
    //   function: rejectMass,
    //   colWidth: "3",
    // },
  ];
  //#endregion

  //#region columnas seleccionados de la tabla MainTable
  const [selected, setSelected] = useState([
    {
      value: "purchaseOrderItem",
      label: "Sel",
      type: "checkBox",
      fun: handleSelPos,
    },
    { value: "consultantName", label: "Consultor" },
    { value: "Cdate", label: "Fecha" },
    { value: "purchaseOrderItem", label: "Orden de Compra" },
    { value: "description", label: "Cliente/Descripción" },
    { value: "hours", label: "Horas" },
    { value: "responsible", label: "Responsable" },
    { value: "actions", label: "Detalles", buttons: details },
    {
      value: "statusName",
      label: "Estado",
      type: "color",
      optionList: "hourReportStatus",
      option: "statusType",
      val: "status",
    },
    { value: "actions", label: "Acciones", buttons: actions },
  ]);
  //#endregion

  //#region columnas de la tabla MainTable
  const optionsSelect = [
    {
      value: "purchaseOrderItem",
      label: "Sel",
      type: "checkBox",
      fun: handleSelPos,
      disabled: true,
    },
    { value: "id", label: "Id" },
    { value: "consultantName", label: "Consultor" },
    { value: "Cdate", label: "Fecha" },
    { value: "purchaseOrderItem", label: "Orden de Compra", disabled: true },
    { value: "description", label: "Cliente/Descripción" },
    { value: "hours", label: "Horas" },
    { value: "catsId", label: "Cats" },
    { value: "ticket", label: "Ticket" },
    { value: "detailsDesencrypt", label: "Detalles" },
    { value: "colabReasonDesencrypt", label: "Razón Cancelado" },
    { value: "sapErrorDesencrypt", label: "Error de SAP" },
    { value: "responsible", label: "Responsable" },
    { value: "actions", label: "Detalles", disabled: true, buttons: details },
    {
      value: "statusName",
      label: "Estado",
      type: "color",
      optionList: "hourReportStatus",
      option: "statusType",
      val: "status",
    },
    { value: "projectName", label: "Proyecto" },
    { value: "helpAreaName", label: "Área Apoyo" },
    { value: "areaCode", label: "Área" },
    { value: "createdAt", label: "Creado En", type: "date" },
    { value: "approvedBy", label: "Aprobador L1" },
    { value: "approvedAtTs", label: "Fecha L1" },
    { value: "approvedByL0", label: "Aprobador L0" },
    { value: "approvedAtL0Ts", label: "Fecha L0" },
    { value: "actions", label: "Acciones", disabled: true, buttons: actions },
  ];
  //#endregion

  //#endregion

  return (
    <>
      <DetailsModal
        row={row}
        setModal={setModal}
        showModal={showModal}
        dropDownOptions={dropDownOptions}
      />

      <FiltersMain
        dropDownOptions={dropDownOptions}
        fields={fields}
        buttons={buttons}
        handleOnChangeFilter={handleOnChangeFilter}
        columnTitles={optionsSelect}
        tableParameter={"rt"}
        search={search}
        Toast={Toast}
      />

      <MainTable
        setPage={setPage}
        page={page}
        reloadTable={reloadTable}
        search={search}
        dropDownOptions={dropDownOptions}
        selected={selected}
        setSelected={setSelected}
        optionsSelect={optionsSelect}
        tableParameter={"rt"}
        rolArea={rolArea}
        statusError={"4"}
        isAdmin={isAdmin}
        isL0={isL0}
        checkboxState={checkboxState}
      />
    </>
  );
};
function poInfoMassCreate(rows, sumHours) {
  let htmlPoInfo = `<div  class="card">
                        <div class="card-body">
                            <table class="align-items-center table-flush table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Ordenes Seleccionadas:</th>
                                    <th>Descripcion</th>
                                    <th>Horas</th>
                                    <th>Fecha del registro</th>
                                </tr>
                            </thead>
                            
                            <tfoot>
                                <tr>
                                    <th>Total de Horas: ${sumHours}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            
                            </tfoot>
                            <tbody class="list">`;

  rows.forEach(function (json) {
    htmlPoInfo =
      htmlPoInfo +
      `<tr>
                                    <td>${json["purchaseOrderItem"]}</td>
                                    <td>${json["description"]}</td>
                                    <td>${json["hours"]}</td>
                                    <td>${json["Cdate"]}</td>
                                    </tr>`;
  });

  htmlPoInfo =
    htmlPoInfo +
    `</tbody>
                    <br>
                    </table>
                    </div>
                    </div>`;
  return htmlPoInfo;
}
export default MainReportHours;
