/*
=========================================================
*Hours Report - CATS
=========================================================

*Description.
This is the component of the Form used to create a new CATS Report, all its functions are passed as props from main view
at HoursReport folder, Home.jsx
=========================================================

* Coded by Daniel Chen Mondragón - Application Management GBM

*/

import React from "react";
import PropTypes from "prop-types";
import { FaExclamationCircle } from "react-icons/fa";
import {
  FormGroup,
  Form,
  Input,
  Label,
  Col,
  Row,
  Button,
  Card,
  CardHeader,
  FormFeedback,
} from "reactstrap";

function CATS({
  title,
  description,
  catsTypes,
  onCancelClick,
  onSendCats,
  onChangeInput,
  onSelectedTask,
  errors,
  searchConflictReport,
  showConflicButton,
  reportType,
}) {
  return (
    <Card>
      <CardHeader style={{ marginBottom: "2rem" }}>
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <h3 className="mb-0">{title}</h3>
            <p className="text-sm mb-0">{description}</p>
          </Col>
        </Row>
      </CardHeader>
      <Form>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            {showConflicButton && (
              <div className="text-center">
                <Button
                  color="danger"
                  style={{
                    backgroundColor: "#f2f21e",
                    borderColor: "#f2f21e",
                    borderRadius: "5px",
                    padding: "8px 16px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => searchConflictReport(reportType)}
                >
                  <FaExclamationCircle style={{ marginRight: "8px" }} />
                  <span style={{ color: "white" }}>Ver conflictos</span>
                </Button>
              </div>
            )}
            <FormGroup>
              <Label className="font-weight-bold">Tipo de tarea</Label>
              <Input
                id="select-typeCATS"
                type="select"
                className="basic-multi-select text-dark"
                style={{
                  color: "black",
                  borderColor: errors.taskType ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onSelectedTask(e.target.value);
                }}
                invalid={errors.taskType}
              >
                <option value={""} disabled selected>
                  Selecciona una tarea
                </option>
                {catsTypes.map((task, index) => (
                  <option key={task.TASKTYPE} value={index}>
                    {task.TEXT}
                  </option>
                ))}
              </Input>
              {errors.taskType && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Fecha a reportar</Label>
              <Input
                id="date-input"
                type="date"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.CATSINPDAT ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("CATSINPDAT", e.target.value);
                }}
                invalid={errors.CATSINPDAT}
              />
              {errors.CATSINPDAT && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Hora de inicio</Label>
              <Input
                id="time-input"
                type="time"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.CATSBEGUZ ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("CATSBEGUZ", e.target.value);
                }}
                invalid={errors.CATSBEGUZ}
              />
              {errors.CATSBEGUZ && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Hora de fin</Label>
              <Input
                id="time-input"
                type="time"
                className="form-control"
                style={{
                  color: "black",
                  borderColor: errors.CATSENDUZ ? "red" : "#dee2e6",
                }}
                onChange={(e) => {
                  onChangeInput("CATSENDUZ", e.target.value);
                }}
                invalid={errors.CATSENDUZ}
              />
              {errors.CATSENDUZ && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={10} sm={6}>
            <FormGroup>
              <Label className="font-weight-bold">Descripción (max. 100)</Label>
              <Input
                id="text-input"
                type="textarea"
                className="form-control"
                autoComplete="off"
                maxLength={100}
                resize="none"
                style={{
                  color: "black",
                  borderColor: errors.description ? "red" : "#dee2e6",
                  height: "6rem",
                }}
                onChange={(e) => {
                  onChangeInput("description", e.target.value);
                }}
                invalid={errors.description}
              />
              {errors.description && (
                <FormFeedback style={{ color: "red" }}>
                  Este campo es obligatorio
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xs={10} sm={6} className="text-center">
            <Button
              id="btn-cancel-cats"
              className="btn-icon mt-3 mr-3"
              color="danger"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                width: "115px",
                height: "30px",
                fontSize: "0.75rem",
                marginLeft: "0px",
              }}
              onClick={onCancelClick}
            >
              <span className="btn-inner--text">Cancelar</span>
            </Button>
            <Button
              id="btn-create-cats"
              className="btn-icon mt-3"
              color="success"
              size="sm"
              type="button"
              style={{
                borderRadius: "10px",
                width: "115px",
                height: "30px",
                fontSize: "0.75rem",
                marginLeft: "0px",
              }}
              onClick={onSendCats}
            >
              <span className="btn-inner--text">Enviar CATS</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

CATS.propTypes = {
  catsTypes: PropTypes.arrayOf(
    PropTypes.shape({
      TASKTYPE: PropTypes.string.isRequired,
      TEXT: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onSendCats: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onSelectedTask: PropTypes.func.isRequired,
};

export default CATS;
