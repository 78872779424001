// core antd
import { Divider } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, Form as FormRS, FormGroup, Input, Label, Row } from "reactstrap";

const RequestForm = props => {

  const {
    title,
    subtitle,
    page,
    data,
    initialValues,
    notify,
    onSearch,
    onChangePage,
    onCancel,
    onCreate
  } = props;

  const [flag, setFlag] = useState(false);

  const validate = values => {
    const errors = {};
    if (Object.keys(values).length > 1) {
      if (values.officeHours !== "0" && values.timeChangePart !== "4" && values.timeChangePart !== "0" && flag === false) {
        setFlag(true);
        notify('warning', 'Atención', 'Se detecto que se requieren partes criticas');
      } else if (values.officeHours !== "0" && values.timeChangePart === "4" && flag === true) {
        setFlag(false);
        notify('warning', 'Atención', 'No se requieren partes criticas');
      }
    }
    if (!values.opportunityNumber || values.opportunityNumber === "")
      errors.opportunityNumber = "Debes ingresar el número de oportunidad.";
    if (Object.keys(data).length) {
      if (!values.customer || values.customer === "")
        errors.customer = "Debes ingresar el nombre del cliente.";
      if (!values.requestedExecutive || values.requestedExecutive === "")
        errors.requestedExecutive = "Debes ingresar el nombre del ejecutivo de la solicitud.";
      if (!values.salesRep || values.salesRep === "")
        errors.salesRep = "Debes ingresar el nombre del representante de ventas.";
      if (!values.businessModel || values.businessModel === "0")
        errors.businessModel = "Debes seleccionar un modelo de negocio.";
      if (!values.typeSupport || values.typeSupport === "0")
        errors.typeSupport = "Debes seleccionar un tipo de soporte.";
      // if ((!values.typeSupportCisco || values.typeSupportCisco === "0") && values.typeSupport === "2")
      //   errors.typeSupportCisco = "Debes seleccionar un tipo de soporte de CISCO.";
      if ((!values.operatingSystemType || values.operatingSystemType === "0") && values.typeSupport !== "2")
        errors.operatingSystemType = "Debes seleccionar un tipo de sistema operativo.";
      if (!values.amountOfEquipment || values.amountOfEquipment === "")
        errors.amountOfEquipment = "Debes ingresar la cantidad de equipos.";
      if (values.amountOfEquipment <= 0)
        errors.amountOfEquipment = "La cantidad de equipos debe ser un número positivo.";
      if (!values.officeHours || values.officeHours === "0")
        errors.officeHours = "Debes seleccionar el horario de atención.";
      if (!values.responseTime || values.responseTime === "0")
        errors.responseTime = "Debes seleccionar el tiempo de respuesta.";
      if (!values.timeChangePart || values.timeChangePart === "0")
        errors.timeChangePart = "Debes seleccionar el tiempo para cambio de parte.";
      if (!values.validityService || values.validityService === "0")
        errors.validityService = "Debes seleccionar la vigencia del servicio.";
      if (!values.wayPay || values.wayPay === "0")
        errors.wayPay = "Debes seleccionar la forma de pago del servicio.";
      if (page === 2) {
        if (!values.physicalLocation || values.physicalLocation === "0")
          errors.physicalLocation = "Debes seleccionar la ubicación fisica de los equipos.";
        if ((!values.equipmentServiceCenterIn || values.equipmentServiceCenterIn === "0") && values.physicalLocation !== "2")
          errors.equipmentServiceCenterIn = "Debes seleccionar la ubicación fisica de los equipos.";
        if ((!values.amountOfEquipmentIn || values.amountOfEquipmentIn === "") && values.physicalLocation !== "2")
          errors.amountOfEquipmentIn = "Debes ingresar la cantidad de equipos.";
        if ((!values.equipmentServiceCenterOut || values.equipmentServiceCenterOut === "0") && values.physicalLocation !== "1")
          errors.equipmentServiceCenterOut = "Debes seleccionar la ubicación fisica de los equipos.";
        if ((!values.amountOfEquipmentOut || values.amountOfEquipmentOut === "") && values.physicalLocation !== "1")
          errors.amountOfEquipmentOut = "Debes ingresar la cantidad de equipos.";
        if ((!values.localtionNotes || values.localtionNotes === "") && values.physicalLocation !== "1")
          errors.localtionNotes = "Debes ingresar las notas de las ubicaciones de los equipos.";
        if (!values.amountMaintenance || values.amountMaintenance === "")
          errors.amountMaintenance = "Debes seleccionar la cantidad de mantenimientos.";
        if (!values.scheduleMaintenance || values.scheduleMaintenance === "")
          errors.scheduleMaintenance = "Debes seleccionar el horario del mantenimiento.";
        if (parseInt(values.amountOfEquipment) < (parseInt(values.physicalLocation !== "2" ? values.amountOfEquipmentIn : 0) + parseInt(values.physicalLocation !== "1" ? values.amountOfEquipmentOut : 0))) {
          errors.amountOfEquipmentIn = "Sobrepaso la cantidad de equipos ingresada en la primer parte.";
          errors.amountOfEquipmentOut = "Sobrepaso la cantidad de equipos ingresada en la primer parte.";
        }
        if (parseInt(values.amountOfEquipment) > (parseInt(values.physicalLocation !== "2" ? values.amountOfEquipmentIn : 0) + parseInt(values.physicalLocation !== "1" ? values.amountOfEquipmentOut : 0))) {
          errors.amountOfEquipmentIn = "Aun no cumple la cantidad de equipos ingresada en la primer parte.";
          errors.amountOfEquipmentOut = "Aun no cumple la cantidad de equipos ingresada en la primer parte.";
        }
      }
    }
    return errors;
  };

  const renderFormPageOne = values => (
    <>
      <div className="form-row align-items-center">
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="opportunityNumber">Número de Oportunidad</Label>
            <Field name="opportunityNumber">
              {({ input, meta }) => (
                <div>
                  <Input
                    {...input}
                    type="number"
                    className="form-control"
                    placeholder="Ingrese el número de oportunidad"
                    disabled
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  />
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="customer">Nombre del cliente</Label>
            <Field name="customer">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="text"
                    placeholder="Ingrese el nombre del cliente"
                    disabled={initialValues.customer}
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  />
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="requestedExecutive">Gerente de Cuenta</Label>
            <Field name="requestedExecutive">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="text"
                    placeholder="Ingrese el nombre del ejecutivo de la solicitud"
                    disabled={initialValues.requestedExecutive}
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  />
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
      </div>
      <div className="form-row align-items-center">
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="salesRep">Especialista de Servicios</Label>
            <Field name="salesRep">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="text"
                    placeholder="Ingrese el nombre del representante de ventas"
                    disabled={initialValues.salesRep}
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  />
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="businessModel" className="font-weight-bold">Modelo de Negocio</Label>
            <Field name="businessModel">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    className="font-weight-bold"
                    type="select"
                    placeholder="Seleccione el modelo de negocio"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione un tipo de modelo de negocio</option>
                    {
                      data.businessModel.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="typeSupport">Tipo de Soporte</Label>
            <Field name="typeSupport">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="select"
                    placeholder="Seleccione el tipo de soporte"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione un tipo de soporte</option>
                    {
                      data.typeSupport.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
      </div>
      <div className="form-row align-items-center">
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="operatingSystemType">Tipo de Sistema Operativo</Label>
            <Field name="operatingSystemType">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="select"
                    placeholder="Seleccione el tipo de sistema operativo"
                    disabled={values.typeSupport === "2"}
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione un tipo de sistema operativo</option>
                    {
                      data.operatingSystemType.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="amountOfEquipment">Cantidad de Equipos</Label>
            <Field name="amountOfEquipment">
              {({ input, meta }) => (
                <div>
                  <Input
                    {...input}
                    type="number"
                    placeholder="Ingrese la cantidad de equipos"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                    min={1}
                  />
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="officeHours">Horario de Atención</Label>
            <Field name="officeHours">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="select"
                    placeholder="Seleccione el horario de atención"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione el horario de atención</option>
                    {
                      data.officeHours.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
      </div>
      <div className="form-row align-items-center">
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="responseTime">Tiempo de Respuesta</Label>
            <Field name="responseTime">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="select"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione el tiempo de respuesta</option>
                    {
                      data.responseTime.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="8">
          <FormGroup>
            <Label for="applicationNotes">Notas (300)</Label>
            <Field name="applicationNotes">
              {({ input, meta }) => (
                <div>
                  <Input
                    {...input}
                    type="textarea"
                    placeholder="Escriba aquí las notas."
                    maxLength={300}
                    rows="3"
                    resize="none"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  />
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
      </div>
      <div className="form-row align-items-center">
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="timeChangePart">Tiempo para Cambio de Partes</Label>
            <Field name="timeChangePart">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="select"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione el tiempo para cambio de parte</option>
                    {
                      data.timeChangePart.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="validityService">Vigencia del Servicio</Label>
            <Field name="validityService">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="select"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione la vigencia del servicio</option>
                    {
                      data.validityService.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="wayPay">Forma de Pago del Servicio</Label>
            <Field name="wayPay">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="select"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione la forma de pago del servicio</option>
                    {
                      data.wayPay.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
      </div>
      <Divider>Formulario Digital</Divider>
    </>
  )

  const renderFormPageTwo = values => (
    <>
      <div className="form-row align-items-center">
        <>
          <Col className="mb-3" sm="12" md="4">
            <FormGroup>
              <Label for="physicalLocation">Ubicación Fisica de los Equipos</Label>
              <Field name="physicalLocation">
                {({ input, meta }) => (
                  <div >
                    <Input
                      {...input}
                      type="select"
                      valid={!meta.error && meta.modified && meta.touched}
                      invalid={meta.error && meta.touched}
                    >
                      <option key="0" value="0">Seleccione la ubicación fisica de los equipos</option>
                      {
                        data.physicalLocation.map((row, key) => {
                          return (
                            <option key={key} value={row.id}>{row.name}</option>
                          )
                        })
                      }
                    </Input>
                    {
                      meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                    }
                  </div>
                )}
              </Field>
            </FormGroup>
          </Col>
          {
            values.physicalLocation && (values.physicalLocation === "1" || values.physicalLocation === "3") &&
            <>
              <Col className="mb-3" sm="12" md="4">
                <FormGroup>
                  <Label for="amountOfEquipmentIn">Cantidad de equipos dentro del radio</Label>
                  <Field name="amountOfEquipmentIn">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          type="number"
                          placeholder="Ingrese la cantidad de equipos"
                          valid={!meta.error && meta.modified && meta.touched}
                          invalid={meta.error && meta.touched}
                          min={1}
                        />
                        {
                          meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                        }
                      </div>
                    )}
                  </Field>
                </FormGroup>
              </Col>
              <Col className="mb-3" sm="12" md="4">
                <FormGroup>
                  <Label for="equipmentServiceCenterIn">Distancia del Centro de Servicios Autorizado</Label>
                  <Field name="equipmentServiceCenterIn">
                    {({ input, meta }) => (
                      <div >
                        <Input
                          {...input}
                          type="select"
                          valid={!meta.error && meta.modified && meta.touched}
                          invalid={meta.error && meta.touched}
                        >
                          <option key="0" value="0">Seleccione la distancia del centro de servicios autorizado</option>
                          {
                            data.equipmentServiceCenter.map((row, key) => {
                              if (row.type === 'IN') {
                                return (
                                  <option key={key} value={row.id}>{row.name}</option>
                                )
                              }
                              return null;
                            })
                          }
                        </Input>
                        {
                          meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                        }
                      </div>
                    )}
                  </Field>
                </FormGroup>
              </Col>
            </>
          }
        </>
      </div>
      {
        values.physicalLocation && (values.physicalLocation === "2" || values.physicalLocation === "3") &&
        <div className="form-row align-items-center">
          <Col className="mb-3" sm="12" md="4">
            <FormGroup>
              <Label for="amountOfEquipmentOut">Cantidad de equipos fuera del radio</Label>
              <Field name="amountOfEquipmentOut">
                {({ input, meta }) => (
                  <div>
                    <Input
                      {...input}
                      type="number"
                      placeholder="Ingrese la cantidad de equipos"
                      valid={!meta.error && meta.modified && meta.touched}
                      invalid={meta.error && meta.touched}
                      min={1}
                    />
                    {
                      meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                    }
                  </div>
                )}
              </Field>
            </FormGroup>
          </Col>
          <Col className="mb-3" sm="12" md="4">
            <FormGroup>
              <Label for="equipmentServiceCenterOut">Distancia del Centro de Servicios Autorizado</Label>
              <Field name="equipmentServiceCenterOut">
                {({ input, meta }) => (
                  <div >
                    <Input
                      {...input}
                      type="select"
                      valid={!meta.error && meta.modified && meta.touched}
                      invalid={meta.error && meta.touched}
                    >
                      <option key="0" value="0">Seleccione la distancia del centro de servicios autorizado</option>
                      {
                        data.equipmentServiceCenter.map((row, key) => {
                          if (row.type === 'OUT') {
                            return (
                              <option key={key} value={row.id}>{row.name}</option>
                            )
                          }
                          return null;
                        })
                      }
                    </Input>
                    {
                      meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                    }
                  </div>
                )}
              </Field>
            </FormGroup>
          </Col>
          <Col className="mb-3" sm="12" md="4">
            <FormGroup>
              <Label for="localtionNotes">Notas (300)</Label>
              <Field name="localtionNotes">
                {({ input, meta }) => (
                  <div>
                    <Input
                      {...input}
                      type="textarea"
                      placeholder="Escriba aquí las notas."
                      maxLength={300}
                      rows="3"
                      resize="none"
                      valid={!meta.error && meta.modified && meta.touched}
                      invalid={meta.error && meta.touched}
                    />
                    {
                      meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                    }
                  </div>
                )}
              </Field>
            </FormGroup>
          </Col>
        </div>
      }
      <DropdownItem divider />
      <div className="form-row align-items-center">
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="amountMaintenance">Cantidad de Mantenimientos Preventivos</Label>
            <Field name="amountMaintenance">
              {({ input, meta }) => (
                <div>
                  {
                    values.officeHours === "2" && values.timeChangePart === "1" ?
                      <Input
                        {...input}
                        type="select"
                        value={"2"}
                        disabled
                        valid={!meta.error && meta.modified && meta.touched}
                        invalid={meta.error && meta.touched}
                      >
                        <option key="0" value="0">Seleccione la cantidad de mantenimientos</option>
                        {
                          data.amountMaintenance.map((row, key) => {
                            return (
                              <option key={key} value={row.id}>{row.name}</option>
                            )
                          })
                        }
                      </Input>
                      :
                      values.officeHours === "2" && values.timeChangePart === "4" ?
                        <Input
                          {...input}
                          type="select"
                          value={"1"}
                          disabled
                          valid={!meta.error && meta.modified && meta.touched}
                          invalid={meta.error && meta.touched}
                        >
                          <option key="0" value="0">Seleccione la cantidad de mantenimientos</option>
                          {
                            data.amountMaintenance.map((row, key) => {
                              return (
                                <option key={key} value={row.id}>{row.name}</option>
                              )
                            })
                          }
                        </Input>
                        :
                        <Input
                          {...input}
                          type="select"
                          valid={!meta.error && meta.modified && meta.touched}
                          invalid={meta.error && meta.touched}
                        >
                          <option key="0" value="0">Seleccione la cantidad de mantenimientos</option>
                          {
                            data.amountMaintenance.map((row, key) => {
                              return (
                                <option key={key} value={row.id}>{row.name}</option>
                              )
                            })
                          }
                        </Input>
                  }
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col className="mb-3" sm="12" md="4">
          <FormGroup>
            <Label for="scheduleMaintenance">Horario del Mantenimiento Preventivo</Label>
            <Field name="scheduleMaintenance">
              {({ input, meta }) => (
                <div >
                  <Input
                    {...input}
                    type="select"
                    valid={!meta.error && meta.modified && meta.touched}
                    invalid={meta.error && meta.touched}
                  >
                    <option key="0" value="0">Seleccione horario del mantenimiento preventivo</option>
                    {
                      data.scheduleMaintenance.map((row, key) => {
                        return (
                          <option key={key} value={row.id}>{row.name}</option>
                        )
                      })
                    }
                  </Input>
                  {
                    meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                  }
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
      </div>
      <Divider>Formulario Digital</Divider>
    </>
  )

  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h6 className="surtitle">{title}</h6>
                <h5 className="h3 mb-0">{subtitle}</h5>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={!Object.keys(data).length ? onSearch : page === 1 ? () => onChangePage('next') : onCreate}
                  validate={validate}
                  initialValues={initialValues}
                  render={({ handleSubmit, values, submitting, validating, reset }) => (
                    <FormRS onSubmit={handleSubmit}>
                      {
                        !Object.keys(data).length ?
                          <div className="form-row align-items-center">
                            <Col className="mb-3" sm="12" md="4">
                              <FormGroup>
                                <Label for="opportunityNumber">Número de Oportunidad</Label>
                                <Field name="opportunityNumber">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input
                                        {...input}
                                        type="number"
                                        className="form-control"
                                        placeholder="Ingrese el número de oportunidad"
                                        valid={!meta.error && meta.modified && meta.touched}
                                        invalid={meta.error && meta.touched}
                                      />
                                      {
                                        meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                      }
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </div>
                          :
                          page === 1 ?
                            renderFormPageOne(values)
                            : renderFormPageTwo(values)
                      }
                      {
                        !Object.keys(data).length ?
                          <div className="form-row align-items-center">
                            <Col className="mb-3" sm="12" md="3">
                              <Button
                                disabled={validating || submitting}
                                className="btn-icon"
                                color="info"
                                block
                                type="submit"
                              // onClick={onNext}
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-search" />
                                </span>
                                <span className="btn-inner--text">Buscar Información</span>
                              </Button>
                            </Col>
                          </div>
                          :
                          page === 1 &&
                          <div className="form-row align-items-center justify-content-center">
                            <Col className="mb-3" sm="12" md="3" style={{
                              justifyContent: "center",
                              display: "flex",
                              alignContent: "center"
                            }}>
                              <Button
                                className="btn-icon"
                                color="danger"
                                type="button"
                                block
                                onClick={onCancel}
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-window-close" />
                                </span>
                                <span className="btn-inner--text">Cancelar</span>
                              </Button>
                            </Col>
                            <Col className="mb-3" sm="12" md="3" style={{
                              justifyContent: "center",
                              display: "flex",
                              alignContent: "center"
                            }}>
                              <Button
                                disabled={validating}
                                className="btn-icon"
                                color="success"
                                block
                                type="submit"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-arrow-right" />
                                </span>
                                <span className="btn-inner--text">Siguiente</span>
                              </Button>
                            </Col>
                          </div>
                      }
                      {
                        page === 2 &&
                        <div className="form-row align-items-center justify-content-center">
                          <Col className="mb-3" sm="12" md="3" style={{
                            justifyContent: "center",
                            display: "flex",
                            alignContent: "center"
                          }}>
                            <Button
                              className="btn-icon"
                              color="danger"
                              type="button"
                              block
                              onClick={() => onChangePage('back')}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-arrow-left" />
                              </span>
                              <span className="btn-inner--text">Volver</span>
                            </Button>
                          </Col>
                          <Col className="mb-3" sm="12" md="3" style={{
                            justifyContent: "center",
                            display: "flex",
                            alignContent: "center"
                          }}>
                            <Button
                              disabled={validating || submitting}
                              className="btn-icon"
                              color="success"
                              block
                              type="submit"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-save" />
                              </span>
                              <span className="btn-inner--text">Crear Requerimiento</span>
                            </Button>
                          </Col>
                        </div>
                      }
                    </FormRS>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};

RequestForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
};

export default RequestForm;
