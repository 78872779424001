/*!

=========================================================
*MasterData - MaintainView
=========================================================

*Componente para desplegar los diferentes Cards y tablas que tienen los campos de cada formulario
*Imprime un cart por cada fields de cada card en CardsMaintenance.jsx
*y por cada uno de esos card muestra un TabPanel con el componente MaintenanceTable.jsx
=========================================================

* Coded by Diego Meza Castro - Application Management GBM

*/

//#region Imports de librerías externas.

//Imports del Framework de React.
import React, { useState, useEffect, useContext, useRef } from "react";

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de la Librería raíz Reactstrap.
import {
    TabContent,
    CardHeader,
    CardBody,
    TabPane,
    Button,
    Card,
    Col,
    Row,
} from "reactstrap";

//#region Imports de componentes internos.
import { CardMaintenance } from "components/NewMasterData/SharedComponents/CardMaintenance.jsx";
import { MaintenanceTable } from "components/NewMasterData/SharedComponents/MaintenanceTable.jsx";
//#endregion

const MaintainView = ({
    handleOnBack,
    fields,
    title
}) => {

    //estado para verificar que tabla ver
    const [formSelected, setFormSelected] = useState(0);

    return (
        <>
            <Card>
                <CardHeader>
                    <Row>
                        <Col xs="3">
                            <Button
                                color="danger"
                                onClick={() => handleOnBack()}
                                size="sm"
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                Regresar
                            </Button>
                        </Col>
                        <Col xs="6" className="text-center">
                            <h3>
                                Portal de Mantenimiento de los campos {title}
                            </h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        {
                            fields &&
                            fields.map((card, key) => (
                                <Col xs={fields.length > 7 ? "2" : ""}>
                                    <CardMaintenance
                                        title={card.label}
                                        id={key + 1}
                                        formSelected={formSelected}
                                        setFormSelected={setFormSelected}
                                    />
                                </Col>
                            ))
                        }
                    </Row>

                    <Row>
                        <Col xs="12">
                            <TabContent activeTab={formSelected}>
                                <TabPane tabId="0">
                                </TabPane>
                                {
                                    fields &&
                                    fields.map((card, key) => (
                                        <TabPane key={key} tabId={key + 1}>
                                            <Card className="bg-gradient border-0">
                                                <CardHeader>
                                                    <h2>Editar campos del {card.label}{ card?.isFactor == true && " - Factor"}</h2>
                                                </CardHeader>
                                                <CardBody>
                                                    <MaintenanceTable
                                                        title={card.label}
                                                        id={key + 1}
                                                        table={card.table}
                                                        formSelected={formSelected}
                                                        columnsException={card?.columnsException}
                                                        isFactor={card?.isFactor}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    ))
                                }
                            </TabContent>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </>
    )
}

export default MaintainView;