import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findMasterVariablesByMasterTable = createAction(
  constants.GET_MASTER_VARIABLES_BY_MASTER_TABLE,
  (type) =>
    method.get(`${urls.SUPPORT.getMasterVariablesByMasterTable}/${type}`)()
);

export const findAllUserEscalationTss = createAction(
  constants.GET_ALL_USER_ESCALATION_TSS,
  method.get(`${urls.SUPPORT.getAllUserEscalationTss}`)
);

export const createMasterVariablesByMasterTable = createAction(
  constants.CREATE_MASTER_VARIABLE_BY_MASTER_TABLE,
  (body) => method.post(urls.SUPPORT.createMasterVariablesByMasterTable, body)()
);

export const updateMasterVariablesByMasterTable = createAction(
  constants.UPDATE_MASTER_VARIABLE_BY_MASTER_TABLE,
  (body) => method.put(urls.SUPPORT.updateMasterVariablesByMasterTable, body)()
);

export const deactivatedMasterVariablesByMasterTable = createAction(
  constants.DELETE_MASTER_VARIABLE_BY_MASTER_TABLE,
  (id, type) =>
    method.put(
      `${urls.SUPPORT.deactivatedMasterVariablesByMasterTable}/${id}/${type}`
    )()
);

export const createUserEscalationTSS = createAction(
  constants.CREATE_USER_ESCALATION_TSS,
  (body) => method.post(urls.SUPPORT.createUserEscalationTSS, body)()
);

export const deactivatedUserEscalationTss = createAction(
  constants.DELETE_USER_ESCALATION_TSS,
  (id) => method.put(`${urls.SUPPORT.deactivatedUserEscalationTss}/${id}`)()
);
