// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Card, CardHeader, Modal, Table } from "reactstrap";

const TargetsModal = props => {

  const {
    targets,
    showModal,
    toggleModal,
  } = props;

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <Card>
        <CardHeader className="border-0">
          <h3 className="mb-0">Objetivos del colaborador</h3>
        </CardHeader>
        <Table
          className="align-items-center table-flush"
          responsive
        >
          <thead className="thead-light">
            <tr>
              <th>Tipo</th>
              <th>Peso</th>
              <th>Cuota Anual</th>
              <th>Skew</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody className="list">
            {
              targets.map((row, key) => {
                return (
                  <tr key={key}>
                    <td
                      className="budget"
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify"
                      }}
                    >
                      {row.type}
                    </td>
                    <td
                      className="budget"
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify"
                      }}
                    >
                      {row.weight}
                    </td>
                    <td
                      className="budget"
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify"
                      }}
                    >
                      {row.quota}
                    </td>
                    <td
                      className="budget"
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify"
                      }}
                    >
                      {row.skew === 0 ? "No" : "Sí"}
                    </td>
                    <td
                      className="budget"
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify"
                      }}
                    >
                      {row.description}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Card>
    </Modal>
  );
};

TargetsModal.propTypes = {
  targets: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default TargetsModal;