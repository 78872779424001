/*!
=========================================================
*Autopp - CardsRquestsStats
=========================================================

* Componente para desplegar cards y estadisticas importantes en la tabla de 
requests.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/


// React librería
import React, { useState, useContext } from 'react';

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

// Reacstrap librería
import { Card, CardBody, Col, Row, Container, CardTitle, Progress } from "reactstrap";


const CardsRequestsStats = React.memo(({ }) => {

    const {
        rowsRequests,

    } = useContext(UserContext);

    const [cardsReports] = useState(
        [
            {
                id: "completingBAW",
                className: "bg-gradient-primary border-0",
                labelTitle: "Casos completados",
                colorBar:"success"
            },

            {
                id: "returnedBAW",
                className: "bg-gradient-info border-0",
                labelTitle: "Casos devueltos",
                colorBar:"danger"
            },

            {
                id: "pendingBAW",
                className: "bg-gradient-danger border-0",
                labelTitle: "Casos pendientes",
                colorBar:"success"
            },

            {
                id: "rejectedBaw",
                className: "bg-gradient-default border-0",
                labelTitle: "Casos rechazados",
                colorBar:"warning"
            },

        ]
    )
    //#endregion
    return (
        <>

            <Container className="" fluid>
                <Row >
                    {
                        cardsReports.map((field, key) => (
                            <>
                                <Col md="6" xl="3">
                                    <Card className={field.className} key={field.id + key} id={field.id + key + "card"}>
                                        <CardBody >
                                            <Row
                                            >
                                                <div className="col">
                                                    <CardTitle
                                                        id={field.id + "title"}
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0 text-white"
                                                    >
                                                        {field.labelTitle}
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0 text-white">
                                                    {rowsRequests.statsBaw[0]?.[field.id]} /  {rowsRequests.statsBaw[0]?.bawQuantity}
                                                    </span>
                                                    <Progress
                                                        id={field.id + "progress"}
                                                        className="progress-xs mt-3 mb-0"
                                                        max="100"
                                                        value={(rowsRequests.statsBaw[0]?.[field.id]/rowsRequests.statsBaw[0]?.bawQuantity)*100}
                                                        color={field.colorBar}
                                                    />
                                                </div>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>

                            </>))}
                </Row>

                {/*JSON.stringify(rowsRequests?.statsBaw[0]?.bawQuantity)*/}


            </Container>
        </>
    );
});

export default CardsRequestsStats;