import React, { useState, useEffect } from 'react';
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import { Container } from "reactstrap";
import CompleteInverviewMainTable from 'components/ExitInterview/CompleteInterviewMainTable';
import { getDraftInterviewStatusInfo, getMasterData } from "actions/exitInterview"
import { Spin } from 'antd';
import { useDispatch } from "react-redux";

const CompleteInterview = () => {
  const dispatch = useDispatch();

  const [loading, setLoad] = useState(true);
  const [info, setInfo] = useState({});
  const [options, setOptions] = useState({
    goBackGBM: [],
    exitReason: [],
    countriesData: [],
    exitType: [],
    rehirable: [],
    masterData: [],
    countries: [],
    companyType: []
  });
  const [updateInfo, setUpdateInfo] = useState({});

  useEffect(() => {
    const getRowsData = async () => {
      await handleRowInfo();
    };
    getRowsData();
  }, []);

  const handleRowInfo = async () => {
    dispatch(getDraftInterviewStatusInfo()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setInfo(payload.data.payload.data[0]);
        info["date"] = new Date().toISOString().split('T')[0];
      }
    });
    dispatch(getMasterData()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setOptions((prevState) => ({
          ...prevState,
          masterData: payload.data.payload.masterData,
          goBackGBM: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.goBackGBM],
          exitReason: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.exitReason],
          countriesData: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.countriesData],
          exitType: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.exitType],
          rehirable: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.rehirable],
          companyType: [{ label: "---", value: 0 }, ...payload.data.payload.masterData.companyType],
          countries: payload.data.payload.masterData.countries,
        }));
      }
    });
    setLoad(false);
  };
  const handleOnChangeInfo = (constant, e, name) => {
    if (name === "exitType") {
      setUpdateInfo((prevState) => ({
        ...prevState,
        [constant]: e.id
      }));
    } else {
      setUpdateInfo((prevState) => ({
        ...prevState,
        [constant]: e,
      }));
    }

  };
  return (
    <div>
      <AdminHeader name="Home" parentName="Completar Entrevista" />
      <Container className="mt--6" fluid>
        <Spin size="large" spinning={loading}>
          <CompleteInverviewMainTable
            info={info}
            updateInfo={updateInfo}
            option={options}
            handleOnChangeInfo={handleOnChangeInfo} />
        </Spin>
      </Container>
    </div >
  );
};





export default CompleteInterview;