// core actions
import { createMasterVariable, createUserCoe, deactivateMasterVariableByID, findAllEmaUsersActive, findValuesMaintenanceUsers, updateMasterVariableByID, updateUserCoe } from 'actions/coe.jsx';
// antd components
import { Spin } from 'antd';
// core components COE
import EditUserModal from "components/COE/EditUserModal.jsx";
import EmaUsers from "components/COE/EmaUsers.jsx";
import MaintenanceForm from "components/COE/MaintenanceForm.jsx";
import MasterVariableManagement from "components/COE/MasterVariableManagement.jsx";
import MasterVariables from "components/COE/MasterVariables.jsx";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getAllEmaUsersActive, getInitialValuesForm, getValuesFormMaintenance } from 'selectors/coe.jsx';

class UsersMaintenance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: 'Cargando la lista de usuarios EMA',
      loadingModal: false,
      messageModal: null,
      addUser: false,
      masterVariables: false,
      editUserModal: {
        showModal: false,
        options: {
          coes: [],
          equipments: [],
          countries: [],
          specialities: []
        },
        initialValues: {}
      },
      masterTable: {
        value: 0,
        label: "Seleccione la tabla maestra a gestionar"
      },
      variableModal: {
        title: "",
        addOrEdit: null,
        type: null,
        showModal: false,
        options: [],
        initialValues: {},
      },
    };
  };

  componentDidMount() {
    this.findAllUsersActive();
  };

  findAllUsersActive = () => {
    this.props.findAllEmaUsersActive().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        this.props.findValuesMaintenanceUsers();
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnCreateCoeUser = values => {
    this.setState({
      loading: true,
      message: `Creando el usuario`
    });
    this.props.createUserCoe(values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
      }
      this.setState({
        loading: false,
        message: null
      });
    });
  };

  handleOnAddUserEMA = () => {
    this.setState({
      loading: true,
      message: "Cargando los valores requeridos para el formulario"
    });
    this.props.findValuesMaintenanceUsers().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        this.setState({
          addUser: true,
        });
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnMasterVariables = () => {
    this.setState({
      loading: true,
      message: "Cargando los valores requeridos para el formulario"
    });
    this.props.findValuesMaintenanceUsers().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        this.setState({
          masterVariables: true,
        });
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnBackUsersEMA = () => {
    this.setState({
      addUser: false,
    });
  };

  handleOnBackEmaUsers = () => {
    this.setState({
      masterVariables: false,
    });
  };

  handleOnToggleEditModal = () => {
    this.setState({
      editUserModal: {
        showModal: false,
        options: {
          coes: [],
          equipments: [],
          countries: [],
          specialities: []
        },
        initialValues: {}
      }
    });
  };

  handleOnEditUserEMA = row => {
    row.lider = row.coeLider === "NO" ? false : true;
    const { values } = this.props;
    this.setState({
      editUserModal: {
        showModal: true,
        options: values,
        initialValues: row,
      }
    })
  };

  handleOnSaveEditUserEMA = values => {
    this.setState({
      loadingModal: true,
      messageModal: 'Actualizando el usuario'
    });
    this.props.updateUserCoe(values).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.findAllUsersActive();
        this.handleOnToggleEditModal();
      }
      this.setState({
        loadingModal: false,
        messageModal: null
      });
    });
  };

  handleOnChangeOptionsValue = event => {
    this.setState({
      masterTable: event
    });
  };

  handleOnDeleteMasterVariable = (row, type) => {
    this.setState({
      loading: true,
      message: "Eliminando la variable maestra"
    });
    this.props.deactivateMasterVariableByID(row.id, type).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const { data: { payload } } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
        }
      } else {
        const { data: { payload } } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.props.findValuesMaintenanceUsers();
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnToggleAddModal = () => {
    this.setState({
      variableModal: {
        title: "",
        addOrEdit: null,
        type: null,
        showModal: false,
        options: [],
      },
    });
  };

  handleOnAddMasterVariable = type => {
    this.setState({
      variableModal: {
        title: "Creación de variable maestra",
        addOrEdit: 'add',
        type,
        showModal: true,
        options: [
          {
            label: 'Nombre',
            type: 'text',
            key: 'name',
            visible: true,
            required: true,
            options: []
          }
        ],
        initialValues: {}
      }
    });
  };

  handleOnEditMasterVariable = (row, type) => {
    this.setState({
      variableModal: {
        title: "Edición de la variable maestra",
        addOrEdit: 'edit',
        type,
        showModal: true,
        options: [
          {
            label: 'Nombre',
            type: 'text',
            key: 'name',
            visible: true,
            required: true,
            options: []
          }
        ],
        initialValues: row
      }
    });
  }

  handleOnSaveMasterVariableAdded = (values, type, addOrEdit) => {
    if (addOrEdit === "add") {
      this.setState({
        loadingModal: true,
        messageModal: "Creando la variable maestra"
      });
      this.props.createMasterVariable({
        name: values.name,
        type
      }).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findValuesMaintenanceUsers();
          this.handleOnToggleAddModal();
        }
        this.setState({
          loadingModal: false,
          messageModal: null,
        });
      });
    } else if (addOrEdit === "edit") {
      this.setState({
        loadingModal: true,
        messageModal: "Actualizando la variable maestra"
      });
      this.props.updateMasterVariableByID(values.id, {
        name: values.name,
        type
      }).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const { data: { payload } } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
          }
        } else {
          const { data: { payload } } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findValuesMaintenanceUsers();
          this.handleOnToggleAddModal();
        }
        this.setState({
          loadingModal: false,
          messageModal: null,
        });
      });
    }
  };

  render() {

    const {
      name,
      parentName,
      cols,
      values,
      allUsers,
      colsAvaibles,
      initialValues,
    } = this.props;

    const {
      loading,
      message,
      addUser,
      masterTable,
      loadingModal,
      messageModal,
      editUserModal,
      masterVariables,
      variableModal,
    } = this.state;

    return (
      <>
        <EditUserModal
          loading={loadingModal}
          message={messageModal}
          modalOptions={editUserModal}
          toggleModal={this.handleOnToggleEditModal}
          onEditUser={this.handleOnSaveEditUserEMA}
        />
        <MasterVariableManagement
          loading={loadingModal}
          message={messageModal}
          modalOptions={variableModal}
          toggleModal={this.handleOnToggleAddModal}
          onAddFlow={this.handleOnSaveMasterVariableAdded}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            <Row>
              <Col>
                {
                  masterVariables ?
                    <MasterVariables
                      title="Mantenimiento"
                      subtitle="Gestión de datos para las tablas maestras"
                      options={values}
                      optionSelected={masterTable}
                      colsAvaibles={colsAvaibles}
                      onChange={this.handleOnChangeOptionsValue}
                      onBack={this.handleOnBackEmaUsers}
                      onEdit={this.handleOnEditMasterVariable}
                      onDelete={this.handleOnDeleteMasterVariable}
                      onAddVariable={this.handleOnAddMasterVariable}
                    />
                    :
                    addUser ?
                      <MaintenanceForm
                        title="Mantenimiento de Usuarios"
                        subtitle="Agregar nuevos usuarios para el reporte de horas EMA"
                        options={values}
                        initialValues={initialValues}
                        onSubmit={this.handleOnCreateCoeUser}
                        onBack={this.handleOnBackUsersEMA}
                      />
                      :
                      <EmaUsers
                        title="Mantenimiento de Usuarios"
                        subtitle="Lista de usuarios activos para reporte de horas EMA"
                        cols={cols}
                        rows={allUsers}
                        onEdit={this.handleOnEditUserEMA}
                        onAddUser={this.handleOnAddUserEMA}
                        onMasterVariables={this.handleOnMasterVariables}
                      />
                }
              </Col>
            </Row>
          </Spin>
        </Container>
      </>
    );
  }
}

UsersMaintenance.defaultProps = {
  name: "Mantenimiento de Usuarios",
  parentName: "COE",
  values: {
    coes: [],
    equipments: [],
    countries: [],
    specialities: []
  },
  cols: [
    { key: "idUser", name: "ID Colaborador" },
    { key: "username", name: "Nombre Usuario" },
    { key: "name", name: "Nombre Completo" },
    { key: "email", name: "Correo Primario" },
    { key: "email2", name: "Correo Secundario" },
    { key: "coeLider", name: "Líder COE" },
    { key: "coeName", name: "COE" },
    { key: "equipName", name: "Equipo" },
    { key: "espName", name: "Especialidad" },
    { key: "countryName", name: "País" },
    { key: "action", name: "" },
  ],
  allUsers: [],
  initialValues: {},
  colsAvaibles: {
    coes: [
      { name: "#REF", key: "id" },
      { key: "name", name: "COE" },
    ],
    equipments: [
      { name: "#REF", key: "id" },
      { key: "name", name: "Equipo" },
    ],
    countries: [
      { name: "#REF", key: "id" },
      { key: "name", name: "País" },
    ],
    specialities: [
      { name: "#REF", key: "id" },
      { key: "name", name: "Especialidad" },
    ],
  }
};

UsersMaintenance.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  allUsers: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  allUsers: getAllEmaUsersActive(state),
  values: getValuesFormMaintenance(state),
  initialValues: getInitialValuesForm(state),
});

export default withRouter(connect(mapStateToProps, {
  createMasterVariable,
  createUserCoe,
  deactivateMasterVariableByID,
  findAllEmaUsersActive,
  findValuesMaintenanceUsers,
  updateMasterVariableByID,
  updateUserCoe
})(UsersMaintenance));
