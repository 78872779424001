import React,{useEffect, useState} from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row, Badge, CardTitle } from "reactstrap";
import Select from "react-select";
import moment from "moment";

const Flows = ({ flow, flowInfo, goBack }) => {
    const [flowRegister, setFlowRegister] = useState("");
    useEffect(() => {
    let flowFilter = [];
        flowFilter = flow.filter( item => item.status ==="En transito")
        setFlowRegister(flowFilter);
    }, [flow]);
   return (
        <div>
            <Row className="justify-content-end" style={{ marginRight: '2vh' }}>
                <Button
                    color="warning"
                    onClick={() => goBack()}
                >
                    <span className="btn-inner--icon mr-1">
                        <i className="fas fa-arrow-left"></i>
                    </span>Regresar
                </Button>
            </Row>
            {
                <Row className="mt-5">
                    <Col md="12" xl="4">
                        <Card className="bg-gradient-default shadow ">
                            <CardHeader className="bg-transparent">
                                <h3 className="mb-0 text-white">Flujo del rastreo de part number</h3>
                            </CardHeader>
                            <CardBody>
                                <div
                                    className="timeline timeline-one-side"
                                    data-timeline-axis-style="dashed"
                                    data-timeline-content="axis"
                                >
                                    {flow.map((item, key) => (
                                        <div key={key} className="timeline-block">
                                            <span className={`timeline-step badge-${item.color}`}>
                                                <i className={`${item.icon}`}></i>
                                            </span>
                                            <div className="timeline-content">
                                                <span className="text-light font-weight-bold">
                                                    {item.status}
                                                </span>
                                                <div>
                                                    <small className="text-light font-weight-bold">
                                                        <i className="fas fa-clock mr-1" /> {moment.utc(item.updateAt).format("YYYY-MM-DD")}
                                                    </small>
                                                    <div className="mt-3">
                                                        <Badge color={`${item.color}`} pill>
                                                            {item.status}
                                                        </Badge>
                                                        <Badge color={`${item.color}`} pill>
                                                        </Badge>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="12" xl="8">
                        <Row>
                            <Col md="12" xl="6">
                                <Card className="bg-gradient-success">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle className="text-uppercase text-muted mb-0 text-white font-weight-bold">
                                                    Tiempo estimado en producción
                                                </CardTitle>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                    <i className="fas fa-people-carry" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className=" d-flex justify-content-end " style={{ marginTop: "10px", marginRight: "2vh" }}>
                                            <span className="text-white  ">
                                                <i className="fas fa-clock mr-1" /> {!flowInfo.firmShipDate.includes("9999") ?`${moment(flowInfo.firmShipDate).diff(moment(flowInfo.ordenEntryDate), "days")} días` : ""}
                                            </span>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="12" xl="6">
                                <Card className="bg-gradient-info">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle className="text-uppercase text-muted mb-0 text-white font-weight-bold">
                                                    Tiempo estimado transito Miami
                                                </CardTitle>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                    <i className="ni ni-time-alarm" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className=" d-flex justify-content-end " style={{ marginTop: "10px", marginRight: "2vh" }}>
                                            <span className="text-white  ">
                                                <i className="fas fa-clock mr-1" />{flowRegister[0] ? (`${moment(flowInfo.deliveryDate).diff(moment(flowRegister[0].updateAt), "days")} días`) : ""}
                                            </span>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card className="mb-4">
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example3cols1Input"
                                            >
                                                Número de producto:
                                            </label>
                                            <Input
                                                id="example3cols1Input"
                                                type="text"
                                                value={flowInfo.PN}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example3cols2Input"
                                            >
                                                Cantidad Disponible:
                                            </label>
                                            <Input
                                                id="example3cols2Input"
                                                placeholder="One of three cols"
                                                type="text"
                                                value={flowInfo.ordenQuantity}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
};


export default Flows;