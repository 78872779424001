import React from 'react';
import {
    Col,
    FormGroup,
    Input,
    Button,
    Row,
    Form,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import Select from "react-select";

import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const FormTemplate = ({ itemForm, itemInfo, handleOnChangeInfo, onSortEnd, saveButton, closeButton, duplicateButton, nameButton1, nameButton2, nameButton3, saveButtonDisabled, extraOptions }) => {

    return (
        <div>
            <Form>
                <Row>
                    {itemForm.map((item, key) => (
                        <Col xs={item.colWidth} key={key}>
                            {item.type === "select" && (
                                <FormGroup>
                                    <label
                                        style={{ fontSize: "12px" }}
                                        className="form-control-label"
                                    >
                                        {item.label}:
                                    </label>
                                    <Select
                                        key={key}
                                        className="basic-single is-invalid"
                                        type="select"
                                        required={item.required}
                                        isDisabled={item.disabled}
                                        isSearchable
                                        isMulti={item.multi}
                                        options={item.options}
                                        placeholder={item.placeholder}
                                        defaultValue={itemInfo && (item.multi === true ? itemInfo[item.value] : { label: itemInfo[item.value], value: 1 })}
                                        onChange={item.isCustom ? (e) => item.customAction(item.valueInsert, item.multi === true ? e : e.value) : (e) => handleOnChangeInfo(item.valueInsert, item.multi === true ? e : e.value)}
                                    />
                                </FormGroup>
                            )}
                            {item.type === "input" && (
                                <FormGroup>
                                    <label className="form-control-label">
                                        {item.label}:
                                    </label>
                                    <Input
                                        defaultValue={item.type === "date" ? moment.utc(itemInfo[item.value]).format("YYYY-MM-DD")
                                            : itemInfo[item.value]}
                                        key={key}
                                        required={item.required}
                                        className="m-0"
                                        type={item.type}
                                        onChange={(e) => handleOnChangeInfo(item.value, e.target.value)}
                                    />
                                </FormGroup>)}
                            {item.type === "sorter" && (
                                <FormGroup>
                                    <DragDropContext onDragEnd={onSortEnd}>
                                        <label className="form-control-label">
                                            {item.label}:
                                        </label>
                                        {!item.options || item.options.length === 0 ?
                                            (<h4>Sin aprobadores seleccionados</h4>)
                                            :
                                            (<Droppable droppableId="approvers">
                                                {(droppableProvided) => (
                                                    <ul
                                                        {...droppableProvided.droppableProps}
                                                        ref={droppableProvided.innerRef}
                                                    >
                                                        {item.options.map((approver, index) => (
                                                            <Draggable key={approver.value} draggableId={`${approver.value}`} index={index}>
                                                                {(draggableProvided) => (

                                                                    <div
                                                                        ref={draggableProvided.innerRef}
                                                                        {...draggableProvided.draggableProps}
                                                                        {...draggableProvided.dragHandleProps}
                                                                    >
                                                                        <Row className="align-items-center">
                                                                            <Col xs="2">
                                                                                <i className="fa fa-bars" aria-hidden="true"></i>
                                                                            </Col>
                                                                            <Col xs="10" className="mt-1">
                                                                                <h4>{approver.label}</h4>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {droppableProvided.placeholder}
                                                    </ul>
                                                )}
                                            </Droppable>)
                                        }

                                    </DragDropContext>
                                </FormGroup>
                            )}
                        </Col>
                    ))}
                </Row>
                {extraOptions && extraOptions}
                <Row className="d-flex justify-content-between mb-5">
                    <Col xs="12" md="5" className="p-1">
                        <Button
                            className="btn  btn-icon"
                            color="danger"
                            block
                            type="button"
                            onClick={closeButton}
                        >
                            <span className="btn-inner--icon mr-">
                                <i className="fas fa-times"></i>
                            </span>
                            <span className="btn-inner--text">{nameButton1}</span>
                        </Button>
                    </Col>
                    <Col xs="12" md="5" className="p-1">
                        <Button
                            className="btn-icon"
                            color="success"
                            block
                            type="button"
                            disabled={saveButtonDisabled}
                            onClick={saveButton}
                        >
                            <span className="btn-inner--icon mr-">
                                <i className="fas fa-save"></i>
                            </span>
                            <span className="btn-inner--text">
                                {nameButton2}
                            </span>
                        </Button>
                    </Col>
                    {nameButton3 && (
                        <Col xs="12" className="p-1">
                        <Button
                            className="btn  btn-icon"
                            color="info"
                            block
                            type="button"
                            onClick={duplicateButton}
                        >
                            <span className="btn-inner--icon mr-">
                            <i className="fas fa-save"></i>
                            </span>
                            <span className="btn-inner--text">{nameButton3}</span>
                        </Button>
                    </Col>
                    )}
                </Row>
            </Form>
        </div>
    );
};
export default FormTemplate;