import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// react library
import React, { Component } from "react";

import _ from "lodash";
import moment from "moment";

import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Badge,
  Table,
} from "reactstrap";

import ReactDatetime from "react-datetime";

import { allOrders } from "actions/SOActions.jsx";

import { allOrdersSelector } from "selectors/SO.jsx";

class AllServiceOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrdersData: { orders: [] },
      ticket: "",
      os: "",
      status: "",
      date: null,
      user: "",
      country: null,
    };
  }

  componentDidMount() {
    this.props.allOrders().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
    });
  }

  handleOnSelect = () => {};

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnCLick = () => {};

  renderTableData() {
    return this.props.executedTotalsData.total.map((row) => {
      const { key, doc_count } = row;
      return (
        <tr key={key}>
          <td>{key}</td>
          <td>{doc_count}</td>
        </tr>
      );
    });
  }

  handleSelectSO = (item) => {
    this.props.history.push(`${this.props.match.url}/details/${item.id}`);
  };

  handleFilterReset = () => {
    this.setState({
      ticket: "",
      os: "",
      status: "",
      date: null,
      user: "",
    });
  };

  handleFilters(value) {
    // console.log(value.target);
    switch (value.target.id) {
      case "ticket":
        this.setState({ ticket: value.target.value });
        break;
      case "os":
        this.setState({ os: value.target.value });
        break;
      case "status":
        this.setState({ status: value.target.value });
        break;
      case "user":
        this.setState({ user: value.target.value });
        break;
      default:
        this.setState({ date: "todos" });
        break;
    }
  }

  handleDate(date) {
    this.setState({ date: moment(date).format("DD-MM-YYYY") });
  }

  render() {
    const { allOrdersData } = this.props;
    let { ticket, os, status, date, user } = this.state;
    let tableRows = [],
      country = null;

    if (allOrdersData.orders) {
      let filteredRows = allOrdersData.orders;
      if (ticket !== null && ticket !== undefined && ticket !== "") {
        filteredRows = _.filter(filteredRows, { ticket: ticket });
      }

      if (os !== null && os !== undefined && os !== "") {
        filteredRows = _.filter(filteredRows, { os: os });
      }

      if (status !== null && status !== undefined && status !== "") {
        filteredRows = _.filter(filteredRows, { status: status });
      }

      if (date !== null && date !== undefined && date !== "") {
        filteredRows = _.filter(filteredRows, {
          formatedDateOnly: date,
        });
      }

      if (user !== null && user !== undefined && user !== "") {
        filteredRows = _.filter(filteredRows, { user: user });
      }

      filteredRows.forEach((order) => {
        tableRows.push(
          <tr key={order.id}>
            <td>{order.name}</td>
            <td>{order.os}</td>
            <td>{order.ticket}</td>
            <td>
              <Badge color="" className="badge-dot mr-4">
                <i className={order.color} />
                <span className="status">{order.statusName}</span>
              </Badge>
            </td>
            <td>{order.formatedDate}</td>
            <td className="text-right">
              <Button onClick={() => this.handleSelectSO(order)}>
                {" "}
                Ver Detalles
              </Button>
            </td>
          </tr>
        );
      });
      if (country == null) country = allOrdersData.country;
    }
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader
          name="Boleta de Servicio Digital"
          parentName="Listado de Registros"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-12">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h5 className="h3 mb-0">Filtros</h5>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    className="btn-danger"
                    color="default"
                    onClick={this.handleFilterReset}
                    size="sm"
                  >
                    Limpiar
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="ticket">
                      Ticket
                    </label>
                    <Input
                      id="ticket"
                      placeholder="Ticket Atendido"
                      type="text"
                      value={ticket}
                      onChange={(value) => this.handleFilters(value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="os">
                      Orden de Servicio
                    </label>
                    <Input
                      id="os"
                      placeholder="Orden de servicio Relacionada"
                      type="text"
                      value={os}
                      onChange={(value) => this.handleFilters(value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="status">
                      Status
                    </label>
                    <Input
                      id="status"
                      type="select"
                      value={status}
                      onChange={(value) => this.handleFilters(value)}
                    >
                      <option value="">Todos</option>
                      <option value="pending">Pendiente</option>
                      <option value="devices">Equipos</option>
                      <option value="replacements">Repuestos</option>
                      <option value="activities">Actividades</option>
                      <option value="complete">Completado</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="user">
                      Usuario Asignado
                    </label>
                    <Input
                      id="user"
                      placeholder="Usuario de Dominio"
                      type="text"
                      value={user}
                      onChange={(value) => this.handleFilters(value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  {/* <FormGroup>
                    <label className="form-control-label" htmlFor="date">
                      Fecha
                    </label>
                    <Input
                      id="date"
                      type="date"
                      defaultValue={date}
                      onChange={value => this.handleFilters(value)}
                    />
                  </FormGroup> */}
                  <FormGroup>
                    <label className="form-control-label" htmlFor="date">
                      Fecha
                    </label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Seleccione una fecha",
                      }}
                      timeFormat={false}
                      value={date}
                      onChange={(value) => this.handleDate(value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">
                    Resultados{" "}
                    {country && (
                      <span className=" mb-0">
                        | <span className="text-red">{country}</span>
                      </span>
                    )}
                  </h3>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>Usuario</th>
                      <th>OS</th>
                      <th>Ticket</th>
                      <th>Status</th>
                      <th>Fecha</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {tableRows.length > 0 ? (
                      tableRows
                    ) : allOrdersData.engineerError ? (
                      <tr>
                        <td className="justify-content-md-center">
                          Usted no cuenta con los permisos para visualizar esta
                          información.
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="justify-content-md-center">
                          Resultados no encontrados
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

AllServiceOrders.defaultProps = {
  allOrdersData: { orders: [] },
};

const mapStateToProps = (state) => ({
  allOrdersData: allOrdersSelector(state),
});

export default withRouter(
  connect(mapStateToProps, { allOrders })(AllServiceOrders)
);
