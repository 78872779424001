import React, { useContext, useState, useEffect } from "react";
// Componentes de reactstrap
import {
  Container,
  FormGroup,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

import { useDispatch } from "react-redux";
import { Upload, Icon } from "antd";

//Endpoint de masterData
import { deleteFileByTempId } from "actions/masterData";

//Urls
import urls from "api/urls.jsx";

//Importar swal para el toast
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
//import del Json de GeneralStates
import formFields from "../SharedComponents/GeneralStates";

//Para subir archivos
const { Dragger } = Upload;

const AproverDocuments = (props) => {
  const {
    typeForm,
    globalInfo,
    setGlobalInfo,
    setFileListApprover,
    fileListApprover,
    temporalRequestId,
    stillChargingDocuments,
    setStillChargingDocuments
  } = props;

  //Desestructuración del objeto de fileList
  const { fileList } = fileListApprover;

  //Para disparar al api de ss
  const dispatch = useDispatch();

  //#region Notificaciones Toast
  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  //#endregion


  const [fileListUploaded, setFileListUploaded] = useState([]) //State diseñado para agregar los archivos que ya han sido subidos completamente, no los que están en proceso.


  const handleOnFileList = (info) => {
    const { status, name } = info.file;

    const existFileInFileListWithSameName = fileList.filter(itemDoc => itemDoc.name === info.file.name)

    if (existFileInFileListWithSameName.length > 0 && fileListUploaded.includes(name) && status !== "removed") {
      Toast.fire({
        title: "Atención",
        html: "Debe cambiar el nombre del archivo ya que hay uno en la lista con el mismo nombre.",
        type: "warning",
      });
      return;
    }

    //Mientras está cargando por alguna razón se ejecuta varias veces este método, entonces que lo ejecute solo una vez.
    if (stillChargingDocuments.chargingApproversDocs === false) {
      setStillChargingDocuments({ ...stillChargingDocuments, chargingApproversDocs: true }) //Se indica que se está cargando el doc en generalForm
    }


    //console.log(status);
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status },
        },
      } = info;
      if (status === 200) {
        setStillChargingDocuments({ ...stillChargingDocuments, chargingApproversDocs: false })
        setFileListApprover((prevState) => ({
          ...prevState,
          fileList: fileList.filter((row) => row.uid !== uid),
        }));

      } else {
        setStillChargingDocuments({ ...stillChargingDocuments, chargingApproversDocs: false })
        setFileListApprover((prevState) =>
          fileList.filter((row) => row.uid !== uid)
        );

      }
      setFileListUploaded(fileListUploaded.filter(file => file != name))

      dispatch(
        deleteFileByTempId({ temporalRequestId: temporalRequestId, name: name })
      ).then((resp) => {
        const { payload } = resp;
        if (payload.status !== 200) {
          console.log("Error al intentar borrar el archivo:");
          console.log(payload);
          setStillChargingDocuments({ ...stillChargingDocuments, chargingApproversDocs: false })
        }
      });
    } else {
      //Éxito
      const {
        fileList,
        file: { status, name },
      } = info;
      //console.log(info)
      if (status === "error") {
        console.log(`Ocurrió un error cargando el archivo ${name}.`);
        console.log(info);
        setStillChargingDocuments({ ...stillChargingDocuments, chargingApproversDocs: false })
      } else if (status === "done") {
        //console.log("Éxito cargando")
        setStillChargingDocuments({ ...stillChargingDocuments, chargingApproversDocs: false })
        setFileListUploaded([...fileListUploaded, name]) //Se agrega en este state los files que ya han sido subidos completamente.

      }
      setFileListApprover({ fileList: fileList });
    }
  };

  const allowedFileTypes = [
    ".xlsx", ".xls", ".xlsb", ".xlt", ".ppt", ".pptx", ".pdf", ".doc", ".docx",
    ".rar", ".zip", ".cfr", ".csv", ".txt", ".eml", ".jpg", ".png", ".html", ".rtf", ".xml",
    ".css", ".tif", ".ods", ".msg"
  ];

  const uploadProps = {
    name: "file",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    accept: allowedFileTypes.join(","),
    multiple: true,
    action: `${urls.MASTERDATA.uploadFiles}/${temporalRequestId}/${formFields[typeForm].motherTable}/2`,
    beforeUpload: file => {
      const fileType = '.' + file.name.split('.').pop().toLowerCase(); //toma el ultimo valor que es la extension del archivo
      if (!allowedFileTypes.includes(fileType)) {
        console.error(`Solo puedes subir archivos de tipo: ${allowedFileTypes.join(', ')}`);
        return false;
      }
      return true;
    },
    onChange: info => {
      console.log(info)
      // Filtrar la lista de archivos válidos para mantener solo los que aún existen
      const filteredFiles = info.fileList.filter(file => {
        console.log(file)
        if (file.status) {
          return true;
        }
        return false;
      });
      info.fileList = filteredFiles;
      // Actualizar la lista de archivos válidos
      // Llamar a la función handleOnFileList con la lista de archivos válidos
      handleOnFileList(info);
    },
  };

  return (
    <>
      <Card style={{ backgroundColor: "#E8E8E8" }}>
        <Card className="mt-4 mr-4 ml-4">
          <CardBody>
            <Row className="mb-2">
              <Col xs="12" sm="12">
                <h3 class="text-center">
                  Si el aprobador necesita algún tipo de documento, favor
                  adjuntarlo en esta sección.
                </h3>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col xs="12">
                <FormGroup>
                  <div className="form-row align-items-center">
                    <Col className="m-1" sm="12">
                      <Container>
                        <Dragger {...uploadProps} fileList={fileList}>
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-uploading-text">
                            Suelte el archivo aquí o haga clic para cargar.
                          </p>
                          <p className="ant-uploading-hint">
                            Puede seleccionar archivos de diferentes
                            folders/carpetas
                          </p>
                        </Dragger>
                      </Container>
                    </Col>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Card>
    </>
  );
};

export default AproverDocuments;
