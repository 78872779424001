// core actions
import { getUrlCisco, authenticateExternal } from "actions/auth.jsx";
// antd componentes
import { Spin } from "antd";
//core components
import Footer from "components/Shared/Footer/Footer.jsx";
import Header from "components/Shared/Header/Header.jsx";
// helpers
import { verifyToken } from "helpers/verifyToken.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react final form components
import { Form } from "react-final-form";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { getTokenAuth, getIsExternal } from "selectors/adminLayout";


import bgImage from '../../assets/img/login/backgroundLogin.jpg'; // Ajusta la ruta según la ubicación del archivo
import logoImage from '../../assets/img/login/logoBlanco.png'; // Asegúrate de importar la imagen que quieres usar

// reactstrap components
import {
    Button,
    Row,
    Col,
    Form as FormRS,
    FormGroup,
    Input,
    InputGroup,
    Nav, NavItem, NavLink,
    // Spinner

    InputGroupText,
    InputGroupAddon
} from "reactstrap";
// react final form components
import { Field } from "react-final-form";



class LoginDark extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userExternal: null
        };
    }

    isExternal = () => {
        return this.props.location.pathname.includes("sales-cloud")
    }


    redirect = () => {



        if (!this.isExternal()) //=> Normal login.
        {
            this.props.getUrlCisco({ env: "DEV", external: (this.isExternal() ? "1" : "0") }, {}).then((res) => {

                if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                        const {
                            data: { payload },
                        } = res.payload.response;
                        this.notify("danger", "Falló", payload.message);
                    } else {
                        this.notify(
                            "danger",
                            "Falló",
                            "No se logro establecer conexion con el servidor."
                        );
                    }
                } else {
                    const {
                        data: { url },
                    } = res.payload;
                    window.location.href = url;
                }

                this.setState((state) => ({
                    ...state,
                    loading: !state.loading,
                }));
            });
        } else { //=> External login.
          

            if (!this.state.userExternal) { //=> Validation if the field user is empty.
                this.notify(
                    "warning",
                    "Atención",
                    "El campo de usuario no puede ser vacío."
                );
            } else {
                this.setState((state) => ({
                    ...state,
                    loading: !state.loading,
                }));
                this.props.authenticateExternal({ env: "DEV", external: (this.isExternal() ? "1" : "0"), userExternal: this.state.userExternal }, {}).then((res) => {

                    if (res.payload.isAxiosError) {
                        if (res.payload.response) {
                            const {
                                data: { payload },
                            } = res.payload.response;
                            this.notify("danger", "Falló", payload.message);
                        } else {
                            this.notify(
                                "danger",
                                "Falló",
                                "No se logro establecer conexion con el servidor."
                            );
                        }
                    } else {
                        // const {
                        //     data: { url },
                        // } = res.payload;
                        // // window.location.href = url;

                        const {
                            data: { token, avatar },
                          } = res.payload;
                          localStorage.setItem("token", token);
                          localStorage.setItem("avatar", avatar);
                          // this.props.history.push("/admin");
                          if (getIsExternal()) {
                            this.props.history.push("/external");
                          } else {
                            this.props.history.push("/admin");
                          }
                    }

                    // this.setState((state) => ({
                    //     loading: !state.loading,
                    // }));
                });
            }
       
        }


    };

    componentWillMount() {
        const hasToken = verifyToken();
        if (hasToken) {
            this.props.history.push("/admin");
        }

    }

    componentDidMount() {
        document.body.classList.add("bg-default");

        if (this.isExternal()) {
            console.log("Mount")
            // this.redirect()
        } else {

        }
    }
    componentWillUnmount() {
        document.body.classList.remove("bg-default");

    }
    /**
     * Funcion para el manejo de notificaciones parametrizadas
     * @memberof OptionalsPositions
     */
    notify = (type, title, message) => {
        let options = {
            place: "br",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {title}
                    </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        if (Object.keys(this.refs).length)
            if (this.props.history.location.pathname === this.props.location.pathname)
                this.refs.notificationAlert.notificationAlert(options);
    };
    validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = "Este campo es obligatorio";
        }
        if (!values.password) {
            errors.password = "Este campo es obligatorio";
        }
        return errors;
    };
    renderInputComponent = (props) => {
        const { placeholder, input, inputProps, meta } = props;
        return (
            <FormGroup>
                <InputGroup className="input-group-merge input-group-alternative">
                    {inputProps}
                    <Input
                        {...input}
                        placeholder={placeholder}
                        invalid={meta.error && meta.touched}
                    />
                </InputGroup>
                {meta.error && meta.touched && (
                    <span
                        style={{
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#fb6340",
                        }}
                    >
                        {meta.error}
                    </span>
                )}
            </FormGroup>
        );
    };
    handleOnSubmit = async (values) => {
        console.log(values);
        await this.props.authUser(values).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const {
                        data: { payload },
                    } = res.payload.response;
                    this.notify("danger", "Falló", payload.message);
                } else {
                    this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                    );
                }
            } else {
                const { token } = this.props;
                // console.log(token);
                localStorage.setItem("token", token);
                this.props.history.push(`${this.props.match.url}`);
            }
        });
    };

    render() {
        const { loading } = this.state;

        //#region Estilos de la columna de la imagen.
        // Define los estilos de la columna de la imagen.
        const colImageStyle = {
            position: 'relative',
            backgroundImage: `url(${bgImage})`, // Utiliza la imagen importada
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh', // Ajusta la altura según sea necesario
            color: 'white' // Color del texto
        };

        // Estilo para el texto con efecto de difuminado
        const textStyle = {
            fontSize: '1.45rem',
            background: 'linear-gradient(to right, white 85%, rgba(255, 255, 255, 0) 100%)', // Gradiente de blanco a transparente al final
            WebkitBackgroundClip: 'text', // Aplica el gradiente al texto
            WebkitTextFillColor: 'transparent', // Hace el texto transparente para mostrar el gradiente
            color: 'white', // Texto blanco en los navegadores que no soportan WebKit
            display: 'inline', // Asegura que el gradiente se aplique solo al texto
            margin: 0 // Ajusta márgenes si es necesario navegadores que no soportan WebKit
        };
        //#endregion

        //#region Estilos de la columna del login.

        //Estilos de la columna del botón de login.
        const colLoginStyle = {
            backgroundImage: 'linear-gradient(to bottom right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1))',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh', // Ajusta la altura según sea necesario
            color: 'white', // Color del texto
            padding: '20px', // Ajusta el relleno si es necesario
        };

        // Estilo para el contenedor del texto
        const textContainerStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Centra verticalmente
            alignItems: 'center', // Centra horizontalmente
            height: '100%', // Asegura que el contenedor ocupe toda la altura de la columna
            textAlign: 'center' // Centra el texto
        };
        //#endregion

        // Define un spinner personalizado con el color blanco
        const customIcon = (
            <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />
        );

        // Handler to update local state
        const handleOnChangeUser = (user) => {
            this.setState((state) => ({
                ...state,
                userExternal: user,
            }));
        };
        return (
            <>
                <Row>
                    <Col xs="6" className="d-none d-md-block" style={colImageStyle} >
                        <div style={textContainerStyle}>
                            <h6 className="surtitle" style={textStyle}>WE BUILD YOUR DIGITAL FUTURE:</h6>
                            <img src={logoImage} alt="GBM Logo" style={{ width: '130px', height: 'auto', margin: '5px 0', marginTop: '0px' }} />
                        </div>
                    </Col>
                    <Col xs="12" sm="6" className="" style={colLoginStyle} >
                        <div className="rna-wrapper">
                            <NotificationAlert ref="notificationAlert" />
                        </div>

                        <div style={textContainerStyle}>
                            <Row>
                                <Col sm="12">
                                    {<h5 className="h3 mb-4" style={{
                                        fontSize: '1.50rem',
                                        color: 'white',
                                    }} >Bienvenido a Smart & Simple</h5>}

                                </Col>
                                <Col sm="12" className="">
                                    <h6 className="surtitle" style={{
                                        fontSize: '0.7rem',
                                        color: 'white',
                                    }} >
                                        Para realizar su gestión, por favor inicie su sesión
                                    </h6>

                                </Col>
                                <Col sm="12" className="mt-4">
                                    <img src={logoImage} alt="GBM Logo" style={{ width: '160px', height: 'auto', margin: '5px 0', marginTop: '0px' }} />

                                </Col>
                                <Col sm="12">

                                    <Form
                                        onSubmit={this.handleOnSubmit}
                                        validate={this.validate}
                                        render={({
                                            handleSubmit,
                                            values,
                                            submitting,
                                            validating,
                                            valid,
                                        }) => (

                                            <FormRS role="form" onSubmit={handleSubmit}>
                                                <div className="text-center">
                                                    <Spin spinning={false} size="small" style={{ color: 'white' }}>
                                                        {this.isExternal() && (
                                                            <Row className="justify-content-center">
                                                                <Col sm="8" className="d-flex justify-content-center align-items-center" /*style={{ height: '100vh' }}*/>
                                                                    <FormGroup style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <InputGroup style={{ /*width: '300px'*/ }}> {/* Set the width here */}
                                                                            <InputGroupAddon addonType="prepend" className="mr--2">
                                                                                <InputGroupText
                                                                                    style={{
                                                                                        backgroundColor: '#495057', // Dark background for the icon
                                                                                        color: 'white', // Icon color
                                                                                        border: 'none', // Remove default border
                                                                                    }}
                                                                                >
                                                                                    <i className="ni ni-single-02" />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input
                                                                                name="username"
                                                                                placeholder="Ingrese su usuario"
                                                                                disabled={submitting}
                                                                                type="text"
                                                                                onChange={(e) => handleOnChangeUser(e.target.value)}
                                                                                style={{
                                                                                    backgroundColor: '#495057', // Input background
                                                                                    color: 'white', // Text color
                                                                                    border: 'none', // Remove default border
                                                                                    borderRadius: '0.25rem', // Optional: rounded corners
                                                                                    // width: '100%', // Full width of InputGroup
                                                                                }}
                                                                                className="dark-input" // Optional: add a class for further styling
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                        )}
                                                        {!loading ? (
                                                            <Button
                                                                charging={true}
                                                                disabled={loading === true && true}
                                                                className="my-4 btn-info-gbm"
                                                                type="submit"
                                                                onClick={() => this.redirect()}
                                                            >
                                                                Iniciar sesión
                                                            </Button>
                                                        ) : (
                                                            <div style={{ textAlign: 'center' }} className="mt-4">
                                                                <Spin indicator={customIcon} />
                                                            </div>
                                                        )}
                                                    </Spin>
                                                </div>
                                            </FormRS>


                                        )}
                                    />
                                </Col>

                            </Row>
                        </div>
                        <Row style={{
                            alignItems: 'center', // Centra horizontalmente
                        }}>
                            <Col className=" mt--4" sm="12 " style={{
                                display: 'flex',
                                flexDirection: 'column', // Alinea los elementos en una columna
                                justifyContent: 'flex-end', // Alinea los elementos al final (parte inferior)
                                height: '90%', // Ocupa toda la altura de la vista
                                alignItems: 'center', // Centra horizontalmente

                            }}>

                                <Nav className="copyright text-center text-xl-left text-muted">
                                    <NavItem>
                                        <NavLink
                                            target="_blank">
                                            © {new Date().getFullYear()}{" "}
                                            GBM Digital
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                            </Col>
                        </Row>

                    </Col>
                </Row>

            </>
        );
    }
}

LoginDark.defaultProps = {
    token: "",
};

LoginDark.propTypes = {
    token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    token: getTokenAuth(state),
});

export default withRouter(connect(mapStateToProps, { getUrlCisco, authenticateExternal })(LoginDark));

