/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la vista de Baja o Modificacion
=========================================================

* Coded by Sergio Marin  

*/
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardTitle, Label, Col, Row, Input, FormGroup, Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MultiSelect from 'react-select';

import { getEmployeeRequest, getCountryUpdateList } from 'actions/ItRequests';

const UpdateRequest = ({ setData, setShowMenu, setStep, setLoading, countries, freelance }) => {
    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff' };
    const labelStyle = { marginBottom: '0.1rem' };

    //FUNCIONES
    const dispatch = useDispatch();
    const [name, setName] = useState({ firstName: "", lastName: "" });
    const [modal, setModal] = useState(false);
    const [showButtons, setShowButtons] = useState(0);
    const [colabList, setColabList] = useState({});
    let employeeId = "";

    const toggle = () => setModal(!modal);

    //Inicializacion
    useEffect(() => {
        LoadMultiSelectList();
    }, []);

    //cargar la lista del dropdown
    async function LoadMultiSelectList() {
        setLoading(true);
        await dispatch(getCountryUpdateList([countries, freelance])).then((resp) => {
            setLoading(false);
            setColabList(resp.payload.data.request);
        });
    }
    const LoadRequest = () => {
        setData({});
        setName({ firstName: "", lastName: "" });
        if (employeeId != "") {
            setLoading(true);
            dispatch(getEmployeeRequest(employeeId)).then((resp) => {
                const data = resp.payload.data.request
                if (data.isFinished === '0') {
                    toggle();
                }
                else {
                    setData(data);
                    setName({ firstName: data.firstName, lastName: data.lastName });
                    if (data.isFreelance === "1") {
                        setShowButtons(1);
                    } else {
                        setShowButtons(2);
                    }

                }
                setLoading(false);
            });
        }
    }
    const handleModRequest = () => {
        if (name.firstName !== "" && name.firstName !== undefined && name.firstName !== null) {
            setLoading(true);
            setData(prevState => ({ ...prevState, requestState: "Modificacion" }));
            setStep("InitialMod");
            setShowMenu("request");
            setLoading(false);
        }
    }
    const handleFreelanceModRequest = () => {
        if (name.firstName !== "" && name.firstName !== undefined && name.firstName !== null) {
            setLoading(true);
            setData(prevState => ({ ...prevState, requestState: "Modificacion" }));
            setStep("InitialFreelanceMod");
            setShowMenu("request");
            setLoading(false);
        }
    }
    const handleFreelanceTerminateRequest = () => {
        if (name.firstName !== "" && name.firstName !== undefined && name.firstName !== null) {
            setLoading(true);
            setData(prevState => ({ ...prevState, requestState: "Baja" }));
            setStep("InitialFreelanceTermination");
            setShowMenu("request");
            setLoading(false);
        }
    }
    const handleTerminateRequest = () => {
        if (name.firstName !== "" && name.firstName !== undefined && name.firstName !== null) {
            setLoading(true);
            setData(prevState => ({ ...prevState, requestState: "Baja" }));
            setStep("InitialTermination");
            setShowMenu("request");
            setLoading(false);
        }
    }
    const handleMultiChange = (selectedOption) => {
        employeeId = selectedOption.value;
        LoadRequest();
    };

    //RETURN
    return (
        <>
            {(
                <>
                    <Card>
                        <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                            Buscar colaborador
                        </CardTitle>

                        <Row style={{ padding: '0px 10px 0px', marginBottom: "10px" }}>
                            <Col xs="3" >
                                <Label htmlFor="idColab" style={labelStyle}>ID Colaborador</Label>
                                <MultiSelect
                                    id="idColab"
                                    options={colabList}
                                    onChange={(selectedOption) => handleMultiChange(selectedOption)}
                                />

                            </Col>
                        </Row>

                    </Card>
                    <Card>
                        <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                            Información Básica Colaborador
                        </CardTitle>
                        <Row style={{ padding: '0px 10px 0px' }}>
                            <Col xs="6" >
                                <FormGroup style={{ padding: '10px 10px 0px' }}>
                                    <Label for="firstName">Nombre</Label>
                                    <Input
                                        type="text"
                                        id="firstName"
                                        disabled
                                        className="form-control"
                                        value={name.firstName}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6" >
                                <FormGroup style={{ padding: '10px 10px 0px' }}>
                                    <Label for="lastName">Apellido</Label>
                                    <Input
                                        type="text"
                                        id="lastName"
                                        disabled
                                        className="form-control"
                                        value={name.lastName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {showButtons !== 0 &&
                            (showButtons !== 1 ? (
                                < Row style={{ padding: '0px 10px 0px', marginBottom: "10px", marginLeft: "10px" }}>
                                    <Button onClick={() => handleModRequest()}>Modificación</Button>
                                    <Button onClick={() => handleTerminateRequest()}>Baja</Button>
                                </Row>
                            ) : (
                                < Row style={{ padding: '0px 10px 0px', marginBottom: "10px", marginLeft: "10px" }}>
                                    <Button onClick={() => handleFreelanceModRequest()}>Modificación</Button>
                                    <Button onClick={() => handleFreelanceTerminateRequest()}>Baja</Button>
                                </Row>
                            ))}
                    </Card>
                    {/* MODAL */}
                    <Modal isOpen={modal} toggle={toggle} >
                        <ModalHeader toggle={toggle}>Atención</ModalHeader>
                        <ModalBody>
                            Existe una solicitud abierta para este colaborador
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={toggle}>
                                Aceptar
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
            )
            }
        </>
    );
};

export default UpdateRequest;