/*!

=========================================================
*Inbound
=========================================================

*FormGeneral: TasksSpare contiene el state llamado fields, 
el cual tiene los campospara llamar al FormGeneral y 
desplegar el formulario para Ingreso de Mercancía. 
Los dields también tienen funciones con acciones para 
cuando se toca un button. 

*ModalList: En casos específicos donde se necesita abrir 
un modal para seleccionar ots, docs, en base al warehouse, 
se utiliza ese componente reutilizable.

*Props: 
    -Stage: Es un estado, el cual crea etapas en las cuales
    ciertos campos del formulario aparece según la etapa.
    Ejemplo: Etapa 1, aparece los campos 1 y 2. Etapa 2, 
    aparece los campos 3 y 4.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React, { useContext, useState, useEffect } from 'react'

//dispatch hook
import { useDispatch } from 'react-redux';

//#region icons
import { BsChatRightText, BsCheckLg } from "react-icons/bs";

import { FaArrowRight } from "react-icons/fa";

import { GrTransaction } from "react-icons/gr";

import { SiEthiopianairlines } from "react-icons/si";

import { GiPositionMarker } from "react-icons/gi";

import { FaDoorOpen } from "react-icons/fa";

import { UserContext } from '../SharedComponents/UserContext';

import { BiSolidArrowToRight } from "react-icons/bi";

import { MdOutlineNumbers } from "react-icons/md";

import { MdOutlineComputer } from "react-icons/md";

import { IoIosSend } from "react-icons/io";


//#endregion


//Imports de actions de consultas al API.
import {
    //CancelationInbound
    getItemInfoByOt,
    sendRequestOutboundByOt

} from 'actions/wms';

//Componentes reutilizables
import { ModalList } from '../SharedComponents/ModalList';
import { FormGeneral } from '../SharedComponents/FormGeneral';

import { encrypter } from 'helpers/desencrypt.jsx';



export const WarehousesMovements = ({ stage, setStage, selectedComponent, mainView }) => {

    const { modal, setPlant, strictedMaterialValidation, notValidateQuantity } = selectedComponent

    //Renombrar variables
    const idComponent = selectedComponent.id;
    const title = selectedComponent.label;

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isMobile,
        isComputer,
        options,
        notify,
        MySwal,
        warehouse,
        sapPassword
    } = useContext(UserContext);
    //#endregion

    //#region states locales
    const [openModal, setOpenModal] = useState(false)
    const [localSpinner, setLocalSpinner] = useState(false)
    const [buttonSpinner, setButtonSpinner] = useState(false)
    //State para guardar la información.
    const [newInfo, setNewInfo] = useState({})
    //La posición actual en el form.
    const [position, setPosition] = useState(0)
    //Lista de positions para validar al final.
    const [positions, setPositions] = useState([])

    //#enregion 

    //#region efectos
    //Establecer datos iniciales.
    useEffect(() => {

        let movement = fields[idComponent].filter(field => field.movementOptions)
        movement = movement.length > 0 ? movement[0].movementOptions[0] : ""

        setNewInfo({
            plant: options.plants[0].label,
            ot: "",
            po: "",
            document: "",
            reservation: "",
            movement: movement
        })



    }, [idComponent])

    //Efecto excepción para cuando cargue el componente establezca la planta.
    useEffect(() => {
        if (options?.plants && setPlant == true) {
            //  handleOnChange("plant", options.plants[0].label, idComponent)
        }

    }, [options])

    //Efecto excepción para cuando la po sea vacía regrese al stage anterior.
    useEffect(() => {
        if ((idComponent == "po" && newInfo.po == "") ||
            (idComponent == "reservationOutboundAssets" && newInfo.document == "") ||
            (idComponent == "reservationOutboundOthers" && newInfo.reservation == "")
        ) {
            setStage(1)
            delete newInfo.numberSupplier;
            delete newInfo.nameSupplier;
        }
    }, [newInfo])

    //#endregion

    //#region ------------------------funciones-----------------------------------------------

    //Refrescar el componente.
    const handleRefresh = (newInfo) => {
        setStage(1)
        setPositions([])
        setPosition(0)
        setNewInfo({ ...newInfo, po: "", document: "", ot: "", reservation: "" })
    }

    //Extraer la información de la cancelación por OT.
    const getItemInfoByOtE = (newInfo, warehouse) => {
        // setSpinner(true);
        // setLocalSpinner(true)
        setButtonSpinner(true)
        dispatch(getItemInfoByOt({ view: mainView, warehouse: warehouse, orderTransport: newInfo.ot, movement: newInfo.movement })).then((res) => {
            const { payload } = res;
            // setLocalSpinner(false);
            setButtonSpinner(false)


            if (res.payload.isAxiosError) {
                // Fallo
                console.log(payload)

                if (res.payload.response) {
                    const {
                        data: { payload }
                    } = res.payload.response;
                    notify("warning", "Atención", payload.message);

                } else {
                    notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexión con el servidor."
                    );
                }



            } else {
                // Éxito

                let apiInfo = { ...payload.data.payload };

                if (!payload.data.success) {
                    notify("warning", "Alerta", apiInfo.message)
                } else if (!apiInfo.material) {
                    notify("warning", "Alerta", "Existe un problema al extraer la información de la cancelación desde SAP.")
                } else {
                    setNewInfo(Object.assign(newInfo, apiInfo))
                    setStage(2)
                }

            }
        })
    }

    //Ir al stage de cancelación (2) y extraer la información.
    const handleGoItemInfo = (newInfo, positions, isComputer, warehouse) => {
        if (newInfo.ot) {
            getItemInfoByOtE(newInfo, warehouse)
        } else {
            notify("warning", "Atención", "Debe ingresar una OT.")
        }
    }

    //Método para extraer una OT.
    const handleSearchOt = (newInfo, positions, isComputer, warehouse, idComponent) => {
        if (newInfo.ot) {
            // alert("consultando a sap")
            if (idComponent == "cancelation") {
                getItemInfoByOtE(newInfo);
            }
        } else {
            setOpenModal(true)
        }
    }

    //Handle para enviar la petición de salida de mercancía de las que tienen OT.
    const handleSendOutboundByOt = async (newInfo, positions, isComputer) => {

        const incorrectPositions = positions.filter(position => position.pickingMaterial != position.material)

        if (incorrectPositions.length == 0 && validatePicking(newInfo)) {


            MySwal.fire({
                title: `Atención`,
                icon: 'info',
                showCancelButton: true,
                text: "¿Está seguro(a) que desea enviar este documento?",
                // width: 1000,
                confirmButtonText: `Si, confirmar`,
                confirmButtonColor: "#2dce89",
                cancelButtonText: "No, cancelar",
                cancelButtonColor: "#adb5bd",
                buttonsStyling: isComputer,
                customClass: {
                    confirmButton: 'btn btn-sm btn-success', // Botón de confirmación pequeño y verde
                    cancelButton: 'btn btn-sm ' // Botón de cancelación pequeño y rojo
                },
                className: "p-0",
                style: {
                    overflow: 'unset',
                    padding: '0px'
                }
            }).then((result) => {

                if (result.value) {
                    // setLocalSpinner(true)
                    // setspinnerCard(true);
                    setButtonSpinner(true)

                    dispatch(sendRequestOutboundByOt({view: mainView, movement: newInfo.movement, warehouse: warehouse, ot: newInfo.ot, password: encrypter(sapPassword) })).then((resp) => {

                        const { payload } = resp;
                        // setLocalSpinner(false)
                        setButtonSpinner(false);

                        if (resp.payload.isAxiosError) {
                            notify("danger", "Atención", "Ocurrió un error al realizar la confirmación.")

                            console.log(payload)
                        } else {
                            let apiInfo = { ...payload.data.payload };

                            notify("success", "Confirmación éxitosa!", apiInfo.message)

                            //Restablecer.
                            handleRefresh(newInfo);
                        }


                    })
                }
            });

        } else {
            notify("danger", "Alerta", positions.length > 0 ? "Existen posiciones con el picking de material incorrecto, favor validar." : "El picking del material es incorrecto, favor validar.")

        }

    }

    //Cambia el estado newInfo según el campo.
    const handleOnChange = (idField, label, idComponent) => {

        //Excepción para placa.
        if (idField == "plate") {
            if (isNaN(label.toString())) //No es sólo números.
            {
                notify("danger", "Alerta", "Debe digitar únicamente números.")
                return
            }

            if (label.length > 7) {
                notify("danger", "Alerta", "La cantidad de dígitos de la placa no debe ser mayor a 7.")
                return
            }
        }

        let stageMustChangePosition = fields[idComponent].filter(field => field.type == "positionsSelector")

        if (stageMustChangePosition.length > 0 && stageMustChangePosition[0].stage == stage //En este stage debe gestionarse las posiciones.
        ) {
            let currentPosition = positions[position];
            currentPosition = { ...currentPosition, [idField]: label }

            setPositions(

                positions.map((pos, key) => {
                    if (key == position) {
                        return currentPosition
                    }
                    return pos

                })
            )


        }

        setNewInfo({ ...newInfo, [idField]: label })

    }

    //Método para validar si el picking actual está correcto.
    const validatePicking = (newInfo) => {
        return newInfo.pickingMaterial == newInfo.material
    }

    //#endregion    ------------------------fin funciones-----------------------------------------------

    //#region State *clave* los campos de el form y vista, y están clasificados por stages (escenarios), es decir, algunos campos aparecen según el stage.
    const [fields, setFields] = useState(
        {


            //warehousesMovement
            lm05Movement: [
                {
                    id: 'movement',
                    label: "Movimiento",
                    width: "4",
                    type: "input",
                    movementOptions: [311],
                    disabled: true,
                    required: true,
                    icon: GrTransaction,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "OT",
                    width: "4",
                    type: "inputKey",
                    function: handleSearchOt,
                    disabled: false,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [1]
                },
                {
                    id: 'btnOpen',
                    label: "Ingresar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleGoItemInfo,
                    disabled: true,
                    required: true,
                    icon: FaArrowRight,
                    stage: [1]
                },
                {
                    id: 'ot',
                    label: "Número OT",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: SiEthiopianairlines,
                    stage: [2]
                },
                {
                    id: 'origen',
                    label: "Orígen",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: BiSolidArrowToRight,
                    stage: [2]
                },
                {
                    id: 'destination',
                    label: "Destino",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: FaDoorOpen,
                    stage: [2]
                },
                {
                    id: 'position',
                    label: "Posición",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: GiPositionMarker,
                    stage: [2]
                },
                {
                    id: 'material',
                    label: "Material",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineComputer,
                    stage: [2]
                },
                {
                    id: 'description',
                    label: "Descripción",
                    width: "4",
                    type: "textarea",
                    disabled: true,
                    required: true,
                    icon: BsChatRightText,
                    stage: [2]
                },
                {
                    id: 'pickingMaterial',
                    label: "Picking material",
                    width: "4",
                    type: "input",
                    disabled: false,
                    required: true,
                    icon: BsCheckLg,
                    stage: [2]
                },
                {
                    id: 'pendingQuantity',
                    label: "Cantidad esperada",
                    width: "4",
                    type: "input",
                    disabled: true,
                    required: true,
                    icon: MdOutlineNumbers,
                    stage: [2]
                },
                {
                    id: 'btnSendInbound',
                    label: "Enviar",
                    width: "12",
                    type: "button",
                    color: "success",
                    function: handleSendOutboundByOt,
                    disabled: true,
                    required: true,
                    icon: IoIosSend,
                    stage: [2]
                },
            ],

        }
    )
    //#endregion

    //#region consoles a borrar.

    useEffect(() => {
        console.log("New info", newInfo)
    }, [newInfo])

    useEffect(() => {
        console.log("IdComponent", idComponent)
    }, [idComponent])

    useEffect(() => {
        console.log("Positions", positions)
    }, [positions])

    //#endregion


    return (
        <>

            <ModalList
                id={idComponent}
                title={modal.title}
                subtitle={modal.subtitle}
                spinnerText={modal.spinnerText}
                searchText={modal.searchText}
                mainView={mainView}


                openModal={openModal}
                setOpenModal={setOpenModal}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
            // getSupplierByPoE={getSupplierByPoE}

            //Outbound
            // getPositionsByDocE={getPositionsByDocE}

            //Reservation
            // getReservationActiveByDocE={getReservationActiveByDocE}
            // getMovementByReservationE={getMovementByReservationE}

            />
            <FormGeneral
                fields={fields}
                stage={stage}
                isMobile={isMobile}
                title={title}
                position={position}
                setPositions={setPositions}
                buttonSpinner={buttonSpinner}
                localSpinner={localSpinner}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                positions={positions}
                setPosition={setPosition}
                isComputer={isComputer}
                warehouse={warehouse}
                idComponent={idComponent}
                handleOnChange={handleOnChange}
                options={options}
                notValidateQuantity={notValidateQuantity}
                strictedMaterialValidation={strictedMaterialValidation}
            />


        </>
    )
}
