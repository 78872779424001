/*!

=========================================================
*MasterData - Templates
=========================================================

*Componente para desplegar un card por cada templates de la tabla MasterData.MotherTables para modificar la plantilla y su version 
=========================================================

* Coded by Diego Meza Castro - Application Management GBM

*/

//#region Imports de librerías externas.

//Imports del Framework de React.
import React, { useState, useEffect, useContext } from "react";

//Import dispatch consulta al API.
import { useDispatch } from 'react-redux';

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de la Librería raíz Reactstrap.
import {
    CardHeader,
    CardBody,
    Button,
    Card,
    Col,
    Row,
} from "reactstrap";


//#endregion

//Imports de actions de consultas al API.
import {
    getMotherTables
} from 'actions/masterData';

//#region Imports de componentes internos.
import { CardTemplate } from "components/NewMasterData/SharedComponents/CardTemplate.jsx";
//#endregion

const Templates = ({ handleOnBack }) => {

    const {
        loading,
        setLoading,
        Toast,
    } = useContext(UserContext);

    //Función para extraer solicitudes del API.
    const dispatch = useDispatch();

    //#region estado para guardar el json de
    const [formFields, setFormFields] = useState([]);
    //#endregion

    //state refresh
    const [reloadTemplates, setReloadTemplates] = useState(false);

    //#region States Globales
    useEffect(() => {
        setLoading(true)
        dispatch(getMotherTables()).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
                // console.log(payload.data.payload.motherTables)

                setFormFields(payload.data.payload.motherTables);

            } else {
                console.log(payload)
            }
            setLoading(false)
        })

    }, [reloadTemplates])
    //#endregion

    // console.log(formFields)

    return (
        <>
            <Card>
                <CardHeader>
                    <Row>
                        <Col xs="3">
                            <Button
                                color="danger"
                                onClick={() => handleOnBack()}
                                size="sm"
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                Regresar
                            </Button>
                        </Col>
                        <Col xs="6" className="text-center">
                            <h3>
                                Portal de Mantenimiento de las plantillas para solicitudes másivas
                            </h3>
                        </Col>
                    </Row>

                </CardHeader>
                <CardBody>
                    <Row>
                        {
                            formFields &&
                            formFields.map((card) => (
                                card.templateVersion !== '' &&
                                <Col sm="14" md="3">
                                    <CardTemplate
                                        title={card.titulo}
                                        version={card.templateVersion}
                                        image={card.image}
                                        templateName={card.templateName}
                                        motherTable={card.motherTable}
                                        setFormFields={setFormFields}
                                        Toast={Toast}
                                        setReloadTemplates={setReloadTemplates}
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default Templates;