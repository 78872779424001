// core actions
import {
  createCommentaryByRequest,
  deactivateConfigurations,
  findConfigurationsByRequest,
  findJustificationsByRequest,
  findReferencesByRequest,
  updateActivityFlow,
  updateStateRequest,
} from "actions/Sales/digitalRequest.jsx";
import {
  createCommentToJTR,
  createPendingPartsEquipment,
  createSelectedPartsEquipment,
  findCriticalPartsKit,
  findEquipmentsByRequest,
  findEquipmentsPendingParts,
  findEquipmentsSelectedParts,
  findHistoryPartsEquipmentsRequest,
  findPartsEquipmentsRequest,
  findRequestsAssignationUser,
  findSelectedPartsByEquipment,
  updateAssignationUserReturnPlanning,
  updateFrusAndAmountsSelectedParts,
  updateStateAssignationUser,
  updateJTRAssignationUser,
} from "actions/Sales/engineers.jsx";
// core antd
import { message, Spin } from "antd";
// core components Sales
import CardEquipments from "components/Sales/EngineersFlow/CardEquipments.jsx";
import CardRequests from "components/Sales/EngineersFlow/CardRequests.jsx";
import ConfigurationView from "components/Sales/EngineersFlow/ConfigurationView.jsx";
import CriticalPartsKit from "components/Sales/EngineersFlow/CriticalPartsKit.jsx";
import FrusCard from "components/Sales/EngineersFlow/FrusCard.jsx";
import Request from "components/Sales/EngineersFlow/Request.jsx";
import ConfigurationsList from "components/Sales/Request/ConfigurationsList.jsx";
import HistoryParts from "components/Sales/EngineersFlow/HistoryParts.jsx";
import UploadReference from "components/Sales/EngineersFlow/UploadReference.jsx";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getCriticalPartSelected,
  getCriticalPartsKit,
  getEquipmentsIDPartSelected,
  getHistoryPartsEquipmentsRequest,
  getPartsEquipmentsRequest,
  getPendingsPartsEquipmentsRequest,
  getRequirementsEngineers,
  getAllEquipmentsCreated,
  getAllEquipmentsComplete,
} from "selectors/Sales/engineers.jsx";
import {
  getCommentsConfigurationsByRequest,
  getConfigurationsByRequest,
  getEquipmentsCreated,
  getJustificationsByRequest,
  getReferencesByRequest,
} from "selectors/Sales/requests.jsx";
import { getUserProtected } from "selectors/adminLayout";

class EngineersFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      saving: false,
      message: null,
      frusView: false,
      requestSelected: null,
      equipmentSelected: null,
      configurationView: null,
      equipmentsView: false,
      fileList: [],
      fileListRemoved: [],
      commentary: null,
      showModal: false,
      showModalHistory: false,
      partsSelected: {},
      initialValues: {},
      showModalReference: false,
      referenceFilesList: [],
    };
  }

  onMainInitialStateValues = () => {
    this.setState({
      alert: null,
      willMount: true,
      loading: false,
      saving: false,
      message: null,
      frusView: false,
      requestSelected: null,
      equipmentSelected: null,
      configurationView: null,
      equipmentsView: false,
      fileList: [],
      fileListRemoved: [],
      commentary: null,
      showModal: false,
      partsSelected: {},
      initialValues: {},
      showModalReference: false,
      referenceFilesList: [],
    });
  };

  onGetRequestsAssignationUser = () => {
    this.props.findRequestsAssignationUser().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState((state) => ({
        willMount: !state.willMount,
      }));
    });
  };

  componentDidMount() {
    this.onGetRequestsAssignationUser();
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  _getInitialValuesParts = (rows) => {
    const {
      partsSelected,
      equipmentSelected: { id },
    } = this.state;
    const { criticalPartsSelected } = this.props;
    if (!Object.keys(partsSelected).length && rows.length) {
      rows.forEach((element) => {
        partsSelected[element.idCriticalPart] = criticalPartsSelected.some(
          (ele) =>
            ele.idCriticalPart === element.idCriticalPart &&
            ele.idEquipment === id
        )
          ? true
          : false;
      });
      this.setState({
        partsSelected,
      });
    }
    return partsSelected;
  };

  onRedirectRequestJTR = async (comment) => {
    const {
      requestSelected: { id, idAssignment },
    } = this.state;
    this.hideAlert();
    // this.notify("info", "Atención", "Favor dirija el caso a la Junta Técnica Regional para validación.");
    this.setState((state) => ({
      loading: !state.loading,
      message: "Actualizando el estado del requerimiento",
    }));
    await this.props
      .createCommentToJTR(id, { comment, type: 1 })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          await this.props.updateStateRequest(id, 9).then(async (res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              await this.props.updateJTRAssignationUser(idAssignment);
              await this.props.updateActivityFlow(id, {
                description:
                  "Pendiente de validación con la Junta Técnica Regional",
                state: "Pendiente",
                type: "jtr",
              });
              this.onMainInitialStateValues();
              this.onGetRequestsAssignationUser();
            }
          });
        }
      });
  };

  onSaveCriticalPartsSelected = (keys, values) => {
    const { equipmentSelected, requestSelected } = this.state;
    const idPartsSelected = keys.filter((row) => values[row] === true);
    const idPartsDeleted = keys.filter((row) => values[row] === false);
    this.setState((state) => ({
      saving: !state.saving,
      message: "Registrando las partes seleccionadas del kit",
      partsSelected: values,
      alert: null,
    }));
    this.props
      .createSelectedPartsEquipment(requestSelected.id, equipmentSelected.id, {
        partsIds: idPartsSelected,
        partsDeleted: idPartsDeleted,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.setState({
              alert: (
                <ReactBSAlert
                  warning
                  style={{ display: "block", marginTop: "-180px" }}
                  title="Atención"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  confirmBtnBsStyle="warning"
                  confirmBtnText="Entendido"
                  btnSize=""
                >
                  {payload.message}
                </ReactBSAlert>
              ),
            });
          } else {
            this.setState({
              alert: (
                <ReactBSAlert
                  danger
                  style={{ display: "block", marginTop: "-180px" }}
                  title="Atención"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  confirmBtnBsStyle="warning"
                  confirmBtnText="Entendido"
                  btnSize=""
                >
                  No se logro establecer conexion con el servidor.
                </ReactBSAlert>
              ),
            });
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.setState({
            showModal: false,
            partsSelected: {},
          });
          this.props.findEquipmentsSelectedParts(requestSelected.id);
        }
        this.setState((state) => ({
          saving: !state.saving,
          message: null,
        }));
      });
  };

  onAddFrusPartsSelected = () => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Cargando las partes selecionadas a los equipos del requerimiento`,
      alert: null,
    }));
    this.props.findPartsEquipmentsRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const { partsEquipments } = this.props;
        const initialValues = {};
        for (const element of partsEquipments) {
          const { id, fru, isClassify } = element;
          if (fru) {
            initialValues[`fru-${id}`] = fru;
          }
          // initialValues[`isClassify-${id}`] = isClassify === 1 ? true : false;
        }
        this.setState((state) => ({
          frusView: !state.frusView,
          initialValues,
        }));
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  onCompleteProcessFrusAmounts = (values) => {
    const {
      requestSelected: { id, idAssignment },
    } = this.state;
    const {
      equipmentsComplete,
      allEquipments,
      equipments: { ibm },
      user: { EMAIL },
    } = this.props;
    const equipmentsCompleteLength = [];
    for (const element of ibm) {
      if (!equipmentsCompleteLength.some((row) => row === element.id)) {
        equipmentsCompleteLength.push(element.id);
      }
    }
    for (const element of equipmentsComplete) {
      if (!equipmentsCompleteLength.some((row) => row === element.id)) {
        equipmentsCompleteLength.push(element.id);
      }
    }
    this.setState((state) => ({
      loading: !state.loading,
      message: `Actualizando el proceso de los FRUs y las cantidades`,
      alert: null,
    }));
    this.props
      .updateFrusAndAmountsSelectedParts({
        values,
      })
      .then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          if (equipmentsCompleteLength.length !== allEquipments.length) {
            await this.props.updateStateAssignationUser(idAssignment, 1);
            await this.props.updateActivityFlow(id, {
              description: `FRUs y cantidades ingresadas a las partes seleccionadas por ${
                EMAIL.split("@")[0]
              }`,
              state: "Validación Completada",
              type: "frus",
            });
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            this.onMainInitialStateValues();
            this.onGetRequestsAssignationUser();
          } else {
            await this.props.updateStateRequest(id, 7).then(async (res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState((state) => ({
                  loading: !state.loading,
                  message: null,
                }));
              } else {
                await this.props.updateStateAssignationUser(idAssignment, 1);
                await this.props.updateActivityFlow(id, {
                  description: `FRUs y cantidades ingresadas a las partes seleccionadas por ${
                    EMAIL.split("@")[0]
                  }`,
                  state: "Validación Completada",
                  type: "frus",
                });
                const {
                  data: { payload },
                } = res.payload;
                this.notify("success", "Éxito", payload.message);
                this.onMainInitialStateValues();
                this.onGetRequestsAssignationUser();
              }
            });
          }
        }
      });
  };

  _handleOnOpenRequest = (row) => {
    this.setState({
      requestSelected: row,
    });
  };

  _handleOnBack = (value) => {
    this.setState({
      [value]: null,
    });
  };

  _handleOnToggleViewEquipments = () => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Cargando los equipos agregados al requerimiento seleccionado`,
    }));
    this.props.findEquipmentsByRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.props.findReferencesByRequest(id);
        this.props.findJustificationsByRequest(id);
        this.props.findEquipmentsSelectedParts(id);
        this.props.findEquipmentsPendingParts(id);
        this.setState((state) => ({
          equipmentsView: !state.equipmentsView,
        }));
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  _handleOnToggleViewConfiguration = (value) => {
    if (value === "add") {
      this.setState({
        configurationView: value,
      });
    } else {
      const {
        requestSelected: { id },
      } = this.state;
      this.setState((state) => ({
        loading: !state.loading,
        message: "Cargando las configuraciones del requerimiento seleccionado",
      }));
      this.props.findConfigurationsByRequest(id).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
            configurationView: value,
          }));
        }
      });
    }
  };

  _handleOnChangeValues = (event) => {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  };

  _handleOnChangeValuesFrus = (event) => {
    const { id, value } = event.target;
    const { initialValues } = this.state;
    console.log(id, value);
    if (id.includes("amount") && value > 99) {
      this.notify(
        "warning",
        "Atención",
        "La cantidad a ingresar no puede ser mayor a 2 dígitos"
      );
      // } else if (id.includes("isClassify")) {
      //   initialValues[id] = !initialValues[id];
      //   this.setState({
      //     initialValues,
      //   });
    } else {
      initialValues[id] = value.toUpperCase();
      this.setState({
        initialValues,
      });
    }
  };

  _handleOnFileListUpload = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idConfiguration } = payload;
        this.setState((state) => ({
          fileList: state.fileList.filter((row) => row.uid !== uid),
          fileListRemoved: [...state.fileListRemoved, idConfiguration],
        }));
      } else {
        this.setState((state) => ({
          fileList: state.fileList.filter((row) => row.uid !== uid),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        fileList: fileList,
      });
    }
  };

  _handleOnCompleteConfiguration = async () => {
    const {
      commentary,
      fileList,
      fileListRemoved,
      requestSelected: { id, idAssignment },
    } = this.state;
    if (!fileList.length) {
      this.notify(
        "warning",
        "Atención",
        "Es requerido que se adjunte la configuración para avanzar"
      );
    } else {
      this.setState((state) => ({
        loading: !state.loading,
        message: `Completando el proceso de la configuración`,
      }));
      const errors = [];
      if (commentary) {
        await this.props
          .createCommentaryByRequest(id, {
            commentary,
            hasConfiguration: 1,
          })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                errors.push({ type: "commentary", error: payload.message });
              } else {
                errors.push({
                  type: "commentary",
                  error: "No se logro establecer conexion con el servidor.",
                });
              }
            }
          });
      }
      if (errors.length) {
        this.notify(
          "danger",
          "Error",
          "Se presento un error intentando guardar la información"
        );
        this.setState((state) => ({
          loading: !state.loading,
          message: null,
        }));
      } else {
        if (fileListRemoved.length) {
          await this.props
            .deactivateConfigurations({
              configurationsIds: fileListRemoved,
            })
            .then((res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  errors.push({ type: "references", error: payload.message });
                } else {
                  errors.push({
                    type: "references",
                    error: "No se logro establecer conexion con el servidor.",
                  });
                }
              }
            });
        }
        if (errors.length) {
          this.notify(
            "danger",
            "Error",
            "Se presento un error intentando guardar la información"
          );
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        } else {
          await this.props.updateStateRequest(id, 6).then(async (res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("danger", "Falló", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              this.setState((state) => ({
                loading: !state.loading,
                message: null,
              }));
            } else {
              await this.props.updateStateAssignationUser(idAssignment, 1);
              await this.props.updateActivityFlow(id, {
                description: "Archivos de Configuración Cargados",
                state: "Configuración Cargada",
                type: "configuration",
              });
              await this.props.updateActivityFlow(id, {
                description: "En espera de la asignación de un ingeniero",
                state: "Asignación Pendiente",
                type: "configuration",
              });
              const {
                data: { payload },
              } = res.payload;
              this.notify("success", "Éxito", payload.message);
              this.onMainInitialStateValues();
              this.onGetRequestsAssignationUser();
            }
          });
        }
      }
    }
  };

  _handleOnSelectEquipment = (row) => {
    const { typeModel } = row;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Cargando el kit de partes críticas`,
      partsSelected: {},
    }));
    this.props.findCriticalPartsKit(typeModel).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          equipmentSelected: row,
          showModal: true,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  _handleOnToggleKitModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleOnToggleHistoryParts = () => {
    this.setState({
      showModalHistory: false,
    });
  };

  _handleOnSavePartsByEquipment = (keys, values) => {
    if (!keys.some((row) => values[row] === true)) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-180px" }}
            title="Atención"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="warning"
            confirmBtnText="Entendido"
            btnSize=""
          >
            No se ha seleccionado ninguna parte del kit desplegado.
          </ReactBSAlert>
        ),
      });
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-180px" }}
            title="¿Está seguro de guardar las partes seleccionadas del kit?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.onSaveCriticalPartsSelected(keys, values)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, guardar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        ),
      });
    }
  };

  _handleOnCompletePartsValidation = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-180px" }}
          title="¿Las partes seleccionadas son correctas y suficientes para cumplir con el SLA solicitado?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onAddFrusPartsSelected()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, continuar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  _handleOnCompleteFrus = (frusLength) => {
    const { initialValues } = this.state;
    const {
      requestSelected: { id },
    } = this.state;
    let frus = Object.keys(initialValues).filter((row) => row.includes("fru"));
    let length = Object.keys(initialValues).filter(
      (row) => row.includes("fru") && initialValues[row] !== ""
    );
    if (length.length !== frusLength) {
      this.notify(
        "warning",
        "Atención",
        "Para continuar es necesario ingresar todos los frus"
      );
    } else {
      let values = frus.map((row) => {
        return {
          id: parseInt(row.split("-")[1]),
          fru: initialValues[row],
        };
      });
      values = values.map((row) => {
        row.amount = initialValues[`amount-${row.id}`]
          ? parseInt(initialValues[`amount-${row.id}`])
          : 1;
        return row;
      });
      // values = values.map((row) => {
      //   // console.log(initialValues[`isClassify-${row.id}`]);
      //   row.isClassify = initialValues[`isClassify-${row.id}`];
      //   return row;
      // });
      console.log(values);
      this.props.findEquipmentsByRequest(id);
      // this.props.findEquipmentsSelectedParts(id);
      // this.props.findEquipmentsPendingParts(id);
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-180px" }}
            title="¿Está seguro de completar el proceso?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.onCompleteProcessFrusAmounts(values)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, completar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          />
        ),
      });
    }
  };

  onValidJTREquipment = () => {
    this.setState({
      saving: true,
      message: "Registrando el equipo por validar con la JTR",
      alert: null,
    });
    const { requestSelected, equipmentSelected } = this.state;
    this.props
      .createPendingPartsEquipment(requestSelected.id, equipmentSelected.id, {})
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.setState({
            showModal: false,
          });
          this.props.findEquipmentsPendingParts(requestSelected.id);
          this.props.findEquipmentsSelectedParts(requestSelected.id);
        }
        this.setState((state) => ({
          saving: false,
          message: null,
        }));
      });
  };

  handleOnValidJTREquipment = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-180px" }}
          title="¿Está seguro de que requiere validar las partes del equipo?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.onValidJTREquipment()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, validar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnRedirectJTR = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-180px" }}
          title="¿Está seguro de enviar el requerimiento a la JTR?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.onRedirectRequestJTR(input)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, enviar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese un comentario a la solicitud.
        </ReactBSAlert>
      ),
    });
  };

  handleOnBackListEquipments = () => {
    this.setState({
      frusView: false,
    });
  };

  onRejectDigitalRequest = async (comment) => {
    const {
      requestSelected: { id, idAssignment },
    } = this.state;
    const {
      user: { EMAIL },
    } = this.props;
    if (comment) {
      this.hideAlert();
      this.setState({
        loading: true,
        message: "Rechazando la validación de partes de la oportunidad",
      });
      await this.props
        .createCommentToJTR(id, { comment, type: 2 })
        .then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.setState((state) => ({
              loading: !state.loading,
              message: null,
            }));
          } else {
            await this.props.updateStateRequest(id, 15).then(async (res) => {
              if (res.payload.isAxiosError) {
                if (res.payload.response) {
                  const {
                    data: { payload },
                  } = res.payload.response;
                  this.notify("warning", "Atención", payload.message);
                } else {
                  this.notify(
                    "danger",
                    "Falló",
                    "No se logro establecer conexion con el servidor."
                  );
                }
                this.setState((state) => ({
                  loading: !state.loading,
                  message: null,
                }));
              } else {
                await this.props.updateStateAssignationUser(id, 3);
                await this.props.updateActivityFlow(id, {
                  description: `Rechazo de la validación de partes por el ingeniero ${
                    EMAIL.split("@")[0]
                  }`,
                  state: "Validación Rechazada",
                  type: "reject",
                });
                this.onMainInitialStateValues();
                this.onGetRequestsAssignationUser();
              }
            });
          }
        });
    } else {
      this.notify(
        "warning",
        "Atención",
        "El comentario es requerido para avanzar"
      );
    }
  };

  onRedirectPlanningDigitalRequest = async (comment) => {
    const {
      requestSelected: { id, idAssignment },
    } = this.state;
    const {
      user: { EMAIL },
    } = this.props;
    if (comment) {
      this.hideAlert();
      this.setState({
        loading: true,
        message: "Regresando la oportunidad a la oficina de planificación",
      });
      await this.props
        .updateAssignationUserReturnPlanning(idAssignment)
        .then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            this.setState({
              loading: false,
              message: null,
            });
          } else {
            await this.props.createCommentToJTR(id, { comment, type: 3 });
            this.setState({
              loading: false,
              message: null,
            });
            await this.props.updateActivityFlow(id, {
              description: `Devolución a planificación la validación de partes por el ingeniero ${
                EMAIL.split("@")[0]
              }`,
              state: "Devolución de Validación",
              type: "reject",
            });
            this.onMainInitialStateValues();
            this.onGetRequestsAssignationUser();
          }
        });
    } else {
      this.notify(
        "warning",
        "Atención",
        "El comentario es requerido para avanzar"
      );
    }
  };

  handleOnRejectDigitalRequestValidation = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-180px" }}
          title="¿Está seguro de rechazar el requerimiento?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.onRejectDigitalRequest(input)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, rechazar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese un comentario al rechazo.
        </ReactBSAlert>
      ),
    });
  };

  handleOnRedirectPlanningDigitalRequest = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-180px" }}
          title="¿Está seguro de regresar el requerimiento a planificación?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.onRedirectPlanningDigitalRequest(input)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, devolver"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese un comentario para la oficina de planificación.
        </ReactBSAlert>
      ),
    });
  };

  handleOnPartsHistory = () => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState((state) => ({
      loading: !state.loading,
      message: `Cargando el Histórico de partes selecionadas a los equipos del requerimiento`,
    }));
    this.props.findHistoryPartsEquipmentsRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const {
          data: { payload },
        } = res.payload;
        this.notify("success", "Éxito", payload.message);
        this.setState({
          showModalHistory: true,
        });
      }
      this.setState((state) => ({
        loading: !state.loading,
        message: null,
      }));
    });
  };

  onCreateNewActivityFlow = async (input) => {
    const { requestSelected } = this.state;
    if (input.length > 80) {
      this.notify(
        "warning",
        "Atención",
        "La nota es muy larga, no debe sobrepasar los 80 caracteres"
      );
    } else {
      this.hideAlert();
      this.setState((state) => ({
        loading: !state.loading,
        message: `Creando nota al flujo de actividades`,
      }));
      await this.props
        .updateActivityFlow(requestSelected.id, {
          description: `${input}`,
          state: "Nota Creada",
          type: "engineer",
        })
        .then(async (res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
          }
          this.setState((state) => ({
            loading: !state.loading,
            message: null,
          }));
        });
    }
  };

  handleOnNewActivityFlow = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-180px" }}
          title="Ingrese la nota deseada para el flujo de actividades"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={(input) => this.onCreateNewActivityFlow(input)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, enviar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        >
          Ingrese una nota
        </ReactBSAlert>
      ),
    });
  };

  handleOnToggleModalReference = () => {
    this.setState({
      showModalReference: false,
    });
  };

  handleOnUploadReference = () => {
    this.setState({
      showModalReference: true,
    });
  };

  handleOnFileListReference = async (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: {
          name,
          uid,
          response: { status, payload },
        },
      } = info;
      if (status === 200) {
        const { idRerence } = payload;
        this.setState((state) => ({
          referenceFilesList: state.referenceFilesList.filter(
            (row) => row.uid !== uid
          ),
          referencesRemoved: [...state.referencesRemoved, idRerence],
        }));
      } else {
        this.setState((state) => ({
          referenceFilesList: state.referenceFilesList.filter(
            (row) => row.uid !== uid
          ),
        }));
      }
      message.success(`El archivo ${name} fue quitado exitosamente.`);
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        referenceFilesList: fileList,
      });
    }
  };

  handleOnFinishReference = () => {
    const {
      requestSelected: { id },
    } = this.state;
    this.setState({
      referenceFilesList: [],
      showModalReference: false,
      loading: true,
      message: "Finalizando la carga de referencias",
    });
    this.props.findReferencesByRequest(id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  render() {
    const {
      cols,
      kit,
      name,
      colsFrus,
      colsHistory,
      parentName,
      requests,
      equipments,
      references,
      historyParts,
      configurations,
      justifications,
      partsEquipments,
      commentsConfigurations,
      equipmentsIdsPartPending,
      equipmentsIdsPartSelected,
    } = this.props;

    const {
      alert,
      saving,
      frusView,
      loading,
      message,
      fileList,
      willMount,
      showModal,
      showModalHistory,
      showModalReference,
      initialValues,
      equipmentsView,
      requestSelected,
      configurationView,
      referenceFilesList,
    } = this.state;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {showModalReference && (
          <UploadReference
            title="Cargar Referencia a los Equipos de la OPP"
            showModal={showModalReference}
            idRequest={requestSelected.id}
            referenceFilesList={referenceFilesList}
            toggleModal={this.handleOnToggleModalReference}
            onFileListReference={this.handleOnFileListReference}
            onFinishReference={this.handleOnFinishReference}
          />
        )}
        {showModal && (
          <CriticalPartsKit
            title="Set de Partes Críticas"
            subtitle="Lista de las partes críticas del equipo seleccionado"
            loading={{ saving, message }}
            rows={kit}
            cols={cols}
            showModal={showModal}
            initialValues={this._getInitialValuesParts(kit)}
            onToggle={this._handleOnToggleKitModal}
            onValidJTR={this.handleOnValidJTREquipment}
            onContinue={this._handleOnSavePartsByEquipment}
          />
        )}
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de las oportunidades asignadas" />
                </Col>
              </Row>
            ) : requests.length ? (
              requestSelected ? (
                configurationView === "add" ? (
                  <ConfigurationView
                    title="Configuración"
                    subtitle="Adjuntar los archivos referentes a la configuración"
                    fileList={fileList}
                    idRequest={requestSelected.id}
                    onBack={this._handleOnToggleViewConfiguration}
                    onChange={this._handleOnChangeValues}
                    onComplete={this._handleOnCompleteConfiguration}
                    onFileList={this._handleOnFileListUpload}
                  />
                ) : configurationView === "view" ? (
                  <ConfigurationsList
                    title="Configuraciones"
                    subtitle="Documentos de configuración cargados al requerimiento"
                    configurations={configurations}
                    comments={commentsConfigurations}
                    onBack={this._handleOnToggleViewConfiguration}
                  />
                ) : equipmentsView ? (
                  frusView ? (
                    <FrusCard
                      title="Gestión de FRUs"
                      subtitle="Ingresar los FRUs para las partes críticas seleccionadas por equipos"
                      cols={colsFrus}
                      rows={partsEquipments}
                      initialValues={initialValues}
                      onChange={this._handleOnChangeValuesFrus}
                      onBackView={this.handleOnBackListEquipments}
                      onCompleteProcess={this._handleOnCompleteFrus}
                    />
                  ) : showModalHistory ? (
                    <HistoryParts
                      title="Histórico de Partes"
                      subtitle="A continuación se muestra el Histórico de partes críticas seleccionadas para los equipos"
                      cols={colsHistory}
                      rows={historyParts}
                      showModal={showModalHistory}
                      onToggle={this.handleOnToggleHistoryParts}
                    />
                  ) : (
                    <CardEquipments
                      title="Equipos Cargados"
                      subtitle="Lista de los Equipos Cargados al Requerimiento"
                      state={requestSelected.state}
                      idBusinessModel={requestSelected.idBusinessModel}
                      version={requestSelected.version}
                      equipments={equipments}
                      references={references}
                      justifications={justifications}
                      equipmentsSaved={equipmentsIdsPartSelected}
                      equipmentsPendings={equipmentsIdsPartPending.filter(
                        (row) =>
                          equipments.ibm.some(
                            (ele) => ele.id === row.idEquipment
                          )
                      )}
                      onBack={this._handleOnToggleViewEquipments}
                      onSelectEquipment={this._handleOnSelectEquipment}
                      onRedirectJTR={this.handleOnRedirectJTR}
                      onPartsHistory={this.handleOnPartsHistory}
                      onReject={this.handleOnRejectDigitalRequestValidation}
                      onRedirectPlanning={
                        this.handleOnRedirectPlanningDigitalRequest
                      }
                      onCompleteParts={this._handleOnCompletePartsValidation}
                      uploadReference={this.handleOnUploadReference}
                    />
                  )
                ) : (
                  <Request
                    request={requestSelected}
                    onBack={this._handleOnBack}
                    onViewEquipments={this._handleOnToggleViewEquipments}
                    onConfigurationView={this._handleOnToggleViewConfiguration}
                    onNewActivityFlow={this.handleOnNewActivityFlow}
                  />
                )
              ) : (
                <CardRequests
                  title="Lista de Oportunidades"
                  subtitle="Oportunidades asignadas por planner"
                  rows={requests}
                  onOpen={this._handleOnOpenRequest}
                />
              )
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay oportunidades pendientes."
                    subtitle="Al día de hoy no tienes oportunidades pendientes de asignación de un recurso, en el momento que exista una nueva, aparecerán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

EngineersFlow.defaultProps = {
  name: "Oportunidades Asignadas",
  parentName: "Notificaciones",
  requests: [],
  equipments: {
    ibm: [],
    cisco: [],
  },
  kit: [],
  cols: [
    { key: "typeModel", name: "Modelo" },
    { key: "platform", name: "Plataforma" },
    { key: "family", name: "Familia" },
    { key: "category", name: "Categoría" },
    { key: "description", name: "Descripción" },
    { key: "classify", name: "Clasificación de la Parte" },
    { key: "action", name: "" },
  ],
  colsFrus: [
    // { key: "id", name: "#Ref" },
    { key: "amountEquipments", name: "Cantidad Equipos" },
    { key: "typeModel", name: "Modelo" },
    { key: "platform", name: "Plataforma" },
    { key: "category", name: "Categoría" },
    { key: "description", name: "Descripción" },
    { key: "classify", name: "Clasificación de la Parte" },
    // { key: "isClassify", name: "Es Crítica/Sensitiva" },
    { key: "fru", name: "FRU" },
    { key: "amount", name: "Cantidad" },
  ],
  colsHistory: [
    // { key: "id", name: "#Ref" },
    { key: "amountEquipments", name: "Cantidad Equipos" },
    { key: "version", name: "# Versión" },
    { key: "typeModel", name: "Modelo" },
    { key: "platform", name: "Plataforma" },
    { key: "category", name: "Categoría" },
    { key: "description", name: "Descripción" },
    { key: "fru", name: "FRU" },
    { key: "amount", name: "Cantidad" },
  ],
  configurations: [],
  references: [],
  justifications: [],
  commentsConfigurations: [],
  criticalPartsSelected: [],
  equipmentsIdsPartSelected: [],
  equipmentsIdsPartPending: [],
  partsEquipments: [],
  historyParts: [],
  allEquipments: [],
  equipmentsComplete: [],
  user: {},
};

EngineersFlow.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  requests: PropTypes.array.isRequired,
  equipments: PropTypes.object.isRequired,
  kit: PropTypes.array.isRequired,
  configurations: PropTypes.array.isRequired,
  references: PropTypes.array.isRequired,
  justifications: PropTypes.array.isRequired,
  commentsConfigurations: PropTypes.array.isRequired,
  criticalPartsSelected: PropTypes.array.isRequired,
  equipmentsIdsPartSelected: PropTypes.array.isRequired,
  equipmentsIdsPartPending: PropTypes.array.isRequired,
  partsEquipments: PropTypes.array.isRequired,
  historyParts: PropTypes.array.isRequired,
  allEquipments: PropTypes.array.isRequired,
  equipmentsComplete: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requests: getRequirementsEngineers(state),
  equipments: getEquipmentsCreated(state),
  kit: getCriticalPartsKit(state),
  configurations: getConfigurationsByRequest(state),
  references: getReferencesByRequest(state),
  justifications: getJustificationsByRequest(state),
  commentsConfigurations: getCommentsConfigurationsByRequest(state),
  criticalPartsSelected: getCriticalPartSelected(state),
  equipmentsIdsPartSelected: getEquipmentsIDPartSelected(state),
  equipmentsIdsPartPending: getPendingsPartsEquipmentsRequest(state),
  partsEquipments: getPartsEquipmentsRequest(state),
  historyParts: getHistoryPartsEquipmentsRequest(state),
  allEquipments: getAllEquipmentsCreated(state),
  equipmentsComplete: getAllEquipmentsComplete(state),
  user: getUserProtected(),
});

export default withRouter(
  connect(mapStateToProps, {
    createCommentaryByRequest,
    createSelectedPartsEquipment,
    createPendingPartsEquipment,
    deactivateConfigurations,
    findCriticalPartsKit,
    findConfigurationsByRequest,
    findEquipmentsByRequest,
    findEquipmentsSelectedParts,
    findEquipmentsPendingParts,
    findJustificationsByRequest,
    findPartsEquipmentsRequest,
    findReferencesByRequest,
    findRequestsAssignationUser,
    findSelectedPartsByEquipment,
    findHistoryPartsEquipmentsRequest,
    updateActivityFlow,
    updateFrusAndAmountsSelectedParts,
    updateStateAssignationUser,
    updateJTRAssignationUser,
    updateAssignationUserReturnPlanning,
    updateStateRequest,
    createCommentToJTR,
  })(EngineersFlow)
);
