/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "EmailsBilling" del boton de Mantenimiento en el cual se modifican los correos
 de contadores y en copia y su unidad de negocio
    
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
=========================================================

* Coded by Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState } from "react";
// react-select library for filters
import Select from "react-select";
// reactstrap components
import { Button, Card, CardBody, Col, FormGroup, Row } from "reactstrap";
//usedispatch for fetch
import { useDispatch } from "react-redux";
//acciones para extraer los emails necesarios y hacer su respectivo cambio
import { getFinanceEmails, updateEmailBilling } from "actions/Freelance.jsx";

const EmailsBilling = ({ setLoad, Toast, dropDownOptions }) => {
  
  //#region Funtions

    //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion

  //#region state para guardar el cambio de billing
  const [updateBilling, setUpdateBilling] = useState([]);
//#endregion

    //#region States para guardar los Correos de contadores,en copia y su pais para enviar el cambio
  const [senders, setSenders] = useState([]);
  const [copies, setCopies] = useState([]);
  const [country, setCountry] = useState([]);
//#endregion

//#region Funcion para los states de los correos 
  const handleOnChangeEmail = (e, type, rol) => {
    if (type === "sender") {
      setSenders(e);
    } else {
      setCopies(e);
    }
  };
//#endregion

//#region Funcion que ejecuta el dispatch para hacer la actualizacion de los cambios hechos
  const saveChanges = (e) => {
    dispatch(updateEmailBilling({ senders, copies, country })).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        Toast.fire({
          title: "Atención",
          html: `Email billing modificado con éxito`,
          type: "success",
        });
      } else {
        Toast.fire({
          title: "Atención",
          html: payload.message,
          type: "warning",
        });
      }
      setLoad(false);
    });
  };
//#endregion

//#region Funcion que ejecuta el dispatch para extraer los emails segun la unidad de negocio que se seleccione
  const handleOnChange = (e) => {
    dispatch(getFinanceEmails({ country: e })).then((resp) => {
      const { payload } = resp;
      console.log(e);
      if (payload.status === 200) {
        console.log(payload.data.rows);

        let copyArray = [];
        let sendersArray = [];

        payload.data.rows.map((item, key) => {
          if (item.rolText === "Sender") {
            const sendersJson = {
              label: item.name,
              value: item.accountant,
              rol: item.rol,
            };
            sendersArray.push(sendersJson);
          } else {
            const copyJson = {
              label: item.name,
              value: item.accountant,
              rol: item.rol,
            };
            copyArray.push(copyJson);
          }
        });
        setSenders(sendersArray);
        setCopies(copyArray);
        setCountry(e);
      } else {
        setCopies([]);
        setSenders([]);
        Toast.fire({
          title: "Atención",
          html: payload.message,
          type: "warning",
        });
      }
      setLoad(false);
    });
  };
//#endregion

//#endregion
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col sm="3">
              <FormGroup>
                <label className="form-control-label">Unidad de Negocio</label>
                <Select
                  id="companyCode"
                  placeholder="Seleccione"
                  name="companyCode"
                  className="basic-single text-dark"
                  type="select"
                  isSearchable
                  isDisabled={false}
                  // defaultValue={row.defaultValue}
                  onChange={(e) => handleOnChange(e.value)}
                  options={
                    dropDownOptions["companyCode"] && [
                      { value: "", label: "" },
                      ...dropDownOptions["companyCode"],
                    ]
                  }
                />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <label className="form-control-label">Correos contadores</label>
                
                <Select
                  onlyUnique
                  className="bootstrap-tagsinput"
                  onChange={(e, rol) => handleOnChangeEmail(e, "sender", rol)}
                  value={senders}
                  defaultValue={senders}
                  isMulti={true}
                  tagProps={{ className: "tag badge mr-0" }}
                  inputProps={{
                    className: "form-control",
                    placeholder: "Escriba el email",
                  }}
                  options={
                    dropDownOptions["employees"] && [
                      { value: "", label: "" },
                      ...dropDownOptions["employees"],
                    ]
                  }
                />
              </FormGroup>
            </Col>
            <Col sm="5">
              <FormGroup>
                <label className="form-control-label">Correos en copia</label>
                
                <Select
                  onlyUnique
                  className="bootstrap-tagsinput"
                  onChange={(e, rol) => handleOnChangeEmail(e, "copy", 2)}
                  value={copies}
                  defaultValue={copies}
                  isMulti={true}
                  tagProps={{ className: "tag badge mr-0" }}
                  inputProps={{
                    className: "form-control",
                    placeholder: "Escriba el email de copia",
                  }}
                  options={
                    dropDownOptions["employees"] && [
                      { value: "", label: "" },
                      ...dropDownOptions["employees"],
                    ]
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Button
                className="btn-icon"
                color="success"
                size="sm"
                block
                type="button"
                onClick={(e) => saveChanges(e, updateBilling)}
              >
                <span className="btn-inner--text">Guardar Cambios</span>
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default EmailsBilling;
