
// react library
import React, { useState, useEffect, useRef } from 'react';
import moment from "moment";
//Token 
import { getUsernameLogged } from "selectors/adminLayout";
//usedispatch for fetch
import { useDispatch } from 'react-redux';
// reactstrap components
import {
    Button, Card, CardBody, CardHeader, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Form,
    TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import classnames from 'classnames';
//componentes
import PersonalDataForm from 'components/HCMHiring/PersonalDataForm';
import EducationForm from 'components/HCMHiring/EducationForm';
import ExperienceForm from 'components/HCMHiring/ExperienceForm';

//actions for endpoint
import { updateRequest } from 'actions/hcmHiring.jsx';

const EditHiringModal = ({
    showModal,
    setModal,
    row,
    Toast,
    isAdmin,
    isManager,
    candidatePersonalData,
    candidateEducation,
    candidateExperience,
    filesData,
    dropDownOptions
}) => {
    //#region Funciones


    const userName = getUsernameLogged();

    //state principal
    const [state, setState] = useState({
        unmountOnClose: true,
    });

    const { unmountOnClose } = state;

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //state para determinar en que tab estoy 
    const [activeTab, setActiveTab] = useState('1');

    //funcion que cambia el tab
    const handleTab = (event, tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    //siempre se necesita el documentId y el dropDownOptions.StatusApplication previo para determinar si se ingresa el log
    const iState = {
    }
    const [updateInfo, setUpdateInfo] = useState(iState);
    // console.log(updateInfo);
    //state para editar los campos del modal: false = bloqueado
    const [displayI, setdisplayI] = useState(false);

    //para cancelar o cerrar el modal
    const toggleEditDocumentModal = (() => {

        setActiveTab('1');
        setUpdateInfo(iState);
        setdisplayI(false);
        setModal((prevState) => ({
            ...prevState,
            editRow: {},
            editModal: !prevState.editModal,
        }));

    });

    //funcion para enviar los datos a modificar
    const handleOnChange = (table, constant, value) => {

        setUpdateInfo(prevState => ({
            ...prevState,
            [table]: {
                ...prevState[table],
                [constant]: value
            },
        }));

    }

    //funcion boton guardar
 
    const handleUpdateDocument = () => {
        const info = updateInfo;
        info["id"] = row["id"];
        dispatch(updateRequest({ updateInfo: info })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {


                //actualizar la propiedad Row para que aparezca con los nuevos cambios
                Object.keys(info).forEach(function (key) {
                    row[key] = info[key];
                });

                //cerrar el modal
                toggleEditDocumentModal();

                Toast.fire({
                    title: `Se actualizó correctamente`,
                    type: 'success'
                })

            } else {
                Toast.fire({
                    title: payload.data.message,
                    type: 'warning'
                })
            }
        })

    }


    //#endregion

    return (
        <>
            <Modal size="lg" isOpen={showModal} unmountOnClose={unmountOnClose} toggle={toggleEditDocumentModal}>
                <ModalHeader>
                    Detalles del candidato
                </ModalHeader>
                <ModalBody className='mb-lg-0'>
                    <Form>
                        {/* <div className='nav-wrapper'> */}
                        <Card className='shadow mb-lg-0'>
                            <CardHeader className="border-0">
                                <Nav
                                    className='nav-fill flex-column flex-md-row'
                                    id='tabs-icons-text'
                                    pills
                                    role='tablist'
                                >
                                    <NavItem>
                                        <NavLink
                                            aria-selected={activeTab === '1'}
                                            className={classnames('mb-sm-3 mb-md-0', {
                                                active: activeTab === '1'
                                            })}
                                            onClick={e => handleTab(e, '1')}
                                            role='tab'
                                        >
                                            <i className='ni ni-badge mr-2' />
                                            Datos Personales
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-selected={activeTab === '2'}
                                            className={classnames('mb-sm-3 mb-md-0', {
                                                active: activeTab === '2'
                                            })}
                                            onClick={e => handleTab(e, '2')}
                                            role='tab'
                                        >
                                            <i className='ni ni-badge mr-2' />
                                            Educación
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-selected={activeTab === '3'}
                                            className={classnames('mb-sm-3 mb-md-0', {
                                                active: activeTab === '3'
                                            })}
                                            onClick={e => handleTab(e, '3')}
                                            // href='#AUopp'
                                            role='tab'
                                        >
                                            <i className='ni ni-badge mr-2' />
                                            Experiencia
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId='1'>
                                        <PersonalDataForm
                                            data={candidatePersonalData}
                                            row={row}
                                            handleOnChange={handleOnChange}
                                            setUpdateInfo={setUpdateInfo}
                                            dropDownOptions={dropDownOptions}
                                        />
                                    </TabPane>
                                    <TabPane tabId='2'>
                                        <EducationForm
                                            data={candidateEducation}
                                            filesData={filesData}
                                            row={row}
                                            handleOnChange={handleOnChange}
                                            isAdmin={isAdmin}
                                            isManager={isManager}
                                            Toast={Toast}
                                            dropDownOptions={dropDownOptions}
                                        />
                                    </TabPane>
                                    <TabPane tabId='3'>
                                        <ExperienceForm
                                            data={candidateExperience}
                                            row={row}
                                            handleOnChange={handleOnChange}
                                            dropDownOptions={dropDownOptions}
                                        />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Form>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="danger"
                        type="button"
                        onClick={(e) => toggleEditDocumentModal()}
                    >
                        Cancelar
                    </Button>
                    {
                        updateInfo !== {} ?
                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleUpdateDocument()}
                            >
                                Guardar
                            </Button>
                            :
                            <></>

                    }

                </ModalFooter>
            </Modal>
        </>
    )
};

export default EditHiringModal;