/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Row, Table } from "reactstrap";

const EmaUsers = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    onEdit,
    onAddUser,
    onMasterVariables,
  } = props;

  return (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row className="align-items-center">
          <Col sm="12" md="7">
            <h6 className="surtitle text-white">{title}</h6>
            <h5 className="h3 mb-0 text-white">{subtitle}</h5>
          </Col>
          <Col sm="12" md="5" className="d-flex justify-content-end">
            <Row>
              <Col sm="12" md="6" className="d-flex justify-content-end">
                <Button
                  id="add"
                  className="btn-round btn-icon"
                  color="info"
                  onClick={onAddUser}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-plus" />
                  </span>
                  <span className="btn-inner--text">Agregar Usuario</span>
                </Button>
              </Col>
              <Col sm="12" md="6" className="d-flex justify-content-end">
                <Button
                  id="add"
                  className="btn-round btn-icon"
                  color="success"
                  onClick={onMasterVariables}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-wrench" />
                  </span>
                  <span className="btn-inner--text">Variables Maestras</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Table
        className="align-items-center table-dark table-flush"
        responsive
      >
        <thead className="thead-dark">
          <tr>
            {
              cols.map((row, key) => <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>{row.name}</th>)
            }
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.map((row, key) => (
              <tr key={key} onClick={() => onEdit(row)}>
                {
                  cols.map((col, key) => (
                    col.key === "action" ?
                      <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                        <a
                          className="table-action table-action"
                          href="#"
                          onClick={() => onEdit(row)}
                        >
                          <i className="fas fa-edit" />
                        </a>
                      </th>
                      :
                      <th key={key} style={{ whiteSpace: "normal", textAlign: "center" }}>
                        {row[col.key]}
                      </th>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Card >
  );
};

EmaUsers.propTypes = {
  title: PropTypes.string.isRequired,
};

export default EmaUsers;