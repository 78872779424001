// core antd
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Modal, Row, Table } from "reactstrap";

const CriticalPartsKit = props => {

  const {
    cols,
    rows,
    title,
    subtitle,
    loading,
    showModal,
    initialValues,
    onToggle,
    onValidJTR,
    onContinue,
  } = props;

  const onChange = (event) => {
    const { id } = event.target;
    initialValues[id] = !initialValues[id];
  };

  return (
    <Modal
      size="lg"
      className="modal-dialog-centered"
      isOpen={showModal}
      toggle={onToggle}
    >
      <Spin size="large" spinning={loading.saving} tip={loading.message}>
        <Card className="bg-transparent mb-0">
          <CardHeader className="bg-transparent border-0">
            <Row>
              <Col xs="12" md='7'>
                <h6 className="surtitle">{title}</h6>
                <h5 className="h3 mb-0">{subtitle}</h5>
              </Col>
              <Col className="d-flex align-items-center justify-content-end" xs="12" md="5">
                <Row>
                  <Col className="d-flex align-items-center justify-content-end" xs="6" md="6">
                    <Button
                      className="btn-round btn-icon"
                      color="danger"
                      onClick={onValidJTR}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-exclamation-triangle" />
                      </span>
                      <span className="btn-inner--text">Validar JTR</span>
                    </Button>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end" xs="6" md="6">
                    <Button
                      className="btn-round btn-icon"
                      color="success"
                      onClick={() => onContinue(Object.keys(initialValues), initialValues)}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Guardar</span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                {
                  cols.map((row) => (
                    <th key={row.key}>
                      {row.name}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody className="list">
              {
                rows.map((row, key) => (
                  <tr key={key}>
                    {
                      cols.map((col, key) => (
                        col.key === 'action' ?
                          <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            <label className="custom-toggle">
                              <input
                                id={row.idCriticalPart}
                                type="checkbox"
                                defaultChecked={initialValues[row.idCriticalPart]}
                                onChange={(e) => onChange(e)}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Sí"
                              />
                            </label>
                          </th>
                          :
                          <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                            {row[col.key]}
                          </th>
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Card>
      </Spin>
    </Modal>
  );
};

CriticalPartsKit.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default CriticalPartsKit;
