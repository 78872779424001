import { getAllFastCotyzationReport } from "actions/panamaBids.jsx";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
// react-select library for filters
import Select from "react-select";
import {
  Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row
} from "reactstrap";


const QuickQuoteFilters = ({
  contInput,
  setContInput,
  setrefreshInput,
  dataSelect,
  setRowsModal,
  options,
  Toast,
}) => {
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState([]);
  const [dataSelectFiler, setDataSelectFiler] = useState([]);
  const [conditionFilter, setConditionFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState([]);

  const handleOnPlusInput = () => {
    let amount = contInput;
    amount.push(2);
    setContInput(amount);
    setrefreshInput(`num${amount}`);
  };
  const handleOnMinusInput = () => {
    let amount = contInput;
    amount.pop(1);
    setContInput(amount);
    setrefreshInput(`num${amount}`);
  };
  const handleChangeInfo = (constant, e) => {
    setDataFilter((prevState) => ({
      ...prevState,
      [constant]: e,
    }));
  };
  const handleChangeInfoSelects = (constant, e) => {
    setDataSelectFiler((prevState) => ({
      ...prevState,
      [constant]: e,
    }));
  };
  const handleChangeInfoCondition = (constant, e) => {
    setConditionFilter((prevState) => ({
      ...prevState,
      [constant]: e,
    }));
  };

  const cleanFilters = () => {
    setDataFilter([]);
    setDataSelectFiler([]);
    setConditionFilter([]);
    setDateFilter([]);
    setContInput([1]);
    setrefreshInput(5);
  }

  const getDataTable = () => {
    dispatch(
      getAllFastCotyzationReport({
        data: dataFilter,
        selects: dataSelectFiler,
        condition: conditionFilter,
        dates: dateFilter,
      })
    ).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setRowsModal((prevState) => ({
          ...prevState,
          rows: payload.data.payload.data,
        }));
      } else if (resp.payload.response.data.status === 404) {
        setRowsModal((prevState) => ({
          ...prevState,
          rows: [],
        }));
        Toast.fire({
          title: "Atención:",
          html: "La información no se encontro",
          type: "warning",

        });
      } else {
        Toast.fire({
          title: "Atención:",
          html: "Debe completar todos los campos",
          type: 'warning'
        });
      }
    });
  };
  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="12" md="10">
              <h3 className="mb-0">Busqueda Avanzada</h3>
              <p className="text-sm mb-0">
                Busqueda personalizada en los registros de la tabla inferior
              </p>
            </Col>
            <Col
              xs="12"
              md="1"
              className="p-1"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-icon"
                color="info"
                size="sm"
                block
                type="button"
                onClick={() => getDataTable()}
              >
                <span className="btn-inner--icon mr-">
                  <i class="fas fa-search"></i>
                </span>
                <span className="btn-inner--text">Buscar</span>
              </Button>
            </Col>
            <Col
              xs="12"
              md="1"
              className="p-1"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-icon"
                color="danger"
                size="sm"
                block
                type="button"
                onClick={() => cleanFilters()}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text">Limpiar</span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="d-flex justify-content-center align-items-center">
            {contInput.map((item, key) => (
              <>
                {item === 1 ? (
                  <>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="startDate">
                        </label>
                        <Input
                          key={`select${key}`}
                          className="m-0"
                          type="select"
                          value={dataSelectFiler[`Select${key}`] ? (dataSelectFiler[`Select${key}`].value) : ""}
                          onChange={(e) =>
                            handleChangeInfoSelects(
                              `Select${key}`,
                              e.target.children[e.target.selectedIndex].id
                            )
                          }
                        >
                          {dataSelect.map((value, key) => {
                            return (
                              <option
                                key={`option${key}`}
                                id={value.Field}
                              >{`${value.Comment}`}</option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="startDate"
                        ></label>
                        <Input
                          value={dataFilter[`Text${key}`] || ""}
                          key={key}
                          className="m-0"
                          onChange={(e) =>
                            handleChangeInfo(`Text${key}`, e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="1" className=" d-flex justify-content-end">
                      <Button
                        className="btn ml-5 "
                        color="success"
                        id="edit"
                        key={key}
                        disabled={contInput.length === 4 ? true : false}
                        onClick={() => handleOnPlusInput()}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus"></i>
                        </span>
                      </Button>
                    </Col>
                    <Col xs="12" md="1">
                      <Button
                        className="btn mr-5"
                        color="danger"
                        id="edit"
                        key={key}
                        disabled={contInput.length === 1 ? true : false}
                        onClick={() => handleOnMinusInput()}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-minus"></i>
                        </span>
                      </Button>
                    </Col>
                  </>
                ) : (
                  item === 2 && (
                    <>
                      <Col xs="12" md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="startDate"
                          ></label>
                          <Input
                            key={key}
                            className="m-0"
                            value={dataSelectFiler[`Select${key}`] ? (dataSelectFiler[`Select${key}`].value) : ""}
                            type="select"
                            onChange={(e) =>
                              handleChangeInfoSelects(
                                `Select${key}`,
                                e.target.children[e.target.selectedIndex].id
                              )
                            }
                          >
                            {dataSelect.map((value, key) => {
                              return (
                                <option
                                  key={`options${key}`}
                                  id={value.Field}
                                >{`${value.Comment}`}</option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="7">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="startDate"
                          ></label>
                          <Input
                            key={key}
                            value={dataFilter[`Text${key}`] || ""}
                            className="m-0"
                            onChange={(e) => handleChangeInfo(`Text${key}`, e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="1">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="startDate"
                          ></label>
                          <Select
                            key={key}
                            className="basic-single"
                            type="select"
                            value={{ label: conditionFilter[`condition${key}`], value: 1 } || ""}
                            isSearchable
                            options={[
                              { label: "AND", value: 1 },
                              { label: "OR", value: 2 },
                            ]}
                            onChange={(e) =>
                              handleChangeInfoCondition(
                                `condition${key}`,
                                e.label
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )
                )}
              </>
            ))}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default QuickQuoteFilters;
