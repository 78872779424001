/* eslint-disable jsx-a11y/anchor-is-valid */
// core antd
import { Icon, Tabs, Upload } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// core components
import EditEquipment from "components/Sales/Request/EditEquipment.jsx";
// nodejs library
import _ from "lodash";
// nodejs library to format dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { useState } from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row, Table } from "reactstrap";

const { TabPane } = Tabs;
const { Dragger } = Upload;

const EquipmentsList = props => {

  const {
    open,
    title,
    state,
    idBusinessModel,
    loading,
    fileList,
    subtitle,
    idRequest,
    equipments,
    references,
    formOptions,
    justifications,
    onBack,
    notify,
    onFileList,
    toggleOpenEdit,
    onChangeJustify,
    onEditEquipment,
    onSaveAndBack,
    onCompleteProcess
  } = props;

  const [tabKey, setTabKey] = useState("1");
  const [equipment, setEquipment] = useState({});
  const [type, setType] = useState(null);

  const changeTab = value => {
    setTabKey(value);
  };

  const openEditEquipment = (row, type) => {
    setEquipment(row);
    toggleOpenEdit();
    setType(type);
  };

  const closeEditEquipment = () => {
    setEquipment({});
    toggleOpenEdit();
    setType(null);
  };

  const editEquipment = values => {
    // console.log(values)
    if (_.isEqual(values, equipment)) {
      notify('warning', 'Atención', 'Aun no se ha modificado ningún valor.');
    } else {
      onEditEquipment(values, type);
    }
  }

  const uploadProps = {
    name: 'reference',
    multiple: true,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    action: `${urlBase}/digital-request/upload-references-files/${idRequest}`,
    onChange: onFileList,
  };

  const verifyAddPrice = () => {
    let flag = false;
    const { ibm, cisco } = equipments;
    if (ibm.length) {
      ibm.forEach(element => {
        if (element.price > 0) {
          flag = true;
        }
      });
    }
    if (cisco.length) {
      cisco.forEach(element => {
        if (element.price > 0) {
          flag = true;
        }
      });
    }
    return flag;
  };

  const renderTableEquipments = (cols, rows, name, type) => (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md='9'>
            <h3 className="text-white mb-0">{name}</h3>
          </Col>
        </Row>
      </CardHeader>
      <Table
        className="align-items-center table-dark table-flush"
        responsive
      >
        <thead className="thead-dark">
          <tr>
            {
              cols.map((row, key) => (row.hide === false && <th key={key}>{row.name}</th>))
            }
            {state === 1 && <th />}
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.map((row, key) => (
              <tr key={key}>
                {
                  cols.map((col, key) => (
                    col.hide === false &&
                    <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                      {row[col.key]}
                    </th>
                  ))
                }
                {
                  state === 99 &&
                  <th key='action' style={{ whiteSpace: "normal", textAlign: "justify" }}>
                    <a
                      className="table-action table-action"
                      onClick={() => openEditEquipment(row, type)}
                      href="#"
                      id="edit"
                    >
                      <i className="fas fa-edit" />
                    </a>
                  </th>
                }
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Card>
  )

  return (
    <>
      <EditEquipment
        type={type}
        title="Modificación de un Equipo"
        visible={open}
        loading={loading}
        initialValues={equipment}
        options={formOptions}
        onClose={() => closeEditEquipment()}
        onEdit={(v) => editEquipment(v)}
      />
      <Card>
        <CardHeader>
          <Row>
            <Col xs="12" md='7'>
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            {
              state === 1 &&
              <Col className="text-right" xs="12" md='5'>
                <Row style={{ justifyContent: "flex-end" }}>
                  <Col xs='12' md='6' className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      className="btn-neutral btn-round btn-icon"
                      color="success"
                      onClick={() => onCompleteProcess(verifyAddPrice())}
                      size="sm"
                      block
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-check-double" />
                      </span>
                      <span className="btn-inner--text">Completar Proceso</span>
                    </Button>
                  </Col>
                  <Col xs='12' md='6' className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      className="btn-neutral btn-round btn-icon"
                      color="success"
                      onClick={onSaveAndBack}
                      size="sm"
                      block
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Guardar y Salir</span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            }
          </Row>
        </CardHeader>
        <CardBody>
          <Tabs defaultActiveKey={tabKey} onChange={changeTab}>
            <TabPane tab="Equipos" key="1">
              {
                equipments.ibm.length ?
                  renderTableEquipments(
                    [
                      { name: "Cantidad de Equipos", key: "amountEquipments", hide: idBusinessModel === 1 ? true : false },
                      { name: "País", key: "country", hide: false },
                      { name: "Tipo de Modelo", key: "typeModel", hide: false },
                      { name: "Serie", key: "serial", hide: idBusinessModel === 1 ? false : true },
                      { name: "Práctica", key: "practice", hide: false },
                      { name: "Cantidad de Mantenimientos", key: "amountMaintenance", hide: false },
                      { name: "Plataforma", key: "platform", hide: false },
                      { name: "Horario de Atención", key: "officeHour", hide: false },
                      { name: "Tiempo Cambio de Parte", key: "timeChangePart", hide: false },
                      { name: "Años del Servicio", key: "validityService", hide: false },
                      { name: "Renovación Automatica", key: "automaticRenewal", hide: false },
                      { name: "Incluye Partes/Equipos", key: "equipmentParts", hide: false },
                      { name: "Viáticos $", key: "viatic", hide: false },
                      { name: `${idBusinessModel === 1 ? "Precio $" : "Costo $"}`, key: "price", hide: false }
                    ], equipments.ibm, 'Lista de Equipos', 'ibm')
                  :
                  <div className="pt-4 pb-4">
                    <h5 className="h3 title">
                      <span className="d-block mb-1 text-center">Sin Equipos </span>
                      <small className="h3 font-weight-light text-muted">
                        Al requerimiento que se selecciono, no se le realizo la carga de ninguna lista de equipos
                    </small>
                    </h5>
                  </div>
              }
            </TabPane>
            {/* <TabPane tab="Equipos CISCO" key="2">
              {
                equipments.cisco.length ?
                  renderTableEquipments([
                    { name: "Serial", key: "serial" },
                    { name: "Número Producto", key: "productNumber" },
                    { name: "Descripción", key: "description" },
                    { name: "Horario de Atención", key: "officeHours" },
                    { name: "Nivel de Cobertura", key: "coverageLevel" },
                    { name: "Vigencia", key: "validityService" },
                    { name: "Precio", key: "price" }
                  ], equipments.cisco, 'CISCO', 'cisco')
                  :
                  <div className="pt-4 pb-4">
                    <h5 className="h3 title">
                      <span className="d-block mb-1 text-center">Sin Equipos CISCO</span>
                      <small className="h3 font-weight-light text-muted">
                        Al requerimiento que se selecciono, no se le realizo la carga de ninguna lista de equipos CISCO
                    </small>
                    </h5>
                  </div>
              }
            </TabPane> */}
            {
              state > 0 && (references.length || justifications.length) &&
              <TabPane tab="Referencias" key="2">
                <div className="card-deck flex-column flex-xl-row">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col sm="12" md="8">
                          <h5 className="h3 mb-0">Documentos Cargados</h5>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <ListGroup className="list my--3" flush>
                        {
                          references.length ?
                            references.map((row, key) => (
                              <ListGroupItem key={key} className="px-0">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      alt="Notificación"
                                      className="avatar rounded-circle"
                                      src={require(`assets/img/theme/attachment.png`)}
                                    />
                                  </Col>
                                  <div className="col ml--2">
                                    <h4 className="mb-0">
                                      {row.name}
                                    </h4>
                                    <small>
                                      {
                                        `${row.createdBy} - ${moment(row.createdAt)
                                          .utc()
                                          .utcOffset(moment().utcOffset())
                                          .format("LLL")}`
                                      }
                                    </small>
                                  </div>
                                  <Col className="col-auto">
                                    <Button
                                      color="info"
                                      size="sm"
                                      href={`${urlBase}/digital-request/download-attachment/${window.btoa(row.path)}/${window.btoa(row.name)}`}
                                    >
                                      <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-file-download" />
                                      </span>
                                      <span className="btn-inner--text">Descargar</span>
                                    </Button>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            ))
                            :
                            <div className="pt-4 pb-4">
                              <h5 className="h3 title">
                                <span className="d-block mb-1 text-center">Sin documentos</span>
                                <small className="h3 font-weight-light text-muted">
                                  Al requerimiento que se seleccionó, no se le realizo la carga de ningún archivo de referencia.
                                  </small>
                              </h5>
                            </div>
                        }
                      </ListGroup>
                    </CardBody>
                    {
                      justifications.length ?
                        <>
                          <CardHeader>
                            <Row className="align-items-center">
                              <Col sm="12" md="8">
                                <h5 className="h3 mb-0">Justificaciones Agregadas</h5>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            {
                              justifications.map((row, key) => (
                                <FormGroup key={key}>
                                  <Input
                                    disabled
                                    id="justify"
                                    rows="3"
                                    resize="none"
                                    type="textarea"
                                    maxLength={500}
                                    defaultValue={row.justify}
                                  />
                                </FormGroup>
                              ))
                            }
                          </CardBody>
                        </> : null
                    }
                  </Card>
                </div>
              </TabPane>
            }
          </Tabs>
          {
            state === 1 && verifyAddPrice() &&
            <div className="form-row align-items-center pt-1">
              <Col sm='12' className="m-1">
                <blockquote className="blockquote text-center">
                  <p className="mb-0">
                    Cargar Referencia
                  </p>
                  <footer className="blockquote-footer">
                    <cite title="Source Title">Por favor, cargue la referencia para el precio o los precios que indicó en los equipos</cite>
                  </footer>
                </blockquote>
                <div className="form-row align-items-center">
                  <Col className="m-1" sm="12">
                    <Dragger {...uploadProps} fileList={fileList}>
                      <p className="ant-uploading-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                      <p className="ant-uploading-hint">
                        *Se debe cargar la referencia para los precios ingresados.
                    </p>
                    </Dragger>
                  </Col>
                </div>
              </Col>
              <Col sm='12' className="m-1">
                <FormGroup>
                  <label
                    className="d-flex justify-content-center"
                    htmlFor="justify"
                  >
                    <blockquote className="blockquote text-center">
                      <p className="mb-0">
                        Justificación
                    </p>
                      <footer className="blockquote-footer">
                        <cite title="Source Title">En caso de no contar con un archivo de referencia</cite>
                      </footer>
                    </blockquote>
                  </label>
                  <Input
                    id="justify"
                    resize="none"
                    rows="5"
                    type="textarea"
                    onChange={(e) => onChangeJustify(e)}
                    maxLength={500}
                  />
                </FormGroup>
              </Col>
            </div>
          }
          <div className="form-row align-items-center">
            <Col className="m-2" sm="12" md="3">
              <Button
                className="btn-icon"
                color="success"
                onClick={onBack}
                size='sm'
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-arrow-left" />
                </span>
                <span className="btn-inner--text">Regresar</span>
              </Button>
            </Col>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

EquipmentsList.propTypes = {
  title: PropTypes.string.isRequired,
  equipments: PropTypes.object.isRequired,
};

export default EquipmentsList;
