/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "DetailsModal" de ReportHours para visualizar el detalle de un reporte de tiempos en especifico

*Props:
    row: json con la información del reporte de horas que el usuario seleccionó
    setModal: useState para cerrar el modal
    showModal: useState para guardar si el modal esta abierto o no
    dropDownOptions: useState con las opciones de los dropdowns
=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState } from 'react';
// reactstrap components
import {
    Button, Card, CardBody, Modal,
    ModalHeader, Row, Col, FormGroup, Input,
    ModalBody,
    ModalFooter, Form,
} from "reactstrap";
// react-select library for filters
import Select from "react-select";

const DetailsModal = ({
    row,
    setModal,
    showModal,
    dropDownOptions
}) => {

    //#region Functions 

    //#region initialize the modal with new data every time I click an element
    const [state, setState] = useState({
        unmountOnClose: true,
    });

    const { unmountOnClose } = state;
    //#endregion

    //#region para cancelar o cerrar el modal
    const toggleCloseModal = (() => {
        setModal((prevState) => ({
            ...prevState,
            showModal: !prevState.showModal,
        }));
    });
    //#endregion

    //#region campos (inputs y selects) del modal
    const [fields, setFields] = useState([
        {
            colWidth: "6",
            label: "Ticket",
            placeholder: "",
            id: "ticket",
            options: "",
            type: "text",
            disabled: true,
            visibleDefault: false,
            elementType: "Input"
        },
        {
            colWidth: "6",
            label: "Proveedor",
            placeholder: "",
            id: "consultantName",
            options: "",
            type: "text",
            disabled: true,
            visibleDefault: false,
            elementType: "Input"
        },
        {
            colWidth: "6",
            label: "Detalle del reporte",
            placeholder: "",
            id: "details",
            options: "",
            type: "textarea",
            disabled: true,
            visibleDefault: false,
            elementType: "Input"
        },
        {
            colWidth: "6",
            label: "Comentarios del coordinador",
            placeholder: "",
            id: "colabReason",
            options: "",
            type: "textarea",
            disabled: true,
            visibleDefault: false,
            elementType: "Input"
        },
    ]);
    //#endregion

    //#endregion

    return (
        <>

            <Modal size="lg" isOpen={showModal} unmountOnClose={unmountOnClose} toggle={toggleCloseModal}>
                <ModalHeader>
                    Detalles del reporte
                </ModalHeader>
                <ModalBody className='mb-lg-0'>
                    <Form className="needs-validation" noValidate>
                        <Card className='shadow mb-lg-0'>
                            <CardBody>
                                <Row>
                                    {
                                        row["status"] === 10 && //rechazado
                                        <Col sm="12">
                                            <FormGroup>
                                                <label className="form-control-label">{"Razón de Rechazado"}</label>
                                                <Select
                                                    id="rejectedReason"
                                                    className="basic-single text-dark"
                                                    type="select"
                                                    isSearchable
                                                    isDisabled={true}
                                                    defaultValue={{ label: row["rejectedText"], value: row["rejectedReason"] }}
                                                    options={dropDownOptions["cancelationReasons"]}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                    {
                                        fields.map((field, key) => (
                                            <Col sm={field.colWidth} key={key}>
                                                <FormGroup key={key}>
                                                    <label className="form-control-label">{field.label}</label>
                                                    {
                                                        field.elementType === "Input" ?
                                                            <Input
                                                                key={field.id}
                                                                id={field.id}
                                                                className="form-control"
                                                                type={field.type}
                                                                readOnly={field.disabled}
                                                                defaultValue={row && (field.type === "textarea" && row[field.id] !== null) ? Buffer.from(row[field.id], "base64").toString() : row[field.id]}
                                                                placeholder={field.placeholder}
                                                            />
                                                            :
                                                            <Select
                                                                key={field.id}
                                                                id={field.id}
                                                                placeholder={field.placeholder}
                                                                name={field.id}
                                                                className="basic-single text-dark"
                                                                type="select"
                                                                isSearchable
                                                                isDisabled={field.disabled}
                                                                defaultValue={row && row[field.id]}
                                                                options={dropDownOptions[field.options]}
                                                            />
                                                    }
                                                </FormGroup>
                                            </Col>

                                        ))
                                    }
                                    {
                                        row["status"] === 4 && //CATS ERROR
                                        <Col sm="12">
                                            <FormGroup>
                                                <label className="form-control-label">{"Error de SAP"}</label>
                                                <Input
                                                    id="sapError"
                                                    className="form-control"
                                                    type="textarea"
                                                    readOnly={true}
                                                    defaultValue={row && (row["sapError"] !== null) ? Buffer.from(row["sapError"], "base64").toString() : row["sapError"]}
                                                    placeholder=""
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-icon" color="danger" size="sm" block type="button"
                        onClick={(e) => toggleCloseModal()}
                    >
                        <span className="btn-inner--text">
                            Cerrar
                        </span>
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
};

export default DetailsModal;