/*!

=========================================================
*Inventario Validado (TOMAS FÍSICAS)
=========================================================

*Componente para verificar los inventarios no / valorados
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

//React 
import React, { useState, useEffect, useContext } from "react";

// Reactstrap components
import {
    InputGroupAddon,
    InputGroup,
    FormGroup,
    Button,
    Input,
    Label,
    Row,
    Col,
} from "reactstrap";

//Componente para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Componentes locales
import ModalInfoInventoryAndInquiries from "../SharedComponents/ModalInfoInventoryAndInquiries.jsx";

//Actions.
import { checkValidatedInventory } from 'actions/wms';

import { Upload } from 'antd';

import { useDispatch } from 'react-redux';

//#region icons
import { MdWarehouse } from "react-icons/md";
import { FaMapMarkerAlt, FaTabletAlt, FaBoxes } from "react-icons/fa";
import { TbBuildingWarehouse } from "react-icons/tb";
import { IoDocumentText } from "react-icons/io5";
import { FaUnity } from "react-icons/fa6";
//#endregion


const Validated = (
    {
        stage,
        setStage,
        selectedComponent
    }
) => {

    //Renombrar variable.
    const idComponent = selectedComponent.id;

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        warehouse,
        isMobile,
        options,
        notify,
    } = useContext(UserContext);
    //#endregion

    //State para guardar la información.
    const [newInfo, setNewInfo] = useState({
        unitMessure: "UN"
    })
    const [openModal, setOpenModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({
        title: "Número de Almacén",
        subtitle: "Seleccione un almacén",
        spinnerText: "Extrayendo Almacenes...",
        searchText: "Buscar almacén...",
        colunmName: "Almacén",
        id: "warehouse"
    })

    const handleSearchWarehouse = (e, newInfo, id) => {

        setModalInfo(prev => {
            return { ...prev, id: id }
        })
        setOpenModal(true)

    }

    const handleSearchWarehouseType = (e, newInfo, id) => {

        setModalInfo(prev => {
            return {
                ...prev,
                title: "Tipo de Almacén",
                subtitle: "Seleccione un tipo almacén",
                spinnerText: "Extrayendo tipo de Almacenes...",
                searchText: "Buscar tipo de almacén...",
                colunmName: "Tipo de Almacén",
                id: id
            }
        })
        setOpenModal(true)

    }

    const handleSearchLocation = (e, newInfo, id) => {
        setModalInfo(prev => {
            return {
                ...prev,
                title: "Seleccionar Ubicación",
                subtitle: "Seleccione una ubicación",
                spinnerText: "Extrayendo ubicaciones...",
                searchText: "Buscar ubicación...",
                colunmName: "Ubicación",
                id: id
            }
        })
        setOpenModal(true)
    }

    const getWareHouses = (newInfo) => {
        alert("per")
    }

    const fields = [
        {
            id: 'warehouse',
            label: "Almacén",
            width: "6",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: MdWarehouse,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "inputKey",
            function: handleSearchWarehouse,
            iconButton: "fas fa-search"
        },
        {
            id: 'warehouseType',
            label: "Tipo",
            width: "6",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: TbBuildingWarehouse,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "inputKey",
            function: handleSearchWarehouseType,
            iconButton: "fas fa-warehouse"
        },
        {
            id: 'document',
            label: "Documento",
            width: "12",
            type: "input",
            options: "",
            disabled: false,
            required: true,
            icon: IoDocumentText,
            deleteEmptyOption: true,
            stage: [1, 2]
        },
        {
            id: 'location',
            label: "Ubicación",
            width: "12",
            type: "input",
            options: "",
            disabled: false,
            required: true,
            icon: FaMapMarkerAlt,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "inputKey",
            function: handleSearchLocation,
            iconButton: "fas fa-map-marker-alt"
        },
        {
            id: 'material',
            label: "Material",
            width: "12",
            type: "input",
            options: "",
            disabled: false,
            required: true,
            icon: FaTabletAlt,
            deleteEmptyOption: true,
            stage: [1, 2]
        },
        {
            id: 'quantity',
            label: "Cantidad",
            width: "12",
            type: "input",
            options: "",
            disabled: false,
            required: true,
            icon: FaBoxes,
            deleteEmptyOption: true,
            stage: [1, 2]
        },
        {
            id: 'unitMessure',
            label: "Unidad",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: false,
            icon: FaUnity,
            deleteEmptyOption: true,
            stage: [1, 2]
        },
    ];



    //Cambia el estado newInfo según el campo.
    const handleOnChange = (idField, label, idComponent) => {


        if (idField === "document") {
            label = label.padStart(10, '0').slice(-10);
        }

        setNewInfo({ ...newInfo, [idField]: label })

    }

    useEffect(() => {
        if (options?.warehouses) {
            handleOnChange("warehouse", warehouse, idComponent)
        }

    }, [options])

    const modeButtonAddon = (field, type) => {
        if (field.matchNumbers) {
            if (newInfo[field.id] == newInfo[field.matchNumbers]) {
                return type == "color" ? "success" : type == "icon" ? "fas fa-check" : true
            }
            else if
                (newInfo[field.id] < newInfo[field.matchNumbers]) {
                return type == "color" ? "warning" : type == "icon" ? "fas fa-times" : true
            } else if
                (newInfo[field.id] > newInfo[field.matchNumbers]) {
                return type == "color" ? "danger" : type == "icon" ? "fas fa-times" : true

            } else {
                return type == "color" ? "default" : type == "icon" ? "fas fa-times" : true
            }
        } else {
            return type == "color" ? "success" : type == "icon" ? "fas fa-search" : false
        }
    }

    const handleConfirmValidated = (newInfo) => {
        let err = null;
        console.log(newInfo)
        if (newInfo.warehouse === "" || newInfo.warehouse === null || newInfo.warehouse === undefined) {
            err = "Por favor seleccione un No. de almacén válido.";
        } else {
            if (newInfo.warehouseType === "" || newInfo.warehouseType === null || newInfo.warehouseType === undefined) {
                err = "Por favor seleccione un tipo de almacén válido.";
            } else {
                if (newInfo.document === "" || newInfo.document === null || newInfo.document === undefined) {
                    err = "Por favor ingrese un No. de documento válido.";
                } else {
                    if (newInfo.location === "" || newInfo.location === null || newInfo.location === undefined) {
                        err = "Por favor seleccione una ubicación válida.";
                    } else {
                        if (newInfo.material === "" || newInfo.material === null || newInfo.material === undefined) {
                            err = "Por favor ingrese un material.";
                        } else {
                            if (newInfo.quantity < 1 || newInfo.quantity === null || newInfo.quantity === undefined) {
                                err = "La cantidad debe ser mayor que 1.";
                            }
                        }
                    }
                }
            }
        }

        if (err) {

            notify("warning", "Alerta", err)
        } else {
            handleConfirm(newInfo);
        }
    }

    const handleConfirm = (newInfo) => {
        dispatch(checkValidatedInventory(newInfo)).then((resp) => {
            const { payload } = resp;
            console.log(payload)
            if (resp.payload.isAxiosError) {
                notify("danger", "Alerta", "Hubo un error al registrar los datos, comuníquese con Application Management.");
            }
            else {
                let message = payload.data.message;
                let responseStatus = payload.data.responseStatus;
                if (responseStatus) {
                    setNewInfo({
                        unitMessure: "UN",
                        warehouse: warehouse
                    })
                }

                notify(responseStatus ? "success" : "danger", responseStatus ? "Resultado: " : "Error: ", message);
            }

        });
    }


    return (
        <>
            <ModalInfoInventoryAndInquiries
                openModal={openModal}
                setOpenModal={setOpenModal}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                modalInfo={modalInfo}
                getWareHouses={getWareHouses}
            />

            <Row >
                <Col xs="10">
                    {isMobile &&
                        <h3 className='text-center'>{selectedComponent.title}</h3>
                    }
                </Col>
                <Col xs="2" style={isMobile ? {} : { display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        className="btn-icon-only rounded-circle"
                        color="info"
                        outline
                        type="button"
                        onClick={() => handleConfirmValidated(newInfo)}
                    >
                        <span className="btn-inner--icon">
                            <i className="fas fa-check" />
                        </span>
                    </Button>
                </Col>

            </Row>
            <Row className={'mt-3'}>
                {
                    fields.map((field, key) =>
                        <Col xs={field.width}>
                            <FormGroup className="mb-4" >

                                <Label className="font-weight-bold ml-2" >

                                    {field.icon && //Icono 
                                        <field.icon className="mr-2 mb-1" style={{ fontSize: "16px" }} />
                                    }

                                    {field.label + ':'}
                                </Label>


                                <InputGroup className="input-group-merge">
                                    <Input
                                        type={field.type}
                                        className=""
                                        disabled={field.disabled || (field.dependsEnableOf && !newInfo[field.dependsEnableOf])}
                                        size={isMobile && "sm"}
                                        value={newInfo && newInfo[field.id] ? newInfo[field.id] : ""}
                                        onChange={(e) => {
                                            if (field.type === "number") {
                                                handleOnChange(field.id, e.target.value.replace(/\D/g, ""), idComponent);
                                            } else {
                                                handleOnChange(field.id, e.target.value, idComponent);
                                            }
                                        }}

                                        min={1}
                                    >
                                        {field.deleteEmptyOption && <option id=""></option>}
                                        {
                                            options[field.options] && options[field.options].map((value) => {
                                                return (
                                                    <option id={value.value}>{value.label}</option>
                                                );
                                            })}
                                    </Input>

                                    {
                                        (field.elementType == "inputKey") &&
                                        <InputGroupAddon size="sm" addonType="append" className={'ml--2'}>
                                            <Button color={modeButtonAddon(field, "color")}
                                                size="sm"
                                                outline
                                                onClick={(e) => field.function(e, newInfo, field.id)}
                                                disabled={modeButtonAddon(field, "disabled")}>
                                                <span className="btn-inner--icon">
                                                    <i className={field.iconButton} />
                                                </span>

                                            </Button>
                                        </InputGroupAddon>
                                    }
                                </InputGroup>


                            </FormGroup>
                        </Col>
                    )
                }
            </Row>

        </>
    )
}

export default Validated;