import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getOptionsData = createAction(constants.FREELANCE_GET_OPTIONS, method.get(urls.FREELANCE.getOptions));
export const getRows = createAction(constants.FREELANCE_GET_DATA, body => method.post(urls.FREELANCE.getRows, body)());
export const getFinanceEmails = createAction(constants.FREELANCE_GET_FINANCE_EMAILS, body => method.post(urls.FREELANCE.getFinanceEmails, body)());
export const getPoInfo = createAction(constants.FREELANCE_GET_PO_INFO, (id) => method.get(`${urls.FREELANCE.getPoInfo}/${id}`)());
export const newPoAssignation = createAction(constants.FREELANCE_NEW_PO_ASSIG, body => method.post(urls.FREELANCE.newPoAssignation, body)());
export const newResponsible = createAction(constants.FREELANCE_NEW_RESPONSIBLE, body => method.post(urls.FREELANCE.newResponsible, body)());
export const inactivePo = createAction(constants.FREELANCE_INACTIVE_PO, body => method.post(urls.FREELANCE.inactivePo, body)());
export const reportHoursActions = createAction(constants.FREELANCE_REPORT_ACTIONS, body => method.post(urls.FREELANCE.reportHoursActions, body)());
export const approveMassAction = createAction(constants.FREELANCE_APPROVE_MASS, body => method.post(urls.FREELANCE.approveMass, body)());
export const hesActions = createAction(constants.FREELANCE_HES_ACTION, body => method.post(urls.FREELANCE.hesActions, body)());
export const billsActions = createAction(constants.FREELANCE_BILLS_ACTION, body => method.post(urls.FREELANCE.billsActions, body)());
export const blockAccess = createAction(constants.FREELANCE_BLOCK_ACCESS, body => method.post(urls.FREELANCE.blockAccess, body)());
export const deleteAccess = createAction(constants.FREELANCE_DELETE_ACCESS, body => method.post(urls.FREELANCE.deleteAccess, body)());
export const newPass = createAction(constants.FREELANCE_NEW_PASS, body => method.post(urls.FREELANCE.newPass, body)());
export const updateAccessFreelance = createAction(constants.FREELANCE_UPDATE_ACCESS_FREELANCE, body => method.post(urls.FREELANCE.updateAccessFreelance, body)());
export const newProject = createAction(constants.FREELANCE_NEW_PROJECT, body => method.post(urls.FREELANCE.newProject, body)());
export const blockProject = createAction(constants.FREELANCE_BLOCK_PROJECT, body => method.post(urls.FREELANCE.blockProject, body)());
export const newApprover = createAction(constants.FREELANCE_NEW_APPROVER, body => method.post(urls.FREELANCE.newApprover, body)());
export const blockApprover = createAction(constants.FREELANCE_BLOCK_APPROVER, body => method.post(urls.FREELANCE.blockApprover, body)());
export const updateEmailBilling = createAction(constants.FREELANCE_UPDATE_EMAIL_BILLING, body => method.post(urls.FREELANCE.updateEmailBilling, body)());
export const getInfoReport = createAction(constants.FREELANCE_GET_INFO_REPORT, body => method.post(urls.FREELANCE.getInfoReport, body)());
export const deleteFile = createAction(constants.FREELANCE_DELETE_FILE, body => method.post(urls.FREELANCE.deleteFile, body)());
export const deleteFileActa = createAction(constants.FREELANCE_DELETE_FILE_ACTA, body => method.post(urls.FREELANCE.deleteFileActa, body)());
export const deleteAllFiles = createAction(constants.FREELANCE_DELETE_ALL_FILES, body => method.post(urls.FREELANCE.deleteAllFiles, body)());
export const uploadActa = createAction(constants.FREELANCE_UPLOAD_ACTA, body => method.post(urls.FREELANCE.newActa, body)());
export const getActaFile = createAction(constants.FREELANCE_GET_ACTA, body => method.post(urls.FREELANCE.getActaFile, body)());
export const getReportStatus = createAction(constants.FREELANCE_GET_REPORT_STATUS, body => method.post(urls.FREELANCE.getReportStatus, body)());
export const getSubcontractInfo = createAction(constants.FREELANCE_GET_SUBCONTRACT, body => method.post(urls.FREELANCE.getSubcontractInfo, body)());
export const approvedAltaRequest = createAction(constants.FREELANCE_UP_ALTA_REQUEST, body => method.post(urls.FREELANCE.approvedAltaRequest, body)());
export const sendAltaRequest = createAction(constants.FREELANCE_SEND_ALTA_REQUEST, body => method.post(urls.FREELANCE.sendAltaRequest, body)());
export const deleteAltaFile = createAction(constants.FREELANCE_DELETE_ALTA_FILE, body => method.post(urls.FREELANCE.deleteAltaFile, body)());

