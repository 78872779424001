/*!

=========================================================
*Autopp - CardSelectForm
=========================================================

* En este componente  valida si hay campos vacíos en los forms, establece una 
bandera llamada "ReadyToSend", lo cual le indica a los componentes que agreguen su información al state principal 
editData, y cuando es correcto realiza un dispatch a la base de datos con la información de la oportunidad y pone la pantalla en default.

*La lógica de envio de la petición funciona como un efecto "cadena", donde handleSendNewRequest ejecuta el método validateForms el cual verifica 
si no hay campos vacíos, si no hay pone el readyToSend en true para que todos los componentes agreguen su info en EditData, luego por ultimo el 
efecto se encarga de realizar el dispatch cuando todos los componentes agregaron su información, y al final el cleanForms reinicia todos los estados.

*Importante revisar el efecto que hay en el componente MainTableAutopp en la linea 116 el cual agrega los ldrs, baw, y otros al EditData.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux';

//Action para el envio de la oportunidad apartir del JSON Principal EditData.
import { newRequest } from 'actions/autoppLdrs';

//Componente de contexto ubicado en AutoppStart
import { UserContext } from './UserContext';
import SalesTeamItemForm from '../SalesTeam/SalesTeamItemForm';

export const useSendForm = () => {


    //#region states globales
    const {
        setReloadTableRequests,
        setResetSpecificStates,
        temporalFolderFilesId,
        setCleanTypeaheads,
        countLDRActives,
        setFormSelected,
        setReadyToSend,
        setFileListBOM,
        fileListBOM,
        readyToSend,
        setEditData,
        toggleCheck,
        userName,
        editData,
        setLoad,
        Toast,


        generalInformationInfo,
        clientInfo,

        resetInfo,


        bawItemsList,
        salesTeamsList,

        handleResetSalesTeams,
        handleResetBaw,

        setLDRSAreReady,
        LDRSAreReady,


        setCountLDRActives //VER SI BORRAR

    } =
        useContext(UserContext);
    //#endregion

    //#region states locales
    const dispatch = useDispatch();
    //#endregion

    //#region Realiza un formato de fecha acorde al campo Date
    const DateNowFormatToLabel = () => {
        let date = new Date();
        let DateBuilded = date.toISOString().split('T')[0]
        return DateBuilded;
    }

    //#endregion

    //#region Este botón gestiona toda la cadena para el envio de información de la oportunidad, validando todos los efectos en MainTableAutopp y de este camponente.

    const handleSendNewRequest = () => {
        setReadyToSend(false);



        let { cont, contQuantityFieldsLDRMeetings } = validateForms();

        if (cont > 0 && true) {
            //setLoad(false); 
            Toast.fire({
                title: "Atención",
                html: "Hay " + cont + " campos de formularios vacíos, por favor complételos.",
                type: 'warning'
            });
            return;
        }

        //Valida si no hay LDR adjuntos en caso que se indique que aplica LDR
        if (!validateLDRQuantity()) {
            debugger;
            console.log("Return LDR")
            return;
        }

        //Es para que en el LDR de Meetings al menos haya un campo lleno debido a que todos son opcionales.
        if (contQuantityFieldsLDRMeetings === 0 && toggleCheck?.meetings === true) {
            //setLoad(false); 
            Toast.fire({
                title: "Atención",
                html: "Debe llenar al menos un campo en el LDR de Meetings.",
                type: 'warning'
            });
            return;
        }

        //Valida si debe adjuntar archivos BOM 
        if (!validateBOMFiles()) {
            debugger;
            console.log("Return BOM")
            return;
        }


        if (bawItemsList[0] === undefined) {
            Toast.fire({
                title: "Baw",
                html: "Debe agregar gestiones en Baw.",
                type: 'warning'
            });
            return;
        }

        if (salesTeamsList[0] === undefined) {
            Toast.fire({
                title: "Equipos de Ventas",
                html: "Agregue por favor personas a su equipo de ventas.",
                type: 'warning'
            })
            return

        }


        //Cuando esta true, dispara todos los efectos y dispatch locales y en general MainTableAutopp.
        setReadyToSend(true)


    }

    //#endregion

    //Validar que el salesteams que esté lleno.
    const validateSalesTeamsList = () => {
        if (Object.keys(salesTeamsList[0].information).length === 0) {
            return false
        } else {
            return true
        }

    }


    //#region Efecto para evaluar cuando está toda la data en EditData, cuando ya está lista hace el dispatch.
    useEffect(() => {

        if (
            validateSalesTeamsList() && //Que sales teams esté lleno.
            generalInformationInfo != null &&
            clientInfo != null &&
            LDRSAreReady && //Verifica si en MainTableAutopp agregó todos los LDRS si es el caso.
            readyToSend

        ) { //Esta condición es para evaluar si general information ha sido llenado.

            
            let asign =
            {
                ["SalesTeamsList"]: salesTeamsList,
                ["BawItemsList"]: bawItemsList
            };

            Object.assign(editData, generalInformationInfo)
            Object.assign(editData, clientInfo)
            Object.assign(editData, asign)

            setReadyToSend(false);
            setLoad(true);


            dispatch(newRequest({ info: editData, fileListBOM: fileListBOM, temporalFolderFilesId: temporalFolderFilesId, type: "1" })).then((resp) => {
                setLoad(false);
                setReloadTableRequests(true);
                const { payload } = resp;
                if (payload.status === 200) {
                    cleanForms();
                    Toast.fire({
                        title: "Atención",
                        html: `Gestión #${payload.data.payload.id} enviada, el Databot pronto enviará el resultado por Webex Teams!`,
                        type: 'success'
                    });


                } else {

                    console.log(payload.message);

                    setEditData({ "costumers": { "country": "", "name": "" }, "userName": userName, "LDRS": "", "initialDate": DateNowFormatToLabel() })
                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrió un error, por favor reinicie la aplicación, si persiste contacte a Application Management.",
                        type: 'warning'
                    });
                };
            })

        }
    }, [editData, /*readyToSend*/, generalInformationInfo, clientInfo, LDRSAreReady])
    //#endregion

    //#region Método para validar si los campos están de todos los forms están vacíos o no.
    const validateForms = () => {

        let contQuantityFieldsLDRMeetings = 0;


        let cont = 0;
        //#region Valida los Typeahead       
        const forms = document.getElementsByClassName("rbt-input-main form-control rbt-input");

        Array.from(forms).forEach((input) => {
            if (input.defaultValue === "") {
                input.setAttribute("style", "border-color:#fb6340;")
                cont++
            } else { input.removeAttribute("style") }

        })
        //#endregion

        //#region Valida los campos input y selects normales

        const forms2 = document.querySelectorAll(".formInfo input,Select, textarea")

        Array.from(forms2).forEach((input) => {
            //Valide todos menos los LDRS.

            if (input.name !== "LDR" && input.required === true) {
                //console.log(input);
                //
                if (input.id === "finalDate" && (input.value < new Date().toISOString().split('T')[0])) {


                    //console.log("Final date incorrecta." + input.value)
                    input.classList.add("is-invalid");
                    cont++;

                } else if (input.value === "") {
                    //console.log(input)
                    input.classList.add("is-invalid");
                    cont++;
                }
                else {
                    input.classList.remove("is-invalid");
                }
            } else {
                //Para que solo ponga en alerta los LDR seleccionados y no los de segundo plano.
                if (/*toggleCheck[input.id] && */input.required === true) {
                    //console.log(input)
                    //
                    if (input.value === "") {
                        input.classList.add("is-invalid");
                        cont++;
                    }
                    else {
                        input.classList.remove("is-invalid");
                    }


                }

                //debugger;
                //Para la excepción de LDR de Meetings, donde todos los campos son opcionales, pero debe haber al menos uno lleno.
                if (input.title === "Meetings") {
                    if (input.value !== "") {
                        contQuantityFieldsLDRMeetings++;
                    }
                }
            }
        });

        //#endregion
        //Para la excepción LDR Meetings.
        //setQuantityLDRMeetingsResp(contQuantityFieldsLDRMeetings);

        //Return principal.
        return { cont, contQuantityFieldsLDRMeetings };

    }

    //#endregion

    //#region Valida si debe aportar archivos a BOM
    const validateBOMFiles = () => {

        if (toggleCheck.applyBOMPNOption === true) {

            if (fileListBOM.fileList.length === 0) {
                Toast.fire({
                    title: "Atención",
                    html: "Debe adjuntar documentos tipo Cartel de Licitación / RFP / RFI / BOM.",
                    type: 'warning'
                });
                return false;
            }

            return true;
        }

        return true;
    }
    //#endregion

    //#region Valida si hay al mnenos un toggle check LDR seleccionado en caso que se indique que aplica LDR.
    const validateLDRQuantity = () => {


        if (toggleCheck.applyLDROption === true && countLDRActives === 0
        ) {

            if (fileListBOM.fileList.length === 0) {
                Toast.fire({
                    title: "Atención",
                    html: "Debe adjuntar al menos un LDR en la gestión.",
                    type: 'warning'
                });
                return false;
            }

            return true;
        }

        return true;
    }
    //#endregion

    //#region Limpia todos los campos de todos los forms y lo pone default.
    const cleanForms = () => {

        const forms = document.querySelectorAll(".formInfo input,Select, textarea");

        //reset campos normales
        Array.from(forms).forEach(input => (input.value = ""));


        setFormSelected("1");
        setReadyToSend(false);
        setEditData({ "costumers": { "country": "", "name": "" }, "userName": userName, "LDRS": "", "initialDate": DateNowFormatToLabel() })

        setResetSpecificStates(true); //Efecto en generalInformation y en LDRSMainForm
        setCleanTypeaheads(true); //Efecto en ClientForm
        setFileListBOM({ fileList: [] }); //Limpiar los archivos subidos BOM

        resetInfo()


        handleResetSalesTeams()
        handleResetBaw()
        setLDRSAreReady(false)


        setCountLDRActives(0)
    }
    //#endregion





    return { handleSendNewRequest }


}


