/*!

=========================================================
*MasterData - MyRequestsTableMasterData
=========================================================

* Este componente contiene una tabla con todas las gestiones de 
*datos maestros dependiendo de su Rol, actúa de una forma 
*server side debido a la gran cantidad de información que contiene
las solicitudes de datos maestros.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Imports de librerías de react.
import React, { useState, useEffect } from 'react'
import {
  PaginationItem,
  PaginationLink,
  Pagination,
  CardHeader,
  FormGroup,
  Button,
  Input,
  Table,
  Badge,
  Card,
  Col,
  Row
} from "reactstrap";

import Select from "react-select";

import CardFooter from 'reactstrap/lib/CardFooter';

//Multiselect
import { MultiSelect } from "react-multi-select-component";

// imports de actions
import {
  getDataAllRows,
  getQuantityAllRows,
  updateStatus,
  getOptions,
  getEmployees
} from 'actions/masterData';

//Import de Spinner
import { Spin } from "antd";

//Import para consulta  en DB
import { useDispatch } from 'react-redux';

//Funciones para obtener el user actaul
import { getUsernameLogged, getTeamsUserAccess } from 'selectors/adminLayout';

// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import { desencrypt } from 'helpers/desencrypt.jsx';

export const AllRequestsTableMasterData = ({
  setModalDetail, options, setOptions, employees, setEmployees
}) => {

  const MySwal = withReactContent(Swal);
  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  //Declaración de disparador para el api
  const dispatch = useDispatch();

  //#region Establecer arreglo de columnas
  //Columnas para el multicolumn y si la seleccionan se agrega al columnsSelectedTableRequests
  const optionsRequestsSelect = [

    { value: "seeDetail", label: "Botón", disabled: true },
    { value: "Gestion", label: "Gestión" },
    { value: "Formulario", label: "Proceso", disabled: true },
    { value: "Fecha", label: "Fecha" },
    { value: "createdBy", label: "Solicitante" },
    { value: "typeOfManagementSingle", label: "Operación" },
    { value: "Estado", label: "Estado" },
    { value: "Factor", label: "Factor" },
    { value: "Aprobadores", label: "Aprobadores" },
    { value: "Respuesta", label: "Respuesta" },
    { value: "method", label: "Método" },
    { value: "comment", label: "Comentario" },
    { value: "commentApproval", label: "Comentario Aprobador" },
    { value: "createdBy", label: "Creado por" },
    { value: "approvedBy", label: "Aprobado por" },
    { value: "finalizationAt", label: "Finalizado en" },
    { value: "approvallAt", label: "Aprobado en" },
    { value: "createdAt", label: "Creado en" },

  ];

  //Columnas seleccionadas por defecto en la tabla.
  const [columnsSelectedTableRequests, setColumnsSelectedTableRequests] = useState(
    [

      { value: "seeDetail", label: "Botón", id: "", width: 70 },
      { value: "Gestion", label: "Gestión", id: "", width: 60 },
      { value: "Formulario", label: "Formulario", id: "", width: 90 },
      { value: "Fecha", label: "Fecha", id: "", width: 70 },
      { value: "Factor", label: "Factor", id: "", width: 70 },
      { value: "Estado", label: "Estado", id: "", width: 90 },
      { value: "Aprobadores", label: "Aprobadores", id: "", width: 150 },
      { value: "Respuesta", label: "Respuesta", id: "", width: 150 },
    ]
  );

  //#endregion 

  //#region Funciones para aplicar flitros a la tabla

  //Filtros request tables
  const [filters, setFilters] = useState([]);

  //State para almacenar los valores a buscar y cuando realice el dispatch de petición el API lo filtre según este state.
  const [valuesToSearch, setValuesToSearch] = useState([])


  //Capturar los valores de los inputs de los filtros
  const handleOnfilterDynamic = (id, value) => {
    // console.log(constant, e)
    // setFilters(prevState => ({
    //   ...prevState,
    //   [constant]: e
    // }))

    setValuesToSearch(valuesToSearch => {
      return {
        ...valuesToSearch,
        [id]: value,
      }
    })
  }

  useEffect(() => {
    console.log("values", valuesToSearch)
  }, [valuesToSearch])


  //Aplicar los filtros a la data de la tabla
  const renderRowsByFilters = (rows) => {

    if (Object.keys(filters).length === 0) {
      return rows
    } else {
      const filterKeys = Object.keys(filters);

      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();

        rows = rows.filter(

          (item) => {
            let temp;

            try {
              temp = item[element]?.toString().toLowerCase().indexOf(valueFiltered);
            } catch (e) { temp = -1 }

            //Si se le da un espacio en el campo, imprima tdoos los vacios
            if (valueFiltered === " ") {
              return temp === undefined
            } else { //No es un espacio, traiga todos los de la palabra clave
              return temp != -1 && temp != undefined
            }

          }
        )
      }
      return rows
    }
  }


  //#endregion 

  //#region Extraer las filas de la tabla 

  //state para refrescar la tabla de Requests
  const [reloadTableRequests, setReloadTableRequests] = useState(false);

  const [rowsRequests, setRowsRequests] = useState({
    rows: [],
    statsBaw: [],
    length: 5,
  })

  //verifica si es rol admin
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const userRol = getTeamsUserAccess();
    if (userRol.some((row) => row.indexOf("Master Data Admin") !== -1)) {
      setIsAdmin(true)
    }
  }, [])

  //Función para poner en mayúscula cada letra de cada palabra y lo demás en minúscula.
  const capitalizeEachWord = (str) => {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
  }


  //Handle para cambiar de estatus 
  const handleChangeStatus = (row) => {

    if (isAdmin) {

      let html = `
    <h2>Nuevo estado:</h2>

    <select id="status" class="form-control">
      <option value=""></option>
    `
      options && options.status.map(status => {
        html += `
      <option value="${status.value}">${capitalizeEachWord(status.label)}</option>      
      `
      })

      html += `  
    </select>

    <h2>Respuesta del robot:</h2>
    <textarea class="form-control" rows="3" id="response" placeholder="Respuesta..."></textarea>'`

      MySwal.fire({

        type: 'info',
        title: `Solicitud: ${row.Gestion}`,
        html: html,

        confirmButtonText: 'Si, cambiar',
        confirmButtonColor: '#2DCE89',
        cancelButtonText: 'No, cancelar',
        showCancelButton: true,

        preConfirm: () => {

          const newInfo = {
            id: row.Gestion,
            newStatus: document.getElementById('status').value,
            response: document.getElementById('response').value
          }
          console.log(newInfo);

          //Validación de si está lleno el status.
          if (!newInfo.newStatus) {
            Swal.showValidationMessage('Por favor seleccione un estado');
            return false;
          }

          dispatch(updateStatus({ newInfo })).then((resp) => {

            const { payload } = resp;
            console.log(payload)
            if (payload.status === 200) {
              Toast.fire({
                title: "Atención",
                html: payload.data.message,
                type: "success",
              });

            } else {
              Toast.fire({
                title: "Atención",
                html: payload.data.message,
                type: "warning",
              });

            }

            setReloadTableRequests(!reloadTableRequests);
          })


        }

      })
    }

  }

  //Cuando reloadTableRequests es true, ejecuta este efecto 
  useEffect(() => {
    if (reloadTableRequests) {

      const getDataR = async () => {
        //Filas.
        await getData(start, sizePerPage/*Quantity*/, [] /*valuesToSearch*/);

        setValuesToSearch([])
        setPage(1);
        //Cantidad.
        await getQuantityData([]);

        //Options
        if (options.length == 0) {
          await getOptionsD();
        }
        //Employees
        if (employees.employeesOptions != {}) {
          await getEmployeesOptions();

        }


      }
      getDataR();
    }
  }, [reloadTableRequests]);


  //Traer las filas de la tabla de mis gestiones en base al usuario.
  const getData = async (start, quantity, valuesToSearch) => {

    const resp = await dispatch(getDataAllRows({ start: start, quantity: quantity, valuesToSearch: valuesToSearch})).then((resp) => {

      const { payload } = resp;
      //console.log(payload)
      if (payload.status === 200) {
        console.log(payload.data.payload.rows);
        setRowsRequests((e) => ({
          ...e,
          rows: payload.data.payload.rows,
          // length: payload.data.payload.rowsRequests.length,
        }))

      } else {
        setRowsRequests({
          rows: [],
          length: 0,
        })

      }

      setReloadTableRequests(false);
      setPaginationSpinner(null)
      setSpinnerSearch(false);
    })
  }

  //Traer las filas de la tabla de mis gestiones en base al usuario.
  const getQuantityData = async (valuesToSearch) => {

    const resp = await dispatch(getQuantityAllRows({ valuesToSearch: valuesToSearch })).then((resp) => {

      const { payload } = resp;
      //console.log(payload)
      if (payload.status === 200) {
        console.log(payload.data.payload.count);
        setRowsRequests((e) => ({
          ...e,
          length: payload.data.payload.count,
        }))

      } else {
        setRowsRequests({
          rows: [],
          length: 0,
        })

      }

      // setReloadTableRequests(false);
    })
  }

  //Trae las opciones de los dropdowns
  const getOptionsD = async () => {

    const resp = dispatch(getOptions()).then((resp) => {

      const { payload } = resp;
      if (payload.status === 200) {
        console.log(payload.data.payload.masterData)

        setOptions(payload.data.payload.masterData)

      } else {
        console.log(payload)
      }

    })
  }

  //trae los empleados de GBM de último porque son muy pesados
  const getEmployeesOptions = async () => {
    //console.log("aqui employees")
    setEmployees({
      employeesOptions: {},
      loadingEmployees: true
    });

    const resp = dispatch(getEmployees()).then((resp) => {
      const { payload } = resp;
      //console.log(payload)
      if (payload.status === 200) {

        setEmployees({
          employeesOptions: JSON.parse(desencrypt(payload.data.payload.employees)),
          loadingEmployees: false
        })
      } else {
        console.log("Ocurrió un error al extraer los empleados: " + payload)
        setEmployees({
          employeesOptions: {},
          loadingEmployees: false
        });
      }
    })
  }

  //#endregion

  //#region Realizar paginations en la tabla

  const [start, setStart] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10)
  const rows = renderRowsByFilters(rowsRequests.rows);

  //state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);

  const paginations = rowsRequests.length > sizePerPage ?
    rowsRequests.length / sizePerPage > Math.round(rowsRequests.length / sizePerPage) ?
      Math.round(rowsRequests.length / sizePerPage) + 1
      : Math.round(rowsRequests.length / sizePerPage)
    : 1

  const [paginationSpinner, setPaginationSpinner] = useState(1);

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <Spin size="small" spinning={paginationSpinner == i ? true : false}>
          <PaginationItem className={page === i ? "active" : ""} key={i}>
            <PaginationLink
              onClick={() => handleOnSetPage(i, "page")}
            >
              {i}
            </PaginationLink>
          </PaginationItem>
        </Spin>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  //Establece el número de filas por pagina 
  const handleOnSetPage = (page) => {
    setPage(page);
    setPaginationSpinner(page)

    getData((page - 1) * sizePerPage, sizePerPage/*Quantity*/, valuesToSearch);

  };

  const handleSetNumPag = (e) => {
    setPage(1);
    const value = e.target.value;
    setSizePerPage(value);

    getData(0, value/*Quantity*/, valuesToSearch);
    setPaginationSpinner(1);



  }

  //#endregion

  //#region Funciones y efectos para abrir el modal y recarga las filas

  //Función para abrir el modal de ver el status de BAW por gestión.
  const handleOpenDetailModal = (row) => {
    setModalDetail({
      showDetailModal: true,
      view: "requests",
      row: row
    })
  }

  //Efecto para cargar filas cuando arranque por primera vez.
  useEffect(() => {
    setReloadTableRequests(true);
  }, [])

  //#endregion

  const handelLabel = (val, array) => {

    if (val !== null && val !== undefined && array !== null && array !== undefined) {
      array.forEach(element => {
        if (element.value === val) {

          const valDefault = {
            label: element.label,
            value: val
          }
          return valDefault;

        }
      });
    }
  }

  //Spinner al dar el botón de buscar
  const [spinnerSearch, setSpinnerSearch] = useState(false);

  //Función para buscar valor en la base de datos.
  const handleToSearch = () => {
    const getDataR = async () => {
      setSpinnerSearch(true);
      //Filas.
      await getData(start, sizePerPage/*Quantity*/, valuesToSearch);
      //Cantidad.
      await getQuantityData(valuesToSearch);

      //Asignar la página
      setPage(1);

    }
    getDataR();
  }

  console.log("AllRequestDM");

  useEffect(() => {
    console.log("rowsRequests", rowsRequests)
  }, [rowsRequests])


  return (
    <>


      <Card className=" ">



        <CardHeader className="bg-transparent border-0">

          <Row className="d-flex align-items-center">
            <Col xs="7" sm="auto">
              <div className="d-flex align-items-center">
                <Col xs="auto" sm="7" className="bg-transparent border-0">
                  <h3 className="mb-0">Gestiones</h3>
                </Col>
                <Col xs="auto" sm="auto">
                  <MultiSelect
                    size="sm"
                    id="multiselectFilter"
                    options={optionsRequestsSelect}
                    value={columnsSelectedTableRequests}
                    onChange={setColumnsSelectedTableRequests}
                    labelledBy="Select"
                  />
                </Col>
              </div>
            </Col>

            <Col xs="auto" sm="auto" className="ml-auto">
              <Spin size="small" spinning={spinnerSearch}>
                <Button
                  id="searchBtn"
                  className="btn-success"
                  size="sm"
                  block
                  type="button"
                  style={{ width: "90px", height: "30px" }}
                  onClick={(e) => handleToSearch()}
                >
                  <span className="btn-inner--icon mr-">
                    <i className="fas fa-search" />
                  </span>
                  {" "}
                  <span className="btn-inner--text">Buscar</span>
                </Button>
              </Spin>
            </Col>

            <Col xs="auto" sm="auto">
              <Button
                id="refreshBtn"
                className="btn-info"
                size="sm"
                block
                type="button"
                style={{ width: "90px", height: "30px" }}
                onClick={(e) => setReloadTableRequests(true)}
              >
                <span className="btn-inner--icon mr-">
                  <i className="fas fa-sync-alt" />
                </span>
                {" "}
                <span className="btn-inner--text">Refrescar</span>
              </Button>
            </Col>
          </Row>


        </CardHeader>


        <Spin size="large" spinning={reloadTableRequests || (options.length == 0 && isAdmin)}>
          <Table
            className="align-items-center  table-flush"
            responsive
            striped={true}
            hover={true}
          >
            <thead className="thead-light">

              <tr>
                {
                  columnsSelectedTableRequests.map((item, key) => {
                    return (
                      item.value !== "seeDetail" ?

                        <th key={key} className="justify-content-md-center">

                          <div style={{ width: item.width }}>
                            <FormGroup key={key}>
                              <label
                                style={{ fontSize: "12px" }}
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                {`${item.label}:`}
                              </label>
                              <Input
                                id={item.id}
                                key={key}
                                className="form-control"
                                type="text"
                                value={valuesToSearch[item.value] ? valuesToSearch[item.value] : ""}
                                placeholder={`Buscar ${item.label}...`}
                                onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                              />
                            </FormGroup>
                          </div>
                        </th>
                        :
                        <th key={key} className="justify-content-md-center">

                        </th>


                    )
                  })

                }
              </tr>
            </thead>
            <tbody className='list'>

              {rowsRequests.length > 0 ?

                rows.map((row, key) => {
                  return (
                    <tr key={key}>
                      {
                        columnsSelectedTableRequests.map((col, keyCol) => {
                          return (
                            col.value === "Estado" ? (
                              <td key={key + keyCol} className="justify-content-md-center">
                                <div style={{ width: (isAdmin) ? 220 : col.width }}>
                                  {
                                    // isAdmin ?
                                    //   <Select
                                    //     onChange={(e) => handleChangeStatus(e, row)}
                                    //     id="changeStatus"
                                    //     name="changeStatus"
                                    //     required={false}
                                    //     options={
                                    //       optionsDropdowns &&
                                    //       optionsDropdowns["status"]
                                    //     }
                                    //     defaultValue={{ label: row.Estado, value: row.statusId }}//{handelLabel(row.value, optionsDropdowns["status"])}
                                    //     className="basic-single"
                                    //     theme={(theme) => ({
                                    //       ...theme,
                                    //       borderRadius: 0,
                                    //       colors: {
                                    //         ...theme.colors,
                                    //         text: 'black',
                                    //         primary25: 'orange',
                                    //         primary: 'green',
                                    //       },
                                    //     })}
                                    //   />
                                    // :
                                    <Badge key={key + keyCol} color={row.statusType} pill onClick={(e) => handleChangeStatus(row)}>
                                      {/* {row.Estado} */}

                                      {
                                        isAdmin &&
                                        <span className="btn-inner--icon mr-">
                                          <i className="fas fa-edit" />
                                        </span>
                                      }

                                      <span className="btn-inner--text">
                                        {" "} {row.Estado}
                                      </span>
                                    </Badge>
                                  }

                                </div>
                              </td>
                            )
                              //  :
                              // col.value === "Respuesta" ? (
                              //   <td key={key + keyCol} className="justify-content-md-center">

                              //     <div style={{ width: col.width }}>
                              //       <Input
                              //         key={key + keyCol}
                              //         id={key + keyCol}
                              //         className="form-control text-dark"
                              //         type="textarea"
                              //         background-color="white"
                              //         readOnly={true}
                              //         value={row.Respuesta}
                              //       />
                              //     </div>
                              //   </td>
                              // )

                              : col.value === "seeDetail" ? ( //Este status es para el botón de detalle



                                <td key={key + keyCol} className="justify-content-md-center">
                                  <div style={{ width: col.width }}>
                                    <Row key={key + keyCol + "row"}>
                                      {row.statusId !== "1" && //No lo muestre cuando apenas se está generando la opp


                                        <Col key={key + keyCol + "col"}>

                                          <Button id="refreshBtn" className="btn-default" size="sm" block type="button" style={{ width: "80px ", height: "30px" }}
                                            onClick={(e) => handleOpenDetailModal(row)}
                                          >
                                            <span className="btn-inner--icon mr-">
                                              <i className="fas fa-edit" />
                                            </span>
                                            <span className="btn-inner--text">
                                              {" "} Detalle
                                            </span>
                                          </Button>
                                        </Col>
                                      }

                                    </Row>
                                  </div>
                                </td>

                              ) : (

                                <td key={key + keyCol} className="text-center text-break" style={{ whiteSpace: "normal", textAlign: "auto" }}>
                                  <div style={{ width: col.width }} >

                                    <span className="btn-inner--text">
                                      {row[col.value]}
                                    </span>

                                  </div>
                                </td>

                              )
                          )
                        })
                      }

                    </tr>

                  )
                })
                :
                <>
                  <tr>
                    <td className="justify-content-md-center">
                      Sin resultados encontrados
                    </td>
                  </tr>
                </>
              }

            </tbody>




          </Table>
        </Spin>
        <CardFooter>

          <Row className="align-items-center ">
            <Col xs='12' md='6' className="p-1 ">
              <Row className="justify-content-start">
                <Col xs='12' md='6'>
                  <span className='pagination mb-0'>
                    Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rowsRequests.length ? rowsRequests.length : page * sizePerPage} de {rowsRequests.length} resultados
                  </span>
                </Col>
                <Col xs='12' md='2'>
                  <Input type="select"
                    value={sizePerPage}
                    onChange={(e) => handleSetNumPag(e)}>
                    <option>5</option>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>1000</option>
                  </Input>
                </Col>
              </Row>
            </Col>

            <Col xs='12' md='6' className="p-1 ">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >

                <PaginationItem className={page === 1 ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {
                  renderPaginations()
                }
                <PaginationItem className={page === paginations ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>

        </CardFooter>

      </Card>

    </>
  )
}
