//Imports de React
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Row, Col } from "reactstrap";

import { useDispatch } from 'react-redux';


// imports de Alerts y  Spin
import { Spin } from 'antd';

import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
//import { MyRequestsTableMasterData } from 'components/NewMasterData/MyRequests/MyRequestsTableMasterData.jsx';

// imports de actions
import {
    getOptions,
    getEmployees
} from 'actions/masterData';
import { RequestDetailModal } from 'components/NewMasterData/SharedComponents/RequestDetailModal';
import { MyRequestsTableMasterData } from 'components/NewMasterData/MyRequests/MyRequestsTableMasterData';
import { IndividualFormModal } from 'components/NewMasterData/SharedComponents/IndividualFormModal';

import { desencrypt } from 'helpers/desencrypt.jsx';

const MyRequestsMasterData = () => {

    // Función para traer solicitudes de la DB
    const dispatch = useDispatch();

    //State para establecer los datos del modal de Detalle de Gestión de mis gestiones.
    const [modalDetail, setModalDetail] = useState({
        //length: 0,
        showDetailModal: false
    })

    const [options, setOptions] = useState([]);

    useEffect(() => {
        getOptionsD()
    }, [])

    //Trae las opciones de los dropdowns
    const getOptionsD = async () => {

        dispatch(getOptions()).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
                console.log(payload.data.payload.masterData)

                setOptions(payload.data.payload.masterData)

            } else {
                console.log(payload)
            }

            getEmployeesOptions()

            //setReloadTableRequests(false);
            //setLoad(false);
        })
    }


    //#region Extraer los nombres de los empleados del API
    const [employees, setEmployees] = useState({
        employeesOptions: {},
        loadingEmployees: false
    });
    const { employeesOptions, loadingEmployees } = employees;



    //trae los empleados de GBM de último porque son muy pesados
    const getEmployeesOptions = async () => {
        //console.log("aqui employees")
        setEmployees({
            employeesOptions: {},
            loadingEmployees: true
        });

        dispatch(getEmployees()).then((resp) => {
            const { payload } = resp;
            //console.log(payload)
            if (payload.status === 200) {

                setEmployees({
                    employeesOptions: JSON.parse(desencrypt(payload.data.payload.employees)),
                    loadingEmployees: false
                })
            } else {
                console.log("Ocurrió un error al extraer los empleados: " + payload)
                setEmployees({
                    employeesOptions: {},
                    loadingEmployees: false
                });
            }
        })
    }

    //#endregion 


    //State para el formularion individual tipo lineal.
    const [individualFormModal, setIndividualFormModal] = useState({
        showModal: false,
        view: "",
        row: "",
        formName: "",
        newInfo: { info: {}, updateInfo: false }
    })

    //Arreglo grande
    const [stateModalsIndvForms, setStateModalsIndvForms] = useState([])





    return (
        <>
            {/* <RequestDetailModal
            options={options}
                modalDetail={modalDetail}
                setModalDetail={setModalDetail}
            >

            </RequestDetailModal> */}

            <RequestDetailModal
                options={options}
                modalDetail={modalDetail}
                setModalDetail={setModalDetail}
                //setReloadTableRequests={setReloadTableRequests}

                //Para poder abrir desde este modal el formulario individual
                individualFormModal={individualFormModal}
                setIndividualFormModal={setIndividualFormModal}


                stateModalsIndvForms={stateModalsIndvForms}
                setStateModalsIndvForms={setStateModalsIndvForms}
            >

            </RequestDetailModal>

            {/* <IndividualFormModal
            options={options}
            individualFormModal={individualFormModal}
            setIndividualFormModal={setIndividualFormModal}
            //setReloadTableRequests={setReloadTableRequests}


            > 

            </IndividualFormModal>*/}

            {
                stateModalsIndvForms.length > 0 &&
                stateModalsIndvForms.map((modal) => (
                    <IndividualFormModal
                        options={options}
                        individualFormModal={modal}
                        setIndividualFormModal={setIndividualFormModal}

                        stateModalsIndvForms={stateModalsIndvForms}
                        setStateModalsIndvForms={setStateModalsIndvForms}

                        employeesOptions={employeesOptions}
                    //setReloadTableRequests={setReloadTableRequests}


                    >

                    </IndividualFormModal>
                )
                )
            }


            <AdminHeader name="Mis Gestiones" parentName="Datos Maestros" />
            <Spin size="large" spinning={false}>
                <Container className="mt--6" fluid>
                    <Card>
                        <CardBody>
                            {/* <Row> */}
                                <MyRequestsTableMasterData
                                    setModalDetail={setModalDetail}
                                ></MyRequestsTableMasterData>
                            {/* </Row> */}
                        </CardBody>
                    </Card>
                </Container>
            </Spin>
        </>
    );
};



export default MyRequestsMasterData;