import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import Select2 from 'react-select2-wrapper'
import { Card, CardHeader, CardBody, Col, Row, Button } from 'reactstrap'
import { reportHistory } from 'actions/contacts.jsx'
import { useDispatch } from 'react-redux'
import { message } from 'antd';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const YearChart = ({ years, yearsInfo, isManager, selectedData }) => {
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState([
    { id: '0', text: 'No hay información por el momento' }
  ])
  const dispatch = useDispatch()
  const [currentYear, setCurrentYear] = useState(null)
  const [data, setData] = useState()
  const [graph, setGraph] = useState([])
  const [showManager, setShowManager] = useState(false)

  useEffect(() => {
    if (
      yearsInfo !== null &&
      yearsInfo !== undefined &&
      years !== null &&
      years !== undefined
    ) {
      setData(yearsInfo)
      let filterSelect = []
      filterSelect.push({ id: ``, text: `Seleccione el año` })
      years.forEach(x => {
        filterSelect.push({ id: `${x}`, text: `Información ${x}` })
      })
      setFilter(filterSelect)
      setLoading(false)
    }
  }, [yearsInfo, years])

  useEffect(() => {
    if (isManager === true) {
      setShowManager(true)
      setLoading(false)
    }
  }, [isManager])

  useEffect(() => {
    if (
      selectedData !== null &&
      selectedData !== undefined &&
      selectedData.length > 0
    ) {
      setGraph(selectedData)
      setLoading(false)
    } else {
      setGraph([])
    }
  }, [selectedData])

  console.log(selectedData)

  const handleChange = event => {
    if (
      event.target.value !== undefined &&
      event.target.value !== null &&
      event.target.value !== ''
    ) {
      let dSets = null
      let dFliter = data.filter(x => x.year == event.target.value)
      //   backgroundColorconsole.log(dFliter)
      dSets = [
        {
          label: 'Creaciones',
          data: dFliter[0].data.create,
          borderColor: colors.theme['success'],
          backgroundColor: colors.theme['success'],
          fill: false
        },
        {
          label: 'Modificaciones',
          data: dFliter[0].data.modify,
          borderColor: colors.theme['info'],
          backgroundColor: colors.theme['info'],
          fill: false
        }
      ]
      setCurrentYear(event.target.value)
      setGraph(dSets)
    }
  }

  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: false,
    timer: 7000,
    showConfirmButton: true
  })
  const colors = {
    gray: {
      100: '#f6f9fc',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#8898aa',
      700: '#525f7f',
      800: '#32325d',
      900: '#212529'
    },
    theme: {
      default: '#172b4d',
      primary: '#5e72e4',
      secondary: '#f4f5f7',
      info: '#11cdef',
      success: '#2dce89',
      danger: '#f5365c',
      warning: '#fb6340'
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent'
  }
  const downloadFile = () => {

    setLoading(true)
    const getInfo = async () => {

      dispatch(
        reportHistory()
      ).then(response => {
        const { payload } = response
        if (payload.isAxiosError === false) {
          Toast.fire({
            title: `Error de conexión, no se logró conectar al servidor`,
            type: 'error'
          })
        } else if (payload.status === 200) {
          const message = payload.data.payload.message
          Toast.fire({
            title: message,
            type: 'success'
          })
        } else {
          Toast.fire({
            title: `Error de conexión, no se logró conectar al servidor`,
            type: 'error'
          })
        }
        setLoading(false)
      })
    }

    const getData = async () => {
      await getInfo()
    }
    getData()
  }
  return (
    <>
      {!loading && (
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h6 className='surtitle'>Overview</h6>
                <h5 className='h3 mb-0'>Uso anual</h5>
              </Col>
              <Col>
                <Row className="justify-content-end mb-3" style={{ marginRight: '2px' }}>
                  <Button color="primary" onClick={() => downloadFile("Lista de clientes")} >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-download" />
                    </span>Exportar
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg='8'></Col>
              <Col lg='4'>
                {!showManager && (
                  <Select2
                    className='form-control text-end float-right'
                    id='year'
                    options={{
                      placeholder: 'Visualizar informacion anual'
                    }}
                    data={filter}
                    value={currentYear}
                    onChange={handleChange}
                  />
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className='chart'>
              <Line
                data={{
                  labels: [
                    'Ene',
                    'Feb',
                    'Mar',
                    'Abr',
                    'May',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Set',
                    'Oct',
                    'Nov',
                    'Dic'
                  ],
                  datasets: graph
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  defaultColor: '#8898aa',
                  defaultFontFamily: 'Open Sans',
                  defaultFontSize: 13,
                  layout: {
                    padding: 0
                  },
                  legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                      usePointStyle: true,
                      padding: 16
                    }
                  },
                  elements: {
                    line: {
                      tension: 0.4,
                      borderWidth: 4,
                      //   borderColor: colors.theme['primary'],
                      //   backgroundColor: colors.transparent,
                      borderCapStyle: 'rounded'
                    }
                  },
                  tooltips: {
                    enabled: true,
                    mode: 'index',
                    intersect: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: '#e9ecef',
                          zeroLineColor: '#e9ecef'
                        },
                        ticks: {}
                      }
                    ]
                  }
                }}
                id='chart-sales'
                className='chart-canvas'
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default YearChart
