// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const EditUserModal = props => {

  const {
    message,
    loading,
    modalOptions: {
      type,
      options,
      showModal,
      initialValues,
    },
    toggleModal,
    onEditUser,
  } = props;

  const validate = values => {
    const errors = {};
    if (!values.idUser || values.idUser === "0")
      errors.idUser = "Debes ingresar el id del colaborador.";
    if (!values.username || values.username === "")
      errors.username = "Debes ingresar el nombre de usuario.";
    if (!values.name || values.name === "")
      errors.name = "Debes ingresar el nombre completo.";
    if (!values.email || values.email === "")
      errors.email = "Debes ingresar el correo electrónico.";
    if (!values.coeID || values.coeID === "0")
      errors.coeID = "Debes seleccionar un COE.";
    if (!values.equipID || values.equipID === "0")
      errors.equipID = "Debes seleccionar un Equipo.";
    if (!values.countryID || values.countryID === "0")
      errors.countryID = "Debes seleccionar un País.";
    if (!values.espID || values.espID === "0")
      errors.espID = "Debes seleccionar una Especialidad.";
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>Edición de Usuario EMA</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={(e) => onEditUser(e, type)}
              validate={validate}
              initialValues={initialValues}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    <div className="form-row align-items-center">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="idUser">ID Colaborador</Label>
                          <Field name="idUser">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="number"
                                  placeholder="Ingrese el número de colaborador"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="username">Nombre Usuario</Label>
                          <Field name="username">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese el nombre de usuario"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="name">Nombre Completo</Label>
                          <Field name="name">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="text"
                                  placeholder="Ingrese el nombre completo"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="email">Correo Electrónico</Label>
                          <Field name="email">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="email"
                                  placeholder="Ingrese el correo electrónico"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="email2">Correo Electrónico Secundario</Label>
                          <Field name="email2">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="email"
                                  placeholder="Ingrese el correo electrónico secundario"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                />
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="coeID">COE</Label>
                          <Field name="coeID">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">Seleccione un COE</option>
                                  {
                                    options.coes.map((row, key) => {
                                      return (
                                        <option key={key} value={row.id}>{row.name}</option>
                                      )
                                    })
                                  }
                                </Input>
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="equipID">Equipo</Label>
                          <Field name="equipID">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">Seleccione un Equipo</option>
                                  {
                                    options.equipments.map((row, key) => {
                                      return (
                                        <option key={key} value={row.id}>{row.name}</option>
                                      )
                                    })
                                  }
                                </Input>
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="countryID">País</Label>
                          <Field name="countryID">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">Seleccione un País</option>
                                  {
                                    options.countries.map((row, key) => {
                                      return (
                                        <option key={key} value={row.id}>{row.name}</option>
                                      )
                                    })
                                  }
                                </Input>
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup>
                          <Label className="form-control-label" for="espID">Especialidad</Label>
                          <Field name="espID">
                            {({ input, meta }) => (
                              <div>
                                <Input
                                  {...input}
                                  type="select"
                                  valid={!meta.error && meta.modified && meta.touched}
                                  invalid={meta.error && meta.touched}
                                >
                                  <option key="0" value="0">Seleccione una Especialidad</option>
                                  {
                                    options.specialities.map((row, key) => {
                                      return (
                                        <option key={key} value={row.id}>{row.name}</option>
                                      )
                                    })
                                  }
                                </Input>
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row align-items-center justify-content-center">
                      <Col className="mb-1" sm="12" md="4">
                        <FormGroup className="d-flex align-items-center justify-content-center">
                          <Field name="lider" type="checkbox">
                            {({ input, meta }) => (
                              <Label>
                                <Input
                                  {...input}
                                  type="checkbox"
                                  className="form-control-label"
                                  invalid={meta.error && meta.touched}
                                />{" "}
                                 Líder COE
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }
                              </Label>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="success"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Actualizar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

EditUserModal.propTypes = {
  showModal: PropTypes.bool,
};

export default EditUserModal;