// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// react library
import React, { useState } from 'react';
// reactstrap components
import { CardFooter, CardBody, Col, Pagination, PaginationItem, PaginationLink, Row, Table, Input, FormGroup } from "reactstrap";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux';
import { getFilesByCustomer } from 'actions/DocumentSystem.jsx';

moment.locale("es");

const MainTable = ({
    rows,
    setEditRow,
    status,
    page,
    setPage,
    columnas,
    filters,
    setRealTable,
    discountType
}) => {
    console.log(rows)
    const dispatch = useDispatch();

    //#region Notificaciones
    const MySwal = withReactContent(Swal)

    const Toast = MySwal.mixin({
        toast: false,
        showConfirmButton: true,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    //#endregion

    //#region State Principal (columnas, paginas, etc)
    const [state, setState] = useState({


        sizePerPage: 5,
    });
    const { sizePerPage } = state;
    //#endregion


    //#region Funcion para abrir el modal para editar
    const handleOnEditDocument = async (event, row) => {
        dispatch(getFilesByCustomer(row["documentId"])).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {
                const value = payload.data.rows; //los archivos de la PO
                let discountTypeNew = [];
                if (row.documentVendor.includes("IBM")) {
                    discountTypeNew = discountType.filter(item => item.vendor === 10027706)
                } else if (row.documentVendor.includes("LOTUS")) {
                    discountTypeNew = discountType.filter(item => item.vendor === 10028101)
                }
                else {
                    discountTypeNew = discountType
                }

                setEditRow((prevState) => ({
                    ...prevState,
                    editModal: !prevState.editModal,
                    editRow: row,
                    filesCustomer: value,
                    discountTypeNew: discountTypeNew
                }))
            } else if (payload.response.status === 404) {
                setEditRow((prevState) => ({
                    ...prevState,
                    editModal: !prevState.editModal,
                    editRow: row,
                    filesCustomer: []
                }))
            }
        })


    };
    //#endregion

    //#region Pagination (no sirve)
    const paginations = rows.length > sizePerPage ?
        rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
            Math.round(rows.length / sizePerPage) + 1
            : Math.round(rows.length / sizePerPage)
        : 1

    const renderPaginations = () => {
        const options = [];
        for (let i = 1; i <= paginations; i++) {
            options.push(
                <PaginationItem className={page === i ? "active" : ""} key={i}>
                    <PaginationLink
                        onClick={() => handleOnSetPage(i, "page")}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
    }

    //Cambiar de pagina
    const handleOnSetPage = (page, who) => {
        setPage(page);
    };

    //funcion para cambiar las filas por las paginas
    const handleOnSetNumPagination = (e) => {
        setPage(1);
        const value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            sizePerPage: value
        }))
    }

    //#endregion

    //#region funcion para poner el nombre completo del pais y no el codigo
    const handleGetStatusByCode = (code) => {
        const st = status.find(el => el.value === code);

        return st.label;
    }
    //#endregion

    //#region mostrar el comentario de la solicitud
    const handleGetComment = (comment) => {

        Toast.fire({
            title: "Comentario",
            html: comment
            // type: 'info'
        })

    }
    //#endregion

    return (
        <>

            <CardBody>

                <Table
                    className="align-items-center table-flush"
                    responsive
                    striped={true}
                    hover={true}
                >
                    <thead className="thead-light">
                        <tr>
                            {columnas.map((item, key) => {
                                return (
                                    (item.value !== "detalle") ?
                                        <th key={key} className="justify-content-md-center">
                                            <FormGroup key={key}>
                                                <label
                                                    style={{ fontSize: "12px" }}
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                >
                                                    {`${item.label}:`}
                                                </label>
                                                <Input
                                                    id={item.id}
                                                    key={key}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={`Buscar ${item.label}...`}
                                                    onChange={(e) => filters(item.value, e.target.value)}
                                                />
                                            </FormGroup>
                                        </th>
                                        :
                                        <th key={key} className="justify-content-md-center"> </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className="list">
                        {
                            rows.length ?
                                rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => (
                                    <tr key={key}>
                                        {
                                            columnas.map((col, key) => (
                                                col.value === "isEcommerce" ?
                                                    row[col.value] === 1 ?
                                                        <td key={key} className="table-actions">
                                                            <img
                                                                alt="Ecommerce"
                                                                className="img-center img-fluid shadow" //rounded-circle 
                                                                src={require(`assets/img/documentSystem/ecommerce-icon-2.png`)}
                                                                style={{ objectFit: "contain", width: "50px", height: "50px" }}
                                                            />
                                                        </td>
                                                        :
                                                        <td key={key} className="table-actions"></td>
                                                    :
                                                    col.value === "detalle" ?
                                                        <td key={key} className="table-actions">
                                                            <a
                                                                className="table-action table-action"
                                                                onClick={(e) => handleOnEditDocument(e, row)}
                                                                href="#"
                                                                id="edit"
                                                            >
                                                                <i className="fas fa-edit" />
                                                            </a>
                                                        </td>
                                                        : col.value === "statusName" ?
                                                            <td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                                <button type="button" className={"btn btn-round btn-wd btn-sm btn-" + (typeof status.find(dt => dt.value === row["status"]) !== "undefined" ? status.find(dt => dt.value === row["status"]).statusType : "")}>

                                                                    <span className="btn-label">
                                                                        <i className="fa fa-warning"></i>
                                                                    </span>
                                                                    {row["statusName"]}
                                                                </button>
                                                            </td>
                                                            :
                                                            <td key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                                                {
                                                                    row[col.value]
                                                                }
                                                            </td>
                                            ))
                                        }
                                    </tr>
                                ))
                                :
                                <>
                                    <tr>
                                        <td className="justify-content-md-center">
                                            Sin resultados encontrados
                                        </td>
                                    </tr>
                                </>
                        }
                    </tbody>
                </Table>
            </CardBody>
            <CardFooter className="py-4">
                <nav aria-label="...">
                    <Row className="align-items-center">
                        <Col xs='12' md='6' className="p-1">
                            <Row className="justify-content-start">
                                <Col xs='12' md='6'>
                                    <span className='pagination mb-0'>
                                        Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                                    </span>
                                </Col>
                                <Col xs='12' md='2'>
                                    <Input type="select" onChange={(e) => handleOnSetNumPagination(e)}>
                                        <option>5</option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs='12' md='6' className="p-1">
                            <Pagination
                                className="pagination justify-content-end mb-0"
                                listClassName="justify-content-end mb-0"
                            >
                                <PaginationItem className={page === 1 ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                                        tabIndex="-1"
                                    >
                                        <i className="fas fa-angle-left" />
                                        <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                </PaginationItem>
                                {
                                    renderPaginations()
                                }
                                <PaginationItem className={page === paginations ? "disabled" : ""}>
                                    <PaginationLink
                                        onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                                    >
                                        <i className="fas fa-angle-right" />
                                        <span className="sr-only">Next</span>
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </Col>
                    </Row>
                </nav>
            </CardFooter>
        </>
    );
};

export default MainTable;