// nodejs library to format dates
import moment from "moment";
import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col, FormGroup,
  Input, Row, Table
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";
import Select from "react-select";

const AgreementEditFormTwo = ({ dataModal, options, handleChangeInfo, products, onChangeProduct, isDisplay,isDisplayBF }) => {
  const [rowsModal, setRowsModal] = useState([
    {
      colWidth: "4",
      label: "Estado GBM",
      id: "gbmStatus",
      type: "select",
    },
    {
      colWidth: "4",
      label: "Estado de la Orden",
      id: "orderStatus",
      type: "select",
    },
    {
      colWidth: "4",
      label: "Cumplimiento de Fianza",
      id: "performanceBond",
      type: "select",
    },
    {
      colWidth: "4",
      label: "Tipo de Forecast",
      id: "forecastType",
      type: "select",
    },
    {
      colWidth: "4",
      label: "Oportunidad",
      id: "oportunity",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Quote",
      id: "quote",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Pedido del Proveedor",
      id: "vendorOrder",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Forecast",
      id: "forecast",
      type: "date",
    },
    {
      colWidth: "4",
      label: "Sales Order",
      id: "salesOrder",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Fecha real de entrega",
      id: "actualDeliveryDate",
      type: "date",
    },
    {
      colWidth: "4",
      label: "Monto de multa",
      id: "fineAmount",
      type: "text",
    },
    {
      colWidth: "4",
      label: "Confirmacion de orden",
      id: "orderConfirmation",
      type: "text",
    },
  ]);
  const colTable = [
    {
      value: "productDescription",
      label: "Producto o Servicio"
    },
    {
      value: "brand",
      label: "Marca"
    },
    {
      value: "price",
      label: "Costo Unitario"
    },
    {
      value: "quantity",
      label: "Cantidad"
    },
    {
      value: "orderType",
      label: "Tipo Inventario"
    },
    {
      value: "Total",
      label: "Total"
    },
  ]
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            {
              products.products.length > 0 && (
                <Card>
                  <CardBody>

                    <Table
                      className="align-items-center table-flush"
                      responsive
                      striped={true}
                      hover={true}
                    >
                      <thead className="thead-light">
                        <tr>
                          {
                            colTable.map((row, key) => {
                              return (
                                <th key={key} className="justify-content-md-center">
                                  {row.label}
                                </th>
                              )
                            }
                            )
                          }
                        </tr>
                      </thead>
                      <tbody className="list">
                        {
                          products.products.map((row, key) => (
                            <tr key={key} className="text-center">
                              {
                                colTable.map((col, key) => (
                                  <td key={key} className="text-center">
                                    {
                                      col.value === "orderType" ?
                                        <Select
                                          key={`input${key}`}
                                          className="basic-single"
                                          type="select"
                                          isSearchable
                                          isDisabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                                          defaultValue={{ label: row[col.value], value: 1 } || ""}
                                          options={options.orderType}
                                          onChange={(e) => onChangeProduct(row["productId"], e.value)}
                                        />
                                        :
                                        row[col.value]
                                    }
                                  </td>
                                ))
                              }
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    <h4>{`SubTotal: ${products.subtotal}`}</h4>
                  </CardFooter>
                </Card>
              )
            }
          </Row>
          <Row>
            {rowsModal.map((row, key) =>
              row.type === "text" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      className="form-control"
                      disabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                      type={row.type}
                      value={dataModal[row.id] || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : row.type === "select" ? (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      type={row.type}
                      disabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                      value={dataModal[row.id] || ""}
                      className="basic-multi-select text-dark"
                      onChange={(e) => handleChangeInfo(row.id, e.target.children[e.target.selectedIndex].value, `${row.id}Num`, e.target.children[e.target.selectedIndex].id)}
                    >
                      {options[row.id].map((value, key) => {
                        return (
                          <option key={key} id={value.value}>{`${value.label}`}</option>
                        );
                      })
                      }
                    </Input>
                  </FormGroup>
                </Col>
              ) : (
                <Col xs={row.colWidth} key={key}>
                  <FormGroup key={key}>
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      {`${row.label}:`}
                    </label>
                    <Input
                      id={row.id}
                      key={key}
                      type={row.type}
                      disabled={row.disabled || (isDisplay === true || isDisplayBF == true) && (true)}
                      value={moment.utc(dataModal[row.id]).format("YYYY-MM-DD") || ""}
                      onChange={(e) => handleChangeInfo(row.id, e.target.value)}
                      className="basic-multi-select text-dark"
                    ></Input>
                  </FormGroup>
                </Col>
              )
            )}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AgreementEditFormTwo;
