// import moment from 'moment';

// Colors charts
const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

// labels chart
const labels = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// options charts Bar
const optionsBar = {
  legend: {
    position: "top",
    display: false,
    // fullWidth: true,
    labels: {
      usePointStyle: false,
    },
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          },
        },
      },
    ],
  },
};

// options charts Bar
const optionsBarLegend = {
  legend: {
    position: "top",
    display: true,
    // fullWidth: true,
    labels: {
      usePointStyle: false,
    },
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: false,
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          },
        },
      },
    ],
  },
};

// options charts Pie
const optionsPie = {
  responsive: true,
  legend: {
    position: "top",
    display: true,
    // fullWidth: true,
    labels: {
      usePointStyle: false,
    },
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        return `${dataset.data[tooltipItem.index]}%`;
      },
    },
  },
};

export const getUsersWithAcess = (state) => {
  if (Object.keys(state.usersAccess).length) {
    const { users } = state.usersAccess;
    return users;
  }
  return [];
};

/**
 * Funcion para obtener todas las posiciones del estado de la aplicacion y pasarlos a los props
 * @param {*} state
 */
export const getPositions = (state) => state.unplanned.positions;

/**
 * Funcion para obtener todas los paises del estado de la aplicacion y pasarlos a los props
 * @param {*} state
 */
export const getCountrys = (state) => state.unplanned.countrys;

/**
 * Funcion para obtener la informacion asociada a una posicion del estado de la aplicacion y pasarlos a los
 * props
 * @param {*} state
 */
export const getDataByPosition = (state) => state.dataUnplanned;

/**
 * Funcion para obtener todos los valores iniciales para el formulario de creacion de una posicion
 * @param {*} state
 */
export const getInitialValuesCreate = (state) => {
  if (Object.keys(state.dataUnplanned).length) {
    const {
      position: [{ idPosition, insNumber, localRegionalType, protection }],
      country: [{ idCountry }],
      organizationalUnits,
      cecos,
      personalArea,
      bussinessLine,
      access,
      direction,
    } = state.dataUnplanned;

    const initialValues = {
      insNumber: idCountry === 5 ? insNumber : 0,
      idPosition,
      idCountry,
      spendTypeCost: "000",
      // changeRequestDate: `${moment()}`,
      localRegionalType: localRegionalType,
      protection: protection === null ? "002" : "000",
      idOrganizacionalUnit:
        organizationalUnits.length === 1
          ? organizationalUnits[0].idOrganizationalUnit
          : "0",
      idCeco: cecos.length === 1 ? cecos[0].idCeco : "0",
      idPersonalArea:
        personalArea.length === 1 ? personalArea[0].idPersonalArea : "0",
      idBussinessLine:
        bussinessLine.length === 1 ? bussinessLine[0].idBussinessLine : "0",
      idAccess: access.length === 1 ? access[0].idAccess : "0",
      idDirection: direction.length === 1 ? direction[0].idDirection : "0",
      idEmployeeSubGroup: "0"
    };

    return initialValues;
  }
  return {};
};

/**
 * Funcion para obtener la informacion asociada a una posicion que se desea modificar del estado de la
 * aplicacion y pasarlos a los props
 * @param {*} state
 */
export const getDataByPositionToUpdate = (state) => state.personal;

/**
 * Funcion para obtener todos los valores iniciales para los formularios de modificacion de una posicion
 * @param {*} state
 */
export const getInitialValuesUpdate = (state) => {
  if (Object.keys(state.personal).length) {
    const { response } = state.personal;

    return response;
  }
  return {};
};

export const getGeneralData = (state) => state.relationByPosition.general;

export const getOrgUnitByPosition = (state) => {
  if (Object.keys(state.relationByPosition).length) {
    const {
      byPosition: { organizationalUnitsByPosition },
    } = state.relationByPosition;
    const keys = organizationalUnitsByPosition.map(
      (element) => element.idOrganizationalUnit
    );
    return keys;
  }
  return [];
};

export const getCecoByPosition = (state) => {
  if (Object.keys(state.relationByPosition).length) {
    const {
      byPosition: { cecosByPosition },
    } = state.relationByPosition;
    const keys = cecosByPosition.map((element) => element.idCeco);
    return keys;
  }
  return [];
};

export const getPersonalAreasByPosition = (state) => {
  if (Object.keys(state.relationByPosition).length) {
    const {
      byPosition: { personalAreasByPosition },
    } = state.relationByPosition;
    const keys = personalAreasByPosition.map(
      (element) => element.idPersonalArea
    );
    return keys;
  }
  return [];
};

export const getDirectionByPosition = (state) => {
  if (Object.keys(state.relationByPosition).length) {
    const {
      byPosition: { directionsByPosition },
    } = state.relationByPosition;
    const keys = directionsByPosition.map((element) => element.idDirection);
    return keys;
  }
  return [];
};

export const getBussinessLinesByPosition = (state) => {
  if (Object.keys(state.relationByPosition).length) {
    const {
      byPosition: { bussinessLinesByPosition },
    } = state.relationByPosition;
    const keys = bussinessLinesByPosition.map(
      (element) => element.idBussinessLine
    );
    return keys;
  }
  return [];
};

export const getAccessByPosition = (state) => {
  if (Object.keys(state.relationByPosition).length) {
    const {
      byPosition: { accessByPosition },
    } = state.relationByPosition;
    const keys = accessByPosition.map((element) => element.idAccess);
    return keys;
  }
  return [];
};

export const getTotalsPositionsCreated = (state) =>
  state.historyTotalPositions.totals;

export const getTotalYearRequests = (state) => {
  const dataNotPlanned = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataStaff = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataVacant = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dataNewCecoPosition = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const { historyTotalPositions } = state;
  if (Object.keys(historyTotalPositions).length) {
    const { notPlannedByYear, staffByYear, vacantByYear, newCecoByYear } =
      historyTotalPositions;
    if (notPlannedByYear.length) {
      for (const row of notPlannedByYear) {
        dataNotPlanned[row.month - 1] = row.count;
      }
    }
    if (staffByYear.length) {
      for (const row of staffByYear) {
        dataStaff[row.month - 1] = row.count;
      }
    }
    if (vacantByYear.length) {
      for (const row of vacantByYear) {
        dataVacant[row.month - 1] = row.count;
      }
    }
    if (newCecoByYear.length) {
      for (const row of newCecoByYear) {
        dataNewCecoPosition[row.month - 1] = row.count;
      }
    }
  }
  return {
    data: {
      labels,
      datasets: [
        {
          label: "No Planificada",
          data: dataNotPlanned,
          backgroundColor: colors.theme["danger"],
        },
        {
          label: "Con personal",
          data: dataStaff,
          backgroundColor: colors.theme["success"],
        },
        {
          label: "Vacante",
          data: dataVacant,
          backgroundColor: colors.theme["primary"],
        },
        {
          label: "Centro de Costos",
          data: dataNewCecoPosition,
          backgroundColor: colors.theme["warning"],
        },
      ],
    },
    options: optionsBarLegend,
  };
};

export const getResponseRate = (state) => {
  const { historyTotalPositions } = state;
  let dataResponseRate = [0, 0, 0];
  if (Object.keys(historyTotalPositions).length) {
    const {
      totals: {
        totalCount,
        countNotPlanned,
        countStaff,
        countVacant,
        countNewCecoPosition,
      },
    } = historyTotalPositions;
    // console.log( countNotPlanned, countStaff, countVacant, NewCecoPosition);
    const notPlannedPercent = (countNotPlanned * 100) / totalCount;
    const staffPercent = (countStaff * 100) / totalCount;
    const vacantPercent = (countVacant * 100) / totalCount;
    const newCecoPercent = (countNewCecoPosition * 100) / totalCount;
    dataResponseRate = [
      Math.round(notPlannedPercent * 100) / 100,
      Math.round(staffPercent * 100) / 100,
      Math.round(vacantPercent * 100) / 100,
      Math.round(newCecoPercent * 100) / 100,
    ];
    // dataResponseRate = [countNotPlanned, countStaff, countVacant, countNewCecoPosition]
  }
  return {
    data: {
      labels: [
        "No planificadas",
        "Con Personal",
        "Vacantes",
        "Centro De Costos",
      ],
      datasets: [
        {
          data: dataResponseRate,
          backgroundColor: [
            colors.theme["danger"],
            colors.theme["success"],
            colors.theme["primary"],
            colors.theme["warning"],
          ],
        },
      ],
    },
    options: optionsPie,
  };
};

export const getVacantsPositions = (state) => state.vacantPosition;

export const getAllRequestsByUsers = (state) =>
  state.allRequestsByUsers.requests;

export const getAllRequestsByCounrys = (state) => {
  const total = [];
  const titles = [];
  if (Object.keys(state.allRequestsByUsers).length) {
    const { countrys } = state.allRequestsByUsers;
    if (countrys.length) {
      for (const el of countrys) {
        titles.push(el.country);
        total.push(el.requests);
      }
    }
  }
  return {
    data: {
      labels: titles,
      datasets: [
        {
          label: "Total",
          backgroundColor: colors.theme["success"],
          borderWidth: 1,
          hoverBackgroundColor: colors.theme["info"],
          hoverBorderColor: colors.theme["primary"],
          data: total,
        },
      ],
    },
    options: optionsBar,
  };
};

export const getUnplannedRequestByUser = (state) => {
  if (Object.keys(state.requestsByUsers).length)
    return state.requestsByUsers.unplanned;
  return [];
};

export const getStaffRequestByUser = (state) => {
  if (Object.keys(state.requestsByUsers).length)
    return state.requestsByUsers.staff;
  return [];
};

export const getVacantRequestByUser = (state) => {
  if (Object.keys(state.requestsByUsers).length)
    return state.requestsByUsers.vacant;
  return [];
};

export const getNewCecoPositionRequestByUser = (state) => {
  if (Object.keys(state.requestsByUsers).length)
    return state.requestsByUsers.newCecoPosition;
  return [];
};

export const getEditVacantRequest = (state, props) => {
  if (Object.keys(state.requestsByUsers).length) {
    const { id } = props.match.params;
    const { vacant } = state.requestsByUsers;
    for (const element of vacant) {
      if (element.id === parseInt(id)) {
        return element;
      }
    }
  }
  return {};
};

export const getEditVacantInitialValues = (state) => {
  if (Object.keys(state.vacantById).length) {
    return state.vacantById.vacant;
  }
  return {};
};

export const getEditVacantData = (state) => {
  if (Object.keys(state.vacantById).length) {
    return state.vacantById.dataUpdate;
  }
  return {};
};

export const getAllMasterDataPositions = (state) => {
  if (Object.keys(state.allMasterDataPositions).length) {
    return state.allMasterDataPositions;
  }
  return {
    cecos: [],
    ins: [],
    positions: [],
    organizationalUnits: [],
  };
};
