/*!
=========================================================
*MasterData - NotAccessView
=========================================================

* Componente para indicar al usuario que no tiene permiso 
* para visualizar la vista.
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

import React from 'react'

//Reactstrap librería
import {
    CardBody,
    Card,
    Col,
    Row,
} from "reactstrap";

export const NotAccessView = () => {
    return (
        <>
            <Row className="mt-5 mb-4">
                <Col sm="4"></Col>

                <Col sm="4" >
                    <Card className="bg-gradient-default border-0 " id={"card"}>
                        <CardBody >
                            <img
                                alt="..."
                                className="rounded img-center mt-2"
                                src={require(`assets/img/masterdata/seguridad.png`)}
                                style={{ objectFit: "contain", width: "70px", height: "70px" }}
                            />

                            <div className="pt-1 text-center mt-2 mb-2">
                                <h4 className="h3 title">
                                    <span className="d-block mb-1 text-light">Sin Acceso</span>
                                    <small className="h5 font-weight-light  text-white">
                                        Actualmente no posee permiso para accesar a este módulo.
                                    </small>
                                </h4>
                            </div>

                        </CardBody>
                    </Card>
                </Col>

                <Col sm="4"></Col>

            </Row>



        </>
    )
}
