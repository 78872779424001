/*!

=========================================================
*Portal Freelance para la administración de los proveedores que subcontrata GBM
=========================================================

*Componente "Main" de PoAssignation para la asignación de ordenes de compra a los freelance externos.
*El cual llama los siguientes componentes:
    FiltersMain: Componente compartido para filtrar la tabla principal de asignaciones de ordenes de compra.
    MainTable: Componente compartido para mostrar las asignaciones de las ordenes de compra
    ToAssignModal: Componente Modal para asignar ordenes de compra a un freelance externo.
*Props:
    setLoad: useState para el boolean del spinner
    Toast: funcion para las notificaciones
    dropDownOptions: useState con las opciones de los dropdowns
    rolArea: useState que guarda el área del usuario logeado
    MySwal: funcion para las notificaciones personalizadas
    isAdmin: useState que guarda un booleano para verificar si el usuario logeado es administrador
    setRefreshOptions: useState que actualiza las opciones de los dropdowns de manera automatica
=========================================================

* Coded by Diego Meza y Kendall Carvajal - Application Management GBM

*/
// react library
import React, { useState, useEffect } from 'react';
//customized Componentes
import FiltersMain from 'components/Freelance/SharedComponents/FiltersMain.jsx';
import ToAssignModal from 'components/Freelance/PoAssignation/ToAssignModal.jsx';
import HitoUploadModal from 'components/Freelance/PoAssignation/HitoUploadModal.jsx';
import MainTable from 'components/Freelance/SharedComponents/MainTable.jsx';
//Acción para extraer las solicitudes, para cambiar el responsable de una asignación, y para bloquear una asignación
import { newResponsible, inactivePo, getActaFile } from 'actions/Freelance.jsx';
//usedispatch for fetch
import { useDispatch } from 'react-redux';

const MainPoAssignation = ({
    setLoad,
    Toast,
    dropDownOptions,
    rolArea,
    MySwal,
    isAdmin,
    setRefreshOptions
}) => {

    //#region Functions

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region state para recargar la tabla principal cuando se necesite
    const [reloadTable, setRealTable] = useState(false);
    //#endregion

    //#region state para definir la pagina inicial del MainTable
    const [page, setPage] = useState(1);
    //#endregion

    //#region state para abrir o cerrar el modal de nuevas asignaciones de POs
    const [modal, setModal] = useState({
        showModal: false,
        type: ""
    });
    const { showModal, type } = modal;
    //#endregion

    //#region  State para guardar los filtros seleccionados según cada tabla en FilterMain
    const [search, setSearch] = useState({});
    //#endregion

    //#region campos del filtro para el componente FilterMain
    const fields = [
        {
            colWidth: "6",
            label: "Área de negocio",
            placeholder: "Seleccione el Área de negocio",
            id: "purcharseOrderAssignation.area",
            options: "billsArea",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Orden de Compra",
            placeholder: "Seleccione la Orden de Compra",
            id: "purcharseOrderAssignation.purchaseOrder",
            options: "purchaseOrderAssignation",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Proveedores",
            placeholder: "Seleccione el Proveedor",
            id: "purcharseOrderAssignation.consultant",
            options: "accessFreelance",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "6",
            label: "Proyecto",
            placeholder: "Seleccione el Proyecto",
            id: "purcharseOrderAssignation.project",
            options: "assignationProjects",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select"
        },
        {
            colWidth: "12",
            label: "Responsable",
            placeholder: "Seleccione el responsable",
            id: "purcharseOrderAssignation.responsible",
            options: "Responsables",
            type: "select",
            disabled: false,
            visibleDefault: false,
            elementType: "Select",
        },
        {
            colWidth: "6",
            label: "Fecha Desde",
            placeholder: "Seleccione la fecha",
            id: "startDate",
            type: "date",
            disabled: false,
            visibleDefault: false,
            elementType: "StartDate"
        },
        {
            colWidth: "6",
            label: "Fecha Hasta",
            placeholder: "Seleccione la fecha",
            id: "endDate",
            type: "date",
            disabled: false,
            visibleDefault: false,
            elementType: "EndDate"
        },
    ];
    //#endregion

    //#region funcion para buscar nuevas asignaciones para llenar la tabla en MainTable
    const handleLookUp = (e) => {
        setPage(1);
        setRealTable((prevState) => (!prevState));
    }
    //#endregion

    //#region funcion para abrir el modal de nueva asignación
    const openCreateModal = () => {
        setModal((prevState) => ({
            ...prevState,
            showModal: !prevState.showModal,
            type: "0"
        }));
    };
    //#endregion

    //#region funcion para abrir el modal de nueva asignación (por hitos)
    const openHitoModal = () => {
        setModal((prevState) => ({
            ...prevState,
            showModal: !prevState.showModal,
            type: "1"
        }));
    };
    //#endregion

    //#region Botones del FilterMain para ejecutar las funciones anteriores
    const buttons = [
        {
            color: "primary",
            icon: "fas fa-search",
            title: "Buscar Asignaciones",
            function: handleLookUp,
            type: "button",
            colWidth: "3"
        },
        {
            color: "success",
            icon: "fa fa-plus",
            title: "Agregar/Asignar PO",
            function: openCreateModal,
            type: "button",
            colWidth: "3"
        },
        {
            color: "secondary",
            icon: "fa fa-box",
            title: "Entregable/Hito",
            function: openHitoModal,
            type: "button",
            colWidth: "3"
        },
        {
            color: "info",
            icon: "fa fa-download",
            title: "Descargar asignaciones",
            docTitle: "Reporte de ordenes de compras asignadas  " + new Date().toISOString().slice(0, 10),
            type: "download",
            colWidth: "3"
        },
    ];
    //#endregion

    //#region funcion para los dropdowns que modifican el state de los filtros (se utiliza en FilterMain)
    const handleOnChangeFilter = (constant, valor) => {
        setSearch((prevState) => ({
            ...prevState,
            [constant]: valor
        }))
    }
    //#endregion

    //#region modificar el responsable de una PO y por ende el área
    const editResponsable = (e, row, dropDown) => {
        //crear el json para el dropdown del sweeralert
        let selectHtml = '<select id="responsible" class="form-control"><option value=""></option>';
        dropDown["Responsables"].forEach(function (json) {
            selectHtml = selectHtml + `<option value="${json["value"]}">${json["label"]}</option>`;
        });
        selectHtml = selectHtml + '</select><br>';

        MySwal.fire({
            title: `Reasignación de Responsable de la PO: ${row["purchaseOrder"]}`,
            html: selectHtml,
            confirmButtonText: 'Si, cambiar.',
            confirmButtonColor: '#2dce89',
            cancelButtonText: 'No, cancelar',
            showCancelButton: true,
            preConfirm: () => {
                let value = document.getElementById('responsible').value;
                if (value !== "") {
                    setLoad(true);
                    //buscar la nueva area del nuevo responsable
                    let area = dropDown["Responsables"][(dropDown["Responsables"].findIndex(y => y.value == value))].rol.split('Freelance Coordinator ');
                    let areaId = "";
                    if (area[1] == "Admin") {
                        var areasObj = {};
                        dropDown["billsArea"].forEach(function (json) {
                            areasObj[json["value"]] = json["label"];
                        });
                        MySwal.fire({
                            title: `Seleccione el área que desea asignar la Orden de Compra`,
                            input: 'select',
                            inputOptions: areasObj,
                            inputPlaceholder: `Seleccione la nueva área`,
                            showCancelButton: true,
                            inputValidator: (vall) => {
                                return new Promise((resolve) => {
                                    areaId = vall;
                                    if (areaId === '') {
                                        resolve();
                                    } else {
                                        var newResp = {
                                            id: row["id"],
                                            newResponsible: value,
                                            newArea: areaId,
                                        };

                                        dispatch(newResponsible({ info: newResp })).then((resp) => {
                                            const { payload } = resp;

                                            if (payload.status === 200) {
                                                setRealTable(prevCheck => !prevCheck);
                                                Toast.fire({
                                                    title: "Atención",
                                                    html: "Se modificó el responsable",
                                                    type: 'success'
                                                });
                                            } else {
                                                resolve("Error, comuniquese con Application Management.");
                                                Toast.fire({
                                                    title: "Atención",
                                                    html: payload.message,
                                                    type: 'warning'
                                                });
                                            }
                                            setLoad(false);
                                        })
                                    }
                                })
                            }
                        })
                    }
                    else {
                        areaId = dropDown["billsArea"][(dropDown["billsArea"].findIndex(y => y.areaCode == area[1]))].value;
                        var newResp = {
                            id: row["id"],
                            newResponsible: value,
                            newArea: areaId,
                        };

                        dispatch(newResponsible({ info: newResp })).then((resp) => {
                            const { payload } = resp;

                            if (payload.status === 200) {
                                setRealTable(prevCheck => !prevCheck);
                                Toast.fire({
                                    title: "Atención",
                                    html: "Se modificó el responsable",
                                    type: 'success'
                                });
                            } else {

                                Toast.fire({
                                    title: "Atención",
                                    html: payload.message,
                                    type: 'warning'
                                });
                            }
                            setLoad(false);
                        })
                    }
                    setLoad(false);
                }
                else {
                    MySwal.showValidationMessage(
                        "El responsable es obligatorio"
                    )
                }

            }

        })
    }
    //#endregion

    //#region desactivar una PO del sistema
    const deleteAssignation = (e, row, dropDown) => {
        const inactive = MySwal.fire({
            title: `Deseas eliminar la asignación de la Purchase Order ${row["purchaseOrder"]}?`,
            text: "Se puede reversar con ayuda de Application Management",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, eliminar.',
            confirmButtonColor: '#fa0303',
            cancelButtonText: 'No, cancelar',
        }).then((result) => {
            // alert(JSON.stringify(result));
            if (result.value) {
                setLoad(true);
                var inPo = {
                    id: row["id"]
                };
                dispatch(inactivePo({ info: inPo })).then((resp) => {
                    const { payload } = resp;

                    if (payload.status === 200) {
                        setRealTable(prevCheck => !prevCheck);
                        Toast.fire({
                            title: "Atención",
                            html: "Se inactivo con éxito",
                            type: 'success'
                        });
                    } else {
                        Toast.fire({
                            title: "Atención",
                            html: payload.message,
                            type: 'warning'
                        });
                    }
                    setLoad(false);
                })
            }
        })

    }
    //#endregion

    //#region abrir modal para subir acta de hitos
    const [modalHitoActa, setModalHitoActa] = useState({
        showModalActaHito: false,
        rowModalHito: {},
        actaFilesName: []
    });
    const { showModalActaHito, rowModalHito, actaFilesName } = modalHitoActa
    const openHitoActaModal = (e, row, dropDown) => {

        setLoad(true);
        dispatch(getActaFile({ id: row.id })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                console.log(payload.data.rows);
                // setActaFiles(payload.data.rows);
                setModalHitoActa((prevState) => ({
                    ...prevState,
                    actaFilesName: payload.data.rows,
                }));
            } else {
                setModalHitoActa((prevState) => ({
                    ...prevState,
                    actaFilesName: [],
                }));
                console.log("error al descargar el archivo")
            }
            setLoad(false);
        })

        setModalHitoActa((prevState) => ({
            ...prevState,
            showModalActaHito: !prevState.showModalActaHito,
            rowModalHito: row
        }));
    };
    //#endregion

    //#region botones de la tabla MainTable por cada fila de la tabla que utilizan las funciones anteriores
    const actions = [
        {
            color: "warning",
            icon: "fas fa-pen",
            fun: editResponsable,
            add: "po",
            title: "Editar Responsable",
            id: "editResponsible"
        },
        {
            color: "danger",
            icon: "fas fa-trash",
            fun: deleteAssignation,
            add: "po",
            title: "Borrar asignación",
            id: "deleteAsignation"
        },
        {
            color: "success",
            icon: "fas fa-upload",
            fun: openHitoActaModal,
            add: "hito",
            title: "Subir Acta de cierre",
            id: "uploadActa"
        }
    ];
    //#endregion

    //#region campos seleccionados de la tabla MainTable
    const [selected, setSelected] = useState(
        [
            { value: "consultantName", label: "Consultor" },
            { value: "Cdate", label: "Fecha", type: "date" },
            { value: "purchaseOrder", label: "Orden de Compra" },
            { value: "item", label: "Item" },
            { value: "description", label: "Descripción" },
            { value: "responsible", label: "Responsable" },
            { value: "areaCode", label: "Área", type: "color", optionList: "billsArea", option: "areaType", val: "area" },
            { value: "actions", label: "Acciones", buttons: actions },
        ]
    );
    //#endregion

    //#region campos/columna de la tabla MainTable
    const optionsSelect = [
        { value: "id", label: "Id" },
        { value: "consultantName", label: "Consultor" },
        { value: "Cdate", label: "Fecha", type: "date" },
        { value: "purchaseOrder", label: "Orden de Compra", disabled: true },
        { value: "item", label: "Item" },
        { value: "description", label: "Descripción" },
        { value: "responsible", label: "Responsable" },
        { value: "areaCode", label: "Área", type: "color", optionList: "billsArea", option: "areaType", val: "area" },
        { value: "actions", label: "Acciones", disabled: true, buttons: actions },
        { value: "projectName", label: "Proyecto" },
        { value: "helpAreaName", label: "Área Apoyo" },
        { value: "byHitoText", label: "Hito" },
        { value: "mountHito", label: "Monto Hito" },
        { value: "inactiveAt", label: "Fecha inactiva" }
    ];
    //#endregion

    //#endregion

    return (
        <>
            <ToAssignModal
                setModal={setModal}
                showModal={showModal}
                dropDownOptions={dropDownOptions}
                setRealTable={setRealTable}
                Toast={Toast}
                MySwal={MySwal}
                type={type}
                setRefreshOptions={setRefreshOptions}
            />
            <HitoUploadModal
                setModal={setModalHitoActa}
                showModal={showModalActaHito}
                dropDownOptions={dropDownOptions}
                setRealTable={setRealTable}
                Toast={Toast}
                MySwal={MySwal}
                setRefreshOptions={setRefreshOptions}
                row={rowModalHito}
                actaFilesName={actaFilesName}
            />
            <FiltersMain
                dropDownOptions={dropDownOptions}
                fields={fields}
                buttons={buttons}
                handleOnChangeFilter={handleOnChangeFilter}
                columnTitles={optionsSelect}
                tableParameter={"po"}
                search={search}
                Toast={Toast}
            />

            <MainTable
                setPage={setPage}
                page={page}
                reloadTable={reloadTable}
                search={search}
                dropDownOptions={dropDownOptions}
                selected={selected}
                setSelected={setSelected}
                optionsSelect={optionsSelect}
                tableParameter={"po"}
                rolArea={rolArea}
                statusError={"NA"}
                isAdmin={isAdmin}
            />
        </>
    );
}

export default MainPoAssignation;