import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const inactiveSystems = handleActions(
  {
    [constants.GET_INACTIVE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.inactive;
      }
      return [null];
    },
    [constants.UPDATE_INACTIVE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const [{ id }] = data.payload.inactiveData;
        const newInactive = state.map(dataOld => {
          if (dataOld.id === id) {
            dataOld = data.payload.inactiveData[0];
          }
          return dataOld;
        });
        return newInactive;
      }
      return [null];
    },
    [constants.INSERT_INACTIVE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const inactiveRow = data.payload.inactiveData;
        if (state[0] === null) {
          return [...inactiveRow];
        }
        return [...state, ...inactiveRow];
      }
      return [null];
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

export const projects = handleActions(
  {
    [constants.GET_PROJECTS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.projects;
      }
      return [null];
    },
    [constants.UPDATE_PROJECTS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const [{ id }] = data.payload.projectsData;
        const newProjects = state.map(dataOld => {
          if (dataOld.id === id) {
            dataOld = data.payload.projectsData[0];
          }
          return dataOld;
        });
        return newProjects;
      }
      return [null];
    },
    [constants.INSERT_PROJECTS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const projectsRow = data.payload.projectsData;
        if (state[0] === null) {
          return [...projectsRow];
        }
        return [...state, ...projectsRow];
      }
      return [null];
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

export const countProjects = handleActions(
  {
    [constants.GET_COUNT_PROJECTS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.activeProjects[0];
      }
      return {};
    },
    [constants.SIGN_OUT]: () => { return {} },
  },
  {}
);

export const maintenance = handleActions(
  {
    [constants.GET_MAINTENANCE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload.maintenance;
      }
      return [null];
    },
    [constants.UPDATE_MAINTENANCE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const [{ id }] = data.payload.maintenanceData;
        const newMaintenance = state.map(dataOld => {
          if (dataOld.id === id) {
            dataOld = data.payload.maintenanceData[0];
          }
          return dataOld;
        });
        return newMaintenance;
      }
      return [null];
    },
    [constants.INSERT_MAINTENANCE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        const maintenanceRow = data.payload.maintenanceData;
        if (state[0] === null) {
          return [...maintenanceRow];
        }
        return [...state, ...maintenanceRow];
      }
      return [null];
    },
    [constants.SIGN_OUT]: () => [],
  },
  []
);

export const internalTeams = handleActions({
  [constants.GET_INTERNAL_TEAMS]: (state, action) => {
    const { status, data } = action.payload;
    if (status === 200) {
      return data.payload;
    }
    return {};
  },
  [constants.SIGN_OUT]: () => { return {} },
}, {});