/*!

=========================================================
*Result
=========================================================

*Componente para gestionar el resultado.
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

import React from "react";
// reactstrap components
import {
    Label,
    Input,
    Row,
    Col,
} from "reactstrap";

const Result = ({
    isResult,
    resultData,
    resultFields,
    isMobile,
    options
}) => {

    return (

        <>
            {
                resultFields.map((field, key) =>
                    <Row key={key} className="mt-3">
                        <Col xs={isMobile ? "5" : "2"}>
                            <Label className="font-weight-bold ml-2">
                                {field.icon && (
                                    // Icon
                                    <field.icon className="mr-2 mb-1" style={{ fontSize: "16px" }} />
                                )}
                                {field.label + ':'}
                            </Label>
                        </Col>
                        <Col xs={isMobile ? "7" : "10"}>
                            <Input
                                type={field.type}
                                className=""
                                disabled={field.disabled || (field.dependsEnableOf && !resultData[field.dependsEnableOf])}
                                size={isMobile ? "sm" : undefined}
                                // style={{ backgroundColor: '#fff' }}
                                value={resultData && resultData[field.id]}
                                min={1}
                            >
                                {field.deleteEmptyOption && <option id=""></option>}
                                {options[field.options] &&
                                    options[field.options].map((value, index) => (
                                        <option key={index} value={value.value}>
                                            {value.label}
                                        </option>
                                    ))}
                            </Input>
                        </Col>
                    </Row >
                )
            }

        </>

    )
}

export default Result;