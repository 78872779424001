import React, { useState, useEffect } from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'

const FieldsInput = ({ md, sm, id, value, label }) => {
  const [state, setState] = useState(false)

  useEffect(() => {
    if (value !== null && value !== undefined && value !== '') {
      setState(true)
    }
  }, [])

  return (
    <>
    {state &&
      <Col md={md} sm={sm}>
        <FormGroup>
          <Label for={id}>{label}</Label>
          <Input readOnly type='text' id={id} value={value} />
        </FormGroup>
      </Col>
    }
    </>
  )
}

export default FieldsInput
