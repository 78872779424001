/*!

=========================================================
*MasterData - MaintenanceTable
=========================================================

* Este componente contiene una tabla de mantenimiento para cada campo de cada formulario del json CardsMaintenance.jsx
* De acuerdo a la tabla recibida trae la información
* tiene las acciones para crear, editar o eliminar una opcion de una lista de mantenimientos
=========================================================

* Coded by Diego Meza Castro - Application Management GBM

*/

//Imports de librerías de react.
import React, { useState, useEffect, useContext } from 'react'
import {
  PaginationItem,
  PaginationLink,
  Pagination,
  CardHeader,
  FormGroup,
  CardBody,
  Tooltip,
  Button,
  Input,
  Table,
  Badge,
  Card,
  Col,
  Row,
} from "reactstrap";

//Footer de la tabla
import CardFooter from 'reactstrap/lib/CardFooter';

//Multiselect
import { MultiSelect } from "react-multi-select-component";

// imports de actions
import {
  getMaintenanceTableInfo,
  maintenanceAction
} from 'actions/masterData';

//Import de Spinner
import { Spin } from "antd";
// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
//Import para consulta  en DB
import { useDispatch } from 'react-redux';
import { UserContext } from './UserContext';


export const MaintenanceTable = ({
  title,
  id,
  table,
  formSelected,
  columnsException,
  isFactor
}) => {


  //#region States globales
  const {
    options,
    setRefreshOptions,
    refreshOptions,
    loading
  } = useContext(UserContext);
  //#endregion

  //#region Notificaciones

  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  //#endregion
  const [load, setLoad] = useState(false);
  const [reloadTableRequests, setReloadTableRequests] = useState(false);

  //Declaración de disparador para el api
  const dispatch = useDispatch();

  //Es el id de la fila a editar.
  const [rowToEdit, setRowToEdit] = useState(0);

  //State para guardar la nueva información de la fila brand a editar.
  const [newInfoEdited, setNewInfoEdited] = useState({});

  //Función para eliminar un formulario LDR.
  const handleDelete = (row) => {

    MySwal.fire({

      type: 'warning',
      title: `Eliminar Opción de Lista`,
      html:

        `<h2>¿Está seguro que desea eliminar el campo ${row.code + "-" + row.description}?</h2>`,

      confirmButtonText: 'Si, eliminar',
      confirmButtonColor: '#f5365c',
      cancelButtonText: 'No, cancelar',
      showCancelButton: true,

      preConfirm: () => {

        dispatch(maintenanceAction({ id: row.id, table, type: "del", isFactor: isFactor })).then((resp) => {

          const { payload } = resp;
          if (payload.status === 200) {
            Toast.fire({
              title: "Atención",
              html: `Opción eliminada con éxito!`,
              type: 'success'
            });

            //Refresca las opciones y pone el spinner
            setReloadTableRequests(prev => !prev);

          } else {

            Toast.fire({
              title: "Atención",
              html: payload.response.data.message,
              type: 'warning'
            });
          };
        })


      },

    })


  }

  //funcion para volver a activar una opcion
  const handleRestore = (row) => {

    MySwal.fire({

      type: 'warning',
      title: `Restaurar Opción de Lista`,
      html:

        `<h2>¿Está seguro que desea restaurar el campo ${row.code + "-" + row.description}?</h2>`,

      confirmButtonText: 'Si, restaurar',
      confirmButtonColor: '#2DCE89',
      cancelButtonText: 'No, cancelar',
      showCancelButton: true,

      preConfirm: () => {

        dispatch(maintenanceAction({ id: row.id, table, type: "res", isFactor: isFactor })).then((resp) => {

          const { payload } = resp;
          if (payload.status === 200) {
            Toast.fire({
              title: "Atención",
              html: `Opción eliminada con éxito!`,
              type: 'success'
            });

            //Refresca las opciones y pone el spinner
            setReloadTableRequests(prev => !prev);

          } else {

            Toast.fire({
              title: "Atención",
              html: payload.response.data.message,
              type: 'warning'
            });
          };
        })


      },

    })


  }

  //Valida si no se llenaron campos en este modal.
  const validateFieldsModal = (row, newInfoE, rows) => {

    //Averigua si hay campos vacíos.
    let result = Object.values(newInfoE).filter(field => field == "")

    //Si en la tabla existe uno igual
    // const existAnotherEqual = rows.some(row => {
    //   return Object.keys(newInfoE).every(key => {
    //     if (typeof row[key] === 'number') {
    //       return parseInt(row[key]) === parseInt(newInfoE[key]);
    //     }
    //     return row[key].trim() === newInfoE[key].trim();
    //   });
    // });
    const existAnotherCodeEqual = rows.some(rowSome => {

      if (typeof rowSome.code === 'number') {
        return (parseInt(rowSome.code) === parseInt(newInfoE.code))
          && rowSome.id != row.id; //Para que no valide el código de la fila actual.
      }
      return (rowSome.code.trim() === newInfoE.code.trim())
        && rowSome.id != row.id; //Para que no valide el código de la fila actual.
    });


    if (result.length > 0) {
      Toast.fire({
        title: "Atención",
        html: "Existen campos vacíos en la fila.",
        type: 'warning'
      });
      return false;
    } else if (existAnotherCodeEqual) {
      Toast.fire({
        title: "Atención",
        html: "Este código ya existe en un registro de la tabla.",
        type: 'warning'
      });
      return false;
    }

    return true;

  }

  //Función para actualizar un brand de los LDRS.
  const handleSave = (row, newInfoE, rows) => {

    if (validateFieldsModal(row, newInfoE, rows)) {

      MySwal.fire({

        type: 'success',
        title: `Actualizar Opción de la lista`,
        html:

          `<h2>¿Está seguro que desea actualizar el campo ${row.code + "-" + row.description}?</h2>`,

        confirmButtonText: 'Si, actualizar',
        confirmButtonColor: '#2DCE89',
        cancelButtonText: 'No, cancelar',
        showCancelButton: true,

        preConfirm: () => {

          dispatch(maintenanceAction({ id: row.id, table, type: "up", info: newInfoE, isFactor: isFactor })).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
              Toast.fire({
                title: "Atención",
                html: `Opción actualizada con éxito!`,
                type: 'success'
              });

              //Para que salga de la row a editar.
              setRowToEdit(0);
              //Refresca las opciones y pone el spinner
              setReloadTableRequests(prev => !prev);

            } else {

              Toast.fire({
                title: "Atención",
                html: payload.response.data.message,
                type: 'warning'
              });
            };
          })


        },

      })

    }

  }

  //Función para editar una fila de brand
  const handleEdit = (row) => {
    setRowToEdit(row.id)
  }


  //Función para cancelar un guardado debrand
  const handleCancellSave = () => {
    //Refresca que no hayan rows editables
    setRowToEdit(0);
  }

  //#region botones de la tabla MainTable por cada fila de la tabla
  const actions = [
    {
      color: "default",
      icon: "fa fa-edit",
      fun: handleEdit,
      title: "Editar campo",
      id: "edit",
      haveException: false

    },
    {
      color: "success",
      icon: "ni ni-check-bold",
      fun: handleRestore,
      title: "Restaurar Campo",
      id: "restore",
      haveException: true
    },
    {
      color: "danger",
      icon: "fa fa-trash",
      fun: handleDelete,
      title: "Eliminar Campo",
      id: "delete",
      haveException: true
    },
    {
      color: "success",
      icon: "fa fa-save",
      fun: handleSave,
      title: "Guardar Cambio",
      id: "save",
      haveException: true
    },
    {
      color: "warning",
      icon: "fa fa-times",
      fun: handleCancellSave,
      title: "Cancelar",
      id: "cancell",
      haveException: true
    },
  ];
  //#endregion


  //#region Establecer array de columnas 

  //Columnas para el multicolumn y si la seleccionan se agrega al columnsSelectedTableRequests
  const [optionsRequestsSelect, setOptionsRequestsSelect] = useState([]);

  //Columnas seleccionadas por defecto en la tabla.
  const [columnsSelectedTableRequests, setColumnsSelectedTableRequests] = useState([]);

  //Función para definir las columnas centrales de la tabla.
  const defineMainColumns = () => {

    //Inicio de las columnas
    let options = [
      { value: "action", label: "Botón", buttons: actions, disabled: true },
      { value: "code", label: "Código", disabled: true, editable: true, type: "input" },
    ]

    if (columnsException != undefined) {
      /*Columnas excepción*/
      options = options.concat(columnsException)

    } else {
      /*Columna por defecto*/
      options = [...options,
      { value: "description", label: "Descripción", disabled: true, editable: true, type: "input" },
      ]
    }

    //Final de las columnas
    options = [...options,
    { value: "activeText", label: "Estado" },
    { value: "createdAtText", label: "Fecha de creación" },
    { value: "createdBy", label: "Usuario Creador" },
    ]

    //Asignación.
    setColumnsSelectedTableRequests(options);
    setOptionsRequestsSelect(options);

  }

  //Cuando arranca por primera vez define las columnas.
  useEffect(() => {
    defineMainColumns()
  }, [])


  //#endregion

  //#region Funciones para aplicar flitros a la tabla
  //Filtros request tables
  const [filters, setFilters] = useState([]);


  //Capturar los valores de los inputs de los filtros
  const handleOnfilterDynamic = (constant, e) => {
    console.log(constant, e)
    setFilters(prevState => ({
      ...prevState,
      [constant]: e
    }))
  }

  //Aplicar los filtros a la data de la tabla
  const renderRowsByFilters = (rows) => {

    if (Object.keys(filters).length === 0) {
      return rows
    } else {
      const filterKeys = Object.keys(filters);

      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();

        rows = rows.filter(

          (item) => {
            let temp;

            try {
              temp = item[element]?.toString().toLowerCase().indexOf(valueFiltered);
            } catch (e) { temp = -1 }

            //Si se le da un espacio en el campo, imprima tdoos los vacios
            if (valueFiltered === " ") {
              return temp === undefined
            } else { //No es un espacio, traiga todos los de la palabra clave
              return temp != -1 && temp != undefined
            }

          }
        )
      }
      return rows
    }
  }

  //#endregion

  //#region Extraer las filas del formulario
  const [rowsRequests, setRowsRequests] = useState({
    rows: [],
    statsBaw: [],
    length: 0,
  })

  //Cuando reloadTableRequests es true, ejecuta este efecto para cargar la tabla
  useEffect(() => {
    handleCancellSave()
    if (formSelected === id) {
      setLoad(true);

      //Determina si hay columnas editables y tipo select que debe refrescar opciones de dropdowns.
      const mustReloadOptions = columnsSelectedTableRequests.some(column => (column.editable == true && column.type == "select"))
      // console.log("COLUMNS", columnsSelectedTableRequests.some(column => (column.editable == true && column.type=="select")))
      dispatch(getMaintenanceTableInfo({ table })).then((resp) => {

        const { payload } = resp;
        //console.log(payload)
        if (payload.status === 200) {
          setRowsRequests((e) => ({
            ...e,
            rows: payload.data.payload.rows,
            length: payload.data.payload.rows.length,
          }))
        } else {
          setRowsRequests({
            rows: [],
            length: 0,
          })

        }

        if (mustReloadOptions || isFactor) {
          setRefreshOptions(true)
          setLoad(false);
        } else {
          setLoad(false);
        }
      })
    }
  }, [formSelected, reloadTableRequests]);

  //#endregion

  //#region Realizar paginations en la tabla


  const [sizePerPage, setSizePerPage] = useState(10)
  const rows = renderRowsByFilters(rowsRequests.rows);

  //state para definir la pagina inicial del MainTable
  const [page, setPage] = useState(1);


  const paginations = rows.length > sizePerPage ?
    rows.length / sizePerPage > Math.round(rows.length / sizePerPage) ?
      Math.round(rows.length / sizePerPage) + 1
      : Math.round(rows.length / sizePerPage)
    : 1

  const renderPaginations = () => {
    const options = [];
    for (let i = 1; i <= paginations; i++) {
      options.push(
        <PaginationItem className={page === i ? "active" : ""} key={i}>
          <PaginationLink
            onClick={() => handleOnSetPage(i, "page")}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return options.length >= 5 ? page >= 5 ? options.slice(page - 3, page + 2) : options.slice(0, 5) : options;
  }

  //Establece el número de filas por pagina 
  const handleOnSetPage = (page) => {
    setPage(page);
  };

  const handleSetNumPag = (e) => {
    setPage(1);
    const value = e.target.value;
    setSizePerPage(value);
  }


  //#endregion

  //Handle para asignar las nuevas filas editables en el state newInfo.
  const handleInfoEdited = (id, e) => {

    setNewInfoEdited(prevState => ({
      ...prevState,
      [id]: e
    }));

  }

  //Diseñado para cuando cambie la row a editar, que asigne la información de los campos de la fila editables al state newInfoEdited, 
  //para almacenar ahí la info y posterior al dar el botón guardar que mande esa info al API.
  useEffect(() => {
    if (rowToEdit != 0) //Si es 0 no fila seleccionada. 
    {

      let finalJson = {};

      //Averiguar los compos de la fila seleccionada
      let rowSelected = rows.filter((row, i) => row.id == rowToEdit)[0];

      //Averigua los campos editables de la fila actual
      let columnsEditables = columnsSelectedTableRequests.filter(column => column.editable == true);


      columnsEditables.map(column => {
        //Valor del campo editable
        // debugger;
        let valueToAsign = "";

        valueToAsign = rowSelected[column.value]

        //Asigna el campo al array.
        finalJson = { ...finalJson, [column.value]: valueToAsign }
      })


      if (Object.keys(finalJson).length > 0) {
        setNewInfoEdited(finalJson);
      }

    }
  }, [rowToEdit])

  //funcion para agregar una nueva opcion a las listas

  const generateHTMLNewRow = () => {
    let htmlText = ``;

    const editables = columnsSelectedTableRequests.filter(column => column.editable == true);

    editables.map(column => {
      if (column.type == "input") {
        htmlText += `<input class="form-control" id="${column.value}" placeholder="Ingrese ${column.label.toLowerCase()} de SAP"/>
        <br>`;
      } else if (column.type == "select") {
        htmlText += `<select class="form-control" id="${column.value}" placeholder="Ingrese ${column.label.toLowerCase()} de SAP">`

        htmlText += `<option disabled selected>Ingrese ${column.label.toLowerCase()} de SAP</option>`

        options[column.value] && options[column.value].map((option) => {

          htmlText += `<option value="${option.value}">${option.label}</option>`
        })

        htmlText += ` </select>
        <br>`;
      }
    })

    return htmlText;


  }

  //Handle para crear una nueva opción en dropdown.
  const handleNewOption = (e) => {
    //Cerrar la fila editable.
    handleCancellSave()

    MySwal.fire({
      title: `Crear una nueva opción de la lista ${title}`,
      html: generateHTMLNewRow(),
      confirmButtonText: "Crear",
      confirmButtonColor: "#2DCE89",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      preConfirm: () => {

        const editables = columnsSelectedTableRequests.filter(column => column.editable == true);

        let areEmpties = false;

        editables.map(column => {
          if (document.getElementById(column.value).value === "" ||

            /*Validación de opción por defecto "Ingrese __ de SAP" */
            (document.getElementById(column.value).value.split(" ").includes("Ingrese") &&
              document.getElementById(column.value).value.split(" ").includes("SAP"))
          ) {
            areEmpties = true;
          }
        })




        let info = {}

        editables.map(column => {
          info = { ...info, [column.value]: document.getElementById(column.value).value.trim(), }
        })

        //Verifica si este campo ya ha sido creado anteriormente en la tabla.
        // const existAnotherEqual = rows.some(row => {
        //   return Object.keys(info).every(key => {
        //     if (typeof row[key] === 'number') {
        //       return parseInt(row[key]) === parseInt(info[key]);
        //     }
        //     return row[key] === info[key];
        //   });
        // });

        //Verifica si este código ya ha sido creado anteriormente en la tabla.

        const existAnotherCodeEqual = rows.some(row => {

          if (typeof row.code === 'number') {
            return parseInt(row.code) === parseInt(info.code);
          }
          return row.code.trim() === info.code.trim();
        });

        setLoad(true);
        if (areEmpties) {

          MySwal.showValidationMessage("Todos los campos son obligatorios");
          setLoad(false);
        } else if (existAnotherCodeEqual) {
          MySwal.showValidationMessage("Este código ya ha sido creado anteriormente en la tabla.");
          setLoad(false);
        }

        else {

          dispatch(maintenanceAction({ table, type: "add", info, isFactor: isFactor })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {
              Toast.fire({
                title: "Atención",
                html: "Se creó la nueva opción con éxito",
                type: "success",
              });
            } else {
              Toast.fire({
                title: "Atención",
                html: payload.response.data.message,
                type: "warning",
              });
            }
            setLoad(false);
            //Refresca las opciones y pone el spinner
            setReloadTableRequests(prev => !prev);
          });
        }
      },
    });
  }



  return (
    <>


      <Card className=" ">

        <Spin size="large" spinning={load || refreshOptions}>


          <CardHeader className="bg-transparent border-0">

            <Row className=" ">
              <Col xs="7" style={{ display: "flex", justifyContent: "flex-start" }}>
                <Col xs="auto" sm="auto" className="bg-transparent border-0">
                  <h3 className=" mb-0">
                    Filtro </h3>
                </Col>


                <Col xs="auto" sm='auto '>
                  <MultiSelect
                    size="sm"
                    id="multiselectFilter"
                    options={optionsRequestsSelect}
                    value={columnsSelectedTableRequests}
                    onChange={setColumnsSelectedTableRequests}
                    labelledBy="Select"
                  />

                </Col>

              </Col>




              <Col xs="5" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Row>
                  <Col xs="5" >
                    <Button id="addNewOption" className="btn-success" size="sm" block type="button" style={{ width: "100px ", height: "30px" }}
                      onClick={(e) => handleNewOption(e)}
                    >
                      <span className="btn-inner--icon mr-">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">
                        {" "}Nuevo
                      </span>
                    </Button>
                  </Col>
                  <Col xs="5">
                    <Button id="refreshBtn" className="btn-info" size="sm" block type="button" style={{ width: "100px ", height: "30px" }}
                      onClick={(e) => setReloadTableRequests(prev => !prev)}
                    >
                      <span className="btn-inner--icon mr-">
                        <i className="fas fa-sync-alt" />
                      </span>
                      <span className="btn-inner--text">
                        {" "}Refrescar
                      </span>
                    </Button>
                  </Col>

                </Row>

              </Col>
            </Row>

          </CardHeader>

          <CardBody>
            <Table
              className="align-items-center  table-flush"
              responsive
              striped={true}
              hover={true}
            >
              <thead className="thead-light">

                <tr>
                  {
                    columnsSelectedTableRequests.map((item, key) => {
                      return (
                        item.value !== "action" ?

                          <th key={key} className="justify-content-md-center">

                            <div style={{ width: item.width }}>
                              <FormGroup key={key}>
                                <label
                                  style={{ fontSize: "12px" }}
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {`${item.label}:`}
                                </label>
                                <Input
                                  id={item.id}
                                  key={key}
                                  className="form-control"
                                  type="text"
                                  placeholder={`Buscar ${item.label}...`}
                                  onChange={(e) => handleOnfilterDynamic(item.value, e.target.value)}
                                />
                              </FormGroup>
                            </div>
                          </th>
                          :
                          <th key={key} className="justify-content-md-center">

                          </th>


                      )
                    })

                  }
                </tr>
              </thead>
              <tbody className='list'>

                {rows.length > 0 ?

                  rows.slice((page - 1) * sizePerPage, page * sizePerPage).map((row, key) => {
                    return (
                      <tr key={key}>
                        {
                          columnsSelectedTableRequests.map((col, keyCol) => {
                            return (
                              col.label === "Estado" ? (
                                <td key={key + keyCol} className="text-center text-break">

                                  <Badge key={key + keyCol} color={(row[col.value] === "Activo") ? "success" : "danger"} pill>
                                    {row[col.value]}
                                  </Badge>

                                </td>
                              )
                                : col.value === "action" ? (

                                  <td key={key + keyCol} className="justify-content-md-center">
                                    {col.buttons.map((butt, key3) => {
                                      return (


                                        ((butt.haveException == false && row.id != rowToEdit) ||
                                          (butt.id === "delete" && row.id !== rowToEdit && row.active === 1) || //Excepción para el botón eliminar.
                                          (butt.id === "save" && row.id === rowToEdit) ||
                                          (butt.id === "cancell" && row.id === rowToEdit) ||
                                          (butt.id === "restore" && row.id !== rowToEdit && row.active !== 1)
                                        ) &&
                                        <>
                                          < Button
                                            className="btn-icon-only"
                                            outline
                                            color={butt.color}
                                            type="button"
                                            key={"button" + key3}
                                            onClick={(e) => butt.fun(row, newInfoEdited, rows)}
                                            title={butt.title}
                                            id={butt.id}
                                          >
                                            <span className="btn-inner--icon">
                                              <i className={butt.icon} />
                                            </span>
                                          </Button >

                                          <Tooltip key={"Tooltip" + key3} target={butt.id}>{butt.title}</Tooltip>
                                        </>


                                      )
                                    })
                                    }
                                    {row[col.value]}
                                  </td>

                                ) : (

                                  <td key={key + keyCol} className="text-center text-break" style={{ whiteSpace: "normal", textAlign: "auto" }}>
                                    {row.id == rowToEdit && col.editable == true ?
                                      <Row className="d-flex justify-content-center p-0">

                                        <Col xs="12" sm="12" className="p-0">
                                          <Input
                                            id={col.value}
                                            className="form-control text-dark mb-3 ml--3 mr--6 mt-3 "
                                            type={col.type}
                                            value={col.type == "input" ?

                                              newInfoEdited[col.value] //=>Para averiguar los inputs
                                              :
                                              options[col.value] && options[col.value].filter(option => option.value == newInfoEdited[col.value])[0]?.label  //=> Para averiguar la option select.
                                            }
                                            onChange={(e) => handleInfoEdited(col.value,
                                              col.type == "input" ?
                                                e.target.value :
                                                parseInt(e.target.children[e.target.selectedIndex].id))
                                            }
                                          >
                                            <option id=""></option>
                                            {
                                              options[col.value] && options[col.value].map((value) => {
                                                return (
                                                  <option id={value.value}>{value.label}</option>
                                                );
                                              })}
                                          </Input>
                                        </Col>
                                      </Row>

                                      :


                                      col.type == "input" || col.type == undefined ?

                                        row[col.value] //=> Para las input.
                                        :
                                        options[col.value] && options[col.value].filter(option => option.value == row[col.value])[0]?.label  //=> Para averiguar la option select.
                                    }




                                  </td>

                                )
                            )
                          })
                        }

                      </tr>

                    )
                  })
                  :
                  <>
                    <tr>
                      <td className="justify-content-md-center">
                        Sin resultados encontrados
                      </td>
                    </tr>
                  </>
                }

              </tbody>




            </Table>
          </CardBody>
        </Spin>

        <CardFooter>

          <Row className="align-items-center ">
            <Col xs='12' md='6' className="p-1 ">
              <Row className="justify-content-start">
                <Col xs='12' md='6'>
                  <span className='pagination mb-0'>
                    Mostrando del {((page - 1) * sizePerPage) + 1} al {page * sizePerPage > rows.length ? rows.length : page * sizePerPage} de {rows.length} resultados
                  </span>
                </Col>
                <Col xs='12' md='2'>
                  <Input type="select"
                    onChange={(e) => handleSetNumPag(e)}>
                    <option>5</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                  </Input>
                </Col>
              </Row>
            </Col>

            <Col xs='12' md='6' className="p-1 ">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={page === 1 ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => handleOnSetPage(page === 1 ? page : page - 1, "page")}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {
                  renderPaginations()
                }
                <PaginationItem className={page === paginations ? "disabled" : ""}>
                  <PaginationLink
                    onClick={() => handleOnSetPage(page === paginations ? page : page + 1, "page")}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>

        </CardFooter>

      </Card>

    </>
  )
}
