// core antd
import { Divider } from "antd";
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const RequestDetail = (props) => {
  const {
    title,
    parts,
    request,
    equipments,
    equipmentsAssign,
    activityFlow,
    configurations,
    usersAssignment,
    onBack,
    onValidUser,
    onViewEquipments,
    onViewConfigurations,
    onNewActivityFlow,
  } = props;

  return (
    <>
      {request.state === 6 && (
        <Row>
          <Col sm="12" md="3">
            <Card className="bg-gradient-info border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Total Equipos
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {equipments.length}
                    </span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Equipos Completados
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {
                        equipmentsAssign.filter(
                          (row) => row.status === "Completado"
                        ).length
                      }
                    </span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="3">
            <Card className="bg-gradient-danger border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Partes Agregadas
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {parts.length}
                    </span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="3">
            <Card className="bg-gradient-warning border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Partes Completadas
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {parts.filter((row) => row.frus !== null).length}
                    </span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="order-xl-2" md="4">
          <Row>
            <Col>
              <Card className="bg-gradient-default">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-white" tag="h3">
                        Agregar Nota
                      </CardTitle>
                      <blockquote className="blockquote text-white mb-0">
                        <p>
                          Creación de notas al flujo de actividades del
                          requerimiento
                        </p>
                      </blockquote>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fas fa-file-code" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        // outline
                        className="btn-icon btn-3"
                        color="info"
                        type="button"
                        size="sm"
                        onClick={onNewActivityFlow}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-plus-square" />
                        </span>
                        <span className="btn-inner--text">Crear Nota</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {equipments.length ? (
            <Row>
              <Col>
                <Card className="bg-gradient-default">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-white" tag="h3">
                          Equipos Agregados
                        </CardTitle>
                        <blockquote className="blockquote text-white mb-0">
                          <p>
                            Visualizar los equipos agregados al requerimiento
                          </p>
                        </blockquote>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="fas fa-desktop" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          // outline
                          className="btn-icon btn-3"
                          color="info"
                          type="button"
                          size="sm"
                          onClick={onViewEquipments}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-clipboard-list" />
                          </span>
                          <span className="btn-inner--text">Ver Equipos</span>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
          {request.state > 5 && configurations.length ? (
            <Row>
              <Col>
                <Card className="bg-gradient-default">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-white" tag="h3">
                          Configuraciones Agregadas
                        </CardTitle>
                        <blockquote className="blockquote text-white mb-0">
                          <p>
                            Visualizar los archivos de configuración adjuntados
                            al requerimiento
                          </p>
                        </blockquote>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="fas fa-paperclip" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          // outline
                          className="btn-icon btn-3"
                          color="info"
                          type="button"
                          size="sm"
                          onClick={onViewConfigurations}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-folder-open" />
                          </span>
                          <span className="btn-inner--text">Ver Archivos</span>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
          {request.state === 5 && (
            <Row>
              <Col>
                <Card className="bg-gradient-default">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-white" tag="h3">
                          Obtener Configuración
                        </CardTitle>
                        <blockquote className="blockquote text-white mb-0">
                          <p>
                            Asignación de colaborador para obtener la
                            configuración
                          </p>
                        </blockquote>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="fa fa-paste" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" md="6" className="mb-3">
                        <blockquote className="blockquote text-white mb-0">
                          <footer className="blockquote-footer">
                            Usuario Asignado:
                          </footer>
                        </blockquote>
                      </Col>
                      <Col sm="6" md="6">
                        <Badge color="success" pill>
                          {usersAssignment.length
                            ? usersAssignment[0].username
                            : "N/A"}
                        </Badge>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          // outline
                          className="btn-icon btn-3"
                          color="info"
                          type="button"
                          size="sm"
                          onClick={onValidUser}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-user-cog" />
                          </span>
                          <span className="btn-inner--text">
                            {usersAssignment.length
                              ? "Reasignar Usuario"
                              : "Asignar Usuario"}
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <h3 className="mb-0 text-white">Flujo de Actividades</h3>
                </CardHeader>
                <CardBody>
                  <div
                    className="timeline timeline-one-side"
                    data-timeline-axis-style="dashed"
                    data-timeline-content="axis"
                  >
                    {activityFlow.map((row, key) => (
                      <div className="timeline-block" key={key}>
                        <span className={`timeline-step badge-${row.color}`}>
                          <i className={row.icon} />
                        </span>
                        <div className="timeline-content">
                          <small className="text-light font-weight-bold">
                            {moment(row.createdAt)
                              .utc()
                              .utcOffset(moment().utcOffset())
                              .format("LLL")}
                          </small>
                          <h5 className="text-white mt-3 mb-0">
                            {row.description}
                          </h5>
                          <div className="mt-3">
                            <Badge color={row.color} pill>
                              {row.status}
                            </Badge>
                            <Badge color={row.color} pill>
                              {row.createdBy}
                            </Badge>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="order-xl-1" md="8">
          <Row>
            <Col>
              <Card className="mb-4">
                <CardHeader>
                  <Row>
                    <Col xs="12" md="9">
                      <h5 className="surtitle">{title}</h5>
                      <Badge className="badge-lg mt-1" color="primary" pill>
                        v{request.version}
                      </Badge>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="opportunityNumber">
                          Número de Oportunidad
                        </Label>
                        <Input
                          id="opportunityNumber"
                          type="number"
                          className="form-control"
                          value={request.opportunityNumber}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="customer">Nombre del Cliente</Label>
                        <Input
                          id="customer"
                          type="text"
                          className="form-control"
                          value={request.customer}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="requestedExecutive">
                          Ejecutivo de la Solicitud
                        </Label>
                        <Input
                          id="requestedExecutive"
                          type="text"
                          className="form-control"
                          value={request.requestedExecutive}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="salesRep">Representante de Ventas</Label>
                        <Input
                          id="salesRep"
                          type="text"
                          className="form-control"
                          value={request.salesRep}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="typeSupport">Tipo de Soporte</Label>
                        <Input
                          id="typeSupport"
                          type="text"
                          className="form-control"
                          value={request.typeSupport}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="businessModel">Modelo de Negocio</Label>
                        <Input
                          id="businessModel"
                          type="text"
                          className="form-control"
                          value={request.businessModel}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="operatingSystemType">
                          Tipo de Sistema Operativo
                        </Label>
                        <Input
                          id="operatingSystemType"
                          type="text"
                          className="form-control"
                          value={request.operatingSystemType}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="amountOfEquipment">
                          Cantidad de Equipos
                        </Label>
                        <Input
                          id="amountOfEquipment"
                          type="text"
                          className="form-control"
                          value={request.amountOfEquipment}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="officeHours">Horario de Atención</Label>
                        <Input
                          id="officeHours"
                          type="text"
                          className="form-control"
                          value={request.officeHours}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="responseTime">Tiempo de Respuesta</Label>
                        <Input
                          id="responseTime"
                          type="text"
                          className="form-control"
                          value={request.responseTime}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="8">
                      <FormGroup>
                        <Label for="applicationNotes">Notas</Label>
                        <Input
                          id="applicationNotes"
                          type="textarea"
                          className="form-control"
                          value={request.applicationNotes}
                          disabled
                          maxLength={300}
                          rows="3"
                          resize="none"
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="timeChangePart">
                          Tiempo para Cambio de Partes
                        </Label>
                        <Input
                          id="timeChangePart"
                          type="text"
                          className="form-control"
                          value={request.timeChangePart}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="validityService">
                          Vigencia del Servicio
                        </Label>
                        <Input
                          id="validityService"
                          type="text"
                          className="form-control"
                          value={request.validityService}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="wayPay">Forma de Pago del Servicio</Label>
                        <Input
                          id="officwayPayeHours"
                          type="text"
                          className="form-control"
                          value={request.wayPay}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Divider>Detalles de Ubicación</Divider>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="locationType">
                          Ubicación Fisica de los Equipos
                        </Label>
                        <Input
                          id="locationType"
                          type="text"
                          className="form-control"
                          value={request.locationType}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="amountOfEquipmentIn">
                          Cantidad de equipos dentro del radio
                        </Label>
                        <Input
                          id="amountOfEquipmentIn"
                          type="number"
                          className="form-control"
                          value={request.amountOfEquipmentIn}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="distanceInside">
                          Distancia del Centro de Servicios Autorizado
                        </Label>
                        <Input
                          id="distanceInside"
                          type="text"
                          className="form-control"
                          value={request.distanceInside}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="amountOfEquipmentOut">
                          Cantidad de equipos fuera del radio
                        </Label>
                        <Input
                          id="amountOfEquipmentOut"
                          type="text"
                          className="form-control"
                          value={request.amountOfEquipmentOut}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="distanceOutside">
                          Distancia del Centro de Servicios Autorizado
                        </Label>
                        <Input
                          id="distanceOutside"
                          type="text"
                          className="form-control"
                          value={request.distanceOutside}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="localtionNotes">Notas</Label>
                        <Input
                          id="localtionNotes"
                          type="text"
                          className="form-control"
                          value={request.localtionNotes}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Divider>Detalles de Mantenimientos</Divider>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="6">
                      <FormGroup>
                        <Label for="amountMaintenance">
                          Cantidad de Mantenimientos Preventivos
                        </Label>
                        <Input
                          id="amountMaintenance"
                          type="text"
                          className="form-control"
                          value={request.amountMaintenance}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="6">
                      <FormGroup>
                        <Label for="scheduleMaintenance">
                          Horario del Mantenimiento Preventivo
                        </Label>
                        <Input
                          id="scheduleMaintenance"
                          type="text"
                          className="form-control"
                          value={request.scheduleMaintenance}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center justify-content-center">
                    <Col
                      className="mb-3"
                      sm="12"
                      md="3"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      <Button
                        className="btn-icon"
                        color="danger"
                        type="button"
                        block
                        onClick={onBack}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-arrow-left" />
                        </span>
                        <span className="btn-inner--text">Salir</span>
                      </Button>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

RequestDetail.propTypes = {
  title: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
};

export default RequestDetail;
