// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Button, Card, CardBody } from "reactstrap";

/**
 * Componente parametrizado para crear un componente para cada tipo de posicion
 * @param {*} props
 * @returns
 */
const TypePosition = props => {
  const { icon, type, title, subtitle, keyworks, onClick } = props;
  return (
    <>
      <Card className="card-lift--hover shadow border-0" onClick={() => onClick(type)}>
        <CardBody className="py-5">
          <div className="mb-4">
            <Button
              outline
              className="btn-icon-only rounded-circle"
              color="info"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className={icon} />
              </span>
            </Button>
          </div>
          <h4 className="h3 text-primary text-uppercase">
            {title}
          </h4>
          <p className="description mt-3">
            {subtitle}
          </p>
          <div>
            {
              keyworks ?
                keyworks.map((row, key) => {
                  return (
                    <Badge key={key} color="info" pill>
                      {row}
                    </Badge>
                  );
                })
                :
                null
            }
          </div>
        </CardBody>
      </Card>
    </>
  );
};

TypePosition.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  keyworks: PropTypes.array,
  onClick: PropTypes.func.isRequired,
};

export default TypePosition;