// javascript plugin that creates nice dropzones for files
import { Spin } from 'antd';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// react-final-form library
import { Field, Form } from "react-final-form";
// reactstrap components
import { Button, Card, CardBody, CardHeader, Form as FormRS, FormGroup, Input, Label, Modal } from "reactstrap";

const EditVariableModal = props => {

  const {
    message,
    loading,
    modalOptions: {
      type,
      options,
      showModal,
      initialValues,
    },
    toggleModal,
    onEditFlow,
  } = props;

  const validate = values => {
    const errors = {};
    options.forEach(element => {
      if ((!values[element.key] || values[element.key] === "0" || values[element.key] === "") && element.required && element.visible) {
        errors[element.key] = 'Este campo es de carácter obligatorio'
      }
      if (element.min && values[element.key] && element.type !== "number") {
        if (element.min !== values[element.key].length)
          errors[element.key] = `Es obligatorio que cumpla el minimo de ${element.min} caracteres`;
      }
    });
    return errors;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>Edición de Variable Maestra</h4>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={(e) => onEditFlow(e, type)}
              validate={validate}
              initialValues={initialValues}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <Spin size="large" spinning={loading} tip={message}>
                  <FormRS role="form">
                    {
                      options.map((row, key) => (
                        <FormGroup key={key}>
                          <Label for={row.key}>{row.label}</Label>
                          <Field name={row.key}>
                            {({ input, meta }) => (
                              <div>
                                {
                                  row.type === 'select' ?
                                    <Input
                                      {...input}
                                      type={row.type}
                                      valid={!meta.error && meta.modified && meta.touched}
                                      invalid={meta.error && meta.touched}
                                    >
                                      {
                                        row.options.map((element, key) => (
                                          <option key={key} value={element.key}>{element.name}</option>
                                        ))
                                      }
                                    </Input>
                                    :
                                    row.type === 'textarea' ?
                                      <Input
                                        {...input}
                                        maxLength={row.max}
                                        type={row.type}
                                        rows="3"
                                        resize="none"
                                        invalid={meta.error && meta.touched}
                                      />
                                      :
                                      <Input
                                        {...input}
                                        maxLength={row.max}
                                        minLength={row.min}
                                        max={row.max}
                                        min={row.min}
                                        type={row.type}
                                        value={values[row.key]}
                                        invalid={meta.error && meta.touched}
                                      />
                                }
                                {
                                  meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>
                                }

                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      ))
                    }
                    <div className="text-center">
                      <Button
                        disabled={validating || submitting}
                        className="my-4"
                        color="success"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Modificar
                      </Button>
                    </div>
                  </FormRS>
                </Spin>
              )}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

EditVariableModal.propTypes = {
  message: PropTypes.string,
};

export default EditVariableModal;
