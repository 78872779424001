// core antd
import { Drawer, Icon, Upload } from 'antd';
// url variables api
import { urlBase } from "api/urls.jsx";
// core components Request
import AddEquipmentsTable from 'components/Sales/Request/AddEquipmentsTable.jsx';
import UploadConfigurationSC from 'components/Sales/Request/UploadConfigurationSC.jsx';
// import EquipmentsForm from 'components/Sales/Request/EquipmentsForm.jsx';
import TableEquipments from 'components/Sales/Request/TableEquipments.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";

// const { TabPane } = Tabs;
const { Dragger } = Upload;

const EquipmentsListForm = props => {

  const {
    parts,
    flow,
    edit,
    type,
    flavor,
    state,
    // views,
    enabled,
    configSC,
    idRequest,
    request,
    title,
    subtitle,
    formOptions: {
      automaticRenewal,
      // coverageLevel,
      includesParts,
      officeHours,
      practices,
      platforms,
      timeChangePart,
      validityService,
    },
    loadView,
    fileList,
    // countries,
    initialEditValues,
    referenceFilesList,
    equipments,
    equipmentsCreated,
    initialValues,
    onCreate,
    onCancel,
    onChangeValues,
    onBack,
    toggleLoadView,
    // amountEquipments,
    configurationsFilesList,
    onChangeJustify,
    onFileList,
    onFileListReference,
    onConfigSC,
    onCreateEquipments,
    onEditEquipment,
    onUpdateEquipment,
    onDeleteEquipment,
    onCompleteEquipments,
    onSaveAndBack,
    onCompleteRequest,
    onAddEquipment,
    onFileListConfigSC,
  } = props;

  // const [tabKey, setTabKey] = useState("1");

  const uploadProps = {
    name: 'file',
    accept: ".xlsx",
    multiple: false,
    action: `${urlBase}/auth/test`,
    onChange: onFileList,
  };

  const uploadProps2 = {
    name: 'reference',
    multiple: true,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    action: `${urlBase}/digital-request/upload-references-files/${idRequest}`,
    onChange: onFileListReference,
  };

  // const changeTab = value => {
  //   setTabKey(value);
  // };

  const verifyAddPrice = () => {
    let flag = false;
    const { ibmEquipments, ciscoEquipments } = equipments;
    const { ibm, cisco } = equipmentsCreated;
    const { IBM, CISCO } = flow;
    if ((ibmEquipments.length || ibm.length) && IBM === 'complete') {
      ibmEquipments.forEach(element => {
        if (parseInt(element.Precio) > 0) {
          flag = true;
        }
      });
      ibm.forEach(element => {
        if (parseInt(element.price) > 0) {
          flag = true;
        }
      });
    }
    if ((ciscoEquipments.length || cisco.length) && CISCO === 'complete') {
      ciscoEquipments.forEach(element => {
        if (parseInt(element.Precio) > 0) {
          flag = true;
        }
      });
      cisco.forEach(element => {
        if (parseInt(element.price) > 0) {
          flag = true;
        }
      });
    }
    return flag;
  };

  const indexValidityService = validityService.findIndex((row) => row.name === request.validityService);
  const validityServiceFix = indexValidityService >= 0 ? validityService[indexValidityService] : validityService;

  return (
    <>
      <UploadConfigurationSC
        title="Configuración"
        subtitle="Adjuntar los archivos referentes a la configuración"
        showModal={configSC}
        idRequest={idRequest}
        fileList={configurationsFilesList}
        onFileList={onFileListConfigSC}
        onComplete={() => onCompleteRequest(verifyAddPrice())}
      />
      <Drawer
        title="Cargar lista de Equipos"
        width={340}
        onClose={toggleLoadView}
        visible={loadView}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {
          !fileList.length &&
          <div className="form-row align-items-center justify-content-center mb-3">
            <Col className="text-right" xs="4">
              <Button
                color="default"
                size="sm"
                href={`${urlBase}/digital-request/get-equipments-template/${idRequest}`}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-file-download" />
                </span>
                <span className="btn-inner--text">Descargar Plantilla</span>
              </Button>
            </Col>
          </div>
        }
        <blockquote className="blockquote text-center">
          <p className="mb-0">
            Selección del archivo oficial para cargar la lista de equipos
          </p>
          <footer className="blockquote-footer">
            <cite title="Source Title">Lista paralela de equipos</cite>
          </footer>
        </blockquote>
        <div className="form-row align-items-center">
          <Col className="m-1" sm="12">
            <Dragger {...uploadProps} fileList={fileList}>
              <p className="ant-uploading-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
              <p className="ant-uploading-hint">
                *Se debe cargar el machote oficial de lista de equipos.
              </p>
            </Dragger>
          </Col>
        </div>
      </Drawer>
      <Card>
        <CardHeader>
          <Row>
            <Col xs="12" md='7'>
              <h6 className="surtitle">{title}</h6>
              <h5 className="h3 mb-0">{subtitle}</h5>
            </Col>
            <Col className="text-right" xs="12" md='5'>
              {
                (flow['IBM'] === "complete" || flow['CISCO'] === "complete") ?
                  <Row style={{ justifyContent: "flex-end" }}>
                    {
                      verifyAddPrice() &&
                      <Col xs='12' md='7' className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          className="btn-round btn-icon"
                          // outline
                          color="success"
                          id="add"
                          onClick={
                            type === 1 ? () => onCompleteRequest(verifyAddPrice())
                              :
                              () => onConfigSC(verifyAddPrice())
                          }
                          size="sm"
                          block
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-share-square" />
                          </span>
                          <span className="btn-inner--text">Carga Finalizada-Continuar</span>
                        </Button>
                      </Col>
                    }
                    <Col xs='12' md='5' className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        className="btn-round btn-icon"
                        // outline
                        color="success"
                        id="add"
                        onClick={onSaveAndBack}
                        size="sm"
                        block
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-save" />
                        </span>
                        <span className="btn-inner--text">Guardar</span>
                      </Button>
                    </Col>
                  </Row>
                  :
                  <Row style={{ justifyContent: "flex-end" }}>
                    <Col xs='12' md='6' className="p-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        className="btn-round btn-icon"
                        color="success"
                        disabled={enabled}
                        id="add"
                        onClick={toggleLoadView}
                        size="sm"
                        block
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-folder-plus" />
                        </span>
                        <span className="btn-inner--text">{(equipments.ibmEquipments.length || equipments.ciscoEquipments.length) ? 'Ver Archivo Cargado' : 'Cargar Equipos'}</span>
                      </Button>
                    </Col>
                  </Row>
              }
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="form-row align-items-center justify-content-center">
            <blockquote className="blockquote text-center">
              <p className="mb-0">
                Información Importante
              </p>
              {
                type === 1 ?
                  <footer className="blockquote-footer">
                    <cite title="Source Title">
                      Recuerde ingresar el <strong><ins>precio de lista</ins></strong> de los equipos
                    </cite>
                  </footer>
                  :
                  <footer className="blockquote-footer">
                    <cite title="Source Title">
                      Considere el <strong><ins>costo base</ins></strong> de los equipos
                    </cite>
                  </footer>
              }
            </blockquote>
          </div>
          {
            equipments.ibmEquipments.length ?
              <TableEquipments
                edit={edit}
                title="Lista de Equipos Cargados"
                type="IBM"
                state={flow}
                cols={[
                  { name: "País", key: "Pais", hide: false },
                  { name: "Tipo de Modelo", key: "TipoModelo", hide: false },
                  { name: "Serie", key: "Serie", hide: type === 2 ? true : false },
                  { name: "Práctica", key: "Practica", hide: false },
                  { name: "Cantidad de Mantenimientos", key: "CantidadMantenimientos", hide: false },
                  { name: "Cantidad de Equipos", key: "CantidadEquipos", hide: type === 1 ? true : false },
                  { name: "Plataforma", key: "Plataforma", hide: false },
                  { name: "Horario de Atención", key: "HorarioAtencion", hide: false },
                  { name: "Tiempo Cambio de Parte", key: "TiempoCambioParte", hide: false },
                  { name: "Años del Servicio", key: "AñosServicio", hide: false },
                  { name: "Renovación Automatica", key: "RenovacionAutomatica", hide: false },
                  { name: "Incluye Partes/Equipos", key: "IncluyePartesEquipos", hide: false },
                  { name: "Viáticos $", key: "Viaticos", hide: false },
                  { name: "Garantía Vigente", key: "GarantiaVigente", hide: false },
                  { name: `${type === 1 ? "Precio $" : "Costo $"}`, key: "Precio", hide: false }
                ]}
                colsCreated={[
                  { name: "País", key: "country", hide: false },
                  { name: "Tipo de Modelo", key: "typeModel", hide: false },
                  { name: "Serie", key: "serial", hide: type === 2 ? true : false },
                  { name: "Práctica", key: "practice", hide: false },
                  { name: "Cantidad de Mantenimientos", key: "amountMaintenance", hide: false },
                  { name: "Cantidad de Equipos", key: "amountEquipments", hide: type === 1 ? true : false },
                  { name: "Plataforma", key: "platform", hide: false },
                  { name: "Horario de Atención", key: "officeHour", hide: false },
                  { name: "Tiempo Cambio de Parte", key: "timeChangePart", hide: false },
                  { name: "Años del Servicio", key: "validityService", hide: false },
                  { name: "Renovación Automatica", key: "automaticRenewal", hide: false },
                  { name: "Incluye Partes/Equipos", key: "equipmentParts", hide: false },
                  // { name: "Cantidad/Hr/Mant", key: "amountHRMant" },
                  { name: "Viáticos $", key: "viatic", hide: false },
                  { name: "Garantía Vigente", key: "validWarranty", hide: false },
                  { name: `${type === 1 ? "Precio $" : "Costo $"}`, key: "price", hide: false }
                ]}
                options={[
                  {
                    name: "País", key: "country", placeholder: "Ingrese el País", options: [
                      { id: "CR", name: "Costa Rica", key: "CR" },
                      { id: "SV", name: "El Salvador", key: "SV" },
                      { id: "GT", name: "Guatemala", key: "GT" },
                      { id: "HN", name: "Honduras", key: "HN" },
                      { id: "MD", name: "Miami", key: "MD" },
                      { id: "NI", name: "Nicaragua", key: "NI" },
                      { id: "PA", name: "Panamá", key: "PA" },
                      { id: "DO", name: "República Dominicana", key: "DO" },
                    ], type: "select", hide: false
                  },
                  { name: "Tipo de Modelo", key: "typeModel", placeholder: "Ingrese el tipo de modelo", type: "text", max: 50, hide: false },
                  { name: "Serie", key: "serial", placeholder: "Ingrese la Serie", type: "text", max: 50, hide: type === 2 ? true : false },
                  { name: "Práctica", key: "practice", placeholder: "Seleccione una opción", options: practices, type: "select", hide: false },
                  { name: "Cant de Mantenimientos", key: "amountMaintenance", placeholder: "Ingrese la cantidad de mantenimientos", type: "number", max: 10, min: parts === false ? 0 : flavor === 'platinum' ? 2 : flavor === 'gold' ? 1 : 0, hide: false },
                  { name: "Cant de Equipos", key: "amountEquipments", placeholder: "Ingrese la cantidad de equipos", type: "number", max: 99, min: 1, hide: type === 1 ? true : false },
                  { name: "Plataforma", key: "platform", placeholder: "Seleccione una opción", options: platforms, type: "select", hide: false },
                  { name: "Horario de Atención", key: "officeHours", placeholder: "Seleccione una opción", options: officeHours, type: "select", hide: false },
                  { name: "Tiempo Cambio de Parte", key: "timeChangePart", placeholder: "Seleccione una opción", options: !parts ? timeChangePart.filter((row) => row.name === "Mejor Esfuerzo") : timeChangePart, type: "select", hide: false },
                  { name: "Años del Servicio", key: "validityService", placeholder: "Seleccione una opción", options: [validityServiceFix], type: "select", hide: false },
                  { name: "Renovación Automatica", key: "automaticRenewal", placeholder: "Seleccione una opción", options: automaticRenewal, type: "select", hide: false },
                  { name: "Incluye Partes/Equipos", key: "equipmentParts", placeholder: "Seleccione una opción", options: !parts ? includesParts.filter((row) => row.name === "No aplica") : includesParts, type: "select", hide: false },
                  { name: "Viáticos $", key: "viatic", placeholder: "Ingrese los Viáticos", type: "number", max: 99999999, min: 0, hide: false },
                  { name: "Garantía Vigente", key: "validWarranty", placeholder: "Ingrese la Garantía Vigente", type: "number", max: 9, min: type === 2 ? 1 : 0, hide: false },
                  { name: `${type === 1 ? "Precio $" : "Costo $"}`, key: "price", placeholder: "Ingrese el Precio", type: "number", max: 99999999, min: 1, hide: false },
                ]}
                rows={equipments.ibmEquipments}
                rowsCreated={equipmentsCreated.ibm}
                initialEditValues={initialEditValues}
                onChange={onChangeValues}
                onEdit={onEditEquipment}
                onUpdate={onUpdateEquipment}
                onDelete={onDeleteEquipment}
                onCompleteEquipments={onCompleteEquipments}
                onCreateEquipments={onCreateEquipments}
              />
              :
              <AddEquipmentsTable
                edit={edit}
                title="Lista de Equipos"
                state={state}
                options={[
                  {
                    name: "País", key: "country", placeholder: "Ingrese el País", options: [
                      { id: "CR", name: "Costa Rica", key: "CR" },
                      { id: "SV", name: "El Salvador", key: "SV" },
                      { id: "GT", name: "Guatemala", key: "GT" },
                      { id: "HN", name: "Honduras", key: "HN" },
                      { id: "MD", name: "Miami", key: "MD" },
                      { id: "NI", name: "Nicaragua", key: "NI" },
                      { id: "PA", name: "Panamá", key: "PA" },
                      { id: "DO", name: "República Dominicana", key: "DO" },
                    ], type: "select", hide: false
                  },
                  { name: "Tipo de Modelo", key: "typeModel", placeholder: "Ingrese el tipo de modelo", type: "text", max: 50, hide: false },
                  { name: "Serie", key: "serial", placeholder: "Ingrese la Serie", type: "text", max: 50, hide: type === 2 ? true : false },
                  { name: "Práctica", key: "practice", placeholder: "Seleccione una opción", options: practices, type: "select", hide: false },
                  { name: "Cant de Mantenimientos", key: "amountMaintenance", placeholder: "Ingrese la cantidad de mantenimientos", type: "number", max: 10, min: parts === false ? 0 : flavor === 'platinum' ? 2 : flavor === 'gold' ? 1 : 0, hide: false },
                  { name: "Cant de Equipos", key: "amountEquipments", placeholder: "Ingrese la cantidad de equipos", type: "number", max: 99, min: 1, hide: type === 1 ? true : false },
                  { name: "Plataforma", key: "platform", placeholder: "Seleccione una opción", options: platforms, type: "select", hide: false },
                  { name: "Horario de Atención", key: "officeHours", placeholder: "Seleccione una opción", options: officeHours, type: "select", hide: false },
                  { name: "Tiempo Cambio de Parte", key: "timeChangePart", placeholder: "Seleccione una opción", options: timeChangePart, type: "select", hide: false },
                  { name: "Años del Servicio", key: "validityService", placeholder: "Seleccione una opción", options: [validityServiceFix], type: "select", hide: false },
                  { name: "Renovación Automatica", key: "automaticRenewal", placeholder: "Seleccione una opción", options: automaticRenewal, type: "select", hide: false },
                  { name: "Incluye Partes/Equipos", key: "equipmentParts", placeholder: "Seleccione una opción", options: includesParts, type: "select", hide: false },
                  { name: "Viáticos $", key: "viatic", placeholder: "Ingrese los Viáticos", type: "number", max: 99999999, min: 1, hide: false },
                  { name: "Garantía Vigente", key: "validWarranty", placeholder: "Ingrese la Garantía Vigente", type: "number", max: 9, min: type === 1 ? 0 : 1, hide: false },
                  { name: `${type === 1 ? "Precio $" : "Costo $"}`, key: "price", placeholder: "Ingrese el Precio", type: "number", max: 99999999, min: 1, hide: false },
                ]}
                cols={[
                  { name: "País", key: "country", hide: false },
                  { name: "Tipo de Modelo", key: "typeModel", hide: false },
                  { name: "Serie", key: "serial", hide: type === 2 ? true : false },
                  { name: "Práctica", key: "practice", hide: false },
                  { name: "Cantidad de Mantenimientos", key: "amountMaintenance", hide: false },
                  { name: "Cantidad de Equipos", key: "amountEquipments", hide: type === 1 ? true : false },
                  { name: "Plataforma", key: "platform", hide: false },
                  { name: "Horario de Atención", key: "officeHour", hide: false },
                  { name: "Tiempo Cambio de Parte", key: "timeChangePart", hide: false },
                  { name: "Años del Servicio", key: "validityService", hide: false },
                  { name: "Renovación Automatica", key: "automaticRenewal", hide: false },
                  { name: "Incluye Partes/Equipos", key: "equipmentParts", hide: false },
                  // { name: "Cantidad/Hr/Mant", key: "amountHRMant" },
                  { name: "Viáticos $", key: "viatic", hide: false },
                  { name: "Garantía Vigente", key: "validWarranty", hide: false },
                  { name: `${type === 1 ? "Precio $" : "Costo $"}`, key: "price", hide: false }
                ]}
                initialValues={initialValues}
                initialEditValues={initialEditValues}
                rows={equipmentsCreated.ibm}
                onChange={onChangeValues}
                onCreate={onCreate}
                onEdit={onEditEquipment}
                onUpdate={onUpdateEquipment}
                onDelete={onDeleteEquipment}
                onAddEquipment={onAddEquipment}
                onCompleteEquipments={onCompleteEquipments}
              />
          }
          {/* <Tabs defaultActiveKey={tabKey} onChange={changeTab}>
            <TabPane tab="Equipos " key="1">
              {
                equipments.ibmEquipments.length ?
                  <TableEquipments
                    title="Lista de Equipos "
                    type="IBM"
                    state={flow}
                    cols={[
                      { name: "País", key: "Pais" },
                      { name: "Tipo de Modelo", key: "TipoModelo" },
                      { name: "Serie", key: "Serie" },
                      { name: "Plataforma", key: "Plataforma" },
                      { name: "Horario de Atención", key: "HorarioAtencion" },
                      { name: "Tiempo Cambio de Parte", key: "TiempoCambioParte" },
                      { name: "Vigencia", key: "Vigencia" },
                      { name: "Renovación Automatica", key: "RenovacionAutomatica" },
                      { name: "Partes Críticas", key: "IncluyePartesCriticas" },
                      { name: "Precio", key: "Precio" }
                    ]}
                    rows={equipments.ibmEquipments}
                    onCompleteEquipments={onCompleteEquipments}
                    onCreateEquipments={onCreateEquipments}
                  />
                  :
                  <>
                    {
                      flow['IBM'] !== "complete" ?
                        <EquipmentsForm
                          type={"IBM"}
                          initialValues={initialValues}
                          data={formOptions}
                          isAdd={equipmentsCreated.ibm.length}
                          onCreate={onCreate}
                          onCancel={onCancel}
                        />
                        : null
                    }
                    {
                      equipmentsCreated.ibm.length ?
                        <TableEquipments
                          title="Lista de Equipos  Agregados"
                          type="IBM"
                          state={flow}
                          cols={[
                            { name: "País", key: "country" },
                            { name: "Tipo de Modelo", key: "typeModel" },
                            { name: "Serie", key: "serial" },
                            { name: "Plataforma", key: "platform" },
                            { name: "Horario de Atención", key: "officeHour" },
                            { name: "Tiempo Cambio de Parte", key: "timeChangePart" },
                            { name: "Vigencia", key: "validityService" },
                            { name: "Renovación Automatica", key: "automaticRenewal" },
                            { name: "Partes Críticas", key: "criticalPart" },
                            { name: "Precio", key: "price" }
                          ]}
                          rows={equipmentsCreated.ibm}
                          onCompleteEquipments={onCompleteEquipments}
                        />
                        : null
                    }
                  </>
              }
            </TabPane>
            <TabPane tab="Equipos CISCO" key="2">
              {
                equipments.ciscoEquipments.length ?
                  <TableEquipments
                    title="Lista de Equipos CISCO"
                    type="CISCO"
                    state={flow}
                    cols={[
                      { name: "Serial", key: "Serial" },
                      { name: "Número Producto", key: "NumeroProducto" },
                      { name: "Descripción", key: "Descripcion" },
                      { name: "Horario de Atención", key: "HorarioAtencion" },
                      { name: "Nivel de Cobertura", key: "NivelCobertura" },
                      { name: "Vigencia", key: "Vigencia" },
                      { name: "Precio", key: "Precio" }
                    ]}
                    rows={equipments.ciscoEquipments}
                    onCompleteEquipments={onCompleteEquipments}
                    onCreateEquipments={onCreateEquipments}
                  />
                  :
                  <>
                    {
                      flow['CISCO'] !== "complete" ?
                        <EquipmentsForm
                          type={"CISCO"}
                          initialValues={initialValues}
                          data={formOptions}
                          isAdd={equipmentsCreated.cisco.length}
                          onCreate={onCreate}
                          onCancel={onCancel}
                        />
                        : null
                    }
                    {
                      equipmentsCreated.cisco.length ?
                        <TableEquipments
                          title="Lista de Equipos CISCO"
                          type="CISCO"
                          state={flow}
                          cols={[
                            { name: "Serial", key: "serial" },
                            { name: "Número Producto", key: "productNumber" },
                            { name: "Descripción", key: "description" },
                            { name: "Horario de Atención", key: "officeHour" },
                            { name: "Nivel de Cobertura", key: "coverageLevel" },
                            { name: "Vigencia", key: "validityService" },
                            { name: "Precio", key: "price" }
                          ]}
                          rows={equipmentsCreated.cisco}
                          onCompleteEquipments={onCompleteEquipments}
                        />
                        : null
                    }
                  </>
              }
            </TabPane>
          </Tabs> */}
          {
            verifyAddPrice() &&
            <div className="form-row align-items-center">
              <Col sm='12' className="m-1">
                <blockquote className="blockquote text-center">
                  <p className="mb-0">
                    Cargar Referencia
                  </p>
                  <footer className="blockquote-footer">
                    <cite title="Source Title">Por favor, cargue la referencia para el precio o los precios que indicó en los equipos</cite>
                  </footer>
                </blockquote>
                <div className="form-row align-items-center">
                  <Col className="m-1" sm="12">
                    <Dragger {...uploadProps2} fileList={referenceFilesList}>
                      <p className="ant-uploading-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-uploading-text">Suelte el archivo aquí o haga clic para cargar.</p>
                      <p className="ant-uploading-hint">
                        *Se debe cargar la referencia para los precios ingresados.
                      </p>
                    </Dragger>
                  </Col>
                </div>
              </Col>
              <Col sm='12' className="m-1">
                <FormGroup>
                  <label
                    className="d-flex justify-content-center"
                    htmlFor="justify"
                  >
                    <blockquote className="blockquote text-center">
                      <p className="mb-0">
                        Justificación
                      </p>
                      <footer className="blockquote-footer">
                        <cite title="Source Title">En caso de no contar con un archivo de referencia</cite>
                      </footer>
                    </blockquote>
                  </label>
                  <Input
                    id="justify"
                    resize="none"
                    rows="5"
                    type="textarea"
                    onChange={(e) => onChangeJustify(e)}
                    maxLength={500}
                  />
                </FormGroup>
              </Col>
            </div>
          }
          <div className="form-row align-items-center">
            <Col className="m-2" sm="12" md="3">
              <Button
                className="btn-icon"
                color="success"
                onClick={onBack}
                size='sm'
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-arrow-left" />
                </span>
                <span className="btn-inner--text">Regresar</span>
              </Button>
            </Col>
          </div>
          <div className="form-row align-items-center justify-content-center">
            {
              !(equipmentsCreated.ibm.length || equipments.ibmEquipments.length) ?
                <Col className="mb-3" sm="12" md="3" style={{
                  justifyContent: "center", display: "flex", alignContent: "center"
                }}>
                  <Button
                    className="btn-icon"
                    color="danger"
                    type="button"
                    block
                    onClick={onCancel}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-times" />
                    </span>
                    <span className="btn-inner--text">Completar Luego</span>
                  </Button>
                </Col>
                : null
            }
          </div>
        </CardBody>
      </Card>
    </>
  );
};

EquipmentsListForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default EquipmentsListForm;
