/*!

=========================================================
*Graphs
=========================================================

*Componente para generar los gráficos de estadísticas de 
tipo línea, barra, entre otros los cuales ayudaron a 
construir los gráficos de ahorro por mes y por año.
=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/

//Componentes de la librería de react.
import React, { useState, useContext, useEffect } from 'react'

//Import de componente contexto para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Librería para generar la tabla de estadística.
import { Line, Bar } from "react-chartjs-2";

//Componentes de la librería de reactstrap.
import { Card, CardHeader, CardBody, Col, Row, Button } from "reactstrap";

//Compenente spinner de la librería antd.
import { Spin } from 'antd';

//Dispatch para hacer peticiones
import { useDispatch } from 'react-redux';

//Componente para emitir notificaciones
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//Actions para realizar peticiones al API
import { getGraphsData } from 'actions/databotDashboard.jsx';

import numeral from 'numeral';
export const Graphs = ({ type }) => {

  //#region States Globales
  const {


    filterSelection,
    setLoadingColorSpinner,

    drawerHelpComponents,
    setDrawerHelpComponents,

    setLoad,

  } = useContext(UserContext);
  //#endregion


  //#region Mensajes de notificaciones
  const MySwal = withReactContent(Swal);
  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  //#endregion



  //#region ejecutar fetch
  const dispatch = useDispatch();
  //#endregion


  //#region States locales


  const [spinner, setSpinner] = useState(false);

  const [radioButtonsGraph, setRadioButtonsGraph] = useState(
    [
      { state: true, label: "Ahorro", id: "saving" },
      { state: false, label: "Iteraciones", id: "iterations" },
      { state: false, label: "Ahorro Líneas", id: "savingWithLines" },
      { state: false, label: "Iteraciones Líneas", id: "iterationsWithLines" },

    ]
  );

  const [graphActive, setgraphActive] = useState("saving");


  const [text, setText] = useState(
    {
      titleMonth: "Ahorro por Mes RPA (USD)",
      subtitleMonth: "Estadísticas Databot",
      titleYear: "Ahorro útimos 3 años RPA",
      subtitleYear: "Estadísticas Databot"
    })

  let { titleMonth, subtitleMonth, titleYear, subtitleYear } = text
  //endregion




  //State para almacenar la información precargada de los gráficos.
  const [infoChargedGraphs, setInfoChargedGraphs] = useState({
    saving: { byMonth: [], byYears: [] },
    iterations: { byMonth: [], byYears: [] },
    savingWithLines: { byMonth: [], byYears: [] },
    iterationsWithLines: { byMonth: [], byYears: [] },
  })



  //State para los gráficos
  const [graphsData, setGraphsData] = useState([])


  //Método para verificar si los filtros están limpios o hay alguna selección en ellos
  const filtersAreClean = () => {
    if (
      ((filterSelection?.year.label) == undefined || filterSelection?.year.label == "") &&
      ((filterSelection?.tower) == undefined || filterSelection?.tower == "") &&
      ((filterSelection?.functionalArea) == undefined || filterSelection?.functionalArea == "") &&
      ((filterSelection?.department) == undefined || filterSelection?.department == "") &&
      ((filterSelection?.bots) == undefined || filterSelection?.bots == "")
    ) {
      return true;
    } else { return false }
  }




  //Hook para refrescar la data de la tabla 
  useEffect(() => {

    //setLoad(true);
    if (!filtersAreClean()) { //Significa que es una pe
      // console.log("Se ejecuta una petición aparte")
      const getGraphsDataR = async () => {
        await handleGetGraphsData();
      }
      getGraphsDataR();
    } else { //Para establecer la información precargada

      if (infoChargedGraphs[graphActive].byMonth.length > 0 /*&& graphsData?.byMonth == undefined*/) //Quiere decir que tiene info precargada
      {

        setGraphsData(grapData => {
          return {
            ...grapData,
            ['byMonth']: infoChargedGraphs[graphActive].byMonth,
            ['byYears']: infoChargedGraphs[graphActive].byYears,
          }
        })
      }

    }
  }, [filterSelection, graphActive, infoChargedGraphs]) //Cuando cambie la selección del filtro ejecute la petición.



  //#region Extracción de información de los APIS



  //#region ******** Extracción ARRANQUE ********


  useEffect(() => {
    const getDataAtStart = async () => {
      // debugger;
      await handleGetGraphsDataStart();

    }
    getDataAtStart();
  }, [])


  //Funcion para traer las rows de la data de la tabla
  const handleGetGraphsData = async () => {


    //Activar el spinner
    setSpinner(true)

    dispatch(getGraphsData({
      year: (filterSelection?.year.label) != undefined ? filterSelection?.year.label : new Date().getFullYear(),
      tower: (filterSelection?.tower) != undefined ? filterSelection?.tower : '',
      functionalArea: (filterSelection?.functionalArea) != undefined ? filterSelection?.functionalArea : '',
      department: (filterSelection?.department) != undefined ? filterSelection?.department : '',
      bots: (filterSelection?.bots) != undefined ? filterSelection?.bots : '',
      view: graphActive,
      mode: 'during'
    }
    )).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {

        // console.log("Aqui GraphsData")

        // console.log(payload.data);
        setSpinner(false)

        setGraphsData(grapData => {
          return {
            ...grapData,
            ['byMonth']: payload.data.byMonth,
            ['byYears']: payload.data.byYears[0],
          }
        })



      }
      else {
        Toast.fire({
          title: payload.message,
          type: 'warning'
        })
      }
    })


  }


  //Funcion para traer las rows al arrancar
  const handleGetGraphsDataStart = async () => {


    //Activar el spinner
    setSpinner(true)

    //Dispatch de saving
    await dispatch(getGraphsData({
      year: new Date().getFullYear(),
      tower: '',
      functionalArea: '',
      department: '',
      bots: '',
      view: 'saving',
      mode: 'start'
    }
    )).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {
        setSpinner(false)

        // console.log("Aqui GraphsData saving")

        // console.log(payload.data);
        setLoadingColorSpinner(false)

        setLoad(false);




        setGraphsData(grapData => {
          return {
            ...grapData,
            ['byMonth']: payload.data.saving.byMonth,
            ['byYears']: payload.data.saving.byYears[0],
          }
        })

        setInfoChargedGraphs(info => {
          return {
            ...info,
            ['saving']: {
              ...info.saving,
              ['byMonth']: payload.data.saving.byMonth,
              ['byYears']: payload.data.saving.byYears[0],
            }

          }
        })


      }
      else {
        Toast.fire({
          title: payload.message,
          type: 'warning'
        })
      }
    })


    //Dispatch de iterations
    dispatch(getGraphsData({
      year: new Date().getFullYear(),
      tower: '',
      functionalArea: '',
      department: '',
      bots: '',
      view: 'iterations',
      mode: 'start'
    }
    )).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {
        setSpinner(false)

        setLoadingColorSpinner(false)

        setInfoChargedGraphs(info => {
          return {
            ...info,
            ['iterations']: {
              ...info.iterations,
              ['byMonth']: payload.data.iterations.byMonth,
              ['byYears']: payload.data.iterations.byYears[0],
            }

          }
        })

      }
      else {
        Toast.fire({
          title: payload.message,
          type: 'warning'
        })
      }
    })

    //Dispatch de savingWithLines
    dispatch(getGraphsData({
      year: new Date().getFullYear(),
      tower: '',
      functionalArea: '',
      department: '',
      bots: '',
      view: 'savingWithLines',
      mode: 'start'
    }
    )).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {
        setSpinner(false)

        setLoadingColorSpinner(false)


        setInfoChargedGraphs(info => {
          return {
            ...info,
            ['savingWithLines']: {
              ...info.savingWithLines,
              ['byMonth']: payload.data.savingWithLines.byMonth,
              ['byYears']: payload.data.savingWithLines.byYears[0],
            }

          }
        })

      }
      else {
        Toast.fire({
          title: payload.message,
          type: 'warning'
        })
      }
    })

    //Dispatch de iterationsWithLines
    dispatch(getGraphsData({
      year: new Date().getFullYear(),
      tower: '',
      functionalArea: '',
      department: '',
      bots: '',
      view: 'iterationsWithLines',
      mode: 'start'
    }
    )).then((resp) => {
      const { payload } = resp;

      if (payload.status === 200) {
        setSpinner(false)

        setLoadingColorSpinner(false)


        setInfoChargedGraphs(info => {
          return {
            ...info,
            ['iterationsWithLines']: {
              ...info.iterationsWithLines,
              ['byMonth']: payload.data.iterationsWithLines.byMonth,
              ['byYears']: payload.data.iterationsWithLines.byYears[0],
            }

          }
        })

      }
      else {
        Toast.fire({
          title: payload.message,
          type: 'warning'
        })
      }
    })




  }

  //#endregion 


  //#region Métodos útiles

  //Cambia la información de la tabla según la selección del radio
  const onChangeChart = event => {

    let radioItem = event.target;

    let aux = radioButtonsGraph.map((radioMap, index) => {
      if (radioMap.id != radioItem.id) { return { ...radioMap, state: false } }
      else { return { ...radioMap, state: true } }
    })

    // // setRadioButtons(radioButtons => { return { ...radioButtons, lineGraph: aux } })

    setRadioButtonsGraph(radioButtons => { return aux })
    setgraphActive(radioItem.id)



    //Para activar el spinner de los gráficos.
    delete graphsData.byMonth;

  }

  //Genera la información moldeada para el gráfico seleccionado
  const generateInformationToGraph = () => {
    let toReturn = ''

    if (graphActive === 'saving' && graphsData?.byMonth != undefined) {
      toReturn = [
        {
          label: "Ahorro por mes (USD)",
          data: (graphsData?.byMonth != undefined && Object?.values(graphsData?.byMonth[0]).map(value => numeral(value).format('0.00')))
            .map(value => numeral(value).format('0.00')),
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)"
        },
      ]


      return toReturn;

    } else if (graphActive === 'savingWithLines' && graphsData?.byMonth != undefined) {
      if (graphsData?.byMonth != undefined && graphsData?.byMonth.length > 0) {

        let rows = (graphsData?.byMonth != undefined && graphsData?.byMonth);


        toReturn = rows.map(row => {

          let rowA = { ...row };

          let name = rowA.name;
          let color = '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')



          delete rowA.name

          let toReturnRow = {
            label: name,
            data: (Object?.values(rowA).map(value => numeral(value).format('0.00'))
            ),
            fill: false,
            borderColor: color,
          }
          return toReturnRow

        })


        if (toReturn[0].label != undefined) {
          return toReturn;
        }

      }


    } else if (graphActive === 'iterationsWithLines' && graphsData?.byMonth != undefined) {
      if (graphsData?.byMonth != undefined && graphsData?.byMonth.length > 0) {

        let rows = (graphsData?.byMonth != undefined && graphsData?.byMonth);


        toReturn = rows.map(row => {

          let rowA = { ...row };

          let name = rowA.name;
          let color = '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')
          let color2 = "rgba(75,192," + Math.round(Math.random() * 255);



          delete rowA.name

          let toReturnRow = {
            label: name,
            data: (Object?.values(rowA).map(value => numeral(value).format('0.00'))),
            fill: false,
            borderColor: color,
          }
          return toReturnRow

        })


        if (toReturn[0].label != undefined) {
          return toReturn;
        }

      }


    } else if (graphActive === 'iterations' && graphsData?.byMonth != undefined) {
      toReturn = [
        {
          label: "Iteraciones mensuales",
          data: (graphsData?.byMonth[0] != undefined && Object?.values(graphsData?.byMonth[0]).map(value => numeral(value).format('0.00'))),
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)"
        },
      ]


      return toReturn;

    }
  }

  let delayed;


  //Ultimos 3 años
  const getLast3Years = () => {
    let currentYear = new Date().getFullYear();
    let counter = 3;

    let years = [];
    while (counter != 0) {
      //2023,2022,2021                
      years = [...years, currentYear - (counter - 1)];
      counter--;
    }

    return years.sort((a, b) => parseFloat(a) - parseFloat(b));
  }



  //Cantidad de filas.
  const quantityRows = () => {

    if (graphsData?.byMonth != undefined) {
      return graphsData?.byMonth.length
    } else {
      return 0
    }
  }

  //#endregion



  return (
    <>
      <Row className="">
        <Col xs='12' md='6' style={{ display: "flex", justifyContent: "flex-start" }}>
          {/* Button por mes */}
          <Button
            className="btn-info btn-round btn-icon"
            color="info"
            id="helpMonthGraphBtn"
            size="sm"
            outline
            onClick={(e) => setDrawerHelpComponents({ ...drawerHelpComponents, visible: true, type: 'byMonth' })}
          >
            <span className="btn-inner--icon ">
              <i className="fa fa-info-circle" />
            </span>
          </Button>
        </Col>
        <Col xs='12' md='6' style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* Button por año  */}
          <Button
            className="btn-info btn-round btn-icon"
            color="info"
            id="helpByYearsGraphBtn"
            outline
            size="sm"
            onClick={(e) => setDrawerHelpComponents({ ...drawerHelpComponents, visible: true, type: 'byYears' })}
          >
            <span className="btn-inner--icon ">
              <i className="fa fa-info-circle" />
            </span>
          </Button>
        </Col>


      </Row>









      <Row className="">
        <Col xs='12' md='8' className="" style={{ display: "flex", justifyContent: "flex-start" }}>
          {/* Gráfico por mes */}
          <Card className="w-100">
            <CardHeader>
              <Row>
                <Col xs="12" sm={"6"} className="">
                  <h6 className="surtitle">{subtitleMonth}</h6>
                  <h5 className="h3 mb-0">{titleMonth}</h5>
                </Col>
                <Col xs="12" md='6' style={{ display: "flex", justifyContent: "flex-end" }} className="mt--1">
                  <div className="d-flex justify-content-center">

                    {
                      radioButtonsGraph.map((radioItem, index) => {
                        return (
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              className="custom-control-input"
                              id={radioItem.id}
                              key={index}
                              type="radio"
                              checked={radioItem.state}
                              onChange={onChangeChart}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={radioItem.id}
                            >
                              {radioItem.label}
                            </label>
                          </div>)
                      })
                    }
                  </div>

                </Col>

              </Row>
            </CardHeader>

            <CardBody >
              <Spin size="large" spinning={spinner || (graphsData?.byMonth == undefined)}>

                <Line

                  title={graphActive !== "iterations" && graphActive !== "iterationsWithLines" ? "Ahorro por Mes RPA (USD)" : "Cantidad de ejecuciones al mes"}
                  subtitle={"Estadísticas Databot"}
                  data=
                  {{
                    labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'],
                    datasets: generateInformationToGraph()
                    // .map(value =>
                    //     numeral(value).format('$0,0')
                    //   ),
                  }}


                  options={


                    {
                      legend: {
                        display: (graphActive != "savingWithLines" && graphActive != "iterationsWithLines") || (quantityRows() < 10),

                      },

                      animation: {
                        onComplete: () => {
                          delayed = true;
                        },
                        delay: (context) => {
                          let delay = 0;
                          if (context.type === 'data' && context.mode === 'default' && !delayed) {
                            delay = context.dataIndex * 300 + context.datasetIndex * 100;
                          }
                          return delay;
                        },
                      },
                      scales: {
                        yAxes: [
                          {
                            // gridLines: {
                            //   color: colors.gray[700],
                            //   zeroLineColor: colors.gray[700]
                            // },
                            ticks: {
                              callback: function (value) {
                                if (graphActive !== "iterations" && graphActive !== "iterationsWithLines") {
                                  return "$" + value;
                                } else {
                                  return value;

                                }
                              }
                            }
                          }
                        ]
                      },
                      tooltips: {
                        callbacks: {
                          label: function (item, data) {
                            var label = data.datasets[item.datasetIndex].label || "";
                            var yLabel = item.yLabel;
                            var content = "";

                            if (data.datasets.length > 1) {
                              content += label;
                            }

                            if (graphActive !== "iterations" && graphActive !== "iterationsWithLines") {
                              content += ": $" + yLabel;
                            } else {
                              content += ": " + yLabel;
                            }
                            return content;
                          }
                        }
                      }

                    }
                  }



                />


              </Spin>

            </CardBody>
          </Card>
        </Col>
        <Col xs='12' md='4' style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* Gráfico por año  */}
          <Card className="w-100">
            <CardHeader>
              <Row>
                <Col xs="12" sm={type == "line" ? '4' : '12'} className="">
                  <h6 className="surtitle">{subtitleYear}</h6>
                  <h5 className="h3 mb-0">{titleYear}</h5>
                </Col>

              </Row>
            </CardHeader>

            <CardBody >
              <Spin size="large" spinning={spinner}>
                <Row >


                  <Bar

                    height={"320"}


                    data=
                    {{
                      labels: getLast3Years(),

                      datasets: [
                        {
                          label: "Ahorro Databot últimos 3 años (USD)",
                          data: graphsData?.byYears != undefined && (
                            Object?.values(graphsData?.byYears).sort((a, b) => parseFloat(a) - parseFloat(b))).map(value => numeral(value).format('0.00')),
                          // fill: true, [300,200, 150]
                          //backgroundColor: "rgba(75,192,192,0.2)",
                          backgroundColor: "rgba(25, 193, 84, 0.8)",

                          borderColor: "rgba(75,192,192,1)",

                          pointRadius: 10,
                          pointHoverRadius: 15,
                          showLine: false
                        },
                      ],
                    }}

                    options={
                      {
                        responsive: true,
                        plugins: {
                          legend: {
                            position: 'top',
                          },
                          title: {
                            display: true,
                            text: 'Chart.js Bar Chart',
                          },
                        },
                        scales: {
                          yAxes: [
                            {
                              // gridLines: {
                              //   color: colors.gray[700],
                              //   zeroLineColor: colors.gray[700]
                              // },
                              ticks: {
                                callback: function (value) {
                                  return "$" + value;
                                }
                              }
                            }
                          ]
                        },
                        tooltips: {
                          callbacks: {
                            label: function (item, data) {
                              var label = data.datasets[item.datasetIndex].label || "";
                              var yLabel = item.yLabel;
                              var content = "";

                              if (data.datasets.length > 1) {
                                content += label;
                              }

                              content += ": $" + yLabel;

                              return content;
                            }
                          }
                        }
                      }
                    }


                  />
                </Row>
              </Spin>

            </CardBody>
          </Card>


        </Col>


      </Row>

























    </>
  )
}
