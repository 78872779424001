import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";

const EntitiesFilter = ({ handleOnLoad, isAdmin }) => {
    return (
        <div>
            <Card>
                <CardHeader>
                    <Col sm="12" md="10">
                        {isAdmin === true && (
                            <h3 className="mb-0" >Agregar un nuevo registro de entidades</h3>
                        )}
                        <h3 className="mb-0" >Entidades Registradas</h3>
                    </Col>
                </CardHeader>
                <CardBody>
                    {
                        isAdmin === true && (
                            <Row className="justify-content-between">
                                <Col sm="12" md="3" >
                                    <Button className="btn-icon" color="info" block type="button"
                                        onClick={() => handleOnLoad()}
                                    >
                                        <span className="btn-inner--icon mr-1">
                                            <i className="fas fa-plus" />
                                        </span>Agregar nuevo registro
                                    </Button>
                                </Col>
                            </Row>
                        )
                    }
                </CardBody>
            </Card>
        </div>
    );
};


export default EntitiesFilter;