/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de la cancelacion de la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React from "react";
import { Card, CardTitle, Label, Col, Row, Input, FormGroup } from "reactstrap";

const CancellationInfo = ({ isVisible, data }) => {

    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>
                    <CardTitle tag="h5" style={
                        { backgroundColor: '#bc2312', color: '#ffffff', fontFamily: 'sans-serif', height: '1.5em', fontSize: '1.25em', textAlign: 'center' }
                    }>
                        Solicitud Cancelada
                    </CardTitle>

                    <Row style={{ padding: '0px 25px 0px' }}>
                        <Col>
                            <FormGroup style={{ marginBottom: '0.1rem', fontFamily: 'sans-serif' }}>
                                <Label style={{ height: '1.5em', marginBottom: '0.1rem', fontFamily: 'sans-serif' }}>
                                    {"Razón de la cancelación"}
                                </Label>
                                <Input
                                    type="textarea"
                                    value={data.cancellationComments}
                                    disabled
                                    className="form-control"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );
}
export default CancellationInfo;