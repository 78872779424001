/*!

=========================================================
*ModalPO
=========================================================

*Componente para importar y seleccionar todas las po, docs de 
una planta en específico.
=========================================================

* Coded by Eduardo Piedra - Application Management GBM

*/

import React, {
    useContext,
    useEffect,
    useState,
} from "react";

//dispatch hook
import { useDispatch } from 'react-redux';

//#region icons
import { FaSearch } from 'react-icons/fa';
//#endregion


// reactstrap components
import {
    InputGroupAddon,
    InputGroup,
    CardHeader,
    FormGroup,
    CardBody,
    Button,
    Table,
    Input,
    Modal,
    Card,
    Row,
    Col,
} from "reactstrap";

//Spinner
import { Spin } from "antd";

//Componentes reutilizables
import { UserContext } from '../SharedComponents/UserContext';

//Imports de actions de consultas al API.
import {
    getReservationsByMovementAndPlant,
    getItemsOtsByWarehouse,
    getReservationsByPlant,
    getAllDocsByPlant,
    getAllPosByPlant,
} from 'actions/wms';

export const ModalList = ({ id, title, subtitle, spinnerText, searchText, mainView, openModal, setOpenModal, newInfo, setNewInfo, getSupplierByPoE, getPositionsByDocE, getReservationActiveByDocE, getMovementByReservationE }) => {

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isComputer,
        notify,
        warehouse
    } = useContext(UserContext);
    //#endregion


    //#region Modal 

    const handleCloseModal = () => {
        setOpenModal(false)
        setItems([])
        setItemsFiltered([])
    }

    //#endregion

    //#region states locales
    //Spinner
    const [spinner, setSpinner] = useState(false)

    //Valor a buscar.
    const [valueToSearch, setValueToSearch] = useState("")

    //Columnas
    const [columns, setColumns] = useState(["action", "Purchase Order"])

    //Todas las purchase orders extraídas 
    const [items, setItems] = useState([])

    //Purchase orders filtradas.
    const [itemsFiltered, setItemsFiltered] = useState([])

    //#endregion

    //#region funciones
    //Extraer Purchase Orders por plant.
    const getAllPosByPlantE = () => {

        setSpinner(true);
        dispatch(getAllPosByPlant({ view: mainView, plant: newInfo.plant })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                console.log(payload)
                let items = payload.data.payload.pos;

                if (items.length == 0) {
                    notify("warning", "Alerta", "No existe POs disponibles para esta planta.")
                    handleCloseModal()
                }

                setItems(items)
                setItemsFiltered(items)
                setSpinner(false);



            } else {
                console.log(payload)
            }
        })
    }

    //Extraer Documents por plant.
    const getAllDocsByPlantE = () => {

        setSpinner(true);
        dispatch(getAllDocsByPlant({ view: mainView, plant: newInfo.plant })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                console.log(payload)
                let items = payload.data.payload.docs;

                if (items.length == 0) {
                    notify("warning", "Alerta", "No existe Documentos disponibles para esta planta.")
                    handleCloseModal()
                }

                setItems(items)
                setItemsFiltered(items)
                setSpinner(false);



            } else {
                console.log(payload)
            }
        })
    }

    //Extraer ots de cancelación .
    const getItemsOtsByWarehouseE = () => {

        setSpinner(true);
        dispatch(getItemsOtsByWarehouse({ warehouse: warehouse, movement: newInfo.movement, mainView: mainView })).then((res) => {
            const { payload } = res;

            setSpinner(false);
            if (res.payload.isAxiosError) {
                console.log(payload)
                handleCloseModal()
                notify("danger", "Alerta", "No se pudo extraer la información de SAP.")

            } else {

                // console.log(payload)
                let items = payload.data.payload.ots;

                if (items.length == 0) {
                    notify("warning", "Alerta", "No hay OTs con este movimiento disponibles en warehouse " + warehouse)
                    handleCloseModal()
                }

                setItems(items)
                setItemsFiltered(items)

            }

        })
    }

    //Extraer reservation por movement and plant.
    const getReservationsByMovementAndPlantE = () => {

        setSpinner(true);
        dispatch(getReservationsByMovementAndPlant({ view: mainView, movement: newInfo.movement, plant: newInfo.plant })).then((resp) => {
            const { payload } = resp;
            console.log("AQUIII")

            if (payload.isAxiosError) {
                console.log(payload)
                handleCloseModal()
                notify("danger", "Alerta", "No se pudo extraer la información de SAP.")

            } else {

                console.log(payload)
                let items = payload.data.payload.docs;

                if (!items || items.length == 0) {
                    notify("warning", "Alerta", "No existe Documentos disponibles para esta planta.")
                    handleCloseModal()
                }

                setItems(items)
                setItemsFiltered(items)
                setSpinner(false);


            }






        })
    }

    //Extraer reservation por  plant.
    const getReservationsByPlantE = () => {

        setSpinner(true);
        dispatch(getReservationsByPlant({ view: mainView, plant: newInfo.plant })).then((resp) => {
            const { payload } = resp;
            setSpinner(false);

            if (payload.status === 200) {
                console.log(payload)
                let items = payload.data.payload.docs;

                if (items.length == 0) {
                    notify("warning", "Alerta", "No existe Documentos disponibles para esta planta.")
                    handleCloseModal()
                }

                setItems(items)
                setItemsFiltered(items)
                setSpinner(false);



            } else {
                console.log(payload)
            }
        })
    }

    //Handle para agregar el item seleccionado.
    const handleChange = (item) => {
        if (id == "po") {
            setNewInfo({ ...newInfo, po: item })
            getSupplierByPoE({ ...newInfo, po: item })
        } else if (/*id == "deliveryOutbound" ||*/ id == "reservationOutboundAssets") {
            setNewInfo({ ...newInfo, document: item })
            getReservationActiveByDocE({ ...newInfo, document: item })
        } else if (id == "deliveryOutbound") {
            setNewInfo({ ...newInfo, document: item })
        } else if (id == "reservationOutboundOthers") {
            setNewInfo({ ...newInfo, reservation: item })
            getMovementByReservationE({ ...newInfo, reservation: item })
        } else {
            setNewInfo({ ...newInfo, ot: item })
        }
        handleCloseModal()
    }
    //#endregion

    //#region efectos
    //Cuando arranque traiga las pos.
    useEffect(() => {
        if (openModal) {
            if (id == "po") {
                getAllPosByPlantE()
            } else if (id == "deliveryOutbound") {
                getAllDocsByPlantE()
            } else if (id == "reservationOutboundAssets") {
                getReservationsByMovementAndPlantE()
            } else if (id == "reservationOutboundOthers") {
                getReservationsByPlantE()
            } else {
                getItemsOtsByWarehouseE()
            }
        }
    }, [openModal])

    //Efecto para realizar el filtrado de items.
    useEffect(() => {
        if (valueToSearch != "") {
            let itemsTemp = items.filter(po => po.toString().includes(valueToSearch));
            setItemsFiltered(itemsTemp)
        } else {
            setItemsFiltered(items)
        }
    }, [valueToSearch])
    //#endregion


    return (
        <>

            <Modal
                // className="modal-dialog-centered"
                className="modal-dialog-centered modal-secondary"
                size="md"
                backdrop={true}
                isOpen={openModal}
                toggle={() => handleCloseModal()}
            >
                <Card className="shadow mb-0">
                    {/* bg-gradient-default  */}

                    <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                        <div>
                            {/* text-white */}
                            {isComputer && <h6 className="surtitle">{subtitle}</h6>}
                            <h5 className="h3 mb-0">{title}</h5>
                        </div>
                        <Button
                            outline
                            type="button"
                            size="sm"
                            onClick={() => handleCloseModal()}
                        >
                            <span className="btn-inner--icon ">
                                <i className="fas fa-times" />
                            </span>
                        </Button>
                    </CardHeader>
                    <Spin
                        className=""
                        spinning={spinner}
                        tip={spinnerText}
                        size="medium"
                    >
                        <CardBody className=" pt-0">


                            <Row className=" justify-content-center">


                                <Col xs="12" sm={"12"} className={""}>
                                    <FormGroup className="mb-2" >


                                        <InputGroup size="sm">
                                            <Input
                                                type="text"
                                                placeholder={searchText}
                                                onChange={(e) => setValueToSearch(e.target.value)}
                                                className="ml-0"

                                            />
                                            <InputGroupAddon addonType="append" className="ml--2">
                                                <span className="input-group-text">
                                                    <FaSearch />
                                                </span>
                                            </InputGroupAddon>
                                        </InputGroup>


                                    </FormGroup>
                                </Col>
                                <Col className="" sm="12">
                                    <Table
                                        className={"align-items-center  table-flush mt--1 "}
                                        responsive
                                        striped={true}
                                        hover={true}
                                    >

                                        <tbody className='list  border'>
                                            {items.length > 0 ?

                                                <>
                                                    {itemsFiltered.sort((a, b) => a - b).map((item, keyItem) => {
                                                        return (

                                                            <tr key={keyItem} className="" onClick={(e) => handleChange(item)}>

                                                                {columns.map((col, key) => {
                                                                    return (

                                                                        col === "action" ? (
                                                                            <td key={key} className={"justify-content-md-center p-1 "}>

                                                                                <input className="mr-2" type="radio" id={"actionBtn" + key} />

                                                                            </td>)


                                                                            //CUALQUIER TIPO 
                                                                            : (
                                                                                <td key={key} className={"text-left justify-content-md-left p-1 "} onClick={(e) => handleChange(item)}>
                                                                                    {item}
                                                                                </td>
                                                                            )
                                                                    )
                                                                })}

                                                            </tr>

                                                        )
                                                    })}
                                                </>
                                                :
                                                <>

                                                </>
                                            }
                                        </tbody>

                                    </Table>
                                </Col>



                            </Row>

                        </CardBody>
                    </Spin>
                </Card>
            </Modal>


        </>
    )
}

