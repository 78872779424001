/* eslint-disable jsx-a11y/anchor-is-valid */
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Button, Card, CardHeader, Col, Row, Table, Input } from "reactstrap";

const TableEquipments = props => {

  const {
    edit,
    title,
    type,
    state,
    cols,
    colsCreated,
    rows,
    rowsCreated,
    initialEditValues,
    options,
    onChange,
    onEdit,
    onUpdate,
    onDelete,
    onCompleteEquipments,
    onCreateEquipments,
  } = props;

  // console.log(edit);
  // console.log(initialEditValues);

  return (
    <Row>
      <Col>
        <Card className="bg-default shadow">
          <CardHeader className="bg-transparent border-0">
            <Row>
              <Col xs="12" md='9'>
                <h3 className="text-white mb-0">{title}</h3>
              </Col>
              <Col className="text-right" xs="12" md='3'>
                {
                  state[type] === "created" ?
                    <Button
                      className="btn-round btn-icon"
                      outline
                      color="info"
                      disabled={state[type] === "complete"}
                      id="add"
                      onClick={() => onCompleteEquipments(type)}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-check-double" />
                      </span>
                      <span className="btn-inner--text">{`Completar Equipos ${type}`}</span>
                    </Button>
                    : state[type] === "load" ?
                      <Button
                        className="btn-round btn-icon"
                        outline
                        color="info"
                        disabled={state[type] === "complete"}
                        id="add"
                        onClick={() => onCreateEquipments(type)}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-folder-plus" />
                        </span>
                        <span className="btn-inner--text">{`Crear Equipos ${type}`}</span>
                      </Button>
                      : null
                }
              </Col>
            </Row>
          </CardHeader>
          <Table
            className="align-items-center table-dark table-flush"
            responsive
          >
            <thead className="thead-dark">
              <tr>
                {
                  state[type] !== "load" ?
                    colsCreated.map((row, key) => (row.hide === false ? <th key={key}>{row.name}</th> : null))
                    :
                    cols.map((row, key) => (row.hide === false ? <th key={key}>{row.name}</th> : null))
                }
                {state[type] === "created" && <th />}
              </tr>
            </thead>
            <tbody className="list">
              {
                state[type] !== "load" ?
                  rowsCreated.map((row, key) => (
                    <tr key={key}>
                      {
                        edit && row.id === initialEditValues.id ?
                          options.map((row, key) => (
                            row.hide === false &&
                            <td key={key}>
                              {
                                row.options ?
                                  <div>
                                    <Input
                                      style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: row.key === 'productID' ? '250px' : "auto" }}
                                      id={row.key}
                                      type={row.type}
                                      value={initialEditValues[row.key]}
                                      onChange={(e) => onChange(e, 'edit')}
                                    >
                                      <option key="0" value="0">{row.placeholder}</option>
                                      {
                                        row.options.map((row, key) => {
                                          return (
                                            <option key={key} value={row.id}>{row.name}</option>
                                          )
                                        })
                                      }
                                    </Input>
                                  </div>
                                  :
                                  <Input
                                    style={{ "backgroundColor": "hsla(0,0%,100%,.1)", "border": "1px solid #1394b6", width: "-webkit-fill-available" }}
                                    id={row.key}
                                    type={row.type}
                                    placeholder={row.placeholder}
                                    value={initialEditValues[row.key]}
                                    onChange={(e) => onChange(e, 'edit')}
                                    maxLength={row.max}
                                    max={row.max}
                                    min={row.min ? row.min : 1}
                                  />
                              }
                            </td>
                          ))
                          :
                          colsCreated.map((col, key) => (
                            col.hide === false ?
                              <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                                {row[col.key]}
                              </th>
                              : null
                          ))
                      }
                      {
                        state[type] === "created" &&
                        <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                          {
                            edit && row.id === initialEditValues.id ?
                              <a
                                className="table-action table-action"
                                href="#"
                                onClick={onUpdate}
                              >
                                <i className="fas fa-check-double" />
                              </a>
                              :
                              <>
                                <a
                                  className="table-action table-action"
                                  href="#"
                                  onClick={() => onEdit(row)}
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <a
                                  className="table-action table-action"
                                  href="#"
                                  onClick={() => onDelete(row)}
                                >
                                  <i className="fas fa-trash" />
                                </a>
                              </>
                          }
                        </th>
                      }
                    </tr>
                  ))
                  :
                  rows.map((row, key) => (
                    <tr key={key}>
                      {
                        cols.map((col, key) => (
                          col.hide === false ?
                            <th key={key} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                              {row[col.key]}
                            </th>
                            : null
                        ))
                      }
                      {
                        state[type] === "created" &&
                        <th key='action' style={{ whiteSpace: "normal", textAlign: "center" }}>
                          <a
                            className="table-action table-action"
                            href="#"
                            onClick={() => onEdit(row)}
                          >
                            <i className="fas fa-edit" />
                          </a>
                          <a
                            className="table-action table-action"
                            href="#"
                            onClick={() => onDelete(row)}
                          >
                            <i className="fas fa-trash" />
                          </a>
                        </th>
                      }
                    </tr>
                  ))
              }
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

TableEquipments.propTypes = {
  title: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default TableEquipments;
