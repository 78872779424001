import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const contractsOnHold = handleActions(
  {
    [constants.GET_ALL_CONTRACTS_ON_HOLD]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const dataMaster = handleActions(
  {
    [constants.GET_ALL_DATA_MASTER_BY_STATUS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const statusContractsOnHold = handleActions(
  {
    [constants.GET_ALL_STATUS_AVAILABLE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const activityFlowByContractOnHold = handleActions(
  {
    [constants.GET_ACTIVITY_FLOW_BY_CONTRACT_ID]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const usersNotifications = handleActions(
  {
    [constants.GET_USERS_NOTIFICATION_ESCALATIONS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const usersEscalations = handleActions(
  {
    [constants.GET_USERS_ESCALATIONS]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const dataDashboardContracts = handleActions(
  {
    [constants.GET_DASHBOARD_CONTRACTS_ON_HOLD_BY_TYPE]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const dataGraphContracts = handleActions(
  {
    [constants.GET_CONTRACTS_GRAPH_DETAIL]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const dataGraphContractsByCountry = handleActions(
  {
    [constants.GET_CONTRACTS_GRAPH_DETAIL_BY_COUNTRY]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const userFMAndPM = handleActions(
  {
    [constants.GET_ALL_USER_FM_AND_PM]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const usersAccessOnHold = handleActions(
  {
    [constants.GET_ALL_USERS_ACCESS_ON_HOLD]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);
