export const getExtras = (state) => {
  for (const element of state.extras) {
    if (element) {
      element.date = element.date.substring(0, 10);
    }
  }
  return state.extras;
};

export const getDateExtras = (state) => {
  for (const element of state.dateExtras) {
    if (element) {
      element.date = element.date.substring(0, 10);
    }
  }
  return state.dateExtras;
};

export const getUpdateExtrasSAP = (state) => state.updatedExtrasSAP;

export const getExtrasToSend = (state) => {
  for (const element of state.extrasToSend) {
    if (element) {
      element.date = element.date.substring(0, 10);
    }
  }
  return state.extrasToSend;
};

export const getSumAllYearHoursWidget = (state) =>
  state.allYearExtraSumHoursWidget;

export const getYearsExtras = (state) => state.yearsExtras;

export const getSumAllYearHoursGraph = (state) => {
  return state.allYearExtraSumHoursGraph;
};

export const getSumCountryYearHoursWidget = (state) =>
  state.countryExtrasSumHoursWidget;

export const getSumCountryYearHoursGraph = (state) =>
  state.countryExtrasSumHoursGraph;

export const getUsersByYear = (state) => state.findUserByYear;

export const getHoursByUser = (state) => state.findHoursByUser;

export const getMonthWidgets = (state) => state.findMonthWidget;

export const getMonthWidgetsCountry = (state) => state.findMonthWidgetCountry;

export const getUserRolesExtra = (state) => state.userRolesExtra;

export const getUsersWithAccess = (state) => {
  if (Object.keys(state.usersWithAccessExtraHours).length) {
    const { users } = state.usersWithAccessExtraHours;
    return users.sort((a, b) => {
      if (a.username < b.username) {
        return -1;
      }
      if (a.username > b.username) {
        return 1;
      }
      return 0;
    });
  }
  return [];
};

export const getCountriesUsers = (state) => {
  if (Object.keys(state.usersWithAccessExtraHours).length) {
    const { users } = state.usersWithAccessExtraHours;
    let countries = users.map((row, key) => {
      return { value: key + 1, label: row.country };
    });
    countries = countries
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => countries[e])
      .map((e) => countries[e]);

    countries = countries.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...countries];
  }
  return [];
};

export const getManagersUsers = (state) => {
  if (Object.keys(state.usersWithAccessExtraHours).length) {
    const { users } = state.usersWithAccessExtraHours;
    let managers = users.map((row, key) => {
      return { value: key + 1, label: row.manager };
    });
    managers = managers
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => managers[e])
      .map((e) => managers[e]);

    managers = managers.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...managers];
  }
  return [];
};

export const getUsernamesUsers = (state) => {
  if (Object.keys(state.usersWithAccessExtraHours).length) {
    const { users } = state.usersWithAccessExtraHours;
    let usernames = users.map((row, key) => {
      return { value: key + 1, label: row.username };
    });
    usernames = usernames
      .map((e) => e["label"])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => usernames[e])
      .map((e) => usernames[e]);

    usernames = usernames.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [{ value: 0, label: "TODOS" }, ...usernames];
  }
  return [];
};

export const getExtraHoursByCeo = (state) => {
  if (Object.keys(state.extraHourCeo).length) {
    const { extraHours } = state.extraHourCeo;
    return extraHours;
  }
  return [];
};

export const getExtraHoursHCUsers = (state) => {
  if (Object.keys(state.extraHoursHC).length) {
    const { users } = state.extraHoursHC;
    return users;
  }
  return [];
};
