// javascipt plugin for creating charts
import Chart from "chart.js";
// react library
import React, { useEffect } from 'react';
// react plugin used to create charts
import { Doughnut } from "react-chartjs-2";
// reactstrap components
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
// core components
import {
    // global options for the charts
    chartOptions,
    // function that adds the global options to our charts
    parseOptions
} from "variables/charts.jsx";
import Select from "react-select";

const ChartBar = ({ title, data, options, year, years, subtitle, onChange, constant }) => {

    useEffect(() => {
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }, [])

    return (
        <>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h6 className="surtitle">{`Por ${title}`}</h6>
                            <h5 className="h3 mb-0">{subtitle}</h5>
                        </Col>
                        <Col>
                            <Select
                                className="basic-single"
                                type="select"
                                isSearchable
                                options={years}
                                defaultValue={{ label: year && year, value: 0 }}
                                onChange={(e) => onChange(constant, e.value)}
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="chart">
                        <Doughnut
                            data={data}
                            options={options}
                            className="chart-canvas"
                            id="chart-bar-stacked"
                        />
                    </div>
                </CardBody>
            </Card>
        </>
    );
}


export default ChartBar;
