import classnames from "classnames";
import React, { useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    Modal, ModalBody,
    ModalFooter, ModalHeader, Nav,
    NavItem,
    NavLink,
    Row, TabContent,
    TabPane
} from "reactstrap";
import CompetitionEditFormOne from "./CompetitionEditFormOne";
import CompetitionEditFormThree from "./CompetitionEditFormThree";
import CompetitionEditFormTwo from "./CompetitionEditFormTwo";
const CompetitionModal = ({ showModal, rowsModal, setRowsModal, setActiveTab, activeTab, dataModal, handleCloseModal }) => {
    const handleTab = (event, tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        let value = "";
        for (const item of rowsModal.products) {
            value += item.total
        }
        setRowsModal(prevState => ({
            ...prevState,
            subtotal: value
        }))
    }, [activeTab])
    return (
        <div>
            {alert}
            <Modal className="modal-xl" isOpen={showModal} style={{ minWidth: "900px", maxWidth: "900px" }}>
                <ModalHeader className="d-flex justify-content-center">
                    {"Registro Unico de Pedido: "}
                    <a rel="noreferrer" href={dataModal.documentLink} target="_blank" style={{ color: "blue" }}>
                        {dataModal.singleOrderRecord}
                    </a>
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            <Nav
                                className="nav-fill flex-column flex-md-row"
                                id="tabs-icons-text"
                                pills
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        aria-selected={activeTab === "1"}
                                        className={classnames("mb-sm-3 mb-md-0", {
                                            active: activeTab === "1",
                                        })}
                                        onClick={(e) => handleTab(e, "1")}
                                        role="tab"
                                    >
                                        <i className="ni ni-badge mr-2" />
                                        Datos Generales
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        aria-selected={activeTab === "2"}
                                        className={classnames("mb-sm-3 mb-md-0", {
                                            active: activeTab === "2",
                                        })}
                                        onClick={(e) => handleTab(e, "2")}
                                        role="tab"
                                    >
                                        <i className="ni ni-badge mr-2" />
                                        Datos de Productos
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        aria-selected={activeTab === "3"}
                                        className={classnames("mb-sm-3 mb-md-0", {
                                            active: activeTab === "3",
                                        })}
                                        onClick={(e) => handleTab(e, "3")}
                                        role="tab"
                                    >
                                        <i className="ni ni-badge mr-2" />
                                        Datos Adicionales
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <CompetitionEditFormOne
                                dataModal={dataModal}
                            />
                        </TabPane>
                        <TabPane tabId="2">
                            <CompetitionEditFormTwo
                                dataModal={dataModal}
                                products={rowsModal}
                            />
                        </TabPane>
                        <TabPane tabId="3">
                            <CompetitionEditFormThree
                                dataModal={dataModal}
                            />
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Button color="danger" onClick={() => handleCloseModal()}>
                            Cancelar
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default CompetitionModal;