// react library
import React from 'react';
// reactstrap components
import {Button, UncontrolledTooltip} from "reactstrap";

const NewRequest = ({
    setEditRow,
    setRealTable
}) => {

    const handleOnNewDocument = (event) => {
        setRealTable(prevState => !prevState);
        setEditRow((prevState) => ({
            ...prevState,
            showNewDocumentModal: !prevState.showNewDocumentModal,
       
        }))
    };



    return (

        <>
            
           
                <Button
                    className="btn-info btn-round btn-icon"
                    color="default"
                    id="tooltip969372949"
                    onClick={(e) => handleOnNewDocument()}
                    size="sm"
                >
                    <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                    </span>
                    <span className="btn-inner--text">Nueva Gestión</span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip969372949">
                    Agregar nuevo documento de colocación
                </UncontrolledTooltip>
       
        </>
    );
};

export default NewRequest;
