// javascript library dates
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
// nodejs library for utf8
import utf8 from "utf8";

const MySignaturesCard = (props) => {
  const { title, signature, signatures, openFile, onBack, onSend, onRelaunch } =
    props;

  const verifyUtf8 = (text) => {
    try {
      return utf8.decode(text);
    } catch (error) {
      return text;
    }
  };

  const renderList = (row, key) => {
    if (row.text) {
      if (row.doc) {
        return (
          <Row key={key}>
            <Col xs="9">
              <li>
                <CardText className="mt-4">{verifyUtf8(row.text)}</CardText>
              </li>
            </Col>
            <Col
              xs="3"
              style={{
                marginTop: "1.5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                color="success"
                size="sm"
                type="button"
                onClick={() => window.open(require(`assets/docs/${row.doc}`))}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-file-pdf" />
                </span>
                <span className="btn-inner--text">Ver</span>
              </Button>
            </Col>
          </Row>
        );
      }
      return (
        <li key={key}>
          <CardText className="mt-4">{verifyUtf8(row.text)}</CardText>
        </li>
      );
    } else {
      const options = row.options.map((row, key) => {
        return renderList(row, key);
      });
      return <ul key={key}>{options}</ul>;
    }
  };

  const renderContent = (row) => {
    const content = [];
    for (const key in row) {
      if (key === "text") {
        content.push(
          <CardText key={key} className="mt-4">
            {verifyUtf8(row[key])}
          </CardText>
        );
      } else if (key === "options") {
        const list = row[key].map((row, key) => {
          return renderList(row, key);
        });
        content.push(<ul key={key}>{list}</ul>);
      }
    }
    return content;
  };

  const renderSignatures = () => (
    <div className="card-deck flex-column flex-xl-row">
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col sm="12" md="8">
              <h5 className="h3 mb-0">{title}</h5>
            </Col>
            <Col className="text-right" xs="4">
              <Button
                color="info"
                size="sm"
                onClick={onSend}
                disabled={!signatures.some((row) => row.active === 1)}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-inbox" />
                </span>
                <span className="btn-inner--text">Enviar a mi correo</span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ListGroup className="list my--3" flush>
            {signatures.map((row, key) => {
              return (
                <ListGroupItem key={key} className="px-0">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        alt="Notificación"
                        className="avatar rounded-circle"
                        src={require(`assets/img/theme/signature.png`)}
                      />
                    </Col>
                    <div className="col ml--2">
                      {row.status ? (
                        <h4 className="mb-0">
                          {row.document} - {row.state}
                        </h4>
                      ) : (
                        <h4 className="mb-0">{row.document}</h4>
                      )}
                      <small className="text-muted">
                        {moment(row.createdAt).format("LLLL")}
                      </small>
                    </div>
                    <Col className="col-auto">
                      {row.active === 1 ? (
                        <Button
                          color="success"
                          // size="sm"
                          type="button"
                          onClick={() => openFile(row)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-search" />
                          </span>
                          <span className="btn-inner--text">Verificar</span>
                        </Button>
                      ) : (
                        <Button
                          color="danger"
                          // size="sm"
                          type="button"
                          disabled
                          // onClick={() => openFile(row)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-times-circle" />
                          </span>
                          <span className="btn-inner--text">Expirada</span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  );

  const renderSignature = () => (
    <Row>
      <Col>
        <div className="card-deck flex-column flex-xl-row">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col sm="12" md="8">
                  {signature.status && (
                    <h5
                      className={`surtitle ${
                        signature.status === 2 ? "text-danger" : "text-success"
                      }`}
                    >
                      Estado - {signature.state}
                    </h5>
                  )}
                  <h5 className="h3 mb-0">{verifyUtf8(signature.document)}</h5>
                </Col>
                {signature.active === 1 ? (
                  <Col className="text-right" xs="4">
                    <Button
                      color="info"
                      size="sm"
                      onClick={() =>
                        window.open(
                          require(`assets/docs/${signature.name}.pdf`)
                        )
                      }
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-file-download" />
                      </span>
                      <span className="btn-inner--text">Descargar</span>
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </CardHeader>
            <CardBody>
              {JSON.parse(signature.content).body.map((row, key) => {
                return <div key={key}>{renderContent(row)}</div>;
              })}
              <div className="d-flex align-items-center justify-content-center text-center">
                <Button
                  className="btn-icon"
                  size="md"
                  color="info"
                  type="button"
                  onClick={onBack}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-arrow-left" />
                  </span>
                  <span className="btn-inner--text">Volver</span>
                </Button>
                {signature.status && (
                  <Button
                    className="my-4"
                    color="success"
                    type="button"
                    onClick={onRelaunch}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-external-link-alt" />
                    </span>
                    <span className="btn-inner--text">Relanzar</span>
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );

  return <>{signature === null ? renderSignatures() : renderSignature()}</>;
};

MySignaturesCard.propTypes = {
  signatures: PropTypes.array.isRequired,
};

export default MySignaturesCard;
