import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getCustomers = createAction(constants.GET_CUSTOMERS, (body) => method.post(urls.CONTACTS.getCustomers, body)());

export const getContacts = createAction(constants.GET_CONTACTS, (body) => method.post(urls.CONTACTS.getContacts, body)());

export const getFunctions = createAction(constants.GET_FUNCTIONS, method.get(urls.CONTACTS.getFunctions));

export const getDepartaments = createAction(constants.GET_DEPARTAMENTS, method.get(urls.CONTACTS.getDepartaments));

export const getCountrys = createAction(constants.GET_COUNTRYS, method.get(urls.CONTACTS.getCountrys));

export const getHistory = createAction(constants.GET_HISTORY, method.get(urls.CONTACTS.getHistory));

export const getConfirmRows = createAction(constants.GET_CONFIRM_ROWS, method.get(urls.CONTACTS.getConfirmRows));

export const createContact = createAction(constants.CREATE_CONTACT, body => method.post(urls.CONTACTS.createContact, body)());

export const updateUserContact = createAction(constants.UPDATE_CONTACT, (body) => method.post(urls.CONTACTS.updatedContact, body)());

export const confirmCheckContact = createAction(constants.CONFIRM_CONTACT, (body) => method.post(urls.CONTACTS.confirmContact, body)());

export const reportHistory = createAction(constants.REPORT_HISTORY, (body) => method.post(urls.CONTACTS.reportHistory, body)());

export const removeConfirmContact = createAction(constants.DELETE_CONFIRM_CONTACT, (id) => method.put(`${urls.CONTACTS.removeConfirmContact}/${id}`)());

export const lockContact = createAction(constants.LOCK_CONTACT, (body) => method.post(urls.CONTACTS.lockContact, body)());

export const getFileUpdate = createAction(constants.GET_FILE_UPDATE, (body) => method.post(urls.CONTACTS.getFileUpdate, body)());

export const downloadExcel = createAction(constants.DOWNLOAD_EXCEL, (body) => method.downloadDataTables(urls.CONTACTS.downloadExcel, body)());

export const getEmployeeStats = createAction(
    constants.CONSTAT_EMPLOYEE,
    (body) => method.post(urls.CONTACTS.getStats, body)()
);

export const getManagerStats = createAction(
    constants.CONSTAT_MANAGER,
    (body) => method.post(urls.CONTACTS.getManagerStats, body)()
);
export const getCustomerInfo = createAction(
    constants.CONSTAT_CUSTOMER,
    (body) => method.post(urls.CONTACTS.getCustomerInfo, body)()
);

export const statsCardInfo = [
    { title: 0, subTitle: 'Creaciones mes actual', color: 'success' },
    { title: 0, subTitle: 'Modificaciones mes actual', color: 'warning' },
    { title: 0, subTitle: 'Inactivaciones mes actual', color: 'danger' },
    { title: 0, subTitle: 'Clientes asignados', color: 'info' }
]

export const statsYears = [
    { id: '2021', text: 'Estadísticas 2021' },
    { id: '2022', text: 'Estadísticas 2022' },
    { id: '2023', text: 'Estadísticas 2023' },
    { id: '2024', text: 'Estadísticas 2024' },
    { id: '2025', text: 'Estadísticas 2025' },
    { id: '2026', text: 'Estadísticas 2026' },
    { id: '2027', text: 'Estadísticas 2027' },
    { id: '2028', text: 'Estadísticas 2028' },
    { id: '2029', text: 'Estadísticas 2029' },
    { id: '2030', text: 'Estadísticas 2030' }
]

export const defaultEmployees = [{ id: '', text: 'Cargando empleados' }]

export const colors = {
    gray: {
        100: '#f6f9fc',
        200: '#e9ecef',
        300: '#dee2e6',
        400: '#ced4da',
        500: '#adb5bd',
        600: '#8898aa',
        700: '#525f7f',
        800: '#32325d',
        900: '#212529'
    },
    theme: {
        default: '#172b4d',
        primary: '#5e72e4',
        secondary: '#f4f5f7',
        info: '#11cdef',
        success: '#2dce89',
        danger: '#f5365c',
        warning: '#fb6340'
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent'
}





