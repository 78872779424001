// core antd
import { Divider } from 'antd';
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";

moment.locale("es");

const RequestDetail = props => {

  const {
    request,
    equipments,
    configurations,
    activityFlow,
    onBack,
    onOpenOffer,
    onViewEquipments,
    onViewConfigurations,
    onViewEquipmentsSpare,
  } = props;

  const getTimeData = (time1, time2) => {
    const ms = moment(time1, "DD/MM/YYYY HH:mm:ss").diff(moment(time2, "DD/MM/YYYY HH:mm:ss"));
    const { _data: { days, hours, minutes } } = moment.duration(ms);
    const daysText = `${days} ${days === 1 ? 'día' : 'días'}`;
    const hoursText = `${hours} ${hours === 1 ? 'hora' : 'horas'}`;
    const minutesText = `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;
    return `${days > 0 ? `${daysText}, ${hoursText} y ${minutesText}` : hours > 0 ? `${hoursText} y ${minutesText}` : minutesText}`
  }

  const today = request.state < 14 ? moment().format("DD/MM/YYYY HH:mm:ss") : moment(request.updatedAt ? request.updatedAt : request.createdAt).format("DD/MM/YYYY HH:mm:ss");

  let ifSpare = false;

  for (const element of equipments) {
    if ((element.idEquipmentParts === 2 || element.idEquipmentParts === 3) && ifSpare === false) {
      ifSpare = true;
    }
  }

  return (
    <>
      <Row>
        <Col sm='12' md='4'>
          <Card className="bg-gradient-success border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    Estado del Requerimiento
                    </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {request.status}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className="ni ni-bell-55" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm='12' md='4'>
          <Card className="bg-gradient-warning border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0 text-white"
                  >
                    Requiere Partes Críticas
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {request.criticalParts}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className="fas fa-exclamation-triangle" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm='12' md='4'>
          <Card className="bg-gradient-danger border-0">
            <CardBody>
              <Row>
                <Col className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0 text-white"
                  >
                    Tiempo Transcurrido
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {getTimeData(today, moment(request.createdAt).format("DD/MM/YYYY HH:mm:ss"))}
                  </span>
                </Col>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i className="ni ni-time-alarm" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="order-xl-2" md='4'>
          {
            (request.state > 4 && request.state <= 12) &&
            <Row>
              <Col>
                <Card className="bg-gradient-danger border-0">
                  <CardBody>
                    <Row>
                      <Col className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          Tiempo Transcurrido en estado actual
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          {getTimeData(today, moment(request.updatedAt ? request.updatedAt : request.createdAt).format("DD/MM/YYYY HH:mm:ss"))}
                        </span>
                      </Col>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="fas fa-hourglass-half" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          {
            (request.state === 8 || (request.state >= 10 && request.state <= 14)) &&
            <Row>
              <Col>
                <Card className="bg-gradient-default d-none d-md-block">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-white" tag="h3">
                          Ver Oferta
                        </CardTitle>
                        <blockquote className="blockquote text-white mb-0">
                          <p>
                            Resumen del calculo realizado a la oportunidad
                          </p>
                        </blockquote>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="fas fa-calculator" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          // outline
                          className="btn-icon btn-3"
                          color="info"
                          type="button"
                          onClick={onOpenOffer}
                          size='sm'
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-folder-open" />
                          </span>
                          <span className="btn-inner--text">Abrir</span>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          {
            equipments.length ?
              <Row>
                <Col>
                  <Card className="bg-gradient-default">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle className="text-white" tag="h3">
                            Equipos Agregados
                          </CardTitle>
                          <blockquote className="blockquote text-white mb-0">
                            <p>
                              Visualizar los equipos agregados al requerimiento
                            </p>
                          </blockquote>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                            <i className="fas fa-desktop" />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            // outline
                            className="btn-icon btn-3"
                            color="info"
                            type="button"
                            size='sm'
                            onClick={onViewEquipments}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-clipboard-list" />
                            </span>
                            <span className="btn-inner--text">Ver Equipos</span>
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row> : null
          }
          {
            ifSpare &&
            <Row>
              <Col>
                <Card className="bg-gradient-default">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-white" tag="h3">
                          Equipos Spare Agregados
                        </CardTitle>
                        <blockquote className="blockquote text-white mb-0">
                          <p>
                            Visualizar los equipos agregados al requerimiento
                            </p>
                        </blockquote>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="fas fa-laptop" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          // outline
                          className="btn-icon btn-3"
                          color="info"
                          type="button"
                          size='sm'
                          onClick={onViewEquipmentsSpare}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-clipboard-list" />
                          </span>
                          <span className="btn-inner--text">Ver Equipos</span>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          {
            request.state > 5 && configurations.length ?
              <Row>
                <Col>
                  <Card className="bg-gradient-default">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle className="text-white" tag="h3">
                            Configuraciones Agregadas
                        </CardTitle>
                          <blockquote className="blockquote text-white mb-0">
                            <p>
                              Visualizar los archivos de configuración adjuntados al requerimiento
                          </p>
                          </blockquote>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                            <i className="fas fa-paperclip" />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            // outline
                            className="btn-icon btn-3"
                            color="info"
                            type="button"
                            size='sm'
                            onClick={onViewConfigurations}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-folder-open" />
                            </span>
                            <span className="btn-inner--text">Ver Archivos</span>
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              : null
          }
          <Row>
            <Col>
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <h3 className="mb-0 text-white">Flujo de Actividades</h3>
                </CardHeader>
                <CardBody>
                  <div
                    className="timeline timeline-one-side"
                    data-timeline-axis-style="dashed"
                    data-timeline-content="axis"
                  >
                    {
                      activityFlow.map((row, key) => (
                        <div className="timeline-block" key={key}>
                          <span className={`timeline-step badge-${row.color}`}>
                            <i className={row.icon} />
                          </span>
                          <div className="timeline-content">
                            <small className="text-light font-weight-bold">
                              {
                                moment(row.createdAt)
                                  .utc()
                                  .utcOffset(moment().utcOffset())
                                  .format("LLL")
                              }
                            </small>
                            <h5 className="text-white mt-3 mb-0">{row.description}</h5>
                            <div className="mt-3">
                              <Badge color={row.color} pill>
                                {row.status}
                              </Badge>
                              <Badge color={row.color} pill>
                                {row.createdBy}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="order-xl-1" md='8'>
          <Row>
            <Col>
              <Card className="mb-4">
                <CardHeader>
                  <Row>
                    <Col xs="12" md='9'>
                      <h5 className="surtitle">Información del Requerimiento</h5>
                      <Badge className="badge-lg mt-1" color="primary" pill>
                        v{request.version}
                      </Badge>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="opportunityNumber">Número de Oportunidad</Label>
                        <Input
                          id="opportunityNumber"
                          type="number"
                          className="form-control"
                          value={request.opportunityNumber}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="customer">Nombre del Cliente</Label>
                        <Input
                          id="customer"
                          type="text"
                          className="form-control"
                          value={request.customer}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="requestedExecutive">Ejecutivo de la Solicitud</Label>
                        <Input
                          id="requestedExecutive"
                          type="text"
                          className="form-control"
                          value={request.requestedExecutive}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="salesRep">Representante de Ventas</Label>
                        <Input
                          id="salesRep"
                          type="text"
                          className="form-control"
                          value={request.salesRep}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="typeSupport">Tipo de Soporte</Label>
                        <Input
                          id="typeSupport"
                          type="text"
                          className="form-control"
                          value={request.typeSupport}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="businessModel">Modelo de Negocio</Label>
                        <Input
                          id="businessModel"
                          type="text"
                          className="form-control"
                          value={request.businessModel}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="operatingSystemType">Tipo de Sistema Operativo</Label>
                        <Input
                          id="operatingSystemType"
                          type="text"
                          className="form-control"
                          value={request.operatingSystemType}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="amountOfEquipment">Cantidad de Equipos</Label>
                        <Input
                          id="amountOfEquipment"
                          type="text"
                          className="form-control"
                          value={request.amountOfEquipment}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="officeHours">Horario de Atención</Label>
                        <Input
                          id="officeHours"
                          type="text"
                          className="form-control"
                          value={request.officeHours}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="responseTime">Tiempo de Respuesta</Label>
                        <Input
                          id="responseTime"
                          type="text"
                          className="form-control"
                          value={request.responseTime}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="8">
                      <FormGroup>
                        <Label for="applicationNotes">Notas</Label>
                        <Input
                          id="applicationNotes"
                          type="textarea"
                          className="form-control"
                          value={request.applicationNotes}
                          disabled
                          maxLength={300}
                          rows="3"
                          resize="none"
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="timeChangePart">Tiempo para Cambio de Partes</Label>
                        <Input
                          id="timeChangePart"
                          type="text"
                          className="form-control"
                          value={request.timeChangePart}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="validityService">Vigencia del Servicio</Label>
                        <Input
                          id="validityService"
                          type="text"
                          className="form-control"
                          value={request.validityService}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="wayPay">Forma de Pago del Servicio</Label>
                        <Input
                          id="officwayPayeHours"
                          type="text"
                          className="form-control"
                          value={request.wayPay}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Divider>Detalles de Ubicación</Divider>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="locationType">Ubicación Fisica de los Equipos</Label>
                        <Input
                          id="locationType"
                          type="text"
                          className="form-control"
                          value={request.locationType}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="amountOfEquipmentIn">Cantidad de equipos dentro del radio</Label>
                        <Input
                          id="amountOfEquipmentIn"
                          type="number"
                          className="form-control"
                          value={request.amountOfEquipmentIn}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="distanceInside">Distancia del Centro de Servicios Autorizado</Label>
                        <Input
                          id="distanceInside"
                          type="text"
                          className="form-control"
                          value={request.distanceInside}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="amountOfEquipmentOut">Cantidad de equipos fuera del radio</Label>
                        <Input
                          id="amountOfEquipmentOut"
                          type="text"
                          className="form-control"
                          value={request.amountOfEquipmentOut}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="distanceOutside">Distancia del Centro de Servicios Autorizado</Label>
                        <Input
                          id="distanceOutside"
                          type="text"
                          className="form-control"
                          value={request.distanceOutside}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="4">
                      <FormGroup>
                        <Label for="localtionNotes">Notas</Label>
                        <Input
                          id="localtionNotes"
                          type="text"
                          className="form-control"
                          value={request.localtionNotes}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <Divider>Detalles de Mantenimientos</Divider>
                  <div className="form-row align-items-center">
                    <Col className="mb-1" sm="12" md="6">
                      <FormGroup>
                        <Label for="amountMaintenance">Cantidad de Mantenimientos Preventivos</Label>
                        <Input
                          id="amountMaintenance"
                          type="text"
                          className="form-control"
                          value={request.amountMaintenance}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-1" sm="12" md="6">
                      <FormGroup>
                        <Label for="scheduleMaintenance">Horario del Mantenimiento Preventivo</Label>
                        <Input
                          id="scheduleMaintenance"
                          type="text"
                          className="form-control"
                          value={request.scheduleMaintenance}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-row align-items-center justify-content-center">
                    <Col className="mb-3" sm="12" md="3" style={{
                      justifyContent: "center",
                      display: "flex",
                      alignContent: "center"
                    }}>
                      <Button
                        className="btn-icon"
                        color="danger"
                        type="button"
                        block
                        onClick={onBack}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-arrow-left" />
                        </span>
                        <span className="btn-inner--text">Salir</span>
                      </Button>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

RequestDetail.propTypes = {
  activityFlow: PropTypes.array.isRequired,
};

export default RequestDetail;
