// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React from 'react';
// reactstrap components
import { Badge, Button, Card, CardHeader, Col, Row, Table } from "reactstrap";

const HistoryParts = props => {

  const {
    title,
    subtitle,
    cols,
    rows,
    onToggle,
  } = props;

  return (
    <Card className="bg-default shadow">
      <CardHeader className="bg-transparent border-0">
        <Row>
          <Col xs="12" md='9'>
            <h6 className="surtitle text-white">{title}</h6>
            <h5 className="h3 mb-0 text-white">{subtitle}</h5>
          </Col>
          <Col xs="12" md="3" className="d-flex align-items-center justify-content-end">
            <Button
              block
              outline
              size="sm"
              color="info"
              className="btn-icon btn-3"
              onClick={onToggle}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-arrow-left" />
              </span>
              <span className="btn-inner--text">Regresar</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table
        className="align-items-center table-dark table-flush"
        responsive
      >
        <thead className="thead-dark">
          <tr>
            {
              cols.map((row, key) => (<th key={key}>{row.name}</th>))
            }
          </tr>
        </thead>
        <tbody className="list">
          {
            rows.map((row, key) => {
              return (
                <tr key={key}>
                  {
                    key === 0 ?
                      <>
                        <td rowSpan={row.counts} style={{ whiteSpace: "normal", textAlign: "center" }}>
                          {row.amountEquipments}
                        </td>
                        <td rowSpan={row.counts} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                          <Badge className="badge-lg mt-1" color="primary" pill>v{row.version}</Badge>
                        </td>
                        <td rowSpan={row.counts} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                          {row.typeModel}
                        </td>
                      </>
                      :
                      rows[key - 1].idEquipment !== row.idEquipment &&
                      <>
                        <td rowSpan={row.counts} style={{ whiteSpace: "normal", textAlign: "center" }}>
                          {row.amountEquipments}
                        </td>
                        <td rowSpan={row.counts} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                          <Badge className="badge-lg mt-1" color="primary" pill>v{row.version}</Badge>
                        </td>
                        <td rowSpan={row.counts} style={{ whiteSpace: "normal", textAlign: "justify" }}>
                          {row.typeModel}
                        </td>
                      </>
                  }
                  <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                    {row.platform}
                  </td>
                  <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                    {row.category}
                  </td>
                  <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                    {row.description}
                  </td>
                  <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                    {row.fru}
                  </td>
                  <td style={{ whiteSpace: "normal", textAlign: "justify" }}>
                    {row.amount}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </Card>
  );
};

HistoryParts.propTypes = {
  title: PropTypes.string.isRequired
};

export default HistoryParts;