//Imports de React
import React, { useState, useEffect, useContext } from 'react';
import {
  ModalHeader,
  Container,
  ModalBody,
  FormGroup,
  Button,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap";

// imports de Alerts y  Spin
import { Spin, Checkbox } from 'antd';

//Import de contexto para extraer states globales.
import { UserContext } from "../../SharedComponents/UserContext";

// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import { useDispatch } from 'react-redux';

import { getUsernameLogged } from 'selectors/adminLayout';

//Para traducir los labels y crear un idName.
import axios from 'axios';

//Action para el envio de la oportunidad apartir del JSON Principal EditData.
import { newLDRForm } from 'actions/autoppLdrs';


export const ModalSendForm = ({ options, openModal, setOpenModal, type, fields, handleReturnToStart, infoLDRToEdit, setRefreshOptions }) => {

  //Dispatch al API.
  const dispatch = useDispatch();

  const userName = getUsernameLogged();

  //#region States globales
  const {
    setSpinnerTable
  } = useContext(UserContext);
  //#endregion


  //#region Notificaciones

  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: true,
    timer: 5000,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  //#endregion





  //#region Métodos de creación o modificación del LDR.


  //Método para preparar el json antes de enviar, ej: cambiar el id select1 a quantityCardsLan en base al label


  //Traduce el título del LDR. Ej: LDR Colaboración -> LDR Colaboration
  const translateLDRTitle = (LDRTitle) => {
    //  let LDRTitle = fields[0].label;

    return new Promise((resolve, reject) => {
      const timeout = 2000; //2 seg.

      const timer = setTimeout(() => {
        reject(new Error("Tiempo de espera agotado")); //En caso que no haya respuesta en 2 segundos.
      }, timeout)

      axios
        .post(`https://libretranslate.de/translate`, {
          q: LDRTitle,
          target: "en",
          source: "es"
        })
        .then((response) => {
          clearTimeout(timer); //Limpiar el temportizador

          LDRTitle = response.data.translatedText;
          resolve(LDRTitle); // Resuelve la promesa con el valor traducido
        })
        .catch((error) => {
          clearTimeout(timer); //Limpiar el temportizador

          reject(error); // Rechaza la promesa si ocurre un error
        });
    });
  };


  //Lo construye en cammel case.
  const toCammelCase = (word) => {

    //Agrupa y realiza un cammel case 
    word = word.split(' ').map(wordA => {
      return wordA.charAt(0).toUpperCase() + wordA.slice(1);
    }).join("");

    return word
  }

  //Agregar el id a los fields antes de enviar.
  const adjustFields = (LDRTitle) => {
    let fieldsA = [...fields];

    // debugger;
    //Agregar el id del LDR Title a cada field
    // fieldsA = fieldsA.map(field => {
    //   if (field.dependsAnotherDropdown == 1) {
    //     let idDropdownDependsE = field.idDropdownDepends;
    //     let idE = field.id;

    //     if (!idDropdownDependsE.endsWith(LDRTitle)) //=>Si no tiene el title al final, se lo agrega.
    //     {
    //       idDropdownDependsE = idDropdownDependsE + LDRTitle;
    //     }

    //     if (!idE.endsWith(LDRTitle)) //=>Si no tiene el title al final, se lo agrega.
    //     {
    //       idE = idE + LDRTitle
    //     }
    //     // debugger;
    //     return {
    //       ...field,
    //       idDropdownDepends: idDropdownDependsE,
    //       id: idE
    //     }
    //   } else {
    //     let idE = field.id;

    //     if (!idE.endsWith(LDRTitle)) //=>Si no tiene el title al final, se lo agrega.
    //     {
    //       idE = idE + LDRTitle
    //     }
    //     return {
    //       ...field,
    //       id: idE
    //     }
    //   }
    // })

    console.log("FieldsA", fieldsA)
    return fieldsA;
  }
  //State para almacenar el LDR Title
  const [ldrTitle, setLdrTitle] = useState("")


  //Efecto para cuando arranque traduzca el titulo
  useEffect(() => {

    if (openModal) {
      // debugger;

      // let LDRTitleA = fields[0].label;



      // //Traducir el título primero.
      // translateLDRTitle(/*LDRTitle*/ LDRTitleA)
      //   .then((translatedText) => {
      //     setLdrTitle(toCammelCase(translatedText))

      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     setLdrTitle(toCammelCase(LDRTitleA))

      //   })

      setLdrTitle("LDR" + Date.now().toString(36))

    }

  }, [openModal])



  //#endregion





  //Campos para la pantalla del modal de guardar el LDR.
  const [fieldsModal, setfieldsModal] = useState([
    {
      id: "cycle",
      type: "select",
      label: "Tipo de venta del LDR",
      placeholder: "Seleccione una opción",
      width: 12,
      required: true,
      options: "cycle",
      // options: "cycle",
      optionsDepends: false,
    },
    {
      id: "brand",
      type: "select",
      label: "Marca del LDR",
      placeholder: "Seleccione una opción",
      width: 12,
      required: true,
      options: "brand",
      optionsDepends: true,
      idDropdownThatDepends: "cycle"
    },
    {
      id: "showOnlyInGTL",
      type: "checkbox",
      label: "Sólo aparezca en el ciclo GTL Quotation",
      required: false,
      fieldDepends: "cycle",
      valueDepends: "GTL Quotation"
    }

  ])

  //Almacena la selección del modal
  const [newInfoModal, setNewInfoModal] = useState([])

  //Spinner para cuando se envia la gestión que pueda aparecer cargando
  const [spinnerModal, setSpinnerModal] = useState(false)

  //Efecto para cargar newInfoModal con info
  useEffect(() => {
    let newInfoA = []

    fieldsModal.map(field => {
      newInfoA =
      {
        ...newInfoA,
        // [field.id]: ""
        [field.id]:
          type != "edit" ?
            (field.type == "checkbox" ? false : "")//Si no es edit, pongalo en blanco
            :
            //Si es edit 
            (field.type == "select" ?
              options.autoppCareOptions[field.id].filter(opt => opt.label == infoLDRToEdit[field.id + "Name"])[0]?.label

              : field.type == "checkbox" ?
                infoLDRToEdit[field.id] == 1 //si es 1 de true o 0 de false

                : infoLDRToEdit[field.id])//otro que no se contempla
      }
    })


    setNewInfoModal(newInfoA);
  }, [])


  //Para agregar la información seleccionada al state.
  const handleModalInfo = (id, type, value) => {

    let newInfoModalA = { ...newInfoModal };

    if (type == "select" || type == "checkbox") {
      newInfoModalA = {
        ...newInfoModalA,
        [id]: value
      }

    }
    newInfoModalA = handleCleanChild(id, newInfoModalA)

    setNewInfoModal(newInfoModalA);

  }

  //Limpia la información de los dropdowns hijos.
  const handleCleanChild = (idField, newInfoModalA) => {

    let childFields = fieldsModal.filter(f => f.idDropdownThatDepends == idField)


    let localNewInfoModalA = { ...newInfoModalA };

    if (childFields.length > 0) { //=> Existen dropdowns hijos a limpiar

      childFields.map(childF => {
        localNewInfoModalA = {
          ...localNewInfoModalA,
          [childF.id]: ""
        }
      })
    }

    return localNewInfoModalA;
  }


  //Valida si no se llenaron campos en este modal
  const validateFieldsModal = () => {
    let result = Object.values(newInfoModal).filter(field => typeof field === "string" && field === "");

    if (result.length > 0) {
      Toast.fire({
        title: "Atención",
        html: "Por favor, seleccione los anteriores campos obligatorios.",
        type: 'warning'
      });
      return false;
    }

    return true;

  }

  //Handle para enviar la petición
  const handleToSendPetition = (typeName, idButton) => {

    let ldrTitleAux= "LDR" + Date.now().toString(36);

    if (validateFieldsModal()) {

      MySwal.fire({

        type: 'success',
        title: `Guardar Documento`,
        html:

          `<h2>¿Está seguro que desea guardar el documento ${typeName}?</h2>`,

        confirmButtonText: 'Si, guardar',
        confirmButtonColor: '#2DCE89',
        cancelButtonText: 'No, cancelar',
        cancelButtonColor: '#f5365c',
        showCancelButton: true,

        preConfirm: () => {
          setSpinnerModal(true)
          dispatch(newLDRForm({ fields: adjustFields(ldrTitleAux), ldrTitle: ldrTitleAux, type: type, salesCycle: newInfoModal.salesCycle, brand: newInfoModal.brand, stage: idButton, showOnlyInGTL: newInfoModal.showOnlyInGTL ? 1 : 0, userName: userName, idLDR: infoLDRToEdit?.idNumber })).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
              setOpenModal(false);
              handleReturnToStart();
              document.body.style.overflow = "auto"
              setRefreshOptions(true);
              setSpinnerTable(true);

              Toast.fire({
                title: "Atención",
                html: `¡Documento enviado con éxito!`,
                type: 'success'
              });


            } else {

              Toast.fire({
                title: "Atención",
                html: payload.message,
                type: 'warning'
              });
            };

            setSpinnerModal(false);
          })


        },

      })
    }

  }

  //Para desplegar botones finales en el modal.
  const [modalButtons, setModalButtons] = useState(
    [
      {
        idNumber: 1,
        label: "En productivo",
        color: "success",
        id: "Production",
        icon: "fa fa-cogs",
        typeFunction: "send",
        width: 5
      },
      {
        idNumber: 2,
        label: "Como borrador",
        color: "warning",
        id: "Draft",
        icon: "fa fa-edit",
        typeFunction: "send",
        width: 4
      },
      {
        label: "Cancelar",
        color: "danger",
        id: "cancel",
        icon: "fa fa-reply",
        typeFunction: "cancell",
        width: 3
      }


    ]
  )

  //Genera las options dependiendo del field y si sus opciones dependen de lo que seleccione otro campo.
  const generateOptions = (field) => {
    // debugger;

    let result = options.autoppCareOptions &&
      options.autoppCareOptions[field.options] &&
      options.autoppCareOptions[field.options]
        .map((value) => {
          if (!field.optionsDepends ||
            (value[field.idDropdownThatDepends] == newInfoModal[field.idDropdownThatDepends])
          )
            return (
              <option id={value.value}>{value.label}</option>
            );
        })

    return result

  }

  const [openInformation, setopenInformation] = useState(false)

  return (
    <>
      <Modal isOpen={openModal} >
        <Spin size="large" spinning={spinnerModal}>
          <ModalHeader>
            <Row>
              <Col sm="12" className="ml-3">
                <h5 className="h3 mb-0">Guardar documento LDR</h5>
                <h6 className="surtitle">Selección de guardado</h6>
              </Col>

            </Row>

          </ModalHeader>


          <ModalBody className="d-flex justify-content-center mt--4 mb--2">

            <Container className=" ml--4 mr--4 p-0" >
              <Row>
                <Col sm="12">
                  {
                    fieldsModal.map((field, key) => (
                      <>
                        {field.type == "select" ?
                          <FormGroup >
                            <label
                              className="form-control-label ml-3"
                              htmlFor="input-username"
                            >
                              {field.label}{field.required ? "*" : ""}
                            </label>
                            <Input
                              id={field.id}
                              required={field.required}
                              placeholder={field.placeholder}
                              className="form-control text-dark"
                              value={newInfoModal[field.id]}
                              onChange={(e) => handleModalInfo(field.id, field.type, e.target.children[e.target.selectedIndex]?.value)}
                              type={field.type}

                            >
                              <option id=""></option>
                              {

                                generateOptions(field)


                              }

                            </Input>
                            <div className="invalid-feedback">
                              Este campo no puede estar vacío
                            </div>
                          </FormGroup>

                          : field.type == "checkbox" ?

                            (newInfoModal[field.fieldDepends] == field.valueDepends &&
                              <FormGroup className='ml-2 mb-1'>

                                <Checkbox
                                  checked={newInfoModal[field.id]}
                                  // onChange={(e) => handleActivateAllCheckbox(e.target.checked)}
                                  onChange={(e) => handleModalInfo(field.id, field.type, e.target.checked)}

                                >
                                </Checkbox>

                                <label
                                  className="form-control-label ml-3"
                                  htmlFor="input-username"
                                >
                                  {field.label}{field.required ? "*" : ""}
                                </label>


                              </FormGroup>)
                            :
                            <>
                            </>

                        }
                      </>
                    ))


                  }
                </Col>




              </Row>
              <Row className="">

                <Col sm="12" className="d-flex justify-content-center ">


                  <Button
                    color="default"
                    type="button"
                    size="sm"
                    className="mb-2 btn-neutral"
                    onClick={() => setopenInformation(!openInformation)}
                  >


                    <span className="btn-inner--text">Nota importante {"  "}</span>

                    <span className="btn-inner--icon mr-1">
                      <i className={openInformation ? "fa fa-minus" : "fa fa-eye"} />
                    </span>

                  </Button>


                </Col>
                {openInformation &&
                  <>
                    <Col sm="12" className='' style={{ display: "flex", justifyContent: "start" }}>
                      <label className="form-control-label ml-2" htmlFor="input-username">
                        En caso de que requiera que el formulario se muestre sólo para el GTL, debe seleccionar GTL Quotation en el Tipo de Venta, y deberá marcar el check que se habilitará.
                      </label>
                    </Col>
                    <Col sm="12" style={{ display: "flex", justifyContent: "start" }}>
                      <label className="form-control-label ml-2" htmlFor="input-username">
                        Al marcar el check, el formulario creado únicamente se visualizará cuando seleccione en Sales Stage: GTL Quotation.
                      </label>
                    </Col>
                    <Col sm="12" style={{ display: "flex", justifyContent: "start" }}>

                      <label className="form-control-label ml-2" htmlFor="input-username">
                        Al no seleccionarlo el formulario se visualizará cuando se seleccione en el sales stage:  Gesto Quotation o Quotation.                  </label>
                    </Col>
                  </>}
              </Row>
              <Row className="mt-2">
                <Col sm="12" style={{ display: "flex", justifyContent: "center" }}>
                  <label className="form-control-label" htmlFor="input-username">
                    ¿Cómo desea guardar el documento LDR?
                  </label>
                </Col>
              </Row>
              <Row className="p-0 justify-content-center">
                <Col xs="12" sm="auto">
                  {modalButtons.map((button) => (

                    <Button
                      key={button.label}
                      color={button.color}
                      type="button"
                      className="mb-2"
                      onClick={
                        button.typeFunction === 'send'
                          ? () => handleToSendPetition(button.label.toLowerCase(), button.idNumber)
                          : () => setOpenModal(false)
                      }
                    >

                      <span className="btn-inner--icon mr-1">
                        <i className={button.icon} />
                      </span>
                      <span className="btn-inner--text">{button.label}</span>

                    </Button>

                  ))}
                </Col>
              </Row>
            </Container>

          </ModalBody>
        </Spin>

      </Modal>
    </>
  )

}