
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Button,
    CardFooter,
    Tooltip
} from "reactstrap";
import FormInformation from "components/FinanceFlows/FlowsAprobation/FormInformation";
import FormApprovers from "components/FinanceFlows/FlowsAprobation/FormApprovers";



const RequestInformation = ({ disabled, InformationTamplet, handleOnChangeInfo, getTamplets, template, setApprovers, approvers, deleteApprovers, setView, info, newInfo, newInfoToInsert,
    handleOnChangeInfoTable, handleOnChangeCountRows, countRows, disabledForm, setCountRows, setNewInfoToInsert, setNewInfo, setGenerateRequest, generateRequest }) => {

    const handleOnChangeTemplate = () => {
        setView(0);
        setCountRows([]);
        setNewInfoToInsert([]);
        setNewInfo([])
    }
    useEffect(() => {
        handleOnGenerateRequest()
    }, [newInfoToInsert]);

    useEffect(() => {
        handleOnGenerateRequest()
    }, []);


    const handleOnGenerateRequest = () => {
        let requireds = [];
        let newInfoRequired = [];
        let notRequired = 0;
        for (const item of InformationTamplet) {
            if (item.required === 1) {
                requireds.push(`${item.id}`)
            } else {
                notRequired++;
            }
        }
        if (notRequired !== InformationTamplet.length) {
            for (const item of Object.keys(newInfoToInsert)) {
                if (newInfoToInsert[item] !== "") {
                    newInfoRequired.push(`${item}`)
                }
            }
            const validate = requireds.filter((item) => newInfoRequired.includes(item))
            if (validate.length === requireds.length) {
                if (requireds.length !== 0) {
                    setGenerateRequest(false)
                }
            } else {

                setGenerateRequest(true)

            }
        } else {
            setGenerateRequest(false);
        }

    }
    return (
        <div>
            {info.view === 0 ?
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h3 className="mb-0">
                                            Información de Solicitud
                                        </h3>
                                    </Col>
                                    <Col xs="4">
                                        <Button
                                            className="btn  btn-icon"
                                            color="info"
                                            block
                                            type="button"
                                            onClick={() => getTamplets()}
                                        >
                                            <span className="btn-inner--icon mr-">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <span className="btn-inner--text">Buscar Plantilla</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <FormInformation
                                    InformationTamplet={InformationTamplet}
                                    handleOnChangeInfo={handleOnChangeInfo}
                                    handleOnChangeInfoTable={handleOnChangeInfoTable}
                                    disabled={disabled}
                                    handleOnChangeCountRows={handleOnChangeCountRows}
                                    newInfo={newInfo}
                                    typeForm={2}
                                    countRows={countRows}
                                    identificator={"unitTypeId"}
                                    valueInsert={"id"}
                                    disabledForm={disabledForm}
                                />
                            </CardBody >
                            <CardFooter>
                                <span>Los campos que incluyen * son requeridos</span>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h3 className="mb-0">
                                            Plantillas de Aprobación
                                        </h3>
                                    </Col>
                                    <Col xs="3">
                                        {
                                            approvers.length > 0 &&
                                            <Button
                                                className="btn  btn-icon btn-sm"
                                                color="info"
                                                block
                                                type="button"
                                                onClick={() => deleteApprovers()}
                                            >
                                                Cambiar plantilla
                                            </Button>
                                        }

                                    </Col>
                                </Row>

                            </CardHeader>
                            <CardBody>
                                {/* Componente donde tenemos la lista de plantillas */}
                                <FormApprovers
                                    template={template}
                                    approvers={approvers}
                                    setApprovers={setApprovers}
                                />
                            </CardBody >
                            {
                                approvers.length > 0 &&
                                <CardFooter>
                                    <Row>
                                        <Col xs="4">
                                            <Button
                                                disabled={generateRequest}
                                                className="btn  btn-icon "
                                                color="success"
                                                block
                                                id={"generateRequest"}
                                                type="button"
                                                title={"Debe completar los campos con '*'"}
                                                onClick={() => setView(1)}
                                            >
                                                Generar Solicitud
                                            </Button>

                                        </Col>
                                    </Row>
                                </CardFooter>
                            }
                        </Card>
                    </Col>
                </Row>
                :
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h3 className="mb-0">
                                    Flujos de Aprobación
                                </h3>
                            </Col>
                            {
                                <Col xs="2">
                                    <Button
                                        className="btn  btn-icon"
                                        color="dark"
                                        block
                                        type="button"
                                        onClick={() => handleOnChangeTemplate()}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-sync-alt"></i>
                                        </span>
                                        <span className="btn-inner--text">Cambiar</span>
                                    </Button>
                                </Col>
                            }
                        </Row>
                    </CardHeader>
                    <CardBody>

                        <Row className="mb-3">
                            <span className="h6 surtitle text-muted">
                                Flujo seleccionado:
                            </span>
                        </Row>
                        <Row className="mb-3">
                            <h1>{info.Template.description}</h1>
                        </Row>
                    </CardBody>
                </Card>
            }
        </div >
    );
};


export default RequestInformation;