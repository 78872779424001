export const getConflictInterestsInfo = (state) => {
  if (Object.keys(state.conflictInterestsInfo).length) {
    return state.conflictInterestsInfo.info;
  }
  return {};
};

export const getAllConflictInterestsInfo = (state) => {
  if (Object.keys(state.allConflictInterestsInfo).length) {
    return state.allConflictInterestsInfo.forms;
  }
  return [];
};

export const getConflictInterests = (state) => {
  if (Object.keys(state.conflictInterests).length) {
    let { info } = state.conflictInterests;
    return info;
  }
  return {};
};
