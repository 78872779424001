
// react library
import React, { useState, useEffect, useRef } from 'react';
import moment from "moment";
//Token 
import { getUsernameLogged } from "selectors/adminLayout";
//usedispatch for fetch
import { useDispatch } from 'react-redux';
// reactstrap components
import {
    Button, Card, CardBody, CardHeader, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Form,
    TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import classnames from 'classnames';
//componentes Document System
import EditFormOne from 'components/DocumentSystem/EditFormOne';
import EditFormTwo from 'components/DocumentSystem/EditFormTwo';
import EditFormThree from 'components/DocumentSystem/EditFormThree';
import LogModal from 'components/DocumentSystem/LogModal';

//actions for endpoint
import { getDataLog, updateRequest } from 'actions/DocumentSystem.jsx';
// antd components
import { Spin } from 'antd';

const EditDocumentModal = ({
    showModal,
    setModal,
    row,
    documentType,
    estado,
    country,
    rejectedReason,
    discountType,
    Toast,
    isAdmin,
    isDisplay,
    filesCustomer,
    MySwal,
    dropdowns,
    setRealTable
}) => {
    //#region Funciones
    console.log(row)

    const [loading, setLoad] = useState(false);

    const userName = getUsernameLogged();

    //state principal
    const [state, setState] = useState({
        title: "Nuevo Documento",
        unmountOnClose: true,
        fileList: null,
        onFileList: null,

    });

    const { unmountOnClose, fileList, onFileList, search, filters } = state;

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //state para determinar en que tab estoy 
    const [activeTab, setActiveTab] = useState('1');

    //funcion que cambia el tab
    const handleTab = (event, tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    //para cancelar o cerrar el modal
    const toggleEditDocumentModal = (() => {

        setActiveTab('1');
        setUpdateInfo(iState);
        setdisplayI(false);
        setModal((prevState) => ({
            ...prevState,
            editRow: {},
            filesCustomer: [],
            editModal: !prevState.editModal,
        }));


    });

    //state para abrir el modal de log
    const [logModal, setLogModal] = useState({
        modalToggel: false,
        documentId: "",
        modalInfo: [],
    });
    const { modalToggel, documentId, modalInfo } = logModal;



    //función para traer el log de cambios
    useEffect(() => {
        if (documentId !== "") {

            const getLogR = async () => {
                await getLog(documentId);
                // setLoad(false);
            }
            getLogR();
        }

    }, [documentId]);

    const getLog = async (documentId) => {

        dispatch(getDataLog({ documentId: documentId })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setLogModal((prev) => ({
                    ...prev,
                    modalInfo: payload.data.payload.logInfo,
                }))
            } else {
                setLogModal((prev) => ({
                    ...prev,
                    documentId: "",
                    modalInfo: [],
                }))
            }
        })

    }

    //siempre se necesita el documentId y el estado previo para determinar si se ingresa el log
    const iState = {
    }
    const [updateInfo, setUpdateInfo] = useState(iState);

    //funcion para enviar los datos a modificar
    const handleOnChange = (constant, value) => {
        // debugger;
        //mostrar un pop up de rechazo 
        if (constant === "status") {
            if (value === "RJ" || value === "CA") {
                let selectHtml = '<select id="rejectedReasonId" class="form-control"><option value=""></option>';
                console.log(dropdowns["rejectedReason"]);
                dropdowns["rejectedReason"].forEach(function (json) {
                    if (json["type"] === value || value === "CA") {
                        selectHtml =
                            selectHtml +
                            `<option value="${json["value"]}">${json["label"]}</option>`;
                    }
                });
                selectHtml = selectHtml + "</select><br>";

                MySwal.fire({
                    title: `Razón de ${(value === "RJ") ? "rechazado" : "cancelado"} de la Orden: ${row["documentId"]}`,
                    html:
                        selectHtml +
                        '<textarea class="form-control" rows="3" id="cancelledReasonNew" placeholder="Opcional..."></textarea>',
                    confirmButtonText: `Si, ${(value === "RJ") ? "rechazar" : "cancelar"}.`,
                    confirmButtonColor: "#f5365c",
                    cancelButtonText: "No, cancelar",
                    showCancelButton: true,
                    preConfirm: () => {
                        setLoad(true);
                        console.log(document.getElementById("rejectedReasonId"))
                        console.log(document.getElementById("rejectedReasonId").options)
                        console.log(document.getElementById("rejectedReasonId").value)
                        if (
                            document.getElementById("rejectedReasonId").value === "" || document.getElementById("rejectedReasonId").value === undefined
                        ) {
                            MySwal.showValidationMessage("la Razón es obligatoria");
                            setLoad(false);
                        } else {
                            //actualizar el status
                            setUpdateInfo(prevState => ({
                                ...prevState,
                                [constant]: value,
                            }));

                            //actualizar la razon y comentario

                            setUpdateInfo(prevState => ({
                                ...prevState,
                                "rejectedReason": document.getElementById("rejectedReasonId").value,
                                "rejectedReasonLabel": document.getElementById("rejectedReasonId").options[document.getElementById("rejectedReasonId").selectedIndex].text,
                            }));
                            if (document.getElementById("cancelledReasonNew").value !== "") {
                                setUpdateInfo(prevState => ({
                                    ...prevState,
                                    "cancelledReason": document.getElementById("cancelledReasonNew").value,
                                }));
                            }

                            setLoad(false);
                        }
                    },
                });
            }
            else {
                setUpdateInfo(prevState => ({
                    ...prevState,
                    [constant]: value,
                }));
            }
        }
        // else if (constant === "discountAmount") {
        //     const parsedValue = formatNumber(value.replace(/,/g, "").replace(/\./g, "."));
        //     setUpdateInfo(prevState => ({
        //         ...prevState,
        //         [constant]: parsedValue,
        //     }));
        // }
        else {
            setUpdateInfo(prevState => ({
                ...prevState,
                [constant]: value,
            }));
        }
    }

    //estado para hacer disabled el boton de guardar
    const [sending, setSending] = useState(false);

    //funcion boton guardar
    const handleUpdateDocument = () => {
        setSending(true);
        const info = updateInfo;
        info["documentId"] = row["documentId"];
        info["prevStatus"] = row["status"];
        info["userRequest"] = row["createdBy"];
        info["newStatus"] = typeof estado.find(dt => dt.value === info["status"]) !== "undefined" ? estado.find(dt => dt.value === info["status"]).label : ""

        dispatch(updateRequest({ updateInfo: info })).then((resp) => {
            const { payload } = resp;

            if (payload.status === 200) {


                //actualizar la propiedad Row para que aparezca con los nuevos cambios
                Object.keys(info).forEach(function (key) {

                    if (key === "status") {
                        row["statusName"] = estado.find(dt => dt.value === info[key]).label;
                    }
                    else if (key === "submittedToIBM") {
                        row["SubIBM"] = info[key];
                    }
                    row[key] = key !== "documentTrading" ? info[key] : info[key].join(",");
                });

                row["Udate"] = moment().format("DD/MM/YYYY H:mm:ss");
                row["updatedBy"] = userName;

                setRealTable(prev => !prev);
                //cerrar el modal
                toggleEditDocumentModal();

                Toast.fire({
                    title: `Se actualizó correctamente`,
                    type: 'success'
                })


            } else {
                Toast.fire({
                    title: payload.response.data.message,
                    type: 'warning'
                })
            }
            setSending(false);
        })

    }

    //state para editar los campos del modal: false = bloqueado
    const [displayI, setdisplayI] = useState(false);

    //función para editar los inputs en el modal
    const handleToggle = ((e) => { setdisplayI(!displayI); });

    // Function to format the number with the desired format "0,000.00"
    function formatNumber(num) {

        if (num !== undefined && num !== null) {
            const sanitizedValue = num
                .replace(/[^\d.,]/g, "") // Remove all non-numeric, comma, and dot characters
                .replace(/\./g, "") // Remove any dots (if user used "25.000,99" format)
                .replace(",", "."); // Replace the comma with a dot for correct parsing

            const parsedValue = parseFloat(sanitizedValue);
            return isNaN(parsedValue) ? "" : parsedValue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
        return "";
    }

    //#endregion

    return (
        <>

            <LogModal
                modalToggel={modalToggel}
                documentId={documentId}
                setLogModal={setLogModal}
                info={modalInfo}
            />


            <Modal size="lg" isOpen={showModal} unmountOnClose={unmountOnClose} toggle={toggleEditDocumentModal}>
                <Spin size="large" spinning={loading}>
                    <ModalHeader>
                        {/* <Editar></Editar> */}
                        Editar Documento
                    </ModalHeader>
                    <ModalBody className='mb-lg-0'>
                        {
                            isAdmin ?
                                <label className="custom-toggle custom-toggle-info">
                                    {/* defaultChecked={displayI} */}
                                    <input id="edit" type="checkbox" checked={displayI} onChange={(e) => handleToggle(e)} />
                                    <span className="custom-toggle-slider rounded-circle" data-label-off="M" data-label-on="E"></span>
                                </label>
                                :
                                <></>
                        }
                        <Form>
                            {/* <div className='nav-wrapper'> */}
                            <Card className='shadow mb-lg-0'>
                                <CardHeader className="border-0">
                                    <Nav
                                        className='nav-fill flex-column flex-md-row'
                                        id='tabs-icons-text'
                                        pills
                                        role='tablist'
                                    >
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === '1'}
                                                className={classnames('mb-sm-3 mb-md-0', {
                                                    active: activeTab === '1'
                                                })}
                                                onClick={e => handleTab(e, '1')}
                                                role='tab'
                                            >
                                                <i className='ni ni-badge mr-2' />
                                                Datos Generales
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === '2'}
                                                className={classnames('mb-sm-3 mb-md-0', {
                                                    active: activeTab === '2'
                                                })}
                                                onClick={e => handleTab(e, '2')}
                                                role='tab'
                                            >
                                                <i className='ni ni-badge mr-2' />
                                                Datos SAP
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === '3'}
                                                className={classnames('mb-sm-3 mb-md-0', {
                                                    active: activeTab === '3'
                                                })}
                                                onClick={e => handleTab(e, '3')}
                                                // href='#AUopp'
                                                role='tab'
                                            >
                                                <i className='ni ni-badge mr-2' />
                                                Datos Adicionales
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <TabContent activeTab={activeTab}>
                                        {/* Datos Generales */}
                                        <TabPane tabId='1'>
                                            <EditFormOne
                                                row={row}
                                                documentType={documentType}
                                                country={country}
                                                estado={estado}
                                                displayI={!displayI}
                                                handleOnChange={handleOnChange}
                                                setUpdateInfo={setUpdateInfo}
                                            />
                                        </TabPane>
                                        {/* Datos SAP */}
                                        <TabPane tabId='2'>
                                            <EditFormTwo
                                                row={row}
                                                displayI={!displayI}
                                                handleOnChange={handleOnChange}
                                            />
                                        </TabPane>
                                        {/* Datos Adicionales */}
                                        <TabPane tabId='3'>
                                            <EditFormThree
                                                filesCustomer={filesCustomer}
                                                row={row}
                                                displayI={!displayI}
                                                handleOnChange={handleOnChange}
                                                setLogModal={setLogModal}
                                                isAdmin={isAdmin}
                                                isDisplay={isDisplay}
                                                Toast={Toast}
                                                rejectedReason={rejectedReason}
                                                discountType={discountType}
                                                setModal={setModal}
                                                formatNumber={formatNumber}
                                            />
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Form>

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            type="button"
                            onClick={(e) => toggleEditDocumentModal()}
                        >
                            Cancelar
                        </Button>
                        {
                            Object.keys(updateInfo).length > 0 &&
                            <Button
                                color="success"
                                type="button"
                                disabled={sending}
                                onClick={() => handleUpdateDocument()}
                            >
                                Guardar
                            </Button>
                        }

                    </ModalFooter>
                </Spin>
            </Modal>
        </>
    )
};

export default EditDocumentModal;