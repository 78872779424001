import AdminHeader from "components/Shared/Header/AdminHeader.jsx";

// react library
import React, { Component } from "react";

import _ from "lodash";

import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import moment from "moment";
// reactstrap components
import {
  UncontrolledAlert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";

import {
  getApprovers,
  createRequest,
  getOtherApprovers,
} from "actions/ASActions.jsx";

import { approversSelector, otherApproversSelector } from "selectors/AS.jsx";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import XLSX from "xlsx";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import { Spin } from "antd";
import { getUserProtected, getTeamsUserAccess } from "selectors/adminLayout";

const DragHandle = sortableHandle(() => <span className="fa fa-sort"></span>);

const SortableItem = sortableElement(({ value, disabled }) => (
  <ListGroup>
    <DragHandle />
    {value}
  </ListGroup>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ListGroup>{children}</ListGroup>;
});

const { SearchBar } = Search;
const optionsSales = [
  { id: "01", color: "#f28a19", color2: "#f69a36", text: "Cambios dentro del Grid y hasta un 15% (No incluye Gerentes)" },
  { id: "02", color: "#c22c41", color2: "#c14153", text: "Cambios de salario de Gerentes dentro y fuera del Grid." },
  { id: "03", color: "#013e5e", color2: "#0d5981", text: "Superior al 15% o cuando obedece a un Plan Acelerado de incrementos que supera el 15% anual (Personal que reporta a Gerente General)." },
  { id: "04", color: "#48b5b6", color2: "#6ad3d4", text: "Personal que reporta a Directores" },
  { id: "05", color: "#86af4a", color2: "#a2d05e", text: "General Services (Solo para HQ)" },
  // {id:"06",text:"Personal que reporta a Gerentes Generales fuera de los rangos establecidos en el grid o cuando obedece a un Plan Acelerado.",text:"Personal que reporta a Gerentes Generales fuera de los rangos establecidos en el grid o cuando obedece a unPlan Acelerado"},
];

class SalaryApprovalNewRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: null,
      cols: null,
      notes: "",
      country: "",
      selectedApprovers: [],
      showConfirmAlert: false,
      showCreateRequestAlert: false,
      createdRequest: null,
      showInputErrorAlert: false,
      showDocumentErrorAlert: false,
      errorInfo: null,
      showApproversModal: false,
      approvers: [],
      otherApprovers: [],
      selectedOtherApprovers: [],
      selectedOtherApproversList: [],
      showConfirmDeleteApprover: false,
      selectedRemoveIndex: null,
      requestType: null,
      userFilter: "",
      loadingApprovers: false,
      //MEJORAs
      approversLoaded: false,
      approversError: { error: "", value: false },
      creatingRequest: false,
      payrollApprover: {},
      //IMPLEMENTACION DE FECHA
      date: null,
      countries: [],
      isColombia: false,
    };
  }

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleUserFilters = (value) => {
    this.setState({ userFilter: value.target.value });
  };

  handleOnCreateRequest = async () => {
    this.setState({ showCreateRequestAlert: false, creatingRequest: true });
    let err = false;
    if (
      this.state.notes === null ||
      this.state.notes.length === 0 ||
      this.state.country === null ||
      this.state.country.length === 0
    )
      err = true;
    if (err) {
      this.setState({
        showInputErrorAlert: true,
        errorInfo:
          "Encontramos errores en la información ingresada. Los Aprobadores, Notas y Pais son obligatorios. ",
      });
    } else {
      let data = {
        note: this.state.notes,
        country: this.state.country,
        user: {
          id: null,
        },
        doc: {
          rows: this.state.rows,
          cols: this.state.cols,
        },
        approvers: _.without(this.state.approvers, undefined),
        type: this.state.requestType.type,
        date: this.state.date,
      };
      await this.props.createRequest(data).then(async (res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState({
            creatingRequest: false,
          });
        } else {
          this.notify("success", "Solicitud creada Exitosamente.", "OK");
          let data = res.payload.data.payload.data;
          if (data.request.id) this.setState({ showConfirmAlert: true });
          this.setState({
            createdRequest: data.request.id,
            creatingRequest: false,
          });
        }
      });
    }
  };

  newFileHandler = (e) => {
    const scope = this;
    let cols = [];
    let rows = [];
    let errFields = [];
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    if (
      files[0].type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      this.setState({
        showDocumentErrorAlert: true,
        errorInfo:
          "Solo permitimos adjuntar archivos de Hojas de Cálculo (.xlsx).",
      });
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary", cellDates: true });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

        dataParse[0].forEach((value, field) => {
          cols.push({
            key: field,
            dataField: value.toString().replace(/ /g, "_"),
            text: value.toString(),
            sort: true,
          });
        });

        dataParse.forEach((value, key) => {
          if (key > 0 && value.length > 0) {
            var tempRows = { key: key };
            value.forEach((row, belongs) => {
              switch (cols[belongs].dataField) {
                case "Fecha_ante-penultimo_aumento_":
                  row = moment(row).format("MM-YYYY");
                  tempRows[cols[belongs].dataField] = row;
                  break;

                case "Fecha_Penultimo_aumento_":
                  row = moment(row).format("MM-YYYY");
                  tempRows[cols[belongs].dataField] = row;
                  break;

                case "Fecha_último_aumento_":
                  row = moment(row).format("MM-YYYY");
                  tempRows[cols[belongs].dataField] = row;
                  break;

                case "Mes_aplicación_aumento":
                  row = moment(row).format("MM-YYYY");
                  tempRows[cols[belongs].dataField] = row;
                  break;

                case "%_salario__variable_nuevo":
                  row = Math.round(row * 100);
                  tempRows[cols[belongs].dataField] = row.toString() + "%";
                  break;

                case "%_salario_fijo_nuevo":
                  row = Math.round(row * 100);
                  tempRows[cols[belongs].dataField] = row.toString() + "%";
                  break;

                case "Porcentaje_de_aumento":
                  row = row * 100;
                  if (!Number.isInteger(row)) row = row.toFixed(2);
                  tempRows[cols[belongs].dataField] = row.toString() + "%";
                  break;

                default:
                  tempRows[cols[belongs].dataField] = row;
                  break;
              }
            });
            rows.push(tempRows);
          }
        });

        //VALIDATIONS
        if (rows.length === 0) {
          errFields.push("El Documento esta en blanco.");
        }
        rows.map((value) => {
          if (
            value["%_salario__variable_nuevo"].slice(-1) !== "%" ||
            value["%_salario_fijo_nuevo"].slice(-1) !== "%"
          ) {
            errFields.push("Porcentaje de salario deben ser porcentajes.");
          } else if (
            isNaN(value["%_salario__variable_nuevo"].slice(0, -1)) ||
            isNaN(value["%_salario_fijo_nuevo"].slice(0, -1))
          ) {
            errFields.push("Porcentaje de salario deben ser numeros enteros.");
          } else {
            if (
              parseInt(value["%_salario__variable_nuevo"].slice(0, -1)) +
              parseInt(value["%_salario_fijo_nuevo"].slice(0, -1)) !==
              100
            ) {
              errFields.push("Porcentaje de salario diferente a 100%.");
            }
          }
        });
        console.log(errFields);
        //VALIDATIONS
        if (errFields.length > 0) {
          scope.setState({
            showDocumentErrorAlert: true,
            errorInfo:
              "Encontramos errores en la importación del documento: " +
              errFields[0],
          });
        } else {
          scope.setState({
            cols: cols,
            rows: rows,
          });
          let approverInfo = {
            document: rows,
            type: scope.state.requestType.type,
            date: scope.state.date,
          };
          scope.setState(() => ({
            loadingApprovers: true,
          }));
          console.log(approverInfo);
          scope.props.getApprovers(approverInfo).then(async (res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;

                await scope.notify("danger", "Falló", payload.message);
              } else {
                await scope.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
              scope.setState(() => ({
                loadingApprovers: false,
              }));
            } else {
              if (res.payload.data.payload.error.error) {
                scope.setState({
                  approversError: {
                    error: res.payload.data.payload.error.errorMessage,
                    value: true,
                  },
                });
              }
              scope.setState({
                approvers: scope.props.approversData,
                approversLoaded: true,
              });
              scope.setCountries();
              scope.setState((scope) => ({
                loadingApprovers: !scope.loadingApprovers,
              }));
            }
          });
        }
      };
      reader.readAsBinaryString(f);
    }
  };

  handleSelectOtherApprover = (item) => {
    item.selected = !item.selected;
    var otherApproverlist = this.state.selectedOtherApprovers;
    otherApproverlist.push(item);
    this.setState({ selectedOtherApprovers: otherApproverlist });
  };

  handleRemoveOtherApprover = (item) => {
    item.selected = !item.selected;
    var otherApproverlist = this.state.selectedOtherApprovers;
    otherApproverlist.push(item);
    otherApproverlist = _.remove(otherApproverlist, function (approver) {
      return approver.name === item.name;
    });
    this.setState({ otherApproverlist });
  };

  handleAddOtherApprovers = () => {
    var tempApprover = [];
    this.state.otherApprovers.forEach((value, key) => {
      if (value.selected) {
        tempApprover.push({
          POSICION: value.position,
          APLICA: null,
          PERSON: value.UserID,
          ZUSER: value.user,
          NUMERO: "",
          sign: value,
          originalApprover: false,
        });
      }
    });
    let tempApprovers = this.state.approvers;
    let tempPayrollIndex = _.findIndex(tempApprovers, { APLICA: "X" });
    let tempPayroll = _.find(tempApprovers, { APLICA: "X" });
    tempApprovers.splice(tempPayrollIndex, 1);
    this.setState({
      approvers: [...tempApprover, ...tempApprovers, tempPayroll],
    });
    this.setState((prevState) => ({
      showApproversModal: !prevState.showApproversModal,
    }));
  };

  handleApproverSelection = (item, index) => {
    this.setState({
      showConfirmDeleteApprover: true,
      selectedRemoveIndex: index,
    });
  };

  handleConfirmRemove = () => {
    this.setState({ showConfirmDeleteApprover: false });
    //AQUI CONFIRMAMOS LA ELIMINACION
    let index = this.state.selectedRemoveIndex;

    let array = [...this.state.approvers];
    if (index !== -1) {
      var deletedApprover = array[index];
      array.splice(index, 1);
      this.setState({ approvers: array });
      var inList = _.find(this.state.selectedOtherApprovers, {
        user: deletedApprover.ZUSER,
      });
      if (inList !== undefined) {
        var otherApproverlist = this.state.selectedOtherApprovers;
        otherApproverlist = _.remove(otherApproverlist, function (approver) {
          return approver.user === deletedApprover.ZUSER;
        });
        this.setState({ otherApproverlist });
      }
    }
  };

  goToRequest = (request) => {
    this.props.history.push(`/admin/salary/details/${request}`);
  };

  closeDocumentError() {
    this.setState({
      showDocumentErrorAlert: false,
      errorInfo: null,
    });
  }

  closeInputError() {
    this.setState({
      showInputErrorAlert: false,
      errorInfo: null,
    });
  }

  hideConfirmNotification = () => {
    this.setState({ showCreateRequestAlert: false });
  };

  hideDeleteNotification = () => {
    this.setState({ showConfirmDeleteApprover: false });
  };

  //APPROVERS MODAL

  toggleApproversModal() {
    this.props.getOtherApprovers().then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          await this.notify("danger", "Falló", payload.message);
        } else {
          await this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          otherApprovers: this.props.otherApproversData,
        });
      }
    });

    this.setState((prevState) => ({
      showApproversModal: !prevState.showApproversModal,
    }));
  }
  //APPROVERS MODAL

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (this.state.approvers[newIndex].APLICA !== "X") {
      this.setState(({ approvers }) => ({
        approvers: arrayMove(approvers, oldIndex, newIndex),
      }));
    } else {
      this.setState({
        showInputErrorAlert: true,
        errorInfo: "El Payroll debe estar al final del flujo de aprobación. ",
      });
    }
  };

  handleRequestTypeSelection = (type, name) => {
    this.setState({ requestType: { type: type, name: name } });
  };

  handleClearRequestType = (type, name) => {
    this.setState({ requestType: null });
  };

  handleOnChangeCountry = (country) => {
    this.setState({ country: country });
  };

  updateDateValue = (date) => {
    let selectedDate = this.state.date;
    selectedDate = date;
    this.setState({ date: selectedDate });
  };

  setCountries() {
    let countries = [];
    const { rows } = this.state;
    const teams = getTeamsUserAccess();
    if (_.includes(teams, "HC Salary Approval")) {
      countries = [
        { country: "Corporación", code: "HQ" },
        { country: "Costa Rica", code: "CR" },
        { country: "Panama", code: "PA" },
        { country: "Nicaragua", code: "NI" },
        { country: "Guatemala", code: "GT" },
        { country: "Honduras", code: "HN" },
        { country: "El Salvador", code: "SV" },
        { country: "Colombia", code: "CO" },
        { country: "Republica Dominicana", code: "DR" },
        { country: "Miami", code: "MD" },
      ];
      this.setState({ countries, country: countries[0].code });
    } else {
      for (let index = 0; index < teams.length; index++) {
        switch (teams[index]) {
          case "HC Salary Approval HQ":
            countries.push({ country: "Corporación", code: "HQ" });
            break;
          case "HC Salary Approval CR":
            countries.push({ country: "Costa Rica", code: "CR" });
            break;
          case "HC Salary Approval PA":
            countries.push({ country: "Panama", code: "PA" });
            break;
          case "HC Salary Approval NI":
            countries.push({ country: "Nicaragua", code: "NI" });
            break;
          case "HC Salary Approval GT":
            countries.push({ country: "Guatemala", code: "GT" });
            break;
          case "HC Salary Approval HN":
            countries.push({ country: "Honduras", code: "HN" });
            break;
          case "HC Salary Approval SV":
            countries.push({ country: "El Salvador", code: "SV" });
            break;
          case "HC Salary Approval CO":
            countries.push({ country: "Colombia", code: "CO" });
            break;
          case "HC Salary Approval DR":
            countries.push({ country: "Republica Dominicana", code: "DR" });
            break;
          case "HC Salary Approval MD":
            countries.push({ country: "Miami", code: "MD" });
            break;
        }
      }
      this.setState({ countries, country: countries[0].code });
    }

    if (
      rows[0]["Auxilio_de_Alimentación_ACTUAL"] &&
      rows[0]["NUEVO_Auxilio_Alimentación"]
    ) {
      if (_.findIndex(countries, { code: "CO" }) !== -1) {
        this.setState({ isColombia: true, country: "CO" });
      }
    } else {
      if (countries[0].code === "CO") {
        countries.push(countries.splice(0, 1)[0]);
        this.setState({ countries, country: countries[0].code });
      }
    }
  }
  componentDidMount() {
    let selectedDate = this.state.date;
    if (selectedDate === null)
      this.setState({ date: moment().format("YYYY-MM-DD") });
  }

  render() {
    let {
      notes,
      country,
      createdRequest,
      approvers,
      otherApprovers,
      requestType,
      userFilter,
      selectedOtherApprovers,
      selectedOtherApproversList,
      loadingApprovers,
      approversLoaded,
      approversError,
      creatingRequest,
      date,
      countries,
      isColombia,
    } = this.state;
    let approversRender = [];
    let otherApproversRender = [];
    let payrollApprover = {};
    if (approvers) {
      approvers.forEach((approver, index) => {
        if (approver.APLICA !== "X") {
          approversRender.push(
            //   <ListGroupItem className="px-0" key={index}>
            <Row className="align-items-center">
              <Col className="col-auto">
                <p className="avatar rounded-circle">
                  <img alt="..." src={require("assets/img/theme/user.svg")} />
                </p>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">
                  <p>{approver.sign.name}</p>
                </h4>
                <small>
                  <strong> Posición: </strong>
                  {approver.sign.position}
                </small>
                <br></br>
                {/* {approver.APLICA === "X" ? (
                <small>
                  <strong> Payroll Seleccionado </strong>
                </small>
              ) : (
                <div></div>
              )} */}
              </div>
              <Col className="col-auto">
                {!approver.originalApprover && (
                  <Button
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={() =>
                      this.handleApproverSelection(approver, index)
                    }
                  >
                    Eliminar
                  </Button>
                )}
              </Col>
            </Row>
          );
        }
      });

      if (approvers.find((value) => value.APLICA === "X")) {
        payrollApprover = approvers.find((value) => value.APLICA === "X");
      }
    }

    if (otherApprovers) {
      var buttonHtml = null;
      var foundApprovers = [];
      if (userFilter != null && userFilter.length > 0) {
        var search = new RegExp(userFilter, "i");
        let founds = otherApprovers.filter((item) => search.test(item.name));
        foundApprovers = founds;
      } else {
        foundApprovers = otherApprovers;
      }
      foundApprovers.forEach((approver, index) => {
        var inList = _.find(selectedOtherApprovers, { name: approver.name });
        if (!approver.selected && inList === undefined) {
          buttonHtml = (
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={() => this.handleSelectOtherApprover(approver)}
            >
              Seleccionar
            </Button>
          );
        } else {
          if (approver.selected || inList !== undefined)
            buttonHtml = (
              <Button
                color="default"
                size="sm"
                type="button"
                onClick={() => this.handleSelectOtherApprover(approver)}
                disabled
              >
                Seleccionar
              </Button>
            );
        }

        otherApproversRender.push(
          <ListGroupItem className="px-0" key={"lista-" + index}>
            <Row className="align-items-center">
              <Col className="col-auto">
                <a
                  className="avatar rounded-circle"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img alt="..." src={require("assets/img/theme/user.svg")} />
                </a>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">
                  <a
                    onClick={(e) => e.preventDefault()}
                    href="e.preventDefault()"
                  >
                    {approver.name}
                  </a>
                </h4>
                <small>{approver.position} </small>
              </div>
              <Col className="col-auto">{buttonHtml}</Col>
            </Row>
          </ListGroupItem>
        );
      });
    }

    if (selectedOtherApprovers.length > 0) {
      selectedOtherApproversList = [];
      selectedOtherApprovers.forEach((approver, index) => {
        selectedOtherApproversList.push(
          <ListGroupItem className="px-0" key={"seleccionado-" + index}>
            <Row className="align-items-center">
              <Col className="col-auto">
                <a
                  className="avatar rounded-circle"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img alt="..." src={require("assets/img/theme/user.svg")} />
                </a>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {approver.name}
                  </a>
                </h4>
                <small>{approver.position} </small>
              </div>
              <Col className="col-auto">
                <Button
                  color="danger"
                  size="sm"
                  type="button"
                  onClick={() => this.handleRemoveOtherApprover(approver)}
                >
                  X
                </Button>
              </Col>
            </Row>
          </ListGroupItem>
        );
      });
    }

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Modal isOpen={this.state.showApproversModal}>
          <ModalHeader>Aprobadores Adicionales</ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                {selectedOtherApproversList.length > 0 ? (
                  <ListGroup className="list my--3" flush>
                    {selectedOtherApproversList}
                  </ListGroup>
                ) : (
                  <p> Ningún aprobador adicional seleccionado. </p>
                )}
              </CardBody>
            </Card>{" "}
            <Card>
              <CardHeader className="py-0">
                <Form>
                  <FormGroup className="mb-0">
                    <InputGroup className="input-group-lg input-group-flush">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <span className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="user"
                        placeholder="Buscar..."
                        type="search"
                        defaultValue={userFilter}
                        onChange={(value) => this.handleUserFilters(value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
              </CardHeader>
              <CardBody>
                {otherApproversRender.length > 0 ? (
                  <ListGroup data-toggle="checklist" flush>
                    {otherApproversRender}
                  </ListGroup>
                ) : (
                  <p> Resultados no Encontrados </p>
                )}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => this.handleAddOtherApprovers()}
            >
              Aceptar
            </Button>
          </ModalFooter>
        </Modal>
        <SweetAlert
          show={this.state.showConfirmAlert}
          success
          showCancel
          showCloseButton
          confirmBtnText="Si"
          cancelBtnText="Ir Atras"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Solicitud Creada Exitosamente"
          onConfirm={() => this.goToRequest(createdRequest)}
          onCancel={() => this.props.history.goBack()}
        >
          Desea visualizar la solicitud?
        </SweetAlert>
        <SweetAlert
          show={this.state.showInputErrorAlert}
          error
          showCloseButton
          confirmBtnText="OK"
          confirmBtnBsStyle="danger"
          title="Mensaje de Error:"
          onConfirm={() => this.closeInputError()}
        >
          {this.state.errorInfo}
        </SweetAlert>
        <SweetAlert
          show={this.state.showDocumentErrorAlert}
          error
          showCloseButton
          confirmBtnText="OK"
          confirmBtnBsStyle="danger"
          title="Error:"
          onConfirm={() => this.closeDocumentError()}
        >
          {this.state.errorInfo}
        </SweetAlert>
        <SweetAlert
          show={this.state.showCreateRequestAlert}
          warning
          showCancel
          cancelBtnText="Cancelar"
          confirmBtnText="Si, Estoy Seguro!"
          confirmBtnBsStyle="danger"
          title="Esta Seguro?"
          onConfirm={() => this.handleOnCreateRequest()}
          onCancel={() => this.hideConfirmNotification()}
          focusCancelBtn
        >
          Favor verificar el flujo de aprobación según Power Reserve Antes de
          enviar la solicitud ya que una vez enviada, no podrá ser modificada.
        </SweetAlert>
        <SweetAlert
          show={this.state.showConfirmDeleteApprover}
          warning
          showCancel
          cancelBtnText="Cancelar"
          confirmBtnText="Si, Estoy Seguro!"
          confirmBtnBsStyle="danger"
          title="Esta Seguro?"
          onConfirm={() => this.handleConfirmRemove()}
          onCancel={() => this.hideDeleteNotification()}
          focusCancelBtn
        >
          Esta seguro que desea eliminar este aprobador?
        </SweetAlert>

        <AdminHeader
          name="Nueva Solicitud"
          parentName="Sistema de Aprobación Salarial"
        />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={creatingRequest}>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Paso 1: Tipo de Solicitud</h3>
                      </Col>
                      {requestType && !approversLoaded && (
                        <Col className="text-right" xs="6">
                          <Button
                            className="btn-round btn-icon"
                            color="warning"
                            onClick={(e) => this.handleClearRequestType()}
                            size="sm"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-times" />
                            </span>
                            <span className="btn-inner--text">Cambiar</span>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </CardHeader>
                  {requestType ? (
                    <CardBody>
                      <div className="my-4">
                        <span className="h6 surtitle text-muted">
                          Tipo Seleccionado:
                        </span>
                        <div className="h1">{requestType.name}</div>
                      </div>
                      <Row>
                        <div className="col">
                          <span className="h6 surtitle text-muted">
                            Código:
                          </span>
                          <span className="d-block h3">{requestType.type}</span>
                        </div>
                      </Row>
                      <Row>
                        <FormGroup className="row">
                          <Label
                            className="form-control-label"
                            htmlFor="example-text-input"
                            md="4"
                          >
                            <div>Fecha de Aplicación</div>
                          </Label>
                          <Col md="8">
                            <Input
                              defaultValue={date}
                              id="example-date-input"
                              type="date"
                              onChange={(e) =>
                                this.updateDateValue(e.target.value)
                              }
                              disabled={approversLoaded}
                            />
                          </Col>
                        </FormGroup>
                      </Row>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <Row>
                        {
                          optionsSales.map((item, key) => (
                            <Col xs="6" className="mb-3">
                              <Card key={key}
                                style={{ background: `linear-gradient(${item.color}, ${item.color2})`, mixHeight: "100px" }}
                                className="card-pricing  border-0 text-center mb-4  h-100 card-lift--hover "
                                onClick={() =>
                                  this.handleRequestTypeSelection(
                                    item.id,
                                    item.text
                                  )
                                }>
                                <CardHeader className="bg-transparent">
                                </CardHeader>
                                <CardBody className="px-lg-7">
                                  <Row
                                    className="d-flex justify-content-center"
                                  >
                                    <Col>
                                      <h3 className="text-white">{item.text}</h3>
                                    </Col>
                                  </Row>


                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                      </Row>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
            {approversError.value && (
              <div>
                <Row>
                  <Col>
                    {" "}
                    <ShowError errorMessage={approversError.error} />
                  </Col>
                </Row>
              </div>
            )}
            {requestType && (
              <div>
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="6">
                            <h3 className="mb-0">
                              Paso 2: Documento de Aprobación
                            </h3>
                          </Col>

                          {/* <Col className="text-right" xs="4">
                      {this.state.rows ? (
                        <Button
                          color="warning"
                          href="#"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          Ver Salarios
                        </Button>
                      ) : (
                        <div></div>
                      )}
                    </Col> */}
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <div
                          className="dropzone dropzone-single mb-3"
                          id="dropzone-single"
                        >
                          {this.state.rows &&
                            !this.state.approversError.error ? (
                            <Row>
                              <ToolkitProvider
                                data={this.state.rows}
                                keyField="key"
                                columns={this.state.cols}
                                search
                              >
                                {(props) => (
                                  <div className="py-4 table-responsive">
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1"
                                    >
                                      <label>
                                        Buscar:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder="Buscar"
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Row>
                          ) : (
                            <div className="fallback">
                              <div className="custom-file">
                                <input
                                  className="custom-file-input"
                                  id="projectCoverUploads"
                                  type="file"
                                  onChange={this.newFileHandler.bind(this)}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="projectCoverUploads"
                                >
                                  Seleccione un archivo
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
            <Spin size="large" spinning={loadingApprovers}>
              {approversLoaded && !this.state.approversError.error ? (
                <Row>
                  <Col lg="6">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs="8">
                            <h5 className="h3 mb-0">Orden de Aprobadores</h5>
                          </Col>
                          <Col className="text-right" xs="4">
                            <Button
                              color="success"
                              onClick={(e) => this.toggleApproversModal()}
                              size="sm"
                            >
                              Agregar Otros
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <SortableContainer
                          onSortEnd={this.onSortEnd}
                          useDragHandle
                          className="list my--3"
                          flush
                        >
                          {approversRender.map((value, index) => (
                            <SortableItem
                              key={`item-${index}`}
                              index={index}
                              value={value}
                            />
                          ))}
                        </SortableContainer>
                        {approversRender.length === 0 && (
                          <div>
                            <p>Ningún Aprobador Seleccionado</p>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs="8">
                            <h5 className="h3 mb-0">
                              Aplicación de Solicitud{" "}
                            </h5>
                          </Col>
                        </Row>
                      </CardHeader>

                      <CardBody>
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <p className="avatar rounded-circle">
                              <img
                                alt="..."
                                src={require("assets/img/theme/user.svg")}
                              />
                            </p>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <p>{payrollApprover.sign.name}</p>
                            </h4>
                            <small>
                              <strong> Posición: </strong>
                              {payrollApprover.sign.position}
                            </small>
                            <br></br>
                          </div>
                          <Col className="col-auto">
                            <Button
                              color="primary"
                              size="sm"
                              type="button"
                              disabled
                            >
                              Payroll
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Información Adicional</h3>
                          </Col>
                          <Col className="text-right" xs="4"></Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <div>
                            <Col>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlTextarea3"
                                >
                                  Notas:
                                </label>
                                <Input
                                  id="exampleFormControlTextarea3"
                                  resize="none"
                                  rows="3"
                                  type="textarea"
                                  value={notes}
                                  onChange={(e) =>
                                    this.setState({ notes: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </div>
                          <div>
                            <Col>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlTextarea3"
                                >
                                  Pais:
                                </label>
                                <Input
                                  id="status"
                                  type="select"
                                  value={country}
                                  onChange={(e) =>
                                    this.handleOnChangeCountry(e.target.value)
                                  }
                                  disabled={isColombia}
                                >
                                  {countries.map((value, key) => {
                                    if (value.code === "CO" && !isColombia) {
                                      return (
                                        <option
                                          value={value.code}
                                          key={"COUNTRY-" + value.code}
                                          disabled
                                        >
                                          {value.country}
                                        </option>
                                      );
                                    } else {
                                      return (
                                        <option
                                          value={value.code}
                                          key={"COUNTRY-" + value.code}
                                        >
                                          {value.country}
                                        </option>
                                      );
                                    }
                                  })}
                                </Input>
                              </FormGroup>
                            </Col>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h3 className="mb-0">Enviar Solicitud</h3>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <p className="mb-0">
                              Favor verificar el flujo de aprobación según Power
                              Reserve Antes de enviar la solicitud. El Flujo de
                              aprobación se realizara en el orden indicado.
                              <br></br>
                              <br></br>
                              Enviaremos correos a los aprobadores
                              seleccionados, si alguno de ellos rechaza la
                              solicitud esta pasara inmediatamente a estado{" "}
                              <code>RECHAZADA</code>. Si el 100% de los
                              aprobadores aprueba la solicitud pasara
                              automáticamente a <code>APROBADA</code>.
                            </p>
                            <p
                              className="mb-0"
                              style={{ color: "red", fontWeight: "bold" }}
                            >
                              <br></br>
                              Recuerde adjuntar la documentación requerida luego
                              de crear la solicitud.
                              <br></br>
                            </p>
                          </Col>
                        </Row>
                        <hr />
                        <Form className="needs-validation" noValidate>
                          <Button
                            color="primary"
                            type="button"
                            //onClick={this.handleOnCreateRequest}
                            onClick={() =>
                              this.setState({ showCreateRequestAlert: true })
                            }
                            disabled={loadingApprovers}
                          >
                            Enviar
                          </Button>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <div></div>
              )}
            </Spin>
          </Spin>
        </Container>
      </>
    );
  }
}

SalaryApprovalNewRequest.defaultProps = {
  approversData: [],
  otherApproversData: [],
};

const mapStateToProps = (state) => ({
  approversData: approversSelector(state),
  otherApproversData: otherApproversSelector(state),
});

export default withRouter(
  connect(mapStateToProps, { getApprovers, getOtherApprovers, createRequest })(
    SalaryApprovalNewRequest
  )
);

function ShowError({ errorMessage }) {
  return (
    <UncontrolledAlert color="danger">
      <span className="alert-icon">
        <i className="ni ni-like-2" />
      </span>
      <span className="alert-text ml-1">
        <strong>Error:</strong> {errorMessage}
      </span>
    </UncontrolledAlert>
  );
}
