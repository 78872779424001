/*!

=========================================================
*Inquires Main
=========================================================

*Componente para verificar el stock de un material en una 
planta y un tipo de storage dado.
=========================================================

* Coded by Diego Meza - Application Management GBM

*/

//Librería de React.
import React, { useState, useEffect, useContext } from "react";

// Reactstrap components.
import {
    CardHeader,
    CardBody,
    Button,
    Card,
    Row,
    Col,
} from "reactstrap";

//Componente para states globales.
import { UserContext } from '../SharedComponents/UserContext';

//Imports de actions de consultas al API.
import {
    stockInquiries
} from 'actions/wms';

//Disparador.
import { useDispatch } from 'react-redux';

//Componentes locales.
import ModalInfoInventoryAndInquiries from "../SharedComponents/ModalInfoInventoryAndInquiries.jsx";
import Search from "./Search.jsx";
import Result from "./Result.jsx";

//#region States globales

import { MdWarehouse, MdComputer } from "react-icons/md";
import { GrStorage } from "react-icons/gr";
import { FaMapMarkerAlt, FaBoxes } from "react-icons/fa";
import { TbBuildingWarehouse } from "react-icons/tb";
import { IoDocumentText } from "react-icons/io5";
import { CiCalendarDate } from "react-icons/ci";

//#endregion

const InquiriesMain = ({ handleOnBack }) => {

    //#region ejecutar fetch
    const dispatch = useDispatch();
    //#endregion

    //#region States globales
    const {
        isComputer,
        isMobile,
        options,
        notify,
        MySwal,
    } = useContext(UserContext);
    //#endregion

    //#region States locales
    //estado para verificar si se debe de enviar
    const [readyToSend, setReadyToSend] = useState(false);

    const [isResult, setisResult] = useState(false);

    const [resultData, setResultData] = useState([]);

    const [openModal, setOpenModal] = useState(false);

    const [modalInfo, setModalInfo] = useState({
        title: "Tipo de Almacén",
        subtitle: "Seleccione un tipo almacén",
        spinnerText: "Extrayendo tipo de Almacenes...",
        searchText: "Buscar tipo de almacén...",
        colunmName: "Tipo de Almacén",
        id: ""
    })
    //#endregion

    //Función de búsqueda.
    const handleSearchWarehouseType = (e, newInfo, id) => {

        setModalInfo(prev => {
            return {
                ...prev,
                id: id
            }
        })
        setOpenModal(true)

    }


    //Campos de formulario
    const [fields, setFields] = useState([
        {
            id: 'material',
            label: "Material",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: false,
            required: true,
            icon: MdComputer,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'plant',
            label: "Planta",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: MdWarehouse,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'warehouseType',
            label: "Storage Type",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: GrStorage,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "inputKey",
            function: handleSearchWarehouseType
        },

    ])

    const [resultFields, setResultFields] = useState([
        {
            id: 'LGPLA',
            label: "Ubicación",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: FaMapMarkerAlt,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'MATNR',
            label: "Material",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: MdComputer,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'MAKTX',
            label: "Descripción",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: IoDocumentText,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'LGORT',
            label: "Planta",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: MdWarehouse,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'LGTYP',
            label: "Storage Type",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: TbBuildingWarehouse,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'LGPLA',
            label: "Storage Bin",
            width: "12",
            type: "text",
            setPlant: true,
            options: "",
            disabled: true,
            required: true,
            icon: GrStorage,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'VERME',
            label: "Cantidad",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: FaBoxes,
            deleteEmptyOption: true,
            stage: [1, 2],
            elementType: "input",
        },
        {
            id: 'WDATU',
            label: "Fecha de recibido",
            width: "12",
            type: "input",
            options: "",
            disabled: true,
            required: true,
            icon: CiCalendarDate,
            deleteEmptyOption: true,
            elementType: "input",
            stage: [1, 2]
        },
    ])

    const [newInfo, setNewInfo] = useState({

        plant: options.plants[0].label,

    })

    const [localSpinner, setlocalSpinner] = useState(false)


    //Función para volver a los botones o cards.
    const handleLocalOnBack = () => {

        if (isResult) {
            setisResult(false)
        }
        else {

            handleOnBack()
        }

    }

    //Función para limpiar el formulario.
    const handleClearFormm = (e, newInfo) => {
        setNewInfo(prev => {
            const updatedNewInfo = {
                ...prev,
                material: "",
                warehouseType: ""
            };
            return updatedNewInfo;
        });
        setReadyToSend(prev => !prev);

    }

    //Cambia el estado newInfo según el campo.
    const handleOnChange = (idField, label) => {


        setNewInfo(prev => {
            const newPrev = { ...prev, [idField]: label };
            const allIdsValid = fields.every(field => {
                const value = newPrev[field.id];
                return value !== null && value !== undefined && value !== '';
            });

            // Update readyToSend state
            setReadyToSend(allIdsValid);

            return newPrev;
        })


    }

    //Efecto para determinar si está listo para enviar.
    useEffect(() => {
        const allIdsValid = fields.every(field => {
            const value = newInfo[field.id];
            return value !== null && value !== undefined && value !== '';
        });

        // Update readyToSend state
        setReadyToSend(allIdsValid);

    }, [newInfo])

    const handleConfirmNoValidated = (newInfo) => {
        let err = null;
        console.log(newInfo)
        if (newInfo.material === "" || newInfo.material === 0 || newInfo.material == undefined) {
            err = "Ingrese un material a consultar.";
        } else {
            if (newInfo.plant === null || newInfo.plant === 0 || newInfo.plant == undefined) {
                err = "Seleccione la planta.";
            } else {
                if (newInfo.warehouseType === "" || newInfo.warehouseType === null || newInfo.warehouseType === undefined) {
                    err = "Ingrese el tipo de almacén.";
                }
            }
        }

        if (err) {

            notify("warning", "Alerta", err)
        } else {
            handleConfirm(newInfo);
        }
    }

    const handleConfirm = (newInfo) => {
        setlocalSpinner(true)
        dispatch(stockInquiries(newInfo)).then((resp) => {
            setlocalSpinner(false);
            const { payload } = resp;
            if (resp.payload.isAxiosError) {
                console.log(payload)
                console.log(payload.status)
                setisResult(false)
                setResultData([])
                notify("danger", "Alerta", "El material ingresado no es correcto, favor validar.");
            }
            else {
                let response = payload.data.response;
                console.log(response)
                if (response === "OK") {
                    setisResult(true)
                    setResultData(payload.data.data)
                }
            }

        });
    }

    const modeButtonAddon = (field, type) => {
        if (field.matchNumbers) {
            if (newInfo[field.id] == newInfo[field.matchNumbers]) {
                return type == "color" ? "success" : type == "icon" ? "fas fa-check" : true
            }
            else if
                (newInfo[field.id] < newInfo[field.matchNumbers]) {
                return type == "color" ? "warning" : type == "icon" ? "fas fa-times" : true
            } else if
                (newInfo[field.id] > newInfo[field.matchNumbers]) {
                return type == "color" ? "danger" : type == "icon" ? "fas fa-times" : true

            } else {
                return type == "color" ? "default" : type == "icon" ? "fas fa-times" : true
            }
        } else {
            return type == "color" ? "success" : type == "icon" ? "fas fa-search" : false
        }
    }

    const renderSearchCard = () => (
        <Card className="bg-gradient">
            <CardHeader>
                <Row>
                    <Col xs="3">
                        <Button color="secondary" size="sm" outline onClick={() => handleOnBack()}>
                            <span className="btn-inner--icon mr-1">
                                <i className={"fas fa-arrow-left"} />
                            </span>
                            Inicio
                        </Button>
                    </Col>
                    <Col xs="7">
                        <div className="text-center">
                            <h5 className="h3 mb-0">Consulta de Stock</h5>
                        </div>
                    </Col>
                    <Col xs="2" className={isComputer?"ml--2":""}>
                        <Button
                            className="btn-icon-only rounded-circle"
                            color="danger"
                            outline
                            disabled={!readyToSend}
                            type="button"
                            onClick={(e) => handleClearFormm(e, newInfo)}
                        >
                            <span className="btn-inner--icon">
                                <i className="fas fa-trash" />
                            </span>
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Search
                    readyToSend={readyToSend}
                    fields={fields}
                    isMobile={isMobile}
                    newInfo={newInfo}
                    handleOnChange={handleOnChange}
                    modeButtonAddon={modeButtonAddon}
                    handleConfirmNoValidated={handleConfirmNoValidated}
                    options={options}
                    localSpinner={localSpinner}
                />
            </CardBody>
        </Card>
    );

    const renderResultCard = () => (
        <Card className="bg-gradient">
            <CardHeader>
                <Row className="text-center">
                    {
                        isMobile &&
                        <Col xs="3">
                            <Button color="secondary" size="sm" outline onClick={() => handleLocalOnBack()}>
                                <span className="btn-inner--icon mr-1">
                                    <i className={"fas fa-arrow-left"} />
                                </span>
                                Volver
                            </Button>
                        </Col>
                    }

                    <Col className="text-center" xs={isMobile ? "9" : "12"}>
                        <div className="text-center">
                            <h5 className="h3 mb-0">Resultado de la consulta</h5>
                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Result
                    isResult={isResult}
                    resultData={resultData}
                    resultFields={resultFields}
                    isMobile={isMobile}
                    options={options}
                />
            </CardBody>
        </Card>
    );

    return (
        <>
            <ModalInfoInventoryAndInquiries
                openModal={openModal}
                setOpenModal={setOpenModal}
                newInfo={newInfo}
                setNewInfo={setNewInfo}
                modalInfo={modalInfo}
                MySwal={MySwal}
                setReadyToSend={setReadyToSend}
            />
            {isMobile ? (
                <>

                    {!isResult ? renderSearchCard() : renderResultCard()}

                </>
            ) : (
                <>
                    <Row>
                        <Col xs="3">
                            {renderSearchCard()}
                        </Col>
                        <Col xs="9">
                            {renderResultCard()}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );

}
export default InquiriesMain;