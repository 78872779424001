/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la seccion de titulo Accesos a Sistemas en la solicitud
=========================================================

* Coded by Sergio Marin  

*/
import React, { useState, useEffect, useRef } from "react";
import { Card, CardTitle, Col, Row, FormGroup } from "reactstrap";
import MultiSelect from 'react-select';

const SystemAccesses = ({ optionsJson, setData, isVisible, data, disabledFieldsJson }) => {

    const titleStyle = { backgroundColor: '#1275bc', color: '#ffffff', fontFamily: 'sans-serif' };
    const h5Style = { fontSize: "1.25em", height: '1.0em', fontFamily: 'sans-serif' };

    const [accessTypes, setAccessTypes] = useState([]);
    const [mutiValues, setMutiValues] = useState([]);
    const [mutiSelected, setMutiSelected] = useState([]);
    const ref = useRef(data.jobPosition);

    //FUNCIONES 

    //Llenar opciones posición DATACENTER
    function selectDataCenterOptions() {
        const datacenterJobIds = ["36", "38"];// IDs de los trabajos relacionados con Datacenter

        if (datacenterJobIds.includes(data.jobPosition)) {
            const datacenterItems = optionsJson.systemAccess
                .filter(item => item.accessType === "Datacenter")
                .map(item => ({ label: item.value, value: item.id }));

            setMutiSelected({ Datacenter: datacenterItems });
        } else {
            setMutiSelected({ Datacenter: [] });
        }
    }

    //Inicializacion
    useEffect(() => {

        //OBTIENE TODO LOS "TITULOS"
        setAccessTypes([...new Set(optionsJson["systemAccess"].map(item => item.accessType))]);

        //PARSEO DE OPTIONSJSON PARA EL MULTISELECT
        const transformedObject = {};
        optionsJson["systemAccess"].forEach(item => {
            const { accessType, value, id } = item;
            if (!(accessType in transformedObject)) {
                transformedObject[accessType] = [];
            }
            transformedObject[accessType].push({ label: value, value: Number(id) });
        });
        setMutiValues(transformedObject);

        //PARSEO DE DATA PARA EL MULTIVALUE
        if (data.systemsAccesses) {
            const accessTypeMap = optionsJson["systemAccess"].reduce((acc, item) => {
                const { id, value, accessType } = item;
                if (!acc[accessType]) {
                    acc[accessType] = [];
                }
                acc[accessType].push({ label: value, value: id });
                return acc;
            }, {});

            const options = data.systemsAccesses.reduce((acc, id) => {
                for (const accessType in accessTypeMap) {
                    const match = accessTypeMap[accessType].find(item => item.value.toString() === id);
                    if (match) {
                        if (!acc[accessType]) {
                            acc[accessType] = [];
                        }
                        acc[accessType].push(match);
                        break;
                    }
                }
                return acc;
            }, {});
            setMutiSelected(options);
        }

        //Llenar opciones de datacenter automaticamente
        selectDataCenterOptions();

    }, []);

    //funcion del multiselect
    const handleMultiChange = (selectedOptions, accessType) => {
        setMutiSelected((prevState) => ({ ...prevState, [accessType]: selectedOptions }));
    };

    //Cargar los systemaccesses a data
    useEffect(() => {
        function getAllValues(obj) {
            const values = [];

            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    // Si es un array, iteramos sobre los elementos
                    for (const item of obj[key]) {
                        if (item.hasOwnProperty("value")) {
                            values.push(item.value);
                        }
                    }
                } else if (typeof obj[key] === "object") {
                    // Si es un objeto, llamamos a la función recursivamente
                    const subValues = getAllValues(obj[key]);
                    values.push(...subValues);
                }
            }

            return values;
        }

        // Paso 3: Extraer todos los valores dinámicamente
        const allValues = getAllValues(mutiSelected);
        const integersOnlyArray = allValues.map(item => parseInt(item));

        //guardar en data
        setData((prevState) => ({ ...prevState, systemsAccesses: integersOnlyArray }));
    }, [mutiSelected]);

    //Llenar DATACENTER si se selecciona posición
    useEffect(() => {
        if (ref.current !== data.jobPosition) {
            selectDataCenterOptions();
        }
    }, [data.jobPosition]);


    //RETURN
    return (
        <>
            {isVisible && (
                <Card style={{ padding: '0px 0px 10px' }}>

                    <CardTitle tag="h5" style={{ ...titleStyle, height: '1.5em', fontSize: '1.25em', fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Accesos a Sistemas
                    </CardTitle>

                    <Row style={{ padding: '0px 25px 0px' }}>
                        {accessTypes && accessTypes.map((accessType) => {
                            const sap = !(accessType == "SAP" && data.jobType == "1");
                            return (sap && (
                                <Col xs="3" style={{ padding: '10px 20px 0px', fontFamily: 'sans-serif' }} key={accessType}>
                                    <h5 style={h5Style}>{accessType}</h5>
                                    <FormGroup  >
                                        <MultiSelect
                                            isDisabled={(disabledFieldsJson && disabledFieldsJson.includes("SystemAccesses"))}
                                            closeMenuOnSelect={false}
                                            id={"select_" + accessType}
                                            isMulti
                                            options={mutiValues[accessType]}
                                            value={mutiSelected[accessType]}
                                            onChange={(selectedOptions) => handleMultiChange(selectedOptions, accessType)}
                                        />
                                    </FormGroup>
                                </Col>
                            )
                            );
                        })}
                    </Row>
                </Card>
            )}
        </>
    );
}

export default SystemAccesses;
