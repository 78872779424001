import { handleActions } from "redux-actions";
import * as constants from "constants/index.jsx";

export const requerimentsPlanners = handleActions(
  {
    [constants.GET_REQUESTS_PENDING_ASSIGNATION]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    [constants.SIGN_OUT]: () => {
      return {};
    },
  },
  {}
);

export const allRequerimentsPlanners = handleActions(
  {
    [constants.GET_ALL_REQUESTS_PENDING_ASSIGNMENT]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
    // [constants.CREATE_USER_ASSIGNMENT_BY_PLANNER]: (state, action) => {
    //   const { status, data } = action.payload;
    //   if (status === 200) {
    //     const { id } = data.payload;
    //     const { request, message } = state;
    //     return {
    //       message,
    //       request: request.filter((row) => row.id !== parseInt(id)),
    //     };
    //   }
    //   return state;
    // },
  },
  {}
);

export const equipmentsByRequestPlanners = handleActions(
  {
    [constants.GET_EQUIPMENTS_ASSIGNMENT_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
  },
  {}
);

export const userAssignmentByRequestPlanners = handleActions(
  {
    [constants.GET_USER_ASSIGNMENT_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
  },
  {}
);

export const allPartsEquipmentsByRequest = handleActions(
  {
    [constants.GET_ALL_PARTS_EQUIPMENTS_BY_REQUEST]: (state, action) => {
      const { status, data } = action.payload;
      if (status === 200) {
        return data.payload;
      }
      return {};
    },
  },
  {}
);
