import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findUserWithAccess = createAction(
  constants.GET_USERS_WITH_ACCESS_EXTRA_HOURS,
  method.get(urls.EXTRAHOURS.getUsersWithAccess)
);

export const findExtras = createAction(constants.GET_EXTRAS, (data) =>
  method.post(urls.EXTRAHOURS.getExtras, data)()
);

export const findYearsExtras = createAction(
  constants.GET_YEARS_EXTRAS,
  method.get(urls.EXTRAHOURS.getYearsExtras)
);

export const userRolesExtra = createAction(
  constants.GET_USER_ROLES,
  method.get(urls.EXTRAHOURS.getUserRolesExtra)
);

export const updateExtrasSAP = createAction(
  constants.UPDATE_EXTRAS_SAP,
  (data) => method.put(urls.EXTRAHOURS.getUpdateExtrasSAP, data)()
);

export const updateExtraInfo = createAction(constants.UPDATE_EXTRAS, (data) =>
  method.put(urls.EXTRAHOURS.updateExtraInfo, data)()
);

export const findDateExtras = createAction(constants.GET_DATE_EXTRAS, (data) =>
  method.post(urls.EXTRAHOURS.getDateExtras, data)()
);

export const findExtrasToSend = createAction(
  constants.GET_EXTRAS_TO_SEND,
  (data) => method.post(urls.EXTRAHOURS.getExtrasToSend, data)()
);

export const allYearExtraSumHoursWidget = createAction(
  constants.GET_ALL_YEAR_EXTRAS_SUM_HOURS_WIDGET,
  (data) => method.post(urls.EXTRAHOURS.getSumAllYearHoursWidget, data)()
);

export const allYearExtraSumHoursGraph = createAction(
  constants.GET_ALL_YEAR_EXTRAS_SUM_HOURS_GRAPH,
  (data) => method.post(urls.EXTRAHOURS.getSumAllYearHoursGraph, data)()
);

export const countryExtrasSumHoursWidget = createAction(
  constants.GET_COUNTRY_YEAR_EXTRAS_SUM_HOURS_WIDGET,
  (data) => method.post(urls.EXTRAHOURS.getSumCountryYearHoursWidget, data)()
);

export const countryExtrasSumHoursGraph = createAction(
  constants.GET_COUNTRY_YEAR_EXTRAS_SUM_HOURS_GRAPH,
  (data) => method.post(urls.EXTRAHOURS.getSumCountryYearHoursGraph, data)()
);

export const findUserByYear = createAction(constants.GET_USER_YEAR, (data) =>
  method.post(urls.EXTRAHOURS.getUsersByYear, data)()
);

export const findHoursByUser = createAction(constants.GET_HOUR_USER, (data) =>
  method.post(urls.EXTRAHOURS.getHoursByUser, data)()
);

export const findMonthWidget = createAction(
  constants.GET_MONTH_WIDGET,
  (data) => method.post(urls.EXTRAHOURS.getMonthWidgets, data)()
);

export const findMonthWidgetCountry = createAction(
  constants.GET_MONTH_WIDGET_COUNTRY,
  (data) => method.post(urls.EXTRAHOURS.getMonthWidgetsCountry, data)()
);

export const deactivateUserRol = createAction(
  constants.DEACTIVATE_USER_ROL_EXTRA_HOURS,
  (id) => method.put(`${urls.EXTRAHOURS.deactivateUserRol}/${id}`)()
);

export const createUserRol = createAction(
  constants.CREATE_USER_ROL_EXTRAS,
  (data) => method.post(urls.EXTRAHOURS.createUserRol, data)()
);

export const findExtraHourByCeo = createAction(
  constants.GET_EXTRA_HOURS_BY_CEO,
  (data) => method.post(urls.EXTRAHOURS.getExtraHourByCeo, data)()
);

export const findUsersWithAccess = createAction(
  constants.GET_USERS_WITH_ACCESS,
  method.get(urls.EXTRAHOURS.getHCWithAccess)
);

export const createUserHCAccess = createAction(
  constants.CREATE_USER_WITH_ACCESS,
  (body) => method.post(urls.EXTRAHOURS.createUserHCAccess, body)()
);

export const deactivatedHCWithAccess = createAction(
  constants.DEACTIVATED_USER_WITH_ACCESS,
  (id) => method.put(`${urls.EXTRAHOURS.deactivatedHCWithAccess}/${id}`)()
);

export const updateStatus = createAction(constants.EH_UPDATE_STATUS, (body) =>
  method.post(urls.EXTRAHOURS.updateStatus, body)()
);
export const getReasonForChangeSap = createAction(
  constants.EH_GET_REASON_FOR_CHANGE_SAP,
  (body) => method.post(urls.EXTRAHOURS.getReasonForChange, body)()
);

export const createPreapproverRole = createAction(
  constants.CREATE_PREAPPROVER_ACCESS,
  (body) => method.post(urls.EXTRAHOURS.createPreapproverRole, body)()
);

export const deletePreapproverRole = createAction(
  constants.DELETE_PREAPPROVER_ACCESS,
  (id) => method.put(`${urls.EXTRAHOURS.deletePreapproverRole}/${id}`)()
);
