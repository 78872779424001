import {
  makeDaysDonation,
  makeMonetaryDonation,
  userHideEvent,
} from "actions/events.jsx";
import { hideMedicalEvent } from "actions/medicalRecords.jsx";
import { findAllNotifications } from "actions/notifications.jsx";
// antd components
import { Spin } from "antd";
// core components Shared
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import CardEmpty from "components/Shared/Notifications/CardEmpty.jsx";
import CardEventModal from "components/Shared/Notifications/CardEventModal.jsx";
import CardReportHours from "components/Shared/Notifications/CardReportHours.jsx";
import CardNotification from "components/Shared/Notifications/CardNotifications.jsx";
import CovidEvent from "components/Shared/Notifications/CovidEvent.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import { getUserProtected } from "selectors/adminLayout";
import { getCovidEvent, getNotifications } from "selectors/notifications.jsx";
import { desencrypt } from "helpers/desencrypt.jsx";

import { LoadingOutlined } from '@ant-design/icons';


const convertUnicode = (input) => {
  return input.replace(/\\u(\w{4,4})/g, function (a, b) {
    var charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });
};

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      alert: null,
      loading: false,
      eventModal: {
        visible: false,
        title: "",
        type: "",
        paragraph: "",
      },
      //State para manejar modal de Reporte de Horas
      // reportHoursModal: {
      //   visible: false,
      //   title: "",
      //   type: "",
      //   paragraph: "",
      // },
      notificationEvent: false,
    };
  }

  componentDidMount() {
    this.setState((state) => ({
      loading: !state.loading,
    }));
    this.props.findAllNotifications().then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("danger", "Falló", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
        this.setState((state) => ({
          loading: !state.loading,
        }));
      } else {
        const {
          data: {
            payload: { medical },
          },
        } = res.payload;
        const decrypt = desencrypt(medical);
        const medicalRecordDecrypt = JSON.parse(decrypt);
        if (!medicalRecordDecrypt.record && !medicalRecordDecrypt.hidden) {
          this.setState({
            eventModal: {
              visible: true,
              title: "Expediente Médico Digital",
              type: "medicalRecord",
              paragraph:
                "Ya se encuentra disponible el expediente médico digital, en el cual podrás agregar tu información personal médica",
            },
          });
        }

        //Cuando se monta el componente muestro el modal de reportar horas
        // this.setState({
        //   reportHoursModal: {
        //     visible: true,
        //     title: "Reporte de horas - Smart and Simple",
        //     paragraph:
        //       "Ahora puedes reportar tus horas en Smart and Simple, clickeando el ícono que se señala en la imagen",
        //   },
        // });

        this.setState((state) => ({
          loading: !state.loading,
          notificationEvent: true,
        }));
      }
    });
  }

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  _onToggleModal = (event) => {
    this.setState((state) => ({
      [event]: !state[event],
    }));
  };

  _handleOnSelectNotification = (row) => {
    const { type } = row;
    if (type === "vacant-position")
      this.props.history.push(`/admin/new-position/requests/work-flow`);
    else if (type === "extra-hours")
      this.props.history.push(`/admin/extra-hours/information`);
    else if (type === "salary-approvals")
      this.props.history.push(
        `/admin/notifications/salary/approvals/${row.RequestID}`
      );
    else if (type === "inventory-price")
      this.props.history.push(`/admin/inventories-management/quotes`);
    else if (type === "financial-flows")
      this.props.history.push(`/admin/finance/request/update/${row.RequestID}`);
    else if (type === "exit-interview")
      this.props.history.push(`/admin/notifications/exit-interview-completion`);
    else if (type === "exit-interview-hcm")
      this.props.history.push(`/admin/exit-interview/digital-form`);
    else if (type === "finance-flows-notificactions")
      this.props.history.push(`/admin/finance/notifications`);
    else if (type === "planners-flow")
      this.props.history.push(`/admin/planners/assignment-manager`);
    else if (type === "extra-hours-flow")
      this.props.history.push(`/admin/notifications/extra-hours-flow`);
    else this.props.history.push(`${this.props.match.url}/${type}`);
  };

  _handleOnCancelDonation = (action) => {
    if (action) {
      const {
        event: { id },
      } = this.props;
      this.setState((state) => ({
        loading: !state.loading,
      }));
      this.props.userHideEvent(id).then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
          }));
        } else {
          this.setState((state) => ({
            loading: !state.loading,
            notificationEvent: !state.notificationEvent,
          }));
        }
      });
    } else {
      this._onToggleModal("notificationEvent");
    }
  };

  _handleOnChangePage = (event) => {
    this.setState((state) => ({
      current: event === "back" ? state.current - 1 : state.current + 1,
    }));
  };

  _handleOnMonetaryDonate = (amount, months, message, page) => {
    const {
      event: { id },
    } = this.props;
    this.setState((state) => ({
      loading: !state.loading,
      alert: null,
    }));
    this.props
      .makeMonetaryDonation(id, {
        amount,
        months,
        message,
        platform: "SS",
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
          }));
        } else {
          // const { data: { payload } } = res.payload;
          // this.notify("success", "Éxito", payload.message);
          this.setState((state) => ({
            loading: !state.loading,
            current: page + 1,
          }));
        }
      });
  };

  _handleOnVacationsDonate = (amount, message, page) => {
    const {
      event: { id },
    } = this.props;
    this.setState((state) => ({
      loading: !state.loading,
      alert: null,
    }));
    this.props
      .makeDaysDonation(id, {
        amount,
        message,
        platform: "SS",
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
          this.setState((state) => ({
            loading: !state.loading,
          }));
        } else {
          // const { data: { payload } } = res.payload;
          // this.notify("success", "Éxito", payload.message);
          this.setState((state) => ({
            loading: !state.loading,
            current: page + 1,
          }));
        }
      });
  };

  _handleOnSubmitDonations = (amount, days, months, message, page, type) => {
    const { event } = this.props;
    if (type === 1) {
      if (amount > 0 && months > 0) {
        this.setState({
          alert: (
            <ReactBSAlert
              custom
              showCancel
              style={{ display: "block", marginTop: "-100px" }}
              title="¿Está seguro de realizar la donación?"
              customIcon={
                <div
                  className="swal2-icon swal2-question swal2-animate-question-icon"
                  style={{ display: "flex" }}
                >
                  <span className="swal2-icon-text">?</span>
                </div>
              }
              onConfirm={() =>
                this._handleOnMonetaryDonate(amount, months, message, page)
              }
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Sí, continuar"
              cancelBtnText="No, volver"
              btnSize="md"
            >
              {months === 1
                ? `En el proximo mes`
                : `En cada uno de los proximos ${months} meses`}
              , se te rebajara el monto de{" "}
              {Object.keys(event.currency).length
                ? convertUnicode(event.currency.unicode_icon)
                : null}
              {amount}
            </ReactBSAlert>
          ),
        });
      } else {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Atención"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Entendido"
              btnSize="md"
            >
              {amount === 0 && months === 0
                ? "Los valores del monto a donar y la cantidad de meses no son válidos"
                : `El ${
                    amount === 0 ? "monto a donar" : "número de meses"
                  } debe ser mayor a cero`}
            </ReactBSAlert>
          ),
        });
      }
    } else if (type === 2) {
      if (days > 0) {
        this.setState({
          alert: (
            <ReactBSAlert
              custom
              showCancel
              style={{ display: "block", marginTop: "-100px" }}
              title="¿Está seguro de realizar la donación?"
              customIcon={
                <div
                  className="swal2-icon swal2-question swal2-animate-question-icon"
                  style={{ display: "flex" }}
                >
                  <span className="swal2-icon-text">?</span>
                </div>
              }
              onConfirm={() =>
                this._handleOnVacationsDonate(days, message, page)
              }
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Sí, continuar"
              cancelBtnText="No, volver"
              btnSize="md"
            >
              Se te {days > 1 ? `rebajaran` : `rebajara`} {days} días de tus
              vacaciones
            </ReactBSAlert>
          ),
        });
      } else {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Atención"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Entendido"
              btnSize="md"
            >
              {amount === 0 && months === 0
                ? "Los valores del monto a donar y la cantidad de meses no son válidos"
                : `El ${
                    amount === 0 ? "monto a donar" : "número de meses"
                  } debe ser mayor a cero`}
            </ReactBSAlert>
          ),
        });
      }
    }
  };

  //Funcion cerrar Modal reportar horas
  // handleHideReportHoursModal = () => {
  //   this.setState({
  //     reportHoursModal: {
  //       visible: false,
  //       title: "",
  //       type: "",
  //       paragraph: "",
  //     },
  //   });
  // };

  handleOnToggleEventModal = () => {
    this.setState({
      eventModal: {
        visible: false,
        title: "",
        type: "",
        paragraph: "",
      },
    });
  };

  handleOnViewEvent = (type) => {
    if (type === "medicalRecord") {
      this.props.history.push(`/admin/mis/profile`);
      this.handleOnToggleEventModal();
    }
  };

  onHiddenViewEvent = () => {
    this.hideAlert();
    this.setState({
      loading: true,
      message: "Oculando la notificación del evento",
    });
    const {
      user: { IDCOLABC },
    } = this.props;
    this.props.hideMedicalEvent(IDCOLABC).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.handleOnToggleEventModal();
      }
      this.setState({
        loading: false,
        message: null,
      });
    });
  };

  handleOnHiddenViewEvent = (type) => {
    if (type === "medicalRecord") {
      this.setState({
        alert: (
          <ReactBSAlert
            custom
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Está seguro de ocultar la notificación?"
            customIcon={
              <div
                className="swal2-icon swal2-question swal2-animate-question-icon"
                style={{ display: "flex" }}
              >
                <span className="swal2-icon-text">?</span>
              </div>
            }
            onConfirm={() => this.onHiddenViewEvent()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Sí, ocultar"
            cancelBtnText="No, cancelar"
            btnSize="md"
          >
            Si la ocultas, ya no podras volverla a visualizar
          </ReactBSAlert>
        ),
      });
    }
  };

  render() {
    const { name, parentName, notifications, event } = this.props;

    const {
      alert,
      loading,
      current,
      eventModal,
      notificationEvent,
      // reportHoursModal, // Variable para modal reportar horas
    } = this.state;

    return (
      <>
        {alert}
        {notificationEvent && Object.keys(event).length ? (
          <CovidEvent
            event={event}
            open={notificationEvent}
            loading={loading}
            page={current}
            onChangePage={this._handleOnChangePage}
            toggleModal={this._onToggleModal}
            onCancel={this._handleOnCancelDonation}
            onSubmit={this._handleOnSubmitDonations}
          />
        ) : null}
        <CardEventModal
          event={eventModal}
          loading={loading}
          toggleModal={this.handleOnToggleEventModal}
          onEvent={this.handleOnViewEvent}
          onHidden={this.handleOnHiddenViewEvent}
        />
        {/* {this.state.reportHoursModal.visible && (
          <CardReportHours
            event={reportHoursModal}
            toggleModal={this.handleHideReportHoursModal}
          />
        )} */}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
        {/* <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '' }} spin />} /> */}

          <Spin  size="large" spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 45, color: '#32325d' }} spin />} >
            {notifications.length ? (
              <Row className="justify-content-center">
                <Col sm="12" md="12">
                  <CardNotification
                    title="Mis notificaciones"
                    notifications={notifications}
                    onSelectNotification={this._handleOnSelectNotification}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center">
                <Col sm="12" md="8">
                  <CardEmpty
                    title="No hay notificaciones pendientes."
                    subtitle="Al día de hoy no tienes notificaciones pendientes, en el momento que exista una nueva, aparecerán en esta ventana."
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

Notifications.defaultProps = {
  name: "Notificaciones",
  parentName: "Smart & Simple",
  notifications: [],
  event: {},
  user: {},
};

Notifications.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  notifications: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
  event: getCovidEvent(state),
  user: getUserProtected(),
});

export default withRouter(
  connect(mapStateToProps, {
    hideMedicalEvent,
    findAllNotifications,
    makeMonetaryDonation,
    makeDaysDonation,
    userHideEvent,
  })(Notifications)
);
