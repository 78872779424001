/*!

=========================================================
*MasterData - RequestDetailModal
=========================================================

* Este componente es un modal que se puede utilizar en mis 
*gestiones o aprobaciones, despliega la información de la gestión
*en masivo o lineal, junto a todos los documents ligados a la gestión
=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/


//Librerías de react.
import React, { useState } from 'react';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

//Urls
import urls from "api/urls.jsx";

// imports de actions
import {
    getLinealAndMasiveData,
    approvalRejectRequest,
    getGeneralInfo
} from 'actions/masterData';

import {
    getUsernameLogged
} from 'selectors/adminLayout';

//Import de Spinner
import { Spin } from "antd";

// react plugin for creating notifications over the dashboard
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

//Formato para números de teléfono
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { encrypter } from 'helpers/desencrypt.jsx';

//Componentes Reactstrap
import {
    ModalFooter,
    CardHeader,
    Container,
    FormGroup,
    ModalBody,
    CardBody,
    Button,
    Input,
    Modal,
    Table,
    Card,
    Row,
    Col,
} from "reactstrap";

//Extraer los campos para los formularios individuales
import formFields from "./GeneralStates";

//Componente para subir un archivo al server
import { UploadFile } from './UploadFile';

export const RequestDetailModal = (
    {
        setReloadTableRequests,

        setModalDetail,
        modalDetail,
        options,

        //States de formularios individuales
        stateModalsIndvForms,
        setStateModalsIndvForms
    }) => {

    //Establecer el usuario logueado.
    const user = getUsernameLogged();

    // Establecer el disparador para el API
    const dispatch = useDispatch();

    //#region Desestructuración de variables

    //Define si la vista del modal es en requests o approvals
    const view = modalDetail.view;

    //Contiene información de la solicitud, como tipo lineal, comentarios, id, etc.
    const reqGeneralInfo = modalDetail.row;

    let formNameFormat = reqGeneralInfo?.Formulario.toLowerCase().replace(/ /g, "").normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace("de", "");

    let creacionm = formFields[formNameFormat]?.creacionm;

    let motherTable = formFields[formNameFormat]?.motherTable;
    //#endregion

    //#region Notificaciones y spinner
    const [spinner, setSpinner] = useState({ tableSpinner: false })

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        timer: 5000,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    //Método para hacer el llamado a notificación Toast de una manera más corta.
    const showToast = (message, type) => {
        Toast.fire({
            title: "Atención",
            html: message,
            type: type,
        });
    };

    //#endregion 

    //#region Variables del modal y handle para abrir o cerrar el modal
    //State del modal
    const [stateDetail] = useState({
        unmountOnClose: true,
        readyToSend: false,
        idFileRequest: "",
        fileList: [],
    });

    //Handle para cerrar el modal
    const handleCloseModal = () => {
        //setReloadTableRequests(true);

        setModalDetail({
            showDetailModal: false,
            view: "",
        })

        setItemsLinealToDelete(() => ([]))

        //Se restablecen los filest
        setFilesListCorrections({ fileList: [] })
        setFilesListDocumentation({ fileList: [] })

        setStateModalsIndvForms([])



    }
    //#endregion 

    //#region States para desplegar campos del form y state newInfo
    //State para guardar la información de los campos del componente
    const [newInfo, setNewInfo] = useState({ info: {}, updateInfo: false })


    //Campos que se despliegan en información general, donde cada json tiene un campo clave llamado dependsTypeForm,
    //esto para indicarle que en caso que sea true aparezca sólo cuando el nombre del formulario coindida con el json de nameTypeForm,
    //y el json de othersFieldsId, indica que si no encuentra datos en el id, recorra cada uno de los othersFieldsId y verifique donde encuentre datos. 
    const [generalInfoFields, setGeneralInfoFields] = useState([
        {
            colWidth: "6",
            label: "Tipo de gestión*",
            id: 'typeOfManagement',
            type: "select",
            dependsTypeForm: false,
            nameTypeForm: [],
            disabled: false,
        },
        {
            colWidth: "6",
            label: "País de la solicitud*",
            id: "sendingCountry",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Clientes', 'Contactos', 'Equipos', 'Ibase', 'Garantías'],
            othersFieldsId: ['countryClient', 'countryContact', 'countryEquipment', 'countryIbase', 'countryWarranties'], //Diferentes nombres de id ya que los nombres de las columnas en el left join tiene que ser diferente.
            disabled: false,
        },

        {
            colWidth: "12",
            label: "Grupo de Artículo*",
            id: "materialGroup",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Materiales', 'Materiales de Servicio', 'Servicios'],
            othersFieldsId: ['materialGroupMaterials', 'materialGroupServiceMaterials', 'materialGroupServices'],
            disabled: false,
        },

        {
            colWidth: "12",
            label: "Grupo de Artículo*",
            id: "materialGroupSpartParts",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Repuestos'],
            othersFieldsId: ['materialGroupSpartParts'],
            disabled: false,
        },

        {
            colWidth: "6",
            label: "Baw*", //MATERIALES
            id: "baw",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Materiales'],
            othersFieldsId: ['baw'],
            disabled: false,
        },

        {
            colWidth: "6",
            label: "Case Number BAW", //MATERIALES
            id: "bawManagement",
            type: "text",
            dependsTypeForm: true,
            nameTypeForm: ['Materiales'],
            othersFieldsId: ['bawManagement'],
            disabled: false,
        },

        {
            colWidth: "6",
            label: "Grupo de Cliente*", //CLIENT
            id: "valueTeam",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Clientes'],
            othersFieldsId: ['valueTeam'],
            disabled: false,
        },

        {
            colWidth: "6",
            label: "Canal*", //CLIENT
            id: "channel",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Clientes'],
            othersFieldsId: ['channel'],
            disabled: false,
        },

        {
            colWidth: "6",
            label: "Sujeto a IVA*", //CLIENT
            id: "subjectVat",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Clientes'],
            othersFieldsId: ['subjectVat'],
            disabled: false,
        },

        {
            colWidth: "6",
            label: "Sociedad del Proveedor*", //PROVEEDOR
            id: "companyCode",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Proveedores'],
            othersFieldsId: ['companyCode'],
            disabled: false,
        },

        {
            colWidth: "6",
            label: "Vendor group*", //PROVEEDOR
            id: "vendorGroup",
            type: "select",
            dependsTypeForm: true,
            nameTypeForm: ['Proveedores'],
            othersFieldsId: ['vendorGroup'],
            disabled: false,
        },

        {
            colWidth: "12",
            label: "Comentarios de gestión",
            id: 'comment',
            type: "textarea",
            dependsTypeForm: false,
            nameTypeForm: [],
            disabled: true,
        },
        {
            colWidth: "12",
            label: "Comentarios de Aprobador",
            id: "commentApproval",
            type: "textarea",
            dependsTypeForm: false,
            nameTypeForm: [],
            disabled: true,
        },
        {
            colWidth: "12",
            label: "Respuesta Databot",
            id: "Respuesta",
            type: "textarea",
            dependsTypeForm: false,
            nameTypeForm: [],
            disabled: true,
        },


    ]);

    //Al arrancar el modal, se recorre el json de generalInfoFields, y cada item lo almacena en newInfo con su respectivo valor, esto
    //ayuda a si hay una modificación modifique newInfo y haga el update
    useEffect(() => {

        if (reqGeneralInfo?.Formulario !== undefined) {
            //Se limpia el state
            setNewInfo({})

            generalInfoFields.map(field => {

                if (


                    field.dependsTypeForm === false ||
                    //Validación para verificar si el nombre del formulario (ej: Clientes), esta dentro del campo nameTypeForm del json del input, de lo contario no se muestra
                    (field.dependsTypeForm === true && field.nameTypeForm.includes(reqGeneralInfo?.Formulario))) {

                    //Asignar el valor dependiendo del id, consulta los datos de reqGeneralInfo 
                    const aux = asignValue(field.id, field.othersFieldsId);
                    //console.log(aux)
                    //debugger;
                    //Diferente a tipo select
                    if (field.type !== "select") {

                        setNewInfo((prevState) => ({
                            ...prevState,
                            info: { ...prevState.info, [field.id]: aux.value },
                            updateInfo: false

                        }))


                    } else { //Tipo select, y el campo lo guarda como label: value

                        setNewInfo((prevState) => ({
                            ...prevState,
                            info: {
                                ...prevState.info, [field.id]: {
                                    "label": aux.value,
                                    "value": reqGeneralInfo[aux.id + "Id"]
                                }
                            },
                            updateInfo: false

                        }))
                    }
                }
            }
            )
        }
    }, [modalDetail])


    //#endregion 

    //#region States y lógica para subir archivos al server
    const [filesListCorrections, setFilesListCorrections] = useState({ fileList: [] })
    const [filesListDocumentation, setFilesListDocumentation] = useState({ fileList: [] })

    //state "bandera" para determinar si aún se está cargando un documento y si se le da enviar gestión indique que debe esperar.
    const [stillChargingDocuments, setStillChargingDocuments] = useState({ chargingDocumentationDocs: false, chargingCorrectionsDocs: false })

    //Documentos masivos, de aprobación, o masivos finales.
    const [documentsNames, setDocumentsNames] = useState([]);
    //#endregion 

    //#region Extracción de filas para la tabla en el API

    //Extrae las filas de tipo lineal según sea el caso.
    const [linealRows, setLinealRows] = useState([]);

    //Extrae la información de la base de datos de la gestión apenas arranque el modal
    useEffect(() => {
        if (reqGeneralInfo != undefined) {
            getLinealMasiveDataM(reqGeneralInfo.Formulario, reqGeneralInfo.Gestion, reqGeneralInfo.typeOfManagementId)

        }
    }, [reqGeneralInfo])

    //State bandera para verificar si se debe extraer la información general.
    const [generalInfoExtracted, setGeneralInfoExtracted] = useState(false);

    //Spinner de general info.
    const [generalInfoSpinner, setGeneralInfoSpinner] = useState(false)

    //Efecto para que extraiga la información de datos generales solo para la vista de todas las gestiones.
    useEffect(() => {
        if (Object.keys(newInfo?.info).length > 0 && view == "requests" && modalDetail.showDetailModal && !generalInfoExtracted) {
            getGeneralInfoM(reqGeneralInfo.Formulario, reqGeneralInfo.Gestion, reqGeneralInfo.typeOfManagementId, newInfo)
            setGeneralInfoExtracted(true);
        }
        console.log("MI NUEVO INFO", newInfo.info);
    }, [newInfo])



    useEffect(() => {
        console.log("reqGeneralInfo", reqGeneralInfo)
    }, [reqGeneralInfo])



    //Extrae las filas de datos tipo o los documentos masivos de cada gestión según sea el caso.
    const getLinealMasiveDataM = async (dataType, Gestion, typeOfManagementId) => {

        //Quitar tíldes.
        const tempDataType = dataType.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

        setSpinner((prevState) => ({
            ...prevState,
            tableSpinner: true
        }))

        dispatch(getLinealAndMasiveData({ dataType: encrypter(tempDataType), requestId: encrypter(Gestion), typeOfManagementId: encrypter(typeOfManagementId) })).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {

                setLinealRows(payload.data.payload.rows)
                setDocumentsNames(payload.data.payload.documentsNames)

            } else {
                console.log(payload)
            }
            setSpinner((prevState) => ({
                ...prevState,
                tableSpinner: false
            }))
        })


    }

    //Extrae las filas de de los datos generales cuando está en la vista de todas las gestiones.
    const getGeneralInfoM = async (dataType, Gestion, typeOfManagementId, newInfo) => {
        setGeneralInfoSpinner(true);

        //Quitar tíldes.
        const tempDataType = dataType.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

        dispatch(getGeneralInfo({ dataType: encrypter(tempDataType), requestId: encrypter(Gestion), typeOfManagementId: encrypter(typeOfManagementId), formId: motherTable })).then((resp) => {

            const { payload } = resp;
            if (payload.status === 200) {
                console.log("RESPONSE", payload.data.payload.generalInfo)
                const generalInfo = payload.data.payload.generalInfo;

                let newInfoA = { ...newInfo.info };

                let keysNewInfo = Object.keys(newInfoA);
                let keysGeneralInfo = Object.keys(generalInfo);

                keysNewInfo.map(itemNewInfo => {
                    if (keysGeneralInfo.includes(itemNewInfo) &&
                        keysGeneralInfo.includes(itemNewInfo + "Id")) { //Si tiene id es select.
                        console.log("select" + itemNewInfo);

                        newInfoA = {
                            ...newInfoA,
                            [itemNewInfo]: { label: generalInfo[itemNewInfo], value: generalInfo[itemNewInfo + "Id"] }
                        }
                    } else if (keysGeneralInfo.includes(itemNewInfo)) { //input
                        newInfoA = {
                            ...newInfoA,
                            [itemNewInfo]: generalInfo[itemNewInfo]
                        }
                    }

                })

                setNewInfo({
                    ...newInfo,
                    info: { ...newInfoA }
                });

                setGeneralInfoSpinner(false);

            } else {
                console.log(payload)
            }
        })
    }
    //#endregion

    //#region Establecimiento de columnas para la tabla cuando es lineal o masivo (documentos)
    //Columnas de la tabla para mostrar documentos masivos
    const [columnsMasiveTemplate, setColumnsMasiveTemplate] = useState(
        [
            { value: "ID", label: "#", width: 90 },
            { value: "name", label: "Plantilla Masivo", width: 180 },
        ]
    );

    //Columnas de la tabla para mostrar documentos de aprobación
    const [columnsAprobationDocuments, setColumnsAprobationDocuments] = useState(
        [
            { value: "ID", label: "#", width: 90 },
            { value: "name", label: "Documentos de Aprobación", width: 180 },
        ]
    );

    //Columnas de la tabla para mostrar documentos masivos finales
    const [columnsFinalMasiveTemplate, setColumnsFinalMasiveTemplate] = useState(
        [
            { value: "ID", label: "#", width: 90 },
            { value: "name", label: "Plantilla Carga Masiva Final", width: 180 },
        ]
    );

    //Columnas para la tabla de filas de tipo lineal 
    const [columnsLinealRequest, setcolumnsLinealRequest] = useState(
        [
            { value: "ID", label: "#", width: 90 },
            { value: "linealDescription", label: "Linea", width: 90 },
            { value: "seeDetail", label: "Detalle", width: 70 },
        ]
    );



    //#endregion

    //#region State y función para guardar las líneas a eliminar en el api 
    //Almacena un array con los id de elementos de la tabla a eliminar 
    const [itemsLinealToDelete, setItemsLinealToDelete] = useState([])

    //Almacena en el state el id con el item a eliminar 
    const handleDeleteLinealItem = (id) => {

        setItemsLinealToDelete((prevState) => ([
            ...prevState, id
        ]))
    }
    //#endregion

    //#region Generar una tabla de tipo lineal y de tipo masivo

    //En el detalle de la tabla tipo lineal, hay algunos caso en los cuales se pide que en la fila
    //se concatenen dos valores de campos de el state reqGeneralInfo, por tanto este método establece 
    //cuales se concatenan o de lo contrario se muestran solos.
    const asignLinealValueTable = (row) => {
        // debugger;
        let type = reqGeneralInfo?.typeOfManagementId;

        //Extrae el state de los modales en base al ID del row, para verificar si el campo que va a la tabla lineal fue modificado
        const result = stateModalsIndvForms?.filter(modal => modal.row.ID === row.ID)[0]?.newInfo.info


        const nameLine = {
            //Creation
            1: {
                Clientes: ['businessName'],
                Contactos: ['firstName', 'lastName'],
                Materiales: ['idMaterial'],
                Equipos: ['materialId', 'equipmentSeries'],
                Ibase: ['description'],
                MaterialesdeServicio: ['idMaterial'],
                Servicios: ['idMaterial'],
                Repuestos: ['spareId'],
                Proveedores: ['socialReason'],
            },

            //Modification
            2: {

                Clientes: ['businessName'],
                Contactos: ['firstName', 'lastName'],
                Materiales: ['idMaterial'],
                Equipos: ['materialId', 'equipmentSeries'],
                //Ibase: ['description'],
                MaterialesdeServicio: ['idMaterial'],
                Servicios: ['idMaterial'],
                Repuestos: ['spareId'],
                Proveedores: ['socialReason'],


                Ibase: ['ibase'],
            }
        }

        try {
            //[1 (creation)][Clientes]
            let getIdToSearch = nameLine[type][reqGeneralInfo?.Formulario.replace(/ /g, "")]

            //Verifica si ha sido actualizado el state en newInfo del modal
            if (result[getIdToSearch[0]] !== undefined) {
                row = result
            }


            let valueToReturn = ""

            //Es solo un id a retornar
            if (getIdToSearch.length === 1) {
                valueToReturn = row[getIdToSearch]
            } else { //Se concatena varios campos
                getIdToSearch
                    .map(e =>
                        valueToReturn = valueToReturn + " " + row[e.toString()])
            }

            return valueToReturn;


        } catch (e) {
            return ""
        }
    }

    //Método que devuelve una tabla especificandole sus filas (rows), también un campo nameTypeDocumentToFilter (por ejemplo Masivo),
    //el cual las filas las filtra para que despligue solo las del nombre tipo de documentos, y por último se le aporta el nombre de las columnas.
    const generateTableLinealOrDocuments = (rows, nameTypeDocumentToFilter, columnsTable,) => {

        return (

            <Table
                className="align-items-center  table-flush mt-3 "
                responsive
                striped={true}
                hover={true}
            >

                <thead className="thead-light">

                    <tr>
                        {
                            columnsTable.map((column, key) => {
                                return (
                                    <th key={key} className="justify-content-md-center">
                                        {column.label}
                                    </th>
                                )
                            })

                        }
                    </tr>
                </thead>
                <tbody className='list'>
                    {rows.length > 0 ?

                        <>
                            {rows.filter(e => {
                                //console.log(e.typeDocument)
                                return ((e.typeDocument === nameTypeDocumentToFilter || nameTypeDocumentToFilter == "")

                                    //Esta validación es para que en la tabla tipo lineal no aparezca los id del array itemsLinealToDelete (debido a los que estan en esa lista son items que le dieron tocar al boton eliminar en la vista de aprobaciones), además que si typeDocument es diferente undefined significa que esta en la tabla de documentos, por tanto no se necesita  realizar el filtro a los ID
                                    && (!itemsLinealToDelete.includes(e.ID) || e.typeDocument !== undefined))
                            }
                            ).map((row, indexRow/*key*/) => {
                                return (

                                    <tr key={indexRow}>

                                        {columnsTable.map((col, key) => {
                                            return (

                                                //LINEAL
                                                col.value === "seeDetail" ? (
                                                    <td key={key} className="justify-content-md-center">

                                                        {view === "requests" &&
                                                            <Button id="refreshBtn" className="btn-info" size="sm" block type="button" style={{ width: "80px ", height: "30px" }}
                                                                onClick={(e) => handleOpenIndividualFormModal(row, indexRow)}
                                                            >
                                                                <span className="btn-inner--icon mr-">
                                                                    <i className="fa fa-search" />
                                                                </span>
                                                                <span className="btn-inner--text">
                                                                    {" "} Ver
                                                                </span>
                                                            </Button>


                                                        }


                                                        {view === "approvals" &&
                                                            (
                                                                <Row style={{ display: "flex", justifyContent: "center" }}>
                                                                    <Col style={{ display: "flex", justifyContent: "center" }}>
                                                                        <Button id="refreshBtn" className="btn-info mt-2" size="sm" block type="button" style={{ height: "30px" }}
                                                                            onClick={(e) => handleOpenIndividualFormModal(row, key)}
                                                                        >
                                                                            <span className="btn-inner--icon mr-">
                                                                                <i className="fas fa-edit" />
                                                                            </span>
                                                                            <span className="btn-inner--text">
                                                                                {" "} Editar
                                                                            </span>
                                                                        </Button>

                                                                        <Button id="refreshBtn" className="btn-danger" size="sm" block type="button" /*style={{ width: "80px " }}*/
                                                                            onClick={(e) => handleDeleteLinealItem(row.ID)}

                                                                        >
                                                                            <span className="btn-inner--icon mr-">
                                                                                <i className="fas fa-trash" />
                                                                            </span>
                                                                            <span className="btn-inner--text">
                                                                                {" "} Eliminar
                                                                            </span>
                                                                        </Button></Col>
                                                                </Row>
                                                            )


                                                        }

                                                    </td>)

                                                    //LINEAL
                                                    : col.value === "linealDescription" ? (
                                                        <td key={key} className="justify-content-md-center">
                                                            <span className="btn-inner--icon mr-">
                                                                <i className="ni ni-check-bold" />
                                                            </span>
                                                            <span className="btn-inner--text">
                                                                {" "} {asignLinealValueTable(row)}
                                                            </span>
                                                        </td>)



                                                        //MASIVO DOCUMENTOS APROBACION ETC

                                                        : col.value === "name" ? (
                                                            <td key={key} className="justify-content-md-center ">

                                                                <Button id="refreshBtn" className="btn-default" size="sm" block type="button" style={{ width: "fit-content", height: "30px" }}
                                                                  
                                                                    onClick={(e) => handleDownloadFile(e, reqGeneralInfo?.Gestion, row[col.value])}
                                                                >
                                                                    <span className="btn-inner--icon mr-">
                                                                        <i className="fa fa-file" />
                                                                    </span>
                                                                    {" "}    {row[col.value]}

                                                                </Button>

                                                            </td>)

                                                            //CUALQUIER TIPO 
                                                            : (
                                                                <td key={key} className="justify-content-md-center">
                                                                    {row[col.value]}
                                                                </td>
                                                            )
                                            )
                                        })}

                                    </tr>

                                )
                            })}
                        </>
                        :
                        <>
                            <tr>
                                <td className="justify-content-md-center">
                                    Sin resultados encontrados
                                </td>
                            </tr>
                        </>
                    }
                </tbody>

            </Table>
        )
    }

    //Verifica cada campo del json reqGeneralInfo, y en othersFieldsId verifica si alguno tiene datos en reqGeneralInfo, de lo contrario asume que el id tiene datos en reqGeneralInfo 
    const asignValue = (id, othersFieldsId) => {
        //Verifica cuales de los campos othersFieldsId del input del formulario, contiene datos en reqGeneralInfo, y ese es el id que va a tomar.
        const tempId = othersFieldsId?.filter(field => reqGeneralInfo[field] != null || reqGeneralInfo[field] != undefined)

        //Encontró un id con datos
        if (tempId != undefined) {
            return {
                id: tempId,
                value: reqGeneralInfo[tempId]
            };
        }
        //Ninguno de los id de othersFieldsId tiene datos, por lógica entonces el id por defecto  del campo del json generalInfoFields tiene datos en reqGeneralInfo, 
        else {
            return {
                id: id,
                value: reqGeneralInfo[id]
            };
        }

    }

    //Valida si en documentsNames contiene un nombre de tipo de documento.
    const containsThisTypeDocument = (typeDocument) => {


        const result = documentsNames.filter((document) => {
            if (document.typeDocument === typeDocument) {
                return document;
            }
        })

        return result.length > 0;

    }



    //#endregion

    //#region Métodos handles de cambio de información y abrir modal
    //Asigna los valores de cada campo del form en newInfo, y a la vez asigna los campos option a los demás dropdowns dependiendo de la selección.
    const handleChangeInfo = (constant, e, i) => {

        //console.log(constant)
        //console.log(i)
        if (constant === "vendorGroup" && formNameFormat === "proveedores") {
            showToast("Por favor revise el Vendor Type del(os) proveedor(es).", "warning")
        }


        setNewInfo((prevState) => ({
            ...prevState,
            info: { ...prevState.info, [constant]: e },
            updateInfo: true

        }))



    }

    const handleOpenIndividualFormModal = (row,) => {

        const newState = stateModalsIndvForms.map(modal =>
            (modal.row.ID === row.ID || modal.row === row) ? { ...modal, showModal: true } : modal)
        console.log(newState)

        setStateModalsIndvForms(newState)

    }
    //#endregion

    //#region Handle de aprobar/rechazar solicitud y métodos clave para validaciones del envío

    //Método para generar la fecha actual de la gestión
    const generateDateNow = () => {
        let d = new Date();
        let dateReturn = "[" + [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/') + ' ' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':') + "]";
        return dateReturn
    }
    //Método para devolver una cadena si existe, y si es vacío no devuelve nada.
    const generateCommentApprovalOrReject = () => {
        //debugger;
        let result = reqGeneralInfo.commentApproval;
        if (result === null || result === false || result === "false") {
            return ""
        }
        return result + " ";
    }
    // Método para validar si los campos están de todos los forms están vacíos o no.
    const validateForms = () => {

        let cont = 0;
        //#region Valida los campos input y selects normales

        const forms2 = document.querySelectorAll(".formInfo input,Select, textarea")

        Array.from(forms2).forEach((input) => {

            if (input.name === "masterDataModalField" &&
                input.id !== "Respuesta" &&
                input.id !== "commentApproval" &&
                input.id !== "comment"

            ) {

                if (input.value === "") {
                    input.classList.add("is-invalid");
                    cont++;
                }
                else {
                    input.classList.remove("is-invalid");
                }
            }
        });

        //#endregion
        return cont;

    }

    //Aplica el formato E.164 a los números telefónicos.  
    const applyE164FormatPhoneNumbers = () => {
        // console.log("state", stateModalsIndvForms);

        const newStateModalsIndvForms = stateModalsIndvForms.map(item => {
            if (item.newInfo && item.newInfo.info) {
                // Verificar si existe phone o phoneContact en el objeto actual
                const info = item.newInfo.info;
                if ('phone' in info) {
                    let countryCode = info.country.label.split(' - ')[0];
                    info.phone = parsePhoneNumberFromString(info.phone, countryCode).format('E.164');
                }
                if ('phoneContact' in info) {
                    let countryCode = info.countryContact.label.split(' - ')[0];
                    info.phoneContact = parsePhoneNumberFromString(info.phoneContact, countryCode).format('E.164');
                }
            }
            return item;
        });


        // console.log("NEW STATE", newStateModalsIndvForms)
        return newStateModalsIndvForms
    }


    //Este handle es para los botones de aprobar o rechazar, el action es para espercificar si es el boton de aprobar o rechazar
    const handleApprovalOrReject = (action) => {
        //debugger;
        const countEmpty = validateForms();

        if (countEmpty > 0) {
            Toast.fire({

                title: "Atención",

                html: `"No debe dejar ningún campo en blanco"`,

                type: 'warning'

            });
            return;
        }

        if (stillChargingDocuments.chargingCorrectionsDocs === true) {
            showToast("Por favor, debe esperar que termine de cargar los documentos de corrección.", "warning")
            return;
        }

        if (stillChargingDocuments.chargingDocumentationDocs === true) {
            showToast("Por favor, debe esperar que termine de cargar los archivos de documentación.", "warning")
            return;
        }


        if (action === "approvalGestionBtn") { //Lógica de aprobar gestión 

            MySwal.fire({

                type: 'info',
                title: `Solicitud: ${reqGeneralInfo.Gestion}`,
                html:

                    '<h2>Aprobar solicitud</h2><textarea class="form-control" rows="3" id="approvalComment" placeholder="Comentarios del aprobación..."></textarea>',

                confirmButtonText: 'Si, aprobar.',
                confirmButtonColor: '#2DCE89',
                cancelButtonText: 'No, cancelar',
                showCancelButton: true,

                preConfirm: () => {

                    //  let modalsStates = applyE164FormatPhoneNumbers(); //=> 23-2-2024 - EPIEDRA: se inactiva debido a que sap hay un campo interno donde le aplica el prefijo segun el pais, es decir solo debe mandarse el número
                    
                    let modalsStates= stateModalsIndvForms;
                    setStateModalsIndvForms(modalsStates);

                    dispatch(approvalRejectRequest({
                        newInfo: newInfo,
                        itemsLinealToDelete: itemsLinealToDelete,
                        reqGeneralInfo: reqGeneralInfo,
                        filesListDocumentation: filesListDocumentation,
                        filesListCorrections: filesListCorrections,
                        stateModalsIndvForms: modalsStates,
                        commentApproval:


                            generateCommentApprovalOrReject() + user + generateDateNow() + ": " + document.getElementById('approvalComment').value + ". ",

                        commentApprovalSingle: document.getElementById('approvalComment').value,
                        action: "APROBAR",
                        user: user,
                        motherTable: motherTable

                    })).then((resp) => {

                        const { payload } = resp;
                        if (payload.status === 200) {

                            console.log(payload.data.payload)

                            //Recargar las filas de la tabla principal
                            setReloadTableRequests(true);
                            Toast.fire({

                                title: "Atención",

                                html: `Se ha aprobado correctamente, la gestión pasa a estado: ${payload?.data.payload.state[0].status}`,

                                type: 'success'

                            });
                        } else {
                            Toast.fire({

                                title: "Atención",

                                html: `Ocurrió un error, por favor intente nuevamente o contacte a Support.`,

                                type: 'warning'

                            });
                            console.log(payload.response?.data.message)
                        }
                    })


                    handleCloseModal()


                }

            })






        } else if (action === "rejectGestionBtn") { //Lógica de botón de rechazar gestión 

            MySwal.fire({

                type: 'question',

                title: `Solicitud: ${reqGeneralInfo.Gestion}`,

                text: 'Rechazar solicitud',

                html:

                    '<h2>Rechazar solicitud</h2><textarea class="form-control" rows="3" id="rejectComment" placeholder="Comentarios del rechazo..."></textarea>',

                confirmButtonText: 'Si, rechazar.',

                confirmButtonColor: '#f5365c',

                cancelButtonText: 'No, cancelar',

                showCancelButton: true,

                preConfirm: () => {

                    dispatch(approvalRejectRequest({
                        newInfo: newInfo,
                        itemsLinealToDelete: itemsLinealToDelete,
                        reqGeneralInfo: reqGeneralInfo,
                        filesListDocumentation: filesListDocumentation,
                        filesListCorrections: filesListCorrections,
                        stateModalsIndvForms: stateModalsIndvForms,
                        commentApproval:
                            generateCommentApprovalOrReject() + user + " [" + new Date().toISOString().replace("T", " ").split('.')[0] + "]: " + document.getElementById('rejectComment').value + ". ",
                        commentApprovalSingle: document.getElementById('rejectComment').value,
                        action: "RECHAZAR",
                        user: user,
                        motherTable: motherTable

                    })).then((resp) => {

                        const { payload } = resp;
                        if (payload.status === 200) {

                            //Recargar la tabla principal
                            setReloadTableRequests(true);

                            Toast.fire({

                                title: "Atención",

                                html: `Se ha rechazado la gestión correctamente.`,

                                type: 'success'

                            });
                        } else {
                            Toast.fire({

                                title: "Atención",

                                html: `Ocurrió un error, por favor intente nuevamente o contacte a Support.`,

                                type: 'warning'

                            });
                        }
                    })
                    handleCloseModal()


                }

            })

        }

    }
    //#endregion

    //#region Efectos CLAVE para generar los modales de formularios individuales tipo lineal 
    const [linealFirstTime, setLinealFirstTime] = useState(0)

    //Este efecto es clave para generar un arreglo con todos los modales en base a los rows lineales del modal
    useEffect(() => {
        if (linealRows.length > 0) {
            if (linealFirstTime === 0) {
                setStateModalsIndvForms([]) //reinicia el state 

                console.log(stateModalsIndvForms)

                linealRows.map((linealRow) => {
                    setStateModalsIndvForms((prevState) => ([
                        ...prevState,
                        {
                            showModal: false,
                            view: view, //requests, approvals, newrequest
                            row: linealRow, //En caso de cargar información predefinida como en Mis Gestiones o aprobaciones.
                            formName: reqGeneralInfo?.Formulario, //Nombre del formulario para hacer match con generalStates
                            newInfo: {
                                info: {},
                                updateInfo: false
                            },
                            typeOfManagementId: newInfo?.info.typeOfManagement.value,//reqGeneralInfo?.typeOfManagementId //Creación o modificación
                            countryClientId: newInfo.info.sendingCountry?.value, //Para la excepción de clientes 
                            vendorGroup: newInfo.info.vendorGroup?.value, //Para la excepción de proveedores 
                            companyCode: newInfo.info.companyCode?.value //Para la excepción de proveedores 

                        }
                    ])
                    )
                }
                )
                setLinealFirstTime(linealFirstTime + 1);
            }



        }
    }, [linealRows]) //Muy importante tomar en cuenta el newInfo debido a que cuando cambie el newInfo actualice el typeOfManagementId


    //En caso de que newInfo cambie por ejemplo el cambio de creacion/modificación, actualice el nuevo cambio a los props de los modales lineales( formularios individuales)
    useEffect(() => {
        if (linealRows.length > 0) {

            const newState = stateModalsIndvForms.map((modal) => {

                return {
                    ...modal,
                    typeOfManagementId: parseInt(newInfo?.info.typeOfManagement.value),//reqGeneralInfo?.typeOfManagementId //Creación o modificación
                    countryClientId: parseInt(newInfo.info.sendingCountry?.value), //Para la excepción de clientes 
                    vendorGroup: parseInt(newInfo.info.vendorGroup?.value), //Para la excepción de proveedores 
                    companyCode: parseInt(newInfo.info.companyCode?.value) //Para la excepción de proveedores 
                }
            });

            setStateModalsIndvForms(newState)
        }
    }, [newInfo])



    //Efecto para reiniciar el contador para que solo renderice la primera vez
    useEffect(() => {
        if (modalDetail.showDetailModal === false) {
            setLinealFirstTime(0)
            setGeneralInfoExtracted(false);
        }
    }, [modalDetail.showDetailModal])


    
    const handleDownloadFile = (e, gestion, val) => {
       
        const downloadUrl = `${urls.MASTERDATA.downloadDocument}/${encrypter(gestion)}/${encrypter(val)}`

        fetch(downloadUrl, {
            method: 'HEAD', // Utilice la solicitud HEAD para verificar la existencia sin descargar el archivo completo
        })
            .then(response => {
                if (response.ok) {
                    window.location.assign(downloadUrl);
                } else {
                    Toast.fire({
                        title: "Atención",
                        html: "Ocurrio un error, el archivo es inexistente, por favor intente de nuevo ",
                        type: "warning",

                    });
                }
            })
            .catch(error => {
                console.error('Error checking file existence:', error);
                Toast.fire({
                    title: "Atención",
                    html: "Ocurrio un error al validar el archivo ",
                    type: "warning",

                });
            });
    }
    //#endregion 

    return (
        <>

            <Modal backdrop="static" size="xl" style={{ maxWidth: '1500px', width: '100%' }} isOpen={modalDetail.showDetailModal} unmountOnClose={stateDetail.unmountOnClose} toggle={handleCloseModal} >
                <Spin size="large" spinning={false} >
                    <ModalBody className=" mb--2" style={{ display: "flex", justifyContent: "center", maxWidth: '1500px', width: '100%' }}>


                        <Row style={{ display: "flex", justifyContent: "start", maxWidth: '1500px', width: '100%' }} className="mr-1">
                            <Col sm="8" className=" ">
                                <h3>Formulario: {reqGeneralInfo?.Formulario}</h3>
                            </Col>

                            {view === "approvals" &&
                                <Col sm="4" className="" style={{ display: "flex", justifyContent: "center" }}>
                                    <Button id="approvalGestionBtn" className="btn-success mt-2" size="sm" block type="button" style={{ height: "30px" }}
                                        onClick={(e) => handleApprovalOrReject("approvalGestionBtn")}

                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="ni ni-check-bold" />
                                        </span>
                                        <span className="btn-inner--text">
                                            {" "} Aprobar gestión
                                        </span>
                                    </Button>

                                    <Button id="rejectGestionBtn" className="btn-danger" size="sm" block type="button" /*style={{ width: "80px " }}*/
                                        onClick={(e) => handleApprovalOrReject("rejectGestionBtn")}

                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fa fa-times" />
                                        </span>
                                        <span className="btn-inner--text">
                                            {" "} Rechazar gestión
                                        </span>
                                    </Button>
                                </Col>}
                        </Row>
                    </ModalBody>


                    <ModalBody className="d-flex justify-content-center mt--4 mb--2">

                        <Container className="" fluid>
                            <Card>
                                <CardHeader className="bg-transparent border-1">
                                    <span> <b> Id Gestión: #</b>{reqGeneralInfo?.Gestion} <b>| Solicitante:</b> {reqGeneralInfo?.createdBy} <b>| Estado: </b>{reqGeneralInfo?.Estado} <b>| Aprobadores:</b> {reqGeneralInfo?.Aprobadores}</span>
                                </CardHeader >
                                <CardBody className="mt--4">
                                    <Row >
                                        <Col sm="12" className="" style={{ display: "flex", justifyContent: "center" }}>
                                            <h2>Información de la Gestión</h2>
                                        </Col>
                                    </Row>

                                    <Row >




                                        <Col xs="12" sm="6">


                                            <Button
                                                color="primary"
                                                className="btn-block"
                                                type="button"
                                                size="sm"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fa fa-id-card" />
                                                </span>Datos Generales
                                            </Button>

                                            <Spin size="large" spinning={view == "requests" && generalInfoSpinner}>
                                                <Row xs="12" className="border mt-2 ">
                                                    {generalInfoFields.map((row, key) =>
                                                        (row.dependsTypeForm === false ||
                                                            //Validación para verificar si debe mostrarse dependiendo del JSON que tenga el form
                                                            (row.dependsTypeForm === true && row.nameTypeForm.includes(reqGeneralInfo?.Formulario))
                                                        )
                                                        &&
                                                        <Col xs="12" sm={row.colWidth} key={key}>
                                                            {row.type === "select" ?
                                                                (<FormGroup key={key}>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-username"
                                                                    >
                                                                        {`${row.label}:`}
                                                                    </label>
                                                                    <Input
                                                                        key={key}
                                                                        id={row.id}
                                                                        name={"masterDataModalField"}
                                                                        required={row.required}
                                                                        placeholder={row.placeholder}
                                                                        className="form-control text-dark"
                                                                        readOnly={row.disabled || view === "requests" ||
                                                                            //Para que se bloquee si es ibase 
                                                                            (row.id === "typeOfManagement" && reqGeneralInfo?.dataTypeId === 5/*ibase*/)}
                                                                        //value={info != undefined && asignValue(row.id, row.othersFieldsId)}
                                                                        //value="CREAR - Creacion"
                                                                        value={newInfo.info[row.id]?.label}
                                                                        type={row.type}
                                                                        onChange={(e) => {
                                                                            //Para que no ejecute el onchange cuando hay estas condiciones 
                                                                            !(row.disabled || view === "requests" ||
                                                                                (row.id === "typeOfManagement" && reqGeneralInfo?.dataTypeId === 5/*ibase*/)) &&

                                                                                handleChangeInfo(row.id, { label: e.target.children[e.target.selectedIndex].value, value: e.target.children[e.target.selectedIndex].id }, e.target.children[e.target.selectedIndex].name)
                                                                        }
                                                                        }
                                                                    >
                                                                        <option id=""></option>
                                                                        {
                                                                            options[row.id] && options[row.id]
                                                                                .filter(option => {
                                                                                    //Este filter fue diseñado para que dependendiendo del tipo de gestión aparezca creación, modificación o ambos dentro de las opciones, partiendo del campo creacionm de generalstates
                                                                                    //debugger;
                                                                                    if (row.id === "typeOfManagement") {
                                                                                        if (creacionm === "crearymod") {
                                                                                            return option
                                                                                        } else if (creacionm === "creacion") {
                                                                                            return option.value === 1
                                                                                        } else if (creacionm === "modificacion") {
                                                                                            return option.value === 2
                                                                                        }
                                                                                    } else {
                                                                                        return option
                                                                                    }

                                                                                }
                                                                                ).map((value) => {
                                                                                    return (
                                                                                        <option id={value.value}>{value.label}</option>
                                                                                    );
                                                                                })}

                                                                    </Input>
                                                                    <div className="invalid-feedback">
                                                                        Este campo no puede estar vacío
                                                                    </div>
                                                                </FormGroup>)


                                                                : //Tipo texto
                                                                (<FormGroup key={key}>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-username"
                                                                    >
                                                                        {`${row.label}:`}
                                                                    </label>
                                                                    <Input
                                                                        key={key}
                                                                        id={row.id}
                                                                        name={"masterDataModalField"}
                                                                        required={row.required}
                                                                        placeholder={row.placeholder}
                                                                        className="form-control text-dark"
                                                                        readOnly={row.disabled || view === "requests"}
                                                                        //value={reqGeneralInfo != undefined && asignValue(row.id, row.othersFieldsId).value}
                                                                        value={newInfo?.info[row.id]}
                                                                        type={row.type}
                                                                        onChange={(e) => { !row.disabled && handleChangeInfo(row.id, e.target.value) }}

                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Este campo no puede estar vacío
                                                                    </div>
                                                                </FormGroup>)



                                                            }


                                                        </Col>



                                                    )}

                                                    {/*Documentos de Aprobación */}
                                                    {
                                                        <Col xs="12" sm={12} >


                                                            {containsThisTypeDocument("Documentos de Aprobacion") &&
                                                                view === "approvals" && //Aparezca en este sitio cuando son aprobaciones de lo contario que aparezca en la linea 1299

                                                                generateTableLinealOrDocuments(documentsNames, "Documentos de Aprobacion", columnsAprobationDocuments)

                                                            }

                                                        </Col>
                                                    }

                                                    {view === "approvals" &&
                                                        <Col xs="12" >

                                                            <UploadFile
                                                                id={"documentation"}
                                                                fileListM={filesListDocumentation}
                                                                setFileListM={setFilesListDocumentation}
                                                                gestion={reqGeneralInfo?.Gestion}
                                                                title={"Adjuntar documentación"}
                                                                subtitle={"A continuación puede adjuntar alguna documentación de la gestión..."}
                                                                stillChargingDocuments={stillChargingDocuments}
                                                                setStillChargingDocuments={setStillChargingDocuments}
                                                                motherTable={motherTable}
                                                                type={"2"}
                                                                documentsNames={documentsNames}
                                                            ></UploadFile>

                                                        </Col>
                                                    }

                                                </Row>
                                            </Spin>

                                        </Col>

                                        <Col xs="12" sm="6">
                                            <Button
                                                color="primary"
                                                className="btn-block ml-2"
                                                type="button"
                                                size="sm"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fa fa-id-card" />
                                                </span> {reqGeneralInfo?.method}
                                            </Button>

                                            <Col xs="12" className="border mt-2 ml-1 mr--1 p-0" >
                                                <Spin size="large" spinning={spinner.tableSpinner}>
                                                    {/*Lineal */}
                                                    <Col xs="12" sm="12" style={{ display: "flex", justifyContent: "center", width: "100%" }} >

                                                        {reqGeneralInfo?.method === "Lineal" &&

                                                            generateTableLinealOrDocuments(linealRows, "", columnsLinealRequest)

                                                        }

                                                    </Col>

                                                    {/*Plantilla Carga Masiva */}

                                                    <Col xs="12" sm={12} >

                                                        {containsThisTypeDocument("Plantilla Carga Masiva") &&
                                                            !containsThisTypeDocument("Plantilla Carga Masiva Final") && //Si hay plantilla final no aparezca las de carga masiva
                                                            reqGeneralInfo?.method === "Masivo" &&

                                                            generateTableLinealOrDocuments(documentsNames, "Plantilla Carga Masiva", columnsMasiveTemplate)

                                                        }

                                                    </Col>

                                                    {/*Documentos de Aprobación */}
                                                    {
                                                        <Col xs="12" sm={12} >


                                                            {containsThisTypeDocument("Documentos de Aprobacion") && //Aparezca en este sitio cuando son solicitudes de lo contario que aparezca en la linea 1227
                                                                view === "requests" &&

                                                                generateTableLinealOrDocuments(documentsNames, "Documentos de Aprobacion", columnsAprobationDocuments)

                                                            }

                                                        </Col>
                                                    }

                                                    {/*Plantilla Carga Masiva Final */}
                                                    <Col xs="12" sm={12} >


                                                        {containsThisTypeDocument("Plantilla Carga Masiva Final") &&
                                                            reqGeneralInfo?.method === "Masivo" &&

                                                            generateTableLinealOrDocuments(documentsNames, "Plantilla Carga Masiva Final", columnsFinalMasiveTemplate)

                                                        }
                                                    </Col>

                                                    {view === "approvals" &&

                                                        <Col xs="12" sm={12} className="mt-6 ml-2" >

                                                            {reqGeneralInfo?.method === "Masivo" &&

                                                                <UploadFile
                                                                    id={"corrections"}
                                                                    fileListM={filesListCorrections}
                                                                    setFileListM={setFilesListCorrections}
                                                                    gestion={reqGeneralInfo?.Gestion}
                                                                    title={"Adjuntar corrección"}
                                                                    subtitle={"A continuación puede adjuntar alguna corrección de la gestión..."}
                                                                    stillChargingDocuments={stillChargingDocuments}
                                                                    setStillChargingDocuments={setStillChargingDocuments}
                                                                    motherTable={motherTable}
                                                                    type={"1"}
                                                                    documentsNames={documentsNames}
                                                                ></UploadFile>

                                                            }
                                                        </Col>
                                                    }



                                                </Spin>
                                            </Col>



                                        </Col>





                                    </Row>
                                </CardBody>
                            </Card>
                        </Container>

                    </ModalBody>

                    <ModalFooter className="mt--4 border">

                        <Button
                            color="info"
                            type="button"
                            onClick={() => { handleCloseModal() }}
                        >
                            Volver
                        </Button>

                    </ModalFooter>
                </Spin>

            </Modal >



        </>
    )
}
