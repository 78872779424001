/*!

=========================================================
*Autopp - LDRSelectOption
=========================================================

*Este componente despliega los toggles de todos los LDRS que 
puede seleccionar el usuario. Los mismos los agrega a los 
states llamados toggleCheck, el cual es consumido por la vista
de LDRMainForm.

=========================================================

* Coded by Eduardo Piedra Sanabria - Application Management GBM

*/

//Import de todos las librerias de React
import React, { useState, useEffect, useContext } from 'react'
import { Col, Row } from "reactstrap";

//Import de componentes
import { UserContext } from '../SharedComponents/UserContext';

// imports de Alerts y  Spin
import { Spin } from 'antd';

export const LDRSelectOption = React.memo(({
    setToggleCheck,
    toggleCheck,
    cycle
}) => {

    //#region States Globales
    const {

        setCountLDRActives,
        dropDownOptions,
        setDeleteLDR,
        setEditData,
        LDRfields,
    } = useContext(UserContext);
    //#endregion

    useEffect(() => {
        // console.log("Cycle", cycle)
        // console.log("LDRfields", LDRfields)

    }, [cycle, LDRfields])


    //#region States Locales



    //Backup de como trabajaba antiguamente el state de subtítulos
    const [subtitlesBackup] = useState([

        {
            clasification: "collaboration",
            colWidth: "2",
            label: "Collaboration",
            placeholder: "",
            id: 'colaborationTitle',
        },

        {
            clasification: "networkingSDN",
            colWidth: "2",
            label: "Networking SDN",
            placeholder: "",
            id: 'networkingSDNTitle',
        },
        {
            clasification: "gtlVirtualization",
            colWidth: "2",
            label: "Virtualización GTL",
            placeholder: "",
            id: 'gtlVirtualizationTitle',
        },

        {
            clasification: "compute",
            colWidth: "2",
            label: "Compute",
            placeholder: "",
            id: 'computeTitle',
        },
        {
            clasification: "storage",
            colWidth: "2",
            label: "Storage",
            placeholder: "",
            id: 'storageTitle',
        },

    ])

    //Backup de como trabajaba antiguamente el state de fieldsFormBackup
    const [fieldsFormBackup] = useState([
        {
            clasification: "principal",
            colWidth: "2",
            label: "¿Aplica LDR?",
            placeholder: "",
            id: 'applyLDROption',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },
        {
            clasification: "principal",
            colWidth: "3",
            label: "¿Adjuntar Cartel de Licitación / RFP / RFI / BOM?",
            placeholder: "",
            id: 'applyBOMPNOption',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        {
            clasification: "collaboration",
            colWidth: "3",
            label: "Meetings",
            placeholder: "",
            id: 'meetings',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        {
            clasification: "collaboration",
            colWidth: "3",
            label: "Telefonía",
            placeholder: "",
            id: 'telephony',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        {
            clasification: "collaboration",
            colWidth: "3",
            label: "Dispositivos",
            placeholder: "",
            id: 'devices',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        {
            clasification: "collaboration",
            colWidth: "3",
            label: "Contact Center",
            placeholder: "",
            id: 'contactCenter',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        //Networking SDN
        {
            clasification: "networkingSDN",
            colWidth: "3",
            label: "Datacenter",
            placeholder: "",
            id: 'dataCenter',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        {
            clasification: "networkingSDN",
            colWidth: "3",
            label: "LAN y WLAN",
            placeholder: "",
            id: 'LANAndWLAN',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        {
            clasification: "networkingSDN",
            colWidth: "3",
            label: "WAN Enterprise",
            placeholder: "",
            id: 'WANEnterprise',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        //Virtualización GTL

        {
            clasification: "gtlVirtualization",
            colWidth: "3",
            label: "Renovar VMware",
            placeholder: "",
            id: 'revenueVMware',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        //Compute

        {
            clasification: "compute",
            colWidth: "3",
            label: "Lenovo",
            placeholder: "",
            id: 'lenovo',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },


        {
            clasification: "compute",
            colWidth: "3",
            label: "Power - Nuevo",
            placeholder: "",
            id: 'powerNew',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },


        {
            clasification: "compute",
            colWidth: "3",
            label: "Power - MES",
            placeholder: "",
            id: 'powerMES',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },

        //Storage
        {
            clasification: "storage",
            colWidth: "3",
            label: "Storage Nuevo",
            placeholder: "",
            id: 'newStorage',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },
        {
            clasification: "storage",
            colWidth: "3",
            label: "MES Storage",
            placeholder: "",
            id: 'mesStorage',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },


    ])

    //Subtítulos del componente
    const [subtitles, setSubtitles] = useState([]);

    //Asignación de campos con cada Toggle
    const [fieldsForm, setFieldsForm] = useState([

        {
            clasification: "principal",
            colWidth: "2",
            label: "¿Aplica LDR?",
            placeholder: "",
            id: 'applyLDROption',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },
        {
            clasification: "principal",
            colWidth: "3",
            label: "¿Adjuntar Pliego Cartelario, RFP, RFI, Correo electrónico del cliente detallando el requerimiento?",
            placeholder: "",
            id: 'applyBOMPNOption',
            defaultValue: '',
            type: "toggle",
            disabled: false,
            visibleDefault: true,
        },
    ]);


    //Efecto para generar los subtítulos y campos, según los LDRS y Brands.
    useEffect(() => {
        if (LDRfields.LDRBrand != undefined) {
            // debugger;
            let subtitlesTemp = []
            let fieldsFormTemp = [...fieldsForm.filter(field => field.clasification == 'principal')] //Solo traiga los LDR principales
            let showAllLDRs = dropDownOptions.cycle.filter((cycleO) => cycleO.value == cycle)[0].showAllLDRs == 1
            // let foundCycle = dropDownOptions.cycle.filter()

            // console.log("showAllLDRs", showAllLDRs);

            // console.log("LDRFIELDS", LDRfields)

            //Declarar los subtítulos
            LDRfields.LDRBrand.map(brand => {
                if (
                    brand.cycle == cycle
                    || (showAllLDRs && brand.cycle == 1)

                    // //Excepción para cuando seleccione 4 Quotation, también muestre los de GTL Quotation
                    // || (brand.cycle == 1 /*GTL Quotation*/)
                    // || (brand.cycle == 6 /*GESTO Quotation*/)
                ) {

                    subtitlesTemp = [...subtitlesTemp,

                    {
                        clasification: brand.idName,
                        colWidth: "2",
                        label: brand.label,
                        id: brand.idName + 'Title',
                    },]
                }

            })

            //Declarar los fields form
            Object.values(LDRfields.useLDRFields).map(LDR => {
                if (
                    (LDR.cycle == cycle // Que sea el mismo ciclo 
                        || (showAllLDRs && LDR.cycle == 1 && LDR.showOnlyInGTL == 0)) // O que el ciclo muestre todos los LDRS de GTL, PERO si ese LDR de GTL en específico no se desea que se muestre en otro ciclo no mostrar.

                    //&& LDR.cycle != 1 && LDR.showOnlyInGTL == 1

                    // //Excepción para cuando seleccione 4 Quotation, también muestre los de GTL Quotation
                    // || (LDR.cycle == 1 /*GTL Quotation*/)
                    // || (LDR.cycle == 6 /*GESTO Quotation*/)
                ) {


                    fieldsFormTemp = [...fieldsFormTemp,

                    {
                        clasification: LDR.brandFather,
                        colWidth: "3",
                        label: LDR.label,
                        id: LDR.id,
                        type: "toggle",
                        disabled: false,
                        visibleDefault: true,
                    },]
                }

            })

            setSubtitles(subtitlesTemp)
            setFieldsForm(fieldsFormTemp)
            // console.log(subtitlesTemp)
        }

    }, [cycle, LDRfields])

    useEffect(() => {
        // console.log("Subtitle", subtitles)
        // console.log("fieldsForm", fieldsForm)
    }, [subtitles, fieldsForm])


    //#endregion

    //#region Asignación del titulo y legend del LDR.
    const [state] = useState({
        title: "Opciones",
        legend: "¿Cuál podría ser el tipo de servicio o producto que aplica para esta oportunidad?",
        LDRtitle: "Selección de LDR"
    });
    const { title, legend, LDRtitle } = state;
    //#endregion

    //#region Efectos
    //Para cuando arranque ponga en true el toggle LDR
    useEffect(() => {
        fieldsForm.map((row, index) => {

            if (index === 0) { // Para que inicialice siempre el primer botón principal (En este caso LDROption)
                setToggleCheck(prevState => ({
                    ...prevState,
                    [row.id]: true
                }))

            } else { //Los normales
                setToggleCheck(prevState => ({
                    ...prevState,
                    [row.id]: false
                }))

            }
        })
    }, [])

    //Cuenta los toggles encendidos y lo almacena en una variable ( countLDRActives )
    useEffect(() => {

        let togglesToJSON = Object.keys(toggleCheck).map((key) => [key = toggleCheck[key]]);

        const counterTogglesOn = togglesToJSON.filter((e, index) => {
            if (e[0] === true) {
                return e
            }
        });
        setCountLDRActives(counterTogglesOn.length - 1); //-1 para no tomar en cuenta los toggles (LDR o BOM)

    }, [toggleCheck])
    //#endregion

    //#region Handles
    const handleToggleCheck = (type, constant) => {

        if (type === 1) { //tipo 1 son los checkbox de LDR o BOM

            if (constant === "applyLDROption") {
                setToggleCheck(prevState => ({
                    ...prevState,
                    [constant]: !toggleCheck[constant],
                    ["applyBOMPNOption"]: toggleCheck[constant]
                }))
            } else { //applyBOMPNOption
                setToggleCheck(prevState => ({
                    ...prevState,
                    [constant]: !toggleCheck[constant],
                    ["applyLDROption"]: toggleCheck[constant]
                }))

                fieldsForm.map((row) => {
                    if (row.clasification !== "principal") {
                        setToggleCheck(prevState => ({
                            ...prevState,
                            [row.id]: false
                        }))
                    }
                })
            }
        } else if (type === 2) {

            //Valida si superó la cantidad de LDR establecidos y reinicia.
            if (/*countLDRActives === 8*/false) { // Si se necesita poner un límite de toggles hablitar esa validación 
                //console.log(countLDRActives)
                setCountLDRActives(0);

                fieldsForm.map((row) => {
                    if (row.id !== "applyLDROption" && row.id !== "applyBOMPNOption") {
                        setToggleCheck(prevState => ({
                            ...prevState,
                            [row.id]: false
                        }))
                    }
                })

                //Limpie LDRS si ha superado los 4 toggles
                setEditData(prevState => ({
                    ...prevState,
                    ["LDRS"]: ""
                }))

            } else { //no ha superado el límite toggles


                let aux = constant;

                setToggleCheck(prevState => ({
                    ...prevState,
                    [constant]: !toggleCheck[constant]
                }))

                //Le indica a LDRMainForm cual toggle se acaba de apagar, para que lo elimine de su JSON.
                if (!toggleCheck[aux] === false) {
                    setDeleteLDR(aux)
                }
            }
        }







    }
    //#endregion


    //Función para averiguar si un subtítulo tiene LDRS activos.
    const subtitleHaveLDRS = (subtitle) => {
        const ldrs = fieldsForm.filter(rowField => rowField.clasification === subtitle.clasification)

        return ldrs.length > 0
    }


    return (
        <Spin size="large" spinning={LDRfields.LDRBrand == undefined}>

            <Row className="" style={{ display: "flex", justifyContent: "center" }}>
                <Col className="" sm="3" style={{ display: "flex", justifyContent: "center" }}>
                    <h3 className="mb-0">{title}</h3>
                </Col>
            </Row>



            {fieldsForm.map((row, key) =>
                //Se imprime los dos toggles de ldr y Bom
                (row.clasification === "principal") &&
                <>
                    <Row className="mb--1 mt-2" style={{ display: "flex", justifyContent: "center" }}>
                        <Col sm="auto" key={key + "label"} className="" style={{ display: "flex", justifyContent: "center" }} >
                            <label ><strong> {`${row.label} `} </strong></label>


                        </Col>

                        <Col sm="auto" key={key} className="ml--4 " style={{ display: "flex", justifyContent: "center" }}>
                            <label className="custom-toggle custom-toggle-info " >
                                <input
                                    key={row.id}
                                    checked={toggleCheck[row.id]}
                                    className=''
                                    id="edit"
                                    type="checkbox"
                                    onChange={(e) => handleToggleCheck(1, row.id)}
                                    disabled={row.disabled}>
                                </input>
                                <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Si"></span>
                            </label>
                        </Col>


                    </Row>
                </>


            )}

            {(toggleCheck["applyLDROption"]) &&
                <hr className='mt-3 mb-4' />
            }


            {(toggleCheck["applyLDROption"] === true) &&
                <Row className="" style={{ display: "flex", justifyContent: "center" }}>
                    <Col className="mt--1" sm="12" style={{ display: "flex", justifyContent: "center" }}>
                        <>
                            <h6 className="surtitle">{LDRtitle}</h6>
                        </>
                    </Col>

                    <Col className="" sm="12" style={{ display: "flex", justifyContent: "center" }}>
                        <>
                            <h5 className="h3 mb-0">{legend}</h5>
                        </>
                    </Col>
                </Row>}



            {(toggleCheck["applyLDROption"] === true) && subtitles.map((row, keySub) =>
                //Imprime los subtitles con sus toggles relacionados al clasification
                <>
                    {subtitleHaveLDRS(row) &&
                        <Row className="" style={{ display: "flex", justifyContent: "center" }}>
                            <Col sm="11" key={row.label + "Col22"} className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                                <h2>{row.label}</h2>
                            </Col>

                        </Row>}
                    <Row className="" style={{ display: "flex", justifyContent: "center" }}>


                        {fieldsForm.map((rowField, keyFields) =>
                            //Se imprime el resto de toggles relacionados al subtitle en clasification
                            (rowField.clasification === row.clasification) &&
                            <>
                                <Col xs="12" sm="auto" key={keyFields} className=" mt-2" style={{ display: "flex", justifyContent: "center" }}>

                                    <label ><strong> {`${rowField.label} `} </strong></label>


                                    <label className="custom-toggle custom-toggle-info ml-2 mr-2 " >
                                        <input
                                            key={row.id}
                                            checked={toggleCheck[rowField.id]}
                                            className=''
                                            id="edit"
                                            type="checkbox"
                                            onChange={(e) => handleToggleCheck(2, rowField.id)}
                                            disabled={rowField.disabled}>
                                        </input>
                                        <span id={rowField.id + "Toggle"} className="custom-toggle-slider rounded-circle" ></span>
                                    </label>

                                </Col>

                            </>


                        )}


                    </Row>

                </>

            )}




        </Spin>
    )
})
