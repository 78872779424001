//CHARTS

//DATA
export const transactionCountSelector = state => state.transactionCountData;
export const loginTotalsSelector = state => state.loginTotalsData;
export const mostExecutedTransactionSelector = state =>
  state.mostExecutedTransactionData;
//export const executedTotalsSelector = state => state.executedTotalsData.total;
//export const locationsTotalsSelector = state => state.locationsTotalsData;
//export const monthsTotalsSelector = state => state.monthsTotalsData;
export const daysTotalsSelector = state => state.daysTotalsData;

//Movements Totals Chart
export const movementsTotalsSelector = state => {
  let data = [];
  let labels = [];
  if (state.movementsTotalsData.total) {
    let movementsGraph = state.movementsTotalsData.total;
    movementsGraph.forEach(element => {
      if (element.key != null && element.key) {
        labels.push(element.key);
        data.push(parseInt(element.doc_count));
      }
    });
  }

  return {
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function(value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              }
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          }
        }
      }
    },
    data: {
      labels: labels,
      datasets: [
        {
          label: "Movimientos",
          data: data
        }
      ]
    }
  };
};

//Executed Totals Chart
export const executedTotalsSelector = state => {
  let data = [];
  let labels = [];
  let colors = [];
  if (state.executedTotalsData.total) {
    let executedGraph = state.executedTotalsData.total;
    executedGraph.forEach(element => {
      if (element.key != null && element.key) {
        labels.push(element.key);
        data.push(parseInt(element.doc_count));
        colors.push("#" + ((Math.random() * 0xffffff) << 0).toString(16));
      }
    });
  }

  return {
    data: {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: colors
        }
      ]
    },
    values: state.executedTotalsData.total
  };
};

export const locationsTotalsSelector = state => {
  let data = [];
  let labels = [];
  if (state.locationsTotalsData.total) {
    let movementsGraph = state.locationsTotalsData.total;
    movementsGraph.forEach(element => {
      if (element.key != null && element.key) {
        labels.push(element.key);
        data.push(parseInt(element.doc_count));
      }
    });
  }

  return {
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function(value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              }
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          }
        }
      }
    },
    data: {
      labels: labels,
      datasets: [
        {
          label: "Movimientos",
          data: data
        }
      ]
    }
  };
};

export const monthsTotalsSelector = state => {
  let data = [];
  let labels = [];
  if (state.monthsTotalsData.total) {
    let movementsGraph = state.monthsTotalsData.total;
    movementsGraph.forEach(element => {
      labels.push(element.name);
      data.push(parseInt(element.value));
    });
  }

  return {
    data: {
      labels: labels,
      datasets: [
        {
          label: "Transacciones",
          data: data
        }
      ]
    }
  };
};
