// core components
import AdminHeader from 'components/Shared/Header/AdminHeader';
import Table from 'components/Shared/Table/Table.jsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library
import React, { Component } from 'react';
// react component used to create sweet alerts
// import ReactBSAlert from "react-bootstrap-sweetalert";
// react-redux library for connect component
import { connect } from 'react-redux';
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
// selectors for get dato of resourse
import { getOrders, getTasks } from 'selectors/planningMRS';

/**
 * Clase para mostrar todas las tareas y ordenes asignadas a un recurso especifico
 * @class Tasks
 * @extends {Component}
 */
class Tasks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      page: 1,
      sizePerPage: 5,
      open: false
    };
  }

  /**
   * Funcion para regresar a la vista de nodos
   * @memberof Resourses
   */
  handleOnBack = () => {
    // this.setState({
    //   alert: (
    //     <ReactBSAlert
    //       custom
    //       showCancel
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="¿Está seguro de regresar?"
    //       customIcon={
    //         <div
    //           className="swal2-icon swal2-question swal2-animate-question-icon"
    //           style={{ display: "flex" }}
    //         >
    //           <span className="swal2-icon-text">?</span>
    //         </div>
    //       }
    //       onConfirm={() => this.props.history.goBack()}
    //       onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="success"
    //       cancelBtnBsStyle="danger"
    //       confirmBtnText="Sí, regresar"
    //       cancelBtnText="No, continuar"
    //       btnSize="md"
    //     />
    //   )
    // });
    this.props.history.goBack()
  }

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  closeModal = () => {
    this.setState({
      open: false
    })
  }

  expandRow = {
    parentClassName: '#2dce89',
    onlyOneExpanding: true,
    renderer: ({ DETALLES: { item: [element] } }) => (
      <div>
        <span>
          <div className="row">
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Contrato</span> <br />
              {element.CONTRACT}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>
                Cliente
              </span>{" "}
              <br />
              {element.CUSTOMER}
            </div>
            <div className="col">
              <span style={{ color: "#0D45A4" }}>Tiquete</span> <br />
              {element.TICKET}
            </div>
          </div>
        </span>
      </div>
    ),
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    }
  }

  render() {

    const { alert, page, sizePerPage } = this.state;
    const { tasksColumns, ordersColumns, orders, tasks } = this.props;

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    return (
      <>
        {alert}
        <AdminHeader name="Lista de tareas" parentName="Planning MRS" />
        <div className="header pb-6">
          <Container className="mt--6" fluid>
            <Row>
              {
                tasks.length ?
                  <div className="col">
                    <Table
                      title="Tareas"
                      subtitle="Tareas asignadas al recurso."
                      columns={tasksColumns}
                      data={tasks}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={tasks.length}
                      defaultSorted={defaultSorted}
                    />
                  </div>
                  : null
              }
              {
                orders.length ?
                  <div className="col">
                    <Table
                      title="Ordenes"
                      subtitle="Ordenes asignadas al recurso."
                      columns={ordersColumns}
                      data={orders}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={orders.length}
                      defaultSorted={defaultSorted}
                      expandFeatrue={this.expandRow}
                    />
                  </div>
                  : null
              }
            </Row>
            <Row>
              <Col xs={12} sm={3}>
                <Button
                  className="btn-icon"
                  color="info"
                  block
                  type="button"
                  onClick={this.handleOnBack}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-bold-left" />
                  </span>
                  <span className="btn-inner--text">Atrás</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

Tasks.defaultProps = {
  tasks: [],
  orders: [],
  tasksColumns: [
    {
      dataField: "ID",
      text: "ID",
      sort: true
    },
    {
      dataField: "IN_HOUR",
      text: "Hora de Inicio",
      sort: true
    },
    {
      dataField: "EN_HOUR",
      text: "Hora de Fin",
      sort: true
    },
    {
      dataField: "IN_DATE",
      text: "Día de Inicio",
      sort: true
    },
    {
      dataField: "EN_DATE",
      text: "Día de Fin",
      sort: true
    },
    {
      dataField: "DESCRIPTION",
      text: "Descripción",
    },
  ],
  ordersColumns: [
    {
      dataField: "ID",
      text: "ID",
      sort: true
    },
    {
      dataField: "IN_HOUR",
      text: "Hora de Inicio",
      sort: true
    },
    {
      dataField: "EN_HOUR",
      text: "Hora de Fin",
      sort: true
    },
    {
      dataField: "IN_DATE",
      text: "Dia de Inicio",
      sort: true
    },
    {
      dataField: "EN_DATE",
      text: "Dia de Fin",
      sort: true
    },
    {
      dataField: "DESCRIPTION",
      text: "Descripcion",
    },
  ]
};

Tasks.propTypes = {
  tasksColumns: PropTypes.array.isRequired,
  orders: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
}
const mapStateToProps = (state, props) => ({
  tasks: getTasks(state, props),
  orders: getOrders(state, props)
});

export default withRouter(connect(mapStateToProps, null)(Tasks));